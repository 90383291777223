import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Input, Tree, Button as AntButton, Form, Modal as AntModal, Select, Badge, Space, Divider, InputNumber, Row, Col } from "antd";
import { FcFolder, FcEditImage, FcFile, FcSettings } from "react-icons/fc";
import { Menu, Item, useContextMenu } from "react-contexify";
import { FaFileAudio, FaRegFile } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../ApiConnector";
import { showError, showSuccess, error } from "../../MessageHelper";
import { Button, Table as TableBoot } from "react-bootstrap";
import { PlusOutlined, MinusCircleOutlined, ArrowRightOutlined } from "@ant-design/icons";
import DraggableModal from "../../Components/DraggableModal";
import StocksTreeNumber from "./StocksTreeNumber";


const Menu_NewStock = "Menu_NewStock";
const Menu_Stock = "Menu_Stock";
const Menu_SubStock = "Menu_SubStock";
const Menu_SubArea = "Menu_SubArea";

const { Option } = Select;
//const { TextArea } = Input;

const { show } = useContextMenu();

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

export class StocksTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "StocksTree",
      controllerName: "stocksTree",
      id: null,
      projectName: "",
      expandedKeys: [],
      expandedKeysStoreType: [],
      autoExpandParent: true,
      checkedKeys: [],
      checkedKeysStoreType: [],
      selectedKeys: [],
      nodes: [],
      nodesStoreType: [],
      loadedKeys: [],
      loadedKeysStoreType: [],
      searchValue: "",
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showServiceModal: false,
      refreshTree: true
    };
  }

  inputRef = React.createRef(null);

  formRef = React.createRef();
  formRefOnClickName = React.createRef();
  formRefCreateProject = React.createRef();
  formRefStocksTreeStock = React.createRef();
  formRefshowStocksTreeSubStockFromStock = React.createRef();
  formRefshowStocksTreeSubStockFromSubStock = React.createRef();
  formRefshowStocksTreeSubStockFromSubArea = React.createRef();
  formRefStocksTreeSubAreaFromStock = React.createRef();
  formRefStocksTreeSubAreaFromSubStock = React.createRef();
  formRefStocksTreeSubAreaFromSubArea = React.createRef();
  formRefStocksTreeStockCoordinates = React.createRef();
  formRefStocksTreeSubStockCoordinates = React.createRef();
  formRefSubAreaCoordinates = React.createRef();

  componentDidMount = async () => {
    this.fillTree();
    this.fillTreeStoreType();
    this.fillCode("Stock_StockType");
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "ProjectName"
    };

    // SUBITEMS
    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      // if (treenode.type === "Stock") {
      //   response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsStock/" + obj.id);
      // } else

      if (treenode.type === "SubStock") {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsSubStock/" + obj.id);
      } else if (treenode.type === "SubArea") {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsSubArea/" + obj.id);
      } else {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitems/" + obj.id + "/" + obj.type);
      }
    }

    if (response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.icon =
          Boolean(element.type) && element.type === "Stock" ? (
            <FcFile />
          ) : element.type === "SubStock" ? (
            <FaRegFile />
          ) : element.type === "SubArea" ? (
            <FaFileAudio />
          ) : (
            <FcFolder />
          );
        element.title = element.value;
        element.id = element.key;
        element.key = element.key + "-" + element.type + "." + element.projectId;
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      this.setState({
        nodes: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  onRightClick = (event, node) => {
    let parentNode = this.getParentNode(node);
    this.setState(
      {
        selectedNodeKey: node.key,
        selectedNode: node,
        parentNode: parentNode
      },
      this.displayMenu(event, Boolean(node) && Boolean(node.type) ? node.type : null)
    );
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  showCloseTreeModal = () => {
    this.setState({
      modalCloseTreeShow: !this.state.modalCloseTreeShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  openNumbersModal = () => {
    this.setState({
      modalOpenNumbers: !this.state.modalOpenNumbers
    });

  };

  


  handleItemClick = ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;

    console.log("AAA", event.currentTarget.id);
    console.log(selectedNode);
    console.log(parentNode);
    console.log(key);

    this.setState({
      projectId: selectedNode.projectId
    });

    switch (event.currentTarget.id) {
      case "add_newStock":
        this.setState({
          showStocksTreeStockModal: true,
          parentId: null,
          type: "Stock",
          id: null
        });
        break;

      case "add_subStockFromStock":
        this.setState({
          showStocksTreeSubStockFromStockModal: true,
          parentId: selectedNode.id,
          type: "SubStock",
          id: null
        });
        break;

      case "add_subStockFromSubStock":
        this.setState({
          showStocksTreeSubStockFromSubStockModal: true,
          parentId:
            Boolean(parentNode) && !(parentNode.type === "ProjectName" || parentNode.type === "CompanyName") ? parentNode.id : selectedNode.id,
          type: "SubStock",
          id: null
        });
        break;

      case "add_subStockFromSubArea":
        this.setState({
          showStocksTreeSubStockFromSubAreaModal: true,
          parentId: Boolean(parentNode) && !(parentNode.type === "ProjectName" || parentNode.type === "CompanyName") ? parentNode.id : null,
          stocksTreeSubAreaId: selectedNode.id,
          type: "SubStock",
          id: null
        });
        break;

      case "add_subAreaFromStock":
        this.setState({
          showStocksTreeSubAreaFromStockModal: true,
          parentId:
            Boolean(parentNode) && !(parentNode.type === "ProjectName" || parentNode.type === "CompanyName") ? parentNode.id : selectedNode.id,
          type: "SubArea",
          id: null
        });
        break;

      case "add_subAreaFromSubStock":
        this.setState({
          showStocksTreeSubAreaFromSubStockModal: true,
          parentId: Boolean(parentNode) && !(parentNode.type === "ProjectName" || parentNode.type === "CompanyName") ? parentNode.id : null,
          stocksTreeSubStockId: selectedNode.id,
          type: "SubArea",
          id: null
        });
        break;

      case "add_subAreaFromSubArea":
        this.setState({
          showStocksTreeSubAreaFromSubAreaModal: true,
          parentId: Boolean(parentNode) && !(parentNode.type === "ProjectName" || parentNode.type === "CompanyName") ? parentNode.id : null,
          type: "SubArea",
          id: null
        });
        break;

      case "edit_stock":
        this.setState(
          {
            showStocksTreeStockModal: true,
            id: selectedNode.id,
            parentId: Boolean(parentNode) && !(parentNode.type === "ProjectName" || parentNode.type === "CompanyName") ? parentNode.id : null,
            type: selectedNode.type
          },
          async () => {
            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/" + selectedNode.id);
            if (response.type === "ERROR") {
              error(response);
            } else {
              if (Boolean(response.data)) {
                this.formRefStocksTreeStock.current.setFieldsValue({
                  ...response.data
                });
              }
            }
          }
        );
        break;

      case "edit_subStock":
        this.setState(
          {
            showStocksTreeSubStockFromStockModal: true,
            id: selectedNode.id,
            parentId: Boolean(parentNode) && !(parentNode.type === "ProjectName" || parentNode.type === "CompanyName") ? parentNode.id : null,
            type: selectedNode.type
          },
          async () => {
            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/" + selectedNode.id);
            if (response.type === "ERROR") {
              error(response);
            } else {
              if (Boolean(response.data)) {
                this.formRefshowStocksTreeSubStockFromStock.current.setFieldsValue({
                  ...response.data
                });
              }
            }
          }
        );
        break;

      case "edit_subArea":
        this.setState(
          {
            showStocksTreeSubAreaFromStockModal: true,
            id: selectedNode.id,
            parentId: Boolean(parentNode) && !(parentNode.type === "ProjectName" || parentNode.type === "CompanyName") ? parentNode.id : null,
            type: selectedNode.type
          },
          async () => {
            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getSubArea/" + selectedNode.id);
            if (response.type === "ERROR") {
              error(response);
            } else {
              if (Boolean(response.data)) {
                this.formRefStocksTreeSubAreaFromStock.current.setFieldsValue({
                  ...response.data
                });
              }
            }
          }
        );
        break;

      case "deleteStocksTreeStock":
        this.setState({
          showDeleteStocksTreeStockModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;

      case "deleteStocksTreeSubStock":
        this.setState({
          showDeleteStocksTreeSubStockModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;

      case "deleteStocksTreeSubArea":
        this.setState({
          showDeleteStocksTreeSubAreaModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;

      case "delete":
        this.setState({
          showDeleteModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;
      default:
    }
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id,
      type: this.state.type
    };

    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
      else this.fillTree();

      showSuccess();
      this.setState({
        id: null,
        showDeleteModal: false
      });
    }
  };

  displayMenu = (e, type) => {
    console.log("TTT", type);
    if (Boolean(type)) {
      if (type === "Stock") {
        show(e, { id: Menu_Stock });
      } else if (type === "SubStock") {
        show(e, { id: Menu_SubStock });
      } else if (type === "SubArea") {
        show(e, { id: Menu_SubArea });
      } else if (type === "CompanyName" || type === "ProjectName") {
        show(e, { id: Menu_NewStock });
      }
    }
  };

  // DELETE   DELETE    DELETE

  deleteStocksTreeStock = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteStocksTreeStock/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteStocksTreeStockModal: false
        });
      }
    } else {
      showError("Stock not found.");
    }
  };

  deleteStocksTreeSubStock = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteStocksTreeSubStock/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteStocksTreeSubStockModal: false
        });
      }
    } else {
      showError("SubStock not found.");
    }
  };

  deleteStocksTreeSubArea = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteStocksTreeSubArea/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteStocksTreeSubAreaModal: false
        });
      }
    } else {
      showError("SubArea not found.");
    }
  };

  save = async values => {
    const { process, selectedNode } = this.state;
    console.log("selectedNode", selectedNode);
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId,
      type: Boolean(this.state.type) ? this.state.type : "ProjectName"
    };
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveProjectsTree", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateProjectsTree/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showCreateProjectModal: false
      });
    }
  };

  // SAVE UPDATE   SAVE UPDATE  SAVE UPDATE   SAVE UPDATE   SAVE UPDATE

  // SAVE ADD NEW STOCK
  saveStocksTreeStock = async values => {
    const { process, selectedNode, checkedKeysStoreType } = this.state;
    let storeTypes = [];
    let storingTypes = [];
    let insideStoreAreas = [];
    console.log("checkedKeysStoreType", checkedKeysStoreType);
    checkedKeysStoreType.forEach(item => {
      const parts = item.split("-");
      console.log("parts", parts);
      if (parts.length > 1) {
        if (parts[1] === "StoringType") {
          storingTypes.push({ storingTypeId: parts[0] });
        } else if (parts[1] === "InsideStoreArea") {
          insideStoreAreas.push({ insideStoreAreaId: parts[0] });
        }
      } else {
        storeTypes.push({ storeTypeId: item });
      }
    });

    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId,
      projectId: this.state.projectId,
      storeTypes: storeTypes,
      storingTypes: storingTypes,
      insideStoreAreas: insideStoreAreas
    };

    console.log(newItem);
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createStock", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateStock/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showStocksTreeStockModal: false
      });
    }
  };

  // SAVE ADD SUB STOCK
  saveStocksTreeSubStockFromStock = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId,
      projectId: this.state.projectId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createStock", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateStock/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showStocksTreeSubStockFromStockModal: false
      });
    }
  };

  saveStocksTreeSubStockFromSubStock = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveStocksTreeSubStock", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateStocksTreeSubStock/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showStocksTreeSubStockFromSubStockModal: false
      });
    }
  };

  saveStocksTreeSubStockFromSubArea = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      stocksTreeSubAreaId: this.state.stocksTreeSubAreaId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveStocksTreeSubStock", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateStocksTreeSubStock/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showStocksTreeSubStockFromSubAreaModal: false
      });
    }
  };

  // SAVE NEW SUB-STORING AREA

  saveStocksTreeSubAreaFromStock = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      stockId: this.state.parentId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveStocksTreeSubArea", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateStocksTreeSubArea/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showStocksTreeSubAreaFromStockModal: false
      });
    }
  };

  saveStocksTreeSubAreaFromSubStock = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      stocksTreeSubStockId: this.state.stocksTreeSubStockId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveStocksTreeSubArea", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateStocksTreeSubArea/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showStocksTreeSubAreaModal: false
      });
    }
  };

  saveStocksTreeSubAreaFromSubArea = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveStocksTreeSubArea", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateStocksTreeSubArea/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showStocksTreeSubAreaModal: false
      });
    }
  };

  // SAVE COORDINATES

  saveStocksTreeStockCoordinates = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveStocksTreeSubStockCoordinates", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateStocksTreeStockCoordinates/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showStocksTreeSubAreaModal: false
      });
    }
  };

  saveStocksTreeSubStockCoordinates = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      stocksTreeSubStockId: this.state.stocksTreeSubStockId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveStocksTreeSubStockCoordinates", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateStocksTreeSubStockCoordinates/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showStocksTreeSubAreaModal: false
      });
    }
  };

  saveStocksTreeSubAreaCoordinates = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      stocksTreeSubAreaId: this.state.stocksTreeSubAreaId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveStocksTreeSubAreaCoordinates", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateStocksTreeSubAreaCoordinates/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showStocksTreeSubAreaModal: false
      });
    }
  };

  paste = () => {
    this.formRef.current.submit();
    this.formRefCreateProject.current.submit();
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      projectName: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      process: null,
      showOrderModal: false,
      showPasteModal: false,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showServiceModal: false
    });
  };

  expandSelectedNode = () => {
    let key = this.state.selectedNodeKey;
    let node = this.state.selectedNode;
    if (Boolean(key)) {
      let expandedKeys = Boolean(this.state.expandedKeys) ? this.state.expandedKeys : [];
      if (Boolean(expandedKeys.find(p => p === key))) {
        this.loadData(node, false);
      } else {
        this.setState({
          expandedKeys: [...expandedKeys, key]
        });
        this.loadData(node, false);
      }
    }
  };

  itemTemplate(item) {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <h5 className="mb-2">{item.title}</h5>
        </div>
      </div>
    );
  }

  onDragEnter = info => {};

  onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...this.state.nodes];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 &&
      // Has children
      info.node.props.expanded &&
      // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    this.setState({
      nodes: data
    });
  };

  onExpandStoreType = expandedKeys => {
    this.setState({
      expandedKeysStoreType: expandedKeys
    });
  };

  onLoadDataStoreType = async loadedKeys => {
    this.setState({
      loadedKeysStoreType: loadedKeys
    });
  };

  loadDataStoreType = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeysStoreType } = this.state;
    var nodesStoreType = this.state.nodesStoreType;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "StoreType"
    };

    // SUBITEMS
    console.log("treenode", treenode);
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsStoreType/" + obj.id + "/" + obj.type);
    if (response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.icon = element.type === "StoringType" ? <FaRegFile /> : element.type === "InsideStoreArea" ? <FaFileAudio /> : <FcFolder />;
        element.title = element.value;
        element.id = element.key;
        element.key = element.key + "-" + element.type;
      });

      var res = updateTreeData(nodesStoreType, treenode.key, data, expandedKeysStoreType);
      this.setState({
        nodesStoreType: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  fillTreeStoreType = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/rootStoreType");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
        });

        this.setState({
          nodesStoreType: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  onCheckStoreType = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    this.setState({
      checkedKeysStoreType: checkedKeys,
      halfCheckedKeysStoreType: e.halfCheckedKeys
    });
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <Badge.Ribbon
        text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage={"Stocks Tree "} />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <br /> <br />
          {this.state.refreshTree && (
            <Tree
              style={{ marginTop: "10px", lineHeight: "30px" }}
              className="draggable-tree"
              showLine={{ showLeafIcon: false }}
              showIcon
              checkStrictly={true}
              loadedKeys={this.state.loadedKeys}
              onLoad={this.onLoadData}
              loadData={this.loadData}
              onRightClick={({ event, node }) => this.onRightClick(event, node)}
              onExpand={this.onExpand}
              expandedKeys={this.state.expandedKeys}
              autoExpandParent={false}
              treeData={this.state.nodes}
              blockNode={true}
              draggable
              // checkable
              // onCheck={this.onCheck}
              // checkedKeys={this.state.checkedKeys}
              halfCheckedKeys={this.state.halfCheckedKeys}
              onDragEnter={this.onDragEnter}
              onDrop={this.onDrop}
            />
          )}
          {/* MENU */}
          <Menu id={Menu_NewStock}>
            <Item id="add_newStock" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="StocksTree.AddNewStock" defaultMessage="Add New Stock" />
                </span>
              </Space>
            </Item>
          </Menu>
          <Menu id={Menu_Stock}>
            <Item id="edit_stock" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="StocksTree.edit" defaultMessage="Edit" />
                </span>
              </Space>
            </Item>
            <Item id="add_subStockFromStock" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="StocksTree.AddSubStock" defaultMessage="Add Sub Stock" />
                </span>
              </Space>
            </Item>
            <Item id="add_subAreaFromStock" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="StocksTree.AddSubArea" defaultMessage="Add Sub Area" />
                </span>
              </Space>
            </Item>
            <Item id="add_maintenance" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="StocksTree.Maintenance" defaultMessage="Maintenance" />
                </span>
              </Space>
            </Item>
            <Item id="deleteStocksTreeStock" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="StocksTree.Delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>
          <Menu id={Menu_SubStock}>
            <Item id="edit_subStock" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="StocksTree.Edit" defaultMessage="Edit" />
                </span>
              </Space>
            </Item>
            <Item id="add_subStockFromSubStock" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="StocksTree.AddSubStock" defaultMessage="Add Sub Stock" />
                </span>
              </Space>
            </Item>
            <Item id="add_subAreaFromSubStock" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="StocksTree.AddSubArea" defaultMessage="Add Sub Area" />
                </span>
              </Space>
            </Item>
            <Item id="add_maintenance" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="StocksTree.Maintenance" defaultMessage="Maintenance" />
                </span>
              </Space>
            </Item>
            <Item id="deleteStocksTreeSubStock" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="StocksTree.Delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>
          <Menu id={Menu_SubArea}>
            <Item id="edit_subArea" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="StocksTree.Edit" defaultMessage="Edit" />
                </span>
              </Space>
            </Item>
            {/* <Item id="add_subStockFromSubArea" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="StocksTree.AddSubStock" defaultMessage="Add Sub Stock" />
                </span>
              </Space>
            </Item>
            <Item id="add_subAreaFromSubArea" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="StocksTree.AddSubArea" defaultMessage="Add Sub Area" />
                </span>
              </Space>
            </Item> */}
            <Item id="add_maintenance" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="StocksTree.Maintenance" defaultMessage="Maintenance" />
                </span>
              </Space>
            </Item>
            <Item id="deleteStocksTreeSubArea" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="StocksTree.Delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>
          {/* TABLE MODAL */}
          {/* ADD NEW STOCK */}
          <DraggableModal
            title={<FormattedMessage id="StocksTree.AddNewStore" defaultMessage="Add New Store" />}
            open={this.state.showStocksTreeStockModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showStocksTreeStockModal: false })}
            onOk={() => this.formRefStocksTreeStock.current.submit()}
            width={800}
            centered
            content={
              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveStocksTreeStock}
                onFinishFailed={onFinishFailed}
                ref={this.formRefStocksTreeStock}
              >
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="StocksTree.StoreName" defaultMessage="Store Name" />}
                    name="stockName"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="StocksTree.Address" defaultMessage="Address" />}
                    name="address"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item {...layout} label={<FormattedMessage id="ToDo.File" defaultMessage="Coordinates" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => this.setState({ showStocksTreeStockCoordinatesModal: true })}
                      icon={<FcSettings />}
                    ></AntButton>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="StocksTree.Layout" defaultMessage="Layout" />}
                    name="layout"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                <Row gutter={[16, 16]}>
                  <Col md={21}></Col>
                  <Col md={2}>
                    <AntButton
                      type="default"
                      onClick={this.showCloseTreeModal}
                      style={{ borderRadius: "", color: "#000000", borderColor: "", background: "#dcfade" }}
                      icon={<ArrowRightOutlined />}
                    ></AntButton>
                  </Col>
                </Row>

                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="CompanyProject.AddNewStore" defaultMessage="Add New Store" />}
                    name="storeTypeIds"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Tree
                      style={{ marginTop: "10px", lineHeight: "30px" }}
                      className="draggable-tree"
                      showLine={{ showLeafIcon: false }}
                      showIcon
                      checkStrictly={false}
                      loadedKeys={this.state.loadedKeysStoreType}
                      onLoad={this.onLoadDataStoreType}
                      loadData={this.loadDataStoreType}
                      onExpand={this.onExpandStoreType}
                      expandedKeys={this.state.expandedKeysStoreType}
                      autoExpandParent={false}
                      treeData={this.state.nodesStoreType}
                      blockNode={true}
                      checkable
                      onCheck={this.onCheckStoreType}
                      checkedKeys={this.state.checkedKeysStoreType}
                      halfCheckedKeys={this.state.halfCheckedKeysStoreType}
                    />
                  </Form.Item>
                }
              </Form>
            }
          />
          {/* ADD SUB STOCK*/}
          <DraggableModal
            title={<FormattedMessage id="StocksTree.SubStock" defaultMessage="Sub-Stock" />}
            open={this.state.showStocksTreeSubStockFromStockModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showStocksTreeSubStockFromStockModal: false })}
            onOk={() => this.formRefshowStocksTreeSubStockFromStock.current.submit()}
            width={800}
            centered
            content={
              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveStocksTreeSubStockFromStock}
                onFinishFailed={onFinishFailed}
                ref={this.formRefshowStocksTreeSubStockFromStock}
              >
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="StocksTree.StockName" defaultMessage="Stock Name" />}
                    name="stockName"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {/* COORDINATES */}
                {
                  <Form.Item {...layout} label={<FormattedMessage id="ToDo.File" defaultMessage="Coordinates" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => this.setState({ showStocksTreeSubStockCoordinatesModal: true })}
                      icon={<FcSettings />}
                    ></AntButton>
                  </Form.Item>
                }
                {/* Stock Type */}
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="StocksTree.StockType" defaultMessage="Stock Type" />}
                    name="stockTypeId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Stock Type"
                      optionFilterProp="children"
                      dropdownRender={menu => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              padding: 8
                            }}
                          >
                            <Input
                              style={{ flex: "auto" }}
                              value={this.state.codeName}
                              onChange={e => {
                                this.setState({
                                  codeName: e.target.value
                                });
                              }}
                            />
                            <AntButton type="link" icon={<PlusOutlined />} onClick={() => this.addCode("Stock_StockType")}>
                              <FormattedMessage id="General.AddNew" defaultMessage="Add New" />
                            </AntButton>
                          </div>
                        </div>
                      )}
                    >
                      {Boolean(this.state["Stock_StockTypeSelectItems"]) &&
                        this.state["Stock_StockTypeSelectItems"].map(i => (
                          <Option key={i.id} value={i.id}>
                            {i.name}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }
                {/* Item To Be Stored */}
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="StocksTree.ItemToBeStoredId" defaultMessage="Item To Be Stored" />}
                    name="itemToBeStoredId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                      {Boolean(this.state["itemToBeStoredIdSelectItems"]) &&
                        this.state["itemToBeStoredIdSelectItems"].length > 0 &&
                        this.state["itemToBeStoredIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }
              </Form>
            }
          />
          {/* ADD SUB STOCK FROM SUBSTOCK*/}
          <AntModal
            title={<FormattedMessage id="StocksTree.SubStockXX" defaultMessage="Sub-Stock xx" />}
            open={this.state.showStocksTreeSubStockFromSubStockModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showStocksTreeSubStockFromSubStockModal: false })}
            onOk={() => this.formRefshowStocksTreeSubStockFromSubStock.current.submit()}
            width={800}
            centered
          >
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveStocksTreeSubStockFromSubStock}
              onFinishFailed={onFinishFailed}
              ref={this.formRefshowStocksTreeSubStockFromSubStock}
            >
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="StocksTree.StockName" defaultMessage="Stock Name" />}
                  name="stockName"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {/* COORDINATES */}
              {
                <Form.Item {...layout} label={<FormattedMessage id="ToDo.File" defaultMessage="Coordinates" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => this.setState({ showStocksTreeSubStockCoordinatesModal: true })}
                    icon={<FcSettings />}
                  ></AntButton>
                </Form.Item>
              }
              {/* Stock Type */}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="StocksTree.StockType" defaultMessage="Stock Type" />}
                  name="stockTypeId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Stock Type"
                    optionFilterProp="children"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8
                          }}
                        >
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={e => {
                              this.setState({
                                codeName: e.target.value
                              });
                            }}
                          />
                          <AntButton type="link" icon={<PlusOutlined />} onClick={() => this.addCode("Stock_StockType")}>
                            <FormattedMessage id="General.AddNew" defaultMessage="Add New" />
                          </AntButton>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["Stock_StockTypeSelectItems"]) &&
                      this.state["Stock_StockTypeSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {/* Item To Be Stored */}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="StocksTree.ItemToBeStoredId" defaultMessage="Item To Be Stored" />}
                  name="itemToBeStoredId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["itemToBeStoredIdSelectItems"]) &&
                      this.state["itemToBeStoredIdSelectItems"].length > 0 &&
                      this.state["itemToBeStoredIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
            </Form>
          </AntModal>
          {/* ADD SUB STOCK FROM SUBAREA*/}
          <AntModal
            title={<FormattedMessage id="StocksTree.AddSubArea" defaultMessage="Sub-Area" />}
            open={this.state.showStocksTreeSubStockFromSubAreaModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showStocksTreeSubStockFromSubAreaModal: false })}
            onOk={() => this.formRefshowStocksTreeSubStockFromSubArea.current.submit()}
            width={800}
            centered
          >
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveStocksTreeSubStockFromSubArea}
              onFinishFailed={onFinishFailed}
              ref={this.formRefshowStocksTreeSubStockFromSubArea}
            >
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="StocksTree.StockName" defaultMessage="Stock Name" />}
                  name="stockName"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {/* COORDINATES */}
              {
                <Form.Item {...layout} label={<FormattedMessage id="ToDo.File" defaultMessage="Coordinates" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => this.setState({ showStocksTreeSubStockCoordinatesModal: true })}
                    icon={<FcSettings />}
                  ></AntButton>
                </Form.Item>
              }
              {/* Stock Type */}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="StocksTree.StockType" defaultMessage="Stock Type" />}
                  name="stockTypeId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Stock Type"
                    optionFilterProp="children"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8
                          }}
                        >
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={e => {
                              this.setState({
                                codeName: e.target.value
                              });
                            }}
                          />
                          <AntButton type="link" icon={<PlusOutlined />} onClick={() => this.addCode("Stock_StockType")}>
                            <FormattedMessage id="General.AddNew" defaultMessage="Add New" />
                          </AntButton>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["Stock_StockTypeSelectItems"]) &&
                      this.state["Stock_StockTypeSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {/* Item To Be Stored */}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="StocksTree.ItemToBeStoredId" defaultMessage="Item To Be Stored" />}
                  name="itemToBeStoredId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["itemToBeStoredIdSelectItems"]) &&
                      this.state["itemToBeStoredIdSelectItems"].length > 0 &&
                      this.state["itemToBeStoredIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
            </Form>
          </AntModal>
          {/* NEW SUB  AREA FROM STOCK*/}
          <AntModal
            title={<FormattedMessage id="StocksTree.AddMaterial" defaultMessage="Sub-Storing Area" />}
            open={this.state.showStocksTreeSubAreaFromStockModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showStocksTreeSubAreaFromStockModal: false })}
            onOk={() => this.formRefStocksTreeSubAreaFromStock.current.submit()}
            width={800}
            centered
          >
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveStocksTreeSubAreaFromStock}
              onFinishFailed={onFinishFailed}
              ref={this.formRefStocksTreeSubAreaFromStock}
            >
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="StocksTree.Code" defaultMessage="Code" />}
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="StocksTree.StockName" defaultMessage="Definition" />}
                  name="definition"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item {...layout} label={<FormattedMessage id="StocksTree.Dimensions" defaultMessage="Dimensions" />}>
                  <Row gutter={5}>
                    <Col span={5}>
                      {/* LENGTH */}
                      <Form.Item
                        name="length"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input placeholder="length" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={5}>
                      {/* WIDTH */}
                      <Form.Item
                        name="width"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input placeholder="width" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={5}>
                      {/* HEIGHT */}
                      <Form.Item
                        name="height"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input placeholder="height" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              }
              {/* COORDINATES */}
              {
                <Form.Item {...layout} label={<FormattedMessage id="ToDo.File" defaultMessage="Coordinates" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => this.setState({ showStocksTreeSubAreaCoordinatesModal: true })}
                    icon={<FcSettings />}
                  ></AntButton>
                </Form.Item>
              }
              {/* Stock Type */}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="StocksTree.StockType" defaultMessage="Stock Type" />}
                  name="stockTypeId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Stock Type"
                    optionFilterProp="children"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8
                          }}
                        >
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={e => {
                              this.setState({
                                codeName: e.target.value
                              });
                            }}
                          />
                          <AntButton type="link" icon={<PlusOutlined />} onClick={() => this.addCode("Stock_StockType")}>
                            <FormattedMessage id="General.AddNew" defaultMessage="Add New" />
                          </AntButton>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["Stock_StockTypeSelectItems"]) &&
                      this.state["Stock_StockTypeSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {/* Item To Be Stored */}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="StocksTree.ItemToBeStoredId" defaultMessage="Item To Be Stored" />}
                  name="itemToBeStoredId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["itemToBeStoredIdSelectItems"]) &&
                      this.state["itemToBeStoredIdSelectItems"].length > 0 &&
                      this.state["itemToBeStoredIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
            </Form>
          </AntModal>
          {/* NEW SUB  AREA FROM SUBSTOCK*/}
          <AntModal
            title={<FormattedMessage id="StocksTree.AddMaterial" defaultMessage="Sub-Storing Area" />}
            open={this.state.showStocksTreeSubAreaFromSubStockModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showStocksTreeSubAreaFromSubStockModal: false })}
            onOk={() => this.formRefStocksTreeSubAreaFromSubStock.current.submit()}
            width={800}
            centered
          >
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveStocksTreeSubAreaFromSubStock}
              onFinishFailed={onFinishFailed}
              ref={this.formRefStocksTreeSubAreaFromSubStock}
            >
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="StocksTree.StockName" defaultMessage="Definition" />}
                  name="definition"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item {...layout} label={<FormattedMessage id="StocksTree.Dimensions" defaultMessage="Dimensions" />}>
                  <Row gutter={5}>
                    <Col span={5}>
                      {/* LENGTH */}
                      <Form.Item
                        name="length"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input placeholder="length" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={5}>
                      {/* WIDTH */}
                      <Form.Item
                        name="width"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input placeholder="width" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={5}>
                      {/* HEIGHT */}
                      <Form.Item
                        name="height"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input placeholder="height" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              }
              {/* COORDINATES */}
              {
                <Form.Item {...layout} label={<FormattedMessage id="ToDo.File" defaultMessage="Coordinates" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => this.setState({ showStocksTreeSubAreaCoordinatesModal: true })}
                    icon={<FcSettings />}
                  ></AntButton>
                </Form.Item>
              }
              {/* Stock Type */}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="StocksTree.StockType" defaultMessage="Stock Type" />}
                  name="stockTypeId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Stock Type"
                    optionFilterProp="children"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8
                          }}
                        >
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={e => {
                              this.setState({
                                codeName: e.target.value
                              });
                            }}
                          />
                          <AntButton type="link" icon={<PlusOutlined />} onClick={() => this.addCode("Stock_StockType")}>
                            <FormattedMessage id="General.AddNew" defaultMessage="Add New" />
                          </AntButton>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["Stock_StockTypeSelectItems"]) &&
                      this.state["Stock_StockTypeSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {/* Item To Be Stored */}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="StocksTree.ItemToBeStoredId" defaultMessage="Item To Be Stored" />}
                  name="itemToBeStoredId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["itemToBeStoredIdSelectItems"]) &&
                      this.state["itemToBeStoredIdSelectItems"].length > 0 &&
                      this.state["itemToBeStoredIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
            </Form>
          </AntModal>
          {/* NEW SUB  AREA FROM SUBAREA*/}
          <AntModal
            title={<FormattedMessage id="StocksTree.AddMaterial" defaultMessage="Sub-Storing Area" />}
            open={this.state.showStocksTreeSubAreaFromSubAreaModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showStocksTreeSubAreaFromSubAreaModal: false })}
            onOk={() => this.formRefStocksTreeSubAreaFromSubArea.current.submit()}
            width={800}
            centered
          >
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveStocksTreeSubAreaFromSubArea}
              onFinishFailed={onFinishFailed}
              ref={this.formRefStocksTreeSubAreaFromSubArea}
            >
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="StocksTree.StockName" defaultMessage="Definition" />}
                  name="definition"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item {...layout} label={<FormattedMessage id="StocksTree.Dimensions" defaultMessage="Dimensions" />}>
                  <Row gutter={5}>
                    <Col span={5}>
                      {/* LENGTH */}
                      <Form.Item
                        name="length"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input placeholder="length" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={5}>
                      {/* WIDTH */}
                      <Form.Item
                        name="width"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input placeholder="width" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={5}>
                      {/* HEIGHT */}
                      <Form.Item
                        name="height"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input placeholder="height" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              }
              {/* COORDINATES */}
              {
                <Form.Item {...layout} label={<FormattedMessage id="ToDo.File" defaultMessage="Coordinates" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => this.setState({ showStocksTreeSubAreaCoordinatesModal: true })}
                    icon={<FcSettings />}
                  ></AntButton>
                </Form.Item>
              }
              {/* Stock Type */}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="StocksTree.StockType" defaultMessage="Stock Type" />}
                  name="stockTypeId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Stock Type"
                    optionFilterProp="children"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8
                          }}
                        >
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={e => {
                              this.setState({
                                codeName: e.target.value
                              });
                            }}
                          />
                          <AntButton type="link" icon={<PlusOutlined />} onClick={() => this.addCode("Stock_StockType")}>
                            <FormattedMessage id="General.AddNew" defaultMessage="Add New" />
                          </AntButton>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["Stock_StockTypeSelectItems"]) &&
                      this.state["Stock_StockTypeSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {/* Item To Be Stored */}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="StocksTree.ItemToBeStoredId" defaultMessage="Item To Be Stored" />}
                  name="itemToBeStoredId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["itemToBeStoredIdSelectItems"]) &&
                      this.state["itemToBeStoredIdSelectItems"].length > 0 &&
                      this.state["itemToBeStoredIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
            </Form>
          </AntModal>
          {/* NEW STOCK COORDINATES MODAL */}
          <AntModal
            title={<FormattedMessage id="StocksTree.Coordinates" defaultMessage="Coordinates" />}
            keyboard={false}
            maskClosable={false}
            open={this.state.showStocksTreeStockCoordinatesModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showStocksTreeStockCoordinatesModal: false })}
            onOk={() => this.formRefStocksTreeStockCoordinates.current.submit()}
            width={800}
            centered
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveStocksTreeStockCoordinates} red={this.formRefStocksTreeStockCoordinates}>
              <br></br>
              <br></br>
              <br />{" "}
              <h4>
                <FormattedMessage id={"StocksTree.Coordinates"} defaultMessage={"  "} />
              </h4>
              <Form.List name="coordinates">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            {/* POINT */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.Point"} defaultMessage={"Point"} />
                            </th>
                            {/* N */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.N"} defaultMessage={"N"} />
                            </th>
                            {/* E */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.E"} defaultMessage={"E"} />
                            </th>
                            {/* EL */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.EL"} defaultMessage={"El"} />
                            </th>
                            {/* CONNECTION TYPE */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.ConnectionType"} defaultMessage={"Connection Type"} />
                            </th>
                            <th colSpan="1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                {/* POINT QUANTITY */}
                                <td>
                                  <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    name="pointQuantity"
                                    rules={[
                                      { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                    ]}
                                  >
                                    <InputNumber
                                      style={{
                                        width: "100%"
                                      }}
                                      min={1}
                                      max={this.state.availableQuantity}
                                    />
                                  </Form.Item>
                                </td>
                                {/* N QUANTITY */}
                                <td>
                                  <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    name="nQuantity"
                                    rules={[
                                      { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                    ]}
                                  >
                                    <InputNumber
                                      style={{
                                        width: "100%"
                                      }}
                                      min={1}
                                      max={this.state.availableQuantity}
                                    />
                                  </Form.Item>
                                </td>
                                {/* E QUANTITY */}
                                <td>
                                  <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    name="eQuantity"
                                    rules={[
                                      { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                    ]}
                                  >
                                    <InputNumber
                                      style={{
                                        width: "100%"
                                      }}
                                      min={1}
                                      max={this.state.availableQuantity}
                                    />
                                  </Form.Item>
                                </td>
                                {/* EL QUANTITY */}
                                <td>
                                  <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    name="elQuantity"
                                    rules={[
                                      { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                    ]}
                                  >
                                    <InputNumber
                                      style={{
                                        width: "100%"
                                      }}
                                      min={1}
                                      max={this.state.availableQuantity}
                                    />
                                  </Form.Item>
                                </td>
                                {/* CONNECTION TYPE */}
                                <td>
                                  <Form.Item name="connectionTypeId">
                                    <Select
                                      allowClear
                                      showSearch
                                      style={{ width: "100%" }}
                                      placeholder=""
                                      onChange={value => {
                                        this.setState({ answerType: value });
                                      }}
                                    >
                                      {Boolean(this.state["connectionTypeIdSelectItems"]) &&
                                        this.state["connectionTypeIdSelectItems"].length > 0 &&
                                        this.state["connectionTypeIdSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                {/* MINUSCIRCLEOUTLINED */}
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}

                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"StocksTree.AddNewPersonnel"} defaultMessage={"Add New Coordinate "} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          </AntModal>
          {/* SUB STOCK COORDINATES MODAL */}
          <AntModal
            title={<FormattedMessage id="StocksTree.Coordinates" defaultMessage="Coordinates" />}
            keyboard={false}
            maskClosable={false}
            open={this.state.showStocksTreeSubStockCoordinatesModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showStocksTreeSubStockCoordinatesModal: false })}
            onOk={() => this.formRefStocksTreeSubStockCoordinates.current.submit()}
            width={800}
            centered
          >
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveStocksTreeSubStockCoordinates}
              red={this.formRefStocksTreeSubStockCoordinates}
            >
              <br></br>
              <br></br>
              <br />{" "}
              <h4>
                <FormattedMessage id={"StocksTree.Coordinates"} defaultMessage={"  "} />
              </h4>
              <Form.List name="coordinates">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            {/* POINT */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.Point"} defaultMessage={"Point"} />
                            </th>
                            {/* N */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.N"} defaultMessage={"N"} />
                            </th>
                            {/* E */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.E"} defaultMessage={"E"} />
                            </th>
                            {/* EL */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.EL"} defaultMessage={"El"} />
                            </th>
                            {/* CONNECTION TYPE */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.ConnectionType"} defaultMessage={"Connection Type"} />
                            </th>
                            <th colSpan="1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                {/* POINT QUANTITY */}
                                <td>
                                  <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    name="pointQuantity"
                                    rules={[
                                      { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                    ]}
                                  >
                                    <InputNumber
                                      style={{
                                        width: "100%"
                                      }}
                                      min={1}
                                      max={this.state.availableQuantity}
                                    />
                                  </Form.Item>
                                </td>
                                {/* N QUANTITY */}
                                <td>
                                  <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    name="nQuantity"
                                    rules={[
                                      { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                    ]}
                                  >
                                    <InputNumber
                                      style={{
                                        width: "100%"
                                      }}
                                      min={1}
                                      max={this.state.availableQuantity}
                                    />
                                  </Form.Item>
                                </td>
                                {/* E QUANTITY */}
                                <td>
                                  <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    name="eQuantity"
                                    rules={[
                                      { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                    ]}
                                  >
                                    <InputNumber
                                      style={{
                                        width: "100%"
                                      }}
                                      min={1}
                                      max={this.state.availableQuantity}
                                    />
                                  </Form.Item>
                                </td>
                                {/* EL QUANTITY */}
                                <td>
                                  <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    name="elQuantity"
                                    rules={[
                                      { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                    ]}
                                  >
                                    <InputNumber
                                      style={{
                                        width: "100%"
                                      }}
                                      min={1}
                                      max={this.state.availableQuantity}
                                    />
                                  </Form.Item>
                                </td>
                                {/* CONNECTION TYPE */}
                                <td>
                                  <Form.Item name="connectionTypeId">
                                    <Select
                                      allowClear
                                      showSearch
                                      style={{ width: "100%" }}
                                      placeholder=""
                                      onChange={value => {
                                        this.setState({ answerType: value });
                                      }}
                                    >
                                      {Boolean(this.state["connectionTypeIdSelectItems"]) &&
                                        this.state["connectionTypeIdSelectItems"].length > 0 &&
                                        this.state["connectionTypeIdSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                {/* MINUSCIRCLEOUTLINED */}
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}

                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"StocksTree.AddNewPersonnel"} defaultMessage={"Add New Coordinate "} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          </AntModal>
          {/* SUB AREA COORDINATES MODAL */}
          <AntModal
            title={<FormattedMessage id="StocksTree.Coordinates" defaultMessage="Coordinates" />}
            keyboard={false}
            maskClosable={false}
            open={this.state.showStocksTreeSubAreaCoordinatesModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showSubStoringAreaCoordinatesModal: false })}
            onOk={() => this.formRefSubAreaCoordinates.current.submit()}
            width={800}
            centered
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveStocksTreeSubAreaCoordinates} red={this.formRefSubAreaCoordinates}>
              <br></br>
              <br></br>
              <br />{" "}
              <h4>
                <FormattedMessage id={"StocksTree.Coordinates"} defaultMessage={"  "} />
              </h4>
              <Form.List name="coordinates">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            {/* POINT */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.Point"} defaultMessage={"Point"} />
                            </th>
                            {/* N */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.N"} defaultMessage={"N"} />
                            </th>
                            {/* E */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.E"} defaultMessage={"E"} />
                            </th>
                            {/* EL */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.EL"} defaultMessage={"El"} />
                            </th>
                            {/* CONNECTION TYPE */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.ConnectionType"} defaultMessage={"Connection Type"} />
                            </th>
                            <th colSpan="1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                {/* POINT QUANTITY */}
                                <td>
                                  <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    name="pointQuantity"
                                    rules={[
                                      { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                    ]}
                                  >
                                    <InputNumber
                                      style={{
                                        width: "100%"
                                      }}
                                      min={1}
                                      max={this.state.availableQuantity}
                                    />
                                  </Form.Item>
                                </td>
                                {/* N QUANTITY */}
                                <td>
                                  <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    name="nQuantity"
                                    rules={[
                                      { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                    ]}
                                  >
                                    <InputNumber
                                      style={{
                                        width: "100%"
                                      }}
                                      min={1}
                                      max={this.state.availableQuantity}
                                    />
                                  </Form.Item>
                                </td>
                                {/* E QUANTITY */}
                                <td>
                                  <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    name="eQuantity"
                                    rules={[
                                      { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                    ]}
                                  >
                                    <InputNumber
                                      style={{
                                        width: "100%"
                                      }}
                                      min={1}
                                      max={this.state.availableQuantity}
                                    />
                                  </Form.Item>
                                </td>
                                {/* EL QUANTITY */}
                                <td>
                                  <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    name="elQuantity"
                                    rules={[
                                      { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                    ]}
                                  >
                                    <InputNumber
                                      style={{
                                        width: "100%"
                                      }}
                                      min={1}
                                      max={this.state.availableQuantity}
                                    />
                                  </Form.Item>
                                </td>
                                {/* CONNECTION TYPE */}
                                <td>
                                  <Form.Item name="connectionTypeId">
                                    <Select
                                      allowClear
                                      showSearch
                                      style={{ width: "100%" }}
                                      placeholder=""
                                      onChange={value => {
                                        this.setState({ answerType: value });
                                      }}
                                    >
                                      {Boolean(this.state["connectionTypeIdSelectItems"]) &&
                                        this.state["connectionTypeIdSelectItems"].length > 0 &&
                                        this.state["connectionTypeIdSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                {/* MINUSCIRCLEOUTLINED */}
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}

                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"StocksTree.AddNewPersonnel"} defaultMessage={"Add New Coordinate "} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          </AntModal>
          {/* DeleteModal */}
          {/* DELETE STOCK */}
          <AntModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            open={this.state.showDeleteStocksTreeStockModal}
            onCancel={() => {
              this.setState({ showDeleteStocksTreeStockModal: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ showDeleteStocksTreeStockModal: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.deleteStocksTreeStock}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Space>
            ]}
          >
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </AntModal>
          {/* DELETE SUBSTOCK */}
          <AntModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            open={this.state.showDeleteStocksTreeSubStockModal}
            onCancel={() => {
              this.setState({ showDeleteStocksTreeSubStockModal: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ showDeleteStocksTreeSubStockModal: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.deleteStocksTreeSubStock}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Space>
            ]}
          >
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </AntModal>
          {/* DELETE SUBAREA */}
          <AntModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            open={this.state.showDeleteStocksTreeSubAreaModal}
            onCancel={() => {
              this.setState({ showDeleteStocksTreeSubAreaModal: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ showDeleteStocksTreeSubAreaModal: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.deleteStocksTreeSubArea}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Space>
            ]}
          >
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </AntModal>

          <AntModal
            title={<FormattedMessage id="StocksTree.CloseTreeModal" defaultMessage="" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            open={this.state.modalCloseTreeShow}
            onCancel={() => {
              this.setState({ modalCloseTreeShow: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ modalCloseTreeShow: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.openNumbersModal}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Yes" />
                </Button>
              </Space>
            ]}
          >
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure to close tree structure?" />
            </p>
          </AntModal>
          
          <DraggableModal
            title={<FormattedMessage id="StocksTree.NumbersModal" defaultMessage="Numbers" />}
            open={this.state.modalOpenNumbers}
            onCancel={() => {
              this.setState({ modalOpenNumbers: false });
            }}
            cancelButtonProps={{ hidden: false }}
            okButtonProps={{ hidden: true }}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            width={1200}
            centered
            content={
              <StocksTreeNumber random={this.state.random} bookCreatorId={this.state.id} type={this.state.type} />
            }
          />
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StocksTree);
