import React, { Component } from 'react';
import { connect } from 'react-redux';
import { handleRequest } from '../../ApiConnector';
import CustomMaterialMenu from '../CustomMenu/CustomMaterialMenu';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from "react-intl";
import { Form, Table, Input, Select, Radio, Tabs, Divider, Col, Row, Button as AntButton } from 'antd';
import 'antd/dist/antd.css';
import { Avatar } from 'antd';
import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { API_BASE_ROOT } from '../../ApiConnector';
import store from '../../../redux/store';
import CreateIcon from '@material-ui/icons/AddCircle';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { error, showError, showSuccess } from '../../MessageHelper';

const { Option } = Select;
const { TabPane } = Tabs;
function callback(key) {
    console.log(key);
}

export class PersonelInfo extends Component {

    constructor() {
        super();
        this.state = {

            modalDeleteShow: false,
            hiddenInputs: true,
            filePath: '',
            fileList: [],
            personelInfo: store.getState().auth.personelInfo,
            personelInfoId: null,
            companyId: null,
            companyLineAge: null,
            projectId: null,
            tableList: [],
            name: "",
            surname: "",
            email: "",
            role: "",
            personelInfoname: "",
            mode: 'top',

            companySelectItems: [],
            designationSelectItems: [],
            selectedOptions: [],
            photoPath: null,
            personnelType: null,
            budgeNo: null,
            countryId: null,
            nationalId: null,
            departmentId: null,
            designationId: null,
            codeName: null,

            countrySelectItems: [],
            departmentSelectItems: [],
            projectSelectItems: [],

            hideUpdate: true,
            hideSave: false,
            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['2', '10', '25', '50', '100', '1000', '10000', '100000', '1000000'],
            },
            loading: false,
        };



    }

    formRef = React.createRef();

    componentDidMount = async () => {

        const { pagination } = this.state;
        this.restartTable({ pagination });

        this.fillCountries();
        this.fillDepartments();
        this.fillcompanies();
        this.fillProjects();
        this.fillDesignations();

    };


    callback = async (key) => {

        console.log(key);
    }

    handleModeChange = e => {
        const mode = e.target.value;
        this.setState({ mode });
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters, });
    }

    fillProjects = async () => {

        let url4 = "/api/projects/authorized";

        const response4 = await handleRequest("GET", url4);

        if (response4.type === "ERROR") {
            error(response4);
        }
        else
            this.setState({
                projectSelectItems: Boolean(response4.data) ? response4.data : []
            });
    }

    fillCountries = async () => {

        let url = "/api/countries/selectItems";
        var response = await handleRequest("GET", url);
        if (response.type === "ERROR") {
            error(response);
        }
        else
            this.setState({
                countrySelectItems: Boolean(response.data) ? response.data : []
            });

    }

    cancel = () => {
        this.setState({
            hiddenInputs: true,
            hideUpdate: true,
            hideSave: false
        });
        this.resetInputs();
    };

    resetInputs = () => {

        this.formRef.current.setFieldsValue({
            name: "",
            surname: "",
            email: "",
            role: "",
            personelInfoname: "",
            photoPath: null,
            personnelType: null,
            budgeNo: null,
            countryId: null,
            nationalId: null,
            departmentId: null,
            designationId: null,
            codeName: null,
            companyId: null,
            projectId: null,
            companyLineAge: null,
        });

        this.setState({
            personelInfoId: null,
            name: "",
            surname: "",
            email: "",
            role: "",
            personelInfoname: "",
            photoPath: null,
            personnelType: null,
            budgeNo: null,
            countryId: null,
            nationalId: null,
            departmentId: null,
            designationId: null,
            codeName: null,
            filePath: null, fileList: [],
            companyId: null,
            projectId: null,
            companyLineAge: null,
        })
    }

    edit = (row) => {

        let cla = Boolean(row.companyLineAge) ? row.companyLineAge.split(",") : null;
        let companyLineAge = [];
        if (Boolean(cla)) {
            cla.forEach(element => {
                companyLineAge.push(parseInt(element));
            });
        }

        let fileList = [];

        if (Boolean(row.photoPath) && row.photoPath.length > 0)
            fileList = [{
                uid: 1,
                name: "",
                url: row.photoPath,
                thumbUrl: row.photoPath,
            }];

        this.formRef.current.setFieldsValue({
            name: row.name,
            surname: row.surname,
            email: row.email,
            role: row.role,
            companyLineAge: companyLineAge,
            personelInfoname: row.personelInfoname,
            photoPath: row.photoPath,
            personnelType: row.personnelType,
            budgeNo: row.budgeNo,
            countryId: row.countryId,
            nationalId: row.nationalId,
            departmentId: row.departmentId,
            designationId: row.designationId,
            codeName: row.codeName,
            companyId: row.companyId,
            projectId: row.projectId
        });

        this.setState({
            hideUpdate: false,
            hideSave: true,
            hiddenInputs: false,
            personelInfoId: row.id,
            name: row.name,
            surname: row.surname,
            email: row.email,
            role: row.role,
            personelInfoname: row.personelInfoname,
            photoPath: row.photoPath,
            personnelType: row.personnelType,
            budgeNo: row.budgeNo,
            countryId: row.countryId,
            nationalId: row.nationalId,
            departmentId: row.departmentId,
            designationId: row.designationId,
            codeName: row.codeName,
            filePath: row.photoPath, fileList: fileList,
            companyId: row.companyId,
            companyLineAge: companyLineAge,
            projectId: row.projectId,
        });

    };




    actionTemplate(row) {

        return <React.Fragment>
            <CustomMaterialMenu row={row} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;

    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            personelInfoId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    delete = async () => {

        const deletedItem = {
            id: this.state.personelInfoId
        }

        var response = await handleRequest("DELETE", "/api/personelInfos/" + deletedItem.id);


        if (response.type === "ERROR") {
            error(response);
        }
        else {
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.setState({
                personelInfoId: null,
                modalDeleteShow: false
            });
        }

    }


    restartTable = async (params = {}) => {

        this.setState({ loading: true });
        const newObj = this.createWhere(params);

        let url = "/api/personelInfos/paging";
        const response = await handleRequest("POST", url, newObj);
        if (response.type === "ERROR") {
            error(response);
            this.setState({
                loading: false,
                tableList: [],
                pagination: {
                    ...params.pagination,
                    total: 0,
                },
                sortField: "",
                sortOrder: "",
            });
        }
        else {
            if (response.data.length !== 0) {
                let list = response.data.data;
                list.forEach((element, index) => {
                    element.sn = index + 1;
                });
                this.setState({
                    loading: false,
                    tableList: list,
                    pagination: {
                        ...params.pagination,
                        total: response.data.totalRecords,
                    },
                    sortField: params.sortField,
                    sortOrder: params.sortOrder,
                });
            } else {
                this.setState({
                    loading: false,
                    tableList: [],
                    pagination: {
                        ...params.pagination,
                        total: 0,
                    },
                    sortField: "",
                    sortOrder: "",
                });
            }
        }
    };

    createWhere(params) {
        if (Boolean(params._pagination))
            params.pagination = params._pagination;
        var current = this.state.pagination.current;
        var pageSize = this.state.pagination.pageSize;
        var sortField = "", sortOrder = "";


        if (Boolean(params) && Boolean(params.pagination)) {
            current = params.pagination.current;
            pageSize = params.pagination.pageSize;


            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }

        }
        const newObj = {
            ...params.pagination,
            firstResult: current - 1, // (current - 1) * pageSize,
            maxResults: pageSize,
            sortField: sortField,
            sortOrder: sortOrder,
        };
        return newObj;
    }

    save = async () => {

        const newItem = {
            id: this.state.personelInfoId,
            name: this.state.name,
            surname: this.state.surname,
            email: this.state.email,
            role: this.state.role,
            personelInfoname: this.state.personelInfoname,
            photoPath: this.state.filePath,
            personnelType: this.state.personnelType,
            budgeNo: this.state.budgeNo,
            countryId: this.state.countryId,
            nationalId: this.state.nationalId,
            departmentId: this.state.departmentId,
            designationId: this.state.designationId,
            codeName: this.state.codeName,
            companyLineAge: Boolean(this.state.companyLineAge) ? this.state.companyLineAge.join() : null

        }

        if (newItem.id === null) {
            const response2 = await handleRequest("GET", "/api/personelInfos/controlpersonelInfoname/" + newItem.personelInfoname);

            if (response2.data === 0) {
                var response = await handleRequest("POST", "/api/personelInfos", newItem);
            }
            else {

                if (Boolean(response2.data) && response2.type === "ERROR" && response2.response.status === 403)
                    window.location.href = "/logout";
                else
                    showError("This personelInfoname added before please change personelInfoname.");
                return;
            }

        }
        else response = await handleRequest("PUT", "/api/personelInfos/" + newItem.id, newItem);


        if (response.type === "ERROR") {
            error(response);
        }
        else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
        }

    };


    addItem = async () => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: "department"
            }

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: '',
                });
                this.fillDepartments();
            }
        } else {
            showError("Please enter department");
        }
    };

    fillDepartments = async () => {
        const response = await handleRequest("GET", "/api/codes/type/department");
        if (Boolean(response.data)) {
            this.setState({
                departmentSelectItems: response.data
            });
        }
    }

    fillcompanies = async () => {

        let url = "/api/companies/tree";

        const response = await handleRequest("GET", url);

        if (response.type === "ERROR") {
            error(response);
        }
        else
            this.setState({
                companySelectItems: Boolean(response.data) ? response.data : []
            });

    }


    loadData = async (selectedOptions) => {

        if (Boolean(selectedOptions)) {

            const targetOption = selectedOptions[selectedOptions.length - 1];

            if (Boolean(targetOption)) {
                targetOption.loading = true;

                console.log(selectedOptions);
                console.log(targetOption);

                let url = "/api/companies/subcompanies/" + targetOption.value;

                const response = await handleRequest("GET", url);

                if (response.type === "ERROR") {
                    error(response);
                }
                else {
                    targetOption.loading = false;
                    targetOption.children = Boolean(response.data) ? response.data : [];
                    this.setState({
                        companySelectItems: [...this.state.companySelectItems]
                    });
                }
            }

        }


    };

    addDesignation = async () => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: "designation"
            }

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: '',
                });
                this.fillDesignations();
            }
        } else {
            showError("Please enter designation");
        }
    };

    fillDesignations = async () => {
        const response = await handleRequest("GET", "/api/codes/type/designation");
        if (Boolean(response.data)) {
            this.setState({
                designationSelectItems: response.data
            });
        }
    }

    onClickCreateNew = async () => {
        this.resetInputs();
        if (this.state.hiddenInputs) {
            this.setState({
                hiddenInputs: false,
                hiddenSave: false,
                hiddenUpdate: true,
            });
        }
        else {
            this.setState({
                hiddenInputs: true,
                hiddenSave: true,
                hiddenUpdate: false,
            });
        }
    }


    render() {

        const { mode } = this.state;

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout2 = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 }
        };

        const columns = [

            {
                title: 'Actions', key: 'name', render: (record) => {
                    return this.actionTemplate(record);
                }
            },

            {
                title: 'S/N', key: 'name', render: (value, row, index) => {

                    return index + 1;

                }
            },

            {
                title: 'Photo', key: 'photoPath', render: (value, row, index) => {

                    if (Boolean(row.photoPath))
                        return <Avatar shape="square" size={64} src={row.photoPath} />;
                    else return "";

                }
            },

            {
                title: 'Name', key: 'name', render: (record) => {

                    return record.name;
                }
            },
            {
                title: 'Surname', key: 'name', render: (record) => {

                    return record.surname;
                }
            },


            {
                title: 'Personnel Type', key: 'personnelType', render: (record) => {

                    return record.personnelType;
                }
            },
            {
                title: 'Budge No', key: 'budgeNo', render: (record) => {

                    return record.budgeNo;
                }
            },

            {
                title: 'Nationality', key: 'countryId', render: (record) => {

                    if (Boolean(record.country))
                        return record.country.countryName;
                }
            },

            {
                title: 'National Id', key: 'nationalId', render: (record) => {

                    return record.nationalId;
                }
            },
            {
                title: 'Department', key: 'departmentId', render: (record) => {

                    if (Boolean(record.department))
                        return record.department.name;
                }
            },

            {
                title: 'Designation', key: 'designationId', render: (record) => {

                    if (Boolean(record.designation))
                        return record.designation.name;
                }
            },

            {
                title: 'Email', key: 'name', render: (record) => {

                    return record.email;
                }
            },

            {
                title: 'Role', key: 'name', render: (record) => {

                    return record.role;
                }
            },



        ];

        const uploadButton = (
            <div>
                <PlusOutlined />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        const props = {
            action: `${API_BASE_ROOT}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };


        return (

            <div hidden={this.state.hideInputs} className="card card-custom" style={{ padding: '2rem' }}>
                <Tabs defaultActiveKey="1" onChange={callback}>

                    <TabPane tab="Promt Record" key="1">

                        <Form
                            initialValues={{ remember: false }}
                            onFinish={this.save}
                            onFinishFailed={onFinishFailed}
                            ref={this.formRef}
                        >
                            <Row>
                                <Col xs={20}></Col>
                                <Col md={1}>
                                    {/* {excelExport} */}
                                </Col>
                                <Col md={1}>

                                </Col>
                                <Col xs={2}>
                                    <AntButton hidden={!this.state.hiddenInputs} style={{ border: "0px" }} onClick={() => this.onClickCreateNew()} icon={<CreateIcon color="secondary" fontSize="large" />}></AntButton>
                                    <AntButton hidden={this.state.hiddenInputs} style={{ border: "0px" }} onClick={() => this.onClickCreateNew()} icon={<RemoveCircle color="error" fontSize="large" />}></AntButton>
                                </Col>
                            </Row>
                            <div hidden={this.state.hiddenInputs}>



                                {
                                    <Form.Item
                                        {...layout2}
                                        label={<FormattedMessage id="todo" defaultMessage="Name" />}
                                        name="name"
                                        rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type name" /> }]}
                                    >


                                        <Input placeholder="Name" style={{ width: '100%' }} id="todo" value={this.state.name} onChange={(e) => {

                                            this.setState({ name: e.target.value })
                                        }} />


                                    </Form.Item>
                                }


                                {
                                    <Form.Item
                                        {...layout2}
                                        label={<FormattedMessage id="todo" defaultMessage="Surname" />}
                                        name="surname"
                                        rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type surname" /> }]} >

                                        <Input placeholder="Surname" id="todo" value={this.state.surname} onChange={(e) => this.setState({ surname: e.target.value })} />


                                    </Form.Item>
                                }

                                {
                                    <Form.Item
                                        {...layout2}
                                        label={<FormattedMessage id="designationId" defaultMessage="Designation" />}
                                        name="designationId"
                                        rules={[{ required: false, message: <FormattedMessage id="designationId" defaultMessage="This field is required" /> }]} >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            style={{ width: "100%" }} placeholder="Designation" id="designationId"
                                            value={this.state.designationId}
                                            onChange={(value) => this.setState({ designationId: value })}
                                            dropdownRender={menu => (
                                                <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(e) => {

                                                            this.setState({
                                                                codeName: e.target.value
                                                            });

                                                        }} />
                                                        <a
                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                            onClick={this.addDesignation}
                                                        >
                                                            <PlusOutlined /> Add Designation
                                                        </a>
                                                    </div>
                                                </div>
                                            )}
                                        >
                                            <Option key={null} value={null}>Select</Option>
                                            {this.state.designationSelectItems.map(i => (
                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                }

                                {
                                    <Form.Item
                                        {...layout2}
                                        label={<FormattedMessage id="todo" defaultMessage="Photo" />}
                                        name="photoPath"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please upload file" /> }]}
                                    >
                                        <Upload {...props} id="path"
                                            showUploadList={true}
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            fileList={this.state.fileList}
                                            onChange={({ fileList }) => {
                                                if (fileList.length === 1) {
                                                    var filePath = "";
                                                    if (Boolean(fileList[0].response)) {
                                                        filePath = fileList[0].response.url;
                                                        showSuccess("file uploaded successfully");
                                                        // fileName: info.file.name, 
                                                    }
                                                    this.setState({
                                                        fileList: fileList,
                                                        filePath: filePath,
                                                    });
                                                }
                                            }}
                                            beforeUpload={(fileList) => {
                                                if (this.state.fileList.length > 0) {
                                                    showError("You have been already choose a file! For change firstly remove the uploaded file.");
                                                    return false;
                                                }
                                                return true;
                                            }}
                                            onRemove={({ fileList }) => {
                                                this.setState({
                                                    fileList: [],
                                                    filePath: "",
                                                })
                                            }
                                            }>
                                            {uploadButton}
                                        </Upload>
                                    </Form.Item>
                                }

                                {
                                    <Form.Item
                                        {...layout2}
                                        label={<FormattedMessage id="todo" defaultMessage="Personnel Type" />}
                                        name="personnelType"
                                        rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please select  personnel type" /> }]} >
                                        <Radio.Group onChange={(e) => {
                                            this.setState({
                                                personnelType: e.target.value,

                                            })


                                        }} value={this.state.assetType}>

                                            <Radio value={"COMPANY"}>{"Company"}</Radio>
                                            <Radio value={"RENTAL"}>{"Rental"}</Radio>

                                        </Radio.Group>
                                    </Form.Item>
                                }

                                {
                                    <Form.Item
                                        {...layout2}
                                        label={<FormattedMessage id="todo" defaultMessage="Budge No:" />}
                                        name="budgeNo"
                                        rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type budge no" /> }]} >

                                        <Input placeholder="Budge No" id="todo" value={this.state.budgeNo} onChange={(e) => this.setState({ budgeNo: e.target.value })} />


                                    </Form.Item>
                                }

                                {
                                    <Form.Item
                                        {...layout2}
                                        label={<FormattedMessage id="todo" defaultMessage="Nationality" />}
                                        name="countryId"
                                        rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please select country" /> }]} >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            onChange={(value) => {
                                                this.setState({
                                                    countryId: value,

                                                });

                                            }}>
                                            <Option key={null} value={null}>Select</Option>
                                            {
                                                this.state.countrySelectItems.map(i => <Option value={i.key}>{i.value}</Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                }

                                {
                                    <Form.Item
                                        {...layout2}
                                        label={<FormattedMessage id="todo" defaultMessage="Iqama No / National Id" />}
                                        name="nationalId"
                                        rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type budge no" /> }]} >

                                        <Input placeholder="Iqama No / National Id" id="todo" value={this.state.nationalId} onChange={(e) => this.setState({ nationalId: e.target.value })} />


                                    </Form.Item>
                                }
                                {
                                    <Form.Item
                                        {...layout2}

                                        label={<FormattedMessage id="todo" defaultMessage="Department" />}

                                        style={{
                                            marginBottom: 0,
                                        }}
                                        name="departmentId"
                                        rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="This field is required" /> }]} >
                                        <Row gutter={[8, 8]}>
                                            <Col span={12}>
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    style={{ width: "100%" }} placeholder="Department" id="DefinitionsType" value={this.state.departmentId} onChange={(value) => {
                                                        this.formRef.current.setFieldsValue({
                                                            departmentId: value
                                                        });
                                                        this.setState({ departmentId: value })
                                                    }}
                                                    dropdownRender={menu => (
                                                        <div>
                                                            {menu}
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(e) => {

                                                                    this.setState({
                                                                        codeName: e.target.value
                                                                    });

                                                                }} />
                                                                <a
                                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                    onClick={this.addItem}
                                                                >
                                                                    <PlusOutlined /> Add item
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )}

                                                >
                                                    <Option key={null} value={null}>Select</Option>
                                                    {this.state.departmentSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                        </Row>

                                    </Form.Item>
                                }
                                {
                                    <Form.Item
                                        {...layout2}
                                        label={<FormattedMessage id="todo" defaultMessage="Email" />}
                                        name="email"
                                        rules={[{
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        }, { required: true, message: <FormattedMessage id="todo" defaultMessage="Please type email" /> }]} >

                                        <Input placeholder="Email" id="todo" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value, personelInfoname: e.target.value })} />


                                    </Form.Item>
                                }

                                {
                                    <Form.Item
                                        {...layout2}
                                        label={<FormattedMessage id="todo" defaultMessage="Role" />}
                                        name="role"
                                        rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type role" /> }]} >


                                        <Select style={{ width: "100%" }} placeholder="Role" id="PressureInjuryMrnNo" value={this.state.role} onChange={(value) => {

                                            this.setState({ role: value })
                                        }}>
                                            <Option value={null}>Select</Option>
                                            {Boolean(this.state.personelInfo) && this.state.personelInfo.role === "ADMIN" && <Option value={"ADMIN"}>Admin</Option>}
                                            <Option value={"COMPANY_ADMIN"}>Company Admin</Option>
                                            <Option value={"USER"}>PersonelInfo</Option>

                                        </Select>

                                    </Form.Item>
                                }



                                <div hidden={this.state.hideSave}>
                                    {
                                        <Row gutter={8}>
                                            <Col span={8}></Col>

                                            <Col span={8}>
                                                {
                                                    <Button id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                        variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                                                }
                                            </Col>
                                        </Row>

                                    }
                                </div>



                                <div hidden={this.state.hideUpdate}>
                                    {
                                        <Row gutter={8}>
                                            <Col span={8}></Col>

                                            <Col span={4}>
                                                <Button id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                                            </Col>
                                            <Col span={4}>
                                                <Button id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                                            </Col>

                                        </Row>
                                    }
                                </div>
                            </div>
                        </Form>


                    </TabPane>


                    <TabPane tab="Normal Record" key="2">

                        <Row>
                            <Col xs={20}></Col>
                            <Col md={1}>
                                {/* {excelExport} */}
                            </Col>
                            <Col md={1}>

                            </Col>
                            <Col xs={2}>
                                <AntButton hidden={!this.state.hiddenInputs} style={{ border: "0px" }} onClick={() => this.onClickCreateNew()} icon={<CreateIcon color="secondary" fontSize="large" />}></AntButton>
                                <AntButton hidden={this.state.hiddenInputs} style={{ border: "0px" }} onClick={() => this.onClickCreateNew()} icon={<RemoveCircle color="error" fontSize="large" />}></AntButton>
                            </Col>
                        </Row>
                        <div hidden={this.state.hiddenInputs}>

                            <Radio.Group onChange={this.handleModeChange} value={mode} style={{ marginBottom: 8 }}>
                                {/* <Radio.Button value="top"></Radio.Button> */}

                            </Radio.Group>
                            <Tabs defaultActiveKey="1" tabPosition={mode} style={{ height: 220 }}>

                                <TabPane tab="Personel" key="3">
                                    Personel
                                </TabPane>

                                <TabPane tab="Contact" key="4">
                                    Contact
                                </TabPane>

                                <TabPane tab="Training" key="5">
                                    Training
                                </TabPane>

                                <TabPane tab="Contract" key="6">
                                    Contract
                                </TabPane>

                                <TabPane tab="Experiences " key="7">
                                    Experiences
                                </TabPane>

                                <TabPane tab="Education " key="8">
                                    Education
                                </TabPane>

                                <TabPane tab="Language Skills" key="9">
                                    Language Skills
                                </TabPane>

                                <TabPane tab="Bank Accounts" key="10">
                                    Bank Accounts
                                </TabPane>

                                <TabPane tab="Social and Hobbies" key="11">
                                    Social and Hobbies
                                </TabPane>

                            </Tabs>
                        </div>
                    </TabPane>

                </Tabs>




                <div style={{ marginTop: '2rem', marginBottom: '1rem' }} className="content-section implementation">
                    <Table
                        size="small"
                        bordered
                        scroll={{ y: 500, x: 900 }}
                        columns={columns}
                        dataSource={this.state.tableList}
                        loading={this.state.loading} onChange={this.handleTableChange}
                        pagination={this.state.pagination}
                    />

                </div>
                <Modal
                    show={this.state.modalDeleteShow}
                    onHide={this.showOrHideDeleteModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</Button>
                        <Button variant="danger" onClick={this.delete} >Delete</Button>
                    </Modal.Footer>
                </Modal>
            </div>


        );


    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonelInfo)