import { Button, Modal, notification } from "antd";
import objectPath from "object-path";
import React, { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { reducer } from "../../../../../app/modules/Auth/_redux/authRedux";
import SockJS from "sockjs-client";
import { API_BASE_ROOT, handleRequest } from "../../../../../app/ApiConnector";
import * as Stomp from "stompjs";
import store from "../../../../../redux/store";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { error, showSuccess } from "../../../../../app/MessageHelper";
import { ChatRoom } from "./ChatRoom";

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false
};

let timeout = 3000;
let timeoutCounter = 1;
const maxTimeoutCounter = 5;

export default function ChatDropdown() {
    const [isOpen, setIsOpen] = useState(false);
    const [chats, setChats] = useState([]);
    const [message, setMessage] = useState("");
    const [personnels, setPersonnels] = useState([]);
    const uiService = useHtmlClassService();
    const [state, setState] = React.useState({
        right: false,
    });

    const layoutProps = useMemo(() => {
        return {
            offcanvas: objectPath.get(uiService.config, "extras.chat.layout") === "offcanvas"
        };
    }, [uiService]);

    const fillPersonnels = async () => {
        const response = await handleRequest("GET", "/api/personnelLog");
        if (Boolean(response.data)) {
            setPersonnels(response.data);
        }
    };

    const fillChats = async () => {
        const response = await handleRequest("GET", "/api/chat");
        if (Boolean(response.data)) {
            setChats(response.data);
        }
    };

    const openChatNotification = e => {
        const key = e.id;

        const desc = (
            <>
                <p>{e.message}</p>
                <div className="navi-text">
                    <div className="text-muted"> {moment(e.activationTime).format("DD/MM/YYYY HH:mm")}</div>
                </div>
            </>
        );

        notification["info"]({
            message: e.title,
            description: desc,
            duration: 0,
            key
        });
    };

    const connect = () => {
        const token = reducer.authToken;
        const ws = new SockJS(`${API_BASE_ROOT}/socket`); //{`${API_BASE}/Staff/download/photo/`+row.id }
        const stompClient = Stomp.over(ws);

        stompClient.connect(
            { Authorization: "Bearer " + token },
            function (frame) {
                stompClient.subscribe("/user/" + store.getState().auth.user.id + "/", message => {
                    console.log(message.body);

                    // that.fillAlerts();
                    //that.getNotifications();
                    var data = JSON.parse(message.body);
                    openChatNotification(data);
                });
            },
            error => {
                timeoutCounter++;
                console.log("Trying to connect to websocket...");
                if (timeoutCounter < maxTimeoutCounter) {
                    setTimeout(connect, timeoutCounter * timeout);
                }
            }
        );
    };

    useEffect(() => {
        connect();
        fillChats();
        fillPersonnels();
    }, []);

    const sendMessage = async () => {
        const newItem = {
            message: message,
            personnelId: store.getState().auth.user.id,
            messageTime: moment().toDate(),
            onoff: 1
        }

        var response = await handleRequest("POST", "/api/chat", newItem);

        if (response.type === "ERROR") {
            error(response);
        } else {
            fillChats();
            showSuccess();
        }
    };

    const onMessageChange = e => {
        console.log(e.target.value);
        setMessage(e.target.value);
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, ["right"]: open });
    };

    return (
        <>
            {layoutProps.offcanvas && (
                <div className="topbar-item">
                    <div
                        className={`btn btn-icon btn-clean btn-lg mr-1 ${Boolean(chats) && chats.length > 0 ? "pulse pulse-danger" : ""}`} id="kt_notes_toggle">
                        <span className="svg-icon svg-icon-xl svg-icon-primary">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Chat1.svg")} />
                        </span>
                    </div>
                </div>
            )}
            {!layoutProps.offcanvas && (
                <div className="topbar-item">
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="user-notification-tooltip">Chat</Tooltip>}>
                        <Button
                            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                            icon={
                                <span className="svg-icon svg-icon-xl svg-icon-primary">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Chat1.svg")} />
                                </span>
                            }
                            onClick={toggleDrawer("right", true)}
                        ></Button>
                    </OverlayTrigger>
                </div>
            )}
            <ChatRoom state={state} setState={setState} toggleDrawer={toggleDrawer} />
        </>
    );
}
