import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import Item from "./Item";
import ItemTest from "./ItemTest";
const SortableItem = ({ id, idRight }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1
  };

  return (
    <li style={style} ref={setNodeRef} {...attributes} {...listeners}>
      {idRight === true ? <ItemTest id={id} /> : <Item id={id} />}
    </li>
  );
};

export default SortableItem;
