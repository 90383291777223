import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Design from "../Design";
import Procurement from "../Procurement";
import Manufacturing from "../Manufacturing";
import QualityControl from "../QualityControl";
import SiteConstruction from "../SiteConstruction";
import Budget from "../Budget";
import { Col, Row } from "antd";
import { ExportOutlined } from "@ant-design/icons";

//IMPORT
import CountyNumberOfProjects from "../CountyNumberOfProjects";
import CountyProjectTypeNumberOfProjects from "../CountyProjectTypeNumberOfProjects";
import ProjectTypeNumberOfProject from "../ProjectTypeNumberOfProject";
import DraggableModal from "../../../Components/DraggableModal";
import ProjectsProgress from "../projects/ProjectstProgress";

const ProjectsComponent = () => {
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <Row>
              <Col span={16}>
                <h5>
                  <FormattedMessage id="DashboardPage.ProjectProgress" defaultMessage="Projects Progresses" />
                </h5>
              </Col>
              <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                <ExportOutlined
                  style={{ fontSize: "150%" }}
                  onClick={() => {
                    setShowModal(true);
                    setModalTitle("Projects Progresses");
                    setModalBody(<ProjectsProgress />);
                  }}
                />
              </Col>
              <ProjectsProgress />
            </Row>
          </div>
        </div>
      </div>

      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <Row>
              <Col span={16}>
                <h5>
                  <FormattedMessage id="DashboardPage.CountyVsNumberOfProjects" defaultMessage="County-Number of Projects" />
                </h5>
              </Col>
              <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                <ExportOutlined
                  style={{ fontSize: "150%" }}
                  onClick={() => {
                    setShowModal(true);
                    setModalTitle("County vs Number of Projects");
                    setModalBody(<CountyNumberOfProjects />);
                  }}
                />
              </Col>
            </Row>
            <CountyNumberOfProjects />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <Row>
              <Col span={16}>
                <h5>
                  <FormattedMessage id="DashboardPage.CountyVsProjectType" defaultMessage="Project Type-Number of Projects" />
                </h5>{" "}
              </Col>
              <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                <ExportOutlined
                  style={{ fontSize: "150%" }}
                  onClick={() => {
                    setShowModal(true);
                    setModalTitle("Project Type vs Number of Projects");
                    setModalBody(<ProjectTypeNumberOfProject />);
                  }}
                />
              </Col>
            </Row>
            <ProjectTypeNumberOfProject />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <Row>
              <Col span={16}>
                <h5>
                  <FormattedMessage id="DashboardPage.ProjectTypeVsNumberOfProjects" defaultMessage="Project Type vs Number of Projects" />
                </h5>
              </Col>
              <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                <ExportOutlined
                  style={{ fontSize: "150%" }}
                  onClick={() => {
                    setShowModal(true);
                    setModalTitle("Project Type vs Number of Projects");
                    setModalBody(<CountyProjectTypeNumberOfProjects />);
                  }}
                />
              </Col>
            </Row>
            <CountyProjectTypeNumberOfProjects />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <h5>
              <FormattedMessage id="DashboardPage.ProjectSummary" defaultMessage="Project Summary" />
            </h5>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <h5>
              <FormattedMessage id="DashboardPage.Design" defaultMessage="Design" />
            </h5>
            <Design />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <h5>
              <FormattedMessage id="DashboardPage.Procurement" defaultMessage="Procurement" />
            </h5>
            <Procurement />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <h5>
              <FormattedMessage id="DashboardPage.Manufacturing" defaultMessage="Manufacturing" />
            </h5>
            <Manufacturing />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <h5>
              <FormattedMessage id="DashboardPage.QualityControl" defaultMessage="Quality Control" />
            </h5>
            <QualityControl />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <h5>
              <FormattedMessage id="DashboardPage.SiteConstruction" defaultMessage="Site Construction" />
            </h5>
            <SiteConstruction />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <h5>
              <FormattedMessage id="DashboardPage.Budget" defaultMessage="Budget" />
            </h5>
            <Budget />
          </div>
        </div>
      </div>

      <DraggableModal
        title={modalTitle}
        closable={true}
        onCancel={() => {
          setShowModal(false);
        }}
        okButtonProps={{ hidden: false }}
        cancelButtonProps={{ hidden: false }}
        open={showModal}
        width={"55svw"}
        content={<div style={{ height: "55svh" }}>{modalBody}</div>}
        footer={[]}
      />
    </>
  );
};

export default ProjectsComponent;
