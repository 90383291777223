import axios from "axios";
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { API_BASE_ROOT } from "../../../../../app/ApiConnector";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";

export function getcompanies() {
  let url = API_BASE_ROOT + "/api/companies/authorized/tree";
  return axios.get(url);
}

export function HeaderMenu({ layoutProps }) {
  const [companies, setCompanies] = useState(null);

  const location = useLocation();
  const getMenuItemActive = url => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  const addChildren = children => {
    return (
      <ul className="menu-subnav">
        {" "}
        {Boolean(children) &&
          children.length > 0 &&
          children.map(child =>
            child.company ? (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive("/google-material/navigation")}`}
                data-menu-toggle="hover"
                aria-haspopup="true"
                key={child.value}
              >
                <NavLink
                  className="menu-link"
                  to={"/dashboard/" + child.value}
                  onClick={async () => {
                    await axios.put(API_BASE_ROOT + "/api/users/setCompany", child);
                  }}
                >
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-from-left.svg")} />
                  </span>
                  <span className="menu-text">{child.label}</span>
                  <i className="menu-arrow" />
                </NavLink>
                {Boolean(child.children) && child.children.length > 0 && (
                  <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>{addChildren(child.children)}</div>
                )}
              </li>
            ) : (
              <li className={`menu-item ${getMenuItemActive("/dashboard/" + child.value)}`} key={child.value}>
                <NavLink
                  className="menu-link menu-toggle"
                  to={"/dashboard/" + child.value}
                  onClick={async e => {
                    e.preventDefault();
                  }}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span
                    className="menu-text"
                    onClick={async () => {
                      await axios.put(API_BASE_ROOT + "/api/users/setProject", child).then(() => {
                        window.location.reload(false);
                      });
                    }}
                  >
                    {child.label}
                  </span>
                </NavLink>
              </li>
            )
          )}{" "}
      </ul>
    );
  };

  useEffect(() => {
    if (!Boolean(companies))
      getcompanies()
        .then(({ data }) => {
          setCompanies(data);
          return data;
        })
        .catch(() => {
          setCompanies(null);
          return null;
        });
  });

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-rel ${getMenuItemActive("/dashboard")}`}>
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-text">Dashboard</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*Classic submenu*/}
        {/*begin::1 Level*/}
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive("/google-material")}`}
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="menu-text">Company/Project</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              {Boolean(companies) &&
                companies.length > 0 &&
                companies.map(company => (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive("/google-material/navigation")}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                    key={company.value}
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to={"/dashboard/" + company.value}
                      onClick={async e => {
                        e.preventDefault();
                      }}
                    >
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-from-left.svg")} />
                      </span>
                      <span
                        className="menu-text"
                        onClick={async () => {
                          await axios.put(API_BASE_ROOT + "/api/users/setCompany", company).then(() => {
                            window.location.reload(false);
                          });
                        }}
                      >
                        {company.label}
                      </span>
                      <i className="menu-arrow" />
                    </NavLink>
                    {Boolean(company.children) && company.children.length > 0 && (
                      <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>{addChildren(company.children)}</div>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
