import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Checkbox, Col, Row, Modal, Tree, DatePicker } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "react-bootstrap";

export class MaintenancePlanningGanttDetail extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "maintenancePlanningGanttDetail",
      controllerName: "maintenancePlanning",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      checkedKeys: [],
      halfCheckedKeys: [],
      nodes: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();
  formRefStartEndDate = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = (prevProps) => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    this.fillStructure();
    this.fillComboboxes();
  }

  fillComboboxes = async () => {

  };

  fillStructure = async () => {
    console.log("data", this.props.data);
    this.formRef.current.resetFields();
    if (Boolean(this.props.data)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/structures", this.props.data);
      if (response.type === "ERROR") {
        error(response);
      } else {
        let structures = Boolean(response.data) && response.data.length > 0 ? response.data : [];
        this.setState({
          structures: structures,
          structure: false
        });
        this.formRef.current.setFieldsValue({
          structures: structures
        });
      }
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      this.fillComboboxes();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);
    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    console.log("values", values);
    if (values.structure) {
      if (Boolean(values.structures) && values.structures.length > 0) {
        let structureChecked = false;
        let structure = [];
        values.structures.forEach(element => {
          if (element.structureChecked) {
            if (element.child) {
              structure.push({
                structure: "",
                "subStructure": element.name,
                apartmentId: this.props.data.apartmentId,
                apartmentSectionId: this.props.data.apartmentSectionId,
                apartmentRoomId: this.props.data.apartmentRoomId,
                id: this.props.data.roomQuantityId,
                houseTypeId: this.props.data.houseTypeId,
                roomTypeId: this.props.data.roomTypeId,
                apartmentFloorId: this.props.data.apartmentFloorId,
                apartmentHouseId: this.props.data.apartmentHouseId,
                structureId: this.props.data.structureId,
                subStructureId: this.props.data.subStructureId,
                roomUnitId: this.props.data.roomUnitId,
                roomQuantityItemId: this.props.data.roomQuantityItemId,
              });
            } else {
              structure.push({
                structure: element.name,
                "subStructure": "",
                apartmentId: this.props.data.apartmentId,
                apartmentSectionId: this.props.data.apartmentSectionId,
                apartmentRoomId: this.props.data.apartmentRoomId,
                id: this.props.data.roomQuantityId,
                houseTypeId: this.props.data.houseTypeId,
                roomTypeId: this.props.data.roomTypeId,
                apartmentFloorId: this.props.data.apartmentFloorId,
                apartmentHouseId: this.props.data.apartmentHouseId,
                structureId: this.props.data.structureId,
                subStructureId: this.props.data.subStructureId,
                roomUnitId: this.props.data.roomUnitId,
                roomQuantityItemId: this.props.data.roomQuantityItemId,
              });
            }
            structureChecked = true;
          }
        });

        if (structureChecked) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/structureSubStructureList", structure);
          if (response.type === "ERROR") {
            error(response);
          } else {
            this.setState({
              modalDetailShow: true,
              checkedKeys: [],
              halfCheckedKeys: [],
              checkedNodes: [],
              selectedStructure: structure,
              nodes: Boolean(response.data) ? response.data : []
            });
          }
        } else {
          showWarning("Please select structure item");
        }
      } else {
        showWarning("Please select structure item");
      }
    } else {
      showWarning("Please select structure");
    }
  };

  saveCheckedItems = async () => {
    const { checkedKeys } = this.state;
    if (Boolean(checkedKeys) && checkedKeys.length > 0) {
      this.setState({
        modalStartEndDateShow: true,
      });
    } else {
      showWarning("Please select item.");
    }
  };

  saveStartEndDate = async values => {
    const { checkedKeys, halfCheckedKeys, checkedNodes, selectedStructure } = this.state;
    console.log("checkedKeys", checkedKeys);
    console.log("halfCheckedKeys", halfCheckedKeys);
    console.log("checkedNodes", checkedNodes);
    console.log("selectedStructure", selectedStructure);
    console.log("values", values);
    if (Boolean(checkedNodes) && checkedNodes.length > 0) {
      if (Boolean(values.startDate) && Boolean(values.endDate)) {
        let list = [];
        let selectedStructures = [];
        selectedStructure.forEach(element => {
          if(Boolean(element.structure)){
            selectedStructures.push(element.structure);
          }
          if(Boolean(element.subStructure)){
            selectedStructures.push(element.subStructure);
          }
        });
        checkedNodes.forEach(element => {
          let value = element.value;
          if (element.label === "Apartment") {
            list.push({
              "startDate": values.startDate, "endDate": values.endDate,
              "apartmentId": value,
              "selectedStructures": selectedStructures.join(", "),
            });
          } else if (element.label === "ApartmentSection") {
            list.push({
              "startDate": values.startDate, "endDate": values.endDate,
              "apartmentSectionId": value,
              "selectedStructures": selectedStructures.join(", "),
            });
          } else if (element.label === "ApartmentRoom") {
            list.push({
              "startDate": values.startDate, "endDate": values.endDate,
              "apartmentRoomId": value,
              "selectedStructures": selectedStructures.join(", "),
            });
          } else if (element.label === "RoomQuantity") {
            list.push({
              "startDate": values.startDate, "endDate": values.endDate,
              "roomQuantityId": value,
              "selectedStructures": selectedStructures.join(", "),
            });
          } else if (element.label === "ApartmentFloor") {
            list.push({
              "startDate": values.startDate, "endDate": values.endDate,
              "apartmentFloorId": value,
              "selectedStructures": selectedStructures.join(", "),
            });
          } else if (element.label === "ApartmentHouse") {
            list.push({
              "startDate": values.startDate, "endDate": values.endDate,
              "apartmentHouseId": value,
              "selectedStructures": selectedStructures.join(", "),
            });
          } else if (element.label === "HouseType") {
            list.push({
              "startDate": values.startDate, "endDate": values.endDate,
              "houseTypeId": value,
              "selectedStructures": selectedStructures.join(", "),
            });
          } else if (element.label === "RoomType") {
            list.push({
              "startDate": values.startDate, "endDate": values.endDate,
              "roomTypeId": value,
              "selectedStructures": selectedStructures.join(", "),
            });
          } else if (element.label === "RoomUnit") {
            list.push({
              "startDate": values.startDate, "endDate": values.endDate,
              "roomUnit": value,
              "selectedStructures": selectedStructures.join(", "),
            });
          } else if (element.label === "Structure") {
            list.push({
              "startDate": values.startDate, "endDate": values.endDate,
              "structure": value,
              "selectedStructures": selectedStructures.join(", "),
            });
          } else if (element.label === "SubStructure") {
            list.push({
              "startDate": values.startDate, "endDate": values.endDate,
              "subStructure": value,
              "selectedStructures": selectedStructures.join(", "),
            });
          } else if (element.label === "RoomQuantityItem") {
            list.push({
              "startDate": values.startDate, "endDate": values.endDate,
              "roomQuantityItemId": value,
              "selectedStructures": selectedStructures.join(", "),
            });
          }
        });

        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveMaintenancePlanningDetail", list);
          if (response.type === "ERROR") {
            error(response);
          } else {
            showSuccess();
            this.setState({
              modalDetailShow: false,
              modalStartEndDateShow: false,
            });
            this.props.modalDetailHide();
          }
        } else {
          showWarning("Not found.");
        }
      } else {
        showWarning("Please select start date or end date.");
      }
    } else {
      showWarning("Please select item.");
    }
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  timelineModal = async row => {

  };

  onCheck = (checkedKeys, e) => {
    console.log("e", e);
    console.log("onCheck", checkedKeys);
    console.log("halfCheckedKeys", e.halfCheckedKeys);
    console.log(e);



    this.setState({
      checkedKeys: checkedKeys,
      halfCheckedKeys: e.halfCheckedKeys,
      checkedNodes: e.checkedNodes,
    });
  }

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layoutChild = {
      labelCol: { span: 9 },
      wrapperCol: { span: 8 }
    };

    const layoutChildChild = {
      labelCol: { span: 10 },
      wrapperCol: { span: 8 }
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Form.Item
            {...layout}
            label={<FormattedMessage id="MaintenancePlanningGanttDetail.Discipline" defaultMessage="Discipline" />}
            name="discipline"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            valuePropName="checked"
          >
            <Checkbox onChange={(e) => {
              this.setState({
                discipline: e.target.checked
              });
            }} />
          </Form.Item>

          {this.state.discipline &&
            <>

            </>
          }

          <Form.Item
            {...layout}
            label={<FormattedMessage id="MaintenancePlanningGanttDetail.Structure" defaultMessage="Structure" />}
            name="structure"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            valuePropName="checked"
          >
            <Checkbox onChange={(e) => {
              this.setState({
                structure: e.target.checked
              });
            }} />
          </Form.Item>

          {this.state.structure &&
            <>
              <Form.List name="structures">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map(field => {
                        return (
                          <>
                            {
                              this.state.structures[field.name].child ?
                                <Form.Item
                                  {...layoutChildChild}
                                  label={this.state.structures[field.name].name}
                                  name={[field.name, "structureChecked"]}
                                  valuePropName="checked"  >
                                  <Checkbox />
                                </Form.Item>
                                :
                                <Form.Item
                                  {...layoutChild}
                                  label={this.state.structures[field.name].name}
                                  name={[field.name, "structureChecked"]}
                                  valuePropName="checked"  >
                                  <Checkbox />
                                </Form.Item>
                            }
                          </>
                        );
                      })}
                    </>
                  );
                }}
              </Form.List>
            </>
          }
          {
            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Button id="CreateButton"
                  type="submit"
                  style={{ width: "100%" }}
                  variant="primary">
                  <FormattedMessage id="GeneralButtonCreate"
                    defaultMessage="Create" />
                </Button>
              </Col>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            </Row>
          }
        </Form>

        <Modal
          title={<FormattedMessage id="MaintenancePlanningGanttDetail.Detail" defaultMessage="Detail" />}
          closable="false"
          open={this.state.modalDetailShow}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({
              modalDetailShow: false
            });
          }}
          width={800}
          centered
        >
          <Tree
            style={{ width: "100%" }}
            checkable
            showLine={true}
            onCheck={this.onCheck}
            checkedKeys={this.state.checkedKeys}
            halfCheckedKeys={this.state.halfCheckedKeys}
            treeData={this.state.nodes}
          />

          <br /> <br />
          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Button id="CreateButton"
                onClick={this.saveCheckedItems}
                style={{ width: "100%" }}
                variant="primary">
                <FormattedMessage id="GeneralButtonCreate"
                  defaultMessage="Create" />
              </Button>
            </Col>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
          </Row>
        </Modal>

        <Modal
          title={<FormattedMessage id="MaintenancePlanningGanttDetail.StartEndDate" defaultMessage="Start-End Date" />}
          closable="false"
          open={this.state.modalStartEndDateShow}
          okButtonProps={{ hidden: false }}
          cancelButtonProps={{ hidden: true }}
          onOk={() => this.formRefStartEndDate.current.submit()}
          onCancel={() => {
            this.setState({
              modalStartEndDateShow: false
            });
          }}
          width={600}
          centered
        >
          <Form initialValues={{ remember: false }} onFinish={this.saveStartEndDate} ref={this.formRefStartEndDate}>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="MaintenancePlanningGanttDetail.StartDate" defaultMessage="Start Date" />}
              name="startDate"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <DatePicker showTime format="DD-MM-YYYY HH:mm" style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="MaintenancePlanningGanttDetail.EndDate" defaultMessage="End Date" />}
              name="endDate"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <DatePicker showTime format="DD-MM-YYYY HH:mm" style={{ width: "100%" }} />
            </Form.Item>

          </Form>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(MaintenancePlanningGanttDetail);