import { EditOutlined } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Button as AntButton, Col, Form, Row, Select, Table, Modal as AntModal } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import DataTableComponent2 from "../../Components/DataTableComponent2";
import { GetDynamicFormItems, PrepareRowForEdit, PrepareRowForSave } from "../../Components/ElementUtils";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { ExcelReader } from "../excel/ExcelReader";
import { CashRequestTransferTo } from "./CashRequestTransferTo";
import ElementComponent from "../../Components/Element";
import CommentIcon from "@material-ui/icons/Comment";

const { Option } = Select;

const generateItemList = data => {
  let itemList = [];
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    itemList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children && node.children.length > 0) {
      itemList.push(...generateItemList(node.children));
    }
  }
  return itemList;
};
export class CashRequest extends Component {
  constructor() {
    super();
    this.state = {
      formName: "CashRequest",
      controllerName: "cashRequest",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      tableList: [],
      restartTable: false,
      pageFormData: null,
      excelData: [],
      createWhereParams: null,
      refreshForm: false,
      requestTypeSelectList: [],
      selectedRowKeys: [],
      detailList: [],
      paginationDetail: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    let data = await GetDynamicFormItems(this.state.formName);
    this.setState({
      pageFormData: data
    });

    this.fillComboboxes(data.pageFormInputCompanyList);
  };
  componentDidUpdate = async nextProps => { };
  fillComboboxes = async inputList => {
    inputList.forEach(async (item, index) => {
      if (item.inputType === "CASCADER") {
        let url = item.loadUrl;
        var response = await handleRequest("GET", url);
        if (response.type === "ERROR") {
          showError(response);
        } else {
          let list = generateItemList(response.data);
          this.setState({
            [item.inputName + "Cascader"]: list
          });
        }
      }
    });

    let urlCashRequestType = "/api/" + this.state.controllerName + "/cashRequestType";
    const responseCashRequestType = await handleRequest("GET", urlCashRequestType);
    if (responseCashRequestType.type === "ERROR") {
      error(responseCashRequestType);
    } else {
      this.setState({
        requestTypeSelectList: Boolean(responseCashRequestType.data) ? responseCashRequestType.data : [],
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null
    });
  };

  setData = data => {
    this.setState({
      tableList: data
    });
  };

  edit = row => {
    let handledValues = {
      ...row
    };
    handledValues = PrepareRowForEdit(handledValues, this.state.pageFormData.pageFormInputCompanyList);
    this.state.pageFormData.pageFormInputCompanyList.forEach(item => {
      if (item.inputType === "CASCADER" && this.state[item.inputName + "Cascader"]) {
        let itemId = this.state[item.inputName + "Cascader"].find(p => p.key === row[item.inputName]);
        if (itemId) {
          handledValues[item.inputName] = itemId.lineage;
        }
      }
    });
    this.formRef.current.setFieldsValue({
      ...handledValues
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      requestType: row.requestType
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async () => {
    this.setState({ restartTable: !this.state.restartTable });
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    let newItem = {
      id: this.state.id,
      ...values
    };
    newItem = PrepareRowForSave(newItem, this.state.pageFormData.pageFormInputCompanyList);

    var cashRequestTransferTo = [];
    if (Boolean(this.state.selectedRowKeys) && this.state.selectedRowKeys.length > 0) {
      this.state.selectedRowKeys.forEach(element => {
        cashRequestTransferTo.push({
          ["cashTransferToId"]: element,
        });
      });
    }
    newItem.cashRequestTransferTo = cashRequestTransferTo;

    if (!Boolean(newItem.id)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      showError(response);
    } else {
      this.cancel();
      this.restartTable();
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          this.state.pageFormData.pageFormInputCompanyList.forEach(input => {
            Boolean(input.tableColumnValue) ? (obj[input.tableColumnValue] = element[input.label]) : (obj[input.inputName] = element[input.label]);
          });
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            this.restartTable();
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  action = record => {
    return (
      Boolean(record.requestType) && record.requestType === "TRANSFER_TO_CASH" ?
        <React.Fragment>
          <DynamicActionMenu
            row={record}
            actionList={[
              {
                name: "Edit",
                icon: <EditOutlined fontSize="small" color="primary" />,
                actionClick: this.edit.bind(this)
              },
              {
                name: "Delete",
                icon: <DeleteIcon fontSize="small" color="error" />,
                actionClick: this.deleteModal.bind(this)
              },
              {
                name: "Transfer to Cash",
                icon: <CommentIcon fontSize="small" color="secondary" />,
                actionClick: this.detailModal.bind(this)
              }
            ]}
          />
        </React.Fragment>
        :
        <React.Fragment>
          <DynamicActionMenu
            row={record}
            actionList={[
              {
                name: "Edit",
                icon: <EditOutlined fontSize="small" color="primary" />,
                actionClick: this.edit.bind(this)
              },
              {
                name: "Delete",
                icon: <DeleteIcon fontSize="small" color="error" />,
                actionClick: this.deleteModal.bind(this)
              }
            ]}
          />
        </React.Fragment>
    );
  };

  detailModal = async (row) => {
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/transferToCashs", row);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        detailList: Boolean(response.data) && response.data.length > 0 ? response.data : [],
        modalDetailShow: true,
      });
    }
  };

  rowSelection = (selectedRowKeys) => {
    this.setState({
      selectedRowKeys: selectedRowKeys
    });
  }

  handleTableChangeDetail = (paginationDetail, filters, sorter) => {
    this.setState({
      paginationDetail: paginationDetail,
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    const columnsDetail = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.paginationDetail.pageSize * (this.state.paginationDetail.current - 1);
        }
      },
      {
        title: <FormattedMessage id="CashRequest.transferTo" defaultMessage="To" />,
        key: "transferTo",
        render: record => {
          return record.transferTo;
        },
      },
      {
        title: <FormattedMessage id="CashRequest.amount" defaultMessage="Amount" />,
        key: "amount",
        render: record => {
          return record.amount;
        },
      },
      {
        title: <FormattedMessage id="CashRequest.reason" defaultMessage="Reason" />,
        key: "reason",
        render: record => {
          return record.reason;
        },
      },
      {
        title: <FormattedMessage id="CashRequest.date" defaultMessage="Date" />,
        key: "date",
        render: record => {
          return Boolean(record.date) ? moment(record.date).format("DD-MM-YYYY HH:mm")
            : "";
        },
      },
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename={this.state.formName} hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
              {Boolean(this.state.pageFormData) &&
                this.state.pageFormData.pageFormInputCompanyList.map(item => {
                  return (
                    <ExcelColumn
                      key={item.inputName}
                      label={item.label}
                      value={Boolean(item.tableColumnValue) ? item.tableColumnValue : item.inputName}
                    />
                  );
                })}
            </ExcelSheet>
          </ExcelFile>
        ) : (
            <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
          )} */}
      </>
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col md={21}>
            <span className="h3">
              <FormattedMessage
                id={this.state.controllerName + ".pageTitle"}
                defaultMessage={Boolean(this.state.pageFormData) ? this.state.pageFormData.pageForm.title : ""}
              />
            </span>
          </Col>
          <Col md={1}>{excelExport}</Col>
          <Col md={1}>
            <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
          </Col>
          <Col md={1}>
            <AntButton
              style={{ border: "0px" }}
              hidden={!this.state.hideInputs}
              onClick={this.onClickCreateNew}
              icon={<CreateIcon color="secondary" fontSize="large" />}
            ></AntButton>
            <AntButton
              style={{ border: "0px" }}
              hidden={this.state.hideInputs}
              onClick={this.onClickCreateNew}
              icon={<RemoveCircle color="error" fontSize="large" />}
            ></AntButton>
          </Col>
        </Row>
        <div hidden={this.state.hideInputs}>
          <Form initialValues={{ remember: false, requestType: null }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
              <>
                {(Boolean(this.state.pageFormData.pageFormInputCompanyList) && this.state.pageFormData.pageFormInputCompanyList.length > 0) ?
                  this.state.pageFormData.pageFormInputCompanyList.map((field, i) =>
                    field.inputName === "requestType" ?
                      <>
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="CashRequest.requestType" defaultMessage={"Request Type"} />}
                          name="requestType"
                          rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select
                            allowClear
                            showSearch
                            style={{ width: "100%" }} placeholder="Request Type" id="requestType"
                            value={this.state.requestType} onChange={(value) => {
                              this.setState({ requestType: value })
                            }}>
                            {this.state.requestTypeSelectList.map(i => (
                              <Option value={i.key}>{i.value}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </>
                      :
                      <>
                        {Boolean(this.state.requestType) && this.state.requestType === "PERSONAL" &&
                          <ElementComponent key={i} field={field} layout={layout}
                            form={this.formRef} refresh={this.state.refreshForm} />}
                      </>
                  )
                  : null}

                {Boolean(this.state.requestType) && this.state.requestType === "TRANSFER_TO_CASH" &&
                  <CashRequestTransferTo rowSelection={this.rowSelection} />}
              </>
            ) : null}
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </Form>
        </div>
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
            <DataTableComponent2
              key="dataTable"
              field={this.state.pageFormData.pageForm}
              inputs={this.state.pageFormData.pageFormInputCompanyList}
              actions={this.action}
              editRow={this.edit.bind(this)}
              restartTable={this.state.restartTable}
              createWhereParams={this.state.createWhereParams}
              setData={this.setData.bind(this)}
            />
          ) : null}
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <AntModal
          centered
          width={1500}
          title="Transfer to Cash"
          visible={this.state.modalDetailShow}
          onCancel={() => {
            this.setState({ modalDetailShow: false });
          }}
          footer={[
            <Button variant="secondary" onClick={() => {
              this.setState({
                modalDetailShow: false
              });
            }}>Close</Button>
          ]}
        >
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columnsDetail}
              dataSource={this.state.detailList}
              loading={this.state.loading}
              onChange={this.handleTableChangeDetail}
              pagination={this.state.paginationDetail}
            />
          </div>
        </AntModal>

      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CashRequest);