import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Select, Input, DatePicker, InputNumber, Skeleton, Steps, Cascader, Radio } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import { Modal as AntModal } from "antd";
import { FilterOutlined, CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import moment from "moment";
import { excelExport } from "../../../ExcelHelper";

const { Step } = Steps;
const { Option } = Select;
const SGKTaban = 20002.5;
const SGKTavan = 151018.9;

//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    dataIndex === "endDate" || dataIndex === "startDate"
      ? form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? moment(record[dataIndex], "DD-MM-YYYY HH:mm") : ""
      })
      : form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
      });
  };

  const toggleSave = async () => {
    try {
      if (dataIndex === "endDate") {
        const values = await form.validateFields();
        values.endDate = Boolean(values.endDate) ? moment(values.endDate).format("DD-MM-YYYY HH:mm") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else if (dataIndex === "startDate") {
        const values = await form.validateFields();
        values.startDate = Boolean(values.startDate) ? moment(values.startDate).format("DD-MM-YYYY HH:mm") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record }, { ...values });
      }
    } catch (errInfo) { }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <>
        {dataIndex === "deliveryPlace" ? (
          <>
            <Form.Item
              label={<FormattedMessage id="EquipmentLogAssign.town" defaultMessage="Town" />}
              name="town"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Form.Item
              label={<FormattedMessage id="EquipmentLogAssign.city" defaultMessage="City" />}
              name="city"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Button id="SaveButton" onClick={toggleSave} style={{ width: "100%" }} variant="success">
              <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
            </Button>
          </>
        ) : (
          <Form.Item
            style={{
              margin: 0
            }}
            name={dataIndex}
          >
            {dataIndex === "endDate" || dataIndex === "startDate" ? (
              <DatePicker ref={inputRef} onBlur={toggleSave} format={"DD-MM-YYYY HH:mm"} showTime={{ format: "HH:mm" }} />
            ) : dataIndex === "currency" ? (
              <Select ref={inputRef} onChange={toggleSave} onBlur={toggleSave} showSearch optionFilterProp="children" >
                {Boolean(record.currencySelectItems) &&
                  record.currencySelectItems.length > 0 &&
                  record.currencySelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            ) : dataIndex === "unit" ? (
              <Cascader
                style={{ width: "100%" }}
                options={record.unitSelectItems}
                ref={inputRef} onChange={toggleSave}
                onBlur={toggleSave}
              />
            ) : (
              <InputNumber parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")}
                min={0} ref={inputRef} onPressEnter={toggleSave} onBlur={toggleSave} />
            )}
          </Form.Item>
        )}
      </>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion

export class EldenAccountingSalary extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "EldenAccountingSalary",
      controllerName: "eldenAccountingSalary",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      taxGroupDetailSelectItems: [],
      sgkIsciiKesinti: 0,
      sgkIscisizlikPrimi: 0,
      maasDamgaVergisi: 0,
      maasGelirVergisiRow: [],
      type: "BrutToNet",

      pagination: {
        current: 1,
        pageSize: 12,
        showSizeChanger: true,
        pageSizeOptions: ["12"],
        total: 0
      },
      searchCriteriaModels: [],
      modalStatusShow: false
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();

    this.formRef.current.setFieldsValue({
      type: "BrutToNet",
    });
  };

  fillComboboxes = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/taxGroupDetail");
    if (response.type === "ERROR") {
      error(response);
    } else {
      let taxGroupDetailSelectItems = [];
      let sgkIsciiKesintiRow = null;
      let maasDamgaVergisiRow = null;
      let sgkIscisizlikPrimiRow = null;
      let maasGelirVergisiRow = null;
      if (Boolean(response.data) && response.data.length > 0) {
        taxGroupDetailSelectItems = response.data;
        sgkIsciiKesintiRow = taxGroupDetailSelectItems.find(p => p.description === "SGK İşçi Primi");
        sgkIscisizlikPrimiRow = taxGroupDetailSelectItems.find(p => p.description === "SGK İşsizlik Primi");
        maasGelirVergisiRow = taxGroupDetailSelectItems.find(p => p.description === "Maaş Gelir Vergisi");
        maasDamgaVergisiRow = taxGroupDetailSelectItems.find(p => p.description === "Maaş Damga Vergisi");
      }

      this.setState({
        taxGroupDetailSelectItems: taxGroupDetailSelectItems,
        sgkIsciiKesinti: Boolean(sgkIsciiKesintiRow) ? sgkIsciiKesintiRow.fixPercent : 0,
        maasDamgaVergisi: Boolean(maasDamgaVergisiRow) ? maasDamgaVergisiRow.fixPercent : 0,
        sgkIscisizlikPrimi: Boolean(sgkIscisizlikPrimiRow) ? sgkIscisizlikPrimiRow.fixPercent : 0,
        maasGelirVergisiRow: Boolean(maasGelirVergisiRow.detailGradualRates) && maasGelirVergisiRow.detailGradualRates.length > 0 ?
          maasGelirVergisiRow.detailGradualRates.sort((a, b) => a.amountMin - b.amountMin) : [],
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
          element.key = element.index;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 12,
        showSizeChanger: true,
        pageSizeOptions: ["12"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleTotalWorkingDay = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.totalWorkingDay === value.totalWorkingDay) return;
        item.totalWorkingDay = value.totalWorkingDay;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleGrossSalary = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      newData.forEach(item => {
        if (Boolean(value) && Boolean(item)) {
          if (item.grossSalary === value.grossSalary) return;
          item.grossSalary = value.grossSalary;
          this.setState({
            tableList: newData
          });
        }
      });
    }
  };

  handleTotalNetSalary = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      newData.forEach(item => {
        if (Boolean(value) && Boolean(item)) {
          if (item.totalNetSalary === value.totalNetSalary) return;
          item.totalNetSalary = value.totalNetSalary;
          this.handleUpdateRecord(item);
          this.setState({
            tableList: newData
          });
        }
      });
    }
    //this.calculate();
  };

  handleUpdateRecord = (updatedRecord) => {
    const sgkIsciiKesinti = this.state.sgkIsciiKesinti;
    const sgkIscisizlikPrimi = this.state.sgkIscisizlikPrimi;
    const maasGelirVergisiRow = this.state.maasGelirVergisiRow;
    const maasDamgaVergisi = this.state.maasDamgaVergisi;

    const updatedValues = this.calculateRecordValues(updatedRecord, sgkIsciiKesinti, sgkIscisizlikPrimi, maasGelirVergisiRow, maasDamgaVergisi, SGKTaban, SGKTavan);

    // Update the state or perform other actions with updatedValues
    this.setState(prevState => ({
      tableList: prevState.tableList.map(record =>
        record.id === updatedRecord.id ? updatedValues : record
      )
    }));
  };

  calculateRecordValues = (record, sgkIsciiKesinti, sgkIscisizlikPrimi, maasGelirVergisiRow, maasDamgaVergisi, SGKTaban, SGKTavan) => {
    let { totalNetSalary, totalWorkingDay } = record;
    let grossSalary = totalNetSalary;
    let iteration = 0;
    const maxIterations = 100;
    const tolerance = 0.001;

    // Calculate grossSalary
    while (iteration < maxIterations) {
      const totalLegalDeductions = this.calculateTotalLegalDeductions(record, sgkIsciiKesinti, sgkIscisizlikPrimi, 0, SGKTaban, maasDamgaVergisi);
      const calculatedGrossSalary = totalNetSalary + totalLegalDeductions;
      const error = calculatedGrossSalary - grossSalary;

      if (Math.abs(error) < tolerance) {
        break;
      }

      grossSalary = calculatedGrossSalary;
      iteration++;
    }

    record.grossSalary = grossSalary;
    record.totalLegalDeductions = this.calculateTotalLegalDeductions(record, sgkIsciiKesinti, sgkIscisizlikPrimi, 0, SGKTaban, maasDamgaVergisi);
    record.totalGross = this.calculateTotalGross(record, grossSalary);
    record.sgkBase = this.calculateSGKBase(record, SGKTaban, SGKTavan);
    record.sgkWorkerDeduction = (record.sgkBase * sgkIsciiKesinti) / 100;
    record.sgkUnemployment = (record.sgkBase * sgkIscisizlikPrimi) / 100;
    record.sgkDeductionTotal = record.sgkWorkerDeduction + record.sgkUnemployment;
    record.incomeTaxBase = record.totalGross - record.sgkDeductionTotal;
    record.cumulativeTaxBase = this.calculateCumulativeTaxBase(record);
    record.cumulativeIncomeTax = this.calculateCumulativeIncomeTax(record, maasGelirVergisiRow);
    record.incomeTax = record.cumulativeIncomeTax - (record.previousCumulativeIncomeTax || 0);
    record.minimumWageTaxBase = SGKTaban - (SGKTaban * (sgkIsciiKesinti + sgkIscisizlikPrimi) / 100);
    record.minimumWageCumulativeTaxBase = this.calculateMinimumWageCumulativeTaxBase(record);
    record.minimumWageCumulativeTaxDeduction = this.calculateMinimumWageCumulativeTaxDeduction(record, maasGelirVergisiRow);
    record.minimumWageIncomeTax = record.minimumWageCumulativeTaxDeduction - (record.previousMinimumWageCumulativeTaxDeduction || 0);
    record.netIncomeTaxPayable = record.incomeTax - record.minimumWageIncomeTax;
    record.stampTaxBase = record.totalGross;
    record.stampDutyDeduction = (record.stampTaxBase * maasDamgaVergisi) / 100;
    record.minimumWageStampTaxBase = SGKTaban;
    record.minimumWageStampDuty = (record.minimumWageStampTaxBase * maasDamgaVergisi) / 100;
    record.stampDutyToBePaid = record.stampDutyDeduction - record.minimumWageStampDuty;
    record.totalLegalDeductions = record.sgkDeductionTotal + record.netIncomeTaxPayable + record.stampDutyToBePaid;
    record.gross = record.grossSalary + record.totalLegalDeductions;

    return record;
  };

  calculateCumulativeTaxBase(record) {
    return 0;
  }

  calculateCumulativeIncomeTax(record) {
    return 0;
  }

  calculateTotalLegalDeductions = (record, sgkIsciiKesinti, sgkIscisizlikPrimi, maasGelirVergisiRow, SGKTaban, maasDamgaVergisi) => {
    return record.sgkWorkerDeduction + record.sgkUnemployment + record.netIncomeTaxPayable + record.stampDutyToBePaid;
  };

  calculate() {
    let { tableList } = this.state;
    tableList.forEach((record, index) => {

      this.calculateStampDutyDeduction(record);
      this.calculateStampDutyToBePaid(record);
      this.calculateNetIncomeTaxPayable(record, index);
      this.calculateSgkDeductionTotal(record);
      this.calculateGrossSalary(record, record.totalNetSalary, record.totalNetSalary);
      this.calculateTotalGross(record, index);
      this.calculateStampTaxBase(record);// record.stampTaxBase = record.totalGross;


      this.calculateSGKBase(record);
      this.calculateMinimumWageCumulativeTaxDeduction(record);
      this.calculateIncomeTax(record);
      this.calculateMinimumWageTaxBase(record);
      this.calculateMinimumWageCumulativeTaxBase(record, index);
      this.calculateSgkDeductionTotal(record);

      console.log("totalLegalDeductions", record.totalLegalDeductions);
      console.log("grossSalary", record.grossSalary);
      console.log("gross", record.gross);
      let i = 0;
      while (record.grossSalary !== record.gross && i < 10) {
        this.calculateGrossSalary(record, record.totalNetSalary, record.grossSalary);
        i++;
      }
      console.log("finished");

    });
    this.setState({
      tableList: tableList,
    });
  }

  calculateGrossSalary(record, netSalary, grossSalary) {
    let totalLegalDeductions = record.totalLegalDeductions;
    let iteration = 0;
    let maxIterations = 100;
    let tolerance = 0.001;

    console.log("record", record);
    while (iteration < maxIterations) {
      const gross = netSalary + totalLegalDeductions;
      const error = gross - grossSalary;

      console.log("error", error);
      console.log("gross", gross);
      console.log("grossSalary", grossSalary);
      if (Math.abs(error) < tolerance) {
        break;
      }

      grossSalary = grossSalary + error;
      iteration++;
    }

    record.grossSalary = grossSalary + totalLegalDeductions;
  }

  calculateTotalGross(record, index) {
    let { tableList } = this.state;
    if (index === 0) {
      record.totalGross = record.grossSalary;
      return;
    }
    const previousValue = tableList.slice(0, index).reduce((sum, item) => sum + item.grossSalary, 0);
    record.totalGross = previousValue + record.grossSalary;
  }

  calculateSGKBase(record) {
    if (Boolean(record.totalGross) && record.totalGross > 0) {
      record.sgkBase = record.totalGross;
      if (record.sgkBase < SGKTaban) {
        record.sgkBase = SGKTaban;
      } else if (record.sgkBase > SGKTavan) {
        record.sgkBase = SGKTavan;
      }
    } else {
      record.sgkBase = SGKTaban;
    }
  }

  calculateMinimumWageCumulativeTaxDeduction(record) {
    let { maasGelirVergisiRow } = this.state;
    if (!maasGelirVergisiRow || maasGelirVergisiRow.length === 0) {
      record.minimumWageCumulativeTaxDeduction = 0;
    }

    let rateCoverage = maasGelirVergisiRow[0].rateCoverage;
    let total = 0;

    const calculateMinimumWageCumulativeTaxBase = (record, element) => {
      if (record.minimumWageCumulativeTaxBase > element.amountMin) {
        const max = element.amountMax || record.minimumWageCumulativeTaxBase;
        return ((Math.min(record.minimumWageCumulativeTaxBase, max) - element.amountMin) * element.ratePercent) / 100;
      }
      return 0;
    };

    if (rateCoverage === "Only_This_Line") {
      const selectedRow = maasGelirVergisiRow.find(element =>
        (record.minimumWageCumulativeTaxBase > element.amountMin && record.minimumWageCumulativeTaxBase < element.amountMax) ||
        (record.minimumWageCumulativeTaxBase > element.amountMax && element.amountMax !== null) ||
        (record.minimumWageCumulativeTaxBase > element.amountMin && element.amountMax === null)
      );
      record.minimumWageCumulativeTaxDeduction = selectedRow
        ? (record.minimumWageCumulativeTaxBase * selectedRow.ratePercent) / 100
        : 0;
    } else if (rateCoverage === "Addition_Of_Previousus") {
      total = maasGelirVergisiRow.reduce((acc, element) => {
        return acc + calculateMinimumWageCumulativeTaxBase(record, element);
      }, 0);
      record.minimumWageCumulativeTaxDeduction = total;
    } else {
      record.minimumWageCumulativeTaxDeduction = 0;
    }
  }

  calculateNetIncomeTaxPayable(record, index) {
    let { tableList } = this.state;
    if (index === 0) {
      record.incomeTax = record.cumulativeIncomeTax;
    } else {
      const currentCumulativeIncomeTaxValue = tableList[index - 1].cumulativeIncomeTax;
      const nextCumulativeIncomeTaxValue = record.cumulativeIncomeTax;
      record.incomeTax = nextCumulativeIncomeTaxValue - currentCumulativeIncomeTaxValue;
    }

    if (index === 0) {
      record.minimumWageIncomeTax = record.minimumWageCumulativeTaxDeduction;
    } else {
      const currentMinimumWageCumulativeTaxDeductionValue = tableList[index - 1].minimumWageCumulativeTaxDeduction;
      const nextMinimumWageCumulativeTaxDeductionValue = record.minimumWageCumulativeTaxDeduction;
      record.minimumWageIncomeTax = nextMinimumWageCumulativeTaxDeductionValue - currentMinimumWageCumulativeTaxDeductionValue;
    }

    record.netIncomeTaxPayable = record.incomeTax - record.minimumWageIncomeTax;
  }

  calculateIncomeTax(record) {
    let { maasGelirVergisiRow } = this.state;
    if (!maasGelirVergisiRow || maasGelirVergisiRow.length === 0) {
      record.cumulativeIncomeTax = 0;
    }

    let rateCoverage = maasGelirVergisiRow[0].rateCoverage;
    let total = 0;

    const calculateCumulativeIncomeTax = (record, element) => {
      if (record.cumulativeTaxBase > element.amountMin) {
        const max = element.amountMax || record.cumulativeTaxBase;
        return ((Math.min(record.cumulativeTaxBase, max) - element.amountMin) * element.ratePercent) / 100;
      }
      return 0;
    };

    if (rateCoverage === "Only_This_Line") {
      const selectedRow = maasGelirVergisiRow.find(element =>
        (record.cumulativeTaxBase > element.amountMin && record.cumulativeTaxBase < element.amountMax) ||
        (record.cumulativeTaxBase > element.amountMax && element.amountMax !== null) ||
        (record.cumulativeTaxBase > element.amountMin && element.amountMax === null)
      );
      record.cumulativeIncomeTax = selectedRow
        ? (record.cumulativeTaxBase * selectedRow.ratePercent) / 100
        : 0;
    } else if (rateCoverage === "Addition_Of_Previousus") {
      total = maasGelirVergisiRow.reduce((acc, element) => {
        return acc + calculateCumulativeIncomeTax(record, element);
      }, 0);
      record.cumulativeIncomeTax = total;
    } else {
      record.cumulativeIncomeTax = 0;
    }
  }

  calculateIncomeTax(record) {
    record.incomeTaxBase = record.totalGross - record.sgkDeductionTotal;
  }

  calculateMinimumWageTaxBase(record) {
    record.minimumWageTaxBase = SGKTaban - (SGKTaban * (this.state.sgkIsciiKesinti + this.state.sgkIscisizlikPrimi) / 100)
  }

  calculateMinimumWageCumulativeTaxBase(record, index) {
    const { tableList } = this.state;
    if (index === 0) {
      record.minimumWageCumulativeTaxBase = record.minimumWageTaxBase;
      return;
    }

    const previousValue = tableList.slice(0, index).reduce((sum, item) => sum + item.minimumWageTaxBase, 0);
    record.minimumWageCumulativeTaxBase = previousValue + record.minimumWageTaxBase;
  }

  calculateSgkDeductionTotal(record) {
    record.sgkWorkerDeduction = (record.sgkBase * this.state.sgkIsciiKesinti) / 100;
    record.sgkUnemployment = (record.sgkBase * this.state.sgkIscisizlikPrimi) / 100;
    record.sgkDeductionTotal = record.sgkWorkerDeduction + record.sgkUnemployment;

    record.totalLegalDeductions = record.sgkDeductionTotal + record.netIncomeTaxPayable + record.stampDutyToBePaid;
  }

  calculateStampTaxBase(record) {
    record.stampTaxBase = record.totalGross;
  }

  calculateStampDutyToBePaid(record) {
    record.stampDutyToBePaid = record.stampDutyDeduction - record.minimumWageStampDuty;
  }

  calculateStampDutyDeduction(record) {
    record.stampDutyDeduction = (record.stampTaxBase * this.state.maasDamgaVergisi) / 100
  }

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    };

    const columnsBrutToNet = [
      {
        title: <FormattedMessage id="EldenAccountingSalary.Ay" defaultMessage="Ay" />,
        key: "month",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.month.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.ToplamÇalışmaGünü" defaultMessage="Toplam Çalışma Günü" />,
        key: "totalWorkingDay",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.totalWorkingDay.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "totalWorkingDay",
          title: <FormattedMessage id="EldenAccountingSalary.ToplamÇalışmaGünü" defaultMessage="Toplam Çalışma Günü" />,
          handleSave: this.handleTotalWorkingDay
        }),
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.BrütMaaş" defaultMessage="Brüt Maaş" />,
        key: "grossSalary",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.grossSalary.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "grossSalary",
          title: <FormattedMessage id="EldenAccountingSalary.BrütMaaş" defaultMessage="Brüt Maaş" />,
          handleSave: this.handleGrossSalary
        }),
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.ToplamBrüt" defaultMessage="Toplam Brüt" />,
        key: "totalGross",
        render: record => {
          record.totalGross = (record.totalWorkingDay * record.grossSalary) / 30;

          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.totalGross.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.SGKMatrahı" defaultMessage="SGK Matrahı" />,
        key: "sgkBase",
        render: record => {
          if (Boolean(record.totalGross) && record.totalGross > 0) {
            record.sgkBase = record.totalGross;
            if (record.sgkBase < SGKTaban) {
              record.sgkBase = SGKTaban;
            } else if (record.sgkBase > SGKTavan) {
              record.sgkBase = SGKTavan;
            }
          }
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.sgkBase.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.SGKİşçiKesintisi" defaultMessage="SGK İşçi Kesintisi" />,
        key: "sgkWorkerDeduction",
        render: record => {
          record.sgkWorkerDeduction = (record.sgkBase * this.state.sgkIsciiKesinti) / 100
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.sgkWorkerDeduction.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.SGKİşsizlik" defaultMessage="SGK İşsizlik" />,
        key: "sgkUnemployment",
        render: record => {
          record.sgkUnemployment = (record.sgkBase * this.state.sgkIscisizlikPrimi) / 100
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.sgkUnemployment.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.SGKKesintiToplamı" defaultMessage="SGK Kesinti Toplamı" />,
        key: "sgkDeductionTotal",
        render: record => {
          record.sgkDeductionTotal = record.sgkWorkerDeduction + record.sgkUnemployment;

          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.sgkDeductionTotal.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.GelirVergisiMatrahı" defaultMessage="Gelir Vergisi Matrahı" />,
        key: "incomeTaxBase",
        render: record => {
          record.incomeTaxBase = record.totalGross - record.sgkDeductionTotal;
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.incomeTaxBase.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.KümülatifVergiMatrahı" defaultMessage="Kümülatif Vergi Matrahı" />,
        key: "cumulativeTaxBase",
        render: (_, record, index) => {
          const prevRecords = this.state.tableList.slice(0, index);
          record.cumulativeTaxBase = prevRecords.reduce((sum, item) => sum + (item.totalGross - item.sgkDeductionTotal), 0) + (record.totalGross - record.sgkDeductionTotal);
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.cumulativeTaxBase.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.KümülatifGelirVergisi" defaultMessage="Kümülatif Gelir Vergisi" />,
        key: "cumulativeIncomeTax",
        render: record => {
          const { maasGelirVergisiRow } = this.state;

          if (!maasGelirVergisiRow || maasGelirVergisiRow.length === 0) {
            record.cumulativeIncomeTax = 0;
            return (
              <span style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
                {record.cumulativeIncomeTax.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
              </span>
            );
          }

          const rateCoverage = maasGelirVergisiRow[0].rateCoverage;
          let total = 0;

          const calculateTax = (record, element) => {
            if (record.cumulativeTaxBase > element.amountMin) {
              const max = element.amountMax || record.cumulativeTaxBase;
              return ((Math.min(record.cumulativeTaxBase, max) - element.amountMin) * element.ratePercent) / 100;
            }
            return 0;
          };

          if (rateCoverage === "Only_This_Line") {
            const selectedRow = maasGelirVergisiRow.find(element =>
              (record.cumulativeTaxBase > element.amountMin && record.cumulativeTaxBase < element.amountMax) ||
              (record.cumulativeTaxBase > element.amountMax && element.amountMax !== null) ||
              (record.cumulativeTaxBase > element.amountMin && element.amountMax === null)
            );
            record.cumulativeIncomeTax = selectedRow
              ? (record.cumulativeTaxBase * selectedRow.ratePercent) / 100
              : 0;
          } else if (rateCoverage === "Addition_Of_Previousus") {
            total = maasGelirVergisiRow.reduce((acc, element) => {
              return acc + calculateTax(record, element);
            }, 0);
            record.cumulativeIncomeTax = total;
          } else {
            record.cumulativeIncomeTax = 0;
          }

          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.cumulativeIncomeTax.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.GelirVergisi" defaultMessage="Gelir Vergisi" />,
        key: "incomeTax",
        render: (_, record, index) => {
          const { tableList } = this.state;
          if (index === 0) {
            record.incomeTax = record.cumulativeIncomeTax;
          } else {
            const currentValue = tableList[index - 1].cumulativeIncomeTax;
            const nextValue = record.cumulativeIncomeTax;
            record.incomeTax = nextValue - currentValue;
          }
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.incomeTax.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.AsgariÜcretVergiMatrahı" defaultMessage="Asgari Ücret Vergi Matrahı" />,
        key: "minimumWageTaxBase",
        render: record => {
          record.minimumWageTaxBase = SGKTaban - (SGKTaban * (this.state.sgkIsciiKesinti + this.state.sgkIscisizlikPrimi) / 100)
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.minimumWageTaxBase.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.AsgariÜcretKümülatifVergiMatrahı" defaultMessage="Asgari Ücret Kümülatif Vergi Matrahı" />,
        key: "minimumWageCumulativeTaxBase",
        render: (_, record, index) => {
          if (index === 0) {
            record.minimumWageCumulativeTaxBase = record.minimumWageTaxBase;

            return (
              <span style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
                {record.minimumWageCumulativeTaxBase.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
              </span>
            );
          }

          const previousValue = this.state.tableList.slice(0, index).reduce((sum, item) => sum + item.minimumWageTaxBase, 0);
          record.minimumWageCumulativeTaxBase = previousValue + record.minimumWageTaxBase;
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.minimumWageCumulativeTaxBase.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.AsgariÜcretKümülatifVergiKesintisi" defaultMessage="Asgari Ücret Kümülatif Vergi Kesintisi" />,
        key: "minimumWageCumulativeTaxDeduction",
        render: record => {
          const { maasGelirVergisiRow } = this.state;

          if (!maasGelirVergisiRow || maasGelirVergisiRow.length === 0) {
            record.minimumWageCumulativeTaxDeduction = 0;
            return (
              <span style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
                {record.minimumWageCumulativeTaxDeduction.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
              </span>
            );
          }

          const rateCoverage = maasGelirVergisiRow[0].rateCoverage;
          let total = 0;

          const calculateTax = (record, element) => {
            if (record.minimumWageCumulativeTaxBase > element.amountMin) {
              const max = element.amountMax || record.minimumWageCumulativeTaxBase;
              return ((Math.min(record.minimumWageCumulativeTaxBase, max) - element.amountMin) * element.ratePercent) / 100;
            }
            return 0;
          };

          if (rateCoverage === "Only_This_Line") {
            const selectedRow = maasGelirVergisiRow.find(element =>
              (record.minimumWageCumulativeTaxBase > element.amountMin && record.minimumWageCumulativeTaxBase < element.amountMax) ||
              (record.minimumWageCumulativeTaxBase > element.amountMax && element.amountMax !== null) ||
              (record.minimumWageCumulativeTaxBase > element.amountMin && element.amountMax === null)
            );
            record.minimumWageCumulativeTaxDeduction = selectedRow
              ? (record.minimumWageCumulativeTaxBase * selectedRow.ratePercent) / 100
              : 0;
          } else if (rateCoverage === "Addition_Of_Previousus") {
            total = maasGelirVergisiRow.reduce((acc, element) => {
              return acc + calculateTax(record, element);
            }, 0);
            record.minimumWageCumulativeTaxDeduction = total;
          } else {
            record.minimumWageCumulativeTaxDeduction = 0;
          }

          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.minimumWageCumulativeTaxDeduction.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.AsgariÜcretGelirVergisi" defaultMessage="Asgari Ücret Gelir Vergisi" />,
        key: "minimumWageIncomeTax",
        render: (_, record, index) => {
          const { tableList } = this.state;
          if (index === 0) {
            record.minimumWageIncomeTax = record.minimumWageCumulativeTaxDeduction;
          } else {
            const currentValue = tableList[index - 1].minimumWageCumulativeTaxDeduction;
            const nextValue = record.minimumWageCumulativeTaxDeduction;
            record.minimumWageIncomeTax = nextValue - currentValue;
          }

          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.minimumWageIncomeTax.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.ÖdenecekNetGelirVergisi" defaultMessage="Ödenecek Net Gelir Vergisi" />,
        key: "netIncomeTaxPayable",
        render: record => {
          record.netIncomeTaxPayable = record.incomeTax - record.minimumWageIncomeTax;

          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.netIncomeTaxPayable.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.DamgaVergisiMatrahı" defaultMessage="Damga Vergisi Matrahı" />,
        key: "stampTaxBase",
        render: record => {
          record.stampTaxBase = record.totalGross;

          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.stampTaxBase.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.DamgaVergisiKesintisi" defaultMessage="Damga Vergisi Kesintisi" />,
        key: "stampDutyDeduction",
        render: record => {
          record.stampDutyDeduction = (record.stampTaxBase * this.state.maasDamgaVergisi) / 100
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.stampDutyDeduction.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.AsgariÜcretDamgaVergisiMatrahı" defaultMessage="Asgari Ücret Damga Vergisi Matrahı" />,
        key: "minimumWageStampTaxBase",
        render: record => {
          record.minimumWageStampTaxBase = SGKTaban;
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.minimumWageStampTaxBase.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.AsgariÜcretDamgaVergisi" defaultMessage="Asgari Ücret Damga Vergisi" />,
        key: "minimumWageStampDuty",
        render: record => {
          record.minimumWageStampDuty = (record.minimumWageStampTaxBase * this.state.maasDamgaVergisi) / 100
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.minimumWageStampDuty.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.ÖdenecekDamgaVergisi" defaultMessage="Ödenecek Damga Vergisi" />,
        key: "stampDutyToBePaid",
        render: record => {
          record.stampDutyToBePaid = record.stampDutyDeduction - record.minimumWageStampDuty;
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.stampDutyToBePaid.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.YasalKesintilerToplamı" defaultMessage="Yasal Kesintiler Toplamı" />,
        key: "totalLegalDeductions",
        render: record => {
          record.totalLegalDeductions = record.sgkDeductionTotal + record.netIncomeTaxPayable + record.stampDutyToBePaid;
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.totalLegalDeductions.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.NetÖdeme" defaultMessage="Net Ödeme" />,
        key: "netPayment",
        render: record => {
          record.netPayment = record.totalGross - record.totalLegalDeductions;
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.netPayment.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      }
    ];


    const columnsNetToGross = [
      {
        title: <FormattedMessage id="EldenAccountingSalary.Ay" defaultMessage="Ay" />,
        key: "month",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.month.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.ToplamÇalışmaGünü" defaultMessage="Toplam Çalışma Günü" />,
        key: "totalWorkingDay",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.totalWorkingDay.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "totalWorkingDay",
          title: <FormattedMessage id="EldenAccountingSalary.ToplamÇalışmaGünü" defaultMessage="Toplam Çalışma Günü" />,
          handleSave: this.handleTotalWorkingDay
        }),
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.ToplamNetMaaş" defaultMessage="Toplam Net Maaş" />,
        key: "totalNetSalary",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.totalNetSalary.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "totalNetSalary",
          title: <FormattedMessage id="EldenAccountingSalary.ToplamNetMaaş" defaultMessage="Toplam Net Maaş" />,
          handleSave: this.handleTotalNetSalary
        }),
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.BrütMaaş" defaultMessage="Brüt Maaş" />,
        key: "grossSalary",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {record.grossSalary.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.ToplamBrüt" defaultMessage="Toplam Brüt" />,
        key: "totalGross",
        render: (_, record, index) => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.totalGross) ? 0 : record.totalGross.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.SGKMatrahı" defaultMessage="SGK Matrahı" />,
        key: "sgkBase",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.sgkBase) ? 0 : record.sgkBase.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.SGKİşçiKesintisi" defaultMessage="SGK İşçi Kesintisi" />,
        key: "sgkWorkerDeduction",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.sgkWorkerDeduction) ? 0 : record.sgkWorkerDeduction.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.SGKİşsizlik" defaultMessage="SGK İşsizlik" />,
        key: "sgkUnemployment",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.sgkUnemployment) ? 0 : record.sgkUnemployment.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.SGKKesintiToplamı" defaultMessage="SGK Kesinti Toplamı" />,
        key: "sgkDeductionTotal",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.sgkDeductionTotal) ? 0 : record.sgkDeductionTotal.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.GelirVergisiMatrahı" defaultMessage="Gelir Vergisi Matrahı" />,
        key: "incomeTaxBase",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.incomeTaxBase) ? 0 : record.incomeTaxBase.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.KümülatifVergiMatrahı" defaultMessage="Kümülatif Vergi Matrahı" />,
        key: "cumulativeTaxBase",
        render: (_, record, index) => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.cumulativeTaxBase) ? 0 : record.cumulativeTaxBase.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.KümülatifGelirVergisi" defaultMessage="Kümülatif Gelir Vergisi" />,
        key: "cumulativeIncomeTax",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.cumulativeIncomeTax) ? 0 : record.cumulativeIncomeTax.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.GelirVergisi" defaultMessage="Gelir Vergisi" />,
        key: "incomeTax",
        render: (_, record, index) => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.incomeTax) ? 0 : record.incomeTax.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.AsgariÜcretVergiMatrahı" defaultMessage="Asgari Ücret Vergi Matrahı" />,
        key: "minimumWageTaxBase",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.minimumWageTaxBase) ? 0 : record.minimumWageTaxBase.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.AsgariÜcretKümülatifVergiMatrahı" defaultMessage="Asgari Ücret Kümülatif Vergi Matrahı" />,
        key: "minimumWageCumulativeTaxBase",
        render: (_, record, index) => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.minimumWageCumulativeTaxBase) ? 0 : record.minimumWageCumulativeTaxBase.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.AsgariÜcretKümülatifVergiKesintisi" defaultMessage="Asgari Ücret Kümülatif Vergi Kesintisi" />,
        key: "minimumWageCumulativeTaxDeduction",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.minimumWageCumulativeTaxDeduction) ? 0 : record.minimumWageCumulativeTaxDeduction.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.AsgariÜcretGelirVergisi" defaultMessage="Asgari Ücret Gelir Vergisi" />,
        key: "minimumWageIncomeTax",
        render: (_, record, index) => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.minimumWageIncomeTax) ? 0 : record.minimumWageIncomeTax.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.ÖdenecekNetGelirVergisi" defaultMessage="Ödenecek Net Gelir Vergisi" />,
        key: "netIncomeTaxPayable",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.netIncomeTaxPayable) ? 0 : record.netIncomeTaxPayable.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.DamgaVergisiMatrahı" defaultMessage="Damga Vergisi Matrahı" />,
        key: "stampTaxBase",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.stampTaxBase) ? 0 : record.stampTaxBase.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.DamgaVergisiKesintisi" defaultMessage="Damga Vergisi Kesintisi" />,
        key: "stampDutyDeduction",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.stampDutyDeduction) ? 0 : record.stampDutyDeduction.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.AsgariÜcretDamgaVergisiMatrahı" defaultMessage="Asgari Ücret Damga Vergisi Matrahı" />,
        key: "minimumWageStampTaxBase",
        render: record => {
          record.minimumWageStampTaxBase = SGKTaban;
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.minimumWageStampTaxBase) ? 0 : record.minimumWageStampTaxBase.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.AsgariÜcretDamgaVergisi" defaultMessage="Asgari Ücret Damga Vergisi" />,
        key: "minimumWageStampDuty",
        render: record => {
          record.minimumWageStampDuty = (record.minimumWageStampTaxBase * this.state.maasDamgaVergisi) / 100
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.minimumWageStampDuty) ? 0 : record.minimumWageStampDuty.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.ÖdenecekDamgaVergisi" defaultMessage="Ödenecek Damga Vergisi" />,
        key: "stampDutyToBePaid",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.stampDutyToBePaid) ? 0 : record.stampDutyToBePaid.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.YasalKesintilerToplamı" defaultMessage="Yasal Kesintiler Toplamı" />,
        key: "totalLegalDeductions",
        render: record => {
          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.totalLegalDeductions) ? 0 : record.totalLegalDeductions.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      },
      {
        title: <FormattedMessage id="EldenAccountingSalary.Brüt" defaultMessage="Brüt" />,
        key: "gross",
        render: record => {
          record.gross = record.grossSalary + record.totalLegalDeductions;

          return (
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {!Boolean(record.gross) ? 0 : record.gross.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
            </span>
          );
        },
      }
    ];

    const columnsExcel = [
      { header: "Ay", key: "month" },
      { header: "Toplam Çalışma Günü", key: "totalWorkingDay" },
      { header: "Brüt Maaş", key: "grossSalary" },
      { header: "Toplam Brüt", key: "totalGross" },
      { header: "SGK Matrahı", key: "sgkBase" },
      { header: "SGK İşçi Kesintisi", key: "sgkWorkerDeduction" },
      { header: "SGK İşsizlik", key: "sgkUnemployment" },
      { header: "SGK Kesinti Toplamı", key: "sgkDeductionTotal" },
      { header: "Gelir Vergisi Matrahı", key: "incomeTaxBase" },
      { header: "Kümülatif Vergi Matrahı", key: "cumulativeTaxBase" },
      { header: "Kümülatif Gelir Vergisi", key: "cumulativeIncomeTax" },
      { header: "Gelir Vergisi", key: "incomeTax" },
      { header: "Asgari Ücret Vergi Matrah", key: "minimumWageTaxBase" },
      { header: "Asgari Ücret Kümülatif Vergi Matrahı", key: "minimumWageCumulativeTaxBase" },
      { header: "Asgari Ücret Kümülatif Vergi Kesintisi", key: "minimumWageCumulativeTaxDeduction" },
      { header: "Asgari Ücret Gelir Vergisi", key: "minimumWageIncomeTax" },
      { header: "Ödenecek Net Gelir Vergisi", key: "netIncomeTaxPayable" },
      { header: "Damga Vergisi Matrahı", key: "stampTaxBase" },
      { header: "Damga Vergisi Kesintisi", key: "stampDutyDeduction" },
      { header: "Asgari Ücret Damga Vergisi Matrahı", key: "minimumWageStampTaxBase" },
      { header: "Asgari Ücret Damga Vergisi", key: "minimumWageStampDuty" },
      { header: "Ödenecek Damga Vergisi", key: "stampDutyToBePaid" },
      { header: "Yasal Kesintiler Toplamı", key: "totalLegalDeductions" },
      { header: "Net Ödeme", key: "netPayment" },
    ];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="TenderingSalary_Form.pageTitle" defaultMessage="Salary" />}
        placement="start"
        color="purple"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={23}></Col>
              <Col md={1}>{
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => { excelExport(columnsExcel, this.state.tableList) }}
                  icon={<ExportIcon color="action" fontSize="large" />}
                />
              }</Col>
            </Row>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="StockCard.type" defaultMessage="Type" />}
              name="type"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Radio.Group
                onChange={e => {
                  this.setState({
                    type: e.target.value
                  });
                }}
              >
                <Radio value={"BrutToNet"}>Brüt To Net</Radio>
                <Radio value={"NetToGross"}>Net To Gross</Radio>
              </Radio.Group>
            </Form.Item>

          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              {" "}
              <div className="alert-icon">
                {" "}
                <FilterOutlined />{" "}
              </div>{" "}
              <div className="alert-text">
                {" "}
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      {" "}
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>{" "}
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>{" "}
                      <span>
                        {" "}
                        <Tooltip title="Remove">
                          {" "}
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />{" "}
                        </Tooltip>{" "}
                      </span>{" "}
                    </div>
                  );
                })}{" "}
              </div>{" "}
              <div className="alert-close">
                {" "}
                <Tooltip title="Remove All">
                  {" "}
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />{" "}
                </Tooltip>{" "}
              </div>{" "}
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              components={components}
              rowClassName={() => "editable-row"}
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={this.state.type === "BrutToNet" ? columnsBrutToNet : columnsNetToGross}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              pagination={false}
            />
          </div>
        </div>

        <AntModal
          open={this.state.modalStatusShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalStatusShow: false })}
          width={700}
          centered
        >
          <Badge.Ribbon
            text={<FormattedMessage id="RequestForQuotation.Status" defaultMessage="Status" />}
            placement="start"
            color="purple"
            style={{ fontSize: 12 }}
          >
            <div className="card card-custom" style={{ padding: "0.1rem", minHeight: "100%" }}>
              <div className="card-body pt-4 custom-timeline pr-5 pl-5 mr-5 ml-5 align-content-center">
                <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                <Steps direction="vertical" className="pl-5-custom anticon-custom">
                  {Boolean(this.state.statusList) &&
                    this.state.statusList.length > 0 &&
                    this.state.statusList.map((item, index) => {
                      return item.spin ? (
                        <Step status="process" title={item.status + (item.count >= 0 ? " - " + item.count : "")} icon={<LoadingOutlined />} />
                      ) : (
                        <Step
                          title={item.status + (item.count >= 0 ? " - " + item.count : "")}
                          status={
                            item.color === "green"
                              ? "finish"
                              : item.color === "blue"
                                ? "process"
                                : item.status === "Evaluation"
                                  ? "wait"
                                  : item.status === "Selection of vendor(s)"
                                    ? "wait"
                                    : item.status === "P.O. Creation"
                                      ? "wait"
                                      : "wait"
                          }
                          description={item.color === "green" || item.color === "blue" ? moment(item.date).format("DD-MM-YYYY HH:mm:ss") : ""}
                        />
                      );
                    })}
                </Steps>
              </div>
            </div>
          </Badge.Ribbon>
        </AntModal>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EldenAccountingSalary);
