import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../../MessageHelper";
import { handleRequest } from "../../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge } from "antd";
import DynamicActionMenu from "../../../CustomMenu/DynamicActionMenu";
import { Add, AddCircle, Archive, ArrowDownward, AttachFile, Bookmark, BookmarkOutlined, Cached, CloudDownloadRounded, Delete, DeleteOutlined, EditOutlined, Room, SearchOutlined, Settings, ShortText, Star, StarOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { fillFilterOperations, getColumnFilter } from "../../../../Components/DataTableFilterComponent";
import { Avatar } from "@material-ui/core";
import Search from "antd/lib/transfer/search";
import ReactQuill from "react-quill";

export class InboxNewMessage extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "InboxNewMessage",
      controllerName: "taxPayers",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };



    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);



    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  handleQuillChange = (value) => {
    console.log("Quill içeriği değişti:", value);
  };

  onSubjectChange = (value) => {
    console.log("Subject içeriği değişti:", value);
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="InboxNewMessage.taxPayers" defaultMessage="Tax Payers" />,
        key: "taxPayers",
        render: record => {
          return record.taxPayers;
        }, ...getColumnFilter({
          inputName: "taxPayers", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
    ];

    const data = [
      {
        id: 1,
        name: 'Ana Simmons',
        imageSrc: 'URL_TO_IMAGE',
        skills: 'HTML, JS, ReactJS',
        company: 'Intertico',
        specialization: 'Web, UI/UX Design',
        date: '15 Dec 2023',
      },
    ];

    const renderTableRows = () => {
      return data.map((item) => (
        <tr key={item.id}>
          <td>
            <div className='form-check form-check-sm form-check-custom form-check-solid'>
              <input className='form-check-input widget-9-check' type='checkbox' value={item.id} />
            </div>
          </td>
          <td>
            <div className='d-flex'>
              <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                <Star />
              </a>
              <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                <Bookmark />
              </a>
            </div>
          </td>
          <td>
            <div className='d-flex align-items-center'>
              <Avatar src={item.imageSrc} alt={item.name} className='me-2' />
              <div className='d-flex flex-column'>
                <a href='#' className='text-gray-900 fw-bold text-hover-primary fs-6'>
                  {item.name}
                </a>
                <span className='text-muted fw-semibold text-muted fs-7'>{item.skills}</span>
              </div>
            </div>
          </td>
          <td>
            <a href='#' className='text-gray-900 fw-bold text-hover-primary d-block fs-6'>
              {item.company}
            </a>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>{item.specialization}</span>
          </td>
          <td className='text-end'>
            <div className='d-flex flex-column'>
              <span className='text-muted fs-7 fw-semibold' style={{ lineHeight: '1.5rem', fontSize: '15px' }}>
                {item.date}
              </span>
            </div>
          </td>
        </tr>
      ));
    };

    return (
      <div id="kt_app_content_container" className="app-container container-xxl">
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid ms-lg-12 ms-xl-12">
            <div className="card">
              <div className="card-header align-items-center">
                <div className="card-title">
                  <h2>Compose Message</h2>
                </div>
              </div>
              <div className="card-body p-0">
                <form id="kt_inbox_compose_form">
                  <div className="d-block">
                    <div
                      className="d-flex align-items-center border-bottom px-8 min-h-50px"
                    >
                      <div className="text-dark fw-bold w-75px">To:</div>
                      <input
                        type="text"
                        className="form-control form-control-transparent border-0"
                        name="compose_to"
                        value=""
                        data-kt-inbox-form="tagify"
                      />
                      <div className="ms-auto w-75px text-end">
                        <span
                          className="text-muted fs-bold cursor-pointer text-hover-primary me-2"
                          data-kt-inbox-form="cc_button"
                        >
                          Cc
                        </span>
                        <span
                          className="text-muted fs-bold cursor-pointer text-hover-primary"
                          data-kt-inbox-form="bcc_button"
                        >
                          Bcc
                        </span>
                      </div>
                    </div>
                    <div
                      className="d-none align-items-center border-bottom ps-8 pe-5 min-h-50px"
                      data-kt-inbox-form="cc"
                    >
                      <div className="text-dark fw-bold w-75px">Cc:</div>
                      <input
                        type="text"
                        className="form-control form-control-transparent border-0"
                        name="compose_cc"
                        value=""
                        data-kt-inbox-form="tagify"
                      />
                      <span
                        className="btn btn-clean btn-xs btn-icon"
                        data-kt-inbox-form="cc_close"
                      >
                        <i className="la la-close"></i>
                      </span>
                    </div>
                    <div
                      className="d-none align-items-center border-bottom inbox-to-bcc ps-8 pe-5 min-h-50px"
                      data-kt-inbox-form="bcc"
                    >
                      <div className="text-dark fw-bold w-75px">Bcc:</div>
                      <input
                        type="text"
                        className="form-control form-control-transparent border-0"
                        name="compose_bcc"
                        value=""
                        data-kt-inbox-form="tagify"
                      />
                      <span
                        className="btn btn-clean btn-xs btn-icon"
                        data-kt-inbox-form="bcc_close"
                      >
                        <i className="la la-close"></i>
                      </span>
                    </div>
                    <div className="border-bottom">
                      <input
                        className="form-control form-control-transparent border-0 px-8 min-h-45px"
                        name="compose_subject"
                        id="compose_subject"
                        onChange={this.onSubjectChange}
                        placeholder="Subject"
                      />
                    </div>
                    <div
                      id="kt_inbox_form_editor"
                      className="bg-transparent border-0 h-350px px-3"
                      style={{ marginTop: '20px', }}
                    >
                      <ReactQuill
                        className="bg-transparent border-0 h-300px px-3"
                        theme="snow"
                        onChange={this.handleQuillChange}
                        placeholder="Type your text here..."
                      />
                    </div>
                    <div
                      className="dropzone dropzone-queue px-8 py-4"
                      id="kt_inbox_reply_attachments"
                      data-kt-inbox-form="dropzone"
                    >
                    </div>
                  </div>
                  <div className="d-flex flex-stack flex-wrap gap-2 py-5 ps-8 pe-5 border-top">
                    <div className="d-flex align-items-center me-3">
                      <div className="btn-group me-4">
                        <span
                          className="btn btn-primary fs-bold px-6 ml-4"
                          data-kt-inbox-form="send"
                        // onClick={this.sendMessage}
                        >
                          Send
                        </span>
                      </div>
                      <span
                        className="btn btn-icon btn-sm btn-clean btn-active-light-primary me-2"
                        id="kt_inbox_reply_attachments_select"
                        data-kt-inbox-form="dropzone_upload"
                      >
                        <AttachFile />
                      </span>
                      <span
                        className="btn btn-icon btn-sm btn-clean btn-active-light-primary"
                      >
                        <Room />
                      </span>
                    </div>
                    <div className="d-flex align-items-center ms-auto">
                      <span
                        className="btn btn-icon btn-sm btn-clean btn-active-light-primary me-2"
                        data-toggle="tooltip"
                        title="More actions"
                      >
                        <Settings />
                      </span>
                      <span
                        className="btn btn-icon btn-sm btn-clean btn-active-light-primary"
                        data-inbox="dismiss"
                        data-toggle="tooltip"
                        title="Dismiss reply"
                      >
                        <DeleteOutlined />
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(InboxNewMessage);
