import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import {
  FilterOutlined,
  CloseCircleOutlined,
  FileTextOutlined,
  DeleteOutlined,
  TrophyOutlined,
  RocketOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import { Tooltip } from "antd";
import moment from "moment";
import DraggableModal from "../../../Components/DraggableModal";
import TenderPreparation from "./TenderPreparation";
import TenderLaunch from "./TenderLaunch";
import TenderAward from "./TenderAward";

export class TenderExecutionLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "TenderExecutionLog",
      controllerName: "proposedProjectLog",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      typeRevise: false,
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {};

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/pagingExecution";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;
    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = (typeRevise, row) => {
    document.getElementById("kt_scrolltop").click();

    let fileUrl = [];
    let filePathList = Boolean(row.fileUrl) ? row.fileUrl.split(",") : [];
    if (Boolean(filePathList) && filePathList.length > 0) {
      filePathList.forEach((item, index) => {
        let obj = {
          uid: index,
          name: item.split("download/")[1],
          url: item,
          thumbUrl: item
        };

        fileUrl.push(obj);
      });
    }
    this.formRef.current.setFieldsValue({
      ...row,
      fileUrl: fileUrl,
      issueDate: Boolean(row.issueDate) ? moment(row.issueDate) : null
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      typeRevise: typeRevise,
      fileList: fileUrl
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined style={{ fontSize: "small", color: "#1890ff" }} />,
      actionClick: this.edit.bind(this)
    };
    let tenderInfoAction = {
      name: "Tender Info",
      icon: <InfoCircleOutlined style={{ fontSize: "small", color: "#1890ff" }} />,
      actionClick: this.tenderInfoModal.bind(this)
    };
    let tenderDocumentsAction = {
      name: "Tender Documents",
      icon: <FileTextOutlined style={{ fontSize: "small", color: "#1890ff" }} />,
      actionClick: this.tenderDocumentsModal.bind(this)
    };
    let launchAction = {
      name: "Launch",
      icon: <RocketOutlined style={{ fontSize: "small", color: "#1890ff" }} />,
      actionClick: this.launchModal.bind(this)
    };
    let awardAction = {
      name: "Award",
      icon: <TrophyOutlined style={{ fontSize: "small", color: "#1890ff" }} />,
      actionClick: this.awardModal.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteOutlined style={{ fontSize: "small", color: "#ff4d4f" }} />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(tenderInfoAction);
    actionList.push(tenderDocumentsAction);
    actionList.push(launchAction);
    actionList.push(awardAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  tenderInfoModal = row => {
    this.setState({
      modalTenderInfoAction: true,
      id: row.id,
      random: Math.random()
    });
  };

  tenderDocumentsModal = row => {};

  launchModal = row => {
    this.setState({
      modalTenderLaunchAction: true,
      id: row.id,
      random: Math.random()
    });
  };

  awardModal = row => {
    this.setState({
      modalTenderAwardAction: true,
      id: row.id,
      random: Math.random()
    });
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      typeRevise: false,
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    let pathList = [];
    if (Boolean(newItem.fileUrl))
      newItem.fileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) pathList.push(item.response.url);
        else if (Boolean(item.url)) pathList.push(item.url);
      });
    newItem.fileUrl = pathList.toString();

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      if (this.state.typeRevise) {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/revise/" + newItem.id, newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
      }
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  getStatusColor = status => {
    if (Boolean(status)) {
      if (status === "Prelimanry") {
        return "processing";
      } else if (status === "Design") {
        return "warning";
      } else if (status === "Execution") {
        return "success";
      }
    }
    return "default";
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const columns = [
      { key: "key", render: this.action },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.ProposalNo" defaultMessage="Proposal No" />,
        key: "proposalNo",
        render: record => {
          return record.id;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.Date" defaultMessage="Date" />,
        key: "date",
        render: record => {
          return record.date ? moment(record.date).format("DD-MM-YYYY HH:mm") : "";
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.ProjectName" defaultMessage="Project Name" />,
        key: "projectName",
        render: record => {
          return record.projectName;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.status" defaultMessage="Status" />,
        key: "status",
        render: record => {
          return record.status;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.ProjectType" defaultMessage="Project Type" />,
        key: "projectType",
        render: record => {
          return record.projectType;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.FacilityType" defaultMessage="Facility Type" />,
        key: "facilityType",
        render: record => {
          return record.facilityType;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.Scope" defaultMessage="Scope" />,
        key: "scope",
        render: record => {
          return record.scope;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.LocationAddress" defaultMessage="Location, Address" />,
        key: "locationAddress",
        render: record => {
          return record.locationAddress;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.EstimatedBudget" defaultMessage="Estimated Budget" />,
        key: "estimatedBudget",
        render: record => {
          return record.estimatedBudget;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.DurationOfProject" defaultMessage="Duration of Project" />,
        key: "duration",
        render: record => {
          return record.duration;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.ProposedBy" defaultMessage="Proposed By" />,
        key: "proposedBy",
        render: record => {
          return record.proposedBy;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.PreliminaryScoring" defaultMessage="Preliminary Scoring" />,
        key: "preliminaryScoring",
        render: record => {
          return record.preliminaryScoring;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.DesignScoring" defaultMessage="Design Scoring" />,
        key: "designScoring",
        render: record => {
          return record.designScoring;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.ProjectExecutionScoring" defaultMessage="Project Execution Scoring" />,
        key: "projectExecutionScoring",
        render: record => {
          return record.projectExecutionScoring;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.Tendering" defaultMessage="Tendering" />,
        key: "tendering",
        render: record => {
          return record.tendering;
        },
        sorter: false
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}></Form>
        {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
          <div className="alert alert-custom alert-light">
            <div className="alert-icon">
              <FilterOutlined />
            </div>
            <div className="alert-text">
              {this.state.searchCriteriaModels.map((val, i) => {
                return (
                  <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                    <span className="mr-2">{val.label}</span>
                    <strong className="mr-2">{val.type}</strong>
                    <span className="mr-2">{val.operationValue}</span>
                    <span className="mr-2">{val.showValue}</span>
                    <span>
                      <Tooltip title="Remove">
                        <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                      </Tooltip>
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="alert-close">
              <Tooltip title="Remove All">
                <CloseCircleOutlined
                  style={{ color: "crimson" }}
                  onClick={() =>
                    this.setState({ searchCriteriaModels: [] }, () => {
                      this.filterChangeItem([]);
                    })
                  }
                />
              </Tooltip>
            </div>
          </div>
        )}
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            rowKey={record => record.id}
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <DraggableModal
          title={<FormattedMessage id="ProjectLog.TenderInfo" defaultMessage="Tender Info" />}
          width={1500}
          centered
          okButtonProps={{ hidden: true }}
          open={this.state.modalTenderInfoAction}
          onCancel={() => this.setState({ modalTenderInfoAction: false })}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <TenderPreparation
              proposedProjectLogId={this.state.id}
              random={this.state.random}
              close={() => this.setState({ modalTenderInfoAction: false })}
            />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="ProjectLog.TenderLaunch" defaultMessage="Launch" />}
          width={1500}
          centered
          okButtonProps={{ hidden: true }}
          open={this.state.modalTenderLaunchAction}
          onCancel={() => this.setState({ modalTenderLaunchAction: false })}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <TenderLaunch
              proposedProjectLogId={this.state.id}
              random={this.state.random}
              close={() => this.setState({ modalTenderLaunchAction: false })}
            />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="ProjectLog.TenderAward" defaultMessage="Award" />}
          width={1500}
          centered
          okButtonProps={{ hidden: true }}
          open={this.state.modalTenderAwardAction}
          onCancel={() => this.setState({ modalTenderAwardAction: false })}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <TenderAward
              proposedProjectLogId={this.state.id}
              random={this.state.random}
              close={() => this.setState({ modalTenderAwardAction: false })}
            />
          }
        />
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TenderExecutionLog);
