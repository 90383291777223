import { FilterOutlined } from "@ant-design/icons";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import { Button as AntButton, Badge, Col, DatePicker, Form, Input, InputNumber, Row, Select, Table, Tooltip } from "antd";
import "antd/dist/antd.css";
import Text from "antd/lib/typography/Text";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess } from "../../MessageHelper";
import DraggableModal from "../../Components/DraggableModal";
import SalesmanCashFlowByConsignment from "./SalesmanCashFlowByConsignment";
import { excelExport } from "../../ExcelHelper";
import SettingsIcon from "@material-ui/icons/Settings";
import SalesmanCommisisionSettings from "./SalesmanCommisisionSettings";

const { Option } = Select;
const { RangePicker } = DatePicker;

const currentYear = new Date().getFullYear();
const startYear = 2020;

const yearSelectItems = Array.from({ length: currentYear - startYear + 1 }, (_, index) => {
  const year = startYear + index;
  return { key: year.toString(), value: year.toString() };
});

const monthSelectItems = [
  { key: "01", value: "January" },
  { key: "02", value: "February" },
  { key: "03", value: "March" },
  { key: "04", value: "April" },
  { key: "05", value: "May" },
  { key: "06", value: "June" },
  { key: "07", value: "July" },
  { key: "08", value: "August" },
  { key: "09", value: "September" },
  { key: "10", value: "October" },
  { key: "11", value: "November" },
  { key: "12", value: "December" }
];

export class SalesmanCashFlow extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      tableList: [],
      dateMin: null,
      dateMax: null,
      year: null,
      month: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {};

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/salesmanCash/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/salesmanCash/pagingSalesman";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = { ...values, id: this.state.id };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/salesmanCash", newItem);
    } else {
      response = await handleRequest("PUT", "/api/salesmanCash/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleReceivedAmountSave = async (row, value) => {
    const newData = [...this.state.tableList];
    const index = newData.findIndex(item => row.id === item.id);
    const item = newData[index];
    if (item.receivedAmount === value.receivedAmount) return;
    item.receivedAmount = value.receivedAmount;
    item.salesBalance = item.receivedAmount - item.expectedAmount;
    newData[index] = item;
    if (Boolean(value) && value.receivedAmount > 0) {
      var response = await handleRequest("PATCH", "/api/salesmanCash/" + row.id, item);
      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess();
        this.setState({
          tableList: newData
        });
      }
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      dateMin: this.state.dateMin,
      dateMax: this.state.dateMax,
      month: this.state.month,
      year: this.state.year,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/salesmanCash/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  salesmanModal = row => {
    this.setState({
      modalSalesmanShow: true,
      consignmentUserId: row.consignmentUserId,
      random: Math.random()
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const columns = [
      {
        title: <FormattedMessage id="GENERAL.SN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="SalesmanCash.salesman" defaultMessage="Salesman" />,
        key: "salesman",
        render: record => {
          return record.consignment;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.consignment) ? this.state.pagination.consignment : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  consignment: e.target.value,
                  consignmentColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.consignmentColor) ? this.state.pagination.consignmentColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="SalesmanCash.expectedAmount" defaultMessage="Expected Amount" />,
        key: "expectedAmount",
        render: record => {
          return Boolean(record.expectedAmount)
            ? record.expectedAmount.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")
            : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.expectedAmount) ? this.state.pagination.expectedAmount : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  expectedAmount: e.target.value,
                  expectedAmountColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.expectedAmountColor) ? this.state.pagination.expectedAmountColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalesmanCash.receivedAmount" defaultMessage="Received Amount" />,
        key: "receivedAmount",
        editable: true,
        render: record => {
          return Boolean(record.receivedAmount)
            ? record.receivedAmount.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")
            : "";
        },
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "receivedAmount",
          title: <FormattedMessage id="SalesmanCash.receivedAmount" defaultMessage="Received Amount" />,
          handleSave: this.handleReceivedAmountSave
        }),
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.receivedAmount) ? this.state.pagination.receivedAmount : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  receivedAmount: e.target.value,
                  receivedAmountColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.receivedAmountColor) ? this.state.pagination.receivedAmountColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },

      {
        title: <FormattedMessage id="SalesmanCash.SalesBalance" defaultMessage="Sales Balance" />,
        key: "salesBalance",
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: record.salesBalance >= 0 ? "green" : "red",
                  textDecoration: "underline"
                }}
                
                onClick={e => this.salesmanModal(record)}
              >
                {Boolean(record.salesBalance)
                  ? record.salesBalance.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")
                  : ""}
              </div>
            </React.Fragment>
          );
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.salesBalance) ? this.state.pagination.salesBalance : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  salesBalance: e.target.value,
                  salesBalanceColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.salesBalanceColor) ? this.state.pagination.salesBalanceColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalesmanCash.EntitledCommission" defaultMessage="Entitled Commission" />,
        key: "entitledCommission",
        render: record => {
          return Boolean(record.entitledCommission)
            ? record.entitledCommission.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")
            : "";
        }
      },
      {
        title: <FormattedMessage id="SalesmanCash.PaidCommission" defaultMessage="Paid Commission" />,
        key: "paidCommission",
        editable: true,
        render: record => {
          return record.paidCommission;
        },
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "paidCommission",
          title: <FormattedMessage id="SalesmanCash.paidCommission" defaultMessage="Paid Commission" />,
          handleSave: this.handleReceivedAmountSave
        }),
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.paidCommission) ? this.state.pagination.paidCommission : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  paidCommission: e.target.value,
                  paidCommissionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.paidCommissionColor) ? this.state.pagination.paidCommissionColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="SalesmanCash.commisionBalance" defaultMessage="Commision Balance" />,
        key: "commisionBalance",
        render: record => {
          if (record.commisionBalance >= 0) {
            return <Text type="success">{record.commisionBalance}</Text>;
          } else {
            return <Text type="danger">{record.commisionBalance}</Text>;
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.commisionBalance) ? this.state.pagination.commisionBalance : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  commisionBalance: e.target.value,
                  commisionBalanceColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.commisionBalanceColor) ? this.state.pagination.commisionBalanceColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      }
    ];

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const excelColumns = [
      { header: "S/N", key: "index" },
      { header: "Consignment", key: "consignment" },
      { header: "Expected Amount", key: "expectedAmount" },
      { header: "Received Amount", key: "receivedAmount" },
      { header: "Sales Balance", key: "salesBalance" },
      { header: "Received Date", key: "receivedDate" }
    ];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id={"SalesmanAccount.pageTitle"} defaultMessage={"Salesman Account"} />}
        placement="start"
        color="purple"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]} justify="end">
              <Col md={6}></Col>
              <Col md={6}>
                <Form.Item style={{ width: "100%" }} name="rangeDate">
                  <RangePicker
                    style={{ width: "100%" }}
                    format="DD-MM-YYYY HH:mm"
                    onChange={value => {
                      console.log("value", value);
                      if (Boolean(value) && value.length === 2) {
                        this.setState(
                          {
                            dateMin: value[0],
                            dateMax: value[1]
                          },
                          () => {
                            const { pagination } = this.state;
                            this.restartTable({ pagination });
                          }
                        );
                      } else {
                        this.setState(
                          {
                            dateMin: null,
                            dateMax: null
                          },
                          () => {
                            const { pagination } = this.state;
                            this.restartTable({ pagination });
                          }
                        );
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item name="year">
                  <Select
                    allowClear
                    showSearch
                    style={{ width: "150px" }}
                    optionFilterProp="children"
                    onChange={value => {
                      this.setState(
                        {
                          year: value
                        },
                        () => {
                          const { pagination } = this.state;
                          this.restartTable({ pagination });
                        }
                      );
                    }}
                  >
                    {yearSelectItems.map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item name="month">
                  <Select
                    allowClear
                    showSearch
                    style={{ width: "150px" }}
                    optionFilterProp="children"
                    onChange={value => {
                      this.setState(
                        {
                          month: value
                        },
                        () => {
                          const { pagination } = this.state;
                          this.restartTable({ pagination });
                        }
                      );
                    }}
                  >
                    {monthSelectItems.map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={1}>
                <Tooltip title={<FormattedMessage id="SalesmanAccount.SalesmanCommisisionSettings" defaultMessage="Salesman Commisision Settings" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => {
                      this.setState({
                        random: Math.random(),
                        salesmanCommisisionSettingsShow: true
                      });
                    }}
                    icon={<SettingsIcon color="error" fontSize="large" />}
                  ></AntButton>
                </Tooltip>
              </Col>
              <Col md={1}>
                {
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => {
                      excelExport(excelColumns, this.state.tableList);
                    }}
                    icon={<ExportIcon color="action" fontSize="large" />}
                  />
                }
              </Col>
            </Row>
          </Form>

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              rowKey="id"
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <DraggableModal
            title={<FormattedMessage id="SalesmanAccount.Sales" defaultMessage="Sales" />}
            open={this.state.showSalesModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showSalesModal: false })}
            onOk={() => this.formRefSales.current.submit()}
            width={800}
            centered
            content={
              <Form initialValues={{ remember: false }} onFinish={this.updateProgress} onFinishFailed={onFinishFailed} ref={this.formRefProgress}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProductLog.Date" defaultMessage="Date" />}
                    name="Date"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY" />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="SalesmanCashFlow.ProgressArae " defaultMessage="Progress (Square-meter)" />}
                    name="physicalProgress"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="SalesmanCashFlow.ProgressArae " defaultMessage="Progress (%)" />}
                    name="progressPercent"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            }
          />

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          <DraggableModal
            title={<FormattedMessage id="SalesmanCashFlow.Salesman" defaultMessage="Salesman" />}
            open={this.state.modalSalesmanShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ modalSalesmanShow: false })}
            width={1400}
            centered
            content={
              <SalesmanCashFlowByConsignment
                consignmentUserId={this.state.consignmentUserId}
                year={this.state.year}
                month={this.state.month}
                dateMin={this.state.dateMin}
                dateMax={this.state.dateMax}
                random={this.state.random}
                close={() => this.setState({ modalSalesmanShow: false })}
              />
            }
          />

          <DraggableModal
            title={<FormattedMessage id="SalesmanCashFlow.CommisisionSalesman" defaultMessage="Commisision Settings" />}
            open={this.state.salesmanCommisisionSettingsShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ salesmanCommisisionSettingsShow: false })}
            width={1000}
            centered
            content={
              <SalesmanCommisisionSettings random={this.state.random} close={() => this.setState({ salesmanCommisisionSettingsShow: false })} />
            }
          />
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SalesmanCashFlow);
