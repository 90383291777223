import axios from "axios";
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { API_BASE_ROOT } from "../../../../../app/ApiConnector";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";

export function getMenuItems() {
  let url = API_BASE_ROOT + "/api/menus/tree/layout";
  return axios.get(url);
}

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const [menuItems, setMenuItems] = useState(null);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open ` : "";
  };

  const getParentLi = i => {
    let isOpen = false;
    //  console.log(i);
    if (Boolean(i.submenu) && i.submenu.length > 0) {
      i.submenu.forEach(item => {
        if (checkIsActive(location, "/" + item.page)) {
          isOpen = true;
        }
      });
    }
    return (
      <li key={i.id} className={`menu-item menu-item-submenu ${isOpen ? "menu-item-open" : ""}`} aria-haspopup="true" data-menu-toggle="hover">
        <NavLink className="menu-link  menu-toggle" to={Boolean(i.submenu) && i.submenu.length > 0 ? "/" : "/" + i.page}>
          <span className="svg-icon menu-icon">
            {Boolean(i.submenu) && i.submenu.length > 0 && <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />}
            {!(Boolean(i.submenu) && i.submenu.length > 0) && <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />}
          </span>
          <span className="menu-text">
            <FormattedMessage id={"MENU" + i.id} defaultMessage={i.title} />
          </span>
          <i className="menu-arrow" />
        </NavLink>
        {getSubmenus(i.submenu)}
      </li>
    );
  };

  const getChildLi = i => {
    return (
      <li
        key={i.id}
        className={`menu-item ${getMenuItemActive(
          Boolean(i.submenu) && i.submenu.length > 0 ? "" : "/" + i.page,
          Boolean(i.submenu) && i.submenu.length > 0
        )}`}
        aria-haspopup="true"
      >
        <NavLink className="menu-link" to={Boolean(i.submenu) && i.submenu.length > 0 ? "/" : "/" + i.page}>
          <span className="svg-icon menu-icon">
            {Boolean(i.submenu) && i.submenu.length > 0 && <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />}
            {!(Boolean(i.submenu) && i.submenu.length > 0) && <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />}
          </span>
          <span className="menu-text">
            {" "}
            <FormattedMessage id={"MENU" + i.id} defaultMessage={i.title} />
          </span>
        </NavLink>
        {getSubmenus(i.submenu)}
      </li>
    );
  };

  const getSubmenus = submenu => {
    return Boolean(submenu) && submenu.length > 0 ? (
      <div className="menu-submenu ">
        <ul className="menu-subnav">
          <ul className="menu-subnav">
            {Boolean(submenu) &&
              submenu.length > 0 &&
              submenu.map(i => (Boolean(i.submenu) && i.submenu.length > 0 ? getParentLi(i) : getChildLi(i)))}
          </ul>
        </ul>
      </div>
    ) : (
      ""
    );
  };

  useEffect(() => {
    if (!Boolean(menuItems))
      getMenuItems()
        .then(({ data }) => {
          setMenuItems(data);
          return data;
        })
        .catch(error => {
          if (error.response && error.response.status === 403) {
            window.location.href = "/logout";
          }
          setMenuItems(null);
          return null;
        });
  }, []);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {Boolean(menuItems) &&
          menuItems.length > 0 &&
          menuItems.map(i => (Boolean(i.submenu) && i.submenu.length > 0 ? getParentLi(i) : getChildLi(i)))}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
