import "antd/dist/antd.css";
import moment from "moment";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Col, Row, Button as AntButton, Badge, Table, Tooltip, Checkbox, TimePicker } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { excelExport } from "../../ExcelHelper";
import { Select } from "antd";
import "react-quill/dist/quill.snow.css";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { CloseCircleOutlined, FilterOutlined } from "@ant-design/icons";
import RouteSchedule from "./RouteSchedule";
import DraggableModal from "../../Components/DraggableModal";

const { Option } = Select;

export class Departure extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "Departure",
      controllerName: "departure",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseDays = await handleRequest("GET", "/api/" + this.state.controllerName + "/days");
    if (responseDays.type === "ERROR") {
      error(responseDays);
    }
    var responseMonths = await handleRequest("GET", "/api/" + this.state.controllerName + "/months");
    if (responseMonths.type === "ERROR") {
      error(responseMonths);
    }
    this.setState({
      daysSelectItems: Boolean(responseDays.data) ? responseDays.data : [],
      monthsSelectItems: Boolean(responseMonths.data) ? responseMonths.data : []
    });
  };

  searchrouteId = async item => {
    this.setState({ loadingSearch: true });
    var response = await handleRequest("GET", `/api/${this.state.controllerName}/routeId/${Boolean(item) && item.length > 0 ? item : "''"}`);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        routeIdSelectItems: [],
        loadingSearch: false
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          routeIdSelectItems: response.data,
          loadingSearch: false
        });
      } else {
        this.setState({
          routeIdSelectItems: [],
          loadingSearch: false
        });
      }
    }
  };

  searchbusStopId = async item => {
    this.setState({ loadingSearch: true });
    var response = await handleRequest("GET", `/api/${this.state.controllerName}/busStopId/${Boolean(item) && item.length > 0 ? item : "''"}`);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        busStopIdSelectItems: [],
        loadingSearch: false
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          busStopIdSelectItems: response.data,
          loadingSearch: false
        });
      } else {
        this.setState({
          busStopIdSelectItems: [],
          loadingSearch: false
        });
      }
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.searchrouteId(row.routeName);
    this.searchbusStopId(row.busStopName);

    var days = [];
    if (Boolean(row.days) && row.days.length > 0) {
      row.days.forEach(element => {
        days.push(element.day);
      });
    }

    var months = [];
    if (Boolean(row.months) && row.months.length > 0) {
      row.months.forEach(element => {
        months.push(element.month);
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      days: days,
      months: months,
      starTime: row.starTime ? moment(row.starTime, "HH:mm:ss") : null
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };

    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      starTime: Boolean(values.starTime) ? values.starTime.format("HH:mm:ss") : null
    };

    let days = [];
    if (newItem.days && newItem.days.length > 0)
      newItem.days.forEach(element => {
        days.push({ day: element });
      });
    newItem.days = days;

    let months = [];
    if (newItem.months && newItem.months.length > 0)
      newItem.months.forEach(element => {
        months.push({ month: element });
      });
    newItem.months = months;

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };
  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };
  DepartureModal = record => {
    this.setState({
      showDepartureModal: true,
      selectedDeparture: record
    });
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = { labelCol: { span: 8 }, wrapperCol: { span: 8 } };

    const columns = [
      { key: "key", render: this.action },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1)
      },
      {
        title: <FormattedMessage id="Departure.Route" defaultMessage="Route" />,
        key: "routeId",
        render: record => record.routeName,
        ...getColumnFilter({
          inputName: "routeId",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="Departure.StartStation" defaultMessage="Start Station" />,
        key: "busStopId",
        render: record => record.busStopName,
        ...getColumnFilter({
          inputName: "busStopId",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="Departure.StartTime" defaultMessage="Start Time" />,
        key: "starTime",
        render: record => record.starTime
      },
      {
        title: <FormattedMessage id="Departure.Days" defaultMessage="Days" />,
        key: "id",
        render: record => record.days.map(p => p.day).join(", ")
      },
      {
        title: <FormattedMessage id="Departure.Months" defaultMessage="Months" />,
        key: "id",
        render: record => record.months.map(p => p.month).join(", ")
      }
    ];

    const columnsExcel = [
      { header: "Departure No", key: "departureNo" },
      { header: "Departure", key: "departure" },
      { header: "Stop No", key: "stopNo" },
      { header: "Time", key: "time" },
      { header: "Km", key: "km" }
    ];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="departure.pageTitle" defaultMessage="Departure" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={21}></Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => {
                    excelExport(columnsExcel, this.state.tableList);
                  }}
                  icon={<ExportIcon color="action" fontSize="large" />}
                />
              </Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>
            <div hidden={this.state.hideInputs}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Departure.Route" defaultMessage="Route" />}
                name="routeId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={this.searchrouteId}
                  style={{ marginBottom: 0, width: "100%" }}
                  loading={this.state.loadingSearch}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {Boolean(this.state["routeIdSelectItems"]) &&
                    this.state["routeIdSelectItems"].length > 0 &&
                    this.state["routeIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="Departure.StartStation" defaultMessage="Start Station" />}
                name="busStopId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={this.searchbusStopId}
                  style={{ marginBottom: 0, width: "100%" }}
                  loading={this.state.loadingSearch}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {Boolean(this.state["busStopIdSelectItems"]) &&
                    this.state["busStopIdSelectItems"].length > 0 &&
                    this.state["busStopIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="Departure.StartTime" defaultMessage="Start Time" />}
                name="starTime"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <TimePicker />
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="Departure.Days" defaultMessage="Days" />}
                name="days"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Checkbox.Group>
                  {Boolean(this.state["daysSelectItems"]) &&
                    this.state["daysSelectItems"].length > 0 &&
                    this.state["daysSelectItems"].map(i => <Checkbox value={i.key}>{i.value}</Checkbox>)}
                </Checkbox.Group>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="Departure.Months" defaultMessage="Months" />}
                name="months"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Checkbox.Group>
                  {Boolean(this.state["monthsSelectItems"]) &&
                    this.state["monthsSelectItems"].length > 0 &&
                    this.state["monthsSelectItems"].map(i => <Checkbox value={i.key}>{i.value}</Checkbox>)}
                </Checkbox.Group>
              </Form.Item>

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>

          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              rowKey={record => record.id}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
              expandable={{
                expandedRowRender: record => Boolean(record) && <RouteSchedule selectedRow={record} random={this.state.random} />,
                rowExpandable: () => {
                  return true;
                },
                onExpand: (expanded, record) => {
                  this.setState({ random: Math.random() });
                }
              }}
            />
          </div>

          <DraggableModal
            centered
            width={500}
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            visible={this.state.modalDeleteShow}
            onCancel={() => {
              this.setState({ modalDeleteShow: false });
            }}
            footer={[
              <Button
                style={{ marginRight: "10px" }}
                variant="secondary"
                onClick={() => {
                  this.setState({
                    modalDeleteShow: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonNo" defaultMessage="Cancel" />
              </Button>,
              <Button style={{ marginRight: "3px" }} variant="primary" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonYes" defaultMessage="Delete" />
              </Button>
            ]}
            content={
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            }
          />
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Departure);
