import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import { showError, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Button as AntButton, Col, Row, Spin, Breadcrumb, Space } from "antd";
import { Input } from "antd";
import { fillFilterOperations } from "../../../Components/DataTableFilterComponent";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export class ProjectsTreeCompanySection extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ProjectsTreeCompanySection",
      controllerName: "apartment",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      loadingButtonSubmit: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      currentRow: 0
    };
  }

  formRefHouseRooms = React.createRef();
  formRefHouseTypes = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
    fillFilterOperations();
    this.getHouseTypes();
  };

  fillComboboxes = async () => { };

  fillTypeOfHouses = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  getHouseTypes = async () => {
    this.setState(
      {
        typeOfHousesModal: true
      },
      async () => {
        await this.fillTypeOfHouses("Apartment.TypeOfHouses");
        console.log("Apartment.TypeOfHousesSelectItems", this.state["Apartment.TypeOfHousesSelectItems"]);
        if (Boolean(this.formRefHouseTypes) && Boolean(this.formRefHouseTypes.current)) {
          this.formRefHouseTypes.current.resetFields();
          let typeOfHouses = [];
          if (this.state["Apartment.TypeOfHousesSelectItems"] && this.state["Apartment.TypeOfHousesSelectItems"].length > 0) {
            this.state["Apartment.TypeOfHousesSelectItems"].forEach(element => {
              typeOfHouses.push({ houseTypeName: element.name });
            });
          }
          console.log("typeOfHouses", typeOfHouses);
          this.formRefHouseTypes.current.setFieldsValue({
            typeOfHouses: typeOfHouses
          });
        }
      }
    );
  };

  fillHouseRoomsNotNull = async houseTypeId => {
    if (Boolean(houseTypeId) && houseTypeId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/houseRoomsNotNull/" + houseTypeId);
      if (response.type === "ERROR") {
        error(response);
      }

      this.setState({
        houseRoomsNotNull: Boolean(response.data) ? response.data : []
      });
    }
  };

  saveHouseTypes = async values => {
    if (Boolean(values.typeOfHouses) && values.typeOfHouses.length > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveHouseTypes", values.typeOfHouses);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.props.nextTab();
      }
    } else {
      showWarning("Please enter at least one house type.");
      this.setState({
        loadingButtonSubmit: false
      });
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  render() {
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <div className="card card-custom card-stretch">
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">

              <Breadcrumb separator=">">
                <Breadcrumb.Item>
                  <a
                    href="#"
                    className={this.state.typeOfHousesModal ? "customBreadcrumbItem" : ""}
                    onClick={() => {
                      this.setState(
                        {
                          typeOfHousesModal: true
                        },
                        () => {
                          this.getHouseTypes();
                        }
                      );
                    }}
                  >
                    <Space>
                      <SVG style={{ width: '20px', height: '20px' }} src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>
                      <FormattedMessage id="ProjectsTreeCompanyApartment.Sections" defaultMessage="Sections" />
                    </Space>
                  </a>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="card-toolbar"></div>
          </div>

          <br />
          <h3 className="card-label font-weight-bolder text-dark">
            <FormattedMessage id="ProjectsTreeCompanySection.Sections" defaultMessage="Sections" />
          </h3>
          <br />
        </div>

        <Form initialValues={{ remember: false }} onFinish={this.saveHouseTypes} ref={this.formRefHouseTypes}>
          <Form.List name="typeOfHouses">
            {(fields, { add, remove }) => {
              return (
                <>
                  <TableBoot bordered size="sm">
                    <tbody>
                      {fields.map(field => {
                        return (
                          <tr key={field.key}>
                            <td>
                              <Form.Item noStyle name={[field.name, "houseTypeName"]}>
                                <Input />
                              </Form.Item>
                            </td>
                            <td>
                              <MinusCircleOutlined onClick={() => remove(field.name)} />
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan={11}>
                          <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            <FormattedMessage id={"ProjectsTreeCompanySection.AddNew"} defaultMessage={"Add New"} />
                          </AntButton>
                        </td>
                      </tr>
                    </tbody>
                  </TableBoot>
                </>
              );
            }}
          </Form.List>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}></Col>
            <Col xs={0} sm={0} md={14} lg={14} xl={14} xxl={14}></Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Spin spinning={this.state.loadingButtonSubmit}>
                <Button
                  variant="primary"
                  style={{ width: "100%" }}
                  onClick={() => {
                    this.formRefHouseTypes.current.submit();
                  }}
                >
                  <FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />
                </Button>
              </Spin>
            </Col>
          </Row>
        </Form>

      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsTreeCompanySection);
