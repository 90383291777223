import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Form, Table, Input, Modal as AntModal, DatePicker, Button as AntButton, Col, Row, TreeSelect } from "antd";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import CreateIcon from "@material-ui/icons/AddCircle";
import { ZoomInOutlined } from "@ant-design/icons";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { getColumnFilter } from "../../Components/DataTableFilterComponent";
import "react-quill/dist/quill.snow.css";
import { excelExport } from "../../ExcelHelper";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

export class CodingFormatDetailMethod extends Component {
  constructor() {
    super();
    this.state = {
      formName: "CodingFormatDetailMethod",
      controllerName: "codingFormatDetailMethod",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      searchCriteriaModels: [],

      id: null,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    this.setState(
      {
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
          total: 0
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
        this.fillComboboxes();
      }
    );
  };

  fillComboboxes = async () => {};

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/export");
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(response.data) && response.data.length !== 0) {
        response.data.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          exportList: response.data,
          exportClick: true
        });
      } else {
        this.setState({
          exportList: [],
          exportClick: true
        });
      }
    }
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            methodName: element["Method"],
            format: element["Format"],
            formatLocation: element["Format Location"]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  formatModalShow = async format => {
    this.setState({
      formatModal: format,
      modalFormatShow: true
    });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 8 }
    };

    const layout2 = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    };

    const treeData = [
      {
        value: "Backend",
        title: "Backend",
        children: [
          {
            value: "Backend_Domain",
            title: <b style={{ color: "#08c" }}>Domain</b>
          },
          {
            value: "Backend_Service",
            title: <b style={{ color: "#08c" }}>Service</b>
          },
          {
            value: "Backend_Rest",
            title: <b style={{ color: "#08c" }}>Rest</b>
          },
          {
            value: "Backend_Model",
            title: <b style={{ color: "#08c" }}>Model</b>,
            children: [
              {
                value: "Backend_Model_Enums",
                title: <b style={{ color: "#08c" }}>Enums</b>
              }
            ]
          }
        ]
      },
      {
        value: "Frontend",
        title: "Frontend",
        children: [
          {
            value: "Frontend_Import",
            title: <b style={{ color: "#08c" }}>Import</b>
          },
          {
            value: "Frontend_Cons",
            title: <b style={{ color: "#08c" }}>Cons</b>
          },
          {
            value: "Frontend_Var",
            title: <b style={{ color: "#08c" }}>Var</b>
          },
          {
            value: "Frontend_Chart",
            title: <b style={{ color: "#08c" }}>Chart</b>
          },
          {
            value: "Frontend_Export",
            title: <b style={{ color: "#08c" }}>Export</b>,
            children: [
              {
                value: "Frontend_Export_Constructor",
                title: <b style={{ color: "#08c" }}>Constructor</b>,
                children: [
                  {
                    value: "Frontend_Export_Constructor_ThisState",
                    title: <b style={{ color: "#08c" }}>This.State</b>
                  }
                ]
              },
              {
                value: "Frontend_Export_Methods",
                title: <b style={{ color: "#08c" }}>Methods</b>
              },
              {
                value: "Frontend_Export_Render",
                title: <b style={{ color: "#08c" }}>Render</b>,
                children: [
                  {
                    value: "Frontend_Export_Render_ExcelExport",
                    title: <b style={{ color: "#08c" }}>Excel Export</b>
                  },
                  {
                    value: "Frontend_Export_Render_Methods",
                    title: <b style={{ color: "#08c" }}>Methods</b>
                  },
                  {
                    value: "Frontend_Export_Render_FormItem",
                    title: <b style={{ color: "#08c" }}>Form.Item</b>
                  },
                  {
                    value: "Frontend_Export_Render_Return",
                    title: <b style={{ color: "#08c" }}>Return</b>,
                    children: [
                      {
                        value: "Frontend_Export_Render_Return_Table",
                        title: <b style={{ color: "#08c" }}>Table</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_Table_Filter",
                        title: <b style={{ color: "#08c" }}>Table Filter</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_Table_Parent_Child",
                        title: <b style={{ color: "#08c" }}>Table Parent Child</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_CreateUploadExportButton",
                        title: <b style={{ color: "#08c" }}>Create-Upload-Export Button</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_Save_Button",
                        title: <b style={{ color: "#08c" }}>Save Button</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_Update_Button",
                        title: <b style={{ color: "#08c" }}>Update Button</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_Modal",
                        title: <b style={{ color: "#08c" }}>Modal</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_Modal_Button",
                        title: <b style={{ color: "#08c" }}>Modal Button</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_Modal_Opening_Draggable",
                        title: <b style={{ color: "#08c" }}>Modal Opening Draggable</b>
                      }
                    ]
                  }
                ]
              },
              {
                value: "Frontend_Export_Column",
                title: <b style={{ color: "#08c" }}>Column</b>
              }
            ]
          }
        ]
      }
    ];

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="CodingFormatDetailMethod.method" defaultMessage="Method" />,
        key: "methodName",
        render: record => {
          return record.methodName;
        },
        ...getColumnFilter({
          inputName: "methodName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="CodingFormatDetailMethod.FormatLocation" defaultMessage="Format Location" />,
        key: "formatLocation",
        render: record => {
          return record.formatLocationName;
        },
        ...getColumnFilter({
          inputName: "formatLocation",
          inputType: "SELECT",
          selectItems: treeData,
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="CodingFormatDetailMethod.Format" defaultMessage="Format" />,
        key: "format",
        render: record => {
          return (
            <AntButton
              onClick={() => this.formatModalShow(record.format)}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<ZoomInOutlined fontSize="large" />}
            ></AntButton>
          );
        }
      },
      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const columnsExcel = [
      { header: "S/N", key: "index" },
      { header: "Method", key: "methodName" },
      { header: "Format", key: "format" },
      { header: "Format Location", key: "formatLocation", value: col => (Boolean(col.formatLocation) ? col.formatLocation : "") }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={21}></Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                onClick={async () => {
                  await this.handleExportExcel();
                  excelExport(columnsExcel, this.state.exportList);
                }}
                icon={<ExportIcon color="action" fontSize="large" />}
              />
            </Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CodingFormatDetailMethod.methodName" defaultMessage="Method" />}
                name="methodName"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="CodingFormatDetailMethod.FormatLocation" defaultMessage="Format Location" />}
                name="formatLocation"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <TreeSelect allowClear showSearch style={{ width: "500px" }} treeData={treeData} />
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="CodingFormatDetailMethod.format" defaultMessage="Format" />}
                name="format"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                {/* <ReactQuill theme="snow" value={this.state.format || ""} modules={modules} formats={formats} /> */}
                <TextArea style={{ width: "100%" }} rows={10} />
              </Form.Item>
            }

            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <AntModal
          title={<FormattedMessage id="CodingFormatDetailMethod.Format" defaultMessage="Format" />}
          closable="false"
          open={this.state.modalFormatShow}
          onCancel={() => {
            this.setState({ modalFormatShow: false });
          }}
          width={1200}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalFormatShow: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
        >
          {/* <ReactQuill theme="snow" value={this.state.formatModal || ""} readOnly={true} /> */}
          <TextArea style={{ width: "100%" }} rows={10} value={this.state.formatModal || ""} readOnly={true} />
        </AntModal>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CodingFormatDetailMethod);
