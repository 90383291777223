import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import { Form, Col, Row, Button as AntButton, Badge, Upload, InputNumber, Modal as AntModal, Input, Tag, Space, Cascader, Image } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Select } from "antd";
import { DatePicker } from "antd";
import moment from "moment";
import { UploadOutlined, LeftOutlined, RightOutlined, ZoomInOutlined } from "@ant-design/icons";
import { Tooltip, Calendar, Table } from "antd";
import { } from "antd";
import "../planning/event.css";
import MaintenancePlanningGantt from "./MaintenancePlanningGantt";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import CreateIcon from "@material-ui/icons/AddCircle";
import MaintenancePlanningProduct from "./MaintenancePlanningProduct";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};


//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    dataIndex === "endDate" || dataIndex === "startDate"
      ? form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? moment(record[dataIndex], "DD-MM-YYYY HH:mm") : ""
      })
      : form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
      });
  };

  const toggleSave = async () => {
    try {
      if (dataIndex === "endDate") {
        const values = await form.validateFields();
        values.endDate = Boolean(values.endDate) ? moment(values.endDate).format("DD-MM-YYYY HH:mm") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else if (dataIndex === "startDate") {
        const values = await form.validateFields();
        values.startDate = Boolean(values.startDate) ? moment(values.startDate).format("DD-MM-YYYY HH:mm") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record }, { ...values });
      }
    } catch (errInfo) { }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <>
        {dataIndex === "deliveryPlace" ? (
          <>
            <Form.Item
              label={<FormattedMessage id="MaintenancePlanning.town" defaultMessage="Town" />}
              name="town"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Form.Item
              label={<FormattedMessage id="MaintenancePlanning.city" defaultMessage="City" />}
              name="city"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Button id="SaveButton" onClick={toggleSave} style={{ width: "100%" }} variant="success">
              <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
            </Button>
          </>
        ) : (
          <Form.Item
            style={{
              margin: 0
            }}
            name={dataIndex}
          >
            {dataIndex === "endDate" || dataIndex === "startDate" ? (
              <DatePicker ref={inputRef} onBlur={toggleSave} format={"DD-MM-YYYY HH:mm"} showTime={{ format: "HH:mm" }} />
            ) : dataIndex === "currency" ? (
              <Select ref={inputRef} onChange={toggleSave} onBlur={toggleSave} showSearch optionFilterProp="children" >
                {Boolean(record.currencySelectItems) &&
                  record.currencySelectItems.length > 0 &&
                  record.currencySelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            ) : dataIndex === "unit" ? (
              <Cascader
                style={{ width: "100%" }}
                options={record.unitSelectItems}
                ref={inputRef} onChange={toggleSave}
                onBlur={toggleSave}
              />
            ) : (
              <InputNumber parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")}
                min={0} ref={inputRef} onPressEnter={toggleSave} onBlur={toggleSave} />
            )}
          </Form.Item>
        )}
      </>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion

export class MaintenancePlanning extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "MaintenancePlanning",
      controllerName: "maintenancePlanning",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      fileList: [],
      myTasks: [],
      processType: "calendar",
      detailList: [],
      searchCriteriaModels: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
    };
  }

  formRef = React.createRef();
  formRefConfirmation = React.createRef();
  formRefAverage = React.createRef();

  componentDidMount = async () => {
    this.getMaintenancePlanningByDate();
    this.fillComboboxes();
    fillFilterOperations();
    this.fillActivityId();
  };

  fillComboboxes = async () => {

    const { workLocationId, companyProjectId } = this.state;

    var responseCompanyProjectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/companyProjectId");
    if (responseCompanyProjectId.type === "ERROR") {
      error(responseCompanyProjectId);
    }
    this.setState({
      companyProjectSelectItems: Boolean(responseCompanyProjectId.data) ? responseCompanyProjectId.data : []
    });

    var responseWorkLocationId = await handleRequest("GET", "/api/" + this.state.controllerName + "/workLocationId");
    if (responseWorkLocationId.type === "ERROR") {
      error(responseWorkLocationId);
    }
    this.setState({
      workLocationSelectItems: Boolean(responseWorkLocationId.data) ? responseWorkLocationId.data : []
    });
  };

  fillProductId = async () => {
    const { workLocationId, companyProjectId } = this.state;
    if (Boolean(workLocationId) && workLocationId > 0 && Boolean(companyProjectId) && companyProjectId > 0) {
      var responseProductId = await handleRequest("GET", "/api/" + this.state.controllerName + "/productId/" + workLocationId + "/" + companyProjectId);
      if (responseProductId.type === "ERROR") {
        error(responseProductId);
      }
      this.setState({
        stockCardSelectItems: Boolean(responseProductId.data) ? responseProductId.data : []
      });
    } else {
      this.setState({
        stockCardSelectItems: []
      });
    }
  }

  fillActivityId = async () => {
    const { workLocationId } = this.state;
    if (Boolean(workLocationId) && workLocationId > 0) {
      var responseActivityLogId = await handleRequest("GET", "/api/" + this.state.controllerName + "/activityLogId" + "/" + workLocationId);
      if (responseActivityLogId.type === "ERROR") {
        error(responseActivityLogId);
      }
      this.setState({
        activityLogSelectItems: Boolean(responseActivityLogId.data) ? responseActivityLogId.data : []
      });
    }
    else {
      this.setState({
        activityLogSelectItems: []
      });
    }
  }

  getMaintenancePlanningByDate = async date => {
    let firstDayOfMonth = moment()
      .clone()
      .startOf("month");
    let lastDayOfMonth = moment()
      .clone()
      .endOf("month");
    if (Boolean(date)) {
      firstDayOfMonth = moment(date)
        .clone()
        .startOf("month");
      lastDayOfMonth = moment(date)
        .clone()
        .endOf("month");
    }
    let newObj = {
      startDate: firstDayOfMonth,
      endDate: lastDayOfMonth
    };
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/maintenancePlanningByDate", newObj);
    if (response.type === "ERROR") {
      error(response);
    }
    this.setState({
      tableList: Boolean(response.data) && response.data.length > 0 ? response.data : []
    });
  };

  edit = row => {
    console.log("row", row);
    document.getElementById("kt_scrolltop").click();

    let fileList = [];
    let drawing = Boolean(row.drawing) ? row.drawing.split(",") : [];
    if (Boolean(drawing) && drawing.length > 0) {
      drawing.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };
        fileList.push(obj);
      });
    }

    var products = [];
    if (Boolean(row.products) && row.products.length > 0) {
      row.products.forEach(element => {
        products.push(element.productId);
      });
    }

    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.setFieldsValue({
        ...row,
        products: products,
      });
    }

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      fileList: fileList,
      workLocationId: row.workLocationId,
      companyProjectId: row.companyProjectId,
    }, () => {
      this.fillProductId();
      this.fillActivityId();
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.resetFields();

      this.formRef.current.setFieldsValue({
        status: "On_Going",
        singleStepped: "Single",
      });
    }
    this.setState({
      id: null,
      next: false,
      previous: false,
      startDate: null,
      modalStepDate: null,
      singleStepped: "Single",

      loading: false,
      sortField: "",
      sortOrder: "",
      fileList: []
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    let { workLocationId } = this.state;
    if (Boolean(workLocationId) && workLocationId > 0) {
      console.log("values", values);
      const newItem = {
        ...values,
        id: this.state.id,
        workLocationId: workLocationId,
        drawing: Boolean(this.state.fileList) && this.state.fileList.length > 0 ? this.state.fileList[0].url : ""
      };

      let products = [];
      if (newItem.products && newItem.products.length > 0) {
        newItem.products.forEach(element => {
          products.push({ productId: element });
        });
      }
      newItem.products = products;

      if (Boolean(products != null && products.length > 0)) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/products", newItem);
        if (response.type === "ERROR") {
          error(response);
        } else {
          if (Boolean(response.data) && response.data.length > 0) {
            this.setState({
              saveData: newItem,
              modalFormShow: false,
              modalDetailShow: true,
              detailList: response.data,
            });
          } else {
            showWarning("Product not found.");
          }
        }
      } else {
        showWarning("Please select product.");
      }
    } else {
      showWarning("Work Location not found.");
    }
  };

  saveAll = async () => {
    const { selectedRowKeys, saveData, detailList } = this.state;
    if (Boolean(saveData) && Boolean(selectedRowKeys) && selectedRowKeys.length > 0) {
      let products = [];
      selectedRowKeys.forEach(element => {
        let row = detailList.find(p => p.projectTakeOffQuantityId === element);
        if (Boolean(row)) {
          let endDate = null, startDate = null;
          if (Boolean(row.endDate)) {
            endDate = moment(row.endDate, "DD-MM-YYYY HH:mm");
          }
          if (Boolean(row.startDate)) {
            startDate = moment(row.startDate, "DD-MM-YYYY HH:mm");
          }
          products.push({ productId: element, startDate: startDate, endDate: endDate, processingQuantity: row.processingQuantity });
        }
      });
      saveData.products = products;

      if (saveData.id === null) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName, saveData);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + saveData.id, saveData);
      }

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        this.getMaintenancePlanningByDate();
        showSuccess();
        this.setState({
          modalDetailShow: false,
          saveData: null,
        });
      }
    } else {
      showWarning("Please select item.");
    }
  };

  dateCellRender = value => {
    const { workLocationSelectItems } = this.state;
    return (
      <ul className="events">
        {Boolean(workLocationSelectItems) &&
          workLocationSelectItems.length > 0 &&
          workLocationSelectItems.map(item => {
            return (
              <li key={item.value} onClick={() => this.onClickDetail(item, value)}>
                <Badge status={this.getStatusRandom()} text={item.value} />
              </li>
            );
          })}
      </ul >
    );
  };

  getStatusRandom = () => {
    let random = Math.floor(Math.random() * 5);
    if (random === 0) {
      return "processing";
    } else if (random === 1) {
      return "warning";
    } else if (random === 2) {
      return "success";
    } else if (random === 3) {
      return "default";
    } else if (random === 4) {
      return "error";
    }
  };

  getStatus = status => {
    if (Boolean(status)) {
      if (status === "On_Going" || status === "Delay") {  //Devam Ediyor
        return "processing";
      }
      else if (status === "Generated" || status === "New_Task") {   //Yeni Görev
        return "processing";
      }
      else if (status === "Response" || status === "Action_Start" || status === "Rejected"
        || status === "Completed") {   //Cevap
        return "warning";
      } else if (status === "Done") {   //Tamamlandı
        return "success";
      } else if (status === "Hold") {    //Askıya Alındı
        return "default";
      } else if (status === "Deleted") {  //Silindi
        return "error";
      }
    }
    return "error";
  };

  onClickDetail = (row, date) => {
    this.setState(
      {
        hideUpdate: true,
        hideSave: false,
        hideInputs: true,
        modalFormShow: true,
        workLocationId: row.key,
        workLocationName: row.value,
        date: date
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  onPanelChangeClick = (value, mode) => {
    this.setState({
      currentDate: value
    }, () => {
      const { pagination } = this.state;
      this.restartTable({ pagination });
    });
  };

  handleSelectClick = date => {
    if (!this.state.currentDate) {
      const tableList = this.state.tableList.filter(p => moment(p.endDate) >= date && moment(p.startDate) <= date);
      this.setState(
        {
          hideUpdate: true,
          hideSave: false,
          hideInputs: true,
          // modalFormShow: true
        },
        () => {
          if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
            if (!(Boolean(tableList) && tableList.length > 0)) {
              this.setState({
                startDate: Boolean(date) ? moment(date).startOf("day") : moment().startOf("day"),
              });
            }
          }
        }
      );
    }
  };

  handlePriceListQuantityOurExpectedPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.detailList];
      const item = newData.find(item => row.projectTakeOffQuantityId === item.projectTakeOffQuantityId);
      if (Boolean(value) && Boolean(item)) {
        if (item.processingQuantity === value.processingQuantity) return;

        item.processingQuantity = value.processingQuantity;
        this.setState({
          detailList: newData
        });
      }
    }
  };

  handlePriceListForecastedDurationOurExpectedPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.detailList];
      const item = newData.find(item => row.projectTakeOffQuantityId === item.projectTakeOffQuantityId);
      if (Boolean(value) && Boolean(item)) {
        if (item.forecastedDuration === value.forecastedDuration) return;

        item.forecastedDuration = value.forecastedDuration;
        this.setState({
          detailList: newData
        });
      }
    }
  };

  handlePriceListStartDateDetailList = async (row, value) => {
    console.log("row", row);
    console.log("value", value);
    if (Boolean(value)) {
      const newData = [...this.state.detailList];
      const item = newData.find(item => row.projectTakeOffQuantityId === item.projectTakeOffQuantityId);
      if (Boolean(value) && Boolean(item)) {
        if (item.startDate === value.startDate) return;

        item.startDate = value.startDate;

        this.setState({
          detailList: newData
        });

        // this.getEndDate(item, newData);
      }
    }
  };

  getEndDate = async (item, newData) => {
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/getEndDate", item);
    if (response.type === "ERROR") {
      error(response);
    } else {
      console.log("response.data", response.data);
      item.endDate = Boolean(response.data) ? response.data.endDate : null

      this.setState({
        detailList: newData
      });
    }
  }

  handlePriceListEndDateDetailList = async (row, value) => {
    console.log("row", row);
    console.log("value", value);
    if (Boolean(value)) {
      const newData = [...this.state.detailList];
      const item = newData.find(item => row.projectTakeOffQuantityId === item.projectTakeOffQuantityId);
      if (Boolean(value) && Boolean(item)) {
        if (item.endDate === value.endDate) return;

        item.endDate = value.endDate;
        this.setState({
          detailList: newData
        });
      }
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys: selectedRowKeys,
    });
  };

  avaliableQuantityModal = async (selectedRow) => {
    let avaliableQuantityList = [];
    if (Boolean(selectedRow.projectTakeOffQuantityId) && selectedRow.projectTakeOffQuantityId > 0 && Boolean(selectedRow.companyProjectId) && selectedRow.companyProjectId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/avaliable/" + selectedRow.projectTakeOffQuantityId + "/" + selectedRow.companyProjectId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(Boolean(response.data)) && Boolean(response.data.avaliable) && response.data.avaliable.length > 0) {
          avaliableQuantityList = response.data.avaliable;
        }
      }
    }
    console.log("avaliableQuantityList", avaliableQuantityList);

    this.setState({
      modalAvaliableQuantityShow: true,
      avaliableQuantityList: avaliableQuantityList,
      selectedRow: selectedRow,
    }, () => {
      this.setColumnsAvaliable();
    });
  };

  setColumnsAvaliable() {
    const { selectedRow, avaliableQuantityList } = this.state;

    let columnsAvaliable = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="StockControl.Stock" defaultMessage="Stock" />,
        key: "stockName",
        render: record => {
          return record.stockName;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="StockControl.InStock" defaultMessage="In Stock" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        },
        width: 100
      },
    ];

    if (Boolean(selectedRow) && Boolean(selectedRow.dimension)) {
      let dimensionList = selectedRow.dimension.split(", ");
      console.log("selectedRow", dimensionList);

      if (Boolean(avaliableQuantityList) && avaliableQuantityList.length > 0) {
        avaliableQuantityList.forEach((element) => {
          if (Boolean(element.allDimensions)) {
            let dimensionRowList = element.allDimensions.split(", ");

            if (Boolean(dimensionRowList) && dimensionRowList.length > 1) {
              dimensionRowList.forEach(element => {
                if (!dimensionList.includes(element)) {
                  dimensionList.push(element);
                }
              });
            }
          }
        });
      }

      if (Boolean(dimensionList) && dimensionList.length > 0) {
        dimensionList.forEach(element => {
          let dimension = element.split(":");
          if (Boolean(dimension) && dimension.length > 1) {

            let obj = {
              title: dimension[0],
              dataIndex: dimension[0],
              //[dimension[0]]: dimension[1],
            };

            let addColumn = columnsAvaliable.find(p => p.title === obj.title);
            if (!Boolean(addColumn)) {
              columnsAvaliable.push(obj);
            }

            if (Boolean(avaliableQuantityList) && avaliableQuantityList.length > 0) {
              avaliableQuantityList.forEach((element, index) => {

                if (Boolean(element.allDimensions) && element.allDimensions.includes(dimension[0])) {
                  let dimensionRowList = element.allDimensions.split(", ");
                  if (Boolean(dimensionRowList) && dimensionRowList.length > 1) {
                    console.log("dimensionRowList", dimensionRowList);
                    dimensionRowList.forEach(subRow => {
                      console.log("subRow", subRow);

                      let subDimension = subRow.split(":");
                      if (Boolean(subDimension) && subDimension.length > 1 && dimension[0] === subDimension[0]) {
                        element.key = index + 1;
                        element[dimension[0]] = subDimension[1];
                        return;
                      }
                    });
                  }
                } else {
                  element.key = index + 1;
                  element[dimension[0]] = "";
                }
              });
            }
          }
        });
      }
    }
    this.setState({
      columnsAvaliable: columnsAvaliable,
    }, () => {
      console.log("avaliableQuantityList", avaliableQuantityList);
      console.log("columnsAvaliable", this.state.columnsAvaliable);
    });
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableMaintenanceList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableMaintenanceList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableMaintenanceList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      workLocationId: this.state.workLocationId,
      date: this.state.date,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  averageModal = async (row) => {
    this.setState({
      modalAverageShow: true,
      selectedAverageRow: row,
      average: row.average
    }, () => {
      this.formRefAverage.current.setFieldsValue({
        average: row.average
      });
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    };

    function customHeaderRender({ value, type, onChange, onTypeChange }) {
      const onPrev = () => {
        if (type === 'date') {
          onChange(value.clone().subtract(1, 'month'));
        } else if (type === 'month') {
          onChange(value.clone().subtract(1, 'year'));
        } else if (type === 'year') {
          onChange(value.clone().subtract(10, 'year'));
        }
      };

      const onNext = () => {
        if (type === 'date') {
          onChange(value.clone().add(1, 'month'));
        } else if (type === 'month') {
          onChange(value.clone().add(1, 'year'));
        } else if (type === 'year') {
          onChange(value.clone().add(10, 'year'));
        }
      };

      return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <LeftOutlined onClick={onPrev} style={{ cursor: 'pointer' }} />
          {type === 'year' ? (
            <span>{value.format('YYYY')}</span>
          ) : (
            <span>{value.format('MMMM YYYY')}</span>
          )}
          <RightOutlined onClick={onNext} style={{ cursor: 'pointer' }} />
        </div>
      );
    }

    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="MaintenancePlanning.Product" defaultMessage="Product" />,
        key: "product",
        width: 150,
        ellipsis: true,
        render: record => {
          return <Tooltip
            color={"white"}
            title={<div className="w-100" style={{ color: "black" }}>
              {record.product}
            </div>}
          >
            {record.product}
          </Tooltip>
        }
      },
      {
        title: <FormattedMessage id="MaintenancePlanning.Requirement" defaultMessage="Requirement" />,
        width: 100,
        children: [
          {
            title: <FormattedMessage id="MaintenancePlanning.Dimension" defaultMessage="Dimension" />,
            key: "dimension",
            width: 150,
            ellipsis: true,
            render: record => {
              return <Tooltip
                color={"white"}
                title={<div className="w-100" style={{ color: "black" }}>
                  {record.dimension}
                </div>}
              >
                {record.dimension}
              </Tooltip>
            }
          },
          {
            title: <FormattedMessage id="MaintenancePlanning.Qty" defaultMessage="Qty" />,
            key: "requirementQuantity",
            width: 100,
            render: record => {
              return record.requirementQuantity;
            },
          },
        ]
      },
      {
        title: <FormattedMessage id="MaintenancePlanning.Processing" defaultMessage="Processing" />,
        width: 100,
        children: [
          {
            title: <FormattedMessage id="MaintenancePlanning.Qty" defaultMessage="Qty" />,
            key: "processingQuantity",
            width: 100,
            render: record => {
              return record.processingQuantity
            },
            editable: true,
            onCell: record => ({
              record,
              editable: true,
              dataIndex: "processingQuantity",
              handleSave: this.handlePriceListQuantityOurExpectedPrice
            })
          },
          {
            title: <FormattedMessage id="MaintenancePlanning.ForecastedDurationHMS" defaultMessage="Forecasted Duration(h:m:s)" />,
            key: "forecastedDuration",
            width: 100,
            render: record => {
              if (Boolean(record.processingQuantity) && Boolean(record.average)) {
                let totalTime = record.processingQuantity * record.average;

                const second = parseInt(totalTime % 60);
                const minute = parseInt(Math.floor((totalTime / 60) % 60));
                const hour = parseInt(Math.floor(totalTime / 3600));

                record.second = second;
                record.minute = minute;
                record.hour = hour;

                if (record.averageManuel) {
                  return <div style={{ cursor: 'pointer', color: 'blue' }}
                    onClick={() => this.averageModal(record)}>
                    {record.hour + ":" + record.minute + ":" + record.second}
                  </div>
                } else {
                  return record.hour + ":" + record.minute + ":" + record.second;
                }
              }
              return <AntButton
                onClick={() => this.averageModal(record)}
                style={{ border: "0px", color: "#1BC5BD" }}
                icon={<ZoomInOutlined fontSize="large" />}
              ></AntButton>;
            },
          },
          {
            title: <FormattedMessage id="MaintenancePlanning.StartDate" defaultMessage="Start Date" />,
            key: "startDate",
            width: 200,
            render: record => {
              if (Boolean(record.startDate))
                return record.startDate;
              return "";
            },
            editable: true,
            onCell: record => ({
              record,
              editable: true,
              dataIndex: "startDate",
              handleSave: this.handlePriceListStartDateDetailList
            })
          },
          {
            title: <FormattedMessage id="MaintenancePlanning.EndDate" defaultMessage="End Date" />,
            key: "endDate",
            width: 200,
            render: record => {
              if (Boolean(record.endDate))
                return record.endDate;
              return "";
            },
            editable: true,
            onCell: record => ({
              record,
              editable: true,
              dataIndex: "endDate",
              handleSave: this.handlePriceListEndDateDetailList
            })
          },
        ]
      },
      {
        title: <FormattedMessage id="MaintenancePlanning.Inventory" defaultMessage="Inventory" />,
        width: 100,
        children: [
          {
            title: <FormattedMessage id="MaintenancePlanning.Material" defaultMessage="Material" />,
            key: "material",
            width: 150,
            ellipsis: true,
            render: record => {
              return <Tooltip
                color={"white"}
                title={<div className="w-100" style={{ color: "black" }}>
                  {record.material}
                </div>}
              >
                {record.material}
              </Tooltip>
            }
          },
          {
            title: <FormattedMessage id="MaintenancePlanning.Qty" defaultMessage="Qty" />,
            key: "inventoryQuantity",
            width: 150,
            ellipsis: true,
            render: record => {
              return (
                Boolean(record.inventoryQuantity) && record.inventoryQuantity > 0 ?
                  <React.Fragment>
                    <div
                      className="d-inline"
                      style={{
                        cursor: "pointer",
                        color: "#3699FF"
                      }}
                      onClick={() => this.avaliableQuantityModal(record)}
                    >
                      <span className="mr-5">
                        <i style={{ color: "#3699FF" }} className="fas fa-eye"></i>
                      </span>
                      {record.inventoryQuantity}
                    </div>
                  </React.Fragment>
                  : ""
              );
            }
          },
        ]
      },
      {
        title: <FormattedMessage id="MaintenancePlanning.ExpectedStocks" defaultMessage="Expected Stocks" />,
        width: 100,
        children: [
          {
            title: <FormattedMessage id="MaintenancePlanning.Qty" defaultMessage="Qty" />,
            key: "expectedStocksQuantity",
            width: 150,
            ellipsis: true,
            render: record => {
              return <Tooltip
                color={"white"}
                title={<div className="w-100" style={{ color: "black" }}>
                  {record.expectedStocksQuantity}
                </div>}
              >
                {record.expectedStocksQuantity}
              </Tooltip>
            }
          },
          {
            title: <FormattedMessage id="MaintenancePlanning.Remarks" defaultMessage="Remarks" />,
            key: "expectedRemarks",
            width: 150,
            ellipsis: true,
            render: record => {
              return <Tooltip
                color={"white"}
                title={<div className="w-100" style={{ color: "black" }}>
                  {record.expectedRemarks}
                </div>}
              >
                {record.expectedRemarks}
              </Tooltip>
            }
          },
        ]
      },
    ];

    const columnsMaintenance = [
      {
        key: "key",
        title: <FormattedMessage id="General.TitleActions" defaultMessage="Actions" />,
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="MaintenancePlanning.CompanyProject" defaultMessage="Company Project" />,
        key: "companyProjectId",
        render: record => {
          return record.companyProjectName;
        },
        ...getColumnFilter({
          inputName: "companyProjectId", inputType: "SELECT",
          selectItems: Boolean(this.state["companyProjectIdSelectItems"]) ? this.state["companyProjectIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="MaintenancePlanning.ActivityLog" defaultMessage="Activity Log" />,
        key: "activityLogName",
        render: record => {
          return record.activityLogName;
        },
        ...getColumnFilter({
          inputName: "activityLogId", inputType: "SELECT",
          selectItems: Boolean(this.state["activityLogIdSelectItems"]) ? this.state["activityLogIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="DocumentControlDetail.Drawing" defaultMessage="Drawing" />,
        key: "drawing",
        render: record => {
          if (Boolean(record.drawing)) {
            return <Image width={80} src={record.drawing}></Image>
          }
        },
      },
    ];

    return (
      <Badge.Ribbon
        text={< FormattedMessage id="MaintenancePlanning.pageTitle" defaultMessage="Maintenance Planning" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>

          <Row gutter={[16, 16]}>
            <Col md={21}></Col>
            <Col md={3}>
              <Select value={this.state.processType} style={{ width: "100%" }} showSearch optionFilterProp="children"
                onChange={e => {
                  this.setState({
                    processType: e
                  });
                }}>
                <Option key={"calendar"} value={"calendar"}>
                  {"Calendar"}
                </Option>
                <Option key={"gantt"} value={"gantt"}>
                  {"Gantt"}
                </Option>
              </Select>
            </Col>
          </Row>
          <br />
          <Row gutter={[16, 16]}>
            <Col md={24}>
              {this.state.processType === "calendar" ?
                <Calendar mode="date" headerRender={customHeaderRender} dateCellRender={this.dateCellRender} onPanelChange={this.onPanelChangeClick}
                  onSelect={date => {
                    this.setState({
                      currentDate: null
                    }, () => {
                      this.handleSelectClick(date);
                    });
                  }}
                />
                :
                <MaintenancePlanningGantt />
              }
            </Col>
          </Row>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>{" "}
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <AntModal
            title={this.state.workLocationName + "/" + moment(this.state.date).format("DD-MM-YYYY")}
            open={this.state.modalFormShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState({
                modalFormShow: false,
              });
            }}

            width={1300}
            centered
          >
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>

              <Row gutter={[16, 16]}>
                <Col md={23}></Col>
                <Col md={1}>
                  <AntButton
                    style={{ border: "0px" }}
                    hidden={!this.state.hideInputs}
                    onClick={() => {
                      this.onClickCreateNew();
                      this.fillActivityId();
                    }}
                    icon={<CreateIcon color="secondary" fontSize="large" />}
                  ></AntButton>
                  <AntButton
                    style={{ border: "0px" }}
                    hidden={this.state.hideInputs}
                    onClick={this.onClickCreateNew}
                    icon={<RemoveCircle color="error" fontSize="large" />}
                  ></AntButton>
                </Col>
              </Row>

              <div hidden={this.state.hideInputs}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="MaintenancePlanning.ActivityLogId" defaultMessage="Activity" />}
                    name="activityLogId"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear style={{ width: "100%" }} showSearch optionFilterProp="children">
                      {Boolean(this.state["activityLogSelectItems"]) &&
                        this.state["activityLogSelectItems"].length > 0 &&
                        this.state["activityLogSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="MaintenancePlanning.Project" defaultMessage="Project" />}
                    name="companyProjectId"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear style={{ width: "100%" }} showSearch optionFilterProp="children"
                      onChange={(value) => {
                        this.setState({
                          companyProjectId: value
                        }, () => {
                          this.fillProductId();
                        });
                      }}>
                      {Boolean(this.state["companyProjectSelectItems"]) &&
                        this.state["companyProjectSelectItems"].length > 0 &&
                        this.state["companyProjectSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="MaintenancePlanning.Product" defaultMessage="Product" />}
                    name="products"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear style={{ width: "100%" }} showSearch optionFilterProp="children" mode="multiple">
                      {Boolean(this.state["stockCardSelectItems"]) &&
                        this.state["stockCardSelectItems"].length > 0 &&
                        this.state["stockCardSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="MaintenancePlanning.Drawing" defaultMessage="Drawing" />}
                    name="drawing"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Upload
                      {...props}
                      showUploadList={true}
                      fileList={this.state.fileList}
                      onChange={({ fileList }) => {
                        if (fileList.length === 1) {
                          if (Boolean(fileList[0].response)) {
                            fileList[0].url = fileList[0].response.url;
                            showSuccess("file uploaded successfully");
                          }
                          this.setState({
                            fileList: fileList
                          });
                        }
                      }}
                      beforeUpload={fileList => {
                        if (this.state.fileList.length > 0) {
                          showError("You have been already choose a file! For change firstly remove the uploaded file.");
                          return false;
                        }
                        return true;
                      }}
                      onRemove={fileList => {
                        this.setState({
                          fileList: []
                        });
                      }}
                    >
                      <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                        <UploadOutlined /> Upload
                      </AntButton>
                    </Upload>
                  </Form.Item>
                }

                <div hidden={this.state.hideSave}>
                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="primary">
                          <FormattedMessage id="MaintenancePlanning.CreatePlan" defaultMessage="Create Plan" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </div>
                <div hidden={this.state.hideUpdate}>
                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                          <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                        </Button>
                      </Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                          <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </div>
              </div>
            </Form>

            <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
              <Table
                rowKey={"id"}
                bordered
                scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                columns={columnsMaintenance}
                dataSource={this.state.tableMaintenanceList}
                loading={this.state.loading}
                onChange={this.handleTableChange}
                pagination={this.state.pagination}
                expandable={{
                  expandedRowRender: record => Boolean(record) &&
                    <MaintenancePlanningProduct main={record} random={this.state.random} date={this.state.date} />,
                  rowExpandable: () => {
                    return true;
                  },
                  onExpand: (expanded, record) => {
                    this.setState({ random: Math.random() });
                  }
                }}
              />
            </div>
          </AntModal>

          <AntModal
            title={<FormattedMessage id="MaintenancePlanning.Detail" defaultMessage="Detail" />}
            open={this.state.modalDetailShow}
            okButtonProps={{ hidden: false }}
            okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
            onCancel={() => {
              this.setState({
                modalDetailShow: false,
                saveDate: null
              });
            }}
            onOk={() => {
              this.formRefConfirmation.current.submit();
            }}
            width={1300}
            centered
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveAll} onFinishFailed={onFinishFailed} ref={this.formRefConfirmation}>
              <Table
                bordered
                rowKey={"projectTakeOffQuantityId"}
                components={components}
                rowSelection={rowSelection}
                rowClassName={() => "editable-row"}
                scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                columns={columns}
                dataSource={this.state.detailList}
                loading={this.state.loading}
                pagination={false}
              />
            </Form>
          </AntModal>

          <AntModal
            title={<FormattedMessage id="StockControl.AvaliableQuantity" defaultMessage="Avaliable Quantity" />}
            closable="false"
            open={this.state.modalAvaliableQuantityShow}
            onCancel={() => {
              this.setState({ modalAvaliableQuantityShow: false });
            }}
            width={800}
            centered
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ modalAvaliableQuantityShow: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                </Button>

                {/* <Button style={{ width: "100%" }} variant="success" onClick={() => this.saveAvaliableQuantity()}>
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button> */}
              </Space>
            ]}
          >
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Table
                bordered
                size="small"
                components={components}
                rowClassName={() => "editable-row"}
                columns={this.state.columnsAvaliable}
                dataSource={this.state.avaliableQuantityList}
                loading={this.state.loading}
                pagination={false}
              />
            </div>
          </AntModal>

          <AntModal
            title={<FormattedMessage id="MaintenancePlanning.AverageUnitTime" defaultMessage="Average unit time" />}
            closable="false"
            open={this.state.modalAverageShow}
            okButtonProps={{ hidden: false }}
            okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
            onCancel={() => {
              this.setState({ modalAverageShow: false });
            }}
            onOk={() => {
              const { average, selectedAverageRow } = this.state;
              if (Boolean(average) && Boolean(selectedAverageRow)) {
                selectedAverageRow.average = average;
                selectedAverageRow.averageManuel = true;
                this.setState({
                  modalAverageShow: false,
                });
              } else {
                showWarning("Average unit time is required");
              }
            }}
            width={600}
            centered
          >
            <Form initialValues={{ remember: false }} ref={this.formRefAverage}>
              <Form.Item
                {...layout}
                name="average"
                label={<FormattedMessage id="RoomQuantity.AverageSec" defaultMessage="Average (Sec)" />}
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber onChange={(value) => {
                  this.setState({
                    average: value
                  });
                }}
                  parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} className="w-100" placeholder="    " min={0} />
              </Form.Item>
            </Form>
          </AntModal>
        </div >
      </Badge.Ribbon >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(MaintenancePlanning);