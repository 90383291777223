import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import { showError, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import {
  Form,
  Table,
  Col,
  Row,
  Radio,
  Select,
  Space,
  InputNumber,
  Tooltip,
  Spin,
  Checkbox,
  Breadcrumb
} from "antd";
import { Input } from "antd";
import { fillFilterOperations } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, MinusCircleOutlined, CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import DraggableModal from "../../../Components/DraggableModal";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const { Option } = Select;

export class ProjectsTreeCompanyFloor extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ProjectsTreeCompanyFloor",
      controllerName: "apartment",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      houseRoomList: [],
      loadingButtonSubmit: false,
      apartmentIdSelectItems: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRefNumberOfFloors = React.createRef();
  formRefSectionHouses = React.createRef();
  formRefFloorHeights = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
    fillFilterOperations();
    this.apartmentsWithHouseNotNull();
  };

  apartmentsWithHouseNotNull = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/apartmentsWithHouseNotNull");
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        tableVisibleModal: true,
        tableList: Boolean(response.data) ? response.data : []
      });
    }
  };

  getNumberOfFloors = async () => {
    this.setState(
      {
        sectionsOfApartmentsModal: false,
        numberOfFloorsModal: true,
        tableVisibleModal: false
      },
      async () => {
        if (Boolean(this.formRefNumberOfFloors) && Boolean(this.formRefNumberOfFloors.current)) {
          var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/numberOfFloorsNotNull");
          if (response.type === "ERROR") {
            error(response);
          } else {
            let numberOfFloorsNotNull = Boolean(response.data) ? response.data : null;
            if (Boolean(numberOfFloorsNotNull)) {
              let allSameVariableType = numberOfFloorsNotNull.allSameVariableType;
              let floor = numberOfFloorsNotNull.floor;
              let numberOfFloors = numberOfFloorsNotNull.numberOfFloors;
              this.formRefNumberOfFloors.current.setFieldsValue({
                allSameVariableType: allSameVariableType,
                floor: floor,
                numberOfFloors: numberOfFloors
              });
              this.setState({
                allSameVariableType: allSameVariableType
              });
            } else {
              this.formRefNumberOfFloors.current.resetFields();
            }
          }
        }
        this.setState({
          loadingButtonSubmit: false
        });
      }
    );
  };

  fillComboboxes = async () => {
    this.fillApartmentTypes("Apartment.ApartmentType");
    this.fillCode("Apartment.TypeOfHouses");

    var responseApartmentHeightUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/apartmentHeightUnit");
    if (responseApartmentHeightUnit.type === "ERROR") {
      error(responseApartmentHeightUnit);
    }

    this.setState({
      apartmentHeightUnitSelectItems: Boolean(responseApartmentHeightUnit.data) ? responseApartmentHeightUnit.data : []
    });
  };

  fillApartmentsWithHouseNotNull = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/apartmentsWithHouseNotNull");
    if (response.type === "ERROR") {
      error(response);
    }

    this.setState({
      apartmentsWithHouseNotNull: Boolean(response.data) ? response.data : []
    });
  };

  fillFloors = async apartmentId => {
    if (Boolean(apartmentId) && apartmentId > 0) {
      var responseHouseTypeId = await handleRequest("GET", "/api/" + this.state.controllerName + "/floor/" + apartmentId);
      if (responseHouseTypeId.type === "ERROR") {
        error(responseHouseTypeId);
      }

      this.setState({
        floorSelectItems: Boolean(responseHouseTypeId.data) ? responseHouseTypeId.data : []
      });
    } else {
      this.setState({
        floorSelectItems: []
      });
    }
  };

  fillApartmentSection = async apartmentId => {
    let currentSectionSelectItems = [],
      sectionSelectItems = [];
    if (Boolean(apartmentId) && apartmentId > 0) {
      var responseSection = await handleRequest("GET", "/api/" + this.state.controllerName + "/sectionId/" + apartmentId);
      if (responseSection.type === "ERROR") {
        error(responseSection);
      } else {
        sectionSelectItems = Boolean(responseSection.data) ? responseSection.data : [];
      }

      const sectionHouses = this.formRefSectionHouses.current.getFieldValue("sectionHouses");

      console.log("sectionHouses", sectionHouses);

      if (Boolean(sectionHouses) && sectionHouses.length > 0) {
        console.log("sectionSelectItems", sectionSelectItems);
        if (sectionSelectItems.length > 0) {
          sectionSelectItems.forEach(element => {
            console.log("element", element);
            const row = sectionHouses.find(p => Boolean(p) && Boolean(p.apartmentId) && p.apartmentId === apartmentId && p.section === element.key);
            console.log("row", row);
            if (!row) {
              const hasSec = currentSectionSelectItems.find(p => p.key === element.key);
              console.log("hasSec", currentSectionSelectItems);
              if (!hasSec) {
                console.log("element.key", element.key);
                currentSectionSelectItems.push({ key: element.key, value: element.value });
              }
              console.log("1111");
            } else {
              console.log("2222");
            }
          });
        }
      }
    }

    console.log("currentSectionSelectItems", currentSectionSelectItems);
    this.setState({
      currentSectionSelectItems: currentSectionSelectItems,
      sectionSelectItems: sectionSelectItems
    });
  };

  fillApartmentSectionNumberOfFloors = async apartmentId => {
    let currentSectionSelectItems = [],
      sectionSelectItems = [];
    if (Boolean(apartmentId) && apartmentId > 0) {
      var responseSection = await handleRequest("GET", "/api/" + this.state.controllerName + "/sectionId/" + apartmentId);
      if (responseSection.type === "ERROR") {
        error(responseSection);
      } else {
        sectionSelectItems = Boolean(responseSection.data) ? responseSection.data : [];
      }

      const numberOfFloors = this.formRefNumberOfFloors.current.getFieldValue("numberOfFloors");

      console.log("numberOfFloors", numberOfFloors);

      if (Boolean(numberOfFloors) && numberOfFloors.length > 0) {
        console.log("sectionSelectItems", sectionSelectItems);
        if (sectionSelectItems.length > 0) {
          sectionSelectItems.forEach(element => {
            console.log("element", element);
            const row = numberOfFloors.find(p => Boolean(p) && Boolean(p.id) && p.id === apartmentId && p.section === element.key);
            console.log("row", row);
            if (!row) {
              const hasSec = currentSectionSelectItems.find(p => p.key === element.key);
              console.log("hasSec", currentSectionSelectItems);
              if (!hasSec) {
                console.log("element.key", element.key);
                currentSectionSelectItems.push({ key: element.key, value: element.value });
              }
              console.log("1111");
            } else {
              console.log("2222");
            }
          });
        }
      }
    }

    console.log("currentSectionSelectItems", currentSectionSelectItems);
    this.setState({
      currentSectionSelectItems: currentSectionSelectItems,
      sectionSelectItems: sectionSelectItems
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/pagingFloor";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: "",
        tableVisibleModal: true
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder,
          tableVisibleModal: true
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: "",
          tableVisibleModal: true
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  resetInputs = () => { };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  fillTypeHouseRoomsModal() {
    this.setState(
      {
        typeOfHousesModal: false,
        typeHouseRoomsModal: true
      },
      async () => {
        if (Boolean(this.formRefSectionHouses) && Boolean(this.formRefSectionHouses.current)) {
          this.fillCode("Apartment.TypeOfHouses");
          await this.fillApartmentsWithHouseNotNull();

          const { apartmentsWithHouseNotNull } = this.state;
          let sectionHouses = [];
          console.log("data", apartmentsWithHouseNotNull);
          if (Boolean(apartmentsWithHouseNotNull) && apartmentsWithHouseNotNull.length > 0) {
            apartmentsWithHouseNotNull.forEach(element => {
              sectionHouses.push({
                apartmentTypeId: element.apartmentTypeId,
                apartmentTypeName: element.apartmentTypeName,
                section: element.section,
                floor: element.floor,
                houseTypeId: element.houseTypeId,
                numberOfHouse: element.numberOfHouse
              });
            });
          }
          this.formRefSectionHouses.current.setFieldsValue({
            sectionHouses: sectionHouses
          });
          //this.calcApartmentsForSectionHouses();
        }
        this.setState({
          loadingButtonSubmit: false
        });
      }
    );
  }

  calcApartmentsForSectionHouses = async () => {
    const sectionHouses = this.formRefSectionHouses.current.getFieldValue("sectionHouses");
    console.log("sectionHouses", sectionHouses);
    const { apartmentIdSelectItems } = this.state;
    console.log("apartmentIdSelectItems", apartmentIdSelectItems);

    const selectedApartments = [];
    async function fetchSectionData(apartment, sectionHouses, controllerName) {
      console.log("sectionHouses", sectionHouses);

      const currentApartmentId = apartment.key;
      if (currentApartmentId > 0 && Boolean(sectionHouses) && sectionHouses.length > 0) {
        const responseSection = await handleRequest("GET", `/api/${controllerName}/sectionId/${currentApartmentId}`);
        if (responseSection.type === "ERROR") {
          error(responseSection);
        } else {
          const sectionSelectItems = responseSection.data || [];
          console.log("sectionSelectItems", sectionSelectItems);
          if (sectionSelectItems.length > 0) {
            sectionSelectItems.forEach(element => {
              console.log("element", element);
              const row = sectionHouses.find(
                p => Boolean(p) && Boolean(p.apartmentId) && Boolean(p.section) && p.apartmentId === currentApartmentId && p.section === element.key
              );
              console.log("row", row);
              if (!row) {
                const hasApt = selectedApartments.find(p => p.key === apartment.key);
                if (!hasApt) {
                  selectedApartments.push({ key: apartment.key, value: apartment.value });
                }
              }
            });
          } else {
            const hasApt = selectedApartments.find(p => p.key === apartment.key);
            if (!hasApt) {
              selectedApartments.push({ key: apartment.key, value: apartment.value });
            }
            console.log("3333");
          }
        }
      } else {
        const hasApt = selectedApartments.find(p => p.key === apartment.key);
        if (!hasApt) {
          selectedApartments.push({ key: apartment.key, value: apartment.value });
        }
        console.log("4444");
      }
    }

    for (const apartment of apartmentIdSelectItems) {
      await fetchSectionData(apartment, sectionHouses, this.state.controllerName);
    }

    console.log("selectedApartments", selectedApartments);
    this.setState({
      currentApartmentIdSelectItems: selectedApartments
    });
  };

  calcApartmentTypeAndSection = async () => {
    const numberOfFloors = this.formRefNumberOfFloors.current.getFieldValue("numberOfFloors");
    const { apartmentIdSelectItems } = this.state;
    const selectedApartments = [];
    async function fetchSectionData(apartment, numberOfFloors, controllerName) {
      console.log("numberOfFloors", numberOfFloors);

      const currentApartmentId = apartment.key;
      if (currentApartmentId > 0 && Boolean(numberOfFloors) && numberOfFloors.length > 0) {
        const responseSection = await handleRequest("GET", `/api/${controllerName}/sectionId/${currentApartmentId}`);
        if (responseSection.type === "ERROR") {
          error(responseSection);
        } else {
          const sectionSelectItems = responseSection.data || [];
          console.log("sectionSelectItems", sectionSelectItems);
          if (sectionSelectItems.length > 0) {
            sectionSelectItems.forEach(element => {
              console.log("element", element);
              const row = numberOfFloors.find(
                p => Boolean(p) && Boolean(p.id) && Boolean(p.section) && p.id === currentApartmentId && p.section === element.key
              );
              console.log("row", row);
              if (!row) {
                const hasApt = selectedApartments.find(p => p.key === apartment.key);
                if (!hasApt) {
                  selectedApartments.push({ key: apartment.key, value: apartment.value });
                }
              }
            });
          } else {
            const hasApt = selectedApartments.find(p => p.key === apartment.key);
            if (!hasApt) {
              selectedApartments.push({ key: apartment.key, value: apartment.value });
            }
            console.log("3333");
          }
        }
      } else {
        const hasApt = selectedApartments.find(p => p.key === apartment.key);
        if (!hasApt) {
          selectedApartments.push({ key: apartment.key, value: apartment.value });
        }
        console.log("4444");
      }
    }

    for (const apartment of apartmentIdSelectItems) {
      await fetchSectionData(apartment, numberOfFloors, this.state.controllerName);
    }

    console.log("selectedApartments", selectedApartments);
    this.setState({
      currentApartmentIdSelectItems: selectedApartments
    });
  };

  saveNumberOfFloors = async values => {
    console.log("values", values);
    if (Boolean(values.allSameVariableType)) {
      if (values.allSameVariableType === "AllSame") {
        let list = [];
        if (Boolean(values.floorHeights) && values.floorHeights.length > 0) {
          values.floorHeights.forEach(floor => {
            list.push({
              floor: floor.floor,
              unit: floor.unit,
              lowerHeight: floor.lowerHeight,
              upperHeight: floor.upperHeight,
              basement: Boolean(floor.basement) ? floor.basement : false,
              roof: Boolean(floor.roof) ? floor.roof : false
            });
          });
        } else {
          if (Boolean(values.floor) && values.floor > 0) {
            list.push({
              ...values,
              foundation: true
            });
            if (values.basement) {
              list.push({
                ...values,
                basement: true
              });
            }
            for (let index = 1; index <= values.floor; index++) {
              list.push({
                ...values,
                floor: index
              });
            }
            if (values.roof) {
              list.push({
                ...values,
                roof: true
              });
            }
          }
        }
        console.log("list", list);

        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveNumberOfAllFloors", list);
          if (response.type === "ERROR") {
            error(response);
          } else {
            this.setState({
              numberOfFloorsModal: false
            }, () => {
              this.fillTypeHouseRoomsFloors();
            });
          }
        } else {
          showWarning("Not found.");
        }
      } else if (Boolean(values.numberOfFloors) && values.numberOfFloors.length > 0) {
        const isValid = values.numberOfFloors.every(
          element => Boolean(element) && Boolean(element.apartmentTypeId) && Boolean(element.section) && Boolean(element.floor)
        );
        if (!isValid) {
          showWarning("Apartment type, section and floor are required.");
          this.setState({
            loadingButtonSubmit: false
          });
        } else {
          let list = [];
          if (Boolean(values.numberOfFloors) && values.numberOfFloors.length > 0) {
            values.numberOfFloors.forEach(element => {
              if (Boolean(element.floorHeights) && element.floorHeights.length > 0) {
                element.floorHeights.forEach(floor => {
                  list.push({
                    ...element,
                    floor: floor.floor,
                    unit: floor.unit,
                    lowerHeight: floor.lowerHeight,
                    upperHeight: floor.upperHeight,
                    basement: Boolean(floor.basement) ? floor.basement : false,
                    roof: Boolean(floor.roof) ? floor.roof : false
                  });
                });
              } else {
                if (Boolean(element.floor) && element.floor > 0) {
                  list.push({
                    ...element,
                    floor: "Foundation"
                  });
                  if (element.basement) {
                    list.push({
                      ...element,
                      floor: "Basement"
                    });
                  }
                  for (let index = 1; index <= element.floor; index++) {
                    list.push({
                      ...element,
                      floor: index
                    });
                  }
                  if (element.roof) {
                    list.push({
                      ...element,
                      floor: "Roof"
                    });
                  }
                }
              }
            });
          }
          console.log("list", list);

          if (Boolean(list) && list.length > 0) {
            var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveNumberOfFloors", list);
            if (response.type === "ERROR") {
              error(response);
            } else {
              this.setState({
                numberOfFloorsModal: false
              }, () => {
                this.fillTypeHouseRoomsFloors();
              });
            }
          } else {
            showWarning("Not found.");
          }
        }
      }

      this.apartmentTypeOfHouses();
    } else {
      showWarning("Please select type.");
      this.setState({
        loadingButtonSubmit: false
      });
    }
  };

  fillTypeHouseRoomsFloors() {
    this.cancel();

    this.setState(
      {
        typeHouseRoomsModal: true
      },
      () => {
        let typeOfHouses = [];
        if (this.state["Apartment.TypeOfHousesSelectItems"] && this.state["Apartment.TypeOfHousesSelectItems"].length > 0) {
          this.state["Apartment.TypeOfHousesSelectItems"].forEach(element => {
            typeOfHouses.push({ houseTypeName: element.name });
          });
        }
        console.log("TypeOfHousesSelectItems", this.state["Apartment.TypeOfHousesSelectItems"]);
        console.log("typeOfHouses", typeOfHouses);
      }
    );

    this.setState({
      loadingButtonSubmit: false,
      numberOfFloorsModal: false
    });
  }

  apartmentTypeOfHouses = async () => {
    this.setState(
      {
        typeOfHousesModal: false,
        typeHouseRoomsModal: true
      },
      async () => {
        if (Boolean(this.formRefSectionHouses) && Boolean(this.formRefSectionHouses.current)) {
          this.fillCode("Apartment.TypeOfHouses");
          await this.fillApartmentsWithHouseNotNull();

          const { apartmentsWithHouseNotNull } = this.state;
          let sectionHouses = [];
          console.log("data", apartmentsWithHouseNotNull);
          if (Boolean(apartmentsWithHouseNotNull) && apartmentsWithHouseNotNull.length > 0) {
            apartmentsWithHouseNotNull.forEach(element => {
              sectionHouses.push({
                apartmentTypeId: element.apartmentTypeId,
                apartmentTypeName: element.apartmentTypeName,
                section: element.section,
                floor: element.floor,
                houseTypeId: element.houseTypeId,
                numberOfHouse: element.numberOfHouse
              });
            });
          }
          this.formRefSectionHouses.current.setFieldsValue({
            sectionHouses: sectionHouses
          });
          //this.calcApartmentsForSectionHouses();
        }
        this.setState({
          loadingButtonSubmit: false
        });
      }
    );
  };

  saveSectionHouses = async values => {
    const sectionHouses = values.sectionHouses;
    if (Boolean(sectionHouses) && sectionHouses.length > 0) {
      const isValid = sectionHouses.every(
        element =>
          Boolean(element) && Boolean(element.apartmentTypeId) && Boolean(element.section) && Boolean(element.floor) && Boolean(element.houseTypeId)
      );
      if (!isValid) {
        showWarning("Apartment type, section, floor and house type are required.");
        this.setState({
          loadingButtonSubmit: false
        });
      } else {
        let houseTypeIds = [];
        let apartmentIds = [];
        let apartmentIdsMap = {};
        values.sectionHouses.forEach(house => {
          if (!houseTypeIds.includes(house.houseTypeId)) {
            houseTypeIds.push(house.houseTypeId);
          }
          if (!apartmentIdsMap[house.apartmentTypeId]) {
            apartmentIdsMap[house.apartmentTypeId] = true;
            apartmentIds.push({ apartmentTypeId: house.apartmentTypeId, maxFloor: house.floor, section: house.section });
          }
        });
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveSectionHouses", values.sectionHouses);
        if (response.type === "ERROR") {
          error(response);
        } else {
          this.setState({
            typeHouseRoomsModal: false
          });
        }
        this.setState({
          loadingButtonSubmit: false
        });
      }
    } else {
      showWarning("Apartment type, section, floor and house type are required.");
      this.setState({
        loadingButtonSubmit: false
      });
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillApartmentTypes = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data,
        currentApartmentTypeSelectItems: response.data
      });
    }
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        title: <FormattedMessage id="Apartment.ApartmentType" defaultMessage="Apartment Type" />,
        key: "id",
        render: record => {
          return record.apartmentTypeName;
        }
      },
      {
        title: <FormattedMessage id="Apartment.Section" defaultMessage="Section" />,
        key: "section",
        render: record => {
          return record.section;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.section) ? this.state.pagination.section : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  section: e.target.value,
                  sectionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.sectionColor) ? this.state.pagination.sectionColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Apartment.Floor" defaultMessage="Floor" />,
        key: "floor",
        render: record => {
          return record.floor;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <InputNumber
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.floor) ? this.state.pagination.floor : null}
              onChange={async value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  floor: value,
                  floorColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.floorColor) ? this.state.pagination.floorColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="RoomQuantity.HouseType" defaultMessage="House Type" />,
        key: "houseTypeId",
        render: record => {
          return record.houseTypeName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <InputNumber
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.houseTypeId) ? this.state.pagination.houseTypeId : null}
              onChange={async value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  houseTypeId: value,
                  houseTypeIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.houseTypeIdColor) ? this.state.pagination.houseTypeIdColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Apartment.NumberOfHouse" defaultMessage="Number of House" />,
        key: "numberOfHouse",
        render: record => {
          return record.numberOfHouse;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <InputNumber
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.numberOfHouse) ? this.state.pagination.numberOfHouse : null}
              onChange={async value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  numberOfHouse: value,
                  numberOfHouseColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.numberOfHouseColor) ? this.state.pagination.numberOfHouseColor : "#bfbfbf" }}
          />
        ),
        sorter: true
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <div className="card card-custom card-stretch">
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">

              <Breadcrumb separator=">">
                <Breadcrumb.Item className={this.state.tableVisibleModal ? "customBreadcrumbItem" : ""}>
                  <a
                    href="#"
                    onClick={() => {
                      this.setState(
                        {
                          tableVisibleModal: true,

                          numberOfFloorsModal: false,
                          typeHouseRoomsModal: false
                        },
                        () => {
                          this.apartmentsWithHouseNotNull();
                        }
                      );
                    }}
                  >
                    <Space>
                      <SVG style={{ width: '20px', height: '20px' }} src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>
                      <FormattedMessage id="ProjectsTreeCompanyFloor.Floors" defaultMessage="Floors" />
                    </Space>
                  </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a
                    href="#"
                    className={this.state.numberOfFloorsModal ? "customBreadcrumbItem" : ""}
                    onClick={() => {
                      this.setState(
                        {
                          numberOfFloorsModal: true,

                          tableVisibleModal: false,
                          typeHouseRoomsModal: false
                        },
                        () => {
                          this.getNumberOfFloors();
                        }
                      );
                    }}
                  >
                    <FormattedMessage id="ProjectsTreeCompanyFloor.NumberOfFloors" defaultMessage="Number Of Floors" />
                  </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a
                    href="#"
                    className={this.state.typeHouseRoomsModal ? "customBreadcrumbItem" : ""}
                    onClick={() => {
                      this.setState(
                        {
                          typeHouseRoomsModal: true,

                          tableVisibleModal: false,
                          numberOfFloorsModal: false
                        },
                        () => {
                          this.fillTypeHouseRoomsModal();
                        }
                      );
                    }}
                  >
                    <FormattedMessage id="ProjectsTreeCompanyFloor.NumberOfHouse" defaultMessage="Number of House" />
                  </a>
                </Breadcrumb.Item>
              </Breadcrumb>

            </div>
            <div className="card-toolbar"></div>
          </div>

          <br />
          <h3 className="card-label font-weight-bolder text-dark">
            <FormattedMessage id="ProjectsTreeCompanyFloor.Floors" defaultMessage="Floors" />
          </h3>
        </div>

        <>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span>
                      <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table bordered size="small" columns={columns} dataSource={this.state.tableList} loading={this.state.loading} pagination={false} />
          </div>

          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={19} lg={19} xl={19} xxl={19}></Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Button
                onClick={() => {
                  this.props.nextTab();
                }}
                style={{ width: "100%" }}
                variant="primary"
              >
                <FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />
              </Button>
            </Col>
          </Row>
        </>

        <DraggableModal
          title={<FormattedMessage id="ProjectsTreeCompanyFloor.NumberOfFloors" defaultMessage="Number Of Floors" />}
          centered
          width={1200}
          open={this.state.numberOfFloorsModal}
          onCancel={() => this.setState({ numberOfFloorsModal: false })}
          onOk={() => {
            this.formRefNumberOfFloors.current.submit();
          }}
          okText={<FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          cancelButtonProps={{ hidden: true }}
          content={
            <Spin spinning={this.state.loadingButtonSubmit}>
              <Form initialValues={{ remember: false }} onFinish={this.saveNumberOfFloors} ref={this.formRefNumberOfFloors}>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProjectsTreeCompanyFloor.Type" defaultMessage="Type" />}
                  name="allSameVariableType"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Radio.Group
                    onChange={e => {
                      this.setState({
                        allSameVariableType: e.target.value
                      });
                    }}
                  >
                    <Radio key={"AllSame"} value={"AllSame"}>
                      {<FormattedMessage id="ProjectsTreeCompanyFloor.AllSame" defaultMessage="All Same" />}
                    </Radio>
                    <Radio key={"Variable"} value={"Variable"}>
                      {<FormattedMessage id="ProjectsTreeCompanyFloor.Variable" defaultMessage="Variable" />}
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                {Boolean(this.state.allSameVariableType) && (
                  <>
                    {this.state.allSameVariableType === "AllSame" && (
                      <>
                        <Form.Item hidden name="floorHeights"></Form.Item>
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="ProjectsTreeCompanyFloor.Floor" defaultMessage="Floor" />}
                          name="floor"
                          rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <InputNumber min={1} style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="ProjectsTreeCompanyFloor.Basement" defaultMessage="Basement" />}
                          name="basement"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          valuePropName="checked"
                        >
                          <Checkbox />
                        </Form.Item>

                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="ProjectsTreeCompanyFloor.Roof" defaultMessage="Roof" />}
                          name="roof"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          valuePropName="checked"
                        >
                          <Checkbox />
                        </Form.Item>
                      </>
                    )}

                    {this.state.allSameVariableType === "Variable" && (
                      <Form.List name="numberOfFloors">
                        {(fields, { add, remove }) => {
                          const handleAddClick = () => {
                            const numberOfFloors = this.formRefNumberOfFloors.current.getFieldValue("numberOfFloors");
                            if (Boolean(numberOfFloors) && numberOfFloors.length > 0) {
                              const isValid = numberOfFloors.every(
                                element => Boolean(element) && Boolean(element.apartmentTypeId) && Boolean(element.section) && Boolean(element.floor)
                              );
                              if (!isValid) {
                                showWarning("Apartment type, section and floor are required.");
                              } else {
                                this.calcApartmentTypeAndSection();
                                add();
                              }
                            } else {
                              this.calcApartmentTypeAndSection();
                              add();
                            }
                          };

                          const handleRemoveClick = fieldKey => {
                            remove(fieldKey);
                            this.calcApartmentTypeAndSection();
                          };

                          return (
                            <>
                              <TableBoot bordered size="sm">
                                <thead>
                                  <tr>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"ProjectsTreeCompanyFloor.ApartmentType"} defaultMessage={"Apartment Type"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"ProjectsTreeCompanyFloor.Sections"} defaultMessage={"Sections"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"ProjectsTreeCompanyFloor.NumberOfFloors"} defaultMessage={"Number Of Floors"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"ProjectsTreeCompanyFloor.Basement"} defaultMessage={"Basement"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"ProjectsTreeCompanyFloor.Roof"} defaultMessage={"Roof"} />
                                    </th>
                                    <th colSpan="1"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map(field => {
                                    return (
                                      <tr key={field.key}>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "apartmentTypeId"]}>
                                            <Select
                                              showSearch
                                              allowClear
                                              optionFilterProp="children"
                                              style={{ width: "100%" }}
                                              onChange={value => {
                                                this.fillApartmentSectionNumberOfFloors(value);
                                              }}
                                            >
                                              {Boolean(this.state["currentApartmentTypeSelectItems"]) &&
                                                this.state["currentApartmentTypeSelectItems"].length > 0 &&
                                                this.state["currentApartmentTypeSelectItems"].map(i => (
                                                  <Option key={i.id} value={i.id}>
                                                    {i.name}
                                                  </Option>
                                                ))}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "section"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["currentSectionSelectItems"]) &&
                                                this.state["currentSectionSelectItems"].length > 0 &&
                                                this.state["currentSectionSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "floor"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item style={{ width: "100px" }} name={[field.name, "basement"]} valuePropName="checked">
                                            <Checkbox />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item style={{ width: "100px" }} name={[field.name, "roof"]} valuePropName="checked">
                                            <Checkbox />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <MinusCircleOutlined onClick={() => handleRemoveClick(field.name)} />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                  {/* <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ProjectsTreeCompanyFloor.AddNew"} defaultMessage={"Add New"} />
                                  </AntButton>
                                </td>
                              </tr> */}
                                </tbody>
                              </TableBoot>
                            </>
                          );
                        }}
                      </Form.List>
                    )}
                  </>
                )}
              </Form>
            </Spin>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="ProjectsTreeCompanyFloor.NumberOfHouse" defaultMessage="Number Of House" />}
          centered
          width={1200}
          open={this.state.typeHouseRoomsModal}
          onCancel={() => this.setState({ typeHouseRoomsModal: false })}
          onOk={() => {
            this.formRefSectionHouses.current.submit();
          }}
          okText={<FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          cancelButtonProps={{ hidden: true }}
          content={
            <Spin spinning={this.state.loadingButtonSubmit}>
              <Form initialValues={{ remember: false }} onFinish={this.saveSectionHouses} ref={this.formRefSectionHouses}>
                <Form.List name="sectionHouses">
                  {(fields, { add, remove }) => {
                    const handleAddClick = () => {
                      const sectionHouses = this.formRefSectionHouses.current.getFieldValue("sectionHouses");
                      if (Boolean(sectionHouses) && sectionHouses.length > 0) {
                        const isValid = sectionHouses.every(
                          element =>
                            Boolean(element) &&
                            Boolean(element.apartmentTypeId) &&
                            Boolean(element.section) &&
                            Boolean(element.floor) &&
                            Boolean(element.houseTypeId)
                        );
                        if (!isValid) {
                          showWarning("Apartment type, section, floor and house type are required.");
                        } else {
                          //this.calcApartmentsForSectionHouses();
                          add();
                        }
                      } else {
                        //this.calcApartmentsForSectionHouses();
                        add();
                      }
                    };

                    const handleRemoveClick = fieldKey => {
                      remove(fieldKey);
                      //this.calcApartmentsForSectionHouses();
                    };

                    const handleCopyClick = fieldKey => {
                      let newIndex = parseInt(fieldKey, 10) + 1;
                      const sectionHouses = this.formRefSectionHouses.current.getFieldValue("sectionHouses");
                      let apartmentSection = { ...sectionHouses[fieldKey] };
                      apartmentSection.houseTypeId = null;
                      apartmentSection.numberOfHouse = null;
                      apartmentSection.copy = true;

                      sectionHouses.splice(newIndex, 0, apartmentSection);

                      console.log("sectionHouses", sectionHouses);

                      this.formRefSectionHouses.current.setFieldsValue({
                        sectionHouses: sectionHouses
                      });
                      //this.calcApartmentsForSectionHouses();
                    };

                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ProjectsTreeCompanyFloor.ApartmentType"} defaultMessage={"Apartment Type"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ProjectsTreeCompanyFloor.Sections"} defaultMessage={"Sections"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ProjectsTreeCompanyFloor.Floor"} defaultMessage={"Floor"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ProjectsTreeCompanyFloor.HouseType"} defaultMessage={"House Type"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ProjectsTreeCompanyFloor.NumberOfHouse"} defaultMessage={"Number of House"} />
                              </th>
                              <th colSpan="1"> </th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <>
                                  <Form.Item hidden name={[field.name, "apartmentTypeId"]}>
                                    <Select
                                      showSearch
                                      allowClear
                                      optionFilterProp="children"
                                      style={{ width: "100%" }}
                                      onChange={value => {
                                        this.fillFloors(value);
                                        this.fillApartmentSection(value);
                                      }}
                                    >
                                      {Boolean(this.state["currentApartmentTypeSelectItems"]) &&
                                        this.state["currentApartmentTypeSelectItems"].length > 0 &&
                                        this.state["currentApartmentTypeSelectItems"].map(i => (
                                          <Option key={i.id} value={i.id}>
                                            {i.name}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item name={[field.name, "apartmentTypeName"]}>
                                        <Input readOnly></Input>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item name={[field.name, "section"]}>
                                        <Input readOnly></Input>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item name={[field.name, "floor"]}>
                                        <Input readOnly></Input>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "houseTypeId"]}>
                                        <Select allowClear style={{ width: "100px" }} optionFilterProp="children">
                                          {Boolean(this.state["Apartment.TypeOfHousesSelectItems"]) &&
                                            this.state["Apartment.TypeOfHousesSelectItems"].length > 0 &&
                                            this.state["Apartment.TypeOfHousesSelectItems"].map(i => (
                                              <Option key={i.id} value={i.id}>
                                                {i.name}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "numberOfHouse"]}>
                                        <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Space>
                                        <PlusOutlined onClick={() => handleCopyClick(field.name)} />

                                        {Boolean(this.formRefSectionHouses.current.getFieldValue("sectionHouses")) &&
                                          this.formRefSectionHouses.current.getFieldValue("sectionHouses").length > 0 &&
                                          Boolean(this.formRefSectionHouses.current.getFieldValue("sectionHouses")[field.name]) &&
                                          Boolean(this.formRefSectionHouses.current.getFieldValue("sectionHouses")[field.name].copy) &&
                                          this.formRefSectionHouses.current.getFieldValue("sectionHouses")[field.name].copy && (
                                            <MinusCircleOutlined onClick={() => handleRemoveClick(field.name)} />
                                          )}
                                      </Space>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}

                            {/* <tr>
                          <td colSpan={11}>
                            <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                              <FormattedMessage id={"ProjectsTreeCompanyFloor.AddNew"} defaultMessage={"Add New"} />
                            </AntButton>
                          </td>
                        </tr> */}
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              </Form>
            </Spin>
          }
        />
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsTreeCompanyFloor);