import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Form, Spin, Card, Progress } from "antd";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../../ApiConnector";
import { showSuccess, error, showWarning } from "../../../MessageHelper";
import { Link } from "react-router-dom";

export class TenderingProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "TenderingProgress",
      controllerName: "tenderingProgress",
      id: null,
      projectName: "",
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      nodesShowHide: [],
      loadedKeys: [],
      unitIdSelectItems: [],
      settingsList: [],
      overallList: [],
      tenderingList: [],
      biddingList: [],
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }
  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getTenderingProgress");
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        overallList: Boolean(response.data) ? response.data.overall : [],
        tenderingList: Boolean(response.data) ? response.data.tendering : [],
        biddingList: Boolean(response.data) ? response.data.bidding : []
      });
    }
  };

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     settingsList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       settingsList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       settingsList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      tenderingLogId: this.props.tenderingLogId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id,
      type: this.state.type
    };

    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
      this.setState({
        id: null,
        showDeleteModal: false
      });
    }
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      description: "",
      projectName: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      showOrderModal: false,
      showPasteModal: false
    });
  };

  onDragEnter = info => {};

  saveAll = async values => {
    console.log("values", values);
    if (Boolean(values) && Boolean(values.settingsList) && values.settingsList.length > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAll", values.settingsList);
      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess();
        this.props.close();
      }
    } else {
      showWarning("Not found.");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const { overallList, tenderingList, biddingList } = this.state;

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.saveAll} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <>
            <Card id="tendering-progress-wrapper" style={{ width: "100%", margin: "20px 0", overflow: "hidden" }}>
              <h5>
                <FormattedMessage id="TenderingProgress.Overall" defaultMessage="Overall" />
              </h5>
              <Spin spinning={!overallList}>
                <div id="top-risk-scores-widget" style={{ display: "flex", flexWrap: "wrap" }}>
                  {overallList &&
                    overallList.map((i, index) => (
                      <div key={index} className="box" style={{ marginRight: "20px", marginBottom: "20px" }}>
                        {i.value === "Total" ? (
                          <Progress width={60} type="circle" strokeColor={"#C03C38"} percent={i.key} format={() => (Boolean(i.key) ? i.key : 0)} />
                        ) : (
                          <Progress width={60} type="circle" strokeColor={"#C03C38"} percent={i.key} />
                        )}
                        <div className="texts" style={{ marginTop: "10px" }}>
                          <Link className="copy-to-link">
                            <span style={{ whiteSpace: "nowrap" }}>{i.value}</span>
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              </Spin>
            </Card>

            <Card id="tendering-progress-wrapper" style={{ width: "100%", margin: "20px 0", overflow: "hidden" }}>
              <h5>
                <FormattedMessage id="TenderingProgress.Tendering" defaultMessage="Tendering" />
              </h5>
              <Spin spinning={!tenderingList}>
                <div id="top-risk-scores-widget" style={{ display: "flex", flexWrap: "wrap" }}>
                  {tenderingList &&
                    tenderingList.map((i, index) => (
                      <div key={index} className="box" style={{ marginRight: "20px", marginBottom: "20px" }}>
                        {i.value === "Total" ? (
                          <Progress width={60} type="circle" strokeColor={"#C03C38"} percent={i.key} format={() => (Boolean(i.key) ? i.key : 0)} />
                        ) : (
                          <Progress width={60} type="circle" strokeColor={"#C03C38"} percent={i.key} />
                        )}
                        <div className="texts" style={{ marginTop: "10px" }}>
                          <Link className="copy-to-link">
                            <span style={{ whiteSpace: "nowrap" }}>{i.value}</span>
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              </Spin>
            </Card>

            <Card id="tendering-progress-wrapper" style={{ width: "100%", margin: "20px 0", overflow: "hidden" }}>
              <h5>
                <FormattedMessage id="TenderingProgress.Bidding" defaultMessage="Bidding" />
              </h5>
              <Spin spinning={!biddingList}>
                <div id="top-risk-scores-widget" style={{ display: "flex", flexWrap: "wrap" }}>
                  {biddingList &&
                    biddingList.map((i, index) => (
                      <div key={index} className="box" style={{ marginRight: "20px", marginBottom: "20px" }}>
                        {i.value === "Total" ? (
                          <Progress width={60} type="circle" strokeColor={"#C03C38"} percent={i.key} format={() => (Boolean(i.key) ? i.key : 0)} />
                        ) : (
                          <Progress width={60} type="circle" strokeColor={"#C03C38"} percent={i.key} />
                        )}
                        <div className="texts" style={{ marginTop: "10px" }}>
                          <Link className="copy-to-link">
                            <span style={{ whiteSpace: "nowrap" }}>{i.value}</span>
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              </Spin>
            </Card>
          </>
        </Form>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TenderingProgress);
