import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Button as AntButton, Drawer, Divider, InputNumber, Input } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined, RemoveCircle, } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import DraggableModal from "../../../Components/DraggableModal";
import BiddingLogQuotationEquipment from "./BiddingLogQuotationEquipment";
import BiddingLogQuotationMaterial from "./BiddingLogQuotationMaterial";
import BiddingLogQuotationManpower from "./BiddingLogQuotationManpower";
import BiddingLogQuotationExpenses from "./BiddingLogQuotationExpenses";
import CreateIcon from "@material-ui/icons/AddCircle";
import BiddingLogQuotationFittings from "./BiddingLogQuotationFittings";
import BiddingLogMobilization from "../bidding/BiddingLogMobilization";

export class BiddingLogQuotation extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "BiddingLogQuotation",
      controllerName: "biddingLog",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      manpowerHideInputs: true,
      materialHideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      expensesList: [],
      quotation: {
        "material": 0, "equipment": 0, "manpower": 0, "manpowerDirect": 0, "manpowerIndirect": 0, "expenses": 0, "mobilizasyon": 0,
        "materialPercent": 0, "equipmentPercent": 0, "manpowerPercent": 0, "manpowerDirectPercent": 0, "manpowerIndirectPercent": 0, "expensesPercent": 0,
      },
      total: 0,
      profitPercent: 0,
      profit: 0,
      taxPercent: 22,
      tax: 0,
      grandTotal: 0,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      subTotal: 0,
    };
  }

  formRef = React.createRef();
  formRefWorkingPeriod = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.getQuotation();
  };

  getQuotation = async () => {
    let total = 0;
    let subTotal = 0;
    let expensesList = [];
    let quotation = [];
    const responseExpenses = await handleRequest("GET", "/api/" + this.state.controllerName + "/type/BiddingLogQuotationExpenses.PaymentGroup/" + this.props.tenderingLogId);
    if (responseExpenses.type === "ERROR") {
      error(responseExpenses);
    } else {
      expensesList = Boolean(responseExpenses.data) ? responseExpenses.data : [];

      if (Boolean(expensesList) && expensesList.length > 0) {
        expensesList.forEach(expense => {
          if (Boolean(expense.amountRate) && expense.amountRate > 0) {
            total += expense.amountRate;
            if (expense.name !== "Contract Expenses") {
              subTotal += expense.amountRate;
            }
          }
        });
      }
    }

    var responseitemId = await handleRequest("GET", "/api/" + this.state.controllerName + "/getQuotation/" + this.props.tenderingLogId);
    if (responseitemId.type === "ERROR") {
      error(responseitemId);
    } else {
      quotation = Boolean(responseitemId.data) ? responseitemId.data : {
        "material": 0, "equipment": 0, "manpower": 0, "manpowerDirect": 0, "manpowerIndirect": 0, "expenses": 0, "mobilizasyon": 0,
        "materialPercent": 0, "equipmentPercent": 0, "manpowerPercent": 0, "manpowerDirectPercent": 0, "manpowerIndirectPercent": 0, "expensesPercent": 0,
      };
      total += quotation.material + quotation.equipment + quotation.manpower + quotation.expenses + quotation.mobilizasyon;
      subTotal += quotation.material + quotation.equipment + quotation.manpower + quotation.mobilizasyon;
    }

    this.setState({
      total: total,
      subTotal: subTotal,
      expensesList: expensesList,
      quotation: quotation,
    }, () => {
      this.calcProfitTaxGrandTotal();
    });
  };

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const workingPeriod = this.state.formRefWorkingPeriod;
    const newItem = {
      ...values,
      ...workingPeriod,
      id: this.state.id,
      tenderingLogId: this.props.tenderingLogId,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  saveWorkingPeriod = async values => {
    console.log("Values", values);
    this.setState({ formRefWorkingPeriod: values, showWorkingPeriodModal: false });
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  toggleCollapse = async (id, collapse) => {
    if (Boolean(id) && id > 0) {
      if (collapse) {
        var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/payment/" + id + "/" + this.props.tenderingLogId);
        if (response.type === "ERROR") {
          error(response);
        } else {
          this.setState({
            ["paymentSelectItems" + id]: Boolean(response.data) ? response.data : []
          });
        }
      } else {
        this.setState({
          ["paymentSelectItems" + id]: []
        });
      }
      this.setState(prevState => ({
        ["expensesHideInputs" + id]: !prevState["expensesHideInputs" + id]
      }));
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const { quotation, total, grandTotal } = this.state;

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>

          <Row gutter={[16, 16]}>
            <Col md={2}></Col>
            <Col md={2}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.materialHideInputs}
                onClick={() => {
                  if (this.state.materialHideInputs) {
                    this.setState({
                      materialHideInputs: false,
                    });
                  } else {
                    this.setState({
                      materialHideInputs: true,
                    });
                  }
                }}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.materialHideInputs}
                onClick={() => {
                  if (this.state.materialHideInputs) {
                    this.setState({
                      materialHideInputs: false,
                    });
                  } else {
                    this.setState({
                      materialHideInputs: true,
                    });
                  }
                }}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
            <Col md={10}>
              <Button
                onClick={() => {
                  this.setState({
                    showMaterialModal: true,
                  });
                }}
                style={{ width: "100%", textAlign: "left" }}
                variant="secondary"
              >
                {<FormattedMessage id="BiddingLogQuotation.Material" defaultMessage="Material" />}
              </Button>
            </Col>
            <Col md={4}>
              <Input value={!Boolean(quotation.material) ? 0 : quotation.material.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")} readOnly
                style={{ width: "100%", textAlign: "right" }} />
            </Col>
            <Col md={2}>
              <Input
                value={grandTotal === 0 ? 0 : `${((quotation.material * 100) / grandTotal).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}%`}
                readOnly style={{ width: "100%", textAlign: "right" }} />
            </Col>
          </Row>

          {!this.state.materialHideInputs && <>
            <br />
            <Row gutter={[16, 16]}>
              <Col md={6}>

              </Col>
              <Col md={8}>
                <Button
                  onClick={() => {
                    this.setState({
                      showMaterialModal: true,
                    });
                  }}
                  style={{ width: "100%", textAlign: "left" }}
                  variant="secondary"
                >
                  {<FormattedMessage id="BiddingLogQuotation.Material" defaultMessage="Material" />}
                </Button>
              </Col>
              <Col md={4}>
                <Input value={!Boolean(quotation.material) ? 0 : quotation.material.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")} readOnly
                  style={{ width: "100%", textAlign: "right" }} />
              </Col>
              <Col md={2}>
                <Input
                  value={grandTotal === 0 ? 0 : `${((quotation.material * 100) / grandTotal).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}%`}
                  readOnly style={{ width: "100%", textAlign: "right" }} />
              </Col>
            </Row> <br />
            <Row gutter={[16, 16]}>
              <Col md={6}>

              </Col>
              <Col md={8}>
                <Button
                  onClick={() => {
                    this.setState({
                      showFittingsModal: true,
                    });
                  }}
                  style={{ width: "100%", textAlign: "left" }}
                  variant="secondary"
                >
                  {<FormattedMessage id="BiddingLogQuotation.Fittings" defaultMessage="Fittings" />}
                </Button>
              </Col>
              <Col md={4}>
                <Input value={!Boolean(quotation.manpowerIndirect) ? 0 : quotation.manpowerIndirect.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")} readOnly
                  style={{ width: "100%", textAlign: "right" }} />
              </Col>
              <Col md={2}>
                <Input
                  value={grandTotal === 0 ? 0 : `${((quotation.manpowerIndirect * 100) / grandTotal).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}%`}
                  readOnly style={{ width: "100%", textAlign: "right" }} />
              </Col>
            </Row>
          </>
          }

          <br />
          <Row gutter={[16, 16]}>
            <Col md={4}>

            </Col>
            <Col md={10}>
              <Button
                onClick={() => {
                  this.setState({
                    showEquipmentModal: true,
                  });
                }}
                style={{ width: "100%", textAlign: "left" }}
                variant="secondary"
              >
                {<FormattedMessage id="BiddingLogQuotation.Equipment" defaultMessage="Equipment" />}
              </Button>
            </Col>
            <Col md={4}>
              <Input value={!Boolean(quotation.equipment) ? 0 : quotation.equipment.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")} readOnly
                style={{ width: "100%", textAlign: "right" }} />
            </Col>
            <Col md={2}>
              <Input
                value={grandTotal === 0 ? 0 : `${((quotation.equipment * 100) / grandTotal).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}%`}
                readOnly style={{ width: "100%", textAlign: "right" }} />
            </Col>
          </Row>
          <br />
          <Row gutter={[16, 16]}>
            <Col md={2}></Col>
            <Col md={2}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.manpowerHideInputs}
                onClick={() => {
                  if (this.state.manpowerHideInputs) {
                    this.setState({
                      manpowerHideInputs: false,
                    });
                  } else {
                    this.setState({
                      manpowerHideInputs: true,
                    });
                  }
                }}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.manpowerHideInputs}
                onClick={() => {
                  if (this.state.manpowerHideInputs) {
                    this.setState({
                      manpowerHideInputs: false,
                    });
                  } else {
                    this.setState({
                      manpowerHideInputs: true,
                    });
                  }
                }}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
            <Col md={10}>
              <Button
                onClick={() => {
                  this.setState({
                    showManpowerModal: true,
                    manpowerType: "",
                  });
                }}
                style={{ width: "100%", textAlign: "left" }}
                variant="secondary"
              >
                {<FormattedMessage id="BiddingLogQuotation.Manpower" defaultMessage="Manpower" />}
              </Button>
            </Col>
            <Col md={4}>
              <Input value={!Boolean(quotation.manpower) ? 0 : quotation.manpower.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")} readOnly
                style={{ width: "100%", textAlign: "right" }} />
            </Col>
            <Col md={2}>
              <Input
                value={grandTotal === 0 ? 0 : `${((quotation.manpower * 100) / grandTotal).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}%`}
                readOnly style={{ width: "100%", textAlign: "right" }} />
            </Col>
          </Row>
          <br />
          <Row gutter={[16, 16]}>
            <Col md={4}>

            </Col>
            <Col md={10}>
              <Button
                onClick={() => {
                  this.setState({
                    showMobilizasyonModal: true,
                  });
                }}
                style={{ width: "100%", textAlign: "left" }}
                variant="secondary"
              >
                {<FormattedMessage id="BiddingLogQuotation.Mobilizasyon" defaultMessage="Mobilizasyon" />}
              </Button>
            </Col>
            <Col md={4}>
              <Input value={!Boolean(quotation.mobilizasyon) ? 0 : quotation.mobilizasyon.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")} readOnly
                style={{ width: "100%", textAlign: "right" }} />
            </Col>
            <Col md={2}>
              <Input
                value={grandTotal === 0 ? 0 : `${((quotation.mobilizasyon * 100) / grandTotal).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}%`}
                readOnly style={{ width: "100%", textAlign: "right" }} />
            </Col>
          </Row>

          {!this.state.manpowerHideInputs && <>
            <br />
            <Row gutter={[16, 16]}>
              <Col md={6}>

              </Col>
              <Col md={8}>
                <Button
                  onClick={() => {
                    this.setState({
                      showManpowerModal: true,
                      manpowerType: "Direct",
                    });
                  }}
                  style={{ width: "100%", textAlign: "left" }}
                  variant="outline-secondary"
                >
                  {<FormattedMessage id="BiddingLogQuotation.Direct" defaultMessage="Direct" />}
                </Button>
              </Col>
              <Col md={4}>
                <Input value={!Boolean(quotation.manpowerDirect) ? 0 : quotation.manpowerDirect.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")} readOnly
                  style={{ width: "100%", textAlign: "right" }} />
              </Col>
              <Col md={2}>
                <Input
                  value={grandTotal === 0 ? 0 : `${((quotation.manpowerDirect * 100) / grandTotal).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}%`}
                  readOnly style={{ width: "100%", textAlign: "right" }} />
              </Col>
            </Row> <br />
            <Row gutter={[16, 16]}>
              <Col md={6}>

              </Col>
              <Col md={8}>
                <Button
                  onClick={() => {
                    this.setState({
                      showManpowerModal: true,
                      manpowerType: "Indirect",
                    });
                  }}
                  style={{ width: "100%", textAlign: "left" }}
                  variant="outline-secondary"
                >
                  {<FormattedMessage id="BiddingLogQuotation.Indirect" defaultMessage="Indirect" />}
                </Button>
              </Col>
              <Col md={4}>
                <Input value={!Boolean(quotation.manpowerIndirect) ? 0 : quotation.manpowerIndirect.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")} readOnly
                  style={{ width: "100%", textAlign: "right" }} />
              </Col>
              <Col md={2}>
                <Input
                  value={grandTotal === 0 ? 0 : `${((quotation.manpowerIndirect * 100) / grandTotal).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}%`}
                  readOnly style={{ width: "100%", textAlign: "right" }} />
              </Col>
            </Row>
          </>
          }

          {
            this.state.expensesList.map(i => (
              <>
                <br />
                <Row gutter={[16, 16]}>
                  <Col md={2}></Col>
                  <Col md={2}>
                    <AntButton
                      style={{ border: "0px" }}
                      hidden={this.state["expensesHideInputs" + i.id]}
                      onClick={() => this.toggleCollapse(i.id, true)}
                      icon={<CreateIcon color="secondary" fontSize="large" />}
                    ></AntButton>
                    <AntButton
                      style={{ border: "0px" }}
                      hidden={!this.state["expensesHideInputs" + i.id]}
                      onClick={() => this.toggleCollapse(i.id, false)}
                      icon={<RemoveCircle color="error" fontSize="large" />}
                    ></AntButton>
                  </Col>
                  <Col md={10}>
                    <Button
                      style={{ width: "100%", textAlign: "left" }}
                      variant="secondary"
                      onClick={() => {
                        this.setState({
                          showExpensesModal: true,
                        });
                      }}
                    >
                      {i.name}
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Input value={!Boolean(i.amountRate) ? 0 : i.amountRate.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")} readOnly
                      style={{ width: "100%", textAlign: "right" }} />
                  </Col>
                  <Col md={2}>
                    <Input
                      value={grandTotal === 0 ? 0 : `${((i.amountRate * 100) / grandTotal).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}%`}
                      readOnly style={{ width: "100%", textAlign: "right" }} />
                  </Col>
                </Row>

                {
                  Boolean(this.state["paymentSelectItems" + i.id]) && this.state["paymentSelectItems" + i.id].length > 0 &&
                  this.state["paymentSelectItems" + i.id].map(p => (
                    <>
                      <br />
                      <Row gutter={[16, 16]}>
                        <Col md={6}>
                        </Col>
                        <Col md={8}>
                          <Button
                            style={{ width: "100%", textAlign: "left" }}
                            variant="outline-secondary"
                          >
                            {p.payment}
                          </Button>
                        </Col>
                        <Col md={4}>
                          <Input value={!Boolean(p.amountRate) ? 0 : p.amountRate.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")} readOnly
                            style={{ width: "100%", textAlign: "right" }} />
                        </Col>
                        <Col md={2}>
                          <Input
                            value={grandTotal === 0 ? 0 : `${((p.amountRate * 100) / grandTotal).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}%`}
                            readOnly style={{ width: "100%", textAlign: "right" }} />
                        </Col>
                      </Row>
                    </>
                  ))
                }
              </>
            ))
          }

          <Divider />
          <Row gutter={[16, 16]}>
            <Col md={4}>

            </Col>
            <Col md={10}>
              <Button
                style={{ width: "100%", textAlign: "left" }}
                variant="light"
              >
                {<FormattedMessage id="BiddingLogQuotation.Total" defaultMessage="Total" />}
              </Button>
            </Col>
            <Col md={4}>
              <Input value={!Boolean(total) ? 0 : total.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
                readOnly style={{ width: "100%", textAlign: "right" }} />
            </Col>
            <Col md={2}>
              <Input
                value={grandTotal === 0 ? 0 : `${((total * 100) / grandTotal).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}%`}
                readOnly style={{ width: "100%", textAlign: "right" }} />
            </Col>
          </Row> <br />

          <Row gutter={[16, 16]}>
            <Col md={4}>

            </Col>
            <Col md={6}>
              <Button
                style={{ width: "100%", textAlign: "left" }}
                variant="light"
              >
                {<FormattedMessage id="BiddingLogQuotation.Profit" defaultMessage="Profit" />}
              </Button>
            </Col>
            <Col md={4}>
              <InputNumber
                parser={value => value.replace(",", ".")}
                formatter={value => value.replace(".", ",")}
                onChange={value => {
                  this.setState({
                    profitPercent: value
                  }, () => {
                    this.calcProfitTaxGrandTotal();
                  });
                }}
                value={this.state.profitPercent} max={100} style={{ width: "100%" }}
              />
            </Col>
            <Col md={4}>
              <Input value={!Boolean(this.state.profit) ? 0 : this.state.profit.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
                readOnly style={{ width: "100%", textAlign: "right" }} />
            </Col>
            <Col md={2}>
              <Input
                value={grandTotal === 0 ? 0 : `${((this.state.profit * 100) / grandTotal).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}%`}
                readOnly style={{ width: "100%", textAlign: "right" }} />
            </Col>
          </Row> <br />

          <Row gutter={[16, 16]}>
            <Col md={4}>

            </Col>
            <Col md={6}>
              <Button
                style={{ width: "100%", textAlign: "left" }}
                variant="light"
              >
                {<FormattedMessage id="BiddingLogQuotation.Tax" defaultMessage="Tax" />}
              </Button>
            </Col>
            <Col md={4}>
              <InputNumber onChange={value => {
                this.setState({
                  taxPercent: value
                }, () => {
                  this.calcProfitTaxGrandTotal();
                });
              }}
                value={this.state.taxPercent} max={100} style={{ width: "100%" }} />
            </Col>
            <Col md={4}>
              <Input value={!Boolean(this.state.tax) ? 0 : this.state.tax.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
                readOnly style={{ width: "100%", textAlign: "right" }} />
            </Col>
            <Col md={2}>
              <Input
                value={grandTotal === 0 ? 0 : `${((this.state.tax * 100) / grandTotal).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}%`}
                readOnly style={{ width: "100%", textAlign: "right" }} />
            </Col>
          </Row> <br />

          <Row gutter={[16, 16]}>
            <Col md={4}>

            </Col>
            <Col md={10}>
              <Button
                style={{ width: "100%", textAlign: "left" }}
                variant="light"
              >
                {<FormattedMessage id="BiddingLogQuotation.GrandTotal" defaultMessage="Grand Total" />}
              </Button>
            </Col>
            <Col md={4}>
              <Input value={!Boolean(this.state.grandTotal) ? 0 : this.state.grandTotal.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
                readOnly style={{ width: "100%", textAlign: "right" }} />
            </Col>
            <Col md={2}>
              <Input
                value={`${"100".toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}%`}
                readOnly style={{ width: "100%", textAlign: "right" }} />
            </Col>
          </Row> <br />

        </Form>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Drawer
          title={<FormattedMessage id="BiddingLogQuotation.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"BiddingLogQuotation"} />
        </Drawer>

        <DraggableModal
          title={<FormattedMessage id="BiddingLogQuotation.Equipment" defaultMessage="Equipment" />}
          centered
          width={1400}
          okButtonProps={{ hidden: true }}
          maskClosable={false}
          open={this.state.showEquipmentModal}
          onCancel={() => {
            this.setState({ showEquipmentModal: false });
          }}
          content={<BiddingLogQuotationEquipment tenderingLogId={this.props.tenderingLogId} random={Math.random()}
            close={() => {
              this.setState({ showEquipmentModal: false }, () => {
                this.getQuotation();
              });
            }}
          />}
        ></DraggableModal>

        <DraggableModal
          title={<FormattedMessage id="BiddingLogQuotation.Material" defaultMessage="Material" />}
          centered
          width={1400}
          okButtonProps={{ hidden: true }}
          maskClosable={false}
          open={this.state.showMaterialModal}
          onCancel={() => {
            this.setState({ showMaterialModal: false });
          }}
          content={<BiddingLogQuotationMaterial tenderingLogId={this.props.tenderingLogId} random={Math.random()}
            close={() => {
              this.setState({ showMaterialModal: false }, () => {
                this.getQuotation();
              });
            }}
          />}
        ></DraggableModal>

        <DraggableModal
          title={<FormattedMessage id="BiddingLogQuotation.Manpower" defaultMessage="Manpower" />}
          centered
          width={1400}
          okButtonProps={{ hidden: true }}
          maskClosable={false}
          open={this.state.showManpowerModal}
          onCancel={() => {
            this.setState({ showManpowerModal: false });
          }}
          content={<BiddingLogQuotationManpower tenderingLogId={this.props.tenderingLogId} random={Math.random()} type={this.state.manpowerType}
            close={() => {
              this.setState({ showManpowerModal: false }, () => {
                this.getQuotation();
              });
            }}
          />}
        ></DraggableModal>

        <DraggableModal
          title={<FormattedMessage id="BiddingLogQuotation.Expenses" defaultMessage="Expenses" />}
          centered
          width={1200}
          okButtonProps={{ hidden: true }}
          maskClosable={false}
          open={this.state.showExpensesModal}
          onCancel={() => {
            this.setState({ showExpensesModal: false });
          }}
          content={<BiddingLogQuotationExpenses tenderingLogId={this.props.tenderingLogId} random={Math.random()}
            profit={this.state.profitPercent} tax={this.state.taxPercent} subTotal={this.state.subTotal}
            close={() => {
              this.setState({ showExpensesModal: false }, () => {
                this.getQuotation();
              });
            }}
          />}
        />

        <DraggableModal
          title={<FormattedMessage id="BiddingLogQuotation.Fittings" defaultMessage="Fittings" />}
          centered
          width={1400}
          okButtonProps={{ hidden: true }}
          maskClosable={false}
          open={this.state.showFittingsModal}
          onCancel={() => {
            this.setState({ showFittingsModal: false });
          }}
          content={<BiddingLogQuotationFittings tenderingLogId={this.props.tenderingLogId} random={Math.random()}
            close={() => {
              this.setState({ showFittingsModal: false }, () => {
                this.getQuotation();
              });
            }}
          />}
        />

        <DraggableModal
          title={<FormattedMessage id="BiddingLogQuotation.Mobilizasyon" defaultMessage="Mobilizasyon" />}
          centered
          width={1400}
          okButtonProps={{ hidden: true }}
          maskClosable={false}
          open={this.state.showMobilizasyonModal}
          onCancel={() => {
            this.setState({ showMobilizasyonModal: false });
          }}
          content={<BiddingLogMobilization tenderingLogId={this.props.tenderingLogId} random={Math.random()}
            close={() => {
              this.setState({ showMobilizasyonModal: false }, () => {
                this.getQuotation();
              });
            }}
          />}
        ></DraggableModal>
      </div>
    );
  }

  calcProfitTaxGrandTotal() {
    const { total, profitPercent, taxPercent } = this.state;
    let profit = (total * profitPercent) / 100;
    let tax = (profit * taxPercent) / 100;
    let grandTotal = (total + profit) + tax;
    this.setState({
      profit: profit,
      tax: tax,
      grandTotal: grandTotal,
    });
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BiddingLogQuotation);