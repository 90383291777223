import React, { Component } from "react";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Table, Input, Select, Divider, Tooltip, Col, Row, Button as AntButton, Badge, DatePicker, Checkbox, Tree } from "antd";
import moment from "moment";
import { FilterOutlined, CloseCircleOutlined, NotificationOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { PlusOutlined } from "@ant-design/icons";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { FcFolder, FcFile } from "react-icons/fc";
import { FaFileAudio, FaRegFile } from "react-icons/fa";

import CreateIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { error, showError, showSuccess, showWarning } from "../../MessageHelper";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import DraggableModal from "../../Components/DraggableModal";

const { Option } = Select;

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

export class CompanyProject extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "CompanyProject",
      controllerName: "companyProject",
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,

      id: null,
      tableList: [],
      selectedOptions: [],
      countrySelectItems: [],
      clientSelectItems: [],
      projectsTreeIds: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"]
      },
      loading: false,
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  formRefProjectStatus = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.fillCountries();
    this.fillClients();
    this.fillTree();

    var responsePreparedById = await handleRequest("GET", "/api/" + this.state.controllerName + "/preparedById");
    if (responsePreparedById.type === "ERROR") {
      error(responsePreparedById);
    }
    this.setState({
      preparedByIdSelectItems: Boolean(responsePreparedById.data) ? responsePreparedById.data : []
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  fillCountries = async () => {
    let url = "/api/" + this.state.controllerName + "/countries";
    var response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else
      this.setState({
        countrySelectItems: Boolean(response.data) ? response.data : []
      });
  };

  fillCities = async countryId => {
    if (Boolean(countryId)) {
      let url = "/api/" + this.state.controllerName + "/cities/" + countryId;
      var response = await handleRequest("GET", url);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          cityIdSelectItems: Boolean(response.data) ? response.data : []
        });
      }
    } else {
      this.setState({
        cityIdSelectItems: []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
      startDate: Boolean(row.startDate) ? moment(row.startDate) : null,
      endDate: Boolean(row.endDate) ? moment(row.endDate) : null,
    });

    if (Boolean(row.countryId)) {
      this.fillCities(row.countryId);
    }

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      checkedKeys: { "checked": row.projectsTreeIds },
    });
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let projectStatusAction = {
      name: "Project Status",
      icon: <NotificationOutlined fontSize="small" color="error" />,
      actionClick: this.projectStatusActionModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);
    actionList.push(projectStatusAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  projectStatusActionModal = async (row) => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/" + row.id);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        modalProjectStatusAction: true,
        id: row.id
      }, () => {
        this.formRefProjectStatus.current.setFieldsValue({
          ...response.data
        });
      });
    }
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.sn = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;
    var current = this.state.pagination.current;
    var pageSize = this.state.pagination.pageSize;
    var sortField = "",
      sortOrder = "";

    if (Boolean(params) && Boolean(params.pagination)) {
      current = params.pagination.current;
      pageSize = params.pagination.pageSize;

      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      firstResult: current - 1,
      maxResults: pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      projectsTreeIds: Boolean(this.state.checkedKeys) && Boolean(this.state.checkedKeys.checked) && this.state.checkedKeys.checked.length > 0 ?
        this.state.checkedKeys.checked
        :
        [],
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  saveProjectStatus = async values => {
    const { id } = this.state;
    if (Boolean(id) && id > 0) {
      var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/saveProjectStatus/" + id + "/" + values.completed);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.setState({
          modalProjectStatusAction: false,
        });
      }
    } else {
      showWarning("Company project not found.");
    }
  };

  addClient = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: "CompanyProjectClientId"
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: ""
        });
        this.fillClients();
      }
    } else {
      showError("Please enter code");
    }
  };

  fillClients = async () => {
    const response = await handleRequest("GET", "/api/codes/type/CompanyProjectClientId");
    if (Boolean(response.data)) {
      this.setState({
        clientSelectItems: response.data
      });
    }
  };

  onClickCreateNew = async () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "ProjectName"
    };

    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitems/" + obj.id);
    }
    if (response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.icon =
          Boolean(element.type) && element.type === "ProjectName" ? (
            <FcFile />
          ) : element.type === "Project" ? (
            <FaRegFile />
          ) : element.type === "ProjectChild" ? (
            <FaFileAudio />
          ) : (
            <FcFolder />
          );
        element.title = element.projectName;
        element.key = element.id;
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      this.setState({
        nodes: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  onCheck = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    this.setState({
      checkedKeys: checkedKeys,
      halfCheckedKeys: e.halfCheckedKeys,
      checkedNodes: e.checkedNodes
    });
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "ProjectName";
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: "Project Code",
        key: "projectCode",
        render: record => {
          return record.projectCode;
        },
        ...getColumnFilter({
          inputName: "projectCode",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: "Project Name",
        key: "projectFullName",
        render: record => {
          return record.projectFullName;
        },
        ...getColumnFilter({
          inputName: "projectFullName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: "Project Short Name",
        key: "projectShortName",
        render: record => {
          return record.projectShortName;
        },
        ...getColumnFilter({
          inputName: "projectShortName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: "Client",
        key: "clientId",
        render: record => {
          return record.clientName;
        },
        ...getColumnFilter({
          inputName: "clientId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["clientSelectItems"]) && this.state["clientSelectItems"].length > 0 ? this.state["clientSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: "Project Title",
        key: "projectTitle",
        render: record => {
          return record.projectTitle;
        },
        ...getColumnFilter({
          inputName: "projectTitle",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: "Projects",
        key: "id",
        render: record => {
          return Boolean(record.projectsTreeNames) && record.projectsTreeNames.length > 0 ?
            record.projectsTreeNames.join(", ")
            :
            "";
        },
      },
      {
        title: "Country",
        key: "countryId",
        render: record => {
          return record.countryName;
        },
        ...getColumnFilter({
          inputName: "countryId",
          inputType: "SELECT",
          selectItems:
            Boolean(this.state["countrySelectItems"]) && this.state["countrySelectItems"].length > 0 ? this.state["countrySelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: "City",
        key: "cityId",
        render: record => {
          return record.cityName;
        },
        ...getColumnFilter({
          inputName: "cityId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["cityIdSelectItems"]) && this.state["cityIdSelectItems"].length > 0 ? this.state["cityIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: "Address",
        key: "address",
        render: record => {
          return record.address;
        },
        ...getColumnFilter({
          inputName: "address",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: "Contract No",
        key: "contractNo",
        render: record => {
          return record.contractNo;
        },
        ...getColumnFilter({
          inputName: "contractNo",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
    ];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="companyProject.pageTitle" defaultMessage="Company Project" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={22}></Col>
              {/* <Col md={1}>{excelExport}</Col> */}
              <Col md={1}>{/* <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader> */}</Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>

            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CompanyProject.ProjectName" defaultMessage="Project Name" />}
                  name="projectFullName"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input placeholder="Project Name" style={{ width: "100%" }} />
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CompanyProject.ProjectShortName" defaultMessage="Project Short Name" />}
                  name="projectShortName"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input placeholder="Project Short Name" style={{ width: "100%" }} />
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CompanyProject.ProjectCode" defaultMessage="Project Code" />}
                  name="projectCode"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input placeholder="Project Code" style={{ width: "100%" }} />
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CompanyProject.Client" defaultMessage="Client" />}
                  name="clientId"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    placeholder="Client"
                    value={this.state.clientId}
                    onChange={value => {
                      this.formRef.current.setFieldsValue({
                        clientId: value
                      });
                      this.setState({ clientId: value });
                    }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={e => {
                              this.setState({
                                codeName: e.target.value
                              });
                            }}
                          />
                          <a style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }} onClick={this.addClient}>
                            <PlusOutlined /> Add item
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {this.state.clientSelectItems.map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CompanyProject.ProjectTitle" defaultMessage="Project Title" />}
                  name="projectTitle"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input placeholder="Project Title" style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CompanyProject.Projects" defaultMessage="Projects" />}
                  name="projectsTreeIds"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Tree
                    style={{ marginTop: "10px", lineHeight: "30px" }}
                    className="draggable-tree"
                    showLine={{ showLeafIcon: false }}
                    showIcon
                    checkStrictly={true}
                    loadedKeys={this.state.loadedKeys}
                    onLoad={this.onLoadData}
                    loadData={this.loadData}
                    onExpand={this.onExpand}
                    expandedKeys={this.state.expandedKeys}
                    autoExpandParent={false}
                    treeData={this.state.nodes}
                    blockNode={true}
                    checkable
                    onCheck={this.onCheck}
                    checkedKeys={this.state.checkedKeys}
                    halfCheckedKeys={this.state.halfCheckedKeys}
                  />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CompanyProject.startDate" defaultMessage="Start Date" />}
                  name="startDate"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime={{ defaultValue: moment("00:00:00", "HH:mm") }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CompanyProject.endDate" defaultMessage="End Date" />}
                  name="endDate"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime={{ defaultValue: moment("00:00:00", "HH:mm") }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CompanyProject.Country" defaultMessage="Country" />}
                  name="countryId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    onChange={value => {
                      this.fillCities(value);
                    }}
                  >
                    {this.state.countrySelectItems.map(i => (
                      <Option value={i.key}>{i.value}</Option>
                    ))}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PersonnelExperience.cityId" defaultMessage="City" />}
                  name="cityId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select style={{ width: "100%" }} allowClear showSearch optionFilterProp="children">
                    {Boolean(this.state["cityIdSelectItems"]) &&
                      this.state["cityIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CompanyProject.Address" defaultMessage="Address" />}
                  name="address"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input placeholder="Address" style={{ width: "100%" }} />
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="contractNoLabel" defaultMessage="Contract No" />}
                  name="contractNo"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input placeholder="Contract No" style={{ width: "100%" }} id="contractNo" />
                </Form.Item>
              }
              <Form.Item
                {...layout}
                label={
                  <FormattedMessage id="CompanyProject.TheProjectTakeOffListispreparedby" defaultMessage="The Project Take Off List is prepared by" />
                }
                name="preparedById"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["preparedByIdSelectItems"]) &&
                    this.state["preparedByIdSelectItems"].length > 0 &&
                    this.state["preparedByIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>

          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              {" "}
              <div className="alert-icon">
                {" "}
                <FilterOutlined />{" "}
              </div>{" "}
              <div className="alert-text">
                {" "}
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      {" "}
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>{" "}
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>{" "}
                      <span>
                        {" "}
                        <Tooltip title="Remove">
                          {" "}
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />{" "}
                        </Tooltip>{" "}
                      </span>{" "}
                    </div>
                  );
                })}{" "}
              </div>{" "}
              <div className="alert-close">
                {" "}
                <Tooltip title="Remove All">
                  {" "}
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />{" "}
                </Tooltip>{" "}
              </div>{" "}
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              size="small"
              bordered
              rowClassName={(record, index) => (record.completed ? "text-danger" : "")}
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <DraggableModal
          title={<FormattedMessage id="CompanyProject.ProjectStatus" defaultMessage="Project Status" />}
          centered
          open={this.state.modalProjectStatusAction}
          onOk={() => this.formRefProjectStatus.current.submit()}
          onCancel={() => this.setState({ modalProjectStatusAction: false })}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveProjectStatus}
              onFinishFailed={onFinishFailed}
              ref={this.formRefProjectStatus}
            >
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CompanyProject.ProjectStatus" defaultMessage="Project Completed" />}
                  name="completed"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox></Checkbox>
                </Form.Item>
              }
            </Form>
          }
        />
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProject);