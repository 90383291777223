import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button as AntButton, Col, Form, Row, Upload } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { API_BASE_ROOT, handleRequest } from "../../../ApiConnector";
import { error, showError, showSuccess } from "../../../MessageHelper";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { Button, Modal } from "react-bootstrap";
import { UploadOutlined } from "@ant-design/icons";

export class SummaryContentPagePhoto extends Component {
  constructor() {
    super();
    this.state = {
      formName: "SummaryContentPagePhoto",
      controllerName: "summaryContentPage",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      dynamicMenuCascader: [],
      answerTypeSelectItems: [],
      id: null,
      tableList: [],
      excelData: [],
      createWhereParams: null,
      refreshForm: false,
      filePath: "",
      fileList: [],
      rows: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });

    this.fillComboboxes();
    this.getPhotoUrls();
  };

  componentDidUpdate = async nextProps => {};

  fillComboboxes = async () => {};

  getPhotoUrls = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getPhotoUrls/" + this.props.summaryContentPageId);
    if (response.type === "ERROR") {
      error(response);
    }
    let row = response.data;

    let fileUrl1 = [];
    let fileUrl2 = [];
    let fileUrl3 = [];
    let fileUrl4 = [];

    if (Boolean(row.fileUrl1)) {
      let obj = {
        uid: row.fileUrl1,
        name: row.fileUrl1.split("download/")[1],
        url: row.fileUrl1,
        thumbUrl: row.fileUrl1
      };
      fileUrl1.push(obj);
    }

    if (Boolean(row.fileUrl2)) {
      let obj = {
        uid: row.fileUrl2,
        name: row.fileUrl2.split("download/")[1],
        url: row.fileUrl2,
        thumbUrl: row.fileUrl2
      };
      fileUrl2.push(obj);
    }

    if (Boolean(row.fileUrl3)) {
      let obj = {
        uid: row.fileUrl3,
        name: row.fileUrl3.split("download/")[1],
        url: row.fileUrl3,
        thumbUrl: row.fileUrl3
      };
      fileUrl3.push(obj);
    }

    if (Boolean(row.fileUrl4)) {
      let obj = {
        uid: row.fileUrl4,
        name: row.fileUrl4.split("download/")[1],
        url: row.fileUrl4,
        thumbUrl: row.fileUrl4
      };
      fileUrl4.push(obj);
    }

    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.setFieldsValue({
        fileUrl1: fileUrl1,
        fileUrl2: fileUrl2,
        fileUrl3: fileUrl3,
        fileUrl4: fileUrl4
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      answerType: null,
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      searchCriteriaModels = [],
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      summaryContentPageId: this.props.summaryContentPageId,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
      searchCriteriaModels: searchCriteriaModels
    };
    return newObj;
  }

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    let newItem = {
      ...values,
      id: this.props.summaryContentPageId
    };
    let fileUrl1 = [];
    if (Boolean(newItem.fileUrl1) && newItem.fileUrl1.length > 0) {
      newItem.fileUrl1.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) fileUrl1.push(item.response.url);
        else if (Boolean(item.url)) fileUrl1.push(item.url);
      });
      newItem.fileUrl1 = fileUrl1.toString();
    }

    let fileUrl2 = [];
    if (Boolean(newItem.fileUrl2) && newItem.fileUrl2.length > 0) {
      newItem.fileUrl2.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) fileUrl2.push(item.response.url);
        else if (Boolean(item.url)) fileUrl2.push(item.url);
      });
      newItem.fileUrl2 = fileUrl2.toString();
    }

    let fileUrl3 = [];
    if (Boolean(newItem.fileUrl3) && newItem.fileUrl3.length > 0) {
      newItem.fileUrl3.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) fileUrl3.push(item.response.url);
        else if (Boolean(item.url)) fileUrl3.push(item.url);
      });
      newItem.fileUrl3 = fileUrl3.toString();
    }

    let fileUrl4 = [];
    if (Boolean(newItem.fileUrl4) && newItem.fileUrl4.length > 0) {
      newItem.fileUrl4.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) fileUrl4.push(item.response.url);
        else if (Boolean(item.url)) fileUrl4.push(item.url);
      });
      newItem.fileUrl4 = fileUrl4.toString();
    }
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/savePhotoPage", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.getPhotoUrls();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  setOpenPanels = key => {
    this.setState({
      activePanelKey: key
    });
  };

  setOpenPanelsTitle = key => {
    this.setState({
      activePanelKeyTitle: key
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/typeAll/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  getRatingList = max => {
    const numbers = [];
    for (let index = 1; index <= max; index++) {
      numbers.push(index);
    }
    return numbers;
  };

  handleFileChange = ({ fileList }) => this.setState({ fileList });

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const uploadProps = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <br />
          {this.props.photoCount === 1 && (
            <Form.Item
              {...layout}
              label={<FormattedMessage id="RequestForQuotation.Upload1stPhoto" defaultMessage="Upload 1st Photo" />}
              name="fileUrl1"
              rules={[{ required: true, message: "Missing Load" }]}
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload {...uploadProps} name="file" maxCount="1">
                <AntButton icon={<UploadOutlined />}></AntButton>
              </Upload>
            </Form.Item>
          )}
          {this.props.photoCount === 2 && (
            <>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="RequestForQuotation.Upload1stPhoto" defaultMessage="Upload 1st Photo" />}
                name="fileUrl1"
                rules={[{ required: true, message: "Missing Load" }]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload {...uploadProps} name="file" maxCount="1">
                  <AntButton icon={<UploadOutlined />}></AntButton>
                </Upload>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="RequestForQuotation.Upload2ndPhoto" defaultMessage="Upload 2nd Photo" />}
                name="fileUrl2"
                rules={[{ required: true, message: "Missing Load" }]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload {...uploadProps} name="file" maxCount="1">
                  <AntButton icon={<UploadOutlined />}></AntButton>
                </Upload>
              </Form.Item>
            </>
          )}
          {this.props.photoCount === 4 && (
            <>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="RequestForQuotation.Upload1stPhoto" defaultMessage="Upload 1st Photo" />}
                name="fileUrl1"
                rules={[{ required: true, message: "Missing Load" }]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload {...uploadProps} name="file" maxCount="1">
                  <AntButton icon={<UploadOutlined />}></AntButton>
                </Upload>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="RequestForQuotation.Upload2ndPhoto" defaultMessage="Upload 2nd Photo" />}
                name="fileUrl2"
                rules={[{ required: true, message: "Missing Load" }]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload {...uploadProps} name="file" maxCount="1">
                  <AntButton icon={<UploadOutlined />}></AntButton>
                </Upload>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="RequestForQuotation.Upload3rdPhoto" defaultMessage="Upload 3rd Photo" />}
                name="fileUrl3"
                rules={[{ required: true, message: "Missing Load" }]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload {...uploadProps} name="file" maxCount="1">
                  <AntButton icon={<UploadOutlined />}></AntButton>
                </Upload>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="RequestForQuotation.Upload4thPhoto" defaultMessage="Upload 4th Photo" />}
                name="fileUrl4"
                rules={[{ required: true, message: "Missing Load" }]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload {...uploadProps} name="file" maxCount="1">
                  <AntButton icon={<UploadOutlined />}></AntButton>
                </Upload>
              </Form.Item>
            </>
          )}
          <br /> <br />
          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
              </Button>
            </Col>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
          </Row>
        </Form>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryContentPagePhoto);
