import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import { Form, Table, Col, Row, Modal, Button as AntButton, Radio, Space, Cascader, Switch, InputNumber, Checkbox, Upload, Avatar } from "antd";
import { Input } from "antd";
import { Select } from "antd";
import { handleRequest, API_BASE_ROOT } from "../../../../../../app/ApiConnector";
import { showError, showSuccess, error, showWarning } from "../../../../../../app/MessageHelper";
import { fillFilterOperations } from "../../../../../../app/Components/DataTableFilterComponent";
import ReactQuill from "react-quill";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";
import DraggableModal from "../../../../../../app/Components/DraggableModal";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (Boolean(node.data)) {
      var lineage = [];
      if (Boolean(node.data.lineage) && node.data.lineage.length > 0) {
        node.data.lineage.forEach(element => {
          if (!lineage.includes(element)) {
            lineage.push(element);
          }
        });
      }

      dataList.push({
        key: node.data.id,
        lineage: lineage,
        title: node.data.menuTitle,
        page: node.data.page
      });
    }
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class ReplyToUser extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ReplyToUser",
      controllerName: "ticketMessageReply",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,

      hideUpdate: true,
      hideSave: false,
      hideInputs: false,

      tableList: [],
      materialList: [],
      usageList: [],
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      relatedToPage: true,
      dynamicMenuCascader: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.setState({
        hideUpdate: true,
        hideSave: false,
        hideInputs: false,
      }, () => {
        this.initialize();
      });
    }
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  }

  fillComboboxes = async () => {
    var responseUser = await handleRequest("GET", "/api/" + this.state.controllerName + "/users");
    if (responseUser.type === "ERROR") {
      error(responseUser);
    } else {
      this.setState({
        userSelectItems: Boolean(responseUser.data) ? responseUser.data : []
      });
    }
  };

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    console.log("row", row);
    document.getElementById("kt_scrolltop").click();

    let ticketMessages = [];

    row.ticketMessageMaterial = row.ticketMessageMaterials;
    ticketMessages.push(row);

    console.log("ticketMessages", ticketMessages);

    this.formRef.current.setFieldsValue({
      ticketMessages: ticketMessages,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  save = async values => {
    if (Boolean(this.props.ticketMessageId) && this.props.ticketMessageId > 0) {
      const newItem = {
        ...values,
        id: this.state.id,
        ticketMessageId: this.props.ticketMessageId,
        relatedMenuId: Boolean(values.relatedMenuId) && values.relatedMenuId.length > 0 ? values.relatedMenuId[values.relatedMenuId.length - 1] : null
      };

      let pathList = [];
      if (Boolean(newItem.filePath))
        newItem.filePath.forEach(item => {
          if (Boolean(item.response) && Boolean(item.response.url)) pathList.push(item.response.url);
          else if (Boolean(item.url)) pathList.push(item.url);
        });
      newItem.filePath = pathList.toString();

      let replyUsers = [];
      const { userIds } = this.state;
      if (userIds && userIds.length > 0)
        userIds.forEach(element => {
          replyUsers.push({ userId: element });
        });
      newItem.messageReplyUsers = replyUsers;

      console.log(newItem);
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.props.close();
      }
    } else {
      showWarning("Ticket message not found.");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          modalAddNewDimensionShow: false,
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  showReport = () => {
    this.setState({
      modalUserShow: true
    });
  }

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };

    const propsUpload = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
    };

    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form
          initialValues={{ remember: false }}
          onFinish={this.save}
          onFinishFailed={onFinishFailed}
          ref={this.formRef}>
          <div hidden={this.state.hideInputs}>
            <br /> <br />

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="ReplyToUser.MessageToUser" defaultMessage="Message to User" />}
                name="messageToUser"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                initialValue=""
                valuePropName="value"
              >
                <ReactQuill
                  className="bg-transparent border-0 h-200px px-3"
                  theme="snow"
                  placeholder="Type your text here..."
                  initialValue=""
                  value=""
                  onChange={(content, delta, source, editor) => {
                    const updatedValue = editor.getContents();
                  }}
                />
              </Form.Item>
            }

            <br /><br />
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ReplyToUser.Upload" defaultMessage={"Upload"} />}
                name="filePath"
                rules={[{ required: false, message: "Missing Load" }]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload {...propsUpload} showUploadList={true} maxCount={1}>
                  <AntButton icon={<UploadOutlined />} style={{ width: "100%", marginTop: "0.2rem" }}>
                    <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                  </AntButton>
                </Upload>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ReplyToUser.MNDNotifications" defaultMessage="MND notifications" />}
                name="mndNotification"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox onClick={(e) => {
                  this.setState({
                    mndNotification: e.target.checked
                  });
                  this.formRef.current.setFieldsValue({
                    mndMessage: "",
                  });
                }}>
                </Checkbox>
              </Form.Item>
            }
            {this.state.mndNotification &&
              <>
                <Row gutter={8}>
                  <Col span={2}></Col>
                  <Col span={20}>
                    <Form.Item name="mndMessage"
                      {...layout2}
                      label={<FormattedMessage id="ReplyToUser.Message" defaultMessage="Message" />}
                      style={{ marginBottom: "5px" }}
                      valuePropName="value"
                    >
                      <ReactQuill
                        className="bg-transparent border-0 h-200px px-3"
                        theme="snow"
                        placeholder="Type your text here..."
                        initialValue=""
                        value=""
                        onChange={(content, delta, source, editor) => {
                          const updatedValue = editor.getContents();
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <AntButton icon={<UserOutlined />} onClick={() => {
                      this.setState({
                        modalUserShow: true
                      });
                    }} ></AntButton>
                  </Col>
                </Row>
              </>
            }
            <br /><br /><br />

            <div hidden={this.state.hideSave}>
              {<Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Button id="SaveButton"
                    type="submit"
                    style={{ width: "100%" }}
                    variant="success">
                    <FormattedMessage id="GeneralButtonSave"
                      defaultMessage="Save" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>}
            </div>

            <div hidden={this.state.hideUpdate}>
              {<Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="CancelButton" style={{ width: "100%" }}
                    variant="secondary"
                    onClick={this.cancel}>
                    <FormattedMessage
                      id="GeneralButtonCancel"
                      defaultMessage="Cancel" />
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="UpdateButton"
                    style={{ width: "100%" }}
                    variant="warning" type="submit">
                    <FormattedMessage id="GeneralButtonUpdate"
                      defaultMessage="Update" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>}
            </div>
          </div>
        </Form>

        <Modal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={500}
          okButtonProps={{ hidden: true }}
          open={this.state.modalDeleteShow}
          onCancel={() => {
            this.setState({ modalDeleteShow: false });
          }}
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalDeleteShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Space>
          ]}
        >
          <p>
            <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
          </p>
        </Modal>

        <DraggableModal
          title={<FormattedMessage id="EldenInbox.User" defaultMessage="User" />}
          open={this.state.modalUserShow}
          okButtonProps={{ hidden: false }}
          onCancel={() => this.setState({ modalUserShow: false, userIds: [] })}
          onOk={() => this.setState({ modalUserShow: false })}
          width={800}
          centered
          content={
            <Form.Item
              {...layout}
              label={<FormattedMessage id="EldenInbox.User" defaultMessage="User" />}
              name="users"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <Select style={{ width: "100%" }} showSearch allowClear mode="multiple"
                onChange={value => {
                  this.setState({
                    userIds: value
                  });
                }}>
                {Boolean(this.state["userSelectItems"]) && this.state["userSelectItems"].length > 0 &&
                  this.state["userSelectItems"].map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          }
        />
      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ReplyToUser);