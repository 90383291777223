import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined, PlusOutlined, UserAddOutlined, UserOutlined, ScheduleTwoTone, FileTextTwoTone, UploadOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import {
  Form,
  Table,
  Input,
  Select,
  Divider,
  DatePicker,
  Badge,
  Avatar,
  Tooltip,
  Upload,
  Button as AntButton,
  Modal as AntModal,
  Col,
  Row
} from "antd";

import moment from "moment";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import MeetingAgenda from "./MeetingAgenda";
import MeetingStart from "./MeetingStart";
import MeetingMinutes from "./MeetingMinutes";
import "../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";
import DraggableModal from "../../Components/DraggableModal";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class Meeting extends Component {
  constructor() {
    super();
    this.state = {
      formName: "Meeting",
      controllerName: "meeting",
      modalDeleteShow: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      selectedRow: null,
      tableList: [],
      filePath: "",
      fileList: [],
      attachmentPath: [],
      organizerSelectItems: null,
      reporterSelectItems: null,
      attendeesSelectItems: null,
      guestAttendeesSelectItems: null,
      notifyToSelectItems: null,
      guestNotifyToSelectItems: null,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      meetingId: null,
      excelData: []
    };
  }

  formRef = React.createRef();
  formSendInvitationRef = React.createRef();
  formAddGuestAttendeesRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    this.fillCode("MeetingLocationId");
    this.fillCode("MeetingRequiredDocumentId");
  };

  fillComboboxes = async () => {
    var responseUsers = await handleRequest("GET", "/api/meeting/users");
    if (responseUsers.type === "ERROR") {
      error(responseUsers);
    } else {
      this.setState({
        organizerSelectItems: Boolean(responseUsers.data) ? responseUsers.data : [],
        reporterSelectItems: Boolean(responseUsers.data) ? responseUsers.data : [],
        attendeesSelectItems: Boolean(responseUsers.data) ? responseUsers.data : [],
        notifyToSelectItems: Boolean(responseUsers.data) ? responseUsers.data : []
      });
    }

    var responseGuest = await handleRequest("GET", "/api/meetingGuestAttendeesDetail/guest");
    if (responseGuest.type === "ERROR") {
      error(responseGuest);
    } else {
      this.setState({
        guestAttendeesSelectItems: Boolean(responseGuest.data) ? responseGuest.data : [],
        guestNotifyToSelectItems: Boolean(responseGuest.data) ? responseGuest.data : []
      });
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu
          row={record}
          onEditRow={this.edit.bind(this)}
          onSendInvitation={this.sendInvitationModal.bind(this)}
          onAddFile={this.addFileModal.bind(this)}
          onDeleteRow={this.deleteModal.bind(this)}
          onMeetingStart={this.MeetingStartModal.bind(this)}
          onMeetingMinutes={this.MeetingMinutesModal.bind(this)}
        />
      </React.Fragment>
    );
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  showCustomerModal = () => {
    this.setState({
      showGuestAttendeesModal: true
    });
  };

  addFileModal = () => {
    this.setState({
      showAddFileModal: true
    });
  };

  onClickMeetingAgendaModal = row => {
    this.setState({
      meetingId: row,
      modalAgendaShow: true
    });
  };

  onClickMeetingAgendaModal2 = () => {
    this.setState({
      modalAgendaShow: true
    });
  };

  MeetingStartModal = row => {
    this.setState({
      meetingId: row.id,
      meetingStartModal: true
    });
  };

  MeetingMinutesModal = row => {
    this.setState({
      meetingId: row.id,
      meetingMinutesModal: true
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showAttendeesCompanyModal = row => {
    this.setState({
      showAttendeesCompanyModal: true,
      ...row,
      id: row.id
    });
  };
  showAttendeesGuestModal = row => {
    this.setState({
      showAttendeesGuestModal: true,
      ...row,
      id: row.id
    });
  };
  showNotifyToCompanyModal = row => {
    this.setState({
      showNotifyToCompanyModal: true,
      ...row,
      id: row.id
    });
  };
  showNotifyToGuestModal = row => {
    this.setState({
      showNotifyToGuestModal: true,
      ...row,
      id: row.id
    });
  };



  sendInvitationModal = row => {
    this.setState({
      modalInvitationShow: true
    }, () => {
      this.formSendInvitationRef.current.setFieldsValue({ organizer : row.organizerName })
      this.formSendInvitationRef.current.setFieldsValue({ description : row.description })
      this.formSendInvitationRef.current.setFieldsValue({ dateTime : row.startDate })
      this.formSendInvitationRef.current.setFieldsValue({ location : row.locationName })
      this.formSendInvitationRef.current.setFieldsValue({ attendees : row.meetingAttendees })
      this.formSendInvitationRef.current.setFieldsValue({ notifyTo : row.meetingNotifyTos })
      this.formSendInvitationRef.current.setFieldsValue({ reporter : row.reporterName })
      this.formSendInvitationRef.current.setFieldsValue({ requiredDocuments : row.requiredDocumentName })
      this.formSendInvitationRef.current.setFieldsValue({ agenda : row.meetingAgendaAction })
    }
    );
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    let pathList = [];
    if (Boolean(newItem.attachmentPath))
      newItem.attachmentPath.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) pathList.push(item.response.url);
        else if (Boolean(item.url)) pathList.push(item.url);
      });
    newItem.attachmentPath = pathList.toString();

    let meetingAttendees = [];
    if (newItem.attendees && newItem.attendees.length > 0)
      newItem.attendees.forEach(element => {
        meetingAttendees.push({ userId: element });
      });

    newItem.meetingAttendees = meetingAttendees;

    let meetingNotifyTos = [];
    if (newItem.notifyTo && newItem.notifyTo.length > 0)
      newItem.notifyTo.forEach(element => {
        meetingNotifyTos.push({ userId: element });
      });
    newItem.meetingNotifyTos = meetingNotifyTos;

    let meetingGuestAttendees = [];
    if (newItem.guestAttendees && newItem.guestAttendees.length > 0)
      newItem.guestAttendees.forEach(element => {
        meetingGuestAttendees.push({ meetingGuestAttendeesDetailId: element });
      });

    newItem.meetingGuestAttendees = meetingGuestAttendees;

    let meetingGuestNotifyTos = [];
    if (newItem.guestNotifyTo && newItem.guestNotifyTo.length > 0)
      newItem.guestNotifyTo.forEach(element => {
        meetingGuestNotifyTos.push({ meetingGuestAttendeesDetailId: element });
      });

    newItem.meetingGuestNotifyTos = meetingGuestNotifyTos;

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/meeting", newItem);
    } else {
      response = await handleRequest("PUT", "/api/meeting/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        this.onClickMeetingAgendaModal(response.data);
        this.MeetingStartModal(response.data);
        this.MeetingMinutesModal(response.data);
      }
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  saveGuestAttendees = async values => {
    let newItem = {
      customer: true,
      ...values
    };

    var response = await handleRequest("POST", "/api/meetingGuestAttendeesDetail", newItem);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.fillComboboxes();
      this.setState({
        showGuestAttendeesModal: false
      });
      showSuccess();
      this.formAddGuestAttendeesRef.current.resetFields();
    }
  };

  saveCustomer = async values => {
    let newItem = {
      customer: true,
      ...values
    };

    var response = await handleRequest("POST", "/api/vendor", newItem);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.fillComboboxes();
      this.setState({
        showAddCustomerModal: false
      });
      showSuccess();
    }
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = [];
    let filePathList = Boolean(row.attachmentPath) ? row.attachmentPath.split(",") : [];
    if (Boolean(filePathList) && filePathList.length > 0) {
      filePathList.forEach((item, index) => {
        let obj = {
          uid: index,
          name: item.split("download/")[1],
          url: item,
          thumbUrl: item
        };

        fileList.push(obj);
      });
    }

    var attendees = [];
    if (Boolean(row.meetingAttendees) && row.meetingAttendees.length > 0) {
      row.meetingAttendees.forEach(element => {
        attendees.push(element.userId);
      });
    }

    var guestAttendees = [];
    if (Boolean(row.meetingGuestAttendees) && row.meetingGuestAttendees.length > 0) {
      row.meetingGuestAttendees.forEach(element => {
        guestAttendees.push(element.meetingGuestAttendeesDetailId);
      });
    }

    var guestNotifyTo = [];
    if (Boolean(row.meetingGuestNotifyTos) && row.meetingGuestNotifyTos.length > 0) {
      row.meetingGuestNotifyTos.forEach(element => {
        guestNotifyTo.push(element.meetingGuestAttendeesDetailId);
      });
    }

    var notifyTo = [];
    if (Boolean(row.meetingNotifyTos) && row.meetingNotifyTos.length > 0) {
      row.meetingNotifyTos.forEach(element => {
        notifyTo.push(element.userId);
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      attendees: attendees,
      guestAttendees: guestAttendees,
      notifyTo: notifyTo,
      guestNotifyTo: guestNotifyTo,
      attachmentPath: fileList,
      startDate: Boolean(row.startDate) ? moment(row.startDate) : null,
      endDate: Boolean(row.endDate) ? moment(row.endDate) : null
    });
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      fileList: fileList,
      selectedRow: row
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/meeting/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/meeting/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data && response.data.data) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      selectedRow: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        fixed: "left",
        width: "3%",
        render: this.action
      },
      {
        title: "S/N",
        key: "id",
        width: 100,
        fixed: "left",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="Meeting.organizerId" defaultMessage="Organizer" />,
        key: "organizerId",
        fixed: "left",
        width: 100,
        render: record => {
          return record.organizerName;
        },

        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <TextArea
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.organizer) ? this.state.pagination.organizer : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  organizer: e.target.value,
                  organizerColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.organizerColor) ? this.state.pagination.organizerColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      // tablo 111
      {
        title: <FormattedMessage id="Meeting.description" defaultMessage="Meeting Description" />,
        key: "description",
        fixed: "left",
        width: 100,
        showTitle: true,
        ellipsis: true,

        render: record => {
          return record.description;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <TextArea
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.description) ? this.state.pagination.description : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  description: e.target.value,
                  descriptionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.descriptionColor) ? this.state.pagination.descriptionColor : "#bfbfbf" }} />
        ),

        sorter: true
      },

      {
        title: <FormattedMessage id="Meeting.date" defaultMessage="Date" />,
        width: 100,
        children: [
          {
            title: <FormattedMessage id="Meeting.startDate" defaultMessage="Start" />,
            key: "startDate",
            width: 100,
            render: record => {
              return Boolean(record.startDate) ? moment(record.startDate).format("DD-MM-YYYY HH:mm") : "";
            },
            filterDropdown: () => (
              <div style={{ padding: 8 }}>
                <Input
                  style={{ flex: "auto" }}
                  value={Boolean(this.state.pagination.startDate) ? this.state.pagination.startDate : null}
                  onChange={async e => {
                    const { pagination } = this.state;
                    let _pagination = {
                      ...pagination,
                      startDate: e.target.value,
                      startDateColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                    };
                    this.setState({
                      pagination: _pagination
                    });

                    this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                  }}
                />
              </div>
            ),
            filterIcon: filtered => (
              <FilterOutlined style={{ color: Boolean(this.state.pagination.startDateColor) ? this.state.pagination.startDateColor : "#bfbfbf" }} />
            ),
            sorter: true
          },

          {
            title: <FormattedMessage id="Meeting.endDate" defaultMessage="End" />,
            key: "endDate",
            width: 100,
            render: record => {
              return Boolean(record.endDate) ? moment(record.endDate).format("DD-MM-YYYY HH:mm") : "";
            },
            filterDropdown: () => (
              <div style={{ padding: 8 }}>
                <Input
                  style={{ flex: "auto" }}
                  value={Boolean(this.state.pagination.endDate) ? this.state.pagination.endDate : null}
                  onChange={async e => {
                    const { pagination } = this.state;
                    let _pagination = {
                      ...pagination,
                      endDate: e.target.value,
                      endDateColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                    };
                    this.setState({
                      pagination: _pagination
                    });

                    this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                  }}
                />
              </div>
            ),
            filterIcon: filtered => (
              <FilterOutlined style={{ color: Boolean(this.state.pagination.endDateColor) ? this.state.pagination.endDateColor : "#bfbfbf" }} />
            ),

            sorter: true
          }
        ]
      },

      {
        title: <FormattedMessage id="Meeting.revNo" defaultMessage="Rev. No" />,
        key: "revNo",
        width: 100,
        render: record => {
          return (
            <React.Fragment>
              {Boolean(record.revNo) && record.revNo > 0 ? (
                <div
                  style={{
                    cursor: "pointer",
                    color: record.status === "Deleted" ? "#d3d3d3" : "blue",
                    textDecoration: "underline"
                  }}
                  onClick={e => this.showRevisedList(record)}
                >
                  {record.revNo}
                </div>
              ) : record.revNo ? (
                record.revNo.length
              ) : (
                0
              )}
            </React.Fragment>
          );
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        }),
        align: "center"
      },
      {
        title: <FormattedMessage id="Meeting.attendees" defaultMessage="Atendees" />,
        width: 100,
        children: [
          {
            title: <FormattedMessage id="Meeting.attendees" defaultMessage="Company" />,
            key: "meetingAttendees",
            width: 100,
            align: "center",
            render: record => {
              return (
                <Avatar.Group maxCount={1}>
                  <Tooltip
                    overlayInnerStyle={{ width: "400px" }}
                    color={"#b37feb"}
                    placement="top"
                    title={
                      record.meetingAttendees &&
                      record.meetingAttendees.length > 0 &&
                      record.meetingAttendees.map((element, index) => {
                        return (
                          <div key={index} className="w-100" style={{ color: element.userId ? "black" : "#d3d3d3" }}>
                            {index + 1 + ". " + (element.userName + " " + element.surname + " " + element.email)}
                          </div>
                        );
                      })
                    }
                  >
                    <Badge offset={[-30, 0]} showZero count={record.meetingAttendees ? record.meetingAttendees.length : 0}>
                      <Avatar icon={<UserOutlined />}></Avatar>
                    </Badge>
                  </Tooltip>
                  <Tooltip
                    overlayInnerStyle={{ width: "400px" }}
                    color={"#ffadd2"}
                    placement="bottom"
                    title={
                      record.meetingAttendees &&
                      record.meetingAttendees.length > 0 &&
                      record.meetingAttendees.map((element, index) => {
                        return (
                          <div key={index} className="w-100" style={{ color: element.userId ? "black" : "#d3d3d3" }}>
                            {index + 1 + ". " + (element.userName + " " + element.surname + " " + element.email)}
                          </div>
                        );
                      })
                    }
                  >
                    <Badge showZero count={record.meetingAttendees ? record.meetingAttendees.length : 0} offset={[0, 30]}>
                      <Avatar icon={<UserOutlined />}></Avatar>
                    </Badge>
                  </Tooltip>
                </Avatar.Group>
              );
            }
          },
          {
            title: <FormattedMessage id="Meeting.guestAttendees" defaultMessage="Guest" />,
            key: "meetingGuestAttendees",
            align: "center",
            width: 100,
            render: record => {
              return (
                <Avatar.Group maxCount={1}>
                  <Tooltip
                    color={"#95de64"}
                    placement="top"
                    overlayInnerStyle={{ width: "400px" }}
                    title={
                      record.meetingGuestAttendees &&
                      record.meetingGuestAttendees.length > 0 &&
                      record.meetingGuestAttendees.map((element, index) => {
                        return (
                          <div key={index} className="w-100" style={{ color: element.meetingGuestAttendeesDetailId ? "black" : "#d3d3d3" }}>
                            {index +
                              1 +
                              ". " +
                              element.meetingGuestAttendeesDetailName +
                              " " +
                              element.meetingGuestAttendeesDetailSurname +
                              " " +
                              element.meetingGuestAttendeesDetailEmail}
                          </div>
                        );
                      })
                    }
                  >
                    <Badge offset={[-30, 0]} showZero count={record.meetingGuestAttendees ? record.meetingGuestAttendees.length : 0}>
                      <Avatar icon={<UserOutlined />}></Avatar>
                    </Badge>
                  </Tooltip>
                  <Tooltip
                    color={"#95de64"}
                    placement="bottom"
                    overlayInnerStyle={{ width: "400px" }}
                    title={
                      record.meetingGuestAttendees &&
                      record.meetingGuestAttendees.length > 0 &&
                      record.meetingGuestAttendees.map((element, index) => {
                        return (
                          <div key={index} className="w-100" style={{ color: element.meetingGuestAttendeesDetailId ? "black" : "#d3d3d3" }}>
                            {index +
                              1 +
                              ". " +
                              element.meetingGuestAttendeesDetailName +
                              " " +
                              element.meetingGuestAttendeesDetailSurname +
                              " " +
                              element.meetingGuestAttendeesDetailEmail}
                          </div>
                        );
                      })
                    }
                  >
                    <Badge showZero count={record.meetingGuestAttendees ? record.meetingGuestAttendees.length : 0} offset={[0, 30]}>
                      <Avatar icon={<UserOutlined />}></Avatar>
                    </Badge>
                  </Tooltip>
                </Avatar.Group>
              );
            }
          }
        ]
      },

      {
        title: "Notify To",
        width: 100,
        children: [
          {
            title: <FormattedMessage id="Meeting.notifyTo" defaultMessage="Company" />,
            key: "meetingNotifyTos",
            align: "center",
            width: 100,
            render: record => {
              return (
                <Avatar.Group maxCount={1}>
                  <Tooltip
                    color={"#87e8de"}
                    placement="top"
                    overlayInnerStyle={{ width: "400px" }}
                    title={
                      record.meetingNotifyTos &&
                      record.meetingNotifyTos.length > 0 &&
                      record.meetingNotifyTos.map((element, index) => {
                        return (
                          <div key={index} className="w-100" style={{ color: element.userId ? "black" : "#d3d3d3" }}>
                            {index + 1 + ". " + (element.userName + " " + element.surname + " " + element.email)}
                          </div>
                        );
                      })
                    }
                  >
                    <Badge offset={[-30, 0]} showZero count={record.meetingNotifyTos ? record.meetingNotifyTos.length : 0}>
                      <Avatar icon={<UserOutlined />} />
                    </Badge>
                  </Tooltip>
                  <Tooltip
                    color={"#87e8de"}
                    placement="top"
                    overlayInnerStyle={{ width: "400px" }}
                    title={
                      record.meetingNotifyTos &&
                      record.meetingNotifyTos.length > 0 &&
                      record.meetingNotifyTos.map((element, index) => {
                        return (
                          <div key={index} className="w-100" style={{ color: element.userId ? "black" : "#d3d3d3" }}>
                            {index + 1 + ". " + (element.userName + " " + element.surname + " " + element.email)}
                          </div>
                        );
                      })
                    }
                  >
                    <Badge offset={[0, 30]} showZero count={record.meetingNotifyTos ? record.meetingNotifyTos.length : 0}>
                      <Avatar icon={<UserOutlined />} />
                    </Badge>
                  </Tooltip>
                </Avatar.Group>
              );
            }
          },
          {
            title: <FormattedMessage id="Meeting.guestNotifyTo" defaultMessage="Guest" />,
            key: "meetingGuestNotifyTos",
            align: "center",
            width: 100,
            render: record => {
              return (
                <Avatar.Group maxCount={1}>
                  <Tooltip
                    color={"#ffd666"}
                    placement="top"
                    overlayInnerStyle={{ width: "400px" }}
                    title={
                      record.meetingGuestNotifyTos &&
                      record.meetingGuestNotifyTos.length > 0 &&
                      record.meetingGuestNotifyTos.map((element, index) => {
                        return (
                          <div key={index} className="w-100" style={{ color: element.meetingGuestAttendeesDetailId ? "black" : "#d3d3d3" }}>
                            {index +
                              1 +
                              ". " +
                              element.meetingGuestAttendeesDetailName +
                              " " +
                              element.meetingGuestAttendeesDetailSurname +
                              " " +
                              element.meetingGuestAttendeesDetailEmail}
                          </div>
                        );
                      })
                    }
                  >
                    <Badge showZero offset={[-30, 0]} count={record.meetingGuestNotifyTos ? record.meetingGuestNotifyTos.length : 0}>
                      {" "}
                      <Avatar icon={<UserOutlined />} />
                    </Badge>
                  </Tooltip>
                  <Tooltip
                    color={"#ffd666"}
                    placement="top"
                    overlayInnerStyle={{ width: "400px" }}
                    title={
                      record.meetingGuestNotifyTos &&
                      record.meetingGuestNotifyTos.length > 0 &&
                      record.meetingGuestNotifyTos.map((element, index) => {
                        return (
                          <div key={index} className="w-100" style={{ color: element.meetingGuestAttendeesDetailId ? "black" : "#d3d3d3" }}>
                            {index +
                              1 +
                              ". " +
                              element.meetingGuestAttendeesDetailName +
                              " " +
                              element.meetingGuestAttendeesDetailSurname +
                              " " +
                              element.meetingGuestAttendeesDetailEmail}
                          </div>
                        );
                      })
                    }
                  >
                    <Badge showZero offset={[0, 30]} count={record.meetingGuestNotifyTos ? record.meetingGuestNotifyTos.length : 0}>
                      {" "}
                      <Avatar icon={<UserOutlined />} />
                    </Badge>
                  </Tooltip>
                </Avatar.Group>
              );
            }
          }
        ]
      },

      {
        title: <FormattedMessage id="Meeting.reporterId" defaultMessage="Reporter" />,
        key: "reporterId",
        width: 100,
        render: record => {
          return record.reporterName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <TextArea
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.reporter) ? this.state.pagination.reporter : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  reporter: e.target.value,
                  reporterColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.reporterColor) ? this.state.pagination.reporterColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="Meeting.location" defaultMessage="Location" />,
        key: "locationId",
        width: 100,
        render: record => {
          return record.locationName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <TextArea
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.location) ? this.state.pagination.location : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  location: e.target.value,
                  locationColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.locationColor) ? this.state.pagination.locationColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="Meeting.requiredDocument" defaultMessage="Required Document" />,
        key: "requiredDocumentId",
        width: 100,
        render: record => {
          return record.requiredDocumentName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <TextArea
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.requiredDocument) ? this.state.pagination.requiredDocument : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  requiredDocument: e.target.value,
                  requiredDocumentColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.requiredDocumentColor) ? this.state.pagination.requiredDocumentColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="Meeting.agenda" defaultMessage="Agenda" />,
        key: "agenda",
        align: "center",
        width: 100,
        render: record => {
          return (
            <div>
              <ScheduleTwoTone value=" Fill Agenda" style={{ fontSize: 25 }} onClick={() => this.onClickMeetingAgendaModal(record.id)} />
            </div>
          );
        }
      },

      {
        title: <FormattedMessage id="Meeting.attachmentPath" defaultMessage="Attachment" />,
        key: "attachmentPath",
        width: 100,
        render: record => {
          return record.attachmentPath;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.attachmentPath) ? this.state.pagination.attachmentPathYYYY : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  attachmentPath: e.target.value,
                  attachmentPathColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.attachmentPathColor) ? this.state.pagination.attachmentPathColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },

      {
        title: <FormattedMessage id="Meeting.minutesOfMeeting" defaultMessage="Minutes Of Meeting" />,
        key: "minutesOfMeeting",
        width: 100,
        render: record => {
          return (
            <div>
              <FileTextTwoTone value=" Fill Minutes" style={{ fontSize: 25 }} onClick={() => this.this.MeetingMinutesModal(record.id)} />
            </div>
          );
        }
      },
      {
        title: <FormattedMessage id="Meeting.status" defaultMessage="Status" />,
        key: "status",
        width: 100,
        render: record => {
          return record.status;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.status) ? this.state.pagination.status : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  status: e.target.value,
                  statusColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.statusColor) ? this.state.pagination.statusColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: "Last Update",
        key: "lastUpdateDate",
        width: 100,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];

      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    return (
      <Badge.Ribbon text="Meeting" placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "3rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={23}></Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>

            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Meeting.organizerId" defaultMessage="Organizer" />}
                  name="organizerId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                    {Boolean(this.state["organizerSelectItems"]) &&
                      this.state["organizerSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Meeting.description" defaultMessage="Meeting Description" />}
                  name="description"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <TextArea placeholder="Description" style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Meeting.startDate" defaultMessage="Start Date" />}
                  name="startDate"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <DatePicker showTime format="DD-MM-YYYY HH:mm" style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Meeting.endDate" defaultMessage="End Date" />}
                  name="endDate"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <DatePicker showTime format="DD-MM-YYYY HH:mm" style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Meeting.location" defaultMessage="Location Of Meeting" />}
                  name="locationId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("MeetingLocationId")}
                          >
                            {<PlusOutlined />}
                            <FormattedMessage id="Meeting.AddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["MeetingLocationIdSelectItems"]) &&
                      this.state["MeetingLocationIdSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Meeting.attendees" defaultMessage="Attendees" />}
                  name="attendees"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select style={{ width: "100%" }} placeholder="Attendees" showSearch allowClear mode="multiple">
                    {Boolean(this.state["attendeesSelectItems"]) &&
                      this.state["attendeesSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Meeting.guestAttendees" defaultMessage="Guest Attendees" />}
                  name="guestAttendees"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Guest Attendees"
                    showSearch
                    allowClear
                    mode="multiple"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8
                          }}
                        >
                          <AntButton
                            type="primary"
                            shape="dashed"
                            block
                            icon={<UserAddOutlined style={{ fontSize: 20 }} />}
                            onClick={this.showCustomerModal}
                          >
                            {<FormattedMessage id="GuestAttendees.addGuest" defaultMessage="Add Guest" />}
                          </AntButton>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["guestAttendeesSelectItems"]) &&
                      this.state["guestAttendeesSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Meeting.notifyTo" defaultMessage="Notify To" />}
                  name="notifyTo"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select style={{ width: "100%" }} placeholder="Notify To" allowClear showSearch mode="multiple">
                    {Boolean(this.state["notifyToSelectItems"]) &&
                      this.state["notifyToSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Meeting.guestNotifyTo" defaultMessage="Notify To Guest" />}
                  name="guestNotifyTo"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Notify To Geust"
                    allowClear
                    mode="multiple"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8
                          }}
                        >
                          <AntButton
                            type="primary"
                            shape="dashed"
                            block
                            icon={<UserAddOutlined style={{ fontSize: 20 }} />}
                            onClick={this.showCustomerModal}
                          >
                            {<FormattedMessage id="NotifyToGuest.addOther" defaultMessage="Add Guest" />}
                          </AntButton>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["guestNotifyToSelectItems"]) &&
                      this.state["guestNotifyToSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Meeting.reporterId" defaultMessage="Reporter" />}
                  name="reporterId"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select showSearch style={{ width: "100%" }} placeholder="Reporter" allowClear>
                    {Boolean(this.state["reporterSelectItems"]) &&
                      this.state["reporterSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Meeting.requiredDocument" defaultMessage="Required Document" />}
                  name="requiredDocumentId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("MeetingRequiredDocumentId")}
                          >
                            <PlusOutlined /> <FormattedMessage id="Meeting.AddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["MeetingRequiredDocumentIdSelectItems"]) &&
                      this.state["MeetingRequiredDocumentIdSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Meeting.attachmentPath" defaultMessage="Attach File" />}
                  name="attachmentPath"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Upload
                    multiple
                    showUploadList={true}
                    fileList={this.state.fileList}
                    onChange={({ fileList }) => {
                      if (fileList.length == 1) {
                        if (Boolean(fileList[0].response)) {
                          fileList[0].url = fileList[0].response.url;
                          showSuccess("file uploaded successfully");
                        }
                        this.setState({
                          fileList: fileList
                        });
                      }
                    }}
                    beforeUpload={fileList => {
                      if (this.state.fileList.length > 0) {
                        showError("You have been already choose a file! For change firstly remove the uploaded file.");
                        return false;
                      }
                      return true;
                    }}
                    onRemove={fileList => {
                      this.setState({
                        fileList: []
                      });
                    }}
                  >
                    <AntButton type="primary" shape="dashed" block>
                      <UploadOutlined /> Click to Upload
                    </AntButton>
                  </Upload>
                </Form.Item>
              }
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              size="small"
              components={{
                header: {
                  cell: ResizableTitle
                }
              }}
              columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
              scroll={{ x: 2500, scrollToFirstRowOnChange: true }}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <AntModal
            closable={false}
            visible={this.state.modalAgendaShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ modalAgendaShow: false })}
            width={1500}
            centered
            scroll={{ x: 400, y: 400, scrollToFirstRowOnChange: true }}
          >
            <MeetingAgenda meetingId={this.state.meetingId} random={Math.random()} />
          </AntModal>

          {this.state.meetingId && (
            <AntModal
              closable={false}
              visible={this.state.meetingStartModal}
              okButtonProps={{ hidden: true }}
              onCancel={() => this.setState({ meetingStartModal: false })}
              width={1500}
              centered
              scroll={{ x: 400, y: 400, scrollToFirstRowOnChange: true }}
            >
              <MeetingStart meetingId={this.state.meetingId} random={Math.random()} />
            </AntModal>
          )}

          {this.state.meetingId && (
            <AntModal
              closable={false}
              visible={this.state.meetingMinutesModal}
              okButtonProps={{ hidden: true }}
              onCancel={() => this.setState({ meetingMinutesModal: false })}
              width={1500}
              centered
              scroll={{ x: 400, y: 400, scrollToFirstRowOnChange: true }}
            >
              <MeetingMinutes meetingId={this.state.meetingId} random={Math.random()} />
            </AntModal>
          )}

          <DraggableModal
            visible={this.state.modalInvitationShow}
            closable={false}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ modalInvitationShow: false })}
            width={1000}
            centered
            scroll={{ x: 400, y: 400, scrollToFirstRowOnChange: true }}
          >
            <Badge.Ribbon text="Meeting Invitation" placement="start" color="purple" style={{ fontSize: 12 }}>
              <div className="card card-custom" style={{ padding: "3rem", minHeight: "100%" }}>
                <Form
                  initialValues={{ remember: false }}
                  onFinish={this.saveCustomer}
                  onFinishFailed={onFinishFailed}
                  ref={this.formSendInvitationRef}
                >
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="MeetingInvitation.organizer" defaultMessage="Organizer" />}
                    name="organizer"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input  style={{ width: "100%" }} readOnly
                    />
                    { }
                  </Form.Item>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="MeetingInvitation.description" defaultMessage="Meeting Description" />}
                    name="description"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} readOnly />
                    { }
                  </Form.Item>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="MeetingInvitation.dateTime" defaultMessage="Date and Time" />}
                    name="dateTime"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                     <Input
                  style={{ flex: "auto" }}
                  value={Boolean(this.state.pagination.startDate) ? this.state.pagination.startDate : null}
                  onChange={async e => {
                    const { pagination } = this.state;
                    let _pagination = {
                      ...pagination,
                      startDate: e.target.value,
                      startDateColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                    };
                    this.setState({
                      pagination: _pagination
                    });

                    this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                  }}
                />
                    
                  </Form.Item>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="MeetingInvitation.location" defaultMessage="Location Of Meeting" />}
                    name="location"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                     <Input  style={{ width: "100%" }} readOnly />
                    { }
                  </Form.Item>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="MeetingInvitation.attendees" defaultMessage="Attendees" />}
                    name="attendees"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input  style={{ width: "100%" }} readOnly />
                    { }
                  </Form.Item>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="MeetingInvitation.notifyTo" defaultMessage="Notify To" />}
                    name="notifyTo"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    { }
                    <Input  style={{ width: "100%" }} readOnly />
                  </Form.Item>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="MeetingInvitation.reporter" defaultMessage="Reporter" />}
                    name="reporter"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input  style={{ width: "100%" }} readOnly />
                    { }
                  </Form.Item>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="MeetingInvitation.requiredDocuments" defaultMessage="Required Documents" />}
                    name="requiredDocuments"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input  style={{ width: "100%" }} readOnly />
                    { }
                  </Form.Item>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="MeetingInvitation.agenda" defaultMessage="Agenda" />}
                    name="agenda"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                     <ScheduleTwoTone value=" Fill Agenda" style={{ fontSize: 25 }} onClick={() => this.onClickMeetingAgendaModal2 }/>
                    { }
                  </Form.Item>
                </Form>
              </div>
            </Badge.Ribbon>
          </DraggableModal>

          <AntModal
            visible={this.state.showGuestAttendeesModal}
            closable={false}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ showGuestAttendeesModal: false })}
            width={1200}
            centered
            scroll={{ x: 400, y: 400, scrollToFirstRowOnChange: true }}
          >
            <Badge.Ribbon text="Guest" placement="start" color="purple" style={{ fontSize: 12 }}>
              <div className="card card-custom" style={{ padding: "3rem", minHeight: "100%" }}>
                <Form
                  initialValues={{ remember: false }}
                  onFinish={this.saveGuestAttendees}
                  onFinishFailed={onFinishFailed}
                  ref={this.formAddGuestAttendeesRef}
                >
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="MettingAttendees.name" defaultMessage="Name" />}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="MettingAttendees.surname" defaultMessage="Surname" />}
                    name="surname"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item {...layout} label={<FormattedMessage id="MettingAttendees.email" defaultMessage="E-Mail" />} name="email">
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item {...layout} label={<FormattedMessage id="MettingAttendees.phone" defaultMessage="Phone" />} name="phone">
                    <Input style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item {...layout} label={<FormattedMessage id="MettingAttendees.company" defaultMessage="Company" />} name="company">
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item {...layout} label={<FormattedMessage id="MettingAttendees.title" defaultMessage="Title" />} name="title">
                    <Input style={{ width: "100%" }} />
                  </Form.Item>

                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button
                        id="CancelButton"
                        style={{ width: "100%" }}
                        variant="secondary"
                        onClick={() => {
                          this.formAddGuestAttendeesRef.current.resetFields();
                          this.setState({
                            showGuestAttendeesModal: false
                          });
                        }}
                      >
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                </Form>
              </div>
            </Badge.Ribbon>
          </AntModal>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Meeting);
