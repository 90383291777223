import React, { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { FormattedMessage } from "react-intl";
import { ProjectsTreeCompanyApartment } from "./ProjectsTreeCompanyApartment";
import { ProjectsTreeCompanyFloor } from "./ProjectsTreeCompanyFloor";
import ProjectsTreeCompanyDivision from "./ProjectsTreeCompanyDivision";
import { ProjectsTreeCompanyQuantity } from "./ProjectsTreeCompanyQuantity";
import ProjectsTreeCompanyLayout from "./ProjectsTreeCompanyLayout";
import ProjectsTreeCompanyHeight from "./ProjectsTreeCompanyHeight";
import "./style.css";
import ProjectsTreeCompanySection from "./ProjectsTreeCompanySection";

export function ProjectsTreeCompanyDrawer(companyProjectId) {
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  const [selectedTab, setSelectedTab] = React.useState("apartment");
  useEffect(() => {
    return () => { };
  }, [user]);

  return (
    <>
      {user && (
        <div className="d-flex flex-row">
          <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-250px" id="kt_profile_aside">
            <div className="card card-custom card-stretch">
              {/* begin::Body */}
              <div className="card-body pt-4">
                {/* begin::Toolbar */}
                <div className="d-flex justify-content-end"></div>
                {/* end::Toolbar */}
                {/* begin::Nav */}
                <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="ProjectsTreeCompanyDrawer.Apartment" defaultMessage="Apartment" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "apartment" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("apartment");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="ProjectsTreeCompanyDrawer.Apartment" defaultMessage="Apartment" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="ProjectsTreeCompanyDrawer.Sections" defaultMessage="Sections" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "sections" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("sections");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="ProjectsTreeCompanyDrawer.Sections" defaultMessage="Sections" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="ProjectsTreeCompanyDrawer.Floors" defaultMessage="Floors" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "floors" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("floors");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Article.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="ProjectsTreeCompanyDrawer.Floors" defaultMessage="Floors" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="ProjectsTreeCompanyDrawer.Divisions" defaultMessage="Divisions" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "divisions" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("divisions");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="ProjectsTreeCompanyDrawer.Divisions" defaultMessage="Divisions" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="ProjectsTreeCompanyDrawer.Heights" defaultMessage="Heights" />{" "}
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "height" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("height");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="ProjectsTreeCompanyDrawer.Heights" defaultMessage="Heights" />{" "}
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="ProjectsTreeCompanyDrawer.Layout" defaultMessage="Layout" />{" "}
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "layout" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("layout");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="ProjectsTreeCompanyDrawer.Layout" defaultMessage="Layout" />{" "}
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="ProjectsTreeCompanyDrawer.Quantities" defaultMessage="Quantities" />{" "}
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "quantities" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("quantities");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="ProjectsTreeCompanyDrawer.Quantities" defaultMessage="Quantities" />{" "}
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                </div>
                {/* end::Nav */}
              </div>
              {/* end::Body */}
            </div>
          </div>
          <div className="flex-row-fluid ml-lg-8">
            {selectedTab === "apartment" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    <ProjectsTreeCompanyApartment nextTab={() => setSelectedTab("sections")} />
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "sections" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    <ProjectsTreeCompanySection nextTab={() => setSelectedTab("floors")} />
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "floors" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    <ProjectsTreeCompanyFloor nextTab={() => setSelectedTab("divisions")} />
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "divisions" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    <ProjectsTreeCompanyDivision nextTab={() => setSelectedTab("height")} />
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "height" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    <ProjectsTreeCompanyHeight nextTab={() => setSelectedTab("layout")} />
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "layout" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    <ProjectsTreeCompanyLayout nextTab={() => setSelectedTab("quantities")} />
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "quantities" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    <ProjectsTreeCompanyQuantity nextTab={() => setSelectedTab("apartment")} companyProjectId={companyProjectId.companyProjectId} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
