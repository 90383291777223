import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { Form, Table, Col, Row, Modal as AntModal, Alert, Button as AntButton, Select, Typography } from "antd";
import { handleRequest } from "../../ApiConnector";
import CommentIcon from "@material-ui/icons/Comment";
import moment from "moment";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const { Option } = Select;
const { Text } = Typography;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class PersonnelContractSummary extends Component {
  constructor() {
    super();
    this.state = {
      controller: "personnelLog",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      personnelsSelectItems: [],
      totalBalance: 0,
      personnelLogId: -1,
      salaryDetailList: [],

      id: null,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.fillPersonnelByUserId();
  };

  fillPersonnelByUserId = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controller + "/getPersonnelByPersonnelId/" + this.props.user.personnelLogId);
    if (response.type === "ERROR") {
      this.error(response);
    } else {
      this.setState({
        personnel: Boolean(response.data) ? response.data : [],
      });
    }
  };

  render() {
    const user = this.props.user;
    const personnel = this.state.personnel;
    console.log("props", this.props);
    console.log("user", user);
    console.log("personnel", personnel);
    return (
      <div className="card card-custom card-stretch" style={{ padding: "2rem", minHeight: "100%" }} >
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">

        </div>
        <div className="card-toolbar"></div>
      </div>
      <div className="form">
        <div className="card-body">
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mb-6">Contract Summary</h5>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Contract Type</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                readOnly
                className={`form-control form-control-lg form-control-solid`}
                name="contractType"
                value={Boolean(personnel) && Boolean(personnel) ? personnel.contractType : ""}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Start Date</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                readOnly
                className={`form-control form-control-lg form-control-solid`}
                name="startDate"
                value={Boolean(user.personnel) && Boolean(user.personnel) ? personnel.contractDate : ""}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Contract Duration</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                readOnly
                className={`form-control form-control-lg form-control-solid`}
                name="contractDuration"
                value={Boolean(personnel) && Boolean(personnel ) ? personnel.contractDuration : ""}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Annual Vacation Days</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                readOnly
                className={`form-control form-control-lg form-control-solid`}
                name="annualVacationDays"
                value={Boolean(personnel) && Boolean(personnel) ? personnel.annualVacationDays : ""}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Salary</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                readOnly
                className={`form-control form-control-lg form-control-solid`}
                name="salary"
                value={Boolean(personnel) && Boolean(personnel) ? personnel.basicSalary : ""}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Overtime</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                readOnly
                className={`form-control form-control-lg form-control-solid`}
                name="overtime"
                value={Boolean(personnel) && Boolean(personnel) ? user.surname : ""}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Accomodation</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                readOnly
                className={`form-control form-control-lg form-control-solid`}
                name="accomodation"
                value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Transportation</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                readOnly
                className={`form-control form-control-lg form-control-solid`}
                name="transportation"
                value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Fooding </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                readOnly
                className={`form-control form-control-lg form-control-solid`}
                name="fooding"
                value={Boolean(personnel) && Boolean(personnel) ? personnel.foodClass : ""}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Family Status </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                readOnly
                className={`form-control form-control-lg form-control-solid`}
                name="familyStatus"
                value={Boolean(personnel) && Boolean(personnel) ? personnel.familyStatus : ""}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Vacation Days</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                readOnly
                className={`form-control form-control-lg form-control-solid`}
                name="vacationDays"
                value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Insurance</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                readOnly
                className={`form-control form-control-lg form-control-solid`}
                name="insurance"
                value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Fly Ticket</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                readOnly
                className={`form-control form-control-lg form-control-solid`}
                name="flyTicket"
                // fly ticket'a ne gelecek ?
                value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
              />
            </div>
          </div>


        </div>
      </div>
    </div>

      
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PersonnelContractSummary);
