import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, InputNumber, Col, Row, Button as AntButton, Badge } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Select } from "antd";
import {
  Input,
  Cascader,
  Divider
} from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const { Option } = Select;


const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};
const tailFormItemLayout2 = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 6

    }
  }
};
const layout2 = {
  labelCol: { span: 8 },
  wrapperCol: { span: 9 }
};
export class ProfileWeights extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ProfileWeights",
      controllerName: "profileWeights",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      nodes: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }
  handleEldenItemIdChange = async () => {
    this.formRef.current.setFieldsValue({
      coatingId: null,
      surfaceFinishingId: null,
      standardId: null
    });

    var newObj = {
      materialGroupId: this.formRef.current.getFieldValue("materialGroupId"),
      id: this.formRef.current.getFieldValue("descriptionId")
    };
    let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/eldenItem", newObj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        selectedEldenItem: response.data,
        descriptionType: Boolean(response.data.descriptionId) ? "ADD" : "SELECT"
      });

      if (Boolean(response.data) && Boolean(response.data.itemCodeCriterias) && response.data.itemCodeCriterias.length > 0) {
        response.data.itemCodeCriterias.forEach(element => {
          this.fillCode("StockCard" + element.itemDefinition);
          this.formRef.current.setFieldsValue({
            [element.itemDefinition]: null
          });
        });
      }
    }

    // this.handleCodeCriteriaChange();
  };
  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };
  fillComboboxes = async () => {
    var responsematerialGroupId = await handleRequest("GET", "/api/" + this.state.controllerName + "/materialGroupId");
    if (responsematerialGroupId.type === "ERROR") {
      error(responsematerialGroupId);
    }
    this.setState({
      materialGroupIdSelectItems: Boolean(responsematerialGroupId.data) ? responsematerialGroupId.data : []
    });

    var responseunitId = await handleRequest("GET", "/api/" + this.state.controllerName + "/unitId");
    if (responseunitId.type === "ERROR") {
      error(responseunitId);
    }
    this.setState({
      unitIdSelectItems: Boolean(responseunitId.data) ? responseunitId.data : []
    });


    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/tree");
    if (responseTree.data.length !== 0) {
      generateList(responseTree.data);
      this.setState({
        nodes: responseTree.data
      });
    }
  };
  handleCodeCriteriaChange = async () => {
    let materialTypeIds = this.formRef.current.getFieldValue("materialTypeId");
    let materialTypeId = Boolean(materialTypeIds) && materialTypeIds.length > 0 ? materialTypeIds[materialTypeIds.length - 1] : null;

    let obj = {
      materialTypeId: materialTypeId,
      materialGroupId: this.formRef.current.getFieldValue("materialGroupId"),
      descriptionId: this.formRef.current.getFieldValue("descriptionId"),
    };


    var materialDescription = [];
    if (
      Boolean(this.state.selectedEldenItem) &&
      Boolean(this.state.selectedEldenItem.materialDescription) &&
      this.state.selectedEldenItem.materialDescription.length > 0
    ) {
      this.state.selectedEldenItem.materialDescription.forEach(element => {
        let itemDefinition = this.formRef.current.getFieldValue([element.itemDefinition]);

        materialDescription.push({
          itemDefinition: element.itemDefinition,
          codeId: itemDefinition
        });
      });
    }
    obj.materialDescription = materialDescription;

    let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/controlCode", obj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      let list = response.data;
      if (Boolean(list) && list.length > 0) {
        obj.list = list;
        let response2 = await handleRequest("POST", "/api/" + this.state.controllerName + "/controlCodeInList", obj);
        if (response.type === "ERROR") {
          error(response2);
        } else {
          let row = response2.data;

          if (Boolean(row)) {
            var materialType = dataList.find(p => p.key === row.materialTypeId);
            let materialTypeId = [];
            if (Boolean(materialType)) {
              materialTypeId = materialType.lineage;
            }

            let descriptionId = row.descriptionId;
            if (Boolean(row.materialDescriptionId)) descriptionId = row.materialDescriptionId;

            this.formRef.current.setFieldsValue({
              ...row,
              descriptionId: descriptionId,
              materialTypeId: materialTypeId
            });

            await this.handleEldenItemIdChange();


            this.formRef.current.setFieldsValue({
              ...row,
              descriptionId: descriptionId,
              materialTypeId: materialTypeId
            });

            this.setState({
              hideUpdate: false,
              hideSave: true,
              hideInputs: false,
              id: row.id,
              descriptionType: Boolean(row.descriptionId) ? "ADD" : "SELECT"
            });
          } else {
            this.formRef.current.setFieldsValue({
              code: null
            });
            this.setState({
              hideUpdate: true,
              hideSave: false,
              id: null
            });
          }
        }
      } else {
        this.formRef.current.setFieldsValue({
          code: null
        });
        this.setState({
          hideUpdate: true,
          hideSave: false,
          id: null
        });
      }
    }
  };
  setItemDescriptionId = async value => {
    this.formRef.current.setFieldsValue({
      descriptionId: value.key,
      materialDescriptionId: value.key
    }); this.handleEldenItemIdChange();

    this.formRef.current.setFieldsValue({
      descriptionId: value.key,
      materialDescriptionId: value.key
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  fillCodeDescription = async () => {
    var newObj = {
      itemGroupId: null
    };
    var responsedescriptionId = await handleRequest("POST", "/api/" + this.state.controllerName + "/description", newObj);
    if (responsedescriptionId.type === "ERROR") {
      error(responsedescriptionId);
    } else {
      this.setState({
        descriptionIdSelectItems: Boolean(responsedescriptionId.data) ? responsedescriptionId.data : []
      });
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = async (row) => {
    let linkedDrawingIds = [];
    document.getElementById("kt_scrolltop").click();

    if (Boolean(row.materialGroupId) && row.materialGroupId > 0) {
      await this.onChangeItemGroupId(row.materialGroupId);
    }

    var materialType = dataList.find(p => p.key === row.materialTypeId);
    let materialTypeId = [];
    if (Boolean(materialType)) {
      materialTypeId = materialType.lineage;
    }

    let descriptionId = row.descriptionId;
    if (Boolean(row.materialDescriptionId)) descriptionId = row.materialDescriptionId;

    this.formRef.current.setFieldsValue({
      ...row,
      descriptionId: descriptionId,
      materialTypeId: materialTypeId
    });

    await this.handleEldenItemIdChange();

    this.formRef.current.setFieldsValue({
      ...row,
      descriptionId: descriptionId,
      materialTypeId: materialTypeId
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      descriptionType: Boolean(row.descriptionId) ? "ADD" : "SELECT",
      linkedDrawingIds: linkedDrawingIds,
      descriptionId: descriptionId,
      materialGroupId: row.materialGroupId,
      materialTypeId: Boolean(materialTypeId) && materialTypeId.length > 0 ? materialTypeId[materialTypeId.length - 1] : null,
    });
  };

  onChangeItemGroupId = async value => {
    if (Boolean(value)) {
      var responseitemDescriptionId = await handleRequest("GET", "/api/profileWeights/itemDescriptionIdSelectItems/" + value);
      if (responseitemDescriptionId.type === "ERROR") {
        error(responseitemDescriptionId);
        this.setState({
          descriptionIdSelectItems: [],
          descriptionId: null,
        });
        this.formRef.current.setFieldsValue({
          descriptionId: null
        });
      } else {
        this.setState({
          descriptionIdSelectItems: Boolean(responseitemDescriptionId.data) ? responseitemDescriptionId.data : [],
          selectedEldenItem: null,
          descriptionId: null,
        });
        this.formRef.current.setFieldsValue({
          descriptionId: null
        });
      }
    } else {
      this.setState({
        descriptionIdSelectItems: [],
        descriptionId: null,
      });
      this.formRef.current.setFieldsValue({
        descriptionId: null
      });
    }
  };


  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };



    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);



    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      descriptionId: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };
    var itemCodeCriterias = [];
    if (
      Boolean(this.state.selectedEldenItem) &&
      Boolean(this.state.selectedEldenItem.itemCodeCriterias) &&
      this.state.selectedEldenItem.itemCodeCriterias.length > 0
    ) {
      this.state.selectedEldenItem.itemCodeCriterias.forEach(element => {
        let itemDefinition = this.formRef.current.getFieldValue([element.itemDefinition]);

        itemCodeCriterias.push({
          itemDefinition: element.itemDefinition,
          codeId: itemDefinition
        });
      });
    }
    newItem.itemCodeCriterias = itemCodeCriterias;

    if (Boolean(this.state.selectedEldenItem) && Boolean(this.state.selectedEldenItem.sketchPath)) {
      newItem.sketchPath = this.state.selectedEldenItem.sketchPath;
    }
    newItem.materialTypeId =
      Boolean(values.materialTypeId) && values.materialTypeId.length > 0 ? values.materialTypeId[values.materialTypeId.length - 1] : null;

    if (this.state.descriptionType === "ADD") {
      newItem.descriptionId = values.descriptionId;
      newItem.descriptionSelectId = null;
    } else {
      newItem.descriptionId = null;
      newItem.descriptionSelectId = values.descriptionId;
    }


    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }
    if (this.state.descriptionType === "ADD") {
      newItem.descriptionId = values.descriptionId;
      newItem.materialDescriptionId = null;
    } else {
      newItem.descriptionId = null;
      newItem.materialDescriptionId = values.descriptionId;
    }
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };


  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ProfileWeights.materialGroupId" defaultMessage="materialGroup" />,
        key: "materialGroupId",
        render: record => {
          return record.materialGroupName;
        }, ...getColumnFilter({
          inputName: "materialGroupName", inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ProfileWeights.materialTypeId" defaultMessage="materialType" />,
        key: "materialTypeId",
        render: record => {
          return record.materialTypeName;
        }, ...getColumnFilter({
          inputName: "materialTypeId", inputType: "CASCADER",
          selectItems: Boolean(this.state["nodes"]) ? this.state["nodes"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ProfileWeights.materialDescriptionId" defaultMessage="materialDescription" />,
        key: "materialDescriptionId",
        render: record => {
          return record.materialDescriptionName;
        }, ...getColumnFilter({
          inputName: "materialDescriptionId", inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ProfileWeights.productName" defaultMessage="product Name" />,
        key: "productName",
        render: record => {
          return record.productName;
        }, ...getColumnFilter({
          inputName: "productName", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: false
      },
      {
        title: <FormattedMessage id="ProfileWeights.kgmt" defaultMessage="kgmt" />,
        key: "kgmt",
        render: record => {
          return record.kgmt;
        }, ...getColumnFilter({
          inputName: "kgmt", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ProfileWeights.outerDiameter" defaultMessage="outerDiameter" />,
        key: "outerDiameter",
        render: record => {
          return record.outerDiameter;
        }, ...getColumnFilter({
          inputName: "outerDiameter", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ProfileWeights.diameter" defaultMessage="diameter" />,
        key: "diameter",
        render: record => {
          return record.diameter;
        }, ...getColumnFilter({
          inputName: "diameter", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ProfileWeights.width" defaultMessage="width" />,
        key: "width",
        render: record => {
          return record.width;
        }, ...getColumnFilter({
          inputName: "width", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ProfileWeights.heigth" defaultMessage="heigth" />,
        key: "heigth",
        render: record => {
          return record.heigth;
        }, ...getColumnFilter({
          inputName: "heigth", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ProfileWeights.thickness" defaultMessage="thickness" />,
        key: "thickness",
        render: record => {
          return record.thickness;
        }, ...getColumnFilter({
          inputName: "thickness", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ProfileWeights.webThickness" defaultMessage="webThickness" />,
        key: "webThickness",
        render: record => {
          return record.webThickness;
        }, ...getColumnFilter({
          inputName: "webThickness", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ProfileWeights.radious" defaultMessage="radious" />,
        key: "radious",
        render: record => {
          return record.radious;
        }, ...getColumnFilter({
          inputName: "radious", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ProfileWeights.webRadious" defaultMessage="webRadious" />,
        key: "webRadious",
        render: record => {
          return record.webRadious;
        }, ...getColumnFilter({
          inputName: "webRadious", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
    ];
    const excelExport = (<>
      {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}         {this.state.exportClick ? (<ExcelFile filename="ProfileWeights" hideElement={true}>             <ExcelSheet name="ProfileWeights" data={this.state.tableList}>               <ExcelColumn label="materialGroup" value="materialGroupName" />
        <ExcelColumn label="materialType" value="materialTypeName" />
        <ExcelColumn label="materialDescription" value="materialDescriptionName" />
        <ExcelColumn label="productName" value="productName" />
        <ExcelColumn label="kgmt" value="kgmt" />
        <ExcelColumn label="outerDiameter" value="outerDiameter" />
        <ExcelColumn label="diameter" value="diameter" />
        <ExcelColumn label="width" value="width" />
        <ExcelColumn label="heigth" value="heigth" />
        <ExcelColumn label="thickness" value="thickness" />
        <ExcelColumn label="webThickness" value="webThickness" />
        <ExcelColumn label="radious" value="radious" />
        <ExcelColumn label="webRadious" value="webRadious" />
      </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel}
        icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)} */}
    </>);
    return (
      <Badge.Ribbon
        text={
          <FormattedMessage
            id="profileWeights.pageTitle"
            defaultMessage="Profile Weights Table" />}
        placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom"
          style={{ padding: "2rem", minHeight: "100%" }}>
          <Form
            initialValues={{ remember: false }}
            onFinish={this.save}
            onFinishFailed={onFinishFailed}
            ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={21}></Col>
              <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader
                  onCreateJson={this.handleUploadExcel.bind(this)}>
                </ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary"
                    fontSize="large" />
                  }
                ></AntButton>
                <AntButton style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error"
                    fontSize="large" />}
                ></AntButton>             </Col>
            </Row>


            <div hidden={this.state.hideInputs}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ProfileWeights.materialGroupId" defaultMessage="Material Group" />}
                name="materialGroupId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  onChange={value => {
                    this.setState({
                      materialGroupId: value
                    }, () => {
                      // this.checkCode();
                      this.onChangeItemGroupId(value);

                      //this.handleEldenItemIdChange();
                    });
                  }}
                >
                  {Boolean(this.state["materialGroupIdSelectItems"]) && this.state["materialGroupIdSelectItems"].length > 0 &&
                    this.state["materialGroupIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              {
                <Form.Item
                  {...layout2}
                  label={
                    <FormattedMessage
                      id="ProfileWeights.descriptionId"
                      defaultMessage="Material Description"
                    />
                  }
                >
                  <Row>
                    <Col span={21}>
                      <Form.Item
                        name="descriptionId"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select
                          showSearch
                          allowClear
                          optionFilterProp="children"
                          onChange={value => {
                            this.setState({
                              descriptionId: value,
                            }, () => {
                              // this.checkCode();
                              this.handleEldenItemIdChange();
                            });
                          }}
                          style={{ width: "100%" }}
                        >
                          {Boolean(this.state["descriptionIdSelectItems"]) &&
                            this.state["descriptionIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}{" "}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={2}>
                      {Boolean(this.state["materialDescriptionId"]) && this.state["materialDescriptionId"].length > 0}
                    </Col>
                  </Row>
                </Form.Item>
              }
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ProfileWeights.materialTypeId" defaultMessage="Material Type" />}
                name="materialTypeId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Cascader
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  onChange={value => {
                    this.setState({
                      materialTypeId: Boolean(value) &&
                        value.length > 0 ?
                        value[value.length - 1] :
                        null,
                    }, () => {
                      this.handleEldenItemIdChange();

                    });
                  }}
                  options={this.state.nodes}
                />
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="ProfileWeights.productName" defaultMessage="Product Name" />}
                name="productName"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="Product Name" style={{ width: "100%" }} />
              </Form.Item><Form.Item
                {...layout}
                label={<FormattedMessage id="ProfileWeights.unitId" defaultMessage="Unit" />}
                name="unitId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                >
                  {Boolean(this.state["unitIdSelectItems"]) && this.state["unitIdSelectItems"].length > 0 &&
                    this.state["unitIdSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.abbreviation}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              {Boolean(this.state.selectedEldenItem) && (
                <Row>
                  <Col span={16}></Col>
                  <Col span={16}>
                    <div className="float-left font-weight-bold mb-5">

                    </div>
                  </Col>
                </Row>
              )}
              {Boolean(this.state.selectedEldenItem) &&
                Boolean(this.state.selectedEldenItem.itemDimension) &&
                this.state.selectedEldenItem.itemDimension.length > 0 &&
                this.state.selectedEldenItem.itemDimension.map((element, index) => (
                  <Form.Item {...tailFormItemLayout2}>
                    <Row>
                      <Col span={10}>
                        <Form.Item
                          label={element.itemDefinition}
                          name={element.itemDefinition}
                          rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >

                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={1}></Col>
                      <Col span={4}>
                        <Form.Item
                          noStyle
                          name={element.itemDefinition + "unitId"}
                          rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select allowClear placeholder="Unit"
                            onChange={value => {
                              if (Boolean(this.state.selectedEldenItem) &&
                                Boolean(this.state.selectedEldenItem.itemDimension) &&
                                this.state.selectedEldenItem.itemDimension.length > 0 && Boolean(this.formRef) && Boolean(this.formRef.current)) {
                                this.state.selectedEldenItem.itemDimension.forEach(element => {
                                  this.formRef.current.setFieldsValue({
                                    [element.itemDefinition + "unitId"]: value,
                                  });
                                });
                              }
                            }}>
                            {element.units &&
                              element.units.map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}{" "}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                ))}
              <div hidden={this.state.hideSave}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>                     <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">                       <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
              <div hidden={this.state.hideUpdate}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>                       <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />                     </Button>                   </Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">                       <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
            </div>

          </Form>


          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (<div className="alert alert-custom alert-light">
            <div className="alert-icon">
              <FilterOutlined />
            </div>
            <div className="alert-text">
              {this.state.searchCriteriaModels.map((val, i) => {
                return (
                  <div
                    key={i}
                    className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                    <span className="mr-2">{val.label}</span>
                    <strong className="mr-2">{val.type}</strong>
                    <span className="mr-2">{val.operationValue}</span>
                    <span className="mr-2">{val.showValue}</span>
                    <span>
                      <Tooltip title="Remove">
                        <CloseCircleOutlined
                          style={{ color: "crimson" }}
                          onClick={() => this.removeSearchCriteria(i)} />
                      </Tooltip>
                    </span>
                  </div>);
              })}
            </div>
            <div className="alert-close">
              <Tooltip title="Remove All">
                <CloseCircleOutlined
                  style={{ color: "crimson" }}
                  onClick={() => this.setState({
                    searchCriteriaModels: []
                  }, () => { this.filterChangeItem([]); })} />
              </Tooltip>
            </div>
          </div>)}
          <div
            style={{ marginTop: "2rem", marginBottom: "1rem" }}
            className="content-section implementation">
            <Table
              bordered scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns} dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination} />
          </div>


          <Modal
            show={this.state.modalDeleteShow}
            onHide={this.showOrHideDeleteModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted"
                  defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage
                  id="GeneralMessageAreYouSure"
                  defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary"
                onClick={this.showOrHideDeleteModal}>
                <FormattedMessage
                  id="GeneralButtonCancel"
                  defaultMessage="Cancel" />
              </Button>
              <Button
                variant="danger"
                onClick={this.delete}>
                <FormattedMessage
                  id="GeneralButtonDelete"
                  defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileWeights);
