import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Select, Input, DatePicker, InputNumber, Skeleton, Steps } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Modal as AntModal } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Step } = Steps;

export class ProcurementLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ProcurementLog",
      controllerName: "procurementLog",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      modalStatusShow: false
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseCompanyProjectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/companyProjectId");
    if (responseCompanyProjectId.type === "ERROR") {
      error(responseCompanyProjectId);
    }

    this.setState({
      companyProjectIdSelectItems: Boolean(responseCompanyProjectId.data) ? responseCompanyProjectId.data : []
    });

    var responsefilterItem = await handleRequest("GET", "/api/" + this.state.controllerName + "/filterItem");
    if (responsefilterItem.type === "ERROR") {
      error(responsefilterItem);
    }
    this.setState({
      filterItemSelectItems: Boolean(responsefilterItem.data) ? responsefilterItem.data : []
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  procurementLogStatus = async row => {
    let url = "/api/" + this.state.controllerName + "/getStatus/" + row.requestForQuotationId + "/" + row.poId;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        statusList: response.data,
        modalStatusShow: true
      });
    } else {
      this.setState({
        modalStatusShow: true
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ProcurementLog.project" defaultMessage="Project" />,
        key: "project",
        render: record => {
          return record.project;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Company Project"
              id="companyProjectId"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  companyProjectId: value,
                  companyProjectIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["companyProjectIdSelectItems"]) &&
                this.state["companyProjectIdSelectItems"].length > 0 &&
                this.state["companyProjectIdSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}{" "}
            </Select>
          </div>
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.itemGroup" defaultMessage="Item Group" />,
        key: "itemGroup",
        render: record => {
          return record.itemGroup;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Item Group"
              id="itemGroup"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  itemGroup: value,
                  itemGroupColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["filterItemSelectItems"]) &&
                this.state["filterItemSelectItems"].length > 0 &&
                this.state["filterItemSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}{" "}
            </Select>
          </div>
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.item" defaultMessage="Item" />,
        key: "item",
        render: record => {
          return record.item;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Item"
              id="item"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  item: value,
                  itemColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["itemSelectItems"]) &&
                this.state["itemSelectItems"].length > 0 &&
                this.state["itemSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}{" "}
            </Select>
          </div>
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.requestDate" defaultMessage="Request Date" />,
        key: "requestDate",
        render: record => {
          if (Boolean(record.requestDate)) {
            return moment(record.requestDate).format("DD-MM-YYYY HH:mm");
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.requestDate) ? this.state.pagination.requestDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={async (dates, dateStrings) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  requestDate: dates,
                  requestDateColor: Boolean(dates) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.quantity" defaultMessage="Quantity" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <InputNumber
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.quantity) ? this.state.pagination.quantity : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  current: 1,
                  quantity: e.target.value,
                  quantityColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.unit" defaultMessage="Unit" />,
        key: "unit",
        render: record => {
          return record.unit;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Unit"
              id="unit"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  unit: value,
                  unitColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["unitSelectItems"]) &&
                this.state["unitSelectItems"].length > 0 &&
                this.state["unitSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}{" "}
            </Select>
          </div>
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.Status" defaultMessage="Status" />,
        key: "statusName",
        width: 150,
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: record.status === "Deleted" ? "#d3d3d3" : "blue",
                  textDecoration: "underline",
                  display: "flex",
                  alignItems: "center"
                }}
                onClick={e => this.procurementLogStatus(record)}
              >
                {record.status}
              </div>
            </React.Fragment>
          );
        }
      },
      {
        title: <FormattedMessage id="ProcurementLog.%Progress" defaultMessage="% Progress" />,
        key: "progress",
        render: record => {
          return record.progress;
        },
        ...getColumnFilter({
          inputName: "progress",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.rfqNo" defaultMessage="Rfq No" />,
        key: "rfqNo",
        render: record => {
          return record.rfqNo;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.rfqNo) ? this.state.pagination.rfqNo : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  current: 1,
                  rfqNo: e.target.value,
                  rfqNoColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.rfqCreationDate" defaultMessage="Rfq Creation Date" />,
        key: "rfqCreationDate",
        render: record => {
          if (Boolean(record.rfqCreationDate)) return moment(record.rfqCreationDate).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.rfqCreationDate) ? this.state.pagination.rfqCreationDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={async (dates, dateStrings) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  rfqCreationDate: dates,
                  rfqCreationDateColor: Boolean(dates) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.rfqDate" defaultMessage="Rfq Date" />,
        key: "rfqDate",
        render: record => {
          if (Boolean(record.rfqDate)) return moment(record.rfqDate).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.rfqDate) ? this.state.pagination.rfqDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={async (dates, dateStrings) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  rfqDate: dates,
                  rfqDateColor: Boolean(dates) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.vendors" defaultMessage="Vendors" />,
        key: "vendors",
        render: record => {
          return record.vendors;
        },
        ...getColumnFilter({
          inputName: "vendors",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.reply" defaultMessage="Reply" />,
        key: "reply",
        render: record => {
          return record.reply;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <InputNumber
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.reply) ? this.state.pagination.reply : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  current: 1,
                  reply: e.target.value,
                  replyColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.evaluationDate" defaultMessage="Evaluation Date" />,
        key: "evaluationDate",
        render: record => {
          return record.evaluationDate;
        },
        ...getColumnFilter({
          inputName: "evaluationDate",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.poNo" defaultMessage="Po No" />,
        key: "poNo",
        render: record => {
          return record.poNo;
        },
        ...getColumnFilter({
          inputName: "poNo",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.poCreationDate" defaultMessage="Po Creation Date" />,
        key: "poCreationDate",
        render: record => {
          if (Boolean(record.poCreationDate)) return moment(record.poCreationDate).format("DD-MM-YYYY HH:mm");
        },
        ...getColumnFilter({
          inputName: "poCreationDate",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.poRequestDate" defaultMessage="Po Request Date" />,
        key: "poRequestDate",
        render: record => {
          return record.poRequestDate;
        },
        ...getColumnFilter({
          inputName: "poRequestDate",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.vendorReply" defaultMessage="Vendor Reply" />,
        key: "vendorReply",
        render: record => {
          return record.vendorReply;
        },
        ...getColumnFilter({
          inputName: "vendorReply",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.poDate" defaultMessage="Po Date" />,
        key: "poDate",
        render: record => {
          return record.poDate;
        },
        ...getColumnFilter({
          inputName: "poDate",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.vendor" defaultMessage="Vendor" />,
        key: "vendor",
        render: record => {
          return record.vendor;
        },
        ...getColumnFilter({
          inputName: "vendor",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.deliveryDate" defaultMessage="Delivery Date" />,
        key: "deliveryDate",
        render: record => {
          return record.deliveryDate;
        },
        ...getColumnFilter({
          inputName: "deliveryDate",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.poQuantity" defaultMessage="Po Quantity" />,
        key: "poQuantity",
        render: record => {
          return record.poQuantity;
        },
        ...getColumnFilter({
          inputName: "poQuantity",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProcurementLog.deliveredAmount" defaultMessage="Delivered Amount" />,
        key: "deliveredAmount",
        render: record => {
          return record.deliveredAmount;
        },
        ...getColumnFilter({
          inputName: "deliveredAmount",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
    ];
    const excelExport = (
      <>
        {/* {" "}
        {this.state.exportClick ? this.setState({ exportClick: false }) : ""}{" "}
        {this.state.exportClick ? (
          <ExcelFile filename="ProcurementLog" hideElement={true}>
            {" "}
            <ExcelSheet name="ProcurementLog" data={this.state.tableList}>
              {" "}
              <ExcelColumn label="Project" value="project" />
              <ExcelColumn label="Item Group" value="itemGroup" />
              <ExcelColumn label="Item" value="item" />
              <ExcelColumn label="Request Date" value="requestDate" />
              <ExcelColumn label="Quantity" value="quantity" />
              <ExcelColumn label="Unit" value="unit" />
              <ExcelColumn label="Rfq No" value="rfqNo" />
              <ExcelColumn label="Rfq Creation Date" value="rfqCreationDate" />
              <ExcelColumn label="Rfq Date" value="rfqDate" />
              <ExcelColumn label="Vendors" value="vendors" />
              <ExcelColumn label="Reply" value="reply" />
              <ExcelColumn label="Evaluation Date" value="evaluationDate" />
              <ExcelColumn label="Po No" value="poNo" />
              <ExcelColumn label="Po Creation Date" value="poCreationDate" />
              <ExcelColumn label="Po Request Date" value="poRequestDate" />
              <ExcelColumn label="Vendor Reply" value="vendorReply" />
              <ExcelColumn label="Po Date" value="poDate" />
              <ExcelColumn label="Vendor" value="vendor" />
              <ExcelColumn label="Delivery Date" value="deliveryDate" />
              <ExcelColumn label="Po Quantity" value="poQuantity" />
              <ExcelColumn label="Delivered Amount" value="deliveredAmount" />
              <ExcelColumn label="Status" value="status" />
              <ExcelColumn label="% Progress" value="progress" />
            </ExcelSheet>{" "}
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )}{" "} */}
      </>
    );
    return (
      <Badge.Ribbon
        text={<FormattedMessage id="procurementLog_Form.pageTitle" defaultMessage="Procurement Log" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              {" "}
              <Col md={21}></Col> <Col md={1}>{excelExport}</Col>{" "}
              <Col md={1}>
                {" "}
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>{" "}
              </Col>{" "}
              <Col md={1}>
                {" "}
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>{" "}
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>{" "}
              </Col>{" "}
            </Row>
            <div hidden={this.state.hideInputs}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="ProcurementLog.button" style={{ width: "100%" }} variant="secondary" onClick={this.buttonModal}>
                      <FormattedMessage id="ProcurementLog.button" defaultMessage="Button" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }{" "}
              <br />
              <div hidden={this.state.hideSave}>
                {" "}
                {
                  <Row gutter={[16, 16]}>
                    {" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      {" "}
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        {" "}
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />{" "}
                      </Button>{" "}
                    </Col>{" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                  </Row>
                }{" "}
              </div>
              <div hidden={this.state.hideUpdate}>
                {" "}
                {
                  <Row gutter={[16, 16]}>
                    {" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      {" "}
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        {" "}
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />{" "}
                      </Button>{" "}
                    </Col>{" "}
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      {" "}
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        {" "}
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />{" "}
                      </Button>{" "}
                    </Col>{" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                  </Row>
                }{" "}
              </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              {" "}
              <div className="alert-icon">
                {" "}
                <FilterOutlined />{" "}
              </div>{" "}
              <div className="alert-text">
                {" "}
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      {" "}
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>{" "}
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>{" "}
                      <span>
                        {" "}
                        <Tooltip title="Remove">
                          {" "}
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />{" "}
                        </Tooltip>{" "}
                      </span>{" "}
                    </div>
                  );
                })}{" "}
              </div>{" "}
              <div className="alert-close">
                {" "}
                <Tooltip title="Remove All">
                  {" "}
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />{" "}
                </Tooltip>{" "}
              </div>{" "}
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            {" "}
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />{" "}
          </div>
        </div>

        <AntModal
          open={this.state.modalStatusShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalStatusShow: false })}
          width={700}
          centered
        >
          <Badge.Ribbon
            text={<FormattedMessage id="RequestForQuotation.Status" defaultMessage="Status" />}
            placement="start"
            color="purple"
            style={{ fontSize: 12 }}
          >
            <div className="card card-custom" style={{ padding: "0.1rem", minHeight: "100%" }}>
              <div className="card-body pt-4 custom-timeline pr-5 pl-5 mr-5 ml-5 align-content-center">
                <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                <Steps direction="vertical" className="pl-5-custom anticon-custom">
                  {Boolean(this.state.statusList) &&
                    this.state.statusList.length > 0 &&
                    this.state.statusList.map((item, index) => {
                      return item.spin ? (
                        <Step status="process" title={item.status + (item.count >= 0 ? " - " + item.count : "")} icon={<LoadingOutlined />} />
                      ) : (
                        <Step
                          title={item.status + (item.count >= 0 ? " - " + item.count : "")}
                          status={
                            item.color === "green"
                              ? "finish"
                              : item.color === "blue"
                              ? "process"
                              : item.status === "Evaluation"
                              ? "wait"
                              : item.status === "Selection of vendor(s)"
                              ? "wait"
                              : item.status === "P.O. Creation"
                              ? "wait"
                              : "wait"
                          }
                          description={item.color === "green" || item.color === "blue" ? moment(item.date).format("DD-MM-YYYY HH:mm:ss") : ""}
                        />
                      );
                    })}
                </Steps>
              </div>
            </div>
          </Badge.Ribbon>
        </AntModal>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProcurementLog);
