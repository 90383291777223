import "antd/dist/antd.css";
import moment from "moment";
import { connect } from "react-redux";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Badge, Select, Input, DatePicker, InputNumber, Cascader, Button as AntButton } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { fillFilterOperations } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, ZoomInOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const { Option } = Select;

//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate"
      ? form.setFieldsValue({
          [dataIndex]: Boolean(record[dataIndex]) ? moment(record[dataIndex], "DD-MM-YYYY") : ""
        })
      : form.setFieldsValue({
          [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
        });
  };

  const toggleSave = async () => {
    try {
      if (dataIndex === "planedDeliveryDate") {
        const values = await form.validateFields();
        values.planedDeliveryDate = Boolean(values.planedDeliveryDate) ? moment(values.planedDeliveryDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else if (dataIndex === "paymentDate") {
        const values = await form.validateFields();
        values.paymentDate = Boolean(values.paymentDate) ? moment(values.paymentDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record }, { ...values });
      }
    } catch (errInfo) {}
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <>
        {dataIndex === "deliveryPlace" ? (
          <>
            <Form.Item
              label={<FormattedMessage id="POVendorReply.town" defaultMessage="Town" />}
              name="town"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Form.Item
              label={<FormattedMessage id="POVendorReply.city" defaultMessage="City" />}
              name="city"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Button id="SaveButton" onClick={toggleSave} style={{ width: "100%" }} variant="success">
              <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
            </Button>
          </>
        ) : (
          <Form.Item
            style={{
              margin: 0
            }}
            name={dataIndex}
          >
            {dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate" ? (
              <DatePicker ref={inputRef} onBlur={toggleSave} format={"DD-MM-YYYY"} />
            ) : dataIndex === "purchasingCriteriaId" ? (
              <Cascader
                style={{ width: "100%" }}
                options={record.purchasingCriteriaIdSelectItems}
                ref={inputRef}
                onChange={toggleSave}
                onBlur={toggleSave}
              />
            ) : dataIndex === "unit" ? (
              <Cascader style={{ width: "100%" }} options={record.unitSelectItems} ref={inputRef} onChange={toggleSave} onBlur={toggleSave} />
            ) : (
              <InputNumber
                parser={value => value.replace(",", ".")}
                formatter={value => value.replace(".", ",")}
                min={0}
                ref={inputRef}
                onPressEnter={toggleSave}
                onBlur={toggleSave}
              />
            )}
          </Form.Item>
        )}
      </>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion

export class StockLevelsLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "StockLevelsLog",
      controllerName: "stockLevelsLog",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      modalStatusShow: false
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {};

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.key = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async () => {
    const { tableList } = this.state;
    if (Boolean(tableList) && tableList.length > 0) {
      tableList.forEach(element => {
        element.purchasingCriteriaId =
          Boolean(element.purchasingCriteriaId) && element.purchasingCriteriaId.length > 0
            ? element.purchasingCriteriaId[element.purchasingCriteriaId.length - 1]
            : element.purchasingCriteriaId;
      });
    }
    console.log(tableList);
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAll", this.state.tableList);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handlePurchasingCriteria = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(item)) {
        let unitId =
          Boolean(value.purchasingCriteriaId) && value.purchasingCriteriaId.length > 0
            ? value.purchasingCriteriaId[value.purchasingCriteriaId.length - 1]
            : null;
        let unitTypeId = Boolean(value.purchasingCriteriaId) && value.purchasingCriteriaId.length > 0 ? value.purchasingCriteriaId[0] : null;
        if (!isNaN(unitId) && !isNaN(unitTypeId)) {
          item.unitId = unitId;
          item.purchasingCriteriaId = unitId;
          const unitType = row.purchasingCriteriaIdSelectItems.find(p => p.value === unitTypeId);
          if (Boolean(unitType) && Boolean(unitType.children) && unitType.children.length > 0) {
            const unit = unitType.children.find(p => p.value === unitId);
            if (Boolean(unit)) {
              item.purchasingCriteriaName = unit.label;
            }
          }
          this.setState({
            tableList: newData
          });
        }
      }
    }
  };

  handleMinStockLevel = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.minStockLevel === value.minStockLevel) return;
        item.minStockLevel = value.minStockLevel;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleMaxStockLevel = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.maxStockLevel === value.maxStockLevel) return;
        item.maxStockLevel = value.maxStockLevel;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleLeadTimeDay = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.leadTimeDay === value.leadTimeDay) return;
        item.leadTimeDay = value.leadTimeDay;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleDesiredLevelOfService = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.desiredLevelOfService === value.desiredLevelOfService) return;
        item.desiredLevelOfService = value.desiredLevelOfService;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleUsageRateDay = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.usageRateDay === value.usageRateDay) return;
        item.usageRateDay = value.usageRateDay;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleSafetyStockPercentage = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.safetyStockPercentage === value.safetyStockPercentage) return;
        item.safetyStockPercentage = value.safetyStockPercentage;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleSafeDays = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.safeDays === value.safeDays) return;
        item.safeDays = value.safeDays;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  searchItem = async item => {
    console.log("item", item);
    this.setState({ loadingSearch: true });
    if (Boolean(item) && item.length > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemSearch/" + item);
      console.log("response", response.data);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          itemIdSelectItems: [],
          loadingSearch: false
        });
      } else {
        if (Boolean(response.data)) {
          this.setState({
            itemIdSelectItems: response.data,
            loadingSearch: false
          });
        } else {
          this.setState({
            itemIdSelectItems: [],
            loadingSearch: false
          });
        }
      }
    } else {
      this.setState({
        itemIdSelectItems: [],
        loadingSearch: false
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    };

    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="StockLevelsLog.project" defaultMessage="Project" />,
        key: "companyProjectId",
        render: record => {
          return record.companyProjectName;
        }
      },
      {
        title: <FormattedMessage id="StockLevelsLog.ItemCode" defaultMessage="Item Code" />,
        key: "code",
        render: record => {
          return record.code;
        }
      },
      {
        title: <FormattedMessage id="StockLevelsLog.Item" defaultMessage="Item" />,
        key: "id",
        render: record => {
          return record.renameText;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              allowClear
              onSearch={this.searchItem}
              optionFilterProp="children"
              style={{ width: "100%" }}
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  id: value,
                  idColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {Boolean(this.state["itemIdSelectItems"]) &&
                this.state["itemIdSelectItems"].length > 0 &&
                this.state["itemIdSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.idColor) ? this.state.idColor : "#bfbfbf" }} />,
        sorter: true
      },
      {
        title: <FormattedMessage id="StockLevelsLog.PurchasingCriteria" defaultMessage="Purchasing Criteria" />,
        key: "purchasingCriteriaId",
        width: 100,
        render: record => {
          return record.purchasingCriteriaName;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "purchasingCriteriaId",
          title: <FormattedMessage id="StockLevelsLog.PurchasingCriteria" defaultMessage="Purchasing Criteria" />,
          handleSave: this.handlePurchasingCriteria
        })
      },
      {
        title: <FormattedMessage id="StockLevelsLog.MinStockLevel" defaultMessage="Min Stock Level" />,
        key: "minStockLevel",
        width: 100,
        render: record => {
          if (Boolean(record.desiredLevelOfService) && record.desiredLevelOfService > 0) {
            record.minStockLevel = (record.usageRateDay * record.leadTimeDay) / record.desiredLevelOfService - record.safetyStockPercentage;
          }
          if (record.minStockLevel < 0) {
            record.minStockLevel = 0;
          }
          return record.minStockLevel?.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "minStockLevel",
          title: <FormattedMessage id="StockLevelsLog.MinStockLevel" defaultMessage="Min Stock Level" />,
          handleSave: this.handleMinStockLevel
        })
      },
      {
        title: <FormattedMessage id="StockLevelsLog.MaxStockLevel" defaultMessage="Max Stock Level" />,
        key: "maxStockLevel",
        width: 100,
        render: record => {
          return record.maxStockLevel?.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "maxStockLevel",
          title: <FormattedMessage id="StockLevelsLog.MaxStockLevel" defaultMessage="Max Stock Level" />,
          handleSave: this.handleMaxStockLevel
        })
      },
      {
        title: <FormattedMessage id="StockLevelsLog.LeadTimeDay" defaultMessage="Lead Time Day" />,
        key: "leadTimeDay",
        width: 100,
        render: record => {
          return record.leadTimeDay?.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "leadTimeDay",
          title: <FormattedMessage id="StockLevelsLog.LeadTimeDay" defaultMessage="Lead Time (Day)" />,
          handleSave: this.handleLeadTimeDay
        })
      },
      {
        title: <FormattedMessage id="StockLevelsLog.DesiredLevelOfService" defaultMessage="Desired Level Of Service (%)" />,
        key: "desiredLevelOfService",
        width: 100,
        render: record => {
          return record.desiredLevelOfService?.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "desiredLevelOfService",
          title: <FormattedMessage id="StockLevelsLog.DesiredLevelOfService" defaultMessage="Desired Level Of Service" />,
          handleSave: this.handleDesiredLevelOfService
        })
      },
      {
        title: <FormattedMessage id="StockLevelsLog.UsageRateDay" defaultMessage="Usage Rate (In a day)" />,
        key: "usageRateDay",
        width: 100,
        render: record => {
          record.usageRateDay = record.leadTimeDay / 30;
          return record.usageRateDay?.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "usageRateDay",
          title: <FormattedMessage id="StockLevelsLog.UsageRateDay" defaultMessage="Usage Rate Day" />,
          handleSave: this.handleUsageRateDay
        })
      },
      {
        title: <FormattedMessage id="StockLevelsLog.SafetyStockPercentage" defaultMessage="Safety Stock Percentage" />,
        key: "safetyStockPercentage",
        width: 100,
        render: record => {
          return record.safetyStockPercentage?.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "safetyStockPercentage",
          title: <FormattedMessage id="StockLevelsLog.SafetyStockPercentage" defaultMessage="Safety Stock Percentage" />,
          handleSave: this.handleSafetyStockPercentage
        })
      },
      {
        title: <FormattedMessage id="StockLevelsLog.SafeDays" defaultMessage="Safe Days" />,
        key: "safeDays",
        width: 100,
        render: record => {
          record.safeDays = Boolean(record.leadTimeDay) && record.leadTimeDay > 0 ? record.minStockLevel / record.leadTimeDay : 0;
          return record.safeDays?.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "safeDays",
          title: <FormattedMessage id="StockLevelsLog.SafeDays" defaultMessage="Safe Days" />,
          handleSave: this.handleSafeDays
        })
      },
      {
        title: <FormattedMessage id="StockLevelsLog.StockMovements" defaultMessage="Stock Movements" />,
        key: "id",
        width: 100,
        render: record => {
          return <AntButton onClick={() => {}} style={{ border: "0px", color: "#1BC5BD" }} icon={<ZoomInOutlined fontSize="large" />}></AntButton>;
        }
      }
    ];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage="Stock Level Log" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={20}></Col>
              <Col md={4}>
                <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="GeneralButtonSaveAll" defaultMessage="Save All" />
                </Button>
              </Col>
            </Row>
            <div hidden={this.state.hideInputs}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="StockLevelsLog.button" style={{ width: "100%" }} variant="secondary" onClick={this.buttonModal}>
                      <FormattedMessage id="StockLevelsLog.button" defaultMessage="Button" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
              <br />
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              components={components}
              rowClassName={() => "editable-row"}
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(StockLevelsLog);
