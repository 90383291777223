import { Table, Image } from "antd";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";

export class SelectFromImageList extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    console.log("zzz", this.props.data);
  }

  getImageGalleryFromUrls = (paths, key) => {
    const galleryName = "Gallery" + key;
    if (Boolean(paths) && paths.length > 0) {
      let pathList = paths.split(",");

      return (
        <>
          {Boolean(paths) && paths.length > 0 ? (
            <Image
              width={250}
              preview={{
                visible: false
              }}
              src={pathList[0].trim()}
              onClick={() => this.setState({ [galleryName]: true })}
            />
          ) : null}
          {Boolean(pathList) && pathList.length > 0 ? (
            <div style={{ display: "none" }}>
              <Image.PreviewGroup
                preview={{
                  visible: this.state[galleryName],
                  onVisibleChange: vis => this.setState({ [galleryName]: vis })
                }}
              >
                {pathList.map((field, i) => (
                  <Image key={i} src={field} />
                ))}
              </Image.PreviewGroup>
            </div>
          ) : null}
        </>
      );
    }
  };

  render() {
    const columns = [
      {
        title: "Image",
        key: "image",
        alignnment: "center",
        render: record => {
          return this.getImageGalleryFromUrls(record.path, record.key);
        }
      }
    ];
    return (
      <div>
        {Boolean(this.props.data) && (
          <Table
            rowSelection={{
              type: "radio",
              onChange: (selectedRowKeys, selectedRows) => {
                if (Boolean(selectedRows) && selectedRows.length > 0) {
                  this.props.setSelected(selectedRows[0]);
                }
              }
            }}
            columns={columns}
            dataSource={this.props.data}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SelectFromImageList);
