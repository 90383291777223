import "antd/dist/antd.css";
import moment from "moment";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import {
  Form, Table, Col, Row, Button as AntButton, Badge, Tooltip, Card, InputNumber, Divider, Upload, Image, Collapse, Checkbox
  , Radio, Cascader, Input, Typography, Select, DatePicker
} from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, DownloadOutlined, PlusOutlined, UploadOutlined, CloseOutlined, EyeInvisibleTwoTone, EyeTwoTone, MinusCircleOutlined, MinusOutlined } from "@ant-design/icons";
import DraggableModal from "../../Components/DraggableModal";


const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;
const { Text } = Typography;

export class ContractLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ContractLog",
      controllerName: "contractLog",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      hideUpdate2: true,
      hideSave2: false,
      hideInputs2: true,
      tableList: [],
      activePanelKey: [],
      accordion: false,
      ourExpectedPrices: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.fillCode("ContractLog.ContractType");

    var responseCustomerId = await handleRequest("GET", "/api/" + this.state.controllerName + "/customerId");
    if (responseCustomerId.type === "ERROR") {
      error(responseCustomerId);
    }
    var responseVendorId = await handleRequest("GET", "/api/" + this.state.controllerName + "/vendorId");
    if (responseVendorId.type === "ERROR") {
      error(responseVendorId);
    }
    var responseDepartment = await handleRequest("GET", "/api/" + this.state.controllerName + "/departmentId");
    if (responseDepartment.type === "ERROR") {
      error(responseDepartment);
    }
    var responseCurrency = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responseCurrency.type === "ERROR") {
      error(responseCurrency);
    }

    this.setState({
      customerIdSelectItems: Boolean(responseCustomerId.data) ? responseCustomerId.data : [],
      vendorIdSelectItems: Boolean(responseVendorId.data) ? responseVendorId.data : [],
      departmentIdSelectItems: Boolean(responseDepartment.data) ? responseDepartment.data : [],
      currencySelectItems: Boolean(responseCurrency.data) ? responseCurrency.data : [],
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = [];
    let fileUrl = Boolean(row.fileUrl) ? row.fileUrl.split(",") : [];
    if (Boolean(fileUrl) && fileUrl.length > 0) {
      fileUrl.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileList.push(obj);
      });
    }
    console.log("row", row);
    console.log("fileList", fileList);

    this.formRef.current.setFieldsValue({
      ...row,
      contractDate: Boolean(row.contractDate) ? moment(row.contractDate) : null,
      effectiveDate: Boolean(row.effectiveDate) ? moment(row.effectiveDate) : null,
      endDate: Boolean(row.endDate) ? moment(row.endDate) : null,
      fileUrl: fileList,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      fileList: fileList,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  editParasModal = row => {
    this.setState({
      showEditParas: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let editParasAction = {
      name: "Edit Paras",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.editParasModal.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(editParasAction);
    actionList.push(deleteAction);



    
    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      hideUpdate2: true,
      hideSave2: false,
      hideInputs2: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  onClickCreateNew2 = () => {
    this.resetInputs();
    if (this.state.hideInputs2) {
      this.setState({
        hideInputs2: false,
        hideSave2: false,
        hideUpdate2: true
      });
    } else {
      this.setState({
        hideInputs2: true,
        hideSave2: true,
        hideUpdate2: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };

    let pathList = [];
    if (Boolean(newItem.fileUrl))
      newItem.fileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) pathList.push(item.response.url);
        else if (Boolean(item.url)) pathList.push(item.url);
      });
    newItem.fileUrl = pathList.toString();

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  showReport = async rowData => {
    try {
      if (rowData.fileUrl !== null && rowData.fileUrl !== undefined && rowData.fileUrl !== "") {
        const fileRealUrl = rowData.fileUrl;

        if (
          fileRealUrl.endsWith(".png") ||
          fileRealUrl.endsWith(".jpg") ||
          fileRealUrl.endsWith(".tiff") ||
          fileRealUrl.endsWith(".jpeg") ||
          fileRealUrl.endsWith(".PNG") ||
          fileRealUrl.endsWith(".JPG") ||
          fileRealUrl.endsWith(".TIFF") ||
          fileRealUrl.endsWith(".JPEG")
        ) {
          this.setState({
            imageUrl: fileRealUrl,
            modalPhotoShow: true
          });
        } else {
          window.location.href = fileRealUrl;
        }
      } else {
        showError("File cant found");
      }
    } catch (error) {
      showError("File cant found");
    }
  };

  setOpenPanels = key => {
    this.setState({
      activePanelKey: key
    });
  };

  render() {

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 12 }
    };

    const layout1 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      style: {
        marginBottom: 0
      }
    };

    const layoutLeft = {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 }
    };

    const layoutRight = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 }
    };

    const layoutDimension = {
      labelCol: { span: 9 },
      wrapperCol: { span: 8 }
    };
    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }

      return e && e.fileList;
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ContractLog.contractNo" defaultMessage="Contract No" />,
        key: "contractNo",
        render: record => {
          return record.contractNo;
        }, ...getColumnFilter({
          inputName: "contractNo", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ContractLog.SignedWithCustomer" defaultMessage="Signed With Customer" />,
        key: "customerId",
        render: record => {
          return record.customerName;
        }, ...getColumnFilter({
          inputName: "customerId", inputType: "SELECT",
          selectItems: Boolean(this.state["customerIdSelectItems"]) ? this.state["customerIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ContractLog.SignedWithVendor" defaultMessage="Signed With Vendor" />,
        key: "vendorId",
        render: record => {
          return record.vendorName;
        }, ...getColumnFilter({
          inputName: "vendorId", inputType: "SELECT",
          selectItems: Boolean(this.state["vendorIdSelectItems"]) ? this.state["vendorIdSelectItems"] : [],
          filterChangeItecustomerIdSelectItemsm: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ContractLog.ResponsibleDepartment" defaultMessage="Responsible Department" />,
        key: "departmentId",
        render: record => {
          return record.departmentName;
        }, ...getColumnFilter({
          inputName: "departmentId", inputType: "TEXT",
          selectItems: Boolean(this.state["departmentIdSelectItems"]) ? this.state["departmentIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ContractLog.ContractType" defaultMessage="Contract Type" />,
        key: "contractTypeId",
        render: record => {
          return record.contractTypeName;
        }, ...getColumnFilter({
          inputName: "contractTypeId", inputType: "TEXT",
          selectItems: Boolean(this.state["contractTypeIdSelectItems"]) ? this.state["contractTypeIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ContractLog.contractDate" defaultMessage="Contract Date" />,
        key: "contractDate",
        render: record => {
          return Boolean(record.contractDate) ? moment(record.contractDate).format("DD-MM-YYYY") : "";
        }, ...getColumnFilter({
          inputName: "contractDate", inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ContractLog.effectiveDate" defaultMessage="Effective Date" />,
        key: "effectiveDate",
        render: record => {
          return Boolean(record.effectiveDate) ? moment(record.effectiveDate).format("DD-MM-YYYY") : "";
        }, ...getColumnFilter({
          inputName: "effectiveDate", inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ContractLog.endDate" defaultMessage="End Date" />,
        key: "endDate",
        render: record => {
          return Boolean(record.endDate) ? moment(record.endDate).format("DD-MM-YYYY") : "";
        }, ...getColumnFilter({
          inputName: "endDate", inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ContractLog.title" defaultMessage="Title" />,
        key: "title",
        render: record => {
          return record.title;
        }, ...getColumnFilter({
          inputName: "title", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ContractLog.Scope" defaultMessage="Scope" />,
        key: "detail",
        render: record => {
          return record.detail;
        }, ...getColumnFilter({
          inputName: "detail", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ContractLog.Volume" defaultMessage="Volume" />,
        key: "volumeOfContract",
        render: record => {
          return record.volumeOfContract;
        }, ...getColumnFilter({
          inputName: "volumeOfContract", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ContractLog.ContractParas" defaultMessage="Contract Paras" />,
        key: "ofContract",
        render: record => {
          let ofContractNames = [];
          if (Boolean(record.ofContract)) {
            ofContractNames.push(record.ofContract);
          }
          if (Boolean(record.currency)) {
            ofContractNames.push(record.currency);
          }
          return ofContractNames.join(" ");
        }, ...getColumnFilter({
          inputName: "ofContract", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ContractLog.File" defaultMessage="File" />,
        key: "fileUrl",
        render: record => {
          return (
            record.fileUrl &&
            <>
              {(
                record.fileUrl.endsWith(".png") ||
                record.fileUrl.endsWith(".jpg") ||
                record.fileUrl.endsWith(".tiff") ||
                record.fileUrl.endsWith(".jpeg") ||
                record.fileUrl.endsWith(".PNG") ||
                record.fileUrl.endsWith(".JPG") ||
                record.fileUrl.endsWith(".TIFF") ||
                record.fileUrl.endsWith(".JPEG")
              )
                ? (
                  <Image width={80} src={record.fileUrl} />
                ) : (
                  <AntButton
                    style={{
                      marginLeft: "2px",
                      marginTop: "-2px",
                      alignContent: "center"
                    }}
                    onClick={() => this.showReport(record)}
                    type="primary"
                    shape="round"
                    icon={<DownloadOutlined />}
                  >
                    {/* You can add text inside the button here if needed */}
                  </AntButton>
                )}
            </>
          );
        },
      },
      {
        title: <FormattedMessage id="ContractLog.paymentTerms" defaultMessage="Payment Terms" />,
        key: "paymentTerms",
        render: record => {
          return record.paymentTerms;
        }, ...getColumnFilter({
          inputName: "paymentTerms", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
    ];
    const excelExport = (<>
      {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}         {this.state.exportClick ? (<ExcelFile filename="ContractLog" hideElement={true}>             <ExcelSheet name="ContractLog" data={this.state.tableList}>               <ExcelColumn label="S/N" value="sn" />
      <ExcelColumn label="Contract No" value="contractNo" />
      <ExcelColumn label="Status" value="status" />
      <ExcelColumn label="Signed With" value="signedWith" />
      <ExcelColumn label="Responsible Department" value="responsibleDepartment" />
      <ExcelColumn label="Contract Type" value="contractType" />
      <ExcelColumn label="Contract Date" value="contractDate" />
      <ExcelColumn label="Effective Date" value="effectiveDate" />
      <ExcelColumn label="End Date" value="endDate" />
      <ExcelColumn label="Title" value="title" />
      <ExcelColumn label="Scope" value="scope" />
      <ExcelColumn label="Volume" value="volume" />
      <ExcelColumn label="Contract Paras" value="contractParas" />
      <ExcelColumn label="Payment Terms" value="paymentTerms" />
    </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel}
     icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)}    */}
    </>);
    return (
      <Badge.Ribbon text={<FormattedMessage id="contractLog.pageTitle" defaultMessage="Contract Log" />} placement="start" color="purple" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={21}></Col>
              <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>             <Col md={1}>
                <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={this.onClickCreateNew} icon={<CreateIcon color="secondary" fontSize="large" />}               ></AntButton>
                <AntButton style={{ border: "0px" }} hidden={this.state.hideInputs} onClick={this.onClickCreateNew} icon={<RemoveCircle color="error" fontSize="large" />}               ></AntButton>
              </Col>
            </Row>

            <div hidden={this.state.hideInputs}>

              <Row gutter={16} style={{ marginBottom: "16px" }}>
                {/* Sol Card */}
                <Col span={24}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                     <Row gutter={16} style={{ marginBottom: "16px" }}>
                    <Col span={12}>
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ContractLog.contractNo" defaultMessage="Contract No" />}
                        name="contractNo"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    }

                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ContractLog.ContractType" defaultMessage="Contract Type" />}
                      name="contractTypeId"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch
                        optionFilterProp="children"
                        style={{ width: "100%" }}
                        dropdownRender={menu => (
                          <div>
                            {menu}
                            <Divider style={{ margin: "4px 0" }} />
                            <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                              <Input
                                style={{ flex: "auto" }}
                                value={this.state.codeName}
                                onChange={event => {
                                  this.setState({
                                    codeName: event.target.value
                                  });
                                }}
                              />
                              <a
                                style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                onClick={() => this.addCode("ContractLog.ContractType")}
                              >
                                <PlusOutlined /> <FormattedMessage id="General.AddItem" defaultMessage="Add Item" />
                              </a>
                            </div>
                          </div>
                        )}
                      >
                        {Boolean(this.state["ContractLog.ContractTypeSelectItems"]) && this.state["ContractLog.ContractTypeSelectItems"].length > 0 &&
                          this.state["ContractLog.ContractTypeSelectItems"].map(i => (
                            <Option key={i.id} value={i.id}>
                              {i.name}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ContractLog.SignedWithVendor" defaultMessage="Signed With Vendor" />}
                      name="vendorId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                      >
                        {Boolean(this.state["vendorIdSelectItems"]) && this.state["vendorIdSelectItems"].length > 0 &&
                          this.state["vendorIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ContractLog.SignedWithCustomer" defaultMessage="Signed With Customer" />}
                      name="customerId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                      >
                        {Boolean(this.state["customerIdSelectItems"]) && this.state["customerIdSelectItems"].length > 0 &&
                          this.state["customerIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>


                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ContractLog.responsibleDepartment" defaultMessage="Responsible Department" />}
                      name="departmentId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                      >
                        {Boolean(this.state["departmentIdSelectItems"]) && this.state["departmentIdSelectItems"].length > 0 &&
                          this.state["departmentIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ContractLog.contractDate" defaultMessage="Contract Date" />}
                        name="contractDate"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                      </Form.Item>
                    }
</Col>
<Col span={12}>
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ContractLog.effectiveDate" defaultMessage="Effective Date" />}
                        name="effectiveDate"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ContractLog.endDate" defaultMessage="End Date" />}
                        name="endDate"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ContractLog.TitleContract" defaultMessage="Title of Contract" />}
                        name="title"
                        rules={[
                          {
                            required: true,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ContractLog.scopeAndDetail" defaultMessage="Scope and Detail" />}
                        name="detail"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ContractLog.VolumeOfContract" defaultMessage="Volume of Contract" />}
                        name="volumeOfContract"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    }

                    {<Form.Item
                      {...layout}
                      label={<FormattedMessage id="ContractLog.VolumeOfContract" defaultMessage="Volume of Contract" />}
                      style={{ marginBottom: "5px" }}
                    >
                      <Row gutter={8}>
                        <Col span={10}>
                          <Form.Item name="ofContract">
                            <InputNumber
                              style={{
                                width: "100%"
                              }}
                              min={0}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                        </Col>
                        <Col span={10}>
                          <Form.Item name="currency">
                            <Select allowClear showSearch
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                            >
                              {Boolean(this.state["currencySelectItems"]) && this.state["currencySelectItems"].length > 0 &&
                                this.state["currencySelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}{" "}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    }

</Col>

</Row>

<Row gutter={16} style={{ marginBottom: "16px" }}>
<Col span={18}></Col>
<Col span={6}>
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="DocumentLog.File" defaultMessage="File" />}
                        name="fileUrl"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...props}>
                          <AntButton icon={<UploadOutlined />}>
                            <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                          </AntButton>
                        </Upload>
                      </Form.Item>
                    }
                    </Col>
</Row>
                  </Card>
                </Col>

              </Row>



              <br />
              <div hidden={this.state.hideSave}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>                     <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">                       <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
              <div hidden={this.state.hideUpdate}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>                       <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />                     </Button>                   </Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">                       <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
            </div>
          </Form>


         


          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (<div className="alert alert-custom alert-light">                 <div className="alert-icon">                   <FilterOutlined />                 </div>                 <div className="alert-text">                   {this.state.searchCriteriaModels.map((val, i) => { return (<div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">                         <span className="mr-2">{val.label}</span>                         <strong className="mr-2">{val.type}</strong>                         <span className="mr-2">{val.operationValue}</span>                         <span className="mr-2">{val.showValue}</span>                         <span>                           <Tooltip title="Remove">                             <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />                           </Tooltip>                         </span>                       </div>); })}                 </div>                 <div className="alert-close">                   <Tooltip title="Remove All">                     <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.setState({ searchCriteriaModels: [] }, () => { this.filterChangeItem([]); })} />                   </Tooltip>                 </div>               </div>)}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">           <Table bordered scroll={{ x: 900, scrollToFirstRowOnChange: true }} columns={columns} dataSource={this.state.tableList} loading={this.state.loading} onChange={this.handleTableChange} pagination={this.state.pagination} />         </div>
          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>           <Modal.Header closeButton>             <Modal.Title id="contained-modal-title-vcenter">               <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />             </Modal.Title>           </Modal.Header>           <Modal.Body>             <p>               <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />             </p>           </Modal.Body>           <Modal.Footer>             <Button variant="secondary" onClick={this.showOrHideDeleteModal}>               <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />             </Button>             <Button variant="danger" onClick={this.delete}>               <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />             </Button>           </Modal.Footer>         </Modal>
          <DraggableModal
            title={<FormattedMessage id="ContractLog.showEditParas" defaultMessage="" />}
            closable="false"
            open={this.state.showEditParas}
            onCancel={() => {
              this.setState({ showEditParas: false });
            }}
            width={1000}
            centered
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ showEditParas: false });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
            content={
              <div className="card card-custom" style={{ minHeight: "100%" }}>

                
                <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    { <Row gutter={[16, 16]}>
            <Col md={23}></Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs2}
                onClick={this.onClickCreateNew2}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs2}
                onClick={this.onClickCreateNew2}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>
          }

<div hidden={this.state.hideInputs2}>
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="VendorPerformance.titleId" defaultMessage="Title" />}
                  name="titleId"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("VendorPerformanceTitle")}
                          >
                            <PlusOutlined /> Add item
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["VendorPerformanceTitleSelectItems"]) &&
                      this.state["VendorPerformanceTitleSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              <div hidden={this.state.hideSave2}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate2}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </Form>
          </div>

                    <br />
                    <Collapse activeKey={this.state.activePanelKey} onChange={this.setOpenPanels} accordion={this.state.accordion}>
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage
                              id="RequestForQuotation.PricePaymentAndInvoicingDeliveryDates"
                              defaultMessage="Price, payment and invoicing/Delivery dates"
                            />
                          </span>
                        }
                        key="1"
                      >
                        {this.state.pricePaymentDeliveryYourOfferForPriceVisible && (
                          <Form.Item
                            {...layout}
                            label={
                              <span style={{ color: this.state.priceOfferVisible === true ? "#1677ff" : "black", fontWeight: "bold" }}>
                                <FormattedMessage id="RequestForQuotation.priceOffer" defaultMessage="Your offer for price" />{" "}
                              </span>
                            }
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              textDecoration: "underline"
                            }}
                            name="priceOffer"
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                            valuePropName="checked"
                          >
                            <Checkbox
                              onChange={e => {
                                let pricePaymentDeliveryOurExpectedPriceVisible = true;
                                if (e.target.checked) {
                                  pricePaymentDeliveryOurExpectedPriceVisible = false;

                                  this.formRef.current.setFieldsValue({});
                                }
                                this.setState({
                                  mergeColumnsOurExpectedPriceVisible: e.target.checked,
                                  priceOfferVisible: e.target.checked,
                                  pricePaymentDeliveryOurExpectedPriceVisible: pricePaymentDeliveryOurExpectedPriceVisible
                                });
                              }}
                            />
                          </Form.Item>
                        )}
                        {this.state.priceOfferVisible && (
                          <>
                            <Row gutter={[16, 16]}>
                              <Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6}></Col>
                              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                {<span style={{ fontWeight: "bold" }}>Including : </span>}
                              </Col>
                              <Col xs={0} sm={0} md={10} lg={10} xl={10} xxl={10}></Col>
                            </Row>
                            <br />

                            <Row gutter={8}>
                              <Col span={6}></Col>
                              <Col span={6}>
                                {this.state.goodsAndServiceVisible && (
                                  <Form.Item
                                    label={<FormattedMessage id="RequestForQuotation.goodsAndService" defaultMessage="Goods and Service" />}
                                    name="goodsAndService"
                                    rules={[
                                      {
                                        required: false,
                                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                      }
                                    ]}
                                    valuePropName="checked"
                                  >
                                    <Checkbox></Checkbox>
                                  </Form.Item>
                                )}
                              </Col>
                              <Col span={6}>
                                {this.state.insuranceVisible && (
                                  <Form.Item
                                    label={<FormattedMessage id="RequestForQuotation.insurance" defaultMessage="Insurance" />}
                                    name="insurance"
                                    rules={[
                                      {
                                        required: false,
                                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                      }
                                    ]}
                                    valuePropName="checked"
                                  >
                                    <Checkbox></Checkbox>
                                  </Form.Item>
                                )}
                              </Col>
                              <Col span={6}>
                                {this.state.shippingVisible && (
                                  <Form.Item
                                    label={<FormattedMessage id="RequestForQuotation.shipping" defaultMessage="Shipping" />}
                                    name="shipping"
                                    rules={[
                                      {
                                        required: false,
                                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                      }
                                    ]}
                                    valuePropName="checked"
                                  >
                                    <Checkbox></Checkbox>
                                  </Form.Item>
                                )}
                              </Col>
                            </Row>
                          </>
                        )}

                        {this.state.pricePaymentDeliveryOurExpectedPriceVisible && (
                          <Form.Item
                            {...layout}
                            label={
                              <span
                                style={{
                                  color: this.state.pricePaymentDeliveryYourOfferForPriceVisible === true ? "black" : "#1677ff",
                                  fontWeight: "bold"
                                }}
                              >
                                <FormattedMessage id="RequestForQuotation.ourExpectedPrice" defaultMessage="Our expected price" />{" "}
                              </span>
                            }
                            name="ourExpectedPrice"
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                            valuePropName="checked"
                          >
                            <Checkbox
                              onChange={e => {
                                let pricePaymentDeliveryYourOfferForPriceVisible = true;
                                if (e.target.checked) {
                                  pricePaymentDeliveryYourOfferForPriceVisible = false;
                                  this.formRef.current.setFieldsValue({});
                                }
                                this.setState({
                                  mergeColumnsOurExpectedPriceVisible: e.target.checked,
                                  pricePaymentDeliveryYourOfferForPriceVisible: pricePaymentDeliveryYourOfferForPriceVisible
                                });
                              }}
                            />
                          </Form.Item>
                        )}

                        {this.state.mergeColumnsOurExpectedPriceVisible && (
                          <>
                            {this.state.id &&
                              <Row gutter={[16, 16]}>
                                <Col md={4}>
                                  <Tooltip title={<FormattedMessage id="RequestForQuotation.AddMoreItems" defaultMessage="Add More Items" />}>
                                    <Button
                                      style={{ width: "100%" }}
                                      variant="primary"
                                      onClick={() => {
                                        this.setState({
                                          addMoreItemsModalShow: true,
                                          random: Math.random()
                                        });
                                      }}
                                    >
                                      <FormattedMessage id="RequestForQuotation.AddMoreItems" defaultMessage="Add More Items" />
                                    </Button>
                                  </Tooltip>
                                </Col>
                              </Row>}
                            <br />
                            <Table
                              // rowSelection={rowSelectionOurExpectedPrice}
                              bordered
                              // components={components}
                              rowClassName={() => "editable-row"}
                              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                              // columns={
                              //   Boolean(mergeColumnsOurExpectedPrice) && mergeColumnsOurExpectedPrice.length > 0
                              //     ? mergeColumnsOurExpectedPrice
                              //     : columnsOurExpectedPrice
                              // }
                              dataSource={this.state.ourExpectedPrices.filter(p => p.active)}
                              loading={this.state.loading}
                              pagination={false}
                              summary={pageData => {
                                let priceSubTotal = 0;
                                let priceVatTotal = 0;
                                pageData.forEach(({ price, priceVat, selected }) => {
                                  if (Boolean(price) && price > 0 && Boolean(selected) && selected) {
                                    priceSubTotal += price;

                                    if (Boolean(priceVat) && priceVat > 0) {
                                      priceVatTotal += (priceVat * price) / 100;
                                    }
                                  }
                                });
                                var priceTotal = priceSubTotal + priceVatTotal;
                                return (
                                  <>
                                    <Table.Summary.Row>
                                      <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                                      <Table.Summary.Cell>
                                        <FormattedMessage id="RequestForQuotation.subTotal" defaultMessage="Sub total" />
                                      </Table.Summary.Cell>
                                      <Table.Summary.Cell>
                                        <Text type="danger">{priceSubTotal}</Text>
                                      </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                    <Table.Summary.Row>
                                      <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                                      <Table.Summary.Cell>
                                        <FormattedMessage id="RequestForQuotation.vat" defaultMessage="VAT" />
                                      </Table.Summary.Cell>
                                      <Table.Summary.Cell>
                                        <InputNumber readOnly min={0} value={priceVatTotal} />
                                      </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                    <Table.Summary.Row>
                                      <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                                      <Table.Summary.Cell>
                                        <FormattedMessage id="RequestForQuotation.total" defaultMessage="Total" />
                                        Total
                                      </Table.Summary.Cell>
                                      <Table.Summary.Cell>
                                        <Text type="danger">{priceTotal}</Text>
                                      </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                  </>
                                );
                              }}
                            />
                          </>
                        )}
                      </Panel>

                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.PaymentTerms" defaultMessage="Payment Terms" />
                          </span>
                        }
                        key="2"
                      >
                        {
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="RequestForQuotation.termsForPayment" defaultMessage="Your terms for payment" />}
                            name="termsForPayment"
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                            valuePropName="checked"
                          >
                            <Checkbox></Checkbox>
                          </Form.Item>
                        }

                        {this.dynamicAttirbute(layout, "paymentTerms")}
                      </Panel>
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.ValidityOfQuotation" defaultMessage="Validity of Quotation" />
                          </span>
                        }
                        key="3"
                      >
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="RequestForQuotation.validityValue" defaultMessage="Validity" />}
                          style={{ marginBottom: "5px" }}
                        >
                          <Row gutter={8}>
                            <Col span={6}>
                              <Form.Item
                                {...layout}
                                name="validityValue"
                                rules={[
                                  {
                                    required: false,
                                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                  }
                                ]}
                              >
                                <InputNumber parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} min={0} />
                              </Form.Item>
                            </Col>
                            <Col span={18}>
                              <Form.Item
                                {...layout}
                                name="quoteValidityDateType"
                                rules={[
                                  {
                                    required: false,
                                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                  }
                                ]}
                              >
                                <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                  {Boolean(this.state.quoteValidityDateTypeSelectItems) && this.state.quoteValidityDateTypeSelectItems.length > 0 &&
                                    this.state.quoteValidityDateTypeSelectItems.map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                      </Panel>
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.ReceptionOfQuotation" defaultMessage="Reception of Quotation" />
                          </span>
                        }
                        key="4"
                      >
                        {
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="RequestForQuotation.receptionOptions" defaultMessage="Reception of Quotation" />}
                            name="receptionOptions"
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                          >
                            <Radio.Group
                              onChange={e => {
                                this.setState({
                                  receptionOptions: e.target.value
                                });
                              }}
                            >
                              {Boolean(this.state.receptionOptionsSelectItems) && this.state.receptionOptionsSelectItems.length > 0
                                && this.state.receptionOptionsSelectItems.map(i => (
                                  <Radio key={i.key} value={i.key}>
                                    {i.value}
                                  </Radio>
                                ))}
                            </Radio.Group>
                          </Form.Item>
                        }

                        {this.state.receptionOptions === "OTHER" && (
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="RequestForQuotation.receptionOtherValue" defaultMessage="Other Value" />}
                            name="receptionOtherValue"
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        )}
                      </Panel>
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.DeliveryPlace" defaultMessage="Delivery Place" />
                          </span>
                        }
                        key="5"
                      >
                        {
                          <Form.Item
                            {...layout}
                            label={this.state.companyName}
                            name="projectSite"
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                            valuePropName="checked"
                          >
                            <Checkbox
                              onChange={e => {
                                if (e.target.checked) {
                                  this.formRef.current.setFieldsValue({
                                    manufacturingPlace: null
                                  });
                                }
                              }}
                            ></Checkbox>
                          </Form.Item>
                        }
                        {
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="RequestForQuotation.manufacturingPlace" defaultMessage="Your manufacturing place" />}
                            name="manufacturingPlace"
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                            valuePropName="checked"
                          >
                            <Checkbox
                              onChange={e => {
                                if (e.target.checked) {
                                  this.formRef.current.setFieldsValue({
                                    projectSite: null
                                  });
                                }
                              }}
                            ></Checkbox>
                          </Form.Item>
                        }

                        {this.dynamicAttirbute(layout, "deliveryPlaces")}
                      </Panel>
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.Stock" defaultMessage="Stock" />
                          </span>
                        }
                        key="6"
                      >
                        {
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="RequestForQuotation.stockId" defaultMessage="Stock" />}
                            name="stockId"
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                          >
                            <Cascader changeOnSelect style={{ width: "100%" }} options={this.state.nodes} onChange={this.handleStockIdChange} />
                          </Form.Item>
                        }
                        {
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="RequestForQuotation.placeAddress" defaultMessage="Place Address" />}
                            name="placeAddress"
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                          >
                            <TextArea style={{ width: "100%" }} />
                          </Form.Item>
                        }
                      </Panel>
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.RequestedDocuments" defaultMessage="Requested documents" />
                          </span>
                        }
                        key="7"
                      >
                        {this.dynamicAttirbute(layout, "requestedDocuments")}
                      </Panel>
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.Attachments" defaultMessage="Attachments" />
                          </span>
                        }
                        key="8"
                      >
                        {
                          <Form.Item
                            {...layoutDimension}
                            label={<FormattedMessage id="RequestForQuotation.attachmentsDrawing" defaultMessage="Drawing" />}
                            name="attachmentsDrawing"
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                            valuePropName="checked"
                          >
                            <Checkbox></Checkbox>
                          </Form.Item>
                        }

                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="RequestForQuotation.attachmentsFileUrl" defaultMessage="Attachments File" />}
                          name="attachmentsFileUrl"
                          rules={[{ required: false, message: "Missing Load" }]}
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                        >
                          <Upload {...props} name="file" maxCount="1">
                            <AntButton icon={<UploadOutlined />}>
                              <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                            </AntButton>
                          </Upload>
                        </Form.Item>
                      </Panel>

                      {(this.state.showAllItems ||
                        (Boolean(this.formRef) &&
                          Boolean(this.formRef.current) &&
                          Boolean(this.formRef.current.getFieldValue("generalTerms")) &&
                          this.formRef.current.getFieldValue("generalTerms").filter(p => p.check === true).length > 0)) && (
                          <Panel
                            header={
                              <span style={{ fontWeight: "bold" }}>
                                <FormattedMessage id="RequestForQuotation.GeneralTermsAndConditions" defaultMessage="General Terms and Conditions" />
                              </span>
                            }
                            key="9"
                          >
                            <Form.Item
                              {...layout}
                              label={<FormattedMessage id="RequestForQuotation.generalTermsFileUrl" defaultMessage="File" />}
                              name="generalTermsFileUrl"
                              rules={[
                                { required: false, message: <FormattedMessage id="RequestForQuotation.missingLoad" defaultMessage="Missing Load" /> }
                              ]}
                              valuePropName="fileList"
                              getValueFromEvent={normFile}
                            >
                              <Upload {...props} name="file" maxCount="1">
                                <AntButton icon={<UploadOutlined />}>
                                  <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                                </AntButton>
                              </Upload>
                            </Form.Item>

                            {this.dynamicAttirbute(layout, "generalTerms")}
                          </Panel>
                        )}

                      {(this.state.showAllItems ||
                        (Boolean(this.formRef) &&
                          Boolean(this.formRef.current) &&
                          Boolean(this.formRef.current.getFieldValue("techRequirements")) &&
                          this.formRef.current.getFieldValue("techRequirements").filter(p => p.check === true).length > 0)) && (
                          <Panel
                            header={
                              <span style={{ fontWeight: "bold" }}>
                                <FormattedMessage id="RequestForQuotation.TechnicalRequirements" defaultMessage="Technical Requirements" />
                              </span>
                            }
                            key="10"
                          >
                            <Form.Item
                              {...layout}
                              label={<FormattedMessage id="RequestForQuotation.techRequirementsFileUrl" defaultMessage="File" />}
                              name={"techRequirementsFileUrl"}
                              rules={[
                                { required: false, message: <FormattedMessage id="RequestForQuotation.missingLoad" defaultMessage="Missing Load" /> }
                              ]}
                              valuePropName="fileList"
                              getValueFromEvent={normFile}
                            >
                              <Upload {...props} name="file" maxCount="1">
                                <AntButton icon={<UploadOutlined />}>
                                  <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                                </AntButton>
                              </Upload>
                            </Form.Item>

                            <Form.Item
                              {...layout}
                              label={<FormattedMessage id="RequestForQuotation.techRequirementsAddPr" defaultMessage="File" />}
                              name={"techRequirementsAddPr"}
                              rules={[
                                { required: false, message: <FormattedMessage id="RequestForQuotation.missingLoad" defaultMessage="Missing Load" /> }
                              ]}
                              valuePropName="fileList"
                              getValueFromEvent={normFile}
                            >
                              <Upload {...props} name="file" maxCount="1">
                                <AntButton icon={<UploadOutlined />}>
                                  <FormattedMessage id="RequestForQuotation.AddPurchaseRequisition" defaultMessage="Add Purchase Requisition" />
                                </AntButton>
                              </Upload>
                            </Form.Item>

                            {this.dynamicAttirbute(layout, "techRequirements")}
                          </Panel>
                        )}

                      {(this.state.showAllItems ||
                        (Boolean(this.formRef) &&
                          Boolean(this.formRef.current) &&
                          Boolean(this.formRef.current.getFieldValue("taxes")) &&
                          this.formRef.current.getFieldValue("taxes").filter(p => p.check === true).length > 0)) && (
                          <Panel
                            header={
                              <span style={{ fontWeight: "bold" }}>
                                <FormattedMessage id="RequestForQuotation.Taxes" defaultMessage="Taxes" />
                              </span>
                            }
                            key="11"
                          >
                            {this.dynamicAttirbute(layout, "taxes")}
                          </Panel>
                        )}

                      {(this.state.showAllItems ||
                        (Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("transportOptions")))) && (
                          <Panel
                            header={
                              <span style={{ fontWeight: "bold" }}>
                                <FormattedMessage id="RequestForQuotation.ModeOfTransport" defaultMessage="Mode of Transport" />
                              </span>
                            }
                            key="12"
                          >
                            {
                              <Form.Item
                                {...layout}
                                label={<FormattedMessage id="RequestForQuotation.transportOptions" defaultMessage="Mode of Transport" />}
                                name="transportOptions"
                                rules={[
                                  {
                                    required: false,
                                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                  }
                                ]}
                              >
                                <Radio.Group
                                  onChange={e => {
                                    this.setState({
                                      transportOptions: e.target.value
                                    });
                                  }}
                                >
                                  {Boolean(this.state.transportOptionsSelectItems) && this.state.transportOptionsSelectItems.length > 0
                                    && this.state.transportOptionsSelectItems.map(i => (
                                      <Radio key={i.key} value={i.key}>
                                        {i.value}
                                      </Radio>
                                    ))}
                                </Radio.Group>
                              </Form.Item>
                            }

                            {this.state.transportOptions === "OTHER" && (
                              <Form.Item
                                {...layout}
                                label={<FormattedMessage id="RequestForQuotation.transportOtherValue" defaultMessage="Other Value" />}
                                name="transportOtherValue"
                                rules={[
                                  {
                                    required: false,
                                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                  }
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            )}
                          </Panel>
                        )}

                      {(this.state.showAllItems ||
                        (Boolean(this.formRef) &&
                          Boolean(this.formRef.current) &&
                          Boolean(this.formRef.current.getFieldValue("warranties")) &&
                          this.formRef.current.getFieldValue("warranties").filter(p => p.check === true).length > 0)) && (
                          <Panel
                            header={
                              <span style={{ fontWeight: "bold" }}>
                                <FormattedMessage id="RequestForQuotation.Warranty" defaultMessage="Warranty" />
                              </span>
                            }
                            key="13"
                          >
                            {this.dynamicAttirbute(layout, "warranties")}
                          </Panel>
                        )}

                      {(this.state.showAllItems ||
                        (Boolean(this.formRef) &&
                          Boolean(this.formRef.current) &&
                          Boolean(this.formRef.current.getFieldValue("languages")) &&
                          this.formRef.current.getFieldValue("languages").filter(p => p.check === true).length > 0)) && (
                          <Panel
                            header={
                              <span style={{ fontWeight: "bold" }}>
                                <FormattedMessage id="RequestForQuotation.Language" defaultMessage="Language" />
                              </span>
                            }
                            key="14"
                          >
                            {this.dynamicAttirbute(layout, "languages")}
                          </Panel>
                        )}

                      {(this.state.showAllItems ||
                        (Boolean(this.formRef) &&
                          Boolean(this.formRef.current) &&
                          Boolean(this.formRef.current.getFieldValue("terminations")) &&
                          this.formRef.current.getFieldValue("terminations").filter(p => p.check === true).length > 0)) && (
                          <Panel
                            header={
                              <span style={{ fontWeight: "bold" }}>
                                <FormattedMessage id="RequestForQuotation.Termination" defaultMessage="Termination" />
                              </span>
                            }
                            key="15"
                          >
                            {this.dynamicAttirbute(layout, "terminations")}
                          </Panel>
                        )}

                      {(this.state.showAllItems ||
                        (Boolean(this.formRef) &&
                          Boolean(this.formRef.current) &&
                          Boolean(this.formRef.current.getFieldValue("confidentialities")) &&
                          this.formRef.current.getFieldValue("confidentialities").filter(p => p.check === true).length > 0)) && (
                          <Panel
                            header={
                              <span style={{ fontWeight: "bold" }}>
                                <FormattedMessage
                                  id="RequestForQuotation.ConfidentialityPrivacyAndPublicity"
                                  defaultMessage="Confidentiality, privacy and publicity"
                                />
                              </span>
                            }
                            key="16"
                          >
                            {this.dynamicAttirbute(layout, "confidentialities")}
                          </Panel>
                        )}

                      {(this.state.showAllItems ||
                        (Boolean(this.formRef) &&
                          Boolean(this.formRef.current) &&
                          Boolean(this.formRef.current.getFieldValue("inspectionAndAcceptances")) &&
                          this.formRef.current.getFieldValue("inspectionAndAcceptances").filter(p => p.check === true).length > 0)) && (
                          <Panel
                            header={
                              <span style={{ fontWeight: "bold" }}>
                                <FormattedMessage id="RequestForQuotation.InspectionAndAcceptance" defaultMessage="Inspection and acceptance" />
                              </span>
                            }
                            key="17"
                          >
                            {this.dynamicAttirbute(layout, "inspectionAndAcceptances")}
                          </Panel>
                        )}

                      {(this.state.showAllItems ||
                        (Boolean(this.formRef) &&
                          Boolean(this.formRef.current) &&
                          Boolean(this.formRef.current.getFieldValue("competentCourt")) &&
                          this.formRef.current.getFieldValue("competentCourt").filter(p => p.check === true).length > 0)) && (
                          <Panel
                            header={
                              <span style={{ fontWeight: "bold" }}>
                                <FormattedMessage
                                  id="RequestForQuotation.GoverningLawAndCompetentCourt"
                                  defaultMessage="Governing law and competent court"
                                />
                              </span>
                            }
                            key="18"
                          >
                            {this.dynamicAttirbute(layout, "competentCourt")}
                          </Panel>
                        )}

                      {(this.state.showAllItems ||
                        (Boolean(this.formRef) &&
                          Boolean(this.formRef.current) &&
                          Boolean(this.formRef.current.getFieldValue("liabilityInsurances")) &&
                          this.formRef.current.getFieldValue("liabilityInsurances").filter(p => p.check === true).length > 0)) && (
                          <Panel
                            header={
                              <span style={{ fontWeight: "bold" }}>
                                <FormattedMessage id="RequestForQuotation.LiabilityAndInsurance" defaultMessage="Liability and insurance" />
                              </span>
                            }
                            key="19"
                          >
                            {this.dynamicAttirbute(layout, "liabilityInsurances")}
                          </Panel>
                        )}

                      {(this.state.showAllItems ||
                        (Boolean(this.formRef) &&
                          Boolean(this.formRef.current) &&
                          Boolean(this.formRef.current.getFieldValue("intellectualProperties")) &&
                          this.formRef.current.getFieldValue("intellectualProperties").filter(p => p.check === true).length > 0)) && (
                          <Panel
                            header={
                              <span style={{ fontWeight: "bold" }}>
                                <FormattedMessage id="RequestForQuotation.IntellectualProperty" defaultMessage="Intellectual property" />
                              </span>
                            }
                            key="20"
                          >
                            {this.dynamicAttirbute(layout, "intellectualProperties")}
                          </Panel>
                        )}

                      {(this.state.showAllItems ||
                        (Boolean(this.formRef) &&
                          Boolean(this.formRef.current) &&
                          Boolean(this.formRef.current.getFieldValue("contractRecords")) &&
                          this.formRef.current.getFieldValue("contractRecords").filter(p => p.check === true).length > 0)) && (
                          <Panel
                            header={
                              <span style={{ fontWeight: "bold" }}>
                                <FormattedMessage id="RequestForQuotation.ContractRecords" defaultMessage="Contract records" />
                              </span>
                            }
                            key="21"
                          >
                            {this.dynamicAttirbute(layout, "contractRecords")}
                          </Panel>
                        )}

                      {(this.state.showAllItems ||
                        (Boolean(this.formRef) &&
                          Boolean(this.formRef.current) &&
                          Boolean(this.formRef.current.getFieldValue("informationPrivacies")) &&
                          this.formRef.current.getFieldValue("informationPrivacies").filter(p => p.check === true).length > 0)) && (
                          <Panel
                            header={
                              <span style={{ fontWeight: "bold" }}>
                                <FormattedMessage
                                  id="RequestForQuotation.FreedomOfInformationAndPrivacy"
                                  defaultMessage="Freedom of information and privacy"
                                />
                              </span>
                            }
                            key="22"
                          >
                            {this.dynamicAttirbute(layout, "informationPrivacies")}
                          </Panel>
                        )}

                      {(this.state.showAllItems ||
                        (Boolean(this.formRef) &&
                          Boolean(this.formRef.current) &&
                          Boolean(this.formRef.current.getFieldValue("miscellaneous")) &&
                          this.formRef.current.getFieldValue("miscellaneous").filter(p => p.check === true).length > 0)) && (
                          <Panel
                            header={
                              <span style={{ fontWeight: "bold" }}>
                                <FormattedMessage id="RequestForQuotation.Miscellaneous" defaultMessage="Miscellaneous" />
                              </span>
                            }
                            key="23"
                          >
                            {this.dynamicAttirbute(layout, "miscellaneous")}
                          </Panel>
                        )}

                      {Boolean(this.state.additionsList) && this.state.additionsList.length > 0
                        && this.state.additionsList.map((a, i) => {
                          return (
                            <Panel
                              header={<span style={{ fontWeight: "bold" }}>{a.description}</span>}
                              key={this.state.maxPanelKey + i}
                              extra={
                                <CloseOutlined
                                  onClick={() => {
                                    let { additionsList } = this.state;
                                    additionsList.splice(i, 1);
                                    this.setState({ additionsList: additionsList });
                                  }}
                                />
                              }
                            >
                              {/* {this.dynamicAttirbute(layout, "additionsList")} */}
                              <Row>
                                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                <Col span={2}>
                                  <Checkbox
                                    onChange={e => {
                                      this.setState({ ["additionsListItemCheck" + i]: e.target.checked });
                                    }}
                                    checked={this.state["additionsListItemCheck" + i]}
                                  />
                                </Col>
                                <Col span={6}>
                                  {
                                    <Form.Item>
                                      <FormattedMessage id="RequestForQuotation.Description" defaultMessage="Description">
                                        <Input
                                          value={this.state["additionsListItemDescription" + i]}
                                          onChange={e =>
                                            this.setState({
                                              ["additionsListItemDescription" + i]: e.target.value
                                            })
                                          }
                                        />
                                      </FormattedMessage>
                                    </Form.Item>
                                  }
                                </Col>
                                <Col span={1}> </Col>
                                <Col span={1}>
                                  <Tooltip title={<FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" />}>
                                    <AntButton
                                      type="dashed"
                                      onClick={() => {
                                        let { additionsList } = this.state;
                                        additionsList[i].rfqModelList.push({
                                          check: this.state["additionsListItemCheck" + i],
                                          description: this.state["additionsListItemDescription" + i]
                                        });
                                        this.setState({ ["additionsListItemDescription" + i]: "", additionsList: additionsList });
                                      }}
                                      block
                                      icon={<PlusOutlined />}
                                    ></AntButton>
                                  </Tooltip>
                                </Col>
                              </Row>
                              {Boolean(this.state.additionsList[i]) && Boolean(this.state.additionsList[i].rfqModelList) && this.state.additionsList[i].rfqModelList.length > 0 &&
                                this.state.additionsList[i].rfqModelList.map((item, index) => (
                                  <Form.Item {...layout1} style={{ marginBottom: "2px" }} label={index + 1} key={i + "-" + index}>
                                    <Row gutter={[8, 8]} className="mb-2">
                                      <Col span={3}>
                                        <Checkbox
                                          onChange={e => {
                                            let { additionsList } = this.state;
                                            item.check = e.target.checked;
                                            additionsList[i].rfqModelList[index] = item;
                                            this.setState({ additionsList: additionsList });
                                          }}
                                          checked={item.check}
                                        />
                                      </Col>
                                      <Col span={9}>{item.description}</Col>
                                      <Col span={1}> </Col>
                                      <Col span={2}>
                                        {
                                          <Tooltip title={<FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />}>
                                            <AntButton
                                              type="dashed"
                                              onClick={() => {
                                                let { additionsList } = this.state;
                                                additionsList[i].rfqModelList.splice(index, 1);
                                                this.setState({ additionsList: additionsList });
                                              }}
                                              block
                                              icon={<CloseOutlined />}
                                            ></AntButton>
                                          </Tooltip>
                                        }
                                      </Col>
                                    </Row>
                                  </Form.Item>
                                ))}
                            </Panel>
                          );
                        })}
                    </Collapse>
                    <br />
                    <Row className="mt-2">
                      <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                        <Tooltip title={<FormattedMessage id="RequestForQuotation.ShowItems" defaultMessage="Show Items" />}>
                          <AntButton
                            style={{ border: "0px" }}
                            hidden={this.state.showAllItems}
                            onClick={() => {
                              this.setState({
                                showAllItems: !this.state.showAllItems
                              });
                            }}
                            icon={<EyeTwoTone style={{ fontSize: "25px", cursor: "pointer" }} />}
                          ></AntButton>
                        </Tooltip>

                        <Tooltip title={<FormattedMessage id="RequestForQuotation.HideItems" defaultMessage="Hide Items" />}>
                          <AntButton
                            style={{ border: "0px" }}
                            hidden={!this.state.showAllItems}
                            onClick={() => {
                              this.setState({
                                showAllItems: !this.state.showAllItems
                              });
                            }}
                            icon={<EyeInvisibleTwoTone style={{ fontSize: "25px", cursor: "pointer" }} />}
                          ></AntButton>
                        </Tooltip>
                      </Col>
                      <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}></Col>
                    </Row>
                    <br />
                  </Card>


              </div>
            }
          />
       
        </div>
      </Badge.Ribbon>
    );
  }

  dynamicAttirbute(layout, name) {
    return (
      <Form.List name={name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item {...layout} label={index + 1}>
                <Form.Item
                  {...field}
                  style={{
                    display: "inline-block",
                    width: "calc(10%)"
                  }}
                  name={[field.name, "check"]}
                  valuePropName="checked"
                >
                  <Checkbox autoFocus checked={true} defaultChecked={true}></Checkbox>
                </Form.Item>

                <Form.Item
                  {...field}
                  style={{
                    display: "inline-block",
                    width: "calc(60%)"
                  }}
                  name={[field.name, "description"]}
                >
                  <Input></Input>
                </Form.Item>
                <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} />
              </Form.Item>
            ))}
            <Form.Item>
              <Row gutter={[16, 16]}>
                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}></Col>
                <Col md={4}></Col>
                <Col md={6}>
                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    <FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" />
                  </AntButton>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row gutter={[16, 16]}>
                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <Tooltip title={<FormattedMessage id="General.ShowItems" defaultMessage="Show Items" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      hidden={this.state.showAllSubItems === name ? true : false}
                      onClick={() => {
                        //let { newItemState } = this.prepareAttributes(this.state.selectedRow, false, false, name);

                        this.setState({
                          //  ...newItemState,
                          showAllSubItems: name
                        });
                      }}
                      icon={<EyeTwoTone style={{ fontSize: "25px", cursor: "pointer" }} />}
                    ></AntButton>
                  </Tooltip>

                  <Tooltip title={<FormattedMessage id="General.HideItems" defaultMessage="Hide Items" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      hidden={!(this.state.showAllSubItems === name ? true : false)}
                      onClick={() => {
                        // let { newItem, newItemState } = this.prepareAttributes(this.state.selectedRow, true, false, name);

                        this.setState({
                          //   ...newItemState,
                          showAllSubItems: !name
                        });
                      }}
                      icon={<EyeInvisibleTwoTone style={{ fontSize: "25px", cursor: "pointer" }} />}
                    ></AntButton>
                  </Tooltip>
                </Col>
                <Col md={10}></Col>
              </Row>
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ContractLog);
