import { Cascader, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleRequest } from "../ApiConnector";
import { error } from "../MessageHelper";
import PrepareFormItemProps from "./ElementUtils";
import { changeParentSelectValue } from "./redux/dependantSelectSlice";

const CascaderComponent = ({ field, layout, form }) => {
  const [list, setList] = useState(null);
  const [parentValue, setParentValue] = useState(null);
  const parentValues = useSelector(state => {
    return state.dependantSelect.value;
  });
  const pValue = parentValues[field.parentName + "ParentValue"];
  const dispatch = useDispatch();
  let formItemProps = PrepareFormItemProps(field, form);
  let otherProps = {};
  if (field.children) {
    otherProps.onChange = values => {
      dispatch(changeParentSelectValue({ key: field.inputName, value: Boolean(values) && values.length > 0 ? values[values.length - 1] : null }));
    };
  }
  if (formItemProps.hidden && otherProps.onChange) {
    otherProps.onChange(undefined);
  }
  const fillList = async () => {
    if (Boolean(form)) {
      form.current.setFieldsValue({ [field.inputName]: null });
    }
    let url = field.loadUrl; //"/api/countries/selectItems";
    var response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else setList(Boolean(response.data) ? response.data : []);
  };
  useEffect(() => {
    const getData = async p => {
      if (p !== parentValue) {
        await fillList(p);
      }
      setParentValue(p);
    };
    if (parentValue !== pValue) {
      getData(pValue).catch(console.error);
    }
  }, [parentValue, pValue]);

  return (
    <>
      <Form.Item {...layout} {...formItemProps}>
        <Cascader allowClear changeOnSelect style={{ width: "100%" }} options={list} {...otherProps} />
      </Form.Item>
    </>
  );
};

export default CascaderComponent;
