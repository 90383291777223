import React from "react";

import "./Item.css";

import { Checkbox, DatePicker, Input, InputNumber, Radio, Select, TimePicker, Button } from "antd";
import PhoneInput from "react-phone-input-2";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const Item = ({ id, dragOverlay }) => {
  const style = {
    cursor: dragOverlay ? "grabbing" : "grab",
    minWidth: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  };

  const iconByValue = value => {
    switch (value) {
      case "Text":
        return <Input readOnly placeholder="Text" style={{ width: "100%" }} />;
      case "Select":
        return <Select readOnly placeholder="Select" style={{ width: "100%" }}></Select>;
      case "Select Addable":
        return <Select readOnly placeholder="Select Addable" style={{ width: "100%" }}></Select>;
      case "Mail":
        return <Input readOnly placeholder="Mail" style={{ width: "100%" }} />;
      case "Phone":
        return <PhoneInput placeholder="Phone Number" style={{ width: "100%" }} />;
      case "Password":
        return <Input.Password readOnly placeholder="Password" style={{ width: "100%" }} />;
      case "Number":
        return <InputNumber readOnly placeholder="Text" style={{ width: "100%" }} />;
      case "Text Area":
        return <TextArea rows={1} readOnly placeholder="Text Area" style={{ width: "100%" }} />;
      case "Text Editor":
        return <TextArea rows={1} readOnly placeholder="Text Format" style={{ width: "100%" }} />;
      case "Checkbox":
        return <Checkbox>Checkbox</Checkbox>;
      case "Radio":
        return (
          <Radio.Group>
            <Radio value="radio"> Radio </Radio>
          </Radio.Group>
        );
      case "Date Picker":
        return <DatePicker readOnly placeholder="Date Picker" style={{ width: "100%" }} />;
      case "Datepicker With Time":
        return <DatePicker showTime readOnly placeholder="Date Picker with Time" style={{ width: "100%" }} />;
      case "Time Picker":
        return <TimePicker readOnly placeholder="Time Picker" style={{ width: "100%" }} />;
      case "Month Picker":
        return <DatePicker picker="month" readOnly placeholder="Month Picker" style={{ width: "100%" }} />;
      case "Year Picker":
        return <DatePicker picker="year" readOnly placeholder="Year Picker" style={{ width: "100%" }} />;
      case "Range Picker":
        return <RangePicker readOnly placeholder="Range Picker" style={{ width: "100%" }} />;
      case "Range Picker With Time":
        return <RangePicker showTime readOnly placeholder="Range Picker with Time" style={{ width: "100%" }} />;
      case "Button":
        return <Button style={{ width: "100%" }}>Button</Button>;
      case "Drawer Button":
        return (
          <Button type="primary" style={{ width: "100%" }}>
            Drawer
          </Button>
        );
      default:
        return null;
    }
  };

  return iconByValue(id);
};

export default Item;
