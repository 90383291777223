import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import {
  Input,
  Tree,
  Form,
  Modal as AntModal,
  Badge,
  Space,
  Row,
  Col,
  Drawer, Switch
} from "antd";
import { FcFolder, FcEditImage, FcFile } from "react-icons/fc";
import { Menu, Item, useContextMenu } from "react-contexify";
import { FaFileAudio, FaRegFile } from "react-icons/fa";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../../ApiConnector";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { Button } from "react-bootstrap";
import { FcLibrary } from "react-icons/fc";
import ProjectsTree from "../../elden/planning/ProjectsTree";
import { ProjectsTreeCompanyDrawer } from "./ProjectsTreeCompanyDrawer";

const Menu_ProjectName = "Menu_ProjectName";
const Menu_Transfer = "Menu_Transfer";
const Menu_Apt_Detail = "Menu_Apt_Detail";
const Menu_Project = "Menu_Project";
const Menu_ProjectChild = "Menu_ProjectChild";

const { show } = useContextMenu();

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

export class ProjectsTreeCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "ProjectsTreeCompany",
      controllerName: "projectsTreeCompany",
      id: null,
      projectName: "",
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      nodesShowHide: [],
      loadedKeys: [],
      searchValue: "",
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showServiceModal: false,
      refreshTree: true,
      openDrawer: false,
      openApartmentDrawer: false,
      openHousesDrawer: false,
      openFloorsDrawer: false,
      openQuantitiesDrawer: false,
      totalApartmentsModal: false,
    };
  }

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { nodes, expandedKeys, checkedNodesShowHide } = this.state;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "ProjectName"
    };

    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {

      console.log("checkedNodesShowHide", checkedNodesShowHide);

      let levels = [], disciplines = [], constructions = [], structures = [], subStructures = [];
      if (Boolean(checkedNodesShowHide) && checkedNodesShowHide.length > 0) {
        checkedNodesShowHide.forEach(element => {
          if (Boolean(element.label) && Boolean(element.value)) {
            if (element.label === "Levels") {
              levels.push(element.value);
            } else if (element.label === "Discipline") {
              disciplines.push(element.value);
            } else if (element.label === "Construction") {
              constructions.push(element.value);
            } else if (element.label === "Structure") {
              structures.push(element.value);
            } else if (element.label === "SubStructure") {
              subStructures.push(element.value);
            }
          }
        });
      }

      let newObj = {
        levels: levels,
        disciplines: disciplines,
        constructions: constructions,
        structures: structures,
        subStructures: subStructures,
      }

      response = await handleRequest("POST", "/api/" + this.state.controllerName + "/subitems/" + obj.id + "/" + obj.type, newObj);
    }
    if (response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;
      data.forEach(element => {
        if (element.projectName === "Apartment") {
          element.type = "ApartmentRoot";
        }
        element.icon =
          Boolean(element.type) && element.type === "ProjectName" ? (
            <FcFile />
          ) : element.type === "Project" ? (
            <FaRegFile />
          ) : element.type === "ProjectChild" ? (
            <FaFileAudio />
          ) : element.type === "ApartmentRoot" ? (
            <FcLibrary />
          ) : (
            <FcFolder />
          );
        element.title = element.projectName;
        element.key = Math.random();
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      this.setState({
        nodes: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  formRefCreateProject = React.createRef();

  componentDidMount = async () => {
    this.fillTree();
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "ProjectName";
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  onRightClick = (event, node) => {
    let parentNode = this.getParentNode(node);
    this.setState(
      {
        selectedNodeKey: node.key,
        selectedNode: node,
        parentNode: parentNode
      },
      this.displayMenu(event, Boolean(node) && Boolean(node.type) ? node.type : null)
    );
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  handleItemClick = ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;

    console.log("AAA", event.currentTarget.id);
    console.log(selectedNode);
    console.log(parentNode);
    console.log(key);

    // eslint-disable-next-line default-case
    switch (event.currentTarget.id) {
      case "create_projectName":
        this.setState(
          {
            showCreateProjectModal: true,
            parentId: selectedNode.id,
            projectName: "",
            type: "ProjectName"
          },
          () => {
            if (Boolean(this.formRefCreateProject) && Boolean(this.formRefCreateProject.current)) {
              this.formRefCreateProject.current.resetFields();
            }
          }
        );
        break;

      case "create_project":
        this.setState(
          {
            showCreateProjectModal: true,
            parentId: selectedNode.id,
            projectName: "",
            type: "Project"
          },
          () => {
            if (Boolean(this.formRefCreateProject) && Boolean(this.formRefCreateProject.current)) {
              this.formRefCreateProject.current.resetFields();
            }
          }
        );
        break;

      case "create_projectChild":
        this.setState(
          {
            showCreateProjectModal: true,
            parentId: selectedNode.id,
            projectName: "",
            type: "ProjectChild"
          },
          () => {
            if (Boolean(this.formRefCreateProject) && Boolean(this.formRefCreateProject.current)) {
              this.formRefCreateProject.current.resetFields();
            }
          }
        );
        break;

      case "elden_transfer":
        this.setState({
          modalEldenProjectTree: true,
          parentId: null,
          companyProjectId: selectedNode.id,
          projectName: "",
          type: ""
        });
        break;

      case "rename":
        this.setState({
          showCreateProjectModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        });
        break;

      case "delete":
        this.setState({
          showDeleteModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;

      case "apt_detail":
        this.setState({
          openDrawer: true,
          id: selectedNode.id,
          type: selectedNode.type,
          companyProjectId: selectedNode.companyProjectId
        });
        break;
      default:
    }
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id,
      type: this.state.type
    };

    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
      else this.fillTree();

      showSuccess();
      this.setState({
        id: null,
        showDeleteModal: false
      });
    }
  };

  displayMenu = (e, type) => {
    console.log("TTT", type);
    if (Boolean(type)) {
      if (type === "Project") {
        show(e, { id: Menu_Project });
      } else if (type === "ProjectChild") {
        show(e, { id: Menu_ProjectChild });
      } else if (type === "ProjectName") {
        show(e, { id: Menu_ProjectName });
      } else if (type === "ApartmentRoot") {
        show(e, { id: Menu_Apt_Detail });
      } else if (type === "CompanyProject") {
        show(e, { id: Menu_Transfer });
      }
    }
  };

  save = async values => {
    const { process, selectedNode } = this.state;
    console.log("selectedNode", selectedNode);
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId,
      type: Boolean(this.state.type) ? this.state.type : "ProjectName"
    };
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveProjectsTree", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateProjectsTree/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showCreateProjectModal: false
      });
    }
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      description: "",
      projectName: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      process: null,
      showOrderModal: false,
      showPasteModal: false,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showServiceModal: false
    });
  };

  expandSelectedNode = () => {
    let key = this.state.selectedNodeKey;
    let node = this.state.selectedNode;
    if (Boolean(key)) {
      let expandedKeys = Boolean(this.state.expandedKeys) ? this.state.expandedKeys : [];
      if (Boolean(expandedKeys.find(p => p === key))) {
        this.loadData(node, false);
      } else {
        this.setState({
          expandedKeys: [...expandedKeys, key]
        });
        this.loadData(node, false);
      }
    }
  };

  itemTemplate(item) {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <h5 className="mb-2">{item.title}</h5>
        </div>
      </div>
    );
  }

  onDragEnter = info => { };

  onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...this.state.nodes];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 &&
      // Has children
      info.node.props.expanded &&
      // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    this.setState({
      nodes: data
    });
  };

  closeFormModal = () => {
    this.setState({
      showFormModal: false
    });
  };

  closeTableModal = () => {
    this.setState({
      showTableModal: false
    });
  };

  closeInputFormatModal = () => {
    this.setState({
      showInputFormatModal: false
    });
  };

  closeTableFormatModal = () => {
    this.setState({
      showTableFormatModal: false
    });
  };

  okProjectTreeTemplate = async () => {
    const { targetKeys, companyProjectId } = this.state;
    if (Boolean(companyProjectId) && companyProjectId > 0) {
      if (targetKeys && targetKeys.length > 0) {
        targetKeys.sort((a, b) => {
          return a - b;
        });

        var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/eldenTransfer/" + targetKeys + "/" + companyProjectId);
        if (response.type === "ERROR") {
          error(response);
        } else {
          if (Boolean(this.state.parentNode)) {
            this.loadData(this.state.parentNode, false);
          } else {
            this.fillTree();
          }
          showSuccess();
          this.setState({ modalEldenProjectTree: false });
        }
      } else {
        showError("Not found.");
      }
    } else {
      showError("Company-Project Not found.");
    }
  };

  onTargetKeysCheck = async targetKeys => {
    console.log("targetKeys", targetKeys);
    this.setState({
      targetKeys: targetKeys
    });
  };

  showDrawer = () => {
    this.setState({
      openDrawer: true
    });
  };

  onDrawerClose = () => {
    this.setState({
      openDrawer: false
    });
  };

  showApartmentDrawer = () => {
    this.setState({
      openApartmentDrawer: true
    });
  };

  onApartmetDrawerClose = () => {
    this.setState({
      openApartmentDrawer: false
    });
  };

  showHousesDrawer = () => {
    this.setState({
      openHousesDrawer: true
    });
  };

  onHousesDrawerClose = () => {
    this.setState({
      openHousesDrawer: false
    });
  };

  showFloorsDrawer = () => {
    this.setState({
      openFloorsDrawer: true
    });
  };

  onFloorsDrawerClose = () => {
    this.setState({
      openFloorsDrawer: false
    });
  };

  showQuantitiesDrawer = () => {
    this.setState({
      openQuantitiesDrawer: true
    });
  };

  onQuantitiesDrawerClose = () => {
    this.setState({
      openQuantitiesDrawer: false
    });
  };

  onCheckShowHide = (checkedKeys, e) => {
    console.log("e", e);
    console.log("onCheck", checkedKeys);
    console.log("halfCheckedKeys", e.halfCheckedKeys);
    console.log(e);

    this.setState({
      checkedKeysShowHide: checkedKeys,
      halfCheckedKeysShowHide: e.halfCheckedKeys,
      checkedNodesShowHide: e.checkedNodes,
    });
  }

  okShowHideModal = async () => {
    const { checkedNodesShowHide } = this.state;
    if (Boolean(checkedNodesShowHide) && checkedNodesShowHide.length > 0) {
      this.setState({
        showModalChecked: false,
        checkedShowHide: true,
      }, () => {
        this.refreshData();
      });
    } else {
      showWarning("Please select item.");
    }
  }

  refreshData() {
    this.setState({
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      loadedKeys: [],
    }, () => {
      this.fillTree();
    });
  }

  fillShowHideList = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillShowHideList");
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        checkedKeysShowHide: [],
        halfCheckedKeysShowHide: [],
        checkedNodesShowHide: [],
        nodesShowHide: Boolean(response.data) ? response.data : []
      });
    }
  }

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <Badge.Ribbon
        text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage={"Projects "} />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <br /> <br />

          <Row gutter={[16, 16]}>
            <Col md={8}></Col>
            <Col md={2}>
              <Switch checked={this.state.checkedShowHide}
                checkedChildren={
                  <FormattedMessage id="ProjectsTreeCompany.Show" defaultMessage="Show" />
                }
                unCheckedChildren={
                  <FormattedMessage id="ProjectsTreeCompany.Hide" defaultMessage="Hide" />
                }
                onChange={(checked) => {
                  console.log("checked", checked);
                  if (checked) {
                    this.fillShowHideList();
                  } else {
                    this.setState({
                      checkedKeysShowHide: [],
                      halfCheckedKeysShowHide: [],
                      checkedNodesShowHide: [],
                    }, () => {
                      this.refreshData();
                    });
                  }
                  this.setState({
                    showModalChecked: checked,
                    checkedShowHide: checked,
                  });
                }}
              />
            </Col>
          </Row>

          {this.state.refreshTree && (
            <Tree
              style={{ marginTop: "10px", lineHeight: "30px" }}
              className="draggable-tree"
              showLine={{ showLeafIcon: false }}
              showIcon
              checkStrictly={true}
              loadedKeys={this.state.loadedKeys}
              onLoad={this.onLoadData}
              loadData={this.loadData}
              onRightClick={({ event, node }) => this.onRightClick(event, node)}
              onExpand={this.onExpand}
              expandedKeys={this.state.expandedKeys}
              autoExpandParent={false}
              treeData={this.state.nodes}
              blockNode={true}
              draggable
              checkable
              onCheck={this.onCheck}
              checkedKeys={this.state.checkedKeys}
              halfCheckedKeys={this.state.halfCheckedKeys}
              onDragEnter={this.onDragEnter}
              onDrop={this.onDrop}
            />
          )}
          {/* MENU */}
          {/* <Menu id={Menu_ProjectName}>
            <Item id="create_project" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="ProjectsTreeCompany.AddProject" defaultMessage="Add Project" />
                </span>
              </Space>
            </Item>
          </Menu> */}
          {/* <Menu id={Menu_Project}>
            <Item id="create_projectChild" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="ProjectsTreeCompany.CreateChild" defaultMessage="Create Child" />
                </span>
              </Space>
            </Item>
          </Menu>
          <Menu id={Menu_ProjectChild}>
            <Item id="create_projectChild" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="ProjectsTreeCompany.CreateProjectChild" defaultMessage="Create Project Child" />
                </span>
              </Space>
            </Item>
          </Menu>
          <Menu id={Menu_Transfer}>
            <Item id="elden_transfer" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="ProjectsTreeCompany.Transfer" defaultMessage="Transfer" />
                </span>
              </Space>
            </Item>
          </Menu> */}
          <Menu id={Menu_Apt_Detail}>
            <Item id="apt_detail" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="ProjectsTreeCompany.Details" defaultMessage="Details" />
                </span>
              </Space>
            </Item>
          </Menu>
          {/* TABLE MODAL */}
          <AntModal
            title={<FormattedMessage id="ProjectTree.Project" defaultMessage="Project" />}
            open={this.state.showCreateProjectModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showCreateProjectModal: false })}
            onOk={() => this.formRefCreateProject.current.submit()}
            width={1300}
            centered
          >
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRefCreateProject}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProjectPlanning.ProjectName" defaultMessage="Project Name" />}
                  name="projectName"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
            </Form>
          </AntModal>
          {/* DeleteModal */}
          <AntModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            open={this.state.showDeleteModal}
            onCancel={() => {
              this.setState({ showDeleteModal: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ showDeleteModal: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.delete}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Space>
            ]}
          >
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </AntModal>
          <AntModal
            centered
            width={1150}
            maskClosable={false}
            open={this.state.modalEldenProjectTree}
            onCancel={() => {
              this.setState({ modalEldenProjectTree: false });
            }}
            onOk={() => {
              this.okProjectTreeTemplate();
            }}
          >
            <div className="card card-custom" style={{ padding: "1rem", minHeight: "100%" }}>
              <ProjectsTree onTargetKeysCheck={this.onTargetKeysCheck}></ProjectsTree>
            </div>
          </AntModal>

          <Drawer title={<FormattedMessage id="ProjectsTreeCompany.Details" defaultMessage="Details" />}
            width={1200} closable={false} onClose={this.onDrawerClose} open={this.state.openDrawer}>
            <ProjectsTreeCompanyDrawer companyProjectId={this.state.companyProjectId} />
          </Drawer>

          <AntModal
            centered
            width={800}
            closable={false}
            open={this.state.showModalChecked}
            onCancel={() => {
              this.setState({
                showModalChecked: false,
                checkedShowHide: false,
              }, () => {
                this.refreshData();
              })
            }}
            onOk={() => {
              this.okShowHideModal();
            }}
          >
            <Tree
              style={{ width: "100%" }}
              checkable
              showLine={true}
              onCheck={this.onCheckShowHide}
              checkedKeys={this.state.checkedKeysShowHide}
              halfCheckedKeys={this.state.halfCheckedKeysShowHide}
              treeData={this.state.nodesShowHide}
            />
          </AntModal>
        </div>
      </Badge.Ribbon >
    );
  }
}
const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsTreeCompany);