import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Table, Badge, Space, Typography, Card } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import moment from "moment";
import SendIcon from "@material-ui/icons/Send";
import { FilterOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import DraggableModal from "../../../Components/DraggableModal";
import StockControlAssigment from "./StockControlAssigment";
import PlurkSvg from "./Icon/plurk.svg";
import Vimeo from "./Icon/vimeo.svg";

const { Text } = Typography;

export class StockControlApproval extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "StockControlApproval",
      controllerName: "stockControlApproval",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      tab: "projectTakeOff",

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);

    let url = "/api/" + this.state.controllerName + "/paging";
    if (this.state.tab === "purchaseRequisition") {
      url = "/api/" + this.state.controllerName + "/pagingRequisition";
    }
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let sendAction = {
      name: "Approve",
      icon: <SendIcon fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.sendAllModal.bind(this)
    };
    console.log("record", record);
    let actionList = [];
    if (Boolean(record.status)) {
      actionList.push(sendAction);
    }

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  sendAllModal = row => {
    this.setState({
      modalSendAll: true,
      id: row.id,
      selectedRow: row
    });
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  desicionModal = async row => {
    if (Boolean(row.id) && row.id > 0) {
      this.setState({
        modalDesicionShow: true,
        selectedRow: row,
        random: Math.random()
      });
    } else {
      showError("Not found.");
    }
  };

  setQuantityToBeProcured(record) {
    let quantity = 0;
    if (Boolean(record.quantity)) {
      quantity = record.quantity;
    }

    let reservedQuantity = 0;
    if (Boolean(record.reservedQuantity)) {
      reservedQuantity = record.reservedQuantity;
    }

    let toBeReleasedQuantity = 0;
    if (Boolean(record.toBeReleasedQuantity)) {
      toBeReleasedQuantity = record.toBeReleasedQuantity;
    }

    let requested = 0;
    if (Boolean(record.requested)) {
      requested = record.requested;
    }

    let result = quantity - (reservedQuantity + toBeReleasedQuantity + requested);
    if (result > 0) {
      return result;
    }
    return 0;
  }

  sendToRFQ = async () => {
    let list = [];
    const { id, selectedRow } = this.state;
    if (Boolean(id) && Boolean(selectedRow)) {
      let result = this.setQuantityToBeProcured(selectedRow);
      if (Boolean(result) && result > 0) {
        list.push({ id: id, quantityToBeProcured: result });
      }

      if (Boolean(list) && list.length > 0) {
        let url = "/api/" + this.state.controllerName + "/sendToRFQ";
        if (this.state.tab === "purchaseRequisition") {
          url = "/api/" + this.state.controllerName + "/sendToRFQRequisition";
        }
        var response = await handleRequest("POST", url, list);
        if (response.type === "ERROR") {
          error(response);
        } else {
          const { pagination } = this.state;
          this.restartTable({ pagination });
          showSuccess();
          this.setState({
            id: null,
            modalSendAll: false
          });
        }
      } else {
        showWarning("No item found to send.");
      }
    } else {
      showWarning("Not found.");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="StockControlApproval.Project" defaultMessage="Project" />,
        key: "companyProjectId",
        render: record => {
          return record.companyProjectName;
        },
        ...getColumnFilter({
          inputName: "project",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="StockControlApproval.Date" defaultMessage="Date" />,
        key: "sendedDate",
        render: record => {
          return Boolean(record.sendedDate) ? moment(record.sendedDate).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "sendedDate",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="StockControlApproval.item" defaultMessage="Item" />,
        key: "item",
        render: record => {
          return record.itemDescriptionRename;
        },
        ...getColumnFilter({
          inputName: "item",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="StockControlApproval.dimension" defaultMessage="Dimension" />,
        key: "allDimensions",
        render: record => {
          return record.allDimensions;
        },
        ...getColumnFilter({
          inputName: "dimension",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="StockControlApproval.quantity" defaultMessage="Quantity" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        },
        ...getColumnFilter({
          inputName: "quantity",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="StockControlApproval.availableStock" defaultMessage="Available Stock" />,
        key: "availableStock",
        render: record => {
          return (
            <Text style={{ cursor: "pointer" }} onClick={e => this.desicionModal(record)} type="success">
              {record.availableStock}
            </Text>
          );
        },
        ...getColumnFilter({
          inputName: "availableStock",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="StockControlApproval.quantityToBeProcured" defaultMessage="Quantity To Be Procured" />,
        key: "quantityToBeProcured",
        render: record => {
          let result = this.setQuantityToBeProcured(record);

          if (result > 0) {
            return result.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");
          }
          return 0;
        },
        ...getColumnFilter({
          inputName: "quantityToBeProcured",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="StockControlApproval.Remarks" defaultMessage="Remarks" />,
        key: "remarks",
        render: record => {
          return record.remarks;
        },
        ...getColumnFilter({
          inputName: "remarks",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="StockControlApproval.Updates" defaultMessage="Updates" />,
        key: "lastUpdated",
        render: record => {
          return Boolean(record.lastUpdated) ? moment(record.lastUpdated).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "lastUpdated",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
    ];

    const tabTitleComponent = (title, key) => (
      <div className={"card-title-component"}>
        <img src={key === "projectTakeOff" ? PlurkSvg : Vimeo} alt="Icon" style={{ width: "20px", height: "20px", marginRight: "10px" }} color={key === this.state.tab ? "#469CF0" : "#A2A5B5"} />
        <span style={{ color: key === this.state.tab ? "#469CF0" : "#A2A5B5" }}>
          {title}
        </span>
      </div>
    );

    const tabList = [
      {
        key: "projectTakeOff",
        tab: tabTitleComponent(
          "Project Take Off",
          "projectTakeOff"
        ),
      },
      {
        key: "purchaseRequisition",
        tab: tabTitleComponent("Purchase Requisition", "purchaseRequisition"),
      },
    ];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="stockControlApproval.pageTitle" defaultMessage="Stock Control Approval" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Card
            bodyStyle={{ padding: 0 }}
            tabList={tabList}
            style={{ marginTop: 32, marginBottom: 8 }}
            onTabChange={value => {
              const { pagination } = this.state;
              let _pagination = {
                ...pagination,
                current: 1,
              };
              this.setState({
                tab: value,
                selectedRowKeys: [],
                pagination: _pagination
              }, () => {
                const { pagination } = this.state;
                this.restartTable({ pagination });
              });
            }}
          />

          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>
        </div>

        <DraggableModal
          title={<FormattedMessage id="StockControl.Assignment" defaultMessage="Assignment" />}
          closable="false"
          open={this.state.modalDesicionShow}
          onOk={() => this.sendAll()}
          onCancel={() => this.setState({ modalDesicionShow: false })}
          content={<StockControlAssigment random={this.state.random} selectedRow={this.state.selectedRow} />}
          width={800}
          centered={true}
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalDesicionShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            </Space>
          ]}
        />

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="StockControl.SendToRFQ" defaultMessage="Send To Request For Quotation" />
              </span>
            </span>
          }
          open={this.state.modalSendAll}
          onOk={() => this.sendToRFQ()}
          onCancel={() => this.setState({ modalSendAll: false })}
          content={
            <FormattedMessage
              id="GeneralMessage.SelectedProjectItemWillSendedToRequestForQuotationAreYouSure"
              defaultMessage="Selected Project Item will sended to Request For Quotation. Are you sure?"
            />
          }
        />
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(StockControlApproval);
