import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Upload } from "antd";
import React from "react";
import { API_BASE_ROOT } from "../ApiConnector";
import PrepareFormItemProps from "./ElementUtils";

const FileUploadComponent = ({ field, layout }) => {
  // const [list, setList] = useState([]);
  // const [filePaths, setfilePaths] = useState(null);
  const props = {
    action: `${API_BASE_ROOT}/File/upload`,
    listType: "picture"
  };
  const normalizingFileUpload = event => {
    if (Array.isArray(event)) {
      return event;
    }
    return event && event.fileList;
  };
  const otherProps = {};
  if (field.max) {
    otherProps.maxCount = field.max;
  }

  return (
    <>
      <Form.Item {...layout} {...PrepareFormItemProps(field)} valuePropName="fileList" getValueFromEvent={normalizingFileUpload}>
        <Upload {...props} {...otherProps} multiple>
          <Button icon={<UploadOutlined />} style={{ width: "100%", marginTop: "0.2rem" }}>
            <span>Upload</span>
          </Button>
        </Upload>
      </Form.Item>
    </>
  );
};

export default FileUploadComponent;
