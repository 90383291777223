import CreateIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Avatar, Button as AntButton, Col, Form, Row } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import DataTableComponent from "../../Components/DataTableComponent";
import ElementComponent from "../../Components/Element";
import FilePage from "../../Components/FilePage";
import { error, showSuccess } from "../../MessageHelper";
import ActionMenu from "../CustomMenu/ActionMenu";

export class DynamicPage extends Component {
  constructor(props) {
    super(props);
    console.log("elements", FilePage);
    this.state = {
      modalDeleteShow: false,
      hiddenInputs: true,

      hideUpdate: true,
      hideSave: false,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"]
      },
      loading: false
    };
  }
  formRef = React.createRef();

  getFormElements = async page => {
    let url = "/api/pageInputCompany/inputs/" + page;

    const response = await handleRequest("GET", url);

    if (response.type === "ERROR") {
      error(response);
    } else return response.data;
  };
  componentDidMount = async () => {
    switch (this.props.match.params.name) {
      case "file":
        this.state.formElements = await this.getFormElements(10);
        break;
      case "checklist":
        this.state.formElements = await this.getFormElements(5);
        break;
      case "a":
        this.state.formElements = await this.getFormElements(this.props.match.params.name);
        break;
      default:
        this.state.inputs = FilePage.inputs;
        this.state.dataTable = FilePage.dataTable;
    }
    this.resetInputs();
  };

  cancel = () => {
    this.setState({
      hiddenInputs: true,
      hideUpdate: true,
      hideSave: false
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      hideUpdate: true,
      hideSave: false
    });
  };
  handleChange = field => {
    let obj = {};
    obj[field.inputValue] = field.value;
    this.setState(obj);
  };

  editRow = row => {
    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.companyId
    };

    var response = await handleRequest("DELETE", "/api/companies/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
      this.setState({
        modalDeleteShow: false
      });
    }
  };

  save = async values => {
    console.log("values", values);
    console.log("valuesJSON", JSON.stringify(values));
    console.log(this.state);
  };

  onClickCreateNew = async () => {
    this.resetInputs();
    if (this.state.hiddenInputs) {
      this.setState({
        hiddenInputs: false,
        hiddenSave: false,
        hiddenUpdate: true
      });
    } else {
      this.setState({
        hiddenInputs: true,
        hiddenSave: true,
        hiddenUpdate: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/personnelLog/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", this.formRef.current);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        title: "Actions",
        key: "Actions",
        render: record => {
          return this.actionTemplate(record);
        }
      },

      {
        title: "S/N",
        key: "index",
        render: (value, row, index) => {
          return index + 1;
        }
      },

      {
        title: "Logo",
        key: "companyLogoPath",
        render: (value, row, index) => {
          if (Boolean(row.companyLogoPath)) return <Avatar shape="square" size={64} src={row.companyLogoPath} />;
          else return "";
        }
      },

      {
        title: "Company Short Name",
        key: "companyShortName",
        dataIndex: "companyShortName"
      },

      {
        title: "Company Name",
        key: "companyName",
        dataIndex: "companyName"
      },
      {
        title: "Domain",
        key: "domain",
        dataIndex: "domain"
      },
      {
        title: "Address",
        key: "address",
        dataIndex: "address"
      },
      {
        title: "Country",
        key: "countryId",
        dataIndex: "countryName"
      },
      {
        title: "Phone",
        key: "phone",
        dataIndex: "phone"
      },
      {
        title: "Fax",
        key: "fax",
        dataIndex: "fax"
      },
      {
        title: "Email",
        key: "email",
        dataIndex: "email"
      }
    ];
    const action = (value, row, index) => {
      return (
        <React.Fragment>
          <ActionMenu row={row} onDeleteRow={this.deleteModal} onEditRow={this.editRow} />
        </React.Fragment>
      );
    };
    return (
      <div hidden={this.state.hideInputs} className="card card-custom" style={{ padding: "2rem" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row>
            <Col xs={20}></Col>
            <Col md={1}>{/* {excelExport} */}</Col>
            <Col md={1}>{/* <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader> */}</Col>
            <Col xs={2}>
              <AntButton
                hidden={!this.state.hiddenInputs}
                style={{ border: "0px" }}
                onClick={() => this.onClickCreateNew()}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                hidden={this.state.hiddenInputs}
                style={{ border: "0px" }}
                onClick={() => this.onClickCreateNew()}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>
          <div hidden={this.state.hiddenInputs}>
            {this.state.inputs ? this.state.inputs.map((field, i) => <ElementComponent key={i} field={field} layout={layout} />) : null}
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={8}>
                  <Col span={8}></Col>

                  <Col span={8}>
                    {
                      <Button id="OrganizationalLevelSaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    }
                  </Col>
                </Row>
              }
            </div>

            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={8}>
                  <Col span={8}></Col>

                  <Col span={4}>
                    <Button id="OrganizationalLevelCancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col span={4}>
                    <Button id="OrganizationalLevelUpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          {this.state.dataTable ? <DataTableComponent key="dataTable" field={this.state.dataTable} editRow={this.editRow.bind(this)} /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicPage);
