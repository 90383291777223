import { EditOutlined } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Button as AntButton, Col, Form, Row } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import DataTableComponent2 from "../../Components/DataTableComponent2";
import ElementsProvider from "../../Components/ElementsProvider";
import { GetDynamicFormItems, PrepareRowForEdit, PrepareRowForSave } from "../../Components/ElementUtils";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { ExcelReader } from "../excel/ExcelReader";

export class Discipline extends Component {
  constructor() {
    super();
    this.state = {
      formName: "discipline",
      controllerName: "discipline",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      tableList: [],
      restartTable: false,
      pageFormData: null,
      excelData: [],
      createWhereParams: null,
      refreshForm: false
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    let data = await GetDynamicFormItems(this.state.formName);
    this.setState({
      pageFormData: data
    });

    this.fillComboboxes();
  };
  componentDidUpdate = async nextProps => { };
  fillComboboxes = async () => { };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null
    });
  };

  setData = data => {
    this.setState({
      tableList: data
    });
  };

  edit = row => {
    let handledValues = {
      ...row
    };
    handledValues = PrepareRowForEdit(handledValues, this.state.pageFormData.pageFormInputCompanyList);
    this.formRef.current.setFieldsValue({
      ...handledValues
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async () => {
    this.setState({ restartTable: !this.state.restartTable });
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    let newItem = {
      id: this.state.id,
      ...values
    };
    newItem = PrepareRowForSave(newItem, this.state.pageFormData.pageFormInputCompanyList);
    if (!Boolean(newItem.id)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      this.restartTable();
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          this.state.pageFormData.pageFormInputCompanyList.forEach(input => {
            obj[input.inputName] = element[input.label];
          });
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            this.restartTable();
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  action = record => {
    return (
        <React.Fragment>
          <DynamicActionMenu
              row={record}
              actionList={[
                {
                  name: "Edit",
                  icon: <EditOutlined fontSize="small" color="primary" />,
                  actionClick: this.edit.bind(this)
                },
                {
                  name: "Delete",
                  icon: <DeleteIcon fontSize="small" color="error" />,
                  actionClick: this.deleteModal.bind(this)
                }
              ]}
          />
        </React.Fragment>
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    const excelExport = (
        <>
          {/* {this.state.exportClick
              ? this.setState({
                exportClick: false
              })
              : ""}
          {this.state.exportClick ? (
              <ExcelFile filename={this.state.formName} hideElement={true}>
                <ExcelSheet name="Discipline" data={this.state.tableList}>
                  <ExcelColumn label="S/N" value="index" />
                  {Boolean(this.state.pageFormData) &&
                      this.state.pageFormData.pageFormInputCompanyList.map(item => {
                        return <ExcelColumn key={item.inputName} label={item.label} value={Boolean(item.tableColumnValue) ? item.tableColumnValue : item.inputName} />;
                      })}
                </ExcelSheet>
              </ExcelFile>
          ) : (
              <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
          )} */}
        </>
    );

    return (
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Row gutter={[16, 16]}>
            <Col md={21}>
            <span className="h3">
              <FormattedMessage
                  id={this.state.controllerName + ".pageTitle"}
                  defaultMessage={Boolean(this.state.pageFormData) ? this.state.pageFormData.pageForm.title : ""}
              />
            </span>
            </Col>
            <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>
          <div hidden={this.state.hideInputs}>
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
              {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
                  <ElementsProvider
                      inputs={this.state.pageFormData.pageFormInputCompanyList}
                      layout={layout}
                      form={this.formRef}
                      refresh={this.state.refreshForm}
                  />
              ) : null}
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </Form>
          </div>
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
                <DataTableComponent2
                    key="dataTable"
                    field={this.state.pageFormData.pageForm}
                    inputs={this.state.pageFormData.pageFormInputCompanyList}
                    actions={this.action}
                    editRow={this.edit.bind(this)}
                    restartTable={this.state.restartTable}
                    createWhereParams={this.state.createWhereParams}
                    setData={this.setData.bind(this)}
                />
            ) : null}
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Discipline);
