import { FilterOutlined, } from "@ant-design/icons";
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Table,
  Cascader,
  Badge,
  Tooltip,
  Row,
  Col,
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../../ApiConnector";
import { error, showError, showSuccess, showWarning } from "../../../MessageHelper";
import "../../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";
import { Button } from "react-bootstrap";

const { Option } = Select;

//# Columns-Resize
const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate"
      ? form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? moment(record[dataIndex], "DD-MM-YYYY") : ""
      })
      : form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
      });
  };

  const toggleSave = async () => {
    try {
      if (dataIndex === "planedDeliveryDate") {
        const values = await form.validateFields();
        values.planedDeliveryDate = Boolean(values.planedDeliveryDate) ? moment(values.planedDeliveryDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else if (dataIndex === "paymentDate") {
        const values = await form.validateFields();
        values.paymentDate = Boolean(values.paymentDate) ? moment(values.paymentDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record }, { ...values });
      }
    } catch (errInfo) { }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
      >
        {dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate" ? (
          <DatePicker showTime ref={inputRef} onBlur={toggleSave} format={"DD-MM-YYYY HH:mm"} />
        ) : dataIndex === "unit" ? (
          <Cascader style={{ width: "100%" }} options={record.unitSelectItems} ref={inputRef} onChange={toggleSave} onBlur={toggleSave} />
        ) : dataIndex === "currency" ? (
          <Select
            allowClear
            ref={inputRef} onChange={toggleSave} onBlur={toggleSave} style={{ width: "150px" }} showSearch optionFilterProp="children" >
            {Boolean(record.currencySelectItems) &&
              record.currencySelectItems.length > 0 &&
              record.currencySelectItems.map(i => (
                <Option key={i.key} value={i.key}>
                  {i.value}
                </Option>
              ))}
          </Select>
        ) :
          (
            <InputNumber parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")}
              min={0} ref={inputRef} onPressEnter={toggleSave} onBlur={toggleSave} />
          )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (Boolean(node)) {
      var lineage = [];
      if (Boolean(node.lineage) && node.lineage.length > 0) {
        node.lineage.forEach(element => {
          if (!lineage.includes(element)) {
            lineage.push(element);
          }
        });
      }

      dataList.push({
        key: node.value,
        lineage: node.lineage,
        title: node.value
      });
    }
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class RequestForQuotationAddMoreItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      controllerName: "requestForQuotation",

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  }

  fillComboboxes = async () => {

  }

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/getRfqAddMoreItems";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        addMoreRFQList: [],
        modalAddMorRFQShow: false,
        addMoreRFQShow: true,
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: "",
        selectedRowKeys: [],
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        let selectedRowKeys = [];
        list.forEach((element, index) => {
          element.index = index + 1;
          if (element.selected) {
            selectedRowKeys.push(element.rfqOurExpectedPriceId);
          }
        });
        this.setState({
          loading: false,
          addMoreRFQList: response.data.data,
          modalAddMorRFQShow: false,
          addMoreRFQShow: true,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder,
          selectedRowKeys: selectedRowKeys,
        });
      } else {
        this.setState({
          loading: false,
          addMoreRFQList: [],
          modalAddMorRFQShow: false,
          addMoreRFQShow: true,
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: "",
          selectedRowKeys: [],
        });
      }
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: this.props.rfqId,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }


  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search and click enter`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
      </div>
    ),
    filterIcon: filtered => <FilterOutlined style={{ color: filtered ? "#1890ff" : "#bfbfbf" }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handlePriceListQuantityOurExpectedPrice = async (row, value) => {
    if (Boolean(value)) {
      const maxOurExpectedPrice = this.state.maxOurExpectedPrice + 1;
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);

      console.log("item", item);
      console.log("value", value);

      if (Boolean(value) && Boolean(item)) {
        if (item.quantity === value.quantity) return;
        var newQuantity = item.quantity - value.quantity;
        if (Boolean(newQuantity) && newQuantity > 0) {
          row.quantity = newQuantity;
          row.oldQuantity = 0;
          row.key = maxOurExpectedPrice;
          var newObj = row;
          newObj.selected = false;
          newObj.sourceKey = item.key;
          newObj.unit = item.defaultUnit;
          newObj.unitId = item.defaultUnitId;
          newData.push(newObj);
        }
        item.oldQuantity = item.quantity;
        item.quantity = value.quantity;
        item.price = item.quantity * item.unitPrice;
        item.unit = item.defaultUnit;
        item.unitId = item.defaultUnitId;
        this.setState({
          ourExpectedPrices: newData,
          maxOurExpectedPrice: maxOurExpectedPrice
        });
        console.log("newData", newData);

        if (!(Boolean(newQuantity) && newQuantity > 0)) {
          this.setState({
            selectedQuantityKey: item.key,
            quantityDescription: item.quantityDescription,
            showQuantityDescriptionModal: true
          });
        }
      }
    }
  };

  save = async () => {
    if (Boolean(this.props.rfqId) && this.props.rfqId > 0) {
      let newObj = {
        requestForQuotationId: this.props.rfqId,
        rfqOurExpectedPriceIds: this.state.selectedRowKeys
      }
      var response = await handleRequest(
        "POST",
        "/api/" + this.state.controllerName + "/saveRfqAddMoreItems",
        newObj
      );
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.props.close();
      }
    } else {
      showWarning("RFQ not found.");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      },

      header: {
        cell: ResizableTitle
      }
    };

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys: selectedRowKeys,
      onChange: this.onSelectChange
    };

    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        fixed: "left",
        width: 50,
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotationAddMoreItems.RFQNo" defaultMessage="RFQ No" />,
        fixed: "left",
        width: 100,
        key: "rfqNo",
        render: record => {
          return record.rfqNo;
        },
        ...this.getColumnSearchProps("rfqNo"),
        sorter: (a, b) => Boolean(a.rfqNo) && Boolean(b.rfqNo) && (a.rfqNo + "").localeCompare(b.rfqNo + "")
      },
      {
        title: <FormattedMessage id="General.RevNo" defaultMessage="Rev No" />,
        fixed: "left",
        width: 50,
        key: "revNo",
        render: record => {
          return record.revNo;
        },
        ...this.getColumnSearchProps("revNo"),
        sorter: (a, b) => Boolean(a.revNo) && Boolean(b.revNo) && (a.revNo + "").localeCompare(b.revNo + "")
      },
      {
        title: <FormattedMessage id="General.Projects" defaultMessage="Projects" />,
        fixed: "left",
        width: 100,
        key: "projectName",
        render: record => {
          return <Tooltip
            color={"white"}
            title={<div className="w-100" style={{ color: "black" }}>
              {record.projectName}
            </div>}
          >
            <div style={{ color: "black", whiteSpace: "nowrap" }}>
              {Boolean(record.projectName) && record.projectName.length > 10 ? record.projectName.substring(0, 10) + "..." : record.projectName}
            </div>
          </Tooltip>
        },
        ...this.getColumnSearchProps("projectName"),
        sorter: (a, b) => Boolean(a.projectName) && Boolean(b.projectName) && (a.projectName + "").localeCompare(b.projectName + "")
      },
      {
        title: <FormattedMessage id="RequestForQuotationAddMoreItems.ItemGroup" defaultMessage="Item Group" />,
        key: "itemGroupName",
        width: 100,
        render: record => {
          return record.itemGroupName;
        },
        ...this.getColumnSearchProps("itemGroupName"),
        sorter: (a, b) => Boolean(a.itemGroupName) && Boolean(b.itemGroupName) && (a.itemGroupName + "").localeCompare(b.itemGroupName + "")
      },
      {
        title: <FormattedMessage id="RequestForQuotationAddMoreItems.Item" defaultMessage="Item" />,
        key: "projectTakeOffName",
        width: 100,
        render: record => {
          return <Tooltip
            color={"white"}
            title={<div className="w-100" style={{ color: "black" }}>
              {record.projectTakeOffName}
            </div>}
          >
            <div style={{ color: "black", whiteSpace: "nowrap" }}>
              {Boolean(record.projectTakeOffName) && record.projectTakeOffName.length > 10 ? record.projectTakeOffName.substring(0, 10) + "..." : record.projectTakeOffName}
            </div>
          </Tooltip>
        },
        ...this.getColumnSearchProps("projectTakeOffName"),
        sorter: (a, b) => Boolean(a.projectTakeOffName) && Boolean(b.projectTakeOffName) && (a.projectTakeOffName + "").localeCompare(b.projectTakeOffName + "")
      },
      {
        title: <FormattedMessage id="RequestForQuotationAddMoreItems.Item" defaultMessage="Dimension" />,
        key: "dimensionName",
        width: 100,
        render: record => {
          return <Tooltip
            color={"white"}
            title={<div className="w-100" style={{ color: "black" }}>
              {record.dimensionName}
            </div>}
          >
            <div style={{ color: "black", whiteSpace: "nowrap" }}>
              {Boolean(record.dimensionName) && record.dimensionName.length > 10 ? record.dimensionName.substring(0, 10) + "..." : record.dimensionName}
            </div>
          </Tooltip>
        },
        ...this.getColumnSearchProps("dimensionName"),
        sorter: (a, b) => Boolean(a.dimensionName) && Boolean(b.dimensionName) && (a.dimensionName + "").localeCompare(b.dimensionName + "")
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Quantity" defaultMessage="Quantity" />,
        key: "quantity",
        width: 100,
        render: record => {
          let quantity = record.quantity;
          if (!Boolean(quantity)) {
            quantity = record.quantity;
          }
          return Boolean(record.oldQuantity) && record.oldQuantity > 0 ? (
            <Badge showZero offset={[25, -15]} overflowCount={record.oldQuantity + 1} count={record.oldQuantity}>
              {quantity}
            </Badge>
          ) : (
            quantity
          );
        },
        ...this.getColumnSearchProps("quantity"),
        sorter: (a, b) => Boolean(a.quantity) && Boolean(b.quantity) && (a.quantity + "").localeCompare(b.quantity + "")
      },
      {
        title: <FormattedMessage id="General.Vendors" defaultMessage="Vendors" />,
        fixed: "left",
        width: 100,
        key: "vendorName",
        render: record => {
          return <Tooltip
            color={"white"}
            title={<div className="w-100" style={{ color: "black" }}>
              {record.vendorName}
            </div>}
          >
            <div style={{ color: "black", whiteSpace: "nowrap" }}>
              {Boolean(record.vendorName) && record.vendorName.length > 10 ? record.vendorName.substring(0, 10) + "..." : record.vendorName}
            </div>
          </Tooltip>
        },
        ...this.getColumnSearchProps("vendorName"),
        sorter: (a, b) => Boolean(a.vendorName) && Boolean(b.vendorName) && (a.vendorName + "").localeCompare(b.vendorName + "")
      }
    ];

    return (
      <>
        <Table
          bordered
          components={components}
          rowSelection={rowSelection}
          rowClassName={() => "editable-row"}
          rowKey={record => record.rfqOurExpectedPriceId}
          scroll={{ x: 900, scrollToFirstRowOnChange: true }}
          columns={columns}
          dataSource={this.state.addMoreRFQList}
          loading={this.state.loading}
          pagination={false}
        />

        <br />
        <Row gutter={[16, 16]}>
          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Button id="SaveButton" onClick={this.save} style={{ width: "100%" }} variant="success">
              <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
            </Button>
          </Col>
          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RequestForQuotationAddMoreItems);
