import "antd/dist/antd.css";
import moment from "moment";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { handleRequest } from "../../../ApiConnector";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { Form, Table, Tooltip, Drawer, Input, Cascader, InputNumber, DatePicker, Row, Col } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import { Select } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    dataIndex === "plannedDeliveryDate" || dataIndex === "paymentDate"
      ? form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? moment(record[dataIndex], "DD-MM-YYYY") : ""
      })
      : form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
      });
  };

  const toggleSave = async () => {
    try {
      if (dataIndex === "plannedDeliveryDate") {
        const values = await form.validateFields();
        values.plannedDeliveryDate = Boolean(values.plannedDeliveryDate) ? moment(values.plannedDeliveryDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else if (dataIndex === "paymentDate") {
        const values = await form.validateFields();
        values.paymentDate = Boolean(values.paymentDate) ? moment(values.paymentDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record }, { ...values });
      }
    } catch (errInfo) { }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <>
        {dataIndex === "deliveryPlace" ? (
          <>
            <Form.Item
              label={<FormattedMessage id="BiddingLogQuotationEquipment.town" defaultMessage="Town" />}
              name="town"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Form.Item
              label={<FormattedMessage id="BiddingLogQuotationEquipment.city" defaultMessage="City" />}
              name="city"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Button id="SaveButton" onClick={toggleSave} style={{ width: "100%" }} variant="success">
              <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
            </Button>
          </>
        ) : (
          <Form.Item
            style={{
              margin: 0
            }}
            name={dataIndex}
          >
            {dataIndex === "plannedDeliveryDate" || dataIndex === "paymentDate" ? (
              <DatePicker ref={inputRef} onBlur={toggleSave} format={"DD-MM-YYYY"} />
            ) : dataIndex === "currency" ? (
              <Select ref={inputRef} onChange={toggleSave} onBlur={toggleSave} showSearch optionFilterProp="children">
                {Boolean(record.currencySelectItems) &&
                  record.currencySelectItems.length > 0 &&
                  record.currencySelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            ) : dataIndex === "unit" ? (
              <Cascader style={{ width: "100%" }} options={record.unitSelectItems} ref={inputRef} onChange={toggleSave} onBlur={toggleSave} />
            ) : (
              <InputNumber
                parser={value => value.replace(",", ".")}
                formatter={value => value.replace(".", ",")}
                min={0}
                ref={inputRef}
                onPressEnter={toggleSave}
                onBlur={toggleSave}
              />
            )}
          </Form.Item>
        )}
      </>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion

export class BiddingLogQuotationEquipment extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "BiddingLogQuotationEquipment",
      controllerName: "biddingLogQuotationEquipment",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  }

  fillComboboxes = async () => {
    // var responseitemId = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemId");
    // if (responseitemId.type === "ERROR") {
    //   error(responseitemId);
    // }
    // this.setState({
    //   itemIdSelectItems: Boolean(responseitemId.data) ? responseitemId.data : []
    // });
  };

  fillVendorId = async (itemId) => {
    if (Boolean(itemId) && itemId > 0) {
      var responsevendorId = await handleRequest("GET", "/api/" + this.state.controllerName + "/vendorId/" + itemId);
      if (responsevendorId.type === "ERROR") {
        error(responsevendorId);
      }
      this.setState({
        vendorIdSelectItems: Boolean(responsevendorId.data) ? responsevendorId.data : []
      });
    } else {
      this.setState({
        vendorIdSelectItems: []
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/pagingEquipment";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
          element.key = element.id;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      tenderingLogId: this.props.tenderingLogId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleCorrectedUnitCost = async (row, value) => {
    console.log("row", row);
    console.log("value", value);
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        const newData = [...this.state.tableList];
        const item = newData.find(item => row.key === item.key);
        if (Boolean(value) && Boolean(item)) {
          if (item.correctedUnitCost === value.correctedUnitCost) return;
          item.correctedUnitCost = value.correctedUnitCost;
          this.setState({
            tableList: newData
          });
        }
      }
    }
  };

  saveAll = async () => {
    const { tableList } = this.state;
    console.log("tableList");
    if (Boolean(this.props.tenderingLogId) && this.props.tenderingLogId > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAll", tableList);
      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess();
        this.props.close();
      }
    } else {
      showWarning("Bill Of Quantity not found.");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    };

    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationEquipment.Group" defaultMessage="Group" />,
        key: "id",
        render: record => {
          return record.groupName;
        },
        ...getColumnFilter({
          inputName: "id",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationEquipment.ItemDescription" defaultMessage="Item Description" />,
        key: "itemName",
        render: record => {
          return record.itemName;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationEquipment.Quantity" defaultMessage="Quantity" />,
        key: "numberToBeUsed",
        render: record => {
          return record.numberToBeUsed;
        },
        ...getColumnFilter({
          inputName: "numberToBeUsed",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationEquipment.WorkingPeriod" defaultMessage="Working Period" />,
        key: "workingPeriodName",
        render: record => {
          return record.workingPeriodName;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationEquipment.UnitCost" defaultMessage="Unit Cost" />,
        key: "forecastedCost",
        render: record => {
          let forecastedCost = "";
          if (Boolean(record.forecastedCost) && record.forecastedCost > 0) {
            forecastedCost += record.forecastedCost + " ";

            if (Boolean(record.forecastedCostName)) {
              forecastedCost += record.forecastedCostName;
            }
          }
          return forecastedCost;
        },
        ...getColumnFilter({
          inputName: "forecastedCost",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationEquipment.Cost" defaultMessage="Cost" />,
        key: "forecastedCost",
        render: record => {
          let forecastedCost = "";
          if (Boolean(record.forecastedCost) && record.forecastedCost > 0) {
            forecastedCost += (record.forecastedCost * record.numberToBeUsed) + " ";

            if (Boolean(record.forecastedCostName)) {
              forecastedCost += record.forecastedCostName;
            }
          }
          return forecastedCost;
        },
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationEquipment.CorrectedUnitCost" defaultMessage="Corrected Unit Cost" />,
        key: "correctedUnitCost",
        render: record => {
          let correctedUnitCost = "";
          if (Boolean(record.correctedUnitCost) && record.correctedUnitCost > 0) {
            correctedUnitCost += record.correctedUnitCost + " ";

            if (Boolean(record.forecastedCostName)) {
              correctedUnitCost += record.forecastedCostName;
            }
          }
          return correctedUnitCost;
        },
        ...getColumnFilter({
          inputName: "correctedUnitCost",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "correctedUnitCost",
          title: <FormattedMessage id="BiddingLogQuotationEquipment.CorrectedUnitCost" defaultMessage="Corrected Unit Cost" />,
          handleSave: this.handleCorrectedUnitCost
        })
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationEquipment.CorrectedCost" defaultMessage="Corrected Cost" />,
        key: "correctedUnitCost",
        render: record => {
          let correctedUnitCost = "";
          if (Boolean(record.correctedUnitCost) && record.correctedUnitCost > 0) {
            correctedUnitCost += (record.correctedUnitCost * record.numberToBeUsed).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");

            if (Boolean(record.forecastedCostName)) {
              correctedUnitCost += record.forecastedCostName;
            }
          }
          return correctedUnitCost;
        },
        ...getColumnFilter({
          inputName: "correctedUnitCost",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
      },
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={21}></Col>
            <Col md={3}>
              <Button
                variant="primary"
                onClick={this.saveAll.bind(this)}
                style={{ width: "100%", marginTop: "10px" }}
                disabled={Boolean(this.state.tableList) && this.state.tableList.length > 0 ? false : true}
                loading={this.state.loading}
              >
                <FormattedMessage id="BiddingLogQuotationEquipment.SaveAll" defaultMessage="Save All" />
              </Button>
            </Col>
          </Row>
        </Form>
        {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
          <div className="alert alert-custom alert-light">
            <div className="alert-icon">
              <FilterOutlined />
            </div>
            <div className="alert-text">
              {this.state.searchCriteriaModels.map((val, i) => {
                return (
                  <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                    <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                    <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                    <span>
                      <Tooltip title="Remove">
                        <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                      </Tooltip>
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="alert-close">
              <Tooltip title="Remove All">
                <CloseCircleOutlined
                  style={{ color: "crimson" }}
                  onClick={() =>
                    this.setState({ searchCriteriaModels: [] }, () => {
                      this.filterChangeItem([]);
                    })
                  }
                />
              </Tooltip>
            </div>
          </div>
        )}
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            components={components}
            rowClassName={() => "editable-row"}
            scroll={{ x: 900, y: 400, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Drawer
          title={<FormattedMessage id="BiddingLogQuotationEquipment.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"BiddingLogQuotationEquipment"} />
        </Drawer>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BiddingLogQuotationEquipment);