import "antd/dist/antd.css";
import moment from "moment";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { API_BASE_ROOT, handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Button as AntButton, Drawer, Select, Input, Upload, DatePicker, Checkbox } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";
import { PrepareFileUploadEdit, PrepareFileUploadSave } from "../../../Components/ElementUtils";
import DraggableModal from "../../../Components/DraggableModal";

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

export class BiddingLogAssignment extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "BiddingLogAssignment",
      controllerName: "biddingLogAssignment",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      }
    };
  }

  formRef = React.createRef();
  formRefNotification = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    this.fillComboboxes();
  }

  fillComboboxes = async () => {
    const responseData = await handleRequest("GET", "/api/" + this.state.controllerName + "/getList/" + this.props.tenderingLogId);
    if (responseData.type === "ERROR") {
      error(responseData);
    } else {
      const list = Boolean(responseData.data) ? responseData.data : [];

      const civilList = this.prepareList(list, "Civil");
      const electricalList = this.prepareList(list, "Electrical");
      const mechanicalList = this.prepareList(list, "Mechanical");
      const othersList = this.prepareList(list, "Others");

      this.formRef.current.setFieldsValue({
        civilList,
        electricalList,
        mechanicalList,
        othersList,
      });
    }

    var responseUploadPath = await handleRequest("GET", "/api/" + this.state.controllerName + "/uploadPaths/" + this.props.tenderingLogId);
    if (responseUploadPath.type === "ERROR") {
      error(responseUploadPath);
    }
    var responsePersonnels = await handleRequest("GET", "/api/" + this.state.controllerName + "/personnels");
    if (responsePersonnels.type === "ERROR") {
      error(responsePersonnels);
    }

    this.setState({
      uploadPathSelectItems: Boolean(responseUploadPath.data) ? responseUploadPath.data : [],
      personnelSelectItems: Boolean(responsePersonnels.data) ? responsePersonnels.data : [],
    });
  };

  prepareList = (list, discipline) => {
    return list
      .filter(p => p.discipline === discipline)
      .map(element => {
        element.fileUrl = PrepareFileUploadEdit(element.fileUrl);
        let personnels = [];
        if (Boolean(element.personnels)) {
          element.personnels.forEach(pers => {
            personnels.push(pers.personnelLogId);
          });
        }
        element.personnels = personnels;

        let startEndDate = [];
        if (Boolean(element.startDate) && Boolean(element.endDate)) {
          startEndDate.push(moment(element.startDate));
          startEndDate.push(moment(element.endDate));
        }
        element.startEndDate = startEndDate;

        return element;
      });
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = { ...values };
    const { tenderingLogId } = this.props;

    newItem.civilList = this.processListItems(newItem.civilList);
    newItem.electricalList = this.processListItems(newItem.electricalList);
    newItem.mechanicalList = this.processListItems(newItem.mechanicalList);
    newItem.othersList = this.processListItems(newItem.othersList);

    let list = [
      ...newItem.civilList,
      ...newItem.electricalList,
      ...newItem.mechanicalList,
      ...newItem.othersList
    ];

    console.log("list", list);
    var response = await handleRequest(
      "POST",
      "/api/" + this.state.controllerName + "/saveAllList/" + tenderingLogId,
      list
    );

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      showSuccess();
      this.props.close();
    }
  };

  processListItems = (list) => {
    if (Boolean(list) && list.length > 0) {
      return list.map(item => {
        item.fileUrl = PrepareFileUploadSave(item.fileUrl);

        let personnels = [];
        if (Boolean(item.personnels) && item.personnels.length > 0) {
          personnels = item.personnels.map(p => ({ "personnelLogId": p }));
        }
        item.personnels = personnels;

        if (Boolean(item.startEndDate) && item.startEndDate.length === 2) {
          item.startDate = item.startEndDate[0];
          item.endDate = item.startEndDate[1];
        }

        if (!Boolean(item.fileUrl)) {
          item.fileUrl = item.fileUrlSelect;
        }

        return item;
      });
    }
    return [];
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };


  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  saveNotification = async values => {
    const { currentField, currentType } = this.state;

    const updateList = (currentType) => {
      let list = this.formRef.current.getFieldValue(currentType);
      let item = { ...list[currentField] };

      let notifications = [];
      if (Boolean(values.personnelLogIds) && values.personnelLogIds.length > 0) {
        values.personnelLogIds.forEach(element => {
          notifications.push({ "personnelLogId": element, "remarks": values.remarks });
        });
      }
      item.notifications = notifications;

      let newList = [...list];
      newList[currentField] = item;

      this.formRef.current.setFieldsValue({
        [currentType]: newList
      });
    };

    updateList(currentType);
    this.setState({
      modalNotificationShow: false,
    });
  };

  updateFormFields = () => {
    const { currentField, currentType } = this.state;
    let list = this.formRef.current.getFieldValue(currentType);
    let item = { ...list[currentField] };
    let personnelLogIds = [];
    let remarks = "";

    if (Boolean(item.notifications) && item.notifications.length > 0) {
      item.notifications.forEach(element => {
        personnelLogIds.push(element.personnelLogId);
        remarks = element.remarks;
      });
    }

    this.formRefNotification.current.setFieldsValue({
      "personnelLogIds": personnelLogIds,
      "remarks": remarks,
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    const normFile = e => {
      console.log("Upload event:", e);

      if (Array.isArray(e)) {
        return e;
      }

      return e && e.fileList;
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
        <Checkbox
                        // onChange={e => {
                        //   if (e.target.checked) {
                        //     this.formRef.current.setFieldsValue({});
                        //   }
                        //   this.setState({});
                        // }}
                      >
                       
                      </Checkbox>
          <FormattedMessage id="BiddingLogAssignment.Civil" defaultMessage="Civil" />
          <Form.List name="civilList">
            {(fields, { }) => {
              return (
                <>
                  <TableBoot bordered size="sm">
                    <thead>
                      <tr>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                        </th>
                        <th colSpan="1" style={{ textAlign: "center", width: "150px" }}>
                          <FormattedMessage id="BiddingLogAssignment.Personnel" defaultMessage="Personnel" />
                        </th>
                        <th colSpan="2" style={{ textAlign: "center" }}>
                          <FormattedMessage id="BiddingLogAssignment.File" defaultMessage="File" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="BiddingLogAssignment.Date" defaultMessage="Date" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="BiddingLogAssignment.Remarks" defaultMessage="Remarks" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map((field, index) => {
                        return (
                          <tr key={field.key}>
                            <Form.Item hidden name={[field.name, "id"]}></Form.Item>
                            <td>
                              <Form.Item noStyle name={[field.name, "type"]}>
                                <Input style={{ width: "100%" }} readOnly />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item noStyle name={[field.name, "personnels"]}>
                                <Select
                                  allowClear
                                  showSearch
                                  mode="multiple"
                                  style={{ width: "100%" }}
                                  optionFilterProp="children"
                                >
                                  {Boolean(this.state["personnelSelectItems"]) &&
                                    this.state["personnelSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item noStyle name={[field.name, "fileUrlSelect"]}>
                                <Select
                                  allowClear
                                  showSearch
                                  style={{ width: "150px" }}
                                  optionFilterProp="children"
                                >
                                  {Boolean(this.state["uploadPathSelectItems"]) &&
                                    this.state["uploadPathSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>

                            <td>
                              <Form.Item noStyle valuePropName="fileList" getValueFromEvent={normFile} name={[field.name, "fileUrl"]}>
                                <Upload {...props}>
                                  <AntButton icon={<UploadOutlined />}>
                                    <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                                  </AntButton>
                                </Upload>
                              </Form.Item>
                            </td>

                            <td>
                              <Form.Item style={{ width: "100%" }} name={[field.name, "startEndDate"]}>
                                <RangePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" />
                              </Form.Item>
                            </td>

                            <td>
                              <UserOutlined onClick={() => {
                                this.setState({
                                  currentField: field.name,
                                  currentType: "civilList",
                                  modalNotificationShow: true,
                                }, () => {
                                  this.updateFormFields();
                                });
                              }} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </TableBoot>
                </>
              );
            }}
          </Form.List>
          <br /> <br />
          <Checkbox
                        // onChange={e => {
                        //   if (e.target.checked) {
                        //     this.formRef.current.setFieldsValue({});
                        //   }
                        //   this.setState({});
                        // }}
                      >
                       
                      </Checkbox>
          <FormattedMessage id="BiddingLogAssignment.Electrical" defaultMessage="Electrical" />
          <Form.List name="electricalList">
            {(fields, { }) => {
              return (
                <>
                  <TableBoot bordered size="sm">
                    <thead>
                      <tr>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                        </th>
                        <th colSpan="1" style={{ textAlign: "center", width: "150px" }}>
                          <FormattedMessage id="BiddingLogAssignment.Personnel" defaultMessage="Personnel" />
                        </th>
                        <th colSpan="2" style={{ textAlign: "center" }}>
                          <FormattedMessage id="BiddingLogAssignment.File" defaultMessage="File" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="BiddingLogAssignment.Date" defaultMessage="Date" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="BiddingLogAssignment.Remarks" defaultMessage="Remarks" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map((field, index) => {
                        return (
                          <tr key={field.key}>
                            <Form.Item hidden name={[field.name, "id"]}></Form.Item>
                            <td>
                              <Form.Item noStyle name={[field.name, "type"]}>
                                <Input style={{ width: "100%" }} readOnly />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item noStyle name={[field.name, "personnels"]}>
                                <Select
                                  allowClear
                                  showSearch
                                  mode="multiple"
                                  style={{ width: "100%" }}
                                  optionFilterProp="children"
                                >
                                  {Boolean(this.state["personnelSelectItems"]) &&
                                    this.state["personnelSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item noStyle name={[field.name, "fileUrlSelect"]}>
                                <Select
                                  allowClear
                                  showSearch
                                  style={{ width: "150px" }}
                                  optionFilterProp="children"
                                >
                                  {Boolean(this.state["uploadPathSelectItems"]) &&
                                    this.state["uploadPathSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>

                            <td>
                              <Form.Item noStyle valuePropName="fileList" getValueFromEvent={normFile} name={[field.name, "fileUrl"]}>
                                <Upload {...props}>
                                  <AntButton icon={<UploadOutlined />}>
                                    <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                                  </AntButton>
                                </Upload>
                              </Form.Item>
                            </td>

                            <td>
                              <Form.Item style={{ width: "100%" }} name={[field.name, "startEndDate"]}>
                                <RangePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" />
                              </Form.Item>
                            </td>

                            <td>
                              <UserOutlined onClick={() => {
                                this.setState({
                                  currentField: field.name,
                                  currentType: "electricalList",
                                  modalNotificationShow: true,
                                }, () => {
                                  this.updateFormFields();
                                });
                              }} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </TableBoot>
                </>
              );
            }}
          </Form.List>
          <br /> <br />
          <Checkbox
                        // onChange={e => {
                        //   if (e.target.checked) {
                        //     this.formRef.current.setFieldsValue({});
                        //   }
                        //   this.setState({});
                        // }}
                      >
                       
                      </Checkbox>
          <FormattedMessage id="BiddingLogAssignment.Mechanical" defaultMessage="Mechanical" />
          <Form.List name="mechanicalList">
            {(fields, { }) => {
              return (
                <>
                  <TableBoot bordered size="sm">
                    <thead>
                      <tr>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                        </th>
                        <th colSpan="1" style={{ textAlign: "center", width: "150px" }}>
                          <FormattedMessage id="BiddingLogAssignment.Personnel" defaultMessage="Personnel" />
                        </th>
                        <th colSpan="2" style={{ textAlign: "center" }}>
                          <FormattedMessage id="BiddingLogAssignment.File" defaultMessage="File" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="BiddingLogAssignment.Date" defaultMessage="Date" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="BiddingLogAssignment.Remarks" defaultMessage="Remarks" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map((field, index) => {
                        return (
                          <tr key={field.key}>
                            <Form.Item hidden name={[field.name, "id"]}></Form.Item>
                            <td>
                              <Form.Item noStyle name={[field.name, "type"]}>
                                <Input style={{ width: "100%" }} readOnly />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item noStyle name={[field.name, "personnels"]}>
                                <Select
                                  allowClear
                                  showSearch
                                  mode="multiple"
                                  style={{ width: "100%" }}
                                  optionFilterProp="children"
                                >
                                  {Boolean(this.state["personnelSelectItems"]) &&
                                    this.state["personnelSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item noStyle name={[field.name, "fileUrlSelect"]}>
                                <Select
                                  allowClear
                                  showSearch
                                  style={{ width: "150px" }}
                                  optionFilterProp="children"
                                >
                                  {Boolean(this.state["uploadPathSelectItems"]) &&
                                    this.state["uploadPathSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>

                            <td>
                              <Form.Item noStyle valuePropName="fileList" getValueFromEvent={normFile} name={[field.name, "fileUrl"]}>
                                <Upload {...props}>
                                  <AntButton icon={<UploadOutlined />}>
                                    <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                                  </AntButton>
                                </Upload>
                              </Form.Item>
                            </td>

                            <td>
                              <Form.Item style={{ width: "100%" }} name={[field.name, "startEndDate"]}>
                                <RangePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" />
                              </Form.Item>
                            </td>

                            <td>
                              <UserOutlined onClick={() => {
                                this.setState({
                                  currentField: field.name,
                                  currentType: "mechanicalList",
                                  modalNotificationShow: true,
                                }, () => {
                                  this.updateFormFields();
                                });
                              }} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </TableBoot>
                </>
              );
            }}
          </Form.List>
          <br /> <br />
          <FormattedMessage id="BiddingLogAssignment.Others" defaultMessage="Others" />
          <Form.List name="othersList">
            {(fields, { }) => {
              return (
                <>
                  <TableBoot bordered size="sm">
                    <thead>
                      <tr>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                        </th>
                        <th colSpan="1" style={{ textAlign: "center", width: "150px" }}>
                          <FormattedMessage id="BiddingLogAssignment.Personnel" defaultMessage="Personnel" />
                        </th>
                        <th colSpan="2" style={{ textAlign: "center" }}>
                          <FormattedMessage id="BiddingLogAssignment.File" defaultMessage="File" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="BiddingLogAssignment.Date" defaultMessage="Date" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="BiddingLogAssignment.Remarks" defaultMessage="Remarks" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map((field, index) => {
                        return (
                          <tr key={field.key}>
                            <Form.Item hidden name={[field.name, "id"]}></Form.Item>
                            <td>
                              <Form.Item noStyle name={[field.name, "type"]}>
                                <Input style={{ width: "100%" }} readOnly />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item noStyle name={[field.name, "personnels"]}>
                                <Select
                                  allowClear
                                  showSearch
                                  mode="multiple"
                                  style={{ width: "100%" }}
                                  optionFilterProp="children"
                                >
                                  {Boolean(this.state["personnelSelectItems"]) &&
                                    this.state["personnelSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item noStyle name={[field.name, "fileUrlSelect"]}>
                                <Select
                                  allowClear
                                  showSearch
                                  style={{ width: "150px" }}
                                  optionFilterProp="children"
                                >
                                  {Boolean(this.state["uploadPathSelectItems"]) &&
                                    this.state["uploadPathSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>

                            <td>
                              <Form.Item noStyle valuePropName="fileList" getValueFromEvent={normFile} name={[field.name, "fileUrl"]}>
                                <Upload {...props}>
                                  <AntButton icon={<UploadOutlined />}>
                                    <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                                  </AntButton>
                                </Upload>
                              </Form.Item>
                            </td>

                            <td>
                              <Form.Item style={{ width: "100%" }} name={[field.name, "startEndDate"]}>
                                <RangePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" />
                              </Form.Item>
                            </td>

                            <td>
                              <UserOutlined onClick={() => {
                                this.setState({
                                  currentField: field.name,
                                  currentType: "othersList",
                                  modalNotificationShow: true,
                                }, () => {
                                  this.updateFormFields();
                                });
                              }} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </TableBoot>
                </>
              );
            }}
          </Form.List>

          <div hidden={this.state.hideSave}>
            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            }
          </div>
          <div hidden={this.state.hideUpdate}>
            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            }
          </div>
        </Form>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Drawer
          title={<FormattedMessage id="BiddingLogAssignment.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"BiddingLogAssignment"} />
        </Drawer>

        <DraggableModal
          title={<FormattedMessage id="BiddingLogAssignment.Personnel" defaultMessage="Personnel" />}
          open={this.state.modalNotificationShow}
          okButtonProps={{ hidden: false }}
          onCancel={() => this.setState({ modalNotificationShow: false, userIds: [] })}
          onOk={() => this.formRefNotification.current.submit()}
          width={800}
          centered
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveNotification} ref={this.formRefNotification}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="BiddingLogAssignment.Personnel" defaultMessage="Personnel" />}
                name="personnelLogIds"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  allowClear
                  mode="multiple"
                  onChange={value => {
                    this.setState({
                      userIds: value
                    });
                  }}
                >
                  {Boolean(this.state["personnelSelectItems"]) &&
                    this.state["personnelSelectItems"].length > 0 &&
                    this.state["personnelSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="BiddingLogAssignment.Remarks" defaultMessage="Remarks" />}
                name="remarks"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input.TextArea style={{ width: "100%" }} autoSize={{ minRows: 2, maxRows: 6 }} />
              </Form.Item>
            </Form>
          }
        />
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BiddingLogAssignment);