import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Input, Tree, Form, Modal as AntModal, Badge, Space } from "antd";
import { FcFolder, FcEditImage, FcFile } from "react-icons/fc";
import { Menu, Item, useContextMenu } from "react-contexify";
import { FaFileAudio, FaRegFile } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../../ApiConnector";
import { error, showError, showSuccess } from "../../../MessageHelper";
import { Button } from "react-bootstrap";

const Menu_ProjectName = "Menu_ProjectName";
const Menu_Project = "Menu_Project";
const Menu_Structure = "Menu_Structure";
const Menu_Area = "Menu_Area";
const Menu_Division = "Menu_Division";

const { show } = useContextMenu();

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

export class ProjectsIdentifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "ProjectsIdentifications",
      controllerName: "projectsIdentifications",
      id: null,
      projectName: "",
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      nodes: [],
      loadedKeys: [],
      searchValue: "",
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showServiceModal: false,
      refreshTree: true,

      menuProjectNameVisible: true,
      menuProjectVisible: true,
      menuStructureVisible: true,
      menuAreaVisible: true,
      menuDivisionVisible: true
    };
  }

  inputRef = React.createRef(null);

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "ProjectName"
    };

    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitems/" + obj.id);
    }
    if (response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.icon =
          Boolean(element.type) && element.type === "ProjectName" ? (
            <FcFile />
          ) : element.type === "Project" ? (
            <FaRegFile />
          ) : element.type === "Structure" ? (
            <FaFileAudio />
          ) : element.type === "Area" ? (
            <FaFileAudio />
          ) : element.type === "Division" ? (
            <FaFileAudio />
          ) : (
            <FcFolder />
          );
        element.title = element.projectName;
        element.key = element.id;
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      this.setState({
        nodes: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  formRef = React.createRef();
  formRefOnClickName = React.createRef();
  formRefCreateProject = React.createRef();

  componentDidMount = async () => {
    this.fillTree();
  };

  onCheck = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    if (Boolean(this.props) && Boolean(this.props.onTargetKeysCheck)) {
      console.log("checkedKeys", e.checkedNodes);
      console.log("checkedKeys", e.halfCheckedKeys);
      this.props.onTargetKeysCheck(checkedKeys.checked);
    }
    this.setState({
      checkedKeys: checkedKeys,
      halfCheckedKeys: e.halfCheckedKeys,
      checkedNodes: e.checkedNodes
    });
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "ProjectName";
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  onRightClick = (event, node) => {
    let parentNode = this.getParentNode(node);
    this.setState(
      {
        selectedNodeKey: node.key,
        selectedNode: node,
        parentNode: parentNode
      },
      this.displayMenu(event, Boolean(node) && Boolean(node.type) ? node.type : null)
    );
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  handleItemClick = ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;

    console.log("AAA", event.currentTarget.id);
    console.log(selectedNode);
    console.log(parentNode);
    console.log(key);

    // eslint-disable-next-line default-case
    switch (event.currentTarget.id) {
      case "create_projectName":
        this.setState(
          {
            showCreateProjectModal: true,
            parentId: selectedNode.id,
            projectName: "",
            type: "ProjectName"
          },
          () => {
            if (Boolean(this.formRefCreateProject) && Boolean(this.formRefCreateProject.current)) {
              this.formRefCreateProject.current.resetFields();
            }
          }
        );
        break;

      case "create_project":
        this.setState(
          {
            showCreateProjectModal: true,
            parentId: selectedNode.id,
            projectName: "",
            type: "Project"
          },
          () => {
            if (Boolean(this.formRefCreateProject) && Boolean(this.formRefCreateProject.current)) {
              this.formRefCreateProject.current.resetFields();
            }
          }
        );
        break;

      case "create_Structure":
        this.setState(
          {
            showCreateProjectModal: true,
            parentId: selectedNode.id,
            projectName: "",
            type: "Structure"
          },
          () => {
            if (Boolean(this.formRefCreateProject) && Boolean(this.formRefCreateProject.current)) {
              this.formRefCreateProject.current.resetFields();
            }
          }
        );
        break;

      case "create_Area":
        this.setState(
          {
            showCreateProjectModal: true,
            parentId: selectedNode.id,
            projectName: "",
            type: "Area"
          },
          () => {
            if (Boolean(this.formRefCreateProject) && Boolean(this.formRefCreateProject.current)) {
              this.formRefCreateProject.current.resetFields();
            }
          }
        );
        break;

      case "create_Division":
        this.setState(
          {
            showCreateProjectModal: true,
            parentId: selectedNode.id,
            projectName: "",
            type: "Division"
          },
          () => {
            if (Boolean(this.formRefCreateProject) && Boolean(this.formRefCreateProject.current)) {
              this.formRefCreateProject.current.resetFields();
            }
          }
        );
        break;

      case "rename":
        this.setState({
          showCreateProjectModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        });
        break;

      case "delete":
        this.setState({
          showDeleteModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;
      default:
    }
  };

  getCheckedKeys = async id => {
    if (Boolean(id) && id > 0) {
      var res = await handleRequest("GET", "/api/" + this.state.controllerName + "/samplingDivision/" + id);
      if (res.type === "ERROR") {
        error(res);
      } else {
        let list = Boolean(res.data) ? res.data : [];
        let keyList = [];
        list.forEach(element => {
          keyList.push("CODE~" + element.stockCardId);
        });
        console.log(keyList);
        if (Boolean(this.props) && Boolean(this.props.onTargetKeysCheck)) {
          this.props.onTargetKeysCheck(keyList);
        }
        this.setState({
          checkedKeys: keyList
        });
      }
    }
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id,
      type: this.state.type
    };

    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
      else this.fillTree();

      showSuccess();
      this.setState({
        id: null,
        showDeleteModal: false
      });
    }
  };

  displayMenu = (e, type) => {
    console.log("TTT", type);
    if (Boolean(type)) {
      if (type === "Project") {
        show(e, { id: Menu_Project });
      } else if (type === "Structure") {
        show(e, { id: Menu_Structure });
      } else if (type === "Area") {
        show(e, { id: Menu_Area });
      } else if (type === "Division") {
        show(e, { id: Menu_Division });
      } else if (type === "ProjectName") {
        show(e, { id: Menu_ProjectName });
      }
    }
  };

  save = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId,
      type: Boolean(this.state.type) ? this.state.type : "ProjectName"
    };

    // let list = [];
    // if (Boolean(this.state.checkedKeys) && this.state.checkedKeys.length > 0) {
    //   this.state.checkedKeys.forEach(element => {
    //     let obj = { stockCardId: element.split("~")[1], type: element.split("~")[0], vendorId: this.state.vendorId };
    //     if (obj.type === "CODE" && Boolean(obj.stockCardId) && obj.stockCardId > 0 && !list.includes(obj.stockCardId)) {
    //       list.push(obj.stockCardId);
    //     }
    //   });
    // }

    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveProjectsIdentifications", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateProjectsIdentifications/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showCreateProjectModal: false
      });
    }
  };

  saveOnClickName = async values => {
    if (Boolean(this.state.pageAutoDesignId) && this.state.pageAutoDesignId > 0) {
      const newItem = { ...values, id: this.state.id };
      newItem.id = this.state.pageAutoDesignId;
      newItem.autoDesignOnClick = this.state.autoDesignOnClick;
      newItem.type = this.state.type;

      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveOnClickName", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess();
        this.setState({ showOnClickNameModal: false });
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }
    } else {
      showError("Compounds Form not found");
    }
  };

  paste = () => {
    this.formRef.current.submit();
    this.formRefCreateProject.current.submit();
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      description: "",
      projectName: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      process: null,
      showOrderModal: false,
      showPasteModal: false,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showServiceModal: false
    });
  };

  expandSelectedNode = () => {
    let key = this.state.selectedNodeKey;
    let node = this.state.selectedNode;
    if (Boolean(key)) {
      let expandedKeys = Boolean(this.state.expandedKeys) ? this.state.expandedKeys : [];
      if (Boolean(expandedKeys.find(p => p === key))) {
        this.loadData(node, false);
      } else {
        this.setState({
          expandedKeys: [...expandedKeys, key]
        });
        this.loadData(node, false);
      }
    }
  };

  itemTemplate(item) {
    return (
      <div className="product-item">
        <div className="product-list-Division">
          <h5 className="mb-2">{item.title}</h5>
        </div>
      </div>
    );
  }

  onDragEnter = info => {};

  onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...this.state.nodes];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 &&
      // Has children
      info.node.props.expanded &&
      // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    this.setState({
      nodes: data
    });
  };

  closeFormModal = () => {
    this.setState({
      showFormModal: false
    });
  };

  closeTableModal = () => {
    this.setState({
      showTableModal: false
    });
  };

  closeInputFormatModal = () => {
    this.setState({
      showInputFormatModal: false
    });
  };

  closeTableFormatModal = () => {
    this.setState({
      showTableFormatModal: false
    });
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <Badge.Ribbon
        text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage={"Projects Identifications"} />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <br />
          <br />

          {this.state.refreshTree &&
            (Boolean(this.props) && Boolean(this.props.onTargetKeysCheck) ? (
              <Tree
                style={{ marginTop: "10px", lineHeight: "30px" }}
                className="draggable-tree"
                showLine={{ showLeafIcon: false }}
                showIcon
                checkStrictly={true}
                loadedKeys={this.state.loadedKeys}
                onLoad={this.onLoadData}
                loadData={this.loadData}
                onRightClick={({ event, node }) => this.onRightClick(event, node)}
                onExpand={this.onExpand}
                expandedKeys={this.state.expandedKeys}
                autoExpandParent={false}
                treeData={this.state.nodes}
                blockNode={true}
                draggable
                checkable
                onCheck={this.onCheck}
                checkedKeys={this.state.checkedKeys}
                halfCheckedKeys={this.state.halfCheckedKeys}
                onDragEnter={this.onDragEnter}
                onDrop={this.onDrop}
              />
            ) : (
              <Tree
                style={{ marginTop: "10px", lineHeight: "30px" }}
                className="draggable-tree"
                showLine={{ showLeafIcon: false }}
                showIcon
                checkStrictly={true}
                loadedKeys={this.state.loadedKeys}
                onLoad={this.onLoadData}
                loadData={this.loadData}
                onRightClick={({ event, node }) => this.onRightClick(event, node)}
                onExpand={this.onExpand}
                expandedKeys={this.state.expandedKeys}
                autoExpandParent={false}
                treeData={this.state.nodes}
                blockNode={true}
                draggable
                onDragEnter={this.onDragEnter}
                onDrop={this.onDrop}
              />
            ))}

          {/* MENU */}
          {this.state.menuProjectNameVisible && !(Boolean(this.props) && Boolean(this.props.onTargetKeysCheck)) && (
            <Menu id={Menu_ProjectName}>
              <Item id="create_project" onClick={this.handleItemClick}>
                <Space>
                  <FcEditImage />
                  <span>
                    <FormattedMessage id="ProjectsIdentifications.AddProject" defaultMessage="Add Project" />
                  </span>
                </Space>
              </Item>
            </Menu>
          )}
          {this.state.menuProjectVisible && !(Boolean(this.props) && Boolean(this.props.onTargetKeysCheck)) && (
            <Menu id={Menu_Project}>
              <Item id="create_Structure" onClick={this.handleItemClick}>
                <Space>
                  <FcEditImage />
                  <span>
                    <FormattedMessage id="ProjectsIdentifications.CreateStructure" defaultMessage="Create Structure" />
                  </span>
                </Space>
              </Item>
              <Item id="rename" onClick={this.handleItemClick}>
                <Space>
                  <FcEditImage />
                  <span>
                    <FormattedMessage id="ProjectsIdentifications.rename" defaultMessage="Rename" />
                  </span>
                </Space>
              </Item>
              <Item id="delete" onClick={this.handleItemClick}>
                <Space>
                  <MdDelete />
                  <span>
                    <FormattedMessage id="ProjectsIdentifications.delete" defaultMessage="Delete" />
                  </span>
                </Space>
              </Item>
            </Menu>
          )}
          {this.state.menuStructureVisible && !(Boolean(this.props) && Boolean(this.props.onTargetKeysCheck)) && (
            <Menu id={Menu_Structure}>
              <Item id="create_Area" onClick={this.handleItemClick}>
                <Space>
                  <FcEditImage />
                  <span>
                    <FormattedMessage id="ProjectsIdentifications.CreateArea" defaultMessage="Create Area" />
                  </span>
                </Space>
              </Item>
              <Item id="rename" onClick={this.handleItemClick}>
                <Space>
                  <FcEditImage />
                  <span>
                    <FormattedMessage id="ProjectsIdentifications.rename" defaultMessage="Rename" />
                  </span>
                </Space>
              </Item>
              <Item id="delete" onClick={this.handleItemClick}>
                <Space>
                  <MdDelete />
                  <span>
                    <FormattedMessage id="ProjectsIdentifications.delete" defaultMessage="Delete" />
                  </span>
                </Space>
              </Item>
            </Menu>
          )}
          {this.state.menuAreaVisible && !(Boolean(this.props) && Boolean(this.props.onTargetKeysCheck)) && (
            <Menu id={Menu_Area}>
              <Item id="create_Division" onClick={this.handleItemClick}>
                <Space>
                  <FcEditImage />
                  <span>
                    <FormattedMessage id="ProjectsIdentifications.CreateDivision" defaultMessage="Create Division" />
                  </span>
                </Space>
              </Item>
              <Item id="rename" onClick={this.handleItemClick}>
                <Space>
                  <FcEditImage />
                  <span>
                    <FormattedMessage id="ProjectsIdentifications.rename" defaultMessage="Rename" />
                  </span>
                </Space>
              </Item>
              <Item id="delete" onClick={this.handleItemClick}>
                <Space>
                  <MdDelete />
                  <span>
                    <FormattedMessage id="ProjectsIdentifications.delete" defaultMessage="Delete" />
                  </span>
                </Space>
              </Item>
            </Menu>
          )}
          {this.state.menuDivisionVisible && !(Boolean(this.props) && Boolean(this.props.onTargetKeysCheck)) && (
            <Menu id={Menu_Division}>
              <Item id="rename" onClick={this.handleItemClick}>
                <Space>
                  <FcEditImage />
                  <span>
                    <FormattedMessage id="ProjectsIdentifications.rename" defaultMessage="Rename" />
                  </span>
                </Space>
              </Item>
              <Item id="delete" onClick={this.handleItemClick}>
                <Space>
                  <MdDelete />
                  <span>
                    <FormattedMessage id="ProjectsIdentifications.delete" defaultMessage="Delete" />
                  </span>
                </Space>
              </Item>
            </Menu>
          )}
          {/* TABLE MODAL */}

          <AntModal
            title={<FormattedMessage id="ProjectsIdentifications.Project" defaultMessage="Project" />}
            open={this.state.showCreateProjectModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showCreateProjectModal: false })}
            onOk={() => this.formRefCreateProject.current.submit()}
            width={800}
            centered
          >
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRefCreateProject}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProjectPlanning.Location" defaultMessage="Project Name" />}
                  name="projectName"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
            </Form>
          </AntModal>

          {/* DeleteModal */}

          <AntModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            open={this.state.showDeleteModal}
            onCancel={() => {
              this.setState({ showDeleteModal: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ showDeleteModal: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.delete}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Space>
            ]}
          >
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </AntModal>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsIdentifications);
