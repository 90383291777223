import { Button as AntButton, Col, Form, Row, DatePicker, Select, Table } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import DataTableComponent2 from "../../Components/DataTableComponent2";
import ElementsProvider from "../../Components/ElementsProvider";
import { GetDynamicFormItems, PrepareRowForEdit, PrepareRowForSave } from "../../Components/ElementUtils";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { ExcelReader } from "../excel/ExcelReader";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;

export class CashFlow extends Component {
  constructor() {
    super();
    this.state = {
      period: "DAILY",
      startDate: moment()
        .subtract(1, "M")
        .toDate(),
      endDate: moment().toDate(),
      loading: false,
      columns: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.restartTable();
  };

  search = async values => {
    console.log(values);
    if (Boolean(values.startDate) && Boolean(values.endDate) && values.startDate > values.endDate) {
      showError("Start date cannot be greater than end date");
      return;
    }

    this.setState(
      {
        period: Boolean(values.period) ? values.period : "MONTHLY",
        startDate: Boolean(values.startDate) ? values.startDate.toDate() : null,
        endDate: Boolean(values.endDate) ? values.endDate.toDate() : null,
        loading: true
      },
      this.restartTable
    );
  };

  restartTable = async () => {
    const { period, startDate, endDate } = this.state;

    const search = {
      period,
      startDate: startDate
        ? startDate
        : moment()
            .subtract(1, "M")
            .toDate(),
      endDate: endDate ? endDate : moment().toDate()
    };
    var response = await handleRequest("POST", "/api/cashflow", search);
    if (response.type === "ERROR") {
      error(response);
    } else {
      let data = Boolean(response.data) ? response.data : null;
      let tableList = [];
      let tableListActualized = [];
      let columns = [
        {
          title: "",
          key: "name",
          render: (value, row, index) => {
            return (
              <React.Fragment>
                <div
                  style={{
                    color: "black",
                    font: "bold",
                    fontSize: "16px"
                  }}
                >
                  {row.name}
                </div>
              </React.Fragment>
            );
          },
          width: 200,
          fixed: "left"
        }
      ];

      if (Boolean(data)) {
        let localColumns = this.generateColumns();

        let incomeList = this.generateList2(data.incomes, localColumns, "Incomes", data.incomesActualized);
        let outgoingList = this.generateList2(data.outgoings, localColumns, "Outgoings", data.outgoingsActualized);

        let incomeList2 = this.generateList(data.incomes, localColumns, "Incomes");
        let outgoingList2 = this.generateList(data.outgoings, localColumns, "Outgoings");
        let totalRows = this.generateTotalRows(incomeList2, outgoingList2, localColumns, "(P)");

        let incomeListActualized = this.generateList(data.incomesActualized, localColumns, "Incomes");
        let outgoingListActualized = this.generateList(data.outgoingsActualized, localColumns, "Outgoings");
        let totalRowsActualized = this.generateTotalRows(incomeListActualized, outgoingListActualized, localColumns, "(A)");

        tableList.push(...incomeList);
        tableList.push(...outgoingList);
        tableList.push(...totalRows);
        tableList.push(...totalRowsActualized);

        tableListActualized.push(...incomeListActualized);
        tableListActualized.push(...outgoingListActualized);
        tableListActualized.push(...totalRowsActualized);

        columns.push(...localColumns);
      }
      this.setState({ columns, tableList, tableListActualized, loading: false });
    }
  };

  generateColumns = () => {
    const { period, startDate, endDate } = this.state;
    let columns = [];
    if (Boolean(startDate) && Boolean(endDate))
      switch (period) {
        case "DAILY":
          let start = startDate;
          let end = endDate;
          let days = moment(end).diff(moment(start), "days");
          if (days >= 0) {
            for (let i = 0; i <= days; i++) {
              let date = moment(start)
                .add(i, "days")
                .toDate();
              columns.push({
                title: moment(date).format("DD-MM-YYYY"),
                key: moment(date).format("DD-MM-YYYY"),
                render: (value, row, index) => {
                  return row[moment(date).format("DD-MM-YYYY")];
                },
                width: 200
              });
            }
          }
          break;
        case "WEEKLY":
          let startWeek = startDate;
          let endWeek = endDate;
          let weeks = moment(endWeek).diff(moment(startWeek), "weeks");
          if (weeks >= 0) {
            for (let i = 0; i <= weeks; i++) {
              let date = moment(startWeek)
                .add(i, "weeks")
                .toDate();
              let weekIndex = moment(date).weekYear();
              let year = moment(date).year();
              columns.push({
                title: weekIndex + "-" + year,
                key: weekIndex + "-" + year,
                render: (value, row, index) => {
                  return row[weekIndex + "-" + year];
                },
                width: 200
              });
            }
          }

          break;
        case "MONTHLY":
          let startMonth = startDate;
          let endMonth = endDate;
          let months = moment(endMonth).diff(moment(startMonth), "months");
          if (months >= 0) {
            for (let i = 0; i <= months; i++) {
              let date = moment(startMonth)
                .add(i, "months")
                .toDate();
              let monthIndex = moment(date).month() + 1;
              let year = moment(date).year();
              columns.push({
                title: moment(date).format("MMM-YYYY"),
                key: monthIndex + "-" + year, //moment(date).format("MMM-YYYY"),
                render: (value, row, index) => {
                  return row[monthIndex + "-" + year];
                },
                width: 200
              });
            }
          }

          break;
        case "YEARLY":
          let startYear = startDate;
          let endYear = endDate;
          let years = moment(endYear).diff(moment(startYear), "years");
          if (years >= 0) {
            for (let i = 0; i <= years; i++) {
              let date = moment(startYear)
                .add(i, "years")
                .toDate();
              let year = moment(date).year() + "";
              columns.push({
                title: year,
                key: year,
                render: (value, row, index) => {
                  return row[year];
                },
                width: 200
              });
            }
          }

          break;

        default:
          break;
      }

    return columns;
  };

  generateList2 = (list, columns, type, listActualized) => {
    let tableList = [];
    let firstRow = { key: type, name: type };
    if (Boolean(list)) {
      list.forEach(item => {
        if (tableList.findIndex(x => x.name === item.name) === -1) {
          let row = {
            key: item.name,
            name: item.name
          };
          columns.forEach(column => {
            let f = list.find(x => x.name === item.name && x.dateStr === column.key);
            let fAct = listActualized.find(x => x.name === item.name && x.dateStr === column.key);
            if (Boolean(f)) {
              firstRow[column.key + "planned"] = Boolean(firstRow[column.key + "planned"]) ? firstRow[column.key + "planned"] + f.amount : f.amount;
              if (Boolean(fAct)) {
                row[column.key] = f.amount + " / " + fAct.amount;
                firstRow[column.key + "actual"] = Boolean(firstRow[column.key + "actual"])
                  ? firstRow[column.key + "actual"] + fAct.amount
                  : fAct.amount;
              } else {
                row[column.key] = f.amount + " / " + 0;
                firstRow[column.key + "actual"] = Boolean(firstRow[column.key + "actual"]) ? firstRow[column.key + "actual"] + 0 : 0;
              }
              firstRow[column.key] = firstRow[column.key + "planned"] + " / " + firstRow[column.key + "actual"];
            } else {
              firstRow[column.key + "planned"] = Boolean(firstRow[column.key + "planned"]) ? firstRow[column.key + "planned"] + 0 : 0;
              firstRow[column.key + "actual"] = Boolean(firstRow[column.key + "actual"]) ? firstRow[column.key + "actual"] + 0 : 0;
              firstRow[column.key] = firstRow[column.key + "planned"] + " / " + firstRow[column.key + "actual"];
            }
          });
          tableList.push(row);
        }
      });
    }
    firstRow.children = tableList;
    return [firstRow];
  };

  generateList = (list, columns, type) => {
    let tableList = [];
    let firstRow = { key: type, name: type };
    if (Boolean(list)) {
      list.forEach(item => {
        if (tableList.findIndex(x => x.name === item.name) === -1) {
          let row = {
            key: item.name,
            name: item.name
          };
          columns.forEach(column => {
            let f = list.find(x => x.name === item.name && x.dateStr === column.key);
            if (Boolean(f)) {
              row[column.key] = f.amount;
              firstRow[column.key] = Boolean(firstRow[column.key]) ? firstRow[column.key] + f.amount : f.amount;
            } else firstRow[column.key] = Boolean(firstRow[column.key]) ? firstRow[column.key] + 0 : 0;
          });
          tableList.push(row);
        }
      });
    }
    firstRow.children = tableList;
    return [firstRow];
  };

  generateTotalRows = (incomeList, outgoingList, columns, name) => {
    let totalRows = [];
    let D = 0;
    let firstRow = { key: "Deviation" + name, name: "Deviation " + name };
    let secondRow = { key: "Ending Cash" + name, name: "Ending Cash " + name };

    columns.forEach(column => {
      let income = Boolean(incomeList[0][column.key]) ? incomeList[0][column.key] : 0;
      let outgoing = Boolean(outgoingList[0][column.key]) ? outgoingList[0][column.key] : 0;
      //if (Boolean(income) && Boolean(outgoing)) {
      let C = income - outgoing;
      firstRow[column.key] = C;
      secondRow[column.key] = C + D;
      D = D + C;
      // }
    });
    totalRows.push(firstRow);
    totalRows.push(secondRow);
    console.log(name, totalRows);
    return totalRows;
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    return (
      <div>
        <Form initialValues={{ remember: false }} onFinish={this.search} onFinishFailed={onFinishFailed} ref={this.formRef}>
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductStatistics.Period" defaultMessage="Period" />}
              initialValue={"DAILY"}
              style={{
                marginBottom: 0
              }}
              name="period"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <Select placeholder="Period" defaultActiveFirstOption allowClear>
                <Option key={"DAILY"} value={"DAILY"}>
                  <FormattedMessage id="period.day" defaultMessage="Day" />
                </Option>
                <Option key={"MONTHLY"} value={"MONTHLY"}>
                  <FormattedMessage id="period.month" defaultMessage="Month" />
                </Option>
                <Option key={"YEARLY"} value={"YEARLY"}>
                  <FormattedMessage id="period.year" defaultMessage="Year" />
                </Option>
              </Select>
            </Form.Item>
          }{" "}
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductStatistics.startdate" defaultMessage="Start Date" />}
              initialValue={moment().subtract(1, "M")}
              style={{
                marginBottom: 0
              }}
              name="startDate"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductStatistics.endDate" defaultMessage="End Date" />}
              style={{
                marginBottom: 0
              }}
              name="endDate"
              initialValue={moment()}
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          }
          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <AntButton type="dashed" htmlType="submit" style={{ width: "100%" }} icon={<SearchOutlined />} size="large">
                <FormattedMessage id="GeneralButtonSearch" defaultMessage="Search" />
              </AntButton>
            </Col>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
          </Row>
        </Form>
        <Row>
          <Col span={8}>
            <h2>
              <div className="float-left font-weight-bold mb-4 mr-2">
                <FormattedMessage id="IncomeLog.planned" defaultMessage="PLANNED" />
              </div>
            </h2>
          </Col>
        </Row>
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            size="small"
            bordered
            scroll={{ y: 600, x: 900 }}
            // scroll={{ x: 900 }}
            columns={this.state.columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
          />
        </div>
        {/* <Row>
          <Col span={8}>
            <h2>
              {" "}
              <div className="float-left font-weight-bold mb-4 mr-2">
                <FormattedMessage id="IncomeLog.acutalized" defaultMessage="ACTUALIZED" />
              </div>
            </h2>
          </Col>
        </Row>
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            size="small"
            bordered
            scroll={{ y: 600, x: 900 }}
            // scroll={{ x: 900 }}
            columns={this.state.columns}
            dataSource={this.state.tableListActualized}
            loading={this.state.loading}
          />
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(CashFlow);
