import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Form, Table, Input, Select, InputNumber, Checkbox, Divider, DatePicker, Button as AntButton, Col, Row } from "antd";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import { PlusOutlined, MinusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { Option } = Select;

export class JobOfferContract extends Component {
  constructor({ jobOfferId, personnelName }) {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      departmentIdSelectItems: null,
      designationIdSelectItems: null,
      contractTypeSelectItems: null,
      contractDurationPeriodSelectItems: null,
      salaryPeriodSelectItems: null,
      employeeInsuranceIdSelectItems: null,
      spouseInsuranceIdSelectItems: null,
      childrenInsuranceIdSelectItems: null,
      startPeriodSelectItems: null,
      annualVacationStartPeriodSelectItems: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      excelData: [],

      jobOfferId: jobOfferId,
      personnelName: personnelName,
      createWhereParams: [
        {
          key: "jobOfferId",
          value: jobOfferId
        }
      ]
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  componentDidUpdate = async nextProps => {
    let id = Boolean(this.props) && Boolean(this.props.jobOfferId) ? this.props.jobOfferId : null;
    if (id !== this.state.jobOfferId) {
      this.setState(
        {
          jobOfferId: this.props.jobOfferId,
          personnelName: this.props.personnelName,
          designationId: null,
          departmentId: null
        },
        () => {
          const { pagination } = this.state;
          this.restartTable({ pagination });
          this.fillComboboxes();
        }
      );
    }
  };

  fillComboboxes = async () => {
    var responsedepartmentId = await handleRequest("GET", "/api/employeeContract/departments");
    if (responsedepartmentId.type === "ERROR") {
      error(responsedepartmentId);
    } else {
      this.setState({
        departmentIdSelectItems: Boolean(responsedepartmentId.data) ? responsedepartmentId.data : []
      });
    }
    var responsedesignationId = await handleRequest("GET", "/api/employeeContract/designations");
    if (responsedesignationId.type === "ERROR") {
      error(responsedesignationId);
    } else {
      this.setState({
        designationIdSelectItems: Boolean(responsedesignationId.data) ? responsedesignationId.data : []
      });
    }
    var responsecontractType = await handleRequest("GET", "/api/employeeContract/contractTypes");
    if (responsecontractType.type === "ERROR") {
      error(responsecontractType);
    } else {
      this.setState({
        contractTypeSelectItems: Boolean(responsecontractType.data) ? responsecontractType.data : []
      });
    }
    var responsecontractDurationPeriod = await handleRequest("GET", "/api/employeeContract/periodTypes");
    if (responsecontractDurationPeriod.type === "ERROR") {
      error(responsecontractDurationPeriod);
    } else {
      this.setState({
        contractDurationPeriodSelectItems: Boolean(responsecontractDurationPeriod.data) ? responsecontractDurationPeriod.data : []
      });
    }
    var responsesalaryPeriod = await handleRequest("GET", "/api/employeeContract/periodBases");
    if (responsesalaryPeriod.type === "ERROR") {
      error(responsesalaryPeriod);
    } else {
      this.setState({
        salaryPeriodSelectItems: Boolean(responsesalaryPeriod.data) ? responsesalaryPeriod.data : []
      });
    }
    var responseemployeeInsuranceId = await handleRequest("GET", "/api/codes/type/employeeInsuranceId");
    if (responseemployeeInsuranceId.type === "ERROR") {
      error(responseemployeeInsuranceId);
    } else {
      this.setState({
        employeeInsuranceIdSelectItems: Boolean(responseemployeeInsuranceId.data) ? responseemployeeInsuranceId.data : [],
        spouseInsuranceIdSelectItems: Boolean(responseemployeeInsuranceId.data) ? responseemployeeInsuranceId.data : [],
        childrenInsuranceIdSelectItems: Boolean(responseemployeeInsuranceId.data) ? responseemployeeInsuranceId.data : []
      });
    }

    var responsestartPeriod = await handleRequest("GET", "/api/employeeContract/annualVacationStartPeriods");
    if (responsestartPeriod.type === "ERROR") {
      error(responsestartPeriod);
    } else {
      this.setState({
        startPeriodSelectItems: Boolean(responsestartPeriod.data) ? responsestartPeriod.data : []
      });
    }

    if (responsecontractDurationPeriod.type === "ERROR") {
      error(responsecontractDurationPeriod);
    } else {
      this.setState({
        annualVacationStartPeriodSelectItems: Boolean(responsecontractDurationPeriod.data) ? responsecontractDurationPeriod.data : []
      });
    }

    var responsepaymentType = await handleRequest("GET", "/api/employeeContract/homeAllowances");
    if (responsepaymentType.type === "ERROR") {
      error(responsepaymentType);
    } else {
      this.setState({
        paymentTypeSelectItems: Boolean(responsepaymentType.data) ? responsepaymentType.data : []
      });
    }
    var responsecompanyAccomadationId = await handleRequest("GET", "/api/codes/type/companyAccomadationId");
    if (responsecompanyAccomadationId.type === "ERROR") {
      error(responsecompanyAccomadationId);
    } else {
      this.setState({
        companyAccomadationIdSelectItems: Boolean(responsecompanyAccomadationId.data) ? responsecompanyAccomadationId.data : []
      });
    }
    var responsepaymentHouseholder = await handleRequest("GET", "/api/employeeContract/allowancePayments");
    if (responsepaymentHouseholder.type === "ERROR") {
      error(responsepaymentHouseholder);
    } else {
      this.setState({
        paymentSelectItems: Boolean(responsepaymentHouseholder.data) ? responsepaymentHouseholder.data : [],
        paymentHouseholderSelectItems: Boolean(responsepaymentHouseholder.data) ? responsepaymentHouseholder.data : [],
        paymentElectricitySelectItems: Boolean(responsepaymentHouseholder.data) ? responsepaymentHouseholder.data : [],
        paymentWaterSelectItems: Boolean(responsepaymentHouseholder.data) ? responsepaymentHouseholder.data : [],
        paymentFurnitureSelectItems: Boolean(responsepaymentHouseholder.data) ? responsepaymentHouseholder.data : []
      });
    }

    var responsepaymentTypeTransportation = await handleRequest("GET", "/api/employeeContract/paymentTypeTransportationAllowance");
    if (responsepaymentTypeTransportation.type === "ERROR") {
      error(responsepaymentTypeTransportation);
    } else {
      this.setState({
        paymentTypeTransportationSelectItems: Boolean(responsepaymentTypeTransportation.data) ? responsepaymentTypeTransportation.data : []
      });
    }

    var responsevehicleClassId = await handleRequest("GET", "/api/codes/type/vehicleClassId");
    if (responsevehicleClassId.type === "ERROR") {
      error(responsevehicleClassId);
    } else {
      this.setState({
        vehicleClassIdSelectItems: Boolean(responsevehicleClassId.data) ? responsevehicleClassId.data : []
      });
    }

    var responsepaymentTypeFood = await handleRequest("GET", "/api/employeeContract/paymentTypeFoodAllowance");
    if (responsepaymentTypeFood.type === "ERROR") {
      error(responsepaymentTypeFood);
    } else {
      this.setState({
        paymentTypeFoodSelectItems: Boolean(responsepaymentTypeFood.data) ? responsepaymentTypeFood.data : []
      });
    }

    var responseFoodClassId = await handleRequest("GET", "/api/codes/type/foodClassId");
    if (responseFoodClassId.type === "ERROR") {
      error(responseFoodClassId);
    } else {
      this.setState({
        foodClassIdSelectItems: Boolean(responseFoodClassId.data) ? responseFoodClassId.data : []
      });
    }

    var responseemployeeFlightStatus = await handleRequest("GET", "/api/employeeContract/flightStatus");
    if (responseemployeeFlightStatus.type === "ERROR") {
      error(responseemployeeFlightStatus);
    } else {
      this.setState({
        employeeFlightStatusSelectItems: Boolean(responseemployeeFlightStatus.data) ? responseemployeeFlightStatus.data : [],
        spouseFlightStatusSelectItems: Boolean(responseemployeeFlightStatus.data) ? responseemployeeFlightStatus.data : [],
        childrenflightStatusSelectItems: Boolean(responseemployeeFlightStatus.data) ? responseemployeeFlightStatus.data : []
      });
    }
    var responseeducationTypeId = await handleRequest("GET", "/api/codes/type/educationTypeId");
    if (responseeducationTypeId.type === "ERROR") {
      error(responseeducationTypeId);
    } else {
      this.setState({
        educationTypeIdSelectItems: Boolean(responseeducationTypeId.data) ? responseeducationTypeId.data : []
      });
    }
    var responseeducationPayment = await handleRequest("GET", "/api/employeeContract/educationPayments");
    if (responseeducationPayment.type === "ERROR") {
      error(responseeducationPayment);
    } else {
      this.setState({
        educationPaymentSelectItems: Boolean(responseeducationPayment.data) ? responseeducationPayment.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: [],
      designationId: null,
      departmentId: null,
      personnelContractFoodAllowance: null,
      personnelContractTransportationAllowance: null,
      personnelContractHomeAllowance: null,
      personnelContractFlightAllowance: null,
      homeAllowance: null,
      transportationAllowance: null,
      foodAllowance: null,
      flightAllowance: null,
      educationSupport: null
    });
  };

  getContractByDeparmentAndDesignation = async () => {
    const { departmentId, designationId } = this.state;
    if (Boolean(departmentId) && Boolean(designationId)) {
      var response = await handleRequest("POST", "/api/employeeContract/getContractByDeparmentAndDesignation", {
        departmentId: departmentId,
        designationId: designationId
      });
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          contractSettings: null
        });
      } else {
        let row = response.data;
        if (row == null) {
          this.setState({
            contractSettings: null
          });

          showWarning("There is no defined contract settings for selected department and designation.");
        } else {
          this.setState({
            contractSettings: row
          });
          this.editContract(row);
        }
      }
    }
  };

  editContract = row => {
    if (this.state.id === null) {
      document.getElementById("kt_scrolltop").click();

      this.formRef.current.setFieldsValue({
        ...row,
        effectiveDate: Boolean(row.effectiveDate) ? moment(row.effectiveDate) : null,
        personnelContractFoodAllowance: row.employeeContractFoodAllowance,
        personnelContractTransportationAllowance: row.employeeContractTransportationAllowance,
        personnelContractHomeAllowance: row.employeeContractHomeAllowance,
        personnelContractFlightAllowance: row.employeeContractFlightAllowance,
        familyStatus: false,
        spouse: false,
        childreen: false,
        numberOfChildren: 0,
        iqama: false,
        iqamaSpouse: false,
        iqamaChildren: false,
        iqamaNumberOfChildren: 0
      });

      this.setState({
        personnelContractFoodAllowance: row.employeeContractFoodAllowance,
        personnelContractTransportationAllowance: row.employeeContractTransportationAllowance,
        personnelContractHomeAllowance: row.employeeContractHomeAllowance,
        personnelContractFlightAllowance: row.employeeContractFlightAllowance,
        homeAllowance: row.homeAllowance,
        transportationAllowance: row.transportationAllowance,
        foodAllowance: row.foodAllowance,
        flightAllowance: row.flightAllowance,
        educationSupport: row.educationSupport,
        familyStatus: false,
        spouse: false,
        childreen: false,
        numberOfChildren: 0,
        iqama: false,
        iqamaSpouse: false,
        iqamaChildren: false,
        iqamaNumberOfChildren: 0
      });
    }
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    this.formRef.current.setFieldsValue({
      ...row,
      effectiveDate: Boolean(row.effectiveDate) ? moment(row.effectiveDate) : null
    });

    this.setState(
      {
        hideUpdate: false,
        hideSave: true,
        hideInputs: false,
        id: row.id,
        personnelContractFoodAllowance: row.personnelContractFoodAllowance,
        personnelContractTransportationAllowance: row.personnelContractTransportationAllowance,
        personnelContractHomeAllowance: row.personnelContractHomeAllowance,
        personnelContractFlightAllowance: row.personnelContractFlightAllowance,
        homeAllowance: row.homeAllowance,
        transportationAllowance: row.transportationAllowance,
        foodAllowance: row.foodAllowance,
        flightAllowance: row.flightAllowance,
        educationSupport: row.educationSupport,
        departmentId: row.departmentId,
        designationId: row.designationId,
        familyStatus: row.familyStatus,
        spouse: row.spouse,
        childreen: row.childreen,
        numberOfChildren: row.numberOfChildren,
        iqama: row.iqama,
        iqamaSpouse: row.iqamaSpouse,
        iqamaChildren: row.iqamaChildren,
        iqamaNumberOfChildren: row.iqamaNumberOfChildren
      },
      this.getContractByDeparmentAndDesignation
    );
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/personnelContract/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/personnelContract/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        console.log(list);
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const { contractSettings } = this.state;
    const newItem = { ...values, id: this.state.id, jobOfferId: this.state.jobOfferId };

    if (Boolean(newItem.basicSalary)) {
      if (newItem.basicSalary < contractSettings.salaryRangeFrom) {
        showWarning("Basic salary should be more than or equal to " + contractSettings.salaryRangeFrom);
        return;
      }
      if (newItem.basicSalary > contractSettings.salaryRangeTo) {
        showWarning("Basic salary should be less than or equal to " + contractSettings.salaryRangeTo);
        return;
      }
    } else {
      showError("Please type salary!");
      return;
    }

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/personnelContract", newItem);
    } else {
      response = await handleRequest("PUT", "/api/personnelContract/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      jobOfferId: this.state.jobOfferId,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]

            companyId: "14",
            projectId: "13",
            departmentId: element["Department"],
            designationId: element["Designation"],
            basicSalary: element["Maaş"]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/employeeContract/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  hideForm = (key, value) => {
    let keys = key.split(".");
    if (keys.length === 1) {
      return !(Boolean(this.state[keys[0]]) && this.state[keys[0]] === value);
    } else if (keys.length > 1) {
      return !(Boolean(this.state[keys[0]]) && Boolean(this.state[keys[0]][keys[1]]) && this.state[keys[0]][keys[1]] === value);
    }

    return true;
  };

  minSalary = () => {
    console.log("minSalary");
    const { contractSettings } = this.state;

    if (Boolean(contractSettings) && Boolean(contractSettings.salaryRangeFrom)) {
      return contractSettings.salaryRangeFrom;
    }
    return 0;
  };

  maxSalary = () => {
    console.log("maxSalary");
    const { contractSettings } = this.state;
    if (Boolean(contractSettings) && Boolean(contractSettings.salaryRangeTo)) {
      return contractSettings.salaryRangeTo;
    }
    return null;
  };

  salaryExtra = () => {
    console.log("salaryExtra");
    const { contractSettings } = this.state;
    if (Boolean(contractSettings) && Boolean(contractSettings.salaryRangeFrom) && Boolean(contractSettings.salaryRangeTo)) {
      return "(" + contractSettings.salaryRangeFrom + " - " + contractSettings.salaryRangeTo + ")";
    }
    return "";
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 16,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="EmployeeContract.departmentId" defaultMessage="Department" />,
        key: "departmentId",
        render: record => {
          if (Boolean(record.department)) return record.department.departmentName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.departmentId) ? this.state.pagination.departmentId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  departmentId: e.target.value,
                  departmentIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.departmentIdColor) ? this.state.pagination.departmentIdColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="EmployeeContract.designationId" defaultMessage="Designation" />,
        key: "designationId",
        render: record => {
          if (Boolean(record.designation)) return record.designation.designation;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.designationId) ? this.state.pagination.designationId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  designationId: e.target.value,
                  designationIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.designationIdColor) ? this.state.pagination.designationIdColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="EmployeeContract.effectiveDate" defaultMessage="Effective Date" />,
        key: "effectiveDate",
        render: record => {
          if (Boolean(record.effectiveDate)) return moment(record.effectiveDate).format("DD-MM-YYYY");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.effectiveDate) ? this.state.pagination.effectiveDate : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  effectiveDate: e.target.value,
                  effectiveDateColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.effectiveDateColor) ? this.state.pagination.effectiveDateColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },
      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
              exportClick: false
            })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <span className="h3 header">{"Personnel:" + this.state.personnelName}</span>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={21}></Col>
            <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EmployeeContract.departmentId" defaultMessage="Department" />}
                name="departmentId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Department"
                  onChange={value =>
                    this.setState(
                      {
                        departmentId: value
                      },
                      () => {
                        this.getContractByDeparmentAndDesignation();
                      }
                    )
                  }
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["departmentIdSelectItems"]) &&
                    this.state["departmentIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EmployeeContract.designationId" defaultMessage="Designation" />}
                name="designationId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Designation"
                  onChange={value =>
                    this.setState(
                      {
                        designationId: value
                      },
                      () => {
                        this.getContractByDeparmentAndDesignation();
                      }
                    )
                  }
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["designationIdSelectItems"]) &&
                    this.state["designationIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            <div hidden={!Boolean(this.state.contractSettings)}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EmployeeContract.effectiveDate" defaultMessage="Effective Date" />}
                  name="effectiveDate"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <DatePicker format="DD-MM-YYYY" />
                </Form.Item>
              }{" "}
              {
                <Row gutter={[16, 16]}>
                  <Col md={4}></Col>
                  <Col md={4}>
                    <>
                      <AntButton
                        style={{ width: "100%" }}
                        className="mb-3"
                        type="dashed"
                        hidden={Boolean(this.state.contractButton) && this.state.contractButton}
                        icon={<PlusOutlined />}
                        size="large"
                        onClick={() => {
                          this.setState({
                            contractButton: true
                          });
                        }}
                      >
                        {"Contract"}
                      </AntButton>
                      <AntButton
                        style={{ width: "100%" }}
                        className="mb-3"
                        type="dashed"
                        hidden={!(Boolean(this.state.contractButton) && this.state.contractButton)}
                        icon={<MinusOutlined />}
                        size="large"
                        onClick={() => {
                          this.setState({
                            contractButton: false
                          });
                        }}
                      >
                        {"Contract"}
                        {/* {<FormattedMessage id="EmployeeContract.ContractButton" defaultMessage="Contract" />} */}
                      </AntButton>
                    </>
                  </Col>
                </Row>
              }
              <div hidden={!(Boolean(this.state.contractButton) && this.state.contractButton)}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeContract.contractType" defaultMessage="Contract Type" />}
                    name="contractType"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select disabled style={{ width: "100%" }} placeholder="Contract Type">
                      <Option key={null} value={null}>
                        Select
                      </Option>
                      {Boolean(this.state["contractTypeSelectItems"]) &&
                        this.state["contractTypeSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }{" "}
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeContract.contractDuration" defaultMessage="Contract Duration" />}
                    name="contractDuration"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <InputNumber disabled placeholder="Contract Duration" min={0} />
                  </Form.Item>
                }{" "}
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeContract.contractDurationPeriod" defaultMessage="Contract Duration Period" />}
                    name="contractDurationPeriod"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select disabled style={{ width: "100%" }} placeholder="Contract Duration Period">
                      <Option key={null} value={null}>
                        Select
                      </Option>
                      {Boolean(this.state["contractDurationPeriodSelectItems"]) &&
                        this.state["contractDurationPeriodSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }{" "}
              </div>
              {
                <Row gutter={[16, 16]}>
                  <Col md={4}></Col>
                  <Col md={4}>
                    <>
                      <AntButton
                        style={{ width: "100%" }}
                        className="mb-3"
                        type="dashed"
                        hidden={Boolean(this.state.salaryButton) && this.state.salaryButton}
                        icon={<PlusOutlined />}
                        size="large"
                        onClick={() => {
                          this.setState({
                            salaryButton: true
                          });
                        }}
                      >
                        {"Salary"}
                      </AntButton>
                      <AntButton
                        style={{ width: "100%" }}
                        className="mb-3"
                        type="dashed"
                        hidden={!(Boolean(this.state.salaryButton) && this.state.salaryButton)}
                        icon={<MinusOutlined />}
                        size="large"
                        onClick={() => {
                          this.setState({
                            salaryButton: false
                          });
                        }}
                      >
                        {"Salary"}
                        {/* {<FormattedMessage id="EmployeeContract.ContractButton" defaultMessage="Contract" />} */}
                      </AntButton>
                    </>
                  </Col>
                </Row>
              }
              <div hidden={!(Boolean(this.state.salaryButton) && this.state.salaryButton)}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeContract.basicSalary" defaultMessage="Salary" />}
                    name="basicSalary"
                    extra={this.salaryExtra}
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <InputNumber className="w-100" placeholder="Salary" min={this.minSalary} max={this.maxSalary} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeContract.salaryPeriod" defaultMessage="Salary Period" />}
                    name="salaryPeriod"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select disabled style={{ width: "100%" }} placeholder="Salary Period">
                      <Option key={null} value={null}>
                        Select
                      </Option>
                      {Boolean(this.state["salaryPeriodSelectItems"]) &&
                        this.state["salaryPeriodSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }{" "}
              </div>
              <div hidden={!(Boolean(this.state.contractSettings) && this.state.contractSettings.familyStatus)}>
                {
                  <Row gutter={[16, 16]}>
                    <Col md={4}></Col>
                    <Col md={4}>
                      <>
                        <AntButton
                          style={{ width: "100%" }}
                          className="mb-3"
                          type="dashed"
                          hidden={Boolean(this.state.familyButton) && this.state.familyButton}
                          icon={<PlusOutlined />}
                          size="large"
                          onClick={() => {
                            this.setState({
                              familyButton: true
                            });
                          }}
                        >
                          {"Family"}
                        </AntButton>
                        <AntButton
                          style={{ width: "100%" }}
                          className="mb-3"
                          type="dashed"
                          hidden={!(Boolean(this.state.familyButton) && this.state.familyButton)}
                          icon={<MinusOutlined />}
                          size="large"
                          onClick={() => {
                            this.setState({
                              familyButton: false
                            });
                          }}
                        >
                          {"Family"}
                          {/* {<FormattedMessage id="EmployeeContract.ContractButton" defaultMessage="Contract" />} */}
                        </AntButton>
                      </>
                    </Col>
                  </Row>
                }
                <div hidden={!(Boolean(this.state.familyButton) && this.state.familyButton)}>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EmployeeContract.familyStatus" defaultMessage="Family Status" />}
                      name="familyStatus"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={e => {
                          this.setState({
                            familyStatus: e.target.checked
                          });
                        }}
                      >
                        {" "}
                        <FormattedMessage id="EmployeeContract.familyStatus" defaultMessage="Family Status" />{" "}
                      </Checkbox>
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      {...layout}
                      hidden={!this.state.familyStatus}
                      label={<FormattedMessage id="EmployeeContract.spouse" defaultMessage="Spouse" />}
                      name="spouse"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={e => {
                          this.setState({
                            spouse: e.target.checked
                          });
                        }}
                      >
                        {" "}
                        <FormattedMessage id="EmployeeContract.spouse" defaultMessage="Spouse" />{" "}
                      </Checkbox>
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EmployeeContract.childreen" defaultMessage="Childreen" />}
                      name="childreen"
                      hidden={!this.state.familyStatus}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={e => {
                          this.setState({
                            childreen: e.target.checked
                          });
                        }}
                      >
                        {" "}
                        <FormattedMessage id="EmployeeContract.childreen" defaultMessage="Childreen" />{" "}
                      </Checkbox>
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      {...layout}
                      hidden={!(this.state.familyStatus && this.state.childreen)}
                      label={<FormattedMessage id="EmployeeContract.numberOfChildren" defaultMessage="Number Of Children" />}
                      name="numberOfChildren"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber placeholder="Number Of Children" min={0} />
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EmployeeContract.iqama" defaultMessage="Iqama" />}
                      name="iqama"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={e => {
                          this.setState({
                            iqama: e.target.checked
                          });
                        }}
                      >
                        {" "}
                        <FormattedMessage id="EmployeeContract.iqama" defaultMessage="Iqama" />{" "}
                      </Checkbox>
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      {...layout}
                      hidden={!(this.state.familyStatus && this.state.spouse)}
                      label={<FormattedMessage id="EmployeeContract.iqamaSpouse" defaultMessage="Iqama Spouse" />}
                      name="iqamaSpouse"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      valuePropName="checked"
                    >
                      <Checkbox>
                        {" "}
                        <FormattedMessage id="EmployeeContract.iqamaSpouse" defaultMessage="Iqama Spouse" />{" "}
                      </Checkbox>
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      {...layout}
                      hidden={!(this.state.familyStatus && this.state.childreen)}
                      label={<FormattedMessage id="EmployeeContract.iqamaChildren" defaultMessage="Iqama Children" />}
                      name="iqamaChildren"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={e => {
                          this.setState({
                            iqamaChildren: e.target.checked
                          });
                        }}
                      >
                        {" "}
                        <FormattedMessage id="EmployeeContract.iqamaChildren" defaultMessage="Iqama Children" />{" "}
                      </Checkbox>
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      {...layout}
                      hidden={!(this.state.familyStatus && this.state.childreen && this.state.iqamaChildren)}
                      label={<FormattedMessage id="EmployeeContract.iqamaNumberOfChildren" defaultMessage="Iqama Number Of Children" />}
                      name="iqamaNumberOfChildren"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber placeholder="Iqama Number Of Children" min={0} />
                    </Form.Item>
                  }{" "}
                </div>
              </div>
              {
                <Row gutter={[16, 16]}>
                  <Col md={4}></Col>
                  <Col md={4}>
                    <>
                      <AntButton
                        style={{ width: "100%" }}
                        className="mb-3"
                        type="dashed"
                        hidden={Boolean(this.state.insuranceButton) && this.state.insuranceButton}
                        icon={<PlusOutlined />}
                        size="large"
                        onClick={() => {
                          this.setState({
                            insuranceButton: true
                          });
                        }}
                      >
                        {"Insurance"}
                      </AntButton>
                      <AntButton
                        style={{ width: "100%" }}
                        className="mb-3"
                        type="dashed"
                        hidden={!(Boolean(this.state.insuranceButton) && this.state.insuranceButton)}
                        icon={<MinusOutlined />}
                        size="large"
                        onClick={() => {
                          this.setState({
                            insuranceButton: false
                          });
                        }}
                      >
                        {"Insurance"}
                        {/* {<FormattedMessage id="EmployeeContract.ContractButton" defaultMessage="Contract" />} */}
                      </AntButton>
                    </>
                  </Col>
                </Row>
              }
              <div hidden={!(Boolean(this.state.insuranceButton) && this.state.insuranceButton)}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeContract.employeeInsuranceId" defaultMessage="Employee Insurance" />}
                    name="employeeInsuranceId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      disabled
                      style={{ width: "100%" }}
                      placeholder="Employee Insurance"
                      dropdownRender={menu => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                            <Input
                              style={{ flex: "auto" }}
                              value={this.state.codeName}
                              onChange={event => {
                                this.setState({
                                  codeName: event.target.value
                                });
                              }}
                            />
                            <a
                              style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                              onClick={() => this.addCode("employeeInsuranceId")}
                            >
                              <PlusOutlined /> Add item
                            </a>
                          </div>
                        </div>
                      )}
                    >
                      <Option key={null} value={null}>
                        Select
                      </Option>
                      {Boolean(this.state["employeeInsuranceIdSelectItems"]) &&
                        this.state["employeeInsuranceIdSelectItems"].map(i => (
                          <Option key={i.id} value={i.id}>
                            {i.name}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }{" "}
                {
                  <Form.Item
                    {...layout}
                    hidden={!(this.state.familyStatus && this.state.spouse)}
                    label={<FormattedMessage id="EmployeeContract.spouseInsuranceId" defaultMessage="Spouse Insurance" />}
                    name="spouseInsuranceId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      disabled
                      style={{ width: "100%" }}
                      placeholder="Spouse Insurance"
                      dropdownRender={menu => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                            <Input
                              style={{ flex: "auto" }}
                              value={this.state.codeName}
                              onChange={event => {
                                this.setState({
                                  codeName: event.target.value
                                });
                              }}
                            />
                            <a
                              style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                              onClick={() => this.addCode("spouseInsuranceId")}
                            >
                              <PlusOutlined /> Add item
                            </a>
                          </div>
                        </div>
                      )}
                    >
                      <Option key={null} value={null}>
                        Select
                      </Option>
                      {Boolean(this.state["spouseInsuranceIdSelectItems"]) &&
                        this.state["spouseInsuranceIdSelectItems"].map(i => (
                          <Option key={i.id} value={i.id}>
                            {i.name}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }{" "}
                {
                  <Form.Item
                    {...layout}
                    hidden={!(this.state.familyStatus && this.state.childreen)}
                    label={<FormattedMessage id="EmployeeContract.childrenInsuranceId" defaultMessage="Children Insurance" />}
                    name="childrenInsuranceId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      disabled
                      placeholder="Children Insurance"
                      dropdownRender={menu => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                            <Input
                              style={{ flex: "auto" }}
                              value={this.state.codeName}
                              onChange={event => {
                                this.setState({
                                  codeName: event.target.value
                                });
                              }}
                            />
                            <a
                              style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                              onClick={() => this.addCode("childrenInsuranceId")}
                            >
                              <PlusOutlined /> Add item
                            </a>
                          </div>
                        </div>
                      )}
                    >
                      <Option key={null} value={null}>
                        Select
                      </Option>
                      {Boolean(this.state["childrenInsuranceIdSelectItems"]) &&
                        this.state["childrenInsuranceIdSelectItems"].map(i => (
                          <Option key={i.id} value={i.id}>
                            {i.name}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }{" "}
              </div>
              {
                <Row gutter={[16, 16]}>
                  <Col md={4}></Col>
                  <Col md={4}>
                    <>
                      <AntButton
                        style={{ width: "100%" }}
                        className="mb-3"
                        type="dashed"
                        hidden={Boolean(this.state.annualVacationButton) && this.state.annualVacationButton}
                        icon={<PlusOutlined />}
                        size="large"
                        onClick={() => {
                          this.setState({
                            annualVacationButton: true
                          });
                        }}
                      >
                        {"Annual Vacation"}
                      </AntButton>
                      <AntButton
                        style={{ width: "100%" }}
                        className="mb-3"
                        type="dashed"
                        hidden={!(Boolean(this.state.annualVacationButton) && this.state.annualVacationButton)}
                        icon={<MinusOutlined />}
                        size="large"
                        onClick={() => {
                          this.setState({
                            annualVacationButton: false
                          });
                        }}
                      >
                        {"Annual Vacation"}
                        {/* {<FormattedMessage id="EmployeeContract.ContractButton" defaultMessage="Contract" />} */}
                      </AntButton>
                    </>
                  </Col>
                </Row>
              }
              <div hidden={!(Boolean(this.state.annualVacationButton) && this.state.annualVacationButton)}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeContract.annualVacationDays" defaultMessage="Annual Vacation Days" />}
                    name="annualVacationDays"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <InputNumber disabled placeholder="Annual Vacation Days" min={0} />
                  </Form.Item>
                }{" "}
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeContract.startPeriod" defaultMessage="Start Period" />}
                    name="startPeriod"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select disabled style={{ width: "100%" }} placeholder="Start Period">
                      <Option key={null} value={null}>
                        Select
                      </Option>
                      {Boolean(this.state["startPeriodSelectItems"]) &&
                        this.state["startPeriodSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }{" "}
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeContract.annualVacationStartPoint" defaultMessage="Annual Vacation Start Point" />}
                    name="annualVacationStartPoint"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <InputNumber disabled placeholder="Annual Vacation Start Point" min={0} />
                  </Form.Item>
                }{" "}
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeContract.annualVacationStartPeriod" defaultMessage="Annual VacationStart Period" />}
                    name="annualVacationStartPeriod"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select disabled style={{ width: "100%" }} placeholder="Annual VacationStart Period">
                      <Option key={null} value={null}>
                        Select
                      </Option>
                      {Boolean(this.state["annualVacationStartPeriodSelectItems"]) &&
                        this.state["annualVacationStartPeriodSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }{" "}
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeContract.leaveDaysCompensation" defaultMessage="Leave Days Compensation" />}
                    name="leaveDaysCompensation"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox disabled>
                      {" "}
                      <FormattedMessage id="EmployeeContract.leaveDaysCompensation" defaultMessage="Leave Days Compensation" />{" "}
                    </Checkbox>
                  </Form.Item>
                }{" "}
              </div>
              {
                <Row gutter={[16, 16]}>
                  <Col md={4}></Col>
                  <Col md={4}>
                    <>
                      <AntButton
                        style={{ width: "100%" }}
                        className="mb-3"
                        type="dashed"
                        hidden={Boolean(this.state.overtimeButton) && this.state.overtimeButton}
                        icon={<PlusOutlined />}
                        size="large"
                        onClick={() => {
                          this.setState({
                            overtimeButton: true
                          });
                        }}
                      >
                        {"Overtime"}
                      </AntButton>
                      <AntButton
                        style={{ width: "100%" }}
                        className="mb-3"
                        type="dashed"
                        hidden={!(Boolean(this.state.overtimeButton) && this.state.overtimeButton)}
                        icon={<MinusOutlined />}
                        size="large"
                        onClick={() => {
                          this.setState({
                            overtimeButton: false
                          });
                        }}
                      >
                        {"Overtime"}
                        {/* {<FormattedMessage id="EmployeeContract.ContractButton" defaultMessage="Contract" />} */}
                      </AntButton>
                    </>
                  </Col>
                </Row>
              }
              <div hidden={!(Boolean(this.state.overtimeButton) && this.state.overtimeButton)}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeContract.overtimeAllowed" defaultMessage="Overtime Allowed" />}
                    name="overtimeAllowed"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox disabled>
                      {" "}
                      <FormattedMessage id="EmployeeContract.overtimeAllowed" defaultMessage="Overtime Allowed" />{" "}
                    </Checkbox>
                  </Form.Item>
                }{" "}
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeContract.regularOvertime" defaultMessage="Requalar overtime hours / day" />}
                    name="regularOvertime"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <InputNumber disabled placeholder="Requalar overtime hours / day" min={0} />
                  </Form.Item>
                }{" "}
              </div>
              {
                <Row gutter={[16, 16]}>
                  <Col md={4}></Col>
                  <Col md={4}>
                    <>
                      <AntButton
                        style={{ width: "100%" }}
                        className="mb-3"
                        type="dashed"
                        hidden={Boolean(this.state.allowancesButton) && this.state.allowancesButton}
                        icon={<PlusOutlined />}
                        size="large"
                        onClick={() => {
                          this.setState({
                            allowancesButton: true
                          });
                        }}
                      >
                        {"Allowances"}
                      </AntButton>
                      <AntButton
                        style={{ width: "100%" }}
                        className="mb-3"
                        type="dashed"
                        hidden={!(Boolean(this.state.allowancesButton) && this.state.allowancesButton)}
                        icon={<MinusOutlined />}
                        size="large"
                        onClick={() => {
                          this.setState({
                            allowancesButton: false
                          });
                        }}
                      >
                        {"Allowances"}
                        {/* {<FormattedMessage id="EmployeeContract.ContractButton" defaultMessage="Contract" />} */}
                      </AntButton>
                    </>
                  </Col>
                </Row>
              }
              <div hidden={!(Boolean(this.state.allowancesButton) && this.state.allowancesButton)}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeContract.homeAllowance" defaultMessage="Home Allowance" />}
                    name="homeAllowance"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox
                      disabled
                      onChange={e => {
                        this.setState({
                          homeAllowance: e.target.checked
                        });
                      }}
                    >
                      {" "}
                      <FormattedMessage id="EmployeeContract.homeAllowance" defaultMessage="Home Allowance" />{" "}
                    </Checkbox>
                  </Form.Item>
                }{" "}
                <div
                  hidden={
                    !(
                      Boolean(this.state.allowancesButton) &&
                      this.state.allowancesButton &&
                      Boolean(this.state.homeAllowance) &&
                      this.state.homeAllowance
                    )
                  }
                >
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EmployeeContractHomeAllowance.paymentType" defaultMessage="Payment Type" />}
                      name={["personnelContractHomeAllowance", "paymentType"]}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select
                        disabled
                        style={{ width: "100%" }}
                        placeholder="Payment Type"
                        onChange={value => {
                          console.log(value);
                          let personnelContractHomeAllowance = { paymentType: value };
                          this.setState({
                            personnelContractHomeAllowance
                          });
                        }}
                      >
                        <Option key={null} value={null}>
                          Select
                        </Option>
                        {Boolean(this.state["paymentTypeSelectItems"]) &&
                          this.state["paymentTypeSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      hidden={this.hideForm("personnelContractHomeAllowance.paymentType", "DIRECT_PAYMENT")}
                      {...layout}
                      label={<FormattedMessage id="EmployeeContractHomeAllowance.payment" defaultMessage="Payment" />}
                      name={["personnelContractHomeAllowance", "payment"]}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select disabled style={{ width: "100%" }} placeholder="Payment">
                        <Option key={null} value={null}>
                          Select
                        </Option>
                        {Boolean(this.state["paymentSelectItems"]) &&
                          this.state["paymentSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      hidden={this.hideForm("personnelContractHomeAllowance.paymentType", "DIRECT_PAYMENT")}
                      {...layout}
                      label={<FormattedMessage id="EmployeeContractHomeAllowance.paymentAmount" defaultMessage="Payment Amount" />}
                      name={["personnelContractHomeAllowance", "paymentAmount"]}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber disabled placeholder="Payment Amount" min={0} />
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      hidden={this.hideForm("personnelContractHomeAllowance.paymentType", "COMPANY_ACCOMADATION")}
                      {...layout}
                      label={<FormattedMessage id="EmployeeContractHomeAllowance.companyAccomadationId" defaultMessage="Company Accomadation" />}
                      name={["personnelContractHomeAllowance", "companyAccomadationId"]}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select
                        disabled
                        style={{ width: "100%" }}
                        placeholder="Company Accomadation"
                        dropdownRender={menu => (
                          <div>
                            {menu}
                            <Divider style={{ margin: "4px 0" }} />
                            <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                              <Input
                                style={{ flex: "auto" }}
                                value={this.state.codeName}
                                onChange={event => {
                                  this.setState({
                                    codeName: event.target.value
                                  });
                                }}
                              />
                              <a
                                style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                onClick={() => this.addCode("companyAccomadationId")}
                              >
                                <PlusOutlined /> Add item
                              </a>
                            </div>
                          </div>
                        )}
                      >
                        <Option key={null} value={null}>
                          Select
                        </Option>
                        {Boolean(this.state["companyAccomadationIdSelectItems"]) &&
                          this.state["companyAccomadationIdSelectItems"].map(i => (
                            <Option key={i.id} value={i.id}>
                              {i.name}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      hidden={this.hideForm("personnelContractHomeAllowance.paymentType", "RENT_OF_HOUSE")}
                      {...layout}
                      label={<FormattedMessage id="EmployeeContractHomeAllowance.paymentHouseholder" defaultMessage="Payment House Holder" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Row gutter={2}>
                        <Col span={11}>
                          <Form.Item name={["personnelContractHomeAllowance", "paymentHouseholder"]}>
                            <Select disabled style={{ width: "100%" }} placeholder="Payment House Holder">
                              <Option key={null} value={null}>
                                Select
                              </Option>
                              {Boolean(this.state["paymentHouseholderSelectItems"]) &&
                                this.state["paymentHouseholderSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}{" "}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                          <Form.Item
                            {...layout}
                            name={["personnelContractHomeAllowance", "paymentAmountHouseholder"]}
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                          >
                            <InputNumber disabled placeholder="Amount" min={0} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      hidden={this.hideForm("personnelContractHomeAllowance.paymentType", "RENT_OF_HOUSE")}
                      {...layout}
                      label={<FormattedMessage id="EmployeeContractHomeAllowance.paymentElectricity" defaultMessage="Payment Electricity" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Row gutter={2}>
                        <Col span={11}>
                          <Form.Item name={["personnelContractHomeAllowance", "paymentElectricity"]}>
                            <Select disabled style={{ width: "100%" }} placeholder="Payment Electricity">
                              <Option key={null} value={null}>
                                Select
                              </Option>
                              {Boolean(this.state["paymentElectricitySelectItems"]) &&
                                this.state["paymentElectricitySelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}{" "}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                          <Form.Item
                            {...layout}
                            name={["personnelContractHomeAllowance", "paymentAmountElectricity"]}
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                          >
                            <InputNumber disabled placeholder="Amount" min={0} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      hidden={this.hideForm("personnelContractHomeAllowance.paymentType", "RENT_OF_HOUSE")}
                      {...layout}
                      label={<FormattedMessage id="EmployeeContractHomeAllowance.paymentWater" defaultMessage="Payment Water" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Row gutter={2}>
                        <Col span={11}>
                          <Form.Item name={["personnelContractHomeAllowance", "paymentWater"]}>
                            <Select disabled style={{ width: "100%" }} placeholder="Payment Water">
                              <Option key={null} value={null}>
                                Select
                              </Option>
                              {Boolean(this.state["paymentWaterSelectItems"]) &&
                                this.state["paymentWaterSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}{" "}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                          <Form.Item
                            {...layout}
                            name={["personnelContractHomeAllowance", "paymentAmountWater"]}
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                          >
                            <InputNumber disabled placeholder="Amount" min={0} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      hidden={this.hideForm("personnelContractHomeAllowance.paymentType", "RENT_OF_HOUSE")}
                      {...layout}
                      label={<FormattedMessage id="EmployeeContractHomeAllowance.paymentFurniture" defaultMessage="Payment Furniture" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Row gutter={2}>
                        <Col span={11}>
                          <Form.Item name={["personnelContractHomeAllowance", "paymentFurniture"]}>
                            <Select disabled style={{ width: "100%" }} placeholder="Payment Furniture">
                              <Option key={null} value={null}>
                                Select
                              </Option>
                              {Boolean(this.state["paymentFurnitureSelectItems"]) &&
                                this.state["paymentFurnitureSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}{" "}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                          <Form.Item
                            {...layout}
                            name={["personnelContractHomeAllowance", "paymentAmountFurniture"]}
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                          >
                            <InputNumber disabled placeholder="Amount" min={0} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  }{" "}
                </div>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeContract.transportationAllowance" defaultMessage="Transportation Allowance" />}
                    name="transportationAllowance"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox
                      disabled
                      onChange={e => {
                        this.setState({
                          transportationAllowance: e.target.checked
                        });
                      }}
                    >
                      {" "}
                      <FormattedMessage id="EmployeeContract.transportationAllowance" defaultMessage="Transportation Allowance" />{" "}
                    </Checkbox>
                  </Form.Item>
                }{" "}
                <div
                  hidden={
                    !(
                      Boolean(this.state.allowancesButton) &&
                      this.state.allowancesButton &&
                      Boolean(this.state.transportationAllowance) &&
                      this.state.transportationAllowance
                    )
                  }
                >
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EmployeeContractHomeAllowance.paymentType" defaultMessage="Payment Type" />}
                      name={["personnelContractTransportationAllowance", "paymentType"]}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select
                        disabled
                        style={{ width: "100%" }}
                        placeholder="Payment Type"
                        onChange={value => {
                          console.log(value);
                          let personnelContractTransportationAllowance = { paymentType: value };
                          this.setState({
                            personnelContractTransportationAllowance
                          });
                        }}
                      >
                        <Option key={null} value={null}>
                          Select
                        </Option>
                        {Boolean(this.state["paymentTypeTransportationSelectItems"]) &&
                          this.state["paymentTypeTransportationSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      hidden={this.hideForm("personnelContractTransportationAllowance.paymentType", "DIRECT_PAYMENT")}
                      {...layout}
                      label={<FormattedMessage id="EmployeeContractHomeAllowance.payment" defaultMessage="Payment" />}
                      name={["personnelContractTransportationAllowance", "payment"]}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select disabled style={{ width: "100%" }} placeholder="Payment">
                        <Option key={null} value={null}>
                          Select
                        </Option>
                        {Boolean(this.state["paymentSelectItems"]) &&
                          this.state["paymentSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      hidden={this.hideForm("personnelContractTransportationAllowance.paymentType", "DIRECT_PAYMENT")}
                      {...layout}
                      label={<FormattedMessage id="EmployeeContractHomeAllowance.paymentAmount" defaultMessage="Payment Amount" />}
                      name={["personnelContractTransportationAllowance", "paymentAmount"]}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber disabled placeholder="Payment Amount" min={0} />
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      hidden={this.hideForm("personnelContractTransportationAllowance.paymentType", "COMPANY_VEHICLE")}
                      {...layout}
                      label={<FormattedMessage id="EmployeeContractTransportationAllowance.vehicleClassId" defaultMessage="Vehicle Class" />}
                      name={["personnelContractTransportationAllowance", "vehicleClassId"]}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select
                        disabled
                        style={{ width: "100%" }}
                        placeholder="Vehicle Class"
                        dropdownRender={menu => (
                          <div>
                            {menu}
                            <Divider style={{ margin: "4px 0" }} />
                            <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                              <Input
                                style={{ flex: "auto" }}
                                value={this.state.codeName}
                                onChange={event => {
                                  this.setState({
                                    codeName: event.target.value
                                  });
                                }}
                              />
                              <a
                                style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                onClick={() => this.addCode("vehicleClassId")}
                              >
                                <PlusOutlined /> Add item
                              </a>
                            </div>
                          </div>
                        )}
                      >
                        <Option key={null} value={null}>
                          Select
                        </Option>
                        {Boolean(this.state["vehicleClassIdSelectItems"]) &&
                          this.state["vehicleClassIdSelectItems"].map(i => (
                            <Option key={i.id} value={i.id}>
                              {i.name}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>
                  }
                </div>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeContract.foodAllowance" defaultMessage="Food Allowance" />}
                    name="foodAllowance"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox
                      disabled
                      onChange={e => {
                        this.setState({
                          foodAllowance: e.target.checked
                        });
                      }}
                    >
                      {" "}
                      <FormattedMessage id="EmployeeContract.foodAllowance" defaultMessage="Food Allowance" />{" "}
                    </Checkbox>
                  </Form.Item>
                }{" "}
                <div
                  hidden={
                    !(
                      Boolean(this.state.allowancesButton) &&
                      this.state.allowancesButton &&
                      Boolean(this.state.foodAllowance) &&
                      this.state.foodAllowance
                    )
                  }
                >
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EmployeeContractHomeAllowance.paymentType" defaultMessage="Payment Type" />}
                      name={["personnelContractFoodAllowance", "paymentType"]}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select
                        disabled
                        style={{ width: "100%" }}
                        placeholder="Payment Type"
                        onChange={value => {
                          console.log(value);
                          let personnelContractFoodAllowance = { paymentType: value };
                          this.setState({
                            personnelContractFoodAllowance
                          });
                        }}
                      >
                        <Option key={null} value={null}>
                          Select
                        </Option>
                        {Boolean(this.state["paymentTypeFoodSelectItems"]) &&
                          this.state["paymentTypeFoodSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      hidden={this.hideForm("personnelContractFoodAllowance.paymentType", "DIRECT_PAYMENT")}
                      {...layout}
                      label={<FormattedMessage id="EmployeeContractHomeAllowance.payment" defaultMessage="Payment" />}
                      name={["personnelContractFoodAllowance", "payment"]}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select disabled style={{ width: "100%" }} placeholder="Payment">
                        <Option key={null} value={null}>
                          Select
                        </Option>
                        {Boolean(this.state["paymentSelectItems"]) &&
                          this.state["paymentSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      hidden={this.hideForm("personnelContractFoodAllowance.paymentType", "DIRECT_PAYMENT")}
                      {...layout}
                      label={<FormattedMessage id="EmployeeContractHomeAllowance.paymentAmount" defaultMessage="Payment Amount" />}
                      name={["personnelContractFoodAllowance", "paymentAmount"]}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber disabled placeholder="Payment Amount" min={0} />
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      hidden={this.hideForm("personnelContractFoodAllowance.paymentType", "COMPANY_FOOD")}
                      {...layout}
                      label={<FormattedMessage id="EmployeeContractTransportationAllowance.vehicleClassId" defaultMessage="Food Class" />}
                      name={["personnelContractFoodAllowance", "foodClassId"]}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select
                        disabled
                        style={{ width: "100%" }}
                        placeholder="Food Class"
                        dropdownRender={menu => (
                          <div>
                            {menu}
                            <Divider style={{ margin: "4px 0" }} />
                            <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                              <Input
                                style={{ flex: "auto" }}
                                value={this.state.codeName}
                                onChange={event => {
                                  this.setState({
                                    codeName: event.target.value
                                  });
                                }}
                              />
                              <a
                                style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                onClick={() => this.addCode("foodClassId")}
                              >
                                <PlusOutlined /> Add item
                              </a>
                            </div>
                          </div>
                        )}
                      >
                        <Option key={null} value={null}>
                          Select
                        </Option>
                        {Boolean(this.state["foodClassIdSelectItems"]) &&
                          this.state["foodClassIdSelectItems"].map(i => (
                            <Option key={i.id} value={i.id}>
                              {i.name}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>
                  }
                </div>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeContract.flightAllowance" defaultMessage="Flight Allowance" />}
                    name="flightAllowance"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox
                      disabled
                      onChange={e => {
                        this.setState({
                          flightAllowance: e.target.checked
                        });
                      }}
                    >
                      {" "}
                      <FormattedMessage id="EmployeeContract.flightAllowance" defaultMessage="Flight Allowance" />{" "}
                    </Checkbox>
                  </Form.Item>
                }{" "}
                <div
                  hidden={
                    !(
                      Boolean(this.state.allowancesButton) &&
                      this.state.allowancesButton &&
                      Boolean(this.state.flightAllowance) &&
                      this.state.flightAllowance
                    )
                  }
                >
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EmployeeContractFlightAllowance.employeeFlightStatus" defaultMessage="Employee" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Row gutter={2}>
                        <Col span={11}>
                          <Form.Item name={["personnelContractFlightAllowance", "employeeFlightStatus"]}>
                            <Select disabled style={{ width: "100%" }} placeholder="Employee">
                              <Option key={null} value={null}>
                                Select
                              </Option>
                              {Boolean(this.state["employeeFlightStatusSelectItems"]) &&
                                this.state["employeeFlightStatusSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}{" "}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                          <Form.Item
                            {...layout}
                            name={["personnelContractFlightAllowance", "employeeRoundTrip"]}
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                            valuePropName="checked"
                          >
                            <Checkbox disabled>
                              {" "}
                              <FormattedMessage id="EmployeeContractFlightAllowance.employeeRoundTrip" defaultMessage="Round Trip" />{" "}
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      {...layout}
                      hidden={!(this.state.familyStatus && this.state.spouse)}
                      label={<FormattedMessage id="EmployeeContractFlightAllowance.spouseFlightStatus" defaultMessage="Spouse" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Row gutter={2}>
                        <Col span={11}>
                          <Form.Item name={["personnelContractFlightAllowance", "spouseFlightStatus"]}>
                            <Select disabled style={{ width: "100%" }} placeholder="Spouse">
                              <Option key={null} value={null}>
                                Select
                              </Option>
                              {Boolean(this.state["spouseFlightStatusSelectItems"]) &&
                                this.state["spouseFlightStatusSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}{" "}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                          <Form.Item
                            {...layout}
                            name={["personnelContractFlightAllowance", "spouseRoundTrip"]}
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                            valuePropName="checked"
                          >
                            <Checkbox disabled>
                              {" "}
                              <FormattedMessage id="EmployeeContractFlightAllowance.spouseRoundTrip" defaultMessage="Round Trip" />{" "}
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  }{" "}
                  {
                    <Form.Item
                      {...layout}
                      hidden={!(this.state.familyStatus && this.state.childreen)}
                      label={<FormattedMessage id="EmployeeContractFlightAllowance.childrenflightStatus" defaultMessage="Children" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Row gutter={2}>
                        <Col span={11}>
                          <Form.Item name={["personnelContractFlightAllowance", "childrenflightStatus"]}>
                            <Select disabled style={{ width: "100%" }} placeholder="Children">
                              <Option key={null} value={null}>
                                Select
                              </Option>
                              {Boolean(this.state["childrenflightStatusSelectItems"]) &&
                                this.state["childrenflightStatusSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}{" "}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                          <Form.Item
                            {...layout}
                            name={["personnelContractFlightAllowance", "childrenRoundTrip"]}
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                            valuePropName="checked"
                          >
                            <Checkbox disabled>
                              {" "}
                              <FormattedMessage id="EmployeeContractFlightAllowance.childrenRoundTrip" defaultMessage="Round Trip" />{" "}
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  }{" "}
                </div>
              </div>
              {
                <Row gutter={[16, 16]}>
                  <Col md={4}></Col>
                  <Col md={4}>
                    <>
                      <AntButton
                        style={{ width: "100%" }}
                        className="mb-3"
                        type="dashed"
                        hidden={Boolean(this.state.educationSupportButton) && this.state.educationSupportButton}
                        icon={<PlusOutlined />}
                        size="large"
                        onClick={() => {
                          this.setState({
                            educationSupportButton: true
                          });
                        }}
                      >
                        {"Education Support"}
                      </AntButton>
                      <AntButton
                        style={{ width: "100%" }}
                        className="mb-3"
                        type="dashed"
                        hidden={!(Boolean(this.state.educationSupportButton) && this.state.educationSupportButton)}
                        icon={<MinusOutlined />}
                        size="large"
                        onClick={() => {
                          this.setState({
                            educationSupportButton: false
                          });
                        }}
                      >
                        {"Education Support"}
                        {/* {<FormattedMessage id="EmployeeContract.ContractButton" defaultMessage="Contract" />} */}
                      </AntButton>
                    </>
                  </Col>
                </Row>
              }
              <div hidden={!(Boolean(this.state.educationSupportButton) && this.state.educationSupportButton)}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeContract.educationSupport" defaultMessage="Education Support" />}
                    name="educationSupport"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox
                      disabled
                      onChange={e => {
                        this.setState({
                          educationSupport: e.target.checked
                        });
                      }}
                    >
                      {" "}
                      <FormattedMessage id="EmployeeContract.educationSupport" defaultMessage="Education Support" />{" "}
                    </Checkbox>
                  </Form.Item>
                }
              </div>
              <div
                hidden={
                  !(
                    Boolean(this.state.educationSupportButton) &&
                    this.state.educationSupportButton &&
                    Boolean(this.state.educationSupport) &&
                    this.state.educationSupport
                  )
                }
              >
                {
                  <Form.List name="employeeContractEducationSupports">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(field => (
                          <Form.Item {...tailFormItemLayout}>
                            <Form.Item
                              {...field}
                              style={{
                                display: "inline-block",
                                width: "calc(30%)"
                              }}
                              extra="Education Type"
                              name={[field.name, "educationTypeId"]}
                            >
                              <Select
                                style={{ width: "100%" }}
                                placeholder="Education Type"
                                dropdownRender={menu => (
                                  <div>
                                    {menu}
                                    <Divider style={{ margin: "4px 0" }} />
                                    <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                      <Input
                                        style={{ flex: "auto" }}
                                        value={this.state.codeName}
                                        onChange={event => {
                                          this.setState({
                                            codeName: event.target.value
                                          });
                                        }}
                                      />
                                      <a
                                        style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                        onClick={() => this.addCode("educationTypeId")}
                                      >
                                        <PlusOutlined /> Add item
                                      </a>
                                    </div>
                                  </div>
                                )}
                              >
                                <Option key={null} value={null}>
                                  Select
                                </Option>
                                {Boolean(this.state["educationTypeIdSelectItems"]) &&
                                  this.state["educationTypeIdSelectItems"].map(i => (
                                    <Option key={i.id} value={i.id}>
                                      {i.name}
                                    </Option>
                                  ))}{" "}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...field}
                              style={{
                                display: "inline-block",
                                width: "calc(30%)"
                              }}
                              extra="Payment"
                              name={[field.name, "educationPayment"]}
                            >
                              <Select style={{ width: "100%" }} placeholder="Education Payment">
                                <Option key={null} value={null}>
                                  Select
                                </Option>
                                {Boolean(this.state["educationPaymentSelectItems"]) &&
                                  this.state["educationPaymentSelectItems"].map(i => (
                                    <Option key={i.key} value={i.key}>
                                      {i.value}
                                    </Option>
                                  ))}{" "}
                              </Select>
                            </Form.Item>

                            <Form.Item
                              {...field}
                              style={{
                                display: "inline-block",
                                width: "calc(30%)"
                              }}
                              extra="Amount"
                              name={[field.name, "paymentAmount"]}
                            >
                              <InputNumber placeholder="Amount" min={0} />
                            </Form.Item>

                            <Form.Item {...field} hidden name={[field.name, "id"]}>
                              <InputNumber></InputNumber>
                            </Form.Item>

                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                          </Form.Item>
                        ))}

                        <Form.Item {...tailFormItemLayout}>
                          <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add New
                          </AntButton>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                }
              </div>
            </div>
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(JobOfferContract);
