import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import { showError, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import {
  Form,
  Table,
  Col,
  Row,
  Select,
  Space,
  InputNumber,
  Tooltip,
  Typography,
  Breadcrumb
} from "antd";
import { Input } from "antd";
import { fillFilterOperations } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, } from "@ant-design/icons";
import DraggableModal from "../../../Components/DraggableModal";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const { Text } = Typography;
const { Option } = Select;

export class ProjectsTreeCompanyHeight extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ProjectsTreeCompanyHeight",
      controllerName: "apartment",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      floorHeightList: [],
      loadingButtonSubmit: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      currentRow: 0
    };
  }

  formRefNumberOfFloors = React.createRef();
  formRefSectionHouses = React.createRef();
  formRefFloorHeights = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
    fillFilterOperations();
    this.apartmentsWithHouseNotNull();
  };

  apartmentsWithHouseNotNull = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/apartmentsWithHouseNotNull");
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        tableVisibleModal: true,
        tableList: Boolean(response.data) ? response.data : []
      });
    }
  };

  getNumberOfFloors = async () => {
    this.setState(
      {
        modalFloorHeightsShow: true,
        tableVisibleModal: false
      },
      async () => {
        const { tableList, currentRow } = this.state;

        if (Boolean(tableList) && tableList.length > 0 && tableList.length > currentRow) {
          let row = tableList[currentRow];
          this.setState(
            {
              apartmentTypeName: row.apartmentTypeName,
              section: row.section,
              apartmentTypeId: row.apartmentTypeId,
            },
            async () => {
              let newObj = {
                apartmentTypeId: row.apartmentTypeId,
                section: row.section
              };

              var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/floorHeights", newObj);
              if (response.type === "ERROR") {
                error(response);
              } else {
                let floorHeights = Boolean(response.data) && response.data.length > 0 ? response.data : [];
                this.formRefFloorHeights.current.setFieldsValue({
                  floorHeights: floorHeights
                });
              }
            }
          );
        } else {
          this.setState({
            modalFloorHeightsShow: false
          });
        }
        this.setState({
          loadingButtonSubmit: false
        });
      }
    );
  };

  fillComboboxes = async () => {
    this.fillApartmentTypes("Apartment.ApartmentType");
    this.fillCode("Apartment.TypeOfHouses");

    var responseApartmentHeightUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/apartmentHeightUnit");
    if (responseApartmentHeightUnit.type === "ERROR") {
      error(responseApartmentHeightUnit);
    }

    this.setState({
      apartmentHeightUnitSelectItems: Boolean(responseApartmentHeightUnit.data) ? responseApartmentHeightUnit.data : []
    });
  };

  fillApartmentsWithHouseNotNull = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/apartmentsWithHouseNotNull");
    if (response.type === "ERROR") {
      error(response);
    }

    this.setState({
      apartmentsWithHouseNotNull: Boolean(response.data) ? response.data : []
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/pagingFloor";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: "",
        tableVisibleModal: true
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder,
          tableVisibleModal: true
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: "",
          tableVisibleModal: true
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  resetInputs = () => { };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  calcApartmentsForSectionHouses = async () => {
    const sectionHouses = this.formRefSectionHouses.current.getFieldValue("sectionHouses");
    console.log("sectionHouses", sectionHouses);
    const { apartmentIdSelectItems } = this.state;
    console.log("apartmentIdSelectItems", apartmentIdSelectItems);

    const selectedApartments = [];
    async function fetchSectionData(apartment, sectionHouses, controllerName) {
      console.log("sectionHouses", sectionHouses);

      const currentApartmentId = apartment.key;
      if (currentApartmentId > 0 && Boolean(sectionHouses) && sectionHouses.length > 0) {
        const responseSection = await handleRequest("GET", `/api/${controllerName}/sectionId/${currentApartmentId}`);
        if (responseSection.type === "ERROR") {
          error(responseSection);
        } else {
          const sectionSelectItems = responseSection.data || [];
          console.log("sectionSelectItems", sectionSelectItems);
          if (sectionSelectItems.length > 0) {
            sectionSelectItems.forEach(element => {
              console.log("element", element);
              const row = sectionHouses.find(
                p => Boolean(p) && Boolean(p.apartmentId) && Boolean(p.section) && p.apartmentId === currentApartmentId && p.section === element.key
              );
              console.log("row", row);
              if (!row) {
                const hasApt = selectedApartments.find(p => p.key === apartment.key);
                if (!hasApt) {
                  selectedApartments.push({ key: apartment.key, value: apartment.value });
                }
              }
            });
          } else {
            const hasApt = selectedApartments.find(p => p.key === apartment.key);
            if (!hasApt) {
              selectedApartments.push({ key: apartment.key, value: apartment.value });
            }
            console.log("3333");
          }
        }
      } else {
        const hasApt = selectedApartments.find(p => p.key === apartment.key);
        if (!hasApt) {
          selectedApartments.push({ key: apartment.key, value: apartment.value });
        }
        console.log("4444");
      }
    }

    for (const apartment of apartmentIdSelectItems) {
      await fetchSectionData(apartment, sectionHouses, this.state.controllerName);
    }

    console.log("selectedApartments", selectedApartments);
    this.setState({
      currentApartmentIdSelectItems: selectedApartments
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillApartmentTypes = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data,
        currentApartmentTypeSelectItems: response.data
      });
    }
  };

  saveFloorHeights = async values => {
    console.log("values", values);
    const { apartmentTypeId, section } = this.state;
    if (Boolean(values.floorHeights) && values.floorHeights.length > 0 &&
      Boolean(apartmentTypeId) && Boolean(section)) {

      values.floorHeights.forEach(element => {
        element.floor = element.floor.replace("Floor-", "");
      });

      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveFloorHeights/"
        + apartmentTypeId + "/" + section, values.floorHeights);
      if (response.type === "ERROR") {
        error(response);
      } else {
        let { currentRow } = this.state;

        this.setState(
          {
            currentRow: currentRow + 1,
            modalFloorHeightsShow: false,
          },
          () => {
            this.getNumberOfFloors();
          }
        );
      }
    } else {
      showWarning("Apartment Type or Section not found.");
    }

  };

  getFloorHeights = async (row) => {
    let newObj = {
      apartmentTypeId: row.apartmentTypeId,
      section: row.section
    };

    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/floorHeights", newObj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        floorHeightList: Boolean(response.data) && response.data.length > 0 ? response.data : []
      });
    }
  }

  render() {
    const columns = [
      {
        title: <FormattedMessage id="Apartment.ApartmentType" defaultMessage="Apartment Type" />,
        key: "id",
        render: record => {
          return record.apartmentTypeName;
        }
      },
      {
        title: <FormattedMessage id="Apartment.Section" defaultMessage="Section" />,
        key: "section",
        render: record => {
          return record.section;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.section) ? this.state.pagination.section : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  section: e.target.value,
                  sectionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.sectionColor) ? this.state.pagination.sectionColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Apartment.Floor" defaultMessage="Floor" />,
        key: "floor",
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline"
                }}
                onClick={() =>
                  this.setState({
                    modalHouseRoomShow: true,
                  }, () => {
                    this.getFloorHeights(record)
                  })
                }>
                {record.floor}
              </div>
            </React.Fragment >
          );
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <InputNumber
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.floor) ? this.state.pagination.floor : null}
              onChange={async value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  floor: value,
                  floorColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div >
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.floorColor) ? this.state.pagination.floorColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="RoomQuantity.HouseType" defaultMessage="House Type" />,
        key: "houseTypeId",
        render: record => {
          return record.houseTypeName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <InputNumber
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.houseTypeId) ? this.state.pagination.houseTypeId : null}
              onChange={async value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  houseTypeId: value,
                  houseTypeIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.houseTypeIdColor) ? this.state.pagination.houseTypeIdColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Apartment.NumberOfHouse" defaultMessage="Number of House" />,
        key: "numberOfHouse",
        render: record => {
          return record.numberOfHouse;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <InputNumber
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.numberOfHouse) ? this.state.pagination.numberOfHouse : null}
              onChange={async value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  numberOfHouse: value,
                  numberOfHouseColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.numberOfHouseColor) ? this.state.pagination.numberOfHouseColor : "#bfbfbf" }}
          />
        ),
        sorter: true
      }
    ];

    const columnsFloorHeightList = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="ProjectsTreeCompanyHeight.Floor" defaultMessage="Floor" />,
        key: "id",
        render: record => {
          return record.floor;
        }
      },
      {
        title: <FormattedMessage id="ProjectsTreeCompanyHeight.Heights" defaultMessage="Heights" />,
        key: "id",
        render: record => {
          let lowerHeight = 0;
          if (Boolean(record.lowerHeight)) {
            lowerHeight = record.lowerHeight;
          }

          let upperHeight = 0;
          if (Boolean(record.upperHeight)) {
            upperHeight = record.upperHeight;
          }

          let unit = "";
          if (Boolean(record.unit)) {
            unit = record.unit;
          }

          return lowerHeight + "-" + upperHeight + " " + unit;
        }
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <div className="card card-custom card-stretch">
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <Breadcrumb separator=">">
                <Breadcrumb.Item className={this.state.tableVisibleModal ? "customBreadcrumbItem" : ""}>
                  <a
                    href="#"
                    onClick={() => {
                      this.setState(
                        {
                          tableVisibleModal: true,

                          modalFloorHeightsShow: false,
                          currentRow: 0
                        },
                        () => {
                          this.apartmentsWithHouseNotNull();
                        }
                      );
                    }}
                  >
                    <Space>
                      <SVG style={{ width: '20px', height: '20px' }} src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>
                      <FormattedMessage id="ProjectsTreeCompanyHeight.Floors" defaultMessage="Floors" />
                    </Space>
                  </a>
                </Breadcrumb.Item>

                <Breadcrumb.Item>
                  <a
                    href="#"
                    className={this.state.modalFloorHeightsShow ? "customBreadcrumbItem" : ""}
                    onClick={() => {
                      this.setState(
                        {
                          modalFloorHeightsShow: true,

                          tableVisibleModal: false,
                          modalFloorHeightsShow: false,
                          currentRow: 0
                        },
                        () => {
                          this.getNumberOfFloors();
                        }
                      );
                    }}
                  >
                    <FormattedMessage id="ProjectsTreeCompanyHeight.Heights" defaultMessage="Heights" />
                  </a>

                  {Boolean(this.state.tableList) && this.state.tableList.length > 0 && (
                    <Breadcrumb separator=">">
                      {this.state.tableList.map((val, i) => {
                        return (
                          <Breadcrumb.Item
                            className={
                              val.apartmentTypeName + "-" + val.section === this.state.apartmentTypeName + "-" + this.state.section
                                ? "customBreadcrumbItem"
                                : ""
                            }
                          >
                            <a
                              href="#"
                              onClick={() => {
                                this.setState(
                                  {
                                    currentRow: i
                                  },
                                  () => {
                                    this.getNumberOfFloors();
                                  }
                                );
                              }}
                            >
                              {val.apartmentTypeName + "-" + val.section}
                            </a>
                          </Breadcrumb.Item>
                        );
                      })}
                    </Breadcrumb>
                  )}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="card-toolbar"></div>
          </div>

          <br />
          <h3 className="card-label font-weight-bolder text-dark">
            <FormattedMessage id="ProjectsTreeCompanyHeight.Heights" defaultMessage="Heights" />
          </h3>
        </div>

        <>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">

                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span>
                      <span className="mr-2">{val.showValue}</span>
                      <span>

                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table bordered size="small" columns={columns} dataSource={this.state.tableList} loading={this.state.loading} pagination={false} />
          </div>

          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={19} lg={19} xl={19} xxl={19}></Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Button
                onClick={() => {
                  this.props.nextTab();
                }}
                style={{ width: "100%" }}
                variant="primary"
              >
                <FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />
              </Button>
            </Col>
          </Row>
        </>

        <DraggableModal
          title={<FormattedMessage id="ProjectsTreeCompanyHeight.Heights" defaultMessage="Heights" />}
          centered
          width={1000}
          open={this.state.modalFloorHeightsShow}
          onCancel={() => this.setState({ modalFloorHeightsShow: false })}
          onOk={() => {
            this.formRefFloorHeights.current.submit();
          }}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          cancelButtonProps={{ hidden: true }}
          content={
            <>
              <br />
              <h3 className="card-label font-weight-bolder text-dark">{this.state.apartmentTypeName + "-" + this.state.section}</h3>
              <br />

              <Form initialValues={{ remember: false }} onFinish={this.saveFloorHeights} ref={this.formRefFloorHeights}>
                <Form.List name="floorHeights">
                  {(fields, { add, remove }) => {
                    return (
                      <div style={{ height: '400px', overflowY: 'scroll' }}>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ProjectsTreeCompanyHeight.Floor"} defaultMessage={"Floor"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ProjectsTreeCompanyHeight.BottomHeight"} defaultMessage={"Bottom Height"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ProjectsTreeCompanyHeight.TopHeight"} defaultMessage={"Top Height"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ProjectsTreeCompanyHeight.Unit"} defaultMessage={"Unit"} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <>
                                  <tr key={field.key}>
                                    <Form.Item hidden name={[field.name, "floor"]}>
                                      <Input />
                                    </Form.Item>
                                    <td>
                                      <Text type="secondary">
                                        {Boolean(this.formRefFloorHeights) &&
                                          this.formRefFloorHeights.current &&
                                          this.formRefFloorHeights.current.getFieldValue("floorHeights") &&
                                          this.formRefFloorHeights.current.getFieldValue("floorHeights").length > 0 &&
                                          this.formRefFloorHeights.current.getFieldValue("floorHeights")[field.name].floor}
                                      </Text>
                                    </td>
                                    <td>
                                      <Form.Item name={[field.name, "lowerHeight"]}>
                                        <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item name={[field.name, "upperHeight"]}>
                                        <InputNumber min={0} onChange={value => {
                                          let details = this.formRefFloorHeights.current.getFieldValue("floorHeights");
                                          console.log("length", details.length - 1);
                                          console.log("index", field.name);
                                          if (details.length - 1 > field.name) {
                                            const updatedDetails = { ...details };
                                            updatedDetails[field.name + 1].lowerHeight = value;

                                            this.formRefFloorHeights.current.setFieldsValue({
                                              lowerHeight: updatedDetails,
                                            });
                                          }
                                        }}
                                          parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "unit"]}>
                                        <Select
                                          allowClear
                                          style={{ width: "100px" }}
                                          optionFilterProp="children"
                                          onChange={value => {
                                            if (
                                              Boolean(this.formRefFloorHeights) &&
                                              this.formRefFloorHeights.current &&
                                              this.formRefFloorHeights.current.getFieldValue("floorHeights") &&
                                              this.formRefFloorHeights.current.getFieldValue("floorHeights").length > 0
                                            ) {
                                              let floorHeights = this.formRefFloorHeights.current.getFieldValue("floorHeights");
                                              floorHeights.forEach(element => {
                                                element.unit = value;
                                              });

                                              this.formRefFloorHeights.current.setFieldsValue({
                                                floorHeights: floorHeights
                                              });
                                            }
                                          }}
                                        >
                                          {Boolean(this.state["apartmentHeightUnitSelectItems"]) &&
                                            this.state["apartmentHeightUnitSelectItems"].length > 0 &&
                                            this.state["apartmentHeightUnitSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </TableBoot>
                      </div>
                    );
                  }}
                </Form.List>
              </Form>
            </>
          }
        />

        <DraggableModal
          centered
          width={800}
          title={<FormattedMessage id="ProjectsTreeCompanyHeight.Heights" defaultMessage="Heights" />}
          open={this.state.modalHouseRoomShow}
          onCancel={() => {
            this.setState({ modalHouseRoomShow: false });
          }}
          okButtonProps={{ hidden: true }}
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Table
                bordered
                size="small"
                columns={columnsFloorHeightList}
                dataSource={this.state.floorHeightList}
                loading={this.state.loading}
                pagination={false}
              />
            </div>
          }
        />
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsTreeCompanyHeight);
