import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Col as ColBoot, Row as RowBoot, Form as FormBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { InputNumber, Col, Row, Divider, Spin } from "antd";
import { handleRequest } from "../../../ApiConnector";

export class ProjectProgressScoring extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "companyProjectProgress",
      hideSave: false,

      id: null,
      projectProgressScoringList: [],
      saveLoading: false
    };
  }

  componentDidMount = async () => {
    this.fillComboboxes();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.fillComboboxes();
    }
  };

  fillComboboxes = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getProgressScoring/" + this.props.companyProjectId);
    if (response.type === "ERROR") {
      error(response);
    }
    this.setState({
      projectProgressScoringList: Boolean(response.data) && response.data.length > 0 ? response.data : []
    });
  };

  cancel = () => {
    this.setState({
      hideSave: false
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.setState({
      id: null,

      projectProgressScoringList: []
    });
  };

  save = async () => {
    this.setState({ saveLoading: true });
    if (Boolean(this.state.projectProgressScoringList) && this.state.projectProgressScoringList.length > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAll", this.state.projectProgressScoringList);
      if (response.type === "ERROR") {
        error(response);
        this.setState({ saveLoading: false });
      } else {
        this.resetInputs();
        this.fillComboboxes();
        this.cancel();
        showSuccess();
        this.setState({ saveLoading: false });
        if (Boolean(this.props.close)) {
          this.props.close();
        }
      }
    } else {
      showError();
      this.setState({ saveLoading: false });
    }
  };

  renderElement = values => {
    return values.map(item => (
      <React.Fragment key={`item-${item.projectName}`}>
        {/* Ana değer */}
        <RowBoot style={{ marginBottom: "1rem" }}>
          <ColBoot xs={4}>
            <FormBoot.Label style={{ color: "black", marginTop: "0.5rem" }}>{item.projectName}</FormBoot.Label>
          </ColBoot>
          <ColBoot xs={2}>
            <InputNumber
              min={0}
              value={item.score}
              onChange={value => {
                item.score = value;
                this.calcEvaluationScore(item.parentId);
              }}
            />
          </ColBoot>
          <ColBoot xs={2}>
            <InputNumber disabled min={0} value={item.weight} />
          </ColBoot>
        </RowBoot>

        {/* Alt değerler (children), sadece başlık kısmı sağa kaydırılmış */}
        {item.children &&
          item.children.length > 0 &&
          item.children.map((child, index) => (
            <RowBoot key={`child-${index}`} style={{ marginBottom: "1rem" }}>
              <ColBoot xs={4}>
                <FormBoot.Label
                  style={{
                    color: "black",
                    marginTop: "0.5rem",
                    marginLeft: "2rem" // Sadece başlık sağa kayıyor
                  }}
                >
                  {child.projectName}
                </FormBoot.Label>
              </ColBoot>
              <ColBoot xs={2}>
                <InputNumber
                  min={0}
                  value={child.score}
                  onChange={value => {
                    child.score = value;
                    this.calcEvaluationScore(child.parentId);
                  }}
                />
              </ColBoot>
              <ColBoot xs={2}>
                <InputNumber disabled min={0} value={child.weight} />
              </ColBoot>
            </RowBoot>
          ))}
      </React.Fragment>
    ));
  };

  calcEvaluationScore = parentId => {
    this.setState(
      {
        projectProgressScoringList: this.state.projectProgressScoringList
      },
      () => {
        this.calcTotalScore(parentId);
      }
    );
  };

  calcTotalScore = parentId => {
    console.log("parentId", parentId);
    var totalScore = 0;
    const { projectProgressScoringList } = this.state;
    console.log("projectProgressScoringList", projectProgressScoringList);

    if (Boolean(projectProgressScoringList) && projectProgressScoringList.length > 0) {
      projectProgressScoringList.forEach(element => {
        console.log("element", element);
        if (element.parentId === parentId && Boolean(element.score)) {
          totalScore += element.score;
        }

        if (Boolean(element.children) && element.children.length > 0) {
          element.children.forEach(child => {
            console.log("child", child);
            if (child.parentId === parentId && Boolean(child.score)) {
              totalScore += child.score;
            }
          });
        }
      });
    }

    console.log("totalScore", totalScore);
    if (Boolean(totalScore)) {
      if (Boolean(projectProgressScoringList) && projectProgressScoringList.length > 0) {
        projectProgressScoringList.forEach(element => {
          if (element.parentId === parentId && Boolean(element.score)) {
            element.weight = (element.score * 100) / totalScore;
          }

          if (Boolean(element.children) && element.children.length > 0) {
            element.children.forEach(child => {
              if (child.parentId === parentId && Boolean(child.score)) {
                child.weight = (child.score * 100) / totalScore;
              }
            });
          }
        });
      }
    }

    this.setState({
      projectProgressScoringList: projectProgressScoringList
    });
  };

  render() {
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        {Boolean(this.state.projectProgressScoringList) && this.state.projectProgressScoringList.length > 0 ? (
          <>
            <RowBoot style={{ marginTop: "2rem" }}>
              <ColBoot xs={4}>
                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                  <FormattedMessage id="ProjectProgressScoring.Item" defaultMessage="Item" />
                </FormBoot.Label>
              </ColBoot>
              <ColBoot xs={2}>
                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                  <FormattedMessage id="ProjectProgressScoring.Score" defaultMessage="Score" />
                </FormBoot.Label>
              </ColBoot>
              <ColBoot xs={2}>
                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                  <FormattedMessage id="ProjectProgressScoring.WeightPercent" defaultMessage="Weight %" />
                </FormBoot.Label>
              </ColBoot>
            </RowBoot>
            <Divider style={{ "background-color": "black" }} />

            {this.renderElement(this.state.projectProgressScoringList)}

            <br />
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Spin spinning={this.state.saveLoading}>
                      <Button id="SaveButton" onClick={this.save} style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Spin>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectProgressScoring);
