import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { Add, EditOutlined } from "@material-ui/icons";
import { showError, showSuccess, error } from "../../../MessageHelper";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Form, Table, Input, DatePicker, Badge, Button as AntButton, Col, Row, Divider, Space } from "antd";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import { handleRequest } from "../../../ApiConnector";
import CreateIcon from "@material-ui/icons/AddCircle";
import { PlusOutlined } from "@ant-design/icons";
import { Select } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { getColumnFilter, fillFilterOperations } from "../../../Components/DataTableFilterComponent";
import { excelExport } from "../../../ExcelHelper";
import ExcelReader from "../../excel/ExcelReader";
import DraggableModal from "../../../Components/DraggableModal";
import SoftwareLogType from "./SoftwareLogType";
import SoftwareLogProvider from "./SoftwareLogProvider";

const { Option } = Select;
const { RangePicker } = DatePicker;

export class SoftwareLog extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "softwareLog",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      policyPath: "",
      nodes: [],
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.fillCode("SoftwareLog.ApplicableAreaId");
    fillFilterOperations();
    var responseType = await handleRequest("GET", "/api/" + this.state.controllerName + "/types");
    if (responseType.type === "ERROR") {
      error(responseType);
    }
    var responseProvider = await handleRequest("GET", "/api/" + this.state.controllerName + "/providers");
    if (responseProvider.type === "ERROR") {
      error(responseProvider);
    }
    var responseDepartment = await handleRequest("GET", "/api/" + this.state.controllerName + "/departments");
    if (responseDepartment.type === "ERROR") {
      error(responseDepartment);
    }
    var responseUser = await handleRequest("GET", "/api/" + this.state.controllerName + "/users");
    if (responseUser.type === "ERROR") {
      error(responseUser);
    }
    var responseEquipment = await handleRequest("GET", "/api/" + this.state.controllerName + "/equipments");
    if (responseEquipment.type === "ERROR") {
      error(responseEquipment);
    }
    this.setState({
      typeIdSelectItems: Boolean(responseType.data) ? responseType.data : [],
      providerIdSelectItems: Boolean(responseProvider.data) ? responseProvider.data : [],
      departmentIdSelectItems: Boolean(responseDepartment.data) ? responseDepartment.data : [],
      userIdSelectItems: Boolean(responseUser.data) ? responseUser.data : [],
      equipmentLogIdSelectItems: Boolean(responseEquipment.data) ? responseEquipment.data : []
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      projectId: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      policyPath: ""
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let usagePeriod = [];
    if (Boolean(row.usagePeriodMin) && Boolean(row.usagePeriodMax)) {
      usagePeriod.push(moment(row.usagePeriodMin));
      usagePeriod.push(moment(row.usagePeriodMax));
    }

    this.formRef.current.setFieldsValue({
      ...row,
      aggrementDate: Boolean(row.aggrementDate) ? moment(row.aggrementDate) : null,
      usagePeriod: usagePeriod
    });

    let equipmentSoftware = false;
    if (Boolean(row.typeId) && Boolean(this.state["typeIdSelectItems"]) && this.state["typeIdSelectItems"].length > 0) {
      let selected = this.state["typeIdSelectItems"].find(p => p.key === row.typeId);
      if (selected?.value === "Equipment Software") {
        equipmentSoftware = true;
      }
    }
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      equipmentSoftware: equipmentSoftware
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (Boolean(values.usagePeriod) && values.usagePeriod.length === 2) {
      newItem.usagePeriodMin = values.usagePeriod[0];
      newItem.usagePeriodMax = values.usagePeriod[1];
    }

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      equipmentLogId: this.props.equipmentLogId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          if (Array.isArray(element))
            element.forEach(element2 => {
              let obj = { columns: element2 };
              list.push(obj);
            });
          else {
            let obj = { columns: element };
            list.push(obj);
          }
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/importDynamicaly", list);
          if (response.type === "ERROR") {
            error(response);
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          error("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];

    if(!this.props.equipmentLogId){
      actionList.push(editAction);
      actionList.push(deleteAction);
    }
    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="SoftwareLog.SoftwareDescription" defaultMessage="Software Description" />,
        key: "description",
        render: record => {
          return record.description;
        },
        ...getColumnFilter({
          inputName: "description",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SoftwareLog.Provider" defaultMessage="Provider" />,
        key: "providerId",
        render: record => {
          return record.providerName;
        },
        ...getColumnFilter({
          inputName: "providerId",
          inputType: "TEXT",
          selectItems: Boolean(this.state["providerIdSelectItems"]) ? this.state["providerIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SoftwareLog.SoftwareType" defaultMessage="Software Type" />,
        key: "typeId",
        render: record => {
          return record.typeName;
        },
        ...getColumnFilter({
          inputName: "typeId",
          inputType: "TEXT",
          selectItems: Boolean(this.state["typeIdSelectItems"]) ? this.state["typeIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SoftwareLog.AggrementDate" defaultMessage="Aggrement Date" />,
        key: "aggrementDate",
        render: record => {
          return Boolean(record.aggrementDate) ? moment(record.aggrementDate).format("DD-MM-YYYY HH:mm") : "";
        },
        sorter: true
      },
      {
        title: <FormattedMessage id="SoftwareLog.UsagePeriod" defaultMessage="Usage Period" />,
        key: "id",
        render: record => {
          return Boolean(record.usagePeriodMin) && Boolean(record.usagePeriodMax)
            ? moment(record.usagePeriodMin).format("DD-MM-YYYY HH:mm") + " / " + moment(record.usagePeriodMax).format("DD-MM-YYYY HH:mm")
            : "";
        },
        sorter: true
      },
      {
        title: <FormattedMessage id="SoftwareLog.CustodianDepartment" defaultMessage="Custodian Department" />,
        key: "departmentId",
        render: record => {
          return record.departmentName;
        },
        ...getColumnFilter({
          inputName: "departmentId",
          inputType: "TEXT",
          selectItems: Boolean(this.state["departmentIdSelectItems"]) ? this.state["departmentIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SoftwareLog.Admin" defaultMessage="Admin" />,
        key: "userId",
        render: record => {
          return record.userName;
        },
        ...getColumnFilter({
          inputName: "userId",
          inputType: "TEXT",
          selectItems: Boolean(this.state["userIdSelectItems"]) ? this.state["userIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SoftwareLog.ApplicableArea" defaultMessage="Applicable Area" />,
        key: "applicableAreaId",
        render: record => {
          return record.applicableAreaName;
        },
        ...getColumnFilter({
          inputName: "applicableAreaId",
          inputType: "TEXT",
          selectItems: Boolean(this.state["applicableAreaIdSelectItems"]) ? this.state["applicableAreaIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
    ];

    const columnsExcel = [{ header: "S/N", key: "index" }];

    return (
      <Badge.Ribbon text="Software Log" placement="start" color="purple" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <br />
            {!this.props.equipmentLogId && (
              <Row gutter={[16, 16]}>
                <Col md={21}></Col>
                <Col md={1}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => {
                      excelExport(columnsExcel, this.state.tableList);
                    }}
                    icon={<ExportIcon color="action" fontSize="large" />}
                  />
                </Col>
                <Col md={1}>
                  <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
                </Col>
                <Col md={1}>
                  <AntButton
                    style={{ border: "0px" }}
                    hidden={!this.state.hideInputs}
                    onClick={this.onClickCreateNew}
                    icon={<CreateIcon color="secondary" fontSize="large" />}
                  ></AntButton>
                  <AntButton
                    style={{ border: "0px" }}
                    hidden={this.state.hideInputs}
                    onClick={this.onClickCreateNew}
                    icon={<RemoveCircle color="error" fontSize="large" />}
                  ></AntButton>
                </Col>
              </Row>
            )}
            <br />
            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="SoftwareLog.SoftwareDescription" defaultMessage="Software Description" />}
                  name="description"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }

              <Form.Item
                {...layout}
                label={<FormattedMessage id="SoftwareLog.Provider" defaultMessage="Provider" />}
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    name="providerId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear style={{ width: "250px" }} optionFilterProp="children">
                      {Boolean(this.state["providerIdSelectItems"]) &&
                        this.state["providerIdSelectItems"].length > 0 &&
                        this.state["providerIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <AntButton
                    type="default"
                    style={{
                      color: "black",
                      background: "white",
                      width: "10%"
                    }}
                    onClick={() => {
                      this.setState({ modalProviderShow: true, random: Math.random() });
                    }}
                    icon={<Add />}
                  ></AntButton>
                </Space.Compact>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="SoftwareLog.SoftwareType" defaultMessage="Software Type" />}
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    name="typeId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "250px" }}
                      optionFilterProp="children"
                      onChange={value => {
                        let equipmentSoftware = false;
                        if (Boolean(value)) {
                          let row = this.state["typeIdSelectItems"].find(p => p.key === value);
                          if (row?.value === "Equipment Software") {
                            equipmentSoftware = true;
                          }
                        }
                        this.setState({
                          equipmentSoftware: equipmentSoftware
                        });
                      }}
                    >
                      {Boolean(this.state["typeIdSelectItems"]) &&
                        this.state["typeIdSelectItems"].length > 0 &&
                        this.state["typeIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <AntButton
                    type="default"
                    style={{
                      color: "black",
                      background: "white",
                      width: "10%"
                    }}
                    onClick={() => {
                      this.setState({ modalTypeShow: true, random: Math.random() });
                    }}
                    icon={<Add />}
                  ></AntButton>
                </Space.Compact>
              </Form.Item>

              {this.state.equipmentSoftware && (
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="SoftwareLog.Equipment" defaultMessage="Equipment" />}
                  name="equipmentLogId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["equipmentLogIdSelectItems"]) &&
                      this.state["equipmentLogIdSelectItems"].length > 0 &&
                      this.state["equipmentLogIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                {...layout}
                label={<FormattedMessage id="SoftwareLog.AggrementDate" defaultMessage="Aggrement Date" />}
                name="aggrementDate"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime={{ format: "HH:mm" }} />
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="SoftwareLog.UsagePeriod" defaultMessage="Usage Period" />}
                name="usagePeriod"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <RangePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime={{ format: "HH:mm" }} />
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="SoftwareLog.Department" defaultMessage="Department" />}
                name="departmentId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["departmentIdSelectItems"]) &&
                    this.state["departmentIdSelectItems"].length > 0 &&
                    this.state["departmentIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="SoftwareLog.Admin" defaultMessage="Admin" />}
                name="userId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["userIdSelectItems"]) &&
                    this.state["userIdSelectItems"].length > 0 &&
                    this.state["userIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="SoftwareLog.ApplicableArea" defaultMessage="Applicable Area" />}
                name="applicableAreaId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={event => {
                            this.setState({
                              codeName: event.target.value
                            });
                          }}
                        />
                        <a
                          style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                          onClick={() => this.addCode("SoftwareLog.ApplicableAreaId")}
                        >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                        </a>
                      </div>
                    </div>
                  )}
                >
                  {Boolean(this.state["SoftwareLog.ApplicableAreaIdSelectItems"]) &&
                    this.state["SoftwareLog.ApplicableAreaIdSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              rowKey="id"
              size="small"
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          <DraggableModal
            title={<FormattedMessage id="SoftwareLog.Type" defaultMessage="Type" />}
            open={this.state.modalTypeShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState({ modalTypeShow: false });
              this.fillComboboxes();
            }}
            width={1000}
            centered
            content={<SoftwareLogType random={this.state.random} />}
          />

          <DraggableModal
            title={<FormattedMessage id="SoftwareLog.Provider" defaultMessage="Provider" />}
            open={this.state.modalProviderShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState({ modalProviderShow: false });
              this.fillComboboxes();
            }}
            width={1000}
            centered
            content={<SoftwareLogProvider random={this.state.random} />}
          />
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SoftwareLog);
