import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import { Button } from 'react-bootstrap';
import { handleRequest } from '../../ApiConnector';
import { Form, Col, Row, Input } from 'antd';
import { Link } from "react-router-dom";
import { error, showSuccess } from '../../MessageHelper';

const validatePassword = (rule, value, callback) => {
    if (Boolean(value) && value.length > 2) {
        callback();
    } else {
        callback("Error!");
    }
};

export class ChangePassword extends Component {

    constructor() {
        super();
        this.state = {
            password: "",
            newPassword: "",
            confirmNewPassword: "",
        };
    }

    formRef = React.createRef();

    componentDidMount = async () => {

    };

    save = async () => {
        const newItem = {
            password: this.state.password,
            newPassword: this.state.newPassword,
            confirmNewPassword: this.state.confirmNewPassword,
        }

        var response = await handleRequest("POST", "/api/users/changePassword", newItem);
        if (response.type === "ERROR") {
            error(response);
        }
        else {
            showSuccess();
            try {
                document.getElementById("dashboard").click();
            } catch (error) {
            }
        }
    };

    render() {
        const layout2 = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 }
        };

        return (
            <div hidden={this.state.hideInputs} className="card card-custom" style={{ padding: '2rem', minHeight: '100%' }}>

                <Form
                    initialValues={{ remember: false }}
                    onFinish={this.save}
                    ref={this.formRef}>

                    <Link id="logout" to="/logout" hidden ></Link>
                    <Link id="dashboard" to="" hidden ></Link>

                    {
                        <Form.Item
                            {...layout2}
                            label={<FormattedMessage id="todo" defaultMessage="Current Password" />}
                            name="password"
                            rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type current password" /> }]}
                        >

                            <Input.Password id="todo" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                        </Form.Item>
                    }

                    {
                        <Form.Item
                            {...layout2}
                            name="newPassword"
                            label="New Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your new password at least 3 characters!',
                                    validator: validatePassword,
                                },
                            ]}
                        >
                            <Input.Password id="todo" value={this.state.newPassword} onChange={(e) => this.setState({ newPassword: e.target.value })} />
                        </Form.Item>
                    }

                    {
                        <Form.Item
                            {...layout2}
                            name="confirm"
                            label="Confirm New Password"
                            dependencies={['newPassword']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your new password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password id="todo" value={this.state.confirmNewPassword} onChange={(e) => this.setState({ confirmNewPassword: e.target.value })} />
                        </Form.Item>
                    }

                    {
                        <Row gutter={[16, 16]}>
                            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                <Button id="SaveButton" type="submit" style={{ width: '100%' }}
                                    variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>

                            </Col>
                            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                        </Row>
                    }

                </Form>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)