import React, { useState, useEffect } from "react";
import { Line } from "@ant-design/plots";
import {  handleRequest } from "../../ApiConnector";
import { error} from "../../MessageHelper";
import moment from "moment";
import {  Col, Row, Select, DatePicker } from "antd";
import { FormattedMessage } from "react-intl";
const { Option } = Select;

function sortDate(a, b) {
  if (a.year === b.year) {
    if (a.month === b.month) {
      if (a.day === b.day) {
        return 0;
      }
      return a.day < b.day ? -1 : 1;
    } else return a.month < b.month ? -1 : 1;
  } else {
    return a.year < b.year ? -1 : 1;
  }
}
const QualityControlResultsChart = () => {
  const [data, setData] = useState([]);
  const [period, setPeriod] = useState("DAILY");
  const [startDate, setStartDate] = useState(moment().subtract(6, "M"));
  const [endDate, setEndDate] = useState(moment());

  useEffect(() => {
    asyncFetch();
  }, [period, startDate, endDate]);

  const asyncFetch = async () => {
    const search = {
      period,
      startDate: startDate
        ? startDate.toDate()
        : moment()
            .subtract(6, "M")
            .toDate(),
      endDate: endDate ? endDate.toDate() : moment().toDate()
    };
    console.log(search);
    var response = await handleRequest("POST", "/api/dashboard/qualityControlResultsChart", search);
    if (response.type === "ERROR") {
      //error(response);
    } else {
      let chartData = Boolean(response.data) ? response.data : null;
      chartData.sort((a, b) => sortDate(a, b));
      console.log(chartData);
      setData(chartData);
    }
  };
  const config = {
    data,
    xField: "dateStr",
    yField: "quantity",
    seriesField: "name",
    yAxis: {
      label: {
        formatter: v => `${v}`
      }
    },
    color: ["#1979C9", "#D62A0D", "#FAA219", "#FF99C3"]
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={6}></Col>
        <Col lg={6}>
          <DatePicker
            placeholder="Start Date"
            style={{ width: "100%" }}
            value={startDate}
            onChange={date => {
              setStartDate(date);
            }}
          />
        </Col>
        <Col lg={6}>
          <DatePicker
            placeholder="End Date"
            style={{ width: "100%" }}
            value={endDate}
            onChange={date => {
              setEndDate(date);
            }}
          />
        </Col>
        <Col lg={6}>
          <Select
            placeholder="Period"
            defaultActiveFirstOption
            allowClear
            value={period}
            className="w-100"
            onChange={value => {
              setPeriod(value);
            }}
          >
            <Option key={"DAILY"} value={"DAILY"}>
              <FormattedMessage id="period.day" defaultMessage="Day" />
            </Option>
            <Option key={"MONTHLY"} value={"MONTHLY"}>
              <FormattedMessage id="period.month" defaultMessage="Month" />
            </Option>
            <Option key={"YEARLY"} value={"YEARLY"}>
              <FormattedMessage id="period.year" defaultMessage="Year" />
            </Option>
          </Select>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={24}>
          <Line {...config} />
        </Col>
      </Row>
    </>
  );
};

export default QualityControlResultsChart;
