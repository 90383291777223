import React, { Component } from "react";
import ExcelJS from "exceljs";
import UploadIcon from "@material-ui/icons/CloudUpload";
import SyncIcon from "@material-ui/icons/Sync";
import { Button as AntButton } from "antd";

export class ExcelReaderReadAllWorkSheets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      excelData: []
    };
    this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  handleChange(e) {
    const files = e.target.files;

    if (files && files[0]) this.setState({ file: files[0] });
    
    setTimeout(
      () => this.inputButton.click(),
      100
    );
  }

  removeFile(e) {
    this.setState({ file: null });
  }

  clickButton = e => {
    document.getElementsByClassName("myCustomUploadButton").click();
  };

  handleClick = e => {
    this.inputElement.click();
  };

  handleFile() {
    if (this.state.file === null) {
      this.props.onCreateJson(null);
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const arrayBuffer = e.target.result;
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(arrayBuffer);
      
      const _data = [];
      workbook.worksheets.forEach((worksheet) => {
        const data = [];
        worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
          if (rowNumber === 1) return; // Başlık satırını atla
          const rowData = row.values.slice(1); // İlk boş değeri atla
          data.push(rowData);
        });
        _data.push({ sheetName: worksheet.name, data });
      });

      this.setState({ excelData: _data }, () => {
        console.log(JSON.stringify(this.state.excelData, null, 2));
      });

      this.props.onCreateJson(this.state.excelData);
    };

    reader.readAsArrayBuffer(this.state.file);
  }

  render() {
    return (
      <div>
        <div className="custom-file" style={{ width: "100%", display: "none" }}>
          <input
            ref={input => (this.inputElement = input)}
            style={{ width: "100%" }}
            type="file"
            placeholder="Upload an Excel"
            className="custom-file-input"
            id="customFile"
            onChange={this.handleChange}
          />
          <input
            type="submit"
            className="myCustomUploadButton"
            ref={input => (this.inputButton = input)}
            value="Process Triggers"
            onClick={this.handleFile}
          />
          <label className="custom-file-label" htmlFor="customFile">
            {this.state.file !== null && this.state.file.name}
          </label>
        </div>
        {Boolean(this.props.excelConfig) ? (
          <AntButton
            style={{ border: "0px" }}
            onClick={() => this.handleClick()}
            icon={<SyncIcon color="primary" />}
          >
            {this.props.excelConfig}
          </AntButton>
        ) : (
          <AntButton
            disabled={Boolean(this.props.disabled) ? true : false}
            style={{ border: "0px" }}
            onClick={() => this.handleClick()}
            icon={<UploadIcon color="primary" fontSize="large" />}
          ></AntButton>
        )}
      </div>
    );
  }
}

export default ExcelReaderReadAllWorkSheets;
