/*--------import/react---------- */
import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";

/*--------../_metronic---------- */
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import { ContentRouteUserOnly } from "../_metronic/layout/components/content/ContentRouteUserOnly";
import { PublicContentRoute } from "../_metronic/layout/components/content/PublicContentRoute";

/*--------./pages/_partials---------- */
import { UserProfile } from "./pages/_partials/UserProfile";

/*--------./pages/error---------- */
import AuthorizationError from "./pages/error/AuthorizationError";
import ErrorPage1 from "./pages/error/ErrorPage1";
import WarningPages from "./pages/error/WarningPages";
import NotFoundError from "./pages/error/NotFoundError";

/*--------./pages/DashboardPage---------- */
import DashboardPage from "./pages/DashboardPage";

/*--------./pages/humanresources---------- */
import AssignmentPersonnel from "./pages/humanresources/assignment/AssigmentPersonnel";
import Clinic from "./pages/humanresources/Clinic";
import ContractDocument from "./pages/humanresources/ContractDocument";
import Department from "./pages/humanresources/Department";
import Designation from "./pages/humanresources/Designation";
import DesignationDiscipline from "./pages/humanresources/DesignationDiscipline";
import Discipline from "./pages/humanresources/Discipline";
import EmployeeRequest from "./pages/humanresources/EmployeeRequest";
import HolidayLog from "./pages/humanresources/HolidayLog";
import InsurancePolicy from "./pages/humanresources/InsurancePolicy";
import ItpDiscipline from "./pages/humanresources/ItpDiscipline";
import LeavePolicy from "./pages/humanresources/LeavePolicy";
import LeavePolicyGroup from "./pages/humanresources/LeavePolicyGroup";
import ManagementLevel from "./pages/humanresources/ManagementLevel";
import OrganizationalChart from "./pages/humanresources/OrganizationalChart";
import OrganizationalLevel from "./pages/humanresources/OrganizationalLevel";
import OvertimeRequest from "./pages/humanresources/OvertimeRequest";
import PersonelInfo from "./pages/humanresources/PersonelInfo";
import PersonnelLog from "./pages/humanresources/PersonnelLog";
import RoleDescriptionCompany from "./pages/humanresources/RoleDescriptionCompany";
import RoleGroup from "./pages/humanresources/RoleGroup";
import Shifts from "./pages/humanresources/Shifts";
import Timesheet from "./pages/humanresources/Timesheet";
import TimesheetWorkflow from "./pages/humanresources/TimesheetWorkflow";
import UserItpDiscipline from "./pages/humanresources/UserItpDiscipline";
import EmployeeContract from "./pages/humanresources/EmployeeContract";
import TrainingSchedule from "./pages/humanresources/TrainingSchedule";
import LeaveRequest from "./pages/humanresources/LeaveRequest";
import OrganizationalCategory from "./pages/humanresources/OrganizationalCategory";
import Exit from "./pages/humanresources/Exit";
import AbsenceInfractions from "./pages/humanresources/AbsenceInfractions";
import JobOffer from "./pages/humanresources/JobOffer";
import CandidatePortal from "./pages/humanresources/CandidatePortal";

/*--------./pages/administrator---------- */
import ChangePassword from "./pages/administrator/ChangePassword";
import CodeDefine from "./pages/administrator/CodeDefine";
import { Company } from "./pages/administrator/Company";
import DashboardChart from "./pages/administrator/DashboardChart";
import DynamicMenu from "./pages/administrator/DynamicMenu";
import GenerateFormattedMessagesJson from "./pages/administrator/GenerateFormattedMessagesJson";
import HowToUse from "./pages/administrator/HowToUse";
import { Info } from "./pages/administrator/Info";
import LanguageSettings from "./pages/administrator/LanguageSettings";
import LanguageSettings2 from "./pages/administrator/LanguageSettings2";
import MenuAuthorization from "./pages/administrator/MenuAuthorization";
import PageAction from "./pages/administrator/PageAction";
import PageForm from "./pages/administrator/PageForm";
import PageFormInput from "./pages/administrator/PageFormInput";
import PageFormInputCompany from "./pages/administrator/PageFormInputCompany";
import PageInput from "./pages/administrator/PageInput";
import PageInputCompany from "./pages/administrator/PageInputCompany";
import { Project } from "./pages/administrator/Project";
import { SubCompany } from "./pages/administrator/SubCompany";
import { User } from "./pages/administrator/User";
import UserDashboardChart from "./pages/administrator/UserDashboardChart";

/*--------./pages/logistics----------*/
import { BusStops } from "./pages/logistics/BusStops.js";
import { Regions } from "./pages/logistics/Regions.js";
import { DriverCategory } from "./pages/logistics/DriverCategory.js";
import { SlopeRanges } from "./pages/logistics/SlopeRanges.js";
import { CurveRanges } from "./pages/logistics/CurveRanges.js";
/*import { Route } from "./pages/logistics/Route.js";*/
import { Drivers } from "./pages/logistics/Drivers.js";
import { Buses } from "./pages/logistics/Buses.js";

/*--------./pages/archive---------- */
import { Checklist } from "./pages/archive/Checklist";
import { Drawing } from "./pages/archive/Drawing";
import { DynamicPage } from "./pages/archive/DynamicPage";
import { File } from "./pages/archive/File";
import { Filling } from "./pages/archive/Filling";
import { Folder } from "./pages/archive/Folder";
import { InspectionLog } from "./pages/archive/InspectionLog";
import { InspectionLogItem } from "./pages/archive/InspectionLogItem";
import { ItpActivity } from "./pages/archive/ItpActivity";

import { Log } from "./pages/archive/Log";
import ReportVerify from "./pages/archive/ReportVerify";
import { SaicLog } from "./pages/archive/SaicLog";
import { SatipLog } from "./pages/archive/SatipLog";
import { TransmittalLog } from "./pages/archive/TransmittalLog";
import { Trash } from "./pages/archive/Trash";
import { UserCompany } from "./pages/archive/UserCompany";
import { UserFolder } from "./pages/archive/UserFolder";

/*import InspectionTestReport from "./pages/archive/InspectionTestReport";
import InspectionTestReportLogReport from "./pages/archive/InspectionTestReportLogReport";
import { ItpLog } from "./pages/archive/ItpLog";*/

/*--------./pages/Autogenerator---------- */
import CreateJavaClass from "./pages/Autogenerator/CreateJavaClass";
import FrontendGenerator from "./pages/Autogenerator/FrontendGenerator";
import Generated from "./pages/Autogenerator/generated";
import CoderPageForm from "./pages/Autogenerator/CoderPageForm";

/*--------./pages/clientAdministration---------- */
import TimelineQRGenerator from "./pages/clientAdministration/TimelineQRGenerator";
import TimelineQRSettings from "./pages/clientAdministration/TimelineQRSettings";
import TimelineQRUserScan from "./pages/clientAdministration/TimelineQRUserScan";
import TicketMessagesExecutive from "./pages/clientAdministration/TicketMessagesExecutive";

/*--------./pages/clients---------- */
import ComListing from "./pages/clients/ComListing";
import SAEngineeringProcedure from "./pages/clients/SAEngineeringProcedure";
import SaEngineeringStandard from "./pages/clients/SaEngineeringStandard";
import SAMaterialsSystemSpecification from "./pages/clients/SAMaterialsSystemSpecification";

/*--------./pages/documentation---------- */
import Glossary from "./pages/documentation/Glossary";
import FlowChart from "./pages/documentation/FlowChart";
import ReactFlowChart from "./pages/documentation/ReactFlowChart";
import React3D from "./pages/documentation/React3D";
import Meeting from "./pages/documentation/Meeting";
import AuditLog from "./pages/documentation/AuditLog";
import TextFormatting from "./pages/documentation/TextFormatting";
import DocumentType from "./pages/documentation/DocumentType";
import DocumentLog from "./pages/documentation/DocumentLog";
import ContractLog from "./pages/documentation/ContractLog";
import AutoReport from "./pages/documentation/autoReport/AutoReport";
import Presentation from "./pages/documentation/presentation/Presentation";
import TextToPara from "./pages/documentation/textToPara/TextToParaLog";
import BookCreator from "./pages/documentation/bookCreator/BookCreator.js";

/*--------./pages/elden---------- */
import { EldenItem } from "./pages/elden/Item";
import { ItemCategory as EldenItemCategory } from "./pages/elden/ItemCategory";
import ItemGroup from "./pages/elden/ItemGroup";
/*import { JointingMethodItem } from "./pages/elden/JointingMethod";*/
import MaterialGroup from "./pages/elden/MaterialGroup";
import MaterialType from "./pages/elden/MaterialType";
import { EldenProperty } from "./pages/elden/Property";
import { EldenPropertyGroup } from "./pages/elden/PropertyGroup";
import RoleDescription from "./pages/elden/RoleDescription";
import { EldenStandard } from "./pages/elden/Standard";
import Unit from "./pages/elden/Unit";
import UnitConverter from "./pages/elden/UnitConverter";
import UnitGroup from "./pages/elden/UnitGroup";
import LaborLaw from "./pages/elden/LaborLaw";
import VendorQuestionnary from "./pages/elden/VendorQuestionnary";
import VendorPerformance from "./pages/elden/VendorPerformance";
import RequestForQuotationTemplate from "./pages/elden/RequestForQuotationTemplate";
import { InvoiceTemplates } from "./pages/elden/InvoiceTemplates";
import PresentationSettings from "./pages/elden/Presentation";
import ActivityPlanning from "./pages/elden/ActivityPlanning";
import ChemicalComposition from "./pages/elden/ChemicalComposition";
import DimensionDefinitions from "./pages/elden/DimensionDefinitions";
import Rename from "./pages/elden/Rename";
import PurchaseCriteria from "./pages/elden/PurchaseCriteria";
import MaterialCategory from "./pages/elden/MaterialCategory";

/*--------./pages/elden/quality---------- */
import Dimension from "./pages/elden/Dimension";
import InspectionTypes from "./pages/elden/InspectionTypes";
import ProfileWeights from "./pages/elden/ProfileWeights";
import TypeOfTests from "./pages/elden/planning/TypeOfTests";
import Grades from "./pages/elden/Grades";
import PeriodicTable from "./pages/elden/quality/PeriodicTable";
import ProductForm from "./pages/elden/ProductForm";

/*--------./pages/elden/standardization---------- */
import CEV from "./pages/elden/CEV";
import ColorTable from "./pages/elden/ColorTable";

/*--------./pages/elden/planning---------- */
import Projects from "./pages/elden/planning/Projects";
import PlanningProjectTypes from "./pages/elden/planning/PlanningProjectTypes";
import ProjectsTree from "./pages/elden/planning/ProjectsTree";
import EldenMobilization from "./pages/elden/planning/EldenMobilization";
import EldenBudgetItems from "./pages/elden/planning/EldenBudgetItems";
import StormLine from "./pages/planning/StormLine";

/*import MainProjectTypes from "./pages/elden/planning/MainProjectTypes";
import PlanningComponent from "./pages/elden/planning/PlanningComponent"; 
import Services from "./pages/elden/planning/Services";*/

/*--------./pages/elden/library---------- */
import AnkaraCounty from "./pages/elden/AnkaraCounty";
import IstanbulCounty from "./pages/elden/IstanbulCounty.js";

/*--------./pages/elden/standards---------- */
import MechanicalAndPhysicalTests from "./pages/elden/planning/MechanicalAndPhysicalTests";

/*--------./pages/elden/accounting---------- */
import IncomeOutgoingGroups from "./pages/elden/accounting/IncomeOutgoingGroups";
import AccountingActivityCodes from "./pages/elden/accounting/AccountingActivityCodes";
import GovermentAgency from "./pages/elden/accounting/GovermentAgency";
import TaxPayers from "./pages/elden/accounting/TaxPayers";
import AccountingLegalObligations from "./pages/elden/accounting/AccountingLegalObligations";
import MuhtasarKodlar from "./pages/elden/accounting/MuhtasarKodlar";
import TaxDescriptions from "./pages/elden/accounting/TaxDescriptions";
import TaxSchedule from "./pages/elden/accounting/TaxSchedule";
import Tax from "./pages/elden/accounting/Tax";
import NaceCodes from "./pages/elden/accounting/NaceCodes";
import TheUniformChartofAccounting from "./pages/elden/accounting/TheUniformChartofAccounting";

/*--------./pages/elden/equipment---------- */
import EquipmentIdentification from "./pages/elden/equipment/EquipmentIdentification";
import EldenEquipmentLog from "./pages/elden/equipment/EldenEquipmentLog";
import EldenPurposeOfUse from "./pages/elden/equipment/EldenPurposeOfUse";
import EldenEquipmentGroup from "./pages/elden/equipment/EldenEquipmentGroup";
import EldenEquipmentType from "./pages/elden/equipment/EldenEquipmentType";
import EldenEquipmentTree from "./pages/elden/equipment/EldenEquipmentTree";
// import EldenEquipment from "./pages/elden/equipment/EldenEquipment";

/*--------./pages/elden/manufacturing---------- */
import ManufacturingWorkOrder from "./pages/elden/manufacturing/ManufacturingWorkOrder";

/*--------./pages/elden/engineering---------- */
import EldenServices from "./pages/elden/engineering/EldenServices";
import EldenCompounds from "./pages/elden/engineering/EldenCompounds";
import EldenMethodOfStatement from "./pages/elden/engineering/EldenMethodOfStatement";

/*import EldenActivities from "./pages/elden/engineering/EldenActivities";
import EldenDesignationActivityLog from "./pages/elden/engineering/EldenDesignationActivityLog";
import EldenLocationActivityLog from "./pages/elden/engineering/EldenLocationActivityLog";
import EldenWorkplaces from "./pages/elden/engineering/EldenWorkplaces";
import EldenActivityTypes from "./pages/elden/engineering/EldenActivityTypes";
import EldenItemClasses from "./pages/elden/engineering/EldenItemClasses"; */

import EquipmentType from "./pages/equipment/EquipmentType";
import FuelRefill from "./pages/equipment/FuelRefill";
import EquipmentLogMain from "./pages/equipment/EquipmentLogMain";
import ParkingLog from "./pages/equipment/ParkingLog";
import Breakdown from "./pages/equipment/Breakdown";

/*--------./pages/maintenance---------- */
import MaintenanceLog from "./pages/maintenance/MaintenanceLog";
import MaintenanceChecklist from "./pages/maintenance/MaintenanceChecklist";

/*--------./pages/elden/humanresources---------- */
import ReasonForExit from "./pages/elden/humanresources/ReasonForExit";
import EldenDepartment from "./pages/elden/humanresources/EldenDepartment";
import EldenDiscipline from "./pages/elden/humanresources/EldenDiscipline";
import EldenDesignation from "./pages/elden/humanresources/EldenDesignation";

/*--------./pages/elden/eldenService---------- */
/*import EldenServiceIdentifications from "./pages/elden/eldenService/EldenServiceIdentifications";*/

/*--------./pages/elden/stock---------- */
import EldenEquivalentStockCodesLog from "./pages/elden/stock/EldenEquivalentStockCodesLog";

/*--------./pages/elden/presentation---------- */

/*--------./pages/finance---------- */
import BankAccountsLog from "./pages/finance/BankAccountsLog";
import CashFlow from "./pages/finance/CashFlow";
import Cashes from "./pages/finance/Cashes";
import CashRequest from "./pages/finance/CashRequest";
import ChequeLog from "./pages/finance/ChequeLog";
import EmployeesSalary from "./pages/finance/EmployeesSalary";
import IncomePlan from "./pages/finance/IncomePlan";
import NoteLog from "./pages/finance/NoteLog";
import PaymentOrder from "./pages/finance/PaymentOrder";
import VatLog from "./pages/finance/VatLog";
import SalarySettings from "./pages/finance/SalarySettings";
import PersonnelAccount from "./pages/finance/PersonnelAccount";
import CompanyAssetOnEmployee from "./pages/finance/CompanyAssetOnEmployee";
import PaymentVendorContract from "./pages/finance/PaymentVendorContract";
import PaymentContractCustomer from "./pages/finance/PaymentContractCustomer";
import PaymentPlan from "./pages/finance/PaymentPlan";
import PaymentPlanSchedule from "./pages/finance/PaymentPlanSchedule";
import IncomePlanSchedule from "./pages/finance/IncomePlanSchedule";
import CashLogNew from "./pages/finance/CashLogNew";
import BankTransactionsLog from "./pages/finance/BankTransactionsLog";
import AgreementLog from "./pages/finance/AgreementLog";

/*--------./pages/planning---------- */

import { WorkOrder } from "./pages/planning/WorkOrder";
import { WorkStation } from "./pages/planning/WorkStation";
import ProjectPlanning from "./pages/planning/ProjectPlanning";
import ProjectsTreeCompany from "./pages/planning/projects/ProjectsTreeCompany";
import RawMaterialMonitoring from "./pages/planning/RawMaterialMonitoring";
import WorkOrders from "./pages/planning/manufacturing/WorkOrders";
import AvarageProductionTime from "./pages/planning/manufacturing/AvarageProductionTime";
import Apartment from "./pages/planning/Apartment";
import SanitarySewerLine from "./pages/planning/SanitarySewerLine";
import ToDo from "./pages/planning/ToDo";
import ActivityLog from "./pages/elden/planning/ActivityLog";

/*import ManufacturingPlanning from "./pages/planning/manufacturing/ManufacturingPlanning";
import MainComponent from "./pages/planning/MainComponent";
import Plan from "./pages/planning/Plan";
import Service from "./pages/planning/Service";
import Workflow from "./pages/planning/Workflow";
import PageApproval from "./pages/planning/PageApproval";
import ProjectCategory from "./pages/planning/ProjectCategory";
import ProjectComponents from "./pages/planning/ProjectComponents";
import ProjectService from "./pages/planning/ProjectService";
import MainProjects from "./pages/planning/MainProjects";
import Compounds from "./pages/planning/Compounds";
*/

import AppGant from "./pages/planning/AppGant";

/*--------./pages/procurement---------- */

import PurchaseAuthorization from "./pages/procurement/PurchaseAuthorization";
import PurchaseOrder from "./pages/procurement/PurchaseOrder";

import PurchaseRequisition from "./pages/procurement/PurchaseRequisition";
import PurchaseRequisitionNew from "./pages/procurement/PurchaseRequisitionNew";
import RequestForQuotation from "./pages/procurement/rfq/RequestForQuotation";
import ProcurementRequirements from "./pages/procurement/ProcurementRequirements";
import ProjectTakeOffMain from "./pages/procurement/ProjectTakeOffMain";
import StockControl from "./pages/procurement/stockcontrol/StockControl";
import ProcurementProgressNew from "./pages/procurement/ProcurementProgressNew";

import ProcurementLog from "./pages/procurement/ProcurementLog";
import StockControlApproval from "./pages/procurement/stockcontrol/StockControlApproval";
import Quotation from "./pages/procurement/Quotation";
import VendorScoring from "./pages/procurement/VendorScoring";

/*import RequestForPurchase from "./pages/procurement/RequestForPurchase";
import SupplEvaluation from "./pages/procurement/SupplEvaluation";
import MRChecklist from "./pages/procurement/MRChecklist";
import POPriceDelivery from "./pages/procurement/POPriceDelivery";
import GapAnalysisCompany from "./pages/procurement/GapAnalysisCompany";
import PurchaseInternalRequest from "./pages/procurement/PurchaseInternalRequest";
import ShippingRelease from "./pages/procurement/ShippingRelease";
import VendorSelectionOnTotal from "./pages/procurement/VendorSelectionOnTotal";
import InspectionAssignmentPackage from "./pages/procurement/InspectionAssignmentPackage";
import InspectionNotification from "./pages/procurement/InspectionNotification";
import InspectionResultClient from "./pages/procurement/InspectionResultClient";
import VendorQuestionnaryCompany from "./pages/procurement/VendorQuestionnaryCompany";
import InspectionResultNotification from "./pages/procurement/InspectionResultNotification";
import VendorEvaluation from "./pages/procurement/VendorEvaluation";
import ProcurementVendorPerformance from "./pages/procurement/ProcurementVendorPerformance";


*/

/*--------./pages/company---------- */

import CompanySettings from "./pages/company/CompanySettings";
import PerformanceCriterias from "./pages/company/PerformanceCriterias";

/*--------./pages/store---------- */
import CustomerAccount from "./pages/store/CustomerAccount";
import ExpenseLog from "./pages/store/ExpenseLog";
import Inventory from "./pages/store/Inventory";
import InventoryLog from "./pages/store/InventoryLog";
import Invoice from "./pages/store/Invoice";
import Product from "./pages/store/Product";
import ProductMovement from "./pages/store/ProductMovement";
import ProductStatistics from "./pages/store/ProductStatistics";
import SalesmanCashFlow from "./pages/store/SalesmanCashFlow";
import SalesReport from "./pages/store/SalesReport";
import Stock from "./pages/store/Stock";
import Vendor from "./pages/store/Vendor";
import DiscountPolicy from "./pages/store/DiscountPolicy";
import StockCard from "./pages/store/StockCard";
import ItemBreakdown from "./pages/store/ItemBreakdown";
import StockMovements from "./pages/store/StockMovements";
import PageAutoDesign from "./pages/Autogenerator/PageAutoDesign";
import PropertyTabs from "./pages/store/PropertyTabs";

/*import PropertyGroup from "./pages/store/PropertyGroup";
import Sector from "./pages/store/Sector";
import ItemCategory from "./pages/store/ItemCategory";
import UnitType from "./pages/store/UnitType";
import Item from "./pages/store/Item";
import { ItemProperty } from './pages/store/ItemProperty';*/

/*--------./pages/ibb---------- */
import ProjectLog from "./pages/ibb/projectLog/ProjectLog.js";
import ProposedProjectLog from "./pages/ibb/proposedProjectLog/ProposedProjectLog.js";
import TenderDesignLog from "./pages/ibb/tenderPreparation/TenderDesignLog.js";
import GapAnalysisCompany from "./pages/ibb/gapAnalysis/GapAnalysisCompany.js";

/*--------/elden/ibb----------*/
import EldenGapAnalysis from "./pages/elden/ibb/gapAnalysis/EldenGapAnalysis.js";

/*--------./pages/mnd---------- */
import CodingFormat from "./pages/mnd/CodingFormat";
import StockCardEquivalent from "./pages/store/StockCardEquivalent";
import { DocumentControl } from "./pages/documentation/DocumentControl";
/*--------./pages/sales---------- */
import ClientProject from "./pages/businessDevelopment/ClientProject";
import CompanyProject from "./pages/engineering/CompanyProject";
import Module from "./pages/engineering/Module";
import RoomQuantity from "./pages/engineering/RoomQuantity";
import RoomApplication from "./pages/engineering/RoomApplication";
import ProductLog from "./pages/sales/ProductLog";
import PriceList from "./pages/sales/PriceList";
import SalesQuotation from "./pages/sales/SalesQuotation";
/*import SalesmanOrders from "./pages/sales/SalesmanOrders.js";
import SalesmanAccount from "./pages/sales/salesmanAccount.js";*/

/*--------./pages/quality---------- */
import QualityChecklist from "./pages/quality/QualityChecklist";
import Check_list from "./pages/quality/Checklist";
import Sampling from "./pages/quality/Sampling";
import ControlPlan from "./pages/quality/ControlPlan";

/*--------./pages/tendering----------*/

import TenderingLog from "./pages/tendering/TenderingLog";
import BiddingLog from "./pages/tendering/bidding/BiddingLog";
import BillOfQuantity from "./pages/tendering/billOfQuantity/BillOfQuantity";

import ServiceStockCardLog from "./pages/tendering/ServiceStockCardLog";

/*--------./pages/rota----------*/
import JobTitle from "./pages/rota/JobTitle";
import Accommodation from "./pages/rota/Accommodation";
import RotaPersonnelLog from "./pages/rota/RotaPersonnelLog";
import JobSchedule from "./pages/rota/JobSchedule";
import PatientCareResponsibility from "./pages/rota/PatientCareResponsibility";
import RotaRules from "./pages/rota/RotaRules";

/*--------./pages/test----------*/
import CustomerLog from "./pages/businessDevelopment/CustomerLog";
import ModelView from "./pages/test/ModelView";
import EldenStockCard from "./pages/elden/stock/EldenStockCard";
import TestPage from "./pages/test/TestPage";
import PersonnelSalaryLog from "./pages/finance/PersonnelSalaryLog";
import TaxCodes from "./pages/elden/accounting/TaxCodes";
import TaxGroup from "./pages/elden/accounting/TaxGroup";
import BiddingLogQuotationIsolation from "./pages/tendering/quotation/BiddingLogQuotationIsolation";
import BiddingLogQuotationLaborShip from "./pages/tendering/quotation/BiddingLogQuotationLaborShip";
import BiddingLogQuotationFittings from "./pages/tendering/quotation/BiddingLogQuotationFittings";
import CostComparisionBiddingActual from "./pages/tendering/CostComparisionBiddingActual";
import StocksTree from "./pages/stock/StocksTree";
import MaintenanceControlPlan from "./pages/maintenance/MaintenanceControlPlan";
import MaintenanceWorkStation from "./pages/maintenance/MaintenanceWorkStation";
import MaintenanceWorkOrder from "./pages/maintenance/MaintenanceWorkOrder";
import MaintenanceAvarageProductionTime from "./pages/maintenance/MaintenanceAvarageProductionTime";
import MaintenanceTimeline from "./pages/maintenance/MaintenanceTimeline";
import MaintenancePlanning from "./pages/maintenance/MaintenancePlanning";
import WorkShops from "./pages/maintenance/WorkShops";
import TenderingSalary from "./pages/elden/accounting/EldenAccountingSalary";
import { EldenPresentation } from "./pages/elden/presentation/EldenPresentation";
import CompanyActivityLog from "./pages/engineering/CompanyActivityLog";
import EquipmentInsurance from "./pages/equipment/EquipmentInsurance";
import BudgetMaintenance from "./pages/finance/budget/BudgetMaintenance";
import Budget from "./pages/finance/budget/Budget";
import EldenEquipmentLogVrc from "./pages/elden/equipment/EldenEquipmentLogVrc.js";
import SoftwareLog from "./pages/finance/software/SoftwareLog.js";
import StockLevelsLog from "./pages/store/StockLevelsLog.js";
import PartRequirementList from "./pages/procurement/PartRequirementList.js";
import EldenStoreType from "./pages/elden/stock/EldenStoreType.js";
import EldenStoringType from "./pages/elden/stock/EldenStoringType.js";
import EldenInsideStoreArea from "./pages/elden/stock/EldenInsideStoreArea.js";
import MaintenanceSchedule from "./pages/maintenance/MaintenanceSchedule.js";
import EldenActivityPhase from "./pages/elden/engineering/EldenActivityPhase.js";
import { EldenHrRolesLog } from "./pages/elden/humanresources/EldenHrRolesLog.js";
import { EldenHrJobDescription } from "./pages/elden/humanresources/EldenHrJobDescription.js";
import MaintenancePlanLog from "./pages/maintenance/MaintenancePlanLog.js";
import SurrenderOfVehicleLog from "./pages/maintenance/SurrenderOfVehicleLog.js";
import DailyCheck from "./pages/maintenance/DailyCheck.js";
import PageGenerate from "./pages/Autogenerator/pageGenerate/PageGenerate.js";
import EldenManufacturer from "./pages/elden/EldenManufacturer.js";
import Route from "./pages/logistics/Route.js";
import RouteScheduleGantt from "./pages/logistics/RouteScheduleGantt.js";
import ProjectsIdentifications from "./pages/ibb/projectLog/ProjectsIdentifications.js";
import ProjectSummary from "./pages/ibb/projectLog/ProjectSummary.js";
import SalesmanOrder from "./pages/sales/SalesmanOrder.js";
import CompanyPersonnelLog from "./pages/company/CompanyPersonnelLog.js";

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }

        {/*--------/_partials----------*/}
        <ContentRouteUserOnly path="/user-profile" component={UserProfile} />

        {/*--------/error----------*/}
        <ContentRoute path="/error" component={ErrorPage1} />
        <ContentRoute path="/warningPages" component={WarningPages} />
        <ContentRoute path="/default" component={ErrorPage1} />
        <ContentRoute path="/authorization-error" component={AuthorizationError} />
        <ContentRoute path="/notfound-error" component={NotFoundError} />

        {/*--------/DashboardPage----------*/}
        <ContentRoute path="/dashboard" component={DashboardPage} />

        {/*--------/humanresources----------*/}
        <ContentRoute path="/humanresources/shifts" component={Shifts} />
        <ContentRoute path="/humanresources/timesheet" component={Timesheet} />
        <ContentRoute path="/humanresources/timesheet-workflow" component={TimesheetWorkflow} />
        <ContentRoute path="/humanresources/clinic" component={Clinic} />
        <ContentRoute path="/humanresources/employee-contract-settings" component={EmployeeContract} />
        <ContentRoute path="/humanresources/employee-request" component={EmployeeRequest} />
        <ContentRoute path="/humanresources/assignment-personnel" component={AssignmentPersonnel} />
        <ContentRoute path="/humanresources/organizationalcategory" component={OrganizationalCategory} />
        <ContentRoute path="/humanresources/training-schedule" component={TrainingSchedule} />
        <ContentRoute path="/humanresources/leave-request" component={LeaveRequest} />
        <ContentRoute path="/humanresources/organizational-chart" component={OrganizationalChart} />
        <ContentRoute path="/humanresources/role-description" component={RoleDescriptionCompany} />
        <ContentRoute path="/designation" component={Designation} />
        <ContentRoute path="/leave-policy" component={LeavePolicy} />
        <ContentRoute path="/leave-policy-group" component={LeavePolicyGroup} />
        <ContentRoute path="/overtimerequest" component={OvertimeRequest} />
        <ContentRoute path="/contract-documents" component={ContractDocument} />
        <ContentRoute path="/holiday-log" component={HolidayLog} />
        <ContentRoute path="/insurance-policy" component={InsurancePolicy} />
        <ContentRoute path="/roleGroup" component={RoleGroup} />
        <ContentRoute path="/personelInfo" component={PersonelInfo} />
        <ContentRoute path="/personnellog" component={PersonnelLog} />
        <ContentRoute path="/discipline" component={Discipline} />
        <ContentRoute path="/department" component={Department} />
        <ContentRoute path="/user-auth-itpdiscipline" component={UserItpDiscipline} />
        <ContentRoute path="/managementLevel" component={ManagementLevel} />
        <ContentRoute path="/organizationalLevel" component={OrganizationalLevel} />
        <ContentRoute path="/itp-discipline" component={ItpDiscipline} />
        <ContentRoute path="/designation-auth-discipline" component={DesignationDiscipline} />
        <ContentRoute path="/humanresources/exit" component={Exit} />
        <ContentRoute path="/humanresources/absenceinfractions" component={AbsenceInfractions} />
        <ContentRoute path="/humanresources/jobOffer" component={JobOffer} />
        <ContentRoute path="/humanresources/candidatePortal" component={CandidatePortal} />

        {/*--------/client-administration----------*/}
        <ContentRoute path="/client-administration/tickets" component={TicketMessagesExecutive} />
        <ContentRouteUserOnly path="/my-tickets" component={TimelineQRUserScan} />

        <ContentRoute path="/test/TestPage" component={TestPage} />
        {/*--------/procurement----------*/}
        <ContentRoute path="/procurement/purchase-requisition" component={PurchaseRequisition} />
        <ContentRoute path="/procurement/purchaseRequisitionNew" component={PurchaseRequisitionNew} />
        <ContentRoute path="/procurement/request-for-quotation" component={RequestForQuotation} />
        <ContentRoute path="/procurement/procurement-requirements" component={ProcurementRequirements} />
        <ContentRoute path="/procurement/purchase-authorization" component={PurchaseAuthorization} />
        <ContentRoute path="/procurement/purchase-order" component={PurchaseOrder} />
        <ContentRoute path="/project-take-off-list" component={ProjectTakeOffMain} />
        <ContentRoute path="/procurement/stock-control" component={StockControl} />
        <ContentRoute path="/procurement/procurementLog" component={ProcurementLog} />
        <ContentRoute path="/procurement/stock-control-approval" component={StockControlApproval} />
        <ContentRoute path="/procurement/quotation" component={Quotation} />
        <ContentRoute path="/procurement/part-requirement-list" component={PartRequirementList} />
        <ContentRoute path="/procurement/vendor-scoring" component={VendorScoring} />
        {/*
        
        <ContentRoute path="/procurement/gap-analysis" component={GapAnalysisCompany} />
        <ContentRoute path="/mrchecklist" component={MRChecklist} />
        <ContentRoute path="/procurement/vendor-questionnary-company" component={VendorQuestionnaryCompany} />
        <ContentRoute path="/procurement/procurement-vendor-performance" component={ProcurementVendorPerformance} />
        <ContentRoute path="/procurement/vendor-questionnary-company" component={VendorQuestionnaryCompany} />
        <ContentRoute path="/procurement/supplEvaluation" component={SupplEvaluation} />
        <ContentRoute path="/procurement/inspection-assignment-package" component={InspectionAssignmentPackage} />
        <ContentRoute path="/procurement/inspection-notification" component={InspectionNotification} />
        <ContentRoute path="/procurement/inspection-result-notification" component={InspectionResultNotification} />
        <ContentRoute path="/procurement/inspection-result-client" component={InspectionResultClient} />
        <ContentRoute path="/procurement/shipping-release" component={ShippingRelease} />
        <ContentRoute path="/procurement/vendor-evaluation" component={VendorEvaluation} />
        <ContentRoute path="/procurement/price-delivery" component={POPriceDelivery} />
        <ContentRoute path="/procurement/purchase-internal-request" component={RequestForPurchase} />
        <ContentRoute path="/procurement/vendor-selection" component={VendorSelectionOnTotal} /> *
        */}

        {/*--------/logistics----------*/}
        <ContentRoute path="/logistics/BusStops" component={BusStops} />
        <ContentRoute path="/logistics/Regions" component={Regions} />
        <ContentRoute path="/logistics/DriverCategory" component={DriverCategory} />
        <ContentRoute path="/logistics/SlopeRanges" component={SlopeRanges} />
        <ContentRoute path="/logistics/CurveRanges" component={CurveRanges} />
        {/*<ContentRoute path="/logistics/Route" component={Route} /> */}
        <ContentRoute path="/logistics/Drivers" component={Drivers} />
        <ContentRoute path="/logistics/Buses" component={Buses} />
        <ContentRoute path="/logistics/route" component={Route} />
        <ContentRoute path="/logistics/route-schedule" component={RouteScheduleGantt} />

        {/*--------/archive----------*/}
        <ContentRoute path="/folder" component={Folder} />
        <ContentRoute path="/trash" component={Trash} />
        <ContentRoute path="/file" component={File} />
        <ContentRoute path="/log" component={Log} />
        <ContentRoute path="/filling" component={Filling} />
        <ContentRoute path="/transmittal" component={TransmittalLog} />
        <ContentRoute path="/satiplog" component={SatipLog} />
        <ContentRoute path="/saiclog" component={SaicLog} />
        {/*<ContentRoute path="/itplog" component={ItpLog} />*/}

        <ContentRoute path="/checklist" component={Checklist} />
        <ContentRoute path="/inspectionlog" component={InspectionLog} />
        <ContentRoute path="/inspectionlog-item" component={InspectionLogItem} />
        <ContentRoute path="/authorization" component={UserCompany} />
        <ContentRoute path="/folder-auth" component={UserFolder} />
        <ContentRoute path="/itpactivity" component={ItpActivity} />
        {/*<ContentRoute path="/inspection-test-reports-report" component={InspectionTestReportLogReport} />*/}
        {/*<ContentRoute path="/inspection-test-report" component={InspectionTestReport} />*/}
        <PublicContentRoute path="/report/verify/:qrcode" component={ReportVerify} />

        {/*--------/Autogenerator----------*/}
        <ContentRoute path="/generatebackend" component={CreateJavaClass} />
        <ContentRoute path="/generatefrontend" component={FrontendGenerator} />
        <ContentRoute path="/generatedform" component={Generated} />
        <ContentRoute path="/autogenerator/coder-page-form" component={CoderPageForm} />
        <ContentRoute path="/autogenerator/page-auto-design" component={PageAutoDesign} />
        <ContentRoute path="/autogenerator/page-generate" component={PageGenerate} />

        {/*--------/stock----------*/}
        <ContentRoute path="/stock/stocksTreeMain" component={StocksTree} />

        {/*--------/company----------*/}
        <ContentRoute path="/company/companySettings" component={CompanySettings} />
        <ContentRoute path="/company/performanceCriterias" component={PerformanceCriterias} />
        <ContentRoute path="/companySetting/personnel-log" component={CompanyPersonnelLog} />

        {/*--------/store----------*/}
        <ContentRoute path="/vendor" component={Vendor} />
        <ContentRoute path="/inventory" component={Inventory} />
        <ContentRoute path="/inventorylog" component={InventoryLog} />
        <ContentRoute path="/stock" component={Stock} />
        <ContentRoute path="/invoice" component={Invoice} />
        <ContentRoute path="/store/product" component={Product} />
        <ContentRoute path="/store/salesstatistics" component={ProductStatistics} />
        <ContentRoute path="/store/productmovement" component={ProductMovement} />
        <ContentRoute path="/store/customeraccount" component={CustomerAccount} />
        <ContentRoute path="/store/expense" component={ExpenseLog} />
        <ContentRoute path="/store/salesman-cash" component={SalesmanCashFlow} />
        <ContentRoute path="/store/stock-card" component={StockCard} />
        <ContentRoute path="/store/discount-policy" component={DiscountPolicy} />
        <ContentRoute path="/store/item-breakdown" component={ItemBreakdown} />
        <ContentRoute path="/store/sales-report" component={SalesReport} />
        <ContentRoute path="/store/stock-card-equivalent" component={StockCardEquivalent} />
        <ContentRoute path="/store/stockMovements" component={StockMovements} />
        <ContentRoute path="/property" component={PropertyTabs} />
        <ContentRoute path="/store/stock-levels-log" component={StockLevelsLog} />

        {/*<ContentRoute path="/item" component={Item} />  
        <ContentRoute path="/property-group" component={PropertyGroup} />
        <ContentRoute path="/sector" component={Sector} />
        <ContentRoute path="/item-category" component={ItemCategory} />        
        <ContentRoute path="/unit-type" component={UnitType} />
        <ContentRoute path="/itemProperty" component={ItemProperty} />*/}

        {/*--------/clients----------*/}
        <ContentRoute path="/sa-engineering-procedures" component={SAEngineeringProcedure} />
        <ContentRoute path="/sa-engineering-standards" component={SaEngineeringStandard} />
        <ContentRoute path="/sa-materials-system-specifications" component={SAMaterialsSystemSpecification} />
        <ContentRoute path="/com-listing" component={ComListing} />

        {/*--------/administrator----------*/}
        <ContentRoute path="/administrator/dashboard-charts" component={DashboardChart} />
        <ContentRoute path="/administrator/user-dashboard-charts" component={UserDashboardChart} />
        <ContentRoute path="/administrator/page-form-input" component={PageFormInput} />
        <ContentRoute path="/administrator/page-form" component={PageForm} />
        <ContentRoute path="/administrator/page-form-input-company" component={PageFormInputCompany} />
        <ContentRoute path="/administrator/page-actions" component={PageAction} />
        <ContentRoute path="/administrator/dynamic-page/:name" component={DynamicPage} />
        <ContentRoute path="/project" component={Project} />
        <ContentRoute path="/user" component={User} />
        <ContentRoute path="/company" component={SubCompany} />
        <ContentRoute path="/info" component={Info} />
        <ContentRoute path="/howtouse" component={HowToUse} />
        <ContentRoute path="/change-password" component={ChangePassword} />
        <ContentRoute path="/code" component={CodeDefine} />
        <ContentRoute path="/utils/formattedmessage" component={GenerateFormattedMessagesJson} />
        <ContentRoute path="/menu" component={DynamicMenu} />
        <ContentRoute path="/menu-auth" component={MenuAuthorization} />
        <ContentRoute path="/language-settings" component={LanguageSettings} />
        <ContentRoute path="/language-settings2" component={LanguageSettings2} />
        <ContentRoute path="/pageinput" component={PageInput} />
        <ContentRoute path="/pageinput-company" component={PageInputCompany} />
        <ContentRoute path="/client" component={Company} />

        {/*--------/planning----------*/}

        <ContentRoute path="/planning/procurementprogress" component={ProcurementProgressNew} />
        <ContentRoute path="/planning/work-order" component={WorkOrder} />
        <ContentRoute path="/planning/work-station" component={WorkStation} />
        <ContentRoute path="/planning/projectPlanning" component={ProjectPlanning} />
        <ContentRoute path="/planning/rawMaterialMonitoring" component={RawMaterialMonitoring} />
        <ContentRoute path="/planning/timeline" component={WorkOrders} />
        <ContentRoute path="/planning/manufacturing/avarage-production-time" component={AvarageProductionTime} />
        <ContentRoute path="/planning/Apartment" component={Apartment} />
        <ContentRoute path="/planning/SanitarySewerLine" component={SanitarySewerLine} />
        <ContentRoute path="/planning/StormLine" component={StormLine} />
        <ContentRoute path="/planning/todo" component={ToDo} />
        <ContentRoute path="/planning/project-tree-company" component={ProjectsTreeCompany} />
        <ContentRoute path="/planning/AppGant" component={AppGant} />

        {/*<ContentRoute path="/planning/mainProjects" component={MainProjects} />
        <ContentRoute path="/planning/compounds" component={Compounds} />
        <ContentRoute path="/planning/workflow" component={Workflow} />
        <ContentRoute path="/planning/plan" component={Plan} />
        <ContentRoute path="/planning/service" component={Service} />
        <ContentRoute path="/planning/maincomponent" component={MainComponent} />
        <ContentRoute path="/planning/pageapproval" component={PageApproval} />
        
        <ContentRoute path="/planning/projectCategory" component={ProjectCategory} />
        <ContentRoute path="/planning/projectComponents" component={ProjectComponents} />
        <ContentRoute path="/planning/projectService" component={ProjectService} />
        <ContentRoute path="/planning/manufacturing-planning" component={ManufacturingPlanning} />*/}

        {/*--------/equipment----------*/}
        <ContentRoute path="/equipment/equipment-types" component={EquipmentType} />
        <ContentRoute path="/equipment/fuel-refill" component={FuelRefill} />
        <ContentRoute path="/equipment/equipment-log-main" component={EquipmentLogMain} />
        <ContentRoute path="/equipment/parking-log" component={ParkingLog} />
        <ContentRoute path="/equipment/insurance" component={EquipmentInsurance} />

        {/*--------/equipment----------*/}
        <ContentRoute path="/equipment/maintenance-log" component={MaintenanceLog} />
        <ContentRoute path="/maintenance/maintenance-checklist" component={MaintenanceChecklist} />
        <ContentRoute path="/maintenance/maintenance-control-plan" component={MaintenanceControlPlan} />
        <ContentRoute path="/maintenance/maintenance-work-station" component={MaintenanceWorkStation} />
        <ContentRoute path="/maintenance/maintenance-work-order" component={MaintenanceWorkOrder} />
        <ContentRoute path="/maintenance/maintenance-avarage-production-time" component={MaintenanceAvarageProductionTime} />
        <ContentRoute path="/maintenance/maintenance-timeline" component={MaintenanceTimeline} />
        <ContentRoute path="/maintenance/maintenance-planning" component={MaintenancePlanning} />
        <ContentRoute path="/maintenance/work-shops" component={WorkShops} />
        <ContentRoute path="/equipment/breakdown" component={Breakdown} />
        <ContentRoute path="/maintenance/schedule" component={MaintenanceSchedule} />
        <ContentRoute path="/maintenance/maintenance-plan-log" component={MaintenancePlanLog} />
        <ContentRoute path="/maintenance/surrender-of-vehicle-log" component={SurrenderOfVehicleLog} />
        <ContentRoute path="/maintenance/daily-check" component={DailyCheck} />

        {/*--------/elden----------*/}
        <ContentRoute path="/elden/invoice-templates" component={InvoiceTemplates} />
        <ContentRoute path="/elden/unitgroup" component={UnitGroup} />
        <ContentRoute path="/elden/unit" component={Unit} />
        <ContentRoute path="/elden/materialgroup" component={MaterialGroup} />
        <ContentRoute path="/elden/materialtype" component={MaterialType} />
        <ContentRoute path="/elden/itemgroup" component={ItemGroup} />
        <ContentRoute path="/elden/itemcategory" component={EldenItemCategory} />
        <ContentRoute path="/elden/propertygroup" component={EldenPropertyGroup} />
        <ContentRoute path="/elden/property" component={EldenProperty} />
        <ContentRoute path="/elden/itemdescription" component={EldenItem} />
        {/*<ContentRoute path="/elden/jointingmethod" component={JointingMethodItem} />*/}
        <ContentRoute path="/elden/quality/standard" component={EldenStandard} />
        <ContentRoute path="/elden/stock/stock-card" component={EldenStockCard} />
        <ContentRoute path="/elden/role-description" component={RoleDescription} />
        <ContentRoute path="/elden/laborlaw" component={LaborLaw} />
        <ContentRoute path="/elden/vendor-questionnary" component={VendorQuestionnary} />
        <ContentRoute path="/elden/vendor-performance" component={VendorPerformance} />
        <ContentRoute path="/elden/request-for-quotation-template" component={RequestForQuotationTemplate} />
        <ContentRoute path="/elden/presentations" component={PresentationSettings} />
        <ContentRoute path="/unit/conversion" component={UnitConverter} />
        <ContentRoute path="/elden/activity-planning" component={ActivityPlanning} />
        <ContentRoute path="/elden/quality/ChemicalComposition" component={ChemicalComposition} />
        <ContentRoute path="/elden/quality/profileWeights" component={ProfileWeights} />
        <ContentRoute path="/elden/planning/typeOfTests" component={TypeOfTests} />
        <ContentRoute path="/elden/DimensionDefinitions" component={DimensionDefinitions} />
        <ContentRoute path="/elden/Rename" component={Rename} />
        <ContentRoute path="/elden/Purchase Criteria" component={PurchaseCriteria} />
        <ContentRoute path="/elden/MaterialCategory" component={MaterialCategory} />
        <ContentRoute path="/elden/productForm" component={ProductForm} />
        <ContentRoute path="/elden/ColorTable" component={ColorTable} />
        <ContentRoute path="/elden/accounting/salary" component={TenderingSalary} />

        {/*--------/elden/stock----------*/}
        <ContentRoute path="/elden/stock/elden-equivalent-stock-codes-log" component={EldenEquivalentStockCodesLog} />
        <ContentRoute path="/elden/elden-store-type" component={EldenStoreType} />
        <ContentRoute path="/elden/elden-storing-type" component={EldenStoringType} />
        <ContentRoute path="/elden/elden-inside-store-area" component={EldenInsideStoreArea} />

        {/*--------/elden/presentation----------*/}
        <ContentRoute path="/elden/presentation" component={EldenPresentation} />

        {/*--------/elden/planning----------*/}
        <ContentRoute path="/elden/planning/projects" component={Projects} />

        {/*--------/elden/library----------*/}
        <ContentRoute path="/elden/ankaraCounty" component={AnkaraCounty} />
        <ContentRoute path="/elden/istanbulCounty" component={IstanbulCounty} />

        <ContentRoute path="/elden/planning/planningProjectTypes" component={PlanningProjectTypes} />
        <ContentRoute path="/elden/quality/mechanicalAndPhysicalTests" component={MechanicalAndPhysicalTests} />
        <ContentRoute path="/elden/planning/projectsTree" component={ProjectsTree} />
        <ContentRoute path="/elden/planning/elden-mobilization" component={EldenMobilization} />
        <ContentRoute path="/elden/planning/elden-budget-items" component={EldenBudgetItems} />
        <ContentRoute path="/elden/planning/activity-log" component={ActivityLog} />

        {/*<ContentRoute path="/elden/planning/mainProjectTypes" component={MainProjectTypes} />
        <ContentRoute path="/elden/planning/services" component={Services} />
        <ContentRoute path="/elden/planning/planningComponents" component={PlanningComponent} />*/}

        {/*--------/elden/accounting----------*/}
        <ContentRoute path="/elden/accounting/incomeOutgoingGroups" component={IncomeOutgoingGroups} />
        <ContentRoute path="/elden/accounting/accountingActivityCodes" component={AccountingActivityCodes} />
        <ContentRoute path="/elden/accounting/govermentAgency" component={GovermentAgency} />
        <ContentRoute path="/elden/accounting/taxPayers" component={TaxPayers} />
        <ContentRoute path="/elden/accounting/accountingLegalObligations" component={AccountingLegalObligations} />
        <ContentRoute path="/elden/accounting/muhtasarKodlar" component={MuhtasarKodlar} />
        <ContentRoute path="/elden/accounting/taxDescriptions" component={TaxDescriptions} />
        <ContentRoute path="/elden/accounting/taxSchedule" component={TaxSchedule} />
        <ContentRoute path="/elden/accounting/tax" component={Tax} />
        <ContentRoute path="/elden/accounting/naceCodes" component={NaceCodes} />
        <ContentRoute path="/elden/accounting/theUniformChartofAccounting" component={TheUniformChartofAccounting} />
        <ContentRoute path="/elden/accounting/tax-codes" component={TaxCodes} />
        <ContentRoute path="/elden/accounting/tax-group" component={TaxGroup} />

        {/*--------/elden/equipment----------*/}
        <ContentRoute path="/elden/equipment/equipmentIdentification" component={EquipmentIdentification} />
        <ContentRoute path="/elden/equipment/elden-equipment-log" component={EldenEquipmentLog} />
        <ContentRoute path="/elden/equipment/elden-purpose-of-use" component={EldenPurposeOfUse} />
        <ContentRoute path="/elden/equipment/elden-equipment-group" component={EldenEquipmentGroup} />
        <ContentRoute path="/elden/equipment/elden-equipment-type" component={EldenEquipmentType} />
        <ContentRoute path="/elden/equipment/elden-equipment-tree" component={EldenEquipmentTree} />
        <ContentRoute path="/elden/equipment/elden-equipment-log-vrc" component={EldenEquipmentLogVrc} />
        <ContentRoute path="/elden/manufacturer" component={EldenManufacturer} />

        {/* <ContentRoute path="/elden/equipment/elden-equipment" component={EldenEquipment} /> */}

        {/*--------/elden/manufacturing----------*/}
        <ContentRoute path="/elden/manufacturing/manufacturingWorkOrder" component={ManufacturingWorkOrder} />

        {/*--------/elden/quality----------*/}
        <ContentRoute path="/elden/quality/dimension-tolerences" component={Dimension} />
        <ContentRoute path="/elden/quality/inspection-types" component={InspectionTypes} />
        <ContentRoute path="/elden/quality/grades" component={Grades} />
        <ContentRoute path="/elden/quality/periodicTable" component={PeriodicTable} />

        {/*--------/elden/standards----------*/}
        <ContentRoute path="/elden/quality/CEV" component={CEV} />

        {/*--------/elden/engineering----------*/}
        <ContentRoute path="/elden/engineering/room-application" component={RoomApplication} />
        <ContentRoute path="/elden/engineering/elden-method-of-statement" component={EldenMethodOfStatement} />
        <ContentRoute path="/elden/engineering/elden-services" component={EldenServices} />
        <ContentRoute path="/elden/engineering/elden-compounds" component={EldenCompounds} />
        <ContentRoute path="/elden/engineering/activity-phase" component={EldenActivityPhase} />
        {/*<ContentRoute path="/elden/engineering/elden-activity-types" component={EldenActivityTypes} />
        <ContentRoute path="/elden/engineering/elden-activities" component={EldenActivities} />*/}

        {/*<ContentRoute path="/elden/engineering/elden-designation-activity-log" component={EldenDesignationActivityLog} />
        <ContentRoute path="/elden/engineering/elden-location-activity-log" component={EldenLocationActivityLog} />
        <ContentRoute path="/elden/engineering/elden-workplaces" component={EldenWorkplaces} />
        <ContentRoute path="/elden/engineering/elden-item-classes" component={EldenItemClasses} />*/}

        {/*--------/elden/humanresources----------*/}
        <ContentRoute path="/elden/humanresources/reasonForExit" component={ReasonForExit} />
        <ContentRoute path="/elden/humanresources/elden-department" component={EldenDepartment} />
        <ContentRoute path="/elden/humanresources/elden-discipline" component={EldenDiscipline} />
        <ContentRoute path="/elden/humanresources/elden-designation" component={EldenDesignation} />
        <ContentRoute path="/elden/humanresources/elden-roles-log" component={EldenHrRolesLog} />
        <ContentRoute path="/elden/humanresources/elden-job-description" component={EldenHrJobDescription} />

        {/*--------/elden/eldenService----------*/}
        {/*<ContentRoute path="/elden/eldenService/elden-service-identifications" component={EldenServiceIdentifications} />*/}

        {/*--------/elden/ibb----------*/}
        <ContentRoute path="/ibb/elden/gap-analysis" component={EldenGapAnalysis} />

        {/*--------/client-administration----------*/}
        <ContentRoute path="/client-administration/timeline-qrcode-generator" component={TimelineQRGenerator} />
        <ContentRoute path="/client-administration/timeline-qrcode-settings" component={TimelineQRSettings} />
        <ContentRoute path="/client-administration/timeline-qrcode-scanner" component={TimelineQRUserScan} />

        {/*--------/tendering----------*/}
        <ContentRoute path="/tendering/tendering-Log" component={TenderingLog} />
        <ContentRoute path="/tendering/bidding-Log" component={BiddingLog} />
        <ContentRoute path="/tendering/bill-of-quantity" component={BillOfQuantity} />
        <ContentRoute path="/tendering/service-stock-card-log" component={ServiceStockCardLog} />
        <ContentRoute path="/tendering/bidding-log-isolation" component={BiddingLogQuotationIsolation} />
        <ContentRoute path="/tendering/bidding-log-labor-ship" component={BiddingLogQuotationLaborShip} />
        <ContentRoute path="/tendering/bidding-log-fittings" component={BiddingLogQuotationFittings} />
        <ContentRoute path="/tendering/cost-comparision-bidding-actual" component={CostComparisionBiddingActual} />

        {/*--------/finance----------*/}
        <ContentRoute path="/finance/bank-accounts-log" component={BankAccountsLog} />
        <ContentRoute path="/finance/cashes" component={Cashes} />
        <ContentRoute path="/finance/notelog" component={NoteLog} />
        <ContentRoute path="/finance/vatlog" component={VatLog} />
        <ContentRoute path="/finance/cashflow" component={CashFlow} />
        <ContentRoute path="/finance/paymentplan" component={PaymentPlan} />
        <ContentRoute path="/finance/incomeplan" component={IncomePlan} />
        <ContentRoute path="/finance/personnel-account" component={PersonnelAccount} />
        <ContentRoute path="/finance/company-asset-on-employee" component={CompanyAssetOnEmployee} />
        <ContentRoute path="/finance/cash-request" component={CashRequest} />
        <ContentRoute path="/finance/vendor-contract" component={PaymentVendorContract} />
        <ContentRoute path="/finance/customer-contract" component={PaymentContractCustomer} />
        <ContentRoute path="/finance/cheque-log" component={ChequeLog} />
        <ContentRoute path="/finance/payment-order" component={PaymentOrder} />
        <ContentRoute path="/finance/employees-salary" component={EmployeesSalary} />
        <ContentRoute path="/finance/salary-settings" component={SalarySettings} />
        <ContentRoute path="/finance/paymentlog" component={PaymentPlanSchedule} />
        <ContentRoute path="/finance/incomelog" component={IncomePlanSchedule} />
        <ContentRoute path="/finance/cashLogNew" component={CashLogNew} />
        <ContentRoute path="/finance/bankTransactionsLog" component={BankTransactionsLog} />
        <ContentRoute path="/finance/personnel-salary-log" component={PersonnelSalaryLog} />
        <ContentRoute path="/finance/agreement-log" component={AgreementLog} />
        <ContentRoute path="/finance/budget" component={Budget} />
        <ContentRoute path="/finance/budget-maintenance" component={BudgetMaintenance} />
        <ContentRoute path="/finance/software" component={SoftwareLog} />

        {/*--------/documentation----------*/}
        <ContentRoute path="/documentation/glossary" component={Glossary} />
        <ContentRoute path="/documentation/flowchart" component={FlowChart} />
        <ContentRoute path="/documentation/react-flow-chart" component={ReactFlowChart} />
        <ContentRoute path="/documentation/react3D" component={React3D} />
        <ContentRoute path="/documentation/meeting" component={Meeting} />
        <ContentRoute path="/documentation/auditLog" component={AuditLog} />
        <ContentRoute path="/documentation/text-formatting" component={TextFormatting} />
        <ContentRoute path="/documentation/document-control" component={DocumentControl} />
        <ContentRoute path="/documentation/document-type" component={DocumentType} />
        <ContentRoute path="/documentation/document-log" component={DocumentLog} />
        <ContentRoute path="/documentation/ContractLog" component={ContractLog} />
        <ContentRoute path="/documentation/AutoReport" component={AutoReport} />
        <ContentRoute path="/documentation/presentation" component={Presentation} />
        <ContentRoute path="/documentation/text-to-para" component={TextToPara} />
        <ContentRoute path="/documentation/BookCreator" component={BookCreator} />

        {/*--------/ibb----------*/}
        <ContentRoute path="/ibb/projectLog" component={ProjectLog} />
        <ContentRoute path="/ibb/proposedProjectLog/ProposedProjectLog" component={ProposedProjectLog} />
        <ContentRoute path="/ibb/tenderPreparation/design-log" component={TenderDesignLog} />
        <ContentRoute path="/ibb/gap-analysis" component={GapAnalysisCompany} />
        <ContentRoute path="/ibb/project-identifications" component={ProjectsIdentifications} />
        <ContentRoute path="/ibb/project-summary" component={ProjectSummary} />

        {/*--------/mnd----------*/}
        <ContentRoute path="/mnd/coding-format" component={CodingFormat} />

        {/*--------/sales----------*/}
        <ContentRoute path="/businessDevelopment/clientProject" component={ClientProject} />
        <ContentRoute path="/sales/product-log" component={ProductLog} />
        <ContentRoute path="/sales/price-list" component={PriceList} />
        <ContentRoute path="/sales/quotationlog" component={SalesQuotation} />
        <ContentRoute path="/sales/salesman-order" component={SalesmanOrder} />
        {/*<ContentRoute path="/sales/salesmanOrders" component={SalesmanOrders} />
        <ContentRoute path="/sales/salesmanAccount" component={SalesmanAccount} />*/}

        {/*--------/engineering----------*/}
        <ContentRoute path="/engineering/company-project" component={CompanyProject} />
        <ContentRoute path="/engineering/roomQuantity" component={RoomQuantity} />
        <ContentRoute path="/engineering/module" component={Module} />
        <ContentRoute path="/drawing" component={Drawing} />
        <ContentRoute path="/engineering/activity-log" component={CompanyActivityLog} />
        {/*--------/quality----------*/}
        <ContentRoute path="/quality/qualityChecklist" component={QualityChecklist} />

        <ContentRoute path="/quality/checklist" component={Check_list} />
        <ContentRoute path="/quality/sampling" component={Sampling} />
        <ContentRoute path="/quality/controlPlan" component={ControlPlan} />

        {/*--------/test----------*/}
        <ContentRoute path="/test/typeOfTests" component={TypeOfTests} />
        <ContentRoute path="/test/modelView" component={ModelView} />

        {/*--------/planning----------*/}
        <ContentRoute path="/businessDevelopment/customerLog" component={CustomerLog} />

        {/*--------/quality----------*/}
        <ContentRoute path="/quality/QualityChecklist" component={QualityChecklist} />
        <ContentRoute path="/quality/TypeOfTests" component={TypeOfTests} />

        {/* --------/rota-------- */}
        <ContentRoute path="/rota/job-title" component={JobTitle} />
        <ContentRoute path="/rota/accommodation" component={Accommodation} />
        <ContentRoute path="/rota/rota-personnel-log" component={RotaPersonnelLog} />
        <ContentRoute path="/rota/job-schedule" component={JobSchedule} />
        <ContentRoute path="/rota/patient-care-responsibility" component={PatientCareResponsibility} />
        <ContentRoute path="/rota/rota-rules" component={RotaRules} />

        <Redirect to="error" />
      </Switch>
    </Suspense>
  );
}
