import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Input, Tree, Button as AntButton, Checkbox, Form, Modal as AntModal, Select, Badge, Space, Table } from "antd";
import { FcFolder, FcEditImage, FcAddressBook } from "react-icons/fc";
import { Menu, Item, useContextMenu } from "react-contexify";
import { FcTreeStructure, FcBiotech, FcMediumPriority, FcLibrary } from "react-icons/fc";
import { MdDelete } from "react-icons/md";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../../ApiConnector";
import { showError, showSuccess, error } from "../../../MessageHelper";
import DraggableModal from "../../../Components/DraggableModal";
import { ZoomInOutlined } from "@ant-design/icons";
import EldenMobilizationAssign from "./EldenMobilizationAssign";

const Menu_Mobilization = "Menu_Mobilization";
const Menu_Title = "Menu_Title";
const Menu_SubTitle = "Menu_SubTitle";
const Menu_Input = "Menu_Input";

const { Option } = Select;
//const { TextArea } = Input;

const { show } = useContextMenu();

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

export class EldenMobilization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "EldenMobilization",
      controllerName: "eldenMobilization",
      id: null,
      description: "",
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      loadedKeys: [],
      searchValue: "",
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showTitleModal: false,
      refreshTree: true
    };
  }

  inputRef = React.createRef(null);

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "Page"
    };

    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      if (treenode.type === "Title") {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsTitle/" + obj.id);
      } else if (treenode.type === "SubTitle") {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsSubTitle/" + obj.id);
      } else if (treenode.type === "Input") {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsInput/" + obj.id);
      } else {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsMobilization/" + obj.id);
      }
    }
    if (Boolean(response) && response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.icon =
          Boolean(element.type) && element.type === "Service" ? (
            <FcLibrary />
          ) : element.type === "Title" ? (
            <FcTreeStructure />
          ) : element.type === "SubTitle" ? (
            <FcAddressBook />
          ) : element.type === "Input" ? (
            <FcBiotech />
          ) : element.type === "Format" ? (
            <FcMediumPriority />
          ) : (
            <FcFolder />
          );

        element.title = element.value;
        element.id = element.key;
        element.key = element.key + "-" + element.type;
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      this.setState({
        nodes: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  formRef = React.createRef();
  formRefTitle = React.createRef();
  formRefSubTitle = React.createRef();
  formRefInput = React.createRef();
  formRefFormat = React.createRef();

  componentDidMount = async () => {
    this.fillTree();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseInputFormatIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/inputFormat");
    if (responseInputFormatIdSelectItems.type === "ERROR") {
      error(responseInputFormatIdSelectItems);
    }
    this.setState({
      inputFormatIdSelectItems: Boolean(responseInputFormatIdSelectItems.data) ? responseInputFormatIdSelectItems.data : []
    });
    var responseValueIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/value");
    if (responseValueIdSelectItems.type === "ERROR") {
      error(responseValueIdSelectItems);
    }
    this.setState({
      valueIdSelectItems: Boolean(responseValueIdSelectItems.data) ? responseValueIdSelectItems.data : []
    });
    var responseValuePlusIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/valuePlus");
    if (responseValuePlusIdSelectItems.type === "ERROR") {
      error(responseValuePlusIdSelectItems);
    }
    this.setState({
      valuePlusIdSelectItems: Boolean(responseValuePlusIdSelectItems.data) ? responseValuePlusIdSelectItems.data : []
    });
    var responseValuePlusLinkIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/valuePlusLink");
    if (responseValuePlusLinkIdSelectItems.type === "ERROR") {
      error(responseValuePlusLinkIdSelectItems);
    }
    this.setState({
      valuePlusLinkIdSelectItems: Boolean(responseValuePlusLinkIdSelectItems.data) ? responseValuePlusLinkIdSelectItems.data : []
    });
    var responseUserActionIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/userAction");
    if (responseUserActionIdSelectItems.type === "ERROR") {
      error(responseUserActionIdSelectItems);
    }
    this.setState({
      userActionIdSelectItems: Boolean(responseUserActionIdSelectItems.data) ? responseUserActionIdSelectItems.data : []
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "Mobilization";
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  onRightClick = (event, node) => {
    if (!(Boolean(this.props) && Boolean(this.props.onTargetKeysCheck))) {
      let parentNode = this.getParentNode(node);
      this.setState(
        {
          selectedNodeKey: node.key,
          selectedNode: node,
          parentNode: parentNode
        },
        this.displayMenu(event, Boolean(node) && Boolean(node.type) ? node.type : null)
      );
    }
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  handleItemClick = ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;

    console.log("AAA", event.currentTarget.id);
    console.log(selectedNode);
    console.log(parentNode);
    console.log(key);

    // eslint-disable-next-line default-case
    switch (event.currentTarget.id) {
      case "create_title":
        this.setState({
          showTitleModal: true,
          parentId: selectedNode.id,
          type: "Title"
        });
        break;

      case "create_subTitle":
        this.setState({
          showSubTitleModal: true,
          eldenMobilizationTitleId: selectedNode.id,
          type: "SubTitle"
        });
        break;

      case "create_subTitleSubTitle":
        this.setState({
          showSubTitleSubTitleModal: true,
          parentId: selectedNode.id,
          type: "SubTitle"
        });
        break;

      case "create_input":
        this.setState({
          showInputModal: true,
          eldenMobilizationTitleId: selectedNode.id,
          type: "Input"
        });
        break;

      case "create_inputSubTitle":
        this.setState({
          showInputSubTitleModal: true,
          eldenMobilizationSubTitleId: selectedNode.id,
          type: "Input"
        });
        break;

      case "create_format":
        this.setState({
          showFormatModal: true,
          eldenMobilizationInputId: selectedNode.id,
          type: "Format"
        });
        break;

      case "edit_title":
        this.setState({
          showEditTitleModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        }, async () => {
          var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getTitle/" + selectedNode.id);
          if (response.type === "ERROR") {
            error(response);
          }
          this.formRefTitle.current.setFieldsValue({
            ...response.data
          });
        });
        break;

      case "edit_subTitle":
        this.setState({
          showEditSubTitleModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        }, async () => {
          var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getSubTitle/" + selectedNode.id);
          if (response.type === "ERROR") {
            error(response);
          }
          this.formRefSubTitle.current.setFieldsValue({
            ...response.data
          });
        });
        break;

      case "edit_input":
        this.setState({
          showEditInputModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          eldenMobilizationSubTitleId: selectedNode.id,
          type: selectedNode.type
        }, async () => {
          var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getInput/" + selectedNode.id);
          if (response.type === "ERROR") {
            error(response);
          }
          this.formRefInput.current.setFieldsValue({
            ...response.data
          });
        });
        break;

      case "delete_title":
        this.setState({
          showDeleteTitleModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;

      case "delete_subTitle":
        this.setState({
          showDeleteSubTitleModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;

      case "delete_input":
        this.setState({
          showDeleteInputModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;
    }
  };

  deleteTitle = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteTitle/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteTitleModal: false
        });
      }
    } else {
      showError("Location not found.");
    }
  };

  deleteSubTitle = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteSubTitle/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteSubTitleModal: false
        });
      }
    } else {
      showError("Location not found.");
    }
  };

  deleteInput = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteInput/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteInputModal: false
        });
      }
    } else {
      showError("Location not found.");
    }
  };

  displayMenu = (e, type) => {
    console.log("TTT", type);
    if (Boolean(type)) {
      if (type === "Mobilization") {
        show(e, { id: Menu_Mobilization });
      } else if (type === "Title") {
        show(e, { id: Menu_Title });
      } else if (type === "SubTitle") {
        show(e, { id: Menu_SubTitle });
      } else if (type === "Input") {
        show(e, { id: Menu_Input });
      }
    }
  };

  saveTitle = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveTitle", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateTitle/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showTitleModal: false,
        showEditTitleModal: false
      });
    }
  };

  saveSubTitle = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      eldenMobilizationTitleId: this.state.eldenMobilizationTitleId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveSubTitle", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateSubTitle/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showSubTitleModal: false,
        showEditSubTitleModal: false
      });
    }
  };

  saveSubTitleSubTitle = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveSubTitle", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateSubTitle/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showSubTitleSubTitleModal: false,
        showEditSubTitleSubTitleModal: false
      });
    }
  };

  saveInput = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      eldenMobilizationTitleId: this.state.eldenMobilizationTitleId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveInput", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateInput/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showInputModal: false,
        showEditInputModal: false
      });
    }
  };

  saveInputSubTitle = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      eldenMobilizationSubTitleId: this.state.eldenMobilizationSubTitleId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveInput", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateInput/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showInputSubTitleModal: false,
        showEditInputSubTitleModal: false
      });
    }
  };

  saveFormat = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      eldenMobilizationInputId: this.state.eldenMobilizationInputId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveFormat", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateFormat/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showFormatModal: false
      });
    }
  };

  paste = () => {
    this.formRef.current.submit();
    this.formRefTitle.current.submit();
    this.formRefSubTitle.current.submit();
    this.formRefInput.current.submit();
    this.formRefFormat.current.submit();
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      description: "",
      code: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      process: null,
      showOrderModal: false,
      showPasteModal: false,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showTitleModal: false
    });
  };

  expandSelectedNode = () => {
    let key = this.state.selectedNodeKey;
    let node = this.state.selectedNode;
    if (Boolean(key)) {
      let expandedKeys = Boolean(this.state.expandedKeys) ? this.state.expandedKeys : [];
      if (Boolean(expandedKeys.find(p => p === key))) {
        this.loadData(node, false);
      } else {
        this.setState({
          expandedKeys: [...expandedKeys, key]
        });
        this.loadData(node, false);
      }
    }
  };

  itemTemplate(item) {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <h5 className="mb-2">{item.title}</h5>
        </div>
      </div>
    );
  }

  onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...this.state.nodes];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 &&
      // Has children
      info.node.props.expanded &&
      // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    this.setState({
      nodes: data
    });
  };

  onCheck = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    if (Boolean(this.props) && Boolean(this.props.onTargetKeysCheck)) {
      console.log("checkedNodes", e.checkedNodes);
      console.log("halfCheckedKeys", e.halfCheckedKeys);

      let keys = [];
      if (Boolean(checkedKeys) && checkedKeys.length > 0) {
        checkedKeys.forEach(element => {
          keys.push(element);
        });
      }

      if (Boolean(e.halfCheckedKeys) && e.halfCheckedKeys.length > 0) {
        e.halfCheckedKeys.forEach(element => {
          keys.push(element);
        });
      }

      this.props.onTargetKeysCheck(keys);
    }
    this.setState({
      checkedKeys: checkedKeys,
      halfCheckedKeys: e.halfCheckedKeys,
      checkedNodes: e.checkedNodes
    });
    let row = null;
    if (Boolean(e.checkedNodes) && e.checkedNodes.length > 0) {
      row = e.checkedNodes[e.checkedNodes.length - 1];
    }
    if (Boolean(row) && Boolean(row.id) && row.id > 0) {
      let eldenMobilizationTitleId = -1;
      let eldenMobilizationSubTitleId = -1;
      if (row.type === "Title") {
        eldenMobilizationTitleId = row.id;
      } else if (row.type === "SubTitle") {
        eldenMobilizationSubTitleId = row.id;
      }
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/monpower/" + eldenMobilizationTitleId + "/" + eldenMobilizationSubTitleId + "/" +
        this.props.tenderingLogId
      );
      if (response.type === "ERROR") {
        error(response);
      }
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          eldenMobilizationTitleId: eldenMobilizationTitleId,
          eldenMobilizationSubTitleId: eldenMobilizationSubTitleId,
          showMobilizationAssignModal: true,
          monpowerSelectItems: Boolean(response.data) ? response.data : [],
          assignRowType: row.type,
        });
      }
    }
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const columnsManpower = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="EldenMobilization.NumberOfPerson" defaultMessage="Number Of Person" />,
        key: "id",
        render: record => {
          return record.numberOfPerson;
        },
      },
      {
        title: <FormattedMessage id="EldenMobilization.Department" defaultMessage="Department" />,
        key: "id",
        render: record => {
          return record.departmentName;
        },
      },
      {
        title: <FormattedMessage id="EldenMobilization.Designation" defaultMessage="Designation" />,
        key: "id",
        render: record => {
          return record.designationName;
        },
      },
      {
        title: <FormattedMessage id="EldenMobilization.Type" defaultMessage="Type" />,
        key: "id",
        render: record => {
          return record.usedRoomUnit;
        },
      },
      {
        title: <FormattedMessage id="EldenMobilization.Assing" defaultMessage="Assing" />,
        key: "id",
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: "blue",
                }}
                onClick={() =>
                  this.setState({
                    modalAssignShow: true,
                    assignRow: record,
                    random: Math.random(),
                  })}
              >
                <FormattedMessage id="EldenMobilization.Assing" defaultMessage="Assing" />
              </div>
            </React.Fragment>
          );
        }
      },
    ];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage={"Elden Mobilization "} />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <br />
          <br />


          {this.state.refreshTree &&
            (Boolean(this.props) && Boolean(this.props.onTargetKeysCheck) ? (
              <Tree
                style={{ marginTop: "10px", lineHeight: "30px" }}
                className="draggable-tree"
                showLine={{ showLeafIcon: false }}
                showIcon
                checkStrictly={false}
                loadedKeys={this.state.loadedKeys}
                onLoad={this.onLoadData}
                loadData={this.loadData}
                onRightClick={({ event, node }) => this.onRightClick(event, node)}
                onExpand={this.onExpand}
                expandedKeys={this.state.expandedKeys}
                autoExpandParent={false}
                treeData={this.state.nodes}
                blockNode={true}
                draggable
                checkable
                onCheck={this.onCheck}
                checkedKeys={this.state.checkedKeys}
                halfCheckedKeys={this.state.halfCheckedKeys}
                onDragEnter={this.onDragEnter}
                onDrop={this.onDrop}
              />
            ) : (
              <Tree
                style={{ marginTop: "10px", lineHeight: "30px" }}
                className="draggable-tree"
                showLine={{ showLeafIcon: false }}
                showIcon
                checkStrictly={true}
                loadedKeys={this.state.loadedKeys}
                onLoad={this.onLoadData}
                loadData={this.loadData}
                onRightClick={({ event, node }) => this.onRightClick(event, node)}
                onExpand={this.onExpand}
                expandedKeys={this.state.expandedKeys}
                autoExpandParent={false}
                treeData={this.state.nodes}
                blockNode={true}
                // draggable
                onDragEnter={this.onDragEnter}
                onDrop={this.onDrop}
              />
            ))}

          <Menu id={Menu_Mobilization}>
            <Item id="create_title" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenMobilization.CreateTitle" defaultMessage="Create Title" />
                </span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Title}>
            <Item id="edit_title" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenMobilization.Edit" defaultMessage="Edit" />
                </span>
              </Space>
            </Item>
            <Item id="create_subTitle" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenMobilization.CreateSubTitle" defaultMessage="Create Sub-Title" />
                </span>
              </Space>
            </Item>
            <Item id="create_input" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenMobilization.CreateInput" defaultMessage="Create Input" />
                </span>
              </Space>
            </Item>
            <Item id="delete_title" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="EldenMobilization.Delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_SubTitle}>
            <Item id="edit_subTitle" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenMobilization.Edit" defaultMessage="Edit" />
                </span>
              </Space>
            </Item>
            <Item id="create_subTitleSubTitle" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenMobilization.CreateSubTitle" defaultMessage="Create Sub-Title" />
                </span>
              </Space>
            </Item>
            <Item id="create_inputSubTitle" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenMobilization.CreateInput" defaultMessage="Create Input" />
                </span>
              </Space>
            </Item>
            <Item id="delete_subTitle" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="EldenMobilization.Delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Input}>
            <Item id="edit_input" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenMobilization.Edit" defaultMessage="Edit" />
                </span>
              </Space>
            </Item>
            <Item id="create_format" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenMobilization.CreateInput" defaultMessage="Create Format" />
                </span>
              </Space>
            </Item>
            <Item id="delete_input" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="EldenMobilization.Delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>

          <DraggableModal
            title={<FormattedMessage id="EldenMobilization.Title" defaultMessage="Title" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showTitleModal}
            onOk={() => this.formRefTitle.current.submit()}
            onCancel={() => {
              this.setState({ showTitleModal: false });
            }}
            content={
              <>
                <Form initialValues={{ remember: false }} onFinish={this.saveTitle} onFinishFailed={onFinishFailed} ref={this.formRefTitle}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenMobilization.Title" defaultMessage="Title" />}
                    name="title"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantity.MatchWithPersonnel" defaultMessage="Match with personnel" />}
                    name="matchWithPersonnel"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox></Checkbox>
                  </Form.Item>
                </Form>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenMobilization.Sub-Title" defaultMessage="Sub-Title" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showSubTitleModal}
            onOk={() => this.formRefSubTitle.current.submit()}
            onCancel={() => {
              this.setState({ showSubTitleModal: false });
            }}
            content={
              <>
                <Form initialValues={{ remember: false }} onFinish={this.saveSubTitle} onFinishFailed={onFinishFailed} ref={this.formRefSubTitle}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenMobilization.Sub-Title" defaultMessage="Sub-Title" />}
                    name="subTitle"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantity.MatchWithPersonnel" defaultMessage="Match with personnel" />}
                    name="matchWithPersonnel"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox></Checkbox>
                  </Form.Item>
                </Form>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenMobilization.Sub-Title" defaultMessage="Sub-Title" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showSubTitleSubTitleModal}
            onOk={() => this.formRefSubTitle.current.submit()}
            onCancel={() => {
              this.setState({ showSubTitleSubTitleModal: false });
            }}
            content={
              <>
                <Form
                  initialValues={{ remember: false }}
                  onFinish={this.saveSubTitleSubTitle}
                  onFinishFailed={onFinishFailed}
                  ref={this.formRefSubTitle}
                >
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenMobilization.Sub-Title" defaultMessage="Sub-Title" />}
                    name="subTitle"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantity.MatchWithPersonnel" defaultMessage="Match with personnel" />}
                    name="matchWithPersonnel"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox></Checkbox>
                  </Form.Item>
                </Form>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenMobilization.Input" defaultMessage="Input" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showInputModal}
            onOk={() => this.formRefInput.current.submit()}
            onCancel={() => {
              this.setState({ showInputModal: false });
            }}
            content={
              <>
                <Form initialValues={{ remember: false }} onFinish={this.saveInput} onFinishFailed={onFinishFailed} ref={this.formRefInput}>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenMobilization.Input" defaultMessage="Input" />}
                      name="input"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                </Form>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenMobilization.Input" defaultMessage="Input" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showInputSubTitleModal}
            onOk={() => this.formRefInput.current.submit()}
            onCancel={() => {
              this.setState({ showInputSubTitleModal: false });
            }}
            content={
              <>
                <Form initialValues={{ remember: false }} onFinish={this.saveInputSubTitle} onFinishFailed={onFinishFailed} ref={this.formRefInput}>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenMobilization.Input" defaultMessage="Input" />}
                      name="input"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                </Form>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenMobilization.Format" defaultMessage="Format" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showFormatModal}
            onOk={() => this.formRefFormat.current.submit()}
            onCancel={() => {
              this.setState({ showFormatModal: false });
            }}
            content={
              <>
                <Form initialValues={{ remember: false }} onFinish={this.saveFormat} onFinishFailed={onFinishFailed} ref={this.formRefFormat}>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenMobilization.Input" defaultMessage="Input" />}
                      name="input"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }

                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenMobilization.InputFormat" defaultMessage="Input Format" />}
                      name="inputFormatId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["inputFormatIdSelectItems"]) &&
                          this.state["inputFormatIdSelectItems"].length > 0 &&
                          this.state["inputFormatIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }

                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenMobilization.Value" defaultMessage="Value" />}
                      name="valueId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["valueIdSelectItems"]) &&
                          this.state["valueIdSelectItems"].length > 0 &&
                          this.state["valueIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }

                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenMobilization.Value+" defaultMessage="Value +" />}
                      name="valuePlusId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["valuePlusIdSelectItems"]) &&
                          this.state["valuePlusIdSelectItems"].length > 0 &&
                          this.state["valuePlusIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }

                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenMobilization.Value+Link" defaultMessage="Value + link" />}
                      name="valuePlusLinkId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["valuePlusLinkIdSelectItems"]) &&
                          this.state["valuePlusLinkIdSelectItems"].length > 0 &&
                          this.state["valuePlusLinkIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }

                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenMobilization" defaultMessage=" " />}
                      name="isValue"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      valuePropName="checked"
                    >
                      <Checkbox>
                        <FormattedMessage id="EldenMobilization.ValueandValuue+areonsameline" defaultMessage="Value and Valuue + are on same line" />
                      </Checkbox>
                    </Form.Item>
                  }

                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenMobilization.UserAction" defaultMessage="User Action" />}
                      name="userActionId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["userActionIdSelectItems"]) &&
                          this.state["userActionIdSelectItems"].length > 0 &&
                          this.state["userActionIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                </Form>
              </>
            }
          />

          {/* EditModal */}

          <DraggableModal
            title={<FormattedMessage id="EldenMobilization.EditTitle" defaultMessage="Edit Title" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showEditTitleModal}
            onOk={() => this.formRefTitle.current.submit()}
            onCancel={() => {
              this.setState({ showEditTitleModal: false });
            }}
            content={
              <>
                <Form initialValues={{ remember: false }} onFinish={this.saveTitle} onFinishFailed={onFinishFailed} ref={this.formRefTitle}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenMobilization.Title" defaultMessage="Title" />}
                    name="title"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantity.MatchWithPersonnel" defaultMessage="Match with personnel" />}
                    name="matchWithPersonnel"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox></Checkbox>
                  </Form.Item>
                </Form>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenMobilization.EditSub-Title" defaultMessage="Edit Sub-Title" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showEditSubTitleModal}
            onOk={() => this.formRefSubTitle.current.submit()}
            onCancel={() => {
              this.setState({ showEditSubTitleModal: false });
            }}
            content={
              <>
                <Form initialValues={{ remember: false }} onFinish={this.saveSubTitle} onFinishFailed={onFinishFailed} ref={this.formRefSubTitle}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenMobilization.Sub-Title" defaultMessage="Sub-Title" />}
                    name="subTitle"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantity.MatchWithPersonnel" defaultMessage="Match with personnel" />}
                    name="matchWithPersonnel"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox></Checkbox>
                  </Form.Item>
                </Form>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenMobilization.EditInput" defaultMessage="Edit Input" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showEditInputModal}
            onOk={() => this.formRefInput.current.submit()}
            onCancel={() => {
              this.setState({ showEditInputModal: false });
            }}
            content={
              <>
                <Form initialValues={{ remember: false }} onFinish={this.saveInput} onFinishFailed={onFinishFailed} ref={this.formRefInput}>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenMobilization.Input" defaultMessage="Input" />}
                      name="input"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                </Form>
              </>
            }
          />

          {/* DeleteModal */}

          <DraggableModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={400}
            maskClosable={false}
            open={this.state.showDeleteTitleModal}
            okText={<FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />}
            onOk={this.deleteTitle}
            onCancel={() => {
              this.setState({ showDeleteTitleModal: false });
            }}
            content={
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={400}
            maskClosable={false}
            open={this.state.showDeleteSubTitleModal}
            okText={<FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />}
            onOk={this.deleteSubTitle}
            onCancel={() => {
              this.setState({ showDeleteSubTitleModal: false });
            }}
            content={
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={400}
            maskClosable={false}
            open={this.state.showDeleteInputModal}
            okText={<FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />}
            onOk={this.deleteInput}
            onCancel={() => {
              this.setState({ showDeleteInputModal: false });
            }}
            content={
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenMobilization.Assign" defaultMessage="Assign" />}
            centered
            width={1200}
            maskClosable={false}
            open={this.state.showMobilizationAssignModal}
            okButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState({ showMobilizationAssignModal: false });
            }}
            content={
              <div className="card card-custom" style={{ minHeight: "100%" }}>
                <Table
                  bordered
                  size="small"
                  columns={columnsManpower}
                  dataSource={this.state.monpowerSelectItems}
                  loading={this.state.loading}
                  pagination={false}
                />
              </div>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenMobilization.Assign" defaultMessage="Assign" />}
            open={this.state.modalAssignShow}
            onCancel={() => this.setState({ modalAssignShow: false })}
            okButtonProps={{ hidden: true }}
            width={800}
            centered
            content={
              <EldenMobilizationAssign
                tenderingLogId={this.props.tenderingLogId}
                eldenMobilizationTitleId={this.state.eldenMobilizationTitleId}
                eldenMobilizationSubTitleId={this.state.eldenMobilizationSubTitleId}
                assignRow={this.state.assignRow}
                random={this.state.random}
                type={this.state.assignRowType}
              />
            }
          />
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EldenMobilization);