import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { ReplyOutlined, ScoreOutlined, MessageOutlined, PictureAsPdf, Delete, InfoOutlined } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { FormattedMessage } from "react-intl";
import { Carousel, Checkbox, Drawer, Spin } from "antd";
import { Button, Table as TableBoot } from "react-bootstrap";
import {
  FilterOutlined,
  ExclamationCircleOutlined,
  SettingTwoTone,
  CloseCircleOutlined,
  StockOutlined,
  PlusOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import SendIcon from "@material-ui/icons/Send";
import {
  Form,
  Table,
  Input,
  InputNumber,
  Badge,
  Select,
  DatePicker,
  Button as AntButton,
  Cascader,
  Image,
  Col,
  Row,
  Radio,
  Typography,
  Tag,
  Space,
  Tooltip,
  Skeleton,
  Steps,
  Statistic
} from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import { InventoryLogReceive } from "./InventoryLogReceive";
import InventoryLogRelease from "./InventoryLogRelease";
import "../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";
import { PageSettings } from "../administrator/pageSettings/PageSettings";
import store from "../../../redux/store";
import SettingsIcon from "@material-ui/icons/Settings";
import Viewer, { defaultLayout, Worker } from "@phuocng/react-pdf-viewer";
import DraggableModal from "../../Components/DraggableModal";
import { Emergency } from "./inventory/Emergency";
import StockMovements from "./StockMovements";

const { Option } = Select;
const { Text } = Typography;
const { Step } = Steps;
const { Countdown } = Statistic;

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate"
      ? form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? moment(record[dataIndex], "DD-MM-YYYY") : ""
      })
      : form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
      });
  };

  const toggleSave = async () => {
    try {
      if (dataIndex === "planedDeliveryDate") {
        const values = await form.validateFields();
        values.planedDeliveryDate = Boolean(values.planedDeliveryDate) ? moment(values.planedDeliveryDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else if (dataIndex === "paymentDate") {
        const values = await form.validateFields();
        values.paymentDate = Boolean(values.paymentDate) ? moment(values.paymentDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record }, { ...values });
      }
    } catch (errInfo) { }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
      >
        {dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate" ? (
          <DatePicker ref={inputRef} onBlur={toggleSave} format={"DD-MM-YYYY"} />
        ) : (
          <InputNumber min={0} ref={inputRef} onPressEnter={toggleSave} onBlur={toggleSave} />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion

export class InventoryLog extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "inventoryLog",
      controllerNameMain: "inventoryLogMain",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      currentItem: null,
      id: null,
      tableList: [],
      tableReceivingList: [],
      tableRelease: [],
      tableMovementList: [],
      filePath: "",
      filePaths: [],
      fileList: [],
      currentMovementType: null,
      selectedRowKeysRelease: [],
      selectedRowKeysReceiving: [],
      nodes: [],
      spinLoadingPO: false,
      stockReserveMovement: false,
      searchCriteriaModels: [],
      loadingButtonSubmit: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      paginationReceive: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      paginationRelease: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      paginationMovement: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      columnsStockQuantityDimension: [],
      tableStockQuantityDimensiontList: [],
      user: store.getState().auth.user
    };
  }

  formRef = React.createRef();
  formRefReceive = React.createRef();
  formRefRelease = React.createRef();
  formRefMinStockReq = React.createRef();
  formRefReserve = React.createRef();
  formRefPdfFilter = React.createRef();
  formRefStockMovementsPdfFilter = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    fillFilterOperations();
  };

  fillComboboxes = async () => {
    var responseProjectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/projectId");
    if (responseProjectId.type === "ERROR") {
      error(responseProjectId);
    } else {
      this.setState({
        projectIdSelectItems: Boolean(responseProjectId.data) ? responseProjectId.data : []
      });
    }

    var responsecategoryId = await handleRequest("GET", "/api/" + this.state.controllerName + "/category");
    if (responsecategoryId.type === "ERROR") {
      error(responsecategoryId);
    } else {
      this.setState({
        categoryIdSelectItems: Boolean(responsecategoryId.data) ? responsecategoryId.data : []
      });
    }

    var responseprojectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/projectId");
    if (responseprojectId.type === "ERROR") {
      error(responseprojectId);
    } else {
      this.setState({
        projectIdSelectItems: Boolean(responseprojectId.data) ? responseprojectId.data : []
      });
    }

    var responseStockCardId = await handleRequest("GET", "/api/" + this.state.controllerName + "/stockCardId");
    if (responseStockCardId.type === "ERROR") {
      error(responseStockCardId);
    } else {
      this.setState({
        stockCardIdSelectItems: Boolean(responseStockCardId.data) ? responseStockCardId.data : []
      });
    }
  };

  fillStock = async (projectId) => {
    if (Boolean(projectId)) {
      const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/stockTree/" + projectId);
      if (responseTree.data.length !== 0) {
        generateList(responseTree.data);
        this.setState({
          nodes: responseTree.data
        });
      } else {
        this.setState({
          nodes: [],
          stockId: null
        });
        this.formRef.current.setFieldsValue({
          stockId: null
        });
      }
    } else {
      this.setState({
        nodes: [],
        stockId: null
      });
      this.formRef.current.setFieldsValue({
        stockId: null
      });
    }
  }

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      selectedRowKeysRelease: [],
      selectedRowKeysReceiving: [],
      stockId: null,
      selectedRow: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      paginationReceive: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      paginationRelease: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      paginationMovement: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  setSelectedFiles = field => {
    let fileList = [];
    if (Boolean(field) && field.length > 0) {
      let files = field.split(",");
      for (let i = 0; i < files.length; i++) {
        fileList.push({
          uid: i,
          name: files[i],
          url: files[i],
          thumbUrl: files[i]
        });
      }
      return fileList;
    }
    return [];
  };

  edit = async row => {
    if (row.movementType === "RECEIVE") {
      var selectedPO = null;
      if (Boolean(row.purchaseOrderId) && row.purchaseOrderId > 0) {
        var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/purchaseOrder/" + row.purchaseOrderId);
        if (response.type === "ERROR") {
          error(response);
        } else {
          selectedPO = response.data;
        }
      }
      this.setState({
        id: row.id,
        showStockReceivingModal: true,
        stockId: row.stockId,
        receivingType: row.receivingType,
        selectedPO: selectedPO,
        selectedRow: row
      });
    } else if (row.movementType === "RELEASE") {
      var selectedPrjTakeOff = null;
      if (Boolean(row.projectTakeOffListId) && row.projectTakeOffListId > 0) {
        var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/projectTakeOffList/" + row.projectTakeOffListId);
        if (response.type === "ERROR") {
          error(response);
        } else {
          selectedPrjTakeOff = response.data;
        }
      }
      this.setState({
        id: row.id,
        showStockReleaseModal: true,
        stockId: row.stockId,
        releaseType: row.releaseType,
        selectedPrjTakeOff: selectedPrjTakeOff,
        selectedRow: row
      });
    } else {
      showError("Type not found");
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false,
        showMovementModal: false,
        stockId: null,
        selectedRowKeysRelease: [],
        selectedRowKeysReceiving: [],
        selectedRow: null
      });
    }
  };

  showInventoryMovement = row => {
    this.setState(
      {
        showMovementModal: true,
        inventoryLogMainId: row.inventoryLogMainId,
        stockCardId: row.stockCardId,
        stockReserveMovement: false
      },
      () => {
        const { paginationMovement } = this.state;
        this.restartTableMovement({ paginationMovement });
      }
    );
  };

  showStockQuantityDimension = async row => {
    let tableStockQuantityDimensiontList = [];
    if (Boolean(row.id) && row.id > 0) {
      let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/stockQuantityDimension/" + row.id);
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(Boolean(response.data)) && Boolean(response.data.avaliable) && response.data.avaliable.length > 0) {
          tableStockQuantityDimensiontList = response.data.avaliable;
        }
      }
      console.log("tableStockQuantityDimensiontList", tableStockQuantityDimensiontList);

      this.setState(
        {
          showStockQuantityDimensionModal: true,
          tableStockQuantityDimensiontList: tableStockQuantityDimensiontList
        },
        () => {
          this.setColumnsAvaliable();
        }
      );
    } else {
      showError("Inventory Log Main not found.");
    }
  };

  setColumnsAvaliable() {
    const { tableStockQuantityDimensiontList } = this.state;

    let columnsStockQuantityDimension = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.Stock" defaultMessage="Stock" />,
        key: "stockName",
        render: record => {
          return record.stockName;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="InventoryLog.InStock" defaultMessage="In Stock" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        },
        width: 100
      }
    ];

    let dimensionList = [];
    if (Boolean(tableStockQuantityDimensiontList) && tableStockQuantityDimensiontList.length > 0) {
      tableStockQuantityDimensiontList.forEach(element => {
        if (Boolean(element.allDimensions)) {
          let dimensionRowList = element.allDimensions.split(", ");

          if (Boolean(dimensionRowList) && dimensionRowList.length > 1) {
            dimensionRowList.forEach(element => {
              if (!dimensionList.includes(element)) {
                dimensionList.push(element);
              }
            });
          }
        }
      });
    }

    if (Boolean(dimensionList) && dimensionList.length > 0) {
      dimensionList.forEach(element => {
        let dimension = element.split(":");
        if (Boolean(dimension) && dimension.length > 1) {
          let obj = {
            // key: row.key,
            title: dimension[0],
            dataIndex: dimension[0]
            //[dimension[0]]: dimension[1],
          };

          let addColumn = columnsStockQuantityDimension.find(p => p.title === obj.title);
          if (!Boolean(addColumn)) {
            columnsStockQuantityDimension.push(obj);
          }

          if (Boolean(tableStockQuantityDimensiontList) && tableStockQuantityDimensiontList.length > 0) {
            tableStockQuantityDimensiontList.forEach((element, index) => {
              if (Boolean(element.allDimensions) && element.allDimensions.includes(dimension[0])) {
                let dimensionRowList = element.allDimensions.split(", ");
                if (Boolean(dimensionRowList) && dimensionRowList.length > 1) {
                  console.log("dimensionRowList", dimensionRowList);
                  dimensionRowList.forEach(subRow => {
                    console.log("subRow", subRow);

                    let subDimension = subRow.split(":");
                    if (Boolean(subDimension) && subDimension.length > 1 && dimension[0] === subDimension[0]) {
                      element.key = index + 1;
                      element[dimension[0]] = subDimension[1];
                      return;
                    }
                  });
                }
              } else {
                element.key = index + 1;
                element[dimension[0]] = "";
              }
            });
          }
        }
      });
    }
    this.setState(
      {
        columnsStockQuantityDimension: columnsStockQuantityDimension
      },
      () => {
        console.log("tableStockQuantityDimensiontList", tableStockQuantityDimensiontList);
        console.log("columnsStockQuantityDimension", this.state.columnsStockQuantityDimension);
      }
    );
  }

  showMinStockRequirement = row => {
    this.setState(
      {
        showMinStockRequirementModal: true,
        inventoryLogMainId: row.inventoryLogMainId,
        formMinQuantityUnitName: row.unitName
      },
      () => {
        if (Boolean(this.formRefMinStockReq) && Boolean(this.formRefMinStockReq.current)) {
          this.formRefMinStockReq.current.setFieldsValue({
            ...row
          });
        }
      }
    );
  };

  showProperties = row => { };

  showManufacturerInfo = row => { };

  showReserve = row => {
    if (Boolean(this.state.stockId) && this.state.stockId > 0) {
      var availableQuantity = 0;
      var inStock = 0;
      var reservedQuantity = 0;
      var toBeReleasedQuantity = 0;
      if (Boolean(row.quantity) && row.quantity > 0) {
        inStock = row.quantity;
      }
      if (Boolean(row.reservedQuantity) && row.reservedQuantity > 0) {
        reservedQuantity = row.reservedQuantity;
      }
      if (Boolean(row.toBeReleasedQuantity) && row.toBeReleasedQuantity > 0) {
        toBeReleasedQuantity = row.toBeReleasedQuantity;
      }
      availableQuantity = inStock - (reservedQuantity + toBeReleasedQuantity);

      this.setState(
        {
          showReserveModal: true,
          inventoryLogMainId: row.inventoryLogMainId,
          availableQuantity: availableQuantity > 0 ? availableQuantity : 0
        },
        () => {
          if (Boolean(this.formRefReserve) && Boolean(this.formRefReserve.current)) {
            this.formRefReserve.current.setFieldsValue({
              availableQuantity: availableQuantity > 0 ? availableQuantity : 0
            });
          }
        }
      );
    } else {
      showError("Please select stock");
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  handleTableChangeReceiving = (paginationReceive, filters, sorter) => {
    this.restartTableReceiving({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      paginationReceive,
      filters
    });
  };

  handleTableChangeRelease = (paginationRelease, filters, sorter) => {
    this.restartTableReleaseProjectItems({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      paginationRelease,
      filters
    });
  };

  handleTableChangeOtherItems = (paginationRelease, filters, sorter) => {
    this.restartTableReleaseOtherItems({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      paginationRelease,
      filters
    });
  };

  handleTableChangeMovement = (paginationMovement, filters, sorter) => {
    this.setState({
      paginationMovement
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (Boolean(response.data) && response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.key = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  getSelectedFiles = field => {
    if (Boolean(field) && Boolean(field.fileList) && field.fileList.length > 0) {
      let files = "";
      files = field.fileList[0].response.url;
      for (let i = 1; i < field.fileList.length; i++) {
        if (Boolean(field.fileList[i].response)) {
          files += "," + field.fileList[i].response.url;
        }
      }
      return files;
    }
    return field;
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };
    const { filePaths } = this.state;

    newItem.materialPhotoPaths = Boolean(filePaths) ? filePaths.toString() : "";

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      stockId: Boolean(this.state.stockId) ? this.state.stockId : null,
      projectId: Boolean(this.state.projectId) ? this.state.projectId : null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  createWhereReceiving(params) {
    if (Boolean(params._pagination)) params.paginationReceive = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.paginationReceive)) {
      if (Boolean(params.paginationReceive.lastUpdateDate)) {
        lastUpdateDateStart = params.paginationReceive.lastUpdateDate[0];
        lastUpdateDateEnd = params.paginationReceive.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.paginationReceive,
      stockId: this.state.stockId,
      id: Boolean(this.state.poNo) ? this.state.poNo : null,
      vendorId: Boolean(this.state.vendorId) ? this.state.vendorId : null,
      projectTakeOffQuantityId: Boolean(this.state.projectTakeOffQuantityId) ? this.state.projectTakeOffQuantityId : null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.paginationReceive.current - 1,
      maxResults: params.paginationReceive.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  createWhereRelease(params) {
    if (Boolean(params._pagination)) params.paginationRelease = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.paginationRelease)) {
      if (Boolean(params.paginationRelease.lastUpdateDate)) {
        lastUpdateDateStart = params.paginationRelease.lastUpdateDate[0];
        lastUpdateDateEnd = params.paginationRelease.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.paginationRelease,
      id: Boolean(this.state.projectTakeOffQuantityId) ? this.state.projectTakeOffQuantityId : null,
      stockId: this.state.stockId,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.paginationRelease.current - 1,
      maxResults: params.paginationRelease.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  createWhereOtherItemsRelease(params) {
    if (Boolean(params._pagination)) params.paginationRelease = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.paginationRelease)) {
      if (Boolean(params.paginationRelease.lastUpdateDate)) {
        lastUpdateDateStart = params.paginationRelease.lastUpdateDate[0];
        lastUpdateDateEnd = params.paginationRelease.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.paginationRelease,
      id: Boolean(this.state.projectTakeOffQuantityId) ? this.state.projectTakeOffQuantityId : null,
      stockId: this.state.stockId,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.paginationRelease.current - 1,
      maxResults: params.paginationRelease.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  createWhereMovement(params) {
    if (Boolean(params._pagination)) params.paginationMovement = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.paginationMovement)) {
      if (Boolean(params.paginationMovement.lastUpdateDate)) {
        lastUpdateDateStart = params.paginationMovement.lastUpdateDate[0];
        lastUpdateDateEnd = params.paginationMovement.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.paginationMovement,
      inventoryLogMainId: Boolean(this.state.inventoryLogMainId) ? this.state.inventoryLogMainId : null,
      stockId: Boolean(this.state.stockId) ? this.state.stockId : null,
      stockCardId: Boolean(this.state.stockCardId) ? this.state.stockCardId : null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.paginationMovement.current - 1,
      maxResults: params.paginationMovement.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  receiveModal = async () => {
    if (Boolean(this.state.stockId)) {
      this.fillReceivingComboboxes();
      const { paginationReceive } = this.state;
      this.restartTableReceiving({ paginationReceive });

      if (Boolean(this.formRefReceive) && Boolean(this.formRefReceive.current)) {
        this.formRefReceive.current.resetFields();
      }
      this.setState({
        currentMovementTypeitems: "RECEIVING",
        showReceivingModal: true,
        selectedRowKeysReceiving: [],
        receivingType: null
      });
    } else {
      showError("Please select stock");
    }
  };

  receiveSelectModal = async () => {
    if (Boolean(this.state.stockId)) {
      this.fillReceivingComboboxes();
      const { paginationReceive } = this.state;
      this.restartTableReceiving({ paginationReceive });

      if (Boolean(this.formRefReceive) && Boolean(this.formRefReceive.current)) {
        this.formRefReceive.current.resetFields();
      }
      this.setState({
        currentMovementTypeitems: "RECEIVING",
        showReceiveSelectModal: true,
        selectedRowKeysReceiving: [],
        receivingType: null
      });
    } else {
      showError("Please select stock");
    }
  };

  fillReceivingComboboxes = async () => {
    var responseVendor = await handleRequest("GET", "/api/" + this.state.controllerName + "/vendors");
    if (responseVendor.type === "ERROR") {
      error(responseVendor);
    } else {
      this.setState({
        vendorSelectItems: Boolean(responseVendor.data) ? responseVendor.data : []
      });
    }

    var responsePoNo = await handleRequest("GET", "/api/" + this.state.controllerName + "/poNos");
    if (responsePoNo.type === "ERROR") {
      error(responsePoNo);
    } else {
      this.setState({
        poNoSelectItems: Boolean(responsePoNo.data) ? responsePoNo.data : []
      });
    }
  };

  releaseModal = async () => {
    if (Boolean(this.state.stockId)) {
      if (Boolean(this.formRefRelease) && Boolean(this.formRefRelease.current)) {
        this.formRefRelease.current.resetFields();
      }
      this.setState({
        currentMovementType: "RELEASE",
        showReleaseModal: true,
        selectedRowKeysRelease: [],
        releaseType: null,
        onPurposeType: null,
        onPurposeTypeName: "",
        tableRelease: [],
        paginationRelease: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
          total: 0
        }
      });
    } else {
      showError("Please select stock");
    }
  };

  action = record => {
    let properties = {
      name: "Properties",
      icon: <ReplyOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.showProperties.bind(this)
    };

    let manufacturerInfo = {
      name: "Manufacturer info",
      icon: <ScoreOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.showManufacturerInfo.bind(this)
    };

    let reserve = {
      name: "Reserve",
      icon: <StockOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.showReserve.bind(this)
    };

    let actionList = [];
    if (record.quantity > 0 && this.state.stockReserveMovement) {
      actionList.push(reserve);
    }

    actionList.push(properties);
    actionList.push(manufacturerInfo);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  actionMovement = record => {
    let movementInfo = {
      name: "Stock Movement",
      icon: <ScoreOutlined fontSize="small" color="primary" />,
      actionClick: this.showInventoryMovement.bind(this)
    };

    let minStockRequirement = {
      name: "Min Stock Requirement",
      icon: <EditIcon fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.showMinStockRequirement.bind(this)
    };

    let reserveMovement = {
      name: "Reserve",
      icon: <StockOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.showReserve.bind(this)
    };

    let actionList = [];
    actionList.push(movementInfo);
    actionList.push(minStockRequirement);

    if (record.quantity > 0) {
      actionList.push(reserveMovement);
    }

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  actionReceiving = record => {
    let receiver = {
      name: <FormattedMessage id="InventoryLog.Receive" defaultMessage="Receive" />,
      icon: <ScoreOutlined fontSize="small" color="primary" />,
      actionClick: this.receivingSelect.bind(this)
    };

    let receiveEdit = {
      name: <FormattedMessage id="InventoryLog.Edit" defaultMessage="Edit" />,
      icon: <EditIcon fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.receivingSelect.bind(this)
    };

    let receiveDeleteReceivingRecords = {
      name: <FormattedMessage id="InventoryLog.DeleteReceivingRecords" defaultMessage="Delete Receiving Records" />,
      icon: <Delete fontSize="small" style={{ border: "0px", color: "red" }} />,
      actionClick: this.showDeleteReceivingRecords.bind(this)
    };

    let receiveRequestForQC = {
      name: <FormattedMessage id="InventoryLog.RequestforQC" defaultMessage="Request for QC" />,
      icon: <SendIcon fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.requestForQC.bind(this)
    };

    let receiveSummary = {
      name: <FormattedMessage id="InventoryLog.Summary" defaultMessage="Summary" />,
      icon: <InfoOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.summary.bind(this)
    };

    let actionList = [];
    if (record.materialReceiveStatusName === "Accept") {
      actionList.push(receiveRequestForQC);
      actionList.push(receiveEdit);
      actionList.push(receiveDeleteReceivingRecords);
    } else if (record.materialReceiveStatusName === "Pending") {
      actionList.push(receiver);
      actionList.push(receiveEdit);
      actionList.push(receiveDeleteReceivingRecords);
    } else if (record.materialReceiveStatusName === "Request for QC") {
      actionList.push(receiveSummary);
    }

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  getImageGalleryFromUrls = (paths, galleryName) => {
    if (Boolean(paths) && paths.length > 0) {
      let pathList = paths.split(",");

      return (
        <>
          {Boolean(paths) && paths.length > 0 ? (
            <Image
              width={80}
              preview={{
                visible: false
              }}
              src={pathList[0].trim()}
              onClick={() => this.setState({ [galleryName]: true })}
            />
          ) : null}
          {Boolean(pathList) && pathList.length > 0 ? (
            <div style={{ display: "none" }}>
              <Image.PreviewGroup
                preview={{
                  visible: this.state[galleryName],
                  onVisibleChange: vis => this.setState({ [galleryName]: vis })
                }}
              >
                {pathList.map((field, i) => (
                  <Image key={i} src={field} />
                ))}
              </Image.PreviewGroup>
            </div>
          ) : null}
        </>
      );
    }
  };

  onSelectChangeReceiving = selectedRowKeysReceiving => {
    if (
      Boolean(selectedRowKeysReceiving) &&
      selectedRowKeysReceiving.length > 0 &&
      Boolean(selectedRowKeysReceiving[0]) &&
      selectedRowKeysReceiving[0] > 0
    ) {
      var data = this.state.tableReceivingList.find(p => p.index === selectedRowKeysReceiving[0]);
      if (Boolean(data)) {
        if (Boolean(this.state.receivingType)) {
          if (Boolean(data.materialReceiveStatusName)) {
            if (data.materialReceiveStatusName === "Pending") {
              this.setState({
                selectedRowKeysReceiving,
                showStockReceivingModal: true,
                selectedPO: data,
                selectedRow: null
              });
            } else if (data.materialReceiveStatusName === "Accept") {
              this.setState({
                selectedRowKeysReceiving,
                modalRFQCShow: true,
                selectedRequestForQC: data,
                selectedPO: data,
                selectedRow: null
              });
            }
          } else {
            showError("Status not found.");
          }
        } else {
          showError("Please select type.");
        }
      } else {
        showError("Not found.");
      }
    } else {
      showError("Please select item.");
    }
  };

  onSelectChangeRelease = selectedRowKeysRelease => {
    if (Boolean(selectedRowKeysRelease) && selectedRowKeysRelease.length > 0) {
      var data = this.state.tableRelease.find(p => p.id === selectedRowKeysRelease[0]);
      if (Boolean(data)) {
        if (Boolean(this.state.releaseType)) {
          let inventoryLogReservedId = null;
          let inventoryLogReleaseId = null;
          if (this.state.onPurposeType === "ReservedItems") {
            inventoryLogReservedId = data.id;
          } else if (this.state.onPurposeType === "ToBeReleasedItems") {
            inventoryLogReleaseId = data.id;
          }

          this.setState({
            selectedRowKeysRelease,
            showStockReleaseModal: true,
            selectedPrjTakeOff: data,
            selectedRow: null,
            inventoryLogReservedId: inventoryLogReservedId,
            inventoryLogReleaseId: inventoryLogReleaseId
          });
        } else {
          showError("Please select type");
        }
      }
    }
  };

  poSearch = () => {
    const { paginationReceive } = this.state;
    this.restartTableReceiving({ paginationReceive });
  };

  prjTakeOffSearch = () => {
    if (Boolean(this.state.onPurposeType) && Boolean(this.state.releaseType)) {
      if (this.state.releaseType === "On_Purpose" && this.state.onPurposeType === "ProjectItems") {
        const { paginationRelease } = this.state;
        this.restartTableReleaseProjectItems({ paginationRelease });
      } else if (this.state.releaseType === "On_Purpose" && this.state.onPurposeType === "ReservedItems") {
        const { paginationRelease } = this.state;
        this.restartTableReleaseReservedItems({ paginationRelease });
      } else if (this.state.releaseType === "On_Purpose" && this.state.onPurposeType === "ToBeReleasedItems") {
        const { paginationRelease } = this.state;
        this.restartTableReleaseToBeReleasedItems({ paginationRelease });
      } else if (this.state.releaseType === "On_Purpose" && this.state.onPurposeType === "OtherItems") {
        const { paginationRelease } = this.state;
        this.restartTableReleaseOtherItems({ paginationRelease });
      } else {
        this.setState({
          tableRelease: []
        });
      }
    } else {
      this.setState({
        tableRelease: []
      });
    }
  };

  restartTableReceiving = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhereReceiving(params);
    let url = "/api/" + this.state.controllerName + "/poInventoryLogs";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableReceivingList: [],
        paginationReceive: {
          ...params.paginationReceive,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        let list = response.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableReceivingList: list,
          paginationReceive: {
            ...params.paginationReceive,
            total: response.data.length
          },
          totalRecords: response.data.length,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableReceivingList: [],
          paginationReceive: {
            ...params.paginationReceive,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  restartTableReleaseProjectItems = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhereRelease(params);
    let url = "/api/" + this.state.controllerName + "/pagingPrjTakeOff";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableRelease: [],
        paginationRelease: {
          ...params.paginationRelease,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (Boolean(response.data) && Boolean(response.data.data) && response.data.data.length > 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableRelease: list,
          paginationRelease: {
            ...params.paginationRelease,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableRelease: [],
          paginationRelease: {
            ...params.paginationRelease,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  restartTableReleaseReservedItems = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhereRelease(params);
    let url = "/api/" + this.state.controllerName + "/reservedItems";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableRelease: [],
        paginationRelease: {
          ...params.paginationRelease,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (Boolean(response.data) && Boolean(response.data.data) && response.data.data.length > 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableRelease: list,
          paginationRelease: {
            ...params.paginationRelease,
            total: response.data.length
          },
          totalRecords: response.data.length,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableRelease: [],
          paginationRelease: {
            ...params.paginationRelease,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  restartTableReleaseToBeReleasedItems = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhereRelease(params);
    let url = "/api/" + this.state.controllerName + "/toBeReleasedItems";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableRelease: [],
        paginationRelease: {
          ...params.paginationRelease,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (Boolean(response.data) && Boolean(response.data.data) && response.data.data.length > 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableRelease: list,
          paginationRelease: {
            ...params.paginationRelease,
            total: response.data.length
          },
          totalRecords: response.data.length,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableRelease: [],
          paginationRelease: {
            ...params.paginationRelease,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  restartTableReleaseOtherItems = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhereOtherItemsRelease(params);
    let url = "/api/" + this.state.controllerName + "/otherItems";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableRelease: [],
        paginationRelease: {
          ...params.paginationRelease,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (Boolean(response.data) && Boolean(response.data.data) && response.data.data.length > 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableRelease: list,
          paginationRelease: {
            ...params.paginationRelease,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableRelease: [],
          paginationRelease: {
            ...params.paginationRelease,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  restartTableMovement = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhereMovement(params);
    let url = "/api/" + this.state.controllerName + "/pagingMovement";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableMovementList: [],
        paginationMovement: {
          ...params.paginationMovement,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (Boolean(response.data) && response.data.length !== 0) {
        let list = response.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableMovementList: list,
          paginationMovement: {
            ...params.paginationMovement,
            total: response.data.length
          },
          totalRecords: response.data.length,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableMovementList: [],
          paginationMovement: {
            ...params.paginationMovement,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  handleStockIdChange = async value => {
    let stockId = Boolean(value) && value.length > 0 ? value[value.length - 1] : null;
    this.setState(
      {
        stockId: stockId
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  saveStockReceiving = () => {
    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.resetFields();
    }
    this.setState(
      {
        showStockReceivingModal: false,
        // showReceivingModal: false,
        // showMovementModal: false,
        stockId: null,
        id: null,
        selectedRowKeysReceiving: [],
        selectedRow: null
      },
      () => {
        const { paginationReceive } = this.state;
        this.restartTableReceiving({ paginationReceive });
      }
    );
  };

  saveStockRelease = () => {
    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.resetFields();
    }
    this.setState(
      {
        showStockReleaseModal: false,
        showReleaseModal: false,
        showMovementModal: false,
        stockId: null,
        id: null,
        selectedRowKeysRelease: [],
        selectedRow: null
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  handlePriceListQuantityOurExpectedPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableReceivingList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.quantity === value.quantity) return;
        item.quantity = value.quantity;
        this.setState({
          tableReceivingList: newData
        });
      }
    }
  };

  saveReserveSubmit = async values => {
    if (Boolean(this.state.stockId) && this.state.stockId > 0) {
      if (Boolean(this.state.inventoryLogMainId) && this.state.inventoryLogMainId > 0) {
        const newItem = {
          stockId: this.state.stockId,
          inventoryLogMainId: this.state.inventoryLogMainId,
          ...values
        };

        let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/addReserveQuantity", newItem);

        if (response.type === "ERROR") {
          error(response);
        } else {
          this.setState({
            showReserveModal: false
          });
          const { pagination } = this.state;
          this.restartTable({ pagination });
          showSuccess();
        }
      } else {
        showError("Inventory Log Main Not found");
      }
    } else {
      showError("Please select stock");
    }
  };

  saveMinQuantitySubmit = async values => {
    const newItem = {
      id: this.state.inventoryLogMainId,
      ...values
    };

    if (Boolean(this.state.inventoryLogMainId) && this.state.inventoryLogMainId > 0) {
      var response = await handleRequest("PUT", "/api/" + this.state.controllerNameMain + "/updateMinReqQuantity/" + newItem.id, newItem);

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          showMinStockRequirementModal: false
        });
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showError("Not found");
    }
  };

  getReportList = async values => {
    this.setState({ loadingButtonSubmit: true });
    const newItem = {
      ...values
    };
    let stockId = Boolean(values.stockId) && values.stockId.length > 0 ? values.stockId[values.stockId.length - 1] : null;
    newItem.stockId = stockId;

    let url = "/api/" + this.state.controllerName + "/getReportList";
    const response = await handleRequest("POST", url, newItem);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loadingButtonSubmit: false
      });
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true,
        modalPdfSearchShow: false,
        loadingButtonSubmit: false
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false,
        modalPdfSearchShow: false,
        loadingButtonSubmit: false
      });
      showError("There is no created report for selected item");
    }
  };

  getReportListStockMovements = async values => {
    this.setState({ loadingButtonSubmit: true });
    const newItem = {
      ...values
    };
    let stockId = Boolean(values.stockId) && values.stockId.length > 0 ? values.stockId[values.stockId.length - 1] : null;
    newItem.stockId = stockId;

    let url = "/api/" + this.state.controllerName + "/getReportListStockMovements";
    const response = await handleRequest("POST", url, newItem);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loadingButtonSubmit: false
      });
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true,
        modalPdfSearchStockMovementShow: false,
        loadingButtonSubmit: false
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false,
        modalPdfSearchStockMovementShow: false,
        loadingButtonSubmit: false
      });
      showError("There is no created report for selected item");
    }
  };

  // actionReceiving = record => {
  //   let selectAction = {
  //     name: <FormattedMessage id="GeneralActionButtonSelect" defaultMessage="Select" />,
  //     icon: <MessageOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
  //     actionClick: this.receivingSelect.bind(this)
  //   };
  //   let sendAction = {
  //     name: <FormattedMessage id="InventoryLog.RequestForQualityControl" defaultMessage="Request for Q.C." />,
  //     icon: <SendIcon fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
  //     actionClick: this.requestForQC.bind(this)
  //   };
  //   let manufacturingStage = {
  //     name: <FormattedMessage id="InventoryLog.CallRequestBack" defaultMessage="Manufacturing Stage" />,
  //     icon: <SendIcon fontSize="small" style={{ border: "0px", color: "#007bff" }} />
  //   };
  //   let shipping = {
  //     name: <FormattedMessage id="InventoryLog.CallRequestBack" defaultMessage="Shipping" />,
  //     icon: <SendIcon fontSize="small" style={{ border: "0px", color: "#007bff" }} />
  //   };
  //   let itemsReceived = {
  //     name: <FormattedMessage id="InventoryLog.CallRequestBack" defaultMessage="Item(s) Received" />,
  //     icon: <SendIcon fontSize="small" style={{ border: "0px", color: "#007bff" }} />
  //   };
  //   let inspectionRequestDone = {
  //     name: <FormattedMessage id="InventoryLog.CallRequestBack" defaultMessage="Inspection Request Done" />,
  //     icon: <SendIcon fontSize="small" style={{ border: "0px", color: "#007bff" }} />
  //   };
  //   let inspectionRequestCalledBack = {
  //     name: <FormattedMessage id="InventoryLog.CallRequestBack" defaultMessage="Inspection Request Called Back" />,
  //     icon: <SendIcon fontSize="small" style={{ border: "0px", color: "#007bff" }} />
  //   };
  //   let inspectionPerformed = {
  //     name: <FormattedMessage id="InventoryLog.CallRequestBack" defaultMessage="Inspection Performed" />,
  //     icon: <SendIcon fontSize="small" style={{ border: "0px", color: "#007bff" }} />
  //   };

  //   let actionList = [];
  //   if (!(Boolean(record.inspectionLogItemResult) && record.inspectionLogItemResult === "Accept")) {
  //     if (Boolean(record.receiveStatus)) {
  //       // actionList.push(selectAction);
  //       actionList.push(manufacturingStage);
  //       actionList.push(shipping);
  //       actionList.push(itemsReceived);
  //       actionList.push(inspectionRequestDone);
  //       actionList.push(inspectionPerformed);
  //       if (record.receiveStatus === "Accept") {
  //         actionList.push(sendAction);
  //       }
  //       if (record.receiveStatus === "RequestForQC") {
  //         actionList.push(inspectionRequestCalledBack);
  //       }
  //     } else {
  //       actionList.push(selectAction);
  //     }
  //   }
  //   return (
  //     <React.Fragment>
  //       <DynamicActionMenu row={record} actionList={actionList} />
  //     </React.Fragment>
  //   );
  // };

  receivingSelect = row => {
    if (Boolean(this.state.receivingType)) {
      this.setState({
        showStockReceivingModal: true,
        selectedPO: row,
        selectedRow: null
      });
    } else {
      showError("Please select type");
    }
  };

  requestForQC = row => {
    this.setState({
      modalRFQCShow: true,
      selectedRequestForQC: row
    });
  };

  sendQualityControl = async () => {
    console.log("selectedRequestForQC", this.state.selectedRequestForQC);
    if (
      Boolean(this.state.selectedRequestForQC) &&
      Boolean(this.state.selectedRequestForQC.id) &&
      this.state.selectedRequestForQC.id > 0 &&
      Boolean(this.state.selectedRequestForQC.stockId) &&
      this.state.selectedRequestForQC.stockId > 0
    ) {
      var newObj = {
        id: this.state.selectedRequestForQC.id,
        stockId: this.state.selectedRequestForQC.stockId
      };
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/requestForQC", newObj);
      if (response.type === "ERROR") {
        error(response);
      } else {
        const { paginationReceive } = this.state;
        this.restartTableReceiving({ paginationReceive });
        this.setState({
          modalRFQCShow: false,
          selectedRowKeysReceiving: []
        });
      }
    } else {
      showError("The selected item was not found.");
    }
  };

  summary = row => {
    this.setState({
      modalSummaryShow: true,
      selectedSummaryRow: row
    });
  };

  deleteReceivingRecords = async () => {
    console.log("selectedRow", this.state.selectedRow);
    if (Boolean(this.state.selectedRow) && Boolean(this.state.selectedRow.id) && this.state.selectedRow.id > 0) {
      var response = await handleRequest(
        "DELETE",
        "/api/" +
        this.state.controllerName +
        "/deleteReceivingRecords/" +
        this.state.selectedRow.id +
        "/" +
        this.state.selectedRow.materialReceiveStatusName
      );
      if (response.type === "ERROR") {
        error(response);
      } else {
        const { paginationReceive } = this.state;
        this.restartTableReceiving({ paginationReceive });
        this.setState({
          modalDeleteReceivingRecordsShow: false,
          selectedRow: null
        });
      }
    } else {
      showError("The selected item was not found.");
    }
  };

  showDeleteReceivingRecords = row => {
    this.setState({
      modalDeleteReceivingRecordsShow: true,
      selectedRow: row
    });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  receivingTypeCheckboxChange = type => {
    this.setState({ receivingType: type });
  };

  renderStockMovements() {
    return <StockMovements />;
  }

  render() {
    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };
    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.printButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const { selectedRowKeysReceiving } = this.state;
    const rowSelectionReceiving = {
      selectedRowKeys: selectedRowKeysReceiving,
      onChange: this.onSelectChangeReceiving
    };

    const { selectedRowKeysRelease } = this.state;
    const rowSelectionRelease = {
      selectedRowKeys: selectedRowKeysRelease,
      onChange: this.onSelectChangeRelease
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    };

    const columns = [
      {
        key: "key",
        width: 100,
        title: <FormattedMessage id="General.TitleActions" defaultMessage="Actions" />,
        render: this.actionMovement
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.Date" defaultMessage="Date" />,
        key: "date",
        width: 100,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        sorter: true
      },
      {
        title: (
          <Tooltip title="Tooltip Text for Item Description">
            <FormattedMessage id="InventoryLog.itemName" defaultMessage="Item Description" />
          </Tooltip>
        ),
        key: "stockCardId",
        width: 100,
        ellipsis: true,
        render: record => {
          return <Tooltip title={record.itemName}>{record.itemName}</Tooltip>;
        },
        ...getColumnFilter({
          inputName: "stockCardId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["stockCardIdSelectItems"]) ? this.state["stockCardIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="InventoryLog.imageUrl" defaultMessage="Photo" />,
        key: "imageUrl",
        width: 100,
        render: record => {
          return Boolean(record.imageUrl) && <Image src={record.imageUrl} />;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.dimensionName" defaultMessage="Dimension" />,
        key: "id",
        ellipsis: true,
        width: 100,
        render: (value, row, index) => {
          return <Tooltip title={row.dimensionName}>{row.dimensionName}</Tooltip>;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.quantity" defaultMessage="In Stock" />,
        key: "quantity",
        width: 100,
        render: record => {
          if (record.movementType === "RECEIVE") {
            return (
              <Text style={{ cursor: "pointer" }} onClick={e => this.showInventoryMovement(record)} type="success">
                {Math.abs(record.quantity)}
              </Text>
            );
          } else {
            return (
              <Text style={{ cursor: "pointer" }} onClick={e => this.showInventoryMovement(record)} type="danger">
                {Math.abs(record.quantity)}
              </Text>
            );
          }
        },
        ...getColumnFilter({
          inputName: "quantity",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },

      {
        title: <FormattedMessage id="InventoryLog.ReservedQuantity" defaultMessage="Reserved Quantity" />,
        key: "reservedQuantity",
        width: 100,
        render: record => {
          return record.reservedQuantity;
        },
        ...getColumnFilter({
          inputName: "reservedQuantity",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },

      {
        title: <FormattedMessage id="InventoryLog.ToBeReleasedQuantity" defaultMessage="To Be Released Quantity" />,
        key: "toBeReleasedQuantity",
        width: 100,
        render: record => {
          return record.toBeReleasedQuantity;
        },
        ...getColumnFilter({
          inputName: "toBeReleasedQuantity",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },

      {
        title: <FormattedMessage id="InventoryLog.availableQuantity" defaultMessage="Available Quantity" />,
        key: "availableQuantity",
        width: 100,
        render: record => {
          if (record.movementType === "RECEIVE") {
            let avaliableQuantity = record.quantity - (record.reservedQuantity + record.toBeReleasedQuantity);
            return avaliableQuantity > 0 ? avaliableQuantity : 0;
          }
        },
        ...getColumnFilter({
          inputName: "availableQuantity",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },

      {
        title: <FormattedMessage id="InventoryLog.quantity" defaultMessage="Min. Req. Stock" />,
        key: "minReqQuantity",
        width: 100,
        render: record => {
          return record.minReqQuantity;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.purposeOfUse" defaultMessage="Purpose of Use" />,
        key: "purposeOfUse",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.dateOfPlannedUsage" defaultMessage="Date of Planned Usage" />,
        key: "dateOfPlannedUsage",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.source" defaultMessage="Source" />,
        key: "source",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.status" defaultMessage="Status" />,
        key: "status",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="General.LastUpdate" defaultMessage="Last Update" />,
        key: "lastUpdated",
        width: 100,
        ellipsis: true,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.remarks" defaultMessage="Remarks" />,
        key: "remarks",
        width: 100,
        render: record => {
          return "";
        }
      }
    ];

    const columnsMovement = [
      {
        key: "key",
        width: 100,
        title: <FormattedMessage id="General.TitleActions" defaultMessage="Actions" />,
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.paginationMovement.pageSize * (this.state.paginationMovement.current - 1);
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.stockName" defaultMessage="Stock" />,
        key: "stockName",
        width: 100,
        render: record => {
          return record.stockName;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.itemName" defaultMessage="Item Description" />,
        key: "itemName",
        width: 100,
        ellipsis: true,
        render: record => {
          return <Tooltip title={record.dimensionName}>{record.itemName}</Tooltip>;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.imageUrl" defaultMessage="Photo" />,
        key: "imageUrl",
        width: 100,
        render: record => {
          return Boolean(record.imageUrl) && <Image src={record.imageUrl} />;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.dimensionName" defaultMessage="Dimension" />,
        key: "id",
        ellipsis: true,
        width: 100,
        render: (value, row, index) => {
          return <Tooltip title={row.dimensionName}>{row.dimensionName}</Tooltip>;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.reserve" defaultMessage="Reserve" />,
        key: "reserve",
        width: 100,
        render: record => {
          return <Text type="danger">{Math.abs(record.reservedQuantity)}</Text>;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.release" defaultMessage="Release" />,
        key: "release",
        width: 100,
        render: record => {
          return <Text type="danger">{Math.abs(record.releaseQuantity)}</Text>;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.receive" defaultMessage="Receive" />,
        key: "receive",
        width: 100,
        render: record => {
          return <Text type="success">{Math.abs(record.receiveQuantity)}</Text>;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.quantity" defaultMessage="In Stock" />,
        key: "quantity",
        width: 100,
        render: (value, record, index) => {
          if (index === 0 && record.quantity > 0) {
            return (
              <Text style={{ cursor: "pointer" }} onClick={e => this.showStockQuantityDimension(record)} type="success">
                {Math.abs(record.quantity)}
              </Text>
            );
          } else {
            if (record.quantity > 0) {
              return <Text type="success">{Math.abs(record.quantity)}</Text>;
            } else {
              return <Text type="danger">{Math.abs(record.quantity)}</Text>;
            }
          }
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.manufacturer" defaultMessage="Manufacturer" />,
        key: "manufacturer",
        width: 100,
        render: record => {
          return record.manufacturer;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.Date" defaultMessage="Date" />,
        key: "dateCreated",
        width: 100,
        ellipsis: true,
        render: record => {
          return moment(record.dateCreated).format("DD-MM-YYYY HH:mm");
        }
      }
    ];

    const columnsReserve = [
      {
        key: "key",
        width: 100,
        title: <FormattedMessage id="General.TitleActions" defaultMessage="Actions" />,
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.paginationMovement.pageSize * (this.state.paginationMovement.current - 1);
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.stockName" defaultMessage="Stock" />,
        key: "stockName",
        width: 100,
        render: record => {
          return record.stockName;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.itemName" defaultMessage="Item Description" />,
        key: "itemName",
        width: 100,
        ellipsis: true,
        render: record => {
          return record.itemName;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.imageUrl" defaultMessage="Photo" />,
        key: "imageUrl",
        width: 100,
        render: record => {
          return Boolean(record.imageUrl) && <Image src={record.imageUrl} />;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.dimensionName" defaultMessage="Dimension" />,
        key: "dimensionName",
        ellipsis: true,
        width: 100,
        render: record => {
          return record.dimensionName;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.quantity" defaultMessage="In Stock" />,
        key: "quantity",
        width: 100,
        render: record => {
          if (record.quantity > 0) {
            return <Text type="success">{Math.abs(record.quantity)}</Text>;
          } else {
            return <Text type="danger">{Math.abs(record.quantity)}</Text>;
          }
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.reservedQuantity" defaultMessage="Reserved" />,
        key: "reservedQuantity",
        width: 100,
        render: record => {
          return record.reservedQuantity;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.avaliableQuantity" defaultMessage="Available Quantity" />,
        key: "id",
        width: 100,
        render: record => {
          let avaliableQuantity = record.quantity - (record.reservedQuantity + record.toBeReleasedQuantity);
          return avaliableQuantity > 0 ? avaliableQuantity : 0;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.manufacturer" defaultMessage="Manufacturer" />,
        key: "manufacturer",
        width: 100,
        render: record => {
          return record.manufacturer;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.Date" defaultMessage="Date" />,
        key: "dateCreated",
        width: 100,
        render: record => {
          return moment(record.dateCreated).format("DD-MM-YYYY HH:mm");
        }
      }
    ];

    const columnsReceiving = [
      // {
      //   key: "key",
      //   width: 50,
      //   // title: <FormattedMessage id="General.TitleActions" defaultMessage="Actions" />,
      //   render: this.actionReceiving
      // },
      {
        key: "key",
        width: 50,
        render: this.actionReceiving
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 50,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.PlannedDeliveryDate" defaultMessage="Planned Delivery Date" />,
        key: "planedDeliveryDate",
        width: 100,
        render: record => {
          return Boolean(record.planedDeliveryDate) ? (
            moment(record.planedDeliveryDate).isAfter(moment()) ? (
              <Countdown
                value={moment(record.planedDeliveryDate).valueOf()}
                onFinish={() => console.log("Countdown finished!")}
                format="D [day] H [hour] m [minute]"
                style={{ fontSize: "11px", fontWeight: "bold" }}
                valueStyle={{ fontSize: "12px", color: "#1890ff" }}
                prefix={<strong>{moment(record.planedDeliveryDate).format("DD-MM-YYYY")} </strong>}
              />
            ) : (
              moment(record.planedDeliveryDate).format("DD-MM-YYYY")
            )
          ) : (
            ""
          );
        },
        ...getColumnFilter({
          inputName: "planedDeliveryDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryLog.Status" defaultMessage="Status" />,
        key: "materialReceiveStatusName",
        width: 120,
        render: record => {
          if (Boolean(record.materialReceiveStatusName)) {
            if (record.materialReceiveStatusName === "Accept") {
              return (
                <Tag color="green">
                  <FormattedMessage id="InspectionLogItem.Accept" defaultMessage="Accept" />
                </Tag>
              );
            } else if (record.materialReceiveStatusName === "Pending") {
              return (
                <Tag color="blue">
                  <FormattedMessage id="InspectionLogItem.Pending" defaultMessage="Pending" />
                </Tag>
              );
            }
          }
          return record.materialReceiveStatusName;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.ItemDescription" defaultMessage="Item Description" />,
        key: "description",
        width: 100,
        ellipsis: true,
        render: record => {
          return record.description;
        },
        ...getColumnFilter({
          inputName: "description",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryLog.Quantity" defaultMessage="Quantity" />,
        key: "quantity",
        width: 100,
        render: record => {
          return record.quantity + " " + record.unit;
          // return (
          //   <Row gutter={6}>
          //     <Col span={12}>
          //       {record.quantity}
          //     </Col>
          //     <Col span={12}>
          //       {record.vendorName}
          //     </Col>
          //   </Row>
          // );
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "quantity",
          title: <FormattedMessage id="InventoryLog.quantity" defaultMessage="Quantity" />,
          handleSave: this.handlePriceListQuantityOurExpectedPrice
        }),
        ...getColumnFilter({
          inputName: "quantity",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      // {
      //   title: <FormattedMessage id="InventoryLog.Unit" defaultMessage="Unit" />,
      //   key: "unit",
      //   width: 100,
      //   render: record => {
      //     return record.unit;
      //   },
      //   ...getColumnFilter({
      //     inputName: "unit",
      //     inputType: "NUMBER",
      //     selectItems: [],
      //     filterChangeItem: this.filterChangeItem,
      //     searchCriteriaModels: this.state.searchCriteriaModels
      //   }),
      //   sorter: true
      // },
      {
        title: <FormattedMessage id="InventoryLog.Supplier" defaultMessage="Supplier" />,
        key: "itemName",
        width: 100,
        render: record => {
          return record.vendorName;
        },
        ...getColumnFilter({
          inputName: "itemName",
          inputType: "SELECT",
          selectItems: Boolean(this.state["itemIdSelectItems"]) && this.state["itemIdSelectItems"].length > 0 ? this.state["itemIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryLog.DeliveryPlace" defaultMessage="Delivery Place" />,
        key: "placeAddress",
        width: 100,
        render: record => {
          return record.placeAddress;
        },
        ...getColumnFilter({
          inputName: "placeAddress",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryLog.Store" defaultMessage="Store" />,
        key: "store",
        width: 100,
        render: record => {
          return record.store;
        },
        ...getColumnFilter({
          inputName: "store",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryLog.PO" defaultMessage="PO#" />,
        key: "purchaseOrderNo",
        width: 100,
        render: record => {
          return record.purchaseOrderNo;
        },
        ...getColumnFilter({
          inputName: "purchaseOrderNo",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryLog.PODate" defaultMessage="P.O. Date" />,
        key: "sentToVendorsDate",
        width: 100,
        render: record => {
          return Boolean(record.sentToVendorsDate) ? moment(record.sentToVendorsDate).format("DD-MM-YYYY HH:mm:ss") : "";
        },
        ...getColumnFilter({
          inputName: "sentToVendorsDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
      // {
      //   title: <FormattedMessage id="InventoryLog.ReceiveStatus" defaultMessage="Receive Status" />,
      //   key: "receiveStatus",
      //   width: 100,
      //   render: record => {
      //     return (
      //       Boolean(record.receiveStatus) && (
      //         <Tag color={record.receiveStatus === "Accept" ? "green" : record.receiveStatus === "OnHold" ? "blue" : "red"}>
      //           <FormattedMessage id={'InventoryLog.' + record.receiveStatus} defaultMessage={record.receiveStatus} />
      //         </Tag>
      //       )
      //     );
      //   }
      // }
    ];

    const columnsRelease = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.paginationRelease.pageSize * (this.state.paginationRelease.current - 1);
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.Store" defaultMessage="Store" />,
        key: "stockId",
        width: 100,
        render: record => {
          return record.stockName;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.ItemDescription" defaultMessage="Item Description" />,
        key: "itemName",
        width: 100,
        ellipsis: true,
        render: record => {
          return record.itemName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              onChange={value => {
                const { paginationRelease } = this.state;
                let _pagination = {
                  ...paginationRelease,
                  stockCardId: value,
                  stockCardIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  paginationRelease: _pagination
                });

                this.restartTableReleaseOtherItems({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["stockCardIdSelectItems"]) &&
                this.state["stockCardIdSelectItems"].length > 0 &&
                this.state["stockCardIdSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.paginationRelease.stockCardIdColor) ? this.state.paginationRelease.stockCardIdColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryLog.Project" defaultMessage="Project" />,
        key: "id",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.PurphoseOfUse" defaultMessage="Purphose of Use" />,
        key: "id",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.Quantity" defaultMessage="Quantity" />,
        key: "quantity",
        width: 100,
        render: record => {
          return record.quantity;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.ReservedQuantity" defaultMessage="Reserved Quantity" />,
        key: "reservedQuantity",
        width: 100,
        render: record => {
          return record.reservedQuantity;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.ToBeReleasedQuantity" defaultMessage="To Be Released Quantity" />,
        key: "toBeReleasedQuantity",
        width: 100,
        render: record => {
          return record.toBeReleasedQuantity;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.ToWhom" defaultMessage="To Whom" />,
        key: "id",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.PlannedReleaseDate" defaultMessage="Planned Release Date" />,
        key: "plannedDeliveryDate",
        width: 100,
        render: record => {
          return Boolean(record.plannedDeliveryDate) ? moment(record.plannedDeliveryDate).format("DD-MM-YYYY HH:mm") : "";
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.PO" defaultMessage="P.O." />,
        key: "purchaseOrderId",
        width: 100,
        render: record => {
          return record.poNo;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.PlannedReleaseDate" defaultMessage="P.O. Date" />,
        key: "purchaseOrderId",
        width: 100,
        render: record => {
          return Boolean(record.poDate) ? moment(record.poDate).format("DD-MM-YYYY HH:mm") : "";
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.Supplier" defaultMessage="Supplier" />,
        key: "manufacturer",
        width: 100,
        render: record => {
          return record.manufacturer;
        }
      },
      {
        title: <FormattedMessage id="InventoryLog.Status" defaultMessage="Status" />,
        key: "id",
        width: 100,
        render: record => {
          return "";
        }
      }
    ];

    const handleResizeColumnsRelease = index => (_, { size }) => {
      const newColumnsRelease = [...columnsRelease];
      newColumnsRelease[index] = {
        ...newColumnsRelease[index],
        width: size.width
      };

      this.setState({
        columnsRelease: newColumnsRelease
      });
    };

    const handleResizeColumnsReceiving = index => (_, { size }) => {
      const newColumnsReceiving = [...columnsReceiving];
      newColumnsReceiving[index] = {
        ...newColumnsReceiving[index],
        width: size.width
      };

      this.setState({
        columnsReceiving: newColumnsReceiving
      });
    };

    const handleResizeColumnsMovement = index => (_, { size }) => {
      const newColumnsMovement = [...columnsMovement];
      newColumnsMovement[index] = {
        ...newColumnsMovement[index],
        width: size.width
      };

      this.setState({
        columnsMovement: newColumnsMovement
      });
    };

    const handleResizeColumnsReserve = index => (_, { size }) => {
      const newcolumnsReserve = [...columnsReserve];
      newcolumnsReserve[index] = {
        ...newcolumnsReserve[index],
        width: size.width
      };

      this.setState({
        columnsReserve: newcolumnsReserve
      });
    };

    const handleResizeColumns = index => (_, { size }) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumnsRelease =
      Boolean(this.state.columnsRelease) && this.state.columnsRelease.length > 0
        ? this.state.columnsRelease.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumnsRelease(index)
          })
        }))
        : Boolean(columnsRelease) &&
        columnsRelease.length > 0 &&
        columnsRelease.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumnsRelease(index)
          })
        }));

    const mergeColumnsReceiving =
      Boolean(this.state.columnsReceiving) && this.state.columnsReceiving.length > 0
        ? this.state.columnsReceiving.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumnsReceiving(index)
          })
        }))
        : Boolean(columnsReceiving) &&
        columnsReceiving.length > 0 &&
        columnsReceiving.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumnsReceiving(index)
          })
        }));

    const mergeColumnsMovement =
      Boolean(this.state.columnsMovement) && this.state.columnsMovement.length > 0
        ? this.state.columnsMovement.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumnsMovement(index)
          })
        }))
        : Boolean(columnsMovement) &&
        columnsMovement.length > 0 &&
        columnsMovement.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumnsMovement(index)
          })
        }));

    const mergeColumnsReserve =
      Boolean(this.state.columnsReserve) && this.state.columnsMocolumnsReservevement.length > 0
        ? this.state.columnsReserve.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumnsReserve(index)
          })
        }))
        : Boolean(columnsReserve) &&
        columnsReserve.length > 0 &&
        columnsReserve.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumnsReserve(index)
          })
        }));

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumns(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumns(index)
          })
        }));

    return (
      <>
        <div className="col-lg-12">
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-body">
              <div className="row m-0">
                <div className="col-lg-4">
                  <Carousel autoplay>
                    <div>
                      <div className="col bg-light-warning px-6 py-4 rounded-xl mr-7 mb-3 justify-content-center">
                        <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2"></span>
                        <a href="#" className="text-warning font-weight-bold font-size-h6">
                          New Request:
                          <divs id></divs>
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="col bg-light-primary px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2"></span>
                        <a href="#" className="text-primary font-weight-bold font-size-h6 mt-2">
                          Pending Requests:
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="col bg-light-danger px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2"></span>
                        <a href="#" className="text-danger font-weight-bold font-size-h6 mt-2">
                          Done:
                        </a>
                      </div>
                    </div>
                  </Carousel>
                </div>

                <div className="col-lg-4">
                  <Carousel autoplay>
                    <div>
                      <div className="col bg-light-success px-6 py-4 rounded-xl mb-3">
                        <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2"></span>
                        <a href="#" className="text-success font-weight-bold font-size-h6 mt-2">
                          Pound
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="col bg-light-warning px-6 py-4 rounded-xl mr-7 mb-3 justify-content-center">
                        <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2"></span>
                        <a href="#" className="text-warning font-weight-bold font-size-h6">
                          Bitcoin
                        </a>
                      </div>
                    </div>

                    <div>
                      <div className="col bg-light-primary px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2"></span>
                        <a href="#" className="text-primary font-weight-bold font-size-h6 mt-2">
                          Dollar
                        </a>
                      </div>
                    </div>

                    <div>
                      <div className="col bg-light-danger px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2"></span>
                        <a href="#" className="text-danger font-weight-bold font-size-h6 mt-2">
                          Euro
                        </a>
                      </div>
                    </div>
                  </Carousel>
                </div>

                <div className="col-lg-4">
                  <Carousel autoplay>
                    <div>
                      <div className="col bg-light-danger px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2"></span>
                        <a href="#" className="text-danger font-weight-bold font-size-h6 mt-2">
                          Chart-Bar
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="col bg-light-success px-6 py-4 rounded-xl mb-3">
                        <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2"></span>
                        <a href="#" className="text-success font-weight-bold font-size-h6 mt-2">
                          Chart-Line
                        </a>
                      </div>
                    </div>

                    <div>
                      <div className="col bg-light-warning px-6 py-4 rounded-xl mr-7 mb-3 justify-content-center">
                        <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2"></span>
                        <a href="#" className="text-warning font-weight-bold font-size-h6">
                          Shuffle
                        </a>
                      </div>
                    </div>

                    <div>
                      <div className="col bg-light-primary px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2"></span>
                        <a href="#" className="text-primary font-weight-bold font-size-h6 mt-2">
                          Chart-Pie
                        </a>
                      </div>
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Badge.Ribbon
          text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage="Inventory Log" />}
          placement="start"
          color="volcano"
          style={{ fontSize: 12 }}
        >
          <div className="card card-custom" style={{ padding: "4rem", minHeight: "100%" }}>
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
              <Row gutter={[16, 16]}>
                <Col md={5}>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="Stock.Branch" defaultMessage="Branch" />}
                      name="projectId"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch style={{ width: "150px" }} optionFilterProp="children"
                        onChange={value => {
                          this.setState({
                            projectId: value
                          });
                          this.fillStock(value);
                        }}>
                        {Boolean(this.state["projectIdSelectItems"]) &&
                          this.state["projectIdSelectItems"].length > 0 &&
                          this.state["projectIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                </Col>
                <Col md={5}>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="InventoryLog.stockIds" defaultMessage="Stock" />}
                      name="stockId"
                      rules={[
                        {
                          required: true,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Cascader changeOnSelect style={{ width: "150px" }} options={this.state.nodes} onChange={this.handleStockIdChange} />
                    </Form.Item>
                  }
                </Col>
                <Col md={3}></Col>
                <Col md={3}>
                  {/* <Button id="ReceiveButton" style={{ width: "100%" }} variant="success" onClick={this.receiveModal}>
                    <FormattedMessage id="ButtonReceive" defaultMessage="Receive" />
                  </Button> */}
                  <Button id="ReceiveButton" style={{ width: "100%" }} variant="success" onClick={this.receiveSelectModal}>
                    <FormattedMessage id="ButtonReceive" defaultMessage="Receive" />
                  </Button>
                </Col>
                <Col md={3}>
                  <Button id="ReleaseButton" style={{ width: "100%" }} variant="danger" onClick={this.releaseModal}>
                    <FormattedMessage id="ButtonRelease" defaultMessage="Release" />
                  </Button>
                </Col>
                <Col md={1}>
                  {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                    <Tooltip title={<FormattedMessage id="InventoryLog.Settings" defaultMessage="Settings" />}>
                      <AntButton
                        style={{ border: "0px" }}
                        onClick={() => {
                          this.setState({
                            drawerSettingsShow: true
                          });
                        }}
                        icon={<SettingsIcon color="error" fontSize="large" />}
                      ></AntButton>
                    </Tooltip>
                  )}
                </Col>
                <Col md={3}>
                  <AntButton
                    type="primary"
                    style={{ borderRadius: "5px", width: "100%" }}
                    onClick={() => {
                      this.setState({
                        showStockmovementsModal: true
                      });
                    }}
                  >
                    Movements
                  </AntButton>
                </Col>
                <Col md={1}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => {
                      this.setState({
                        modalPdfViewerConditions: true
                      });
                    }}
                    icon={<PictureAsPdf style={{ color: "red" }} fontSize="large" />}
                  ></AntButton>
                </Col>
              </Row>

              <div hidden={this.state.hideInputs}>
                <div hidden={this.state.hideSave}>
                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                          <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </div>
                <div hidden={this.state.hideUpdate}>
                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                          <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                        </Button>
                      </Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                          <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </div>
              </div>
            </Form>
            <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
              {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
                <div className="alert alert-custom alert-light">
                  <div className="alert-icon">
                    <FilterOutlined />
                  </div>
                  <div className="alert-text">
                    {this.state.searchCriteriaModels.map((val, i) => {
                      return (
                        <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                          <span className="mr-2">{val.label}</span>
                          <strong className="mr-2">{val.type}</strong>
                          <span className="mr-2">{val.operationValue}</span>
                          <span className="mr-2">{val.showValue}</span>
                          <span>
                            <Tooltip title="Remove">
                              <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                            </Tooltip>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="alert-close">
                    <Tooltip title="Remove All">
                      <CloseCircleOutlined
                        style={{ color: "crimson" }}
                        onClick={() =>
                          this.setState({ searchCriteriaModels: [] }, () => {
                            this.filterChangeItem([]);
                          })
                        }
                      />
                    </Tooltip>
                  </div>
                </div>
              )}

              <Table
                bordered
                rowKey="key"
                scroll={{ x: 1200, y: 900, scrollToFirstRowOnChange: true }}
                components={{
                  header: {
                    cell: ResizableTitle
                  }
                }}
                columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
                dataSource={this.state.tableList}
                loading={this.state.loading}
                onChange={this.handleTableChange}
                pagination={this.state.pagination}
              />
            </div>

            <DraggableModal
              title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
              centered
              width={500}
              okButtonProps={{ hidden: true }}
              open={this.state.modalDeleteShow}
              onCancel={() => {
                this.setState({ modalDeleteShow: false });
              }}
              footer={[
                <Space>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      this.setState({ modalDeleteShow: false });
                    }}
                  >
                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                  </Button>
                  <Button variant="danger" onClick={this.delete}>
                    <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                  </Button>
                </Space>
              ]}
              content={
                <>
                  <p>
                    <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
                  </p>
                </>
              }
            ></DraggableModal>

            <DraggableModal
              centered
              width={1000}
              title={this.state.selectedPO?.description + " Material Receiving"}
              open={this.state.showStockReceivingModal}
              onCancel={() => {
                this.setState({
                  showStockReceivingModal: false,
                  selectedRowKeysReceiving: []
                });
              }}
              footer={[
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      showStockReceivingModal: false,
                      selectedRowKeysReceiving: []
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
              ]}
              content={
                <InventoryLogReceive
                  id={this.state.id}
                  random={Math.random()}
                  stockId={this.state.stockId}
                  selectedPO={this.state.selectedPO}
                  receivingType={this.state.receivingType}
                  saveStockReceiving={this.saveStockReceiving}
                />
              }
            />

            <DraggableModal
              centered
              width={1400}
              title={<FormattedMessage id="InventoryLog.Receiving" defaultMessage="Receiving" />}
              open={this.state.showReceivingModal}
              onCancel={() => {
                this.setState({
                  showReceivingModal: false
                });
              }}
              footer={[
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      showReceivingModal: false
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
              ]}
              content={
                <>
                  {/* 
                  <Form initialValues={{ remember: false }} ref={this.formRefReceive}>
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="InventoryLog.receivingType" defaultMessage="Type" />}
                      name="receivingType"
                      rules={[
                        {
                          required: true,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Radio.Group
                        onChange={e => {
                          let ConsigmentReceiveModal = false;
                          let TransferReceiveModal = false;
                          let EmergencyReceiveModal = false;
                          if (e.target.value === "Consigment") {
                            ConsigmentReceiveModal = true;
                          }
                          if (e.target.value === "Emergency") {
                            EmergencyReceiveModal = true;
                          } else if (e.target.value === "Transfer") {
                            TransferReceiveModal = true;
                          }
                          this.setState({
                            receivingType: e.target.value,
                            TransferReceiveModal: TransferReceiveModal,
                            ConsigmentReceiveModal: ConsigmentReceiveModal,
                            EmergencyReceiveModal: EmergencyReceiveModal
                          });
                        }}
                      >
                        <Row gutter={24}>
                          <Col span={6}>
                            <Radio key={"Purchasing"} value={"Purchasing"}>
                              {<FormattedMessage id="InventoryLog.Purchasing" defaultMessage="Purchasing" />}
                            </Radio>
                          </Col>
                          <Col span={6}>
                            <Radio key={"Transfer"} value={"Transfer"}>
                              {<FormattedMessage id="InventoryLog.Transfer" defaultMessage="Transfer" />}
                            </Radio>
                          </Col>
                          <Col span={6}>
                            <Radio key={"Consigment"} value={"Consigment"}>
                              {<FormattedMessage id="InventoryLog.Consigment" defaultMessage="Consigment" />}
                            </Radio>
                          </Col>
                          <Col span={6}>
                            <Radio key={"Emergency"} value={"Emergency"}>
                              {<FormattedMessage id="InventoryLog.Emergency" defaultMessage="Emergency" />}
                            </Radio>
                          </Col>
                        </Row>
                      </Radio.Group>
                    </Form.Item>
                  </Form> 
                  */}

                  <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                    <Table
                      bordered
                      rowKey="index"
                      // rowSelection={{
                      //   type: "radio",
                      //   ...rowSelectionReceiving
                      // }}
                      scroll={{ x: 2000, scrollToFirstRowOnChange: true }}
                      components={{
                        header: {
                          cell: ResizableTitle
                        }
                      }}
                      columns={Boolean(mergeColumnsReceiving) && mergeColumnsReceiving.length > 0 ? mergeColumnsReceiving : columnsReceiving}
                      dataSource={this.state.tableReceivingList}
                      loading={this.state.loading}
                      onChange={this.handleTableChangeReceiving}
                      pagination={false}
                    />
                  </div>
                </>
              }
            />

            <DraggableModal
              centered
              width={900}
              title={<FormattedMessage id="InventoryLog.Release" defaultMessage="Release" />}
              open={this.state.showStockReleaseModal}
              onCancel={() => {
                this.setState({
                  showStockReleaseModal: false,
                  selectedRowKeysRelease: []
                });
              }}
              footer={[
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      showStockReleaseModal: false,
                      selectedRowKeysRelease: []
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
              ]}
              content={
                <InventoryLogRelease
                  selectedRow={this.state.selectedRow}
                  id={this.state.id}
                  random={Math.random()}
                  stockId={this.state.stockId}
                  selectedPrjTakeOff={this.state.selectedPrjTakeOff}
                  releaseType={this.state.releaseType}
                  onPurposeType={this.state.onPurposeType}
                  inventoryLogReservedId={this.state.inventoryLogReservedId}
                  inventoryLogReleaseId={this.state.inventoryLogReleaseId}
                  saveStockRelease={this.saveStockRelease}
                />
              }
            />

            <DraggableModal
              centered
              width={1200}
              title={<FormattedMessage id="InventoryLog.Release" defaultMessage="Release" />}
              open={this.state.showReleaseModal}
              onCancel={() => {
                this.setState({
                  showReleaseModal: false
                });
              }}
              footer={[
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      showReleaseModal: false
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
              ]}
              content={
                <>
                  <Form initialValues={{ remember: false }} ref={this.formRefRelease}>
                    <Row gutter={8}>
                      <Col span={8}></Col>
                      <Col span={10}>
                        <Form.Item
                          name="releaseType"
                          rules={[
                            {
                              required: true,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                          extra={this.state.onPurposeTypeName}
                        >
                          {/**          <Radio.Group
                      onChange={e => {
                        let showOnPurposeTypeModal = false;
                        if (e.target.value === "On_Purpose") {
                          showOnPurposeTypeModal = true;
                        }
                        this.setState({
                          releaseType: e.target.value,
                          showOnPurposeTypeModal: showOnPurposeTypeModal,
                          onPurposeType: null,
                          onPurposeTypeName: "",
                          tableRelease: [],
                        });
                      }}
                    >*/}
                          <Radio.Group
                            onChange={e => {
                              let showOnPurposeTypeModal = false;
                              let ConsigmentReleaseModal = false;
                              let TransferReleaseModal = false;
                              if (e.target.value === "On_Purpose") {
                                showOnPurposeTypeModal = true;
                              } else if (e.target.value === "Consigment") {
                                ConsigmentReleaseModal = true;
                              } else if (e.target.value === "Transfer") {
                                TransferReleaseModal = true;
                              }
                              this.setState({
                                releaseType: e.target.value,
                                showOnPurposeTypeModal: showOnPurposeTypeModal,
                                ConsigmentReleaseModal: ConsigmentReleaseModal,
                                TransferReleaseModal: TransferReleaseModal,
                                onPurposeType: null,
                                onPurposeTypeName: "",
                                tableRelease: []
                              });
                            }}
                          >
                            <Radio key={"On_Purpose"} value={"On_Purpose"}>
                              {"On Purpose"}
                            </Radio>
                            <Radio key={"Transfer"} value={"Transfer"}>
                              {"Transfer"}
                            </Radio>
                            <Radio key={"Consigment"} value={"Consigment"}>
                              {"Consigment"}
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <Button id="SearchButton" style={{ width: "100%" }} variant="success" onClick={this.prjTakeOffSearch}>
                          <FormattedMessage id="GeneralButtonSearch" defaultMessage="Search" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  </Form>
                  <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                    {this.state.onPurposeType === "ProjectItems" ? (
                      <Table
                        bordered
                        rowKey="id"
                        rowSelection={{
                          type: "radio",
                          ...rowSelectionRelease
                        }}
                        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                        components={{
                          header: {
                            cell: ResizableTitle
                          }
                        }}
                        columns={Boolean(mergeColumnsRelease) && mergeColumnsRelease.length > 0 ? mergeColumnsRelease : columnsRelease}
                        dataSource={this.state.tableRelease}
                        loading={this.state.loading}
                        onChange={this.handleTableChangeRelease}
                        pagination={this.state.paginationRelease}
                      />
                    ) : this.state.onPurposeType === "OtherItems" ? (
                      <Table
                        bordered
                        rowKey="id"
                        rowSelection={{
                          type: "radio",
                          ...rowSelectionRelease
                        }}
                        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                        components={{
                          header: {
                            cell: ResizableTitle
                          }
                        }}
                        columns={Boolean(mergeColumnsRelease) && mergeColumnsRelease.length > 0 ? mergeColumnsRelease : columnsRelease}
                        dataSource={this.state.tableRelease}
                        loading={this.state.loading}
                        onChange={this.handleTableChangeOtherItems}
                        pagination={this.state.paginationRelease}
                      />
                    ) : (
                      <Table
                        bordered
                        rowKey="id"
                        rowSelection={{
                          type: "radio",
                          ...rowSelectionRelease
                        }}
                        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                        components={{
                          header: {
                            cell: ResizableTitle
                          }
                        }}
                        columns={Boolean(mergeColumnsRelease) && mergeColumnsRelease.length > 0 ? mergeColumnsRelease : columnsRelease}
                        dataSource={this.state.tableRelease}
                        loading={this.state.loading}
                        onChange={this.handleTableChangeRelease}
                        pagination={this.state.paginationRelease}
                      />
                    )}
                  </div>
                </>
              }
            />

            <DraggableModal
              centered
              width={1500}
              title="Stock Movement"
              open={this.state.showMovementModal}
              onCancel={() => {
                this.setState({
                  showMovementModal: false
                });
              }}
              footer={[
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      showMovementModal: false
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
              ]}
              content={
                <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                  <Table
                    bordered
                    rowKey="id"
                    scroll={{ x: 1200, scrollToFirstRowOnChange: true }}
                    components={{
                      header: {
                        cell: ResizableTitle
                      }
                    }}
                    columns={Boolean(mergeColumnsMovement) && mergeColumnsMovement.length > 0 ? mergeColumnsMovement : columnsMovement}
                    dataSource={this.state.tableMovementList}
                    loading={this.state.loading}
                    pagination={this.state.paginationMovement}
                    onChange={this.handleTableChangeMovement}
                  />
                </div>
              }
            />

            <DraggableModal
              centered
              width={1200}
              title=""
              open={this.state.showStockQuantityDimensionModal}
              onCancel={() => {
                this.setState({
                  showStockQuantityDimensionModal: false
                });
              }}
              footer={[
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      showStockQuantityDimensionModal: false
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
              ]}
              content={
                <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                  <Table
                    bordered
                    rowKey="id"
                    columns={this.state.columnsStockQuantityDimension}
                    dataSource={this.state.tableStockQuantityDimensiontList}
                    loading={this.state.loading}
                    pagination={false}
                  />
                </div>
              }
            />

            <DraggableModal
              centered
              width={900}
              title={<FormattedMessage id="InventoryLog.reserve" defaultMessage="Reserve" />}
              open={this.state.showReserveModal}
              onCancel={() => {
                this.setState({
                  showReserveModal: false
                });
              }}
              footer={[
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      showReserveModal: false
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
              ]}
              content={
                <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
                  <Form id="FormReserve" initialValues={{ remember: false }} onFinish={this.saveReserveSubmit} ref={this.formRefReserve}>
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="InventoryLog.availableQuantity" defaultMessage="Available Stock Quantity" />}
                        style={{ marginBottom: "5px" }}
                        name="availableQuantity"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <InputNumber
                          style={{
                            width: "100%"
                          }}
                          min={0}
                          readOnly
                        />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="InventoryLog.ReservedQuantity" defaultMessage="Reserved Quantity" />}
                        style={{ marginBottom: "5px" }}
                        name="reservedQuantity"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <InputNumber
                          style={{
                            width: "100%"
                          }}
                          min={1}
                          max={this.state.availableQuantity}
                        />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="InventoryLog.Project" defaultMessage="Project" />}
                        style={{ marginBottom: "5px" }}
                        name="project"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                          {Boolean(this.state["projectSelectItems"]) &&
                            this.state["projectSelectItems"].length > 0 &&
                            this.state["projectSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    }

                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="InventoryLog.PlannedReleasedDate" defaultMessage="Planned released date" />}
                        style={{ marginBottom: "5px" }}
                        name="plannedReleasedDate"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <DatePicker showTime format="DD-MM-YYYY HH:mm" style={{ width: "100%" }} />
                      </Form.Item>
                    }

                    {
                      <Row gutter={[16, 16]}>
                        <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                          <Button id="SaveButton" style={{ width: "100%" }} variant="success" type="submit">
                            <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                          </Button>
                        </Col>
                        <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      </Row>
                    }
                  </Form>
                </div>
              }
            />

            <DraggableModal
              centered
              width={900}
              title="Min Stock Requirement"
              open={this.state.showMinStockRequirementModal}
              onCancel={() => {
                this.setState({
                  showMinStockRequirementModal: false
                });
              }}
              footer={[
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      showMinStockRequirementModal: false
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
              ]}
              content={
                <div className="card card-custom" style={{ minHeight: "100%" }}>
                  <Form
                    id="FormMinStockRequirement"
                    initialValues={{ remember: false }}
                    onFinish={this.saveMinQuantitySubmit}
                    ref={this.formRefMinStockReq}
                  >
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="InventoryLog.minReqQuantity" defaultMessage="Minimum Stock" />}
                        style={{ marginBottom: "5px" }}
                      >
                        <Row gutter={8}>
                          <Col span={8}>
                            <Form.Item name="minReqQuantity">
                              <InputNumber
                                style={{
                                  width: "100%"
                                }}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2}></Col>
                          <Col span={14}>{this.state.formMinQuantityUnitName}</Col>
                        </Row>
                      </Form.Item>
                    }

                    {
                      <Row gutter={[16, 16]}>
                        <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                          <Button id="SaveButton" style={{ width: "100%" }} variant="success" type="submit">
                            <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                          </Button>
                        </Col>
                        <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      </Row>
                    }
                  </Form>
                </div>
              }
            />

            <DraggableModal
              title={
                <span>
                  <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                  <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                    <FormattedMessage id="InventoryLog.RequestForQualityControl" defaultMessage="Request For Quality Control" />
                  </span>
                </span>
              }
              centered
              open={this.state.modalRFQCShow}
              onOk={() => this.sendQualityControl()}
              onCancel={() =>
                this.setState({
                  modalRFQCShow: false,
                  selectedRowKeysReceiving: []
                })
              }
              okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
              cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
              content={
                <FormattedMessage id="InventoryLog.MessageSendQualityControl" defaultMessage="Do you really want to send to Quality Control?" />
              }
            />

            <DraggableModal
              title={
                <span>
                  <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                  <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                    <FormattedMessage id="InventoryLog.DeleteReceivingRecords" defaultMessage="Delete Receiving Records" />
                  </span>
                </span>
              }
              centered
              open={this.state.modalDeleteReceivingRecordsShow}
              onOk={() => this.deleteReceivingRecords()}
              onCancel={() =>
                this.setState({
                  modalDeleteReceivingRecordsShow: false,
                  selectedRow: null
                })
              }
              okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
              cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
              content={
                <FormattedMessage id="InventoryLog.MessageDeleteQualityControl" defaultMessage="Do you really want to delete receiving records?" />
              }
            />

            <DraggableModal
              title={
                <Badge.Ribbon
                  text={<FormattedMessage id="TransferReleaseModal.pageTitle" defaultMessage="Transfer-Release" />}
                  placement="start"
                  color="volcano"
                  style={{ fontSize: 12 }}
                ></Badge.Ribbon>
              }
              closable="false"
              open={this.state.TransferReleaseModal}
              onCancel={() => {
                this.setState({ TransferReleaseModal: false });
              }}
              width={1200}
              centered
              footer={[
                <Space>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      this.setState({ TransferReleaseModal: false });
                    }}
                  >
                    <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                  </Button>
                  <Button
                    style={{ width: "100%" }}
                    variant="success"
                    onClick={() => {
                      this.setState({ TransferReleaseModal: false });
                      this.setState({ ConsigmentReceiveModal: true });
                    }}
                  >
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Space>
              ]}
              content={
                <>
                  <br></br>
                  <br></br>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="TransferReleaseModal.overtimeCriterias" defaultMessage="Transfer to" />}
                    name="overtimeCriterias"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }} placeholder="   ">
                      {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                        this.state["overtimeCriteriasSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="TransferReleaseModal.overtimeCriterias" defaultMessage="Handovered to" />}
                    name="overtimeCriterias"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }} placeholder="  ">
                      {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                        this.state["overtimeCriteriasSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="TransferReleaseModal.overtimeCriterias" defaultMessage="Delivered by " />}
                    name="overtimeCriterias"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }} placeholder="  ">
                      {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                        this.state["overtimeCriteriasSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  <br />
                  <h4>
                    <FormattedMessage id={"TransferReleaseModal.Transfer Release"} defaultMessage={"  "} />
                  </h4>

                  <Form.List name="TransferReleaseModal">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Code"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Group"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Category"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"Item"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Photo"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"Dimension"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"Quantity"} />
                                </th>
                                <th colSpan="1"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td></td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[
                                          {
                                            required: true,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Input
                                          style={{
                                            width: "40%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[
                                          {
                                            required: true,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Input
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ExecutionPlan.AddNewPersonnel"} defaultMessage={"Add New Item "} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </>
              }
            />

            <DraggableModal
              title={
                <Badge.Ribbon
                  text={<FormattedMessage id="OuterLayoutHouseType.pageTitle" defaultMessage="Consigment " />}
                  placement="start"
                  color="volcano"
                  style={{ fontSize: 12 }}
                ></Badge.Ribbon>
              }
              closable="false"
              open={this.state.ConsigmentReceiveModal}
              onCancel={() => {
                this.setState({ ConsigmentReceiveModal: false });
              }}
              width={1200}
              centered
              footer={[
                <Space>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      this.setState({ ConsigmentReceiveModal: false });
                    }}
                  >
                    <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                  </Button>
                  <Button
                    style={{ width: "100%" }}
                    variant="success"
                    onClick={() => {
                      // this.setState({ ConsigmentReceiveModal: false });
                      // this.setState({ TransferReceiveModal: true })
                    }}
                  >
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Space>
              ]}
              content={
                <>
                  <br></br>
                  <br></br>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenLaborLaw.overtimeCriterias" defaultMessage="Handovered to" />}
                    name="overtimeCriterias"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select style={{ width: "100%" }} mode="multiple" placeholder="  ">
                      {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                        this.state["overtimeCriteriasSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenLaborLaw.overtimeCriterias" defaultMessage="Delivered by " />}
                    name="overtimeCriterias"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select style={{ width: "100%" }} mode="multiple" placeholder="  ">
                      {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                        this.state["overtimeCriteriasSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  <br />
                  <h4>
                    <FormattedMessage id={"APDetailsTypesOfHousesModal.SectionsOfApartments"} defaultMessage={"  "} />
                  </h4>

                  <Form.List name="APDetailsTypesOfHouses">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Code"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Group"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Category"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"Item"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Photo"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"Dimension"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"Quantity"} />
                                </th>
                                <th colSpan="1"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td></td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[
                                          {
                                            required: true,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Input
                                          style={{
                                            width: "40%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[
                                          {
                                            required: true,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Input
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ExecutionPlan.AddNewPersonnel"} defaultMessage={"Add New Item "} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </>
              }
            />

            <DraggableModal
              title={
                <Badge.Ribbon
                  text={<FormattedMessage id="TransferReceiveModal.pageTitle" defaultMessage="Transfer-Receive" />}
                  placement="start"
                  color="volcano"
                  style={{ fontSize: 12 }}
                ></Badge.Ribbon>
              }
              closable="false"
              open={this.state.TransferReceiveModal}
              onCancel={() => {
                this.setState({ TransferReceiveModal: false });
              }}
              width={1200}
              centered
              footer={[
                <Space>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      this.setState({ TransferReceiveModal: false });
                    }}
                  >
                    <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                  </Button>
                  <Button
                    style={{ width: "100%" }}
                    variant="success"
                    onClick={() => {
                      this.setState({ TransferReceiveModal: false });
                    }}
                  >
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Space>
              ]}
              content={
                <>
                  <br></br>
                  <br></br>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenLaborLaw.overtimeCriterias" defaultMessage="Transfer from" />}
                    name="overtimeCriterias"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                      {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                        this.state["overtimeCriteriasSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenLaborLaw.overtimeCriterias" defaultMessage="Delivered by " />}
                    name="overtimeCriterias"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select style={{ width: "100%" }} mode="multiple" placeholder="  ">
                      {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                        this.state["overtimeCriteriasSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenLaborLaw.overtimeCriterias" defaultMessage="Recieved by" />}
                    name="overtimeCriterias"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select style={{ width: "100%" }} mode="multiple" placeholder="  ">
                      {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                        this.state["overtimeCriteriasSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  <br />
                  <h4>
                    <FormattedMessage id={"APDetailsTypesOfHousesModal.SectionsOfApartments"} defaultMessage={"  "} />
                  </h4>

                  <Form.List name="APDetailsTypesOfHouses">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Code"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Group"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Category"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"Item"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Photo"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"Dimension"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"Quantity"} />
                                </th>
                                <th colSpan="1"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td></td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[
                                          {
                                            required: true,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Input
                                          style={{
                                            width: "40%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[
                                          {
                                            required: true,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Input
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ExecutionPlan.AddNewPersonnel"} defaultMessage={"Add New Item "} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </>
              }
            />

            <DraggableModal
              title={<FormattedMessage id="EmergencyReceiveModal.pageTitle" defaultMessage="Emergency" />}
              closable="false"
              open={this.state.EmergencyReceiveModal}
              onCancel={() => {
                this.setState({ EmergencyReceiveModal: false });
              }}
              width={1200}
              centered
              footer={[
                <Space>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      this.setState({ EmergencyReceiveModal: false });
                    }}
                  >
                    <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                  </Button>
                </Space>
              ]}
              content={
                <Emergency stockId={this.state.stockId}
                  random={Math.random()}
                  close={() => {
                    this.setState({
                      EmergencyReceiveModal: false,
                      showReceivingModal: false
                    }, () => {
                      const { pagination } = this.state;
                      this.restartTable({ pagination });
                    });
                  }} />
              }
            />

            <DraggableModal
              title={
                <Badge.Ribbon
                  text={<FormattedMessage id="OuterLayoutHouseType.pageTitle" defaultMessage="Consigment Release " />}
                  placement="start"
                  color="volcano"
                  style={{ fontSize: 12 }}
                ></Badge.Ribbon>
              }
              closable="false"
              open={this.state.ConsigmentReleaseModal}
              onCancel={() => {
                this.setState({ ConsigmentReleaseModal: false });
              }}
              width={1200}
              centered
              footer={[
                <Space>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      this.setState({ ConsigmentReleaseModal: false });
                    }}
                  >
                    <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                  </Button>
                  <Button
                    style={{ width: "100%" }}
                    variant="success"
                    onClick={() => {
                      this.setState({ ConsigmentReleaseModal: false });
                      this.setState({ ConsigmentReleaseModal: true });
                    }}
                  >
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Space>
              ]}
              content={
                <>
                  <br></br>
                  <br></br>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenLaborLaw.overtimeCriterias" defaultMessage="Delivered by" />}
                    name="overtimeCriterias"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select style={{ width: "100%" }} mode="multiple" placeholder="  ">
                      {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                        this.state["overtimeCriteriasSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenLaborLaw.overtimeCriterias" defaultMessage="Recieved by" />}
                    name="overtimeCriterias"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select style={{ width: "100%" }} mode="multiple" placeholder="  ">
                      {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                        this.state["overtimeCriteriasSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  <br />
                  <h4>
                    <FormattedMessage id={"APDetailsTypesOfHousesModal.SectionsOfApartments"} defaultMessage={" "} />
                  </h4>

                  <Form.List name="APDetailsTypesOfHouses">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Code"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Group"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Category"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"Item"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Photo"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"Dimension"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"Quantity"} />
                                </th>
                                <th colSpan="1"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td></td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[
                                          {
                                            required: true,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Input
                                          style={{
                                            width: "40%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[
                                          {
                                            required: true,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Input
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ExecutionPlan.AddNewPersonnel"} defaultMessage={"Add New Item "} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </>
              }
            />

            <DraggableModal
              title={<FormattedMessage id="InventoryLog.OnPurposeType" defaultMessage="On Purpose Type" />}
              centered
              width={500}
              open={this.state.showOnPurposeTypeModal}
              onCancel={() => {
                this.setState({
                  showOnPurposeTypeModal: false
                });
              }}
              footer={[
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      showOnPurposeTypeModal: false
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
              ]}
              content={
                <div className="card card-custom" style={{ minHeight: "100%" }}>
                  <Row gutter={8}>
                    <Col span={6}></Col>
                    <Col span={12}>
                      <Radio.Group
                        value={this.state.onPurposeType}
                        onChange={e => {
                          let onPurposeTypeName = "";
                          if (e.target.value === "ReservedItems") {
                            onPurposeTypeName = "Reserved Items";
                          } else if (e.target.value === "ToBeReleasedItems") {
                            onPurposeTypeName = "To be released items";
                          } else if (e.target.value === "ProjectItems") {
                            onPurposeTypeName = "Project Items";
                          } else if (e.target.value === "Requests") {
                            onPurposeTypeName = "Requests";
                          } else if (e.target.value === "OtherItems") {
                            onPurposeTypeName = "Other Items";
                          }
                          this.setState({
                            onPurposeType: e.target.value,
                            showOnPurposeTypeModal: false,
                            onPurposeTypeName: onPurposeTypeName
                          });
                        }}
                      >
                        <Radio key={"ReservedItems"} value={"ReservedItems"}>
                          {"Reserved items"}
                        </Radio>
                        <Radio key={"ToBeReleasedItems"} value={"ToBeReleasedItems"}>
                          {"To be released items"}
                        </Radio>
                        <Radio key={"ProjectItems"} value={"ProjectItems"}>
                          {"Project items"}
                        </Radio>
                        <Radio key={"Requests"} value={"Requests"}>
                          {"Requests"}
                        </Radio>
                        <Radio key={"OtherItems"} value={"OtherItems"}>
                          {"Other items"}
                        </Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                </div>
              }
            />

            <DraggableModal
              open={this.state.modalStatusShow}
              okButtonProps={{ hidden: true }}
              onCancel={() => this.setState({ modalStatusShow: false })}
              width={700}
              centered
              content={
                <>
                  <Badge.Ribbon
                    text={<FormattedMessage id="InventoryLog.Status" defaultMessage="Status" />}
                    placement="start"
                    color="purple"
                    style={{ fontSize: 12 }}
                  >
                    <div className="card card-custom" style={{ padding: "0.1rem", minHeight: "100%" }}>
                      <div className="card-body pt-4 custom-timeline pr-5 pl-5 mr-5 ml-5 align-content-center">
                        <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                        <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                        <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                        <Steps direction="vertical" className="pl-5-custom anticon-custom">
                          {Boolean(this.state.statusList) &&
                            this.state.statusList.length > 0 &&
                            this.state.statusList.map((item, index) => {
                              return item.spin ? (
                                <Step status="process" title={item.status + (item.count >= 0 ? " - " + item.count : "")} icon={<LoadingOutlined />} />
                              ) : (
                                <Step
                                  title={item.status + (item.count >= 0 ? " - " + item.count : "")}
                                  status={
                                    item.color === "green"
                                      ? "finish"
                                      : item.color === "blue"
                                        ? "process"
                                        : item.status === "Evaluation"
                                          ? "wait"
                                          : item.status === "Selection of vendor(s)"
                                            ? "wait"
                                            : item.status === "P.O. Creation"
                                              ? "wait"
                                              : "wait"
                                  }
                                  description={item.color === "green" || item.color === "blue" ? moment(item.date).format("DD-MM-YYYY HH:mm:ss") : ""}
                                />
                              );
                            })}
                        </Steps>
                      </div>
                    </div>
                  </Badge.Ribbon>
                </>
              }
            ></DraggableModal>

            <Drawer
              title={<FormattedMessage id="InventoryLog.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
              width={1200}
              closable={false}
              onClose={() => {
                this.setState({ drawerSettingsShow: false });
              }}
              open={this.state.drawerSettingsShow}
            >
              <PageSettings pageName={"Inventory_Log"} />
            </Drawer>

            <DraggableModal
              title={<FormattedMessage id="GeneralPDFReportViewer" defaultMessage="PDF Report Viewer" />}
              open={this.state.modalPdfViewer}
              okButtonProps={{ hidden: true }}
              onCancel={() => this.setState({ modalPdfViewer: false })}
              width={1200}
              centered
              content={
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                  <div style={{ marginTop: "2rem" }}>
                    <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                  </div>
                </Worker>
              }
            ></DraggableModal>

            <DraggableModal
              title={<FormattedMessage id="InventoryLog.InventoryLog" defaultMessage="Inventory Log" />}
              open={this.state.modalPdfSearchShow}
              onCancel={() => this.setState({ modalPdfSearchShow: false })}
              okButtonProps={{ hidden: true }}
              width={700}
              centered
              footer={[
                <Space>
                  <Spin spinning={this.state.loadingButtonSubmit}>
                    <Button
                      variant="primary"
                      onClick={() => {
                        this.formRefPdfFilter.current.submit();
                      }}
                    >
                      <FormattedMessage id="GeneralButtonReport" defaultMessage="Report" />
                    </Button>
                  </Spin>
                </Space>
              ]}
              content={
                <Form initialValues={{ remember: false }} onFinish={this.getReportList} onFinishFailed={onFinishFailed} ref={this.formRefPdfFilter}>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="InventoryLog.Stock" defaultMessage="Stock" />}
                      name="stockId"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Cascader
                        changeOnSelect
                        allowClear
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        options={this.state.nodes}
                      />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="InventoryLog.ItemGroup" defaultMessage="Item Group" />}
                      name="categoryId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["categoryIdSelectItems"]) &&
                          this.state["categoryIdSelectItems"].length > 0 &&
                          this.state["categoryIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="InventoryLog.Project" defaultMessage="Project" />}
                      name="projectId"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["projectIdSelectItems"]) &&
                          this.state["projectIdSelectItems"].length > 0 &&
                          this.state["projectIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                </Form>
              }
            />

            <DraggableModal
              title={<FormattedMessage id="InventoryLog.StockMovements" defaultMessage="Stock Movements" />}
              open={this.state.modalPdfSearchStockMovementShow}
              onCancel={() => this.setState({ modalPdfSearchStockMovementShow: false })}
              okButtonProps={{ hidden: true }}
              width={700}
              centered
              footer={[
                <Space>
                  <Spin spinning={this.state.loadingButtonSubmit}>
                    <Button
                      variant="primary"
                      onClick={() => {
                        this.formRefStockMovementsPdfFilter.current.submit();
                      }}
                    >
                      <FormattedMessage id="GeneralButtonReport" defaultMessage="Report" />
                    </Button>
                  </Spin>
                </Space>
              ]}
              content={
                <Form
                  initialValues={{ remember: false }}
                  onFinish={this.getReportListStockMovements}
                  onFinishFailed={onFinishFailed}
                  ref={this.formRefStockMovementsPdfFilter}
                >
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="InventoryLog.Stock" defaultMessage="Stock" />}
                      name="stockId"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Cascader
                        changeOnSelect
                        allowClear
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        options={this.state.nodes}
                      />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="InventoryLog.Date" defaultMessage="Date" />}
                      name="date"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <DatePicker showTime format="DD-MM-YYYY HH:mm" />
                    </Form.Item>
                  }
                </Form>
              }
            />

            <DraggableModal
              title={<FormattedMessage id="GeneralPDFReportViewer" defaultMessage="PDF Report Viewer" />}
              open={this.state.modalPdfViewerConditions}
              okButtonProps={{ hidden: true }}
              onCancel={() => this.setState({ modalPdfViewerConditions: false })}
              width={400}
              centered
              content={
                <>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    <Checkbox
                      style={{ marginLeft: "20%" }}
                      onChange={() => {
                        this.setState(
                          {
                            modalPdfSearchShow: true,
                            modalPdfViewerConditions: false
                          },
                          () => {
                            this.formRefPdfFilter.current.resetFields();
                          }
                        );
                      }}
                    >
                      <FormattedMessage id="InventoryLog.InventoryReport" defaultMessage="Inventory Report" />
                    </Checkbox>
                    <Checkbox
                      style={{ marginLeft: "20%" }}
                      onChange={() => {
                        this.setState(
                          {
                            modalPdfSearchStockMovementShow: true,
                            modalPdfViewerConditions: false
                          },
                          () => {
                            this.formRefStockMovementsPdfFilter.current.resetFields();
                          }
                        );
                      }}
                    >
                      <FormattedMessage id="InventoryLog.StockMovement" defaultMessage="Stock Movement" />
                    </Checkbox>
                  </div>
                </>
              }
            />

            <DraggableModal
              title={<FormattedMessage id="InventoryLog.ReceiveSelect" defaultMessage="Receive Select" />}
              open={this.state.showReceiveSelectModal}
              onOk={() => {
                if (this.state.receivingType === "Purchasing") {
                  this.setState({ showReceivingModal: true });
                } else if (this.state.receivingType === "Transfer") {
                  this.setState({ TransferReceiveModal: true });
                } else if (this.state.receivingType === "Consigment") {
                  this.setState({ ConsigmentReceiveModal: true });
                } else if (this.state.receivingType === "Emergency") {
                  this.setState({ EmergencyReceiveModal: true });
                }
                this.setState({ showReceiveSelectModal: false });
              }}
              onCancel={() => this.setState({ showReceiveSelectModal: false })}
              width={400}
              centered
              content={
                <>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    <Checkbox
                      style={{ marginLeft: "20%" }}
                      checked={this.state.receivingType === "Purchasing"}
                      onChange={() => this.receivingTypeCheckboxChange("Purchasing")}
                    >
                      <FormattedMessage id="InventoryLog.Purchasing" defaultMessage="Purchasing" />
                    </Checkbox>
                    <Checkbox
                      style={{ marginLeft: "20%" }}
                      checked={this.state.receivingType === "Transfer"}
                      onChange={() => this.receivingTypeCheckboxChange("Transfer")}
                    >
                      <FormattedMessage id="InventoryLog.TransferToOtherStock" defaultMessage="Transfer to other stock" />
                    </Checkbox>
                    <Checkbox
                      style={{ marginLeft: "20%" }}
                      checked={this.state.receivingType === "Consigment"}
                      onChange={() => this.receivingTypeCheckboxChange("Consigment")}
                    >
                      <FormattedMessage id="InventoryLog.Consignment" defaultMessage="Consignment" />
                    </Checkbox>
                    <Checkbox
                      style={{ marginLeft: "20%" }}
                      checked={this.state.receivingType === "Emergency"}
                      onChange={() => this.receivingTypeCheckboxChange("Emergency")}
                    >
                      <FormattedMessage id="InventoryLog.EmergencyReceiving" defaultMessage="Emergency Receiving" />
                    </Checkbox>
                  </div>
                </>
              }
            />

            <DraggableModal
              title={<FormattedMessage id="InventoryLog.ReceiveSelect" defaultMessage="Movement" />}
              open={this.state.showStockmovementsModal}
              okButtonProps={{ hidden: true }}
              onCancel={() => this.setState({ showStockmovementsModal: false })}
              width={1200}
              centered
              content={this.renderStockMovements()}
            />
          </div>
        </Badge.Ribbon >
      </>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryLog);
