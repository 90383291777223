import { QuestionCircleOutlined } from "@ant-design/icons";
import { Form, Menu, Select } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { handleRequest } from "../ApiConnector";
import { error } from "../MessageHelper";
import PrepareFormItemProps from "./ElementUtils";

const { Option } = Select;
const SelectMultiComponent = ({ field, layout }) => {
  const [list, setList] = useState(null);
  const menu = (
    <Menu>
      <Menu.Item key="1">
        <QuestionCircleOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />
        <FormattedMessage id={field.whatIsLanguageKey} defaultMessage={field.whatIsDescription} />
      </Menu.Item>
      {/* <Menu.Item key="2">2nd menu item</Menu.Item>
      <Menu.Item key="3">3rd menu item</Menu.Item> */}
    </Menu>
  );

  const fillList = async () => {
    let url = field.loadUrl; //"/api/countries/selectItems";
    if (!Boolean(url)) return;
    var response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else setList(Boolean(response.data) ? response.data : []);
  };
  useEffect(() => {
    fillList();
  }, []);

  return (
    <>
      <Form.Item {...layout} {...PrepareFormItemProps(field)}>
        <Select showSearch allowClear mode="multiple" optionFilterProp="children" placeholder={field.label}>
          {list
            ? list.map(i => (
                <Option value={Boolean(field.propKey) ? i[field.propKey] : i.key} key={Boolean(field.propKey) ? i[field.propKey] : i.key}>
                  {Boolean(field.propValue) ? i[field.propValue] : i.value}
                </Option>
              ))
            : null}
        </Select>
      </Form.Item>
    </>
  );
};

export default SelectMultiComponent;
