import React, { useState, useEffect } from "react";
import { handleRequest } from "../../ApiConnector";
import { error } from "../../MessageHelper";
import { Table, Col, Select, Row, Typography, Button, Modal as AntModal } from "antd";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { SettingFilled } from "@ant-design/icons";

import { StockCostSetting } from "./StockCostSetting";

const { Text } = Typography;
const { Option } = Select;
const StockCosts = () => {
  const [tableList, setTableList] = useState([]);
  const [detailList, setDetailList] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(6, "M"));
  const [endDate, setEndDate] = useState(moment());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    showSizeChanger: true,
    pageSizeOptions: ["2", "5", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
    total: 0
  });
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    asyncFetch();
  }, [startDate, endDate]);

  const asyncFetch = async () => {
    const search = {
      firstResult: pagination.current - 1,
      maxResults: pagination.pageSize
    };
    console.log(search);
    var response = await handleRequest("POST", "/api/dashboard/stockCosts", search);
    if (response.type === "ERROR") {
      error(response);
    } else {
      setTableList(Boolean(response.data.data) ? response.data.data : null);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  const restartTable = async (params = {}) => {
    const search = {
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize
    };
    console.log(search);
    var response = await handleRequest("POST", "/api/dashboard/stockCosts", search);
    if (response.type === "ERROR") {
      error(response);
    } else {
      setTableList(Boolean(response.data.data) ? response.data.data : null);
      setPagination({
        ...params.pagination,
        total: response.data.totalRecords
      });
    }
  };

  const columns = [
    {
      title: <FormattedMessage id="StockCosts.Category" defaultMessage="Category" />,
      key: "categoryName",
      render: record => {
        return record.categoryName;
      },
      align: "center"
    },
    {
      title: <FormattedMessage id="StockCosts.Quantity" defaultMessage="Quantity" />,
      key: "quantity",
      render: record => {
        return record.quantity;
      },
      align: "center"
    },

    {
      title: <FormattedMessage id="StockCosts.Unit" defaultMessage="Unit" />,
      key: "unit",
      render: record => {
        return record.unit;
      },
      align: "center"
    },
    {
      title: <FormattedMessage id="StockCosts.Cost" defaultMessage="Cost" />,
      key: "costPrice",
      render: record => {
        return Boolean(record.costPrice) && Boolean(record.quantity) ? record.costPrice * record.quantity : 0;
      },
      align: "center"
    }
  ];

  return (
    <>
      <div>
        <Row gutter={[16, 16]}>
          <Col lg={4}></Col>
          <Col lg={10}></Col>
          <Col lg={8}>
            <Select
              style={{}}
              placeholder="Stock Costs"
              defaultActiveFirstOption
              allowClear
              className="w-100"
              onChange={value => {
                restartTable({ pagination }, value);
              }}
            >
              <Option key={"dashboard"} value={"dashboard"}>
                <FormattedMessage id="StockCosts.Euro" defaultMessage="EURO" />
              </Option>
              <Option key={"circle"} value={"circle"}>
                <FormattedMessage id="StockCosts.Dolar" defaultMessage="DOLAR" />
              </Option>
              <Option key={"table"} value={"table"}>
                <FormattedMessage id="StockCosts.Sterlin" defaultMessage="Sterlin" />
              </Option>
              <Option key={"pie"} value={"pie"}>
                <FormattedMessage id="StockCosts.SaudiArabianRiyal" defaultMessage="Saudi Arabian Riyal" />
              </Option>
            </Select>
          </Col>
          <Col lg={1}>
            <SettingFilled type="primary" onClick={showModal} />
          </Col>
        </Row>
        <br></br>
        <Row gutter={[16, 16]}>
          <Col lg={24}>
            <Table
              bordered
              columns={columns}
              dataSource={tableList}
              onChange={handleTableChange}
              pagination={pagination}
              summary={pageData => {
                let totalQuantity = 0;
                let totalCost = 0;

                tableList.forEach(({ quantity, costPrice }) => {
                  totalQuantity += quantity;
                  totalCost += quantity * costPrice;
                });

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell type="success">Total</Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text type="success">{totalCost}</Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Col>
        </Row>
        <AntModal
          centered
          width={1200}
          title={<FormattedMessage id="StockCosts.stockCostSetting" defaultMessage="StockCost Setting" />}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="cancel" variant="secondary" onClick={handleCancel}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
          ]}
        >
          <StockCostSetting />
        </AntModal>
      </div>
    </>
  );
};

export default StockCosts;
