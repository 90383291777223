import React, { Component } from "react";
import { connect } from "react-redux";
import { API_BASE_ROOT, handleRequest } from "../../../ApiConnector";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import {
  Form,
  Table,
  Input,
  Select,
  Tooltip,
  Col,
  Row,
  Button as AntButton,
  Badge,
  Progress,
  DatePicker,
  Card,
  Timeline,
  Tree,
  Divider,
  InputNumber,
  Space,
  Upload,
  Spin
} from "antd";
import moment from "moment";
import Viewer, { defaultLayout, Worker } from "@phuocng/react-pdf-viewer";
import {
  FilterOutlined,
  CloseCircleOutlined,
  NotificationOutlined,
  PlusOutlined,
  DollarOutlined,
  LineChartOutlined,
  ToolOutlined,
  FileOutlined,
  ProfileOutlined,
  SlidersOutlined,
  BarChartOutlined
} from "@ant-design/icons";
import "antd/dist/antd.css";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { FcFolder, FcFile, FcEditImage } from "react-icons/fc";
import { FaFileAudio, FaRegFile } from "react-icons/fa";
import CreateIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { error, showError, showSuccess, showWarning } from "../../../MessageHelper";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import DraggableModal from "../../../Components/DraggableModal";
import CompanyProjectAssign from "./CompanyProjectAssign";
import CompanyProjectProgress from "./CompanyProjectProgress";
import CompanyProjectBudget from "./CompanyProjectBudget";
import { Resizable } from "react-resizable";
import PresentationContent from "../presentation/PresentationContent";
import SummaryContent from "../summary/SummaryContent";
import { Menu, Item, useContextMenu } from "react-contexify";
import { UploadOutlined } from "@ant-design/icons";
import PresentationContentTable from "../presentation/PresentationContentTable";
import SummaryContentTable from "../summary/SummaryContentTable";
import "../../../css/button-style.css";
import ExcelReader from "../../excel/ExcelReader";
import { excelExport } from "../../../ExcelHelper";
import CompanyProjectStatus from "./CompanyProjectStatus";
import ProjectProgressScoring from "./ProjectProgressScoring";

const Menu_Details = "Menu_Details";

const { show } = useContextMenu();

const { Option } = Select;

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

//# Columns-Resize
const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class ProjectLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ProjectLog",
      controllerName: "companyProject",
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      historyList: [],
      id: null,
      tableList: [],
      tableListPresentation: [],
      tableListSummary: [],
      selectedOptions: [],
      countySelectItems: [],
      clientSelectItems: [],
      projectsTreeIds: [],
      modalHistoryShow: false,
      modalRemarksShow: false,
      activeTabKey: "projectInfo",
      nodes: [],
      nodesProjectIdentifications: [],
      spinLoading: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"]
      },
      loading: false,
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  formRefContractor = React.createRef();
  formRefContractUpdates = React.createRef();
  formRefArea = React.createRef();
  formRefProgress = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.searchistanbulCountyId("");
    this.searchcountyId("");
    this.fillClients();
    this.fillTree();
    this.fillCode("ProjectLog.contractorId");

    var responsePreparedById = await handleRequest("GET", "/api/" + this.state.controllerName + "/preparedById");
    if (responsePreparedById.type === "ERROR") {
      error(responsePreparedById);
    }

    var responseCurrency = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responseCurrency.type === "ERROR") {
      error(responseCurrency);
    }

    this.setState({
      preparedByIdSelectItems: Boolean(responsePreparedById.data) ? responsePreparedById.data : [],
      currencySelectItems: Boolean(responseCurrency.data) ? responseCurrency.data : []
    });
  };

  searchistanbulCountyId = async item => {
    this.setState({ loadingSearch: true });
    var response = await handleRequest("GET", `/api/${this.state.controllerName}/istanbulCountyId/${Boolean(item) && item.length > 0 ? item : "''"}`);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        istanbulCountyIdSelectItems: [],
        loadingSearch: false
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          istanbulCountyIdSelectItems: response.data,
          loadingSearch: false
        });
      } else {
        this.setState({
          istanbulCountyIdSelectItems: [],
          loadingSearch: false
        });
      }
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
      startDate: Boolean(row.startDate) ? moment(row.startDate) : null,
      endDate: Boolean(row.endDate) ? moment(row.endDate) : null,
      contractSignDate: Boolean(row.contractSignDate) ? moment(row.contractSignDate) : null,
      landDeliveryDate: Boolean(row.landDeliveryDate) ? moment(row.landDeliveryDate) : null,
      kickOffDate: Boolean(row.kickOffDate) ? moment(row.kickOffDate) : null,
      projectStartDate: Boolean(row.projectStartDate) ? moment(row.projectStartDate) : null,
      contractEndDate: Boolean(row.contractEndDate) ? moment(row.contractEndDate) : null,
      extendedProjectEndDate: Boolean(row.extendedProjectEndDate) ? moment(row.extendedProjectEndDate) : null,
      actualProjectEndDate: Boolean(row.actualProjectEndDate) ? moment(row.actualProjectEndDate) : null
    });
    this.searchistanbulCountyId(row.istanbulCountyName);
    this.searchcountyId(row.constructorCountyName);
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      checkedKeys: { checked: row.projectsTreeIds }
    });
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let contractUpdateAction = {
      name: "Contract Updates",
      icon: <NotificationOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.contractUpdateActionModal.bind(this)
    };
    let contractorAction = {
      name: "Contractor",
      icon: <FileOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.contractorActionModal.bind(this)
    };
    let assignAction = {
      name: "Assign",
      icon: <ToolOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.assignActionModal.bind(this)
    };
    let progressAction = {
      name: "Progress",
      icon: <LineChartOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.projectIdentificationModal.bind(this)
    };
    let budgetAction = {
      name: "Budget",
      icon: <DollarOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.budgetActionModal.bind(this)
    };
    let paymentAction = {
      name: "Payment",
      icon: <DollarOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.paymentActionModal.bind(this)
    };
    let remarksAction = {
      name: "Remarks",
      icon: <DollarOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.remarksActionModal.bind(this)
    };
    let presentationAction = {
      name: "Presentation",
      icon: <SlidersOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.presentationActionModal.bind(this)
    };
    let summaryAction = {
      name: "Summary",
      icon: <ProfileOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.summaryActionModal.bind(this)
    };
    let statusAction = {
      name: "Status",
      icon: <ProfileOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.statusActionModal.bind(this)
    };
    let progressScoringAction = {
      name: "Progress Scoring",
      icon: <BarChartOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.progressScoringActionModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(contractUpdateAction);
    actionList.push(contractorAction);
    actionList.push(assignAction);
    actionList.push(progressAction);
    actionList.push(budgetAction);
    actionList.push(paymentAction);
    actionList.push(remarksAction);
    actionList.push(presentationAction);
    actionList.push(summaryAction);
    actionList.push(statusAction);
    actionList.push(progressScoringAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  contractUpdateActionModal = row => {
    this.setState(
      {
        modalContractUpdateAction: true,
        id: row.id
      },
      () => {
        this.formRefContractUpdates.current.setFieldsValue({
          ...row,
          contractSignDate: Boolean(row.contractSignDate) ? moment(row.contractSignDate) : null,
          landDeliveryDate: Boolean(row.landDeliveryDate) ? moment(row.landDeliveryDate) : null,
          kickOffDate: Boolean(row.kickOffDate) ? moment(row.kickOffDate) : null,
          projectStartDate: Boolean(row.projectStartDate) ? moment(row.projectStartDate) : null,
          contractEndDate: Boolean(row.contractEndDate) ? moment(row.contractEndDate) : null,
          extendedProjectEndDate: Boolean(row.extendedProjectEndDate) ? moment(row.extendedProjectEndDate) : null,
          actualProjectEndDate: Boolean(row.actualProjectEndDate) ? moment(row.actualProjectEndDate) : null
        });
      }
    );
  };

  contractorActionModal = row => {
    this.setState(
      {
        modalContractorAction: true,
        id: row.id
      },
      () => {
        this.formRefContractor.current.setFieldsValue({
          ...row
        });
        this.searchcountyId(row.constructorCountyName);
      }
    );
  };

  assignActionModal = row => {
    this.setState({
      modalAssignAction: true,
      id: row.id,
      random: Math.random()
    });
  };

  presentationTableModal = row => {
    this.setState({
      modalPresentationTable: true,
      id: row.id,
      random: Math.random()
    });
  };

  summaryTableModal = row => {
    this.setState({
      modalSummaryTable: true,
      id: row.id,
      random: Math.random()
    });
  };

  progressActionModal = row => {
    this.setState({
      modalProgressAction: true,
      id: row.id,
      random: Math.random()
    });
  };

  projectIdentificationModal = row => {
    this.setState(
      {
        modalProjectIdentificationAction: true,
        selectedIdentification: row,
        random: Math.random(),
        id: row.id,
        nodes: [],
        nodesProjectIdentifications: [],
        refreshTree: true,
        checkedKeys: null,
        checkedKeysProjectIdentification: null,
        halfCheckedKeys: null,
        checkedNodes: null,

        autoExpandParent: false,
        expandedKeys: null,
        expandedKeysProjectIdentificationAction: null
      },
      () => {
        this.fillTree();
      }
    );
  };

  budgetActionModal = row => {
    this.setState({
      modalBudgetAction: true,
      id: row.id,
      random: Math.random()
    });
  };

  statusActionModal = row => {
    this.setState({
      modalStatusAction: true,
      id: row.id,
      random: Math.random()
    });
  };

  progressScoringActionModal = row => {
    this.setState({
      modalProgressScoringAction: true,
      id: row.id,
      random: Math.random()
    });
  };

  paymentActionModal = row => {
    this.setState({
      modalPaymentAction: true,
      id: row.id,
      random: Math.random()
    });
  };

  remarksActionModal = row => {
    this.setState({
      modalRemarksAction: true,
      id: row.id,
      random: Math.random()
    });
  };

  presentationActionModal = row => {
    this.setState({
      modalPresentationAction: true,
      id: row.id,
      random: Math.random()
    });
  };

  summaryActionModal = row => {
    this.setState({
      modalSummaryAction: true,
      id: row.id,
      random: Math.random()
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.sn = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;
    var current = this.state.pagination.current;
    var pageSize = this.state.pagination.pageSize;
    var sortField = "",
      sortOrder = "";

    if (Boolean(params) && Boolean(params.pagination)) {
      current = params.pagination.current;
      pageSize = params.pagination.pageSize;

      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      firstResult: current - 1,
      maxResults: pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  save = async values => {
    const { activeTabKey } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      projectsTreeIds:
        Boolean(this.state.checkedKeys) && Boolean(this.state.checkedKeys.checked) && this.state.checkedKeys.checked.length > 0
          ? this.state.checkedKeys.checked
          : []
    };
    console.log("newItem", newItem);
    if (!Boolean(activeTabKey)) {
      showWarning("Tab not selected.");
      return;
    }

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      if (activeTabKey === "projectInfo") {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateProjectInfo/" + newItem.id, newItem);
      } else if (activeTabKey === "contractInfo") {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateContractInfo/" + newItem.id, newItem);
      } else if (activeTabKey === "contractor") {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateContractor/" + newItem.id, newItem);
      }
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  saveContractor = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };
    console.log("newItem", newItem);

    let response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateContractor/" + newItem.id, newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        modalContractorAction: false
      });
    }
  };

  saveContractUpdates = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };
    console.log("newItem", newItem);

    let response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateContractInfo/" + newItem.id, newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        modalContractUpdateAction: false
      });
    }
  };

  saveProjectStatus = async values => {
    const { id } = this.state;
    if (Boolean(id) && id > 0) {
      var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/saveProjectStatus/" + id + "/" + values.completed);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.setState({
          modalProjectStatusAction: false
        });
      }
    } else {
      showWarning("Company project not found.");
    }
  };

  addClient = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: "ProjectLogClientId"
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: ""
        });
        this.fillClients();
      }
    } else {
      showError("Please enter code");
    }
  };

  fillClients = async () => {
    const response = await handleRequest("GET", "/api/codes/type/ProjectLogClientId");
    if (Boolean(response.data)) {
      this.setState({
        clientSelectItems: response.data
      });
    }
  };

  onClickCreateNew = async () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  onExpandProjectIdentifications = expandedKeys => {
    this.setState({
      expandedKeysProjectIdentificationAction: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadDataProjectIdentifications = async loadedKeys => {
    this.setState({
      loadedKeysProjectIdentifications: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys, modalProjectIdentificationAction } = this.state;
    if (modalProjectIdentificationAction) {
      return;
    }

    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "ProjectName"
    };

    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitems/" + obj.id);
    }
    if (response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.icon =
          Boolean(element.type) && element.type === "ProjectName" ? (
            <FcFile />
          ) : element.type === "Project" ? (
            <FaRegFile />
          ) : element.type === "ProjectChild" ? (
            <FaFileAudio />
          ) : (
            <FcFolder />
          );

        if (Boolean(element.area) && element.area > 0) {
          element.title = element.projectName + " / " + element.area + " m2";
        } else {
          element.title = element.projectName;
        }
        element.key = element.id;
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      this.setState({
        nodes: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  loadDataProjectIdentification = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeysProjectIdentificationAction, id } = this.state;
    var nodesProjectIdentifications = this.state.nodesProjectIdentifications;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "ProjectName"
    };

    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsProjectIdentification/" + obj.id + "/" + id);
    }
    if (response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;
      console.log("data", data);

      data.forEach(element => {
        element.icon =
          Boolean(element.type) && element.type === "ProjectName" ? (
            <FcFile />
          ) : element.type === "Project" ? (
            <FaRegFile />
          ) : element.type === "ProjectChild" ? (
            <FaFileAudio />
          ) : (
            <FcFolder />
          );

        element.title = element.projectName;
        if (element.area > 0) {
          element.title += ` / ${element.area} m2`;
        }
        if (element.physicalProgress > 0 && element.area > 0) {
          element.title = element.projectName + ` / ${element.physicalProgress}-${element.area} m2`;
        }
        element.key = element.id;
      });

      var res = updateTreeData(nodesProjectIdentifications, treenode.key, data, expandedKeysProjectIdentificationAction);
      this.setState({
        nodesProjectIdentifications: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  onCheck = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    this.setState({
      checkedKeys: checkedKeys,
      halfCheckedKeys: e.halfCheckedKeys,
      checkedNodes: e.checkedNodes
    });
  };

  onCheckProjectIdentification = async (checkedKeys, e) => {
    console.log("checkedKeysProjectIdentification", checkedKeys);
    let projectsIdentificationsId = [];
    if (Boolean(checkedKeys) && Boolean(checkedKeys.checked)) {
      projectsIdentificationsId = checkedKeys.checked[checkedKeys.checked.length - 1];
    }
    this.setState(
      {
        checkedKeysProjectIdentification: checkedKeys,
        halfCheckedKeys: e.halfCheckedKeys,
        checkedNodes: e.checkedNodes,
        projectsIdentificationsId: projectsIdentificationsId
      },
      () => {
        if (Boolean(projectsIdentificationsId)) {
          this.progressActionModal(this.state.selectedIdentification);
        }
      }
    );
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "ProjectName";
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          nodesProjectIdentifications: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  onTab2Change = key => {
    this.setState({ activeTabKey: key });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  onRightClick = (event, node) => {
    let parentNode = this.getParentNode(node);
    this.setState(
      {
        selectedNodeKey: node.key,
        selectedNode: node,
        parentNode: parentNode
      },
      this.displayMenu(event, Boolean(node) && Boolean(node.type) ? node.type : null)
    );
  };

  displayMenu = (e, type) => {
    console.log("TTT", type);
    show(e, { id: Menu_Details });
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  handleItemClick = ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;

    console.log("AAA", event.currentTarget.id);
    console.log(selectedNode);
    console.log(parentNode);
    console.log(key);

    switch (event.currentTarget.id) {
      case "update_area":
        this.setState(
          {
            showAreaModal: true,
            id: selectedNode.id
          },
          () => {
            this.formRefArea.current.setFieldsValue({
              area: selectedNode.area
            });
          }
        );
        break;
      default:
    }
  };

  updateArea = async values => {
    const { id } = this.state;
    if (Boolean(id) && id > 0) {
      var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateArea/" + id + "/" + values.area);
      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess();
        this.setState({
          showAreaModal: false
        });
      }
    } else {
      showWarning("Company project not found.");
    }
  };

  getStatus = async row => {
    let url = "/api/" + this.state.controllerName + "/getStatus/" + row.id;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        statusList: response.data,
        modalStatusShow: true
      });
    }
  };

  getHistory = async row => {
    let url = "/api/" + this.state.controllerName + "/getHistory/" + row.id;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        historyList: response.data,
        modalHistoryShow: true
      });
    }
  };

  /*getRemarks = async row => {
    let url = "/api/" + this.state.controllerName + "/getRemarks/" + row.id;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        remarksList: response.data,
        modalRemarksShow: true
      });
    }
  };
  */

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //responsibleDepartment: element["Responsible Department"],
            //responsiblePerson: element["Responsible Person"],
            contractorName: element["Contractor"],
            tenderingName: element["Tendering Name"],
            projectFullName: element["Project Name"],
            ownership: element["Ownership"],
            istanbulCountyName: element["County"],
            // status: element["Status"],
            //remarks: element["Remarks"],
            physicalProgress: element["Progress"],
            contractSignDate: element["Contract Sign Date"],
            projectStartDate: element["Project Start Date"],
            contractEndDate: element["Contract End Date"],
            extendedProjectEndDate: element["Extended Project End Date"],
            estimatedProjectEndDate: element["Estimated Project End Date"],
            actualProjectEndDate: element["Actual Project End Date"],
            contractBudget: element["Contract Budget"],
            correctedBudget: element["Corrected Budget"]
            /* budgetIncreament: element["Budget Increament"]
            /*budgetIncreamentPercent: element["Budget Increament %"],           
            progressPayment: element["Progress Payment"],
            progressPaymentPercent: element["Progress Payment %"],
            constructionCoordinator: element["Construction Coordinator"],
            constructionEngineer: element["Construction Engineer"],
            architect: element["Architect"],
            electricalEngineer: element["Electrical Engineer"],
            mechanicalEngineer: element["Mechanical Engineer"],
            landscapeArchitect: element["Landscape Architect"],
            surveyorEngineer: element["Surveyor Engineer"]

            contractNo: element["Contract Info"],*/
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.cancel();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  searchcountyId = async item => {
    this.setState({ loadingSearch: true });
    var response = await handleRequest("GET", `/api/${this.state.controllerName}/istanbulCountyId/${Boolean(item) && item.length > 0 ? item : "''"}`);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        countyIdSelectItems: [],
        loadingSearch: false
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          countyIdSelectItems: response.data,
          loadingSearch: false
        });
      } else {
        this.setState({
          countyIdSelectItems: [],
          loadingSearch: false
        });
      }
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.printButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };

    const columns = [
      {
        key: "key",
        render: this.action,
        width: 70
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        },
        width: 70
      },
      {
        title: <FormattedMessage id="ProjectLog.Status" defaultMessage="Status" />,
        key: "status",
        render: record => {
          return (
            Boolean(record.statusName) && (
              <React.Fragment>
                <div
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#3699FF"
                  }}
                  onClick={e => this.statusActionModal(record)}
                >
                  {record.statusName}
                </div>
              </React.Fragment>
            )
          );
        },
        ...getColumnFilter({
          inputName: "status",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        width: 100
      },
      {
        title: <FormattedMessage id="ProjectLog.ResponsibleDepartment" defaultMessage="Responsible Department" />,
        key: "id",
        render: record => {
          return record.responsibleDepartment;
        },
        ...getColumnFilter({
          inputName: "responsibleDepartment",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        width: 100
      },
      {
        title: <FormattedMessage id="ProjectLog.ResponsiblePerson" defaultMessage="Responsible Person" />,
        key: "id",
        render: record => {
          return record.responsiblePerson;
        },
        ...getColumnFilter({
          inputName: "responsiblePerson",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        width: 100
      },
      {
        title: <FormattedMessage id="ProjectLog.TenderingName" defaultMessage="Tendering Name" />,
        key: "tenderingName",
        render: record => (
          <Tooltip title={record.tenderingName}>
            <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{record.tenderingName}</div>
          </Tooltip>
        ),
        ...getColumnFilter({
          inputName: "tenderingName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        width: 100
      },
      {
        title: <FormattedMessage id="ProjectLog.ProjectName" defaultMessage="Project Name" />,
        key: "projectFullName",
        render: record => (
          <Tooltip title={record.projectFullName}>
            <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{record.projectFullName}</div>
          </Tooltip>
        ),
        ...getColumnFilter({
          inputName: "projectFullName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        width: 100
      },
      {
        title: <FormattedMessage id="ProjectLog.ProjectType" defaultMessage="Project Type" />,
        key: "projectType",
        render: record => {
          return (
            <Tooltip title={record.projectsTreeNames.join(" - ")}>
              <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{record.projectsTreeNames.join(" - ")}</div>
            </Tooltip>
          );
        },
        width: 100
      },
      {
        title: <FormattedMessage id="ProjectLog.Address" defaultMessage="Address" />,
        key: "address",
        render: record => {
          let address = [];
          if (record.contractorAddress) {
            address.push(record.contractorAddress);
          }
          if (record.constructorCityName) {
            address.push(record.constructorCityName);
          }
          if (record.constructorCountyName) {
            address.push(record.constructorCountyName);
          }
          return (
            <Tooltip title={address.join(" / ")}>
              <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{address.join(" / ")}</div>
            </Tooltip>
          );
        },
        width: 100
      },
      {
        title: <FormattedMessage id="ProjectLog.Progress" defaultMessage="Progress" />,
        key: "progress",
        width: 120,

        render: record => {
          return (
            <Progress
              type="circle"
              percent={record.percentProgress}
              success={{ percent: record.physicalProgress }}
              format={() => `${record.percentProgress}%`}
              width={50}
            />
          );
        }
      },
      /*render: (text, record) =>
        Boolean(record.percentProgress) &&
        Boolean(record.physicalProgress) && (
          <div
            style={{
              cursor: "pointer"
            }}
            onClick={e => this.projectIdentificationModal(record)}
          >
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column">
                <a href="#" className="text-gray-900 fw-bold text-hover-primary fs-6">
                  <FormattedMessage id="ProjectLog.PhysicalProgress" defaultMessage="Physical Progress" />
                </a>
                <span className="text-muted fw-bold text fs-7">
                  
                  
                  {record.percentProgress} % {" / "}
                  {record.physicalProgress} m²
                </span>
              </div>
            </div>
          </div>
        ), */
      {
        title: <FormattedMessage id="ProjectLog.ContractInfo" defaultMessage="Contract Info" />,
        key: "contractInfo",
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "#3699FF",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
                onClick={e => this.contractUpdateActionModal(record)}
              >
                {record.contractNo}
              </div>
            </React.Fragment>
          );
        },
        width: 130
      },
      {
        title: <FormattedMessage id="ProjectLog.BudgetInfo" defaultMessage="Budget Info" />,
        key: "budgetInfo",
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: "#3699FF",
                  textDecoration: "underline"
                }}
                onClick={e => this.budgetActionModal(record)}
              >
                {record?.contractBudget != null && !isNaN(parseFloat(record.contractBudget))
                  ? (typeof record.contractBudget === "number" ? record.contractBudget : parseFloat(record.contractBudget))
                      .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                      .replace(/,/g, " ")
                  : record.contractBudget}
              </div>
            </React.Fragment>
          );
        },
        width: 150
      },
      {
        title: <FormattedMessage id="ProjectLog.Contractor" defaultMessage="Contractor" />,
        key: "contractorId",
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: "#3699FF",
                  textDecoration: "underline",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
                onClick={e => this.contractorActionModal(record)}
              >
                {record.contractorName}
              </div>
            </React.Fragment>
          );
        },
        width: 120
      },
      {
        title: <FormattedMessage id="ProjectLog.Assignments" defaultMessage="Assignments" />,
        key: "assignments",
        render: record => {
          return (
            <Button type="button" className="custom-assignment-button" onClick={() => this.assignActionModal(record)}>
              <FormattedMessage id="ProjectsLog.Assignment" defaultMessage="Assignment" />
            </Button>
          );
        },
        width: 120
      },
      {
        title: <FormattedMessage id="ProjectLog.Presentation" defaultMessage="Presentation" />,
        key: "presentation",
        render: (text, record) =>
          Boolean(record.percentProgress) &&
          Boolean(record.physicalProgress) && (
            <div
              style={{
                cursor: "pointer"
              }}
              onClick={e => this.presentationTableModal(record)}
            >
              <div className="d-flex align-items-center">
                <div className="d-flex flex-column">
                  <a href="#" className="text-gray-900 fw-bold text-hover-primary fs-6">
                    <FormattedMessage id="ProjectLog.Summary" defaultMessage="Summary" /> |
                    <FormattedMessage id="ProjectLog.Presentation" defaultMessage="Presentation" />|
                    <FormattedMessage id="ProjectLog.Report" defaultMessage="Report" />
                  </a>
                  <span className="text-muted fw-bold text fs-7">
                    {/* text-muted fw-semibold text-muted fs-7*/}
                    {record.percentProgress} % {" / "}
                    {record.physicalProgress} m²
                  </span>
                </div>
              </div>
            </div>
          ),
        width: 150
        /*return (
            <AntButton
              onClick={() => {
                this.presentationTableModal(record);
              }}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<CheckSquareOutlined fontSize="large" />}
            ></AntButton>
          );*/
      },
      {
        title: <FormattedMessage id="ProjectLog.Summary" defaultMessage="Summary" />,
        key: "summary",
        render: (text, record) =>
          Boolean(record.percentProgress) &&
          Boolean(record.physicalProgress) && (
            <div
              style={{
                cursor: "pointer"
              }}
              onClick={e => this.summaryTableModal(record)}
            >
              <div className="d-flex align-items-center">
                <div className="d-flex flex-column">
                  <a href="#" className="text-gray-900 fw-bold text-hover-primary fs-6">
                    <FormattedMessage id="ProjectLog.Summary" defaultMessage="New" /> {"|"}
                    <FormattedMessage id="ProjectLog.Summary" defaultMessage="Total" />
                  </a>
                  <span className="text-muted fw-bold text fs-7">
                    {/* text-muted fw-semibold text-muted fs-7*/}
                    {record.percentProgress} % {" / "}
                    {record.physicalProgress} m²
                  </span>
                </div>
              </div>
            </div>
          ),
        width: 150
        /* return (
            <AntButton
              onClick={() => {
                this.summaryTableModal(record);
              }}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<CheckSquareOutlined fontSize="large" />}
            ></AntButton>
          );*/
      },
      {
        title: <FormattedMessage id="General.LastUpdate" defaultMessage="Last Update" />,
        key: "lastUpdated",
        width: 180,
        render: record => {
          return Boolean(record.statusName) ? (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "#3699FF",
                  display: "flex",
                  alignItems: "center"
                }}
                onClick={e => this.getHistory(record)}
              >
                {moment(record.lastUpdated).format("DD-MM-YYYY HH:mm:ss")}
              </div>
            </React.Fragment>
          ) : (
            moment(record.lastUpdated).format("DD-MM-YYYY HH:mm:ss")
          );
        }
      },
      {
        title: <FormattedMessage id="ProjectsLog.Remarks" defaultMessage="Remarks" />,
        key: "remarksName",
        width: 100,
        render: record => {
          return Boolean(record.statusName) ? (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "#3699FF",
                  display: "flex",
                  alignItems: "center"
                }}
                onClick={e => this.getHistory(record)}
              >
                {moment(record.lastUpdated).format("DD-MM-YYYY HH:mm:ss")}
              </div>
            </React.Fragment>
          ) : (
            moment(record.lastUpdated).format("DD-MM-YYYY HH:mm:ss")
          );
        }
      }
    ];

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: handleResize(index)
            })
          }))
        : Boolean(columns) &&
          columns.length > 0 &&
          columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: handleResize(index)
            })
          }));

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout1 = {
      labelCol: { span: 12 },
      wrapperCol: { span: 8 }
    };

    const { activeTabKey } = this.state;
    const tabList = [
      {
        key: "projectInfo",
        label: "projectInfo",
        tab: "Project Info"
      },
      {
        key: "contractInfo",
        label: "contractInfo",
        tab: "Contract Info"
      },
      {
        key: "contractor",
        label: "contractor",
        tab: "Contractor"
      }
    ];

    const contentList = {
      projectInfo: (
        <>
          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <br></br>
            {/* Sol Card */}
            <Col span={1}> </Col>
            <Col span={11}>
              <Card
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                  height: "100%"
                }}
              >
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ContractLog.tenderingName" defaultMessage="Tendering Name" />}
                    name="tenderingName"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ContractLog.ProjectName" defaultMessage="Project Name" />}
                    name="projectFullName"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ContractLog.projectType" defaultMessage="Project Type" />}
                    name="projectType"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Tree
                      style={{ marginTop: "10px", lineHeight: "30px" }}
                      className="draggable-tree"
                      showLine={{ showLeafIcon: false }}
                      showIcon
                      checkStrictly={true}
                      loadedKeys={this.state.loadedKeys}
                      onLoad={this.onLoadData}
                      loadData={this.loadData}
                      onExpand={this.onExpand}
                      expandedKeys={this.state.expandedKeys}
                      autoExpandParent={false}
                      treeData={this.state.nodes}
                      blockNode={true}
                      checkable
                      onCheck={this.onCheck}
                      checkedKeys={this.state.checkedKeys}
                      halfCheckedKeys={this.state.halfCheckedKeys}
                      onRightClick={({ event, node }) => this.onRightClick(event, node)}
                    />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProjectLog.Ownership" defaultMessage="Ownership" />}
                    name="ownership"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Card>
            </Col>

            {/* Sağ Card */}
            <Col span={11}>
              <Card
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                  height: "100%"
                }}
              >
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProjectLog.cityId" defaultMessage="City" />}
                    name="cityId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select style={{ width: "100%" }} allowClear showSearch optionFilterProp="children">
                      {Boolean(this.state["cityIdSelectItems"]) &&
                        this.state["cityIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProjectLog.county" defaultMessage="County" />}
                    name="istanbulCountyId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      onSearch={this.searchistanbulCountyId}
                      style={{ marginBottom: 0, width: "100%" }}
                      loading={this.state.loadingSearch}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {Boolean(this.state["istanbulCountyIdSelectItems"]) &&
                        this.state["istanbulCountyIdSelectItems"].length > 0 &&
                        this.state["istanbulCountyIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProjectLog.district" defaultMessage="District" />}
                    name="districtId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear showSearch optionFilterProp="children">
                      {this.state.countySelectItems.map(i => (
                        <Option value={i.key}>{i.value}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProjectLog.boulevard" defaultMessage="Boulevard" />}
                    name="boulevardId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear showSearch optionFilterProp="children">
                      {this.state.countySelectItems.map(i => (
                        <Option value={i.key}>{i.value}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProjectLog.street" defaultMessage="Street" />}
                    name="streetId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear showSearch optionFilterProp="children">
                      {this.state.countySelectItems.map(i => (
                        <Option value={i.key}>{i.value}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                }

                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ContractLog.Address" defaultMessage="Address" />}
                    name="address"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ContractLog.Coordinates" defaultMessage="Coordinates" />}
                    name="coordinates"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProjectLog.LocationMap" defaultMessage="Location Map" />}
                    name="locationMap"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Upload
                      {...props}
                      showUploadList={true}
                      fileList={this.state.fileListLocationMap}
                      onChange={({ fileList }) => {
                        if (fileList.length === 1) {
                          if (Boolean(fileList[0].response)) {
                            fileList[0].url = fileList[0].response.url;
                            showSuccess("file uploaded successfully");
                            // fileName: info.file.name,
                          }
                          this.setState({
                            fileListLocationMap: fileList
                          });
                        }
                      }}
                      beforeUpload={fileListLocationMap => {
                        if (this.state.fileListLocationMap.length > 0) {
                          showError("You have already choosed a file! For change firstly remove the uploaded file.");
                          return false;
                        }
                        return true;
                      }}
                      onRemove={({ fileList }) => {
                        this.setState({
                          fileListLocationMap: []
                        });
                      }}
                    >
                      <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                        <UploadOutlined /> Upload
                      </AntButton>
                    </Upload>
                  </Form.Item>
                }
              </Card>
            </Col>
            <Col span={1}></Col>
          </Row>

          <br></br>
        </>
      ),

      contractInfo: (
        <>
          {this.state.id === null && (
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ContractLog.ProjectName" defaultMessage="Project Name" />}
              name="projectFullName"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          )}
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductLog.TenderNo" defaultMessage="Tender No" />}
              name="tenderNo"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductLog.ContractNo" defaultMessage="Contract No" />}
              name="contractNo"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductLog.ContractSignDate" defaultMessage="Contract Sign Date" />}
              name="contractSignDate"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductLog.ContractEndDate" defaultMessage="Contract End Date" />}
              name="contractEndDate"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductLog.LandDeliveryDate" defaultMessage="Land Delivery Date" />}
              name="landDeliveryDate"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductLog.KickOffDate" defaultMessage="Kick-off Date" />}
              name="kickOffDate"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductLog.ProjectStartDate" defaultMessage="Project Start Date" />}
              name="projectStartDate"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductLog.ExtendedProjectEndDate" defaultMessage="Extentended Project End Date" />}
              name="extendedProjectEndDate"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductLog.ActualProjectEndDate" defaultMessage="Actual Project End Date" />}
              name="actualProjectEndDate"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
            </Form.Item>
          }

          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ContractLog.ContractBudget" defaultMessage="Contract Budget" />}
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Space.Compact style={{ width: "100%" }}>
                <Form.Item
                  name="contractBudget"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  style={{ width: "80%" }}
                >
                  <InputNumber style={{ width: "100%" }} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                </Form.Item>

                <Form.Item
                  name="contractBudgetCurrency"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  style={{ width: "100%" }}
                >
                  <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["currencySelectItems"]) &&
                      this.state["currencySelectItems"].length > 0 &&
                      this.state["currencySelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Space.Compact>
            </Form.Item>
          }
        </>
      ),

      contractor: (
        <>
          {this.state.id === null && (
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ContractLog.ProjectName" defaultMessage="Project Name" />}
              name="projectFullName"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          )}
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ContractLog.ContractorName" defaultMessage="Contractor" />}
              name="contractorId"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                style={{ width: "100%" }}
                value={this.state.contractorId}
                onChange={value => {
                  this.formRef.current.setFieldsValue({
                    contractorId: value
                  });
                  this.setState({ contractorId: value });
                }}
                dropdownRender={menu => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                      <Input
                        style={{ flex: "auto" }}
                        value={this.state.codeName}
                        onChange={event => {
                          this.setState({
                            codeName: event.target.value
                          });
                        }}
                      />
                      <AntButton type="link" icon={<PlusOutlined />} onClick={() => this.addCode("ProjectLog.contractorId")}>
                        Add item
                      </AntButton>
                    </div>
                  </div>
                )}
              >
                {Boolean(this.state["ProjectLog.contractorIdSelectItems"]) &&
                  this.state["ProjectLog.contractorIdSelectItems"].length > 0 &&
                  this.state["ProjectLog.contractorIdSelectItems"].map(i => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          }

          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProjectLog.City" defaultMessage="City" />}
              name="constructorCityId"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select style={{ width: "100%" }} allowClear showSearch optionFilterProp="children">
                {Boolean(this.state["cityIdSelectItems"]) &&
                  this.state["cityIdSelectItems"].map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProjectLog.county" defaultMessage="County" />}
              name="constructorCountyId"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select
                allowClear
                showSearch
                onSearch={this.searchcountyId}
                style={{ marginBottom: 0, width: "100%" }}
                loading={this.state.loadingSearch}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {Boolean(this.state["countyIdSelectItems"]) &&
                  this.state["countyIdSelectItems"].length > 0 &&
                  this.state["countyIdSelectItems"].map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          }

          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ContractLog.Address" defaultMessage="Address" />}
              name="contractorAddress"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ContractLog.contactPerson" defaultMessage="Contact Person" />}
              name="contactPerson"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ContractLog.ContactNo" defaultMessage="Contact No" />}
              name="contactNo"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
        </>
      )
    };

    const columnsExcel = [
      //{ header: "Responsible Department", key: "responsibleDepartment" },
      //{ header: "Responsible Person", key: "responsiblePerson" },
      { header: "Contractor", key: "contractorName" },
      { header: "Tendering Name", key: "tenderingName" },
      { header: "Project Name", key: "projectFullName" },
      { header: "Ownership", key: "ownership" },
      { header: "County", key: "istanbulCountyName" },
      //{ header: "Status", key: "statusName" },
      //{ header: "Remarks", key: "remarks" },
      { header: "Progress", key: "physicalProgress" },
      { header: "Contract Sign Date", key: "contractSignDate" },
      { header: "Project Start Date", key: "projectStartDate" },
      { header: "Contract End Date", key: "contractEndDate" },
      { header: "Extended Project End Date", key: "extendedProjectEndDate" },
      { header: "Actual Project End Date", key: "actualProjectEndDate" },
      { header: "Estimated Contract Budget", key: "estimatedContractBudget" },
      { header: "Budget Info", key: "contractBudget" },
      { header: "Corrected Budget", key: "correctedBudget" }

      /*{ header: "Budget Increament", key: "budgetIncreament" },
      { header: "Budget Increament Percent", key: "budgetIncreamentPercent" },
      { header: "Budget of Project", key: "projectOfBudget" },
      { header: "Progress Payment", key: "progressPayment" },
     { header: "Progress PaymentPercent", key: "progressPayment%" },
      { header: "Construction Coordinator", key: "constructionCoordinator" },
      { header: "Construction Engineer", key: "constructionEngineer" },
      { header: "Architect", key: "architect" },
      { header: "Electrical Engineer", key: "electricalEngineer" },
      { header: "Mechanical Engineer", key: "mechanicalEngineer" },
      { header: "Landscape Architect", key: "landscapeArchitect" },
      { header: "Survey Engineer", key: "surveyEngineer" }
      { header: "Contract Info", key: "contractNo" },*/
    ];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="projectLog.pageTitle" defaultMessage="Project Log" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={21}></Col>
              <Col md={1}>
                <Spin spinning={this.state.spinLoading}>
                  {
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => {
                        excelExport(columnsExcel, this.state.tableList);
                      }}
                      icon={<ExportIcon color="action" fontSize="large" />}
                    />
                  }
                </Spin>
              </Col>
              <Col md={1}>
                <Spin spinning={this.state.spinLoading}>
                  <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
                </Spin>
              </Col>
              <Col md={1}>
                <AntButton
                  tooltip={"Create New"}
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>

            <div hidden={this.state.hideInputs}>
              <Card
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                  height: "100%"
                }}
                activeTabKey={this.state.activeTabKey}
                tabBarExtraContent={<a href="#">More</a>}
                tabList={tabList}
                onTabChange={this.onTab2Change}
              >
                {contentList[activeTabKey]}

                <div hidden={this.state.hideSave}>
                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                          <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </div>
                <div hidden={this.state.hideUpdate}>
                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                          <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                        </Button>
                      </Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                          <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </div>
              </Card>
              <br></br>
            </div>
          </Form>

          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              size="small"
              bordered
              components={{
                header: {
                  cell: ResizableTitle
                }
              }}
              scroll={{ y: 500, x: 900 }}
              columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Menu id={Menu_Details}>
            <Item id="update_area" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="ProjectLog.Area" defaultMessage="Details" />
                </span>
              </Space>
            </Item>
          </Menu>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <DraggableModal
          title={<FormattedMessage id="ProjectLog.contractUpdates" defaultMessage="Contract Updates" />}
          width={1200}
          centered
          open={this.state.modalContractUpdateAction}
          onOk={() => this.formRefContractUpdates.current.submit()}
          onCancel={() => this.setState({ modalContractUpdateAction: false })}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveContractUpdates}
              onFinishFailed={onFinishFailed}
              ref={this.formRefContractUpdates}
            >
              <Row gutter={24} style={{ marginBottom: "16px" }}>
                <br></br>
                {/* Sol Card */}
                <Col span={1}> </Col>
                <Col span={11}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    {
                      <Form.Item
                        {...layout1}
                        label={<FormattedMessage id="ProductLog.ContractSignDate" defaultMessage="Contract Sign Date" />}
                        name="contractSignDate"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY" />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout1}
                        label={<FormattedMessage id="ContractLog.ContractEndDate" defaultMessage="Contract End Date" />}
                        name="contractEndDate"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY" />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout1}
                        label={<FormattedMessage id="ProductLog.LandDeliveryDate" defaultMessage="Land Delivery Date" />}
                        name="landDeliveryDate"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY" />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout1}
                        label={<FormattedMessage id="ProductLog.KickOffDate" defaultMessage="Kick-off Date" />}
                        name="kickOffDate"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY" />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout1}
                        label={<FormattedMessage id="ProductLog.ProjectStartDate" defaultMessage="Project Start Date" />}
                        name="projectStartDate"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout1}
                        label={<FormattedMessage id="ProductLog.ExtendedProjectEndDate" defaultMessage="Extended Project End Date" />}
                        name="extendedProjectEndDate"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout1}
                        label={<FormattedMessage id="ProductLog.ActualProjectEndDate" defaultMessage="Actual Project End Date" />}
                        name="actualProjectEndDate"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY" />
                      </Form.Item>
                    }
                  </Card>
                </Col>

                {/* Sağ Card */}
                <Col span={11}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    {
                      <Form.Item
                        {...layout1}
                        label={<FormattedMessage id="ProductLog.TenderNo" defaultMessage="Tender No" />}
                        name="tenderNo"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout1}
                        label={<FormattedMessage id="ProductLog.ContractNo" defaultMessage="Contract No" />}
                        name="contractNo"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    }
                  </Card>
                </Col>
                <Col span={1}></Col>
              </Row>

              <></>
            </Form>
          }
        />
        <DraggableModal
          title={<FormattedMessage id="ProjectLog.contractor" defaultMessage="Contractor" />}
          width={1200}
          centered
          open={this.state.modalContractorAction}
          onOk={() => this.formRefContractor.current.submit()}
          onCancel={() => this.setState({ modalContractorAction: false })}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveContractor} onFinishFailed={onFinishFailed} ref={this.formRefContractor}>
              <>
                <Row gutter={16} style={{ marginBottom: "16px" }}>
                  {/* Sol Card */}
                  <Col span={1}> </Col>
                  <Col span={11}>
                    <Card
                      style={{
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        width: "100%",
                        height: "100%"
                      }}
                    >
                      {
                        <Form.Item
                          {...layout1}
                          label={<FormattedMessage id="ContractLog.ContractorName" defaultMessage="Contractor" />}
                          name="contractorId"
                          rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }}
                            value={this.state.contractorId}
                            onChange={value => {
                              this.formRef.current.setFieldsValue({
                                contractorId: value
                              });
                              this.setState({ contractorId: value });
                            }}
                            dropdownRender={menu => (
                              <div>
                                {menu}
                                <Divider style={{ margin: "4px 0" }} />
                                <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                  <Input
                                    style={{ flex: "auto" }}
                                    value={this.state.codeName}
                                    onChange={event => {
                                      this.setState({
                                        codeName: event.target.value
                                      });
                                    }}
                                  />
                                  <AntButton type="link" icon={<PlusOutlined />} onClick={() => this.addCode("ProjectLog.contractorId")}>
                                    Add item
                                  </AntButton>
                                </div>
                              </div>
                            )}
                          >
                            {Boolean(this.state["ProjectLog.contractorIdSelectItems"]) &&
                              this.state["ProjectLog.contractorIdSelectItems"].length > 0 &&
                              this.state["ProjectLog.contractorIdSelectItems"].map(i => (
                                <Option key={i.id} value={i.id}>
                                  {i.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      }
                      {
                        <Form.Item
                          {...layout1}
                          label={<FormattedMessage id="ProjectLog.City" defaultMessage="City" />}
                          name="constructorCityId"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select style={{ width: "100%" }} allowClear showSearch optionFilterProp="children">
                            {Boolean(this.state["cityIdSelectItems"]) &&
                              this.state["cityIdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      }
                      {
                        <Form.Item
                          {...layout1}
                          label={<FormattedMessage id="ProjectLog.county" defaultMessage="County" />}
                          name="constructorCountyId"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select
                            allowClear
                            showSearch
                            onSearch={this.searchcountyId}
                            style={{ marginBottom: 0, width: "100%" }}
                            loading={this.state.loadingSearch}
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          >
                            {Boolean(this.state["countyIdSelectItems"]) &&
                              this.state["countyIdSelectItems"].length > 0 &&
                              this.state["countyIdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      }
                      {
                        <Form.Item
                          {...layout1}
                          label={<FormattedMessage id="ContractLog.Address" defaultMessage="Address" />}
                          name="contractorAddress"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      }
                      {
                        <Form.Item
                          {...layout1}
                          label={<FormattedMessage id="ContractLog.contactPerson" defaultMessage="Contact Person" />}
                          name="contactPerson"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      }
                      {
                        <Form.Item
                          {...layout1}
                          label={<FormattedMessage id="ContractLog.ContactNo" defaultMessage="Contact No" />}
                          name="contactNo"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      }
                    </Card>
                  </Col>

                  {/* Sağ Card */}
                  <Col span={11}>
                    <Card
                      style={{
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        width: "100%",
                        height: "100%"
                      }}
                    >
                      {
                        <Form.Item
                          {...layout1}
                          label={<FormattedMessage id="ContractLog.ContactNo" defaultMessage="Contact No" />}
                          name="contactNo"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      }
                    </Card>
                  </Col>
                  <Col span={1}></Col>
                </Row>
              </>
            </Form>
          }
        />
        <DraggableModal
          title={<FormattedMessage id="ProjectLog.assign" defaultMessage="Assign" />}
          width={1200}
          centered
          okButtonProps={{ hidden: true }}
          open={this.state.modalAssignAction}
          onCancel={() => this.setState({ modalAssignAction: false })}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <CompanyProjectAssign
              companyProjectId={this.state.id}
              random={this.state.random}
              close={() => this.setState({ modalAssignAction: false })}
            />
          }
        />
        <DraggableModal
          title={<FormattedMessage id="ProjectLog.progress" defaultMessage="Project Progress" />}
          width={900}
          centered
          okButtonProps={{ hidden: true }}
          open={this.state.modalProgressAction}
          onCancel={() =>
            this.setState({
              modalProgressAction: false,
              projectsIdentificationsId: null,
              checkedKeysProjectIdentification: [],
              halfCheckedKeys: null,
              checkedNodes: null
            })
          }
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <CompanyProjectProgress
              companyProjectId={this.state.id}
              random={this.state.random}
              close={() => {
                this.setState({
                  modalProgressAction: false,
                  projectsIdentificationsId: null,
                  checkedKeysProjectIdentification: [],
                  halfCheckedKeys: null,
                  checkedNodes: null
                });
              }}
              projectsIdentificationsId={this.state.projectsIdentificationsId}
            />
          }
        />
        <DraggableModal
          title={<FormattedMessage id="ProjectLog.progress" defaultMessage="Project Progress" />}
          width={800}
          centered
          okButtonProps={{ hidden: true }}
          open={this.state.modalProjectIdentificationAction}
          onCancel={() => this.setState({ modalProjectIdentificationAction: false })}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Tree
              style={{ marginTop: "10px", lineHeight: "30px" }}
              className="draggable-tree"
              showLine={{ showLeafIcon: false }}
              showIcon
              checkStrictly={true}
              loadedKeys={this.state.loadedKeysProjectIdentifications}
              onLoad={this.onLoadDataProjectIdentifications}
              loadData={this.loadDataProjectIdentification}
              onExpand={this.onExpandProjectIdentifications}
              expandedKeys={this.state.expandedKeysProjectIdentificationAction}
              autoExpandParent={false}
              treeData={this.state.nodesProjectIdentifications}
              blockNode={true}
              checkable
              onCheck={this.onCheckProjectIdentification}
              checkedKeys={this.state.checkedKeysProjectIdentification}
              halfCheckedKeys={this.state.halfCheckedKeys}
            />
          }
        />
        <DraggableModal
          title={<FormattedMessage id="ProjectLog.budget" defaultMessage="Budget" />}
          width={1200}
          centered
          okButtonProps={{ hidden: true }}
          open={this.state.modalBudgetAction}
          onCancel={() => this.setState({ modalBudgetAction: false })}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <CompanyProjectBudget
              companyProjectId={this.state.id}
              random={this.state.random}
              close={() => this.setState({ modalBudgetAction: false })}
            />
          }
        />
        <DraggableModal
          title={<FormattedMessage id="ProjectLog.Payment" defaultMessage="Payment" />}
          width={1200}
          centered
          okButtonProps={{ hidden: true }}
          open={this.state.modalPaymentAction}
          onCancel={() => this.setState({ modalPaymentAction: false })}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.updateArea} onFinishFailed={onFinishFailed} ref={this.formRefArea}>
              <Row gutter={16} style={{ marginBottom: "16px" }}>
                <br></br>
                {/* Sol Card */}
                <Col span={1}> </Col>
                <Col span={11}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    {/* <Form.Item label={<FormattedMessage id="ProjectLog.Date" defaultMessage="Date" />}>
                      <Text>{this.state.initialValues.date}</Text>
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="ProjectLog.ContractBudget" defaultMessage="Contract Budget" />}>
                      <Text>{this.state.initialValues.contractBudget}</Text>
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="ProjectLog.EstimatedBudget" defaultMessage="Estimated Budget" />}>
                      <Text>{this.state.initialValues.estimatedBudget}</Text>
                    </Form.Item>
                    <Form.Item>
                      label={<FormattedMessage id="ProjectLog.CorrectedContractBudget" defaultMessage="Corrected Contract Budget" />}
                      <Text>{this.state.initialValues.correctedContractBudget}</Text>                      
                    </Form.Item>
                    <br></br>
                    <Form.Item>
                      label={<FormattedMessage id="ProjectLog.ProgressPayment" defaultMessage="Progress Payment" />}
                      <Text>{this.state.initialValues.progressPayment}</Text>                      
                    </Form.Item>
                    <Form.Item>
                      label={<FormattedMessage id="ProjectLog.PaidAmount" defaultMessage="Paid Amount" />}
                      <Text>{this.state.initialValues.paidAmount}</Text>                      
                    </Form.Item>
                    */}
                  </Card>
                </Col>

                {/* Sağ Card */}
                <Col span={11}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    {
                      <Form.Item
                        {...layout1}
                        label={<FormattedMessage id="ProductLog.PaymentDate" defaultMessage="Payment Date" />}
                        name="paymentDate"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY" />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProjectLog.EstimatedBudget" defaultMessage="Estimated Budget" />}
                        name="estimatedBudget"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Space.Compact style={{ width: "100%" }}>
                          <Form.Item
                            name="supplierId"
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                            style={{ width: "90%" }}
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              parser={value => value.replace(",", ".")}
                              formatter={value => value.replace(".", ",")}
                            />
                          </Form.Item>

                          <Select
                            allowClear
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            onChange={value => {
                              this.setState(
                                {
                                  supplierId: value
                                },
                                () => {
                                  this.fillBrand();
                                }
                              );
                            }}
                          ></Select>
                        </Space.Compact>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProjectLog.CorrectedBudget" defaultMessage="Corrected Budget" />}
                        name="correctedBudget"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Space.Compact style={{ width: "100%" }}>
                          <Form.Item
                            name="supplierId"
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                            style={{ width: "90%" }}
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              parser={value => value.replace(",", ".")}
                              formatter={value => value.replace(".", ",")}
                            />
                          </Form.Item>

                          <Select
                            allowClear
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            onChange={value => {
                              this.setState(
                                {
                                  supplierId: value
                                },
                                () => {
                                  this.fillBrand();
                                }
                              );
                            }}
                          ></Select>
                        </Space.Compact>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProjectLog.PaidAmount" defaultMessage="Paid Amount" />}
                        name="paidAmount"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Space.Compact style={{ width: "100%" }}>
                          <Form.Item
                            name="supplierId"
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                            style={{ width: "90%" }}
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              parser={value => value.replace(",", ".")}
                              formatter={value => value.replace(".", ",")}
                            />
                          </Form.Item>

                          <Select
                            allowClear
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            onChange={value => {
                              this.setState(
                                {
                                  supplierId: value
                                },
                                () => {
                                  this.fillBrand();
                                }
                              );
                            }}
                          ></Select>
                        </Space.Compact>
                      </Form.Item>
                    }
                  </Card>
                </Col>
                <Col span={1}></Col>
              </Row>
            </Form>
          }
        />
        <DraggableModal
          title={<FormattedMessage id="ProjectLog.Remarks" defaultMessage="Remarks" />}
          width={800}
          centered
          okButtonProps={{ hidden: true }}
          open={this.state.modalRemarksAction}
          onCancel={() => this.setState({ modalRemarksAction: false })}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.updateArea} onFinishFailed={onFinishFailed} ref={this.formRefArea}>
              <Row gutter={16} style={{ marginBottom: "16px" }}>
                <br></br>
                {/* Sol Card */}
                <Col span={1}> </Col>
                <Col span={11}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    {/* <Form.Item label={<FormattedMessage id="ProjectLog.Date" defaultMessage="Date" />}>
                      <Text>{this.state.initialValues.date}</Text>
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="ProjectLog.ContractBudget" defaultMessage="Contract Budget" />}>
                      <Text>{this.state.initialValues.contractBudget}</Text>
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="ProjectLog.EstimatedBudget" defaultMessage="Estimated Budget" />}>
                      <Text>{this.state.initialValues.estimatedBudget}</Text>
                    </Form.Item>
                    <Form.Item>
                      label={<FormattedMessage id="ProjectLog.CorrectedContractBudget" defaultMessage="Corrected Contract Budget" />}
                      <Text>{this.state.initialValues.correctedContractBudget}</Text>                      
                    </Form.Item>
                    <br></br>
                    <Form.Item>
                      label={<FormattedMessage id="ProjectLog.ProgressPayment" defaultMessage="Progress Payment" />}
                      <Text>{this.state.initialValues.progressPayment}</Text>                      
                    </Form.Item>
                    <Form.Item>
                      label={<FormattedMessage id="ProjectLog.PaidAmount" defaultMessage="Paid Amount" />}
                      <Text>{this.state.initialValues.paidAmount}</Text>                      
                    </Form.Item>
                    */}
                  </Card>
                </Col>

                {/* Sağ Card */}
                <Col span={11}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    {
                      <Form.Item
                        {...layout1}
                        label={<FormattedMessage id="ProductLog.Date" defaultMessage="Date" />}
                        name="remarksDate"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY" />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProjectLog.Remarks" defaultMessage="Remarks" />}
                        name="remarks"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProjectLog.cityId" defaultMessage="City" />}
                        name="cityId"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select style={{ width: "100%" }} allowClear showSearch optionFilterProp="children">
                          {Boolean(this.state["cityIdSelectItems"]) &&
                            this.state["cityIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    }
                  </Card>
                </Col>
                <Col span={1}></Col>
              </Row>
            </Form>
          }
        />
        <DraggableModal
          title={<FormattedMessage id="GeneralPDFReportViewer" defaultMessage="PDF Report Viewer" />}
          open={this.state.modalPdfViewer}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalPdfViewer: false })}
          width={1200}
          centered
          content={
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div style={{ marginTop: "2rem" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>
          }
        />
        <DraggableModal
          title={<FormattedMessage id="ProjectLog.Presentation" defaultMessage="Presentation" />}
          width={1000}
          centered
          okButtonProps={{ hidden: true }}
          open={this.state.modalPresentationAction}
          onCancel={() => this.setState({ modalPresentationAction: false })}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <PresentationContent
              companyProjectId={this.state.id}
              random={this.state.random}
              close={() => this.setState({ modalPresentationAction: false })}
            />
          }
        />
        <DraggableModal
          title={<FormattedMessage id="ProjectLog.Summary" defaultMessage="Summary" />}
          width={1000}
          centered
          okButtonProps={{ hidden: true }}
          open={this.state.modalSummaryAction}
          onCancel={() => this.setState({ modalSummaryAction: false })}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <SummaryContent companyProjectId={this.state.id} random={this.state.random} close={() => this.setState({ modalSummaryAction: false })} />
          }
        />
        <DraggableModal
          title={<FormattedMessage id="ProjectsLog.ProjectProgress" defaultMessage="Project Progress" />}
          open={this.state.showProgressPreviewModal}
          okButtonProps={{ hidden: false }}
          onCancel={() => this.setState({ showProgressPreviewModal: false })}
          onOk={() => this.formRefProgress.current.submit()}
          width={800}
          centered
          content={
            <Form initialValues={{ remember: false }} onFinish={this.updateProgress} onFinishFailed={onFinishFailed} ref={this.formRefProgress}>
              {
                <Form.Item
                  {...layout1}
                  label={<FormattedMessage id="ProductLog.Date" defaultMessage="Date" />}
                  name="Date"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY" />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout1}
                  label={<FormattedMessage id="ProjectLog.ProgressArae " defaultMessage="Progress (Square-meter)" />}
                  name="physicalProgress"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout1}
                  label={<FormattedMessage id="ProjectLog.ProgressArae " defaultMessage="Progress (%)" />}
                  name="progressPercent"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              }
            </Form>
          }
        />
        <DraggableModal
          title={<FormattedMessage id="ProjectsIdentifications.Area" defaultMessage="Area" />}
          open={this.state.showAreaModal}
          okButtonProps={{ hidden: false }}
          onCancel={() => this.setState({ showAreaModal: false })}
          onOk={() => this.formRefArea.current.submit()}
          width={800}
          centered
          content={
            <Form initialValues={{ remember: false }} onFinish={this.updateArea} onFinishFailed={onFinishFailed} ref={this.formRefArea}>
              {
                <Form.Item
                  {...layout1}
                  label={<FormattedMessage id="ProjectLog.Quantity" defaultMessage="Quantity" />}
                  name="quantity"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout1}
                  label={<FormattedMessage id="ProjectLog.Capacity" defaultMessage="Capacity" />}
                  name="capacity"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout1}
                  label={<FormattedMessage id="ProjectLog.Area" defaultMessage="Area (square-meter)" />}
                  name="area"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              }
            </Form>
          }
        />
        <DraggableModal
          title={<FormattedMessage id="ProjectsIdentifications.Presentation" defaultMessage="Presentation" />}
          open={this.state.modalPresentationTable}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalPresentationTable: false })}
          width={1000}
          centered
          content={<PresentationContentTable random={this.state.random} companyProjectId={this.state.id} />}
        />
        <DraggableModal
          title={<FormattedMessage id="ProjectsIdentifications.Summary" defaultMessage="Summary" />}
          open={this.state.modalSummaryTable}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalSummaryTable: false })}
          width={1000}
          centered
          content={<SummaryContentTable random={this.state.random} companyProjectId={this.state.id} />}
        />
        <DraggableModal
          title={<FormattedMessage id="ProjectLog.Status" defaultMessage="Status" />}
          open={this.state.modalStatusAction}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalStatusAction: false })}
          width={1200}
          centered
          content={
            <CompanyProjectStatus
              companyProjectId={this.state.id}
              random={this.state.random}
              close={() => this.setState({ modalStatusAction: false })}
            />

            /*<div className="card card-custom" style={{ padding: "0.1rem", minHeight: "100%" }}>
              <div className="card-body pt-4 custom-timeline pr-5 pl-5 mr-5 ml-5 align-content-center">
                <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                <Steps direction="vertical" className="pl-5-custom anticon-custom">
                  {Boolean(this.state.statusList) &&
                    this.state.statusList.length > 0 &&
                    this.state.statusList.map((item, index) => {
                      return item.spin ? (
                        <Step status="process" title={item.status + (item.count >= 0 ? " - " + item.count : "")} icon={<LoadingOutlined />} />
                      ) : (
                        <Step
                          title={item.status + (item.count >= 0 ? " - " + item.count : "")}
                          status={item.color === "green" ? "finish" : item.color === "blue" ? "process" : "wait"}
                          description={item.color === "green" || item.color === "blue" ? moment(item.date).format("DD-MM-YYYY HH:mm:ss") : ""}
                        />
                      );
                    })}
                </Steps>
              </div>
            </div>*/
          }
        />
        <DraggableModal
          title={<FormattedMessage id="ProjectsIdentifications.History" defaultMessage="History" />}
          open={this.state.modalHistoryShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalHistoryShow: false })}
          width={600}
          centered
          scroll={{ x: 400, y: 400, scrollToFirstRowOnChange: true }}
          content={
            <div className="card card-custom" style={{ minHeight: "100%", padding: "2rem" }}>
              <Timeline mode="alternate">
                {Boolean(this.state.historyList) &&
                  this.state.historyList.length > 0 &&
                  this.state.historyList.map((item, index) => {
                    return (
                      <Timeline.Item>
                        {item.statusName} <br />
                        {item.remoteUserName} <br />
                        {moment(item.date).format("DD-MM-YYYY HH:mm:ss")}
                      </Timeline.Item>
                    );
                  })}
              </Timeline>
            </div>
          }
        />
        <DraggableModal
          title={<FormattedMessage id="ProjectsIdentifications.Scoring" defaultMessage="Scoring" />}
          open={this.state.modalProgressScoringAction}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalProgressScoringAction: false })}
          width={1200}
          centered
          content={
            <ProjectProgressScoring
              random={this.state.random}
              companyProjectId={this.state.id}
              close={() => this.setState({ modalProgressScoringAction: false })}
            />
          }
        />
        <DraggableModal
          title={<FormattedMessage id="ProjectsIdentifications.Remarks" defaultMessage="Remarks" />}
          open={this.state.modalRemarksShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalRemarksShow: false })}
          width={600}
          centered
          scroll={{ x: 400, y: 400, scrollToFirstRowOnChange: true }}
          content={
            <div className="card card-custom" style={{ minHeight: "100%", padding: "2rem" }}>
              <Timeline mode="alternate">
                {Boolean(this.state.remarksList) &&
                  this.state.remarksList.length > 0 &&
                  this.state.remarksList.map((item, index) => {
                    return (
                      <Timeline.Item>
                        {moment(item.date).format("DD-MM-YYYY HH:mm:ss")}
                        <br />
                        {item.remoteUserName}
                        <br />
                        {item.remarks}
                      </Timeline.Item>
                    );
                  })}
              </Timeline>
            </div>
          }
        />
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectLog);
