import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { EditOutlined, ReplyOutlined, ScoreOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined, LinkOutlined, SettingTwoTone, ZoomInOutlined } from "@ant-design/icons";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import ImageSearch from "@material-ui/icons/ImageSearch";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";

import {
  Form,
  Table,
  Input,
  Select,
  InputNumber,
  Radio,
  Divider,
  Cascader,
  Button as AntButton,
  Col,
  Row,
  Image,
  Tag,
  Tooltip,
  Modal as AntModal,
  Spin,
  Badge
} from "antd";
import { ExcelReader } from "../../excel/ExcelReader";
import moment from "moment";
import { handleRequest } from "../../../ApiConnector";
import CreateIcon from "@material-ui/icons/AddCircle";
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import SelectFromImageList from "../../visual/SelectFromImageList";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Viewer, { defaultLayout, Worker } from "@phuocng/react-pdf-viewer";
import ExcelReaderReadAllWorkSheets from "../../excel/ExcelReaderReadAllWorkSheets";
// import { StockCardNo } from "./StockCardNo";
// import { StockCardPurchaseCriteria } from "./StockCardPurchaseCriteria";
// import StockCardRenameCriteria from "./StockCardRenameCriteria";
import "../../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";
import StockCardSelectable from "../../store/StockCardSelectable";
import DraggableModal from "../../../Components/DraggableModal";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};
const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};


export class EldenStockCardSelectable extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "eldenStockCard",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      modalPdfViewer: false,
      pdfFile: "",
      lastUpdateDate: [],
      showImageViewerModal: false,
      descriptionIdSelectItems: [],
      id: null,
      tableList: [],
      nodes: [], // for cascader.
      unitTypes: [],
      visible: false,
      open: false,
      disabled: true,
      bounds: { left: 0, top: 0, bottom: 0, right: 0 },

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      disabledLinkedDrawings: false,
      linkedDrawingIds: [],
      linkDrawingList: [],
      searchCriteriaModels: [],
      spinLoading: false
    };
  }


  formRef = React.createRef();
  formRefstockCardModal = React.createRef();


  fillPurchasingCriteriaUnits = async criteriaId => {
    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.setFieldsValue({
        purchasingCriteriaUnitId: null
      });
    }
    if (Boolean(criteriaId)) {
      let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getUnitType/" + criteriaId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          purchasingCriteriaUnitSelectItems: Boolean(response.data) ? response.data : []
        });
      }
    } else {
      this.setState({
        purchasingCriteriaUnitSelectItems: []
      });
    }
  };


  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    this.refreshLinkDrawingList();
    fillFilterOperations();
  };

  fillComboboxes = async () => {
    this.fillCode("StockCardCoatingId");
    this.fillCode("StockCardCuttingId");
    this.fillCode("StockCardMillingId");
    this.fillCode("StockCardDrillingId");
    this.fillCode("StockCardItemClassId");
    this.fillCode("purchasingCriteriaId");

    var responseUnits = await handleRequest("GET", "/api/" + this.state.controllerName + "/unitTypes");
    if (responseUnits.type === "ERROR") {
      error(responseUnits);
    } else {
      this.setState({
        unitTypes: Boolean(responseUnits.data) ? responseUnits.data : []
      });
    }
    var responseStandardId = await handleRequest("GET", "/api/" + this.state.controllerName + "/standardId");
    if (responseStandardId.type === "ERROR") {
      error(responseStandardId);
    } else {
      this.setState({
        StockCardStandardIdSelectItems: Boolean(responseStandardId.data) ? responseStandardId.data : []
      });
    }

    var responsecategoryId = await handleRequest("GET", "/api/" + this.state.controllerName + "/category");
    if (responsecategoryId.type === "ERROR") {
      error(responsecategoryId);
    } else {
      this.setState({
        categoryIdSelectItems: Boolean(responsecategoryId.data) ? responsecategoryId.data : []
      });
    }

    var responceDrawing = await handleRequest("GET", "/api/" + this.state.controllerName + "/drawings");
    if (responceDrawing.type === "ERROR") {
      error(responceDrawing);
    } else {
      this.setState({
        drawingIdSelectItems: Boolean(responceDrawing.data) ? responceDrawing.data : []
      });
    }

    var responsedescriptionSelectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/descriptionSelect");
    if (responsedescriptionSelectId.type === "ERROR") {
      error(responsedescriptionSelectId);
    } else {
      this.setState({
        descriptionSelectIdSelectItems: Boolean(responsedescriptionSelectId.data) ? responsedescriptionSelectId.data : []
      });
    }
    var responsetabledescriptionSelect = await handleRequest("GET", "/api/" + this.state.controllerName + "/tableDescription");
    if (responsetabledescriptionSelect.type === "ERROR") {
      error(responsetabledescriptionSelect);
    } else {
      this.setState({
        tabledescriptionSelectItems: Boolean(responsetabledescriptionSelect.data) ? responsetabledescriptionSelect.data : []
      });
    }
    console.log(this.state.tabledescriptionSelectItems);

    var responseweightUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/weightUnitId");
    if (responseweightUnit.type === "ERROR") {
      error(responseweightUnit);
    }
    this.setState({
      weightUnitIdSelectItems: Boolean(responseweightUnit.data) ? responseweightUnit.data : []
    });

    var responsefilterItem = await handleRequest("GET", "/api/" + this.state.controllerName + "/filterItem");
    if (responsefilterItem.type === "ERROR") {
      error(responsefilterItem);
    }
    this.setState({
      filterItemSelectItems: Boolean(responsefilterItem.data) ? responsefilterItem.data : []
    });

    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/tree");
    if (responseTree.data.length !== 0) {
      generateList(responseTree.data);
      this.setState({
        nodes: responseTree.data
      });
    }

    //this.fillCodeDescription();
  };

  fillCodeDescription = async () => {
    var newObj = {
      itemGroupId: null
    };
    var responsedescriptionId = await handleRequest("POST", "/api/" + this.state.controllerName + "/description", newObj);
    if (responsedescriptionId.type === "ERROR") {
      error(responsedescriptionId);
    } else {
      this.setState({
        descriptionIdSelectItems: Boolean(responsedescriptionId.data) ? responsedescriptionId.data : []
      });
    }
  };

  setItemDescriptionId = async value => {
    this.formRef.current.setFieldsValue({
      descriptionId: value.key,
    });

    this.handleEldenItemIdChange();

    this.formRef.current.setFieldsValue({
      descriptionId: value.key,
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  fillGrade = async standardId => {
    if (Boolean(standardId) && standardId > 0) {
      var responsegradeId = await handleRequest("GET", "/api/" + this.state.controllerName + "/gradeId/" + standardId);
      if (responsegradeId.type === "ERROR") {
        error(responsegradeId);
      }
      this.setState({
        gradeIdSelectItems: Boolean(responsegradeId.data) ? responsegradeId.data : []
      });
    } else {
      this.setState({
        gradeIdSelectItems: []
      });
    }
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      selectedEldenItem: null,
      disabledLinkedDrawings: false,
      categoryId: null,
      materialTypeId: null,
      descriptionId: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  edit = async (typeRevise, row) => {
    let linkedDrawingIds = [];
    document.getElementById("kt_scrolltop").click();

    if (Boolean(row.categoryId) && row.categoryId > 0) {
      await this.onChangeItemGroupId(row.categoryId);
    }

    var materialType = dataList.find(p => p.key === row.materialTypeId);
    let materialTypeId = [];
    if (Boolean(materialType)) {
      materialTypeId = materialType.lineage;
    }

    let descriptionId = row.descriptionId;

    this.formRef.current.setFieldsValue({
      ...row,
      descriptionId: descriptionId,
      materialTypeId: materialTypeId
    });

    if (Boolean(row.linkedDrawingList))
      row.linkedDrawingList.forEach(element => {
        linkedDrawingIds.push(element.linkedDrawingId);
      });

    await this.handleEldenItemIdChange();
    await this.editDimensionsAndCodeCriteria(row);
    this.fillGrade(row.standardId);

    this.formRef.current.setFieldsValue({
      ...row,
      descriptionId: descriptionId,
      materialTypeId: materialTypeId
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      linkedDrawingIds: linkedDrawingIds,
      descriptionId: descriptionId,
      categoryId: row.categoryId,
      materialTypeId: Boolean(materialTypeId) && materialTypeId.length > 0 ? materialTypeId[materialTypeId.length - 1] : null
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  active = async row => {
    var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/setActive/" + row.id, row);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    var itemDimension = [];
    if (Boolean(this.state.selectedEldenItem) &&
      Boolean(this.state.selectedEldenItem.itemDimension) &&
      this.state.selectedEldenItem.itemDimension.length > 0) {
      this.state.selectedEldenItem.itemDimension.forEach(element => {
        let itemDefinition = this.formRef.current.getFieldValue([element.itemDefinition]);
        let unitId = this.formRef.current.getFieldValue([element.itemDefinition + "unitId"]);

        itemDimension.push({
          itemDefinitionName: element.itemDefinition,
          codeId: itemDefinition,
          unitId: unitId
        });
      });
    }
    newItem.dimensions = itemDimension;

    var itemCodeCriterias = [];
    if (
      Boolean(this.state.selectedEldenItem) &&
      Boolean(this.state.selectedEldenItem.itemCodeCriterias) &&
      this.state.selectedEldenItem.itemCodeCriterias.length > 0
    ) {
      this.state.selectedEldenItem.itemCodeCriterias.forEach(element => {
        let itemDefinition = this.formRef.current.getFieldValue([element.itemDefinition]);

        itemCodeCriterias.push({
          itemDefinitionName: element.itemDefinition,
          codeId: itemDefinition
        });
      });
    }
    newItem.codeCriterias = itemCodeCriterias;

    if (Boolean(this.state.selectedEldenItem) && Boolean(this.state.selectedEldenItem.sketchPath)) {
      newItem.sketchPath = this.state.selectedEldenItem.sketchPath;
    }
    newItem.materialTypeId =
      Boolean(values.materialTypeId) && values.materialTypeId.length > 0 ? values.materialTypeId[values.materialTypeId.length - 1] : null;

    console.log("selectedEldenItem", this.state.selectedEldenItem);
    console.log("newItem", newItem);

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDastockCardart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDastockCardart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDastockCardart: lastUpdateDastockCardart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          if (Array.isArray(element))
            element.forEach(element2 => {
              let obj = { columns: element2 };
              list.push(obj);
            });
          else {
            let obj = { columns: element };
            list.push(obj);
          }
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/importDynamicaly", list);
          if (response.type === "ERROR") {
            error(response);
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          error("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleUploadExcelStockCardAndInventory = async excelJson => {
    try {
      this.setState({ spinLoading: true });
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            ...element
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/importStockCardAndInventory", list);
          if (response.type === "ERROR") {
            error(response);
            this.setState({ spinLoading: false });
          } else {
            let message = response.data;
            if (Boolean(message)) {
              this.setState({ spinLoading: false });
              showError(message);
            } else {
              this.resetInputs();
              const { pagination } = this.state;
              this.restartTable({ pagination });
              showSuccess();
              this.setState({ spinLoading: false });
            }
          }
        } else {
          showError("No records to be added");
          this.setState({ spinLoading: false });
        }
      }
    } catch (error) {
      showError("");
      this.setState({ spinLoading: false });
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  addCodeDescription = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCodeDescription(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  action = record => {
    let editAction = {
      name: <FormattedMessage id="EldenStockCard.Edit" defaultMessage="Edit" />,
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this, false)
    };

    let deleteAction = {
      name: <FormattedMessage id="EldenStockCard.Delete" defaultMessage="Delete" />,
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  onChangeItemGroupId = async value => {
    if (Boolean(value)) {
      var responseitemDescriptionId = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemDescriptionIdSelectItems/" + value);
      if (responseitemDescriptionId.type === "ERROR") {
        error(responseitemDescriptionId);
        this.setState({
          descriptionIdSelectItems: [],
          descriptionId: null
        });
        this.formRef.current.setFieldsValue({
          descriptionId: null
        });
      } else {
        this.setState({
          descriptionIdSelectItems: Boolean(responseitemDescriptionId.data) ? responseitemDescriptionId.data : [],
          selectedEldenItem: null,
          descriptionId: null
        });
        this.formRef.current.setFieldsValue({
          descriptionId: null
        });
      }
    } else {
      this.setState({
        descriptionIdSelectItems: [],
        descriptionId: null
      });
      this.formRef.current.setFieldsValue({
        descriptionId: null
      });
    }
  };

  handleEldenItemIdChange = async () => {
    this.formRef.current.setFieldsValue({
      coatingId: null,
      surfaceFinishingId: null,
      standardId: null
    });

    var newObj = {
      materialCategoryId: this.formRef.current.getFieldValue("categoryId"),
      id: this.formRef.current.getFieldValue("descriptionId")
    };
    let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/eldenItem", newObj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        selectedEldenItem: response.data,
      });
      if (Boolean(response.data) && Boolean(response.data.itemDimension) && response.data.itemDimension.length > 0) {
        response.data.itemDimension.forEach(element => {
          this.fillCode("StockCard" + element.itemDefinition);
          this.formRef.current.setFieldsValue({
            [element.itemDefinition]: null
          });
        });
      }
      if (Boolean(response.data) && Boolean(response.data.itemCodeCriterias) && response.data.itemCodeCriterias.length > 0) {
        response.data.itemCodeCriterias.forEach(element => {
          this.fillCode("StockCard" + element.itemDefinition);
          this.formRef.current.setFieldsValue({
            [element.itemDefinition]: null
          });
        });
      }
    }

    // this.handleCodeCriteriaChange();
  };

  editDimensionsAndCodeCriteria = async row => {
    console.log("row", row);

    if (Boolean(row.dimensions) && row.dimensions.length > 0) {
      row.dimensions.forEach(element => {
        this.formRef.current.setFieldsValue({
          [element.itemDefinitionName]: element.codeId,
          [element.itemDefinitionName + "unitId"]: element.unitId
        });
      });
    }
    if (Boolean(row.codeCriterias) && row.codeCriterias.length > 0) {
      row.codeCriterias.forEach(element => {
        this.formRef.current.setFieldsValue({
          [element.itemDefinitionName]: element.codeId
        });
      });
    }
  };

  handleCodeCriteriaChange = async () => {
    let materialTypeIds = this.formRef.current.getFieldValue("materialTypeId");
    let materialTypeId = Boolean(materialTypeIds) && materialTypeIds.length > 0 ? materialTypeIds[materialTypeIds.length - 1] : null;

    let standardId = this.formRef.current.getFieldValue("standardId");
    let cuttingId = this.formRef.current.getFieldValue("cuttingId");
    let millingId = this.formRef.current.getFieldValue("millingId");
    let dirllingId = this.formRef.current.getFieldValue("drillingId");
    let coatingId = this.formRef.current.getFieldValue("coatingId");

    let obj = {
      materialTypeId: materialTypeId,
      categoryId: this.formRef.current.getFieldValue("categoryId"),
      descriptionId: this.formRef.current.getFieldValue("descriptionId"),
      standardId: Boolean(standardId) ? standardId : null,
      cuttingId: Boolean(cuttingId) ? cuttingId : null,
      millingId: Boolean(millingId) ? millingId : null,
      dirllingId: Boolean(dirllingId) ? dirllingId : null,
      coatingId: Boolean(coatingId) ? coatingId : null
    };

    console.log("selectedEldenItem", this.state.selectedEldenItem);
    var itemDimension = [];
    if (Boolean(this.state.selectedEldenItem) &&
      Boolean(this.state.selectedEldenItem.itemDimension) &&
      this.state.selectedEldenItem.itemDimension.length > 0) {
      this.state.selectedEldenItem.itemDimension.forEach(element => {
        let itemDefinition = this.formRef.current.getFieldValue([element.itemDefinition]);
        itemDimension.push({
          itemDefinitionName: element.itemDefinition,
          codeId: itemDefinition
        });
      });
    }
    obj.dimensions = itemDimension;

    var itemCodeCriterias = [];
    if (Boolean(this.state.selectedEldenItem) &&
      Boolean(this.state.selectedEldenItem.itemCodeCriterias) &&
      this.state.selectedEldenItem.itemCodeCriterias.length > 0) {
      this.state.selectedEldenItem.itemCodeCriterias.forEach(element => {
        let itemDefinition = this.formRef.current.getFieldValue([element.itemDefinition]);

        itemCodeCriterias.push({
          itemDefinitionName: element.itemDefinition,
          codeId: itemDefinition
        });
      });
    }
    obj.codeCriterias = itemCodeCriterias;
    console.log("obj", obj);

    let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/controlCode", obj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      let list = response.data;
      if (Boolean(list) && list.length > 0) {
        obj.list = list;
        let response2 = await handleRequest("POST", "/api/" + this.state.controllerName + "/controlCodeInList", obj);
        if (response.type === "ERROR") {
          error(response2);
        } else {
          let row = response2.data;
          console.log("response2Data", row);

          if (Boolean(row)) {
            var materialType = dataList.find(p => p.key === row.materialTypeId);
            let materialTypeId = [];
            if (Boolean(materialType)) {
              materialTypeId = materialType.lineage;
            }

            let descriptionId = row.descriptionId;

            this.formRef.current.setFieldsValue({
              ...row,
              descriptionId: descriptionId,
              materialTypeId: materialTypeId
            });

            await this.handleEldenItemIdChange();
            await this.editDimensionsAndCodeCriteria(row);

            this.formRef.current.setFieldsValue({
              ...row,
              descriptionId: descriptionId,
              materialTypeId: materialTypeId
            });

            this.setState({
              hideUpdate: false,
              hideSave: true,
              hideInputs: false,
              id: row.id,
            });
          } else {
            this.formRef.current.setFieldsValue({
              code: null
            });
            this.setState({
              hideUpdate: true,
              hideSave: false,
              id: null
            });
          }
        }
      } else {
        this.formRef.current.setFieldsValue({
          code: null
        });
        this.setState({
          hideUpdate: true,
          hideSave: false,
          id: null
        });
      }
    }
  };

  onClickImageViewer = () => {
    this.setState({
      showImageViewerModal: true
    });
  };

  getImageGalleryFromUrls = (paths, key) => {
    const galleryName = "Gallery" + key;
    if (Boolean(paths) && paths.length > 0) {
      let pathList = paths.split(",");
      return (
        <>
          {Boolean(paths) && paths.length > 0 ? (
            <Button
              style={{
                color: "black",
                backgroundColor: "transparent", // Set the background color to transparent
                border: "2px navy-blue",
                width: "100%", // Set the button width to 100% of the cell width
                fontSize: "12px", // Adjust the font size as needed
                textAlign: "center",
              }}
              title="preview"
              width={200}
              preview={{
                visible: false
              }}
              src={pathList[0].trim()}
              onClick={() => this.setState({ [galleryName]: true })}
            ><FormattedMessage id="EldenStockCard.Preview" defaultMessage="Preview" /></Button>
          ) : null}
          {Boolean(pathList) && pathList.length > 0 ? (
            <div style={{ display: "none" }}>
              <Image.PreviewGroup
                preview={{
                  visible: this.state[galleryName],
                  onVisibleChange: vis => this.setState({ [galleryName]: vis })
                }}
              >
                {pathList.map((field, i) => (
                  <Image key={i} src={field} />
                ))}
              </Image.PreviewGroup>
            </div>
          ) : null}
        </>
      );
    }
  };

  refreshLinkDrawingList = async drawingSearch => {
    const { disciplineId, drawingTypeId } = this.state;

    let obj = {
      disciplineId: disciplineId,
      drawingTypeId: drawingTypeId,
      drawingNo: drawingSearch,
      shtNo: drawingSearch
    };

    const response = await handleRequest("POST", "/api/drawings/search", obj);
    if (Boolean(response.data)) {
      this.setState({
        linkDrawingList: response.data
      });
    } else
      this.setState({
        linkDrawingList: []
      });
  };

  generateReport = async () => {
    let url = "/api/" + this.state.controllerName + "/getReport";
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false
      });
      showError("There is no created report for selected item");
    }
  };

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showReport = async url => {
    try {
      const fileRealUrl = url;

      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true
      });
    } catch (error) {
      alert("File cant found.");
    }
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  onStockCardNoClick = () => {
    this.setState({
      random: Math.random(),
      modalStockCardNoShow: true
    });
  };

  onRenameClick = () => {
    this.setState({
      random: Math.random(),
      modalRenameShow: true
    });
  };

  onPurchaseCriteriaClick = () => {
    this.setState({
      random: Math.random(),
      modalPurchaseCriteriaShow: true
    });
  };

  saveStockCardPurchaseCriteria = () => {
    this.setState({
      modalPurchaseCriteriaShow: false
    });
  };

  saveStockCardRenameCriteria = () => {
    this.setState({
      modalRenameShow: false
    });
  };

  dimensionModal = async row => {
    this.setState({
      modalDimensionShow: true,
      modalDimensionList: row.dimensions
    });
  };

  codeCriteriaModal = async row => {
    this.setState({
      modalCodeCriteriaShow: true,
      modalCodeCriteriaList: row.codeCriterias
    });
  };

  onSelectChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys: selectedRowKeys,
    });
    if (Boolean(this.props.onSelectChange)) {
      this.props.onSelectChange(selectedRowKeys);
    }
  };

  render() {
    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.printButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const tailFormItemLayout2 = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };
    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };
    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="EldenStockCard.Type" defaultMessage="Type" />,
        key: "type",
        width: 150,
        render: record => {
          return record.type;
        },
        editable: true,
        ...getColumnFilter({
          inputName: "type",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="EldenStockCard.ItemCategory" defaultMessage="Item Category" />,
        key: "categoryId",
        width: 150,
        render: record => {
          return record.categoryName;
        },
        editable: true,
        ...getColumnFilter({
          inputName: "categoryId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["filterItemSelectItems"]) ? this.state["filterItemSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="EldenStockCard.ItemType" defaultMessage="Item Type" />,
        key: "materialTypeId",
        width: 150,
        ellipsis: true,
        render: record => {
          return record.materialTypeName;
        },
        ...getColumnFilter({
          inputName: "materialTypeId",
          inputType: "CASCADER",
          selectItems: Boolean(this.state["nodes"]) ? this.state["nodes"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="EldenStockCard.ItemDescription" defaultMessage="Item Description" />,
        key: "descriptionId",
        width: 150,
        ellipsis: true,
        render: record => {
          return record.descriptionName;
        },
        ...getColumnFilter({
          inputName: "descriptionId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["tabledescriptionSelectItems"]) ? this.state["tabledescriptionSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="EldenStockCard.Sketch" defaultMessage="Sketch" />,
        key: "sketchPath",
        width: 150,
        render: record => {
          return this.getImageGalleryFromUrls(record.sketchPath, record.id);
        }
      },
      {
        title: <FormattedMessage id="EldenStockCard.Dimension" defaultMessage="Dimension" />,
        key: "id",
        width: 150,
        ellipsis: true,
        render: record => {
          return (record.dimensions) && record.dimensions.length > 0 ? (
            <AntButton
              onClick={() => this.dimensionModal(record)}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<ZoomInOutlined fontSize="large" />}
            ></AntButton>
          ) : (
            ""
          )
        }
      },
      {
        title: <FormattedMessage id="EldenStockCard.CodeCriteria" defaultMessage="Code Criteria" />,
        key: "id",
        width: 150,
        ellipsis: true,
        render: record => {
          return (record.codeCriterias) && record.codeCriterias.length > 0 ? (
            <AntButton
              onClick={() => this.codeCriteriaModal(record)}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<ZoomInOutlined fontSize="large" />}
            ></AntButton>
          ) : (
            ""
          )
        }
      },
      {
        title: <FormattedMessage id="EldenStockCard.Coating" defaultMessage="Coating" />,
        key: "coatingId",
        width: 150,
        render: record => {
          return record.coatingName;
        },
        ...getColumnFilter({
          inputName: "coatingId",
          inputType: "SELECT_ADDABLE",
          selectItems: Boolean(this.state["StockCardCoatingIdSelectItems"]) ? this.state["StockCardCoatingIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="EldenStockCard.Standard" defaultMessage="Standard" />,
        key: "standardId",
        width: 150,
        render: record => {
          return record.standardName;
        },
        ...getColumnFilter({
          inputName: "standardId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["StockCardStandardIdSelectItems"]) ? this.state["StockCardStandardIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="EldenStockCard.Grade" defaultMessage="Grade" />,
        key: "gradeId",
        width: 150,
        render: record => {
          return record.gradeName;
        },
        ...getColumnFilter({
          inputName: "gradeId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["StockCardGradeIdSelectItems"]) ? this.state["StockCardGradeIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="EldenStockCard.LastUpdate" defaultMessage="Last Update" />,
        key: "lastUpdated",
        width: 150,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        ...getColumnFilter({
          inputName: "lastUpdated",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      }
    ];

    const columnsDimensionList = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="EldenStockCard.Definition" defaultMessage="Definition" />,
        key: "id",
        render: record => {
          return record.itemDefinitionName;
        },
      },
      {
        title: <FormattedMessage id="EldenStockCard.Value" defaultMessage="Value" />,
        key: "id",
        render: record => {
          return record.codeValue;
        },
      },
      {
        title: <FormattedMessage id="EldenStockCard.Unit" defaultMessage="Unit" />,
        key: "id",
        render: record => {
          return record.unitName;
        },
      },
    ];

    const columnsCodeCriteriaList = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="EldenStockCard.Dimension" defaultMessage="Dimension" />,
        key: "id",
        render: record => {
          return record.itemDefinitionName;
        },
      },
      {
        title: <FormattedMessage id="EldenStockCard.Value" defaultMessage="Value" />,
        key: "id",
        render: record => {
          return record.codeValue;
        },
      },
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span>
                      <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span>
                      <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}

          <Table
            rowSelection={rowSelection}
            rowKey={record => record.id}
            scroll={{ x: 900, y: 400, scrollToFirstRowOnChange: true }}
            columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            components={{
              header: {
                cell: ResizableTitle
              }
            }}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>
        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfViewer}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>


        <AntModal

          width={1000}
          title={
            "Select an Image"
          }
          open={this.state.showImageViewerModal}
          // okButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ showImageViewerModal: false });
            // this.resetInputs();
          }}
          onOk={() => {
            this.setState({ showImageViewerModal: false });
          }}

        >

          {Boolean(this.state.descriptionIdSelectItems) && (

            <SelectFromImageList data={this.state.descriptionIdSelectItems} setSelected={this.setItemDescriptionId}>

            </SelectFromImageList>

          )}
        </AntModal>

        <AntModal
          open={this.state.modalStockCardNoShow}
          onCancel={() => {
            this.setState({ modalStockCardNoShow: false });
          }}
          width={1500}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalStockCardNoShow: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}

        >
          <Badge.Ribbon
            text={<FormattedMessage id="StockCard.PurchaseOrderNo" defaultMessage="Stock Card No" />}
            placement="start"
            color="purple"
            style={{ fontSize: 12 }}
          >
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              {/* <StockCardNo
                random={this.state.random}
                categoryId={this.state.categoryId}
                descriptionId={this.state.descriptionId}
                materialTypeId={this.state.materialTypeId}
              /> */}
            </div>
          </Badge.Ribbon>


        </AntModal>

        <AntModal
          open={this.state.modalRenameShow}
          onCancel={() => {
            this.setState({ modalRenameShow: false });
          }}
          title={
            <Badge.Ribbon
              text={<FormattedMessage id="StockCard.RenameSettings" defaultMessage="Rename Settings" />}
              placement="start"
              color="purple"
              style={{ fontSize: 12 }}
            >
              {" "}
            </Badge.Ribbon>
          }
          width={1000}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalRenameShow: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
        >
          <div className="card card-custom" style={{ minHeight: "100%" }}>
            {/* <StockCardRenameCriteria
              random={this.state.random}
              descriptionId={this.state.descriptionId}
              categoryId={this.state.categoryId}
              saveStockCardRenameCriteria={this.saveStockCardRenameCriteria}
            /> */}
          </div>
        </AntModal>

        <AntModal
          open={this.state.modalPurchaseCriteriaShow}
          onCancel={() => {
            this.setState({ modalPurchaseCriteriaShow: false });
          }}
          width={1500}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalPurchaseCriteriaShow: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
        >
          <Badge.Ribbon
            text={<FormattedMessage id="EldenStockCard.PurchaseCriteria" defaultMessage="Purchase Criteria" />}
            placement="start"
            color="purple"
            style={{ fontSize: 12 }}
          >
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              {/* <StockCardPurchaseCriteria
                random={this.state.random}
                categoryId={this.state.categoryId}
                descriptionId={this.state.descriptionId}
                saveStockCardPurchaseCriteria={this.saveStockCardPurchaseCriteria}
              /> */}
            </div>
          </Badge.Ribbon>
        </AntModal>

        <AntModal
          title={<FormattedMessage id="EldenStockCard.Dimension" defaultMessage="Dimension" />}
          closable="false"
          open={this.state.modalDimensionShow}
          onCancel={() => {
            this.setState({ modalDimensionShow: false });
          }}
          width={1000}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalDimensionShow: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
        >
          <div className="card card-custom" style={{ minHeight: "100%" }}>
            <Table
              bordered
              size="small"
              columns={columnsDimensionList}
              dataSource={this.state.modalDimensionList}
              loading={this.state.loading}
              pagination={false}
            />
          </div>
        </AntModal>

        <AntModal
          title={<FormattedMessage id="EldenStockCard.CodeCriteria" defaultMessage="Code Criteria" />}
          closable="false"
          open={this.state.modalCodeCriteriaShow}
          onCancel={() => {
            this.setState({ modalCodeCriteriaShow: false });
          }}
          width={1000}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalCodeCriteriaShow: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
        >
          <div className="card card-custom" style={{ minHeight: "100%" }}>
            <Table
              bordered
              size="small"
              columns={columnsCodeCriteriaList}
              dataSource={this.state.modalCodeCriteriaList}
              loading={this.state.loading}
              pagination={false}
            />
          </div>
        </AntModal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EldenStockCardSelectable);