import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../modules/Auth/_redux/authRedux";
import EditIcon from '@material-ui/icons/EditTwoTone';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PageviewIcon from '@material-ui/icons/Pageview';
import { Button, Modal } from 'react-bootstrap';
import { FilterOutlined } from '@ant-design/icons';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { handleRequest } from '../../ApiConnector';
import { API_BASE_ROOT } from '../../ApiConnector';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import CustomDrawingMenu from '../CustomMenu/CustomDrawingMenu';

import CreateIcon from '@material-ui/icons/AddCircle';
import ExportIcon from '@material-ui/icons/CloudDownloadRounded';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { Form, message, Table, Input, Select, Switch, Col, Row, DatePicker, Button as AntButton, InputNumber, Divider } from 'antd';
import { ExcelReader } from '../excel/ExcelReader';
import moment from 'moment';
import { Upload } from 'antd';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import ShareIcon from '@material-ui/icons/Share';
import { FaLastfmSquare, FaLessThanEqual } from 'react-icons/fa';
import { string } from 'prop-types';
import { instanceOf } from 'prop-types';
import { AsyncMode } from 'react-is';
import { error,showError,showSuccess } from '../../MessageHelper';

const { Option } = Select;

export class TransmittalResponse extends Component {

    constructor() {
        super();
        this.state = {

            modalDeleteShow: false,
            exportList: [],
            exportClick: false,
            showCodeModal: false,

            hideUpdate: true,
            hideSave: false,
            hiddenInputs: true,
            filePath: '',
            fileList: [],
            filePaths: [],
            showRevisedListModal: false,
            showLinkedListModal: false,
            revisedFileList: [],
            modalPdfViewer: false,
            pdfFile: "",
            codeName: "",
            responseSelectItems: [],

            id: null,
            modalSupersedeShow: false,
            selectedRow: null,
            revising: false,

            disciplineId: null,
            drawingTypeId: null,
            drawingSearch: null,
            linkDrawingList: [],
            linkedDrawingIds: [],
            dateOfSupersede: null,
            modalSupersedeAction: false,
            index: null,
            transmittalLogId:null,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['2', '10', '25', '50', '100', '1000', '10000', '100000', '1000000'],
            },
            loading: false,
            sortField: "",
            sortOrder: "",
        };

        
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        let logId = Boolean(this.props) && Boolean(this.props.transmittalLogId) ? this.props.transmittalLogId : null;
        this.edit(logId);
        this.setState({
            transmittalLogId: logId
        });
        this.fillResponse();

    };

    componentDidUpdate = (prevProps) => {

        let logId = Boolean(this.props) && Boolean(this.props.transmittalLogId) ? this.props.transmittalLogId : null;
        let prevLogId = this.state.transmittalLogId;  //Boolean(prevProps) && Boolean(prevProps.logId) ? prevProps.logId : null;



        if (prevLogId !== logId) {
            console.log("LOGID", logId);
            console.log("Previous LOGID", prevLogId);
            this.edit(logId);
            this.setState({
                transmittalLogId: logId
            });
            this.fillResponse();
        }
    }


    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    cancel = () => {
        this.setState({
            hideUpdate: true,
            hideSave: false,
            hiddenInputs: true,
        });
        this.resetInputs();

        if(Boolean(this.props.close))
        this.props.close(); 
    };

    resetInputs = () => {

        this.formRef.current.resetFields();

        this.setState({
            filePath: '',
            fileList: [],

            id: null,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['2', '10', '25', '50', '100', '1000', '10000', '100000', '1000000'],
            },
        })
    }


    edit = async (logId) => {

        this.resetInputs();
        if (Boolean(logId)) {
            var response = await handleRequest("GET", "/api/transmittalResponses/byTransmittalLogId/" + logId);
        }

        if (response.type === "ERROR") {
            // error(response);
        }
        else {
            var row = response.data;
            if (Boolean(row) && Boolean(row.id)) {

                let fileList = [];
                let pathList = Boolean(row.filePath) ? row.filePath.split(",") : [];

                if (Boolean(pathList) && pathList.length > 0) {

                    pathList.forEach((element, index) => {
                        let obj = {
                            uid: index,
                            name: element.split("download/")[1],
                            url: element,
                            thumbUrl: element,
                        };

                        fileList.push(obj);
                    });
                }


                this.formRef.current.setFieldsValue({
                    ...row,
                    responseDate: moment(row.responseDate),


                });


                this.setState({
                    hideUpdate: false,
                    hideSave: true,
                    id: row.id,
                    fileList: fileList,
                    filePaths: pathList
                });
            }

        }
    };





    openShareModal = (row) => {

        this.setState({
            shareFileId: row.id,
            showShareModal: true
        });

    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    supersedeModal = (row) => {
        this.setState({
            modalSupersedeShow: true,
            selectedRow: row,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    delete = async () => {
        const deletedItem = {
            id: this.state.id
        }
        var response = await handleRequest("DELETE", "/api/transmittalResponses/" + deletedItem.id);

        if (response.type === "ERROR") {
            error(response);
        }
        else {

            this.setState({
                id: null,
                modalDeleteShow: false
            });
        }
    }





    handleTableChange = (pagination, filters, sorter) => {
        this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters, });
    }

    restartTable = async (params = {}) => {

        this.setState({ loading: true });
        const newObj = this.createWhere(params);

        let url = "/api/transmittalResponses/paging";
        const response = await handleRequest("POST", url, newObj);
        if (response.type === "ERROR") {
            error(response);
            this.setState({
                loading: false,
                tableList: [],
                pagination: {
                    ...params.pagination,
                    total: 0,
                },
                sortField: "",
                sortOrder: "",
            });
        }
        else {
            if (response.data.length !== 0) {
                let list = response.data.data;
                list.forEach((element, index) => {
                    element.sn = index + 1;
                });
                this.setState({
                    loading: false,
                    tableList: list,
                    pagination: {
                        ...params.pagination,
                        total: response.data.totalRecords,
                    },
                    sortField: params.sortField,
                    sortOrder: params.sortOrder,
                });
            } else {
                this.setState({
                    loading: false,
                    tableList: [],
                    pagination: {
                        ...params.pagination,
                        total: 0,
                    },
                    sortField: "",
                    sortOrder: "",
                });
            }
        }
    };

    onClickCreateNew = async () => {
        this.resetInputs();
        if (this.state.hiddenInputs) {
            this.setState({
                hiddenInputs: false,
                hiddenSave: false,
                hiddenUpdate: true,
            });
        }
        else {
            this.setState({
                hiddenInputs: true,
                hiddenSave: true,
                hiddenUpdate: false,
            });
        }
    }

    save = async (values) => {

        const { responseSelectItems, filePaths } = this.state;

        let status = "Open";
        if (Boolean(responseSelectItems)) {
            let fResponse = responseSelectItems.find(p => p.id === values.responseId);

            if (Boolean(fResponse)) {
                switch (fResponse.name) {
                    case "Approved":
                        status = "Closed"
                        break;
                    case "C&R":
                        status = "Open"
                        break;
                    case "Rejected":
                        status = "Closed"
                        break;

                    default:
                        break;
                }
            }
        }

        const newItem = {
            ...values,
            id: this.state.id,
            transmittalLogId: this.state.transmittalLogId,
            responseDate: values.responseDate.toDate(),
            filePath: Boolean(filePaths) ? filePaths.toString() : "",
            status: status
        }


        if (newItem.id === null) {
            var response = await handleRequest("POST", "/api/transmittalResponses", newItem);
        }
        else {
            response = await handleRequest("PUT", "/api/transmittalResponses/" + newItem.id, newItem);
        }

        if (response.type === "ERROR") {
            error(response);
        }
        else {

            showSuccess();
            this.cancel();
          
            this.props.closeModal();

            if(Boolean(this.props.close))
            this.props.close(); 
        }
    };

    createWhere(params) {
        var current = this.state.pagination.current;
        var pageSize = this.state.pagination.pageSize;
        var sortField = "", sortOrder = "";
        if (Boolean(params) && Boolean(params.pagination)) {
            current = params.pagination.current;
            pageSize = params.pagination.pageSize;


            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }

        }
        const newObj = {
            ...params.pagination,
            firstResult: current - 1, //(current - 1) * pageSize,
            maxResults: pageSize,
            sortField: sortField,
            sortOrder: sortOrder,
        };
        return newObj;
    }

    downloadFile = async (url) => {
        window.location.href = url;
    }

    showReport = async (url) => {
        try {
            const fileRealUrl = url;

            this.setState({
                pdfFile: fileRealUrl,
                modalPdfViewer: true,
            });

        } catch (error) {
            alert("File cant found.");
        }
    }






    addResponseType = async () => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: "response"
            }

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: '',
                });
                this.fillResponse();
            }
        } else {
            showError("Please enter response type");
        }
    };

    fillResponse = async () => {
        const response = await handleRequest("GET", "/api/codes/type/response");
        if (Boolean(response.data) && response.data.length > 0) {
            this.setState({
                responseSelectItems: response.data
            });
        }
        else {
            const newItem = {
                name: "Approved",
                type: "response"
            }

            await handleRequest("POST", "/api/codes", newItem);

            const newItem2 = {
                name: "C&R",
                type: "response"
            }

            await handleRequest("POST", "/api/codes", newItem2);

            const newItem3 = {
                name: "Rejected",
                type: "response"
            }

            await handleRequest("POST", "/api/codes", newItem3);

            this.fillResponse();

        }
    }


    actionTemplate(row) {

        return <React.Fragment>
            <CustomDrawingMenu row={row} onDeleteRow={this.deleteModal.bind(this)} onReviseRow={this.revise.bind(this)} onSupersedeRow={this.supersedeModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;

    }

    refreshLinkDrawingList = async (drawingSearch) => {

        const { disciplineId, drawingTypeId } = this.state;

        let obj = { disciplineId: disciplineId, drawingTypeId: drawingTypeId, drawingNo: drawingSearch, shtNo: drawingSearch };

        const response = await handleRequest("POST", "/api/transmittalResponses/search", obj);
        if (Boolean(response.data)) {
            this.setState({
                linkDrawingList: response.data
            });
        }
        else this.setState({
            linkDrawingList: []
        });

    }

    handleExportExcel = async () => {

        this.setState({

            exportClick: true,
        });

    }

    ExcelDateToJSDate = (serial) => {

        if ((serial + "").includes(".")) {

            return moment(serial, "DD.MM.YYYY").toDate()
        }
        else {

            var hours = Math.floor((serial % 1) * 24);
            var minutes = Math.floor((((serial % 1) * 24) - hours) * 60)
            return new Date(Date.UTC(0, 0, serial, hours - 17, minutes));
        }

    }

    handleUploadExcel = async (excelJson) => {
        try {
            var list = [];
            if (excelJson === null)
                showError("Select an excel file");
            else {
                excelJson.forEach(element => {


                    const obj = {
                        date: this.ExcelDateToJSDate(element["Date"]),
                        disciplineStr: element["Discipline"],
                        drawingTypeStr: element["Drawing Type"],
                        drawingNo: element["Drawing No"],
                        shtNo: element["Sheet no"],
                        revNo: element["Rev no"],
                        index: element["Index"],
                        locationStr: element["Location"],
                        materialEquipmentStr: element["Material/Equipment"],
                        description: element["Description"],
                        status: element["Status"],
                        remarks: element["Remarks"]
                    }
                    list.push(obj);
                });
                if (Boolean(list) && list.length > 0) {
                    var response = await handleRequest("POST", "/api/transmittalResponses/import", list);
                    if (response.type === "ERROR") {
                        showError("No records to be added");
                    }
                    else {
                        this.resetInputs();
                        const { pagination } = this.state;
                        this.restartTable({ pagination });
                        showSuccess();
                        this.cancel();
                    }
                } else {
                    showError("No records to be added");
                }
            }
        } catch (error) {
            showError("");
        }
    }

    openCodeModal = (type) => {

        this.setState({
            showCodeModal: true,
            codeType: type,

        })

    }

    addCode = async () => {

        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: this.state.codeType
            }

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: null,
                    showCodeModal: false
                });
                this.fillCode();
            }
        } else {
            showError("Please type value");
        }

    }

    fillCode = async () => {
        const { codeType } = this.state;

        const response = await handleRequest("GET", "/api/codes/type/" + codeType);
        if (Boolean(response.data)) {
            this.setState({
                [codeType + "SelectItems"]: response.data
            });
        }
    }

    render() {
        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout2 = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 }
        };

        const renderToolbar = (toolbarSlot) => {
            return (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexGrow: 1,
                            flexShrink: 1,
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.previousPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.nextPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomOutButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomPopover}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomInButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.downloadButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.moreActionsPopover}
                        </div>
                    </div>
                </div>
            );
        };

        const props = {
            action: `${API_BASE_ROOT}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        const layoutView = (
            isSidebarOpened,
            container,
            main,
            toolbar,
            sidebar,
        ) => {
            return defaultLayout(
                isSidebarOpened,
                container,
                main,
                toolbar(renderToolbar),
                sidebar,
            );
        };

        const revisedColumns = [
            {
                title: 'S/N', key: 'id', render: (value, row, index) => {
                    return index + 1;
                }
            },

            {
                title: 'Date', key: 'date', render: (record) => {
                    if (Boolean(record.date)) {
                        return moment(record.date).format("YYYY-MM-DD");
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <DatePicker style={{ flex: 'auto' }} value={Boolean(this.state.pagination.barcode) ? this.state.pagination.barcode : ""} placeholder="Date"
                            onChange={async (date) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    date: date,
                                    dateColor: Boolean(date) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })

                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }}
                        />
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.dateColor) ? this.state.pagination.dateColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Discipline', key: 'disciplineId', render: (record) => {
                    if (Boolean(record.discipline)) {
                        return record.discipline.name
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Dsicipline"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    disciplineId: value,
                                    disciplineColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })

                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }

                        >
                            <Option key={null} value={null}>Select</Option>
                            {this.state.disciplineSelectItems.map(i => (
                                <Option key={i.id} value={i.id}>{i.name}</Option>
                            ))}
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.disciplineColor) ? this.state.pagination.disciplineColor : "#bfbfbf" }} />,
                sorter: true,
            },
            {
                title: 'Drawing Type', key: 'drawingTypeId', render: (record) => {
                    if (Boolean(record.drawingType)) {
                        return record.drawingType.name
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Drawing Type"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    drawingTypeId: value,
                                    drawingTypeColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                            <Option key={null} value={null}>Select</Option>
                            {this.state.drawingTypeSelectItems.map(i => (
                                <Option key={i.id} value={i.id}>{i.name}</Option>
                            ))}
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.drawingTypeColor) ? this.state.pagination.drawingTypeColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Drawing No', key: 'drawingNo', render: (record) => {
                    return record.drawingNo
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Drawing No"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    drawingNo: e.target.value,
                                    drawingNoColor: Boolean(e.taget.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.drawingNoColor) ? this.state.pagination.drawingNoColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Sht No', key: 'shtNo', render: (record) => {
                    return record.shtNo
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Sht No"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    shtNo: e.target.value,
                                    shtNoColor: Boolean(e.taget.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.shtNoColor) ? this.state.pagination.shtNoColor : "#bfbfbf" }} />,
                sorter: true,
            },
            {
                title: 'Rev No', key: 'revNo', render: (record) => {
                    return record.revNo
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Rev. No"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    revNo: e.target.value,
                                    revNoColor: Boolean(e.taget.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.revNoColor) ? this.state.pagination.revNoColor : "#bfbfbf" }} />,
                sorter: true,
            },
            {
                title: 'Location', key: 'locationId', render: (record) => {
                    if (Boolean(record.location)) {
                        return record.location.name
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Location"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    locationId: value,
                                    locationColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                            <Option key={null} value={null}>Select</Option>
                            {this.state.locationSelectItems.map(i => (
                                <Option key={i.id} value={i.id}>{i.name}</Option>
                            ))}
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.locationColor) ? this.state.pagination.locationColor : "#bfbfbf" }} />,
                sorter: true,
            },
            {
                title: 'Description', key: 'description', render: (record) => {
                    return record.description
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Description"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    description: e.target.value,
                                    descriptionColor: Boolean(e.taget.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }}
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.descriptionColor) ? this.state.pagination.descriptionColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Status', key: 'status', render: (record) => {
                    if (Boolean(record.status)) {
                        return record.status
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Status"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    status: value,
                                    statusColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                            <Option key={null} value={null}>Select</Option>
                            <Option key={"Open"} value={"Open"}>{"Open"}</Option>
                            <Option key={"Close"} value={"Close"}>{"Close"}</Option>
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.statusColor) ? this.state.pagination.statusColor : "#bfbfbf" }} />,
                sorter: true,
            },



            {
                title: 'File', key: 'fileUrl', render: (record) => {

                    if (Boolean(record.fileUrl))
                        return <AntButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadFile(record.fileUrl)} type="primary" shape="round" icon={<DownloadOutlined />} >

                        </AntButton>

                },

            },
            {
                title: 'Remarks', key: 'remarks', render: (record) => {
                    return record.remarks
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Remarks"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    remarks: e.target.value,
                                    remarksColor: Boolean(e.taget.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }}
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.remarksColor) ? this.state.pagination.remarksColor : "#bfbfbf" }} />,
                sorter: true,
            }

        ];

        const columns = [
            {
                title: 'S/N', key: 'id', render: (value, row, index) => {
                    return index + 1 + (this.state.pagination.pageSize * (this.state.pagination.current - 1));
                }
            },

            {
                title: 'Date', key: 'date', render: (record) => {
                    if (Boolean(record.date)) {
                        return moment(record.date).format("YYYY-MM-DD");
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <DatePicker style={{ flex: 'auto' }} value={Boolean(this.state.pagination.barcode) ? this.state.pagination.barcode : ""} placeholder="Date"
                            onChange={async (date) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    date: date,
                                    dateColor: Boolean(date) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })

                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }}
                        />
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.dateColor) ? this.state.pagination.dateColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Discipline', key: 'disciplineId', render: (record) => {
                    if (Boolean(record.discipline)) {
                        return record.discipline.name
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Dsicipline" id="DefinitionsType"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    disciplineId: value,
                                    disciplineColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })

                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }

                        >
                            <Option key={null} value={null}>Select</Option>
                            {this.state.disciplineSelectItems.map(i => (
                                <Option key={i.id} value={i.id}>{i.name}</Option>
                            ))}
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.disciplineColor) ? this.state.pagination.disciplineColor : "#bfbfbf" }} />,
                sorter: true,
            },
            {
                title: 'Drawing Type', key: 'drawingTypeId', render: (record) => {
                    if (Boolean(record.drawingType)) {
                        return record.drawingType.name
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Drawing Type"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    drawingTypeId: value,
                                    drawingTypeColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                            <Option key={null} value={null}>Select</Option>
                            {this.state.drawingTypeSelectItems.map(i => (
                                <Option key={i.id} value={i.id}>{i.name}</Option>
                            ))}
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.drawingTypeColor) ? this.state.pagination.drawingTypeColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Drawing No', key: 'drawingNo', render: (record) => {
                    return record.drawingNo
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Drawing No"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    drawingNo: e.target.value,
                                    drawingNoColor: Boolean(e.taget.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.drawingNoColor) ? this.state.pagination.drawingNoColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Sht No', key: 'shtNo', render: (record) => {
                    return record.shtNo
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Sht No"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    shtNo: e.target.value,
                                    shtNoColor: Boolean(e.taget.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.shtNoColor) ? this.state.pagination.shtNoColor : "#bfbfbf" }} />,
                sorter: true,
            },
            {
                title: 'Rev No', key: 'revNo', render: (record) => {
                    return record.revNo
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Rev. No"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    revNo: e.target.value,
                                    revNoColor: Boolean(e.taget.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.revNoColor) ? this.state.pagination.revNoColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Index', key: 'indexx', render: (record) => {
                    return record.index
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Index"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    index: e.target.value,
                                    indexColor: Boolean(e.taget.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }}
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.indexColor) ? this.state.pagination.indexColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Location', key: 'locationId', render: (record) => {
                    if (Boolean(record.location)) {
                        return record.location.name
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Location"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    locationId: value,
                                    locationColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                            <Option key={null} value={null}>Select</Option>
                            {this.state.locationSelectItems.map(i => (
                                <Option key={i.id} value={i.id}>{i.name}</Option>
                            ))}
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.locationColor) ? this.state.pagination.locationColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Material/Equipment', key: 'materialEquipmentId', render: (record) => {
                    if (Boolean(record.materialEquipment)) {
                        return record.materialEquipment.name
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Material/Equipment"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    materialEquipmentId: value,
                                    materialEquipmentIdColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                            <Option key={null} value={null}>Select</Option>
                            {this.state.materialEquipmentSelectItems.map(i => (
                                <Option key={i.id} value={i.id}>{i.name}</Option>
                            ))}
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.materialEquipmentIdColor) ? this.state.pagination.materialEquipmentIdColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Description', key: 'description', render: (record) => {
                    return record.description
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Description"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    description: e.target.value,
                                    descriptionColor: Boolean(e.taget.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }}
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.descriptionColor) ? this.state.pagination.descriptionColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Status', key: 'status', render: (record) => {
                    if (Boolean(record.status)) {
                        return record.status
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Status"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    status: value,
                                    statusColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                            <Option key={null} value={null}>Select</Option>
                            <Option key={"Active"} value={"Active"}>{"Active"}</Option>
                            <Option key={"Superseded"} value={"Superseded"}>{"Superseded"}</Option>
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.statusColor) ? this.state.pagination.statusColor : "#bfbfbf" }} />,
                sorter: true,
            },



            {
                title: 'File', key: 'fileUrl', render: (record) => {

                    if (Boolean(record.fileUrl))
                        return <AntButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadFile(record.fileUrl)} type="primary" shape="round" icon={<DownloadOutlined />} >

                        </AntButton>

                },

            },
            {
                title: 'Remarks', key: 'remarks', render: (record) => {
                    return record.remarks
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Remarks"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    remarks: e.target.value,
                                    remarksColor: Boolean(e.taget.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }}
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.remarksColor) ? this.state.pagination.remarksColor : "#bfbfbf" }} />,
                sorter: true,
            },
            {
                title: 'Linked Drawings', key: 'key', render: (record) => {
                    return <PageviewIcon fontSize="default" color="primary" backgroundColor="black" onClick={() => this.showLinkedList(record)} />
                }
            },

            {
                title: 'Revised Versions', key: 'key', render: (record) => {
                    return <PageviewIcon fontSize="default" color="primary" backgroundColor="black" onClick={() => this.showRevisedList(record)} />
                }
            },

            {
                title: 'Actions', key: 'name', render: (record) => {
                    return this.actionTemplate(record);
                }
            },


        ];

        const excelExport = <>
            {/* {this.state.exportClick ?
                this.setState({
                    exportClick: false
                }) : ""}
            {this.state.exportClick ?
                <ExcelFile filename="Drawing List" hideElement={true}>
                    <ExcelSheet name="Drawing List" data={this.state.tableList}>
                        <ExcelColumn label="S/N" value="sn" />
                        <ExcelColumn label="Date" value={(col) => Boolean(col.date) ? moment(col.date).format("DD.MM.YYYY") : null} />
                        <ExcelColumn label="Discipline" value={(col) => Boolean(col.discipline) ? col.discipline.name : ""} />
                        <ExcelColumn label="Drawing Type" value={(col) => Boolean(col.drawingType) ? col.drawingType.name : ""} />
                        <ExcelColumn label="Drawing No" value="drawingNo" />
                        <ExcelColumn label="Sheet no" value="shtNo" />
                        <ExcelColumn label="Rev no" value="revNo" />
                        <ExcelColumn label="Index" value="index" />
                        <ExcelColumn label="Location" value={(col) => Boolean(col.location) ? col.location.name : ""} />
                        <ExcelColumn label="Material/Equipment" value={(col) => Boolean(col.materialEquipment) ? col.materialEquipment.name : ""} />
                        <ExcelColumn label="Description" value="description" />
                        <ExcelColumn label="Status" value="status" />
                        <ExcelColumn label="Remarks" value="remarks" />
                        <ExcelColumn label="Last Update" value={(col) => moment(col.lastUpdated).format("DD-MM-YYYY HH:mm")} />
                    </ExcelSheet>
                </ExcelFile> :
                <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
            } */}
        </>

        return (
            <div hidden={this.state.hideInputs} className="card card-custom" style={{ padding: '2rem' }}>

                <Form
                    initialValues={{ remember: false }}
                    onFinish={this.save}
                    onFinishFailed={onFinishFailed}
                    ref={this.formRef}>



                    <div>

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Response Date" />}
                                name="responseDate"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please select date" /> }]} >
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Response" />}
                                name="responseId"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="This field is required" /> }]} >

                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }} placeholder="Response"
                                    onchange={(value) => {
                                        let status = "Open";
                                        const { responseSelectItems } = this.state;
                                        if (Boolean(responseSelectItems)) {
                                            let fResponse = responseSelectItems.find(p => p.id === value);

                                            if (Boolean(fResponse)) {
                                                switch (fResponse.name) {
                                                    case "Approved":
                                                        status = "Closed"
                                                        break;
                                                    case "C&R":
                                                        status = "Open"
                                                        break;
                                                    case "Rejected":
                                                        status = "Closed"
                                                        break;

                                                    default:
                                                        break;
                                                }
                                            }
                                        }
                                        this.formRef.setFieldsValue({
                                            status: status
                                        })
                                    }}
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>

                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                    onClick={() => this.openCodeModal("response")}>
                                                    <PlusOutlined /> Add New
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    <Option key={null} value={null}>Select</Option>
                                    {this.state.responseSelectItems.map(i => (
                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        }



                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Remarks" />}
                                name="remarks"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type remarks" /> }]} >

                                <Input placeholder="Remarks" />

                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Status" />}
                                name="status"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="This field is required" /> }]} >

                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }} placeholder="Status" >

                                    <Option key={null} value={null}>Select</Option>
                                    <Option key={"Open"} value={"Open"}>{"Open"}</Option>
                                    <Option key={"Closed"} value={"Closed"}>{"Closed"}</Option>

                                </Select>


                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Reference No" />}
                                name="referenceNo"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type remarks" /> }]} >

                                <Input placeholder="Reference No" />

                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Upload" />}
                                name="filePath"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please upload file" /> }]}
                            >
                                <Upload {...props} id="path"
                                    accept=".png,.PNG,.jpeg,.JPEG,.jpg,.JPG,.pdf,.PDF"
                                    showUploadList={true}
                                    multiple
                                    fileList={this.state.fileList}
                                    onChange={({ fileList }) => {
                                        var filePaths = [];
                                        const stateFileList = this.state.fileList;
                                        if (fileList.length >= 1) {
                                            for (let index = 0; index < fileList.length; index++) {

                                                if (Boolean(fileList[index].response)) {
                                                    filePaths[index] = fileList[index].response.url;
                                                    fileList[index].url = fileList[index].response.url;

                                                    if (filePaths.length === fileList.length && fileList.length > stateFileList.length)
                                                        this.showSuccess("Files uploaded successfully");

                                                } else
                                                    if (Boolean(fileList[index].url)) {
                                                        filePaths[index] = fileList[index].url;

                                                    }

                                            }

                                        }

                                        this.setState({
                                            fileList: fileList,
                                            filePaths: filePaths,
                                        });

                                    }}
                                    beforeUpload={(fileList) => {

                                        return true;
                                    }}
                                    onRemove={({ fileList }) => {


                                    }
                                    }>
                                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                        <UploadOutlined /> Upload
                                    </AntButton>
                                </Upload>
                            </Form.Item>
                        }


                        <div hidden={this.state.hideSave}>
                            {
                                <Row gutter={8}>
                                    <Col span={8}></Col>

                                    <Col span={8}>
                                        {
                                            <Button id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                                        }
                                    </Col>
                                </Row>

                            }
                        </div>

                        <div hidden={this.state.hideUpdate}>
                            {
                                <Row gutter={8}>
                                    <Col span={8}></Col>

                                    <Col span={4}>
                                        <Button id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                                    </Col>
                                    <Col span={4}>
                                        <Button id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                                    </Col>

                                </Row>
                            }
                        </div>
                    </div>
                </Form>


                <Modal
                    show={this.state.showCodeModal}
                    onHide={() => {
                        this.setState({
                            showCodeModal: false
                        })
                    }}

                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"> Add item to dropdown </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Input placeholder="Type Value" id="modalCodeName" value={this.state.codeName} onChange={(e) => this.setState({ codeName: e.target.value })} />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showCodeModal: false,
                                codeName: null
                            })
                        }}>Close</Button>

                        <Button variant="primary" onClick={this.addCode} > Save </Button>

                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalDeleteShow}
                    onHide={this.showOrHideDeleteModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</Button>
                        <Button variant="danger" onClick={this.delete} >Delete</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalSupersedeShow}
                    onHide={() => {
                        this.setState({
                            modalSupersedeShow: false
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Chosen record will be superseded !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {Boolean(this.state.selectedRow) && <p>Do you want to supersede Drawing no: {this.state.selectedRow.drawingNo}, Sht. no: {this.state.selectedRow.shtNo}, Rev. No: {this.state.selectedRow.revNo}</p>
                        }
                        <p> Date Of Supersede :  <DatePicker style={{ flex: 'auto' }} value={this.state.dateOfSupersede} placeholder="Date"
                            onChange={(date) => {
                                this.setState({
                                    dateOfSupersede: date
                                })
                            }}
                        /> </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.supersede} >Yes</Button>
                        <Button variant="warning" onClick={() => {
                            this.setState({
                                modalSupersedeShow: false
                            })
                        }} > No</Button>

                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalSupersedeAction}
                    onHide={() => {
                        this.setState({
                            modalSupersedeAction: false,
                            linkedDrawingIds: []
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Is there any drawing !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Is there any drawing to be replaced with superseded drawing?</p>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => {

                            this.setState({
                                modalSupersedeAction: false,
                            })
                            this.onClickCreateNew();
                        }} >Yes</Button>
                        <Button variant="warning" onClick={() => {
                            this.setState({
                                modalSupersedeAction: false,
                                linkedDrawingIds: []
                            })
                        }} > No</Button>

                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showRevisedListModal}
                    onHide={() => {
                        this.setState({
                            showRevisedListModal: false
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Revised Drawings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table
                            bordered
                            scroll={{ x: 400, scrollToFirstRowOnChange: true }}
                            columns={revisedColumns}
                            dataSource={this.state.revisedFileList} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showRevisedListModal: false
                            })
                        }}>Close</Button>

                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showLinkedListModal}
                    onHide={() => {
                        this.setState({
                            showLinkedListModal: false
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Linked Drawings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table
                            bordered
                            scroll={{ x: 400, scrollToFirstRowOnChange: true }}
                            columns={revisedColumns}
                            dataSource={this.state.revisedFileList} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showLinkedListModal: false
                            })
                        }}>Close</Button>

                    </Modal.Footer>
                </Modal>


                <Modal
                    size="xl"
                    show={this.state.modalPdfViewer}
                    onHide={this.showOrHideModalPdfViewer}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            PDF Report Viewer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                            <div style={{ height: "900px" }}>
                                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                            </div>
                        </Worker>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.showOrHideModalPdfViewer}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    console.log("YYY", state);

    return {
        // auth: state.auth,
        logout: state.logout,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TransmittalResponse)
// export default connect(null, auth.actions)(Domain);
