import "antd/dist/antd.css";
import moment from "moment";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Button as AntButton, DatePicker, Card, InputNumber, Skeleton, Checkbox, Select, Steps, Typography, Radio } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../../redux/store";
import { HistoryOutlined } from "@ant-design/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { LoadingOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Step } = Steps;
const { Option } = Select;

export class CompanyProjectStatus extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "CompanyProjectStatus",
      controllerName: "companyProjectStatus",
      id: null,
      loading: false,
      loadingCard: true,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: true,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      fileList: [],
      nodes: [],
      type: "",
      revNo: 0,
      initialValues: {
        date: null,
        status: null,
        justification: null,
        notify: null
      },

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    this.getStatus();

    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getAllStatus/" + this.props.companyProjectId);
    if (response.type === "ERROR") {
      error(response);
    }
    this.setState({
      revNo: Boolean(response.data) ? response.data.revNo : 0,
      loadingCard: true,
      hideUpdate: true,
      hideSave: true,
      hideInputs: true,
      initialValues: {
        ...response.data,
        date: Boolean(response.data) && Boolean(response.data.date) ? moment(response.data.date).format("DD-MM-YYYY HH:mm") : null
      }
    });

    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.setFieldsValue({
        ...response.data,
        date: Boolean(response.data) && Boolean(response.data.date) ? moment(response.data.date) : null
      });
    }
  };

  fillComboboxes = async () => {};

  fillStatusByRevision = async () => {
    const { revNo } = this.state;
    if (Boolean(revNo) && revNo > 0) {
      var response = await handleRequest(
        "GET",
        "/api/" + this.state.controllerName + "/fillStatusByRevision/" + this.props.companyProjectId + "/" + revNo
      );
      if (response.type === "ERROR") {
        error(response);
      }
      this.setState({
        id: Boolean(response.data) ? response.data.id : null,
        initialValues: {
          ...response.data,
          date: Boolean(response.data) && Boolean(response.data.date) ? moment(response.data.date).format("DD-MM-YYYY HH:mm") : null
        }
      });
      this.formRef.current.setFieldsValue({
        ...response.data,
        date: Boolean(response.data) && Boolean(response.data.date) ? moment(response.data.date) : null
      });
    } else {
      showWarning("No previous records found.");
      this.formRef.current.resetFields();
      this.setState({
        id: null
      });
    }
  };

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true,
        loadingCard: false
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false,
        loadingCard: true
      });
    }
  };

  save = async values => {
    let { companyProjectId } = this.props;
    const newItem = {
      ...values,
      id: this.state.id,
      companyProjectId: companyProjectId
    };
    console.log("newItem", newItem);
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      showSuccess();
      if (Boolean(this.props) && Boolean(this.props.close)) {
        this.props.close();
      }
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  getStatus = async row => {
    let url = "/api/" + this.state.controllerName + "/getStatus/" + this.props.companyProjectId;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        statusList: response.data,
        modalStatusShow: true
      });
    }
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 12 },
      wrapperCol: { span: 8 }
    };
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[20, 20]}>
            <Col md={22}></Col>
            <Col md={1}>
              {Boolean(this.state.revNo) && this.state.revNo > 0 && (
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => {
                    this.setState(
                      {
                        revNo: this.state.revNo - 1
                      },
                      () => {
                        this.fillStatusByRevision();
                      }
                    );
                  }}
                  icon={<HistoryOutlined fontSize="large" style={{ border: "0px", color: "#007bff" }} />}
                ></AntButton>
              )}
            </Col>
            <Col md={1}>
              <AntButton
                tooltip={"Create New"}
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>
          <div hidden={false}>
            <Row gutter={24} style={{ marginBottom: "16px" }}>
              <br></br>
              {/* Sol Card */}

              <Col span={12}>
                <Card
                  loading={!this.state.loadingCard}
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    height: "100%",
                    marginLeft: 0,
                    marginRight: "auto"
                  }}
                >
                  <div className="card card-custom" style={{ padding: "0.1rem", minHeight: "100%" }}>
                    <div className="card-body pt-4 custom-timeline pr-5 pl-5 mr-5 ml-5 align-content-center">
                      <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                      <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                      <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                      <Steps direction="vertical" className="pl-5-custom anticon-custom">
                        {Boolean(this.state.statusList) &&
                          this.state.statusList.length > 0 &&
                          this.state.statusList.map((item, index) => {
                            return item.spin ? (
                              <Step status="process" title={item.status + (item.count >= 0 ? " - " + item.count : "")} icon={<LoadingOutlined />} />
                            ) : (
                              <Step
                                title={item.status + (item.count >= 0 ? " - " + item.count : "")}
                                status={item.color === "green" ? "finish" : item.color === "blue" ? "process" : "wait"}
                                description={item.color === "green" || item.color === "blue" ? moment(item.date).format("DD-MM-YYYY HH:mm:ss") : ""}
                              />
                            );
                          })}
                      </Steps>
                    </div>
                  </div>
                </Card>
              </Col>

              {/* Sağ Card */}
              <Col span={12}>
                <Card
                  loading={this.state.loadingCard}
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="CompanyProjectStatus.Date" defaultMessage="Date" />}
                    name="date"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
                  </Form.Item>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="CompanyProjectStatus.Type" defaultMessage="Type" />}
                    name="status"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Radio.Group
                      onChange={e => {
                        this.setState({
                          status: e.target.value
                        });
                      }}
                    >
                      <Radio value={"Cost_Calculation"}>
                        <FormattedMessage id="CompanyProjectStatus.CostCalculation" defaultMessage="Cost Calculation" />
                      </Radio>
                      <Radio value={"Tendering"}>
                        <FormattedMessage id="CompanyProjectStatus.Tendering" defaultMessage="Tendering" />
                      </Radio>
                      <Radio value={"On_Hold"}>
                        <FormattedMessage id="CompanyProjectStatus.OnHold" defaultMessage="On Hold" />
                      </Radio>
                      <Radio value={"Cancelled"}>
                        <FormattedMessage id="CompanyProjectStatus.Cancel" defaultMessage="Cancel" />
                      </Radio>
                      <Radio value={"Liquidated"}>
                        <FormattedMessage id="CompanyProjectStatus.Liquidated" defaultMessage="Liquidated" />
                      </Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="CompanyProjectStatus.Justification" defaultMessage="Justification" />}
                    name="justification"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                  </Form.Item>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="CompanyProjectStatus.Notify" defaultMessage="Notify" />}
                      name="notifyId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select style={{ width: "100%" }} allowClear showSearch optionFilterProp="children">
                        {Boolean(this.state["cityIdSelectItems"]) &&
                          this.state["cityIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                </Card>
              </Col>
            </Row>
          </div>

          <div hidden={this.state.hideSave}>
            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            }
          </div>
        </Form>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyProjectStatus);
