import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card, Button, Button as ReactButton, Col, Row, Modal, Form as FormBoot} from 'react-bootstrap';
import {
    CloseOutlined,
    PlusOutlined,
    RightCircleOutlined,
    UndoOutlined,
    CloseCircleOutlined,
    FilterOutlined, DownloadOutlined, UploadOutlined, SaveOutlined
} from '@ant-design/icons';
import {Checkbox, Button as AntButton, Divider, DatePicker, Input, Upload} from 'antd';
import {InputNumber, Space, Select, Table, Form, Switch, TimePicker} from 'antd';
import {handleRequest} from "../../ApiConnector";
import {API_BASE_ROOT} from "../../ApiConnector";
import {Redirect} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import {IconButton} from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import 'react-contexify/dist/ReactContexify.min.css';
import {Menu, Item, MenuProvider} from 'react-contexify';
import moment from 'moment';
import PageviewIcon from "@material-ui/icons/Pageview";
import store from "../../../redux/store";
import DataTableComponent2 from "../../Components/DataTableComponent2";
import {GetDynamicFormItems} from "../../Components/ElementUtils";
import FormTopRightButtonsComponent from "../../Components/FormTopRightButtonsComponent";
import CreateIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import ElementsProvider from "../../Components/ElementsProvider";
import {PrepareRowForEdit, PrepareRowForSave} from "../../Components/ElementUtils";
import FormSaveButtonsComponent from "../../Components/FormSaveButtonsComponent";
import {error, showError, showSuccess} from "../../MessageHelper";

const {Option} = Select;
const {Column} = Table;

const _DurationTypes = [
    {label: "Day", value: 3},
    {label: "Hour", value: 4},
    {label: "Minute", value: 5},
];

export class OvertimeRequest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: store.getState().auth.user,
            formName: 'overtimeRequest',
            restartTable: false,
            pageFormData: null,
            hidden: true,
            hiddenSave: false,
            hiddenUpdate: true,
            selectedClinic: null,
            overtimeRequests: [],
            selectedOvertimeRequests: [],
            overtimeDurationType: true,
            staffs: [],
            equipmentTypes: [],
            transportationList: [],
            places: [],
            requester: ["Individual"],
            location: "",
            activity: "",
            durationObj: {},
            duration: "",
            durationNumber: "",
            durationType: null,
            durationTypes: _DurationTypes.slice(),
            overtimeRequestEmployeeObjects: [],
            overtimeRequestAdditionalEmployeeObjects: [],
            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            modalHowToUse: false,
            howToUseThis: "",
            modalMNDSettings: false,
            modalSettings: false,
            howToUseSetting: false,
            whatIsSetting: false,
            visibleWhatis: false,
            visibleHowToUse: false,
            modalShowWhatIs: false,
            topX: 0,
            leftY: 0,
            selectID: "",
            selectURI: "",
            inputDescription: "",
            userSettings: null,
            formId: null,
            selectedRowKeys: [],
            exportClick: false,
            hideUpdate: true,
            hideSave: false,
            hideInputs: true,
            isPromptModalVisible: false,

        };

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarning = this.showWarning.bind(this);
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        let data = await GetDynamicFormItems(this.state.formName);
        this.setState({
            pageFormData: data
        });

        this.setState({
            pageFormData: data
        });
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull."/>;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1"
                                    defaultMessage="An error was occured please try again later!"/>;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs"
                                    defaultMessage="Something went wrong. Please check inputs."/>;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
    }

    showHowToUse = async () => {
        if (this.state.formId !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + this.state.formId);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    restartTable = async (params = {}) => {
        this.setState({restartTable: !this.state.restartTable});
    };

    cancel = () => {
        this.setState({
            hideUpdate: true,
            hideSave: false,
            hideInputs: true
        });
        this.resetInputs();
    };

    onPersonnelSave = values => {
        this.setState({
            id: values.id,
            currentPersonnelName: values.name
        });
        this.restartTable();
    };

    resetInputs = () => {
        this.setState({
            id: null,
            currentRow: null,
            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
                total: this.state.totalRecords
            },
            loading: false,
            sortField: "",
            sortOrder: "",
            filePath: "",
            fileList: []
        });
    };

    edit = row => {
        document.getElementById("kt_scrolltop").click();
        this.setState({
            hideUpdate: false,
            hideSave: true,
            hideInputs: false,
            id: row.id,
            currentPersonnelName: row.name + " " + (Boolean(row.midName) ? row.midName : "") + " " + row.lastName,
            currentRow: row,
            isPrompt: false
        });
    };

    deleteModal = (row) => {
        this.setState({
            deleteModal: true,
            selectedOvertimeRequest: row
        });
    }

    showOrHideModalDelete = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    deleteOvertimeRequestOK = async () => {
        const deleteOvertimeRequest = {
            id: this.state.selectedOvertimeRequest.id
        }
        var response = await handleRequest("POST", "/OvertimeRequest/delete", deleteOvertimeRequest);
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.restartTable();
                this.showSuccess();
                this.showOrHideModalDelete();
            } else {
                this.showError();
            }
        }
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    onClickCreateNew = () => {
        this.resetInputs();
        if (this.state.hideInputs) {
            this.setState({
                hideInputs: false,
                isPromptModalVisible: false
            });
        } else {
            this.setState({
                id: null,
                currentRow: null,
                hideInputs: true,
                isPromptModalVisible: false
            });
        }
    };

    stopProcess = () => {
        this.resetInputs();
    }

    save = async values => {
        let newItem = {
            ...values
        };
        newItem = PrepareRowForSave(values, this.state.pageFormData.pageFormInputCompanyList);
        newItem.id = this.state.id;
        console.log(newItem);
        if (newItem.id === null) {
            var response = await handleRequest("POST", "/api/OvertimeRequest", newItem);
        } else {
            response = await handleRequest("PUT", "/api/OvertimeRequest/" + newItem.id, newItem);
        }

        if (response.type === "ERROR") {
            error(response);
        } else {
            showSuccess();
            if (Boolean(this.props) && Boolean(this.props.onSave)) {
                let obj = {
                    id: response.data,
                    name: newItem.name + " " + (Boolean(newItem.midName) ? newItem.midName : "") + " " + newItem.lastName
                };
                if (Boolean(newItem.id)) {
                    obj.id = newItem.id;
                }
                this.props.onSave(obj);
                document.getElementById("kt_scrolltop").click();
            }
        }
    };

    saveOvertimeRequest = async () => {
        let flagOvertimeRequestEmployees = [];
        let flagOvertimeRequestAdditionalEmployees = [];

        for (let index = 0; index < this.state.staff.length; index++) {
            flagOvertimeRequestEmployees.push({
                staffId: this.state.staff[index]
            });
        }

        for (let index = 0; index < this.state.additionalStaff.length; index++) {
            flagOvertimeRequestAdditionalEmployees.push({
                staffId: this.state.additionalStaff[index]
            });
        }

        const addOvertimeRequest = {
            requester: this.state.requester.join(),
            location: this.state.location,
            activity: this.state.activity,
            overtimeRequestEmployees: flagOvertimeRequestEmployees,
            overtimeRequestAdditionalEmployees: flagOvertimeRequestAdditionalEmployees,
            overtimeDuration: this.state.durationObj,
            overtimeDurationType: this.state.overtimeDurationType,
            overtimeRequestStartDate: this.state.startDateTime,
            overtimeRequestEndDate: this.state.endDateTime,
            justification: this.state.justification,
            transportation: this.state.transportation,
            vehicleId: this.state.vehicleId,
            shuttle: this.state.shuttle,
            timeOfDepart: this.state.timeOfDepart,
            food: this.state.food,
            timeForDining: this.state.timeForDining,
            placeId: this.state.placeId
        }

        var response = await handleRequest("POST", "/OvertimeRequest/add", addOvertimeRequest);
        if (response.data !== null && response.data !== undefined) {
            if (response.data !== "0" && response.data !== 0) {
                this.resetInputs();
                this.restartTable();
                this.showSuccess();
            } else {
                this.showError();
            }
        }
    }

    updateOvertimeRequest = async () => {
        if (this.state.selectedOvertimeRequests !== null) {

            let flagOvertimeRequestEmployees = [];
            let oreValues = [];
            let flagOvertimeRequestAdditionalEmployees = [];
            let oraeValues = [];

            for (let index = 0; index < this.state.overtimeRequestEmployeeObjects.length; index++) {
                oreValues.push(this.state.overtimeRequestEmployeeObjects[index].staffId);
            }

            for (let index = 0; index < this.state.overtimeRequestEmployeeObjects.length; index++) {
                if (this.state.staff.includes(oreValues[index])) {
                    flagOvertimeRequestEmployees.push({
                        id: this.state.overtimeRequestEmployeeObjects[index].id,
                        active: true,
                        staffId: this.state.overtimeRequestEmployeeObjects[index].staffId,
                    });
                } else {
                    flagOvertimeRequestEmployees.push({
                        id: this.state.overtimeRequestEmployeeObjects[index].id,
                        active: false,
                        staffId: this.state.overtimeRequestEmployeeObjects[index].staffId,
                    });
                }
            }

            for (let index = 0; index < this.state.staff.length; index++) {
                if (!oreValues.includes(this.state.staff[index])) {
                    flagOvertimeRequestEmployees.push({
                        staffId: this.state.staff[index],
                    });
                }
            }

            for (let index = 0; index < this.state.overtimeRequestAdditionalEmployeeObjects.length; index++) {
                oraeValues.push(this.state.overtimeRequestAdditionalEmployeeObjects[index].staffId);
            }

            for (let index = 0; index < this.state.overtimeRequestAdditionalEmployeeObjects.length; index++) {
                if (this.state.additionalStaff.includes(oraeValues[index])) {
                    flagOvertimeRequestAdditionalEmployees.push({
                        id: this.state.overtimeRequestAdditionalEmployeeObjects[index].id,
                        active: true,
                        staffId: this.state.overtimeRequestAdditionalEmployeeObjects[index].staffId,
                    });
                } else {
                    flagOvertimeRequestAdditionalEmployees.push({
                        id: this.state.overtimeRequestAdditionalEmployeeObjects[index].id,
                        active: false,
                        staffId: this.state.overtimeRequestAdditionalEmployeeObjects[index].staffId,
                    });
                }
            }

            for (let index = 0; index < this.state.additionalStaff.length; index++) {
                if (!oraeValues.includes(this.state.additionalStaff[index])) {
                    flagOvertimeRequestAdditionalEmployees.push({
                        staffId: this.state.additionalStaff[index],
                    });
                }
            }

            const updatedOvertimeRequests = {
                id: this.state.selectedOvertimeRequests.id,
                requester: this.state.requester.join(),
                location: this.state.location,
                activity: this.state.activity,
                overtimeRequestEmployees: flagOvertimeRequestEmployees,
                overtimeRequestAdditionalEmployees: flagOvertimeRequestAdditionalEmployees,
                overtimeDuration: this.state.durationObj,
                overtimeDurationType: this.state.overtimeDurationType,
                overtimeRequestStartDate: this.state.startDateTime,
                overtimeRequestEndDate: this.state.endDateTime,
                justification: this.state.justification,
                transportation: this.state.transportation,
                vehicleId: this.state.vehicleId,
                shuttle: this.state.shuttle,
                timeOfDepart: this.state.timeOfDepart,
                food: this.state.food,
                timeForDining: this.state.timeForDining,
                placeId: this.state.placeId
            }

            console.log(updatedOvertimeRequests);

            var response2 = await handleRequest("POST", "/OvertimeRequest/update", updatedOvertimeRequests);
            if (response2.data !== null && response2.data !== undefined) {
                if (response2.data === "SUCCESS") {
                    this.resetInputs();
                    this.setState({
                        selectedClinic: null,
                        hiddenSave: true,
                        hiddenUpdate: false
                    });
                    this.restartTable();
                    this.showSuccess();
                } else {
                    this.showError();
                }
            } else {
                this.showError();
            }
        } else {
            this.showWarning();
        }
    }

    changeButtonUpdate = (row) => {

        console.log(row);

        let _employees = [];
        let _employeesValue = [];
        let _additionalEmployees = [];
        let _additionalEmployeesValue = [];

        if (row.overtimeRequestEmployees !== undefined)
            if (row.overtimeRequestEmployees.length > 0) {
                for (let index = 0; index < row.overtimeRequestEmployees.length; index++) {
                    _employees.push(row.overtimeRequestEmployees[index].overtimeRequestEmployee.name +
                        " " + row.overtimeRequestEmployees[index].overtimeRequestEmployee.midName +
                        " " + row.overtimeRequestEmployees[index].overtimeRequestEmployee.lastName);
                    _employeesValue.push(row.overtimeRequestEmployees[index].staffId);
                }
            }

        if (row.overtimeRequestAdditionalEmployees !== undefined)
            if (row.overtimeRequestAdditionalEmployees.length > 0) {
                for (let index = 0; index < row.overtimeRequestAdditionalEmployees.length; index++) {
                    _additionalEmployees.push(row.overtimeRequestAdditionalEmployees[index].overtimeRequestAdditionalEmployee.name +
                        " " + row.overtimeRequestAdditionalEmployees[index].overtimeRequestAdditionalEmployee.midName +
                        " " + row.overtimeRequestAdditionalEmployees[index].overtimeRequestAdditionalEmployee.lastName);
                    _additionalEmployeesValue.push(row.overtimeRequestAdditionalEmployees[index].staffId);
                }
            }

        this.setDuration(row.overtimeDuration);

        this.formRef.current.setFieldsValue({
            OvertimeRequestRequester: row.requester.split(","),
            Location: row.location,
            Activity: row.activity,
            StartDateTime: moment(row.overtimeRequestStartDate),
            Justification: row.justification,
            Transportation: row.transportation,
            Vehicle: row.vehicle.equipmentDescription,
            Shuttle: row.shuttle,
            TimeOfDepart: moment(row.timeOfDepart, "HH:mm"),
            Food: row.food,
            TimeForDining: moment(row.timeForDining, "HH:mm"),
            Place: row.codePlace.name
        });

        if (row.overtimeRequestEmployees !== undefined) {
            this.formRef.current.setFieldsValue({
                Employee: _employeesValue
            });
        }

        if (row.overtimeRequestAdditionalEmployees !== undefined) {
            this.formRef.current.setFieldsValue({
                AdditionalEmployee: _additionalEmployeesValue
            });
        }

        if (row.overtimeRequestEndDate !== null) {
            this.formRef.current.setFieldsValue({
                EndDateTime: moment(row.overtimeRequestEndDate),
            });

            this.setState({
                endDateTime: moment(row.overtimeRequestEndDate)._d,
            });
        } else {
            this.formRef.current.setFieldsValue({
                EndDateTime: null,
            });

            this.setState({
                endDateTime: null,
            });
        }


        this.setState({
            hidden: false,
            hiddenSave: true,
            hiddenUpdate: false,
            selectedOvertimeRequests: row,
            overtimeDurationType: true,
            requester: row.requester.split(","),
            location: row.location,
            activity: row.activity,
            staff: _employeesValue,
            overtimeRequestEmployeeObjects: row.overtimeRequestEmployees,
            overtimeRequestAdditionalEmployeeObjects: row.overtimeRequestAdditionalEmployees,
            additionalStaff: _additionalEmployeesValue,
            durationObj: row.overtimeDuration,
            durationTypes: _DurationTypes.slice(),
            startDateTime: moment(row.overtimeRequestStartDate)._d,
            justification: row.justification,
            transportation: row.transportation,
            vehicleId: row.vehicleId,
            shuttle: row.shuttle,
            timeOfDepart: row.timeOfDepart,
            food: row.food,
            timeForDining: row.timeForDining,
            placeId: row.placeId
        });
    }

    changeButtonCancel = () => {
        this.formRef.current.resetFields();

        this.formRef.current.setFieldsValue({
            OvertimeRequestRequester: "Individual"
        });

        this.setState({
            hidden: true,
            hiddenSave: false,
            hiddenUpdate: true,
            selectedOvertimeRequests: null,
            overtimeDurationType: true,
            requester: ["Individual"],
            location: "",
            activity: "",
            staff: [],
            additionalStaff: [],
            duration: "",
            durationNumber: "",
            durationType: null,
            durationObj: {},
            durationTypes: _DurationTypes.slice(),
            startDateTime: null,
            endDateTime: null,
            justification: "",
            transportation: false,
            vehicleId: "",
            shuttle: false,
            timeOfDepart: null,
            food: false,
            timeForDining: null,
            placeId: ""
        });
    }

    onStaffChange = (value) => {
        this.setState({
            staff: value
        });
    }

    onAdditionalStaffChange = (value) => {
        this.setState({
            additionalStaff: value
        });
    }

    disabledDate = (current) => {
        return current < moment().startOf('day');
    }

    getUsers = async () => {
        const responseStaffs = await handleRequest("GET", "/Staff/getCompanyItems");

        if (responseStaffs.data.length !== 0) {
            this.setState({
                staffs: responseStaffs.data
            });
        }

        if (responseStaffs.data.length === 0) {
            this.setState({
                staffs: []
            });
        }
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    handleChangeLocation = (value) => {
        this.setState({
            location: value
        });
    }

    handleChangeActivity = (value) => {
        this.setState({
            activity: value
        });
    }

    onChangeStartDateTime = async (date, dateString) => {
        if (date !== null) {
            date._d.setSeconds(0);

            this.setState({
                startDateTime: date._d
            });
        } else {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy + ' 00:00:00';

            this.setState({
                startDateTime: today
            });
        }
    }

    onChangeEndDateTime = async (date, dateString) => {
        if (date !== null) {
            date._d.setSeconds(0);

            this.setState({
                endDateTime: date._d
            });
        } else {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy + ' 00:00:00';

            this.setState({
                endDateTime: today
            });
        }
    }

    onVehicleChange = (value) => {
        this.setState({
            vehicleId: value
        });

        let flagVehicle = this.state.transportationList;

        for (let index = 0; index < flagVehicle.length; index++) {
            if (flagVehicle[index].id === value) {
                this.formRef.current.setFieldsValue({
                    Vehicle: flagVehicle[index].id
                });
            }
        }
    }

    onChangeTimeOfDepartDateTime = async (time) => {
        let timeString = "";

        if (time !== null) {
            timeString = time._d.getHours() + ":" + time._d.getMinutes()

            this.setState({
                timeOfDepart: timeString
            });
        } else {
            var today = new Date();
            timeString = today.getHours() + ":" + today.getMinutes();

            this.setState({
                timeOfDepart: timeString
            });
        }
    }

    onChangeTimeForDiningDateTime = async (time) => {
        let timeString = "";

        if (time !== null) {
            timeString = time._d.getHours() + ":" + time._d.getMinutes()

            this.setState({
                timeForDining: timeString
            });
        } else {
            var today = new Date();
            timeString = today.getHours() + ":" + today.getMinutes();

            this.setState({
                timeForDining: timeString
            });
        }
    }

    handleChangePlace = (e) => {
        var value = "";
        for (var z = 0; z < this.state.places.length; z++) {
            if (this.state.places[z].id == e) {
                value = this.state.places[z].name;
            }
        }
        this.setState({
            place: value,
            placeId: e,
        });
    }

    addNewPlace = async () => {
        const {newPlace} = this.state;
        if (newPlace != null && newPlace != "" && newPlace != undefined) {
            const newItem = {
                name: newPlace,
                type: "OvertimeRequestPlace"
            }

            await handleRequest("POST", "/Code/add", newItem);
            this.setState({
                newPlace: "",
            });
            this.getCodesPlace();
        } else {
            this.showWarning();
        }
    }

    getCodesPlace = async () => {
        const response = await handleRequest("GET", "/Code/type/OvertimeRequestPlace");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                places: response.data
            });
        }
    }

    calculateDuration = () => {
        var myObj = this.state.durationObj;
        var types = this.state.durationTypes;
        const index = this.state.durationTypes.findIndex(p => p.value === this.state.durationType);
        if (index >= 0 && this.state.durationNumber > 0) {
            var durationDesc = this.state.duration + this.state.durationNumber + " " + types[index].label + "s ";
            myObj[types[index].label.toLowerCase()] = this.state.durationNumber;
            types.splice(0, index + 1);

            this.setState({
                durationObj: myObj,
                duration: durationDesc,
                durationTypes: types,
                durationType: null,
            });
        } else {
            this.showWarning("Please type duration inputs!");
        }
    }

    undoDuration = () => {
        if (this.state.duration.trim() !== "") {
            var pObj = this.state.durationObj;
            if (pObj !== null) {
                if (pObj.minute && pObj.minute > 0)
                    pObj.minute = 0;
                else if (pObj.hour && pObj.hour > 0)
                    pObj.hour = 0;
                else if (pObj.day && pObj.day > 0)
                    pObj.day = 0;
                else pObj = {};
            }

            var types = _DurationTypes.slice();
            var durationDesc = this.getDurationDesc(pObj);
            var index = -1;

            if (pObj !== {}) {
                if (pObj.minute && pObj.minute > 0)
                    index = 2;
                else if (pObj.hour && pObj.hour > 0)
                    index = 1;
                else if (pObj.day && pObj.day > 0)
                    index = 0;
            }

            types.splice(0, index + 1);

            this.setState({
                durationObj: pObj,
                duration: durationDesc,
                durationTypes: types
            });
        } else {
            this.showWarning("Please firstly type any duration!");
        }
    }

    removeDuration = () => {
        this.setState({
            durationObj: {},
            duration: "",
            durationNumber: "",
            durationTypes: _DurationTypes.slice(),
            durationType: null
        });
    }

    setDuration = (pObj) => {
        var result = "";

        if (pObj !== undefined && pObj !== null) {
            if (pObj.day > 0)
                result = result + pObj.day + "Days ";
            if (pObj.hour > 0)
                result = result + pObj.hour + "Hours ";
            if (pObj.minute > 0)
                result = result + pObj.minute + "Minutes ";
        } else {
            pObj = {}
        }

        this.setState({
            duration: result,
            durationObj: pObj
        })
    }

    getDurationDesc = (pObj) => {
        var result = "";
        if (pObj !== undefined && pObj !== null) {
            if (pObj.day > 0)
                result = result + pObj.day + "Days ";
            if (pObj.hour > 0)
                result = result + pObj.hour + "Hours ";
            if (pObj.minute > 0)
                result = result + pObj.minute + "Minutes ";
        }
        return result;
    }

    handleUploadExcel = async excelJson => {
        try {
            var list = [];
            if (excelJson === null) this.showError("Select an excel file");
            else {
                excelJson.forEach(element => {
                    const obj = {
                        //name: element["Name"],
                        //shortName: element["Short Name"],
                        // [IMPORT_OBJECT]
                    };
                    list.push(obj);
                });
                if (Boolean(list) && list.length > 0) {
                    var response = await handleRequest("POST", "/api/overtimeRequest/import", list);
                    if (response.type === "ERROR") {
                        this.showError("No records to be added");
                    } else {
                        this.resetInputs();
                        this.restartTable();
                        this.showSuccess();
                    }
                } else {
                    this.showError("No records to be added");
                }
            }
        } catch (error) {
            this.showError("");
        }
    };

    render() {
        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };

        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 8},
            style: {marginBottom: 0}
        };

        return (
            <div>

                <div hidden={this.state.hideInputs}>
                    <Form initialValues={{remember: false}} onFinish={this.save} onFinishFailed={onFinishFailed}
                          ref={this.formRef}>
                        {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
                            <ElementsProvider inputs={this.state.pageFormData.pageFormInputCompanyList}
                                              layout={layout}/>
                        ) : null}
                        <FormSaveButtonsComponent onCancel={this.cancel}/>
                    </Form>
                </div>
                <Modal
                    show={this.state.modalMessage}
                    onHide={this.showOrHideModalMessage}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <Row>
                                <Col sm="12">
                                    {this.state.messageTip == "Success" &&
                                        <i className="fas fa-check fa-2x" style={{color: '#00ff2a'}}/>}

                                    {this.state.messageTip == "Warning" &&
                                        <i className="fas fa-exclamation-circle fa-2x" style={{color: '#faca2a'}}/>}

                                    {this.state.messageTip == "Error" &&
                                        <i className="fas fa-times fa-2x" style={{color: '#ff0400'}}/>}
                                </Col>
                            </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col sm="12">
                                {this.state.message}
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage
                            id="GeneralTextClose" defaultMessage="Close"/></ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.deleteModal}
                    onHide={this.showOrHideModalDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete"/>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{textAlign: "center", color: "black"}}>
                            <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this data?"/>
                        </p>
                        <p style={{textAlign: "center", color: "black"}}>
                            <FormattedMessage id="DeleteMessage2"
                                              defaultMessage="This process will delete all related datas."/>
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalDelete}>
                            <FormattedMessage id="GeneralTextClose" defaultMessage="Close"/>
                        </ReactButton>
                        <ReactButton variant="danger" onClick={this.deleteOvertimeRequestOK}>
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete"/>
                        </ReactButton>
                    </Modal.Footer>
                </Modal>

                <Card>

                    {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
                        <>
                            <div style={{padding: "4px 16px 4px 16px"}}>

                                <FormTopRightButtonsComponent
                                    title=""
                                    inputs={this.state.pageFormData.pageFormInputCompanyList}
                                    tableList={this.state.dataTable}
                                    handleUploadExcel={this.handleUploadExcel.bind(this)}
                                    hideInputs={this.state.hideInputs}
                                    onClickCreateNew={this.onClickCreateNew.bind(this)}
                                    onClickCancel={this.onClickCreateNew.bind(this)}
                                />
                            </div>
                            <DataTableComponent2
                                key="dataTable"
                                field={this.state.pageFormData.pageForm}
                                inputs={this.state.pageFormData.pageFormInputCompanyList}
                                actions={this.action}
                                editRow={this.updateOvertimeRequest.bind(this)}
                                restartTable={this.state.restartTable}
                            /> </>
                    ) : null}

                </Card>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OvertimeRequest)
