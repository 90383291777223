import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { Form, Table, Col, Row, Modal as AntModal, Alert, Button as AntButton, Select, Typography } from "antd";
import { handleRequest } from "../../ApiConnector";
import CommentIcon from "@material-ui/icons/Comment";
import moment from "moment";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { SettingTwoTone } from "@ant-design/icons";

const { Option } = Select;
const { Text } = Typography;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class PersonnelInformation extends Component {
  constructor() {
    super();
    this.state = {
      controller: "personnelLog",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      personnelsSelectItems: [],
      totalBalance: 0,
      personnelLogId: -1,
      salaryDetailList: [],

      id: null,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.fillPersonnelByUserId();
  };

  fillPersonnelByUserId = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controller + "/getPersonnelByUserId/" + this.props.user.id);
    if (response.type === "ERROR") {
      this.error(response);
    } else {
      this.setState({
        personnel: Boolean(response.data) ? response.data : [],
      });
    }
  };

  render() {
    const user = this.props.user;
    const personnel = this.state.personnel;
    console.log("props", this.props);
    console.log("user", user);
    console.log("personnel", personnel);
  return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        {user && personnel &&
          <div className="card card-custom card-stretch">
            <div className="card-header py-3">
              <div className="card-toolbar"></div>
            </div>
            <div className="form">
              <div className="card-body">
                <div className="row">
                  <label className="col-xl-3"></label>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Avatar</label>
                  <div className="col-lg-9 col-xl-6">
                    <img src={Boolean(user.photoPath) ? user.photoPath : toAbsoluteUrl("/media/logos/mndlogo.png")} alt="" className="w-100px" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Name</label>
                  <div className="col-lg-9 col-xl-6">
                    <input
                      type="text"
                      readOnly
                      className={`form-control form-control-lg form-control-solid`}
                      name="firstname"
                      value={Boolean(user) && Boolean(user.name) ? user.name : ""}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Nationality</label>
                  <div className="col-lg-9 col-xl-6">
                    <input
                      type="text"
                      readOnly
                      className={`form-control form-control-lg form-control-solid`}
                      name="firstname"
                      value={Boolean(personnel) && Boolean(personnel.nationality) ? personnel.nationality.nicename : ""}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Iqama No</label>
                  <div className="col-lg-9 col-xl-6">
                    <input
                      type="text"
                      readOnly
                      className={`form-control form-control-lg form-control-solid`}
                      name="firstname"
                      value={Boolean(user) && Boolean(user.name) ? user.name : ""}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Iqama Validity Date</label>
                  <div className="col-lg-9 col-xl-6">
                    <input
                      type="text"
                      readOnly
                      className={`form-control form-control-lg form-control-solid`}
                      name="firstname"
                      value={Boolean(user) && Boolean(user.name) ? user.name : ""}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Passport</label>
                  <div className="col-lg-9 col-xl-6">
                    <input
                      type="text"
                      readOnly
                      className={`form-control form-control-lg form-control-solid`}
                      name="firstname"
                      value={Boolean(personnel) && Boolean(personnel) ? personnel.passportNo : ""}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Passport Validity Date</label>
                  <div className="col-lg-9 col-xl-6">
                    <input
                      type="text"
                      readOnly
                      className={`form-control form-control-lg form-control-solid`}
                      name="firstname"
                      value={Boolean(personnel) && Boolean(personnel) ? personnel.passportExpiryDate : ""}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Driving Licence</label>
                  <div className="col-lg-9 col-xl-6">
                    <input
                      type="text"
                      readOnly
                      className={`form-control form-control-lg form-control-solid`}
                      name="firstname"
                      value={Boolean(personnel) && Boolean(personnel) ? personnel.drivingLicenseNo : ""}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Driving Licence Validity Date</label>
                  <div className="col-lg-9 col-xl-6">
                    <input
                      type="text"
                      readOnly
                      className={`form-control form-control-lg form-control-solid`}
                      name="firstname"
                      value={Boolean(personnel) && Boolean(personnel) ? user.drivingLicenseExpiryDate : ""}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Back Account</label>
                  <div className="col-lg-9 col-xl-6">
                    <input
                      type="text"
                      readOnly
                      className={`form-control form-control-lg form-control-solid`}
                      name="firstname"
                      value={Boolean(user) && Boolean(user.name) ? user.name : ""}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        }
      </div >
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PersonnelInformation);
