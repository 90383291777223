import { QuestionCircleOutlined } from "@ant-design/icons";
import { Form, Input, Menu, Tooltip } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import PrepareFormItemProps from "./ElementUtils";

const MailComponent = ({ field, layout }) => {
  return (
    <>
      <Form.Item
        {...layout}
        {...PrepareFormItemProps(field)}
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!"
          },
          {
            required: field.required,
            message: <FormattedMessage id="GENRAL.REQUIRED" defaultMessage={"Please type " + field.label} />
          }
        ]}
      >
        <Input placeholder={field.label} />
      </Form.Item>
    </>
  );
};

export default MailComponent;
