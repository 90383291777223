import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Tree, Space, Badge, Input, Form, Checkbox } from "antd";
import { FcFolder, FcEditImage, FcFile } from "react-icons/fc";
import { GrInherit } from "react-icons/gr";
import { Menu, Item, Submenu, useContextMenu } from "react-contexify";
import { FaFileAudio, FaPlus, FaRegFile } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../../ApiConnector";
import { error, showSuccess, showWarning } from "../../../MessageHelper";
import DraggableModal from "../../../Components/DraggableModal";
import PageGenerateComponent from "./PageGenerateComponent";

const Menu_ELDEN = "Menu_ELDEN";
const Menu_Folder = "Menu_Folder";
const Menu_Folder_Dashboard = "Menu_Folder_Dashboard";
const Menu_Page = "Menu_Page";
const Menu_NewUI = "Menu_NewUI";
const Menu_Form = "Menu_Form";
const Menu_Table = "Menu_Table";
const Menu_Form_Input = "Menu_Form_Input";
const Menu_Table_Input = "Menu_Table_Input";
const Menu_Form_Input_Button = "Menu_Form_Input_Button";
const Menu_Page_Link = "Menu_Page_Link";
const Menu_Drawer = "Menu_Drawer";
const Menu_Chart = "Menu_Chart";

const { show } = useContextMenu();

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

export class PageGenerate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "PageGenerate",
      controllerName: "pageGenerate",
      id: null,
      title: "",
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      loadedKeys: [],
      searchValue: "",
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      copy: false,
      pageGenerateItems: [],
      copiedId: null,
      showSupplyMethodModal: false,
      showServiceModal: false,
      refreshTree: true,
      folder: ""
    };
  }

  inputRef = React.createRef(null);

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "Folder"
    };

    var response = null;
    if (Boolean(treenode.key)) {
      response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitems/" + obj.id + "/" + obj.type);
    }
    if (response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach((element, index) => {
        element.icon =
          element.type === "Page_Generate" ? (
            <FcFile />
          ) : element.type === "Folder" ? (
            <FcFolder />
          ) : element.type === "Component" ? (
            <FaRegFile />
          ) : element.type === "Field" ? (
            <FaFileAudio />
          ) : (
            <FcFolder />
          );
        element.key = element.id + "-" + element.type + "." + index;
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      this.setState({
        nodes: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  formRef = React.createRef();
  formRefOnClickName = React.createRef();
  formRefFrontBackend = React.createRef();
  formRefType = React.createRef();

  componentDidMount = async () => {
    this.fillTree();
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "Folder";
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  onRightClick = (event, node) => {
    let parentNode = this.getParentNode(node);
    this.setState(
      {
        selectedNodeKey: node.key,
        selectedNode: node,
        parentNode: parentNode
      },
      this.displayMenu(event, Boolean(node) && Boolean(node.type) ? node.type : null, node, parentNode)
    );
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  handleItemClick = ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;

    console.log("AAA", event.currentTarget.id);
    console.log(selectedNode);
    console.log(parentNode);
    console.log(key);

    // eslint-disable-next-line default-case
    switch (event.currentTarget.id) {
      case "create_folder":
        this.setState({
          showCreateModal: true,
          parentId: selectedNode.id,
          title: "",
          type: "Page_Generate"
        });
        break;

      case "create_page":
        this.setState({
          modalPageGenerateFormShow: true,
          parentId: selectedNode.id,
          title: "",
          type: "Page_Generate",
          random: Math.random(),
          folder: selectedNode.title
        });
        break;
      case "create_page_dashboard":
        this.setState({
          showCreateFormDashboardModal: true,
          parentId: selectedNode.id,
          title: "",
          type: "Page_Generate",
          random: Math.random(),
          folder: selectedNode.title
        });
        break;

      case "create_newUI":
        this.setState({
          showNewUIModal: true,
          pageGenerateId: selectedNode.id
        });
        break;

      case "create_form":
        this.setState({
          showFormModal: true,
          pageGenerateId: selectedNode.id
        });
        break;
      case "create_form_link":
        this.setState({
          showFormModal: true,
          pageGenerateId: null,
          pageGenerateFormInputId: selectedNode.id
        });
        break;

      case "create_table":
        this.setState({
          showTableModal: true,
          pageGenerateId: selectedNode.id
        });
        break;

      case "create_table_link":
        this.setState({
          showTableModal: true,
          pageGenerateId: selectedNode.id,
          pageGenerateTableInputId: selectedNode.id
        });
        break;

      case "create_input_format":
        this.setState({
          showInputFormModal: true,
          pageGenerateId: selectedNode.id
        });
        break;

      case "create_table_format":
        this.setState({
          showTableFormatModal: true,
          pageGenerateId: selectedNode.id
        });
        break;

      case "create_form_link_page":
      case "create_table_link_page":
        this.setState(
          {
            showOnClickNameModal: true,
            autoDesignOnClick: "Page_Generate"
          },
          () => {
            let onClickName = null;
            let type = null;
            let pageGenerateId = null;
            if (Boolean(selectedNode.children) && selectedNode.children.length > 0) {
              pageGenerateId = selectedNode.children[0].id;
              onClickName = selectedNode.children[0].title;
              type = selectedNode.children[0].type;
            }
            if (Boolean(this.formRefOnClickName) && Boolean(this.formRefOnClickName.current)) {
              this.formRefOnClickName.current.setFieldsValue({
                onClickName: onClickName
              });
            }
            this.setState({
              type: type,
              pageGenerateId: pageGenerateId
            });
          }
        );
        break;

      case "create__form_link_modal":
      case "create__table_link_modal":
      case "create__table_link_drawer":
        this.setState(
          {
            showOnClickNameModal: true,
            autoDesignOnClick: "Modal"
          },
          () => {
            let onClickName = null;
            let type = null;
            let pageGenerateId = null;
            if (Boolean(selectedNode.children) && selectedNode.children.length > 0) {
              pageGenerateId = selectedNode.children[0].id;
              onClickName = selectedNode.children[0].title;
              type = selectedNode.children[0].type;
            }
            if (Boolean(this.formRefOnClickName) && Boolean(this.formRefOnClickName.current)) {
              this.formRefOnClickName.current.setFieldsValue({
                onClickName: onClickName
              });
            }
            this.setState({
              type: type,
              pageGenerateId: pageGenerateId
            });
          }
        );
        break;

      case "rename":
        this.setState({
          showCreateModal: true,
          title: selectedNode.title,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        });
        break;

      case "Show Inputs":
        this.setState({
          showInputFormModal: true,
          pageGenerateId: selectedNode.id
        });
        break;

      case "delete":
        this.setState({
          showDeleteModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;

      case "save":
        this.generatePage(selectedNode.id);
        break;

      case "create_drawer":
        this.setState({
          showDrawerModal: true,
          pageGenerateId: selectedNode.id
        });
        break;

      case "dragAndDrop":
        this.setState({
          showDragAndDropModal: true,
          pageGenerateId: selectedNode.id
        });
        break;

      case "generate_chart":
        this.generateChart(selectedNode.id);
        break;
    }
  };

  showOrHideDeleteModal = () => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
    if (!this.state.showDeleteModal) {
      this.resetInputs();
    }
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  generatePage = async pageGenerateId => {
    this.setState(
      {
        showFrontAndBacked: true,
        pageGenerateId: pageGenerateId
      },
      () => {
        this.formRefFrontBackend.current.setFieldsValue({
          frontend: true,
          backend: true
        });
      }
    );
  };

  okGeneratePage = async values => {
    const { pageGenerateId } = this.state;
    if (!values.frontend && !values.backend) {
      showWarning("Please choose at least one ?");
      return;
    }
    var response = await handleRequest(
      "GET",
      "/api/" + this.state.controllerName + "/generatePage/" + pageGenerateId + "/" + values.frontend + "/" + values.backend
    );
    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
      this.setState({
        showFrontAndBacked: false
      });
    }
  };

  generateChart = async pageGenerateId => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/generateChart/" + pageGenerateId);
    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
    }
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id,
      type: this.state.type
    };

    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteType/" + deletedItem.id + "/" + deletedItem.type);

    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
      else this.fillTree();

      showSuccess();
      this.setState({
        id: null,
        showDeleteModal: false
      });
    }
  };

  displayMenu = (e, type, node, parentNode) => {
    console.log("type", type);
    console.log("parentNode", parentNode);
    if (Boolean(type)) {
      if (type === "Page_Generate") {
        if (Boolean(parentNode) && parentNode.title === "Dashboard") {
          show(e, { id: Menu_Folder_Dashboard });
        } else {
          show(e, { id: Menu_ELDEN });
        }
      } else if (type === "Child_Form_Table_Link") {
        show(e, { id: Menu_Page });
      } else if (type === "Form_Table_Link") {
        show(e, { id: Menu_Page_Link });
      } else if (type === "NewUI") {
        show(e, { id: Menu_NewUI });
      } else if (type === "Form") {
        show(e, { id: Menu_Form });
      } else if (type === "Table") {
        show(e, { id: Menu_Table });
      } else if (type === "Drawer") {
        show(e, { id: Menu_Drawer });
      } else if (type === "Form_Input") {
        show(e, { id: Menu_Form_Input });
      } else if (type === "Table_Input" || type === "Table_Input_Button") {
        show(e, { id: Menu_Table_Input });
      } else if (type === "Form_Input_Button") {
        show(e, { id: Menu_Form_Input_Button });
      } else if (type === "Table_Input_Link") {
      } else if (type === "Form_Input_Link") {
      } else if (type === "Child_Form_Input_Link") {
      } else if (type === "Child_Table_Input_Link") {
      } else if (type === "Chart") {
        show(e, { id: Menu_Chart });
      } else {
        show(e, { id: Menu_ELDEN });
      }
    } else {
      show(e, { id: Menu_ELDEN });
    }
  };

  save = async () => {
    const { process, selectedNode } = this.state;
    const newItem = {
      id: this.state.id,
      parentId: this.state.parentId,
      title: this.state.title,
      type: Boolean(this.state.type) ? this.state.type : "Folder"
    };
    if (Boolean(process) && process === "create_parent") {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/addparent", newItem);
    } else if (newItem.id === null) {
      response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateType/" + newItem.id + "/" + newItem.type, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
    }
  };

  saveFolder = async () => {
    const { process, title, parentId, id, type } = this.state;
    if (!Boolean(title)) {
      showWarning("Title is required.");
    }
    const newItem = {
      id: id,
      parentId: parentId,
      title: title,
      type: Boolean(type) ? type : "Folder"
    };
    var response = null;
    if (newItem.id === null) {
      response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
    }
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      title: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      process: null,
      pageGenerateItems: [],
      showOrderModal: false,
      showPasteModal: false,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showServiceModal: false,
      showLocationModal: false
    });
  };

  expandSelectedNode = () => {
    let key = this.state.selectedNodeKey;
    let node = this.state.selectedNode;
    if (Boolean(key)) {
      let expandedKeys = Boolean(this.state.expandedKeys) ? this.state.expandedKeys : [];
      if (Boolean(expandedKeys.find(p => p === key))) {
        this.loadData(node, false);
      } else {
        this.setState({
          expandedKeys: [...expandedKeys, key]
        });
        this.loadData(node, false);
      }
    }
  };

  onDragEnter = info => {};

  onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...this.state.nodes];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 &&
      // Has children
      info.node.props.expanded &&
      // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    this.setState({
      nodes: data
    });
  };

  render() {
    return (
      <Badge.Ribbon
        text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage={"Page Generate"} />}
        placement="start"
        color="purple"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <br />
          <br />
          {this.state.refreshTree && (
            <Tree
              style={{ marginTop: "10px", lineHeight: "30px" }}
              className="draggable-tree"
              showLine={{ showLeafIcon: false }}
              showIcon
              checkStrictly={true}
              loadedKeys={this.state.loadedKeys}
              onLoad={this.onLoadData}
              loadData={this.loadData}
              onRightClick={({ event, node }) => this.onRightClick(event, node)}
              onExpand={this.onExpand}
              expandedKeys={this.state.expandedKeys}
              autoExpandParent={false}
              treeData={this.state.nodes}
              blockNode={true}
              // draggable
              onDragEnter={this.onDragEnter}
              onDrop={this.onDrop}
            />
          )}

          <Menu id={Menu_ELDEN}>
            <Submenu
              label={
                <Space>
                  <FaPlus />
                  <span>Create</span>
                </Space>
              }
            >
              <Item id="create_folder" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>Folder</span>
                </Space>
              </Item>
              <Item id="create_page" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>Page</span>
                </Space>
              </Item>
            </Submenu>

            <Item id="rename" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Rename</span>
              </Space>
            </Item>
            <Item id="save" onClick={this.handleItemClick}>
              <Space>
                <FaPlus />
                <span>Save</span>
              </Space>
            </Item>
            <Item id="delete" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>Delete</span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Folder}>
            <Item id="properties" onClick={this.handleItemClick}>
              <Space>
                <GrInherit />
                <span>Properties</span>
              </Space>
            </Item>
            <Item id="dragAndDrop" onClick={this.handleItemClick}>
              <Space>
                <GrInherit />
                <span>Drag And Drop</span>
              </Space>
            </Item>
            <Submenu
              label={
                <Space>
                  <FaPlus />
                  <span>Create</span>
                </Space>
              }
            >
              <Item id="create_folder" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>Folder</span>
                </Space>
              </Item>
              <Item id="create_page" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>Page</span>
                </Space>
              </Item>
            </Submenu>
            <Item id="rename" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Rename</span>
              </Space>
            </Item>
            <Item id="delete" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>Delete</span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Folder_Dashboard}>
            <Item id="properties" onClick={this.handleItemClick}>
              <Space>
                <GrInherit />
                <span>Properties</span>
              </Space>
            </Item>
            <Item id="dragAndDrop" onClick={this.handleItemClick}>
              <Space>
                <GrInherit />
                <span>Drag And Drop</span>
              </Space>
            </Item>
            <Item id="create_page_dashboard" onClick={this.handleItemClick}>
              <Space>
                <GrInherit />
                <span>Create</span>
              </Space>
            </Item>
            <Item id="rename" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Rename</span>
              </Space>
            </Item>
            <Item id="delete" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>Delete</span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Page}>
            <Item id="properties" onClick={this.handleItemClick}>
              <Space>
                <GrInherit />
                <span>Properties</span>
              </Space>
            </Item>
            <Submenu
              label={
                <Space>
                  <FaPlus />
                  <span>Create</span>
                </Space>
              }
            >
              <Item id="create_newUI" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>New UI</span>
                </Space>
              </Item>
              <Item id="create_form" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>Form</span>
                </Space>
              </Item>
              <Item id="create_table" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>Table</span>
                </Space>
              </Item>
              <Item id="create_modal" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>Modal</span>
                </Space>
              </Item>
              <Item id="create_tabPane" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>Tab Pane</span>
                </Space>
              </Item>
            </Submenu>
            <Item id="rename" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Rename</span>
              </Space>
            </Item>
            <Item id="save" onClick={this.handleItemClick}>
              <Space>
                <FaPlus />
                <span>Save</span>
              </Space>
            </Item>
            <Item id="delete" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>Delete</span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Page_Link}>
            <Item id="properties_link" onClick={this.handleItemClick}>
              <Space>
                <GrInherit />
                <span>Properties</span>
              </Space>
            </Item>
            <Submenu
              label={
                <Space>
                  <FaPlus />
                  <span>Create</span>
                </Space>
              }
            >
              <Item id="create_newUI" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>NewUI</span>
                </Space>
              </Item>
              <Item id="create_form_link" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>Form</span>
                </Space>
              </Item>
              <Item id="create_table_link" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>Table</span>
                </Space>
              </Item>
              <Item id="create_modal_link" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>Modal</span>
                </Space>
              </Item>
              <Item id="create_tabPane_link" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>Tab Pane</span>
                </Space>
              </Item>
              <Item id="create_drawer" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>Drawer</span>
                </Space>
              </Item>
            </Submenu>
            <Item id="rename_link" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Rename</span>
              </Space>
            </Item>
            <Item id="delete_link" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>Delete</span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Form}>
            <Item id="createForm" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Create Form</span>
              </Space>
            </Item>
            <Item id="rename" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Rename</span>
              </Space>
            </Item>
            <Item id="showInputs" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Show Inputs</span>
              </Space>
            </Item>
            <Item id="delete" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>Delete</span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Table}>
            <Item id="rename" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Rename</span>
              </Space>
            </Item>
            <Item id="delete" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>Delete</span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Drawer}>
            <Item id="properties_link" onClick={this.handleItemClick}>
              <Space>
                <GrInherit />
                <span>Properties</span>
              </Space>
            </Item>
            <Submenu
              label={
                <Space>
                  <FaPlus />
                  <span>Create</span>
                </Space>
              }
            >
              <Item id="create_drawer" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>Drawer</span>
                </Space>
              </Item>
            </Submenu>
            <Item id="rename_link" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Rename</span>
              </Space>
            </Item>
            <Item id="delete_link" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>Delete</span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Form_Input}>
            <Submenu
              label={
                <Space>
                  <FaPlus />
                  <span>Create</span>
                </Space>
              }
            >
              <Item id="create_input_format" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>Format</span>
                </Space>
              </Item>
            </Submenu>
            <Item id="delete" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>Delete</span>
              </Space>
            </Item>
            <Item id="rename" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Rename</span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Table_Input}>
            <Submenu
              label={
                <Space>
                  <FaPlus />
                  <span>Create</span>
                </Space>
              }
            >
              <Item id="create_table_format" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>Format</span>
                </Space>
              </Item>

              <Submenu
                label={
                  <Space>
                    <FaPlus />
                    <span>Link</span>
                  </Space>
                }
              >
                <Item id="create_table_link_page" onClick={this.handleItemClick}>
                  <Space>
                    <FaPlus />
                    <span>Page</span>
                  </Space>
                </Item>
                <Item id="create__table_link_modal" onClick={this.handleItemClick}>
                  <Space>
                    <FaPlus />
                    <span>Modal</span>
                  </Space>
                </Item>
              </Submenu>
            </Submenu>
            <Item id="delete" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>Delete</span>
              </Space>
            </Item>
            <Item id="rename" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Rename</span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Form_Input_Button}>
            <Submenu
              label={
                <Space>
                  <FaPlus />
                  <span>Create</span>
                </Space>
              }
            >
              <Item id="create_input_format" onClick={this.handleItemClick}>
                <Space>
                  <FaPlus />
                  <span>Format</span>
                </Space>
              </Item>

              <Submenu
                label={
                  <Space>
                    <FaPlus />
                    <span>Link</span>
                  </Space>
                }
              >
                <Item id="create_form_link_page" onClick={this.handleItemClick}>
                  <Space>
                    <FaPlus />
                    <span>Page</span>
                  </Space>
                </Item>
                <Item id="create__form_link_modal" onClick={this.handleItemClick}>
                  <Space>
                    <FaPlus />
                    <span>Modal</span>
                  </Space>
                </Item>
                <Item id="create__table_link_drawer" onClick={this.handleItemClick}>
                  <Space>
                    <FaPlus />
                    <span>Drawer</span>
                  </Space>
                </Item>
              </Submenu>
            </Submenu>
            <Item id="delete" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>Delete</span>
              </Space>
            </Item>
            <Item id="rename" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Rename</span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Chart}>
            <Item id="generate_chart" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Generate Chart</span>
              </Space>
            </Item>
          </Menu>

          <DraggableModal
            title={<FormattedMessage id="PageGenerate.PageGenerate" defaultMessage="Page Generate" />}
            open={this.state.showCreateModal}
            okButtonProps={{ hidden: false }}
            onOk={this.saveFolder}
            onCancel={() => {
              this.setState({
                showCreateModal: false
              });
              this.resetInputs();
            }}
            width={600}
            centered
            content={
              <>
                <Input
                  placeholder="Please write without spaces"
                  ref={this.inputRef}
                  onPressEnter={this.saveFolder}
                  value={this.state.title}
                  onChange={e => this.setState({ title: e.target.value })}
                />
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="PageGenerate.Component" defaultMessage="Component" />}
            open={this.state.modalPageGenerateFormShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState({
                modalPageGenerateFormShow: false
              });
            }}
            width={1200}
            centered
            content={<PageGenerateComponent random={this.state.random} pageGenerateId={this.state.parentId} />}
          />

          <DraggableModal
            title={<FormattedMessage id="PageAutoDesign.FrontBackend" defaultMessage="Frontend/Backend" />}
            centered
            width={600}
            open={this.state.showFrontAndBacked}
            onCancel={() => this.setState({ showFrontAndBacked: false })}
            cancelButtonProps={{ hidden: false }}
            okButtonProps={{ hidden: false }}
            onOk={() => this.formRefFrontBackend.current.submit()}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={
              <Form initialValues={{ remember: false }} onFinish={this.okGeneratePage} ref={this.formRefFrontBackend}>
                <Form.Item
                  name="frontend"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox>
                    <FormattedMessage id="PageAutoDesign.Frontend" defaultMessage="Frontend" />
                  </Checkbox>
                </Form.Item>

                <Form.Item
                  name="backend"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox>
                    <FormattedMessage id="PageAutoDesign.Backend" defaultMessage="Backend" />
                  </Checkbox>
                </Form.Item>
              </Form>
            }
          />
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageGenerate);
