import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../modules/Auth/_redux/authRedux";
import EditIcon from '@material-ui/icons/EditTwoTone';
import { Button, Modal } from 'react-bootstrap';
import { FilterOutlined } from '@ant-design/icons';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { handleRequest } from '../../ApiConnector';
import CreateIcon from '@material-ui/icons/AddCircle';
import ExportIcon from '@material-ui/icons/CloudDownloadRounded';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { Form, message, Table, Input, Select, Switch, Col, Row, DatePicker } from 'antd';
import { ExcelReader } from '../excel/ExcelReader';
import moment from 'moment';
import { error,showError,showSuccess } from '../../MessageHelper';

const { Option } = Select;

export class Folder extends Component {

    constructor() {
        super();
        this.state = {

            modalDeleteShow: false,
            hideUpdate: true,
            hideSave: false,
            hiddenInputs: true,

            id: null,
            parentId: null,
            folderName: null,
            projectId: null,
            companyId: null,
            folderSelectItems: [],
            companySelectItems: [],
            projectSelectItems: [],


            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '25', '50', '100'],
            },
            loading: false,
            sortField: "",
            sortOrder: "",
        };

        
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });

        this.fillComboboxes();
        // this.fillCompanies();
        //  this.fillProjects();
    };

    fillCompanies = async () => {

        let url = "/api/companies/authorized";

        const response = await handleRequest("GET", url);

        if (response.type === "ERROR") {
            error(response);
        }
        else
            this.setState({
                companySelectItems: Boolean(response.data) ? response.data : []
            });

    }

    fillProjects = async () => {

        let url = "/api/projects";// authorized

        const response = await handleRequest("GET", url);

        if (response.type === "ERROR") {
            error(response);
        }
        else
            this.setState({
                projectSelectItems: Boolean(response.data) ? response.data : ''
            });

    }

    cancel = () => {
        this.setState({
            hideUpdate: true,
            hideSave: false,
            hiddenInputs: true,
        });
        this.resetInputs();
    };

    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            id: null,
            parentId: null,
            folderName: null,
            projectId: null,
            companyId: null,
        });

        this.setState({
            id: null,
            folderName: null,
            parentId: null,
            projectId: null,
            companyId: null,
            folderNameColor: '#bfbfbf',
            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '25', '50', '100'],
            },
        })
    }

    edit = (row) => {
        this.formRef.current.setFieldsValue({

            folderName: row.folderName,
            projectId: row.projectId,
            companyId: row.companyId,
        });

        this.setState({
            hideUpdate: false,
            hideSave: true,
            hiddenInputs: false,

            id: row.id,
            folderName: row.folderName,
            projectId: row.projectId,
            companyId: row.companyId,
        });
    };

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    delete = async () => {
        const deletedItem = {
            id: this.state.id
        }
        var response = await handleRequest("DELETE", "/api/folders/" + deletedItem.id);

        if (response.type === "ERROR") {
            error(response);
        }
        else {
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.setState({
                id: null,
                modalDeleteShow: false
            });
        }
    }

    onchangeFolderName = async (event) => {
        if (Boolean(event) && Boolean(event.target) && Boolean(event.target.value)) {
            await this.setState({
                folderNameColor: '#1890ff',
                folderName: event.target.value,
                pagination: {
                    ...this.state.pagination,
                    folderName: event.target.value,
                },
            })
        } else {
            await this.setState({
                folderNameColor: '#bfbfbf',
                folderName: "",
                pagination: {
                    ...this.state.pagination,
                    folderName: "",
                },
            })
        }

        const { pagination } = this.state;
        this.restartTable({ pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    };










    handleTableChange = (pagination, filters, sorter) => {
        this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters, });
    };

    restartTable = async (params = {}) => {

        this.setState({ loading: true });
        const newObj = this.createWhere(params);

        let url = "/api/folders/paging";
        const response = await handleRequest("POST", url, newObj);
        if (response.type === "ERROR") {
            error(response);
            this.setState({
                loading: false,
                tableList: [],
                pagination: {
                    ...params.pagination,
                    total: 0,
                },
                sortField: "",
                sortOrder: "",
            });
        }
        else {
            if (response.data.length !== 0) {
                this.setState({
                    loading: false,
                    tableList: response.data.data,
                    pagination: {
                        ...params.pagination,
                        total: response.data.totalRecords,
                    },
                    sortField: params.sortField,
                    sortOrder: params.sortOrder,
                });
            } else {
                this.setState({
                    loading: false,
                    tableList: [],
                    pagination: {
                        ...params.pagination,
                        total: 0,
                    },
                    sortField: "",
                    sortOrder: "",
                });
            }
        }
    };


    fillComboboxes = async () => {

        let url = "/api/folders/authorized";

        const response = await handleRequest("GET", url);

        if (response.type === "ERROR") {
            error(response);
        }
        else
            this.setState({
                folderSelectItems: Boolean(response.data) ? response.data : []
            });



    }

    onClickCreateNew = async () => {
        this.resetInputs();
        if (this.state.hiddenInputs) {
            this.setState({
                hiddenInputs: false,
                hiddenSave: false,
                hiddenUpdate: true,
            });
        }
        else {
            this.setState({
                hiddenInputs: true,
                hiddenSave: true,
                hiddenUpdate: false,
            });
        }
    }

    save = async () => {

        const newItem = {
            id: this.state.id,
            parentId: this.state.parentId,
            folderName: this.state.folderName,
            projectId: this.state.projectId,
            companyId: this.state.companyId,
        }

        if (newItem.id === null) {
            var response = await handleRequest("POST", "/api/folders", newItem);
        }
        else {
            response = await handleRequest("PUT", "/api/folders/" + newItem.id, newItem);
        }

        if (response.type === "ERROR") {
            error(response);
        }
        else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.cancel();
        }
    };

    createWhere(params) {
        var current = this.state.pagination.current;
        var pageSize = this.state.pagination.pageSize;
        var folderName = "", sortField = "", sortOrder = "";;
        if (Boolean(params) && Boolean(params.pagination)) {
            current = params.pagination.current;
            pageSize = params.pagination.pageSize;
            if (Boolean(params.pagination.folderName)) {
                folderName = params.pagination.folderName;
            }

            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }

        }
        const newObj = {
            id: null,
            folderName: folderName,
            firstResult: current - 1, //(current - 1) * pageSize,
            maxResults: pageSize,
            sortField: sortField,
            sortOrder: sortOrder,
        };
        return newObj;
    }

    handleExportExcel = async () => {
        const { pagination } = this.state;
        const newObj = this.createWhere({ pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });

        let url = "/api/folders/export";
        var response = await handleRequest("POST", url, newObj);

        if (Boolean(response.data) && Boolean(response.data.url)) {
            window.location.href = response.data.url;
        } else {
            message.error("File cant found.");
        }
    }

    handleUploadExcel = async (excelJson) => {
        try {
            var list = [];
            if (excelJson === null)
                showError("Select an excel file");
            else {
                excelJson.forEach(element => {
                    const obj = {
                        folderName: element["Folder Name"]
                    }
                    list.push(obj);
                });

                if (Boolean(list) && list.length > 0) {
                    var response = await handleRequest("POST", "/api/folders/import", list);

                    if (response.type === "ERROR") {
                        error(response);
                    }
                    else {
                        this.resetInputs();
                        const { pagination } = this.state;
                        this.restartTable({ pagination });
                        showSuccess();
                        this.cancel();
                    }
                } else {
                    showError("No records to be added");
                }
            }
        } catch (error) {
            error();
        }
    }

    render() {
        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout2 = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 }
        };

        const columns = [
            {
                title: 'S/N', key: 'id', render: (value, row, index) => {
                    return index + 1 + (this.state.pagination.pageSize * (this.state.pagination.current - 1));
                }
            },
            {
                title: 'Company', key: 'companyId', render: (record) => {
                    if (Boolean(record.company)) {
                        return record.company.companyName;
                    }

                },

            },
            {
                title: 'Project', key: 'projectId', render: (record) => {
                    if (Boolean(record.project)) {
                        return record.project.projectFullName;
                    }

                },

            },
            {
                title: 'Parent Folder', key: 'parentFolder', render: (record) => {
                    if (Boolean(record.folder)) {
                        return record.folder.folderName;
                    }

                },

            },
            {
                title: 'Folder Name', key: 'folderName', render: (record) => {
                    return record.folderName;
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input style={{ width: 200 }} value={this.state.folderName} placeholder="Name"
                            onChange={this.onchangeFolderName}
                        />
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: this.state.folderNameColor }} />,
                sorter: true,
            },

            {
                title: 'Update', key: 'key', render: (record) => {
                    return <EditIcon fontSize="default" color="primary" backgroundColor="black" onClick={() => this.edit(record)} />
                }
            },
            {
                title: 'Delete', key: 'key', render: (record) => {
                    return <DeleteIcon fontSize="default" color="error" onClick={() => this.deleteModal(record)} />
                }
            },
        ];

        return (
            <div hidden={this.state.hideInputs} className="card card-custom" style={{ padding: '2rem' }}>

                <Form
                    initialValues={{ remember: false }}
                    onFinish={this.save}
                    onFinishFailed={onFinishFailed}
                    ref={this.formRef}>

                    <Row>
                        <Col xs={18}></Col>
                        <Col xs={2}>
                            {/* <ExportIcon fontSize="large" color="action" onClick={() => this.handleExportExcel()} /> */}
                        </Col>
                        <Col xs={2}>
                            {/* <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader> */}
                        </Col>
                        <Col xs={2}>
                            <CreateIcon hidden={!this.state.hiddenInputs} fontSize="large" color="secondary" onClick={() => this.onClickCreateNew()} />
                            <RemoveCircle hidden={this.state.hiddenInputs} fontSize="large" color="error" onClick={() => this.onClickCreateNew()} />
                        </Col>
                    </Row>

                    <div hidden={this.state.hiddenInputs}>

                        {/* {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Company" />}
                                name="companyId"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please select company" /> }]} >
                                <Select placeholder="Company"
                                    onChange={(value) => {
                                        this.setState({
                                            companyId: value,

                                        });

                                    }}>
                                    <Option key={null} value={null}>Select</Option>
                                    {
                                        this.state.companySelectItems.map(i => <Option value={i.id}>{i.companyName}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Project" />}
                                name="projectId"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please select project" /> }]} >
                                <Select
                                    onChange={(value) => {
                                        this.setState({
                                            projectId: value,

                                        });

                                    }}>
                                    <Option key={null} value={null}>Select</Option>
                                    {
                                        this.state.projectSelectItems.map(i => <Option value={i.id}>{i.projectFullName}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        } */}

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Parent Folder" />}
                                name="selectedFolderIds"

                            >


                                <Select style={{ width: "100%" }} placeholder="Select folder" id="selectedCompanyIds" value={this.state.parentId} onChange={(value) => {

                                    this.setState({ parentId: value })
                                }}>
                                    <Option key={null} value={null}>Select</Option>
                                    {this.state.folderSelectItems.map(i => (
                                        <Option value={i.id}>{i.folderName}</Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Folder Name" />}
                                name="folderName"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type folder name" /> }]}
                            >
                                <Input placeholder="Name" style={{ width: '100%' }} id="todo" value={this.state.folderName} onChange={(e) => {

                                    this.setState({ folderName: e.target.value })
                                }} />
                            </Form.Item>
                        }







                        <div hidden={this.state.hideSave}>
                            {
                                <Row gutter={8}>
                                    <Col span={8}></Col>

                                    <Col span={8}>
                                        {
                                            <Button id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                                        }
                                    </Col>
                                </Row>

                            }
                        </div>

                        <div hidden={this.state.hideUpdate}>
                            {
                                <Row gutter={8}>
                                    <Col span={8}></Col>

                                    <Col span={4}>
                                        <Button id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                                    </Col>
                                    <Col span={4}>
                                        <Button id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                                    </Col>

                                </Row>
                            }
                        </div>
                    </div>
                </Form>

                <div style={{ marginTop: '2rem', marginBottom: '1rem' }} className="content-section implementation">
                    <Table
                        bordered
                        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                        columns={columns}
                        dataSource={this.state.tableList}
                        loading={this.state.loading} onChange={this.handleTableChange}
                        pagination={this.state.pagination}
                    />
                </div>

                <Modal
                    show={this.state.modalDeleteShow}
                    onHide={this.showOrHideDeleteModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</Button>
                        <Button variant="danger" onClick={this.delete} >Delete</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    console.log("YYY", state);

    return {
        // auth: state.auth,
        logout: state.logout,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Folder)
// export default connect(null, auth.actions)(Domain);
