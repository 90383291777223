import ExcelJS from 'exceljs';

export const excelExport = async (columns, tableList) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet");

  // Define columns dynamically
  worksheet.columns = columns;

  // Add rows
  tableList.forEach((item, index) => {
    const row = { index: index + 1 };
    columns.forEach(col => {
      row[col.key] = typeof col.value === 'function' ? col.value(item) : item[col.key];
    });
    worksheet.addRow(row);
  });

  // Generate buffer and create a downloadable link
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);

  // Create a link element and click it to trigger download
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Export.xlsx';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}