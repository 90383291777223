import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button as AntButton, Badge, Col, Form, Row, Radio, Checkbox, Upload, Switch } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../../ApiConnector";
import { error, showError, showSuccess } from "../../../MessageHelper";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { Button, Modal } from "react-bootstrap";
import { UploadOutlined } from "@ant-design/icons";
import { Col as ColBoot, Form as FormBoot } from "react-bootstrap";
import { RadioGroup } from "@material-ui/core";

export class EquipmentLogSettings extends Component {
  constructor() {
    super();
    this.state = {
      formName: "EquipmentLogSettings",
      controllerName: "equipmentSettings",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      dynamicMenuCascader: [],
      answerTypeSelectItems: [],
      id: null,
      tableList: [],
      excelData: [],
      createWhereParams: null,
      refreshForm: false,
      filePath: "",
      fileList: [],
      rows: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    console.log("props", this.props);
    const { pagination } = this.state;
    this.restartTable({ pagination });

    this.fillComboboxes();
  };

  componentDidUpdate = async nextProps => {};

  fillComboboxes = async () => {};

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      answerType: null,
      fileList: []
    });
  };

  edit = row => {
    console.log("AAA", row);
    document.getElementById("kt_scrolltop").click();

    var options = [];
    if (Boolean(row) && Boolean(row.options) && row.options.length > 0) {
      row.options.forEach(element => {
        options.push(element.codeId);
      });
    }

    var columns = [];
    if (Boolean(row) && Boolean(row.columns) && row.columns.length > 0) {
      row.columns.forEach(element => {
        columns.push(element.codeId);
      });
    }

    let _fileList = [];
    if (Boolean(row.answerType) && row.answerType === "Imagepicker") {
      if (Boolean(row.imageOptions)) {
        row.imageOptions.forEach((element, index) => {
          if (Boolean(element.imageUrl)) {
            let obj = {
              url: element.imageUrl,
              uid: index,
              imagePath: element.imageUrl,
              id: index,
              name: element.imageUrl.substring(element.imageUrl.lastIndexOf("/") + 1)
            };
            _fileList.push(obj);
          }
        });
      }
    }

    this.formRef.current.setFieldsValue({
      ...row,
      options: options,
      columns: columns
    });
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      answerType: row.answerType,
      fileList: _fileList
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    let newObj = {
      eldenEquipmentTreeId: this.props.eldenEquipmentTreeId
    };
    let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/listAll/" + this.props.equipmentLogId, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: []
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          loading: false,
          tableList: response.data
        });
      } else {
        this.setState({
          loading: false,
          tableList: []
        });
      }
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      searchCriteriaModels = [],
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
      searchCriteriaModels: searchCriteriaModels
    };
    return newObj;
  }

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async () => {
    const { tableList } = this.state;
    console.log("tableList", tableList);
    let saveList = [];
    tableList.forEach(row => {
      if (row.answerType === "Comment") {
        saveList.push({
          equipmentLogId: this.props.equipmentLogId,
          eldenEquipmentSettingsId: row.id,
          comment: row.comment
        });
      } else if (row.answerType === "Checkbox") {
        saveList.push({
          equipmentLogId: this.props.equipmentLogId,
          eldenEquipmentSettingsId: row.id,
          settingsOptions: row.optionsCodeIds
        });
      }
    });
    console.log("saveList", saveList);
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAll/" + this.props.equipmentLogId, saveList);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      this.restartTable();
      showSuccess();
      if (Boolean(this.props) && Boolean(this.props.nextTab)) {
        this.props.nextTab();
      }
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  renderElementMultiple = (item, index) => {
    console.log("item", item);
    return (
      <>
        <div style={{ backgroundColor: "white", padding: "10px", border: "1px solid #1ab394" }}>
          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
            <FormBoot.Label style={{ color: "black" }} column sm="9">
              {item.title}
            </FormBoot.Label>
          </FormBoot.Group>
          {Boolean(item.answerType) && item.answerType === "Comment" && (
            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
              <ColBoot xs="12">
                <FormBoot.Control
                  as="textarea"
                  rows="3"
                  value={item.comment || ""}
                  onChange={e => {
                    const newComment = e.target.value;

                    this.setState(prevState => {
                      const updatedItems = prevState.tableList.map((prevItem, i) => {
                        if (i === index) {
                          return { ...prevItem, comment: newComment };
                        }
                        return prevItem;
                      });

                      return { tableList: updatedItems };
                    });
                  }}
                />
              </ColBoot>
            </FormBoot.Group>
          )}

          {Boolean(item.answerType) && item.answerType === "Radiogroup" && (
            <>
              {Boolean(item.options) &&
                item.options.length > 0 &&
                item.options.map((opt, j) => (
                  <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                    <ColBoot xs="11">
                      <Radio checked={false}>{opt.codeName}</Radio>
                    </ColBoot>
                  </FormBoot.Group>
                ))}
            </>
          )}

          {Boolean(item.answerType) && item.answerType === "Imagepicker" && (
            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
              <ColBoot xs="12">
                {Boolean(item.imageOptions) &&
                  item.imageOptions.length > 0 &&
                  item.imageOptions.map((opt, j) => (
                    <>
                      <RadioGroup>
                        <Radio checked={false}>
                          <img alt="option" style={{ width: "120px", height: "120px" }} src={opt.imageUrl} />
                        </Radio>
                      </RadioGroup>
                    </>
                  ))}
              </ColBoot>
            </FormBoot.Group>
          )}

          {Boolean(item.answerType) && item.answerType === "Rating" && (
            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
              <ColBoot xs="12">
                <Radio.Group buttonStyle="solid">
                  {this.getRatingList(item.rateMax).map((element, index) => (
                    <Radio.Button value={element}>{element}</Radio.Button>
                  ))}
                </Radio.Group>
              </ColBoot>
            </FormBoot.Group>
          )}

          {Boolean(item.answerType) && item.answerType === "Boolean" && (
            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
              <ColBoot xs="12">
                <Switch checkedChildren={item.labelTrue} unCheckedChildren={item.labelFalse} defaultChecked />
              </ColBoot>
            </FormBoot.Group>
          )}

          {Boolean(item.answerType) && item.answerType === "Checkbox" && (
            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
              {Boolean(item.options) &&
                item.options.length > 0 &&
                item.options.map((opt, j) => (
                  <>
                    {console.log(opt)}
                    <ColBoot style={{ marginBottom: "1rem" }} xs="11">
                      <Checkbox
                        checked={item.optionsCodeIds?.some(codeObj => codeObj.codeId === opt.codeId) || false}
                        onChange={e => {
                          const isChecked = e.target.checked;

                          this.setState(prevState => {
                            const updatedItems = prevState.tableList.map((prevItem, i) => {
                              if (index === i) {
                                let updatedOptionsCodeIds = [...(prevItem.optionsCodeIds || [])];

                                if (isChecked) {
                                  if (!updatedOptionsCodeIds.some(item => item.codeId === opt.codeId)) {
                                    updatedOptionsCodeIds.push({ codeId: opt.codeId });
                                  }
                                } else {
                                  updatedOptionsCodeIds = updatedOptionsCodeIds.filter(item => item.codeId !== opt.codeId);
                                }
                                return { ...prevItem, optionsCodeIds: updatedOptionsCodeIds };
                              }
                              return prevItem;
                            });
                            return { tableList: updatedItems };
                          });
                        }}
                      >
                        {opt.codeName}
                      </Checkbox>
                    </ColBoot>
                    <br />
                  </>
                ))}
            </FormBoot.Group>
          )}

          {Boolean(item.answerType) && item.answerType === "Dropdown" && (
            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
              <ColBoot xs="4">
                <FormBoot.Control name="type" as="select">
                  <option value="Select">Select Option</option>
                  {Boolean(item.options) &&
                    item.options.length > 0 &&
                    item.options.map((opt, j) => <option value={opt.codeName}>{opt.codeName}</option>)}
                </FormBoot.Control>
              </ColBoot>
            </FormBoot.Group>
          )}

          {Boolean(item.answerType) && item.answerType === "File" && (
            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
              <ColBoot xs="4">
                <Upload>
                  <AntButton>
                    <UploadOutlined /> Click to Upload
                  </AntButton>
                </Upload>
              </ColBoot>
            </FormBoot.Group>
          )}

          {Boolean(item.answerType) && item.answerType === "Matrix_Single" && Boolean(item.columns) && item.columns.length > 0 && (
            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
              <ColBoot> </ColBoot>
              {item.columns.map((col, j) => (
                <ColBoot>{col.codeName}</ColBoot>
              ))}
            </FormBoot.Group>
          )}

          {Boolean(item.answerType) && item.answerType === "Matrix_Multiple" && (
            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
              <ColBoot> </ColBoot>
              {item.columns.map((col, j) => (
                <ColBoot>{col.codeName}</ColBoot>
              ))}
            </FormBoot.Group>
          )}

          {Boolean(item.answerType) &&
            item.answerType === "Matrix_Single" &&
            Boolean(item.columns) &&
            item.columns.length > 0 &&
            Boolean(item.matrixValues) &&
            item.matrixValues.length > 0 &&
            item.matrixValues.map((row, k) => (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot>{row.title}</ColBoot>
                {item.columns.map((col, j) => (
                  <ColBoot>
                    <Radio checked={false}></Radio>
                  </ColBoot>
                ))}
              </FormBoot.Group>
            ))}

          {Boolean(item.answerType) &&
            item.answerType === "Matrix_Multiple" &&
            Boolean(item.matrixValues) &&
            item.matrixValues &&
            item.matrixValues.map((row, k) => (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot> {row.title} </ColBoot>
                {item.columns.map((col, j) => (
                  <ColBoot>
                    <FormBoot.Control name="type" as="select">
                      <option value="Select">Select Option</option>
                      {Boolean(item.options) &&
                        item.options.length > 0 &&
                        item.options.map((opt, j) => <option value={opt.codeId}>{opt.codeName}</option>)}
                    </FormBoot.Control>
                  </ColBoot>
                ))}
              </FormBoot.Group>
            ))}
        </div>
        <br />
      </>
    );
  };

  setOpenPanels = key => {
    this.setState({
      activePanelKey: key
    });
  };

  setOpenPanelsTitle = key => {
    this.setState({
      activePanelKeyTitle: key
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  singleOpenCheckListModel = async row => {};

  multipleChecklistModel = async row => {};

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="EquipmentLogSettings.pageTitle" defaultMessage="Equipment Log Settings" />}
        style={{ fontSize: 12 }}
        placement="start"
        color="volcano"
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <br />
          <div hidden={false}>
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
              {Boolean(this.state.tableList) && this.state.tableList.length > 0 ? (
                <>
                  <br />
                  {this.state.tableList.map((item, index) => this.renderElementMultiple(item, index))}
                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                          <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </>
              ) : (
                ""
              )}
            </Form>
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EquipmentLogSettings);
