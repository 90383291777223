import { Button, Drawer, Modal } from "antd";
import objectPath from "object-path";
import React, { useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import UserNotes from "../../../../../app/pages/UserNotes";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useHtmlClassService } from "../../../_core/MetronicLayout";

export default function MyNotesDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const uiService = useHtmlClassService();
  const location = useLocation();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.search.layout") === "offcanvas"
    };
  }, [uiService]);
  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div className="btn btn-icon btn-clean btn-lg mr-1" id="kt_notes_toggle">
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")} />
            </span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <div className="topbar-item">
          <OverlayTrigger placement="bottom" overlay={<Tooltip id="user-notification-tooltip">Notes</Tooltip>}>
            <Button
              className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
              icon={
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")} />
                </span>
              }
              onClick={() => setIsOpen(true)}
            ></Button>
          </OverlayTrigger>
        </div>
      )}

      <Drawer title={<FormattedMessage id="MyNotesDropdown.MyNotes" defaultMessage="My Notes" />}
        width={1200} closable={true} onClose={() => {
          setIsOpen(false);
        }} open={isOpen}>
        <UserNotes location={location} />
      </Drawer>
    </>
  );
} 