import React, { useRef, useEffect, useState } from 'react'
import { FormattedMessage } from "react-intl";
import { over } from 'stompjs';
import SockJS from 'sockjs-client';
import './index.css';
import { API_BASE_ROOT, handleRequest } from "../../../../../app/ApiConnector";
import store from "../../../../../redux/store";
import { toAbsoluteUrl } from "../../../../_helpers";
import { error, showSuccess, showWarning } from "../../../../../app/MessageHelper";
import moment from "moment/moment";
import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import Drawer from '@material-ui/core/Drawer';
import CreateIcon from "@material-ui/icons/AddCircle";
import { Form, Table, Col, Row, Button as AntButton, Badge, Radio, Select, Space, Input, Divider, Switch, Modal } from "antd";
import { ChatInner } from './ChatInner';
import { FiberManualRecord, } from '@material-ui/icons';

const { Option } = Select;

var stompClient = null;
export function ChatRoom({ toggleDrawer, setState, state }) {
    const [privateChats, setPrivateChats] = useState(new Map());
    const [publicChats, setPublicChats] = useState([]);
    const [message, setMessage] = useState("");
    const [chats, setChats] = useState([]);
    const [personnels, setPersonnels] = useState([]);
    const [groups, setGroups] = useState([]);
    const [groupSingle, setGroupSingle] = useState(true);
    const [modalAddGroup, setModalAddGroup] = useState(false);
    const [tab, setTab] = useState("CHATROOM");
    const stateStore = store.getState();
    const user = stateStore.auth.user;
    const [userData, setUserData] = useState({
        username: user.email,
        receivername: '',
        connected: false,
        message: ''
    });
    useEffect(() => {
        //registerUser();

        // fillChats();
        fillPersonnels();
        fillGroups();
        console.log(userData);
    }, []);

    const formRefTotalNumberOfApartments = useRef();

    const connect = () => {
        let Sock = new SockJS(API_BASE_ROOT + '/socket');
        stompClient = over(Sock);
        stompClient.connect({}, onConnected, onError);
    }

    const connectGroup = (groupName) => {
        let Sock = new SockJS(API_BASE_ROOT + '/socket');
        stompClient = over(Sock);
        stompClient.connect({}, onConnectedGroup(groupName), onError);
    }

    const onConnected = () => {
        setUserData({ ...userData, "connected": true });
        stompClient.subscribe('/chatroom/public', onMessageReceived);
        stompClient.subscribe('/user/' + userData.username + '/private', onPrivateMessage);
        userJoin();
    }

    const onConnectedGroup = (groupName) => {
        setUserData({ ...userData, "connected": true });
        stompClient.subscribe('/chatroom/private', onMessageReceived);
        userJoinGroup(groupName);
    }

    const userJoin = () => {
        var chatMessage = {
            senderName: userData.username,
            status: "JOIN"
        };
        stompClient.send("/app/message", {}, JSON.stringify(chatMessage));
    }

    const userJoinGroup = (groupName) => {
        var chatMessage = {
            senderName: userData.username,
            status: "JOIN",
            groupName: groupName
        };
        stompClient.send("/private-message", {}, JSON.stringify(chatMessage));
    }

    const onMessageReceived = (payload) => {
        console.log("payload", payload);
        var payloadData = JSON.parse(payload.body);
        switch (payloadData.status) {
            case "JOIN":
                if (!privateChats.get(payloadData.senderName)) {
                    privateChats.set(payloadData.senderName, []);
                    setPrivateChats(new Map(privateChats));
                }
                break;
            case "MESSAGE":
                publicChats.push(payloadData);
                setPublicChats([...publicChats]);
                break;
        }
    }

    const onPrivateMessage = (payload) => {
        console.log(payload);
        var payloadData = JSON.parse(payload.body);
        if (privateChats.get(payloadData.senderName)) {
            privateChats.get(payloadData.senderName).push(payloadData);
            setPrivateChats(new Map(privateChats));
        } else {
            let list = [];
            list.push(payloadData);
            privateChats.set(payloadData.senderName, list);
            setPrivateChats(new Map(privateChats));
        }
    }

    const onError = (err) => {
        console.log(err);
    }

    const handleMessage = (event) => {
        const { value } = event.target;
        setUserData({ ...userData, "message": value });
    }

    const sendValue = () => {
        if (!Boolean(userData.message)) {
            return;
        }
        if (stompClient) {
            var chatMessage = {
                senderName: userData.username,
                message: userData.message,
                status: "MESSAGE"
            };
            console.log(chatMessage);
            stompClient.send("/app/message", {}, JSON.stringify(chatMessage));
            setUserData({ ...userData, "message": "" });
        }
    }

    const sendPrivateValue = () => {
        if (!Boolean(userData.message)) {
            return;
        }
        if (stompClient) {
            var chatMessage = {
                senderName: userData.username,
                receiverName: tab,
                message: userData.message,
                status: "MESSAGE"
            };

            if (userData.username !== tab) {
                privateChats.get(tab).push(chatMessage);
                setPrivateChats(new Map(privateChats));
            }
            stompClient.send("/app/private-message", {}, JSON.stringify(chatMessage));
            setUserData({ ...userData, "message": "" });
        }
    }

    const handleUsername = (event) => {
        const { value } = event.target;
        setUserData({ ...userData, "username": value });
    }

    const registerUser = () => {
        connect();
    }

    const registerGroup = (groupName) => {
        connect();
        // connectGroup(groupName);
    }

    const sendMessage = async () => {
        const newItem = {
            message: message,
            personnelId: store.getState().auth.user.id,
            messageTime: moment().toDate(),
            onoff: 1
        }

        var response = await handleRequest("POST", "/api/chat", newItem);

        if (response.type === "ERROR") {
            error(response);
        } else {
            fillChats();
            showSuccess();
        }
    };

    const onMessageChange = e => {
        console.log(e.target.value);
        setMessage(e.target.value);
    }

    const fillPersonnels = async () => {
        const response = await handleRequest("GET", "/api/chatGroup/personnels");
        if (Boolean(response.data)) {
            setPersonnels(response.data);
        }
    };

    const fillChats = async () => {
        const response = await handleRequest("GET", "/api/chat");
        if (Boolean(response.data)) {
            setChats(response.data);
        }
    };

    const fillGroups = async () => {
        const response = await handleRequest("GET", "/api/chatGroup/chatGroups");
        if (Boolean(response.data)) {
            setGroups(response.data);
        }
    };

    const saveChatGroups = async values => {
        if (Boolean(values) && Boolean(values.personnelIds) && values.personnelIds.length > 0) {
            const newItem = {
                ...values,
            };
            var response = await handleRequest("POST", "/api/chatGroup/saveChatGroups", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                fillGroups();
                setModalAddGroup(false);
            }
        } else {
            showWarning("Not found.");
        }
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 8 }
    };

    const userStatus = 'active';
    let statusIcon;
    let statusText;

    if (userStatus === 'active') {
        statusIcon = <FiberManualRecord style={{ color: 'green' }} />;
        statusText = 'Active';
    } else if (userStatus === 'busy') {
        statusIcon = <FiberManualRecord style={{ color: 'orange' }} />;
        statusText = 'Busy';
    } else if (userStatus === 'offline') {
        statusIcon = <FiberManualRecord style={{ color: 'red' }} />;
        statusText = 'Offline';
    }



    return (
        <div>


            <Drawer anchor="right" open={state.right} onClose={toggleDrawer('right', false)}>
                <div className='d-flex flex-column flex-lg-row'>
                    <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0' style={{ height: '500px', overflowY: 'scroll' }}>
                        <div className='card card-flush'>
                            <div className='card-header pt-7' id='kt_chat_contacts_header'>
                                <form className='w-100 position-relative' autoComplete='off'>

                                    <input
                                        type='text'
                                        className='form-control form-control-solid px-15'
                                        name='search'
                                        placeholder='Search by username or email...'
                                    />
                                </form>
                            </div>

                            <div className='card-body pt-5' id='kt_chat_contacts_body'>
                                <div
                                    className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
                                    data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
                                    data-kt-scroll-offset='0px'
                                >
                                    <div className='d-flex flex-stack py-4'>
                                        <div className='d-flex align-items-center'>
                                            <div className='symbol symbol-45px symbol-circle'>
                                                <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                                                    M
                                                </span>
                                            </div>

                                            <div className='ms-5'>
                                                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                                                    Melody Macy
                                                </a>
                                                <div className='fw-bold text-gray-500'>melody@altbox.com</div>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-column align-items-end ms-2'>
                                            <span className='text-muted fs-7 mb-1'>5 hrs</span>
                                        </div>
                                    </div>

                                    <div className='separator separator-dashed d-none'></div>

                                    <div className='d-flex flex-stack py-4'>
                                        <div className='d-flex align-items-center'>
                                            <div className='symbol symbol-45px symbol-circle'>
                                                <img alt='Pic' src={toAbsoluteUrl('media/avatars/300-1.jpg')} />
                                            </div>

                                            <div className='ms-5'>
                                                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                                                    Max Smith
                                                </a>
                                                <div className='fw-bold text-gray-500'>max@kt.com</div>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-column align-items-end ms-2'>
                                            <span className='text-muted fs-7 mb-1'>20 hrs</span>
                                        </div>
                                    </div>

                                    <div className='separator separator-dashed d-none'></div>

                                    <div className='d-flex flex-stack py-4'>
                                        <div className='d-flex align-items-center'>
                                            <div className='symbol symbol-45px symbol-circle'>
                                                <img alt='Pic' src={toAbsoluteUrl('media/avatars/300-5.jpg')} />
                                            </div>

                                            <div className='ms-5'>
                                                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                                                    Sean Bean
                                                </a>
                                                <div className='fw-bold text-gray-500'>sean@dellito.com</div>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-column align-items-end ms-2'>
                                            <span className='text-muted fs-7 mb-1'>20 hrs</span>
                                            <span className='badge badge-sm badge-circle badge-light-success'>6</span>
                                        </div>
                                    </div>

                                    <div className='separator separator-dashed d-none'></div>

                                    <div className='d-flex flex-stack py-4'>
                                        <div className='d-flex align-items-center'>
                                            <div className='symbol symbol-45px symbol-circle'>
                                                <img alt='Pic' src={toAbsoluteUrl('media/avatars/300-25.jpg')} />
                                            </div>

                                            <div className='ms-5'>
                                                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                                                    Brian Cox
                                                </a>
                                                <div className='fw-bold text-gray-500'>brian@exchange.com</div>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-column align-items-end ms-2'>
                                            <span className='text-muted fs-7 mb-1'>20 hrs</span>
                                        </div>
                                    </div>

                                    <div className='separator separator-dashed d-none'></div>

                                    <div className='d-flex flex-stack py-4'>
                                        <div className='d-flex align-items-center'>
                                            <div className='symbol symbol-45px symbol-circle'>
                                                <span className='symbol-label bg-light-warning text-warning fs-6 fw-bolder'>
                                                    M
                                                </span>
                                            </div>

                                            <div className='ms-5'>
                                                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                                                    Mikaela Collins
                                                </a>
                                                <div className='fw-bold text-gray-500'>mikaela@pexcom.com</div>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-column align-items-end ms-2'>
                                            <span className='text-muted fs-7 mb-1'>1 day</span>
                                        </div>
                                    </div>

                                    <div className='separator separator-dashed d-none'></div>

                                    <div className='d-flex flex-stack py-4'>
                                        <div className='d-flex align-items-center'>
                                            <div className='symbol symbol-45px symbol-circle'>
                                                <img alt='Pic' src={toAbsoluteUrl('media/avatars/300-9.jpg')} />
                                            </div>

                                            <div className='ms-5'>
                                                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                                                    Francis Mitcham
                                                </a>
                                                <div className='fw-bold text-gray-500'>f.mitcham@kpmg.com.au</div>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-column align-items-end ms-2'>
                                            <span className='text-muted fs-7 mb-1'>5 hrs</span>
                                            <span className='badge badge-sm badge-circle badge-light-success'>6</span>
                                        </div>
                                    </div>

                                    <div className='separator separator-dashed d-none'></div>

                                    <div className='d-flex flex-stack py-4'>
                                        <div className='d-flex align-items-center'>
                                            <div className='symbol symbol-45px symbol-circle'>
                                                <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                                                    O
                                                </span>
                                                <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2'></div>
                                            </div>

                                            <div className='ms-5'>
                                                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                                                    Olivia Wild
                                                </a>
                                                <div className='fw-bold text-gray-500'>olivia@corpmail.com</div>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-column align-items-end ms-2'>
                                            <span className='text-muted fs-7 mb-1'>1 week</span>
                                        </div>
                                    </div>

                                    <div className='separator separator-dashed d-none'></div>

                                    <div className='d-flex flex-stack py-4'>
                                        <div className='d-flex align-items-center'>
                                            <div className='symbol symbol-45px symbol-circle'>
                                                <span className='symbol-label bg-light-primary text-primary fs-6 fw-bolder'>
                                                    N
                                                </span>
                                            </div>

                                            <div className='ms-5'>
                                                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                                                    Neil Owen
                                                </a>
                                                <div className='fw-bold text-gray-500'>owen.neil@gmail.com</div>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-column align-items-end ms-2'>
                                            <span className='text-muted fs-7 mb-1'>20 hrs</span>
                                            <span className='badge badge-sm badge-circle badge-light-success'>6</span>
                                        </div>
                                    </div>

                                    <div className='separator separator-dashed d-none'></div>

                                    <div className='d-flex flex-stack py-4'>
                                        <div className='d-flex align-items-center'>
                                            <div className='symbol symbol-45px symbol-circle'>
                                                <img alt='Pic' src={toAbsoluteUrl('media/avatars/300-23.jpg')} />
                                            </div>

                                            <div className='ms-5'>
                                                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                                                    Dan Wilson
                                                </a>
                                                <div className='fw-bold text-gray-500'>dam@consilting.com</div>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-column align-items-end ms-2'>
                                            <span className='text-muted fs-7 mb-1'>2 weeks</span>
                                            <span className='badge badge-sm badge-circle badge-light-warning'>9</span>
                                        </div>
                                    </div>

                                    <div className='separator separator-dashed d-none'></div>

                                    <div className='d-flex flex-stack py-4'>
                                        <div className='d-flex align-items-center'>
                                            <div className='symbol symbol-45px symbol-circle'>
                                                <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                                                    E
                                                </span>
                                                <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2'></div>
                                            </div>

                                            <div className='ms-5'>
                                                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                                                    Emma Bold
                                                </a>
                                                <div className='fw-bold text-gray-500'>emma@intenso.com</div>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-column align-items-end ms-2'>
                                            <span className='text-muted fs-7 mb-1'>1 day</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-800px mb-10 mb-lg-0' >
                        <div className='card' id='kt_chat_messenger'>
                            <div className='card-header' id='kt_chat_messenger_header'>
                                <div className='card-title'>
                                    <div className='symbol-group symbol-hover'></div>
                                    <div className='d-flex justify-content-center flex-column me-3'>
                                        <a
                                            href='#'
                                            className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
                                        >
                                            Brian Cox
                                        </a>

                                        <div className='mb-0 lh-1' style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ marginRight: '5px' }}>
                                                {statusIcon}
                                            </div>
                                            <span className='fs-7 fw-bold text-gray-500'>
                                                {statusText}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className='card-toolbar'>
                                    <div className='me-n3'>
                                        <button
                                            className='btn btn-sm btn-icon btn-active-light-primary'
                                            data-kt-menu-trigger='click'
                                            data-kt-menu-placement='bottom-end'
                                            data-kt-menu-flip='top-end'
                                        >
                                            <i className='bi bi-three-dots fs-2'></i>
                                        </button>
                                        {/* <Dropdown1 /> */}
                                    </div>
                                </div>
                            </div>
                            <ChatInner />
                        </div>
                    </div>
                </div>
            </Drawer>

            <Modal
                title={<FormattedMessage id="ChatRoom.AddNewGroup" defaultMessage="Add New Group" />}
                keyboard={false}
                maskClosable={false}
                open={modalAddGroup}
                onCancel={() => {
                    setModalAddGroup(false);
                }}
                okButtonProps={{ hidden: false }}
                onOk={() => formRefTotalNumberOfApartments.current.submit()}
                width={800}
                centered
            >
                <Form initialValues={{ remember: false }} onFinish={saveChatGroups} ref={formRefTotalNumberOfApartments}>
                    <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ChatRoom.Name" defaultMessage="Name" />}
                        name="name"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                        <Input style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ChatRoom.Person" defaultMessage="Person" />}
                        name="personnelIds"
                        rules={[
                            {
                                required: true,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                        ]}
                    >
                        <Select
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            mode="multiple"
                            style={{ width: "100%" }}
                        >
                            {Boolean(personnels) &&
                                personnels.map(i => (
                                    <Option key={i.id} value={i.id}>
                                        {i.name + " " + i.lastName}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}