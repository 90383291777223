import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined, PlusOutlined, ScheduleTwoTone, FileDoneOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import {
  Form,
  Table,
  Input,
  Select,
  Divider,
  DatePicker,
  Badge,
  Button as AntButton,
  Modal as AntModal,
  Col,
  Row
} from "antd";
import moment from "moment";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import MeetingStart from "./MeetingStart";
import MeetingMinutes from "./MeetingMinutes";
import AuditLogSchedule from "./AuditLogSchedule";
import "../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class AuditLog extends Component {
  constructor() {
    super();
    this.state = {
      formName: "Meeting",
      controllerName: "meeting",
      modalDeleteShow: false,
      hideUpdate: true,
      hideSave: false,

      show: false,
      hideInputs: true,
      lastUpdateDate: [],
      sendMeetingInvitationModal: false,
      showModalEnterPayment: false,
      id: null,
      selectedRow: null,
      tableList: [],
      filePath: "",
      fileList: [],

      attachmentPath: [],
      nodes: [], // for cascader.
      organizerSelectItems: null,
      reporterSelectItems: null,
      attendeesSelectItems: null,
      guestAttendeesSelectItems: null,
      notifyToSelectItems: null,
      guestNotifyToSelectItems: null,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      meetingId: null,
      excelData: []
    };
  }

  formRef = React.createRef();
  formAddCustomerRef = React.createRef();
  formAddGuestAttendeesRef = React.createRef();
  formEnterPaymentRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    this.fillCode("MeetingLocationId");
    this.fillCode("MeetingRequiredDocumentId");
  };

  fillComboboxes = async () => {
    var responseUsers = await handleRequest("GET", "/api/meeting/users");
    if (responseUsers.type === "ERROR") {
      error(responseUsers);
    } else {
      this.setState({
        organizerSelectItems: Boolean(responseUsers.data) ? responseUsers.data : [],
        reporterSelectItems: Boolean(responseUsers.data) ? responseUsers.data : [],
        attendeesSelectItems: Boolean(responseUsers.data) ? responseUsers.data : [],
        notifyToSelectItems: Boolean(responseUsers.data) ? responseUsers.data : []
      });
    }

    var responseGuest = await handleRequest("GET", "/api/meetingGuestAttendeesDetail/guest");
    if (responseGuest.type === "ERROR") {
      error(responseGuest);
    } else {
      this.setState({
        guestAttendeesSelectItems: Boolean(responseGuest.data) ? responseGuest.data : [],
        guestNotifyToSelectItems: Boolean(responseGuest.data) ? responseGuest.data : []
      });
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu
          row={record}
          onEditRow={this.edit.bind(this)}
          onAuditNotification={this.sendAuditNotificationModal.bind(this)}
          onAddFile={this.addFileModal.bind(this)}
          onDeleteRow={this.deleteModal.bind(this)}
          onMeetingStart={this.MeetingStartModal.bind(this)}
          onMeetingMinutes={this.MeetingMinutesModal.bind(this)}
        />
      </React.Fragment>
    );
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  showBadge = () => {
    this.setState({ show: true });
  };

  showCustomerModal = () => {
    console.log("showCustomerModal");
    this.setState({
      showAddCustomerModal: true
    });
  };

  sendAuditNotificationModal = () => {
    this.setState({
      sendAuditNotificationModal: true
    });
  };

  addFileModal = () => {
    this.setState({
      sendMeetingInvitationModal: true
    });
  };

  onClickAuditLogScheduleModal = row => {
    this.setState({
      meetingId: row,
      AuditLogScheduleShow: true
    });
  };

  MeetingStartModal = row => {
    this.setState({
      meetingId: row.id,
      meetingStartModal: true
    });
  };

  MeetingMinutesModal = row => {
    this.setState({
      meetingId: row.id,
      meetingMinutesModal: true
    });
  };

  saveGuestAttendees = async values => {
    let newItem = {
      customer: true,
      ...values
    };

    var response = await handleRequest("POST", "/api/meetingGuestAttendeesDetail", newItem);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.fillComboboxes();
      this.setState({
        showAddCustomerModal: false
      });
      showSuccess();
      this.formAddGuestAttendeesRef.current.resetFields();
    }
  };

  saveCustomer = async values => {
    let newItem = {
      customer: true,
      ...values
    };

    var response = await handleRequest("POST", "/api/vendor", newItem);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.fillComboboxes();
      this.setState({
        showAddCustomerModal: false
      });
      showSuccess();
    }
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      selectedRow: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = [];

    let filePathList = Boolean(row.attachmentPath) ? row.attachmentPath.split(",") : [];
    if (Boolean(filePathList) && filePathList.length > 0) {
      filePathList.forEach((item, index) => {
        let obj = {
          uid: index,
          name: item.split("download/")[1],
          url: item,
          thumbUrl: item
        };

        fileList.push(obj);
      });
    }

    var attendees = [];
    if (Boolean(row.meetingAttendees) && row.meetingAttendees.length > 0) {
      row.meetingAttendees.forEach(element => {
        attendees.push(element.userId);
      });
    }

    var guestAttendees = [];
    if (Boolean(row.meetingGuestAttendees) && row.meetingGuestAttendees.length > 0) {
      row.meetingGuestAttendees.forEach(element => {
        guestAttendees.push(element.meetingGuestAttendeesDetailId);
      });
    }

    var guestNotifyTo = [];
    if (Boolean(row.meetingGuestNotifyTos) && row.meetingGuestNotifyTos.length > 0) {
      row.meetingGuestNotifyTos.forEach(element => {
        guestNotifyTo.push(element.meetingGuestAttendeesDetailId);
      });
    }

    var notifyTo = [];
    if (Boolean(row.meetingNotifyTos) && row.meetingNotifyTos.length > 0) {
      row.meetingNotifyTos.forEach(element => {
        notifyTo.push(element.userId);
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      attendees: attendees,
      guestAttendees: guestAttendees,
      notifyTo: notifyTo,
      guestNotifyTo: guestNotifyTo,
      attachmentPath: fileList,
      startDate: Boolean(row.startDate) ? moment(row.startDate) : null,
      endDate: Boolean(row.endDate) ? moment(row.endDate) : null
    });
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      fileList: fileList,
      selectedRow: row
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showAttendeesCompanyModal = row => {
    this.setState({
      showAttendeesCompanyModal: true,
      ...row,
      id: row.id
    });
  };
  showAttendeesGuestModal = row => {
    this.setState({
      showAttendeesGuestModal: true,
      ...row,
      id: row.id
    });
  };
  showNotifyToCompanyModal = row => {
    this.setState({
      showNotifyToCompanyModal: true,
      ...row,
      id: row.id
    });
  };
  showNotifyToGuestModal = row => {
    this.setState({
      showNotifyToGuestModal: true,
      ...row,
      id: row.id
    });
  };

  sendAuditNotificationModal = () => {
    this.setState({
      modalAuditNotificationShow: true
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/meeting/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/meeting/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data && response.data.data) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    let pathList = [];
    if (Boolean(newItem.attachmentPath))
      newItem.attachmentPath.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) pathList.push(item.response.url);
        else if (Boolean(item.url)) pathList.push(item.url);
      });
    newItem.attachmentPath = pathList.toString();

    let meetingAttendees = [];
    if (newItem.attendees && newItem.attendees.length > 0)
      newItem.attendees.forEach(element => {
        meetingAttendees.push({ userId: element });
      });

    newItem.meetingAttendees = meetingAttendees;

    let meetingNotifyTos = [];
    if (newItem.notifyTo && newItem.notifyTo.length > 0)
      newItem.notifyTo.forEach(element => {
        meetingNotifyTos.push({ userId: element });
      });
    newItem.meetingNotifyTos = meetingNotifyTos;

    let meetingGuestAttendees = [];
    if (newItem.guestAttendees && newItem.guestAttendees.length > 0)
      newItem.guestAttendees.forEach(element => {
        meetingGuestAttendees.push({ meetingGuestAttendeesDetailId: element });
      });

    newItem.meetingGuestAttendees = meetingGuestAttendees;

    let meetingGuestNotifyTos = [];
    if (newItem.guestNotifyTo && newItem.guestNotifyTo.length > 0)
      newItem.guestNotifyTo.forEach(element => {
        meetingGuestNotifyTos.push({ meetingGuestAttendeesDetailId: element });
      });

    newItem.meetingGuestNotifyTos = meetingGuestNotifyTos;

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/meeting", newItem);
    } else {
      response = await handleRequest("PUT", "/api/meeting/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        this.onClickAuditLogScheduleModal(response.data);
        this.MeetingStartModal(response.data);
        this.MeetingMinutesModal(response.data);
      }
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        fixed: "left",
        width: 30,
        render: this.action
      },
      {
        title: "S/N",
        key: "id",
        width: 100,
        fixed: "left",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="AuditLog.auditNo" defaultMessage="Audit No" />,
        key: "auditNo",
        width: 100,
        fixed: "left",
        render: record => {
          return (
            <div>
              <ScheduleTwoTone value=" Fill Agenda" style={{ fontSize: 25 }} onClick={() => this.onClickMeetingAgendaModal(record.id)} />
            </div>
          );
        }
      },
      {
        title: <FormattedMessage id="AuditLog.auditType" defaultMessage="Audit Type" />,
        key: "auditType",
        width: 100,
        fixed: "left",
        render: record => {
          return record.auditTypeName;
        },

        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <TextArea
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.auditType) ? this.state.pagination.auditType : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  auditType: e.target.value,
                  auditTypeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.auditTypeColor) ? this.state.pagination.auditTypeColor : "#bfbfbf" }} />
        ),
        sorter: true
      },

      {
        title: <FormattedMessage id="AuditLog.scope" defaultMessage="Scope" />,
        key: "scope",
        width: 100,
        render: record => {
          return record.scopeName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <TextArea
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.scope) ? this.state.pagination.scope : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  scope: e.target.value,
                  scopeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.scopeColor) ? this.state.pagination.scopeColor : "#bfbfbf" }} />
        ),

        sorter: true
      },

      {
        title: <FormattedMessage id="AuditLog.auditDescription" defaultMessage="Audit Description" />,
        key: "auditDescription",
        width: 100,
        showTitle: true,
        ellipsis: true,

        render: record => {
          return record.auditDescription;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <TextArea
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.auditDescription) ? this.state.pagination.auditDescription : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  auditDescription: e.target.value,
                  auditDescriptionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.auditDescriptionColor) ? this.state.pagination.auditDescriptionColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },

      {
        title: <FormattedMessage id="AuditLog.auditDate" defaultMessage="Audit Date" />,
        key: "auditDate",
        width: 100,
        render: record => {
          return Boolean(record.auditDate) ? moment(record.auditDate).format("DD-MM-YYYY HH:mm") : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.auditDate) ? this.state.pagination.auditDate : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  auditDate: e.target.value,
                  auditDateColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.auditDateColor) ? this.state.pagination.auditDateColor : "#bfbfbf" }} />
        ),
        sorter: true
      },

      {
        title: <FormattedMessage id="AuditLog.auditee" defaultMessage="Auditee" />,
        key: "auditee",
        width: 100,
        render: record => {
          return record.auditeeName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <TextArea
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.auditee) ? this.state.pagination.auditee : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  auditee: e.target.value,
                  auditeeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.auditeeColor) ? this.state.pagination.auditeeColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="AuditLog.auditors" defaultMessage="Auditors" />,
        key: "auditors",
        width: 100,
        render: record => {
          return record.auditorsName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <TextArea
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.auditors) ? this.state.pagination.auditors : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  auditors: e.target.value,
                  auditorsColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.auditorsColor) ? this.state.pagination.auditorsColor : "#bfbfbf" }} />
        ),

        sorter: true
      },

      {
        title: <FormattedMessage id="AuditLog.references" defaultMessage="References" />,
        key: "references",
        width: 100,
        render: record => {
          return record.referencesName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <TextArea
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.references) ? this.state.pagination.references : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  references: e.target.value,
                  referencesColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.referencesColor) ? this.state.pagination.referencesColor : "#bfbfbf" }} />
        ),

        sorter: true
      },

      {
        title: <FormattedMessage id="AuditLog.schedule" defaultMessage="Schedule" />,
        key: "schedule",
        width: 100,
        align: "center",
        render: record => {
          return (
            <div>
              <ScheduleTwoTone value=" Fill Agenda" style={{ fontSize: 25 }} onClick={() => this.onClickAuditLogScheduleModal(record.id)} />
            </div>
          );
        }
      },
      {
        title: <FormattedMessage id="AuditLog.auditChecklist" defaultMessage="Checklist" />,
        key: "auditChecklist",
        align: "center",
        width: 100,
        render: record => {
          return (
            <div>
              <FileDoneOutlined value=" Fill Agenda" style={{ fontSize: 25 }} onClick={() => this.onClickMeetingAgendaModal(record.id)} />
            </div>
          );
        }
      },

      {
        title: <FormattedMessage id="Meeting.status" defaultMessage="Status" />,
        key: "status",
        width: 100,
        render: record => {
          return record.status;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.status) ? this.state.pagination.status : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  status: e.target.value,
                  statusColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.statusColor) ? this.state.pagination.statusColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: "Last Update",
        width: 100,
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];

      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));
    return (
      <Badge.Ribbon text="Audit" placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "3rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={23}></Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>

            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.auditNo" defaultMessage="Audit No" />}
                  name="auditNo"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input placeholder="Audit No" style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.description" defaultMessage="Audit Description" />}
                  name="description"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <TextArea placeholder="Description" style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.auditType" defaultMessage="Audit Type" />}
                  name="auditType"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                    {Boolean(this.state["auditLogAuditTypeSelectItems"]) &&
                      this.state["auditLogAuditTypeSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>

                  <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                    {Boolean(this.state["auditLogAuditTypeSelectItems"]) &&
                      this.state["auditLogAuditTypeSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.scope" defaultMessage="Scope" />}
                  name="scope"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("AuditLogScope")}
                          >
                            {<PlusOutlined />}
                            <FormattedMessage id="AuditLog.AddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["AuditLogScopeSelectItems"]) &&
                      this.state["AuditLogScopeSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.auditDate" defaultMessage="Audit Date" />}
                  name="auditDate"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <DatePicker showTime format="DD-MM-YYYY HH:mm" style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.auditee" defaultMessage="Auditee" />}
                  name="auditee"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("AuditLogAuditee")}
                          >
                            {<PlusOutlined />}
                            <FormattedMessage id="AuditLog.AddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["AuditLogAuditeeSelectItems"]) &&
                      this.state["AuditLogAuditeeSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.leadAuditor" defaultMessage="Lead Auditor" />}
                  name="leadAuditor"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("AuditLogLeadAuditor")}
                          >
                            {<PlusOutlined />}
                            <FormattedMessage id="AuditLog.AddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["AuditLogLeadAuditorSelectItems"]) &&
                      this.state["AuditLogLeadAuditorSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.auditor" defaultMessage="Auditor" />}
                  name="auditor"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("AuditLogAuditor")}
                          >
                            {<PlusOutlined />}
                            <FormattedMessage id="AuditLog.AddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["AuditLogAuditorSelectItems"]) &&
                      this.state["AuditLogAuditorSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.backupAuditor" defaultMessage="Back-up Auditor" />}
                  name="backupAuditor"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("AuditLogBackupAuditor")}
                          >
                            {<PlusOutlined />}
                            <FormattedMessage id="AuditLog.AddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["AuditLogBackupAuditorSelectItems"]) &&
                      this.state["AuditLogBackupAuditorSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.guider" defaultMessage="Guider" />}
                  name="guider"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("AuditLogAuditor")}
                          >
                            {<PlusOutlined />}
                            <FormattedMessage id="AuditLog.AddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["AuditLogGuiderSelectItems"]) &&
                      this.state["AuditLogGuiderSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.references" defaultMessage="References" />}
                  name="references"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("AuditLogReferences")}
                          >
                            {<PlusOutlined />}
                            <FormattedMessage id="AuditLog.AddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["AuditLogReferencesSelectItems"]) &&
                      this.state["AuditLogReferencesSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.auditChecklist" defaultMessage="Audit Checklist" />}
                  name="auditChecklist"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("AuditLogAuditChecklist")}
                          >
                            {<PlusOutlined />}
                            <FormattedMessage id="AuditLog.AddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["AuditLogAuditChecklistSelectItems"]) &&
                      this.state["AuditLogAuditChecklistSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              size="small"
              components={{
                header: {
                  cell: ResizableTitle
                }
              }}
              columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
              scroll={{ x: 2300, scrollToFirstRowOnChange: true }}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <AntModal
            closable={false}
            visible={this.state.modalAgendaShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ modalAgendaShow: false })}
            width={1500}
            centered
            scroll={{ x: 400, y: 400, scrollToFirstRowOnChange: true }}
          >
            <AuditLogSchedule meetingId={this.state.meetingId} random={Math.random()} />
          </AntModal>

          {this.state.meetingId && (
            <AntModal
              closable={false}
              visible={this.state.meetingStartModal}
              okButtonProps={{ hidden: true }}
              onCancel={() => this.setState({ meetingStartModal: false })}
              width={1500}
              centered
              scroll={{ x: 400, y: 400, scrollToFirstRowOnChange: true }}
            >
              <MeetingStart meetingId={this.state.meetingId} random={Math.random()} />
            </AntModal>
          )}

          {this.state.meetingId && (
            <AntModal
              closable={false}
              visible={this.state.meetingMinutesModal}
              okButtonProps={{ hidden: true }}
              onCancel={() => this.setState({ meetingMinutesModal: false })}
              width={1500}
              centered
              scroll={{ x: 400, y: 400, scrollToFirstRowOnChange: true }}
            >
              <MeetingMinutes meetingId={this.state.meetingId} random={Math.random()} />
            </AntModal>
          )}

          <AntModal
            visible={this.state.modalAuditNotificationShow}
            closable={false}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ modalAuditNotificationShow: false })}
            width={1000}
            centered
            scroll={{ x: 400, y: 400, scrollToFirstRowOnChange: true }}
          >
            <Badge.Ribbon text="Audit Notification" placement="start" color="purple" style={{ fontSize: 12 }}>
              <div className="card card-custom" style={{ padding: "3rem", minHeight: "100%" }}>
                <Form initialValues={{ remember: false }} onFinish={this.saveCustomer} onFinishFailed={onFinishFailed} ref={this.formAddCustomerRef}>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="AuditLog.auditNo" defaultMessage="Audit No" />}
                      name="auditNo"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    ></Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="AuditLog.description" defaultMessage="Audit Description" />}
                      name="description"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    ></Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="AuditLog.auditType" defaultMessage="Audit Type" />}
                      name="auditType"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    ></Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="AuditLog.scope" defaultMessage="Scope" />}
                      name="scope"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    ></Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="AuditLog.auditDate" defaultMessage="Audit Date" />}
                      name="auditDate"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    ></Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="AuditLog.auditee" defaultMessage="Auditee" />}
                      name="auditee"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    ></Form.Item>
                  }

                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="AuditLog.leadAuditor" defaultMessage="Lead Auditor" />}
                      name="leadAuditor"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    ></Form.Item>
                  }

                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="AuditLog.auditor" defaultMessage="Auditor" />}
                      name="auditor"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    ></Form.Item>
                  }

                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="AuditLog.backupAuditor" defaultMessage="Back-up Auditor" />}
                      name="backupAuditor"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    ></Form.Item>
                  }

                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="AuditLog.guider" defaultMessage="Guider" />}
                      name="guider"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    ></Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="AuditLog.references" defaultMessage="References" />}
                      name="references"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    ></Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="AuditLog.auditChecklist" defaultMessage="Audit Checklist" />}
                      name="auditChecklist"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    ></Form.Item>
                  }

                  <div hidden={this.state.hideSave}>
                    {
                      <Row gutter={[16, 16]}>
                        <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                          <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                            <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                          </Button>
                        </Col>
                        <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      </Row>
                    }
                  </div>
                  <div hidden={this.state.hideUpdate}>
                    {
                      <Row gutter={[16, 16]}>
                        <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                        <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                          <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                          </Button>
                        </Col>
                        <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                          <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                          </Button>
                        </Col>
                        <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      </Row>
                    }
                  </div>
                </Form>
              </div>
            </Badge.Ribbon>
          </AntModal>

          <AntModal
            visible={this.state.showAddCustomerModal}
            closable={false}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ showAddCustomerModal: false })}
            width={1200}
            centered
            scroll={{ x: 400, y: 400, scrollToFirstRowOnChange: true }}
          >
            <Badge.Ribbon text="Guest" placement="start" color="purple" style={{ fontSize: 12 }}>
              <div className="card card-custom" style={{ padding: "3rem", minHeight: "100%" }}>
                <Form
                  initialValues={{ remember: false }}
                  onFinish={this.saveGuestAttendees}
                  onFinishFailed={onFinishFailed}
                  ref={this.formAddGuestAttendeesRef}
                >
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="MettingAttendees.name" defaultMessage="Name" />}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="MettingAttendees.surname" defaultMessage="Surname" />}
                    name="surname"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item {...layout} label={<FormattedMessage id="MettingAttendees.email" defaultMessage="E-Mail" />} name="email">
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item {...layout} label={<FormattedMessage id="MettingAttendees.phone" defaultMessage="Phone" />} name="phone">
                    <Input style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item {...layout} label={<FormattedMessage id="MettingAttendees.company" defaultMessage="Company" />} name="company">
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item {...layout} label={<FormattedMessage id="MettingAttendees.title" defaultMessage="Title" />} name="title">
                    <Input style={{ width: "100%" }} />
                  </Form.Item>

                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button
                        id="CancelButton"
                        style={{ width: "100%" }}
                        variant="secondary"
                        onClick={() => {
                          this.formAddGuestAttendeesRef.current.resetFields();
                          this.setState({
                            showAddCustomerModal: false
                          });
                        }}
                      >
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                </Form>
              </div>
            </Badge.Ribbon>
          </AntModal>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AuditLog);
