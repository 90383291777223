import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Col, Row, Button as AntButton, InputNumber } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Select } from "antd";
import { Input } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import DraggableModal from "../../Components/DraggableModal";
import "./styles.css";

const { Option } = Select;

export class MaintenanceLogPartReplacementSchedule extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "MaintenanceLogPartReplacementSchedule",
      controllerName: "maintenanceLogPartReplacementSchedule",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      fileList: [],
      prerequisitionSelectItems: [],
      equipmentLogIdSelectItems: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  formRefPeriod = React.createRef();
  formRefTool = React.createRef();
  formRefMeasuringDevice = React.createRef();
  formRefPersonnel = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    console.log("props", this.props);
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseType = await handleRequest("GET", "/api/" + this.state.controllerName + "/type");
    if (responseType.type === "ERROR") {
      error(responseType);
    }
    var responsePeriod = await handleRequest("GET", "/api/" + this.state.controllerName + "/period");
    if (responsePeriod.type === "ERROR") {
      error(responsePeriod);
    }
    var responseEquipmentLogId = await handleRequest("GET", "/api/" + this.state.controllerName + "/equipmentLogId");
    if (responseEquipmentLogId.type === "ERROR") {
      error(responseEquipmentLogId);
    }

    this.setState(
      {
        typeSelectItems: Boolean(responseType.data) ? responseType.data : [],
        periodSelectItems: Boolean(responsePeriod.data) ? responsePeriod.data : [],
        equipmentLogIdSelectItems: Boolean(responseEquipmentLogId.data) ? responseEquipmentLogId.data : []
      },
      () => {
        this.fillMaintenanceLogPartReplacementSchedule();
      }
    );
  };

  fillMaintenanceLogPartReplacementSchedule = async () => {
    const { selectedEquipmentLog } = this.props;
    if (Boolean(selectedEquipmentLog) && selectedEquipmentLog.length === 1) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/partReplacement/" + selectedEquipmentLog[0].id);
      if (response.type === "ERROR") {
        error(response);
      }
      if (Boolean(response.data)) {
        this.searchItem(response.data.itemName);

        let maintenanceLogPartReplacementScheduleDetailList = [response.data];
        this.formRef.current.setFieldsValue({
          maintenanceLogPartReplacementScheduleDetailList: maintenanceLogPartReplacementScheduleDetailList
        });
        this.setState({
          maintenanceLogPartReplacementScheduleDetailList: maintenanceLogPartReplacementScheduleDetailList
        });
      } else {
        let maintenanceLogPartReplacementScheduleDetailList = Boolean(selectedEquipmentLog) ? selectedEquipmentLog : null;
        maintenanceLogPartReplacementScheduleDetailList[0].equipmentLogId = maintenanceLogPartReplacementScheduleDetailList[0].id;
        maintenanceLogPartReplacementScheduleDetailList[0].id = null;
        this.formRef.current.setFieldsValue({
          maintenanceLogPartReplacementScheduleDetailList: maintenanceLogPartReplacementScheduleDetailList
        });
        this.setState({
          maintenanceLogPartReplacementScheduleDetailList: maintenanceLogPartReplacementScheduleDetailList
        });
      }
    } else {
      if (Boolean(this.props) && Boolean(this.props.close)) {
        this.props.close();
      }
    }
  };

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };
    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    console.log("values", values);
    if (Boolean(values.maintenanceLogPartReplacementScheduleDetailList) && values.maintenanceLogPartReplacementScheduleDetailList.length === 1) {
      var response = await handleRequest(
        "POST",
        "/api/" + this.state.controllerName + "/saveAll",
        values.maintenanceLogPartReplacementScheduleDetailList[0]
      );
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        if (Boolean(this.props) && Boolean(this.props.close)) {
          this.props.close();
        }
      }
    } else {
      showWarning("Not found.");
    }
  };

  saveReplacement = async () => {
    const maintenanceLogPartReplacementScheduleDetailList = this.formRef.current.getFieldValue("maintenanceLogPartReplacementScheduleDetailList");
    const periods = this.formRefPeriod.current.getFieldValue("periods");
    const { fieldIndex } = this.state;
    let maintenanceLogPartReplacementScheduleDetailListIndex = maintenanceLogPartReplacementScheduleDetailList[fieldIndex];

    let equipmentLogIdSelectItems = [];
    if (Boolean(periods) && periods.length > 0) {
      periods.forEach(element => {
        if (!equipmentLogIdSelectItems.some(item => item.key === element.equipmentLogId)) {
          let row = this.state.equipmentLogIdSelectItems.find(p => p.key === element.equipmentLogId);
          equipmentLogIdSelectItems.push({ key: element.equipmentLogId, value: row != null ? row.value : "" });
        }
      });
    }
    if (Boolean(maintenanceLogPartReplacementScheduleDetailListIndex)) {
      maintenanceLogPartReplacementScheduleDetailListIndex.periods = periods;
    } else {
      maintenanceLogPartReplacementScheduleDetailList[fieldIndex] = { periods: periods };
    }
    this.formRef.current.setFieldsValue({ maintenanceLogPartReplacementScheduleDetailList: maintenanceLogPartReplacementScheduleDetailList });

    this.setState({
      showReplacementModal: false,
      [`equipmentLogIdSelectItems${fieldIndex}`]: equipmentLogIdSelectItems
    });
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  searchItem = async item => {
    console.log("item", item);
    this.setState({ loadingSearch: true });
    if (Boolean(item) && item.length > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemSearch/" + item);
      console.log("response", response.data);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          ["itemIdSelectItems"]: [],
          loadingSearch: false
        });
      } else {
        if (Boolean(response.data)) {
          this.setState({
            ["itemIdSelectItems"]: response.data,
            loadingSearch: false
          });
        } else {
          this.setState({
            ["itemIdSelectItems"]: [],
            loadingSearch: false
          });
        }
      }
    } else {
      this.setState({
        ["itemIdSelectItems"]: [],
        loadingSearch: false
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Form.List name="maintenanceLogPartReplacementScheduleDetailList">
            {(fields, { add, remove }) => {
              return (
                <div className="scrollable-table-container">
                  <TableBoot bordered responsive="sm" className="TableBoot">
                    <thead>
                      <tr>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id={"MaintenanceLogPartReplacementSchedule.Equipment"} defaultMessage={"Equipment"} />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                          <FormattedMessage id={"MaintenanceLogPartReplacementSchedule.Item"} defaultMessage={"Item"} />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id={"MaintenanceLogPartReplacementSchedule.ItemCode"} defaultMessage={"Item Code"} />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id={"MaintenanceLogPartReplacementSchedule.ReplacementPeriod"} defaultMessage={"Replacement Period"} />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map(field => {
                        return (
                          <tr key={field.key}>
                            <Form.Item noStyle hidden name={[field.name, "id"]}></Form.Item>
                            <Form.Item noStyle name={[field.name, "equipmentLogId"]}></Form.Item>
                            <td>
                              <Form.Item noStyle name={[field.name, "equipmentDescription"]}>
                                <Input style={{ width: "150px" }} readOnly />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item noStyle name={[field.name, "itemId"]}>
                                <Select
                                  style={{ width: "150px" }}
                                  allowClear
                                  showSearch
                                  onSearch={value => this.searchItem(value)}
                                  loading={this.state.loadingSearch}
                                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                  onChange={value => {
                                    let row = this.state["itemIdSelectItems"].find(p => p.key === value);
                                    if (Boolean(row) && Boolean(row.description)) {
                                      let list = this.formRef.current.getFieldValue("maintenanceLogPartReplacementScheduleDetailList");
                                      if (Boolean(list) && list.length > 0) {
                                        list[field.name].itemCode = row.description;
                                        this.formRef.current.setFieldsValue({
                                          maintenanceLogPartReplacementScheduleDetailList: list
                                        });
                                        this.forceUpdate();
                                      }
                                    }
                                  }}
                                >
                                  {Boolean(this.state["itemIdSelectItems"]) &&
                                    this.state["itemIdSelectItems"].length > 0 &&
                                    this.state["itemIdSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item noStyle name={[field.name, "itemCode"]}>
                                <Input readOnly style={{ width: "150px" }} />
                              </Form.Item>
                            </td>
                            <td>
                              <AntButton
                                type="primary"
                                onClick={() =>
                                  this.setState(
                                    {
                                      showReplacementModal: true,
                                      fieldIndex: field.name
                                    },
                                    () => {
                                      let list = this.formRef.current.getFieldValue("maintenanceLogPartReplacementScheduleDetailList");
                                      if (Boolean(list) && list.length > 0) {
                                        let periods = [];
                                        if (Boolean(list[field.name]) && Boolean(list[field.name].periods)) {
                                          periods = list[field.name].periods;
                                          periods.forEach(element => {
                                            element.typeValue = Boolean(element.type) && element.type === "Periodic" ? "Every" : "On";
                                          });
                                        }
                                        this.formRefPeriod.current.setFieldsValue({
                                          periods: periods
                                        });
                                      }
                                    }
                                  )
                                }
                              >
                                Replacement{" "}
                              </AntButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </TableBoot>
                </div>
              );
            }}
          </Form.List>

          <br />
          <br />
          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}>
              <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
              </Button>
            </Col>
          </Row>
        </Form>

        <DraggableModal
          centered
          width={800}
          title={<FormattedMessage id={"MaintenanceLogPartReplacementSchedule.Replacement"} defaultMessage={"Replacement"} />}
          open={this.state.showReplacementModal}
          okButtonProps={{ hidden: false }}
          onCancel={() => this.setState({ showReplacementModal: false })}
          onOk={() => this.formRefPeriod.current.submit()}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveReplacement} ref={this.formRefPeriod}>
              {
                <Form.List name="periods">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered responsive="xl">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"MaintenanceLogPartReplacementSchedule.Type"} defaultMessage={"Type"} />
                              </th>
                              <th colSpan="3" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"MaintenanceLogPartReplacementSchedule.Quantity"} defaultMessage={"Period"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "type"]}>
                                      <Select
                                        style={{ width: "150px" }}
                                        onChange={value => {
                                          let list = this.formRefPeriod.current.getFieldValue("periods");
                                          if (Boolean(list) && list.length > 0) {
                                            list[field.name].typeValue = Boolean(value) && value === "Periodic" ? "Every" : "On";
                                            this.formRefPeriod.current.setFieldsValue({
                                              periods: list
                                            });
                                            this.forceUpdate();
                                          }
                                        }}
                                      >
                                        {Boolean(this.state["typeSelectItems"]) &&
                                          this.state["typeSelectItems"].length > 0 &&
                                          this.state["typeSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "typeValue"]}>
                                      <Input noStyle style={{ width: "150px" }} readOnly />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "periodValue"]}>
                                      <InputNumber
                                        noStyle
                                        min={0}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "period"]}>
                                      <Select style={{ width: "150px" }}>
                                        {Boolean(this.state["periodSelectItems"]) &&
                                          this.state["periodSelectItems"].length > 0 &&
                                          this.state["periodSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"MaintenanceLogPartReplacementSchedule.AddNew"} defaultMessage={"Add New"} />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              }
            </Form>
          }
        />
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceLogPartReplacementSchedule);
