import { Col, Row } from "antd";
import React from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const FormSaveButtonsComponent = props => {
  const { onCancel } = props;
  const onCancelClick = e => {
    onCancel();
    return;
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
          <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={onCancelClick}>
            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
          </Button>
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
          <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
            <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
          </Button>
        </Col>
        <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
      </Row>
    </>
  );
};

export default FormSaveButtonsComponent;
