import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Form, Table, Input, Select, InputNumber, Checkbox, Divider, DatePicker, Button as AntButton, Col, Row, Modal as AntModal, Tag } from "antd";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import PaymentLog from "./PaymentLog";
import IncomeLog from "./IncomeLog";
const { RangePicker } = DatePicker;
const { Option } = Select;

export class PaymentPlanSchedule extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      showScheduleButtons: true,
      showPaymentModal: false,

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      paymentGroupIdSelectItems: null,
      reasonForPaymentIdSelectItems: null,
      paymentTypeSelectItems: null,
      paymentModeSelectItems: null,
      scheduleTypeSelectItems: null,
      scheduleAmountTypeSelectItems: null,
      creditorSelectItems: null,
      periodSelectItems: null,
      bankAccountsSelectItems: [],
      cashLogSelectItems: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    fillFilterOperations();
  };

  fillComboboxes = async () => {
    var responsepaymentGroupId = await handleRequest("GET", "/api/codes/type/paymentGroupId");
    if (responsepaymentGroupId.type === "ERROR") {
      error(responsepaymentGroupId);
    } else {
      this.setState({
        paymentGroupIdSelectItems: Boolean(responsepaymentGroupId.data) ? responsepaymentGroupId.data : []
      });
    }
    var responsereasonForPaymentId = await handleRequest("GET", "/api/codes/type/reasonForPaymentId");
    if (responsereasonForPaymentId.type === "ERROR") {
      error(responsereasonForPaymentId);
    } else {
      this.setState({
        reasonForPaymentIdSelectItems: Boolean(responsereasonForPaymentId.data) ? responsereasonForPaymentId.data : []
      });
    }
    var responsepaymentType = await handleRequest("GET", "/api/paymentPlan/paymentTypes");
    if (responsepaymentType.type === "ERROR") {
      error(responsepaymentType);
    } else {
      this.setState({
        paymentTypeSelectItems: Boolean(responsepaymentType.data) ? responsepaymentType.data : []
      });
    }
    var responsepaymentMode = await handleRequest("GET", "/api/paymentPlan/paymentModes");
    if (responsepaymentMode.type === "ERROR") {
      error(responsepaymentMode);
    } else {
      this.setState({
        paymentModeSelectItems: Boolean(responsepaymentMode.data) ? responsepaymentMode.data : []
      });
    }
    var responsescheduleType = await handleRequest("GET", "/api/paymentPlan/scheduleTypes");
    if (responsescheduleType.type === "ERROR") {
      error(responsescheduleType);
    } else {
      this.setState({
        scheduleTypeSelectItems: Boolean(responsescheduleType.data) ? responsescheduleType.data : []
      });
    }
    var responsescheduleAmountType = await handleRequest("GET", "/api/paymentPlan/scheduleAmountTypes");
    if (responsescheduleAmountType.type === "ERROR") {
      error(responsescheduleAmountType);
    } else {
      this.setState({
        scheduleAmountTypeSelectItems: Boolean(responsescheduleAmountType.data) ? responsescheduleAmountType.data : []
      });
    }

    var responsePeriod = await handleRequest("GET", "/api/paymentPlan/periodTypes");
    if (responsePeriod.type === "ERROR") {
      error(responsePeriod);
    } else {
      this.setState({
        periodSelectItems: Boolean(responsePeriod.data) ? responsePeriod.data : []
      });
    }

    var responseDeptor = await handleRequest("GET", "/api/paymentPlan/creditors");
    if (responseDeptor.type === "ERROR") {
      error(responseDeptor);
    } else {
      this.setState({
        creditorSelectItems: Boolean(responseDeptor.data) ? responseDeptor.data : []
      });
    }

    var responseBankAccounts = await handleRequest("GET", "/api/paymentPlan/bankAccounts");
    if (responseBankAccounts.type === "ERROR") {
      error(responseBankAccounts);
    } else {
      this.setState({
        bankAccountsSelectItems: Boolean(responseBankAccounts.data) ? responseBankAccounts.data : []
      });
    }

    var responseCashLog = await handleRequest("GET", "/api/paymentPlan/cashLogs");
    if (responseCashLog.type === "ERROR") {
      error(responseCashLog);
    } else {
      this.setState({
        cashLogSelectItems: Boolean(responseCashLog.data) ? responseCashLog.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = [];
    let scheduleList = [];
    if (Boolean(row.scheduleList)) {
      scheduleList = row.scheduleList.map((item, index) => {
        return {
          ...item,
          key: index,
          id: null,
          date: item.date ? moment(item.date) : null
        };
      });
    }

    console.log("scheduleList", scheduleList);

    this.formRef.current.setFieldsValue({
      ...row,
      paymentDate: Boolean(row.paymentDate) ? moment(row.paymentDate) : null,
      firstDateForPeriod: Boolean(row.firstDateForPeriod) ? moment(row.firstDateForPeriod) : null,
      scheduleList: scheduleList
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      paymentType: row.paymentType,
      scheduleType: row.scheduleType,
      scheduleAmountType: row.scheduleAmountType,
      amount: row.amount,
      fixedAmount: row.fixedAmount,
      period: row.period,
      firstDateForPeriod: Boolean(row.firstDateForPeriod) ? moment(row.firstDateForPeriod) : null,
      paymentMode: row.paymentMode
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  paymentModal = row => {
    this.setState({
      showPaymentModal: true,
      selectedIncomePlan: row
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/paymentPlan/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/incomePlanSchedule/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let tempList = response.data.data;
        let list = [];
        tempList.forEach((element, index) => {
          element.index = index + 1;
          list.push({
            index: index + 1,
            ...element.incomePlan,
            id: element.id,
            incomePlanId: element.incomePlan.id,
            amount: element.amount,
            date: element.date,
            incomeLogs: element.incomeLogs
          });
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/paymentPlan/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    if (this.getStatusAsString(record.incomeLogs, record.amount) === "Received")
      return (
        <React.Fragment>
          <ActionMenu row={record} onEditRow={this.paymentModal.bind(this)} />
        </React.Fragment>
      );
    else
      return (
        <React.Fragment>
          <ActionMenu row={record} onReceive={this.paymentModal.bind(this)} />
        </React.Fragment>
      );
  };

  createElementWithPeriodAndAmount = (period, firstDateForPeriod, index, fixedAmount) => {
    let element = { key: index, date: null, amount: fixedAmount };

    if (Boolean(period) && Boolean(firstDateForPeriod)) {
      let firstDate = firstDateForPeriod.toDate();
      switch (period) {
        case "DAILY":
          element.date = moment(firstDate).add(index, "days");

          break;
        case "WEEKLY":
          element.date = moment(firstDate).add(index, "weeks");
          break;
        case "MONTHLY":
          element.date = moment(firstDate).add(index, "months");
          break;
        case "YEARLY":
          element.date = moment(firstDate).add(index, "years");
          break;

        default:
          break;
      }
    }

    return element;
  };

  calculateScheduleList = async () => {
    const { scheduleType, scheduleAmountType, amount, fixedAmount, period, firstDateForPeriod } = this.state;

    if (!Boolean(scheduleType)) {
      showWarning("Please select schedule type");
      return;
    }

    if (!Boolean(scheduleAmountType)) {
      showWarning("Please select schedule amount type");
      return;
    }

    if (scheduleAmountType === "FIXED") {
      if (!Boolean(amount)) {
        showWarning("Please enter amount");
        return;
      }

      if (!Boolean(fixedAmount)) {
        showWarning("Please enter fixed amount");
        return;
      }
    }

    if (scheduleAmountType === "VARIABLE") {
      if (!Boolean(amount)) {
        showWarning("Please enter amount");
        return;
      }
    }

    if (scheduleType === "PERIODIC") {
      if (!Boolean(period)) {
        showWarning("Please select period");
        return;
      }

      if (!Boolean(firstDateForPeriod)) {
        showWarning("Please select first date for period");
        return;
      }
    }

    switch (scheduleType) {
      case "VARIABLEDATES":
        if (Boolean(scheduleAmountType) && scheduleAmountType === "FIXED") {
          if (Boolean(amount) && Boolean(fixedAmount)) {
            let k = amount % fixedAmount;
            let schduleListCount = Math.ceil(amount / fixedAmount);
            let schduleList = [];
            if (k > 0) schduleListCount = schduleListCount - 1;
            for (let index = 0; index < schduleListCount; index++) {
              const element = { key: index, date: null, amount: fixedAmount };
              schduleList.push(element);
            }

            if (k > 0) {
              const element = { key: schduleListCount, date: null, amount: k };
              schduleList.push(element);
            }

            this.formRef.current.setFieldsValue({
              scheduleList: schduleList
            });
          }
          this.setState({
            showScheduleButtons: false
          });
        }

        if (Boolean(scheduleAmountType) && scheduleAmountType === "VARIABLE") {
          this.setState({
            showScheduleButtons: true
          });
        }

        break;

      case "PERIODIC":
        if (Boolean(scheduleAmountType) && scheduleAmountType === "FIXED") {
          if (Boolean(amount) && Boolean(fixedAmount)) {
            let k = amount % fixedAmount;
            let schduleListCount = Math.ceil(amount / fixedAmount);
            let schduleList = [];
            if (k > 0) schduleListCount = schduleListCount - 1;
            for (let index = 0; index < schduleListCount; index++) {
              const element = this.createElementWithPeriodAndAmount(period, firstDateForPeriod, index, fixedAmount);
              schduleList.push(element);
            }

            if (k > 0) {
              const element = this.createElementWithPeriodAndAmount(period, firstDateForPeriod, schduleListCount, k);
              schduleList.push(element);
            }

            this.formRef.current.setFieldsValue({
              scheduleList: schduleList
            });
          }
          this.setState({
            showScheduleButtons: false
          });
        }

        if (Boolean(scheduleAmountType) && scheduleAmountType === "VARIABLE") {
          this.setState({
            showScheduleButtons: true
          });
        }

        break;

      default:
        break;
    }
  };

  addNewSchedule = async () => {
    const { scheduleType, scheduleAmountType, amount, fixedAmount, period, firstDateForPeriod } = this.state;

    if (!Boolean(scheduleType)) {
      showWarning("Please select schedule type");
      return;
    }

    if (!Boolean(scheduleAmountType)) {
      showWarning("Please select schedule amount type");
      return;
    }

    if (scheduleAmountType === "FIXED") {
      if (!Boolean(amount)) {
        showWarning("Please enter amount");
        return;
      }

      if (!Boolean(fixedAmount)) {
        showWarning("Please enter fixed amount");
        return;
      }
    }

    if (scheduleAmountType === "VARIABLE") {
      if (!Boolean(amount)) {
        showWarning("Please enter amount");
        return;
      }
    }

    if (scheduleType === "PERIODIC") {
      if (!Boolean(period)) {
        showWarning("Please select period");
        return;
      }

      if (!Boolean(firstDateForPeriod)) {
        showWarning("Please select first date for period");
        return;
      }
    }
    let schduleList = Boolean(this.formRef.current.getFieldValue("scheduleList")) ? this.formRef.current.getFieldValue("scheduleList") : [];

    if (scheduleType === "VARIABLEDATES") {
      const element = { key: schduleList.length, date: null, amount: null };
      schduleList.push(element);

      this.formRef.current.setFieldsValue({
        scheduleList: schduleList
      });
    }

    if (scheduleType === "PERIODIC") {
      const element = this.createElementWithPeriodAndAmount(
        period,
        firstDateForPeriod,
        Boolean(schduleList) && schduleList.length > 0 ? schduleList.length : 0,
        null
      );
      schduleList.push(element);

      this.formRef.current.setFieldsValue({
        scheduleList: schduleList
      });
    }
  };

  getStatus = (list, amount) => {
    if (Boolean(amount)) {
      if (Boolean(list) && list.length > 0) {
        let total = 0;
        list.map(item => {
          total = total + item.amount;
        });
        if (total === amount) {
          return (
            <Tag color="green">
              {" "}
              <FormattedMessage id="IncomePlanSchedule.Received" defaultMessage="Received" />{" "}
            </Tag>
          );
        } else {
          return (
            <Tag color="yellow">
              {" "}
              <FormattedMessage id="IncomePlanSchedule.partiallyReceived" defaultMessage="Partially Received" />{" "}
            </Tag>
          );
        }
      } else
        return (
          <Tag color="red">
            {" "}
            <FormattedMessage id="IncomePlanSchedule.notReceived" defaultMessage="Not Received" />{" "}
          </Tag>
        );
    }
  };

  getStatusAsString = (list, amount) => {
    if (Boolean(amount)) {
      if (Boolean(list) && list.length > 0) {
        let total = 0;
        list.map(item => {
          total = total + item.amount;
        });
        if (total === amount) {
          return "Received";
        } else {
          return "Partially Received";
        }
      } else return "Not Received";
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="IncomePlan.incomeGroupId" defaultMessage="Income Group" />,
        key: "incomeGroupId",
        render: record => {
          return record.incomeGroupName;
        },
        ...getColumnFilter({
          inputName: "incomeGroupId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["incomeGroupIdSelectItems"]) ? this.state["incomeGroupIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }),

        sorter: true
      },
      {
        title: <FormattedMessage id="IncomePlan.incomeDescription" defaultMessage="Income Description" />,
        key: "incomeDescription",
        render: record => {
          return record.incomeDescription;
        },
        ...getColumnFilter({
          inputName: "incomeDescription",
          inputType: "TEXT",
          selectItems: Boolean(this.state["incomeDescriptionSelectItems"]) ? this.state["incomeDescriptionSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }),

        sorter: true
      },
      {
        title: <FormattedMessage id="IncomePlan.reasonForIncomeId" defaultMessage="Reason For Income" />,
        key: "reasonForIncomeId",
        render: record => {
          return record.reasonForIncomeName;
        },
        ...getColumnFilter({
          inputName: "reasonForIncomeId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["reasonForIncomeIdSelectItems"]) ? this.state["reasonForIncomeIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }),

        sorter: true
      },
      {
        title: <FormattedMessage id="IncomePlan.deptorId" defaultMessage="Deptor" />,
        key: "deptorId",
        render: record => {
          return record.deptorName;
        },
        ...getColumnFilter({
          inputName: "deptorId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["deptorIdSelectItems"]) ? this.state["deptorIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }),

        sorter: true
      },

      {
        title: <FormattedMessage id="PaymentPlan.paymentDate" defaultMessage="Date" />,
        key: "date",
        render: record => {
          if (Boolean(record.date)) return moment(record.date).format("DD/MM/YYYY");
        },
        ...getColumnFilter({
          inputName: "date",
          inputType: "SELECT",
          selectItems: Boolean(this.state["dateSelectItems"]) ? this.state["dateSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }),

        sorter: true
      },
      {
        title: <FormattedMessage id="PaymentPlan.amount" defaultMessage="Total Amount" />,
        key: "amount",
        render: record => {
          return record.amount;
        },
        ...getColumnFilter({
          inputName: "amount",
          inputType: "SELECT",
          selectItems: Boolean(this.state["amountSelectItems"]) ? this.state["amountSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }),

        sorter: true
      },
      {
        title: <FormattedMessage id="PaymentPlan.status" defaultMessage="Status" />,
        key: "status",
        render: record => {
          let a = this.getStatus(record.incomeLogs, record.amount);
          return a;
        }
      },
      {
        title: <FormattedMessage id="PaymentPlan.paymentMode" defaultMessage="Payment" />,
        key: "paymentMode",
        render: record => {
          if (Boolean(record.incomeLogs) && record.incomeLogs.length > 0) {
            let a = record.incomeLogs.map(item => {
              return (
                <Tag key={item.id} color={item.paymentMode === "CASH" ? "green" : "blue"}>
                  {item.paymentMode} : {item.amount}
                </Tag>
              );
            });

            return a;
          }
        }
      },
      {
        title: <FormattedMessage id="PaymentPlan.includeVat" defaultMessage="Include Vat" />,
        key: "includeVat",
        render: record => {
          return record.includeVat;
        },
        ...getColumnFilter({
          inputName: "includeVat",
          inputType: "SELECT",
          selectItems: Boolean(this.state["includeVatSelectItems"]) ? this.state["includeVatSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }),

        sorter: true
      },
      {
        title: <FormattedMessage id="PaymentPlan.paymentMode" defaultMessage="Mode Of Payment" />,
        key: "paymentMode",
        render: record => {
          return record.paymentMode;
        },

        ...getColumnFilter({
          inputName: "paymentMode",
          inputType: "SELECT",
          selectItems: Boolean(this.state["paymentModeSelectItems"]) ? this.state["paymentModeSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }),

        sorter: true
      },

      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        ...getColumnFilter({
          inputName: "lastUpdateDate",
          inputType: "SELECT",
          selectItems: Boolean(this.state["lastUpdateDateSelectItems"]) ? this.state["lastUpdateDateSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }),
         sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
              exportClick: false
            })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 8,
          offset: 0
        },
        sm: {
          span: 8,
          offset: 8
        }
      }
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        {this.state.showPaymentModal && (
          <AntModal
            centered
            width={1500}
            title="Receive Detail"
            visible={this.state.showPaymentModal}
            onCancel={() => {
              this.setState({ showPaymentModal: false });
              const { pagination } = this.state;
              this.restartTable({ pagination });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showPaymentModal: false
                  });
                  const { pagination } = this.state;
                  this.restartTable({ pagination });
                }}
              >
                Close
              </Button>
            ]}
          >
            {this.state.showPaymentModal && (
              <IncomeLog
                incomePlanId={this.state.selectedIncomePlan.incomePlanId}
                incomePlanScheduleId={this.state.selectedIncomePlan.id}
                totalAmount={this.state.selectedIncomePlan.amount}
              ></IncomeLog>
            )}
          </AntModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPlanSchedule);
