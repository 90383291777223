import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Button as AntButton, Col, Form, Row, Checkbox, InputNumber, Modal as AntModal, Input, Select } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import DataTableComponent2 from "../../Components/DataTableComponent2";
import ElementComponent from "../../Components/Element";
import { GetDynamicFormItems, PrepareRowForEdit, PrepareRowForSave } from "../../Components/ElementUtils";
import { error, showError, showSuccess } from "../../MessageHelper";
import ActionMenu from "../CustomMenu/ActionMenu";
import { ExcelReader } from "../excel/ExcelReader";
import { PlusOutlined, MinusCircleOutlined, ArrowRightOutlined, UndoOutlined } from "@ant-design/icons";
import Features from "./Features";
import MathJax from "react-mathjax2";
import { Table } from "react-bootstrap";
import SettingsIcon from "@material-ui/icons/Settings";
import DraggableModal from "../../Components/DraggableModal";
import ItemCodeCriteriaOptions from "./ItemCodeCriteriaOptions";

const { Option } = Select;

const generateItemList = data => {
  let itemList = [];
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    itemList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.valuecreateOperator
    });
    if (node.children && node.children.length > 0) {
      itemList.push(...generateItemList(node.children));
    }
  }
  return itemList;
};

function createOperator(operator) {
  console.log(operator);
  switch (operator) {
    case "add":
      return "+";
    case "subtract":
      return "-";
    case "multiply":
      return "*";
    case "divide":
      return "/";
    case "sqrt":
      return "sqrt";
    case "pow":
      return "^";
    case "open_parenthesis":
      return "(";
    case "close_parenthesis":
      return ")";
    default:
      return "";
  }
}
export class EldenItem extends Component {
  constructor() {
    super();
    this.state = {
      formName: "EldenItem",
      controllerName: "eldenItem",
      modalDeleteShow: false,
      showBreakDownModal: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      tableList: [],
      restartTable: false,
      pageFormData: null,
      excelData: [],
      createWhereParams: null,
      refreshForm: false,
      dimensionList: [],
      selectDimensionList: [],
      mathConstantList: [],
      mathConstantValue: null,
      numberConstant: null,
      numberConstantValue: null,
      operatorList: [],
      operatorValue: null,
      itemFeatureFormula: [],
      formulaText: "",
      features: [],
      dimensions: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    let data = await GetDynamicFormItems(this.state.formName);
    this.setState({
      pageFormData: data
    });

    this.fillComboboxes(data.pageFormInputCompanyList);
  };
  componentDidUpdate = async nextProps => { };
  fillComboboxes = async inputList => {
    inputList.forEach(async (item, index) => {
      if (item.inputType === "CASCADER") {
        let url = item.loadUrl;
        var response = await handleRequest("GET", url);
        if (response.type === "ERROR") {
          showError(response);
        } else {
          let list = generateItemList(response.data);
          this.setState({
            [item.inputName + "Cascader"]: list
          });
        }
      }
    });

    var responseOperators = await handleRequest("GET", "/api/eldenItem/operators");
    if (responseOperators.type === "ERROR") {
      error(responseOperators);
    } else {
      this.setState({
        operatorList: Boolean(responseOperators.data) ? responseOperators.data : []
      });
    }

    var responseMathConstants = await handleRequest("GET", "/api/eldenItem/mathconstants");
    if (responseMathConstants.type === "ERROR") {
      error(responseMathConstants);
    } else {
      this.setState({
        mathConstantList: Boolean(responseMathConstants.data) ? responseMathConstants.data : []
      });
    }

    var responseproductFormId = await handleRequest("GET", "/api/" + this.state.controllerName + "/productForm");
    if (responseproductFormId.type === "ERROR") {
      error(responseproductFormId);
    }
    this.setState({
      productFormIdSelectItems: Boolean(responseproductFormId.data) ? responseproductFormId.data : []
    });

  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      dimensionList: [],
      selectDimensionList: []
    });
  };

  setData = data => {
    this.setState({
      tableList: data
    });
  };

  edit = row => {
    let handledValues = {
      ...row
    };
    handledValues = PrepareRowForEdit(handledValues, this.state.pageFormData.pageFormInputCompanyList);
    this.state.pageFormData.pageFormInputCompanyList.forEach(item => {
      if (item.inputType === "CASCADER" && this.state[item.inputName + "Cascader"]) {
        let itemId = this.state[item.inputName + "Cascader"].find(p => p.key === row[item.inputName]);
        if (itemId) {
          handledValues[item.inputName] = itemId.lineage;
        }
      }
    });

    var selectDimensionList = [];
    if (Boolean(row.itemDimension) && row.itemDimension.length > 0) {
      row.itemDimension.forEach(element => {
        if (Boolean(element.itemDefinition)) {
          selectDimensionList.push({ definition: element.itemDefinition, checked: true });
        }
      });
    }

    let grade = false;
    let codeCriterias = [];
    if (Boolean(handledValues) && Boolean(handledValues.codeCriterias) && handledValues.codeCriterias.length > 0) {
      handledValues.codeCriterias.forEach(element => {
        if (element.itemDefinition === "Grade") {
          grade = true;
        } else {
          codeCriterias.push(element);
        }
      });
    } else {
      if (Boolean(handledValues) && Boolean(handledValues.itemCodeCriterias) && handledValues.itemCodeCriterias.length > 0) {
        handledValues.itemCodeCriterias.forEach(element => {
          if (element.itemDefinition === "Grade") {
            grade = true;
          } else {
            codeCriterias.push(element);
          }
        });
      }
    }

    this.formRef.current.setFieldsValue({
      ...handledValues,
      selectDimensionList: selectDimensionList,
      grade: grade,
      codeCriterias: codeCriterias,
    });
    console.log("handledValues", handledValues);
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      selectDimensionList: selectDimensionList,
      processing: row.processing,
      standard: row.standard,
      features: row.features
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async () => {
    this.setState({ restartTable: !this.state.restartTable });
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    console.log("values", values);
    let newItem = {
      id: this.state.id,
      ...values
    };
    newItem = PrepareRowForSave(newItem, this.state.pageFormData.pageFormInputCompanyList);
    var itemDimension = [];

    const { selectDimensionList } = this.state;
    if (Boolean(values.dimension) && selectDimensionList && selectDimensionList.length > 0) {
      selectDimensionList.forEach(element => {
        if (element.checked) {
          itemDimension.push({
            itemDefinition: element.definition
          });
        }
      });
    }

    newItem.itemDimension = itemDimension;

    var codeCriterias = Boolean(values.codeCriterias) && values.codeCriterias.length > 0 ? values.codeCriterias : [];
    if (values.grade) {
      let itemGrade = codeCriterias.find(p => p.itemDefinition === "Grade")
      if (!Boolean(itemGrade)) {
        codeCriterias.push({ "itemDefinition": "Grade" });
      }
    }
    newItem.codeCriterias = codeCriterias;
    newItem.itemCodeCriterias = codeCriterias;

    newItem.features = this.state.features;

    // if (newItem.features && newItem.features.length > 0) {
    //   newItem.features.forEach(element => {
    //     if (Boolean(element.formulaText) && element.formulaText.length > 0) {
    //       element.formulaText = "";
    //     }
    //     if (element.itemFeatureFormula && element.itemFeatureFormula.length > 0) {
    //       element.itemFeatureFormula.forEach(formula => {
    //         if (Boolean(formula.formulaText) && formula.formulaText.length > 0) {
    //           formula.formulaText = "";
    //         }
    //       });
    //     }
    //   });
    // }

    console.log("XXX", newItem);
    console.log("newItem.features", newItem.features);
    if (!Boolean(newItem.id)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      showError(response);
    } else {
      this.cancel();
      this.restartTable();
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          this.state.pageFormData.pageFormInputCompanyList.forEach(input => {
            Boolean(input.tableColumnValue) ? (obj[input.tableColumnValue] = element[input.label]) : (obj[input.inputName] = element[input.label]);
          });
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            this.restartTable();
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  /*  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            },

             {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };
*/
  action = record => {
    return (
      <React.Fragment>
        <ActionMenu
          row={record}
          onDeleteRow={this.deleteModal.bind(this)}
          onEditRow={this.edit.bind(this)}
          onFeatures={this.addBreakDownModal.bind(this)}
        />
      </React.Fragment>
    );
  };

  addBreakDownModal = record => {
    this.setState({
      showBreakDownModal: true
      //selectedGapAnalysisCompanyId: record.id
    });
  };

  addToFormulaList = index => {
    const { features } = this.state;
    let field = features[index];
    let dimensions =
      Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("dimensionList"))
        ? this.formRef.current.getFieldValue("dimensionList")
        : [];
    let formulaObject = { constant: field.constant, numberConstant: field.numberConstant, operator: field.operator };
    if (!Boolean(field.itemFeatureFormula)) {
      field.itemFeatureFormula = [];
    }

    formulaObject.indexOrder = Boolean(field.itemFeatureFormula) ? field.itemFeatureFormula.length + 1 : 0;
    if (Boolean(field.dimensionKey)) {
      let d = dimensions.find(x => x.abbreviation === field.dimensionKey);
      formulaObject.dimensionAbbreviation = d.abbreviation;
      formulaObject.dimension = d.definition;
    }
    field.itemFeatureFormula.push(formulaObject);
    field.formulaText = this.getformulaText(field.itemFeatureFormula);
    console.log(field.itemFeatureFormula);
    console.log(field.formulaText);
    field.dimensionKey = null;
    field.constant = null;
    field.numberConstant = null;
    field.operator = null;
    this.setState({ features });
  };

  removeFromFormulaList = index => {
    const { features } = this.state;
    let field = features[index];
    if (field.itemFeatureFormula && field.itemFeatureFormula.length > 0) {
      field.itemFeatureFormula.pop();
      field.formulaText = this.getformulaText(field.itemFeatureFormula);
      this.setState({ features });
    }
  };

  getformulaText = formulaList => {
    let formulaText = "";
    if (Boolean(formulaList) && formulaList.length > 0) {
      formulaList.forEach(element => {
        let constant = Boolean(element.constant)
          ? element.constant
          : Boolean(element.dimensionAbbreviation)
            ? element.dimensionAbbreviation
            : Boolean(element.numberConstant)
              ? element.numberConstant
              : "";
        let operator = Boolean(element.operator) ? createOperator(element.operator) : "";
        formulaText += constant + operator;
      });
    }
    return formulaText;
  };

  addFeature = () => {
    const { features } = this.state;
    let key = 0;
    if (Boolean(features) && features.length > 0) {
      key = features[features.length - 1].key + 1;
    }
    if (key === 0) {
      features.push({ key: key, definition: "Weight", formulaText: "" });
    } else features.push({ key: key, definition: "", formulaText: "" });

    this.setState({ features });
  };

  removeFeature = index => {
    const { features } = this.state;
    features.splice(index, 1);
    this.setState({ features });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    let dimensions =
      Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("dimensionList"))
        ? this.formRef.current.getFieldValue("dimensionList")
        : [];

    console.log("DIMENSIONS", dimensions);

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    const layoutFormula = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      style: {
        marginBottom: 0
      }
    };

    const layoutDimension = {
      labelCol: { span: 9 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename={this.state.formName} hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
              {Boolean(this.state.pageFormData) &&
                this.state.pageFormData.pageFormInputCompanyList.map(item => {
                  return (
                    <ExcelColumn
                      key={item.inputName}
                      label={item.label}
                      value={Boolean(item.tableColumnValue) ? item.tableColumnValue : item.inputName}
                    />
                  );
                })}
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col md={21}>
            <span className="h3">
              <FormattedMessage
                id={this.state.controllerName + ".pageTitle"}
                defaultMessage={Boolean(this.state.pageFormData) ? this.state.pageFormData.pageForm.title : ""}
              />
            </span>
          </Col>
          <Col md={1}>{excelExport}</Col>
          <Col md={1}>
            <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
          </Col>
          <Col md={1}>
            <AntButton
              style={{ border: "0px" }}
              hidden={!this.state.hideInputs}
              onClick={this.onClickCreateNew}
              icon={<CreateIcon color="secondary" fontSize="large" />}
            ></AntButton>
            <AntButton
              style={{ border: "0px" }}
              hidden={this.state.hideInputs}
              onClick={this.onClickCreateNew}
              icon={<RemoveCircle color="error" fontSize="large" />}
            ></AntButton>
          </Col>
        </Row>
        <div hidden={this.state.hideInputs}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EldenItem.ProductForm" defaultMessage="Product Form" />}
                name="productFormId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["productFormIdSelectItems"]) &&
                    this.state["productFormIdSelectItems"].length > 0 &&
                    this.state["productFormIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }
            {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
              <>
                {Boolean(this.state.pageFormData.pageFormInputCompanyList) && this.state.pageFormData.pageFormInputCompanyList.length > 0
                  ? this.state.pageFormData.pageFormInputCompanyList.map((field, i) =>
                    field.inputName === "dimensionList" ? (
                      <>
                        <ElementComponent key={i} field={field} layout={layout} form={this.formRef} refresh={this.state.refreshForm} />
                        <>
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="EldenItem.CodeCriterias" defaultMessage="Code Criterias" />}
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                            valuePropName="checked"
                          >
                            {
                              <Form.Item
                                name="dimension"
                                rules={[
                                  { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                                valuePropName="checked"
                                className="mb-0"
                              >
                                <Checkbox
                                  onChange={e => {
                                    let dimensionList = Boolean(this.formRef.current.getFieldValue("dimensionList"))
                                      ? this.formRef.current.getFieldValue("dimensionList")
                                      : [];
                                    if (e.target.checked && Boolean(dimensionList) && dimensionList.length > 0) {
                                      dimensionList.forEach(element => {
                                        element.checked = false;
                                      });
                                    }

                                    this.setState({
                                      selectDimensionList: [],
                                      showDimensionModal: e.target.checked,
                                      dimensionList: dimensionList
                                    });
                                  }}
                                >
                                  {<FormattedMessage id="EldenItem.dimension" defaultMessage="Dimensions" />}
                                </Checkbox>
                              </Form.Item>
                            }

                            {this.state.selectDimensionList.map(field => (
                              <>
                                <Form.Item
                                  {...layoutDimension}
                                  label={field.definition}
                                  name={field.definition}
                                  rules={[
                                    {
                                      required: false,
                                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                    }
                                  ]}
                                  valuePropName="checked"
                                  initialValue={field.checked}
                                >
                                  <Checkbox
                                    onChange={e => {
                                      var selectDimension = this.state.selectDimensionList.find(p => p.definition === field.definition);
                                      if (Boolean(selectDimension)) {
                                        selectDimension.checked = e.target.checked;
                                        this.setState({
                                          selectDimensionList: this.state.selectDimensionList
                                        });
                                      }
                                    }}
                                  ></Checkbox>
                                </Form.Item>
                              </>
                            ))}
                            {
                              <Form.Item
                                {...layout}
                                name="standard"
                                rules={[
                                  { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                                valuePropName="checked"
                              >
                                <Checkbox
                                  onChange={e => {
                                    this.setState({ standard: e.target.checked });
                                  }}
                                >
                                  {<FormattedMessage id="EldenItem.standard" defaultMessage="Standard" />}
                                </Checkbox>
                              </Form.Item>
                            }
                            {this.state.standard && (
                              <Form.Item
                                {...layout}
                                name="grade"
                                rules={[
                                  { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                                valuePropName="checked"
                              >
                                <Checkbox>{<FormattedMessage id="EldenItem.grade" defaultMessage="Grade" />}</Checkbox>
                              </Form.Item>
                            )}

                            {
                              <Form.Item
                                {...layout}
                                name="processing"
                                rules={[
                                  { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                                valuePropName="checked"
                              >
                                <Checkbox
                                  onChange={e => {
                                    this.setState({
                                      processing: e.target.checked
                                    });
                                  }}
                                >
                                  {" "}
                                  {<FormattedMessage id="EldenItem.processing" defaultMessage="Processing" />}
                                </Checkbox>
                              </Form.Item>
                            }
                            {Boolean(this.state.processing) && (
                              <Form.Item
                                {...layoutDimension}
                                label={<FormattedMessage id="EldenItem.processingCutting" defaultMessage="Cutting" />}
                                name="processingCutting"
                                rules={[
                                  { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                                valuePropName="checked"
                              >
                                <Checkbox></Checkbox>
                              </Form.Item>
                            )}
                            {Boolean(this.state.processing) && (
                              <Form.Item
                                {...layoutDimension}
                                label={<FormattedMessage id="EldenItem.processingMilling" defaultMessage="Milling" />}
                                name="processingMilling"
                                rules={[
                                  { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                                valuePropName="checked"
                              >
                                <Checkbox></Checkbox>
                              </Form.Item>
                            )}

                            {Boolean(this.state.processing) && (
                              <Form.Item
                                {...layoutDimension}
                                label={<FormattedMessage id="EldenItem.processingDrilling" defaultMessage="Drilling" />}
                                name="processingDrilling"
                                rules={[
                                  { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                                valuePropName="checked"
                              >
                                <Checkbox></Checkbox>
                              </Form.Item>
                            )}

                            {Boolean(this.state.processing) && (
                              <Form.Item
                                {...layoutDimension}
                                name="processingCoating"
                                label={<FormattedMessage id="EldenItem.processingCoating" defaultMessage="Coating" />}
                                rules={[
                                  { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                                valuePropName="checked"
                              >
                                <Checkbox></Checkbox>
                              </Form.Item>
                            )}

                            <Form.List name="codeCriterias">
                              {/* {(fields, { add, remove }) => (
                                <>
                                  {fields.map((field, index) => (
                                    <Form.Item
                                      {...field}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                      className="mb-0"
                                    >
                                      <Form.Item
                                        {...field}
                                        style={{
                                          display: "inline-block",
                                          width: "calc(80%)"
                                        }}
                                        name={[field.name, "itemDefinition"]}
                                      >
                                        <Input></Input>
                                      </Form.Item>

                                      <Form.Item {...field} hidden name={[field.name, "id"]}>
                                        <InputNumber></InputNumber>
                                      </Form.Item>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </Form.Item>
                                  ))}
                                  <Form.Item>
                                    <Row gutter={[16, 16]}>
                                      <Col md={12}>
                                        <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}></AntButton>
                                      </Col>
                                    </Row>
                                  </Form.Item>
                                </>
                              )} */}

                              {(fields, { add, remove }) => {
                                const handleAddClick = () => {
                                  add();
                                };

                                const handleRemoveClick = (fieldKey) => {
                                  remove(fieldKey);
                                };

                                const handleSettingsClick = (fieldKey) => {
                                  let row = this.formRef.current.getFieldValue("codeCriterias")[fieldKey];
                                  console.log("row", row);
                                  this.setState({
                                    showItemCodeCriteriaModal: true,
                                    itemCodeCriteriaId: row.id
                                  });
                                };

                                return (
                                  <>
                                    <TableBoot bordered size="sm">
                                      <thead>
                                        <tr>
                                          <th colSpan="1" style={{ textAlign: "center" }}>
                                            <FormattedMessage id={"EldenItem.Description"} defaultMessage={"Description"} />
                                          </th>
                                          <th colSpan="1" style={{ textAlign: "center" }}>
                                            <FormattedMessage id={"EldenItem.IdentificationType"} defaultMessage={"Identification Type"} />
                                          </th>
                                          <th colSpan="1" style={{ textAlign: "center" }}>
                                            <FormattedMessage id={"EldenItem.Options"} defaultMessage={"Options"} />
                                          </th>
                                          <th colSpan="1">
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {fields.map(field => {
                                          return (
                                            <tr key={field.key}>
                                              <Form.Item noStyle name={[field.name, "id"]}>
                                                <Input hidden style={{ width: "300px" }} />
                                              </Form.Item>
                                              <td>
                                                <Form.Item noStyle name={[field.name, "itemDefinition"]}>
                                                  <Input style={{ width: "300px" }} />
                                                </Form.Item>
                                              </td>
                                              <td>
                                                <Form.Item noStyle name={[field.name, "identificationType"]}>
                                                  <Select showSearch allowClear optionFilterProp="children" style={{ width: "300px" }}>
                                                    <Option key={"Selective"} value={"Selective"}>
                                                      {"Selective"}
                                                    </Option>
                                                    <Option key={"Encoding_Data"} value={"Encoding_Data"}>
                                                      {"Encoding Data"}
                                                    </Option>
                                                  </Select>
                                                </Form.Item>
                                              </td>
                                              <td>
                                                {Boolean(this.formRef.current.getFieldValue("codeCriterias")) &&
                                                  Boolean(this.formRef.current.getFieldValue("codeCriterias")[field.name]) &&
                                                  Boolean(this.formRef.current.getFieldValue("codeCriterias")[field.name].id) &&
                                                  <SettingsIcon onClick={() => handleSettingsClick(field.name)} />
                                                }
                                              </td>
                                              <td>
                                                <MinusCircleOutlined onClick={() => handleRemoveClick(field.name)} />
                                              </td>
                                            </tr>
                                          );
                                        })}
                                        <tr>
                                          <td colSpan={11}>
                                            <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                                              <FormattedMessage id={"EldenItem.AddNew"} defaultMessage={"Add New"} />
                                            </AntButton>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </TableBoot>
                                  </>
                                );
                              }}
                            </Form.List>

                          </Form.Item>
                          <Form.Item {...layoutFormula} label={<FormattedMessage id="EldenItem.features" defaultMessage="Features" />}>
                            <Table borderless size="sm">
                              <thead>
                                {Boolean(this.state.features) && this.state.features.length > 0 && (
                                  <tr>
                                    <th>
                                      <FormattedMessage id={"item.feature.FeatureName"} defaultMessage={"Feature"} />
                                    </th>
                                    <th>
                                      <FormattedMessage id={"item.feature.dimension"} defaultMessage={"Dimension"} />
                                    </th>
                                    <th>
                                      <FormattedMessage id={"item.feature.mathConstant"} defaultMessage={"Constant"} />
                                    </th>
                                    <th>
                                      <FormattedMessage id={"item.feature.numericConstant"} defaultMessage={"Numeric Constant"} />
                                    </th>
                                    <th>
                                      <FormattedMessage id={"item.feature.Operator"} defaultMessage={"Operator"} />
                                    </th>
                                    <th>
                                      <FormattedMessage id={"item.feature.AddToFormula"} defaultMessage={"Add"} />
                                    </th>
                                    <th>
                                      <FormattedMessage id={"item.feature.undoFormFormula"} defaultMessage={"Undo"} />
                                    </th>
                                    <th>
                                      <FormattedMessage id={"item.feature.mathematicalFormula"} defaultMessage={"Formula"} />
                                    </th>
                                    <th>
                                      <FormattedMessage id="GENERAL.Remove" defaultMessage="Remove" />
                                    </th>
                                  </tr>
                                )}
                              </thead>
                              <tbody>
                                {Boolean(this.state.features) &&
                                  this.state.features.map((field, index) => {
                                    return (
                                      <tr>
                                        <td>
                                          {" "}
                                          <Input
                                            value={field.definition}
                                            onChange={e => {
                                              const { features } = this.state;
                                              features[index].definition = e.target.value;
                                              this.setState({ features });
                                            }}
                                          ></Input>
                                        </td>
                                        <td>
                                          <Select
                                            allowClear
                                            style={{ width: "100%" }}
                                            placeholder="Dimensions"
                                            value={field.dimensionKey}
                                            onChange={value => {
                                              const { features } = this.state;
                                              features[index].dimensionKey = value;
                                              features[index].constant = null;
                                              features[index].numberConstant = null;
                                              this.setState({ features });
                                            }}
                                          >
                                            {Boolean(dimensions) &&
                                              dimensions.map(i => (
                                                <Option key={i.abbreviation} value={i.abbreviation}>
                                                  {i.definition}
                                                </Option>
                                              ))}{" "}
                                          </Select>
                                        </td>
                                        <td>
                                          <Select
                                            allowClear
                                            style={{ width: "100%" }}
                                            placeholder="Constants"
                                            value={field.constant}
                                            onChange={value => {
                                              const { features } = this.state;
                                              features[index].dimensionKey = null;
                                              features[index].constant = value;
                                              features[index].numberConstant = null;
                                              this.setState({ features });
                                            }}
                                          >
                                            {Boolean(this.state.mathConstantList) &&
                                              this.state.mathConstantList.map(i => (
                                                <Option key={i.key} value={i.key}>
                                                  {i.value}
                                                </Option>
                                              ))}{" "}
                                          </Select>
                                        </td>
                                        <td>
                                          <InputNumber
                                            value={field.numberConstant}
                                            onChange={value => {
                                              const { features } = this.state;
                                              features[index].dimensionKey = null;
                                              features[index].constant = null;
                                              features[index].numberConstant = value;
                                              this.setState({ features });
                                            }}
                                          ></InputNumber>
                                        </td>
                                        <td>
                                          <Select
                                            allowClear
                                            value={field.operator}
                                            style={{ width: "100%" }}
                                            placeholder="Operator"
                                            onChange={value => {
                                              const { features } = this.state;
                                              features[index].operator = value;
                                              this.setState({ features });
                                            }}
                                          >
                                            {Boolean(this.state.operatorList) &&
                                              this.state.operatorList.map(i => (
                                                <Option key={i.key} value={i.key}>
                                                  {i.value}
                                                </Option>
                                              ))}{" "}
                                          </Select>
                                        </td>
                                        <td>
                                          <ArrowRightOutlined onClick={() => this.addToFormulaList(index)} />
                                        </td>
                                        <td>
                                          <UndoOutlined onClick={() => this.removeFromFormulaList(index)} />
                                        </td>
                                        <td>
                                          <div>
                                            <MathJax.Context input="ascii">
                                              <div>
                                                <MathJax.Node>{field.formulaText}</MathJax.Node>
                                              </div>
                                            </MathJax.Context>
                                          </div>
                                        </td>
                                        <td>
                                          <MinusCircleOutlined onClick={() => this.removeFeature(index)} />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                <tr>
                                  <td colSpan={8} className="justify-content-end">
                                    <AntButton type="dashed" onClick={() => this.addFeature()} block icon={<PlusOutlined />}>
                                      <FormattedMessage id={"Item.features.AddNewButton"} defaultMessage={"Add New Feature"} />
                                    </AntButton>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Form.Item>
                          {/* <Form.List name="features">
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map((field, index) => (
                                    <Form.Item
                                      {...layoutFormula}
                                      {...field}
                                      label={<FormattedMessage id="EldenItem.features" defaultMessage="Features" />}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                      className="mb-0"
                                    >
                                      <Form.Item
                                        {...field}
                                        style={{
                                          display: "inline-block",
                                          width: "calc(20%)"
                                        }}
                                        name={[field.name, "definition"]}
                                      >
                                        <Input></Input>
                                      </Form.Item>

                                      <Form.Item
                                        {...field}
                                        style={{
                                          display: "inline-block",
                                          width: "calc(10%)"
                                        }}
                                        name={[field.name, "dimensionId"]}
                                      >
                                        <Select
                                          style={{ width: "100%" }}
                                          placeholder="Dimensions"
                                          onChange={value => {
                                            this.setState({
                                              dimensionValue: value,
                                              mathConstantValue: null,
                                              numberConstantValue: null
                                            });
                                          }}
                                        >
                                          {Boolean(dimensions) &&
                                            dimensions.map(i => (
                                              <Option key={i.abbreviation} value={i.abbreviation}>
                                                {i.definition}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                      <Form.Item
                                        {...field}
                                        style={{
                                          display: "inline-block",
                                          width: "calc(10%)"
                                        }}
                                        name={[field.name, "constantId"]}
                                      >
                                        <Select
                                          style={{ width: "100%" }}
                                          placeholder="Constants"
                                          onChange={value => {
                                            this.setState({
                                              dimensionValue: null,
                                              mathConstantValue: value,
                                              numberConstantValue: null
                                            });
                                          }}
                                        >
                                          {Boolean(this.state.mathConstantList) &&
                                            this.state.mathConstantList.map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                      <Form.Item
                                        {...field}
                                        style={{
                                          display: "inline-block",
                                          width: "calc(10%)"
                                        }}
                                        name={[field.name, "numberConstant"]}
                                      >
                                        <InputNumber
                                          onChange={value => {
                                            this.setstate({
                                              dimensionValue: null,
                                              mathConstantValue: null,
                                              numberConstantValue: value
                                            });
                                          }}
                                        ></InputNumber>
                                      </Form.Item>

                                      <Form.Item
                                        {...field}
                                        style={{
                                          display: "inline-block",
                                          width: "calc(10%)"
                                        }}
                                        name={[field.name, "operatorId"]}
                                      >
                                        <Select
                                          style={{ width: "100%" }}
                                          placeholder="Constants"
                                          onChange={value => {
                                            this.setState({
                                              operatorValue: value
                                            });
                                          }}
                                        >
                                          {Boolean(this.state.operatorList) &&
                                            this.state.operatorList.map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                      <ArrowRightOutlined onClick={() => this.addToFormulaList()} />
                                      <UndoOutlined onClick={() => this.removeFromFormulaList()} />

                                      <Form.Item
                                        {...field}
                                        style={{
                                          display: "inline-block",
                                          width: "calc(10%)"
                                        }}
                                        name={[field.name, "formula"]}
                                      >
                                        <div>
                                          <MathJax.Context input="ascii">
                                            <div>
                                              <MathJax.Node>{this.state.formulaText}</MathJax.Node>
                                            </div>
                                          </MathJax.Context>
                                        </div>
                                      </Form.Item>

                                      <Form.Item {...field} hidden name={[field.name, "id"]}>
                                        <InputNumber></InputNumber>
                                      </Form.Item>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </Form.Item>
                                  ))}
                                  <Form.Item>
                                    <Row gutter={[16, 16]}>
                                      <Col md={12}>
                                        <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}></AntButton>
                                      </Col>
                                    </Row>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List> */}
                        </>
                      </>
                    ) : (
                      <ElementComponent key={i} field={field} layout={layout} form={this.formRef} refresh={this.state.refreshForm} />
                    )
                  )
                  : null}
              </>
            ) : null}
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </Form>
        </div>
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
            <DataTableComponent2
              key="dataTable"
              field={this.state.pageFormData.pageForm}
              inputs={this.state.pageFormData.pageFormInputCompanyList}
              actions={this.action}
              editRow={this.edit.bind(this)}
              restartTable={this.state.restartTable}
              createWhereParams={this.state.createWhereParams}
              setData={this.setData.bind(this)}
            />
          ) : null}
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {this.state.showBreakDownModal && (
          <DraggableModal
            centered
            width={1500}
            title="Features"
            visible={this.state.showBreakDownModal}
            onCancel={() => {
              this.setState({ showBreakDownModal: false });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showBreakDownModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
            content={
              this.state.showBreakDownModal && <Features></Features>
            }
          />
        )}

        <DraggableModal
          centered
          width={600}
          title="Dimensions"
          visible={this.state.showDimensionModal}
          onCancel={() => {
            this.setState({ showDimensionModal: false });
          }}
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  showDimensionModal: false
                });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
          content={
            this.state.dimensionList.map(field => (
              <Row>
                <Checkbox
                  checked={field.checked}
                  valuePropName="checked"
                  onChange={e => {
                    if (e.target.checked) {
                      field.checked = true;
                      var dimension = { definition: field.definition, checked: true };
                      this.setState(prevState => ({
                        selectDimensionList: [
                          ...prevState.selectDimensionList,
                          {
                            ...dimension
                          }
                        ]
                      }));
                    } else {
                      field.checked = false;
                      this.setState({
                        selectDimensionList: this.state.selectDimensionList.filter(p => p.definition !== field.definition)
                      });
                    }
                  }}
                >
                  {field.definition}
                </Checkbox>
              </Row>
            ))
          }
        />

        <DraggableModal
          centered
          width={800}
          title="Options"
          visible={this.state.showItemCodeCriteriaModal}
          onCancel={() => {
            this.setState({ showItemCodeCriteriaModal: false });
          }}
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  showItemCodeCriteriaModal: false
                });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
          content={
            <ItemCodeCriteriaOptions itemCodeCriteriaId={this.state.itemCodeCriteriaId}
              random={Math.random()} />
          }
        />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EldenItem);