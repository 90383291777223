import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Card, DatePicker, Image, Input } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../../redux/store";
import { Select } from "antd";
import { fillFilterOperations } from "../../../Components/DataTableFilterComponent";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

export class AssignmentSingle extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "AssignmentSingle",
      controllerName: "assignment",
      id: null,
      loading: false,
      loadingSearch: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: false,
      user: store.getState().auth.user,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();

    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.resetFields();

      this.setState({
        personnelPhotoPath: ""
      });
    }
  }

  fillComboboxes = async () => {
    var responseDepartments = await handleRequest("GET", "/api/" + this.state.controllerName + "/departments");
    if (responseDepartments.type === "ERROR") {
      error(responseDepartments);
    } else {
      this.setState({
        departmentIdSelectItems: Boolean(responseDepartments.data) ? responseDepartments.data : []
      });
    }

    var responseDesignationId = await handleRequest("GET", "/api/" + this.state.controllerName + "/designations");
    if (responseDesignationId.type === "ERROR") {
      error(responseDesignationId);
    } else {
      this.setState({
        designationIdSelectItems: Boolean(responseDesignationId.data) ? responseDesignationId.data : []
      });
    }

    var responseAssignmentProjectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/assignmentProjects");
    if (responseAssignmentProjectId.type === "ERROR") {
      error(responseAssignmentProjectId);
    } else {
      this.setState({
        assignmentProjectIdSelectItems: Boolean(responseAssignmentProjectId.data) ? responseAssignmentProjectId.data : []
      });
    }

    var responseCompanyProjectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/companyProjectId");
    if (responseCompanyProjectId.type === "ERROR") {
      error(responseCompanyProjectId);
    } else {
      this.setState({
        companyProjectIdSelectItems: Boolean(responseCompanyProjectId.data) ? responseCompanyProjectId.data : []
      });
    }

    var responseWorkLocationId = await handleRequest("GET", "/api/" + this.state.controllerName + "/workLocations");
    if (responseWorkLocationId.type === "ERROR") {
      error(responseWorkLocationId);
    } else {
      this.setState({
        workLocationIdSelectItems: Boolean(responseWorkLocationId.data) ? responseWorkLocationId.data : []
      });
    }

    var responsePersonnelLogId = await handleRequest("GET", "/api/" + this.state.controllerName + "/personnelLogId");
    if (responsePersonnelLogId.type === "ERROR") {
      error(responsePersonnelLogId);
    } else {
      this.setState({
        personnelLogIdSelectItems: Boolean(responsePersonnelLogId.data) ? responsePersonnelLogId.data : []
      });
    }
  };

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = async (row) => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    let startDate = null;
    let endDate = null;
    if (Boolean(values.date) && values.date.length > 1) {
      startDate = values.date[0];
      endDate = values.date[1];
    }
    newItem.startDate = startDate;
    newItem.endDate = endDate;

    console.log("newItem", newItem);
    var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.props.close();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillPersonnelLastAssign = async (personnelLogId) => {
    if (Boolean(personnelLogId) && personnelLogId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillPersonnelLastAssign/" + personnelLogId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        let data = response.data;
        this.formRef.current.setFieldsValue({
          departmentIdRight: data.departmentName,
          designationIdRight: data.designationName,
          branchProjectLocationRight: data.branchProjectLocationName,
          startDateRight: Boolean(data.startDate) ? moment(data.startDate).format("DD-MM-YYYY HH:mm") : null,
          endDateRight: Boolean(data.endDate) ? moment(data.endDate).format("DD-MM-YYYY HH:mm") : null,
        });
      }
    } else {
      this.formRef.current.setFieldsValue({
        departmentIdRight: "",
        designationIdRight: "",
        branchProjectLocationRight: "",
        startDateRight: null,
        endDateRight: null,
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layoutLeft = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };

    const layoutRight = {
      labelCol: { span: 12 },
      wrapperCol: { span: 12 }
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <div hidden={this.state.hideInputs}>
            <Row gutter={16} style={{ marginBottom: "16px" }}>
              <Col span={15}>
                <Card
                  title={<FormattedMessage id="AssignmentSingle.NewAssignment" defaultMessage="New Assignment" />}
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="AssignmentSingle.Person" defaultMessage="Person" />}
                      name="personnelLogId"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children"
                        onChange={value => {
                          let personnelPhotoPath = "";
                          if (Boolean(value)) {
                            let personnel = this.state["personnelLogIdSelectItems"].find(p => p.id === value);
                            if (Boolean(personnel)) {
                              if (Boolean(personnel.photoPath)) {
                                personnelPhotoPath = personnel.photoPath;
                              }
                            }
                          }
                          this.setState({
                            personnelPhotoPath: personnelPhotoPath,
                          });
                          this.fillPersonnelLastAssign(value);
                        }}>
                        {Boolean(this.state["personnelLogIdSelectItems"]) &&
                          this.state["personnelLogIdSelectItems"].length > 0 &&
                          this.state["personnelLogIdSelectItems"].map(i => (
                            <Option key={i.id} value={i.id}>
                              {i.name + " " + i.lastName}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="AssignmentSingle.Branch" defaultMessage="Branch" />}
                      name="assignmentProjectId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["assignmentProjectIdSelectItems"]) &&
                          this.state["assignmentProjectIdSelectItems"].length > 0 &&
                          this.state["assignmentProjectIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="AssignmentSingle.Project" defaultMessage="Project" />}
                      name="companyProjectId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children"
                        onChange={value => {
                          if (Boolean(value)) {
                            let startDate = null;
                            let endDate = null;

                            let companyProject = this.state["companyProjectIdSelectItems"].find(p => p.id === value);
                            if (Boolean(companyProject)) {
                              if (Boolean(companyProject.startDate)) {
                                startDate = companyProject.startDate;
                              }
                              if (Boolean(companyProject.endDate)) {
                                endDate = companyProject.endDate;
                              }
                            }
                            this.formRef.current.setFieldsValue({
                              date: [Boolean(startDate) ? moment(startDate) : null, Boolean(endDate) ? moment(endDate) : null]
                            });
                          }
                        }}>
                        {Boolean(this.state["companyProjectIdSelectItems"]) &&
                          this.state["companyProjectIdSelectItems"].length > 0 &&
                          this.state["companyProjectIdSelectItems"].map(i => (
                            <Option key={i.id} value={i.id}>
                              {i.projectFullName}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="AssignmentSingle.Location" defaultMessage="Location" />}
                      name="workLocationId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["workLocationIdSelectItems"]) &&
                          this.state["workLocationIdSelectItems"].length > 0 &&
                          this.state["workLocationIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="AssignmentSingle.Date" defaultMessage="Date" />}
                      name="date"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <RangePicker
                        format="DD-MM-YYYY HH:mm"
                        showTime={{ format: "HH:mm" }}
                        onChange={(dates, dateStrings) => {
                          this.setState({
                            dates: dates
                          });
                        }}
                      />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="AssignmentSingle.Department" defaultMessage="Department" />}
                      name="departmentId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["departmentIdSelectItems"]) &&
                          this.state["departmentIdSelectItems"].length > 0 &&
                          this.state["departmentIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="AssignmentSingle.Designation" defaultMessage="Designation" />}
                      name="designationId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["designationIdSelectItems"]) &&
                          this.state["designationIdSelectItems"].length > 0 &&
                          this.state["designationIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                </Card>
              </Col>

              <Col span={9}>
                <Card
                  title={<FormattedMessage id="AssignmentSingle.CurrentAssignment" defaultMessage="Current Assignment" />}
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  {
                    Boolean(this.state.personnelPhotoPath) &&
                    <Image
                      width={200}
                      src={this.state.personnelPhotoPath}
                    />
                  }

                  {
                    <Form.Item
                      {...layoutRight}
                      label={<FormattedMessage id="AssignmentSingle.Department" defaultMessage="Department" />}
                      name="departmentIdRight"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Input readOnly style={{ width: "100%" }} />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layoutRight}
                      label={<FormattedMessage id="AssignmentSingle.Designation" defaultMessage="Designation" />}
                      name="designationIdRight"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Input readOnly style={{ width: "100%" }} />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layoutRight}
                      label={<FormattedMessage id="AssignmentSingle.BranchProjectLocation" defaultMessage="Branch/Project/Location" />}
                      name="branchProjectLocationRight"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Input readOnly style={{ width: "100%" }} />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layoutRight}
                      label={<FormattedMessage id="AssignmentSingle.StartDate" defaultMessage="Start Date" />}
                      name="startDateRight"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Input readOnly style={{ width: "100%" }} />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layoutRight}
                      label={<FormattedMessage id="AssignmentSingle.EndDate" defaultMessage="End Date" />}
                      name="endDateRight"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Input readOnly style={{ width: "100%" }} />
                    </Form.Item>
                  }

                </Card>
              </Col>
            </Row>

            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form >

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(AssignmentSingle);