import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Tree, Form, Badge, Row, Col } from "antd";
import { Button } from "react-bootstrap";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../../ApiConnector";
import { showError, showSuccess, error } from "../../../MessageHelper";
import DraggableModal from "../../../Components/DraggableModal";

export class BudgetCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "BudgetCategory",
      controllerName: "budgetCategory",
      id: null,
      description: "",
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      materialTypeIds: [],
      loadedKeys: [],
      searchValue: "",
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      copy: false,
      copiedId: null,
      refreshTree: true
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    this.fillTree();
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/tree");
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      let checkedKeys = [];
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.title = element.label;
          element.key = element.value;

          if (element.checked) {
            checkedKeys.push(element.value);
          }

          if (Boolean(element.children) && element.children.length > 0) {
            element.children.forEach(element => {
              element.title = element.label;
              element.key = element.value;

              if (element.checked) {
                checkedKeys.push(element.value);
              }
            });
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true,
          checkedKeys: checkedKeys,
        });
      } else {
        this.setState({
          refreshTree: true,
          checkedKeys: [],
        });
      }
    }
  };

  delete = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (!Boolean(this.state.parentNode))
          this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteModal: false
        });
      }
    } else {
      showError("Page not found.");
    }
  };

  save = async () => {
    let { checkedKeys } = this.state;
    console.log("checkedKeys", checkedKeys);
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveCategories", checkedKeys);
    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
      if (Boolean(this.props) && Boolean(this.props.close)) {
        this.props.close();
      }
    }
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      description: "",
      code: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      process: null,
      showOrderModal: false,
      showPasteModal: false,
      copy: false,
      copiedId: null
    });
  };

  onCheck = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    if (Boolean(this.props) && Boolean(this.props.onTargetKeysCheckCompanyAddProduct)) {
      console.log("checkedKeys", e.checkedNodes);
      console.log("checkedKeys", e.halfCheckedKeys);
      this.props.onTargetKeysCheckCompanyAddProduct(checkedKeys.checked);
    }
    this.setState({
      checkedKeys: checkedKeys,
      halfCheckedKeys: e.halfCheckedKeys,
      checkedNodes: e.checkedNodes
    });
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          {this.state.refreshTree && (
            <Tree
              style={{ width: "100%" }}
              checkable
              defaultExpandAll
              showLine={true}
              onCheck={this.onCheck}
              checkedKeys={this.state.checkedKeys}
              halfCheckedKeys={this.state.halfCheckedKeys}
              treeData={this.state.nodes}
            />
          )}
          <br />
          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
              </Button>
            </Col>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
          </Row>
        </Form>

        <DraggableModal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={400}
          maskClosable={false}
          open={this.state.showDeleteModal}
          okText={<FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />}
          onOk={this.delete}
          onCancel={() => {
            this.setState({ showDeleteModal: false });
          }}
          content={
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          }
        />
      </div>
    );

  }
}
const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BudgetCategory);