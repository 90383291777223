import { SearchOutlined } from "@ant-design/icons";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import { Button as AntButton, Badge, Col, DatePicker, Form, PageHeader, Row, Select, Table, Tabs, Typography } from "antd";
import "antd/dist/antd.css";
import { Button } from "react-bootstrap";
import moment from "moment";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error } from "../../MessageHelper";
import { IncomeExpenseReport } from "./IncomeExpenseReport";
import { SalesSummary } from "./SalesSummary";
import DraggableModal from "../../Components/DraggableModal";
import "./style.css";
import { excelExport } from "../../ExcelHelper";

const { Option } = Select;
const { Text } = Typography;
const { TabPane } = Tabs;

export class SalesReport extends Component {

  constructor({ disableProductNotExist }) {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      exportClickProfit: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      disableProductNotExist: disableProductNotExist,
      tableList: [],
      tableListProfit: [],
      tableListPrice: [],
      lineChart: [],
      pieChart: [],
      startDate: moment().subtract(1, "M"),
      endDate: moment(),
      customerIds: null,
      productIds: null,
      salesmanIds: null,
      customerSelectItems: [],
      productSelectItems: [],
      salesmanSelectItems: [],
      show: false,
      showSalesInvoiceReport: false,
      salesInvoiceReportList: [],
      salesTotalReportList: [],


      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      paginationProfit: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
    };
  };

  componentDidMount = async () => {
    this.restartTable();
    this.fillComboboxes();
  };

  fillSmallTable = async () => {
    let newObj = {}
    var responseInvoiceProduct = await handleRequest("POST", "/api/salesReport/smallTable", newObj);
    if (responseInvoiceProduct.type === "ERROR") {
      error(responseInvoiceProduct);
    } else {
      this.setState({
        salesInvoiceReportList: Boolean(responseInvoiceProduct.data) ? responseInvoiceProduct.data : []
      });
    }
  };

  fillBigTable = async () => {
    let newObj = {}
    var responseinvoice = await handleRequest("POST", "/api/salesReport/bigTable", newObj);
    if (responseinvoice.type === "ERROR") {
      error(responseinvoice);
    } else {
      this.setState({
        salesTotalReportList: Boolean(responseinvoice.data) ? responseinvoice.data : []
      });
    }
  };

  fillComboboxes = async () => {
    var responseCustomer = await handleRequest("GET", "/api/invoiceProduct/customerIdSelectItems");
    if (responseCustomer.type === "ERROR") {
      error(responseCustomer);
    } else {
      this.setState({
        customerSelectItems: Boolean(responseCustomer.data) ? responseCustomer.data : []
      });
    }
    var responseProduct = await handleRequest("GET", "/api/invoiceProduct/top50product");
    if (responseProduct.type === "ERROR") {
      error(responseProduct);
    } else {
      this.setState({
        productSelectItems: Boolean(responseProduct.data) ? responseProduct.data : []
      });
    }

    var responseSalesman = await handleRequest("GET", "/api/invoiceProduct/salesman");
    if (responseSalesman.type === "ERROR") {
      error(responseProduct);
    } else {
      this.setState({
        salesmanSelectItems: Boolean(responseSalesman.data) ? responseSalesman.data : []
      });
    }
  };

  restartTable = async () => {
    const { startDate, endDate, customerIds, productIds, salesmanIds } = this.state;
    this.setState({ loading: true });
    let obj = {
      startDate: startDate
        ? startDate
        : moment()
          .subtract(1, "M")
          .toDate(),
      endDate: endDate ? endDate : moment().toDate(),
      customerIds: customerIds,
      productIds: productIds,
      salesmanIds: salesmanIds
    };
    const response = await handleRequest("POST", "/api/invoiceProduct/salesReport", obj);
    console.log(response);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false
      });
    } else {
      response.data.forEach(element => {
        element.productString = element.productList
          .map(item => {
            return item.product + "," + item.arabicProduct + "," + item.quantity;
          })
          .join("\n");
      });
      this.setState({
        tableList: Boolean(response.data) ? response.data : [],
        loading: false
      });
    }
  };

  search = async values => {
    console.log(values);
    this.setState(
      {
        ...values,
        startDate: Boolean(values.startDate) ? values.startDate.toDate() : null,
        endDate: Boolean(values.endDate) ? values.endDate.toDate() : null,
        loading: true
      },
      this.restartTable
    );
  };

  searchProfit = async values => {
    console.log(values);
    this.setState(
      {
        ...values,
        startDate: Boolean(values.startDate) ? values.startDate.toDate() : null,
        endDate: Boolean(values.endDate) ? values.endDate.toDate() : null,
        loading: true
      }, () => {
        const { paginationProfit } = this.state;
        this.restartTableProfit({ paginationProfit });
      }
    );
  };

  restartTableProfit = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/invoiceProduct/salesProfit";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableListProfit: [],
        paginationProfit: {
          ...params.paginationProfit,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableListProfit: list,
          paginationProfit: {
            ...params.paginationProfit,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableListProfit: [],
          paginationProfit: {
            ...params.paginationProfit,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    const { startDate, endDate, productIds, salesmanIds } = this.state;
    console.log(params);
    if (Boolean(params._pagination)) params.paginationProfit = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.paginationProfit)) {
      if (Boolean(params.paginationProfit.lastUpdateDate)) {
        lastUpdateDateStart = params.paginationProfit.lastUpdateDate[0];
        lastUpdateDateEnd = params.paginationProfit.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.paginationProfit,
      id: null,
      startDate: startDate ? startDate : moment().subtract(1, "M").toDate(),
      endDate: endDate ? endDate : moment().toDate(),
      productIds: productIds,
      salesmanIds: salesmanIds,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.paginationProfit.current - 1,
      maxResults: params.paginationProfit.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChangeProfit = (paginationProfit, filters, sorter) => {
    this.restartTableProfit({ sortField: sorter.columnKey, sortOrder: sorter.order, paginationProfit, filters });
  };

  searchProduct = async materialName => {
    let obj = { product: materialName };
    if (Boolean(materialName) && materialName.length > 0) var response = await handleRequest("POST", "/api/invoiceProduct/productsearch", obj);
    else response = await handleRequest("GET", "/api/invoiceProduct/top50product");

    if (Boolean(response.data)) {
      this.setState({
        productSelectItems: response.data
      });
    } else
      this.setState({
        productSelectItems: []
      });
  };

  salesInvoiceReport = async () => {
    this.fillSmallTable();
    this.setState({
      showSalesInvoiceReport: true,
    });
  };

  salesTotalReport = async () => {
    this.fillBigTable();
    this.setState({
      showSalesTotalReport: true,
    });
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleExportExcelProfit = async () => {
    this.setState({
      exportClickProfit: true
    });
  };

  asyncFetch = async () => {
    const search = {

    };
    var response = await handleRequest("POST", "/api/invoice/incoice/Details", search);
    if (response.type === "ERROR") {
      error(response);
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 5 }
    };

    //Letters of "Cash", "Bank", "Credit"(CBC).
    const columnsCBC = [
      {
        title: "",
        key: "type",
        render: record => {
          return record.type;
        },
      },
      {
        title: "Cash",
        key: "cash",
        dataIndex: "cash",
        align: "center"
      },
      {
        title: "Bank",
        key: "bank",
        dataIndex: "bank",
        align: "center"
      },
      {
        title: "Credit",
        key: "credit",
        dataIndex: "credit",
        align: "center"
      },



    ];

    //Letters of "Products", "Quantity", "Total Price", "Average Unit Price", "Profit"(PQTAP).
    const columnsPQTAP = [
      {
        title: "",
        key: "type",
        render: record => {
          return record.type;
        },
      },
      {
        title: "Products",
        key: "products",
        dataIndex: "products",
        align: "center"
      },
      {
        title: "Quantity",
        key: "quantity",
        dataIndex: "quantity",
        align: "center"
      },
      {
        title: "Total Price",
        key: "totalPrice",
        dataIndex: "totalPrice",
        align: "center"
      },
      {
        title: "Average Unit Price",
        key: "averageUnitPrice",
        dataIndex: "averageUnitPrice",
        align: "center"
      },
      {
        title: "Profit",
        key: "profit",
        dataIndex: "profit",
        align: "center"
      },
    ];

    const columns = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: "Date",
        key: "invoiceDate",
        render: (value, row, index) => {
          return moment(row.invoiceDate).format("DD/MM/YYYY");
        }
      },
      {
        title: "Invoice Number",
        key: "invoiceNo",
        dataIndex: "invoiceNo"
      },
      {
        title: "Customer",
        key: "vendor",
        dataIndex: "vendor"
      },
      {
        title: "Discount",
        key: "discount",
        dataIndex: "discount"
      },

      {
        title: "Salesman",
        key: "user",
        dataIndex: "user"
      },
      {
        title: "Products",
        key: "productList",
        render: (value, row, index) => {
          let p;
          if (row.productList.length > 0) {
            p = row.productList.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.product}</td>
                  <td>{item.quantity}</td>
                </tr>
              );
            });
            return (
              <table className="table table-bordered table-sm m-0">
                <tbody>{p}</tbody>
              </table>
            );
          }
        }
      },
      {
        title: "Invoice Price",
        key: "invoicePrice",
        dataIndex: "invoicePrice"
      },
      {
        title: "Payment",
        key: "payment",
        dataIndex: "payment"
      }
    ];

    const salesColumns = [
      { header: "S/N", key: "index" },
      { header: "Date", key: "invoiceDate" },
      { header: "Customer", key: "vendor" },
      { header: "Salesman", key: "user" },
      { header: "Products", key: "productString" },
      { header: "Total Price", key: "grandTotal" },
    ];

    const profitColumns = [
      { header: "S/N", key: "index" },
      { header: "Salesman", key: "user" },
      { header: "Products", key: "productString" },
      { header: "Quantity", key: "grandTotal" },
      { header: "Cost", key: "grandTotal" },
      { header: "Selling Price", key: "grandTotal" },
      { header: "Profit", key: "grandTotal" },
    ];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="SalesReports.pageTitle" defaultMessage="Sales Reports" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <br /><br />
          <Tabs type="card">
            <TabPane tab="Sales Report" key="1">
              <Form
                initialValues={{
                  remember: false,
                  startDate: moment().subtract(1, "M"),
                  endDate: moment()
                }}
                onFinish={this.search}
                onFinishFailed={onFinishFailed}
                ref={this.formRef}
              >
                <Row gutter={[20, 20]}>
                  <Col md={18}>
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProductStatistics.startdate" defaultMessage="Start Date" />}
                        style={{
                          marginBottom: 0
                        }}
                        name="startDate"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProductStatistics.endDate" defaultMessage="End Date" />}
                        style={{
                          marginBottom: 0
                        }}
                        name="endDate"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProductStatistics.Customer" defaultMessage="Customer" />}
                        style={{
                          marginBottom: 0
                        }}
                        name="customerIds"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Select showSearch optionFilterProp="children" placeholder="Customer" mode="multiple" allowClear>
                          {Boolean(this.state["customerSelectItems"]) &&
                            this.state["customerSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}{" "}
                        </Select>
                      </Form.Item>
                    }{" "}
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProductStatistics.product" defaultMessage="Product" />}
                        name="productIds"
                        style={{
                          marginBottom: 0
                        }}
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          mode="multiple"
                          onSearch={this.searchProduct}
                          style={{ marginBottom: 0, width: "100%" }}
                        >
                          {Boolean(this.state["productSelectItems"]) &&
                            this.state["productSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}{" "}
                        </Select>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProductStatistics.salesman" defaultMessage="Salesman" />}
                        style={{ marginBottom: 0 }}
                        name="salesmanIds"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Select allowClear showSearch optionFilterProp="children" mode="multiple" style={{ width: "100%" }}>
                          {Boolean(this.state["salesmanSelectItems"]) &&
                            this.state["salesmanSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}{" "}
                        </Select>
                      </Form.Item>
                    }
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <AntButton type="dashed" htmlType="submit" style={{ width: "63%" }} icon={<SearchOutlined />} size="large">
                          <FormattedMessage id="GeneralButtonSearch" defaultMessage="Search" />
                        </AntButton>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  </Col>


                  <Col md={3}>
                    <Button id="SalesInvoiceReport" style={{ width: "100%" }} variant="success" onClick={this.salesInvoiceReport}>
                      <FormattedMessage id="ButtonSalesInvoiceReport" defaultMessage="Sales Invoice Report" />
                    </Button>
                  </Col>
                  <Col md={3}>
                    <Button id="SalesTotalReport" style={{ width: "100%" }} variant="danger" onClick={this.salesTotalReport}>
                      <FormattedMessage id="ButtonSalesTotalReport" defaultMessage="Sales Total Report" />
                    </Button>
                  </Col>

                </Row>
              </Form>

              <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                <Row gutter={[16, 16]} justify="end">
                  <Col md={1}>
                    {
                      <AntButton
                        style={{ border: "0px" }}
                        onClick={() => { excelExport(salesColumns, this.state.tableList) }}
                        icon={<ExportIcon color="action" fontSize="large" />}
                      />
                    }
                  </Col>
                </Row>
                <Table
                  rowKey={record => record.id}
                  bordered
                  scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                  columns={columns}
                  dataSource={this.state.tableList}
                  loading={this.state.loading}
                  summary={pageData => {
                    let totalAmount = 0;
                    let totalQuantity = 0;

                    this.state.tableList.forEach(({ grandTotal, productList }) => {
                      totalAmount += grandTotal;
                      totalQuantity += productList.reduce((acc, cur) => acc + cur.quantity, 0);
                    });

                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell type="success">Total</Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text type="success">{totalQuantity}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text type="success">{totalAmount}</Text>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </div>
            </TabPane>
            <TabPane tab="Sales Summary" key="2">
              <SalesSummary />
            </TabPane>
            <TabPane tab="Income-Expense Report" key="3">
              <IncomeExpenseReport />
            </TabPane>
            <TabPane tab={<FormattedMessage id="ProductStatistics.Profit" defaultMessage="Profit" />} key="4">
              <Form
                initialValues={{
                  remember: false,
                  startDate: moment().subtract(1, "M"),
                  endDate: moment()
                }}
                onFinish={this.searchProfit}
                onFinishFailed={onFinishFailed}
                ref={this.formRef}
              >
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProductStatistics.startdate" defaultMessage="Start Date" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="startDate"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProductStatistics.endDate" defaultMessage="End Date" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="endDate"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProductStatistics.salesman" defaultMessage="Salesman" />}
                    style={{ marginBottom: 0 }}
                    name="salesmanIds"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Select allowClear showSearch optionFilterProp="children" mode="multiple" style={{ width: "100%" }}>
                      {Boolean(this.state["salesmanSelectItems"]) &&
                        this.state["salesmanSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProductStatistics.product" defaultMessage="Product" />}
                    name="productIds"
                    style={{
                      marginBottom: 0
                    }}
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      mode="multiple"
                      onSearch={this.searchProduct}
                      style={{ marginBottom: 0, width: "100%" }}
                    >
                      {Boolean(this.state["productSelectItems"]) &&
                        this.state["productSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <AntButton type="dashed" htmlType="submit" style={{ width: "100%" }} icon={<SearchOutlined />} size="large">
                      <FormattedMessage id="GeneralButtonSearch" defaultMessage="Search" />
                    </AntButton>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              </Form>
              <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                <Row gutter={[16, 16]} justify="end">
                  <Col md={1}>{
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => { excelExport(profitColumns, this.state.tableListProfit) }}
                      icon={<ExportIcon color="action" fontSize="large" />}
                    />}</Col>
                </Row>
                <Table
                  bordered
                  scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                  columns={columnsCBC}
                  dataSource={this.state.tableListProfit}
                  loading={this.state.loading}
                  pagination={this.state.paginationProfit}
                  onChange={this.handleTableChangeProfit}
                />
              </div>
            </TabPane>
          </Tabs>

          <DraggableModal
            title={<FormattedMessage id="Invoice.salesInvoiceReport" defaultMessage="Sales Invoice Report" />}
            closable="false"
            open={this.state.showSalesInvoiceReport}
            onCancel={() => {
              this.setState({ showSalesInvoiceReport: false });
            }}
            width={1000}
            centered
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ showSalesInvoiceReport: false });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
            content={
              <div className="card card-custom" style={{ minHeight: "100%" }}>
                <Table
                  bordered
                  size="small"
                  columns={columnsCBC}
                  dataSource={this.state.salesInvoiceReportList}
                  loading={this.state.loading}
                  pagination={false}
                />
              </div>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="Invoice.salesTotalReport" defaultMessage="Sales Total Report" />}
            closable="false"
            open={this.state.showSalesTotalReport}
            onCancel={() => {
              this.setState({ showSalesTotalReport: false });
            }}
            width={1000}
            centered
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ showSalesTotalReport: false });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
            content={
              <div className="card card-custom" style={{ minHeight: "100%" }}>
                <Table
                  bordered
                  size="small"
                  columns={columnsPQTAP}
                  dataSource={this.state.salesTotalReportList}
                  loading={this.state.loading}
                  pagination={false}
                  summary={pageData => {
                    let totalQuantity = 0;
                    let totalOfPrice = 0;
                    let totalAUP = 0;
                    let totaProfit = 0;


                    this.state.tableListPrice.forEach(({ grandTotal, productList }) => {
                      totalQuantity += grandTotal;
                      totalQuantity += productList.reduce((acc, cur) => acc + cur.quantity, 0);
                    });

                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell className="align-right" type="success">
                            Total
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="align-right">
                            <Text type="success">{totalQuantity}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="align-right">
                            <Text type="success">{totalOfPrice}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="align-right">
                            <Text type="success">{totalAUP}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="align-right">
                            <Text type="success">{totaProfit}</Text>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </div>
            }
          />
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SalesReport);