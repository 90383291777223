import React, { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { FormattedMessage } from "react-intl";
import PageFeatures from "./PageFeatures";
import Authorization from "./Authorization";
import { Notification } from "./Notification";
import Approval from "./Approval";
import Workflow from "./Workflow";
import { Mail } from "./Mail";
import HowToUse from "./HowToUse";
import DynamicPage from "./DynamicPage";
import AdminNotes from "./AdminNotes";
import HistoryLog from "./HistoryLog";
import Location from "./Location";

export function PageSettings({ pageName }) {
  console.log("pageName", pageName);
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  const [selectedTab, setSelectedTab] = React.useState("pageFeatures");
  useEffect(() => {
    return () => {};
  }, [user]);

  return (
    <>
      {user && (
        <div className="d-flex flex-row">
          <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-250px" id="kt_profile_aside">
            <div className="card card-custom card-stretch">
              {/* begin::Body */}
              <div className="card-body pt-4">
                {/* begin::Toolbar */}
                <div className="d-flex justify-content-end"></div>
                {/* end::Toolbar */}
                {/* begin::Nav */}
                <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="PageSettings.PageFeatures" defaultMessage="Page Features" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "pageFeatures" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("pageFeatures");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="PageSettings.PageFeatures" defaultMessage="Page Features" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="PageSettings.Workflow" defaultMessage="Workflow" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "workflow" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("workflow");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="PageSettings.Workflow" defaultMessage="Workflow" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="PageSettings.Authorization" defaultMessage="Authorization" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "authorization" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("authorization");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Article.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="PageSettings.Authorization" defaultMessage="Authorization" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="PageSettings.Notification" defaultMessage="Notification" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "notification" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("notification");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="PageSettings.Notification" defaultMessage="Notification" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="PageSettings.Approval" defaultMessage="Approval" />{" "}
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "approval" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("approval");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="PageSettings.Approval" defaultMessage="Approval" />{" "}
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="PageSettings.Mail" defaultMessage="Mail" />{" "}
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "mail" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("mail");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Mail-notification.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="PageSettings.Mail" defaultMessage="Mail" />{" "}
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="PageSettings.Location" defaultMessage="Location" />{" "}
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "location" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("location");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="PageSettings.Location" defaultMessage="Location" />{" "}
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="PageSettings.HowToUse" defaultMessage="How to Use" />{" "}
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "howToUse" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("howToUse");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="PageSettings.HowToUse" defaultMessage="How to Use" />{" "}
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="PageSettings.DynamicPage" defaultMessage="Dynamic Page" />{" "}
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "dynamicPage" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("dynamicPage");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="PageSettings.DynamicPage" defaultMessage="Dynamic Page" />{" "}
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="PageSettings.AdminNotes" defaultMessage="Admin Notes" />{" "}
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "adminNotes" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("adminNotes");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="PageSettings.AdminNotes" defaultMessage="Admin Notes" />{" "}
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="PageSettings.HistoryLog" defaultMessage="History Log" />{" "}
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "historyLog" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("historyLog");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="PageSettings.HistoryLog" defaultMessage="History Log" />{" "}
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                </div>
                {/* end::Nav */}
              </div>
              {/* end::Body */}
            </div>
          </div>
          <div className="flex-row-fluid ml-lg-8">
            {selectedTab === "pageFeatures" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                      <FormattedMessage id="PageSettings.PageFeatures" defaultMessage="Page Features" />
                    </h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <PageFeatures pageName={pageName} nextTab={() => setSelectedTab("workflow")}></PageFeatures>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "workflow" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                      <FormattedMessage id="PageSettings.Workflow" defaultMessage="Workflow" />
                    </h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <Workflow pageName={pageName} nextTab={() => setSelectedTab("authorization")}></Workflow>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "authorization" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                      <FormattedMessage id="PageSettings.Authorization" defaultMessage="Authorization" />
                    </h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <Authorization pageName={pageName} nextTab={() => setSelectedTab("notification")}></Authorization>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "notification" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                      <FormattedMessage id="PageSettings.Notification" defaultMessage="Notification" />
                    </h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <Notification pageName={pageName} nextTab={() => setSelectedTab("approval")}></Notification>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "approval" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                      <FormattedMessage id="PageSettings.Approval" defaultMessage="Approval" />
                    </h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <Approval pageName={pageName} nextTab={() => setSelectedTab("mail")}></Approval>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "mail" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                      <FormattedMessage id="PageSettings.Mail" defaultMessage="Mail" />
                    </h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <Mail pageName={pageName} nextTab={() => setSelectedTab("location")}></Mail>
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "location" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                      <FormattedMessage id="PageSettings.Location" defaultMessage="Location" />
                    </h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <Location pageName={pageName} nextTab={() => setSelectedTab("howToUse")}></Location>
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "howToUse" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                      <FormattedMessage id="PageSettings.HowToUse" defaultMessage="How To Use" />
                    </h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <HowToUse pageName={pageName} nextTab={() => setSelectedTab("dynamicPage")}></HowToUse>
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "dynamicPage" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                      <FormattedMessage id="PageSettings.DynamicPage" defaultMessage="Dynamic Page" />
                    </h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <DynamicPage pageName={pageName} nextTab={() => setSelectedTab("adminNotes")}></DynamicPage>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "adminNotes" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                      <FormattedMessage id="PageSettings.AdminNotes" defaultMessage="Admin Notes" />
                    </h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <AdminNotes pageName={pageName} nextTab={() => setSelectedTab("historyLog")}></AdminNotes>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "historyLog" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                      <FormattedMessage id="PageSettings.HistoryLog" defaultMessage="History Log" />
                    </h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <HistoryLog pageName={pageName} nextTab={() => setSelectedTab("pageFeatures")}></HistoryLog>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
