import "antd/dist/antd.css";
import moment from "moment";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Badge, Tooltip, Col, Button as AntButton, Row, Space, Spin, Checkbox, Typography, Layout, Card, Menu } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined, PictureAsPdf } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import Viewer, { defaultLayout, Worker } from "@phuocng/react-pdf-viewer";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, UserOutlined, LaptopOutlined, NotificationOutlined } from "@ant-design/icons";
import { Button } from "react-bootstrap";
import DraggableModal from "../../../Components/DraggableModal";

const { Content, Sider } = Layout;
const { Title } = Typography;

export class ProjectSummary extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ProjectSummary",
      controllerName: "projectSummary",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      loadingButtonSubmit: false,
      selectedKeys: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  formRefPdfFilter = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };
  fillComboboxes = async () => {};
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };
  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };
  ProjectSummaryModal = record => {
    this.setState({
      showProjectSummaryModal: true,
      selectedProjectSummary: record
    });
  };

  getReportList = async values => {
    console.log("values", values);
    this.setState({ loadingButtonSubmit: true });
    // let columns = [];

    // if (Boolean(values.projects) && values.projects.length > 0) {
    //   values.projects.forEach(element => {
    //     columns.push(element);
    //   });
    // }
    // if (Boolean(values.contracts) && values.contracts.length > 0) {
    //   values.contracts.forEach(element => {
    //     columns.push(element);
    //   });
    // }
    // if (Boolean(values.contractors) && values.contractors.length > 0) {
    //   values.contractors.forEach(element => {
    //     columns.push(element);
    //   });
    // }

    if (Boolean(values) && values.length > 0) {
      let url = "/api/" + this.state.controllerName + "/getReportList/" + values;
      const response = await handleRequest("GET", url);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          loadingButtonSubmit: false
        });
      } else if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          pdfFile: response.data,
          modalPdfViewer: true,
          modalPdfSearchShow: false,
          loadingButtonSubmit: false
        });
      } else {
        this.setState({
          pdfFile: null,
          modalPdfViewer: false,
          modalPdfSearchShow: false,
          loadingButtonSubmit: false
        });
        showError("There is no created report for selected item");
      }
    } else {
      this.setState({
        loadingButtonSubmit: false
      });
      showWarning("Please select item.");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };
    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.printButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };

    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1)
      },
      {
        title: <FormattedMessage id="ProjectSummary.status" defaultMessage="Status" />,
        key: "status",
        render: record => record.statusName,
        ...getColumnFilter({
          inputName: "status",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProjectSummary.projectFullName" defaultMessage="Project Name" />,
        key: "projectFullName",
        render: record => record.projectFullName,
        ...getColumnFilter({
          inputName: "projectFullName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProjectSummary.projectsTreeNames" defaultMessage="Project Type" />,
        key: "id",
        render: record => {
          return (
            <Tooltip title={record.projectsTreeNames.join(" - ")}>
              <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{record.projectsTreeNames.join(" - ")}</div>
            </Tooltip>
          );
        }
      },
      {
        title: <FormattedMessage id="ProjectSummary.istanbulCountyId" defaultMessage="County" />,
        key: "istanbulCountyId",
        render: record => record.istanbulCountyName,
        ...getColumnFilter({
          inputName: "istanbulCountyId",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProjectSummary.contractorId" defaultMessage="Contractor" />,
        key: "contractorId",
        render: record => record.contractorName,
        ...getColumnFilter({
          inputName: "contractorId",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProjectSummary.contractSignDate" defaultMessage="Contract Date" />,
        key: "contractSignDate",
        render: record => {
          return record.contractSignDate ? moment(record.contractSignDate).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "contractSignDate",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProjectSummary.contractEndDate" defaultMessage="Project End Date" />,
        key: "contractEndDate",
        render: record => {
          return record.contractEndDate ? moment(record.contractEndDate).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "contractEndDate",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProjectSummary.budgetOfProject" defaultMessage="Budget" />,
        key: "budgetOfProject",
        render: record => record.budgetOfProject,
        ...getColumnFilter({
          inputName: "budgetOfProject",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
    ];
    const columnsExcel = [
      { header: "Status", key: "status" },
      { header: "Project Name", key: "projectFullName" },
      { header: "Project Type", key: "projectsTreeNames" },
      { header: "County", key: "istanbulCountyId" },
      { header: "Contractor", key: "contractorId" },
      { header: "Contract Date", key: "contractSignDate" },
      { header: "Project End Date", key: "contractEndDate" },
      { header: "Budget", key: "budgetOfProject" }
    ];

    const items2 = [
      {
        key: "Project",
        icon: <UserOutlined />,
        label: "Project",
        children: [
          { key: "Project Name", label: "Project Name" },
          { key: "County", label: "County" },
          { key: "Project Type", label: "Project Type" }
        ]
      },
      {
        key: "Contract",
        icon: <LaptopOutlined />,
        label: "Contract",
        children: [
          { key: "Start Date", label: "Start Date" },
          { key: "End Date", label: "End Date" },
          { key: "Budget", label: "Budget" }
        ]
      },
      {
        key: "Contractor",
        icon: <NotificationOutlined />,
        label: "Contractor",
        children: [{ key: "Contractor Name", label: "Contractor Name" }]
      }
    ];

    const handleMenuSelect = ({ key }) => {
      const { selectedKeys } = this.state;
      if (selectedKeys.includes(key)) {
        this.setState({
          selectedKeys: selectedKeys.filter(selectedKey => selectedKey !== key)
        });
      } else {
        this.setState({
          selectedKeys: [...selectedKeys, key]
        });
      }
    };

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="projectSummary.pageTitle" defaultMessage="Project Summary" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Row gutter={[16, 16]}>
            <Col md={23}></Col>
            <Col md={1}>
              <Spin spinning={this.state.loadingButtonSubmit}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => {
                    this.getReportList(this.state.selectedKeys);
                  }}
                  icon={<PictureAsPdf style={{ color: "red" }} fontSize="large" />}
                ></AntButton>
              </Spin>
            </Col>
          </Row>

          <Card
            style={{
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              width: "100%",
              height: "100%"
            }}
          >
            <Layout>
              <Sider width={200}>
                <Menu
                  mode="inline"
                  selectedKeys={this.state.selectedKeys}
                  onClick={handleMenuSelect}
                  style={{
                    height: "100%",
                    borderRight: 0
                  }}
                  items={items2}
                  defaultOpenKeys={["Project", "Contract", "Contractor"]}
                />
              </Sider>
              <Layout
                style={{
                  padding: "0 24px 24px"
                }}
              >
                <Content
                  style={{
                    padding: 24,
                    margin: 0,
                    minHeight: 280
                  }}
                >
                  <>
                    <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
                      <div hidden={this.state.hideInputs}></div>
                    </Form>
                    {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
                      <div className="alert alert-custom alert-light">
                        <div className="alert-icon">
                          <FilterOutlined />
                        </div>
                        <div className="alert-text">
                          {this.state.searchCriteriaModels.map((val, i) => {
                            return (
                              <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                                <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                                <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                                <span>
                                  <Tooltip title="Remove">
                                    <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                                  </Tooltip>
                                </span>
                              </div>
                            );
                          })}
                        </div>
                        <div className="alert-close">
                          <Tooltip title="Remove All">
                            <CloseCircleOutlined
                              style={{ color: "crimson" }}
                              onClick={() =>
                                this.setState({ searchCriteriaModels: [] }, () => {
                                  this.filterChangeItem([]);
                                })
                              }
                            />
                          </Tooltip>
                        </div>
                      </div>
                    )}
                    <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                      <Table
                        bordered
                        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                        columns={columns}
                        dataSource={this.state.tableList}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                        pagination={this.state.pagination}
                      />
                    </div>
                  </>
                </Content>
              </Layout>
            </Layout>
          </Card>

          <DraggableModal
            title={<FormattedMessage id="ProjectSummary.ProjectSummary" defaultMessage="Project Summary" />}
            open={this.state.modalPdfSearchShow}
            onCancel={() => this.setState({ modalPdfSearchShow: false })}
            okButtonProps={{ hidden: true }}
            width={700}
            centered
            footer={[
              <Space>
                <Spin spinning={this.state.loadingButtonSubmit}>
                  <Button
                    variant="primary"
                    onClick={() => {
                      this.formRefPdfFilter.current.submit();
                    }}
                  >
                    <FormattedMessage id="GeneralButtonReport" defaultMessage="Report" />
                  </Button>
                </Spin>
              </Space>
            ]}
            content={
              <Form initialValues={{ remember: false }} onFinish={this.getReportList} onFinishFailed={onFinishFailed} ref={this.formRefPdfFilter}>
                <div>
                  <Title level={5}>
                    <FormattedMessage id="ProjectSummary.Project" defaultMessage="Project" />
                  </Title>
                  <Form.Item
                    {...layout}
                    name="projects"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Checkbox.Group options={["Project Name", "County", "Project Type"]} style={{ display: "flex", flexDirection: "column" }} />
                  </Form.Item>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <Title level={5}>
                    <FormattedMessage id="ProjectSummary.Contract" defaultMessage="Contract" />
                  </Title>
                  <Form.Item
                    {...layout}
                    name="contracts"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Checkbox.Group options={["Start Date", "End Date", "Budget"]} style={{ display: "flex", flexDirection: "column" }} />
                  </Form.Item>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <Title level={5}>
                    <FormattedMessage id="ProjectSummary.Contractor" defaultMessage="Contractor" />
                  </Title>
                  <Form.Item
                    {...layout}
                    name="contractors"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Checkbox.Group options={["Contractor Name"]} style={{ display: "flex", flexDirection: "column" }} />
                  </Form.Item>
                </div>
              </Form>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="GeneralPDFReportViewer" defaultMessage="PDF Report Viewer" />}
            open={this.state.modalPdfViewer}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ modalPdfViewer: false })}
            width={1200}
            centered
            content={
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                <div style={{ marginTop: "2rem" }}>
                  <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                </div>
              </Worker>
            }
          />
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectSummary);
