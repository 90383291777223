import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { Form, Table, Badge, Drawer } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import {
  FilterOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import moment from "moment";
import { PageSettings } from "../administrator/pageSettings/PageSettings";
import store from "../../../redux/store";
import { Resizable } from "react-resizable";
import { handleRequest } from "../../ApiConnector";

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class PersonnelSalaryLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "PersonnelSalaryLog",
      controllerName: "personnelSalaryLog",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      modalSendAll: false,
      collapsedAvailable: true,
      collapsedReserved: false,
      collapsedToBeReleased: false,
      collapsedRequested: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      user: store.getState().auth.user
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {

  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    console.log("record", record);
    let actionList = [];
    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => { };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        },
      },
      {
        title: <FormattedMessage id="PersonnelSalaryLog.BudgeNo" defaultMessage="Budge No" />,
        key: "budgeNo",
        width: 100,
        render: record => {
          return record.budgeNo;
        },
        ...getColumnFilter({
          inputName: "budgeNo",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
      },
      {
        title: <FormattedMessage id="PersonnelSalaryLog.IDNo" defaultMessage="ID No" />,
        key: "identificationNo",
        width: 100,
        render: record => {
          return record.identificationNo;
        },
        ...getColumnFilter({
          inputName: "identificationNo",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
      },
      {
        title: <FormattedMessage id="PersonnelSalaryLog.Name" defaultMessage="Name" />,
        key: "name",
        width: 100,
        render: record => {
          return record.name;
        },
        ...getColumnFilter({
          inputName: "name",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
      },
      {
        title: <FormattedMessage id="PersonnelSalaryLog.Project" defaultMessage="Project" />,
        key: "projectId",
        width: 100,
        render: row => {
          return (
            <Tooltip
              color={"white"}
              title={
                <div className="w-100" style={{ color: "black" }}>
                  {row.projectName}
                </div>
              }
            >
              {Boolean(row.projectName) && row.projectName.length > 10
                ? row.projectName.substring(0, 10) + "..."
                : row.projectName}
            </Tooltip>
          );
        },
        sorter: true,
      },
      {
        title: <FormattedMessage id="PersonnelSalaryLog.Branch" defaultMessage="Branch" />,
        key: "companyProjectId",
        width: 100,
        render: row => {
          return (
            <Tooltip
              color={"white"}
              title={
                <div className="w-100" style={{ color: "black" }}>
                  {row.companyProjectName}
                </div>
              }
            >
              {Boolean(row.companyProjectName) && row.companyProjectName.length > 10
                ? row.companyProjectName.substring(0, 10) + "..."
                : row.companyProjectName}
            </Tooltip>
          );
        },
        sorter: true,
      },
      {
        title: <FormattedMessage id="PersonnelSalaryLog.Department" defaultMessage="Department" />,
        key: "departmentId",
        width: 150,
        render: record => {
          return record.departmentName;
        },
        ...getColumnFilter({
          inputName: "departmentId",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
      },
      {
        title: <FormattedMessage id="PersonnelSalaryLog.Designation" defaultMessage="Designation" />,
        key: "designationId",
        width: 150,
        render: record => {
          return record.designationName;
        },
        ...getColumnFilter({
          inputName: "designationId",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
      },
      {
        title: <FormattedMessage id="PersonnelSalaryLog.BasicSalary" defaultMessage="Basic Salary" />,
        key: "basicSalary",
        width: 100,
        render: record => {
          return record.basicSalary;
        },
        ...getColumnFilter({
          inputName: "basicSalary",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
      },
      {
        title: <FormattedMessage id="PersonnelSalaryLog.Transportation" defaultMessage="Transportation" />,
        key: "id",
        width: 125,
        render: record => {
          return record.transportationAllowance;
        },
      },
      {
        title: <FormattedMessage id="PersonnelSalaryLog.Accomadation" defaultMessage="Accomadation" />,
        key: "homeAllowance",
        width: 125,
        render: record => {
          return record.homeAllowance;
        },
      },
      {
        title: <FormattedMessage id="PersonnelSalaryLog.Food" defaultMessage="Food" />,
        key: "id",
        width: 100,
        render: record => {
          return record.foodAllowance;
        },
      },
      {
        title: <FormattedMessage id="PersonnelSalaryLog.OtherAllowances" defaultMessage="Other Allowances" />,
        key: "otherAllowances",
        width: 100,
        render: record => {
          return record.otherAllowances;
        },
      },
      {
        title: <FormattedMessage id="PersonnelSalaryLog.Tax" defaultMessage="Tax" />,
        key: "tax",
        width: 100,
        render: record => {
          return record.tax;
        },
      },
      {
        title: <FormattedMessage id="PersonnelSalaryLog.SocialInsurance" defaultMessage="Social Insurance" />,
        key: "socialInsurance",
        width: 100,
        render: record => {
          return record.socialInsurance;
        },
      },
      {
        title: <FormattedMessage id="PersonnelSalaryLog.EployeerPayments" defaultMessage="Eployeer Payments" />,
        key: "eployeerPayments",
        width: 100,
        render: record => {
          return record.eployeerPayments;
        },
        ...getColumnFilter({
          inputName: "eployeerPayments",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
      },
      {
        title: <FormattedMessage id="PersonnelSalaryLog.Total" defaultMessage="Total" />,
        key: "total",
        width: 100,
        render: record => {
          return record.total;
        },
        ...getColumnFilter({
          inputName: "total",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
      },
      {
        title: <FormattedMessage id="PersonnelSalaryLog.PastSalaries" defaultMessage="Past Salaries" />,
        key: "pastSalaries",
        width: 100,
        render: record => {
          return record.pastSalaries;
        },
        ...getColumnFilter({
          inputName: "pastSalaries",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];

      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    return (
      <>
        <Badge.Ribbon
          text={<FormattedMessage id="PersonnelSalaryLog.pageTitle" defaultMessage="Personnel Salary Log" />}
          placement="start"
          color="volcano"
          style={{ fontSize: 12 }}
        >
          <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
              <br />
              <br />
            </Form>
            {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
              <div className="alert alert-custom alert-light">
                <div className="alert-icon">
                  <FilterOutlined />
                </div>
                <div className="alert-text">
                  {this.state.searchCriteriaModels.map((val, i) => {
                    return (
                      <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                        <span className="mr-2">{val.label}</span>
                        <strong className="mr-2">{val.type}</strong>
                        <span className="mr-2">{val.operationValue}</span>
                        <span className="mr-2">{val.showValue}</span>
                        <span>
                          <Tooltip title="Remove">
                            <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                          </Tooltip>
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="alert-close">
                  <Tooltip title="Remove All">
                    <CloseCircleOutlined
                      style={{ color: "crimson" }}
                      onClick={() =>
                        this.setState({ searchCriteriaModels: [] }, () => {
                          this.filterChangeItem([]);
                        })
                      }
                    />
                  </Tooltip>{" "}
                </div>{" "}
              </div>
            )}

            <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
              <Table
                rowKey={"id"}
                bordered
                size="small"
                components={{
                  header: {
                    cell: ResizableTitle
                  }
                }}
                scroll={{ x: 900, y: 600, scrollToFirstRowOnChange: true }}
                columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
                dataSource={this.state.tableList}
                loading={this.state.loading}
                onChange={this.handleTableChange}
                pagination={this.state.pagination}
              />
            </div>
          </div>

          <Drawer
            title={<FormattedMessage id="PersonnelSalaryLog.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ drawerSettingsShow: false });
            }}
            open={this.state.drawerSettingsShow}
          >
            <PageSettings pageName={"Personnel_Salary_Log"} />
          </Drawer>
        </Badge.Ribbon>
      </>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PersonnelSalaryLog);