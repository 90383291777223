import { EditOutlined } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Button as AntButton, Badge, Col, Form, Row, Checkbox, InputNumber, Input, Tooltip } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import DataTableComponent2 from "../../Components/DataTableComponent2";
import ElementComponent from "../../Components/Element";
import { GetDynamicFormItems, PrepareRowForEdit, PrepareRowForSave } from "../../Components/ElementUtils";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import PageviewIcon from "@material-ui/icons/Pageview";
import PurchaseRequisitionNo from "./PurchaseRequisitionNo";
import Viewer, { defaultLayout, Worker } from "@phuocng/react-pdf-viewer";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DraggableModal from "../../Components/DraggableModal";

const generateItemList = data => {
  let itemList = [];
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    itemList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children && node.children.length > 0) {
      itemList.push(...generateItemList(node.children));
    }
  }
  return itemList;
};
export class PurchaseRequisition extends Component {
  constructor() {
    super();
    this.state = {
      formName: "PurchaseRequisition",
      controllerName: "purchaseRequisition",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      modalPdfViewer: false,
      pdfFile: "",
      id: null,
      tableList: [],
      restartTable: false,
      pageFormData: null,
      excelData: [],
      createWhereParams: null,
      refreshForm: false,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      }
    };
  }

  formRef = React.createRef();
  formApplyCode = React.createRef();

  componentDidMount = async () => {
    let data = await GetDynamicFormItems(this.state.formName);
    console.log("XXX", data);

    const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/projectAramco");
    console.log("AAA", response.data);
    if (!response.data) {
      var pageFormInputCompanyList = [];
      if (Boolean(data.pageFormInputCompanyList) && data.pageFormInputCompanyList.length > 0) {
        data.pageFormInputCompanyList.forEach(field => {
          if (
            !(
              field.inputName === "dummyPoNo" ||
              field.inputName === "comListingId" ||
              field.inputName === "inspectionLevel" ||
              field.inputName === "pim" ||
              field.inputName === "saepNames" ||
              field.inputName === "saepIds" ||
              field.inputName === "saesNames" ||
              field.inputName === "saesIds" ||
              field.inputName === "samssNames" ||
              field.inputName === "samssIds"
            )
          ) {
            pageFormInputCompanyList.push(field);
          }
        });
      }
      data.pageFormInputCompanyList = pageFormInputCompanyList;
    }

    this.setState({
      pageFormData: data
    });

    this.fillComboboxes(data.pageFormInputCompanyList);
  };

  componentDidUpdate = async nextProps => { };

  fillComboboxes = async inputList => {
    inputList.forEach(async (item, index) => {
      if (item.inputType === "CASCADER") {
        let url = item.loadUrl;
        var response = await handleRequest("GET", url);
        if (response.type === "ERROR") {
          showError(response);
        } else {
          let list = generateItemList(response.data);
          this.setState({
            [item.inputName + "Cascader"]: list
          });
        }
      }
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      }
    });
  };

  setData = data => {
    this.setState({
      tableList: data
    });
  };

  edit = row => {
    let handledValues = {
      ...row
    };
    handledValues = PrepareRowForEdit(handledValues, this.state.pageFormData.pageFormInputCompanyList);
    this.state.pageFormData.pageFormInputCompanyList.forEach(item => {
      if (item.inputType === "CASCADER" && this.state[item.inputName + "Cascader"]) {
        let itemId = this.state[item.inputName + "Cascader"].find(p => p.key === row[item.inputName]);
        if (itemId) {
          handledValues[item.inputName] = itemId.lineage;
        }
      }
    });
    this.formRef.current.setFieldsValue({
      ...handledValues
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async () => {
    this.setState({ restartTable: !this.state.restartTable });
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    console.log("AAA", values);
    let newItem = {
      id: this.state.id,
      ...values
    };
    newItem = PrepareRowForSave(newItem, this.state.pageFormData.pageFormInputCompanyList);
    if (!Boolean(newItem.id)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      var message = Boolean(response.response) && Boolean(response.response.data) ? response.response.data.message : "";
      if (message === "Item Specifications No you must determine") {
        this.setState({
          modalPurchaseRequisitionNoShow: true
        });
      }
      error(response);
    } else {
      this.cancel();
      this.restartTable();
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          this.state.pageFormData.pageFormInputCompanyList.forEach(input => {
            Boolean(input.tableColumnValue) ? (obj[input.tableColumnValue] = element[input.label]) : (obj[input.inputName] = element[input.label]);
          });
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            this.restartTable();
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  onClickMaterialRequisitionNo = () => {
    this.setState({
      showLocationModal: true
    });
  };

  handleApplySubmit = async values => {
    let model = { ...values };
    if (values.ascendingLetter) model.copyNameRule = "ASCENDING_LETTER";
    if (values.ascendingNumber) model.copyNameRule = "ASCENDING_NUMBER";
    const response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveMRNo", model);
    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
      this.setState({
        showLocationModal: false,
        refreshForm: true
      });
    }
  };

  onPurchaseRequisitionNoClick = () => {
    this.setState({
      modalPurchaseRequisitionNoShow: true
    });
  };

  generateReport = async () => {
    let url = "/api/" + this.state.controllerName + "/getReport";
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false
      });
      showError("There is no created report for selected item");
    }
  };

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showReport = async url => {
    try {
      const fileRealUrl = url;

      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true
      });
    } catch (error) {
      alert("File cant found.");
    }
  };

  render() {
    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.printButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 8,
          offset: 0
        },
        sm: {
          span: 8,
          offset: 8
        }
      }
    };

    return (
      <Badge.Ribbon
        text={Boolean(this.state.pageFormData) ? this.state.pageFormData.pageForm.title : ""}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Row gutter={[16, 16]}>
            <Col md={21}>
              {/* <span className="h3">
              <FormattedMessage
                id={this.state.controllerName + ".pageTitle"}
                defaultMessage={Boolean(this.state.pageFormData) ? this.state.pageFormData.pageForm.title : ""}
              />
            </span> */}
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                onClick={this.generateReport}
                icon={<PictureAsPdfIcon style={{ color: "red" }} fontSize="large" />}
              ></AntButton>
            </Col>
            <Col md={1}>
              <Tooltip title="Item Specifications No">
                <AntButton
                  style={{ border: "0px" }}
                  onClick={this.onPurchaseRequisitionNoClick}
                  icon={<PageviewIcon color="primary" fontSize="large" />}
                ></AntButton>
              </Tooltip>
            </Col>
            {/* <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col> */}
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>
          <div hidden={this.state.hideInputs}>
            <Form initialValues={{ remember: false, projectTakeOffId: null }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
              {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
                <>
                  {Boolean(this.state.pageFormData.pageFormInputCompanyList) && this.state.pageFormData.pageFormInputCompanyList.length > 0
                    ? this.state.pageFormData.pageFormInputCompanyList.map(
                      (field, i) =>
                        field.inputName !== "purchaseRequisitionNo" && (
                          <ElementComponent key={i} field={field} layout={layout} form={this.formRef} refresh={this.state.refreshForm} />
                        )
                    )
                    : null}
                </>
              ) : null}
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </Form>
          </div>
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
              <DataTableComponent2
                key="dataTable"
                field={this.state.pageFormData.pageForm}
                inputs={this.state.pageFormData.pageFormInputCompanyList}
                actions={this.action}
                editRow={this.edit.bind(this)}
                restartTable={this.state.restartTable}
                createWhereParams={this.state.createWhereParams}
                setData={this.setData.bind(this)}
              />
            ) : null}
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            size="xl"
            show={this.state.modalPdfViewer}
            onHide={this.showOrHideModalPdfViewer}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Badge.Ribbon text="PDF Report Viewer" placement="start" color="purple" style={{ fontSize: 12 }}>
              <Modal.Body>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                  <div style={{ marginTop: "2rem" }}>
                    <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                  </div>
                </Worker>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={this.showOrHideModalPdfViewer}>
                  Close
                </Button>
              </Modal.Footer>
            </Badge.Ribbon>
          </Modal>

          <DraggableModal
            width={1000}
            title="Material Requisition Settings"
            visible={this.state.showLocationModal}
            onCancel={() => {
              this.setState({ showLocationModal: false });
            }}
            onOk={() => {
              this.formApplyCode.current.submit();
            }}
            content={
              <Form initialValues={{ remember: false }} onFinish={this.handleApplySubmit} ref={this.formApplyCode}>
                {
                  <Form.Item
                    {...tailFormItemLayout}
                    name="ascendingNumber"
                    valuePropName="checked"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Checkbox
                      onChange={value => {
                        if (value) {
                          this.formApplyCode.current.setFieldsValue({
                            ascendingLetter: false
                          });
                        }
                      }}
                    >
                      {" "}
                      <FormattedMessage id="PlanLocation.ascendingNumber" defaultMessage="Ascending with number" />{" "}
                    </Checkbox>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...tailFormItemLayout}
                    name="ascendingLetter"
                    valuePropName="checked"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Checkbox
                      onChange={value => {
                        if (value) {
                          this.formApplyCode.current.setFieldsValue({
                            ascendingNumber: false
                          });
                        }
                      }}
                    >
                      {" "}
                      <FormattedMessage id="PlanLocation.ascendingLetter" defaultMessage="Ascending with letter" />{" "}
                    </Checkbox>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    name="componentCode"
                    label={<FormattedMessage id="PlanLocation.Code" defaultMessage="Code" />}
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input></Input>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    name="count"
                    label={<FormattedMessage id="PlanLocation.NumberOfCopy" defaultMessage="Number Of Copy" />}
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <InputNumber></InputNumber>
                  </Form.Item>
                }
              </Form>
            }
          ></DraggableModal>

          <DraggableModal
            title="Item Specifications No !"
            visible={this.state.modalPurchaseRequisitionNoShow}
            onCancel={() => {
              this.setState({ modalPurchaseRequisitionNoShow: false });
            }}
            width={1500}
            centered
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalPurchaseRequisitionNoShow: false });
                }}
              >
                Close
              </Button>
            ]}
            content={<PurchaseRequisitionNo />}
          ></DraggableModal>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseRequisition);
