import { FilterOutlined } from "@ant-design/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Button as AntButton, Col, DatePicker, Form, Input, InputNumber, Row, Table } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import { QRCodeCanvas } from "qrcode.react";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess } from "../../MessageHelper";
import ActionMenu from "../CustomMenu/ActionMenu";
import { ExcelReader } from "../excel/ExcelReader";

const { RangePicker } = DatePicker;

export class TimelineQRGenerator extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      qrCode: "",
      qrCodeSize: 200,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {};

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      qrCode: row.qrCode,
      qrCodeSize: row.imageSize,
      id: row.id
    });
  };

  print_qr = () => {
    var canvas = document.getElementById("qrCodeImage");
    var printWin = window.open();
    printWin.document.open();
    printWin.document.write(
      "<img src='" + canvas.toDataURL("image/jpeg") + `' width:='${this.state.qrCodeSize}' height='${this.state.qrCodeSize}'/>`
    );
    printWin.document.close();
    printWin.document.addEventListener(
      "load",
      function() {
        printWin.focus();
        printWin.print();
        printWin.document.close();
        printWin.close();
      },
      true
    );
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/timelineQrCode/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/timelineQrCode/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      let qrCode = this.getRandomQRCode("Ann-eeee-AAA-nnA");
      this.formRef.current.setFieldsValue({
        qrCode: qrCode,
        imageSize: 200
      });

      this.setState({
        qrCode: qrCode,
        qrCodeSize: 200,
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  getRandomQRCode = template => {
    var r = "",
      ch,
      n;
    for (var i = 0; i < template.length; i++) {
      ch = template.substr(i, 1);
      if (ch === "n") {
        r += parseInt(Math.random() * 10);
      } else if (ch === "A") {
        r += String.fromCharCode(65 + parseInt(Math.random() * 26));
      } else if (ch === "e") {
        n = parseInt(Math.random() * 36);
        if (n > 9) {
          r += String.fromCharCode(55 + n);
        } else {
          r += n;
        }
      } else {
        r += ch;
      }
    }
    return r;
  };

  save = async values => {
    const newItem = { ...values, id: this.state.id };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/timelineQrCode", newItem);
    } else {
      response = await handleRequest("PUT", "/api/timelineQrCode/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.print_qr();
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/timelineQrCode/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="TimelineQrCode.qrCode" defaultMessage="QrCode" />,
        key: "qrCode",
        render: record => {
          return record.qrCode;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.qrCode) ? this.state.pagination.qrCode : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  qrCode: e.target.value,
                  qrCodeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.qrCodeColor) ? this.state.pagination.qrCodeColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="TimelineQrCode.printedBy" defaultMessage="Printed By" />,
        key: "printedBy",
        render: record => {
          return record.printedBy;
        },
        sorter: true
      },
      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
              exportClick: false
            })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={21}></Col>
            <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                hidden={true}
                label={<FormattedMessage id="TimelineQrCode.qrCode" defaultMessage="QrCode" />}
                name="qrCode"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="QrCode" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="TimelineQrCode.imageSize" defaultMessage="ImageSize" />}
                name="imageSize"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber
                  placeholder="ImageSize"
                  min={200}
                  onChange={value => {
                    this.setState({
                      qrCodeSize: value
                    });
                  }}
                />
              </Form.Item>
            }
            <Form.Item {...layout} label={<FormattedMessage id="TimelineQrCode.preview" defaultMessage="Preview" />}>
              <QRCodeCanvas
                id="qrCodeImage"
                value={this.state.qrCode}
                size={this.state.qrCodeSize}
                level={"L"}
                // imageSettings={{
                //   src: toAbsoluteUrl("/media/logos/logo_elden_xsmall.png"),
                //   x: null,
                //   y: null,
                //   height: this.state.qrCodeSize / 4,
                //   width: this.state.qrCodeSize / 4,
                //   excavate: true
                // }}
              />
            </Form.Item>
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TimelineQRGenerator);
