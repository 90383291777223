import "antd/dist/antd.css";
import moment from "moment";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Spin, DatePicker } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { InputNumber } from "antd";
import { Select } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const { Option } = Select;

export class SalesmanCommisisionSettings extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "SalesmanCommisisionSettings",
      controllerName: "salesmanCommisisionSettings",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [], 

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      saveLoading: false
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responsecurrency = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responsecurrency.type === "ERROR") {
      error(responsecurrency);
    }
    this.setState({
      currencySelectItems: Boolean(responsecurrency.data) ? responsecurrency.data : []
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null, 
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
      effectiveDate: moment(row.effectiveDate)
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    this.setState({ saveLoading: true });
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
      this.setState({ saveLoading: false });
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({ saveLoading: false });
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="SalesmanCommisisionSettings.EffectiveDate" defaultMessage="Effective Date" />,
        key: "effectiveDate",
        width: 100,
        render: record => {
          return Boolean(record.effectiveDate) ? moment(record.effectiveDate).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "effectiveDate",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="SalesmanCommisisionSettings.MinSalesToPay" defaultMessage="Min. Sales to pay" />,
        key: "minCommisision",
        render: record => {
          return record.minCommisision + " " + record.commisisionCurrency;
        },
        ...getColumnFilter({
          inputName: "minCommisision",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalesmanCommisisionSettings.Commisisions" defaultMessage="Commisisions" />,
        key: "id",
        render: record => {
          let commisisions = [];
          if (record.rates && record.rates.length > 0) {
            record.rates.forEach(element => {
              commisisions.push(element.minLimit + "-" + element.maxLimit + " " + element.rate + "%");
            });
          }
          return commisisions.join(" / ");
        }
      }
    ];
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={22}></Col> 
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>
          <div hidden={this.state.hideInputs}>
            <Form.Item
              {...layout}
              label={<FormattedMessage id="SalesmanCommisisionSettings.effectiveDate" defaultMessage="Effective Date" />}
              name="effectiveDate"
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime={{ format: "HH:mm" }} />
            </Form.Item>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="SalesmanCommisisionSettings.MinSalesToPayCommission" defaultMessage="Min Sales To Pay Commission" />}
                style={{ marginBottom: "5px" }}
              >
                <Row gutter={8}>
                  <Col span={10}>
                    <Form.Item name="minCommisision">
                      <InputNumber
                        style={{
                          width: "100%"
                        }}
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={10}>
                    <Form.Item name="commisisionCurrency">
                      <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["currencySelectItems"]) &&
                          this.state["currencySelectItems"].length > 0 &&
                          this.state["currencySelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            }
            <Form.List name="rates">
              {(fields, { add, remove }) => {
                return (
                  <>
                    <TableBoot bordered size="sm">
                      <thead>
                        <tr>
                          <th colSpan="2" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"SalesmanCommisisionSettings.Limits"} defaultMessage={"Limits"} />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"SalesmanCommisisionSettings.CommissionRate"} defaultMessage={"Commission Rate"} />
                          </th>
                          <th colSpan="1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map(field => {
                          return (
                            <tr key={field.key}>
                              <td>
                                <Form.Item noStyle name={[field.name, "minLimit"]}>
                                  <InputNumber min={0} />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item noStyle name={[field.name, "maxLimit"]}>
                                  <InputNumber min={0} />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item noStyle name={[field.name, "rate"]}>
                                  <InputNumber min={0} max={100} />
                                </Form.Item>
                              </td>
                              <td>
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td colSpan={11}>
                            <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                              <FormattedMessage id={"SalesmanCommisisionSettings.AddNew"} defaultMessage={"Add New"} />
                            </AntButton>
                          </td>
                        </tr>
                      </tbody>
                    </TableBoot>
                  </>
                );
              }}
            </Form.List>
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Spin spinning={this.state.saveLoading}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Spin>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Spin spinning={this.state.saveLoading}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Spin>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>
        {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
          <div className="alert alert-custom alert-light">
            <div className="alert-icon">
              <FilterOutlined />
            </div>
            <div className="alert-text">
              {this.state.searchCriteriaModels.map((val, i) => {
                return (
                  <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                    <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                    <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                    <span>
                      <Tooltip title="Remove">
                        <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                      </Tooltip>
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="alert-close">
              <Tooltip title="Remove All">
                <CloseCircleOutlined
                  style={{ color: "crimson" }}
                  onClick={() =>
                    this.setState({ searchCriteriaModels: [] }, () => {
                      this.filterChangeItem([]);
                    })
                  }
                />
              </Tooltip>
            </div>
          </div>
        )}
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SalesmanCommisisionSettings);
