import { Form, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { handleRequest } from "../ApiConnector";
import { error } from "../MessageHelper";
import PrepareFormItemProps from "./ElementUtils";
import { changeParentSelectValue } from "./redux/dependantSelectSlice";

const RadioComponent = ({ field, layout }) => {
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const fillList = async () => {
    let url = field.loadUrl; //"/api/countries/selectItems";
    var response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else setList(Boolean(response.data) ? response.data : []);
  };
  let otherProps = {};
  if (field.radioType) {
    if (field.radioType === "button") {
      otherProps.buttonStyle = "solid";
    }
  }
  if (field.children) {
    otherProps.onChange = e => {
      dispatch(changeParentSelectValue({ key: field.inputName, value: e.target.value }));
    };
  }
  const radioElement = i => {
    switch (field.radioType) {
      case "button":
        return (
          <Radio.Button value={Boolean(field.propKey) ? i[field.propKey] : i.key} key={Boolean(field.propKey) ? i[field.propKey] : i.key}>
            {Boolean(field.propValue) ? i[field.propValue] : i.value}
          </Radio.Button>
        );
      default:
        return (
          <Radio value={Boolean(field.propKey) ? i[field.propKey] : i.key} key={Boolean(field.propKey) ? i[field.propKey] : i.key}>
            {Boolean(field.propValue) ? i[field.propValue] : i.value}
          </Radio>
        );
    }
  };
  useEffect(() => {
    fillList();
  }, []);
  return (
    <>
      <Form.Item {...layout} {...PrepareFormItemProps(field)}>
        <Radio.Group {...otherProps}>{list ? list.map(i => radioElement(i)) : null}</Radio.Group>
      </Form.Item>
    </>
  );
};

export default RadioComponent;
