import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Modal as AntModal } from "antd";
import { Select } from "antd";
import { Input } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Resizable } from "react-resizable";
import XMLParser from "react-xml-parser";

import { CashIn } from "./CashIn";
import { CashOut } from "./CashOut";

const { Option } = Select;

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class CashLogNew extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "CashLogNew",
      controllerName: "cashLogNew",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideCashIn: false,
      hideCashOut: false,
      hideInputs: false,
      showCashInModal: false,
      showCashOutModal: false,
      tableList: [],
      selectedRowKeysCashIn: [],
      selectedRowKeysCashOut: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      paginationCashIn: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();


  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responsecashId = await handleRequest("GET", "/api/" + this.state.controllerName + "/cashId");
    if (responsecashId.type === "ERROR") {
      error(responsecashId);
    }
    this.setState({
      cashIdSelectItems: Boolean(responsecashId.data) ? responsecashId.data : []
    });

    var responsebalance = await handleRequest("GET", "/api/" + this.state.controllerName + "/balance");
    if (responsebalance.type === "ERROR") {
      error(responsebalance);
    }
    var balance = null;
    try {
      balance = new XMLParser().parseFromString(responsebalance.data);
      console.log("KKK", balance);
    } catch (error) { }
    this.setState({
      cashIdSelectItems: Boolean(responsecashId.data) ? responsecashId.data : [],
      balance: balance,
    }, () => {
      let { deger } = this.getBalanceValue();
      this.setState({
        deger: deger,

      });
    }
    );
    this.setState({
      balanceSelectItems: Boolean(responsebalance.data) ? responsebalance.data : []
    });


    var responsebalanceId = await handleRequest("GET", "/api/" + this.state.controllerName + "/balanceId");
    if (responsebalanceId.type === "ERROR") {
      error(responsebalanceId);
    }
    this.setState({
      balanceIdSelectItems: Boolean(responsebalanceId.data) ? responsebalanceId.data : []
    });


    var responseKur = await handleRequest("GET", "/api/" + this.state.controllerName + "/kur");
    if (responseKur.type === "ERROR") {
      error(responseKur);
    }
    var kur = null;
    try {
      kur = new XMLParser().parseFromString(responseKur.data);
      console.log("KKK", kur);
    } catch (error) { }
    this.setState({
      balanceIdSelectItems: Boolean(responsebalanceId.data) ? responsebalanceId.data : [],
      kur: kur,
    }, () => {
      let { dollarKur, euroKur, poundKur, srKur } = this.getKurValue();
      this.setState({
        dollarKur: dollarKur,
        euroKur: euroKur,
        poundKur: poundKur,
        srKur: srKur,
      });
    }
    );
  };

  getPriceWtih(cashId) {
    let { balance, balanceId, dollarKur, euroKur, poundKur, srKur } = this.state;
    let balanceSubTotal = 0;
    if (Boolean(cashId) && (Boolean(cashId.balance))) {

      balanceSubTotal = balance;


    }
    console.log("balanceSubTotal", balanceSubTotal);
    return balanceSubTotal.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ');
  }

  getBalanceValue() {
    let balanceValue = 0;

    if (Boolean(this.state.cashId) && Boolean(this.state.cashId.children) && this.state.cashId.children.length > 0) {
      this.state.cashId.children.forEach(element => {
        if (Boolean(element.children) && element.children.length > 0) {
          let obj = element.children
          if (Boolean(obj)) {
            balanceValue = obj.value;
          }
        }
      });
    }
    return { balanceValue };
  }


  getPriceWtihKur(currency) {
    let { balance, balanceId, dollarKur, euroKur, poundKur, srKur } = this.state;
    let balanceSubTotal = 0;
    if (Boolean(currency) && Boolean(balance) && Boolean(balanceId)) {
      if (balanceId === "DOLLAR") {
        if (currency === "DOLLAR") {
          balanceSubTotal = balance;
        } else if (currency === "EURO") {
          balanceSubTotal = balance * (dollarKur / euroKur);
        } else if (currency === "POUND") {
          balanceSubTotal = balance * (dollarKur / poundKur);
        } else if (currency === "SR") {
          balanceSubTotal = balance * (dollarKur / srKur);
        } else if (currency === "TL") {
          balanceSubTotal = balance * dollarKur;
        }
      } else if (balanceId === "EURO") {
        if (currency === "EURO") {
          balanceSubTotal = balance;
        } else if (currency === "DOLLAR") {
          balanceSubTotal = balance * (euroKur / dollarKur);
        } else if (currency === "POUND") {
          balanceSubTotal = balance * (euroKur / poundKur);
        } else if (currency === "SR") {
          balanceSubTotal = balance * (euroKur / srKur);
        } else if (currency === "TL") {
          balanceSubTotal = balance * euroKur;
        }
      } else if (balanceId === "POUND") {
        if (currency === "POUND") {
          balanceSubTotal = balance;
        } else if (currency === "EURO") {
          balanceSubTotal = balance * (poundKur / euroKur);
        } else if (currency === "DOLLAR") {
          balanceSubTotal = balance * (poundKur / dollarKur);
        } else if (currency === "SR") {
          balanceSubTotal = balance * (poundKur / srKur);
        } else if (currency === "TL") {
          balanceSubTotal = balance * poundKur;
        }
      } else if (balanceId === "SR") {
        if (currency === "SR") {
          balanceSubTotal = balance;
        } else if (currency === "POUND") {
          balanceSubTotal = balance * (srKur / poundKur);
        } else if (currency === "EURO") {
          balanceSubTotal = balance * (srKur / euroKur);
        } else if (currency === "DOLLAR") {
          balanceSubTotal = balance * (srKur / dollarKur);
        } else if (currency === "TL") {
          balanceSubTotal = balance * srKur;
        }
      } else if (balanceId === "TL") {
        if (currency === "TL") {
          balanceSubTotal = balance;
        } else if (currency === "SR") {
          balanceSubTotal = balance * (1 / srKur);
        } else if (currency === "POUND") {
          balanceSubTotal = balance * (1 / poundKur);
        } else if (currency === "EURO") {
          balanceSubTotal = balance * (1 / euroKur);
        } else if (currency === "DOLLAR") {
          balanceSubTotal = balance * (1 / dollarKur);
        }
      } else {
        return 0;
      }
    }
    console.log("balanceSubTotal", balanceSubTotal);
    return balanceSubTotal.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ');
  }

  getKurValue() {
    let dollarKur = 0,
      euroKur = 0,
      poundKur = 0,
      srKur = 0;
    if (Boolean(this.state.kur) && Boolean(this.state.kur.children) && this.state.kur.children.length > 0) {
      this.state.kur.children.forEach(element => {
        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "USD") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            dollarKur = obj.value;
          }
        }

        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "EUR") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            euroKur = obj.value;
          }
        }

        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "GBP") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            poundKur = obj.value;
          }
        }

        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "SAR") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            srKur = obj.value;
          }
        }
      });
    }
    return { dollarKur, euroKur, poundKur, srKur };
  }


  cashOutModal = async () => {
    if (Boolean(this.state.cashId) && this.state.cashId > 0) {
      this.setState({
        showCashOutModal: true,
      });
    } else {
      showError("Please select cash");
    }
  };

  cashInModal = async () => {
    this.setState({
      showCashInModal: true,
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = async row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };



    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);



    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="CashLogNew.date" defaultMessage="Date" />,
        key: "date",
        render: record => {
          return record.date;
        }, ...getColumnFilter({
          inputName: "date", inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="CashLogNew.cashId" defaultMessage="cash" />,
        key: "cashId",
        render: record => {
          return record.cashName;
        }, ...getColumnFilter({
          inputName: "cashName", inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="CashLogNew.cashIn" defaultMessage="Cash In" />,
        key: "cashIn",
        render: record => {
          return record.cashIn;
        }, ...getColumnFilter({
          inputName: "cashIn", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="CashLogNew.cashOut" defaultMessage="Cash Out" />,
        key: "cashOut",
        render: record => {
          return record.cashOut;
        }, ...getColumnFilter({
          inputName: "cashOut", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="CashLogNew.reasonofTransaction" defaultMessage="Reason of Transaction" />,
        key: "reasonofTransaction",
        render: record => {
          return record.reasonofTransaction;
        }, ...getColumnFilter({
          inputName: "reasonofTransaction", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="CashLogNew.fromTo" defaultMessage="From To" />,
        key: "fromTo",
        render: record => {
          return record.fromTo;
        }, ...getColumnFilter({
          inputName: "fromTo", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="CashLogNew.modeofTransaction" defaultMessage="Mode of Transaction" />,
        key: "modeofTransaction",
        render: record => {
          return record.modeofTransaction;
        }, ...getColumnFilter({
          inputName: "modeofTransaction", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="CashLogNew.balance" defaultMessage="Balance" />,
        key: "balance",
        render: record => {
          return record.balance;
        }, ...getColumnFilter({
          inputName: "balance", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
    ];


    const excelExport = (<>
      {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}         {this.state.exportClick ? (<ExcelFile filename="CashLogNew" hideElement={true}>             <ExcelSheet name="CashLogNew" data={this.state.tableList}>               <ExcelColumn label="Date" value="date" />
      <ExcelColumn label="cash" value="cashName" />
      <ExcelColumn label="Cash In" value="cashIn" />
      <ExcelColumn label="Cash Out" value="cashOut" />
      <ExcelColumn label="Reason of Transaction" value="reasonofTransaction" />
      <ExcelColumn label="From To" value="fromTo" />
      <ExcelColumn label="Mode of Transaction" value="modeofTransaction" />
      <ExcelColumn label="Balance" value="balance" />
    </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel}
     icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)}       */}
    </>);
    return (
      <Badge.Ribbon text={<FormattedMessage id="cashLogNew.pageTitle" defaultMessage="Cash Log" />} placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>

            <br />
            <br />
            <Row gutter={[16, 16]}>
              <Col span={4}>
                <Form.Item
                  style={{ marginLeft: "25px" }}
                  label={<FormattedMessage id="CashLogNew.cashId" defaultMessage="Cash" />}
                  name="cashId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    onChange={value => {
                      this.setState({
                        cashId: value
                      });
                    }}
                  >
                    {Boolean(this.state["cashIdSelectItems"]) && this.state["cashIdSelectItems"].length > 0 &&
                      this.state["cashIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}><p> &nbsp;&nbsp;  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;</p></Col>
              <Col span={4}>
                <Form.Item

                  label={<FormattedMessage id="CashLogNew.balance" defaultMessage="Balance" />}
                  name="balance"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }}
                    onChange={e => {
                      this.setState({
                        balance: e.target.value,
                      }, () => {
                        let tl = this.getPriceWtihKur("TL");
                        let euro = this.getPriceWtihKur("EURO");
                        let dollar = this.getPriceWtihKur("DOLLAR");
                        let sr = this.getPriceWtihKur("SR");

                        this.formRef.current.setFieldsValue({

                          tl: tl + "₺",
                          euro: euro + "€",
                          dollar: dollar + "$",
                          sr: sr + "SR",
                        });
                      });
                    }}
                  ></Input>

                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={4}>
                <Form.Item

                  label={<FormattedMessage id="CashLogNew.balanceId" defaultMessage=" " />}
                  name="balanceId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    onChange={value => {
                      this.setState({
                        balanceId: value,
                      }, () => {
                        let tl = this.getPriceWtihKur("TL");
                        let euro = this.getPriceWtihKur("EURO");
                        let dollar = this.getPriceWtihKur("DOLLAR");
                        let sr = this.getPriceWtihKur("SR");
                        this.formRef.current.setFieldsValue({
                          tl: tl + "₺",
                          euro: euro + "€",
                          dollar: dollar + "$",
                          sr: sr + "SR",
                        });
                      });
                    }}
                  >
                    {Boolean(this.state["balanceIdSelectItems"]) && this.state["balanceIdSelectItems"].length > 0 &&
                      this.state["balanceIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={1}></Col>
            </Row>
            <br />

            <Row gutter={[16, 16]} >
              <Col span={4}><p>  &nbsp;&nbsp; </p></Col>

              <Col span={4}

              >

                <Form.Item

                  style={{ marginLeft: "50px" }}
                  label={<FormattedMessage id="CashLogNew.tl" defaultMessage=" " />}
                  name="tl"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input readOnly style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={4}>

                <Form.Item
                  readOnly

                  label={<FormattedMessage id="CashLogNew.dollar" defaultMessage=" " />}
                  name="dollar"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input readOnly style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={4}>
                <Form.Item

                  label={<FormattedMessage id="CashLogNew.euro" defaultMessage=" " />}
                  name="euro"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input readOnly style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={4}>
                <Form.Item

                  label={<FormattedMessage id="CashLogNew.sr" defaultMessage=" " />}
                  name="sr"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input readOnly style={{ width: "100%" }} />
                </Form.Item>
              </Col>

            </Row>




            <div hidden={this.state.hideInputs}>

              <Row gutter={[16, 16]} >

                <Col span={10}></Col>
                <Col span={4}>


                  <Button id="CashLogNew.cashIn" style={{ width: "100%", color: "white", background: "#139000", borderColor: "#139000" }} variant="secondary" onClick={this.cashInModal}>
                    <FormattedMessage id="CashLogNew.cashIn" defaultMessage="Cash in" />
                  </Button></Col>
                <Col span={1}></Col>
                <Col span={4}>

                  <Button id="CashLogNew.cashOut" style={{ width: "100%", color: "white", background: "#FF6666", borderColor: "#FF6666" }} variant="secondary" onClick={this.cashOutModal}>
                    <FormattedMessage id="CashLogNew.cashOut" defaultMessage="Cash out" />
                  </Button>
                </Col>


              </Row>
              <br />
            </div>
            <Row gutter={[16, 16]}>
              <Col md={21}></Col>
              <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={this.onClickCreateNew} icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton style={{ border: "0px" }} hidden={this.state.hideInputs} onClick={this.onClickCreateNew} icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 &&
            (<div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span>
                      <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span>
                      <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined
                            style={{ color: "crimson" }}
                            onClick={() => this.removeSearchCriteria(i)}
                          />
                        </Tooltip>
                      </span>
                    </div>);
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() => this.setState({
                      searchCriteriaModels: []
                    }, () => {
                      this.filterChangeItem([]);
                    })}
                  />
                </Tooltip>
              </div>
            </div>
            )
          }
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }}
            className="content-section implementation">
            <Table bordered scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              components={{
                header: {
                  cell: ResizableTitle
                }
              }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>
          <AntModal
            centered
            width={1200}
            title={<FormattedMessage id="CashLogNew.cashIn" defaultMessage="Cash in" />}
            open={this.state.showCashInModal}
            onCancel={() => {
              this.setState({
                showCashInModal: false
              });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showCashInModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
            ]}
          >
            <CashIn cashId={this.state.cashId} />
          </AntModal>
          <AntModal
            centered
            width={1200}
            title={<FormattedMessage id="CashLogNew.cashOut" defaultMessage="Cash Out" />}
            open={this.state.showCashOutModal}
            onCancel={() => {
              this.setState({
                showCashOutModal: false
              });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showCashOutModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
            ]}
          >
            <CashOut cashId={this.state.cashId} />
          </AntModal>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(CashLogNew);
