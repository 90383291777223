import { FormattedMessage } from "react-intl";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import MenuIcon from "@material-ui/icons/Menu";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { MessageOutlined } from "@ant-design/icons";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import { ReplyOutlined } from "@material-ui/icons";
import SendIcon from "@material-ui/icons/Send";

export default ({
  row,
  onDeleteRow,
  hasDelete,
  onEditRow,
  hasEdit,
  onReviseRow,
  hasRevise,
  onSendToVendorsRow,
  hasSendToVendor,
  onResponsesRow,
  hasResponses,
  onScoringRow,
  hasScoring,
  onRepliesRow,
  hasReplies,
  onCloseTenderingRow,
  hasCloseTendering,
  onEvaluationRow,
  hasEvaluation,
  onEvaluationNewRow,
  size,
  editBgColor,
  deleteBgColor
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(!open);
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteRow = () => {
    if (onDeleteRow) {
      onDeleteRow(row);
      setAnchorEl(null);
    }
  };

  const editRow = () => {
    if (onEditRow) {
      onEditRow(row);
      setAnchorEl(null);
    }
  };

  const reviseRow = () => {
    if (onReviseRow) {
      onReviseRow(row);
      setAnchorEl(null);
    }
  };

  const closeTenderingRow = () => {
    if (onCloseTenderingRow) {
      onCloseTenderingRow(row);
      setAnchorEl(null);
    }
  };

  const scoringRow = () => {
    if (onScoringRow) {
      onScoringRow(row);
      setAnchorEl(null);
    }
  };

  const repliesRow = () => {
    if (onRepliesRow) {
      onRepliesRow(row);
      setAnchorEl(null);
    }
  };

  const responseRow = () => {
    if (onResponsesRow) {
      onResponsesRow(row);
      setAnchorEl(null);
    }
  };

  const sendToVendorsRow = () => {
    if (onSendToVendorsRow) {
      onSendToVendorsRow(row);
      setAnchorEl(null);
    }
  };

  const evaluationRow = () => {
    if (onEvaluationRow) {
      onEvaluationRow(row);
      setAnchorEl(null);
    }
  };

  const evaluationNewRow = () => {
    if (onEvaluationNewRow) {
      onEvaluationNewRow(row);
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick} size={size}>
        <MenuIcon color="primary" />
      </IconButton>
      <Menu
        id="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {hasEdit === true && (
          <>
            <MenuItem onClick={editRow} style={{ backgroundColor: "#" + editBgColor }}>
              <ListItemIcon>
                <EditIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <Typography variant="inherit">
                <FormattedMessage id="GeneralAction.Edit" defaultMessage="Edit" />
              </Typography>
            </MenuItem>
            <Divider />
          </>
        )}

        {hasRevise === true && (
          <>
            <MenuItem onClick={reviseRow} style={{ backgroundColor: "#" + editBgColor }}>
              <ListItemIcon>
                <AccountTreeIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <Typography variant="inherit">
                <FormattedMessage id="GeneralAction.Revise" defaultMessage="Revise" />
              </Typography>
            </MenuItem>
            <Divider />
          </>
        )}

        {hasResponses === true && (
          <>
            <MenuItem style={{ backgroundColor: "#" + editBgColor }}>
              <ListItem button onClick={responseRow}>
                <ListItemIcon>
                  <EditIcon fontSize="small" style={{ border: "0px", color: "#007bff" }} />
                </ListItemIcon>
                <ListItemText primary="Responses" />
              </ListItem>
              {(hasReplies === true || hasScoring === true) && (
                <Typography variant="inherit">
                  <ListItem button onClick={handleClickOpen}>
                    {open ? <IconExpandLess /> : <IconExpandMore />}
                  </ListItem>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Divider />
                    <List component="div" disablePadding>
                      {hasReplies === true && (
                        <ListItem onClick={repliesRow} button>
                          <ListItemText inset primary={<FormattedMessage id="GeneralAction.Replies" defaultMessage="Replies" />} />
                        </ListItem>
                      )}
                      {hasScoring === true && (
                        <ListItem onClick={scoringRow} button>
                          <ListItemText inset primary={<FormattedMessage id="GeneralAction.Scoring" defaultMessage="Scoring" />} />
                        </ListItem>
                      )}
                    </List>
                  </Collapse>
                </Typography>
              )}
            </MenuItem>
            <Divider />
          </>
        )}

        {hasCloseTendering === true && (
          <>
            <MenuItem onClick={closeTenderingRow} style={{ backgroundColor: "#" + editBgColor }}>
              <ListItemIcon>
                <CloseIcon fontSize="small" style={{ border: "0px", color: "#007bff" }} />
              </ListItemIcon>
              <Typography variant="inherit">
                <FormattedMessage id="GeneralAction.CloseTendering" defaultMessage="Close Tendering" />
              </Typography>
            </MenuItem>
            <Divider />
          </>
        )}

        {hasSendToVendor === true && (
          <>
            <MenuItem onClick={sendToVendorsRow} style={{ backgroundColor: "#" + editBgColor }}>
              <ListItemIcon>
                <SendIcon fontSize="small" style={{ border: "0px", color: "#007bff" }} />
              </ListItemIcon>
              <Typography variant="inherit">
                <FormattedMessage id="GeneralAction.SendToVendors" defaultMessage="Send To Vendors" />
              </Typography>
            </MenuItem>
            <Divider />
          </>
        )}

        {hasEvaluation === true && (
          <>
          {/** 
            <MenuItem onClick={evaluationRow} style={{ backgroundColor: "#" + editBgColor }}>
              <ListItemIcon>
                <ReplyOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />
              </ListItemIcon>
              <Typography variant="inherit">
                <FormattedMessage id="GeneralAction.Evaluation" defaultMessage="Evaluation" />
              </Typography>
            </MenuItem>
            <Divider />
        */}
            <MenuItem onClick={evaluationNewRow} style={{ backgroundColor: "#" + editBgColor }}>
              <ListItemIcon>
                <ReplyOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />
              </ListItemIcon>
              <Typography variant="inherit">
                <FormattedMessage id="GeneralAction.EvaluationNew" defaultMessage="Evaluation " />
              </Typography>
            </MenuItem>
            <Divider />
          </>
        )}

        {hasDelete === true && (
          <MenuItem onClick={deleteRow} style={{ backgroundColor: "#" + deleteBgColor }}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" color="error" />
            </ListItemIcon>
            <Typography variant="inherit">
              <FormattedMessage id="GeneralAction.Delete" defaultMessage="Delete" />
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
