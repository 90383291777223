import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import PageviewIcon from "@material-ui/icons/Pageview";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { handleRequest } from "../../ApiConnector";
import { API_BASE_ROOT } from "../../ApiConnector";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import CustomDrawingMenu from "../CustomMenu/CustomDrawingMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import {
  Form,
  message,
  Table,
  Input,
  Select,
  Switch,
  Col,
  Row,
  DatePicker,
  Button as AntButton,
  InputNumber,
  Divider,
} from "antd";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { Upload } from "antd";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import { DownloadOutlined } from "@ant-design/icons";
import ShareIcon from "@material-ui/icons/Share";
import SyncIcon from "@material-ui/icons/Sync";
import store from "../../../redux/store";
import { error, showError, showSuccess } from "../../MessageHelper";

const { Option } = Select;

function JSDateToExcelDate(inDate) {
  var returnDateTime =
    25569.0 +
    (inDate.getTime() - inDate.getTimezoneOffset() * 60 * 1000) /
      (1000 * 60 * 60 * 24);
  return returnDateTime.toString().substr(0, 20);
}

export class Drawing extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,

      hideUpdate: true,
      hideSave: false,
      hiddenInputs: true,
      filePath: "",
      filePaths: [],
      fileList: [],
      bulkFileList: [],
      showRevisedListModal: false,
      showLinkedListModal: false,
      revisedFileList: [],
      modalPdfViewer: false,
      pdfFile: "",
      codeName: "",
      drawingTypeSelectItems: [],
      disciplineSelectItems: [],
      locationSelectItems: [],
      materialEquipmentSelectItems: [],
      id: null,
      modalSupersedeShow: false,
      selectedRow: null,
      revising: false,

      disciplineId: null,
      drawingTypeId: null,
      drawingSearch: null,
      linkDrawingList: [],
      linkedDrawingIds: [],
      dateOfSupersede: null,
      modalSupersedeAction: false,
      index: null,
      disabledLinkedDrawings: false,
      user: store.getState().auth.user,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: [
          "2",
          "10",
          "25",
          "50",
          "100",
          "1000",
          "10000",
          "100000",
          "1000000",
        ],
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });

    this.fillDisciplines();
    this.fillDrawingTypes();
    this.refreshLinkDrawingList();
    this.fillLocations();
    this.fillMaterialEquipment();
  };

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hiddenInputs: true,
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();

    this.setState({
      filePath: "",
      filePaths: [],
      fileList: [],
      bulkFileList: [],
      id: null,
      revising: false,
      disabledLinkedDrawings: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: [
          "2",
          "10",
          "25",
          "50",
          "100",
          "1000",
          "10000",
          "100000",
          "1000000",
        ],
      },
    });
  };

  edit = (row) => {
    let fileList = [];
    let linkedDrawingIds = [];

    let pathList = Boolean(row.fileUrl) ? row.fileUrl.split(",") : [];
    if (Boolean(pathList) && pathList.length > 0) {
      pathList.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element,
        };

        fileList.push(obj);
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      date: moment(row.date),
    });

    if (Boolean(row.linkedDrawingList))
      row.linkedDrawingList.forEach((element) => {
        linkedDrawingIds.push(element.linkedDrawingId);
      });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hiddenInputs: false,

      id: row.id,
      fileList: fileList,
      filePaths: Boolean(row.fileUrl) ? row.fileUrl.split(",") : [],
      linkedDrawingIds: linkedDrawingIds,
    });
  };

  addNew = (row, type) => {
    let linkedDrawingIds = [];
    linkedDrawingIds.push(row.id);
    this.getDrawing(row.id);

    let fObj = this.state.drawingTypeSelectItems.find((p) => p.name === type);

    this.formRef.current.setFieldsValue({
      ...row,
      date: moment(row.date),
      drawingTypeId: Boolean(fObj) ? fObj.id : null,
      linkedDrawingIds: linkedDrawingIds,
    });

    this.setState({
      hideUpdate: true,
      hideSave: false,
      hiddenInputs: false,
      id: null,
      drawingTypeId: Boolean(fObj) ? fObj.id : null,
      linkedDrawingIds: linkedDrawingIds,
      disabledLinkedDrawings: true,
    });
  };

  revise = (row) => {
    let fileList = [];

    let pathList = Boolean(row.fileUrl) ? row.fileUrl.split(",") : [];
    if (Boolean(pathList) && pathList.length > 0) {
      pathList.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element,
        };

        fileList.push(obj);
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      revNo: row.revNo + 1,
      date: moment(row.date),
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hiddenInputs: false,

      id: row.id,

      fileList: fileList,
      filePaths: Boolean(row.fileUrl) ? row.fileUrl.split(",") : [],
      revising: true,
    });
  };

  showRevisedList = async (row) => {
    let url = "/api/revisedDrawings/drawings/" + row.id;

    const response = await handleRequest("GET", url);

    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        revisedFileList: response.data,
        showRevisedListModal: true,
      });
    } else {
      this.setState({
        revisedFileList: [],
        showRevisedListModal: false,
      });

      showError("There is no revised list for selected item");
    }
  };

  showLinkedList = async (row) => {
    let url = "/api/drawings/linked/" + row.id;

    const response = await handleRequest("GET", url);

    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        revisedFileList: response.data,
        showLinkedListModal: true,
      });
    } else {
      this.setState({
        revisedFileList: [],
        showLinkedListModal: false,
      });

      this.showError("There is no linked drawings for selected item");
    }
  };

  openShareModal = (row) => {
    this.setState({
      shareFileId: row.id,
      showShareModal: true,
    });
  };

  deleteModal = (row) => {
    this.setState({
      modalDeleteShow: true,
      id: row.id,
    });
  };

  supersedeModal = (row) => {
    this.setState({
      modalSupersedeShow: true,
      selectedRow: row,
      id: row.id,
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow,
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id,
    };
    var response = await handleRequest(
      "DELETE",
      "/api/drawings/" + deletedItem.id
    );

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false,
      });
    }
  };

  supersede = async () => {
    let linkedDrawingIds = [];
    if (Boolean(this.state.dateOfSupersede)) {
      linkedDrawingIds.push(this.state.id);
      const obj = {
        id: this.state.id,
        dateOfSupersede: this.state.dateOfSupersede,
      };
      var response = await handleRequest("PUT", "/api/drawings/supersede", obj);

      if (response.type === "ERROR") {
        error(response);
      } else {
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.setState({
          id: null,
          modalSupersedeShow: false,
          modalSupersedeAction: true,
          linkedDrawingIds: linkedDrawingIds,
        });
      }
    } else this.showError("Please select date of supersede");
  };

  share = async () => {
    const { shareFileId, selectedMailAddress } = this.state;

    const shareObject = {
      fileIds: [],
      emails: [],
    };

    shareObject.fileIds.push(shareFileId);
    if (Boolean(selectedMailAddress) && selectedMailAddress.length > 0) {
      selectedMailAddress.forEach((element) => {
        shareObject.emails.push(element);
      });

      var response = await handleRequest(
        "POST",
        "/api/drawings/share",
        shareObject
      );

      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess("File sended successfully");
        this.setState({
          showShareModal: false,
          shareFileId: null,
          shareEmail: null,
        });
      }
    } else this.showError("Please type least one email address");
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);

    let url = "/api/drawings/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0,
        },
        sortField: "",
        sortOrder: "",
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;

        list.forEach((element, index) => {
          element.sn = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,

          pagination: {
            ...params.pagination,
            total: response.data.totalRecords,
          },
          sortField: params.sortField,
          sortOrder: params.sortOrder,
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0,
          },
          sortField: "",
          sortOrder: "",
        });
      }
    }
  };

  onClickCreateNew = async () => {
    this.resetInputs();
    if (this.state.hiddenInputs) {
      this.setState({
        hiddenInputs: false,
        hiddenSave: false,
        hiddenUpdate: true,
      });
    } else {
      this.setState({
        hiddenInputs: true,
        hiddenSave: true,
        hiddenUpdate: false,
      });
    }
  };

  save = async (values) => {
    const { linkedDrawingIds } = this.state;
    const { filePaths } = this.state;

    const newItem = {
      ...values,
      id: this.state.id,
      date: values.date.toDate(),
      fileUrl: Boolean(filePaths) ? filePaths.toString() : "",
      linkedDrawingList: [],
    };

    if (Boolean(linkedDrawingIds)) {
      linkedDrawingIds.forEach((element) => {
        let obj = { linkedDrawingId: element };
        newItem.linkedDrawingList.push(obj);
      });
    }

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/drawings", newItem);
    } else {
      if (this.state.revising === true)
        response = await handleRequest(
          "PUT",
          "/api/drawings/revise/" + newItem.id,
          newItem
        );
      else
        response = await handleRequest(
          "PUT",
          "/api/drawings/" + newItem.id,
          newItem
        );
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.resetInputs();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.cancel();
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;
    var current = this.state.pagination.current;
    var pageSize = this.state.pagination.pageSize;
    var sortField = "",
      sortOrder = "";

    if (Boolean(params) && Boolean(params.pagination)) {
      current = params.pagination.current;
      pageSize = params.pagination.pageSize;

      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      firstResult: current - 1, // (current - 1) * pageSize,
      maxResults: pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
    };
    return newObj;
  }

  downloadFile = async (url) => {
    window.location.href = url;
  };

  downloadMultipleFile = async (url) => {
    if (Boolean(url) && url.length > 0) {
      let pathList = url.split(",");
      console.log(pathList);
      if (Boolean(pathList) && pathList.length > 1) {
        var response = await handleRequest(
          "POST",
          "/api/transmittalLogs/download",
          pathList
        );

        if (response.type === "ERROR") {
          error(response);
        } else {
          try {
            const link = document.createElement("a");
            link.href = response.data;
            link.download = "Fİles.zip";
            link.click();
          } catch (error) {
            console.log(error);
          }
        }
      } else if (Boolean(pathList) && pathList.length === 1)
        this.downloadFile(pathList[0]);
    } else this.showError("There is no uploaded file !");
  };

  showReport = async (url) => {
    try {
      const fileRealUrl = url;

      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true,
      });
    } catch (error) {
      alert("File cant found.");
    }
  };

  searchUserMail = async (email) => {
    if (Boolean(email) && email.length > 0) {
      var response = await handleRequest("GET", "/api/users/mails/" + email);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          userSelectItems: Boolean(response.data) ? response.data : [],
        });
      }
    } else
      this.setState({
        userSelectItems: [],
      });
  };

  addItem = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: "discipline",
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: "",
        });
        this.fillDisciplines();
      }
    } else {
      this.showError("Please enter discipline");
    }
  };

  fillDisciplines = async () => {
    const response = await handleRequest("GET", "/api/codes/type/discipline");
    if (Boolean(response.data)) {
      this.setState({
        disciplineSelectItems: response.data,
      });
    }
  };

  addLocation = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: "location",
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: "",
        });
        this.fillLocations();
      }
    } else {
      this.showError("Please enter discipline");
    }
  };

  fillLocations = async () => {
    const response = await handleRequest("GET", "/api/codes/type/location");
    if (Boolean(response.data)) {
      this.setState({
        locationSelectItems: response.data,
      });
    }
  };

  addDrawingType = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: "drawingType",
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: "",
        });
        this.fillDrawingTypes();
      }
    } else {
      this.showError("Please enter discipline");
    }
  };

  fillDrawingTypes = async () => {
    const response = await handleRequest("GET", "/api/codes/type/drawingType");
    if (Boolean(response.data)) {
      this.setState({
        drawingTypeSelectItems: response.data,
      });
    }
  };

  addMaterialEquipment = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: "materialEquipment",
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: "",
        });
        this.fillMaterialEquipment();
      }
    } else {
      this.showError("Please enter discipline");
    }
  };

  fillMaterialEquipment = async () => {
    const response = await handleRequest(
      "GET",
      "/api/codes/type/materialEquipment"
    );
    if (Boolean(response.data)) {
      this.setState({
        materialEquipmentSelectItems: response.data,
      });
    }
  };

  actionTemplate(row) {
    return (
      <React.Fragment>
        <CustomDrawingMenu
          row={row}
          onDeleteRow={this.deleteModal.bind(this)}
          onReviseRow={this.revise.bind(this)}
          onSupersedeRow={this.supersedeModal.bind(this)}
          onEditRow={this.edit.bind(this)}
          onAddNew={this.addNew.bind(this)}
        />
      </React.Fragment>
    );
  }

  refreshLinkDrawingList = async (drawingSearch) => {
    const { disciplineId, drawingTypeId } = this.state;

    let obj = {
      disciplineId: disciplineId,
      drawingTypeId: drawingTypeId,
      drawingNo: drawingSearch,
      shtNo: drawingSearch,
    };

    const response = await handleRequest("POST", "/api/drawings/search", obj);
    if (Boolean(response.data)) {
      this.setState({
        linkDrawingList: response.data,
      });
    } else
      this.setState({
        linkDrawingList: [],
      });
  };

  getDrawing = async (id) => {
    let list = [];
    const response = await handleRequest("GET", "/api/drawings/" + id);
    if (Boolean(response.data)) {
      list.push(response.data);
      this.setState({
        linkDrawingList: list,
      });
    } else
      this.setState({
        linkDrawingList: [],
      });
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true,
    });
  };

  ExcelDateToJSDate = (serial) => {
    if ((serial + "").includes(".")) {
      return moment(serial, "DD.MM.YYYY").toDate();
    } else {
      var hours = Math.floor((serial % 1) * 24);
      var minutes = Math.floor(((serial % 1) * 24 - hours) * 60);
      return new Date(Date.UTC(0, 0, serial, hours - 17, minutes));
    }
  };

  handleUploadExcel = async (excelJson) => {
    try {
      var list = [];
      if (excelJson === null) this.showError("Select an excel file");
      else {
        excelJson.forEach((element) => {
          const obj = {
            date: this.ExcelDateToJSDate(element["Date"]),
            disciplineStr: element["Discipline"],
            drawingTypeStr: element["Drawing Type"],
            drawingNo: element["Drawing No"],
            shtNo: element["Sheet no"],
            revNo: element["Rev no"],
            index: element["Index"],
            locationStr: element["Location"],
            materialEquipmentStr: element["Material/Equipment"],
            description: element["Description"],
            status: element["Status"],
            remarks: element["Remarks"],
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest(
            "POST",
            "/api/drawings/import",
            list
          );
          if (response.type === "ERROR") {
            this.showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.cancel();
          }
        } else {
          this.showError("No records to be added");
        }
      }
    } catch (error) {
      this.showError("");
    }
  };

  sync = async () => {
    let response = await handleRequest("GET", "/api/drawings/sync");

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.resetInputs();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.cancel();
    }
  };

  render() {
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };

    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
    };

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center",
            }}
          >
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList],
    };

    const propsBulk = {
      action: `${API_BASE_ROOT}/File/upload/drawing`,
      listType: "picture",
      defaultFileList: [...this.state.bulkFileList],
    };

    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar
      );
    };

    const revisedColumns = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        },
      },

      {
        title: "Date",
        key: "date",
        render: (record) => {
          if (Boolean(record.date)) {
            return moment(record.date).format("YYYY-MM-DD");
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <DatePicker
              style={{ flex: "auto" }}
              value={
                Boolean(this.state.pagination.barcode)
                  ? this.state.pagination.barcode
                  : ""
              }
              placeholder="Date"
              onChange={async (date) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  date: date,
                  dateColor: Boolean(date) ? "#1890ff" : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.dateColor)
                ? this.state.pagination.dateColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
      },

      {
        title: "Discipline",
        key: "disciplineId",
        render: (record) => {
          if (Boolean(record.discipline)) {
            return record.discipline.name;
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Discicipline"
              onChange={(value) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  disciplineId: value,
                  disciplineColor: Boolean(value) ? "#1890ff" : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              {this.state.disciplineSelectItems.map((i) => (
                <Option key={i.id} value={i.id}>
                  {i.name}
                </Option>
              ))}
            </Select>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.disciplineColor)
                ? this.state.pagination.disciplineColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
      },
      {
        title: "Drawing Type",
        key: "drawingTypeId",
        render: (record) => {
          if (Boolean(record.drawingType)) {
            return record.drawingType.name;
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Drawing Type"
              onChange={(value) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  drawingTypeId: value,
                  drawingTypeColor: Boolean(value) ? "#1890ff" : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              {this.state.drawingTypeSelectItems.map((i) => (
                <Option key={i.id} value={i.id}>
                  {i.name}
                </Option>
              ))}
            </Select>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.drawingTypeColor)
                ? this.state.pagination.drawingTypeColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
      },

      {
        title: "Drawing No",
        key: "drawingNo",
        render: (record) => {
          return Boolean(record.fileUrl) && record.fileUrl.length > 0 ? (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline",
                }}
                onClick={(e) => this.downloadMultipleFile(record.fileUrl)}
              >
                {record.drawingNo}
              </div>
            </React.Fragment>
          ) : (
            record.drawingNo
          );
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Drawing No"
              onChange={(e) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  drawingNo: e.target.value,
                  drawingNoColor: Boolean(e.target.value)
                    ? "#1890ff"
                    : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            ></Input>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.drawingNoColor)
                ? this.state.pagination.drawingNoColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
      },

      {
        title: "Sht No",
        key: "shtNo",
        render: (record) => {
          return record.shtNo;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Sht No"
              onChange={(e) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  shtNo: e.target.value,
                  shtNoColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            ></Input>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.shtNoColor)
                ? this.state.pagination.shtNoColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
      },
      {
        title: "Rev No",
        key: "revNo",
        render: (record) => {
          return record.revNo;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Rev. No"
              onChange={(e) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  revNo: e.target.value,
                  revNoColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            ></Input>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.revNoColor)
                ? this.state.pagination.revNoColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
      },
      {
        title: "Location",
        key: "locationId",
        render: (record) => {
          if (Boolean(record.location)) {
            return record.location.name;
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Location"
              onChange={(value) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  locationId: value,
                  locationColor: Boolean(value) ? "#1890ff" : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              {this.state.locationSelectItems.map((i) => (
                <Option key={i.id} value={i.id}>
                  {i.name}
                </Option>
              ))}
            </Select>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.locationColor)
                ? this.state.pagination.locationColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
      },
      {
        title: "Description",
        key: "description",
        render: (record) => {
          return record.description;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Description"
              onChange={(e) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  description: e.target.value,
                  descriptionColor: Boolean(e.target.value)
                    ? "#1890ff"
                    : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            ></Input>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.descriptionColor)
                ? this.state.pagination.descriptionColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
        width: 200,
      },

      {
        title: "Status",
        key: "status",
        render: (record) => {
          if (Boolean(record.status)) {
            return record.status;
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Status"
              onChange={(value) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  status: value,
                  statusColor: Boolean(value) ? "#1890ff" : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              <Option key={"Active"} value={"Active"}>
                {"Active"}
              </Option>
              <Option key={"Superseded"} value={"Superseded"}>
                {"Superseded"}
              </Option>
            </Select>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.statusColor)
                ? this.state.pagination.statusColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
      },

      {
        title: "Remarks",
        key: "remarks",
        render: (record) => {
          return record.remarks;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Remarks"
              onChange={(e) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  remarks: e.target.value,
                  remarksColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            ></Input>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.remarksColor)
                ? this.state.pagination.remarksColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
      },
    ];

    const columns = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return (
            index +
            1 +
            this.state.pagination.pageSize * (this.state.pagination.current - 1)
          );
        },
        width: 80,
        ellipsis: true,
      },

      {
        title: "Date",
        key: "date",
        render: (record) => {
          if (Boolean(record.date)) {
            return moment(record.date).format("YYYY-MM-DD");
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <DatePicker
              style={{ flex: "auto" }}
              value={
                Boolean(this.state.pagination.date)
                  ? this.state.pagination.date
                  : ""
              }
              placeholder="Date"
              onChange={async (date) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  date: date,
                  dateColor: Boolean(date) ? "#1890ff" : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.dateColor)
                ? this.state.pagination.dateColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
        width: 150,
        ellipsis: true,
      },

      {
        title: "Discipline",
        key: "disciplineId",
        render: (record) => {
          if (Boolean(record.discipline)) {
            return record.discipline.name;
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Discipline"
              id="DefinitionsType"
              onChange={(value) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  disciplineId: value,
                  disciplineColor: Boolean(value) ? "#1890ff" : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              {this.state.disciplineSelectItems.map((i) => (
                <Option key={i.id} value={i.id}>
                  {i.name}
                </Option>
              ))}
            </Select>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.disciplineColor)
                ? this.state.pagination.disciplineColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
        width: 150,
        ellipsis: true,
      },
      {
        title: "Drawing Type",
        key: "drawingTypeId",
        render: (record) => {
          if (Boolean(record.drawingType)) {
            return record.drawingType.name;
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Drawing Type"
              onChange={(value) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  drawingTypeId: value,
                  drawingTypeColor: Boolean(value) ? "#1890ff" : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              {this.state.drawingTypeSelectItems.map((i) => (
                <Option key={i.id} value={i.id}>
                  {i.name}
                </Option>
              ))}
            </Select>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.drawingTypeColor)
                ? this.state.pagination.drawingTypeColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
        width: 200,
        ellipsis: true,
      },

      {
        title: "Drawing No",
        key: "drawingNo",
        render: (record) => {
          return Boolean(record.fileUrl) && record.fileUrl.length > 0 ? (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline",
                }}
                onClick={(e) => this.downloadMultipleFile(record.fileUrl)}
              >
                {record.drawingNo}
              </div>
            </React.Fragment>
          ) : (
            record.drawingNo
          );
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              showSearch
              optionFilterProp="children"
              style={{ width: 200 }}
              placeholder="Drawing No"
              onChange={(e) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  drawingNo: e.target.value,
                  drawingNoColor: Boolean(e.target.value)
                    ? "#1890ff"
                    : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            ></Input>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.drawingNoColor)
                ? this.state.pagination.drawingNoColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
        width: 200,
        ellipsis: true,
      },

      {
        title: "Sht No",
        key: "shtNo",
        render: (record) => {
          return record.shtNo;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              showSearch
              optionFilterProp="children"
              style={{ width: 200 }}
              placeholder="Sht No"
              onChange={(e) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  shtNo: e.target.value,
                  shtNoColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            ></Input>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.shtNoColor)
                ? this.state.pagination.shtNoColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
        width: 150,
        ellipsis: true,
      },
      {
        title: "Rev No",
        key: "revNo",
        render: (record) => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline",
                }}
                onClick={(e) => this.showRevisedList(record)}
              >
                {record.revNo}
              </div>
            </React.Fragment>
          );
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              showSearch
              optionFilterProp="children"
              style={{ width: 200 }}
              placeholder="Rev. No"
              onChange={(e) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  revNo: e.target.value,
                  revNoColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            ></Input>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.revNoColor)
                ? this.state.pagination.revNoColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
        width: 120,
        ellipsis: true,
      },

      {
        title: "Index",
        key: "indexx",
        render: (record) => {
          return record.index;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              showSearch
              optionFilterProp="children"
              style={{ width: 200 }}
              placeholder="Index"
              onChange={(e) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  index: e.target.value,
                  indexColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            ></Input>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.indexColor)
                ? this.state.pagination.indexColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
        width: 100,
        ellipsis: true,
      },

      {
        title: "Location",
        key: "locationId",
        render: (record) => {
          if (Boolean(record.location)) {
            return record.location.name;
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Location"
              onChange={(value) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  locationId: value,
                  locationColor: Boolean(value) ? "#1890ff" : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              {this.state.locationSelectItems.map((i) => (
                <Option key={i.id} value={i.id}>
                  {i.name}
                </Option>
              ))}
            </Select>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.locationColor)
                ? this.state.pagination.locationColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
        width: 150,
        ellipsis: true,
      },

      {
        title: "Material/Equipment",
        key: "materialEquipmentId",
        render: (record) => {
          if (Boolean(record.materialEquipment)) {
            return record.materialEquipment.name;
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Material/Equipment"
              onChange={(value) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  materialEquipmentId: value,
                  materialEquipmentIdColor: Boolean(value)
                    ? "#1890ff"
                    : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              {this.state.materialEquipmentSelectItems.map((i) => (
                <Option key={i.id} value={i.id}>
                  {i.name}
                </Option>
              ))}
            </Select>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.materialEquipmentIdColor)
                ? this.state.pagination.materialEquipmentIdColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
        width: 200,
        ellipsis: true,
      },

      {
        title: "Description",
        key: "description",
        render: (record) => {
          return record.description;
        },

        width: 200,
      },

      {
        title: "Status",
        key: "status",
        render: (record) => {
          if (Boolean(record.status)) {
            return record.status;
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Status"
              onChange={(value) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  status: value,
                  statusColor: Boolean(value) ? "#1890ff" : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              <Option key={"Active"} value={"Active"}>
                {"Active"}
              </Option>
              <Option key={"Superseded"} value={"Superseded"}>
                {"Superseded"}
              </Option>
            </Select>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.statusColor)
                ? this.state.pagination.statusColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
        width: 120,
        ellipsis: true,
      },

      // {
      //     title: 'File', key: 'fileUrl', render: (record) => {

      //         if (Boolean(record.fileUrl))
      //             return <AntButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadMultipleFile(record.fileUrl)} type="primary" shape="round" icon={<DownloadOutlined />} >

      //             </AntButton>

      //     },
      //     width: 120,
      //     ellipsis: true,

      // },
      {
        title: "Remarks",
        key: "remarks",
        render: (record) => {
          return record.remarks;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              showSearch
              optionFilterProp="children"
              style={{ width: 200 }}
              placeholder="Remarks"
              onChange={(e) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  remarks: e.target.value,
                  remarksColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            ></Input>
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.remarksColor)
                ? this.state.pagination.remarksColor
                : "#bfbfbf",
            }}
          />
        ),
        sorter: true,
        width: 120,
        ellipsis: true,
      },
      {
        title: "Linked Drawings",
        key: "key",
        render: (record) => {
          return (
            <PageviewIcon
              fontSize="default"
              color={
                Boolean(record.linkedDrawingList) &&
                record.linkedDrawingList.length > 0
                  ? "primary"
                  : "action"
              }
              backgroundColor="black"
              onClick={() => this.showLinkedList(record)}
            />
          );
        },
        width: 100,
        ellipsis: true,
      },

      // {
      //     title: 'Revised Versions', key: 'key', render: (record) => {
      //         return <PageviewIcon fontSize="default" color={Boolean(record.revisedDrawingList) && record.revisedDrawingList.length > 0 ? "primary" : "action"} backgroundColor="black" onClick={() => this.showRevisedList(record)} />
      //     },
      //     width: 100,
      //     ellipsis: true,
      // },

      {
        title: "Actions",
        key: "name",
        render: (record) => {
          return this.actionTemplate(record);
        },
        width: 100,
        ellipsis: true,
      },
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
              exportClick: false,
            })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Drawing List" hideElement={true}>
            <ExcelSheet name="Drawing List" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="sn" />
              <ExcelColumn
                label="Date"
                value={(col) =>
                  Boolean(col.date)
                    ? moment(col.date).format("DD.MM.YYYY")
                    : null
                }
              />
              <ExcelColumn
                label="Discipline"
                value={(col) =>
                  Boolean(col.discipline) ? col.discipline.name : ""
                }
              />
              <ExcelColumn
                label="Drawing Type"
                value={(col) =>
                  Boolean(col.drawingType) ? col.drawingType.name : ""
                }
              />
              <ExcelColumn label="Drawing No" value="drawingNo" />
              <ExcelColumn label="Sheet no" value="shtNo" />
              <ExcelColumn label="Rev no" value="revNo" />
              <ExcelColumn label="Index" value="index" />
              <ExcelColumn
                label="Location"
                value={(col) =>
                  Boolean(col.location) ? col.location.name : ""
                }
              />
              <ExcelColumn
                label="Material/Equipment"
                value={(col) =>
                  Boolean(col.materialEquipment)
                    ? col.materialEquipment.name
                    : ""
                }
              />
              <ExcelColumn label="Description" value="description" />
              <ExcelColumn label="Status" value="status" />
              <ExcelColumn label="Remarks" value="remarks" />
              <ExcelColumn
                label="Last Update"
                value={(col) =>
                  moment(col.lastUpdated).format("DD-MM-YYYY HH:mm")
                }
              />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton
            style={{ border: "0px" }}
            onClick={this.handleExportExcel}
            icon={<ExportIcon color="secondary" fontSize="large" />}
          ></AntButton>
        )} */}
      </>
    );

    return (
      <div
        hidden={this.state.hideInputs}
        className="card card-custom"
        style={{ padding: "2rem", minHeight: "100%" }}
      >
        <Form
          initialValues={{ remember: false }}
          onFinish={this.save}
          onFinishFailed={onFinishFailed}
          ref={this.formRef}
        >
          <Row>
            <Col xs={6}>
              {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                <Upload
                  {...propsBulk}
                  showUploadList={true}
                  fileList={this.state.bulkFileList}
                  onChange={({ fileList }) => {
                    if (fileList.length == 1) {
                      var imageUrl = "";
                      if (Boolean(fileList[0].response)) {
                        imageUrl = fileList[0].response.url;
                      }
                      this.setState({
                        bulkFileList: fileList,
                        imageUrl: imageUrl,
                      });
                    }
                  }}
                  beforeUpload={(fileList) => {
                    console.log(fileList.type);
                    const isZip =
                      fileList.type === "application/x-zip-compressed";
                    if (this.state.bulkFileList.length > 0) {
                      message.error(
                        "You have been already choose a file! For change firstly remove the uploaded file."
                      );
                      this.setState({
                        bulkFileList: [],
                        imageUrl: "",
                      });
                      return false;
                    }
                    if (!isZip) {
                      message.error("You can only upload zip file!");
                      this.setState({
                        bulkFileList: [],
                        imageUrl: "",
                      });
                      return false;
                    }
                    return true;
                  }}
                  onRemove={({ fileList }) => {
                    this.setState({
                      bulkFileList: [],
                      imageUrl: "",
                    });
                  }}
                >
                  <AntButton style={{ width: "100%" }}>
                    <UploadOutlined /> Bulk File Upload As Zip{" "}
                  </AntButton>
                </Upload>
              )}
            </Col>
            <Col xs={12}></Col>
            <Col xs={2}>
              {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                <AntButton
                  style={{ border: "0px" }}
                  onClick={this.sync}
                  icon={<SyncIcon color="primary" />}
                ></AntButton>
              )}
            </Col>
            <Col xs={1}>{excelExport}</Col>
            <Col xs={1}>
              {/* <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader> */}
            </Col>
            <Col xs={2}>
              <AntButton
                hidden={!this.state.hiddenInputs}
                style={{ border: "0px" }}
                onClick={() => this.onClickCreateNew()}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                hidden={this.state.hiddenInputs}
                style={{ border: "0px" }}
                onClick={() => this.onClickCreateNew()}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hiddenInputs}>
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="todo" defaultMessage="Date" />}
                name="date"
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="Please select date"
                      />
                    ),
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={
                  <FormattedMessage id="todo" defaultMessage="Discipline" />
                }
                name="disciplineId"
                rules={[
                  {
                    required: false,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="This field is required"
                      />
                    ),
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  placeholder="Dsicipline"
                  id="DefinitionsType"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={(e) => {
                            this.setState({
                              codeName: e.target.value,
                            });
                          }}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={this.addItem}
                        >
                          <PlusOutlined /> Add Discipline
                        </a>
                      </div>
                    </div>
                  )}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {this.state.disciplineSelectItems.map((i) => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout2}
                label={
                  <FormattedMessage id="todo" defaultMessage="Drawing Type" />
                }
                name="drawingTypeId"
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="This field is required"
                      />
                    ),
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  placeholder="Drawing Type"
                  id="drawingTypeId"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={(e) => {
                            this.setState({
                              codeName: e.target.value,
                            });
                          }}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={this.addDrawingType}
                        >
                          <PlusOutlined /> Add Drawing Type
                        </a>
                      </div>
                    </div>
                  )}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {this.state.drawingTypeSelectItems.map((i) => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={
                  <FormattedMessage id="todo" defaultMessage="Drawing No" />
                }
                name="drawingNo"
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="Please type drawing"
                      />
                    ),
                  },
                ]}
              >
                <Input placeholder="Drawing No" />
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="todo" defaultMessage="Sht No" />}
                name="shtNo"
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="Please type sht no"
                      />
                    ),
                  },
                ]}
              >
                <Input placeholder="Sht No" />
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="todo" defaultMessage="Rev. No" />}
                name="revNo"
                rules={[
                  {
                    required: false,
                    message: (
                      <FormattedMessage id="todo" defaultMessage="Rev. No" />
                    ),
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  
                  min={0}
                  placeholder="Rev. No"
                />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="todo" defaultMessage="Index" />}
                name="index"
                rules={[
                  {
                    required: false,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="Please type index"
                      />
                    ),
                  },
                ]}
              >
                <Input placeholder="Index" />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="todo" defaultMessage="Location" />}
                name="locationId"
                rules={[
                  {
                    required: false,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="This field is required"
                      />
                    ),
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  placeholder="Location"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={(e) => {
                            this.setState({
                              codeName: e.target.value,
                            });
                          }}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={this.addLocation}
                        >
                          <PlusOutlined /> Add Location
                        </a>
                      </div>
                    </div>
                  )}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {this.state.locationSelectItems.map((i) => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={
                  <FormattedMessage
                    id="todo"
                    defaultMessage="Material/Equipment"
                  />
                }
                name="materialEquipmentId"
                rules={[
                  {
                    required: false,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="This field is required"
                      />
                    ),
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  placeholder="Material/Equipment"
                  id="materialEquipmentId"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={(e) => {
                            this.setState({
                              codeName: e.target.value,
                            });
                          }}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={this.addMaterialEquipment}
                        >
                          <PlusOutlined /> Add Material/Equipment
                        </a>
                      </div>
                    </div>
                  )}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {this.state.materialEquipmentSelectItems.map((i) => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={
                  <FormattedMessage id="todo" defaultMessage="Description" />
                }
                name="description"
                rules={[
                  {
                    required: false,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="Please type description"
                      />
                    ),
                  },
                ]}
              >
                <Input placeholder="Description" />
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="todo" defaultMessage="Status" />}
                initialValue={"Active"}
                name="status"
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="This field is required"
                      />
                    ),
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  placeholder="Status"
                >
                  <Option key={"Active"} value={"Active"}>
                    {"Active"}
                  </Option>
                  <Option key={"Superseded"} value={"Superseded"}>
                    {"Superseded"}
                  </Option>
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="todo" defaultMessage="File" />}
                name="fileUrl"
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="Please upload file"
                      />
                    ),
                  },
                ]}
              >
                <Upload
                  {...props}
                  id="path"
                  showUploadList={true}
                  multiple
                  fileList={this.state.fileList}
                  onChange={({ fileList }) => {
                    var filePaths = [];
                    const stateFileList = this.state.fileList;
                    if (fileList.length >= 1) {
                      for (let index = 0; index < fileList.length; index++) {
                        if (Boolean(fileList[index].response)) {
                          filePaths[index] = fileList[index].response.url;
                          fileList[index].url = fileList[index].response.url;

                          if (
                            filePaths.length === fileList.length &&
                            fileList.length > stateFileList.length
                          )
                            showSuccess("Files uploaded successfully");
                        } else if (Boolean(fileList[index].url)) {
                          filePaths[index] = fileList[index].url;
                        }
                      }
                    }
                    this.setState({
                      fileList: fileList,
                      filePaths: filePaths,
                    });
                  }}
                  beforeUpload={(fileList) => {
                    return true;
                  }}
                  onRemove={({ fileList }) => {}}
                >
                  <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> Upload
                  </AntButton>
                </Upload>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="todo" defaultMessage="Remarks" />}
                name="remarks"
                rules={[
                  {
                    required: false,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="Please type remarks"
                      />
                    ),
                  },
                ]}
              >
                <Input placeholder="Remarks" />
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={
                  <FormattedMessage id="todo" defaultMessage="Linked Drawing" />
                }
                name="Linked Drawing"
                rules={[
                  {
                    required: false,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="Please select date"
                      />
                    ),
                  },
                ]}
              ></Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={
                  <FormattedMessage id="todo" defaultMessage="Discipline" />
                }
                rules={[
                  {
                    required: false,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="This field is required"
                      />
                    ),
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  placeholder="Dsicipline"
                  id="DefinitionsType"
                  onChange={(value) => {
                    this.setState(
                      {
                        disciplineId: value,
                      },
                      this.refreshLinkDrawingList
                    );
                  }}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {this.state.disciplineSelectItems.map((i) => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout2}
                label={
                  <FormattedMessage id="todo" defaultMessage="Drawing Type" />
                }
                rules={[
                  {
                    required: false,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="This field is required"
                      />
                    ),
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  placeholder="Drawing Type"
                  id="drawingTypeId"
                  onChange={(value) => {
                    this.setState(
                      {
                        drawingTypeId: value,
                      },
                      this.refreshLinkDrawingList
                    );
                  }}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {this.state.drawingTypeSelectItems.map((i) => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={
                  <FormattedMessage
                    id="todo"
                    defaultMessage="Linked Drawings"
                  />
                }
                rules={[
                  {
                    required: false,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="Please type drawing"
                      />
                    ),
                  },
                ]}
              >
                <Select
                  showSearch
                  disabled={this.state.disabledLinkedDrawings}
                  optionFilterProp="children"
                  mode="multiple"
                  onSearch={this.refreshLinkDrawingList}
                  value={this.state.linkedDrawingIds}
                  style={{ width: "100%" }}
                  placeholder="Linked Drawings"
                  onChange={(value) => {
                    this.setState(
                      {
                        linkedDrawingIds: value,
                      },
                      this.refreshLinkDrawingList
                    );
                  }}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {this.state.linkDrawingList.map((i) => (
                    <Option key={i.id} value={i.id}>
                      {i.drawingNo + "-" + i.shtNo + "-" + i.revNo}{" "}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }

            <div hidden={this.state.hideSave}>
              {
                <Row gutter={8}>
                  <Col span={8}></Col>

                  <Col span={8}>
                    {
                      <Button
                        id="OrganizationalLevelSaveButton"
                        type="submit"
                        style={{ width: "100%" }}
                        variant="success"
                      >
                        <FormattedMessage
                          id="GeneralButtonSave"
                          defaultMessage="Save"
                        />
                      </Button>
                    }
                  </Col>
                </Row>
              }
            </div>

            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={8}>
                  <Col span={8}></Col>

                  <Col span={4}>
                    <Button
                      id="OrganizationalLevelCancelButton"
                      style={{ width: "100%" }}
                      variant="secondary"
                      onClick={this.cancel}
                    >
                      <FormattedMessage
                        id="GeneralButtonCancel"
                        defaultMessage="Cancel"
                      />
                    </Button>
                  </Col>
                  <Col span={4}>
                    <Button
                      id="OrganizationalLevelUpdateButton"
                      style={{ width: "100%" }}
                      variant="warning"
                      type="submit"
                    >
                      <FormattedMessage
                        id="GeneralButtonUpdate"
                        defaultMessage="Update"
                      />
                    </Button>
                  </Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div
          style={{ marginTop: "2rem", marginBottom: "1rem" }}
          className="content-section implementation"
        >
          <Table
            size="small"
            bordered
            scroll={{ y: 500, x: 900 }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal
          show={this.state.modalDeleteShow}
          onHide={this.showOrHideDeleteModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Chosen record will be deleted !
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure to "Delete" all your data? By clicking "Yes" all data
              will be removed permanently.{" "}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={this.delete}>
              Yes
            </Button>

            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              No
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalSupersedeShow}
          onHide={() => {
            this.setState({
              modalSupersedeShow: false,
            });
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Chosen record will be superseded !
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Boolean(this.state.selectedRow) && (
              <p>
                Do you want to supersede Drawing no:{" "}
                {this.state.selectedRow.drawingNo}, Sht. no:{" "}
                {this.state.selectedRow.shtNo}, Rev. No:{" "}
                {this.state.selectedRow.revNo}
              </p>
            )}
            <p>
              {" "}
              Date Of Supersede :{" "}
              <DatePicker
                style={{ flex: "auto" }}
                value={this.state.dateOfSupersede}
                placeholder="Date"
                onChange={(date) => {
                  this.setState({
                    dateOfSupersede: date,
                  });
                }}
              />{" "}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.supersede}>
              Yes
            </Button>
            <Button
              variant="warning"
              onClick={() => {
                this.setState({
                  modalSupersedeShow: false,
                });
              }}
            >
              {" "}
              No
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalSupersedeAction}
          onHide={() => {
            this.setState({
              modalSupersedeAction: false,
              linkedDrawingIds: [],
            });
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Is there any drawing !
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Is there any drawing to be replaced with superseded drawing?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                this.setState({
                  modalSupersedeAction: false,
                });
                this.onClickCreateNew();
              }}
            >
              Yes
            </Button>
            <Button
              variant="warning"
              onClick={() => {
                this.setState({
                  modalSupersedeAction: false,
                  linkedDrawingIds: [],
                });
              }}
            >
              {" "}
              No
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showRevisedListModal}
          onHide={() => {
            this.setState({
              showRevisedListModal: false,
            });
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Revised Drawings
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table
              bordered
              scroll={{ x: 400, scrollToFirstRowOnChange: true }}
              columns={revisedColumns}
              dataSource={this.state.revisedFileList}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  showRevisedListModal: false,
                });
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showLinkedListModal}
          onHide={() => {
            this.setState({
              showLinkedListModal: false,
            });
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Linked Drawings
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table
              bordered
              scroll={{ x: 400, scrollToFirstRowOnChange: true }}
              columns={revisedColumns}
              dataSource={this.state.revisedFileList}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  showLinkedListModal: false,
                });
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              PDF Report Viewer
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfViewer}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("YYY", state);

  return {
    // auth: state.auth,
    logout: state.logout,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Drawing);
// export default connect(null, auth.actions)(Domain);
