import { DatePicker, Form } from "antd";
import moment from "moment";
import React from "react";
import PrepareFormItemProps from "./ElementUtils";
const DatetimePickerComponent = ({ field, layout }) => {
  return (
    <>
      <Form.Item {...layout} {...PrepareFormItemProps(field)}>
        <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm:ss" showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }} />
      </Form.Item>
    </>
  );
};

export default DatetimePickerComponent;
