import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { ReplyOutlined, ScoreOutlined, MessageOutlined } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { FilterOutlined, ExclamationCircleOutlined, CloseCircleOutlined, StockOutlined, LinkOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import SendIcon from "@material-ui/icons/Send";
import CreateIcon from "@material-ui/icons/AddCircle";
import {
  Form, Table, Badge, Select, InputNumber, DatePicker, Cascader, Image, Drawer,
  Col, Row, Radio, Typography, Tag, Space, Tooltip, Button as AntButton
} from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import { ProductLogReceive } from "./ProductLogReceive";
import ProductLogRelease from "./ProductLogRelease";
import "../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";
import store from "../../../redux/store";
import SettingsIcon from "@material-ui/icons/Settings";
import { PageSettings } from "../administrator/pageSettings/PageSettings";
import DraggableModal from "../../Components/DraggableModal";
import EldenCompoundsSettingsProductTreeSelectable from "../elden/equipment/EldenCompoundsSettingsProductTreeSelectable";
import EldenCompoundsSettingsProductTreeSelectableCompany from "../elden/equipment/EldenCompoundsSettingsProductTreeSelectableCompany";
import EldenCompoundsSettingsProductTree from "../elden/equipment/EldenCompoundsSettingsProductTree";

const { Option } = Select;
const { Text } = Typography;

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class ProductLog extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "productLog",
      controllerNameMain: "productLogMain",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      currentItem: null,
      id: null,
      tableList: [],
      tableReceivingList: [],
      tableRelease: [],
      tableMovementList: [],
      filePath: "",
      filePaths: [],
      fileList: [],
      currentMovementType: null,
      selectedRowKeysRelease: [],
      selectedRowKeysReceiving: [],
      nodes: [],
      spinLoadingPO: false,
      stockReserveMovement: false,
      searchCriteriaModels: [],
      user: store.getState().auth.user,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      paginationReceive: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      paginationRelease: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      paginationMovement: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      columnsStockQuantityDimension: [],
      tableStockQuantityDimensiontList: [],
    };
  }

  formRef = React.createRef();
  formRefReceive = React.createRef();
  formRefRelease = React.createRef();
  formRefMinStockReq = React.createRef();
  formRefReserve = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    fillFilterOperations();
  };

  fillComboboxes = async () => {
    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/stockTree");
    if (responseTree.data.length !== 0) {
      generateList(responseTree.data);
      this.setState({
        nodes: responseTree.data
      });
    }

    var responseStockCardId = await handleRequest("GET", "/api/" + this.state.controllerName + "/stockCardId");
    if (responseStockCardId.type === "ERROR") {
      error(responseStockCardId);
    } else {
      this.setState({
        stockCardIdSelectItems: Boolean(responseStockCardId.data) ? responseStockCardId.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      selectedRowKeysRelease: [],
      selectedRowKeysReceiving: [],
      stockId: null,
      selectedRow: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      paginationReceive: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      paginationRelease: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      paginationMovement: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  setSelectedFiles = field => {
    let fileList = [];
    if (Boolean(field) && field.length > 0) {
      let files = field.split(",");
      for (let i = 0; i < files.length; i++) {
        fileList.push({
          uid: i,
          name: files[i],
          url: files[i],
          thumbUrl: files[i]
        });
      }
      return fileList;
    }
    return [];
  };

  edit = async row => {
    if (row.movementType === "RECEIVE") {
      var selectedPO = null;
      if (Boolean(row.purchaseOrderId) && row.purchaseOrderId > 0) {
        var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/purchaseOrder/" + row.purchaseOrderId);
        if (response.type === "ERROR") {
          error(response);
        } else {
          selectedPO = response.data;
        }
      }
      this.setState({
        id: row.id,
        showStockReceivingModal: true,
        stockId: row.stockId,
        receivingType: row.receivingType,
        selectedPO: selectedPO,
        selectedRow: row
      });
    } else if (row.movementType === "RELEASE") {
      var selectedPrjTakeOff = null;
      if (Boolean(row.projectTakeOffListId) && row.projectTakeOffListId > 0) {
        var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/projectTakeOffList/" + row.projectTakeOffListId);
        if (response.type === "ERROR") {
          error(response);
        } else {
          selectedPrjTakeOff = response.data;
        }
      }
      this.setState({
        id: row.id,
        showStockReleaseModal: true,
        stockId: row.stockId,
        releaseType: row.releaseType,
        selectedPrjTakeOff: selectedPrjTakeOff,
        selectedRow: row
      });
    } else {
      showError("Type not found");
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false,
        showMovementModal: false,
        stockId: null,
        selectedRowKeysRelease: [],
        selectedRowKeysReceiving: [],
        selectedRow: null
      });
    }
  };

  showProductMovement = row => {
    this.setState(
      {
        showMovementModal: true,
        productLogMainId: row.productLogMainId,
        stockCardId: row.stockCardId,
        stockReserveMovement: false
      },
      () => {
        const { paginationMovement } = this.state;
        this.restartTableMovement({ paginationMovement });
      }
    );
  };

  showStockQuantityDimension = async (row) => {
    if (Boolean(row.id) && row.id > 0) {
      let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/stockQuantityDimension/" + row.id);
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(response.data) && response.data.length) {
          this.setState({
            columnsStockQuantityDimension: [
              {
                title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
                render: (value, row, index) => {
                  return index + 1;
                },
              },
            ],
          }, () => {
            const { columnsStockQuantityDimension } = this.state;
            let tableStockQuantityDimensiontList = [];
            let index = 1;

            response.data.forEach(row => {
              if (Boolean(row.list) && row.list.length > 0) {
                row.list.forEach(element => {
                  let obj = {
                    stockCardId: row.stockCardId,
                    quantity: row.quantity,
                    title: element.description,
                    dataIndex: "key" + (index),
                    ["key" + (index)]: element.value,
                  };

                  let addColumn = columnsStockQuantityDimension.find(p => p.title === obj.title);
                  if (!Boolean(addColumn)) {
                    columnsStockQuantityDimension.push(obj);
                  }

                  let t = tableStockQuantityDimensiontList.find(p => p.stockCardId === obj.stockCardId);
                  if (Boolean(t)) {
                    t["key" + (index)] = element.value;
                  } else {
                    tableStockQuantityDimensiontList.push(obj);
                  }
                  index++;
                });

                let objQuantity = {
                  stockCardId: row.stockCardId,
                  quantity: row.quantity,
                  title: "Quantity",
                  dataIndex: "quantity",
                  ["quantity"]: row.quantity,
                };
                columnsStockQuantityDimension.push(objQuantity);
              }
            });
            this.setState({
              showStockQuantityDimensionModal: true,
              tableStockQuantityDimensiontList: tableStockQuantityDimensiontList,
              columnsStockQuantityDimension: columnsStockQuantityDimension,
            });
          });
        }
      }
    } else {
      showError("Product Log Main not found.");
    }
  };

  showMinStockRequirement = row => {
    this.setState(
      {
        showMinStockRequirementModal: true,
        productLogMainId: row.productLogMainId,
        formMinQuantityUnitName: row.unitName
      },
      () => {
        if (Boolean(this.formRefMinStockReq) && Boolean(this.formRefMinStockReq.current)) {
          this.formRefMinStockReq.current.setFieldsValue({
            ...row
          });
        }
      }
    );
  };

  showProperties = row => {

  };

  showManufacturerInfo = row => {

  };

  showReserve = row => {
    if (Boolean(this.state.stockId) && this.state.stockId > 0) {
      var availableQuantity = 0;
      var inStock = 0;
      var reservedQuantity = 0;
      var toBeReleasedQuantity = 0;
      if (Boolean(row.quantity) && row.quantity > 0) {
        inStock = row.quantity;
      }
      if (Boolean(row.reservedQuantity) && row.reservedQuantity > 0) {
        reservedQuantity = row.reservedQuantity;
      }
      if (Boolean(row.toBeReleasedQuantity) && row.toBeReleasedQuantity > 0) {
        toBeReleasedQuantity = row.toBeReleasedQuantity;
      }
      availableQuantity = inStock - (reservedQuantity + toBeReleasedQuantity);

      this.setState(
        {
          showReserveModal: true,
          productLogMainId: row.productLogMainId,
          availableQuantity: availableQuantity > 0 ? availableQuantity : 0
        },
        () => {
          if (Boolean(this.formRefReserve) && Boolean(this.formRefReserve.current)) {
            this.formRefReserve.current.setFieldsValue({
              availableQuantity: availableQuantity > 0 ? availableQuantity : 0,
            });
          }
        }
      );
    } else {
      showError("Please select stock");
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  handleTableChangeReceiving = (paginationReceive, filters, sorter) => {
    this.restartTableReceiving({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      paginationReceive,
      filters
    });
  };

  handleTableChangeRelease = (paginationRelease, filters, sorter) => {
    this.restartTableReleaseProjectItems({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      paginationRelease,
      filters
    });
  };

  handleTableChangeMovement = (paginationMovement, filters, sorter) => {
    this.setState({
      paginationMovement,
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (Boolean(response.data) && response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.key = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  getSelectedFiles = field => {
    if (Boolean(field) && Boolean(field.fileList) && field.fileList.length > 0) {
      let files = "";
      files = field.fileList[0].response.url;
      for (let i = 1; i < field.fileList.length; i++) {
        if (Boolean(field.fileList[i].response)) {
          files += "," + field.fileList[i].response.url;
        }
      }
      return files;
    }
    return field;
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };
    const { filePaths } = this.state;

    newItem.materialPhotoPaths = Boolean(filePaths) ? filePaths.toString() : "";

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      stockId: Boolean(this.state.stockId) ? this.state.stockId : null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  createWhereReceiving(params) {
    if (Boolean(params._pagination)) params.paginationReceive = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.paginationReceive)) {
      if (Boolean(params.paginationReceive.lastUpdateDate)) {
        lastUpdateDateStart = params.paginationReceive.lastUpdateDate[0];
        lastUpdateDateEnd = params.paginationReceive.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.paginationReceive,
      stockId: this.state.stockId,
      id: Boolean(this.state.poNo) ? this.state.poNo : null,
      vendorId: Boolean(this.state.vendorId) ? this.state.vendorId : null,
      projectTakeOffQuantityId: Boolean(this.state.projectTakeOffQuantityId) ? this.state.projectTakeOffQuantityId : null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.paginationReceive.current - 1,
      maxResults: params.paginationReceive.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  createWhereRelease(params) {
    if (Boolean(params._pagination)) params.paginationRelease = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.paginationRelease)) {
      if (Boolean(params.paginationRelease.lastUpdateDate)) {
        lastUpdateDateStart = params.paginationRelease.lastUpdateDate[0];
        lastUpdateDateEnd = params.paginationRelease.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.paginationRelease,
      id: Boolean(this.state.projectTakeOffQuantityId) ? this.state.projectTakeOffQuantityId : null,
      stockId: this.state.stockId,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.paginationRelease.current - 1,
      maxResults: params.paginationRelease.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  createWhereMovement(params) {
    if (Boolean(params._pagination)) params.paginationMovement = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.paginationMovement)) {
      if (Boolean(params.paginationMovement.lastUpdateDate)) {
        lastUpdateDateStart = params.paginationMovement.lastUpdateDate[0];
        lastUpdateDateEnd = params.paginationMovement.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.paginationMovement,
      productLogMainId: Boolean(this.state.productLogMainId) ? this.state.productLogMainId : null,
      stockId: Boolean(this.state.stockId) ? this.state.stockId : null,
      stockCardId: Boolean(this.state.stockCardId) ? this.state.stockCardId : null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.paginationMovement.current - 1,
      maxResults: params.paginationMovement.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  receiveModal = async () => {
    if (Boolean(this.state.stockId)) {
      this.fillReceivingComboboxes();
      const { paginationReceive } = this.state;
      this.restartTableReceiving({ paginationReceive });

      if (Boolean(this.formRefReceive) && Boolean(this.formRefReceive.current)) {
        this.formRefReceive.current.resetFields();
      }
      this.setState({
        currentMovementType: "RECEIVING",
        showReceivingModal: true,
        selectedRowKeysReceiving: [],
        receivingType: null
      });
    } else {
      showError("Please select stock");
    }
  };

  fillReceivingComboboxes = async () => {
    if (Boolean(this.state.stockId) && this.state.stockId > 0) {
      var newObj = {
        stockId: this.state.stockId
      };
      var responseItem = await handleRequest("POST", "/api/" + this.state.controllerName + "/items", newObj);
      if (responseItem.type === "ERROR") {
        error(responseItem);
      } else {
        this.setState({
          itemSelectItems: Boolean(responseItem.data) ? responseItem.data : []
        });
      }
    } else {
      this.setState({
        itemSelectItems: []
      });
    }

    var responseVendor = await handleRequest("GET", "/api/" + this.state.controllerName + "/vendors");
    if (responseVendor.type === "ERROR") {
      error(responseVendor);
    } else {
      this.setState({
        vendorSelectItems: Boolean(responseVendor.data) ? responseVendor.data : []
      });
    }

    var responsePoNo = await handleRequest("GET", "/api/" + this.state.controllerName + "/poNos");
    if (responsePoNo.type === "ERROR") {
      error(responsePoNo);
    } else {
      this.setState({
        poNoSelectItems: Boolean(responsePoNo.data) ? responsePoNo.data : []
      });
    }
  };

  releaseModal = async () => {
    if (Boolean(this.state.stockId)) {
      if (Boolean(this.formRefRelease) && Boolean(this.formRefRelease.current)) {
        this.formRefRelease.current.resetFields();
      }
      this.setState({
        currentMovementType: "RELEASE",
        showReleaseModal: true,
        selectedRowKeysRelease: [],
        releaseType: null,
        onPurposeType: null,
        onPurposeTypeName: "",
        tableRelease: [],
        paginationRelease: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
          total: 0
        },
      });
    } else {
      showError("Please select stock");
    }
  };

  action = record => {
    let properties = {
      name: "Properties",
      icon: <ReplyOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.showProperties.bind(this)
    };

    let manufacturerInfo = {
      name: "Manufacturer info",
      icon: <ScoreOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.showManufacturerInfo.bind(this)
    };

    let reserve = {
      name: "Reserve",
      icon: <StockOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.showReserve.bind(this)
    };

    let actionList = [];
    if (record.quantity > 0 && this.state.stockReserveMovement) {
      actionList.push(reserve);
    }

    actionList.push(properties);
    actionList.push(manufacturerInfo);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  actionMovement = record => {
    let movementInfo = {
      name: "Stock Movement",
      icon: <ScoreOutlined fontSize="small" color="primary" />,
      actionClick: this.showProductMovement.bind(this)
    };

    let minStockRequirement = {
      name: "Min Stock Requirement",
      icon: <EditIcon fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.showMinStockRequirement.bind(this)
    };

    let reserveMovement = {
      name: "Reserve",
      icon: <StockOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.showReserve.bind(this)
    };

    let actionList = [];
    actionList.push(movementInfo);
    actionList.push(minStockRequirement);

    if (record.quantity > 0) {
      actionList.push(reserveMovement);
    }

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  getImageGalleryFromUrls = (paths, galleryName) => {
    if (Boolean(paths) && paths.length > 0) {
      let pathList = paths.split(",");

      return (
        <>
          {Boolean(paths) && paths.length > 0 ? (
            <Image
              width={80}
              preview={{
                visible: false
              }}
              src={pathList[0].trim()}
              onClick={() => this.setState({ [galleryName]: true })}
            />
          ) : null}
          {Boolean(pathList) && pathList.length > 0 ? (
            <div style={{ display: "none" }}>
              <Image.PreviewGroup
                preview={{
                  visible: this.state[galleryName],
                  onVisibleChange: vis => this.setState({ [galleryName]: vis })
                }}
              >
                {pathList.map((field, i) => (
                  <Image key={i} src={field} />
                ))}
              </Image.PreviewGroup>
            </div>
          ) : null}
        </>
      );
    }
  };

  onSelectChangeReceiving = selectedRowKeysReceiving => {
    if (Boolean(selectedRowKeysReceiving) && selectedRowKeysReceiving.length > 0) {
      var data = this.state.tableReceivingList.find(p => p.id === selectedRowKeysReceiving[0]);
      if (Boolean(data)) {
        if (Boolean(this.state.receivingType)) {
          this.setState({
            selectedRowKeysReceiving,
            showStockReceivingModal: true,
            selectedPO: data,
            selectedRow: null
          });
        } else {
          showError("Please select type");
        }
      } else {
        showError("Not found.");
      }
    }
  };

  onSelectChangeRelease = selectedRowKeysRelease => {
    if (Boolean(selectedRowKeysRelease) && selectedRowKeysRelease.length > 0) {
      var data = this.state.tableRelease.find(p => p.id === selectedRowKeysRelease[0]);
      if (Boolean(data)) {
        if (Boolean(this.state.releaseType)) {

          let productLogReservedId = null;
          let productLogReleaseId = null;
          if (this.state.onPurposeType === "ReservedItems") {
            productLogReservedId = data.id;
          } else if (this.state.onPurposeType === "ToBeReleasedItems") {
            productLogReleaseId = data.id;
          }

          this.setState({
            selectedRowKeysRelease,
            showStockReleaseModal: true,
            selectedPrjTakeOff: data,
            selectedRow: null,
            productLogReservedId: productLogReservedId,
            productLogReleaseId: productLogReleaseId,
          });
        } else {
          showError("Please select type");
        }
      }
    }
  };

  poSearch = () => {
    const { paginationReceive } = this.state;
    this.restartTableReceiving({ paginationReceive });
  };

  prjTakeOffSearch = () => {
    if (Boolean(this.state.onPurposeType) && Boolean(this.state.releaseType)) {
      if (this.state.releaseType === "On_Purpose" && this.state.onPurposeType === "ProjectItems") {
        const { paginationRelease } = this.state;
        this.restartTableReleaseProjectItems({ paginationRelease });
      } else if (this.state.releaseType === "On_Purpose" && this.state.onPurposeType === "ReservedItems") {
        const { paginationRelease } = this.state;
        this.restartTableReleaseReservedItems({ paginationRelease });
      } else if (this.state.releaseType === "On_Purpose" && this.state.onPurposeType === "ToBeReleasedItems") {
        const { paginationRelease } = this.state;
        this.restartTableReleaseToBeReleasedItems({ paginationRelease });
      }
      else {
        this.setState({
          tableRelease: [],
        })
      }
    } else {
      this.setState({
        tableRelease: [],
      })
    }
  };

  restartTableReceiving = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhereReceiving(params);
    let url = "/api/" + this.state.controllerName + "/poProductLogs";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableReceivingList: [],
        paginationReceive: {
          ...params.paginationReceive,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        let list = response.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableReceivingList: list,
          paginationReceive: {
            ...params.paginationReceive,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableReceivingList: [],
          paginationReceive: {
            ...params.paginationReceive,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  restartTableReleaseProjectItems = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhereRelease(params);
    let url = "/api/" + this.state.controllerName + "/pagingPrjTakeOff";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableRelease: [],
        paginationRelease: {
          ...params.paginationRelease,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        let list = response.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableRelease: list,
          paginationRelease: {
            ...params.paginationRelease,
            total: response.data.length
          },
          totalRecords: response.data.length,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableRelease: [],
          paginationRelease: {
            ...params.paginationRelease,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  restartTableReleaseReservedItems = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhereRelease(params);
    let url = "/api/" + this.state.controllerName + "/reservedItems";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableRelease: [],
        paginationRelease: {
          ...params.paginationRelease,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (Boolean(response.data) && Boolean(response.data.data) && response.data.data.length > 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableRelease: list,
          paginationRelease: {
            ...params.paginationRelease,
            total: response.data.length
          },
          totalRecords: response.data.length,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableRelease: [],
          paginationRelease: {
            ...params.paginationRelease,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  restartTableReleaseToBeReleasedItems = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhereRelease(params);
    let url = "/api/" + this.state.controllerName + "/toBeReleasedItems";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableRelease: [],
        paginationRelease: {
          ...params.paginationRelease,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (Boolean(response.data) && Boolean(response.data.data) && response.data.data.length > 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableRelease: list,
          paginationRelease: {
            ...params.paginationRelease,
            total: response.data.length
          },
          totalRecords: response.data.length,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableRelease: [],
          paginationRelease: {
            ...params.paginationRelease,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  restartTableMovement = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhereMovement(params);
    let url = "/api/" + this.state.controllerName + "/pagingMovement";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableMovementList: [],
        paginationMovement: {
          ...params.paginationMovement,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (Boolean(response.data) && response.data.length !== 0) {
        let list = response.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableMovementList: list,
          paginationMovement: {
            ...params.paginationMovement,
            total: response.data.length
          },
          totalRecords: response.data.length,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableMovementList: [],
          paginationMovement: {
            ...params.paginationMovement,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  handleStockIdChange = async value => {
    let stockId = Boolean(value) && value.length > 0 ? value[value.length - 1] : null;
    this.setState(
      {
        stockId: stockId
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  saveStockReceiving = () => {
    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.resetFields();
    }
    this.setState(
      {
        showStockReceivingModal: false,
        // showReceivingModal: false,
        // showMovementModal: false,
        stockId: null,
        id: null,
        selectedRowKeysReceiving: [],
        selectedRow: null
      },
      () => {
        const { paginationReceive } = this.state;
        this.restartTableReceiving({ paginationReceive });
      }
    );
  };

  saveStockRelease = () => {
    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.resetFields();
    }
    this.setState(
      {
        showStockReleaseModal: false,
        showReleaseModal: false,
        showMovementModal: false,
        stockId: null,
        id: null,
        selectedRowKeysRelease: [],
        selectedRow: null
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  handlePriceListQuantityOurExpectedPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableReceivingList];
      const item = newData.find(item => row.id === item.id);
      if (Boolean(value) && Boolean(item)) {
        if (item.quantity === value.quantity) return;
        item.quantity = value.quantity;
        this.setState({
          tableReceivingList: newData
        });
      }
    }
  };

  saveReserveSubmit = async values => {
    if (Boolean(this.state.stockId) && this.state.stockId > 0) {
      if (Boolean(this.state.productLogMainId) && this.state.productLogMainId > 0) {
        const newItem = {
          stockId: this.state.stockId,
          productLogMainId: this.state.productLogMainId,
          ...values
        };

        let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/addReserveQuantity", newItem);

        if (response.type === "ERROR") {
          error(response);
        } else {
          this.setState({
            showReserveModal: false
          });
          const { pagination } = this.state;
          this.restartTable({ pagination });
          showSuccess();
        }
      } else {
        showError("Product Log Main Not found");
      }
    } else {
      showError("Please select stock");
    }
  };

  saveMinQuantitySubmit = async values => {
    const newItem = {
      id: this.state.productLogMainId,
      ...values
    };

    if (Boolean(this.state.productLogMainId) && this.state.productLogMainId > 0) {
      var response = await handleRequest("PUT", "/api/" + this.state.controllerNameMain + "/updateMinReqQuantity/" + newItem.id, newItem);

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          showMinStockRequirementModal: false
        });
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showError("Not found");
    }
  };

  actionReceiving = record => {
    let selectAction = {
      name: <FormattedMessage id="GeneralActionButtonSelect" defaultMessage="Select" />,
      icon: <MessageOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.receivingSelect.bind(this)
    };
    let sendAction = {
      name: <FormattedMessage id="ProductLog.RequestForQualityControl" defaultMessage="Request for Q.C." />,
      icon: <SendIcon fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.requestForQC.bind(this)
    };

    let actionList = [];
    if (!(Boolean(record.inspectionLogItemResult) && record.inspectionLogItemResult === "Accept")) {
      if (Boolean(record.receiveStatus)) {
        actionList.push(selectAction);
        if (record.receiveStatus === "Accept") {
          actionList.push(sendAction);
        }
      } else {
        actionList.push(selectAction);
      }
    }
    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  receivingSelect = row => {
    if (Boolean(this.state.receivingType)) {
      this.setState({
        showStockReceivingModal: true,
        selectedPO: row,
        selectedRow: null
      });
    } else {
      showError("Please select type");
    }
  };

  requestForQC = row => {
    this.setState({
      modalRFQCShow: true,
      selectedRequestForQC: row
    });
  };

  sendQualityControl = async () => {
    if (Boolean(this.state.selectedRequestForQC) && Boolean(this.state.selectedRequestForQC.id) && this.state.selectedRequestForQC.id > 0) {
      var newObj = {
        id: this.state.selectedRequestForQC.id
      };
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/requestForQC", newObj);
      if (response.type === "ERROR") {
        error(response);
      } else {
        const { paginationReceive } = this.state;
        this.restartTableReceiving({ paginationReceive });
        this.setState({
          modalRFQCShow: false
        });
      }
    } else {
      showError();
    }
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  okProjectTreeTemplate = async () => {
    const { targetKeys } = this.state;
    if (targetKeys && targetKeys.length > 0) {
      targetKeys.sort((a, b) => {
        return a - b;
      });
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/sendAdminEldenCompoundsSettingsProductTree/" + targetKeys);
      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess();
        this.setState({ showTransferModal: false });
      }
    } else {
      showError("Not found.");
    }
  };

  onTargetKeysCheck = async targetKeys => {
    console.log("targetKeys", targetKeys);
    this.setState({
      targetKeys: targetKeys
    });
  };

  okProjectTreeTemplateCompany = async () => {
    const { targetKeys, stockId } = this.state;
    if (Boolean(stockId) && stockId > 0) {
      if (targetKeys && targetKeys.length > 0) {
        targetKeys.sort((a, b) => {
          return a - b;
        });
        var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/sendProductLog/" + targetKeys + "/" + stockId);
        if (response.type === "ERROR") {
          error(response);
        } else {
          showSuccess();
          this.setState({
            showTransferCompanyModal: false,
            showCreateANewProductModal: false,
          });
          const { pagination } = this.state;
          this.restartTable({ pagination });
        }
      } else {
        showError("Not found.");
      }
    } else {
      showError("Please select stock.");
    }
  };

  onTargetKeysCheckCompany = async targetKeys => {
    console.log("targetKeys", targetKeys);
    this.setState({
      targetKeys: targetKeys
    });
  };

  onTargetKeysCheckCompanyAddProduct = async targetKeys => {
    console.log("targetKeys", targetKeys);
    this.setState({
      targetKeys: targetKeys
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const { selectedRowKeysReceiving } = this.state;
    const { selectedRowKeysRelease } = this.state;
    const rowSelectionRelease = {
      selectedRowKeys: selectedRowKeysRelease,
      onChange: this.onSelectChangeRelease
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        width: 100,
        title: <FormattedMessage id="General.TitleActions" defaultMessage="Actions" />,
        render: this.actionMovement
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ProductLog.itemName" defaultMessage="Item Description" />,
        key: "stockCardId",
        width: 150,
        ellipsis: true,
        render: record => {
          return record.itemName;
        },
        ...getColumnFilter({
          inputName: "stockCardId", inputType: "SELECT",
          selectItems: Boolean(this.state["stockCardIdSelectItems"]) ? this.state["stockCardIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="ProductLog.imageUrl" defaultMessage="Photo" />,
        key: "imageUrl",
        width: 100,
        render: record => {
          return Boolean(record.imageUrl) && <Image src={record.imageUrl} />;
        }
      },
      {
        title: <FormattedMessage id="ProductLog.dimensionName" defaultMessage="Dimension" />,
        key: "id",
        ellipsis: true,
        width: 100,
        render: (value, row, index) => {
          return <Tooltip title={row.dimensionName}>
            {row.dimensionName}
          </Tooltip>
        }
      },
      {
        title: <FormattedMessage id="ProductLog.quantity" defaultMessage="In Stock" />,
        key: "quantity",
        width: 100,
        render: record => {
          if (record.movementType === "RECEIVE") {
            return (
              <Text style={{ cursor: "pointer" }} onClick={e => this.showProductMovement(record)} type="success">
                {Math.abs(record.quantity)}
              </Text>
            );
          } else {
            return (
              <Text style={{ cursor: "pointer" }} onClick={e => this.showProductMovement(record)} type="danger">
                {Math.abs(record.quantity)}
              </Text>
            );
          }
        }
      },

      {
        title: <FormattedMessage id="ProductLog.ReservedQuantity" defaultMessage="Reserved Quantity" />,
        key: "reservedQuantity",
        width: 100,
        render: record => {
          return record.reservedQuantity;
        }
      },

      {
        title: <FormattedMessage id="ProductLog.ToBeReleasedQuantity" defaultMessage="To Be Released Quantity" />,
        key: "toBeReleasedQuantity",
        width: 100,
        render: record => {
          return record.toBeReleasedQuantity;
        }
      },

      {
        title: <FormattedMessage id="ProductLog.availableQuantity" defaultMessage="Available Quantity" />,
        key: "availableQuantity",
        width: 100,
        render: record => {
          if (record.movementType === "RECEIVE") {
            let avaliableQuantity = record.quantity - (record.reservedQuantity + record.toBeReleasedQuantity);
            return avaliableQuantity > 0 ? avaliableQuantity : 0;
          }
        }
      },

      {
        title: <FormattedMessage id="ProductLog.quantity" defaultMessage="Min. Req. Stock" />,
        key: "minReqQuantity",
        width: 100,
        render: record => {
          return record.minReqQuantity;
        }
      },
      {
        title: <FormattedMessage id="ProductLog.purposeOfUse" defaultMessage="Purpose of Use" />,
        key: "purposeOfUse",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="ProductLog.dateOfPlannedUsage" defaultMessage="Date of Planned Usage" />,
        key: "dateOfPlannedUsage",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="ProductLog.source" defaultMessage="Source" />,
        key: "source",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="ProductLog.status" defaultMessage="Status" />,
        key: "status",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="General.LastUpdate" defaultMessage="Last Update" />,
        key: "lastUpdated",
        width: 100,
        ellipsis: true,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
      },
      {
        title: <FormattedMessage id="ProductLog.remarks" defaultMessage="Remarks" />,
        key: "remarks",
        width: 100,
        render: record => {
          return "";
        }
      }
    ];

    const columnsMovement = [
      {
        key: "key",
        width: 100,
        title: <FormattedMessage id="General.TitleActions" defaultMessage="Actions" />,
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.paginationMovement.pageSize * (this.state.paginationMovement.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ProductLog.stockName" defaultMessage="Stock" />,
        key: "stockName",
        width: 100,
        render: record => {
          return record.stockName;
        },
      },
      {
        title: <FormattedMessage id="ProductLog.itemName" defaultMessage="Item Description" />,
        key: "itemName",
        width: 100,
        ellipsis: true,
        render: record => {
          return record.itemName;
        },
      },
      {
        title: <FormattedMessage id="ProductLog.imageUrl" defaultMessage="Photo" />,
        key: "imageUrl",
        width: 100,
        render: record => {
          return Boolean(record.imageUrl) && <Image src={record.imageUrl} />;
        }
      },
      {
        title: <FormattedMessage id="ProductLog.dimensionName" defaultMessage="Dimension" />,
        key: "id",
        ellipsis: true,
        width: 100,
        render: (value, row, index) => {
          return <Tooltip title={row.dimensionName}>
            {row.dimensionName}
          </Tooltip>
        },
      },
      {
        title: <FormattedMessage id="ProductLog.reserve" defaultMessage="Reserve" />,
        key: "reserve",
        width: 100,
        render: record => {
          return <Text type="danger">{Math.abs(record.reservedQuantity)}</Text>;
        }
      },
      {
        title: <FormattedMessage id="ProductLog.release" defaultMessage="Release" />,
        key: "release",
        width: 100,
        render: record => {
          return <Text type="danger">{Math.abs(record.releaseQuantity)}</Text>;
        }
      },
      {
        title: <FormattedMessage id="ProductLog.receive" defaultMessage="Receive" />,
        key: "receive",
        width: 100,
        render: record => {
          return <Text type="success">{Math.abs(record.receiveQuantity)}</Text>;
        }
      },
      {
        title: <FormattedMessage id="ProductLog.quantity" defaultMessage="In Stock" />,
        key: "quantity",
        width: 100,
        render: (value, record, index) => {
          if (index === 0 && record.quantity > 0) {
            return (
              <Text style={{ cursor: "pointer" }} onClick={e => this.showStockQuantityDimension(record)} type="success">
                {Math.abs(record.quantity)}
              </Text>
            );
          } else {
            if (record.quantity > 0) {
              return <Text type="success">{Math.abs(record.quantity)}</Text>;
            } else {
              return <Text type="danger">{Math.abs(record.quantity)}</Text>;
            }
          }
        }
      },
      {
        title: <FormattedMessage id="ProductLog.manufacturer" defaultMessage="Manufacturer" />,
        key: "manufacturer",
        width: 100,
        render: record => {
          return record.manufacturer;
        },
      },
      {
        title: <FormattedMessage id="ProductLog.Date" defaultMessage="Date" />,
        key: "dateCreated",
        width: 100,
        ellipsis: true,
        render: record => {
          return moment(record.dateCreated).format("DD-MM-YYYY HH:mm");
        },
      }
    ];
    const columnsReceiving = [
      {
        key: "key",
        width: 100,
        title: <FormattedMessage id="General.TitleActions" defaultMessage="Actions" />,
        render: this.actionReceiving
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.paginationReceive.pageSize * (this.state.paginationReceive.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ProductLog.PO" defaultMessage="PO#" />,
        key: "purchaseOrderNo",
        width: 100,
        render: record => {
          return record.purchaseOrderNo;
        }
      },
      {
        title: <FormattedMessage id="ProductLog.PODate" defaultMessage="P.O. Date" />,
        key: "sentToVendorsDate",
        width: 100,
        render: record => {
          return Boolean(record.sentToVendorsDate) ? moment(record.sentToVendorsDate).format("DD/MM/YYYY HH:mm:ss") : "";
        }
      },
      {
        title: <FormattedMessage id="ProductLog.Supplier" defaultMessage="Supplier" />,
        key: "itemName",
        width: 100,
        render: record => {
          return record.vendorName;
        }
      },
      {
        title: <FormattedMessage id="ProductLog.ItemCode" defaultMessage="Item Code" />,
        key: "code",
        width: 100,
        render: record => {
          return record.code;
        }
      },
      {
        title: <FormattedMessage id="ProductLog.ItemDescription" defaultMessage="Item Description" />,
        key: "description",
        width: 100,
        ellipsis: true,
        render: record => {
          return record.description;
        },
      },
      {
        title: <FormattedMessage id="ProductLog.Quantity" defaultMessage="Quantity" />,
        key: "quantity",
        width: 100,
        render: record => {
          return record.quantity;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "quantity",
          title: <FormattedMessage id="ProductLog.quantity" defaultMessage="Quantity" />,
          handleSave: this.handlePriceListQuantityOurExpectedPrice
        })
      },
      {
        title: <FormattedMessage id="ProductLog.Unit" defaultMessage="Unit" />,
        key: "unit",
        width: 100,
        render: record => {
          return record.unit;
        }
      },
      {
        title: <FormattedMessage id="ProductLog.DeliveryPlace" defaultMessage="Delivery Place" />,
        key: "placeAddress",
        width: 100,
        render: record => {
          return record.placeAddress;
        }
      },
      {
        title: <FormattedMessage id="ProductLog.Store" defaultMessage="Store" />,
        key: "store",
        width: 100,
        render: record => {
          return record.store;
        }
      },
      {
        title: <FormattedMessage id="ProductLog.PlannedDeliveryDate" defaultMessage="Planned Delivery Date" />,
        key: "planedDeliveryDate",
        width: 100,
        render: record => {
          return Boolean(record.planedDeliveryDate) ? moment(record.planedDeliveryDate).format("DD-MM-YYYY") : "";
        }
      },
      {
        title: <FormattedMessage id="ProductLog.ReceiveStatus" defaultMessage="Receive Status" />,
        key: "receiveStatus",
        width: 100,
        render: record => {
          return (
            Boolean(record.receiveStatus) && (
              <Tag color={record.receiveStatus === "Accept" ? "green" : record.receiveStatus === "OnHold" ? "blue" : "red"}>
                <FormattedMessage id="ProductLog.ReceiveStatusValue" defaultMessage={record.receiveStatus} />
              </Tag>
            )
          );
        }
      }
    ];

    const columnsRelease = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.paginationRelease.pageSize * (this.state.paginationRelease.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ProductLog.Store" defaultMessage="Store" />,
        key: "stockId",
        width: 100,
        render: record => {
          return record.stockName;
        }
      },
      {
        title: <FormattedMessage id="ProductLog.Project" defaultMessage="Project" />,
        key: "id",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="ProductLog.PurphoseOfUse" defaultMessage="Purphose of Use" />,
        key: "id",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="ProductLog.ItemDescription" defaultMessage="Item Description" />,
        key: "itemName",
        width: 100,
        ellipsis: true,
        render: record => {
          return record.itemName;
        },
      },
      {
        title: <FormattedMessage id="ProductLog.Quantity" defaultMessage="Quantity" />,
        key: "quantity",
        width: 100,
        render: record => {
          return record.quantity;
        }
      },
      {
        title: <FormattedMessage id="ProductLog.ReservedQuantity" defaultMessage="Reserved Quantity" />,
        key: "reservedQuantity",
        width: 100,
        render: record => {
          return record.reservedQuantity;
        }
      },
      {
        title: <FormattedMessage id="ProductLog.ToBeReleasedQuantity" defaultMessage="To Be Released Quantity" />,
        key: "toBeReleasedQuantity",
        width: 100,
        render: record => {
          return record.toBeReleasedQuantity;
        }
      },
      {
        title: <FormattedMessage id="ProductLog.ToWhom" defaultMessage="To Whom" />,
        key: "id",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="ProductLog.PlannedReleaseDate" defaultMessage="Planned Release Date" />,
        key: "plannedDeliveryDate",
        width: 100,
        render: record => {
          return Boolean(record.plannedDeliveryDate) ? moment(record.plannedDeliveryDate).format("DD-MM-YYYY HH:mm") : "";
        }
      },
      {
        title: <FormattedMessage id="ProductLog.PO" defaultMessage="P.O." />,
        key: "purchaseOrderId",
        width: 100,
        render: record => {
          return record.poNo;
        }
      },
      {
        title: <FormattedMessage id="ProductLog.PlannedReleaseDate" defaultMessage="P.O. Date" />,
        key: "purchaseOrderId",
        width: 100,
        render: record => {
          return Boolean(record.poDate) ? moment(record.poDate).format("DD-MM-YYYY HH:mm") : "";
        }
      },
      {
        title: <FormattedMessage id="ProductLog.Supplier" defaultMessage="Supplier" />,
        key: "manufacturer",
        width: 100,
        render: record => {
          return record.manufacturer;
        }
      },
      {
        title: <FormattedMessage id="ProductLog.Status" defaultMessage="Status" />,
        key: "id",
        width: 100,
        render: record => {
          return "";
        }
      },
    ];

    const handleResizeColumnsRelease = index => (_, { size }) => {
      const newColumnsRelease = [...columnsRelease];
      newColumnsRelease[index] = {
        ...newColumnsRelease[index],
        width: size.width
      };

      this.setState({
        columnsRelease: newColumnsRelease
      });
    };

    const handleResizeColumnsReceiving = index => (_, { size }) => {
      const newColumnsReceiving = [...columnsReceiving];
      newColumnsReceiving[index] = {
        ...newColumnsReceiving[index],
        width: size.width
      };

      this.setState({
        columnsReceiving: newColumnsReceiving
      });
    };

    const handleResizeColumnsMovement = index => (_, { size }) => {
      const newColumnsMovement = [...columnsMovement];
      newColumnsMovement[index] = {
        ...newColumnsMovement[index],
        width: size.width
      };

      this.setState({
        columnsMovement: newColumnsMovement
      });
    };

    const handleResizeColumns = index => (_, { size }) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumnsRelease =
      Boolean(this.state.columnsRelease) && this.state.columnsRelease.length > 0
        ? this.state.columnsRelease.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumnsRelease(index)
          })
        }))
        : Boolean(columnsRelease) &&
        columnsRelease.length > 0 &&
        columnsRelease.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumnsRelease(index)
          })
        }));

    const mergeColumnsReceiving =
      Boolean(this.state.columnsReceiving) && this.state.columnsReceiving.length > 0
        ? this.state.columnsReceiving.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumnsReceiving(index)
          })
        }))
        : Boolean(columnsReceiving) &&
        columnsReceiving.length > 0 &&
        columnsReceiving.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumnsReceiving(index)
          })
        }));

    const mergeColumnsMovement =
      Boolean(this.state.columnsMovement) && this.state.columnsMovement.length > 0
        ? this.state.columnsMovement.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumnsMovement(index)
          })
        }))
        : Boolean(columnsMovement) &&
        columnsMovement.length > 0 &&
        columnsMovement.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumnsMovement(index)
          })
        }));

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumns(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumns(index)
          })
        }));

    return (
      <Badge.Ribbon
        text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage="Product Log" />}
        placement="start"
        color="purple"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "4rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={6}>
                {
                  <Form.Item
                    label={<FormattedMessage id="ProductLog.Stock" defaultMessage="Stock" />}
                    name="stockId"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Cascader changeOnSelect style={{ width: "100%" }} options={this.state.nodes} onChange={this.handleStockIdChange} />
                  </Form.Item>
                }
              </Col>
              <Col md={3}>
                <Button id="ReceiveButton" style={{ width: "100%" }} variant="success" onClick={this.receiveModal}>
                  <FormattedMessage id="ButtonReceive" defaultMessage="Receive" />
                </Button>
              </Col>
              <Col md={3}>
                <Button id="ReleaseButton" style={{ width: "100%" }} variant="danger" onClick={this.releaseModal}>
                  <FormattedMessage id="ButtonRelease" defaultMessage="Release" />
                </Button>
              </Col>
              <Col md={9}>

              </Col>
              <Col md={1}>
                <Tooltip title={<FormattedMessage id="ProductLog.CreateNew" defaultMessage="Create New" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => {
                      if (Boolean(this.state.stockId) && this.state.stockId > 0) {
                        this.setState({
                          showAddNewModal: true,
                          random: Math.random()
                        });
                      } else {
                        showError("Please select stock");
                      }
                    }}
                    icon={<CreateIcon color="secondary" fontSize="large" />}
                  ></AntButton>
                </Tooltip>
              </Col>
              <Col md={1}>
                {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                  <Tooltip title={<FormattedMessage id="ProductLog.Settings" defaultMessage="Settings" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => {
                        this.setState({
                          drawerSettingsShow: true
                        });
                      }}
                      icon={<SettingsIcon color="error" fontSize="large" />}
                    ></AntButton>
                  </Tooltip>
                )}
              </Col>
              <Col md={1}>
                {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                  <Tooltip title={<FormattedMessage id="ProductLog.Transfer" defaultMessage="Transfer" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => {
                        this.setState({
                          showTransferModal: true,
                          random: Math.random()
                        });
                      }}
                      icon={<LinkOutlined color="primary" fontSize="large" />}
                    ></AntButton>
                  </Tooltip>
                )}
              </Col>
            </Row>

            <div hidden={this.state.hideInputs}>
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
              <div className="alert alert-custom alert-light">
                <div className="alert-icon">
                  <FilterOutlined />
                </div>
                <div className="alert-text">
                  {this.state.searchCriteriaModels.map((val, i) => {
                    return (
                      <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                        <span className="mr-2">{val.label}</span>
                        <strong className="mr-2">{val.type}</strong>
                        <span className="mr-2">{val.operationValue}</span>
                        <span className="mr-2">{val.showValue}</span>
                        <span>
                          <Tooltip title="Remove">
                            <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                          </Tooltip>
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="alert-close">
                  <Tooltip title="Remove All">
                    <CloseCircleOutlined
                      style={{ color: "crimson" }}
                      onClick={() =>
                        this.setState({ searchCriteriaModels: [] }, () => {
                          this.filterChangeItem([]);
                        })
                      }
                    />
                  </Tooltip>
                </div>
              </div>
            )}

            <Table
              bordered
              rowKey="key"
              scroll={{ x: 1200, y: 900, scrollToFirstRowOnChange: true }}
              components={{
                header: {
                  cell: ResizableTitle
                }
              }}
              columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <DraggableModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            open={this.state.modalDeleteShow}
            onCancel={() => {
              this.setState({ modalDeleteShow: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ modalDeleteShow: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.delete}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Space>
            ]}
            content={
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            }
          />

          <DraggableModal
            centered
            width={1200}
            title={<FormattedMessage id="ProductLog.Receiving" defaultMessage="Receiving" />}
            open={this.state.showStockReceivingModal}
            onCancel={() => {
              this.setState({
                showStockReceivingModal: false,
                selectedRowKeysReceiving: []
              });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showStockReceivingModal: false,
                    selectedRowKeysReceiving: []
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
            ]}
            content={
              <ProductLogReceive
                id={this.state.id}
                random={Math.random()}
                stockId={this.state.stockId}
                selectedPO={this.state.selectedPO}
                receivingType={this.state.receivingType}
                saveStockReceiving={this.saveStockReceiving}
              />
            }
          />

          <DraggableModal
            centered
            width={1200}
            title={<FormattedMessage id="ProductLog.Receiving" defaultMessage="Receiving" />}
            open={this.state.showReceivingModal}
            onCancel={() => {
              this.setState({
                showReceivingModal: false
              });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showReceivingModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
            ]}
            content={
              <>
                <Form initialValues={{ remember: false }} ref={this.formRefReceive}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProductLog.receivingType" defaultMessage="Type" />}
                    name="receivingType"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Radio.Group
                      onChange={e => {
                        this.setState({
                          receivingType: e.target.value
                        });
                      }}
                    >
                      <Radio key={"Purchasing"} value={"Purchasing"}>
                        {<FormattedMessage id="ProductLog.Purchasing" defaultMessage="Purchasing" />}
                      </Radio>
                      <Radio key={"Transfer"} value={"Transfer"}>
                        {<FormattedMessage id="ProductLog.Transfer" defaultMessage="Transfer" />}
                      </Radio>
                      <Radio key={"Consigment"} value={"Consigment"}>
                        {<FormattedMessage id="ProductLog.Consigment" defaultMessage="Consigment" />}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Form>
                <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                  <Table
                    bordered
                    rowKey="id"
                    // components={components}
                    // rowSelection={{
                    //   type: "radio",
                    //   ...rowSelectionReceiving,
                    // }}
                    scroll={{ x: 2000, scrollToFirstRowOnChange: true }}
                    components={{
                      header: {
                        cell: ResizableTitle
                      }
                    }}
                    columns={Boolean(mergeColumnsReceiving) && mergeColumnsReceiving.length > 0 ? mergeColumnsReceiving : columnsReceiving}
                    dataSource={this.state.tableReceivingList}
                    loading={this.state.loading}
                    onChange={this.handleTableChangeReceiving}
                    pagination={this.state.paginationReceive}
                  />
                </div>
              </>
            }
          />

          <DraggableModal
            centered
            width={900}
            title={<FormattedMessage id="ProductLog.Release" defaultMessage="Release" />}
            open={this.state.showStockReleaseModal}
            onCancel={() => {
              this.setState({
                showStockReleaseModal: false,
                selectedRowKeysRelease: []
              });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showStockReleaseModal: false,
                    selectedRowKeysRelease: []
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
            ]}
            content={
              <ProductLogRelease
                selectedRow={this.state.selectedRow}
                id={this.state.id}
                random={Math.random()}
                stockId={this.state.stockId}
                selectedPrjTakeOff={this.state.selectedPrjTakeOff}
                releaseType={this.state.releaseType}
                onPurposeType={this.state.onPurposeType}
                productLogReservedId={this.state.productLogReservedId}
                productLogReleaseId={this.state.productLogReleaseId}
                saveStockRelease={this.saveStockRelease}
              />
            }
          />

          <DraggableModal
            centered
            width={1200}
            title={<FormattedMessage id="ProductLog.Release" defaultMessage="Release" />}
            open={this.state.showReleaseModal}
            onCancel={() => {
              this.setState({
                showReleaseModal: false
              });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showReleaseModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
            ]}
            content={
              <>
                <Form initialValues={{ remember: false }} ref={this.formRefRelease}>
                  <Row gutter={8}>
                    <Col span={8}>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        name="releaseType"
                        rules={[
                          {
                            required: true,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                        extra={this.state.onPurposeTypeName}
                      >
                        <Radio.Group
                          onChange={e => {
                            let showOnPurposeTypeModal = false;
                            if (e.target.value === "On_Purpose") {
                              showOnPurposeTypeModal = true;
                            }
                            this.setState({
                              releaseType: e.target.value,
                              showOnPurposeTypeModal: showOnPurposeTypeModal,
                              onPurposeType: null,
                              onPurposeTypeName: "",
                              tableRelease: [],
                            });
                          }}
                        >
                          <Radio key={"On_Purpose"} value={"On_Purpose"}>
                            {"On Purpose"}
                          </Radio>
                          <Radio key={"Transfer"} value={"Transfer"}>
                            {"Transfer"}
                          </Radio>
                          <Radio key={"Consigment"} value={"Consigment"}>
                            {"Consigment"}
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SearchButton" style={{ width: "100%" }} variant="success" onClick={this.prjTakeOffSearch}>
                        <FormattedMessage id="GeneralButtonSearch" defaultMessage="Search" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                </Form>
                <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                  {this.state.onPurposeType === "ProjectItems" ? <Table
                    bordered
                    rowKey="id"
                    rowSelection={{
                      type: "radio",
                      ...rowSelectionRelease
                    }}
                    scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                    components={{
                      header: {
                        cell: ResizableTitle
                      }
                    }}
                    columns={Boolean(mergeColumnsRelease) && mergeColumnsRelease.length > 0 ? mergeColumnsRelease : columnsRelease}
                    dataSource={this.state.tableRelease}
                    loading={this.state.loading}
                    onChange={this.handleTableChangeRelease}
                    pagination={false}
                  /> :
                    <Table
                      bordered
                      rowKey="id"
                      rowSelection={{
                        type: "radio",
                        ...rowSelectionRelease
                      }}
                      scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                      components={{
                        header: {
                          cell: ResizableTitle
                        }
                      }}
                      columns={Boolean(mergeColumnsRelease) && mergeColumnsRelease.length > 0 ? mergeColumnsRelease : columnsRelease}
                      dataSource={this.state.tableRelease}
                      loading={this.state.loading}
                      onChange={this.handleTableChangeRelease}
                      pagination={this.state.paginationRelease}
                    />}
                </div>
              </>
            }
          />

          <DraggableModal
            centered
            width={1500}
            title="Stock Movement"
            open={this.state.showMovementModal}
            onCancel={() => {
              this.setState({
                showMovementModal: false
              });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showMovementModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
            ]}
            content={
              <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                <Table
                  bordered
                  rowKey="id"
                  scroll={{ x: 1200, scrollToFirstRowOnChange: true }}
                  components={{
                    header: {
                      cell: ResizableTitle
                    }
                  }}
                  columns={Boolean(mergeColumnsMovement) && mergeColumnsMovement.length > 0 ? mergeColumnsMovement : columnsMovement}
                  dataSource={this.state.tableMovementList}
                  loading={this.state.loading}
                  pagination={this.state.paginationMovement}
                  onChange={this.handleTableChangeMovement}
                />
              </div>
            }
          />

          <DraggableModal
            centered
            width={1200}
            title=""
            open={this.state.showStockQuantityDimensionModal}
            onCancel={() => {
              this.setState({
                showStockQuantityDimensionModal: false
              });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showStockQuantityDimensionModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
            ]}
            content={
              <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                <Table
                  bordered
                  rowKey="id"
                  columns={this.state.columnsStockQuantityDimension}
                  dataSource={this.state.tableStockQuantityDimensiontList}
                  loading={this.state.loading}
                  pagination={false}
                />
              </div>
            }
          />

          <DraggableModal
            centered
            width={900}
            title={<FormattedMessage id="ProductLog.reserve" defaultMessage="Reserve" />}
            open={this.state.showReserveModal}
            onCancel={() => {
              this.setState({
                showReserveModal: false
              });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showReserveModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
            ]}
            content={
              <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
                <Form id="FormReserve" initialValues={{ remember: false }} onFinish={this.saveReserveSubmit} ref={this.formRefReserve}>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProductLog.availableQuantity" defaultMessage="Available Stock Quantity" />}
                      style={{ marginBottom: "5px" }}
                      name="availableQuantity"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber
                        style={{
                          width: "100%"
                        }}
                        min={0}
                        readOnly
                      />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProductLog.ReservedQuantity" defaultMessage="Reserved Quantity" />}
                      style={{ marginBottom: "5px" }}
                      name="reservedQuantity"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber
                        style={{
                          width: "100%"
                        }}
                        min={1}
                        max={this.state.availableQuantity}
                      />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProductLog.Project" defaultMessage="Project" />}
                      style={{ marginBottom: "5px" }}
                      name="project"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                        {Boolean(this.state["projectSelectItems"]) &&
                          this.state["projectSelectItems"].length > 0 &&
                          this.state["projectSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }

                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProductLog.PlannedReleasedDate" defaultMessage="Planned released date" />}
                      style={{ marginBottom: "5px" }}
                      name="plannedReleasedDate"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <DatePicker showTime format="DD-MM-YYYY HH:mm" style={{ width: "100%" }} />
                    </Form.Item>
                  }

                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <Button id="SaveButton" style={{ width: "100%" }} variant="success" type="submit">
                          <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </Form>
              </div>
            }
          />

          <DraggableModal
            centered
            width={900}
            title="Min Stock Requirement"
            open={this.state.showMinStockRequirementModal}
            onCancel={() => {
              this.setState({
                showMinStockRequirementModal: false
              });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showMinStockRequirementModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
            ]}
            content={
              <div className="card card-custom" style={{ minHeight: "100%" }}>
                <Form
                  id="FormMinStockRequirement"
                  initialValues={{ remember: false }}
                  onFinish={this.saveMinQuantitySubmit}
                  ref={this.formRefMinStockReq}
                >
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProductLog.minReqQuantity" defaultMessage="Minimum Stock" />}
                      style={{ marginBottom: "5px" }}
                    >
                      <Row gutter={8}>
                        <Col span={8}>
                          <Form.Item name="minReqQuantity">
                            <InputNumber
                              style={{
                                width: "100%"
                              }}
                              min={0}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={14}>{this.state.formMinQuantityUnitName}</Col>
                      </Row>
                    </Form.Item>
                  }

                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <Button id="SaveButton" style={{ width: "100%" }} variant="success" type="submit">
                          <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </Form>
              </div>
            }
          />

          <DraggableModal
            title={
              <span>
                <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                  <FormattedMessage id="ProductLog.RequestForQualityControl" defaultMessage="Request For Quality Control" />
                </span>
              </span>
            }
            centered
            open={this.state.modalRFQCShow}
            onOk={() => this.sendQualityControl()}
            onCancel={() => this.setState({ modalRFQCShow: false })}
            okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={
              <FormattedMessage id="ProductLog.MessageSendQualityControl" defaultMessage="Do you really want to send Quality Control?" />
            }
          />

          <DraggableModal
            title={<FormattedMessage id="ProductLog.OnPurposeType" defaultMessage="On Purpose Type" />}
            centered
            width={500}
            open={this.state.showOnPurposeTypeModal}
            onCancel={() => {
              this.setState({
                showOnPurposeTypeModal: false
              });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showOnPurposeTypeModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
            ]}
            content={
              <div className="card card-custom" style={{ minHeight: "100%" }}>
                <Row gutter={8}>
                  <Col span={6}>
                  </Col>
                  <Col span={12}>
                    <Radio.Group value={this.state.onPurposeType}
                      onChange={e => {
                        let onPurposeTypeName = "";
                        if (e.target.value === "ReservedItems") {
                          onPurposeTypeName = "Reserved Items";
                        } else if (e.target.value === "ToBeReleasedItems") {
                          onPurposeTypeName = "To be released items";
                        } else if (e.target.value === "ProjectItems") {
                          onPurposeTypeName = "Project Items";
                        } else if (e.target.value === "Requests") {
                          onPurposeTypeName = "Requests";
                        } else if (e.target.value === "OtherItems") {
                          onPurposeTypeName = "Other Items";
                        }
                        this.setState({
                          onPurposeType: e.target.value,
                          showOnPurposeTypeModal: false,
                          onPurposeTypeName: onPurposeTypeName,
                        });
                      }}
                    >
                      <Radio key={"ReservedItems"} value={"ReservedItems"}>
                        {"Reserved items"}
                      </Radio>
                      <Radio key={"ToBeReleasedItems"} value={"ToBeReleasedItems"}>
                        {"To be released items"}
                      </Radio>
                      <Radio key={"ProjectItems"} value={"ProjectItems"}>
                        {"Project items"}
                      </Radio>
                      <Radio key={"Requests"} value={"Requests"}>
                        {"Requests"}
                      </Radio>
                      <Radio key={"OtherItems"} value={"OtherItems"}>
                        {"Other items"}
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </div>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="ProductLog.ProductTree" defaultMessage="Product Tree" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showTransferModal}
            onCancel={() => {
              this.setState({ showTransferModal: false });
            }}
            onOk={() => {
              this.okProjectTreeTemplate();
            }}
            content={
              <div className="card card-custom" style={{ padding: "1rem", minHeight: "100%" }}>
                <EldenCompoundsSettingsProductTreeSelectable
                  onTargetKeysCheck={this.onTargetKeysCheck}
                />
              </div>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="ProductLog.ProductTree" defaultMessage="Product Tree" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showTransferCompanyModal}
            onCancel={() => {
              this.setState({ showTransferCompanyModal: false });
            }}
            onOk={() => {
              this.okProjectTreeTemplateCompany();
            }}
            content={
              <div className="card card-custom" style={{ padding: "1rem", minHeight: "100%" }}>
                <EldenCompoundsSettingsProductTreeSelectableCompany
                  onTargetKeysCheckCompany={this.onTargetKeysCheckCompany}
                />
              </div>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="ProductLog.CreateNewProduct" defaultMessage="Create New Product" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showCreateANewProductModal}
            onCancel={() => {
              this.setState({ showCreateANewProductModal: false });
            }}
            onOk={() => {
              this.okProjectTreeTemplateCompany();
            }}
            content={
              <div className="card card-custom" style={{ padding: "1rem", minHeight: "100%" }}>
                <EldenCompoundsSettingsProductTree
                  onTargetKeysCheckCompanyAddProduct={this.onTargetKeysCheckCompanyAddProduct}
                />
              </div>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="ProductLog.AddNewProduct" defaultMessage="Add New Product" />}
            centered
            width={400}
            maskClosable={false}
            open={this.state.showAddNewModal}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState({ showAddNewModal: false });
            }}
            content={
              <>
                <Button
                  onClick={() => {
                    this.setState({
                      showTransferCompanyModal: true,
                      showAddNewModal: false
                    });
                  }}
                  style={{ width: "100%" }}
                  variant="secondary"
                >
                  {<FormattedMessage id="ProductLog.SelectFromTemplates" defaultMessage="Select from templates" />}
                </Button>
                <br /> <br />
                <Button
                  onClick={() => {
                    this.setState({
                      showCreateANewProductModal: true,
                      showAddNewModal: false
                    });
                  }}
                  style={{ width: "100%" }}
                  variant="secondary"
                >
                  {<FormattedMessage id="ProductLog.CreateANewProduct" defaultMessage="Create a new product" />}
                </Button>
              </>
            }
          />

          <Drawer
            title={<FormattedMessage id="ProductLog.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ drawerSettingsShow: false });
            }}
            open={this.state.drawerSettingsShow}
          >
            <PageSettings pageName={"Product_Log"} />
          </Drawer>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductLog);