import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Input, Tree, Button as AntButton, Checkbox, Form, Modal as AntModal, Select, Badge, Space, Table, InputNumber } from "antd";
import { FcFolder, FcEditImage, FcAddressBook } from "react-icons/fc";
import { Menu, Item, useContextMenu } from "react-contexify";
import { FcTreeStructure, FcBiotech, FcMediumPriority, FcLibrary } from "react-icons/fc";
import { MdDelete } from "react-icons/md";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../../ApiConnector";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import DraggableModal from "../../../Components/DraggableModal";

const Menu_Quantity = "Menu_Quantity";

const { Option } = Select;
//const { TextArea } = Input;

const { show } = useContextMenu();

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

export class EldenMobilizationAssign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "EldenMobilizationAssign",
      controllerName: "eldenMobilization",
      id: null,
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      loadedKeys: [],
      searchValue: "",
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showTitleModal: false,
      refreshTree: true
    };
  }

  formRef = React.createRef();
  formRefTitle = React.createRef();
  formRefSubTitle = React.createRef();
  formRefInput = React.createRef();
  formRefFormat = React.createRef();
  formRefQuantity = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    this.setState({
      id: null,
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      loadedKeys: [],
      searchValue: "",
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showTitleModal: false,
      refreshTree: true
    }, () => {
      console.log("props", this.props);
      this.fillTree();
      this.fillComboboxes();
    });
  };

  inputRef = React.createRef(null);

  fillComboboxes = async () => {
    var responseInputFormatIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/inputFormat");
    if (responseInputFormatIdSelectItems.type === "ERROR") {
      error(responseInputFormatIdSelectItems);
    }
    this.setState({
      inputFormatIdSelectItems: Boolean(responseInputFormatIdSelectItems.data) ? responseInputFormatIdSelectItems.data : []
    });
    var responseValueIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/value");
    if (responseValueIdSelectItems.type === "ERROR") {
      error(responseValueIdSelectItems);
    }
    this.setState({
      valueIdSelectItems: Boolean(responseValueIdSelectItems.data) ? responseValueIdSelectItems.data : []
    });
    var responseValuePlusIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/valuePlus");
    if (responseValuePlusIdSelectItems.type === "ERROR") {
      error(responseValuePlusIdSelectItems);
    }
    this.setState({
      valuePlusIdSelectItems: Boolean(responseValuePlusIdSelectItems.data) ? responseValuePlusIdSelectItems.data : []
    });
    var responseValuePlusLinkIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/valuePlusLink");
    if (responseValuePlusLinkIdSelectItems.type === "ERROR") {
      error(responseValuePlusLinkIdSelectItems);
    }
    this.setState({
      valuePlusLinkIdSelectItems: Boolean(responseValuePlusLinkIdSelectItems.data) ? responseValuePlusLinkIdSelectItems.data : []
    });
    var responseUserActionIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/userAction");
    if (responseUserActionIdSelectItems.type === "ERROR") {
      error(responseUserActionIdSelectItems);
    }
    this.setState({
      userActionIdSelectItems: Boolean(responseUserActionIdSelectItems.data) ? responseUserActionIdSelectItems.data : []
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/rootAssign/" + this.props.eldenMobilizationTitleId
      + "/" + this.props.eldenMobilizationSubTitleId + "/" + this.props.type
    );

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "Mobilization";
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "Page"
    };

    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      if (treenode.type === "Title") {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsTitle/" + obj.id);
      } else if (treenode.type === "SubTitle") {
        let manpowerId = Boolean(this.props) && Boolean(this.props.assignRow) ? this.props.assignRow.id : -1;
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsSubTitleWithManpower/" + obj.id + "/" + manpowerId);
      } else if (treenode.type === "Input") {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsInput/" + obj.id);
      } else {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsMobilization/" + obj.id);
      }
    }
    if (Boolean(response) && response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.icon =
          Boolean(element.type) && element.type === "Service" ? (
            <FcLibrary />
          ) : element.type === "Title" ? (
            <FcTreeStructure />
          ) : element.type === "SubTitle" ? (
            <FcAddressBook />
          ) : element.type === "Input" ? (
            <FcBiotech />
          ) : element.type === "Format" ? (
            <FcMediumPriority />
          ) : (
            <FcFolder />
          );

        if (Boolean(element.description)) {
          element.title = element.value + " (" + element.description + ")";
        } else {
          element.title = element.value;
        }
        element.id = element.key;
        element.key = element.key + "-" + element.type;
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      this.setState({
        nodes: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  onRightClick = (event, node) => {
    if (!(Boolean(this.props) && Boolean(this.props.onTargetKeysCheck))) {
      let parentNode = this.getParentNode(node);
      this.setState(
        {
          selectedNodeKey: node.key,
          selectedNode: node,
          parentNode: parentNode
        },
        this.displayMenu(event, Boolean(node) && Boolean(node.type) ? node.type : null)
      );
    }
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  handleItemClick = ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;

    console.log("AAA", event.currentTarget.id);
    console.log(selectedNode);
    console.log(parentNode);
    console.log(key);

    switch (event.currentTarget.id) {
      case "edit_quantity":
        this.setState({
          showQuantityModal: true,
          eldenMobilizationInputId: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        }, () => {
          this.formRefQuantity.current.setFieldsValue({
            quantity: selectedNode.description,
          });
        });
        break;
    }
  };

  deleteTitle = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteTitle/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteTitleModal: false
        });
      }
    } else {
      showError("Location not found.");
    }
  };

  deleteSubTitle = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteSubTitle/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteSubTitleModal: false
        });
      }
    } else {
      showError("Location not found.");
    }
  };

  deleteInput = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteInput/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteInputModal: false
        });
      }
    } else {
      showError("Location not found.");
    }
  };

  displayMenu = (e, type) => {
    console.log("TTT", type);
    if (Boolean(type)) {
      if (type === "Input") {
        show(e, { id: Menu_Quantity });
      }
    }
  };

  saveTitle = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveTitle", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateTitle/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showTitleModal: false,
        showEditTitleModal: false
      });
    }
  };

  saveSubTitle = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      eldenMobilizationTitleId: this.state.eldenMobilizationTitleId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveSubTitle", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateSubTitle/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showSubTitleModal: false,
        showEditSubTitleModal: false
      });
    }
  };

  saveSubTitleSubTitle = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveSubTitle", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateSubTitle/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showSubTitleSubTitleModal: false,
        showEditSubTitleSubTitleModal: false
      });
    }
  };

  saveInput = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      eldenMobilizationTitleId: this.state.eldenMobilizationTitleId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveInput", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateInput/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showInputModal: false,
        showEditInputModal: false
      });
    }
  };

  saveInputSubTitle = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      eldenMobilizationSubTitleId: this.state.eldenMobilizationSubTitleId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveInput", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateInput/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showInputSubTitleModal: false,
        showEditInputSubTitleModal: false
      });
    }
  };

  saveFormat = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      eldenMobilizationInputId: this.state.eldenMobilizationInputId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveFormat", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateFormat/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showFormatModal: false
      });
    }
  };

  paste = () => {
    this.formRef.current.submit();
    this.formRefTitle.current.submit();
    this.formRefSubTitle.current.submit();
    this.formRefInput.current.submit();
    this.formRefFormat.current.submit();
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      code: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      process: null,
      showOrderModal: false,
      showPasteModal: false,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showTitleModal: false
    });
  };

  expandSelectedNode = () => {
    let key = this.state.selectedNodeKey;
    let node = this.state.selectedNode;
    if (Boolean(key)) {
      let expandedKeys = Boolean(this.state.expandedKeys) ? this.state.expandedKeys : [];
      if (Boolean(expandedKeys.find(p => p === key))) {
        this.loadData(node, false);
      } else {
        this.setState({
          expandedKeys: [...expandedKeys, key]
        });
        this.loadData(node, false);
      }
    }
  };

  itemTemplate(item) {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <h5 className="mb-2">{item.title}</h5>
        </div>
      </div>
    );
  }

  onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...this.state.nodes];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 &&
      // Has children
      info.node.props.expanded &&
      // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    this.setState({
      nodes: data
    });
  };

  onCheck = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    if (Boolean(this.props) && Boolean(this.props.onTargetKeysCheck)) {
      console.log("checkedKeys", e.checkedNodes);
      console.log("checkedKeys", e.halfCheckedKeys);
      this.props.onTargetKeysCheck(checkedKeys.checked);
    }
    this.setState({
      checkedKeys: checkedKeys,
      halfCheckedKeys: e.halfCheckedKeys,
      checkedNodes: e.checkedNodes
    });
  };

  saveQuantity = async values => {
    if (!(Boolean(this.props) && Boolean(this.props.assignRow))) {
      showWarning("Assign row not found.");
      return;
    }

    const { process, parentNode } = this.state;
    const newItem = {
      ...values,
      manpowerId: this.props.assignRow.id,
      numberOfPerson: this.props.assignRow.numberOfPerson,
      eldenMobilizationInputId: this.state.eldenMobilizationInputId,
    };

    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveQuantity", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(parentNode)) this.loadData(parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showQuantityModal: false,
      });
    }
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        {this.state.refreshTree &&
          <Tree
            style={{ marginTop: "10px", lineHeight: "30px" }}
            className="draggable-tree"
            showLine={{ showLeafIcon: false }}
            showIcon
            checkStrictly={true}
            loadedKeys={this.state.loadedKeys}
            onLoad={this.onLoadData}
            loadData={this.loadData}
            onRightClick={({ event, node }) => this.onRightClick(event, node)}
            onExpand={this.onExpand}
            expandedKeys={this.state.expandedKeys}
            autoExpandParent={false}
            treeData={this.state.nodes}
            blockNode={true}
            draggable
            checkable
            onCheck={this.onCheck}
            checkedKeys={this.state.checkedKeys}
            halfCheckedKeys={this.state.halfCheckedKeys}
            onDragEnter={this.onDragEnter}
            onDrop={this.onDrop}
          />
        }

        <Menu id={Menu_Quantity}>
          <Item id="edit_quantity" onClick={this.handleItemClick}>
            <Space>
              <FcEditImage />
              <span>
                <FormattedMessage id="EldenMobilizationAssign.Quantity" defaultMessage="Quantity" />
              </span>
            </Space>
          </Item>
        </Menu>

        <DraggableModal
          title={<FormattedMessage id="EldenMobilizationAssign.Title" defaultMessage="Title" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.showTitleModal}
          onOk={() => this.formRefTitle.current.submit()}
          onCancel={() => {
            this.setState({ showTitleModal: false });
          }}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveTitle} onFinishFailed={onFinishFailed} ref={this.formRefTitle}>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenMobilizationAssign.Title" defaultMessage="Title" />}
                  name="title"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BillOfQuantity.MatchWithPersonnel" defaultMessage="Match with personnel" />}
                  name="matchWithPersonnel"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox></Checkbox>
                </Form.Item>
              </Form>
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="EldenMobilizationAssign.Sub-Title" defaultMessage="Sub-Title" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.showSubTitleModal}
          onOk={() => this.formRefSubTitle.current.submit()}
          onCancel={() => {
            this.setState({ showSubTitleModal: false });
          }}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveSubTitle} onFinishFailed={onFinishFailed} ref={this.formRefSubTitle}>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenMobilizationAssign.Sub-Title" defaultMessage="Sub-Title" />}
                  name="subTitle"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BillOfQuantity.MatchWithPersonnel" defaultMessage="Match with personnel" />}
                  name="matchWithPersonnel"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox></Checkbox>
                </Form.Item>
              </Form>
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="EldenMobilizationAssign.Sub-Title" defaultMessage="Sub-Title" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.showSubTitleSubTitleModal}
          onOk={() => this.formRefSubTitle.current.submit()}
          onCancel={() => {
            this.setState({ showSubTitleSubTitleModal: false });
          }}
          content={
            <>
              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveSubTitleSubTitle}
                onFinishFailed={onFinishFailed}
                ref={this.formRefSubTitle}
              >
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenMobilizationAssign.Sub-Title" defaultMessage="Sub-Title" />}
                  name="subTitle"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BillOfQuantity.MatchWithPersonnel" defaultMessage="Match with personnel" />}
                  name="matchWithPersonnel"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox></Checkbox>
                </Form.Item>
              </Form>
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="EldenMobilizationAssign.Input" defaultMessage="Input" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.showInputModal}
          onOk={() => this.formRefInput.current.submit()}
          onCancel={() => {
            this.setState({ showInputModal: false });
          }}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveInput} onFinishFailed={onFinishFailed} ref={this.formRefInput}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenMobilizationAssign.Input" defaultMessage="Input" />}
                    name="input"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="EldenMobilizationAssign.Input" defaultMessage="Input" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.showInputSubTitleModal}
          onOk={() => this.formRefInput.current.submit()}
          onCancel={() => {
            this.setState({ showInputSubTitleModal: false });
          }}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveInputSubTitle} onFinishFailed={onFinishFailed} ref={this.formRefInput}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenMobilizationAssign.Input" defaultMessage="Input" />}
                    name="input"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="EldenMobilizationAssign.Format" defaultMessage="Format" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.showFormatModal}
          onOk={() => this.formRefFormat.current.submit()}
          onCancel={() => {
            this.setState({ showFormatModal: false });
          }}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveFormat} onFinishFailed={onFinishFailed} ref={this.formRefFormat}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenMobilizationAssign.Input" defaultMessage="Input" />}
                    name="input"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }

                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenMobilizationAssign.InputFormat" defaultMessage="Input Format" />}
                    name="inputFormatId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                      {Boolean(this.state["inputFormatIdSelectItems"]) &&
                        this.state["inputFormatIdSelectItems"].length > 0 &&
                        this.state["inputFormatIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }

                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenMobilizationAssign.Value" defaultMessage="Value" />}
                    name="valueId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                      {Boolean(this.state["valueIdSelectItems"]) &&
                        this.state["valueIdSelectItems"].length > 0 &&
                        this.state["valueIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }

                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenMobilizationAssign.Value+" defaultMessage="Value +" />}
                    name="valuePlusId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                      {Boolean(this.state["valuePlusIdSelectItems"]) &&
                        this.state["valuePlusIdSelectItems"].length > 0 &&
                        this.state["valuePlusIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }

                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenMobilizationAssign.Value+Link" defaultMessage="Value + link" />}
                    name="valuePlusLinkId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                      {Boolean(this.state["valuePlusLinkIdSelectItems"]) &&
                        this.state["valuePlusLinkIdSelectItems"].length > 0 &&
                        this.state["valuePlusLinkIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }

                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenMobilizationAssign" defaultMessage=" " />}
                    name="isValue"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox>
                      <FormattedMessage id="EldenMobilizationAssign.ValueandValuue+areonsameline" defaultMessage="Value and Valuue + are on same line" />
                    </Checkbox>
                  </Form.Item>
                }

                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenMobilizationAssign.UserAction" defaultMessage="User Action" />}
                    name="userActionId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                      {Boolean(this.state["userActionIdSelectItems"]) &&
                        this.state["userActionIdSelectItems"].length > 0 &&
                        this.state["userActionIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }
              </Form>
            </>
          }
        />

        {/* EditModal */}

        <DraggableModal
          title={<FormattedMessage id="EldenMobilizationAssign.EditTitle" defaultMessage="Edit Title" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.showEditTitleModal}
          onOk={() => this.formRefTitle.current.submit()}
          onCancel={() => {
            this.setState({ showEditTitleModal: false });
          }}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveTitle} onFinishFailed={onFinishFailed} ref={this.formRefTitle}>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenMobilizationAssign.Title" defaultMessage="Title" />}
                  name="title"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BillOfQuantity.MatchWithPersonnel" defaultMessage="Match with personnel" />}
                  name="matchWithPersonnel"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox></Checkbox>
                </Form.Item>
              </Form>
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="EldenMobilizationAssign.EditSub-Title" defaultMessage="Edit Sub-Title" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.showEditSubTitleModal}
          onOk={() => this.formRefSubTitle.current.submit()}
          onCancel={() => {
            this.setState({ showEditSubTitleModal: false });
          }}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveSubTitle} onFinishFailed={onFinishFailed} ref={this.formRefSubTitle}>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenMobilizationAssign.Sub-Title" defaultMessage="Sub-Title" />}
                  name="subTitle"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BillOfQuantity.MatchWithPersonnel" defaultMessage="Match with personnel" />}
                  name="matchWithPersonnel"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox></Checkbox>
                </Form.Item>
              </Form>
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="EldenMobilizationAssign.EditInput" defaultMessage="Edit Input" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.showEditInputModal}
          onOk={() => this.formRefInput.current.submit()}
          onCancel={() => {
            this.setState({ showEditInputModal: false });
          }}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveInput} onFinishFailed={onFinishFailed} ref={this.formRefInput}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenMobilizationAssign.Input" defaultMessage="Input" />}
                    name="input"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </>
          }
        />

        {/* DeleteModal */}

        <DraggableModal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={400}
          maskClosable={false}
          open={this.state.showDeleteTitleModal}
          okText={<FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />}
          onOk={this.deleteTitle}
          onCancel={() => {
            this.setState({ showDeleteTitleModal: false });
          }}
          content={
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={400}
          maskClosable={false}
          open={this.state.showDeleteSubTitleModal}
          okText={<FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />}
          onOk={this.deleteSubTitle}
          onCancel={() => {
            this.setState({ showDeleteSubTitleModal: false });
          }}
          content={
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={400}
          maskClosable={false}
          open={this.state.showDeleteInputModal}
          okText={<FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />}
          onOk={this.deleteInput}
          onCancel={() => {
            this.setState({ showDeleteInputModal: false });
          }}
          content={
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="EldenMobilizationAssign.Quantity" defaultMessage="Quantity" />}
          centered
          width={600}
          maskClosable={false}
          open={this.state.showQuantityModal}
          onOk={() => this.formRefQuantity.current.submit()}
          onCancel={() => {
            this.setState({ showQuantityModal: false });
          }}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveQuantity} onFinishFailed={onFinishFailed} ref={this.formRefQuantity}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenMobilizationAssign.Quantity" defaultMessage="Quantity" />}
                    name="quantity"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </>
          }
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EldenMobilizationAssign);