import { SearchOutlined } from "@ant-design/icons";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import { Button as AntButton, Col, DatePicker, Form, PageHeader, Row, Table, Typography } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error } from "../../MessageHelper";
import { excelExport } from "../../ExcelHelper";

export class SalesSummary extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      tableList: [],

      loading: false,
      sortField: "",
      sortOrder: "",
      startDate: moment().subtract(1, "M"),
      endDate: moment(),

      excelData: [],
      salesAmount: 0,
      paidAmount: 0,
      profit: 0,
    };
  }

  componentDidMount = async () => {
    this.restartTable();
    this.fillComboboxes();
  };

  fillComboboxes = async () => { };

  restartTable = async () => {

    const { startDate, endDate } = this.state;
    this.setState({ loading: true });

    let obj = {
      startDate: startDate
        ? startDate
        : moment()
          .subtract(1, "M")
          .toDate(),
      endDate: endDate ? endDate : moment().toDate()
    };
    const response = await handleRequest("POST", "/api/invoiceProduct/salesSummary", obj);
    console.log(response);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false
      });
    } else {
      this.setState({
        tableList: Boolean(response.data) ? response.data : [],
        // tableList: testData,
        loading: false
      });

      let soldAmount = 0;
      let totalPaid = 0;
      let profit = 0;

      response.data.forEach(({ productAmount, paidAmount }) => {
        console.log("soldAmount: " + productAmount);
        console.log("paidAmount: " + paidAmount);

        soldAmount += productAmount;
        totalPaid += paidAmount;
      });
      profit = soldAmount - totalPaid;

      this.setState(
        {
          salesAmount: soldAmount,
          paidAmount: totalPaid,
          profit: profit,
        }
      );
    }
  };

  search = async values => {

    this.setState(
      {
        ...values,
        startDate: Boolean(values.startDate) ? values.startDate.toDate() : null,
        endDate: Boolean(values.endDate) ? values.endDate.toDate() : null,
        loading: true
      },
      this.restartTable,
    );
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  currencyFormat(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  }

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: "Salesman",
        key: "user",
        dataIndex: "user"
      },

      {
        title: "Product",
        key: "product",
        dataIndex: "product"
      },
      {
        title: "Quantity",
        key: "quantity",
        dataIndex: "quantity"
      },
      {
        title: "Paid Amount",
        key: "paidAmount",
        dataIndex: "paidAmount"
      },
      {
        title: "Sales Amount",
        key: "productAmount",
        dataIndex: "productAmount"
      }
    ];

    const columnsExcel = [
      { header: "S/N", key: "index" },
      { header: "Salesman", key: "user" },
      { header: "Product", key: "product" },
      { header: "Quantity", key: "quantity" },
      { header: "Paid Amount", key: "paidAmount" },
      { header: "Sales Amount", key: "productAmount" },
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <PageHeader className="site-page-header" title={"Sales Summary"} />
        <Form
          initialValues={{ remember: false, startDate: moment().subtract(1, "M"), endDate: moment() }}
          onFinish={this.search}
          onFinishFailed={onFinishFailed}
          ref={this.formRef}
        >
          <Form.Item
            {...layout}
            label={<FormattedMessage id="ProductStatistics.startdate" defaultMessage="Start Date" />}
            style={{
              marginBottom: 0
            }}
            name="startDate"
            rules={[
              {
                required: false,
                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
              }
            ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            {...layout}
            label={<FormattedMessage id="ProductStatistics.endDate" defaultMessage="End Date" />}
            style={{
              marginBottom: 0
            }}
            name="endDate"
            rules={[
              {
                required: false,
                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
              }
            ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>

          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <AntButton type="dashed" htmlType="submit" style={{ width: "100%" }} icon={<SearchOutlined />} size="large">
                <FormattedMessage id="GeneralButtonSearch" defaultMessage="Search" />
              </AntButton>
            </Col>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
          </Row>

          <br />
          <br />

          <Form.Item
            {...layout}
            label={<FormattedMessage id="ProductStatistics.paidAmount" defaultMessage="Paid Amount" />}
            style={{
              marginBottom: 0
            }}
            rules={[
              {
                required: false,
                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
              }
            ]}
          >
            <span className="text-right">{this.currencyFormat(this.state.paidAmount)}</span>
          </Form.Item>
          <Form.Item
            {...layout}
            label={<FormattedMessage id="ProductStatistics.productAmount" defaultMessage="Sold Amount" />}
            style={{
              marginBottom: 0
            }}
            rules={[
              {
                required: false,
                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
              }
            ]}
          >
            <span className="text-right">{this.currencyFormat(this.state.salesAmount)}</span>
          </Form.Item>
          <Form.Item
            {...layout}
            label={<FormattedMessage id="ProductStatistics.profit" defaultMessage="Profit" />}
            style={{
              marginBottom: 0
            }}
            rules={[
              {
                required: false,
                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
              }
            ]}
          >
            <span className="text-right">{this.currencyFormat(this.state.profit)}</span>
          </Form.Item>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Row gutter={[16, 16]} justify="end">
            <Col md={1}>
              {
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => { excelExport(columnsExcel, this.state.tableList) }}
                  icon={<ExportIcon color="action" fontSize="large" />}
                />
              }
            </Col>
          </Row>
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList.map((d, i) => ({ key: i, ...d }))}
            loading={this.state.loading}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SalesSummary);
