import { CloseCircleOutlined, FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Button as AntButton, DatePicker, Modal, Select, Table, Tag, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import DOMPurify from "dompurify";
import moment from "moment";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { handleRequest } from "../ApiConnector";
import { error } from "../MessageHelper";
import ColumnFilterComponent from "./ColumnFilterComponent";
import { DownloadIconsFromPaths } from "./ElementUtils";
import ImageGalleryFromUrls from "./helpers/ImageGalleryFromUrls";
const { Option } = Select;
const { RangePicker } = DatePicker;
let timerId;
const debounceFunction = (func, delay) => {
  // Cancels the setTimeout method execution
  clearTimeout(timerId);
  // Executes the func after delay time.
  timerId = setTimeout(func, delay);
};
export class DataTableComponent2 extends Component {
  constructor(props) {
    super();
    let selectItems = {};
    if (props.inputs) {
      props.inputs.forEach(input => {
        selectItems[input.inputName + "SelectItems"] = [];
      });
    }
    this.state = {
      field: props.field,
      actions: props.actions,
      inputs: props.inputs,
      createWhereParams: props.createWhereParams,
      restartTable: props.restartTable,
      list: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      searchCriteriaModels: [],
      ...selectItems
    };
  }
  columnComponents = {
    Tag: Tag
  };
  filterSearchoperations = {
    TEXT: null,
    MAIL: null,
    PHONE: null,
    PASSWORD: null,
    NUMBER: null,
    TEXTAREA: null,
    TEXT_EDITOR: null,
    SELECT: null,
    SELECT_MULTIPLE: null,
    SELECT_ADDABLE: null,
    SELECT_MULTIPLE_ADDABLE: null,
    CHECKBOX: null,
    RADIO: null,
    DATEPICKER: null,
    DATEPICKER_WITH_TIME: null,
    TIMEPICKER: null,
    MONTHPICKER: null,
    YEARPICKER: null,
    RANGEPICKER: null,
    RANGEPICKER_WITH_TIME: null,
    CASCADER: null,
    FILEUPLOAD: null,
    FILEUPLOAD_IMAGE: null,
    TREE_SELECT: null
  };
  componentDidMount() {
    this.fillFilterOperations();
    const { pagination, inputs } = this.state;
    inputs.forEach(input => {
      if (Boolean(input.loadUrl)) {
        this.fillFilterSelectsList(input);
      } else if (input.inputType === "FORM_LIST") {
        input.formListPageForm.pageFormInputCompanyList.forEach(inner => {
          if (Boolean(inner.loadUrl)) {
            this.fillFilterSelectsList(inner);
          }
        });
      }
    });
    this.restartTable({ pagination });
  }
  componentDidUpdate(prevProps, prevState) {
    let res = Boolean(this.props) ? this.props.restartTable : null;
    if (res !== this.state.restartTable) {
      this.setState({ restartTable: res, createWhereParams: this.props.createWhereParams }, () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      });
    }
  }
  fillFilterOperations = async url => {
    var response = await handleRequest("GET", "/ApplicationSettings/searchOperations");
    if (response.type === "ERROR") {
      this.error(response);
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        this.filterSearchoperations.TEXT = response.data.filter(x => x.key.startsWith("STR_"));
        this.filterSearchoperations.NUMBER = response.data.filter(x => x.key.startsWith("NUM_"));
        this.filterSearchoperations.DATEPICKER = response.data.filter(x => x.key.startsWith("DATE_"));
        this.filterSearchoperations.DATEPICKER_WITH_TIME = this.filterSearchoperations.DATEPICKER;
        this.filterSearchoperations.YEARPICKER = this.filterSearchoperations.NUMBER;
        this.filterSearchoperations.CHECKBOX = response.data.filter(x => x.key.startsWith("BOOL_"));
        this.filterSearchoperations.SELECT = this.filterSearchoperations.CHECKBOX;
        this.filterSearchoperations.SELECT_MULTIPLE = this.filterSearchoperations.CHECKBOX;
        this.filterSearchoperations.SELECT_ADDABLE = this.filterSearchoperations.CHECKBOX;
        this.filterSearchoperations.SELECT_MULTIPLE_ADDABLE = this.filterSearchoperations.CHECKBOX;
        this.filterSearchoperations.RADIO = this.filterSearchoperations.CHECKBOX;
        this.filterSearchoperations.CASCADER = this.filterSearchoperations.CHECKBOX;
        this.filterSearchoperations.MAIL = this.filterSearchoperations.TEXT;
        this.filterSearchoperations.PHONE = this.filterSearchoperations.TEXT;
        this.filterSearchoperations.PASSWORD = this.filterSearchoperations.TEXT;
        this.filterSearchoperations.TEXTAREA = this.filterSearchoperations.TEXT;
        this.filterSearchoperations.TEXT_EDITOR = this.filterSearchoperations.TEXT;
      }
    }
  };
  restartTable = async (params = {}) => {
    const { field } = this.state;
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = field.tableLoadUrl; //"/api/countries/selectItems";
    var response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({ loading: false, list: [], pagination: { ...params.pagination, total: 0 }, sortField: "", sortOrder: "" });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        this.setState({
          list: list,
          pagination: { ...params.pagination, total: response.data.totalRecords },
          loading: false,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
        if (Boolean(this.props.setData)) {
          this.props.setData(list);
        }
      } else {
        this.setState({
          list: [],
          pagination: { ...params.pagination, total: 0 },
          loading: false,
          sortField: "",
          sortOrder: ""
        });
        if (Boolean(this.props.setData)) {
          this.props.setData([]);
        }
      }
    }
  };
  createWhere = params => {
    const { pagination } = this.state;
    if (Boolean(params._pagination)) params.pagination = params._pagination;
    var current = pagination.current;
    var pageSize = pagination.pageSize;
    var sortField = "",
      sortOrder = "";

    if (Boolean(params) && Boolean(params.pagination)) {
      current = params.pagination.current;
      pageSize = params.pagination.pageSize;

      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    let otherParams = {};
    if (Boolean(this.state.createWhereParams)) {
      this.state.createWhereParams.forEach(param => {
        otherParams[param.key] = param.value;
      });
    }
    const newObj = {
      ...params.pagination,
      ...otherParams,
      searchCriteriaModels: this.state.searchCriteriaModels,
      firstResult: current - 1, // (current - 1) * pageSize,
      maxResults: pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  };
  filterChangeItem = searchCriteriaModels => {
    var color = "#bfbfbf";
    let filterColors = { lastUpdatedColor: color };
    this.state.inputs.forEach(input => {
      filterColors[input.inputName + "Color"] = color;
    });
    if (Boolean(searchCriteriaModels) && searchCriteriaModels.length > 0) {
      searchCriteriaModels.forEach(searchCriteriaModel => {
        filterColors[searchCriteriaModel.key + "Color"] = "#0099ff";
      });
    }
    this.setState(
      {
        ...filterColors,
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };
  getColumFilterInput = column => {
    return Boolean(this.ColumnFilters[column.inputType]) ? this.ColumnFilters[column.inputType](column) : null;
  };
  getColumnFilter = column => {
    // console.log("getColumnFilters", this.state[column.inputName + "SelectItems"]);
    let c = Boolean(this.ColumnFilters[column.inputType])
      ? {
          filterDropdown: () => (
            <ColumnFilterComponent
              filterChangeItem={this.filterChangeItem}
              input={column}
              searchCriteriaModels={this.state.searchCriteriaModels}
              random={Math.random()}
              selectItems={this.state[column.inputName + "SelectItems"]}
              filterOperations={this.filterSearchoperations[column.inputType]}
            />
          ),
          filterIcon: filtered => (
            <FilterOutlined style={{ color: Boolean(this.state[column.inputName + "Color"]) ? this.state[column.inputName + "Color"] : "#bfbfbf" }} />
          ),

          sorter: true
        }
      : null;
    return c;
  };
  fillFilterSelectsList = async column => {
    let url = column.loadUrl; //"/api/countries/selectItems";
    if (column.parentName) {
      url = url.replace("{id}", 0);
    }
    if (column.addType) {
      url = url + "/" + column.addType;
    }
    if (this.state[column.inputName + "SelectItems"]) return;
    var response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        [column.inputName + "SelectItems"]: Boolean(response.data) ? response.data : []
      });
    }
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  ColumnFilters = {
    TEXT: true,
    PHONE: true,
    EMAIL: true,
    PASSWORD: true,
    NUMBER: true,
    TEXTAREA: true,
    TEXT_EDITOR: true,
    RADIO: true,
    CHECKBOX: true,
    SELECT: true,
    SELECT_ADDABLE: true,
    SELECT_MULTIPLE: true,
    SELECT_MULTIPLE_ADDABLE: true,
    DATEPICKER: true,
    DATEPICKER_WITH_TIME: true,
    YEARPICKER: true
  };
  getTextareaModal = (visibilityState, content) => {
    return (
      <Modal
        title="Document Text"
        visible={this.state[visibilityState]}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => {
          this.setState({ [visibilityState]: false });
        }}
        onOk={() => {
          this.setState({
            [visibilityState]: false
          });
        }}
        width={600}
      >
        <div>
          <TextArea value={content} readOnly rows={25} />
        </div>
      </Modal>
    );
  };
  getTextEditorModal = (visibilityState, content) => {
    return (
      <Modal
        title="Document Text"
        style={{ top: 20 }}
        visible={this.state[visibilityState]}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => {
          this.setState({ [visibilityState]: false });
        }}
        onOk={() => {
          this.setState({
            [visibilityState]: false
          });
        }}
        width={600}
      >
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}></div>
      </Modal>
    );
  };
  ColumnRenderers = {
    FILEUPLOAD_IMAGE: (input, row) => {
      return <ImageGalleryFromUrls paths={row[input.inputName]} />;
    },
    FILEUPLOAD: (input, row) => {
      return DownloadIconsFromPaths(row[input.inputName]);
    },
    DATEPICKER: (input, row) => {
      return Boolean(row[input.inputName]) ? moment(row[input.inputName]).format("DD-MM-YYYY") : "";
    },
    DATEPICKER_WITH_TIME: (input, row) => {
      return Boolean(row[input.inputName]) ? moment(row[input.inputName]).format("DD-MM-YYYY HH:mm:ss") : "";
    },
    CHECKBOX: (input, row) => {
      let elem = Boolean(this.columnComponents[input.columnElement]) ? this.columnComponents[input.columnElement] : "span";
      return Boolean(row[input.inputName])
        ? React.createElement(elem, { color: Boolean(input.trueColor) ? input.trueColor : "green" }, Boolean(input.trueText) ? input.trueText : "Yes")
        : React.createElement(
            elem,
            { color: Boolean(input.falseColor) ? input.falseColor : "red" },
            Boolean(input.falseText) ? input.falseText : "No"
          );
    },
    TEXTAREA: (input, row) => {
      if (Boolean(row[input.inputName]) && row[input.inputName].length > 200) {
        return (
          <>
            {row[input.inputName].substring(0, 50) + "..."}{" "}
            <Button
              type="button"
              size="sm"
              variant="outline-primary"
              onClick={() => {
                this.setState({
                  ["TextAreaModal" + input.inputName]: true
                });
              }}
            >
              Show All
            </Button>
            {this.getTextareaModal("TextAreaModal" + input.inputName, row[input.inputName])}
          </>
        );
      }
      return Boolean(row[input.inputName]) ? row[input.inputName] : "";
    },
    TEXT_EDITOR: (input, row) => {
      return (
        <>
          <Button
            type="button"
            size="sm"
            variant="outline-primary"
            icon={<SearchOutlined />}
            onClick={() => {
              this.setState({
                ["TextEditorModal" + input.inputName]: true
              });
            }}
          >
            Show All
          </Button>
          {this.getTextEditorModal("TextEditorModal" + input.inputName, row[input.inputName])}
        </>
      );
    },
    FORM_LIST: (input, row) => {
      return (
        <AntButton
          type="primary"
          shape="round"
          icon={<SearchOutlined />}
          onClick={() => {
            this.setState({
              showFormListModal: true,
              selectedFormListValue: row[input.inputName],
              selectedFormListInput: input
            });
          }}
        ></AntButton>
      );
    }
  };
  renderColumnValue = (input, value, row, index) => {
    if (Boolean(input.tableColumnValue)) {
      let v = input.tableColumnValue.split(".");
      let columnValue = row[v[0]];
      for (let i = 1; i < v.length; i++) {
        columnValue = Boolean(columnValue) && Boolean(columnValue[v[i]]) ? columnValue[v[i]] : null;
      }
      return columnValue;
    } else {
      return Boolean(this.ColumnRenderers[input.inputType]) ? this.ColumnRenderers[input.inputType](input, row) : value;
    }
  };
  render() {
    const { field, actions, pagination, inputs } = this.state;
    var cols = [
      {
        title: "Actions",
        key: "Actions",
        render: actions
      },
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + pagination.pageSize * (pagination.current - 1);
        }
      }
    ];
    inputs
      .filter(x => {
        return x.inputType !== "HIDDEN";
      })
      .map((val, i) => {
        let c = {
          title: (
            <>
              {Boolean(val.tableColumnHeader) ? (
                <FormattedMessage id={val.tableColumnHeaderLanguage} defaultMessage={val.tableColumnHeader} />
              ) : (
                <FormattedMessage id={val.labelLanguageKey} defaultMessage={val.label} />
              )}
            </>
          ),
          dataIndex: val.inputName,
          key: val.inputName
        };
        c = {
          ...c,
          render: (value, row, index) => {
            return this.renderColumnValue(val, value, row, index);
          },
          ...this.getColumnFilter(val)
        };

        cols.push(c);
        return cols;
      });
    if (field.showLastUpdateDate !== false) {
      cols.push({
        title: "Last Update",
        key: "lastUpdated",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        ...this.getColumnFilter({ inputName: "lastUpdated", inputType: "DATEPICKER_WITH_TIME" })
      });
    }
    return (
      <>
        {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
          <div className="alert alert-custom alert-light">
            <div className="alert-icon">
              <FilterOutlined />
            </div>
            <div className="alert-text">
              {this.state.searchCriteriaModels.map((val, i) => {
                return (
                  <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                    <span className="mr-2">{val.label}</span>
                    <strong className="mr-2">{val.type}</strong>
                    <span className="mr-2">{val.operationValue}</span>
                    <span className="mr-2">{val.showValue}</span>
                    <span>
                      <Tooltip title="Remove">
                        <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                      </Tooltip>
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="alert-close">
              <Tooltip title="Remove All">
                <CloseCircleOutlined
                  style={{ color: "crimson" }}
                  onClick={() =>
                    this.setState({ searchCriteriaModels: [] }, () => {
                      this.filterChangeItem([]);
                    })
                  }
                />
              </Tooltip>
            </div>
          </div>
        )}
        <Table
          rowKey={record => record.id}
          bordered
          scroll={{ x: 900, scrollToFirstRowOnChange: true }}
          columns={cols}
          dataSource={this.state.list}
          loading={this.state.loading}
          onChange={this.handleTableChange}
          pagination={this.state.pagination}
        />
        <Modal
          title={
            Boolean(this.state.selectedFormListInput) ? (
              <FormattedMessage id={this.state.selectedFormListInput.labelLanguageKey} defaultMessage={this.state.selectedFormListInput.label} />
            ) : (
              ""
            )
          }
          centered
          visible={this.state.showFormListModal}
          onOk={() => this.setState({ showFormListModal: false, selectedFormListValue: null, selectedFormListInput: null })}
          onCancel={() => this.setState({ showFormListModal: false, selectedFormListValue: null, selectedFormListInput: null })}
          cancelButtonProps={{ style: { display: "none" } }}
          width={1000}
        >
          <table className="table table-bordered">
            <thead>
              <tr>
                {Boolean(this.state.selectedFormListInput) &&
                  this.state.selectedFormListInput.formListPageForm.pageFormInputCompanyList
                    .filter(x => {
                      return x.inputType !== "HIDDEN";
                    })
                    .map(item => {
                      return (
                        <th key={item.id}>
                          <FormattedMessage id={item.labelLanguageKey} defaultMessage={item.label} />
                        </th>
                      );
                    })}
              </tr>
            </thead>
            <tbody>
              {Boolean(this.state.selectedFormListValue) &&
                this.state.selectedFormListValue.map(row => {
                  return (
                    <tr key={row.id}>
                      {Boolean(this.state.selectedFormListInput) &&
                        this.state.selectedFormListInput.formListPageForm.pageFormInputCompanyList
                          .filter(x => {
                            return x.inputType !== "HIDDEN";
                          })
                          .map((item, i) => {
                            return <td key={i}>{this.renderColumnValue(item, row[item.inputName], row, i)}</td>;
                          })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Modal>
      </>
    );
  }
}
export default DataTableComponent2;
