import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  RightCircleTwoTone,
  LeftCircleTwoTone,
  UploadOutlined,
  MinusCircleOutlined,
  MinusOutlined,
  ZoomInOutlined,
  FilterOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
  EyeTwoTone,
  EyeInvisibleTwoTone,
  ArrowRightOutlined,
  UndoOutlined
} from "@ant-design/icons";
import { EditOutlined, PictureAsPdf } from "@material-ui/icons";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import CreateIcon from "@material-ui/icons/AddCircle";
import ReactQuill from "react-quill";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import CustomRFQMenu from "../../CustomMenu/CustomRFQMenu";
import FormatListNumberedTwoTone from "@material-ui/icons/FormatListNumberedTwoTone";
import { Carousel, Drawer } from "antd";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  Badge,
  Button as AntButton,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal as AntModal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Upload,
  Skeleton,
  Timeline,
  Typography,
  Cascader,
  Divider,
  Statistic,
  Steps,
  Progress
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { Button, Col as ColBoot, Form as FormBoot, Modal, Row as RowBoot, Table as TableAnt } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { API_BASE_ROOT, handleRequest } from "../../../ApiConnector";
import { error, showError, showSuccess, showWarning } from "../../../MessageHelper";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { PrepareFileUploadEdit } from "../../../Components/ElementUtils";
import RequestForQuotationVendorResponse from "../RequestForQuotationVendorResponse";
import Viewer, { defaultLayout, Worker } from "@phuocng/react-pdf-viewer";
import VendorScoring from "../VendorScoring";
import RfqVendorReply from "../RfqVendorReply";
import VendorSelectionOnTotal from "../VendorSelectionOnTotal";
import "../../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";
import VendorSelectionOnTotalNew from "../VendorSelectionOnTotalNew";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import store from "../../../../redux/store";
import DraggableModal from "../../../Components/DraggableModal";
import RfqAddMoreItems from "./RfqAddMoreItems";

const { TextArea } = Input;
const { Panel } = Collapse;
const { Option } = Select;
const { Step } = Steps;
const { Text } = Typography;

var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0");
var yyyy = today.getFullYear();

today = dd + "/" + mm + "/" + yyyy;

var _splitRows = [
  {
    splitIndex: 1,
    quantity: "",
    date: today
  }
];

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "code"],
    ["clean"]
  ]
};
const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "code"];

//# Columns-Resize
const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate"
      ? form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? moment(record[dataIndex], "DD-MM-YYYY") : ""
      })
      : form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
      });
  };

  const toggleSave = async () => {
    try {
      if (dataIndex === "planedDeliveryDate") {
        const values = await form.validateFields();
        values.planedDeliveryDate = Boolean(values.planedDeliveryDate) ? moment(values.planedDeliveryDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else if (dataIndex === "paymentDate") {
        const values = await form.validateFields();
        values.paymentDate = Boolean(values.paymentDate) ? moment(values.paymentDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record }, { ...values });
      }
    } catch (errInfo) { }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
      >
        {dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate" ? (
          <DatePicker showTime ref={inputRef} onBlur={toggleSave} format={"DD-MM-YYYY HH:mm"} />
        ) : dataIndex === "unit" ? (
          <Cascader style={{ width: "100%" }} options={record.unitSelectItems} ref={inputRef} onChange={toggleSave} onBlur={toggleSave} />
        ) : dataIndex === "currency" ? (
          <Select
            allowClear
            ref={inputRef}
            onChange={toggleSave}
            onBlur={toggleSave}
            style={{ width: "150px" }}
            showSearch
            optionFilterProp="children"
          >
            {Boolean(record.currencySelectItems) &&
              record.currencySelectItems.length > 0 &&
              record.currencySelectItems.map(i => (
                <Option key={i.key} value={i.key}>
                  {i.value}
                </Option>
              ))}
          </Select>
        ) : (
          <InputNumber
            parser={value => value.replace(",", ".")}
            formatter={value => value.replace(".", ",")}
            min={0}
            ref={inputRef}
            onPressEnter={toggleSave}
            onBlur={toggleSave}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (Boolean(node)) {
      var lineage = [];
      if (Boolean(node.lineage) && node.lineage.length > 0) {
        node.lineage.forEach(element => {
          if (!lineage.includes(element)) {
            lineage.push(element);
          }
        });
      }

      dataList.push({
        key: node.value,
        lineage: node.lineage,
        title: node.value
      });
    }
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class RequestForQuotation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      controllerName: "requestForQuotation",
      modalDeleteShow: false,
      modalDeliveryDateShow: false,
      scoringModal: false,
      technicalRequirementChecklistModal: false,
      vendorModal: false,
      showVendorNotRelated: false,
      vendorModalLast: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      showAllItems: false,
      modalDetailShow: false,
      lastUpdateDate: [],
      vendorList: [],
      vendorListNotRelated: [],
      modalPdfViewer: false,
      pdfFile: "",
      typeRevise: false,
      modalHistoryShow: false,
      historyList: [],
      modalStatusShow: false,
      statusList: [],
      showRevisedListModal: false,
      revisedList: [],
      addNewPanel: false,
      dateFormatType: "",
      text: "",
      alteration: "",
      startingWith: "",
      code: "",
      requestForPurchaseId: -1,

      mergeColumnsOurExpectedPriceVisible: false,
      pricePaymentDeliveryOurExpectedPriceVisible: true,
      pricePaymentDeliveryYourOfferForPriceVisible: true,
      goodsAndServiceVisible: true,
      insuranceVisible: true,
      shippingVisible: true,

      id: null,
      vendorLogId: null,
      tableList: [],
      stockTableList: [],
      fileList: [],
      projectTakeOffsSelectItems: [],
      requestForPurchaseSelectItems: [],
      quoteValidityDateTypeSelectItems: [],
      receptionOptionsSelectItems: [],
      transportOptionsSelectItems: [],
      combinedContent: " ",
      markerPosition: { lat: 26.2844367, lng: 50.1888217 },

      generalTerms: [],
      techRequirements: [],
      pricePaymentInvoicing: [],
      paymentTerms: [],
      deliveryPlaces: [],
      taxes: [],
      confidentialities: [],
      inspectionAndAcceptances: [],
      competentCourt: [],
      liabilityInsurances: [],
      intellectualProperties: [],
      contractRecords: [],
      informationPrivacies: [],
      essenceDeliveryDates: [],
      warranties: [],
      languages: [],
      requestedDocuments: [],
      terminations: [],
      documents: [],
      miscellaneous: [],
      technicalRequirementChecklists: [],
      quantityDeliveryDates: [],
      additionsList: [],
      poQuery: [],
      hidecodeLabel: true,

      generalTermsItemCheck: true,
      techRequirementsItemCheck: true,
      pricePaymentInvoicingItemCheck: true,
      paymentTermsItemCheck: true,
      deliveryPlacesItemCheck: true,
      taxesItemCheck: true,
      confidentialitiesItemCheck: true,
      inspectionAndAcceptancesItemCheck: true,
      competentCourtItemCheck: true,
      liabilityInsurancesItemCheck: true,
      intellectualPropertiesItemCheck: true,
      contractRecordsItemCheck: true,
      informationPrivaciesItemCheck: true,
      essenceDeliveryDatesItemCheck: true,
      warrantiesItemCheck: true,
      languagesItemCheck: true,
      requestedDocumentsItemCheck: true,
      terminationsItemCheck: true,
      documentsItemCheck: true,
      miscellaneousItemCheck: true,
      codeTotal: "",

      selectedRowKeysVendorIds: [],
      selectedRowKeysNotRelated: [],
      rfqList: [],
      splitRows: [],
      selectedFixRow: null,
      selectedRFQRow: null,

      ourExpectedPrices: [],
      accordion: false,
      priceOfferVisible: false,
      ourExpectedPriceVisible: false,
      fixDateVisible: false,
      nodes: [],
      companyName: "Project site",
      saveLoading: false,
      user: store.getState().auth.user,

      limitPurchase: 100,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      paginationVendor: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      paginationVendorNotRelated: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      paginationAddMoreRFQ: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      paginationStock: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      paginationDeliveryDate: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      paginationHistory: {
        current: 1,
        pageSize: 25,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      spinLoading: false,
      spinLoadingSendToVendors: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      maxPanelKey: 24,
      unitSelectItems: [],
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  formRefVendor = React.createRef();
  formRefReserve = React.createRef();
  formRefRelease = React.createRef();
  formPoQuery = React.createRef();

  componentDidMount = async () => {
    var activePanelKey = [];
    for (let index = 1; index < this.state.maxPanelKey; index++) {
      activePanelKey.push(index);
    }
    this.setState(
      {
        activePanelKey: activePanelKey
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
        this.fillComboboxes();
        this.checkProjectTakeOffItem();
      }
    );
  };

  checkProjectTakeOffItem = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/checkProjectTakeOffItem");
    if (response.type === "ERROR") {
      error(response);
    }
  };

  getVendor = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhereVendor(params);

    var projectTakeOffId = -1;
    if (Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("projectTakeOffId"))) {
      projectTakeOffId = this.formRef.current.getFieldValue("projectTakeOffId");
    }

    let url = "/api/" + this.state.controllerName + "/vendorPaging/" + projectTakeOffId + "/" + this.state.requestForPurchaseId;
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        vendorList: [],
        paginationVendor: {
          ...params.paginationVendor,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
          element.key = element.id;
        });
        this.setState({
          loading: false,
          vendorList: list,
          paginationVendor: {
            ...params.paginationVendor,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          vendorList: [],
          paginationVendor: {
            ...params.paginationVendor,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  getVendorNotRelated = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhereVendorNotRelated(params);

    var projectTakeOffId = -1;
    if (Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("projectTakeOffId"))) {
      projectTakeOffId = this.formRef.current.getFieldValue("projectTakeOffId");
    }

    let url = "/api/" + this.state.controllerName + "/vendorPagingNotRelated/" + projectTakeOffId + "/" + this.state.requestForPurchaseId;
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        vendorListNotRelated: [],
        paginationVendorNotRelated: {
          ...params.paginationVendorNotRelated,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
          element.key = element.id;
        });
        this.setState({
          loading: false,
          vendorListNotRelated: list,
          paginationVendorNotRelated: {
            ...params.paginationVendorNotRelated,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          vendorListNotRelated: [],
          paginationVendorNotRelated: {
            ...params.paginationVendorNotRelated,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  fillComboboxes = async () => {
    fillFilterOperations();
    this.fillRequestForPurchase();
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/projectTakeOff");
    if (response.type === "ERROR") {
      error(response);
    }

    var responseCurrency = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responseCurrency.type === "ERROR") {
      error(responseCurrency);
    }

    var responsedateFormat = await handleRequest("GET", "/api/" + this.state.controllerName + "/dateFormatType");
    if (responsedateFormat.type === "ERROR") {
      error(responsedateFormat);
    }
    this.setState({
      dateFormatSelect: Boolean(responsedateFormat.data) ? responsedateFormat.data : []
    });

    var responsealteration = await handleRequest("GET", "/api/" + this.state.controllerName + "/alteration");
    if (responsealteration.type === "ERROR") {
      error(responsealteration);
    }
    this.setState({
      alterationSelect: Boolean(responsealteration.data) ? responsealteration.data : []
    });

    var responseValidityDateType = await handleRequest("GET", "/api/" + this.state.controllerName + "/quoteValidityDateType");
    if (responseValidityDateType.type === "ERROR") {
      error(responseValidityDateType);
    }

    var responseReceptionForQuotation = await handleRequest("GET", "/api/" + this.state.controllerName + "/receptionOptions");
    if (responseReceptionForQuotation.type === "ERROR") {
      error(responseReceptionForQuotation);
    }

    var responseTransportOptions = await handleRequest("GET", "/api/" + this.state.controllerName + "/transportOptions");
    if (responseTransportOptions.type === "ERROR") {
      error(responseTransportOptions);
    }

    var nodes = [];
    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/stockTree");
    if (responseTree.type === "ERROR") {
      error(responseTree);
    } else {
      if (Boolean(responseTree.data) && responseTree.data.length > 0) {
        generateList(responseTree.data);
        nodes = responseTree.data;
      }
    }

    var responseCompanyName = await handleRequest("GET", "/api/" + this.state.controllerName + "/getCompanyName");
    if (responseCompanyName.type === "ERROR") {
      error(responseCompanyName);
    }

    var responseAllUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/allUnit");
    if (responseAllUnit.type === "ERROR") {
      error(responseAllUnit);
    }
    var responseitemDescription = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemDescription");
    if (responseitemDescription.type === "ERROR") {
      error(responseitemDescription);
    }

    var responseCompanyProjectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/companyProjectId");
    if (responseCompanyProjectId.type === "ERROR") {
      error(responseCompanyProjectId);
    }
    this.setState({
      projectTakeOffsSelectItems: Boolean(response.data) ? response.data : [],
      quoteValidityDateTypeSelectItems: Boolean(responseValidityDateType.data) ? responseValidityDateType.data : [],
      receptionOptionsSelectItems: Boolean(responseReceptionForQuotation.data) ? responseReceptionForQuotation.data : [],
      itemDescriptionSelectItems: Boolean(responseitemDescription.data) ? responseitemDescription.data : [],
      transportOptionsSelectItems: Boolean(responseTransportOptions.data) ? responseTransportOptions.data : [],
      currencySelectItems: Boolean(responseCurrency.data) ? responseCurrency.data : [],
      companyName: Boolean(responseCompanyName.data) ? "(" + responseCompanyName.data.companyName + ") Project site" : "Project site",
      nodes: nodes,
      allUnitSelectItems: Boolean(responseAllUnit.data) ? responseAllUnit.data : [],
      companyProjectIdSelectItems: Boolean(responseCompanyProjectId.data) ? responseCompanyProjectId.data : []
    });

    var responseNewRequests = await handleRequest("GET", "/api/" + this.state.controllerName + "/newRequests");
    if (responseNewRequests.type === "ERROR") {
      error(responseNewRequests);
    }
    this.setState({
      newRequestsSelectItems: Boolean(responseNewRequests.data) ? responseNewRequests.data : []
    });

    var responseVendors = await handleRequest("GET", "/api/" + this.state.controllerName + "/vendors");
    if (responseVendors.type === "ERROR") {
      error(responseVendors);
    }
    this.setState({
      vendorsSelectItems: Boolean(responseVendors.data) ? responseVendors.data : []
    });

    var responseVendorsAdress = await handleRequest("GET", "/api/" + this.state.controllerName + "/vendorsAdress");
    if (responseVendorsAdress.type === "ERROR") {
      error(responseVendorsAdress);
    }
    this.setState({
      vendorsAdressSelectItems: Boolean(responseVendorsAdress.data) ? responseVendorsAdress.data : []
    });
  };

  fillRequestForPurchase = async () => {
    var responseRequestForPurchase = await handleRequest("GET", "/api/" + this.state.controllerName + "/requestForPurchase/" + this.state.limitPurchase);
    if (responseRequestForPurchase.type === "ERROR") {
      error(responseRequestForPurchase);
    }
    this.setState({
      requestForPurchaseSelectItems: Boolean(responseRequestForPurchase.data) ? responseRequestForPurchase.data : [],
    });
  }

  detailModal = async row => {
    console.log("row", row);
    var itemType = null;
    var projectTakeOffId = null;
    if (Boolean(row.projectTakeOffId)) {
      itemType = "ProjectTakeOffQuantity";
      projectTakeOffId = row.projectTakeOffId;
    } else if (Boolean(row.inventoryLogId)) {
      itemType = "InventoryLog";
      projectTakeOffId = row.inventoryLogId;
    }

    this.getPricesData(projectTakeOffId, itemType, row.id, row.requestForPurchaseId);

    this.setState({
      modalDetailShow: true
    });
  };

  getLastRfq = async () => {
    let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/lastRfq");
    if (response.type === "ERROR") {
      error(response);
    } else {
      let row = response.data;
      if (Boolean(row) && Boolean(row.id)) {
        row.projectTakeOffId = null;
        row.requestForPurchaseId = null;
        let { newItem, newItemState } = this.prepareAttributes(row, true, true, null);

        this.formRef.current.setFieldsValue({
          ...row,
          ...newItem
        });

        var ourExpectedPriceVisible = false;
        if (Boolean(row.pricePaymentInvoicing) && row.pricePaymentInvoicing.length > 0) {
          var ourExpectedPrice = row.pricePaymentInvoicing.find(p => p.check === true && p.description === "Our expected price");
          if (Boolean(ourExpectedPrice)) {
            ourExpectedPriceVisible = true;
          }
        }

        var fixDateVisible = false;
        if (Boolean(row.essenceDeliveryDates) && row.essenceDeliveryDates.length > 0) {
          var ourExpectedPrice = row.essenceDeliveryDates.find(p => p.check === true && p.description === "Fix Date");
          if (Boolean(ourExpectedPrice)) {
            fixDateVisible = true;
          }
        }

        var priceOfferVisible = false;
        if (Boolean(row.pricePaymentInvoicing) && row.pricePaymentInvoicing.length > 0) {
          var ourExpectedPrice = row.pricePaymentInvoicing.find(p => p.check === true && p.description === "Your offer for price");
          if (Boolean(ourExpectedPrice)) {
            priceOfferVisible = true;
          }
        }

        this.setState({
          ...newItemState,
          selectedRow: row,
          priceOfferVisible: priceOfferVisible,
          ourExpectedPriceVisible: ourExpectedPriceVisible,
          fixDateVisible: fixDateVisible
        });
      }
    }

    this.setState({
      accordion: true
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  cancelVendor = () => {
    this.setState({
      showVendorLogModal: false,
      hideSaveVendor: false,
      hideUpdateVendor: true
    });
    this.resetInputsVendor();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      typeRevise: false,
      showAllItems: false,
      addNewPanel: false,
      selectedRow: null,
      accordion: false,
      priceOfferVisible: false,
      ourExpectedPriceVisible: false,
      fixDateVisible: false,

      markerPosition: { lat: 26.2844367, lng: 50.1888217 },

      generalTerms: [],
      techRequirements: [],
      pricePaymentInvoicing: [],
      paymentTerms: [],
      deliveryPlaces: [],
      taxes: [],
      confidentialities: [],
      inspectionAndAcceptances: [],
      competentCourt: [],
      liabilityInsurances: [],
      intellectualProperties: [],
      contractRecords: [],
      informationPrivacies: [],
      essenceDeliveryDates: [],
      warranties: [],
      languages: [],
      requestedDocuments: [],
      terminations: [],
      documents: [],
      miscellaneous: [],
      technicalRequirementChecklists: [],
      quantityDeliveryDates: [],

      selectedRowKeysVendorIds: [],
      selectedRowKeysNotRelated: [],
      rfqList: [],
      splitRows: [],
      selectedFixRow: null,
      activePanelKey: [],

      ourExpectedPrices: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      paginationVendor: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      paginationVendorNotRelated: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      paginationAddMoreRFQ: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      paginationStock: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      paginationDeliveryDate: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      fileList: []
    });
  };

  resetInputsVendor = () => {
    if (Boolean(this.formRefVendor) && Boolean(this.formRefVendor.current)) {
      this.formRefVendor.current.resetFields();
    }
    this.setState({
      vendorLogId: null
    });
  };

  updateCodeField = () => {
    let combinedContent = "";
    const { dateFormatType, text, alteration, startingWith } = this.state;
    const codeValue = `${dateFormatType} ${text} ${alteration} ${startingWith}`;

    this.setState({ code: codeValue, hidecodeLabel: false });

    this.setState({ codeTotal: this.state.code });
    combinedContent += this.state.codeTotal || "";
  };

  handleDateFormatTypeChange = value => {
    this.setState({ dateFormatType: value });
  };

  handleTextChange = e => {
    this.setState({ text: e.target.value });
  };

  handleAlterationChange = value => {
    this.setState({ alteration: value });
  };

  handleStartingWithChange = e => {
    this.setState({ startingWith: e.target.value });
  };

  handleArrowRightClick = () => {
    // Update the code field
    this.updateCodeField();
  };

  edit = (typeRevise, row) => {
    document.getElementById("kt_scrolltop").click();
    let { newItem, newItemState } = this.prepareAttributes(row, true, true, null, true);
    newItem.projectTakeOffListString = Boolean(row.projectTakeOffList) ? row.projectTakeOffList.description : "";
    newItem.requestForPurchaseName = row.requestForPurchaseName;

    var itemType = null;
    var rfqType = "ProjectTakeOff";
    var projectTakeOffId = null;
    if (Boolean(row.projectTakeOffId)) {
      itemType = "ProjectTakeOffQuantity";
      projectTakeOffId = row.projectTakeOffId;
    } else if (Boolean(row.inventoryLogId)) {
      itemType = "InventoryLog";
      projectTakeOffId = row.inventoryLogId;
    } else if (Boolean(row.requestForPurchaseId)) {
      rfqType = "RequestForPurchase";
    }

    this.getPricesData(projectTakeOffId, itemType, row.id, row.requestForPurchaseId);

    var parent = dataList.find(p => p.key === row.stockId);
    let stockId = [];
    if (Boolean(parent)) {
      stockId = parent.lineage;
    }
    this.formRef.current.setFieldsValue({
      ...row,
      ...newItem,
      projectTakeOffId: projectTakeOffId,
      requestForPurchaseId: row.requestForPurchaseId,
      stockId: stockId,
      rfqType: rfqType
    });

    var ourExpectedPriceVisible = false;
    if (Boolean(row.pricePaymentInvoicing) && row.pricePaymentInvoicing.length > 0) {
      var ourExpectedPrice = row.pricePaymentInvoicing.find(p => p.check === true && p.description === "Our expected price");
      if (Boolean(ourExpectedPrice)) {
        ourExpectedPriceVisible = true;
      }
    }

    var fixDateVisible = false;
    if (Boolean(row.essenceDeliveryDates) && row.essenceDeliveryDates.length > 0) {
      var ourExpectedPrice = row.essenceDeliveryDates.find(p => p.check === true && p.description === "Fix Date");
      if (Boolean(ourExpectedPrice)) {
        fixDateVisible = true;
      }
    }

    var priceOfferVisible = false;
    if (Boolean(row.pricePaymentInvoicing) && row.pricePaymentInvoicing.length > 0) {
      var ourExpectedPrice = row.pricePaymentInvoicing.find(p => p.check === true && p.description === "Your offer for price");
      if (Boolean(ourExpectedPrice)) {
        priceOfferVisible = true;
      }
    }

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      ...newItemState,
      typeRevise: typeRevise,
      selectedRow: row,
      itemType: itemType,
      rfqType: rfqType,
      priceOfferVisible: priceOfferVisible,
      ourExpectedPriceVisible: ourExpectedPriceVisible,
      fixDateVisible: fixDateVisible,
      accordion: true,
      activePanelKey: [],
      vendorIds: row.vendorIds,
      projectTakeOffId: projectTakeOffId,
      mergeColumnsOurExpectedPriceVisible: true,
      requestForPurchaseId: row.requestForPurchaseId,
    });
  };

  editVendor = row => {
    this.setState(
      {
        showVendorLogModal: true,
        hideUpdateVendor: false,
        hideSaveVendor: true,
        vendorLogId: row.id
      },
      () => {
        if (Boolean(this.formRefVendor) && Boolean(this.formRefVendor.current)) {
          var emails = "";
          var address = "";
          if (Boolean(row.contactList) && row.contactList.length > 0) {
            row.contactList.forEach(element => {
              if (Boolean(element.emails)) {
                emails += element.emails + ", ";
              }
              if (Boolean(element.address)) {
                address += element.address + ", ";
              }
            });
          }
          if (Boolean(emails)) {
            emails = emails.substring(0, emails.length - 2);
          }
          if (Boolean(address)) {
            address = address.substring(0, address.length - 2);
          }
          this.formRefVendor.current.setFieldsValue({
            ...row,
            emails: emails,
            address: address
          });
        }
      }
    );
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  sendToVendorsModal = row => {
    if (Boolean(row.vendorIds) && row.vendorIds.length > 0) {
      var itemType = null;
      var projectTakeOffId = null;
      if (Boolean(row.projectTakeOffId)) {
        itemType = "ProjectTakeOffQuantity";
        projectTakeOffId = row.projectTakeOffId;
      } else if (Boolean(row.inventoryLogId)) {
        itemType = "InventoryLog";
        projectTakeOffId = row.inventoryLogId;
      }
      this.setState({
        selectedRFQRow: row,
        modalSendToVendorsShow: true,
        id: row.id,
        itemType: itemType
      });

      this.formRef.current.setFieldsValue({
        projectTakeOffId: projectTakeOffId
      });
    } else {
      showError("Vendors not identified");
    }
  };

  sendToVendors = async () => {
    if (Boolean(this.state.selectedRFQRow) && Boolean(this.state.selectedRFQRow.vendorIds) && this.state.selectedRFQRow.vendorIds.length > 0) {
      var selectedRowKeysVendorIds = [];
      this.state.selectedRFQRow.vendorIds.forEach(element => {
        if (Boolean(element.vendorId)) {
          selectedRowKeysVendorIds.push(element.vendorId);
        }
      });
      this.setState(
        {
          modalSendToVendorsShow: false,
          vendorModalLast: true,
          vendorModal: true,
          selectedRowKeysVendorIds: selectedRowKeysVendorIds,
          selectedVendorNotRelatedIds: selectedRowKeysVendorIds
        },
        () => {
          const { paginationVendor } = this.state;
          this.getVendor({ paginationVendor });
        }
      );
    }
  };

  updateSendToVendors = async () => {
    this.setState({ spinLoadingSendToVendors: true });
    if (Boolean(this.state.selectedRFQRow) && Boolean(this.state.selectedRFQRow.id) && this.state.selectedRFQRow.id > 0) {
      var vendorIds = [];
      if (Boolean(this.state.selectedRowKeysVendorIds) && this.state.selectedRowKeysVendorIds.length > 0) {
        this.state.selectedRowKeysVendorIds.forEach(element => {
          vendorIds.push({
            vendorId: element
          });
        });
      }
      var newItem = {
        id: this.state.selectedRFQRow.id,
        vendorIds: vendorIds
      };
      var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateSendToVendors/" + newItem.id, newItem);
      if (response.type === "ERROR") {
        error(response);
        this.setState({ spinLoadingSendToVendors: false });
      } else {
        this.setState({
          vendorModalLast: false,
          vendorModal: false,
          spinLoadingSendToVendors: false,
          modalAddMorRFQShow: true
        });
      }
    } else {
      showError("Please select RFQ");
      this.setState({
        spinLoadingSendToVendors: false
      });
    }
  };

  updateSendToVendorsMoreRFQ = async () => {
    if (Boolean(this.state.selectedRowKeysAddMoreRFQ) && this.state.selectedRowKeysAddMoreRFQ.length > 0) {
      var vendorIds = [];
      if (Boolean(this.state.selectedRowKeysVendorIds) && this.state.selectedRowKeysVendorIds.length > 0) {
        this.state.selectedRowKeysVendorIds.forEach(element => {
          vendorIds.push({
            vendorId: element
          });
        });
      }
      var newItem = {
        vendorIds: vendorIds
      };
      var response = await handleRequest(
        "PUT",
        "/api/" + this.state.controllerName + "/updateSendToVendorsMoreRFQ/" + this.state.selectedRowKeysAddMoreRFQ,
        newItem
      );
      if (response.type === "ERROR") {
        error(response);
        this.setState({ spinLoading: false });
      } else {
        const { pagination } = this.state;
        this.restartTable({ pagination });
        this.setState({
          vendorModalLast: false,
          spinLoading: false,
          addMoreRFQShow: false,
          modalAddMorRFQShow: false
        });
      }
    } else {
      showError("Please select RFQ");
    }
  };

  addMoreRFQConfirm = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhereAddMoreRFQ(params);
    newObj.id = this.state.selectedRFQRow.id;
    let url = "/api/" + this.state.controllerName + "/getMoreRFQ";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        addMoreRFQList: [],
        modalAddMorRFQShow: false,
        addMoreRFQShow: true,
        paginationAddMoreRFQ: {
          ...params.paginationAddMoreRFQ,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          addMoreRFQList: response.data.data,
          modalAddMorRFQShow: false,
          addMoreRFQShow: true,
          paginationAddMoreRFQ: {
            ...params.paginationAddMoreRFQ,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          addMoreRFQList: [],
          modalAddMorRFQShow: false,
          addMoreRFQShow: true,
          paginationAddMoreRFQ: {
            ...params.paginationAddMoreRFQ,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  showVendorResponsesModal = row => {
    this.setState({
      showVendorResponsesModal: true,
      rfqRow: { ...row },
      id: row.id
    });
  };

  showScoringModal = row => {
    this.setState({
      showVendorScoringModal: true,
      rfqRow: { ...row }
    });
  };

  showReplyModal = row => {
    this.setState({
      showVendorReplyModal: true,
      rfqRow: { ...row }
    });
  };

  evaluationModal = row => {
    this.setState({
      showEvaluationModal: true,
      rfqRow: { ...row }
    });
  };

  evaluationNewModal = row => {
    this.setState({
      showEvaluationNewModal: true,
      rfqRow: { ...row }
    });
  };

  closeTenderingModal = row => {
    this.setState({
      modalCloseTenderingShow: true,
      id: row.id
    });
  };

  closeTendering = async () => {
    let response = await handleRequest("PATCH", `/api/${this.state.controllerName}/closeTendering/${this.state.id}`);
    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      this.setState({
        id: null,
        modalCloseTenderingShow: false
      });
    }
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      this.fillComboboxes();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  handleTableChangeVendor = (paginationVendor, filters, sorter) => {
    this.getVendor({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      paginationVendor,
      filters
    });
  };

  handleTableChangeVendorNotRelated = (paginationVendorNotRelated, filters, sorter) => {
    this.getVendorNotRelated({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      paginationVendorNotRelated,
      filters
    });
  };

  handleTableChangeAddMoreRFQ = (paginationAddMoreRFQ, filters, sorter) => {
    this.addMoreRFQConfirm({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      paginationAddMoreRFQ,
      filters
    });
  };

  handleTableChangeStock = (paginationStock, filters, sorter) => {
    this.restartTableStock({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      paginationStock,
      filters
    });
  };

  // handleTableChangeDeliveryDate = (paginationDeliveryDate, filters, sorter) => {
  //   this.restartTable({
  //     sortField: sorter.columnKey,
  //     sortOrder: sorter.order,
  //     paginationDeliveryDate,
  //     filters
  //   });
  // };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  restartTableRevised = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    newObj.requestForQuotationId = this.state.modalRequestForQuotationId;
    let url = "/api/" + this.state.controllerName + "/pagingRevised";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        revisedList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          revisedList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          revisedList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  restartTableStock = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhereStock(params);
    let url = "/api/" + this.state.controllerName + "/pagingStock";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        stockTableList: [],
        paginationStock: {
          ...params.paginationStock,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        let list = response.data;
        list.forEach((element, index) => {
          element.index = index + 1;
          element.requiredQuantity = this.state.requiredQuantity;
        });
        this.setState({
          loading: false,
          stockTableList: list,
          paginationStock: {
            ...params.paginationStock,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          stockTableList: [],
          paginationStock: {
            ...params.paginationStock,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = lastRfq => {
    this.resetInputs();
    if (this.state.hideInputs) {
      if (lastRfq) {
        this.getLastRfq();
      }
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  getCodesBackend(poQuery) {
    let codes = "";
    if (Boolean(poQuery) && poQuery) {
      poQuery.forEach(element => {
        if (Boolean(element.poQuery) && element.poQuery) {
          element.poQuery.forEach(code => {
            if (Boolean(code.dateFormatType) && code.dateFormatType > 0) {
              var dateFormat = this.state["dateFormatSelect"].find(p => p.id === code.dateFormat);
              if (Boolean(dateFormat)) {
                codes += dateFormat.name + " ";
              }
            }
            if (Boolean(code.text)) {
              codes += code.text + " ";
            }
            if (Boolean(code.alteration) && code.alteration > 0) {
              var alteration = this.state["alterationSelect"].find(p => p.id === code.alteration);
              if (Boolean(alteration)) {
                codes += alteration.name + " ";
              }
            }
          });
          codes += "<br />";
        }
      });
    }
    return codes;
  }

  onClickCreateNewVendor = () => {
    this.resetInputsVendor();
    this.setState({
      showVendorLogModal: true,
      hideSaveVendor: false,
      hideUpdateVendor: true
    });
  };

  save = async values => {
    this.setState({ saveLoading: true });
    var generalTerms = [];
    var dataGeneralTerms =
      Boolean(values.generalTerms) && values.generalTerms.length > 0 ? values.generalTerms.filter(p => Boolean(p.description)) : null;
    if (Boolean(dataGeneralTerms) && dataGeneralTerms.length > 0) {
      generalTerms.push({ check: true, description: "General Terms and Conditions", score: 0, weight: 0 });
      this.setDynamicObj(dataGeneralTerms, generalTerms);
    }

    let generalTermsFileUrl = [];
    if (Boolean(values.generalTermsFileUrl)) {
      values.generalTermsFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) generalTermsFileUrl.push(item.response.url);
        else if (Boolean(item.url)) generalTermsFileUrl.push(item.url);
      });
    }

    let attachmentsFileUrl = [];
    if (Boolean(values.attachmentsFileUrl)) {
      values.attachmentsFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) attachmentsFileUrl.push(item.response.url);
        else if (Boolean(item.url)) attachmentsFileUrl.push(item.url);
      });
    }

    var techRequirements = [];
    var dataTechRequirements =
      Boolean(values.techRequirements) && values.techRequirements.length > 0 ? values.techRequirements.filter(p => Boolean(p.description)) : null;
    if (Boolean(dataTechRequirements) && dataTechRequirements.length > 0) {
      techRequirements.push({ check: true, description: "Technical Requirements Checklist", score: 0, weight: 0 });
      this.setDynamicObj(dataTechRequirements, techRequirements);
    }

    let techRequirementsFileUrl = [];
    if (Boolean(values.techRequirementsFileUrl)) {
      values.techRequirementsFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) techRequirementsFileUrl.push(item.response.url);
        else if (Boolean(item.url)) techRequirementsFileUrl.push(item.url);
      });
    }

    let techRequirementsAddPr = [];
    if (Boolean(values.techRequirementsAddPr)) {
      values.techRequirementsAddPr.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) techRequirementsAddPr.push(item.response.url);
        else if (Boolean(item.url)) techRequirementsAddPr.push(item.url);
      });
    }

    var pricePaymentInvoicing = [];
    var dataPricePaymentInvoicing =
      Boolean(values.pricePaymentInvoicing) && values.pricePaymentInvoicing.length > 0
        ? values.pricePaymentInvoicing.filter(p => Boolean(p.description))
        : null;
    if (Boolean(dataPricePaymentInvoicing) && dataPricePaymentInvoicing.length > 0) {
      pricePaymentInvoicing.push({ check: true, description: "Price, payment and invoicing", score: 0, weight: 0 });
      this.setDynamicObj(dataPricePaymentInvoicing, pricePaymentInvoicing);
    } else {
      if (
        (Boolean(values.priceOffer) && values.priceOffer === true) ||
        (Boolean(values.goodsAndService) && values.goodsAndService === true) ||
        (Boolean(values.insurance) && values.insurance === true) ||
        (Boolean(values.shipping) && values.shipping === true) ||
        (Boolean(values.ourExpectedPrice) && values.ourExpectedPrice === true)
      ) {
        pricePaymentInvoicing.push({ check: true, description: "Price, payment and invoicing", score: 0, weight: 0 });
      }
    }

    if (Boolean(values.priceOffer) && values.priceOffer === true) {
      pricePaymentInvoicing.push({ ["check"]: true, ["description"]: "Your offer for price", score: 0, weight: 0 });
    }

    if (Boolean(values.goodsAndService) && values.goodsAndService === true) {
      pricePaymentInvoicing.push({ ["check"]: true, ["description"]: "Goods and Service", score: 0, weight: 0 });
    }

    if (Boolean(values.insurance) && values.insurance === true) {
      pricePaymentInvoicing.push({ ["check"]: true, ["description"]: "Insurance", score: 0, weight: 0 });
    }

    if (Boolean(values.shipping) && values.shipping === true) {
      pricePaymentInvoicing.push({ ["check"]: true, ["description"]: "Shipping", score: 0, weight: 0 });
    }

    if (Boolean(values.ourExpectedPrice) && values.ourExpectedPrice === true) {
      pricePaymentInvoicing.push({ ["check"]: true, ["description"]: "Our expected price", score: 0, weight: 0 });
    }

    var paymentTerms = [];
    var dataPaymentTerms =
      Boolean(values.paymentTerms) && values.paymentTerms.length > 0 ? values.paymentTerms.filter(p => Boolean(p.description)) : null;
    if (Boolean(dataPaymentTerms) && dataPaymentTerms.length > 0) {
      paymentTerms.push({ check: true, description: "Payment Terms", score: 0, weight: 0 });
      this.setDynamicObj(dataPaymentTerms, paymentTerms);
    } else {
      if (Boolean(values.termsForPayment) && values.termsForPayment === true) {
        paymentTerms.push({ check: true, description: "Payment Terms", score: 0, weight: 0 });
      }
    }

    if (Boolean(values.termsForPayment) && values.termsForPayment === true) {
      paymentTerms.push({ ["check"]: true, ["description"]: "Your terms for payment", score: 0, weight: 0 });
    }

    var deliveryPlaces = [];
    var dataDeliveryPlaces =
      Boolean(values.deliveryPlaces) && values.deliveryPlaces.length > 0 ? values.deliveryPlaces.filter(p => Boolean(p.description)) : null;
    if (Boolean(dataDeliveryPlaces) && dataDeliveryPlaces.length > 0) {
      deliveryPlaces.push({ check: true, description: "Delivery Place", score: 0, weight: 0 });
      this.setDynamicObj(dataDeliveryPlaces, deliveryPlaces);
    } else {
      if (
        (Boolean(values.projectSite) && values.projectSite === true) ||
        (Boolean(values.manufacturingPlace) && values.manufacturingPlace === true)
      ) {
        deliveryPlaces.push({ check: true, description: "Delivery Place", score: 0, weight: 0 });
      }
    }

    if (Boolean(values.projectSite) && values.projectSite === true) {
      deliveryPlaces.push({ ["check"]: true, ["description"]: "Project site", score: 0, weight: 0 });
    }

    if (Boolean(values.manufacturingPlace) && values.manufacturingPlace === true) {
      deliveryPlaces.push({ ["check"]: true, ["description"]: "Your manufacturing place", score: 0, weight: 0 });
    }

    var deliveryPlaceLat = Boolean(this.state.markerPosition) ? this.state.markerPosition.lat : "";
    var deliveryPlaceLng = Boolean(this.state.markerPosition) ? this.state.markerPosition.lng : "";

    var taxes = [];
    var dataTaxes = Boolean(values.taxes) && values.taxes.length > 0 ? values.taxes.filter(p => Boolean(p.description)) : null;
    if (Boolean(dataTaxes) && dataTaxes.length > 0) {
      taxes.push({ check: true, description: "Taxes", score: 0, weight: 0 });
      this.setDynamicObj(dataTaxes, taxes);
    }

    var essenceDeliveryDates = [];
    var dataEssenceDeliveryDates =
      Boolean(values.essenceDeliveryDates) && values.essenceDeliveryDates.length > 0
        ? values.essenceDeliveryDates.filter(p => Boolean(p.description))
        : null;
    if (Boolean(dataEssenceDeliveryDates) && dataEssenceDeliveryDates.length > 0) {
      essenceDeliveryDates.push({ check: true, description: "Time of essence/Delivery dates", score: 0, weight: 0 });
      this.setDynamicObj(dataEssenceDeliveryDates, essenceDeliveryDates);
    } else {
      if (
        (Boolean(values.fixDate) && values.fixDate === true) ||
        (Boolean(values.proposalDeliveryDate) && values.proposalDeliveryDate === true) ||
        (Boolean(values.mutualNegotiations) && values.mutualNegotiations === true)
      ) {
        essenceDeliveryDates.push({ check: true, description: "Time of essence/Delivery dates", score: 0, weight: 0 });
      }
    }

    if (Boolean(values.fixDate) && values.fixDate === true) {
      essenceDeliveryDates.push({ ["check"]: true, ["description"]: "Fix Date", score: 0, weight: 0 });
    }

    if (Boolean(values.proposalDeliveryDate) && values.proposalDeliveryDate === true) {
      essenceDeliveryDates.push({ ["check"]: true, ["description"]: "Your proposal of delivery date", score: 0, weight: 0 });
    }

    if (Boolean(values.mutualNegotiations) && values.mutualNegotiations === true) {
      essenceDeliveryDates.push({ ["check"]: true, ["description"]: "To be decided by mutual negotiations", score: 0, weight: 0 });
    }

    var warranties = [];
    var dataWarranties = Boolean(values.warranties) && values.warranties.length > 0 ? values.warranties.filter(p => Boolean(p.description)) : null;
    if (Boolean(dataWarranties) && dataWarranties.length > 0) {
      warranties.push({ check: true, description: "Warranty", score: 0, weight: 0 });
      this.setDynamicObj(dataWarranties, warranties);
    }

    var languages = [];
    var dataLanguages = Boolean(values.languages) && values.languages.length > 0 ? values.languages.filter(p => Boolean(p.description)) : null;
    if (Boolean(dataLanguages) && dataLanguages.length > 0) {
      languages.push({ check: true, description: "Language", score: 0, weight: 0 });
      this.setDynamicObj(dataLanguages, languages);
    }

    var requestedDocuments = [];
    var dataRequestedDocuments =
      Boolean(values.requestedDocuments) && values.requestedDocuments.length > 0
        ? values.requestedDocuments.filter(p => Boolean(p.description))
        : null;
    if (Boolean(dataRequestedDocuments) && dataRequestedDocuments.length > 0) {
      requestedDocuments.push({ check: true, description: "Requested documents", score: 0, weight: 0 });
      this.setDynamicObj(dataRequestedDocuments, requestedDocuments);
    }

    var terminations = [];
    var dataTerminations =
      Boolean(values.terminations) && values.terminations.length > 0 ? values.terminations.filter(p => Boolean(p.description)) : null;
    if (Boolean(dataTerminations) && dataTerminations.length > 0) {
      terminations.push({ check: true, description: "Termination", score: 0, weight: 0 });
      this.setDynamicObj(dataTerminations, terminations);
    }

    var confidentialities = [];
    var dataConfidentialities =
      Boolean(values.confidentialities) && values.confidentialities.length > 0 ? values.confidentialities.filter(p => Boolean(p.description)) : null;
    if (Boolean(dataConfidentialities) && dataConfidentialities.length > 0) {
      confidentialities.push({ check: true, description: "Confidentiality, privacy and publicity", score: 0, weight: 0 });
      this.setDynamicObj(dataConfidentialities, confidentialities);
    }

    var inspectionAndAcceptances = [];
    var dataInspectionAndAcceptances =
      Boolean(values.inspectionAndAcceptances) && values.inspectionAndAcceptances.length > 0
        ? values.inspectionAndAcceptances.filter(p => Boolean(p.description))
        : null;
    if (Boolean(dataInspectionAndAcceptances) && dataInspectionAndAcceptances.length > 0) {
      inspectionAndAcceptances.push({ check: true, description: "Inspection and acceptance", score: 0, weight: 0 });
      this.setDynamicObj(dataInspectionAndAcceptances, inspectionAndAcceptances);
    }

    var competentCourt = [];
    var dataCompetentCourt =
      Boolean(values.competentCourt) && values.competentCourt.length > 0 ? values.competentCourt.filter(p => Boolean(p.description)) : null;
    if (Boolean(dataCompetentCourt) && dataCompetentCourt.length > 0) {
      competentCourt.push({ check: true, description: "Governing law and competent court", score: 0, weight: 0 });
      this.setDynamicObj(dataCompetentCourt, competentCourt);
    }

    var liabilityInsurances = [];
    var dataLiabilityInsurances =
      Boolean(values.liabilityInsurances) && values.liabilityInsurances.length > 0
        ? values.liabilityInsurances.filter(p => Boolean(p.description))
        : null;
    if (Boolean(dataLiabilityInsurances) && dataLiabilityInsurances.length > 0) {
      liabilityInsurances.push({ check: true, description: "Liability and insurance", score: 0, weight: 0 });
      this.setDynamicObj(dataLiabilityInsurances, liabilityInsurances);
    }

    var intellectualProperties = [];
    var dataIntellectualProperties =
      Boolean(values.intellectualProperties) && values.intellectualProperties.length > 0
        ? values.intellectualProperties.filter(p => Boolean(p.description))
        : null;
    if (Boolean(dataIntellectualProperties) && dataIntellectualProperties.length > 0) {
      intellectualProperties.push({ check: true, description: "Intellectual property", score: 0, weight: 0 });
      this.setDynamicObj(dataIntellectualProperties, intellectualProperties);
    }

    var contractRecords = [];
    var dataContractRecords =
      Boolean(values.contractRecords) && values.contractRecords.length > 0 ? values.contractRecords.filter(p => Boolean(p.description)) : null;
    if (Boolean(dataContractRecords) && dataContractRecords.length > 0) {
      contractRecords.push({ check: true, description: "Contract records", score: 0, weight: 0 });
      this.setDynamicObj(dataContractRecords, contractRecords);
    }

    var informationPrivacies = [];
    var dataInformationPrivacies =
      Boolean(values.informationPrivacies) && values.informationPrivacies.length > 0
        ? values.informationPrivacies.filter(p => Boolean(p.description))
        : null;
    if (Boolean(dataInformationPrivacies) && dataInformationPrivacies.length > 0) {
      informationPrivacies.push({ check: true, description: "Freedom of information and privacy", score: 0, weight: 0 });
      this.setDynamicObj(dataInformationPrivacies, informationPrivacies);
    }

    var documents = [];
    var dataDocuments = Boolean(values.documents) && values.documents.length > 0 ? values.documents.filter(p => Boolean(p.description)) : null;
    if (Boolean(dataDocuments) && dataDocuments.length > 0) {
      documents.push({ check: true, description: "Documents", score: 0, weight: 0 });
      this.setDynamicObj(dataDocuments, documents);
    }

    var miscellaneous = [];
    var dataMiscellaneous =
      Boolean(values.miscellaneous) && values.miscellaneous.length > 0 ? values.miscellaneous.filter(p => Boolean(p.description)) : null;
    if (Boolean(dataMiscellaneous) && dataMiscellaneous.length > 0) {
      miscellaneous.push({ check: true, description: "Miscellaneous", score: 0, weight: 0 });
      this.setDynamicObj(dataMiscellaneous, miscellaneous);
    }

    var aList = [];
    if (this.state.additionsList.length > 0) {
      this.state.additionsList.forEach(element => {
        let objList = [];
        var data = element.rfqModelList;
        if (Boolean(data) && data.length > 0) {
          objList.push({ check: true, description: element.description, score: 0, weight: 0 });
          data.forEach(e => {
            objList.push({
              ...element,
              check: e.check,
              description: e.description,
              score: e.score ? e.score : 0,
              weight: e.weight ? e.weight : 0,
              mandatory: e.mandatory ? e.mandatory : false
            });
          });
          aList.push({ check: true, description: element.description, rfqModelList: objList, score: 0, weight: 0 });
        }
      });
    }

    var quantity = 0;
    var code = "";
    var description = "";
    var deliveryDate = null;
    var projectTakeOffId = null;
    var inventoryLogId = null;
    if (Boolean(this.state.itemType) && this.state.itemType === "ProjectTakeOffQuantity") {
      projectTakeOffId = values.projectTakeOffId;
    } else if (Boolean(this.state.itemType) && this.state.itemType === "InventoryLog") {
      inventoryLogId = values.projectTakeOffId;
    }

    var ourExpectedPrices = [];
    ourExpectedPrices = this.state.ourExpectedPrices;
    if (Boolean(ourExpectedPrices) && ourExpectedPrices.length > 0) {
      ourExpectedPrices.forEach(element => {
        if (Boolean(element.planedDeliveryDate)) {
          element.planedDeliveryDate = moment(element.planedDeliveryDate, "DD-MM-YYYY");
        }
        if (Boolean(element.paymentDate)) {
          element.paymentDate = moment(element.paymentDate, "DD-MM-YYYY");
        }
      });
    }

    var vendorIds = [];
    if (Boolean(this.state.vendorIds) && this.state.vendorIds.length > 0) {
      this.state.vendorIds.forEach(element => {
        if (!vendorIds.includes(element)) {
          vendorIds.push(element);
        }
      });
    }

    const newItem = {
      ...values,
      id: this.state.id,
      projectTakeOffId: projectTakeOffId,
      inventoryLogId: inventoryLogId,
      generalTerms: generalTerms,
      generalTermsFileUrl: generalTermsFileUrl.toString(),
      attachmentsFileUrl: attachmentsFileUrl.toString(),
      techRequirements: techRequirements,
      techRequirementsFileUrl: techRequirementsFileUrl.toString(),
      techRequirementsAddPr: techRequirementsAddPr.toString(),
      pricePaymentInvoicing: pricePaymentInvoicing,
      paymentTerms: paymentTerms,
      deliveryPlaces: deliveryPlaces,
      taxes: taxes,
      deliveryPlaceLat: deliveryPlaceLat,
      deliveryPlaceLng: deliveryPlaceLng,
      essenceDeliveryDates: essenceDeliveryDates,
      warranties: warranties,
      languages: languages,
      requestedDocuments: requestedDocuments,
      terminations: terminations,
      confidentialities: confidentialities,
      inspectionAndAcceptances: inspectionAndAcceptances,
      competentCourt: competentCourt,
      liabilityInsurances: liabilityInsurances,
      intellectualProperties: intellectualProperties,
      contractRecords: contractRecords,
      informationPrivacies: informationPrivacies,
      documents: documents,
      miscellaneous: miscellaneous,
      quantity: quantity,
      code: code,
      description: description,
      deliveryDate: deliveryDate,
      additionsList: aList,
      ourExpectedPrices: ourExpectedPrices.filter(p => p.active),
      vendorIds: vendorIds
    };
    if (Boolean(values.stockId) && values.stockId.length > 0) {
      newItem.stockId = Boolean(values.stockId) && values.stockId.length > 0 ? values.stockId[values.stockId.length - 1] : null;
    } else if (Boolean(values.stockId) && values.stockId > 0) {
      newItem.stockId = values.stockId;
    }

    let errorMessage = "";
    errorMessage = this.requiredMethod(newItem, errorMessage);

    if (Boolean(errorMessage)) {
      errorMessage = errorMessage.substring(0, errorMessage.length - 2) + " required";
      showError(errorMessage);
      this.setState({ saveLoading: false });
    } else {
      this.setState(
        {
          saveLoading: false,
          rfqList: [newItem]
        },
        () => {
          this.saveScoring();
        }
      );
    }
  };

  saveRFQ = async () => {
    if (Boolean(this.state.rfqList) && this.state.rfqList.length > 0) {
      this.setState({ spinLoading: true });
      var newItem = this.state.rfqList[0];
      var vendorIds = [];
      if (Boolean(this.state.selectedRowKeysVendorIds) && this.state.selectedRowKeysVendorIds.length > 0) {
        this.state.selectedRowKeysVendorIds.forEach(element => {
          if (!vendorIds.includes(element)) {
            vendorIds.push({
              vendorId: element
            });
          }
        });
      }
      newItem.vendorIds = vendorIds;

      let errorMessage = "";
      errorMessage = this.requiredMethod(newItem, errorMessage);

      if (Boolean(errorMessage)) {
        errorMessage = errorMessage.substring(0, errorMessage.length - 2) + " required";
        showError(errorMessage);
      } else {
        if (newItem.id === null) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
        } else {
          if (this.state.typeRevise) {
            response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/revise/" + newItem.id, newItem);
          } else {
            response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
          }
        }
        if (response.type === "ERROR") {
          error(response);
          this.setState({ spinLoading: false });
        } else {
          this.setState(
            {
              vendorModal: false,
              spinLoading: false
            },
            () => {
              this.fillComboboxes();
              this.cancel();
              this.resetInputs();
              const { pagination } = this.state;
              this.restartTable({ pagination });
              showSuccess();
            }
          );
        }
      }
    } else {
      showError();
    }
  };

  saveScoring = () => {
    var selectedVendorNotRelatedIds = [];
    if (Boolean(this.state.rfqList) && this.state.rfqList.length > 0) {
      var rfq = this.state.rfqList[0];
      if (Boolean(rfq.vendorIds) && rfq.vendorIds.length > 0) {
        rfq.vendorIds.forEach(element => {
          selectedVendorNotRelatedIds.push(element.vendorId);
        });
      }
    }
    this.setState(
      {
        selectedVendorNotRelatedIds: selectedVendorNotRelatedIds,
        selectedRowKeysVendorIds: selectedVendorNotRelatedIds,
        scoringModal: false,
        vendorModal: true
      },
      () => {
        const { paginationVendor } = this.state;
        this.getVendor({ paginationVendor });
      }
    );
  };

  saveScoringBack = () => {
    this.setState({
      scoringModal: true,
      vendorModal: false
    });
  };

  saveTechnicalRequirementChecklist = () => {
    if (Boolean(this.state.rfqList) && this.state.rfqList.length > 0) {
      var technicalRequirementChecklists = [];
      if (Boolean(this.state.technicalRequirementChecklists) && this.state.technicalRequirementChecklists.length > 0) {
        this.state.technicalRequirementChecklists.forEach(element => {
          technicalRequirementChecklists.push({
            field: element.field,
            description: element.description,
            criteria: element.criteria
          });
        });
      }
      var rfqList = this.state.rfqList;
      rfqList[0].technicalRequirementChecklists = technicalRequirementChecklists;

      this.setState({
        technicalRequirementChecklistModal: false,
        vendorModal: true,
        rfqList: rfqList
      });
    } else {
      showError();
    }
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  saveTechnicalRequirementChecklistBack = () => {
    if (Boolean(this.state.rfqList) && this.state.rfqList.length > 0) {
      var technicalRequirementChecklists = [];
      if (Boolean(this.state.technicalRequirementChecklists) && this.state.technicalRequirementChecklists.length > 0) {
        this.state.technicalRequirementChecklists.forEach(element => {
          technicalRequirementChecklists.push({
            field: element.field,
            description: element.description,
            criteria: element.criteria
          });
        });
      }
      var rfqList = this.state.rfqList;
      rfqList[0].technicalRequirementChecklists = technicalRequirementChecklists;

      this.setState({
        technicalRequirementChecklistModal: true,
        modalDeliveryDateShow: false,
        rfqList: rfqList
      });
    } else {
      showError();
    }
  };

  saveDeliveryDate = () => {
    if (Boolean(this.state.rfqList) && this.state.rfqList.length > 0) {
      this.setState({
        modalDeliveryDateShow: false,
        vendorModal: true
      });
    } else {
      showError();
    }
  };

  saveDeliveryDateBack = () => {
    if (Boolean(this.state.rfqList) && this.state.rfqList.length > 0) {
      this.setState({
        scoringModal: true,
        vendorModal: false
      });
    } else {
      showError();
    }
  };

  requiredMethod(newItem, errorMessage) {
    if (!(Boolean(newItem.pricePaymentInvoicing) && newItem.pricePaymentInvoicing.filter(p => p.check === true).length > 0)) {
      errorMessage += "Price, payment and invoicing, ";
    }
    if (!(Boolean(newItem.paymentTerms) && newItem.paymentTerms.filter(p => p.check === true).length > 0)) {
      errorMessage += "Payment Terms, ";
    }
    // if (!(Boolean(newItem.requestedDocuments) && newItem.requestedDocuments.filter(p => p.check === true).length > 0)) {
    //   errorMessage += "Requested Documents, ";
    // }
    if (!(Boolean(newItem.validityValue) && newItem.validityValue > 0 && Boolean(newItem.quoteValidityDateType))) {
      errorMessage += "Validity of Quotation, ";
    }
    if (!Boolean(newItem.receptionOptions)) {
      errorMessage += "Reception of Quotation, ";
    } else if (newItem.receptionOptions === "OTHER" && !Boolean(newItem.receptionOtherValue)) {
      errorMessage += "Reception of Quotation other value, ";
    }
    if (!(Boolean(newItem.stockId) && newItem.stockId > 0)) {
      errorMessage += "Stock, ";
    }

    // if (!Boolean(newItem.attachmentsFileUrl)) {
    //   errorMessage += "Attachments File, ";
    // }

    if (!(Boolean(this.state.selectedOurExpectedPrice) && this.state.selectedOurExpectedPrice.length > 0)) {
      errorMessage += "Price, payment and invoicing/Delivery dates/Our Expected Item, ";
    }

    if (
      Boolean(newItem.pricePaymentInvoicing) &&
      newItem.pricePaymentInvoicing.filter(p => p.check === true && p.description === "Our expected price").length > 0
    ) {
      if (Boolean(this.state.selectedOurExpectedPrice) && this.state.selectedOurExpectedPrice.length > 0) {
        this.state.selectedOurExpectedPrice.forEach(key => {
          var ourExpectedPrice = this.state.ourExpectedPrices.find(p => p.key === key);
          if (Boolean(ourExpectedPrice) && ourExpectedPrice.selected && !(Boolean(ourExpectedPrice.unitPrice) && ourExpectedPrice.unitPrice > 0)) {
            errorMessage += ourExpectedPrice.description + " unit price, ";
          }
        });
      }
    }
    return errorMessage;
  }

  prepareAttributes(row, filter, firstRowSplice, attribute, edit) {
    let newItem = null;
    let newItemState = null;

    if (Boolean(row)) {
      newItem = {
        generalTermsFileUrl: PrepareFileUploadEdit(row.generalTermsFileUrl),
        attachmentsFileUrl: edit ? PrepareFileUploadEdit(row.attachmentsFileUrl) : null,
        techRequirementsFileUrl: PrepareFileUploadEdit(row.techRequirementsFileUrl),
        techRequirementsAddPr: PrepareFileUploadEdit(row.techRequirementsAddPr),

        generalTerms: row.generalTerms,
        techRequirements: row.techRequirements,
        pricePaymentInvoicing: row.pricePaymentInvoicing,
        paymentTerms: row.paymentTerms,
        deliveryPlaces: row.deliveryPlaces,
        taxes: row.taxes,
        essenceDeliveryDates: row.essenceDeliveryDates,
        warranties: row.warranties,
        languages: row.languages,
        requestedDocuments: row.requestedDocuments,
        terminations: row.terminations,
        confidentialities: row.confidentialities,
        inspectionAndAcceptances: row.inspectionAndAcceptances,
        competentCourt: row.competentCourt,
        liabilityInsurances: row.liabilityInsurances,
        intellectualProperties: row.intellectualProperties,
        contractRecords: row.contractRecords,
        informationPrivacies: row.informationPrivacies,
        documents: row.documents,
        miscellaneous: row.miscellaneous
      };

      newItemState = {
        additionsList: row.additionsList,
        technicalRequirementChecklists: row.technicalRequirementChecklists
      };

      if (Boolean(row.additionsList) && row.additionsList.length > 0) {
        var activePanelKey = this.state.activePanelKey;
        row.additionsList.forEach((element, index) => {
          activePanelKey.push(index + this.state.maxPanelKey);
        });
        this.setState({
          activePanelKey: activePanelKey
        });
      }
    }

    if (Boolean(newItem)) {
      if (attribute === null || attribute === "generalTerms") {
        if (Boolean(newItem.generalTerms) && newItem.generalTerms.length > 0) {
          if (firstRowSplice) {
            newItem.generalTerms.splice(0, 1);
          }

          if (filter) {
            if (Boolean(newItem.generalTerms) && newItem.generalTerms.length > 0) {
              newItem.generalTerms = newItem.generalTerms.filter(p => p.check === true);
            }
          }
        } else {
          newItem.generalTerms = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            generalTerms: newItem.generalTerms
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "techRequirements") {
        if (Boolean(newItem.techRequirements) && newItem.techRequirements.length > 0) {
          if (firstRowSplice) {
            newItem.techRequirements.splice(0, 1);
          }

          if (filter) {
            if (Boolean(newItem.techRequirements) && newItem.techRequirements.length > 0) {
              newItem.techRequirements = newItem.techRequirements.filter(p => p.check === true);
            }
          }
        } else {
          newItem.techRequirements = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            techRequirements: newItem.techRequirements
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "pricePaymentInvoicing") {
        if (Boolean(newItem.pricePaymentInvoicing) && newItem.pricePaymentInvoicing.length > 0) {
          if (firstRowSplice) {
            newItem.pricePaymentInvoicing.splice(0, 1);
          }
          if (filter) {
            if (Boolean(newItem.pricePaymentInvoicing) && newItem.pricePaymentInvoicing.length > 0) {
              newItem.pricePaymentInvoicing = newItem.pricePaymentInvoicing.filter(p => p.check === true);
            }
          }

          newItem.pricePaymentInvoicing.forEach(element => {
            if (Boolean(element.description)) {
              if (element.description === "Your offer for price") {
                if (element.check) newItem.priceOffer = true;
                newItem.pricePaymentInvoicing = newItem.pricePaymentInvoicing.filter(p => p.description !== "Your offer for price");
              } else if (element.description === "Goods and Service") {
                if (element.check) newItem.goodsAndService = true;
                newItem.pricePaymentInvoicing = newItem.pricePaymentInvoicing.filter(p => p.description !== "Goods and Service");
              } else if (element.description === "Insurance") {
                if (element.check) newItem.insurance = true;
                newItem.pricePaymentInvoicing = newItem.pricePaymentInvoicing.filter(p => p.description !== "Insurance");
              } else if (element.description === "Shipping") {
                if (element.check) newItem.shipping = true;
                newItem.pricePaymentInvoicing = newItem.pricePaymentInvoicing.filter(p => p.description !== "Shipping");
              } else if (element.description === "Our expected price") {
                if (element.check) newItem.ourExpectedPrice = true;
                newItem.pricePaymentInvoicing = newItem.pricePaymentInvoicing.filter(p => p.description !== "Our expected price");
              }
            }
          });
        } else {
          newItem.pricePaymentInvoicing = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            pricePaymentInvoicing: newItem.pricePaymentInvoicing
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "paymentTerms") {
        if (Boolean(newItem.paymentTerms) && newItem.paymentTerms.length > 0) {
          if (firstRowSplice) {
            newItem.paymentTerms.splice(0, 1);
          }
          if (filter) {
            if (Boolean(newItem.paymentTerms) && newItem.paymentTerms.length > 0) {
              newItem.paymentTerms = newItem.paymentTerms.filter(p => p.check === true);
            }
          }

          newItem.paymentTerms.forEach(element => {
            if (Boolean(element.description)) {
              if (element.description === "Your terms for payment") {
                if (element.check) newItem.termsForPayment = true;
                newItem.paymentTerms = newItem.paymentTerms.filter(p => p.description !== "Your terms for payment");
              }
            }
          });
        } else {
          newItem.paymentTerms = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            paymentTerms: newItem.paymentTerms
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "deliveryPlaces") {
        if (Boolean(newItem.deliveryPlaces) && newItem.deliveryPlaces.length > 0) {
          if (firstRowSplice) {
            newItem.deliveryPlaces.splice(0, 1);
          }
          if (filter) {
            if (Boolean(newItem.deliveryPlaces) && newItem.deliveryPlaces.length > 0) {
              newItem.deliveryPlaces = newItem.deliveryPlaces.filter(p => p.check === true);
            }
          }

          newItem.deliveryPlaces.forEach(element => {
            if (Boolean(element.description)) {
              if (element.description === "Project site") {
                if (element.check) newItem.projectSite = true;
                newItem.deliveryPlaces = newItem.deliveryPlaces.filter(p => p.description !== "Project site");
              } else if (element.description === "Your manufacturing place") {
                if (element.check) newItem.manufacturingPlace = true;
                newItem.deliveryPlaces = newItem.deliveryPlaces.filter(p => p.description !== "Your manufacturing place");
              }
            }
          });
        } else {
          newItem.deliveryPlaces = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            deliveryPlaces: newItem.deliveryPlaces
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "taxes") {
        if (Boolean(newItem.taxes) && newItem.taxes.length > 0) {
          if (firstRowSplice) {
            newItem.taxes.splice(0, 1);
          }
          if (filter) {
            if (Boolean(newItem.taxes) && newItem.taxes.length > 0) {
              newItem.taxes = newItem.taxes.filter(p => p.check === true);
            }
          }
        } else {
          newItem.taxes = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            taxes: newItem.taxes
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "essenceDeliveryDates") {
        if (Boolean(newItem.essenceDeliveryDates) && newItem.essenceDeliveryDates.length > 0) {
          if (firstRowSplice) {
            newItem.essenceDeliveryDates.splice(0, 1);
          }
          if (filter) {
            if (Boolean(newItem.essenceDeliveryDates) && newItem.essenceDeliveryDates.length > 0) {
              newItem.essenceDeliveryDates = newItem.essenceDeliveryDates.filter(p => p.check === true);
            }
          }

          newItem.essenceDeliveryDates.forEach(element => {
            if (Boolean(element.description)) {
              if (element.description === "Fix Date") {
                if (element.check) newItem.fixDate = true;
                newItem.essenceDeliveryDates = newItem.essenceDeliveryDates.filter(p => p.description !== "Fix Date");
              } else if (element.description === "Your proposal of delivery date") {
                if (element.check) newItem.proposalDeliveryDate = true;
                newItem.essenceDeliveryDates = newItem.essenceDeliveryDates.filter(p => p.description !== "Your proposal of delivery date");
              } else if (element.description === "To be decided by mutual negotiations") {
                if (element.check) newItem.mutualNegotiations = true;
                newItem.essenceDeliveryDates = newItem.essenceDeliveryDates.filter(p => p.description !== "To be decided by mutual negotiations");
              }
            }
          });
        } else {
          newItem.essenceDeliveryDates = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            essenceDeliveryDates: newItem.essenceDeliveryDates
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "warranties") {
        if (Boolean(newItem.warranties) && newItem.warranties.length > 0) {
          if (firstRowSplice) {
            newItem.warranties.splice(0, 1);
          }
          if (filter) {
            if (Boolean(newItem.warranties) && newItem.warranties.length > 0) {
              newItem.warranties = newItem.warranties.filter(p => p.check === true);
            }
          }
        } else {
          newItem.warranties = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            warranties: newItem.warranties
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "languages") {
        if (Boolean(newItem.languages) && newItem.languages.length > 0) {
          if (firstRowSplice) {
            newItem.languages.splice(0, 1);
          }
          if (filter) {
            if (Boolean(newItem.languages) && newItem.languages.length > 0) {
              newItem.languages = newItem.languages.filter(p => p.check === true);
            }
          }
        } else {
          newItem.languages = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            languages: newItem.languages
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "requestedDocuments") {
        if (Boolean(newItem.requestedDocuments) && newItem.requestedDocuments.length > 0) {
          if (firstRowSplice) {
            newItem.requestedDocuments.splice(0, 1);
          }

          if (filter) {
            if (Boolean(newItem.requestedDocuments) && newItem.requestedDocuments.length > 0) {
              newItem.requestedDocuments = newItem.requestedDocuments.filter(p => p.check === true);
            }
          }
        } else {
          newItem.requestedDocuments = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            requestedDocuments: newItem.requestedDocuments
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "terminations") {
        if (Boolean(newItem.terminations) && newItem.terminations.length > 0) {
          if (firstRowSplice) {
            newItem.terminations.splice(0, 1);
          }

          if (filter) {
            if (Boolean(newItem.terminations) && newItem.terminations.length > 0) {
              newItem.terminations = newItem.terminations.filter(p => p.check === true);
            }
          }
        } else {
          newItem.terminations = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            terminations: newItem.terminations
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "confidentialities") {
        if (Boolean(newItem.confidentialities) && newItem.confidentialities.length > 0) {
          if (firstRowSplice) {
            newItem.confidentialities.splice(0, 1);
          }

          if (filter) {
            if (Boolean(newItem.confidentialities) && newItem.confidentialities.length > 0) {
              newItem.confidentialities = newItem.confidentialities.filter(p => p.check === true);
            }
          }
        } else {
          newItem.confidentialities = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            confidentialities: newItem.confidentialities
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "inspectionAndAcceptances") {
        if (Boolean(newItem.inspectionAndAcceptances) && newItem.inspectionAndAcceptances.length > 0) {
          if (firstRowSplice) {
            newItem.inspectionAndAcceptances.splice(0, 1);
          }

          if (filter) {
            if (Boolean(newItem.inspectionAndAcceptances) && newItem.inspectionAndAcceptances.length > 0) {
              newItem.inspectionAndAcceptances = newItem.inspectionAndAcceptances.filter(p => p.check === true);
            }
          }
        } else {
          newItem.inspectionAndAcceptances = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            inspectionAndAcceptances: newItem.inspectionAndAcceptances
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "competentCourt") {
        if (Boolean(newItem.competentCourt) && newItem.competentCourt.length > 0) {
          if (firstRowSplice) {
            newItem.competentCourt.splice(0, 1);
          }

          if (filter) {
            if (Boolean(newItem.competentCourt) && newItem.competentCourt.length > 0) {
              newItem.competentCourt = newItem.competentCourt.filter(p => p.check === true);
            }
          }
        } else {
          newItem.competentCourt = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            competentCourt: newItem.competentCourt
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "liabilityInsurances") {
        if (Boolean(newItem.liabilityInsurances) && newItem.liabilityInsurances.length > 0) {
          if (firstRowSplice) {
            newItem.liabilityInsurances.splice(0, 1);
          }

          if (filter) {
            if (Boolean(newItem.liabilityInsurances) && newItem.liabilityInsurances.length > 0) {
              newItem.liabilityInsurances = newItem.liabilityInsurances.filter(p => p.check === true);
            }
          }
        } else {
          newItem.liabilityInsurances = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            liabilityInsurances: newItem.liabilityInsurances
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "intellectualProperties") {
        if (Boolean(newItem.intellectualProperties) && newItem.intellectualProperties.length > 0) {
          if (firstRowSplice) {
            newItem.intellectualProperties.splice(0, 1);
          }

          if (filter) {
            if (Boolean(newItem.intellectualProperties) && newItem.intellectualProperties.length > 0) {
              newItem.intellectualProperties = newItem.intellectualProperties.filter(p => p.check === true);
            }
          }
        } else {
          newItem.intellectualProperties = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            intellectualProperties: newItem.intellectualProperties
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "contractRecords") {
        if (Boolean(newItem.contractRecords) && newItem.contractRecords.length > 0) {
          if (firstRowSplice) {
            newItem.contractRecords.splice(0, 1);
          }

          if (filter) {
            if (Boolean(newItem.contractRecords) && newItem.contractRecords.length > 0) {
              newItem.contractRecords = newItem.contractRecords.filter(p => p.check === true);
            }
          }
        } else {
          newItem.contractRecords = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            contractRecords: newItem.contractRecords
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "informationPrivacies") {
        if (Boolean(newItem.informationPrivacies) && newItem.informationPrivacies.length > 0) {
          if (firstRowSplice) {
            newItem.informationPrivacies.splice(0, 1);
          }

          if (filter) {
            if (Boolean(newItem.informationPrivacies) && newItem.informationPrivacies.length > 0) {
              newItem.informationPrivacies = newItem.informationPrivacies.filter(p => p.check === true);
            }
          }
        } else {
          newItem.informationPrivacies = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            informationPrivacies: newItem.informationPrivacies
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "documents") {
        if (Boolean(newItem.documents) && newItem.documents.length > 0) {
          if (firstRowSplice) {
            newItem.documents.splice(0, 1);
          }

          if (filter) {
            if (Boolean(newItem.documents) && newItem.documents.length > 0) {
              newItem.documents = newItem.documents.filter(p => p.check === true);
            }
          }
        } else {
          newItem.documents = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            documents: newItem.documents
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }

      if (attribute === null || attribute === "miscellaneous") {
        if (Boolean(newItem.miscellaneous) && newItem.miscellaneous.length > 0) {
          if (firstRowSplice) {
            newItem.miscellaneous.splice(0, 1);
          }

          if (filter) {
            if (Boolean(newItem.miscellaneous) && newItem.miscellaneous.length > 0) {
              newItem.miscellaneous = newItem.miscellaneous.filter(p => p.check === true);
            }
          }
        } else {
          newItem.miscellaneous = [];
        }
        if (Boolean(attribute)) {
          var newObj = {
            miscellaneous: newItem.miscellaneous
          };
          this.formRef.current.setFieldsValue({
            ...newObj
          });
        }
      }
    }

    if (Boolean(newItemState)) {
      if (Boolean(newItemState.additionsList) && newItemState.additionsList.length > 0)
        newItemState.additionsList.forEach(addition => {
          if (addition.rfqModelList.length > 0) addition.rfqModelList.splice(0, 1);
        });
      else {
        newItemState.additionsList = [];
      }
    }
    return { newItem, newItemState };
  }

  setDynamicObj(data, addData) {
    data.forEach(element => {
      addData.push({
        ...element,
        check: element.check,
        description: element.description,
        score: element.score ? element.score : 0,
        weight: element.weight ? element.weight : 0,
        mandatory: element.mandatory ? element.mandatory : false
      });
    });
  }

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
      searchCriteriaModels: this.state.searchCriteriaModels
    };
    return newObj;
  }

  createWhereAddMoreRFQ(params) {
    if (Boolean(params._pagination)) params.paginationAddMoreRFQ = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.paginationAddMoreRFQ)) {
      if (Boolean(params.paginationAddMoreRFQ.lastUpdateDate)) {
        lastUpdateDateStart = params.paginationAddMoreRFQ.lastUpdateDate[0];
        lastUpdateDateEnd = params.paginationAddMoreRFQ.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.paginationAddMoreRFQ,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.paginationAddMoreRFQ.current - 1,
      maxResults: params.paginationAddMoreRFQ.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  createWhereStock(params) {
    if (Boolean(params._pagination)) params.paginationStock = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.paginationStock)) {
      if (Boolean(params.paginationStock.lastUpdateDate)) {
        lastUpdateDateStart = params.paginationStock.lastUpdateDate[0];
        lastUpdateDateEnd = params.paginationStock.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.paginationStock,
      id: null,
      equivalentItemsIncluded: Boolean(this.state.equivalentItemsIncluded) ? this.state.equivalentItemsIncluded : false,
      projectTakeOffListId: this.state.projectTakeOffListId,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.paginationStock.current - 1,
      maxResults: params.paginationStock.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  createWhereVendor(params) {
    if (Boolean(params._paginationVendor)) params.paginationVendor = params._paginationVendor;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.paginationVendor)) {
      if (Boolean(params.paginationVendor.lastUpdateDate)) {
        lastUpdateDateStart = params.pagpaginationVendorination.lastUpdateDate[0];
        lastUpdateDateEnd = params.paginationVendor.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.paginationVendor,
      // id: null,
      ids: this.state.selectedVendorNotRelatedIds,
      itemType: this.state.itemType,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.paginationVendor.current - 1,
      maxResults: params.paginationVendor.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  createWhereVendorNotRelated(params) {
    if (Boolean(params._pagination)) params.paginationVendorNotRelated = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.paginationVendorNotRelated)) {
      if (Boolean(params.paginationVendorNotRelated.lastUpdateDate)) {
        lastUpdateDateStart = params.pagpaginationVendorination.lastUpdateDate[0];
        lastUpdateDateEnd = params.paginationVendorNotRelated.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.paginationVendorNotRelated,
      ids: this.state.selectedVendorNotRelatedIds,
      itemType: this.state.itemType,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.paginationVendorNotRelated.current - 1,
      maxResults: params.paginationVendorNotRelated.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  createWhereDeliveryDate(params) {
    if (Boolean(params._pagination)) params.paginationDeliveryDate = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.paginationDeliveryDate)) {
      if (Boolean(params.paginationDeliveryDate.lastUpdateDate)) {
        lastUpdateDateStart = params.paginationDeliveryDate.lastUpdateDate[0];
        lastUpdateDateEnd = params.paginationDeliveryDate.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.paginationDeliveryDate,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.paginationDeliveryDate.current - 1,
      maxResults: params.paginationDeliveryDate.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  actionTemplate(record) {
    let hasDelete = true;
    let hasRevise = false;
    let hasEdit = false;
    let hasSendToVendor = false;
    let hasResponses = false;
    let hasCloseTendering = false;
    let hasEvaluation = false;
    if (Boolean(record.status)) {
      if (record.status === "TenderingClosed") {
        hasEdit = true;
        hasEvaluation = true;
      } else if (record.status === "Revise" || record.status === "Created") {
        hasEdit = true;
        hasSendToVendor = true;
      } else {
        hasRevise = true;
        hasResponses = true;
        hasCloseTendering = true;
      }
    } else {
      hasEdit = true;
      hasSendToVendor = true;
    }

    if (record.status === "Evaluation" || record.status === "Deleted" || record.status === "POCreation" || record.status === "PORequested") {
      hasDelete = false;
      hasRevise = false;
      hasEdit = false;
      hasSendToVendor = false;
      hasResponses = false;
      hasCloseTendering = false;
      hasEvaluation = false;
    }

    return (
      <React.Fragment>
        <CustomRFQMenu
          row={record}
          onDeleteRow={this.deleteModal.bind(this)}
          hasDelete={hasDelete}
          onReviseRow={this.edit.bind(this, true)}
          hasRevise={hasRevise}
          onSendToVendorsRow={this.sendToVendorsModal.bind(this)}
          hasSendToVendor={hasSendToVendor}
          onEditRow={this.edit.bind(this, false)}
          hasEdit={hasEdit}
          onResponsesRow={this.showVendorResponsesModal.bind(this)}
          hasResponses={hasResponses}
          onScoringRow={this.showScoringModal.bind(this)}
          // hasScoring={record.hasScoring}
          hasScoring={false}
          onRepliesRow={this.showReplyModal.bind(this)}
          hasReplies={record.hasReplies}
          onCloseTenderingRow={this.closeTenderingModal.bind(this)}
          hasCloseTendering={hasCloseTendering}
          onEvaluationRow={this.evaluationModal.bind(this)}
          onEvaluationNewRow={this.evaluationNewModal.bind(this)}
          hasEvaluation={hasEvaluation}
        />
      </React.Fragment>
    );
  }

  actionVendor = record => {
    let actionList = [];
    let editAction = {
      name: <FormattedMessage id="GeneralAction.Edit" defaultMessage="Edit" />,
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.editVendor.bind(this)
    };
    actionList.push(editAction);
    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  onMarkerPositionChanged = e => {
    this.setState({
      markerPosition: { lat: e.latLng.lat(), lng: e.latLng.lng() }
    });
  };

  onSelectChangeVendor = selectedRowKeysVendorIds => {
    this.setState({
      selectedRowKeysVendorIds: selectedRowKeysVendorIds
    });
  };

  onSelectChangeVendorNotRelated = async selectedRowKeysNotRelated => {
    const { projectTakeOffId } = this.state;
    if (Boolean(projectTakeOffId) && projectTakeOffId > 0 && Boolean(selectedRowKeysNotRelated) && selectedRowKeysNotRelated.length > 0) {
      var response = await handleRequest(
        "GET",
        "/api/" +
        this.state.controllerName +
        "/saveVendorProduct/" +
        projectTakeOffId +
        "/" +
        selectedRowKeysNotRelated[selectedRowKeysNotRelated.length - 1]
      );
      if (response.type === "ERROR") {
        error(response);
      }
    }

    this.setState({
      selectedRowKeysNotRelated: selectedRowKeysNotRelated
    });
  };

  onSelectStockModal = selectedRowKeysInventoryLog => {
    this.setState({
      selectedRowKeysInventoryLog: selectedRowKeysInventoryLog
    });
  };

  onSelectChangeAddMoreRFQ = selectedRowKeysAddMoreRFQ => {
    this.setState({ selectedRowKeysAddMoreRFQ });
  };

  onSelectChangeOurExpectedPrice = selectedOurExpectedPrice => {
    var ourExpectedPrices = this.state.ourExpectedPrices;
    if (Boolean(ourExpectedPrices) && ourExpectedPrices.length > 0) {
      ourExpectedPrices.forEach(data => {
        data.selected = false;
      });

      if (Boolean(selectedOurExpectedPrice) && selectedOurExpectedPrice.length > 0) {
        selectedOurExpectedPrice.forEach(key => {
          var ourExpectedPrice = ourExpectedPrices.find(p => p.key === key);
          if (Boolean(ourExpectedPrice)) {
            ourExpectedPrice.selected = true;
          }
        });
      }
    }
    this.setState({
      selectedOurExpectedPrice: selectedOurExpectedPrice,
      ourExpectedPrices: ourExpectedPrices
    });
  };

  showOrHideModalDeliveryDate = () => {
    this.setState({
      modalDeliveryDateShow: !this.state.modalDeliveryDateShow,
      vendorModal: false
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showOrHideModalFix = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    today = dd + "/" + mm + "/" + yyyy;

    this.setState({
      fixModal: !this.state.fixModal,
      deliveryDate: today,
      quantityModal: ""
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showModalFix = row => {
    if (!row.quantity.toString().includes(",")) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      today = dd + "/" + mm + "/" + yyyy;

      this.setState({
        fixModal: true,
        selectedFixRow: row,
        deliveryDate: Boolean(row.deliveryDate) ? row.deliveryDate : today,
        quantityModal: row.quantity
      });
    } else {
      let overvalue = false;
      let flagSplitRows = [];

      let flagQuantityTotal = 0;
      let flagQuantity = row.quantity.split(",");
      let flagDate = row.deliveryDate.split(",");

      for (let index = 0; index < flagQuantity.length; index++) {
        flagSplitRows.push({
          splitIndex: index + 1,
          quantity: flagQuantity[index],
          date: flagDate[index]
        });
        flagQuantityTotal += Number(flagQuantity[index]);
      }

      if (flagQuantity < 0) {
        overvalue = true;
      } else {
        overvalue = false;
      }

      _splitRows = flagSplitRows;

      this.setState({
        selectedFixRow: row,
        splitQuantityModal: true,
        splitQuantityTotalModal: flagQuantityTotal,
        splitQuantityRemainingModal: 0,
        splitRows: flagSplitRows,
        overvalue: overvalue
      });
    }

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showModalSplitQuantity = () => {
    _splitRows = [
      {
        splitIndex: 1,
        quantity: "",
        date: today
      }
    ];

    this.setState({
      splitQuantityModal: true,
      splitRows: _splitRows,
      overvalue: false,
      splitQuantityTotalModal: this.state.selectedFixRow.quantity,
      splitQuantityRemainingModal: this.state.selectedFixRow.quantity
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  onChangeSplitDateDelivery = async (index, date, dateString) => {
    let list = this.state.splitRows;

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;

    if (dateString !== "") {
      list[index].date = dateString;
    } else {
      list[index].date = today;
    }

    this.setState({
      splitRows: list.slice()
    });
  };

  handleChangeSplitQuantity = (index, value) => {
    let overvalue = false;
    let list = this.state.splitRows;
    list[index].quantity = value;

    let flagQuantity = this.state.splitQuantityTotalModal;

    for (let index = 0; index < list.length; index++) {
      if (list[index].quantity !== null && list[index].quantity !== "") flagQuantity -= list[index].quantity;
    }

    if (flagQuantity < 0) {
      overvalue = true;
    } else {
      overvalue = false;
    }

    this.setState({
      splitRows: list.slice(),
      splitQuantityRemainingModal: flagQuantity,
      overvalue: overvalue
    });
  };

  addNewSplit = () => {
    _splitRows.push({
      splitIndex: _splitRows.length + 1,
      quantity: "",
      date: today
    });

    this.setState({
      splitRows: _splitRows
    });
  };

  removeSplit = (index, e) => {
    if (_splitRows.length !== 1) {
      _splitRows.splice(index, 1);
    }
    var quantity = 0;
    if (Boolean(_splitRows) && _splitRows.length > 0) {
      _splitRows.forEach(element => {
        if (Boolean(element.quantity) && element.quantity > 0) {
          quantity += element.quantity;
        }
      });
    }

    var splitQuantityRemainingModal = this.state.splitQuantityTotalModal - quantity;
    this.setState({
      splitRows: _splitRows,
      splitQuantityRemainingModal: splitQuantityRemainingModal
    });
  };

  fixItemBefore = () => {
    let list = this.state.splitRows;
    if (Boolean(list) && list.length > 0) {
      if (this.state.splitQuantityRemainingModal == 0) {
        var quantityDeliveryDates = [];
        var deliveryDate = "";
        var quantity = "";
        list.forEach(element => {
          quantity += element.quantity + ", ";
          deliveryDate += element.date + ", ";
          quantityDeliveryDates.push({
            quantity: element.quantity,
            deliveryDate: element.date
          });
        });
        var rfqList = this.state.rfqList;
        rfqList[0].quantityDeliveryDates = quantityDeliveryDates;
        rfqList[0].deliveryDate = Boolean(deliveryDate) ? deliveryDate.substring(0, deliveryDate.length - 2) : "";
        rfqList[0].quantity = Boolean(quantity) ? quantity.substring(0, quantity.length - 2) : "";
        this.setState(
          {
            rfqList: rfqList
          },
          () => this.fixItem2()
        );
      } else {
        showError("You must enter all quantities");
      }
    }
  };

  fixItem2 = () => {
    if (this.state.overvalue) showError("Overvalue exception");
    else {
      let list = this.state.rfqList;
      let flagList = this.state.rfqList[0];
      let flagIndex = 0;

      for (let index = 0; index < flagList.length; index++) {
        if (flagList[index].id === this.state.selectedFixRow.id) flagIndex = index;
      }

      let splitQuantities = [];
      let splitDates = [];
      for (let index = 0; index < this.state.splitRows.length; index++) {
        splitQuantities.push(this.state.splitRows[index].quantity);
        splitDates.push(this.state.splitRows[index].date);
      }

      this.setState({
        rfqList: list.slice()
      });

      this.setState({
        fixModal: false,
        splitQuantityModal: false
      });
    }
  };

  fixItem = () => {
    if (Boolean(this.state.rfqList) && this.state.rfqList.length > 0) {
      let flagSelectedItem = this.state.selectedFixRow;

      var quantityDeliveryDates = [];
      quantityDeliveryDates.push({
        quantity: this.state.quantityModal,
        deliveryDate: this.state.deliveryDate
      });

      var rfqList = this.state.rfqList;
      rfqList[0].quantityDeliveryDates = quantityDeliveryDates;
      rfqList[0].deliveryDate = this.state.deliveryDate;
      rfqList[0].quantity = this.state.quantityModal;

      this.setState({
        fixModal: false,
        rfqList: rfqList
      });
    }
  };

  showOrHideModalSplitQuantity = () => {
    this.setState({
      fixModal: false,
      splitQuantityModal: !this.state.splitQuantityModal,
      splitQuantityTotalModal: "",
      splitQuantityRemainingModal: ""
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  disabledDate = current => {
    return current < moment().startOf("day");
  };

  renderElement = (values, title) => {
    return values.map((item, index) => (
      <RowBoot key={item.description + index} style={{ marginBottom: "1rem" }}>
        <Col xs={index === 0 ? 1 : 2}></Col>
        <Col xs={index === 0 ? 8 : 7}>
          <FormBoot.Label style={index === 0 ? { color: "black", marginTop: "0.5rem", fontWeight: "bold" } : { color: "black", marginTop: "0.5rem" }}>
            {item.description}
          </FormBoot.Label>
        </Col>
        <Col xs={3} className="text-center">
          {index !== 0 && (
            <Checkbox
              checked={item.mandatory}
              onChange={e => {
                item.mandatory = e.target.checked;
                this.setState({
                  rfqList: this.state.rfqList
                });
              }}
            />
          )}
        </Col>
        <Col xs={index === 0 ? 3 : 4}></Col>
        <Col xs={index === 0 ? 3 : 2} className="text-center">
          <FormattedMessage id="RequestForQuotation.score" defaultMessage="Score">
            <InputNumber
              parser={value => value.replace(",", ".")}
              formatter={value => value.replace(".", ",")}
              style={
                index === 0
                  ? { width: "100%", color: "black", marginTop: "0.5rem", fontWeight: "bold", fontSize: "16px" }
                  : { width: "100%", color: "black", marginTop: "0.5rem", textAlign: "right" }
              }
              min={0}
              value={item.score}
              onChange={value => {
                item.score = value;
                this.calcWeight();
              }}
            />
          </FormattedMessage>
        </Col>
        <Col xs={index === 0 ? 3 : 4}></Col>
        <Col xs={index === 0 ? 3 : 2} className="text-center">
          <FormattedMessage id="RequestForQuotation.weight" defaultMessage="Weight">
            <InputNumber
              parser={value => value.replace(",", ".")}
              formatter={value => value.replace(".", ",")}
              style={
                index === 0
                  ? { width: "100%", color: "black", marginTop: "0.5rem", fontWeight: "bold", fontSize: "16px" }
                  : { width: "100%", color: "black", marginTop: "0.5rem", textAlign: "right" }
              }
              readOnly
              min={0}
              max={-1}
              value={item.weight}
            />
          </FormattedMessage>
        </Col>
        <Col xs={2}></Col>
      </RowBoot>
    ));
  };

  getTotalParent = obj => {
    var totalParentScore = 0;
    if (Boolean(this.state.rfqList) && this.state.rfqList.length > 0) {
      if (Boolean(this.state.rfqList[0][obj]) && this.state.rfqList[0][obj].length > 0) {
        var values = this.state.rfqList[0][obj].filter(p => p.check === true);
        if (Boolean(values) && values.length > 0 && Boolean(values[0].score) && values[0].score > 0) {
          totalParentScore += values[0].score;
        }
      }
    }
    return totalParentScore;
  };

  getTotalParentAdditionsList = obj => {
    var totalParentScore = 0;
    if (Boolean(this.state.rfqList) && this.state.rfqList.length > 0) {
      if (Boolean(this.state.rfqList[0].additionsList[obj]) && this.state.rfqList[0].additionsList[obj].length > 0) {
        var values = this.state.rfqList[0].additionsList[obj].filter(p => p.check === true);
        if (Boolean(values) && values.length > 0 && Boolean(values[0].score) && values[0].score > 0) {
          totalParentScore += values[0].score;
        }
      }
    }
    return totalParentScore;
  };

  calcElement = (obj, totalParentScore) => {
    if (totalParentScore > 0) {
      if (Boolean(this.state.rfqList[0][obj]) && this.state.rfqList[0][obj].length > 0) {
        var values = this.state.rfqList[0][obj].filter(p => p.check === true);
        if (Boolean(values) && values.length > 0) {
          if (Boolean(values[0].score) && values[0].score > 0) {
            var totalChildScore = 0;
            values.forEach((element, index) => {
              if (index !== 0 && Boolean(element.score) && element.score > 0) {
                totalChildScore += element.score;
              }
            });

            values.forEach((element, index) => {
              if (index == 0) {
                if (Boolean(element.score) && element.score > 0) {
                  element.weight = Number((element.score / totalParentScore) * 100).toFixed(2);
                }
              } else {
                if (Boolean(element.score) && element.score > 0) {
                  element.weight = Number((element.score / totalChildScore) * 100).toFixed(2);
                }
              }
            });
          }
        }
      }
    }
  };

  calcElementAdditionsList = (obj, totalParentScore) => {
    if (totalParentScore > 0) {
      if (Boolean(this.state.rfqList[0].additionsList[obj]) && this.state.rfqList[0].additionsList[obj].length > 0) {
        var values = this.state.rfqList[0].additionsList[obj].filter(p => p.check === true);
        if (Boolean(values) && values.length > 0) {
          if (Boolean(values[0].score) && values[0].score > 0) {
            var totalChildScore = 0;
            values.forEach((element, index) => {
              if (index !== 0 && Boolean(element.score) && element.score > 0) {
                totalChildScore += element.score;
              }
            });

            values.forEach((element, index) => {
              if (index == 0) {
                if (Boolean(element.score) && element.score > 0) {
                  element.weight = Number((element.score / totalParentScore) * 100).toFixed(2);
                }
              } else {
                if (Boolean(element.score) && element.score > 0) {
                  element.weight = Number((element.score / totalChildScore) * 100).toFixed(2);
                }
              }
            });
          }
        }
      }
    }
  };

  calcWeight = () => {
    var totalParentScore = 0;
    totalParentScore += this.getTotalParent("generalTerms");
    totalParentScore += this.getTotalParent("techRequirements");
    totalParentScore += this.getTotalParent("workingProperties");
    totalParentScore += this.getTotalParent("pricePaymentInvoicing");
    totalParentScore += this.getTotalParent("paymentTerms");
    totalParentScore += this.getTotalParent("deliveryPlaces");
    totalParentScore += this.getTotalParent("taxes");
    totalParentScore += this.getTotalParent("essenceDeliveryDates");
    totalParentScore += this.getTotalParent("warranties");
    totalParentScore += this.getTotalParent("languages");
    totalParentScore += this.getTotalParent("requestedDocuments");
    totalParentScore += this.getTotalParent("terminations");
    totalParentScore += this.getTotalParent("confidentialities");
    totalParentScore += this.getTotalParent("inspectionAndAcceptances");
    totalParentScore += this.getTotalParent("competentCourt");
    totalParentScore += this.getTotalParent("liabilityInsurances");
    totalParentScore += this.getTotalParent("intellectualProperties");
    totalParentScore += this.getTotalParent("contractRecords");
    totalParentScore += this.getTotalParent("informationPrivacies");
    totalParentScore += this.getTotalParent("documents");
    totalParentScore += this.getTotalParent("miscellaneous");
    this.state.rfqList[0].additionsList.forEach((element, index) => {
      totalParentScore += this.getTotalParentAdditionsList(index);
    });

    this.calcElement("generalTerms", totalParentScore);
    this.calcElement("techRequirements", totalParentScore);
    this.calcElement("workingProperties", totalParentScore);
    this.calcElement("pricePaymentInvoicing", totalParentScore);
    this.calcElement("paymentTerms", totalParentScore);
    this.calcElement("deliveryPlaces", totalParentScore);
    this.calcElement("taxes", totalParentScore);
    this.calcElement("essenceDeliveryDates", totalParentScore);
    this.calcElement("warranties", totalParentScore);
    this.calcElement("languages", totalParentScore);
    this.calcElement("requestedDocuments", totalParentScore);
    this.calcElement("terminations", totalParentScore);
    this.calcElement("confidentialities", totalParentScore);
    this.calcElement("inspectionAndAcceptances", totalParentScore);
    this.calcElement("competentCourt", totalParentScore);
    this.calcElement("liabilityInsurances", totalParentScore);
    this.calcElement("intellectualProperties", totalParentScore);
    this.calcElement("contractRecords", totalParentScore);
    this.calcElement("informationPrivacies", totalParentScore);
    this.calcElement("documents", totalParentScore);
    this.calcElement("miscellaneous", totalParentScore);
    this.state.rfqList[0].additionsList.forEach((element, index) => {
      this.calcElementAdditionsList(index, totalParentScore);
    });

    this.setState({
      rfqList: this.state.rfqList
    });
  };

  onChangeDateDelivery = async (date, dateString) => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;

    if (dateString !== "") {
      this.setState({
        deliveryDate: dateString
      });
    } else {
      this.setState({
        deliveryDate: today
      });
    }
  };

  generateReport = async row => {
    this.setState({ spinLoading: true });
    let url = "/api/" + this.state.controllerName + "/getReportPolarkon/" + row.id;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        spinLoading: false
      });
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true,
        spinLoading: false
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false,
        spinLoading: false
      });
      showError("There is no created report for selected item");
    }
  };

  generateReportRevision = async row => {
    let url = "/api/" + this.state.controllerName + "/getReportRevisionPolarkon/" + row.requestForQuotationId + "/" + row.revNo;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false
      });
      showError("There is no created report for selected item");
    }
  };

  generateReportEvaluation = async row => {
    this.setState({ spinLoading: true });
    let url = "/api/" + this.state.controllerName + "/getReportEvaluation/" + row.id;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        spinLoading: false
      });
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true,
        spinLoading: false
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false,
        spinLoading: false
      });
      showError("There is no created report for selected item");
    }
  };

  rfqHistory = async row => {
    let url = "/api/" + this.state.controllerName + "/getHistory/" + row.id;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        historyList: response.data,
        modalHistoryShow: true
      });
    }
  };

  rfqStatus = async row => {
    let url = "/api/" + this.state.controllerName + "/getStatus/" + row.id;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        statusList: response.data,
        modalStatusShow: true
      });
    }
  };

  showReport = async url => {
    try {
      const fileRealUrl = url;

      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true
      });
    } catch (error) {
      alert("File cant found.");
    }
  };

  showRevisedList = async row => {
    this.setState(
      {
        showRevisedListModal: true,
        modalRequestForQuotationId: row.id
      },
      () => {
        const { pagination } = this.state;
        this.restartTableRevised({ pagination });
      }
    );
  };

  getPricesData = async (projectTakeOffId, itemType, id, requestForPurchaseId, addMoreItems) => {
    console.log("projectTakeOffId", projectTakeOffId);
    if (Boolean(projectTakeOffId) || Boolean(requestForPurchaseId)) {
      var newObj = {
        id: id,
        projectTakeOffId: projectTakeOffId,
        itemType: itemType,
        requestForPurchaseId: requestForPurchaseId,
        addMoreItems: addMoreItems
      };
      let responsePricecs = await handleRequest("POST", "/api/" + this.state.controllerName + "/ourExpectedPrices", newObj);
      if (responsePricecs.type === "ERROR") {
        error(responsePricecs);
      } else {
        var selectedOurExpectedPrice = [];
        if (Boolean(responsePricecs.data) && responsePricecs.data.length > 0) {
          responsePricecs.data.forEach((element, index) => {
            element.active = true;
            if (Boolean(element.planedDeliveryDate)) {
              element.planedDeliveryDate = moment(element.planedDeliveryDate).format("DD-MM-YYYY");
            }
            element.price = element.quantity * element.unitPrice;
            element.key = index + 1;
            element.currencySelectItems = this.state.currencySelectItems;
            element.defaultUnit = element.unit;
            element.defaultUnitId = element.unitId;

            if (element.selected) {
              selectedOurExpectedPrice.push(element.key);
            }

            if (!Boolean(element.priceVat)) {
              element.priceVat = 20;
            }
            this.purchasingcriterias(element);
          });
        }
        this.setState({
          ourExpectedPrices: Boolean(responsePricecs.data) ? responsePricecs.data : [],
          maxOurExpectedPrice: responsePricecs.data.length,
          selectedOurExpectedPrice: selectedOurExpectedPrice
        });
      }
    } else {
      this.setState({
        ourExpectedPrices: [],
        maxOurExpectedPrice: 0,
        selectedOurExpectedPrice: []
      });
    }
  };

  handleUnit = async (row, value) => {
    if (Boolean(value) && Boolean(value.unit) && value.unit.length > 0) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(item)) {
        let unitId = Boolean(value.unit) && value.unit.length > 0 ? value.unit[value.unit.length - 1] : null;
        let unitTypeId = Boolean(value.unit) && value.unit.length > 0 ? value.unit[0] : null;
        if (!isNaN(unitId) && !isNaN(unitTypeId)) {
          item.unitId = unitId;
          const unitType = row.unitSelectItems.find(p => p.value === unitTypeId);
          if (Boolean(unitType) && Boolean(unitType.children) && unitType.children.length > 0) {
            const unit = unitType.children.find(p => p.value === unitId);
            if (Boolean(unit)) {
              item.unit = unit.label;
            }
          }
          this.setState({
            ourExpectedPrices: newData
          });
        }
      }
    }
  };

  handlePriceListUnitPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.unitPrice === value.unitPrice) return;
        var newUnitPrice = item.unitPrice - value.unitPrice;
        if (Boolean(newUnitPrice) && newUnitPrice > 0) {
          row.unitPrice = newUnitPrice;
          row.oldUnitPrice = 0;
        }

        item.oldUnitPrice = item.unitPrice;
        item.unitPrice = value.unitPrice;
        item.price = item.quantity * item.unitPrice;
        this.setState({
          ourExpectedPrices: newData
        });
      }
    }
  };

  handlePriceListCurrencyOurExpectedPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.currency === value.currency) return;
        item.currency = value.currency;
        this.setState({
          ourExpectedPrices: newData
        });
      }
    }
  };

  handlePriceListQuantityOurExpectedPrice = async (row, value) => {
    if (Boolean(value)) {
      const maxOurExpectedPrice = this.state.maxOurExpectedPrice + 1;
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);

      console.log("item", item);
      console.log("value", value);

      if (Boolean(value) && Boolean(item)) {
        if (item.quantity === value.quantity) return;
        var newQuantity = item.quantity - value.quantity;
        if (Boolean(newQuantity) && newQuantity > 0) {
          row.quantity = newQuantity;
          row.oldQuantity = 0;
          row.key = maxOurExpectedPrice;
          var newObj = row;
          newObj.selected = false;
          newObj.sourceKey = item.key;
          newObj.unit = item.defaultUnit;
          newObj.unitId = item.defaultUnitId;
          newData.push(newObj);
        }
        item.oldQuantity = item.quantity;
        item.quantity = value.quantity;
        item.price = item.quantity * item.unitPrice;
        item.unit = item.defaultUnit;
        item.unitId = item.defaultUnitId;
        this.setState({
          ourExpectedPrices: newData,
          maxOurExpectedPrice: maxOurExpectedPrice
        });
        console.log("newData", newData);

        if (!(Boolean(newQuantity) && newQuantity > 0)) {
          this.setState({
            selectedQuantityKey: item.key,
            quantityDescription: item.quantityDescription,
            showQuantityDescriptionModal: true
          });
        }
      }
    }
  };

  handlePriceListPlannedDeliveryDate = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.planedDeliveryDate === value.planedDeliveryDate) return;
        item.planedDeliveryDate = value.planedDeliveryDate;
        this.setState({
          ourExpectedPrices: newData
        });
      }
    }
  };

  handlePriceListUnitOurExpectedPriceVat = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.id === item.id);
      if (Boolean(value) && Boolean(item)) {
        if (item.priceVat === value.priceVat) return;
        item.priceVat = value.priceVat;
        this.setState({
          ourExpectedPrices: newData
        });
      }
    }
  };

  handleRequiredQuantity = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.stockTableList];
      const item = newData.find(item => row.id === item.id);
      if (Boolean(value) && Boolean(item)) {
        if (item.requiredQuantity === value.requiredQuantity) return;
        item.requiredQuantity = value.requiredQuantity;
        this.setState({
          stockTableList: newData
        });
      }
    }
  };

  setOpenPanels = key => {
    this.setState({
      activePanelKey: key
    });
  };

  handleFormVendorSubmit = async values => {
    const newItem = {
      id: this.state.vendorLogId,
      ...values
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createVendor", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateVendor/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancelVendor();
      const { paginationVendor } = this.state;
      this.getVendor({ paginationVendor });
      showSuccess();
    }
  };

  handleStockIdChange = async value => {
    let stockId = Boolean(value) && value.length > 0 ? value[value.length - 1] : null;
    this.setState({
      stockId: stockId
    });
  };

  closeRfqVendorReply = () => {
    this.setState(
      {
        showVendorReplyModal: false
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  closeVendorScoring = () => {
    this.setState(
      {
        showVendorScoringModal: false
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  closeVendorEvaluation = () => {
    this.setState(
      {
        showEvaluationModal: false,
        showEvaluationNewModal: false
      },
      () => {
        setTimeout(() => {
          const { pagination } = this.state;
          this.restartTable({ pagination });
        }, 1000);
      }
    );
  };

  saveQuantityDescription = () => {
    const key = this.state.selectedQuantityKey;
    if (Boolean(key) && key > 0) {
      const ourExpectedPrices = this.state.ourExpectedPrices;
      var item = ourExpectedPrices.find(p => p.key === key);
      if (Boolean(item)) {
        item.quantityDescription = this.state.quantityDescription;

        this.setState({
          ourExpectedPrices: ourExpectedPrices,
          showQuantityDescriptionModal: false,
          quantityDescription: null,
          selectedQuantityKey: null
        });
      } else {
        showError("Item not found");
      }
    } else {
      showError("Not found");
    }
  };

  stockModal = async row => {
    this.setState(
      {
        projectTakeOffListId: row.id,
        modalStockShow: true,
        requiredQuantity: row.quantity,
        selectedRowKeysInventoryLog: [],
        equivalentItemsIncluded: false
      },
      () => {
        const { paginationStock } = this.state;
        this.restartTableStock({ paginationStock });
      }
    );
  };

  releaseFromStockModal = async row => {
    const selectedRowKeysInventoryLog = this.state.selectedRowKeysInventoryLog;
    if (Boolean(selectedRowKeysInventoryLog) && selectedRowKeysInventoryLog.length > 0) {
      var row = this.state.stockTableList.find(p => p.id === selectedRowKeysInventoryLog[0]);
      var availableQuantity = 0;
      var inStock = 0;
      var reservedQuantity = 0;
      var toBeReleasedQuantity = 0;
      if (Boolean(row.quantity) && row.quantity > 0) {
        inStock = row.quantity;
      }
      if (Boolean(row.reservedQuantity) && row.reservedQuantity > 0) {
        reservedQuantity = row.reservedQuantity;
      }
      if (Boolean(row.toBeReleasedQuantity) && row.toBeReleasedQuantity > 0) {
        toBeReleasedQuantity = row.toBeReleasedQuantity;
      }
      availableQuantity = inStock - reservedQuantity;

      this.setState(
        {
          showReleaseModal: true,
          inventoryLogId: row.id,
          availableQuantity: availableQuantity > 0 ? availableQuantity : 0
        },
        () => {
          if (Boolean(this.formRefRelease) && Boolean(this.formRefRelease.current)) {
            this.formRefRelease.current.setFieldsValue({
              ...row,
              availableQuantity: availableQuantity > 0 ? availableQuantity : 0
            });
          }
        }
      );
    } else {
      showWarning("Please select item");
    }
  };

  removeOurExpectedModal = async row => {
    this.setState({
      selectedRemoveOurExpectedPrice: row,
      modalRemoveOurExpectedShow: true
    });
  };

  selectedAddMoreItemsModal = () => { };

  removeOurExpected = () => {
    const { selectedRemoveOurExpectedPrice, ourExpectedPrices } = this.state;
    console.log("ourExpectedPrices", ourExpectedPrices);
    console.log("selectedRemoveOurExpectedPrice", selectedRemoveOurExpectedPrice);

    if (
      Boolean(selectedRemoveOurExpectedPrice) &&
      Boolean(selectedRemoveOurExpectedPrice.sourceKey) &&
      selectedRemoveOurExpectedPrice.sourceKey > 0
    ) {
      let selectedRow = ourExpectedPrices.find(p => p.key === selectedRemoveOurExpectedPrice.key);
      if (Boolean(selectedRow)) {
        selectedRow.active = false;
        selectedRow.oldQuantity -= selectedRemoveOurExpectedPrice.quantity;
      }

      // let selectedSourceRow = ourExpectedPrices.filter(p => p.sourceKey === selectedRemoveOurExpectedPrice.key);
      // if (Boolean(selectedSourceRow) && selectedSourceRow.length > 0) {
      //   selectedSourceRow.forEach(element => {
      //     element.active = false;
      //   });
      // }

      let sourceRow = ourExpectedPrices.find(p => p.key === selectedRemoveOurExpectedPrice.sourceKey);
      if (Boolean(sourceRow) && sourceRow.active) {
        sourceRow.quantity += selectedRemoveOurExpectedPrice.quantity;
        sourceRow.oldQuantity = 0;
      }
      console.log("sourceRow", sourceRow);
      this.setState({
        ourExpectedPrices: ourExpectedPrices,
        modalRemoveOurExpectedShow: false,
        selectedRemoveOurExpectedPrice: null
      });
    } else {
      showError("Selected row not found.");
    }
  };

  addNewLine = () => {
    const { poQuery } = this.state;
    let key = 0;
    if (Boolean(poQuery) && poQuery.length > 0) {
      key = poQuery[poQuery.length - 1].key + 1;
    }
    if (key === 0) {
      poQuery.push({ key: key });
    } else poQuery.push({ key: key });

    this.setState({ poQuery });
  };

  saveReleaseSubmit = async values => {
    if (Boolean(this.state.inventoryLogMainId) && this.state.inventoryLogMainId > 0) {
      const newItem = {
        inventoryLogMainId: this.state.inventoryLogMainId,
        ...values
      };

      let response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/addReleaseQuantity", newItem);

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          showReleaseModal: false
        });
        const { paginationStock } = this.state;
        this.restartTableStock({ paginationStock });
        showSuccess();
      }
    } else {
      showError("Inventory Log Main Not found");
    }
  };

  reserveStockQuantitiesModal = async () => {
    const selectedRowKeysInventoryLog = this.state.selectedRowKeysInventoryLog;
    if (Boolean(selectedRowKeysInventoryLog) && selectedRowKeysInventoryLog.length > 0) {
      var row = this.state.stockTableList.find(p => p.id === selectedRowKeysInventoryLog[0]);
      var availableQuantity = 0;
      var inStock = 0;
      var reservedQuantity = 0;
      var toBeReleasedQuantity = 0;
      if (Boolean(row.quantity) && row.quantity > 0) {
        inStock = row.quantity;
      }
      if (Boolean(row.reservedQuantity) && row.reservedQuantity > 0) {
        reservedQuantity = row.reservedQuantity;
      }
      if (Boolean(row.toBeReleasedQuantity) && row.toBeReleasedQuantity > 0) {
        toBeReleasedQuantity = row.toBeReleasedQuantity;
      }
      availableQuantity = inStock - toBeReleasedQuantity;

      this.setState(
        {
          showReserveModal: true,
          inventoryLogId: row.id,
          availableQuantity: availableQuantity > 0 ? availableQuantity : 0
        },
        () => {
          if (Boolean(this.formRefReserve) && Boolean(this.formRefReserve.current)) {
            this.formRefReserve.current.setFieldsValue({
              ...row,
              availableQuantity: availableQuantity > 0 ? availableQuantity : 0
            });
          }
        }
      );
    } else {
      showWarning("Please select item");
    }
  };

  saveReserveSubmit = async values => {
    if (Boolean(this.state.inventoryLogMainId) && this.state.inventoryLogMainId > 0) {
      const newItem = {
        inventoryLogMainId: this.state.inventoryLogMainId,
        ...values
      };

      let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/addReserveQuantity", newItem);

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          showReserveModal: false
        });
        const { paginationStock } = this.state;
        this.restartTableStock({ paginationStock });
        showSuccess();
      }
    } else {
      showError("Inventory Log Main Not found");
    }
  };

  async purchasingcriterias(element) {
    if (Boolean(element.stockCardId) && element.stockCardId > 0) {
      let responsePurchaseCriteria = await handleRequest("GET", "/api/" + this.state.controllerName + "/purchasingcriterias/" + element.stockCardId);
      if (responsePurchaseCriteria.type === "ERROR") {
      } else {
        element.unitSelectItems =
          Boolean(responsePurchaseCriteria.data) && responsePurchaseCriteria.data.length > 0 ? responsePurchaseCriteria.data : [];
      }
    } else {
      element.unitSelectItems = [];
    }
  }
  handleDateFormatTypeChange(index, value) {
    const { poQuery } = this.state;
    poQuery[index].dateFormatType = value;
    this.setState({ poQuery });
  }

  handleAlterationChange(index, value) {
    const { poQuery } = this.state;
    poQuery[index].alteration = value;
    this.setState({ poQuery });
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search and click enter`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
      </div>
    ),
    filterIcon: filtered => <FilterOutlined style={{ color: filtered ? "#1890ff" : "#bfbfbf" }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  render() {
    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };
    const { poQuery } = this.state;

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.printButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };

    // const handleCheckboxChange = (index, checked) => {
    //   const newCheckedStates = [...checkedStates];
    //   newCheckedStates[index] = checked;

    //   this.setState({
    //     checkedStates: newCheckedStates,
    //   });
    // };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout1 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      style: {
        marginBottom: 0
      }
    };

    const layout2 = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    };

    const layoutDimension = {
      labelCol: { span: 9 },
      wrapperCol: { span: 8 }
    };

    const { selectedRowKeysVendorIds } = this.state;
    const rowSelectionVendor = {
      selectedRowKeys: selectedRowKeysVendorIds,
      onChange: this.onSelectChangeVendor
    };

    const { selectedRowKeysNotRelated } = this.state;
    const rowSelectionVendorNotRelated = {
      selectedRowKeys: selectedRowKeysNotRelated,
      onChange: this.onSelectChangeVendorNotRelated
    };

    const { selectedRowKeysAddMoreRFQ } = this.state;
    const rowSelectionAddMoreRFQ = {
      selectedRowKeys: selectedRowKeysAddMoreRFQ,
      onChange: this.onSelectChangeAddMoreRFQ
    };

    const { selectedOurExpectedPrice } = this.state;
    const rowSelectionOurExpectedPrice = {
      selectedRowKeys: selectedOurExpectedPrice,
      onChange: this.onSelectChangeOurExpectedPrice
    };

    const { selectedRowKeysInventoryLog } = this.state;
    const rowSelectionStock = {
      selectedRowKeys: selectedRowKeysInventoryLog,
      onChange: this.onSelectStockModal
    };

    const mapContainerStyle = {
      height: "300px",
      width: "100%"
    };

    const listUploadProps = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }

      return e && e.fileList;
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      },

      header: {
        cell: ResizableTitle
      }
    };
    const mergeColumnsOurExpectedPriceModal =
      Boolean(this.state.columnsOurExpectedPriceModal) && this.state.columnsOurExpectedPriceModal.length > 0
        ? this.state.columnsOurExpectedPriceModal.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columnsOurExpectedPriceModal) &&
        columnsOurExpectedPriceModal.length > 0 &&
        columnsOurExpectedPriceModal.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const columnsOurExpectedPrice = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 50,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Project" defaultMessage="Project" />,
        key: "companyProjectName",
        width: 100,
        render: record => {
          return record.companyProjectName;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.ItemDescription" defaultMessage="Item Description" />,
        key: "itemDescriptionRename",
        width: 100,
        render: record => {
          return record.itemDescriptionRename;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.actionType) ? this.state.pagination.actionType : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  actionType: e.target.value,
                  actionTypeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.actionTypeColor) ? this.state.pagination.actionTypeColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Quantity" defaultMessage="Quantity" />,
        key: "quantity",
        width: 100,
        render: record => {
          let weight = "";
          if (record.unit && Boolean(record.weight) && Boolean(record.quantity)) {
            if (Boolean(this.state.allUnitSelectItems) && this.state.allUnitSelectItems.length > 0) {
              let unit = this.state.allUnitSelectItems.find(p => p.abbreviation === record.unit);
              if (Boolean(unit.abbreviation) && unit.abbreviation === "ad") {
                weight = record.quantity;
              } else {
                if (unit.unitTypeName === "Length") {
                  if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                    weight = (record.length * record.quantity) / unit.coefficent;
                  } else {
                    weight = record.length * record.quantity;
                  }
                } else {
                  if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                    weight = (record.quantity * record.weight) / unit.coefficent;
                  } else {
                    weight = record.weight * record.quantity;
                  }
                }
              }
            }
          }
          //let quantity = record.quantity;
          let quantity = weight.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");
          if (!Boolean(quantity)) {
            quantity = record.quantity;
          }

          return Boolean(record.oldQuantity) && record.oldQuantity > 0 ? (
            <Badge showZero offset={[25, -15]} overflowCount={record.oldQuantity + 1} count={record.oldQuantity}>
              {quantity}
            </Badge>
          ) : (
            quantity
          );
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "quantity",
          title: <FormattedMessage id="RequestForQuotation.quantity" defaultMessage="Quantity" />,
          handleSave: this.handlePriceListQuantityOurExpectedPrice
        }),
        ...this.getColumnSearchProps("quantity"),
        sorter: (a, b) => Boolean(a.quantity) && Boolean(b.quantity) && (a.quantity + "").localeCompare(b.quantity + "")
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Unit" defaultMessage="Unit" />,
        key: "unit",
        width: 100,
        render: record => {
          let weight = "";
          if (record.unit && Boolean(record.weight) && Boolean(record.quantity)) {
            if (Boolean(this.state.allUnitSelectItems) && this.state.allUnitSelectItems.length > 0) {
              let unit = this.state.allUnitSelectItems.find(p => p.abbreviation === record.unit);
              if (Boolean(unit.abbreviation) && unit.abbreviation === "ad") {
                weight = record.quantity;
              } else {
                if (unit.unitTypeName === "Length") {
                  if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                    weight = (record.length * record.quantity) / unit.coefficent;
                  } else {
                    weight = record.length * record.quantity;
                  }
                } else {
                  if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                    weight = (record.quantity * record.weight) / unit.coefficent;
                  } else {
                    weight = record.weight * record.quantity;
                  }
                }
              }
            }
          }
          return record.unit;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "unit",
          title: <FormattedMessage id="RequestForQuotation.Unit" defaultMessage="Unit" />,
          handleSave: this.handleUnit
        }),
        ...this.getColumnSearchProps("unit"),
        sorter: (a, b) => Boolean(a.unit) && Boolean(b.unit) && a.unit.localeCompare(b.unit)
      },
      {
        title: <FormattedMessage id="RequestForQuotation.UnitPrice" defaultMessage="Unit Price" />,
        key: "unitPrice",
        width: 100,
        render: record => {
          // return Boolean(record.oldUnitPrice) && record.oldUnitPrice > 0 ? (
          //   <Badge showZero offset={[25, -15]} overflowCount={record.oldUnitPrice + 1} count={record.oldUnitPrice}>
          //     {record.unitPrice}
          //   </Badge>
          // ) : (
          //   record.unitPrice
          // );
          return record.unitPrice;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "unitPrice",
          title: <FormattedMessage id="RequestForQuotation.unitPrice" defaultMessage="Unit Price" />,
          handleSave: this.handlePriceListUnitPrice
        }),
        ...this.getColumnSearchProps("unitPrice"),
        sorter: (a, b) => Boolean(a.unitPrice) && Boolean(b.unitPrice) && (a.unitPrice + "").localeCompare(b.unitPrice + "")
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Currency" defaultMessage="Currency" />,
        key: "currency",
        width: 100,
        render: record => {
          return record.currency;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "currency",
          title: <FormattedMessage id="RequestForQuotation.currency" defaultMessage="Currency" />,
          handleSave: this.handlePriceListCurrencyOurExpectedPrice
        })
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Price" defaultMessage="Price" />,
        key: "price",
        width: 100,
        render: record => {
          return record.price;
        },
        ...this.getColumnSearchProps("price"),
        sorter: (a, b) => Boolean(a.price) && Boolean(b.price) && (a.price + "").localeCompare(b.price + "")
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Vat" defaultMessage="Vat(%)" />,
        key: "priceVat",
        width: 100,
        render: record => {
          return record.priceVat;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "priceVat",
          title: <FormattedMessage id="RequestForQuotation.priceVat" defaultMessage="Vat(%)" />,
          handleSave: this.handlePriceListUnitOurExpectedPriceVat
        }),
        ...this.getColumnSearchProps("priceVat"),
        sorter: (a, b) => Boolean(a.priceVat) && Boolean(b.priceVat) && (a.priceVat + "").localeCompare(b.priceVat + "")
      },
      {
        title: <FormattedMessage id="RequestForQuotation.PlannedDeliveryDate" defaultMessage="Planned Delivery Date" />,
        key: "planedDeliveryDate",
        width: 100,
        render: record => {
          return record.planedDeliveryDate;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "planedDeliveryDate",
          title: <FormattedMessage id="RequestForQuotation.planedDeliveryDate" defaultMessage="Planned Delivery Date" />,
          handleSave: this.handlePriceListPlannedDeliveryDate
        }),
        ...this.getColumnSearchProps("planedDeliveryDate"),
        sorter: (a, b) =>
          Boolean(a.planedDeliveryDate) && Boolean(b.planedDeliveryDate) && (a.planedDeliveryDate + "").localeCompare(b.planedDeliveryDate + "")
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Description" defaultMessage="Description" />,
        key: "quantityDescription",
        width: 100,
        render: record => {
          return record.quantityDescription;
        },
        ...this.getColumnSearchProps("quantityDescription"),
        sorter: (a, b) =>
          Boolean(a.quantityDescription) && Boolean(b.quantityDescription) && (a.quantityDescription + "").localeCompare(b.quantityDescription + "")
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Stock" defaultMessage="Stock" />,
        key: "id",
        width: 100,
        render: record => {
          return (
            <AntButton
              onClick={() => this.stockModal(record)}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<ZoomInOutlined fontSize="large" />}
            ></AntButton>
          );
        }
      },
      {
        title: "",
        key: "id",
        width: 100,
        render: record => {
          return Boolean(record.sourceKey) && record.sourceKey > 0 ? (
            <AntButton
              onClick={() => this.removeOurExpectedModal(record)}
              style={{ border: "0px", color: "red" }}
              icon={<MinusCircleOutlined fontSize="large" />}
            ></AntButton>
          ) : (
            ""
          );
        }
      }
    ];

    const columnsAddMoreItems = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 50,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Project" defaultMessage="Project" />,
        key: "companyProjectName",
        width: 100,
        render: record => {
          return record.companyProjectName;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.ItemDescription" defaultMessage="Item Description" />,
        key: "itemDescriptionRename",
        width: 100,
        render: record => {
          return record.itemDescriptionRename;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.actionType) ? this.state.pagination.actionType : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  actionType: e.target.value,
                  actionTypeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.actionTypeColor) ? this.state.pagination.actionTypeColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Quantity" defaultMessage="Quantity" />,
        key: "quantity",
        width: 100,
        render: record => {
          let weight = "";
          if (record.unit && Boolean(record.weight) && Boolean(record.quantity)) {
            if (Boolean(this.state.allUnitSelectItems) && this.state.allUnitSelectItems.length > 0) {
              let unit = this.state.allUnitSelectItems.find(p => p.abbreviation === record.unit);
              if (Boolean(unit.abbreviation) && unit.abbreviation === "ad") {
                weight = record.quantity;
              } else {
                if (unit.unitTypeName === "Length") {
                  if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                    weight = (record.length * record.quantity) / unit.coefficent;
                  } else {
                    weight = record.length * record.quantity;
                  }
                } else {
                  if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                    weight = (record.quantity * record.weight) / unit.coefficent;
                  } else {
                    weight = record.weight * record.quantity;
                  }
                }
              }
            }
          }
          //let quantity = record.quantity;
          let quantity = weight.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");
          if (!Boolean(quantity)) {
            quantity = record.quantity;
          }

          return Boolean(record.oldQuantity) && record.oldQuantity > 0 ? (
            <Badge showZero offset={[25, -15]} overflowCount={record.oldQuantity + 1} count={record.oldQuantity}>
              {quantity}
            </Badge>
          ) : (
            quantity
          );
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "quantity",
          title: <FormattedMessage id="RequestForQuotation.quantity" defaultMessage="Quantity" />,
          handleSave: this.handlePriceListQuantityOurExpectedPrice
        }),
        ...this.getColumnSearchProps("quantity"),
        sorter: (a, b) => Boolean(a.quantity) && Boolean(b.quantity) && (a.quantity + "").localeCompare(b.quantity + "")
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Unit" defaultMessage="Unit" />,
        key: "unit",
        width: 100,
        render: record => {
          let weight = "";
          if (record.unit && Boolean(record.weight) && Boolean(record.quantity)) {
            if (Boolean(this.state.allUnitSelectItems) && this.state.allUnitSelectItems.length > 0) {
              let unit = this.state.allUnitSelectItems.find(p => p.abbreviation === record.unit);
              if (Boolean(unit.abbreviation) && unit.abbreviation === "ad") {
                weight = record.quantity;
              } else {
                if (unit.unitTypeName === "Length") {
                  if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                    weight = (record.length * record.quantity) / unit.coefficent;
                  } else {
                    weight = record.length * record.quantity;
                  }
                } else {
                  if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                    weight = (record.quantity * record.weight) / unit.coefficent;
                  } else {
                    weight = record.weight * record.quantity;
                  }
                }
              }
            }
          }
          return record.unit;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "unit",
          title: <FormattedMessage id="RequestForQuotation.Unit" defaultMessage="Unit" />,
          handleSave: this.handleUnit
        }),
        ...this.getColumnSearchProps("unit"),
        sorter: (a, b) => Boolean(a.unit) && Boolean(b.unit) && a.unit.localeCompare(b.unit)
      },
      {
        title: <FormattedMessage id="RequestForQuotation.UnitPrice" defaultMessage="Unit Price" />,
        key: "unitPrice",
        width: 100,
        render: record => {
          // return Boolean(record.oldUnitPrice) && record.oldUnitPrice > 0 ? (
          //   <Badge showZero offset={[25, -15]} overflowCount={record.oldUnitPrice + 1} count={record.oldUnitPrice}>
          //     {record.unitPrice}
          //   </Badge>
          // ) : (
          //   record.unitPrice
          // );
          return record.unitPrice;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "unitPrice",
          title: <FormattedMessage id="RequestForQuotation.unitPrice" defaultMessage="Unit Price" />,
          handleSave: this.handlePriceListUnitPrice
        }),
        ...this.getColumnSearchProps("unitPrice"),
        sorter: (a, b) => Boolean(a.unitPrice) && Boolean(b.unitPrice) && (a.unitPrice + "").localeCompare(b.unitPrice + "")
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Currency" defaultMessage="Currency" />,
        key: "currency",
        width: 100,
        render: record => {
          return record.currency;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "currency",
          title: <FormattedMessage id="RequestForQuotation.currency" defaultMessage="Currency" />,
          handleSave: this.handlePriceListCurrencyOurExpectedPrice
        })
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Price" defaultMessage="Price" />,
        key: "price",
        width: 100,
        render: record => {
          return record.price;
        },
        ...this.getColumnSearchProps("price"),
        sorter: (a, b) => Boolean(a.price) && Boolean(b.price) && (a.price + "").localeCompare(b.price + "")
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Vat" defaultMessage="Vat(%)" />,
        key: "priceVat",
        width: 100,
        render: record => {
          return record.priceVat;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "priceVat",
          title: <FormattedMessage id="RequestForQuotation.priceVat" defaultMessage="Vat(%)" />,
          handleSave: this.handlePriceListUnitOurExpectedPriceVat
        }),
        ...this.getColumnSearchProps("priceVat"),
        sorter: (a, b) => Boolean(a.priceVat) && Boolean(b.priceVat) && (a.priceVat + "").localeCompare(b.priceVat + "")
      },
      {
        title: <FormattedMessage id="RequestForQuotation.PlannedDeliveryDate" defaultMessage="Planned Delivery Date" />,
        key: "planedDeliveryDate",
        width: 100,
        render: record => {
          return record.planedDeliveryDate;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "planedDeliveryDate",
          title: <FormattedMessage id="RequestForQuotation.planedDeliveryDate" defaultMessage="Planned Delivery Date" />,
          handleSave: this.handlePriceListPlannedDeliveryDate
        }),
        ...this.getColumnSearchProps("planedDeliveryDate"),
        sorter: (a, b) =>
          Boolean(a.planedDeliveryDate) && Boolean(b.planedDeliveryDate) && (a.planedDeliveryDate + "").localeCompare(b.planedDeliveryDate + "")
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Description" defaultMessage="Description" />,
        key: "quantityDescription",
        width: 100,
        render: record => {
          return record.quantityDescription;
        },
        ...this.getColumnSearchProps("quantityDescription"),
        sorter: (a, b) =>
          Boolean(a.quantityDescription) && Boolean(b.quantityDescription) && (a.quantityDescription + "").localeCompare(b.quantityDescription + "")
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Stock" defaultMessage="Stock" />,
        key: "id",
        width: 100,
        render: record => {
          return (
            <AntButton
              onClick={() => this.stockModal(record)}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<ZoomInOutlined fontSize="large" />}
            ></AntButton>
          );
        }
      },
      {
        title: "",
        key: "id",
        width: 100,
        render: record => {
          return Boolean(record.sourceKey) && record.sourceKey > 0 ? (
            <AntButton
              onClick={() => this.removeOurExpectedModal(record)}
              style={{ border: "0px", color: "red" }}
              icon={<MinusCircleOutlined fontSize="large" />}
            ></AntButton>
          ) : (
            ""
          );
        }
      }
    ];

    const columns = [
      {
        key: "key",
        responsive: ["md"],
        fixed: "left",
        width: 70,
        render: record => {
          return this.actionTemplate(record);
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        })
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        fixed: "left",
        responsive: ["md"],
        width: 70,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        })
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Project" defaultMessage="Project" />,
        fixed: "left",
        key: "companyProjectId",
        ellipsis: true,
        width: 100,
        render: record => {
          return <Tooltip title={record.companyProjectName}>{record.companyProjectName}</Tooltip>;
        },
        ...getColumnFilter({
          inputName: "companyProjectId",
          inputType: "SELECT_ADDABLE",
          selectItems: Boolean(this.state["companyProjectIdSelectItems"]) ? this.state["companyProjectIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),

        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        })
      },
      {
        title: <FormattedMessage id="RequestForQuotation.RFQNo" defaultMessage="RFQ No" />,
        key: "id",
        ellipsis: true,
        fixed: "left",
        width: 100,
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: record.status === "Deleted" ? "#d3d3d3" : "blue",
                  textDecoration: "underline"
                }}
                onClick={e => this.generateReport(record)}
              >
                {record.rfqNo}
              </div>
            </React.Fragment>
          );
        },
        ...getColumnFilter({
          inputName: "id",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        })
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Status" defaultMessage="Status" />,
        key: "statusName",
        fixed: "left",
        width: 120,
        ellipsis: true,
        render: record => {
          return (
            Boolean(record.status) && (
              <React.Fragment>
                {record.status === "Evaluation" ? (
                  <div
                    style={{
                      cursor: "pointer",
                      color: record.status === "Deleted" ? "#d3d3d3" : "blue",
                      textDecoration: "underline",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <span onClick={e => this.generateReportEvaluation(record)} style={{ marginRight: "5px" }}>
                      <PictureAsPdf />
                    </span>
                    <span
                      onClick={e => this.rfqStatus(record)}
                      style={{
                        cursor: "pointer",
                        color: record.status === "Deleted" ? "#d3d3d3" : "blue",
                        textDecoration: "underline",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      {record.statusName}
                    </span>
                  </div>
                ) : (
                  <div
                    style={{
                      cursor: "pointer",
                      color: record.status === "Deleted" ? "#d3d3d3" : "blue",
                      textDecoration: "underline",
                      display: "flex",
                      alignItems: "center"
                    }}
                    onClick={e => this.rfqStatus(record)}
                  >
                    {record.statusName}
                  </div>
                )}
              </React.Fragment>
            )
          );
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        })
      },
      {
        title: <FormattedMessage id="General.RevNo" defaultMessage="Rev No" />,
        key: "revNo",
        width: 50,
        ellipsis: true,
        render: record => {
          return (
            <React.Fragment>
              {Boolean(record.revNo) && record.revNo > 0 ? (
                <div
                  style={{
                    cursor: "pointer",
                    color: record.status === "Deleted" ? "#d3d3d3" : "blue",
                    textDecoration: "underline"
                  }}
                  onClick={e => this.showRevisedList(record)}
                >
                  {record.revNo}
                </div>
              ) : (
                record.revNo
              )}
            </React.Fragment>
          );
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        }),
        align: "center"
      },
      {
        title: <FormattedMessage id="RequestForQuotation.ItemDescription" defaultMessage="Item Description" />,
        key: "id",
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.itemDescriptionName}>{record.itemDescriptionName}</Tooltip>;
        },
        ...getColumnFilter({
          inputName: "id",
          inputType: "SELECT_ADDABLE",
          selectItems: Boolean(this.state["itemDescriptionSelectItems"]) ? this.state["itemDescriptionSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        })
      },
      {
        title: <FormattedMessage id="RequestForQuotation.DeliveredAmount" defaultMessage="Delivered Amount" />,
        key: "deliveryAmount",
        ellipsis: true,
        width: 150,
        render: record => {
          return (
            <Progress
              type="circle"
              percent={record.deliveryAmount}
              success={{ percent: record.deliveryAmount }}
              format={() => (Boolean(record.deliveryAmount) ? record.deliveryAmount : 0)}
              width={85}
            />
          );
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        })
      },
      {
        title: <FormattedMessage id="RequestForQuotation.DateOfGeneration" defaultMessage="Date of Generation" />,
        key: "dateCreated",
        ellipsis: true,
        width: 200,
        render: record => {
          return (
            <Tooltip title={moment(record.dateCreated).format("DD-MM-YYYY HH:mm:ss")}>
              {moment(record.dateCreated).format("DD-MM-YYYY HH:mm:ss")}
            </Tooltip>
          );
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        })
      },
      {
        title: <FormattedMessage id="RequestForQuotation.RequestDate" defaultMessage="Request Date" />,
        key: "requestDate",
        ellipsis: true,
        width: 150,
        render: record => {
          return (
            <Tooltip title={record.sentToVendorsDate ? moment(record.sentToVendorsDate).format("DD-MM-YYYY HH:mm:ss") : ""}>
              {record.sentToVendorsDate ? moment(record.sentToVendorsDate).format("DD-MM-YYYY HH:mm:ss") : ""}
            </Tooltip>
          );
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        })
      },
      {
        title: <FormattedMessage id="RequestForQuotation.DueDate" defaultMessage="Due Date" />,
        key: "validityValue",
        width: 240,
        ellipsis: true,
        render: record => {
          return record.dueDate;
          //const futureDate = moment(record.dueDate);
          //
          //const now = Date.now();
          //const countdownDuration = futureDate.diff(now, 'day');
          //return (
          //  <Countdown
          //    value={Date.now()+record.dueDateName}
          //    style={{ fontSize: '2px' }}
          //    format="D day H hour m :  s "
          //    onFinish={() => "Countdown Finished"}
          //  />
          //);
        },

        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        })
      },
      {
        title: <FormattedMessage id="RequestForQuotation.RFQDetails" defaultMessage="RFQ Details" />,
        key: "prices",
        width: 100,
        render: record => {
          return (
            <AntButton
              onClick={() => this.detailModal(record)}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<ZoomInOutlined fontSize="large" />}
            ></AntButton>
          );
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Responses" defaultMessage="Responses" />,
        key: "vendorResponseList",
        width: 110,
        render: record => {
          return (
            <Tooltip
              color={"white"}
              title={
                record.vendorIds &&
                record.vendorIds.length > 0 &&
                record.vendorIds.map((element, index) => {
                  return (
                    <div
                      key={index}
                      className="w-100"
                      style={{
                        color:
                          Boolean(record.vendorResponseList) &&
                            record.vendorResponseList.length > 0 &&
                            Boolean(record.vendorResponseList.find(p => p.vendorId === element.vendorId))
                            ? "black"
                            : "#d3d3d3"
                      }}
                    >
                      {index + 1 + ". " + element.vendorName}
                    </div>
                  );
                })
              }
            >
              {!Boolean(record.status) ||
                record.status === "TenderingClosed" ||
                record.status === "Revise" ||
                record.status === "Created" ||
                record.status === "Evaluation" ? (
                <div>{record.vendorResponseList ? record.vendorResponseList.length : 0}</div>
              ) : (
                <div
                  style={{
                    cursor: "pointer",
                    color: record.status === "Deleted" ? "#d3d3d3" : "blue",
                    textDecoration: "underline"
                  }}
                  onClick={e => this.showVendorResponsesModal(record)}
                >
                  {record.vendorResponseList ? record.vendorResponseList.length : 0}
                </div>
              )}
            </Tooltip>
          );
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        }),
        align: "center"
      },
      {
        title: <FormattedMessage id="RequestForQuotation.replyNum" defaultMessage="Replies" />,
        key: "requestedVendorList",
        width: 100,
        render: record => {
          return (
            <Tooltip
              color={"white"}
              title={
                record.vendorHasReplyIds &&
                record.vendorHasReplyIds.length > 0 &&
                record.vendorHasReplyIds.map((element, index) => {
                  return (
                    <div key={index} className="w-100" style={{ color: element.vendorHasReply ? "black" : "#d3d3d3" }}>
                      {index + 1 + ". " + element.vendorName}
                    </div>
                  );
                })
              }
            >
              {!Boolean(record.status) ||
                record.status === "TenderingClosed" ||
                record.status === "Revise" ||
                record.status === "Created" ||
                record.status === "Evaluation" ? (
                <div>{record.vendorHasReplyIds ? record.vendorHasReplyIds.length : 0}</div>
              ) : (
                <div
                  style={{
                    cursor: "pointer",
                    color: record.status === "Deleted" ? "#d3d3d3" : "blue",
                    textDecoration: "underline"
                  }}
                  onClick={e => this.showVendorResponsesModal(record)}
                >
                  {record.vendorHasReplyIds ? record.vendorHasReplyIds.length : 0}
                </div>
              )}
            </Tooltip>
          );
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        }),
        align: "center"
      },
      {
        title: <FormattedMessage id="General.LastUpdate" defaultMessage="Last Update" />,
        key: "lastUpdated",
        width: 180,
        render: record => {
          return (
            Boolean(record.remarksName) &&
            Boolean(record.remarksDate) && (
              <React.Fragment>
                <div
                  style={{
                    cursor: "pointer",
                    color: record.status === "Deleted" ? "#d3d3d3" : "blue",
                    textDecoration: "underline"
                  }}
                  onClick={e => this.rfqHistory(record)}
                >
                  {moment(record.remarksDate).format("DD-MM-YYYY HH:mm:ss")}
                </div>
              </React.Fragment>
            )
          );
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        })
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Remarks" defaultMessage="Remarks" />,
        key: "remarksName",
        width: 100,
        render: record => {
          return "";
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        })
      }
    ];

    const columnsStock = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.paginationStock.pageSize * (this.state.paginationStock.current - 1);
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Stock" defaultMessage="Stock" />,
        key: "stockId",
        width: 100,
        render: record => {
          return record.stockName;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.ItemDescription" defaultMessage="Item Description" />,
        key: "projectTakeOffListId",
        width: 100,
        render: record => {
          return record.itemName;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.RequiredQuantity" defaultMessage="Required Quantity" />,
        key: "requiredQuantity",
        width: 100,
        render: record => {
          return record.requiredQuantity;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.inStock" defaultMessage="In Stock" />,
        key: "quantity",
        width: 100,
        render: record => {
          return record.quantity;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.ReservedQuantity" defaultMessage="Reserved Quantity" />,
        key: "reservedQuantity",
        width: 100,
        render: record => {
          return record.reservedQuantity;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.ToBeReleasedQuantity" defaultMessage="To Be Released Quantity" />,
        key: "toBeReleasedQuantity",
        width: 100,
        render: record => {
          return record.toBeReleasedQuantity;
        }
      },

      {
        title: <FormattedMessage id="RequestForQuotation.availableQuantity" defaultMessage="Available Quantity" />,
        key: "availableQuantity",
        width: 100,
        render: record => {
          if (record.movementType === "RECEIVE") {
            let avaliableQuantity = record.quantity - (record.reservedQuantity + record.toBeReleasedQuantity);
            return avaliableQuantity > 0 ? avaliableQuantity : 0;
          }
        }
      }
    ];

    const columnsStockSub = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.stockName" defaultMessage="Stock" />,
        key: "stockName",
        render: record => {
          return record.stockName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.stockName) ? this.state.pagination.stockName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  stockName: e.target.value,
                  stockNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.stockNameColor) ? this.state.pagination.stockNameColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="RequestForQuotation.itemName" defaultMessage="Item Description" />,
        key: "itemName",
        width: 100,
        render: record => {
          return record.itemName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.itemName) ? this.state.pagination.itemName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  itemName: e.target.value,
                  itemNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.itemNameColor) ? this.state.pagination.itemNameColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="RequestForQuotation.codeName" defaultMessage="Code" />,
        key: "codeName",
        width: 100,
        render: record => {
          return record.codeName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.codeName) ? this.state.pagination.codeName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  codeName: e.target.value,
                  codeNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.codeNameColor) ? this.state.pagination.codeNameColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="RequestForQuotation.dimensionName" defaultMessage="Dimension" />,
        key: "dimensionName",
        width: 100,
        render: record => {
          return record.dimensionName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.dimensionName) ? this.state.pagination.dimensionName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  dimensionName: e.target.value,
                  dimensionNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.dimensionNameColor) ? this.state.pagination.dimensionNameColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="RequestForQuotation.quantity" defaultMessage="In Stock" />,
        key: "quantity",
        width: 100,
        render: record => {
          if (record.movementType === "RECEIVE") {
            return <Text type="success">{record.quantity}</Text>;
          } else {
            return <Text type="danger">{record.quantity}</Text>;
          }
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.manufacturer" defaultMessage="Manufacturer" />,
        key: "manufacturer",
        width: 100,
        render: record => {
          return record.manufacturer;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.manufacturer) ? this.state.pagination.manufacturer : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  manufacturer: e.target.value,
                  manufacturerColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.manufacturerColor) ? this.state.pagination.manufacturerColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="RequestForQuotation.status" defaultMessage="Status" />,
        key: "status",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.quantity" defaultMessage="Min. Req. Stock" />,
        key: "minReqQuantity",
        width: 100,
        render: record => {
          return record.minReqQuantity;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.purposeOfUse" defaultMessage="Purpose of Use" />,
        key: "purposeOfUse",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.dateOfPlannedUsage" defaultMessage="Date of Planned Usage" />,
        key: "dateOfPlannedUsage",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.source" defaultMessage="Source" />,
        key: "source",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.remarks" defaultMessage="Remarks" />,
        key: "remarks",
        width: 100,
        render: record => {
          return "";
        }
      }
    ];
    const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30; // Dayjs is also OK

    const revisedColumns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        fixed: "left",
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.RFQNo" defaultMessage="RFQ No" />,
        key: "requestForQuotationId",
        fixed: "left",
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline"
                }}
                onClick={e => this.generateReportRevision(record)}
              >
                <Tag color={"blue"}>{record.rfqNo}</Tag>
              </div>
            </React.Fragment>
          );
        }
      },
      {
        title: <FormattedMessage id="General.RevNo" defaultMessage="Rev No" />,
        fixed: "left",
        width: 100,
        key: "revNo",
        render: record => {
          return record.revNo;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.ItemDescription" defaultMessage="Item Description" />,
        key: "projectTakeOffName",
        width: 100,
        render: record => {
          return record.projectTakeOffName;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.DateOfGeneration" defaultMessage="Date of Generation" />,
        key: "dateCreated",
        width: 100,
        render: record => {
          return moment(record.dateCreated).format("DD-MM-YYYY HH:mm:ss");
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.RequestDate" defaultMessage="Request Date" />,
        key: "requestDate",
        width: 100,
        className: true ? "d-none" : "",
        render: record => {
          return record.sentToVendorsDate ? moment(record.sentToVendorsDate).format("DD-MM-YYYY HH:mm:ss") : "";
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.DueDate" defaultMessage="Due Date" />,
        key: "validityValue",
        width: 100,
        render: record => {
          return Boolean(record.sentToVendorsDate) && Boolean(record.validityValue) ? record.dueDate : "";
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Vendors" defaultMessage="Vendors" />,
        key: "requestedVendorList",
        width: 100,
        render: record => {
          return (
            <Tooltip
              title={record.vendorIds.map((element, index) => {
                return (
                  <div key={index} className="w-100">
                    {index + 1 + ". " + element.vendorName}
                  </div>
                );
              })}
            >
              <Tag color={"default"}>{record.vendorIds ? record.vendorIds.length : 0}</Tag>
            </Tooltip>
          );
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Responses" defaultMessage="Responses" />,
        key: "vendorResponseList",
        width: 100,
        render: record => {
          return (
            <Tooltip
              title={record.vendorResponseList.map((element, index) => {
                return (
                  <div key={index} className="w-100">
                    {index + 1 + ". " + element.vendorName}
                  </div>
                );
              })}
            >
              <Tag color={"default"}>{record.vendorResponseList ? record.vendorResponseList.length : 0}</Tag>
            </Tooltip>
          );
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Status" defaultMessage="Status" />,
        key: "statusName",
        width: 100,
        render: record => {
          return Boolean(record.status) && <Tag color={"default"}>{record.statusName}</Tag>;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Remarks" defaultMessage="Remarks" />,
        key: "remarksName",
        width: 100,
        render: record => {
          return (
            Boolean(record.remarksName) &&
            Boolean(record.remarksDate) && (
              <Tag color={"default"}>{moment(record.remarksDate).format("DD-MM-YYYY HH:mm:ss") + " " + record.remarksName}</Tag>
            )
          );
        }
      }
    ];

    const columnsDeliveryDate = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.paginationDeliveryDate.pageSize * (this.state.paginationDeliveryDate.current - 1);
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Code" defaultMessage="Code" />,
        key: "code",
        width: 100,
        render: record => {
          return record.code;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Description" defaultMessage="Description" />,
        key: "description",
        width: 100,
        render: record => {
          return record.description;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Quantity" defaultMessage="Quantity" />,
        key: "quantity",
        width: 100,
        render: record => {
          return record.quantity;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.DeliveryDate" defaultMessage="Delivery Date" />,
        key: "deliveryDate",
        width: 100,
        render: record => {
          return record.deliveryDate;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Action" defaultMessage="Action" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return (
            <AntButton type="primary" onClick={() => this.showModalFix(row)}>
              <FormattedMessage id="RequestForQuotation.FixQuantityAndDDate" defaultMessage="Fix Quantity and D.Date" />
            </AntButton>
          );
        }
      }
    ];

    const columnsVendor = [
      {
        key: "key",
        width: 100,
        title: <FormattedMessage id="General.TitleActions" defaultMessage="Actions" />,
        fixed: "left",
        render: this.actionVendor
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.paginationVendor.pageSize * (this.state.paginationVendor.current - 1);
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Vendor" defaultMessage="Vendor" />,
        key: "id",
        width: 100,
        render: record => {
          return record.name;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              onChange={value => {
                const { paginationVendor } = this.state;
                let _paginationVendor = {
                  ...paginationVendor,
                  id: value,
                  idColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  paginationVendor: _paginationVendor
                });

                this.getVendor({
                  _paginationVendor,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["vendorsSelectItems"]) &&
                this.state["vendorsSelectItems"].length > 0 &&
                this.state["vendorsSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.paginationVendor.idColor) ? this.state.paginationVendor.idColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },

      {
        title: <FormattedMessage id="RequestForQuotation.Address" defaultMessage="Address" />,
        key: "contactList",
        width: 100,
        render: record => {
          var address = "";
          if (Boolean(record.contactList) && record.contactList.length > 0) {
            record.contactList.forEach(element => {
              if (Boolean(element.address)) {
                address += element.address + ", ";
              }
            });
          }
          if (Boolean(address)) {
            return address.substring(0, address.length - 2);
          }
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.MailAddress" defaultMessage="Mail Address" />,
        key: "contactList",
        width: 100,
        render: record => {
          var emails = "";
          if (Boolean(record.contactList) && record.contactList.length > 0) {
            record.contactList.forEach(element => {
              if (Boolean(element.emails)) {
                emails += element.emails + ", ";
              }
            });
          }
          if (Boolean(emails)) {
            return emails.substring(0, emails.length - 2);
          }
        }
      }
    ];

    const columnsVendorNotRelated = [
      {
        key: "key",
        title: <FormattedMessage id="General.TitleActions" defaultMessage="Actions" />,
        fixed: "left",
        width: 100,
        render: this.actionVendor
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.paginationVendorNotRelated.pageSize * (this.state.paginationVendorNotRelated.current - 1);
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Vendor" defaultMessage="Vendor" />,
        key: "id",
        width: 100,
        render: record => {
          return record.name;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Vendor"
              id="id"
              onChange={value => {
                const { paginationVendorNotRelated } = this.state;
                let _pagination = {
                  ...paginationVendorNotRelated,
                  id: value,
                  idColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  paginationVendorNotRelated: _pagination
                });
                this.getVendorNotRelated({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["vendorsSelectItems"]) &&
                this.state["vendorsSelectItems"].length > 0 &&
                this.state["vendorsSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}{" "}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.paginationVendorNotRelated.idColor) ? this.state.paginationVendorNotRelated.idColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Address" defaultMessage="Address" />,
        key: "contactList",
        width: 100,
        render: record => {
          var address = "";
          if (Boolean(record.contactList) && record.contactList.length > 0) {
            record.contactList.forEach(element => {
              if (Boolean(element.address)) {
                address += element.address + ", ";
              }
            });
          }
          if (Boolean(address)) {
            return address.substring(0, address.length - 2);
          }
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.MailAddress" defaultMessage="Mail Address" />,
        key: "contactList",
        width: 100,
        render: record => {
          var emails = "";
          if (Boolean(record.contactList) && record.contactList.length > 0) {
            record.contactList.forEach(element => {
              if (Boolean(element.emails)) {
                emails += element.emails + ", ";
              }
            });
          }
          if (Boolean(emails)) {
            return emails.substring(0, emails.length - 2);
          }
        }
      }
    ];

    const columnsHistory = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.paginationHistory.pageSize * (this.state.paginationHistory.current - 1);
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Date" defaultMessage="Date" />,
        key: "date",
        width: 100,
        render: record => {
          return Boolean(record.date) ? moment(record.date).format("DD-MM-YYYY HH:mm:ss") : "";
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.Remarks" defaultMessage="Remarks" />,
        key: "remarks",
        width: 100,
        render: record => {
          return record.remarks;
        }
      }
    ];

    const columnsAddMoreRFQ = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        fixed: "left",
        width: 100,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.paginationAddMoreRFQ.pageSize * (this.state.paginationAddMoreRFQ.current - 1);
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.RFQNo" defaultMessage="RFQ No" />,
        fixed: "left",
        width: 100,
        key: "rfqNo",
        render: record => {
          return record.rfqNo;
        }
      },
      {
        title: <FormattedMessage id="General.RevNo" defaultMessage="Rev No" />,
        fixed: "left",
        width: 100,
        key: "revNo",
        render: record => {
          return record.revNo;
        }
      },
      {
        title: <FormattedMessage id="General.Projects" defaultMessage="Projects" />,
        fixed: "left",
        width: 100,
        key: "projects",
        render: record => {
          return record.projects;
        }
      },
      {
        title: <FormattedMessage id="RequestForQuotation.ItemDescription" defaultMessage="Item Description" />,
        key: "projectTakeOffName",
        width: 100,
        render: record => {
          return record.projectTakeOffName;
        }
      },
      {
        title: <FormattedMessage id="General.Vendors" defaultMessage="Vendors" />,
        fixed: "left",
        width: 100,
        key: "vendors",
        render: record => {
          return record.vendors;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.actionType) ? this.state.pagination.actionType : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  actionType: e.target.value,
                  actionTypeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.actionTypeColor) ? this.state.pagination.actionTypeColor : "#bfbfbf"
            }}
          />
        )
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newOurExpectedPrice = [...columnsOurExpectedPrice];
      newOurExpectedPrice[index] = {
        ...newOurExpectedPrice[index],
        width: size.width
      };

      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };

      const newColumnsStock = [...columnsStock];
      newColumnsStock[index] = {
        ...newColumnsStock[index],
        width: size.width
      };

      const newColumnsStockSub = [...columnsStockSub];
      newColumnsStockSub[index] = {
        ...newColumnsStockSub[index],
        width: size.width
      };

      const newRevisedColumns = [...revisedColumns];
      newRevisedColumns[index] = {
        ...newRevisedColumns[index],
        width: size.width
      };

      const newColumnsDeliveryDate = [...columnsDeliveryDate];
      newColumnsDeliveryDate[index] = {
        ...newColumnsDeliveryDate[index],
        width: size.width
      };

      const newColumnsVendor = [...columnsVendor];
      newColumnsVendor[index] = {
        ...newColumnsVendor[index],
        width: size.width
      };

      const newColumnsVendorNotRelated = [...columnsVendorNotRelated];
      newColumnsVendorNotRelated[index] = {
        ...newColumnsVendorNotRelated[index],
        width: size.width
      };

      const newColumnsHistory = [...columnsHistory];
      newColumnsHistory[index] = {
        ...newColumnsHistory[index],
        width: size.width
      };

      const newColumnsAddMoreRFQ = [...columnsAddMoreRFQ];

      newColumnsAddMoreRFQ[index] = {
        ...newColumnsAddMoreRFQ[index],
        width: size.width
      };

      this.setState({
        columnsOurExpectedPrice: newOurExpectedPrice,
        columns: newColumns,
        columnsStock: newColumnsStock,
        columnsStockSub: newColumnsStockSub,
        revisedColumns: newRevisedColumns,
        columnsDeliveryDate: newColumnsDeliveryDate,
        columnsVendor: newColumnsVendor,
        columnsVendorNotRelated: newColumnsVendorNotRelated,
        columnsHistory: newColumnsHistory,
        columnsAddMoreRFQ: newColumnsAddMoreRFQ
      });
    };
    const rowSelectionModal = {
      selectedOurExpectedPrice,
      getCheckboxProps: () => ({
        disabled: true
      })
    };

    const mergeColumnsOurExpectedPrice =
      Boolean(this.state.columnsOurExpectedPrice) && this.state.columnsOurExpectedPrice.length > 0
        ? this.state.columnsOurExpectedPrice.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columnsOurExpectedPrice) &&
        columnsOurExpectedPrice.length > 0 &&
        columnsOurExpectedPrice.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const columnsOurExpectedPriceModal = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.paginationDeliveryDate.pageSize * (this.state.paginationDeliveryDate.current - 1);
        }
      },
      {
        title: <FormattedMessage id="PurchaseOrder.Item" defaultMessage="Item" />,
        key: "description",
        render: record => {
          return record.description;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="PurchaseOrder.Quantity" defaultMessage="Quantity" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        },
        width: 100,
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "quantity",
          title: <FormattedMessage id="PurchaseOrder.quantity" defaultMessage="Quantity" />,
          handleSave: this.handlePriceListQuantityOurExpectedPrice
        })
      },
      {
        title: <FormattedMessage id="PurchaseOrder.Unit" defaultMessage="Unit" />,
        key: "unit",
        width: 100,
        render: record => {
          return record.unit;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "unit",
          title: <FormattedMessage id="PurchaseOrder.unit" defaultMessage="Unit" />,
          handleSave: this.handlePriceListUnitOurExpectedPrice
        })
      },
      {
        title: <FormattedMessage id="PurchaseOrder.UnitPrice" defaultMessage="Unit Price" />,
        key: "unitPrice",
        render: record => {
          return record.unitPrice;
        },
        width: 100,
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "unitPrice",
          title: <FormattedMessage id="PurchaseOrder.unitPrice" defaultMessage="Unit Price" />,
          handleSave: this.handlePriceListUnitPriceOurExpectedPrice
        })
      },
      {
        title: <FormattedMessage id="PurchaseOrder.Currency" defaultMessage="Currency" />,
        key: "currency",
        render: record => {
          return record.currency;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "currency",
          title: <FormattedMessage id="PurchaseOrder.currency" defaultMessage="Currency" />,
          handleSave: this.handlePriceListCurrencyOurExpectedPrice
        })
      },
      {
        title: <FormattedMessage id="PurchaseOrder.PaymentTerms" defaultMessage="Payment Terms" />,
        key: "price",
        render: record => {
          if (Boolean(record.cash) && record.cash) {
            return <FormattedMessage id="PurchaseOrder.cash" defaultMessage="cash" />;
          } else if (Boolean(record.deferred) && record.deferred) {
            return <FormattedMessage id="PurchaseOrder.deferred" defaultMessage="deferred" />;
          }
        }
      },
      {
        title: <FormattedMessage id="PurchaseOrder.Price" defaultMessage="Price" />,
        key: "price",
        render: record => {
          return record.price;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="PurchaseOrder.Vat" defaultMessage="Vat(%)" />,
        key: "priceVat",
        render: record => {
          return record.priceVat;
        },
        width: 100,
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "priceVat",
          title: <FormattedMessage id="PurchaseOrder.priceVat" defaultMessage="Vat(%)" />,
          handleSave: this.handlePriceListUnitOurExpectedPriceVat
        })
      },
      {
        title: <FormattedMessage id="PurchaseOrder.planedDeliveryDate" defaultMessage="Planned Delivery Date" />,
        key: "planedDeliveryDate",
        render: record => {
          return record.planedDeliveryDate;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "planedDeliveryDate",
          title: <FormattedMessage id="PurchaseOrder.planedDeliveryDate" defaultMessage="Planned Delivery Date" />,
          handleSave: this.handlePriceListPlannedDeliveryDate
        })
      },
      {
        title: <FormattedMessage id="PurchaseOrder.DeliveryPlace" defaultMessage="Delivery Place" />,
        key: "id",
        render: record => {
          return Boolean(record.town) && Boolean(record.town) && Boolean(record.city) && Boolean(record.city) && record.town + "/" + record.city;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "deliveryPlace",
          title: <FormattedMessage id="PurchaseOrder.DeliveryPlace" defaultMessage="Delivery Place" />,
          handleSave: this.handlePriceListDeliveryPlaceOurExpectedPrice
        })
      }
    ];

    const mergeColumnsAddMoreRFQ =
      Boolean(this.state.columnsAddMoreRFQ) && this.state.columnsAddMoreRFQ.length > 0
        ? this.state.columnsAddMoreRFQ.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columnsAddMoreRFQ) &&
        columnsAddMoreRFQ.length > 0 &&
        columnsAddMoreRFQ.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const mergeColumnsVendorNotRelated =
      Boolean(this.state.columnsVendorNotRelated) && this.state.columnsVendorNotRelated.length > 0
        ? this.state.columnsVendorNotRelated.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columnsVendorNotRelated) &&
        columnsVendorNotRelated.length > 0 &&
        columnsVendorNotRelated.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const mergeColumnsHistory =
      Boolean(this.state.columnsHistory) && this.state.columnsHistory.length > 0
        ? this.state.columnsHistory.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columnsHistory) &&
        columnsHistory.length > 0 &&
        columnsHistory.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const mergeColumnsVendor =
      Boolean(this.state.columnsVendor) && this.state.columnsVendor.length > 0
        ? this.state.columnsVendor.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columnsVendor) &&
        columnsVendor.length > 0 &&
        columnsVendor.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const mergeColumnsDeliveryDate =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const mergeRevisedColumns =
      Boolean(this.state.revisedColumns) && this.state.revisedColumns.length > 0
        ? this.state.revisedColumns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(revisedColumns) &&
        revisedColumns.length > 0 &&
        revisedColumns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const mergeColumnsStock =
      Boolean(this.state.columnsStock) && this.state.columnsStock.length > 0
        ? this.state.columnsStock.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columnsStock) &&
        columnsStock.length > 0 &&
        columnsStock.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const mergeColumnsStockSub =
      Boolean(this.state.columnsStockSub) && this.state.columnsStockSub.length > 0
        ? this.state.columnsStockSub.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columnsStockSub) &&
        columnsStockSub.length > 0 &&
        columnsStockSub.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const handleScroll = (e) => {
      const { target } = e;
      console.log("target", target);
      if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
        this.setState(prevState => ({
          limitPurchase: prevState.limitPurchase + 1
        }, () => {
          this.fillRequestForPurchase();
        }));
      }
    };

    return (
      <>
        <div className="col-lg-16">
          <div className="card card-custom card-stretch gutter-b" style={{ display: "flex", flexDirection: "column", height: "100px" }}>
            <div className="card-body">
              <div className="row m-0">
                <div className="col-lg-4">
                  <Carousel autoplay>
                    <div>
                      <div className="col bg-light-warning px-6 py-4 rounded-xl mr-7 mb-3 justify-content-center">
                        <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2"></span>
                        <a href="#" className="text-warning font-weight-bold font-size-h6">
                          New Request: {Boolean(this.state.newRequestsSelectItems) ? this.state.newRequestsSelectItems.newRequests : 0}
                          <divs id></divs>
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="col bg-light-danger px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2"></span>
                        <a href="#" className="text-danger font-weight-bold font-size-h6 mt-2">
                          Partial:
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="col bg-light-primary px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2"></span>
                        <a href="#" className="text-primary font-weight-bold font-size-h6 mt-2">
                          Pending Requests: {Boolean(this.state.newRequestsSelectItems) ? this.state.newRequestsSelectItems.pendingRequests : 0}
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="col bg-light-danger px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2"></span>
                        <a href="#" className="text-danger font-weight-bold font-size-h6 mt-2">
                          Done: {Boolean(this.state.newRequestsSelectItems) ? this.state.newRequestsSelectItems.doneRequests : 0}
                        </a>
                      </div>
                    </div>
                  </Carousel>
                </div>

                <div className="col-lg-4">
                  {/* <Carousel autoplay vertical> */}
                  <Carousel autoplay>
                    <div>
                      <div className="col bg-light-success px-6 py-4 rounded-xl mb-3">
                        <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2"></span>
                        <a href="#" className="text-success font-weight-bold font-size-h6 mt-2">
                          Sent To Vendors: {Boolean(this.state.newRequestsSelectItems) ? this.state.newRequestsSelectItems.sentToVendorsCount : 0}
                          <divs id></divs>
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="col bg-light-warning px-6 py-4 rounded-xl mr-7 mb-3 justify-content-center">
                        <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2"></span>
                        <a href="#" className="text-warning font-weight-bold font-size-h6">
                          Reply: {Boolean(this.state.newRequestsSelectItems) ? this.state.newRequestsSelectItems.replyVendorsCount : 0}
                        </a>
                      </div>
                    </div>

                    <div>
                      <div className="col bg-light-primary px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2"></span>
                        <a href="#" className="text-primary font-weight-bold font-size-h6 mt-2">
                          Evaluation: {Boolean(this.state.newRequestsSelectItems) ? this.state.newRequestsSelectItems.evaluationCount : 0}
                        </a>
                      </div>
                    </div>
                  </Carousel>
                </div>

                <div className="col-lg-4">
                  <Carousel autoplay>
                    <div>
                      <div className="col bg-light-danger px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2"></span>
                        <a href="#" className="text-danger font-weight-bold font-size-h6 mt-2">
                          Responses: {Boolean(this.state.newRequestsSelectItems) ? this.state.newRequestsSelectItems.countResponse : 0}
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="col bg-light-success px-6 py-4 rounded-xl mb-3">
                        <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2"></span>
                        <a href="#" className="text-success font-weight-bold font-size-h6 mt-2">
                          Chart-Line
                        </a>
                      </div>
                    </div>

                    <div>
                      <div className="col bg-light-warning px-6 py-4 rounded-xl mr-7 mb-3 justify-content-center">
                        <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2"></span>
                        <a href="#" className="text-warning font-weight-bold font-size-h6">
                          Shuffle
                        </a>
                      </div>
                    </div>

                    <div>
                      <div className="col bg-light-primary px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2"></span>
                        <a href="#" className="text-primary font-weight-bold font-size-h6 mt-2">
                          Chart-Pie
                        </a>
                      </div>
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Badge.Ribbon
          text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage={"Request For Quotation"} />}
          placement="start"
          color="volcano"
          style={{ fontSize: 12 }}
        >
          <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
              <Row gutter={[16, 16]}>
                <Col md={21}></Col>
                <Col md={1}>
                  <Tooltip title={<FormattedMessage id="RequestForQuotation.RFQNo" defaultMessage="RFQ No" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => {
                        this.setState({
                          modalRFQNoShow: true
                        });
                      }}
                      icon={<FormatListNumberedTwoTone color="primary" fontSize="large" />}
                    ></AntButton>
                  </Tooltip>
                </Col>
                <Col md={1}>
                  <AntButton
                    style={{ border: "0px" }}
                    hidden={!this.state.hideInputs}
                    onClick={() => {
                      this.onClickCreateNew(true);
                    }}
                    icon={<CreateIcon color="secondary" fontSize="large" />}
                  ></AntButton>
                  <AntButton
                    style={{ border: "0px" }}
                    hidden={this.state.hideInputs}
                    onClick={this.onClickCreateNew}
                    icon={<RemoveCircle color="error" fontSize="large" />}
                  ></AntButton>
                </Col>
                <Col md={1}>
                  {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                    <Tooltip title={<FormattedMessage id="RequestForQuotation.Settings" defaultMessage="Settings" />}>
                      <AntButton
                        style={{ border: "0px" }}
                        onClick={() => {
                          this.setState({
                            drawerSettingsShow: true
                          });
                        }}
                        icon={<SettingsIcon color="error" fontSize="large" />}
                      ></AntButton>
                    </Tooltip>
                  )}
                </Col>
              </Row>

              <div hidden={this.state.hideInputs}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="RequestForQuotation.type" defaultMessage="Type" />}
                    name="rfqType"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Radio.Group
                      onChange={e => {
                        this.setState({
                          rfqType: e.target.value
                        });
                      }}
                    >
                      <Radio value={"ProjectTakeOff"}>Project Take Off</Radio>
                      <Radio value={"RequestForPurchase"}>Request For Purchase</Radio>
                    </Radio.Group>
                  </Form.Item>
                }

                {Boolean(this.state.rfqType) && this.state.rfqType === "RequestForPurchase" ? (
                  <>
                    <Form.Item
                      {...layout}
                      hidden={this.state.id}
                      label={<FormattedMessage id="RequestForQuotation.RequestForPurchaseItem" defaultMessage="Request For Purchase Item" />}
                      style={{
                        marginBottom: 0
                      }}
                      name="requestForPurchaseId"
                      rules={[
                        {
                          required: true,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        style={{ width: "100%" }}
                        onPopupScroll={handleScroll}
                        onChange={value => {
                          this.setState({
                            requestForPurchaseId: Boolean(value) ? value : -1
                          });
                          this.getPricesData(null, null, null, value);
                        }}
                      >
                        {this.state.requestForPurchaseSelectItems.map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      {...layout}
                      hidden={!this.state.id}
                      label={<FormattedMessage id="RequestForQuotation.RequestForPurchaseItem" defaultMessage="Request For Purchase Item" />}
                      style={{
                        marginBottom: 0
                      }}
                      name="requestForPurchaseName"
                    >
                      <Input readOnly style={{ width: "100%" }} />
                    </Form.Item>
                  </>
                ) : (
                  Boolean(this.state.rfqType) && (
                    <>
                      <Form.Item
                        {...layout}
                        hidden={this.state.id}
                        label={<FormattedMessage id="RequestForQuotation.ProjectTakeOffItem" defaultMessage="Project Take Off Item" />}
                        style={{
                          marginBottom: 0
                        }}
                        name="projectTakeOffId"
                        rules={[
                          {
                            required: true,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Select
                          showSearch
                          allowClear
                          optionFilterProp="children"
                          style={{ width: "100%" }}
                          onChange={value => {
                            var itemType = null;
                            if (Boolean(value)) {
                              var projectTakeOff = this.state.projectTakeOffsSelectItems.find(p => p.key === value);
                              if (Boolean(projectTakeOff) && Boolean(projectTakeOff.description)) {
                                itemType = projectTakeOff.description;
                                this.setState({
                                  itemType: itemType,
                                  projectTakeOffId: value,
                                  mergeColumnsOurExpectedPriceVisible: true
                                });
                              }
                            }
                            this.getPricesData(value, itemType, null, null);
                          }}
                        >
                          {this.state.projectTakeOffsSelectItems.map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        {...layout}
                        hidden={!this.state.id}
                        label={<FormattedMessage id="RequestForQuotation.ProjectTakeOffItem" defaultMessage="Project Take Off Item" />}
                        style={{
                          marginBottom: 0
                        }}
                        name="projectTakeOffListString"
                      >
                        <Input readOnly style={{ width: "100%" }} />
                      </Form.Item>
                    </>
                  )
                )}

                <Collapse activeKey={this.state.activePanelKey} onChange={this.setOpenPanels} accordion={this.state.accordion}>
                  <Panel
                    header={
                      <span style={{ fontWeight: "bold" }}>
                        <FormattedMessage
                          id="RequestForQuotation.PricePaymentAndInvoicingDeliveryDates"
                          defaultMessage="Price, payment and invoicing/Delivery dates"
                        />
                      </span>
                    }
                    key="1"
                  >
                    {this.state.pricePaymentDeliveryYourOfferForPriceVisible && (
                      <Form.Item
                        {...layout}
                        label={
                          <span style={{ color: this.state.priceOfferVisible === true ? "#1677ff" : "black", fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.priceOffer" defaultMessage="Your offer for price" />{" "}
                          </span>
                        }
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline"
                        }}
                        name="priceOffer"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                        valuePropName="checked"
                      >
                        <Checkbox
                          onChange={e => {
                            let pricePaymentDeliveryOurExpectedPriceVisible = true;
                            if (e.target.checked) {
                              pricePaymentDeliveryOurExpectedPriceVisible = false;

                              this.formRef.current.setFieldsValue({});
                            }
                            this.setState({
                              mergeColumnsOurExpectedPriceVisible: e.target.checked,
                              priceOfferVisible: e.target.checked,
                              pricePaymentDeliveryOurExpectedPriceVisible: pricePaymentDeliveryOurExpectedPriceVisible
                            });
                          }}
                        />
                      </Form.Item>
                    )}
                    {this.state.priceOfferVisible && (
                      <>
                        <Row gutter={[16, 16]}>
                          <Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6}></Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                            {<span style={{ fontWeight: "bold" }}>Including : </span>}
                          </Col>
                          <Col xs={0} sm={0} md={10} lg={10} xl={10} xxl={10}></Col>
                        </Row>
                        <br />

                        <Row gutter={8}>
                          <Col span={6}></Col>
                          <Col span={6}>
                            {this.state.goodsAndServiceVisible && (
                              <Form.Item
                                label={<FormattedMessage id="RequestForQuotation.goodsAndService" defaultMessage="Goods and Service" />}
                                name="goodsAndService"
                                rules={[
                                  {
                                    required: false,
                                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                  }
                                ]}
                                valuePropName="checked"
                              >
                                <Checkbox></Checkbox>
                              </Form.Item>
                            )}
                          </Col>
                          <Col span={6}>
                            {this.state.insuranceVisible && (
                              <Form.Item
                                label={<FormattedMessage id="RequestForQuotation.insurance" defaultMessage="Insurance" />}
                                name="insurance"
                                rules={[
                                  {
                                    required: false,
                                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                  }
                                ]}
                                valuePropName="checked"
                              >
                                <Checkbox></Checkbox>
                              </Form.Item>
                            )}
                          </Col>
                          <Col span={6}>
                            {this.state.shippingVisible && (
                              <Form.Item
                                label={<FormattedMessage id="RequestForQuotation.shipping" defaultMessage="Shipping" />}
                                name="shipping"
                                rules={[
                                  {
                                    required: false,
                                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                  }
                                ]}
                                valuePropName="checked"
                              >
                                <Checkbox></Checkbox>
                              </Form.Item>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}

                    {this.state.pricePaymentDeliveryOurExpectedPriceVisible && (
                      <Form.Item
                        {...layout}
                        label={
                          <span
                            style={{
                              color: this.state.pricePaymentDeliveryYourOfferForPriceVisible === true ? "black" : "#1677ff",
                              fontWeight: "bold"
                            }}
                          >
                            <FormattedMessage id="RequestForQuotation.ourExpectedPrice" defaultMessage="Our expected price" />{" "}
                          </span>
                        }
                        name="ourExpectedPrice"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                        valuePropName="checked"
                      >
                        <Checkbox
                          onChange={e => {
                            let pricePaymentDeliveryYourOfferForPriceVisible = true;
                            if (e.target.checked) {
                              pricePaymentDeliveryYourOfferForPriceVisible = false;
                              this.formRef.current.setFieldsValue({});
                            }
                            this.setState({
                              mergeColumnsOurExpectedPriceVisible: e.target.checked,
                              pricePaymentDeliveryYourOfferForPriceVisible: pricePaymentDeliveryYourOfferForPriceVisible
                            });
                          }}
                        />
                      </Form.Item>
                    )}

                    {this.state.mergeColumnsOurExpectedPriceVisible && (
                      <>
                        {this.state.id &&
                          <Row gutter={[16, 16]}>
                            <Col md={4}>
                              <Tooltip title={<FormattedMessage id="RequestForQuotation.AddMoreItems" defaultMessage="Add More Items" />}>
                                <Button
                                  style={{ width: "100%" }}
                                  variant="primary"
                                  onClick={() => {
                                    this.setState({
                                      addMoreItemsModalShow: true,
                                      random: Math.random()
                                    });
                                  }}
                                >
                                  <FormattedMessage id="RequestForQuotation.AddMoreItems" defaultMessage="Add More Items" />
                                </Button>
                              </Tooltip>
                            </Col>
                          </Row>}
                        <br />
                        <Table
                          rowSelection={rowSelectionOurExpectedPrice}
                          bordered
                          components={components}
                          rowClassName={() => "editable-row"}
                          scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                          columns={
                            Boolean(mergeColumnsOurExpectedPrice) && mergeColumnsOurExpectedPrice.length > 0
                              ? mergeColumnsOurExpectedPrice
                              : columnsOurExpectedPrice
                          }
                          dataSource={this.state.ourExpectedPrices.filter(p => p.active)}
                          loading={this.state.loading}
                          pagination={false}
                          summary={pageData => {
                            let priceSubTotal = 0;
                            let priceVatTotal = 0;
                            pageData.forEach(({ price, priceVat, selected }) => {
                              if (Boolean(price) && price > 0 && Boolean(selected) && selected) {
                                priceSubTotal += price;

                                if (Boolean(priceVat) && priceVat > 0) {
                                  priceVatTotal += (priceVat * price) / 100;
                                }
                              }
                            });
                            var priceTotal = priceSubTotal + priceVatTotal;
                            return (
                              <>
                                <Table.Summary.Row>
                                  <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <FormattedMessage id="RequestForQuotation.subTotal" defaultMessage="Sub total" />
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <Text type="danger">{priceSubTotal}</Text>
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                                <Table.Summary.Row>
                                  <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <FormattedMessage id="RequestForQuotation.vat" defaultMessage="VAT" />
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <InputNumber readOnly min={0} value={priceVatTotal} />
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                                <Table.Summary.Row>
                                  <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <FormattedMessage id="RequestForQuotation.total" defaultMessage="Total" />
                                    Total
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <Text type="danger">{priceTotal}</Text>
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                              </>
                            );
                          }}
                        />
                      </>
                    )}
                  </Panel>

                  <Panel
                    header={
                      <span style={{ fontWeight: "bold" }}>
                        <FormattedMessage id="RequestForQuotation.PaymentTerms" defaultMessage="Payment Terms" />
                      </span>
                    }
                    key="2"
                  >
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="RequestForQuotation.termsForPayment" defaultMessage="Your terms for payment" />}
                        name="termsForPayment"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                        valuePropName="checked"
                      >
                        <Checkbox></Checkbox>
                      </Form.Item>
                    }

                    {this.dynamicAttirbute(layout, "paymentTerms")}
                  </Panel>
                  <Panel
                    header={
                      <span style={{ fontWeight: "bold" }}>
                        <FormattedMessage id="RequestForQuotation.ValidityOfQuotation" defaultMessage="Validity of Quotation" />
                      </span>
                    }
                    key="3"
                  >
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="RequestForQuotation.validityValue" defaultMessage="Validity" />}
                      style={{ marginBottom: "5px" }}
                    >
                      <Row gutter={8}>
                        <Col span={6}>
                          <Form.Item
                            {...layout}
                            name="validityValue"
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                          >
                            <InputNumber parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} min={0} />
                          </Form.Item>
                        </Col>
                        <Col span={18}>
                          <Form.Item
                            {...layout}
                            name="quoteValidityDateType"
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                          >
                            <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                              {this.state.quoteValidityDateTypeSelectItems.map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Panel>
                  <Panel
                    header={
                      <span style={{ fontWeight: "bold" }}>
                        <FormattedMessage id="RequestForQuotation.ReceptionOfQuotation" defaultMessage="Reception of Quotation" />
                      </span>
                    }
                    key="4"
                  >
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="RequestForQuotation.receptionOptions" defaultMessage="Reception of Quotation" />}
                        name="receptionOptions"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Radio.Group
                          onChange={e => {
                            this.setState({
                              receptionOptions: e.target.value
                            });
                          }}
                        >
                          {this.state.receptionOptionsSelectItems.map(i => (
                            <Radio key={i.key} value={i.key}>
                              {i.value}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    }

                    {this.state.receptionOptions === "OTHER" && (
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="RequestForQuotation.receptionOtherValue" defaultMessage="Other Value" />}
                        name="receptionOtherValue"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    )}
                  </Panel>
                  <Panel
                    header={
                      <span style={{ fontWeight: "bold" }}>
                        <FormattedMessage id="RequestForQuotation.DeliveryPlace" defaultMessage="Delivery Place" />
                      </span>
                    }
                    key="5"
                  >
                    {
                      <Form.Item
                        {...layout}
                        label={this.state.companyName}
                        name="projectSite"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                        valuePropName="checked"
                      >
                        <Checkbox
                          onChange={e => {
                            if (e.target.checked) {
                              this.formRef.current.setFieldsValue({
                                manufacturingPlace: null
                              });
                            }
                          }}
                        ></Checkbox>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="RequestForQuotation.manufacturingPlace" defaultMessage="Your manufacturing place" />}
                        name="manufacturingPlace"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                        valuePropName="checked"
                      >
                        <Checkbox
                          onChange={e => {
                            if (e.target.checked) {
                              this.formRef.current.setFieldsValue({
                                projectSite: null
                              });
                            }
                          }}
                        ></Checkbox>
                      </Form.Item>
                    }

                    {this.dynamicAttirbute(layout, "deliveryPlaces")}
                  </Panel>
                  <Panel
                    header={
                      <span style={{ fontWeight: "bold" }}>
                        <FormattedMessage id="RequestForQuotation.Stock" defaultMessage="Stock" />
                      </span>
                    }
                    key="6"
                  >
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="RequestForQuotation.stockId" defaultMessage="Stock" />}
                        name="stockId"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Cascader changeOnSelect style={{ width: "100%" }} options={this.state.nodes} onChange={this.handleStockIdChange} />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="RequestForQuotation.placeAddress" defaultMessage="Place Address" />}
                        name="placeAddress"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <TextArea style={{ width: "100%" }} />
                      </Form.Item>
                    }
                  </Panel>
                  <Panel
                    header={
                      <span style={{ fontWeight: "bold" }}>
                        <FormattedMessage id="RequestForQuotation.RequestedDocuments" defaultMessage="Requested documents" />
                      </span>
                    }
                    key="7"
                  >
                    {this.dynamicAttirbute(layout, "requestedDocuments")}
                  </Panel>
                  <Panel
                    header={
                      <span style={{ fontWeight: "bold" }}>
                        <FormattedMessage id="RequestForQuotation.Attachments" defaultMessage="Attachments" />
                      </span>
                    }
                    key="8"
                  >
                    {
                      <Form.Item
                        {...layoutDimension}
                        label={<FormattedMessage id="RequestForQuotation.attachmentsDrawing" defaultMessage="Drawing" />}
                        name="attachmentsDrawing"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                        valuePropName="checked"
                      >
                        <Checkbox></Checkbox>
                      </Form.Item>
                    }

                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="RequestForQuotation.attachmentsFileUrl" defaultMessage="Attachments File" />}
                      name="attachmentsFileUrl"
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}>
                          <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                        </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>

                  {(this.state.showAllItems ||
                    (Boolean(this.formRef) &&
                      Boolean(this.formRef.current) &&
                      Boolean(this.formRef.current.getFieldValue("generalTerms")) &&
                      this.formRef.current.getFieldValue("generalTerms").filter(p => p.check === true).length > 0)) && (
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.GeneralTermsAndConditions" defaultMessage="General Terms and Conditions" />
                          </span>
                        }
                        key="9"
                      >
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="RequestForQuotation.generalTermsFileUrl" defaultMessage="File" />}
                          name="generalTermsFileUrl"
                          rules={[
                            { required: false, message: <FormattedMessage id="RequestForQuotation.missingLoad" defaultMessage="Missing Load" /> }
                          ]}
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                        >
                          <Upload {...listUploadProps} name="file" maxCount="1">
                            <AntButton icon={<UploadOutlined />}>
                              <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                            </AntButton>
                          </Upload>
                        </Form.Item>

                        {this.dynamicAttirbute(layout, "generalTerms")}
                      </Panel>
                    )}

                  {(this.state.showAllItems ||
                    (Boolean(this.formRef) &&
                      Boolean(this.formRef.current) &&
                      Boolean(this.formRef.current.getFieldValue("techRequirements")) &&
                      this.formRef.current.getFieldValue("techRequirements").filter(p => p.check === true).length > 0)) && (
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.TechnicalRequirements" defaultMessage="Technical Requirements" />
                          </span>
                        }
                        key="10"
                      >
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="RequestForQuotation.techRequirementsFileUrl" defaultMessage="File" />}
                          name={"techRequirementsFileUrl"}
                          rules={[
                            { required: false, message: <FormattedMessage id="RequestForQuotation.missingLoad" defaultMessage="Missing Load" /> }
                          ]}
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                        >
                          <Upload {...listUploadProps} name="file" maxCount="1">
                            <AntButton icon={<UploadOutlined />}>
                              <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                            </AntButton>
                          </Upload>
                        </Form.Item>

                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="RequestForQuotation.techRequirementsAddPr" defaultMessage="File" />}
                          name={"techRequirementsAddPr"}
                          rules={[
                            { required: false, message: <FormattedMessage id="RequestForQuotation.missingLoad" defaultMessage="Missing Load" /> }
                          ]}
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                        >
                          <Upload {...listUploadProps} name="file" maxCount="1">
                            <AntButton icon={<UploadOutlined />}>
                              <FormattedMessage id="RequestForQuotation.AddPurchaseRequisition" defaultMessage="Add Purchase Requisition" />
                            </AntButton>
                          </Upload>
                        </Form.Item>

                        {this.dynamicAttirbute(layout, "techRequirements")}
                      </Panel>
                    )}

                  {(this.state.showAllItems ||
                    (Boolean(this.formRef) &&
                      Boolean(this.formRef.current) &&
                      Boolean(this.formRef.current.getFieldValue("taxes")) &&
                      this.formRef.current.getFieldValue("taxes").filter(p => p.check === true).length > 0)) && (
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.Taxes" defaultMessage="Taxes" />
                          </span>
                        }
                        key="11"
                      >
                        {this.dynamicAttirbute(layout, "taxes")}
                      </Panel>
                    )}

                  {(this.state.showAllItems ||
                    (Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("transportOptions")))) && (
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.ModeOfTransport" defaultMessage="Mode of Transport" />
                          </span>
                        }
                        key="12"
                      >
                        {
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="RequestForQuotation.transportOptions" defaultMessage="Mode of Transport" />}
                            name="transportOptions"
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                          >
                            <Radio.Group
                              onChange={e => {
                                this.setState({
                                  transportOptions: e.target.value
                                });
                              }}
                            >
                              {this.state.transportOptionsSelectItems.map(i => (
                                <Radio key={i.key} value={i.key}>
                                  {i.value}
                                </Radio>
                              ))}
                            </Radio.Group>
                          </Form.Item>
                        }

                        {this.state.transportOptions === "OTHER" && (
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="RequestForQuotation.transportOtherValue" defaultMessage="Other Value" />}
                            name="transportOtherValue"
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        )}
                      </Panel>
                    )}

                  {(this.state.showAllItems ||
                    (Boolean(this.formRef) &&
                      Boolean(this.formRef.current) &&
                      Boolean(this.formRef.current.getFieldValue("warranties")) &&
                      this.formRef.current.getFieldValue("warranties").filter(p => p.check === true).length > 0)) && (
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.Warranty" defaultMessage="Warranty" />
                          </span>
                        }
                        key="13"
                      >
                        {this.dynamicAttirbute(layout, "warranties")}
                      </Panel>
                    )}

                  {(this.state.showAllItems ||
                    (Boolean(this.formRef) &&
                      Boolean(this.formRef.current) &&
                      Boolean(this.formRef.current.getFieldValue("languages")) &&
                      this.formRef.current.getFieldValue("languages").filter(p => p.check === true).length > 0)) && (
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.Language" defaultMessage="Language" />
                          </span>
                        }
                        key="14"
                      >
                        {this.dynamicAttirbute(layout, "languages")}
                      </Panel>
                    )}

                  {(this.state.showAllItems ||
                    (Boolean(this.formRef) &&
                      Boolean(this.formRef.current) &&
                      Boolean(this.formRef.current.getFieldValue("terminations")) &&
                      this.formRef.current.getFieldValue("terminations").filter(p => p.check === true).length > 0)) && (
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.Termination" defaultMessage="Termination" />
                          </span>
                        }
                        key="15"
                      >
                        {this.dynamicAttirbute(layout, "terminations")}
                      </Panel>
                    )}

                  {(this.state.showAllItems ||
                    (Boolean(this.formRef) &&
                      Boolean(this.formRef.current) &&
                      Boolean(this.formRef.current.getFieldValue("confidentialities")) &&
                      this.formRef.current.getFieldValue("confidentialities").filter(p => p.check === true).length > 0)) && (
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage
                              id="RequestForQuotation.ConfidentialityPrivacyAndPublicity"
                              defaultMessage="Confidentiality, privacy and publicity"
                            />
                          </span>
                        }
                        key="16"
                      >
                        {this.dynamicAttirbute(layout, "confidentialities")}
                      </Panel>
                    )}

                  {(this.state.showAllItems ||
                    (Boolean(this.formRef) &&
                      Boolean(this.formRef.current) &&
                      Boolean(this.formRef.current.getFieldValue("inspectionAndAcceptances")) &&
                      this.formRef.current.getFieldValue("inspectionAndAcceptances").filter(p => p.check === true).length > 0)) && (
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.InspectionAndAcceptance" defaultMessage="Inspection and acceptance" />
                          </span>
                        }
                        key="17"
                      >
                        {this.dynamicAttirbute(layout, "inspectionAndAcceptances")}
                      </Panel>
                    )}

                  {(this.state.showAllItems ||
                    (Boolean(this.formRef) &&
                      Boolean(this.formRef.current) &&
                      Boolean(this.formRef.current.getFieldValue("competentCourt")) &&
                      this.formRef.current.getFieldValue("competentCourt").filter(p => p.check === true).length > 0)) && (
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage
                              id="RequestForQuotation.GoverningLawAndCompetentCourt"
                              defaultMessage="Governing law and competent court"
                            />
                          </span>
                        }
                        key="18"
                      >
                        {this.dynamicAttirbute(layout, "competentCourt")}
                      </Panel>
                    )}

                  {(this.state.showAllItems ||
                    (Boolean(this.formRef) &&
                      Boolean(this.formRef.current) &&
                      Boolean(this.formRef.current.getFieldValue("liabilityInsurances")) &&
                      this.formRef.current.getFieldValue("liabilityInsurances").filter(p => p.check === true).length > 0)) && (
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.LiabilityAndInsurance" defaultMessage="Liability and insurance" />
                          </span>
                        }
                        key="19"
                      >
                        {this.dynamicAttirbute(layout, "liabilityInsurances")}
                      </Panel>
                    )}

                  {(this.state.showAllItems ||
                    (Boolean(this.formRef) &&
                      Boolean(this.formRef.current) &&
                      Boolean(this.formRef.current.getFieldValue("intellectualProperties")) &&
                      this.formRef.current.getFieldValue("intellectualProperties").filter(p => p.check === true).length > 0)) && (
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.IntellectualProperty" defaultMessage="Intellectual property" />
                          </span>
                        }
                        key="20"
                      >
                        {this.dynamicAttirbute(layout, "intellectualProperties")}
                      </Panel>
                    )}

                  {(this.state.showAllItems ||
                    (Boolean(this.formRef) &&
                      Boolean(this.formRef.current) &&
                      Boolean(this.formRef.current.getFieldValue("contractRecords")) &&
                      this.formRef.current.getFieldValue("contractRecords").filter(p => p.check === true).length > 0)) && (
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.ContractRecords" defaultMessage="Contract records" />
                          </span>
                        }
                        key="21"
                      >
                        {this.dynamicAttirbute(layout, "contractRecords")}
                      </Panel>
                    )}

                  {(this.state.showAllItems ||
                    (Boolean(this.formRef) &&
                      Boolean(this.formRef.current) &&
                      Boolean(this.formRef.current.getFieldValue("informationPrivacies")) &&
                      this.formRef.current.getFieldValue("informationPrivacies").filter(p => p.check === true).length > 0)) && (
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage
                              id="RequestForQuotation.FreedomOfInformationAndPrivacy"
                              defaultMessage="Freedom of information and privacy"
                            />
                          </span>
                        }
                        key="22"
                      >
                        {this.dynamicAttirbute(layout, "informationPrivacies")}
                      </Panel>
                    )}

                  {(this.state.showAllItems ||
                    (Boolean(this.formRef) &&
                      Boolean(this.formRef.current) &&
                      Boolean(this.formRef.current.getFieldValue("miscellaneous")) &&
                      this.formRef.current.getFieldValue("miscellaneous").filter(p => p.check === true).length > 0)) && (
                      <Panel
                        header={
                          <span style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.Miscellaneous" defaultMessage="Miscellaneous" />
                          </span>
                        }
                        key="23"
                      >
                        {this.dynamicAttirbute(layout, "miscellaneous")}
                      </Panel>
                    )}

                  {this.state.additionsList.map((a, i) => {
                    return (
                      <Panel
                        header={<span style={{ fontWeight: "bold" }}>{a.description}</span>}
                        key={this.state.maxPanelKey + i}
                        extra={
                          <CloseOutlined
                            onClick={() => {
                              let { additionsList } = this.state;
                              additionsList.splice(i, 1);
                              this.setState({ additionsList: additionsList });
                            }}
                          />
                        }
                      >
                        {/* {this.dynamicAttirbute(layout, "additionsList")} */}
                        <Row>
                          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                          <Col span={2}>
                            <Checkbox
                              onChange={e => {
                                this.setState({ ["additionsListItemCheck" + i]: e.target.checked });
                              }}
                              checked={this.state["additionsListItemCheck" + i]}
                            />
                          </Col>
                          <Col span={6}>
                            {
                              <Form.Item>
                                <FormattedMessage id="RequestForQuotation.Description" defaultMessage="Description">
                                  <Input
                                    value={this.state["additionsListItemDescription" + i]}
                                    onChange={e =>
                                      this.setState({
                                        ["additionsListItemDescription" + i]: e.target.value
                                      })
                                    }
                                  />
                                </FormattedMessage>
                              </Form.Item>
                            }
                          </Col>
                          <Col span={1}> </Col>
                          <Col span={1}>
                            <Tooltip title={<FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" />}>
                              <AntButton
                                type="dashed"
                                onClick={() => {
                                  let { additionsList } = this.state;
                                  additionsList[i].rfqModelList.push({
                                    check: this.state["additionsListItemCheck" + i],
                                    description: this.state["additionsListItemDescription" + i]
                                  });
                                  this.setState({ ["additionsListItemDescription" + i]: "", additionsList: additionsList });
                                }}
                                block
                                icon={<PlusOutlined />}
                              ></AntButton>
                            </Tooltip>
                          </Col>
                        </Row>
                        {this.state.additionsList[i].rfqModelList.map((item, index) => (
                          <Form.Item {...layout1} style={{ marginBottom: "2px" }} label={index + 1} key={i + "-" + index}>
                            <Row gutter={[8, 8]} className="mb-2">
                              <Col span={3}>
                                <Checkbox
                                  onChange={e => {
                                    let { additionsList } = this.state;
                                    item.check = e.target.checked;
                                    additionsList[i].rfqModelList[index] = item;
                                    this.setState({ additionsList: additionsList });
                                  }}
                                  checked={item.check}
                                />
                              </Col>
                              <Col span={9}>{item.description}</Col>
                              <Col span={1}> </Col>
                              <Col span={2}>
                                {
                                  <Tooltip title={<FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />}>
                                    <AntButton
                                      type="dashed"
                                      onClick={() => {
                                        let { additionsList } = this.state;
                                        additionsList[i].rfqModelList.splice(index, 1);
                                        this.setState({ additionsList: additionsList });
                                      }}
                                      block
                                      icon={<CloseOutlined />}
                                    ></AntButton>
                                  </Tooltip>
                                }
                              </Col>
                            </Row>
                          </Form.Item>
                        ))}
                      </Panel>
                    );
                  })}
                </Collapse>
                <Row className="mt-2">
                  <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}></Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}></Col>
                  <Col md={6}>
                    <AntButton
                      hidden={this.state.addNewPanel}
                      type="dashed"
                      onClick={() => {
                        this.setState({
                          addNewPanel: !this.state.addNewPanel
                        });
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      <FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" />
                    </AntButton>

                    <AntButton
                      hidden={!this.state.addNewPanel}
                      type="dashed"
                      onClick={() => {
                        this.setState({
                          addNewPanel: !this.state.addNewPanel
                        });
                      }}
                      block
                      icon={<MinusOutlined />}
                    >
                      <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                    </AntButton>
                  </Col>
                </Row>

                {this.state.addNewPanel && (
                  <Row className="mt-2">
                    <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col span={6}>
                      <Form.Item>
                        <FormattedMessage id="RequestForQuotation.title" defaultMessage="Title">
                          <Input
                            value={this.state.addtoAdditionsListItem}
                            onChange={e =>
                              this.setState({
                                addtoAdditionsListItem: e.target.value
                              })
                            }
                          />
                        </FormattedMessage>
                      </Form.Item>
                    </Col>
                    <Col span={1}> </Col>
                    <Col span={1}>
                      <Tooltip title={<FormattedMessage id="RequestForQuotation.AddNewTitle" defaultMessage="Add New Title" />}>
                        <AntButton
                          type="dashed"
                          onClick={() => {
                            if (!this.state.addtoAdditionsListItem || this.state.addtoAdditionsListItem.trim() === "") {
                              return;
                            }
                            let { additionsList } = this.state;
                            additionsList.push({
                              check: true,
                              description: this.state.addtoAdditionsListItem,
                              rfqModelList: []
                            });
                            this.setState({
                              ["additionsListItemCheck" + (additionsList.length - 1)]: true,
                              addtoAdditionsListItem: "",
                              additionsList: additionsList
                            });
                          }}
                          block
                          icon={<PlusOutlined />}
                        ></AntButton>
                      </Tooltip>
                    </Col>
                  </Row>
                )}

                <br />
                <Row className="mt-2">
                  <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                    <Tooltip title={<FormattedMessage id="RequestForQuotation.ShowItems" defaultMessage="Show Items" />}>
                      <AntButton
                        style={{ border: "0px" }}
                        hidden={this.state.showAllItems}
                        onClick={() => {
                          this.setState({
                            showAllItems: !this.state.showAllItems
                          });
                        }}
                        icon={<EyeTwoTone style={{ fontSize: "25px", cursor: "pointer" }} />}
                      ></AntButton>
                    </Tooltip>

                    <Tooltip title={<FormattedMessage id="RequestForQuotation.HideItems" defaultMessage="Hide Items" />}>
                      <AntButton
                        style={{ border: "0px" }}
                        hidden={!this.state.showAllItems}
                        onClick={() => {
                          this.setState({
                            showAllItems: !this.state.showAllItems
                          });
                        }}
                        icon={<EyeInvisibleTwoTone style={{ fontSize: "25px", cursor: "pointer" }} />}
                      ></AntButton>
                    </Tooltip>
                  </Col>
                  <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}></Col>
                </Row>
                <br />
                <div hidden={this.state.hideSave}>
                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <Spin spinning={this.state.saveLoading}>
                          <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                            <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                          </Button>
                        </Spin>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </div>
                <div hidden={this.state.hideUpdate}>
                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                          <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                        </Button>
                      </Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                          <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </div>
              </div>
            </Form>

            <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
              <Spin spinning={this.state.spinLoading}>
                {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
                  <div className="alert alert-custom alert-light">
                    <div className="alert-icon">
                      <FilterOutlined />
                    </div>
                    <div className="alert-text">
                      {this.state.searchCriteriaModels.map((val, i) => {
                        return (
                          <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                            <span className="mr-2">{val.label}</span>
                            <strong className="mr-2">{val.type}</strong>
                            <span className="mr-2">{val.operationValue}</span>
                            <span className="mr-2">{val.showValue}</span>
                            <span>
                              <Tooltip title="Remove">
                                <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                              </Tooltip>
                            </span>
                          </div>
                        );
                      })}
                    </div>
                    <div className="alert-close">
                      <Tooltip title="Remove All">
                        <CloseCircleOutlined
                          style={{ color: "crimson" }}
                          onClick={() =>
                            this.setState({ searchCriteriaModels: [] }, () => {
                              this.filterChangeItem([]);
                            })
                          }
                        />
                      </Tooltip>
                    </div>
                  </div>
                )}

                <Table
                  bordered
                  rowKey={record => record.id}
                  scroll={{ x: 900, y: 500, scrollToFirstRowOnChange: true }}
                  components={{
                    header: {
                      cell: ResizableTitle
                    }
                  }}
                  columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
                  dataSource={this.state.tableList}
                  loading={this.state.loading}
                  onChange={this.handleTableChange}
                  pagination={this.state.pagination}
                />
              </Spin>
            </div>

            <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.delete}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Modal.Footer>
            </Modal>

            <DraggableModal
              title={
                <span>
                  <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                  <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                    <FormattedMessage id="RequestForQuotation.SendToVendors" defaultMessage="Send To Vendors" />
                  </span>
                </span>
              }
              centered
              open={this.state.modalSendToVendorsShow}
              onOk={() => this.sendToVendors()}
              onCancel={() => this.setState({ modalSendToVendorsShow: false })}
              okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
              cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
              content={
                <>
                  <FormattedMessage
                    id="RequestForQuotation.MessageSendRFQToVendors"
                    defaultMessage="Do you really want to send this RFQ to Vendors ?"
                  />
                </>
              }
            ></DraggableModal>

            <DraggableModal
              title={
                <span>
                  <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                  <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                    <FormattedMessage id="RequestForQuotation.CloseTendering" defaultMessage="Close Tendering" />
                  </span>
                </span>
              }
              centered
              open={this.state.modalCloseTenderingShow}
              onOk={() => this.closeTendering()}
              onCancel={() => this.setState({ modalCloseTenderingShow: false })}
              okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
              cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
              content={
                <>
                  <FormattedMessage id="RequestForQuotation.MessageCloseTendering" defaultMessage="Do you really want to Close this Tendering?" />
                </>
              }
            ></DraggableModal>

            {/* <DraggableModal
              closable="false"
              open={this.state.modalDetailShow}
              onCancel={() => {
                this.setState({ modalDetailShow: false });
              }}
              width={1400}
              centered
              footer={[
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ modalDetailShow: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                </Button>
              ]}
              content={
                <>
                  <Badge.Ribbon
                    text={<FormattedMessage id="RequestForQuotation.RFQDetails" defaultMessage="RFQ Details" />}
                    placement="start"
                    color="purple"
                    style={{ fontSize: 12 }}
                  >
                    <div className="card card-custom" style={{ minHeight: "100%" }}>
                      <Table
                        rowSelection={rowSelectionModal}
                        bordered
                        size="small"
                        style={{ marginTop: "3rem", padding: "0.5rem" }}
                        rowClassName={() => "editable-row"}
                        scroll={{ x: 900, y: 1000, scrollToFirstRowOnChange: true }}
                        components={{
                          header: {
                            cell: ResizableTitle
                          }
                        }}
                        columns={
                          Boolean(mergeColumnsOurExpectedPriceModal) && mergeColumnsOurExpectedPriceModal.length > 0
                            ? mergeColumnsOurExpectedPriceModal
                            : columnsOurExpectedPriceModal
                        }
                        dataSource={this.state.ourExpectedPrices}
                        loading={this.state.loading}
                        summary={pageData => {
                          let priceSubTotal = 0;
                          let priceVatTotal = 0;
                          pageData.forEach(({ price, priceVat, currency }) => {
                            if (Boolean(price) && price > 0 && Boolean(currency)) {
                              if (Boolean(price) && price > 0) {
                                priceSubTotal += this.getPriceWtihKur(currency, price);

                                if (Boolean(priceVat) && priceVat > 0) {
                                  priceVatTotal += this.getVatWtihKur(currency, price, priceVat);
                                }
                              }
                            }
                          });
                          var priceTotal = priceSubTotal + priceVatTotal;
                          return (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                                <Table.Summary.Cell>
                                  <FormattedMessage id="GeneralButtonSubTotal" defaultMessage="Sub total" />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                  <Text type="danger">{priceSubTotal.toFixed(2)}</Text>
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                              <Table.Summary.Row>
                                <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                                <Table.Summary.Cell>
                                  <FormattedMessage id="GeneralButtonVAT" defaultMessage="VAT" />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                  <InputNumber readOnly min={0} value={priceVatTotal.toFixed(2)} />
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                              <Table.Summary.Row>
                                <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                                <Table.Summary.Cell>
                                  <FormattedMessage id="GeneralButtonTotal" defaultMessage="Total" />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                  <Text type="danger">{priceTotal.toFixed(2)}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                  <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "200px" }}
                                    value={this.state.currencySelect}
                                    onChange={value => {
                                      this.setState({
                                        currencySelect: value
                                      });
                                    }}
                                  >
                                    {Boolean(this.state.currencySelectItems) &&
                                      this.state.currencySelectItems.length > 0 &&
                                      this.state.currencySelectItems.map(i => (
                                        <Option key={i.key} value={i.key}>
                                          {i.value}
                                        </Option>
                                      ))}
                                  </Select>
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          );
                        }}
                      />
                    </div>
                  </Badge.Ribbon>
                </>
              }
            /> */}
            {/* <Badge.Ribbon
                text={<FormattedMessage id="RequestForQuotation.RFQDetails" defaultMessage="RFQ Details" />}
                placement="start"
                color="purple"
                style={{ fontSize: 12 }}
              >
                <div className="card card-custom" style={{ minHeight: "100%" }}>
                  <Table
                    rowSelection={rowSelectionModal}
                    bordered
                    size="small"
                    style={{ marginTop: "3rem", padding: "0.5rem" }}
                    rowClassName={() => "editable-row"}
                    scroll={{ x: 900, y: 1000, scrollToFirstRowOnChange: true }}
                    components={{
                      header: {
                        cell: ResizableTitle
                      }
                    }}
                    columns={
                      Boolean(mergeColumnsOurExpectedPriceModal) && mergeColumnsOurExpectedPriceModal.length > 0
                        ? mergeColumnsOurExpectedPriceModal
                        : columnsOurExpectedPriceModal
                    }
                    dataSource={this.state.ourExpectedPrices}
                    loading={this.state.loading}
                    summary={pageData => {
                      let priceSubTotal = 0;
                      let priceVatTotal = 0;
                      pageData.forEach(({ price, priceVat, currency }) => {
                        if (Boolean(price) && price > 0 && Boolean(currency)) {
                          if (Boolean(price) && price > 0) {
                            priceSubTotal += this.getPriceWtihKur(currency, price);

                            if (Boolean(priceVat) && priceVat > 0) {
                              priceVatTotal += this.getVatWtihKur(currency, price, priceVat);
                            }
                          }
                        }
                      });
                      var priceTotal = priceSubTotal + priceVatTotal;
                      return (
                        <>
                          <Table.Summary.Row>
                            <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <FormattedMessage id="GeneralButtonSubTotal" defaultMessage="Sub total" />
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <Text type="danger">{priceSubTotal.toFixed(2)}</Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                          <Table.Summary.Row>
                            <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <FormattedMessage id="GeneralButtonVAT" defaultMessage="VAT" />
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <InputNumber readOnly min={0} value={priceVatTotal.toFixed(2)} />
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                          <Table.Summary.Row>
                            <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <FormattedMessage id="GeneralButtonTotal" defaultMessage="Total" />
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <Text type="danger">{priceTotal.toFixed(2)}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <Select
                                showSearch
                                optionFilterProp="children"
                                style={{ width: "200px" }}
                                value={this.state.currencySelect}
                                onChange={value => {
                                  this.setState({
                                    currencySelect: value
                                  });
                                }}
                              >
                                {Boolean(this.state.currencySelectItems) &&
                                  this.state.currencySelectItems.length > 0 &&
                                  this.state.currencySelectItems.map(i => (
                                    <Option key={i.key} value={i.key}>
                                      {i.value}
                                    </Option>
                                  ))}
                              </Select>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      );
                    }}
                  />
                </div>
              </Badge.Ribbon> */}

            <DraggableModal
              closable="false"
              open={this.state.modalDetailShow}
              onCancel={() => {
                this.setState({ modalDetailShow: false });
              }}
              width={1400}
              centered
              footer={[
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ modalDetailShow: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                </Button>
              ]}
              content={
                <Badge.Ribbon
                  text={<FormattedMessage id="RequestForQuotation.RFQDetails" defaultMessage="RFQ Details" />}
                  placement="start"
                  color="purple"
                  style={{ fontSize: 12 }}
                >
                  <div className="card card-custom" style={{ minHeight: "100%" }}>
                    <Table
                      rowSelection={rowSelectionOurExpectedPrice}
                      bordered
                      components={components}
                      rowClassName={() => "editable-row"}
                      scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                      columns={
                        Boolean(mergeColumnsOurExpectedPrice) && mergeColumnsOurExpectedPrice.length > 0
                          ? mergeColumnsOurExpectedPrice
                          : columnsOurExpectedPrice
                      }
                      dataSource={this.state.ourExpectedPrices.filter(p => p.active)}
                      loading={this.state.loading}
                      pagination={false}
                      summary={pageData => {
                        let priceSubTotal = 0;
                        let priceVatTotal = 0;
                        pageData.forEach(({ price, priceVat, selected }) => {
                          if (Boolean(price) && price > 0 && Boolean(selected) && selected) {
                            priceSubTotal += price;

                            if (Boolean(priceVat) && priceVat > 0) {
                              priceVatTotal += (priceVat * price) / 100;
                            }
                          }
                        });
                        var priceTotal = priceSubTotal + priceVatTotal;
                        return (
                          <>
                            <Table.Summary.Row>
                              <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <FormattedMessage id="RequestForQuotation.subTotal" defaultMessage="Sub total" />
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <Text type="danger">{priceSubTotal}</Text>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                              <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <FormattedMessage id="RequestForQuotation.vat" defaultMessage="VAT" />
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <InputNumber readOnly min={0} value={priceVatTotal} />
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                              <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <FormattedMessage id="RequestForQuotation.total" defaultMessage="Total" />
                                Total
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <Text type="danger">{priceTotal}</Text>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          </>
                        );
                      }}
                    />
                  </div>
                </Badge.Ribbon>
              }
            />

            <DraggableModal
              keyboard={false}
              maskClosable={false}
              centered
              open={this.state.showVendorResponsesModal}
              onCancel={() => {
                this.setState({ rfqRow: null, showVendorResponsesModal: false });
                const { pagination } = this.state;
                this.restartTable({ pagination });
              }}
              okButtonProps={{ hidden: true }}
              cancelText={<FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />}
              width={1200}
              content={
                <Badge.Ribbon
                  text={<FormattedMessage id="RequestForQuotation.vendorResponses" defaultMessage="Vendor Responses" />}
                  placement="start"
                  color="purple"
                  style={{ fontSize: 12 }}
                >
                  <div className="card card-custom" style={{ padding: "3rem", minHeight: "100%" }}>
                    {this.state.id ? (
                      <RequestForQuotationVendorResponse
                        requestForQuotationId={this.state.id}
                        requestForQuotation={this.state.rfqRow}
                        restartTable={() => {
                          const { pagination } = this.state;
                          this.restartTable({ pagination });
                        }}
                      />
                    ) : null}
                  </div>
                </Badge.Ribbon>
              }
            ></DraggableModal>

            <DraggableModal
              title={<FormattedMessage id="RequestForQuotation.VendorScoring" defaultMessage="Vendor Scoring" />}
              keyboard={false}
              maskClosable={false}
              centered
              open={this.state.showVendorScoringModal}
              onCancel={() => {
                this.setState({ rfqRow: null, showVendorScoringModal: false });
                const { pagination } = this.state;
                this.restartTable({ pagination });
              }}
              okButtonProps={{ hidden: true }}
              cancelText={<FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />}
              width={1200}
              content={
                <>
                  {" "}
                  {this.state.showVendorScoringModal && this.state.rfqRow ? (
                    <VendorScoring
                      requestForQuotation={this.state.rfqRow}
                      vendor={this.state.rfqVendor}
                      random={Math.random()}
                      closeVendorScoring={this.closeVendorScoring}
                    />
                  ) : null}
                </>
              }
            ></DraggableModal>

            <DraggableModal
              title={<FormattedMessage id="RequestForQuotation.VendorReply" defaultMessage="Vendor Reply" />}
              keyboard={false}
              maskClosable={false}
              centered
              open={this.state.showVendorReplyModal}
              onCancel={() => {
                this.setState({ rfqRow: null, showVendorReplyModal: false });
                const { pagination } = this.state;
                this.restartTable({ pagination });
              }}
              okButtonProps={{ hidden: true }}
              cancelText={<FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />}
              width={1500}
              content={
                <>
                  {this.state.showVendorReplyModal && this.state.rfqRow ? (
                    <RfqVendorReply
                      requestForQuotation={this.state.rfqRow}
                      vendor={this.state.rfqVendor}
                      random={Math.random()}
                      closeRfqVendorReply={this.closeRfqVendorReply}
                    />
                  ) : null}
                </>
              }
            ></DraggableModal>

            <DraggableModal
              title={Boolean(this.state.rfqRow) ? this.state.rfqRow.rfqNo + " Evaluation" : "Vendor Evaluation"}
              keyboard={false}
              maskClosable={false}
              centered
              open={this.state.showEvaluationModal}
              onCancel={() => {
                this.setState({ rfqRow: null, showEvaluationModal: false });
                const { pagination } = this.state;
                this.restartTable({ pagination });
              }}
              okButtonProps={{ hidden: true }}
              cancelText={<FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />}
              width={1500}
              content={
                <>
                  {this.state.showEvaluationModal && this.state.rfqRow ? (
                    <VendorSelectionOnTotal
                      requestForQuotation={this.state.rfqRow}
                      vendor={this.state.rfqVendor}
                      closeVendorEvaluation={this.closeVendorEvaluation}
                      random={Math.random()}
                    />
                  ) : null}
                </>
              }
            ></DraggableModal>

            <DraggableModal
              title={Boolean(this.state.rfqRow) ? this.state.rfqRow.rfqNo + " Evaluation New" : "Vendor Evaluation New"}
              keyboard={false}
              maskClosable={false}
              centered
              open={this.state.showEvaluationNewModal}
              onCancel={() => {
                this.setState({ rfqRow: null, showEvaluationNewModal: false });
                const { pagination } = this.state;
                this.restartTable({ pagination });
              }}
              okButtonProps={{ hidden: true }}
              cancelText={<FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />}
              width={1500}
              content={
                <>
                  {" "}
                  {this.state.showEvaluationNewModal && this.state.rfqRow ? (
                    <VendorSelectionOnTotalNew
                      requestForQuotation={this.state.rfqRow}
                      vendor={this.state.rfqVendor}
                      closeVendorEvaluation={this.closeVendorEvaluation}
                      random={Math.random()}
                    />
                  ) : null}
                </>
              }
            ></DraggableModal>

            <DraggableModal
              title={<FormattedMessage id="RequestForQuotationScoringModal" defaultMessage="Scoring" />}
              keyboard={false}
              maskClosable={false}
              centered
              open={this.state.scoringModal}
              onCancel={() => this.setState({ scoringModal: false })}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              cancelText={<FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />}
              width={1500}
              footer={[
                <AntButton
                  style={{ marginRight: "10px", border: "0px", alignContent: "right" }}
                  type="text"
                  onClick={this.saveScoring}
                  icon={<RightCircleTwoTone style={{ fontSize: "32px" }} />}
                />
              ]}
              content={
                <>
                  {" "}
                  {Boolean(this.state.rfqList) && this.state.rfqList.length > 0 && (
                    <>
                      <RowBoot style={{ marginBottom: "1rem" }}>
                        <Col xs={1}></Col>
                        <Col xs={8}>
                          <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.Item" defaultMessage="Item" />
                          </FormBoot.Label>
                        </Col>
                        <Col xs={3} className="text-center">
                          <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.Mandatory" defaultMessage="Mandatory" />
                          </FormBoot.Label>
                        </Col>
                        <Col xs={2}></Col>
                        <Col xs={3} className="text-center">
                          <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.Score" defaultMessage="Score" />
                          </FormBoot.Label>
                        </Col>
                        <Col xs={2}></Col>
                        <Col xs={3} className="text-center">
                          <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                            <FormattedMessage id="RequestForQuotation.Weight" defaultMessage="Weight" />
                          </FormBoot.Label>
                        </Col>
                      </RowBoot>
                      <Divider style={{ "background-color": "black" }} />

                      {Boolean(this.state.rfqList[0].generalTerms) &&
                        this.state.rfqList[0].generalTerms.length > 0 &&
                        this.state.rfqList[0].generalTerms.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].generalTerms.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.generalTermsConditions" defaultMessage="General Terms and Conditions" />
                        )}

                      {Boolean(this.state.rfqList[0].techRequirements) &&
                        this.state.rfqList[0].techRequirements.length > 0 &&
                        this.state.rfqList[0].techRequirements.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].techRequirements.filter(p => p.check === true),
                          <FormattedMessage
                            id="RequestForQuotation.TechnicalRequirementsChecklist"
                            defaultMessage="Technical Requirements Checklist"
                          />
                        )}

                      {Boolean(this.state.rfqList[0].workingProperties) &&
                        this.state.rfqList[0].workingProperties.length > 0 &&
                        this.state.rfqList[0].workingProperties.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].workingProperties.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.workingCompanyNameProperty" defaultMessage="Working on (company name) property" />
                        )}

                      {Boolean(this.state.rfqList[0].pricePaymentInvoicing) &&
                        this.state.rfqList[0].pricePaymentInvoicing.length > 0 &&
                        this.state.rfqList[0].pricePaymentInvoicing.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].pricePaymentInvoicing.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.pricePaymentInvoicing" defaultMessage="Price, payment and invoicing" />
                        )}

                      {Boolean(this.state.rfqList[0].paymentTerms) &&
                        this.state.rfqList[0].paymentTerms.length > 0 &&
                        this.state.rfqList[0].paymentTerms.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].paymentTerms.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.paymentTerms" defaultMessage="Payment Terms" />
                        )}

                      {Boolean(this.state.rfqList[0].deliveryPlaces) &&
                        this.state.rfqList[0].deliveryPlaces.length > 0 &&
                        this.state.rfqList[0].deliveryPlaces.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].deliveryPlaces.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.deliveryPlace" defaultMessage="Delivery Place" />
                        )}

                      {Boolean(this.state.rfqList[0].taxes) &&
                        this.state.rfqList[0].taxes.length > 0 &&
                        this.state.rfqList[0].taxes.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].taxes.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.taxes" defaultMessage="Taxes" />
                        )}

                      {Boolean(this.state.rfqList[0].essenceDeliveryDates) &&
                        this.state.rfqList[0].essenceDeliveryDates.length > 0 &&
                        this.state.rfqList[0].essenceDeliveryDates.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].essenceDeliveryDates.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.timeEssence/DeliveryDates" defaultMessage="Time of essence/Delivery dates" />
                        )}

                      {Boolean(this.state.rfqList[0].warranties) &&
                        this.state.rfqList[0].warranties.length > 0 &&
                        this.state.rfqList[0].warranties.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].warranties.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.warranty" defaultMessage="Warranty" />
                        )}

                      {Boolean(this.state.rfqList[0].languages) &&
                        this.state.rfqList[0].languages.length > 0 &&
                        this.state.rfqList[0].languages.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].languages.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.language" defaultMessage="Language" />
                        )}

                      {Boolean(this.state.rfqList[0].requestedDocuments) &&
                        this.state.rfqList[0].requestedDocuments.length > 0 &&
                        this.state.rfqList[0].requestedDocuments.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].requestedDocuments.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.requestedDocuments" defaultMessage="Requested documents" />
                        )}

                      {Boolean(this.state.rfqList[0].terminations) &&
                        this.state.rfqList[0].terminations.length > 0 &&
                        this.state.rfqList[0].terminations.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].terminations.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.termination" defaultMessage="Termination" />
                        )}

                      {Boolean(this.state.rfqList[0].confidentialities) &&
                        this.state.rfqList[0].confidentialities.length > 0 &&
                        this.state.rfqList[0].confidentialities.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].confidentialities.filter(p => p.check === true),
                          <FormattedMessage
                            id="RequestForQuotation.confidentialityPrivacyPublicity"
                            defaultMessage="Confidentiality, privacy and publicity"
                          />
                        )}

                      {Boolean(this.state.rfqList[0].inspectionAndAcceptances) &&
                        this.state.rfqList[0].inspectionAndAcceptances.length > 0 &&
                        this.state.rfqList[0].inspectionAndAcceptances.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].inspectionAndAcceptances.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.inspectionAcceptance" defaultMessage="Inspection and acceptance" />
                        )}

                      {Boolean(this.state.rfqList[0].competentCourt) &&
                        this.state.rfqList[0].competentCourt.length > 0 &&
                        this.state.rfqList[0].competentCourt.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].competentCourt.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.GoverningLawCompetentCourt" defaultMessage="Governing law and competent court" />
                        )}

                      {Boolean(this.state.rfqList[0].liabilityInsurances) &&
                        this.state.rfqList[0].liabilityInsurances.length > 0 &&
                        this.state.rfqList[0].liabilityInsurances.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].liabilityInsurances.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.liabilityInsurance" defaultMessage="Liability and insurance" />
                        )}

                      {Boolean(this.state.rfqList[0].intellectualProperties) &&
                        this.state.rfqList[0].intellectualProperties.length > 0 &&
                        this.state.rfqList[0].intellectualProperties.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].intellectualProperties.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.intellectualProperty" defaultMessage="Intellectual property" />
                        )}

                      {Boolean(this.state.rfqList[0].contractRecords) &&
                        this.state.rfqList[0].contractRecords.length > 0 &&
                        this.state.rfqList[0].contractRecords.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].contractRecords.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.contractRecords" defaultMessage="Contract records" />
                        )}

                      {Boolean(this.state.rfqList[0].informationPrivacies) &&
                        this.state.rfqList[0].informationPrivacies.length > 0 &&
                        this.state.rfqList[0].informationPrivacies.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].informationPrivacies.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.freedomInformationPrivacy" defaultMessage="Freedom of information and privacy" />
                        )}

                      {Boolean(this.state.rfqList[0].documents) &&
                        this.state.rfqList[0].documents.length > 0 &&
                        this.state.rfqList[0].documents.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].documents.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.documents" defaultMessage="Documents" />
                        )}

                      {Boolean(this.state.rfqList[0].miscellaneous) &&
                        this.state.rfqList[0].miscellaneous.length > 0 &&
                        this.state.rfqList[0].miscellaneous.filter(p => p.check === true).length > 0 &&
                        this.renderElement(
                          this.state.rfqList[0].miscellaneous.filter(p => p.check === true),
                          <FormattedMessage id="RequestForQuotation.miscellaneous" defaultMessage="Miscellaneous" />
                        )}
                      {Boolean(this.state.rfqList[0].additionsList) &&
                        this.state.rfqList[0].additionsList.length > 0 &&
                        this.state.rfqList[0].additionsList.map(p => {
                          return this.renderElement(
                            p.rfqModelList.filter(p => p.check === true),
                            p.description
                          );
                        })}
                    </>
                  )}
                </>
              }
            ></DraggableModal>

            <DraggableModal
              title={<FormattedMessage id="RequestForQuotationScoringModal" defaultMessage="Technical Requirement Checklist" />}
              keyboard={false}
              maskClosable={false}
              centered
              open={this.state.technicalRequirementChecklistModal}
              onCancel={() => this.setState({ technicalRequirementChecklistModal: false })}
              okButtonProps={{ hidden: true }}
              cancelText={<FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />}
              width={1200}
              footer={[
                <Space>
                  <AntButton
                    style={{ marginLeft: "10px", border: "0px", alignContent: "left" }}
                    type="text"
                    onClick={this.saveScoringBack}
                    icon={<LeftCircleTwoTone style={{ fontSize: "32px" }} />}
                  />
                  <AntButton
                    style={{ marginRight: "10px", border: "0px", alignContent: "right" }}
                    type="text"
                    onClick={this.saveTechnicalRequirementChecklist}
                    icon={<RightCircleTwoTone style={{ fontSize: "32px" }} />}
                  />
                </Space>
              ]}
              content={
                <>
                  <Row>
                    <Col xs={0} sm={0} md={4} lg={4} xl={4} xxl={4}></Col>
                    <Col span={4}>
                      {
                        <Form.Item>
                          <FormattedMessage id="RequestForQuotation.field" defaultMessage="Field">
                            <Input
                              value={this.state.technicalRequirementChecklistsField}
                              onChange={e =>
                                this.setState({
                                  technicalRequirementChecklistsField: e.target.value
                                })
                              }
                            />
                          </FormattedMessage>
                        </Form.Item>
                      }
                    </Col>
                    <Col span={4}>
                      {
                        <Form.Item>
                          <FormattedMessage id="RequestForQuotation.description" defaultMessage="Description">
                            <Input
                              value={this.state.technicalRequirementChecklistsDescription}
                              onChange={e =>
                                this.setState({
                                  technicalRequirementChecklistsDescription: e.target.value
                                })
                              }
                            />
                          </FormattedMessage>
                        </Form.Item>
                      }
                    </Col>
                    <Col span={4}>
                      {
                        <Form.Item>
                          <FormattedMessage id="RequestForQuotation.criteria" defaultMessage="Criteria">
                            <Input
                              value={this.state.technicalRequirementChecklistsCriteria}
                              onChange={e =>
                                this.setState({
                                  technicalRequirementChecklistsCriteria: e.target.value
                                })
                              }
                            />
                          </FormattedMessage>
                        </Form.Item>
                      }
                    </Col>
                    <Col span={1}>
                      {
                        <>
                          {this.state.editTechnicalRequirementChecklists === false && (
                            <Tooltip title={<FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" />}>
                              <AntButton type="primary" onClick={this.addNewTechnicalRequirementChecklists} icon={<PlusOutlined />}></AntButton>
                            </Tooltip>
                          )}
                          {this.state.editTechnicalRequirementChecklists === true && (
                            <Tooltip title={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}>
                              <AntButton
                                type="default"
                                style={{ color: "white", background: "#1BC5BD", borderColor: "#1BC5BD" }}
                                onClick={this.saveEditTechnicalRequirementChecklists}
                                icon={<CheckOutlined />}
                              ></AntButton>
                            </Tooltip>
                          )}
                        </>
                      }
                    </Col>
                  </Row>

                  {Boolean(this.state.technicalRequirementChecklists) &&
                    this.state.technicalRequirementChecklists.length > 0 &&
                    this.state.technicalRequirementChecklists.map((item, index) => {
                      item.id = item.id ? item.id : 10000 + index;
                      return (
                        <Form.Item key={index} {...layout2} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={4}>{<Form.Item>{item.field}</Form.Item>}</Col>
                            <Col span={4}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={4}>{<Form.Item>{item.criteria}</Form.Item>}</Col>
                            <Col span={2}>
                              {
                                <Space>
                                  <Tooltip title={<FormattedMessage id="GeneralButtonEdit" defaultMessage="Edit" />}>
                                    <AntButton
                                      type="primary"
                                      style={{ color: "white", background: "#FFA800", borderColor: "#FFA800" }}
                                      onClick={() => this.editTechnicalRequirementChecklists(item)}
                                      icon={<EditOutlined />}
                                    ></AntButton>
                                  </Tooltip>

                                  <Tooltip title={<FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />}>
                                    <AntButton
                                      type="primary"
                                      onClick={() => this.removeTechnicalRequirementChecklists(item.id)}
                                      danger
                                      icon={<CloseOutlined />}
                                    ></AntButton>
                                  </Tooltip>
                                </Space>
                              }
                            </Col>
                          </Row>
                        </Form.Item>
                      );
                    })}
                </>
              }
            ></DraggableModal>

            <Modal
              show={this.state.modalDeliveryDateShow}
              onHide={this.showOrHideModalDeliveryDate}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              size="xl"
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <FormattedMessage id="RequestForQuotationDeliveryDateModal" defaultMessage="Please fix your quantity and delivery time" />
                </Modal.Title>
                <AntButton
                  style={{ marginLeft: "auto" }}
                  onClick={this.showOrHideModalDeliveryDate}
                  icon={<CloseIcon color="secondary" />}
                ></AntButton>
              </Modal.Header>
              <Modal.Body>
                <Table
                  bordered
                  rowKey={record => record.id}
                  scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                  components={{
                    header: {
                      cell: ResizableTitle
                    }
                  }}
                  columns={Boolean(mergeColumnsDeliveryDate) && mergeColumnsDeliveryDate.length > 0 ? mergeColumnsDeliveryDate : columns}
                  dataSource={this.state.rfqList}
                  loading={this.state.loading}
                />
              </Modal.Body>
              <Modal.Footer style={{ padding: "0px", marginTop: "1rem", marginBottom: "1rem" }}>
                <AntButton
                  style={{ marginLeft: "10px", border: "0px", alignContent: "left" }}
                  type="text"
                  onClick={this.saveTechnicalRequirementChecklistBack}
                  icon={<LeftCircleTwoTone style={{ fontSize: "32px" }} />}
                />
                <AntButton
                  style={{ marginRight: "10px", border: "0px", alignContent: "right" }}
                  type="text"
                  onClick={this.saveDeliveryDate}
                  icon={<RightCircleTwoTone style={{ fontSize: "32px" }} />}
                />
              </Modal.Footer>
            </Modal>

            <DraggableModal
              title={<FormattedMessage id="RequestForQuotation.Vendor" defaultMessage="Vendor" />}
              keyboard={false}
              maskClosable={false}
              open={this.state.vendorModal}
              okButtonProps={{ hidden: true }}
              onCancel={() => this.setState({ vendorModal: false, selectedVendorNotRelatedIds: [] })}
              width={1500}
              centered
              footer={[
                this.state.vendorModalLast ? (
                  <Space>
                    <Button
                      id="CloseButton"
                      onClick={() => {
                        this.setState({ vendorModalLast: false, vendorModal: false, selectedVendorNotRelatedIds: [] });
                        const { pagination } = this.state;
                        this.restartTable({ pagination });
                      }}
                      style={{ width: "100%" }}
                      variant="secondary"
                    >
                      <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                    </Button>

                    <Spin spinning={this.state.spinLoadingSendToVendors}>
                      <Button
                        id="SaveButton"
                        onClick={() => {
                          this.setState(
                            {
                              selectedVendorNotRelatedIds: []
                            },
                            () => {
                              this.updateSendToVendors();
                            }
                          );
                        }}
                        style={{ width: "100%" }}
                        variant="success"
                      >
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Spin>
                  </Space>
                ) : (
                  <Space>
                    {/* <AntButton
                    style={{ marginLeft: "10px", border: "0px", alignContent: "left" }}
                    type="text"
                    onClick={this.saveDeliveryDateBack}
                    icon={<LeftCircleTwoTone style={{ fontSize: "32px" }} />}
                  /> */}
                    <Button
                      id="CloseButton"
                      onClick={() => {
                        this.setState({ vendorModalLast: false, vendorModal: false, selectedVendorNotRelatedIds: [] });
                        const { pagination } = this.state;
                        this.restartTable({ pagination });
                      }}
                      style={{ width: "100%" }}
                      variant="secondary"
                    >
                      <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                    </Button>

                    <Spin spinning={this.state.spinLoading}>
                      {/* <AntButton
                      style={{ marginRight: "10px", border: "0px", alignContent: "right" }}
                      type="text"
                      onClick={this.saveRFQ}
                      icon={<RightCircleTwoTone style={{ fontSize: "32px" }} />}
                    /> */}
                      <Button variant="success" onClick={this.saveRFQ} style={{ width: "100%" }}>
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Spin>
                  </Space>
                )
              ]}
              content={
                <>
                  <Row gutter={[16, 16]}>
                    <Col md={18}></Col>
                    <Col md={3}>
                      <Button
                        id="approvedVendorButton"
                        onClick={() => {
                          const { paginationVendorNotRelated } = this.state;
                          this.getVendorNotRelated({ paginationVendorNotRelated });
                          this.setState({
                            selectedRowKeysNotRelated: [],
                            showVendorNotRelated: true
                          });
                        }}
                        style={{ width: "100%" }}
                        variant="secondary"
                      >
                        <FormattedMessage id="approvedVendorButton" defaultMessage="Approved Vendor" />
                      </Button>
                    </Col>
                    <Col md={3}>
                      <Button id="createNewVendorButton" onClick={this.onClickCreateNewVendor} style={{ width: "100%" }} variant="primary">
                        <FormattedMessage id="NewVendorButton" defaultMessage="New Vendor" />
                      </Button>
                    </Col>
                  </Row>
                  <br />
                  <Table
                    bordered
                    rowKey={record => record.id}
                    rowSelection={rowSelectionVendor}
                    scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                    components={{
                      header: {
                        cell: ResizableTitle
                      }
                    }}
                    columns={Boolean(mergeColumnsVendor) && mergeColumnsVendor.length > 0 ? mergeColumnsVendor : columnsVendor}
                    dataSource={this.state.vendorList}
                    loading={this.state.loading}
                    onChange={this.handleTableChangeVendor}
                    pagination={this.state.paginationVendor}
                  />
                </>
              }
            ></DraggableModal>

            <DraggableModal
              title={<FormattedMessage id="RequestForQuotation.SelectVendor" defaultMessage="Select Vendor" />}
              keyboard={false}
              maskClosable={false}
              open={this.state.showVendorNotRelated}
              okButtonProps={{ hidden: true }}
              onCancel={() => this.setState({ showVendorNotRelated: false })}
              width={1200}
              centered
              footer={[
                <Space>
                  <Button
                    id="CloseButton"
                    onClick={() => {
                      this.setState({ showVendorNotRelated: false });
                    }}
                    style={{ width: "100%" }}
                    variant="secondary"
                  >
                    <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                  </Button>

                  <Button
                    id="SelectButton"
                    onClick={() => {
                      var selectedRowKeysNotRelated = this.state.selectedRowKeysNotRelated;
                      if (Boolean(selectedRowKeysNotRelated) && selectedRowKeysNotRelated.length > 0) {
                        if (Boolean(this.state.selectedRowKeysVendorIds) && this.state.selectedRowKeysVendorIds.length > 0) {
                          this.state.selectedRowKeysVendorIds.forEach(element => {
                            if (!selectedRowKeysNotRelated.includes(element)) {
                              selectedRowKeysNotRelated.push(element);
                            }
                          });
                        }
                        this.setState(
                          {
                            selectedVendorNotRelatedIds: selectedRowKeysNotRelated,
                            showVendorNotRelated: false
                          },
                          () => {
                            const { paginationVendor } = this.state;
                            this.getVendor({ paginationVendor });
                          }
                        );
                      } else {
                        showError(<FormattedMessage id="RequestForQuotation.pleaseSelectVendor" defaultMessage="Please select vendor" />);
                      }
                    }}
                    style={{ width: "100%" }}
                    variant="primary"
                  >
                    <FormattedMessage id="GeneralButtonSelect" defaultMessage="Select" />
                  </Button>
                </Space>
              ]}
              content={
                <>
                  <br />
                  <Table
                    bordered
                    rowKey={record => record.id}
                    rowSelection={rowSelectionVendorNotRelated}
                    scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                    components={{
                      header: {
                        cell: ResizableTitle
                      }
                    }}
                    columns={
                      Boolean(mergeColumnsVendorNotRelated) && mergeColumnsVendorNotRelated.length > 0
                        ? mergeColumnsVendorNotRelated
                        : columnsVendorNotRelated
                    }
                    dataSource={this.state.vendorListNotRelated}
                    loading={this.state.loading}
                    onChange={this.handleTableChangeVendorNotRelated}
                    pagination={this.state.paginationVendorNotRelated}
                  />
                </>
              }
            ></DraggableModal>

            <DraggableModal
              title={
                <span>
                  <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                  <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                    <FormattedMessage id="RequestForQuotation.AddMoreRFQ" defaultMessage="Add More RFQ" />
                  </span>
                </span>
              }
              centered
              open={this.state.modalAddMorRFQShow}
              onOk={() => {
                const { paginationAddMoreRFQ } = this.state;
                this.addMoreRFQConfirm({ paginationAddMoreRFQ });
              }}
              onCancel={() => {
                this.setState({ modalAddMorRFQShow: false });
                const { pagination } = this.state;
                this.restartTable({ pagination });
              }}
              okText={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />}
              cancelText={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />}
              content={
                <>
                  <FormattedMessage id="RequestForQuotation.MessageAddMoreRFQ" defaultMessage="Do you want to add more RFQ ?" />
                </>
              }
            ></DraggableModal>

            <DraggableModal
              centered
              width={1200}
              title={<FormattedMessage id="RequestForQuotation.MoreRFQ" defaultMessage="AddadssafascasdfasdMore RFQ" />}
              open={this.state.addMoreRFQShow}
              onCancel={() => {
                this.setState({ addMoreRFQShow: false });
                const { pagination } = this.state;
                this.restartTable({ pagination });
              }}
              cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
              onOk={() => this.updateSendToVendorsMoreRFQ()}
              content={
                <>
                  {/* <Checkbox onChange={(e) => handleCheckboxChange(index, e.target.checked)}>
                              <Form.Item
                                {...field}
                                style={{
                                  display: "inline-block",
                                  width: "calc(100%)",
                                }}
                                name={[field.name, "description"]}
                              >
                                <Input readOnly></Input>
                              </Form.Item>
                            </Checkbox> */}
                  <br></br>
                  <Radio.Group
                    centered
                    onChange={e => {
                      let ConsigmentReceiveModal = false;
                      let TransferReceiveModal = false;
                      let EmergencyReceiveModal = false;
                      if (e.target.value === "Consigment") {
                        ConsigmentReceiveModal = true;
                      }
                      if (e.target.value === "Emergency") {
                        EmergencyReceiveModal = true;
                      } else if (e.target.value === "Transfer") {
                        TransferReceiveModal = true;
                      }
                      this.setState({
                        receivingType: e.target.value,
                        TransferReceiveModal: TransferReceiveModal,
                        ConsigmentReceiveModal: ConsigmentReceiveModal,
                        EmergencyReceiveModal: EmergencyReceiveModal
                      });
                    }}
                  >
                    <Radio key={"SameProject"} value={"SameProject"}>
                      {<FormattedMessage id="InventoryLog.SameProject" defaultMessage="Same Project" />}
                    </Radio>
                    <Radio key={"SameVendor"} value={"SameVendor"}>
                      {<FormattedMessage id="InventoryLog.SameVendor" defaultMessage="Same Vendor" />}
                    </Radio>
                    <Radio key={"SameItem"} value={"SameItem"}>
                      {<FormattedMessage id="InventoryLog.SameItem" defaultMessage="Same Item" />}
                    </Radio>
                  </Radio.Group>
                  <br></br>
                  <br></br>
                  <br></br>
                  <Table
                    bordered
                    rowKey={record => record.id}
                    rowSelection={rowSelectionAddMoreRFQ}
                    scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                    components={{
                      header: {
                        cell: ResizableTitle
                      }
                    }}
                    columns={Boolean(mergeColumnsAddMoreRFQ) && mergeColumnsAddMoreRFQ.length > 0 ? mergeColumnsAddMoreRFQ : columns}
                    dataSource={this.state.addMoreRFQList}
                    loading={this.state.loading}
                    onChange={this.handleTableChangeAddMoreRFQ}
                    pagination={this.state.paginationAddMoreRFQ}
                  />{" "}
                </>
              }
            ></DraggableModal>

            <Modal
              show={this.state.fixModal}
              onHide={this.showOrHideModalFix}
              aria-labelledby="contained-modal-title-vcenter"
              size="lg"
              centered
              backdrop="static"
            >
              <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <FormattedMessage id="RFQFixItem" defaultMessage="Fix Quantity and Delivery Date" />
                </Modal.Title>
                <AntButton style={{ marginLeft: "auto" }} onClick={this.showOrHideModalFix} icon={<CloseIcon color="secondary" />}></AntButton>
              </Modal.Header>
              <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
                <RowBoot style={{ marginBottom: "1rem" }}>
                  <ColBoot xs={4} style={{ textAlign: "right" }}>
                    <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>Quantity:</FormBoot.Label>
                  </ColBoot>
                  <ColBoot xs={4}>
                    <InputNumber
                      parser={value => value.replace(",", ".")}
                      formatter={value => value.replace(".", ",")}
                      min={0}
                      style={{ width: "100%" }}
                      onChange={e => this.setState({ quantityModal: e })}
                      value={this.state.quantityModal}
                    />
                  </ColBoot>
                  <ColBoot xs={4}>
                    <AntButton type="primary" onClick={this.showModalSplitQuantity}>
                      <FormattedMessage id="RequestForQuotation.splitQuantity" defaultMessage="Split Quantity" />
                    </AntButton>
                  </ColBoot>
                </RowBoot>
                <RowBoot>
                  <ColBoot xs={4} style={{ textAlign: "right" }}>
                    <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                      <FormattedMessage id="RequestForQuotation.deliveryDate" defaultMessage="Delivery Date:" />
                    </FormBoot.Label>
                  </ColBoot>
                  <ColBoot xs={4}>
                    <DatePicker
                      disabledDate={this.disabledDate}
                      value={moment(this.state.deliveryDate, "DD-MM-YYYY")}
                      format="DD-MM-YYYY"
                      onChange={this.onChangeDateDelivery}
                    />
                  </ColBoot>
                  <ColBoot xs={4}></ColBoot>
                </RowBoot>
              </Modal.Body>
              <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
                <Button variant="light" onClick={this.showOrHideModalFix}>
                  <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                </Button>
                <Button variant="primary" onClick={this.fixItem}>
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={this.state.splitQuantityModal}
              onHide={this.showOrHideModalSplitQuantity}
              aria-labelledby="contained-modal-title-vcenter"
              size="lg"
              centered
              backdrop="static"
            >
              <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <FormattedMessage id="RFQFixItem" defaultMessage="Fix Splitted Quantities and Delivery Dates" />
                </Modal.Title>
                <AntButton
                  style={{ marginLeft: "auto" }}
                  onClick={this.showOrHideModalSplitQuantity}
                  icon={<CloseIcon color="secondary" />}
                ></AntButton>
              </Modal.Header>
              <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
                <RowBoot style={{ marginBottom: "1rem" }}>
                  <ColBoot xs={4} style={{ textAlign: "right" }}>
                    <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                      <FormattedMessage id="RequestForQuotation.totalQuantity" defaultMessage="Total Quantity:" />
                    </FormBoot.Label>
                  </ColBoot>
                  <ColBoot xs={4}>
                    <InputNumber
                      parser={value => value.replace(",", ".")}
                      formatter={value => value.replace(".", ",")}
                      min={0}
                      style={{ width: "100%" }}
                      onChange={e => this.setState({ splitQuantityTotalModal: e })}
                      readOnly
                      value={this.state.splitQuantityTotalModal}
                    />
                  </ColBoot>
                </RowBoot>
                <RowBoot style={{ marginBottom: "1rem" }}>
                  <ColBoot xs={4} style={{ textAlign: "right" }}>
                    <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                      <FormattedMessage id="RequestForQuotation.remaining" defaultMessage="Remaining:" />
                    </FormBoot.Label>
                  </ColBoot>
                  <ColBoot xs={4}>
                    <InputNumber
                      min={0}
                      style={{ width: "100%" }}
                      onChange={e => this.setState({ splitQuantityRemainingModal: e })}
                      readOnly
                      value={this.state.splitQuantityRemainingModal}
                    />
                  </ColBoot>
                  <ColBoot xs={4}>
                    {this.state.overvalue && (
                      <FormBoot.Label style={{ color: "red", marginTop: "0.5rem", fontWeight: "bold" }}>
                        <FormattedMessage id="RequestForQuotation.overvalue" defaultMessage="Overvalue" />
                      </FormBoot.Label>
                    )}
                  </ColBoot>
                </RowBoot>
                <RowBoot style={{ marginBottom: "1rem" }}>
                  <ColBoot xs={2} style={{ textAlign: "right" }}></ColBoot>
                  <ColBoot xs={4} style={{ textAlign: "center" }}>
                    <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                      <FormattedMessage id="RequestForQuotation.splittedQuantity" defaultMessage="Splitted Quantity" />
                    </FormBoot.Label>
                  </ColBoot>
                  <ColBoot xs={4} style={{ textAlign: "center" }}>
                    <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                      <FormattedMessage id="RequestForQuotation.date" defaultMessage="Date" />
                    </FormBoot.Label>
                  </ColBoot>
                </RowBoot>

                {this.state.splitRows.map((item, index) => (
                  <RowBoot key={index} style={{ marginBottom: "1rem" }}>
                    <ColBoot xs={2} style={{ textAlign: "right" }}></ColBoot>
                    <ColBoot xs={4} style={{ textAlign: "center" }}>
                      <InputNumber
                        parser={value => value.replace(",", ".")}
                        formatter={value => value.replace(".", ",")}
                        min={0}
                        style={{ width: "100%" }}
                        onChange={value => this.handleChangeSplitQuantity(index, value)}
                        value={item.quantity}
                      />
                    </ColBoot>
                    <ColBoot xs={4} style={{ textAlign: "center" }}>
                      <DatePicker
                        disabledDate={this.disabledDate}
                        value={moment(item.date, "DD-MM-YYYY")}
                        format="DD-MM-YYYY"
                        onChange={(date, dateString) => {
                          this.onChangeSplitDateDelivery(index, date, dateString);
                        }}
                      />
                    </ColBoot>
                    <ColBoot xs={2}>
                      {index === 0 && <AntButton type="primary" onClick={this.addNewSplit} icon={<PlusOutlined />}></AntButton>}
                      {index !== 0 && (
                        <AntButton type="primary" danger onClick={e => this.removeSplit(index, e)} icon={<CloseOutlined />}></AntButton>
                      )}
                    </ColBoot>
                  </RowBoot>
                ))}
              </Modal.Body>
              <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
                <Button variant="light" onClick={this.showOrHideModalSplitQuantity}>
                  <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                </Button>
                <Button variant="primary" onClick={this.fixItemBefore}>
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Modal.Footer>
            </Modal>

            <DraggableModal
              title={<FormattedMessage id="RequestForQuotation.NewRequest" defaultMessage="New Request" />}
              open={this.state.isPromptModalVisible}
              okButtonProps={{ hidden: true }}
              onCancel={() => this.setState({ isPromptModalVisible: false })}
              content={
                <>
                  <Button
                    onClick={() => {
                      this.setState({ isPromptModalVisible: false });
                      this.onClickCreateNew(false);
                    }}
                    style={{ width: "100%", marginBottom: "2rem" }}
                    variant="warning"
                  >
                    <FormattedMessage id="RequestForQuotation.emptyForm" defaultMessage=" Empty Form" />
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({ isPromptModalVisible: false });
                      this.onClickCreateNew(true);
                    }}
                    style={{ width: "100%", marginBottom: "2rem" }}
                    variant="success"
                  >
                    <FormattedMessage id="RequestForQuotation.fillFormWithLastRequest" defaultMessage="Fill form with last Request" />
                  </Button>
                </>
              }
            ></DraggableModal>

            <DraggableModal
              title={<FormattedMessage id="GeneralPDFReportViewer" defaultMessage="PDF Report Viewer" />}
              open={this.state.modalPdfViewer}
              okButtonProps={{ hidden: true }}
              onCancel={() => this.setState({ modalPdfViewer: false })}
              width={1200}
              centered
              content={
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                  <div style={{ marginTop: "2rem" }}>
                    <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                  </div>
                </Worker>
              }
            />

            <DraggableModal
              open={this.state.modalHistoryShow}
              okButtonProps={{ hidden: true }}
              onCancel={() => this.setState({ modalHistoryShow: false })}
              width={1000}
              centered
              scroll={{ x: 400, y: 400, scrollToFirstRowOnChange: true }}
              content={
                <Badge.Ribbon
                  text={<FormattedMessage id="RequestForQuotation.History" defaultMessage="History" />}
                  placement="start"
                  color="purple"
                  style={{ fontSize: 12 }}
                >
                  <div className="card card-custom" style={{ minHeight: "100%", padding: "2rem" }}>
                    <Timeline mode="alternate">
                      {Boolean(this.state.historyList) &&
                        this.state.historyList.length > 0 &&
                        this.state.historyList.map((item, index) => {
                          return (
                            <Timeline.Item>
                              {item.remarks} <br />
                              {item.remoteUserName} <br />
                              {moment(item.date).format("DD-MM-YYYY HH:mm:ss")}
                            </Timeline.Item>
                          );
                        })}
                    </Timeline>
                    {/*<Table*/}
                    {/*  bordered*/}
                    {/*  rowKey={record => record.id}*/}
                    {/*  scroll={{ x: 900, scrollToFirstRowOnChange: true }}*/}
                    {/*  columns={columnsHistory}*/}
                    {/*  dataSource={this.state.historyList}*/}
                    {/*  loading={this.state.loading}*/}
                    {/*  pagination={this.state.paginationHistory}*/}
                    {/*/>*/}
                  </div>
                </Badge.Ribbon>
              }
            ></DraggableModal>

            <DraggableModal
              open={this.state.modalStatusShow}
              okButtonProps={{ hidden: true }}
              onCancel={() => this.setState({ modalStatusShow: false })}
              width={700}
              centered
              content={
                <Badge.Ribbon
                  text={<FormattedMessage id="RequestForQuotation.Status" defaultMessage="Status" />}
                  placement="start"
                  color="purple"
                  style={{ fontSize: 12 }}
                >
                  <div className="card card-custom" style={{ padding: "0.1rem", minHeight: "100%" }}>
                    <div className="card-body pt-4 custom-timeline pr-5 pl-5 mr-5 ml-5 align-content-center">
                      <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                      <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                      <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                      <Steps direction="vertical" className="pl-5-custom anticon-custom">
                        {Boolean(this.state.statusList) &&
                          this.state.statusList.length > 0 &&
                          this.state.statusList.map((item, index) => {
                            return item.spin ? (
                              <Step status="process" title={item.status + (item.count >= 0 ? " - " + item.count : "")} icon={<LoadingOutlined />} />
                            ) : (
                              <Step
                                title={item.status + (item.count >= 0 ? " - " + item.count : "")}
                                status={
                                  item.color === "green"
                                    ? "finish"
                                    : item.color === "blue"
                                      ? "process"
                                      : item.status === "Evaluation"
                                        ? "wait"
                                        : item.status === "Selection of vendor(s)"
                                          ? "wait"
                                          : item.status === "P.O. Creation"
                                            ? "wait"
                                            : "wait"
                                }
                                description={item.color === "green" || item.color === "blue" ? moment(item.date).format("DD-MM-YYYY HH:mm:ss") : ""}
                              />
                            );
                          })}
                      </Steps>
                    </div>
                  </div>
                </Badge.Ribbon>
              }
            ></DraggableModal>

            <DraggableModal
              open={this.state.showRevisedListModal}
              okButtonProps={{ hidden: true }}
              onCancel={() => this.setState({ showRevisedListModal: false })}
              width={1200}
              centered
              content={
                <Badge.Ribbon
                  text={<FormattedMessage id="RequestForQuotation.RevisedRequestForQuotation" defaultMessage="Revised Request For Quotation" />}
                  placement="start"
                  color="purple"
                  style={{ fontSize: 12 }}
                >
                  <div className="card card-custom" style={{ minHeight: "100%" }}>
                    <Table
                      bordered
                      size="small"
                      style={{ marginTop: "3rem", padding: "0.5rem" }}
                      scroll={{ x: 400, y: 400, scrollToFirstRowOnChange: true }}
                      components={{
                        header: {
                          cell: ResizableTitle
                        }
                      }}
                      columns={Boolean(mergeRevisedColumns) && mergeRevisedColumns.length > 0 ? mergeRevisedColumns : columns}
                      dataSource={this.state.revisedList}
                    />
                  </div>
                </Badge.Ribbon>
              }
            ></DraggableModal>

            <DraggableModal
              centered
              width={1200}
              open={this.state.showVendorLogModal}
              okButtonProps={{ hidden: true }}
              onCancel={() => {
                this.setState({
                  showVendorLogModal: false
                });
              }}
              content={
                <Badge.Ribbon
                  text={<FormattedMessage id="RequestForQuotation.AddNewVendor" defaultMessage="Add New Vendor" />}
                  placement="start"
                  color="purple"
                  style={{ fontSize: 12 }}
                >
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="card card-custom" style={{ minHeight: "100%" }}>
                    <Form id="FormVendor" initialValues={{ remember: false }} onFinish={this.handleFormVendorSubmit} ref={this.formRefVendor}>
                      {
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="Vendor.name" defaultMessage="Name" />}
                          style={{
                            marginBottom: 0
                          }}
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      }
                      {
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="VendorContact.address" defaultMessage="Address" />}
                          style={{
                            marginBottom: 0
                          }}
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      }
                      {
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="VendorContact.emails" defaultMessage="Email" />}
                          style={{
                            marginBottom: 0
                          }}
                          name="emails"
                          rules={[
                            {
                              required: true,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      }

                      <div hidden={this.state.hideSaveVendor}>
                        {
                          <Row gutter={[16, 16]}>
                            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                              <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                              </Button>
                            </Col>
                            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                          </Row>
                        }
                      </div>
                      <div hidden={this.state.hideUpdateVendor}>
                        {
                          <Row gutter={[16, 16]}>
                            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                            <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                              <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancelVendor}>
                                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                              </Button>
                            </Col>
                            <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                              <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                                <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                              </Button>
                            </Col>
                            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                          </Row>
                        }
                      </div>
                    </Form>
                  </div>
                </Badge.Ribbon>
              }
            ></DraggableModal>

            <DraggableModal
              title={<FormattedMessage id="RequestForQuotation.Description" defaultMessage="Description" />}
              open={this.state.showQuantityDescriptionModal}
              okButtonProps={{ hidden: true }}
              onCancel={() => this.setState({ showQuantityDescriptionModal: false })}
              width={800}
              centered
              footer={[
                <Space>
                  <Button
                    id="CloseButton"
                    onClick={() => {
                      this.setState({ showQuantityDescriptionModal: false });
                    }}
                    style={{ width: "100%" }}
                    variant="secondary"
                  >
                    <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                  </Button>

                  <Button id="SaveButton" onClick={() => this.saveQuantityDescription()} style={{ width: "100%" }} variant="primary">
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Space>
              ]}
              content={
                <>
                  <TextArea
                    value={this.state.quantityDescription}
                    onChange={e => {
                      this.setState({
                        quantityDescription: e.target.value
                      });
                    }}
                    style={{ width: "100%" }}
                    rows={3}
                  />
                </>
              }
            ></DraggableModal>

            <DraggableModal
              title={<FormattedMessage id="RequestForQuotation.releaseFromStock" defaultMessage="Release From Stock" />}
              open={this.state.modalreleaseFromStockShow}
              onCancel={() => {
                this.setState({ modalreleaseFromStockShow: false });
              }}
              width={1300}
              centered
              footer={[
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ modalreleaseFromStockShow: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                </Button>
              ]}
              content={
                <>
                  <InputNumber
                    parser={value => value.replace(",", ".")}
                    formatter={value => value.replace(".", ",")}
                    value={this.state.quantityDescription}
                    onChange={e => {
                      this.setState({
                        quantityDescription: e.target.value
                      });
                    }}
                    style={{ width: "100%" }}
                    rows={3}
                  />
                </>
              }
            ></DraggableModal>

            <DraggableModal
              title={<FormattedMessage id="RequestForQuotation.Stock" defaultMessage="Stock" />}
              open={this.state.modalStockShow}
              onCancel={() => {
                this.setState({ modalStockShow: false });
              }}
              width={1500}
              centered
              footer={[
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ modalStockShow: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                </Button>
              ]}
              content={
                <>
                  <Row gutter={[16, 16]}>
                    <Col md={12}></Col>
                    <Col md={4}>
                      <Button
                        id="EquivalentItemsStockButton"
                        style={{ width: "100%" }}
                        variant="dark"
                        onClick={() => {
                          this.setState(
                            {
                              equivalentItemsIncluded: true
                            },
                            () => {
                              const { paginationStock } = this.state;
                              this.restartTableStock({ paginationStock });
                            }
                          );
                        }}
                      >
                        <FormattedMessage id="ButtonEquivalentItemsStock" defaultMessage="Equivalent Items" />
                      </Button>
                    </Col>
                    <Col md={4}>
                      <Button id="ReleaseFromStockButton" style={{ width: "100%" }} variant="dark" onClick={this.releaseFromStockModal}>
                        <FormattedMessage id="ButtonReleaseFromStock" defaultMessage="Release from stock" />
                      </Button>
                    </Col>
                    <Col md={4}>
                      <Button id="ReserveStockQuantitiesButton" style={{ width: "100%" }} variant="dark" onClick={this.reserveStockQuantitiesModal}>
                        <FormattedMessage id="ButtonReserveToBeReleased" defaultMessage="Reserve To Be Released" />
                      </Button>
                    </Col>
                  </Row>
                  <br />
                  <div className="card card-custom" style={{ minHeight: "100%" }}>
                    <Table
                      bordered
                      rowKey="id"
                      scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                      rowSelection={{
                        type: "radio",
                        ...rowSelectionStock
                      }}
                      components={{
                        header: {
                          cell: ResizableTitle
                        }
                      }}
                      columns={Boolean(mergeColumnsStock) && mergeColumnsStock.length > 0 ? mergeColumnsStock : columnsStock}
                      dataSource={this.state.stockTableList}
                      loading={this.state.loading}
                      onChange={this.handleTableChangeStock}
                      pagination={false}
                    // pagination={this.state.paginationStock}
                    />
                  </div>
                </>
              }
            ></DraggableModal>

            <DraggableModal
              centered
              width={900}
              title={<FormattedMessage id="InventoryLog.reserve" defaultMessage="Reserve" />}
              open={this.state.showReserveModal}
              onCancel={() => {
                this.setState({
                  showReserveModal: false
                });
              }}
              footer={[
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      showReserveModal: false
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
              ]}
              content={
                <>
                  <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
                    <Form id="FormReserve" initialValues={{ remember: false }} onFinish={this.saveReserveSubmit} ref={this.formRefReserve}>
                      {
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="InventoryLog.availableQuantity" defaultMessage="Available Stock Quantity" />}
                          style={{ marginBottom: "5px" }}
                          name="availableQuantity"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <InputNumber
                            parser={value => value.replace(",", ".")}
                            formatter={value => value.replace(".", ",")}
                            style={{
                              width: "100%"
                            }}
                            min={0}
                            readOnly
                          />
                        </Form.Item>
                      }
                      {
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="InventoryLog.ReservedQuantity" defaultMessage="Reserved Quantity" />}
                          style={{ marginBottom: "5px" }}
                          name="reservedQuantity"
                          rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <InputNumber
                            parser={value => value.replace(",", ".")}
                            formatter={value => value.replace(".", ",")}
                            style={{
                              width: "100%"
                            }}
                            min={0}
                            max={this.state.availableQuantity}
                          />
                        </Form.Item>
                      }
                      {
                        <Row gutter={[16, 16]}>
                          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                            <Button id="SaveButton" style={{ width: "100%" }} variant="success" type="submit">
                              <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                            </Button>
                          </Col>
                          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                        </Row>
                      }
                    </Form>
                  </div>
                </>
              }
            ></DraggableModal>

            <DraggableModal
              centered
              width={900}
              title={<FormattedMessage id="InventoryLog.ToBeReleased" defaultMessage="To Be Released" />}
              open={this.state.showReleaseModal}
              onCancel={() => {
                this.setState({
                  showReleaseModal: false
                });
              }}
              footer={[
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      showReleaseModal: false
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
              ]}
              content={
                <>
                  <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
                    <Form id="FormReserve" initialValues={{ remember: false }} onFinish={this.saveReleaseSubmit} ref={this.formRefRelease}>
                      {
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="InventoryLog.availableQuantity" defaultMessage="Available Stock Quantity" />}
                          style={{ marginBottom: "5px" }}
                          name="availableQuantity"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <InputNumber
                            parser={value => value.replace(",", ".")}
                            formatter={value => value.replace(".", ",")}
                            style={{
                              width: "100%"
                            }}
                            min={0}
                            readOnly
                          />
                        </Form.Item>
                      }
                      {
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="InventoryLog.ReservedQuantity" defaultMessage="To Be Released Quantity" />}
                          style={{ marginBottom: "5px" }}
                          name="toBeReleasedQuantity"
                          rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <InputNumber
                            parser={value => value.replace(",", ".")}
                            formatter={value => value.replace(".", ",")}
                            style={{
                              width: "100%"
                            }}
                            min={0}
                            max={this.state.availableQuantity}
                          />
                        </Form.Item>
                      }
                      {
                        <Row gutter={[16, 16]}>
                          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                            <Button id="SaveButton" style={{ width: "100%" }} variant="success" type="submit">
                              <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                            </Button>
                          </Col>
                          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                        </Row>
                      }
                    </Form>
                  </div>
                </>
              }
            ></DraggableModal>

            <DraggableModal
              open={this.state.modalRFQNoShow}
              onCancel={() => {
                this.setState({ modalRFQNoShow: false });
              }}
              width={1200}
              centered
              footer={[
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ modalRFQNoShow: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                </Button>
              ]}
              content={
                <>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                      <div style={{ marginBottom: "16px" }}>
                        <label>
                          <FormattedMessage id="RequestForQuotation.DateFormatType" defaultMessage="Date Format Type" />
                        </label>
                        <Form.Item
                          name="dateFormatType"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Select allowClear style={{ width: "100%" }} optionFilterProp="children" onChange={this.handleDateFormatTypeChange}>
                            {Boolean(this.state["dateFormatSelect"]) &&
                              this.state["dateFormatSelect"].length > 0 &&
                              this.state["dateFormatSelect"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                      <div style={{ marginBottom: "16px" }}>
                        <label>
                          <FormattedMessage id="RequestForQuotation.Text" defaultMessage="Text" />
                        </label>
                        <Form.Item
                          name="text"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Input onChange={this.handleTextChange} />
                        </Form.Item>
                      </div>
                    </Col>

                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                      <div style={{ marginBottom: "16px" }}>
                        <label>
                          <FormattedMessage id="RequestForQuotation.Alteration" defaultMessage="Alteration" />
                        </label>
                        <Form.Item
                          name="alteration"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Select allowClear style={{ width: "100%" }} optionFilterProp="children" onChange={this.handleAlterationChange}>
                            {Boolean(this.state["alterationSelect"]) &&
                              this.state["alterationSelect"].length > 0 &&
                              this.state["alterationSelect"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                      <div style={{ marginBottom: "16px" }}>
                        <label>
                          <FormattedMessage id="RequestForQuotation.StartingWith" defaultMessage="Starting With" />
                        </label>
                        <Form.Item
                          name="startingWith"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Input onChange={this.handleStartingWithChange} />
                        </Form.Item>
                      </div>
                    </Col>
                    <ArrowRightOutlined onClick={this.handleArrowRightClick} style={{ cursor: "pointer" }} />
                    <UndoOutlined />
                    <br></br>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                      <div style={{ marginBottom: "16px" }}>
                        <label>
                          <FormattedMessage id="RequestForQuotation.Code" defaultMessage="Code" />
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Form.Item name="code">
                    <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                      <ReactQuill theme="snow" value={this.state.codeTotal || ""} readOnly={true} modules={modules} formats={formats} />
                    </Col>
                  </Form.Item>
                </>
              }
            />

            <DraggableModal
              title={
                <span>
                  <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                  <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                    <FormattedMessage id="RequestForQuotation.Remove" defaultMessage="Remove" />
                  </span>
                </span>
              }
              centered
              open={this.state.modalRemoveOurExpectedShow}
              onOk={() => this.removeOurExpected()}
              onCancel={() => this.setState({ modalRemoveOurExpectedShow: false })}
              okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
              cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
              content={
                <>
                  <FormattedMessage id="RequestForQuotation.MessageRemoveItem" defaultMessage="Do you really want to remove item ?" />
                </>
              }
            ></DraggableModal>

            <DraggableModal
              title={
                <span>
                  <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                  <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                    <FormattedMessage id="RequestForQuotation.AddMoreItems" defaultMessage="Add More Items" />
                  </span>
                </span>
              }
              width={1200}
              centered
              open={this.state.addMoreItemsModalShow}
              onCancel={() => this.setState({ addMoreItemsModalShow: false })}
              okButtonProps={{ hidden: true }}
              content={
                <RfqAddMoreItems random={this.state.random} rfqId={this.state.id} close={() => {
                  this.setState({
                    addMoreItemsModalShow: false
                  }, () => {
                    const { projectTakeOffId, itemType, id, requestForPurchaseId } = this.state;
                    this.getPricesData(projectTakeOffId, itemType, id, requestForPurchaseId);
                  })
                }} />
              }
            >
            </DraggableModal>

            <Drawer
              title={<FormattedMessage id="RequestForQuotation.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
              width={1200}
              closable={false}
              onClose={() => {
                this.setState({ drawerSettingsShow: false });
              }}
              open={this.state.drawerSettingsShow}
            >
              <PageSettings pageName={"Request_For_Quotation"} />
            </Drawer>
          </div>
        </Badge.Ribbon>
      </>
    );
  }

  dynamicAttirbute(layout, name) {
    return (
      <Form.List name={name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item {...layout} label={index + 1}>
                <Form.Item
                  {...field}
                  style={{
                    display: "inline-block",
                    width: "calc(10%)"
                  }}
                  name={[field.name, "check"]}
                  valuePropName="checked"
                >
                  <Checkbox autoFocus checked={true} defaultChecked={true}></Checkbox>
                </Form.Item>

                <Form.Item
                  {...field}
                  style={{
                    display: "inline-block",
                    width: "calc(60%)"
                  }}
                  name={[field.name, "description"]}
                >
                  <Input></Input>
                </Form.Item>
                <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} />
              </Form.Item>
            ))}
            <Form.Item>
              <Row gutter={[16, 16]}>
                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}></Col>
                <Col md={4}></Col>
                <Col md={6}>
                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    <FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" />
                  </AntButton>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row gutter={[16, 16]}>
                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <Tooltip title={<FormattedMessage id="General.ShowItems" defaultMessage="Show Items" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      hidden={this.state.showAllSubItems === name ? true : false}
                      onClick={() => {
                        let { newItemState } = this.prepareAttributes(this.state.selectedRow, false, false, name);

                        this.setState({
                          ...newItemState,
                          showAllSubItems: name
                        });
                      }}
                      icon={<EyeTwoTone style={{ fontSize: "25px", cursor: "pointer" }} />}
                    ></AntButton>
                  </Tooltip>

                  <Tooltip title={<FormattedMessage id="General.HideItems" defaultMessage="Hide Items" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      hidden={!(this.state.showAllSubItems === name ? true : false)}
                      onClick={() => {
                        let { newItem, newItemState } = this.prepareAttributes(this.state.selectedRow, true, false, name);

                        this.setState({
                          ...newItemState,
                          showAllSubItems: !name
                        });
                      }}
                      icon={<EyeInvisibleTwoTone style={{ fontSize: "25px", cursor: "pointer" }} />}
                    ></AntButton>
                  </Tooltip>
                </Col>
                <Col md={10}></Col>
              </Row>
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RequestForQuotation);
