import React, { useCallback } from "react";
import { useNodesState, useEdgesState, addEdge, MiniMap, Controls, Background, MarkerType } from "react-flow-renderer";
export const initialElements = [
  {
    id: "11",
    type: "input", // input node
    data: { label: "Input Node" },
    position: { x: 0, y: 0 }
  }
];

export const nodes = [
  {
    id: "10",
    type: "input", // input node
    data: { label: "Input Node" },
    position: { x: 0, y: 20 }
  },

  {
    id: "1",
    type: "input",
    position: { x: 250, y: 0 }
  },
  {
    id: "2",
    data: { label: " output node" },
    position: { x: 100, y: 50 },
    style: {
      background: "#eff"
    }
  },
  {
    id: "3",

    position: { x: 400, y: 80 },
    style: {
      background: "#CAD5EF",
      color: "#444",
      border: "1px solid #332138",
      width: 180
    }
  },
  {
    id: "4",
    position: { x: 500, y: 150 },
    data: { label: "Group A" },
    style: {
      backgroundColor: "rgba(255, 0, 0, 0.2)",
      color: "#444",
      border: "1px solid #222138",
      width: 200,
      height: 200
    },

    markerEnd: {
      type: MarkerType.ArrowClosed
    }
  },
  {
    id: "5",

    data: { label: "drag me around 😎" },
    position: { x: 200, y: 225 }
  },
  {
    id: "6",
    type: "output",
    data: { label: " output node" },
    position: { x: 100, y: 480 }
  },
  {
    id: "7",
    type: "output",
    data: { label: "Another output node" },
    position: { x: 400, y: 450 }
  },
  {
    id: "8",
    data: { label: "A1" },
    position: { x: 10, y: 30 },
    parentNode: "4",
    extent: "parent",
    className: "light"
  },

  {
    id: "9",
    data: { label: "A2" },
    position: { x: 10, y: 150 },
    style: {
      backgroundColor: "rgba(50, 30,200, 0.4)",
      color: "#444",
      border: "1px solid #222138"
    },
    parentNode: "4",
    extent: "parent",
    className: "light"
  }
];

export const edges = [
  { id: "e1-2", source: "1", target: "2", label: "this is an edge label" },
  { id: "e1-3", source: "1", target: "3" },
  {
    id: "e3-4",
    source: "3",
    target: "4",
    animated: true,
    style: { stroke: "green" },
    label: "animated edge"
  },
  {
    id: "e2-5",
    source: "2",
    target: "5",
    label: "edge with arrow head",
    markerEnd: {
      type: MarkerType.ArrowClosed
    }
  },

  {
    id: "e2-8",
    source: "2",
    target: "8",
    style: { stroke: "blue" },
    label: "edge with arrow head",
    markerEnd: {
      type: MarkerType.ArrowClosed
    }
  },

  {
    id: "e8-9",
    source: "8",
    target: "9",
    label: "edge with arrow head",
    markerEnd: {
      type: MarkerType.ArrowClosed
    }
  },

  {
    id: "e5-6",
    source: "5",
    target: "6",
    type: "smoothstep",
    label: "smooth step edge",
    labelBgPadding: [8, 4],
    labelBgBorderRadius: 4,
    labelBgStyle: { fill: "#FACC00", color: "#fff", fillOpacity: 0.8 },
    markerEnd: {
      type: MarkerType.ArrowClosed
    }
  },
  {
    id: "e5-7",
    source: "5",
    target: "7",
    type: "step",
    animated: true,
    label: "animated styled edge",
    style: { stroke: "red" }
  }
];
