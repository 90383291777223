import { Form, Input } from "antd";
import React from "react";
import PrepareFormItemProps from "./ElementUtils";

const TextComponent = props => {
  const { field, layout } = props;
  return (
    <>
      <Form.Item {...layout} {...PrepareFormItemProps(field)}>
        <Input placeholder={field.label} style={{ width: "100%" }} />
      </Form.Item>
    </>
  );
};

export default TextComponent;
