import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form } from "antd";
import React from "react";
import { Table } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ElementComponent from "./Element";
import PrepareFormItemProps from "./ElementUtils";

const FormListComponent = ({ field, layout, form, refresh }) => {
  const { pageFormInputCompanyList } = field.formListPageForm;
  let formItemProps = PrepareFormItemProps(field);
  delete formItemProps.name;
  const localLayout = {
    labelCol: { span: Boolean(layout.labelCol) ? layout.labelCol.span : undefined },
    wrapperCol: {
      span: 24 - (Boolean(layout.labelCol) ? layout.labelCol.span : 0)
    },
    style: { ...layout.style }
  };
  const style = {
    style: layout.style
  };
  let checkBoxesObject = {};
  pageFormInputCompanyList
    .filter(x => x.inputType === "CHECKBOX")
    .forEach(item => {
      checkBoxesObject[item.inputName] = true;
    });

  return (
    <Form.List name={field.inputName}>
      {(fields, { add, remove }) => {
        return (
          <Form.Item {...localLayout} {...formItemProps}>
            <Table borderless size="sm">
              <thead>
                <tr>
                  {fields.length !== 0 &&
                    pageFormInputCompanyList
                      .filter(x => {
                        return x.inputType !== "HIDDEN";
                      })
                      .map(item => {
                        return (
                          <th key={item.id}>
                            <FormattedMessage id={item.labelLanguageKey} defaultMessage={item.label} />
                          </th>
                        );
                      })}
                  <th hidden={fields.length === 0}>
                    <FormattedMessage id="GENERAL.Remove" defaultMessage="Remove" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {fields.map(field => {
                  return (
                    <tr key={field.key}>
                      {pageFormInputCompanyList
                        .filter(x => {
                          return x.inputType !== "HIDDEN";
                        })
                        .map((item, i) => {
                          //make form nostyle and no label
                          let input = { ...item };
                          // input.label = undefined;
                          input.inputName = [field.name, input.inputName];
                          input.hideLabel = true;
                          return (
                            <td key={i}>
                              <ElementComponent layout={style} field={input} form={form} refresh={refresh} />
                            </td>
                          );
                        })}
                      <td>
                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={pageFormInputCompanyList.length + 1} className="justify-content-end">
                    <Button type="dashed" onClick={() => add({ ...checkBoxesObject })} block icon={<PlusOutlined />}>
                      <span className="mr-1">Add</span> <FormattedMessage id={field.labelLanguageKey} defaultMessage={field.label} />
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Form.Item>
        );
      }}
    </Form.List>
  );
};

export default FormListComponent;
