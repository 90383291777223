import { FileImageOutlined, FilePdfOutlined, FullscreenOutlined } from "@ant-design/icons";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button as AntButton } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import avatarPersonnel from "../../assets/img/avatar-personnel.svg";
import OrgChart from "../../Components/react-org-chart";
import { showError } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";

//For downloading org chart as image or pdf based on id
const downloadImageId = "download-image";
const downloadPdfId = "download-pdf";
const zoomExtentId = "zoom-extend";
export class OrganizationalChart extends Component {
  constructor() {
    super();
    this.state = {
      formName: "OrganizationalChart",
      controllerName: "organizationalChart",
      modalDeleteShow: false,
      id: null
    };
  }
  componentDidMount = async () => {
    let chartd = await this.getChartData(0);
    this.tree.person.totalReports = chartd.length;
    this.tree.children.push(...chartd);
    this.setState({
      chartData: this.tree
    });

    //this.fillChartData();
  };
  getChartData = async departmentId => {
    let url = "/api/personnelLog/organizationalChart/" + departmentId;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      showError(response.message);
      return [];
    } else {
      return response.data;
    }
  };

  tree = {
    id: 0,
    person: {
      id: 0,
      avatar: avatarPersonnel,
      name: "Company Name",
      title: "",
      totalReports: 3
    },
    hasChild: true,
    hasParent: false,
    children: []
  };

  // tree1 = [
  //   {
  //     id: 36,
  //     person: {
  //       id: 36,
  //       department: "",
  //       name: "Tomasz polaski",
  //       title: "IT Specialist",
  //       totalReports: 4
  //     },
  //     hasChild: true,
  //     hasParent: false
  //     // children: [],
  //   },
  //   {
  //     id: 32,
  //     person: {
  //       id: 32,
  //       department: "",
  //       name: "Emanuel walker",
  //       title: "IT Specialist",
  //       totalReports: 0
  //     },
  //     hasChild: true,
  //     hasParent: true,
  //     children: []
  //   },
  //   {
  //     id: 25,
  //     person: {
  //       id: 25,
  //       department: "",
  //       name: "Kerry peter",
  //       title: "IT Specialist",
  //       totalReports: 3
  //     },
  //     hasChild: true,
  //     hasParent: true
  //     // children: [],
  //   }
  // ];

  // tree2 = [
  //   {
  //     id: 56,
  //     person: {
  //       id: 56,
  //       department: "",
  //       name: "Sam John",
  //       title: "HR",
  //       totalReports: 2,
  //       link: "https://github.com/unicef/react-org-chart"
  //     },
  //     hasChild: true,
  //     hasParent: true
  //     // children: [],
  //   },
  //   {
  //     id: 66,
  //     person: {
  //       id: 66,
  //       department: "",
  //       name: "John doe",
  //       title: "Developer",
  //       totalReports: 0,
  //       link: "https://github.com/unicef/react-org-chart"
  //     },
  //     hasChild: true,
  //     hasParent: true,
  //     children: []
  //   },
  //   {
  //     id: 76,
  //     person: {
  //       id: 76,
  //       // avatar: avatarPersonnel,
  //       department: "",
  //       name: "Emilia rogers",
  //       title: "Developer",
  //       totalReports: 0,
  //       link: "https://github.com/unicef/react-org-chart"
  //     },
  //     hasChild: true,
  //     hasParent: true,
  //     children: []
  //   },
  //   {
  //     id: 60,
  //     person: {
  //       id: 60,
  //       department: "",
  //       name: "Ellen cott",
  //       title: "IT Officer",
  //       totalReports: 0
  //     },
  //     hasChild: false,
  //     hasParent: true,
  //     children: []
  //   }
  // ];

  // tree3 = [
  //   {
  //     id: 70,
  //     person: {
  //       id: 70,
  //       department: "",
  //       name: "Kenneth dom",
  //       title: "IT Officer",
  //       totalReports: 0
  //     },
  //     hasChild: false,
  //     hasParent: true,
  //     children: []
  //   },
  //   {
  //     id: 45,
  //     person: {
  //       id: 45,
  //       department: "",
  //       name: "Kin baker",
  //       title: "IT Officer",
  //       totalReports: 0
  //     },
  //     hasChild: false,
  //     hasParent: true,
  //     children: []
  //   }
  // ];

  // tree4 = [
  //   {
  //     id: 102,
  //     person: {
  //       id: 102,
  //       department: "",
  //       name: "Hendy kinger",
  //       title: "Manager",
  //       totalReports: 0
  //     },
  //     hasChild: true,
  //     hasParent: true,
  //     children: []
  //   },
  //   {
  //     id: 455,
  //     person: {
  //       id: 455,
  //       department: "",
  //       name: "Kate baker",
  //       title: "IT Officer",
  //       totalReports: 0
  //     },
  //     hasChild: false,
  //     hasParent: true,
  //     children: []
  //   },
  //   {
  //     id: 444,
  //     person: {
  //       id: 444,
  //       department: "",
  //       name: "John medis",
  //       title: "IT Officer",
  //       totalReports: 0
  //     },
  //     hasChild: false,
  //     hasParent: true,
  //     children: []
  //   },

  //   {
  //     id: 456,
  //     person: {
  //       id: 456,
  //       department: "",
  //       name: "Brett lee",
  //       title: "IT Officer",
  //       totalReports: 0
  //     },
  //     hasChild: false,
  //     hasParent: true,
  //     children: []
  //   }
  // ];

  getChild = async id => {
    let chartd = await this.getChartData(id);
    return chartd;
  };

  getParent = d => {
    if (d.id === 100) {
    }
  };

  handleDownload = () => {
    this.setState({ downloadingChart: false });
  };

  handleOnChangeConfig = config => {
    this.setState({ config: config });
  };

  handleLoadConfig = () => {
    const { config } = this.state;
    return config;
  };
  restartTable = async () => {
    this.fillChartData();
  };
  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <div className="row">
          <div className="col-md-12 d-flex">
            <span className="h3 flex-grow-1">
              <FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage={"Organizational Chart"} />
            </span>
            <AntButton style={{ border: "0px" }} id={zoomExtentId} icon={<FullscreenOutlined style={{ fontSize: "23px", color: "#08c" }} />}>
              {/* Fit to screen */}
            </AntButton>
            <AntButton style={{ border: "0px" }} id={downloadImageId} icon={<FileImageOutlined style={{ fontSize: "23px", color: "#08c" }} />}>
              {/* Save As Image */}
            </AntButton>
            <AntButton style={{ border: "0px" }} id={downloadPdfId} icon={<FilePdfOutlined style={{ fontSize: "23px", color: "#08c" }} />}>
              {/* Save As PDF */}
            </AntButton>
          </div>
        </div>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          {this.state.chartData ? (
            <>
              <OrgChart
                tree={this.tree}
                downloadImageId={downloadImageId}
                downloadPdfId={downloadPdfId}
                zoomExtentId={zoomExtentId}
                onConfigChange={config => {
                  this.handleOnChangeConfig(config);
                }}
                loadConfig={d => {
                  let configuration = this.handleLoadConfig(d);
                  if (configuration) {
                    return configuration;
                  }
                }}
                downlowdedOrgChart={d => {
                  this.handleDownload();
                }}
                loadImage={d => {
                  return Promise.resolve(avatarPersonnel);
                }}
                // loadParent={d => {
                //   const parentData = this.getParent(d);
                //   return parentData;
                // }}
                loadChildren={d => {
                  const childrenData = this.getChild(d.departmentId);
                  return childrenData;
                }}
              />
            </>
          ) : null}
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationalChart);
