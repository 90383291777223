import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { EditOutlined } from "@material-ui/icons";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Form, Table, Input, DatePicker, Badge, Button as AntButton, Col, Row, Card, InputNumber } from "antd";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import { handleRequest } from "../../ApiConnector";
import CreateIcon from "@material-ui/icons/AddCircle";
import { Select } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { getColumnFilter, fillFilterOperations } from "../../Components/DataTableFilterComponent";
import ExcelReader from "../excel/ExcelReader";

const { Option } = Select;

export class SurrenderOfVehicleLog extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "surrenderOfVehicleLog",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      filePath: "",
      nodes: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();

    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.setFieldsValue({
        equipmentLogName: this.props?.selectedRow?.equipmentLogName,
        plateNo: this.props?.selectedRow?.plateNo,
        budgeNo: this.props?.selectedRow?.budgeNo
      });
    }
  };

  fillComboboxes = async () => {
    fillFilterOperations();
    var responseReasonForSurrender = await handleRequest("GET", "/api/" + this.state.controllerName + "/reasonForSurrender");
    if (responseReasonForSurrender.type === "ERROR") {
      error(responseReasonForSurrender);
    }
    this.setState({
      reasonForSurrenderSelectItems: Boolean(responseReasonForSurrender.data) ? responseReasonForSurrender.data : []
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.setFieldsValue({
        equipmentLogName: this.props?.selectedRow?.equipmentLogName,
        plateNo: this.props?.selectedRow?.plateNo,
        budgeNo: this.props?.selectedRow?.budgeNo
      });
    }
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: ""
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.equipmentSearch(row.equipmentLogName);
    this.budgeNoSearch(row.budgeNo);
    this.plateNoSearch(row.plateNo);

    this.formRef.current.setFieldsValue({
      ...row
    });

    console.log(row);
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      maintenanceLogProcessId: this.props?.selectedRow?.id,
      equipmentLogId: this.props?.selectedRow?.equipmentLogId
    };

    if (!Boolean(newItem.equipmentLogId)) {
      showWarning("Equipment Log not found");
      return;
    }

    if (!Boolean(newItem.maintenanceLogProcessId)) {
      showWarning("Maintenance Log Process not found");
      return;
    }

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      maintenanceLogProcessId: this.props?.selectedRow?.id,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          if (Array.isArray(element))
            element.forEach(element2 => {
              let obj = { columns: element2 };
              list.push(obj);
            });
          else {
            let obj = { columns: element };
            list.push(obj);
          }
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/importDynamicaly", list);
          if (response.type === "ERROR") {
            error(response);
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          error("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };
    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);
    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  showReport = async rowData => {
    try {
      if (rowData.photoPath !== null && rowData.photoPath !== undefined && rowData.photoPath !== "") {
        const fileRealUrl = rowData.photoPath;
        window.location.href = fileRealUrl;
      } else {
        this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
      }
    } catch (error) {
      this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
    }
  };

  personnelSearch = async item => {
    console.log("item", item);
    this.setState({ loadingSearch: true });
    if (Boolean(item) && item.length > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/personnelSearch/" + item);
      console.log("response", response.data);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          surrenderedByIdSelectItems: [],
          loadingSearch: false
        });
      } else {
        if (Boolean(response.data)) {
          this.setState({
            surrenderedByIdSelectItems: response.data,
            loadingSearch: false
          });
        } else {
          this.setState({
            surrenderedByIdSelectItems: [],
            loadingSearch: false
          });
        }
      }
    } else {
      this.setState({
        surrenderedByIdSelectItems: [],
        loadingSearch: false
      });
    }
  };

  equipmentSearch = async item => {
    console.log("item", item);
    this.setState({ loadingSearch: true });
    if (Boolean(item) && item.length > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/equipmentSearch/" + item);
      console.log("response", response.data);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          equipmentLogIdSelectItems: [],
          loadingSearch: false
        });
      } else {
        if (Boolean(response.data)) {
          this.setState({
            equipmentLogIdSelectItems: response.data,
            loadingSearch: false
          });
        } else {
          this.setState({
            equipmentLogIdSelectItems: [],
            loadingSearch: false
          });
        }
      }
    } else {
      this.setState({
        equipmentLogIdSelectItems: [],
        loadingSearch: false
      });
    }
  };

  budgeNoSearch = async item => {
    console.log("item", item);
    this.setState({ loadingSearch: true });
    if (Boolean(item) && item.length > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/budgeNoSearch/" + item);
      console.log("response", response.data);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          budgeNoSelectItems: [],
          loadingSearch: false
        });
      } else {
        if (Boolean(response.data)) {
          this.setState({
            budgeNoSelectItems: response.data,
            loadingSearch: false
          });
        } else {
          this.setState({
            budgeNoSelectItems: [],
            loadingSearch: false
          });
        }
      }
    } else {
      this.setState({
        budgeNoSelectItems: [],
        loadingSearch: false
      });
    }
  };

  plateNoSearch = async item => {
    console.log("item", item);
    this.setState({ loadingSearch: true });
    if (Boolean(item) && item.length > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/plateNoSearch/" + item);
      console.log("response", response.data);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          plateNoSelectItems: [],
          loadingSearch: false
        });
      } else {
        if (Boolean(response.data)) {
          this.setState({
            plateNoSelectItems: response.data,
            loadingSearch: false
          });
        } else {
          this.setState({
            plateNoSelectItems: [],
            loadingSearch: false
          });
        }
      }
    } else {
      this.setState({
        plateNoSelectItems: [],
        loadingSearch: false
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="SurrenderOfVehicleLog.Vehicle" defaultMessage="Vehicle" />,
        key: "equipmentLogId",
        render: record => {
          return record.equipmentLogName;
        },
        ...getColumnFilter({
          inputName: "equipmentLogId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["equipmentLogIdSelectItems"]) ? this.state["equipmentLogIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SurrenderOfVehicleLog.PlateNo" defaultMessage="Plate No" />,
        key: "equipmentLogId",
        render: record => {
          return record.plateNo;
        },
        ...getColumnFilter({
          inputName: "equipmentLogId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["equipmentLogIdSelectItems"]) ? this.state["equipmentLogIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SurrenderOfVehicleLog.BudgeNo" defaultMessage="Budge No" />,
        key: "equipmentLogId",
        render: record => {
          return record.budgeNo;
        },
        ...getColumnFilter({
          inputName: "equipmentLogId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["equipmentLogIdSelectItems"]) ? this.state["equipmentLogIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SurrenderOfVehicleLog.Km" defaultMessage="Km" />,
        key: "km",
        render: record => {
          return record.km;
        },
        ...getColumnFilter({
          inputName: "km",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SurrenderOfVehicleLog.Hour" defaultMessage="Hour" />,
        key: "hour",
        render: record => {
          return record.hour;
        },
        ...getColumnFilter({
          inputName: "hour",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SurrenderOfVehicleLog.SurrenderedBy" defaultMessage="Surrendered By" />,
        key: "surrenderedById",
        render: record => {
          return record.budgeNo;
        },
        ...getColumnFilter({
          inputName: "surrenderedById",
          inputType: "SELECT",
          selectItems: Boolean(this.state["surrenderedByIdSelectItems"]) ? this.state["surrenderedByIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SurrenderOfVehicleLog.ReasonForSurrender" defaultMessage="Reason for Surrender" />,
        key: "reasonForSurrender",
        render: record => {
          return record.reasonForSurrender;
        },
        ...getColumnFilter({
          inputName: "reasonForSurrender",
          inputType: "SELECT",
          selectItems: Boolean(this.state["reasonForSurrenderSelectItems"]) ? this.state["reasonForSurrenderSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SurrenderOfVehicleLog.DateOfSurrender" defaultMessage="Date of Surrender" />,
        key: "dateOfSurrender",
        render: record => {
          return record.dateOfSurrender ? moment(record.dateOfSurrender).format("DD-MM-YYYY HH:mm") : "";
        },
        sorter: true
      },
      {
        title: "Last Update",
        key: "lastUpdated",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
              <ExcelColumn label="Name" value="name" />
              <ExcelColumn label="Arabic Name" value="arabic_name" />
              <ExcelColumn label="Customer" value="customer" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <Badge.Ribbon text="Surrender of Vehicle" placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <br />
            <Row gutter={[16, 16]}>
              <Col md={21}> </Col>
              <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>

            <div hidden={this.state.hideInputs}>
              <Row gutter={16} style={{ marginBottom: "16px" }}>
                <br></br>
                <br></br>
                <br></br>
                {/* Sol Card */}
                <Col span={1}> </Col>
                <Col span={11}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="SurrenderOfVehicleLog.Equipment" defaultMessage="Equipment" />}
                      name="equipmentLogName"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ marginBottom: 0, width: "100%" }} disabled />
                    </Form.Item>

                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="SurrenderOfVehicleLog.PlateNo" defaultMessage="Plate No" />}
                      name="plateNo"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ marginBottom: 0, width: "100%" }} disabled />
                    </Form.Item>

                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="SurrenderOfVehicleLog.BudgeNo" defaultMessage="Budge No" />}
                      name="budgeNo"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ marginBottom: 0, width: "100%" }} disabled />
                    </Form.Item>

                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="SurrenderOfVehicleLog.Km" defaultMessage="Km" />}
                      name="km"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="SurrenderOfVehicleLog.Hour" defaultMessage="Hour" />}
                      name="hour"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="SurrenderOfVehicleLog.SurrenderedBy" defaultMessage="Surrendered by" />}
                      name="surrenderedById"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Select
                        allowClear
                        showSearch
                        onSearch={this.personnelSearch}
                        style={{ marginBottom: 0, width: "100%" }}
                        loading={this.state.loadingSearch}
                        onChange={value => {
                          this.setState({
                            surrenderedById: value
                          });
                        }}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {Boolean(this.state["surrenderedByIdSelectItems"]) &&
                          this.state["surrenderedByIdSelectItems"].length > 0 &&
                          this.state["surrenderedByIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="SurrenderOfVehicleLog.ReasonForSurrender" defaultMessage="Reason For Surrender" />}
                      name="reasonForSurrender"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Select allowClear showSearch style={{ marginBottom: 0, width: "100%" }}>
                        {Boolean(this.state["reasonForSurrenderSelectItems"]) &&
                          this.state["reasonForSurrenderSelectItems"].length > 0 &&
                          this.state["reasonForSurrenderSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Card>
                </Col>
                <br></br>
                <br></br>
                {/* Sol Card */}
                <Col span={11}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="SurrenderOfVehicleLog.Model" defaultMessage="Model" />}
                        name="model"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input style={{ width: "100%" }} readOnly />
                      </Form.Item>
                    }

                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="SurrenderOfVehicleLog.SerialNo" defaultMessage="Serial No" />}
                        name="serialNo"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input style={{ width: "100%" }} readOnly />
                      </Form.Item>
                    }

                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="SurrenderOfVehicleLog.ChasisNo" defaultMessage="Chasis No" />}
                        name="chasisNo"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input style={{ width: "100%" }} readOnly />
                      </Form.Item>
                    }

                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="SurrenderOfVehicleLog.NextMaintenanceDate" defaultMessage="Next Maintenance Date" />}
                        name="nextMaintenanceDate"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime readOnly />
                      </Form.Item>
                    }
                  </Card>
                </Col>
                <Col span={1}> </Col>
              </Row>

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              rowKey="id"
              size="small"
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SurrenderOfVehicleLog);
