import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { Form, Table, Col, Row, Modal as AntModal, Alert, Tooltip, Button as AntButton, Typography } from "antd";
import { handleRequest } from "../../ApiConnector";
import moment from "moment";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { Print } from "@material-ui/icons";
import Viewer, { defaultLayout, Worker } from "@phuocng/react-pdf-viewer";
import { Resizable } from "react-resizable";

const { Text } = Typography;

const dataList = [];

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class EmployeesSalary extends Component {
  constructor() {
    super();
    this.state = {
      controller: "employeesSalary",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      salaryDetailList: [],
      modalPdfViewer: false,
      pdfFile: "",

      id: null,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    await this.initialize();
  };

  async initialize() {
    let urlDailyFirstData = "/api/" + this.state.controller + "/dailyFirstData";
    const responseDailyFirstData = await handleRequest("GET", urlDailyFirstData);
    if (responseDailyFirstData.type === "ERROR") {
      error(responseDailyFirstData);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
    }
  }

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    var parent = dataList.find(p => p.key === row.parentId);
    let parentId = [];

    if (Boolean(parent)) {
      parentId = parent.lineage;
    }

    this.formRef.current.setFieldsValue({
      ...row,
      parentId: parentId
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controller + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controller + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
          element.key = index + 1;
          if (Boolean(element.timeIn)) {
            element.timeIn = moment(element.timeIn).format("HH:mm");
          }
          if (Boolean(element.timeOut)) {
            element.timeOut = moment(element.timeOut).format("HH:mm");
          }
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: Boolean(values.parentId) ? values.parentId[values.parentId.length - 1] : null
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controller + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controller + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";

    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controller + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            // {
            //   name: "Edit",
            //   icon: <EditOutlined fontSize="small" color="primary" />,
            //   actionClick: this.edit.bind(this)
            // },
            // {
            //   name: "Delete",
            //   icon: <DeleteIcon fontSize="small" color="error" />,
            //   actionClick: this.deleteModal.bind(this)
            // },
            {
              name: "Print",
              icon: <Print fontSize="small" color="error" />,
              actionClick: this.generateReport.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  generateReport = async row => {
    let url = "/api/employeesSalary/getReport/" + row.id;

    const response = await handleRequest("GET", url);

    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false
      });

      showError("There is no created report for selected item");
    }
  };

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showReport = async url => {
    try {
      const fileRealUrl = url;

      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true
      });
    } catch (error) {
      alert("File cant found.");
    }
  };

  showTimesheetDetail = async record => {
    var newObj = {
      personnelLogId: record.personnelLogId
    };
    var response = await handleRequest("POST", "/api/" + this.state.controller + "/timesheetDetail", newObj);
    if (response.type === "ERROR") {
      showError();
    } else {
      this.setState({
        timesheetDetailShow: true,
        tableListTimesheet: Boolean(response.data) ? response.data : []
      });
    }
  };

  showSalaryDetail = async record => {
    var newObj = {
      id: record.personnelLogId
    };
    var response = await handleRequest("POST", "/api/" + this.state.controller + "/salaryDetail", newObj);
    if (response.type === "ERROR") {
      showError();
    } else {
      this.setState({
        salaryDetailShow: true,
        salaryDetailList: Boolean(response.data) && Boolean(response.data.data) && response.data.data.length > 0 ? response.data.data : [],
        modalSalaryTitle: record.month + " Salary Summary",
        modalWorkingDays: record.workingHours,
        modalOvertime: record.overtime,
        modalPersonnelName: record.personnelName,
        contractTotal: response.data.contractTotal,
        payslipTotal: response.data.payslipTotal
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };
    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };


      this.setState({        
        columns: newColumns,
      });
    };
    const mergeColumns =
    Boolean(this.state.columns) && this.state.columns.length > 0
      ? this.state.columns.map((col, index) => ({
        ...col,
        onHeaderCell: column => ({
          width: column.width,
          onResize: handleResize(index)
        })
      }))
      : Boolean(columns) &&
      columns.length > 0 &&
      columns.map((col, index) => ({
        ...col,
        onHeaderCell: column => ({
          width: column.width,
          onResize: handleResize(index)
        })
      }));

    const columns = [
      {
        title: "S/N",
        key: "id",                
        ellipsis: true,
        width: 50,
        
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        })
      },
      {
        title: "Actions",
        key: "actions",                
        ellipsis: true,
        width: 50,
        render: record => {
          return this.action(record);
        },
        
        onCell: (record, rowIndex
          ) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        }),
        width: 70,
        ellipsis: true
      },
      {
        title: <FormattedMessage id="EmployeesSalary.year" defaultMessage="Year" />,
        key: "year",                
        ellipsis: true,
        width: 100,
        render: record => {
          return record.year;
          return <Tooltip title={record.cashLogId}>{record.cashLogId}</Tooltip>;              
        },
        onCell: (record, rowIndex
          ) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        }),
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.month" defaultMessage="Month" />,
        key: "month",                
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.month}>{record.month}</Tooltip>;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.departmentName" defaultMessage="Department" />,
        key: "departmentName",                
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.departmentName}>{record.departmentName}</Tooltip>;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.designationName" defaultMessage="Designation" />,
        key: "designationName",                
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.designationName}>{record.designationName}</Tooltip>;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.identificationNo" defaultMessage="ID" />,
        key: "identificationNo",                
        ellipsis: true,
        width: 100,
        render: record => {
          return <Tooltip title={record.identificationNo}>{record.identificationNo}</Tooltip>;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.personnelName" defaultMessage="Name" />,
        key: "personnelName",                
        ellipsis: true,
        width: 150,
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline"
                }}
                onClick={() => this.showSalaryDetail(record)}
              >
                <Tooltip title={record.personnelName}>{record.personnelName}</Tooltip>;
              </div>
            </React.Fragment>
          );
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.workingHours" defaultMessage="Working Days" />,
        key: "workingHours",                
        ellipsis: true,
        width: 100,
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline"
                }}
                onClick={() => this.showTimesheetDetail(record)}
              >
                {record.workingHours}
              </div>
            </React.Fragment>
          );
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.basicSalary" defaultMessage="Basic Salary" />,
        key: "basicSalary",                
        ellipsis: true,
        width: 100,
        render: record => {
          return <Tooltip title={record.basicSalary}>{record.basicSalary}</Tooltip>;              
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.homeAllowance" defaultMessage="Home Allowance" />,
        key: "homeAllowance",                
        ellipsis: true,
        width: 100,
        render: record => {
          return <Tooltip title={record.homeAllowance}>{record.homeAllowance}</Tooltip>;              
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.transportAllowance" defaultMessage="Transportation Allowance" />,
        key: "transportAllowance",                
        ellipsis: true,
        width: 100,
        render: record => {
          return <Tooltip title={record.transportAllowance}>{record.transportAllowance}</Tooltip>;              
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.foodAllowance" defaultMessage="Food Allowance" />,
        key: "foodAllowance",                
        ellipsis: true,
        width: 100,
        render: record => {
          return <Tooltip title={record.foodAllowance}>{record.foodAllowance}</Tooltip>;              
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.overtime" defaultMessage="Overtime hrs" />,
        key: "overtime",                
        ellipsis: true,
        width: 100,
        render: record => {
          return <Tooltip title={record.overtime}>{record.overtime}</Tooltip>;              
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.totalOvertime" defaultMessage="Overtime" />,
        key: "totalOvertime",                
        ellipsis: true,
        width: 100,
        render: record => {
          return <Tooltip title={record.totalOvertime}>{record.totalOvertime}</Tooltip>;               
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.totalOfPayment" defaultMessage="Total of Payment" />,
        key: "totalOfPayment",                
        ellipsis: true,
        width: 100,
        render: record => {
          return <Tooltip title={record.totalOfPayment}>{record.totalOfPayment}</Tooltip>;              
        },
        sorter: false
      },
      {
        title: "Last Update",
        key: "lastUpdateDate",                
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.lastUpdated}>{moment(record.lastUpdated).format("DD-MM-YYYY HH:mm")}</Tooltip>;
        },
        sorter: false
      }
    ];

    const columnsTimesheet = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="EmployeesSalary.departmentName" defaultMessage="Department" />,
        key: "departmentName",
        render: record => {
          return <Tooltip title={record.departmentName}>{record.departmentName}</Tooltip>;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.designationName" defaultMessage="Designation" />,
        key: "designationName",
        render: record => {
          return <Tooltip title={record.designationName}>{record.designationName}</Tooltip>;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.personnelName" defaultMessage="Name" />,
        key: "personnelName",
        render: record => {
          return <Tooltip title={record.personnelName}>{record.personnelName}</Tooltip>;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.identificationNo" defaultMessage="ID" />,
        key: "identificationNo",
        render: record => {
          return <Tooltip title={record.identificationNo}>{record.identificationNo}</Tooltip>;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.timeIn" defaultMessage="Time In" />,
        key: "timeIn",
        render: record => {
          return Boolean(record.timeIn) ? moment(record.timeIn).format("DD-MM-YYYY HH:mm") : "";
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.timeOut" defaultMessage="Time Out" />,
        key: "timeOut",
        render: record => {
          return Boolean(record.timeOut) ? moment(record.timeOut).format("DD-MM-YYYY HH:mm") : "";
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.workingHours" defaultMessage="Working Hours" />,
        key: "workingHours",
        render: record => {
          return <Tooltip title={record.workingHours}>{record.workingHours}</Tooltip>;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.overtime" defaultMessage="Overtime" />,
        key: "overtime",
        render: record => {
          return <Tooltip title={record.overtime}>{record.overtime}</Tooltip>;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="EmployeesSalary.type" defaultMessage="Type" />,
        key: "type",
        render: record => {
          return <Tooltip title={record.type}>{record.type}</Tooltip>;
        },
        sorter: false
      },
      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return <Tooltip title={record.lastUpdated}>{moment(record.lastUpdated).format("DD-MM-YYYY HH:mm")}</Tooltip>;
        },
        sorter: false
      }
    ];

    const columnsSalaryDetail = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="PersonnelAccount.description" defaultMessage="Description" />,
        key: "description",
        render: record => {
          return <Tooltip title={record.description}>{record.description}</Tooltip>;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="PersonnelAccount.received" defaultMessage="Contract" />,
        key: "contract",
        render: record => {
          return <Tooltip title={record.contract}>{record.contract}</Tooltip>;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="PersonnelAccount.received" defaultMessage="Payslip" />,
        key: "payslip",
        render: record => {
          return <Tooltip title={record.payslip}>{record.payslip}</Tooltip>;
        },
        sorter: false
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={24}>
              <span className="h3">
                <FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage={"Employees Salary"} />
              </span>
            </Col>
          </Row>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            rowKey="id"
            bordered
            components={{
              header: {
                cell: ResizableTitle
              }
            }}
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <AntModal
          title={"Timesheet"}
          visible={this.state.timesheetDetailShow}
          onCancel={() => {
            this.setState({ timesheetDetailShow: false });
          }}
          width={1500}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ timesheetDetailShow: false });
              }}
            >
              Close
            </Button>
          ]}
        >
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              rowKey="id"
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columnsTimesheet}
              dataSource={this.state.tableListTimesheet}
            />
          </div>
        </AntModal>

        <AntModal
          title={this.state.modalSalaryTitle}
          visible={this.state.salaryDetailShow}
          onCancel={() => {
            this.setState({ salaryDetailShow: false });
          }}
          width={1000}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ salaryDetailShow: false });
              }}
            >
              Close
            </Button>
          ]}
        >
          <Row gutter={[16, 16]}>
            <Col md={8}>
              <Alert style={{ backgroundColor: "#f5f5f5", borderColor: "#f5f5f5" }} message={this.state.modalPersonnelName} />
            </Col>
            <Col md={8}>
              <Alert
                style={{ backgroundColor: "#f5f5f5", borderColor: "#f5f5f5" }}
                message="Working Days"
                action={
                  <AntButton size="small" danger>
                    {this.state.modalWorkingDays}
                  </AntButton>
                }
              />
            </Col>
            <Col md={8}>
              <Alert
                style={{ backgroundColor: "#f5f5f5", borderColor: "#f5f5f5" }}
                message="Overtime"
                action={
                  <AntButton size="small" danger>
                    {this.state.modalOvertime}
                  </AntButton>
                }
              />
            </Col>
          </Row>
          <Table
            rowKey="id"
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columnsSalaryDetail}
            dataSource={this.state.salaryDetailList}
            loading={this.state.loading}
            summary={pageData => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={1}></Table.Summary.Cell>
                    <Table.Summary.Cell>Total</Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="danger">{this.state.contractTotal}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="danger">{this.state.payslipTotal}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={1}></Table.Summary.Cell>
                    <Table.Summary.Cell>Received</Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="danger">0</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="danger">0</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </AntModal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfViewer}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesSalary);
