import { DownloadOutlined } from "@ant-design/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import SyncIcon from "@material-ui/icons/Sync";
import { Button as AntButton, Cascader, Badge, Checkbox, message } from "antd";
import "primeicons/primeicons.css";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { OrderList } from "primereact/orderlist";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { TreeTable } from "primereact/treetable";
import React, { Component } from "react";
import { Button, Col, Form, Modal as ModalReact, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import CustomMaterialMenuDynamicMenu from "../CustomMenu/CustomMaterialMenuDynamicMenu";
import PageInput from "./PageInput";
import { error } from "../../MessageHelper";

const dataList = [];
var order = 0;
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.data.id,
      lineage: node.data.lineage,
      title: node.data.menuTitle
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const menuList = [
  {
    page: "dashboard",
    title: "Dashboard",
    submenu: null,
    authority: "DASHBOARD"
  },
  {
    page: null,
    title: "Business Development",
    submenu: [
      {
        page: "businessDevelopment/clientProject",
        title: "Client Project",
        submenu: null,
        authority: "CLIENTPROJECT"
      },
      {
        page: "businessDevelopment/customerLog",
        title: "Customer Log",
        submenu: null,
        authority: "CUSTOMER_LOG"
      }
    ]
  },
  {
    page: null,
    title: "İBB",
    submenu: [
      {
        page: "ibb/projectLog",
        title: "Project Log",
        submenu: null,
        authority: "PROJECT_LOG"
      },
      {
        page: "ibb/proposedProjectLog/ProposedProjectLog",
        title: "Proposed Project Log",
        submenu: null,
        authority: "PROPOSED_PROJECT_LOG"
      },
      {
        page: "ibb/tenderPreparation/design-log",
        title: "Tender Design Log",
        submenu: null,
        authority: "TENDER_DESIGN_LOG"
      },
      {
        page: "ibb/gap-analysis",
        title: "Gap Analysis",
        submenu: null,
        authority: "GAP_ANALYSIS"
      },
      {
        page: "ibb/project-identifications",
        title: "Project Identifications",
        submenu: null,
        authority: "PROJECT_IDENTIFICATIONS"
      },
      {
        page: "ibb/project-summary",
        title: "Project Summary",
        submenu: null,
        authority: "PROJECT_SUMMARY"
      }
    ]
  },
  {
    page: null,
    title: "Company",
    submenu: [
      {
        page: "company/CompanySettings",
        title: "Company Settings",
        submenu: null,
        authority: "COMPANY_SETTINGS"
      },
      {
        page: "company/PerformanceCriterias",
        title: "Performance Criterias",
        submenu: null,
        authority: "PERFORMANCE_CRITERIAS"
      }
    ]
  },
  {
    page: null,
    title: "Company Setting",
    submenu: [
      {
        page: "administrator/dashboard-charts",
        title: "Dashboard Charts",
        submenu: null,
        authority: "DASHBOARD_CHARTS"
      },
      {
        page: "planning/workflow",
        title: "Notification Workflow",
        submenu: null,
        authority: "PLANNING_WORKFLOW"
      },
      {
        page: "planning/pageapproval",
        title: "Page Approval",
        submenu: null,
        authority: "PAGE_APPROVAL"
      },
      {
        page: "planning/procurementprogress",
        title: "Procurement Progress",
        submenu: null,
        authority: "PAGE_PROCUREMENTPROGRESS"
      },
      {
        page: "planning/todo",
        title: "To Do List",
        submenu: null,
        authority: "TODO_LIST"
      },

      {
        page: "administrator/user-dashboard-charts",
        title: "User Dashboard Charts",
        submenu: null,
        authority: "USER_DASHBOARD_CHARTS"
      },
      {
        page: "companySetting/personnel-log",
        title: "Company Personnel Log",
        submenu: null,
        authority: "COMPANY_PERSONNNEL_LOG"
      }
    ]
  },
  {
    page: null,
    title: "Engineering",
    submenu: [
      {
        page: "engineering/activity-log",
        title: "Company Activity Log",
        submenu: null,
        authority: "COMPANY_ACTIVITY_LOG"
      },

      {
        page: "engineering/company-project",
        title: "Company Project",
        submenu: null,
        authority: "COMPANY_PROJECT"
      },

      {
        page: "drawing",
        title: "Drawing",
        submenu: null,
        authority: "DRAWING"
      },
      {
        page: "store/item-breakdown",
        title: "Item Breakdown",
        submenu: null,
        authority: "ITEM_BREAKDOWN"
      },
      {
        page: "warningPages/2En",
        title: "Metod of Statement",
        submenu: null,
        authority: "METOD_OF_STATEMENT"
      },
      {
        page: "project-take-off-list",
        title: "Project Items Take Off List",
        submenu: null,
        authority: "PROJECT_ITEMS_TAKE_OFF_LIST"
      },
      {
        page: "planning/project-tree-company",
        title: "Projects Tree",
        submenu: null,
        authority: "PROJECTS_TREE"
      },
      {
        page: "engineering/roomQuantity",
        title: "Room Quantity",
        submenu: null,
        authority: "ROOM_QUANTITY"
      },
      {
        page: "warningPages/3En",
        title: "Technical Querry",
        submenu: null,
        authority: "TECHNICAL_QUERRY"
      }
    ]
  },
  {
    page: null,
    title: "Documentation",
    submenu: [
      {
        page: "documentation/auditLog",
        title: "AuditLog",
        submenu: null,
        authority: "AUDIT_LOG"
      },
      {
        page: "documentation/AutoReport",
        title: "Auto Report",
        submenu: null,
        authority: "AUTO_REPORT"
      },
      {
        page: "documentation/BookCreator",
        title: "Book Creator",
        submenu: null,
        authority: "BOOK_CREATOR"
      },
      {
        page: "documentation/ContractLog",
        title: "Contract Log",
        submenu: null,
        authority: "CONTRACT_LOG"
      },
      {
        page: "documentation/document-control",
        title: "Document Control",
        submenu: null,
        authority: "DOCUMENT_CONTROL"
      },
      {
        page: "documentation/document-log",
        title: "Document Log",
        submenu: null,
        authority: "DOCUMENT_LOG"
      },
      {
        page: "documentation/document-type",
        title: "Document Type",
        submenu: null,
        authority: "DOCUMENT_TYPE"
      },
      {
        page: "filling",
        title: "Filing",
        submenu: null,
        authority: "FILING"
      },
      {
        page: "documentation/flowchart",
        title: "FlowChart",
        submenu: null,
        authority: "DOCUMENTATION_FLOWCHART"
      },

      {
        page: "documentation/meeting",
        title: "Meeting",
        submenu: null,
        authority: "DOCUMENTATION_MEETING"
      },

      {
        page: "documentation/presentation",
        title: "Presentation",
        submenu: null,
        authority: "PRESENTATION"
      },
      {
        page: "documentation/text-formatting",
        title: "Text Formatting",
        submenu: null,
        authority: "DOCUMENTATION_TEXTFORMATTING"
      },
      {
        page: "documentation/text-to-para",
        title: "Text To Para",
        submenu: null,
        authority: "TEXT_TO_PARA"
      },
      {
        page: "transmittal",
        title: "Transmittal Log",
        submenu: null,
        authority: "TRANSMITTAL_LOG"
      },

      {
        page: "warningPages/4D",
        title: "Violation Log",
        submenu: null,
        authority: "VIOLATION_LOG"
      },
      {
        page: "warningPages/1D",
        title: "Questionnaire",
        submenu: null,
        authority: "QUESTIONNAIRE"
      }
    ]
  },
  {
    page: null,
    title: "Equipment",
    submenu: [
      {
        page: "equipment/breakdown",
        title: "Breakdown",
        submenu: null,
        authority: "BREAKDOWN"
      },
      {
        page: "equipment/equipment-log-main",
        title: "Equipment Log",
        submenu: null,
        authority: "EQUIPMENT_LOG_MAIN"
      },
      {
        page: "equipment/fuel-refill",
        title: "Fuel Refill",
        submenu: null,
        authority: "FUEL_REFILL"
      },

      {
        page: "equipment/insurance",
        title: "Insurance",
        submenu: null,
        authority: "INSURANCE"
      },
      {
        page: "equipment/parking-log",
        title: "Parking",
        submenu: null,
        authority: "PARKING_LOG"
      }
    ]
  },
  {
    title: "Finance",
    submenu: [
      {
        page: "finance/agreement-log",
        title: "Agreement Log",
        submenu: null,
        authority: "AGREEMENT_LOG"
      },
      {
        page: null,
        title: "Budget Pages",
        submenu: [
          {
            page: "finance/budget",
            title: "Budget",
            submenu: null,
            authority: "BUDGET"
          },
          {
            page: null,
            title: "Budget Human Resources",
            submenu: [
              {
                page: "finance/budget-personnel",
                title: "Budget Personnel",
                submenu: null,
                authority: "BUDGET_MAINTENANCE"
              }
            ]
          },
          {
            page: null,
            title: "Budget Maintenance",
            submenu: [
              {
                page: "finance/budget-maintenance",
                title: "Budget Maintenance Detail",
                submenu: null,
                authority: "BUDGET_MAINTENANCE_DETAIL"
              }
            ]
          }
        ]
      },
      {
        page: "finance/bank-accounts-log",
        title: "Bank Accounts Log",
        submenu: null,
        authority: "BANK_ACCOUNTS_LOG"
      },
      {
        page: "finance/cashflow",
        title: "Cash Flow",
        submenu: null,
        authority: "CASHFLOW"
      },
      {
        page: "finance/cashes",
        title: "Cashes",
        submenu: null,
        authority: "CASHES"
      },
      {
        page: "finance/cheque-log",
        title: "Cheque Log",
        submenu: null,
        authority: "CHEQUE_lOG"
      },
      //store alındı
      {
        page: "store/expense",
        title: "Expenses Log",
        submenu: null,
        authority: "EXPENSESLOG"
      },
      {
        page: "finance/incomelog",
        title: "Income Log",
        submenu: null,
        authority: "INCOME_LOG"
      },
      {
        page: "finance/incomeplan",
        title: "Income Plan",
        submenu: null,
        authority: "INCOME_PLAN"
      },
      {
        page: "finance/notelog",
        title: "Note Log",
        submenu: null,
        authority: "NOTE_LOG"
      },
      {
        page: "finance/paymentlog",
        title: "Payment Log",
        submenu: null,
        authority: "PAYMENT_LOG"
      },
      {
        page: "finance/paymentplan",
        title: "Payment Plan",
        submenu: null,
        authority: "PAYMENT_PLAN"
      },
      {
        page: "finance/personnel-salary-log",
        title: "Personnel Salary Log",
        submenu: null,
        authority: "PERSONNEL_SALARY_LOG"
      },
      {
        page: "finance/salary-settings",
        title: "Salary Settings",
        submenu: null,
        authority: "SALARY_SETTINGS"
      },
      {
        page: "finance/software",
        title: "Software Log",
        submenu: null,
        authority: "SOFTWARE_LOG"
      }
    ]
  },
  {
    page: null,
    title: "Human Resources",
    submenu: [
      {
        page: null,
        title: "Health",
        submenu: [
          {
            page: "humanresources/clinic",
            title: "Clinic",
            submenu: null,
            authority: "CLINIC"
          }
        ]
      },
      {
        page: null,
        title: "Hiring",
        submenu: [
          {
            page: "humanresources/candidatePortal",
            title: "Candidate Portal",
            submenu: null,
            authority: "Candidate_Portal"
          },
          {
            page: "humanresources/employee-request",
            title: "Employee Request",
            submenu: null,
            authority: "EMPLOYEEREQUEST"
          },
          {
            page: "warningPages/1H",
            title: "Hiring Menu",
            submenu: null,
            authority: "HIRING"
          },

          {
            page: "humanresources/exit",
            title: "Human Resource Exit",
            submenu: null,
            authority: "HUMAN_RESOURCE_EXIT"
          },
          {
            page: "humanresources/jobOffer",
            title: "Job Offer",
            submenu: null,
            authority: "JOB_OFFER"
          }
        ]
      },
      {
        page: null,
        title: "Organization Structures",
        submenu: [
          {
            page: "department",
            title: "Department",
            submenu: null,
            authority: "Department"
          },
          {
            page: "designation",
            title: "Designation",
            submenu: null,
            authority: "DESIGNATION"
          },

          {
            page: "discipline",
            title: "Discipline",
            submenu: null,
            authority: "DISCIPLINE"
          },
          {
            page: "humanresources/organizationalcategory",
            title: "Organizational Category",
            submenu: null,
            authority: "Organizational_Category"
          },
          {
            page: "managementLevel",
            title: "Management Level",
            submenu: null,
            authority: "MANAGEMENT_LEVEL"
          },
          {
            page: "organizationalLevel",
            title: "Organizational Level",
            submenu: null,
            authority: "Organizational_Level"
          },

          {
            page: "elden/role-description",
            title: "Role Description",
            submenu: null,
            authority: "ELDEN_ROLE_DESCRIPTION"
          },
          {
            page: "roleGroup",
            title: "Role Group",
            submenu: null,
            authority: "Role Group"
          }
        ]
      },
      {
        page: null,
        title: "Personnel",
        submenu: [
          {
            page: "humanresources/assignment-personnel",
            title: "Assignment",
            submenu: null,
            authority: "ASSIGMENT"
          },
          {
            page: "warningPages/3H",
            title: "Attandance",
            submenu: null,
            authority: "ATTANDANCE"
          },

          {
            page: "finance/employees-salary",
            title: "Employees Salary",
            submenu: null,
            authority: "EMPLOYEES_SALARY"
          },
          {
            page: "humanresources/leave-request",
            title: "Leave Request",
            submenu: null,
            authority: "LEAVE_REQUEST"
          },
          {
            page: "humanresources/organizational-chart",
            title: "Organizational Chart",
            submenu: null,
            authority: "ORGANIZATIONALCHART"
          },
          {
            page: "warningPages/2H",
            title: "Overtime",
            submenu: null,
            authority: "OVERTIME"
          },
          {
            page: "humanresources/OvertimeRequest",
            title: "Overtime Request",
            submenu: null,
            authority: "OVERTIME_REQUEST"
          },
          {
            page: "personnellog",
            title: "Personnel Log",
            submenu: null,
            authority: "PERSONNEL_LOG"
          },
          {
            page: "humanresources/timesheet",
            title: "Timesheet",
            submenu: null,
            authority: "TIMESHEET"
          }
        ]
      },
      {
        page: null,
        title: "Policies",
        submenu: [
          {
            page: "humanresources/employee-contract-settings",
            title: "Employee Contract Settings",
            submenu: null,
            authority: "EMPLOYEE_CONTRACT_SETTINGS"
          },
          {
            page: "holiday-log",
            title: "Holiday Log",
            submenu: null,
            authority: "HOLIDAYLOG"
          },
          {
            page: "insurance-policy",
            title: "Insurance Policy",
            submenu: null,
            authority: "INSURANCEPOLICY"
          },
          {
            page: "leave-policy",
            title: "Leave Policy",
            submenu: null,
            authority: "LEAVEPOLICY"
          },
          {
            page: "leave-policy-group",
            title: "Leave Policy Group",
            submenu: null,
            authority: "LEAVEPOLICYGROUP"
          },

          {
            page: "humanresources/shifts",
            title: "Shifts",
            submenu: null,
            authority: "SHIFTS"
          },
          {
            page: "humanresources/timesheet-workflow",
            title: "Timesheet Workflow",
            submenu: null,
            authority: "TIMESHEETWORKFLOW"
          }
        ]
      },
      {
        page: null,
        title: "Training",
        submenu: [
          {
            page: "humanresources/training-schedule",
            title: "Training Schedule",
            submenu: null,
            authority: "TRAINING_SCHEDULE"
          }
        ]
      }
    ]
  },
  {
    page: null,
    title: "Logistics",
    submenu: [
      {
        page: "logistics/Regions",
        title: "Regions",
        submenu: null,
        authority: "REGIONS"
      },
      {
        page: "logistics/DriverCategory",
        title: "Driver Category",
        submenu: null,
        authority: "DRIVER_CATEGORY"
      },
      {
        page: "logistics/SlopeRanges",
        title: "Slope Ranges",
        submenu: null,
        authority: "SLOPE_RANGES"
      },
      {
        page: "logistics/CurveRanges",
        title: "Curve Ranges",
        submenu: null,
        authority: "CURVE_RANGES"
      },
      {
        page: "logistics/BusStops",
        title: "Bus Stops",
        submenu: null,
        authority: "BUS_STOPS"
      },
      {
        page: "logistics/Route",
        title: "Route",
        submenu: null,
        authority: "ROUTE"
      },
      {
        page: "logistics/Buses",
        title: "Buses",
        submenu: null,
        authority: "BUSES"
      },
      {
        page: "logistics/Drivers",
        title: "Drivers",
        submenu: null,
        authority: "DRIVERS"
      },
      {
        page: "warningPages/2L",
        title: "Loading",
        submenu: null,
        authority: "LOADING"
      },
      {
        page: "warningPages/3L",
        title: "Unloading",
        submenu: null,
        authority: "UNLOADING"
      },
      {
        page: "warningPages/1L",
        title: "Shipment",
        submenu: null,
        authority: "SHIPMENT"
      },
      {
        page: "logistics/route",
        title: "Route",
        submenu: null,
        authority: "ROUTE"
      },
      {
        page: "logistics/route-schedule",
        title: "Route Schedule",
        submenu: null,
        authority: "ROUTE_SCHEDULE"
      }
    ]
  },
  {
    page: null,
    title: "Maintenance",
    submenu: [
      {
        page: "maintenance/maintenance-avarage-production-time",
        title: "Maintenance Avarage Production Time",
        submenu: null,
        authority: "MAINTENANCE_AVARAGE_PRODUCTION_TIME"
      },
      {
        page: "maintenance/maintenance-checklist",
        title: "Maintenance Checklist",
        submenu: null,
        authority: "MAINTENANCE_CHECKLIST"
      },
      {
        page: "equipment/maintenance-log",
        title: "Maintenance Log",
        submenu: null,
        authority: "MAINTENANCE_LOG"
      },
      {
        page: "maintenance/maintenance-control-plan",
        title: "Maintenance Plan",
        submenu: null,
        authority: "MAINTENANCE_CONTROL_PLAN"
      },
      {
        page: "maintenance/maintenance-planning",
        title: "Maintenance Planning",
        submenu: null,
        authority: "Maintenance_Planning"
      },
      {
        page: "maintenance/maintenance-timeline",
        title: "Maintenance Timeline",
        submenu: null,
        authority: "MAINTENANCE_TIMELINE"
      },
      {
        page: "maintenance/maintenance-work-order",
        title: "Maintenance Work Order",
        submenu: null,
        authority: "MAINTENANCE_WORK_ORDER"
      },
      {
        page: "maintenance/maintenance-work-station",
        title: "Maintenance Work Station",
        submenu: null,
        authority: "MAINTENANCE_WORK_STATION"
      },
      {
        page: "maintenance/work-shops",
        title: "Work Shops",
        submenu: null,
        authority: "Work_Shops"
      },
      {
        page: "maintenance/schedule",
        title: "Maintenance Schedule",
        submenu: null,
        authority: "MAINTENANCE_SCHEDULE"
      },
      {
        page: "maintenance/maintenance-plan-log",
        title: "Maintenance Plan Log",
        submenu: null,
        authority: "MAINTENANCE_PLAN_LOG"
      },
      {
        page: "maintenance/surrender-of-vehicle-log",
        title: "Surrender of Vehicle Log",
        submenu: null,
        authority: "SURRENDER_OF_VEHICLE_LOG"
      },
      {
        page: "maintenance/daily-check",
        title: "Daily Check",
        submenu: null,
        authority: "DAILY_CHECK"
      }
    ]
  },
  {
    page: null,
    title: "Others",
    submenu: [
      {
        /*
        {
        page: "sector",
        title: "Sectors",
        submenu: null,
        authority: "SECTOR"
      },
      {
        page: "mrchecklist",
        title: "MRChecklist",
        submenu: null,
        authority: "MRCHECKLIST"
      },

      {
        page: "1Pro",
        title: "Item Request",
        submenu: null,
        authority: "ITEM_REQUEST"
      },
      {
        page: "procurement/purchase-requisition",
        title: "Item Specifications",
        submenu: null,
        authority: "PURCHASE_REQUISITION"
      },
      {
        page: "procurement/inspection-assignment-package",
        title: "Inspection Assignment Package",
        submenu: null,
        authority: "INSPECTION_ASSIGNMENT_PACKAGE"
      },
      {
        page: "procurement/inspection-notification",
        title: "Inspection Notification",
        submenu: null,
        authority: "INSPECTION_NOTIFICATION"
      },
      {
        page: "procurement/inspection-result-notification",
        title: "Inspection Result Notification",
        submenu: null,
        authority: "INSPECTION_RESULT_NOTIFICATION"
      },
      {
        page: "warningPages/2Pro",
        title: "No Objection",
        submenu: null,
        authority: "NO_OBJECTİON"
      },

      {
        page: "procurement/price-delivery",
        title: "Price Delivery",
        submenu: null,
        authority: "PRICE_DELIVERY"
      },
      {
        page: "warningPages/3Pro",
        title: "NMR",
        submenu: null,
        authority: "NMR"
      },
      {
        page: "warningPages/1stock",
        title: "Item Properties",
        submenu: null,
        authority: "ITEM_PROPERTIES"
      },
      {
        page: "elden/itemdescription",
        title: "Item Description",
        submenu: null,
        authority: "ITEM_DESCRIPTION"
      },
      {
        page: "property-group",
        title: "Property Group",
        submenu: null,
        authority: "PROPERTY_GROUP"
      },
      {
        page: "property",
        title: "Properties",
        submenu: null,
        authority: "PROPERTY"
      },
      {
        page: "procurement/gap-analysis",
        title: "Gap Analysis",
        submenu: null,
        authority: "GAP_ANALYSIS"
      },
      {
        page: "procurement/vendor-selection",
        title: "Vendor Selection On Total",
        submenu: null,
        authority: "VENDOR_SELECTION_ON_TOTAL"
      }
        */
      }
    ]
  },
  {
    page: null,
    title: "Quality",
    submenu: [
      {
        page: "checklist",
        title: "Checklist",
        submenu: null,
        authority: "CHECKLIST"
      },
      {
        page: "quality/controlPlan",
        title: "Control Plan",
        submenu: null,
        authority: "CONTROL_PLAN"
      },

      {
        page: "inspectionlog",
        title: "Inspection Log",
        submenu: null,
        authority: "INSPECTION_LOG"
      },

      {
        page: "inspectionlog-item",
        title: "Inspection Log Item",
        submenu: null,
        authority: "INSPECTION_LOG_ITEM"
      },
      {
        page: "quality/Sampling",
        title: "Sampling",
        submenu: null,
        authority: "SAMPLING"
      },
      {
        page: "quality/qualityChecklist",
        title: "Quality Checklist",
        submenu: null,
        authority: "CHECK_LIST"
      },

      {
        /*
        {
        page: "saiclog",
        title: "Saic Log",
        submenu: null,
        authority: "SAIC_LOG"
      },

        */
      }
    ]
  },
  {
    page: null,
    title: "Planning",
    submenu: [
      {
        page: "planning/apartment",
        title: "Apartments Details ",
        submenu: null,
        authority: "APARTMENT"
      },

      {
        page: "planning/appGant",
        title: "AppGant",
        submenu: null,
        authority: "APPGANT"
      },

      {
        page: "planning/rawMaterialMonitoring",
        title: "Raw Material Monitoring",
        submenu: null,
        authority: "RAW_MATERIAL_MONITORING"
      },

      {
        page: null,
        title: "Manufacturing",
        submenu: [
          {
            page: "planning/manufacturing/avarage-production-time",
            title: "Avarage Production Time",
            submenu: null,
            authority: "Avarage_Production_Time"
          },
          {
            page: "planning/manufacturing-planning",
            title: "Manufacturing Planning",
            submenu: null,
            authority: "Manufacturing_Planning"
          },
          {
            page: "planning/timeline",
            title: "Work Order",
            submenu: null,
            authority: "Manufacturing_Work_Order"
          }
        ]
      },

      {
        /*
        {
        page: "planning/projectPlanning",
        title: "Project Planning",
        submenu: null,
        authority: "PROJECT_PLANNING"
      },
      {
        page: "planning/plan",
        title: "Planning Tree",
        submenu: null,
        authority: "PLANNING_PLAN"
      },
      {
        page: "planning/mainProjects",
        title: "Main Projects",
        submenu: null,
        authority: "MAIN_PROJECTS"
      },
        {
        page: "warningPages/1P",
        title: "Gantt Chart",
        submenu: null,
        authority: "GANTT_CHART"
      },
      {
        page: "elden/planning/services",
        title: "Services",
        submenu: null,
        authority: "SERVICES"
      },
      {
        page: "elden/activity-planning",
        title: "Activity Planning",
        submenu: null,
        authority: "ACTIVITY-PLANNING"
      }*/
      }
    ]
  },
  {
    page: null,
    title: "Procurement",
    submenu: [
      {
        page: "procurement/quotation",
        title: "Quotation",
        submenu: null,
        authority: "QUOTATION"
      },
      {
        page: "procurement/procurementLog",
        title: "Procurement Log",
        submenu: null,
        authority: "PROCUREMENT_LOG"
      },
      {
        page: "procurement/purchase-authorization",
        title: "Purchase Authorization",
        submenu: null,
        authority: "PURCHASE_AUTHORIZATION"
      },
      {
        page: "procurement/purchaseRequisitionNew",
        title: "Purchase Requisition New",
        submenu: null,
        authority: "PURCHASE_REQUISITION_NEW"
      },

      {
        page: "procurement/purchase-order",
        title: "Purchase Order",
        submenu: null,
        authority: "PURCHASE_ORDER"
      },
      {
        page: "procurement/request-for-quotation",
        title: "Request For Quotation",
        submenu: null,
        authority: "REQUEST_FOR_QUOTATION"
      },
      {
        page: "procurement/purchase-internal-request",
        title: "Request For Purchase",
        submenu: null,
        authority: "PURCHASE_INTERNAL_REQUEST"
      },
      {
        page: "procurement/shipping-release",
        title: "Shipping Release",
        submenu: null,
        authority: "SHIPPING_RELEASE"
      },
      {
        page: "procurement/stock-control",
        title: "Stock Control",
        submenu: null,
        authority: "STOCK_CONTROL"
      },
      {
        page: "procurement/stock-control-approval",
        title: "Stock Control Approval",
        submenu: null,
        authority: "STOCK_CONTROL_APPROVAL"
      },

      {
        page: "store/VendorLogEvaluation",
        title: "Vendor Evaluation",
        submenu: null,
        authority: "VENDOR_LOG_EVALUATION"
      },
      {
        page: "store/vendorLogPerformance",
        title: "Vendor Performance",
        submenu: null,
        authority: "VENDOR_LOG_PERFORMANCE"
      },
      {
        page: "procurement/procurement-requirements",
        title: "Vendor Portal",
        submenu: null,
        authority: "REQUEST_FOR_QUOTATION"
      },
      {
        page: "procurement/part-requirement-list",
        title: "Part Requirement List",
        submenu: null,
        authority: "PART_REQUIREMENT_LIST"
      },
      {
        page: "procurement/vendor-scoring",
        title: "Vendor Scoring",
        submenu: null,
        authority: "VENDOR_SCORING"
      }
    ]
  },
  {
    page: null,
    title: "Rota",
    submenu: [
      {
        page: "rota/accommodation",
        title: "Accommodation",
        submenu: null,
        authority: "ACCOMMODATION"
      },
      {
        page: "rota/job-schedule",
        title: "Job Schedule",
        submenu: null,
        authority: "JOB_SCHEDULE"
      },
      {
        page: "rota/job-title",
        title: "Job Title",
        submenu: null,
        authority: "JOB_TITLE"
      },
      {
        page: "rota/patient-care-responsibility",
        title: "Patient Care Responsibility",
        submenu: null,
        authority: "PATIENT_CARE_RESPONSIBILITY"
      },
      {
        page: "rota/rota-personnel-log",
        title: "Rota Personnel Log",
        submenu: null,
        authority: "ROTA_PERSONNEL_LOG"
      },
      {
        page: "rota/rota-rules",
        title: "Rota Rules",
        submenu: null,
        authority: "ROTA_RULES"
      }
    ]
  },
  {
    page: null,
    title: "Sales",
    submenu: [
      {
        page: "store/customeraccount",
        title: "Customer Account Log",
        submenu: null,
        authority: "CUSTOMERACCOUNT"
      },
      {
        page: "invoice",
        title: "Invoice Log",
        submenu: null,
        authority: "INVOICE"
      },
      {
        page: "sales/price-list",
        title: "Price List",
        submenu: null,
        authority: "PRICELIST"
      },
      {
        page: "sales/product-log",
        title: "Product Log",
        submenu: null,
        authority: "PRODUCTLOG"
      },

      {
        page: "store/sales-report",
        title: "Sales Reports",
        submenu: null,
        authority: "SALESREPORTS"
      },
      {
        page: "store/salesstatistics",
        title: "Sales Statistics",
        submenu: null,
        authority: "SALESSTATISTICS"
      },
      {
        page: "sales/salesmanOrders",
        title: "Salesman Orders",
        submenu: null,
        authority: "SALESMAN_ORDERS"
      },
      {
        /*
        page: "sales/salesmanAccount",
        title: "Salesman Account",
        submenu: null,
        authority: "SALESMAN_ACCOUNT"
      },*/
      },
      {
        page: "store/salesman-cash",
        title: "Salesman Cash Log",
        submenu: null,
        authority: "SALESMANCASHFLOW"
      },

      {
        page: "sales/quotationlog",
        title: "Quotation Log",
        submenu: null,
        authority: "QUOTATIONLOG"
      },
      {
        page: "sales/salesman-order",
        title: "Salesman Order",
        submenu: null,
        authority: "SALESMAN_ORDER"
      },

      {
        /*

      {
        page: "warningPages/1SA",
        title: "Customer  Log",
        submenu: null,
        authority: "CUSTOMER_LOG"
      },
      {
        page: "warningPages/2SA",
        title: "Quotations",
        submenu: null,
        authority: "QUOTATIONS"
      },
      {
        page: "warningPages/3SA",
        title: "Agreement",
        submenu: null,
        authority: "AGREEMENT"
      }
       */
      }
    ]
  },
  {
    page: null,
    title: "Stock",
    submenu: [
      {
        page: "store/stock-card-equivalent",
        title: "Equivalent Item List",
        submenu: null,
        authority: "STOCK_CARD_EQUIVALENT"
      },
      {
        page: "Inventory",
        title: "Inventory Log",
        submenu: null,
        authority: "INVENTORY"
      },

      {
        page: "store/stock-card",
        title: "Stock Card",
        submenu: null,
        authority: "STOCK_CARD"
      },
      {
        page: "store/stockMovements",
        title: "Stock Movements",
        submenu: null,
        authority: "STOCK_MOVEMENTS"
      },
      {
        page: "Stock",
        title: "Stocks",
        submenu: null,
        authority: "STOCK"
      },

      {
        page: "stock/stocksTreeMain",
        title: "Stocks Tree Main",
        submenu: null,
        authority: "STOCKS_TREE_MAIN"
      }
    ]
  },
  {
    page: null,
    title: "Store",
    submenu: [
      {
        page: "store/discount-policy",
        title: "Discount Policy",
        submenu: null,
        authority: "DISCOUNTPOLICY"
      },
      {
        page: "inventorylog",
        title: "Inventory Log New",
        submenu: null,
        authority: "INVENTORYLOG"
      },

      {
        page: "store/product",
        title: "Products",
        submenu: null,
        authority: "PRODUCT"
      },
      {
        page: "store/productmovement",
        title: "Product Release/Receive",
        submenu: null,
        authority: "PRODUCTMOVEMENT"
      },
      {
        page: "vendor",
        title: "Vendor Log",
        submenu: null,
        authority: "VENDOR_LOG"
      },
      {
        page: "store/stock-levels-log",
        title: "Stock Levels Log",
        submenu: null,
        authority: "STOCK_LEVELS_LOG"
      }
    ]
  },
  {
    page: null,
    title: "Tendering",
    submenu: [
      {
        page: "tendering/bidding-Log",
        title: "Bidding Log",
        submenu: null,
        authority: "BIDDING_LOG"
      },
      {
        page: "tendering/bidding-log-fittings",
        title: "Bidding Log Fittings",
        submenu: null,
        authority: "BIDDING_LOG_FITTINGS"
      },
      {
        page: "tendering/bidding-log-isolation",
        title: "Bidding Log Isolation",
        submenu: null,
        authority: "BIDDING_LOG_ISOLATION"
      },
      {
        page: "tendering/bidding-log-labor-ship",
        title: "Bidding Log Laborship",
        submenu: null,
        authority: "BIDDING_LOG_LABOR_SHIP"
      },

      {
        page: "contract-documents",
        title: "Contract Document",
        submenu: null,
        authority: "CONTRACT_DOCUMENTS"
      },
      {
        page: "tendering/cost-comparision-bidding-actual",
        title: "Cost Comparision Bidding and Actual",
        submenu: null,
        authority: "COST_COMPARISION_BIDDING_ACTUAL"
      },

      {
        page: "tendering/service-stock-card-log",
        title: "Services Stock Card Log",
        submenu: null,
        authority: "SERVICES_STOCK_CARD_LOG"
      },
      {
        page: "tendering/service-stock-card-log",
        title: "Services Stock Card Log",
        submenu: null,
        authority: "SERVICES_STOCK_CARD_LOG"
      },
      {
        page: "tendering/tendering-Log",
        title: "Tendering Log",
        submenu: null,
        authority: "TENDERING_LOG"
      }
    ]
  },
  {
    page: null,
    title: "QR Code",
    submenu: [
      {
        page: "client-administration/tickets",
        title: "Tickets",
        submenu: null,
        authority: "CLIENT_TICKETS"
      },
      {
        page: "client-administration/timeline-qrcode-generator",
        title: "Timeline QRCode Generator",
        submenu: null,
        authority: "TIMELINE_QRCODE_GENERATOR"
      },
      {
        page: "client-administration/timeline-qrcode-scanner",
        title: "Timeline QRCode Scanner",
        submenu: null,
        authority: "TIMELINE_QRCODE_SCANNER"
      },
      {
        page: "client-administration/timeline-qrcode-settings",
        title: "Timeline QRCode Settings",
        submenu: null,
        authority: "TIMELINE_QRCODE_SETTINGS"
      }
    ]
  },
  {
    page: null,
    title: "Administration",
    submenu: [
      {
        page: null,
        title: "Elden",
        submenu: [
          {
            page: null,
            title: "Elden İBB",
            submenu: [
              {
                page: "ibb/elden/gap-analysis",
                title: "Elden Gap Analysis",
                submenu: null,
                authority: "ELDEN_GAP_ANALYSIS"
              }
            ]
          },
          {
            page: null,
            title: "Accounting",
            submenu: [
              {
                page: "elden/accounting/accountingActivityCodes",
                title: "Accounting Activity Codes",
                submenu: null,
                authority: "ACCOUNTING_ACTIVITY_CODES"
              },
              {
                page: "elden/accounting/AccountingLegalObligations",
                title: "Accounting Legal Obligations",
                submenu: null,
                authority: "ACCOUNTING_LEGAL_OBLIGATIONS"
              },
              {
                page: "elden/accounting/govermentAgency",
                title: "Goverment Agency",
                submenu: null,
                authority: "GOVERMENT_AGENCY"
              },

              {
                page: "elden/accounting/incomeOutgoingGroups",
                title: "Income Outgoing Groups",
                submenu: null,
                authority: "INCOME_OUTGOING_GROUPS"
              },
              {
                page: "elden/accounting/MuhtasarKodlar",
                title: "Muhtasar Kodlar",
                submenu: null,
                authority: "MUHTASAR_KODLAR"
              },
              {
                page: "elden/accounting/NaceCodes",
                title: "Nace Codes",
                submenu: null,
                authority: "NACE_CODES"
              },
              {
                page: "warningPages/2A",
                title: "Periodic Reports",
                submenu: null,
                authority: "PERIODIC_REPORT"
              },
              {
                page: "elden/accounting/salary",
                title: "Salary",
                submenu: null,
                authority: "ELDEN_ACCOUNTING_SALARY"
              },
              {
                page: "elden/accounting/Tax",
                title: "Tax",
                submenu: null,
                authority: "TAX"
              },
              {
                page: "elden/accounting/tax-codes",
                title: "Tax Codes",
                submenu: null,
                authority: "TAX_CODES"
              },
              {
                page: "elden/accounting/TaxDescriptions",
                title: "Tax Descriptions",
                submenu: null,
                authority: "TAX_DESCRIPTIONS"
              },
              {
                page: "elden/accounting/taxPayers",
                title: "Tax Payers",
                submenu: null,
                authority: "TAX_PAYERS"
              },

              {
                page: "elden/accounting/TaxSchedule",
                title: "Tax Schedule",
                submenu: null,
                authority: "TAX_SCHEDULE"
              },
              {
                page: "elden/accounting/tax-group",
                title: "Taxes and Tax Rates Log",
                submenu: null,
                authority: "TAXEX_AND_TAX_RATES_LOG"
              },

              {
                page: "elden/accounting/TheUniformChartofAccounting",
                title: "The Uniform Chart of Accounting",
                submenu: null,
                authority: "THE_UNIFORM_CHART_OF_ACCOUNTING"
              }
            ]
          },
          {
            page: null,
            title: "Clients",
            submenu: [
              {
                page: null,
                title: "Saudi Aramco",
                submenu: [
                  {
                    page: "com-listing",
                    title: "9Com Listing",
                    submenu: null,
                    authority: "COMLISTING9"
                  },
                  {
                    page: null,
                    title: "Cevre Bakanligi",
                    submenu: []
                  },
                  {
                    page: "itp-discipline",
                    title: "ITP Discipline",
                    submenu: null,
                    authority: "itp-discipline"
                  },

                  {
                    page: "sa-engineering-procedures",
                    title: "SA Engineering Procedures",
                    submenu: null,
                    authority: "SAENGINEERINGPROCEDURES"
                  },
                  {
                    page: "sa-engineering-standards",
                    title: "SA Engineering Standards",
                    submenu: null,
                    authority: "SAENGINEERINGSTANDARDS"
                  },
                  {
                    page: "sa-materials-system-specifications",
                    title: "SA Materials System Specifications",
                    submenu: null,
                    authority: "SAMATERIALSSYSTEMSPECIFICATIONS"
                  },
                  {
                    page: "satiplog",
                    title: "Satip Log",
                    submenu: null,
                    authority: "SATIP_LOG"
                  },

                  {
                    page: "user-auth-itpdiscipline",
                    title: "User ITP-Discipline Auth",
                    submenu: null,
                    authority: "user-auth-itpdiscipline"
                  }
                ]
              }
            ]
          },

          {
            /*
          {
            page: null,
            title: "Misc",
            //Elden Misc
            submenu: [
              {
                page: "itplog",
                title: "ITP Log",
                submenu: null,
                authority: "ITP_LOG"
              },
              {
                page: "inspection-test-report",
                title: "Inspection Test Reports",
                submenu: null,
                authority: "INSPECTION_TEST_REPORT_LOG"
              },
              {
                page: "inspection-test-reports-report",
                title: "Test Reports Report",
                submenu: null,
                authority: "INSPECTION_TEST_REPORT_LOG_REPORT"
              }
            ]
          },*/
          },

          {
            page: null,
            title: "Elden Engineering",
            submenu: [
              {
                page: "elden/planning/activity-log",
                title: "Elden Activity Log",
                submenu: null,
                authority: "ELDEN_ACTIVITY_LOG"
              },
              {
                page: "elden/engineering/elden-compounds",
                title: "Elden Compounds",
                submenu: null,
                authority: "ELDEN_COMPOUNDS"
              },
              {
                page: "elden/engineering/elden-method-of-statement",
                title: "Elden Method Of Statement",
                submenu: null,
                authority: "ELDEN_METHOD_OF_STATEMENT"
              },
              {
                page: "elden/engineering/elden-services",
                title: "Elden Services",
                submenu: null,
                authority: "ELDEN_SERVICES"
              },
              {
                page: "elden/engineering/room-application",
                title: "Room Application",
                submenu: null,
                authority: "ROOM_APPLICATION"
              },
              {
                page: "elden/engineering/activity-phase",
                title: "Elden Activity Phase",
                submenu: null,
                authority: "ELDEN_ACTIVITY_PHASE"
              },

              {
                /*
              {
                page: "elden/jointingmethod",
                title: "Jointing Method",
                submenu: null,
                authority: "JOINTING_METHOD"
              },

              {
                page: "planning/compounds",
                title: "Compounds",
                submenu: null,
                authority: "COMPOUNDS"
              },
              {
                page: "elden/engineering/elden-activity-types",
                title: "Elden Activity Types",
                submenu: null,
                authority: "ELDEN_ACTIVITY_TYPES"
              },
              {
                page: "elden/engineering/elden-activities",
                title: "Elden Activies",
                submenu: null,
                authority: "ELDEN_ACTIVITY_TYPES"
              },
              {
                page: "elden/engineering/elden-designation-activity-log",
                title: "Elden Designation Activity Log",
                submenu: null,
                authority: "ELDEN_DESIGNATION_ACTIVITY_LOG"
              },
              {
                page: "elden/engineering/elden-location-activity-log",
                title: "Elden Location Activity Log",
                submenu: null,
                authority: "ELDEN_DESIGNATION_ACTIVITY_LOG"
              },
              {
                page: "elden/engineering/elden-workplaces",
                title: "Elden Workplaces",
                submenu: null,
                authority: "ELDEN_WORKPLACES"
              },
              {
                page: "elden/engineering/elden-item-classes",
                title: "Elden Item Classes",
                submenu: null,
                authority: "ELDEN_ITEM_CLASSES"
              }
                */
              }
            ]
          },
          {
            page: null,
            title: "Elden Human Resources",
            submenu: [
              {
                page: "elden/humanresources/elden-department",
                title: "Elden Department",
                submenu: null,
                authority: "ELDEN_DEPARTMENT"
              },
              {
                page: "elden/humanresources/elden-designation",
                title: "Elden Designation",
                submenu: null,
                authority: "ELDEN_DESIGNATION"
              },
              {
                page: "elden/humanresources/elden-discipline",
                title: "Elden Discipline",
                submenu: null,
                authority: "ELDEN_DISCIPLINE"
              },
              {
                page: "elden/laborlaw",
                title: "Labor Law",
                submenu: null,
                authority: "ELDEN_LABOR_LAW"
              },
              {
                page: "elden/humanresources/reasonForExit",
                title: "Reason For Exit",
                submenu: null,
                authority: "REASON_FOR_EXIT"
              },
              {
                page: "elden/role-description",
                title: "Role Description",
                submenu: null,
                authority: "ELDEN_dROLE_DESCRIPTION"
              },
              {
                page: "elden/humanresources/elden-roles-log",
                title: "Elden Roles Log",
                submenu: null,
                authority: "ELDEN_ROLES_LOG"
              },
              {
                page: "elden/humanresources/elden-job-description",
                title: "Elden Job Description",
                submenu: null,
                authority: "ELDEN_JOB_DESCRIPTION"
              }
            ]
          },

          {
            page: null,
            title: "Elden Planning",
            submenu: [
              {
                page: "elden/planning/planningComponents",
                title: "Components",
                submenu: null,
                authority: "PLANNING_COMPONENTS"
              },
              {
                page: "elden/planning/elden-budget-items",
                title: "Elden Budget Items",
                submenu: null,
                authority: "ELDEN_BUDGET_ITEMS"
              },
              {
                page: "elden/manufacturing/manufacturingWorkOrder",
                title: "Manufacturing Work Order",
                submenu: null,
                authority: "MANUFACTURING_WORK_ORDER"
              },
              {
                page: "elden/planning/elden-mobilization",
                title: "Elden Mobilization",
                submenu: null,
                authority: "ELDEN_MOBILIZATION"
              },
              {
                page: "elden/planning/mainProjectTypes",
                title: "Main Project Types",
                submenu: null,
                authority: "MAIN_PROJECT_TYPES"
              },

              {
                page: "elden/planning/projectsTree",
                title: "Projects",
                submenu: null,
                authority: "PROJECTS"
              },
              {
                page: "elden/planning/planningProjectTypes",
                title: "Project Types",
                submenu: null,
                authority: "PLANNING_PROJECT_TYPES"
              }
            ]
          },
          {
            page: null,
            title: "Elden Procurement",
            submenu: [
              {
                page: "elden/vendor-performance",
                title: "Elden Vendor Performance",
                submenu: null,
                authority: "ELDEN_VENDOR_PERFORMANCE"
              },
              {
                page: "elden/request-for-quotation-template",
                title: "Request For Quotation Template",
                submenu: null,
                authority: "REQUEST_FOR_QUOTATION_TEMPLATE"
              },
              {
                page: "elden/vendor-questionnary",
                title: "Vendor Selection Questionnary",
                submenu: null,
                authority: "ELDEN_VENDOR_SELECTION_QUESTIONNARY"
              }
            ]
          },
          {
            page: null,
            title: "Elden Logistics",
            submenu: [
              {
                page: "elden/ankaraCounty",
                title: "Elden Ankara County",
                submenu: null,
                authority: "ELDEN_ANKARA_COUNTY"
              }
            ]
          },
          {
            page: null,
            title: "Elden Logistics",
            submenu: [
              {
                page: "elden/istanbulCounty",
                title: "Elden Istanbul County",
                submenu: null,
                authority: "ELDEN_ISTANBUL_COUNTY"
              }
            ]
          },
          {
            page: null,
            title: "Elden Service",
            submenu: [
              {
                /*
              {
                page: "elden/eldenService/elden-service-identifications",
                title: "Elden Service Identifications",
                submenu: null,
                authority: "ELDEN_SERVICE_IDENTIFICATIONS"
              }
                */
              }
            ]
          },

          {
            page: null,
            title: "Elden Stock",
            submenu: [
              {
                page: "elden/stock/elden-equivalent-stock-codes-log",
                title: "Elden Equivalent Stock Codes Log",
                submenu: null,
                authority: "ELDEN_EQUIVALENT_STOCK_CODES_LOG"
              },
              {
                page: "elden/itemcategory",
                title: "Elden Item Category",
                submenu: null,
                authority: "ELDEN_ITEM_CATEGORY"
              },
              {
                page: "elden/stock/stock-card",
                title: "Elden Stock Card",
                submenu: null,
                authority: "ELDEN_STOCK_CARD"
              },

              {
                page: "elden/itemgroup",
                title: "Material Category",
                submenu: null,
                authority: "ITEM_GROUP"
              },
              {
                page: "elden/materialtype",
                title: "Material Type",
                submenu: null,
                authority: "MATERIAL_TYPE"
              },
              {
                page: "elden/property",
                title: "Property",
                submenu: null,
                authority: "PROPERTY"
              },
              {
                page: "elden/elden-store-type",
                title: "Elden Store Type",
                submenu: null,
                authority: "ELDEN_STORE_TYPE"
              },
              {
                page: "elden/elden-storing-type",
                title: "Elden Storing Type",
                submenu: null,
                authority: "ELDEN_STORING_TYPE"
              },
              {
                page: "elden/elden-inside-store-area",
                title: "Elden Inside Store Area",
                submenu: null,
                authority: "ELDEN_INSIDE_STORE_AREA"
              }
            ]
          },
          {
            page: null,
            title: "Elden Documentation",
            submenu: [
              {
                page: "elden/invoice-templates",
                title: "Invoice Templates",
                submenu: null,
                authority: "ELDEN_INVOICE_TEMPLATES"
              },
              {
                page: "elden/presentations",
                title: "Presentations",
                submenu: null,
                authority: "EQUIPMENT_PRESENTATIONS"
              }
            ]
          },
          {
            page: null,
            title: "Elden Equipment",
            submenu: [
              {
                page: "elden/equipment/elden-equipment-group",
                title: "Elden Equipment Group",
                submenu: null,
                authority: "ELDEN_EQUIPMENT_GROUP"
              },
              {
                page: "elden/equipment/elden-equipment-log",
                title: "Elden Equipment Log",
                submenu: null,
                authority: "ELDEN_EQUIPMENT_LOG"
              },

              {
                page: "elden/equipment/elden-equipment-tree",
                title: "Elden Equipment Tree",
                submenu: null,
                authority: "ELDEN_EQUIPMENT_TREE"
              },
              {
                page: "elden/equipment/elden-equipment-type",
                title: "Elden Equipment Type",
                submenu: null,
                authority: "ELDEN_EQUIPMENT_TYPE"
              },
              {
                page: "elden/equipment/elden-purpose-of-use",
                title: "Elden Purpose Of Use",
                submenu: null,
                authority: "ELDEN_PURPOSE_OF_USE"
              },
              {
                page: "elden/equipment/equipmentIdentification",
                title: "Equipment Identification",
                submenu: null,
                authority: "EQUIPMENT_IDENTIFICATION"
              },
              {
                page: "elden/equipment/elden-equipment-log-vrc",
                title: "Vehicle Registration Certificate",
                submenu: null,
                authority: "VEHICLE_REGISTRATION_CERTIFICATE"
              },
              {
                page: "elden/manufacturer",
                title: "Elden Manufacturer",
                submenu: null,
                authority: "ELDEN_MANUFACTURER"
              }
            ]
          },
          {
            page: "elden/presentation",
            title: "Elden Presentation",
            submenu: null,
            authority: "ELDEN_PRESENTATION"
          },
          {
            page: "elden/MaterialCategory",
            title: "Material Category",
            submenu: null,
            authority: "MATERIAL-CATEGORY"
          },
          {
            page: "elden/materialgroup",
            title: "Material Group",
            submenu: null,
            authority: "MATERIAL_GROUP"
          },
          {
            page: null,
            title: "MND",
            submenu: [
              {
                page: "mnd/coding-format",
                title: "Coding Format",
                submenu: null,
                authority: "CODING_FORMAT"
              }
            ]
          },
          {
            page: null,
            title: "Settings",
            submenu: [
              {
                page: "autogenerator/coder-page-form",
                title: "Coder Page Form",
                submenu: null,
                authority: "CODER_PAGE_FORM"
              },
              {
                page: "autogenerator/page-auto-design",
                title: "Page Auto Design",
                submenu: null,
                authority: "PAGE_AUTO_DESIGN"
              },
              {
                page: "autogenerator/page-generate",
                title: "Page Generate",
                submenu: null,
                authority: "PAGE_GENERATE"
              }
            ]
          },
          {
            page: null,
            title: "Standardization",
            submenu: [
              {
                page: "elden/quality/CEV",
                title: "Carbon Equivalent Value",
                submenu: null,
                authority: "CARBON-EQUIVALENT-VALUE"
              },
              {
                page: "elden/quality/ChemicalComposition",
                title: "Chemical Composition",
                submenu: null,
                authority: "Chemical_Composition"
              },
              {
                page: "elden/ColorTable",
                title: "Color Table",
                submenu: null,
                authority: "COLOR_TABLE"
              },
              {
                page: "elden/DinemsionDefinitions",
                title: "Dimension Definitions",
                submenu: null,
                authority: "DIMENSION_DEFINITIONS"
              },
              {
                page: "elden/quality/dimension-tolerences",
                title: "Dimension Tolerences",
                submenu: null,
                authority: "DIMENSION-TOLERENCES"
              },
              {
                page: "elden/quality/Grades",
                title: "Grades",
                submenu: null,
                authority: "GRADES"
              },

              {
                page: "elden/quality/inspection-types",
                title: "Inspection Types",
                submenu: null,
                authority: "INSPECTION-TYPES"
              },

              {
                page: "itpactivity",
                title: "ITP Activty",
                submenu: null,
                authority: "ITP_ACTIVITY"
              },
              {
                page: "elden/quality/mechanicalAndPhysicalTests",
                title: "Mechanical And Physical Tests",
                submenu: null,
                authority: "MECHANICAL_AND_PHYSICAL_TESTS"
              },

              {
                page: "elden/quality/PeriodicTable",
                title: "Periodic Table",
                submenu: null,
                authority: "PERIODIC_TABLE"
              },

              {
                page: "elden/productForm",
                title: "Product Form",
                submenu: null,
                authority: "PRODUCT_FORM"
              },
              {
                page: "elden/quality/profileWeights",
                title: "Profile Weights",
                submenu: null,
                authority: "PROFILE-WEIGHTS"
              },
              {
                page: "elden/PurchaseCriteria",
                title: "Purchase Criteria",
                submenu: null,
                authority: "PURCHASE_CRITERIA"
              },
              {
                page: "elden/Rename",
                title: "Rename",
                submenu: null,
                authority: "RENAME"
              },
              {
                page: "elden/quality/standard",
                title: "Standards",
                submenu: null,
                authority: "ELDEN_STANDARD"
              },
              {
                page: "elden/quality/typeOfTests",
                title: "Type Of Tests",
                submenu: null,
                authority: "TYPE_OF_TESTS"
              },
              {
                page: "elden/unit",
                title: "Unit",
                submenu: null,
                authority: "UNIT"
              },

              {
                page: "unit/conversion",
                title: "Unit Conversion",
                submenu: null,
                authority: "UNIT_CONVERSION"
              },
              {
                page: "elden/unitgroup",
                title: "Unit Group",
                submenu: null,
                authority: "UNITGROUP"
              }
            ]
          },
          {
            page: null,
            title: "Test",
            submenu: [
              {
                page: "test/modelView",
                title: "Model View",
                submenu: null,
                authority: "MODEL_VIEW"
              },
              {
                page: "test/testPage",
                title: "Test Page",
                submenu: null,
                authority: "TEST_PAGE"
              },
              {
                page: "test/vendorProduct",
                title: "Test Product",
                submenu: null,
                authority: "TEST_PRODUCT"
              }
            ]
          }
        ]
      },
      //eldenbitiş
      {
        page: "generatebackend",
        title: "Backend",
        submenu: null,
        authority: "BACKEND"
      },

      {
        page: "client",
        title: "Client",
        submenu: null,
        authority: "CLIENT"
      },
      {
        page: "authorization",
        title: "Company Authorization",
        submenu: null,
        authority: "AUTHORIZATION"
      },
      {
        page: "pageinput-company",
        title: "Company Form Settings",
        submenu: null,
        authority: "PAGE_INPUT_COMPANY"
      },

      {
        page: "code",
        title: "Dropdown Settings",
        submenu: null,
        authority: "DROPDOWN_SETTINGS"
      },
      {
        page: "administrator/dynamic-page/asd",
        title: "Dynamic Page",
        submenu: null,
        authority: "DYNAMIC_PAGE"
      },
      {
        page: "folder-auth",
        title: "Folder Authorization",
        submenu: null,
        authority: "FOLDER_AUTHORIZATION"
      },
      {
        page: "utils/formattedmessage",
        title: "Formatted Message",
        submenu: null,
        authority: "FORMATTED_MESSAGE"
      },
      {
        page: "generatefrontend",
        title: "Frontend",
        submenu: null,
        authority: "FRONTEND"
      },

      {
        page: "howtouse",
        title: "How to Use",
        submenu: null,
        authority: "HOW_TO_USE"
      },
      {
        page: "language-settings",
        title: "Language Settings",
        submenu: null,
        authority: "LANGUAGE_SETTINGS"
      },

      {
        page: "menu",
        title: "Menu",
        submenu: null,
        authority: "MENU"
      },
      {
        page: "menu-auth",
        title: "Menu Authorization",
        submenu: null,
        authority: "MENU_AUTHORIZATION"
      },

      {
        page: "administrator/page-actions",
        title: "Page Actions",
        submenu: null,
        authority: "PAGEACTIONS"
      },
      {
        page: "administrator/page-form",
        title: "Page Form",
        submenu: null,
        authority: "PAGE_FORM"
      },
      {
        page: "administrator/page-form-input",
        title: "Page Form Input",
        submenu: null,
        authority: "PAGE_FORM_INPUT"
      },

      {
        page: "administrator/page-form-input-company",
        title: "Page Form Input Company",
        submenu: null,
        authority: "PAGE_FORM_INPUT_COMPANY"
      },
      {
        page: "pageinput",
        title: "Page Input",
        submenu: null,
        authority: "PAGE_INPUT"
      },
      {
        page: "project",
        title: "Project",
        submenu: null,
        authority: "PROJECT"
      },
      {
        page: "company",
        title: "Sub Company",
        submenu: null,
        authority: "COMPANY"
      },
      {
        page: "user",
        title: "User",
        submenu: null,
        authority: "USER"
      }
    ]
  }
];

export class DynamicMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hiddenInputs: true,
      hiddenSave: true,
      hiddenUpdate: true,
      isometricData: [],
      selectedIsometric: "",
      checkedIsometric: [],
      createNewButton: "Create New",
      createNewButtonVariant: "info",
      dynamicMenuTreeTable: [],
      dynamicMenuCascader: [],
      parentId: [],
      IconUpload: [],
      fileList: [],
      icon: "",
      menuTitle: "",
      description: "",
      page: "",
      id: "",
      orderNo: "",
      translate: "",
      showInMenu: true,

      dynamicMenuId: "",

      dynamicMenuDelete: true,

      orderItemList: [],
      modalOrder: false,
      showPageActionsModal: false,
      showPageInputsModal: false,
      pageInputsModalTitle: null
    };

    this.actionTemplate = this.actionTemplate.bind(this);
    this.renderDownloadButton = this.renderDownloadButton.bind(this);
    this.Order = this.Order.bind(this);
  }

  componentDidMount = async () => {
    this.getDynamicMenu();
  };

  getDynamicMenu = async () => {
    const responseTree = await handleRequest("GET", "/api/menus/tree");
    if (responseTree.data.length !== 0) {
      generateList(responseTree.data.treeTable);
      this.setState({
        dynamicMenuTreeTable: responseTree.data.treeTable,
        dynamicMenuCascader: responseTree.data.cascader
      });
    }
  };

  showOrHideModalTable = async tableTip => {
    this.setState({
      modalTable: !this.state.modalTable,
      tableTip: tableTip
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  };

  createNewClientButton = async () => {
    await this.resetDynamicMenu();

    if (this.state.createNewButton === "Create New") {
      this.setState({
        hiddenInputs: false,
        createNewButton: "Cancel",
        createNewButtonVariant: "danger",
        hiddenSave: false,
        hiddenUpdate: true
      });
    } else {
      this.setState({
        hiddenInputs: true,
        createNewButton: "Create New",
        createNewButtonVariant: "info",
        hiddenSave: true,
        hiddenUpdate: true
      });
    }
  };

  resetDynamicMenu = () => {
    this.setState({
      id: -1,
      parentId: [],
      menuTitle: "",
      translate: "",
      description: "",
      page: "",
      showInMenu: true,
      orderNo: "",
      IconUpload: [],
      fileList: [],
      icon: ""
    });
  };

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  };

  showOrHideModalDeleteDynamicMenu = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  };

  showOrHideModalOrder = async () => {
    this.setState({
      modalOrder: !this.state.modalOrder
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  };

  showOrHidePhotoModal = () => {
    this.setState({
      modalPhotoShow: !this.state.modalPhotoShow
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  };

  showReport = async rowData => {
    try {
      if (rowData.icon !== null && rowData.icon !== undefined && rowData.icon !== "") {
        const fileRealUrl = rowData.icon;

        if (
          fileRealUrl.endsWith(".png") ||
          fileRealUrl.endsWith(".jpg") ||
          fileRealUrl.endsWith(".tiff") ||
          fileRealUrl.endsWith(".jpeg") ||
          fileRealUrl.endsWith(".PNG") ||
          fileRealUrl.endsWith(".JPG") ||
          fileRealUrl.endsWith(".TIFF") ||
          fileRealUrl.endsWith(".JPEG")
        ) {
          this.setState({
            imageUrl: fileRealUrl,
            modalPhotoShow: true
          });
        } else {
          window.location.href = fileRealUrl;
        }
      } else {
        this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
      }
    } catch (error) {
      this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
    }
  };

  showSuccess(msg) {
    if (Boolean(msg) && msg.length > 0) message.success(msg);
    else message.success("Success");
  }

  showError(msg) {
    if (Boolean(msg) && msg.length > 0) message.error(msg);
    else message.error("An error has occured");
  }

  showWarning(msg) {
    if (Boolean(msg) && msg.length > 0) message.warn(msg);
    else message.warn("Something went wrong. Please check inputs");
  }

  actionTemplate(rowData, column) {
    return (
      <React.Fragment>
        <CustomMaterialMenuDynamicMenu
          row={rowData.data}
          onDeleteRow={this.deleteDynamicMenu.bind(this)}
          onEditRow={this.changeButtonDynamicMenu.bind(this)}
          onOrderRow={this.Order.bind(this)}
          onInputsRow={this.openPageInputsModal.bind(this)}
          onActionRow={this.openPageActionsModal.bind(this)}
        />
      </React.Fragment>
    );
  }

  sync = async () => {
    let response = await handleRequest("POST", "/api/menus/sync", menuList);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.getDynamicMenu();
      this.showSuccess();
    }
  };

  deleteDynamicMenu = rowData => {
    this.setState({
      deleteModal: true,
      dynamicMenuId: rowData.id,
      dynamicMenuDelete: false
    });
  };

  openPageInputsModal = row => {
    var deger = dataList.find(p => p.key === row.id);

    if (Boolean(deger)) {
      this.setState({
        pageInputsModalTitle: deger.title
      });
    } else {
      this.setState({
        pageInputsModalTitle: null
      });
    }
    this.setState({
      showPageInputsModal: true,
      dynamicMenuId: row.id
    });
  };

  openPageActionsModal = row => {
    this.setState({
      showPageActionsModal: true,
      dynamicMenuId: row.id
    });
  };

  deleteDynamicMenuOK = async () => {
    const deletedDynamicMenu = {
      id: this.state.dynamicMenuId
    };

    var response = await handleRequest("DELETE", "/api/menus/" + deletedDynamicMenu.id);

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        deleteModal: false
      });
      this.getDynamicMenu();
      this.showSuccess();
    } else {
      this.showError();
    }
    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  };

  changeButtonDynamicMenu = async row => {
    var deger = dataList.filter(p => p.key === row.parentId);

    if (Boolean(deger) && deger.length > 0) {
      this.setState({
        parentId: deger[0].lineage
      });
    } else {
      this.setState({
        parentId: []
      });
    }

    await this.setState({
      hiddenInputs: false,
      hiddenSave: true,
      hiddenUpdate: false,
      createNewButton: "Cancel",
      createNewButtonVariant: "danger",

      id: row.id,
      menuTitle: row.menuTitle,
      description: row.description,
      page: row.page,
      showInMenu: row.showInMenu,
      orderNo: row.orderNo,
      icon: row.icon,
      translate: row.translate
    });
  };

  changeButtonCancel = () => {
    this.setState({
      hiddenSave: true,
      hiddenUpdate: true,
      createNewButton: "Create New",
      createNewButtonVariant: "info",
      hiddenInputs: true
    });
    this.resetDynamicMenu();
  };

  updateDone = async () => {
    if (
      this.state.id !== null &&
      this.state.id !== undefined &&
      this.state.id !== "" &&
      this.state.id !== 0 &&
      this.state.menuTitle !== null &&
      this.state.menuTitle !== undefined &&
      this.state.menuTitle !== ""
    ) {
      var parentId = null;
      if (this.state.parentId.length > 0) {
        parentId = this.state.parentId[this.state.parentId.length - 1];
      }

      const updatedDynamicMenu = {
        id: this.state.id,
        parentId: parentId,
        menuTitle: this.state.menuTitle,
        description: this.state.description,
        page: this.state.page,
        showInMenu: this.state.showInMenu,
        orderNo: this.state.orderNo,
        icon: this.state.icon,
        translate: this.state.translate
      };

      await handleRequest("PUT", "/api/menus/" + updatedDynamicMenu.id, updatedDynamicMenu);
      this.getDynamicMenu();
      this.createNewClientButton();
      this.showSuccess();
      this.resetDynamicMenu();
    } else {
      this.showWarning();
    }
  };

  addDynamicMenu = async () => {
    if (this.state.menuTitle !== null && this.state.menuTitle !== undefined && this.state.menuTitle !== "") {
      var parentId = null;
      if (this.state.parentId.length > 0) {
        parentId = this.state.parentId[this.state.parentId.length - 1];
      }

      var _orderNo = 0;
      if (parentId != null) var res = await handleRequest("GET", "/api/menus/order/" + parentId);
      if (Boolean(res) && Boolean(res.data)) _orderNo = res.data;

      const prm = {
        parentId: parentId,
        menuTitle: this.state.menuTitle,
        description: this.state.description,
        page: this.state.page,
        showInMenu: this.state.showInMenu,
        orderNo: _orderNo,
        icon: this.state.icon,
        translate: this.state.translate
      };

      const response = await handleRequest("POST", "/api/menus", prm);
      if (response.data !== null && response.data !== undefined) {
        if (Boolean(response.data)) {
          this.getDynamicMenu();
          this.createNewClientButton();
          this.showSuccess();
          this.resetDynamicMenu();
        } else {
          this.showError();
        }
      } else {
        this.showError();
      }
    } else {
      this.showWarning();
    }
  };

  onDynamicMenuCascaderChange = value => {
    this.setState({
      parentId: value
    });
  };

  renderDownloadButton(rowData, column) {
    if (rowData.data.icon !== null && rowData.data.icon !== undefined && rowData.data.icon !== "") {
      return (
        <AntButton
          style={{
            marginLeft: "2px",
            marginTop: "-2px",
            alignContent: "center"
          }}
          onClick={() => this.showReport(rowData.data)}
          type="primary"
          shape="round"
          icon={<DownloadOutlined />}
        ></AntButton>
      );
    }
    return "";
  }

  Order = async rowData => {
    const obj = {
      parentId: Boolean(rowData.parentId) ? rowData.parentId : -1
    };
    const response = await handleRequest("GET", "/api/menus/submenus/" + obj.parentId);

    this.setState({
      orderItemList: response.data,
      modalOrder: true
    });
  };

  template(item) {
    return (
      <div className="p-clearfix">
        <div style={{ fontSize: "12px", margin: "5px 5px 0 0" }}>{item.menuTitle}</div>
      </div>
    );
  }

  saveOrder = async () => {
    const list = this.state.orderItemList;
    list.forEach((element, i) => {
      element.orderNo = i;
    });

    await handleRequest("PUT", "/api/menus/order", list);

    this.getDynamicMenu();
    this.showSuccess();
  };

  getMenu = async (element, list, parentId) => {
    if (element.title) {
      var obj = {
        key: element.title,
        url: Boolean(element.url) ? element.url : "",
        title: element.title,
        label: element.title,
        data: "",
        children: [],
        disabled: true
      };

      list.push(obj);

      const prm = {
        parentId: parentId,
        menuTitle: element.title,
        description: element.desc,
        page: element.page,

        orderNo: order,
        icon: element.icon,
        translate: ""
      };

      const response = await handleRequest("POST", "/api/menus", prm);
      let responseId = response.data;
      order = order + 1;
      if (element.submenu) {
        element.submenu.forEach(el => {
          this.getMenu(el, obj.children, responseId);
          //  list.push(obj);
        });
      }
    }
  };

  render() {
    return (
      <Badge.Ribbon text="Dynamic Menu" placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "3rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }}>
            <Row>
              <Col xs={10}>
                {/*<Button onClick={this.createAuto} style={{ width: '100%' }}
                                variant={this.state.createNewButtonVariant} > Create Menu Auto
                            </Button>*/}
              </Col>
              <Col md={1}>
                <AntButton style={{ border: "0px" }} onClick={this.sync} icon={<SyncIcon color="primary" fontSize="large" />}></AntButton>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hiddenInputs}
                  onClick={this.createNewClientButton}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hiddenInputs}
                  onClick={this.createNewClientButton}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>

            <div hidden={this.state.hiddenInputs}>
              <Form.Group style={{ marginTop: "2rem", marginBottom: "1rem" }} as={Row}>
                <Col sm="2"></Col>
                <Form.Label style={{ color: "black" }} column sm="2">
                  <FormattedMessage id="DYNMParentMenu" defaultMessage="Parent Menu" />
                </Form.Label>
                <Col sm="4">
                  <Cascader
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    value={this.state.parentId}
                    options={this.state.dynamicMenuCascader}
                    onChange={this.onDynamicMenuCascaderChange}
                    changeOnSelect
                  />
                </Col>
              </Form.Group>
              <Form.Group style={{ marginTop: "2rem", marginBottom: "1rem" }} as={Row}>
                <Col sm="2"></Col>
                <Form.Label style={{ color: "black" }} column sm="2"></Form.Label>
                <Col sm="4">
                  <Checkbox
                    checked={this.state.showInMenu}
                    onChange={e => {
                      this.setState({
                        showInMenu: e.target.checked
                      });
                    }}
                  >
                    <FormattedMessage id="DYNMShowInMenu" defaultMessage="Show in menu" />
                  </Checkbox>
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: "2rem", marginBottom: "1rem" }} as={Row}>
                <Col sm="2"></Col>
                <Form.Label style={{ color: "black" }} column sm="2">
                  <FormattedMessage id="DYNMTitle" defaultMessage="Title" />
                </Form.Label>
                <Col sm="4">
                  <InputText
                    style={{ width: "100%" }}
                    value={this.state.menuTitle}
                    onChange={e =>
                      this.setState({
                        menuTitle: e.target.value
                      })
                    }
                  />
                </Col>
              </Form.Group>

              <Form.Group hidden={this.state.parentId.length === 0} style={{ marginTop: "2rem", marginBottom: "1rem" }} as={Row}>
                <Col sm="2"></Col>
                <Form.Label style={{ color: "black" }} column sm="2">
                  <FormattedMessage id="DYNMPage" defaultMessage="Page" />
                </Form.Label>
                <Col sm="4">
                  <InputText
                    style={{ width: "100%" }}
                    value={this.state.page}
                    onChange={e =>
                      this.setState({
                        page: e.target.value
                      })
                    }
                  />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: "2rem", marginBottom: "1rem" }} as={Row}>
                <Col sm="2"></Col>
                <Form.Label style={{ color: "black" }} column sm="2">
                  <FormattedMessage id="DYNMIcon" defaultMessage="Icon" />
                </Form.Label>
                <Col sm="4">
                  <InputText
                    style={{ width: "100%" }}
                    value={this.state.icon}
                    onChange={e =>
                      this.setState({
                        icon: e.target.value
                      })
                    }
                  />
                </Col>
              </Form.Group>
            </div>

            <div hidden={this.state.hiddenSave}>
              <Form.Group style={{ marginTop: "2rem", marginBottom: "1rem" }} as={Row} controlId="formGridDocCode">
                <Col sm="4"></Col>
                <Col sm="4">
                  <Button onClick={this.addDynamicMenu} style={{ width: "100%" }} variant="success">
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Col>
                <Col sm="4"></Col>
              </Form.Group>
            </div>

            <div hidden={this.state.hiddenUpdate}>
              <Form.Group style={{ marginTop: "2rem", marginBottom: "1rem" }} as={Row} controlId="formGridDocCode">
                <Col sm="4"></Col>
                <Col sm="2">
                  <Button onClick={this.changeButtonCancel} style={{ width: "100%" }} variant="danger">
                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                  </Button>
                </Col>
                <Col sm="2">
                  <Button onClick={this.updateDone} style={{ width: "100%" }} variant="warning">
                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                  </Button>
                </Col>
                <Col sm="4"></Col>
              </Form.Group>
            </div>
            <br />
            <br />

            <TreeTable value={this.state.dynamicMenuTreeTable}>
              <Column field="menuTitle" header={<FormattedMessage id="DYNMTitle" defaultMessage="Title" />} expander filter sortable />
              <Column field="page" header={<FormattedMessage id="DYNMPage" defaultMessage="Page" />} filter sortable />
              <Column field="icon" header={<FormattedMessage id="DYNMIcon" defaultMessage="Icon" />} filter sortable />
              {/* <Column body={this.renderDownloadButton} header={<FormattedMessage id="DYNMIcon" />} filter ></Column> */}
              <Column header="" body={this.actionTemplate} style={{ textAlign: "center", width: "8em" }} />
            </TreeTable>

            <ModalReact
              show={this.state.modalMessage}
              onHide={this.showOrHideModalMessage}
              backdrop="static"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <ModalReact.Header closeButton>
                <Row>
                  <Col sm="12">
                    {this.state.messageTip === "Success" && <i className="fas fa-check fa-2x" style={{ color: "#00ff2a" }} />}

                    {this.state.messageTip === "Warning" && <i className="fas fa-exclamation-circle fa-2x" style={{ color: "#faca2a" }} />}

                    {this.state.messageTip === "Error" && <i className="fas fa-times fa-2x" style={{ color: "#ff0400" }} />}
                  </Col>
                </Row>
                <AntButton style={{ marginLeft: "auto" }} onClick={this.showOrHideModalMessage} icon={<CloseIcon color="secondary" />}></AntButton>
              </ModalReact.Header>

              <ModalReact.Body>
                <Row>
                  <Col sm="12">{this.state.message}</Col>
                </Row>
              </ModalReact.Body>
              <ModalReact.Footer>
                <Button variant="secondary" onClick={this.showOrHideModalMessage}>
                  <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                </Button>
              </ModalReact.Footer>
            </ModalReact>

            <ModalReact
              show={this.state.deleteModal}
              onHide={this.showOrHideModalDeleteDynamicMenu}
              aria-labelledby="contained-modal-title-vcenter"
              backdrop="static"
              centered
            >
              <ModalReact.Header closeButton>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                <AntButton
                  style={{ marginLeft: "auto" }}
                  onClick={this.showOrHideModalDeleteDynamicMenu}
                  icon={<CloseIcon color="secondary" />}
                ></AntButton>
              </ModalReact.Header>
              <ModalReact.Body>
                <p style={{ textAlign: "center", color: "black" }}>
                  <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
                </p>
                <p style={{ textAlign: "center", color: "black" }}>
                  <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                </p>
              </ModalReact.Body>
              <ModalReact.Footer>
                <Button variant="secondary" onClick={this.showOrHideModalDeleteDynamicMenu}>
                  <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                </Button>
                <div hidden={this.state.dynamicMenuDelete}>
                  <Button variant="danger" onClick={this.deleteDynamicMenuOK}>
                    <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                  </Button>
                </div>
              </ModalReact.Footer>
            </ModalReact>

            <ModalReact
              show={this.state.showPageInputsModal}
              onHide={() => {
                this.setState({
                  showPageInputsModal: false,
                  pageInputsModalTitle: null,
                  dynamicMenuId: null
                });
              }}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              size="xl"
              scrollable={true}
            >
              <ModalReact.Header closeButton>
                <ModalReact.Title id="contained-modal-title-vcenter"> {this.state.pageInputsModalTitle} </ModalReact.Title>
              </ModalReact.Header>
              <ModalReact.Body>
                <PageInput menuId={this.state.dynamicMenuId}></PageInput>
              </ModalReact.Body>
              <ModalReact.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      showPageInputsModal: false,
                      pageInputsModalTitle: null,
                      dynamicMenuId: null
                    });
                  }}
                >
                  Close
                </Button>

                {/* <Button variant="primary" onClick={this.saveLog} > Save </Button> */}
              </ModalReact.Footer>
            </ModalReact>

            <ModalReact
              show={this.state.modalPhotoShow}
              onHide={this.showOrHidePhotoModal}
              backdrop="static"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <ModalReact.Header closeButton>
                <FormattedMessage id="ModalPhotoText" defaultMessage="Photo" />
                <AntButton style={{ marginLeft: "auto" }} onClick={this.showOrHidePhotoModal} icon={<CloseIcon color="secondary" />}></AntButton>
              </ModalReact.Header>
              <ModalReact.Body>
                {this.state.imageUrl !== "" ? (
                  <img src={this.state.imageUrl} alt="avatar" style={{ width: "100%" }} />
                ) : (
                  <p>
                    {" "}
                    <FormattedMessage id="UploadedPhotoMessage1" defaultMessage="Photo has not been uploaded." />
                  </p>
                )}
              </ModalReact.Body>
              <ModalReact.Footer>
                <AntButton variant="info" onClick={this.showOrHidePhotoModal}>
                  <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                </AntButton>
              </ModalReact.Footer>
            </ModalReact>

            <ModalReact
              show={this.state.modalOrder}
              onHide={this.showOrHideModalOrder}
              backdrop="static"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <ModalReact.Header closeButton>
                <Row>
                  <Col sm="12">
                    <FormattedMessage id="DYNMOrderSettings" defaultMessage="Order Settings" />
                  </Col>
                </Row>
                <AntButton style={{ marginLeft: "auto" }} onClick={this.showOrHideModalOrder} icon={<CloseIcon color="secondary" />}></AntButton>
              </ModalReact.Header>

              <ModalReact.Body>
                <Row>
                  <Col sm="12">
                    <Form>
                      <Form.Group style={{ marginTop: "2rem", marginBottom: "1rem" }} as={Row} controlId="formGridDocCode">
                        <Col sm="12">
                          {this.state.orderItemList.length > 0 && (
                            <OrderList
                              value={this.state.orderItemList}
                              responsive={true}
                              itemTemplate={this.template}
                              onChange={e => this.setState({ orderItemList: e.value })}
                            ></OrderList>
                          )}
                        </Col>
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
              </ModalReact.Body>
              <ModalReact.Footer>
                <Button variant="secondary" onClick={this.showOrHideModalOrder}>
                  <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                </Button>
                <Button variant="success" onClick={this.saveOrder}>
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </ModalReact.Footer>
            </ModalReact>
          </Form>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicMenu);
