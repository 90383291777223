import { ExclamationCircleOutlined, FilterOutlined } from "@ant-design/icons";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import {
  Button as AntButton,
  Col,
  Descriptions,
  Form,
  Image,
  Modal as AntModal,
  PageHeader,
  Row,
  Select,
  Table,
  Tag,
  DatePicker,
  Space,
  Spin,
  Tooltip,
  Drawer
} from "antd";
import "antd/dist/antd.css";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess, showWarning } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined, ReplyOutlined, Settings } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import { PageSettings } from "../administrator/pageSettings/PageSettings";
import store from "../../../redux/store";

const { Option } = Select;
const { RangePicker } = DatePicker;

export class CustomerAccount extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      controllerName: "invoice",
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      tableList: [],
      vendorDetail: {},
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      loadingButtonSubmit: false,
      sortField: "",
      sortOrder: "",
      pdfFile: "",

      reportData: [],
      excelData: [],
      customerIdSelectItems: [],
      user: store.getState().auth.user
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responsecustomerId = await handleRequest("GET", "/api/invoice/customerIdSelectItems");
    if (responsecustomerId.type === "ERROR") {
      error(responsecustomerId);
    } else {
      this.setState({
        customerIdSelectItems: Boolean(responsecustomerId.data) ? responsecustomerId.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  getVendorId = () => {
    return this.state.id;
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  edit = row => {
    showWarning("Not Implemented!");
    // document.getElementById("kt_scrolltop").click();

    // this.formRef.current.setFieldsValue({
    //   ...row,
    // });
    // console.log(row);
    // this.setState({
    //   hideUpdate: false,
    //   hideSave: true,
    //   hideInputs: false,
    //   id: row.id,
    // });
  };

  showVendorDetailModal = row => {
    this.setState({
      vendorDetail: row
    });
  };

  deleteModal = row => {
    showWarning("Not Implemented!");
    // this.setState({
    //   modalDeleteShow: true,
    //   id: row.id,
    // });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    console.log("deleted");
  };

  createStatementModal = row => {
    this.setState({
      modalCreateStatementShow: true,
      selectedRow: row
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/vendor/customer/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/vendor/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this, false)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };
    let createStatementAction = {
      name: "Create Statement",
      icon: <ReplyOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.createStatementModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(createStatementAction);
    actionList.push(deleteAction);
    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  generateReport = async () => {
    this.setState({ loadingButtonSubmit: true });
    const { selectedRow, dates } = this.state;
    if (Boolean(selectedRow) && Boolean(selectedRow.id) && selectedRow.id > 0) {
      if (Boolean(dates) && dates.length === 2) {
        let newObj = {
          minDate: dates[0],
          maxDate: dates[1],
          vendorId: selectedRow.id
        };
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/generateLedgerStatementReport", newObj);
        if (response.type === "ERROR") {
          error(response);
          this.setState({
            loadingButtonSubmit: false
          });
        } else if (Boolean(response.data) && response.data.length > 0) {
          this.setState({
            pdfFile: response.data,
            modalPdfViewer: true,
            loadingButtonSubmit: false
          });
        } else {
          this.setState({
            pdfFile: null,
            modalPdfViewer: false,
            loadingButtonSubmit: false
          });
          showError("There is no created report for selected item");
        }
      } else {
        this.setState({
          loadingButtonSubmit: false
        });
        showWarning("Please select period.");
      }
    } else {
      this.setState({
        loadingButtonSubmit: false
      });
      showWarning("Item not found.");
    }
  };

  getLedgerAccount = async () => {
    const { selectedRow, dates } = this.state;

    if (Boolean(selectedRow) && Boolean(selectedRow.id) && selectedRow.id > 0) {
      if (Boolean(dates) && dates.length === 2) {
        let newObj = {
          minDate: dates[0],
          maxDate: dates[1],
          vendorId: selectedRow.id
        };
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/getLedgerAccount", newObj);
        if (response.type === "ERROR") {
          error(response);
        } else {
          this.setState({
            reportData: Boolean(response.data) ? response.data : [],
            modalReportShow: true
          });
        }
      } else {
        showWarning("Please select period.");
      }
    } else {
      showWarning("Item not found.");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };

    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="Vendor.name" defaultMessage="Name" />,
        key: "name",
        render: record => {
          return (
            <Button variant="link" onClick={() => this.showVendorDetailModal(record)}>
              {record.name}
            </Button>
          );
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Customer"
              id="name"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  name: value,
                  nameColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              {this.state.customerIdSelectItems.map(i => (
                <Option key={i.key} value={i.value}>
                  {i.value}
                </Option>
              ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.nameColor) ? this.state.pagination.nameColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Vendor.arabicName" defaultMessage="Arabic Name" />,
        key: "arabicName",
        render: record => {
          return record.arabicName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Customer Arabic name"
              id="arabicName"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  arabicName: value,
                  arabicNameColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              {this.state.customerIdSelectItems.map(i => (
                <Option key={i.key} value={i.description}>
                  {i.description}
                </Option>
              ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.arabicNameColor) ? this.state.pagination.arabicNameColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Vendor.TotalInvoiceAmount" defaultMessage="Total Amount" />,
        key: "totalInvoiceAmount",
        render: record => {
          return record.totalInvoiceAmount;
        }
      },
      {
        title: <FormattedMessage id="Vendor.TotalPaidAmount" defaultMessage="Total Paid" />,
        key: "totalInvoicePaidAmount",
        render: record => {
          return record.totalInvoicePaidAmount;
        }
      },
      {
        title: <FormattedMessage id="Vendor.Balance" defaultMessage="Balance" />,
        key: "balance",
        render: record => {
          let b = record.totalInvoicePaidAmount - record.totalInvoiceAmount;
          return <Text type={b > 0 ? "success" : "danger"}>{b}</Text>;
        }
      }
    ];

    const add = (accumulator, a) => {
      return accumulator + a;
    };
    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
              exportClick: false
            })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="CustomerAccountLog" hideElement={true}>
            <ExcelSheet name="CustomerAccountLog" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );
    const expandedRowRender = row => {
      console.log(row);
      const columns = [
        {
          title: "Date",
          key: "date",
          render: record => {
            return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
          }
        },
        {
          title: "Invoice No",
          key: "invoiceNo",
          render: record => {
            return record.invoiceNo;
          },
          sorter: (a, b) => a.invoiceNo - b.invoiceNo,
          defaultSortOrder: "descend",
          sortDirections: ["descend"]
        },
        {
          title: "Amount",
          key: "grandTotal",
          render: record => {
            return record.grandTotal;
          },
          align: "right"
        },
        {
          title: "Paid Amount",
          key: "paidAmount",
          render: record => {
            let amounts = record.invoicePaymentList.map(item => {
              return item.paymentMethodType !== "CREDIT" ? item.amount : 0;
            });
            return amounts.reduce(add, 0);
          },
          align: "right"
        },
        {
          title: "Invoice Balance",
          key: "balance",
          render: record => {
            let amounts = record.invoicePaymentList.map(item => {
              return item.paymentMethodType !== "CREDIT" ? item.amount : 0;
            });
            let totalPaid = amounts.reduce(add, 0);
            let balance = totalPaid - record.grandTotal;
            return <Text type={balance > 0 ? "success" : "danger"}>{balance}</Text>;
          },
          align: "right"
        },
        {
          title: "Payment Types",
          key: "paymentType",
          render: record => {
            return record.invoicePaymentList.map(item => {
              return (
                <Tag key={item.id} color={item.paymentMethodType === "CASH" ? "green" : item.paymentMethodType === "TRANSFER" ? "blue" : "red"}>
                  {item.paymentMethodType} : {item.amount}
                </Tag>
              );
            });
          },
          align: "right"
        }
        // {
        //   title: "Balance",
        //   key: "balance",
        //   render: (record) => {
        //     return record.balance;
        //   },
        // },
      ];
      return (
        <Table
          bordered
          className="table-striped-rows"
          rowKey="id"
          size="small"
          columns={columns}
          dataSource={row}
          pagination={false}
          // className="table-not-responsive"
        />
      );
    };
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <PageHeader className="site-page-header" title={"Customer Account Log"} />
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col offset={22} md={1}>
              {excelExport}
            </Col>
            <Col md={1}>
              {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                <Tooltip title={<FormattedMessage id="RequestForQuotation.Settings" defaultMessage="Settings" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => {
                      this.setState({
                        drawerSettingsShow: true
                      });
                    }}
                    icon={<Settings color="error" fontSize="large" />}
                  ></AntButton>
                </Tooltip>
              )}
            </Col>
          </Row>
        </Form>
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            rowKey="id"
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
            expandable={{
              expandedRowRender: record => expandedRowRender(record.invoiceList),
              rowExpandable: record => Boolean(record.invoiceList) && record.invoiceList.length > 0,
              expandedRowClassName: record => "danger"
            }}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <AntModal
          title="Vendor Information"
          closable={true}
          centered
          visible={Boolean(this.state.vendorDetail.name)}
          onOk={() => {
            this.setState({
              vendorDetail: {}
            });
          }}
          onCancel={() => {
            this.setState({
              vendorDetail: {}
            });
          }}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <Descriptions bordered column={{ sm: 1, xs: 1 }} size="small">
            <Descriptions.Item label="Name">{this.state.vendorDetail.name}</Descriptions.Item>
            <Descriptions.Item label="Arabic Name">{this.state.vendorDetail.arabicName}</Descriptions.Item>
            <Descriptions.Item label="Short Name">{this.state.vendorDetail.shortName}</Descriptions.Item>
            <Descriptions.Item label="Logo">
              {Boolean(this.state.vendorDetail.logoPath) ? <Image width={80} src={this.state.vendorDetail.logoPath} /> : "No Logo"}
            </Descriptions.Item>
            <Descriptions.Item label="About">{this.state.vendorDetail.about}</Descriptions.Item>
            <Descriptions.Item label="Vision">{this.state.vendorDetail.vision}</Descriptions.Item>
            <Descriptions.Item label="Mission">{this.state.vendorDetail.mission}</Descriptions.Item>
            <Descriptions.Item label="Website">{this.state.vendorDetail.website}</Descriptions.Item>
            <Descriptions.Item label="Address">{this.state.vendorDetail.address}</Descriptions.Item>
            <Descriptions.Item label="Phone">{this.state.vendorDetail.phone}</Descriptions.Item>
            <Descriptions.Item label="CR">{this.state.vendorDetail.cr}</Descriptions.Item>
            <Descriptions.Item label="Tax Number">{this.state.vendorDetail.taxNumber}</Descriptions.Item>
            <Descriptions.Item label="Vat Number">{this.state.vendorDetail.vatNumber}</Descriptions.Item>
            <Descriptions.Item label="Bank">{this.state.vendorDetail.bankName}</Descriptions.Item>
            <Descriptions.Item label="IBAN">{this.state.vendorDetail.ibanNo}</Descriptions.Item>
          </Descriptions>
        </AntModal>

        <AntModal
          title={
            <FormattedMessage
              id="CustomerAccount.PleaseSelectTheCovaragePeriodStatement"
              defaultMessage="Please select the covarage period statement"
            />
          }
          centered
          open={this.state.modalCreateStatementShow}
          onCancel={() => {
            this.setState({
              modalCreateStatementShow: false
            });
          }}
          footer={[
            <Space>
              <Spin spinning={this.state.loadingButtonSubmit}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.generateReport();
                  }}
                >
                  <FormattedMessage id="CustomerAccount.CreatePdf" defaultMessage="Create Pdf" />
                </Button>
              </Spin>
              <Button
                variant="primary"
                onClick={() => {
                  this.getLedgerAccount();
                }}
              >
                <FormattedMessage id="CustomerAccount.ShowTheReport" defaultMessage="Show the report" />
              </Button>
            </Space>
          ]}
        >
          <RangePicker
            format="DD-MM-YYYY HH:mm"
            showTime={{ format: "HH:mm" }}
            onChange={(dates, dateStrings) => {
              this.setState({
                dates: dates
              });
            }}
          />
        </AntModal>

        <AntModal
          title={<FormattedMessage id="GeneralPDFReportViewer" defaultMessage="PDF Report Viewer" />}
          open={this.state.modalPdfViewer}
          onCancel={() => {
            this.setState({ modalPdfViewer: false });
          }}
          cancelButtonProps={{ hidden: false }}
          okButtonProps={{ hidden: true }}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          width={1200}
          centered
        >
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
            <div style={{ height: "900px" }}>
              <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
            </div>
          </Worker>
        </AntModal>

        <AntModal
          title=""
          keyboard={false}
          open={this.state.modalReportShow}
          onCancel={() => {
            this.setState({ modalReportShow: false });
          }}
          cancelButtonProps={{ hidden: false }}
          okButtonProps={{ hidden: true }}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          width={1000}
          centered
        >
          <Row gutter={[16, 16]}>
            <Col md={8}></Col>
            <Col md={8}>
              <h3 className="card-label font-weight-bolder text-dark">
                <FormattedMessage id="ProjectsTreeCompanyDrawer.LEDGERACCOUNT" defaultMessage="LEDGER ACCOUNT" />
              </h3>
            </Col>
          </Row>
          <br />
          <Row gutter={[16, 16]}>
            <Col md={8}>
              <Text style={{ color: "black" }}>
                <FormattedMessage id="CustomerAccount.Customer" defaultMessage="Customer" /> {" : "}
              </Text>
              <Text type="secondary">{Boolean(this.state.reportData) ? this.state.reportData.customer : ""}</Text>
            </Col>
            <Col md={8}>
              <Text style={{ color: "black" }}>
                <FormattedMessage id="CustomerAccount.CRNoTaxNo" defaultMessage="CR no/Tax no" /> {" : "}
              </Text>
              <Text type="secondary">{Boolean(this.state.reportData) ? this.state.reportData.crNoTaxNo : ""}</Text>
            </Col>
          </Row>
          <br />
          <Row gutter={[16, 16]}>
            <Col md={16}>
              <Text style={{ color: "black" }}>
                <FormattedMessage id="CustomerAccount.Address" defaultMessage="Address" /> {" : "}
              </Text>
              <Text type="secondary">{Boolean(this.state.reportData) ? this.state.reportData.address : ""}</Text>
            </Col>
          </Row>
          <br />
          <Row gutter={[16, 16]}>
            <Col md={8}>
              <Text style={{ color: "black" }}>
                <FormattedMessage id="CustomerAccount.Period" defaultMessage="Period" /> {" : "}
              </Text>
              <Text type="secondary">
                {Boolean(this.state.reportData)
                  ? moment(this.state.reportData.minDate).format("DD-MM-YYYY HH:mm") +
                    " - " +
                    moment(this.state.reportData.maxDate).format("DD-MM-YYYY HH:mm")
                  : ""}
              </Text>
            </Col>
            <Col md={8}>
              <Text style={{ color: "black" }}>
                <FormattedMessage id="CustomerAccount.StartingBalance" defaultMessage="Starting Balance" /> {" : "}
              </Text>
              <Text type="secondary">{Boolean(this.state.reportData) ? this.state.reportData.startingBalance : ""}</Text>
            </Col>
          </Row>
          <br />
          <Row gutter={[16, 16]}>
            <Col md={8}>
              <Text style={{ color: "black" }}>
                <FormattedMessage id="CustomerAccount.InvoiceTotal" defaultMessage="Invoice Total" /> {" : "}
              </Text>
              <Text type="secondary">{Boolean(this.state.reportData) ? this.state.reportData.invoiceTotal : ""}</Text>
            </Col>
            <Col md={8}>
              <Text style={{ color: "black" }}>
                <FormattedMessage id="CustomerAccount.PaidAmount" defaultMessage="Paid Amount" /> {" : "}
              </Text>
              <Text type="secondary">{Boolean(this.state.reportData) ? this.state.reportData.paidAmount : ""}</Text>
            </Col>
            <Col md={8}>
              <Text style={{ color: "black" }}>
                <FormattedMessage id="CustomerAccount.OverallBalance" defaultMessage="Overall Balance" /> {" : "}
              </Text>

              <Text type="secondary">{Boolean(this.state.reportData) ? this.state.reportData.overallBalance : ""}</Text>
            </Col>
          </Row>
        </AntModal>

        <Drawer
          title={<FormattedMessage id="CustomerAccount.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"Products"} />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAccount);
