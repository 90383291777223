import "antd/dist/antd.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { showError } from "../../MessageHelper";
import VendorPerformanceChart from "./VendorPerformanceChart";

export class VendorLogPerformance extends Component {
  constructor() {
    super();
    this.state = {
      chartList: [],
      statusList: []
    };
  }

  componentDidMount = async () => {
    let url = "/api/userDashboardChart/all/";
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      showError(response.message);
    } else {
      console.log("userDashboardChart", response.data);
      this.setState({ chartList: response.data });
    }
  };

  fillComboboxes = async () => {};

  render() {
    const contentStyle = {
      height: "160px",
      color: "#fff",
      lineHeight: "160px",
      textAlign: "center",
      background: "#364d79"
    };

    return (
      <div className="row" style={{ padding: "2rem", minHeight: "100%" }}>
        <>
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-body">
              <div className="col-lg-3 mb-3"></div>
              <VendorPerformanceChart />
            </div>
          </div>
        </>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VendorLogPerformance);
