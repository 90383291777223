import "antd/dist/antd.css";
import moment from "moment";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Tooltip, DatePicker } from "antd";
import { EditOutlined, PictureAsPdfOutlined, ReplyOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import DraggableModal from "../../Components/DraggableModal";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import SalesQuotationCreateDetail from "./SalesQuotationDetailCreate";
import SendIcon from "@material-ui/icons/Send";
import { FilterOutlined, ExclamationCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import SalesQuotationDetailReply from "./SalesQuotationDetailReply";
import SalesQuotationDetailEdit from "./SalesQuotationDetailEdit";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";

export class SalesQuotation extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "SalesQuotation",
      controllerName: "salesQuotation",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      typeRevise: false,
      tableList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  formRefResponse = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showAddModal = row => {
    this.setState({
      id: row.id,
      random: Math.random()
    });
  };

  sendModal = row => {
    this.setState({
      id: row.id,
      modalSendToVendorsShow: true
    });
  };

  responseModal = row => {
    this.setState({
      id: row.id,
      modalResponseShow: true
    });
  };

  replyModal = row => {
    this.setState({
      id: row.id,
      modalReplyShow: true,
      random: Math.random(),
      type: row.type
    });
  };

  awardModal = row => {
    this.setState({
      id: row.id,
      modalAwardShow: true
    });
  };

  detailModal = (typeRevise, row) => {
    this.setState({
      modalDetailShow: true,
      id: row.id,
      random: Math.random(),
      typeRevise: typeRevise,
      type: row.type
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.detailModal.bind(this, false)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };
    let reviseAction = {
      name: "Revise",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.detailModal.bind(this, true)
    };
    let sendAction = {
      name: "Send",
      icon: <SendIcon fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.sendModal.bind(this)
    };
    let responseAction = {
      name: "Response",
      icon: <ExclamationCircleOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.responseModal.bind(this)
    };
    let replyAction = {
      name: "Reply",
      icon: <ReplyOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.replyModal.bind(this)
    };
    let awardAction = {
      name: "Award",
      icon: <SendIcon fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.awardModal.bind(this)
    };

    let pdfAction = {
      name: "Pdf",
      icon: <PictureAsPdfOutlined fontSize="small" color="secondary" />,
      actionClick: this.showPdfModal.bind(this)
    };

    let actionList = [];
    if (Boolean(record.status)) {
      if (record.status === "Created") {
        actionList.push(editAction);
        actionList.push(sendAction);
        actionList.push(deleteAction);
      } else if (record.status === "Edit") {
        actionList.push(editAction);
        actionList.push(sendAction);
        actionList.push(deleteAction);
      } else if (record.status === "Revise") {
        actionList.push(editAction);
        actionList.push(sendAction);
        actionList.push(deleteAction);
      } else if (record.status === "Requested") {
        actionList.push(reviseAction);
        actionList.push(responseAction);
        actionList.push(deleteAction);
      } else if (record.status === "Response") {
        actionList.push(reviseAction);
        actionList.push(replyAction);
        actionList.push(deleteAction);
      } else if (record.status === "Reply") {
        actionList.push(awardAction);
        actionList.push(deleteAction);
      }
      actionList.push(pdfAction);
    }

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  showPdfModal = async row => {
    this.setState({ loadingButtonSubmit: true });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/raporCreate/" + row.id);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loadingButtonSubmit: false
      });
    } else if (Boolean(response.data)) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true,
        loadingButtonSubmit: false
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false,
        loadingButtonSubmit: false
      });
      showError("There is no created report for selected item");
    }
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      typeRevise: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  sendToVendors = async () => {
    var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/sendToVendors/" + this.state.id);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({ modalSendToVendorsShow: false });
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  saveResponse = async values => {
    let newObj = {
      ...values
    };
    var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/saveResponse/" + this.state.id, newObj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({ modalResponseShow: false });
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  award = async () => {
    var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/award/" + this.state.id);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({ modalAwardShow: false });
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };

    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="SalesQuotation.QuoteNo" defaultMessage="Quote No" />,
        key: "id",
        render: record => {
          return "Quote-" + record.id;
        },
        ...getColumnFilter({
          inputName: "id",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalesQuotation.Status" defaultMessage="Status" />,
        key: "status",
        render: record => {
          return record.status;
        },
        ...getColumnFilter({
          inputName: "status",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalesQuotation.Date" defaultMessage="Date" />,
        key: "date",
        render: record => {
          return Boolean(record.date) ? moment(record.date).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "date",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalesQuotation.Customer" defaultMessage="Customer" />,
        key: "customerId",
        render: record => {
          return record.customerName;
        },
        ...getColumnFilter({
          inputName: "customerId",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalesQuotation.RevisionNo" defaultMessage="Revision No" />,
        key: "revNo",
        render: record => {
          return record.revNo;
        },
        ...getColumnFilter({
          inputName: "revNo",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalesQuotation.detail" defaultMessage="Detail" />,
        key: "detail",
        render: record => {
          return record.detail;
        },
        ...getColumnFilter({
          inputName: "detail",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalesQuotation.dueDate" defaultMessage="Due Date" />,
        key: "dueDate",
        render: record => {
          return Boolean(record.dueDate) ? moment(record.dueDate).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "dueDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalesQuotation.ReplyDate" defaultMessage="Reply Date" />,
        key: "replyDate",
        render: record => {
          return Boolean(record.replyDate) ? moment(record.replyDate).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "replyDate",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalesQuotation.ResponseDate" defaultMessage="Response Date" />,
        key: "responseDate",
        render: record => {
          return Boolean(record.responseDate) ? moment(record.responseDate).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "responseDate",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalesQuotation.customerReply" defaultMessage="Customer Reply" />,
        key: "customerReply",
        render: record => {
          return record.customerReply;
        },
        ...getColumnFilter({
          inputName: "customerReply",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalesQuotation.decision" defaultMessage="Decision" />,
        key: "decision",
        render: record => {
          return record.decision;
        },
        ...getColumnFilter({
          inputName: "decision",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalesQuotation.awardDate" defaultMessage="Award Date" />,
        key: "awardDate",
        render: record => {
          return Boolean(record.awardDate) ? moment(record.awardDate).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "awardDate",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalesQuotation.totalCost" defaultMessage="Total Cost" />,
        key: "totalCost",
        render: record => {
          return record.totalCost;
        },
        ...getColumnFilter({
          inputName: "totalCost",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
    ];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="SalesQuotation.pageTitle" defaultMessage="Quotation Log" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={23}></Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => {
                    this.setState({
                      showAddModal: true,
                      random: Math.random()
                    });
                  }}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />{" "}
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span>
                      <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span>
                      <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>{" "}
              </div>{" "}
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <DraggableModal
          title={<FormattedMessage id="SalesQuotation.AddNewQuotation" defaultMessage="NEW QUOTATION" />}
          keyboard={false}
          maskClosable={false}
          centered
          open={this.state.showAddModal}
          onCancel={() => {
            this.setState({ showAddModal: false });
            const { pagination } = this.state;
            this.restartTable({ pagination });
          }}
          okButtonProps={{ hidden: true }}
          cancelText={<FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />}
          width={1500}
          content={
            <SalesQuotationCreateDetail
              random={this.state.random}
              close={() => {
                this.setState({ showAddModal: false });
                const { pagination } = this.state;
                this.restartTable({ pagination });
              }}
            />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="SalesQuotation.Detail" defaultMessage="Detail" />}
          keyboard={false}
          maskClosable={false}
          centered
          open={this.state.modalDetailShow}
          onCancel={() => {
            this.setState({ modalDetailShow: false });
            const { pagination } = this.state;
            this.restartTable({ pagination });
          }}
          okButtonProps={{ hidden: true }}
          cancelText={<FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />}
          width={1500}
          content={
            <SalesQuotationDetailEdit
              random={this.state.random}
              salesQuotationId={this.state.id}
              typeRevise={this.state.typeRevise}
              type={this.state.type}
              close={() => {
                this.setState({ modalDetailShow: false });
                const { pagination } = this.state;
                this.restartTable({ pagination });
              }}
            />
          }
        />

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="SalesQuotation.Send" defaultMessage="Send" />
              </span>
            </span>
          }
          centered
          open={this.state.modalSendToVendorsShow}
          onOk={() => this.sendToVendors()}
          onCancel={() => this.setState({ modalSendToVendorsShow: false })}
          okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <>
              <FormattedMessage id="SalesQuotation.MessageSendToVendors" defaultMessage="Do you really want to send this Sales Quotation ?" />
            </>
          }
        />

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="SalesQuotation.Response" defaultMessage="Response" />
              </span>
            </span>
          }
          centered
          open={this.state.modalResponseShow}
          onOk={() => this.formRefResponse.current.submit()}
          onCancel={() => this.setState({ modalResponseShow: false })}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveResponse} ref={this.formRefResponse}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="SalesQuotation.ResponseDate" defaultMessage="Response Date" />}
                name="responseDate"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <DatePicker showTime format="DD-MM-YYYY HH:mm" style={{ width: "100%" }} />
              </Form.Item>
            </Form>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="SalesQuotation.Reply" defaultMessage="Reply" />}
          keyboard={false}
          maskClosable={false}
          centered
          open={this.state.modalReplyShow}
          onCancel={() => {
            this.setState({ modalReplyShow: false });
            const { pagination } = this.state;
            this.restartTable({ pagination });
          }}
          okButtonProps={{ hidden: true }}
          cancelText={<FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />}
          width={1500}
          content={
            <SalesQuotationDetailReply
              random={this.state.random}
              salesQuotationId={this.state.id}
              type={this.state.type}
              close={() => {
                this.setState({ modalReplyShow: false });
                const { pagination } = this.state;
                this.restartTable({ pagination });
              }}
            />
          }
        />

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="SalesQuotation.Award" defaultMessage="Award" />
              </span>
            </span>
          }
          centered
          open={this.state.modalAwardShow}
          onOk={() => this.award()}
          onCancel={() => this.setState({ modalAwardShow: false })}
          okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <>
              <FormattedMessage id="SalesQuotation.MessageSendToVendors" defaultMessage="Do you really want to award ?" />
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="GeneralPDFReportViewer" defaultMessage="PDF Report Viewer" />}
          open={this.state.modalPdfViewer}
          onCancel={() => {
            this.setState({ modalPdfViewer: false });
          }}
          cancelButtonProps={{ hidden: false }}
          okButtonProps={{ hidden: true }}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          width={1200}
          centered
          content={
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>
          }
        />
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SalesQuotation);
