import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined, ReplyOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../../excel/ExcelReader";
import { excelExport } from "../../../ExcelHelper";
import { Select, Drawer } from "antd";
import { DatePicker } from "antd";
import { Input } from "antd";
import moment from "moment";
import SendIcon from "@material-ui/icons/Send";
import { ScheduleTwoTone } from "@ant-design/icons";
import DraggableModal from "../../../Components/DraggableModal";
import BookCreatorItem from "./BookCreatorItem";
import BookCreatorReference from "./BookCreatorReference";

const { Option } = Select;

export class BookCreator extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "BookCreator",
      controllerName: "bookCreator",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      distrubitions: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responsereportTypeId = await handleRequest("GET", "/api/" + this.state.controllerName + "/reportTypeId");
    if (responsereportTypeId.type === "ERROR") {
      error(responsereportTypeId);
    }
    this.setState({
      reportTypeIdSelectItems: Boolean(responsereportTypeId.data) ? responsereportTypeId.data : []
    });
    var responsepersonToPrepareId = await handleRequest("GET", "/api/" + this.state.controllerName + "/personToPrepareId");
    if (responsepersonToPrepareId.type === "ERROR") {
      error(responsepersonToPrepareId);
    }
    this.setState({
      personToPrepareIdSelectItems: Boolean(responsepersonToPrepareId.data) ? responsepersonToPrepareId.data : []
    });
    var responsereviewId = await handleRequest("GET", "/api/" + this.state.controllerName + "/reviewId");
    if (responsereviewId.type === "ERROR") {
      error(responsereviewId);
    }
    this.setState({
      reviewIdSelectItems: Boolean(responsereviewId.data) ? responsereviewId.data : []
    });
    var responseapprovalId = await handleRequest("GET", "/api/" + this.state.controllerName + "/approvalId");
    if (responseapprovalId.type === "ERROR") {
      error(responseapprovalId);
    }
    this.setState({
      approvalIdSelectItems: Boolean(responseapprovalId.data) ? responseapprovalId.data : []
    });
    var responsedistributionId = await handleRequest("GET", "/api/" + this.state.controllerName + "/distributionId");
    if (responsedistributionId.type === "ERROR") {
      error(responsedistributionId);
    }
    this.setState({
      distributionIdSelectItems: Boolean(responsedistributionId.data) ? responsedistributionId.data : []
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    var personToPrepareId = [];
    if (Boolean(row.prepares) && row.prepares.length > 0) {
      row.prepares.forEach(element => {
        personToPrepareId.push(element.personToPrepareId);
      });
    }

    var reviewId = [];
    if (Boolean(row.reviews) && row.reviews.length > 0) {
      row.reviews.forEach(element => {
        reviewId.push(element.reviewId);
      });
    }

    var approvalId = [];
    if (Boolean(row.approvals) && row.approvals.length > 0) {
      row.approvals.forEach(element => {
        approvalId.push(element.approvalId);
      });
    }

    var distributionId = [];
    if (Boolean(row.distributions) && row.distributions.length > 0) {
      row.distributions.forEach(element => {
        distributionId.push(element.distributionId);
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      startDate: Boolean(row.startDate) ? moment(row.startDate) : null,
      submissionDate: Boolean(row.submissionDate) ? moment(row.submissionDate) : null,
      personToPrepareId: personToPrepareId,
      reviewId: reviewId,
      approvalId: approvalId,
      distributionId: distributionId,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };

    let addReferenceAction = {
      name: "Add References",
      icon: <ReplyOutlined fontSize="small" color="secondary" />,
      actionClick: this.showAddReferenceModal.bind(this)
    };

    let bookAction = {
      name: "Book",
      icon: <ReplyOutlined fontSize="small" color="secondary" />,
      actionClick: this.showBookModal.bind(this)
    };

    let sendAction = {
      name: "Send",
      icon: <SendIcon fontSize="small" color="secondary" />,
      actionClick: this.showSendModal.bind(this)
    };

    let pdfAction = {
      name: "Pdf",
      icon: <ReplyOutlined fontSize="small" color="secondary" />,
      actionClick: this.showPdfModal.bind(this)
    };

    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(addReferenceAction);

    actionList.push(pdfAction);
    actionList.push(sendAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  showAddReferenceModal = row => {
    this.setState({
      modalAddReferenceShow: true,
      id: row.id,
      random: Math.random(),
    });
  };
  showBookModal = row => {
    this.setState({
      modalBookShow: true,
      id: row.id,
      random: Math.random(),
    });
  };

  showPdfModal = async (row) => {
    this.setState({ loadingButtonSubmit: true });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/raporCreate/" + row.id);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loadingButtonSubmit: false
      });
    } else if (Boolean(response.data) && Boolean(response.data.url)) {
      this.setState({
        pdfFile: response.data.url,
        modalPdfViewer: true,
        loadingButtonSubmit: false
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false,
        loadingButtonSubmit: false
      });
      showError("There is no created report for selected item");
    }
  };

  showSendModal = row => {
    this.setState({
      modalSendShow: true,
      id: row.id
    });
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };

    let prepares = [];
    if (newItem.personToPrepareId && newItem.personToPrepareId.length > 0)
      newItem.personToPrepareId.forEach(element => {
        prepares.push({ personToPrepareId: element });
      });
    newItem.prepares = prepares;

    let reviews = [];
    if (newItem.reviewId && newItem.reviewId.length > 0)
      newItem.reviewId.forEach(element => {
        reviews.push({ reviewId: element });
      });
    newItem.reviews = reviews;

    let approvals = [];
    if (newItem.approvalId && newItem.approvalId.length > 0)
      newItem.approvalId.forEach(element => {
        approvals.push({ approvalId: element });
      });
    newItem.approvals = approvals;

    let distributions = [];
    if (newItem.distributionId && newItem.distributionId.length > 0)
      newItem.distributionId.forEach(element => {
        distributions.push({ distributionId: element });
      });
    newItem.distributions = distributions;

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  onClickDistrubitionModal = row => {
    this.setState({
      distrubitions: row.distributions,
      modalDistrubitionShow: true
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };

    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="BookCreator.ReportName" defaultMessage="Report Name" />,
        key: "name",
        render: record => {
          return record.name;
        }, sorter: false
      },
      {
        title: <FormattedMessage id="BookCreator.Status" defaultMessage="Status" />,
        key: "status",
        render: record => {
          return record.status;
        }, sorter: false
      },
      {
        title: <FormattedMessage id="BookCreator.ReportType" defaultMessage="Report Type" />,
        key: "reportTypeName",
        render: record => {
          return record.reportTypeName;
        }, sorter: false
      },
      {
        title: <FormattedMessage id="BookCreator.StartDate" defaultMessage="Start Date" />,
        key: "startDate",
        render: record => {
          return Boolean(record.startDate) ? moment(record.startDate).format("DD-MM-YYYY") : "";
        }, sorter: false
      },
      {
        title: <FormattedMessage id="BookCreator.SubmissionDate" defaultMessage="Submission Date" />,
        key: "submissionDate",
        render: record => {
          return Boolean(record.submissionDate) ? moment(record.submissionDate).format("DD-MM-YYYY") : "";
        }, sorter: false
      },
      {
        title: <FormattedMessage id="BookCreator.PreparationBodies" defaultMessage="Preparation Bodies" />,
        key: "preparationBodies",
        render: record => {
          return record.preparationBodies;
        }, sorter: false
      },
      {
        title: <FormattedMessage id="BookCreator.Distrubition" defaultMessage="Distrubition" />,
        key: "distrubitionName",
        render: record => {
          return (
            <div>
              <ScheduleTwoTone style={{ fontSize: 25 }} onClick={() => this.onClickDistrubitionModal(record)} />
            </div>
          );
        }
      },
    ];

    const columnsDistrubitions = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="RoomApplication.Distribution" defaultMessage="Distribution" />,
        key: "id",
        render: record => {
          return record.distributionName;
        },
      },
    ];

    const columnsExcel = [{ header: "Report Name", key: "name" },
    { header: "Status", key: "status" },
    { header: "Report Type", key: "reportTypeName" },
    { header: "Start Date", key: "startDate" },
    { header: "Submission Date", key: "submissionDate" },
    { header: "Preparation Bodies", key: "preparationBodies" },
    { header: "Distrubition", key: "distrubitionName" },
    ];

    return (
      <Badge.Ribbon text={<FormattedMessage id="bookCreator.pageTitle" defaultMessage="Auto Report" />} placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={21}></Col>
              <Col md={1}>                   <AntButton style={{ border: "0px" }} onClick={() => { excelExport(columnsExcel, this.state.tableList) }} icon={<ExportIcon color="action" fontSize="large" />} />                 </Col>
              <Col md={1}><ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader></Col>
              <Col md={1}>                	<AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={this.onClickCreateNew} icon={<CreateIcon color="secondary" fontSize="large" />}> 	</AntButton>                	<AntButton style={{ border: "0px" }} hidden={this.state.hideInputs} onClick={this.onClickCreateNew} icon={<RemoveCircle color="error" fontSize="large" />}></AntButton> 	</Col>
            </Row>
            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BookCreator.ReportName" defaultMessage="Report Name" />}
                  name="name"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              <Form.Item
                {...layout}
                label={<FormattedMessage id="BookCreator.ReportType" defaultMessage="Report Type" />}
                name="reportTypeId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                >
                  {Boolean(this.state["reportTypeIdSelectItems"]) && this.state["reportTypeIdSelectItems"].length > 0 &&
                    this.state["reportTypeIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BookCreator.startDate" defaultMessage="Start Date" />}
                  name="startDate"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BookCreator.submissionDate" defaultMessage="Submission Date" />}
                  name="submissionDate"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              }
              <Form.Item
                {...layout}
                label={<FormattedMessage id="BookCreator.personToPrepareId" defaultMessage="Person To Prepare" />}
                name="personToPrepareId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}>
                <Select
                  allowClear
                  mode="multiple" style={{ width: "100%" }}
                  optionFilterProp="children" >
                  {Boolean(this.state["personToPrepareIdSelectItems"]) && this.state["personToPrepareIdSelectItems"].length > 0 &&
                    this.state["personToPrepareIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}> {i.value} </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="BookCreator.reviewId" defaultMessage="Review" />}
                name="reviewId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}>
                <Select
                  allowClear
                  mode="multiple" style={{ width: "100%" }}
                  optionFilterProp="children" >
                  {Boolean(this.state["reviewIdSelectItems"]) && this.state["reviewIdSelectItems"].length > 0 &&
                    this.state["reviewIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}> {i.value} </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="BookCreator.approvalId" defaultMessage="Approval" />}
                name="approvalId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}>
                <Select
                  allowClear
                  mode="multiple" style={{ width: "100%" }}
                  optionFilterProp="children" >
                  {Boolean(this.state["approvalIdSelectItems"]) && this.state["approvalIdSelectItems"].length > 0 &&
                    this.state["approvalIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}> {i.value} </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="BookCreator.distributionId" defaultMessage="Distribution" />}
                name="distributionId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}>
                <Select
                  allowClear
                  mode="multiple" style={{ width: "100%" }}
                  optionFilterProp="children" >
                  {Boolean(this.state["distributionIdSelectItems"]) && this.state["distributionIdSelectItems"].length > 0 &&
                    this.state["distributionIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}> {i.value} </Option>
                    ))}
                </Select>
              </Form.Item>
              <div hidden={this.state.hideSave}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>                     <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">                       <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
              <div hidden={this.state.hideUpdate}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>                       <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />                     </Button>                   </Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">                       <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
            </div>
          </Form>
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">           <Table bordered scroll={{ x: 900, scrollToFirstRowOnChange: true }} columns={columns} dataSource={this.state.tableList} loading={this.state.loading} onChange={this.handleTableChange} pagination={this.state.pagination} />         </div>
          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>           <Modal.Header closeButton>             <Modal.Title id="contained-modal-title-vcenter">               <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />             </Modal.Title>           </Modal.Header>           <Modal.Body>             <p>               <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />             </p>           </Modal.Body>           <Modal.Footer>             <Button variant="secondary" onClick={this.showOrHideDeleteModal}>               <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />             </Button>             <Button variant="danger" onClick={this.delete}>               <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />             </Button>           </Modal.Footer>         </Modal>

          <DraggableModal
            title={<FormattedMessage id="BookCreator.Distrubition" defaultMessage="Distrubition" />}
            open={this.state.modalDistrubitionShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ modalDistrubitionShow: false })}
            width={800}
            centered
            content={
              <div className="card card-custom" style={{ minHeight: "100%" }}>
                <Table
                  bordered
                  size="small"
                  columns={columnsDistrubitions}
                  dataSource={this.state.distrubitions}
                  loading={this.state.loading}
                  pagination={false}
                />
              </div>
            }
          />

          <Drawer
            title={<FormattedMessage id="BookCreator.Book" defaultMessage="Book" />}
            width={1500} closable={true}
            onClose={() => {
              if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
                this.formRef.current.resetFields();
              }
              this.setState({ modalBookShow: false });
            }}
            open={this.state.modalReportShow}>
            <BookCreatorItem random={this.state.random} bookCreatorId={this.state.id} type={this.state.type} />
          </Drawer>

          <Drawer
            title={<FormattedMessage id="BookCreator.Reference" defaultMessage="References" />}
            width={1500} closable={true}
            onClose={() => {
              if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
                this.formRef.current.resetFields();
              }
              this.setState({ modalAddReferenceShow: false });
            }}
            open={this.state.modalReportShow}>
            <BookCreatorReference random={this.state.random} bookCreatorId={this.state.id} type={this.state.type} />
          </Drawer>



          <DraggableModal
            title={<FormattedMessage id="GeneralPDFReportViewer" defaultMessage="PDF Report Viewer" />}
            open={this.state.modalPdfViewer}
            onCancel={() => {
              this.setState({ modalPdfViewer: false });
            }}
            cancelButtonProps={{ hidden: false }}
            okButtonProps={{ hidden: true }}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            width={1200}
            centered
            content={
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                <div style={{ height: "900px" }}>
                  <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                </div>
              </Worker>
            }
          />

        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BookCreator);