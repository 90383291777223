import React, { useState, useEffect } from "react";
import { handleRequest } from "../../ApiConnector";
import { error } from "../../MessageHelper";
import { Table, Col, Row, InputNumber, Select } from "antd";
import { FormattedMessage } from "react-intl";

const { Option } = Select;

const QualityControlResultsDetail = () => {
  const [tableList, setTableList] = useState([]);

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = async () => {
    const search = {
    };
    var response = await handleRequest("POST", "/api/dashboard/qualityControlResultDetails", search);
    if (response.type === "ERROR") {
      error(response);
    } else {
      setTableList(Boolean(response.data) ? response.data : null);
    }
  };

  const renderBody = (props, columns) => {
    return (
      <tr className={props.className}>
        {columns.map((item, idx) => {
          return props.children[idx];
        })}
      </tr>
    );
  };
  const renderHeader = (props, columns) => {
    return (
      <tr>
        {/* {columns.map((item, idx) => {
          if (item.visible)
            return props.children[idx];
        })} */}
      </tr>
    );
  };

  const columns = [
    {
      title: "",
      key: "type",
      render: record => {
        return record.type;
      },
    },
    {
      title: "",
      key: "title1",
      render: record => {
        return record.title1;
      },
    },
    {
      title: "",
      key: "title2",
      render: record => {
        return record.title2;
      },
    },
    {
      title: "",
      key: "title3",
      render: record => {
        return record.title3;
      },
    },
  ];

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={6}></Col>
        <Col lg={6}>
          <Select
            defaultActiveFirstOption
            allowClear
            className="w-100"
          >
            <Option key={"Last"} value={"Last"}>
              <FormattedMessage id="period.Last" defaultMessage="Last" />
            </Option>
            <Option key={"Overall"} value={"Overall"}>
              <FormattedMessage id="period.Overall" defaultMessage="Overall" />
            </Option>
          </Select>
        </Col>
        <Col lg={6}>
          <InputNumber />
        </Col>
        <Col lg={6}>
          <Select
            defaultActiveFirstOption
            allowClear
            className="w-100"
          >
            <Option key={"WEEKLY"} value={"WEEKLY"}>
              <FormattedMessage id="period.Week" defaultMessage="Week" />
            </Option>
            <Option key={"MONTHLY"} value={"MONTHLY"}>
              <FormattedMessage id="period.month" defaultMessage="Month" />
            </Option>
            <Option key={"YEARLY"} value={"YEARLY"}>
              <FormattedMessage id="period.year" defaultMessage="Year" />
            </Option>
          </Select>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={24}>
          <Table
            bordered
            columns={columns}
            dataSource={tableList}
            pagination={false}
            components={{
              header: {
                row: (props) => renderHeader(props, columns),
              },
              body: {
                row: (props) => renderBody(props, columns),
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default QualityControlResultsDetail;
