import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Input, Tree, Button as AntButton, Form, Modal as AntModal, Select, Badge, Space, InputNumber } from "antd";
import { FcFolder, FcEditImage } from "react-icons/fc";
import { Menu, Item, useContextMenu } from "react-contexify";
import { FcTreeStructure, FcBiotech, FcMediumPriority, FcLibrary } from "react-icons/fc";
import { MdDelete } from "react-icons/md";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../ApiConnector";
import { showError, showSuccess, error } from "../../MessageHelper";
import { Button, Table as TableBoot } from "react-bootstrap";
import { PlusOutlined } from "@ant-design/icons";

const Menu_Project = "Menu_Project";
const Menu_Location = "Menu_Location";
const Menu_Service = "Menu_Service";
const Menu_Activity = "Menu_Activity";
const Menu_Material = "Menu_Material";

const { Option } = Select;
//const { TextArea } = Input;

const { show } = useContextMenu();

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

export class ProjectPlanning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "ProjectPlanning",
      controllerName: "projectPlanning",
      id: null,
      description: "",
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      loadedKeys: [],
      searchValue: "",
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showServiceModal: false,
      refreshTree: true
    };
  }

  inputRef = React.createRef(null);

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "Page"
    };

    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      if (treenode.type === "Location") {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsProjectPlanningLocation/" + obj.id);
      } else if (treenode.type === "Service") {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsProjectPlanningService/" + obj.id);
      } else if (treenode.type === "Activity") {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsProjectPlanningActivity/" + obj.id);
      } else if (treenode.type === "Material") {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsProjectPlanningMaterial/" + obj.id);
      } else {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitems/" + obj.id);
      }
    }
    if (Boolean(response) && response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.icon =
          Boolean(element.type) && element.type === "Location" ? (
            <FcLibrary />
          ) : element.type === "Location" ? (
            <FcTreeStructure />
          ) : element.type === "Service" ? (
            <FcBiotech />
          ) : element.type === "Activity" ? (
            <FcTreeStructure />
          ) : element.type === "Material" ? (
            <FcMediumPriority />
          ) : element.type === "Coordinates" ? (
            <FcTreeStructure />
          ) : (
            <FcFolder />
          );

        element.title = element.value;
        element.id = element.key;
        element.key = element.key + "-" + element.type;
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      this.setState({
        nodes: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  formRef = React.createRef();
  formRefOnClickName = React.createRef();
  formRefCreateLocation = React.createRef();
  formRefCreateActivity = React.createRef();
  formRefCreateMaterial = React.createRef();
  formRefCreateService = React.createRef();
  formRefCreateCoordinates = React.createRef();

  componentDidMount = async () => {
    this.fillTree();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseactivityIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/activityId");
    if (responseactivityIdSelectItems.type === "ERROR") {
      error(responseactivityIdSelectItems);
    }
    this.setState({
      activityIdSelectItems: Boolean(responseactivityIdSelectItems.data) ? responseactivityIdSelectItems.data : []
    });

    var responseserviceIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/serviceId");
    if (responseserviceIdSelectItems.type === "ERROR") {
      error(responseserviceIdSelectItems);
    }
    this.setState({
      serviceIdSelectItems: Boolean(responseserviceIdSelectItems.data) ? responseserviceIdSelectItems.data : []
    });

    var responsematerialCategoryIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/materialCategoryId");
    if (responsematerialCategoryIdSelectItems.type === "ERROR") {
      error(responsematerialCategoryIdSelectItems);
    }
    this.setState({
      materialCategoryIdSelectItems: Boolean(responsematerialCategoryIdSelectItems.data) ? responsematerialCategoryIdSelectItems.data : []
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "CreateLocation";
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  onRightClick = (event, node) => {
    let parentNode = this.getParentNode(node);
    this.setState(
      {
        selectedNodeKey: node.key,
        selectedNode: node,
        parentNode: parentNode
      },
      this.displayMenu(event, Boolean(node) && Boolean(node.type) ? node.type : null)
    );
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  handleItemClick = ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;

    console.log("AAA", event.currentTarget.id);
    console.log(selectedNode);
    console.log(parentNode);
    console.log(key);

    // eslint-disable-next-line default-case
    switch (event.currentTarget.id) {
      case "create_location":
        this.setState({
          showCreateLocationModal: true,
          parentId: selectedNode.id,
          projectPlanningProjectId: selectedNode.id,
          locationId: "",
          type: "Location"
        });
        break;

      case "create_coordinates":
        this.setState({
          showCreateCoordinatesModal: true,
          parentId: selectedNode.id,
          projectPlaningLocationId: selectedNode.id,
          connectionTypeId: "",
          pointId: "",
          Nquantity: "",
          Equantity: "",
          Hquantity: "",
          type: "Coordinates"
        });
        break;

      case "create_service":
        this.setState({
          showCreateServiceModal: true,
          parentId: selectedNode.id,
          projectPlaningLocationId: selectedNode.id,
          serviceId: "",
          type: "Service"
        });
        break;

      case "create_activity":
        this.setState({
          showCreateActivityModal: true,
          parentId: selectedNode.id,
          projectPlaningLocationId: selectedNode.id,
          projectPlaningServiceId: selectedNode.id,
          activityId: "",
          type: "Activity"
        });
        break;

      case "create_material":
        this.setState({
          showCreateMaterialModal: true,
          parentId: selectedNode.id,
          projectPlaningActivityId: selectedNode.id,
          materialCategoryId: "",
          quantity: "",
          type: "Material"
        });
        break;

      case "rename":
        this.setState({
          showCreateModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        });
        break;

      case "renameProjectPlanningLocation":
        this.setState({
          showCreateLocationModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        });
        break;

      case "renameProjectPlanningService":
        this.setState({
          showCreateServiceModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        });
        break;

      case "renameProjectPlanningActivity":
        this.setState({
          showCreateActivityModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        });
        break;

      case "renameProjectPlanningMaterial":
        this.setState(
          {
            showRenameMaterialModal: true,
            id: selectedNode.id,
            parentId: Boolean(parentNode) ? parentNode.id : null,
            type: selectedNode.type
          },
          () => {
            // this.formRefCreateActivity.current.setFieldsValue({
            //   activityId: selectedNode.id
            // });
          }
        );
        break;

      case "delete":
        this.setState({
          showDeleteModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;

      case "deleteProjectPlanningLocation":
        this.setState({
          showDeleteLocationModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;
      case "deleteProjectPlanningService":
        this.setState({
          showDeleteServiceModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;
      case "deleteProjectPlanningActivity":
        this.setState({
          showDeleteActivityModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;
      case "deleteProjectPlanningMaterial":
        this.setState({
          showDeleteMaterialModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;
      case "deleteProjectPlanningCoordinates":
        this.setState({
          showDeleteCoordinatesModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;

      case "save":
        this.generatePage(selectedNode.id);
        break;
    }
  };

  generatePage = async pageAutoDesignId => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/generatePage/" + pageAutoDesignId);
    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
    }
  };

  // DELETE   DELETE    DELETE

  deleteProjectPlanningLocation = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteProjectPlanningLocation/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteLocationModal: false
        });
      }
    } else {
      showError("Location not found.");
    }
  };

  deleteProjectPlanningService = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteProjectPlanningService/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteServiceModal: false
        });
      }
    } else {
      showError("Service not found.");
    }
  };

  deleteProjectPlanningActivity = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteProjectPlanningActivity/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteActivityModal: false
        });
      }
    } else {
      showError("Activity not found.");
    }
  };

  deleteProjectPlanningMaterial = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteProjectPlanningMaterial/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteMaterialModal: false
        });
      }
    } else {
      showError("Material not found.");
    }
  };

  deleteProjectPlanningCoordinates = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteProjectPlanningCoordinates/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteCoordinatesModal: false
        });
      }
    } else {
      showError("Coordinates not found.");
    }
  };

  displayMenu = (e, type) => {
    console.log("TTT", type);
    if (Boolean(type)) {
      if (type === "Project") {
        show(e, { id: Menu_Project });
      } else if (type === "Location") {
        show(e, { id: Menu_Location });
      } else if (type === "Service") {
        show(e, { id: Menu_Service });
      } else if (type === "Activity") {
        show(e, { id: Menu_Activity });
      } else if (type === "Material") {
        show(e, { id: Menu_Material });
      }
    }
  };

  // SAVE UPDATE   SAVE UPDATE  SAVE UPDATE   SAVE UPDATE   SAVE UPDATE

  saveProjectPlanningLocation = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      projectPlanningProjectId: this.state.projectPlanningProjectId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveProjectPlanningLocation", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateProjectPlanningLocation/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showCreateLocationModal: false
      });
    }
  };

  saveProjectPlanningService = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      projectPlaningLocationId: this.state.projectPlaningLocationId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveProjectPlanningService", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateProjectPlanningService/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showRenameCompoundIdentificationModal: false
      });
    }
  };

  saveProjectPlanningActivity = async values => {
    const { process } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      projectPlaningLocationId: this.state.projectPlaningLocationId
    };
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveProjectPlanningActivity", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateProjectPlanningActivity/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
    }
  };

  saveProjectPlanningMaterial = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      projectPlaningActivityId: this.state.projectPlaningActivityId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveProjectPlanningMaterial", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateProjectPlanningMaterial/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
    }
  };

  saveProjectPlanningCoordinates = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      projectPlaningLocationId: this.state.projectPlaningLocationId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveProjectPlanningCoordinates", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateProjectPlanningCoordinates/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showCreateActivityModal: false
      });
    }
  };

  saveProjectPlanningActivityFromService = async values => {
    const { process } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      projectPlaningServiceId: this.state.projectPlaningServiceId
    };
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveProjectPlanningActivity", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateProjectPlanningActivity/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
    }
  };

  saveProjectPlanningActivityFromActivity = async values => {
    const { process } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId
    };
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveProjectPlanningActivity", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateProjectPlanningActivity/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
    }
  };

  saveOnClickName = async values => {
    if (Boolean(this.state.pageAutoDesignId) && this.state.pageAutoDesignId > 0) {
      const newItem = { ...values, id: this.state.id };
      newItem.id = this.state.pageAutoDesignId;
      newItem.autoDesignOnClick = this.state.autoDesignOnClick;
      newItem.type = this.state.type;

      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveOnClickName", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess();
        this.setState({ showOnClickNameModal: false });
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }
    } else {
      showError("Compounds Form not found");
    }
  };

  paste = () => {
    this.formRef.current.submit();
    this.formRefCreateLocation.current.submit();
    this.formRefCreateActivity.current.submit();
    this.formRefCreateMaterial.current.submit();
    this.formRefCreateService.current.submit();
    this.formRefCreateCoordinates.current.submit();
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      description: "",
      code: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      process: null,
      showOrderModal: false,
      showPasteModal: false,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showServiceModal: false
    });
  };

  expandSelectedNode = () => {
    let key = this.state.selectedNodeKey;
    let node = this.state.selectedNode;
    if (Boolean(key)) {
      let expandedKeys = Boolean(this.state.expandedKeys) ? this.state.expandedKeys : [];
      if (Boolean(expandedKeys.find(p => p === key))) {
        this.loadData(node, false);
      } else {
        this.setState({
          expandedKeys: [...expandedKeys, key]
        });
        this.loadData(node, false);
      }
    }
  };

  itemTemplate(item) {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <h5 className="mb-2">{item.title}</h5>
        </div>
      </div>
    );
  }

  onDragEnter = info => {};

  onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...this.state.nodes];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 &&
      // Has children
      info.node.props.expanded &&
      // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    this.setState({
      nodes: data
    });
  };

  closeFormModal = () => {
    this.setState({
      showFormModal: false
    });
  };

  closeTableModal = () => {
    this.setState({
      showTableModal: false
    });
  };

  closeInputFormatModal = () => {
    this.setState({
      showInputFormatModal: false
    });
  };

  closeTableFormatModal = () => {
    this.setState({
      showTableFormatModal: false
    });
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <Badge.Ribbon
        text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage={"Project Planning "} />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <br />
          <br />

          {this.state.refreshTree && (
            <Tree
              style={{ marginTop: "10px", lineHeight: "30px" }}
              className="draggable-tree"
              showLine={{ showLeafIcon: false }}
              showIcon
              checkStrictly={true}
              loadedKeys={this.state.loadedKeys}
              onLoad={this.onLoadData}
              loadData={this.loadData}
              onRightClick={({ event, node }) => this.onRightClick(event, node)}
              onExpand={this.onExpand}
              expandedKeys={this.state.expandedKeys}
              autoExpandParent={false}
              treeData={this.state.nodes}
              blockNode={true}
              // draggable
              onDragEnter={this.onDragEnter}
              onDrop={this.onDrop}
            />
          )}
          {/* MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU */}
          <Menu id={Menu_Project}>
            <Item id="create_location" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="ProjectPlanning.AddLocation" defaultMessage="Add Location" />
                </span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Location}>
            <Item id="create_coordinates" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="ProjectPlanning.Cordinates" defaultMessage="Coordinates" />
                </span>
              </Space>
            </Item>
            <Item id="create_service" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="ProjectPlanning.AddService" defaultMessage="Add Service" />
                </span>
              </Space>
            </Item>
            <Item id="create_activity" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="ProjectPlanning.CreateActivity" defaultMessage="Create Activity" />
                </span>
              </Space>
            </Item>
            <Item id="renameProjectPlanningLocation" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="ProjectPlanning.rename" defaultMessage="Rename" />
                </span>
              </Space>
            </Item>
            <Item id="deleteProjectPlanningLocation" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="ProjectPlanning.delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Service}>
            <Item id="create_activity" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="ProjectPlanning.CreateActivity" defaultMessage="Create Activity" />
                </span>
              </Space>
            </Item>
            <Item id="renameProjectPlanningService" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="ProjectPlanning.rename" defaultMessage="Rename" />
                </span>
              </Space>
            </Item>
            <Item id="deleteProjectPlanningService" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="ProjectPlanning.delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Activity}>
            <Item id="create_activity" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="ProjectPlanning.AddActivity" defaultMessage="Add Activity" />
                </span>
              </Space>
            </Item>
            <Item id="create_material" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="ProjectPlanning.AddMaterial" defaultMessage="Add Material" />
                </span>
              </Space>
            </Item>
            <Item id="renameProjectPlanningActivity" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="ProjectPlanning.rename" defaultMessage="Rename" />
                </span>
              </Space>
            </Item>
            <Item id="deleteProjectPlanningActivity" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="ProjectPlanning.delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Material}>
            <Item id="renameProjectPlanningMaterial" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="ProjectPlanning.rename" defaultMessage="Rename" />
                </span>
              </Space>
            </Item>
            <Item id="deleteProjectPlanningMaterial" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="ProjectPlanning.delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>

          {/* TABLE MODAL   TABLE MODAL   TABLE MODAL   TABLE MODAL */}

          {/* CREATE LOCATİON */}
          <AntModal
            title={<FormattedMessage id="ProjectPlanning.CreateLocation" defaultMessage="Create Location" />}
            open={this.state.showCreateLocationModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showCreateLocationModal: false })}
            onOk={() => this.formRefCreateLocation.current.submit()}
            width={1300}
            centered
          >
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveProjectPlanningLocation}
              onFinishFailed={onFinishFailed}
              ref={this.formRefCreateLocation}
            >
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProjectPlanning.Location" defaultMessage="Location" />}
                  name="location"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
            </Form>
          </AntModal>
          {/* CREATE ACTİVİTY */}
          <AntModal
            title={<FormattedMessage id="ProjectPlanning.CreateActivity" defaultMessage="Create Activity" />}
            open={this.state.showCreateActivityModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showCreateActivityModal: false })}
            onOk={() => this.formRefCreateActivity.current.submit()}
            width={1300}
            centered
          >
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveProjectPlanningActivity}
              onFinishFailed={onFinishFailed}
              ref={this.formRefCreateActivity}
            >
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProjectPlanning.Activity" defaultMessage="Activity" />}
                  name="activityId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["activityIdSelectItems"]) &&
                      this.state["activityIdSelectItems"].length > 0 &&
                      this.state["activityIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
            </Form>
          </AntModal>
          {/* CREATE SERVICE */}
          <AntModal
            title={<FormattedMessage id="ProjectPlanning.CreateService" defaultMessage="Create Service" />}
            open={this.state.showCreateServiceModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showCreateServiceModal: false })}
            onOk={() => this.formRefCreateService.current.submit()}
            width={1300}
            centered
          >
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveProjectPlanningService}
              onFinishFailed={onFinishFailed}
              ref={this.formRefCreateService}
            >
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProjectPlanning.Service" defaultMessage="Service" />}
                  name="serviceId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["serviceIdSelectItems"]) &&
                      this.state["serviceIdSelectItems"].length > 0 &&
                      this.state["serviceIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
            </Form>
          </AntModal>
          {/* CREATE MATERIAL */}
          <AntModal
            title={<FormattedMessage id="ProjectPlanning.AddMaterial" defaultMessage="Add Material" />}
            open={this.state.showCreateMaterialModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showCreateMaterialModal: false })}
            onOk={() => this.formRefCreateMaterial.current.submit()}
            width={1300}
            centered
          >
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveProjectPlanningMaterial}
              onFinishFailed={onFinishFailed}
              ref={this.formRefCreateMaterial}
            >
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProjectPlanning.MaterialCategory" defaultMessage="Material Category" />}
                  name="materialCategoryId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["materialCategoryIdSelectItems"]) &&
                      this.state["materialCategoryIdSelectItems"].length > 0 &&
                      this.state["materialCategoryIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProjectPlanning.Quantity" defaultMessage="Quantity" />}
                  name="quantity"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              }
            </Form>
          </AntModal>
          {/* CREATE COORDINATES */}
          <AntModal
            title={
              <Badge.Ribbon
                text={<FormattedMessage id="OuterLayoutHouseType.pageTitle" defaultMessage="Apartments And Types" />}
                placement="start"
                color="volcano"
                style={{ fontSize: 12 }}
              ></Badge.Ribbon>
            }
            closable="false"
            open={this.state.OuterLayoutHouseType}
            onCancel={() => {
              this.setState({ OuterLayoutHouseType: false });
            }}
            width={800}
            centered
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ OuterLayoutHouseType: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                </Button>
                <Button style={{ width: "100%" }} variant="secondary" onClick={() => this.saveReservedQuantity()}>
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
                <Button
                  style={{ width: "100%" }}
                  variant="success"
                  onClick={() => {
                    this.setState({ OuterLayoutHouseType: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Space>
            ]}
          >
            <br />{" "}
            <h4>
              <FormattedMessage id={"APDetailsTypesOfHousesModal.SectionsOfApartments"} defaultMessage={"  "} />
            </h4>
            <Form.List name="APDetailsTypesOfHouses">
              {(fields, { add, remove }) => {
                return (
                  <>
                    <TableBoot bordered size="sm">
                      <thead>
                        <tr>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Point"} />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"N"} />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"E"} />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"H"} />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"Connection Type"} />
                          </th>
                          <th colSpan="1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map(field => {
                          return (
                            <tr key={field.key}>
                              <td>
                                <Form.Item noStyle name={[field.name, "designationId"]}>
                                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                    {Boolean(this.state["designationIdSelectItems"]) &&
                                      this.state["designationIdSelectItems"].length > 0 &&
                                      this.state["designationIdSelectItems"].map(i => (
                                        <Option key={i.key} value={i.key}>
                                          {i.value}
                                        </Option>
                                      ))}{" "}
                                  </Select>
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  {...layout}
                                  label={<FormattedMessage id=" " defaultMessage=" " />}
                                  style={{ marginBottom: "5px" }}
                                  name="reservedQuantity"
                                  rules={[
                                    { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                  ]}
                                >
                                  <InputNumber
                                    style={{
                                      width: "100%"
                                    }}
                                    min={1}
                                    max={this.state.availableQuantity}
                                  />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  {...layout}
                                  label={<FormattedMessage id=" " defaultMessage=" " />}
                                  style={{ marginBottom: "5px" }}
                                  name="reservedQuantity"
                                  rules={[
                                    { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                  ]}
                                >
                                  <InputNumber
                                    style={{
                                      width: "100%"
                                    }}
                                    min={1}
                                    max={this.state.availableQuantity}
                                  />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  {...layout}
                                  label={<FormattedMessage id=" " defaultMessage=" " />}
                                  style={{ marginBottom: "5px" }}
                                  name="reservedQuantity"
                                  rules={[
                                    { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                  ]}
                                >
                                  <InputNumber
                                    style={{
                                      width: "100%"
                                    }}
                                    min={1}
                                    max={this.state.availableQuantity}
                                  />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item noStyle name={[field.name, "designationId"]}>
                                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                    {Boolean(this.state["designationIdSelectItems"]) &&
                                      this.state["designationIdSelectItems"].length > 0 &&
                                      this.state["designationIdSelectItems"].map(i => (
                                        <Option key={i.key} value={i.key}>
                                          {i.value}
                                        </Option>
                                      ))}{" "}
                                  </Select>
                                </Form.Item>
                              </td>
                            </tr>
                          );
                        })}

                        <tr>
                          <td colSpan={11}>
                            <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                              <FormattedMessage id={"ExecutionPlan.AddNewPersonnel"} defaultMessage={"Add New Coordinate "} />
                            </AntButton>
                          </td>
                        </tr>
                      </tbody>
                    </TableBoot>
                  </>
                );
              }}
            </Form.List>
          </AntModal>

          {/* CREATE ACTİVİTY FROM SERVICE */}
          <AntModal
            title={<FormattedMessage id="ProjectPlanning.CreateActivity" defaultMessage="Create Activity" />}
            open={this.state.showCreateActivityModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showCreateActivityModal: false })}
            onOk={() => this.formRefCreateActivity.current.submit()}
            width={1300}
            centered
          >
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveProjectPlanningActivityFromService}
              onFinishFailed={onFinishFailed}
              ref={this.formRefCreateActivity}
            >
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProjectPlanning.Activity" defaultMessage="Activity" />}
                  name="activityId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["activityIdSelectItems"]) &&
                      this.state["activityIdSelectItems"].length > 0 &&
                      this.state["activityIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
            </Form>
          </AntModal>

          {/* CREATE ACTİVİTY FROM ACTIVITY*/}
          <AntModal
            title={<FormattedMessage id="ProjectPlanning.CreateActivity" defaultMessage="Create Activity" />}
            open={this.state.showCreateActivityModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showCreateActivityModal: false })}
            onOk={() => this.formRefCreateActivity.current.submit()}
            width={1300}
            centered
          >
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveProjectPlanningActivityFromActivity}
              onFinishFailed={onFinishFailed}
              ref={this.formRefCreateActivity}
            >
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProjectPlanning.Activity" defaultMessage="Activity" />}
                  name="activityId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["activityIdSelectItems"]) &&
                      this.state["activityIdSelectItems"].length > 0 &&
                      this.state["activityIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
            </Form>
          </AntModal>

          {/* DeleteModal   DeleteModal     DeleteModal     DeleteModal     DeleteModal     DeleteModal */}

          {/* DELETE LOCATION */}
          <AntModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            open={this.state.showDeleteLocationModal}
            onCancel={() => {
              this.setState({ showDeleteLocationModal: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ showDeleteLocationModal: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.deleteProjectPlanningLocation}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Space>
            ]}
          >
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </AntModal>
          {/* DELETE SERVICE */}
          <AntModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            open={this.state.showDeleteServiceModal}
            onCancel={() => {
              this.setState({ showDeleteServiceModal: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ showDeleteServiceModal: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.deleteProjectPlanningService}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Space>
            ]}
          >
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </AntModal>
          {/* DELETE ACTIVITY */}
          <AntModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            open={this.state.showDeleteActivityModal}
            onCancel={() => {
              this.setState({ showDeleteActivityModal: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ showDeleteActivityModal: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.deleteProjectPlanningActivity}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Space>
            ]}
          >
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </AntModal>
          {/* DELETE MATERIAL */}
          <AntModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            open={this.state.showDeleteMaterialModal}
            onCancel={() => {
              this.setState({ showDeleteMaterialModal: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ showDeleteMaterialModal: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.deleteProjectPlanningMaterial}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Space>
            ]}
          >
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </AntModal>
          {/* DELETE COORDİNATES */}
          <AntModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            open={this.state.showDeleteCoordinatesModal}
            onCancel={() => {
              this.setState({ showDeleteCoordinatesModal: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ showDeleteCoordinatesModal: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.deleteProjectPlanningCoordinates}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Space>
            ]}
          >
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </AntModal>

          {/* RenameModal     RenameModal     RenameModal   RenameModal   RenameModal   RenameModal   RenameModal*/}

          <AntModal
            title={<FormattedMessage id="ProjectPlanning.AddMaterial" defaultMessage="Add Material" />}
            open={this.state.showRenameMaterialModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showRenameMaterialModal: false })}
            onOk={() => this.formRefCreateMaterial.current.submit()}
            width={1300}
            centered
          >
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveProjectPlanningMaterial}
              onFinishFailed={onFinishFailed}
              ref={this.formRefCreateMaterial}
            >
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProjectPlanning.MaterialCategory" defaultMessage="Material Category" />}
                  name="materialCategoryId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["materialCategoryIdSelectItems"]) &&
                      this.state["materialCategoryIdSelectItems"].length > 0 &&
                      this.state["materialCategoryIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
            </Form>
          </AntModal>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPlanning);
