import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { handleRequest } from "../ApiConnector";
import { error } from "../MessageHelper";
import PrepareFormItemProps from "./ElementUtils";

const { Option } = Select;
const SelectAddComponent = ({ field, layout }) => {
  const [list, setList] = useState(null);
  const [selectAdd, setSelectAdd] = useState(null);

  const fillList = async () => {
    if (!Boolean(field.addType)) {
      return;
    }
    if (!Boolean(field.loadUrl)) return;

    let url = field.loadUrl + "/" + field.addType; //"/api/countries/selectItems";
    var response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else setList(Boolean(response.data) ? response.data : []);
  };
  useEffect(() => {
    fillList();
  }, []);
  const addItem = async () => {
    if (Boolean(selectAdd)) {
      const newItem = {
        value: selectAdd,
        key: field.addType
      };
      //TODO: field.addType olacak ve addUrl olacak mı kontrol edilecek.
      let url = field.loadUrl;
      var response = await handleRequest("POST", url, newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        setSelectAdd("");
        fillList();
      }
    } else {
      this.showError("Please enter " + field.label);
    }
  };

  return (
    <>
      <Form.Item {...layout} {...PrepareFormItemProps(field)}>
        <Select
          showSearch
          allowClear
          placeholder={field.label}
          optionFilterProp="children"
          dropdownRender={menu => (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                <Input
                  style={{ flex: "auto" }}
                  value={selectAdd}
                  onChange={e => {
                    setSelectAdd(e.target.value);
                  }}
                />
                <Button type="link" icon={<PlusOutlined />} onClick={addItem}>
                  Add
                </Button>
              </div>
            </div>
          )}
        >
          {list
            ? list.map((i, index) => (
                <Option value={Boolean(field.propKey) ? i[field.propKey] : i.key} key={index}>
                  {Boolean(field.propValue) ? i[field.propValue] : i.value}
                </Option>
              ))
            : null}
        </Select>
      </Form.Item>
    </>
  );
};

export default SelectAddComponent;
