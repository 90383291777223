import axios from "axios";
import React, { useEffect, useState } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { API_BASE_ROOT } from "../../../../app/ApiConnector";
import store from "../../../../redux/store";
import { Content } from "./Content";

const getMenuItems = () => {
  let url = API_BASE_ROOT + "/api/menus/byUser";
  return axios.get(url);
};

export function ContentRoute({ children, component, render, ...props }) {
  const state = store.getState();
  const location = useLocation();

  const user = state.auth.user;
  const [menuItems, setMenuItems] = useState(null);
  const [authorized, setAuthorized] = useState(null);
  //const [error, setError] = useState(null);

  useEffect(() => {
    var page = "";
    if (Boolean(props) && Boolean(props.path) && props.path.length > 0) {
      page = props.path.substring(1, props.path.length);
    }
    //page is more accurate with this
    page = location.pathname.split(/[?#]/)[0].substring(1);
    if (!Boolean(authorized)) {
      if (
        Boolean(page) &&
        (page === "auth/login" ||
          page === "" ||
          page === "auth/forgot-password" ||
          page === "change-password" ||
          page === "authorization-error" ||
          page === "notfound-error")
      ) {
        setAuthorized(true);
      } else {
        if (!Boolean(menuItems)) {
          getMenuItems().then(({ data }) => {
            setMenuItems(data);
          });
        } else {
          let m = menuItems.find(i => i.page === page);
          if (Boolean(m)) {
            setAuthorized(true);
          }
        }
      }
    }
  }, [props, authorized, menuItems, user, location.pathname]);

  return (
    <Route {...props}>
      {routeProps => {
        if (typeof children === "function") {
          return <Content>{children(routeProps)}</Content>;
        }

        if (!routeProps.match) {
          return null;
        }

        if (children) {
          return <Content>{children}</Content>;
        }

        // if (component) {
        //   return (
        //     <Content>{React.createElement(component, routeProps)}</Content>
        //   );
        // }

        if (authorized != null && authorized) {
          return <Content>{React.createElement(component, routeProps)}</Content>;
        } else if (authorized != null && !authorized) {
          return <Redirect to="authorization-error" />;
        }

        if (render) {
          return <Content>{render(routeProps)}</Content>;
        }

        return null;
      }}
    </Route>
  );
}
