import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { FormattedMessage } from "react-intl";
import { Button, Col, Row, Modal as AntModal, Form } from "antd";
import { Add, BookmarkBorder, Delete, Drafts, Filter, Inbox, Send } from "@material-ui/icons";
import InboxPage from "./InboxPage";
import InboxNewMessage from "./InboxNewMessage";
import DraggableModal from "../../../../Components/DraggableModal";


export function InboxDrawer() {
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  const [selectedTab, setSelectedTab] = React.useState("inbox");
  const [isShowNewMessage, setShowNewMessage] = useState(false);
  const [isShowReply, setShowReply] = useState(false);
  useEffect(() => {
    return () => { };
  }, [user]);

  return (
    <>
      {user && (
        <>
          <Row gutter={[16, 16]}>
            {" "}
            <Col md={20}>
            </Col>
            <Col md={2} style={{ marginBottom: '20px' }}>
              <Button type="default" icon={<Filter />} style={{ borderRadius: '5px' }} >
                <FormattedMessage id="InboxDrawer.Filter" defaultMessage="Filter" />
              </Button>
            </Col>
            <Col md={2} style={{ marginBottom: '20px' }}>
              <Button type="primary" style={{ borderRadius: '5px' }} >
                <FormattedMessage id="InboxDrawer.Create" defaultMessage="Create" />
              </Button>
            </Col>
          </Row>
          <div className="d-flex flex-row">
            <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-250px" id="kt_profile_aside">
              <div className="card card-custom card-stretch">
                {/* begin::Body */}
                <div className="card-body pt-4">
                  {/* begin::Toolbar */}
                  <div className="d-flex justify-content-end"></div>
                  {/* end::Toolbar */}
                  {/* begin::Nav */}
                  <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                    <div className="navi-item mb-2">
                      <Button
                        type="primary"
                        style={{
                          width: "100%",
                          height: "40px",
                          marginBottom: "20px",
                          borderRadius: '4px'
                        }}
                        onClick={() => {
                          setShowNewMessage(true)
                        }}
                      >
                        <FormattedMessage
                          id="InboxDrawer.NewMessage"
                          defaultMessage="New Message"
                        />
                      </Button>
                    </div>
                    <div className="navi-item mb-2">
                      <OverlayTrigger placement="right" overlay={<Tooltip><FormattedMessage id="InboxDrawer.Inbox" defaultMessage="Inbox" /></Tooltip>}>
                        <a
                          href="#"
                          className={`navi-link py-4 ${selectedTab === "inbox" ? 'active-link' : ''}`}
                          onClick={() => {
                            setSelectedTab("inbox");
                          }}
                        >
                          <span className="navi-icon mr-2">
                            {<Inbox />}
                          </span>
                          <span className="navi-text font-size-lg"><FormattedMessage id="InboxDrawer.Inbox" defaultMessage="Inbox" /></span>
                        </a>
                      </OverlayTrigger>
                    </div>
                    <div className="navi-item mb-2">
                      <OverlayTrigger placement="right" overlay={<Tooltip><FormattedMessage id="InboxDrawer.Marked" defaultMessage="Marked" /></Tooltip>}>
                        <a
                          href="#"
                          className={`navi-link py-4 ${selectedTab === "marked" ? 'active-link' : ''}`}
                          onClick={() => {
                            setSelectedTab("marked");
                          }}
                        >
                          <span className="navi-icon mr-2">
                            {<BookmarkBorder />}
                          </span>
                          <span className="navi-text"><FormattedMessage id="InboxDrawer.Marked" defaultMessage="Marked" /></span>
                        </a>
                      </OverlayTrigger>
                    </div>
                    <div className="navi-item mb-2">
                      <OverlayTrigger placement="right" overlay={<Tooltip><FormattedMessage id="InboxDrawer.Draft" defaultMessage="Draft" /></Tooltip>}>
                        <a
                          href="#"
                          className={`navi-link py-4 ${selectedTab === "draft" ? 'active-link' : ''}`}
                          onClick={() => {
                            setSelectedTab("draft");
                          }}
                        >
                          <span className="navi-icon mr-2">
                            {<Drafts />}
                          </span>
                          <span className="navi-text"><FormattedMessage id="InboxDrawer.Draft" defaultMessage="Draft" /></span>
                        </a>
                      </OverlayTrigger>
                    </div>
                    <div className="navi-item mb-2">
                      <OverlayTrigger placement="right" overlay={<Tooltip><FormattedMessage id="InboxDrawer.Sent" defaultMessage="Sent" /></Tooltip>}>
                        <a
                          href="#"
                          className={`navi-link py-4 ${selectedTab === "sent" ? 'active-link' : ''}`}
                          onClick={() => {
                            setSelectedTab("sent");
                          }}
                        >
                          <span className="navi-icon mr-2">
                            {<Send />}
                          </span>
                          <span className="navi-text"><FormattedMessage id="InboxDrawer.Sent" defaultMessage="Sent" /></span>
                        </a>
                      </OverlayTrigger>
                    </div>
                    <div className="navi-item mb-2">
                      <OverlayTrigger placement="right" overlay={<Tooltip><FormattedMessage id="InboxDrawer.Trash" defaultMessage="Trash" /> </Tooltip>}>
                        <a
                          href="#"
                          className={`navi-link py-4 ${selectedTab === "trash" ? 'active-link' : ''}`}
                          onClick={() => {
                            setSelectedTab("trash");
                          }}
                        >
                          <span className="navi-icon mr-2">
                            {<Delete />}
                          </span>
                          <span className="navi-text"><FormattedMessage id="InboxDrawer.Trash" defaultMessage="Trash" /> </span>
                        </a>
                      </OverlayTrigger>
                    </div>
                    <div className="navi-item mb-2" style={{ marginTop: '20px' }}>
                      <OverlayTrigger placement="right" overlay={<Tooltip><FormattedMessage id="InboxDrawer.AddLabel" defaultMessage="Add Label" /> </Tooltip>}>
                        <a
                          href="#"
                          className={`navi-link py-4 ${selectedTab === "addLabel" ? 'active-link' : ''}`}
                          onClick={() => {
                            setSelectedTab("addLabel");
                          }}
                        >
                          <span className="navi-icon mr-2">
                            {<Add />}
                          </span>
                          <span className="navi-text"><FormattedMessage id="InboxDrawer.AddLabel" defaultMessage="Add Label" /> </span>
                        </a>
                      </OverlayTrigger>
                    </div>
                  </div>
                  {/* end::Nav */}
                </div>
                {/* end::Body */}
              </div>
            </div>
            <div className="flex-row-fluid ml-lg-8">
              {selectedTab === "inbox" && (
                <div className="card card-custom card-stretch" >
                  <div className="card-header py-3">
                    <div className="card-title align-items-start flex-column">
                      <h3 className="card-label font-weight-bolder text-dark"><FormattedMessage id="InboxDrawer.Inbox" defaultMessage="Inbox" /></h3>
                    </div>
                    <div className="card-toolbar"></div>
                  </div>
                  <div className="form">
                    <div className="card-body">
                      <InboxPage nextTab={() => setSelectedTab("inbox")} />
                    </div>
                  </div>
                </div>
              )}
              {selectedTab === "marked" && (
                <div className="card card-custom card-stretch">
                  <div className="card-header py-3">
                    <div className="card-title align-items-start flex-column">
                      <h3 className="card-label font-weight-bolder text-dark"><FormattedMessage id="InboxDrawer.Marked" defaultMessage="Marked" /></h3>
                    </div>
                    <div className="card-toolbar"></div>
                  </div>
                  <div className="form">
                    <div className="card-body">
                      {/* <ProjectsTreeCompanySection nextTab={() => setSelectedTab("floors")} /> */}
                    </div>
                  </div>
                </div>
              )}
              {selectedTab === "draft" && (
                <div className="card card-custom card-stretch">
                  <div className="card-header py-3">
                    <div className="card-title align-items-start flex-column">
                      <h3 className="card-label font-weight-bolder text-dark"><FormattedMessage id="InboxDrawer.Draft" defaultMessage="Draft" /></h3>
                    </div>
                    <div className="card-toolbar"></div>
                  </div>
                  <div className="form">
                    <div className="card-body">
                      {/* <ProjectsTreeCompanyFloor nextTab={() => setSelectedTab("divisions")} /> */}
                    </div>
                  </div>
                </div>
              )}
              {selectedTab === "sent" && (
                <div className="card card-custom card-stretch">
                  <div className="card-header py-3">
                    <div className="card-title align-items-start flex-column">
                      <h3 className="card-label font-weight-bolder text-dark"><FormattedMessage id="InboxDrawer.Sent" defaultMessage="Sent" /></h3>
                    </div>
                    <div className="card-toolbar"></div>
                  </div>
                  <div className="form">
                    <div className="card-body">
                      {/* <ProjectsTreeCompanyDivision nextTab={() => setSelectedTab("height")} /> */}
                    </div>
                  </div>
                </div>
              )}
              {selectedTab === "trash" && (
                <div className="card card-custom card-stretch">
                  <div className="card-header py-3">
                    <div className="card-title align-items-start flex-column">
                      <h3 className="card-label font-weight-bolder text-dark"><FormattedMessage id="InboxDrawer.Trash" defaultMessage="Trash" /></h3>
                    </div>
                    <div className="card-toolbar"></div>
                  </div>
                  <div className="form">
                    <div className="card-body">
                      {/* <ProjectsTreeCompanyHeight nextTab={() => setSelectedTab("layout")} /> */}
                    </div>
                  </div>
                </div>
              )}
              {selectedTab === "addLabel" && (
                <div className="card card-custom card-stretch">
                  <div className="card-header py-3">
                    <div className="card-title align-items-start flex-column">
                      <h3 className="card-label font-weight-bolder text-dark"><FormattedMessage id="InboxDrawer.AddLabel" defaultMessage="Add Label" /></h3>
                    </div>
                    <div className="card-toolbar"></div>
                  </div>
                  <div className="form">
                    <div className="card-body">
                      {/* <ProjectsTreeCompanyHeight nextTab={() => setSelectedTab("layout")} /> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <DraggableModal
            title={<FormattedMessage id="InboxDrawer.Compose" defaultMessage="Compose" />}
            open={isShowNewMessage}
            okButtonProps={{ hidden: true }}
            onCancel={() => setShowNewMessage(false)}
            width={1300}
            centered
            content={
              <Form
                initialValues={{ remember: false }}
              >
                <InboxNewMessage />
              </Form>
            }
          />
        </>
      )}
    </>
  );
}