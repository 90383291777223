import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { downloadMultipleFile } from "../helper/UploadDownloadHelper";
import { Form, Table, Input, Select, InputNumber, Radio, Tooltip, Divider, DatePicker, Button as AntButton, Col, Row, Cascader } from "antd";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import "../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";
import { UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { DownloadOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { Option } = Select;

//# Columns-Resize
const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};


export class NoteLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      showAddCustomerModal: false,
      showModalEnterPayment: false,

      id: null,
      selectedRow: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      deptorIdSelectItems: null,
      guarantorIdSelectItems: null,
      creditorIdSelectItems: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();
  formAddCustomerRef = React.createRef();
  formEnterPaymentRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    fillFilterOperations();
  };

  fillComboboxes = async () => {
    var responsedeptorId = await handleRequest("GET", "/api/noteLog/deptorIdSelectItems");
    if (responsedeptorId.type === "ERROR") {
      error(responsedeptorId);
    } else {
      this.setState({
        deptorIdSelectItems: Boolean(responsedeptorId.data) ? responsedeptorId.data : [],
        guarantorIdSelectItems: Boolean(responsedeptorId.data) ? responsedeptorId.data : [],
        creditorIdSelectItems: Boolean(responsedeptorId.data) ? responsedeptorId.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  showCustomerModal = () => {
    console.log("showCustomerModal");
    this.setState({
      showAddCustomerModal: true
    });
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      selectedRow: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = [];

    let filePath = Boolean(row.filePath) ? row.filePath.split(",") : [];
    if (Boolean(filePath) && filePath.length > 0) {
      filePath.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileList.push(obj);
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      issueDate: Boolean(row.issueDate) ? moment(row.issueDate) : null,
      paymentDate: Boolean(row.paymentDate) ? moment(row.paymentDate) : null
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      selectedRow: row,
      fileList: fileList,
      noteType: row.noteType
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  enterPayment = row => {
    this.setState(
      {
        showModalEnterPayment: true,
        selectedRow: row,
        id: row.id
      },
      () => {
        this.formEnterPaymentRef.current.setFieldsValue({
          paidDate: Boolean(row.paidDate) ? moment(row.paidDate) : null,
          paidAmount: Boolean(row.paidAmount) ? row.paidAmount : null,
          paidRemarks: Boolean(row.paidRemarks) ? row.paidRemarks : null
        });
      }
    );
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/noteLog/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/noteLog/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const { selectedRow } = this.state;
    const newItem = {
      ...selectedRow,
      ...values,
      id: this.state.id,
      filePath: Boolean(this.state.fileList) && this.state.fileList.length > 0 ? this.state.fileList[0].url : ""
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/noteLog", newItem);
    } else {
      response = await handleRequest("PUT", "/api/noteLog/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/noteLog/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu
          row={record}
          onDeleteRow={this.deleteModal.bind(this)}
          onEnterPaymentRow={this.enterPayment.bind(this)}
          onEditRow={this.edit.bind(this)}
        />
      </React.Fragment>
    );
  };

  saveCustomer = async values => {
    let newItem = {
      customer: true,
      ...values
    };

    var response = await handleRequest("POST", "/api/vendor", newItem);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.fillComboboxes();
      this.setState({
        showAddCustomerModal: false
      });
      showSuccess();
    }
  };

  savePayment = async values => {
    let newItem = this.state.selectedRow;
    newItem = {
      ...newItem,
      ...values
    };

    var response = await handleRequest("PUT", "/api/noteLog/" + newItem.id, newItem);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        showModalEnterPayment: false
      });
      showSuccess();
      const { pagination } = this.state;
      this.restartTable({ pagination });
    }
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        width: 100,
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: "Status",
        key: "status",
        width: 100,
        render: record => {
          return record.status;
        },
        ...getColumnFilter({
          inputName: "status",
          inputType: "SELECT",
          selectItems: Boolean(this.state["statusIdSelectItems"]) ? this.state["statusIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }),

        sorter: true
      },

      {
        title: <FormattedMessage id="NoteLog.deptorId" defaultMessage="Deptor" />,
        key: "deptorId",
        width: 150,
        render: record => {
          return <Tooltip title={record.deptorName}>{record.deptorName}</Tooltip>;
        },
        ...getColumnFilter({
          inputName: "deptorId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["deptorIdSelectItems"]) ? this.state["deptorIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="NoteLog.guarantorId" defaultMessage="Guarantor" />,
        key: "guarantorId",
        width: 150,
        render: record => {
          return <Tooltip title={record.guarantorName}>{record.guarantorName}</Tooltip>;
        },
        ...getColumnFilter({
          inputName: "guarantorId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["guarantorIdSelectItems"]) ? this.state["guarantorIdSelectItems "] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }),

        sorter: true
      },
      {
        title: <FormattedMessage id="NoteLog.creditorId" defaultMessage="Creditor" />,
        key: "creditorId",
        width: 150,
        render: record => {
          return <Tooltip title={record.creditorName}>{record.creditorName}</Tooltip>;
        },
        ...getColumnFilter({
          inputName: "creditorId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["creditorIdSelectItems"]) ? this.state["creditorIdSelectItems "] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }),

        sorter: true
      },
      {
        title: <FormattedMessage id="NoteLog.noteType" defaultMessage="Note Type" />,
        key: "noteType",
        width: 150,
        render: record => {
          return <Tooltip title={record.noteType}>{record.noteType}</Tooltip>;
        },
        ...getColumnFilter({
          inputName: "noteType",
          inputType: "TEXT",
          selectItems: Boolean(this.state["noteType"]) ? this.state["noteType"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }),

        sorter: true
      },
      {
        title: <FormattedMessage id="NoteLog.noteNo" defaultMessage="Note No" />,
        key: "noteNo",
        width: 150,
        render: record => {
          return record.noteNo;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.noteNo) ? this.state.pagination.noteNo : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  noteNo: e.target.value,
                  noteNoColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.noteNoColor) ? this.state.pagination.noteNoColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="NoteLog.issueDate" defaultMessage="Issue Date" />,
        key: "issueDate",
        width: 150,
        render: record => {
          return Boolean(record.issueDate) ? moment(record.issueDate).format("DD-MM-YYYY") : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.issueDate) ? this.state.pagination.issueDate : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  issueDate: e.target.value,
                  issueDateColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.issueDateColor) ? this.state.pagination.issueDateColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="NoteLog.issuePlace" defaultMessage="Issue Place" />,
        key: "issuePlace",
        width: 150,
        render: record => {
          return record.issuePlace;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.issuePlace) ? this.state.pagination.issuePlace : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  issuePlace: e.target.value,
                  issuePlaceColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.issuePlaceColor) ? this.state.pagination.issuePlaceColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="NoteLog.amount" defaultMessage="Amount" />,
        key: "amount",
        width: 150,
        render: record => {
          return record.amount;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.amount) ? this.state.pagination.amount : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  amount: e.target.value,
                  amountColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.amountColor) ? this.state.pagination.amountColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="NoteLog.paymentDate" defaultMessage="Payment Date" />,
        key: "paymentDate",
        width: 150,
        render: record => {
          return Boolean(record.paymentDate) ? moment(record.paymentDate).format("DD-MM-YYYY") : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.paymentDate) ? this.state.pagination.paymentDate : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  paymentDate: e.target.value,
                  paymentDateColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.paymentDateColor) ? this.state.pagination.paymentDateColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="NoteLog.paidReceived" defaultMessage="Paid/Received" />,
        key: "paidAmount",
        width: 150,
        render: record => {
          return record.paidAmount;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.paidAmount) ? this.state.pagination.paidAmount : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  amount: e.target.value,
                  paidAmountColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.paidAmountColor) ? this.state.pagination.paidAmountColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="NoteLog.Balance" defaultMessage="Balance" />,
        key: "balance",
        width: 150,
        render: record => {
          if (record.amount && record.paidAmount) return record.amount - record.paidAmount;
          else if (record.amount) return record.amount;
          else return "";
        }
      },
      {
        title: <FormattedMessage id="NoteLog.filePath" defaultMessage="File" />,
        key: "filePath",
        width: 150,
        render: record => {
          if (Boolean(record.filePath))
            return (
              <AntButton
                style={{
                  marginLeft: "2px",
                  marginTop: "-2px",
                  alignContent: "center"
                }}
                onClick={() => downloadMultipleFile(record.filePath)}
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
              ></AntButton>
            );
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.filePath) ? this.state.pagination.filePath : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  filePath: e.target.value,
                  filePathColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.filePathColor) ? this.state.pagination.filePathColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="NoteLog.remarks" defaultMessage="Remarks" />,
        key: "remarks",
        width: 150,
        render: record => {
          return record.remarks;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.remarks) ? this.state.pagination.remarks : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  remarks: e.target.value,
                  remarksColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.remarksColor) ? this.state.pagination.remarksColor : "#bfbfbf" }} />
        ),

        sorter: true
      },

      {
        title: "Last Update",
        key: "lastUpdateDate",
        width: 150,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];

      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={21}></Col>
            <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="NoteLog.noteType" defaultMessage="Note Type" />}
                name="noteType"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Radio.Group
                  onChange={e => {
                    this.setState({
                      noteType: e.target.value
                    });
                  }}
                >
                  <Radio value={"COMPANY_NOTE"}>Company Note</Radio>
                  <Radio value={"CUSTOMER_NOTE"}>Customer Note</Radio>
                </Radio.Group>
              </Form.Item>
            }
            {Boolean(this.state.noteType) && this.state.noteType === "CUSTOMER_NOTE" && (
              <Form.Item
                {...layout}
                label={<FormattedMessage id="NoteLog.deptorId" defaultMessage="Deptor" />}
                name="deptorId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Deptor"
                  optionFilterProp="children"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8
                        }}
                      >
                        <AntButton type="link" icon={<PlusOutlined />} onClick={this.showCustomerModal}>
                          Add New
                        </AntButton>
                      </div>
                    </div>
                  )}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["deptorIdSelectItems"]) &&
                    this.state["deptorIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            )}{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="NoteLog.guarantorId" defaultMessage="Guarantor" />}
                name="guarantorId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Guarantor"
                  optionFilterProp="children"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8
                        }}
                      >
                        <AntButton type="link" icon={<PlusOutlined />} onClick={this.showCustomerModal}>
                          Add New
                        </AntButton>
                      </div>
                    </div>
                  )}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["guarantorIdSelectItems"]) &&
                    this.state["guarantorIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {Boolean(this.state.noteType) && this.state.noteType === "COMPANY_NOTE" && (
              <Form.Item
                {...layout}
                label={<FormattedMessage id="NoteLog.creditorId" defaultMessage="Creditor" />}
                name="creditorId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Creditor"
                  optionFilterProp="children"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8
                        }}
                      >
                        <AntButton type="link" icon={<PlusOutlined />} onClick={this.showCustomerModal}>
                          Add New
                        </AntButton>
                      </div>
                    </div>
                  )}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["creditorIdSelectItems"]) &&
                    this.state["creditorIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            )}{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="NoteLog.noteNo" defaultMessage="Note No" />}
                name="noteNo"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="Note No" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="NoteLog.issueDate" defaultMessage="Issue Date" />}
                name="issueDate"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <DatePicker format="DD-MM-YYYY" />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="NoteLog.issuePlace" defaultMessage="Issue Place" />}
                name="issuePlace"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="Issue Place" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="NoteLog.amount" defaultMessage="Amount" />}
                name="amount"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber placeholder="Amount" min={0} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="NoteLog.paymentDate" defaultMessage="Payment Date" />}
                name="paymentDate"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <DatePicker format="DD-MM-YYYY" />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="NoteLog.filePath" defaultMessage="File Path" />}
                name="filePath"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Upload
                  {...props}
                  showUploadList={true}
                  fileList={this.state.fileList}
                  onChange={({ fileList }) => {
                    if (fileList.length == 1) {
                      if (Boolean(fileList[0].response)) {
                        fileList[0].url = fileList[0].response.url;
                        showSuccess("file uploaded successfully");
                        // fileName: info.file.name,
                      }
                      this.setState({
                        fileList: fileList
                      });
                    }
                  }}
                  beforeUpload={fileList => {
                    if (this.state.fileList.length > 0) {
                      showError("You have been already choose a file! For change firstly remove the uploaded file.");
                      return false;
                    }
                    return true;
                  }}
                  onRemove={fileList => {
                    this.setState({
                      fileList: []
                    });
                  }}
                >
                  <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> Upload
                  </AntButton>
                </Upload>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="NoteLog.remarks" defaultMessage="Remarks" />}
                name="remarks"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="Remarks" style={{ width: "100%" }} />
              </Form.Item>
            }
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span>
                      <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span>
                      <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <Table
            bordered
            scroll={{ x: 2500, scrollToFirstRowOnChange: true }}
            columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
            components={{
              header: {
                cell: ResizableTitle
              }
            }}
            rowClassName={(record, index) => (Boolean(record.status) && record.status === "NOT_PAID" ? "text-danger" : "")}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal
          show={this.state.showAddCustomerModal}
          onHide={() => {
            this.setState({
              showAddCustomerModal: false
            });
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Add New Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form initialValues={{ remember: false }} onFinish={this.saveCustomer} onFinishFailed={onFinishFailed} ref={this.formAddCustomerRef}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Vendor.name" defaultMessage="Name" />}
                style={{
                  marginBottom: 0
                }}
                name="name"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="Vendor.address" defaultMessage="Address" />}
                style={{
                  marginBottom: 0
                }}
                name="address"
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="Vendor.vatNo" defaultMessage="ID No" />}
                style={{
                  marginBottom: 0
                }}
                name="idNumber"
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Vendor.vatNo" defaultMessage="VAT No" />}
                style={{
                  marginBottom: 0
                }}
                name="vatNumber"
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Vendor.taxNo" defaultMessage="TAX No" />}
                style={{
                  marginBottom: 0
                }}
                name="taxNumber"
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Vendor.phone" defaultMessage="Phone" />}
                style={{
                  marginBottom: 0
                }}
                name="phone"
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>

              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button
                    id="CancelButton"
                    style={{ width: "100%" }}
                    variant="secondary"
                    onClick={() => {
                      this.formAddCustomerRef.current.resetFields();
                      this.setState({
                        showAddCustomerModal: false
                      });
                    }}
                  >
                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  showAddCustomerModal: false
                });
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalEnterPayment}
          onHide={() => {
            this.setState({
              showModalEnterPayment: false
            });
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Enter Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form initialValues={{ remember: false }} onFinish={this.savePayment} onFinishFailed={onFinishFailed} ref={this.formEnterPaymentRef}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EnterPayment.date" defaultMessage="Date" />}
                style={{
                  marginBottom: 0
                }}
                name="paidDate"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="EnterPayment.PaidReceivedAmount" defaultMessage="Paid/Received Amount" />}
                style={{
                  marginBottom: 0
                }}
                name="paidAmount"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                {Boolean(this.state.selectedRow) && <InputNumber style={{ width: "100%" }} max={this.state.selectedRow.amount} />}
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="EnterPayment.Remarks" defaultMessage="Remarks" />}
                style={{
                  marginBottom: 0
                }}
                name="paidRemarks"
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>

              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button
                    id="CancelButton"
                    style={{ width: "100%" }}
                    variant="secondary"
                    onClick={() => {
                      this.formAddCustomerRef.current.resetFields();
                      this.setState({
                        showModalEnterPayment: false
                      });
                    }}
                  >
                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  showModalEnterPayment: false
                });
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NoteLog);
