import { DatePicker, Form } from "antd";
import moment from "moment";
import React from "react";
import PrepareFormItemProps from "./ElementUtils";
const RangePickerTimeComponent = ({ field, layout }) => {
  const { RangePicker } = DatePicker;

  return (
    <>
      <Form.Item {...layout} {...PrepareFormItemProps(field)}>
        <RangePicker
          showTime={{
            format: "HH:mm",
            defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")]
          }}
          format="DD-MM-YYYY HH:mm"
          style={{ width: "100%" }}
        />
      </Form.Item>
    </>
  );
};

export default RangePickerTimeComponent;
