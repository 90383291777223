import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Inbox from "./EldenInbox";
import Open from "./Open";
import Closed from "./Closed";
import Sent from "./Sent";
import { BookmarkBorder, Drafts, Inbox as InboxIcon, Send } from "@material-ui/icons";
import { Form, Button } from "antd";
import DraggableModal from "../../../../../../app/Components/DraggableModal";
import TicketNewMessage from "./TicketNewMessage";

export function MyTicketsMainPage() {
  const [selectedTab, setSelectedTab] = React.useState("inbox");
  const [isShowNewMessage, setShowNewMessage] = useState(false);
  const [random, setRandom] = useState(null);
  useEffect(() => {
    return () => { };
  }, []);

  return (
    <>
      <div className="d-flex flex-row">
        <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-250px" id="kt_profile_aside">
          <div className="card card-custom card-stretch">
            {/* begin::Body */}
            <div className="card-body pt-4">
              {/* begin::Toolbar */}
              <div className="d-flex justify-content-end"></div>
              {/* end::Toolbar */}
              {/* begin::Nav */}
              <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                <div className="navi-item mb-2">
                  <Button
                    type="primary"
                    style={{
                      width: "100%",
                      height: "40px",
                      marginBottom: "20px",
                      borderRadius: '4px'
                    }}
                    onClick={() => {
                      setRandom(Math.random())
                      setShowNewMessage(true)
                    }}
                  >
                    <FormattedMessage
                      id="MyTicketsMainPage.NewTicket"
                      defaultMessage="New Ticket"
                    />
                  </Button>
                </div>
                <div className="navi-item mb-2">
                  <OverlayTrigger placement="right" overlay={<Tooltip><FormattedMessage id="MyTicketsMainPage.Inbox" defaultMessage="Inbox" /></Tooltip>}>
                    <a
                      href="#"
                      className={`navi-link py-4 ${selectedTab === "inbox" ? 'active-link' : ''}`}
                      onClick={() => {
                        setSelectedTab("inbox");
                      }}
                    >
                      <span className="navi-icon mr-2">
                        {<InboxIcon />}
                      </span>
                      <span className="navi-text font-size-lg"><FormattedMessage id="MyTicketsMainPage.Inbox" defaultMessage="Inbox" /></span>
                    </a>
                  </OverlayTrigger>
                </div>
                <div className="navi-item mb-2">
                  <OverlayTrigger placement="right" overlay={<Tooltip><FormattedMessage id="MyTicketsMainPage.Sent" defaultMessage="Sent" /></Tooltip>}>
                    <a
                      href="#"
                      className={`navi-link py-4 ${selectedTab === "sent" ? 'active-link' : ''}`}
                      onClick={() => {
                        setSelectedTab("sent");
                      }}
                    >
                      <span className="navi-icon mr-2">
                        {<Send />}
                      </span>
                      <span className="navi-text"><FormattedMessage id="MyTicketsMainPage.Sent" defaultMessage="Sent" /></span>
                    </a>
                  </OverlayTrigger>
                </div>
                <div className="navi-item mb-2">
                  <OverlayTrigger placement="right" overlay={<Tooltip><FormattedMessage id="MyTicketsMainPage.Open" defaultMessage="Open" /></Tooltip>}>
                    <a
                      href="#"
                      className={`navi-link py-4 ${selectedTab === "open" ? 'active-link' : ''}`}
                      onClick={() => {
                        setSelectedTab("open");
                      }}
                    >
                      <span className="navi-icon mr-2">
                        {<Drafts />}
                      </span>
                      <span className="navi-text"><FormattedMessage id="MyTicketsMainPage.Open" defaultMessage="Open" /></span>
                    </a>
                  </OverlayTrigger>
                </div>
                <div className="navi-item mb-2">
                  <OverlayTrigger placement="right" overlay={<Tooltip><FormattedMessage id="MyTicketsMainPage.Closed" defaultMessage="Closed" /> </Tooltip>}>
                    <a
                      href="#"
                      className={`navi-link py-4 ${selectedTab === "closed" ? 'active-link' : ''}`}
                      onClick={() => {
                        setSelectedTab("closed");
                      }}
                    >
                      <span className="navi-icon mr-2">
                        {<BookmarkBorder />}
                      </span>
                      <span className="navi-text"><FormattedMessage id="MyTicketsMainPage.Closed" defaultMessage="Closed" /> </span>
                    </a>
                  </OverlayTrigger>
                </div>
              </div>
              {/* end::Nav */}
            </div>
            {/* end::Body */}
          </div>
        </div>
        <div className="flex-row-fluid ml-lg-8">
          {selectedTab === "inbox" && (
            <div className="card card-custom card-stretch" >
              <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                  <h3 className="card-label font-weight-bolder text-dark"><FormattedMessage id="MyTicketsMainPage.Inbox" defaultMessage="Inbox" /></h3>
                </div>
                <div className="card-toolbar"></div>
              </div>
              <div className="form">
                <div className="card-body">
                  <Inbox nextTab={() => setSelectedTab("inbox")} />
                </div>
              </div>
            </div>
          )}
          {selectedTab === "sent" && (
            <div className="card card-custom card-stretch">
              <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                  <h3 className="card-label font-weight-bolder text-dark"><FormattedMessage id="MyTicketsMainPage.Sent" defaultMessage="Sent" /></h3>
                </div>
                <div className="card-toolbar"></div>
              </div>
              <div className="form">
                <div className="card-body">
                  <Sent nextTab={() => setSelectedTab("sent")} />
                </div>
              </div>
            </div>
          )}
          {selectedTab === "open" && (
            <div className="card card-custom card-stretch">
              <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                  <h3 className="card-label font-weight-bolder text-dark"><FormattedMessage id="MyTicketsMainPage.Open" defaultMessage="Open" /></h3>
                </div>
                <div className="card-toolbar"></div>
              </div>
              <div className="form">
                <div className="card-body">
                  <Open nextTab={() => setSelectedTab("open")} />
                </div>
              </div>
            </div>
          )}
          {selectedTab === "closed" && (
            <div className="card card-custom card-stretch">
              <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                  <h3 className="card-label font-weight-bolder text-dark"><FormattedMessage id="MyTicketsMainPage.Closed" defaultMessage="Closed" /></h3>
                </div>
                <div className="card-toolbar"></div>
              </div>
              <div className="form">
                <div className="card-body">
                  <Closed nextTab={() => setSelectedTab("closed")} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <DraggableModal
        title={<FormattedMessage id="MyTicketsMainPage.NewTicket" defaultMessage="New Ticket" />}
        open={isShowNewMessage}
        okButtonProps={{ hidden: true }}
        onCancel={() => setShowNewMessage(false)}
        width={1200}
        centered
        content={
          <Form
            initialValues={{ remember: false }}
          >
            <TicketNewMessage close={() => {
              setShowNewMessage(false)
              setSelectedTab("inbox");
            }} random={random} />
          </Form>
        }
      />
    </>
  )
}