import React, { useState, useEffect } from "react";
import { handleRequest } from "../../../ApiConnector";
import { error } from "../../../MessageHelper";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";

import Droppable from "./components/Droppable";
import Item from "./components/Item";
import { arrayMove, insertAtIndex, removeAtIndex } from "./utils/array";
import { Checkbox, Form, Modal as AntModal, Input, Select, Radio, } from "antd";
import { FormattedMessage } from "react-intl";
import PageAutoDesignFormInput from "../PageAutoDesignFormInput";
import PageAutoDesignTableInput from "../PageAutoDesignTableInput";

const { Option } = Select;

function App() {
  const initialGroups = {
    group1: [],

  };

  const [itemGroups, setItemGroups] = useState(initialGroups);
  const [activeId, setActiveId] = useState(null);
  const [parentTableChecked, setParentTableChecked] = useState(false);
  const [parentChildTableChecked, setParentChildTableChecked] = useState(false);
  const [parentChildChildTableChecked, setParentChildChildTableChecked] = useState(false);
  const [tabChecked, setTabChecked] = useState(false);
  const [showFormItemModal, setShowFormItemModal] = useState(false);
  const [showTableModal, setShowTableModal] = useState(false);

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = async () => {
    var responseInputType = await handleRequest("GET", "/api/pageAutoDesign/formItemInputType");
    if (responseInputType.type === "ERROR") {
      error(responseInputType);
    } else {
      const data = Boolean(responseInputType.data) && responseInputType.data.length > 0 ? responseInputType.data : [];
      console.log("data", data);
      const updatedGroups = {
        group1: data.map(item => item.value),
      };
      console.log("updatedGroups", updatedGroups)
      setItemGroups(updatedGroups);
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = ({ active }) => setActiveId(active.id);

  const handleDragCancel = () => setActiveId(null);

  const handleDragOver = ({ active, over }) => {
    const overId = over?.id;

    if (!overId) {
      return;
    }

    const activeContainer = active.data.current.sortable.containerId;
    const overContainer = over.data.current?.sortable.containerId || over.id;

    if (activeContainer !== overContainer) {
      setItemGroups((itemGroups) => {
        const activeIndex = active.data.current.sortable.index;
        const overIndex =
          over.id in itemGroups
            ? itemGroups[overContainer].length + 1
            : over.data.current.sortable.index;

        return moveBetweenContainers(
          itemGroups,
          activeContainer,
          activeIndex,
          overContainer,
          overIndex,
          active.id
        );
      });
    }
  };

  const handleDragEnd = ({ active, over }) => {
    if (!over) {
      setActiveId(null);
      return;
    }

    if (active.id !== over.id) {
      const activeContainer = active.data.current.sortable.containerId;
      const overContainer = over.data.current?.sortable.containerId || over.id;
      const activeIndex = active.data.current.sortable.index;
      const overIndex =
        over.id in itemGroups
          ? itemGroups[overContainer].length + 1
          : over.data.current.sortable.index;

      setItemGroups((itemGroups) => {
        let newItems;
        if (activeContainer === overContainer) {
          newItems = {
            ...itemGroups,
            [overContainer]: arrayMove(
              itemGroups[overContainer],
              activeIndex,
              overIndex
            ),
          };
        } else {
          newItems = moveBetweenContainers(
            itemGroups,
            activeContainer,
            activeIndex,
            overContainer,
            overIndex,
            active.id
          );
        }
        // console.log("overContainer", overContainer);
        // console.log("itemGroups", itemGroups);
        console.log("newItems", newItems);
        // if (overContainer === "formItem") {
        //   newItems[overContainer] = newItems[overContainer].map((item) => transformInputType(item));
        // }
        return newItems;
      });
    }

    setActiveId(null);
  };

  const moveBetweenContainers = (
    items,
    activeContainer,
    activeIndex,
    overContainer,
    overIndex,
    item
  ) => {
    return {
      ...items,
      // [activeContainer]: removeAtIndex(items[activeContainer], activeIndex),
      [overContainer]: insertAtIndex(items[overContainer], overIndex, item),
    };
  };


  const handleCheckboxChange = (name) => (e) => {
    const checked = e.target.checked;

    setItemGroups((itemGroups) => {
      let updatedGroups = { ...itemGroups };

      if (checked) {
        switch (name) {
          case "parentTable":
            parentTableContainer();
            setParentTableChecked(true);
            break;
          case "parentChildTable":
            parentTableContainer();
            parentChildTableContainer();
            setParentChildTableChecked(true);
            break;
          case "parentChildChildTable":
            parentTableContainer();
            parentChildTableContainer();
            parentChildChildTableContainer();
            setParentChildChildTableChecked(true);
            break;
          case "tab":
            //
            break;
          default:
            break;
        }
      } else {
        switch (name) {
          case "parentTable":
            deleteParentTableContainer();
            break;
          case "parentChildTable":
            deleteParentTableContainer();
            deleteParentChildTableContainer();
            break;
          case "parentChildChildTable":
            deleteParentTableContainer();
            deleteParentChildTableContainer();
            deleteParentChildChildTableContainer();
            break;
          case "tab":
            //
            break;
          default:
            break;
        }
      }

      return updatedGroups;

      function parentTableContainer() {
        updatedGroups.formItem = [];
        updatedGroups.table = [];
      }
      function parentChildTableContainer() {
        updatedGroups.formItemChild = [];
        updatedGroups.tableChild = [];
      }
      function parentChildChildTableContainer() {
        updatedGroups.formItemChildChild = [];
        updatedGroups.tableChildChild = [];
      }

      function deleteParentTableContainer() {
        delete updatedGroups.formItem;
        delete updatedGroups.table;
      }
      function deleteParentChildTableContainer() {
        delete updatedGroups.formItemChild;
        delete updatedGroups.tableChild;
      }
      function deleteParentChildChildTableContainer() {
        delete updatedGroups.formItemChildChild;
        delete updatedGroups.tableChildChild;
      }
    });
  };

  const groupKeyMapping = {
    "formItem": "Form.Item Parent",
    "table": "Table Parent",
    "formItemChild": "Form.Item Parent-Child",
    "tableChild": "Table Parent-Child",
    "formItemChildChild": "Form.Item Parent-Child-Child",
    "tableChildChild": "Table Parent-Child-Child",
  };

  const handleShowModal = (modalType) => {
    if (modalType === 'formItemModal') {
      setShowFormItemModal(true);
    } else if (modalType === 'tableModal') {
      setShowTableModal(true);
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 }
  };

  const layout2 = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
    style: { marginBottom: 0 }
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <Form initialValues={{ remember: false }} >
        <div>
          <label>
            <Form.Item name="parentTable" valuePropName="checked">
              <Checkbox checked={parentTableChecked} onChange={handleCheckboxChange("parentTable")}>
                <FormattedMessage id="XxXxX.ParentTable" defaultMessage="Parent Table" />
              </Checkbox>
            </Form.Item>
          </label>
          <label>
            <Form.Item name="parentChildTable" valuePropName="checked">
              <Checkbox checked={parentChildTableChecked} onChange={handleCheckboxChange("parentChildTable")}>
                <FormattedMessage id="XxXxX.ParentChildTable" defaultMessage="Parent-Child Table" />
              </Checkbox>
            </Form.Item>
          </label>
          <label>
            <Form.Item name="parentChildChildTable" valuePropName="checked">
              <Checkbox checked={parentChildChildTableChecked} onChange={handleCheckboxChange("parentChildChildTable")}>
                <FormattedMessage id="XxXxX.ParentChildChildTable" defaultMessage="Parent-Child-Child Table" />
              </Checkbox>
            </Form.Item>
          </label>
          <label>
            <Form.Item name="tab" valuePropName="checked">
              <Checkbox checked={tabChecked} onChange={handleCheckboxChange("tab")}>
                <FormattedMessage id="XxXxX.Tab" defaultMessage="Tab" />
              </Checkbox>
            </Form.Item>
          </label>
        </div>
      </Form>
      <div className="container" style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ marginRight: '16px' }}>
          <div style={{ fontFamily: 'Helvetica', fontSize: '24px', fontWeight: 'bold', color: '#333' }}>
            Input Type
          </div>
          <Droppable
            id="group1"
            items={itemGroups.group1}
            activeId={activeId}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', minWidth: '300px' }}>
          {Object.keys(itemGroups)
            .filter((groupKey) => groupKey !== "group1")
            .map((groupKey, index) => (
              <div key={groupKey} style={{ marginBottom: index < Object.keys(itemGroups).length - 2 ? '16px' : 0 }}>
                <div style={{ fontFamily: 'Helvetica', fontSize: '24px', fontWeight: 'bold', color: '#333', }}>
                  {groupKeyMapping[groupKey]}
                </div>
                <Droppable
                  id={groupKey}
                  idRight={true}
                  items={itemGroups[groupKey]}
                  activeId={activeId}
                  onShowModal={(modalType) => handleShowModal(modalType)}
                  modalType={groupKey === 'formItem' || groupKey === 'formItemChild' || groupKey === 'formItemChildChild' ? 'formItemModal' : 'tableModal'}
                />
              </div>
            ))}
        </div>
      </div>

      <AntModal
        title={<FormattedMessage id="XxXxX.FormItem" defaultMessage="Form Item" />}
        open={showFormItemModal}
        okButtonProps={{ hidden: false }}
        onCancel={() => setShowFormItemModal(false)}
        width={1300}
        centered
      >
        {/* <PageAutoDesignFormInput /> */}
      </AntModal>

      <AntModal
        title={<FormattedMessage id="XxXxX.Table" defaultMessage="Table" />}
        open={showTableModal}
        okButtonProps={{ hidden: false }}
        onCancel={() => setShowTableModal(false)}
        width={1300}
        centered
      >
        <PageAutoDesignTableInput />
      </AntModal>

      {/* {
          <Form.Item
            {...layout}
            label={<FormattedMessage id="PageAutoDesignFormInput.inputName" defaultMessage="Input Name" />}
            name="inputName"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            extra={<FormattedMessage id="PageAutoDesignFormInput.BackendEntityInputNamePleaseWriteWithoutSpaces"
              defaultMessage="Backend entity input name (Please write without spaces)" />}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        }
        {
          <Form.Item
            {...layout}
            label={<FormattedMessage id="PageAutoDesignFormInput.label" defaultMessage="Label" />}
            name="label"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        }
        {
          <Form.Item
            {...layout}
            label={<FormattedMessage id="PageAutoDesignFormInput.placeholder" defaultMessage="Placeholder" />}
            name="placeholder"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        }
        {
          <Form.Item
            {...layout}
            label={<FormattedMessage id="PageAutoDesignFormInput.placeholder" defaultMessage="Input Type" />}
            name="inputType"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              value={inputType}
              onChange={(e) => {
                setInputType(e);
              }}
            >
              {Boolean(inputTypeSelectItems) &&
                inputTypeSelectItems.map((i) => (
                  <Option key={i.key} value={i.key}>
                    {i.key}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        }
        {
          <Form.Item
            {...layout}
            label={<FormattedMessage id="PageAutoDesignFormInput.hideAtStart" defaultMessage="Backend Only" />}
            name="backendOnly"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            valuePropName="checked"
          >
            <Checkbox></Checkbox>
          </Form.Item>
        }
        {
          <Form.Item
            {...layout}
            label={<FormattedMessage id="PageAutoDesignFormInput.hideAtStart" defaultMessage="Hide at start" />}
            name="hideAtStart"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            valuePropName="checked"
          >
            <Checkbox></Checkbox>
          </Form.Item>
        }
        {
          <Form.Item
            {...layout}
            label={<FormattedMessage id="PageAutoDesignFormInput.required" defaultMessage="Required" />}
            name="required"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            valuePropName="checked"
          >
            <Checkbox></Checkbox>
          </Form.Item>
        }
        {
          <Form.Item
            {...layout}
            label={<FormattedMessage id="PageAutoDesignFormInput.hover" defaultMessage="Hover" />}
            name="hover"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        }
        {Boolean(inputType) && inputType === "BUTTON" && (
          <>
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="PageAutoDesignFormInput.autoDesignOnClick" defaultMessage="On Click" />}
                name="autoDesignOnClick">
                <Radio.Group
                  onChange={(e) =>
                    setAutoDesignOnClick(e.target.value)
                  }>
                  {Boolean(autoDesignOnClickList) &&
                    autoDesignOnClickList.length > 0 &&
                    autoDesignOnClickList.map((i) => (
                      <Radio key={i.key} value={i.key}>
                        {i.value}
                      </Radio>
                    ))}
                </Radio.Group>
              </Form.Item>
            }
            {
              Boolean(autoDesignOnClick) && (
                <Form.Item {...layout}
                  label={<FormattedMessage id="PageAutoDesignFormInput.onClickName" defaultMessage="On Click Name" />}
                  name="onClickName"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              )}
          </>
        )}
        {
          <Form.Item
            {...layout}
            label={<FormattedMessage id="PageAutoDesignFormInput.whatIsLanguageKey" defaultMessage="What Is Language Key" />}
            name="whatIsLanguageKey"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        }
        {
          <Form.Item
            {...layout}
            label={<FormattedMessage id="PageAutoDesignFormInput.whatIsDescription" defaultMessage="What Is Description" />}
            name="whatIsDescription"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        }
        {
          <Form.Item
            {...layout}
            label={<FormattedMessage id="PageAutoDesignFormInput.labelLanguageKey" defaultMessage="Label Language Key" />}
            name="labelLanguageKey"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        }
        {
          <Form.Item
            {...layout}
            label={<FormattedMessage id="PageAutoDesignFormInput.controllerName" defaultMessage="Controller Name" />}
            name="controllerName"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        } */}

      <DragOverlay>{activeId ? <Item id={activeId} dragOverlay /> : null}</DragOverlay>
    </DndContext>
  );
}

export default App;
