import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";

import SortableItem from "./SortableItem";

import "./Droppable.css";

const Droppable = ({ id, items, idRight, onShowModal, modalType }) => {
  const { setNodeRef } = useDroppable({
    id,
    onContextMenu: event => handleContextMenu(event)
  });

  const handleContextMenu = event => {
    event.preventDefault();
    console.log("Sağ Tık");
    onShowModal(modalType);
  };

  return (
    <SortableContext id={id} items={items} strategy={rectSortingStrategy}>
      <ul className="droppable" ref={setNodeRef}>
        {console.log("idRight", idRight)}
        {items.map(item => (
          <li key={item} data-item-id={item} onContextMenu={handleContextMenu}>
            <SortableItem id={item} idRight={idRight} />
          </li>
        ))}
      </ul>
    </SortableContext>
  );
};

export default Droppable;
