import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { DownloadOutlined, FileSearchOutlined, FilterOutlined } from "@ant-design/icons";
import { Assignment, EditOutlined, Add, ReplyOutlined, TextFields } from "@material-ui/icons";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import {
  Form,
  Table,
  Input,
  DatePicker,
  Badge,
  Button as AntButton,
  Col,
  Space,
  Tooltip,
  Row,
  Upload,
  Checkbox,
  Drawer,
  Cascader,
  Image,
  Card,
  InputNumber
} from "antd";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import CreateIcon from "@material-ui/icons/AddCircle";
import { UploadOutlined } from "@ant-design/icons";
import ExcelReaderReadAllWorkSheets from "../excel/ExcelReaderReadAllWorkSheets";
import { Select } from "antd";
import { EquipmentLogMainDrawer } from "./EquipmentLogMainDrawer";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import EldenEquipmentTypeSelectable from "../elden/equipment/EldenEquipmentTypeSelectable";
import EldenEquipmentGroupSelectable from "../elden/equipment/EldenEquipmentGroupSelectable";
import DraggableModal from "../../Components/DraggableModal";
import { getColumnFilter, fillFilterOperations } from "../../Components/DataTableFilterComponent";
import { EquipmentLogAssign } from "./EquipmentLogAssign";
import { PrepareFileUploadEdit, PrepareFileUploadSave } from "../../Components/ElementUtils";
import { EquipmentLogIdentificationDrawer } from "./EquipmentLogIdentificationDrawer";

const { RangePicker } = DatePicker;
const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const dataListEquipment = [];
const generateListEquipment = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataListEquipment.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateListEquipment(node.children);
    }
  }
};

export class EquipmentLogMain extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "equipmentLog",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      companyName: "Equipment",
      id: null,
      currentVendorName: "",
      tableList: [],
      tableListKm: [],
      tableListMaintenance: [],
      tableListFuel: [],
      filePath: "",
      nodes: [], // for cascader.

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  formRefCurrentStatus = React.createRef();
  formRefNewEquipmentTypes = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    fillFilterOperations();
    var responseGroupId = await handleRequest("GET", "/api/" + this.state.controllerName + "/groupId");
    if (responseGroupId.type === "ERROR") {
      error(responseGroupId);
    }

    var responseCompanyProjectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/projectId");
    if (responseCompanyProjectId.type === "ERROR") {
      error(responseCompanyProjectId);
    }
    var responseCompanyName = await handleRequest("GET", "/api/" + this.state.controllerName + "/getCompanyName");
    if (responseCompanyName.type === "ERROR") {
      error(responseCompanyName);
    }

    let eldenEquipmentTreeIdSelectItems = [];
    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/eldenEquipmentTreeId");
    if (responseTree.data.length !== 0) {
      generateListEquipment(responseTree.data);
      eldenEquipmentTreeIdSelectItems = responseTree.data;
    }
    this.setState({
      eldenEquipmentTreeIdSelectItems: eldenEquipmentTreeIdSelectItems,
      companyName: Boolean(responseCompanyName.data) ? "" + responseCompanyName.data.companyShortName + " Equipment" : "Equipment",
      projectIdSelectItems: Boolean(responseCompanyProjectId.data) ? responseCompanyProjectId.data : [],
      groupIdSelectItems: Boolean(responseGroupId.data) ? responseGroupId.data : []
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  getVendorId = () => {
    return this.state.id;
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      projectId: null,
      parkingLogId: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: ""
    });
  };

  setSelectedFiles = field => {
    let fileList = [];
    if (Boolean(field) && field.length > 0) {
      let files = field.split(",");
      for (let i = 0; i < files.length; i++) {
        fileList.push({
          uid: i,
          name: files[i],
          url: files[i],
          thumbUrl: files[i]
        });
      }
      return fileList;
    }
    return [];
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    var parent = dataList.find(p => p.key === row.parkingLogId);
    let parkingLogId = [];
    if (Boolean(parent)) {
      parkingLogId = parent.lineage;
    }

    var parentTree = dataListEquipment.find(p => p.key === row.eldenEquipmentTreeId);
    let eldenEquipmentTreeId = [];
    if (Boolean(parentTree)) {
      eldenEquipmentTreeId = parentTree.lineage;
    }

    let fileList = PrepareFileUploadEdit(row.photoPath);

    this.formRef.current.setFieldsValue({
      ...row,
      parkingLogId: parkingLogId,
      photoPath: fileList,
      eldenEquipmentTreeId: eldenEquipmentTreeId,
      firstStartDate: Boolean(row.firstStartDate) ? moment(row.firstStartDate) : null,
      estimatedNextMaintenanceDate: Boolean(row.estimatedNextMaintenanceDate) ? moment(row.estimatedNextMaintenanceDate) : null
    });

    this.fillParking(row.projectId);

    console.log(row);
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      currentVendorName: row.name
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  assignModal = row => {
    this.setState({
      modalAssignShow: true,
      id: row.id
    });
  };

  assignmentModal = row => {
    this.setState({
      modalAssignmentShow: true,
      id: row.id
    });
  };

  newEquipmentModal = row => {
    this.setState({
      modalNewEquipmentShow: true,
      id: row.id
    });
  };

  qrCodeModal = row => {
    this.setState({
      modalQRCodeShow: true,
      id: row.id
    });
  };

  statusModal = row => {
    this.setState(
      {
        modalStatusShow: true,
        id: row.id
      },
      async () => {
        var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getStatus/" + row.id);
        if (response.type === "ERROR") {
          error(response);
        }

        let data = response.data;
        this.formRefCurrentStatus.current.setFieldsValue({
          ...data,
          kmDate: Boolean(data.kmDate) ? moment(data.kmDate) : null,
          hourDate: Boolean(data.hourDate) ? moment(data.hourDate) : null,
          maintenanceDate: Boolean(data.maintenanceDate) ? moment(data.maintenanceDate) : null,
          fuelConsumptionDate: Boolean(data.fuelConsumptionDate) ? moment(data.fuelConsumptionDate) : null
        });
      }
    );
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  handleTabChange = key => {
    //console.log(key);
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  getSelectedFiles = field => {
    if (Boolean(field) && Boolean(field.fileList) && field.fileList.length > 0) {
      let files = "";
      files = field.fileList[0].response.url;
      for (let i = 1; i < field.fileList.length; i++) {
        if (Boolean(field.fileList[i].response)) {
          files += "," + field.fileList[i].response.url;
        }
      }
      return files;
    }
    return field;
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      parkingLogId: Boolean(values.parkingLogId) ? values.parkingLogId[values.parkingLogId.length - 1] : null,
      eldenEquipmentTreeId: Boolean(values.eldenEquipmentTreeId) ? values.eldenEquipmentTreeId[values.eldenEquipmentTreeId.length - 1] : null
    };

    newItem.photoPath = PrepareFileUploadSave(newItem.photoPath);

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      parkingLogId: Boolean(this.state.parkingLogId) ? this.state.parkingLogId : null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          if (Array.isArray(element))
            element.forEach(element2 => {
              let obj = { columns: element2 };
              list.push(obj);
            });
          else {
            let obj = { columns: element };
            list.push(obj);
          }
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/importDynamicaly", list);
          if (response.type === "ERROR") {
            error(response);
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          error("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let identificationAction = {
      name: "Identification",
      icon: <ReplyOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.identificationModal.bind(this)
    };

    let assignAction = {
      name: "Assign",
      icon: <Assignment fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.assignmentModal.bind(this)
    };
    let qrCodeAction = {
      name: "QR Code",
      icon: <Assignment fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.qrCodeModal.bind(this)
    };
    let statusAction = {
      name: "Status",
      icon: <Assignment fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.statusModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);

    if (Boolean(record.eldenEquipmentTreeId) && record.eldenEquipmentTreeId > 0) {
      actionList.push(identificationAction);
    }

    actionList.push(assignAction);
    actionList.push(qrCodeAction);
    actionList.push(statusAction);
    actionList.push(deleteAction);
    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  identificationModal = record => {
    this.setState({
      id: record.id,
      eldenEquipmentTreeId: record.eldenEquipmentTreeId,
      drawerIdentificationCriteriasShow: true,
      random: Math.random()
    });
  };

  documentControlModal = record => {
    this.setState({
      id: record.id,
      drawerSettingsShow: true
    });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  onSelectChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys: selectedRowKeys
    });
  };

  saveEldenEquipmentTree = async values => {
    const newItem = {
      ...values,
      parentId: Boolean(values.parentId) ? values.parentId[values.parentId.length - 1] : null
    };
    let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveEldenEquipmentTree", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
      this.fillComboboxes();
      this.setState({ showNewEquipmentModal: false });
    }
  };

  saveType = async () => {
    const { selectedRowKeys } = this.state;
    if (Boolean(selectedRowKeys) && selectedRowKeys.length > 0) {
      let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveType/" + selectedRowKeys);
      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess();
        this.fillComboboxes();
        this.setState({ modalTypeShow: false });
      }
    } else {
      showWarning("Please select item");
    }
  };

  saveGroup = async () => {
    const { selectedRowKeys } = this.state;
    if (Boolean(selectedRowKeys) && selectedRowKeys.length > 0) {
      let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveGroup/" + selectedRowKeys);
      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess();
        this.fillComboboxes();
        this.setState({ modalGroupShow: false });
      }
    } else {
      showWarning("Please select item");
    }
  };

  handleParkingIdChange = async value => {
    let parkingLogId = Boolean(value) && value.length > 0 ? value[value.length - 1] : null;
    this.setState(
      {
        parkingLogId: parkingLogId
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  fillParking = async projectId => {
    if (Boolean(projectId) && projectId > 0) {
      const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/parkingTree/" + projectId);
      if (responseTree.data.length !== 0) {
        generateList(responseTree.data);
        this.setState({
          nodes: responseTree.data
        });
      } else {
        this.setState({
          nodes: [],
          parkingLogId: null
        });
        this.formRef.current.setFieldsValue({
          parkingLogId: null
        });
      }
    } else {
      this.setState({
        nodes: [],
        parkingLogId: null
      });
      this.formRef.current.setFieldsValue({
        parkingLogId: null
      });
    }
  };

  showReport = async rowData => {
    try {
      if (rowData.photoPath !== null && rowData.photoPath !== undefined && rowData.photoPath !== "") {
        const fileRealUrl = rowData.photoPath;
        window.location.href = fileRealUrl;
      } else {
        this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
      }
    } catch (error) {
      this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout2 = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 }
    };

    const layout3 = {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 }
    };

    const layout4 = {
      labelCol: { span: 10 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="EquipmentLog.Parking" defaultMessage="Parking" />,
        key: "parkingLogId",
        render: record => {
          return record.parkingLogName;
        },
        ...getColumnFilter({
          inputName: "parkingLogId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["filterParkingIdSelectItems"]) ? this.state["filterParkingIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EquipmentLog.equipment" defaultMessage="Equipment" />,
        key: "eldenEquipmentTreeId",
        render: record => {
          return record.eldenEquipmentTreeName;
        },
        ...getColumnFilter({
          inputName: "eldenEquipmentTreeId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["eldenEquipmentTreeIdSelectItems"]) ? this.state["eldenEquipmentTreeIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EquipmentLog.Code" defaultMessage="Code" />,
        key: "equipmentCode",
        render: record => {
          return record.equipmentCode;
        },
        ...getColumnFilter({
          inputName: "equipmentCode",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EquipmentLog.Ownership" defaultMessage="Ownership" />,
        key: "ownership",
        render: record => {
          return record.code;
        }
        // ...getColumnFilter({
        //   inputName: "code",
        //   inputType: "TEXT",
        //   selectItems: [],
        //   filterChangeItem: this.filterChangeItem,
        //   searchCriteriaModels: this.state.searchCriteriaModels
        // }),
        // sorter: true
      },

      {
        title: <FormattedMessage id="EldenEquipmentGroup.Photo" defaultMessage="Photo" />,
        key: "photoPath",
        render: record => {
          if (!Boolean(record.photoPath)) {
            return "";
          }
          return record.photoPath.endsWith(".png") ||
            record.photoPath.endsWith(".jpg") ||
            record.photoPath.endsWith(".tiff") ||
            record.photoPath.endsWith(".jpeg") ||
            record.photoPath.endsWith(".PNG") ||
            record.photoPath.endsWith(".JPG") ||
            record.photoPath.endsWith(".TIFF") ||
            record.photoPath.endsWith(".JPEG") ? (
            <Image width={80} src={record.photoPath} />
          ) : (
            <AntButton
              style={{
                marginLeft: "2px",
                marginTop: "-2px",
                alignContent: "center"
              }}
              onClick={() => this.showReport(record)}
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
            ></AntButton>
          );
        }
      },

      {
        title: <FormattedMessage id="EquipmentLog.ComingAction" defaultMessage="Coming Action" />,
        key: "comingAction",
        render: record => {
          return record.comingAction;
        }
        // ...getColumnFilter({
        //   inputName: "comingAction",
        //   inputType: "TEXT",
        //   selectItems: [],
        //   filterChangeItem: this.filterChangeItem,
        //   searchCriteriaModels: this.state.searchCriteriaModels
        // }),
        // sorter: true
      },

      {
        title: <FormattedMessage id="EquipmentLog.Status" defaultMessage="Status" />,
        key: "status",
        render: record => {
          return record.status;
        }
        // ...getColumnFilter({
        //   inputName: "comingAction",
        //   inputType: "TEXT",
        //   selectItems: [],
        //   filterChangeItem: this.filterChangeItem,
        //   searchCriteriaModels: this.state.searchCriteriaModels
        // }),
        // sorter: true
      },

      {
        title: "Last Update",
        key: "lastUpdated",
        ellipsis: true,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const columnsKm = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="EquipmentLog.Date" defaultMessage="Date" />,
        key: "date",
        render: record => {
          return Boolean(record.date) ? moment(record.date).format("DD-MM-YYYY HH:mm") : "";
        }
      },
      {
        title: <FormattedMessage id="EquipmentLog.Hour" defaultMessage="Hour" />,
        key: "hour",
        render: record => {
          return record.hour;
        }
      },
      {
        title: <FormattedMessage id="EquipmentLog.Action" defaultMessage="Action" />,
        key: "action",
        render: record => {
          return record.action;
        }
      }
    ];

    const columnsMaintenance = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="EquipmentLog.Date" defaultMessage="Date" />,
        key: "date",
        render: record => {
          return Boolean(record.date) ? moment(record.date).format("DD-MM-YYYY HH:mm") : "";
        }
      },
      {
        title: <FormattedMessage id="EquipmentLog.MaintenanceType" defaultMessage="Maintenance Type" />,
        key: "maintenanceType",
        render: record => {
          return record.maintenanceType;
        }
      }
    ];

    const columnsFuel = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="EquipmentLog.Date" defaultMessage="Date" />,
        key: "date",
        render: record => {
          return Boolean(record.date) ? moment(record.date).format("DD-MM-YYYY HH:mm") : "";
        }
      },
      {
        title: <FormattedMessage id="EquipmentLog.FuelType" defaultMessage="Fuel Type" />,
        key: "fuelType",
        render: record => {
          return record.fuelType;
        }
      },
      {
        title: <FormattedMessage id="EquipmentLog.AmountOfFuel" defaultMessage="Amount of Fuel" />,
        key: "amountOfFuel",
        render: record => {
          return record.amountOfFuel;
        }
      },
      {
        title: <FormattedMessage id="EquipmentLog.LtKm" defaultMessage="Lt/Km" />,
        key: "ltKm",
        render: record => {
          return record.ltKm;
        }
      },
      {
        title: <FormattedMessage id="EquipmentLog.Average" defaultMessage="Average" />,
        key: "average",
        render: record => {
          return record.average;
        }
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
              <ExcelColumn label="Name" value="name" />
              <ExcelColumn label="Arabic Name" value="arabic_name" />
              <ExcelColumn label="Customer" value="customer" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    const normFile = e => {
      console.log("Upload event:", e);

      if (Array.isArray(e)) {
        return e;
      }

      return e && e.fileList;
    };

    return (
      <Badge.Ribbon text="Equipment Log" placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <br />
            <Row gutter={[16, 16]}>
              <Col md={8}> </Col>
              <Col md={5}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EquipmentLogMain.Branch" defaultMessage="Branch" />}
                    name="projectId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      style={{ width: "150px" }}
                      optionFilterProp="children"
                      onChange={value => {
                        this.fillParking(value);
                        if (!Boolean(value)) {
                          const { pagination } = this.state;
                          this.restartTable({ pagination });
                        }
                      }}
                    >
                      {Boolean(this.state["projectIdSelectItems"]) &&
                        this.state["projectIdSelectItems"].length > 0 &&
                        this.state["projectIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }
              </Col>
              <Col md={5}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EquipmentLogMain.Parking" defaultMessage="Parking" />}
                    name="parkingLogId"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Cascader changeOnSelect style={{ width: "150px" }} options={this.state.nodes} onChange={this.handleParkingIdChange} />
                  </Form.Item>
                }
              </Col>
              <Col md={1}></Col>
              <Col md={1}>
                <Tooltip title={<FormattedMessage id="EquipmentLogMain.Assign" defaultMessage="Assign" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => {
                      this.setState({
                        modalAssignShow: true,
                        random: Math.random()
                      });
                    }}
                    icon={<Assignment fontSize="large" />}
                  ></AntButton>
                </Tooltip>
              </Col>
              <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReaderReadAllWorkSheets onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReaderReadAllWorkSheets>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>

            <div hidden={this.state.hideInputs}>
              <Row gutter={16} style={{ marginBottom: "16px" }}>
                <br></br>
                <br></br>
                <br></br>
                {/* Sol Card */}
                <Col span={1}> </Col>
                <Col span={11}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Form.Item {...layout3} label={<FormattedMessage id="EquipmentLogMain.Equipment" defaultMessage="Equipment" />}>
                      <Space.Compact style={{ width: "100%" }}>
                        <Form.Item
                          name="eldenEquipmentTreeId"
                          style={{ width: "90%" }}
                          rules={[
                            {
                              required: true,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Cascader
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            changeOnSelect
                            style={{ width: "100%" }}
                            options={this.state.eldenEquipmentTreeIdSelectItems}
                            onChange={value => {
                              this.setState(
                                {
                                  eldenEquipmentTreeId: Boolean(value) && value.length > 0 ? value[value.length - 1] : null
                                },
                                async () => {
                                  if (Boolean(this.state.eldenEquipmentTreeId) && this.state.eldenEquipmentTreeId > 0) {
                                    var response = await handleRequest(
                                      "GET",
                                      "/api/" + this.state.controllerName + "/equipmentTreeTypeCheck/" + this.state.eldenEquipmentTreeId
                                    );
                                    if (response.type === "ERROR") {
                                      error(response);
                                    }
                                    if (!response.data) {
                                      showWarning("Equipment type is not Equipment");
                                    }
                                  }
                                }
                              );
                            }}
                          />
                        </Form.Item>

                        <AntButton
                          type="default"
                          style={{
                            color: "black",
                            background: "lightgrey",
                            width: "10%"
                          }}
                          onClick={() => {
                            this.setState(
                              {
                                showNewEquipmentModal: true
                              },
                              () => {
                                this.formRefNewEquipmentTypes.current.resetFields();
                              }
                            );
                          }}
                          icon={<Add />}
                        ></AntButton>
                      </Space.Compact>
                    </Form.Item>
                    {
                      <Form.Item {...layout3} label={<FormattedMessage id="EquipmentLog.Ownership" defaultMessage="Ownership" />}>
                        <Row gutter={4}>
                          <Col span={1}></Col>
                          <Col span={10}>
                            <Form.Item name="companyEquipment" valuePropName="checked" noStyle>
                              <Checkbox>{this.state.companyName}</Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={2}></Col>
                          <Col span={10}>
                            <Form.Item name="rental" valuePropName="checked" noStyle>
                              <Checkbox>
                                <FormattedMessage id="EquipmentLog.Rental" defaultMessage="Rental" />
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout3}
                        label={<FormattedMessage id="EquipmentLog.BudgeNo" defaultMessage="Budge No" />}
                        name="budgeNo"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout4}
                        label={<FormattedMessage id="EquipmentLog.EstimatedNextMaintenance" defaultMessage="Estimated Next Maintenance" />}
                        name="estimatedNextMaintenanceDate"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime />
                      </Form.Item>
                    }
                  </Card>
                </Col>
                <br></br>
                <br></br>
                {/* Sağ Card */}
                <Col span={11}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="EquipmentLog.PlateNo" defaultMessage="Plate No" />}
                        name="plateNo"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="EquipmentLog.FirstStartDate" defaultMessage="First Start Date" />}
                        name="firstStartDate"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="EquipmentLog.km" defaultMessage="Km" />}
                        name="km"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="EquipmentLog.hour" defaultMessage="Hour" />}
                        name="hour"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="EquipmentLog.Photo" defaultMessage="Photo" />}
                        name="photoPath"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...props}>
                          <AntButton icon={<UploadOutlined />}>
                            <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                          </AntButton>
                        </Upload>
                      </Form.Item>
                    }
                  </Card>
                </Col>
                <Col span={1}> </Col>
              </Row>

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              rowKey="id"
              size="small"
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
              // expandable={{
              //   expandedRowRender: record => Boolean(record) && <EquipmentLogDetail selectedVendor={record}></EquipmentLogDetail>,
              //   rowExpandable: () => {
              //     return true;
              //   }
              // }}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          <Drawer
            title={<FormattedMessage id="EquipmentLogMain.Details" defaultMessage="Details" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ drawerSettingsShow: false });
            }}
            open={this.state.drawerSettingsShow}
          >
            <EquipmentLogMainDrawer equipmentLogId={this.state.id} />
          </Drawer>

          <DraggableModal
            title={<FormattedMessage id="EquipmentLogMain.Group" defaultMessage="Group" />}
            centered
            width={1200}
            open={this.state.modalGroupShow}
            onCancel={() => this.setState({ modalGroupShow: false })}
            okButtonProps={{ hidden: false }}
            onOk={() => this.saveGroup()}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={<EldenEquipmentGroupSelectable random={Math.random()} onSelectChange={this.onSelectChange} />}
          />

          <DraggableModal
            title={<FormattedMessage id="EquipmentLogMain.Assign" defaultMessage="Assign" />}
            centered
            width={1400}
            open={this.state.modalAssignShow}
            onCancel={() => this.setState({ modalAssignShow: false })}
            okButtonProps={{ hidden: true }}
            onOk={() => this.saveType()}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={<EquipmentLogAssign random={this.state.random} />}
          />

          <DraggableModal
            title={<FormattedMessage id="EquipmentLogMain.Type" defaultMessage="Type" />}
            centered
            width={1200}
            open={this.state.modalTypeShow}
            onCancel={() => this.setState({ modalTypeShow: false })}
            okButtonProps={{ hidden: false }}
            onOk={() => this.saveType()}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={<EldenEquipmentTypeSelectable random={Math.random()} onSelectChange={this.onSelectChange} />}
          />

          <DraggableModal
            title={<FormattedMessage id="EquipmentLogMain.QRCode" defaultMessage="QR Code" />}
            centered
            width={1200}
            open={this.state.modalQRCodeShow}
            onCancel={() => this.setState({ modalQRCodeShow: false })}
            okButtonProps={{ hidden: false }}
            onOk={() => this.saveType()}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={<EldenEquipmentTypeSelectable random={Math.random()} onSelectChange={this.onSelectChange} />}
          />

          <DraggableModal
            title={<FormattedMessage id="EquipmentLogMain.CurrentStatus" defaultMessage="Current Status" />}
            centered
            width={800}
            open={this.state.modalStatusShow}
            onCancel={() => this.setState({ modalStatusShow: false })}
            okButtonProps={{ hidden: true }}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={
              <Form initialValues={{ remember: false }} ref={this.formRefCurrentStatus}>
                <>
                  <br></br>
                  <Row gutter={[16, 16]}>
                    <Col md={6}></Col>
                    <Col md={13}>
                      <Form.Item>
                        <Button style={{ width: "100%" }} variant="success" fontSize="large">
                          <FormattedMessage id="EquipmentLog.ACTIVE" defaultMessage="ACTIVE" />
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col md={3}></Col>
                  </Row>
                  <Form.Item {...layout2} label={<FormattedMessage id="EquipmentLog.Km" defaultMessage="Km" />} style={{ marginBottom: "5px" }}>
                    <Row gutter={8}>
                      <Col span={10}>
                        <Form.Item name="km">
                          <Input
                            style={{
                              width: "100%"
                            }}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item name="kmDate">
                          <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime disabled />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <AntButton
                          style={{ border: "0px" }}
                          onClick={async () => {
                            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getKmDetail/" + this.state.id);
                            if (response.type === "ERROR") {
                              error(response);
                            }
                            this.setState({
                              tableListKm: response.data,
                              modalKmShow: true
                            });
                          }}
                          icon={<FileSearchOutlined fontSize="large" />}
                        ></AntButton>
                      </Col>
                    </Row>
                  </Form.Item>

                  <Form.Item {...layout2} label={<FormattedMessage id="EquipmentLog.Hour" defaultMessage="Hour" />} style={{ marginBottom: "5px" }}>
                    <Row gutter={8}>
                      <Col span={10}>
                        <Form.Item name="hour">
                          <Input
                            style={{
                              width: "100%"
                            }}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item name="hourDate">
                          <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime disabled />
                        </Form.Item>
                      </Col>
                      <Col span={2}></Col>
                    </Row>
                  </Form.Item>

                  <Form.Item
                    {...layout2}
                    label={<FormattedMessage id="EquipmentLog.Maintenance" defaultMessage="Maintenance" />}
                    style={{ marginBottom: "5px" }}
                  >
                    <Row gutter={8}>
                      <Col span={10}>
                        <Form.Item name="maintenanceType">
                          <Input
                            style={{
                              width: "100%"
                            }}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item name="maintenanceDate">
                          <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime disabled />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <AntButton
                          style={{ border: "0px" }}
                          onClick={async () => {
                            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getMaintenanceDetail/" + this.state.id);
                            if (response.type === "ERROR") {
                              error(response);
                            }
                            this.setState({
                              tableListMaintenance: response.data,
                              modalMaintenanceShow: true
                            });
                          }}
                          icon={<FileSearchOutlined fontSize="large" />}
                        ></AntButton>
                      </Col>
                    </Row>
                  </Form.Item>

                  <Form.Item
                    {...layout2}
                    label={<FormattedMessage id="EquipmentLog.FuelConsumption" defaultMessage="Fuel Consumption" />}
                    style={{ marginBottom: "5px" }}
                  >
                    <Row gutter={8}>
                      <Col span={10}>
                        <Form.Item name="fuelConsumption">
                          <Input
                            style={{
                              width: "100%"
                            }}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item name="fuelConsumptionDate">
                          <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime disabled />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <AntButton
                          style={{ border: "0px" }}
                          onClick={async () => {
                            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getFuelDetail/" + this.state.id);
                            if (response.type === "ERROR") {
                              error(response);
                            }
                            this.setState({
                              tableListFuel: response.data,
                              modalFuelShow: true
                            });
                          }}
                          icon={<FileSearchOutlined fontSize="large" />}
                        ></AntButton>
                      </Col>
                    </Row>
                  </Form.Item>
                </>
              </Form>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EquipmentLogMain.Asignment" defaultMessage="Asignment" />}
            centered
            width={1200}
            open={this.state.modalAssignmentShow}
            onCancel={() => this.setState({ modalAssignmentShow: false })}
            okButtonProps={{ hidden: false }}
            onOk={() => this.saveType()}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={
              <>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EquipmentLog.CurrentAssignment" defaultMessage="Current Assignment(s)" />}
                    name="currentAssignment"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Row gutter={5}>
                      <Col span={8}>
                        <Input style={{ width: "100%" }} />
                      </Col>
                      <Col span={2}></Col>
                      <Col span={6}>
                        <AntButton
                          style={{ border: "0px" }}
                          onClick={() => {
                            // this.setState({
                            //   modalTypeShow: true
                            // });
                          }}
                          icon={<TextFields fontSize="large" />}
                        ></AntButton>
                      </Col>
                    </Row>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EquipmentLog.AssignTo" defaultMessage="Assign To" />}
                    name="assignToId"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                      {Boolean(this.state["groupIdSelectItems"]) &&
                        this.state["groupIdSelectItems"].length > 0 &&
                        this.state["groupIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EquipmentLog.AssignmentPeriod" defaultMessage="Assignment Period" />}
                    name="assignmentPeriod"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EquipmentLog.Operator" defaultMessage="Operator" />}
                    name="operatorId"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                      {Boolean(this.state["groupIdSelectItems"]) &&
                        this.state["groupIdSelectItems"].length > 0 &&
                        this.state["groupIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EquipmentLog.Transportation" defaultMessage="Transportation" />}
                    name="transportationId"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                      {Boolean(this.state["groupIdSelectItems"]) &&
                        this.state["groupIdSelectItems"].length > 0 &&
                        this.state["groupIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }

                {/* <EquipmentLogAssign random={this.state.random} /> */}
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EquipmentLogMain.NewEquipment" defaultMessage="New Equipment" />}
            centered
            width={800}
            open={this.state.showNewEquipmentModal}
            onCancel={() => this.setState({ showNewEquipmentModal: false })}
            okButtonProps={{ hidden: false }}
            onOk={() => this.formRefNewEquipmentTypes.current.submit()}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={
              <Form initialValues={{ remember: false }} onFinish={this.saveEldenEquipmentTree} ref={this.formRefNewEquipmentTypes}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EquipmentLogMain.Parent" defaultMessage="Parent" />}
                    name="parentId"
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Cascader
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      changeOnSelect
                      style={{ width: "100%" }}
                      options={this.state.eldenEquipmentTreeIdSelectItems}
                    />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EquipmentLog.Equipment" defaultMessage="Equipment" />}
                    name="name"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            }
          />
          <Drawer
            title={<FormattedMessage id="EquipmentLogMain.Identification" defaultMessage="Identification" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ drawerIdentificationCriteriasShow: false });
            }}
            open={this.state.drawerIdentificationCriteriasShow}
          >
            <EquipmentLogIdentificationDrawer
              random={this.state.random}
              eldenEquipmentTreeId={this.state.eldenEquipmentTreeId}
              equipmentLogId={this.state.id}
            />
          </Drawer>

          <DraggableModal
            title={<FormattedMessage id="EquipmentLogMain.Km" defaultMessage="Km" />}
            centered
            width={1200}
            open={this.state.modalKmShow}
            onCancel={() => this.setState({ modalKmShow: false })}
            okButtonProps={{ hidden: true }}
            onOk={() => this.saveType()}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={
              <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                <Table
                  bordered
                  rowKey="id"
                  size="small"
                  scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                  columns={columnsKm}
                  dataSource={this.state.tableListKm}
                  loading={this.state.loading}
                  pagination={false}
                />
              </div>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EquipmentLogMain.Maintenance" defaultMessage="Maintenance" />}
            centered
            width={1200}
            open={this.state.modalMaintenanceShow}
            onCancel={() => this.setState({ modalMaintenanceShow: false })}
            okButtonProps={{ hidden: true }}
            onOk={() => this.saveType()}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={
              <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                <Table
                  bordered
                  rowKey="id"
                  size="small"
                  scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                  columns={columnsMaintenance}
                  dataSource={this.state.tableListMaintenance}
                  loading={this.state.loading}
                  pagination={false}
                />
              </div>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EquipmentLogMain.Fuel" defaultMessage="Fuel" />}
            centered
            width={1200}
            open={this.state.modalFuelShow}
            onCancel={() => this.setState({ modalFuelShow: false })}
            okButtonProps={{ hidden: true }}
            onOk={() => this.saveType()}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={
              <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                <Table
                  bordered
                  rowKey="id"
                  size="small"
                  scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                  columns={columnsFuel}
                  dataSource={this.state.tableListFuel}
                  loading={this.state.loading}
                  pagination={false}
                />
              </div>
            }
          />
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentLogMain);
