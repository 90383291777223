import "antd/dist/antd.css";
import moment from "moment";
import { connect } from "react-redux";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Space, DatePicker, Select, InputNumber, Typography, Cascader } from "antd";
import ActionMenu from "../CustomMenu/ActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Input } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Option } = Select;

//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    dataIndex === "plannedDeliveryDate" || dataIndex === "paymentDate"
      ? form.setFieldsValue({
          [dataIndex]: Boolean(record[dataIndex]) ? moment(record[dataIndex], "DD-MM-YYYY") : ""
        })
      : form.setFieldsValue({
          [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
        });
  };

  const toggleSave = async () => {
    try {
      if (dataIndex === "plannedDeliveryDate") {
        const values = await form.validateFields();
        values.plannedDeliveryDate = Boolean(values.plannedDeliveryDate) ? moment(values.plannedDeliveryDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else if (dataIndex === "paymentDate") {
        const values = await form.validateFields();
        values.paymentDate = Boolean(values.paymentDate) ? moment(values.paymentDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record }, { ...values });
      }
    } catch (errInfo) {}
  };

  const searchInventoryId = async (item, record) => {
    var response = await handleRequest("GET", `/api/salesQuotationDetailReply/inventoryId/${Boolean(item) && item.length > 0 ? item : "''"}`);
    if (response.type === "ERROR") {
      error(response);
      record.inventoryIdSelectItems = [];
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        record.inventoryIdSelectItems = response.data;
      } else {
        record.inventoryIdSelectItems = [];
      }
    }
  };

  const searchInventoryLogMainId = async (item, record) => {
    var response = await handleRequest("GET", `/api/salesQuotationDetailReply/inventoryLogMainId/${Boolean(item) && item.length > 0 ? item : "''"}`);
    if (response.type === "ERROR") {
      error(response);
      record.inventoryLogMainIdSelectItems = [];
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        record.inventoryLogMainIdSelectItems = response.data;
      } else {
        record.inventoryLogMainIdSelectItems = [];
      }
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <>
        {dataIndex === "deliveryPlace" ? (
          <>
            <Form.Item
              label={<FormattedMessage id="BillOfQuantityVendorReply.town" defaultMessage="Town" />}
              name="town"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Form.Item
              label={<FormattedMessage id="BillOfQuantityVendorReply.city" defaultMessage="City" />}
              name="city"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Button id="SaveButton" onClick={toggleSave} style={{ width: "100%" }} variant="success">
              <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
            </Button>
          </>
        ) : (
          <Form.Item
            style={{
              margin: 0
            }}
            name={dataIndex}
          >
            {dataIndex === "plannedDeliveryDate" || dataIndex === "paymentDate" ? (
              <DatePicker ref={inputRef} onBlur={toggleSave} format={"DD-MM-YYYY"} />
            ) : dataIndex === "productTreeId" ? (
              <Select ref={inputRef} onChange={toggleSave} onBlur={toggleSave} showSearch optionFilterProp="children">
                {Boolean(record.productTreeIdSelectItems) &&
                  record.productTreeIdSelectItems.length > 0 &&
                  record.productTreeIdSelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            ) : dataIndex === "inventoryId" ? (
              <Select
                ref={inputRef}
                onChange={toggleSave}
                onBlur={toggleSave}
                showSearch
                optionFilterProp="children"
                allowClear
                onSearch={value => searchInventoryId(value, record)}
                style={{ marginBottom: 0, width: "150px" }}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {Boolean(record.inventoryIdSelectItems) &&
                  record.inventoryIdSelectItems.length > 0 &&
                  record.inventoryIdSelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            ) : dataIndex === "inventoryLogMainId" ? (
              <Select
                ref={inputRef}
                onChange={toggleSave}
                onBlur={toggleSave}
                showSearch
                optionFilterProp="children"
                allowClear
                onSearch={value => searchInventoryLogMainId(value, record)}
                style={{ marginBottom: 0, width: "150px" }}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {Boolean(record.inventoryLogMainIdSelectItems) &&
                  record.inventoryLogMainIdSelectItems.length > 0 &&
                  record.inventoryLogMainIdSelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            ) : dataIndex === "currency" ? (
              <Select ref={inputRef} onChange={toggleSave} onBlur={toggleSave} showSearch optionFilterProp="children">
                {Boolean(record.currencySelectItems) &&
                  record.currencySelectItems.length > 0 &&
                  record.currencySelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            ) : dataIndex === "unit" ? (
              <Cascader style={{ width: "100%" }} options={record.unitIdSelectItems} ref={inputRef} onChange={toggleSave} onBlur={toggleSave} />
            ) : (
              <InputNumber
                parser={value => value.replace(",", ".")}
                formatter={value => value.replace(".", ",")}
                min={0}
                ref={inputRef}
                onPressEnter={toggleSave}
                onBlur={toggleSave}
              />
            )}
          </Form.Item>
        )}
      </>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion

export class SalesQuotationDetailReply extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "SalesQuotationDetailReply",
      controllerName: "salesQuotationDetailReply",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      customerId: null,
      maxTableIndex: 0,
      type: "",

      tableList: [],
      pagination: {
        current: 1,
        pageSize: 5,
        showSizeChanger: true,
        pageSizeOptions: ["2", "5", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseStockCardId = await handleRequest("GET", "/api/" + this.state.controllerName + "/productTreeId");
    if (responseStockCardId.type === "ERROR") {
      error(responseStockCardId);
    }
    var responseCustomerId = await handleRequest("GET", "/api/" + this.state.controllerName + "/customerId");
    if (responseCustomerId.type === "ERROR") {
      error(responseCustomerId);
    }
    var responseAllUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/allUnit");
    if (responseAllUnit.type === "ERROR") {
      error(responseAllUnit);
    }
    var responseCurrency = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responseCurrency.type === "ERROR") {
      error(responseCurrency);
    }

    this.setState(
      {
        productTreeIdSelectItems: Boolean(responseStockCardId.data) ? responseStockCardId.data : [],
        customerIdSelectItems: Boolean(responseCustomerId.data) ? responseCustomerId.data : [],
        allUnitSelectItems: Boolean(responseAllUnit.data) ? responseAllUnit.data : [],
        currencySelectItems: Boolean(responseCurrency.data) ? responseCurrency.data : [],
        customerId: null,
        tableList: [],
        type: this.props.type
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        maxTableIndex: response.data.totalRecords,
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
          element.key = element.index;
          element.productTreeIdSelectItems = this.state.productTreeIdSelectItems;
          element.currencySelectItems = this.state.currencySelectItems;
          element.unitIdSelectItems = this.state.unitIdSelectItems;
        });
        this.setState({
          loading: false,
          tableList: list,
          maxTableIndex: 0,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder,
          maxTableIndex: 0
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          maxTableIndex: 0,
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      salesQuotationId: this.props.salesQuotationId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <ActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 5,
        showSizeChanger: true,
        pageSizeOptions: ["2", "5", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const { tableList } = this.state;
    console.log("values", values);
    console.log("tableList", tableList);
    if (Boolean(this.props.salesQuotationId) && this.props.salesQuotationId > 0) {
      if (Boolean(tableList) && tableList.length > 0) {
        if (Boolean(this.state.tableList) && this.state.tableList.length > 0) {
          this.state.tableList.forEach(element => {
            if (Boolean(element.plannedDeliveryDate)) {
              element.plannedDeliveryDate = moment(element.plannedDeliveryDate, "DD-MM-YYYY");
            }
            if (Boolean(element.paymentDate)) {
              element.paymentDate = moment(element.paymentDate, "DD-MM-YYYY");
            }
          });
        }

        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveReply/" + this.props.salesQuotationId, tableList);
        if (response.type === "ERROR") {
          error(response);
        } else {
          this.cancel();
          const { pagination } = this.state;
          this.restartTable({ pagination });
          showSuccess();
          this.props.close();
        }
      } else {
        showWarning("Please select item.");
      }
    } else {
      showWarning("Sales Quotation not found.");
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleDeliveryPlace = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        item.town = value.town;
        item.city = value.city;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handlePlannedDeliveryDate = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.plannedDeliveryDate === value.plannedDeliveryDate) return;
        item.plannedDeliveryDate = value.plannedDeliveryDate;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handlePriceVat = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.priceVat === value.priceVat) return;
        item.priceVat = value.priceVat;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleCurrency = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.currency === value.currency) return;
        item.currency = value.currency;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleItemDescription = async (row, value) => {
    console.log("row", row);
    console.log("value", value);
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.productTreeId === value.productTreeId) return;

        console.log("item", item);
        console.log("value", value);
        item.productTreeId = value.productTreeId;

        let product = this.state.productTreeIdSelectItems.find(p => p.key === value.productTreeId);
        if (Boolean(product)) {
          item.productTreeName = product.value;
        }

        if (Boolean(value.productTreeId) && value.productTreeId > 0) {
          let responsePurchaseCriteria = await handleRequest(
            "GET",
            "/api/" + this.state.controllerName + "/purchasingcriterias/" + value.productTreeId
          );
          if (responsePurchaseCriteria.type === "ERROR") {
            error(responsePurchaseCriteria);
          } else {
            item.unitIdSelectItems =
              Boolean(responsePurchaseCriteria.data) && responsePurchaseCriteria.data.length > 0 ? responsePurchaseCriteria.data : [];
            this.setState({
              tableList: newData
            });
          }
        } else {
          item.unitIdSelectItems = [];
        }
      }
    }
  };

  handleItemDescriptionInventory = async (row, value) => {
    console.log("row", row);
    console.log("value", value);
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.inventoryId === value.inventoryId) return;

        console.log("item", item);
        console.log("value", value);
        item.inventoryId = value.inventoryId;

        let product = row.inventoryIdSelectItems?.find(p => p.key === value.inventoryId);
        if (Boolean(product)) {
          item.inventoryName = product.value;
        }

        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleItemDescriptionInventoryLogMain = async (row, value) => {
    console.log("row", row);
    console.log("value", value);
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.inventoryLogMainId === value.inventoryLogMainId) return;

        console.log("item", item);
        console.log("value", value);
        item.inventoryLogMainId = value.inventoryLogMainId;

        let product = row.inventoryLogMainIdSelectItems?.find(p => p.key === value.inventoryLogMainId);
        if (Boolean(product)) {
          item.inventoryLogMainName = product.value;
        }

        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleUnitPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.unitPrice === value.unitPrice) return;
        var newUnitPrice = item.unitPrice - value.unitPrice;
        if (Boolean(newUnitPrice) && newUnitPrice > 0) {
          row.unitPrice = newUnitPrice;
          row.oldUnitPrice = 0;
        }

        item.oldUnitPrice = item.unitPrice;
        item.unitPrice = value.unitPrice;
        item.price = item.quantity * item.unitPrice;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleQuantity = async (row, value) => {
    if (Boolean(value)) {
      const maxTableIndex = this.state.maxTableIndex + 1;
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);

      console.log("item", item);
      console.log("value", value);

      if (Boolean(value) && Boolean(item)) {
        if (item.quantity === value.quantity) return;
        var newQuantity = item.quantity - value.quantity;
        if (Boolean(newQuantity) && newQuantity > 0) {
          row.quantity = newQuantity;
          row.oldQuantity = 0;
          row.key = maxTableIndex;
          var newObj = row;
          newObj.selected = false;
          newObj.sourceKey = item.key;
          newObj.unit = item.defaultUnit;
          newObj.unitId = item.defaultUnitId;
          newData.push(newObj);
        }
        item.oldQuantity = item.quantity;
        item.quantity = value.quantity;
        item.price = item.quantity * item.unitPrice;
        item.unit = item.defaultUnit;
        item.unitId = item.defaultUnitId;
        this.setState({
          tableList: newData,
          maxTableIndex: maxTableIndex
        });
        console.log("newData", newData);

        if (!(Boolean(newQuantity) && newQuantity > 0)) {
          this.setState({
            selectedQuantityKey: item.key,
            quantityDescription: item.quantityDescription,
            showQuantityDescriptionModal: true
          });
        }
      }
    }
  };

  handleUnit = async (row, value) => {
    if (Boolean(value) && Boolean(value.unit) && value.unit.length > 0) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(item)) {
        let unitId = Boolean(value.unit) && value.unit.length > 0 ? value.unit[value.unit.length - 1] : null;
        let unitTypeId = Boolean(value.unit) && value.unit.length > 0 ? value.unit[0] : null;
        if (!isNaN(unitId) && !isNaN(unitTypeId)) {
          item.unitId = unitId;
          console.log("row", row);
          console.log("unitTypeId", unitTypeId);
          const unitType = row.unitIdSelectItems.find(p => p.value === unitTypeId);
          if (Boolean(unitType) && Boolean(unitType.children) && unitType.children.length > 0) {
            const unit = unitType.children.find(p => p.value === unitId);
            if (Boolean(unit)) {
              item.unit = unit.label;
            }
          }
          this.setState({
            tableList: newData
          });
        }
      }
    }
  };

  addNewRow = row => {
    const maxTableIndex = this.state.maxTableIndex + 1;
    const newData = [...this.state.tableList];
    if (Boolean(row)) {
      const item = newData.find(item => row.key === item.key);
      if (Boolean(item)) {
        let newObj = { row: row };
        newData.push({ ...newObj.row, key: maxTableIndex, id: null });
        this.setState({
          tableList: newData,
          maxTableIndex: maxTableIndex
        });
      }
    } else {
      let newObj = {
        productTreeIdSelectItems: this.state.productTreeIdSelectItems,
        currencySelectItems: this.state.currencySelectItems,
        unitIdSelectItems: this.state.unitIdSelectItems
      };
      newData.push({ ...newObj, key: maxTableIndex, id: null });
      this.setState({
        tableList: newData,
        maxTableIndex: maxTableIndex
      });
    }
  };

  removeRow = row => {
    if (Boolean(row.key)) {
      this.setState({
        tableList: this.state.tableList.filter(p => p.key !== row.key)
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    };

    const getAdditionalColumn = () => {
      const type = this.state.type;
      let key, dataIndex, titleId, handleSave, render;

      switch (type) {
        case "Client_Tender":
          key = "productTreeId";
          dataIndex = "productTreeId";
          titleId = "SalesQuotationDetailCreate.ItemDescription";
          handleSave = this.handleItemDescription;
          render = record => record.productTreeName;
          break;

        case "Product_List":
          key = "inventoryId";
          dataIndex = "inventoryId";
          titleId = "SalesQuotationDetailCreate.ItemDescription";
          handleSave = this.handleItemDescriptionInventory;
          render = record => record.inventoryName;
          break;

        case "Inventory_List":
          key = "inventoryLogMainId";
          dataIndex = "inventoryLogMainId";
          titleId = "SalesQuotationDetailCreate.ItemDescription";
          handleSave = this.handleItemDescriptionInventoryLogMain;
          render = record => record.inventoryLogMainName;
          break;

        default:
          return null;
      }

      return {
        title: <FormattedMessage id={titleId} defaultMessage="Item Description" />,
        key,
        width: 100,
        render,
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex,
          title: <FormattedMessage id={titleId} defaultMessage="Item Description" />,
          handleSave
        })
      };
    };
    const additionalColumn = getAdditionalColumn();

    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      ...(additionalColumn ? [additionalColumn] : []),
      {
        title: <FormattedMessage id="SalesQuotationDetailReply.Quantity" defaultMessage="Quantity" />,
        key: "quantity",
        width: 100,
        render: record => {
          let weight = "";
          if (record.unit && Boolean(record.weight) && Boolean(record.quantity)) {
            if (Boolean(this.state.allUnitSelectItems) && this.state.allUnitSelectItems.length > 0) {
              let unit = this.state.allUnitSelectItems.find(p => p.abbreviation === record.unit);
              if (Boolean(unit.abbreviation) && unit.abbreviation === "ad") {
                weight = record.quantity;
              } else {
                if (unit.unitTypeName === "Length") {
                  if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                    weight = (record.length * record.quantity) / unit.coefficent;
                  } else {
                    weight = record.length * record.quantity;
                  }
                } else {
                  if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                    weight = (record.quantity * record.weight) / unit.coefficent;
                  } else {
                    weight = record.weight * record.quantity;
                  }
                }
              }
            }
          }
          //let quantity = record.quantity;
          let quantity = weight.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");
          if (!Boolean(quantity)) {
            quantity = record.quantity;
          }

          return Boolean(record.oldQuantity) && record.oldQuantity > 0 ? (
            <Badge showZero offset={[25, -15]} overflowCount={record.oldQuantity + 1} count={record.oldQuantity}>
              {quantity}
            </Badge>
          ) : (
            quantity
          );
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "quantity",
          title: <FormattedMessage id="SalesQuotationDetailReply.quantity" defaultMessage="Quantity" />,
          handleSave: this.handleQuantity
        })
      },
      {
        title: <FormattedMessage id="SalesQuotationDetailReply.Unit" defaultMessage="Unit" />,
        key: "unit",
        width: 100,
        render: record => {
          let weight = "";
          if (record.unit && Boolean(record.weight) && Boolean(record.quantity)) {
            if (Boolean(this.state.allUnitSelectItems) && this.state.allUnitSelectItems.length > 0) {
              let unit = this.state.allUnitSelectItems.find(p => p.abbreviation === record.unit);
              if (Boolean(unit.abbreviation) && unit.abbreviation === "ad") {
                weight = record.quantity;
              } else {
                if (unit.unitTypeName === "Length") {
                  if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                    weight = (record.length * record.quantity) / unit.coefficent;
                  } else {
                    weight = record.length * record.quantity;
                  }
                } else {
                  if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                    weight = (record.quantity * record.weight) / unit.coefficent;
                  } else {
                    weight = record.weight * record.quantity;
                  }
                }
              }
            }
          }
          return record.unit;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "unit",
          title: <FormattedMessage id="SalesQuotationDetailReply.Unit" defaultMessage="Unit" />,
          handleSave: this.handleUnit
        })
      },
      {
        title: <FormattedMessage id="SalesQuotationDetailReply.UnitPrice" defaultMessage="Unit Price" />,
        key: "unitPrice",
        width: 100,
        render: record => {
          return record.unitPrice;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "unitPrice",
          title: <FormattedMessage id="SalesQuotationDetailReply.unitPrice" defaultMessage="Unit Price" />,
          handleSave: this.handleUnitPrice
        })
      },
      {
        title: <FormattedMessage id="SalesQuotationDetailReply.Currency" defaultMessage="Currency" />,
        key: "currency",
        width: 100,
        render: record => {
          return record.currency;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "currency",
          title: <FormattedMessage id="SalesQuotationDetailReply.currency" defaultMessage="Currency" />,
          handleSave: this.handleCurrency
        })
      },
      {
        title: <FormattedMessage id="SalesQuotationDetailReply.Price" defaultMessage="Price" />,
        key: "price",
        width: 100,
        render: record => {
          return Boolean(record.unitPrice) && Boolean(record.quantity) ? record.unitPrice * record.quantity : "";
        }
      },
      {
        title: <FormattedMessage id="SalesQuotationDetailReply.Vat" defaultMessage="Vat(%)" />,
        key: "priceVat",
        width: 100,
        render: record => {
          return record.priceVat;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "priceVat",
          title: <FormattedMessage id="SalesQuotationDetailReply.priceVat" defaultMessage="Vat(%)" />,
          handleSave: this.handlePriceVat
        })
      },
      {
        title: <FormattedMessage id="SalesQuotationDetailReply.PlannedDeliveryDate" defaultMessage="Planned Delivery Date" />,
        key: "plannedDeliveryDate",
        width: 100,
        render: record => {
          // return Boolean(record.plannedDeliveryDate) ? moment(record.plannedDeliveryDate).format("DD-MM-YYYY") : "";
          return record.plannedDeliveryDate;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "plannedDeliveryDate",
          title: <FormattedMessage id="SalesQuotationDetailReply.plannedDeliveryDate" defaultMessage="Planned Delivery Date" />,
          handleSave: this.handlePlannedDeliveryDate
        })
      },
      {
        title: <FormattedMessage id="SalesQuotationDetailReply.PaymentTerms" defaultMessage="Payment Terms" />,
        key: "replyPaymentTerms",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="SalesQuotationDetailReply.DeliveryPlace" defaultMessage="Delivery Place" />,
        key: "deliveryPlace",
        width: 100,
        render: record => {
          let deliveryPlaceNames = [];
          if (Boolean(record.town)) {
            deliveryPlaceNames.push(record.town);
          }
          if (Boolean(record.city)) {
            deliveryPlaceNames.push(record.city);
          }
          return deliveryPlaceNames.join("/");
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "deliveryPlace",
          title: <FormattedMessage id="SalesQuotationDetailReply.DeliveryPlace" defaultMessage="Delivery Place" />,
          handleSave: this.handleDeliveryPlace
        })
      },
      {
        key: "id",
        width: 100,
        render: record => {
          return (
            <Space>
              <AntButton
                onClick={() => this.addNewRow(record)}
                style={{ border: "0px", color: "#007bff" }}
                icon={<PlusCircleOutlined fontSize="large" />}
              ></AntButton>

              <AntButton
                onClick={() => this.removeRow(record)}
                style={{ border: "0px", color: "red" }}
                icon={<MinusCircleOutlined fontSize="large" />}
              ></AntButton>
            </Space>
          );
        }
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}></Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            components={components}
            rowClassName={() => "editable-row"}
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
            summary={pageData => {
              let priceSubTotal = 0;
              let priceVatTotal = 0;
              pageData.forEach(({ price, priceVat, selected }) => {
                if (Boolean(price) && price > 0 && Boolean(selected) && selected) {
                  priceSubTotal += price;

                  if (Boolean(priceVat) && priceVat > 0) {
                    priceVatTotal += (priceVat * price) / 100;
                  }
                }
              });
              var priceTotal = priceSubTotal + priceVatTotal;
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <FormattedMessage id="SalesQuotationDetailReply.subTotal" defaultMessage="Sub total" />
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="danger">{priceSubTotal}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <FormattedMessage id="SalesQuotationDetailReply.vat" defaultMessage="VAT" />
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <InputNumber readOnly min={0} value={priceVatTotal} />
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <FormattedMessage id="SalesQuotationDetailReply.total" defaultMessage="Total" />
                      Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="danger">{priceTotal}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>

        <Row gutter={[16, 16]}>
          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Button id="SaveButton" onClick={this.save} style={{ width: "100%" }} variant="success">
              <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
            </Button>
          </Col>
          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
        </Row>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SalesQuotationDetailReply);
