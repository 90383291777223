import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Badge, Space, Switch } from "antd";
import store from "../../../../redux/store";
import { Select } from "antd";
import { fillFilterOperations } from "../../../Components/DataTableFilterComponent";


const { Option } = Select;

export class EldenEquipmentMaintenance extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "EldenEquipmentMaintenance",
      controllerName: "eldenEquipmentMaintenance",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    fillFilterOperations();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      fileList: []
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    if (Boolean(values) && Boolean(values.settings) && values.settings.length > 0) {
      values.settings.forEach(row => {

        console.log("AAA", row);
        var options = [];
        if (Boolean(row.options) && row.options.length > 0) {
          row.options.forEach(element => {
            options.push({
              ["codeId"]: element
            });
          });
        }

        var columns = [];
        if (Boolean(row.columns) && row.columns.length > 0) {
          row.columns.forEach(element => {
            columns.push({
              ["codeId"]: element
            });
          });
        }

        var _imageOptions = [];
        if (Boolean(this.state.fileList))
          this.state.fileList.forEach(element => {
            let url = null;
            if (Boolean(element.url)) {
              url = element.url;
            } else if (Boolean(element.response) && Boolean(element.response.url)) {
              url = element.response.url;
            }
            if (Boolean(url)) {
              _imageOptions.push({
                ["imageUrl"]: url
              });
            }
          });
        row.options = options;
        row.columns = columns;
        row.imageOptions = _imageOptions;
      });
    }
    console.log("values", values);

    if (Boolean(this.props.equipmentLogId) && this.props.equipmentLogId > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAll/" + this.props.equipmentLogId, values.settings);
      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess();
        this.props.nextTab();
      }
    } else {
      showWarning("Equipment Log not found.");
    }
  };


  handleFileChange = ({ fileList }) => this.setState({ fileList });

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    // const App: React.FC = () => (
    //   <Space direction="vertical">

    //   </Space>
    // );

    const settings = Boolean(this.formRef)
      && Boolean(this.formRef.current)
      && Boolean(this.formRef.current.getFieldValue("settings"))
      && Array.isArray(this.formRef.current.getFieldValue("settings"))
      && this.formRef.current.getFieldValue("settings").length > 0
      ? this.formRef.current.getFieldValue("settings")
      : []
      ;
    const isSettingsArray = Boolean(settings) && Array.isArray(settings) && settings.length > 0;

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="EldenEquipmentMaintenance.pageTitle" defaultMessage="Maintenance" />}
        placement="start"
        color="#F39C12"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <div hidden={false}>
              <br></br>
              <br></br>
              <br></br>
              <br></br>

              <Form.Item name="fieldA" valuePropName="checked">
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>


                    <Switch checkedChildren="Apply Maintenance" unCheckedChildren="Exclude!" defaultChecked />

                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EldenEquipmentMaintenance); 