import axios from "axios";
import { reducer } from "./modules/Auth/_redux/authRedux";

//export const API_BASE_ROOT = "https://driveapi.mndsoftware.com";

export const API_BASE_ROOT = "https://driveapi.mndsoftware.com";

export function API_BASE() {
  return API_BASE_ROOT;
}

export async function handleRequest(httpMethod, URL, data) {
  let selectedLang = "en";
  try {
    selectedLang = JSON.parse(localStorage.getItem("i18nConfig")).selectedLang;
  } catch (error) {

  }
  var flagConfig;

  if (!Boolean(reducer.authToken)) {
    flagConfig = { headers: { Authorization: ``, Lang: selectedLang } };
  } else {
    flagConfig = { headers: { Authorization: `Bearer ${reducer.authToken}`, Lang: selectedLang } };
  }

  var response;

  const flagURL = API_BASE_ROOT + URL;

  try {
    switch (httpMethod) {
      case "GET":
        response = await axios.get(`${flagURL}`, flagConfig);
        break;
      case "POST":
        response = await axios.post(`${flagURL}`, data, flagConfig);
        break;
      case "PUT":
        response = await axios.put(`${flagURL}`, data, flagConfig);
        break;
      case "DELETE":
        response = await axios.delete(`${flagURL}`, flagConfig);
        break;
      case "PATCH":
        response = await axios.patch(`${flagURL}`, data, flagConfig);
        break;
      default:
        break;
    }
    return response;
  } catch (error) {
    console.log(URL, error);

    error.type = "ERROR";

    if (!Boolean(error.data)) error.data = [];

    return error;
    //ornek error objesi :  { data: "This record added before! Please change your selections.", status: 406, statusText: "", headers: {�}, config: {�}, request: XMLHttpRequest }
  }
}
