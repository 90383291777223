import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Col as ColBoot, Form as FormBoot } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { handleRequest } from "../../ApiConnector";
import { API_BASE_ROOT } from "../../ApiConnector";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import CustomInspectionLogMenu from "../CustomMenu/CustomInspectionLogMenu";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import {
  Form,
  Switch,
  Table,
  Input,
  Select,
  Col,
  Row,
  DatePicker,
  Button as AntButton,
  InputNumber,
  Radio,
  Space,
  Modal,
  Spin,
  Checkbox,
  Badge,
  Tag,
  Cascader,
  Drawer,
  Tooltip
} from "antd";
import moment from "moment";
import { Upload } from "antd";
import { UploadOutlined, SaveOutlined } from "@ant-design/icons";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import "../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";
import { PageSettings } from "../administrator/pageSettings/PageSettings";
import store from "../../../redux/store";
import SettingsIcon from "@material-ui/icons/Settings";

const { TextArea } = Input;

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class InspectionLogItem extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "inspectionLogItem",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      showResultModal: false,
      showResultDimensionalAnswerModal: false,
      showResultChemicalCompositionAnswerModal: false,
      saveLoading: false,

      hideUpdate: true,
      hideSave: false,
      hiddenInputs: true,
      filePaths: [],
      fileList: [],
      fileListChecklist: [],
      filePathsChecklist: [],
      fileListPhoto: [],
      filePathsPhoto: [],
      fileListTestReport: [],
      filePathsTestReport: [],
      selectedRowKeys: [],
      issueDate: moment(),

      bulkFileList: [],
      revisedFileList: [],
      modalPdfViewer: false,
      pdfFile: "",
      codeName: "",
      codeName2: "",
      activitySelectItems: [],
      stockSelectItems: [],
      clientQCSelectItems: [],
      clientPMTSelectItems: [],
      constructionRepSelectItems: [],
      qciSelectItems: [],
      supervisorSelectItems: [],
      inspectorSelectItems: [],
      id: null,
      selectedRow: null,
      revising: false,
      index: null,
      result: null,
      status: "Open",
      checklistFilePath: null,
      workPermitRequired: true,
      tableAddResultList: [],
      filePath: "",
      fileList: [],
      sampleSize: 0,
      errorCount: 0,
      samplingCount: 0,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"]
      },
      paginationAddResult: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"]
      },
      loading: false,
      loadingAddResult: false,
      sortField: "",
      sortOrder: "",
      itemIndex: 0,
      previousButtonProps: "none",
      loadingChecklistSingle: false,
      loadingChecklistMultiple: false,

      period: "day",
      lastUpdateDateStart: null,
      lastUpdateDateEnd: null,
      startDate: null,
      endDate: null,
      selectedMonths: null,
      selectedYears: null,
      monthsSelectItems: [],
      yearsSelectItems: [],
      tableAddResultIndex: 0,
      user: store.getState().auth.user,
    };
  }

  formRef = React.createRef();
  formChecklist = React.createRef();
  formAddResultList = React.createRef();
  formInspectionLogItemChemicalResult = React.createRef();
  formSingleRejectedAmountList = React.createRef();
  formSaveRejectedAmountList = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });

    this.fillComboboxes();
  };

  handleLocationIdChange = async value => {
    let locationId = Boolean(value) && value.length > 0 ? value[value.length - 1] : null;
    this.setState(
      {
        locationId: locationId
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  fillComboboxes = async () => {
    const responseStock = await handleRequest("GET", "/api/" + this.state.controllerName + "/stock");
    if (responseStock.type === "ERROR") {
      error(responseStock);
    }

    const responseActivity = await handleRequest("GET", "/api/" + this.state.controllerName + "/activity");
    if (responseActivity.type === "ERROR") {
      error(responseActivity);
    }

    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/tree");

    if (responseTree.data.length !== 0) {
      console.log(responseTree.data);

      generateList(responseTree.data);
      this.setState({
        nodes: responseTree.data
      });
    }

    this.setState({
      stockSelectItems: Boolean(responseActivity.data) ? responseActivity.data : [],
      activitySelectItems: Boolean(responseActivity.data) ? responseActivity.data : []
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hiddenInputs: true
    });
    this.resetInputs();
  };

  addResult = async () => {
    const obj = {
      id: this.state.id,
      result: this.state.result,
      checklistFilePath: this.state.filePathsChecklist.join(),
      photoFilePath: this.state.filePathsPhoto.join()
    };

    let response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/addResult", obj);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.resetInputs();
    }
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    if (Boolean(this.formChecklist) && Boolean(this.formChecklist.current)) {
      this.formChecklist.current.setFieldsValue({
        result: null,
        photoPath: null,
        attachmentPath: null
      });
    }

    this.setState({
      filePath: "",
      filePaths: [],
      fileList: [],
      bulkFileList: [],
      id: null,
      revising: false,
      result: null,
      status: "Open",
      checklistFilePath: null,
      showResultModal: false,
      showResultDimensionalAnswerModal: false,
      showResultChemicalCompositionAnswerModal: false,
      workPermitRequired: true,
      checklist: null,
      tableAddResultList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,

        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"]
      },
      paginationAddResult: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,

        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"]
      }
    });
  };

  edit = row => {
    let fileList = [];
    let fileListPhoto = [];
    let fileListChecklist = [];
    let fileListTestReport = [];
    let clientPmtIds = [];
    let clientPmtIdsArray = Boolean(row.clientPmtIds) ? row.clientPmtIds.split(",") : [];
    let pathList = Boolean(row.drawingFilePath) ? row.drawingFilePath.split(",") : [];
    if (Boolean(pathList) && pathList.length > 0) {
      pathList.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileList.push(obj);
      });
    }

    if (Boolean(clientPmtIdsArray))
      clientPmtIdsArray.forEach(element => {
        clientPmtIds.push(Number(element));
      });

    let pathListPhoto = Boolean(row.photoFilePath) ? row.photoFilePath.split(",") : [];
    if (Boolean(pathListPhoto) && pathListPhoto.length > 0) {
      pathListPhoto.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileListPhoto.push(obj);
      });
    }

    let pathListchecklist = Boolean(row.checklistFilePath) ? row.checklistFilePath.split(",") : [];
    if (Boolean(pathListchecklist) && pathListchecklist.length > 0) {
      pathListchecklist.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileListChecklist.push(obj);
      });
    }

    let pathListTestReport = Boolean(row.testReportPath) ? row.testReportPath.split(",") : [];
    if (Boolean(pathListTestReport) && pathListTestReport.length > 0) {
      pathListTestReport.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileListTestReport.push(obj);
      });
    }

    let drawingIdsStringArray = Boolean(row.drawingIds) ? row.drawingIds.split(",") : [];
    let drawingIds = [];

    drawingIdsStringArray.forEach(element => {
      drawingIds.push(parseInt(element));
    });

    let drawingIdsSelectItems = [];

    if (Boolean(row.drawingList) && row.drawingList.length > 0)
      row.drawingList.forEach(d => {
        drawingIdsSelectItems.push(d);
      });

    this.formRef.current.setFieldsValue({
      ...row,
      issueDate: Boolean(row.issueDate) ? moment(row.issueDate) : null,
      inspectionDate: Boolean(row.inspectionDate) ? moment(row.inspectionDate) : null,
      inspectionTime: Boolean(row.inspectionTime) ? moment(row.inspectionTime) : null,
      status: row.status,
      result: row.result,
      workPermitRequired: Boolean(row.workPermitRequired),
      clientPmtIds: clientPmtIds,
      drawingIds: drawingIds
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hiddenInputs: false,

      id: row.id,
      status: row.status,
      result: row.result,
      fileList: fileList,
      fileListPhoto: fileListPhoto,
      fileListChecklist: fileListChecklist,
      checklistFilePath: row.checklistFilePath,
      photoFilePath: row.photoFilePath,
      filePaths: Boolean(row.drawingFilePath) ? row.drawingFilePath.split(",") : [],
      filePathsPhoto: Boolean(row.photoFilePath) ? row.photoFilePath.split(",") : [],
      filePathsChecklist: Boolean(row.checklistFilePath) ? row.checklistFilePath.split(",") : [],
      fileListTestReport: fileListTestReport,
      filePathsTestReport: pathListTestReport,
      workPermitRequired: Boolean(row.workPermitRequired),
      drawingIdsSelectItems: drawingIdsSelectItems
    });
  };

  revise = row => {
    let fileList = [];

    let pathList = Boolean(row.drawingFilePath) ? row.drawingFilePath.split(",") : [];
    if (Boolean(pathList) && pathList.length > 0) {
      pathList.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileList.push(obj);
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      issueDate: Boolean(row.issueDate) ? moment(row.issueDate) : null,
      inspectionDate: Boolean(row.inspectionDate) ? moment(row.inspectionDate) : null,
      inspectionTime: Boolean(row.inspectionTime) ? moment(row.inspectionTime) : null,
      workPermitRequired: Boolean(row.workPermitRequired)
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hiddenInputs: false,
      id: row.id,
      fileList: fileList,
      filePaths: Boolean(row.drawingFilePath) ? row.drawingFilePath.split(",") : [],
      revising: true,
      workPermitRequired: Boolean(row.workPermitRequired)
    });
  };

  generateReport = async row => {
    let url = "/api/" + this.state.controllerName + "/report/" + row.id;

    const response = await handleRequest("GET", url);

    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false
      });

      showError("There is no created report for selected item");
    }
  };

  openShareModal = row => {
    this.setState({
      shareFileId: row.id,
      showShareModal: true
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  handleTableChangeAddResult = (pagination, filters, sorter) => {
    this.setState({
      paginationAddResult: pagination
    });
  };

  restartTable = async (params = {}) => {
    const prd = Boolean(params.period) ? params.period : "";
    this.setState({ loading: true });

    let newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        sortField: "",
        sortOrder: ""
      });
    } else {
      let list = Boolean(response.data.data) ? response.data.data : response.data;
      if (list !== 0) {
        // let list = (Boolean(response.data.data)) ? response.data.data : response.data;
        list.forEach((element, index) => {
          element.sn = index + 1;
          element.key = element.id;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  search = async values => {
    const { pagination } = this.state;
    var current = this.state.pagination.current;
    var pageSize = this.state.pagination.pageSize;
    var sortField = this.state.pagination.sortField;
    var sortOrder = this.state.pagination.sortOrder;

    let obj = {};
    if (values.period === "day") {
      obj = {
        ...pagination,
        firstResult: current - 1,
        maxResults: pageSize,
        sortField: sortField,
        sortOrder: sortOrder,
        period: values.period,
        lastUpdateDateStart: Boolean(values.startDate) ? values.startDate.toDate() : null,
        lastUpdateDateEnd: Boolean(values.endDate) ? values.endDate.toDate() : null
      };
    } else if (values.period === "month") {
      obj = {
        ...pagination,
        firstResult: current - 1,
        maxResults: pageSize,
        sortField: sortField,
        sortOrder: sortOrder,
        period: values.period,
        selectedMonths: values.selectedMonths,
        selectedYears: [values.selectedYears]
      };
    } else if (values.period === "year") {
      obj = {
        ...pagination,
        firstResult: current - 1,
        sortField: sortField,
        sortOrder: sortOrder,
        maxResults: pageSize,
        period: values.period,
        selectedYears: values.selectedYears
      };
    }

    this.setState(
      {
        period: Boolean(values.period) ? values.period : "day",
        loading: true
      },
      () => {
        this.restartTable(obj);
        // this.generateReport(obj);
      }
    );
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;
    var current = this.state.pagination.current;
    var pageSize = this.state.pagination.pageSize;
    var sortField = "",
      sortOrder = "";

    if (Boolean(params) && Boolean(params.pagination)) {
      current = params.pagination.current;
      pageSize = params.pagination.pageSize;

      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      firstResult: current - 1, // (current - 1) * pageSize,
      maxResults: pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  createWhereAddResult(params) {
    if (Boolean(params._pagination)) params.paginationAddResult = params._pagination;
    var current = this.state.paginationAddResult.current;
    var pageSize = this.state.paginationAddResult.pageSize;
    var sortField = "",
      sortOrder = "";

    if (Boolean(params) && Boolean(params.paginationAddResult)) {
      current = params.paginationAddResult.current;
      pageSize = params.paginationAddResult.pageSize;

      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.paginationAddResult,
      firstResult: current - 1, // (current - 1) * pageSize,
      maxResults: pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  onClickCreateNew = async () => {
    this.resetInputs();
    if (this.state.hiddenInputs) {
      this.setState({
        hiddenInputs: false,
        hiddenSave: false,
        hiddenUpdate: true
      });
    } else {
      this.setState({
        hiddenInputs: true,
        hiddenSave: true,
        hiddenUpdate: false
      });
    }
  };

  save = async values => {
    this.setState({ saveLoading: true });

    const { filePaths, filePathsChecklist, filePathsPhoto, filePathsTestReport } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      issueDate: values.issueDate.toDate(),
      inspectionDate: values.inspectionDate.toDate(),
      inspectionTime: values.inspectionTime.toDate(),
      drawingFilePath: Boolean(filePaths) ? filePaths.toString() : "",
      testReportPath: Boolean(filePathsTestReport) ? filePathsTestReport.toString() : "",
      clientPmtIds: Boolean(values.clientPmtIds) ? values.clientPmtIds.toString() : null,
      checklistFilePath: Boolean(filePathsChecklist) ? filePathsChecklist.toString() : "",
      photoFilePath: Boolean(filePathsPhoto) ? filePathsPhoto.toString() : "",
      status: this.state.status,
      result: Boolean(this.state.status) && this.state.status === "Open" ? null : this.state.result,
      workPermitRequired: this.state.workPermitRequired,
      drawingIds: Boolean(values.drawingIds) ? values.drawingIds.toString() : "",
      rfiNo: values.rfi
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      if (this.state.revising === true) response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/revise/" + newItem.id, newItem);
      else response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.resetInputs();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.cancel();
    }
    this.setState({ saveLoading: false });
  };

  downloadFile = async url => {
    window.location.href = url;
  };

  downloadMultipleFile = async url => {
    let pathList = url.split(",");
    if (Boolean(pathList) && pathList.length > 1) {
      var response = await handleRequest("POST", "/api/transmittalLogs/download", pathList);
      if (response.type === "ERROR") {
        error(response);
      } else {
        try {
          const link = document.createElement("a");
          link.href = response.data;
          link.download = "Fİles.zip";
          link.click();
        } catch (error) { }
      }
    } else if (Boolean(pathList) && pathList.length === 1) this.downloadFile(pathList[0]);
  };

  showReport = async url => {
    try {
      const fileRealUrl = url;

      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true
      });
    } catch (error) {
      alert("File cant found.");
    }
  };

  searchUserMail = async email => {
    if (Boolean(email) && email.length > 0) {
      var response = await handleRequest("GET", "/api/users/mails/" + email);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          userSelectItems: Boolean(response.data) ? response.data : []
        });
      }
    } else
      this.setState({
        userSelectItems: []
      });
  };

  actionTemplate(row) {
    if (!(Boolean(row) && (row.result === "Accept" || row.result === "Inspected"))) {
      return (
        <React.Fragment>
          <CustomInspectionLogMenu
            row={row}
            onDeleteRow={this.deleteModal.bind(this)}
            onReviseRow={this.revise.bind(this)}
            onEditRow={this.edit.bind(this)}
            onAddResult={this.modalProcessType.bind(this)}
            onReportRow={this.generateReport.bind(this)}
          />
        </React.Fragment>
      );
    }
  }

  handleExportExcel = async () => {
    this.setState({ exportClick: true });
  };

  ExcelDateToJSDate = serial => {
    if (Boolean(serial) && serial.length > 0)
      if ((serial + "").includes(".")) {
        return moment(serial, "DD.MM.YYYY").toDate();
      } else {
        var utc_days = Math.floor(serial - 25569);
        var utc_value = utc_days * 86400;
        var date_info = new Date(utc_value * 1000);

        var fractional_day = serial - Math.floor(serial) + 0.0000001;

        var total_seconds = Math.floor(86400 * fractional_day);

        var seconds = total_seconds % 60;

        total_seconds -= seconds;

        var hours = Math.floor(total_seconds / (60 * 60));
        var minutes = Math.floor(total_seconds / 60) % 60;

        return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
      }
    else return null;
  };

  syncTestReports = async excelJson => {
    var list = [];
    if (excelJson === null) showError("Select an excel file");
    else {
      excelJson.forEach(element => {
        let obj = {
          key: Boolean(element["RFI no"])
            ? element["RFI no"].toString().includes("#")
              ? element["RFI no"].toString().split("#")[1]
              : element["RFI no"].toString()
            : null,
          value: element["Test Report No"]
        };
        if (Boolean(obj.key) && Boolean(obj.value)) list.push(obj);
      });
      if (Boolean(list) && list.length > 0) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/sync", list);
        if (response.type === "ERROR") {
          showError("No records to be added");
        } else {
          this.resetInputs();
          const { pagination } = this.state;
          this.restartTable({ pagination });
          showSuccess();
          this.cancel();
        }
      } else {
        showError("No records to be added");
      }
    }
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            rfiNo: element["RFI No"],
            rfi: element["RFI No"],
            logType: element["Type"],
            stockName: element["Stock"],
            revNo: element["Rev"],
            activity: element["Activity"],
            worksInspected: element["Works To Be Inspected"],
            inspectionTime:
              Boolean(element["Insp. Time"]) && element["Insp. Time"].length > 0 ? moment(element["Insp. Time"], "HH:mma").toDate() : null,
            qciName: element["QCI"],
            clientPmtName: element["PMT"],
            clientQcName: element["PID Insp"],
            constructionRepName: element["Construction Representative"],
            supervisorName: element["QC Supervisor"],
            activity: {
              satipNo: element["ITP No"],
              itemNo: Boolean(element["Item no"]) > 0 ? element["Item no"].toString() : null,
              qualityProcedure: Boolean(element["Checklist"]) && element["Checklist"].length > 0 ? element["Checklist"] : ""
            },
            inspectionLevelClient: element["Ins.Level Client"],
            inspectionLevelMcc: element["Ins.Level MCC"],
            status: Boolean(element["Status"]) ? (element["Status"] === "Closed" ? "Closed" : "Open") : "",
            result: Boolean(element["Result"])
              ? element["Result"] === "Accepted" || element["Result"] === "Approved"
                ? "Approved"
                : element["Result"]
              : "",
            drawingNo: element["Drawing no"]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.cancel();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("Catch");
    }
  };

  refreshLinkDrawingList = async drawingSearch => {
    let obj = { drawingNo: drawingSearch };

    const response = await handleRequest("POST", "/api/drawings/search", obj);
    if (Boolean(response.data)) {
      this.setState({
        drawingIdsSelectItems: response.data
      });
    } else
      this.setState({
        drawingIdsSelectItems: []
      });
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  disabledDate = current => {
    return current && current < this.state.issueDate;
  };

  saveSingleResultList = async () => {
    this.formChecklist.current.submit();
  };

  handleFormSingleListSubmit = async values => {
    const itemIndex = this.state.itemIndex;
    this.setState({
      itemIndex: itemIndex + 1,
      previousButtonProps: ""
    });

    var newItem = {
      ...values,
      itemNo: this.state.checklist.itemNo,
      inspectionLogItemId: this.state.selectedChecklistRow.id
    };

    this.setInspectionChecklistResultObj(values, this.state.checklist, newItem);

    // var response = await handleRequest("POST", "/api/inspectionChecklistResult/save", newItem);
    // if (response.type === "ERROR") {
    //   error(response);
    // } else {
    this.singleOpenCheckListModel(this.state.selectedChecklistRow);
    // }
  };

  warningMessage = async (value, diameter, dimension, inspectionLogItemId) => {
    if (Boolean(inspectionLogItemId) && inspectionLogItemId > 0) {
      let response = await handleRequest(
        "GET",
        "/api/" +
        this.state.controllerName +
        "/warningMessage/" +
        inspectionLogItemId +
        "/" +
        this.state.itemIndex +
        "/" +
        dimension + //inspectionType
        "/" +
        value +
        "/" +
        diameter //reference
      );
      if (response.type === "ERROR") {
        error(response);

        this.setState({
          errorResponse: true
        });
      }
      console.log("AAA", response.data);
    } else {
      showWarning("Inspection Log Item not found.");
    }
  };

  warningMessageChemicalComposition = async (value, chemicalCompositionDetailId, elementId, attribute) => {
    let inspectionLogItemId = Boolean(this.state.selectedChecklistRowData) ? this.state.selectedChecklistRowData.id : null;
    if (Boolean(inspectionLogItemId) && inspectionLogItemId > 0) {
      const { elementAttributes } = this.state;

      let response = await handleRequest(
        "GET",
        "/api/" +
        this.state.controllerName +
        "/warningMessageChemicalComposition/" +
        inspectionLogItemId +
        "/" +
        chemicalCompositionDetailId +
        "/" +
        elementId +
        "/" +
        value
      );
      if (response.type === "ERROR") {
        attribute.result = "Reject";
      } else {
        attribute.result = "Accept";
      }
      this.setState({
        elementAttributes: elementAttributes
      });
    } else {
      showWarning("Inspection Log Item not found.");
    }
  };

  saveChemicalResultSubmit = async () => {
    this.formInspectionLogItemChemicalResult.current.submit();
  };

  saveInspectionLogItemChemicalResult = async () => {
    let inspectionLogItemId = Boolean(this.state.selectedChecklistRowData) ? this.state.selectedChecklistRowData.id : null;
    const { elementAttributes } = this.state;
    if (Boolean(inspectionLogItemId) && inspectionLogItemId > 0) {
      if (Boolean(elementAttributes) && elementAttributes.length > 0) {
        var response = await handleRequest(
          "POST",
          "/api/" + this.state.controllerName +
          "/saveInspectionLogItemChemicalResult/" +
          inspectionLogItemId,
          elementAttributes
        );
        if (response.type === "ERROR") {
          error(response);
        } else {
          this.setState({
            showResultChemicalCompositionAnswerModal: false,
            selectedRowKeys: []
          });
          showSuccess();
        }
      } else {
        showWarning("Not found.");
      }
    } else {
      showWarning("Inspection Log Item not found.");
    }
  };

  singleChecklistModel = async () => {
    var row = this.state.selectedChecklistRowData;
    this.setState(
      {
        itemIndex: 0,
        previousButtonProps: "none",
        modalOnebyoneShow: false
      },
      () => {
        this.singleOpenCheckListModel(row);
      }
    );
  };

  saveMultipleResultList = async () => {
    this.formAddResultList.current.submit();
  };

  handleFormMultipleListSubmit = async values => {
    const { sampleNo, sampleSize, samplingDetailId, samplingDetailListId } = this.state;
    var newItem = {
      ...values,
      itemNo: this.state.selectedAddResultAnswer.itemNo,
      inspectionLogItemId: this.state.selectedChecklistRowData.id,
      inspectionLogItemType: this.state.inspectionLogItemType
      // sampleSize: this.state.getChecklistItem.sampleNo
    };
    console.log("newItem", newItem);
    console.log("selectedChecklistRowData", this.state.selectedChecklistRowData);

    let list = [];
    if (Boolean(values)) {
      Object.entries(values).forEach(([anahtar, deger]) => {
        if (anahtar !== "result") {
          list.push({
            dimension: anahtar,
            dimensionValue: parseFloat(deger),
            samplingDetailId: samplingDetailId,
            samplingDetailListId: samplingDetailListId
          });
        }
      });
    }
    console.log("list", list);
    if (
      Boolean(list) &&
      list.length > 0 &&
      Boolean(newItem.inspectionLogItemId) &&
      newItem.inspectionLogItemId > 0 &&
      Boolean(newItem.inspectionLogItemType)
    ) {
      var response = await handleRequest(
        "POST",
        "/api/" +
        this.state.controllerName +
        "/saveInspectionLogItemDimensionResult/" +
        newItem.inspectionLogItemId +
        "/" +
        newItem.inspectionLogItemType +
        "/" +
        sampleNo,
        list
      );
      if (response.type === "ERROR") {
        error(response);
      } else {
        var tableAddResultList = this.state.tableAddResultList;
        var row = this.state.tableAddResultList.find(p => p.itemNo === newItem.itemNo);
        if (Boolean(row)) {
          row.result = newItem.result;
        }

        this.setInspectionChecklistResultObj(values, this.state.selectedAddResultAnswer, newItem);
        let sampleSuccess = false;
        this.setState(
          {
            showResultDimensionalAnswerModal: false,
            selectedRowKeys: [],
            tableAddResultList: tableAddResultList
            // showInspectionResultSuccessModal: true
          },
          () => {
            // showSuccess();
            if (sampleSize > sampleNo) {
              this.setState(
                {
                  sampleNo: sampleNo + 1
                },
                () => {
                  this.setState(
                    {
                      showResultDimensionalAnswerModal: true
                    },
                    () => {
                      const { dynamicAttributes, selectedAddResultAnswer } = this.state;
                      if (Boolean(this.formAddResultList) && Boolean(this.formAddResultList.current)) {
                        this.formAddResultList.current.setFieldsValue({
                          ...selectedAddResultAnswer
                        });

                        dynamicAttributes.forEach(element => {
                          this.formAddResultList.current.setFieldsValue({
                            [element.label]: element.value
                          });
                        });
                      }
                    }
                  );
                }
              );
            }
            else {
              sampleSuccess = true;
            }
          }
        );

        let updatedErrorCount = this.state.errorCount;
        if (this.state.errorResponse) {
          updatedErrorCount++;
        }

        if (updatedErrorCount > this.state.samplingConformingItems) {
          showWarning("maxnonconforming value exceeded");
          this.multipleChecklistModel();
          this.setState(prevState => ({
            errorCount: 0,
            samplingCount: prevState.samplingCount + 1,
            showResultDimensionalAnswerModal: false,
            showInspectionResultModal: true
          }));
        } else if (sampleSuccess && updatedErrorCount > 0) {
          this.setState({
            errorCount: 0,
            errorResponse: false,
            samplingCount: 0,
            showInspectionResultHataSuccessModal: true
          });
        } else if (sampleSuccess) {
          this.setState({
            errorCount: 0,
            errorResponse: false,
            samplingCount: 0,
            showInspectionResultSuccessModal: true
          });
        } else {
          this.setState({
            errorCount: updatedErrorCount,
            errorResponse: false,
            samplingCount: 0
          });
        }
      }
    } else {
      showError("Inspection or type not found");
    }
  };

  setInspectionChecklistResultObj = (values, data, newItem) => {
    if (Boolean(data.answerType)) {
      var options = [];
      if (Boolean(values.options) && values.options.length > 0) {
        values.options.forEach(element => {
          if (Boolean(data.questionnary) && Boolean(data.questionnary.options) && data.questionnary.options.length > 0) {
            var obj = data.questionnary.options.find(p => p.codeId === element);
            if (Boolean(obj)) {
              options.push({
                ["codeId"]: element,
                ["codeName"]: obj.codeName,
                ["value"]: true
              });
            }
          }
        });
        newItem.options = options;
      } else if (Boolean(values.options)) {
        if (Boolean(data.questionnary) && Boolean(data.questionnary.options) && data.questionnary.options.length > 0) {
          var obj = data.questionnary.options.find(p => p.codeId === values.options);
          if (Boolean(obj)) {
            options.push({
              ["codeId"]: values.options,
              ["codeName"]: obj.codeName,
              ["value"]: true
            });
          }
        }
        newItem.options = options;
      }

      var imageOptions = [];
      if (Boolean(values.imageOptions)) {
        imageOptions.push({
          ["imageUrl"]: values.imageOptions,
          ["value"]: true
        });
        newItem.imageOptions = imageOptions;
      }
    }

    newItem.filePath = Boolean(this.state.fileList) && this.state.fileList.length > 0 ? this.state.fileList[0].url : "";
  };

  modalProcessType = async row => {
    this.setState(
      {
        // modalProcessTypeShow: true,
        selectedChecklistRowData: row
      },
      () => {
        this.multipleChecklistModel();
      }
    );
  };

  multipleChecklistModel = async (param = {}) => {
    var row = this.state.selectedChecklistRowData;
    this.setState({ loadingChecklistMultiple: true, loadingAddResult: true });
    let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getAddResultItem/" + row.id + "/" + this.state.samplingCount);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loadingChecklistMultiple: false,
        loadingAddResult: false
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach((element, index) => {
          element.id = index + 1;
        });
        this.setState({
          tableAddResultList: response.data,
          showResultModal: true,
          modalOnebyoneShow: true,
          modalProcessTypeShow: false,
          loadingChecklistMultiple: false,
          loadingAddResult: false,
          selectedRowKeys: []
        });
      } else {
        this.setState({
          tableAddResultList: [],
          showResultModal: true,
          modalOnebyoneShow: true,
          modalProcessTypeShow: false,
          loadingChecklistMultiple: false,
          loadingAddResult: false,
          selectedRowKeys: []
        });
      }
    }
  };

  getChecklistDescription = index => {
    if (Boolean(this.state.checklist) && Boolean(this.state.checklist[index])) {
      return this.state.checklist[index].acceptanceCriteria;
    }
  };

  getChecklistItemNo = index => {
    if (Boolean(this.state.checklist) && Boolean(this.state.checklist[index])) {
      return this.state.checklist[index].itemNo + ".";
    }
  };

  onSelectChangeAddResult = async selectedRowKeys => {
    console.log("selectedRowKeys", selectedRowKeys);
    if (Boolean(this.formAddResultList) && Boolean(this.formAddResultList.current)) {
      this.formAddResultList.current.resetFields();
    }
    if (Boolean(selectedRowKeys) && selectedRowKeys.length === 1) {
      var row = this.state.tableAddResultList.find(p => p.id === selectedRowKeys[0]);
      console.log("row", row);
      let { selectedChecklistRowData } = this.state;
      console.log("selectedChecklistRowData", selectedChecklistRowData);

      if (Boolean(row)) {
        if (Boolean(row.inspectionLogItemType)) {
          let dynamicAttributes = [];
          let chemicalCompositionList = [];

          if (row.inspectionLogItemType === "Dimension_Control") {
            if (Boolean(selectedChecklistRowData) && Boolean(selectedChecklistRowData.allDimensions)) {
              let dimensionList = selectedChecklistRowData.allDimensions.split(", ");
              console.log("dimensionList", dimensionList);

              if (Boolean(dimensionList) && dimensionList.length > 0) {
                dimensionList.forEach(element => {
                  let dimension = element.split(":");
                  if (Boolean(dimension) && dimension.length > 1) {
                    let obj = {
                      label: dimension[0],
                      value: dimension[1]
                    };
                    dynamicAttributes.push(obj);
                  }
                });
              }
            }
          } else if (row.inspectionLogItemType === "Chemical_Composition") {
            var responseCompanyProjectId = await handleRequest(
              "GET",
              "/api/" + this.state.controllerName + "/chemicalComposition/" + selectedChecklistRowData.id
            );
            if (responseCompanyProjectId.type === "ERROR") {
              error(responseCompanyProjectId);
            }
            chemicalCompositionList = Boolean(responseCompanyProjectId.data) ? responseCompanyProjectId.data : [];
            console.log("chemicalCompositionList", chemicalCompositionList);


          }
          this.setState(
            {
              sampleNo: 1,
              sampleSize: Boolean(row) && Boolean(row.sampleNo) && row.sampleNo > 0 ? row.sampleNo : 0,
              samplingConformingItems:
                Boolean(row) && Boolean(row.samplingConformingItems) && row.samplingConformingItems > 0 ? row.samplingConformingItems : 0,
              samplingDetailId: Boolean(row) && Boolean(row.samplingDetailId) && row.samplingDetailId > 0 ? row.samplingDetailId : null,
              samplingDetailListId:
                Boolean(row) && Boolean(row.samplingDetailListId) && row.samplingDetailListId > 0 ? row.samplingDetailListId : null,
              showInspectionInfo: true,
              // showResultDimensionalAnswerModal: true,
              inspectionLogItemType: row.inspectionLogItemType,
              selectedAddResultAnswer: row,
              dynamicAttributes: dynamicAttributes,
              elementAttributes: chemicalCompositionList,
              fileList: this.getFileList(row)
            },
            () => {
              console.log("dynamicAttributes", this.state.dynamicAttributes);
              if (Boolean(this.formAddResultList) && Boolean(this.formAddResultList.current)) {
                this.formAddResultList.current.setFieldsValue({
                  ...row
                });

                dynamicAttributes.forEach(element => {
                  this.formAddResultList.current.setFieldsValue({
                    [element.label]: element.value
                  });
                });
              }
            }
          );
        } else {
          showError("Type not found.");
        }
      } else {
        showError("Row not found.");
      }
    }
    this.setState({ selectedRowKeys });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search and click enter`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
      </div>
    ),
    filterIcon: filtered => <FilterOutlined style={{ color: filtered ? "#1890ff" : "#bfbfbf" }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  getFileList(row) {
    var fileList = [];
    let filePath = Boolean(row.filePath) ? row.filePath.split(",") : [];
    if (Boolean(filePath) && filePath.length > 0) {
      filePath.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileList.push(obj);
      });
    }
    return fileList;
  }

  getRatingList = max => {
    const numbers = [];
    for (let index = 1; index <= max; index++) {
      numbers.push(index);
    }
    return numbers;
  };

  checklistAnswerType(data, layout, props) {
    return (
      <>
        {Boolean(data) && (
          <>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <span
                  className="mr-3"
                  style={{
                    fontWeight: "bold"
                  }}
                >
                  {data.itemNo + ". "}
                </span>
                <div className="d-inline">{data.inspectionDescription}</div>
              </Col>
            </Row>
            <br />

            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
              {/* <ColBoot sm="2">
                          <InputNumber
                            min={0}
                            value={attribute.score}
                            placeholder="Score"
                            onChange={value => {
                              attribute.score = value;
                              // this.calcWeight();
                            }}
                          ></InputNumber>
                        </ColBoot> */}
              <FormBoot.Label style={{ color: "black" }} column sm="7">
                {data.question}
                <br></br>
                {data.answerType}
                <br></br>
                {data.reference}
                <br></br>
              </FormBoot.Label>

              {/* <ColBoot sm="3">
                <div style={{ float: "right" }}>
                  <Row>
                    {data.linkedQuestion && (
                      <Col span={8}>
                        <AntButton style={{ marginRight: "10px" }} type="dashed" shape="circle" icon={<LinkOutlined />} />
                      </Col>
                    )}
                    <Col span={8}>
                      <Checkbox onChange={e => this.onCheckQuestionChange(e, data)}></Checkbox>
                    </Col>
                    <Col span={8}>
                      <AntButton
                        style={{ marginRight: "10px" }}
                        type="dashed"
                        onClick={() => this.edit(data)}
                        shape="circle"
                        icon={<EditOutlined />}
                      />
                    </Col>
                    <Col span={8}>
                      <AntButton type="dashed" onClick={() => this.deleteModal(data)} shape="circle" icon={<CloseOutlined />} />
                    </Col>
                  </Row>
                </div>
              </ColBoot> */}
            </FormBoot.Group>

            <br></br>
            <br></br>
            {Boolean(this.state.inspectionLogItemType) && this.state.inspectionLogItemType === "Dimension_Control" && (
              <>
                {Boolean(this.state.dynamicAttributes) &&
                  this.state.dynamicAttributes.length > 0 &&
                  this.state.dynamicAttributes.map((attribute, index) => {
                    return (
                      <>
                        <Checkbox
                          onChange={e => {
                            if (e.target.checked) {
                              //this.handleFormMultipleListSubmit();
                            }
                          }}
                        ></Checkbox>
                        <Badge overflowCount={attribute.value + 1} count={attribute.value}>
                          <Form.Item
                            {...layout}
                            label={attribute.label}
                            name={attribute.label}
                            rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            key={index}
                          >
                            <InputNumber
                              parser={value => value.replace(",", ".")}
                              formatter={value => value.replace(".", ",")}
                              onChange={value => {
                                if (Boolean(value)) {
                                  this.warningMessage(
                                    value,
                                    attribute.value,
                                    attribute.label,
                                    Boolean(this.state.selectedChecklistRow) ? this.state.selectedChecklistRow.id : null
                                  );
                                }
                              }}
                            />
                          </Form.Item>
                        </Badge>
                        <br />
                      </>
                    );
                  })}
                {/* RESULT */}
                {/* <Form.Item
                  {...layout}
                  label={<FormattedMessage id="InspectionLogItem.Result" defaultMessage="Result" />}
                  name="result"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Radio.Group>
                    <Radio value={"Accept"}>
                      <FormattedMessage id="InspectionLogItem.Accept" defaultMessage="Accept" />
                    </Radio>
                    <Radio onClick={this.onClickSingleItemRejected} value={"Reject"}>
                      <FormattedMessage id="InspectionLogItem.Reject" defaultMessage="Reject" />
                    </Radio>
                    <Radio value={"OnHold"}>
                      <FormattedMessage id="InspectionLogItem.OnHold" defaultMessage="On Hold" />
                    </Radio>
                  </Radio.Group>
                </Form.Item> */}
              </>
            )}

            {Boolean(data.answerType) && data.answerType === "Matrix_Multiple" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot></ColBoot>
                {Boolean(data.questionnary) &&
                  data.questionnary.columns.length > 0 &&
                  data.questionnary.columns.map((col, j) => (
                    <>
                      <ColBoot>{col.codeName}</ColBoot>
                    </>
                  ))}
              </FormBoot.Group>
            )}

            {Boolean(data.answerType) &&
              data.answerType === "Matrix_Multiple" &&
              Boolean(data.questionnary) &&
              Boolean(data.questionnary.matrixValues) &&
              data.questionnary.matrixValues.length > 0 &&
              data.questionnary.matrixValues.map((row, k) => (
                <>
                  <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                    <ColBoot> {row.title} </ColBoot>
                    {Boolean(data.questionnary.columns) &&
                      data.questionnary.columns.length > 0 &&
                      data.questionnary.columns.map((col, j) => (
                        <ColBoot>
                          <Select style={{ width: "100%" }}>
                            {Boolean(data.questionnary.options) &&
                              data.questionnary.options.length > 0 &&
                              data.questionnary.options.map(
                                (opt, j) =>
                                  Boolean(opt.codeId) && (
                                    <Option key={opt.codeId} value={opt.codeId}>
                                      {opt.codeName}
                                    </Option>
                                  )
                              )}
                          </Select>
                        </ColBoot>
                      ))}
                  </FormBoot.Group>
                </>
              ))}

            {Boolean(data.answerType) && data.answerType === "Matrix_Single" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot></ColBoot>
                {Boolean(data.questionnary) &&
                  data.questionnary.columns.length > 0 &&
                  data.questionnary.columns.map((col, j) => (
                    <>
                      <ColBoot>{col.codeName}</ColBoot>
                    </>
                  ))}
              </FormBoot.Group>
            )}

            {Boolean(data.answerType) &&
              data.answerType === "Matrix_Single" &&
              Boolean(data.questionnary) &&
              Boolean(data.questionnary.columns) &&
              data.questionnary.columns.length > 0 &&
              Boolean(data.questionnary.matrixValues) &&
              data.questionnary.matrixValues.length > 0 &&
              data.questionnary.matrixValues.map((row, k) => (
                <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <ColBoot>{row.title}</ColBoot>
                  {Boolean(data.questionnary.columns) &&
                    data.questionnary.columns.length > 0 &&
                    data.questionnary.columns.map(
                      (opt, j) =>
                        Boolean(opt.codeId) && (
                          <ColBoot>
                            <Radio></Radio>
                            {/* <Radio value={opt.value} checked={opt.value} defaultChecked={opt.value}
                        onChange={(e) => {
                          { console.log("KKK", e) }
                          opt.value = e.target.checked;
                          row.value = e.target.checked;
                          row.codeId = opt.codeId;
                          var otherObj = data.questionnary.columns.filter(p => p.codeId !== opt.codeId);
                          { console.log("XXX", otherObj) }
                          { console.log("YYY", opt) }
                          { console.log("ZZZ", row) }
                          if (Boolean(otherObj) && otherObj.length > 0) {
                            otherObj.forEach(element => {
                              element.value = false;
                            });
                          }
                          this.setState({
                            [data]: data,
                          });
                        }} ></Radio> */}
                          </ColBoot>
                        )
                    )}
                </FormBoot.Group>
              ))}

            {/* {Boolean(data.answerType) && (data.answerType === "Checkbox" || data.answerType === "Dropdown") && (
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InspectionLogItem.options" defaultMessage="Options" />}
                name="options"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select mode="tags" style={{ width: "100%" }}>
                  {Boolean(data.questionnary) &&
                    Boolean(data.questionnary.options) &&
                    data.questionnary.options.length > 0 &&
                    data.questionnary.options.map(
                      (opt, j) =>
                        Boolean(opt.codeId) && (
                          <Option key={opt.codeId} value={opt.codeId}>
                            {opt.codeName}
                          </Option>
                        )
                    )}
                </Select>
              </Form.Item>
            )} */}

            {/* {Boolean(data.answerType) && data.answerType === "Rating" && (
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InspectionLogItem.rate" defaultMessage="Rate" />}
                name="rate"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber />
              </Form.Item>
            )} */}
            {/* 
            {Boolean(data.answerType) && data.answerType === "Imagepicker" && (
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InspectionLogItem.imageOptions" defaultMessage="Options" />}
                name="imageOptions"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Radio.Group>
                  {Boolean(data.questionnary) &&
                    Boolean(data.questionnary.imageOptions) &&
                    data.questionnary.imageOptions.length > 0 &&
                    data.questionnary.imageOptions.map((opt, j) => (
                      <Radio value={opt.imageUrl}>
                        <img alt="option" style={{ width: "120px", height: "120px" }} src={opt.imageUrl} />
                      </Radio>
                    ))}
                </Radio.Group>
              </Form.Item>
            )} */}

            {/* {Boolean(data.answerType) && data.answerType === "Boolean" && Boolean(data.questionnary) && Boolean(data.questionnary.id) && (
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InspectionLogItem.resultBool" defaultMessage="Select" />}
                name="resultBool"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Switch checkedChildren={data.questionnary.labelTrue} unCheckedChildren={data.questionnary.labelFalse} valuePropName="checked" />
              </Form.Item>
            )} */}

            {Boolean(data.answerType) && data.answerType === "Comment" && (
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InspectionLogItem.comment" defaultMessage="Comment" />}
                name="comment"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <TextArea style={{ width: "100%" }} rows={3} />
              </Form.Item>
            )}
            {Boolean(data.answerType) && data.answerType === "Checkbox" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                {Boolean(data.options) &&
                  data.options.length > 0 &&
                  data.options.map((opt, j) => (
                    <>
                      <ColBoot sm="2"></ColBoot>
                      <ColBoot style={{ marginBottom: "1rem" }} xs="10">
                        <Checkbox> {opt.codeName}</Checkbox>
                      </ColBoot>{" "}
                      <br />
                    </>
                  ))}
              </FormBoot.Group>
            )}
            {Boolean(data.answerType) && data.answerType === "Radiogroup" && (
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InspectionLogItem.options" defaultMessage="Options" />}
                name="options"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Radio.Group>
                  {Boolean(data.options) &&
                    data.options.length > 0 &&
                    data.options.map((opt, j) => Boolean(opt.codeId) && <Radio value={opt.codeId}>{opt.codeName}</Radio>)}
                </Radio.Group>
              </Form.Item>
            )}

            {Boolean(data.answerType) && data.answerType === "Dropdown" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <FormBoot.Control name="type" as="select">
                  <option value="Select">Select Option</option>
                  {Boolean(data.options) &&
                    data.options.length > 0 &&
                    data.options.map((opt, j) => <option value={opt.codeName}>{opt.codeName}</option>)}
                </FormBoot.Control>
              </FormBoot.Group>
            )}
            {Boolean(data.answerType) && data.answerType === "Rating" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot xs="10">
                  <Radio.Group buttonStyle="solid">
                    {this.getRatingList(data.rateMax).map((element, index) => (
                      <Radio.Button value={element}>{element}</Radio.Button>
                    ))}
                  </Radio.Group>
                </ColBoot>
              </FormBoot.Group>
            )}
            {Boolean(data.answerType) && data.answerType === "Scoring" && (
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InspectionLogItem.score" defaultMessage="Score" />}
                name="score"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber />
              </Form.Item>
            )}
            {/* !!!!!!!!!!!!!!!!!!!!!!!!!!! */}
            {Boolean(data.answerType) && data.answerType === "Imagepicker" && (
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InspectionLogItem.imageOptions" defaultMessage="Options" />}
                name="imageOptions"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Radio.Group>
                  {Boolean(data.imageOptions) &&
                    data.imageOptions.length > 0 &&
                    data.imageOptions.map((opt, j) => (
                      <Radio value={opt.imageUrl}>
                        <img alt="option" style={{ width: "120px", height: "120px" }} src={opt.imageUrl} />
                      </Radio>
                    ))}
                </Radio.Group>
              </Form.Item>
            )}
            {Boolean(data.answerType) && data.answerType === "Boolean" && (
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InspectionLogItem.resultBool" defaultMessage="Select" />}
                name="resultBool"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Switch checkedChildren={data.labelTrue} unCheckedChildren={data.labelFalse} valuePropName="checked" />
              </Form.Item>
            )}
            {Boolean(data.answerType) && data.answerType === "File" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot xs="4">
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="InspectionLogItem.filePath" defaultMessage="File Path" />}
                    name="filePath"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Upload
                      {...props}
                      showUploadList={true}
                      fileList={this.state.fileList}
                      onChange={({ fileList }) => {
                        if (fileList.length == 1) {
                          if (Boolean(fileList[0].response)) {
                            fileList[0].url = fileList[0].response.url;
                            showSuccess("file uploaded successfully");
                          }
                          this.setState({
                            fileList: fileList
                          });
                        }
                      }}
                      beforeUpload={fileList => {
                        if (this.state.fileList.length > 0) {
                          showError("You have been already choose a file! For change firstly remove the uploaded file.");
                          return false;
                        }
                        return true;
                      }}
                      onRemove={fileList => {
                        this.setState({
                          fileList: []
                        });
                      }}
                    >
                      <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                        <UploadOutlined /> Click to Upload
                      </AntButton>
                    </Upload>
                  </Form.Item>
                </ColBoot>
              </FormBoot.Group>
            )}
          </>
        )}
      </>
    );
  }

  saveInspectionLogItemResult = async (inspectionLogItemResult, rejectedAmount) => {
    let obj = {
      inspectionLogItemResult: inspectionLogItemResult
    };
    if (Boolean(rejectedAmount) && rejectedAmount > 0) {
      obj = {
        inspectionLogItemResult: inspectionLogItemResult,
        rejectedAmount: rejectedAmount
      };
    }
    let response = await handleRequest(
      "PUT",
      "/api/" + this.state.controllerName + "/saveInspectionLogItemResult/" + this.state.selectedChecklistRowData.id,
      obj
    );

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        showResultModal: false,
        showChecklistModal: false
      });
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.resetInputs();
    }
  };

  onClickSingleItemRejected = () => {
    this.setState(
      {
        itemSingleRejectedModalShow: true
      },
      () => {
        var totalAmount = Boolean(this.state.selectedChecklistRowData) ? this.state.selectedChecklistRowData.acceptedQuantity : 0;
        if (Boolean(this.formSingleRejectedAmountList) && Boolean(this.formSingleRejectedAmountList.current)) {
          this.formSingleRejectedAmountList.current.setFieldsValue({
            totalAmount: totalAmount,
            rejectedAmount: ""
          });
        }
        this.setState({
          totalAmount: totalAmount
        });
      }
    );
  };

  handleFormRejectedSingleSubmit = async values => {
    this.setState({
      itemSingleRejectedModalShow: false
    });
  };

  onClickMultipleItemRejected = () => {
    this.setState(
      {
        itemSaveRejectedModalShow: true,
        showResultModal: false,
        showResultDimensionalAnswerModal: false,
        showChecklistModal: false
      },
      () => {
        var totalAmount = Boolean(this.state.selectedChecklistRowData) ? this.state.selectedChecklistRowData.acceptedQuantity : 0;
        if (Boolean(this.formSaveRejectedAmountList) && Boolean(this.formSaveRejectedAmountList.current)) {
          this.formSaveRejectedAmountList.current.setFieldsValue({
            totalAmount: totalAmount
          });
        }
        this.setState({
          totalAmount: totalAmount
        });
      }
    );
  };

  handleFormRejectedSaveSubmit = async values => {
    console.log("AAA", values);
    this.saveInspectionLogItemResult("Reject", values.rejectedAmount);
    this.setState({
      itemSaveRejectedModalShow: false
    });
  };

  nextClick = () => {
    const { tableAddResultIndex, tableAddResultList } = this.state;

    if ((tableAddResultIndex < tableAddResultList.length - 1)) {
      this.setState({ tableAddResultIndex: tableAddResultIndex + 1 });
      let id = [];
      id.push(tableAddResultList[tableAddResultIndex + 1].id);
      this.onSelectChangeAddResult(id);
    }
    this.setState({
      showNonConformingSamplesModal: false,
      showInspectionResultModal: false
    });
  }

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 8,
          offset: 0
        },
        sm: {
          span: 8,
          offset: 8
        }
      }
    };

    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const layout = {
      labelCol: { span: 0 },
      wrapperCol: { span: 16 }
    };

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const propsBulk = {
      action: `${API_BASE_ROOT}/File/upload/inspectiontest`,
      listType: "picture",
      defaultFileList: [...this.state.bulkFileList]
    };

    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };

    const { loading, selectedRowKeys } = this.state;
    const hasSelected = selectedRowKeys.length > 0;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const rowSelectionAddResult = {
      selectedRowKeys,
      onChange: this.onSelectChangeAddResult
    };

    const columns = [
      {
        key: "actions",
        width: 70,
        ellipsis: true,
        fixed: "left",
        render: record => {
          return this.actionTemplate(record);
        }
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        },
        ellipsis: true,
        fixed: "left"
      },
      {
        title: <FormattedMessage id="InspectionLogItem.RFINo" defaultMessage="RFI no" />,
        key: "id",
        render: record => {
          return record.rfiNo;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.rfiNo) ? this.state.pagination.rfiNo : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  rfiNo: e.target.value,
                  rfiNoColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.rfiNoColor) ? this.state.pagination.rfiNoColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 100,
        ellipsis: true,
        fixed: "left"
      },
      {
        title: <FormattedMessage id="General.RevNo" defaultMessage="Rev. No" />,
        key: "revNo",
        width: 100,
        render: record => {
          return record.revNo;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.revNo) ? this.state.pagination.revNo : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  revNo: e.target.value,
                  revNoColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.revNoColor) ? this.state.pagination.revNoColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        ellipsis: true
      },
      {
        title: <FormattedMessage id="InspectionLogItem.Item" defaultMessage="Item" />,
        key: "poReplyOurExpectedPriceId",
        render: record => {
          return record.itemName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.itemName) ? this.state.pagination.itemName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  itemName: e.target.value,
                  itemNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.itemNameColor) ? this.state.pagination.itemNameColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 100,
        ellipsis: true
      },
      {
        title: <FormattedMessage id="InspectionLogItem.Quantity" defaultMessage="Quantity" />,
        key: "id",
        render: record => {
          return record.acceptedQuantity;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.acceptedQuantity) ? this.state.pagination.acceptedQuantity : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  acceptedQuantity: e.target.value,
                  acceptedQuantityColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.acceptedQuantityColor) ? this.state.pagination.acceptedQuantityColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 100,
        ellipsis: true
      },
      {
        title: <FormattedMessage id="InspectionLogItem.RejectedQuantity" defaultMessage="Rejected Quantity" />,
        key: "id",
        render: record => {
          return record.rejectedQuantity;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.rejectedQuantity) ? this.state.pagination.rejectedQuantity : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  rejectedQuantity: e.target.value,
                  rejectedQuantityColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.rejectedQuantityColor) ? this.state.pagination.rejectedQuantityColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 100,
        ellipsis: true
      },
      {
        title: <FormattedMessage id="InspectionLogItem.UnitPrice" defaultMessage="Unit Price" />,
        key: "id",
        render: record => {
          return record.unitPrice;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.unitPrice) ? this.state.pagination.unitPrice : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  unitPrice: e.target.value,
                  unitPriceColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.unitPriceColor) ? this.state.pagination.unitPriceColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 100,
        ellipsis: true
      },
      {
        title: <FormattedMessage id="InspectionLogItem.Stock" defaultMessage="Stock" />,
        key: "stockId",
        render: record => {
          return record.stockName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.stockId) ? this.state.pagination.stockId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  stockId: e.target.value,
                  stockIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.stockIdColor) ? this.state.pagination.stockIdColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 200,
        ellipsis: true
      },
      {
        title: <FormattedMessage id="InspectionLogItem.RequestDate" defaultMessage="Request Date" />,
        key: "requestDate",
        render: record => {
          if (Boolean(record.requestDate)) {
            return moment(record.requestDate).format("DD-MM-YYYY");
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <DatePicker
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.requestDate) ? this.state.pagination.requestDate : ""}
              onChange={async date => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  requestDate: date,
                  requestDateColor: Boolean(date) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.requestDateColor) ? this.state.pagination.requestDateColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 150,
        ellipsis: true
      },
      {
        title: <FormattedMessage id="InspectionLogItem.InspectionDateTime" defaultMessage="Inspection Date&Time" />,
        key: "inspectionDate",
        render: record => {
          if (Boolean(record.inspectionDate)) {
            return moment(record.inspectionDate).format("DD-MM-YYYY HH:mm");
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <DatePicker
              style={{ flex: "auto" }}
              showTime={{ format: "HH:mm" }}
              format="DD-MM-YYYY HH:mm"
              value={Boolean(this.state.pagination.inspectionDate) ? this.state.pagination.inspectionDate : ""}
              onChange={async date => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  inspectionDate: date,
                  inspectionDateColor: Boolean(date) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.inspectionDateColor) ? this.state.pagination.inspectionDateColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 150,
        ellipsis: true
      },
      {
        title: <FormattedMessage id="InspectionLogItem.Activity" defaultMessage="Activity" />,
        key: "activityId",
        render: record => {
          return "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  activityId: value,
                  activityIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state.activitySelectItems) &&
                this.state.activitySelectItems.length > 0 &&
                this.state.activitySelectItems.map(i => (
                  <Option key={i.id} value={i.id}>
                    {i.activity}
                  </Option>
                ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.activityIdColor) ? this.state.pagination.activityIdColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 150,
        ellipsis: true
      },
      {
        title: <FormattedMessage id="InspectionLogItem.WorksToBeInspected" defaultMessage="Works To Be Inspected" />,
        key: "worksInspected",
        render: record => {
          return record.worksInspected;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.worksInspected) ? this.state.pagination.worksInspected : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  worksInspected: e.target.value,
                  worksInspectedColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.worksInspectedColor) ? this.state.pagination.worksInspectedColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 200,
        ellipsis: true
      },
      {
        title: <FormattedMessage id="InspectionLogItem.Inspector" defaultMessage="Inspector" />,
        key: "inspectorId",
        render: record => {
          return "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              showSearch
              optionFilterProp="children"
              style={{ width: 200 }}
              onChange={e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  inspectorId: e.target.value,
                  inspectorIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            ></Input>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.inspectorIdColor) ? this.state.pagination.inspectorIdColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 120,
        ellipsis: true
      },
      {
        title: <FormattedMessage id="InspectionLogItem.ITPNo" defaultMessage="ITP no" />,
        key: "itpLogId",
        render: record => {
          return "";
        },
        sorter: true,
        width: 150,
        ellipsis: true
      },
      {
        title: <FormattedMessage id="InspectionLogItem.DrawingNo" defaultMessage="Drawing no" />,
        key: "drawingId",
        render: record => {
          let drawings = [];
          if (Boolean(record.drawingList)) {
            drawings = record.drawingList.map(i => i.drawingNo + "-" + i.shtNo + "-" + i.revNo);
          }
          return drawings.toString();
        },
        sorter: true,
        width: 150,
        ellipsis: true
      },
      {
        title: <FormattedMessage id="InspectionLogItem.Status" defaultMessage="Status" />,
        key: "status",
        render: record => {
          return record.status;
        },
        width: 100,
        ellipsis: true
      },
      {
        title: <FormattedMessage id="InspectionLogItem.Result" defaultMessage="Result" />,
        key: "result",
        render: record => {
          if (Boolean(record.result)) {
            if (record.result === "Accept" || record.result === "Inspected") {
              return (
                <Tag color="green">
                  <FormattedMessage id="InspectionLogItem.Inspected" defaultMessage="Inspected" />
                </Tag>
              );
            } else if (record.result === "Reject") {
              return (
                <Tag color="red">
                  <FormattedMessage id="InspectionLogItem.Reject" defaultMessage="Reject" />
                </Tag>
              );
            } else if (record.result === "OnHold") {
              return (
                <Tag color="blue">
                  <FormattedMessage id="InspectionLogItem.OnHold" defaultMessage="On Hold" />
                </Tag>
              );
            }
          }
          return "";
        },
        width: 100,
        ellipsis: true,
        fixed: "right"
      }
    ];
    const columnsAddResult = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.paginationAddResult.pageSize * (this.state.paginationAddResult.current - 1);
        }
      },
      // {
      //   title: <FormattedMessage id="InspectionLogItem.qualityProcedureNo" defaultMessage="Quality Procedure No" />,
      //   key: "qualityProcedure",
      //   width: 100,
      //   render: record => {
      //     return record.qualityProcedure;
      //   },
      //   ...this.getColumnSearchProps("qualityProcedure"),
      //   sorter: (a, b) => Boolean(a.qualityProcedure) && Boolean(b.qualityProcedure) && a.qualityProcedure.localeCompare(b.qualityProcedure)
      // },
      {
        title: <FormattedMessage id="InspectionLogItem.itemNo" defaultMessage="Status" />,
        key: "samplingStatus",
        width: 100,
        render: record => {
          return (
            <span>
              {<FormattedMessage id="InspectionLogItem.StatusNo" defaultMessage="Sampling No" />}: {record.currentSampleNo}
            </span>
          );
        }
        // ...this.getColumnSearchProps("itemNo"),
        // sorter: (a, b) => Boolean(a.itemNo) && Boolean(b.itemNo) && a.itemNo.localeCompare(b.itemNo)
      },
      {
        title: <FormattedMessage id="InspectionLogItem.Quantity" defaultMessage="Project" />,
        key: "project",
        width: 100,
        render: record => {
          return record.project;
        }
      },
      {
        title: <FormattedMessage id="InspectionLogItem.itemNo" defaultMessage="Item" />,
        key: "item",
        width: 100,
        render: record => {
          return record.item;
        }
        // ...this.getColumnSearchProps("itemNo"),
        // sorter: (a, b) => Boolean(a.itemNo) && Boolean(b.itemNo) && a.itemNo.localeCompare(b.itemNo)
      },
      {
        title: <FormattedMessage id="InspectionLogItem.Quantity" defaultMessage="Quantity" />,
        key: "quantity",
        width: 100,
        render: record => {
          return record.quantity;
        }
      },
      {
        title: <FormattedMessage id="InspectionLogItem.Requirement" defaultMessage="Requirement" />,
        key: "requirement",
        width: 100,
        render: record => {
          return record.requirement;
        }
      },
      {
        title: <FormattedMessage id="InspectionLogItem.InspectionType" defaultMessage="Inspection Type" />,
        key: "inspectionType",
        width: 100,
        render: record => {
          return record.inspectionType;
        }
      },
      {
        title: <FormattedMessage id="InspectionLogItem.InspectionType" defaultMessage="Acceptance Criteria" />,
        key: "acceptanceCriteria",
        width: 100,
        render: record => {
          return record.acceptanceCriteria;
        }
      },
      {
        title: <FormattedMessage id="InspectionLogItem.Result" defaultMessage="Result" />,
        key: "",
        width: 100,
        render: record => {
          if (Boolean(record.result)) {
            if (record.result === "Accept") {
              return (
                <Tag color="green">
                  <FormattedMessage id="InspectionLogItem.Accept" defaultMessage="Accept" />
                </Tag>
              );
            } else if (record.result === "Reject") {
              return (
                <Tag color="red">
                  <FormattedMessage id="InspectionLogItem.Reject" defaultMessage="Reject" />
                </Tag>
              );
            } else if (record.result === "OnHold") {
              return (
                <Tag color="blue">
                  <FormattedMessage id="InspectionLogItem.OnHold" defaultMessage="On Hold" />
                </Tag>
              );
            }
          }
          return "";
        }
      },
      {
        title: <FormattedMessage id="InspectionLogItem.InspectionType" defaultMessage="Photo" />,
        key: "photo",
        width: 100,
        render: record => {
          return record.photo;
        }
      }

      // {
      //   title: <FormattedMessage id="InspectionLogItem.Question" defaultMessage="Question" />,
      //   key: "question",
      //   width: 100,
      //   ellipsis: true,
      //   render: record => {
      //     return record.question;
      //   },
      //   ...this.getColumnSearchProps("question"),
      //   sorter: (a, b) => Boolean(a.question) && Boolean(b.question) && a.question.localeCompare(b.question)
      // },

      // {
      //   title: <FormattedMessage id="InspectionLogItem.AcceptanceCondition" defaultMessage="Acceptance Condition" />,
      //   key: "",
      //   width: 100,
      //   render: record => {
      //     return "";
      //   }
      // },
      // {
      //   title: <FormattedMessage id="InspectionLogItem.Referans" defaultMessage="Referans" />,
      //   key: "reference",
      //   width: 100,
      //   render: record => {
      //     return record.reference;
      //   },
      //   ...this.getColumnSearchProps("reference"),
      //   sorter: (a, b) => Boolean(a.reference) && Boolean(b.reference) && a.reference.localeCompare(b.reference)
      // },
      // {
      //   title: <FormattedMessage id="InspectionLogItem.ControlValue" defaultMessage="Control Value" />,
      //   key: "answer",
      //   width: 100,
      //   render: record => {
      //     if (Boolean(record.answer) && Boolean(record.answerType) && record.answerType === "Imagepicker") {
      //       return <Image width={80} src={record.answer}></Image>;
      //     } else if (Boolean(record.filePath)) {
      //       return (
      //         <AntButton
      //           style={{
      //             marginLeft: "2px",
      //             marginTop: "-2px",
      //             alignContent: "center"
      //           }}
      //           onClick={() => this.downloadFile(record.filePath)}
      //           type="primary"
      //           shape="round"
      //           icon={<DownloadOutlined />}
      //         ></AntButton>
      //       );
      //     }
      //     return record.answer;
      //   }
      // },
      // {
      //   title: <FormattedMessage id="InspectionLogItem.Result" defaultMessage="Result" />,
      //   key: "",
      //   width: 100,
      //   render: record => {
      //     if (Boolean(record.result)) {
      //       if (record.result === "Accept") {
      //         return (
      //           <Tag color="green">
      //             <FormattedMessage id="InspectionLogItem.Accept" defaultMessage="Accept" />
      //           </Tag>
      //         );
      //       } else if (record.result === "Reject") {
      //         return (
      //           <Tag color="red">
      //             <FormattedMessage id="InspectionLogItem.Reject" defaultMessage="Reject" />
      //           </Tag>
      //         );
      //       } else if (record.result === "OnHold") {
      //         return (
      //           <Tag color="blue">
      //             <FormattedMessage id="InspectionLogItem.OnHold" defaultMessage="On Hold" />
      //           </Tag>
      //         );
      //       }
      //     }
      //     return "";
      //   }
      // }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };

      const newColumnsAddResult = [...columnsAddResult];
      newColumnsAddResult[index] = {
        ...newColumnsAddResult[index],
        width: size.width
      };

      this.setState({
        columns: newColumns,
        columnsAddResult: newColumnsAddResult
      });
    };

    const mergeColumnsAddResult =
      Boolean(this.state.columnsAddResult) && this.state.columnsAddResult.length > 0
        ? this.state.columnsAddResult.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columnsAddResult) &&
        columnsAddResult.length > 0 &&
        columnsAddResult.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Inspection Log" hideElement={true}>
            <ExcelSheet name="Inspection Log" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="sn" />
              <ExcelColumn label="RFI No" value="rfiNo" />
              <ExcelColumn label="Rev" value="revNo" />
              <ExcelColumn label="Stock" value="stockName" />
              <ExcelColumn label="Activity" value={col => (Boolean(col.activity) ? col.activity.activity : "")} />
              <ExcelColumn label="Works To Be Inspected" value="worksInspected" />
              <ExcelColumn
                label="Inspection Date"
                value={col => (Boolean(col.inspectionDate) ? moment(col.inspectionDate).format("DD.MM.YYYY") : null)}
              />
              <ExcelColumn label="ITP No" value={col => (Boolean(col.activity) ? col.activity.satipNo : "")} />
              <ExcelColumn label="Status" value="status" />
              <ExcelColumn label="Result" value="result" />
              <ExcelColumn label="Inspector" value="inspectorName" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="secondary" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <Badge.Ribbon
        text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage={"Inspection Log Item"} />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={23}></Col>
              <Col md={1}>
                {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                  <Tooltip title={<FormattedMessage id="InventoryLog.Settings" defaultMessage="Settings" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => {
                        this.setState({
                          drawerSettingsShow: true
                        });
                      }}
                      icon={<SettingsIcon color="error" fontSize="large" />}
                    ></AntButton>
                  </Tooltip>
                )}
              </Col>
            </Row>

            <div hidden={this.state.hiddenInputs}>
              {
                <Form.Item
                  {...layout2}
                  label={<FormattedMessage id="InspectionLogItem.Stock" defaultMessage="Stock" />}
                  name="stockId"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="todo" defaultMessage="This field is required" />
                    }
                  ]}
                >
                  <Select allowClear showSearch optionFilterProp="children" style={{ width: "100%" }}>
                    {Boolean(this.state.stockSelectItems) &&
                      this.state.stockSelectItems.length > 0 &&
                      this.state.stockSelectItems.map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.activity}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout2}
                  label={<FormattedMessage id="InspectionLogItem.activty" defaultMessage="Activity" />}
                  name="activityId"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="todo" defaultMessage="This field is required" />
                    }
                  ]}
                >
                  <Select allowClear showSearch optionFilterProp="children" style={{ width: "100%" }}>
                    {Boolean(this.state.activitySelectItems) &&
                      this.state.activitySelectItems.length > 0 &&
                      this.state.activitySelectItems.map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.activity}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout2}
                  label={<FormattedMessage id="InspectionLogItem.wti" defaultMessage="Works to be inspected" />}
                  name="worksInspected"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="todo" defaultMessage="Please type inspected" />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout2}
                  label={<FormattedMessage id="InspectionLogItem.drawingId" defaultMessage="Drawing no" />}
                  name="drawingId"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select showSearch optionFilterProp="children" onSearch={this.refreshLinkDrawingList} style={{ width: "100%" }}>
                    {Boolean(this.state["drawingIdsSelectItems"]) &&
                      this.state["drawingIdsSelectItems"].length > 0 &&
                      this.state["drawingIdsSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.drawingNo + "-" + i.shtNo + "-" + i.revNo}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }

              <div hidden={this.state.hideSave}>
                {
                  <Form.Item {...tailFormItemLayout}>
                    <AntButton
                      id="SaveButton"
                      htmlType="submit"
                      type="primary"
                      style={{ width: "100%" }}
                      className="btn btn-success"
                      icon={<SaveOutlined style={{ verticalAlign: "1px", marginRight: "5px" }} />}
                      loading={this.state.saveLoading}
                    >
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </AntButton>
                  </Form.Item>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <AntButton id="CancelButton" type="primary" style={{ width: "100%" }} className="btn btn-default" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </AntButton>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <AntButton
                        id="UpdateButton"
                        htmlType="submit"
                        type="primary"
                        style={{ width: "100%" }}
                        className="btn btn-warning"
                        loading={this.state.saveLoading}
                      >
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </AntButton>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              size="small"
              // rowSelection={rowSelection}
              bordered
              scroll={{ y: 500, x: 900 }}
              components={{
                header: {
                  cell: ResizableTitle
                }
              }}
              columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          {/* ONE BY ONE */}
          <Modal
            centered
            width={1400}
            title={<FormattedMessage id="InspectionLogItem.ChecklistResult" defaultMessage="Checklist Result" />}
            open={this.state.showChecklistModal}
            onCancel={() => {
              this.setState({
                showChecklistModal: false
              });
            }}
            previousButtonProps={{ style: { display: "none" } }}
            nextButtonProps={{ style: { display: "none" } }}
            footer={[
              <Space>
                <Button id="AcceptButton" onClick={() => this.saveInspectionLogItemResult("Accept")} style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="InspectionLogItem.Accept" defaultMessage="Accept" />
                </Button>
                {/* <Button id="RejectButton" onClick={this.onClickMultipleItemRejected} style={{ width: "100%" }} variant="danger">
                  <FormattedMessage id="InspectionLogItem.Reject" defaultMessage="Reject" />
                </Button>
                <Button id="OnHoldButton" onClick={() => this.saveInspectionLogItemResult("OnHold")} style={{ width: "100%" }} variant="primary">
                  <FormattedMessage id="InspectionLogItem.OnHold" defaultMessage="On Hold" />
                </Button> */}
              </Space>
            ]}
          >
            <Spin spinning={this.state.loadingChecklistSingle}>
              <Form id="FormChecklist" initialValues={{ remember: false }} onFinish={this.handleFormSingleListSubmit} ref={this.formChecklist}>
                {this.checklistAnswerType(this.state.checklist, layout, props)}
                {/* <Form.Item
                  {...layout}
                  label={<FormattedMessage id="InspectionLogItem.Result" defaultMessage="Result" />}
                  name="result"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Radio.Group>
                    <Radio value={"Accept"}>
                      <FormattedMessage id="InspectionLogItem.Accept" defaultMessage="Accept" />
                    </Radio>
                    <Radio onClick={this.onClickSingleItemRejected} value={"Reject"}>
                      <FormattedMessage id="InspectionLogItem.Reject" defaultMessage="Reject" />
                    </Radio>
                    <Radio value={"OnHold"}>
                      <FormattedMessage id="InspectionLogItem.OnHold" defaultMessage="On Hold" />
                    </Radio>
                  </Radio.Group>
                </Form.Item> */}
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="InspectionLogItem.FileUpload" defaultMessage="Photo" />}
                    name="photo"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Upload
                      {...props}
                      showUploadList={true}
                      fileList={this.state.fileList}
                      onChange={({ fileList }) => {
                        if (fileList.length == 1) {
                          if (Boolean(fileList[0].response)) {
                            fileList[0].url = fileList[0].response.url;
                            showSuccess("file uploaded successfully");
                          }
                          this.setState({
                            fileList: fileList
                          });
                        }
                      }}
                      beforeUpload={fileList => {
                        if (this.state.fileList.length > 0) {
                          showError("You have been already choose a file! For change firstly remove the uploaded file.");
                          return false;
                        }
                        return true;
                      }}
                      onRemove={fileList => {
                        this.setState({
                          fileList: []
                        });
                      }}
                    >
                      <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                        <UploadOutlined /> Click to Upload
                      </AntButton>
                    </Upload>
                  </Form.Item>
                }
                <br />
                <br />
                <Button
                  style={{ display: this.state.previousButtonProps }}
                  variant="dark"
                  onClick={() => {
                    const itemIndex = this.state.itemIndex;
                    if (itemIndex > 0) {
                      this.setState(
                        {
                          itemIndex: itemIndex - 1,
                          previousButtonProps: "",
                          nextButtonProps: ""
                        },
                        () => {
                          this.singleOpenCheckListModel(this.state.selectedChecklistRow);
                        }
                      );
                    } else {
                      this.setState({
                        previousButtonProps: "none"
                      });
                    }
                  }}
                >
                  <FormattedMessage id="GeneralButton.Previous" defaultMessage="Previous" />
                </Button>{" "}
                <Button style={{ display: this.state.nextButtonProps }} variant="dark" onClick={this.saveSingleResultList}>
                  <FormattedMessage id="GeneralButton.Next" defaultMessage="Next" />
                </Button>
              </Form>
            </Spin>
          </Modal>

          <Modal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            visible={this.state.modalDeleteShow}
            onCancel={() => {
              this.setState({ modalDeleteShow: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ modalDeleteShow: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.delete}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Space>
            ]}
          >
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal>

          {/* MULTIPLE SAMPLE MODEL */}
          <Modal
            open={this.state.showResultDimensionalAnswerModal}
            keyboard={false}
            maskClosable={false}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ showResultDimensionalAnswerModal: false, selectedRowKeys: [] })}
            width={800}
            centered
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      showResultDimensionalAnswerModal: false,
                      selectedRowKeys: []
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="success" onClick={this.saveMultipleResultList}>
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Space>
            ]}
          >
            <Badge.Ribbon
              text={"Sample:" + " " + this.state.sampleNo + " / " + this.state.sampleSize}
              placement="start"
              color="purple"
              style={{ fontSize: 12 }}
            >
              <Form
                id="FormAddResultList"
                initialValues={{ remember: false }}
                onFinish={this.handleFormMultipleListSubmit}
                ref={this.formAddResultList}
              >
                <br />
                <br />
                <br />
                {Boolean(this.state.inspectionLogItemType) &&
                  (this.state.inspectionLogItemType === "Dimension_Control") && (
                    <>
                      {console.log("dynamicAttributes", this.state.dynamicAttributes)}
                      {Boolean(this.state.dynamicAttributes) &&
                        this.state.dynamicAttributes.length > 0 &&
                        this.state.dynamicAttributes.map((attribute, index) => {
                          return (
                            <Badge overflowCount={attribute.value + 1} count={attribute.value}>
                              <Form.Item
                                {...layout}
                                label={attribute.label}
                                name={attribute.label}
                                rules={[
                                  { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                                key={index}
                              >
                                <InputNumber
                                  parser={value => value.replace(",", ".")}
                                  formatter={value => value.replace(".", ",")}
                                  onChange={value => {
                                    if (Boolean(value)) {
                                      this.warningMessage(
                                        value,
                                        attribute.value,
                                        attribute.label,
                                        Boolean(this.state.selectedChecklistRowData) ? this.state.selectedChecklistRowData.id : null
                                      );
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Badge>
                          );
                        })}

                      {
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="InspectionLogItem.Result" defaultMessage="Result" />}
                          name="result"
                          rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Radio.Group>
                            <Radio value={"Accept"}>
                              <FormattedMessage id="InspectionLogItem.Accept" defaultMessage="Accept" />
                            </Radio>
                            <Radio onClick={this.onClickSingleItemRejected} value={"Reject"}>
                              <FormattedMessage id="InspectionLogItem.Reject" defaultMessage="Reject" />
                            </Radio>
                            <Radio value={"OnHold"}>
                              <FormattedMessage id="InspectionLogItem.OnHold" defaultMessage="On Hold" />
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      }

                      {
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="InspectionLogItem.FileUpload" defaultMessage="Photo" />}
                          name="photo"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Upload
                            {...props}
                            showUploadList={true}
                            fileList={this.state.fileList}
                            onChange={({ fileList }) => {
                              if (fileList.length == 1) {
                                if (Boolean(fileList[0].response)) {
                                  fileList[0].url = fileList[0].response.url;
                                  showSuccess("file uploaded successfully");
                                }
                                this.setState({
                                  fileList: fileList
                                });
                              }
                            }}
                            beforeUpload={fileList => {
                              if (this.state.fileList.length > 0) {
                                showError("You have been already choose a file! For change firstly remove the uploaded file.");
                                return false;
                              }
                              return true;
                            }}
                            onRemove={fileList => {
                              this.setState({
                                fileList: []
                              });
                            }}
                          >
                            <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                              <UploadOutlined /> Click to Upload
                            </AntButton>
                          </Upload>
                        </Form.Item>
                      }
                    </>
                  )}
              </Form>
            </Badge.Ribbon>
          </Modal>

          <Modal
            open={this.state.showResultChemicalCompositionAnswerModal}
            keyboard={false}
            maskClosable={false}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ showResultChemicalCompositionAnswerModal: false, selectedRowKeys: [] })}
            width={800}
            centered
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      showResultChemicalCompositionAnswerModal: false,
                      selectedRowKeys: []
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="success" onClick={this.saveChemicalResultSubmit}>
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Space>
            ]}
          >
            <Badge.Ribbon
              text={<FormattedMessage id="InspectionLogItem.ChemicalComposition" defaultMessage="Chemical Composition" />}
              placement="start"
              color="purple"
              style={{ fontSize: 12 }}
            >
              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveInspectionLogItemChemicalResult}
                ref={this.formInspectionLogItemChemicalResult}
              >
                <br /><br /><br />
                {Boolean(this.state.inspectionLogItemType) && this.state.inspectionLogItemType === "Chemical_Composition" && (
                  <>
                    {console.log("elementAttributes", this.state.elementAttributes)}
                    {Boolean(this.state.elementAttributes) &&
                      this.state.elementAttributes.length > 0 &&
                      this.state.elementAttributes.map((attribute, index) => {
                        return (
                          <>
                            <Badge overflowCount={attribute.maxValue + 1} count={attribute.maxValue}>
                              <Badge overflowCount={attribute.minValue + 1} count={attribute.minValue} offset={[1, 30]}>
                                <Form.Item
                                  {...layout}
                                  label={attribute.elementName}
                                  name={attribute.elementName}
                                  rules={[
                                    { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                  ]}
                                  key={index}
                                >
                                  <InputNumber
                                    parser={value => value.replace(",", ".")}
                                    formatter={value => value.replace(".", ",")}
                                    onChange={value => {
                                      attribute.value = value;
                                      if (Boolean(value)) {
                                        this.warningMessageChemicalComposition(
                                          value,
                                          attribute.chemicalCompositionDetailId,
                                          attribute.elementId,
                                          attribute
                                        );
                                      }
                                    }}
                                  />
                                </Form.Item>
                                {attribute.result}
                              </Badge>
                            </Badge>
                            <br /><br />
                          </>
                        );
                      })}
                  </>
                )}
              </Form>
            </Badge.Ribbon>
          </Modal>

          {/* MULTIPLE */}
          <Modal
            title={<FormattedMessage id="InspectionLogItem.ChecklistResult" defaultMessage="Checklist Result" />}
            open={this.state.showResultModal}
            onCancel={() => {
              this.setState({ showResultModal: false });
            }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            width={1400}
            centered
            // closable={false}
            footer={[
              <Space>
                <Button id="AcceptButton" onClick={() => this.saveInspectionLogItemResult("Accept")} style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="InspectionLogItem.Accept" defaultMessage="Accept" />
                </Button>
                {/* <Button id="RejectButton" onClick={this.onClickMultipleItemRejected} style={{ width: "100%" }} variant="danger">
                  <FormattedMessage id="InspectionLogItem.Reject" defaultMessage="Reject" />
                </Button>
                <Button id="OnHoldButton" onClick={() => this.saveInspectionLogItemResult("OnHold")} style={{ width: "100%" }} variant="primary">
                  <FormattedMessage id="InspectionLogItem.OnHold" defaultMessage="On Hold" />
                </Button> */}
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      showResultModal: false
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
              </Space>
            ]}
          >
            <Table
              bordered
              rowKey={record => record.id}
              rowSelection={{
                type: "radio",
                ...rowSelectionAddResult
              }}
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              components={{
                header: {
                  cell: ResizableTitle
                }
              }}
              columns={Boolean(mergeColumnsAddResult) && mergeColumnsAddResult.length > 0 ? mergeColumnsAddResult : columnsAddResult}
              dataSource={this.state.tableAddResultList}
              loading={this.state.loadingAddResult}
              onChange={this.handleTableChangeAddResult}
              pagination={this.state.paginationAddResult}
            />
          </Modal>

          <Modal
            title={<FormattedMessage id="InspectionLogItem.PDFReportViewer" defaultMessage="PDF Report Viewer" />}
            centered
            width={1400}
            okButtonProps={{ hidden: true }}
            open={this.state.modalPdfViewer}
            onCancel={() => {
              this.setState({ modalPdfViewer: false });
            }}
          >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>
          </Modal>

          <Modal
            title=""
            centered
            open={this.state.modalProcessTypeShow}
            onCancel={() => {
              this.setState({ modalProcessTypeShow: false });
            }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
          >
            <FormattedMessage
              id="InspectionLogItem.SelectEncodingMethodOfChecklist"
              defaultMessage="Please select encoding method of checklist items ?"
            />
            <br />
            <br />
            <br />
            <Row gutter={[16, 16]}>
              <Col md={6}></Col>
              {/* <Col md={6}>
                <Spin spinning={this.state.loadingChecklistSingle}>
                  <Button id="OneByOneButton" onClick={this.singleChecklistModel} style={{ width: "100%" }} variant="dark">
                    <FormattedMessage id="GeneralButtonOneByOne" defaultMessage="One by one" />
                  </Button>
                </Spin>
              </Col> */}
              <Col md={6}>
                <Spin spinning={this.state.loadingChecklistMultiple}>
                  <Button id="MultipleButton" onClick={this.multipleChecklistModel} style={{ width: "100%" }} variant="dark">
                    <FormattedMessage id="GeneralButtonMultiple" defaultMessage="Multiple" />
                  </Button>
                </Spin>
              </Col>
              <Col md={6}></Col>
            </Row>
          </Modal>

          <Modal
            title=""
            centered
            open={this.state.modalOnebyoneShow}
            onOk={() => {
              const { tableAddResultList } = this.state;
              if (Boolean(tableAddResultList) && tableAddResultList.length > 0) {
                let id = [];
                id.push(tableAddResultList[0].id);

                this.onSelectChangeAddResult(id);
              }
              this.setState(
                {
                  tableAddResultIndex: 0,
                  modalOnebyoneShow: false
                }
              );
            }}
            onCancel={() => {
              this.setState({ modalOnebyoneShow: false });
            }}
            okButtonProps={{ hidden: false }}
            cancelButtonProps={{ hidden: false }}
          >
            <FormattedMessage id="InspectionLogItem.DoyouwanttoaddresultsOnebyOne" defaultMessage="Do you want to add results `One by One`?" />
            <br />
            <br />
            <br />
            <Row gutter={[16, 16]}></Row>
          </Modal>

          <Modal
            title={<FormattedMessage id="InspectionLogItem.RejectedAmount" defaultMessage="Rejected Amount." />}
            centered
            width={800}
            open={this.state.itemSingleRejectedModalShow}
            onOk={() => {
              this.setState({ itemSingleRejectedModalShow: false });
            }}
            onCancel={() => {
              this.setState({ itemSingleRejectedModalShow: false });
            }}
          >
            <Form
              id="formSingleRejectedAmountList"
              initialValues={{ remember: false }}
              onFinish={this.handleFormRejectedSingleSubmit}
              ref={this.formSingleRejectedAmountList}
            >
              <Row gutter={8}>
                <Col span={8}>
                  <Form.Item
                    style={{
                      display: "inline-block",
                      width: "calc(80%)"
                    }}
                    name="rejectedAmount"
                    help={<FormattedMessage id="InspectionLogItem.RejectedAmount" defaultMessage="Rejected Amount" />}
                  >
                    <InputNumber
                      style={{
                        width: "100%"
                      }}
                      min={0}
                      max={this.state.totalAmount}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{
                      display: "inline-block",
                      width: "calc(80%)"
                    }}
                    name="totalAmount"
                    help={<FormattedMessage id="InspectionLogItem.TotalAmount" defaultMessage="Total Amount" />}
                  >
                    <InputNumber
                      style={{
                        width: "100%"
                      }}
                      readOnly
                      min={0}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>

          <Modal
            title={<FormattedMessage id="InspectionLogItem.RejectedAmount" defaultMessage="Rejected Amount" />}
            centered
            width={800}
            open={this.state.itemSaveRejectedModalShow}
            onOk={() => {
              this.formSaveRejectedAmountList.current.submit();
            }}
            onCancel={() => {
              this.setState({ itemSaveRejectedModalShow: false });
            }}
            okText={<FormattedMessage id="InspectionLogItem.Rejected" defaultMessage="Rejected" />}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          >
            <Form
              id="formSaveRejectedAmountList"
              initialValues={{ remember: false }}
              onFinish={this.handleFormRejectedSaveSubmit}
              ref={this.formSaveRejectedAmountList}
            >
              <Row gutter={8}>
                <Col span={8}>
                  <Form.Item
                    style={{
                      display: "inline-block",
                      width: "calc(80%)"
                    }}
                    name="rejectedAmount"
                    help={<FormattedMessage id="InspectionLogItem.RejectedAmount" defaultMessage="Rejected Amount" />}
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <InputNumber
                      style={{
                        width: "100%"
                      }}
                      min={0}
                      max={this.state.totalAmount}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{
                      display: "inline-block",
                      width: "calc(80%)"
                    }}
                    name="totalAmount"
                    help={<FormattedMessage id="InspectionLogItem.TotalAmount" defaultMessage="Total Amount" />}
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <InputNumber
                      style={{
                        width: "100%"
                      }}
                      readOnly
                      min={0}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>

          <Modal
            title={<FormattedMessage id="InspectionLogItem.InspectionResult" defaultMessage="Inspection Result" />}
            open={this.state.showInspectionResultModal}
            okButtonProps={{ hidden: false }}
            cancelButtonProps={{ hidden: true }}
            // onCancel={() => this.setState({ showInspectionResultModal: false })}
            onOk={() => this.setState({ showInspectionResultModal: false, showNonConformingSamplesModal: true })}
            width={800}
            centered
          >
            <p style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>
              <FormattedMessage id="InspectionLogItem.NonConformingLimitExceeded" defaultMessage="Non-Conforming Limit Exceeded !!!" />
            </p>
            <br></br>
            <p style={{ textAlign: "center", color: "green", fontWeight: "bold" }}>
              <FormattedMessage id="InspectionLogItem.PleaseCarryonwithnewsampling" defaultMessage="Please carry on with new sampling." />
            </p>
          </Modal>

          <Modal
            title={<FormattedMessage id="InspectionLogItem.InspectionResult" defaultMessage="Inspection Result" />}
            open={this.state.showInspectionResultSuccessModal}
            okButtonProps={{ hidden: false }}
            cancelButtonProps={{ hidden: true }}
            // onCancel={() => this.setState({ showInspectionResultModal: false })}
            closable={false}
            onOk={() => this.setState({ showInspectionResultSuccessModal: false })}
            width={800}
            centered
          >
            <p style={{ textAlign: "center", color: "green", fontWeight: "bold" }}>
              <FormattedMessage
                id="InspectionLogItem.InspectionresultisSUCCESFULL"
                defaultMessage=" (S/N)  (InspectionType) Inspection result is SUCCESFULL!"
              />
            </p>
            <Button variant="dark" onClick={this.nextClick}>
              Next
            </Button>
          </Modal>

          <Modal
            title={<FormattedMessage id="InspectionLogItem.InspectionResult" defaultMessage="HATA Success Inspection Result" />}
            open={this.state.showInspectionResultHataSuccessModal}
            okButtonProps={{ hidden: false }}
            cancelButtonProps={{ hidden: true }}
            closable={false}
            // onCancel={() => this.setState({ showInspectionResultModal: false })}
            onOk={() => this.setState({ showInspectionResultHataSuccessModal: false, showNonConformingSamplesModal: true })}
            width={800}
            centered
          >
            <p style={{ textAlign: "center", color: "green", fontWeight: "bold" }}>
              <FormattedMessage
                id="InspectionLogItem.InspectionresultisSUCCESFULL"
                defaultMessage=" (S/N)  (InspectionType) Inspection result is SUCCESFULL!"
              />
            </p>
          </Modal>

          <Modal
            title={<FormattedMessage id="InspectionLogItem.Info" defaultMessage="INFO" />}
            open={this.state.showInspectionInfo}
            okButtonProps={{ hidden: false }}
            cancelButtonProps={{ hidden: true }}
            closable={false}
            // onCancel={() => this.setState({ showInspectionResultModal: false })}
            onOk={() => {

              console.log("selectedAddResultAnswer", this.state.selectedAddResultAnswer);

              const { selectedAddResultAnswer } = this.state;

              if (Boolean(selectedAddResultAnswer) && Boolean(selectedAddResultAnswer.inspectionLogItemType)) {
                let showResultDimensionalAnswerModal = false;
                let showResultChemicalCompositionAnswerModal = false;

                if (selectedAddResultAnswer.inspectionLogItemType === "Dimension_Control") {
                  showResultDimensionalAnswerModal = true;
                } else if (selectedAddResultAnswer.inspectionLogItemType === "Chemical_Composition") {
                  showResultChemicalCompositionAnswerModal = true;
                }

                this.setState({
                  showInspectionInfo: false,
                  showResultDimensionalAnswerModal: showResultDimensionalAnswerModal,
                  showResultChemicalCompositionAnswerModal: showResultChemicalCompositionAnswerModal
                });
              } else {
                showWarning("Type not found.");
              }
            }}
            width={800}
            centered
          >
            <p style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>
              <FormattedMessage id="InspectionLogItem.NonConformingLimitExceeded" defaultMessage="Total Number of Sample : " />{" "}
              {this.state.sampleSize}
            </p>
            <br></br>
            <p style={{ textAlign: "center", color: "green", fontWeight: "bold" }}>
              <FormattedMessage
                id="InspectionLogItem.PleaseCarryonwithnewsampling"
                defaultMessage="Samples must be selected randomlay and must represent all lot"
              />
            </p>
          </Modal>


          {/* buraya next eklenmeli */}
          <Modal
            title={<FormattedMessage id="InspectionLogItem.NonConformingSamples" defaultMessage="Non-Conforming Samples" />}
            open={this.state.showNonConformingSamplesModal}
            okButtonProps={{ hidden: false }}
            cancelButtonProps={{ hidden: true }}
            // onCancel={() => this.setState({ showInspectionResultModal: false })}
            closable={false}
            onOk={() => {
              // this.formRefNonConformingSamples.current.submit();
              this.setState({ showNonConformingSamplesModal: false });
            }}
            width={800}
            centered
          >
            <br></br>
            <p style={{ textAlign: "center", color: "darkblue", fontWeight: "bold", fontSize: "16px" }}>
              <FormattedMessage
                id="InspectionLogItem.Pleasemarkandseparatethenonconformingsamples"
                defaultMessage="Please ensure the non-conforming sample(s) is/are marked and separated"
              />
            </p>
            <br></br> <br></br>
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="InspectionLogItem.Location" defaultMessage="Location" />}
                name="parentId"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Cascader changeOnSelect style={{ width: "63%" }} options={this.state.nodes} onChange={this.handleLocationIdChange} />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="InspectionLogItem.FileUpload" defaultMessage="Photo" />}
                name="photo"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Upload
                  {...props}
                  showUploadList={true}
                  fileList={this.state.fileList}
                  onChange={({ fileList }) => {
                    if (fileList.length == 1) {
                      if (Boolean(fileList[0].response)) {
                        fileList[0].url = fileList[0].response.url;
                        showSuccess("file uploaded successfully");
                      }
                      this.setState({
                        fileList: fileList
                      });
                    }
                  }}
                  beforeUpload={fileList => {
                    if (this.state.fileList.length > 0) {
                      showError("You have been already choose a file! For change firstly remove the uploaded file.");
                      return false;
                    }
                    return true;
                  }}
                  onRemove={fileList => {
                    this.setState({
                      fileList: []
                    });
                  }}
                >
                  <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> Click to Upload
                  </AntButton>
                </Upload>
              </Form.Item>
            }
            <Button variant="dark" onClick={this.nextClick}>
              Next
            </Button>
          </Modal>
        </div>

        <Drawer title={<FormattedMessage id="InspectionLogItem.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200} closable={false} onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }} open={this.state.drawerSettingsShow}>
          <PageSettings pageName={"Inspection_Log_Item"} />
        </Drawer>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InspectionLogItem);
