import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import { Button, Modal } from 'react-bootstrap';
import { FilterOutlined } from '@ant-design/icons';
import { showError, showSuccess, error } from '../../MessageHelper';
import ExportIcon from '@material-ui/icons/CloudDownloadRounded';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { Form, Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col, Row } from 'antd';
import { ExcelReader } from '../excel/ExcelReader';
import moment from 'moment';
import { API_BASE_ROOT, handleRequest } from '../../ApiConnector';
import ActionMenu from '../CustomMenu/ActionMenu';
import CreateIcon from '@material-ui/icons/AddCircle';
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import Property from './Property';
import { ItemProperty } from './ItemProperty';
import { InventoryMaterialProperty } from './InventoryMaterialProperty';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

export class PropertyTabs extends Component {

    constructor() {
        super();
        this.state = {

            modalDeleteShow: false,
            exportList: [],
            exportClick: false,
            hideUpdate: true,
            hideSave: false,
            hideInputs: true,
            lastUpdateDate: [],

            id: null,
            activeKey: null,
            panes: [],

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['2', '10', '25', '50', '100', '1000', '10000', '100000', '1000000'],
                total: 0,
            },
            loading: false,
            sortField: "",
            sortOrder: "",

            excelData: [],
        };


    }

    formRef = React.createRef();

    componentDidMount = async () => {

        this.fillComboboxes();
    };

    getContent = (propertyGroupId) => {

        let itemId = this.props.itemId;
        let inventoryId = this.props.inventoryId;

        if (Boolean(inventoryId) && Boolean(itemId))
            return <InventoryMaterialProperty itemId={itemId} inventoryId={inventoryId} propertyGroupId={propertyGroupId} ></InventoryMaterialProperty>;
        else if (Boolean(itemId))
            return <ItemProperty itemId={itemId} propertyGroupId={propertyGroupId} ></ItemProperty>;
        else
            return <Property propertyGroupId={propertyGroupId} ></Property>;
    }

    fillComboboxes = async () => {

        var response = await handleRequest("GET", "/api/propertyGroup");

        if (response.type === "ERROR") {
            error(response);
        }
        else {
            let panes = [];
            if (Boolean(response.data))
                response.data.forEach(element => {
                    panes.push({ title: element.name, content: this.getContent(element.id), key: element.id.toString() });
                });
            this.setState({
                activeKey: panes.length > 0 ? panes[0].key : null,
                panes: panes
            });
        }


    }

    onChange = activeKey => {
        this.setState({ activeKey });
    };

    cancel = () => {
        this.setState({
            hideUpdate: true,
            hideSave: false,
            hideInputs: true,
        });
        this.resetInputs();
    };

    resetInputs = () => {

        this.formRef.current.resetFields();
        this.setState({
            id: null,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['2', '10', '25', '50', '100', '1000', '10000', '100000', '1000000'],
                total: this.state.totalRecords,
            },
            loading: false,
            sortField: "",
            sortOrder: "",
            filePath: '',
            fileList: [],
        });

    }


    edit = (row) => {

        document.getElementById("kt_scrolltop").click();

        let fileList = [];

        this.formRef.current.setFieldsValue({
            ...row,

        });

        this.setState({
            hideUpdate: false,
            hideSave: true,
            hideInputs: false,
            id: row.id,

        });


    };

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    delete = async () => {
        const deletedItem = {
            id: this.state.id
        }
        var response = await handleRequest("DELETE", "/api/propertyGroup/" + deletedItem.id);

        if (response.type === "ERROR") {
            error(response);
        }
        else {
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.setState({
                id: null,
                modalDeleteShow: false
            });
        }
    }




    handleTableChange = (pagination, filters, sorter) => {
        this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters, });
    };

    restartTable = async (params = {}) => {
        this.setState({ loading: true });
        const newObj = this.createWhere(params);
        let url = "/api/propertyGroup/paging";
        const response = await handleRequest("POST", url, newObj);
        if (response.type === "ERROR") {
            error(response);
            this.setState({
                loading: false,
                tableList: [],
                pagination: {
                    ...params.pagination,
                    total: 0,
                },
                totalRecords: 0,
                sortField: "",
                sortOrder: "",
            });
        }
        else {
            if (response.data.length !== 0) {
                let list = response.data.data;
                list.forEach((element, index) => {
                    element.index = index + 1;
                });
                this.setState({
                    loading: false,
                    tableList: list,
                    pagination: {
                        ...params.pagination,
                        total: response.data.totalRecords,
                    },
                    totalRecords: response.data.totalRecords,
                    sortField: params.sortField,
                    sortOrder: params.sortOrder,
                });
            } else {
                this.setState({
                    loading: false,
                    tableList: [],
                    pagination: {
                        ...params.pagination,
                        total: 0,
                    },
                    totalRecords: 0,
                    sortField: "",
                    sortOrder: "",
                });
            }
        }
    };

    onClickCreateNew = () => {
        this.resetInputs();
        if (this.state.hideInputs) {
            this.setState({
                hideInputs: false,
                hideSave: false,
                hideUpdate: true,
            });
        }
        else {
            this.setState({
                hideInputs: true,
                hideSave: true,
                hideUpdate: false,
            });
        }
    }



    save = async (values) => {

        const newItem = { ...values, id: this.state.id }



        if (newItem.id === null) {
            var response = await handleRequest("POST", "/api/propertyGroup", newItem);
        }

        else {
            response = await handleRequest("PUT", "/api/propertyGroup/" + newItem.id, newItem);
        }

        if (response.type === "ERROR") {
            error(response);
        }
        else {
            this.cancel();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();

        }
    };

    createWhere(params) {
        console.log(params);
        if (Boolean(params._pagination))
            params.pagination = params._pagination

        var lastUpdateDateStart = null, lastUpdateDateEnd = null, sortField = "", sortOrder = "";
        if (Boolean(params) && Boolean(params.pagination)) {

            if (Boolean(params.pagination.lastUpdateDate)) {
                lastUpdateDateStart = params.pagination.lastUpdateDate[0];
                lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
            }
            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }
        }
        const newObj = {
            ...params.pagination,
            id: null,
            lastUpdateDateStart: lastUpdateDateStart,
            lastUpdateDateEnd: lastUpdateDateEnd,
            firstResult: (params.pagination.current - 1),
            maxResults: params.pagination.pageSize,
            sortField: sortField,
            sortOrder: sortOrder,
        };
        return newObj;
    }



    handleExportExcel = async () => {

        this.setState({

            exportClick: true,
        });

    }


    addCode = async (codeType) => {

        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: codeType
            }

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: null,
                    showCodeModal: false
                });
                this.fillCode(codeType);
            }
        } else {
            showError("Please type value");
        }

    }

    fillCode = async (codeType) => {

        const response = await handleRequest("GET", "/api/codes/type/" + codeType);
        if (Boolean(response.data)) {
            this.setState({
                [codeType + "SelectItems"]: response.data
            });
        }
    }


    action = (record) => {
        return <React.Fragment>
            <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)}
                onEditRow={this.edit.bind(this)} />
        </React.Fragment>;
    };



    render() {




        return (<div className="card card-custom" style={{ padding: '2rem', minHeight: '100%' }}>

            <div style={{ marginBottom: 16 }}>
              { !Boolean(this.props.itemId) && <AntButton onClick={this.add}>Add Property Group</AntButton>}
            </div>
            <Tabs
                hideAdd
                onChange={this.onChange}
                activeKey={this.state.activeKey}
                type="editable-card"
            >
                {this.state.panes.map(pane => (
                    <TabPane tab={pane.title} key={pane.key}>
                        {pane.content}
                    </TabPane>
                ))}
            </Tabs>



        </div>
        );
    }
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyTabs)
