import "antd/dist/antd.css";
import { connect } from "react-redux";
import XMLParser from "react-xml-parser";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Col as ColBoot, Row as RowBoot, Form as FormBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { ReplyOutlined } from "@material-ui/icons";
import {
  Input,
  Badge,
  InputNumber,
  Checkbox,
  Col,
  Row,
  Modal,
  Table,
  Typography,
  DatePicker,
  Form,
  Space,
  Upload,
  Button as AntButton,
  Divider,
  Select, Cascader, Spin
} from "antd";
import { handleRequest, API_BASE_ROOT } from "../../ApiConnector";
import moment from "moment";
import { UploadOutlined, InfoCircleTwoTone, ExclamationCircleOutlined } from "@ant-design/icons";
import { Resizable } from "react-resizable";
import "../../assets/style/resizeTable.css";
import { POReplyPaymentTerms } from "./POReplyPaymentTerms";

const { Text } = Typography;
const { Option } = Select;

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate"
      ? form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? moment(record[dataIndex], "DD-MM-YYYY") : ""
      })
      : form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
      });
  };

  const toggleSave = async () => {
    try {
      if (dataIndex === "planedDeliveryDate") {
        const values = await form.validateFields();
        values.planedDeliveryDate = Boolean(values.planedDeliveryDate) ? moment(values.planedDeliveryDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else if (dataIndex === "paymentDate") {
        const values = await form.validateFields();
        values.paymentDate = Boolean(values.paymentDate) ? moment(values.paymentDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record }, { ...values });
      }
    } catch (errInfo) { }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <>
        {dataIndex === "deliveryPlace" ? (
          <>
            <Form.Item
              label={<FormattedMessage id="POVendorReply.town" defaultMessage="Town" />}
              name="town"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Form.Item
              label={<FormattedMessage id="POVendorReply.city" defaultMessage="City" />}
              name="city"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Button id="SaveButton" onClick={toggleSave} style={{ width: "100%" }} variant="success">
              <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
            </Button>
          </>
        ) : (
          <Form.Item
            style={{
              margin: 0
            }}
            name={dataIndex}
          >
            {dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate" ? (
              <DatePicker ref={inputRef} onBlur={toggleSave} format={"DD-MM-YYYY"} />
            ) : dataIndex === "currency" ? (
              <Select ref={inputRef} onChange={toggleSave} onBlur={toggleSave} showSearch optionFilterProp="children" style={{ width: "200px" }}>
                {Boolean(record.currencySelectItems) &&
                  record.currencySelectItems.length > 0 &&
                  record.currencySelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            ) : dataIndex === "unit" ? (
              <Cascader
                style={{ width: "100%" }}
                options={record.unitSelectItems}
                ref={inputRef} onChange={toggleSave}
                onBlur={toggleSave}
              />
            ) : (
              <InputNumber parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} min={0} ref={inputRef} onPressEnter={toggleSave} onBlur={toggleSave} />
            )}
          </Form.Item>
        )}
      </>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion

export class POVendorReply extends Component {
  constructor({ purchaseOrder }) {
    super();
    this.state = {
      controllerName: "poVendorReply",
      hideSave: false,
      id: null,
      purchaseOrder: purchaseOrder,
      purchaseOrderId: purchaseOrder?.id,
      requestForQuotationId: purchaseOrder?.requestForQuotationId,
      vendorId: purchaseOrder?.vendorId,
      requestForQuotationsSelectItems: [],
      disableRfqSelect: true,
      vendorReplyList: [],
      fileList: [],
      fileUrl: "",
      hasReplyOurExpectedPrice: false,
      currencySelect: "TL",

      saveLoading: false,

      paginationDeliveryDate: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },

    };
  }

  formCurrency = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
    this.fillReplies();
    this.getHasReplyOurExpectedPrice();
  };

  componentDidUpdate = async prevProps => {
    let purchaseOrderId = Boolean(this.props) && Boolean(this.props.purchaseOrder) ? this.props.purchaseOrder.id : undefined;
    let requestForQuotationId = Boolean(this.props) && Boolean(this.props.purchaseOrder) ? this.props.purchaseOrder.requestForQuotationId : undefined;
    let vendorId = Boolean(this.props) && Boolean(this.props.purchaseOrder) ? this.props.purchaseOrder.vendorId : undefined;

    let random = this.props.random;
    if (random !== prevProps.random) {
      this.setState(
        {
          purchaseOrderId: purchaseOrderId,
          requestForQuotationId: requestForQuotationId,
          vendorId: vendorId,
          vendorReplyList: [],
          fileUrl: "",
          fileList: []
        },
        () => {
          this.fillComboboxes();
          this.fillReplies();
          this.getHasReplyOurExpectedPrice();
        }
      );
    }
  };

  fillComboboxes = async () => {
    var responsePeriodType = await handleRequest("GET", "/api/" + this.state.controllerName + "/periodType");
    if (responsePeriodType.type === "ERROR") {
      error(responsePeriodType);
    }

    var responseCurrency = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responseCurrency.type === "ERROR") {
      error(responseCurrency);
    }

    var responseKur = await handleRequest("GET", "/api/" + this.state.controllerName + "/kur");
    if (responseKur.type === "ERROR") {
      error(responseKur);
    }

    var responseAllUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/allUnit");
    if (responseAllUnit.type === "ERROR") {
      error(responseAllUnit);
    }

    var kur = null;
    try {
      kur = new XMLParser().parseFromString(responseKur.data);
      console.log("KKK", kur);
    } catch (error) { }

    this.setState({
      periodTypeSelectItems: Boolean(responsePeriodType.data) ? responsePeriodType.data : [],
      currencySelectItems: Boolean(responseCurrency.data) ? responseCurrency.data : [],
      kur: kur,
      allUnitSelectItems: Boolean(responseAllUnit.data) ? responseAllUnit.data : [],
    });
  };

  fillReplies() {
    let newItem = {};
    this.fillVendorReplies();

    this.setState({
      ...newItem
    });
  }

  getHasReplyOurExpectedPrice = async () => {
    if (Boolean(this.state.purchaseOrderId)) {
      var responseOurExpected = await handleRequest(
        "GET",
        "/api/" + this.state.controllerName + "/hasReplyOurExpectedPrice/" + this.state.purchaseOrderId
      );
      if (responseOurExpected.type === "ERROR") {
        error(responseOurExpected);
      } else {
        this.setState({
          hasReplyOurExpectedPrice: responseOurExpected.data
        });
      }
    } else {
      this.setState({
        hasReplyOurExpectedPrice: false
      });
    }
  };

  cancel = () => {
    this.setState({
      hideSave: false
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.setState({
      id: null,

      vendorReplyList: [],
      fileList: [],
      fileUrl: ""
    });
  };


  onClickDeliveryDateModal = () => {
    this.setState({
      modalDeliveryDateShow: true
    });
    this.getPricesData();
  };

  onClickPricesModal = () => {
    this.setState({
      modalPriceShow: true
    });

    this.getPricesData();
  };

  getPricesData = async () => {
    if (Boolean(this.state.purchaseOrderId) && Boolean(this.state.requestForQuotationId)) {
      var newObj = {
        id: this.state.purchaseOrderId,
        requestForQuotationId: this.state.requestForQuotationId
      };
      let responsePricecs = await handleRequest("POST", "/api/" + this.state.controllerName + "/ourExpectedPrices/" + this.state.vendorId, newObj);
      if (responsePricecs.type === "ERROR") {
        error(responsePricecs);
      } else {
        if (Boolean(responsePricecs.data) && responsePricecs.data.length > 0) {
          responsePricecs.data.forEach((element, index) => {
            if (Boolean(element.planedDeliveryDate)) {
              element.planedDeliveryDate = moment(element.planedDeliveryDate).format("DD-MM-YYYY");
            }
            element.price = element.quantity * element.unitPrice;
            element.key = index + 1;
            element.currencySelectItems = this.state.currencySelectItems;

            if (!Boolean(element.priceVat)) {
              element.priceVat = 20;
            }

            this.getUnitStcokCard(element);
          });
        }
        this.setState({
          ourExpectedPrices: Boolean(responsePricecs.data) && responsePricecs.data.length > 0 ? responsePricecs.data : [],
          // modalPriceShow: true
        });
      }
    } else {
      this.setState({
        ourExpectedPrices: []
      });
    }
  };

  handlePriceListUnitOurExpectedPrice = async (row, value) => {
    if (Boolean(value) && Boolean(value.unit) && value.unit.length > 0) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(item)) {
        let unitId = Boolean(value.unit) && value.unit.length > 0 ? value.unit[value.unit.length - 1] : null;
        let unitTypeId = Boolean(value.unit) && value.unit.length > 0 ? value.unit[0] : null;
        if (!isNaN(unitId) && !isNaN(unitTypeId)) {
          item.unitId = unitId;
          item.purchasingCriteriaUnitId = unitId;
          const unitType = row.unitSelectItems.find(p => p.value === unitTypeId);
          if (Boolean(unitType) && Boolean(unitType.children) && unitType.children.length > 0) {
            const unit = unitType.children.find(p => p.value === unitId);
            if (Boolean(unit)) {
              item.unit = unit.label;
            }
          }
          console.log("RRR", row);

          this.setState({
            ourExpectedPrices: newData
          });
        }
      }
    }
  };

  handlePriceListQuantityOurExpectedPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.quantity === value.quantity) return;
        var newQuantity = item.quantity - value.quantity;
        if (Boolean(newQuantity) && newQuantity > 0) {
          row.quantity = newQuantity;
          row.oldQuantity = 0;
        }
        item.oldQuantity = item.quantity;
        item.quantity = value.quantity;
        item.price = item.quantity * item.unitPrice;
        this.setState({
          ourExpectedPrices: newData
        });
      }
    }
  };

  handlePriceListPlannedDeliveryDate = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.planedDeliveryDate === value.planedDeliveryDate) return;
        item.planedDeliveryDate = value.planedDeliveryDate;
        this.setState({
          ourExpectedPrices: newData
        });
      }
    }
  };

  handlePriceListUnitOurExpectedPriceVat = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.priceVat === value.priceVat) return;
        item.priceVat = value.priceVat;
        this.setState({
          ourExpectedPrices: newData
        });
      }
    }
  };

  handlePriceListUnitPriceOurExpectedPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(value.unitPrice) && Boolean(item)) {
        if (item.unitPrice === value.unitPrice) return;
        item.unitPrice = value.unitPrice;
        item.price = item.quantity * item.unitPrice;
        this.setState(
          {
            ourExpectedPrices: newData,
            modalCurrencyShow: true,
            selectedOurExpectedPriceId: item.key
          },
          () => {
            this.formCurrency.current.setFieldsValue({
              ...row
            });
          }
        );
      }
    }
  };

  handlePriceListCurrencyOurExpectedPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.currency === value.currency) return;
        item.currency = value.currency;
        this.setState({
          ourExpectedPrices: newData
        });
      }
    }
  };

  handlePriceListDeliveryPlaceOurExpectedPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        item.town = value.town;
        item.city = value.city;
        this.setState({
          ourExpectedPrices: newData
        });
      }
    }
  };

  renderElement = (values, title) => {
    return values.map((item, index) => (
      <RowBoot key={item.description + index} style={{ marginBottom: "1rem" }}>
        <ColBoot xs={4}>
          <FormBoot.Label style={index === 0 ? { color: "black", marginTop: "0.5rem", fontWeight: "bold" } : { color: "black", marginTop: "0.5rem" }}>
            {item.description}
          </FormBoot.Label>
        </ColBoot>
        <ColBoot xs={2} className="text-center">
          {index !== 0 && (
            <Checkbox
              valuePropName="checked"
              checked={item.mandatory}
              onChange={e => {
                if (e.target.checked) {
                  item.mandatory = false;
                }
                item.mandatory = e.target.checked;
                this.setState({
                  vendorReplyList: this.state.vendorReplyList
                });
              }}
            />
          )}
        </ColBoot>

        {/* {(item.description === "Fix Date" || item.description === "Your proposal of delivery date" ||
          item.description === "To be decided by mutual negotiations") ?
          <>
            <ColBoot xs={3}>
              {index !== 0 && <Input
                value={item.replyMessage}
                placeholder="Reply"
                onChange={e => {
                  item.replyMessage = e.target.value;
                  this.setState({
                    vendorReplyList: this.state.vendorReplyList
                  });
                }}
                onClick={this.onClickDeliveryDateModal}
              ></Input>}
            </ColBoot>
          </> :
          (item.description === "Your offer for price" || item.description === "Our expected price") ?
            <>
              <ColBoot xs={3}>
                {index !== 0 && <Input
                  value={item.replyMessage}
                  placeholder="Reply"
                  onChange={e => {
                    item.replyMessage = e.target.value;

                    this.setState({
                      vendorReplyList: this.state.vendorReplyList
                    });
                  }}
                  onClick={this.onClickPricesModal}
                ></Input>}
              </ColBoot>
            </> :
            <ColBoot xs={3}>
              {index !== 0 && <Input
                value={item.replyMessage}
                placeholder="Reply"
                onChange={e => {
                  item.replyMessage = e.target.value;

                  this.setState({
                    vendorReplyList: this.state.vendorReplyList
                  });
                }}
              ></Input>}
            </ColBoot>
        } */}

        {item.description === "Fix Date" ||
          item.description === "Your proposal of delivery date" ||
          item.description === "To be decided by mutual negotiations" ? (
          <>
            {this.state.hasReplyOurExpectedPrice ? (
              <>
                <ColBoot xs={1}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={this.onClickDeliveryDateModal}
                    icon={<InfoCircleTwoTone style={{ fontSize: "25px", cursor: "pointer" }} />}
                  ></AntButton>
                </ColBoot>
                <ColBoot xs={2}>
                  {index !== 0 && (
                    <FormattedMessage id="POVendorReply.reply" defaultMessage="Reply">
                      {msg => (
                        <Input
                          value={item.replyMessage}
                          placeholder={msg}
                          onChange={e => {
                            item.replyMessage = e.target.value;
                            this.setState({
                              vendorReplyList: this.state.vendorReplyList
                            });
                          }}
                          onClick={this.onClickDeliveryDateModal}
                        ></Input>
                      )}
                    </FormattedMessage>
                  )}
                </ColBoot>
              </>
            ) : (
              <ColBoot xs={3}>
                {index !== 0 && (
                  <FormattedMessage id="POVendorReply.reply" defaultMessage="Reply">
                    {msg => (
                      <Input
                        value={item.replyMessage}
                        placeholder={msg}
                        onChange={e => {
                          item.replyMessage = e.target.value;
                          this.setState({
                            vendorReplyList: this.state.vendorReplyList
                          });
                        }}
                        onClick={this.onClickDeliveryDateModal}
                      ></Input>
                    )}
                  </FormattedMessage>
                )}
              </ColBoot>
            )}
          </>
        ) : item.description === "Your offer for price" || item.description === "Our expected price" ? (
          <>
            {this.state.hasReplyOurExpectedPrice ? (
              <>
                <ColBoot xs={1}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={this.onClickPricesModal}
                    icon={<InfoCircleTwoTone style={{ fontSize: "25px", cursor: "pointer" }} />}
                  ></AntButton>
                </ColBoot>
                <ColBoot xs={2}>
                  {index !== 0 && (
                    <FormattedMessage id="POVendorReply.reply" defaultMessage="Reply">
                      {msg => (
                        <Input
                          value={item.replyMessage}
                          placeholder={msg}
                          onChange={e => {
                            item.replyMessage = e.target.value;
                            this.setState({
                              vendorReplyList: this.state.vendorReplyList
                            });
                          }}
                          onClick={this.onClickPricesModal}
                        ></Input>
                      )}
                    </FormattedMessage>
                  )}
                </ColBoot>
              </>
            ) : (
              <ColBoot xs={3}>
                {index !== 0 && (
                  <FormattedMessage id="POVendorReply.reply" defaultMessage="Reply">
                    {msg => (
                      <Input
                        value={item.replyMessage}
                        placeholder={msg}
                        onChange={e => {
                          item.replyMessage = e.target.value;
                          this.setState({
                            vendorReplyList: this.state.vendorReplyList
                          });
                        }}
                        onClick={this.onClickPricesModal}
                      ></Input>
                    )}
                  </FormattedMessage>
                )}
              </ColBoot>
            )}
          </>
        ) : (
          <ColBoot xs={3}>
            {index !== 0 && (
              <FormattedMessage id="POVendorReply.reply" defaultMessage="Reply">
                {msg => (
                  <Input
                    value={item.replyMessage}
                    placeholder={msg}
                    onChange={e => {
                      item.replyMessage = e.target.value;
                      this.setState({
                        vendorReplyList: this.state.vendorReplyList
                      });
                    }}
                  ></Input>
                )}
              </FormattedMessage>
            )}
          </ColBoot>
        )}

        <ColBoot xs={3}>
          {index !== 0 && (
            <FormattedMessage id="POVendorReply.Remarks" defaultMessage="Remarks">
              {msg => (
                <Input
                  value={item.remarks}
                  placeholder={msg}
                  onChange={e => {
                    item.remarks = e.target.value;

                    this.setState({
                      vendorReplyList: this.state.vendorReplyList
                    });
                  }}
                ></Input>
              )}
            </FormattedMessage>
          )}
        </ColBoot>
      </RowBoot>
    ));
  };

  onChangeRequestForQuotation = async value => {
    this.setState({
      requestForQuotationId: value,
      vendorReplyList: []
    });
  };

  fillVendorReplies = async () => {
    if (Boolean(this.state.requestForQuotationId) && Boolean(this.state.purchaseOrderId)) {
      var response = await handleRequest(
        "GET",
        "/api/" + this.state.controllerName + "/getRfq/" + this.state.requestForQuotationId + "/" + this.state.purchaseOrderId
      );
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(response.data) && response.data.length > 0) {
          let fileList = [];
          if (response.data[0].fileUrl) {
            let fileUrl = Boolean(response.data[0].fileUrl) ? response.data[0].fileUrl.split(",") : [];
            if (Boolean(fileUrl) && fileUrl.length > 0) {
              fileUrl.forEach((element, index) => {
                let obj = {
                  uid: index,
                  name: element.split("download/")[1],
                  url: element,
                  thumbUrl: element
                };
                fileList.push(obj);
              });
            }
          }
          this.setState(
            {
              vendorReplyList: response.data,
              fileList: fileList,
              fileUrl: response.data[0].fileUrl
            },
            () => {
              if (Boolean(this.state.vendorReplyList) && this.state.vendorReplyList.length > 0) {
                this.getPricesData();
              }
            }
          );
        } else {
          this.setState({
            vendorReplyList: [],
            fileList: [],
            fileUrl: ""
          });
        }
      }
    } else {
      this.setState({
        vendorReplyList: [],
        fileList: [],
        fileUrl: ""
      });
    }
  };

  getRfqNo = record => {
    if (!Boolean(record) || !Boolean(record.id)) {
      return "";
    }
    if (record.id.toString().length === 2) {
      return "RFQ-0" + record.id;
    } else if (record.id.toString().length === 1) {
      return "RFQ-00" + record.id;
    } else {
      return "RFQ-" + record.id;
    }
  };

  saveDeliveryDate = async () => {
    if (Boolean(this.state.ourExpectedPrices) && this.state.ourExpectedPrices.length > 0) {
      if (Boolean(this.state.ourExpectedPrices) && this.state.ourExpectedPrices.length > 0) {
        this.state.ourExpectedPrices.forEach(element => {
          if (Boolean(element.planedDeliveryDate)) {
            element.planedDeliveryDate = moment(element.planedDeliveryDate, "DD-MM-YYYY");
          }
        });
      }
      var response = await handleRequest(
        "POST",
        "/api/" + this.state.controllerName + "/saveDeliveryDate/" + this.state.purchaseOrderId,
        this.state.ourExpectedPrices
      );
      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess();
        this.setState({
          modalDeliveryDateShow: false
        });
      }
    } else {
      this.showError("Not found");
    }
  };

  savePrice = async () => {
    this.setState({ saveLoading: true });
    if (Boolean(this.state.ourExpectedPrices) && this.state.ourExpectedPrices.length > 0) {
      console.log("ourExpectedPrices", this.state.ourExpectedPrices);
      let paymentTermsErrorItem = [];
      this.state.ourExpectedPrices.forEach(element => {
        if (Boolean(element.planedDeliveryDate)) {
          element.planedDeliveryDate = moment(element.planedDeliveryDate, "DD-MM-YYYY");
        }
        if (!(Boolean(element.poPaymentTerms) && element.poPaymentTerms.length > 0)) {
          paymentTermsErrorItem.push(element.itemDescriptionRename + " (" + element.quantity + ")");
        }
      });
      if (Boolean(paymentTermsErrorItem) && paymentTermsErrorItem.length > 0) {
        this.setState({
          modalPaymentTermsErrorShow: true,
          paymentTermsErrorItem: paymentTermsErrorItem,
          saveLoading: false,
        })
      } else {
        var response = await handleRequest(
          "POST",
          "/api/" + this.state.controllerName + "/savePrice/" + this.state.purchaseOrderId,
          this.state.ourExpectedPrices
        );
        if (response.type === "ERROR") {
          error(response);
          this.setState({ saveLoading: false });
        } else {
          this.getHasReplyOurExpectedPrice();
          showSuccess();
          this.setState({
            modalPriceShow: false,
            saveLoading: false
          });
          if (
            Boolean(this.state.vendorReplyList) &&
            this.state.vendorReplyList.length > 0 &&
            Boolean(this.state.purchaseOrderId) &&
            this.state.purchaseOrderId > 0
          ) {
            var newItem = { ...this.state.vendorReplyList[0] };
            newItem.id = null;
            newItem.purchaseOrderId = this.state.purchaseOrderId;
            newItem.fileUrl = this.state.fileUrl;

            var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/save", newItem);
            if (response.type === "ERROR") {
              error(response);
              this.setState({ saveLoading: false });
            } else {
              this.cancel();
              this.props.hideReplyModal();
              this.setState({ saveLoading: false });
            }
          } else {
            showError();
            this.setState({ saveLoading: false });
          }
        }
      }
    } else {
      this.showError("Not found");
      this.setState({ saveLoading: false });
    }
  };

  savePriceAgain = async () => {
    if (Boolean(this.state.ourExpectedPrices) && this.state.ourExpectedPrices.length > 0) {
      this.state.ourExpectedPrices.forEach(element => {
        if (Boolean(element.planedDeliveryDate)) {
          element.planedDeliveryDate = moment(element.planedDeliveryDate, "DD-MM-YYYY");
        }
      });
      var response = await handleRequest(
        "POST",
        "/api/" + this.state.controllerName + "/savePrice/" + this.state.purchaseOrderId,
        this.state.ourExpectedPrices
      );
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.getHasReplyOurExpectedPrice();
        showSuccess();
        this.setState({
          modalPriceShow: false
        });
        if (
          Boolean(this.state.vendorReplyList) &&
          this.state.vendorReplyList.length > 0 &&
          Boolean(this.state.purchaseOrderId) &&
          this.state.purchaseOrderId > 0
        ) {
          var newItem = { ...this.state.vendorReplyList[0] };
          newItem.id = null;
          newItem.purchaseOrderId = this.state.purchaseOrderId;
          newItem.fileUrl = this.state.fileUrl;

          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/save", newItem);
          if (response.type === "ERROR") {
            error(response);
          } else {
            this.cancel();
            this.props.hideReplyModal();
          }
        } else {
          showError();
        }
      }
    } else {
      this.showError("Not found");
    }
  };

  paymentTermsModal = async row => {
    this.setState(
      {
        selectedOurExpectedPrice: row,
        selectedOurExpectedPriceId: row.key,
        modalPaymentTermsShow: true,
      });
  };

  setPaymentTerms = (poPaymentTerms) => {
    if (Boolean(this.state.selectedOurExpectedPriceId) && this.state.selectedOurExpectedPriceId > 0) {
      var ourExpectedPrices = this.state.ourExpectedPrices;
      var obj = ourExpectedPrices.find(p => p.key === this.state.selectedOurExpectedPriceId);
      if (Boolean(obj)) {
        obj.poPaymentTerms = poPaymentTerms;
        this.setState({
          ourExpectedPrices: ourExpectedPrices,
          modalPaymentTermsShow: false
        });
      } else {
        showError("Not found");
      }
    } else {
      showError("Not found");
    }
  };

  saveCurrency = values => {
    console.log("AAA", values);
    if (Boolean(this.state.selectedOurExpectedPriceId) && this.state.selectedOurExpectedPriceId > 0) {
      var ourExpectedPrices = this.state.ourExpectedPrices;
      var obj = ourExpectedPrices.find(p => p.key === this.state.selectedOurExpectedPriceId);
      if (Boolean(obj)) {
        obj.currency = values.currency;
        this.setState({
          ourExpectedPrices: ourExpectedPrices,
          modalCurrencyShow: false
        });
        this.paymentTermsModal(obj);
        showSuccess();
      } else {
        showError("Not found");
      }
    } else {
      showError("Not found");
    }
  };

  async getUnitStcokCard(element) {
    if (Boolean(element) && Boolean(element.id)) {
      let responseUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/unitStockCard/" + element.id);
      if (responseUnit.type === "ERROR") {
        error(responseUnit);
      } else {
        element.unitSelectItems = Boolean(responseUnit.data) && responseUnit.data.length > 0 ? responseUnit.data : [];
      }
    } else {
      element.unitSelectItems = [];
    }
  }

  addNewRow = row => {
    let maxOurExpectedPrice = this.state.maxOurExpectedPrice + 1;
    let newData = [...this.state.ourExpectedPrices];

    row.key = maxOurExpectedPrice;
    let newObj = { row: row };
    newData.push(newObj.row);

    this.setState({
      ourExpectedPrices: newData,
      maxOurExpectedPrice: maxOurExpectedPrice
    });
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      },
      header: {
        cell: ResizableTitle
      }
    };

    const columnsOurExpectedPrice = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.paginationDeliveryDate.pageSize * (this.state.paginationDeliveryDate.current - 1);
        }
      },
      {
        title: <FormattedMessage id="POVendorReply.ItemDescription" defaultMessage="Item Description" />,
        key: "id",
        width: 100,
        render: record => {
          return record.itemDescriptionRename;
        }
      },
      {
        title: <FormattedMessage id="POVendorReply.Quantity" defaultMessage="Quantity" />,
        key: "quantity",
        width: 100,
        render: record => {
          return Boolean(record.oldQuantity) && record.oldQuantity > 0 ? (
            <Badge showZero offset={[25, -15]} overflowCount={record.oldQuantity + 1} count={record.oldQuantity}>
              {record.quantity}
            </Badge>
          ) : (
            record.quantity
          );
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "quantity",
          title: <FormattedMessage id="POVendorReply.quantity" defaultMessage="Quantity" />,
          handleSave: this.handlePriceListQuantityOurExpectedPrice
        })
      },
      {
        title: <FormattedMessage id="POVendorReply.Unit" defaultMessage="Unit" />,
        key: "unit",
        width: 100,
        render: record => {
          let weight = "";
          console.log("record", record);
          if (record.unit && Boolean(record.weight) && Boolean(record.quantity)) {
            if (Boolean(this.state.allUnitSelectItems) && this.state.allUnitSelectItems.length > 0) {
              let unit = this.state.allUnitSelectItems.find(p => p.abbreviation === record.unit);
              console.log("unit", unit);
              if (Boolean(unit) && Boolean(unit.abbreviation) && unit.abbreviation === "ad") {
                weight = record.quantity;
              } else {
                if (Boolean(unit) && unit.unitTypeName === "Length") {
                  if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                    weight = ((record.length * record.quantity) / unit.coefficent);
                  } else {
                    weight = (record.length * record.quantity);
                  }
                } else {
                  if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                    console.log("coefficent1111", unit.coefficent);
                    console.log(record.quantity * record.weight);
                    console.log((record.quantity * record.weight) / unit.coefficent);
                    weight = ((record.quantity * record.weight) / unit.coefficent);
                  } else {
                    console.log("weight2222", record.weight * record.quantity);
                    weight = (record.weight * record.quantity);
                  }
                }
              }
            }
          }
          console.log("weight", weight);
          console.log("unit", record.unit);
          return ((weight.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ')) + " " + record.unit);
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "unit",
          title: <FormattedMessage id="POVendorReply.unit" defaultMessage="Unit" />,
          handleSave: this.handlePriceListUnitOurExpectedPrice
        })
      },
      {
        title: <FormattedMessage id="POVendorReply.UnitPrice" defaultMessage="Unit Price" />,
        key: "unitPrice",
        width: 100,
        render: record => {
          return Boolean(record.oldUnitPrice) && record.oldUnitPrice > 0 ? (
            <Badge showZero offset={[25, -15]} overflowCount={record.oldUnitPrice + 1} count={record.oldUnitPrice}>
              {record.unitPrice}
            </Badge>
          ) : (
            record.unitPrice
          );
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "unitPrice",
          title: <FormattedMessage id="POVendorReply.unitPrice" defaultMessage="Unit Price" />,
          handleSave: this.handlePriceListUnitPriceOurExpectedPrice
        })
      },
      {
        title: <FormattedMessage id="POVendorReply.Currency" defaultMessage="Currency" />,
        key: "currency",
        width: 100,
        render: record => {
          return record.currency;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "currency",
          title: <FormattedMessage id="POVendorReply.currency" defaultMessage="Currency" />,
          handleSave: this.handlePriceListCurrencyOurExpectedPrice
        })
      },
      {
        title: <FormattedMessage id="POVendorReply.PaymentTerms" defaultMessage="Payment Terms" />,
        key: "id",
        width: 100,
        render: record => {
          return (
            <AntButton
              onClick={() => this.paymentTermsModal(record)}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<ReplyOutlined fontSize="large" />}
            ></AntButton>
          );
        }
      },
      {
        title: <FormattedMessage id="POVendorReply.Price" defaultMessage="Price" />,
        key: "price",
        width: 100,
        render: record => {
          return record.price;
        }
      },
      {
        title: <FormattedMessage id="POVendorReply.Vat" defaultMessage="Vat(%)" />,
        key: "priceVat",
        width: 100,
        render: record => {
          return record.priceVat;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "priceVat",
          title: <FormattedMessage id="POVendorReply.priceVat" defaultMessage="Vat(%)" />,
          handleSave: this.handlePriceListUnitOurExpectedPriceVat
        })
      },
      {
        title: <FormattedMessage id="POVendorReply.planedDeliveryDate" defaultMessage="Planned Delivery Date" />,
        key: "planedDeliveryDate",
        width: 100,
        render: record => {
          return record.planedDeliveryDate;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "planedDeliveryDate",
          title: <FormattedMessage id="POVendorReply.planedDeliveryDate" defaultMessage="Planned Delivery Date" />,
          handleSave: this.handlePriceListPlannedDeliveryDate
        })
      },
      {
        title: <FormattedMessage id="POVendorReply.DeliveryPlace" defaultMessage="Delivery Place" />,
        key: "id",
        width: 100,
        render: record => {
          return Boolean(record.town) && Boolean(record.town) && Boolean(record.city) && Boolean(record.city) && record.town + "/" + record.city;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "deliveryPlace",
          title: <FormattedMessage id="POVendorReply.DeliveryPlace" defaultMessage="Delivery Place" />,
          handleSave: this.handlePriceListDeliveryPlaceOurExpectedPrice
        })
      }
    ];

    const columnsPricesDeliveryDate = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.paginationDeliveryDate.pageSize * (this.state.paginationDeliveryDate.current - 1);
        }
      },
      {
        title: <FormattedMessage id="POVendorReply.Item" defaultMessage="Item" />,
        key: "description",
        width: 100,
        render: record => {
          return record.description;
        }
      },
      {
        title: <FormattedMessage id="POVendorReply.ItemCode" defaultMessage="Item Code" />,
        width: 100,
        key: "code",
        render: record => {
          return record.code;
        }
      },
      {
        title: <FormattedMessage id="POVendorReply.Quantity" defaultMessage="Quantity" />,
        width: 100,
        key: "quantity",
        render: record => {
          return record.quantity;
        }
      },
      {
        title: <FormattedMessage id="POVendorReply.Unit" defaultMessage="Unit" />,
        width: 100,
        key: "unit",
        render: record => {
          return record.unit;
        }
      },
      {
        title: <FormattedMessage id="POVendorReply.Location" defaultMessage="Location" />,
        width: 100,
        key: "locationId",
        render: record => {
          return Boolean(record.location) ? record.location.name : "";
        }
      },
      {
        title: <FormattedMessage id="POVendorReply.Vat" defaultMessage="Vat(%)" />,
        width: 100,
        key: "priceVat",
        render: record => {
          return record.priceVat;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "priceVat",
          title: <FormattedMessage id="POVendorReply.priceVat" defaultMessage="Vat(%)" />,
          handleSave: this.handlePriceListUnitOurExpectedPriceVat
        })
      },
      {
        title: <FormattedMessage id="POVendorReply.planedDeliveryDate" defaultMessage="Planned Delivery Date" />,
        width: 100,
        key: "planedDeliveryDate",
        render: record => {
          return record.planedDeliveryDate;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "planedDeliveryDate",
          title: <FormattedMessage id="POVendorReply.planedDeliveryDate" defaultMessage="Planned Delivery Date" />,
          handleSave: this.handlePriceListPlannedDeliveryDate
        })
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumnsPricesDeliveryDate = [...columnsPricesDeliveryDate];

      newColumnsPricesDeliveryDate[index] = {
        ...newColumnsPricesDeliveryDate[index],
        width: size.width
      };

      const newColumnsOurExpectedPrice = [...columnsOurExpectedPrice];

      newColumnsOurExpectedPrice[index] = {
        ...newColumnsOurExpectedPrice[index],
        width: size.width
      };

      this.setState({
        columnsPricesDeliveryDate: newColumnsPricesDeliveryDate,
        columnsOurExpectedPrice: newColumnsOurExpectedPrice
      });
    };

    const mergeColumnsOurExpectedPrice =
      Boolean(this.state.columnsOurExpectedPrice) && this.state.columnsOurExpectedPrice.length > 0
        ? this.state.columnsOurExpectedPrice.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columnsOurExpectedPrice) &&
        columnsOurExpectedPrice.length > 0 &&
        columnsOurExpectedPrice.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const mergeColumnsPricesDeliveryDate =
      Boolean(this.state.columnsPricesDeliveryDate) && this.state.columnsPricesDeliveryDate.length > 0
        ? this.state.columnsPricesDeliveryDate.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columnsPricesDeliveryDate) &&
        columnsPricesDeliveryDate.length > 0 &&
        columnsPricesDeliveryDate.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col md={24}>
            <span className="h4">{this.props.purchaseOrder.vendorName}</span>
          </Col>
        </Row>
        {Boolean(this.state.vendorReplyList) && this.state.vendorReplyList.length > 0 ? (
          <>
            <RowBoot style={{ marginTop: "2rem" }}>
              <ColBoot xs={4}>
                <Upload
                  {...props}
                  id="fileUrl"
                  showUploadList={true}
                  fileList={this.state.fileList}
                  onChange={({ fileList }) => {
                    if (fileList.length === 1) {
                      var fileUrl = "";
                      if (Boolean(fileList[0].response)) {
                        fileUrl = fileList[0].response.url;
                        showSuccess("file uploaded successfully");
                      }
                      this.setState({
                        fileList: fileList,
                        fileUrl: fileUrl
                      });
                    }
                  }}
                  beforeUpload={fileList => {
                    if (this.state.fileList.length > 0) {
                      showError("You have been already choose a file! For change firstly remove the uploaded file.");
                      return false;
                    }
                    return true;
                  }}
                  onRemove={({ fileList }) => {
                    this.setState({
                      fileList: [],
                      fileUrl: ""
                    });
                  }}
                >
                  <AntButton icon={<UploadOutlined />}> Add File </AntButton>
                </Upload>
              </ColBoot>
            </RowBoot>
            <br />
            <Table
              bordered
              components={components}
              rowClassName={() => "editable-row"}
              scroll={{ x: 1200, scrollToFirstRowOnChange: true }}
              columns={
                Boolean(mergeColumnsOurExpectedPrice) && mergeColumnsOurExpectedPrice.length > 0
                  ? mergeColumnsOurExpectedPrice
                  : columnsOurExpectedPrice
              }
              dataSource={this.state.ourExpectedPrices}
              loading={this.state.loading}
              pagination={false}
              summary={pageData => {
                let priceSubTotal = 0;
                let priceVatTotal = 0;
                pageData.forEach(({ price, priceVat, currency }) => {
                  if (Boolean(price) && price > 0 && Boolean(currency)) {
                    if (Boolean(price) && price > 0) {
                      priceSubTotal += this.getPriceWtihKur(currency, price);

                      if (Boolean(priceVat) && priceVat > 0) {
                        priceVatTotal += this.getVatWtihKur(currency, price, priceVat);
                      }
                    }
                  }
                });
                var priceTotal = priceSubTotal + priceVatTotal;
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <FormattedMessage id="GeneralButtonSubTotal" defaultMessage="Sub total" />
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text type="danger">{priceSubTotal.toFixed(2)}</Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <FormattedMessage id="GeneralButtonVAT" defaultMessage="VAT" />
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <InputNumber readOnly min={0} value={priceVatTotal.toFixed(2)} />
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <FormattedMessage id="GeneralButtonTotal" defaultMessage="Total" />
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text type="danger">{priceTotal.toFixed(2)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Select
                          showSearch
                          optionFilterProp="children"
                          style={{ width: "200px" }}
                          value={this.state.currencySelect}
                          onChange={value => {
                            this.setState({
                              currencySelect: value
                            });
                          }}
                        >
                          {Boolean(this.state.currencySelectItems) &&
                            this.state.currencySelectItems.length > 0 &&
                            this.state.currencySelectItems.map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />

            <br />
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Spin spinning={this.state.saveLoading}>
                      <Button id="SaveButton" onClick={this.savePrice} style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Spin>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </>
        ) : (
          ""
        )}

        <Modal
          title="Delivery Date"
          open={this.state.modalDeliveryDateShow}
          onCancel={() => {
            this.setState({ modalDeliveryDateShow: false });
          }}
          width={1500}
          centered
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalDeliveryDateShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>

              <Button id="SaveButton" onClick={this.saveDeliveryDate} style={{ width: "100%" }} variant="success">
                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
              </Button>
            </Space>
          ]}
        >
          <Table
            bordered
            components={components}
            rowClassName={() => "editable-row"}
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={
              Boolean(mergeColumnsPricesDeliveryDate) && mergeColumnsPricesDeliveryDate.length > 0
                ? mergeColumnsPricesDeliveryDate
                : columnsPricesDeliveryDate
            }
            dataSource={this.state.ourExpectedPrices}
            loading={this.state.loading}
          />
        </Modal>

        <Modal
          title="Price"
          open={this.state.modalPriceShow}
          onCancel={() => {
            this.setState({ modalPriceShow: false });
          }}
          width={1500}
          centered
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalPriceShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
              <Spin spinning={this.state.saveLoading}>
                <Button id="SaveButton" onClick={this.savePrice} style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Spin>
            </Space>
          ]}
        >
          <Table
            bordered
            components={components}
            rowClassName={() => "editable-row"}
            scroll={{ x: 1200, scrollToFirstRowOnChange: true }}
            columns={
              Boolean(mergeColumnsOurExpectedPrice) && mergeColumnsOurExpectedPrice.length > 0
                ? mergeColumnsOurExpectedPrice
                : columnsOurExpectedPrice
            }
            dataSource={this.state.ourExpectedPrices}
            loading={this.state.loading}
            pagination={false}
            summary={pageData => {
              let priceSubTotal = 0;
              let priceVatTotal = 0;
              pageData.forEach(({ price, priceVat, currency }) => {
                if (Boolean(price) && price > 0 && Boolean(currency)) {
                  if (Boolean(price) && price > 0) {
                    priceSubTotal += this.getPriceWtihKur(currency, price);

                    if (Boolean(priceVat) && priceVat > 0) {
                      priceVatTotal += this.getVatWtihKur(currency, price, priceVat);
                    }
                  }
                }
              });
              var priceTotal = priceSubTotal + priceVatTotal;
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <FormattedMessage id="GeneralButtonSubTotal" defaultMessage="Sub total" />
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="danger">{priceSubTotal.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <FormattedMessage id="GeneralButtonVAT" defaultMessage="VAT" />
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <InputNumber readOnly min={0} value={priceVatTotal.toFixed(2)} />
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <FormattedMessage id="GeneralButtonTotal" defaultMessage="Total" />
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="danger">{priceTotal.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        style={{ width: "200px" }}
                        value={this.state.currencySelect}
                        onChange={value => {
                          this.setState({
                            currencySelect: value
                          });
                        }}
                      >
                        {Boolean(this.state.currencySelectItems) &&
                          this.state.currencySelectItems.length > 0 &&
                          this.state.currencySelectItems.map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Modal>

        <Modal
          open={this.state.modalCurrencyShow}
          onCancel={() => {
            this.setState({ modalCurrencyShow: false });
          }}
          width={1000}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalCurrencyShow: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
        >
          <Badge.Ribbon
            text={<FormattedMessage id="POVendorReply.Currency" defaultMessage="Currency" />}
            placement="start"
            color="purple"
            style={{ fontSize: 12 }}
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveCurrency} ref={this.formCurrency}>
              <br />
              <Form.Item {...layout} label={<FormattedMessage id="POVendorReply.currency" defaultMessage="Currency" />} name="currency">
                <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                  {Boolean(this.state.currencySelectItems) &&
                    this.state.currencySelectItems.length > 0 &&
                    this.state.currencySelectItems.map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <br />
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            </Form>
          </Badge.Ribbon>
        </Modal>

        <Modal
          open={this.state.modalPaymentTermsShow}
          width={1800}
          centered
          keyboard={false}
          maskClosable={false}
          closable={false}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
        >
          <POReplyPaymentTerms selectedOurExpectedPrice={this.state.selectedOurExpectedPrice} random={Math.random()} setPaymentTerms={this.setPaymentTerms}></POReplyPaymentTerms>
        </Modal>

        <Modal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="POVendorReply.PaymentTermsInformationIsBlank" defaultMessage="Payment Terms information is blank !" />
              </span>
            </span>
          }
          centered
          open={this.state.modalPaymentTermsErrorShow}
          onOk={() => this.savePriceAgain()}
          onCancel={() => this.setState({ modalPaymentTermsErrorShow: false })}
          okText={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />}
          cancelText={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />}
        >
          {
            Boolean(this.state.paymentTermsErrorItem) && this.state.paymentTermsErrorItem.length > 0
              ? this.state.paymentTermsErrorItem.map((item, index) => (
                <div key={index}>{item}</div>
              ))
              : ""
          }
          <br />
          <br />
          <FormattedMessage id="POVendorReply.MessageDoYouWantToContinue?" defaultMessage="Do you want to continue ?" />
        </Modal>
      </div>
    );
  }

  getPriceWtihKur(currency, price) {
    let { dollarKur, euroKur, poundKur, srKur } = this.getKurValue();
    let priceSubTotal = 0;
    if (currency === "DOLLAR") {
      if (this.state.currencySelect === "DOLLAR") {
        priceSubTotal += price;
      } else if (this.state.currencySelect === "EURO") {
        priceSubTotal += price * (dollarKur / euroKur);
      } else if (this.state.currencySelect === "POUND") {
        priceSubTotal += price * (dollarKur / poundKur);
      } else if (this.state.currencySelect === "SR") {
        priceSubTotal += price * (dollarKur / srKur);
      } else if (this.state.currencySelect === "TL") {
        priceSubTotal += price * dollarKur;
      }
    } else if (currency === "EURO") {
      if (this.state.currencySelect === "EURO") {
        priceSubTotal += price;
      } else if (this.state.currencySelect === "DOLLAR") {
        priceSubTotal += price * (euroKur / dollarKur);
      } else if (this.state.currencySelect === "POUND") {
        priceSubTotal += price * (euroKur / poundKur);
      } else if (this.state.currencySelect === "SR") {
        priceSubTotal += price * (euroKur / srKur);
      } else if (this.state.currencySelect === "TL") {
        priceSubTotal += price * euroKur;
      }
    } else if (currency === "POUND") {
      if (this.state.currencySelect === "POUND") {
        priceSubTotal += price;
      } else if (this.state.currencySelect === "EURO") {
        priceSubTotal += price * (poundKur / euroKur);
      } else if (this.state.currencySelect === "DOLLAR") {
        priceSubTotal += price * (poundKur / dollarKur);
      } else if (this.state.currencySelect === "SR") {
        priceSubTotal += price * (poundKur / srKur);
      } else if (this.state.currencySelect === "TL") {
        priceSubTotal += price * poundKur;
      }
    } else if (currency === "SR") {
      if (this.state.currencySelect === "SR") {
        priceSubTotal += price;
      } else if (this.state.currencySelect === "POUND") {
        priceSubTotal += price * (srKur / poundKur);
      } else if (this.state.currencySelect === "EURO") {
        priceSubTotal += price * (srKur / euroKur);
      } else if (this.state.currencySelect === "DOLLAR") {
        priceSubTotal += price * (srKur / dollarKur);
      } else if (this.state.currencySelect === "TL") {
        priceSubTotal += price * srKur;
      }
    } else if (currency === "TL") {
      if (this.state.currencySelect === "TL") {
        priceSubTotal += price;
      } else if (this.state.currencySelect === "SR") {
        priceSubTotal += price * (1 / srKur);
      } else if (this.state.currencySelect === "POUND") {
        priceSubTotal += price * (1 / poundKur);
      } else if (this.state.currencySelect === "EURO") {
        priceSubTotal += price * (1 / euroKur);
      } else if (this.state.currencySelect === "DOLLAR") {
        priceSubTotal += price * (1 / dollarKur);
      }
    } else {
      return 0;
    }
    return priceSubTotal;
  }

  getVatWtihKur(currency, price, priceVat) {
    let { dollarKur, euroKur, poundKur, srKur } = this.getKurValue();
    let priceVatTotal = 0;
    if (currency === "DOLLAR") {
      if (this.state.currencySelect === "DOLLAR") {
        priceVatTotal += (priceVat * price) / 100;
      } else if (this.state.currencySelect === "EURO") {
        priceVatTotal += (priceVat * (price * (dollarKur / euroKur))) / 100;
      } else if (this.state.currencySelect === "POUND") {
        priceVatTotal += (priceVat * (price * (dollarKur / poundKur))) / 100;
      } else if (this.state.currencySelect === "SR") {
        priceVatTotal += (priceVat * (price * (dollarKur / srKur))) / 100;
      } else if (this.state.currencySelect === "TL") {
        priceVatTotal += (priceVat * (price * dollarKur)) / 100;
      }
    } else if (currency === "EURO") {
      if (this.state.currencySelect === "EURO") {
        priceVatTotal += (priceVat * price) / 100;
      } else if (this.state.currencySelect === "DOLLAR") {
        priceVatTotal += (priceVat * (price * (euroKur / dollarKur))) / 100;
      } else if (this.state.currencySelect === "POUND") {
        priceVatTotal += (priceVat * (price * (euroKur / poundKur))) / 100;
      } else if (this.state.currencySelect === "SR") {
        priceVatTotal += (priceVat * (price * (euroKur / srKur))) / 100;
      } else if (this.state.currencySelect === "TL") {
        priceVatTotal += (priceVat * (price * euroKur)) / 100;
      }
    } else if (currency === "POUND") {
      if (this.state.currencySelect === "POUND") {
        priceVatTotal += (priceVat * price) / 100;
      } else if (this.state.currencySelect === "EURO") {
        priceVatTotal += (priceVat * (price * (poundKur / euroKur))) / 100;
      } else if (this.state.currencySelect === "DOLLAR") {
        priceVatTotal += (priceVat * (price * (poundKur / dollarKur))) / 100;
      } else if (this.state.currencySelect === "SR") {
        priceVatTotal += (priceVat * (price * (poundKur / srKur))) / 100;
      } else if (this.state.currencySelect === "TL") {
        priceVatTotal += (priceVat * (price * poundKur)) / 100;
      }
    } else if (currency === "SR") {
      if (this.state.currencySelect === "SR") {
        priceVatTotal += (priceVat * price) / 100;
      } else if (this.state.currencySelect === "POUND") {
        priceVatTotal += (priceVat * (price * (srKur / poundKur))) / 100;
      } else if (this.state.currencySelect === "EURO") {
        priceVatTotal += (priceVat * (price * (srKur / euroKur))) / 100;
      } else if (this.state.currencySelect === "DOLLAR") {
        priceVatTotal += (priceVat * (price * (srKur / dollarKur))) / 100;
      } else if (this.state.currencySelect === "TL") {
        priceVatTotal += (priceVat * (price * srKur)) / 100;
      }
    } else if (currency === "TL") {
      if (this.state.currencySelect === "TL") {
        priceVatTotal += (priceVat * price) / 100;
      } else if (this.state.currencySelect === "SR") {
        priceVatTotal += (priceVat * (price * (1 / srKur))) / 100;
      } else if (this.state.currencySelect === "POUND") {
        priceVatTotal += (priceVat * (price * (1 / poundKur))) / 100;
      } else if (this.state.currencySelect === "EURO") {
        priceVatTotal += (priceVat * (price * (1 / euroKur))) / 100;
      } else if (this.state.currencySelect === "DOLLAR") {
        priceVatTotal += (priceVat * (price * (1 / dollarKur))) / 100;
      }
    } else {
      return 0;
    }
    return priceVatTotal;
  }

  getKurValue() {
    let dollarKur = 0,
      euroKur = 0,
      poundKur = 0,
      srKur = 0;
    if (Boolean(this.state.kur) && Boolean(this.state.kur.children) && this.state.kur.children.length > 0) {
      this.state.kur.children.forEach(element => {
        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "USD") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            dollarKur = obj.value;
          }
        }

        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "EUR") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            euroKur = obj.value;
          }
        }

        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "GBP") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            poundKur = obj.value;
          }
        }

        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "SAR") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            srKur = obj.value;
          }
        }
      });
    }
    return { dollarKur, euroKur, poundKur, srKur };
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(POVendorReply);
