import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Row, Col, Button as AntButton, Input, InputNumber, Card, Checkbox, Tooltip, Select, Radio, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined, ZoomInOutlined } from '@ant-design/icons';
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import DraggableModal from "../../../Components/DraggableModal";
import { Filter } from "@material-ui/icons";
import SyntaxHighlighter from 'react-syntax-highlighter';
import ReactMarkdown from 'react-markdown';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { SVGIcon } from "../../../../_metronic/_partials/controls";
import copyLink from "../../helper/CopLink";
import "./index.css";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class PageAutoDesignFrontendCode extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "PageAutoDesignFrontendCode",
      controllerName: "pageAutoDesign",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      materialList: [],
      usageList: [],
      inputDetails: [],
      currText: "",

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();
  formRefDetail = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.getFrontendCodes();
  };

  getFrontendCodes = async () => {
    let newObj = {
      ...this.props.pageAutoDesign,
      folder: this.props.folder
    }
    const response = await handleRequest("POST", "/api/" + this.state.controllerName + "/generateFrontendPage", newObj);
    if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        currText: response.data
      });
    }
  };


  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  handleAddDetail = () => {
    const detailValues = this.formRefDetail.current.getFieldsValue();
    console.log("detailValues", detailValues);
    const { selectedInputIndex, selectedInputName, inputDetails } = this.state;

    let updatedDetails = [...inputDetails];
    if (selectedInputIndex !== -1) {
      let inputDetail = updatedDetails[selectedInputIndex] || {};
      inputDetail = { ...inputDetail, ...detailValues };
      inputDetail.inputName = selectedInputName;
      updatedDetails[selectedInputIndex] = inputDetail;
      this.setState({
        inputDetails: updatedDetails,
        selectedInputIndex: -1,
        selectedInputName: "",
      });
      this.formRefDetail.current.resetFields();
    }
  }

  save = async values => {
    const { generateType, parentId } = this.props;
    let { inputDetails } = this.state;

    let formName = values.formName;

    if (!Boolean(generateType)) {
      showWarning("Generate type not found.");
      return;
    }

    if (!(Boolean(formName) && formName.length > 1)) {
      showWarning("Form name not found.");
      return;
    }

    const newItem = {
      ...values,
      id: this.state.id,
      parentId: parentId,
      description: formName,
      generateType: generateType,
    };

    let controllerName = formName.charAt(0).toLowerCase() + formName.slice(1) + 's';
    let forms = [];
    if (Boolean(inputDetails) && inputDetails.length > 0) {
      forms.push({
        formName: formName,
        ribbonName: values.ribbonName,
        controllerName: controllerName,
        tableInputs: inputDetails,
      });
    }
    newItem.forms = forms;
    console.log("newItem", newItem);
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAllPageAutoDesign", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      if (Boolean(this.props) && Boolean(this.props.close)) {
        this.props.close();
      }
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          modalAddNewDimensionShow: false,
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  render() {
    return (
      <ReactMarkdown
        components={{
          code(props) {
            const { children, className, node, ...rest } = props
            const match = /language-(\w+)/.exec(className || 'javascript') 
            return <div className='gpt-code-container' style={{ position: 'relative' }}>
              <SyntaxHighlighter
                {...rest}
                PreTag="div"
                children={String(children).replace(/\n$/, '')}
                language={Boolean(match) && match.length > 1 ? match[1] : ""}
                style={vs2015}
              />

              <div className="copy-button" style={{ position: 'absolute', right: '6px', top: '5px' }}>
                <SVGIcon
                  style={{ cursor: 'pointer', }}
                  icon={'copy'}
                  onClick={() => copyLink(children)}
                  color={'#b9b9b9'}
                  size={25}
                />
              </div>
            </div>
          }
        }}
      >
        {`${this.state.currText}`}

      </ReactMarkdown>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PageAutoDesignFrontendCode);