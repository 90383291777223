import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Space } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Select, Checkbox, Cascader } from "antd";
import { Modal as AntModal } from "antd";
import { Input } from "antd";
import { InputNumber } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined, ZoomInOutlined, PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const { Option } = Select;

const dataList = [];
const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];

        dataList.push({
            key: node.value,
            lineage: node.lineage,
            title: node.value
        });
        if (node.children) {
            generateList(node.children);
        }
    }
};

export class RoomQuantity extends Component {
    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            formName: "RoomQuantity",
            controllerName: "roomQuantity",
            id: null,
            loading: false,
            sortField: "",
            sortOrder: "",
            exportClick: false,
            hideUpdate: true,
            hideSave: false,
            hideInputs: true,
            tableList: [],
            modalPersonnelList: [],
            modalEquipmentList: [],
            unitSelectItems: [],
            roomUnitSelectItems: [],
            disciplineIdSelectItems: [],
            applicationIdSelectItems: [],
            modalApplicationShow: false,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
                total: 0
            },
            searchCriteriaModels: [],
        };
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        this.initialize();
    };

    componentDidUpdate = prevProps => {
        let random = this.props.random;
        if (random !== prevProps.random)
            this.initialize();
    };

    initialize = async () => {
        console.log("props", this.props);
        const { pagination } = this.state;
        this.restartTable({ pagination });
        fillFilterOperations();
        this.fillComboboxes();

        if (Boolean(this.props.houseTypeId) && this.props.houseTypeId > 0) {
            this.setState({
                hideInputs: false,
            });
        }
    }

    fillComboboxes = async () => {
        this.fillCode("Apartment.TypeOfHouses");

        var unitSelectItems = [];
        const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/units");
        if (responseTree.type === "ERROR") {
            error(responseTree);
        } else {
            if (Boolean(responseTree.data) && responseTree.data.length > 0) {
                generateList(responseTree.data);
                unitSelectItems = responseTree.data;
            }
        }
        this.setState({
            unitSelectItems: unitSelectItems
        });

        var responseRoomUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/roomUnit");
        if (responseRoomUnit.type === "ERROR") {
            error(responseRoomUnit);
        } else {
            this.setState({
                roomUnitSelectItems: Boolean(responseRoomUnit.data) ? responseRoomUnit.data : []
            });
        }
    };

    fillStockCardId = async () => {
        const { itemId } = this.state;
        if (Boolean(itemId) && itemId > 0) {
            var responseEldenStockCardId = await handleRequest("GET", "/api/" + this.state.controllerName + "/eldenStockCardId/" + itemId);
            if (responseEldenStockCardId.type === "ERROR") {
                error(responseEldenStockCardId);
            } else {
                this.setState({
                    eldenStockCardIdSelectItems: Boolean(responseEldenStockCardId.data) ? responseEldenStockCardId.data : []
                });
            }
        } else {
            this.setState({
                eldenStockCardIdSelectItems: []
            });
        }
    }

    fillStockCardIdIn = async () => {
        const { itemIds } = this.state;
        console.log("itemIds", itemIds);
        if (Boolean(itemIds) && itemIds.length > 0) {
            var responseEldenStockCardId = await handleRequest("GET", "/api/" + this.state.controllerName + "/eldenStockCardIdIn/" + itemIds);
            if (responseEldenStockCardId.type === "ERROR") {
                error(responseEldenStockCardId);
            } else {
                this.setState({
                    eldenStockCardIdSelectItems: Boolean(responseEldenStockCardId.data) ? responseEldenStockCardId.data : []
                });
            }
        } else {
            this.setState({
                eldenStockCardIdSelectItems: []
            });
        }
    }

    fillRoomTypeId = async (houseTypeId) => {
        if (Boolean(houseTypeId) && houseTypeId > 0) {
            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/roomTypeId/" + houseTypeId);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    roomTypeIdSelectItems: Boolean(response.data) ? response.data : []
                });

                if (Boolean(this.props.roomTypeId) && this.props.roomTypeId > 0) {
                    let roomType = response.data.find(p => p.key === this.props.roomTypeId);
                    if (Boolean(roomType)) {
                        this.formRef.current.setFieldsValue({
                            "roomTypeName": roomType.value
                        });
                    }
                }
            }
        } else {
            this.setState({
                roomTypeIdSelectItems: []
            });
        }
    };

    fillDiscipline = async (roomUnit) => {
        if (Boolean(roomUnit)) {
            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/discipline/" + roomUnit);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    disciplineIdSelectItems: Boolean(response.data) ? response.data : []
                });
            }
        } else {
            this.setState({
                disciplineIdSelectItems: []
            });
        }
    };

    fillStructure = async () => {
        const { discipline, roomUnit } = this.state;
        if (Boolean(discipline) && Boolean(roomUnit)) {
            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/structure/" + discipline + "/" + roomUnit);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    structureSelectItems: Boolean(response.data) ? response.data : []
                });
            }
        } else {
            this.setState({
                structureSelectItems: []
            });
        }
    };

    fillSubStructure = async () => {
        const { structure } = this.state;
        if (Boolean(structure)) {
            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subStructure/" + structure);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    subStructureSelectItems: Boolean(response.data) ? response.data : []
                });
            }
        } else {
            this.setState({
                subStructureSelectItems: []
            });
        }
    };

    fillShape = async () => {
        const { subStructure } = this.state;
        if (Boolean(subStructure)) {
            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/shape/" + subStructure);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    shapeIdSelectItems: Boolean(response.data) ? response.data : []
                });
            }
        } else {
            this.setState({
                shapeIdSelectItems: []
            });
        }
    };

    fillSubStructureType = async () => {
        const { shapeId } = this.state;
        if (Boolean(shapeId)) {
            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subStructureType/" + shapeId);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    subStructureTypeSelectItems: Boolean(response.data) ? response.data : []
                });
            }
        } else {
            this.setState({
                subStructureTypeSelectItems: []
            });
        }
    };

    fillItems = async () => {
        let newObj = {
            roomQuantityRoomUnit: this.state.roomUnit,
            discipline: this.state.discipline,
            structure: this.state.structure,
            subStructure: this.state.subStructure,
            shapeId: this.state.shapeId,
            subStructureType: this.state.subStructureType,
        }
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/items", newObj);
        if (response.type === "ERROR") {
            error(response);
        } else {
            this.setState({
                roomApplicationItems: Boolean(response.data) ? response.data : []
            });
        }
    };

    restartTable = async (params = {}) => {
        this.setState({ loading: true });
        const newObj = this.createWhere(params);
        let url = "/api/" + this.state.controllerName + "/paging";
        const response = await handleRequest("POST", url, newObj);
        if (response.type === "ERROR") {
            error(response);
            this.setState({
                loading: false,
                tableList: [],
                pagination: {
                    ...params.pagination,
                    total: 0
                },
                totalRecords: 0,
                sortField: "",
                sortOrder: ""
            });
        } else {
            if (response.data.length !== 0) {
                let list = response.data.data;
                list.forEach((element, index) => {
                    element.index = index + 1;
                });
                this.setState({
                    loading: false,
                    tableList: list,
                    pagination: {
                        ...params.pagination,
                        total: response.data.totalRecords
                    },
                    totalRecords: response.data.totalRecords,
                    sortField: params.sortField,
                    sortOrder: params.sortOrder
                });
            } else {
                this.setState({
                    loading: false,
                    tableList: [],
                    pagination: {
                        ...params.pagination,
                        total: 0
                    },
                    totalRecords: 0,
                    sortField: "",
                    sortOrder: ""
                });
            }
        }
    };

    createWhere(params) {
        console.log(params);
        if (Boolean(params._pagination)) params.pagination = params._pagination;

        var lastUpdateDateStart = null,
            lastUpdateDateEnd = null,
            sortField = "",
            sortOrder = "";
        if (Boolean(params) && Boolean(params.pagination)) {
            if (Boolean(params.pagination.lastUpdateDate)) {
                lastUpdateDateStart = params.pagination.lastUpdateDate[0];
                lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
            }
            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }
        }
        const newObj = {
            ...params.pagination,
            id: null,

            searchCriteriaModels: this.state.searchCriteriaModels,
            lastUpdateDateStart: lastUpdateDateStart,
            lastUpdateDateEnd: lastUpdateDateEnd,
            firstResult: params.pagination.current - 1,
            maxResults: params.pagination.pageSize,
            sortField: sortField,
            sortOrder: sortOrder
        };
        return newObj;
    }

    fillCode = async codeType => {
        const response = await handleRequest("GET", "/api/codes/type/" + codeType);
        if (Boolean(response.data)) {
            this.setState({
                [codeType + "SelectItems"]: response.data
            });
            if (Boolean(this.props.houseTypeId) && this.props.houseTypeId > 0) {
                let houseType = response.data.find(p => p.id === this.props.houseTypeId);
                if (Boolean(houseType)) {
                    this.formRef.current.setFieldsValue({
                        "houseTypeName": houseType.name
                    });
                }
                this.fillRoomTypeId(this.props.houseTypeId);
            }
        }
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
    };

    edit = row => {
        document.getElementById("kt_scrolltop").click();

        let itemIds = [];
        if (Boolean(row.itemQuantities) && row.itemQuantities.length > 0) {
            row.itemQuantities.forEach(element => {
                if (Boolean(element.itemId) && !itemIds.includes(element.itemId)) {
                    itemIds.push(element.itemId);
                }
            });
        }

        this.setState({
            hideUpdate: false,
            hideSave: true,
            hideInputs: false,
            id: row.id,
            roomUnit: row.roomUnit,
            discipline: row.discipline,
            usedBefore: row.usedBefore,
            structure: row.structure,
            subStructure: row.subStructure,
            shapeId: row.shapeId,
            subStructureType: row.subStructureType,
            itemIds: itemIds,
        }, async () => {
            this.fillRoomTypeId(row.houseTypeId);
            this.fillDiscipline(row.roomUnit);
            this.fillStructure();
            this.fillSubStructure();
            this.fillShape();
            this.fillSubStructureType();
            this.fillItems();
            this.fillStockCardIdIn();

            if (Boolean(row.quantityDimensions) && row.quantityDimensions.length > 0) {
                row.quantityDimensions.forEach(element => {
                    if (Boolean(element.quantityUnitId)) {
                        var quantityUnit = dataList.find(p => p.key === element.quantityUnitId);
                        let quantityUnitId = [];
                        if (Boolean(quantityUnit)) {
                            quantityUnitId = quantityUnit.lineage;
                        }
                        element.quantityUnitId = quantityUnitId;
                    }
                });
            }

            if (Boolean(row.itemQuantities) && row.itemQuantities.length > 0) {
                row.itemQuantities.forEach(element => {
                    if (Boolean(element.quantityUnitId)) {
                        var quantityUnit = dataList.find(p => p.key === element.quantityUnitId);
                        let quantityUnitId = [];
                        if (Boolean(quantityUnit)) {
                            quantityUnitId = quantityUnit.lineage;
                        }
                        element.quantityUnitId = quantityUnitId;
                    }
                });
            }

            var quantityUnit = dataList.find(p => p.key === row.quantityUnitId);
            let quantityUnitId = [];
            if (Boolean(quantityUnit)) {
                quantityUnitId = quantityUnit.lineage;
            }

            var materialUnit = dataList.find(p => p.key === row.itemUnitId);
            let itemUnitId = [];
            if (Boolean(materialUnit)) {
                itemUnitId = materialUnit.lineage;
            }

            this.formRef.current.setFieldsValue({
                ...row,
                quantityUnitId: quantityUnitId,
                itemUnitId: itemUnitId
            });
        });
    };

    delete = async () => {
        const deletedItem = {
            id: this.state.id
        };
        var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

        if (response.type === "ERROR") {
            error(response);
        } else {
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.setState({
                id: null,
                modalDeleteShow: false
            });
        }
    };
    deleteModal = row => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    };
    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };
    removeSearchCriteria = index => {
        let searchCriteriaModels = this.state.searchCriteriaModels;
        searchCriteriaModels.splice(index, 1);
        this.setState(
            {
                searchCriteriaModels: searchCriteriaModels
            },
            () => {
                this.filterChangeItem(searchCriteriaModels);
            }
        );
    };
    action = record => {
        let editAction = {
            name: "Edit",
            icon: <EditOutlined fontSize="small" color="primary" />,
            actionClick: this.edit.bind(this)
        };
        let deleteAction = {
            name: "Delete",
            icon: <DeleteIcon fontSize="small" color="error" />,
            actionClick: this.deleteModal.bind(this)
        };



        let actionList = [];
        actionList.push(editAction);
        actionList.push(deleteAction);



        return (
            <React.Fragment>
                <DynamicActionMenu row={record} actionList={actionList} />
            </React.Fragment>
        );
    };
    resetInputs = () => {
        this.formRef.current.resetFields();
        this.setState({
            id: null,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
                total: this.state.totalRecords
            },
            loading: false,
            sortField: "",
            sortOrder: "",
        });
    };
    cancel = () => {
        this.setState({
            hideUpdate: true,
            hideSave: false,
            hideInputs: true
        });
        this.resetInputs();
    };
    onClickCreateNew = () => {
        this.resetInputs();
        if (this.state.hideInputs) {
            this.setState({
                hideInputs: false,
                hideSave: false,
                hideUpdate: true
            });
        } else {
            this.setState({
                hideInputs: true,
                hideSave: true,
                hideUpdate: false
            });
        }
    };

    save = async values => {
        const newItem = {
            ...values,
            id: this.state.id,
        };
        newItem.itemUnitId = Boolean(values.itemUnitId) && values.itemUnitId.length > 0 ? values.itemUnitId[values.itemUnitId.length - 1] : null;
        newItem.quantityUnitId = Boolean(values.quantityUnitId) && values.quantityUnitId.length > 0 ? values.quantityUnitId[values.quantityUnitId.length - 1] : null;

        if (values.quantityDimensions && values.quantityDimensions.length > 0) {
            values.quantityDimensions.forEach(element => {
                element.quantityUnitId = Boolean(element.quantityUnitId) && element.quantityUnitId.length > 0 ? element.quantityUnitId[element.quantityUnitId.length - 1] : null;
            });
        }

        if (values.itemQuantities && values.itemQuantities.length > 0) {
            values.itemQuantities.forEach(element => {
                element.quantityUnitId = Boolean(element.quantityUnitId) && element.quantityUnitId.length > 0 ? element.quantityUnitId[element.quantityUnitId.length - 1] : null;
            });
        }

        if (Boolean(this.props.houseTypeId) && this.props.houseTypeId > 0) {
            newItem.houseTypeId = this.props.houseTypeId;
        }

        if (Boolean(this.props.roomTypeId) && this.props.roomTypeId > 0) {
            newItem.roomTypeId = this.props.roomTypeId;
        }

        if (newItem.id === null) {
            var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
        } else {
            response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
        }

        if (response.type === "ERROR") {
            error(response);
        } else {
            this.cancel();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();

            if (Boolean(this.props) && Boolean(this.props.roomQuantityModalClose)) {
                this.props.roomQuantityModalClose();
            }
        }
    };

    handleExportExcel = async () => {
        this.setState({
            exportClick: true
        });
    };
    handleUploadExcel = async excelJson => {
        try {
            var list = [];
            if (excelJson === null) showError("Select an excel file");
            else {
                excelJson.forEach(element => {
                    const obj = {
                    };
                    list.push(obj);
                });
                if (Boolean(list) && list.length > 0) {
                    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
                    if (response.type === "ERROR") {
                        showError("No records to be added");
                    } else {
                        this.resetInputs();
                        const { pagination } = this.state;
                        this.restartTable({ pagination });
                        showSuccess();
                    }
                } else {
                    showError("No records to be added");
                }
            }
        } catch (error) {
            showError("");
        }
    };

    filterChangeItem = searchCriteriaModels => {
        this.setState(
            {
                searchCriteriaModels: searchCriteriaModels,
                pagination: {
                    ...this.state.pagination,
                    current: 1
                }
            },
            () => {
                const { pagination } = this.state;
                this.restartTable({ pagination });
            }
        );
    };

    dimensionModal = async row => {
        this.setState({
            modalDimensionShow: true,
            modalDimensionList: row.quantityDimensions
        });
    };

    itemQuantityModal = async row => {
        this.setState({
            modalItemQuantityShow: true,
            modalItemQuantityList: row.itemQuantities
        });
    };

    usedBeforeModal = async row => {
        let modalUsedBeforeList = [];
        modalUsedBeforeList.push({ "usedRoomTypeName": row.usedRoomTypeName, "usedRoomUnit": row.usedRoomUnit });

        this.setState({
            modalUsedBeforeShow: true,
            modalUsedBeforeList: modalUsedBeforeList
        });
    };

    fillQuantityDimensions = async () => {
        const { shapeId, id } = this.state;
        let quantityDimensions = [];
        let roomApplicationMaterials = [];
        if (Boolean(shapeId) && shapeId > 0) {
            let roomQuantityId = Boolean(id) && id > 0 ? id : -1;
            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/dimensions/" + shapeId + "/" + roomQuantityId);
            if (response.type === "ERROR") {
                error(response);
            } else {
                quantityDimensions = Boolean(response.data) ? response.data : []
            }
        }
        this.formRef.current.setFieldsValue({
            quantityDimensions: quantityDimensions
        });
        this.setState({
            roomApplicationMaterials: roomApplicationMaterials,
        });
    }

    fillMaterialListEdit(value) {
        let usedBeforeVisible = false;
        let roomApplicationMaterials = [];
        if (Boolean(value) && value > 0) {
            let application = this.state["applicationIdSelectItems"].find(p => p.roomApplicationId === value);
            if (Boolean(application)) {
                if (application.application === "Main Structure") {
                    usedBeforeVisible = true;
                }
                roomApplicationMaterials = application.roomApplicationMaterials;
            }
        }
        this.setState({
            roomApplicationId: value,
            usedBeforeVisible: usedBeforeVisible,
            roomApplicationMaterials: roomApplicationMaterials,
        });
    }

    render() {
        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 }
        };

        const columns = [
            {
                key: "key",
                render: this.action
            },
            {
                title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
                render: (value, row, index) => {
                    return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
                }
            },
            {
                title: <FormattedMessage id="RoomQuantity.HouseType" defaultMessage="House Type" />,
                key: "houseTypeId",
                render: record => {
                    return record.houseTypeName;
                }, ...getColumnFilter({
                    inputName: "houseTypeId", inputType: "SELECT",
                    selectItems: Boolean(this.state["Apartment.TypeOfHousesSelectItems"]) && this.state["Apartment.TypeOfHousesSelectItems"].length > 0 ? this.state["Apartment.TypeOfHousesSelectItems"] : [],
                    filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
                }), sorter: true
            },
            {
                title: <FormattedMessage id="RoomQuantity.Room" defaultMessage="Room" />,
                key: "roomTypeId",
                render: record => {
                    return record.roomTypeName;
                }, ...getColumnFilter({
                    inputName: "roomTypeId", inputType: "SELECT",
                    selectItems: Boolean(this.state["roomTypeIdSelectItems"]) && this.state["roomTypeIdSelectItems"].length > 0 ? this.state["roomTypeIdSelectItems"] : [],
                    filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
                }), sorter: true
            },
            {
                title: <FormattedMessage id="RoomQuantity.RoomUnit" defaultMessage="Room Unit" />,
                key: "roomUnit",
                render: record => {
                    return record.roomUnit;
                }, ...getColumnFilter({
                    inputName: "roomUnit", inputType: "SELECT",
                    selectItems: Boolean(this.state["roomUnitSelectItems"]) && this.state["roomUnitSelectItems"].length > 0 ? this.state["roomUnitSelectItems"] : [],
                    filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
                }), sorter: true
            },
            {
                title: <FormattedMessage id="RoomQuantity.Discipline" defaultMessage="Discipline" />,
                key: "discipline",
                render: record => {
                    return record.discipline;
                }, ...getColumnFilter({
                    inputName: "discipline", inputType: "SELECT",
                    selectItems: Boolean(this.state["disciplineIdSelectItems"]) && this.state["disciplineIdSelectItems"].length > 0 ? this.state["disciplineIdSelectItems"] : [],
                    filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
                }), sorter: true
            },
            {
                title: <FormattedMessage id="RoomQuantity.Structure" defaultMessage="Structure" />,
                key: "structure",
                render: record => {
                    return record.structure;
                }, ...getColumnFilter({
                    inputName: "structure", inputType: "SELECT",
                    selectItems: Boolean(this.state["structureSelectItems"]) && this.state["structureSelectItems"].length > 0 ? this.state["structureSelectItems"] : [],
                    filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
                }), sorter: true
            },
            {
                title: <FormattedMessage id="RoomQuantity.SubStructure" defaultMessage="Sub-structure" />,
                key: "subStructure",
                render: record => {
                    return record.subStructure;
                }, ...getColumnFilter({
                    inputName: "subStructure", inputType: "SELECT",
                    selectItems: Boolean(this.state["subStructureSelectItems"]) && this.state["subStructureSelectItems"].length > 0 ? this.state["subStructureSelectItems"] : [],
                    filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
                }), sorter: true
            },
            {
                title: <FormattedMessage id="RoomQuantity.Shape" defaultMessage="Shape" />,
                key: "shapeId",
                render: record => {
                    return record.shapeName;
                }, ...getColumnFilter({
                    inputName: "shapeId", inputType: "SELECT",
                    selectItems: Boolean(this.state["shapeIdSelectItems"]) && this.state["shapeIdSelectItems"].length > 0 ? this.state["shapeIdSelectItems"] : [],
                    filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
                }), sorter: true
            },
            {
                title: <FormattedMessage id="RoomQuantity.SubStructureType" defaultMessage="Sub-structure Type" />,
                key: "subStructureType",
                ellipsis: true,
                render: record => {
                    return record.subStructureType;
                }, ...getColumnFilter({
                    inputName: "subStructureType", inputType: "SELECT",
                    selectItems: Boolean(this.state["subStructureTypeSelectItems"]) && this.state["subStructureTypeSelectItems"].length > 0 ? this.state["subStructureTypeSelectItems"] : [],
                    filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
                }), sorter: true
            },
            {
                title: <FormattedMessage id="RoomQuantity.Item" defaultMessage="Item" />,
                key: "id",
                render: record => {
                    return (record.itemQuantities) && record.itemQuantities.length > 0 ? (
                        <AntButton
                            onClick={() => this.itemQuantityModal(record)}
                            style={{ border: "0px", color: "#1BC5BD" }}
                            icon={<ZoomInOutlined fontSize="large" />}
                        ></AntButton>
                    ) : (
                        ""
                    )
                }
            },
            {
                title: <FormattedMessage id="RoomQuantity.Dimensions" defaultMessage="Dimensions" />,
                key: "id",
                render: record => {
                    return (record.quantityDimensions) && record.quantityDimensions.length > 0 ? (
                        <AntButton
                            onClick={() => this.dimensionModal(record)}
                            style={{ border: "0px", color: "#1BC5BD" }}
                            icon={<ZoomInOutlined fontSize="large" />}
                        ></AntButton>
                    ) : (
                        ""
                    )
                }
            },
            {
                title: <FormattedMessage id="RoomQuantity.UsedBefore" defaultMessage="Used Before" />,
                key: "usedBefore",
                render: record => {
                    return (
                        record.usedBefore ?
                            <AntButton
                                type="default" style={{ color: "white", background: "#1BC5BD", borderColor: "#1BC5BD" }}
                                onClick={() => this.usedBeforeModal(record)}
                                icon={<CheckOutlined />}
                            ></AntButton> : <AntButton
                                type="default" style={{ color: "white", background: "#c51b23", borderColor: "#c51b23" }}
                                icon={<CloseOutlined color="secondary" />}
                            ></AntButton>
                    )
                },
            },
        ];

        const columnsDimensionList = [
            {
                title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
                render: (value, row, index) => {
                    return index + 1;
                }
            },
            {
                title: <FormattedMessage id="RoomQuantity.Dimension" defaultMessage="Dimension" />,
                key: "id",
                render: record => {
                    return record.dimensionName;
                },
            },
            {
                title: <FormattedMessage id="RoomQuantity.Quantity" defaultMessage="Quantity" />,
                key: "id",
                render: record => {
                    return record.quantityArea;
                },
            },
            {
                title: <FormattedMessage id="RoomQuantity.Unit" defaultMessage="Unit" />,
                key: "id",
                render: record => {
                    return record.quantityUnitName;
                },
            },
        ];

        const columnsItemQuantityList = [
            {
                title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
                render: (value, row, index) => {
                    return index + 1;
                }
            },
            {
                title: <FormattedMessage id="RoomQuantity.Item" defaultMessage="Item" />,
                key: "id",
                render: record => {
                    return record.itemName;
                },
            },
            {
                title: <FormattedMessage id="RoomQuantity.StockCard" defaultMessage="Stock Card" />,
                key: "id",
                render: record => {
                    return record.eldenStockCardName;
                },
            },
            {
                title: <FormattedMessage id="RoomQuantity.Quantity" defaultMessage="Quantity" />,
                key: "id",
                render: record => {
                    return record.quantityArea;
                },
            },
            {
                title: <FormattedMessage id="RoomQuantity.Unit" defaultMessage="Unit" />,
                key: "id",
                render: record => {
                    return record.quantityUnitName;
                },
            },
        ];

        const columnsUsedBeforeList = [
            {
                title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
                render: (value, row, index) => {
                    return index + 1;
                }
            },
            {
                title: <FormattedMessage id="RoomQuantity.Room" defaultMessage="Room" />,
                key: "id",
                render: record => {
                    return record.usedRoomTypeName;
                },
            },
            {
                title: <FormattedMessage id="RoomQuantity.RoomUnit" defaultMessage="Room Unit" />,
                key: "id",
                render: record => {
                    return record.usedRoomUnit;
                },
            },
        ];

        const excelExport = (<>
            {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}         {this.state.exportClick ? (<ExcelFile filename="RoomQuantity" hideElement={true}>             <ExcelSheet name="RoomQuantity" data={this.state.tableList}>               <ExcelColumn label="unit" value="unit" />
            <ExcelColumn label="period" value="period" />
            <ExcelColumn label="material" value="material" />
            <ExcelColumn label="personnel" value="personnel" />
            <ExcelColumn label="equipment" value="equipment" />
        </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} 
        icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)}    */}
        </>);
        return (
            <Badge.Ribbon text={<FormattedMessage id="roomQuantity.pageTitle" defaultMessage="Room Quantity" />} placement="start" color="volcano" style={{ fontSize: 12 }}>
                <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
                    <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
                        {!(Boolean(this.props.houseTypeId) && this.props.houseTypeId > 0) &&
                            <Row gutter={[16, 16]}>
                                <Col md={21}>
                                </Col>
                                <Col md={1}>{excelExport}
                                </Col>
                                <Col md={1}>
                                    <ExcelReader
                                        onCreateJson={this.handleUploadExcel.bind(this)}>
                                    </ExcelReader>
                                </Col>
                                <Col md={1}>
                                    <AntButton
                                        style={{ border: "0px" }}
                                        hidden={!this.state.hideInputs}
                                        onClick={this.onClickCreateNew}
                                        icon={<CreateIcon color="secondary"
                                            fontSize="large" />}
                                    ></AntButton>
                                    <AntButton style={{ border: "0px" }}
                                        hidden={this.state.hideInputs}
                                        onClick={this.onClickCreateNew}
                                        icon={<RemoveCircle color="error"
                                            fontSize="large" />}
                                    ></AntButton>
                                </Col>
                            </Row>
                        }

                        <div hidden={this.state.hideInputs}>
                            {!(Boolean(this.props.houseTypeId) && this.props.houseTypeId > 0) ?
                                <Form.Item
                                    {...layout}
                                    name="houseTypeId"
                                    label={<FormattedMessage id="RoomQuantity.HouseType" defaultMessage="House Type" />}
                                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                >
                                    <Select showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        style={{ width: "100%" }}
                                        onChange={value => {
                                            this.fillRoomTypeId(value);
                                        }}
                                    >
                                        {Boolean(this.state["Apartment.TypeOfHousesSelectItems"]) && this.state["Apartment.TypeOfHousesSelectItems"].length > 0 &&
                                            this.state["Apartment.TypeOfHousesSelectItems"].map(i => (
                                                <Option key={i.id} value={i.id}>
                                                    {i.name}
                                                </Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                                :
                                <Form.Item
                                    {...layout}
                                    name="houseTypeName"
                                    label={<FormattedMessage id="RoomQuantity.HouseType" defaultMessage="House Type" />}
                                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                >
                                    <Input readOnly />
                                </Form.Item>
                            }

                            {!(Boolean(this.props.houseTypeId) && this.props.houseTypeId > 0) ?
                                <Form.Item
                                    {...layout}
                                    name="roomTypeId"
                                    label={<FormattedMessage id="RoomQuantity.Room" defaultMessage="Room" />}
                                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        style={{ width: "100%" }}
                                    >
                                        {Boolean(this.state["roomTypeIdSelectItems"]) && this.state["roomTypeIdSelectItems"].length > 0 &&
                                            this.state["roomTypeIdSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                </Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                                :
                                <Form.Item
                                    {...layout}
                                    name="roomTypeName"
                                    label={<FormattedMessage id="RoomQuantity.Room" defaultMessage="Room" />}
                                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                >
                                    <Input readOnly />
                                </Form.Item>
                            }

                            {
                                <Form.Item
                                    {...layout}
                                    name="roomUnit"
                                    label={<FormattedMessage id="RoomQuantity.RoomUnit" defaultMessage="Room Unit" />}
                                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        style={{ width: "100%" }}
                                        onChange={value => {
                                            this.setState({
                                                roomUnit: value,
                                            }, () => {
                                                this.fillDiscipline(value);
                                                this.fillStructure();
                                            });
                                        }}
                                    >
                                        {Boolean(this.state["roomUnitSelectItems"]) && this.state["roomUnitSelectItems"].length > 0 &&
                                            this.state["roomUnitSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                </Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            }

                            <Form.Item
                                {...layout}
                                name="discipline"
                                label={<FormattedMessage id="RoomQuantity.discipline" defaultMessage="Discipline" />}
                                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    style={{ width: "100%" }}
                                    onChange={value => {
                                        this.setState({
                                            discipline: value
                                        }, () => {
                                            this.fillStructure();
                                        });
                                    }}
                                >
                                    {Boolean(this.state["disciplineIdSelectItems"]) && this.state["disciplineIdSelectItems"].length > 0 &&
                                        this.state["disciplineIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                                {i.value}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>

                            {
                                <Form.Item {...layout}
                                    label={<FormattedMessage id="RoomQuantity.Structure" defaultMessage="Structure" />}
                                >
                                    <Row gutter={5}>
                                        <Col span={10}>
                                            <Form.Item
                                                name="structure"
                                                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    optionFilterProp="children"
                                                    style={{ width: "100%" }}
                                                    onChange={value => {
                                                        this.setState({
                                                            structure: value,
                                                        }, () => {
                                                            this.fillSubStructure();
                                                        });
                                                    }}
                                                >
                                                    {Boolean(this.state["structureSelectItems"]) && this.state["structureSelectItems"].length > 0 &&
                                                        this.state["structureSelectItems"].map(i => (
                                                            <Option key={i.key} value={i.key}>
                                                                {i.value}
                                                            </Option>
                                                        ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}></Col>
                                        <Col span={10}>
                                            {Boolean(this.state.structure) && this.state.structure === "Main Structure" &&
                                                <Form.Item
                                                    name="usedBefore"
                                                    rules={[{ required: false }]}
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox onClick={(e) => {
                                                        this.setState({
                                                            usedBefore: e.target.checked
                                                        });
                                                    }}>
                                                        <FormattedMessage id="RoomQuantity.UsedBefore" defaultMessage="Used Before" />
                                                    </Checkbox>
                                                </Form.Item>}
                                        </Col>

                                    </Row>
                                </Form.Item>
                            }

                            {this.state.usedBefore &&
                                <>
                                    {
                                        <Form.Item
                                            {...layout}
                                            name="usedRoomTypeId"
                                            label={<FormattedMessage id="RoomQuantity.UsedRoom" defaultMessage="Used Room" />}
                                            rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                optionFilterProp="children"
                                                style={{ width: "100%" }}
                                            >
                                                {Boolean(this.state["roomTypeIdSelectItems"]) && this.state["roomTypeIdSelectItems"].length > 0 &&
                                                    this.state["roomTypeIdSelectItems"].map(i => (
                                                        <Option key={i.key} value={i.key}>
                                                            {i.value}
                                                        </Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    }

                                    {
                                        <Form.Item
                                            {...layout}
                                            name="usedRoomUnit"
                                            label={<FormattedMessage id="RoomQuantity.UsedRoomUnit" defaultMessage="Used Room Unit" />}
                                            rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                optionFilterProp="children"
                                                style={{ width: "100%" }}
                                            >
                                                {Boolean(this.state["roomUnitSelectItems"]) && this.state["roomUnitSelectItems"].length > 0 &&
                                                    this.state["roomUnitSelectItems"].map(i => (
                                                        <Option key={i.key} value={i.key}>
                                                            {i.value}
                                                        </Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    }
                                </>
                            }
                            {
                                <Form.Item
                                    {...layout}
                                    name="subStructure"
                                    label={<FormattedMessage id="RoomQuantity.SubStructure" defaultMessage="Sub-structure" />}
                                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        style={{ width: "100%" }}
                                        onChange={value => {
                                            this.setState({
                                                subStructure: value,
                                            }, () => {
                                                this.fillShape();
                                            });
                                        }}
                                    >
                                        {Boolean(this.state["subStructureSelectItems"]) && this.state["subStructureSelectItems"].length > 0 &&
                                            this.state["subStructureSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                </Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            }
                            {
                                <Form.Item
                                    {...layout}
                                    name="shapeId"
                                    label={<FormattedMessage id="RoomQuantity.Shape" defaultMessage="Shape" />}
                                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        style={{ width: "100%" }}
                                        onChange={value => {
                                            this.setState({
                                                shapeId: value,
                                            }, () => {
                                                this.fillSubStructureType();
                                                this.fillQuantityDimensions();
                                            });
                                        }}
                                    >
                                        {Boolean(this.state["shapeIdSelectItems"]) && this.state["shapeIdSelectItems"].length > 0 &&
                                            this.state["shapeIdSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                </Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            }
                            {
                                <Form.Item
                                    {...layout}
                                    name="subStructureType"
                                    label={<FormattedMessage id="RoomQuantity.SubStructureType" defaultMessage="Sub-structure Type" />}
                                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        style={{ width: "100%" }}
                                        onChange={value => {
                                            this.setState({
                                                subStructureType: value,
                                            }, () => {
                                                this.fillItems();
                                            });
                                        }}
                                    >
                                        {Boolean(this.state["subStructureTypeSelectItems"]) && this.state["subStructureTypeSelectItems"].length > 0 &&
                                            this.state["subStructureTypeSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                </Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            }

                            <Form.List name="quantityDimensions">
                                {(fields, { add, remove }) => {
                                    return (
                                        <>
                                            <TableBoot bordered size="sm">
                                                <thead>
                                                    <tr>
                                                        <th colSpan="1" style={{ textAlign: "center" }}>
                                                            <FormattedMessage id="RoomQuantity.Dimension" defaultMessage="Dimension" />
                                                        </th>
                                                        <th colSpan="1" style={{ textAlign: "center" }}>
                                                            <FormattedMessage id="RoomQuantity.Value" defaultMessage="Value" />
                                                        </th>
                                                        <th colSpan="1" style={{ textAlign: "center" }}>
                                                            <FormattedMessage id="RoomQuantity.Unit" defaultMessage="Unit" />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fields.map(field => {
                                                        return (
                                                            <tr key={field.key}>
                                                                <td>
                                                                    <Form.Item
                                                                        noStyle name={[field.name, "dimensionName"]}>
                                                                        <Input readOnly />
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item
                                                                        noStyle name={[field.name, "quantityArea"]}>
                                                                        <InputNumber style={{ width: "100%" }}
                                                                            parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} className="w-100" placeholder="    " min={0} />
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item
                                                                        noStyle name={[field.name, "quantityUnitId"]}>
                                                                        <Cascader
                                                                            showSearch
                                                                            allowClear
                                                                            optionFilterProp="children"
                                                                            style={{ width: "100%" }} options={this.state.unitSelectItems} />
                                                                    </Form.Item>
                                                                </td>

                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </TableBoot>
                                        </>
                                    );
                                }}
                            </Form.List>

                            <Form.List name="itemQuantities">
                                {(fields, { add, remove }) => {

                                    const addNewStockCard = () => {
                                        this.setState({
                                            modalStockCardShow: true,
                                        });
                                    };

                                    return (
                                        <>
                                            <TableBoot bordered size="sm">
                                                <thead>
                                                    <tr>
                                                        <th colSpan="1" style={{ textAlign: "center" }}>
                                                            <FormattedMessage id="RoomQuantity.Item" defaultMessage="Item" />
                                                        </th>
                                                        <th colSpan="1" style={{ textAlign: "center" }}>
                                                            <FormattedMessage id="RoomQuantity.StockCard" defaultMessage="Stock Card" />
                                                        </th>
                                                        <th colSpan="2" style={{ textAlign: "center" }}>
                                                            <FormattedMessage id="RoomQuantity.Unit" defaultMessage="Quantity" />
                                                        </th>
                                                        <th colSpan="1" style={{ textAlign: "center" }}>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fields.map(field => {
                                                        return (
                                                            <tr key={field.key}>
                                                                <td>
                                                                    <Form.Item noStyle name={[field.name, "itemId"]}>
                                                                        <Select
                                                                            showSearch
                                                                            allowClear
                                                                            optionFilterProp="children"
                                                                            style={{ width: "300px" }}
                                                                            onChange={value => {
                                                                                this.setState({
                                                                                    itemId: value,
                                                                                }, () => {
                                                                                    this.fillStockCardId();
                                                                                });
                                                                            }}
                                                                        >
                                                                            {Boolean(this.state["roomApplicationItems"]) && this.state["roomApplicationItems"].length > 0 &&
                                                                                this.state["roomApplicationItems"].map(i => (
                                                                                    <Option key={i.id} value={i.id}>
                                                                                        {i.material}
                                                                                    </Option>
                                                                                ))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item noStyle name={[field.name, "eldenStockCardId"]}>
                                                                        <Select
                                                                            showSearch
                                                                            allowClear
                                                                            optionFilterProp="children"
                                                                            style={{ width: "300px" }}
                                                                            onChange={value => {
                                                                                this.setState({
                                                                                    eldenStockCardId: value,
                                                                                });
                                                                            }}
                                                                        >
                                                                            {Boolean(this.state["eldenStockCardIdSelectItems"]) && this.state["eldenStockCardIdSelectItems"].length > 0 &&
                                                                                this.state["eldenStockCardIdSelectItems"].map(i => (
                                                                                    <Option key={i.key} value={i.key}>
                                                                                        {i.value}
                                                                                    </Option>
                                                                                ))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item
                                                                        noStyle name={[field.name, "quantityArea"]}>
                                                                        <InputNumber style={{ width: "100%" }}
                                                                            parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} className="w-100" placeholder="    " min={0} />
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item
                                                                        noStyle name={[field.name, "quantityUnitId"]}>
                                                                        <Cascader
                                                                            showSearch
                                                                            allowClear
                                                                            optionFilterProp="children"
                                                                            style={{ width: "200px" }} options={this.state.unitSelectItems} />
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Space>
                                                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                                                        <AntButton type="dashed" onClick={() => addNewStockCard()} block icon={<PlusOutlined />}>
                                                                            <FormattedMessage id={"RoomQuantity.AddNewCard"} defaultMessage={"Add New Card"} />
                                                                        </AntButton>
                                                                    </Space>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    <tr>
                                                        <td colSpan={11}>
                                                            <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                <FormattedMessage id={"RoomQuantity.AddNew"} defaultMessage={"Add New"} />
                                                            </AntButton>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </TableBoot>
                                        </>
                                    );
                                }}
                            </Form.List>

                            <div hidden={this.state.hideSave}>
                                {<Row gutter={[16, 16]}>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                        <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                                            <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                                        </Button>
                                    </Col>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                </Row>
                                }
                            </div>
                            <div hidden={this.state.hideUpdate}>
                                {<Row gutter={[16, 16]}>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                                        <Button id="CancelButton" style={{ width: "100%" }}
                                            variant="secondary"
                                            onClick={this.cancel}
                                        >
                                            <FormattedMessage
                                                id="GeneralButtonCancel"
                                                defaultMessage="Cancel" />
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                                        <Button id="UpdateButton" style={{ width: "100%" }}
                                            variant="warning" type="submit">
                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                                        </Button>
                                    </Col>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                </Row>}
                            </div>

                        </div>

                    </Form>
                    {
                        Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 &&
                        (<div className="alert alert-custom alert-light">                 <div className="alert-icon">
                            <FilterOutlined />
                        </div>
                            <div className="alert-text">
                                {this.state.searchCriteriaModels.map((val, i) => {
                                    return (
                                        <div key={i}
                                            className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                                            <span className="mr-2">
                                                {val.label}
                                            </span>
                                            <strong className="mr-2">
                                                {val.type}
                                            </strong>
                                            <span className="mr-2">
                                                {val.operationValue}
                                            </span>
                                            <span className="mr-2">
                                                {val.showValue}
                                            </span>
                                            <span>
                                                <Tooltip title="Remove">

                                                    <CloseCircleOutlined style={{ color: "crimson" }}
                                                        onClick={() => this.removeSearchCriteria(i)} />
                                                </Tooltip>
                                            </span>
                                        </div>);
                                })}
                            </div>
                            <div className="alert-close">
                                <Tooltip title="Remove All">
                                    <CloseCircleOutlined
                                        style={{ color: "crimson" }}
                                        onClick={() => this.setState({
                                            searchCriteriaModels: []
                                        }, () => {
                                            this.filterChangeItem([]);
                                        })} />
                                </Tooltip>
                            </div>
                        </div>)
                    }


                    {!(Boolean(this.props.houseTypeId) && this.props.houseTypeId > 0) &&
                        <div style={{
                            marginTop: "2rem",
                            marginBottom: "1rem"
                        }} className="content-section implementation"
                        >
                            <Table
                                bordered scroll={{
                                    x: 900, scrollToFirstRowOnChange: true
                                }} columns={columns}
                                dataSource={this.state.tableList}
                                loading={this.state.loading}
                                onChange={this.handleTableChange}
                                pagination={this.state.pagination} />
                        </div>
                    }

                    <AntModal
                        title={<FormattedMessage id="RoomQuantity.Dimension" defaultMessage="Dimension" />}
                        closable="false"
                        open={this.state.modalDimensionShow}
                        onCancel={() => {
                            this.setState({ modalDimensionShow: false });
                        }}
                        width={1000}
                        centered
                        footer={[
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    this.setState({ modalDimensionShow: false });
                                }}
                            >
                                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                            </Button>
                        ]}
                    >
                        <div className="card card-custom" style={{ minHeight: "100%" }}>
                            <Table
                                bordered
                                size="small"
                                columns={columnsDimensionList}
                                dataSource={this.state.modalDimensionList}
                                loading={this.state.loading}
                                pagination={false}
                            />
                        </div>
                    </AntModal>

                    <AntModal
                        title={<FormattedMessage id="RoomQuantity.Item" defaultMessage="Item" />}
                        closable="false"
                        open={this.state.modalItemQuantityShow}
                        onCancel={() => {
                            this.setState({ modalItemQuantityShow: false });
                        }}
                        width={1200}
                        centered
                        footer={[
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    this.setState({ modalItemQuantityShow: false });
                                }}
                            >
                                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                            </Button>
                        ]}
                    >
                        <div className="card card-custom" style={{ minHeight: "100%" }}>
                            <Table
                                bordered
                                size="small"
                                columns={columnsItemQuantityList}
                                dataSource={this.state.modalItemQuantityList}
                                loading={this.state.loading}
                                pagination={false}
                            />
                        </div>
                    </AntModal>


                    <AntModal
                        title={<FormattedMessage id="RoomQuantity.UsedBefore" defaultMessage="Used Before" />}
                        closable="false"
                        open={this.state.modalUsedBeforeShow}
                        onCancel={() => {
                            this.setState({ modalUsedBeforeShow: false });
                        }}
                        width={1000}
                        centered
                        footer={[
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    this.setState({ modalUsedBeforeShow: false });
                                }}
                            >
                                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                            </Button>
                        ]}
                    >
                        <div className="card card-custom" style={{ minHeight: "100%" }}>
                            <Table
                                bordered
                                size="small"
                                columns={columnsUsedBeforeList}
                                dataSource={this.state.modalUsedBeforeList}
                                loading={this.state.loading}
                                pagination={false}
                            />
                        </div>
                    </AntModal>

                    <AntModal
                        title={""}
                        closable="false"
                        open={this.state.modalStockCardShow}
                        onCancel={() => {
                            this.setState({ modalStockCardShow: false });
                        }}
                        width={1400}
                        centered
                        footer={[
                            <Space>
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        this.setState({ modalStockCardShow: false });
                                    }}
                                >
                                    <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                                </Button>
                            </Space>
                        ]}
                    >
                        {/* <StockCard /> */}
                    </AntModal>


                    <Modal show={this.state.modalDeleteShow}
                        onHide={this.showOrHideDeleteModal}
                        aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                            </Button>             <Button variant="danger" onClick={this.delete}>
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div >
            </Badge.Ribbon >
        );
    }
}

const mapStateToProps = () => {
    return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(RoomQuantity);
