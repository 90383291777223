import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import {
  Form,
  Table,
  Input,
  Select,
  InputNumber,
  Checkbox,
  Divider,
  DatePicker,
  Button as AntButton,
  Col,
  Row,
  Typography,
  Modal as AntModal
} from "antd";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import { PlusOutlined, MinusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { AlignCenterOutlined } from "@ant-design/icons";
import { Table as TableBoot } from "react-bootstrap";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;

export class LaborLaw extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      contractTypeSelectItems: null,
      organizationTypeSelectItems: null,
      workTypeIdSelectItems: null,
      contractDurationSelectItems: null,
      probationPeriodTypeSelectItems: null,
      extensionProbationPeriodTypeSelectItems: null,
      additionalDaysSelectItems: null,
      offDayListSelectItems: null,
      currencySelectItems: null,
      overtimeCriteriasSelectItems: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responsecontractType = await handleRequest("GET", "/api/eldenLaborLaw/contractTypes");
    if (responsecontractType.type === "ERROR") {
      error(responsecontractType);
    } else {
      this.setState({
        contractTypeSelectItems: Boolean(responsecontractType.data) ? responsecontractType.data : []
      });
    }
    var responseorganizationType = await handleRequest("GET", "/api/eldenLaborLaw/organizationTypes");
    if (responseorganizationType.type === "ERROR") {
      error(responseorganizationType);
    } else {
      this.setState({
        organizationTypeSelectItems: Boolean(responseorganizationType.data) ? responseorganizationType.data : []
      });
    }
    var responseworkTypeId = await handleRequest("GET", "/api/codes/type/workTypeId");
    if (responseworkTypeId.type === "ERROR") {
      error(responseworkTypeId);
    } else {
      this.setState({
        workTypeIdSelectItems: Boolean(responseworkTypeId.data) ? responseworkTypeId.data : []
      });
    }
    var responsecontractDuration = await handleRequest("GET", "/api/eldenLaborLaw/durations");
    if (responsecontractDuration.type === "ERROR") {
      error(responsecontractDuration);
    } else {
      this.setState({
        contractDurationSelectItems: Boolean(responsecontractDuration.data) ? responsecontractDuration.data : [],
        vacationDaysDurationSelectItems: Boolean(responsecontractDuration.data) ? responsecontractDuration.data : []
      });
    }
    var responseprobationPeriodType = await handleRequest("GET", "/api/eldenLaborLaw/periodTypes");
    if (responseprobationPeriodType.type === "ERROR") {
      error(responseprobationPeriodType);
    } else {
      this.setState({
        probationPeriodTypeSelectItems: Boolean(responseprobationPeriodType.data) ? responseprobationPeriodType.data : [],
        extensionProbationPeriodTypeSelectItems: Boolean(responseprobationPeriodType.data) ? responseprobationPeriodType.data : [],
        wagePeriodTypeSelectItems: Boolean(responseprobationPeriodType.data) ? responseprobationPeriodType.data : []
      });
    }

    var responseAdditionalDaysSelectItems = await handleRequest("GET", "/api/eldenLaborLaw/additionalDays");
    if (responseAdditionalDaysSelectItems.type === "ERROR") {
      error(responseAdditionalDaysSelectItems);
    } else {
      this.setState({
        additionalDaysSelectItems: Boolean(responseAdditionalDaysSelectItems.data) ? responseAdditionalDaysSelectItems.data : []
      });
    }

    var responseoffDayList = await handleRequest("GET", "/api/eldenLaborLaw/dayOfWeek");
    if (responseoffDayList.type === "ERROR") {
      error(responseoffDayList);
    } else {
      this.setState({
        offDayListSelectItems: Boolean(responseoffDayList.data) ? responseoffDayList.data : []
      });
    }
    var responsecurrency = await handleRequest("GET", "/api/eldenLaborLaw/currency");
    if (responsecurrency.type === "ERROR") {
      error(responsecurrency);
    } else {
      this.setState({
        currencySelectItems: Boolean(responsecurrency.data) ? responsecurrency.data : []
      });
    }
    var responseovertimeCriterias = await handleRequest("GET", "/api/eldenLaborLaw/overtimeCriterias");
    if (responseovertimeCriterias.type === "ERROR") {
      error(responseovertimeCriterias);
    } else {
      this.setState({
        overtimeCriteriasSelectItems: Boolean(responseovertimeCriterias.data) ? responseovertimeCriterias.data : []
      });
    }

    var responseCountries = await handleRequest("GET", "/api/countries/selectItems");
    if (responseCountries.type === "ERROR") {
      error(responseCountries);
    } else {
      this.setState({
        countryIdSelectItems: Boolean(responseCountries.data) ? responseCountries.data : []
      });
    }

    var responseDataTypeSelectItems = await handleRequest("GET", "/api/eldenLaborLaw/inputTypes");
    if (responseDataTypeSelectItems.type === "ERROR") {
      error(responseDataTypeSelectItems);
    } else {
      this.setState({
        dataTypeSelectItems: Boolean(responseDataTypeSelectItems.data) ? responseDataTypeSelectItems.data : []
      });
    }

    var responsewageTypeSelectItems = await handleRequest("GET", "/api/eldenLaborLaw/periodBases");
    if (responsewageTypeSelectItems.type === "ERROR") {
      error(responsewageTypeSelectItems);
    } else {
      this.setState({
        wageTypeSelectItems: Boolean(responsewageTypeSelectItems.data) ? responsewageTypeSelectItems.data : []
      });
    }

    var responseGreaterOrLess = await handleRequest("GET", "/api/eldenLaborLaw/greaterOrLess");
    if (responseGreaterOrLess.type === "ERROR") {
      error(responseGreaterOrLess);
    }
    this.setState({
      workingPeriodId01SelectItems: Boolean(responseGreaterOrLess.data) ? responseGreaterOrLess.data : [],
      workingPeriodId02SelectItems: Boolean(responseGreaterOrLess.data) ? responseGreaterOrLess.data : [],
      hoursId01SelectItems: Boolean(responseGreaterOrLess.data) ? responseGreaterOrLess.data : [],
      hoursId02SelectItems: Boolean(responseGreaterOrLess.data) ? responseGreaterOrLess.data : [],
      genderId01SelectItems: Boolean(responseGreaterOrLess.data) ? responseGreaterOrLess.data : [],
      genderId02SelectItems: Boolean(responseGreaterOrLess.data) ? responseGreaterOrLess.data : [],
      ageId01SelectItems: Boolean(responseGreaterOrLess.data) ? responseGreaterOrLess.data : [],
      ageId02SelectItems: Boolean(responseGreaterOrLess.data) ? responseGreaterOrLess.data : [],
      minWageId01SelectItems: Boolean(responseGreaterOrLess.data) ? responseGreaterOrLess.data : [],
      ageOfEmployeeId01SelectItems: Boolean(responseGreaterOrLess.data) ? responseGreaterOrLess.data : [],
      ageOfEmployeeId02SelectItems: Boolean(responseGreaterOrLess.data) ? responseGreaterOrLess.data : [],
      workingYearsId01SelectItems: Boolean(responseGreaterOrLess.data) ? responseGreaterOrLess.data : [],
      workingYearsId02SelectItems: Boolean(responseGreaterOrLess.data) ? responseGreaterOrLess.data : [],
      vacationDaysIdSelectItems: Boolean(responseGreaterOrLess.data) ? responseGreaterOrLess.data : []
    });

    var responseWrittenType = await handleRequest("GET", "/api/eldenLaborLaw/writtenType");
    if (responseWrittenType.type === "ERROR") {
      error(responseWrittenType);
    } else {
      this.setState({
        writtenContractIdSelectItems: Boolean(responseWrittenType.data) ? responseWrittenType.data : []
      });
    }

    var responseMonthYearType = await handleRequest("GET", "/api/eldenLaborLaw/monthYearType");
    if (responseMonthYearType.type === "ERROR") {
      error(responseMonthYearType);
    } else {
      this.setState({
        probationPeriodIdSelectItems: Boolean(responseMonthYearType.data) ? responseMonthYearType.data : [],
        extensionOfParobationIdSelectItems: Boolean(responseMonthYearType.data) ? responseMonthYearType.data : [],
        additionalDaysIdSelectItems: Boolean(responseMonthYearType.data) ? responseMonthYearType.data : []
      });
    }

    var responseDayMonthType = await handleRequest("GET", "/api/eldenLaborLaw/dayMonthType");
    if (responseDayMonthType.type === "ERROR") {
      error(responseDayMonthType);
    } else {
      this.setState({
        reminderIdSelectItems: Boolean(responseDayMonthType.data) ? responseDayMonthType.data : [],
        noticePeriodIdSelectItems: Boolean(responseDayMonthType.data) ? responseDayMonthType.data : [],
        legalOffDaysIdSelectItems: Boolean(responseDayMonthType.data) ? responseDayMonthType.data : []
      });
    }

    var responseMinuteHourDayMonthType = await handleRequest("GET", "/api/eldenLaborLaw/minuteHourDayMonthType");
    if (responseMinuteHourDayMonthType.type === "ERROR") {
      error(responseMinuteHourDayMonthType);
    } else {
      this.setState({
        normalWorkIdSelectItems: Boolean(responseMinuteHourDayMonthType.data) ? responseMinuteHourDayMonthType.data : [],
        maximumWorkIdSelectItems: Boolean(responseMinuteHourDayMonthType.data) ? responseMinuteHourDayMonthType.data : [],
        applicablePeriodLastIdSelectItems: Boolean(responseMinuteHourDayMonthType.data) ? responseMinuteHourDayMonthType.data : [],
        maximumConsecutiveIdSelectItems: Boolean(responseMinuteHourDayMonthType.data) ? responseMinuteHourDayMonthType.data : [],
        offIdSelectItems: Boolean(responseMinuteHourDayMonthType.data) ? responseMinuteHourDayMonthType.data : []
      });
    }

    var responseVacationDaysType = await handleRequest("GET", "/api/eldenLaborLaw/vacationDaysType");
    if (responseVacationDaysType.type === "ERROR") {
      error(responseVacationDaysType);
    } else {
      this.setState({
        minWageId02SelectItems: Boolean(responseVacationDaysType.data) ? responseVacationDaysType.data : []
      });
    }

    var responseVacationPeriodType = await handleRequest("GET", "/api/eldenLaborLaw/vacationPeriodType");
    if (responseVacationPeriodType.type === "ERROR") {
      error(responseVacationPeriodType);
    } else {
      this.setState({
        paymentPeriodIdSelectItems: Boolean(responseVacationPeriodType.data) ? responseVacationPeriodType.data : [],
        startOfNewVacationPeriodIdSelectItems: Boolean(responseVacationPeriodType.data) ? responseVacationPeriodType.data : []
      });
    }

    var responseCurrencyType = await handleRequest("GET", "/api/eldenLaborLaw/currencyType");
    if (responseCurrencyType.type === "ERROR") {
      error(responseCurrencyType);
    } else {
      this.setState({
        currencyIdSelectItems: Boolean(responseCurrencyType.data) ? responseCurrencyType.data : []
      });
    }

    var responsePaymentHourlyWageType = await handleRequest("GET", "/api/eldenLaborLaw/paymentHourlyWageType");
    if (responsePaymentHourlyWageType.type === "ERROR") {
      error(responsePaymentHourlyWageType);
    } else {
      this.setState({
        paymentIdSelectItems: Boolean(responsePaymentHourlyWageType.data) ? responsePaymentHourlyWageType.data : []
      });
    }

    var responseLeaveType = await handleRequest("GET", "/api/eldenLaborLaw/leaveType");
    if (responseLeaveType.type === "ERROR") {
      error(responseLeaveType);
    } else {
      this.setState({
        typeOfLeaveIdSelectItems: Boolean(responseLeaveType.data) ? responseLeaveType.data : []
      });
    }

    var responseGenderType = await handleRequest("GET", "/api/eldenLaborLaw/genderType");
    if (responseGenderType.type === "ERROR") {
      error(responseGenderType);
    } else {
      this.setState({
        genderOfEmployeeIdSelectItems: Boolean(responseGenderType.data) ? responseGenderType.data : [],
        relationId01SelectItems: Boolean(responseGenderType.data) ? responseGenderType.data : []
      });
    }

    var responseRelationEmployeeType = await handleRequest("GET", "/api/eldenLaborLaw/relationEmployeeType");
    if (responseRelationEmployeeType.type === "ERROR") {
      error(responseRelationEmployeeType);
    } else {
      this.setState({
        relationId02SelectItems: Boolean(responseRelationEmployeeType.data) ? responseRelationEmployeeType.data : []
      });
    }

    var responsePercentRatioType = await handleRequest("GET", "/api/eldenLaborLaw/percentRatioType");
    if (responsePercentRatioType.type === "ERROR") {
      error(responsePercentRatioType);
    } else {
      this.setState({
        paymentId01SelectItems: Boolean(responsePercentRatioType.data) ? responsePercentRatioType.data : []
      });
    }

    var responsePaymentOfSalaryType = await handleRequest("GET", "/api/eldenLaborLaw/paymentOfSalaryType");
    if (responsePaymentOfSalaryType.type === "ERROR") {
      error(responsePaymentOfSalaryType);
    } else {
      this.setState({
        paymentId02SelectItems: Boolean(responsePaymentOfSalaryType.data) ? responsePaymentOfSalaryType.data : []
      });
    }

    var responsePaymentForTotalType = await handleRequest("GET", "/api/eldenLaborLaw/paymentForTotalType");
    if (responsePaymentForTotalType.type === "ERROR") {
      error(responsePaymentForTotalType);
    } else {
      this.setState({
        calculationIdSelectItems: Boolean(responsePaymentForTotalType.data) ? responsePaymentForTotalType.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    let additionalDays = [];
    if (Boolean(row.additionalDays) && row.additionalDays.length > 0) {
      row.additionalDays.forEach((element, index) => {
        additionalDays.push(element.additionalDay);
      });
    }

    let offDayList = [];
    if (Boolean(row.offDayList) && row.offDayList.length > 0) {
      row.offDayList.forEach((element, index) => {
        offDayList.push(element.dayOfWeek);
      });
    }

    let overtimeCriterias = [];
    if (Boolean(row.overtimeCriterias) && row.overtimeCriterias.length > 0) {
      row.overtimeCriterias.forEach((element, index) => {
        overtimeCriterias.push(element.overtimeCriteria);
      });
    }

    let contractList = [];
    contractList.push(row);

    let contractTypeList = [];
    contractTypeList.push(row);

    let contractRenewalOfFixTermContractList = [];
    contractRenewalOfFixTermContractList.push(row);

    let contractMinimumEmployeInformationList = [];
    contractMinimumEmployeInformationList.push(row);

    let workingHoursShiftsList = [];
    workingHoursShiftsList.push(row);

    let workingHoursList = [];
    workingHoursList.push(row);

    let wageList = [];
    wageList.push(row);

    let overtimePaymentCalculationList = [];
    overtimePaymentCalculationList.push(row);

    let leaveList = [];
    leaveList.push(row);

    let annualVacationList = [];
    annualVacationList.push(row);

    let endOfServiceVoluntaryList = [];
    endOfServiceVoluntaryList.push(row);

    let endOfServiceInvoluntaryList = [];
    endOfServiceInvoluntaryList.push(row);

    row.additionalDays = additionalDays;
    row.offDayList = offDayList;
    row.overtimeCriterias = overtimeCriterias;

    this.formRef.current.setFieldsValue({
      ...row,
      effectiveDate: Boolean(row.effectiveDate) ? moment(row.effectiveDate) : null
      // contractList: contractList,
      // contractTypeList: contractTypeList,
      // contractRenewalOfFixTermContractList: contractRenewalOfFixTermContractList,
      // contractMinimumEmployeInformationList: contractMinimumEmployeInformationList,
      // workingHoursShiftsList: workingHoursShiftsList,
      // workingHoursList: workingHoursList,
      // wageList: wageList,
      // leaveList: leaveList,
      // annualVacationList: annualVacationList,
      // endOfServiceVoluntaryList: endOfServiceVoluntaryList,
      // endOfServiceInvoluntaryList: endOfServiceInvoluntaryList,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/eldenLaborLaw/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/eldenLaborLaw/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = { ...values, id: this.state.id };
    let additionalDays = [];
    if (Boolean(newItem.additionalDays) && newItem.additionalDays.length > 0) {
      newItem.additionalDays.forEach((element, index) => {
        additionalDays.push({ additionalDay: element });
      });
    }

    let offDayList = [];
    if (Boolean(newItem.offDayList) && newItem.offDayList.length > 0) {
      newItem.offDayList.forEach((element, index) => {
        offDayList.push({ dayOfWeek: element });
      });
    }

    let overtimeCriterias = [];
    if (Boolean(newItem.overtimeCriterias) && newItem.overtimeCriterias.length > 0) {
      newItem.overtimeCriterias.forEach((element, index) => {
        overtimeCriterias.push({ overtimeCriteria: element });
      });
    }

    newItem.additionalDays = additionalDays;
    newItem.offDayList = offDayList;
    newItem.overtimeCriterias = overtimeCriterias;

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/eldenLaborLaw", newItem);
    } else {
      response = await handleRequest("PUT", "/api/eldenLaborLaw/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/eldenLaborLaw/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="EldenLaborLaw.nameOfLaborLaw" defaultMessage="Name Of LaborLaw" />,
        key: "nameOfLaborLaw",
        render: record => {
          return record.nameOfLaborLaw;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.nameOfLaborLaw) ? this.state.pagination.nameOfLaborLaw : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  nameOfLaborLaw: e.target.value,
                  nameOfLaborLawColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.nameOfLaborLawColor) ? this.state.pagination.nameOfLaborLawColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="EldenLaborLaw.effectiveDate" defaultMessage="Effective Date" />,
        key: "effectiveDate",
        render: record => {
          if (Boolean(record.effectiveDate)) return moment(record.effectiveDate).format("DD/MM/YYYY");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.effectiveDate) ? this.state.pagination.effectiveDate : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  effectiveDate: e.target.value,
                  effectiveDateColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.effectiveDateColor) ? this.state.pagination.effectiveDateColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="EldenLaborLaw.applicableCountries" defaultMessage="Applicable Countries" />,
        key: "applicableCountryList",
        render: record => {
          let countries = [];
          if (Boolean(record.applicableCountryList) && record.applicableCountryList.length > 0) {
            record.applicableCountryList.forEach(element => {
              if (element.country) countries.push(element.country.countryName);
            });
          }
          return countries.toString();
        }
      },

      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={18}></Col>
            <Col md={3}>
              <AntButton
                style={{ border: "1px solid #193058" }}
                hidden={!this.state.hideInputs}
                onClick={() =>
                  this.setState({
                    showTemplateModal: true
                  })
                }
                icon={<AlignCenterOutlined color="secondary" fontSize="large" />}
              >
                {" "}
                Template
              </AntButton>
            </Col>
            <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EldenLaborLaw.nameOfLaborLaw" defaultMessage="Name Of LaborLaw" />}
                name="nameOfLaborLaw"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="Name Of LaborLaw" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EldenLaborLaw.applicableCountryList2" defaultMessage="Applicable Country" />}
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                name="countryId"
              >
                <Select style={{ width: "100%" }} placeholder="Country">
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["countryIdSelectItems"]) &&
                    this.state["countryIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EldenLaborLaw.effectiveDate" defaultMessage="Effective Date" />}
                name="effectiveDate"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <DatePicker format="DD-MM-YYYY" />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="TaxSchedule.taxId" defaultMessage="Template" />}
                name="templateId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["templateIdSelectItems"]) &&
                    this.state["templateIdSelectItems"].length > 0 &&
                    this.state["templateIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            }
            {
              <Row gutter={[16, 16]}>
                <Col md={3}></Col>
                <Col md={5}>
                  <>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={Boolean(this.state.overallButton) && this.state.overallButton}
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          overallButton: true
                        });
                      }}
                    >
                      {"Overall"}
                    </AntButton>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={!(Boolean(this.state.overallButton) && this.state.overallButton)}
                      icon={<MinusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          overallButton: false
                        });
                      }}
                    >
                      {"Overall"}
                    </AntButton>
                  </>
                </Col>
              </Row>
            }
            <div hidden={!(Boolean(this.state.overallButton) && this.state.overallButton)}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenLaborLaw.contractType" defaultMessage="Contract Type" />}
                  name="contractType"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select style={{ width: "100%" }} placeholder="Contract Type">
                    <Option key={null} value={null}>
                      Select
                    </Option>
                    {Boolean(this.state["contractTypeSelectItems"]) &&
                      this.state["contractTypeSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenLaborLaw.organizationType" defaultMessage="Organization Type" />}
                  name="organizationType"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select style={{ width: "100%" }} placeholder="Organization Type">
                    <Option key={null} value={null}>
                      Select
                    </Option>
                    {Boolean(this.state["organizationTypeSelectItems"]) &&
                      this.state["organizationTypeSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenLaborLaw.workTypeId" defaultMessage="Work Type" />}
                  name="workTypeId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Work Type"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }} onClick={() => this.addCode("workTypeId")}>
                            <PlusOutlined /> Add item
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    <Option key={null} value={null}>
                      Select
                    </Option>
                    {Boolean(this.state["workTypeIdSelectItems"]) &&
                      this.state["workTypeIdSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenLaborLaw.contractDuration" defaultMessage="Contract Duration" />}
                  name="contractDuration"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select style={{ width: "100%" }} placeholder="Contract Duration">
                    <Option key={null} value={null}>
                      Select
                    </Option>
                    {Boolean(this.state["contractDurationSelectItems"]) &&
                      this.state["contractDurationSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenLaborLaw.durationYears" defaultMessage="Duration Years" />}
                  name="durationYears"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <InputNumber placeholder="Duration Years" min={0} />
                </Form.Item>
              }{" "}
            </div>
            {
              <Row gutter={[16, 16]}>
                <Col md={3}></Col>
                <Col md={5}>
                  <>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={Boolean(this.state.contractButton) && this.state.contractButton}
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          contractButton: true
                        });
                      }}
                    >
                      {"Contract"}
                    </AntButton>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={!(Boolean(this.state.contractButton) && this.state.contractButton)}
                      icon={<MinusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          contractButton: false
                        });
                      }}
                    >
                      {"Contract"}
                    </AntButton>
                  </>
                </Col>
              </Row>
            }
            <div hidden={!(Boolean(this.state.contractButton) && this.state.contractButton)}>
              {
                <>
                  <span style={{ fontSize: "16px", fontWeight: "bold", color: "blue" }}>
                    <FormattedMessage id="EldenLaborLaw.ContractType" defaultMessage="Contract Type" />
                  </span>
                  <Form.List name="contractList">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="5" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.WorkPeriod(Days)"} defaultMessage={"Work Period (Days)"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.ContractType"} defaultMessage={"Contract Type"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.WrittenContract"} defaultMessage={"Written Contract"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.SignRequired"} defaultMessage={"Sign Required"} />
                                </th>
                                <th colSpan="1"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "workingPeriod01"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "workingPeriodId01"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["workingPeriodId01SelectItems"]) &&
                                            this.state["workingPeriodId01SelectItems"].length > 0 &&
                                            this.state["workingPeriodId01SelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    To
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "workingPeriod02"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "workingPeriodId02"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["workingPeriodId02SelectItems"]) &&
                                            this.state["workingPeriodId02SelectItems"].length > 0 &&
                                            this.state["workingPeriodId02SelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        hidden
                                        name={[field.name, "contractType"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                      >
                                        <Input style={{ width: "100%" }} />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "writtenContractId"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["writtenContractIdSelectItems"]) &&
                                            this.state["writtenContractIdSelectItems"].length > 0 &&
                                            this.state["writtenContractIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "signRequired"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Checkbox>Yes</Checkbox>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </>
              }

              {
                <Form.List name="contractTypeList">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenLaborLaw.WorkPeriod(Days)"} defaultMessage={"Contract Type"} />
                              </th>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenLaborLaw.ContractType"} defaultMessage={"Probation Period"} />
                              </th>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenLaborLaw.WrittenContract"} defaultMessage={"Extension Of Parobation"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenLaborLaw.SignRequired"} defaultMessage={"Additional Days"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "contractTypeId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["contractTypeIdSelectItems"]) &&
                                          this.state["contractTypeIdSelectItems"].length > 0 &&
                                          this.state["contractTypeIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "probationPeriod"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                        className="w-100"
                                        placeholder="    "
                                        min={0}
                                      />
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "probationPeriodId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["probationPeriodIdSelectItems"]) &&
                                          this.state["probationPeriodIdSelectItems"].length > 0 &&
                                          this.state["probationPeriodIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "extensionOfParobation"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                        className="w-100"
                                        placeholder="    "
                                        min={0}
                                      />
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "extensionOfParobationId"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["extensionOfParobationIdSelectItems"]) &&
                                          this.state["extensionOfParobationIdSelectItems"].length > 0 &&
                                          this.state["extensionOfParobationIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "additionalDaysId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["additionalDaysIdSelectItems"]) &&
                                          this.state["additionalDaysIdSelectItems"].length > 0 &&
                                          this.state["additionalDaysIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              }

              {
                <>
                  <span style={{ fontSize: "16px", fontWeight: "bold", color: "blue" }}>
                    <FormattedMessage id="EldenLaborLaw.RenewalOfFixTermContract" defaultMessage="Renewal Of Fix-Term Contract" />
                  </span>
                  <Form.List name="contractRenewalOfFixTermContractList">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="2" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.Reminder"} defaultMessage={"Reminder"} />
                                </th>
                                <th colSpan="2" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.NoticePeriod"} defaultMessage={"Notice Period"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.SignRequired"} defaultMessage={"Sign Required"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.Validty"} defaultMessage={"Validty"} />
                                </th>
                                <th colSpan="1"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "reminder"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>

                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "reminderId"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["reminderIdSelectItems"]) &&
                                            this.state["reminderIdSelectItems"].length > 0 &&
                                            this.state["reminderIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "noticePeriod"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>

                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "noticePeriodId"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["noticePeriodIdSelectItems"]) &&
                                            this.state["noticePeriodIdSelectItems"].length > 0 &&
                                            this.state["noticePeriodIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>

                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "signRequired"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Checkbox>Yes</Checkbox>
                                      </Form.Item>
                                    </td>

                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "validty"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Checkbox>New Offer period of contract</Checkbox>
                                      </Form.Item>
                                    </td>

                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </>
              }

              {
                <>
                  <span style={{ fontSize: "16px", fontWeight: "bold", color: "blue" }}>
                    <FormattedMessage id="EldenLaborLaw.MinimumEmployeInformation" defaultMessage="Minimum Employe Information" />
                  </span>
                  <Form.List name="contractMinimumEmployeInformationList">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.MinimumEmployeInformation"} defaultMessage={"Minimum Employe Information"} />
                                </th>
                                <th colSpan="1"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "minimumEmployeInformation"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Input style={{ width: "100%" }} />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </>
              }
            </div>
            {
              <Row gutter={[16, 16]}>
                <Col md={3}></Col>
                <Col md={5}>
                  <>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={Boolean(this.state.probationPeriodButton) && this.state.probationPeriodButton}
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          probationPeriodButton: true
                        });
                      }}
                    >
                      {"Probation Period"}
                    </AntButton>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={!(Boolean(this.state.probationPeriodButton) && this.state.probationPeriodButton)}
                      icon={<MinusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          probationPeriodButton: false
                        });
                      }}
                    >
                      {"Probation Period"}
                    </AntButton>
                  </>
                </Col>
              </Row>
            }
            <div hidden={!(Boolean(this.state.probationPeriodButton) && this.state.probationPeriodButton)}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenLaborLaw.probationPeriod" defaultMessage="Probation Period" />}
                  name="probationPeriod"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <InputNumber placeholder="Probation Period" min={0} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenLaborLaw.probationPeriodType" defaultMessage="Probation Period Type" />}
                  name="probationPeriodType"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select style={{ width: "100%" }} placeholder="Probation Period Type">
                    <Option key={null} value={null}>
                      Select
                    </Option>
                    {Boolean(this.state["probationPeriodTypeSelectItems"]) &&
                      this.state["probationPeriodTypeSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenLaborLaw.extensionProbationPeriod" defaultMessage="Extension Probation Period" />}
                  name="extensionProbationPeriod"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <InputNumber placeholder="Extension Probation Period" min={0} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenLaborLaw.extensionProbationPeriodType" defaultMessage="Extension ProbationPeriod Type" />}
                  name="extensionProbationPeriodType"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select style={{ width: "100%" }} placeholder="Extension ProbationPeriod Type">
                    <Option key={null} value={null}>
                      Select
                    </Option>
                    {Boolean(this.state["extensionProbationPeriodTypeSelectItems"]) &&
                      this.state["extensionProbationPeriodTypeSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenLaborLaw.additionalDays" defaultMessage="Additional Days" />}
                  name="additionalDays"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select style={{ width: "100%" }} mode="multiple" placeholder="Extension ProbationPeriod Type">
                    {Boolean(this.state["additionalDaysSelectItems"]) &&
                      this.state["additionalDaysSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }{" "}
            </div>
            {
              <Row gutter={[16, 16]}>
                <Col md={3}></Col>
                <Col md={5}>
                  <>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={Boolean(this.state.renewalButton) && this.state.renewalButton}
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          renewalButton: true
                        });
                      }}
                    >
                      {"Renewal of Contract"}
                    </AntButton>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={!(Boolean(this.state.renewalButton) && this.state.renewalButton)}
                      icon={<MinusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          renewalButton: false
                        });
                      }}
                    >
                      {"Renewal of Contract"}
                    </AntButton>
                  </>
                </Col>
              </Row>
            }
            <div hidden={!(Boolean(this.state.renewalButton) && this.state.renewalButton)}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenLaborLaw.autoRenewal" defaultMessage="Auto Renewal" />}
                  name="autoRenewal"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox>
                    {" "}
                    <FormattedMessage id="EldenLaborLaw.autoRenewal" defaultMessage="Auto Renewal" />{" "}
                  </Checkbox>
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenLaborLaw.renewalReminderDays" defaultMessage="Renewal Reminder Days" />}
                  name="renewalReminderDays"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <InputNumber placeholder="Renewal Reminder Days" min={0} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenLaborLaw.employeeNoticePeriod" defaultMessage="Employee Notice Period" />}
                  name="employeeNoticePeriod"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <InputNumber placeholder="Employee Notice Period" min={0} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={
                    <FormattedMessage
                      id="EldenLaborLaw.changeFixedToIndefiniteAfter"
                      defaultMessage="Change condition form fixed to Indefinite term contract"
                    />
                  }
                  name="changeFixedToIndefiniteAfter"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <InputNumber placeholder="Change condition fom fixed to Indefinite term contract" min={0} />
                </Form.Item>
              }{" "}
            </div>
            {
              <Row gutter={[16, 16]}>
                <Col md={3}></Col>
                <Col md={5}>
                  <>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={Boolean(this.state.workingHoursButton) && this.state.workingHoursButton}
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState(
                          {
                            workingHoursButton: true
                          },
                          () => {
                            let workingHoursList = [];

                            workingHoursList.push({ time: "Day" });
                            workingHoursList.push({ time: "Week" });
                            workingHoursList.push({ time: "Month" });
                            workingHoursList.push({ time: "Year" });

                            this.formRef.current.setFieldsValue({
                              workingHoursList: workingHoursList
                            });
                          }
                        );
                      }}
                    >
                      {"Working Hours"}
                    </AntButton>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={!(Boolean(this.state.workingHoursButton) && this.state.workingHoursButton)}
                      icon={<MinusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          workingHoursButton: false
                        });
                      }}
                    >
                      {"Working Hours"}
                    </AntButton>
                  </>
                </Col>
              </Row>
            }
            <div hidden={!(Boolean(this.state.workingHoursButton) && this.state.workingHoursButton)}>
              {
                <>
                  <span style={{ fontSize: "16px", fontWeight: "bold", color: "blue" }}>
                    <FormattedMessage id="EldenLaborLaw.Shifts" defaultMessage="Shifts" />
                  </span>
                  <Form.List name="workingHoursShiftsList">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.ShiftName"} defaultMessage={"Shift Name"} />
                                </th>
                                <th colSpan="5" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.Hours"} defaultMessage={"Hours"} />
                                </th>
                                <th colSpan="1"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "shiftName"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Input style={{ width: "100px" }} />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "hours01"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "hoursId01"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["hoursId01SelectItems"]) &&
                                            this.state["hoursId01SelectItems"].length > 0 &&
                                            this.state["hoursId01SelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    To
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "hours02"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "hoursId02"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["hoursId02SelectItems"]) &&
                                            this.state["hoursId02SelectItems"].length > 0 &&
                                            this.state["hoursId02SelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </>
              }

              {
                <>
                  <span style={{ fontSize: "16px", fontWeight: "bold", color: "blue" }}>
                    <FormattedMessage id="EldenLaborLaw.WorkingHours" defaultMessage="Working Hours" />
                  </span>
                  <Form.List name="workingHoursList">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw."} defaultMessage={" "} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.Shift"} defaultMessage={"Shift"} />
                                </th>
                                <th colSpan="2" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.NormalWork"} defaultMessage={"Normal Work"} />
                                </th>
                                <th colSpan="2" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.MaximumWork"} defaultMessage={"Maximum Work"} />
                                </th>
                                <th colSpan="2" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.ApplicablePeriodLast"} defaultMessage={"Applicable Period (Last)"} />
                                </th>
                                <th colSpan="2" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.MaximumConsecutive"} defaultMessage={"Maximum Consecutive"} />
                                </th>
                                <th colSpan="2" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.MaximumConsecutive"} defaultMessage={"Off"} />
                                </th>
                                <th colSpan="1"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      {field.name > 3 ? (
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "time"]}
                                          style={{
                                            display: "inline-block",

                                            marginBottom: "5px",
                                            marginLeft: "5px"
                                          }}
                                          rules={[
                                            {
                                              required: false,
                                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                            }
                                          ]}
                                        >
                                          <Input style={{ width: "100px" }} />
                                        </Form.Item>
                                      ) : (
                                        <Text type="secondary">
                                          {Boolean(this.formRef) &&
                                            this.formRef.current &&
                                            this.formRef.current.getFieldValue("workingHoursList") &&
                                            this.formRef.current.getFieldValue("workingHoursList").length > 0 &&
                                            this.formRef.current.getFieldValue("workingHoursList")[field.name].time}
                                        </Text>
                                      )}
                                    </td>

                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "shiftId"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["shiftIdSelectItems"]) &&
                                            this.state["shiftIdSelectItems"].length > 0 &&
                                            this.state["shiftIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>

                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "normalWork"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>

                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "normalWorkId"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["normalWorkIdSelectItems"]) &&
                                            this.state["normalWorkIdSelectItems"].length > 0 &&
                                            this.state["normalWorkIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>

                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "maximumWork"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>

                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "maximumWorkId"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["maximumWorkIdSelectItems"]) &&
                                            this.state["maximumWorkIdSelectItems"].length > 0 &&
                                            this.state["maximumWorkIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>

                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "applicablePeriodLast"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>

                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "applicablePeriodLastId"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["applicablePeriodLastIdSelectItems"]) &&
                                            this.state["applicablePeriodLastIdSelectItems"].length > 0 &&
                                            this.state["applicablePeriodLastIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>

                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "maximumConsecutive"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>

                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "maximumConsecutiveId"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["maximumConsecutiveIdSelectItems"]) &&
                                            this.state["maximumConsecutiveIdSelectItems"].length > 0 &&
                                            this.state["maximumConsecutiveIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>

                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "off"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>

                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "offId"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["offIdSelectItems"]) &&
                                            this.state["offIdSelectItems"].length > 0 &&
                                            this.state["offIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>

                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan={13}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </>
              }
            </div>
            {
              <Row gutter={[16, 16]}>
                <Col md={3}></Col>
                <Col md={5}>
                  <>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={Boolean(this.state.wageButton) && this.state.wageButton}
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          wageButton: true
                        });
                      }}
                    >
                      {"Wage"}
                    </AntButton>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={!(Boolean(this.state.wageButton) && this.state.wageButton)}
                      icon={<MinusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          wageButton: false
                        });
                      }}
                    >
                      {"Wage"}
                    </AntButton>
                  </>
                </Col>
              </Row>
            }
            <div hidden={!(Boolean(this.state.wageButton) && this.state.wageButton)}>
              {
                <Form.List name="wageList">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              <th colSpan="5" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenLaborLaw.Sex"} defaultMessage={"Gender"} />
                              </th>
                              <th colSpan="5" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenLaborLaw.Age"} defaultMessage={"Age"} />
                              </th>
                              <th colSpan="3" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenLaborLaw.MinWage"} defaultMessage={"Min Wage"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenLaborLaw.PaymentPeriod"} defaultMessage={"Payment Period"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenLaborLaw.Currency"} defaultMessage={"Currency"} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "gender01"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                        className="w-100"
                                        placeholder="    "
                                        min={0}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "genderId01"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["genderId01SelectItems"]) &&
                                          this.state["genderId01SelectItems"].length > 0 &&
                                          this.state["genderId01SelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  To
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "gender02"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                        className="w-100"
                                        placeholder="    "
                                        min={0}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "genderId02"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["genderId02SelectItems"]) &&
                                          this.state["genderId02SelectItems"].length > 0 &&
                                          this.state["genderId02SelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "age01"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                        className="w-100"
                                        placeholder="    "
                                        min={0}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "ageId01"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["ageId01SelectItems"]) &&
                                          this.state["ageId01SelectItems"].length > 0 &&
                                          this.state["ageId01SelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  To
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "age02"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                        className="w-100"
                                        placeholder="    "
                                        min={0}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "ageId02"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["ageId02SelectItems"]) &&
                                          this.state["ageId02SelectItems"].length > 0 &&
                                          this.state["ageId02SelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "minWageId01"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["minWageId01SelectItems"]) &&
                                          this.state["minWageId01SelectItems"].length > 0 &&
                                          this.state["minWageId01SelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "minWage"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                        className="w-100"
                                        placeholder="    "
                                        min={0}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "minWageId02"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["minWageId02SelectItems"]) &&
                                          this.state["minWageId02SelectItems"].length > 0 &&
                                          this.state["minWageId02SelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "paymentPeriodId"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["paymentPeriodIdSelectItems"]) &&
                                          this.state["paymentPeriodIdSelectItems"].length > 0 &&
                                          this.state["paymentPeriodIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "currencyId"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["currencyIdSelectItems"]) &&
                                          this.state["currencyIdSelectItems"].length > 0 &&
                                          this.state["currencyIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan={15}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              }
            </div>
            {
              <Row gutter={[16, 16]}>
                <Col md={3}></Col>
                <Col md={5}>
                  <>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={Boolean(this.state.overtimeButton) && this.state.overtimeButton}
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState(
                          {
                            overtimeButton: true
                          },
                          () => {
                            let overtimePaymentCalculationList = [];

                            overtimePaymentCalculationList.push({ work: "Normal Work Day" });
                            overtimePaymentCalculationList.push({ work: "Weekend" });
                            overtimePaymentCalculationList.push({ work: "National Day" });
                            overtimePaymentCalculationList.push({ work: "Religion Day" });
                            overtimePaymentCalculationList.push({ work: "Official Holiday" });

                            this.formRef.current.setFieldsValue({
                              overtimePaymentCalculationList: overtimePaymentCalculationList
                            });
                          }
                        );
                      }}
                    >
                      {"Overtime Payment Calculation"}
                    </AntButton>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={!(Boolean(this.state.overtimeButton) && this.state.overtimeButton)}
                      icon={<MinusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          overtimeButton: false
                        });
                      }}
                    >
                      {"Overtime Payment Calculation"}
                    </AntButton>
                  </>
                </Col>
              </Row>
            }
            <div hidden={!(Boolean(this.state.overtimeButton) && this.state.overtimeButton)}>
              <Form.List name="overtimePaymentCalculationList">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"EldenLaborLaw.Work"} defaultMessage={"Work"} />
                            </th>
                            <th colSpan="2" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"EldenLaborLaw.Payment"} defaultMessage={"Payment"} />
                            </th>
                            <th colSpan="1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  {field.name > 4 ? (
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "work"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Input style={{ width: "100px" }} />
                                    </Form.Item>
                                  ) : (
                                    <Text type="secondary">
                                      {Boolean(this.formRef) &&
                                        this.formRef.current &&
                                        this.formRef.current.getFieldValue("overtimePaymentCalculationList") &&
                                        this.formRef.current.getFieldValue("overtimePaymentCalculationList").length > 0 &&
                                        this.formRef.current.getFieldValue("overtimePaymentCalculationList")[field.name].work}
                                    </Text>
                                  )}
                                </td>

                                <td>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "payment"]}
                                    style={{
                                      display: "inline-block",

                                      marginBottom: "5px",
                                      marginLeft: "5px"
                                    }}
                                    rules={[
                                      {
                                        required: false,
                                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                      }
                                    ]}
                                  >
                                    <InputNumber
                                      style={{ width: "100px" }}
                                      parser={value => value.replace(",", ".")}
                                      formatter={value => value.replace(".", ",")}
                                      className="w-100"
                                      placeholder="    "
                                      min={0}
                                    />
                                  </Form.Item>
                                </td>

                                <td>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "paymentId"]}
                                    style={{
                                      display: "inline-block",
                                      marginBottom: "5px",
                                      marginLeft: "5px"
                                    }}
                                    rules={[
                                      {
                                        required: false,
                                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                      }
                                    ]}
                                  >
                                    <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                      {Boolean(this.state["paymentIdSelectItems"]) &&
                                        this.state["paymentIdSelectItems"].length > 0 &&
                                        this.state["paymentIdSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>

                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </div>
            {
              <Row gutter={[16, 16]}>
                <Col md={3}></Col>
                <Col md={5}>
                  <>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={Boolean(this.state.annualVacationsButton) && this.state.annualVacationsButton}
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          annualVacationsButton: true
                        });
                      }}
                    >
                      {"Annual Vacation"}
                    </AntButton>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={!(Boolean(this.state.annualVacationsButton) && this.state.annualVacationsButton)}
                      icon={<MinusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          annualVacationsButton: false
                        });
                      }}
                    >
                      {"Annual Vacation"}
                    </AntButton>
                  </>
                </Col>
              </Row>
            }
            <div hidden={!(Boolean(this.state.annualVacationsButton) && this.state.annualVacationsButton)}>
              {
                <Form.List name="annualVacationList">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              <th colSpan="5" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenLaborLaw.AgeofEmployee"} defaultMessage={"Age of Employee"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenLaborLaw.GenderofEmployee"} defaultMessage={"Gender of Employee"} />
                              </th>
                              <th colSpan="5" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenLaborLaw.MinWage"} defaultMessage={"Working Years"} />
                              </th>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenLaborLaw.Vacationdays"} defaultMessage={"Vacation days"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenLaborLaw.Startofnewvacationperiod"} defaultMessage={"Start of new vacation period"} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "ageOfEmployee01"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                        className="w-100"
                                        placeholder="    "
                                        min={0}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "ageOfEmployeeId01"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["ageOfEmployeeId01SelectItems"]) &&
                                          this.state["ageOfEmployeeId01SelectItems"].length > 0 &&
                                          this.state["ageOfEmployeeId01SelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  To
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "ageOfEmployee02"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                        className="w-100"
                                        placeholder="    "
                                        min={0}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "ageOfEmployeeId02"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["ageOfEmployeeId02SelectItems"]) &&
                                          this.state["ageOfEmployeeId02SelectItems"].length > 0 &&
                                          this.state["ageOfEmployeeId02SelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "genderOfEmployeeId"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["genderOfEmployeeIdSelectItems"]) &&
                                          this.state["genderOfEmployeeIdSelectItems"].length > 0 &&
                                          this.state["genderOfEmployeeIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "workingYears01"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                        className="w-100"
                                        placeholder="    "
                                        min={0}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "workingYearsId01"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["workingYearsId01SelectItems"]) &&
                                          this.state["workingYearsId01SelectItems"].length > 0 &&
                                          this.state["workingYearsId01SelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  To
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "workingYears02"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                        className="w-100"
                                        placeholder="    "
                                        min={0}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "workingYearsId02"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["workingYearsId02SelectItems"]) &&
                                          this.state["workingYearsId02SelectItems"].length > 0 &&
                                          this.state["workingYearsId02SelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "vacationDaysId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["vacationDaysIdSelectItems"]) &&
                                          this.state["vacationDaysIdSelectItems"].length > 0 &&
                                          this.state["vacationDaysIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "vacationDays"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                        className="w-100"
                                        placeholder="    "
                                        min={0}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "startOfNewVacationPeriodId"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["startOfNewVacationPeriodIdSelectItems"]) &&
                                          this.state["startOfNewVacationPeriodIdSelectItems"].length > 0 &&
                                          this.state["startOfNewVacationPeriodIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan={14}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              }
            </div>
            {
              <Row gutter={[16, 16]}>
                <Col md={3}></Col>
                <Col md={5}>
                  <>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={Boolean(this.state.leavesButton) && this.state.leavesButton}
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          leavesButton: true
                        });
                      }}
                    >
                      {"Leave"}
                    </AntButton>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={!(Boolean(this.state.leavesButton) && this.state.leavesButton)}
                      icon={<MinusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          leavesButton: false
                        });
                      }}
                    >
                      {"Leave"}
                    </AntButton>
                  </>
                </Col>
              </Row>
            }
            <div hidden={!(Boolean(this.state.leavesButton) && this.state.leavesButton)}>
              {
                <Form.List name="leaveList">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenLaborLaw.TypeofLeave"} defaultMessage={"Type of Leave"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenLaborLaw.GenderofEmployee"} defaultMessage={"Gender of Employee"} />
                              </th>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenLaborLaw.Relation"} defaultMessage={"Relation"} />
                              </th>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenLaborLaw.LegalOffDays"} defaultMessage={"Legal off Days"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "typeOfLeaveId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["typeOfLeaveIdSelectItems"]) &&
                                          this.state["typeOfLeaveIdSelectItems"].length > 0 &&
                                          this.state["typeOfLeaveIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "genderOfEmployeeId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["genderOfEmployeeIdSelectItems"]) &&
                                          this.state["genderOfEmployeeIdSelectItems"].length > 0 &&
                                          this.state["genderOfEmployeeIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "relationId01"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["relationId01SelectItems"]) &&
                                          this.state["relationId01SelectItems"].length > 0 &&
                                          this.state["relationId01SelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "relationId02"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["relationId02SelectItems"]) &&
                                          this.state["relationId02SelectItems"].length > 0 &&
                                          this.state["relationId02SelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "legalOffDays"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                        className="w-100"
                                        placeholder="    "
                                        min={0}
                                      />
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "legalOffDaysId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["legalOffDaysIdSelectItems"]) &&
                                          this.state["legalOffDaysIdSelectItems"].length > 0 &&
                                          this.state["legalOffDaysIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              }
            </div>
            {
              <Row gutter={[16, 16]}>
                <Col md={3}></Col>
                <Col md={5}>
                  <>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={Boolean(this.state.EndOfServiceButton) && this.state.EndOfServiceButton}
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          EndOfServiceButton: true
                        });
                      }}
                    >
                      {"End of Service"}
                    </AntButton>
                    <AntButton
                      style={{ width: "1000px" }}
                      className="mb-3"
                      type="dashed"
                      hidden={!(Boolean(this.state.EndOfServiceButton) && this.state.EndOfServiceButton)}
                      icon={<MinusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          EndOfServiceButton: false
                        });
                      }}
                    >
                      {"End of Service"}
                    </AntButton>
                  </>
                </Col>
              </Row>
            }
            <div hidden={!(Boolean(this.state.EndOfServiceButton) && this.state.EndOfServiceButton)}>
              {
                <>
                  <span style={{ fontSize: "16px", fontWeight: "bold", color: "blue" }}>
                    <FormattedMessage id="EldenLaborLaw.Voluntary" defaultMessage="Voluntary" />
                  </span>
                  <Form.List name="endOfServiceVoluntaryList">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.Applicable"} defaultMessage={"Applicable"} />
                                </th>
                                <th colSpan="5" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.WorkingYears"} defaultMessage={"Working Years"} />
                                </th>
                                <th colSpan="4" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.MinWage"} defaultMessage={"Payment"} />
                                </th>
                                <th colSpan="2" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.Vacationdays"} defaultMessage={"Calculation"} />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "applicableId"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["applicableIdSelectItems"]) &&
                                            this.state["applicableIdSelectItems"].length > 0 &&
                                            this.state["applicableIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "workingYears01"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "workingYearsId01"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["ageOfEmployeeId01SelectItems"]) &&
                                            this.state["ageOfEmployeeId01SelectItems"].length > 0 &&
                                            this.state["ageOfEmployeeId01SelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    To
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "workingYears02"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "workingYearsId02"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["workingYearsId02SelectItems"]) &&
                                            this.state["workingYearsId02SelectItems"].length > 0 &&
                                            this.state["workingYearsId02SelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "paymentId01"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["paymentId01SelectItems"]) &&
                                            this.state["paymentId01SelectItems"].length > 0 &&
                                            this.state["paymentId01SelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "payment01"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "payment02"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "paymentId02"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["paymentId02SelectItems"]) &&
                                            this.state["paymentId02SelectItems"].length > 0 &&
                                            this.state["paymentId02SelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "calculationId"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        {" "}
                                        over
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["calculationIdSelectItems"]) &&
                                            this.state["calculationIdSelectItems"].length > 0 &&
                                            this.state["calculationIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan={14}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </>
              }

              {
                <>
                  <span style={{ fontSize: "16px", fontWeight: "bold", color: "blue" }}>
                    <FormattedMessage id="EldenLaborLaw.Involuntary" defaultMessage="Involuntary" />
                  </span>
                  <Form.List name="endOfServiceInvoluntaryList">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.Applicable"} defaultMessage={"Applicable"} />
                                </th>
                                <th colSpan="5" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.WorkingYears"} defaultMessage={"Working Years"} />
                                </th>
                                <th colSpan="4" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.MinWage"} defaultMessage={"Payment"} />
                                </th>
                                <th colSpan="2" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"EldenLaborLaw.Vacationdays"} defaultMessage={"Calculation"} />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "applicableId"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["applicableIdSelectItems"]) &&
                                            this.state["applicableIdSelectItems"].length > 0 &&
                                            this.state["applicableIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "workingYears01"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "workingYearsId01"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["ageOfEmployeeId01SelectItems"]) &&
                                            this.state["ageOfEmployeeId01SelectItems"].length > 0 &&
                                            this.state["ageOfEmployeeId01SelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    To
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "workingYears02"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "workingYearsId02"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["workingYearsId02SelectItems"]) &&
                                            this.state["workingYearsId02SelectItems"].length > 0 &&
                                            this.state["workingYearsId02SelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "paymentId01"]}
                                        style={{
                                          display: "inline-block",

                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["paymentId01SelectItems"]) &&
                                            this.state["paymentId01SelectItems"].length > 0 &&
                                            this.state["paymentId01SelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "payment01"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "payment02"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: "100px" }}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          className="w-100"
                                          placeholder="    "
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "paymentId02"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["paymentId02SelectItems"]) &&
                                            this.state["paymentId02SelectItems"].length > 0 &&
                                            this.state["paymentId02SelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "calculationId"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                          }
                                        ]}
                                      >
                                        {" "}
                                        over
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                          {Boolean(this.state["calculationIdSelectItems"]) &&
                                            this.state["calculationIdSelectItems"].length > 0 &&
                                            this.state["calculationIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan={14}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </>
              }
            </div>
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <AntModal
          centered
          width={1300}
          title="Template"
          open={this.state.showTemplateModal}
          okButtonProps={{ hidden: false }}
          onCancel={() => this.setState({ showTemplateModal: false })}
          onOk={() => this.formRef.current.submit()}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
        >
          <Form
            initialValues={{ remember: false }}
            onFinish={this.save}
            // onFinishFailed={onFinishFailed}
            ref={this.formRef}
          >
            {<></>}
          </Form>
        </AntModal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LaborLaw);
