import React, { useState, useEffect } from "react";
import { Table, Col, Select, Row, DatePicker, Progress } from "antd";
import { Pie } from "@ant-design/plots";

import moment from "moment";


const { Option } = Select;

const PaymentType = () => {
  


  const [startDate, setStartDate] = useState(moment().subtract(6, "M"));
  const [endDate, setEndDate] = useState(moment());
  const data = [
    { type: 'Cash', value: 40 },
    { type: 'Credit', value: 20 },
    { type: 'Bank Transfer', value: 15 },
    { type: 'Cheque', value: 24 },
    { type: 'Note', value: 1 },
  ];

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{name}: {percentage}',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
}

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={6}>
          <DatePicker
            placeholder="Start Date"
            style={{ width: "100%" }}
            value={startDate}
            onChange={date => {
              setStartDate(date);
            }}
          />
        </Col>
        <Col lg={6}>
          <DatePicker
            placeholder="End Date"
            style={{ width: "100%" }}
            value={endDate}
            onChange={date => {
              setEndDate(date);
            }}
          />
          
        </Col>
        <Col lg={6}>
         
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={24}>
          
        </Col>

        <Col lg={24}>          
        <Pie {...config} />;
        </Col>
      </Row>
    </>
  );
};

export default PaymentType;
