import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { Form, Table, Input, Select, InputNumber, Tooltip, Checkbox, Radio, DatePicker, Switch, Button as AntButton, Col, Row } from "antd";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";

const { RangePicker } = DatePicker;
const { Option } = Select;

export class SalarySettings extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      controllerName: "salarySettings",
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      monthSelectItems: [],
      paymentTypesSelectItems: [],
      paymentDayTypesSelectItems: [],
      paymentDaysSelectItems: [],
      dayMonthsSelectItems: [],
      homeAllowance: true,
      transportationAllowance: true,
      foodAllowance: true,
      overtime: true,
      commision: true,
      bonus: true,
      businessTrip: true,
      otherPayments: true,
      applyToAllMonths: false,

      id: null,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    let urlFirstData = "/api/" + this.state.controllerName + "/firstData";
    const responseFirstData = await handleRequest("GET", urlFirstData);
    if (responseFirstData.type === "ERROR") {
      error(responseFirstData);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      this.fillComboboxes();
      fillFilterOperations();
    }
  };

  fillComboboxes = async () => {
    var responseMonths = await handleRequest("GET", "/api/" + this.state.controllerName + "/months");
    if (responseMonths.type === "ERROR") {
      error(responseMonths);
    } else {
      this.setState({
        monthSelectItems: Boolean(responseMonths.data) ? responseMonths.data : []
      });
    }

    var responsePaymentTypes = await handleRequest("GET", "/api/" + this.state.controllerName + "/paymentTypes");
    if (responsePaymentTypes.type === "ERROR") {
      error(responsePaymentTypes);
    } else {
      this.setState({
        paymentTypesSelectItems: Boolean(responsePaymentTypes.data) ? responsePaymentTypes.data : []
      });
    }

    var responsePaymentDayTypes = await handleRequest("GET", "/api/" + this.state.controllerName + "/paymentDayTypes");
    if (responsePaymentDayTypes.type === "ERROR") {
      error(responsePaymentDayTypes);
    } else {
      this.setState({
        paymentDayTypesSelectItems: Boolean(responsePaymentDayTypes.data) ? responsePaymentDayTypes.data : []
      });
    }

    var responsePaymentDays = await handleRequest("GET", "/api/" + this.state.controllerName + "/paymentDays");
    if (responsePaymentDays.type === "ERROR") {
      error(responsePaymentDays);
    } else {
      this.setState({
        paymentDaysSelectItems: Boolean(responsePaymentDays.data) ? responsePaymentDays.data : []
      });
    }

    var responseDayMonths = await handleRequest("GET", "/api/" + this.state.controllerName + "/dayMonths");
    if (responseDayMonths.type === "ERROR") {
      error(responseDayMonths);
    } else {
      this.setState({
        dayMonthsSelectItems: Boolean(responseDayMonths.data) ? responseDayMonths.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.formRef.current.setFieldsValue({
      homeAllowance: true,
      transportationAllowance: true,
      foodAllowance: true,
      overtime: true,
      commision: true,
      bonus: true,
      businessTrip: true,
      otherPayments: true,
      applyToAllMonths: false
    });

    this.setState({
      id: null,
      homeAllowance: true,
      transportationAllowance: true,
      foodAllowance: true,
      overtime: true,
      commision: true,
      bonus: true,
      businessTrip: true,
      otherPayments: true,
      applyToAllMonths: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  edit = row => {
    console.log("AAA", row);
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
      year: moment(row.year, "YYYY")
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      homeAllowance: row.homeAllowance,
      transportationAllowance: row.transportationAllowance,
      foodAllowance: row.foodAllowance,
      overtime: row.overtime,
      commision: row.commision,
      bonus: row.bonus,
      businessTrip: row.businessTrip,
      otherPayments: row.otherPayments,
      paymentDay: row.paymentDay,
      paymentType: row.paymentType
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    console.log("AAA", values);
    var year = Number(moment(values.year).format("YYYY"));
    let month = Number(
      Boolean(values.month)
        ? moment()
            .month(values.month)
            .format("M")
        : 0
    );
    var paymentDate = null,
      homeAllowanceDate = null,
      transportationAllowanceDate = null,
      foodAllowanceDate = null,
      overtimeDate = null,
      commisionDate = null,
      bonusDate = null,
      businessTripDate = null,
      otherPaymentsDate;
    if (Boolean(values.paymentDayType)) {
      if (values.paymentDayType === "Following_Month") {
        if (month === 12) {
          year = year + 1;
          month = 1;
        } else {
          month = month + 1;
        }
      }
      if (values.paymentDay === "First_Day") {
        if (Boolean(month) && month > 0) {
          paymentDate = moment(year + "-" + month + "-" + "01");
          homeAllowanceDate = this.fillAllowanceDate(
            values,
            year,
            month,
            "01",
            "homeAllowance",
            "homeAllowanceDayMonth",
            "homeAllowanceTypeDayMonth",
            "homeAllowanceNextMonthWithSalary"
          );
          transportationAllowanceDate = this.fillAllowanceDate(
            values,
            year,
            month,
            "01",
            "transportationAllowance",
            "transportationAllowanceDayMonth",
            "transportationAllowanceTypeDayMonth",
            "transportationAllowanceNextMonthWithSalary"
          );
          foodAllowanceDate = this.fillAllowanceDate(
            values,
            year,
            month,
            "01",
            "foodAllowance",
            "foodAllowanceDayMonth",
            "foodAllowanceTypeDayMonth",
            "foodAllowanceNextMonthWithSalary"
          );
          overtimeDate = this.fillAllowanceDate(
            values,
            year,
            month,
            "01",
            "overtime",
            "overtimeDayMonth",
            "overtimeTypeDayMonth",
            "overtimeNextMonthWithSalary"
          );
          commisionDate = this.fillAllowanceDate(
            values,
            year,
            month,
            "01",
            "commision",
            "commisionDayMonth",
            "commisionTypeDayMonth",
            "commisionNextMonthWithSalary"
          );
          bonusDate = this.fillAllowanceDate(values, year, month, "01", "bonus", "bonusDayMonth", "bonusTypeDayMonth", "bonusNextMonthWithSalary");
          businessTripDate = this.fillAllowanceDate(
            values,
            year,
            month,
            "01",
            "businessTrip",
            "businessTripDayMonth",
            "businessTripTypeDayMonth",
            "businessTripNextMonthWithSalary"
          );
          otherPaymentsDate = this.fillAllowanceDate(
            values,
            year,
            month,
            "01",
            "otherPayments",
            "otherPaymentsDayMonth",
            "otherPaymentsTypeDayMonth",
            "otherPaymentsNextMonthWithSalary"
          );
        }
      } else if (values.paymentDay === "Last_Day") {
        if (Boolean(month) && month > 0) {
          var end = moment(year + "-" + month + "-" + "01").format("YYYY-MM-") + moment(year + "-" + month + "-" + "01").daysInMonth();
          var endDay = moment(end).format("DD");
          paymentDate = moment(year + "-" + month + "-" + endDay);
          homeAllowanceDate = this.fillAllowanceDate(
            values,
            year,
            month,
            endDay,
            "homeAllowance",
            "homeAllowanceDayMonth",
            "homeAllowanceTypeDayMonth",
            "homeAllowanceNextMonthWithSalary"
          );
          transportationAllowanceDate = this.fillAllowanceDate(
            values,
            year,
            month,
            endDay,
            "transportationAllowance",
            "transportationAllowanceDayMonth",
            "transportationAllowanceTypeDayMonth",
            "transportationNextMonthWithSalary"
          );
          foodAllowanceDate = this.fillAllowanceDate(
            values,
            year,
            month,
            endDay,
            "foodAllowance",
            "foodAllowanceDayMonth",
            "foodAllowanceTypeDayMonth",
            "foodAllowanceNextMonthWithSalary"
          );
          overtimeDate = this.fillAllowanceDate(
            values,
            year,
            month,
            endDay,
            "overtime",
            "overtimeDayMonth",
            "overtimeTypeDayMonth",
            "overtimeNextMonthWithSalary"
          );
          commisionDate = this.fillAllowanceDate(
            values,
            year,
            month,
            endDay,
            "commision",
            "commisionDayMonth",
            "commisionTypeDayMonth",
            "commisionNextMonthWithSalary"
          );
          bonusDate = this.fillAllowanceDate(values, year, month, endDay, "bonus", "bonusDayMonth", "bonusTypeDayMonth", "bonusNextMonthWithSalary");
          businessTripDate = this.fillAllowanceDate(
            values,
            year,
            month,
            endDay,
            "businessTrip",
            "businessTripDayMonth",
            "businessTripTypeDayMonth",
            "businessTripNextMonthWithSalary"
          );
          otherPaymentsDate = this.fillAllowanceDate(
            values,
            year,
            month,
            endDay,
            "otherPayments",
            "otherPaymentsDayMonth",
            "otherPaymentsTypeDayMonth",
            "otherPaymentsNextMonthWithSalary"
          );
        }
      } else if (values.paymentDay === "Every") {
        if (Boolean(month) && month > 0) {
          var everyDay = Boolean(values.ofMonthWeek) ? values.ofMonthWeek : 1;
          paymentDate = moment(year + "-" + month + "-" + everyDay);
          homeAllowanceDate = this.fillAllowanceDate(
            values,
            year,
            month,
            everyDay,
            "homeAllowance",
            "homeAllowanceDayMonth",
            "homeAllowanceTypeDayMonth",
            "homeAllowanceNextMonthWithSalary"
          );
          transportationAllowanceDate = this.fillAllowanceDate(
            values,
            year,
            month,
            everyDay,
            "transportationAllowance",
            "transportationAllowanceDayMonth",
            "transportationAllowanceTypeDayMonth",
            "transportationAllowanceNextMonthWithSalary"
          );
          foodAllowanceDate = this.fillAllowanceDate(
            values,
            year,
            month,
            everyDay,
            "foodAllowance",
            "foodAllowanceDayMonth",
            "foodAllowanceTypeDayMonth",
            "foodAllowanceNextMonthWithSalary"
          );
          overtimeDate = this.fillAllowanceDate(
            values,
            year,
            month,
            everyDay,
            "overtime",
            "overtimeDayMonth",
            "overtimeTypeDayMonth",
            "overtimeNextMonthWithSalary"
          );
          commisionDate = this.fillAllowanceDate(
            values,
            year,
            month,
            everyDay,
            "commision",
            "commisionDayMonth",
            "commisionTypeDayMonth",
            "commisionNextMonthWithSalary"
          );
          bonusDate = this.fillAllowanceDate(
            values,
            year,
            month,
            everyDay,
            "bonus",
            "bonusDayMonth",
            "bonusTypeDayMonth",
            "bonusNextMonthWithSalary"
          );
          businessTripDate = this.fillAllowanceDate(
            values,
            year,
            month,
            everyDay,
            "businessTrip",
            "businessTripDayMonth",
            "businessTripTypeDayMonth",
            "businessTripNextMonthWithSalary"
          );
          otherPaymentsDate = this.fillAllowanceDate(
            values,
            year,
            month,
            everyDay,
            "otherPayments",
            "otherPaymentsDayMonth",
            "otherPaymentsTypeDayMonth",
            "otherPaymentsNextMonthWithSalary"
          );
        }
      }
    }

    const newItem = {
      ...values,
      id: this.state.id,
      year: year,
      paymentDate: paymentDate,
      homeAllowanceDate: homeAllowanceDate,
      transportationAllowanceDate: transportationAllowanceDate,
      foodAllowanceDate: foodAllowanceDate,
      overtimeDate: overtimeDate,
      commisionDate: commisionDate,
      bonusDate: bonusDate,
      businessTripDate: businessTripDate,
      otherPaymentsDate: otherPaymentsDate
    };
    var response = null;
    if (newItem.id === null) {
      if (this.state.applyToAllMonths) {
        response = await handleRequest("POST", "/api/" + this.state.controllerName + "/applyToAllMonths", newItem);
      } else {
        response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
      }
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  fillAllowanceDate(values, year, month, day, allowance, allowanceDayMonth, allowanceTypeDayMonth, allowanceNextMonthWithSalary) {
    var allowanceDate = null;
    if (values[allowance] === true || values[allowanceNextMonthWithSalary] === "true") {
      allowanceDate = moment(year + "-" + month + "-" + day);
    } else {
      allowanceDate = moment(year + "-" + month + "-" + day);
      if (
        Boolean(values[allowanceDayMonth]) &&
        values[allowanceDayMonth] > 0 &&
        Boolean(values[allowanceTypeDayMonth]) &&
        values[allowanceTypeDayMonth] === "Day"
      ) {
        allowanceDate.add(values[allowanceDayMonth], "days");
      } else if (
        Boolean(values[allowanceDayMonth]) &&
        values[allowanceDayMonth] > 0 &&
        Boolean(values[allowanceTypeDayMonth]) &&
        values[allowanceTypeDayMonth] === "Month"
      ) {
        allowanceDate.add(values[allowanceDayMonth], "months");
      }
    }
    return allowanceDate;
  }

  applyToAllMonths = () => {
    this.setState(
      {
        applyToAllMonths: true
      },
      this.formRef.current.submit()
    );
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  action = record => {
    var nowYear = Number(moment().format("YYYY"));
    var nowMonth = moment().format("MM");
    let recordMonth = Number(
      Boolean(record.month)
        ? moment()
            .month(record.month)
            .format("M")
        : 0
    );
    if (nowYear > record.year || (nowYear === record.year && nowMonth > recordMonth)) {
      return;
    } else {
      return (
        <React.Fragment>
          <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>
      );
    }
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        ellipsis: true,
        width: 50,
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        ellipsis: true,
        width: 50,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: "Year",
        key: "year",
        ellipsis: true,
        width: 100,
        render: record => {
          return <Tooltip title={record.year}>{record.year}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.year) ? this.state.pagination.year : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  year: e.target.value,
                  yearColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.yearColor) ? this.state.pagination.yearColor : "#bfbfbf" }} />
        ),
        sorter: true
      },

      {
        title: <FormattedMessage id="SalarySettings.month" defaultMessage="Months" />,
        key: "month",
        ellipsis: true,
        width: 100,
        render: record => {
          return <Tooltip title={record.month}>{record.month}</Tooltip>;
        },
        ...getColumnFilter({
          inputName: "month",
          inputType: "SELECT",
          selectItems: Boolean(this.state["monthSelectItems"]) ? this.state["monthSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalarySettings.salaryPeriods" defaultMessage="Salary Periods" />,
        key: "salaryPeriods",
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.salaryPeriods}>{record.salaryPeriods}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.salaryPeriods) ? this.state.pagination.salaryPeriods : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  salaryPeriods: e.target.value,
                  salaryPeriodsColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });
                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.salaryPeriodsColor) ? this.state.pagination.salaryPeriodsColor : "#bfbfbf" }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalarySettings.paymentDate" defaultMessage="Payment Date" />,
        key: "paymentDate",
        ellipsis: true,
        width: 150,
        render: record => {
          return Boolean(record.paymentDate) ? moment(record.paymentDate).format("DD-MM-YYYY") : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.paymentDate) ? this.state.pagination.paymentDate : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  paymentDate: e.target.value,
                  paymentDateColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });
                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.paymentDateColor) ? this.state.pagination.paymentDateColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalarySettings.homeAllowance" defaultMessage="Home Allowance" />,
        key: "homeAllowance",
        ellipsis: true,
        width: 150,
        render: record => {
          return Boolean(record.homeAllowanceDate) ? moment(record.homeAllowanceDate).format("DD-MM-YYYY") : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.homeAllowance) ? this.state.pagination.homeAllowance : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  homeAllowance: e.target.value,
                  homeAllowanceColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.homeAllowanceColor) ? this.state.pagination.homeAllowanceColor : "#bfbfbf" }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalarySettings.transportationAllowance" defaultMessage="Transportation Allowance" />,
        key: "transportationAllowance",
        ellipsis: true,
        width: 150,
        render: record => {
          return Boolean(record.transportationAllowanceDate) ? moment(record.transportationAllowanceDate).format("DD-MM-YYYY") : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.transportationAllowance) ? this.state.pagination.transportationAllowance : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  transportationAllowance: e.target.value,
                  transportationAllowanceColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.transportationAllowanceColor) ? this.state.pagination.transportationAllowanceColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalarySettings.foodAllowance" defaultMessage="Food Allowance" />,
        key: "foodAllowance",
        ellipsis: true,
        width: 150,
        render: record => {
          return Boolean(record.foodAllowanceDate) ? moment(record.foodAllowanceDate).format("DD-MM-YYYY") : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.foodAllowance) ? this.state.pagination.foodAllowance : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  foodAllowance: e.target.value,
                  foodAllowanceColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.foodAllowanceColor) ? this.state.pagination.foodAllowanceColor : "#bfbfbf" }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalarySettings.overtime" defaultMessage="Overtime" />,
        key: "overtime",
        ellipsis: true,
        width: 100,
        render: record => {
          return Boolean(record.overtimeDate) ? moment(record.overtimeDate).format("DD-MM-YYYY") : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.overtime) ? this.state.pagination.overtime : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  overtime: e.target.value,
                  overtimeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.overtimeColor) ? this.state.pagination.overtimeColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalarySettings.commision" defaultMessage="Commision" />,
        key: "commision",
        ellipsis: true,
        width: 100,
        render: record => {
          return Boolean(record.commisionDate) ? moment(record.commisionDate).format("DD-MM-YYYY") : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.commision) ? this.state.pagination.commision : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  commision: e.target.value,
                  commisionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.commisionColor) ? this.state.pagination.commisionColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalarySettings.bonus" defaultMessage="Bonus" />,
        key: "bonus",
        ellipsis: true,
        width: 100,
        render: record => {
          return Boolean(record.bonusDate) ? moment(record.bonusDate).format("DD-MM-YYYY") : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.bonus) ? this.state.pagination.bonus : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  bonus: e.target.value,
                  bonusColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.bonusColor) ? this.state.pagination.bonusColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalarySettings.businessTrip" defaultMessage="Business Trip" />,
        key: "businessTrip",
        ellipsis: true,
        width: 100,
        render: record => {
          return Boolean(record.businessTripDate) ? moment(record.businessTripDate).format("DD-MM-YYYY") : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.businessTrip) ? this.state.pagination.businessTrip : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  businessTrip: e.target.value,
                  businessTripColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.businessTripColor) ? this.state.pagination.businessTripColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalarySettings.otherPayments" defaultMessage="Other Payments" />,
        key: "otherPayments",
        ellipsis: true,
        width: 100,
        render: record => {
          return Boolean(record.otherPaymentsDate) ? moment(record.otherPaymentsDate).format("DD-MM-YYYY") : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.otherPayments) ? this.state.pagination.otherPayments : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  otherPayments: e.target.value,
                  otherPaymentsColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.otherPaymentsColor) ? this.state.pagination.otherPaymentsColor : "#bfbfbf" }}
          />
        ),
        sorter: true
      },
      {
        title: "Last Update",
        key: "lastUpdateDate",
        ellipsis: true,
        width: 100,
        render: record => {
          return <Tooltip title={record.date}>{moment(record.lastUpdated).format("DD-MM-YYYY HH:mm")}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={21}></Col>
            <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="SalarySettings.year" defaultMessage="Year" />}
                name="year"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <DatePicker picker="year" format="YYYY" style={{ width: "100%" }} />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="SalarySettings.month" defaultMessage="Month" />}
                name="month"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} placeholder="Month">
                  {Boolean(this.state.monthSelectItems) &&
                    this.state.monthSelectItems.length > 0 &&
                    this.state.monthSelectItems.map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="SalarySettings.paymentType" defaultMessage="Payment Type" />}
                name="paymentType"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Radio.Group
                  onChange={e => {
                    this.setState({
                      paymentType: e.target.value
                    });
                  }}
                >
                  {Boolean(this.state.paymentTypesSelectItems) &&
                    this.state.paymentTypesSelectItems.length > 0 &&
                    this.state.paymentTypesSelectItems.map(i => <Radio value={i.key}>{i.value}</Radio>)}
                </Radio.Group>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="SalarySettings.salaryPeriod" defaultMessage="Salary Period" />}
                style={{ marginBottom: "5px" }}
              >
                <Row gutter={8}>
                  <Col span={10}>
                    <Form.Item
                      name="salaryPeriodMin"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber
                        style={{
                          width: "100%"
                        }}
                        min={0}
                        max={31}
                        onChange={value => {
                          var salaryPeriodMax = null;
                          if (Boolean(value) && value > 0) {
                            salaryPeriodMax = value - 1;
                            if (value === 1) {
                              let year = Boolean(this.formRef.current.getFieldValue("year"))
                                ? moment(this.formRef.current.getFieldValue("year")).format("YYYY")
                                : null;
                              let month = Boolean(this.formRef.current.getFieldValue("month"))
                                ? moment()
                                    .month(this.formRef.current.getFieldValue("month"))
                                    .format("M")
                                : null;
                              var end = moment().format("YYYY-MM-") + moment().daysInMonth();
                              if (Boolean(year) && year > 0 && Boolean(month) && month > 0) {
                                end =
                                  moment(year + "-" + month + "-" + "01").format("YYYY-MM-") + moment(year + "-" + month + "-" + "01").daysInMonth();
                              }
                              salaryPeriodMax = moment(end).format("DD");
                            }
                          }
                          this.formRef.current.setFieldsValue({
                            salaryPeriodMax: salaryPeriodMax
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>To</Col>
                  <Col span={10}>
                    <Form.Item
                      name="salaryPeriodMax"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber
                        disabled={true}
                        style={{
                          width: "100%"
                        }}
                        min={0}
                        max={31}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="SalarySettings.paymentDay" defaultMessage="Payment Day" />}
                style={{ marginBottom: "5px" }}
              >
                <Row gutter={8}>
                  <Col span={5}>
                    <Form.Item
                      name="paymentDayType"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }}>
                        {Boolean(this.state.paymentDayTypesSelectItems) &&
                          this.state.paymentDayTypesSelectItems.length > 0 &&
                          this.state.paymentDayTypesSelectItems.map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      name="paymentDay"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select
                        allowClear
                        style={{ width: "100%" }}
                        onChange={value => {
                          this.setState({
                            paymentDay: value
                          });
                          this.formRef.current.setFieldsValue({
                            ofMonthWeek: null
                          });
                        }}
                      >
                        {Boolean(this.state.paymentDaysSelectItems) &&
                          this.state.paymentDaysSelectItems.length > 0 &&
                          this.state.paymentDaysSelectItems.map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {this.state.paymentDay === "Every" && (
                    <>
                      <Col span={4}>
                        <Form.Item name="ofMonthWeek">
                          <InputNumber
                            style={{
                              width: "100%"
                            }}
                            min={0}
                            max={28}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        {"of "} {Boolean(this.state.paymentType) && this.state.paymentType === "Monthly" ? "month" : "week"}
                      </Col>
                    </>
                  )}
                  <Col span={7}>
                    <Form.Item name="inFinancialWorkingDays" valuePropName="checked">
                      <Checkbox>in financial working days</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="SalarySettings.homeAllowance" defaultMessage="Home Allowance" />}
                style={{ marginBottom: "5px" }}
              >
                <Row gutter={8}>
                  <Col span={6}>
                    <Form.Item name="homeAllowance" valuePropName="checked">
                      <Switch
                        checkedChildren="Included"
                        unCheckedChildren="Not included"
                        onChange={checked => {
                          if (checked) {
                            this.formRef.current.setFieldsValue({
                              homeAllowanceDayMonth: null,
                              homeAllowanceTypeDayMonth: null,
                              homeAllowanceNextMonthWithSalary: false
                            });
                            this.setState({
                              homeAllowance: checked,
                              homeAllowanceNextMonthWithSalary: false
                            });
                          } else {
                            this.formRef.current.setFieldsValue({
                              homeAllowanceNextMonthWithSalary: true
                            });
                            this.setState({
                              homeAllowance: checked,
                              homeAllowanceNextMonthWithSalary: true
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {!this.state.homeAllowance && (
                    <>
                      <Col span={4}>
                        <Form.Item name="homeAllowanceNextMonthWithSalary" valuePropName="checked">
                          <Checkbox
                            onChange={e => {
                              this.setState({
                                homeAllowanceNextMonthWithSalary: e.target.checked
                              });
                              if (!e.target.checked) {
                                this.formRef.current.setFieldsValue({
                                  homeAllowanceDayMonth: null,
                                  homeAllowanceTypeDayMonth: null
                                });
                              }
                            }}
                          >
                            Next month with salary
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      {!this.state.homeAllowanceNextMonthWithSalary && (
                        <>
                          <Col span={4}>
                            <Form.Item name="homeAllowanceDayMonth">
                              <InputNumber
                                style={{
                                  width: "100%"
                                }}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item name="homeAllowanceTypeDayMonth">
                              <Select allowClear style={{ width: "100%" }}>
                                {Boolean(this.state.dayMonthsSelectItems) &&
                                  this.state.dayMonthsSelectItems.length > 0 &&
                                  this.state.dayMonthsSelectItems.map(i => (
                                    <Option key={i.key} value={i.key}>
                                      {i.value}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item>After salary payment day</Form.Item>
                          </Col>
                        </>
                      )}
                    </>
                  )}
                </Row>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="SalarySettings.transportationAllowance" defaultMessage="Transportation Allowance" />}
                style={{ marginBottom: "5px" }}
              >
                <Row gutter={8}>
                  <Col span={6}>
                    <Form.Item name="transportationAllowance" valuePropName="checked">
                      <Switch
                        checkedChildren="Included"
                        unCheckedChildren="Not included"
                        onChange={checked => {
                          if (checked) {
                            this.formRef.current.setFieldsValue({
                              transportationAllowanceDayMonth: null,
                              transportationAllowanceTypeDayMonth: null,
                              transportationAllowanceNextMonthWithSalary: false
                            });
                            this.setState({
                              transportationAllowance: checked,
                              transportationAllowanceNextMonthWithSalary: false
                            });
                          } else {
                            this.formRef.current.setFieldsValue({
                              transportationAllowanceNextMonthWithSalary: true
                            });
                            this.setState({
                              transportationAllowance: checked,
                              transportationAllowanceNextMonthWithSalary: true
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {!this.state.transportationAllowance && (
                    <>
                      <Col span={4}>
                        <Form.Item name="transportationAllowanceNextMonthWithSalary" valuePropName="checked">
                          <Checkbox
                            onChange={e => {
                              this.setState({
                                transportationAllowanceNextMonthWithSalary: e.target.checked
                              });
                              if (!e.target.checked) {
                                this.formRef.current.setFieldsValue({
                                  transportationAllowanceDayMonth: null,
                                  transportationAllowanceTypeDayMonth: null
                                });
                              }
                            }}
                          >
                            Next month with salary
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      {!this.state.transportationAllowanceNextMonthWithSalary && (
                        <>
                          <Col span={4}>
                            <Form.Item name="transportationAllowanceDayMonth">
                              <InputNumber
                                style={{
                                  width: "100%"
                                }}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item name="transportationAllowanceTypeDayMonth">
                              <Select allowClear style={{ width: "100%" }}>
                                {Boolean(this.state.dayMonthsSelectItems) &&
                                  this.state.dayMonthsSelectItems.length > 0 &&
                                  this.state.dayMonthsSelectItems.map(i => (
                                    <Option key={i.key} value={i.key}>
                                      {i.value}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item>After salary payment day</Form.Item>
                          </Col>
                        </>
                      )}
                    </>
                  )}
                </Row>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="SalarySettings.foodAllowance" defaultMessage="Food Allowance" />}
                style={{ marginBottom: "5px" }}
              >
                <Row gutter={8}>
                  <Col span={6}>
                    <Form.Item name="foodAllowance" valuePropName="checked">
                      <Switch
                        checkedChildren="Included"
                        unCheckedChildren="Not included"
                        onChange={checked => {
                          if (checked) {
                            this.formRef.current.setFieldsValue({
                              foodAllowanceDayMonth: null,
                              foodAllowanceTypeDayMonth: null,
                              foodAllowanceNextMonthWithSalary: false
                            });
                            this.setState({
                              foodAllowance: checked,
                              foodAllowanceNextMonthWithSalary: false
                            });
                          } else {
                            this.formRef.current.setFieldsValue({
                              foodAllowanceNextMonthWithSalary: true
                            });
                            this.setState({
                              foodAllowance: checked,
                              foodAllowanceNextMonthWithSalary: true
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {!this.state.foodAllowance && (
                    <>
                      <Col span={4}>
                        <Form.Item name="foodAllowanceNextMonthWithSalary" valuePropName="checked">
                          <Checkbox
                            onChange={e => {
                              this.setState({
                                foodAllowanceNextMonthWithSalary: e.target.checked
                              });
                              if (!e.target.checked) {
                                this.formRef.current.setFieldsValue({
                                  foodAllowanceDayMonth: null,
                                  foodAllowanceTypeDayMonth: null
                                });
                              }
                            }}
                          >
                            Next month with salary
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      {!this.state.foodAllowanceNextMonthWithSalary && (
                        <>
                          <Col span={4}>
                            <Form.Item name="foodAllowanceDayMonth">
                              <InputNumber
                                style={{
                                  width: "100%"
                                }}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item name="foodAllowanceTypeDayMonth">
                              <Select allowClear style={{ width: "100%" }}>
                                {Boolean(this.state.dayMonthsSelectItems) &&
                                  this.state.dayMonthsSelectItems.length > 0 &&
                                  this.state.dayMonthsSelectItems.map(i => (
                                    <Option key={i.key} value={i.key}>
                                      {i.value}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item>After salary payment day</Form.Item>
                          </Col>
                        </>
                      )}
                    </>
                  )}
                </Row>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="SalarySettings.overtime" defaultMessage="Overtime" />}
                style={{ marginBottom: "5px" }}
              >
                <Row gutter={8}>
                  <Col span={6}>
                    <Form.Item name="overtime" valuePropName="checked">
                      <Switch
                        checkedChildren="Included"
                        unCheckedChildren="Not included"
                        onChange={checked => {
                          if (checked) {
                            this.formRef.current.setFieldsValue({
                              overtimeDayMonth: null,
                              overtimeTypeDayMonth: null,
                              overtimeNextMonthWithSalary: false
                            });
                            this.setState({
                              overtime: checked,
                              overtimeNextMonthWithSalary: false
                            });
                          } else {
                            this.formRef.current.setFieldsValue({
                              overtimeNextMonthWithSalary: true
                            });
                            this.setState({
                              overtime: checked,
                              overtimeNextMonthWithSalary: true
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {!this.state.overtime && (
                    <>
                      <Col span={4}>
                        <Form.Item name="overtimeNextMonthWithSalary" valuePropName="checked">
                          <Checkbox
                            onChange={e => {
                              this.setState({
                                overtimeNextMonthWithSalary: e.target.checked
                              });
                              if (!e.target.checked) {
                                this.formRef.current.setFieldsValue({
                                  overtimeDayMonth: null,
                                  overtimeTypeDayMonth: null
                                });
                              }
                            }}
                          >
                            Next month with salary
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      {!this.state.overtimeNextMonthWithSalary && (
                        <>
                          <Col span={4}>
                            <Form.Item name="overtimeDayMonth">
                              <InputNumber
                                style={{
                                  width: "100%"
                                }}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item name="overtimeTypeDayMonth">
                              <Select allowClear style={{ width: "100%" }}>
                                {Boolean(this.state.dayMonthsSelectItems) &&
                                  this.state.dayMonthsSelectItems.length > 0 &&
                                  this.state.dayMonthsSelectItems.map(i => (
                                    <Option key={i.key} value={i.key}>
                                      {i.value}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item>After salary payment day</Form.Item>
                          </Col>
                        </>
                      )}
                    </>
                  )}
                </Row>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="SalarySettings.commision" defaultMessage="Commision" />}
                style={{ marginBottom: "5px" }}
              >
                <Row gutter={8}>
                  <Col span={6}>
                    <Form.Item name="commision" valuePropName="checked">
                      <Switch
                        checkedChildren="Included"
                        unCheckedChildren="Not included"
                        onChange={checked => {
                          if (checked) {
                            this.formRef.current.setFieldsValue({
                              commisionDayMonth: null,
                              commisionTypeDayMonth: null,
                              commisionNextMonthWithSalary: false
                            });
                            this.setState({
                              commision: checked,
                              commisionNextMonthWithSalary: false
                            });
                          } else {
                            this.formRef.current.setFieldsValue({
                              commisionNextMonthWithSalary: true
                            });
                            this.setState({
                              commision: checked,
                              commisionNextMonthWithSalary: true
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {!this.state.commision && (
                    <>
                      <Col span={4}>
                        <Form.Item name="commisionNextMonthWithSalary" valuePropName="checked">
                          <Checkbox
                            onChange={e => {
                              this.setState({
                                commisionNextMonthWithSalary: e.target.checked
                              });
                              if (!e.target.checked) {
                                this.formRef.current.setFieldsValue({
                                  commisionDayMonth: null,
                                  commisionTypeDayMonth: null
                                });
                              }
                            }}
                          >
                            Next month with salary
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      {!this.state.commisionNextMonthWithSalary && (
                        <>
                          <Col span={4}>
                            <Form.Item name="commisionDayMonth">
                              <InputNumber
                                style={{
                                  width: "100%"
                                }}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item name="commisionTypeDayMonth">
                              <Select allowClear style={{ width: "100%" }}>
                                {Boolean(this.state.dayMonthsSelectItems) &&
                                  this.state.dayMonthsSelectItems.length > 0 &&
                                  this.state.dayMonthsSelectItems.map(i => (
                                    <Option key={i.key} value={i.key}>
                                      {i.value}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item>After salary payment day</Form.Item>
                          </Col>
                        </>
                      )}
                    </>
                  )}
                </Row>
              </Form.Item>
            }
            {
              <Form.Item {...layout2} label={<FormattedMessage id="SalarySettings.bonus" defaultMessage="Bonus" />} style={{ marginBottom: "5px" }}>
                <Row gutter={8}>
                  <Col span={6}>
                    <Form.Item name="bonus" valuePropName="checked">
                      <Switch
                        checkedChildren="Included"
                        unCheckedChildren="Not included"
                        onChange={checked => {
                          if (checked) {
                            this.formRef.current.setFieldsValue({
                              bonusDayMonth: null,
                              bonusTypeDayMonth: null,
                              bonusNextMonthWithSalary: false
                            });
                            this.setState({
                              bonus: checked,
                              bonusNextMonthWithSalary: false
                            });
                          } else {
                            this.formRef.current.setFieldsValue({
                              bonusNextMonthWithSalary: true
                            });
                            this.setState({
                              bonus: checked,
                              bonusNextMonthWithSalary: true
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {!this.state.bonus && (
                    <>
                      <Col span={4}>
                        <Form.Item name="bonusNextMonthWithSalary" valuePropName="checked">
                          <Checkbox
                            onChange={e => {
                              this.setState({
                                bonusNextMonthWithSalary: e.target.checked
                              });
                              if (!e.target.checked) {
                                this.formRef.current.setFieldsValue({
                                  bonusDayMonth: null,
                                  bonusTypeDayMonth: null
                                });
                              }
                            }}
                          >
                            Next month with salary
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      {!this.state.bonusNextMonthWithSalary && (
                        <>
                          <Col span={4}>
                            <Form.Item name="bonusDayMonth">
                              <InputNumber
                                style={{
                                  width: "100%"
                                }}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item name="bonusTypeDayMonth">
                              <Select allowClear style={{ width: "100%" }}>
                                {Boolean(this.state.dayMonthsSelectItems) &&
                                  this.state.dayMonthsSelectItems.length > 0 &&
                                  this.state.dayMonthsSelectItems.map(i => (
                                    <Option key={i.key} value={i.key}>
                                      {i.value}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item>After salary payment day</Form.Item>
                          </Col>
                        </>
                      )}
                    </>
                  )}
                </Row>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="SalarySettings.businessTrip" defaultMessage="Business Trip" />}
                style={{ marginBottom: "5px" }}
              >
                <Row gutter={8}>
                  <Col span={6}>
                    <Form.Item name="businessTrip" valuePropName="checked">
                      <Switch
                        checkedChildren="Included"
                        unCheckedChildren="Not included"
                        onChange={checked => {
                          if (checked) {
                            this.formRef.current.setFieldsValue({
                              businessTripDayMonth: null,
                              businessTripTypeDayMonth: null,
                              businessTripNextMonthWithSalary: false
                            });
                            this.setState({
                              businessTrip: checked,
                              businessTripNextMonthWithSalary: false
                            });
                          } else {
                            this.formRef.current.setFieldsValue({
                              businessTripNextMonthWithSalary: true
                            });
                            this.setState({
                              businessTrip: checked,
                              businessTripNextMonthWithSalary: true
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {!this.state.businessTrip && (
                    <>
                      <Col span={4}>
                        <Form.Item name="businessTripNextMonthWithSalary" valuePropName="checked">
                          <Checkbox
                            onChange={e => {
                              this.setState({
                                businessTripNextMonthWithSalary: e.target.checked
                              });
                              if (!e.target.checked) {
                                this.formRef.current.setFieldsValue({
                                  businessTripDayMonth: null,
                                  businessTripTypeDayMonth: null
                                });
                              }
                            }}
                          >
                            Next month with salary
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      {!this.state.businessTripNextMonthWithSalary && (
                        <>
                          <Col span={4}>
                            <Form.Item name="businessTripDayMonth">
                              <InputNumber
                                style={{
                                  width: "100%"
                                }}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item name="businessTripTypeDayMonth">
                              <Select allowClear style={{ width: "100%" }}>
                                {Boolean(this.state.dayMonthsSelectItems) &&
                                  this.state.dayMonthsSelectItems.length > 0 &&
                                  this.state.dayMonthsSelectItems.map(i => (
                                    <Option key={i.key} value={i.key}>
                                      {i.value}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item>After salary payment day</Form.Item>
                          </Col>
                        </>
                      )}
                    </>
                  )}
                </Row>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="SalarySettings.otherPayments" defaultMessage="Other Payments" />}
                style={{ marginBottom: "5px" }}
              >
                <Row gutter={8}>
                  <Col span={6}>
                    <Form.Item name="otherPayments" valuePropName="checked">
                      <Switch
                        checkedChildren="Included"
                        unCheckedChildren="Not included"
                        onChange={checked => {
                          if (checked) {
                            this.formRef.current.setFieldsValue({
                              otherPaymentsDayMonth: null,
                              otherPaymentsTypeDayMonth: null,
                              otherPaymentsNextMonthWithSalary: false
                            });
                            this.setState({
                              otherPayments: checked,
                              otherPaymentsNextMonthWithSalary: false
                            });
                          } else {
                            this.formRef.current.setFieldsValue({
                              otherPaymentsNextMonthWithSalary: true
                            });
                            this.setState({
                              otherPayments: checked,
                              otherPaymentsNextMonthWithSalary: true
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {!this.state.otherPayments && (
                    <>
                      <Col span={4}>
                        <Form.Item name="otherPaymentsNextMonthWithSalary" valuePropName="checked">
                          <Checkbox
                            onChange={e => {
                              this.setState({
                                otherPaymentsNextMonthWithSalary: e.target.checked
                              });
                              if (!e.target.checked) {
                                this.formRef.current.setFieldsValue({
                                  otherPaymentsDayMonth: null,
                                  otherPaymentsTypeDayMonth: null
                                });
                              }
                            }}
                          >
                            Next month with salary
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      {!this.state.otherPaymentsNextMonthWithSalary && (
                        <>
                          <Col span={4}>
                            <Form.Item name="otherPaymentsDayMonth">
                              <InputNumber
                                style={{
                                  width: "100%"
                                }}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item name="otherPaymentsTypeDayMonth">
                              <Select allowClear style={{ width: "100%" }}>
                                {Boolean(this.state.dayMonthsSelectItems) &&
                                  this.state.dayMonthsSelectItems.length > 0 &&
                                  this.state.dayMonthsSelectItems.map(i => (
                                    <Option key={i.key} value={i.key}>
                                      {i.value}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item>After salary payment day</Form.Item>
                          </Col>
                        </>
                      )}
                    </>
                  )}
                </Row>
              </Form.Item>
            }
            <div hidden={this.state.hideSave}>
              {
                <>
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="ApplyToAllMonthsButton" onClick={this.applyToAllMonths} style={{ width: "100%" }} variant="primary">
                        <FormattedMessage id="ApplyToAllMonthsButton" defaultMessage="Apply to all months" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                  <br />
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                </>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SalarySettings);
