import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Tooltip, Drawer, Cascader, InputNumber, DatePicker, Card, Input } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined, Settings } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../../excel/ExcelReader";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import { Select } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment/moment";

const { Option } = Select;

const dataListMaterialType = [];
const generateListMaterialType = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataListMaterialType.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateListMaterialType(node.children);
    }
  }
};

const dataListPurchaseCriteria = [];
const generateListPurchaseCriteria = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataListPurchaseCriteria.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateListPurchaseCriteria(node.children);
    }
  }
};

export class BiddingLogQuotationLaborShip extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "BiddingLogQuotationLaborShip",
      controllerName: "biddingLogQuotationLaborShip",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      type: null,
      categoryId: -1,
      descriptionId: -1,
      materialTypeId: -1,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      countrySelectItems: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responsedisciplineId = await handleRequest("GET", "/api/" + this.state.controllerName + "/discipline");
    if (responsedisciplineId.type === "ERROR") {
      error(responsedisciplineId);
    }

    var responsecategoryId = await handleRequest("GET", "/api/" + this.state.controllerName + "/category");
    if (responsecategoryId.type === "ERROR") {
      error(responsecategoryId);
    }

    var responseactivityId = await handleRequest("GET", "/api/" + this.state.controllerName + "/activityId");
    if (responseactivityId.type === "ERROR") {
      error(responseactivityId);
    }

    var responsecurrency = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responsecurrency.type === "ERROR") {
      error(responsecurrency);
    }

    this.setState({
      disciplineIdSelectItems: Boolean(responsedisciplineId.data) ? responsedisciplineId.data : [],
      categoryIdSelectItems: Boolean(responsecategoryId.data) ? responsecategoryId.data : [],
      activityIdSelectItems: Boolean(responseactivityId.data) ? responseactivityId.data : [],
      currencySelectItems: Boolean(responsecurrency.data) ? responsecurrency.data : [],
    });
  };

  fillMaterialType = async () => {
    const { categoryId, descriptionId } = this.state;
    if (Boolean(categoryId) && categoryId > 0 && Boolean(descriptionId) && descriptionId > 0) {
      const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/tree/" + categoryId + "/" + descriptionId);
      if (responseTree.data.length !== 0) {
        generateListMaterialType(responseTree.data);
        this.setState({
          nodes: responseTree.data
        });
      }
    } else {
      this.setState({
        nodes: []
      });
    }
  };

  getPurchaseCriteria = async value => {
    if (Boolean(value)) {
      let responsePurchaseCriteria = await handleRequest("GET", "/api/" + this.state.controllerName + "/purchasingcriterias/" + value);
      if (responsePurchaseCriteria.type === "ERROR") {
      } else {
        generateListPurchaseCriteria(responsePurchaseCriteria.data);
        this.setState({
          purchasingcriteriaSelectItems: Boolean(responsePurchaseCriteria.data) && responsePurchaseCriteria.data.length > 0 ? responsePurchaseCriteria.data : []
        });
      }
    } else {
      this.setState({
        purchasingcriteriaSelectItems: []
      });
    }
  };


  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = async (row) => {
    document.getElementById("kt_scrolltop").click();

    this.fillService(row.disciplineId);
    this.fillComponent(row.compound);
    this.getPurchaseCriteria(row.itemId);

    this.setState({
      type: row.type,
      categoryId: row.categoryId,
      descriptionId: row.descriptionId,
      materialTypeId: row.materialTypeId,
      serviceId: row.serviceId,
    }, () => {
      this.getStockCardItem();
      this.fillMaterialType();
      this.fillCompounds();
    });

    await this.onChangeItemGroupId(row.categoryId);

    var materialType = dataListMaterialType.find(p => p.key === row.materialTypeId);
    let materialTypeId = [];
    if (Boolean(materialType)) {
      materialTypeId = materialType.lineage;
    }

    var unit = dataListPurchaseCriteria.find(p => p.key === row.unitId);
    let unitId = [];
    if (Boolean(unit)) {
      unitId = unit.lineage;
    }

    var itemIds = [];
    if (Boolean(row.items) && row.items.length > 0) {
      row.items.forEach(element => {
        itemIds.push(element.stockCardId);
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      materialTypeId: materialTypeId,
      unitId: unitId,
      itemIds: itemIds,
      effectiveDate: Boolean(row.effectiveDate) ? moment(row.effectiveDate) : null,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  projectDocumentsModal = row => {
    this.setState({
      modalProjectDocumentsShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };

    newItem.materialTypeId =
      Boolean(values.materialTypeId) && values.materialTypeId.length > 0 ? values.materialTypeId[values.materialTypeId.length - 1] : null;

    newItem.unitId =
      Boolean(values.unitId) && values.unitId.length > 0 ? values.unitId[values.unitId.length - 1] : null;

    let items = [];
    if (newItem.itemIds && newItem.itemIds.length > 0)
      newItem.itemIds.forEach(element => {
        items.push({ stockCardId: element });
      });
    newItem.items = items;

    console.log("newItem", newItem);
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  onChangeItemGroupId = async value => {
    if (Boolean(value)) {
      var responseitemDescriptionId = await handleRequest("GET", "/api/" + this.state.controllerName + "/description/" + value);
      if (responseitemDescriptionId.type === "ERROR") {
        error(responseitemDescriptionId);
        this.setState({
          descriptionIdSelectItems: [],
          descriptionId: null
        });
        this.formRef.current.setFieldsValue({
          descriptionId: null
        });
      } else {
        this.setState({
          descriptionIdSelectItems: Boolean(responseitemDescriptionId.data) ? responseitemDescriptionId.data : [],
          descriptionId: null
        });
        this.formRef.current.setFieldsValue({
          descriptionId: null
        });
      }
    } else {
      this.setState({
        descriptionIdSelectItems: [],
        descriptionId: null
      });
      this.formRef.current.setFieldsValue({
        descriptionId: null
      });
    }
  };

  fillService = async value => {
    if (Boolean(value)) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/service/" + value);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          serviceIdSelectItems: [],
        });
      } else {
        this.setState({
          serviceIdSelectItems: Boolean(response.data) ? response.data : [],
        });
      }
    } else {
      this.setState({
        serviceIdSelectItems: [],
      });
    }
  };

  fillCompounds = async () => {
    const { serviceId } = this.state;
    if (Boolean(serviceId)) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/compound/" + serviceId);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          compoundIdSelectItems: [],
        });
      } else {
        this.setState({
          compoundIdSelectItems: Boolean(response.data) ? response.data : [],
        });
      }
    } else {
      this.setState({
        compoundIdSelectItems: [],
      });
    }
  };

  fillComponent = async (value) => {
    if (Boolean(value)) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/component/" + value);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          componentIdSelectItems: [],
        });
      } else {
        this.setState({
          componentIdSelectItems: Boolean(response.data) ? response.data : [],
        });
      }
    } else {
      this.setState({
        componentIdSelectItems: [],
      });
    }
  };

  getStockCardItem = async () => {
    const { type, categoryId, descriptionId, materialTypeId } = this.state;
    var newObj = {
      type: type,
      categoryId: categoryId,
      descriptionSelectId: descriptionId,
      materialTypeId: materialTypeId,
    };
    let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/getStockCardItem", newObj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        itemIdSelectItems: Boolean(response.data) ? response.data : [],
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };

    const columns = [
      { key: "key", render: this.action },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationLaborShip.Discipline" defaultMessage="Discipline" />,
        key: "disciplineId",
        render: record => {
          return record.disciplineName;
        },
        ...getColumnFilter({
          inputName: "disciplineId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["disciplineIdSelectItems"]) ? this.state["disciplineIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationLaborShip.Service" defaultMessage="Service" />,
        key: "serviceId",
        render: record => {
          return record.serviceName;
        },
        ...getColumnFilter({
          inputName: "serviceId",
          inputType: "CASCADER",
          selectItems: Boolean(this.state["serviceIdSelectItems"]) ? this.state["serviceIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationLaborShip.Compound" defaultMessage="Compound" />,
        key: "compound",
        render: record => {
          return record.compound;
        },
        ...getColumnFilter({
          inputName: "compound",
          inputType: "SELECT",
          selectItems: Boolean(this.state["compoundIdSelectItems"]) ? this.state["compoundIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationLaborShip.Component" defaultMessage="Component" />,
        key: "component",
        render: record => {
          return record.componentName;
        },
        ...getColumnFilter({
          inputName: "component",
          inputType: "SELECT",
          selectItems: Boolean(this.state["componentIdSelectItems"]) ? this.state["componentIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationLaborShip.Activity" defaultMessage="Activity" />,
        key: "activityId",
        render: record => {
          return record.activityName;
        },
        ...getColumnFilter({
          inputName: "activityId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["activityIdSelectItems"]) ? this.state["activityIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationLaborShip.MaterialCategory" defaultMessage="Material Category" />,
        key: "categoryId",
        render: record => {
          return record.categoryName;
        },
        ...getColumnFilter({
          inputName: "categoryId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["categoryIdSelectItems"]) ? this.state["categoryIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationLaborShip.ItemDescription" defaultMessage="Item Description" />,
        key: "descriptionId",
        render: record => {
          return record.descriptionName;
        },
        ...getColumnFilter({
          inputName: "descriptionId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["descriptionIdSelectItems"]) ? this.state["descriptionIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationLaborShip.MaterialType" defaultMessage="Material Type" />,
        key: "materialTypeId",
        render: record => {
          return record.materialTypeName;
        },
        ...getColumnFilter({
          inputName: "materialTypeId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["materialTypeIdSelectItems"]) ? this.state["materialTypeIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationLaborShip.Item" defaultMessage="Item" />,
        key: "itemId",
        render: record => {
          return record.itemName;
        },
        ...getColumnFilter({
          inputName: "itemId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["itemIdSelectItems"]) ? this.state["itemIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationLaborShip.CostRatio" defaultMessage="Cost Ratio" />,
        key: "costRatio",
        render: record => {
          let value = [];
          if (Boolean(record.costRatio)) {
            value.push(record.costRatio);
          }
          if (Boolean(record.currency)) {
            value.push(record.currency);
          }
          if (Boolean(record.unitName)) {
            value.push(record.unitName);
          }
          return value.join(" ");
        },
        ...getColumnFilter({
          inputName: "costRatio",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLogQuotationLaborShip.EffectiveDate" defaultMessage="Effective Date" />,
        key: "effectiveDate",
        render: record => {
          return Boolean(record.effectiveDate) ? moment(record.effectiveDate).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "effectiveDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="BiddingLogQuotationLaborShip" hideElement={true}>
            <ExcelSheet name="BiddingLogQuotationLaborShip" data={this.state.tableList}>
              <ExcelColumn label="BiddingLogQuotationLaborShip" value="biddingLogQuotationLaborShip" />
              <ExcelColumn label="Rev No" value="revNo" />
              <ExcelColumn label="Date" value="date" />
              <ExcelColumn label="Status" value="status" />
              <ExcelColumn label="Client" value="clientName" />
              <ExcelColumn label="Contractor" value="contractorName" />
              <ExcelColumn label="Project Description" value="projectDescription" />
              <ExcelColumn label="Client Project No" value="clientProjectNo" />
              <ExcelColumn label="Project Scope" value="projectScope" />
              <ExcelColumn label="Project Details" value="projectDetails" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="BiddingLogQuotationLaborship.pageTitle" defaultMessage="Laborship Cost" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={20}></Col> <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
              <Col md={1}>
                {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                  <Tooltip title={<FormattedMessage id="BiddingLogQuotationLaborship.Settings" defaultMessage="Settings" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => {
                        this.setState({ drawerSettingsShow: true });
                      }}
                      icon={<Settings color="error" fontSize="large" />}
                    ></AntButton>
                  </Tooltip>
                )}
              </Col>
            </Row>
            <br></br>

            <div hidden={this.state.hideInputs}>
              <Row gutter={16} style={{ marginBottom: "16px" }}>

                <br></br>
                <br></br>
                <br></br>
                {/* Sol Card */}
                <Col span={1}> </Col>
                <Col span={11}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="BiddingLogQuotationLaborship.Discipline" defaultMessage="Discipline" />}
                        name="disciplineId"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children"
                          onChange={value => {
                            this.setState(
                              {
                                disciplineId: value
                              }, () => {
                                this.fillService(value);
                              }
                            );
                          }}>
                          {Boolean(this.state["disciplineIdSelectItems"]) &&
                            this.state["disciplineIdSelectItems"].length > 0 &&
                            this.state["disciplineIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="BiddingLogQuotationLaborship.Service" defaultMessage="Service" />}
                        name="serviceId"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children"
                          onChange={value => {
                            this.setState({
                              serviceId: value
                            }, () => {
                              this.fillCompounds();
                            });
                          }}
                        >
                          {Boolean(this.state["serviceIdSelectItems"]) &&
                            this.state["serviceIdSelectItems"].length > 0 &&
                            this.state["serviceIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="BiddingLogQuotationLaborship.Compound" defaultMessage="Compound" />}
                        name="compound"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children"
                          onChange={value => {
                            this.setState({
                              compound: value
                            }, () => {
                              this.fillComponent(value);
                            });
                          }}
                        >
                          {Boolean(this.state["compoundIdSelectItems"]) &&
                            this.state["compoundIdSelectItems"].length > 0 &&
                            this.state["compoundIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="BiddingLogQuotationLaborship.Component" defaultMessage="Component" />}
                        name="component"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                          {Boolean(this.state["componentIdSelectItems"]) &&
                            this.state["componentIdSelectItems"].length > 0 &&
                            this.state["componentIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    }
                  </Card>
                </Col>

                {/* Sağ Card */}
                <Col span={11}>
                  <Card
                    style={{

                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >

                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="BiddingLogQuotationLaborship.Category" defaultMessage="Category" />}
                        name="categoryId"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select
                          showSearch
                          allowClear
                          optionFilterProp="children"
                          style={{ width: "100%" }}
                          onChange={value => {
                            this.setState(
                              {
                                categoryId: value
                              },
                              () => {
                                this.onChangeItemGroupId(value);
                                this.getStockCardItem();
                                this.fillMaterialType();
                              }
                            );
                          }}
                        >
                          {Boolean(this.state["categoryIdSelectItems"]) &&
                            this.state["categoryIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                                {i.description !== null && i.description !== "" && (
                                  <img src={i.description} alt={i.value} style={{ width: "30px", marginLeft: "10px" }} />
                                )}
                              </Option>
                            ))}{" "}
                        </Select>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="BiddingLogQuotationLaborship.Description" defaultMessage="Description" />}
                        name="descriptionId"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select
                          showSearch
                          allowClear
                          optionFilterProp="children"
                          onChange={value => {
                            this.setState(
                              {
                                descriptionId: value
                              },
                              () => {
                                this.getStockCardItem();
                                this.fillMaterialType();
                              }
                            );
                          }}
                          style={{ width: "100%" }}
                        >
                          {Boolean(this.state["descriptionIdSelectItems"]) &&
                            this.state["descriptionIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}{" "}
                        </Select>
                      </Form.Item>
                    }

                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="BiddingLogQuotationLaborship.MaterialType" defaultMessage="Material Type" />}
                        name="materialTypeId"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Cascader
                          showSearch
                          allowClear
                          optionFilterProp="children"
                          style={{ width: "100%" }}
                          onChange={value => {
                            this.setState(
                              {
                                materialTypeId: Boolean(value) && value.length > 0 ? value[value.length - 1] : null
                              },
                              () => {
                                this.getStockCardItem();
                              }
                            );
                          }}
                          options={this.state.nodes}
                        />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="BiddingLogQuotationLaborship.Item" defaultMessage="Item" />}
                        name="itemId"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select showSearch allowClear style={{ width: "100%" }} optionFilterProp="children"
                          onChange={value => {
                            this.getPurchaseCriteria(value);
                          }}
                        >
                          {Boolean(this.state["itemIdSelectItems"]) &&
                            this.state["itemIdSelectItems"].length > 0 &&
                            this.state["itemIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    }


                  </Card>
                </Col>
                <Col span={1}></Col>
              </Row>
            </div>

            <br></br>

            <div hidden={this.state.hideInputs}>
              <Row gutter={16} style={{ marginBottom: "16px" }}>
                <Col span={1}> </Col>
                <Col span={22}>
                  <Card
                    style={{

                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="BiddingLogQuotationLaborship.Laborship" defaultMessage="Laborship Definition" />}
                        name="definition"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    }

                    {
                      <Form.Item
                        {...layout2}
                        label={<FormattedMessage id="BiddingLogQuotationLaborship.CostRatio" defaultMessage="Cost Ratio" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Row gutter={8}>
                          <Col span={3}>
                            <Form.Item
                              name="costRatio"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item
                              name="currency"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <Select showSearch allowClear style={{ width: "100%" }} optionFilterProp="children">
                                {Boolean(this.state["currencySelectItems"]) &&
                                  this.state["currencySelectItems"].length > 0 &&
                                  this.state["currencySelectItems"].map(i => (
                                    <Option key={i.key} value={i.key}>
                                      {i.value}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              name="unitId"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <Cascader showSearch allowClear optionFilterProp="children" style={{ width: '100%' }} options={this.state.purchasingcriteriaSelectItems} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    }


                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="BiddingLogQuotationLaborship.EffectiveDate" defaultMessage="Effective Date" />}
                        name="effectiveDate"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm:ss" showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }} />
                      </Form.Item>
                    }


                  </Card>
                </Col>
              </Row>

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>

          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <Drawer
            title={<FormattedMessage id="BiddingLogQuotationLaborship.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ drawerSettingsShow: false });
            }}
            open={this.state.drawerSettingsShow}
          >
            <PageSettings pageName={"BiddingLogQuotationLaborship"} />
          </Drawer>
        </div>
      </Badge.Ribbon >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BiddingLogQuotationLaborShip);