import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import { Form, Col, Row, Button as AntButton, Badge, Upload, InputNumber, Modal as AntModal, Input, Tag, Radio, Cascader } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Select } from "antd";
import { DatePicker } from "antd";
import moment from "moment";
import { UploadOutlined, MinusCircleOutlined, PlusOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Calendar } from "antd";
import "./event.css";
import store from "../../../redux/store";
import ToDoGantt from "./ToDoGantt";

const { Option } = Select;
const { TextArea } = Input;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class ToDo extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ToDo",
      controllerName: "toDo",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      fileList: [],
      myTasks: [],
      user: store.getState().auth.user,
      processType: "calendar",
    };
  }

  formRef = React.createRef();
  formRefConfirmation = React.createRef();

  componentDidMount = async () => {
    this.restartTable();
    this.fillComboboxes();
    console.log("user", this.state.user);
  };

  fillComboboxes = async () => {
    var responseUser = await handleRequest("GET", "/api/" + this.state.controllerName + "/users");
    if (responseUser.type === "ERROR") {
      error(responseUser);
    }
    this.setState({
      actionBySelectItems: Boolean(responseUser.data) ? responseUser.data : [],
      reviewBySelectItems: Boolean(responseUser.data) ? responseUser.data : [],
      informToSelectItems: Boolean(responseUser.data) ? responseUser.data : []
    });

    var responsecompanyPersonnel = await handleRequest("GET", "/api/" + this.state.controllerName + "/companyPersonnel");
    if (responsecompanyPersonnel.type === "ERROR") {
      error(responsecompanyPersonnel);
    }
    this.setState({
      companyPersonnelSelectItems: Boolean(responsecompanyPersonnel.data) ? responsecompanyPersonnel.data : []
    });

    var responsestatus = await handleRequest("GET", "/api/" + this.state.controllerName + "/status");
    if (responsestatus.type === "ERROR") {
      error(responsestatus);
    }
    this.setState({
      statusSelectItems: Boolean(responsestatus.data) ? responsestatus.data : []
    });

    var responseconfirmationId = await handleRequest("GET", "/api/" + this.state.controllerName + "/confirmationId");
    if (responseconfirmationId.type === "ERROR") {
      error(responseconfirmationId);
    }
    this.setState({
      confirmationIdSelectItems: Boolean(responseconfirmationId.data) ? responseconfirmationId.data : []
    });

    var responseMyTasks = await handleRequest("GET", "/api/" + this.state.controllerName + "/myTasks");
    if (responseMyTasks.type === "ERROR") {
      error(responseMyTasks);
    }
    if (responseMyTasks.data.length !== 0) {
      console.log(responseMyTasks.data);

      generateList(responseMyTasks.data);
      this.setState({
        myTasks: responseMyTasks.data
      });
    }
  };

  restartTable = async date => {
    let firstDayOfMonth = moment()
      .clone()
      .startOf("month");
    let lastDayOfMonth = moment()
      .clone()
      .endOf("month");
    if (Boolean(date)) {
      firstDayOfMonth = moment(date)
        .clone()
        .startOf("month");
      lastDayOfMonth = moment(date)
        .clone()
        .endOf("month");
    }
    let newObj = {
      startDate: firstDayOfMonth,
      endDate: lastDayOfMonth
    };
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/toDoByDate", newObj);
    if (response.type === "ERROR") {
      error(response);
    }
    this.setState({
      tableList: Boolean(response.data) && response.data.length > 0 ? response.data : []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = [];
    let fileUrl = Boolean(row.fileUrl) ? row.fileUrl.split(",") : [];
    if (Boolean(fileUrl) && fileUrl.length > 0) {
      fileUrl.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };
        fileList.push(obj);
      });
    }

    var dtoActionBy = [];
    if (Boolean(row.dtoToDoActions) && row.dtoToDoActions.length > 0) {
      row.dtoToDoActions.forEach(element => {
        dtoActionBy.push(element.userId);
      });
    }

    var dtoInformTo = [];
    if (Boolean(row.dtoToDoInformTos) && row.dtoToDoInformTos.length > 0) {
      row.dtoToDoInformTos.forEach(element => {
        dtoInformTo.push(element.userId);
      });
    }

    var dtoReviewBy = [];
    if (Boolean(row.dtoToDoReviewBies) && row.dtoToDoReviewBies.length > 0) {
      row.dtoToDoReviewBies.forEach(element => {
        dtoReviewBy.push(element.userId);
      });
    }

    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.setFieldsValue({
        ...row,
        startDate: Boolean(row.startDate) ? moment(row.startDate) : null,
        endDate: Boolean(row.endDate) ? moment(row.endDate) : null,
        dtoActionBy: dtoActionBy,
        dtoInformTo: dtoInformTo,
        dtoReviewBy: dtoReviewBy
      });
    }

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      fileList: fileList
    });
  };

  confirmationPage = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = [];
    let fileUrl = Boolean(row.fileUrl) ? row.fileUrl.split(",") : [];
    if (Boolean(fileUrl) && fileUrl.length > 0) {
      fileUrl.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };
        fileList.push(obj);
      });
    }

    var dtoActionBy = [];
    if (Boolean(row.dtoToDoActions) && row.dtoToDoActions.length > 0) {
      row.dtoToDoActions.forEach(element => {
        dtoActionBy.push(element.userId);
      });
    }

    var dtoInformTo = [];
    if (Boolean(row.dtoToDoInformTos) && row.dtoToDoInformTos.length > 0) {
      row.dtoToDoInformTos.forEach(element => {
        dtoInformTo.push(element.userId);
      });
    }

    var dtoReviewBy = [];
    if (Boolean(row.dtoToDoReviewBies) && row.dtoToDoReviewBies.length > 0) {
      row.dtoToDoReviewBies.forEach(element => {
        dtoReviewBy.push(element.userId);
      });
    }

    if (Boolean(this.formRefConfirmation) && Boolean(this.formRefConfirmation.current)) {
      this.formRefConfirmation.current.setFieldsValue({
        ...row,
        startDate: Boolean(row.startDate) ? moment(row.startDate) : null,
        endDate: Boolean(row.endDate) ? moment(row.endDate) : null,
        dtoActionBy: dtoActionBy,
        dtoInformTo: dtoInformTo,
        dtoReviewBy: dtoReviewBy
      });
    }

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      fileList: fileList
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.resetFields();

      this.formRef.current.setFieldsValue({
        status: "On_Going",
        singleStepped: "Single",
      });
    }
    this.setState({
      id: null,
      workStationId: null,
      next: false,
      previous: false,
      startDate: null,
      modalStepDate: null,
      singleStepped: "Single",

      loading: false,
      sortField: "",
      sortOrder: "",
      fileList: []
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      modalFormShow: false,
      modalConfirmationShow: false,

      hideInputs: true,
      hideSave: true,
      hideUpdate: false
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    console.log("values", values);
    const newItem = {
      ...values,
      id: this.state.id,
      fileUrl: Boolean(this.state.fileList) && this.state.fileList.length > 0 ? this.state.fileList[0].url : ""
    };

    let dtoToDoActions = [];
    if (newItem.dtoActionBy && newItem.dtoActionBy.length > 0) {
      newItem.dtoActionBy.forEach(element => {
        dtoToDoActions.push({ userId: element });
      });
    }
    newItem.dtoToDoActions = dtoToDoActions;

    let dtoToDoInformTos = [];
    if (newItem.dtoInformTo && newItem.dtoInformTo.length > 0) {
      newItem.dtoInformTo.forEach(element => {
        dtoToDoInformTos.push({ userId: element });
      });
    }
    newItem.dtoToDoInformTos = dtoToDoInformTos;

    let dtoToDoReviewBies = [];
    if (newItem.dtoReviewBy && newItem.dtoReviewBy.length > 0) {
      newItem.dtoReviewBy.forEach(element => {
        dtoToDoReviewBies.push({ userId: element });
      });
    }
    newItem.dtoToDoReviewBies = dtoToDoReviewBies;

    let dtoSubTasks = [];
    if (newItem.dtoSubTasks && newItem.dtoSubTasks.length > 0) {
      newItem.dtoSubTasks.forEach(sub => {

        let dtoActionBies = [];
        if (sub.dtoActionBy && sub.dtoActionBy.length > 0) {
          sub.dtoActionBy.forEach(element => {
            dtoActionBies.push({ userId: element });
          });
        }
        sub.dtoActionBies = dtoActionBies;

        let dtoInformTos = [];
        if (sub.dtoInformTo && sub.dtoInformTo.length > 0) {
          sub.dtoInformTo.forEach(element => {
            dtoInformTos.push({ userId: element });
          });
        }
        sub.dtoInformTos = dtoInformTos;
        // dtoSubTasks.fileUrl = Boolean(this.state.fileList) && this.state.fileList.length > 0 ? this.state.fileList[0].url : ""
        dtoSubTasks.push(sub);
      });
    }
    newItem.dtoSubTasks = dtoSubTasks;



    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      this.restartTable();
      showSuccess();
      this.setState({
        modalFormShow: false,

        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  saveConfirmation = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      fileUrl: Boolean(this.state.fileList) && this.state.fileList.length > 0 ? this.state.fileList[0].url : ""
    };

    let dtoToDoActions = [];
    if (newItem.dtoActionBy && newItem.dtoActionBy.length > 0) {
      newItem.dtoActionBy.forEach(element => {
        dtoToDoActions.push({ userId: element });
      });
    }
    newItem.dtoToDoActions = dtoToDoActions;

    let dtoToDoInformTos = [];
    if (newItem.dtoInformTo && newItem.dtoInformTo.length > 0) {
      newItem.dtoInformTo.forEach(element => {
        dtoToDoInformTos.push({ userId: element });
      });
    }
    newItem.dtoToDoInformTos = dtoToDoInformTos;

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveConfirmation", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateConfirmation/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      this.restartTable();
      showSuccess();
      this.setState({
        modalConfirmationShow: false
      });
    }
  };

  dateCellRender = value => {
    const tableList = this.state.tableList.filter(p => moment(p.endDate) >= value && moment(p.startDate) <= value);
    console.log("tableList", tableList);
    return (
      <ul className="events">
        {Boolean(tableList) && tableList.length > 0 && (
          <li onClick={() => this.addNewClick(value)}>
            <Tag color={"blue"}>{"Add New"}</Tag>
          </li>
        )}
        {Boolean(tableList) &&
          tableList.length > 0 &&
          tableList.map(item => (
            <Tooltip
              color={"white"}
              title={
                <div className="w-100" style={{ color: "black" }}>
                  <div>
                    {moment(item.startDate).format("DD-MM-YYYY")} - {moment(item.endDate).format("DD-MM-YYYY")}
                  </div>
                  <br />
                  <div>
                    {item.task} - {item.description}
                  </div>
                  <br />
                  <div>{item.toDoActionsNames}</div>
                  <br />
                  <div>{<Tag color={this.getStatus(item.status)}>{item.statusName}</Tag>}</div>
                </div>
              }
            >
              <li key={item.task} onClick={() => this.onClickDetail(item)}>
                <Badge status={this.getStatus(item.status)} text={item.task} />
              </li>
              <li key={item.task} onClick={() => this.modalConfirmationDetail(item)}>
                <Badge status={this.getStatus(item.status)} text={item.task} />
              </li>
            </Tooltip>
          ))}
      </ul>
    );
  };

  getStatus = status => {
    if (Boolean(status)) {
      if (status === "On_Going" || status === "Delay") {  //Devam Ediyor
        return "processing";
      }
      else if (status === "Generated" || status === "New_Task") {   //Yeni Görev
        return "processing";
      }
      else if (status === "Response" || status === "Action_Start" || status === "Rejected"
        || status === "Completed") {   //Cevap
        return "warning";
      } else if (status === "Done") {   //Tamamlandı
        return "success";
      } else if (status === "Hold") {    //Askıya Alındı
        return "default";
      } else if (status === "Deleted") {  //Silindi
        return "error";
      }
    }
    return "error";
  };

  onClickDetail = row => {
    this.setState(
      {
        modalFormShow: true
      },
      () => {
        this.resetInputs();
        this.edit(row);
      }
    );
  };

  onPanelChangeClick = (value, mode) => {
    this.setState({
      currentDate: value
    }, () => {
      this.restartTable(value);
    });
  };

  handleSelectClick = date => {
    if (!this.state.currentDate) {
      const tableList = this.state.tableList.filter(p => moment(p.endDate) >= date && moment(p.startDate) <= date);
      this.setState(
        {
          modalFormShow: true
        },
        () => {
          if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
            if (!(Boolean(tableList) && tableList.length > 0)) {
              this.onClickCreateNew();
              this.formRef.current.setFieldsValue({
                startDate: Boolean(date) ? moment(date).startOf("day") : moment().startOf("day"),
                endDate: Boolean(date) ? moment(date).endOf("day") : moment().endOf("day")
              });
            }
          }
          // Confirmation
          if (Boolean(this.formRefConfirmation) && Boolean(this.formRefConfirmation.current)) {
            if (!(Boolean(tableList) && tableList.length > 0)) {
              this.onClickCreateNew();
              this.formRefConfirmation.current.setFieldsValue({
                startDate: Boolean(date) ? moment(date).startOf("day") : moment().startOf("day"),
                endDate: Boolean(date) ? moment(date).endOf("day") : moment().endOf("day")
              });
            }
          }
        }
      );
    }
  };

  // Confirmation
  modalConfirmationDetail = row => {
    this.setState(
      {
        modalConfirmationShow: true
      },
      () => {
        this.resetInputs();
        this.confirmationPage(row);
      }
    );
  };

  addNewClick = date => {
    this.setState(
      {
        modalConfirmationShow: true
      }, () => {
        if (Boolean(this.formRefConfirmation) && Boolean(this.formRefConfirmation.current)) {
          this.onClickCreateNew();
          this.formRefConfirmation.current.setFieldsValue({
            startDate: Boolean(date) ? moment(date).startOf("day") : moment().startOf("day"),
            endDate: Boolean(date) ? moment(date).endOf("day") : moment().endOf("day"),
            assigned: Boolean(this.state.user) ? this.state.user.name + " " + this.state.user.surname : "",
          });
        }
      }
    );
  };


  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    function customHeaderRender({ value, type, onChange, onTypeChange }) {
      const onPrev = () => {
        if (type === 'date') {
          onChange(value.clone().subtract(1, 'month'));
        } else if (type === 'month') {
          onChange(value.clone().subtract(1, 'year'));
        } else if (type === 'year') {
          onChange(value.clone().subtract(10, 'year'));
        }
      };

      const onNext = () => {
        if (type === 'date') {
          onChange(value.clone().add(1, 'month'));
        } else if (type === 'month') {
          onChange(value.clone().add(1, 'year'));
        } else if (type === 'year') {
          onChange(value.clone().add(10, 'year'));
        }
      };

      return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <LeftOutlined onClick={onPrev} style={{ cursor: 'pointer' }} />
          {type === 'year' ? (
            <span>{value.format('YYYY')}</span>
          ) : (
            <span>{value.format('MMMM YYYY')}</span>
          )}
          <RightOutlined onClick={onNext} style={{ cursor: 'pointer' }} />
        </div>
      );
    }

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="toDo.pageTitle" defaultMessage="To Do List" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>

          <Row gutter={[16, 16]}>
            <Col md={17}></Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={() => {
                  this.setState(
                    {
                      modalFormShow: true
                    },
                    () => {
                      this.onClickCreateNew();
                    }
                  )
                }}
                icon={<CreateIcon color="secondary" fontSize="large" />}>
              </AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
            <Col md={3}>
              <Cascader style={{ width: "100%" }} options={this.state.myTasks} />
            </Col>
            <Col md={3}>
              <Select value={this.state.processType} style={{ width: "100%" }} showSearch optionFilterProp="children"
                onChange={e => {
                  this.setState({
                    processType: e
                  });
                }}>
                <Option key={"calendar"} value={"calendar"}>
                  {"Calendar"}
                </Option>
                <Option key={"gantt"} value={"gantt"}>
                  {"Gantt"}
                </Option>
              </Select>
            </Col>
          </Row>
          <br />
          <Row gutter={[16, 16]}>
            <Col md={24}>
              {this.state.processType === "calendar" ?
                <Calendar mode="date" headerRender={customHeaderRender} dateCellRender={this.dateCellRender} onPanelChange={this.onPanelChangeClick}
                  onSelect={date => {
                    this.setState({
                      currentDate: null
                    }, () => {
                      this.handleSelectClick(date);
                    });
                  }}
                />
                :
                <ToDoGantt />
              }
            </Col>
          </Row>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>{" "}
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <AntModal
            title={<FormattedMessage id="ToDo.pageTitle" defaultMessage="To Do List" />}
            open={this.state.modalFormShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState({
                modalFormShow: false,

                hideInputs: true,
                hideSave: true,
                hideUpdate: false
              });
            }}
            width={1300}
            centered
          >
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.task" defaultMessage="Task" />}
                  name="task"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.description" defaultMessage="Description" />}
                  name="description"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <TextArea style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.Catergory" defaultMessage="Catergory" />}
                  name="companyPersonnel"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} showSearch optionFilterProp="children">
                    {Boolean(this.state["companyPersonnelSelectItems"]) &&
                      this.state["companyPersonnelSelectItems"].length > 0 &&
                      this.state["companyPersonnelSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.Type" defaultMessage="Type" />}
                  name="singleStepped"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Radio.Group value={this.state.singleStepped}
                    onChange={e => {
                      this.setState({
                        singleStepped: e.target.value
                      });

                      if (Boolean(e.target.value) && e.target.value === "Single") {
                        this.formRef.current.setFieldsValue({
                          dtoSubTasks: [],
                        });
                      }
                    }}
                  >
                    <Radio value={"Single"}>Single</Radio>
                    <Radio value={"Stepped"}>Stepped</Radio>
                  </Radio.Group>
                </Form.Item>
              }

              {Boolean(this.state.singleStepped) && this.state.singleStepped === "Stepped" &&
                <Form.List name="dtoSubTasks">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ToDo.SubTask"} defaultMessage={"Sub Task"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ToDo.Start"} defaultMessage={"Start"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ToDo.End"} defaultMessage={"End"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ToDo.ActionBy"} defaultMessage={"Action By"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ToDo.Inform To"} defaultMessage={"Inform To"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ToDo.File"} defaultMessage={"File"} />
                              </th>
                              <th colSpan="1">
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "task"]}>
                                      <Input style={{ width: "100%" }} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "startDate"]}>
                                      <DatePicker showTime style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "endDate"]}>
                                      <DatePicker showTime style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "dtoActionBy"]}>
                                      <Select allowClear style={{ width: "100%" }} showSearch optionFilterProp="children" mode="multiple">
                                        {Boolean(this.state["actionBySelectItems"]) &&
                                          this.state["actionBySelectItems"].length > 0 &&
                                          this.state["actionBySelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}{" "}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "dtoInformTo"]}>
                                      <Select allowClear style={{ width: "100%" }} showSearch optionFilterProp="children" mode="multiple">
                                        {Boolean(this.state["informToSelectItems"]) &&
                                          this.state["informToSelectItems"].length > 0 &&
                                          this.state["informToSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}{" "}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "fileUrl"]}>
                                      <Upload
                                        {...props}
                                        showUploadList={true}
                                        fileList={this.state.fileList}
                                        onChange={({ fileList }) => {
                                          if (fileList.length === 1) {
                                            if (Boolean(fileList[0].response)) {
                                              fileList[0].url = fileList[0].response.url;
                                              showSuccess("file uploaded successfully");
                                            }
                                            this.setState({
                                              fileList: fileList
                                            });
                                          }
                                        }}
                                        beforeUpload={fileList => {
                                          if (this.state.fileList.length > 0) {
                                            showError("You have been already choose a file! For change firstly remove the uploaded file.");
                                            return false;
                                          }
                                          return true;
                                        }}
                                        onRemove={fileList => {
                                          this.setState({
                                            fileList: []
                                          });
                                        }}
                                      >
                                        <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                          <UploadOutlined /> Upload
                                        </AntButton>
                                      </Upload>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"ToDo.AddNew"} defaultMessage={"Add New"} />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.startDate" defaultMessage="Start Date" />}
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker showTime style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.endDate" defaultMessage="End Date" />}
                  name="endDate"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker showTime style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.File" defaultMessage="File" />}
                  name="fileUrl"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Upload
                    {...props}
                    showUploadList={true}
                    fileList={this.state.fileList}
                    onChange={({ fileList }) => {
                      if (fileList.length === 1) {
                        if (Boolean(fileList[0].response)) {
                          fileList[0].url = fileList[0].response.url;
                          showSuccess("file uploaded successfully");
                        }
                        this.setState({
                          fileList: fileList
                        });
                      }
                    }}
                    beforeUpload={fileList => {
                      if (this.state.fileList.length > 0) {
                        showError("You have been already choose a file! For change firstly remove the uploaded file.");
                        return false;
                      }
                      return true;
                    }}
                    onRemove={fileList => {
                      this.setState({
                        fileList: []
                      });
                    }}
                  >
                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> Upload
                    </AntButton>
                  </Upload>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.dtoReviewBy" defaultMessage="Review By" />}
                  name="dtoReviewBy"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} showSearch optionFilterProp="children" mode="multiple">
                    {Boolean(this.state["reviewBySelectItems"]) &&
                      this.state["reviewBySelectItems"].length > 0 &&
                      this.state["reviewBySelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.dtoActionBy" defaultMessage="Action By" />}
                  name="dtoActionBy"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} showSearch optionFilterProp="children" mode="multiple">
                    {Boolean(this.state["actionBySelectItems"]) &&
                      this.state["actionBySelectItems"].length > 0 &&
                      this.state["actionBySelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.dtoInformTo" defaultMessage="Inform To" />}
                  name="dtoInformTo"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} showSearch optionFilterProp="children" mode="multiple">
                    {Boolean(this.state["informToSelectItems"]) &&
                      this.state["informToSelectItems"].length > 0 &&
                      this.state["informToSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.Status" defaultMessage="Status" />}
                  name="status"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} showSearch optionFilterProp="children">
                    {Boolean(this.state["statusSelectItems"]) &&
                      this.state["statusSelectItems"].length > 0 &&
                      this.state["statusSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </Form>
          </AntModal>

          <AntModal
            title={<FormattedMessage id="ToDo.ToDoListConfirmation" defaultMessage="To Do List Confirmation" />}
            open={this.state.modalConfirmationShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ modalConfirmationShow: false })}
            width={1300}
            centered
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveConfirmation} onFinishFailed={onFinishFailed} ref={this.formRefConfirmation}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.AssignmentNo" defaultMessage="Assignment No" />}
                  name="assignmentNo"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.AssignedBy" defaultMessage="Assigned by" />}
                  name="assigned"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} readOnly />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.task" defaultMessage="Task" />}
                  name="task"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.description" defaultMessage="Description" />}
                  name="description"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <TextArea style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.startDate" defaultMessage="Start Date" />}
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.endDate" defaultMessage="End Date" />}
                  name="endDate"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.File" defaultMessage="File" />}
                  name="fileUrl"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Upload
                    {...props}
                    showUploadList={true}
                    fileList={this.state.fileList}
                    onChange={({ fileList }) => {
                      if (fileList.length === 1) {
                        if (Boolean(fileList[0].response)) {
                          fileList[0].url = fileList[0].response.url;
                          showSuccess("file uploaded successfully");
                        }
                        this.setState({
                          fileList: fileList
                        });
                      }
                    }}
                    beforeUpload={fileList => {
                      if (this.state.fileList.length > 0) {
                        showError("You have been already choose a file! For change firstly remove the uploaded file.");
                        return false;
                      }
                      return true;
                    }}
                    onRemove={fileList => {
                      this.setState({
                        fileList: []
                      });
                    }}
                  >
                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> Upload
                    </AntButton>
                  </Upload>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.dtoActionBy" defaultMessage="Action By" />}
                  name="dtoActionBy"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} showSearch optionFilterProp="children" mode="multiple">
                    {Boolean(this.state["actionBySelectItems"]) &&
                      this.state["actionBySelectItems"].length > 0 &&
                      this.state["actionBySelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.dtoInformTo" defaultMessage="Inform To" />}
                  name="dtoInformTo"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} showSearch optionFilterProp="children" mode="multiple">
                    {Boolean(this.state["informToSelectItems"]) &&
                      this.state["informToSelectItems"].length > 0 &&
                      this.state["informToSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.Confirmation" defaultMessage="Confirmation" />}
                  name="confirmationId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["confirmationIdSelectItems"]) &&
                      this.state["confirmationIdSelectItems"].length > 0 &&
                      this.state["confirmationIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.Justification" defaultMessage="Justification" />}
                  name="justification"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.File" defaultMessage="File" />}
                  name="fileUrl"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Upload
                    {...props}
                    showUploadList={true}
                    fileList={this.state.fileList}
                    onChange={({ fileList }) => {
                      if (fileList.length === 1) {
                        if (Boolean(fileList[0].response)) {
                          fileList[0].url = fileList[0].response.url;
                          showSuccess("file uploaded successfully");
                        }
                        this.setState({
                          fileList: fileList
                        });
                      }
                    }}
                    beforeUpload={fileList => {
                      if (this.state.fileList.length > 0) {
                        showError("You have been already choose a file! For change firstly remove the uploaded file.");
                        return false;
                      }
                      return true;
                    }}
                    onRemove={fileList => {
                      this.setState({
                        fileList: []
                      });
                    }}
                  >
                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> Upload
                    </AntButton>
                  </Upload>
                </Form.Item>
              }

              {/* {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ToDo.Status" defaultMessage="Status" />}
                  name="status"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} showSearch optionFilterProp="children">
                    {Boolean(this.state["statusSelectItems"]) &&
                      this.state["statusSelectItems"].length > 0 &&
                      this.state["statusSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              } */}

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  // <Row gutter={[16, 16]}>
                  //   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  //   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  //     <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                  //       <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                  //     </Button>
                  //   </Col>
                  //   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  //     <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                  //       <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                  //     </Button>
                  //   </Col>
                  //   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  // </Row>
                }
              </div>
            </Form>
          </AntModal>
        </div >
      </Badge.Ribbon >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ToDo);
