import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { EditOutlined } from "@material-ui/icons";
import { showError, showSuccess, error } from "../../../MessageHelper";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Form, Table, Input, Button as AntButton, Col, Row, Tooltip, Checkbox } from "antd";
import RevisionIcon from "@material-ui/icons/ViewList";
import DeleteIcon from "@material-ui/icons/Delete";
import { handleRequest } from "../../../ApiConnector";
import CreateIcon from "@material-ui/icons/AddCircle";
import { Select } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import DraggableModal from "../../../Components/DraggableModal";
import PageGenerateField from "./PageGenerateField";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { CloseCircleOutlined, FilterOutlined } from "@ant-design/icons";

const { Option } = Select;

export class PageGenerateComponent extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "pageGenerateComponent",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      policyPath: "",
      nodes: [],
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    fillFilterOperations();
    this.getFolders();
    this.getEntities();
    var responseType = await handleRequest("GET", "/api/" + this.state.controllerName + "/type");
    if (responseType.type === "ERROR") {
      error(responseType);
    }
    this.setState({
      typeSelectItems: Boolean(responseType.data) ? responseType.data : []
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      projectId: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      policyPath: ""
    });
  };

  setSelectedFiles = field => {
    let fileList = [];
    if (Boolean(field) && field.length > 0) {
      let files = field.split(",");
      for (let i = 0; i < files.length; i++) {
        fileList.push({
          uid: i,
          name: files[i],
          url: files[i],
          thumbUrl: files[i]
        });
      }
      return fileList;
    }
    return [];
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      type: row.type
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  detailIconModal = row => {
    this.setState({
      modalFieldShow: true,
      id: row.id,
      selectedRow: row,
      random: Math.random()
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      pageGenerateId: this.props.pageGenerateId
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      pageGenerateId: this.props.pageGenerateId,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          if (Array.isArray(element))
            element.forEach(element2 => {
              let obj = { columns: element2 };
              list.push(obj);
            });
          else {
            let obj = { columns: element };
            list.push(obj);
          }
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/importDynamicaly", list);
          if (response.type === "ERROR") {
            error(response);
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          error("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let detailAction = {
      name: "Field",
      icon: <RevisionIcon fontSize="small" color="secondary" />,
      actionClick: this.detailIconModal.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(detailAction);
    actionList.push(deleteAction);
    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  showReport = async rowData => {
    try {
      if (rowData.policyPath !== null && rowData.policyPath !== undefined && rowData.policyPath !== "") {
        const fileRealUrl = rowData.policyPath;
        window.location.href = fileRealUrl;
      } else {
        this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
      }
    } catch (error) {
      this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
    }
  };

  getFolders = async () => {
    const responseFolder = await handleRequest("GET", "/ApplicationSettings/getDomainFolders");
    if (Boolean(responseFolder.data) && responseFolder.data.length > 0) {
      this.setState({
        folderSelectItems: responseFolder.data
      });
    }
  };

  getEntities = async folder => {
    if (!Boolean(folder)) {
      let response = await handleRequest("GET", "/api/pageForm/getEntitiesFromLocalDomain");
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          entitySelectItems: response.data
        });
      }
    } else {
      let response = await handleRequest("GET", "/api/pageForm/getEntitiesFromFolderDomain/" + folder);
      console.log(response);
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          entitySelectItems: response.data
        });
      }
    }
  };

  onChangeFolder = async value => {
    let folder = value;
    if (!Boolean(folder)) {
      let response = await handleRequest("GET", "/api/pageForm/getEntitiesFromLocalDomain");
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          entitySelectItems: response.data
        });
      }
    } else {
      let response = await handleRequest("GET", "/api/pageForm/getEntitiesFromFolderDomain/" + folder);

      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          entitySelectItems: response.data
        });
      }
    }
    this.setState({
      folder: folder
    });
  };

  getEntityFields = async (entity, callback) => {
    let { folder } = this.state;
    let url = "/api/" + this.state.controllerName + "/bymodeldto/" + entity;
    if (Boolean(folder)) {
      url = "/api/" + this.state.controllerName + "/bymodeldtoandfolder/" + entity + "/" + folder;
    }
    const response = await handleRequest("GET", url);
    if (Boolean(response.data) && response.data.length > 0) {
      const data = response.data;
      data.forEach((element, index) => {
        element.rowIndex = index + 1;
        if (Boolean(element.inputProps)) {
          element.inputProps = JSON.parse(element.inputProps);
        }
        if (!Boolean(element.inputType)) {
          element.inputType = "TEXT";
          element.inputProps = this.state.inputTypeSelectItems.find(i => i.key === "TEXT").inputProps;
        }
      });

      this.setState(
        {
          dynamicFormList: data
        },
        () => {
          callback(data);
        }
      );
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="PageGenerateComponent.Name" defaultMessage="Name" />,
        key: "name",
        render: record => {
          return record.name;
        },
        ...getColumnFilter({
          inputName: "name",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="PageGenerateComponent.Type" defaultMessage="Type" />,
        key: "type",
        render: record => {
          return record.type;
        },
        ...getColumnFilter({
          inputName: "type",
          inputType: "SELECT",
          selectItems: Boolean(this.state["typeSelectItems"]) ? this.state["typeSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="PageGenerateComponent.RibbonName" defaultMessage="Ribbon Name" />,
        key: "ribbonName",
        render: record => {
          return record.ribbonName;
        },
        ...getColumnFilter({
          inputName: "ribbonName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="PageGenerateComponent.ControllerName" defaultMessage="Controller Name" />,
        key: "controllerName",
        render: record => {
          return record.controllerName;
        },
        ...getColumnFilter({
          inputName: "controllerName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="PageGenerateField.WithoutCompany" defaultMessage="Without Company" />,
        key: "withoutCompany",
        render: record => {
          return record.withoutCompany ? "True" : "False";
        }
      },
      {
        title: <FormattedMessage id="PageGenerateField.WithoutCompany" defaultMessage="Without Company" />,
        key: "withoutCompany",
        render: record => {
          return record.withoutCompany ? "True" : "False";
        }
      },
      {
        title: <FormattedMessage id="PageGenerateComponent.Entity" defaultMessage="Entity" />,
        key: "entity",
        render: record => {
          return record.entity;
        },
        ...getColumnFilter({
          inputName: "entity",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={23}></Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>
          <div hidden={this.state.hideInputs}>
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageGenerateComponent.Name" defaultMessage="Name" />}
              name="name"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageGenerateComponent.Type" defaultMessage="Type" />}
              name="type"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                style={{ width: "100%" }}
                onChange={value => {
                  this.setState({
                    type: value
                  });
                }}
              >
                {this.state.typeSelectItems &&
                  this.state.typeSelectItems.length > 0 &&
                  this.state.typeSelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            {(this.state.type === "Form" || this.state.type === "Form_No_Entry") && (
              <>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PageGenerateComponent.RibbonName" defaultMessage="Ribbon Name" />}
                  name="ribbonName"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PageGenerateComponent.ControllerName" defaultMessage="Controller Name" />}
                  name="controllerName"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PageGenerateField.WithoutCompany" defaultMessage="Without Company" />}
                  name="withoutCompany"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox></Checkbox>
                </Form.Item>
              </>
            )}

            {this.state.type === "Form_No_Entry" && (
              <>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PageAutoDesignFormInput.Folder" defaultMessage="Folder" />}
                  name="folderName"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    value={this.state.folder}
                    onChange={this.onChangeFolder}
                  >
                    {Boolean(this.state["folderSelectItems"]) &&
                      this.state["folderSelectItems"].length > 0 &&
                      this.state["folderSelectItems"].map(i => (
                        <Option key={i} value={i}>
                          {i}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PageAutoDesignFormInput.Entity" defaultMessage="Entity" />}
                  name="entity"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    placeholder="Entity Name"
                    style={{ width: "100%" }}
                    value={this.state.entity}
                  >
                    {this.state.entitySelectItems.map(i => (
                      <Option key={i} value={i}>
                        {i}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}

            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span>
                      <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span>
                      <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}

          <Table
            bordered
            rowKey="id"
            size="small"
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <DraggableModal
          title={<FormattedMessage id="PageGenerate.Field" defaultMessage="Field" />}
          open={this.state.modalFieldShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({
              modalFieldShow: false
            });
          }}
          width={1400}
          centered
          content={<PageGenerateField random={this.state.random} pageGenerateComponentId={this.state.id} type={this.state?.selectedRow?.type} />}
        />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageGenerateComponent);
