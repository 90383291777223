import { CheckOutlined, EditOutlined } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import { Button as AntButton, Col, Form, Input, InputNumber, Modal as AntModal, Row, Select } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess, showWarning } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";

const { Option } = Select;
export class RoleDescriptionTemplate extends Component {
  constructor() {
    super();
    this.state = {
      formName: "RoleDescriptionTemplate",
      controllerName: "roleDescriptionTemplate",
      templateGroupId: 1, //may need if more templates needed
      modalDeleteShow: false,
      lastUpdateDate: [],
      id: null,
      tableList: [],
      showSimilarWordsModal: false,
      currentSimilarWords: [],
      selectedObject: null,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.restartTable();
    this.fillComboboxes();
  };
  componentDidUpdate = async nextProps => {};
  fillComboboxes = async inputList => {};

  edit = row => {
    this.formRef.current.setFieldsValue({
      ...row
    });
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showSimilarWordsModal = row => {
    this.setState({
      selectedObject: row,
      currentSimilarWords: row.similarNameList,
      showSimilarWordsModal: true
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async () => {
    let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/group/" + this.state.templateGroupId);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        tableList: response.data
      });
    }
  };

  save = async values => {
    values.name = values.name.trim();
    values.similarNameList = values.similarNameList.map(item => {
      return item.trim();
    });
    if (!values.id) {
      //check if tableList containts the same name
      let found = false;
      this.state.tableList.forEach(item => {
        if (item.name === values.name) {
          found = true;
        }
      });
      if (found) {
        showWarning("Name already exists");
        return;
      }
    }
    let similars = new Set(values.similarNameList);
    values.similarNameList = Array.from(similars);

    if (!values.similarNameList.includes(values.name)) {
      values.similarNameList.push(values.name);
    }
    if (!Boolean(values.id)) {
      values.templateGroupId = this.state.templateGroupId;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", values);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + values.id, values);
    }

    if (response.type === "ERROR") {
      showError(response);
      return false;
    } else {
      this.formRef.current.resetFields();
      this.restartTable();
      showSuccess();
      return true;
    }
  };
  selectSimilarWord = async similarWord => {
    const { selectedObject } = this.state;
    var obj = { ...selectedObject };
    obj.name = similarWord;
    let res = await this.save(obj);
    if (res) {
      this.setState({
        selectedObject,
        showSimilarWordsModal: false
      });
    }
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null
    });
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Similar Words",
              icon: <SpellcheckIcon fontSize="small" color="secondary" />,
              actionClick: this.showSimilarWordsModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 12 },
      style: {
        marginBottom: 0
      }
    };
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col md={21}>
            <span className="h3">
              <FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage={"Role Description Template"} />
            </span>
          </Col>
          <Col md={1}></Col>
          <Col md={1}></Col>
          <Col md={1}>
            <AntButton
              style={{ border: "0px" }}
              hidden={!this.state.hideInputs}
              onClick={this.onClickCreateNew}
              icon={<CreateIcon color="secondary" fontSize="large" />}
            ></AntButton>
            <AntButton
              style={{ border: "0px" }}
              hidden={this.state.hideInputs}
              onClick={this.onClickCreateNew}
              icon={<RemoveCircle color="error" fontSize="large" />}
            ></AntButton>
          </Col>
        </Row>
        <div hidden={this.state.hideInputs}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Form.Item name={"id"} hidden={true}>
              <InputNumber />
            </Form.Item>
            <Form.Item name={"templateGroupId"} hidden={true}>
              <InputNumber />
            </Form.Item>
            <Form.Item
              {...layout}
              name="name"
              label={<FormattedMessage id="RoleDescripitonTemplate.name" defaultMessage="Name" />}
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              {...layout}
              label={<FormattedMessage id="RoleDescripitonTemplate.similarWords" defaultMessage="Similar Words" />}
              name="similarNameList"
            >
              <Select mode="tags" style={{ width: "100%" }} placeholder="Similar words" optionFilterProp="children"></Select>
            </Form.Item>

            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Col>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            </Row>
          </Form>
        </div>
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <ul className="list-group">
            {this.state.tableList.map(item => (
              <li key={item.id} className="list-group-item d-flex">
                <span className="flex-grow-1 h4 mb-0 pt-4">{item.name}</span>
                <span>{this.action(item)}</span>
              </li>
            ))}
          </ul>
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <AntModal
          title="Similar Words"
          visible={this.state.showSimilarWordsModal}
          onCancel={() => {
            this.setState({ showSimilarWordsModal: false });
          }}
          cancelButtonProps={{ style: { display: "none" } }}
          onOk={() => {
            this.setState({ showSimilarWordsModal: false });
          }}
          maskClosable={false}
        >
          <ul className="list-group">
            {this.state.currentSimilarWords.map((item, index) => (
              <li key={index} className="list-group-item d-flex">
                <span className={"flex-grow-1 h4 mb-0 pt-2" + (this.state.selectedObject.name === item ? " text-success" : "")}>{item}</span>
                <span>
                  <AntButton
                    disabled={this.state.selectedObject.name === item}
                    onClick={() => {
                      this.selectSimilarWord(item);
                    }}
                    icon={<CheckOutlined color="action" />}
                  >
                    {this.state.selectedObject.name === item ? "Using" : "Use"}
                  </AntButton>
                </span>
              </li>
            ))}
          </ul>
        </AntModal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RoleDescriptionTemplate);
