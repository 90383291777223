import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { PlusOutlined, DeleteFilled, EditFilled, ClockCircleOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import {
  Form,
  Timeline,
  Input,
  Avatar,
  Space,
  Collapse,
  Card,
  Select,
  Divider,
  DatePicker,
  Badge,
  Button as AntButton,
  Col,
  Row
} from "antd";
import moment from "moment";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import CreateIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Panel } = Collapse;

export class AuditLogSchedule extends Component {
  constructor() {
    super();
    this.state = {
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      showAddCustomerModal: false,
      modalAgendaTimelineShow: false,
      id: null,
      tableList: [],

      fileList: [],
      nodes: [],
      agendaBySelectItems: null,
      MeetingAgendaExpectationsSelectItems: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "" // for cascader.
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    this.fillCode("MeetingAgendaExpectations");
    this.fillCode("MeetingAgendaRequiredDocument");
    this.fillCode("MeetingAgendaAction");
  };

  fillComboboxes = async () => {
    var responseUsers = await handleRequest("GET", "/api/meetingAgenda/users");
    if (responseUsers.type === "ERROR") {
      error(responseUsers);
    } else {
      this.setState({
        agendaBySelectItems: Boolean(responseUsers.data) ? responseUsers.data : []
      });
    }
  };
  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  showCustomerModal = () => {
    console.log("showCustomerModal");
    this.setState({
      showAddCustomerModal: true
    });
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: ""
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    var expectations = [];
    if (Boolean(row.meetingAgendaExpectations) && row.meetingAgendaExpectations.length > 0) {
      row.meetingAgendaExpectations.forEach(element => {
        expectations.push(element.expectationsId);
      });
    }

    var requiredDocument = [];
    if (Boolean(row.meetingAgendaRequiredDocument) && row.meetingAgendaRequiredDocument.length > 0) {
      row.meetingAgendaRequiredDocument.forEach(element => {
        requiredDocument.push(element.requiredDocumentId);
      });
    }

    var action = [];
    if (Boolean(row.meetingAgendaAction) && row.meetingAgendaAction.length > 0) {
      row.meetingAgendaAction.forEach(element => {
        action.push(element.actionId);
      });
    }
    let time = [];
    if (Boolean(row.startTime) && Boolean(row.endTime)) {
      time.push(moment(row.startTime));
      time.push(moment(row.endTime));
    }

    this.formRef.current.setFieldsValue({
      ...row,
      meetingAgendaAction: action,
      meetingAgendaExpectations: expectations,
      meetingAgendaRequiredDocument: requiredDocument,
      time: time
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/meetingAgenda/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    if (Boolean(this.props.meetingId) && this.props.meetingId > 0) {
      const newItem = {
        ...values,
        id: this.state.id,
        meetingId: this.props.meetingId
      };

      let expectations = [];
      if (newItem.meetingAgendaExpectations && newItem.meetingAgendaExpectations.length > 0)
        newItem.meetingAgendaExpectations.forEach(element => {
          expectations.push({ expectationsId: element });
        });
      newItem.meetingAgendaExpectations = expectations;

      let action = [];
      if (newItem.meetingAgendaAction && newItem.meetingAgendaAction.length > 0)
        newItem.meetingAgendaAction.forEach(element => {
          action.push({ actionId: element });
        });
      newItem.meetingAgendaAction = action;

      let requiredDocument = [];
      if (newItem.meetingAgendaRequiredDocument && newItem.meetingAgendaRequiredDocument.length > 0)
        newItem.meetingAgendaRequiredDocument.forEach(element => {
          requiredDocument.push({ requiredDocumentId: element });
        });
      newItem.meetingAgendaRequiredDocument = requiredDocument;

      let startTime = null;
      let endTime = null;
      if (Boolean(values.time) && values.time.length === 2) {
        startTime = values.time[0];
        endTime = values.time[1];
      }
      newItem.startTime = startTime;
      newItem.endTime = endTime;

      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/meetingAgenda", newItem);
      } else {
        response = await handleRequest("PUT", "/api/meetingAgenda/" + newItem.id, newItem);
      }

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showError("Meeting not found");
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      meetingId: this.props.meetingId,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/meetingAgenda/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    return (
      <Badge.Ribbon text="Meeting Agenda" placement="start" color="purple" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "3rem", minHeight: "100%" }}>
          <Form onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={23}></Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>

            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLogSchedule.title" defaultMessage="Title" />}
                  name="title"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input placeholder="Title" style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.scope" defaultMessage="Scope" />}
                  name="scope"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("AuditLogScope")}
                          >
                            {<PlusOutlined />}
                            <FormattedMessage id="AuditLog.AddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["AuditLogScopeSelectItems"]) &&
                      this.state["AuditLogScopeSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.references" defaultMessage="References" />}
                  name="references"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("AuditLogReferences")}
                          >
                            {<PlusOutlined />}
                            <FormattedMessage id="AuditLog.AddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["AuditLogReferencesSelectItems"]) &&
                      this.state["AuditLogReferencesSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.auditee" defaultMessage="Auditee" />}
                  name="auditee"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("AuditLogAuditee")}
                          >
                            {<PlusOutlined />}
                            <FormattedMessage id="AuditLog.AddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["AuditLogAuditeeSelectItems"]) &&
                      this.state["AuditLogAuditeeSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.leadAuditor" defaultMessage="Lead Auditor" />}
                  name="leadAuditor"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("AuditLogLeadAuditor")}
                          >
                            {<PlusOutlined />}
                            <FormattedMessage id="AuditLog.AddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["AuditLogLeadAuditorSelectItems"]) &&
                      this.state["AuditLogLeadAuditorSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.auditor" defaultMessage="Auditor" />}
                  name="auditor"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("AuditLogAuditor")}
                          >
                            {<PlusOutlined />}
                            <FormattedMessage id="AuditLog.AddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["AuditLogAuditorSelectItems"]) &&
                      this.state["AuditLogAuditorSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.guider" defaultMessage="Guider" />}
                  name="guider"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("AuditLogAuditor")}
                          >
                            {<PlusOutlined />}
                            <FormattedMessage id="AuditLog.AddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["AuditLogGuiderSelectItems"]) &&
                      this.state["AuditLogGuiderSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="AuditLog.auditChecklist" defaultMessage="Audit Checklist" />}
                  name="auditChecklist"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("AuditLogAuditChecklist")}
                          >
                            {<PlusOutlined />}
                            <FormattedMessage id="AuditLog.AddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["AuditLogAuditChecklistSelectItems"]) &&
                      this.state["AuditLogAuditChecklistSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="MeetingAgenda.time" defaultMessage="Time" />}
                  name="time"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <RangePicker showTime style={{ width: "100%" }} />
                </Form.Item>
              }
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>

            <div className="card-body pt-4">
              <Badge.Ribbon text="Meeting Agenda Timeline" placement="end" color="#f759ab" style={{ fontSize: 12 }}>
                <div className="card card-custom" style={{ padding: "5rem" }}>
                  <Timeline mode="alternate">
                    {Boolean(this.state.tableList) &&
                      this.state.tableList.length > 0 &&
                      this.state.tableList.map((item, index) => {
                        return (
                          <Timeline.Item
                            dot={
                              <>
                                <br />
                                <ClockCircleOutlined
                                  style={{
                                    color: "green",
                                    fontSize: "20px"
                                  }}
                                />
                                <br />
                                <ClockCircleOutlined
                                  style={{
                                    color: "red",
                                    fontSize: "20px"
                                  }}
                                />
                              </>
                            }
                            label={
                              <>
                                {Boolean(item.startTime) ? moment(item.startTime).format("DD-MM-YYYY HH:mm") : ""}
                                <br />
                                {Boolean(item.endTime) ? moment(item.endTime).format("DD-MM-YYYY HH:mm") : ""}
                              </>
                            }
                          >
                            <Collapse expandIconPosition="right" hoverable style={{ align: "left", backgroundColor: "#efdbff" }} size="small">
                              <Panel
                                header={item.title}
                                extra={
                                  <Space>
                                    <DeleteFilled style={{ color: "#f5222d" }} onClick={() => this.deleteModal(item)} />
                                    <Divider type="vertical" />
                                    <EditFilled style={{ color: "#1890ff" }} onClick={() => this.edit(item)} />
                                    <Divider type="vertical" />
                                  </Space>
                                }
                              >
                                <Card size="small" hoverable style={{ backgroundColor: "#efdbff" }}>
                                  <p>
                                    <Avatar src={toAbsoluteUrl("/media/svg/avatars/009-boy-4.svg")} />
                                    {Boolean(item.agendaByName) ? item.agendaByName : ""}
                                  </p>

                                  <Divider dashed style={{ borderColor: "#9254de", color: "#9254de" }} orientation="left" plain>
                                    <FormattedMessage id="AuditLogSchedule.AddItem" defaultMessage="Expectations" />
                                  </Divider>
                                  {Boolean(item.meetingAgendaExpectations) &&
                                    item.meetingAgendaExpectations.length > 0 &&
                                    item.meetingAgendaExpectations.map((element, elementIndex) => {
                                      return (
                                        <div key={element.id} value={element.id}>
                                          {elementIndex + 1 + ". " + element.expectationsName}
                                        </div>
                                      );
                                    })}

                                  <Divider dashed orientation="left" style={{ borderColor: "#9254de", color: "#9254de" }} plain>
                                    <FormattedMessage id="AuditLogSchedule.AddItem" defaultMessage="Required Document" />
                                  </Divider>

                                  {Boolean(item.meetingAgendaRequiredDocument) &&
                                    item.meetingAgendaRequiredDocument.length > 0 &&
                                    item.meetingAgendaRequiredDocument.map((element, elementIndex) => {
                                      return (
                                        <div key={element.id} value={element.id}>
                                          {elementIndex + 1 + ". " + element.requiredDocumentName}
                                        </div>
                                      );
                                    })}

                                  <Divider dashed orientation="left" style={{ borderColor: "#9254de", color: "#9254de" }} plain>
                                    Action
                                  </Divider>

                                  {Boolean(item.meetingAgendaAction) &&
                                    item.meetingAgendaAction.length > 0 &&
                                    item.meetingAgendaAction.map((element, elementIndex) => {
                                      return (
                                        <div key={element.id} value={element.id}>
                                          {elementIndex + 1 + ". " + element.actionName}
                                        </div>
                                      );
                                    })}
                                </Card>
                              </Panel>
                            </Collapse>
                          </Timeline.Item>
                        );
                      })}
                  </Timeline>
                </div>
              </Badge.Ribbon>
            </div>

            <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.delete}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogSchedule);