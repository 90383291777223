import React, { useEffect, useState } from "react";
import { gantt } from "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import "./Gantt.css";
import { handleRequest } from "../../../../ApiConnector";
import { error } from "../../../../MessageHelper";

const GanttChart = () => {
  const [setTasks] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchTasks(); // Sayfa yüklendiğinde mevcut görevleri al
  }, []);

  const fetchTasks = async () => {
    // fetch(API_BASE_ROOT + '/api/apartment/getGanttChart')
    //     .then((response) => response.json())
    //     .then((data) => {
    //         setTasks(data);
    //         initializeGantt(data); // Görevleri Gantt bileşenine yükle
    //     })
    //     .catch((error) => {
    //         console.error('Görevleri alma hatası:', error);
    //     });

    var response = await handleRequest("GET", "/api/apartment/getGanttChart");
    if (response.type === "ERROR") {
      error(response);
    } else {
      let data = Boolean(response.data) ? response.data.data : null;
      console.log("data", data);
      setTasks(data);
      initializeGantt(data); // Görevleri Gantt bileşenine yükle
      setTotalPages(150);
    }
  };

  const initializeGantt = data => {
    gantt.init("gantt_container_test");
    gantt.parse({ data });
    gantt.attachEvent("onAfterTaskUpdate", handleTaskUpdate);
    gantt.attachEvent("onAfterTaskAdd", handleTaskAdd);
    gantt.attachEvent("onAfterTaskDelete", handleTaskDelete);
  };

  const handleTaskUpdate = (id, task) => {
    // Güncellenen görevi sunucuya gönder
    fetch(`/api/tasks/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(task)
    })
      .then(response => response.json())
      .then(data => {
        // Sunucudan gelen cevabı işle
        // Örneğin, sunucu tarafında yapılan değişiklikler varsa güncelle
        fetchTasks();
      })
      .catch(error => {
        console.error("Görev güncelleme hatası:", error);
      });
  };

  const handleTaskAdd = (id, task) => {
    // Yeni görevi sunucuya gönder
    fetch("/api/tasks", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(task)
    })
      .then(response => response.json())
      .then(data => {
        // Sunucudan gelen cevabı işle
        // Örneğin, sunucu tarafında yapılan değişiklikler varsa güncelle
        fetchTasks();
      })
      .catch(error => {
        console.error("Görev ekleme hatası:", error);
      });
  };

  const handleTaskDelete = id => {
    // Silinen görevi sunucuya gönder
    fetch(`/api/tasks/${id}`, {
      method: "DELETE"
    })
      .then(response => response.json())
      .then(data => {
        // Sunucudan gelen cevabı işle
        // Örneğin, sunucu tarafında yapılan değişiklikler varsa güncelle
        fetchTasks();
      })
      .catch(error => {
        console.error("Görev silme hatası:", error);
      });
  };

  const handlePageChange = newPage => {
    setPage(newPage);
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
      fetchTasks();
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
      fetchTasks();
    }
  };

  return (
    <div>
      <div id="gantt_container_test" style={{ width: "100%", height: "600px" }} />
      <div>
        <button onClick={handlePrevPage} disabled={page === 1}>
          Önceki Sayfa
        </button>
        <span>{`Sayfa ${page} / ${totalPages}`}</span>
        <button onClick={handleNextPage} disabled={page === totalPages}>
          Sonraki Sayfa
        </button>
      </div>
    </div>
  );
};

export default GanttChart;
