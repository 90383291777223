import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {}
};

export const dependantSelectSlice = createSlice({
  name: "dependantSelect",
  initialState,
  reducers: {
    changeParentSelectValue: (state, action) => {
      state.value[action.payload.key + "ParentValue"] = action.payload.value;
    },
    getParentValue: (state, action) => {
      return state.value[action.payload.key + "ParentValue"];
    }
  }
});

export const { changeParentSelectValue, getParentValue } = dependantSelectSlice.actions;

export default dependantSelectSlice.reducer;
