import { EditOutlined } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Button as AntButton, Col, Form, Input, Row, Collapse, Checkbox, Radio } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../../ApiConnector";
import { error, showError, showSuccess } from "../../../MessageHelper";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { Button, Modal } from "react-bootstrap";
import PresentationContentPageConstruction from "./PresentationContentPageConstruction";
import PresentationContentPageProject from "./PresentationContentPageProject";
import DraggableModal from "../../../Components/DraggableModal";
import PresentationContentPagePhoto from "./PresentationContentPagePhoto";

const { Panel } = Collapse;

export class PresentationContentPage extends Component {
  constructor() {
    super();
    this.state = {
      formName: "PresentationContentPage",
      controllerName: "presentationContentPage",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      dynamicMenuCascader: [],
      answerTypeSelectItems: [],
      id: null,
      tableList: [],
      excelData: [],
      createWhereParams: null,
      refreshForm: false,
      filePath: "",
      fileList: [],
      rows: [],
      page: 1,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    };
  }

  formRef = React.createRef();
  formRefPhotoPage = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    this.setState({
      page: 1
    });
    const { pagination } = this.state;
    this.restartTable({ pagination });

    this.fillComboboxes();
  };

  fillComboboxes = async () => {};

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      answerType: null,
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/pagingTitle";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      searchCriteriaModels = [],
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      presentationContentId: this.props.presentationContentId,
      page: this.state.page,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
      searchCriteriaModels: searchCriteriaModels
    };
    return newObj;
  }

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    console.log("props", this.props);
    let newItem = {
      ...values,
      id: this.state.id,
      presentationContentId: this.props.presentationContentId,
      page: this.state.page
    };
    if (!Boolean(newItem.id)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createTitle", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateTitle/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        presentationContentPageId: response.data
      });
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  savePresentationContentPage = async () => {
    let newItem = {
      id: this.state.presentationContentPageId,
      presentationContentId: this.props.presentationContentId,
      addLocationMap: this.state.addLocationMap
    };
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/savePresentationContentPage", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  setOpenPanels = key => {
    this.setState({
      activePanelKey: key
    });
  };

  setOpenPanelsTitle = key => {
    this.setState({
      activePanelKeyTitle: key
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/typeAll/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  getRatingList = max => {
    const numbers = [];
    for (let index = 1; index <= max; index++) {
      numbers.push(index);
    }
    return numbers;
  };

  handleFileChange = ({ fileList }) => this.setState({ fileList });

  savePhotoPage = async values => {
    this.setState({
      modalPresentationPhotoCountPage: false,
      modalPresentationPhotoPage: true,
      photoCount: values.photoCount
    });
  };

  addNewPage = async page => {
    let newItem = {
      presentationContentId: this.props.presentationContentId,
      page: page
    };
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/addNewPage", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col md={23}></Col>
          <Col md={1}>
            <AntButton
              style={{ border: "0px" }}
              hidden={!this.state.hideInputs}
              onClick={this.onClickCreateNew}
              icon={<CreateIcon color="secondary" fontSize="large" />}
            ></AntButton>
            <AntButton
              style={{ border: "0px" }}
              hidden={this.state.hideInputs}
              onClick={this.onClickCreateNew}
              icon={<RemoveCircle color="error" fontSize="large" />}
            ></AntButton>
          </Col>
        </Row>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <div hidden={this.state.hideInputs}>
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PresentationContentPage.Title" defaultMessage="Title" />}
              name="title"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="PresentationContentPage.ProjectLogItem" defaultMessage="Project Log Item" />}
              name="projectLogItem"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              valuePropName="checked"
            >
              <Checkbox
                onChange={e => {
                  if (e.target.checked) {
                    this.formRef.current.setFieldsValue({
                      constructionDetail: null
                    });
                  }
                }}
              />
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="PresentationContentPage.ConstructionDetail" defaultMessage="Construction Detail" />}
              name="constructionDetail"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              valuePropName="checked"
            >
              <Checkbox
                onChange={e => {
                  if (e.target.checked) {
                    this.formRef.current.setFieldsValue({
                      projectLogItem: null
                    });
                  }
                }}
              />
            </Form.Item>

            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
          {Boolean(this.state.tableList) && this.state.tableList.length > 0 && (
            <>
              <br />
              <Collapse activeKey={this.state.activePanelKey} onChange={this.setOpenPanels} accordion>
                {this.state.tableList.map((item, index) => (
                  <Panel header={item.title} key={index}>
                    {item.projectLogItem && <PresentationContentPageProject presentationContentPageTitleId={item.id} random={Math.random()} />}

                    {item.constructionDetail && (
                      <PresentationContentPageConstruction presentationContentPageTitleId={item.id} random={Math.random()} />
                    )}
                  </Panel>
                ))}
              </Collapse>

              <br />
              <br />
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PresentationContentPage.AddLocationMap" defaultMessage="Add location map" />}
                name="addLocationMap"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox
                  onClick={e => {
                    this.setState({
                      addLocationMap: e.target.checked
                    });
                  }}
                />
              </Form.Item>
            </>
          )}

          <br />
          <br />
          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={4} lg={4} xl={4} xxl={4}></Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
              {this.state.page > 1 && (
                <Button
                  style={{ width: "100%" }}
                  variant="secondary"
                  onClick={() => {
                    this.setState({ page: this.state.page - 1 }, () => {
                      const { pagination } = this.state;
                      this.restartTable({ pagination });
                    });
                  }}
                >
                  <FormattedMessage id="PresentationContentPage.Previous" defaultMessage="Previous" />
                </Button>
              )}
            </Col>

            {Boolean(this.state.tableList) && this.state.tableList.length > 0 && (
              <>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button
                    style={{ width: "100%" }}
                    variant="secondary"
                    onClick={() =>
                      this.setState(
                        prevState => ({ page: prevState.page + 1 }),
                        () => {
                          const { pagination } = this.state;
                          this.restartTable({ pagination });
                        }
                      )
                    }
                  >
                    <FormattedMessage id="PresentationContentPage.Next" defaultMessage="Next" />
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button
                    style={{ width: "100%" }}
                    variant="secondary"
                    onClick={() =>
                      this.setState(
                        prevState => ({ page: prevState.page + 1 }),
                        () => {
                          this.addNewPage(this.state.page);
                        }
                      )
                    }
                  >
                    <FormattedMessage id="PresentationContentPage.PagePlus" defaultMessage="+ Page" />
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button style={{ width: "100%" }} variant="warning" onClick={() => this.setState({ modalPresentationPhotoCountPage: true })}>
                    <FormattedMessage id="PresentationContentPage.PagePhotoPlus" defaultMessage="+ Photo Page" />
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button style={{ width: "100%" }} variant="success" onClick={this.savePresentationContentPage}>
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Form>
        <br />

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <DraggableModal
          title={<FormattedMessage id="ProjectLog.PhotoPage" defaultMessage="Photo Page" />}
          width={600}
          centered
          okButtonProps={{ hidden: false }}
          cancelButtonProps={{ hidden: false }}
          open={this.state.modalPresentationPhotoCountPage}
          onCancel={() => this.setState({ modalPresentationPhotoCountPage: false })}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          okText={<FormattedMessage id="GeneralButtonOk" defaultMessage="Ok" />}
          onOk={() => this.formRefPhotoPage.current.submit()}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.savePhotoPage} onFinishFailed={onFinishFailed} ref={this.formRefPhotoPage}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ProjectLog.Type" defaultMessage="Type" />}
                name="photoCount"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Radio.Group>
                  <Radio value={1}>One Photo</Radio>
                  <Radio value={2}>Two Photos</Radio>
                  <Radio value={4}>Four Photos</Radio>
                </Radio.Group>
              </Form.Item>
            </Form>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="ProjectLog.Photo" defaultMessage="Photo" />}
          width={800}
          centered
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          open={this.state.modalPresentationPhotoPage}
          onCancel={() => this.setState({ modalPresentationPhotoPage: false })}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <PresentationContentPagePhoto
              photoCount={this.state.photoCount}
              presentationContentPageId={this.state.presentationContentPageId}
              random={this.state.random}
              close={() => this.setState({ modalPresentationPhotoPage: false })}
            />
          }
        />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PresentationContentPage);
