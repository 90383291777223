import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Badge, Col, Row, Table, Button as AntButton } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined, ViewAgendaOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import "react-quill/dist/quill.snow.css";
import { fillFilterOperations } from "../../Components/DataTableFilterComponent";
import { addMonths, format, getDaysInMonth, eachDayOfInterval, isAfter } from "date-fns";
import DraggableModal from "../../Components/DraggableModal";
import { Departure } from "./Departure";

export class RouteScheduleGantt extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "RouteScheduleGantt",
      controllerName: "route",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };
  fillComboboxes = async () => {};
  searchregionId = async item => {
    this.setState({ loadingSearch: true });
    var response = await handleRequest("GET", `/api/${this.state.controllerName}/regionId/${Boolean(item) && item.length > 0 ? item : "''"}`);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        regionIdSelectItems: [],
        loadingSearch: false
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          regionIdSelectItems: response.data,
          loadingSearch: false
        });
      } else {
        this.setState({
          regionIdSelectItems: [],
          loadingSearch: false
        });
      }
    }
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/routeSchedule";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };

    let detailAction = {
      name: "Detail",
      icon: <ViewAgendaOutlined fontSize="small" color="secondary" />,
      actionClick: this.detailModal.bind(this)
    };

    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(detailAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  detailModal = record => {
    this.setState({
      random: Math.random(),
      showDetailModal: true,
      selectedRow: record
    });
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  generateColumns = () => {
    const today = new Date(); // Bugünün tarihi
    const columns = [
      {
        title: "Route",
        dataIndex: "route",
        key: "route",
        fixed: "left",
        width: 150
      }
    ];

    for (let i = 0; i < 12; i++) {
      const currentMonth = addMonths(today, i);

      // Bugünden itibaren günleri hesaplama
      const daysInMonth = eachDayOfInterval({
        start: i === 0 ? today : new Date(format(currentMonth, "yyyy-MM-01")),
        end: new Date(format(currentMonth, "yyyy-MM") + `-${getDaysInMonth(currentMonth)}`)
      });

      daysInMonth.forEach(day => {
        // Eğer sütunları sadece gelecekteki günler için istiyorsanız:
        if (isAfter(day, today) || format(day, "yyyy-MM-dd") === format(today, "yyyy-MM-dd")) {
          const formattedDate = format(day, "dd-MM-yyyy");
          const dayLabel = format(day, "d/M"); // Gün ve ay
          const dayName = format(day, "EEE"); // Haftanın kısa günü (Pzt, Sal, Çrş)
          const yearLabel = format(day, "yyyy"); // Yıl

          columns.push({
            title: (
              <div>
                <div>{dayLabel}</div>
                <div style={{ fontSize: "10px", color: "#888" }}>{yearLabel}</div>
                <div style={{ fontSize: "10px", color: "#888" }}>{dayName}</div>
              </div>
            ),
            dataIndex: formattedDate,
            key: formattedDate,
            width: 80,
            render: text => (text ? "✔" : ""),
            align: "center"
          });
        }
      });
    }

    return columns;
  };

  generateData = () => {
    const today = new Date(); // Bugünün tarihi

    return this.state.tableList.map(item => {
      const row = { key: item.route, route: item.route };

      for (let i = 0; i < 12; i++) {
        const currentMonth = addMonths(today, i);
        const daysInMonth = eachDayOfInterval({
          start: new Date(format(currentMonth, "yyyy-MM-01")),
          end: new Date(format(currentMonth, "yyyy-MM") + `-${getDaysInMonth(currentMonth)}`)
        });

        daysInMonth.forEach(day => {
          const formattedDate = format(day, "dd-MM-yyyy");
          // Bugünden sonra veya bugüne denk gelen tarihleri kontrol et
          if (isAfter(day, today) || format(day, "yyyy-MM-dd") === format(today, "yyyy-MM-dd")) {
            row[formattedDate] = item?.startTimes?.includes(formattedDate) ? "✔" : "";
          }
        });
      }

      return row;
    });
  };

  render() {
    return (
      <Badge.Ribbon
        text={<FormattedMessage id="route.pageTitle" defaultMessage="Route Schedule" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <br />
          <Row gutter={[16, 16]}>
            <Col md={18}> </Col>
            <Col md={3}>
              <AntButton type="primary" style={{ borderRadius: "5px", width: "100%" }}>
                <FormattedMessage id="RouteScheduleGantt.Requirements" defaultMessage="Requirements" />
              </AntButton>
            </Col>
            <Col md={3}>
              <AntButton
                type="primary"
                style={{ borderRadius: "5px", width: "100%" }}
                onClick={() => {
                  this.setState({
                    showAddDepartureModal: true,
                    random: Math.random()
                  });
                }}
              >
                <FormattedMessage id="RouteScheduleGantt.AddDeparture" defaultMessage="Add Departure" />
              </AntButton>
            </Col>
          </Row>
          <br />
          <Table
            columns={this.generateColumns()}
            loading={this.state.loading}
            dataSource={this.generateData()}
            scroll={{ x: "100%" }}
            bordered
            pagination={false}
          />
        </div>
        <DraggableModal
          title={<FormattedMessage id="RouteScheduleGantt.Departure" defaultMessage="Departure" />}
          closable="false"
          open={this.state.showAddDepartureModal}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            const { pagination } = this.state;
            this.restartTable({ pagination });

            this.setState({
              showAddDepartureModal: false
            });
          }}
          width={1400}
          centered
          content={<Departure random={this.state.random} />}
        />
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(RouteScheduleGantt);
