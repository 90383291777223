import { DownloadOutlined, FilterOutlined, SendOutlined } from "@ant-design/icons";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import { Button as AntButton, DatePicker, Input, Select, Table, Tabs, Tooltip } from "antd";
import "antd/dist/antd.css";
import DOMPurify from "dompurify";
import moment from "moment";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Option } = Select; 

const itemList = [];

const generateItemList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    itemList.push({
      key: node.data.id,
      lineage: node.data.lineage,
      title: node.data.menuTitle,
      page: node.data.page
    });
    if (node.children) {
      generateItemList(node.children);
    }
  }
};

export class UserReceivedMessages extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      currentUrl: "",
      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      messageToUserIdSelectItems: [],
      message: "",
      currentTabKey: "1",
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000"],
        total: 0
      },
      loading: false,
      sortField: "lastUpdateDate",
      sortOrder: "descend",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };
  componentDidUpdate = async (prevProps, prevState) => {};

  fillComboboxes = async () => {
    this.getDynamicMenu();
    var responseUsers = await handleRequest("GET", "/api/users");
    if (responseUsers.type === "ERROR") {
      error(responseUsers);
    } else {
      this.setState({
        messageToUserIdSelectItems: Boolean(responseUsers.data) ? responseUsers.data : []
      });
    }
  };

  getDynamicMenu = async () => {
    const responseTree = await handleRequest("GET", "/api/menus/tree");
    if (responseTree.data.length !== 0) {
      generateItemList(responseTree.data.treeTable);
      this.setState({
        menuIdSelectItems: responseTree.data.cascader,
        menuIdTableList: responseTree.data.treeTable
      });
    }
  };

  setCascaderMenu = val => {
    val = val.substring(val.indexOf("/") + 1);
    var item = itemList.find(p => p.page === val);
    let itemId = [];
    if (Boolean(item)) {
      itemId = item.lineage;
    }
    this.formRef.current.setFieldsValue({
      menuId: itemId
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "lastUpdateDate",
      sortOrder: "descend",
      filePath: "",
      fileList: []
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  closeModal = row => {
    this.setState({
      modalCloseShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  closeMessage = async () => {
    const closedItem = {
      id: this.state.id
    };
    var response = await handleRequest("PATCH", "/api/userMessage/close/" + closedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalCloseShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/userMessage/received/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Reply",
              icon: <SendOutlined />,
              actionClick: this.replyMessage.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };
  replyMessage = row => {
    if (this.props.onReply) {
      this.props.onReply(row);
    }
  };
  render() {
    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="UserMessage.messageFromUser" defaultMessage="To" />,
        key: "createUserId",
        render: record => {
          return Boolean(record.createUserString) ? record.createUserString : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              allowClear
              placeholder="User"
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.createUserId) ? this.state.pagination.createUserId : null}
              onChange={async value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  createUserId: value,
                  createUserIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            >
              {Boolean(this.state["messageToUserIdSelectItems"]) &&
                this.state["messageToUserIdSelectItems"].map(i => (
                  <Option key={i.id} value={i.id}>
                    {i.name + " " + i.surname}
                    <br />
                    {i.email}
                  </Option>
                ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.createUserIdColor) ? this.state.pagination.createUserIdColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="UserMessage.menuId" defaultMessage="Menu" />,
        key: "menuId",
        render: record => {
          return Boolean(record.menu) ? record.menu.menuTitle : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.menuId) ? this.state.pagination.menuId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  menuId: e.target.value,
                  menuIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.menuIdColor) ? this.state.pagination.menuIdColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="UserMessage.subject" defaultMessage="Subject" />,
        key: "subject",
        render: record => {
          return record.subject;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.subject) ? this.state.pagination.subject : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  subject: e.target.value,
                  subjectColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.subjectColor) ? this.state.pagination.subjectColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="UserMessage.message" defaultMessage="Message" />,
        key: "message",
        render: record => {
          return (
            <Tooltip placement="topLeft" title={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(record.message) }}></div>}>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  lineClamp: "2",
                  WebkitLineClamp: "2"
                }}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(record.message) }}
              ></div>
            </Tooltip>
          );
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.message) ? this.state.pagination.message : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  message: e.target.value,
                  messageColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.messageColor) ? this.state.pagination.messageColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="UserMessage.filePath" defaultMessage="File" />,
        key: "filePath",
        render: record => {
          let path = record.filePath;
          if (Boolean(path)) {
            path = path.split(",");
            let buttons = path.map((item, index) => {
              return (
                <AntButton key={index} href={item} target="_blank" type="primary" shape="round" icon={<DownloadOutlined />}>
                  <span>{index + 1}</span>
                </AntButton>
              );
            });
            return buttons;
          } else return "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.filePath) ? this.state.pagination.filePath : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  filePath: e.target.value,
                  filePathColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.filePathColor) ? this.state.pagination.filePathColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="UserMessage.status" defaultMessage="Status" />,
        key: "status",
        render: record => {
          return Boolean(record.status) ? "Open" : "Closed";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.status) ? this.state.pagination.status : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  status: e.target.value,
                  statusColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.statusColor) ? this.state.pagination.statusColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="UserMessage.finishDate" defaultMessage="Close Date" />,
        key: "finishDate",
        render: record => {
          return record.finishDate;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.finishDate) ? this.state.pagination.finishDate : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  finishDate: e.target.value,
                  finishDateColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.finishDateColor) ? this.state.pagination.finishDateColor : "#bfbfbf" }} />
        ),

        sorter: true
      },

      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
              exportClick: false
            })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Notes" hideElement={true}>
            <ExcelSheet name="Notes" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
              <ExcelColumn label="To" value="messageToUser" />
              <ExcelColumn label="Menu" value="menuId" />
              <ExcelColumn label="Subject" value="subject" />
              <ExcelColumn label="Message" value="message" />
              <ExcelColumn label="File" value="filePath" />
              <ExcelColumn label="Status" value="status" />
              <ExcelColumn label="Finish Date" value="finishDate" />
              <ExcelColumn label="Last Update Date" value="lastUpdateDate" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );
    return (
      <div className="card card-custom" style={{ minHeight: "100%" }}>
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            size="small"
            rowKey={record => record.id}
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.modalCloseShow}
          onHide={() => {
            this.setState({ id: null, modalCloseShow: false });
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be closed !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ id: null, modalCloseShow: false });
              }}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={this.closeMessage}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserReceivedMessages);
