import React, { Component, useContext, useEffect, useRef, useState } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import moment from "moment";
import {
  Input,
  Form,
  Row,
  Col,
  Button as AntButton,
  Tooltip,
  Table,
  InputNumber, DatePicker, Select, Cascader, Space
} from "antd";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../../ApiConnector";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import EldenMobilization from "../../elden/planning/EldenMobilization";
import CreateIcon from "@material-ui/icons/AddCircle";
import { CloseCircleOutlined, FilterOutlined, MinusCircleOutlined } from "@ant-design/icons";
import DraggableModal from "../../../Components/DraggableModal";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class BiddingLogMobilization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "BiddingLogMobilization",
      controllerName: "biddingLogMobilization",
      id: null,
      projectName: "",
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      nodesShowHide: [],
      loadedKeys: [],
      tableList: [],
      unitIdSelectItems: [],
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showServiceModal: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }
  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseAllUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/unit");
    if (responseAllUnit.type === "ERROR") {
      error(responseAllUnit);
    }
    var responseUnitTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/unitTree");
    if (responseUnitTree.type === "ERROR") {
      error(responseUnitTree);
    }
    generateList(responseUnitTree.data);

    var responseCurrency = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responseCurrency.type === "ERROR") {
      error(responseCurrency);
    }

    var responseMobilizations = await handleRequest("GET", "/api/" + this.state.controllerName + "/mobilizations/" + this.props.tenderingLogId);
    if (responseMobilizations.type === "ERROR") {
      error(responseMobilizations);
    }
    let mobilizations = Boolean(responseMobilizations.data) && responseMobilizations.data.length > 0 ? responseMobilizations.data : [];
    if (Boolean(mobilizations) && mobilizations.length > 0) {
      mobilizations.forEach(element => {
        console.log("element", element);
        if (Boolean(element.unitId) && element.unitId > 0) {
          var unit = dataList.find(p => p.key === element.unitId);
          console.log("dataList", dataList);
          if (Boolean(unit)) {
            element.unitId = unit.lineage;
          }
        }
      });
    }

    this.formRef.current.setFieldsValue({
      mobilizations: mobilizations
    });

    this.setState({
      allUnitSelectItems: Boolean(responseAllUnit.data) ? responseAllUnit.data : [],
      unitIdSelectItems: Boolean(responseUnitTree.data) ? responseUnitTree.data : [],
      currencySelectItems: Boolean(responseCurrency.data) ? responseCurrency.data : [],
      mobilizations: mobilizations,
    });
  };

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      tenderingLogId: this.props.tenderingLogId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id,
      type: this.state.type
    };

    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
      this.setState({
        id: null,
        showDeleteModal: false
      });
    }
  };

  save = async values => {
    const { process, selectedNode } = this.state;
    console.log("selectedNode", selectedNode);
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId,
      type: Boolean(this.state.type) ? this.state.type : "ProjectName"
    };
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveProjectsTree", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateProjectsTree/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showCreateProjectModal: false
      });
    }
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      description: "",
      projectName: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      process: null,
      showOrderModal: false,
      showPasteModal: false,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showServiceModal: false
    });
  };

  onDragEnter = info => { };

  onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...this.state.nodes];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 &&
      // Has children
      info.node.props.expanded &&
      // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    this.setState({
      nodes: data
    });
  };

  okProjectTreeTemplate = async () => {
    const { targetKeys } = this.state;
    const { tenderingLogId } = this.props;
    if (Boolean(tenderingLogId) && tenderingLogId > 0) {
      if (targetKeys && targetKeys.length > 0) {
        targetKeys.sort((a, b) => {
          return a - b;
        });


        let titleIds = [];
        let subTitleIds = [];
        if (Boolean(targetKeys) && targetKeys.length > 0) {
          targetKeys.forEach(element => {
            if (element.includes("SubTitle")) {
              let subTitle = element.replace("-SubTitle", "");
              console.log("subTitle", subTitle);
              if (Boolean(subTitle) && subTitle > 0) {
                subTitleIds.push(subTitle);
              }
            } else {
              let title = element.replace("-Title", "");
              console.log("title", title);
              if (Boolean(title) && title > 0) {
                titleIds.push(title);
              }
            }
          });
        }

        if ((Boolean(titleIds) && titleIds.length > 0) || (Boolean(subTitleIds) && subTitleIds.length > 0)) {
          if (!(Boolean(titleIds) && titleIds.length > 0)) {
            titleIds = [-1]
          }
          if (!(Boolean(subTitleIds) && subTitleIds.length > 0)) {
            subTitleIds = [-1]
          }

          var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/eldenTransfer/" + titleIds + "/" + subTitleIds + "/" + tenderingLogId);
          if (response.type === "ERROR") {
            error(response);
          } else {
            const { pagination } = this.state;
            this.restartTable({ pagination });
            this.fillComboboxes();
            showSuccess();
            this.setState({ modalEldenProjectTree: false });
          }
        } else {
          showError("Please select item.");
        }
      } else {
        showError("Not found.");
      }
    } else {
      showError("Tendering Not found.");
    }
  };

  onTargetKeysCheck = async targetKeys => {
    console.log("targetKeys", targetKeys);
    this.setState({
      targetKeys: targetKeys
    });
  };

  handleQuantity = async (row, value) => {
    console.log("row", row);
    console.log("value", value);
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        const newData = [...this.state.tableList];
        const item = newData.find(item => row.key === item.key);
        if (Boolean(value) && Boolean(item)) {
          if (item.quantity === value.quantity) return;
          item.quantity = value.quantity;
          this.setState({
            tableList: newData
          });
        }
      }
    }
  };

  handleUnit = async (row, value) => {
    console.log("row", row);
    console.log("value", value);
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        const newData = [...this.state.tableList];
        const item = newData.find(item => row.key === item.key);
        if (Boolean(value) && Boolean(item)) {
          if (!(Boolean(value.unitId) && value.unitId.length > 1)) {
            return;
          }
          let unitId = value.unitId[value.unitId.length - 1];
          console.log("unitId", unitId);
          if (!Boolean(unitId)) {
            return;
          }
          if (item.unitId === unitId) return;
          item.unitId = unitId;
          let unit = this.state.allUnitSelectItems.find(p => p.id === unitId);
          item.unitName = unit.abbreviation;
          this.setState({
            tableList: newData
          });
        }
      }
    }
  };

  handleCurrency = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.currency === value.currency) return;
        item.currency = value.currency;
        let currency = item.currencySelectItems.find(p => p.key === value.currency);
        item.currencyName = currency.value;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleUnitPrice = async (row, value) => {
    console.log("row", row);
    console.log("value", value);
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        const newData = [...this.state.tableList];
        const item = newData.find(item => row.key === item.key);
        if (Boolean(value) && Boolean(item)) {
          if (item.unitPrice === value.unitPrice) return;
          item.unitPrice = value.unitPrice;
          this.setState({
            tableList: newData
          });
        }
      }
    }
  };

  saveAll = async values => {
    console.log("values", values);
    if (Boolean(this.props.tenderingLogId) && this.props.tenderingLogId > 0 &&
      Boolean(values) && Boolean(values.mobilizations) && values.mobilizations.length > 0) {
      values.mobilizations.forEach(element => {
        element.tenderingLogId = this.props.tenderingLogId;
        element.currencySelectItems = [];
        element.unitId = Boolean(element) && Boolean(element.unitId) && element.unitId.length > 0 ? element.unitId[element.unitId.length - 1] : null;
      });
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAll/" + this.props.tenderingLogId, values.mobilizations);
      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess();
        this.props.close();
      }
    } else {
      showWarning("Tendering not found.");
    }
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const mobilizations = Boolean(this.formRef) && Boolean(this.formRef.current) && this.formRef.current.getFieldValue("mobilizations").length > 0 ?
      this.formRef.current.getFieldValue("mobilizations") :
      [];
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.saveAll} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={23}>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                onClick={() => {
                  this.setState({ modalEldenProjectTree: true });
                }}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>
          <br />
          <Form.List name="mobilizations">
            {(fields, { add, remove }) => {
              return (
                <>
                  <TableBoot bordered size="sm">
                    <thead>
                      <tr>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id={"BiddingLogMobilization.Mobilization"} defaultMessage={"Mobilization"} />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id={"BiddingLogMobilization.Quantity"} defaultMessage={"Quantity"} />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id={"BiddingLogMobilization.UnitPrice"} defaultMessage={"Price"} />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id={"BiddingLogMobilization.Cost"} defaultMessage={"Cost"} />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map(field => {
                        return (
                          <tr key={field.key}>
                            <td>
                              {Boolean(mobilizations) &&
                                mobilizations.length > 0 &&
                                Boolean(mobilizations[field.key]) &&
                                Boolean(mobilizations[field.key].parentId)
                                ?
                                <Form.Item noStyle name={[field.name, "mobilizationName"]}>
                                  <Input style={{ width: "75%", float: "right" }} readOnly />
                                </Form.Item>
                                :
                                <Form.Item noStyle name={[field.name, "mobilizationName"]}>
                                  <Input style={{ width: "100%" }} readOnly />
                                </Form.Item>
                              }
                            </td>
                            {Boolean(mobilizations) &&
                              mobilizations.length > 0 &&
                              Boolean(mobilizations[field.key]) &&
                              Boolean(mobilizations[field.key].parentId)
                              &&
                              <>
                                <td>
                                  <Space>
                                    <Form.Item noStyle name={[field.name, "quantity"]}>
                                      <InputNumber style={{ width: "100%" }}
                                        onChange={(value) => {
                                          this.setState({
                                            quantity: value
                                          })
                                        }} />
                                    </Form.Item>
                                    <Form.Item noStyle name={[field.name, "unitId"]}>
                                      <Cascader style={{ width: "150px" }} options={this.state.unitIdSelectItems} showSearch optionFilterProp="children" />
                                    </Form.Item>
                                  </Space>
                                </td>
                                <td>
                                  <Space>
                                    <Form.Item noStyle name={[field.name, "unitPrice"]}>
                                      <InputNumber style={{ width: "100%" }}
                                        onChange={(value) => {
                                          this.setState({
                                            unitPrice: value
                                          })
                                        }} />
                                    </Form.Item>

                                    <Form.Item noStyle name={[field.name, "currency"]}>
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "150px" }}
                                        onChange={(value) => {
                                          this.setState({
                                            currency: value
                                          })
                                        }}
                                      >
                                        {Boolean(this.state["currencySelectItems"]) && this.state["currencySelectItems"].length > 0 &&
                                          this.state["currencySelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </Space>
                                </td>
                              </>
                            }
                            <td>
                              {Boolean(mobilizations) &&
                                mobilizations.length > 0 &&
                                Boolean(mobilizations[field.key]) && Boolean(mobilizations[field.key].quantity) && Boolean(mobilizations[field.key].unitPrice) ?
                                (mobilizations[field.key].quantity * mobilizations[field.key].unitPrice) + " " + mobilizations[field.key].currency
                                :
                                ""
                              }
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </TableBoot>
                </>
              );
            }}
          </Form.List>

          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Button
                variant="primary"
                type="submit"
                style={{ width: "100%", marginTop: "10px" }}
                disabled={Boolean(this.state.mobilizations) && this.state.mobilizations.length > 0 ? false : true}
                loading={this.state.loading}
              >
                <FormattedMessage id="BiddingLogMobilization.SaveAll" defaultMessage="Save All" />
              </Button>
            </Col>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
          </Row>
        </Form>

        <DraggableModal
          centered
          width={1150}
          maskClosable={false}
          open={this.state.modalEldenProjectTree}
          onCancel={() => {
            this.setState({ modalEldenProjectTree: false });
          }}
          onOk={() => {
            this.okProjectTreeTemplate();
          }}
          content={
            <div className="card card-custom" style={{ padding: "1rem", minHeight: "100%" }}>
              <EldenMobilization onTargetKeysCheck={this.onTargetKeysCheck}
                tenderingLogId={this.props.tenderingLogId}></EldenMobilization>
            </div>
          }
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BiddingLogMobilization);