import React, { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { FormattedMessage } from "react-intl";
import EldenMOSSettingsItem from "./EldenMOSSettingsItem";
import EldenMOSSettingsEquipment from "./EldenMOSSettingsEquipment";
import EldenMOSSettingsTool from "./EldenMOSSettingsTool";
import EldenMOSSettingsPhoto from "./EldenMOSSettingsPhoto";
import EldenMOSSettingsVideo from "./EldenMOSSettingsVideo";

export function EldenMethodOfStatementStepsDrawer() {
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  const [selectedTab, setSelectedTab] = React.useState("item");
  useEffect(() => {
    return () => {};
  }, [user]);

  return (
    <>
      {user && (
        <div className="d-flex flex-row">
          <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-250px" id="kt_profile_aside">
            <div className="card card-custom card-stretch">
              {/* begin::Body */}
              <div className="card-body pt-4">
                {/* begin::Toolbar */}
                <div className="d-flex justify-content-end"></div>
                {/* end::Toolbar */}
                {/* begin::Nav */}
                <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenMethodOfStatementStepsDrawer.Item" defaultMessage="Item" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "item" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("item");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="EldenMethodOfStatementStepsDrawer.Item" defaultMessage="Item" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenMethodOfStatementStepsDrawer.Equipment" defaultMessage="Equipment" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "equipment" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("equipment");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EldenMethodOfStatementStepsDrawer.Equipment" defaultMessage="Equipment" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenMethodOfStatementStepsDrawer.Tool" defaultMessage="Tool" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "tool" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("tool");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Article.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EldenMethodOfStatementStepsDrawer.Tool" defaultMessage="Tool" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenMethodOfStatementStepsDrawer.Manpower" defaultMessage="Manpower" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "manpower" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("manpower");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EldenMethodOfStatementStepsDrawer.Manpower" defaultMessage="Manpower" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenMethodOfStatementStepsDrawer.Document" defaultMessage="Document" />{" "}
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "document" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("document");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EldenMethodOfStatementStepsDrawer.Document" defaultMessage="Document" />{" "}
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenMethodOfStatementStepsDrawer.Photo" defaultMessage="Photo" />{" "}
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "photo" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("photo");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EldenMethodOfStatementStepsDrawer.Photo" defaultMessage="Photo" />{" "}
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenMethodOfStatementStepsDrawer.Video" defaultMessage="Video" />{" "}
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "video" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("video");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EldenMethodOfStatementStepsDrawer.Video" defaultMessage="Video" />{" "}
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                </div>
                {/* end::Nav */}
              </div>
              {/* end::Body */}
            </div>
          </div>
          <div className="flex-row-fluid ml-lg-8">
            {selectedTab === "item" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    <EldenMOSSettingsItem nextTab={() => setSelectedTab("equipment")} />
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "equipment" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    <EldenMOSSettingsEquipment nextTab={() => setSelectedTab("tool")} />
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "tool" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    <EldenMOSSettingsTool nextTab={() => setSelectedTab("manpower")} />
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "manpower" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">{/* <ProjectsTreeCompanyDivision nextTab={() => setSelectedTab("document")} /> */}</div>
                </div>
              </div>
            )}
            {selectedTab === "document" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">{/* <ProjectsTreeCompanyHeight nextTab={() => setSelectedTab("photo")} /> */}</div>
                </div>
              </div>
            )}

            {selectedTab === "photo" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    <EldenMOSSettingsPhoto nextTab={() => setSelectedTab("video")} />
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "video" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    <EldenMOSSettingsVideo nextTab={() => setSelectedTab("item")} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
