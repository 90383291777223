import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Card, DatePicker, Table, InputNumber, Input, Image } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import {
  FilterOutlined,
} from "@ant-design/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../../redux/store";
import { Select } from "antd";
import { fillFilterOperations } from "../../../Components/DataTableFilterComponent";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;


//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    dataIndex === "startDate" || dataIndex === "endDate"
      ? form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? moment(record[dataIndex], "DD-MM-YYYY") : ""
      })
      : form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
      });
  };

  const toggleSave = async () => {
    try {
      if (dataIndex === "startDate") {
        const values = await form.validateFields();
        values.startDate = Boolean(values.startDate) ? moment(values.startDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else if (dataIndex === "endDate") {
        const values = await form.validateFields();
        values.endDate = Boolean(values.endDate) ? moment(values.endDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record }, { ...values });
      }
    } catch (errInfo) { }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
      >
        {dataIndex === "startDate" || dataIndex === "endDate" ? (
          <DatePicker showTime ref={inputRef} onBlur={toggleSave} format={"DD-MM-YYYY HH:mm"} />
        ) : dataIndex === "currency" ? (
          <Select
            allowClear
            ref={inputRef}
            onChange={toggleSave}
            onBlur={toggleSave}
            style={{ width: "150px" }}
            showSearch
            optionFilterProp="children"
          >
            {Boolean(record.currencySelectItems) &&
              record.currencySelectItems.length > 0 &&
              record.currencySelectItems.map(i => (
                <Option key={i.key} value={i.key}>
                  {i.value}
                </Option>
              ))}
          </Select>
        ) : (
          <InputNumber
            parser={value => value.replace(",", ".")}
            formatter={value => value.replace(".", ",")}
            min={0}
            ref={inputRef}
            onPressEnter={toggleSave}
            onBlur={toggleSave}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion

export class AssignmentMultiple extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "AssignmentMultiple",
      controllerName: "assignment",
      id: null,
      loading: false,
      loadingSearch: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: false,
      user: store.getState().auth.user,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();

    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.resetFields();

      this.setState({
        personnelPhotoPath: ""
      });
    }
  }

  fillComboboxes = async () => {
    var responseDepartments = await handleRequest("GET", "/api/" + this.state.controllerName + "/departments");
    if (responseDepartments.type === "ERROR") {
      error(responseDepartments);
    } else {
      this.setState({
        departmentIdSelectItems: Boolean(responseDepartments.data) ? responseDepartments.data : []
      });
    }

    var responseDesignationId = await handleRequest("GET", "/api/" + this.state.controllerName + "/designations");
    if (responseDesignationId.type === "ERROR") {
      error(responseDesignationId);
    } else {
      this.setState({
        designationIdSelectItems: Boolean(responseDesignationId.data) ? responseDesignationId.data : []
      });
    }

    var responseAssignmentProjectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/assignmentProjects");
    if (responseAssignmentProjectId.type === "ERROR") {
      error(responseAssignmentProjectId);
    } else {
      this.setState({
        assignmentProjectIdSelectItems: Boolean(responseAssignmentProjectId.data) ? responseAssignmentProjectId.data : []
      });
    }

    var responseCompanyProjectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/companyProjectId");
    if (responseCompanyProjectId.type === "ERROR") {
      error(responseCompanyProjectId);
    } else {
      this.setState({
        companyProjectIdSelectItems: Boolean(responseCompanyProjectId.data) ? responseCompanyProjectId.data : []
      });
    }

    var responseWorkLocationId = await handleRequest("GET", "/api/" + this.state.controllerName + "/workLocations");
    if (responseWorkLocationId.type === "ERROR") {
      error(responseWorkLocationId);
    } else {
      this.setState({
        workLocationIdSelectItems: Boolean(responseWorkLocationId.data) ? responseWorkLocationId.data : []
      });
    }

    var responsePersonnelLogId = await handleRequest("GET", "/api/" + this.state.controllerName + "/personnelLogId");
    if (responsePersonnelLogId.type === "ERROR") {
      error(responsePersonnelLogId);
    } else {
      this.setState({
        personnelLogIdSelectItems: Boolean(responsePersonnelLogId.data) ? responsePersonnelLogId.data : []
      });
    }
  };

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = async (row) => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    let startDate = null;
    let endDate = null;
    if (Boolean(values.date) && values.date.length > 1) {
      startDate = values.date[0];
      endDate = values.date[1];
    }
    newItem.startDate = startDate;
    newItem.endDate = endDate;

    console.log("newItem", newItem);
    var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.props.close();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillPersonnelIdsLastAssign = async (personnelLogIds) => {
    if (Boolean(personnelLogIds) && personnelLogIds.length > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillPersonnelIdsLastAssign/" + personnelLogIds);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          tableList: Boolean(response.data) && response.data.length > 0 ? response.data : [],
        });
      }
    } else {
      this.setState({
        tableList: [],
      });
    }
  };


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search and click enter`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
      </div>
    ),
    filterIcon: filtered => <FilterOutlined style={{ color: filtered ? "#1890ff" : "#bfbfbf" }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleStartDate = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.startDate === value.startDate) return;
        item.startDate = value.startDate;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleEndDate = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.endDate === value.endDate) return;
        item.endDate = value.endDate;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleDepartment = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.departmentIdRight === value.departmentIdRight) return;
        item.departmentIdRight = value.departmentIdRight;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleDesignation = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.designationIdRight === value.designationIdRight) return;
        item.designationIdRight = value.designationIdRight;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layoutLeft = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    };

    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 50,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="AssignmentMultiple.Name" defaultMessage="Name" />,
        key: "name",
        width: 100,
        render: record => {
          return record.name;
        },
        ...this.getColumnSearchProps("name"),
        sorter: (a, b) =>
          Boolean(a.name) && Boolean(b.name) && (a.name + "").localeCompare(b.name + "")
      },
      {
        title: <FormattedMessage id="AssignmentMultiple.Photo" defaultMessage="Photo" />,
        key: "photoPath",
        width: 100,
        render: record => {
          return Boolean(record.photoPath) ?
            <Image
              width={50}
              src={record.photoPath}
            /> : "";
        },
      },
      {
        title: <FormattedMessage id="AssignmentMultiple.Department" defaultMessage="Department" />,
        key: "departmentName",
        width: 100,
        render: record => {
          return record.departmentName;
        },
        ...this.getColumnSearchProps("departmentName"),
        sorter: (a, b) =>
          Boolean(a.departmentName) && Boolean(b.departmentName) && (a.departmentName + "").localeCompare(b.departmentName + "")
      },
      {
        title: <FormattedMessage id="AssignmentMultiple.Department" defaultMessage="Designation" />,
        key: "designationName",
        width: 100,
        render: record => {
          return record.designationName;
        },
        ...this.getColumnSearchProps("designationName"),
        sorter: (a, b) =>
          Boolean(a.designationName) && Boolean(b.designationName) && (a.designationName + "").localeCompare(b.designationName + "")
      },
      {
        title: <FormattedMessage id="AssignmentMultiple.NewAssignment" defaultMessage="New Assignment" />,
        width: 100,
        children: [
          {
            title: <FormattedMessage id="AssignmentMultiple.Department" defaultMessage="Department" />,
            key: "departmentName",
            width: 100,
            fixed: "right",
            render: record => {
              return record.departmentName;
            },
            editable: true,
            onCell: record => ({
              record,
              editable: true,
              dataIndex: "departmentName",
              title: <FormattedMessage id="AssignmentMultiple.Department" defaultMessage="Department" />,
              handleSave: this.handleDepartment
            }),
          },
          {
            title: <FormattedMessage id="AssignmentMultiple.Department" defaultMessage="Designation" />,
            key: "designationName",
            width: 100,
            fixed: "right",
            render: record => {
              return record.designationName;
            },
            editable: true,
            onCell: record => ({
              record,
              editable: true,
              dataIndex: "designationName",
              title: <FormattedMessage id="AssignmentMultiple.Designation" defaultMessage="Designation" />,
              handleSave: this.handleDesignation
            }),
          },
          {
            title: <FormattedMessage id="AssignmentMultiple.StartDate" defaultMessage="Start Date" />,
            key: "startDate",
            width: 100,
            fixed: "right",
            render: record => {
              return Boolean(record.startDate) ? moment(record.startDate).format("DD-MM-YYYY HH:mm") : "";
            },
            editable: true,
            onCell: record => ({
              record,
              editable: true,
              dataIndex: "startDate",
              title: <FormattedMessage id="AssignmentMultiple.StartDate" defaultMessage="Start Date" />,
              handleSave: this.handleStartDate
            }),
          },
          {
            title: <FormattedMessage id="AssignmentMultiple.EndDate" defaultMessage="End Date" />,
            key: "endDate",
            width: 100,
            fixed: "right",
            render: record => {
              return Boolean(record.endDate) ? moment(record.endDate).format("DD-MM-YYYY HH:mm") : "";
            },
            editable: true,
            onCell: record => ({
              record,
              editable: true,
              dataIndex: "endDate",
              title: <FormattedMessage id="AssignmentMultiple.EndDate" defaultMessage="End Date" />,
              handleSave: this.handleEndDate
            }),
          }
        ]
      },
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <div hidden={this.state.hideInputs}>
            <Row gutter={16} style={{ marginBottom: "16px" }}>
              <Col span={9}>
                <Card
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="AssignmentMultiple.Person" defaultMessage="Person" />}
                      name="personnelLogId"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children"
                        mode="multiple"
                        onChange={value => {
                          this.fillPersonnelIdsLastAssign(value);
                        }}>
                        {Boolean(this.state["personnelLogIdSelectItems"]) &&
                          this.state["personnelLogIdSelectItems"].length > 0 &&
                          this.state["personnelLogIdSelectItems"].map(i => (
                            <Option key={i.id} value={i.id}>
                              {i.name + " " + i.lastName}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="AssignmentMultiple.Project" defaultMessage="Project" />}
                      name="assignmentProjectId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["assignmentProjectIdSelectItems"]) &&
                          this.state["assignmentProjectIdSelectItems"].length > 0 &&
                          this.state["assignmentProjectIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="AssignmentMultiple.Branch" defaultMessage="Branch" />}
                      name="companyProjectId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children"
                        onChange={value => {
                          if (Boolean(value)) {
                            let startDate = null;
                            let endDate = null;

                            let companyProject = this.state["companyProjectIdSelectItems"].find(p => p.id === value);
                            if (Boolean(companyProject)) {
                              if (Boolean(companyProject.startDate)) {
                                startDate = companyProject.startDate;
                              }
                              if (Boolean(companyProject.endDate)) {
                                endDate = companyProject.endDate;
                              }
                            }
                            this.formRef.current.setFieldsValue({
                              date: [Boolean(startDate) ? moment(startDate) : null, Boolean(endDate) ? moment(endDate) : null]
                            });
                          }
                        }}>
                        {Boolean(this.state["companyProjectIdSelectItems"]) &&
                          this.state["companyProjectIdSelectItems"].length > 0 &&
                          this.state["companyProjectIdSelectItems"].map(i => (
                            <Option key={i.id} value={i.id}>
                              {i.projectFullName}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="AssignmentMultiple.Location" defaultMessage="Location" />}
                      name="workLocationId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["workLocationIdSelectItems"]) &&
                          this.state["workLocationIdSelectItems"].length > 0 &&
                          this.state["workLocationIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="AssignmentMultiple.Date" defaultMessage="Date" />}
                      name="date"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <RangePicker
                        format="DD-MM-YYYY HH:mm"
                        showTime={{ format: "HH:mm" }}
                        onChange={(dates, dateStrings) => {
                          this.setState({
                            dates: dates
                          });
                        }}
                      />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="AssignmentMultiple.Department" defaultMessage="Department" />}
                      name="departmentId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["departmentIdSelectItems"]) &&
                          this.state["departmentIdSelectItems"].length > 0 &&
                          this.state["departmentIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="AssignmentMultiple.Designation" defaultMessage="Designation" />}
                      name="designationId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["designationIdSelectItems"]) &&
                          this.state["designationIdSelectItems"].length > 0 &&
                          this.state["designationIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                </Card>
              </Col>

              <Col span={15}>
                <Card
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <Table
                    bordered
                    columns={columns}
                    components={components}
                    rowClassName={() => "editable-row"}
                    scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                    dataSource={this.state.tableList}
                    loading={this.state.loading}
                    pagination={false}
                  />
                </Card>
              </Col>
            </Row>

            <div hidden={this.state.hideSave}>
              {
                // <Row gutter={[16, 16]}>
                //   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                //   <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                //     <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                //       <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                //     </Button>
                //   </Col>
                //   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                // </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form >

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(AssignmentMultiple);