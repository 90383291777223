import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Button, Col, PageHeader, Row } from "antd";
import React, { useEffect } from "react";
import ExcelJS from 'exceljs';
import ExcelReader from "../pages/excel/ExcelReader";

const FormTopRightButtonsComponent = (props) => {
  const { title, inputs, tableList, handleUploadExcel, hideInputs, onClickCreateNew, onClickCancel } = props;
  const [showCreate, setShowCreate] = React.useState(true);

  const handleExportExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Records");

    // Define columns
    worksheet.columns = [
      { header: "S/N", key: "index" },
      ...inputs.map(input => ({ header: input.label, key: input.inputName }))
    ];

    // Add rows
    tableList.forEach((item, index) => {
      worksheet.addRow({ index: index + 1, ...item });
    });

    // Generate buffer and create a downloadable link
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    
    // Create a link element and click it to trigger download
    const a = document.createElement('a');
    a.href = url;
    a.download = `${title}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    setShowCreate(!hideInputs);
  }, [hideInputs]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col md={21}>
          <PageHeader title={title} />
        </Col>
        <Col md={1}>
          <Button
            style={{ border: "0px" }}
            onClick={handleExportExcel}
            icon={<ExportIcon color="action" fontSize="large" />}
          />
        </Col>
        <Col md={1}>
          <ExcelReader onCreateJson={handleUploadExcel} />
        </Col>
        <Col md={1}>
          <Button
            style={{ border: "0px" }}
            hidden={!showCreate}
            onClick={onClickCreateNew}
            icon={<CreateIcon color="secondary" fontSize="large" />}
          />
          <Button
            style={{ border: "0px" }}
            hidden={showCreate}
            onClick={onClickCancel}
            icon={<RemoveCircle color="error" fontSize="large" />}
          />
        </Col>
      </Row>
    </>
  );
};

export default FormTopRightButtonsComponent;
