import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { API_BASE_ROOT, handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Tooltip, Drawer, Upload, DatePicker, Checkbox, Input } from "antd";
import { Settings } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import { ExcelReader } from "../../excel/ExcelReader";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import DraggableModal from "../../../Components/DraggableModal";
import { ExclamationCircleOutlined, FileOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { BiddingLogProjects } from "./BiddingLogProjects";
import moment from "moment";
import BillOfQuantity from "../billOfQuantity/BillOfQuantity";
import Equipment from "../Equipment";
import Manpower from "../manpower/Manpower";
import BiddingLogQuotation from "../quotation/BiddingLogQuotation";
import BillOfQuantityVendorResponseAll from "../billOfQuantity/BillOfQuantityVendorResponseAll";
import { PrepareFileUploadEdit } from "../../../Components/ElementUtils";
import BiddingLogMobilization from "./BiddingLogMobilization";
import BiddingLogAssignment from "./BiddingLogAssignment";
import CustomBiddingMenu from "../../CustomMenu/CustomBiddingMenu";

export class BiddingLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "BiddingLog",
      controllerName: "biddingLog",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      tableListClientDocuments: [],
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  formRefFile = React.createRef();
  formRefClientDecision = React.createRef();
  formRefDeclineOk = React.createRef();
  formRefTenderingAddDocuments = React.createRef();
  formRefSendByEmailPrintOut = React.createRef();
  formRefTenderingSubmitType = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  generateReportEvaluation = async row => {
    this.setState({ spinLoading: true });
    let url = "/api/" + this.state.controllerName + "/getReportEvaluation/" + row.id;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        spinLoading: false
      });
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true,
        spinLoading: false
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false,
        spinLoading: false
      });
      showError("There is no created report for selected item");
    }
  };
  rfqStatus = async row => {
    let url = "/api/" + this.state.controllerName + "/getStatus/" + row.id;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        statusList: response.data,
        modalStatusShow: true
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  billOffQuantityModal = row => {
    this.setState({
      modalBillOffQuantityShow: true,
      id: row.id
    });
  };

  mobilizationModal = row => {
    this.setState({
      modalMobilizationShow: true,
      id: row.id
    });
  };

  manpowerModal = row => {
    this.setState({
      modalManpowerShow: true,
      id: row.id
    });
  };

  equipmentModal = row => {
    this.setState({
      modalEquipmentShow: true,
      id: row.id
    });
  };

  quotationModal = row => {
    this.setState({
      modalQuotationShow: true,
      id: row.id
    });
  };

  submitModal = row => {
    console.log("row", row);
    this.setState({
      modalTenderingSubmitTypeShow: true,
      id: row.id,
      selectedRow: row,
    }, () => {
      let byEmailWithAttachments = false;
      let byQuotationFormat = false;
      if (Boolean(row.tenderingSubmitType)) {
        if (row.tenderingSubmitType === "By_Email_With_Attachments") {
          byEmailWithAttachments = true;
        } else if (row.tenderingSubmitType === "By_Quotation_Format") {
          byQuotationFormat = true;
        }
      }
      this.formRefTenderingSubmitType.current.setFieldsValue({
        byQuotationFormat: byQuotationFormat,
        byEmailWithAttachments: byEmailWithAttachments,
      });
    });
  };

  addDocumentsModal = row => {
    console.log("row", row);
    this.setState({
      modalTenderingAddDocumentsShow: true,
      id: row.id,
      selectedRow: row,
    }, () => {
      let quotation = false;
      let equipmentLog = false;
      let billOfQuantity = false;
      let summary = false;
      if (Boolean(row.tenderingAddDocuments)) {
        if (row.tenderingAddDocuments === "Quotation") {
          quotation = true;
        } else if (row.tenderingAddDocuments === "Bill_Of_Quantity") {
          billOfQuantity = true;
        } else if (row.tenderingAddDocuments === "Equipment_Log") {
          equipmentLog = true;
        } else if (row.tenderingAddDocuments === "Summary") {
          summary = true;
        }
      }
      this.formRefTenderingAddDocuments.current.setFieldsValue({
        equipmentLog: equipmentLog,
        quotation: quotation,
        billOfQuantity: billOfQuantity,
        summary: summary,
      });
    });
  };

  declineModal = row => {
    this.setState({
      id: row.id,
      modalDeclineShow: true,
    });
  };

  clientDecisionModal = row => {
    console.log("row", row);
    this.setState({
      modalClientDecisionShow: true,
      id: row.id
    }, () => {
      let accepted = false;
      let rejected = false;
      let requiredRevision = false;
      if (Boolean(row.clientDecision)) {
        if (row.clientDecision === "Accepted") {
          accepted = true;
        } else if (row.clientDecision === "Rejected") {
          rejected = true;
        } else if (row.clientDecision === "Required_Revision") {
          requiredRevision = true;
        }
      }
      this.formRefClientDecision.current.setFieldsValue({
        clientDecisionDate: Boolean(row.clientDecisionDate) ? moment(row.clientDecisionDate) : null,
        requiredRevision: requiredRevision,
        accepted: accepted,
        rejected: rejected,
      });
    });
  };

  assignmentModal = row => {
    this.setState({
      id: row.id,
      modalAssignmentShow: true,
      random: Math.random(),
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    // let assignAction = {
    //   name: "Assign",
    //   icon: <Assignment fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
    //   actionClick: this.assignmentModal.bind(this)
    // };
    // let declineAction = {
    //   name: "Decline",
    //   icon: <NoteAdd fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
    //   actionClick: this.declineModal.bind(this)
    // };
    // let createBillOffQuantity = {
    //   name: "Bill Off Quantity",
    //   icon: <PlusOutlined fontSize="small" style={{ border: "0px", color: "#ff8400" }} />,
    //   actionClick: this.billOffQuantityModal.bind(this)
    // };

    // let createManpower = {
    //   name: "Manpower",
    //   icon: <Notes fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
    //   actionClick: this.manpowerModal.bind(this)
    // };

    // let createEquipment = {
    //   name: "Equipment",
    //   icon: <AttachFile fontSize="small" style={{ border: "0px", color: "#f0e0b0" }} />,
    //   actionClick: this.equipmentModal.bind(this)
    // };

    // let mobilizationAction = {
    //   name: "Mobilization",
    //   icon: <AttachFile fontSize="small" style={{ border: "0px", color: "#92b938" }} />,
    //   actionClick: this.mobilizationModal.bind(this)
    // };

    // let createQuotation = {
    //   name: "Quotation",
    //   icon: <ReplyOutlined fontSize="small" style={{ border: "0px", color: "#fee529" }} />,
    //   actionClick: this.quotationModal.bind(this)
    // };

    // let submitAction = {
    //   name: "Submit",
    //   icon: <SendIcon fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
    //   actionClick: this.submitModal.bind(this)
    // };

    // let desicionAction = {
    //   name: "Client Decision",
    //   icon: <CheckIcon fontSize="small" style={{ border: "0px", color: "#ff0000" }} />,
    //   actionClick: this.clientDecisionModal.bind(this)
    // };

    // let actionList = [];
    // if (!(Boolean(record.clientDecision) && record.clientDecision === "Accepted")) {
    //   actionList.push(assignAction);
    //   actionList.push(declineAction);
    //   actionList.push(createBillOffQuantity);
    //   actionList.push(createManpower);
    //   actionList.push(createEquipment);
    //   actionList.push(mobilizationAction);
    //   actionList.push(createQuotation);
    //   actionList.push(submitAction);
    //   actionList.push(desicionAction);
    // }
    // return (
    //   <React.Fragment>
    //     <DynamicActionMenu row={record} actionList={actionList} />
    //   </React.Fragment>
    // );

    if (!(Boolean(record.clientDecision) && record.clientDecision === "Accepted")) {
      return (
        <React.Fragment>
          <CustomBiddingMenu
            row={record}
            onAssignRow={record.hasAssign === false ? this.assignmentModal.bind(this) : null}
            onEditAssign={record.hasAssign === true ? this.assignmentModal.bind(this) : null}
            onDeclineRow={this.declineModal.bind(this)}
            onBillOfQuantityRow={this.billOffQuantityModal.bind(this)}
            onManpowerRow={this.manpowerModal.bind(this)}
            onEquipmentRow={this.equipmentModal.bind(this)}
            onMobilizationRow={this.mobilizationModal.bind(this)}
            onQuotationRow={this.quotationModal.bind(this)}
            onSubmitRow={this.submitModal.bind(this)}
            onClientDecisionRow={this.clientDecisionModal.bind(this)}
          />
        </React.Fragment>
      );
    } else {
      return "";
    }
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  saveClientDecision = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };
    let clientDecision = null;
    if (newItem.accepted) {
      clientDecision = "Accepted";
    } else if (newItem.requiredRevision) {
      clientDecision = "Required_Revision";
    } else if (newItem.rejected) {
      clientDecision = "Rejected";
    }
    newItem.clientDecision = clientDecision;

    if (Boolean(clientDecision)) {
      var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateClientDecision/" + newItem.id, newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.setState({
          modalClientDecisionShow: false,
        });
      }
    } else {
      showWarning("Please select Client Decision.");
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  fillClientDocuments = async (tenderingLogId) => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillClientDocuments/" + tenderingLogId);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        tableListClientDocuments: Boolean(response.data) ? response.data : []
      });
    }
  }

  saveBiddingDecline = async values => {
    let newObj = {
      biddingDecline: values.biddingDecline
    }
    var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/saveBiddingDecline/" + this.state.id, newObj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        modalDeclineOkShow: false,
      });
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  getBiddingDecline = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getBiddingDecline/" + this.state.id);
    if (response.type === "ERROR") {
      error(response);
    } else {
      let data = Boolean(response.data) ? response.data : null;
      this.setState({
        modalDeclineShow: false,
        modalDeclineOkShow: true,
      }, () => {
        this.formRefDeclineOk.current.setFieldsValue({
          biddingDecline: Boolean(data) ? data.biddingDecline : "",
        });
      });
    }
  };

  saveTenderingAddDocuments = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };
    let tenderingAddDocuments = null;
    if (newItem.quotation) {
      tenderingAddDocuments = "Quotation";
    } else if (newItem.billOfQuantity) {
      tenderingAddDocuments = "Bill_Of_Quantity";
    } else if (newItem.equipmentLog) {
      tenderingAddDocuments = "Equipment_Log";
    } else if (newItem.summary) {
      tenderingAddDocuments = "Summary";
    }
    newItem.tenderingAddDocuments = tenderingAddDocuments;

    if (Boolean(tenderingAddDocuments)) {
      var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/saveTenderingAddDocuments/" + newItem.id, newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        const { pagination } = this.state;
        this.restartTable({ pagination });
        this.setState({
          modalTenderingAddDocumentsShow: false,
          modalSendByEmailPrintOutShow: true,
        }, () => {
          const { selectedRow } = this.state;

          let sendByEmail = false;
          let printOut = false;
          if (Boolean(selectedRow.sendByEmailPrintOut)) {
            if (selectedRow.sendByEmailPrintOut === "SendByEmail") {
              sendByEmail = true;
            } else if (selectedRow.sendByEmailPrintOut === "PrintOut") {
              printOut = true;
            }
          }
          this.formRefSendByEmailPrintOut.current.setFieldsValue({
            sendByEmail: sendByEmail,
            printOut: printOut,
          });
        });
      }
    } else {
      showWarning("Please select Tendering Add Documents.");
    }
  };

  saveSendByEmailPrintOut = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };
    let sendByEmailPrintOut = null;
    if (newItem.sendByEmail) {
      sendByEmailPrintOut = "SendByEmail";
    } else if (newItem.printOut) {
      sendByEmailPrintOut = "PrintOut";
    }
    newItem.sendByEmailPrintOut = sendByEmailPrintOut;

    if (Boolean(sendByEmailPrintOut)) {
      var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/saveSendByEmailPrintOut/" + newItem.id, newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.setState({
          modalSendByEmailPrintOutShow: false,
        });
      }
    } else {
      showWarning("Please select Send By Email/Print Out.");
    }
  };

  saveTenderingSubmit = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };
    let tenderingSubmitType = null;
    if (newItem.byEmailWithAttachments) {
      tenderingSubmitType = "By_Email_With_Attachments";
    } else if (newItem.byQuotationFormat) {
      tenderingSubmitType = "By_Quotation_Format";
    }
    newItem.tenderingSubmitType = tenderingSubmitType;

    if (Boolean(tenderingSubmitType)) {
      var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/saveTenderingSubmit/" + newItem.id, newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        this.setState({
          modalTenderingSubmitTypeShow: false,
        });
        this.addDocumentsModal(this.state.selectedRow);
      }
    } else {
      showWarning("Please select tendering type.");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout2 = {
      labelCol: { span: 12 },
      wrapperCol: { span: 8 }
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const normFile = e => {
      console.log("Upload event:", e);

      if (Array.isArray(e)) {
        return e;
      }

      return e && e.fileList;
    };

    const columns = [
      { key: "key", render: this.action },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="BiddingLog.TenderProjectNo" defaultMessage="Tendering Project No" />,
        key: "id",
        render: record => {
          return record.tenderProjectNo;
        },
      },
      {
        title: <FormattedMessage id="BiddingLog.RevNo" defaultMessage="Rev. No" />,
        key: "revNo",
        render: record => {
          return record.revNo;
        },
        ...getColumnFilter({
          inputName: "revNo",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLog.date" defaultMessage="Date" />,
        key: "date",
        render: record => {
          return record.date;
        },
        ...getColumnFilter({
          inputName: "date",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLog.Status" defaultMessage="Status" />,
        key: "status",
        render: record => {
          return "";
          // return (
          //   Boolean(record.status) && (
          //     <React.Fragment>
          //       <div
          //         style={{
          //           cursor: "pointer",
          //           textDecoration: "underline",
          //           display: "flex",
          //           alignItems: "center"
          //         }}
          //       // onClick={e => this.rfqStatus(record)}
          //       >
          //         {record.status}
          //       </div>
          //     </React.Fragment>
          //   )
          // );
        },
      },
      {
        title: <FormattedMessage id="BiddingLog.client" defaultMessage="Client" />,
        key: "client",
        render: record => {
          return record.client;
        },
        ...getColumnFilter({
          inputName: "client",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLog.contractor" defaultMessage="Contractor" />,
        key: "contractor",
        render: record => {
          return record.contractor;
        },
        ...getColumnFilter({
          inputName: "contractor",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLog.projectDescription" defaultMessage="Project Description" />,
        key: "projectDescription",
        render: record => {
          return record.projectDescription;
        },
        ...getColumnFilter({
          inputName: "projectDescription",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLog.clientProjectNo" defaultMessage="Client Project No" />,
        key: "clientProjectNo",
        render: record => {
          return record.clientProjectNo;
        },
        ...getColumnFilter({
          inputName: "clientProjectNo",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLog.ClientDocuments" defaultMessage="Client Documents" />,
        key: "clientDocuments",
        render: record => {
          return <AntButton
            style={{ border: "0px" }}
            onClick={() => {
              this.setState({
                showClientDocumentsModal: true
              }, () => {
                this.fillClientDocuments(record.id);
              });
            }}
            icon={<FileOutlined color="secondary" fontSize="large" />}
          ></AntButton>
        }
      },
      {
        title: <FormattedMessage id="BiddingLog.tenderingType" defaultMessage="Tendering Type" />,
        key: "tenderingType",
        render: record => {
          return record.tenderingType;
        },
        ...getColumnFilter({
          inputName: "tenderingType",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLog.biddingClosingDate" defaultMessage="Bidding Closing Date" />,
        key: "biddingClosingDate",
        render: record => {
          return Boolean(record.biddingClosingDate) ? moment(record.biddingClosingDate).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "biddingClosingDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLog.tenderingDate" defaultMessage="Tendering Date" />,
        key: "tenderingDate",
        render: record => {
          return Boolean(record.tenderingDate) ? moment(record.tenderingDate).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "tenderingDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLog.projectStartDate" defaultMessage="Project Start Date" />,
        key: "projectStartDate",
        render: record => {
          return Boolean(record.projectStartDate) ? moment(record.projectStartDate).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "projectStartDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BiddingLog.BiddingDocuments" defaultMessage="Bidding Documents" />,
        key: "biddingDocuments",
        render: record => {
          return <AntButton
            style={{ border: "0px" }}
            onClick={() => {
              this.setState({
                showBiddingDocumentsModal: true
              });
            }}
            icon={<FileOutlined color="secondary" fontSize="large" />}
          ></AntButton>
        }
      }
    ];

    const columnsClientDocuments = [
      {
        title: <FormattedMessage id="BiddingLog.DocumentType" defaultMessage="Document Type" />,
        key: "documentTypeName",
        render: record => {
          return record.documentTypeName;
        },
        sorter: false,
      },
      {
        title: <FormattedMessage id="BiddingLog.FileName" defaultMessage="File Name" />,
        key: "fileName",
        render: record => {
          return record.fileName;
        },
        sorter: false,
      },
      {
        title: <FormattedMessage id="BiddingLog.DocumentNo" defaultMessage="Document No" />,
        key: "docNo",
        render: record => {
          if (Boolean(record.uploadPath)) {
            return <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline"
                }}
                onClick={() => {
                  this.setState({
                    showClientDocumentsFileModal: true,
                  }, () => {
                    this.formRefFile.current.setFieldsValue({
                      uploadPath: PrepareFileUploadEdit(record.uploadPath),
                    });
                  });
                }}
              >
                {record.docNo}
              </div>
            </React.Fragment>
          } else {
            return record.docNo;
          }
        },
        sorter: false,
      },
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="BiddingLog" hideElement={true}>
            <ExcelSheet name="BiddingLog" data={this.state.tableList}>
              <ExcelColumn label="Tendering Project No" value="tenderProjectNo" />
              <ExcelColumn label="rev No" value="revNo" />
              <ExcelColumn label="Date" value="date" />
              <ExcelColumn label="Status" value="status" />
              <ExcelColumn label="Client" value="client" />
              <ExcelColumn label="Contractor" value="contractor" />
              <ExcelColumn label="Project Description" value="projectDescription" />
              <ExcelColumn label="Client Project No" value="clientProjectNo" />
              <ExcelColumn label="Project Scope" value="projectScope" />
              <ExcelColumn label="Project Details" value="projectDetails" />
              <ExcelColumn label="Tendering Documents" value="tenderingDocuments" />
              <ExcelColumn label="Tendering Type" value="tenderingType" />
              <ExcelColumn label="Bidding Closing Date" value="biddingClosingDate" />
              <ExcelColumn label="Tendering Date" value="tenderingDate" />
              <ExcelColumn label="Project Start Date" value="projectStartDate" />
              <ExcelColumn label="Bidding Documents" value="biddingDocuments" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );
    return (
      <Badge.Ribbon
        text={<FormattedMessage id="biddingLog.pageTitle" defaultMessage="Bidding Log" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={19}></Col>
              <Col md={1}>
                <Tooltip title={<FormattedMessage id="BiddingLog.VendorResponse" defaultMessage="Vendor Response" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => {
                      this.setState({
                        showVendorResponseModal: true
                      });
                    }}
                    icon={<ThunderboltOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />}
                  ></AntButton>
                </Tooltip>
              </Col>
              <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <Tooltip title={<FormattedMessage id="BiddingLog.BiddingLogProjects" defaultMessage="Bidding Log Projects" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => {
                      this.setState({
                        showBiddingLogProjectsModal: true,
                        random: Math.random(),
                      });
                    }}
                    icon={<CreateIcon color="secondary" fontSize="large" />}
                  ></AntButton>
                </Tooltip>
              </Col>
              <Col md={1}>
                {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                  <Tooltip title={<FormattedMessage id="PurchaseRequisitionNew.Settings" defaultMessage="Settings" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => {
                        this.setState({ drawerSettingsShow: true });
                      }}
                      icon={<Settings color="error" fontSize="large" />}
                    ></AntButton>
                  </Tooltip>
                )}
              </Col>
            </Row>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <Drawer
            title={<FormattedMessage id="BiddingLog.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ drawerSettingsShow: false });
            }}
            open={this.state.drawerSettingsShow}
          >
            <PageSettings pageName={"BiddingLog"} />
          </Drawer>

          <DraggableModal
            title={<FormattedMessage id="BiddingLog.BiddingLogProjects" defaultMessage="Bidding Log Projects" />}
            centered
            width={1400}
            okButtonProps={{ hidden: true }}
            maskClosable={false}
            open={this.state.showBiddingLogProjectsModal}
            onCancel={() => {
              this.setState({ showBiddingLogProjectsModal: false });
            }}
            content={<BiddingLogProjects close={() => {
              this.setState({ showBiddingLogProjectsModal: false });
              const { pagination } = this.state;
              this.restartTable({ pagination });
            }} random={this.state.random} />}
          ></DraggableModal>

          <DraggableModal
            title={<FormattedMessage id="BiddingLog.BillOfQuantity" defaultMessage="Bill Of Quantity" />}
            centered
            width={1400}
            okButtonProps={{ hidden: true }}
            maskClosable={false}
            open={this.state.modalBillOffQuantityShow}
            onCancel={() => {
              this.setState({ modalBillOffQuantityShow: false });
            }}
            content={<BillOfQuantity tenderingLogId={this.state.id} random={Math.random()} />}
          ></DraggableModal>

          <DraggableModal
            title={<FormattedMessage id="BiddingLog.Manpower" defaultMessage="Manpower" />}
            centered
            width={1400}
            okButtonProps={{ hidden: true }}
            maskClosable={false}
            open={this.state.modalManpowerShow}
            onCancel={() => {
              this.setState({ modalManpowerShow: false });
            }}
            content={<Manpower tenderingLogId={this.state.id} random={Math.random()} />}
          ></DraggableModal>

          <DraggableModal
            title={<FormattedMessage id="BiddingLog.Equipment" defaultMessage="Equipment" />}
            centered
            width={1400}
            okButtonProps={{ hidden: true }}
            maskClosable={false}
            open={this.state.modalEquipmentShow}
            onCancel={() => {
              this.setState({ modalEquipmentShow: false });
            }}
            content={<Equipment tenderingLogId={this.state.id} random={Math.random()} />}
          ></DraggableModal>

          <DraggableModal
            title={<FormattedMessage id="BiddingLog.Quotation" defaultMessage="Quotation" />}
            centered
            width={1400}
            okButtonProps={{ hidden: true }}
            maskClosable={false}
            open={this.state.modalQuotationShow}
            onCancel={() => {
              this.setState({ modalQuotationShow: false });
            }}
            content={<BiddingLogQuotation tenderingLogId={this.state.id} random={Math.random()} />}
          ></DraggableModal>

          <DraggableModal
            title={<FormattedMessage id="BillOfQuantity.VendorResponse" defaultMessage="Vendor Response" />}
            centered
            width={1400}
            maskClosable={false}
            open={this.state.showVendorResponseModal}
            okButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState({ showVendorResponseModal: false });
            }}
            content={<BillOfQuantityVendorResponseAll random={Math.random()} />}
          />

          <DraggableModal
            title={<FormattedMessage id="BillOfQuantity.ClientDocuments" defaultMessage="Client Documents" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showClientDocumentsModal}
            okButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState({ showClientDocumentsModal: false });
            }}
            content={
              <Table
                bordered
                columns={columnsClientDocuments}
                dataSource={this.state.tableListClientDocuments}
                loading={this.state.loading}
                pagination={false}
              />
            }
          />

          <DraggableModal
            title={<FormattedMessage id="BillOfQuantity.File" defaultMessage="File" />}
            centered
            width={900}
            maskClosable={false}
            open={this.state.showClientDocumentsFileModal}
            okButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState({ showClientDocumentsFileModal: false });
            }}
            content={
              <Form initialValues={{ remember: false }} ref={this.formRefFile}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BiddingLog.File" defaultMessage="File" />}
                    name="uploadPath"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                  >
                    <Upload {...props}>
                    </Upload>
                  </Form.Item>
                }
              </Form>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="BillOfQuantity.BiddingDocuments" defaultMessage="Bidding Documents" />}
            centered
            width={400}
            maskClosable={false}
            open={this.state.showBiddingDocumentsModal}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState({ showBiddingDocumentsModal: false });
            }}
            content={
              <>
                <Button
                  style={{ width: "100%" }}
                  variant="secondary"
                >
                  {<FormattedMessage id="BiddingLog.BillOfQuantity" defaultMessage="Bill of Quantity" />}
                </Button>

                <br /> <br />
                <Button
                  style={{ width: "100%" }}
                  variant="secondary"
                >
                  {<FormattedMessage id="BiddingLog.Manpower" defaultMessage="Manpower" />}
                </Button>

                <br /> <br />
                <Button
                  style={{ width: "100%" }}
                  variant="secondary"
                >
                  {<FormattedMessage id="BiddingLog.Equipment" defaultMessage="Equipment" />}
                </Button>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="BiddingLog.Mobilization" defaultMessage="Mobilization" />}
            centered
            width={1400}
            okButtonProps={{ hidden: true }}
            maskClosable={false}
            open={this.state.modalMobilizationShow}
            onCancel={() => {
              this.setState({ modalMobilizationShow: false });
            }}
            content={<BiddingLogMobilization tenderingLogId={this.state.id} random={Math.random()}
              close={() => {
                this.setState({ modalMobilizationShow: false });
              }} />}
          ></DraggableModal>

          <DraggableModal
            title={<FormattedMessage id="BiddingLog.ClientDecision" defaultMessage="Client Decision" />}
            centered
            width={600}
            maskClosable={false}
            open={this.state.modalClientDecisionShow}
            onCancel={() => {
              this.setState({ modalClientDecisionShow: false });
            }}
            onOk={() => this.formRefClientDecision.current.submit()}
            content={
              <Form initialValues={{ remember: false }} onFinish={this.saveClientDecision} ref={this.formRefClientDecision}>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BiddingLog.Accepted" defaultMessage="Accepted" />}
                  name="accepted"
                  rules={[
                    { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox onChange={(e) => {
                    this.formRefClientDecision.current.setFieldsValue({
                      accepted: e.target.checked,
                      requiredRevision: !e.target.checked,
                      rejected: !e.target.checked,
                    })
                  }}></Checkbox>
                </Form.Item>

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BiddingLog.RequiredRevision" defaultMessage="Required Revision" />}
                  name="requiredRevision"
                  rules={[
                    { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox onChange={(e) => {
                    this.formRefClientDecision.current.setFieldsValue({
                      requiredRevision: e.target.checked,
                      accepted: !e.target.checked,
                      rejected: !e.target.checked,
                    })
                  }}></Checkbox>
                </Form.Item>

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BiddingLog.Rejected" defaultMessage="Rejected" />}
                  name="rejected"
                  rules={[
                    { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox onChange={(e) => {
                    this.formRefClientDecision.current.setFieldsValue({
                      rejected: e.target.checked,
                      accepted: !e.target.checked,
                      requiredRevision: !e.target.checked,
                    })
                  }}></Checkbox>
                </Form.Item>

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BiddingLog.Date" defaultMessage="Date" />}
                  name="clientDecisionDate"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
                </Form.Item>

              </Form>
            }
          ></DraggableModal>

          <DraggableModal
            title={
              <span>
                <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                  <FormattedMessage id="BiddingLog.Decline" defaultMessage="Decline" />
                </span>
              </span>
            }
            centered
            open={this.state.modalDeclineShow}
            onOk={() => this.getBiddingDecline()}
            onCancel={() => this.setState({ modalDeclineShow: false })}
            okText={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />}
            cancelText={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />}
            content={
              <>
                <FormattedMessage
                  id="BiddingLog.MessageAreYouSureDecline"
                  defaultMessage="Are you sure decline ?"
                />
              </>
            }
          />

          <DraggableModal
            title={
              <span>
                <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                  <FormattedMessage id="BiddingLog.Decline" defaultMessage="Decline" />
                </span>
              </span>
            }
            centered
            open={this.state.modalDeclineOkShow}
            onOk={() => {
              this.formRefDeclineOk.current.submit();
            }}
            onCancel={() => this.setState({ modalDeclineOkShow: false })}
            content={
              <Form initialValues={{ remember: false }} onFinish={this.saveBiddingDecline} ref={this.formRefDeclineOk}>
                <Form.Item
                  {...layout2}
                  label={<FormattedMessage id="BiddingLog.DeclineDescription" defaultMessage="The reason(s) of decline" />}
                  name="biddingDecline"

                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </Form>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="BiddingLog.Type" defaultMessage="Type" />}
            centered
            width={600}
            maskClosable={false}
            open={this.state.modalTenderingSubmitTypeShow}
            onCancel={() => {
              this.setState({ modalTenderingSubmitTypeShow: false });
            }}
            onOk={() => {
              this.formRefTenderingSubmitType.current.submit();
            }}
            content={
              <Form initialValues={{ remember: false }} onFinish={this.saveTenderingSubmit} ref={this.formRefTenderingSubmitType}>
                <Form.Item
                  {...layout2}
                  label={<FormattedMessage id="BiddingLog.ByEmailWithAttachments" defaultMessage="By Email With Attachments" />}
                  name="byEmailWithAttachments"
                  rules={[
                    { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox onChange={(e) => {
                    this.formRefTenderingSubmitType.current.setFieldsValue({
                      byEmailWithAttachments: e.target.checked,
                      byQuotationFormat: !e.target.checked,
                    })
                  }}></Checkbox>
                </Form.Item>

                <Form.Item
                  {...layout2}
                  label={<FormattedMessage id="BiddingLog.ByQuotationFormat" defaultMessage="By Quotation Format" />}
                  name="byQuotationFormat"
                  rules={[
                    { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox onChange={(e) => {
                    this.formRefTenderingSubmitType.current.setFieldsValue({
                      byQuotationFormat: e.target.checked,
                      byEmailWithAttachments: !e.target.checked,
                    })
                  }}></Checkbox>
                </Form.Item>
              </Form>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="BiddingLog.AddDocuments" defaultMessage="Add Documents" />}
            centered
            width={600}
            maskClosable={false}
            open={this.state.modalTenderingAddDocumentsShow}
            onCancel={() => {
              this.setState({ modalTenderingAddDocumentsShow: false });
            }}
            onOk={() => {
              this.formRefTenderingAddDocuments.current.submit();
            }}
            content={
              <Form initialValues={{ remember: false }} onFinish={this.saveTenderingAddDocuments} ref={this.formRefTenderingAddDocuments}>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BiddingLog.Quotation" defaultMessage="Quotation" />}
                  name="quotation"
                  rules={[
                    { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox onChange={(e) => {
                    this.formRefTenderingAddDocuments.current.setFieldsValue({
                      quotation: e.target.checked,
                      billOfQuantity: !e.target.checked,
                      equipmentLog: !e.target.checked,
                      summary: !e.target.checked,
                    })
                  }}></Checkbox>
                </Form.Item>

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BiddingLog.BillOfQuantity" defaultMessage="Bill of Quantity" />}
                  name="billOfQuantity"
                  rules={[
                    { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox onChange={(e) => {
                    this.formRefTenderingAddDocuments.current.setFieldsValue({
                      billOfQuantity: e.target.checked,
                      quotation: !e.target.checked,
                      equipmentLog: !e.target.checked,
                      summary: !e.target.checked,
                    })
                  }}></Checkbox>
                </Form.Item>

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BiddingLog.EquipmentLog" defaultMessage="Equipment Log" />}
                  name="equipmentLog"
                  rules={[
                    { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox onChange={(e) => {
                    this.formRefTenderingAddDocuments.current.setFieldsValue({
                      equipmentLog: e.target.checked,
                      quotation: !e.target.checked,
                      billOfQuantity: !e.target.checked,
                      summary: !e.target.checked,
                    })
                  }}></Checkbox>
                </Form.Item>

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BiddingLog.Summary" defaultMessage="Summary" />}
                  name="summary"
                  rules={[
                    { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox onChange={(e) => {
                    this.formRefTenderingAddDocuments.current.setFieldsValue({
                      summary: e.target.checked,
                      equipmentLog: !e.target.checked,
                      quotation: !e.target.checked,
                      billOfQuantity: !e.target.checked,
                    })
                  }}></Checkbox>
                </Form.Item>
              </Form>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="BiddingLog.Type" defaultMessage="Type" />}
            centered
            width={600}
            maskClosable={false}
            open={this.state.modalSendByEmailPrintOutShow}
            onCancel={() => {
              this.setState({ modalSendByEmailPrintOutShow: false });
            }}
            onOk={() => {
              this.formRefSendByEmailPrintOut.current.submit();
            }}
            content={
              <Form initialValues={{ remember: false }} onFinish={this.saveSendByEmailPrintOut} ref={this.formRefSendByEmailPrintOut}>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BiddingLog.SendByEmail" defaultMessage="Send By Email" />}
                  name="sendByEmail"
                  rules={[
                    { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox onChange={(e) => {
                    this.formRefSendByEmailPrintOut.current.setFieldsValue({
                      sendByEmail: e.target.checked,
                      printOut: !e.target.checked,
                    })
                  }}></Checkbox>
                </Form.Item>

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BiddingLog.PrintOut" defaultMessage="Print Out" />}
                  name="printOut"
                  rules={[
                    { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox onChange={(e) => {
                    this.formRefSendByEmailPrintOut.current.setFieldsValue({
                      printOut: e.target.checked,
                      sendByEmail: !e.target.checked,
                    })
                  }}></Checkbox>
                </Form.Item>
              </Form>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="BillOfQuantityAddProduct.Assignment" defaultMessage="Assignment" />}
            centered
            width={1200}
            maskClosable={false}
            open={this.state.modalAssignmentShow}
            onCancel={() => {
              this.setState({ modalAssignmentShow: false });
            }}
            okButtonProps={{ hidden: true }}
            content={
              <div className="card card-custom" style={{ padding: "1rem", minHeight: "100%" }}>
                <BiddingLogAssignment
                  random={this.state.random}
                  tenderingLogId={this.state.id}
                  close={() => {
                    this.setState({ modalAssignmentShow: false });
                  }}
                />
              </div>
            }
          />

        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BiddingLog);