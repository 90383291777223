import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Input, Tree, Form, Space, Row, Col } from "antd";
import { FcFolder, FcEditImage, FcAddressBook } from "react-icons/fc";
import { Menu, Item, useContextMenu } from "react-contexify";
import { FcTreeStructure, FcBiotech, FcMediumPriority, FcLibrary } from "react-icons/fc";
import { MdDelete } from "react-icons/md";
import "react-contexify/dist/ReactContexify.css";
import { showError, showSuccess, error } from "../../MessageHelper";
import DraggableModal from "../../Components/DraggableModal";
import { handleRequest } from "../../ApiConnector";

const Menu_Title = "Menu_Title";

const { show } = useContextMenu();

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

export class ItemCodeCriteriaOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "ItemCodeCriteriaOptions",
      controllerName: "itemCodeCriteriaOptions",
      id: null,
      description: "",
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      loadedKeys: [],
      searchValue: "",
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showTitleModal: false,
      refreshTree: true
    };
  }

  inputRef = React.createRef(null);

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    this.fillTree();
    this.fillComboboxes();
  }

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "Title"
    };

    console.log("treenode", treenode);
    var response = null;
    if (Boolean(treenode.key)) {
      response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitems/" + obj.id);
    }
    if (Boolean(response) && response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.icon =
          Boolean(element.type) && element.type === "Service" ? (
            <FcLibrary />
          ) : element.type === "Title" ? (
            <FcTreeStructure />
          ) : element.type === "SubTitle" ? (
            <FcAddressBook />
          ) : element.type === "Input" ? (
            <FcBiotech />
          ) : element.type === "Format" ? (
            <FcMediumPriority />
          ) : (
            <FcFolder />
          );

        element.title = element.value;
        element.id = element.key;
        element.key = element.key + "-" + element.type;
        if (!Boolean(element.type)) {
          element.type = "Title";
        }
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      this.setState({
        nodes: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  formRef = React.createRef();
  formRefTitle = React.createRef();

  fillComboboxes = async () => {

  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root/" + this.props.itemCodeCriteriaId);

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "Title";
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          nodes: [],
          refreshTree: true,
        });
      }
    }
  };

  onRightClick = (event, node) => {
    if (!(Boolean(this.props) && Boolean(this.props.onTargetKeysCheck))) {
      let parentNode = this.getParentNode(node);
      this.setState(
        {
          selectedNodeKey: node.key,
          selectedNode: node,
          parentNode: parentNode
        },
        this.displayMenu(event, Boolean(node) && Boolean(node.type) ? node.type : null)
      );
    }
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  handleItemClick = ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;

    console.log("AAA", event.currentTarget.id);
    console.log(selectedNode);
    console.log(parentNode);
    console.log(key);

    switch (event.currentTarget.id) {
      case "create_subTitle":
        this.setState({
          id: null,
          showTitleModal: true,
          parentId: selectedNode.id,
          type: "SubTitle"
        }, () => {
          this.formRefTitle.current.setFieldsValue({
            description: "",
          });
        });
        break;

      case "edit_title":
        this.setState({
          showEditTitleModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        }, () => {
          this.formRefTitle.current.setFieldsValue({
            description: selectedNode.value,
          });
        });
        break;

      case "delete_title":
        this.setState({
          showDeleteTitleModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;
    }
  };

  deleteTitle = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteTitleModal: false
        });
      }
    } else {
      showError("Title not found.");
    }
  };

  displayMenu = (e, type) => {
    console.log("TTT", type);
    if (Boolean(type)) {
      if (type === "Title") {
        show(e, { id: Menu_Title });
      }
    }
  };

  saveTitle = async values => {
    const { process } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId,
      itemCodeCriteriaId: this.props.itemCodeCriteriaId,
    };
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showTitleModal: false,
        showEditTitleModal: false
      });
    }
  };

  paste = () => {
    this.formRef.current.submit();
    this.formRefTitle.current.submit();
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      description: "",
      code: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      process: null,
      showOrderModal: false,
      showPasteModal: false,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showTitleModal: false
    });
  };

  expandSelectedNode = () => {
    let key = this.state.selectedNodeKey;
    let node = this.state.selectedNode;
    if (Boolean(key)) {
      let expandedKeys = Boolean(this.state.expandedKeys) ? this.state.expandedKeys : [];
      if (Boolean(expandedKeys.find(p => p === key))) {
        this.loadData(node, false);
      } else {
        this.setState({
          expandedKeys: [...expandedKeys, key]
        });
        this.loadData(node, false);
      }
    }
  };

  onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...this.state.nodes];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 &&
      // Has children
      info.node.props.expanded &&
      // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    this.setState({
      nodes: data
    });
  };

  onCheck = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    if (Boolean(this.props) && Boolean(this.props.onTargetKeysCheck)) {
      console.log("checkedKeys", e.checkedNodes);
      console.log("checkedKeys", e.halfCheckedKeys);
      this.props.onTargetKeysCheck(checkedKeys.checked);
    }
    this.setState({
      checkedKeys: checkedKeys,
      halfCheckedKeys: e.halfCheckedKeys,
      checkedNodes: e.checkedNodes
    });
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Button id="AddOptionsButton"
              onClick={() => {
                this.setState({
                  showTitleModal: true,
                  parentId: null
                }, () => {
                  this.formRefTitle.current.setFieldsValue({
                    description: "",
                  });
                });
              }}
              style={{ width: "100%" }} variant="primary">
              <FormattedMessage id="ItemCodeCriteriaOptions.AddOptions" defaultMessage="Add Options" />
            </Button>
          </Col>
        </Row>

        {this.state.refreshTree &&
          (Boolean(this.props) && Boolean(this.props.onTargetKeysCheck) ? (
            <Tree
              style={{ marginTop: "10px", lineHeight: "30px" }}
              className="draggable-tree"
              showLine={{ showLeafIcon: false }}
              showIcon
              checkStrictly={true}
              loadedKeys={this.state.loadedKeys}
              onLoad={this.onLoadData}
              loadData={this.loadData}
              onRightClick={({ event, node }) => this.onRightClick(event, node)}
              onExpand={this.onExpand}
              expandedKeys={this.state.expandedKeys}
              autoExpandParent={false}
              treeData={this.state.nodes}
              blockNode={true}
              draggable
              checkable
              onCheck={this.onCheck}
              checkedKeys={this.state.checkedKeys}
              halfCheckedKeys={this.state.halfCheckedKeys}
              onDragEnter={this.onDragEnter}
              onDrop={this.onDrop}
            />
          ) : (
            <Tree
              style={{ marginTop: "10px", lineHeight: "30px" }}
              className="draggable-tree"
              showLine={{ showLeafIcon: false }}
              showIcon
              checkStrictly={true}
              loadedKeys={this.state.loadedKeys}
              onLoad={this.onLoadData}
              loadData={this.loadData}
              onRightClick={({ event, node }) => this.onRightClick(event, node)}
              onExpand={this.onExpand}
              expandedKeys={this.state.expandedKeys}
              autoExpandParent={false}
              treeData={this.state.nodes}
              blockNode={true}
              // draggable
              onDragEnter={this.onDragEnter}
              onDrop={this.onDrop}
            />
          ))}

        <Menu id={Menu_Title}>
          <Item id="edit_title" onClick={this.handleItemClick}>
            <Space>
              <FcEditImage />
              <span>
                <FormattedMessage id="ItemCodeCriteriaOptions.Edit" defaultMessage="Edit" />
              </span>
            </Space>
          </Item>
          <Item id="create_subTitle" onClick={this.handleItemClick}>
            <Space>
              <FcEditImage />
              <span>
                <FormattedMessage id="ItemCodeCriteriaOptions.CreateSubTitle" defaultMessage="Create Sub-Title" />
              </span>
            </Space>
          </Item>
          <Item id="delete_title" onClick={this.handleItemClick}>
            <Space>
              <MdDelete />
              <span>
                <FormattedMessage id="ItemCodeCriteriaOptions.Delete" defaultMessage="Delete" />
              </span>
            </Space>
          </Item>
        </Menu>

        <DraggableModal
          title={<FormattedMessage id="ItemCodeCriteriaOptions.Title" defaultMessage="Title" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.showTitleModal}
          onOk={() => this.formRefTitle.current.submit()}
          onCancel={() => {
            this.setState({ showTitleModal: false });
          }}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveTitle} onFinishFailed={onFinishFailed} ref={this.formRefTitle}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ItemCodeCriteriaOptions.Title" defaultMessage="Title" />}
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </>
          }
        />

        {/* EditModal */}

        <DraggableModal
          title={<FormattedMessage id="ItemCodeCriteriaOptions.EditTitle" defaultMessage="Edit Title" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.showEditTitleModal}
          onOk={() => this.formRefTitle.current.submit()}
          onCancel={() => {
            this.setState({ showEditTitleModal: false });
          }}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveTitle} onFinishFailed={onFinishFailed} ref={this.formRefTitle}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ItemCodeCriteriaOptions.Title" defaultMessage="Title" />}
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </>
          }
        />

        {/* DeleteModal */}

        <DraggableModal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={400}
          maskClosable={false}
          open={this.state.showDeleteTitleModal}
          okText={<FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />}
          onOk={this.deleteTitle}
          onCancel={() => {
            this.setState({ showDeleteTitleModal: false });
          }}
          content={
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          }
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ItemCodeCriteriaOptions);