import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Input, Button as AntButton, Tree, Modal } from "antd";
import { fillFilterOperations } from "../../../Components/DataTableFilterComponent";
import { Table as TableBoot } from "react-bootstrap";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

export class AdminNotes extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "AdminNotes",
      controllerName: "pageSettings",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      houseRoomList: [],
      loadingButtonSubmit: false,
      nodes: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
    fillFilterOperations();

    var responseAdminNotes = await handleRequest("GET", "/api/" + this.state.controllerName + "/pageAdminNotess/" + this.props.pageName);
    if (responseAdminNotes.type === "ERROR") {
      error(responseAdminNotes);
    } else {
      if (Boolean(responseAdminNotes.data)) {
        let data = responseAdminNotes.data;
        console.log("data", data);
        if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
          this.formRef.current.setFieldsValue({
            adminNotess: data
          });
        }
      } else {
        if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
          this.formRef.current.resetFields();
        }
      }
    }
  };

  fillComboboxes = async () => {
    var responseAction = await handleRequest("GET", "/api/" + this.state.controllerName + "/actions/" + this.props.pageName);
    if (responseAction.type === "ERROR") {
      error(responseAction);
    } else {
      this.setState({
        actionsSelectItems: Boolean(responseAction.data) ? responseAction.data : []
      });
    }

    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(response.data))
        this.setState({
          nodes: Boolean(response.data) ? response.data : []
        });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: "",
        tableVisibleModal: true
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder,
          tableVisibleModal: true
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: "",
          tableVisibleModal: true
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  resetInputs = () => { };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  save = async values => {
    console.log("values", values);
    let list = [];
    if (Boolean(values) && Boolean(values.adminNotess) && values.adminNotess.length > 0) {
      values.adminNotess.forEach(element => {
        element.pageName = this.props.pageName;

        let adminNotesTos = [];
        if (Boolean(element.adminNotesTos) && element.adminNotesTos.length > 0) {
          element.adminNotesTos.forEach(to => {
            console.log("to", to);
            if (Boolean(to.id) && to.id > 0) {
              adminNotesTos.push({ id: to.id, userId: to.userId, departmentId: to.departmentId });
            } else {
              let type = to.split("~")[0];
              let value = to.split("~")[1];
              if (Boolean(type) && Boolean(value)) {
                if (type === "CODE") {
                  adminNotesTos.push({ userId: value });
                } else if (type === "CATEGORY") {
                  adminNotesTos.push({ departmentId: value });
                }
              }
            }
          });
        }
        element.adminNotesTos = adminNotesTos;

        let adminNotesInfos = [];
        if (Boolean(element.adminNotesInfos) && element.adminNotesInfos.length > 0) {
          element.adminNotesInfos.forEach(info => {
            if (Boolean(info.id) && info.id > 0) {
              adminNotesInfos.push({ id: info.id, userId: info.userId, departmentId: info.departmentId });
            } else {
              let type = info.split("~")[0];
              let value = info.split("~")[1];
              if (Boolean(type) && Boolean(value)) {
                if (type === "CODE") {
                  adminNotesInfos.push({ userId: value });
                } else if (type === "CATEGORY") {
                  adminNotesInfos.push({ departmentId: value });
                }
              }
            }
          });
        }
        element.adminNotesInfos = adminNotesInfos;

        list.push(element);
      });
    }
    console.log("list", list);

    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAdminNotes", list);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.props.nextTab();
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  onCheckTo = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    this.setState({
      checkedKeysTo: checkedKeys,
      halfCheckedKeysTo: e.halfCheckedKeys,
      checkedNodesTo: e.checkedNodes
    });
  };

  onCheckInfo = async (checkedKeys, e) => {
    this.setState({
      checkedKeysInfo: checkedKeys,
      halfCheckedKeysInfo: e.halfCheckedKeys,
      checkedNodesInfo: e.checkedNodes
    });
  };

  render() {
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} ref={this.formRef}>
          <Form.List name="adminNotess">
            {(fields, { add, remove }) => {
              const handleAddClick = () => {
                add();
              };

              return (
                <>
                  <TableBoot bordered size="sm">
                    <thead>
                      <tr>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="RoomQuantity.ItemNo" defaultMessage="Item No" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="RoomQuantity.Note" defaultMessage="Note" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="RoomQuantity.To" defaultMessage="To" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map((field, index) => {
                        return (
                          <tr key={field.key}>
                            <Form.Item hidden name={[field.name, "id"]}></Form.Item>
                            <Form.Item hidden name={[field.name, "adminNotesTos"]}></Form.Item>
                            <Form.Item hidden name={[field.name, "adminNotesInfos"]}></Form.Item>
                            <td>{index + 1}</td>

                            <td>
                              <Form.Item noStyle name={[field.name, "note"]}>
                                <Input placeholder="Message"></Input>
                              </Form.Item>
                            </td>

                            <td>
                              <Form.Item noStyle name={[field.name, "noteTo"]}>
                                <Input placeholder="To"></Input>
                              </Form.Item>
                            </td>
                            <td>
                              <MinusCircleOutlined onClick={() => remove(field.name)} />
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan={11}>
                          <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                            <FormattedMessage id={"AdminNotes.AddNew"} defaultMessage={"Add New"} />
                          </AntButton>
                        </td>
                      </tr>
                    </tbody>
                  </TableBoot>
                </>
              );
            }}
          </Form.List>
          <br />
          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={19} lg={19} xl={19} xxl={19}></Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Button type="submit" style={{ width: "100%" }} variant="success">
                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
              </Button>
            </Col>
          </Row>
        </Form>

        <Modal
          title={<FormattedMessage id="AdminNotes.To" defaultMessage="To" />}
          closable="false"
          open={this.state.modalToDepartmentShow}
          onCancel={() => {
            this.setState({ modalToDepartmentShow: false });
          }}
          width={800}
          centered
          okButtonProps={{ hidden: false }}
          onOk={() => {
            const { checkedKeysTo, fieldKey } = this.state;

            if (Boolean(checkedKeysTo) && checkedKeysTo.length > 0) {
              const adminNotess = this.formRef.current.getFieldValue("adminNotess");

              const row = adminNotess[fieldKey];
              console.log("row", row);

              row.adminNotesTos = checkedKeysTo;

              console.log("adminNotess", adminNotess);

              this.formRef.current.setFieldsValue({
                adminNotess: adminNotess
              });
              this.setState({ modalToDepartmentShow: false });
            } else {
              showWarning("Please select item.");
            }
          }}
        >
          <Tree
            blockNode
            checkable
            showLine={true}
            onCheck={this.onCheckTo}
            checkedKeys={this.state.checkedKeysTo}
            halfCheckedKeys={this.state.halfCheckedKeysTo}
            treeData={this.state.nodes}
          />
        </Modal>

        <Modal
          title={<FormattedMessage id="AdminNotes.Info" defaultMessage="Info" />}
          closable="false"
          open={this.state.modalInfoDepartmentShow}
          onCancel={() => {
            this.setState({ modalInfoDepartmentShow: false });
          }}
          width={800}
          centered
          okButtonProps={{ hidden: false }}
          onOk={() => {
            const { checkedKeysInfo, fieldKey } = this.state;

            if (Boolean(checkedKeysInfo) && checkedKeysInfo.length > 0) {
              const adminNotess = this.formRef.current.getFieldValue("adminNotess");

              const row = adminNotess[fieldKey];
              console.log("row", row);

              row.adminNotesInfos = checkedKeysInfo;

              console.log("adminNotess", adminNotess);

              this.formRef.current.setFieldsValue({
                adminNotess: adminNotess
              });
              this.setState({ modalInfoDepartmentShow: false });
            } else {
              showWarning("Please select item.");
            }
          }}
        >
          <Tree
            blockNode
            checkable
            showLine={true}
            onCheck={this.onCheckInfo}
            checkedKeys={this.state.checkedKeysInfo}
            halfCheckedKeys={this.state.halfCheckedKeysInfo}
            treeData={this.state.nodes}
          />
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(AdminNotes);
