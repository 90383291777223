import React, { useState, useEffect } from "react";
import { handleRequest } from "../../ApiConnector";
import { error } from "../../MessageHelper";
import { Table, Col, Select, Row, DatePicker } from "antd";
import { FormattedMessage } from "react-intl";
import moment from "moment";

const DeliveryTime = () => {
  const [tableList, setTableList] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(6, "M"));
  const [endDate, setEndDate] = useState(moment());
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    showSizeChanger: true,
    pageSizeOptions: ["2", "5", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
    total: 0
  });

  useEffect(() => {
    asyncFetch();
  }, [startDate, endDate]);

  const asyncFetch = async () => {
    const search = {
      firstResult: pagination.current - 1,
      maxResults: pagination.pageSize,
    };
    console.log(search);
    var response = await handleRequest("POST", "/api/dashboard/deliveryTime", search);
    if (response.type === "ERROR") {
      error(response);
    } else {
      setTableList(Boolean(response.data.data) ? response.data.data : null);
      setPagination({
        ...pagination,
        total: response.data.totalRecords
      },)
    }
  };
  const handleTableChange = (pagination, filters, sorter) => {
    restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  const restartTable = async (params = {}) => {
    const search = {
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
    };
    console.log(search);
    var response = await handleRequest("POST", "/api/dashboard/deliveryTime", search);
    if (response.type === "ERROR") {
      error(response);
    } else {
      setTableList(Boolean(response.data.data) ? response.data.data : null);
      setPagination({
        ...params.pagination,
        total: response.data.totalRecords
      },)
    }
  };

  const columns = [
    {
      title: "",
      key: "type",
      render: record => {
        return record.type;
      },
    },


    {
      title: <FormattedMessage id="DeliveryTime.Time" defaultMessage="Time (Month)" />,
      key: "time",
      render: record => {
        return record.time;
      },
      align: "center"
    },


  ]

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={4}></Col>
        <Col lg={6}>
          <DatePicker
            placeholder="Start Date"
            style={{ width: "100%" }}
            value={startDate}
            onChange={date => {
              setStartDate(date);
            }}
          />
        </Col>
        <Col lg={6}>
          <DatePicker
            placeholder="End Date"
            style={{ width: "100%" }}
            value={endDate}
            onChange={date => {
              setEndDate(date);
            }}
          />

        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={24}>
          <Table
            scroll={{ y: 900, scrollToFirstRowOnChange: true }}
            bordered
            columns={columns}
            dataSource={tableList}
            onChange={handleTableChange}
            pagination={pagination}
          />
        </Col>

      </Row>
    </>
  );
};

export default DeliveryTime;
