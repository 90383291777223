import { FormattedMessage } from "react-intl";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { NoteAdd, ReplyOutlined } from "@material-ui/icons";
import SendIcon from "@material-ui/icons/Send";
import CheckIcon from "@material-ui/icons/CheckCircleOutlineRounded";

export default ({
  row,
  onDeclineRow,
  onSubmitRow,
  onManpowerRow,
  onBillOfQuantityRow,
  onAssignRow,
  onEquipmentRow,
  onMobilizationRow,
  onExpensesRow,
  onEditAssign,
  onQuotationRow,
  onClientDesicionRow,
  size,
  editBgColor,
  deleteBgColor
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(!open);
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const declineRow = () => {
    if (onDeclineRow) {
      onDeclineRow(row);
      setAnchorEl(null);
    }
  };

  const editAssign = () => {
    if (onEditAssign) {
      onEditAssign(row);
      setAnchorEl(null);
    }
  };

  const manpowerRow = () => {
    if (onManpowerRow) {
      onManpowerRow(row);
      setAnchorEl(null);
    }
  };

  const billOfQuantityRow = () => {
    if (onBillOfQuantityRow) {
      onBillOfQuantityRow(row);
      setAnchorEl(null);
    }
  };

  const assignRow = () => {
    if (onAssignRow) {
      onAssignRow(row);
      setAnchorEl(null);
    }
  };

  const equipmentRow = () => {
    if (onEquipmentRow) {
      onEquipmentRow(row);
      setAnchorEl(null);
    }
  };

  const mobilizationRow = () => {
    if (onMobilizationRow) {
      onMobilizationRow(row);
      setAnchorEl(null);
    }
  };

  const expensesRow = () => {
    if (onExpensesRow) {
      onExpensesRow(row);
      setAnchorEl(null);
    }
  };

  const quotationRow = () => {
    if (onQuotationRow) {
      onQuotationRow(row);
      setAnchorEl(null);
    }
  };

  const submitRow = () => {
    if (onSubmitRow) {
      onSubmitRow(row);
      setAnchorEl(null);
    }
  };

  const clientDesicionRow = () => {
    if (onClientDesicionRow) {
      onClientDesicionRow(row);
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick} size={size}>
        <MenuIcon color="primary" />
      </IconButton>
      <Menu
        id="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >

        {Boolean(onAssignRow) && (
          <>
            <MenuItem onClick={assignRow} style={{ backgroundColor: "#" + editBgColor }}>
              <ListItemIcon>
                <ReplyOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />
              </ListItemIcon>
              <Typography variant="inherit">
                <FormattedMessage id="GeneralAction.Assign" defaultMessage="Assign" />
              </Typography>
            </MenuItem>
            <Divider />
          </>
        )}

        {Boolean(onEditAssign) && (
          <>
            <MenuItem onClick={editAssign} style={{ backgroundColor: "#" + editBgColor }}>
              <ListItemIcon>
                <ReplyOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />
              </ListItemIcon>
              <Typography variant="inherit">
                <FormattedMessage id="GeneralAction.EditAssignments" defaultMessage="Edit Assignments" />
              </Typography>
            </MenuItem>
            <Divider />
          </>
        )}

        <MenuItem style={{ backgroundColor: "#" + editBgColor }}>
          <ListItem button>
            <ListItemIcon>
              <EditIcon fontSize="small" style={{ border: "0px", color: "#007bff" }} />
            </ListItemIcon>
            <ListItemText primary="Pricing" />
          </ListItem>
          <>
            <Typography variant="inherit">
              <ListItem button onClick={handleClickOpen}>
                {open ? <IconExpandLess /> : <IconExpandMore />}
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Divider />
                <List component="div" disablePadding>
                  <ListItem onClick={billOfQuantityRow} button>
                    <ListItemText inset primary={<FormattedMessage id="GeneralAction.BillOfQuantity" defaultMessage="Bill of Quantity" />} />
                  </ListItem>

                  <ListItem onClick={manpowerRow} button>
                    <ListItemText inset primary={<FormattedMessage id="GeneralAction.Manpower" defaultMessage="Manpower" />} />
                  </ListItem>

                  <ListItem onClick={equipmentRow} button>
                    <ListItemText inset primary={<FormattedMessage id="GeneralAction.Equipment" defaultMessage="Equipment" />} />
                  </ListItem>

                  <ListItem onClick={mobilizationRow} button>
                    <ListItemText inset primary={<FormattedMessage id="GeneralAction.Mobilization" defaultMessage="Mobilization" />} />
                  </ListItem>

                  <ListItem onClick={expensesRow} button>
                    <ListItemText inset primary={<FormattedMessage id="GeneralAction.Expenses" defaultMessage="Expenses" />} />
                  </ListItem>
                </List>
              </Collapse>
            </Typography>
          </>
        </MenuItem>
        <Divider />

        {Boolean(onQuotationRow) && (
          <>
            <MenuItem onClick={quotationRow} style={{ backgroundColor: "#" + editBgColor }}>
              <ListItemIcon>
                <ReplyOutlined fontSize="small" style={{ border: "0px", color: "#fee529" }} />
              </ListItemIcon>
              <Typography variant="inherit">
                <FormattedMessage id="GeneralAction.Quotations" defaultMessage="Quotations" />
              </Typography>
            </MenuItem>
            <Divider />
          </>
        )}

        {Boolean(onSubmitRow) && (
          <>
            <MenuItem onClick={submitRow} style={{ backgroundColor: "#" + editBgColor }}>
              <ListItemIcon>
                <SendIcon fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />
              </ListItemIcon>
              <Typography variant="inherit">
                <FormattedMessage id="GeneralAction.Submit" defaultMessage="Submit" />
              </Typography>
            </MenuItem>
            <Divider />
          </>
        )}

        {Boolean(onClientDesicionRow) && (
          <>
            <MenuItem onClick={clientDesicionRow} style={{ backgroundColor: "#" + editBgColor }}>
              <ListItemIcon>
                <CheckIcon fontSize="small" style={{ border: "0px", color: "#ff0000" }} />
              </ListItemIcon>
              <Typography variant="inherit">
                <FormattedMessage id="GeneralAction.ClientDecision" defaultMessage="Client Decision" />
              </Typography>
            </MenuItem>
            <Divider />
          </>
        )}

        {Boolean(onDeclineRow) && (
          <>
            <MenuItem onClick={declineRow} style={{ backgroundColor: "#" + editBgColor }}>
              <ListItemIcon>
                <NoteAdd fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />
              </ListItemIcon>
              <Typography variant="inherit">
                <FormattedMessage id="GeneralAction.Decline" defaultMessage="Decline" />
              </Typography>
            </MenuItem>
            <Divider />
          </>
        )}
      </Menu>
    </div>
  );
};