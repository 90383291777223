import { UploadOutlined, PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {
  Button as AntButton,
  Col,
  Badge,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Checkbox,
  Image,
  Upload,
  Modal,
  Space,
  Select,
  Divider,
  Transfer
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest, API_BASE_ROOT } from "../../ApiConnector";
import { error, showError, showSuccess } from "../../MessageHelper";
import ActionMenu from "../CustomMenu/ActionMenu";
import { OrderList } from "primereact/orderlist";
import SortIcon from "@material-ui/icons/Sort";
import DraggableModal from "../../Components/DraggableModal";

const { Option } = Select;

export class StockCardRenameCriteria2 extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "stockCardRenameCriteria2",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      filePath: "",
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      templateList: [],
      targetKeys: [],
      detailsTransfer: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initPage();
  };

  initPage = async params => {
    console.log("AAA", this.props);
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();

    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.resetFields();
    }
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initPage();
    }
  };

  fillComboboxes = async () => {};

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.props.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    const newItem = {
      categoryId: this.props.categoryId0
    };
    if (this.props.descriptionType === "ADD") {
      newItem.descriptionId = this.props.descriptionId;
      newItem.descriptionSelectId = null;
    } else {
      newItem.descriptionId = null;
      newItem.descriptionSelectId = this.props.descriptionId;
    }
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/getRenameCriteria", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      var details = Boolean(response.data) && Boolean(response.data.details) && response.data.details.length > 0 ? response.data.details : null;
      console.log("AAA", details);
      if (Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(details)) {
        this.setState({
          details: details,
          detailsTransfer: details
        });
        this.formRef.current.setFieldsValue({
          details: details
        });
      } else {
        this.setState({
          details: []
        });
        if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
          this.formRef.current.resetFields();
        }
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values
    };
    if (this.props.descriptionType === "ADD") {
      newItem.descriptionId = this.props.descriptionId;
      newItem.descriptionSelectId = null;
    } else {
      newItem.descriptionId = null;
      newItem.descriptionSelectId = this.props.descriptionId;
    }

    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/save", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.props.saveStockCardRenameCriteria();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  onClickOrder = () => {
    this.setState({
      modalOrderShow: true,
      modalTransferShow: true
    });
  };

  template(item) {
    return (
      <div className="p-clearfix">
        <div style={{ fontSize: "12px", margin: "5px 5px 0 0" }}>{item.itemDefinition}</div>
      </div>
    );
  }

  saveOrder = async () => {
    const list = this.state.details;
    list.forEach((element, i) => {
      element.orderNo = i;
    });

    this.formRef.current.setFieldsValue({
      details: list
    });

    // var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/order", list);
    // if (response.type === "ERROR") {
    //   error(response);
    // } else {
    this.setState({
      modalOrderShow: false
    });
    // }
  };

  onChange = (newTargetKeys, direction, moveKeys) => {
    console.log(newTargetKeys, direction, moveKeys);
    this.setState({
      targetKeys: newTargetKeys
    });
  };

  okTransfer = () => {
    const transferredItems = this.state.details.filter(item => this.state.targetKeys.includes(item.id));
    console.log("TransferedItemsList", transferredItems);
    this.setState({
      modalTransferShow: false,
      details: transferredItems
    });
    this.formRef.current.setFieldsValue({
      details: transferredItems
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };

    const layout2 = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 }
    };

    const details = Boolean(this.state.details) && this.state.details.length > 0 ? this.state.details : [];
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Form.List name="details">
            {(fields, { add, remove }) => (
              <>
                {console.log("fields", fields)}
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => {
                    this.onClickOrder();
                  }}
                  icon={<SortIcon fontSize="large" color="secondary" />}
                ></AntButton>

                {fields.map((field, index) =>
                  Boolean(details) &&
                  details.length > 0 &&
                  Boolean(details[field.key]) &&
                  Boolean(details[field.key].itemDefinition) &&
                  details[field.key].itemDefinition === "Text" ? (
                    Boolean(details) &&
                    details.length > 0 &&
                    Boolean(details[field.key]) &&
                    Boolean(details[field.key].itemDefinition) && (
                      <Form.Item {...layout} key={field.key} label={details[field.key].itemDefinition}>
                        <Form.Item
                          {...field}
                          name={[field.name, "selected"]}
                          style={{
                            display: "inline-block",
                            width: "calc(40%)",
                            marginBottom: "5px"
                          }}
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          valuePropName="checked"
                        >
                          <Checkbox style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "textValue"]}
                          extra={<FormattedMessage id="Stockcard.Text" defaultMessage="Text" />}
                          style={{
                            display: "inline-block",
                            width: "calc(20%)",
                            marginBottom: "5px",
                            marginLeft: "5px"
                          }}
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "nextSeperation"]}
                          extra={<FormattedMessage id="Stockcard.NextSeperation" defaultMessage="Next Seperation" />}
                          style={{
                            display: "inline-block",
                            width: "calc(20%)",
                            marginBottom: "5px",
                            marginLeft: "5px"
                          }}
                          rules={[
                            { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                            {
                              max: 1,
                              message: "Value must be 1 character"
                            }
                          ]}
                          length
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      </Form.Item>
                    )
                  ) : Boolean(details) &&
                    details.length > 0 &&
                    Boolean(details[field.key]) &&
                    Boolean(details[field.key].itemDefinition) &&
                    details[field.key].itemDefinition === "Dimensions" ? (
                    Boolean(details) &&
                    details.length > 0 &&
                    Boolean(details[field.key]) &&
                    Boolean(details[field.key].itemDefinition) && (
                      <Form.Item {...layout} key={field.key} label={details[field.key].itemDefinition}>
                        <Form.Item
                          {...field}
                          name={[field.name, "selected"]}
                          style={{
                            display: "inline-block",
                            width: "calc(40%)",
                            marginBottom: "5px"
                          }}
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          valuePropName="checked"
                        >
                          <Checkbox style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "nextSeperation"]}
                          extra={<FormattedMessage id="Stockcard.NextSeperation" defaultMessage="Next Seperation" />}
                          style={{
                            display: "inline-block",
                            width: "calc(20%)",
                            marginBottom: "5px",
                            marginLeft: "5px"
                          }}
                          rules={[
                            { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                            {
                              max: 1,
                              message: "Value must be 1 character"
                            }
                          ]}
                          length
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "betweenSeperation"]}
                          extra={<FormattedMessage id="Stockcard.InBetweenSeperation" defaultMessage="In Between Seperation" />}
                          style={{
                            display: "inline-block",
                            width: "calc(20%)",
                            marginBottom: "5px",
                            marginLeft: "5px"
                          }}
                          rules={[
                            { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                            {
                              max: 1,
                              message: "Value must be 1 character"
                            }
                          ]}
                          length
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      </Form.Item>
                    )
                  ) : Boolean(details) &&
                    details.length > 0 &&
                    Boolean(details[field.key]) &&
                    Boolean(details[field.key].itemDefinition) &&
                    details[field.key].childDimension ? (
                    <Form.Item {...layout2} key={field.key} label={details[field.key].itemDefinition}>
                      <Form.Item
                        {...field}
                        name={[field.name, "selected"]}
                        style={{
                          display: "inline-block",
                          width: "calc(40%)",
                          marginBottom: "5px"
                        }}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        valuePropName="checked"
                      >
                        <Checkbox style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, "textValue"]}
                        extra={<FormattedMessage id="Stockcard.Text" defaultMessage="Text" />}
                        style={{
                          display: "inline-block",
                          width: "calc(40%)",
                          marginBottom: "5px",
                          marginLeft: "5px"
                        }}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    </Form.Item>
                  ) : (
                    <Form.Item {...layout} key={field.key} label={details[field.key].itemDefinition}>
                      <Form.Item
                        {...field}
                        name={[field.name, "selected"]}
                        style={{
                          display: "inline-block",
                          width: "calc(40%)",
                          marginBottom: "5px"
                        }}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        valuePropName="checked"
                      >
                        <Checkbox style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        name={[field.name, "textValue"]}
                        extra={<FormattedMessage id="Stockcard.Text" defaultMessage="Text" />}
                        style={{
                          display: "inline-block",
                          width: "calc(20%)",
                          marginBottom: "5px",
                          marginLeft: "5px"
                        }}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        name={[field.name, "nextSeperation"]}
                        extra={<FormattedMessage id="Stockcard.NextSeperation" defaultMessage="Next Seperation" />}
                        style={{
                          display: "inline-block",
                          width: "calc(20%)",
                          marginBottom: "5px",
                          marginLeft: "5px"
                        }}
                        rules={[
                          { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                          {
                            max: 1,
                            message: "Value must be 1 character"
                          }
                        ]}
                        length
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    </Form.Item>
                  )
                )}
              </>
            )}
          </Form.List>
          {
            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Col>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            </Row>
          }
        </Form>

        <Modal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={500}
          okButtonProps={{ hidden: true }}
          open={this.state.modalDeleteShow}
          onCancel={() => {
            this.setState({ modalDeleteShow: false });
          }}
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalDeleteShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Space>
          ]}
        >
          <p>
            <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
          </p>
        </Modal>

        <Modal
          title={<FormattedMessage id="StockCardRenameCriteria2.Order" defaultMessage="Order" />}
          centered
          width={800}
          okButtonProps={{ hidden: true }}
          open={this.state.modalOrderShow}
          onCancel={() => {
            this.setState({ modalOrderShow: false });
          }}
          footer={[
            <Space>
              <Button id="SaveButton" onClick={() => this.saveOrder()} style={{ width: "100%" }} variant="success">
                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
              </Button>
            </Space>
          ]}
        >
          {Boolean(this.state.details) && this.state.details.length > 0 && (
            <OrderList
              value={this.state.details}
              responsive={true}
              itemTemplate={this.template}
              onChange={e => this.setState({ details: e.value })}
            ></OrderList>
          )}
        </Modal>

        <DraggableModal
          centered
          width={1150}
          maskClosable={false}
          open={this.state.modalTransferShow}
          onCancel={() => {
            this.setState({ modalTransferShow: false });
          }}
          onOk={this.okTransfer}
          content={
            <Badge.Ribbon text="Rename Settings Show" placement="start" color="purple" style={{ fontSize: 12 }}>
              <div className="card card-custom" style={{ padding: "1rem", minHeight: "100%" }}>
                <Transfer
                  showSearch
                  listStyle={{
                    width: 500,
                    height: 500
                  }}
                  style={{ marginTop: "2rem", minHeight: "100%" }}
                  dataSource={this.state.detailsTransfer}
                  rowKey={record => record.id}
                  titles={["Source", "Target"]}
                  targetKeys={this.state.targetKeys}
                  onChange={this.onChange}
                  render={item => `${item.itemDefinition}`}
                  oneWay={true}
                  pagination
                />
              </div>
            </Badge.Ribbon>
          }
        ></DraggableModal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StockCardRenameCriteria2);
