import BarChart from "./BarChart";
import LineChart from "./LineChart";
import PieChart from "./PieChart";

const DashboardCharts = {
  BarChart: BarChart,
  LineChart: LineChart,
  PieChart: PieChart
};
export { DashboardCharts };
