import { Cascader, Checkbox, Col as ColAnt, DatePicker, Form as FormAnt, Input, InputNumber, Row as RowAnt, Select, Table } from "antd";
import React, { Component } from "react";
import { Button as ReactButton, Col, Modal, Row } from "react-bootstrap";
import "react-contexify/dist/ReactContexify.min.css";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import CustomMaterialMenu from "../CustomMenu/CustomMaterialMenu";

const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;

const inputTypeSelectItems = [
  {
    id: "TEXT",
    value: "Text",
    component: '<Input placeholder="[PLACE_HOLDER]" style={{ width: "100%" }}  /> '
  },
  {
    id: "SELECT",
    value: "Select",
    extra: [{ key: "loadUrl", element: <Input></Input> }],
    component:
      '<Select style={{ width: "100%" }} placeholder="[PLACE_HOLDER]"  ' +
      "  > \r\n" +
      "                                          <Option key={null} value={null}>Select</Option>\r\n" +
      "   {Boolean(this.state['[INPUT_VALUE]SelectItems']) && this.state['[INPUT_VALUE]SelectItems'].map(i => (" +
      "   <Option key={i.key} value={i.key}>{i.value}</Option>" +
      "      ))}" +
      " </Select>"
  },

  {
    //TODO
    id: "SELECT_ADDABLE",
    value: "Select Addable",
    component:
      "<Select\r\n" +
      "                                                style={{ width: '100%' }}\r\n" +
      '                                                placeholder="[PLACE_HOLDER]"\r\n' +
      "\r\n" +
      "\r\n" +
      "                                                dropdownRender={menu => (\r\n" +
      "                                                    <div>\r\n" +
      "                                                        {menu}\r\n" +
      "                                                        <Divider style={{ margin: '4px 0' }} />\r\n" +
      "                                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>\r\n" +
      "                                                            <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {\r\n" +
      "                                                                this.setState({\r\n" +
      "                                                                    codeName: event.target.value,\r\n" +
      "                                                                });\r\n" +
      "                                                            }} />\r\n" +
      "                                                            <a\r\n" +
      "                                                                style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}\r\n" +
      "                                                                onClick={()=>this.addCode('[INPUT_VALUE]')}\r\n" +
      "                                                            >\r\n" +
      "                                                                <PlusOutlined /> Add item\r\n" +
      "                                                               </a>\r\n" +
      "                                                        </div>\r\n" +
      "                                                    </div>\r\n" +
      "                                                )}\r\n" +
      "                                            >\r\n" +
      "                                          <Option key={null} value={null}>Select</Option>\r\n" +
      "   {Boolean(this.state['[INPUT_VALUE]SelectItems']) && this.state['[INPUT_VALUE]SelectItems'].map(i => (" +
      "   <Option key={i.id} value={i.id}>{i.name}</Option>" +
      "      ))}" +
      "                                            </Select>"
  },
  {
    id: "NUMBER",
    value: "Number",
    component: '<InputNumber  placeholder="[PLACE_HOLDER]"\r\n min={0} />'
  },
  {
    id: "DATEPICKER",
    value: "Date Picker",
    component: ' <DatePicker  format="DD-MM-YYYY" />'
  },
  {
    id: "DATEPICKER_WITH_TIME",
    value: "Date Picker With Time",
    component: ' <DatePicker showTime format="DD-MM-YYYY HH:mm:ss" />'
  },
  {
    id: "TIMEPICKER",
    value: "Time Picker",
    component: " <TimePicker />"
  },
  {
    id: "YEARPICKER",
    value: "Year Picker",
    component: ' <DatePicker   picker="year" />'
  },
  {
    id: "MONTHPICKER",
    value: "Month Picker",
    component: ' <DatePicker    picker="month" />'
  },
  {
    id: "RANGEPICKER",
    value: "Range Picker",
    component:
      " <RangePicker    \r\n" +
      "      ranges={{\r\n" +
      "        Today: [moment(), moment()],\r\n" +
      "        'This Month': [moment().startOf('month'), moment().endOf('month')],\r\n" +
      "      }}\r\n />"
  },
  {
    id: "RANGEPICKER_WITH_TIME",
    value: "Range Picker With Time",
    component:
      "<RangePicker  \r\n" +
      "      ranges={{\r\n" +
      "        Today: [moment(), moment()],\r\n" +
      "        'This Month': [moment().startOf('month'), moment().endOf('month')],\r\n" +
      "      }}\r\n" +
      " showTime \r\n" +
      '  format="YYYY/MM/DD HH:mm:ss" \r\n />'
  },
  {
    id: "CHECKBOX",
    value: "Checkbox",
    component: '  <Checkbox > <FormattedMessage id="[LABEL_LANGUAGE_KEY]" defaultMessage="[PLACE_HOLDER]" />  </Checkbox>'
  },

  {
    id: "RADIO",
    value: "Radio",
    component:
      "<Radio.Group  >\r\n" +
      "                                                    <Radio value={1}>Option 1</Radio>\r\n" +
      "                                                    <Radio value={2}>Option 2</Radio>\r\n" +
      "                                                </Radio.Group>"
  },

  {
    id: "CASCADER",
    value: "Cascader",
    component:
      "   <Cascader   \r\n" +
      '                                                    style={{ width: "100%" }}\r\n' +
      "                                                    options={this.state.nodes}\r\n" +
      "                                                />"
  },

  {
    id: "FILEUPLOAD",
    value: "File Upload",
    component:
      "<Upload {...props}  \r\n" +
      "                                                    showUploadList={true}\r\n" +
      "                                                    fileList={this.state.fileList}\r\n" +
      "                                                    onChange={({ fileList }) => {\r\n" +
      "                                                        if (fileList.length == 1) {\r\n" +
      "                                                            if (Boolean(fileList[0].response)) {\r\n" +
      "                                                                fileList[0].url = fileList[0].response.url;\r\n" +
      '                                                                this.showSuccess("file uploaded successfully");\r\n' +
      "                                                                // fileName: info.file.name, \r\n" +
      "                                                            }\r\n" +
      "                                                            this.setState({\r\n" +
      "                                                                fileList: fileList,\r\n" +
      "                                                            });\r\n" +
      "                                                        }\r\n" +
      "                                                    }}\r\n" +
      "                                                    beforeUpload={(fileList) => {\r\n" +
      "                                                        if (this.state.fileList.length > 0) {\r\n" +
      '                                                            this.showError("You have been already choose a file! For change firstly remove the uploaded file.");\r\n' +
      "                                                            return false;\r\n" +
      "                                                        }\r\n" +
      "                                                        return true;\r\n" +
      "                                                    }}\r\n" +
      "                                                    >\r\n" +
      '                                                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>\r\n' +
      "                                                        <UploadOutlined /> Upload \r\n" +
      "                            </AntButton>\r\n" +
      "                                                </Upload>"
  }
];

export class FrontendGenerator extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      hideInputs: true,
      hideSave: false,
      hideUpdate: true,

      statusList: [],
      status: "",
      abbreviation: "",
      statusId: 0,

      dynamicMenuIds: null,
      dynamicMenuId: null,
      dynamicInputList: [],
      dynamicMenuCascader: [],
      dynamicFormList: [],
      entity: "",
      entitySelectItems: [],
      entityAttributeList: [],
      bindField: "",
      inputTypeSelectItems: [],
      folderSelectItems: [],

      dynamicAttributes: [],
      authorizations: [],
      Inputs: [],
      isAdmin: false,
      howToUseSetting: false,
      whatIsSetting: false,
      folder: "nill",

      formId: 0
    };

    // this.actionTemplate = this.actionTemplate.bind(this);
    // this.showSuccess = this.showSuccess.bind(this);
    // this.showError = this.showError.bind(this);
    // this.showWarning = this.showWarning.bind(this);
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    await this.getDynamicMenu();

    const responseFolder = await handleRequest("GET", "/ApplicationSettings/getDomainFolders");

    console.log(responseFolder);
    if (Boolean(responseFolder.data) && responseFolder.data.length > 0) {
      let data = responseFolder.data;

      this.setState({
        folderSelectItems: data
      });
    }

    const response = await handleRequest("GET", "/ApplicationSettings/getEntitiesFromLocal");

    console.log(response);
    if (Boolean(response.data) && response.data.length > 0) {
      const data = response.data;
      console.log(data);
      this.setState({
        entitySelectItems: data
      });
    }

    var responseinputTypeId = await handleRequest("GET", "/api/pageInput/inputTypeIdSelectItems");
    if (responseinputTypeId.type === "ERROR") {
      this.showError("An error has occured!");
    } else {
      this.setState({
        inputTypeSelectItems: Boolean(responseinputTypeId.data) ? responseinputTypeId.data : []
      });
    }
  };

  onChangeFolder = async value => {
    let folder = value;

    if (folder === "nill") {
      let response = await handleRequest("GET", "/ApplicationSettings/getEntitiesFromLocal");

      console.log(response);
      if (Boolean(response.data) && response.data.length > 0) {
        let data = response.data;
        console.log(data);
        this.setState({
          entitySelectItems: data
        });
      }
    } else {
      let response = await handleRequest("GET", "/ApplicationSettings/getEntitiesFromFolder/" + folder);

      console.log(response);
      if (Boolean(response.data) && response.data.length > 0) {
        let data = response.data;
        console.log(data);
        this.setState({
          entitySelectItems: data
        });
      }
    }

    this.setState({
      folder: folder
    });
  };

  getEntityFields = async () => {
    let { folder } = this.state;
    let url = "/api/dynamicform/byentity/" + this.state.entity;
    if (Boolean(folder) && folder !== "nill") {
      url = "/api/dynamicform/byentityandfolder/" + this.state.entity + "/" + folder;
    }
    const response = await handleRequest("GET", url);

    if (Boolean(response.data) && response.data.length > 0) {
      const data = response.data;
      console.log(data);
      data.forEach(element => {
        if (!Boolean(element.inputType)) {
          element.inputType = "TEXT";
          element.inputComponentCode = '<Input placeholder="[PLACE_HOLDER]" style={{ width: "100%" }}  /> ';
        }
      });

      this.setState({
        dynamicFormList: data
      });
    }
  };

  // getDynamicMenu = async () => {
  //     const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
  //     if (responseTree.data.length !== 0) {
  //         // generateList(responseTree.data.treeTable);
  //         console.log(responseTree.data.cascader);
  //         this.setState({
  //             dynamicMenuCascader: responseTree.data.cascader
  //         });
  //     }
  // }

  getDynamicMenu = async () => {
    const responseTree = await handleRequest("GET", "/api/menus/tree");
    if (responseTree.data.length !== 0) {
      //generateList(responseTree.data.treeTable);
      this.setState({
        //   dynamicMenuTreeTable: responseTree.data.treeTable,
        dynamicMenuCascader: responseTree.data.cascader
      });
    }
  };

  onDynamicMenuCascaderChange = async value => {
    if (Boolean(value) && value.length > 0) {
      this.setState({
        dynamicMenuId: value[value.length - 1]
      });

      // const response = await handleRequest("GET", "/DynamicInputs/getAllByMenuId/" + value[value.length - 1])
      // const response2 = await handleRequest("GET", "/DynamicForm/getForm/" + value[value.length - 1]);
      // var dynamicFormList = [];

      // if (Boolean(response2.data) && response2.data.length > 0) {
      //     dynamicFormList = response2.data;
      //     this.setState({
      //         entity : dynamicFormList[dynamicFormList.length-1].controller
      //     })
      // }

      // if (Boolean(response.data) && response.data.length > 0) {
      //     const list = response.data;
      //     console.log(list);
      //     console.log(dynamicFormList);
      //     const formList = [];
      //     list.forEach(element => {
      //         let index = -1;
      //         if (dynamicFormList.length > 0) {
      //             index = dynamicFormList.findIndex(p => p.dynamicInputsId === element.id);
      //         }
      //         if (index === -1) {
      //             let obj = { id: 0, dynamicMenuId: value[value.length - 1], active: true, dynamicInputsId: element.id, inputKey: element.inputKey, inputValue: element.inputValue, inputTypeId: null, inputComponentCode: '', row1: formList.length, rowOffsetLabel: true, rowOffset: 0, col1: 1, colSpan: 8, showLabel: true, dynamicInputs: element, bindField: '' }
      //             formList.push(obj);
      //         }
      //         else {
      //             let c = inputTypeSelectItems.find(p=>p.id===dynamicFormList[index].inputTypeId);
      //             if(Boolean(c)){
      //                 dynamicFormList[index].inputComponentCode=c.component;
      //             }
      //             formList.push(dynamicFormList[index]);}

      //     });
      //     console.log(formList);
      //     this.setState({
      //         dynamicFormList: formList
      //     })
      // }
    }

    this.setState({
      dynamicMenuIds: value
    });
  };

  save = async () => {
    const list = this.state.dynamicFormList;
    const dynamicMenuIds = this.state.dynamicMenuIds;
    const menuId = Boolean(dynamicMenuIds) && dynamicMenuIds.length > 0 ? dynamicMenuIds[dynamicMenuIds.length - 1] : null;
    if (Boolean(this.state.entity) && this.state.entity.length > 0) {
      list.forEach(element => {
        element.entity = this.state.entity;
        element.menuId = menuId;
      });
    }
    let response = await handleRequest("POST", "/api/dynamicform/saveAll", list);

    if (response.type === "ERROR") {
      this.showError();
    } else {
      this.showSuccess();
      this.getEntityFields();
    }
  };

  createForm = async () => {
    const entity = this.state.entity;
    if (Boolean(entity)) {
      await handleRequest("GET", "/api/dynamicform/createFormFile/" + entity);

      this.showSuccess();
    } else this.showError();
  };

  edit = row => {
    this.formRef.current.setFieldsValue({
      status: row.status,
      abbreviation: row.abbreviation
    });

    this.setState({
      statusId: row.id,
      status: row.status,
      abbreviation: row.abbreviation,
      hideSave: true,
      hideUpdate: false,
      hideInputs: false
    });
  };

  delete = async () => {
    const obj = {
      id: this.state.statusId
    };

    await handleRequest("POST", "/DynamicForm/delete", obj);

    this.restartTable();
    this.showSuccess();
    this.showOrHideDeleteModal();
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      statusId: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  };

  showSuccess(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  }

  showError(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  }

  showWarning(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  }

  actionTemplate(rowData, column) {
    return (
      <React.Fragment>
        <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  }

  restartTable = async () => {};

  setStandardizations = async () => {
    const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "DynamicForm" });
    if (responseDP.data.length > 0) {
      this.setState({
        dynamicAttributes: responseDP.data
      });
    } else {
      this.setState({
        dynamicAttributes: []
      });
    }

    const obj = {
      pageName: "DynamicForm"
    };

    const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
    if (responseInputs.data.length !== 0) {
      this.setState({
        Inputs: responseInputs.data
      });
    } else {
      this.setState({
        Inputs: []
      });
    }
  };

  createNew = () => {
    this.setState({
      hideInputs: !this.state.hideInputs,
      status: "",
      abbreviation: "",
      statusId: 0,
      modalDeleteShow: false,
      hideSave: false,
      hideUpdate: true
    });
  };

  stop = () => {
    this.setState({
      status: "",
      abbreviation: "",
      statusId: 0,
      modalDeleteShow: false,
      hideInputs: true,
      hideSave: false,
      hideUpdate: true
    });
  };

  resetInputs = () => {
    this.formRef.current.setFieldsValue({
      status: "",
      abbreviation: ""
    });

    this.setState({
      status: "",
      abbreviation: "",
      statusId: 0,
      modalDeleteShow: false,
      hideSave: false,
      hideUpdate: true
    });
  };

  cancel = () => {
    this.resetInputs();
  };

  searchTree = (element, matchingTitle) => {
    if (element.data.page == matchingTitle) {
      return element;
    } else if (element.children != null) {
      var i;
      var result = null;
      for (i = 0; result == null && i < element.children.length; i++) {
        result = this.searchTree(element.children[i], matchingTitle);
      }
      return result;
    }
    return null;
  };

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <div>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen status will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </ReactButton>
            <ReactButton variant="danger" onClick={this.delete}>
              Delete
            </ReactButton>
          </Modal.Footer>
        </Modal>

        <div>
          <Row>
            <Col sm="1"></Col>
            <Col sm="10"></Col>
            <Col sm="1" style={{ textAlign: "right" }}></Col>
          </Row>
        </div>

        <div style={{ marginBottom: "2rem" }}>
          <FormAnt initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <RowAnt>
              <ColAnt span={2}>Folder</ColAnt>
              <ColAnt span={4}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder=""
                  style={{ width: "100%" }}
                  value={this.state.folder}
                  onChange={this.onChangeFolder}
                >
                  <Option key={"nill"} value={"nill"}>
                    Select
                  </Option>
                  {this.state.folderSelectItems.map(i => (
                    <Option key={i} value={i}>
                      {i}
                    </Option>
                  ))}
                </Select>
              </ColAnt>
            </RowAnt>
            <RowAnt>
              <ColAnt span={2}>Controller :</ColAnt>
              <ColAnt span={4}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder=""
                  style={{ width: "100%" }}
                  value={this.state.entity}
                  onChange={value => {
                    this.setState(
                      {
                        entity: value
                      },
                      this.getEntityFields
                    );
                  }}
                >
                  <Option key={0} value={0}>
                    Select
                  </Option>
                  {this.state.entitySelectItems.map(i => (
                    <Option key={i} value={i}>
                      {i}
                    </Option>
                  ))}
                </Select>
              </ColAnt>
            </RowAnt>
            <RowAnt>
              <ColAnt span={2}>Menu :</ColAnt>
              <ColAnt span={4}>
                <Cascader
                  style={{ width: "100%", marginBottom: "16px" }}
                  value={this.state.dynamicMenuIds}
                  options={this.state.dynamicMenuCascader}
                  onChange={this.onDynamicMenuCascaderChange}
                />
              </ColAnt>
            </RowAnt>

            <RowAnt gutter={[16, 16]}>
              <ColAnt span={1}>Show In Form</ColAnt>

              <ColAnt span={2}>Row</ColAnt>

              <ColAnt span={3}>Value(Attribute)</ColAnt>

              <ColAnt span={3}>Label</ColAnt>

              <ColAnt span={1}>Is Required ?</ColAnt>

              <ColAnt span={3}>Input Type</ColAnt>

              <ColAnt span={3}>Whatis Description</ColAnt>
            </RowAnt>

            {this.state.dynamicFormList
              .filter(p => p.active === true)
              .map((item, i) => (
                <div style={{ backgroundColor: "white", marginTop: "1px" }}>
                  <RowAnt gutter={[16, 16]}>
                    <ColAnt span={1}>
                      <Checkbox
                        onChange={e => {
                          let list = this.state.dynamicFormList;
                          let obj = list[i];
                          obj.showOnForm = e.target.checked;
                          this.setState({ dynamicFormList: list });
                        }}
                        checked={item.showOnForm}
                      >
                        {" "}
                      </Checkbox>
                    </ColAnt>

                    <ColAnt span={2}>
                      <InputNumber
                        placeholder="Row"
                        style={{ width: "100%" }}
                        id="todo"
                        min={0}
                        max={this.state.dynamicFormList.length}
                        value={item.rowIndex}
                        onChange={value => {
                          let list = this.state.dynamicFormList;
                          let obj = list[i];
                          obj.rowIndex = value;
                          this.setState({ dynamicFormList: list });
                        }}
                      />
                    </ColAnt>

                    <ColAnt span={3}>{item.inputValue}</ColAnt>
                    <ColAnt span={3}>
                      <Input
                        value={item.label}
                        onChange={e => {
                          let list = this.state.dynamicFormList;
                          let obj = list[i];
                          obj.label = e.target.value;
                          this.setState({ dynamicFormList: list });
                        }}
                      />
                    </ColAnt>
                    <ColAnt span={1}>
                      <Checkbox
                        onChange={e => {
                          let list = this.state.dynamicFormList;
                          let obj = list[i];
                          obj.required = e.target.checked;
                          this.setState({ dynamicFormList: list });
                        }}
                        checked={item.required}
                      >
                        {" "}
                      </Checkbox>
                    </ColAnt>
                    <ColAnt span={3}>
                      <Select
                        placeholder=""
                        style={{ width: "100%" }}
                        value={item.inputType}
                        onChange={value => {
                          let list = this.state.dynamicFormList;
                          let inputType = inputTypeSelectItems.find(p => p.id === value);
                          let obj = list[i];
                          obj.inputType = value;
                          obj.inputComponentCode = inputType.component;
                          this.setState({ dynamicFormList: list });
                        }}
                      >
                        {this.state.inputTypeSelectItems.map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                      </Select>
                    </ColAnt>

                    <ColAnt span={6}>
                      <Input
                        value={item.whatIsDescription}
                        onChange={e => {
                          let list = this.state.dynamicFormList;
                          let obj = list[i];
                          obj.whatIsDescription = e.target.value;
                          this.setState({ dynamicFormList: list });
                        }}
                      />
                    </ColAnt>
                  </RowAnt>
                </div>
              ))}

            <div hidden={this.state.hideSave}>
              {
                <RowAnt gutter={8}>
                  <ColAnt span={8}>
                    {
                      <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        Save All
                      </ReactButton>
                    }
                  </ColAnt>

                  <ColAnt span={8}>
                    {Boolean(this.state.dynamicFormList) && this.state.dynamicFormList.length > 0 && Boolean(this.state.dynamicFormList[0].id) && (
                      <ReactButton
                        id="OrganizationalLevelSaveButton"
                        type="button"
                        onClick={this.createForm}
                        style={{ width: "100%" }}
                        variant="success"
                      >
                        {" "}
                        Create Form
                      </ReactButton>
                    )}
                  </ColAnt>
                </RowAnt>
              }
            </div>

            <div hidden={this.state.hideUpdate}>
              {
                <Row>
                  <Col sm="3"></Col>
                  <Col sm="2">
                    <ReactButton id="OrganizationalLevelCancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </ReactButton>
                  </Col>
                  <Col sm="3">
                    <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </ReactButton>
                  </Col>
                  <Col sm="3"></Col>
                </Row>
              }
            </div>
            <Modal
              show={this.state.modalMessage}
              onHide={this.showOrHideModalMessage}
              backdrop="static"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <Row>
                    <Col sm="12">
                      {this.state.messageTip == "Success" && <i className="fas fa-check fa-2x" style={{ color: "#00ff2a" }} />}

                      {this.state.messageTip == "Warning" && <i className="fas fa-exclamation-circle fa-2x" style={{ color: "#faca2a" }} />}

                      {this.state.messageTip == "Error" && <i className="fas fa-times fa-2x" style={{ color: "#ff0400" }} />}
                    </Col>
                  </Row>
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Row>
                  <Col sm="12">{this.state.message}</Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}>
                  <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                </ReactButton>
              </Modal.Footer>
            </Modal>
          </FormAnt>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FrontendGenerator);
