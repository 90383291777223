import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import {Button as ReactButton, Button, Form as FormBoot, Modal} from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import {
    Form,
    Table,
    Col,
    Row,
    Button as AntButton,
    DatePicker,
    Select,
    Input,
    Divider,
    Space,
    Checkbox,
    InputNumber
} from "antd";
import { handleRequest } from "../../ApiConnector";
import { ClinicSub } from "./ClinicSub";
import CreateIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import {ExcelReader} from "../excel/ExcelReader";
import moment from "moment";

const {Option} = Select;

var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0');
var yyyy = today.getFullYear();

today = dd + '/' + mm + '/' + yyyy;

var _followUpRows = [
    {
        followUpIndex: 1,
        date: today
    }
];

export class Clinic extends Component {
    constructor() {
        super();
        this.state = {
            controller: "clinic",
            modalDeleteShow: false,
            modalFollowOp: false,
            exportList: [],
            exportClick: false,
            hideUpdate: true,
            hideSave: false,
            hideInputs: true,
            lastUpdateDate: [],

            id: null,
            tableList: [],
            searchCriteriaModels: [],

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
                total: 0
            },
            loading: false,
            sortField: "",
            sortOrder: "",

            excelData: [],
            period: "",
            periodUnit: "",
            periodic: false,
            codeName:"",
            codeType:"",
            initialChecksItemCount: 0,
            initialCheckRows: [],
            initialCheckses: [],
            initialChecks: [],
            initialChecksCodeId: null,
            followUpsId: null,
            followUpRows: [{
                followUpIndex: 1,
                date: today
            }],
            employeeId: null,
            complainOfEmployeeId: null,
            diagnosisId: null,
            treatmentsId: null,
            medicinesId: null,
            transferToMedicalCenterId: null,
            employeesSelectItems: [],
            employeesIDSelectItems: [],
            clinicComplainOfEmployeeSelectItems: [],
            clinicInitialChecksSelectItems: [],
            clinicDiagnosisSelectItems: [],
            clinicTreatmentsSelectItems: [],
            clinicMedicinesSelectItems: [],
            clinicTransferToMedicalCenterSelectItems: [],
        };
    }

    formRef = React.createRef();

    componentDidMount = async () => {

        const { pagination } = this.state;
        this.restartTable({ pagination });


        this.setState({
            clinicDate: today,
            followUpRows: _followUpRows
        });

        this.fillComboboxes();
    };

    fillComboboxes = async () => {

        this.fillEmployees();
        this.fillEmployeeIDs();
        this.fillComplainOfEmployee();
        this.fillInitialChecks();
        this.fillDiagnosis();
        this.fillTreatments();
        this.fillMedicines();
        this.fillTransferToMedicalCenter();
    };

    fillEmployees = async () => {
        const response = await handleRequest("GET", "/api/"+this.state.controller+"/getEmployess");
        if (response.type === "ERROR") {
            error(response);
        } else {
            this.setState({
                employeesSelectItems: Boolean(response.data)
                    ? response.data
                    : [],
            });
        }
    };

    fillEmployeeIDs = async () => {
        const response = await handleRequest("GET", "/api/"+this.state.controller+"/getEmployeId");
        if (Boolean(response.data)) {
            console.log("fillEmployeeIDs: " + JSON.stringify(response.data));
            this.setState({
                employeesIDSelectItems: response.data
            });
        }
    };

    fillComplainOfEmployee = async () => {
        const response = await handleRequest("GET", "/api/codes/type/ClinicComplainOfEmployee");
        if (response.type === "ERROR") {
            error(response);
        } else {
            this.setState({
                clinicComplainOfEmployeeSelectItems: Boolean(response.data)
                    ? response.data
                    : [],
            });
        }
    };

    fillInitialChecks = async () => {
        const response = await handleRequest("GET", "/api/codes/type/ClinicInitialChecks");
        if (response.type === "ERROR") {
            error(response);
        } else {
            this.setState({
                clinicInitialChecksSelectItems: Boolean(response.data)
                    ? response.data
                    : [],
            });
        }
    };

    fillDiagnosis = async () => {
        const response = await handleRequest("GET", "/api/codes/type/ClinicDiagnosis");
        if (response.type === "ERROR") {
            error(response);
        } else {
            this.setState({
                clinicDiagnosisSelectItems: Boolean(response.data)
                    ? response.data
                    : [],
            });
        }
    };

    fillTreatments = async () => {
        const response = await handleRequest("GET", "/api/codes/type/ClinicTreatments");
        if (response.type === "ERROR") {
            error(response);
        } else {
            this.setState({
                clinicTreatmentsSelectItems: Boolean(response.data)
                    ? response.data
                    : [],
            });
        }
    };

    fillMedicines = async () => {
        const response = await handleRequest("GET", "/api/codes/type/ClinicMedicines");
        if (response.type === "ERROR") {
            error(response);
        } else {
            this.setState({
                clinicMedicinesSelectItems: Boolean(response.data)
                    ? response.data
                    : [],
            });
        }
    };

    fillTransferToMedicalCenter = async () => {
        const response = await handleRequest("GET", "/api/codes/type/ClinicTransferToMedicalCenter");
        if (response.type === "ERROR") {
            error(response);
        } else {
            this.setState({
                clinicTransferToMedicalCenterSelectItems: Boolean(response.data)
                    ? response.data
                    : [],
            });
        }
    };

    cancel = () => {
        this.setState({
            hideUpdate: true,
            hideSave: false,
            hideInputs: true
        });
        this.resetInputs();
    };

    resetInputs = () => {
        this.setState({
            id: null,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
                total: this.state.totalRecords
            },
            loading: false,
            sortField: "",
            sortOrder: "",
            initialChecksCodeId: null,
            followUpsId: null,
            employeeId: null,
            complainOfEmployeeId: null,
            diagnosisId: null,
            treatmentsId: null,
            medicinesId: null,
            transferToMedicalCenterId: null,
        });
    };

    edit = row => {
        document.getElementById("kt_scrolltop").click();
        this.formRef.current.setFieldsValue({
            ...row,
        });
        this.setState({
            hideUpdate: false,
            hideSave: true,
            hideInputs: false,
            id: row.id
        });
    };

    deleteModal = row => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    };

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };

    delete = async () => {
        const deletedItem = {
            id: this.state.id
        };
        var response = await handleRequest("DELETE", "/api/" + this.state.controller + "/" + deletedItem.id);

        if (response.type === "ERROR") {
            error(response);
        } else {
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.setState({
                id: null,
                modalDeleteShow: false
            });
        }
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.restartTable({
            sortField: sorter.columnKey,
            sortOrder: sorter.order,
            pagination,
            filters
        });
    };

    restartTable = async (params = {}) => {
        this.setState({ loading: true });
        const newObj = this.createWhere(params);
        let url = "/api/" + this.state.controller + "/pagingPersonnel";
        const response = await handleRequest("POST", url, newObj);
        if (response.type === "ERROR") {
            error(response);
            this.setState({
                loading: false,
                tableList: [],
                pagination: {
                    ...params.pagination,
                    total: 0
                },
                totalRecords: 0,
                sortField: "",
                sortOrder: ""
            });
        } else {
            if (response.data.length !== 0) {
                let list = response.data.data;
                list.forEach((element, index) => {
                    element.index = index + 1;
                    element.key = index + 1;
                });
                this.setState({
                    loading: false,
                    tableList: list,
                    pagination: {
                        ...params.pagination,
                        total: response.data.totalRecords
                    },
                    totalRecords: response.data.totalRecords,
                    sortField: params.sortField,
                    sortOrder: params.sortOrder
                });
            } else {
                this.setState({
                    loading: false,
                    tableList: [],
                    pagination: {
                        ...params.pagination,
                        total: 0
                    },
                    totalRecords: 0,
                    sortField: "",
                    sortOrder: ""
                });
            }
        }
    };

    onClickCreateNew = () => {
        this.resetInputs();

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        _followUpRows = [
            {
                followUpIndex: 1,
                date: today,
                periodic: false,
                period: "",
                periodUnit: ""
            }
        ];

        if (this.state.hideInputs) {
            this.setState({
                hideInputs: false,
                hideSave: false,
                hideUpdate: true
            });
        } else {
            this.setState({
                hideInputs: true,
                hideSave: true,
                hideUpdate: false
            });
        }
    };

    save = async values => {
        const newItem = {
            ...values,
            id: this.state.id,
            parentId: Boolean(values.parentId) ? values.parentId[values.parentId.length - 1] : null
        };

        if (newItem.id === null) {
            var response = await handleRequest("POST", "/api/" + this.state.controller + "", newItem);
        } else {
            response = await handleRequest("PUT", "/api/" + this.state.controller + "/" + newItem.id, newItem);
        }

        if (response.type === "ERROR") {
            error(response);
        } else {
            this.cancel();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
        }
    };

    createWhere(params) {
        if (Boolean(params._pagination)) params.pagination = params._pagination;

        var lastUpdateDateStart = null,
            lastUpdateDateEnd = null,
            searchCriteriaModels = [],
            sortField = "",
            sortOrder = "";
        if (Boolean(params) && Boolean(params.pagination)) {
            if (Boolean(params.pagination.lastUpdateDate)) {
                lastUpdateDateStart = params.pagination.lastUpdateDate[0];
                lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
            }
            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }
        }
        const newObj = {
            ...params.pagination,
            id: null,
            lastUpdateDateStart: lastUpdateDateStart,
            lastUpdateDateEnd: lastUpdateDateEnd,
            firstResult: params.pagination.current - 1,
            maxResults: params.pagination.pageSize,
            sortField: sortField,
            sortOrder: sortOrder,
            searchCriteriaModels: searchCriteriaModels,
        };
        return newObj;
    }

    handleExportExcel = async () => {
        this.setState({
            exportClick: true
        });
    };

    handleUploadExcel = async excelJson => {
        try {
            var list = [];
            if (excelJson === null) showError("Select an excel file");
            else {
                excelJson.forEach(element => {
                    const obj = {};
                    list.push(obj);
                });
                if (Boolean(list) && list.length > 0) {
                    var response = await handleRequest("POST", "/api/" + this.state.controller + "/import", list);
                    if (response.type === "ERROR") {
                        showError("No records to be added");
                    } else {
                        this.resetInputs();
                        const { pagination } = this.state;
                        this.restartTable({ pagination });
                        showSuccess();
                    }
                } else {
                    showError("No records to be added");
                }
            }
        } catch (error) {
            showError("");
        }
    };

    addItem = async () => {
        const { codeName, codeType } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: codeType
            };

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: ""
                });
                if(codeType === "ClinicComplainOfEmployee") {
                    this.fillComplainOfEmployee();
                }
                if(codeType === "ClinicInitialChecks") {
                    this.fillInitialChecks();
                }
                if(codeType === "ClinicDiagnosis") {
                    this.fillDiagnosis();
                }
                if(codeType === "ClinicTreatments") {
                    this.fillTreatments();
                }
                if(codeType === "ClinicMedicines") {
                    this.fillMedicines();
                }
                if(codeType === "ClinicTransferToMedicalCenter") {
                    this.fillTransferToMedicalCenter();
                }
            }
        } else {
            showError("Please enter name");
        }
    };

    handleChangeInitialChecks = (value) => {
        this.setState({
            initialChecks: value
        });

        let _initialCheckRows = [];

        for (let index = 0; index < value.length; index++) {
            let flagName = "";
            for (var z = 0; z < this.state.clinicInitialChecksSelectItems.length; z++) {
                if (this.state.clinicInitialChecksSelectItems[z].id == value[index]) {
                    flagName = this.state.clinicInitialChecksSelectItems[z].name;
                }
            }

            _initialCheckRows.push(
                {
                    initialCheckIndex: _initialCheckRows.length + 1,
                    name: flagName,
                    value: ""
                }
            );
        }

        this.setState({
            initialCheckRows: _initialCheckRows
        });
    }

    handleChangeInitialCheckValue = (index, e) => {
        let list = this.state.initialCheckRows;
        list[index].value = e.target.value;

        this.setState({
            initialCheckRows: list.slice()
        });
    }

    addNewFollowUpLine = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        _followUpRows.push(
            {
                key: _followUpRows.length + 1,
                followUpIndex: _followUpRows.length + 1,
                date: today
            }
        );
console.log(_followUpRows);
        this.setState({
            followUpRows: _followUpRows,
            periodic: false
        });
    }

    removeFollowUpLine = (index, e) => {
        _followUpRows.splice(index, 1);

        this.setState({
            followUpRows: _followUpRows
        });
    }

    disabledDate = (current) => {
        return current < moment().startOf('day');
    }

    onChangeFollowUpDate = async (index, date, dateString) => {
        let list = this.state.followUpRows;

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            list[index].date = dateString;
        } else {
            list[index].date = today;
        }

        this.setState({
            followUpRows: list.slice()
        });
    }

    onChangeFollowUpPeriodicCheck = (e) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (e.target.checked) {
            _followUpRows = [
                {
                    followUpIndex: 1,
                    date: today
                }
            ];

            this.setState({
                followUpRows: _followUpRows,
                modalFollowUp: true
            });
        }

        this.setState({
            periodic: e.target.checked
        });
    }

    showOrHideModalFollowUp = () => {
        this.setState({
            modalFollowUp: !this.state.modalFollowUp
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    periodOK = () => {
        this.setState({
            modalFollowUp: false
        });
    }

    render() {


        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };

        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 8},
            style: {
                marginBottom: 0
            }
        };

        const excelExport = (
            <>
                {/* {this.state.exportClick
                    ? this.setState({
                        exportClick: false
                    })
                    : ""}
                {this.state.exportClick ? (
                    <ExcelFile filename={this.state.formName} hideElement={true}>
                        <ExcelSheet name="Products" data={this.state.tableList}>
                            <ExcelColumn label="S/N" value="index"/>
                            {Boolean(this.state.pageFormData) &&
                                this.state.pageFormData.pageFormInputCompanyList.map(item => {
                                    return (
                                        <ExcelColumn
                                            key={item.inputName}
                                            label={item.label}
                                            value={Boolean(item.tableColumnValue) ? item.tableColumnValue : item.inputName}
                                        />
                                    );
                                })}
                        </ExcelSheet>
                    </ExcelFile>
                ) : (
                    <AntButton style={{border: "0px"}} onClick={this.handleExportExcel}
                               icon={<ExportIcon color="action" fontSize="large"/>}></AntButton>
                )} */}
            </>
        );


        const columns = [
            {
                title: "S/N",
                key: "id",
                render: (value, row, index) => {
                    return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
                }
            },
            {
                title: <FormattedMessage id="Clinic.identificationNo" defaultMessage="ID" />,
                key: "identificationNo",
                render: record => {
                    return record.identificationNo;
                },
                sorter: false
            },
            {
                title: <FormattedMessage id="Clinic.personnelName" defaultMessage="Name" />,
                key: "personnelName",
                render: record => {
                    var personnelName = record.name + " ";
                    personnelName += Boolean(record.midName) ? record.midName + " " : " ";
                    personnelName += record.lastName;
                    return personnelName;
                },
                sorter: false
            }
        ];

        return (
            <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
                <Row gutter={[16, 16]}>
                    <Col md={21}>
            <span className="h3">
                <FormattedMessage
                    id={this.state.controllerName + ".pageTitle"}
                    defaultMessage={"Clinic"}
                />
            </span>
                    </Col>
                    <Col md={1}>{excelExport}</Col>
                    <Col md={1}>
                        <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
                    </Col>
                    <Col md={1}>
                        <AntButton
                            style={{border: "0px"}}
                            hidden={!this.state.hideInputs}
                            onClick={this.onClickCreateNew}
                            icon={<CreateIcon color="secondary" fontSize="large"/>}
                        ></AntButton>
                        <AntButton
                            style={{border: "0px"}}
                            hidden={this.state.hideInputs}
                            onClick={this.onClickCreateNew}
                            icon={<RemoveCircle color="error" fontSize="large"/>}
                        ></AntButton>
                    </Col>
                </Row>
                <div hidden={this.state.hideInputs}>
                    <Form initialValues={{remember: false}} onFinish={this.save} onFinishFailed={onFinishFailed}
                          ref={this.formRef}>
                        {
                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id="clinic.clinicDate"
                                                         defaultMessage="Date"/>}
                                name="clinicDate"
                                rules={[
                                    {
                                        required: false,
                                        message: <FormattedMessage id="GENERAL.REQUIRED"
                                                                   defaultMessage="This field is required."/>
                                    }
                                ]}
                            >
                                {/*<DatePicker style={{ width: "100%" }} />*/}
                                <DatePicker style={{width: "100%"}} value={this.state.date}/>
                            </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id="clinic.employeeId"
                                                         defaultMessage="Name of Employee"/>}
                                name="employeeId"
                                rules={[
                                    {
                                        required: false,
                                        message: <FormattedMessage id="GENERAL.REQUIRED"
                                                                   defaultMessage="This field is required."/>
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={value => {
                                        this.setState({
                                            employeeId: value
                                        });
                                    }}
                                    style={{width: "100%"}}
                                    placeholder="Name of Employee"
                                >
                                    <Option key={null} value={null}>
                                        Select
                                    </Option>
                                    {Boolean(this.state["employeesSelectItems"]) &&
                                        this.state["employeesSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                                {i.value}
                                            </Option>
                                        ))}{" "}
                                </Select>
                            </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id="clinic.employeeIdNo"
                                                         defaultMessage="ID of Employee"/>}
                                name="employeeIdNo"
                                rules={[
                                    {
                                        required: false,
                                        message: <FormattedMessage id="GENERAL.REQUIRED"
                                                                   defaultMessage="This field is required."/>
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={value => {
                                        console.log(value)
                                    }}
                                    style={{width: "100%"}}
                                    placeholder="ID of Employee"
                                >
                                    <Option key={null} value={null}>
                                        Select
                                    </Option>
                                    {Boolean(this.state["employeesIDSelectItems"]) &&
                                        this.state["employeesIDSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                                {i.value}
                                            </Option>
                                        ))}{" "}
                                </Select>
                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id="clinic.complainOfEmployeeId" defaultMessage="Complain Of Employee" />}
                                name="complainOfEmployeeId"
                                rules={[
                                    {
                                        required: false,
                                        message: <FormattedMessage id="todo" defaultMessage="This field is required" />
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }}
                                    placeholder="Complain Of Employee"
                                    id="complainOfEmployeeId"
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: "4px 0" }} />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "nowrap",
                                                    padding: 8
                                                }}
                                            >
                                                <Input
                                                    style={{ flex: "auto" }}
                                                    value={this.state.codeName}
                                                    onChange={e => {
                                                        this.setState({
                                                            codeName: e.target.value,
                                                            codeType: "ClinicComplainOfEmployee"
                                                        });
                                                    }}
                                                />
                                                <a
                                                    style={{
                                                        flex: "none",
                                                        padding: "8px",
                                                        display: "block",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={this.addItem}
                                                >
                                                    <PlusOutlined /> Add Complain Of Employee
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    <Option key={null} value={null}>
                                        Select
                                    </Option>
                                    {this.state.clinicComplainOfEmployeeSelectItems.map(i => (
                                        <Option key={i.id} value={i.id}>
                                            {i.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id="clinic.initialChecksCodeId" defaultMessage="Initial Checks" />}
                                name="initialChecksCodeId"
                                rules={[
                                    {
                                        required: false,
                                        message: <FormattedMessage id="todo" defaultMessage="This field is required" />
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    mode="multiple"
                                    optionFilterProp="children"
                                    style={{ width: "100%" }}
                                    placeholder="Initial Checks"
                                    id="initialChecksCodeId"
                                    onChange={this.handleChangeInitialChecks}
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: "4px 0" }} />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "nowrap",
                                                    padding: 8
                                                }}
                                            >
                                                <Input
                                                    style={{ flex: "auto" }}
                                                    value={this.state.codeName}
                                                    onChange={e => {
                                                        this.setState({
                                                            codeName: e.target.value,
                                                            codeType: "ClinicInitialChecks",
                                                        });
                                                    }}
                                                />
                                                <a
                                                    style={{
                                                        flex: "none",
                                                        padding: "8px",
                                                        display: "block",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={this.addItem}
                                                >
                                                    <PlusOutlined /> Add Initial Checks
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    <Option key={null} value={null}>
                                        Select
                                    </Option>
                                    {this.state.clinicInitialChecksSelectItems.map(i => (
                                        <Option key={i.id} value={i.id}>
                                            {i.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        }
                        {Boolean(this.state.initialCheckRows) && this.state.initialCheckRows.map((i, index) => (
                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id={i.name}
                                                         defaultMessage={i.name}/>}
                                name={i.name}
                                rules={[{
                                    required: false,
                                    message: <FormattedMessage id={i.name}
                                                               defaultMessage="This field is required"/>
                                }]}>

                                <Input placeholder={i.name} onChange={(e) => this.handleChangeInitialCheckValue(i, e)}/>

                            </Form.Item>
                        ))

                        }

                        {
                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id="clinic.diagnosisId" defaultMessage="Diagnosis" />}
                                name="diagnosisId"
                                rules={[
                                    {
                                        required: false,
                                        message: <FormattedMessage id="todo" defaultMessage="This field is required" />
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }}
                                    placeholder="Diagnosis"
                                    id="diagnosisId"
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: "4px 0" }} />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "nowrap",
                                                    padding: 8
                                                }}
                                            >
                                                <Input
                                                    style={{ flex: "auto" }}
                                                    value={this.state.codeName}
                                                    onChange={e => {
                                                        this.setState({
                                                            codeName: e.target.value,
                                                            codeType: "ClinicDiagnosis"
                                                        });
                                                    }}
                                                />
                                                <a
                                                    style={{
                                                        flex: "none",
                                                        padding: "8px",
                                                        display: "block",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={this.addItem}
                                                >
                                                    <PlusOutlined /> Add Diagnosis
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    <Option key={null} value={null}>
                                        Select
                                    </Option>
                                    {this.state.clinicDiagnosisSelectItems.map(i => (
                                        <Option key={i.id} value={i.id}>
                                            {i.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id="clinic.treatmentsId" defaultMessage="Treatments" />}
                                name="treatmentsId"
                                rules={[
                                    {
                                        required: false,
                                        message: <FormattedMessage id="todo" defaultMessage="This field is required" />
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }}
                                    placeholder="Treatments"
                                    id="treatmentsId"
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: "4px 0" }} />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "nowrap",
                                                    padding: 8
                                                }}
                                            >
                                                <Input
                                                    style={{ flex: "auto" }}
                                                    value={this.state.codeName}
                                                    onChange={e => {
                                                        this.setState({
                                                            codeName: e.target.value,
                                                            codeType: "ClinicTreatments"
                                                        });
                                                    }}
                                                />
                                                <a
                                                    style={{
                                                        flex: "none",
                                                        padding: "8px",
                                                        display: "block",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={this.addItem}
                                                >
                                                    <PlusOutlined /> Add Treatments
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    <Option key={null} value={null}>
                                        Select
                                    </Option>
                                    {this.state.clinicTreatmentsSelectItems.map(i => (
                                        <Option key={i.id} value={i.id}>
                                            {i.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id="clinic.recommendations"
                                                         defaultMessage="Recommendations"/>}
                                name="recommendations"
                                rules={[{
                                    required: false,
                                    message: <FormattedMessage id="recommendations"
                                                               defaultMessage="This field is required"/>
                                }]}>

                                <Input placeholder="recommendations"/>

                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id="clinic.medicinesId" defaultMessage="Medicines" />}
                                name="medicinesId"
                                rules={[
                                    {
                                        required: false,
                                        message: <FormattedMessage id="todo" defaultMessage="This field is required" />
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }}
                                    placeholder="Medicines"
                                    id="medicinesId"
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: "4px 0" }} />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "nowrap",
                                                    padding: 8
                                                }}
                                            >
                                                <Input
                                                    style={{ flex: "auto" }}
                                                    value={this.state.codeName}
                                                    onChange={e => {
                                                        this.setState({
                                                            codeName: e.target.value,
                                                            codeType: "ClinicMedicines"
                                                        });
                                                    }}
                                                />
                                                <a
                                                    style={{
                                                        flex: "none",
                                                        padding: "8px",
                                                        display: "block",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={this.addItem}
                                                >
                                                    <PlusOutlined /> Add Medicines
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    <Option key={null} value={null}>
                                        Select
                                    </Option>
                                    {this.state.clinicMedicinesSelectItems.map(i => (
                                        <Option key={i.id} value={i.id}>
                                            {i.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id="clinic.ClinicFollowUps" defaultMessage="Follow Ups"/>}
                                name="followUpsId"
                                rules={[
                                    {
                                        required: false,
                                        message: <FormattedMessage id="todo" defaultMessage="This field is required" />
                                    }
                                ]}
                            >
                                <Space>
                                    <DatePicker value={moment(this.state.followUpRows[0].date, 'DD/MM/YYYY')}
                                                format="DD/MM/YYYY" onChange={(date, dateString) => {
                                        this.onChangeFollowUpDate(0, date, dateString)
                                    }} disabledDate={this.disabledDate}/>
                                    <AntButton type="primary" onClick={this.addNewFollowUpLine}
                                               icon={<PlusOutlined/>}></AntButton>
                                    <Checkbox onChange={(e) => this.onChangeFollowUpPeriodicCheck(e)}
                                              checked={this.state.periodic}>Periodic</Checkbox>
                                </Space>
                            </Form.Item>
                        }

                        {Boolean(this.state.followUpRows) && (this.state.followUpRows.map((item, index) => (
                            (index > 0) && <Form.Item
                                {...layout}
                                label="  ">
                                <Space>
                                    <DatePicker value={moment(item.date, 'DD/MM/YYYY')} format="DD/MM/YYYY" onChange={(date, dateString) => { this.onChangeFollowUpDate(index, date, dateString) }} disabledDate={this.disabledDate} />
                                    {(index !== 0) && (<AntButton type="primary" danger onClick={(e) => this.removeFollowUpLine(index, e)} icon={<CloseOutlined />}></AntButton>)}
                                </Space>
                            </Form.Item>)))}
                        {
                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id="clinic.transferToMedicalCenterId" defaultMessage="Transfer To Medical Center" />}
                                name="transferToMedicalCenterId"
                                rules={[
                                    {
                                        required: false,
                                        message: <FormattedMessage id="todo" defaultMessage="This field is required" />
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }}
                                    placeholder="Transfer To Medical Center"
                                    id="transferToMedicalCenterId"
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: "4px 0" }} />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "nowrap",
                                                    padding: 8
                                                }}
                                            >
                                                <Input
                                                    style={{ flex: "auto" }}
                                                    value={this.state.codeName}
                                                    onChange={e => {
                                                        this.setState({
                                                            codeName: e.target.value,
                                                            codeType: "ClinicTransferToMedicalCenter"
                                                        });
                                                    }}
                                                />
                                                <a
                                                    style={{
                                                        flex: "none",
                                                        padding: "8px",
                                                        display: "block",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={this.addItem}
                                                >
                                                    <PlusOutlined /> Add Transfer To Medical Center
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    <Option key={null} value={null}>
                                        Select
                                    </Option>
                                    {this.state.clinicTransferToMedicalCenterSelectItems.map(i => (
                                        <Option key={i.id} value={i.id}>
                                            {i.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        }
                        <div hidden={this.state.hideSave}>
                            {
                                <Row gutter={[16, 16]}>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                        <Button id="SaveButton" type="submit" style={{width: "100%"}} variant="success">
                                            <FormattedMessage id="GeneralButtonSave" defaultMessage="Save"/>
                                        </Button>
                                    </Col>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                </Row>
                            }
                        </div>
                        <div hidden={this.state.hideUpdate}>
                            {
                                <Row gutter={[16, 16]}>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                                        <Button id="CancelButton" style={{width: "100%"}} variant="secondary"
                                                onClick={this.cancel}>
                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel"/>
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                                        <Button id="UpdateButton" style={{width: "100%"}} variant="warning"
                                                type="submit">
                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update"/>
                                        </Button>
                                    </Col>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                </Row>
                            }
                        </div>
                    </Form>
                </div>

                <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                    <Table
                        rowKey="id"
                        bordered
                        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                        columns={columns}
                        dataSource={this.state.tableList}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                        pagination={this.state.pagination}
                        expandable={{
                            expandedRowRender: (record) => (
                                <ClinicSub
                                    random={Math.random()}
                                    employeeId={record.id}
                                ></ClinicSub>
                            ),
                            rowExpandable: () => {
                                return true;
                            },
                        }}
                    />
                </div>

                <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={this.delete}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalFollowUp}
                    onHide={this.showOrHideModalFollowUp}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FormattedMessage id="ClinicFollowUpModal" defaultMessage="Please select follow up period" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row style={{ marginTop: "1rem", width: "100%" }}>
                            <Col sm="5">
                                <FormBoot.Label style={{ marginTop: "0.5rem", textAlign: "right" }}>
                                    <FormattedMessage id="ClinicFollowUpModalEvery" defaultMessage="Every" />
                                </FormBoot.Label>
                            </Col>
                            <Col sm="4">
                                <InputNumber id="ClinicFollowUpPeriod" style={{ width: "100%" }} value={this.state.period} onChange={(value) => this.setState({ period: value })} />
                            </Col>
                            <Col sm="3">
                                <Select style={{ width: "100%" }} value={this.state.periodUnit} onChange={(value) => this.setState({ periodUnit: value })} >
                                    <Option value="Day"><FormattedMessage id="ClinicFollowUpModalDay" defaultMessage="Day" /></Option>
                                    <Option value="Week"><FormattedMessage id="ClinicFollowUpModalWeek" defaultMessage="Week" /></Option>
                                    <Option value="Month"><FormattedMessage id="ClinicFollowUpModalMonth" defaultMessage="Month" /></Option>
                                    <Option value="Year"><FormattedMessage id="ClinicFollowUpModalYear" defaultMessage="Year" /></Option>
                                </Select>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalFollowUp}>Close</ReactButton>
                        <ReactButton variant="primary" onClick={this.periodOK}>Save</ReactButton>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Clinic);
