import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { showError, showSuccess, error } from "../../MessageHelper";
import SyncIcon from "@material-ui/icons/Sync";
import { handleRequest } from "../../ApiConnector";
import { Table, Badge, Form, Select, Row, Col } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Tooltip, Button as AntButton } from "antd";
import DeclineIcon from "@material-ui/icons/CloseOutlined";
import CheckIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import DraggableModal from "../../Components/DraggableModal";
import StockCardNo from "./StockCardNo";
import StockCardPurchaseCriteria from "./StockCardPurchaseCriteria";
import StockCardRenameCriteria from "./StockCardRenameCriteria";
import StockCardRenameCriteria2 from "./StockCardRenameCriteria2";

const { Option } = Select;

export class StockCardSetting extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "StockCardSetting",
      controllerName: "stockCardSetting",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 5,
        showSizeChanger: true,
        pageSizeOptions: ["2", "5", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseType = await handleRequest("GET", "/api/" + this.state.controllerName + "/type");
    if (responseType.type === "ERROR") {
      error(responseType);
    }
    var responseMaterialCategory = await handleRequest("GET", "/api/" + this.state.controllerName + "/materialCategory");
    if (responseMaterialCategory.type === "ERROR") {
      error(responseMaterialCategory);
    }
    var responseMaterialDescription = await handleRequest("GET", "/api/" + this.state.controllerName + "/materialDescription");
    if (responseMaterialDescription.type === "ERROR") {
      error(responseMaterialDescription);
    }
    var responseMaterialType = await handleRequest("GET", "/api/" + this.state.controllerName + "/materialType");
    if (responseMaterialType.type === "ERROR") {
      error(responseMaterialType);
    }

    this.setState({
      typeSelectItems: Boolean(responseType.data) ? responseType.data : [],
      materialCategorySelectItems: Boolean(responseMaterialCategory.data) ? responseMaterialCategory.data : [],
      materialDescriptionSelectItems: Boolean(responseMaterialDescription.data) ? responseMaterialDescription.data : [],
      materialTypeSelectItems: Boolean(responseMaterialType.data) ? responseMaterialType.data : [],
    });
  }

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  editCode = row => {
    this.setState({
      modalStockCardNoShow: true,
      random: Math.random(),
      categoryId: row.categoryId,
      descriptionId: row.descriptionId,
      materialTypeId: row.materialTypeId,
    });
  };

  editRename1 = row => {
    this.setState({
      modalRenameShow: true,
      random: Math.random(),
      categoryId: row.categoryId,
      descriptionId: row.descriptionId,
      materialTypeId: row.materialTypeId,
    });
  };

  editRename2 = row => {
    this.setState({
      modalRenameShow2: true,
      random: Math.random(),
      categoryId: row.categoryId,
      descriptionId: row.descriptionId,
      materialTypeId: row.materialTypeId,
    });
  };

  editPurcahsing = row => {
    this.setState({
      modalPurchaseCriteriaShow: true,
      random: Math.random(),
      categoryId: row.categoryId,
      descriptionId: row.descriptionId,
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };
  action = record => {
    let editCode = {
      name: "Code",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.editCode.bind(this)
    };
    let editRename1 = {
      name: "Rename 1",
      icon: <EditOutlined fontSize="small" color="error" />,
      actionClick: this.editRename1.bind(this)
    };
    let editRename2 = {
      name: "Rename 2",
      icon: <EditOutlined fontSize="small" color="secondary" />,
      actionClick: this.editRename2.bind(this)
    };
    let editPurcahsing = {
      name: "Purchasing",
      icon: <EditOutlined fontSize="small" color="action" />,
      actionClick: this.editPurcahsing.bind(this)
    };

    let actionList = [];
    actionList.push(editCode);
    actionList.push(editRename1);
    actionList.push(editRename2);
    actionList.push(editPurcahsing);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 5,
        showSizeChanger: true,
        pageSizeOptions: ["2", "5", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  sync = async () => {
    let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/sync");
    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
    }
  };


  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const columns = [
      {
        key: "key",
        render: this.action,
        width: "7%",
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        },
        width: "7%",
      },
      {
        title: <FormattedMessage id="StockCardSetting.type" defaultMessage="Type" />,
        key: "type",
        render: record => {
          return record.type;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "250px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  type: value,
                  typeColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["typeSelectItems"]) && this.state["typeSelectItems"].length > 0 &&
                this.state["typeSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}{" "}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.typeColor) ? this.state.pagination.typeColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: "10%",
      },
      {
        title: <FormattedMessage id="StockCardSetting.materialCategory" defaultMessage="Material Category" />,
        key: "categoryId",
        render: record => {
          return record.materialCategory;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "250px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  categoryId: value,
                  categoryIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["materialCategorySelectItems"]) && this.state["materialCategorySelectItems"].length > 0 &&
                this.state["materialCategorySelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}{" "}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.categoryIdColor) ? this.state.pagination.categoryIdColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: "16%",
      },
      {
        title: <FormattedMessage id="StockCardSetting.materialDescription" defaultMessage="Material Description" />,
        key: "descriptionId",
        render: record => {
          return record.materialDescription;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "250px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  descriptionId: value,
                  descriptionIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["materialDescriptionSelectItems"]) && this.state["materialDescriptionSelectItems"].length > 0 &&
                this.state["materialDescriptionSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}{" "}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.descriptionIdColor) ? this.state.pagination.descriptionIdColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: "16%",
      },
      {
        title: <FormattedMessage id="StockCardSetting.materialType" defaultMessage="Material Type" />,
        key: "materialTypeId",
        render: record => {
          return record.materialType;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "250px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  materialTypeId: value,
                  materialTypeIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["materialTypeSelectItems"]) && this.state["materialTypeSelectItems"].length > 0 &&
                this.state["materialTypeSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}{" "}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.materialTypeIdColor) ? this.state.pagination.materialTypeIdColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: "16%",
      },
      {
        title: <FormattedMessage id="StockCardSetting.Code" defaultMessage="Code" />,
        key: "id",
        render: record => {
          return record.hasCode ?
            <CheckIcon
              onClick={() => {
                this.editCode(record);
              }}
              fontSize="large" color="primary" /> :
            <DeclineIcon
              onClick={() => {
                this.editCode(record);
              }}
              fontSize="large" color="error" />;
        },
        width: "7%",
      },
      {
        title: <FormattedMessage id="StockCardSetting.Rename1" defaultMessage="Rename1" />,
        key: "id",
        render: record => {
          return record.hasRename1 ?
            <CheckIcon
              onClick={() => {
                this.editRename1(record);
              }}
              fontSize="large" color="primary" /> :
            <DeclineIcon
              onClick={() => {
                this.editRename1(record);
              }}
              fontSize="large" color="error" />;
        },
        width: "7%",
      },
      {
        title: <FormattedMessage id="StockCardSetting.Rename2" defaultMessage="Rename2" />,
        key: "id",
        render: record => {
          return record.hasRename2 ?
            <CheckIcon
              onClick={() => {
                this.editRename2(record);
              }}
              fontSize="large" color="primary" /> :
            <DeclineIcon
              onClick={() => {
                this.editRename2(record);
              }}
              fontSize="large" color="error" />;
        },
        width: "7%",
      },
      {
        title: <FormattedMessage id="StockCardSetting.purchasing" defaultMessage="Purchasing" />,
        key: "id",
        render: record => {
          return record.hasPurcahse ?
            <CheckIcon
              onClick={() => {
                this.editPurcahsing(record);
              }}
              fontSize="large" color="primary" /> :
            <DeclineIcon
              onClick={() => {
                this.editPurcahsing(record);
              }}
              fontSize="large" color="error" />;
        },
        width: "7%",
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row>
            <Col xs={23}>
            </Col>
            <Col md={1}>
              <Tooltip title="Sync Rename Settings">
                <AntButton style={{ border: "0px" }} onClick={this.sync} icon={<SyncIcon color="primary" fontSize="large" />}></AntButton>
              </Tooltip>
            </Col>
          </Row>

        </Form>
        {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
          <div className="alert alert-custom alert-light">
            <div className="alert-icon">
              <FilterOutlined />
            </div>
            <div className="alert-text">
              {this.state.searchCriteriaModels.map((val, i) => {
                return (
                  <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                    <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                    <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                    <span>
                      <Tooltip title="Remove">
                        <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                      </Tooltip>
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="alert-close">
              <Tooltip title="Remove All">
                <CloseCircleOutlined
                  style={{ color: "crimson" }}
                  onClick={() =>
                    this.setState({ searchCriteriaModels: [] }, () => {
                      this.filterChangeItem([]);
                    })
                  }
                />
              </Tooltip>
            </div>
          </div>
        )}
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <DraggableModal
          open={this.state.modalStockCardNoShow}
          onCancel={() => {
            this.setState({ modalStockCardNoShow: false });
          }}
          width={1500}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalStockCardNoShow: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
          content={
            <Badge.Ribbon
              text={<FormattedMessage id="StockCardNo.purchaseOrderNo" defaultMessage="Stock Card No" />}
              placement="start"
              color="purple"
              style={{ fontSize: 12 }}
            >
              <div className="card card-custom" style={{ minHeight: "100%" }}>
                <StockCardNo
                  random={this.state.random}
                  categoryId={this.state.categoryId}
                  descriptionId={this.state.descriptionId}
                  materialTypeId={this.state.materialTypeId}
                  descriptionType="SELECT"
                />
              </div>
            </Badge.Ribbon>
          }
        />

        <DraggableModal
          open={this.state.modalPurchaseCriteriaShow}
          onCancel={() => {
            this.setState({ modalPurchaseCriteriaShow: false });
          }}
          width={800}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalPurchaseCriteriaShow: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <StockCardPurchaseCriteria
                random={this.state.random}
                categoryId={this.state.categoryId}
                descriptionId={this.state.descriptionId}
                descriptionType="SELECT"
                saveStockCardPurchaseCriteria={() => {
                  this.setState({
                    modalPurchaseCriteriaShow: false
                  })
                }}
              />
            </div>
          }
        />

        <DraggableModal
          open={this.state.modalRenameShow}
          onCancel={() => {
            this.setState({ modalRenameShow: false });
          }}
          title={
            <Badge.Ribbon
              text={<FormattedMessage id="StockCardNo.RenameSettings" defaultMessage="Rename Settings" />}
              placement="start"
              color="purple"
              style={{ fontSize: 12 }}
            >
              {" "}
            </Badge.Ribbon>
          }
          width={1000}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalRenameShow: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <StockCardRenameCriteria
                random={this.state.random}
                descriptionId={this.state.descriptionId}
                categoryId={this.state.categoryId}
                descriptionType="SELECT"
                saveStockCardRenameCriteria={() => {
                  this.setState({
                    modalRenameShow: false
                  })
                }}
              />
            </div>
          }
        />

        <DraggableModal
          open={this.state.modalRenameShow2}
          onCancel={() => {
            this.setState({ modalRenameShow2: false });
          }}
          title={
            <Badge.Ribbon
              text={<FormattedMessage id="StockCardNo.RenameSettings" defaultMessage="Rename Settings" />}
              placement="start"
              color="purple"
              style={{ fontSize: 12 }}
            >
            </Badge.Ribbon>
          }
          width={1000}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalRenameShow2: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <StockCardRenameCriteria2
                random={this.state.random}
                descriptionId={this.state.descriptionId}
                categoryId={this.state.categoryId}
                descriptionType="SELECT"
                saveStockCardRenameCriteria={() => {
                  this.setState({
                    modalRenameShow2: false
                  })
                }}
              />
            </div>
          }
        />
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(StockCardSetting);