import { Form, InputNumber } from "antd";
import React from "react";
import PrepareFormItemProps from "./ElementUtils";

const NumberComponent = ({ field, layout }) => {
  let otherProps = {};
  if (field.min) {
    otherProps.min = field.min;
  }
  return (
    <>
      <Form.Item {...layout} {...PrepareFormItemProps(field)}>
        <InputNumber {...otherProps} placeholder={field.label} style={{ width: "100%" }} />
      </Form.Item>
    </>
  );
};

export default NumberComponent;
