import { Form } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import ElementsProvider from "../../Components/ElementsProvider";
import { GetDynamicFormItems, PrepareRowForEdit, PrepareRowForSave } from "../../Components/ElementUtils";
import FormSaveButtonsComponent from "../../Components/FormSaveButtonsComponent";
import { error, showError, showSuccess } from "../../MessageHelper";

export class JobOfferPromptForm extends Component {
  constructor(props) {
    super();
    this.state = {
      id: null,
      formName: "PersonnelLogPromptRecord",
      inputs: [],
      country: "sa",
      pageFormData: null
    };
  }
  formRef = React.createRef();
  componentDidMount = async () => {
    let data = await GetDynamicFormItems(this.state.formName);
    this.setState({
      pageFormData: data
    });
    if (Boolean(this.props) && Boolean(this.props.row)) {
      this.edit(this.props.row);
    }
    this.fillComboboxes();
  };
  componentDidUpdate = async nextProps => {
    let id = Boolean(this.props) && Boolean(this.props.row) ? this.props.row.id : null;
    if (id === this.state.id) {
      return;
    }

    if (Boolean(this.props) && Boolean(this.props.row)) {
      this.edit(this.props.row);
    } else {
      this.resetInputs();
    }
  };

  fillComboboxes = async () => {};

  cancel = () => {
    this.resetInputs();
    if (Boolean(this.props) && Boolean(this.props.closeModal)) {
      this.props.closeModal();
    }
    if (Boolean(this.props) && Boolean(this.props.onCancel)) {
      this.props.onCancel();
    }
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      country: "sa"
    });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();
    let handledValues = {
      ...row
    };
    handledValues = PrepareRowForEdit(handledValues, this.state.inputs);
    this.formRef.current.setFieldsValue({
      ...handledValues
    });

    this.setState({
      id: row.id
    });
  };

  onClickCreateNew = () => {
    this.resetInputs();
  };

  save = async values => {
    let newItem = {
      ...values
    };
    newItem = PrepareRowForSave(values, this.state.pageFormData.pageFormInputCompanyList);
    newItem.id = this.state.id;
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/personnelLog", newItem);
    } else {
      response = await handleRequest("PUT", "/api/personnelLog/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
      if (Boolean(this.props) && Boolean(this.props.onSave)) {
        let obj = {
          id: response.data,
          name: newItem.name + " " + (Boolean(newItem.midName) ? newItem.midName : "") + " " + newItem.lastName
        };
        if (Boolean(newItem.id)) {
          obj.id = newItem.id;
        }
        this.props.onSave(obj);
        document.getElementById("kt_scrolltop").click();
      }
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };
  normalizingFileUpload = event => {
    if (Array.isArray(event)) {
      return event;
    }
    return event && event.fileList;
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: { marginBottom: 0 }
    };

    return (
      <Form initialValues={{ remember: false, gender: "MALE" }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
        {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
          <ElementsProvider
            inputs={this.state.pageFormData.pageFormInputCompanyList}
            layout={layout}
            form={this.formRef}
            refresh={this.state.refreshForm}
          />
        ) : null}
        <FormSaveButtonsComponent onCancel={this.cancel} />
      </Form>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(JobOfferPromptForm);
