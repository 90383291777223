import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Input, Tree, Button as AntButton, Form, Modal as AntModal, Select, Card, Badge, Space, Divider, InputNumber, Row, Col } from "antd";
import { FcFolder, FcEditImage, FcFile, FcSettings } from "react-icons/fc";
import { Menu, Item, useContextMenu } from "react-contexify";
import { FaFileAudio, FaRegFile } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../ApiConnector";
import { showError, showSuccess, error } from "../../MessageHelper";
import { Button, Table as TableBoot } from "react-bootstrap";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import DraggableModal from "../../Components/DraggableModal";

const Menu_NewWorkShops = "Menu_NewWorkShops";
const Menu_WorkShops = "Menu_WorkShops";
const Menu_SubWorkShops = "Menu_SubWorkShops";
const Menu_Station = "Menu_Station";


const { Option } = Select;
//const { TextArea } = Input;

const { show } = useContextMenu();

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

var dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    dataList.push({ key: node.key, title: node.title });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class WorkShops extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "WorkShops",
      controllerName: "workShops",
      id: null,
      projectName: "",
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      loadedKeys: [],
      searchValue: "",
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showServiceModal: false,
      refreshTree: true
    };
  }

  inputRef = React.createRef(null);

  formRef = React.createRef();
  formRefOnClickName = React.createRef();
  formRefCreateProject = React.createRef();
  formRefWorkShopsWorkShops = React.createRef();
  formRefshowWorkShopsSubWorkShopsFromWorkShops = React.createRef();
  formRefshowWorkShopsSubWorkShopsFromSubWorkShops = React.createRef();
  formRefshowWorkShopsSubWorkShopsFromStation = React.createRef();
  formRefWorkShopsStationFromWorkShops = React.createRef();
  formRefWorkShopsStationFromSubWorkShops = React.createRef();
  formRefWorkShopsStationFromStation = React.createRef();
  formRefWorkShopsWorkShopsCoordinates = React.createRef();
  formRefWorkShopsSubWorkShopsCoordinates = React.createRef();
  formRefStationCoordinates = React.createRef();
  formRefWorkShopsWorkDescription = React.createRef();

  componentDidMount = async () => {
    this.fillTree();
    this.fillCode("WorkShops_WorkShopsType");
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "ProjectName";
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "ProjectName"
    };

    // SUBITEMS
    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      // if (treenode.type === "WorkShops") {
      //   response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsWorkShops/" + obj.id);
      // } else

      if (treenode.type === "SubWorkShops") {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsSubWorkShops/" + obj.id);
      } else if (treenode.type === "Station") {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsStation/" + obj.id);
      } else {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitems/" + obj.id + "/" + obj.type);
      }
    }

    if (response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.icon =
          Boolean(element.type) && element.type === "WorkShops" ? (
            <FcFile />
          ) : element.type === "SubWorkShops" ? (
            <FaRegFile />
          ) : element.type === "Station" ? (
            <FaFileAudio />
          ) : (
            <FcFolder />
          );
        element.title = element.value;
        element.id = element.key;
        element.key = element.key + "-" + element.type + "." + element.projectId;
        // element.key = element.id + "." + element.type + "." + element.projectId;
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      this.setState({
        nodes: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  onRightClick = (event, node) => {
    let parentNode = this.getParentNode(node);
    console.log("parentNode", parentNode);
    console.log("node", node);
    this.setState(
      {
        selectedNodeKey: node.key,
        selectedNode: node,
        parentNode: parentNode
      },
      this.displayMenu(event, Boolean(node) && Boolean(node.type) ? node.type : null)
    );
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  handleItemClick = ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;

    console.log("AAA", event.currentTarget.id);
    console.log(selectedNode);
    console.log(parentNode);
    console.log(key);

    this.setState({
      projectId: selectedNode.projectId,
    });

    switch (event.currentTarget.id) {
      case "add_WorkShops":
        this.setState({
          showWorkShopsWorkShopsModal: true,
          parentId: null,
          type: "WorkShops",
          id: null,
        });
        break;

      case "add_SubWork":
        this.setState({
          showWorkShopsSubWorkShopsFromWorkShopsModal: true,
          parentId: (Boolean(selectedNode) && !(selectedNode.type === "ProjectName" || selectedNode.type === "CompanyName")) ? selectedNode.id : null,
          type: "SubWorkShops",
          id: null,
        });
        break;

      case "add_Station":
        this.setState({
          showWorkShopsStationFromWorkShopsModal: true,
          parentId: (Boolean(selectedNode) && !(selectedNode.type === "ProjectName" || selectedNode.type === "CompanyName")) ? selectedNode.id : null,
          type: "Station",
          id: null,
        });
        break;

      case "work_Description":
        this.setState({
          showWorkDescriptionModal: true,
          parentId: (Boolean(selectedNode) && !(selectedNode.type === "ProjectName" || selectedNode.type === "CompanyName")) ? selectedNode.id : null,
          type: "Work Description",
          id: null,
        });
        break;

      case "edit_WorkShops":
        this.setState({
          showWorkShopsWorkShopsModal: true,
          id: selectedNode.id,
          parentId: (Boolean(parentNode) && !(parentNode.type === "ProjectName" || parentNode.type === "CompanyName")) ? parentNode.id : null,
          type: selectedNode.type,
        }, async () => {
          var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/" + selectedNode.id);
          if (response.type === "ERROR") {
            error(response);
          } else {
            if (Boolean(response.data)) {
              this.formRefWorkShopsWorkShops.current.setFieldsValue({
                ...response.data
              });
            }
          }
        });
        break;

      case "edit_SubWorkShops":
        this.setState({
          showWorkShopsSubWorkShopsFromWorkShopsModal: true,
          id: selectedNode.id,
          parentId: (Boolean(parentNode) && !(parentNode.type === "ProjectName" || parentNode.type === "CompanyName")) ? parentNode.id : null,
          type: selectedNode.type,
        }, async () => {
          var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/" + selectedNode.id);
          if (response.type === "ERROR") {
            error(response);
          } else {
            if (Boolean(response.data)) {
              this.formRefshowWorkShopsSubWorkShopsFromWorkShops.current.setFieldsValue({
                ...response.data
              });
            }
          }
        });
        break;

      case "edit_Station":
        this.setState({
          showWorkShopsStationFromWorkShopsModal: true,
          id: selectedNode.id,
          parentId: (Boolean(parentNode) && !(parentNode.type === "ProjectName" || parentNode.type === "CompanyName")) ? parentNode.id : null,
          type: selectedNode.type
        }, async () => {
          var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getStation/" + selectedNode.id);
          if (response.type === "ERROR") {
            error(response);
          } else {
            if (Boolean(response.data)) {
              this.formRefWorkShopsStationFromWorkShops.current.setFieldsValue({
                ...response.data
              });
            }
          }
        });
        break;

      case "edit_Work_Description":
        this.setState({
          showWorkDescriptionModal: true,
          id: selectedNode.id,
          parentId: (Boolean(parentNode) && !(parentNode.type === "ProjectName" || parentNode.type === "CompanyName")) ? parentNode.id : null,
          type: selectedNode.type
        }, async () => {
          var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getStation/" + selectedNode.id);
          if (response.type === "ERROR") {
            error(response);
          } else {
            if (Boolean(response.data)) {
              this.formWorkDescription.current.setFieldsValue({
                ...response.data
              });
            }
          }
        });
        break;

      case "deleteWorkShopsWorkShops":
        this.setState({
          showDeleteWorkShopsWorkShopsModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;

      case "deleteWorkShopsSubWorkShops":
        this.setState({
          showDeleteWorkShopsSubWorkShopsModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;

      case "deleteWorkShopsStation":
        this.setState({
          showDeleteWorkShopsStationModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;

      case "deleteWorkDescription":
        this.setState({
          showDeleteWorkDescriptionModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;

      case "delete":
        this.setState({
          showDeleteModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;
      default:
    }
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id,
      type: this.state.type
    };

    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
      else this.fillTree();

      showSuccess();
      this.setState({
        id: null,
        showDeleteModal: false
      });
    }
  };

  displayMenu = (e, type) => {
    console.log("TTT", type);
    if (Boolean(type)) {
      if (type === "WorkShops") {
        show(e, { id: Menu_WorkShops });
      } else if (type === "SubWorkShops") {
        show(e, { id: Menu_SubWorkShops });
      } else if (type === "Station") {
        show(e, { id: Menu_Station });
      } else if (type === "CompanyName" || type === "ProjectName") {
        show(e, { id: Menu_NewWorkShops });
      }
    }
  };

  // DELETE   DELETE    DELETE

  deleteWorkShopsWorkShops = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteWorkShopsWorkShops/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteWorkShopsWorkShopsModal: false
        });
      }
    } else {
      showError("WorkShops not found.");
    }
  };

  deleteWorkShopsSubWorkShops = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteWorkShopsSubWorkShops/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteWorkShopsSubWorkShopsModal: false
        });
      }
    } else {
      showError("SubWorkShops not found.");
    }
  };

  deleteWorkShopsStation = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteWorkShopsStation/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteWorkShopsStationModal: false
        });
      }
    } else {
      showError("Station not found.");
    }
  };

  save = async values => {
    const { process, selectedNode } = this.state;
    console.log("selectedNode", selectedNode);
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId,
      type: Boolean(this.state.type) ? this.state.type : "ProjectName"
    };
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveProjectsTree", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateProjectsTree/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showCreateProjectModal: false
      });
    }
  };

  // SAVE UPDATE   SAVE UPDATE  SAVE UPDATE   SAVE UPDATE   SAVE UPDATE

  // SAVE ADD NEW WorkShops
  saveWorkShopsWorkShops = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId,
      projectId: this.state.projectId,
      type: this.state.type
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createWorkShops", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateWorkShops/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showWorkShopsWorkShopsModal: false
      });
    }
  };

  // SAVE ADD SUB WorkShop
  saveWorkShopsSubWorkShopsFromWorkShops = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId,
      projectId: this.state.projectId,
      type: this.state.type
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createWorkShops", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateWorkShops/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showWorkShopsSubWorkShopsFromWorkShopsModal: false,
      });
    }
  };

  saveWorkShopsSubWorkShopsFromStation = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      workShopsStationId: this.state.workShopsStationId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveWorkShopsSubWorkShops", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateWorkShopsSubWorkShops/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showWorkShopsSubWorkShopsFromStationModal: false
      });
    }
  };

  // SAVE NEW Station

  saveWorkShopsStationFromWorkShops = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      workShopsId: this.state.parentId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveWorkShopsStation", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateWorkShopsStation/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showWorkShopsStationFromWorkShopsModal: false
      });
    }
  };

  saveWorkShopsStationFromSubWorkShops = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      workShopsId: this.state.workShopsId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveWorkShopsStation", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateWorkShopsStation/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showWorkShopsStationModal: false
      });
    }
  };

  saveWorkShopsStationFromStation = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveWorkShopsStation", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateWorkShopsStation/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showWorkShopsStationModal: false
      });
    }
  };

  // SAVE COORDINATES

  saveWorkShopsWorkShopsCoordinates = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveWorkShopsSubWorkShopsCoordinates", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateWorkShopsWorkShopsCoordinates/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showWorkShopsStationModal: false
      });
    }
  };

  saveWorkShopsSubWorkShopsCoordinates = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      workShopsId: this.state.workShopsId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveWorkShopsSubWorkShopsCoordinates", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateWorkShopsSubWorkShopsCoordinates/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showWorkShopsStationModal: false
      });
    }
  };

  saveWorkShopsStationCoordinates = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      workShopsStationId: this.state.workShopsStationId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveWorkShopsStationCoordinates", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateWorkShopsStationCoordinates/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showWorkShopsStationModal: false
      });
    }
  };

  paste = () => {
    this.formRef.current.submit();
    this.formRefCreateProject.current.submit();
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      projectName: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      process: null,
      showOrderModal: false,
      showPasteModal: false,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showServiceModal: false
    });
  };

  expandSelectedNode = () => {
    let key = this.state.selectedNodeKey;
    let node = this.state.selectedNode;
    if (Boolean(key)) {
      let expandedKeys = Boolean(this.state.expandedKeys) ? this.state.expandedKeys : [];
      if (Boolean(expandedKeys.find(p => p === key))) {
        this.loadData(node, false);
      } else {
        this.setState({
          expandedKeys: [...expandedKeys, key]
        });
        this.loadData(node, false);
      }
    }
  };

  itemTemplate(item) {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <h5 className="mb-2">{item.title}</h5>
        </div>
      </div>
    );
  }

  onDragEnter = info => { };

  onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...this.state.nodes];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 &&
      // Has children
      info.node.props.expanded &&
      // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    this.setState({
      nodes: data
    });
  };

  closeFormModal = () => {
    this.setState({
      showFormModal: false
    });
  };

  closeTableModal = () => {
    this.setState({
      showTableModal: false
    });
  };

  closeInputFormatModal = () => {
    this.setState({
      showInputFormatModal: false
    });
  };

  closeTableFormatModal = () => {
    this.setState({
      showTableFormatModal: false
    });
  };

  okProjectTreeTemplate = async () => {
    const { targetKeys, projectId } = this.state;
    if (Boolean(projectId) && projectId > 0) {
      if (targetKeys && targetKeys.length > 0) {
        targetKeys.sort((a, b) => {
          return a - b;
        });

        var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/eldenTransfer/" + targetKeys + "/" + projectId);
        if (response.type === "ERROR") {
          error(response);
        } else {
          if (Boolean(this.state.parentNode)) {
            this.loadData(this.state.parentNode, false);
          } else {
            this.fillTree();
          }
          showSuccess();
          this.setState({ modalEldenProjectTree: false });
        }
      } else {
        showError("Not found.");
      }
    } else {
      showError("Company-Project Not found.");
    }
  };

  onTargetKeysCheck = async targetKeys => {
    console.log("targetKeys", targetKeys);
    this.setState({
      targetKeys: targetKeys
    });
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 12 }
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <Badge.Ribbon
        text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage={"Work Shops"} />}
        placement="start"
        color="purple"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <br /> <br />
          {this.state.refreshTree && (
            <Tree
              style={{ marginTop: "10px", lineHeight: "30px" }}
              className="draggable-tree"
              showLine={{ showLeafIcon: false }}
              showIcon
              checkStrictly={true}
              loadedKeys={this.state.loadedKeys}
              onLoad={this.onLoadData}
              loadData={this.loadData}
              onRightClick={({ event, node }) => this.onRightClick(event, node)}
              onExpand={this.onExpand}
              expandedKeys={this.state.expandedKeys}
              autoExpandParent={false}
              treeData={this.state.nodes}
              blockNode={true}
              draggable
              // checkable
              // onCheck={this.onCheck}
              // checkedKeys={this.state.checkedKeys}
              halfCheckedKeys={this.state.halfCheckedKeys}
              onDragEnter={this.onDragEnter}
              onDrop={this.onDrop}
            />
          )}
          {/* MENU */}
          <Menu id={Menu_NewWorkShops}>
            <Item id="add_WorkShops" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="WorkShops.AddNewWorkShop" defaultMessage="Add New WorkShop" />
                </span>
              </Space>
            </Item>
          </Menu>
          <Menu id={Menu_WorkShops}>
            <Item id="edit_WorkShops" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="WorkShops.edit" defaultMessage="Edit" />
                </span>
              </Space>
            </Item>
            <Item id="add_SubWork" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="WorkShops.AddSubWorkShop" defaultMessage="Add Sub WorkShop" />
                </span>
              </Space>
            </Item>
            <Item id="add_Station" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="WorkShops.AddStation" defaultMessage="Add Station" />
                </span>
              </Space>
            </Item>

            <Item id="deleteWorkShopsWorkShops" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="WorkShops.Delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>
          <Menu id={Menu_SubWorkShops}>
            <Item id="edit_SubWorkShops" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="WorkShops.Edit" defaultMessage="Edit" />
                </span>
              </Space>
            </Item>
            <Item id="add_SubWork" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="WorkShops.AddSubWorkShop" defaultMessage="Add Sub WorkShop" />
                </span>
              </Space>
            </Item>
            <Item id="add_Station" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="WorkShops.AddStation" defaultMessage="Add Station" />
                </span>
              </Space>
            </Item>

            <Item id="deleteWorkShopsSubWorkShops" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="WorkShops.Delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>
          <Menu id={Menu_Station}>
            <Item id="edit_Station" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="WorkShops.Edit" defaultMessage="Edit" />
                </span>
              </Space>
            </Item>
            <Item id="work_Description" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="WorkShops.WorkDescription" defaultMessage="Work Description" />
                </span>
              </Space>
            </Item>
            <Item id="deleteWorkShopsStation" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="WorkShops.Delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>
          {/* TABLE MODAL */}

          {/* Add NewW WorkShops */}
          <DraggableModal
            title={<FormattedMessage id="WorkShops.AddNewWorkShop" defaultMessage="Add New WorkShop" />}
            open={this.state.showWorkShopsWorkShopsModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showWorkShopsWorkShopsModal: false })}
            onOk={() => this.formRefWorkShopsWorkShops.current.submit()}
            width={800}
            centered
            content={
              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveWorkShopsWorkShops}
                onFinishFailed={onFinishFailed}
                ref={this.formRefWorkShopsWorkShops}
              >
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="WorkShops.WorkShopName" defaultMessage="WorkShop Name" />}
                    name="workShopName"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item {...layout} label={<FormattedMessage id="ToDo.File" defaultMessage="Coordinates" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => this.setState({ showWorkShopsWorkShopsCoordinatesModal: true })}
                      icon={<FcSettings />}
                    ></AntButton>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="WorkShops.WorkShopType" defaultMessage="WorkShop Type" />}
                    name="workShopsTypeId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="WorkShops Type"
                      optionFilterProp="children"
                      dropdownRender={menu => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              padding: 8
                            }}
                          >
                            <Input
                              style={{ flex: "auto" }}
                              value={this.state.codeName}
                              onChange={e => {
                                this.setState({
                                  codeName: e.target.value
                                });
                              }}
                            />
                            <AntButton type="link" icon={<PlusOutlined />} onClick={() => this.addCode("WorkShops_WorkShopsType")}>
                              <FormattedMessage id="General.AddNew" defaultMessage="Add New" />
                            </AntButton>
                          </div>
                        </div>
                      )}
                    >
                      {Boolean(this.state["WorkShops_WorkShopsTypeSelectItems"]) &&
                        this.state["WorkShops_WorkShopsTypeSelectItems"].map(i => (
                          <Option key={i.id} value={i.id}>
                            {i.name}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }

                {/* ADDRESS */}
                {
                  <Form.Item {...layout} label={<FormattedMessage id="WorkShops.Address" defaultMessage="Address" />}>
                    <Row gutter={5}>
                      <Col span={6}>
                        {/* Address01 */}
                        <Form.Item
                          name="address01Id"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                            {Boolean(this.state["address03IdSelectItems"]) &&
                              this.state["address03IdSelectItems"].length > 0 &&
                              this.state["address03IdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}{" "}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={6}>
                        {/* Address02 */}
                        <Form.Item
                          name="address02Id"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                            {Boolean(this.state["address03IdSelectItems"]) &&
                              this.state["address03IdSelectItems"].length > 0 &&
                              this.state["address03IdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}{" "}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={6}>
                        {/* Address03 */}
                        <Form.Item
                          name="address03Id"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                            {Boolean(this.state["address03IdSelectItems"]) &&
                              this.state["address03IdSelectItems"].length > 0 &&
                              this.state["address03IdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}{" "}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={24}>
                        {/* Address Information */}
                        <Form.Item
                          name="addressInformation"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                }
              </Form>
            }
          />

          {/* Add Sub-WorkShops*/}
          <DraggableModal
            title={<FormattedMessage id="WorkShops.SubWorkShop" defaultMessage="Sub-WorkShop" />}
            open={this.state.showWorkShopsSubWorkShopsFromWorkShopsModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showWorkShopsSubWorkShopsFromWorkShopsModal: false })}
            onOk={() => this.formRefshowWorkShopsSubWorkShopsFromWorkShops.current.submit()}
            width={800}
            centered
            content={
              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveWorkShopsSubWorkShopsFromWorkShops}
                onFinishFailed={onFinishFailed}
                ref={this.formRefshowWorkShopsSubWorkShopsFromWorkShops}
              >
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="WorkShops.WorkShopsName" defaultMessage="WorkShops Name" />}
                    name="workShopsName"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {/* COORDINATES */}
                {
                  <Form.Item {...layout} label={<FormattedMessage id="ToDo.File" defaultMessage="Coordinates" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => this.setState({ showWorkShopsSubWorkShopsCoordinatesModal: true })}
                      icon={<FcSettings />}
                    ></AntButton>
                  </Form.Item>
                }
                {/* WorkShops Type */}
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="WorkShops.WorkShopsType" defaultMessage="WorkShops Type" />}
                    name="workShopsTypeId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="WorkShops Type"
                      optionFilterProp="children"
                      dropdownRender={menu => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              padding: 8
                            }}
                          >
                            <Input
                              style={{ flex: "auto" }}
                              value={this.state.codeName}
                              onChange={e => {
                                this.setState({
                                  codeName: e.target.value
                                });
                              }}
                            />
                            <AntButton type="link" icon={<PlusOutlined />} onClick={() => this.addCode("WorkShops_WorkShopsType")}>
                              <FormattedMessage id="General.AddNew" defaultMessage="Add New" />
                            </AntButton>
                          </div>
                        </div>
                      )}
                    >
                      {Boolean(this.state["WorkShops_WorkShopsTypeSelectItems"]) &&
                        this.state["WorkShops_WorkShopsTypeSelectItems"].map(i => (
                          <Option key={i.id} value={i.id}>
                            {i.name}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }
                {/* ADDRESS */}
                {
                  <Form.Item {...layout} label={<FormattedMessage id="WorkShops.Address" defaultMessage="Address" />}>
                    <Row gutter={5}>
                      <Col span={6}>
                        {/* Address01 */}
                        <Form.Item
                          name="address01Id"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                            {Boolean(this.state["address03IdSelectItems"]) &&
                              this.state["address03IdSelectItems"].length > 0 &&
                              this.state["address03IdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}{" "}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={6}>
                        {/* Address02 */}
                        <Form.Item
                          name="address02Id"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                            {Boolean(this.state["address03IdSelectItems"]) &&
                              this.state["address03IdSelectItems"].length > 0 &&
                              this.state["address03IdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}{" "}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={6}>
                        {/* Address03 */}
                        <Form.Item
                          name="address03Id"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                            {Boolean(this.state["address03IdSelectItems"]) &&
                              this.state["address03IdSelectItems"].length > 0 &&
                              this.state["address03IdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}{" "}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={24}>
                        {/* Address Information */}
                        <Form.Item
                          name="addressInformation"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                }

              </Form>
            }
          />

          {/* ADD SUB WorkShop FROM SUBAREA*/}
          <DraggableModal
            title={<FormattedMessage id="WorkShops.AddStation" defaultMessage="Sub-Area" />}
            open={this.state.showWorkShopsSubWorkShopsFromStationModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showWorkShopsSubWorkShopsFromStationModal: false })}
            onOk={() => this.formRefshowWorkShopsSubWorkShopsFromStation.current.submit()}
            width={800}
            centered
            content={
              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveWorkShopsSubWorkShopsFromStation}
                onFinishFailed={onFinishFailed}
                ref={this.formRefshowWorkShopsSubWorkShopsFromStation}
              >
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="WorkShops.WorkShopsName" defaultMessage="WorkShops Name" />}
                    name="workShopsName"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {/* COORDINATES */}
                {
                  <Form.Item {...layout} label={<FormattedMessage id="ToDo.File" defaultMessage="Coordinates" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => this.setState({ showWorkShopsSubWorkShopsCoordinatesModal: true })}
                      icon={<FcSettings />}
                    ></AntButton>
                  </Form.Item>
                }
                {/* WorkShops Type */}
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="WorkShops.WorkShopsType" defaultMessage="WorkShops Type" />}
                    name="workShopsTypeId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="WorkShops Type"
                      optionFilterProp="children"
                      dropdownRender={menu => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              padding: 8
                            }}
                          >
                            <Input
                              style={{ flex: "auto" }}
                              value={this.state.codeName}
                              onChange={e => {
                                this.setState({
                                  codeName: e.target.value
                                });
                              }}
                            />
                            <AntButton type="link" icon={<PlusOutlined />} onClick={() => this.addCode("WorkShops_WorkShopsType")}>
                              <FormattedMessage id="General.AddNew" defaultMessage="Add New" />
                            </AntButton>
                          </div>
                        </div>
                      )}
                    >
                      {Boolean(this.state["WorkShops_WorkShopsTypeSelectItems"]) &&
                        this.state["WorkShops_WorkShopsTypeSelectItems"].map(i => (
                          <Option key={i.id} value={i.id}>
                            {i.name}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }

              </Form>
            }
          />

          {/* "New Station" from WorkShops*/}
          <DraggableModal
            title={<FormattedMessage id="WorkShops.NewStation" defaultMessage="New Station" />}
            visible={this.state.showWorkShopsStationFromWorkShopsModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showWorkShopsStationFromWorkShopsModal: false })}
            onOk={() => this.formRefWorkShopsStationFromWorkShops.current.submit()}
            width={800}
            centered
            content={
              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveWorkShopsStationFromWorkShops}
                onFinishFailed={onFinishFailed}
                ref={this.formRefWorkShopsStationFromWorkShops}
              >
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="WorkShops.Code" defaultMessage="Code" />}
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="WorkShops.WorkShopsName" defaultMessage="Definition" />}
                    name="definition"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item {...layout} label={<FormattedMessage id="WorkShops.Dimensions" defaultMessage="Dimensions" />}>
                    <Row gutter={6}>
                      <Col span={6}>
                        {/* LENGTH */}
                        <Form.Item
                          name="length"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Input placeholder="length" style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={1}></Col>
                      <Col span={6}>
                        {/* WIDTH */}
                        <Form.Item
                          name="width"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Input placeholder="width" style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={1}></Col>
                      <Col span={6}>
                        {/* HEIGHT */}
                        <Form.Item
                          name="height"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Input placeholder="height" style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                }
                {/* COORDINATES */}
                {
                  <Form.Item {...layout} label={<FormattedMessage id="ToDo.File" defaultMessage="Coordinates" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => this.setState({ showWorkShopsStationCoordinatesModal: true })}
                      icon={<FcSettings />}
                    ></AntButton>
                  </Form.Item>
                }
                {/* WorkShops Type */}
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="WorkShops.WorkShopsType" defaultMessage="WorkShops Type" />}
                    name="workShopsTypeId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="WorkShops Type"
                      optionFilterProp="children"
                      dropdownRender={menu => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              padding: 8
                            }}
                          >
                            <Input
                              style={{ flex: "auto" }}
                              value={this.state.codeName}
                              onChange={e => {
                                this.setState({
                                  codeName: e.target.value
                                });
                              }}
                            />
                            <AntButton type="link" icon={<PlusOutlined />} onClick={() => this.addCode("WorkShops_WorkShopsType")}>
                              <FormattedMessage id="General.AddNew" defaultMessage="Add New" />
                            </AntButton>
                          </div>
                        </div>
                      )}
                    >
                      {Boolean(this.state["WorkShops_WorkShopsTypeSelectItems"]) &&
                        this.state["WorkShops_WorkShopsTypeSelectItems"].map(i => (
                          <Option key={i.id} value={i.id}>
                            {i.name}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }

              </Form>
            }
          />
          {/* NEW SUB  AREA FROM SUBSTOCK*/}
          <DraggableModal
            title={<FormattedMessage id="WorkShops.Station" defaultMessage="Station" />}
            visible={this.state.showWorkShopsStationFromSubWorkShopsModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showWorkShopsStationFromSubWorkShopsModal: false })}
            onOk={() => this.formRefWorkShopsStationFromSubWorkShops.current.submit()}
            width={800}
            centered
            content={

              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveWorkShopsStationFromSubWorkShops}
                onFinishFailed={onFinishFailed}
                ref={this.formRefWorkShopsStationFromSubWorkShops}
              >
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="WorkShops.WorkShopsName" defaultMessage="Definition" />}
                    name="definition"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item {...layout} label={<FormattedMessage id="WorkShops.Dimensions" defaultMessage="Dimensions" />}>
                    <Row gutter={5}>
                      <Col span={5}>
                        {/* LENGTH */}
                        <Form.Item
                          name="length"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Input placeholder="length" style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={5}>
                        {/* WIDTH */}
                        <Form.Item
                          name="width"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Input placeholder="width" style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={5}>
                        {/* HEIGHT */}
                        <Form.Item
                          name="height"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Input placeholder="height" style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                }
                {/* COORDINATES */}
                {
                  <Form.Item {...layout} label={<FormattedMessage id="ToDo.File" defaultMessage="Coordinates" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => this.setState({ showWorkShopsStationCoordinatesModal: true })}
                      icon={<FcSettings />}
                    ></AntButton>
                  </Form.Item>
                }
                {/* WorkShops Type */}
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="WorkShops.WorkShopsType" defaultMessage="WorkShops Type" />}
                    name="workShopsTypeId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="WorkShops Type"
                      optionFilterProp="children"
                      dropdownRender={menu => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              padding: 8
                            }}
                          >
                            <Input
                              style={{ flex: "auto" }}
                              value={this.state.codeName}
                              onChange={e => {
                                this.setState({
                                  codeName: e.target.value
                                });
                              }}
                            />
                            <AntButton type="link" icon={<PlusOutlined />} onClick={() => this.addCode("WorkShops_WorkShopsType")}>
                              <FormattedMessage id="General.AddNew" defaultMessage="Add New" />
                            </AntButton>
                          </div>
                        </div>
                      )}
                    >
                      {Boolean(this.state["WorkShops_WorkShopsTypeSelectItems"]) &&
                        this.state["WorkShops_WorkShopsTypeSelectItems"].map(i => (
                          <Option key={i.id} value={i.id}>
                            {i.name}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>}

              </Form>
            }
          />
          {/* NEW SUB  AREA FROM SUBAREA*/}
          <DraggableModal
            title={<FormattedMessage id="WorkShops.NewStation" defaultMessage="New Station" />}
            open={this.state.showWorkShopsStationFromStationModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showWorkShopsStationFromStationModal: false })}
            onOk={() => this.formRefWorkShopsStationFromStation.current.submit()}
            width={800}
            centered
            content={

              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveWorkShopsStationFromStation}
                onFinishFailed={onFinishFailed}
                ref={this.formRefWorkShopsStationFromStation}
              >
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="WorkShops.WorkShopsName" defaultMessage="Definition" />}
                    name="definition"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item {...layout} label={<FormattedMessage id="WorkShops.Dimensions" defaultMessage="Dimensions" />}>
                    <Row gutter={5}>
                      <Col span={5}>
                        {/* LENGTH */}
                        <Form.Item
                          name="length"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Input placeholder="length" style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={5}>
                        {/* WIDTH */}
                        <Form.Item
                          name="width"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Input placeholder="width" style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={5}>
                        {/* HEIGHT */}
                        <Form.Item
                          name="height"
                          rules={[
                            {
                              required: false,
                              message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                            }
                          ]}
                        >
                          <Input placeholder="height" style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                }
                {/* COORDINATES */}
                {
                  <Form.Item {...layout} label={<FormattedMessage id="ToDo.File" defaultMessage="Coordinates" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => this.setState({ showWorkShopsStationCoordinatesModal: true })}
                      icon={<FcSettings />}
                    ></AntButton>
                  </Form.Item>
                }
                {/* WorkShops Type */}
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="WorkShops.WorkShopsType" defaultMessage="WorkShops Type" />}
                    name="workShopsTypeId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="WorkShops Type"
                      optionFilterProp="children"
                      dropdownRender={menu => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              padding: 8
                            }}
                          >
                            <Input
                              style={{ flex: "auto" }}
                              value={this.state.codeName}
                              onChange={e => {
                                this.setState({
                                  codeName: e.target.value
                                });
                              }}
                            />
                            <AntButton type="link" icon={<PlusOutlined />} onClick={() => this.addCode("WorkShops_WorkShopsType")}>
                              <FormattedMessage id="General.AddNew" defaultMessage="Add New" />
                            </AntButton>
                          </div>
                        </div>
                      )}
                    >
                      {Boolean(this.state["WorkShops_WorkShopsTypeSelectItems"]) &&
                        this.state["WorkShops_WorkShopsTypeSelectItems"].map(i => (
                          <Option key={i.id} value={i.id}>
                            {i.name}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                }

              </Form>
            }
          />
          {/* NEW WorkShops COORDINATES MODAL */}
          <DraggableModal
            title={<FormattedMessage id="WorkShops.Coordinates" defaultMessage="Coordinates" />}
            keyboard={false}
            maskClosable={false}
            open={this.state.showWorkShopsWorkShopsCoordinatesModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showWorkShopsWorkShopsCoordinatesModal: false })}
            onOk={() => this.formRefWorkShopsWorkShopsCoordinates.current.submit()}
            width={800}
            centered
            content={
              <Form initialValues={{ remember: false }} onFinish={this.saveWorkShopsWorkShopsCoordinates} red={this.formRefWorkShopsWorkShopsCoordinates}>
                <br></br>
                <br></br>
                <br />{" "}
                <h4>
                  <FormattedMessage id={"WorkShops.Coordinates"} defaultMessage={"  "} />
                </h4>
                <Form.List name="coordinates">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              {/* POINT */}
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"WorkShops.Point"} defaultMessage={"Point"} />
                              </th>
                              {/* N */}
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"WorkShops.N"} defaultMessage={"N"} />
                              </th>
                              {/* E */}
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"WorkShops.E"} defaultMessage={"E"} />
                              </th>
                              {/* EL */}
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"WorkShops.EL"} defaultMessage={"El"} />
                              </th>
                              {/* CONNECTION TYPE */}
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"WorkShops.ConnectionType"} defaultMessage={"Connection Type"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  {/* POINT QUANTITY */}
                                  <td>
                                    <Form.Item
                                      style={{ marginBottom: "5px" }}
                                      name="pointQuantity"
                                      rules={[
                                        { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{
                                          width: "100%"
                                        }}
                                        min={1}
                                        max={this.state.availableQuantity}
                                      />
                                    </Form.Item>
                                  </td>
                                  {/* N QUANTITY */}
                                  <td>
                                    <Form.Item
                                      style={{ marginBottom: "5px" }}
                                      name="nQuantity"
                                      rules={[
                                        { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{
                                          width: "100%"
                                        }}
                                        min={1}
                                        max={this.state.availableQuantity}
                                      />
                                    </Form.Item>
                                  </td>
                                  {/* E QUANTITY */}
                                  <td>
                                    <Form.Item
                                      style={{ marginBottom: "5px" }}
                                      name="eQuantity"
                                      rules={[
                                        { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{
                                          width: "100%"
                                        }}
                                        min={1}
                                        max={this.state.availableQuantity}
                                      />
                                    </Form.Item>
                                  </td>
                                  {/* EL QUANTITY */}
                                  <td>
                                    <Form.Item
                                      style={{ marginBottom: "5px" }}
                                      name="elQuantity"
                                      rules={[
                                        { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{
                                          width: "100%"
                                        }}
                                        min={1}
                                        max={this.state.availableQuantity}
                                      />
                                    </Form.Item>
                                  </td>
                                  {/* CONNECTION TYPE */}
                                  <td>
                                    <Form.Item name="connectionTypeId">
                                      <Select
                                        allowClear
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder=""
                                        onChange={value => {
                                          this.setState({ answerType: value });
                                        }}
                                      >
                                        {Boolean(this.state["connectionTypeIdSelectItems"]) &&
                                          this.state["connectionTypeIdSelectItems"].length > 0 &&
                                          this.state["connectionTypeIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  {/* MINUSCIRCLEOUTLINED */}
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"WorkShops.AddNewPersonnel"} defaultMessage={"Add New Coordinate "} />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              </Form>
            }
          />
          {/* SUB WorkShops COORDINATES MODAL */}
          <DraggableModal
            title={<FormattedMessage id="WorkShops.Coordinates" defaultMessage="Coordinates" />}
            keyboard={false}
            maskClosable={false}
            open={this.state.showWorkShopsSubWorkShopsCoordinatesModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showWorkShopsSubWorkShopsCoordinatesModal: false })}
            onOk={() => this.formRefWorkShopsSubWorkShopsCoordinates.current.submit()}
            width={800}
            centered
            content={

              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveWorkShopsSubWorkShopsCoordinates}
                red={this.formRefWorkShopsSubWorkShopsCoordinates}
              >
                <br></br>
                <br></br>
                <br />{" "}
                <h4>
                  <FormattedMessage id={"WorkShops.Coordinates"} defaultMessage={"  "} />
                </h4>
                <Form.List name="coordinates">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              {/* POINT */}
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"WorkShops.Point"} defaultMessage={"Point"} />
                              </th>
                              {/* N */}
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"WorkShops.N"} defaultMessage={"N"} />
                              </th>
                              {/* E */}
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"WorkShops.E"} defaultMessage={"E"} />
                              </th>
                              {/* EL */}
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"WorkShops.EL"} defaultMessage={"El"} />
                              </th>
                              {/* CONNECTION TYPE */}
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"WorkShops.ConnectionType"} defaultMessage={"Connection Type"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  {/* POINT QUANTITY */}
                                  <td>
                                    <Form.Item
                                      style={{ marginBottom: "5px" }}
                                      name="pointQuantity"
                                      rules={[
                                        { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{
                                          width: "100%"
                                        }}
                                        min={1}
                                        max={this.state.availableQuantity}
                                      />
                                    </Form.Item>
                                  </td>
                                  {/* N QUANTITY */}
                                  <td>
                                    <Form.Item
                                      style={{ marginBottom: "5px" }}
                                      name="nQuantity"
                                      rules={[
                                        { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{
                                          width: "100%"
                                        }}
                                        min={1}
                                        max={this.state.availableQuantity}
                                      />
                                    </Form.Item>
                                  </td>
                                  {/* E QUANTITY */}
                                  <td>
                                    <Form.Item
                                      style={{ marginBottom: "5px" }}
                                      name="eQuantity"
                                      rules={[
                                        { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{
                                          width: "100%"
                                        }}
                                        min={1}
                                        max={this.state.availableQuantity}
                                      />
                                    </Form.Item>
                                  </td>
                                  {/* EL QUANTITY */}
                                  <td>
                                    <Form.Item
                                      style={{ marginBottom: "5px" }}
                                      name="elQuantity"
                                      rules={[
                                        { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{
                                          width: "100%"
                                        }}
                                        min={1}
                                        max={this.state.availableQuantity}
                                      />
                                    </Form.Item>
                                  </td>
                                  {/* CONNECTION TYPE */}
                                  <td>
                                    <Form.Item name="connectionTypeId">
                                      <Select
                                        allowClear
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder=""
                                        onChange={value => {
                                          this.setState({ answerType: value });
                                        }}
                                      >
                                        {Boolean(this.state["connectionTypeIdSelectItems"]) &&
                                          this.state["connectionTypeIdSelectItems"].length > 0 &&
                                          this.state["connectionTypeIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  {/* MINUSCIRCLEOUTLINED */}
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"WorkShops.AddNewPersonnel"} defaultMessage={"Add New Coordinate "} />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              </Form>
            }
          />
          {/* SUB AREA COORDINATES MODAL */}
          <DraggableModal
            title={<FormattedMessage id="WorkShops.Coordinates" defaultMessage="Coordinates" />}
            keyboard={false}
            maskClosable={false}
            open={this.state.showWorkShopsStationCoordinatesModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showWorkShopsStationCoordinatesModal: false })}
            onOk={() => this.formRefStationCoordinates.current.submit()}
            width={800}
            centered
            content={
              <Form initialValues={{ remember: false }} onFinish={this.saveWorkShopsStationCoordinates} red={this.formRefStationCoordinates}>
                <br></br>
                <br></br>
                <br />{" "}
                <h4>
                  <FormattedMessage id={"WorkShops.Coordinates"} defaultMessage={"  "} />
                </h4>
                <Form.List name="coordinates">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              {/* POINT */}
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"WorkShops.Point"} defaultMessage={"Point"} />
                              </th>
                              {/* N */}
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"WorkShops.N"} defaultMessage={"N"} />
                              </th>
                              {/* E */}
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"WorkShops.E"} defaultMessage={"E"} />
                              </th>
                              {/* EL */}
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"WorkShops.EL"} defaultMessage={"El"} />
                              </th>
                              {/* CONNECTION TYPE */}
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"WorkShops.ConnectionType"} defaultMessage={"Connection Type"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  {/* POINT QUANTITY */}
                                  <td>
                                    <Form.Item
                                      style={{ marginBottom: "5px" }}
                                      name="pointQuantity"
                                      rules={[
                                        { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{
                                          width: "100%"
                                        }}
                                        min={1}
                                        max={this.state.availableQuantity}
                                      />
                                    </Form.Item>
                                  </td>
                                  {/* N QUANTITY */}
                                  <td>
                                    <Form.Item
                                      style={{ marginBottom: "5px" }}
                                      name="nQuantity"
                                      rules={[
                                        { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{
                                          width: "100%"
                                        }}
                                        min={1}
                                        max={this.state.availableQuantity}
                                      />
                                    </Form.Item>
                                  </td>
                                  {/* E QUANTITY */}
                                  <td>
                                    <Form.Item
                                      style={{ marginBottom: "5px" }}
                                      name="eQuantity"
                                      rules={[
                                        { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{
                                          width: "100%"
                                        }}
                                        min={1}
                                        max={this.state.availableQuantity}
                                      />
                                    </Form.Item>
                                  </td>
                                  {/* EL QUANTITY */}
                                  <td>
                                    <Form.Item
                                      style={{ marginBottom: "5px" }}
                                      name="elQuantity"
                                      rules={[
                                        { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                      ]}
                                    >
                                      <InputNumber
                                        style={{
                                          width: "100%"
                                        }}
                                        min={1}
                                        max={this.state.availableQuantity}
                                      />
                                    </Form.Item>
                                  </td>
                                  {/* CONNECTION TYPE */}
                                  <td>
                                    <Form.Item name="connectionTypeId">
                                      <Select
                                        allowClear
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder=""
                                        onChange={value => {
                                          this.setState({ answerType: value });
                                        }}
                                      >
                                        {Boolean(this.state["connectionTypeIdSelectItems"]) &&
                                          this.state["connectionTypeIdSelectItems"].length > 0 &&
                                          this.state["connectionTypeIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  {/* MINUSCIRCLEOUTLINED */}
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"WorkShops.AddNewPersonnel"} defaultMessage={"Add New Coordinate "} />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              </Form>
            }
          />
          {/* Work Description */}
          <DraggableModal
            title={<FormattedMessage id="WorkShops.WorkDescription" defaultMessage="Work Description" />}
            open={this.state.showWorkDescriptionModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showWorkDescriptionModal: false })}
            onOk={() => this.formRefWorkShopsWorkDescription.current.submit()}
            width={1200}
            centered
            content={
              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveWorkDescription}
                onFinishFailed={onFinishFailed}
                ref={this.formRefWorkShopsWorkDescription}
              >
                <div>
                  <Row gutter={16} style={{ marginBottom: "16px" }}>
                    {/* Sol Card */}
                    <Col span={1}> </Col>
                    <Col span={11}>
                      <Card
                        style={{
                          borderRadius: "8px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          width: "100%",
                          height: "100%"
                        }}
                      >
                        {
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="MaintenanceWorkStation.control" defaultMessage="Control" />}
                            name="workStationActivitys"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <Select allowClear
                              style={{ width: "100%" }}
                              showSearch
                              optionFilterProp="children"
                              mode="multiple"
                            >
                              {Boolean(this.state["activitySelectItems"]) && this.state["activitySelectItems"].length > 0 &&
                                this.state["activitySelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}{" "}
                            </Select>
                          </Form.Item>
                        }
                        {
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="WorkShops.Equipment" defaultMessage="Equipment" />}
                            name="equipment"
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                          >
                            <Input style={{ width: "100%" }} />
                          </Form.Item>
                        }
                        {
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="WorkShops.Tools" defaultMessage="Tools" />}
                            name="tools"
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                          >
                            <Input style={{ width: "100%" }} />
                          </Form.Item>
                        }
                        {
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="WorkShops.MeasuringDevices" defaultMessage="Measuring Devices" />}
                            name="measuringDevices"
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                          >
                            <Input style={{ width: "100%" }} />
                          </Form.Item>
                        }
                        {
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="WorkShops.Personnel" defaultMessage="Personnel" />}
                            name="personnel"
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                          >
                            <Input style={{ width: "100%" }} />
                          </Form.Item>
                        }
                        
                      </Card>
                    </Col>

                    {/* Sağ Card */}
                    <Col span={11}>
                      <Card
                        style={{
                          borderRadius: "8px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          width: "100%",
                          height: "100%"
                        }}
                      >
                        {
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="MaintenanceWorkStation.equipment" defaultMessage="Equipment" />}
                            name="workStationActivitys"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <Select allowClear
                              style={{ width: "100%" }}
                              showSearch
                              optionFilterProp="children"
                              mode="multiple"
                            >
                              {Boolean(this.state["activitySelectItems"]) && this.state["activitySelectItems"].length > 0 &&
                                this.state["activitySelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}{" "}
                            </Select>
                          </Form.Item>
                        }
                        {
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="MaintenanceWorkStation.tool" defaultMessage="Tool" />}
                            name="workStationActivitys"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <Select allowClear
                              style={{ width: "100%" }}
                              showSearch
                              optionFilterProp="children"
                              mode="multiple"
                            >
                              {Boolean(this.state["activitySelectItems"]) && this.state["activitySelectItems"].length > 0 &&
                                this.state["activitySelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}{" "}
                            </Select>
                          </Form.Item>
                        }
                        {
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="MaintenanceWorkStation.measuringDevice" defaultMessage="Measuring Device" />}
                            name="workStationActivitys"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <Select allowClear
                              style={{ width: "100%" }}
                              showSearch
                              optionFilterProp="children"
                              mode="multiple"
                            >
                              {Boolean(this.state["activitySelectItems"]) && this.state["activitySelectItems"].length > 0 &&
                                this.state["activitySelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}{" "}
                            </Select>
                          </Form.Item>
                        }
                        {
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="MaintenanceWorkStation.personnel" defaultMessage="Personnel" />}
                            name="workStationActivitys"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <Select allowClear
                              style={{ width: "100%" }}
                              showSearch
                              optionFilterProp="children"
                              mode="multiple"
                            >
                              {Boolean(this.state["activitySelectItems"]) && this.state["activitySelectItems"].length > 0 &&
                                this.state["activitySelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}{" "}
                            </Select>
                          </Form.Item>
                        }
                        {
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="MaintenanceWorkStation.supporting" defaultMessage="Supporting" />}
                            name="workStationActivitys"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <Select allowClear
                              style={{ width: "100%" }}
                              showSearch
                              optionFilterProp="children"
                              mode="multiple"
                            >
                              {Boolean(this.state["activitySelectItems"]) && this.state["activitySelectItems"].length > 0 &&
                                this.state["activitySelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}{" "}
                            </Select>
                          </Form.Item>
                        }                              

                      </Card>
                    </Col>
                  </Row>
                </div>
              </Form>
            }
          />
          {/* DeleteModal */}
          {/* DELETE WorkShops */}
          <AntModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            open={this.state.showDeleteWorkShopsWorkShopsModal}
            onCancel={() => {
              this.setState({ showDeleteWorkShopsWorkShopsModal: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ showDeleteWorkShopsWorkShopsModal: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.deleteWorkShopsWorkShops}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Space>
            ]}
          >
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </AntModal>
          {/* DELETE SUBSTOCK */}
          <AntModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            open={this.state.showDeleteWorkShopsSubWorkShopsModal}
            onCancel={() => {
              this.setState({ showDeleteWorkShopsSubWorkShopsModal: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ showDeleteWorkShopsSubWorkShopsModal: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.deleteWorkShopsSubWorkShops}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Space>
            ]}
          >
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </AntModal>
          {/* DELETE SUBAREA */}
          <AntModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            open={this.state.showDeleteWorkShopsStationModal}
            onCancel={() => {
              this.setState({ showDeleteWorkShopsStationModal: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ showDeleteWorkShopsStationModal: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.deleteWorkShopsStation}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Space>
            ]}
          >
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </AntModal>
        </div>
      </Badge.Ribbon >
    );
  }
}
const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WorkShops);
