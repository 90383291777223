import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Form, Table, Input, Select, DatePicker, Button as AntButton, Col, Row, PageHeader, Upload } from "antd";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import { UploadOutlined, DownloadOutlined, FilterOutlined } from "@ant-design/icons";
import { Resizable } from "react-resizable";
import "../../assets/style/resizeTable.css";

const { RangePicker } = DatePicker;
const { Option } = Select;

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class InventoryMaterialClient extends Component {
  constructor({ inventoryId, inventoryName }) {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      inventoryId: inventoryId,
      inventoryName: inventoryName,
      id: null,
      tableList: [],
      fileListManufacturerManuel: [],
      fileListSpecification: [],
      nodes: [], // for cascader.
      supplierIdSelectItems: null,
      manufacturerIdSelectItems: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initPage();
  };
  initPage = async params => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };
  componentDidUpdate = async nextProps => {
    let id = Boolean(this.props) && Boolean(this.props.inventoryId) ? this.props.inventoryId : null;
    if (id !== this.state.inventoryId) {
      this.setState({ inventoryId: id, inventoryName: this.props.inventoryName }, () => {
        this.initPage();
      });
    }
  };

  fillComboboxes = async () => {
    var responsesupplierId = await handleRequest("GET", "/api/vendor/suppliers");
    if (responsesupplierId.type === "ERROR") {
      error(responsesupplierId);
    } else {
      this.setState({
        supplierIdSelectItems: Boolean(responsesupplierId.data) ? responsesupplierId.data : []
      });
    }
    var responsemanufacturerId = await handleRequest("GET", "/api/vendor/manufacturers");
    if (responsemanufacturerId.type === "ERROR") {
      error(responsemanufacturerId);
    } else {
      this.setState({
        manufacturerIdSelectItems: Boolean(responsemanufacturerId.data) ? responsemanufacturerId.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };
  setSelectedFiles = field => {
    let fileList = [];
    if (Boolean(field) && field.length > 0) {
      let files = field.split(",");
      for (let i = 0; i < files.length; i++) {
        fileList.push({
          uid: i,
          name: files[i],
          url: files[i],
          thumbUrl: files[i]
        });
      }
      return fileList;
    }
    return [];
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileListManufacturerManuel = this.setSelectedFiles(row.manufacturerManuelPath);
    let fileListSpecification = this.setSelectedFiles(row.specificationPath);

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      fileListManufacturerManuel: fileListManufacturerManuel,
      fileListSpecification: fileListSpecification
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/inventoryMaterialClient/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/inventoryMaterialClient/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  getSelectedFiles = field => {
    if (Boolean(field) && field.length > 0) {
      let files = "";
      files = Boolean(field[0].url) ? field[0].url : field[0].response.url;
      for (let i = 1; i < field.length; i++) {
        if (Boolean(field[i].response)) {
          files += "," + field[i].response.url;
        } else if (Boolean(field[i].url)) {
          files += "," + field[i].url;
        }
      }
      return files;
    }
    return field;
  };
  save = async values => {
    const newItem = {
      ...values,
      inventoryId: this.state.inventoryId,
      id: this.state.id
    };
    newItem.manufacturerManuelPath = this.getSelectedFiles(this.state.fileListManufacturerManuel);
    newItem.specificationPath = this.getSelectedFiles(this.state.fileListSpecification);
    console.log(newItem);
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/inventoryMaterialClient", newItem);
    } else {
      response = await handleRequest("PUT", "/api/inventoryMaterialClient/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      inventoryId: this.state.inventoryId,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/inventoryMaterialClient/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="InventoryMaterialClient.supplierId" defaultMessage="Supplier" />,
        key: "supplierId",
        width: 100,
        render: record => {
          return Boolean(record.supplier) ? record.supplier.name : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.supplierId) ? this.state.pagination.supplierId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  supplierId: e.target.value,
                  supplierIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.supplierIdColor) ? this.state.pagination.supplierIdColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryMaterialClient.manufacturerId" defaultMessage="Manufacturer" />,
        key: "manufacturerId",
        width: 100,
        render: record => {
          return Boolean(record.manufacturer) ? record.manufacturer.name : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.manufacturerId) ? this.state.pagination.manufacturerId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  manufacturerId: e.target.value,
                  manufacturerIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.manufacturerIdColor) ? this.state.pagination.manufacturerIdColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryMaterialClient.materialCode" defaultMessage="Material Code" />,
        key: "materialCode",
        width: 100,
        render: record => {
          return record.materialCode;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.materialCode) ? this.state.pagination.materialCode : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  materialCode: e.target.value,
                  materialCodeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.materialCodeColor) ? this.state.pagination.materialCodeColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryMaterialClient.materialDescription" defaultMessage="Material Description" />,
        key: "materialDescription",
        width: 100,
        render: record => {
          return record.materialDescription;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.materialDescription) ? this.state.pagination.materialDescription : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  materialDescription: e.target.value,
                  materialDescriptionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.materialDescriptionColor) ? this.state.pagination.materialDescriptionColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryMaterialClient.manufacturerManuelPath" defaultMessage="Manufacturer Manual" />,
        key: "manufacturerManuelPath",
        width: 100,
        render: record => {
          let path = record.manufacturerManuelPath;
          if (Boolean(path))
            return (
              <AntButton
                style={{
                  marginLeft: "2px",
                  marginTop: "-2px",
                  alignContent: "center"
                }}
                href={path}
                target="_blank"
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
              ></AntButton>
            );
          else return "";
        }
      },
      {
        title: <FormattedMessage id="InventoryMaterialClient.barcode" defaultMessage="Barcode" />,
        key: "barcode",
        width: 100,
        render: record => {
          return record.barcode;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.barcode) ? this.state.pagination.barcode : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  barcode: e.target.value,
                  barcodeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.barcodeColor) ? this.state.pagination.barcodeColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryMaterialClient.qrCode" defaultMessage="QrCode" />,
        key: "qrCode",
        width: 100,
        render: record => {
          return record.qrCode;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.qrCode) ? this.state.pagination.qrCode : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  qrCode: e.target.value,
                  qrCodeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.qrCodeColor) ? this.state.pagination.qrCodeColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryMaterialClient.specificationPath" defaultMessage="Specification" />,
        key: "specificationPath",
        width: 100,
        render: record => {
          let path = record.specificationPath;
          if (Boolean(path))
            return (
              <AntButton
                style={{
                  marginLeft: "2px",
                  marginTop: "-2px",
                  alignContent: "center"
                }}
                href={path}
                target="_blank"
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
              ></AntButton>
            );
          else return "";
        }
      },
      {
        title: <FormattedMessage id="InventoryMaterialClient.remarks" defaultMessage="Remarks" />,
        key: "remarks",
        width: 100,
        render: record => {
          return record.remarks;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.remarks) ? this.state.pagination.remarks : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  remarks: e.target.value,
                  remarksColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.remarksColor) ? this.state.pagination.remarksColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },

      {
        title: "Last Update",
        key: "lastUpdated",
        width: 100,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];

      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: handleResize(index)
            })
          }))
        : Boolean(columns) &&
          columns.length > 0 &&
          columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: handleResize(index)
            })
          }));

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
              exportClick: false
            })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    const uploadProps = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <PageHeader className="site-page-header" title={"Inventory:" + this.state.inventoryName} />
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={21}></Col>
            <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InventoryMaterialClient.supplierId" defaultMessage="Supplier" />}
                name="supplierId"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select style={{ width: "100%" }} placeholder="Supplier">
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["supplierIdSelectItems"]) &&
                    this.state["supplierIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InventoryMaterialClient.manufacturerId" defaultMessage="Manufacturer" />}
                name="manufacturerId"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select style={{ width: "100%" }} placeholder="Manufacturer">
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["manufacturerIdSelectItems"]) &&
                    this.state["manufacturerIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InventoryMaterialClient.materialCode" defaultMessage="Material Code" />}
                name="materialCode"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input placeholder="Material Code" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InventoryMaterialClient.materialDescription" defaultMessage="Material Description" />}
                name="materialDescription"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input placeholder="Material Description" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InventoryMaterialClient.manufacturerManuelPath" defaultMessage="Manufacturer Manual" />}
                name="manufacturerManuelPath"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Upload
                  {...uploadProps}
                  showUploadList={true}
                  fileList={this.state.fileListManufacturerManuel}
                  onChange={({ fileList }) => {
                    if (fileList.length === 1) {
                      if (Boolean(fileList[0].response)) {
                        fileList[0].url = fileList[0].response.url;
                        showSuccess("file uploaded successfully");
                        // fileName: info.file.name,
                      }
                      this.setState({
                        fileListManufacturerManuel: fileList
                      });
                    }
                  }}
                  beforeUpload={fileList => {
                    if (this.state.fileListManufacturerManuel.length > 0) {
                      showError("You have been already choose a file! For change firstly remove the uploaded file.");
                      return false;
                    }
                    return true;
                  }}
                  onRemove={({ fileList }) => {
                    this.setState({
                      fileListManufacturerManuel: []
                    });
                  }}
                >
                  <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> Upload
                  </AntButton>
                </Upload>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InventoryMaterialClient.barcode" defaultMessage="Barcode" />}
                name="barcode"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input placeholder="Barcode" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InventoryMaterialClient.qrCode" defaultMessage="QrCode" />}
                name="qrCode"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input placeholder="QrCode" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InventoryMaterialClient.specificationPath" defaultMessage="Specification" />}
                name="specificationPath"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Upload
                  {...uploadProps}
                  showUploadList={true}
                  fileList={this.state.fileListSpecification}
                  onChange={({ fileList }) => {
                    if (fileList.length === 1) {
                      if (Boolean(fileList[0].response)) {
                        fileList[0].url = fileList[0].response.url;
                        showSuccess("file uploaded successfully");
                        // fileName: info.file.name,
                      }
                      this.setState({
                        fileListSpecification: fileList
                      });
                    }
                  }}
                  beforeUpload={fileList => {
                    if (this.state.fileListSpecification.length > 0) {
                      showError("You have been already choose a file! For change firstly remove the uploaded file.");
                      return false;
                    }
                    return true;
                  }}
                  onRemove={({ fileList }) => {
                    this.setState({
                      fileListSpecification: []
                    });
                  }}
                >
                  <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> Upload
                  </AntButton>
                </Upload>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InventoryMaterialClient.remarks" defaultMessage="Remarks" />}
                name="remarks"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input placeholder="Remarks" style={{ width: "100%" }} />
              </Form.Item>
            }
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            rowKey="id"
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            components={{
              header: {
                cell: ResizableTitle
              }
            }}
            columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryMaterialClient);
