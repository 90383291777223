import React, { useState, useEffect } from "react";
import { handleRequest } from "../../../ApiConnector";
import { error } from "../../../MessageHelper";
import { Card, Col, Progress, Row, Space, Tooltip, Button as AntButton } from "antd";
import moment from "moment";
import { Bar } from '@ant-design/plots';
import SettingsIcon from "@material-ui/icons/Settings";
import { FormattedMessage } from "react-intl";
import DraggableModal from "../../../Components/DraggableModal";
import TenderingOnGoingProjectSettings from "./TenderingOnGoingProjectSettings";

const TenderingOnGoingProject = () => {
  const [tableList, setTableList] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(6, "M"));
  const [endDate, setEndDate] = useState(moment());
  const [tenderingOnGoingProjectSettingsShow, setTenderingOnGoingProjectSettingsShow] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    showSizeChanger: true,
    pageSizeOptions: ["2", "5", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
    total: 0
  });

  useEffect(() => {
    asyncFetch();
  }, [startDate, endDate]);

  const asyncFetch = async () => {
    const search = {
      firstResult: pagination.current - 1,
      maxResults: pagination.pageSize,
    };
    console.log(search);
    var response = await handleRequest("POST", "/api/dashboard/biddingOngoingProjects", search);
    if (response.type === "ERROR") {
      error(response);
    } else {
      setTableList(Boolean(response.data) ? response.data : null);
    }
  };
  const restartTable = async (params = {}) => {
    const search = {
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
    };
    console.log(search);
    var response = await handleRequest("POST", "/api/dashboard/biddingOngoingProjects", search);
    if (response.type === "ERROR") {
      error(response);
    } else {
      setTableList(Boolean(response.data) ? response.data : null);
      setPagination({
        ...params.pagination,
        total: response.data.totalRecords
      },)
    }
  };
  const handleTableChange = (pagination, filters, sorter) => {
    restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  const BarConfig = {
    isStack: true,
    xField: 'key',
    yField: 'description',
    seriesField: 'value',
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
  };

  return (
    <>
      <Card
        className="dashboard-responsive-card"
      >
        <Row gutter={[16, 16]}>
          <Col md={22}></Col>
          <Col md={1}>
            <Tooltip title={<FormattedMessage id="TenderingOnGoingProject.Settings" defaultMessage="Settings" />}>
              <AntButton
                style={{ border: "0px" }}
                onClick={() => {
                  setTenderingOnGoingProjectSettingsShow(true);
                }}
                icon={<SettingsIcon color="error" fontSize="large" />}
              ></AntButton>
            </Tooltip>
          </Col>
          <Col md={1}></Col>
        </Row>
        <br />
        {Boolean(tableList) &&
          tableList.length > 0 &&
          tableList.map(i => (
            <Space>
              <Tooltip title={i.projectName}>
                {Boolean(i.projectName) && i.projectName.length > 15
                  ? i.projectName.substring(0, 15) + ".."
                  : i.projectName}
              </Tooltip>
              <Bar style={{ height: '100px', width: "380px" }} data={i.data}  {...BarConfig} />

              <Progress
                width={55}
                type="circle"
                strokeColor={'#C03C38'}
                percent={i.score}
              />
            </Space>
          ))}
      </Card>

      <DraggableModal
        title={<FormattedMessage id="TenderingOnGoingProject.Settings" defaultMessage="Settings" />}
        centered
        width={800}
        okButtonProps={{ hidden: true }}
        maskClosable={false}
        open={tenderingOnGoingProjectSettingsShow}
        onCancel={() => {
          setTenderingOnGoingProjectSettingsShow(false);
        }}
        content={<TenderingOnGoingProjectSettings random={Math.random()}
          close={() => {
            setTenderingOnGoingProjectSettingsShow(false);
            setStartDate(new Date());
            setEndDate(new Date());
          }} />}
      ></DraggableModal>
    </>
  );
};

export default TenderingOnGoingProject;