import React, { useEffect } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

function PresentationPage3(props) {
    const [loadUrl, setLoadUrl] = React.useState(null);

    useEffect(() => {
        setLoadUrl(props.apiUrl);

        // const getData = async () => {
        //   await fillData(props.apiUrl);
        // };
        // getData().catch(console.error);
    }, []);

    return (
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4">
                    <div class="row">
                        <div class="col-md-12">
                            <span className="headerText">Business Plan 2025</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div style={{
                                "font-family": "Public Sans Thin",
                                "font-size": "60px",
                                "margin-top": '100px'
                            }}>Our
                            </div>
                            <div style={{
                                "position": "relative",
                                "font-family": "Public Sans Thin",
                                "font-size": "60px",
                            }}>Business
                            </div>
                            <div style={{
                                "position": "relative",
                                "font-family": "Public Sans",
                                "font-size": "60px",
                                "font-weight": "bolder"
                            }}>Model
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <img src={toAbsoluteUrl("/media/presentation/middle1.png")} width="400px" height="100%" />
                </div>
                <div class="col-md-4">
                    <div class="row">
                        <div class="col-md-12">
                            <span className="headerText">Anissa May Co.</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <span className="headerText">Anissa May Co.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PresentationPage3;
