import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showSuccess, error } from "../../MessageHelper";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import {
  Form,
  Table,
  Input,
  Select,
  InputNumber,
  DatePicker,
  Button as AntButton,
  Col,
  Row,
  Tooltip,
  Modal as AntModal
} from "antd";
import { handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import { ChequeLogExpanded } from "./ChequeLogExpanded";
import { ChequeLogTo } from "./ChequeLogTo";
import { ChequeLogUpdate } from "./ChequeLogUpdate";

const { Option } = Select;

export class ChequeLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      controllerName: "chequeLog",
      controllerNameChequeOwner: "chequeOwner",
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      bankAccountsLogSelectList: [],
      chequeHolderSelectList: [],
      signatureAuthoritySelectList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();
  formAddCustomerRef = React.createRef();
  formEnterPaymentRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/bankAccounts");
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        bankAccountsLogSelectList: Boolean(response.data) ? response.data : []
      });
    }

    var responseCheSig = await handleRequest("GET", "/api/" + this.state.controllerName + "/chequeHolderSignatureAuthorities");
    if (responseCheSig.type === "ERROR") {
      error(responseCheSig);
    } else {
      this.setState({
        chequeHolderSelectList: Boolean(responseCheSig.data) ? responseCheSig.data : [],
        signatureAuthoritySelectList: Boolean(responseCheSig.data) ? responseCheSig.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = (row) => {
    document.getElementById("kt_scrolltop").click();
    var chequeHolder = [];
    if (Boolean(row.chequeHolderIds) && row.chequeHolderIds.length > 0) {
      chequeHolder = row.chequeHolderIds;
    }
    var signatureAuthority = [];
    if (Boolean(row.signatureAuthorityIds) && row.signatureAuthorityIds.length > 0) {
      signatureAuthority = row.signatureAuthorityIds;
    }

    this.formRef.current.setFieldsValue({
      ...row,
      chequeHolder: chequeHolder,
      signatureAuthority: signatureAuthority
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  typeModal = row => {
    this.setState({
      showChequeLogToModal: true,
      selectedChequeLog: row
    });
  };

  updateModal = row => {
    this.setState({
      showChequeLogUpdateModal: true,
      selectedChequeLog: row
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerNameChequeOwner + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };

    var chequeHolder = [];
    if (Boolean(values.chequeHolder) && values.chequeHolder.length > 0) {
      values.chequeHolder.forEach(element => {
        chequeHolder.push({
          ["personnelLogId"]: element,
        });
      });
    }
    newItem.chequeHolder = chequeHolder;

    var signatureAuthority = [];
    if (Boolean(values.signatureAuthority) && values.signatureAuthority.length > 0) {
      values.signatureAuthority.forEach(element => {
        signatureAuthority.push({
          ["personnelLogId"]: element,
        });
      });
    }
    newItem.signatureAuthority = signatureAuthority;

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu
          row={record}
          onDeleteRow={this.deleteModal.bind(this)}
          onEditRow={this.edit.bind(this)}
        />
      </React.Fragment>
    );
  };

  saveChequeLogTo = () => {
    this.setState({
      showChequeLogToModal: false,
    });
  };

  saveChequeLogUpdate = () => {
    this.setState({
      showChequeLogUpdateModal: false,
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: "Check Owner",
        key: "checkOwnerName",
        render: record => {
          return <Tooltip title={record.checkOwnerName}>{record.checkOwnerName}</Tooltip>;
        },
      },
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={23}></Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ChequeLog.bankAccountsLogId" defaultMessage="Bank Account" />}
                name="bankAccountsLogId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Bank Account"
                  optionFilterProp="children"
                >
                  {this.state.bankAccountsLogSelectList.map(i => (
                    <Option value={i.key}>{i.value}</Option>
                  ))}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ChequeLog.chequeBookNo" defaultMessage="Cheque book no" />}
                name="chequeBookNo"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="Cheque book no" style={{ width: "100%" }} />
              </Form.Item>
            }
            {
              this.state.hideUpdate && <Form.Item
                {...layout}
                label={<FormattedMessage id="ChequeLog.serialNo" defaultMessage="Serial no" />}
                style={{ marginBottom: "5px" }}
              >
                <Row gutter={8}>
                  <Col span={10}>
                    <Form.Item name="serialNoMin">
                      <InputNumber
                        style={{
                          width: "100%"
                        }}
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    To
                  </Col>
                  <Col span={10}>
                    <Form.Item name="serialNoMax">
                      <InputNumber
                        style={{
                          width: "100%"
                        }}
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            }
            {
              !this.state.hideUpdate &&
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ChequeLog.serialNo" defaultMessage="Serial no" />}
                name="serialNo"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber placeholder="Serial no" style={{ width: "100%" }} min={0} />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ChequeLog.chequeHolder" defaultMessage="Cheque Holder" />}
                name="chequeHolder"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  allowClear
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Cheque Holder"
                  optionFilterProp="children"
                >
                  {this.state.chequeHolderSelectList.map(i => (
                    <Option value={i.key}>{i.value}</Option>
                  ))}
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ChequeLog.signatureAuthority" defaultMessage="Signature Authority" />}
                name="signatureAuthority"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  allowClear
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Signature Authority"
                  optionFilterProp="children"
                >
                  {this.state.signatureAuthoritySelectList.map(i => (
                    <Option value={i.key}>{i.value}</Option>
                  ))}
                </Select>
              </Form.Item>
            }

            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            rowKey="id"
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
            expandable={{
              expandedRowRender: (record) => (
                <ChequeLogExpanded chequeOwnerId={record.id}
                  fromTo={Boolean(record.chequeOwnerCompanyId) ? "To" : Boolean(record.chequeOwnerCustomerId) ? "From" : ""}
                  edit={this.edit} deleteModal={this.deleteModal}
                  typeModal={this.typeModal} updateModal={this.updateModal} />
              ),
              rowExpandable: () => {
                return true;
              },
            }}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <AntModal
          centered
          width={1500}
          title="Cheque to"
          visible={this.state.showChequeLogToModal}
          onCancel={() => {
            this.setState({ showChequeLogToModal: false });
          }}
          footer={[
            <Button variant="secondary" onClick={() => {
              this.setState({
                showChequeLogToModal: false
              });
            }}>Close</Button>
          ]}
        >
          <ChequeLogTo random={Math.random()}
            chequeLog={this.state.selectedChequeLog}
            saveChequeLogTo={this.saveChequeLogTo}
          />
        </AntModal>

        <AntModal
          centered
          width={1500}
          title="Cheque Update"
          visible={this.state.showChequeLogUpdateModal}
          onCancel={() => {
            this.setState({ showChequeLogUpdateModal: false });
          }}
          footer={[
            <Button variant="secondary" onClick={() => {
              this.setState({
                showChequeLogUpdateModal: false
              });
            }}>Close</Button>
          ]}
        >
          <ChequeLogUpdate random={Math.random()}
            fromTo={Boolean(this.state.selectedChequeLog) ?
              (Boolean(this.state.selectedChequeLog.chequeOwnerCompanyId) ? "To" : "From") : ""}
            chequeLog={this.state.selectedChequeLog}
            saveChequeLogUpdate={this.saveChequeLogUpdate}
          />
        </AntModal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChequeLog);