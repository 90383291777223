import { EditOutlined } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import {
  PlusOutlined,
  UploadOutlined,
  CloseOutlined,
  MinusCircleOutlined,
  RollbackOutlined,
  CheckOutlined,
  ArrowRightOutlined,
  LinkOutlined
} from "@ant-design/icons";
import { Button as AntButton, Radio, Col, Input, InputNumber, Form, Row, Select, Divider, Collapse, Upload, Switch, Checkbox } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest, API_BASE_ROOT } from "../../ApiConnector";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { ExcelReader } from "../excel/ExcelReader";
import { Button, Modal, Col as ColBoot, Row as RowBoot, Form as FormBoot } from "react-bootstrap";
import RadioGroup from "antd/lib/radio/group";

const { Option } = Select;
const { Panel } = Collapse;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (Boolean(node.data)) {
      var lineage = [];
      if (Boolean(node.data.lineage) && node.data.lineage.length > 0) {
        node.data.lineage.forEach(element => {
          if (!lineage.includes(element)) {
            lineage.push(element);
          }
        });
      }

      dataList.push({
        key: node.data.id,
        lineage: lineage,
        title: node.data.menuTitle,
        page: node.data.page
      });
    }
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class Features extends Component {
  constructor() {
    super();
    this.state = {
      formName: "Features",
      controllerName: "gapAnalysis",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      dynamicMenuCascader: [],
      answerTypeSelectItems: [],
      id: null,
      tableList: [],
      excelData: [],
      createWhereParams: null,
      refreshForm: false,
      filePath: "",
      fileList: [],
      rows: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });

    this.fillComboboxes();
  };

  componentDidUpdate = async nextProps => {};

  fillComboboxes = async () => {
    this.getAnswerType();
    this.fillCode("GapAnalysisTitle");
    this.fillCode("GapAnalysisParentTitle");
    this.fillCode("GapAnalysisOptions");
    this.fillCode("GapAnalysisColumns");
  };

  getAnswerType = async () => {
    const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/answerType");
    if (Boolean(response.data)) {
      this.setState({
        answerTypeSelectItems: response.data
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      answerType: null,
      fileList: []
    });
  };

  edit = row => {
    console.log("AAA", row);
    document.getElementById("kt_scrolltop").click();

    var options = [];
    if (Boolean(row) && Boolean(row.options) && row.options.length > 0) {
      row.options.forEach(element => {
        options.push(element.codeId);
      });
    }

    var columns = [];
    if (Boolean(row) && Boolean(row.columns) && row.columns.length > 0) {
      row.columns.forEach(element => {
        columns.push(element.codeId);
      });
    }

    let _fileList = [];
    if (Boolean(row.answerType) && row.answerType === "Imagepicker") {
      if (Boolean(row.imageOptions)) {
        row.imageOptions.forEach((element, index) => {
          if (Boolean(element.imageUrl)) {
            let obj = {
              url: element.imageUrl,
              uid: index,
              imagePath: element.imageUrl,
              id: index,
              name: element.imageUrl.substring(element.imageUrl.lastIndexOf("/") + 1)
            };
            _fileList.push(obj);
          }
        });
      }
    }

    this.formRef.current.setFieldsValue({
      ...row,
      options: options,
      columns: columns
    });
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      answerType: row.answerType,
      fileList: _fileList
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const { gapAnalysisCompanyId } = this.props;
    let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getAllByCompany/" + gapAnalysisCompanyId);
    console.log("XXX", response.data);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: []
      });
    } else {
      this.setState({
        loading: false,
        tableList: response.data
      });
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      searchCriteriaModels = [],
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      gapAnalysisCompanyId: this.props.gapAnalysisCompanyId,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
      searchCriteriaModels: searchCriteriaModels
    };
    return newObj;
  }

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    console.log("AAA", values);
    var options = [];
    if (Boolean(values.options) && values.options.length > 0) {
      values.options.forEach(element => {
        options.push({
          ["codeId"]: element
        });
      });
    }

    var columns = [];
    if (Boolean(values.columns) && values.columns.length > 0) {
      values.columns.forEach(element => {
        columns.push({
          ["codeId"]: element
        });
      });
    }

    var _imageOptions = [];
    if (Boolean(this.state.fileList))
      this.state.fileList.forEach(element => {
        let url = null;
        if (Boolean(element.url)) {
          url = element.url;
        } else if (Boolean(element.response) && Boolean(element.response.url)) {
          url = element.response.url;
        }
        if (Boolean(url)) {
          _imageOptions.push({
            ["imageUrl"]: url
          });
        }
      });

    let newItem = {
      ...values,
      id: this.state.id,
      gapAnalysisCompanyId: this.props.gapAnalysisCompanyId,
      options: options,
      columns: columns,
      imageOptions: _imageOptions
    };
    console.log("xxll", newItem);

    if (!Boolean(newItem.id)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      this.restartTable();
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  renderElement = (values, parenTitle) => {
    return (
      Boolean(values) &&
      values.length > 0 &&
      values.map((item, index) => (
        <Collapse activeKey={this.state.activePanelKeyTitle} onChange={this.setOpenPanelsTitle} accordion>
          <Panel header={item.title} key={parenTitle + index}>
            {Boolean(item.children) && item.children.length > 0 && (
              <>
                {Boolean(item.children) &&
                  item.children.length > 0 &&
                  item.children.map((item, index) => (
                    <>
                      <div style={{ backgroundColor: "white", padding: "10px", border: "1px solid #1ab394" }}>
                        <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                          <FormBoot.Label style={{ color: "black" }} column sm="9">
                            {item.question}
                          </FormBoot.Label>

                          <ColBoot sm="2">
                            <div style={{ float: "right" }}>
                              {item.linkedQuestion && (
                                <AntButton style={{ marginRight: "10px" }} type="dashed" shape="circle" icon={<LinkOutlined />} />
                              )}
                              <AntButton
                                style={{ marginRight: "10px" }}
                                type="dashed"
                                onClick={() => this.edit(item)}
                                shape="circle"
                                icon={<EditOutlined />}
                              />
                              <AntButton type="dashed" onClick={() => this.deleteModal(item)} shape="circle" icon={<CloseOutlined />} />
                            </div>
                          </ColBoot>
                        </FormBoot.Group>
                        {Boolean(item.answerType) && item.answerType === "Comment" && (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <ColBoot xs="12">
                              <FormBoot.Control as="textarea" rows="3" />
                            </ColBoot>
                          </FormBoot.Group>
                        )}

                        {Boolean(item.answerType) && item.answerType === "Radiogroup" && (
                          <>
                            {Boolean(item.options) &&
                              item.options.length > 0 &&
                              item.options.map((opt, j) => (
                                <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                                  <ColBoot xs="11">
                                    <Radio checked={false}>{opt.codeName}</Radio>
                                  </ColBoot>
                                </FormBoot.Group>
                              ))}
                          </>
                        )}

                        {Boolean(item.answerType) && item.answerType === "Imagepicker" && (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <ColBoot xs="12">
                              {Boolean(item.imageOptions) &&
                                item.imageOptions.length > 0 &&
                                item.imageOptions.map((opt, j) => (
                                  <>
                                    <RadioGroup>
                                      <Radio checked={false}>
                                        <img alt="option" style={{ width: "120px", height: "120px" }} src={opt.imageUrl} />
                                      </Radio>
                                    </RadioGroup>
                                  </>
                                ))}
                            </ColBoot>
                          </FormBoot.Group>
                        )}

                        {Boolean(item.answerType) && item.answerType === "Rating" && (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <ColBoot xs="12">
                              <Radio.Group buttonStyle="solid">
                                {this.getRatingList(item.rateMax).map((element, index) => (
                                  <Radio.Button value={element}>{element}</Radio.Button>
                                ))}
                              </Radio.Group>
                            </ColBoot>
                          </FormBoot.Group>
                        )}

                        {Boolean(item.answerType) && item.answerType === "Boolean" && (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <ColBoot xs="12">
                              <Switch checkedChildren={item.labelTrue} unCheckedChildren={item.labelFalse} defaultChecked />
                            </ColBoot>
                          </FormBoot.Group>
                        )}

                        {Boolean(item.answerType) && item.answerType === "Checkbox" && (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            {Boolean(item.options) &&
                              item.options.length > 0 &&
                              item.options.map((opt, j) => (
                                <>
                                  <ColBoot style={{ marginBottom: "1rem" }} xs="11">
                                    <Checkbox> {opt.codeName}</Checkbox>
                                  </ColBoot>{" "}
                                  <br />
                                </>
                              ))}
                          </FormBoot.Group>
                        )}

                        {Boolean(item.answerType) && item.answerType === "Dropdown" && (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <ColBoot xs="4">
                              <FormBoot.Control name="type" as="select">
                                <option value="Select">Select Option</option>
                                {Boolean(item.options) &&
                                  item.options.length > 0 &&
                                  item.options.map((opt, j) => <option value={opt.codeName}>{opt.codeName}</option>)}
                              </FormBoot.Control>
                            </ColBoot>
                          </FormBoot.Group>
                        )}

                        {Boolean(item.answerType) && item.answerType === "File" && (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <ColBoot xs="4">
                              <Upload>
                                <AntButton>
                                  <UploadOutlined /> Click to Upload
                                </AntButton>
                              </Upload>
                            </ColBoot>
                          </FormBoot.Group>
                        )}

                        {Boolean(item.answerType) && item.answerType === "Matrix_Single" && Boolean(item.columns) && item.columns.length > 0 && (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <ColBoot> </ColBoot>
                            {item.columns.map((col, j) => (
                              <ColBoot>{col.codeName}</ColBoot>
                            ))}
                          </FormBoot.Group>
                        )}

                        {Boolean(item.answerType) && item.answerType === "Matrix_Multiple" && (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <ColBoot> </ColBoot>
                            {item.columns.map((col, j) => (
                              <ColBoot>{col.codeName}</ColBoot>
                            ))}
                          </FormBoot.Group>
                        )}

                        {Boolean(item.answerType) &&
                          item.answerType === "Matrix_Single" &&
                          Boolean(item.columns) &&
                          item.columns.length > 0 &&
                          Boolean(item.matrixValues) &&
                          item.matrixValues.length > 0 &&
                          item.matrixValues.map((row, k) => (
                            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                              <ColBoot>{row.title}</ColBoot>
                              {item.columns.map((col, j) => (
                                <ColBoot>
                                  <Radio checked={false}></Radio>
                                </ColBoot>
                              ))}
                            </FormBoot.Group>
                          ))}

                        {Boolean(item.answerType) &&
                          item.answerType === "Matrix_Multiple" &&
                          Boolean(item.matrixValues) &&
                          item.matrixValues &&
                          item.matrixValues.map((row, k) => (
                            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                              <ColBoot> {row.title} </ColBoot>
                              {item.columns.map((col, j) => (
                                <ColBoot>
                                  <FormBoot.Control name="type" as="select">
                                    <option value="Select">Select Option</option>
                                    {Boolean(item.options) &&
                                      item.options.length > 0 &&
                                      item.options.map((opt, j) => <option value={opt.codeId}>{opt.codeName}</option>)}
                                  </FormBoot.Control>
                                </ColBoot>
                              ))}
                            </FormBoot.Group>
                          ))}
                      </div>
                      <br />
                    </>
                  ))}
              </>
            )}
          </Panel>
        </Collapse>
      ))
    );
  };

  calcWeight = () => {
    var activePanelKey = this.state.activePanelKey;
    var values = this.state.tableList;
    if (Boolean(values) && values.length > 0) {
      var totalParentScore = 0;
      values.forEach((element, index) => {
        if (Boolean(element.score) && element.score > 0) {
          totalParentScore += element.score;
        }
      });

      values.forEach((element, index) => {
        if (Boolean(element.score) && element.score > 0 && totalParentScore > 0) {
          element.weight = Number((element.score / totalParentScore) * 100).toFixed(2);
        }
        if (index == activePanelKey) {
          var totalChildScore = 0;
          if (Boolean(element.children) && element.children.length > 0) {
            element.children.forEach((child, index) => {
              if (Boolean(child.score) && child.score > 0) {
                totalChildScore += child.score;
              }
            });
          }

          values.forEach(element => {
            if (Boolean(element.children) && element.children.length > 0) {
              element.children.forEach((child, index) => {
                if (Boolean(child.score) && child.score > 0 && totalChildScore > 0) {
                  child.weight = Number((child.score / totalChildScore) * 100).toFixed(2);
                }
              });
            }
          });
        }
      });
    }

    this.setState({
      tableList: this.state.tableList
    });
  };

  setOpenPanels = key => {
    this.setState({
      activePanelKey: key
    });
  };

  setOpenPanelsTitle = key => {
    this.setState({
      activePanelKeyTitle: key
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  getRatingList = max => {
    const numbers = [];
    for (let index = 1; index <= max; index++) {
      numbers.push(index);
    }
    return numbers;
  };

  handleFileChange = ({ fileList }) => this.setState({ fileList });

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
              exportClick: false
            })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Row>
          <Col md={21}></Col>
          <Col md={1}>{excelExport}</Col>
          <Col md={1}>
            <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
          </Col>
          <Col md={1}>
            <AntButton
              style={{ border: "0px" }}
              hidden={!this.state.hideInputs}
              onClick={this.onClickCreateNew}
              icon={<CreateIcon color="secondary" fontSize="large" />}
            ></AntButton>
            <AntButton
              style={{ border: "0px" }}
              hidden={this.state.hideInputs}
              onClick={this.onClickCreateNew}
              icon={<RemoveCircle color="error" fontSize="large" />}
            ></AntButton>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <div hidden={this.state.hideInputs}>
              <Form defaultMessage="pipe" initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
                <Form.List name="minimumRequirementList">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(field => (
                        <Form.Item
                          {...layout}
                          {...field}
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Form.Item
                            name={[field.name, "key"]}
                            extra="Title"
                            {...field}
                            style={{
                              display: "inline-block",
                              width: "calc(100%)"
                            }}
                          >
                            <Input></Input>
                          </Form.Item>

                          <Form.Item
                            name={[field.name, "Dimension"]}
                            extra="Dimension"
                            {...field}
                            style={{
                              display: "inline-block",
                              width: "calc(100%)"
                            }}
                          >
                            <Select style={{ width: "100%" }} placeholder="Dimension">
                              <Option key={null} value={null}>
                                Select
                              </Option>
                              {Boolean(this.state["dataTypeSelectItems"]) &&
                                this.state["dataTypeSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}{" "}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            name={[field.name, "Constant"]}
                            extra="Constant"
                            {...field}
                            style={{
                              display: "inline-block",
                              width: "calc(100%)"
                            }}
                          >
                            <Select style={{ width: "100%" }} placeholder="Constant">
                              <Option key={null} value={null}>
                                Select
                              </Option>
                              {Boolean(this.state["dataTypeSelectItems"]) &&
                                this.state["dataTypeSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}{" "}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            extra="Operator"
                            name={[field.name, "Operator"]}
                            {...field}
                            style={{
                              display: "inline-block",
                              width: "calc(100%)"
                            }}
                          >
                            <Select style={{ width: "100%" }} placeholder="Operator">
                              <Option key={null} value={null}>
                                Select
                              </Option>
                              {Boolean(this.state["dataTypeSelectItems"]) &&
                                this.state["dataTypeSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}{" "}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            {...field}
                            style={{
                              display: "inline-block",
                              width: "calc(100%)"
                            }}
                          >
                            <AntButton type="primary" style={{ marginRight: "5px" }} icon={<ArrowRightOutlined fontSize="large" />} />
                            <AntButton type="primary" icon={<RollbackOutlined fontSize="large" />} />
                          </Form.Item>

                          <Form.Item
                            style={{
                              display: "inline-block",
                              width: "calc(100%)"
                            }}
                            extra="Formula"
                            name={[field.name, "key"]}
                            {...field}
                          >
                            <Input></Input>
                          </Form.Item>

                          <Form.Item
                            {...field}
                            style={{
                              display: "inline-block",
                              width: "calc(100%)"
                            }}
                          >
                            <AntButton type="danger" icon={<CloseOutlined fontSize="large" />} />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            style={{
                              display: "inline-block",
                              width: "calc(100%)"
                            }}
                          >
                            <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                              <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                            </Button>
                          </Form.Item>

                          <Form.Item {...field} hidden name={[field.name, "id"]}>
                            <InputNumber></InputNumber>
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <AntButton style={{ width: "40%" }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add Features
                        </AntButton>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form>
            </div>
          </Col>
        </Row>
        <br />

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Features);
