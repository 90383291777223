import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Input } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import store from "../../../../redux/store";
import DraggableModal from "../../../Components/DraggableModal";
import { Select } from "antd";
import { Table } from "antd";
import { FilterOutlined } from "@ant-design/icons";

const { Option } = Select;

export class BillOfQuantityVendorNotRelated extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "BillOfQuantityVendorNotRelated",
      controllerName: "billOfQuantity",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      }
    };
  }

  formRef = React.createRef();
  formRefNewVendor = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    this.fillComboboxes();
    this.resetInputs();
    const { pagination } = this.state;
    this.restartTable({ pagination });
  };

  fillComboboxes = async () => {

  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/vendorPagingNotRelated/" + this.props.itemIds;
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
          element.key = element.id;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagpaginationination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      // id: null,
      ids: this.state.selectedVendorNotRelatedIds,
      itemType: this.state.itemType,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  fillItem = async (groupId) => {
    if (Boolean(groupId) && groupId > 0) {
      var responseitemId = await handleRequest("GET", "/api/" + this.state.controllerName + "/item/" + groupId);
      if (responseitemId.type === "ERROR") {
        error(responseitemId);
      }
      this.setState({
        itemIdSelectItems: Boolean(responseitemId.data) ? responseitemId.data : []
      });
    } else {
      this.setState({
        itemIdSelectItems: []
      });
    }
  }

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  edit = row => {
    this.setState(
      {
        showVendorLogModal: true,
        hideUpdateVendor: false,
        hideSaveVendor: true,
        vendorLogId: row.id
      },
      () => {
        if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
          var emails = "";
          var address = "";
          if (Boolean(row.contactList) && row.contactList.length > 0) {
            row.contactList.forEach(element => {
              if (Boolean(element.emails)) {
                emails += element.emails + ", ";
              }
              if (Boolean(element.address)) {
                address += element.address + ", ";
              }
            });
          }
          if (Boolean(emails)) {
            emails = emails.substring(0, emails.length - 2);
          }
          if (Boolean(address)) {
            address = address.substring(0, address.length - 2);
          }
          this.formRef.current.setFieldsValue({
            ...row,
            emails: emails,
            address: address
          });
        }
      }
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      showSuccess();
      this.props.onCancel();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleFormNewVendorSubmit = async values => {
    const newItem = {
      ...values,
    };
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createVendor", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        showNewVendorModal: false
      });
      showSuccess();
      const { pagination } = this.state;
      this.restartTable({ pagination });
    }
  };

  onSelectChange = selectedRowKeysIds => {
    this.setState({
      selectedRowKeysIds: selectedRowKeysIds
    });
    if (Boolean(this.props.updateSelectedVendorNotRelatedRowKeys)) {
      this.props.updateSelectedVendorNotRelatedRowKeys(selectedRowKeysIds);
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const { selectedRowKeysIds } = this.state;
    const rowSelection = {
      selectedRowKeys: selectedRowKeysIds,
      onChange: this.onSelectChange
    };

    const columns = [
      {
        key: "key",
        width: 100,
        title: <FormattedMessage id="General.TitleActions" defaultMessage="Actions" />,
        fixed: "left",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="BillOfQuantityVendorNotRelated.Vendor" defaultMessage="Vendor" />,
        key: "id",
        width: 100,
        render: record => {
          return record.name;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  id: value,
                  idColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["vendorsSelectItems"]) &&
                this.state["vendorsSelectItems"].length > 0 &&
                this.state["vendorsSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.idColor) ? this.state.pagination.idColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },

      {
        title: <FormattedMessage id="BillOfQuantityVendorNotRelated.Address" defaultMessage="Address" />,
        key: "contactList",
        width: 100,
        render: record => {
          var address = "";
          if (Boolean(record.contactList) && record.contactList.length > 0) {
            record.contactList.forEach(element => {
              if (Boolean(element.address)) {
                address += element.address + ", ";
              }
            });
          }
          if (Boolean(address)) {
            return address.substring(0, address.length - 2);
          }
        }
      },
      {
        title: <FormattedMessage id="BillOfQuantityVendorNotRelated.MailAddress" defaultMessage="Mail Address" />,
        key: "contactList",
        width: 100,
        render: record => {
          var emails = "";
          if (Boolean(record.contactList) && record.contactList.length > 0) {
            record.contactList.forEach(element => {
              if (Boolean(element.emails)) {
                emails += element.emails + ", ";
              }
            });
          }
          if (Boolean(emails)) {
            return emails.substring(0, emails.length - 2);
          }
        }
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <br /> <br />
          <Table
            bordered
            rowKey={record => record.id}
            rowSelection={rowSelection}
            scroll={{ x: 900, y: 400, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </Form>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BillOfQuantityVendorNotRelated); 