import React, { useState, useEffect } from "react";
import { handleRequest } from "../../../ApiConnector";
import { error } from "../../../MessageHelper";
import { Col, Row, Table, Select, Button as AntButton, Input, Progress, Badge, Steps, Button, Form } from "antd";
import { FormattedMessage } from "react-intl";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import DraggableModal from "../../../Components/DraggableModal";
const { Option } = Select;
const { Step } = Steps;

const ProjectsProgress = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState(null);
  const [page] = useState(0);
  const [limit, setLimit] = useState(5);
  const [progressType, setProgressType] = useState("line");
  const [projectsProgress, setProjectsProgress] = useState(null);
  const [showModalProjectsProgress, setShowModalProjectsProgress] = useState(false);

  useEffect(() => {
    asyncFetch();
  }, [page, limit, keyword]);

  const asyncFetch = async () => {
    let pagination = {
      page: page,
      limit: limit,
      keyword: keyword
    };
    setLoading(true);
    var response = await handleRequest("POST", "/api/dashboard/projectsProgress", pagination);
    setLoading(false);
    if (response.type === "ERROR") {
      error(response);
    } else {
      let data = Boolean(response.data) ? response.data : null;
      if (Boolean(data.details) && data.details.length > 0) {
        let index = data.details.findIndex(p => p.status === "Not Started");
        if (index !== -1) data.details[index].status = "In Progress";
      }
      setData(data);
    }
  };

  let columns = [
    {
      key: "projectFullName",
      title: <FormattedMessage id="ProjectsProgress.Project" defaultMessage="Project" />,
      render: (value, row, index) => <AntButton type="link">{row.projectFullName} </AntButton>
    },
    {
      key: "Progress",
      title: "Progress",
      render: (value, row, index) => {
        return <Progress type={progressType} percent={row.percentProgress} status="active" strokeColor={{ from: "#108ee9", to: "#87d068" }} />;
      }
    }
  ];

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={18}>
          <Input
            value={keyword}
            allowClear
            placeholder="Search Project"
            prefix={<SearchOutlined />}
            onChange={e => {
              setKeyword(e.target.value);
              setLimit(5);
            }}
          />
        </Col>
        
        <Col lg={6}>
          <Select
            placeholder="Progress Type"
            defaultActiveFirstOption
            allowClear
            value={progressType}
            className="w-100"
            onChange={value => {
              setProgressType(value);
              setLimit(5);
            }}
          >
            <Option key={"line"} value={"line"}>
              <FormattedMessage id="ProjectsProgress.line" defaultMessage="Line" />
            </Option>
            <Option key={"circle"} value={"circle"}>
              <FormattedMessage id="ProjectsProgress.Circle" defaultMessage="Circle" />
            </Option>
          </Select>
        </Col>
        {/* <Col lg={4}>
          <AntButton
            type="dashed"
            icon={<SearchOutlined />}
            onClick={() => {
              asyncFetch();
            }}
          >
            <FormattedMessage id="GeneralButtonSearch" defaultMessage="Search" />
          </AntButton>
        </Col> */}
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={24}>
          <div>
            <Table columns={columns} dataSource={data} pagination={false} />
          </div>
          <Button
            onClick={() => setLimit(limit + 5)}
            type="dashed"
            loading={loading}
            disabled={loading}
            style={{ marginRight: "auto", marginLeft: "auto", marginTop: "8px" }}
          >
            Load More
          </Button>
        </Col>
      </Row>

      <DraggableModal
        title={<FormattedMessage id="ModalPhotoText" defaultMessage="Photo" />}
        open={showModalProjectsProgress}
        okButtonProps={{ hidden: true }}
        onCancel={() => setShowModalProjectsProgress(false)}
        width={700}
        centered
        content={
          <Badge.Ribbon text="Projects Progress" placement="start" color="green" style={{ fontSize: 12 }}>
            <div className="card card-custom" style={{ padding: "0.1rem", minHeight: "100%" }}>
              <div className="card-body pt-4 custom-timeline pr-5 pl-5 mr-5 mt-10 align-content-center">
                <Steps direction="vertical" className="pl-5-custom anticon-custom">
                  {Boolean(projectsProgress) &&
                    Boolean(projectsProgress.details) &&
                    projectsProgress.details.length > 0 &&
                    projectsProgress.details.map((item, index) => {
                      return (
                        <Step
                          title={item.projectsProgressStepName}
                          status={
                            item.status === "Completed"
                              ? "finish"
                              : item.status === "In Progress"
                              ? "process"
                              : item.status === "Not Started"
                              ? "wait"
                              : "wait"
                          }
                          description={"( " + item.weight + " % )"}
                          icon={item.status === "In Progress" && <LoadingOutlined />}
                        />
                      );
                    })}
                </Steps>
              </div>
            </div>
          </Badge.Ribbon>
        }
      />
    </>
  );
};
export default ProjectsProgress;
