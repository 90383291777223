import { Button, Drawer } from "antd";
import objectPath from "object-path";
import React, { useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { useHtmlClassService } from "../../../../_core/MetronicLayout";
import { MyTicketsMainPage } from "./MyTicketsMainPage";

export default function MyTicketsDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.search.layout") === "offcanvas"
    };
  }, [uiService]);
  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div className="btn btn-icon btn-clean btn-lg mr-1" id="kt_notes_toggle">
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Clip.svg")} />
            </span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <div className="topbar-item">
          <OverlayTrigger placement="bottom" overlay={<Tooltip id="user-notification-tooltip">
            <FormattedMessage id="MyTicketsDropdown.MyTickets" defaultMessage="My Tickets" />
          </Tooltip>}>
            <Button
              className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
              icon={
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Clip.svg")} />
                </span>
              }
              onClick={() => setIsOpen(true)}
            ></Button>
          </OverlayTrigger>
        </div>
      )}

      <Drawer title={<FormattedMessage id="MyTicketsDropdown.MyTickets" defaultMessage="My Tickets" />}
        width={1200} closable={true} onClose={() => {
          setIsOpen(false);
        }} open={isOpen}>
        <MyTicketsMainPage />
      </Drawer>
    </>
  );
}