import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, List, Button as AntButton, Badge } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Checkbox } from "antd";
import { DatePicker } from "antd";
import { Select, Tooltip, Cascader } from "antd";
import { Input } from "antd";
import { EyeTwoTone, EyeInvisibleTwoTone, MinusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

export class BankTransactionsDeposit extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "BankTransactionsDeposit",
      controllerName: "bankTransactionsDeposit",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: false,
      bankTransferVisible: false,
      invoiceVisible: true,
      returnPaymentVisible: true,
      dateVisible: false,
      fromVisible: false,
      bankAccountVisible: false,
      receivedByVisible: false,
      amountIdVisible: false,


      inHouseBankTransferVisible: false,
      inHouseManualDepositVisible: false,
      externalInvoiceVisible: false,
      externalChequeVisible: false,
      externalNoteVisible: false,
      externalOtherVisible: false,
      transactionDateVisible: false,
      transactionAmountVisible: false,
      bankAccountVisible: false,
      bankTransferBankAccountVisible: false,
      customerVisible: false,
      invoiceNoVisible: false,
      chequeNoVisible: false,
      noteNoVisible: false,
      amountVisible: false,
      balanceVisible: false,
      reasonForTransactionVisible: false,
      uploadVisible: false,

      externalVisible: true,
      inHouseVisible: true,

      tableList: [],

      cashIn: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
    };
  }

  formRef = React.createRef();




  componentDidMount = async () => {
    this.fillComboboxes();
  };
  fillComboboxes = async () => {
    var responsefromId = await handleRequest("GET", "/api/" + this.state.controllerName + "/fromId");
    if (responsefromId.type === "ERROR") {
      error(responsefromId);
    }
    this.setState({
      fromIdSelectItems: Boolean(responsefromId.data) ? responsefromId.data : []
    });
    var responsebankAccountId = await handleRequest("GET", "/api/" + this.state.controllerName + "/bankAccountId");
    if (responsebankAccountId.type === "ERROR") {
      error(responsebankAccountId);
    }
    this.setState({
      bankAccountIdSelectItems: Boolean(responsebankAccountId.data) ? responsebankAccountId.data : []
    });
    var responseinvoiceNoId = await handleRequest("GET", "/api/" + this.state.controllerName + "/invoiceNoId");
    if (responseinvoiceNoId.type === "ERROR") {
      error(responseinvoiceNoId);
    }
    this.setState({
      invoiceNoIdSelectItems: Boolean(responseinvoiceNoId.data) ? responseinvoiceNoId.data : []
    });
    var responsereceivedById = await handleRequest("GET", "/api/" + this.state.controllerName + "/receivedById");
    if (responsereceivedById.type === "ERROR") {
      error(responsereceivedById);
    }
    this.setState({
      receivedByIdSelectItems: Boolean(responsereceivedById.data) ? responsereceivedById.data : []
    });
    var responseamountId = await handleRequest("GET", "/api/" + this.state.controllerName + "/amountId");
    if (responseamountId.type === "ERROR") {
      error(responseamountId);
    }
    this.setState({
      amountIdSelectItems: Boolean(responseamountId.data) ? responseamountId.data : []
    });
    var responsecashId = await handleRequest("GET", "/api/" + this.state.controllerName + "/cashId");
    if (responsecashId.type === "ERROR") {
      error(responsecashId);
    }
    this.setState({
      cashIdSelectItems: Boolean(responsecashId.data) ? responsecashId.data : []
    });
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };



    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);



    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id.cashId
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  save = async values => {
    if (Boolean(this.props.cashId) && this.props.cashId > 0) {
      const newItem = {
        ...values,
        id: this.state.id,
        cashId: this.props.cashId,
      };

      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
      }

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showWarning("Cash not found.");
    }

  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  edit = (typeRevise, row) => {
    console.log("RRR", row);
    document.getElementById("kt_scrolltop").click();


    var bankAccountVisible = false;
    if (Boolean(row.cashIn) && row.cashIn.length > 0) {

      bankAccountVisible = true;
    }
    var invoiceVisible = false;
    if (Boolean(row.cashIn) && row.cashIn.length > 0) {

      invoiceVisible = true;
    }

    var returnPaymentVisible = false;
    if (Boolean(row.cashIn) && row.cashIn.length > 0) {

      returnPaymentVisible = true;
    }



    this.setState({

      bankAccountVisible: bankAccountVisible,
      invoiceVisible: invoiceVisible,
      returnPaymentVisible: returnPaymentVisible
    });
  };


  getAuthorized = async (cashLogId) => {
    if (Boolean(cashLogId) && cashLogId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/authorized/" + cashLogId);
      if (response.type === "ERROR") {
        error(response);
      }
      this.setState({
        releasedByIdSelectItems: Boolean(response.data) ? response.data : []
      });
    } else {
      this.setState({
        releasedByIdSelectItems: []
      });
    }
  }

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };


    return (
      <Badge.Ribbon
        text={<FormattedMessage id="BankTransactionsDeposit.pageTitle"
          defaultMessage="Deposit "
        />
        } placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div
          className="card card-custom"
          style={{ padding: "2rem", minHeight: "100%" }}
        >

          <Form
            initialValues={{ remember: false }}
            onFinish={this.save}
            onFinishFailed={onFinishFailed}
            ref={this.formRef}
          >

            <Form.Item
              {...layout}
              label={<FormattedMessage id="CashIn.date" defaultMessage="Date" />}
              name="date"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="CashIn.bankAccountId" defaultMessage="Bank Account" />}
              name="bankAccountId"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select allowClear
                showSearch
                style={{ width: "100%" }}
                optionFilterProp="children"

              >
                {Boolean(this.state["bankAccountIdSelectItems"]) && this.state["bankAccountIdSelectItems"].length > 0 &&
                  this.state["bankAccountIdSelectItems"].map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}{" "}
              </Select>
            </Form.Item>


            {this.state.inHouseVisible &&
            <Form.Item
              {...layout}
              label={<FormattedMessage id="BankTransactionsDeposit.Inhouse" defaultMessage="In-house" />}
              name="inhouse"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              valuePropName="checked"
            >
              <Checkbox
                onChange={e => {
                  let externalVisible = true;
                  if (e.target.checked) {
                    externalVisible = false;
                    this.formRef.current.setFieldsValue({
                      externalInvoice: null,
                      advancePayment: null,
                      pettyCash: null,
                    });
                  }
                  this.setState({
                    inHouseBankTransferVisible: e.target.checked,
                    inHouseManualDepositVisible: e.target.checked,
                    cashCBVisible: e.target.checked,
                    bankVisible: e.target.checked,
                    releasedByVisible: e.target.checked,


                    toSelectVisible: false,
                    externalVisible: externalVisible,
                  });

                }} />
            </Form.Item>
  }

            {this.state.externalVisible &&
              <Form.Item
                {...layout}
                label={<FormattedMessage id="BankTransactionsDeposit.External" defaultMessage="External" />}
                name="external"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox
                  onChange={e => {
                    let inHouseVisible = true;
                    if (e.target.checked) {
                      inHouseVisible = false;
                      this.formRef.current.setFieldsValue({
                        externalInvoice: null,
                        externalCheque: null,
                        externalNote: null,
                        externalOther: null,


                        advancePayment: null,
                        pettyCash: null,

                      });
                         
                    }
                    else{
                      this.setState({
                        transactionDateVisible: e.target.checked,
                        transactionAmountVisible: e.target.checked,
                        customerVisible: e.target.checked,
                        invoiceNoVisible: e.target.checked,
                        reasonForTransactionVisible: e.target.checked,
                        uploadVisible: e.target.checked,
                        chequeNoVisible: e.target.checked,
                        balanceVisible: e.target.checked,
                        noteNoVisible: e.target.checked,
                      })
                    }
                    this.setState({
                      externalInvoiceVisible: e.target.checked,
                      externalChequeVisible: e.target.checked,
                      externalNoteVisible: e.target.checked,
                      externalOtherVisible: e.target.checked,

                      cashCBVisible: e.target.checked,
                      bankVisible: e.target.checked,
                      releasedByVisible: e.target.checked,
                      toSelectVisible: false,
                      invoiceNoVisible: false,
                      
                      inHouseVisible: inHouseVisible,

                    });
                  }} />
              </Form.Item>
            }
            <div hidden={this.state.hideInputs}>




              {this.state.dateVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CashIn.date" defaultMessage="Date" />}
                  name="date"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              }
              {this.state.fromVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CashIn.fromId" defaultMessage="From" />}
                  name="fromId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                  >
                    {Boolean(this.state["fromIdSelectItems"]) && this.state["fromIdSelectItems"].length > 0 &&
                      this.state["fromIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              {this.state.receivedByVisible &&

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CashIn.receivedById" defaultMessage="Received by" />}
                  name="receivedById"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                  >
                    {Boolean(this.state["receivedByIdSelectItems"]) && this.state["receivedByIdSelectItems"].length > 0 &&
                      this.state["receivedByIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {this.state.amountVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CashIn.amount" defaultMessage="Amount" />}>
                  <Row gutter={8}>
                    <Col span={8}>
                      <Form.Item name="amount">
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        label={<FormattedMessage id="CashIn.amountId" defaultMessage="Currency" />}
                        name="amountId"

                      >
                        <Select allowClear
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                        >
                          {Boolean(this.state["amountIdSelectItems"]) && this.state["amountIdSelectItems"].length > 0 &&
                            this.state["amountIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}{" "}
                        </Select>

                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              }


              {this.state.inHouseBankTransferVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BankTransactionsDeposit.InHouseBankTransfer" defaultMessage="Bank Transfer" />}
                  name="inHouseBankTransfer"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={e => {
                      let inHouseManualDepositVisible = true;
                      if (e.target.checked) {
                        inHouseManualDepositVisible = false;
                        this.formRef.current.setFieldsValue({
                          externalInvoice: null,
                          advancePayment: null,
                          pettyCash: null,
                          bank: null,

                        });
                      }
                      this.setState({
                        transactionDateVisible: e.target.checked,
                        transactionAmountVisible: e.target.checked,
                        bankTransferBankAccountVisible: e.target.checked,
                        reasonForTransactionVisible: e.target.checked,
                        uploadVisible: e.target.checked,
                        inHouseManualDepositVisible: inHouseManualDepositVisible,

                        cashVisible: e.target.checked,
                        invoiceNoVisible: false,
                        toSelectVisible: false,
                        bankAccountVisible: false,


                      });

                    }} />
                </Form.Item>
              }


              {this.state.inHouseManualDepositVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BankTransactionsDeposit.InHouseManualDeposit" defaultMessage="Manual Deposit" />}
                  name="inHouseManualDeposit"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={e => {
                      let inHouseBankTransferVisible = true;
                      if (e.target.checked) {
                        inHouseBankTransferVisible= false;
                        this.formRef.current.setFieldsValue({
                          externalInvoice: null,
                          advancePayment: null,
                          pettyCash: null,
                          bank: null,

                        });
                      }

                      this.setState({
                        transactionDateVisible: e.target.checked,
                        transactionAmountVisible: e.target.checked,
                        reasonForTransactionVisible: e.target.checked,
                        uploadVisible: e.target.checked,
                        inHouseBankTransferVisible: inHouseBankTransferVisible,

                        cashVisible: e.target.checked,
                        invoiceNoVisible: false,
                        bankAccountVisible: false,
                        toSelectVisible: false,
                        cashVisible: false,
                        bankVisible: false

                      });

                    }} />
                </Form.Item>
              }

              {this.state.externalInvoiceVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BankTransactionsDeposit.externalInvoice" defaultMessage="Invoice" />}
                  name="externalInvoice"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={e => {
                      let externalChequeVisible = true;
                      let externalNoteVisible = true;
                      let externalOtherVisible = true;
                      if (e.target.checked) {
                        externalChequeVisible = false;
                        externalNoteVisible = false;
                        externalOtherVisible = false;
                        this.formRef.current.setFieldsValue({
                          advancePayment: null,
                          pettyCash: null,
                          bank: null,

                        });
                      }
                      this.setState({
                          transactionDateVisible: e.target.checked,
                          transactionAmountVisible: e.target.checked,
                          customerVisible: e.target.checked,
                          invoiceNoVisible: e.target.checked,
                          reasonForTransactionVisible: e.target.checked,
                          uploadVisible: e.target.checked,
                          externalChequeVisible: externalChequeVisible,
                          externalNoteVisible: externalNoteVisible,
                          externalOtherVisible: externalOtherVisible,


                        cashVisible: e.target.checked,
                        toSelectVisible: false,
                        bankAccountVisible: false,

                      });

                    }} />
                </Form.Item>
              }
              {this.state.externalChequeVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BankTransactionsDeposit.externalCheque" defaultMessage="Cheque" />}
                  name="externalCheque"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={e => {
                      let externalInvoiceVisible = true;
                      let externalNoteVisible = true;
                      let externalOtherVisible = true;
                      if (e.target.checked) {
                        externalInvoiceVisible = false;
                        externalNoteVisible = false;
                        externalOtherVisible = false;
                        this.formRef.current.setFieldsValue({
                          externalInvoice: null,
                          advancePayment: null,
                          pettyCash: null,
                          bank: null,

                        });
                      }
                      this.setState({
                        transactionDateVisible: e.target.checked,
                        transactionAmountVisible: e.target.checked,
                        customerVisible: e.target.checked,
                        invoiceNoVisible: e.target.checked,
                        chequeNoVisible: e.target.checked,
                        balanceVisible: e.target.checked,
                        reasonForTransactionVisible: e.target.checked,
                        uploadVisible: e.target.checked,
                        externalInvoiceVisible: externalInvoiceVisible,
                        externalNoteVisible: externalNoteVisible,
                        externalOtherVisible: externalOtherVisible,


                        cashVisible: e.target.checked,
                        toSelectVisible: false,
                        bankAccountVisible: false,

                      });

                    }} />
                </Form.Item>
              }

              {this.state.externalNoteVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BankTransactionsDeposit.externalNote" defaultMessage="Note" />}
                  name="externalNote"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={e => {
                      let externalInvoiceVisible = true;
                      let externalChequeVisible = true;
                      let externalOtherVisible = true;
                      if (e.target.checked) {
                        externalInvoiceVisible = false;
                        externalChequeVisible = false;
                        externalOtherVisible = false;
                        this.formRef.current.setFieldsValue({
                          externalInvoice: null,
                          advancePayment: null,
                          pettyCash: null,
                          bank: null,

                        });
                      }
                      this.setState({
                        externalInvoiceVisible: externalInvoiceVisible,
                        externalChequeVisible: externalChequeVisible,
                        externalOtherVisible: externalOtherVisible,
                        transactionDateVisible: e.target.checked,
                        transactionAmountVisible: e.target.checked,
                        customerVisible: e.target.checked,
                        invoiceNoVisible: e.target.checked,
                        noteNoVisible: e.target.checked,
                        balanceVisible: e.target.checked,
                        reasonForTransactionVisible: e.target.checked,
                        uploadVisible: e.target.checked,


                        cashVisible: e.target.checked,
                        toSelectVisible: false,
                        bankAccountVisible: false,

                      });
                    }} />
                </Form.Item>
              }

              {this.state.externalOtherVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BankTransactionsDeposit.externalOther" defaultMessage="Other" />}
                  name="externalOther"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={e => {
                      let externalInvoiceVisible = true;
                      let externalChequeVisible = true;
                      let externalNoteVisible = true;
                      if (e.target.checked) {
                        externalInvoiceVisible = false;
                        externalChequeVisible = false;
                        externalNoteVisible = false;
                        this.formRef.current.setFieldsValue({
                          externalInvoice: null,
                          advancePayment: null,
                          pettyCash: null,
                          bank: null,

                        });
                      }
                      this.setState({
                        transactionDateVisible: e.target.checked,
                        transactionAmountVisible: e.target.checked,
                        reasonForTransactionVisible: e.target.checked,
                        uploadVisible: e.target.checked,
                        externalInvoiceVisible: externalInvoiceVisible,
                        externalChequeVisible: externalChequeVisible,
                        externalNoteVisible: externalNoteVisible,



                        cashVisible: e.target.checked,
                        invoiceNoVisible: false,
                        toSelectVisible: false,
                        bankAccountVisible: false,


                      });
                    }} />
                </Form.Item>
              }

              {this.state.transactionDateVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BankTransactionsDeposit.transactionDate" defaultMessage="Transaction Date" />}
                  name="transactionDate"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              }

              {this.state.transactionAmountVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BankTransactionsDeposit.transactionAmount" defaultMessage="Transaction Amount" />}>
                  <Row gutter={8}>
                    <Col span={8}>
                      <Form.Item name="transactionAmount">
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        name="transactionAmountId"

                      >
                        <Select allowClear
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                        >
                          {Boolean(this.state["amountIdSelectItems"]) && this.state["amountIdSelectItems"].length > 0 &&
                            this.state["amountIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}{" "}
                        </Select>

                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              }

              {this.state.bankTransferBankAccountVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BankTransactionsDeposit.bankAccountId" defaultMessage="Bank Account" />}
                  name="bankAccount2Id"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    showSearch
                    style={{ width: "100%" }}
                    optionFilterProp="children"

                  >
                    {Boolean(this.state["bankAccountIdSelectItems"]) && this.state["bankAccountIdSelectItems"].length > 0 &&
                      this.state["bankAccountIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              {this.state.customerVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BankTransactionsDeposit.Customer" defaultMessage="Customer" />}
                  name="customerId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    showSearch
                    style={{ width: "100%" }}
                    optionFilterProp="children"

                  >
                    {Boolean(this.state["bankAccountIdSelectItems"]) && this.state["bankAccountIdSelectItems"].length > 0 &&
                      this.state["bankAccountIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              {this.state.invoiceNoVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BankTransactionsDeposit.invoiceNoId" defaultMessage="Invoice No" />}
                  name="invoiceNoId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                  >
                    {Boolean(this.state["invoiceNoIdSelectItems"]) && this.state["invoiceNoIdSelectItems"].length > 0 &&
                      this.state["invoiceNoIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              {this.state.chequeNoVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BankTransactionsDeposit.chequeNoId" defaultMessage="Cheque No" />}
                  name="chequeNoId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                  >
                    {Boolean(this.state["invoiceNoIdSelectItems"]) && this.state["invoiceNoIdSelectItems"].length > 0 &&
                      this.state["invoiceNoIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }


              {this.state.noteNoVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BankTransactionsDeposit.noteNoId" defaultMessage="Note No" />}
                  name="noteNoId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                  >
                    {Boolean(this.state["invoiceNoIdSelectItems"]) && this.state["invoiceNoIdSelectItems"].length > 0 &&
                      this.state["invoiceNoIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              {this.state.amountVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BankTransactionsDeposit.Amount" defaultMessage="Amount" />}
                  name="amount"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }

              {this.state.balanceVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BankTransactionsDeposit.Balance" defaultMessage="Balance" />}
                  name="balance"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }

              {this.state.reasonForTransactionVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BankTransactionsDeposit.reasonForTransaction" defaultMessage="Reason For Transaction" />}
                  name="reasonForTransaction"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }

              {this.state.uploadVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="BankTransactionsDeposit.Upload" defaultMessage="Upload" />}
                  name="upload"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }




              {this.state.toSelectVisible &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CashOut.toSelectId" defaultMessage="To" />}
                  name="toSelectId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    showSearch
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    onChange={value => {
                      this.getAuthorized(value);
                    }}
                  >
                    {Boolean(this.state["toSelectIdSelectItems"]) && this.state["toSelectIdSelectItems"].length > 0 &&
                      this.state["toSelectIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }





              <div hidden={this.state.hideSave}>
                {<Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton"
                      type="submit"
                      style={{ width: "100%" }}
                      variant="success">
                      <FormattedMessage id="GeneralButtonSave"
                        defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>}
              </div>
              <div hidden={this.state.hideUpdate}>
                {<Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }}
                      variant="secondary"
                      onClick={this.cancel}>
                      <FormattedMessage
                        id="GeneralButtonCancel"
                        defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton"
                      style={{ width: "100%" }}
                      variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate"
                        defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>}
              </div>
            </div>
          </Form>
        </div>
      </Badge.Ribbon>
    );
  }
  dynamicAttirbute(layout, name) {
    return (
      <Form.List name={name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item {...layout} label={index + 1}>
                <Form.Item
                  {...field}
                  style={{
                    display: "inline-block",
                    width: "calc(10%)"
                  }}
                  name={[field.name, "check"]}
                  valuePropName="checked"
                >
                  <Checkbox autoFocus checked={true} defaultChecked={true}></Checkbox>
                </Form.Item>

                <Form.Item
                  {...field}
                  style={{
                    display: "inline-block",
                    width: "calc(60%)"
                  }}
                  name={[field.name, "description"]}
                >
                  <Input></Input>
                </Form.Item>
                <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} />
              </Form.Item>
            ))}

            <Form.Item>
              <Row gutter={[16, 16]}>
                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <Tooltip title={<FormattedMessage id="General.ShowItems" defaultMessage="Show Items" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      hidden={this.state.showAllSubItems === name ? true : false}
                      onClick={() => {
                        let { newItemState } = this.prepareAttributes(this.state.selectedRow, false, false, name);

                        this.setState({
                          ...newItemState,
                          showAllSubItems: name
                        });
                      }}
                      icon={<EyeTwoTone style={{ fontSize: "25px", cursor: "pointer" }} />}
                    ></AntButton>
                  </Tooltip>

                  <Tooltip title={<FormattedMessage id="General.HideItems" defaultMessage="Hide Items" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      hidden={!(this.state.showAllSubItems === name ? true : false)}
                      onClick={() => {
                        let { newItem, newItemState } = this.prepareAttributes(this.state.selectedRow, true, false, name);

                        this.setState({
                          ...newItemState,
                          showAllSubItems: !name
                        });
                      }}
                      icon={<EyeInvisibleTwoTone style={{ fontSize: "25px", cursor: "pointer" }} />}
                    ></AntButton>
                  </Tooltip>
                </Col>
                <Col md={10}></Col>
              </Row>
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  }
}

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BankTransactionsDeposit);
