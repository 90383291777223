import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { ClockCircleOutlined, DeleteFilled, EditFilled } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Form, Timeline, Space, Input, Select, Collapse, Card, Divider, DatePicker, Badge, Button as AntButton, Col, Row } from "antd";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";

import CreateIcon from "@material-ui/icons/AddCircle";
const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

export class MeetingMinutes extends Component {
  constructor() {
    super();
    this.state = {
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      startAttendeesSelectItems: null,
      minutesActionBySelectItems: null,
      id: null,
      selectedRow: null,
      tableList: [],
      fileList: [],
      nodes: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "" // for cascader.
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };
  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };
  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseActionByUsers = await handleRequest("GET", "/api/meetingMinutes/users");
    if (responseActionByUsers.type === "ERROR") {
      error(responseActionByUsers);
    } else {
      this.setState({
        minutesActionBySelectItems: Boolean(responseActionByUsers.data) ? responseActionByUsers.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      selectedRow: null,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: ""
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    var actionBy = [];
    if (Boolean(row.meetingMinutesActionBy) && row.meetingMinutesActionBy.length > 0) {
      row.meetingMinutesActionBy.forEach(element => {
        actionBy.push(element.userId);
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      meetingMinutesActionBy: actionBy,
      startTime: Boolean(row.startTime) ? moment(row.startTime) : null
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/meetingMinutes/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data && response.data.data) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    if (Boolean(this.props.meetingId) && this.props.meetingId > 0) {
      const newItem = {
        ...values,
        id: this.state.id,
        meetingId: this.props.meetingId,
        filePath: Boolean(this.state.fileList) && this.state.fileList.length > 0 ? this.state.fileList[0].url : ""
      };

      let actionBy = [];
      if (newItem.meetingMinutesActionBy && newItem.meetingMinutesActionBy.length > 0)
        newItem.meetingMinutesActionBy.forEach(element => {
          actionBy.push({ userId: element });
        });

      newItem.meetingMinutesActionBy = actionBy;

      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/meetingMinutes", newItem);
      } else {
        response = await handleRequest("PUT", "/api/meetingMinutes/" + newItem.id, newItem);
      }

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showError("Meeting not found");
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;
    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      meetingId: Boolean(this.props) && Boolean(this.props.meetingId) ? this.props.meetingId : null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    console.log(newObj);
    return newObj;
  }

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/meetingMinutes/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    return (
      <Badge.Ribbon text="Minutes of Meeting" placement="start" color="purple" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "3rem", minHeight: "100%" }}>
          <Form onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={23}></Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>

            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="MeetingMinutes.title" defaultMessage="Title" />}
                  name="title"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input placeholder="Title" style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="MeetingMinutes.minutesBy" defaultMessage="By" />}
                  name="meetingMinutesBy"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select placeholder="By" showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                    {Boolean(this.state["MeetingMinutesBySelectItems"]) &&
                      this.state["MeetingMinutesBySelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="MeetingMinutes.time" defaultMessage="Time" />}
                  name="time"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <DatePicker showTime style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="MeetingMinutes.decision" defaultMessage="Decision" />}
                  name="decision"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <TextArea placeholder="Decision" style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="MeetingMinutes.dueDate" defaultMessage="Due Date" />}
                  name="dueDate"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <DatePicker showTime style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="MeetingMinutes.actionBy" defaultMessage="Action By" />}
                  name="MeetingMinutesActionBy"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select mode="multiple" showSearch allowClear optionFilterProp="children" style={{ width: "100%" }} placeholder="Attendees">
                    {Boolean(this.state["minutesActionBySelectItems"]) &&
                      this.state["minutesActionBySelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
            <div className="card-body pt-4">
              <Badge.Ribbon
                text={<FormattedMessage id="MeetingMinutes.minutesTimeline" defaultMessage="Minutes of Meeting Timeline" />}
                placement="end"
                color="#f759ab"
                style={{ fontSize: 12 }}
              >
                <div className="card card-custom" style={{ padding: "5rem" }}>
                  <Timeline mode="alternate">
                    {Boolean(this.state.tableList) &&
                      this.state.tableList.length > 0 &&
                      this.state.tableList.map((item, index) => {
                        return (
                          <Timeline.Item
                            dot={
                              <ClockCircleOutlined
                                style={{
                                  color: "green",
                                  fontSize: "20px"
                                }}
                              />
                            }
                            label={Boolean(item.startTime) ? moment(item.startTime).format("HH:mm") : ""}
                          >
                            <Collapse hoverable style={{ align: "left", backgroundColor: "#efdbff" }} size="small">
                              <Panel
                                extra={
                                  <Space>
                                    <DeleteFilled style={{ color: "#f5222d" }} onClick={() => this.deleteModal(item)} />
                                    <Divider type="vertical" />
                                    <EditFilled style={{ color: "#1890ff" }} onClick={() => this.edit(item)} />
                                    <Divider type="vertical" />
                                  </Space>
                                }
                              >
                                <Card size="small" hoverable style={{ backgroundColor: "#efdbff" }}>
                                  <Divider dashed style={{ borderColor: "#9254de", color: "#9254de" }} orientation="left" plain>
                                    <FormattedMessage id="MeetingMinutes.title" defaultMessage="Title" />
                                  </Divider>
                                  {Boolean(item.title) ? item.title : ""}
                                  <Divider dashed style={{ borderColor: "#9254de", color: "#9254de" }} orientation="left" plain>
                                    <FormattedMessage id="MeetingMinutes.by" defaultMessage="By" />
                                  </Divider>
                                  {Boolean(item.meetingMinutesBy) &&
                                    item.meetingMinutesBy.length > 0 &&
                                    item.meetingMinutesBy.map((element, elementIndex) => {
                                      return (
                                        <div key={element.id} value={element.id}>
                                          {elementIndex + 1 + ". " + element.minutesByName}
                                        </div>
                                      );
                                    })}
                                  <Divider dashed style={{ borderColor: "#9254de", color: "#9254de" }} orientation="left" plain>
                                    <FormattedMessage id="MeetingMinutes.time" defaultMessage="Time" />
                                  </Divider>
                                  {Boolean(item.time) ? moment(item.time).format("DD-MM-YYYY HH:mm") : ""}
                                  <Divider dashed style={{ borderColor: "#9254de", color: "#9254de" }} orientation="left" plain>
                                    <FormattedMessage id="MeetingMinutes.decision" defaultMessage="Decision" />
                                  </Divider>
                                  {Boolean(item.decision) ? item.decision : ""}
                                  <Divider dashed style={{ borderColor: "#9254de", color: "#9254de" }} orientation="left" plain>
                                    <FormattedMessage id="MeetingMinutes.dueDate" defaultMessage=" Due Date" />
                                  </Divider>
                                  {Boolean(item.dueDate) ? moment(item.dueDate).format("DD-MM-YYYY HH:mm") : ""}
                                  <Divider dashed style={{ borderColor: "#9254de", color: "#9254de" }} orientation="left" plain>
                                    <FormattedMessage id="MeetingMinutes.actionBy" defaultMessage="Action By" />
                                  </Divider>
                                  {Boolean(item.meetingMinutesActionBy) &&
                                    item.meetingMinutesActionBy.length > 0 &&
                                    item.meetingMinutesActionBy.map((element, elementIndex) => {
                                      return (
                                        <div key={element.id} value={element.id}>
                                          {elementIndex + 1 + ". " + element.minutesActionByName}
                                        </div>
                                      );
                                    })}
                                </Card>
                              </Panel>
                            </Collapse>
                          </Timeline.Item>
                        );
                      })}
                  </Timeline>
                </div>
              </Badge.Ribbon>
            </div>
            <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.delete}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingMinutes);
