import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { error, showSuccess } from "../../MessageHelper";
import { Table } from "antd";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { FlagOutlined } from "@ant-design/icons";
import DraggableModal from "../../Components/DraggableModal";
import SurrenderOfVehicleLog from "../maintenance/SurrenderOfVehicleLog";

export class MaintenanceLogCallLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      showMaintenanceLogCallLogModal: false,
      controllerName: "maintenanceLogProcess",
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      tableListKm: [],

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      bankAccountsLogSelectList: [],
      chequeHolderSelectList: [],
      signatureAuthoritySelectList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();
  formRefCurrentStatus = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {};

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  maintanancePlan = row => {
    this.setState({
      showMaintenanceLogCallLogModal: true,
      selectedEquipmentLog: row,
      random: Math.random()
    });
  };

  partReplacementSchedule = row => {
    this.setState({
      showPartReplacementScheduleModal: true,
      selectedEquipmentLog: [row],
      random: Math.random()
    });
  };

  lastKmHour = row => {
    this.setState(
      {
        showLastKmHourModal: true,
        id: row.id,
        random: Math.random()
      },
      async () => {
        var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getStatus/" + row.id);
        if (response.type === "ERROR") {
          error(response);
        }
        let data = response.data;
        this.formRefCurrentStatus.current.setFieldsValue({
          ...data,
          kmDate: Boolean(data.kmDate) ? moment(data.kmDate) : null
        });
      }
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/pagingCallLog";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      maintenanceLogId: this.props.maintenanceLogId,
      equipmentLogId: this.props.equipmentLogId,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteEquipmentMaintanance/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  action = record => {
    let surrenderAction = {
      name: "Surrender",
      icon: <FlagOutlined fontSize="small" color="primary" />,
      actionClick: this.surrender.bind(this)
    };

    let actionList = [];
    actionList.push(surrenderAction);
    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  surrender = row => {
    this.setState({
      showSurrenderModal: true,
      selectedRow: row,
      random: Math.random()
    });
  };

  render() {
    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: "Call Date",
        key: "maintenanceStartDate",
        render: record => {
          return Boolean(record.callDate) ? moment(record.callDate).format("DD-MM-YYYY HH:mm") : "";
        }
      },
      {
        title: "Equipment",
        key: "equipmentLogId",
        render: record => {
          return record.equipmentLogName;
        }
      },
      {
        title: "Maintenance",
        key: "maintenanceLogId",
        render: record => {
          return record.maintenanceBase;
        }
      },
      {
        title: "Estimated Maintenance Date",
        key: "maintenanceDate",
        render: record => {
          return Boolean(record.maintenanceDate) ? moment(record.maintenanceDate).format("DD-MM-YYYY HH:mm") : "";
        }
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            rowKey="id"
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            pagination={false}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <DraggableModal
          centered
          width={1400}
          title={<FormattedMessage id="MaintenanceLogExpanded.Surrender" defaultMessage="Surrender" />}
          visible={this.state.showSurrenderModal}
          onCancel={() => {
            this.setState({
              showSurrenderModal: false
            });
          }}
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  showSurrenderModal: false
                });
              }}
            >
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
          ]}
          content={<SurrenderOfVehicleLog selectedRow={this.state?.selectedRow} random={this.state.random} />}
        />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceLogCallLog);
