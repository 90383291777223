import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { EditOutlined, Schedule } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { error, showSuccess } from "../../MessageHelper";
import { Table, Badge, Input, DatePicker, Col, Row, Button as AntButton, Form } from "antd";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import { MaintenanceLogExpanded } from "../equipment/MaintenanceLogExpanded";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import MaintenanceLogProcess from "../equipment/MaintenanceLogProcess";
import DraggableModal from "../../Components/DraggableModal";
import MaintenanceLogPartReplacementSchedule from "./MaintenanceLogPartReplacementSchedule";
import { FileSearchOutlined, ThunderboltOutlined } from "@ant-design/icons";

export class MaintenanceLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      showMaintenanceLogModal: false,
      controllerName: "maintenanceLog",
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      tableListKm: [],

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      bankAccountsLogSelectList: [],
      chequeHolderSelectList: [],
      signatureAuthoritySelectList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();
  formRefCurrentStatus = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {};

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  maintanancePlan = row => {
    this.setState({
      showMaintenanceLogModal: true,
      selectedEquipmentLog: row,
      random: Math.random()
    });
  };

  partReplacementSchedule = row => {
    this.setState({
      showPartReplacementScheduleModal: true,
      selectedEquipmentLog: [row],
      random: Math.random()
    });
  };

  lastKmHour = row => {
    this.setState(
      {
        showLastKmHourModal: true,
        id: row.id,
        random: Math.random()
      },
      async () => {
        var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getStatus/" + row.id);
        if (response.type === "ERROR") {
          error(response);
        }
        let data = response.data;
        this.formRefCurrentStatus.current.setFieldsValue({
          ...data,
          kmDate: Boolean(data.kmDate) ? moment(data.kmDate) : null
        });
      }
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/pagingEquipmentLog";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteEquipmentMaintanance/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Maintanance Plan",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.maintanancePlan.bind(this)
            },
            {
              name: "Part Replacement Schedule",
              icon: <Schedule fontSize="small" color="primary" />,
              actionClick: this.partReplacementSchedule.bind(this)
            },
            {
              name: "Last km/hour",
              icon: <ThunderboltOutlined fontSize="small" color="primary" />,
              actionClick: this.lastKmHour.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: "Equipment Type",
        key: "equipmentType",
        render: record => {
          return Boolean(record.equipmentType) ? record.equipmentType.name : "";
        }
      },
      {
        title: "Equipment Name",
        key: "id",
        render: record => {
          return record.eldenEquipmentTreeName;
        }
      },
      {
        title: "Equipment Code",
        key: "equipmentCode",
        render: record => {
          return record.equipmentCode;
        }
      },
      {
        title: "Department",
        key: "department",
        render: record => {
          return Boolean(record.department) ? record.department.departmentName : "";
        }
      },
      {
        title: "Operator",
        key: "operator",
        render: record => {
          return "";
        }
      },
      {
        title: "Coming Maintenance Date",
        key: "maintenanceStartDate",
        render: record => {
          return Boolean(record.maintenanceStartDate) ? moment(record.maintenanceStartDate).format("DD-MM-YYYY") : "";
        }
      },
      {
        title: "Maintenance Type",
        key: "maintenanceType",
        render: record => {
          return record.maintenanceType;
        }
      },
      {
        title: "Duration of Maintenance",
        key: "durationOfMaintenance",
        render: record => {
          return record.durationOfMaintenance;
        }
      },
      {
        title: "Maintenance Location",
        key: "maintenanceLocation",
        render: record => {
          return "";
        }
      },
      {
        title: "Past Maintenances Status",
        key: "pastMaintenancesStatus",
        render: record => {
          return "";
        }
      }
    ];

    const columnsKm = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="MaintenanceLog.Date" defaultMessage="Date" />,
        key: "date",
        render: record => {
          return Boolean(record.date) ? moment(record.date).format("DD-MM-YYYY HH:mm") : "";
        }
      },
      {
        title: <FormattedMessage id="MaintenanceLog.Action" defaultMessage="Action" />,
        key: "action",
        render: record => {
          return record.action;
        }
      },
      {
        title: <FormattedMessage id="MaintenanceLog.Km" defaultMessage="Km" />,
        key: "km",
        render: record => {
          return record.km;
        }
      },
      {
        title: <FormattedMessage id="MaintenanceLog.Hour" defaultMessage="Hour" />,
        key: "hour",
        render: record => {
          return record.hour;
        }
      }
    ];

    return (
      <Badge.Ribbon text="Maintenance Log" placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              rowKey="id"
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
              expandable={{
                expandedRowRender: record => <MaintenanceLogProcess equipmentLogId={record.id} random={Math.random()} />,
                rowExpandable: () => {
                  return true;
                }
              }}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          <DraggableModal
            centered
            width={1200}
            title={<FormattedMessage id="MaintenanceLogExpanded.MaintenancePlan" defaultMessage="Maintenance Plan" />}
            visible={this.state.showMaintenanceLogModal}
            onCancel={() => {
              this.setState(
                {
                  showMaintenanceLogModal: false
                },
                () => {
                  const { pagination } = this.state;
                  this.restartTable({ pagination });
                }
              );
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState(
                    {
                      showMaintenanceLogModal: false
                    },
                    () => {
                      const { pagination } = this.state;
                      this.restartTable({ pagination });
                    }
                  );
                }}
              >
                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
              </Button>
            ]}
            content={
              Boolean(this.state.selectedEquipmentLog) ? (
                <MaintenanceLogExpanded random={this.state.random} equipmentLogId={this.state.selectedEquipmentLog.id} />
              ) : (
                ""
              )
            }
          />

          <DraggableModal
            centered
            width={1200}
            title={<FormattedMessage id="MaintenanceLogExpanded.PartReplacementSchedule" defaultMessage="Part Replacement Schedule" />}
            visible={this.state.showPartReplacementScheduleModal}
            okButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState(
                {
                  showPartReplacementScheduleModal: false
                },
                () => {
                  const { pagination } = this.state;
                  this.restartTable({ pagination });
                }
              );
            }}
            content={
              Boolean(this.state.selectedEquipmentLog) && this.state.selectedEquipmentLog.length === 1 ? (
                <MaintenanceLogPartReplacementSchedule
                  random={this.state.random}
                  selectedEquipmentLog={this.state.selectedEquipmentLog}
                  close={() => {
                    this.setState(
                      {
                        showPartReplacementScheduleModal: false
                      },
                      () => {
                        const { pagination } = this.state;
                        this.restartTable({ pagination });
                      }
                    );
                  }}
                />
              ) : (
                ""
              )
            }
          />

          <DraggableModal
            centered
            width={600}
            title={<FormattedMessage id="MaintenanceLogExpanded.LastKmHour" defaultMessage="Last km/hour" />}
            visible={this.state.showLastKmHourModal}
            okButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState({
                showLastKmHourModal: false
              });
            }}
            content={
              <Form initialValues={{ remember: false }} ref={this.formRefCurrentStatus}>
                <>
                  <br></br>
                  <Form.Item {...layout} label={<FormattedMessage id="MaintenanceLog.Date" defaultMessage="Date" />} style={{ marginBottom: "5px" }}>
                    <Row gutter={[16, 16]}>
                      <Col md={22}>
                        <Form.Item name="kmDate">
                          <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime disabled />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <AntButton
                          style={{ border: "0px" }}
                          onClick={async () => {
                            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getKmDetail/" + this.state.id);
                            if (response.type === "ERROR") {
                              error(response);
                            }
                            this.setState({
                              tableListKm: response.data,
                              modalKmShow: true
                            });
                          }}
                          icon={<FileSearchOutlined fontSize="large" />}
                        ></AntButton>
                      </Col>
                    </Row>
                  </Form.Item>

                  <Form.Item {...layout} label={<FormattedMessage id="MaintenanceLog.Km" defaultMessage="Km" />} style={{ marginBottom: "5px" }}>
                    <Form.Item name="km">
                      <Input
                        style={{
                          width: "100%"
                        }}
                        disabled
                      />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item {...layout} label={<FormattedMessage id="MaintenanceLog.Hour" defaultMessage="Hour" />} style={{ marginBottom: "5px" }}>
                    <Form.Item name="hour">
                      <Input
                        style={{
                          width: "100%"
                        }}
                        disabled
                      />
                    </Form.Item>
                  </Form.Item>
                </>
              </Form>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EquipmentLogMain.Km" defaultMessage="Km" />}
            centered
            width={1200}
            open={this.state.modalKmShow}
            onCancel={() => this.setState({ modalKmShow: false })}
            okButtonProps={{ hidden: true }}
            onOk={() => this.saveType()}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={
              <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                <Table
                  bordered
                  rowKey="id"
                  size="small"
                  scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                  columns={columnsKm}
                  dataSource={this.state.tableListKm}
                  loading={this.state.loading}
                  pagination={false}
                />
              </div>
            }
          />
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceLog);
