import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Space, Collapse, Button as AntButton, Badge, Cascader, Tag, Modal as AntModal, Timeline } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { InputNumber } from "antd";
import { Select } from "antd";
import { DatePicker } from "antd";
import moment from "moment";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import {
  FilterOutlined, CloseCircleOutlined,
  RightCircleTwoTone,
  LeftCircleTwoTone,
} from "@ant-design/icons";
import RestorePageTwoTone from "@material-ui/icons/RestorePageTwoTone";
import { Tooltip, Skeleton } from "antd";

const { Option } = Select;
const { Panel } = Collapse;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (Boolean(node)) {
      var lineage = [];
      if (Boolean(node.lineage) && node.lineage.length > 0) {
        node.lineage.forEach(element => {
          if (!lineage.includes(element)) {
            lineage.push(element);
          }
        });
      }

      dataList.push({
        key: node.value,
        lineage: node.lineage,
        title: node.value
      });
    }
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class WorkOrder extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "WorkOrder",
      controllerName: "workOrder",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      currentStep: 0,
      workingStationItems: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };



  fillComboboxes = async () => {
    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/tree");
    if (responseTree.data.length !== 0) {
      generateList(responseTree.data);
      this.setState({
        workStationSelectItems: responseTree.data
      });
    }

    var responseprojectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/projectId");
    if (responseprojectId.type === "ERROR") {
      error(responseprojectId);
    }
    this.setState({
      projectIdSelectItems: Boolean(responseprojectId.data) ? responseprojectId.data : []
    });
    var responseactivityId = await handleRequest("GET", "/api/" + this.state.controllerName + "/activityId");
    if (responseactivityId.type === "ERROR") {
      error(responseactivityId);
    }
    this.setState({
      activityIdSelectItems: Boolean(responseactivityId.data) ? responseactivityId.data : []
    });

    var responseparentStation = await handleRequest("GET", "/api/" + this.state.controllerName + "/parentStation");
    if (responseparentStation.type === "ERROR") {
      error(responseparentStation);
    }
    this.setState({
      parentStationItems: Boolean(responseparentStation.data) ? responseparentStation.data : []
    });


  };

  fillStation = async (parentId) => {
    if (Boolean(parentId)) {
      var responseworkingStation = await handleRequest("GET", "/api/" + this.state.controllerName + "/workingStation/" + parentId);
      if (responseworkingStation.type === "ERROR") {
        error(responseworkingStation);
      }
      this.setState({
        workingStationItems: Boolean(responseworkingStation.data) ? responseworkingStation.data : []
      });
    } else {
      this.setState({
        workingStationItems: []
      });
    }
  }

  fillProduct = async (workOrderProjectId) => {
    if (Boolean(workOrderProjectId)) {
      var responseproductId = await handleRequest("GET", "/api/" + this.state.controllerName + "/productId/" + workOrderProjectId);
      if (responseproductId.type === "ERROR") {
        error(responseproductId);
      }
      this.setState({
        productIdSelectItems: Boolean(responseproductId.data) ? responseproductId.data : []
      });
    } else {
      this.setState({
        productIdSelectItems: []
      });
    }
  }

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    var parent = dataList.find(p => p.key === row.workStationId);
    let workStationId = [];

    if (Boolean(parent)) {
      workStationId = parent.lineage;
    }

    this.formRef.current.setFieldsValue({
      ...row,
      workStationId: workStationId,
      startDate: Boolean(row.startDate) ? moment(row.startDate) : null,
      endDate: Boolean(row.endDate) ? moment(row.endDate) : null,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      workStationId: row.workStationId,
      startDate: Boolean(row.startDate) ? moment(row.startDate) : null,
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  onCheckQuestionChange = (e, item) => {
    if (e.target.checked) {
      console.log(`checked question = ${item.id} - ${item.question}`);
    }
  };


  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  setOpenPanels = key => {
    this.fillStation(key);
    this.setState({
      activePanelKey: key

    });
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };





    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);



    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  renderElement = values => {
    return (
      Boolean(values) &&
      values.length > 0 &&
      values.map((item, index) => (
        <Panel header={item.value} key={item.key}>
          <>
            <>
              <Space>
                {this.state.workingStationItems.map((item, index) => (
                  <Button type="primary">
                    <span key={item.key}>{item.value}</span>
                  </Button>
                ))}
              </Space>


              <br />
            </>
          </>
        </Panel>
      ))
    );
  };




  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      workStationId: null,
      next: false,
      previous: false,
      startDate: null,
      modalStepDate: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      workStationId: Boolean(values.workStationId) ? values.workStationId[values.workStationId.length - 1] : null,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  getWorkStationStartDate = async () => {
    const newObj = {
      next: this.state.next,
      previous: this.state.previous,
      startDate: moment(this.state.modalStepDate),
      workStationId: this.state.workStationId,
    };
    let url = "/api/" + this.state.controllerName + "/manufacturingPlanningByDate";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        stepList: [],
        modalStepShow: false
      });
    } else {
      let list = [];
      if (Boolean(response.data) && response.data.length > 0) {
        list = response.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
      }
      this.setState({
        stepList: list,
        modalStepShow: true,
      });
    }
  };

  onStepClick = () => {
    let selectedWorkStation = this.state.workStationSelectItems.find(p => p.value === this.state.workStationId);
    this.setState({
      next: false,
      previous: false,
      selectedWorkStationName: Boolean(selectedWorkStation) ? selectedWorkStation.label : "",
      modalStepDate: this.state.startDate,
    }, () => {
      const { pagination } = this.state;
      this.getWorkStationStartDate({ pagination });
    });
  };

  onChangeStepsNext = () => {
    this.setState({
      next: true,
      previous: false,
      modalStepDate: moment(this.state.modalStepDate).add(1, 'day'),
    }, () => {
      const { pagination } = this.state;
      this.getWorkStationStartDate({ pagination });
    });
  };

  onChangeStepsPrevious = () => {
    this.setState({
      next: false,
      previous: true,
      modalStepDate: moment(this.state.modalStepDate).subtract(1, 'day'),
    }, () => {
      this.getWorkStationStartDate();
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="WorkOrder.woNo" defaultMessage="WO no" />,
        key: "woNo",
        render: record => {
          return record.woNo;
        }, ...getColumnFilter({
          inputName: "woNo", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="WorkOrder.plannedDate" defaultMessage="Planned date, time" />,
        key: "id",
        render: record => {
          if (Boolean(record.startDate) && Boolean(record.endDate)) {
            return <table className="table table-bordered table-sm">
              <tbody>
                <tr key={0}>
                  <td>
                    <Tag color="green">{moment(record.startDate).format("DD-MM-YYYY HH:mm")}</Tag>{" "}
                  </td>
                  <td>
                    <Tag color="red">{moment(record.endDate).format("DD-MM-YYYY HH:mm")}</Tag>{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          }
        },
      },
      {
        title: <FormattedMessage id="WorkOrder.Location" defaultMessage="Location" />,
        key: "workStationId",
        render: record => {
          return record.workStationName;
        }, ...getColumnFilter({
          inputName: "workStationId", inputType: "CASCADER",
          selectItems: Boolean(this.state["workStationSelectItems"]) ? this.state["workStationSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="WorkOrder.Project" defaultMessage="Project" />,
        key: "workOrderProjectId",
        render: record => {
          return record.workOrderProjectName;
        }, ...getColumnFilter({
          inputName: "workOrderProjectId", inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="WorkOrder.ActivityName" defaultMessage="Activity Name" />,
        key: "activityId",
        render: record => {
          return record.activityName;
        }, ...getColumnFilter({
          inputName: "activityId", inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="WorkOrder.Product" defaultMessage="Product" />,
        key: "productId",
        render: record => {
          return record.productName;
        }, ...getColumnFilter({
          inputName: "productName", inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="WorkOrder.Quantity" defaultMessage="Quantity" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        }, ...getColumnFilter({
          inputName: "quantity", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="WorkOrder.Operator" defaultMessage="Operator" />,
        key: "operatorId",
        render: record => {
          return record.operatorName;
        }, ...getColumnFilter({
          inputName: "operatorId", inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },

      {
        title: <FormattedMessage id="WorkOrder.ActualDate" defaultMessage="Actual Date" />,
        key: "id",
        render: record => {
          if (Boolean(record.actualStartDate) && Boolean(record.actualEndDate)) {
            return <table className="table table-bordered table-sm">
              <tbody>
                <tr key={0}>
                  <td>
                    <Tag color="green">{moment(record.actualStartDate).format("DD-MM-YYYY HH:mm")}</Tag>{" "}
                  </td>
                  <td>
                    <Tag color="red">{moment(record.actualEndDate).format("DD-MM-YYYY HH:mm")}</Tag>{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          }
        },
      },
      /*
      {
        title: <FormattedMessage id="WorkOrder.ActualDate" defaultMessage="Actual Date" />,
        key: "actualDate",
        render: record => {
          return record.actualDate;
        }, ...getColumnFilter({
          inputName: "actualDate", inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      }
      */
      {
        title: <FormattedMessage id="WorkOrder.actualQuantity" defaultMessage="Actual Quantity" />,
        key: "actualQuantity",
        render: record => {
          return record.actualQuantity;
        }, ...getColumnFilter({
          inputName: "actualQuantity", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
    ];




    const excelExport = (<>
      {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}         {this.state.exportClick ? (<ExcelFile filename="WorkOrder" hideElement={true}>             <ExcelSheet name="WorkOrder" data={this.state.tableList}>               <ExcelColumn label="Planned date, time" value="plannedDate" />
      <ExcelColumn label="Location" value="workStationName" />
      <ExcelColumn label="Project" value="workOrderProjectName" />
      <ExcelColumn label="activityName" value="Activity" />
      <ExcelColumn label="Product" value="productName" />
      <ExcelColumn label="Quantity" value="quantity" />
      <ExcelColumn label="operator" value="Operator" />
      <ExcelColumn label="actualDate" value="Actual Date, time" />
      <ExcelColumn label="Actual Quantity" value="actualQuantity" />
      <ExcelColumn label="WO no" value="woNo" />
    </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} 
    icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)} */}
    </>);
    return (
      <Badge.Ribbon text={<FormattedMessage id="workOrder.pageTitle" defaultMessage="Work Order" />} placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>             <Col md={21}></Col>             <Col md={1}>{excelExport}</Col>             <Col md={1}>               <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>             </Col>             <Col md={1}>               <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={this.onClickCreateNew} icon={<CreateIcon color="secondary" fontSize="large" />}               ></AntButton>               <AntButton style={{ border: "0px" }} hidden={this.state.hideInputs} onClick={this.onClickCreateNew} icon={<RemoveCircle color="error" fontSize="large" />}               ></AntButton>             </Col>           </Row>
            <div hidden={this.state.hideInputs}>

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="WorkOrder.Location" defaultMessage="Work Location" />}
                >
                  <Row>
                    <Col span={22}>
                      <Form.Item
                        name="workStationId"
                        rules={[
                          {
                            required: true,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Cascader changeOnSelect style={{ width: "100%" }}
                          onChange={value => {
                            this.setState({
                              workStationId: Boolean(value) && value.length > 0 ? value[value.length - 1] : -1,
                            });
                          }}
                          options={this.state.workStationSelectItems} />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      {Boolean(this.state.workStationId) && Boolean(this.state.startDate) &&
                        <AntButton
                          style={{ border: "0px" }}
                          onClick={this.onStepClick}
                          icon={<RestorePageTwoTone color="primary" fontSize="large" />}
                        ></AntButton>
                      }
                    </Col>
                  </Row>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="WorkOrder.startDate" defaultMessage="Start Date" />}
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker style={{ width: "100%" }}
                    onChange={async value => {
                      this.setState({
                        startDate: Boolean(value) ? value : null,
                      });
                    }}
                    format="DD-MM-YYYY HH:mm" showTime={{ defaultValue: moment("00:00:00", "HH:mm") }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="WorkOrder.endDate" defaultMessage="End Date" />}
                  name="endDate"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime={{ defaultValue: moment("00:00:00", "HH:mm") }} />
                </Form.Item>
              }
              <Form.Item
                {...layout}
                label={<FormattedMessage id="WorkOrder.workOrderProjectId" defaultMessage="Project" />}
                name="workOrderProjectId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  style={{ width: "100%" }}
                  showSearch
                  optionFilterProp="children"
                  onChange={value => {
                    this.fillProduct(value);
                  }}
                >
                  {Boolean(this.state["projectIdSelectItems"]) && this.state["projectIdSelectItems"].length > 0 &&
                    this.state["projectIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="WorkOrder.productId" defaultMessage="Product" />}
                name="productId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  style={{ width: "100%" }}
                  showSearch
                  optionFilterProp="children"
                >
                  {Boolean(this.state["productIdSelectItems"]) && this.state["productIdSelectItems"].length > 0 &&
                    this.state["productIdSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.renameText}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="WorkOrder.activityId" defaultMessage="Activity" />}
                name="activityId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  style={{ width: "100%" }}
                  showSearch
                  optionFilterProp="children"
                >
                  {Boolean(this.state["activityIdSelectItems"]) && this.state["activityIdSelectItems"].length > 0 &&
                    this.state["activityIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="WorkOrder.quantity" defaultMessage="Quantity" />}
                  name="quantity"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              }
              <div hidden={this.state.hideSave}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>                     <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">                       <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
              <div hidden={this.state.hideUpdate}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>                       <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />                     </Button>                   </Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">                       <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (<div className="alert alert-custom alert-light">                 <div className="alert-icon">                   <FilterOutlined />                 </div>                 <div className="alert-text">                   {this.state.searchCriteriaModels.map((val, i) => { return (<div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">                         <span className="mr-2">{val.label}</span>                         <strong className="mr-2">{val.type}</strong>                         <span className="mr-2">{val.operationValue}</span>                         <span className="mr-2">{val.showValue}</span>                         <span>                           <Tooltip title="Remove">                             <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />                           </Tooltip>                         </span>                       </div>); })}                 </div>                 <div className="alert-close">                   <Tooltip title="Remove All">                     <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.setState({ searchCriteriaModels: [] }, () => { this.filterChangeItem([]); })} />                   </Tooltip>                 </div>               </div>)}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">           <Table bordered scroll={{ x: 900, scrollToFirstRowOnChange: true }} columns={columns} dataSource={this.state.tableList} loading={this.state.loading} onChange={this.handleTableChange} pagination={this.state.pagination} />         </div>
          {Boolean(this.state.parentStationItems) && this.state.parentStationItems.length > 0 ? (
            <>
              <Collapse activeKey={this.state.activePanelKey} onChange={this.setOpenPanels} accordion>
                {this.renderElement(this.state.parentStationItems)}
              </Collapse>
            </>
          ) : (
            ""
          )}



          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>           <Modal.Header closeButton>             <Modal.Title id="contained-modal-title-vcenter">               <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />             </Modal.Title>           </Modal.Header>           <Modal.Body>             <p>               <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />             </p>           </Modal.Body>           <Modal.Footer>             <Button variant="secondary" onClick={this.showOrHideDeleteModal}>               <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />             </Button>             <Button variant="danger" onClick={this.delete}>               <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />             </Button>           </Modal.Footer>         </Modal>


          {/* STEP */}
          <AntModal
            open={this.state.modalStepShow}
            onCancel={() => {
              this.setState({ modalStepShow: false });
            }}
            title={Boolean(this.state.selectedWorkStationName) ? this.state.selectedWorkStationName : "Work Station"}
            width={900}
            centered
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalStepShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
          >
            <div className="card card-custom" style={{ padding: "0.1rem", minHeight: "100%" }}>
              <div className="card-body pt-4 custom-timeline pr-5 pl-5 mr-5 ml-5 align-content-center">
                <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />

                <Row>
                  <Col span={8}></Col>
                  <AntButton
                    style={{ marginLeft: "10px", border: "0px", alignContent: "left" }}
                    type="text"
                    onClick={this.onChangeStepsPrevious}
                    icon={<LeftCircleTwoTone style={{ fontSize: "32px" }} />}
                  />
                  <Col span={1}></Col>
                  <Col span={3}>
                    <Tag color="red"> {Boolean(this.state.modalStepDate) ? moment(this.state.modalStepDate).format("DD-MM-YYYY") : ""}</Tag>
                  </Col>
                  <Col span={1}></Col>
                  <AntButton
                    style={{ marginRight: "10px", border: "0px", alignContent: "right" }}
                    type="text"
                    onClick={this.onChangeStepsNext}
                    icon={<RightCircleTwoTone style={{ fontSize: "32px" }} />}
                  />
                </Row>
                <br />

                {/* <div className="card-body pt-4 custom-timeline pr-5 pl-5 mr-5 ml-5 align-content-center">
                  <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                  <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                  <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                  <Steps direction="vertical" className="pl-5-custom anticon-custom">
                    {Boolean(this.state.stepList) &&
                      this.state.stepList.length > 0 &&
                      this.state.stepList.map((item, index) => {
                        return <Step status="process" title={item.companyProjectName} icon={<LoadingOutlined />}
                          description={item.productName + " - " + item.processingQuantity}
                          subTitle={item.companyProjectName}
                        />
                      })}
                  </Steps>
                </div> */}

                <div className="card card-custom" style={{ minHeight: "100%", padding: "2rem" }}>
                  <Timeline mode="left">
                    {Boolean(this.state.stepList) &&
                      this.state.stepList.length > 0 &&
                      this.state.stepList.map((item) => {
                        return (
                          <Timeline.Item label={Boolean(item.startDate) && Boolean(item.endDate) ? moment(item.startDate).format("DD-MM-YYYY HH:mm") + " / " +
                            moment(item.endDate).format("DD-MM-YYYY HH:mm") : ""}
                            children={item.companyProjectName + " - " + item.productName + " - " + item.processingQuantity}>
                          </Timeline.Item>
                        );
                      })}
                  </Timeline>
                </div>

              </div>
            </div>
          </AntModal>

        </div>
      </Badge.Ribbon >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(WorkOrder);