import { connect } from "react-redux";
import React, { Component } from "react";
import { Col, Row, Tooltip, Button as AntButton, Badge, Form, Image, Space } from "antd";
import CreateIcon from "@material-ui/icons/AddCircle";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import DeleteIcon from "@material-ui/icons/Delete";
import { EditOutlined } from "@material-ui/icons";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import DraggableModal from "../../../Components/DraggableModal";
import EldenPresentationCreate from "./EldenPresentationCreate";
import moment from "moment";
import EditIcon from '@material-ui/icons/EditTwoTone';
import EldenPresentationDesign from "./EldenPresentationDesign";
import { CheckOutlined, ZoomInOutlined } from "@ant-design/icons";
import EldenPresentationPreview from "./EldenPresentationPreview";

export class EldenPresentation extends Component {
  constructor() {
    super();
    this.state = {
      formName: "EldenPresentation",
      controllerName: "eldenPresentation",
      modalDeleteShow: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      exportClick: false,
      exportList: [],
      lastUpdateDate: [],
      id: null,
      selectedRow: null,
      tableList: [],
      filePath: "",
      fileList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      textFormattingDetailId: null,
      excelData: [],
      searchCriteriaModels: [],
      nodes: [],
      inputTypeSelectItems: null,
      layoutSelectItems: null,
      methodSelectItems: null,
      details: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {

  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showInputModal = row => {
    this.setState({
      modalInputShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  save = async values => {
    const newItem = { ...values, id: this.state.id };
    console.log("RRR", this.state.details);
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.fillComboboxes();
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  edit = row => {
    console.log("RRR", row);
    document.getElementById("kt_scrolltop").click();

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      selectedRow: row,
      presentationId: row.id,
      modalPresentationShow: true,
      random: Math.random(),
    });
  };

  design = row => {
    this.setState({
      selectedRow: row,
      modalDesignShow: true,
      random: Math.random()
    });
  }

  preview = row => {
    this.setState({
      selectedRow: row,
      modalPreviewShow: true,
      random: Math.random()
    });
  }

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.fillComboboxes();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data && response.data.data) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      selectedRow: null,
      allCodes: "",
      details: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  addToFormulaList = index => {
    const { details } = this.state;
    let field = details[index];

    if (Boolean(field.codes)) {
      let object = {
        methodId: field.methodId,
        codes: field.codes,
        layouts: field.layouts,
        inputType: field.inputType,
        remarks: field.remarks,
        templateLocation: field.templateLocation
      };
      if (!Boolean(field.details)) {
        field.details = [];
      }
      object.indexOrder = Boolean(field.details) ? field.details.length + 1 : 0;
      field.details.push(object);

      let allCodes = this.getCodes(details);
      this.setState({ details, allCodes: allCodes });
    } else {
      showError("Code is required");
    }
  };

  removeFromFormulaList = index => {
    const { details } = this.state;
    let field = details[index];
    if (field.details && field.details.length > 0) {
      field.details.pop();
    }
    let allCodes = this.getCodes(details);
    this.setState({ details, allCodes: allCodes });
  };

  addFeature = () => {
    const { details } = this.state;
    let key = 0;
    if (Boolean(details) && details.length > 0) {
      key = details[details.length - 1].key + 1;
    }
    if (key === 0) {
      details.push({ key: key });
    } else details.push({ key: key });

    this.setState({ details });
  };

  removeFeature = index => {
    const { details } = this.state;
    details.splice(index, 1);

    let allCodes = this.getCodes(details);
    this.setState({ details, allCodes: allCodes });
  };

  getCodes(details) {
    let allCodes = "";
    if (Boolean(details) && details) {
      details.forEach(element => {
        if (Boolean(element.details) && element.details) {
          element.details.forEach(code => {
            if (Boolean(code.codes)) {
              allCodes += code.codes + " ";
            }
          });
          allCodes += "\r\n\r\n";
        }
      });
    }
    return allCodes;
  }

  handleExportExcel = async () => {
    const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/export");
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(response.data) && response.data.length !== 0) {
        response.data.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          exportList: response.data,
          exportClick: true
        });
      } else {
        this.setState({
          exportList: [],
          exportClick: true
        });
      }
    }
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            componentName: element["Component"],
            layoutName: element["Layout"],
            methodName: element["Method"],
            templateLocation: Boolean(element["Template Location"]) ? element["Template Location"] : null,
            inputType: Boolean(element["Input Type"]) ? element["Input Type"] : null,
            codes: element["Code"],
            remarks: element["Remarks"]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 8 }
    };

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="EldenPresentation.EldenPresentation" defaultMessage="Elden Presentation" />}
        placement="start"
        color="purple"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "3rem", minHeight: "100%" }}>
          <div className={`card card-custom`}>
            {/* Header */}
            <Row gutter={[16, 16]}>
              <Col md={23}></Col>
              <Col md={1}>
                <Tooltip title="Create">
                  <AntButton
                    style={{ border: "0px" }}
                    icon={<CreateIcon color="secondary" fontSize="large" />}
                    onClick={() => {
                      this.setState({ modalPresentationShow: true, random: Math.random() });
                    }}
                  ></AntButton>
                </Tooltip>
              </Col>
            </Row>

            {/* Body */}

            <div className="card-body pt-0">
              {Boolean(this.state.tableList) && this.state.tableList.length > 0
                && this.state.tableList.map(item => (
                  <>
                    <div className="mb-10">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-45 symbol-light mr-5">
                          <span className="symbol-label">
                            {Boolean(item.uploadPath) && <Image width={50} src={item.uploadPath} />}
                          </span>
                        </div>

                        <div className="d-flex flex-column flex-grow-1">
                          <a
                            href="#"
                            className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                          >
                            {item.name}
                          </a>
                          <span className="text-muted font-weight-bold">
                            {item.type}
                          </span>
                        </div>
                      </div>

                      <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                        {moment(item.date).format("DD-MM-YYYY")}
                      </p>

                      <Space>
                        <Tooltip title="Edit">
                          <EditIcon fontSize="default" color="primary" backgroundColor="black" onClick={() => this.edit(item)} />
                        </Tooltip>
                        <Tooltip title="Design">
                          <ZoomInOutlined fontSize="default" color="primary" backgroundColor="black" onClick={() => this.design(item)} />
                        </Tooltip>
                        <Tooltip title="Preview">
                          <CheckOutlined fontSize="default" color="primary" backgroundColor="black" onClick={() => this.preview(item)} />
                        </Tooltip>
                        <Tooltip title="Delete">
                          <DeleteIcon fontSize="small" color="error" onClick={() => this.deleteModal(item)} />
                        </Tooltip>
                      </Space>
                    </div>
                  </>
                ))}
            </div>
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <DraggableModal
            title={<FormattedMessage id="EldenPresentation.Presentation" defaultMessage="Presentation" />}
            open={this.state.modalPresentationShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ modalPresentationShow: false })}
            width={600}
            centered
            content={
              <EldenPresentationCreate random={this.state.random}
                id={this.state.id}
                selectedRow={this.state.selectedRow}
                close={() => {
                  this.setState({
                    modalPresentationShow: false, id: null
                  }, () => {
                    const { pagination } = this.state;
                    this.restartTable({ pagination });
                  });
                }} />
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenPresentation.Design" defaultMessage="Design" />}
            open={this.state.modalDesignShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ modalDesignShow: false })}
            width={800}
            centered
            content={
              <EldenPresentationDesign random={this.state.random} selectedRow={this.state.selectedRow} close={() => this.setState({ modalDesignShow: false })} />
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenPresentation.Preview" defaultMessage="Preview" />}
            open={this.state.modalPreviewShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ modalPreviewShow: false })}
            width={1400}
            centered
            content={
              <EldenPresentationPreview random={this.state.random} selectedRow={this.state.selectedRow} close={() => this.setState({ modalPreviewShow: false })} />
            }
          />
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EldenPresentation);