import { Form } from "antd";
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PrepareFormItemProps from "./ElementUtils";
const PhoneComponent = ({ field, layout }) => {
  return (
    <>
      <Form.Item {...layout} {...PrepareFormItemProps(field)}>
        <PhoneInput enableSearch country={field.country} placeholder={field.label} inputStyle={{ width: "100%" }} />
      </Form.Item>
    </>
  );
};

export default PhoneComponent;
