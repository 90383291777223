import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Form, Table, Input, Select, InputNumber, Switch, PageHeader, DatePicker, Cascader, Tabs, Image, Button as AntButton, Col, Row } from "antd";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import CustomInventoryMenu from "../CustomMenu/CustomInventoryMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import { Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { InventoryMaterialClient } from "./InventoryMaterialClient";
import { StockMovement } from "./StockMovement";
import { PropertyTabs } from "./PropertyTabs";
import { InventoryPacking } from "./InventoryPacking";
import "../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Option } = Select;

const itemList = [];
const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const generateItemList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    itemList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateItemList(node.children);
    }
  }
};

export class Inventory extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      currentItem: null,
      id: null,
      tableList: [],
      filePath: "",
      filePaths: [],
      fileList: [],
      itemNodes: null,
      currentInventoryId: null,
      currentMovementType: null,
      itemId: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }
  currencyCodeSelectItems = [
    { value: "DOLLAR", label: "$" },
    { value: "EURO", label: "€" },
    { value: "POUND", label: "£" },
    { value: "SR", label: "SR" }
  ];
  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    const responseItemTree = await handleRequest("GET", "/api/item/tree");

    if (responseItemTree.data.length !== 0) {
      console.log(responseItemTree.data);

      generateItemList(responseItemTree.data);
      this.setState({
        itemNodes: responseItemTree.data
      });
    }
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  setSelectedFiles = field => {
    let fileList = [];
    if (Boolean(field) && field.length > 0) {
      let files = field.split(",");
      for (let i = 0; i < files.length; i++) {
        fileList.push({
          uid: i,
          name: files[i],
          url: files[i],
          thumbUrl: files[i]
        });
      }
      return fileList;
    }
    return [];
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = this.setSelectedFiles(row.materialPhotoPaths);

    var item = itemList.find(p => p.key === row.itemId);
    let itemId = [];

    if (Boolean(item)) {
      itemId = item.lineage;
    }

    this.formRef.current.setFieldsValue({
      ...row,
      itemId: itemId
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      itemId: row.itemId,
      fileList: fileList,
      filePaths: Boolean(row.materialPhotoPaths) ? row.materialPhotoPaths.split(",") : [],
      currentInventoryName: row.item.name
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/inventory/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/inventory/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  getSelectedFiles = field => {
    if (Boolean(field) && Boolean(field.fileList) && field.fileList.length > 0) {
      let files = "";
      files = field.fileList[0].response.url;
      for (let i = 1; i < field.fileList.length; i++) {
        if (Boolean(field.fileList[i].response)) {
          files += "," + field.fileList[i].response.url;
        }
      }
      return files;
    }
    return field;
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      itemId: Boolean(values.itemId) && values.itemId.length > 0 ? values.itemId[values.itemId.length - 1] : null
    };
    const { filePaths } = this.state;

    // newItem.materialPhotoPaths = this.getSelectedFiles(
    //   newItem.materialPhotoPaths
    // );
    newItem.materialPhotoPaths = Boolean(filePaths) ? filePaths.toString() : "";

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/inventory", newItem);
    } else {
      response = await handleRequest("PUT", "/api/inventory/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/inventory/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };
  receiveModal = async row => {
    this.setState({
      currentInventoryId: row.id,
      currentMovementType: "RECEIVE",
      showStockMovementModal: true
    });
    console.log("receive", row);
  };
  releaseModal = async row => {
    this.setState({
      currentInventoryId: row.id,
      currentMovementType: "RELEASE",
      showStockMovementModal: true
    });
    console.log("release", row);
  };

  action = record => {
    return (
      <React.Fragment>
        <CustomInventoryMenu
          row={record}
          onDeleteRow={this.deleteModal.bind(this)}
          onEditRow={this.edit.bind(this)}
          onReceiveRow={this.receiveModal.bind(this)}
          onReleaseRow={this.releaseModal.bind(this)}
        />
      </React.Fragment>
    );
  };

  getImageGalleryFromUrls = (paths, galleryName) => {
    if (Boolean(paths) && paths.length > 0) {
      let pathList = paths.split(",");

      return (
        <>
          {Boolean(paths) && paths.length > 0 ? (
            <Button
              style={{
                color: "black",
                backgroundColor: "transparent", // Set the background color to transparent
                border: "2px navy-blue",
                width: "100%", // Set the button width to 100% of the cell width
                fontSize: "12px", // Adjust the font size as needed
                textAlign: "center"
              }}
              title="preview"
              width={200}
              preview={{
                visible: false
              }}
              src={pathList[0].trim()}
              onClick={() => this.setState({ [galleryName]: true })}
            >
              <FormattedMessage id="Inventory.Preview" defaultMessage="Preview" />
            </Button>
          ) : null}
          {Boolean(pathList) && pathList.length > 0 ? (
            <div style={{ display: "none" }}>
              <Image.PreviewGroup
                preview={{
                  visible: this.state[galleryName],
                  onVisibleChange: vis => this.setState({ [galleryName]: vis })
                }}
              >
                {pathList.map((field, i) => (
                  <Image key={i} src={field} />
                ))}
              </Image.PreviewGroup>
            </div>
          ) : null}
        </>
      );
    }
  };
  handleTabChange = key => {
    console.log(key);
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        width: 100,
        title: <FormattedMessage id="General.TitleActions" defaultMessage="Actions" />,
        render: this.action
      },

      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        width: 100,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="Inventory.itemId" defaultMessage="Item" />,
        key: "itemId",
        width: 100,
        render: record => {
          if (Boolean(record.item)) return record.item.name;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.itemId) ? this.state.pagination.itemId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  itemId: e.target.value,
                  itemIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.itemIdColor) ? this.state.pagination.itemIdColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="Inventory.materialPhotoPaths" defaultMessage="Material Photos" />,
        key: "materialPhotoPaths",
        width: 100,
        render: record => {
          return this.getImageGalleryFromUrls(record.materialPhotoPaths, "gallery" + record.id);
        }
      },
      {
        title: <FormattedMessage id="Inventory.materialCode" defaultMessage="Material Code" />,
        key: "materialCode",
        width: 100,
        render: record => {
          return record.materialCode;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.materialCode) ? this.state.pagination.materialCode : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  materialCode: e.target.value,
                  materialCodeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.materialCodeColor) ? this.state.pagination.materialCodeColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="Inventory.materialName" defaultMessage="Material Name" />,
        key: "materialName",
        width: 100,
        render: record => {
          return record.materialName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.materialName) ? this.state.pagination.materialName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  materialName: e.target.value,
                  materialNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.materialNameColor) ? this.state.pagination.materialNameColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="Inventory.arabicMaterialName" defaultMessage="Arabic Material Name" />,
        key: "arabicMaterialName",
        width: 100,
        render: record => {
          return record.arabicMaterialName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.arabicMaterialName) ? this.state.pagination.arabicMaterialName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  materialName: e.target.value,
                  materialNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.materialNameColor) ? this.state.pagination.materialNameColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="Inventory.quantity" defaultMessage="In Stock" />,
        key: "quantity",
        width: 100,
        render: record => {
          return record.quantity;
        }
      },
      {
        title: <FormattedMessage id="Inventory.minQuantity" defaultMessage="Min Q'ty" />,
        key: "minQuantity",
        width: 100,
        render: record => {
          return record.minQuantity;
        }
      },
      {
        title: <FormattedMessage id="Inventory.remarks" defaultMessage="Remarks" />,
        key: "remarks",
        width: 100,
        render: record => {
          return record.remarks;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.remarks) ? this.state.pagination.remarks : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  remarks: e.target.value,
                  remarksColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.remarksColor) ? this.state.pagination.remarksColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },

      {
        title: <FormattedMessage id="General.LastUpdate" defaultMessage="Last Update" />,
        width: 100,
        key: "lastUpdated",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];

      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: handleResize(index)
            })
          }))
        : Boolean(columns) &&
          columns.length > 0 &&
          columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: handleResize(index)
            })
          }));

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );
    const uploadProps = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <PageHeader className="site-page-header" title={"Inventory Log"} />
        <Tabs onChange={this.handleTabChange} type="card">
          <TabPane tab="Material Identification" key="1">
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
              <Row gutter={[16, 16]}>
                <Col md={21}></Col>
                <Col md={1}>{excelExport}</Col>
                <Col md={1}>
                  <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
                </Col>
                <Col md={1}>
                  <AntButton
                    style={{ border: "0px" }}
                    hidden={!this.state.hideInputs}
                    onClick={this.onClickCreateNew}
                    icon={<CreateIcon color="secondary" fontSize="large" />}
                  ></AntButton>
                  <AntButton
                    style={{ border: "0px" }}
                    hidden={this.state.hideInputs}
                    onClick={this.onClickCreateNew}
                    icon={<RemoveCircle color="error" fontSize="large" />}
                  ></AntButton>
                </Col>
              </Row>

              <div hidden={this.state.hideInputs}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Inventory.itemId" defaultMessage="Item" />}
                    name="itemId"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Cascader changeOnSelect style={{ width: "100%" }} options={this.state.itemNodes} />
                  </Form.Item>
                }{" "}
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Inventory.materialPhotoPaths" defaultMessage="Material Photos" />}
                    name="materialPhotoPaths"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Upload
                      {...uploadProps}
                      fileList={this.state.fileList}
                      showUploadList={true}
                      multiple
                      onChange={({ fileList }) => {
                        var filePaths = [];
                        const stateFileList = this.state.fileList;
                        if (fileList.length >= 1) {
                          for (let index = 0; index < fileList.length; index++) {
                            if (Boolean(fileList[index].response)) {
                              filePaths[index] = fileList[index].response.url;
                              fileList[index].url = fileList[index].response.url;

                              if (filePaths.length === fileList.length && fileList.length > stateFileList.length)
                                showSuccess("Files uploaded successfully");
                            } else if (Boolean(fileList[index].url)) {
                              filePaths[index] = fileList[index].url;
                            }
                          }
                        }
                        this.setState({
                          fileList: fileList,
                          filePaths: filePaths
                        });
                      }}
                      beforeUpload={fileList => {
                        return true;
                      }}
                      onRemove={({ fileList }) => {}}
                    >
                      <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                        <UploadOutlined />
                        <FormattedMessage id="General.Upload" defaultMessage="Upload" />,
                      </AntButton>
                    </Upload>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Inventory.materialCode" defaultMessage="Material Code" />}
                    name="materialCode"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Inventory.materialName" defaultMessage="Material Name" />}
                    name="materialName"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Inventory.arabicMaterialName" defaultMessage="Arabic Material Name" />}
                    name="arabicMaterialName"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input placeholder="Arabic Material Name" style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Inventory.quantity" defaultMessage="In Stock" />}
                    name="quantity"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <InputNumber min={0} style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Inventory.minQuantity" defaultMessage="Min. Quantity" />}
                    name="minQuantity"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <InputNumber min={0} style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item {...layout} label={<FormattedMessage id="Inventory.buyingPrice" defaultMessage="Buying Price" />}>
                    <Row gutter={8}>
                      <Col span={8}>
                        <Form.Item name="buyingPrice">
                          <InputNumber min={0} placeholder="Buying Price" style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={16}>
                        <Form.Item
                          label={<FormattedMessage id="Inventory.buyingPriceIncludeVat" defaultMessage="Include Vat?" />}
                          name="buyingPriceIncludeVat"
                          valuePropName="checked"
                        >
                          <Switch />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                }
                {
                  <Form.Item {...layout} label={<FormattedMessage id="Inventory.sellingPrice" defaultMessage="Selling Price" />}>
                    <Row gutter={8}>
                      <Col span={8}>
                        {" "}
                        <Form.Item name="sellingPrice">
                          <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={16}>
                        <Form.Item
                          label={<FormattedMessage id="Inventory.sellingPriceIncludeVat" defaultMessage="Include Vat?" />}
                          name="sellingPriceIncludeVat"
                          valuePropName="checked"
                        >
                          <Switch />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                }{" "}
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="InventoryProduct.currencyCode" defaultMessage="Currency" />}
                  name="currencyCode"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select allowClear>
                    {Boolean(this.currencyCodeSelectItems) &&
                      this.currencyCodeSelectItems.map(i => (
                        <Option key={i.value} value={i.value}>
                          {i.label}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Inventory.remarks" defaultMessage="Remarks" />}
                    name="remarks"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                <div hidden={this.state.hideSave}>
                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                          <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </div>
                <div hidden={this.state.hideUpdate}>
                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                          <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                        </Button>
                      </Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                          <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </div>
              </div>
            </Form>
            <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
              <Table
                bordered
                rowKey="id"
                scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                components={{
                  header: {
                    cell: ResizableTitle
                  }
                }}
                columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
                dataSource={this.state.tableList}
                loading={this.state.loading}
                onChange={this.handleTableChange}
                pagination={this.state.pagination}
              />
            </div>
          </TabPane>
          <TabPane tab={<FormattedMessage id="Inventory.itemProperties" defaultMessage="Item Properties" />} key="2" disabled={this.state.id == null}>
            <PropertyTabs inventoryId={this.state.id} itemId={this.state.itemId}></PropertyTabs>
          </TabPane>
          <TabPane
            tab={<FormattedMessage id="Inventory.ClientsMaterialInformation " defaultMessage="Client's Material Information" />}
            key="3"
            disabled={this.state.id == null}
          >
            <InventoryMaterialClient inventoryId={this.state.id} inventoryName={this.state.currentInventoryName} />
          </TabPane>

          <TabPane tab={<FormattedMessage id="Inventory.Packing" defaultMessage="Packing" />} key="4" disabled={this.state.id == null}>
            <InventoryPacking inventoryId={this.state.id} inventoryName={this.state.currentInventoryName} />
          </TabPane>
          <TabPane tab={<FormattedMessage id="Inventory.Handling" defaultMessage="Handling" />} key="5" disabled={this.state.id == null}></TabPane>
          <TabPane
            tab={<FormattedMessage id="Inventory.Transportation" defaultMessage="Transportation" />}
            key="6"
            disabled={this.state.id == null}
          ></TabPane>
          <TabPane tab={<FormattedMessage id="Inventory.Stocking" defaultMessage="Stocking" />} key="7" disabled={this.state.id == null}></TabPane>

          <TabPane
            tab={<FormattedMessage id="Inventory.Maintenance" defaultMessage="Maintenance" />}
            key="8"
            disabled={this.state.id == null}
          ></TabPane>
          <TabPane
            tab={<FormattedMessage id="Inventory.AccessoriesAndSpareParts" defaultMessage="Accessories and Spare Parts" />}
            key="9"
            disabled={this.state.id == null}
          ></TabPane>
          <TabPane tab={<FormattedMessage id="Inventory.Warranty" defaultMessage="Warranty" />} key="10" disabled={this.state.id == null}></TabPane>
        </Tabs>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showStockMovementModal}
          onHide={() => {
            this.setState({
              showStockMovementModal: false
            });
          }}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="modal-90w"
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="Inventory.StockMovement" defaultMessage=" Stock Movement" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <StockMovement inventoryId={this.state.currentInventoryId} movementType={this.state.currentMovementType} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  showStockMovementModal: false
                });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
