import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import {
  Form,
  Table,
  Input,
  Select,
  InputNumber,
  Divider,
  DatePicker,
  Button as AntButton,
  Col,
  Row,
  Space,
  Tag,
  Tooltip,
  Modal as AntModal
} from "antd";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import { PlusOutlined } from "@ant-design/icons";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import HistoryOutlined from "@material-ui/icons/HistoryOutlined";
import { evaluate, round } from "mathjs";

const { RangePicker } = DatePicker;
const { Option } = Select;

function createOperator(operator) {
  console.log(operator);
  switch (operator) {
    case "add":
      return "+";
    case "subtract":
      return "-";
    case "multiply":
      return "*";
    case "divide":
      return "/";
    case "sqrt":
      return "sqrt";
    case "pow":
      return "^";
    case "open_parenthesis":
      return "(";
    case "close_parenthesis":
      return ")";
    default:
      return "";
  }
}

export class ItemBreakdownQuantity extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      selectedItemBreakdown: null,
      richSearchModal: false,

      id: null,
      selectedStockCard: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      codeSelectItems: null,
      disciplineIdSelectItems: null,
      locationIdSelectItems: null,
      tableListRoot: [],
      tableListLastPurchase: [],
      showLastPurchaseModal: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.setState(
      {
        selectedItemBreakdown: Boolean(this.props) && Boolean(this.props.selectedItemBreakdown) ? this.props.selectedItemBreakdown : null
      },
      this.initialize
    );
  };

  initialize = async () => {
    //const { pagination } = this.state;
    //this.restartTable({ pagination });
    await this.fillComboboxes();
    this.restartTableRoot();
  };

  fillComboboxes = async () => {
    //var responsecode = await handleRequest("GET", "/api/itemBreakdownQuantity/codeSelectItems");
    const { selectedItemBreakdown } = this.state;
    let obj = {
      materialTypeId: Boolean(selectedItemBreakdown) ? selectedItemBreakdown.materialTypeId : null,
      categoryId: Boolean(selectedItemBreakdown) ? selectedItemBreakdown.itemGroupId : null,
      descriptionId: Boolean(selectedItemBreakdown) ? selectedItemBreakdown.itemDescriptionId : null
    };

    let responsecode = await handleRequest("POST", "/api/stockCard/controlCode", obj);
    if (responsecode.type === "ERROR") {
      error(responsecode);
    } else {
      this.setState({
        codeSelectItems: Boolean(responsecode.data) ? responsecode.data : []
      });
    }

    let responseItemDescription = await handleRequest("GET", "/api/eldenItem/" + obj.descriptionId);

    if (responseItemDescription.type === "ERROR") {
      error(responseItemDescription);
    } else {
      let itemDescription = Boolean(responseItemDescription.data) ? responseItemDescription.data : [];
      this.setState({
        itemDescription: itemDescription
      });

      if (Boolean(itemDescription.itemDimension) && itemDescription.itemDimension.length > 0) {
        itemDescription.itemDimension.forEach(element => {
          this.fillCode("StockCard" + element.itemDefinition);
          this.formRef.current.setFieldsValue({
            [element.itemDefinition]: null,
            [element.itemDefinition + "Unit"]: null
          });
        });
      }
      if (Boolean(itemDescription.itemCodeCriterias) && itemDescription.itemCodeCriterias.length > 0) {
        itemDescription.itemCodeCriterias.forEach(element => {
          this.fillCode("StockCard" + element.itemDefinition);
          this.formRef.current.setFieldsValue({
            [element.itemDefinition]: null
          });
        });
      }
      let residueOfDimensions = [];
      if (Boolean(itemDescription.dimensionList) && itemDescription.dimensionList.length > 0) {
        itemDescription.dimensionList.forEach(element => {
          if (Boolean(itemDescription.itemDimension) && itemDescription.itemDimension.length > 0) {
            let f = itemDescription.itemDimension.find(x => x.itemDefinition === element.definition);
            if (!Boolean(f)) {
              residueOfDimensions.push({
                key: element.id,
                definition: element.definition,
                value: null,
                itemDimensionId: element.id,
                unitId: null,
                units: element.unitType.units
              });
            }
          } else {
            residueOfDimensions.push({
              key: element.id,
              definition: element.definition,
              value: null,
              itemDimensionId: element.id,
              unitId: null,
              units: element.unitType.units
            });
          }
        });
      }
      console.log("residueOfDimensions", residueOfDimensions);

      this.setState({
        residueOfDimensions: residueOfDimensions
      });
      this.formRef.current.setFieldsValue({
        residueOfDimensions: residueOfDimensions
      });
    }

    var responsedisciplineId = await handleRequest("GET", "/api/codes/type/disciplineId");
    if (responsedisciplineId.type === "ERROR") {
      error(responsedisciplineId);
    } else {
      this.setState({
        disciplineIdSelectItems: Boolean(responsedisciplineId.data) ? responsedisciplineId.data : []
      });
    }
    var responselocationId = await handleRequest("GET", "/api/codes/type/locationId");
    if (responselocationId.type === "ERROR") {
      error(responselocationId);
    } else {
      this.setState({
        locationIdSelectItems: Boolean(responselocationId.data) ? responselocationId.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      selectedStockCard: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = async row => {
    document.getElementById("kt_scrolltop").click();
    if (Boolean(row.stockCardId)) {
      let response = await handleRequest("GET", "/api/stockCard/" + row.stockCardId);

      if (response.type === "ERROR") {
        error(response);
      } else {
        let stockCard = Boolean(response.data) ? response.data : [];
        this.setState(
          {
            selectedStockCard: stockCard
          },
          this.fillPurchasingCriterias
        );

        if (Boolean(stockCard.itemDimension) && stockCard.itemDimension.length > 0) {
          stockCard.itemDimension.forEach(element => {
            this.formRef.current.setFieldsValue({
              [element.itemDefinition]: element.codeId,
              [element.itemDefinition + "Unit"]: element.unitId
            });
          });
        }
        if (Boolean(stockCard.itemCodeCriterias) && stockCard.itemCodeCriterias.length > 0) {
          stockCard.itemCodeCriterias.forEach(element => {
            this.formRef.current.setFieldsValue({
              [element.itemDefinition]: element.codeId
            });
          });
        }
      }
    }

    let residueOfDimensions = [];

    if (Boolean(row.residueOfDimensions) && row.residueOfDimensions.length > 0) {
      row.residueOfDimensions.forEach(element => {
        residueOfDimensions.push({
          id: element.id,
          key: element.id,
          definition: element.itemDimension.definition,
          value: element.value,
          itemDimensionId: element.itemDimensionId,
          unitId: element.unitId,
          units: element.itemDimension.unitType.units
        });
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      deliveryDate: Boolean(row.deliveryDate) ? moment(row.deliveryDate) : null,
      weight: this.calculateWeight(row)
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      residueOfDimensions: residueOfDimensions,
      id: row.id
    });

    setTimeout(() => {
      const { purchasingCriteriaSelectItems } = this.state;
      const selectedPurchasingCriteria = purchasingCriteriaSelectItems.find(i => i.key === row.purchasingCriteriaId);
      this.setState({
        selectedPurchasingCriteria
      });
    }, 1500);
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/itemBreakdownQuantity/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      //const { pagination } = this.state;
      //this.restartTable({ pagination });
      this.restartTableRoot();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTableRoot = async () => {
    this.setState({ loading: true });
    const newObj = { itemBreakdownId: this.state.selectedItemBreakdown.id };
    let url = "/api/itemBreakdownQuantity/list";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableListRoot: []
      });
    } else {
      let list = response.data;
      console.log(list);
      if (Boolean(list) && list.length > 0) {
        list.forEach(element => {
          list.forEach((element, index) => {
            element.index = index + 1;
            element.key = index;
            if (element.subList && element.subList.length > 0) {
              element.subList.forEach((subElement, subIndex) => {
                subElement.index = index + 1;
                subElement.key = subElement.id;
              });
            }
          });
          this.setState({
            loading: false,
            tableListRoot: list
          });
        });
      } else {
        this.setState({
          loading: false,
          tableListRoot: []
        });
      }
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/itemBreakdownQuantity/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = { ...values, id: this.state.id };
    const { selectedItemBreakdown, residueOfDimensions } = this.state;
    newItem.itemBreakdownId = Boolean(selectedItemBreakdown) ? selectedItemBreakdown.id : null;
    newItem.stockCardId = Boolean(this.state.selectedStockCard) ? this.state.selectedStockCard.id : null;

    if (Boolean(residueOfDimensions)) {
      newItem.residueOfDimensions = residueOfDimensions;
    } else {
      newItem.residueOfDimensions = [];
    }

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/itemBreakdownQuantity", newItem);
    } else {
      response = await handleRequest("PUT", "/api/itemBreakdownQuantity/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      // const { pagination } = this.state;
      //  this.restartTable({ pagination });
      this.restartTableRoot();
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    const { selectedItemBreakdown } = this.state;
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      itemBreakdownId: Boolean(selectedItemBreakdown) ? selectedItemBreakdown.id : null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  fillPurchasingCriterias = async () => {
    const { selectedStockCard } = this.state;
    let responsePurchaseCriteria = await handleRequest("GET", "/api/itemBreakdownQuantity/purchasingcriterias/" + selectedStockCard.id);
    if (responsePurchaseCriteria.type === "ERROR") {
      error(responsePurchaseCriteria);
    } else {
      this.setState({
        purchasingCriteriaSelectItems: Boolean(responsePurchaseCriteria.data) ? responsePurchaseCriteria.data : []
      });
    }
  };

  calculateWeight = row => {
    const { itemDescription } = this.state;

    if (Boolean(itemDescription) && Boolean(itemDescription.features) && itemDescription.features.length > 0) {
      let weightFeature = itemDescription.features[0];
      if (weightFeature.itemFeatureFormula && weightFeature.itemFeatureFormula.length > 0) {
        let formulaText = "";
        console.log(weightFeature);
        weightFeature.itemFeatureFormula.forEach((element, index) => {
          let constant = Boolean(element.constant)
            ? element.constant
            : Boolean(element.dimensionAbbreviation)
            ? this.getDimensionValue(element.dimension, element.dimensionId, row)
            : Boolean(element.numberConstant)
            ? element.numberConstant
            : "";
          let operator = Boolean(element.operator) ? createOperator(element.operator) : "";
          formulaText += constant + operator;
        });

        console.log("Formül", formulaText);

        if (!formulaText.includes("DIMENSION_NOT_FOUND")) {
          let evaluatedValue = evaluate(formulaText);
          let result = round(evaluatedValue, 2);

          if (row) return result;
          else
            this.formRef.current.setFieldsValue({
              weight: result
            });
        }

        return "";
      }
    }
  };

  getDimensionValue = (dimension, dimensionId, row) => {
    const { residueOfDimensions, selectedStockCard } = this.state;
    let dimensionValue = "DIMENSION_NOT_FOUND";
    let stockCard = { ...selectedStockCard };
    let rdo = residueOfDimensions;
    if (row) {
      stockCard = this.state.codeSelectItems.find(i => i.code === row.code);
      rdo = row.residueOfDimensions;
    }

    if (Boolean(rdo) && rdo.length > 0) {
      rdo.forEach(element => {
        if (element.itemDimensionId === dimensionId) {
          if (Boolean(element.value)) {
            dimensionValue = element.value;
          }
        }
      });
    }

    if (dimensionValue === "DIMENSION_NOT_FOUND" && stockCard && stockCard.itemDimension && stockCard.itemDimension.length > 0) {
      stockCard.itemDimension.forEach(element => {
        if (element.itemDefinition === dimension) {
          let code = Boolean(this.state["StockCard" + element.itemDefinition + "SelectItems"])
            ? this.state["StockCard" + element.itemDefinition + "SelectItems"].find(x => x.id === element.codeId)
            : null;
          dimensionValue = Boolean(code) ? code.name : "DIMENSION_NOT_FOUND";
        }
      });
    }

    return dimensionValue;
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/itemBreakdownQuantity/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            //const { pagination } = this.state;
            // this.restartTable({ pagination });
            this.restartTableRoot();
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  sendSelected = async () => {
    const { selectedRowKeys, selectedItemBreakdown } = this.state;
    console.log(selectedRowKeys);
    var response = await handleRequest("POST", "/api/itemBreakdown/sendSelectedList/" + selectedItemBreakdown.id, selectedRowKeys);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTableRoot();
      showSuccess();
    }
    this.setState({
      selectedRowKeys: []
    });
  };

  openRichSearch = () => {
    this.setState({
      richSearchModal: true,
      tempCodeSelectItems: this.state.codeSelectItems
    });
  };

  openLastPurchaseModal = async () => {
    const { selectedStockCard } = this.state;
    if (!Boolean(selectedStockCard)) {
      showWarning("Please select a stock card code");
      return;
    }

    var response = await handleRequest("GET", "/api/stockCard/lastPurchaseList/" + selectedStockCard.code + "/" + 5);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        tableListLastPurchase: response.data,
        showLastPurchaseModal: true
      });
    }
  };

  filterOnCriterias = async () => {
    const { codeSelectItems, itemDescription } = this.state;

    let temp = [...codeSelectItems];

    if (Boolean(itemDescription) && Boolean(itemDescription.itemDimension) && itemDescription.itemDimension.length > 0) {
      itemDescription.itemDimension.forEach(element => {
        if (Boolean(element.codeId)) temp = temp.filter(p => p.itemDimension.findIndex(u => u.codeId === element.codeId) >= 0);
      });
    }

    if (Boolean(itemDescription) && Boolean(itemDescription.itemCodeCriterias) && itemDescription.itemCodeCriterias.length > 0) {
      itemDescription.itemCodeCriterias.forEach(element => {
        if (Boolean(element.codeId)) temp = temp.filter(p => p.itemCodeCriterias.findIndex(u => u.codeId === element.codeId) >= 0);
      });
    }

    this.setState({
      tempCodeSelectItems: temp
    });

    if (Boolean(temp) && temp.length === 1) {
      this.setState({
        selectedStockCard: temp[0]
      });
    } else
      this.setState({
        selectedStockCard: null
      });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideSelectAll: true,
      renderCell: (checked, record, index, originNode) => {
        if (!Boolean(record.status)) return originNode;
        else if (record.status === "NotSent") return originNode;
        else return null;
      }
    };

    const columnsLastPurchase = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.vendor" defaultMessage="Vendor" />,
        key: "vendor",
        render: record => {
          return record.vendor;
        }
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.quantity" defaultMessage="Quantity" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        }
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.unitPrice" defaultMessage="Unit Price" />,
        key: "unitPrice",
        render: record => {
          return record.unitPrice;
        }
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.unitPrice" defaultMessage="Planned Delivery Date" />,
        key: "planedDeliveryDate",
        render: record => {
          if (record.planedDeliveryDate) {
            return moment(record.planedDeliveryDate).format("DD-MM-YYYY");
          }
        }
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.unitPrice" defaultMessage="Delivery Date" />,
        key: "deliveryDate",
        render: record => {
          if (record.deliveryDate) {
            return moment(record.deliveryDate).format("DD-MM-YYYY");
          }
        }
      }
    ];

    const columnsRoot = [
      {
        key: "key",
        title: ""
      },

      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.code" defaultMessage="Code" />,
        key: "code",
        render: record => {
          return record.code;
        }
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.quantity" defaultMessage="Quantity" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        }
      }
    ];

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },

      {
        title: <FormattedMessage id="ItemBreakdownQuantity.code" defaultMessage="Code" />,
        key: "code",
        render: record => {
          return record.code;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.code) ? this.state.pagination.code : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  code: e.target.value,
                  codeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.codeColor) ? this.state.pagination.codeColor : "#bfbfbf" }} />
        ),

        sorter: true
      },

      {
        title: "Status",
        key: "status",
        render: (value, row, index) => {
          if (!Boolean(row.status)) return <Tag color="red">Not Sent</Tag>;
          else if (row.status === "NotSent") return <Tag color="red">{"Not Sent"}</Tag>;
          else return <Tag color="green">{"Sent"}</Tag>;
        }
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.ItemDefinition" defaultMessage="Item Definition" />,
        key: "code",
        render: record => {
          if (Boolean(record.itemBreakdown) && Boolean(record.itemBreakdown.itemDescription)) {
            return record.itemBreakdown.itemDescription.name;
          }
        }
      },
      {
        title: "Dimensions",
        key: "idimensions",
        render: (value, row, index) => {
          return row.allDimensions;
        }
      },
      {
        title: "Quantity Unit",
        key: "purchasingCriteria",
        render: (value, row, index) => {
          if (Boolean(row.purchasingCriteria) && Boolean(row.purchasingCriteria.purchasingCriteria) && Boolean(row.purchasingCriteria.unit)) {
            return row.purchasingCriteria.purchasingCriteria.name + " - " + row.purchasingCriteria.unit.description;
          }
        }
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.weight" defaultMessage="Weight" />,
        key: "weight",
        render: record => {
          return this.calculateWeight(record);
        }
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.quantity" defaultMessage="Quantity" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.quantity) ? this.state.pagination.quantity : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  quantity: e.target.value,
                  quantityColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.quantityColor) ? this.state.pagination.quantityColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.disciplineId" defaultMessage="Discipline" />,
        key: "disciplineId",
        render: record => {
          if (Boolean(record.discipline)) {
            return record.discipline.name;
          }
          //return record.disciplineId;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.disciplineId) ? this.state.pagination.disciplineId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  disciplineId: e.target.value,
                  disciplineIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.disciplineIdColor) ? this.state.pagination.disciplineIdColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.locationId" defaultMessage="Location" />,
        key: "locationId",
        render: record => {
          if (Boolean(record.location)) {
            return record.location.name;
          }
          //return record.locationId;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.locationId) ? this.state.pagination.locationId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  locationId: e.target.value,
                  locationIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.locationIdColor) ? this.state.pagination.locationIdColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.deliveryDate" defaultMessage="DeliveryDate" />,
        key: "deliveryDate",
        render: record => {
          if (Boolean(record.deliveryDate)) {
            return moment(record.deliveryDate).format("DD-MM-YYYY");
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.deliveryDate) ? this.state.pagination.deliveryDate : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  deliveryDate: e.target.value,
                  deliveryDateColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.deliveryDateColor) ? this.state.pagination.deliveryDateColor : "#bfbfbf" }} />
        ),

        sorter: true
      },

      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
            <Col md={23}></Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item {...layout} label={<FormattedMessage id="ItemBreakdownQuantity.codeName" defaultMessage="Name/Code" />}>
                <Row>
                  <Col span={18}>
                    <Form.Item
                      name="code"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        placeholder="Code"
                        onChange={value => {
                          const { codeSelectItems } = this.state;
                          if (Boolean(value)) {
                            let fObj = codeSelectItems.find(i => i.code === value);
                            console.log("firstObj", fObj);
                            if (Boolean(fObj)) {
                              if (Boolean(fObj.itemCodeCriterias) && fObj.itemCodeCriterias.length > 0) {
                                fObj.itemCodeCriterias.forEach(element => {
                                  this.formRef.current.setFieldsValue({
                                    [element.itemDefinition]: element.codeId
                                  });
                                });
                              }

                              if (Boolean(fObj.itemDimension) && fObj.itemDimension.length > 0) {
                                fObj.itemDimension.forEach(element => {
                                  this.formRef.current.setFieldsValue({
                                    [element.itemDefinition]: element.codeId,
                                    [element.itemDefinition + "Unit"]: element.unitId
                                  });
                                });
                              }
                            }
                            this.setState(
                              {
                                selectedStockCard: fObj
                              },
                              this.fillPurchasingCriterias
                            );
                          } else {
                            this.formRef.current.resetFields();
                            this.setState({
                              selectedStockCard: null
                            });
                          }
                        }}
                      >
                        <Option key={null} value={null}>
                          Select
                        </Option>
                        {Boolean(this.state["codeSelectItems"]) &&
                          this.state["codeSelectItems"].map(i => (
                            <Option key={i.code} value={i.code}>
                              {i.renameText != null ? i.renameText + " / " + i.code : i.code}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={2}>
                    {
                      <Tooltip title="Rich Search">
                        <AntButton
                          style={{ border: "0px" }}
                          onClick={this.openRichSearch}
                          icon={<FindInPageIcon color="secondary" fontSize="large" />}
                        ></AntButton>
                      </Tooltip>
                    }
                  </Col>
                  <Col span={1}></Col>
                  <Col span={2}>
                    {
                      <Tooltip title="Last Purchases">
                        <AntButton
                          style={{ border: "0px" }}
                          onClick={this.openLastPurchaseModal}
                          icon={<HistoryOutlined color="secondary" fontSize="large" />}
                        ></AntButton>
                      </Tooltip>
                    }
                  </Col>
                </Row>
              </Form.Item>
            }
            {Boolean(this.state.itemDescription) &&
              Boolean(this.state.itemDescription.itemCodeCriterias) &&
              this.state.itemDescription.itemCodeCriterias.length > 0 &&
              this.state.itemDescription.itemCodeCriterias.map(element => (
                <Form.Item
                  {...layout}
                  label={element.itemDefinition}
                  name={element.itemDefinition}
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select disabled showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                    {Boolean(this.state["StockCard" + element.itemDefinition + "SelectItems"]) &&
                      this.state["StockCard" + element.itemDefinition + "SelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              ))}
            {Boolean(this.state.itemDescription) &&
              Boolean(this.state.itemDescription.itemDimension) &&
              this.state.itemDescription.itemDimension.length > 0 &&
              this.state.itemDescription.itemDimension.map(element => (
                <Form.Item
                  {...layout}
                  label={element.itemDefinition}
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name={element.itemDefinition}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select disabled showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                          {Boolean(this.state["StockCard" + element.itemDefinition + "SelectItems"]) &&
                            this.state["StockCard" + element.itemDefinition + "SelectItems"].map(i => (
                              <Option key={i.id} value={i.id}>
                                {i.name}
                              </Option>
                            ))}{" "}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={element.itemDefinition + "Unit"}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select disabled showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                          {element.units &&
                            element.units.map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}{" "}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              ))}
            {Boolean(this.state.residueOfDimensions) &&
              this.state.residueOfDimensions.length > 0 &&
              this.state.residueOfDimensions.map((dimension, index) => {
                return (
                  <Form.Item key={index} {...layout} label={dimension.definition}>
                    <Row>
                      <Col span={12}>
                        <InputNumber
                          placeholder={dimension.definition}
                          style={{ width: "100%" }}
                          min={0}
                          value={dimension.value}
                          onChange={value => {
                            let list = this.state.residueOfDimensions;

                            list[index].value = value;

                            this.setState(
                              {
                                residueOfDimensions: list
                              },
                              this.calculateWeight
                            );
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <Select
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          placeholder="Unit"
                          value={dimension.unitId}
                          onChange={value => {
                            let list = this.state.residueOfDimensions;

                            list[index].unitId = value;

                            this.setState({
                              residueOfDimensions: list
                            });
                          }}
                        >
                          {dimension.units &&
                            dimension.units.map(i => (
                              <Option key={i.id} value={i.id}>
                                {i.description}
                              </Option>
                            ))}{" "}
                        </Select>
                      </Col>
                    </Row>
                  </Form.Item>
                );
              })}
            {
              <Form.Item
                {...layout}
                label={
                  <FormattedMessage
                    id="ItemBreakdownQuantity.quantity"
                    defaultMessage="Quantity"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  />
                }
              >
                <Row>
                  <Col span={8}>
                    <Form.Item
                      name="quantity"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber className="w-100" placeholder="Quantity" min={0} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      noStyle
                      name={"purchasingCriteriaId"}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select
                        allowClear
                        placeholder="Purchasing Criteria"
                        showSearch
                        optionFilterProp="children"
                        onChange={value => {
                          const { purchasingCriteriaSelectItems } = this.state;
                          const selectedPurchasingCriteria = purchasingCriteriaSelectItems.find(i => i.key === value);
                          this.setState({
                            selectedPurchasingCriteria
                          });
                        }}
                      >
                        {this.state.purchasingCriteriaSelectItems &&
                          this.state.purchasingCriteriaSelectItems.map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      noStyle
                      name={"purchasingCriteriaUnitId"}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select showSearch optionFilterProp="children" allowClear placeholder="Purchasing Criteria">
                        {Boolean(this.state.selectedPurchasingCriteria) &&
                          this.state.selectedPurchasingCriteria.units.map(i => (
                            <Option key={i.id} value={i.id}>
                              {i.abbreviation}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ItemBreakdownQuantity.weight" defaultMessage="Weight" />}
                name="weight"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber disabled className="w-100" placeholder="Weight" min={0} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ItemBreakdownQuantity.disciplineId" defaultMessage="Discipline" />}
                name="disciplineId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  placeholder="Discipline"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={event => {
                            this.setState({
                              codeName: event.target.value
                            });
                          }}
                        />
                        <a style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }} onClick={() => this.addCode("disciplineId")}>
                          <PlusOutlined /> Add item
                        </a>
                      </div>
                    </div>
                  )}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["disciplineIdSelectItems"]) &&
                    this.state["disciplineIdSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.name}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ItemBreakdownQuantity.locationId" defaultMessage="Location" />}
                name="locationId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Location"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={event => {
                            this.setState({
                              codeName: event.target.value
                            });
                          }}
                        />
                        <a style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }} onClick={() => this.addCode("locationId")}>
                          <PlusOutlined /> Add item
                        </a>
                      </div>
                    </div>
                  )}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["locationIdSelectItems"]) &&
                    this.state["locationIdSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.name}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ItemBreakdownQuantity.deliveryDate" defaultMessage="DeliveryDate" />}
                name="deliveryDate"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <DatePicker format="DD-MM-YYYY" />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                hidden={true}
                label={<FormattedMessage id="ItemBreakdownQuantity.deliveryDate1" defaultMessage="Status" />}
                name="status"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input />
              </Form.Item>
            }
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <Row gutter={[16, 16]}>
          <Col md={24}>
            <Space>
              <Button
                variant="primary"
                onClick={this.sendSelected}
                style={{ marginTop: "10px" }}
                disabled={Boolean(this.state.selectedRowKeys) && this.state.selectedRowKeys.length > 0 ? false : true}
                loading={this.state.loading}
              >
                Send Selected
              </Button>
              <span style={{ marginLeft: 8 }}>
                {Boolean(this.state.selectedRowKeys) && this.state.selectedRowKeys.length > 0 ? `Selected ${selectedRowKeys.length} items` : ""}
              </span>
            </Space>
          </Col>
        </Row>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columnsRoot}
            dataSource={this.state.tableListRoot}
            loading={this.state.loading}
            expandable={{
              expandedRowRender: record =>
                Boolean(record) && (
                  <Table
                    bordered
                    rowSelection={rowSelection}
                    scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                    columns={columns}
                    dataSource={record.subList}
                    loading={this.state.loading}
                    // onChange={this.handleTableChange}
                    // pagination={this.state.pagination}
                  />
                ),
              rowExpandable: () => {
                return true;
              }
            }}
            // onChange={this.handleTableChange}
            // pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {this.state.showLastPurchaseModal && (
          <AntModal
            centered
            width={1500}
            title="Last Purchases"
            visible={this.state.showLastPurchaseModal}
            onCancel={() => {
              this.setState({ tableListLastPurchase: [], showLastPurchaseModal: false });
            }}
            footer={[
              <Button
                style={{ marginRight: "3px" }}
                variant="secondary"
                onClick={() => {
                  this.setState({
                    tableListLastPurchase: [],
                    showLastPurchaseModal: false
                  });
                }}
              >
                Close
              </Button>
            ]}
          >
            <Row>
              <Col md={2}></Col>
              <Col md={20}>
                <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                  <Table
                    bordered
                    scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                    columns={columnsLastPurchase}
                    dataSource={this.state.tableListLastPurchase}
                    loading={this.state.loading}
                  />
                </div>
              </Col>
              <Col md={2}></Col>
            </Row>
          </AntModal>
        )}

        {this.state.richSearchModal && (
          <AntModal
            centered
            width={1500}
            title="Rich Search"
            visible={this.state.richSearchModal}
            onCancel={() => {
              this.setState({ richSearchModal: false });
            }}
            footer={[
              <Button
                style={{ marginRight: "3px" }}
                variant="secondary"
                onClick={() => {
                  this.setState({
                    richSearchModal: false,
                    selectedStockCard: null
                  });
                }}
              >
                Close
              </Button>,
              <Button
                variant="primary"
                onClick={() => {
                  let fObj = this.state.selectedStockCard;
                  this.setState({
                    richSearchModal: false
                  });

                  if (Boolean(fObj)) {
                    this.formRef.current.setFieldsValue({
                      code: fObj.code
                    });

                    if (Boolean(fObj.itemCodeCriterias) && fObj.itemCodeCriterias.length > 0) {
                      fObj.itemCodeCriterias.forEach(element => {
                        this.formRef.current.setFieldsValue({
                          [element.itemDefinition]: element.codeId
                        });
                      });
                    }

                    if (Boolean(fObj.itemDimension) && fObj.itemDimension.length > 0) {
                      fObj.itemDimension.forEach(element => {
                        this.formRef.current.setFieldsValue({
                          [element.itemDefinition]: element.codeId,
                          [element.itemDefinition + "Unit"]: element.unitId
                        });
                      });
                    }

                    this.fillPurchasingCriterias();
                    this.calculateWeight();
                  }
                }}
              >
                Ok
              </Button>
            ]}
          >
            {Boolean(this.state.itemDescription) &&
              Boolean(this.state.itemDescription.itemCodeCriterias) &&
              this.state.itemDescription.itemCodeCriterias.length > 0 &&
              this.state.itemDescription.itemCodeCriterias.map((element, index) => (
                <Row>
                  <Col md={4}></Col>
                  <Col md={4}>{element.itemDefinition}</Col>
                  <Col md={8}>
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      style={{ width: "100%" }}
                      onChange={value => {
                        element.codeId = value;
                        this.filterOnCriterias();
                      }}
                    >
                      {Boolean(this.state["StockCard" + element.itemDefinition + "SelectItems"]) &&
                        this.state["StockCard" + element.itemDefinition + "SelectItems"].map(i => (
                          <Option key={i.id} value={i.id}>
                            {i.name}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Col>
                  <Col md={8}></Col>
                </Row>
              ))}
            {Boolean(this.state.itemDescription) &&
              Boolean(this.state.itemDescription.itemDimension) &&
              this.state.itemDescription.itemDimension.length > 0 &&
              this.state.itemDescription.itemDimension.map(element => (
                <Row>
                  <Col md={4}></Col>
                  <Col md={4}>{element.itemDefinition}</Col>
                  <Col md={8}>
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      style={{ width: "100%" }}
                      onChange={value => {
                        console.log(value);
                        element.codeId = value;
                        this.filterOnCriterias();
                      }}
                    >
                      {Boolean(this.state["StockCard" + element.itemDefinition + "SelectItems"]) &&
                        this.state["StockCard" + element.itemDefinition + "SelectItems"].map(i => (
                          <Option key={i.id} value={i.id}>
                            {i.name}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Col>
                  <Col md={8}></Col>
                </Row>
              ))}
            <Row>
              <Col md={8}></Col>
              <Col md={8}>
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  placeholder="Code"
                  value={Boolean(this.state.selectedStockCard) ? this.state.selectedStockCard.code : null}
                  onChange={value => {
                    const { codeSelectItems } = this.state;
                    if (Boolean(value)) {
                      let fObj = codeSelectItems.find(i => i.code === value);

                      this.setState({
                        selectedStockCard: fObj
                      });
                    } else {
                      this.setState({
                        selectedStockCard: null
                      });
                    }
                  }}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["tempCodeSelectItems"]) &&
                    this.state["tempCodeSelectItems"].map(i => (
                      <Option key={i.code} value={i.code}>
                        {i.renameText != null ? i.renameText + " / " + i.code : i.code}
                      </Option>
                    ))}{" "}
                </Select>
              </Col>
              <Col md={8}></Col>
            </Row>
          </AntModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ItemBreakdownQuantity);
