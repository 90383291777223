import "antd/dist/antd.css";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Col, Form, InputNumber, Row, Select, Image, Input, Button as AntButton, Table, DatePicker } from "antd";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess, showWarning } from "../../MessageHelper";
import ActionMenu from "../CustomMenu/ActionMenu";
import { FileOutlined } from "@ant-design/icons";
import DraggableModal from "../../Components/DraggableModal";
import moment from "moment";

const { Option } = Select;


//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate"
      ? form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? moment(record[dataIndex], "DD-MM-YYYY") : ""
      })
      : form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
      });
  };

  const toggleSave = async () => {
    try {
      if (dataIndex === "planedDeliveryDate") {
        const values = await form.validateFields();
        values.planedDeliveryDate = Boolean(values.planedDeliveryDate) ? moment(values.planedDeliveryDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else if (dataIndex === "paymentDate") {
        const values = await form.validateFields();
        values.paymentDate = Boolean(values.paymentDate) ? moment(values.paymentDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record }, { ...values });
      }
    } catch (errInfo) { }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
      >
        {dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate" ? (
          <DatePicker ref={inputRef} onBlur={toggleSave} format={"DD-MM-YYYY"} />
        ) : (
          <InputNumber
            min={0}
            max={record.quantity > 0 ? record.quantity : 0}
            parser={value => value.replace(",", ".")}
            formatter={value => value.replace(".", ",")}
            ref={inputRef}
            onPressEnter={toggleSave}
            onBlur={toggleSave}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion

export class InventoryLogRelease extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "inventoryLog",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      toBeReleasedQuantityList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      stockIdSelectItems: null,
      vendorIdSelectItems: null,
      columnsToBeReleased: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initPage();
  };

  initPage = async params => {
    console.log("AAA", this.props);
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    this.toBeReleasedQuantityModal();

    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      if (Boolean(this.props.selectedRow)) {
        this.formRef.current.setFieldsValue({
          ...this.props.selectedRow
        });

        this.setState({
          quantity: Boolean(this.props.selectedRow.quantity) ? this.props.selectedRow.quantity : 0
        });
      } else {
        let quantity = 0;
        if (this.props.onPurposeType === "ReservedItems") {
          quantity = Boolean(this.props.selectedPrjTakeOff) ? this.props.selectedPrjTakeOff.reservedQuantity : null;
        } else if (this.props.onPurposeType === "ToBeReleasedItems") {
          quantity = Boolean(this.props.selectedPrjTakeOff) ? this.props.selectedPrjTakeOff.toBeReleasedQuantity : null;
        } else {
          quantity = Boolean(this.props.selectedPrjTakeOff) ? this.props.selectedPrjTakeOff.quantity : null;
        }
        this.setState({
          quantity: quantity
        });

        this.formRef.current.setFieldsValue({
          quantity: quantity,
          itemName: Boolean(this.props.selectedPrjTakeOff) ? this.props.selectedPrjTakeOff.itemName : null
        });
      }
    }
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initPage();
    }
  };

  fillComboboxes = async () => {
    var responseReceiver = await handleRequest("GET", "/api/" + this.state.controllerName + "/receivers");
    if (responseReceiver.type === "ERROR") {
      error(responseReceiver);
    }

    var responseCompanyProjectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/companyProjectId");
    if (responseCompanyProjectId.type === "ERROR") {
      error(responseCompanyProjectId);
    }

    var responseDepartments = await handleRequest("GET", "/api/" + this.state.controllerName + "/departments");
    if (responseDepartments.type === "ERROR") {
      error(responseDepartments);
    }

    this.setState({
      receiverSelectItems: Boolean(responseReceiver.data) ? responseReceiver.data : [],
      departmentSelectItems: Boolean(responseDepartments.data) ? responseDepartments.data : [],
      companyProjectIdSelectItems: Boolean(responseCompanyProjectId.data) ? responseCompanyProjectId.data : []
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.props.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => { };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.props.id,
      stockId: this.props.stockId,
      releaseType: this.props.releaseType,
      projectTakeOffListId: this.props.selectedPrjTakeOff.projectTakeOffListId,
      stockCardId: this.props.selectedPrjTakeOff.stockCardId,
      onPurposeType: this.props.onPurposeType,
      inventoryLogReservedId: this.props.inventoryLogReservedId,
      inventoryLogReleaseId: this.props.inventoryLogReleaseId
    };

    const { toBeReleasedQuantityList } = this.state;
    let relaseQuantities = [];
    if (Boolean(toBeReleasedQuantityList) && toBeReleasedQuantityList.length > 0) {
      toBeReleasedQuantityList.forEach(element => {
        if (Boolean(element.toBeReleasedQuantity) && element.toBeReleasedQuantity > 0) {
          element.companyProjectId = values.companyProjectId;
          relaseQuantities.push(element);
        }
      });
    }

    if (Boolean(relaseQuantities) && relaseQuantities.length > 0) {
      newItem.relaseQuantities = relaseQuantities;
      console.log("newItem", newItem);
      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/release", newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/release/" + newItem.id, newItem);
      }

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.props.saveStockRelease();
      }
    } else {
      showWarning("To be Released quantity not found.");
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  toBeReleasedQuantityModal = async () => {
    let selectedRow = this.props.selectedPrjTakeOff;
    console.log("selectedRow", selectedRow);

    let toBeReleasedQuantityList = [];
    if (Boolean(selectedRow.projectTakeOffListId) && selectedRow.projectTakeOffListId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/avaliable/" + selectedRow.projectTakeOffListId + "/" + selectedRow.companyProjectId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(Boolean(response.data)) && Boolean(response.data.avaliable) && response.data.avaliable.length > 0) {
          toBeReleasedQuantityList = response.data.avaliable;
        }
      }
    }
    console.log("toBeReleasedQuantityList", toBeReleasedQuantityList);

    this.setState(
      {
        modalToBeReleasedQuantityShow: true,
        toBeReleasedQuantityList: toBeReleasedQuantityList
      },
      () => {
        this.setColumnsToBeReleased();
      }
    );
  };

  setColumnsToBeReleased() {
    const { toBeReleasedQuantityList } = this.state;
    let selectedRow = this.props.selectedPrjTakeOff;

    let columnsToBeReleased = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="InventoryLogRelease.Stock" defaultMessage="Stock" />,
        key: "stockName",
        render: record => {
          return record.stockName;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="InventoryLogRelease.InStock" defaultMessage="In Stock" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        },
        width: 100
      }
    ];

    if (Boolean(selectedRow) && Boolean(selectedRow.allDimensions)) {
      let dimensionList = selectedRow.allDimensions.split(", ");

      if (Boolean(toBeReleasedQuantityList) && toBeReleasedQuantityList.length > 0) {
        toBeReleasedQuantityList.forEach(element => {
          if (Boolean(element.allDimensions)) {
            let dimensionRowList = element.allDimensions.split(", ");

            if (Boolean(dimensionRowList) && dimensionRowList.length > 1) {
              dimensionRowList.forEach(element => {
                if (!dimensionList.includes(element)) {
                  dimensionList.push(element);
                }
              });
            }
          }
        });
      }

      if (Boolean(dimensionList) && dimensionList.length > 0) {
        dimensionList.forEach(element => {
          let dimension = element.split(":");
          if (Boolean(dimension) && dimension.length > 1) {
            let obj = {
              // key: row.key,
              title: dimension[0],
              dataIndex: dimension[0]
              // [dimension[0]]: dimension[1],
            };

            let addColumn = columnsToBeReleased.find(p => p.title === obj.title);
            if (!Boolean(addColumn)) {
              columnsToBeReleased.push(obj);
            }

            if (Boolean(toBeReleasedQuantityList) && toBeReleasedQuantityList.length > 0) {
              toBeReleasedQuantityList.forEach((element, index) => {
                if (Boolean(element.allDimensions) && element.allDimensions.includes(dimension[0])) {
                  let dimensionRowList = element.allDimensions.split(", ");
                  if (Boolean(dimensionRowList) && dimensionRowList.length > 1) {
                    console.log("dimensionRowList", dimensionRowList);
                    dimensionRowList.forEach(subRow => {
                      console.log("subRow", subRow);

                      let subDimension = subRow.split(":");
                      if (Boolean(subDimension) && subDimension.length > 1 && dimension[0] === subDimension[0]) {
                        element.key = index + 1;
                        element[dimension[0]] = subDimension[1];
                        return;
                      }
                    });
                  }
                } else {
                  element.key = index + 1;
                  element[dimension[0]] = "";
                }
              });
            }
          }
        });
      }
    }

    let avaliable = {
      title: <FormattedMessage id="InventoryLogRelease.ToBeReleased" defaultMessage="To be Released" />,
      key: "toBeReleasedQuantity",
      render: record => {
        return record.toBeReleasedQuantity;
      },
      width: 100,
      editable: true,
      onCell: record => ({
        record,
        editable: true,
        dataIndex: "toBeReleasedQuantity",
        title: <FormattedMessage id="InventoryLogRelease.ToBeReleased" defaultMessage="To be Released" />,
        handleSave: this.handleToBeReleasedQuantityList
      })
    };

    columnsToBeReleased.push(avaliable);
    this.setState({
      columnsToBeReleased: columnsToBeReleased,
      releaseDescriptionModal: true
    });
  }

  handleToBeReleasedQuantityList = async (row, value) => {
    console.log("row", row);
    console.log("value", value);
    if (Boolean(value)) {
      const newData = [...this.state.toBeReleasedQuantityList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.toBeReleasedQuantity === value.toBeReleasedQuantity) return;
        item.toBeReleasedQuantity = value.toBeReleasedQuantity;
        console.log("item", item);

        this.setState(
          {
            toBeReleasedQuantityList: newData
          },
          () => {
            this.setColumnsToBeReleased();
            console.log("toBeReleased", this.state.toBeReleasedQuantityList);
          }
        );
      }
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={8}>
            <Col span={20}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="InventoryLogRelease.Item" defaultMessage="Item" />}
                  name="itemName"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} readOnly />
                </Form.Item>
              }
              {/* {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="InventoryLogRelease.ReleaseQuantity" defaultMessage="Release Quantity" />}
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Row>
                    <Col span={22}>
                      <Form.Item
                        name="quantity"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <InputNumber style={{ width: "100%" }} min={1} max={this.state.quantity} />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <AntButton
                        style={{ border: "0px" }}
                        onClick={() => {
                          this.toBeReleasedQuantityModal();
                        }}
                        icon={<FileOutlined color="secondary" fontSize="large" />}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              } */}

              <Table
                bordered
                components={components}
                rowClassName={() => "editable-row"}
                style={{ width: "100%" }}
                // scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                columns={this.state.columnsToBeReleased}
                dataSource={this.state.toBeReleasedQuantityList}
                loading={this.state.loading}
                pagination={false}
              />
              <br />
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="InventoryLogRelease.projectId" defaultMessage="Project" />}
                  name="companyProjectId"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                    {Boolean(this.state.companyProjectIdSelectItems) &&
                      this.state.companyProjectIdSelectItems.length > 0 &&
                      this.state.companyProjectIdSelectItems.map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="InventoryLogRelease.receiverId" defaultMessage="Receiver" />}
                  name="receiverId"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                    {Boolean(this.state.receiverSelectItems) &&
                      this.state.receiverSelectItems.length > 0 &&
                      this.state.receiverSelectItems.map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="InventoryLogRelease.delivererId" defaultMessage="Deliverer" />}
                  name="delivererId"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                    {Boolean(this.state.receiverSelectItems) &&
                      this.state.receiverSelectItems.length > 0 &&
                      this.state.receiverSelectItems.map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }
            </Col>
            <Col span={2}>
              <br />
              <br />
              <br />
              {Boolean(this.props.selectedPrjTakeOff) && Boolean(this.props.selectedPrjTakeOff.imageUrl) && (
                <Image src={this.props.selectedPrjTakeOff.imageUrl} />
              )}
            </Col>
            <Col span={2}></Col>
          </Row>
          {
            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6}></Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Col>
              <Col xs={0} sm={0} md={10} lg={10} xl={10} xxl={10}></Col>
            </Row>
          }
        </Form>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryLogRelease);
