import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal as ModalBoot, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Radio, Modal } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Select } from "antd";
import { Input } from "antd";
import { InputNumber } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

export class ChemicalCompositionDetail extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ChemicalCompositionDetail",
      controllerName: "chemicalCompositionDetail",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  formRefAndOrModal = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };
  fillComboboxes = async () => {
    var responsegradeId = await handleRequest("GET", "/api/" + this.state.controllerName + "/gradeId");
    if (responsegradeId.type === "ERROR") {
      error(responsegradeId);
    }
    this.setState({
      gradeIdSelectItems: Boolean(responsegradeId.data) ? responsegradeId.data : []
    });

    var responseelementId = await handleRequest("GET", "/api/" + this.state.controllerName + "/elementId");
    if (responseelementId.type === "ERROR") {
      error(responsegradeId);
    }
    this.setState({
      elementIdSelectItems: Boolean(responseelementId.data) ? responseelementId.data : []
    });

    var responsethickness01Id = await handleRequest("GET", "/api/" + this.state.controllerName + "/greaterOrEqual");
    if (responsethickness01Id.type === "ERROR") {
      error(responsethickness01Id);
    }
    this.setState({
      thickness01IdSelectItems: Boolean(responsethickness01Id.data) ? responsethickness01Id.data : []
    });
    var responsethickness02Id = await handleRequest("GET", "/api/" + this.state.controllerName + "/lesserOrEqual");
    if (responsethickness02Id.type === "ERROR") {
      error(responsethickness02Id);
    }
    this.setState({
      thickness02IdSelectItems: Boolean(responsethickness02Id.data) ? responsethickness02Id.data : []
    });
    var responsecontent01Id = await handleRequest("GET", "/api/" + this.state.controllerName + "/content01Id");
    if (responsecontent01Id.type === "ERROR") {
      error(responsecontent01Id);
    }
    this.setState({
      content01IdSelectItems: Boolean(responsecontent01Id.data) ? responsecontent01Id.data : []
    });
    var responsecontent02Id = await handleRequest("GET", "/api/" + this.state.controllerName + "/content02Id");
    if (responsecontent02Id.type === "ERROR") {
      error(responsecontent02Id);
    }
    this.setState({
      content02IdSelectItems: Boolean(responsecontent02Id.data) ? responsecontent02Id.data : []
    });

    if (
      Boolean(this.props.selectedChemicalCompositionDetail) &&
      Boolean(this.props.selectedChemicalCompositionDetail.productFormId) &&
      this.props.selectedChemicalCompositionDetail.productFormId > 0
    ) {
      var responseDimension = await handleRequest(
        "GET",
        "/api/" + this.state.controllerName + "/dimension/" + this.props.selectedChemicalCompositionDetail.productFormId
      );
      if (responseDimension.type === "ERROR") {
        error(responseDimension);
      }
      this.setState({
        dimensionSelectItems: Boolean(responseDimension.data) ? responseDimension.data : []
      });
    } else {
      this.setState({
        dimensionSelectItems: []
      });
    }

    this.fillCode("GradeRangeselementId");
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      chemicalCompositionId: this.props.selectedChemicalCompositionDetail.id,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let chemicalCompositionDetailList = [];
    chemicalCompositionDetailList.push(row);

    this.formRef.current.setFieldsValue({
      ...row,
      chemicalCompositionDetailList: chemicalCompositionDetailList
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    if (Boolean(this.props.selectedChemicalCompositionDetail.id) && this.props.selectedChemicalCompositionDetail.id > 0) {
      const chemicalCompositionDetailList = this.formRef.current.getFieldValue("chemicalCompositionDetailList");
      if (Boolean(chemicalCompositionDetailList) && chemicalCompositionDetailList.length > 0) {
        let isValid = false;
        chemicalCompositionDetailList.forEach(element => {
          if (element.thickness01Id === "Greater" && element.thickness02Id === "Greater") {
            isValid = true;
            return;
          }
          if (element.thickness01Id === "GreaterEqual" && element.thickness02Id === "GreaterEqual") {
            isValid = true;
            return;
          }
          if (element.thickness01Id === "Less" && element.thickness02Id === "Less") {
            isValid = true;
            return;
          }
          if (element.thickness01Id === "LessEqual" && element.thickness02Id === "LessEqual") {
            isValid = true;
            return;
          }
          if (element.thickness01Id === "Greater" && element.thickness02Id === "GreaterEqual") {
            isValid = true;
            return;
          }
          if (element.thickness01Id === "GreaterEqual" && element.thickness02Id === "Greater") {
            isValid = true;
            return;
          }
          if (element.thickness01Id === "Greater" && element.thickness02Id === "GreaterEqual") {
            isValid = true;
            return;
          }
          if (element.thickness01Id === "Less" && element.thickness02Id === "LessEqual") {
            isValid = true;
            return;
          }
          if (element.thickness01Id === "LessEqual" && element.thickness02Id === "Less") {
            isValid = true;
            return;
          }
          if (element.thickness01Id === "Greater" && element.thickness02Id === "Less" && (element.thickness1 > element.thickness2)) {
            isValid = true;
            return;
          }
          if (element.thickness01Id === "Greater" && element.thickness02Id === "LessEqual" && (element.thickness1 > element.thickness2)) {
            isValid = true;
            return;
          }
          if (element.thickness01Id === "GreaterEqual" && element.thickness02Id === "Less" && (element.thickness1 > element.thickness2)) {
            isValid = true;
            return;
          }
          if (element.thickness01Id === "GreaterEqual" && element.thickness02Id === "LessEqual" && (element.thickness1 > element.thickness2)) {
            isValid = true;
            return;
          }
        });
        console.log("isValid", isValid);
        if (isValid) {
          showWarning("Entered Criteria Ranges cannot have the same meaning");
        } else {
          var response = await handleRequest(
            "POST",
            "/api/" + this.state.controllerName + "/saveAllList/" + this.props.selectedChemicalCompositionDetail.id,
            values.chemicalCompositionDetailList
          );

          if (response.type === "ERROR") {
            error(response);
          } else {
            this.cancel();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        }
        console.log("chemicalCompositionDetailList", chemicalCompositionDetailList);
      }
      // var response = await handleRequest(
      //   "POST",
      //   "/api/" + this.state.controllerName + "/saveAllList/" + this.props.selectedChemicalCompositionDetail.id,
      //   values.chemicalCompositionDetailList
      // );

      // if (response.type === "ERROR") {
      //   error(response);
      // } else {
      //   this.cancel();
      //   const { pagination } = this.state;
      //   this.restartTable({ pagination });
      //   showSuccess();
      // }
    } else {
      showWarning("Chemical Composition not found.");
    }
  };

  calcCriteria = async () => {
    const chemicalCompositionDetailList = this.formRef.current.getFieldValue("chemicalCompositionDetailList");

    if (Boolean(chemicalCompositionDetailList) && chemicalCompositionDetailList.length > 0) {
      chemicalCompositionDetailList.forEach((element, index) => {
        console.log("index", index);
        if (index > 0) {
          console.log("element", element);
          if (Boolean(element)) {
            if (element.thickness1 > element.thickness2) {
              showWarning("HATA");
            }
          }
        }
      });
    }
    // if (Boolean(chemicalCompositionDetailList) && chemicalCompositionDetailList.length > 0) {
    //   chemicalCompositionDetailList.forEach((element, index) => {

    //     console.log("index", index);
    //     if (index > 0) {
    //       console.log("element", element);
    //       let element1 = samplingDetailList[index - 1];
    //       if (Boolean(element)) {
    //         if(element.thickness1 > element.thickness2){
    //           showWarning("Chemical Composition not found.");
    //         } else {
    //         samplingDetailList[index] = { size: element1.lotSize };
    //       }
    //     } else {
    //       if (!Boolean(element)) {
    //         samplingDetailList[index] = { size: 2 };
    //       }
    //     }
    //   });
    // }
  };

  saveandOrModal = async values => {
    if (Boolean(values.andOrModal) && values.andOrModal.length > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAndOrModal", values.andOrModal);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };
  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ChemicalCompositionDetail.gradeId" defaultMessage="Grade" />,
        key: "gradeId",
        render: record => {
          return record.gradeName;
        },
        ...getColumnFilter({
          inputName: "gradeId",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ChemicalCompositionDetail.elementId" defaultMessage="Element" />,
        key: "elementId",
        render: record => {
          return record.elementName;
        },
        ...getColumnFilter({
          inputName: "elementId",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
    ];
    const excelExport = (
      <>
        {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}{" "}
        {this.state.exportClick ? (
          <ExcelFile filename="ChemicalCompositionDetail" hideElement={true}>
            {" "}
            <ExcelSheet name="ChemicalCompositionDetail" data={this.state.tableList}>
              {" "}
              <ExcelColumn label="Grade" value="gradeId" />
              <ExcelColumn label="Element" value="element" />
            </ExcelSheet>{" "}
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );
    return (
      <Badge.Ribbon
        text={<FormattedMessage id="ChemicalCompositionDetail.pageTitle" defaultMessage="Chemical Composition Details" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              {" "}
              <Col md={21}></Col> <Col md={1}>{excelExport}</Col>{" "}
              <Col md={1}>
                {" "}
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>{" "}
              </Col>{" "}
              <Col md={1}>
                {" "}
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>{" "}
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>{" "}
              </Col>{" "}
            </Row>
            <div hidden={this.state.hideInputs}>
              <br />{" "}
              <h4>
                <FormattedMessage id={"ChemicalCompositionDetail.Material"} defaultMessage={"Material"} />
              </h4>
              {
                <Form.List name="chemicalCompositionDetailList">
                  {(fields, { add, remove }) => {
                    const handleAddClick = () => {
                      const chemicalCompositionDetailList = this.formRef.current.getFieldValue("chemicalCompositionDetailList");

                      if (!chemicalCompositionDetailList || chemicalCompositionDetailList.length === 0) {
                        // this.calcCriteria();
                        add();
                        return;
                      }

                      let isValid = false;
                      chemicalCompositionDetailList.forEach(element => {
                        if (element.thickness01Id === "Greater" && element.thickness02Id === "Greater") {
                          isValid = true;
                          return;
                        }
                        if (element.thickness01Id === "GreaterEqual" && element.thickness02Id === "GreaterEqual") {
                          isValid = true;
                          return;
                        }
                        if (element.thickness01Id === "Less" && element.thickness02Id === "Less") {
                          isValid = true;
                          return;
                        }
                        if (element.thickness01Id === "LessEqual" && element.thickness02Id === "LessEqual") {
                          isValid = true;
                          return;
                        }
                        if (element.thickness01Id === "Greater" && element.thickness02Id === "GreaterEqual") {
                          isValid = true;
                          return;
                        }
                        if (element.thickness01Id === "GreaterEqual" && element.thickness02Id === "Greater") {
                          isValid = true;
                          return;
                        }
                        if (element.thickness01Id === "Greater" && element.thickness02Id === "GreaterEqual") {
                          isValid = true;
                          return;
                        }
                        if (element.thickness01Id === "Less" && element.thickness02Id === "LessEqual") {
                          isValid = true;
                          return;
                        }
                        if (element.thickness01Id === "LessEqual" && element.thickness02Id === "Less") {
                          isValid = true;
                          return;
                        }
                        if (element.thickness01Id === "Greater" && element.thickness02Id === "Less" && (element.thickness1 > element.thickness2)) {
                          isValid = true;
                          return;
                        }
                        if (element.thickness01Id === "Greater" && element.thickness02Id === "LessEqual" && (element.thickness1 > element.thickness2)) {
                          isValid = true;
                          return;
                        }
                        if (element.thickness01Id === "GreaterEqual" && element.thickness02Id === "Less" && (element.thickness1 > element.thickness2)) {
                          isValid = true;
                          return;
                        }
                        if (element.thickness01Id === "GreaterEqual" && element.thickness02Id === "LessEqual" && (element.thickness1 > element.thickness2)) {
                          isValid = true;
                          return;
                        }
                      });
                      console.log("isValid", isValid);
                      if (isValid) {
                        showWarning("Entered Criteria Ranges cannot have the same meaning");
                      } else {
                        // this.calcCriteria();
                        add();
                      }
                      console.log("chemicalCompositionDetailList", chemicalCompositionDetailList);
                    };

                    return (
                      <>
                        <TableBoot bordered responsive="xl">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ChemicalCompositionDetail.gradeId"} defaultMessage={"Grades"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ChemicalCompositionDetail.elementId"} defaultMessage={"Element"} />
                              </th>
                              <th colSpan="5" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ChemicalCompositionDetail.materialQuantity"} defaultMessage={"Criteria"} />
                              </th>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ChemicalCompositionDetail.minContent"} defaultMessage={"Min Content"} />
                              </th>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ChemicalCompositionDetail.maxContent"} defaultMessage={"Max Content"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "gradeId"]}>
                                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                        {Boolean(this.state["gradeIdSelectItems"]) &&
                                          this.state["gradeIdSelectItems"].length > 0 &&
                                          this.state["gradeIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}{" "}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "elementId"]}>
                                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                        {Boolean(this.state["elementIdSelectItems"]) &&
                                          this.state["elementIdSelectItems"].length > 0 &&
                                          this.state["elementIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}{" "}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "productFormDimensionId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        {
                                          required: false,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                        {Boolean(this.state["dimensionSelectItems"]) &&
                                          this.state["dimensionSelectItems"].length > 0 &&
                                          this.state["dimensionSelectItems"].map(i => (
                                            <Option key={i.id} value={i.id}>
                                              {i.definition}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "thickness1"]}>
                                      <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "thickness01Id"]}>
                                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                        {Boolean(this.state["thickness01IdSelectItems"]) &&
                                          this.state["thickness01IdSelectItems"].length > 0 &&
                                          this.state["thickness01IdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}{" "}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "thickness2"]}>
                                      <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "thickness02Id"]}>
                                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                        {Boolean(this.state["thickness02IdSelectItems"]) &&
                                          this.state["thickness02IdSelectItems"].length > 0 &&
                                          this.state["thickness02IdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}{" "}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "content"]}>
                                      <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "content01Id"]}>
                                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                        {Boolean(this.state["content01IdSelectItems"]) &&
                                          this.state["content01IdSelectItems"].length > 0 &&
                                          this.state["content01IdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}{" "}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "content2"]}>
                                      <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "content02Id"]}>
                                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                        {Boolean(this.state["content02IdSelectItems"]) &&
                                          this.state["content02IdSelectItems"].length > 0 &&
                                          this.state["content02IdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}{" "}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <AntButton
                                      style={{ border: "0px" }}
                                      onClick={() => {
                                        this.setState({ andOrModal: true });
                                      }}
                                      icon={<CreateIcon color="secondary" fontSize="large" />}
                                    ></AntButton>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"ChemicalCompositionDetail.AddNewMaterial"} defaultMessage={"Add New"} />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              }
              <Modal
                title={
                  <Badge.Ribbon
                    text={<FormattedMessage id="ChemicalCompositionDetail.andOrModal" defaultMessage="Number of Floors" />}
                    placement="start"
                    color="volcano"
                    style={{ fontSize: 12 }}
                  ></Badge.Ribbon>
                }
                keyboard={false}
                maskClosable={false}
                open={this.state.andOrModal}
                onCancel={() => {
                  this.setState({ andOrModal: false });
                }}
                okButtonProps={{ hidden: false }}
                okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
                cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                onOk={() => {
                  this.formRefNumberOfFloors.current.submit();
                  this.setState({
                    andOrModal: false
                  });
                  this.fillCode("ChemicalCompositionDetail.TypeOfHouses");
                }}
                width={800}
                centered
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveandOrModal} ref={this.formRefAndOrModal}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ChemicalCompositionDetail.Type" defaultMessage="Type" />}
                    name="allSameVariableType"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Radio.Group
                      onChange={e => {
                        this.setState({
                          allSameVariableType: e.target.value
                        });
                      }}
                    >
                      <Radio key={"AllSame"} value={"AllSame"}>
                        {<FormattedMessage id="ChemicalCompositionDetail.AllSame" defaultMessage="And" />}
                      </Radio>
                      <Radio key={"Variable"} value={"Variable"}>
                        {<FormattedMessage id="ChemicalCompositionDetail.Variable" defaultMessage="Or" />}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Form>
              </Modal>
              <div hidden={this.state.hideSave}>
                {" "}
                {
                  <Row gutter={[16, 16]}>
                    {" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      {" "}
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        {" "}
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />{" "}
                      </Button>{" "}
                    </Col>{" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                  </Row>
                }{" "}
              </div>
              <div hidden={this.state.hideUpdate}>
                {" "}
                {
                  <Row gutter={[16, 16]}>
                    {" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      {" "}
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        {" "}
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />{" "}
                      </Button>{" "}
                    </Col>{" "}
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      {" "}
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        {" "}
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />{" "}
                      </Button>{" "}
                    </Col>{" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                  </Row>
                }{" "}
              </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              {" "}
              <div className="alert-icon">
                {" "}
                <FilterOutlined />{" "}
              </div>{" "}
              <div className="alert-text">
                {" "}
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      {" "}
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>{" "}
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>{" "}
                      <span>
                        {" "}
                        <Tooltip title="Remove">
                          {" "}
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />{" "}
                        </Tooltip>{" "}
                      </span>{" "}
                    </div>
                  );
                })}{" "}
              </div>{" "}
              <div className="alert-close">
                {" "}
                <Tooltip title="Remove All">
                  {" "}
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />{" "}
                </Tooltip>{" "}
              </div>{" "}
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            {" "}
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />{" "}
          </div>
        </div>
        <ModalBoot show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          {" "}
          <ModalBoot.Header closeButton>
            {" "}
            <ModalBoot.Title id="contained-modal-title-vcenter">
              {" "}
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />{" "}
            </ModalBoot.Title>{" "}
          </ModalBoot.Header>{" "}
          <ModalBoot.Body>
            {" "}
            <p>
              {" "}
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />{" "}
            </p>{" "}
          </ModalBoot.Body>{" "}
          <ModalBoot.Footer>
            {" "}
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              {" "}
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />{" "}
            </Button>{" "}
            <Button variant="danger" onClick={this.delete}>
              {" "}
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />{" "}
            </Button>{" "}
          </ModalBoot.Footer>{" "}
        </ModalBoot>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ChemicalCompositionDetail);
