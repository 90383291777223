import React, { Component } from 'react';
import { connect } from 'react-redux';
import { handleRequest } from '../../ApiConnector';
import CustomMaterialMenu from '../CustomMenu/CustomMaterialMenu';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from "react-intl";
import { Form, Table, Input, Select, Divider, Col, Row, Button as AntButton } from 'antd';
import 'antd/dist/antd.css';
import { PlusOutlined } from '@ant-design/icons';
import CreateIcon from '@material-ui/icons/AddCircle';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { error, showError, showSuccess } from '../../MessageHelper';

const { Option } = Select;

export class Project extends Component {

    constructor() {
        super();
        this.state = {

            modalDeleteShow: false,
            filePath: '',
            fileList: [],

            id: null,
            tableList: [],
            companyId: null,
            companyLineAge: null,
            projectCode: null,
            projectFullName: null,
            projectShortName: null,
            countryId: null,
            address: null,
            clientId: null,
            codeName: null,
            selectedOptions: [],
            companySelectItems: [],
            countrySelectItems: [],
            clientSelectItems: [],
            hiddenInputs: true,
            locationId: null,
            projectTitle: null,
            contractNo: null,
            biNo: null,
            joNo: null,

            licenseStartDate: null,
            licenseEndDate: null,
            companyType: null,

            hideUpdate: true,
            hideSave: false,
            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['2', '10', '25', '50', '100', '1000', '10000', '100000', '1000000'],
            },
            loading: false,
        };



    }

    formRef = React.createRef();

    componentDidMount = async () => {

        const { pagination } = this.state;
        this.restartTable({ pagination });
        this.fillcompanies();
        this.fillCountries();
        this.fillClients();
        this.fillLocations();

    };

    handleTableChange = (pagination, filters, sorter) => {
        this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters, });
    }

    fillCountries = async () => {

        let url = "/api/countries/selectItems";
        var response = await handleRequest("GET", url);
        if (response.type === "ERROR") {
            error(response);
        }
        else
            this.setState({
                countrySelectItems: Boolean(response.data) ? response.data : []
            });

    }

    cancel = () => {
        this.setState({
            hiddenInputs: true,
            hideUpdate: true,
            hideSave: false
        });
        this.resetInputs();
    };

    resetInputs = () => {

        this.formRef.current.setFieldsValue({
            companyLineAge: null,
            companyId: null,
            projectCode: null,
            projectFullName: null,
            projectShortName: null,
            countryId: null,
            address: null,
            clientId: null,
            locationId: null,
            projectTitle: null,
            contractNo: null,
            biNo: null,
            joNo: null,
        });

        this.setState({
            hideUpdate: true,
            hideSave: false,

            companyId: null,
            companyLineAge: null,
            projectCode: null,
            clientId: null,
            projectFullName: null,
            projectShortName: null,
            countryId: null,
            address: null,
            locationId: null,
            projectTitle: null,
            contractNo: null,
            biNo: null,
            joNo: null,

            prefixDomain: 'http://'

        })
    }

    error = (response) => {
        try {
            if (Boolean(response.response) && response.response.status === 403)
                // document.getElementById("logout").click();
                window.location.href = "/logout";
            else
                showError((Boolean(response.response) && Boolean(response.response.data)) ? response.response.data.message : "");
        } catch (error) {
        }
    }

    edit = (row) => {

        let cla = Boolean(row.companyLineAge) ? row.companyLineAge.split(",") : null;
        let companyLineAge = [];
        if (Boolean(cla)) {
            cla.forEach(element => {
                companyLineAge.push(parseInt(element));
            });
        }

        this.formRef.current.setFieldsValue({
            id: row.id,
            companyId: row.companyId,
            companyLineAge: companyLineAge,
            projectCode: row.projectCode,
            projectFullName: row.projectFullName,
            projectShortName: row.projectShortName,
            countryId: row.countryId,
            address: row.address,
            clientId: row.clientId,
            locationId: row.locationId,
            projectTitle: row.projectTitle,
            contractNo: row.contractNo,
            biNo: row.biNo,
            joNo: row.joNo
        });

        this.setState({
            hideUpdate: false,
            hideSave: true,
            hiddenInputs: false,
            id: row.id,
            companyId: row.companyId,
            companyLineAge: companyLineAge,
            projectCode: row.projectCode,
            clientId: row.clientId,
            projectFullName: row.projectFullName,
            projectShortName: row.projectShortName,
            countryId: row.countryId,
            address: row.address,
            locationId: row.locationId,
            projectTitle: row.projectTitle,
            contractNo: row.contractNo,
            biNo: row.biNo,
            joNo: row.joNo
        });


    };




    actionTemplate(row) {

        return <React.Fragment>
            <CustomMaterialMenu row={row} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;

    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            companyId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    delete = async () => {

        const deletedItem = {
            id: this.state.companyId

        }

        var response = await handleRequest("DELETE", "/api/projects/" + deletedItem.id);


        if (response.type === "ERROR") {
            error(response);
        }
        else {
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.setState({
                companyId: null,
                modalDeleteShow: false
            });
        }

    }

    // restartTable = async () => {

    //     let url = "/api/projects/company";// authorized

    //     const response = await handleRequest("GET", url);

    //     if (response.type === "ERROR") {
    //         error(response);
    //     }
    //     else
    //         this.setState({
    //             tableList: Boolean(response.data) ? response.data : ''
    //         });

    // }

    restartTable = async (params = {}) => {

        this.setState({ loading: true });
        const newObj = this.createWhere(params);

        let url = "/api/projects/paging";
        const response = await handleRequest("POST", url, newObj);
        if (response.type === "ERROR") {
            error(response);
            this.setState({
                loading: false,
                tableList: [],
                pagination: {
                    ...params.pagination,
                    total: 0,
                },
                sortField: "",
                sortOrder: "",
            });
        }
        else {
            if (response.data.length !== 0) {
                let list = response.data.data;
                list.forEach((element, index) => {
                    element.sn = index + 1;
                });
                this.setState({
                    loading: false,
                    tableList: list,
                    pagination: {
                        ...params.pagination,
                        total: response.data.totalRecords,
                    },
                    sortField: params.sortField,
                    sortOrder: params.sortOrder,
                });
            } else {
                this.setState({
                    loading: false,
                    tableList: [],
                    pagination: {
                        ...params.pagination,
                        total: 0,
                    },
                    sortField: "",
                    sortOrder: "",
                });
            }
        }
    };

    createWhere(params) {
        if (Boolean(params._pagination))
            params.pagination = params._pagination;
        var current = this.state.pagination.current;
        var pageSize = this.state.pagination.pageSize;
        var sortField = "", sortOrder = "";


        if (Boolean(params) && Boolean(params.pagination)) {
            current = params.pagination.current;
            pageSize = params.pagination.pageSize;


            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }

        }
        const newObj = {
            ...params.pagination,
            firstResult: current - 1, // (current - 1) * pageSize,
            maxResults: pageSize,
            sortField: sortField,
            sortOrder: sortOrder,
        };
        return newObj;
    }

    // fillcompanies = async () => {

    //     let url = "/api/companies/authorized";

    //     const response = await handleRequest("GET", url);

    //     if (response.type === "ERROR") {
    //         error(response);
    //     }
    //     else
    //         this.setState({
    //             companySelectItems: Boolean(response.data) ? response.data : []
    //         });

    // }


    fillcompanies = async () => {

        let url = "/api/companies/tree";

        const response = await handleRequest("GET", url);

        if (response.type === "ERROR") {
            error(response);
        }
        else
            this.setState({
                companySelectItems: Boolean(response.data) ? response.data : []
            });

    }

    save = async () => {

        const newItem = {

            id: this.state.id,
            companyId: this.state.companyId,
            companyLineAge: Boolean(this.state.companyLineAge) ? this.state.companyLineAge.join() : null,
            projectCode: this.state.projectCode,
            clientId: this.state.clientId,
            projectFullName: this.state.projectFullName,
            projectShortName: this.state.projectShortName,
            countryId: this.state.countryId,
            address: this.state.address,
            locationId: this.state.locationId,
            projectTitle: this.state.projectTitle,
            contractNo: this.state.contractNo,
            biNo: this.state.biNo,
            joNo: this.state.joNo

        }

        if (newItem.id === null) {
            var response = await handleRequest("POST", "/api/projects", newItem);
        }
        else response = await handleRequest("PUT", "/api/projects/" + newItem.id, newItem);


        if (response.type === "ERROR") {
            error(response);
        }
        else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
        }

    };


    addItem = async () => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: "client"
            }

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: '',
                });
                this.fillClients();
            }
        } else {
            showError("Please enter department");
        }
    };

    fillClients = async () => {
        const response = await handleRequest("GET", "/api/codes/type/client");
        if (Boolean(response.data)) {
            this.setState({
                clientSelectItems: response.data
            });
        }
    }


    addLocation = async () => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: "location"
            }

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: '',
                });
                this.fillLocations();
            }
        } else {
            showError("Please enter department");
        }
    };

    fillLocations = async () => {
        const response = await handleRequest("GET", "/api/codes/type/location");
        if (Boolean(response.data)) {
            this.setState({
                clientSelectItems: response.data
            });
        }
    }

    loadData = async (selectedOptions) => {

        if (Boolean(selectedOptions)) {

            const targetOption = selectedOptions[selectedOptions.length - 1];

            if (Boolean(targetOption)) {
                targetOption.loading = true;

                console.log(selectedOptions);
                console.log(targetOption);

                let url = "/api/companies/subcompanies/" + targetOption.value;

                const response = await handleRequest("GET", url);

                if (response.type === "ERROR") {
                    error(response);
                }
                else {
                    targetOption.loading = false;
                    targetOption.children = Boolean(response.data) ? response.data : [];
                    this.setState({
                        companySelectItems: [...this.state.companySelectItems]
                    });
                }
            }

        }


    };

    onClickCreateNew = async () => {
        this.resetInputs();
        if (this.state.hiddenInputs) {
            this.setState({
                hiddenInputs: false,
                hiddenSave: false,
                hiddenUpdate: true,
            });
        }
        else {
            this.setState({
                hiddenInputs: true,
                hiddenSave: true,
                hiddenUpdate: false,
            });
        }
    }

    render() {

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout2 = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 }
        };

        const columns = [

            {
                title: 'Actions', key: 'Actions', render: (record) => {
                    return this.actionTemplate(record);
                }
            },

            {
                title: 'S/N', key: 'index', render: (value, row, index) => {

                    return index + 1;

                }
            },


            {
                title: 'Company Name', key: 'companyName', render: (record) => {

                    if (Boolean(record.company))
                        return record.company.companyName;
                }
            },
            {
                title: 'Project Code', key: 'projectCode', render: (record) => {
                    return record.projectCode;
                }
            },
            {
                title: 'Project Name', key: 'projectFullName', render: (record) => {
                    return record.projectFullName;
                }
            },
            {
                title: 'Project Short Name', key: 'projectShortName', render: (record) => {
                    return record.projectShortName;
                }
            },
            {
                title: 'Client', key: 'clientId', render: (record) => {
                    return Boolean(record.client) ? record.client.name : "";
                }
            },

            {
                title: 'Project Title', key: 'projectTitle', render: (record) => {
                    return record.projectTitle;
                }
            },

            {
                title: 'Country', key: 'countryId', render: (record) => {

                    if (Boolean(record.country))
                        return record.country.countryName;
                }
            },
            {
                title: 'Location', key: 'locationId', render: (record) => {
                    return Boolean(record.location) ? record.location.name : "";
                }
            },
            {
                title: 'Address', key: 'countryName', render: (record) => {

                    return record.address;
                }
            },
            {
                title: 'Contract No', key: 'contractNo', render: (record) => {

                    return record.contractNo;
                }
            },
            {
                title: 'BI No', key: 'biNo', render: (record) => {

                    return record.biNo;
                }
            },
            {
                title: 'JO No', key: 'joNo', render: (record) => {

                    return record.joNo;
                }
            },

        ];




        return (

            <div hidden={this.state.hideInputs} className="card card-custom" style={{ padding: '2rem' }}>

                <Form
                    initialValues={{ remember: false }}
                    onFinish={this.save}
                    onFinishFailed={onFinishFailed}
                    ref={this.formRef}
                >

                    <Row>
                        <Col xs={20}></Col>
                        <Col md={1}>
                            {/* {excelExport} */}
                        </Col>
                        <Col md={1}>
                            {/* <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader> */}
                        </Col>
                        <Col xs={2}>
                            <AntButton hidden={!this.state.hiddenInputs} style={{ border: "0px" }} onClick={() => this.onClickCreateNew()} icon={<CreateIcon color="secondary" fontSize="large" />}></AntButton>
                            <AntButton hidden={this.state.hiddenInputs} style={{ border: "0px" }} onClick={() => this.onClickCreateNew()} icon={<RemoveCircle color="error" fontSize="large" />}></AntButton>
                        </Col>
                    </Row>
                    <div hidden={this.state.hiddenInputs}>
                        {/* {
                        <Form.Item
                            {...layout2}
                            label={<FormattedMessage id="todo" defaultMessage="Company" />}
                            name="companyLineAge"
                            rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please select company" /> }]} >

                            <Cascader options={this.state.companySelectItems} value={this.state.companyLineAge} onChange={(value, selectedOptions) => {
                                this.setState({
                                    companyId: Boolean(value) && value.length > 0 ? value[value.length - 1] : null,
                                    companyLineAge: value,
                                    selectedOptions: selectedOptions

                                });

                                // this.loadData(selectedOptions); 

                            }} changeOnSelect />
                           
                        </Form.Item>
                    } */}

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Project Name" />}
                                name="projectFullName"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type project name" /> }]}
                            >

                                <Input placeholder="Project Name" style={{ width: '100%' }} id="todo" value={this.state.projectFullName} onChange={(e) => {

                                    this.setState({ projectFullName: e.target.value })
                                }} />


                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Project Short Name" />}
                                name="projectShortName"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type project short name" /> }]}
                            >

                                <Input placeholder="Project Short Name" style={{ width: '100%' }} id="todo" value={this.state.projectShortName} onChange={(e) => {

                                    this.setState({ projectShortName: e.target.value })
                                }} />


                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Company/Project Code" />}
                                name="projectCode"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type project code" /> }]}
                            >

                                <Input placeholder="Project Code" style={{ width: '100%' }} id="todo" value={this.state.projectCode} onChange={(e) => {

                                    this.setState({ projectCode: e.target.value })
                                }} />


                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}

                                label={<FormattedMessage id="todo" defaultMessage="Client" />}
                                name="clientId"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="This field is required" /> }]} >
                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            style={{ width: "100%" }} placeholder="Client" id="DefinitionsType" value={this.state.clientId} onChange={(value) => {
                                                this.formRef.current.setFieldsValue({
                                                    clientId: value
                                                });
                                                this.setState({ clientId: value })
                                            }}
                                            dropdownRender={menu => (
                                                <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(e) => {

                                                            this.setState({
                                                                codeName: e.target.value
                                                            });

                                                        }} />
                                                        <a
                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                            onClick={this.addItem}
                                                        >
                                                            <PlusOutlined /> Add item
                                                        </a>
                                                    </div>
                                                </div>
                                            )}

                                        >
                                            <Option key={null} value={null}>Select</Option>
                                            {this.state.clientSelectItems.map(i => (
                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>

                            </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="projectTitle" defaultMessage="Project Title" />}
                                name="projectTitle"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type project title" /> }]}
                            >

                                <Input placeholder="Project Title" style={{ width: '100%' }} id="todo" value={this.state.projectTitle} onChange={(e) => {

                                    this.setState({ projectTitle: e.target.value })
                                }} />


                            </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Country" />}
                                name="countryId"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please select country" /> }]} >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(value) => {
                                        this.setState({
                                            countryId: value,

                                        });

                                    }}>
                                    <Option key={null} value={null}>Select</Option>
                                    {
                                        this.state.countrySelectItems.map(i => <Option value={i.key}>{i.value}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout2}

                                label={<FormattedMessage id="locationId" defaultMessage="Location" />}
                                name="locationId"
                                rules={[{ required: true, message: <FormattedMessage id="locationId" defaultMessage="This field is required" /> }]} >
                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            style={{ width: "100%" }} placeholder="Location" id="DefinitionsType" value={this.state.locationId} onChange={(value) => {
                                                this.formRef.current.setFieldsValue({
                                                    locationId: value
                                                });
                                                this.setState({ locationId: value })
                                            }}
                                            dropdownRender={menu => (
                                                <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(e) => {

                                                            this.setState({
                                                                codeName: e.target.value
                                                            });

                                                        }} />
                                                        <a
                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                            onClick={this.addLocation}
                                                        >
                                                            <PlusOutlined /> Add item
                                                        </a>
                                                    </div>
                                                </div>
                                            )}

                                        >
                                            <Option key={null} value={null}>Select</Option>
                                            {this.state.clientSelectItems.map(i => (
                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>

                            </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Address" />}
                                name="address"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type address" /> }]} >
                                <Input placeholder="Address" id="domain" value={this.state.address}
                                    onChange={(e) => this.setState({ address: e.target.value })} />


                            </Form.Item>
                        }

                        {<Form.Item
                            {...layout2}
                            label={<FormattedMessage id="contractNoLabel" defaultMessage="Contract No" />}
                            name="contractNo"
                            rules={[{ required: false, message: <FormattedMessage id="contractNoRuleMessage" defaultMessage="Please type contract no" /> }]}
                        >

                            <Input placeholder="Contract No" style={{ width: '100%' }} id="contractNo" value={this.state.contractNo} onChange={(e) => {

                                this.setState({ contractNo: e.target.value })
                            }} />


                        </Form.Item>
                        }

                        {<Form.Item
                            {...layout2}
                            label={<FormattedMessage id="biNoLabel" defaultMessage="BI No" />}
                            name="biNo"
                            rules={[{ required: false, message: <FormattedMessage id="biNoRuleMessage" defaultMessage="Please type contract no" /> }]}
                        >

                            <Input placeholder="BI No" style={{ width: '100%' }} id="biNo" value={this.state.biNo} onChange={(e) => {

                                this.setState({ biNo: e.target.value })
                            }} />


                        </Form.Item>
                        }

                        {<Form.Item
                            {...layout2}
                            label={<FormattedMessage id="joNo" defaultMessage="JO No" />}
                            name="joNo"
                            rules={[{ required: false, message: <FormattedMessage id="joNo" defaultMessage="Please type contract no" /> }]}
                        >

                            <Input placeholder="JO No" style={{ width: '100%' }} id="joNo" value={this.state.joNo} onChange={(e) => {

                                this.setState({ joNo: e.target.value })
                            }} />


                        </Form.Item>
                        }


                        <div hidden={this.state.hideSave}>
                            {
                                <Row gutter={8}>
                                    <Col span={8}></Col>

                                    <Col span={8}>
                                        {
                                            <Button id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                                        }
                                    </Col>
                                </Row>

                            }
                        </div>



                        <div hidden={this.state.hideUpdate}>
                            {
                                <Row gutter={8}>
                                    <Col span={8}></Col>

                                    <Col span={4}>
                                        <Button id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                                    </Col>
                                    <Col span={4}>
                                        <Button id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                                    </Col>

                                </Row>
                            }
                        </div>
                    </div>
                </Form>



                <div style={{ marginTop: '2rem', marginBottom: '1rem' }} className="content-section implementation">
                    <Table
                        size="small"
                        bordered
                        scroll={{ y: 500, x: 900 }}
                        columns={columns}
                        dataSource={this.state.tableList}
                        loading={this.state.loading} onChange={this.handleTableChange}
                        pagination={this.state.pagination}
                    />

                </div>
                <Modal
                    show={this.state.modalDeleteShow}
                    onHide={this.showOrHideDeleteModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</Button>
                        <Button variant="danger" onClick={this.delete} >Delete</Button>
                    </Modal.Footer>
                </Modal>
            </div>


        );


    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Project)