import React, { useState, useEffect } from "react";
import { handleRequest } from "../../ApiConnector";
import { error } from "../../MessageHelper";
import { Table, Col, Row, DatePicker } from "antd";
import { FormattedMessage } from "react-intl";
import moment from "moment";

const QualityControlResults = () => {
  const [tableList, setTableList] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(6, "M"));
  const [endDate, setEndDate] = useState(moment());

  useEffect(() => {
    asyncFetch();
  }, [startDate, endDate]);

  const asyncFetch = async () => {
    const search = {
      startDate: startDate
        ? startDate.toDate()
        : moment()
          .subtract(6, "M")
          .toDate(),
      endDate: endDate ? endDate.toDate() : moment().toDate()
    };
    console.log(search);
    var response = await handleRequest("POST", "/api/dashboard/qualityControlResults", search);
    if (response.type === "ERROR") {
      error(response);
    } else {
      setTableList(Boolean(response.data) ? response.data : null);
    }
  };

  const columns = [
    {
      title: "",
      key: "type",
      render: record => {
        return record.type;
      },
    },
    {
      title: <FormattedMessage id="QualityControlResults.Factory" defaultMessage="Fabrika" />,
      key: "id",
      children: [
        {
          title: <FormattedMessage id="QualityControlResults.MaterialIntroduction" defaultMessage="Malzeme Girişi" />,
          key: "materialIntroduction",
          render: record => {
            return record.materialIntroduction;
          },
          align: "center"
        },
        {
          title: <FormattedMessage id="QualityControlResults.Production" defaultMessage="Üretim" />,
          key: "production",
          render: record => {
            return record.production;
          },
          align: "center"
        }
      ]
    },
    {
      title: <FormattedMessage id="QualityControlResults.Field" defaultMessage="Saha" />,
      key: "id",
      children: [
        {
          title: <FormattedMessage id="QualityControlResults.MaterialDelivery" defaultMessage="Malzeme Teslimi" />,
          key: "materialDelivery",
          render: record => {
            return record.materialDelivery;
          },
          align: "center"
        },
        {
          title: <FormattedMessage id="QualityControlResults.Assembly" defaultMessage="Montaj" />,
          key: "assembly",
          render: record => {
            return record.assembly;
          },
          align: "center"
        }
      ]
    }
  ];

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={12}></Col>
        <Col lg={6}>
          <DatePicker
            placeholder="Start Date"
            style={{ width: "100%" }}
            value={startDate}
            onChange={date => {
              setStartDate(date);
            }}
          />
        </Col>
        <Col lg={6}>
          <DatePicker
            placeholder="End Date"
            style={{ width: "100%" }}
            value={endDate}
            onChange={date => {
              setEndDate(date);
            }}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={24}>
          <Table
            bordered
            columns={columns}
            dataSource={tableList}
            pagination={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default QualityControlResults;
