import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { handleRequest } from "../ApiConnector";
import { showError } from "../MessageHelper";

export default function PrepareFormItemProps(field, form) {
  const parentValues = useSelector(state => {
    return state.dependantSelect.value;
  });
  const pValue = parentValues[field.visibilityParentName + "ParentValue"];

  let obj = {
    name: field.inputName,
    label: !Boolean(field.hideLabel) ? <FormattedMessage id={field.labelLanguageKey} defaultMessage={field.label} /> : null
  };
  if (Boolean(field.showWhatIs)) {
    obj.tooltip = <FormattedMessage id={field.whatIsLanguageKey} defaultMessage={field.whatIsDescription} />;
  }
  if (field.required !== undefined) {
    obj.rules = [
      {
        required: field.required,
        message: <FormattedMessage id="GENRAL.REQUIRED" defaultMessage={"Please type " + field.label} />
      }
    ];
  }
  if (pValue !== undefined) {
    obj.hidden = Boolean(field.showWhenParentValue) ? pValue !== field.showWhenParentValue : pValue === field.hideWhenParentValue;
    if (Boolean(obj.hidden) && Boolean(form)) {
      form.current.setFieldsValue({ [field.inputName]: null });
    }
  }
  return obj;
}

export const GetDynamicFormItems = async formName => {
  let url = "/api/pageFormInputCompany/all/" + formName;
  const response = await handleRequest("GET", url);
  if (response.type === "ERROR") {
    showError(response.message);
  } else {
    response.data.pageFormInputCompanyList = PrepareInputProps(response.data.pageFormInputCompanyList);
    response.data.pageFormInputCompanyList.forEach(item => {
      if (item.inputType === "FORM_LIST" && item.formListPageForm) {
        item.formListPageForm.pageFormInputCompanyList = PrepareInputProps(item.formListPageForm.pageFormInputCompanyList);
      }
    });
    return response.data;
  }
  return null;
};

export const GetDynamicFormItemsToHideForNonDynamicPage = async formName => {
  let url = "/api/pageFormInputCompany/allForNonDynamic/" + formName;
  const response = await handleRequest("GET", url);
  if (response.type === "ERROR") {
    showError(response.message);
  } else {
    // response.data.pageFormInputCompanyList = PrepareInputProps(response.data.pageFormInputCompanyList);
    var returnObj = {};
    response.data.pageFormInputCompanyList.forEach(item => {
      if (item.showOnForm === false) {
        returnObj["hide_" + item.inputName] = true;
      }
    });
    return returnObj;
  }
  return null;
};

function PrepareInputProps(inputs) {
  let data = inputs;
  if (Boolean(data) && data.length > 0) {
    data.forEach(item => {
      if (item.pageFormInput.inputProps) {
        item.inputProps = JSON.parse(item.pageFormInput.inputProps);
        item.inputProps.forEach(input => {
          item[input.key] = input[input.key];
        });
      }
    });
  }
  return data;
}

function PrepareMomentDateEdit(date) {
  return Boolean(date) ? moment(date) : null;
}

export function PrepareFileUploadEdit(file) {
  let uploadedPhotos = [];
  let photoPath = Boolean(file) ? file.split(",") : [];
  if (Boolean(photoPath) && photoPath.length > 0) {
    photoPath.forEach((item, index) => {
      let obj = { uid: index, name: item.split("download/")[1], url: item, thumbUrl: item };
      uploadedPhotos.push(obj);
    });
  }
  return uploadedPhotos;
}

const EditFunctions = {
  FILEUPLOAD: PrepareFileUploadEdit,
  FILEUPLOAD_IMAGE: PrepareFileUploadEdit,
  DATEPICKER: PrepareMomentDateEdit,
  DATEPICKER_WITH_TIME: PrepareMomentDateEdit,
  MONTHPICKER: PrepareMomentDateEdit,
  YEARPICKER: PrepareYearMomentEdit
};

const SaveFunctions = {
  FILEUPLOAD: PrepareFileUploadSave,
  FILEUPLOAD_IMAGE: PrepareFileUploadSave,
  YEARPICKER: PrepareYearSave,
  CASCADER: PrepareCascaderSave
};

export function PrepareFileUploadSave(file) {
  let pathList = [];
  if (Boolean(file))
    file.forEach(item => {
      if (Boolean(item.response) && Boolean(item.response.url)) pathList.push(item.response.url);
      else if (Boolean(item.url)) pathList.push(item.url);
    });
  return pathList.toString();
}

function PrepareYearSave(date) {
  return Boolean(date) ? parseInt(date.format("YYYY")) : undefined;
}
function PrepareYearMomentEdit(date) {
  return Boolean(date) ? moment(date + "", "YYYY") : undefined;
}

function PrepareCascaderSave(values) {
  return Boolean(values) && values.length > 0 ? values[values.length - 1] : null;
}

export function PrepareRowForEdit(row, inputs) {
  document.getElementById("kt_scrolltop").click();

  inputs.forEach(p => {
    if (Boolean(EditFunctions[p.inputType])) {
      row[p.inputName] = EditFunctions[p.inputType](row[p.inputName]);
    }
    if (p.inputType === "FORM_LIST" && row[p.inputName] && row[p.inputName].length > 0) {
      row[p.inputName].forEach(item => {
        item = PrepareRowForEdit(item, p.formListPageForm.pageFormInputCompanyList);
      });
    }
  });
  return row;
}

export function PrepareRowForSave(row, inputs) {
  inputs.forEach(p => {
    if (Boolean(SaveFunctions[p.inputType])) {
      row[p.inputName] = SaveFunctions[p.inputType](row[p.inputName]);
    }
    if (p.inputType === "FORM_LIST" && row[p.inputName] && row[p.inputName].length > 0) {
      row[p.inputName].forEach(item => {
        item = PrepareRowForSave(item, p.formListPageForm.pageFormInputCompanyList);
      });
    }
  });
  return row;
}

// export function ImageGalleryFromUrls(paths, galleryName) {
//   const [gallery, setGallery] = React.useState(false);
//   if (Boolean(paths) && paths.length > 0) {
//     let pathList = paths.split(",");

//     return (
//       <>
//         {Boolean(paths) && paths.length > 0 ? (
//           <Image
//             width={80}
//             preview={{
//               visible: false
//             }}
//             src={pathList[0].trim()}
//             onClick={() => setGallery(true)}
//           />
//         ) : null}
//         {Boolean(pathList) && pathList.length > 0 ? (
//           <div style={{ display: "none" }}>
//             <Image.PreviewGroup
//               preview={{
//                 visible: gallery,
//                 onVisibleChange: vis => setGallery(vis)
//               }}
//             >
//               {pathList.map((field, i) => (
//                 <Image key={i} src={field} />
//               ))}
//             </Image.PreviewGroup>
//           </div>
//         ) : null}
//       </>
//     );
//   }
// }

export function DownloadIconsFromPaths(paths) {
  let path = paths;
  if (Boolean(path)) {
    path = path.split(",");
    let buttons = path.map((item, index) => {
      return (
        <Button key={index} href={item} target="_blank" type="primary" shape="round" icon={<DownloadOutlined />}>
          <span>{index + 1}</span>
        </Button>
      );
    });
    return buttons;
  } else return "";
}
