import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Button as AntButton, Col, Form, Input, Row, Checkbox, Modal, Space, Select, Divider } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess } from "../../MessageHelper";
import ActionMenu from "../CustomMenu/ActionMenu";
import DraggableModal from "../../Components/DraggableModal";
import { OrderList } from "primereact/orderlist";
import SortIcon from "@material-ui/icons/Sort";

const { Option } = Select;

export class StockCardPurchaseCriteria extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "stockCardPurchaseCriteria",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      filePath: "",
      fileList: [],
      details: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initPage();
  };

  initPage = async params => {
    console.log("AAA", this.props);
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initPage();
    }
  };

  fillComboboxes = async () => {
    this.fillCode("purchasingCriteriaId");

    var responseUnits = await handleRequest("GET", "/api/" + this.state.controllerName + "/unitTypes");
    if (responseUnits.type === "ERROR") {
      error(responseUnits);
    } else {
      this.setState({
        unitTypes: Boolean(responseUnits.data) ? responseUnits.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.props.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    const newItem = {
      categoryId: this.props.categoryId,
    };
    if (this.props.descriptionType === "ADD") {
      newItem.descriptionId = this.props.descriptionId;
      newItem.descriptionSelectId = null;
    } else {
      newItem.descriptionId = null;
      newItem.descriptionSelectId = this.props.descriptionId;
    }
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/getPurchaseCriteria", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      var row = Boolean(response.data) ? response.data : null;
      if (Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(row)) {
        let details = [];
        if (row.piece) {
          details.push({ id: row.pieceCriteriaId, value: "Piece", orderNo: row.orderNo });
        }
        if (row.length) {
          details.push({ id: row.lengthCriteriaId, value: "Length", orderNo: row.orderNo });
        }
        if (row.weight) {
          details.push({ id: row.weightCriteriaId, value: "Weight", orderNo: row.orderNo });
        }

        if (Boolean(row.stockCardPurchaseCriteriaDetails) && row.stockCardPurchaseCriteriaDetails.length > 0) {
          row.stockCardPurchaseCriteriaDetails.forEach(element => {
            details.push({ id: element.id, value: element.purchasingCriteriaName, orderNo: element.orderNo });
          });
        }
        this.formRef.current.setFieldsValue({
          ...row
        });

        this.setState({
          details: details.sort((a, b) => a.orderNo - b.orderNo),
        });
      } else {
        if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
          this.formRef.current.resetFields();
        }
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      categoryId: this.props.categoryId,
    };
    if (this.props.descriptionType === "ADD") {
      newItem.descriptionId = this.props.descriptionId;
      newItem.descriptionSelectId = null;
    } else {
      newItem.descriptionId = null;
      newItem.descriptionSelectId = this.props.descriptionId;
    }

    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/save", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.props.saveStockCardPurchaseCriteria();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  saveOrder = async () => {
    const list = this.state.details;
    list.forEach((element, i) => {
      element.orderNo = i;
    });

    this.formRef.current.setFieldsValue({
      details: list
    });

    var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/order", list);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        modalOrderShow: false
      });
    }
  };

  template(item) {
    return (
      <div className="p-clearfix">
        <div style={{ fontSize: "12px", margin: "5px 5px 0 0" }}>{item.value}</div>
      </div>
    );
  }

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 8,
          offset: 0
        },
        sm: {
          span: 8,
          offset: 8
        }
      }
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                onClick={() => {
                  this.setState({
                    modalOrderShow: true,
                  });
                }}
                icon={<SortIcon fontSize="large" color="secondary" />}
              ></AntButton>
            </Col>
          </Row>

          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="StockCardPurchaseCriteria.piece" defaultMessage="Piece" />}
              name="piece"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              valuePropName="checked"
            >
              <Checkbox style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="StockCardPurchaseCriteria.length" defaultMessage="Length" />}
              name="length"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              valuePropName="checked"
            >
              <Checkbox style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="StockCardPurchaseCriteria.weight" defaultMessage="Weight" />}
              name="weight"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              valuePropName="checked"
            >
              <Checkbox style={{ width: "100%" }} />
            </Form.Item>
          }

          <Form.List name="stockCardPurchaseCriteriaDetails">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...layout}
                    key={field.key}
                    label={<FormattedMessage id="StockCard.PurchasingCriteria" defaultMessage={"Purchasing Criteria"} />}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, "purchasingCriteriaId"]}
                      extra={<FormattedMessage id="StockCard.purchasingCriteriaId" defaultMessage="Purchasing Criteria" />}
                      style={{
                        display: "inline-block",
                        width: "calc(40%)",
                        marginBottom: "5px"
                      }}
                      rules={[
                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                      ]}
                    >
                      <Select
                        allowClear
                        style={{ width: "100%" }}
                        dropdownRender={menu => (
                          <div>
                            {menu}
                            <Divider style={{ margin: "4px 0" }} />
                            <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                              <Input
                                style={{ flex: "auto" }}
                                value={this.state.codeName}
                                onChange={event => {
                                  this.setState({
                                    codeName: event.target.value
                                  });
                                }}
                              />
                              <a
                                style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                onClick={() => this.addCode("purchasingCriteriaId")}
                              >
                                <PlusOutlined />
                              </a>
                            </div>
                          </div>
                        )}
                      >
                        {Boolean(this.state["purchasingCriteriaIdSelectItems"]) &&
                          this.state["purchasingCriteriaIdSelectItems"].map(i => (
                            <Option key={i.id} value={i.id}>
                              {i.name}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      {...field}
                      name={[field.name, "unitTypeId"]}
                      extra={<FormattedMessage id="StockCardPurchaseCriteria.UnitType" defaultMessage="Unit Type" />}
                      style={{
                        display: "inline-block",
                        width: "calc(40%)",
                        marginBottom: "5px",
                        marginLeft: "5px"
                      }}
                      rules={[
                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                      ]}
                    >
                      <Select
                        allowClear
                        style={{
                          width: "100%"
                        }}
                        placeholder="Please select"
                        showSearch
                        optionFilterProp="children"
                      >
                        {Boolean(this.state["unitTypes"]) &&
                          this.state["unitTypes"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>

                    <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} />
                  </Form.Item>
                ))}

                <Form.Item {...tailFormItemLayout}>
                  <AntButton type="dashed" onClick={() => add()} style={{ width: "100%" }} icon={<PlusOutlined />}>
                    <FormattedMessage id="Stockcard.Addnewpurchasingcriteria" defaultMessage="Add new purchasing criteria" />
                  </AntButton>
                </Form.Item>
              </>
            )}
          </Form.List>
          {
            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Col>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            </Row>
          }
        </Form>

        <Modal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={500}
          okButtonProps={{ hidden: true }}
          visible={this.state.modalDeleteShow}
          onCancel={() => {
            this.setState({ modalDeleteShow: false });
          }}
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalDeleteShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Space>
          ]}
        >
          <p>
            <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
          </p>
        </Modal>

        <DraggableModal
          title={<FormattedMessage id="StockCardPurchaseCriteria.Order" defaultMessage="Order" />}
          centered
          width={600}
          okButtonProps={{ hidden: true }}
          open={this.state.modalOrderShow}
          onCancel={() => {
            this.setState({ modalOrderShow: false });
          }}
          footer={[
            <Space>
              <Button id="SaveButton" onClick={() => this.saveOrder()} style={{ width: "100%" }} variant="success">
                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
              </Button>
            </Space>
          ]}
          content={
            <>
              {Boolean(this.state.details) && this.state.details.length > 0 && (
                <OrderList
                  value={this.state.details}
                  responsive={true}
                  itemTemplate={this.template}
                  onChange={e => this.setState({ details: e.value })}
                ></OrderList>
              )}
            </>
          }
        />
      </div >
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StockCardPurchaseCriteria);