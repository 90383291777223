import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess, showWarningBasic } from "../../MessageHelper";
import ActionMenu from "../CustomMenu/ActionMenu";
import moment from "moment";
import { IApplicationContribution } from "@antv/xflow-core";
import { message, Col, Row, Table, Space, Select, Button as AntButton, Modal as AntModal, Spin, Input, DatePicker } from "antd";
import { FormattedMessage } from "react-intl";
import { SearchOutlined } from "@ant-design/icons";
const { Option } = Select;

function sortDate(a, b) {
  if (a.year === b.year) {
    if (a.month === b.month) {
      if (a.day === b.day) {
        return 0;
      }
      return a.day < b.day ? -1 : 1;
    } else return a.month < b.month ? -1 : 1;
  } else {
    return a.year < b.year ? -1 : 1;
  }
}
const CashFlowLineChart = () => {
  const [data, setData] = useState([]);
  const [period, setPeriod] = useState("DAILY");
  const [startDate, setStartDate] = useState(moment().subtract(6, "M"));
  const [endDate, setEndDate] = useState(moment());

  useEffect(() => {
    asyncFetch();
  }, [period, startDate, endDate]);

  const asyncFetch = async () => {
    const search = {
      period,
      startDate: startDate
        ? startDate.toDate()
        : moment()
            .subtract(6, "M")
            .toDate(),
      endDate: endDate ? endDate.toDate() : moment().toDate()
    };

    console.log(search);
    var response = await handleRequest("POST", "/api/cashflow/linechart", search);
    if (response.type === "ERROR") {
      error(response);
    } else {
      let data = Boolean(response.data) ? response.data : null;
      let chartData = [];

      if (Boolean(data.outgoings) && data.outgoings.length > 0) {
        data.outgoings.forEach(outgoing => {
          chartData.push({
            ...outgoing,
            category: "Outgoing Plan"
          });
        });
      }

      if (Boolean(data)) {
        if (Boolean(data.incomes) && data.incomes.length > 0) {
          data.incomes.forEach(income => {
            chartData.push({
              ...income,
              category: "Income Plan"
            });
          });
        }

        if (Boolean(data.incomesActualized) && data.incomesActualized.length > 0) {
          data.incomesActualized.forEach(ia => {
            chartData.push({
              ...ia,
              category: "Income Actualized"
            });
          });
        }

        if (Boolean(data.outgoingsActualized) && data.outgoingsActualized.length > 0) {
          data.outgoingsActualized.forEach(oa => {
            chartData.push({
              ...oa,
              category: "Outgoing Actualized"
            });
          });
        }
      }
      chartData.sort((a, b) => sortDate(a, b));
      console.log(chartData);
      setData(chartData);
    }
  };
  const config = {
    data,
    xField: "dateStr",
    yField: "amount",
    seriesField: "category",
    yAxis: {
      label: {
        formatter: v => `${v}`
      }
    },
    color: ["#1979C9", "#D62A0D", "#FAA219", "#FF99C3"]
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={6}></Col>
        <Col lg={6}>
          <DatePicker
            placeholder="Start Date"
            style={{ width: "100%" }}
            value={startDate}
            onChange={date => {
              setStartDate(date);
            }}
          />
        </Col>
        <Col lg={6}>
          <DatePicker
            placeholder="End Date"
            style={{ width: "100%" }}
            value={endDate}
            onChange={date => {
              setEndDate(date);
            }}
          />
        </Col>
        <Col lg={6}>
          <Select
            placeholder="Period"
            defaultActiveFirstOption
            allowClear
            value={period}
            className="w-100"
            onChange={value => {
              setPeriod(value);
            }}
          >
            <Option key={"DAILY"} value={"DAILY"}>
              <FormattedMessage id="period.day" defaultMessage="Day" />
            </Option>
            <Option key={"MONTHLY"} value={"MONTHLY"}>
              <FormattedMessage id="period.month" defaultMessage="Month" />
            </Option>
            <Option key={"YEARLY"} value={"YEARLY"}>
              <FormattedMessage id="period.year" defaultMessage="Year" />
            </Option>
          </Select>
        </Col>
        {/* <Col lg={4}>
          <AntButton
            type="dashed"
            icon={<SearchOutlined />}
            onClick={() => {
              asyncFetch();
            }}
          >
            <FormattedMessage id="GeneralButtonSearch" defaultMessage="Search" />
          </AntButton>
        </Col> */}
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={24}>
          <Line {...config} />
        </Col>
      </Row>
    </>
  );
};

export default CashFlowLineChart;
