import { Checkbox, Form, Switch } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import PrepareFormItemProps from "./ElementUtils";
import { changeParentSelectValue } from "./redux/dependantSelectSlice";

const CheckboxComponent = ({ field, layout }) => {
  let checkLabel = null;
  let localLayout = {};
  const dispatch = useDispatch();
  let otherProps = {};
  let switchProps = {};
  if (field.children) {
    otherProps.onChange = e => {
      dispatch(changeParentSelectValue({ key: field.inputName, value: e.target.checked.toString() }));
    };
    switchProps.onChange = e => {
      dispatch(changeParentSelectValue({ key: field.inputName, value: e.toString() }));
    };
  }
  const checkElement = () => {
    switch (field.checkType) {
      case "switch":
        return <Switch {...switchProps} />;
      default:
        return Boolean(field.labelRight) ? <Checkbox {...otherProps}>{checkLabel}</Checkbox> : <Checkbox {...otherProps} />;
    }
  };
  const getFormItemProps = () => {
    let p = PrepareFormItemProps(field);
    if (field.labelRight) {
      localLayout = {
        wrapperCol: {
          xs: {
            span: layout.wrapperCol.span,
            offset: 0
          },
          sm: {
            span: layout.wrapperCol.span,
            offset: layout.labelCol.span
          }
        },
        style: { ...layout.style }
      };
      checkLabel = p.label;
      delete p.label;
    } else {
      localLayout = { ...layout };
    }
    return p;
  };
  let formItemProps = getFormItemProps();
  return (
    <>
      <Form.Item {...localLayout} {...formItemProps} valuePropName="checked" initialValue={false}>
        {checkElement()}
      </Form.Item>
    </>
  );
};

export default CheckboxComponent;
