import { FilterOutlined, ZoomInOutlined } from "@ant-design/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Button as AntButton, Checkbox, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Table, Tooltip } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { inputTypeSelectItems } from "../../Components/helpers/ComponentProperties";
import { error, showSuccess } from "../../MessageHelper";
import ActionMenu from "../CustomMenu/ActionMenu";

const { RangePicker } = DatePicker;
const { Option } = Select;
let timerId;
const debounceFunction = (func, delay) => {
  // Cancels the setTimeout method execution
  clearTimeout(timerId);
  // Executes the func after delay time.
  timerId = setTimeout(func, delay);
};

export class PageFormInput extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      inputTypeSelectItems: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["10", "25", "50", "100", "1000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.setState({
      inputTypeSelectItems: inputTypeSelectItems
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      loading: false,
      filePath: "",
      inputProps: null
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    let inputProps = [];
    let a = inputTypeSelectItems.find(x => x.key === row.inputType).inputProps;
    if (row.inputProps) {
      inputProps = [...a];
      let rowProps = JSON.parse(row.inputProps);
      for (let i = 0; i < inputProps.length; i++) {
        let p = rowProps.find(x => x.key === inputProps[i].key);
        if (p) {
          inputProps[i][inputProps[i].key] = p[inputProps[i].key];
        }
      }
    }
    this.formRef.current.setFieldsValue({
      ...row,
      inputProps: inputProps
    });
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      inputProps: a
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/pageFormInput/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/pageFormInput/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = { ...values, id: this.state.id };
    if (newItem.inputProps) {
      newItem.inputProps.forEach(element => {
        delete element.element;
      });
      newItem.inputProps = JSON.stringify(newItem.inputProps);
    }

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/pageFormInput", newItem);
    } else {
      response = await handleRequest("PUT", "/api/pageFormInput/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="PageFormInput.pageFormId" defaultMessage="Form" />,
        key: "pageFormId",
        render: record => {
          return Boolean(record.pageForm) ? record.pageFormId + "," + record.pageForm.formName : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.pageFormId) ? this.state.pagination.pageFormId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  pageFormId: e.target.value,
                  pageFormIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                debounceFunction(() => {
                  this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                }, 500);
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.pageFormIdColor) ? this.state.pagination.pageFormIdColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PageFormInput.inputName" defaultMessage="Input Name" />,
        key: "inputName",
        render: record => {
          return record.inputName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.inputName) ? this.state.pagination.inputName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  inputName: e.target.value,
                  inputNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                debounceFunction(() => {
                  this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                }, 500);
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.inputNameColor) ? this.state.pagination.inputNameColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PageFormInput.label" defaultMessage="Label" />,
        key: "label",
        render: record => {
          return record.label;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.label) ? this.state.pagination.label : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  label: e.target.value,
                  labelColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                debounceFunction(() => {
                  this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                }, 500);
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.labelColor) ? this.state.pagination.labelColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PageFormInput.inputType" defaultMessage="Input Type" />,
        key: "inputType",
        render: record => {
          return record.inputType;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              allowClear
              showSearch
              style={{ width: "100%" }}
              placeholder="Input Type"
              onChange={async value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  inputType: value,
                  inputTypeColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            >
              {Boolean(this.state["inputTypeSelectItems"]) &&
                this.state["inputTypeSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.key}
                  </Option>
                ))}{" "}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.inputTypeColor) ? this.state.pagination.inputTypeColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PageFormInput.showOnForm" defaultMessage="Show On Form" />,
        key: "showOnForm",
        render: record => {
          return record.showOnForm ? <p className="text-success">True</p> : <p className="text-danger">False</p>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              style={{ flex: "auto" }}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  current: 1,
                  showOnForm: e.target.value === "true" ? true : e.target.value === "false" ? false : undefined,
                  showOnFormColor: Boolean(e.target.value === "true") ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            >
              <Radio.Button>All</Radio.Button>
              <Radio.Button value="true">Shown</Radio.Button>
              <Radio.Button value="false">Hidden</Radio.Button>
            </Radio.Group>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.showOnFormColor) ? this.state.pagination.showOnFormColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PageFormInput.required" defaultMessage="Required" />,
        key: "required",
        render: record => {
          return record.required;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.required) ? this.state.pagination.required : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  required: e.target.value,
                  requiredColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.requiredColor) ? this.state.pagination.requiredColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PageFormInput.rowIndex" defaultMessage="Row Index" />,
        key: "rowIndex",
        render: record => {
          return record.rowIndex;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.rowIndex) ? this.state.pagination.rowIndex : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  rowIndex: e.target.value,
                  rowIndexColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.rowIndexColor) ? this.state.pagination.rowIndexColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PageFormInput.description" defaultMessage="Description" />,
        key: "description",
        render: record => {
          return record.description;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.description) ? this.state.pagination.description : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  description: e.target.value,
                  descriptionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.descriptionColor) ? this.state.pagination.descriptionColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PageFormInput.whatIsLanguageKey" defaultMessage="What Is Language Key" />,
        key: "whatIsLanguageKey",
        render: record => {
          return record.whatIsLanguageKey;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.whatIsLanguageKey) ? this.state.pagination.whatIsLanguageKey : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  whatIsLanguageKey: e.target.value,
                  whatIsLanguageKeyColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.whatIsLanguageKeyColor) ? this.state.pagination.whatIsLanguageKeyColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PageFormInput.whatIsDescription" defaultMessage="What Is Description" />,
        key: "whatIsDescription",
        render: record => {
          return record.whatIsDescription;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.whatIsDescription) ? this.state.pagination.whatIsDescription : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  whatIsDescription: e.target.value,
                  whatIsDescriptionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.whatIsDescriptionColor) ? this.state.pagination.whatIsDescriptionColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PageFormInput.labelLanguageKey" defaultMessage="Label Language Key" />,
        key: "labelLanguageKey",
        render: record => {
          return record.labelLanguageKey;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.labelLanguageKey) ? this.state.pagination.labelLanguageKey : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  labelLanguageKey: e.target.value,
                  labelLanguageKeyColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.labelLanguageKeyColor) ? this.state.pagination.labelLanguageKeyColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PageFormInput.inputProps" defaultMessage="Input Props" />,
        key: "inputProps",
        render: record => {
          let json = JSON.parse(record.inputProps);
          if (Boolean(json)) {
            let p = json.map((item, index) => {
              return (
                <div className="d-block" key={index}>
                  {item.key + ":" + item[item.key]}
                </div>
              );
            });
            return (
              <Tooltip placement="topLeft" title={p} style={{ maxWidth: "300px" }}>
                <AntButton
                  style={{ border: "0px", alignContent: "center" }}
                  type="text"
                  icon={<ZoomInOutlined style={{ fontSize: "20px", color: "#0b5fcc" }} />}
                />
              </Tooltip>
            );
          }
          return record.inputProps;
        }
      },

      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={21}></Col>
            <Col md={1}></Col>
            <Col md={1}></Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageFormInput.pageFormId" defaultMessage="Form" />}
                name="pageFormId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="Form" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageFormInput.inputName" defaultMessage="Input Name" />}
                name="inputName"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="Input Name" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageFormInput.label" defaultMessage="Label" />}
                name="label"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="Label" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageFormInput.inputType" defaultMessage="Input Type" />}
                name="inputType"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Input Type"
                  onChange={value => {
                    let props = inputTypeSelectItems.find(item => item.key === value);
                    if (!Boolean(props.inputProps)) {
                      this.setState(
                        {
                          inputProps: null
                        },
                        () => {
                          this.formRef.current.setFieldsValue({
                            inputProps: []
                          });
                        }
                      );
                      return;
                    }
                    this.setState(
                      {
                        inputProps: props.inputProps
                      },
                      () => {
                        this.formRef.current.setFieldsValue({
                          inputProps: props.inputProps
                        });
                      }
                    );
                  }}
                >
                  {Boolean(this.state["inputTypeSelectItems"]) &&
                    this.state["inputTypeSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.key}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageFormInput.required" defaultMessage="Required" />}
                name="required"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageFormInput.showOnForm" defaultMessage="Show On Form" />}
                name="showOnForm"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageFormInput.rowIndex" defaultMessage="Row Index" />}
                name="rowIndex"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber placeholder="Row Index" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageFormInput.description" defaultMessage="Description" />}
                name="description"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="Description" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageFormInput.whatIsLanguageKey" defaultMessage="What Is Language Key" />}
                name="whatIsLanguageKey"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="What Is Language Key" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageFormInput.whatIsDescription" defaultMessage="What Is Description" />}
                name="whatIsDescription"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="What Is Description" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageFormInput.labelLanguageKey" defaultMessage="Label Language Key" />}
                name="labelLanguageKey"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="Label Language Key" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.List name="inputProps">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map(field => {
                        if (!Boolean(field) || !Boolean(this.state.inputProps)) return null;
                        let f = this.state.inputProps[field.name];
                        if (!Boolean(f)) return null;
                        return (
                          <Form.Item
                            {...layout}
                            label={f.label}
                            key={field.key}
                            name={[field.name, f.key]}
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                          >
                            {f.element}
                          </Form.Item>
                        );
                      })}
                    </>
                  );
                }}
              </Form.List>
            }
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            rowKey={record => record.id}
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageFormInput);
