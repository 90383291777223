import { QuestionCircleOutlined } from "@ant-design/icons";
import { Form, Input, Menu, Tooltip } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import PrepareFormItemProps from "./ElementUtils";

const TextAreaComponent = ({ field, layout }) => {
  const { TextArea } = Input;
  // const menu = (
  //   <Menu>
  //     <Menu.Item key="1">
  //       <QuestionCircleOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />
  //       <Tooltip title={<FormattedMessage id={field.whatIsLanguageKey} defaultMessage={field.whatIsDescription} />}> What Is This?</Tooltip>
  //     </Menu.Item>
  //     {/* <Menu.Item key="2">2nd menu item</Menu.Item>
  //     <Menu.Item key="3">3rd menu item</Menu.Item> */}
  //   </Menu>
  // );
  return (
    <>
      <Form.Item {...layout} {...PrepareFormItemProps(field)}>
        <TextArea rows={3} placeholder={field.label} style={{ marginBottom: "5px" }} />
      </Form.Item>
    </>
  );
};

export default TextAreaComponent;
