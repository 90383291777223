import React, { Component } from "react";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import CustomMaterialMenu from "../CustomMenu/CustomMaterialMenu";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Select, Radio, Col, Row, Tree } from "antd";
import "antd/dist/antd.css";
import { FcFolder } from "react-icons/fc";
import { FileIcon, defaultStyles } from "react-file-icon";
import { error, showSuccess } from "../../MessageHelper";

const { Option } = Select;

function updateReadWrite(list, key, readWrite) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, readWrite: readWrite };
    }

    if (node.children) {
      return { ...node, children: updateReadWrite(node.children, key, readWrite) };
    }

    return node;
  });
}

export class UserFolder extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      userId: null,
      selectedFolderIds: [],
      selectedUserIds: [],
      readWrite: null,
      folderSelectItems: [],
      userSelectItems: [],
      companySelectItemms: [],
      selectedCompanyIds: [],
      projectSelectItems: [],
      selectedProjectIds: [],

      checkedKeys: [],
      halfCheckedKeys: [],
      checkedNodes: [],

      tableList: [],
      hideUpdate: true,
      hideSave: false
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();

    const folderTree = await handleRequest("GET", "/api/folders/tree/authorization");
    if (folderTree.data.length !== 0) {
      folderTree.data.forEach(element => {
        this.initialize(element);
      });

      this.setState({
        nodes: folderTree.data
      });
    } else {
      this.setState({
        nodes: []
      });
    }
  };

  refreshNodes = list => {
    return (
      Boolean(list) &&
      list.map(element => {
        this.initialize(element);

        return element;
      })
    );
  };

  initialize(element) {
    let obj = { ...element };
    element.originalTitle = Boolean(element.originalTitle) && element.originalTitle.length > 0 ? element.originalTitle : obj.title;

    if (element.type === "FOLDER") {
      element.icon = <FcFolder />;
      element.title = <span> {element.originalTitle} </span>;
    } else {
      element.isLeaf = true;

      element.title = <span style={{ padding: "10px" }}> {element.originalTitle} </span>;
    }
    if (element.type === "FILE")
      element.icon = (
        <div>
          {" "}
          <FileIcon extension={element.extension} {...defaultStyles[element.extension]} /> <br />{" "}
        </div>
      );
    if (element.type === "LOG")
      element.icon = (
        <div>
          {" "}
          <FileIcon extension={"LOG"} color={"#2596be"} /> <br />{" "}
        </div>
      ); //<FcFile />;

    if (Boolean(element.children) && element.children.length > 0) {
      element.children.forEach(child => {
        this.initialize(child);
      });
    }
  }

  initialize2(element) {
    let obj = { ...element };
    element.originalTitle = Boolean(element.originalTitle) && element.originalTitle.length > 0 ? element.originalTitle : obj.title;

    if (element.type === "FOLDER") {
      element.icon = <FcFolder />;
      element.title = (
        <>
          {" "}
          <span> {element.originalTitle}</span>{" "}
          <Radio.Group
            onChange={e => {
              element.readWrite = e.target.value;
              let nodes = [...this.state.nodes];
              let updatedNodes = updateReadWrite(nodes, element.key, e.target.value);

              this.setState({
                nodes: updatedNodes
              });
            }}
            value={element.readWrite}
          >
            <Radio value={"R"}>{"R"}</Radio>
            <Radio value={"RW"}>{"R/W"}</Radio>
          </Radio.Group>{" "}
        </>
      );
    } else {
      element.isLeaf = true;

      element.title = (
        <>
          {" "}
          <span style={{ padding: "10px" }}> {element.originalTitle}</span>{" "}
          <Radio.Group
            onChange={e => {
              element.readWrite = e.target.value;
              let nodes = [...this.state.nodes];

              let updatedNodes = updateReadWrite(nodes, element.key, e.target.value);

              this.setState({
                nodes: updatedNodes
              });
            }}
            value={element.readWrite}
          >
            <Radio value={"R"}>{"R"}</Radio>
            <Radio value={"RW"}>{"R/W"}</Radio>
          </Radio.Group>{" "}
        </>
      );
    }
    if (element.type === "FILE")
      element.icon = (
        <div>
          {" "}
          <FileIcon extension={element.extension} {...defaultStyles[element.extension]} /> <br />{" "}
        </div>
      );
    if (element.type === "LOG")
      element.icon = (
        <div>
          {" "}
          <FileIcon extension={"LOG"} color={"#2596be"} /> <br />{" "}
        </div>
      ); //<FcFile />;

    if (Boolean(element.children) && element.children.length > 0) {
      element.children.forEach(child => {
        this.initialize(child);
      });
    }
  }

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.setFieldsValue({});

    this.setState({
      hideUpdate: true,
      hideSave: false
    });
  };

  edit = row => {
    var folderIds = [];
    var userIds = [],
      companyIds = [],
      projectIds = [];
    userIds.push(row.id);
    if (Boolean(row.folderList) && row.folderList.length > 0)
      row.folderList.forEach(element => {
        folderIds.push(element.folderId);
      });
    if (Boolean(row.projectList) && row.projectList.length > 0)
      row.projectList.forEach(element => {
        projectIds.push(element.projectId);
      });

    if (Boolean(row.companyList) && row.companyList.length > 0)
      row.companyList.forEach(element => {
        companyIds.push(element.companyId);
      });

    this.formRef.current.setFieldsValue({
      selectedFolderIds: folderIds,
      selectedUserIds: userIds,
      selectedCompanyIds: companyIds,
      selectedProjectIds: projectIds
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,

      selectedFolderIds: folderIds,
      selectedUserIds: userIds,
      selectedCompanyIds: companyIds,
      selectedProjectIds: projectIds
    });
  };

  actionTemplate(row) {
    return (
      <React.Fragment>
        <CustomMaterialMenu row={row} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  }

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      userId: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.userId
    };

    var response = await handleRequest("DELETE", "/api/userFolders/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
      this.setState({
        companyId: null,
        modalDeleteShow: false
      });
    }
  };

  fillComboboxes = async () => {
    let url = "/api/folders/authorized";

    const response = await handleRequest("GET", url);

    if (response.type === "ERROR") {
      error(response);
    } else
      this.setState({
        folderSelectItems: Boolean(response.data) ? response.data : ""
      });

    // let url2 = "/api/users/authorized";
    let url2 = "/api/users/company";
    const response2 = await handleRequest("GET", url2);

    if (response2.type === "ERROR") {
      error(response2);
    } else
      this.setState({
        userSelectItems: Boolean(response2.data) ? response2.data : []
      });

    let url3 = "/api/companies/authorized";

    const response3 = await handleRequest("GET", url3);

    if (response.type === "ERROR") {
      error(response3);
    } else
      this.setState({
        companySelectItemms: Boolean(response3.data) ? response3.data : []
      });

    let url4 = "/api/projects/authorized";

    const response4 = await handleRequest("GET", url4);

    if (response.type === "ERROR") {
      error(response4);
    } else
      this.setState({
        projectSelectItems: Boolean(response4.data) ? response4.data : []
      });
  };

  save = async () => {
    let folderList = [],
      fileList = [];
    const { readWrite, checkedKeys, halfCheckedKeys } = this.state;
    console.log(checkedKeys);
    let selectedFolderIds = Boolean(checkedKeys) && checkedKeys.length > 0 ? checkedKeys.filter(p => p.startsWith("FOLDER")) : [];
    let halfSelectedFolderIds = Boolean(halfCheckedKeys) && halfCheckedKeys.length > 0 ? halfCheckedKeys.filter(p => p.startsWith("FOLDER")) : [];
    let selectedUserIds = this.state.selectedUserIds;
    let selectedFileIds = Boolean(checkedKeys) && checkedKeys.length > 0 ? checkedKeys.filter(p => p.startsWith("FILE") || p.startsWith("LOG")) : [];

    if (Boolean(selectedUserIds) && selectedUserIds.length > 0)
      selectedUserIds.forEach(userId => {
        if (Boolean(selectedFolderIds))
          selectedFolderIds.forEach(folderId => {
            let obj = { userId: userId, folderId: folderId.split("~")[1], authorizationType: readWrite, halfChecked: false };
            folderList.push(obj);
          });

        if (Boolean(halfSelectedFolderIds))
          halfSelectedFolderIds.forEach(folderId => {
            let obj = { userId: userId, folderId: folderId.split("~")[1], authorizationType: readWrite, halfChecked: true };
            folderList.push(obj);
          });

        if (Boolean(selectedFileIds))
          selectedFileIds.forEach(fileId => {
            let obj = { userId: userId, fileId: fileId.split("~")[1], authorizationType: readWrite };
            fileList.push(obj);
          });
      });

    var response = await handleRequest("POST", "/api/users/authorize", {
      selectedUserIds: selectedUserIds,
      authorizationType: readWrite,
      fileList: fileList,
      folderList: folderList
    });

    if (response.type === "ERROR") {
      error(response);
    } else {
      // this.resetInputs();
      // this.restartTable();
      showSuccess();
    }
  };

  onCheck = (checkedKeys, e) => {
    this.setState({
      checkedKeys: checkedKeys,
      halfCheckedKeys: e.halfCheckedKeys,
      checkedNodes: e.checkedNodes
    });
  };

  findNode(list, key) {
    if (Boolean(list) && list.length > 0) {
      list.forEach(node => {
        if (node.key === key) return node;
        else this.findNode(node.children, key);
      });
    }
  }

  setCheckedNodes = async () => {
    var list = [];
    var nodes = [];
    var half = [];
    const { selectedUserIds, readWrite } = this.state;

    if (Boolean(selectedUserIds) && selectedUserIds.length > 0 && Boolean(readWrite)) {
      let response = await handleRequest("POST", "/api/folders/authlist", { selectedUserIds: selectedUserIds, authorizationType: readWrite });

      if (response.type === "ERROR") {
        error(response);
      } else {
        let data = response.data;

        data.forEach(element => {
          if (element.halfChecked) half.push(element.key);
          else list.push(element.key);
        });
      }
    }

    this.setState({
      checkedKeys: list.length > 0 ? list.filter(p => p !== "") : [],
      halfCheckedKeys: half.length > 0 ? half.filter(p => p !== "") : []
    });
  };

  render() {
    console.log("RENDER RENDER RENDER");

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout2 = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    };

    const columns = [
      {
        title: "Actions",
        key: "Actions",
        render: record => {
          return this.actionTemplate(record);
        }
      },

      {
        title: "S/N",
        key: "index",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: "User",
        key: "user",
        render: record => {
          return record.name + " " + record.surname + " " + record.username;
        }
      },
      {
        title: "Companies",
        key: "company",
        render: record => {
          var result = [];
          if (Boolean(record.companyList) && record.companyList.length > 0)
            record.companyList.forEach(element => {
              result.push(element.company.companyName);
            });

          return result.toString();
        }
      },
      {
        title: "Projects",
        key: "projects",
        render: record => {
          var result = [];
          if (Boolean(record.projectList) && record.projectList.length > 0)
            record.projectList.forEach(element => {
              result.push(element.project.projectFullName);
            });

          return result.toString();
        }
      },
      {
        title: "Folders",
        key: "folders",
        render: record => {
          var result = [];
          if (Boolean(record.folderList) && record.folderList.length > 0)
            record.folderList.forEach(element => {
              result.push(element.folder.folderName);
            });

          return result.toString();
        }
      }
    ];

    return (
      <div hidden={this.state.hideInputs} className="card card-custom" style={{ padding: "2rem" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          {
            <Form.Item
              {...layout2}
              label={<FormattedMessage id="todo" defaultMessage="Users" />}
              name="selectedUserIds"
              rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Select at least one user." /> }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                style={{ width: "100%" }}
                mode="multiple"
                placeholder="Select users"
                id="selectedUserIds"
                value={this.state.selectedUserIds}
                onChange={value => {
                  this.setState({ selectedUserIds: value }, this.setCheckedNodes);
                }}
              >
                <Option key={null} value={null}>
                  Select
                </Option>
                {this.state.userSelectItems.map(i => (
                  <Option value={i.id}>{i.username}</Option>
                ))}
              </Select>
            </Form.Item>
          }

          {
            <Form.Item
              {...layout2}
              label={<FormattedMessage id="todo" defaultMessage="Read/Write" />}
              name="readWrite"
              rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please select authorization type " /> }]}
            >
              <Radio.Group
                onChange={e => {
                  this.setState(
                    {
                      readWrite: e.target.value
                    },
                    this.setCheckedNodes
                  );
                }}
                value={this.state.readWrite}
              >
                <Radio value={"R"}>{"Read"}</Radio>
                <Radio value={"RW"}>{"Write"}</Radio>
              </Radio.Group>
            </Form.Item>
          }

          {
            <Row gutter={8}>
              <Col span={4}></Col>

              <Col span={20}>
                <Tree
                  showLine={{ showLeafIcon: false }}
                  checkable
                  showIcon
                  onCheck={this.onCheck}
                  checkedKeys={this.state.checkedKeys}
                  halfCheckedKeys={this.state.halfCheckedKeys}
                  treeData={this.state.nodes}
                  defaultExpandedKeys={["All"]}
                  blockNode={true}
                  style={{ lineHeight: "30px" }}
                />
              </Col>
            </Row>
          }

          <div hidden={this.state.hideSave}>
            {
              <Row gutter={8}>
                <Col span={8}></Col>

                <Col span={8}>
                  {
                    <Button id="OrganizationalLevelSaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  }
                </Col>
              </Row>
            }
          </div>

          <div hidden={this.state.hideUpdate}>
            {
              <Row gutter={8}>
                <Col span={8}></Col>

                <Col span={4}>
                  <Button id="OrganizationalLevelCancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                  </Button>
                </Col>
                <Col span={4}>
                  <Button id="OrganizationalLevelUpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                  </Button>
                </Col>
              </Row>
            }
          </div>
        </Form>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserFolder);
