import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Col as ColBoot, Row as RowBoot, Form as FormBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import {
  Input,
  Select,
  InputNumber,
  Checkbox,
  Col,
  Row,
  Divider,
  Spin,
} from "antd";
import { handleRequest } from "../../ApiConnector";

const { Option } = Select;

export class VendorScoring extends Component {
  constructor({ requestForQuotation, vendor }) {
    super();
    this.state = {
      controllerName: "vendorScoring",
      hideSave: false,

      id: null,
      vendorsSelectItems: [],
      vendorScoringList: [],
      totalScore: 0,
      disableRFQVendorSelect: false,
      requestForQuotation: requestForQuotation,
      requestForQuotationId: requestForQuotation?.id,
      vendorId: vendor?.id,
      vendor: vendor,
      saveLoading: false,
    };
  }

  componentDidMount = async () => {
    this.fillComboboxes();
  };

  componentDidUpdate = (prevProps) => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.fillComboboxes();
    }
  };

  fillComboboxes = async () => {
    console.log("AAA", this.props);
    if (Boolean(this.props.requestForQuotation) && Boolean(this.props.requestForQuotation.id) &&
      this.props.requestForQuotation.id > 0) {
      this.setState({
        requestForQuotationId: this.props.requestForQuotation.id,
      });
      this.fillVendors(this.state.requestForQuotationId)
    }
  };

  fillVendors = async (requestForQuotationId) => {
    if (Boolean(requestForQuotationId) && requestForQuotationId > 0) {
      var response = null;
      if (this.props.responsePage && Boolean(this.props.vendor) &&
        Boolean(this.props.vendor.id) && this.props.vendor.id > 0) {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/vendorResponsePage/" + requestForQuotationId + "/" + this.props.vendor.id);
      } else {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/vendor/" + requestForQuotationId);
      }
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(response.data) && response.data.length === 1 &&
          this.props.responsePage && Boolean(this.props.vendor) &&
          Boolean(this.props.vendor.id) && this.props.vendor.id > 0) {
          this.onChangeVendor(response.data[0].id);
          this.setState({
            vendorsSelectItems: response.data,
            disableRFQVendorSelect: true,
          });
        } else {
          this.setState({
            vendorsSelectItems: Boolean(response.data) && response.data.length > 0 ? response.data : [],
            disableRFQVendorSelect: false,
            vendorScoringList: [],
          });
        }
      }
    } else {
      this.setState({
        vendorsSelectItems: [],
        vendorScoringList: [],
        disableRFQVendorSelect: false,
      });
    }
  };

  cancel = () => {
    this.setState({
      hideSave: false,
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.setState({
      id: null,

      vendorsSelectItems: [],
      vendorScoringList: [],
      vendorId: null,
      requestForQuotationId: null,
      totalScore: 0,
    });
  };

  save = async () => {
    this.setState({ saveLoading: true });
    if (Boolean(this.state.vendorScoringList) && this.state.vendorScoringList.length > 0 &&
      Boolean(this.state.vendorId) && this.state.vendorId > 0 &&
      Boolean(this.state.requestForQuotationId) && this.state.requestForQuotationId > 0) {
      var newItem = this.state.vendorScoringList[0];
      newItem.id = null;
      newItem.vendorId = this.state.vendorId;
      newItem.requestForQuotationId = this.state.requestForQuotationId;
      newItem.totalScore = this.state.totalScore;

      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
      }
      if (response.type === "ERROR") {
        error(response);
        this.setState({ saveLoading: false });
      } else {
        this.resetInputs();
        this.fillComboboxes();
        this.cancel();
        showSuccess();
        this.setState({ saveLoading: false });
        if (Boolean(this.props.closeVendorScoring)) {
          this.props.closeVendorScoring();
        }
      }
    } else {
      showError();
      this.setState({ saveLoading: false });
    }
  };

  renderElement = (values, title) => {
    return values.map((item, index) => (
      <RowBoot style={{ marginBottom: '1rem' }}>
        <ColBoot xs={4}>
          <FormBoot.Label style={index === 0 ? { color: "black", marginTop: "0.5rem", fontWeight: "bold" } : { color: "black", marginTop: "0.5rem" }}>
            {item.description}
          </FormBoot.Label>
        </ColBoot>
        <ColBoot xs={1}>
          <Checkbox
            valuePropName="checked"
            checked={item.vendorCheckYes}
            defaultValue={item.vendorCheckYes}
            onChange={e => {
              if (e.target.checked) {
                item.vendorCheckNo = false;
              }
              item.vendorCheckYes = e.target.checked;
              this.setState({
                vendorScoringList: this.state.vendorScoringList
              });
            }}
          />
        </ColBoot>
        <ColBoot xs={1}>
          <Checkbox
            valuePropName="checked"
            checked={item.vendorCheckNo}
            defaultValue={item.vendorCheckNo}
            onChange={e => {
              if (e.target.checked) {
                item.vendorCheckYes = false;
              }
              item.vendorCheckNo = e.target.checked;
              this.setState({
                vendorScoringList: this.state.vendorScoringList
              });
            }}
          />
        </ColBoot>
        <ColBoot xs={2}>
          <Input value={item.remarks}
            onChange={e => {
              item.remarks = e.target.value;

              this.setState({
                vendorScoringList: this.state.vendorScoringList
              });
            }}
          ></Input>
        </ColBoot>
        <ColBoot xs={2}>
          <InputNumber disabled min={0} value={item.score}
          ></InputNumber>
        </ColBoot>
        <ColBoot xs={2}>
          <InputNumber disabled={index === 0 ? true : false} min={0} max={item.score}
            value={item.evaluationScore}
            onChange={value => {
              item.evaluationScore = value;
              this.calcEvaluationScore();
            }}
          ></InputNumber>
        </ColBoot>
      </RowBoot>
    ))
  }

  calcEvaluationScore = () => {
    this.calcElement("generalTerms");
    this.calcElement("techRequirements");
    this.calcElement("workingProperties");
    this.calcElement("pricePaymentInvoicing");
    this.calcElement("paymentTerms");
    this.calcElement("deliveryPlaces");
    this.calcElement("taxes");
    this.calcElement("essenceDeliveryDates");
    this.calcElement("warranties");
    this.calcElement("languages");
    this.calcElement("requestedDocuments");
    this.calcElement("terminations");
    this.calcElement("confidentialities");
    this.calcElement("inspectionAndAcceptances");
    this.calcElement("competentCourt");
    this.calcElement("liabilityInsurances");
    this.calcElement("intellectualProperties");
    this.calcElement("contractRecords");
    this.calcElement("informationPrivacies");
    this.calcElement("miscellaneous");

    this.setState({
      vendorScoringList: this.state.vendorScoringList
    }, () => {
      this.calcTotalScore();
    });
  }

  calcElement = (obj) => {
    if (Boolean(this.state.vendorScoringList[0][obj]) && this.state.vendorScoringList[0][obj].length > 0) {
      var values = this.state.vendorScoringList[0][obj].filter(p => p.check === true);
      if (Boolean(values) && values.length > 0) {
        if (Boolean(values[0].score) && values[0].score > 0) {
          var totalChildEvaluationScore = 0;
          var totalChildScore = 0;
          var totalParentScore = values[0].score;
          if (Boolean(totalParentScore) && totalParentScore > 0) {
            values.forEach((element, index) => {
              if (index !== 0 && Boolean(element.evaluationScore)) {
                totalChildEvaluationScore += element.evaluationScore;
              }
              if (index !== 0 && Boolean(element.score)) {
                totalChildScore += element.score;
              }
            });
            if (totalChildScore > 0 && totalChildEvaluationScore > 0) {
              values[0].evaluationScore = Number((totalChildEvaluationScore * totalParentScore) / totalChildScore).toFixed(2);
            } else {
              values[0].evaluationScore = 0;
            }
          }
        }
      }
    }
  }

  calcTotalScore = () => {
    var totalScore = 0;
    totalScore += this.getTotalScore("generalTerms");
    totalScore += this.getTotalScore("techRequirements");
    totalScore += this.getTotalScore("workingProperties");
    totalScore += this.getTotalScore("pricePaymentInvoicing");
    totalScore += this.getTotalScore("paymentTerms");
    totalScore += this.getTotalScore("deliveryPlaces");
    totalScore += this.getTotalScore("taxes");
    totalScore += this.getTotalScore("essenceDeliveryDates");
    totalScore += this.getTotalScore("warranties");
    totalScore += this.getTotalScore("languages");
    totalScore += this.getTotalScore("requestedDocuments");
    totalScore += this.getTotalScore("terminations");
    totalScore += this.getTotalScore("confidentialities");
    totalScore += this.getTotalScore("inspectionAndAcceptances");
    totalScore += this.getTotalScore("competentCourt");
    totalScore += this.getTotalScore("liabilityInsurances");
    totalScore += this.getTotalScore("intellectualProperties");
    totalScore += this.getTotalScore("contractRecords");
    totalScore += this.getTotalScore("informationPrivacies");
    totalScore += this.getTotalScore("miscellaneous");

    this.setState({
      totalScore: Number(totalScore).toFixed(2)
    });
  }

  getTotalScore = (obj) => {
    var totalScore = 0;
    if (Boolean(this.state.vendorScoringList) && this.state.vendorScoringList.length > 0) {
      if (Boolean(this.state.vendorScoringList[0][obj]) && this.state.vendorScoringList[0][obj].length > 0) {
        var values = this.state.vendorScoringList[0][obj].filter(p => p.check === true);
        if (Boolean(values) && values.length > 0) {
          if (Boolean(values[0].evaluationScore) && values[0].evaluationScore > 0) {
            totalScore += values[0].evaluationScore;
          }
        }
      }
    }
    return Number(totalScore);
  }

  onChangeRequestForQuotation = async (value) => {
    this.setState({
      requestForQuotationId: value,
      vendorId: null,
      vendorScoringList: [],
    }, () => {
      this.fillVendors(value);
    });
  }

  onChangeVendor = async (value) => {
    this.setState({
      vendorId: value,
    }, () => {
      this.fillVendorScoring();
    });
  }

  fillVendorScoring = async () => {
    if (Boolean(this.state.vendorId) && Boolean(this.state.requestForQuotationId)) {
      const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/vendorScoring/" + this.state.requestForQuotationId + "/" + this.state.vendorId);
      if (response.type === "ERROR") {
        error(response);
      } else if (Boolean(response.data)) {
        let scoring = response.data;
        this.vendorScoringCheckList(scoring);

        this.setState({
          vendorScoringList: [scoring],
        }, () => {
          this.calcTotalScore();
        });
      } else {
        this.setState({
          vendorScoringList: [this.state.requestForQuotation],
        }, () => {
          this.calcTotalScore();
        });
      }
    } else {
      this.setState({
        vendorScoringList: [],
      });
    }
  }

  getRfqNo = record => {
    if (!Boolean(record) || !Boolean(record.id)) {
      return "";
    }
    if (record.id.toString().length === 2) {
      return "RFQ-0" + record.id;
    } else if (record.id.toString().length === 1) {
      return "RFQ-00" + record.id;
    } else {
      return "RFQ-" + record.id;
    }
  };

  vendorScoringCheckList(scoring) {
    this.vendorScoringCheck(scoring, "generalTerms");
    this.vendorScoringCheck(scoring, "techRequirements");
    this.vendorScoringCheck(scoring, "workingProperties");
    this.vendorScoringCheck(scoring, "pricePaymentInvoicing");
    this.vendorScoringCheck(scoring, "paymentTerms");
    this.vendorScoringCheck(scoring, "deliveryPlaces");
    this.vendorScoringCheck(scoring, "taxes");
    this.vendorScoringCheck(scoring, "essenceDeliveryDates");
    this.vendorScoringCheck(scoring, "warranties");
    this.vendorScoringCheck(scoring, "languages");
    this.vendorScoringCheck(scoring, "requestedDocuments");
    this.vendorScoringCheck(scoring, "terminations");
    this.vendorScoringCheck(scoring, "confidentialities");
    this.vendorScoringCheck(scoring, "inspectionAndAcceptances");
    this.vendorScoringCheck(scoring, "competentCourt");
    this.vendorScoringCheck(scoring, "liabilityInsurances");
    this.vendorScoringCheck(scoring, "intellectualProperties");
    this.vendorScoringCheck(scoring, "contractRecords");
    this.vendorScoringCheck(scoring, "informationPrivacies");
    this.vendorScoringCheck(scoring, "miscellaneous");
  }

  vendorScoringCheck(scoring, item) {
    if (Boolean(scoring[item]) && scoring[item].length > 0) {
      scoring[item].forEach(element => {
        element.check = true;
      });
    }
  }

  render() {
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col md={24}>
            <span className="h4">{this.getRfqNo(this.props.requestForQuotation)}</span>
            {Boolean(this.props.vendor) && <span className="h4"> {this.props.vendor.name}</span>}
          </Col>
        </Row>

        {!this.state.disableRFQVendorSelect && <RowBoot style={{ marginTop: '2rem' }}>
          <ColBoot xs={1}>
            <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
              <FormattedMessage id="VendorScoring.RFQ" defaultMessage="RFQ" />
            </FormBoot.Label>
          </ColBoot>
          <ColBoot xs={4}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              value={this.state.requestForQuotationId}
              onChange={this.onChangeRequestForQuotation}
            >
              {Boolean(this.state.requestForQuotation) &&
                <Option key={this.state.requestForQuotation.id}
                  value={this.state.requestForQuotation.id}>{this.getRfqNo(this.state.requestForQuotation)}</Option>
              }
            </Select>
          </ColBoot>
          <ColBoot xs={2}></ColBoot>
          <ColBoot xs={1}>
            <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
              <FormattedMessage id="VendorScoring.Vendor" defaultMessage="Vendor" />
            </FormBoot.Label>
          </ColBoot>
          <ColBoot xs={4}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              value={this.state.vendorId}
              onChange={this.onChangeVendor}
            >
              {this.state.vendorsSelectItems.map(i => (
                <Option key={i.id} value={i.id}>{i.name}</Option>
              ))}
            </Select>
          </ColBoot>
        </RowBoot>}

        {Boolean(this.state.vendorScoringList) && this.state.vendorScoringList.length > 0 ?
          <>
            <RowBoot style={{ marginTop: '2rem' }}>
              <ColBoot xs={4}>
                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                  <FormattedMessage id="VendorScoring.Item" defaultMessage="Item" />
                </FormBoot.Label>
              </ColBoot>
              <ColBoot xs={1}>
                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                  <FormattedMessage id="VendorScoring.Yes" defaultMessage="Yes" />
                </FormBoot.Label>
              </ColBoot>
              <ColBoot xs={1}>
                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                  <FormattedMessage id="VendorScoring.No" defaultMessage="No" />
                </FormBoot.Label>
              </ColBoot>
              <ColBoot xs={2}>
                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                  <FormattedMessage id="VendorScoring.Remarks" defaultMessage="Remarks" />
                </FormBoot.Label>
              </ColBoot>
              <ColBoot xs={2}>
                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                  <FormattedMessage id="VendorScoring.RFQScore" defaultMessage="RFQ Score" />
                </FormBoot.Label>
              </ColBoot>
              <ColBoot xs={2}>
                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                  <FormattedMessage id="VendorScoring.EvaluationScore" defaultMessage="Evaluation Score" />
                </FormBoot.Label>
              </ColBoot>
            </RowBoot>
            <Divider style={{ 'background-color': 'black' }} />

            {Boolean(this.state.vendorScoringList[0].generalTerms) && this.state.vendorScoringList[0].generalTerms.length > 0 &&
              this.state.vendorScoringList[0].generalTerms.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].generalTerms.filter(p => p.check === true), "General Terms and Conditions")
            }

            {Boolean(this.state.vendorScoringList[0].techRequirements) && this.state.vendorScoringList[0].techRequirements.length > 0 &&
              this.state.vendorScoringList[0].techRequirements.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].techRequirements.filter(p => p.check === true), "Technical Requirements Checklist")
            }

            {Boolean(this.state.vendorScoringList[0].workingProperties) && this.state.vendorScoringList[0].workingProperties.length > 0 &&
              this.state.vendorScoringList[0].workingProperties.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].workingProperties.filter(p => p.check === true), "Working on (company name) property")
            }

            {Boolean(this.state.vendorScoringList[0].pricePaymentInvoicing) && this.state.vendorScoringList[0].pricePaymentInvoicing.length > 0 &&
              this.state.vendorScoringList[0].pricePaymentInvoicing.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].pricePaymentInvoicing.filter(p => p.check === true), "Price, payment and invoicing")
            }

            {Boolean(this.state.vendorScoringList[0].paymentTerms) && this.state.vendorScoringList[0].paymentTerms.length > 0 &&
              this.state.vendorScoringList[0].paymentTerms.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].paymentTerms.filter(p => p.check === true), "Payment Terms")
            }

            {Boolean(this.state.vendorScoringList[0].deliveryPlaces) && this.state.vendorScoringList[0].deliveryPlaces.length > 0 &&
              this.state.vendorScoringList[0].deliveryPlaces.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].deliveryPlaces.filter(p => p.check === true), "Delivery Place")
            }

            {Boolean(this.state.vendorScoringList[0].taxes) && this.state.vendorScoringList[0].taxes.length > 0 &&
              this.state.vendorScoringList[0].taxes.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].taxes.filter(p => p.check === true), "Taxes")
            }

            {Boolean(this.state.vendorScoringList[0].essenceDeliveryDates) && this.state.vendorScoringList[0].essenceDeliveryDates.length > 0 &&
              this.state.vendorScoringList[0].essenceDeliveryDates.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].essenceDeliveryDates.filter(p => p.check === true), "Time of essence/Delivery dates")
            }

            {Boolean(this.state.vendorScoringList[0].warranties) && this.state.vendorScoringList[0].warranties.length > 0 &&
              this.state.vendorScoringList[0].warranties.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].warranties.filter(p => p.check === true), "Warranty")
            }

            {Boolean(this.state.vendorScoringList[0].languages) && this.state.vendorScoringList[0].languages.length > 0 &&
              this.state.vendorScoringList[0].languages.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].languages.filter(p => p.check === true), "Language")
            }

            {Boolean(this.state.vendorScoringList[0].requestedDocuments) && this.state.vendorScoringList[0].requestedDocuments.length > 0 &&
              this.state.vendorScoringList[0].requestedDocuments.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].requestedDocuments.filter(p => p.check === true), "Requested documents")
            }

            {Boolean(this.state.vendorScoringList[0].terminations) && this.state.vendorScoringList[0].terminations.length > 0 &&
              this.state.vendorScoringList[0].terminations.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].terminations.filter(p => p.check === true), "Termination")
            }

            {Boolean(this.state.vendorScoringList[0].confidentialities) && this.state.vendorScoringList[0].confidentialities.length > 0 &&
              this.state.vendorScoringList[0].confidentialities.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].confidentialities.filter(p => p.check === true), "Confidentiality, privacy and publicity")
            }

            {Boolean(this.state.vendorScoringList[0].inspectionAndAcceptances) && this.state.vendorScoringList[0].inspectionAndAcceptances.length > 0 &&
              this.state.vendorScoringList[0].inspectionAndAcceptances.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].inspectionAndAcceptances.filter(p => p.check === true), "Inspection and acceptance")
            }

            {Boolean(this.state.vendorScoringList[0].competentCourt) && this.state.vendorScoringList[0].competentCourt.length > 0 &&
              this.state.vendorScoringList[0].competentCourt.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].competentCourt.filter(p => p.check === true), "Governing law and competent court")
            }

            {Boolean(this.state.vendorScoringList[0].liabilityInsurances) && this.state.vendorScoringList[0].liabilityInsurances.length > 0 &&
              this.state.vendorScoringList[0].liabilityInsurances.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].liabilityInsurances.filter(p => p.check === true), "Liability and insurance")
            }

            {Boolean(this.state.vendorScoringList[0].intellectualProperties) && this.state.vendorScoringList[0].intellectualProperties.length > 0 &&
              this.state.vendorScoringList[0].intellectualProperties.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].intellectualProperties.filter(p => p.check === true), "Intellectual property")
            }

            {Boolean(this.state.vendorScoringList[0].contractRecords) && this.state.vendorScoringList[0].contractRecords.length > 0 &&
              this.state.vendorScoringList[0].contractRecords.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].contractRecords.filter(p => p.check === true), "Contract records")
            }

            {Boolean(this.state.vendorScoringList[0].informationPrivacies) && this.state.vendorScoringList[0].informationPrivacies.length > 0 &&
              this.state.vendorScoringList[0].informationPrivacies.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].informationPrivacies.filter(p => p.check === true), "Freedom of information and privacy")
            }

            {Boolean(this.state.vendorScoringList[0].miscellaneous) && this.state.vendorScoringList[0].miscellaneous.length > 0 &&
              this.state.vendorScoringList[0].miscellaneous.filter(p => p.check === true).length > 0 &&
              this.renderElement(this.state.vendorScoringList[0].miscellaneous.filter(p => p.check === true), "Miscellaneous")
            }

            <RowBoot style={{ marginBottom: '1rem' }}>
              <ColBoot xs={8}></ColBoot>
              <ColBoot xs={2}>
                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                  <FormattedMessage id="VendorScoring.TotalScore" defaultMessage="Total Score" />
                </FormBoot.Label>
              </ColBoot>
              <ColBoot xs={2}>
                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                  {this.state.totalScore}
                </FormBoot.Label>
              </ColBoot>
            </RowBoot>
            <br />
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Spin spinning={this.state.saveLoading}>
                    <Button id="SaveButton" onClick={this.save} style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                    </Spin>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </>
          :
          ""
        }
      </div >
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VendorScoring);