import { FilterOutlined, SaveOutlined, ZoomInOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@material-ui/icons";
import Viewer, { defaultLayout, Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { Button as AntButton, Checkbox, Col, DatePicker, Form, Image, Input, Radio, Row, Select, Table, Tag, Badge } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import store from "../../../redux/store";
import { handleRequest } from "../../ApiConnector";
import { error, showError, showInfo, showSuccess, showWarning } from "../../MessageHelper";
import "../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class SalesmanOrder extends Component {
  constructor() {
    super();
    this.state = {
      formName: "SalesmanOrder",
      controllerName: "salesmanOrder",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      showProductListModal: false,
      invoiceProductList: [],
      invoiceReportList: [],
      showInvoiceDetailModal: false,
      showSalableProductListModal: false,
      selectedProducts: [],
      discountRate: null,
      discountValue: null,
      discountRateVat: null,
      discountValueVat: null,
      hasVat: true,
      vatValue: 15,
      grandTotal: 0,
      modalPdfViewer: false,
      isFreeProduct: false,
      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      customerIdSelectItems: [],
      pdfFile: "",
      user: store.getState().auth.user,
      showReceivePaymentModal: false,
      showReturnInvoiceModal: false,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      selectedSalableProductKeys: [],
      salableProductlist: [],
      saveLoading: false,
      refreshProductListWhenChange: false,
      addNewIndex: 0,
      maxAmount: 0
    };
  }

  formRef = React.createRef();
  formAddCustomerRef = React.createRef();
  formReceivePayment = React.createRef();
  formProceedWith = React.createRef();

  paymentTypeItems = [
    { value: "CASH", label: "Cash" },
    { value: "TRANSFER", label: "Transfer" },
    { value: "CREDIT", label: "Credit" }
  ];

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    console.log("user", this.state.user);
    var responsecustomerId = await handleRequest("GET", "/api/" + this.state.controllerName + "/customerIdSelectItems");
    if (responsecustomerId.type === "ERROR") {
      error(responsecustomerId);
    } else {
      this.setState({
        customerIdSelectItems: Boolean(responsecustomerId.data) ? responsecustomerId.data : []
      });
    }

    var responseSalesman = await handleRequest("GET", "/api/" + this.state.controllerName + "/salesman");
    if (responseSalesman.type === "ERROR") {
      error(responseSalesman);
    } else {
      if (this.state.user.role === "SALESMAN") {
        if (Boolean(responseSalesman.data) && responseSalesman.data.length > 0) {
          this.setState({
            salesmanSelectItems: responseSalesman.data.filter(p => p.key === this.state.user.id)
          });
        } else {
          this.setState({
            salesmanSelectItems: []
          });
        }
      } else {
        this.setState({
          salesmanSelectItems: Boolean(responseSalesman.data) ? responseSalesman.data : []
        });
      }
    }
  };

  fillSalesmanProducts = async () => {
    if (this.state.user.role === "SALESMAN") {
      let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/waitList/" + this.state.user.id);
      if (response.type === "ERROR") {
        error(response);
      } else if (response.data.length > 0) {
        let products = response.data.map(element => {
          return {
            id: element.inventoryId,
            materialName: element.inventory,
            arabicName: element.arabicName,
            quantity: element.quantity,
            relatedReleaseId: element.id
          };
        });
        this.setState({
          salableProductlist: products
        });
      } else {
        showWarning("No products available for you to sale");
        this.productMessages = [
          {
            variant: "warning",
            text: "No products available for you to sale"
          }
        ];
        this.setState({
          selectedProducts: []
        });
      }
    }
  };
  calculateTotal = () => {
    const { hasVat, vatValue, selectedProducts } = this.state;
    let total = 0;
    let grandTotal = 0;

    if (Boolean(selectedProducts) && selectedProducts.length > 0) {
      selectedProducts.forEach(element => {
        if (Boolean(element.price)) {
          grandTotal = grandTotal + element.price;
        }
      });
      grandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;

      if (grandTotal > 0) {
        if (hasVat && Boolean(vatValue)) {
          total = (grandTotal * 100) / (100 + vatValue);
          total = Math.round((total + Number.EPSILON) * 100) / 100;
        } else if (!hasVat) {
          this.setState({ vatValue: null });
          total = grandTotal;
          total = Math.round((total + Number.EPSILON) * 100) / 100;
        }

        this.setState({
          grandTotal: grandTotal,
          total: total,
          totalVat: Math.round((grandTotal - total + Number.EPSILON) * 100) / 100,
          discountRate: null,
          discountValue: null,
          discountRateVat: null,
          discountValueVat: null
        });

        this.formRef.current.setFieldsValue({
          grandTotal: grandTotal,
          total: total,
          totalVat: Math.round((grandTotal - total + Number.EPSILON) * 100) / 100,
          discountRate: null,
          discountValue: null,
          discountRateVat: null,
          discountValueVat: null,
          totalDiscount: null,
          payment1: { amount: grandTotal }
        });
      }
    } else {
      this.setState({
        grandTotal: 0,
        total: 0,
        totalVat: 0,
        discountRate: null,
        discountValue: null,
        discountRateVat: null,
        discountValueVat: null
      });
      this.formRef.current.setFieldsValue({
        grandTotal: 0,
        total: 0,
        totalVat: 0,
        discountRate: null,
        discountValue: null,
        discountRateVat: null,
        discountValueVat: null,
        totalDiscount: null,
        payment1: { amount: 0 }
      });
    }
  };

  calculateDiscountVat = () => {
    const { hasVat, vatValue, selectedProducts, discountValue, discountValueVat, discountRateVat } = this.state;
    let total = 0;
    let grandTotal = 0;
    let tempGrandTotal = 0;
    let tempDiscountValueVat = 0;
    let tempDiscountRateVat = 0;

    if (Boolean(selectedProducts) && selectedProducts.length > 0) {
      selectedProducts.forEach(element => {
        if (Boolean(element.price)) {
          grandTotal = grandTotal + element.price;
          tempGrandTotal = tempGrandTotal + element.price;
        }
      });

      grandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;
      tempGrandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;
    }

    if (grandTotal > 0) {
      if (hasVat && Boolean(vatValue)) {
        total = (grandTotal * 100) / (100 + vatValue);
        total = Math.round((total + Number.EPSILON) * 100) / 100;
      } else if (!hasVat) {
        this.setState({ vatValue: null });
        total = grandTotal;
        total = Math.round((total + Number.EPSILON) * 100) / 100;
      }

      if (Boolean(discountValue)) {
        total = total - discountValue;
        total = Math.round((total + Number.EPSILON) * 100) / 100;
        grandTotal = hasVat && Boolean(vatValue) ? total + (total * vatValue) / 100 : total;
        grandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;
      }

      if (Boolean(discountValueVat)) {
        tempDiscountValueVat = discountValueVat;
        tempDiscountRateVat = (discountValueVat * 100) / grandTotal;
        grandTotal = grandTotal - tempDiscountValueVat;
        grandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;
        total = hasVat && Boolean(vatValue) ? (grandTotal * 100) / (100 + vatValue) : grandTotal;
        total = Math.round((total + Number.EPSILON) * 100) / 100;
      } else if (Boolean(discountRateVat)) {
        tempDiscountRateVat = discountRateVat;
        tempDiscountValueVat = (grandTotal * discountRateVat) / 100;
        grandTotal = grandTotal - tempDiscountValueVat;
        grandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;
        total = hasVat && Boolean(vatValue) ? (grandTotal * 100) / (100 + vatValue) : grandTotal;
        total = Math.round((total + Number.EPSILON) * 100) / 100;
      }

      this.setState({
        grandTotal: grandTotal,
        total: total,
        totalVat: Math.round((grandTotal - total + Number.EPSILON) * 100) / 100,
        discountValueVat: Math.round((tempDiscountValueVat + Number.EPSILON) * 100) / 100,
        discountRateVat: Math.round((tempDiscountRateVat + Number.EPSILON) * 1000) / 1000
      });

      this.formRef.current.setFieldsValue({
        grandTotal: grandTotal,
        total: total,
        totalDiscount: Math.round((grandTotal - tempGrandTotal + Number.EPSILON) * 100) / 100,
        totalVat: Math.round((grandTotal - total + Number.EPSILON) * 100) / 100,
        discountValueVat: Math.round((tempDiscountValueVat + Number.EPSILON) * 100) / 100,
        discountRateVat: Math.round((tempDiscountRateVat + Number.EPSILON) * 1000) / 1000,
        payment1: { amount: grandTotal }
      });
    }
  };

  calculateDiscount = () => {
    const { hasVat, vatValue, selectedProducts, discountRate, discountValue } = this.state;
    let total = 0;
    let grandTotal = 0;
    let tempGrandTotal = 0;
    let tempDiscountValue = 0;
    let tempDiscountRate = 0;

    if (Boolean(selectedProducts) && selectedProducts.length > 0) {
      selectedProducts.forEach(element => {
        if (Boolean(element.price)) {
          grandTotal = grandTotal + element.price;
          tempGrandTotal = tempGrandTotal + element.price;
        }
      });

      grandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;
      tempGrandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;

      if (grandTotal > 0) {
        if (hasVat && Boolean(vatValue)) {
          total = (grandTotal * 100) / (100 + vatValue);
          total = Math.round((total + Number.EPSILON) * 100) / 100;
        } else if (!hasVat) {
          this.setState({ vatValue: null });
          total = grandTotal;
          total = Math.round((total + Number.EPSILON) * 100) / 100;
        }

        if (Boolean(discountValue)) {
          tempDiscountValue = discountValue;
          tempDiscountRate = (discountValue * 100) / total;
          total = total - tempDiscountValue;
          total = Math.round((total + Number.EPSILON) * 100) / 100;
          grandTotal = hasVat && Boolean(vatValue) ? total + (total * vatValue) / 100 : total;
          grandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;
        } else if (Boolean(discountRate)) {
          tempDiscountRate = discountRate;
          tempDiscountValue = (total * discountRate) / 100;
          total = total - tempDiscountValue;
          total = Math.round((total + Number.EPSILON) * 100) / 100;
          grandTotal = hasVat && Boolean(vatValue) ? total + (total * vatValue) / 100 : total;
          grandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;
        }

        this.setState({
          grandTotal: grandTotal,
          total: total,
          totalVat: Math.round((grandTotal - total + Number.EPSILON) * 100) / 100,
          discountValue: Math.round((tempDiscountValue + Number.EPSILON) * 100) / 100,
          discountRate: Math.round((tempDiscountRate + Number.EPSILON) * 1000) / 1000,
          discountValueVat: null,
          discountRateVat: null
        });

        this.formRef.current.setFieldsValue({
          grandTotal: grandTotal,
          total: total,
          totalVat: Math.round((grandTotal - total + Number.EPSILON) * 100) / 100,
          discountValue: Math.round((tempDiscountValue + Number.EPSILON) * 100) / 100,
          discountRate: Math.round((tempDiscountRate + Number.EPSILON) * 1000) / 1000,
          totalDiscount: Math.round((grandTotal - tempGrandTotal + Number.EPSILON) * 100) / 100,

          discountValueVat: null,
          discountRateVat: null,
          payment1: { amount: grandTotal }
        });
      }
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.formRef.current.setFieldsValue({
      hasVat: true
    });

    this.setState({
      id: null,
      discountRate: null,
      hasVat: true,
      vatValue: 15,
      selectedProducts: [],
      totalVat: null,
      selectedInvoice: null,
      showReceivePaymentModal: false,
      showReturnInvoiceModal: false,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,

        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: [],
      grandTotal: 0
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    if (Boolean(row.invoiceProductList)) {
      row.invoiceProductList.forEach(element => {
        if (Boolean(element.product)) element.name = element.product.materialName;
      });

      this.setState({
        selectedProducts: row.invoiceProductList.map(element => {
          return { ...element, quantity_available: element.quantity, oldUnitPrice: element.unitPrice };
        })
      });
    }
    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState(
      {
        hideUpdate: false,
        hideSave: true,
        hideInputs: false,
        id: row.id,
        discountRate: row.discountRate,
        discountValue: row.discountValue,
        discountRateVat: row.discountRateVat,
        discountValueVat: row.discountValueVat,
        vatValue: row.vatValue,
        grandTotal: row.grandTotal
      },
      () => {
        //this.calculateDiscountVat();
      }
    );
  };

  deleteModal = row => {
    console.log("deleteModal", row);
    this.setState({
      modalDeleteShow: true,
      id: row.id,
      deleteReason: row.remarks
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow,
      deleteReason: ""
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    let obj = {
      remarks: this.state.deleteReason
    };
    console.log("reason", obj);
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/delete/" + deletedItem.id, obj);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        deleteReason: "",
        modalDeleteShow: false
      });
    }
  };
  initPage = async params => {
    console.log("AAA", this.props);
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();

    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      if (Boolean(this.props.selectedPO)) {
        this.setState({
          invoice: this.props.selectedPO.invoice,
          delivery: this.props.selectedPO.delivery,
          requiredDocuments: this.props.selectedPO.requiredDocuments
        });

        let invoiceFileUrl = [];
        let fileUrlInvoice = Boolean(this.props.selectedPO.invoiceFileUrl) ? this.props.selectedPO.invoiceFileUrl.split(",") : [];
        if (Boolean(fileUrlInvoice) && fileUrlInvoice.length > 0) {
          fileUrlInvoice.forEach((element, index) => {
            let obj = {
              uid: index,
              name: element.split("download/")[1],
              url: element,
              thumbUrl: element
            };
            invoiceFileUrl.push(obj);
          });
        }

        let deliveryFileUrl = [];
        let fileUrlDelivery = Boolean(this.props.selectedPO.deliveryFileUrl) ? this.props.selectedPO.deliveryFileUrl.split(",") : [];
        if (Boolean(fileUrlDelivery) && fileUrlDelivery.length > 0) {
          fileUrlDelivery.forEach((element, index) => {
            let obj = {
              uid: index,
              name: element.split("download/")[1],
              url: element,
              thumbUrl: element
            };
            deliveryFileUrl.push(obj);
          });
        }

        if (
          Boolean(this.props.selectedPO) &&
          Boolean(this.props.selectedPO.requiredDocuments) &&
          this.props.selectedPO.requiredDocuments.length > 0
        ) {
          this.props.selectedPO.requiredDocuments.forEach((element, index) => {
            var fileUrl = [];
            if (Boolean(element.fileUrl) && element.fileUrl.length > 0) {
              let obj = {
                uid: index,
                name: element.fileUrl.split("download/")[1],
                url: element.fileUrl,
                thumbUrl: element.fileUrl
              };
              fileUrl.push(obj);
            }
            element.fileUrl = fileUrl;
          });
        }

        this.formRef.current.setFieldsValue({
          ...this.props.selectedPO,
          invoiceFileUrl: invoiceFileUrl,
          deliveryFileUrl: deliveryFileUrl,
          invoiceDate: Boolean(this.props.selectedPO.invoiceDate) ? moment(this.props.selectedPO.invoiceDate) : null,
          deliveryDate: Boolean(this.props.selectedPO.deliveryDate) ? moment(this.props.selectedPO.deliveryDate) : null
        });
      } else {
        this.formRef.current.setFieldsValue({
          quantity: Boolean(this.props.selectedPO) ? this.props.selectedPO.quantity : null,
          unitPrice: Boolean(this.props.selectedPO) ? this.props.selectedPO.unitPrice : null,
          oldUnitPrice: Boolean(this.state.unitPrice) ? this.state.unitPrice : null
        });
      }
    }
  };

  addSelectedProducts = async selectedRows => {
    const { selectedProducts, isFreeProduct } = this.state;
    let data = selectedRows;
    let invoiceProductList = [...selectedProducts];
    data.forEach(element => {
      let obj = {};
      obj.id = null;
      obj.productId = element.id;
      obj.name = Boolean(element.producDetail) ? element.producDetail.name : element.materialName;
      obj.quantity = element.quantity;
      obj.quantity_available = element.quantity;
      obj.unitPrice = Boolean(element.productDetail) ? element.productDetail.sellingPrice : element.sellingPrice;
      obj.oldUnitPrice = obj.unitPrice;
      obj.price = obj.quantity * obj.unitPrice;
      if (Boolean(isFreeProduct)) {
        obj.unitPrice = 0;
        obj.price = 0;
        obj.quantity = 1;
      }
      invoiceProductList.push(obj);
    });
    showSuccess();
    this.setState(
      {
        selectedProducts: invoiceProductList,
        showProductListModal: false,
        discountRate: null,
        discountValue: null,
        discountRateVat: null,
        discountValueVat: null
      },
      () => {
        this.calculateTotal();
      }
    );
  };

  addSelectedSalesmanProducts = async () => {
    const { selectedProducts, selectedSalableProductKeys, salableProductlist, isFreeProduct } = this.state;

    let data = salableProductlist.filter(item => selectedSalableProductKeys.includes(item.relatedReleaseId));
    var response = await handleRequest(
      "POST",
      "/api/" + this.state.controllerName + "/selectedproducts",
      data.map(item => item.id)
    );
    var response2 = await handleRequest("POST", "/api/" + this.state.controllerName + "/soldProductsByRelease", selectedSalableProductKeys);

    if (response.type === "ERROR" || response2.type === "ERROR") {
      error(response);
    } else {
      let products = response.data;
      // let sellings = response2.data;
      let invoiceProductList = [...selectedProducts];
      data.forEach(element => {
        let obj = {};
        let p = products.filter(item => item.id === element.id)[0];
        // let i = sellings.filter(
        //   (item) => item.relatedReleaseId === element.relatedReleaseId
        // )[0];
        // element.quantity = Boolean(i)
        //   ? element.quantity - i.quantity
        //   : element.quantity;
        if (Boolean(p)) {
          obj.id = null;
          obj.productId = element.id;
          obj.name = Boolean(p.producDetail) ? p.producDetail.name : p.materialName;
          obj.quantity = element.quantity;
          obj.quantity_available = element.quantity;
          obj.unitPrice = Boolean(p.productDetail) ? p.productDetail.sellingPrice : p.sellingPrice;
          obj.oldUnitPrice = obj.unitPrice;
          obj.price = obj.quantity * obj.unitPrice;
          obj.relatedReleaseId = element.relatedReleaseId;
          if (Boolean(isFreeProduct)) {
            obj.unitPrice = 0;
            obj.price = 0;
            obj.quantity = 1;
          }
          invoiceProductList.push(obj);
        }
      });
      showSuccess();
      this.setState(
        {
          selectedProducts: invoiceProductList,
          showSalableProductListModal: false,
          discountRate: null,
          discountValue: null,
          discountRateVat: null,
          discountValueVat: null
        },
        () => {
          this.calculateTotal();
        }
      );
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = Boolean(response.data.data) ? response.data.data : response.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    this.setState({ saveLoading: true });
    var chequeLog = [];
    if (Boolean(this.state.chequeLog)) {
      chequeLog.push(this.state.chequeLog);
    }
    const newItem = {
      ...values,
      id: this.state.id,
      invoiceProductList: this.state.selectedProducts,
      hasVat: this.state.hasVat,
      vatValue: this.state.vatValue,
      chequeLog: chequeLog
    };

    console.log("newItem", newItem);

    if (Boolean(newItem.invoiceProductList) && newItem.invoiceProductList.length > 0) {
      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
      }

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else showError("Please add at least one product to invoice");
    this.setState({ saveLoading: false, refreshProductListWhenChange: !this.state.refreshProductListWhenChange });
  };

  saveReceivePayment = async values => {
    this.setState({ saveLoading: true });

    const newItem = {
      invoiceId: this.state.id,
      ...values
    };

    if (newItem.paymentMethodType === newItem.receiveFor) {
      showInfo("The payment method must be chosen differently.");
    } else {
      var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/receivePayment", newItem);

      if (response.type === "ERROR") {
        error(response);
        this.setState({ saveLoading: false });
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.setState({ saveLoading: false });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    let tempPaymentList = null;
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
      if (Boolean(params.pagination.invoicePaymentList)) {
        tempPaymentList = params.pagination.invoicePaymentList.map(item => {
          return { paymentMethodType: item };
        });
      }
    }
    const newObj = {
      ...params.pagination,
      invoicePaymentList: tempPaymentList,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  getImageGalleryFromUrls = (paths, galleryName) => {
    if (Boolean(paths) && paths.length > 0) {
      let pathList = paths.split(",");
      // for (let i = 0; i < pathList.length; i++) {
      //   images.push(<Image key={i} width={80} src={pathList[i].trim()} />);
      // }

      return (
        <>
          {Boolean(paths) && paths.length > 0 ? (
            <Image
              width={80}
              preview={{
                visible: false
              }}
              src={pathList[0].trim()}
              onClick={() => this.setState({ [galleryName]: true })}
            />
          ) : null}
          {Boolean(pathList) && pathList.length > 0 ? (
            <div style={{ display: "none" }}>
              <Image.PreviewGroup
                preview={{
                  visible: this.state[galleryName],
                  onVisibleChange: vis => this.setState({ [galleryName]: vis })
                }}
              >
                {pathList.map((field, i) => (
                  <Image key={i} src={field} />
                ))}
              </Image.PreviewGroup>
            </div>
          ) : null}
        </>
      );
    }
  };

  add = () => {
    this.setState({
      showProductListModal: true,
      isFreeProduct: false,
      random: Math.random()
    });
  };

  showInvoiceProducts = row => {
    console.log(row);
    this.setState({
      invoiceProductList: row.invoiceProductList,
      showInvoiceDetailModal: true
    });
  };

  remove = index => {
    let list = this.state.selectedProducts;

    list.splice(index, 1);

    this.setState(
      {
        selectedProducts: list
      },
      this.calculateTotal
    );
  };

  showReport = record => {
    console.log(record);
    if (Boolean(record.invoicePath))
      try {
        const fileRealUrl = record.invoicePath;
        this.setState({
          pdfFile: fileRealUrl,
          modalPdfViewer: true
        });
      } catch (error) {
        alert("File cant found.");
      }
    else showError("SalesmanOrder path could not find !");
  };

  showThermalReportFromUrl = async url => {
    const response = await handleRequest("GET", url);

    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false
      });

      showError("There is no created report for selected item");
    }
  };

  showThermalReport = record => {
    if (Boolean(record.thermalInvoicePath))
      try {
        const fileRealUrl = record.thermalInvoicePath;

        this.setState({
          pdfFile: fileRealUrl,
          modalPdfViewer: true
        });
      } catch (error) {
        alert("File cant found.");
      }
    else showError("SalesmanOrder path could not find !");
  };

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer
    });
  };

  onSalableProductSelectChange = (selectedRowKeys, selectedRows) => {
    let selectedRowKeysState = this.state.selectedSalableProductKeys;
    selectedRowKeysState = [...selectedRowKeys];

    this.setState({ selectedSalableProductKeys: selectedRowKeysState });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          allowClear
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
        />
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  saveChequeLogCustomer = chequeLog => {
    this.setState({
      chequeLog: chequeLog
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };

    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 8,
          offset: 0
        },
        sm: {
          span: 8,
          offset: 8
        }
      }
    };

    const columnsInvoiceProducts = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },

      {
        title: <FormattedMessage id="SalesmanOrder.productName" defaultMessage="Product Name" />,
        key: "productName",
        width: 100,
        render: record => {
          if (Boolean(record.product)) return record.product.materialName;
        }
      },
      {
        title: <FormattedMessage id="InventoryProduct.materialPhotoPaths" defaultMessage="Product Photos" />,
        key: "materialPhotoPaths",
        width: 100,
        render: record => {
          return this.getImageGalleryFromUrls(record.product.materialPhotoPaths, "galleryInvoicePhotos" + record.id);
        }
      },
      {
        title: <FormattedMessage id="SalesmanOrder.quantity" defaultMessage="Quantity" />,
        key: "invoiceNumber",
        width: 100,
        render: record => {
          return record.quantity;
        }
      },
      {
        title: <FormattedMessage id="SalesmanOrder.unitPrice" defaultMessage="List Unit Price" />,
        key: "invoiceNumber",
        width: 100,
        render: record => {
          return record.unitPrice;
        }
      },
      {
        title: <FormattedMessage id="SalesmanOrder.price" defaultMessage="List Price" />,
        key: "invoiceNumber",
        width: 100,
        render: record => {
          return record.price;
        }
      },
      {
        title: <FormattedMessage id="SalesmanOrder.salesUnitPrice" defaultMessage="Sales Unit Price" />,
        key: "salesUnitPrice",
        width: 100,
        render: record => {
          return record.salesUnitPrice;
        }
      },
      {
        title: <FormattedMessage id="SalesmanOrder.salesPrice" defaultMessage="Sales Price" />,
        key: "salesPrice",
        width: 100,
        render: record => {
          return record.salesPrice;
        }
      }
    ];

    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        width: 100,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="SalesmanOrder.salesmanId" defaultMessage="Salesman" />,
        key: "salesmanId",
        width: 100,
        render: record => {
          return record.salesmanName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Salesman"
              id="DefinitionsType"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  salesmanId: value,
                  salesmanIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["salesmanSelectItems"]) &&
                this.state["salesmanSelectItems"].length > 0 &&
                this.state["salesmanSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}{" "}
            </Select>
          </div>
        )
      },
      {
        title: <FormattedMessage id="SalesmanOrder.invoiceProductList" defaultMessage="Products" />,
        key: "invoiceNumber",
        width: 100,
        render: record => {
          return (
            <AntButton
              style={{ border: "0px", alignContent: "center" }}
              type="text"
              onClick={() => this.showInvoiceProducts(record)}
              icon={<ZoomInOutlined style={{ fontSize: "20px", color: "#0b5fcc" }} />}
            />
          );
        }
      },
      {
        title: <FormattedMessage id="SalesmanOrder.customerId" defaultMessage="Customer" />,
        key: "customerId",
        width: 100,
        render: record => {
          if (Boolean(record.vendor))
            if (Boolean(record.vendor.arabicName) && Boolean(record.vendor.name) && !this.state.hide_customerArabicName)
              return record.vendor.arabicName + " / " + record.vendor.name;
            else if (Boolean(record.vendor.arabicName) && !this.state.hide_customerArabicName) return record.vendor.arabicName;
            else if (Boolean(record.vendor.name)) return record.vendor.name;
            else return "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Customer"
              id="DefinitionsType"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  customerId: value,
                  customerIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              {this.state.customerIdSelectItems.map(i => (
                <Option key={i.key} value={i.key}>
                  {Boolean(i.value) ? i.value : i.description}
                </Option>
              ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.customerIdColor) ? this.state.pagination.customerIdColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalesmanOrder.discountValue" defaultMessage="Discount" />,
        key: "discountValue",
        width: 100,
        render: record => {
          return record.totalDiscount;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.totalDiscount) ? this.state.pagination.totalDiscount : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  current: 1,
                  totalDiscount: e.target.value,
                  totalDiscountColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.totalDiscountColor) ? this.state.pagination.totalDiscountColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalesmanOrder.grandTotal" defaultMessage="Total" />,
        key: "grandTotal",
        width: 100,
        render: record => {
          return record.grandTotal;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.grandTotal) ? this.state.pagination.grandTotal : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  current: 1,
                  grandTotal: e.target.value,
                  grandTotalColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.grandTotalColor) ? this.state.pagination.grandTotalColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="SalesmanOrder.invoicePaymentList" defaultMessage="Payment" />,
        key: "invoicePaymentList",
        width: 100,
        className: this.state.hide_invoicePaymentList ? "d-none" : "",
        render: record => {
          let a = record.invoicePaymentList.map(item => {
            return (
              <>
                <Tag key={item.id} color={item.paymentMethodType === "CASH" ? "green" : item.paymentMethodType === "TRANSFER" ? "blue" : "red"}>
                  {item.paymentMethodType} : {item.amount}
                </Tag>
              </>
            );
          });

          return a;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Checkbox.Group
              style={{ width: "100%" }}
              value={Boolean(this.state.pagination.invoicePaymentList) ? this.state.pagination.invoicePaymentList : null}
              onChange={async list => {
                console.log(list);

                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  current: 1,
                  invoicePaymentList: list,
                  invoicePaymentListColor: Boolean(list) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            >
              {this.paymentTypeItems.map(item => {
                return (
                  <Row key={item.value}>
                    <Checkbox value={item.value}>
                      <Tag color={item.value === "CASH" ? "green" : item.value === "TRANSFER" ? "blue" : "red"}>{item.label}</Tag>
                    </Checkbox>
                  </Row>
                );
              })}
            </Checkbox.Group>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.invoicePaymentListColor) ? this.state.pagination.invoicePaymentListColor : "#bfbfbf"
            }}
          />
        )
      },
      {
        title: <FormattedMessage id="SalesmanOrder.active" defaultMessage="Status" />,
        key: "active",
        width: 100,
        render: record => {
          return record.active ? (
            <p className="text-success">
              <FormattedMessage id="SalesmanOrder.active" defaultMessage="Active" />
            </p>
          ) : (
            <p className="text-danger">
              <FormattedMessage id="SalesmanOrder.deleted" defaultMessage="Deleted" />
            </p>
          );
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              style={{ flex: "auto" }}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  current: 1,
                  active: e.target.value === "true" ? true : e.target.value === "false" ? false : undefined,
                  activeColor: Boolean(e.target.value === "true") ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            >
              <Radio.Button>
                <FormattedMessage id="SalesmanOrder.all" defaultMessage="All" />
              </Radio.Button>
              <Radio.Button value="true">
                <FormattedMessage id="SalesmanOrder.active" defaultMessage="Active" />
              </Radio.Button>
              <Radio.Button value="false">
                <FormattedMessage id="SalesmanOrder.deleted" defaultMessage="Deleted" />
              </Radio.Button>
            </Radio.Group>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.remarksColor) ? this.state.pagination.remarksColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="General.LastUpdate" defaultMessage="Last Update" />,
        key: "lastUpdated",
        width: 100,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={async (dates, dateStrings) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  lastUpdateDate: dates,
                  lastUpdatedColor: Boolean(dates) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.lastUpdatedColor) ? this.state.pagination.lastUpdatedColor : "#bfbfbf" }} />
        ),
        sorter: true
      }
    ];

    const columnsSalableProducts = [
      {
        title: <FormattedMessage id="SalesmanOrder.sn" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="SalesmanOrder.productArabicName" defaultMessage="Product Name" />,
        key: "arabicName",
        width: 100,
        dataIndex: "arabicName",
        render: (value, row, index) => {
          return value;
        }
      },
      {
        title: <FormattedMessage id="SalesmanOrder.productName" defaultMessage="Product Name" />,
        key: "materialName",
        width: 100,
        dataIndex: "materialName",
        render: (value, row, index) => {
          return value;
        }
      },

      {
        title: <FormattedMessage id="SalesmanOrder.productQuantity" defaultMessage="Quantity" />,
        key: "quantity",
        width: 100,
        dataIndex: "quantity"
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };

      const newColumnsSalableProducts = [...columnsSalableProducts];
      newColumnsSalableProducts[index] = {
        ...newColumnsSalableProducts[index],
        width: size.width
      };

      const newColumnsInvoiceProducts = [...columnsInvoiceProducts];
      newColumnsInvoiceProducts[index] = {
        ...newColumnsInvoiceProducts[index],
        width: size.width
      };

      this.setState({
        columns: newColumns,
        columnsSalableProducts: newColumnsSalableProducts,
        columnsInvoiceProducts: newColumnsInvoiceProducts
      });
    };

    const mergeColumnsInvoiceProducts =
      Boolean(this.state.columnsInvoiceProducts) && this.state.columnsInvoiceProducts.length > 0
        ? this.state.columnsInvoiceProducts.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: handleResize(index)
            })
          }))
        : Boolean(columnsInvoiceProducts) &&
          columnsInvoiceProducts.length > 0 &&
          columnsInvoiceProducts.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: handleResize(index)
            })
          }));

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: handleResize(index)
            })
          }))
        : Boolean(columns) &&
          columns.length > 0 &&
          columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: handleResize(index)
            })
          }));

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <Badge.Ribbon
        text={<FormattedMessage id={"SalesmanOrder.pageTitle"} defaultMessage={"Salesman Order"} />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form
            initialValues={{
              remember: false,
              vatValue: 15,
              payment1: {
                paymentMethodType: "CASH"
              }
            }}
            onFinish={this.save}
            onFinishFailed={onFinishFailed}
            ref={this.formRef}
          >
            <Row gutter={[16, 16]}>
              <Col md={23}></Col>
              <Col md={1}>{excelExport}</Col>
            </Row>

            <div hidden={this.state.hideInputs}>
              <div hidden={this.state.hideSave}>
                {
                  <Form.Item {...tailFormItemLayout}>
                    <AntButton
                      id="SaveButton"
                      htmlType="submit"
                      type="primary"
                      style={{ width: "100%" }}
                      className="btn btn-success"
                      icon={<SaveOutlined style={{ verticalAlign: "1px", marginRight: "5px" }} />}
                      loading={this.state.saveLoading}
                    >
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </AntButton>
                  </Form.Item>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <AntButton id="CancelButton" type="primary" style={{ width: "100%" }} className="btn btn-default" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </AntButton>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <AntButton
                        id="UpdateButton"
                        htmlType="submit"
                        type="primary"
                        style={{ width: "100%" }}
                        className="btn btn-warning"
                        loading={this.state.saveLoading}
                      >
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </AntButton>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              rowKey="id"
              rowClassName={(record, index) => (record.active ? "" : "text-danger")}
              scroll={{ x: 1500, scrollToFirstRowOnChange: true }}
              components={{
                header: {
                  cell: ResizableTitle
                }
              }}
              columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
              <p>
                <TextArea
                  placeholder="Delete Reason"
                  value={this.state.deleteReason}
                  onChange={e => this.setState({ deleteReason: e.target.value })}
                />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            size="xl"
            show={this.state.modalPdfViewer}
            onHide={this.showOrHideModalPdfViewer}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralPDFReportViewer" defaultMessage="PDF Report Viewer" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                <div style={{ height: "auto", maxHeight: "74vh", overflow: "auto" }}>
                  <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                </div>
              </Worker>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideModalPdfViewer}>
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.showInvoiceDetailModal}
            onHide={() => {
              this.setState({
                showInvoiceDetailModal: false
              });
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="Invoice.invoiceProductList " defaultMessage="Invoice Product List " />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table
                bordered
                rowKey="id"
                scroll={{ x: 1000, scrollToFirstRowOnChange: true }}
                components={{
                  header: {
                    cell: ResizableTitle
                  }
                }}
                columns={Boolean(mergeColumnsInvoiceProducts) && mergeColumnsInvoiceProducts.length > 0 ? mergeColumnsInvoiceProducts : columns}
                dataSource={this.state.invoiceProductList}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showInvoiceDetailModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SalesmanOrder);
