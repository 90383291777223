import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { CloseCircleOutlined, FilterOutlined } from "@ant-design/icons";
import { Badge, Drawer, Tooltip } from "antd";
import { showError, showSuccess, error } from "../../MessageHelper";
import { downloadMultipleFile } from "../helper/UploadDownloadHelper";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Form, Table, Input, Select, Card, Divider, DatePicker, Button as AntButton, Col, Row, Space, InputNumber } from "antd";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import { PlusOutlined, CloseOutlined, CheckOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { DownloadOutlined } from "@ant-design/icons";
import { Resizable } from "react-resizable";
import { PageSettings } from "../administrator/pageSettings/PageSettings";
import { Settings } from "@material-ui/icons";
import store from "../../../redux/store";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class ExpenseLog extends Component {
  constructor() {
    super();
    this.state = {
      formName: "ExpenseLog",
      controllerName: "cexpense",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      expenseCodeIdSelectItems: null,
      supplierIdSelectItems: null,
      amountCurrencySelectItems: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      searchCriteriaModels: [],
      user: store.getState().auth.user
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    fillFilterOperations();
  };

  fillComboboxes = async () => {
    var responseexpenseCodeId = await handleRequest("GET", "/api/codes/type/expenseCodeId");
    if (responseexpenseCodeId.type === "ERROR") {
      error(responseexpenseCodeId);
    } else {
      this.setState({
        expenseCodeIdSelectItems: Boolean(responseexpenseCodeId.data) ? responseexpenseCodeId.data : []
      });
    }
    var responsesupplierId = await handleRequest("GET", "/api/expense/supplierIdSelectItems");
    if (responsesupplierId.type === "ERROR") {
      error(responsesupplierId);
    } else {
      this.setState({
        supplierIdSelectItems: Boolean(responsesupplierId.data) ? responsesupplierId.data : []
      });
    }
    var responseamountCurrency = await handleRequest("GET", "/api/expense/amountCurrencySelectItems");
    if (responseamountCurrency.type === "ERROR") {
      error(responseamountCurrency);
    } else {
      this.setState({
        amountCurrencySelectItems: Boolean(responseamountCurrency.data) ? responseamountCurrency.data : []
      });
    }

    var responsePayerId = await handleRequest("GET", "/api/expense/payerIdSelectItems");
    if (responsePayerId.type === "ERROR") {
      error(responsePayerId);
    } else {
      this.setState({
        payerIdSelectItems: Boolean(responsePayerId.data) ? responsePayerId.data : []
      });
    }

    var responseCurrency = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responseCurrency.type === "ERROR") {
      error(responseCurrency);
    }

    this.setState({
      currencySelectItems: Boolean(responseCurrency.data) ? responseCurrency.data : []
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = [];

    let invoicePath = Boolean(row.invoicePath) ? row.invoicePath.split(",") : [];
    if (Boolean(invoicePath) && invoicePath.length > 0) {
      invoicePath.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileList.push(obj);
      });
    }

    let documents = [];

    if (Boolean(row.documentList) && row.documentList.length > 0) {
      row.documentList.forEach(element => {
        let uploadedDocumentList = [];

        let documentPath = Boolean(element.documentPath) ? element.documentPath.split(",") : [];
        if (Boolean(documentPath) && documentPath.length > 0) {
          documentPath.forEach((item, index) => {
            let obj = {
              uid: index,
              name: item.split("download/")[1],
              url: item,
              thumbUrl: item
            };

            uploadedDocumentList.push(obj);
          });
        }
        let obj = { ...element, documentPath: uploadedDocumentList };
        documents.push(obj);
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      invoiceDate: Boolean(row.invoiceDate) ? moment(row.invoiceDate) : null,
      documents: documents
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      fileList: fileList
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/expense/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/expense/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        console.log(list);
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    console.log(values);

    let documents = [];

    if (Boolean(values.documents)) {
      values.documents.forEach(element => {
        let pathList = [];
        if (Boolean(element.documentPath))
          element.documentPath.forEach(item => {
            if (Boolean(item.response) && Boolean(item.response.url)) pathList.push(item.response.url);
            else if (Boolean(item.url)) pathList.push(item.url);
          });
        let doc = { ...element, documentPath: pathList.toString() };
        documents.push(doc);
      });
    }

    const newItem = {
      ...values,
      id: this.state.id,
      invoicePath: Boolean(this.state.fileList) && this.state.fileList.length > 0 ? this.state.fileList[0].url : "",
      invoiceDate: Boolean(values.invoiceDate) ? values.invoiceDate.toDate() : null,
      documentList: documents
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/expense", newItem);
    } else {
      response = await handleRequest("PUT", "/api/expense/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      searchCriteriaModels: this.state.searchCriteriaModels,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/expense/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 16,
          offset: 0
        },
        sm: {
          span: 8,
          offset: 8
        }
      }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        width: 100,
        render: this.action
      },

      {
        title: "S/N",
        key: "id",

        ellipsis: true,
        width: 150,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="Expense.expenseCodeId" defaultMessage="Expense" />,
        key: "expenseCodeId",
        ellipsis: true,
        width: 150,
        render: record => {
          if (record.expenseCode != null) {
            return <Tooltip title={record.expenseCode.name}>{record.expenseCode.name}</Tooltip>;
          }
        },
        ...getColumnFilter({
          inputName: "expenseCodeId",
          inputType: "SELECT",
          selectItems:
            Boolean(this.state["expenseCodeIdSelectItems"]) && this.state["expenseCodeIdSelectItems"].length > 0
              ? this.state["expenseCodeIdSelectItems"]
              : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="Expense.supplierId" defaultMessage="Supplier" />,
        key: "supplierId",

        ellipsis: true,
        width: 150,
        render: record => {
          if (Boolean(record) && Boolean(record.supplier)) {
            return <Tooltip title={record.supplier.name}>{record.supplier.name}</Tooltip>;
          }
          return "";
        },
        ...getColumnFilter({
          inputName: "deptorId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["supplierIdSelectItems"]) ? this.state["supplierIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ExpenseLog.SupplierArabic" defaultMessage="Supplier (Arabic)" />,
        key: "supplierArabic",
        width: 100,
        dataIndex: "arabicName",
        render: (value, row, index) => {
          return value;
        },
        ...getColumnFilter({
          inputName: "amount",
          inputType: "TEXT",
          selectItems: Boolean(this.state["amountSelectItems"]) ? this.state["amountSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),

        sorter: true
      },
      {
        title: <FormattedMessage id="Expense.amount" defaultMessage="Amount" />,
        key: "amount",
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.amount}>{record.amount}</Tooltip>;
        },
        ...getColumnFilter({
          inputName: "amount",
          inputType: "TEXT",
          selectItems: Boolean(this.state["amountSelectItems"]) ? this.state["amountSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),

        sorter: true
      },
      {
        title: <FormattedMessage id="Expense.amountCurrency" defaultMessage="Currency" />,
        key: "amountCurrency",
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.amountCurrency}>{record.amountCurrency}</Tooltip>;
        },
        ...getColumnFilter({
          inputName: "amountCurrency",
          inputType: "SELECT",
          selectItems: Boolean(this.state["amountCurrencySelectItems"]) ? this.state["amountCurrencySelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="Expense.Payer" defaultMessage="Payer" />,
        key: "payerId",
        ellipsis: true,
        width: 150,
        render: record => {
          return record.payerName;
        },
        ...getColumnFilter({
          inputName: "payerId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["payerIdSelectItems"]) ? this.state["payerIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="Expense.invoiceNo" defaultMessage="Invoice No" />,
        key: "invoiceNo",
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.invoiceNo}>{record.invoiceNo}</Tooltip>;
        },
        ...getColumnFilter({
          inputName: "invoiceNo",
          inputType: "SELECT",
          selectItems: Boolean(this.state["invoiceNoSelectItems"]) ? this.state["invoiceNoSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),

        sorter: true
      },
      {
        title: <FormattedMessage id="Expense.invoicePath" defaultMessage="Invoice" />,
        key: "invoicePath",
        ellipsis: true,
        width: 150,
        render: record => {
          if (Boolean(record.invoicePath))
            return (
              <AntButton
                style={{
                  marginLeft: "2px",
                  marginTop: "-2px",
                  alignContent: "center"
                }}
                onClick={() => downloadMultipleFile(record.invoicePath)}
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
              ></AntButton>
            );
        }
      },
      {
        title: <FormattedMessage id="Expense.InvoiceDate" defaultMessage="Invoice Date" />,
        key: "invoiceDate",
        ellipsis: true,
        width: 150,
        render: record => {
          return Boolean(record.invoiceDate) ? moment(record.invoiceDate).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "invoiceDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="Expense.remarks" defaultMessage="Remarks" />,
        key: "remarks",
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.remarks}>{record.remarks}</Tooltip>;
        },
        ...getColumnFilter({
          inputName: "remarks",
          inputType: "SELECT",
          selectItems: Boolean(this.state["remarksSelectItems"]) ? this.state["remarksSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),

        sorter: true
      },
      {
        title: <FormattedMessage id="Expense.LastUpdate" defaultMessage="Last Update" />,
        key: "lastUpdated",
        ellipsis: true,
        width: 150,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        ...getColumnFilter({
          inputName: "lastUpdated",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];

      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: handleResize(index)
            })
          }))
        : Boolean(columns) &&
          columns.length > 0 &&
          columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: handleResize(index)
            })
          }));

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const listUploadProps = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    const normFile = e => {
      console.log("Upload event:", e);

      if (Array.isArray(e)) {
        return e;
      }

      return e && e.fileList;
    };

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="ExpenseLog.pageTitle" defaultMessage="Expense Log" />}
        placement="start"
        color="purple"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={20}></Col>
              <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
              <Col md={1}>
                {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                  <Tooltip title={<FormattedMessage id="RequestForQuotation.Settings" defaultMessage="Settings" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => {
                        this.setState({
                          drawerSettingsShow: true
                        });
                      }}
                      icon={<Settings color="error" fontSize="large" />}
                    ></AntButton>
                  </Tooltip>
                )}
              </Col>
            </Row>
            <br></br>
            <br></br>

            <div hidden={this.state.hideInputs}>
              <Row gutter={24} style={{ marginBottom: "16px" }}>
                {/* Sol Card */}

                <Col span={12}>
                  <Card
                    //loading={!this.state.loadingCard}
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="Expense.expenseCodeId" defaultMessage="Expense" />}
                        name="expenseCodeId"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Select
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          placeholder="Expense"
                          dropdownRender={menu => (
                            <div>
                              {menu}
                              <Divider style={{ margin: "4px 0" }} />
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "nowrap",
                                  padding: 8
                                }}
                              >
                                <Input
                                  style={{ flex: "auto" }}
                                  value={this.state.codeName}
                                  onChange={event => {
                                    this.setState({
                                      codeName: event.target.value
                                    });
                                  }}
                                />
                                <a
                                  style={{
                                    flex: "none",
                                    padding: "8px",
                                    display: "block",
                                    cursor: "pointer"
                                  }}
                                  onClick={() => this.addCode("expenseCodeId")}
                                >
                                  <PlusOutlined /> Add item
                                </a>
                              </div>
                            </div>
                          )}
                        >
                          {Boolean(this.state["expenseCodeIdSelectItems"]) &&
                            this.state["expenseCodeIdSelectItems"].map(i => (
                              <Option key={i.id} value={i.id}>
                                {i.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="Expense.supplierId" defaultMessage="Supplier" />}
                        name="supplierId"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Select placeholder="Supplier" allowClear showSearch optionFilterProp="children">
                          {Boolean(this.state["supplierIdSelectItems"]) &&
                            this.state["supplierIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        hidden={this.state.hide_customerArabicName}
                        label={<FormattedMessage id="Invoice.supplierArabicName" defaultMessage="عميل" />}
                        name="customerId"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Select
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          placeholder="عميل"
                          dropdownRender={menu => (
                            <div>
                              {menu}
                              <Divider style={{ margin: "4px 0" }} />
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "nowrap",
                                  padding: 8
                                }}
                              >
                                <AntButton type="link" icon={<PlusOutlined />} onClick={this.showCustomerModal}>
                                  <FormattedMessage id="Invoice.إضافة عميل جديد" defaultMessage=" إضافة عميل جديد" />
                                </AntButton>
                              </div>
                            </div>
                          )}
                        >
                          {Boolean(this.state["customerIdSelectItems"]) &&
                            this.state["customerIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.description}
                              </Option>
                            ))}{" "}
                        </Select>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="Expense.Payer" defaultMessage="Payer" />}
                        name="payerId"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Select placeholder="Person" allowClear showSearch optionFilterProp="children">
                          {Boolean(this.state["payerIdSelectItems"]) &&
                            this.state["payerIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="Expense.amount" defaultMessage="Amount" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Space.Compact>
                          <Form.Item
                            name="amount"
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              parser={value => value.replace(",", ".")}
                              formatter={value => value.replace(".", ",")}
                            />
                          </Form.Item>

                          <Form.Item
                            name="amountCurrency"
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                            style={{ width: "50%" }}
                          >
                            <Select allowClear showSearch optionFilterProp="children">
                              {Boolean(this.state["amountCurrencySelectItems"]) &&
                                this.state["amountCurrencySelectItems"].length > 0 &&
                                this.state["amountCurrencySelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Space.Compact>
                      </Form.Item>
                    }

                    {/*{
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Expense.amountCurrency" defaultMessage="Currency" />}
                  name="amountCurrency"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select allowClear placeholder="Currency" showSearch optionFilterProp="children">
                    {Boolean(this.state["amountCurrencySelectItems"]) &&
                      this.state["amountCurrencySelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }*/}
                  </Card>
                </Col>

                {/* Sağ Card */}
                <Col span={12}>
                  <Card
                    //loading={this.state.loadingCard}
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="Expense.invoiceNo" defaultMessage="Invoice No" />}
                        name="invoiceNo"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input placeholder="Invoice No" />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="Expense.invoicePath" defaultMessage="Invoice" />}
                        name="invoicePath"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Upload
                          {...props}
                          showUploadList={true}
                          fileList={this.state.fileList}
                          onChange={({ fileList }) => {
                            if (fileList.length == 1) {
                              if (Boolean(fileList[0].response)) {
                                fileList[0].url = fileList[0].response.url;
                                showSuccess("file uploaded successfully");
                                // fileName: info.file.name,
                              }
                              this.setState({
                                fileList: fileList
                              });
                            }
                          }}
                          beforeUpload={fileList => {
                            if (this.state.fileList.length > 0) {
                              showError("You have been already choose a file! For change firstly remove the uploaded file.");
                              return false;
                            }
                            return true;
                          }}
                          onRemove={fileList => {
                            this.setState({
                              fileList: []
                            });
                          }}
                        >
                          <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                            <UploadOutlined /> Upload
                          </AntButton>
                        </Upload>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="Expense.invoiceDate" defaultMessage="InvoiceDate" />}
                        name="invoiceDate"
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <DatePicker format="DD-MM-YYYY" />
                      </Form.Item>
                    }
                    {
                      <Form.List name="documents">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(field => (
                              <Form.Item {...tailFormItemLayout}>
                                <Form.Item
                                  {...field}
                                  style={{
                                    display: "inline-block",
                                    width: "calc(40%)"
                                  }}
                                  extra="Document Name"
                                  name={[field.name, "documentName"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing Document Name"
                                    }
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  {...field}
                                  style={{
                                    display: "inline-block",
                                    width: "calc(40%)"
                                  }}
                                  extra="Load"
                                  name={[field.name, "documentPath"]}
                                  rules={[{ required: true, message: "Missing Load" }]}
                                  valuePropName="fileList"
                                  getValueFromEvent={normFile}
                                >
                                  <Upload {...listUploadProps} name="file">
                                    <AntButton icon={<UploadOutlined />}>Click to upload</AntButton>
                                  </Upload>
                                </Form.Item>

                                <Form.Item {...field} hidden name={[field.name, "id"]}>
                                  <InputNumber></InputNumber>
                                </Form.Item>

                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                              </Form.Item>
                            ))}

                            <Form.Item {...tailFormItemLayout}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add document
                              </AntButton>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="Expense.remarks" defaultMessage="Remarks" />}
                        name="remarks"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input placeholder="Remarks" style={{ width: "100%" }} />
                      </Form.Item>
                    }
                  </Card>
                </Col>
                <Col span={1}></Col>
              </Row>
              <br></br>
              <br></br>

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              scroll={{ x: 900, y: 400, scrollToFirstRowOnChange: true }}
              components={{
                header: {
                  cell: ResizableTitle
                }
              }}
              columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          <Drawer
            title={<FormattedMessage id="Product.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ drawerSettingsShow: false });
            }}
            open={this.state.drawerSettingsShow}
          >
            <PageSettings pageName={"Expense_Log"} />
          </Drawer>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseLog);
