import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Table, Badge, Card, Row, Col, Form, Select, InputNumber } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import moment from "moment";
import CommentIcon from "@material-ui/icons/Comment";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import PlurkSvg from "../procurement/stockcontrol/Icon/plurk.svg";
import Vimeo from "../procurement/stockcontrol/Icon/vimeo.svg";

const { Option } = Select;

export class MaintenancePlanLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "MaintenancePlanLog",
      controllerName: "maintenancePlanLog",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      tab: "station",

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);

    let url = "/api/" + this.state.controllerName + "/pagingStation";
    if (this.state.tab === "equipment") {
      url = "/api/" + this.state.controllerName + "/pagingEquipment";
    }
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let actionList = [];
    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  sendAllModal = row => {
    this.setState({
      modalSendAll: true,
      id: row.id,
      selectedRow: row
    });
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  detailModal = async row => {
    this.setState({
      selectedRow: row,
      modalDetailShow: true,
      random: Math.random()
    });
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columnsStation = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="MaintenancePlanLog.Date" defaultMessage="Date" />,
        key: "date",
        render: record => {
          return Boolean(record.date) ? moment(record.date).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "date",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="MaintenancePlanLog.Station" defaultMessage="Station" />,
        key: "stationName",
        render: record => {
          return record.stationName;
        },
        ...getColumnFilter({
          inputName: "stationName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="MaintenancePlanLog.Equipment" defaultMessage="Equipment" />,
        key: "equipmentLogName",
        render: record => {
          return record.equipmentLogName;
        },
        ...getColumnFilter({
          inputName: "equipmentLogName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="MaintenancePlanLog.Control" defaultMessage="Control" />,
        key: "controlName",
        render: record => {
          return record.controlName;
        },
        ...getColumnFilter({
          inputName: "controlName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="MaintenancePlanLog.Criteria" defaultMessage="Criteria" />,
        key: "id",
        render: record => {
          let allStartValue = [];
          let km = record?.durations?.find(p => p.type === "Km")?.periodType || "";
          let workTime = record?.durations?.find(p => p.type === "Work_Time")?.periodType || "";
          let calendar = record?.durations?.find(p => p.type === "Calendar")?.periodType || "";

          km && allStartValue.push(record?.startKm ? `${record.startKm} ${km}` : `0 ${km}`);
          workTime && allStartValue.push(record?.startWorkTime ? `${record.startWorkTime} ${workTime}` : `0 ${workTime}`);
          calendar && allStartValue.push(record?.startCalendar ? `${record.startCalendar} ${calendar}` : `0 ${calendar}`);

          return allStartValue.filter(Boolean).join(" - ");
        }
      }
    ];

    const columnsEquipment = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="MaintenancePlanLog.Date" defaultMessage="Date" />,
        key: "date",
        render: record => {
          return Boolean(record.date) ? moment(record.date).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "date",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="MaintenancePlanLog.Equipment" defaultMessage="Equipment" />,
        key: "equipmentLogName",
        render: record => {
          return record.equipmentLogName;
        },
        ...getColumnFilter({
          inputName: "equipmentLogName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="MaintenancePlanLog.Control" defaultMessage="Control" />,
        key: "controlName",
        render: record => {
          return record.controlName;
        },
        ...getColumnFilter({
          inputName: "controlName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="MaintenancePlanLog.Station" defaultMessage="Station" />,
        key: "stationName",
        render: record => {
          return record.stationName;
        },
        ...getColumnFilter({
          inputName: "stationName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="MaintenancePlanLog.Criteria" defaultMessage="Criteria" />,
        key: "id",
        render: record => {
          let allStartValue = [];
          let km = record?.durations?.find(p => p.type === "Km")?.periodType || "";
          let workTime = record?.durations?.find(p => p.type === "Work_Time")?.periodType || "";
          let calendar = record?.durations?.find(p => p.type === "Calendar")?.periodType || "";

          km && allStartValue.push(record?.startKm ? `${record.startKm} ${km}` : `0 ${km}`);
          workTime && allStartValue.push(record?.startWorkTime ? `${record.startWorkTime} ${workTime}` : `0 ${workTime}`);
          calendar && allStartValue.push(record?.startCalendar ? `${record.startCalendar} ${calendar}` : `0 ${calendar}`);

          return allStartValue.filter(Boolean).join(" - ");
        }
      }
    ];

    const tabTitleComponent = (title, key) => (
      <div className={"card-title-component"}>
        <img
          src={key === "station" ? PlurkSvg : Vimeo}
          alt="Icon"
          style={{ width: "20px", height: "20px", marginRight: "10px" }}
          color={key === this.state.tab ? "#469CF0" : "#A2A5B5"}
        />
        <span style={{ color: key === this.state.tab ? "#469CF0" : "#A2A5B5" }}>{title}</span>
      </div>
    );

    const tabList = [
      {
        key: "station",
        tab: tabTitleComponent("Station", "station")
      },
      {
        key: "equipment",
        tab: tabTitleComponent("Equipment", "equipment")
      }
    ];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="MaintenancePlanLog.pageTitle" defaultMessage="Maintenance Plan Log" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Card
            bodyStyle={{ padding: 0 }}
            tabList={tabList}
            style={{ marginTop: 32, marginBottom: 8 }}
            onTabChange={value => {
              const { pagination } = this.state;
              let _pagination = {
                ...pagination,
                current: 1
              };
              this.setState(
                {
                  tab: value,
                  selectedRowKeys: [],
                  pagination: _pagination
                },
                () => {
                  const { pagination } = this.state;
                  this.restartTable({ pagination });
                }
              );
            }}
          />

          <Row gutter={[16, 16]}>
            <Col md={8}></Col>
            <Col md={3}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="MaintenancePlanLog.Last" defaultMessage="Last" />}
                name="last"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  onChange={value => {
                    this.setState({
                      last: value
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={5}>
              <Form.Item
                name="lastPeriod"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  allowClear
                  showSearch
                  style={{ width: "150px" }}
                  optionFilterProp="children"
                  onChange={value => {
                    this.setState({
                      lastPeriod: value
                    });
                  }}
                >
                  <Option key={"Day"} value={"Day"}>
                    {"Day"}
                  </Option>
                  <Option key={"Month"} value={"Month"}>
                    {"Month"}
                  </Option>
                  <Option key={"Maintenance"} value={"Maintenance"}>
                    {"Maintenance"}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={this.state.tab === "station" ? columnsStation : columnsEquipment}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(MaintenancePlanLog);
