import moment from "moment";
import { EditOutlined } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import CommentIcon from "@material-ui/icons/Comment";
import { Button as AntButton, Col, Form, Row, Table, DatePicker, Input, Tag, Select, Tooltip } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import ElementComponent from "../../Components/Element";
import { GetDynamicFormItems, PrepareRowForEdit, PrepareRowForSave } from "../../Components/ElementUtils";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { ExcelReader } from "../excel/ExcelReader";
import { FilterOutlined } from "@ant-design/icons";
import ShareIcon from "@material-ui/icons/Share";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import { DownloadOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Option } = Select;

const generateItemList = data => {
  let itemList = [];
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    itemList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children && node.children.length > 0) {
      itemList.push(...generateItemList(node.children));
    }
  }
  return itemList;
};
export class TrainingSchedule extends Component {
  constructor() {
    super();
    this.state = {
      formName: "TrainingSchedule",
      controllerName: "trainingSchedule",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      type: "Edit",
      userSelectItems: [],

      lastUpdateDate: [],
      id: null,
      tableList: [],
      pageFormData: null,
      excelData: [],
      refreshForm: false,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    let data = await GetDynamicFormItems(this.state.formName);
    this.setState({
      pageFormData: data
    });
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes(data.pageFormInputCompanyList);
  };
  componentDidUpdate = async nextProps => { };
  fillComboboxes = async inputList => {
    inputList.forEach(async (item, index) => {
      if (item.inputType === "CASCADER") {
        let url = item.loadUrl;
        var response = await handleRequest("GET", url);
        if (response.type === "ERROR") {
          error(response);
        } else {
          let list = generateItemList(response.data);
          this.setState({
            [item.inputName + "Cascader"]: list
          });
        }
      }
    });

    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/personnels");
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        userSelectItems: Boolean(response.data) ? response.data : [],
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      type: "Edit",

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  setData = data => {
    this.setState({
      tableList: data
    });
  };

  post = row => {
    var selectedMailAddress = [];
    if (Boolean(row.attendeesIds) && row.attendeesIds.length > 0) {
      selectedMailAddress = row.attendeesIds;
    }
    this.setState({
      selectedMailAddress: selectedMailAddress,
      showShareModal: true,
    });
  }

  share = async () => {

  }

  attendanceSheet = row => {

  }

  edit = (type, row) => {
    var attendees = [];
    if (Boolean(row.attendeesIds) && row.attendeesIds.length > 0) {
      attendees = row.attendeesIds;
    }
    let handledValues = {
      ...row,
      attendees: attendees
    };
    handledValues = PrepareRowForEdit(handledValues, this.state.pageFormData.pageFormInputCompanyList);
    this.state.pageFormData.pageFormInputCompanyList.forEach(item => {
      if (item.inputType === "CASCADER" && this.state[item.inputName + "Cascader"]) {
        let itemId = this.state[item.inputName + "Cascader"].find(p => p.key === row[item.inputName]);
        if (itemId) {
          handledValues[item.inputName] = itemId.lineage;
        }
      }
    });
    this.formRef.current.setFieldsValue({
      ...handledValues
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      type: type
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    let newItem = {
      id: this.state.id,
      type: this.state.type,
      ...values
    };
    newItem = PrepareRowForSave(newItem, this.state.pageFormData.pageFormInputCompanyList);

    var attendees = [];
    if (Boolean(values.attendees) && values.attendees.length > 0) {
      values.attendees.forEach(element => {
        attendees.push({
          ["personnelLogId"]: element,
        });
      });
    }
    newItem.attendees = attendees;

    if (!Boolean(newItem.id)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
    } else {
      if (this.state.type === "Revise") {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/revise/" + newItem.id, newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
      }
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          this.state.pageFormData.pageFormInputCompanyList.forEach(input => {
            Boolean(input.tableColumnValue) ? (obj[input.tableColumnValue] = element[input.label]) : (obj[input.inputName] = element[input.label]);
          });
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this, "Edit")
            },
            {
              name: "Post",
              icon: <ShareIcon fontSize="small" color="secondary" />,
              actionClick: this.post.bind(this)
            },
            {
              name: "Revise",
              icon: <AccountTreeIcon fontSize="small" color="error" />,
              actionClick: this.edit.bind(this, "Revise")
            },
            {
              name: "Attendance Sheet",
              icon: <CommentIcon fontSize="small" color="primary" />,
              actionClick: this.attendanceSheet.bind(this, "AttendanceSheet")
            },
            {
              name: "Update",
              icon: <EditOutlined fontSize="small" color="secondary" />,
              actionClick: this.edit.bind(this, "Update")
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: "Description of Training",
        key: "description",
        render: record => {
          return record.description;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.description) ? this.state.pagination.description : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  description: e.target.value,
                  descriptionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.descriptionColor) ? this.state.pagination.descriptionColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: "Rev no",
        key: "revNo",
        render: record => {
          return record.revNo;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.revNo) ? this.state.pagination.revNo : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  revNo: e.target.value,
                  revNoColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.revNoColor) ? this.state.pagination.revNoColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: "Organization",
        key: "organizationName",
        render: record => {
          return record.organizationName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.organizationName) ? this.state.pagination.organizationName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  organizationName: e.target.value,
                  organizationNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.organizationNameColor) ? this.state.pagination.organizationNameColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: "Trainee",
        key: "trainee",
        render: record => {
          return record.trainee;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.trainee) ? this.state.pagination.trainee : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  trainee: e.target.value,
                  traineeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.traineeColor) ? this.state.pagination.traineeColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: "Attendees",
        key: "attendeesNames",
        render: record => {
          return record.attendeesNames;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.attendeesNames) ? this.state.pagination.attendeesNames : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  attendeesNames: e.target.value,
                  attendeesNamesColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.attendeesNamesColor) ? this.state.pagination.attendeesNamesColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: "Start date, time",
        key: "startDateList",
        render: record => {
          return Boolean(record.startDateList) && record.startDateList.length > 0 &&
            record.startDateList.map((item, index) => {
              return (
                Boolean(item.description) &&
                <Tooltip title={index === 0 ? "Start date, time" : "Planned Start date, time"}>
                  <Tag color={item.color}>
                    {moment(item.description).format("DD-MM-YYYY HH:mm")}
                  </Tag>
                </Tooltip>
              );
            });
        },
      },
      {
        title: "End Date, time",
        key: "endDateList",
        render: record => {
          return Boolean(record.endDateList) && record.endDateList.length > 0 &&
            record.endDateList.map((item, index) => {
              return (
                Boolean(item.description) && 
                <Tooltip title={index === 0 ? "End date, time" : "Planned End date, time"}>
                  <Tag color={item.color}>
                    {moment(item.description).format("DD-MM-YYYY HH:mm")}
                  </Tag>
                </Tooltip>
              );
            });
        },
      },
      {
        title: "Total Training Days",
        key: "totalTrainingDaysList",
        render: record => {
          return Boolean(record.totalTrainingDaysList) && record.totalTrainingDaysList.length > 0 &&
            record.totalTrainingDaysList.map((item, index) => {
              return (
                Boolean(item.description) &&
                <Tooltip title={index === 0 ? "Total training days" : "Planned total training days"}>
                  <Tag color={item.color}>
                    {item.description}
                  </Tag>
                </Tooltip>
              );
            });
        },
      },
      {
        title: "Total Training hours",
        key: "totalTrainingHoursList",
        render: record => {
          return Boolean(record.totalTrainingHoursList) && record.totalTrainingHoursList.length > 0 &&
            record.totalTrainingHoursList.map((item, index) => {
              return (
                Boolean(item.description) &&
                <Tooltip title={index === 0 ? "Total training hours" : "Planned total training hours"}>
                  <Tag color={item.color}>
                    {item.description}
                  </Tag>
                </Tooltip>
              );
            });
        },
      },
      {
        title: "Location of Trainig",
        key: "locationOfTrainigId",
        render: record => {
          return "";
        },
      },
      {
        title: "Files",
        key: "fileUrls",
        render: record => {
          let path = record.fileUrls;
          if (Boolean(path)) {
            path = path.split(",");
            let buttons = path.map((item, index) => {
              return (
                Boolean(item) && <>
                  <AntButton key={index} href={item} target="_blank" type="primary" shape="round" icon={< DownloadOutlined />}>
                    <span>{index === 0 ? "Training Document" : "Attendance Sheet"}</span>
                  </AntButton>
                </>
              );
            });
            return buttons;
          } else return "";
        },
      },
      {
        title: "Status",
        key: "status",
        render: record => {
          return "";
        },
      },
      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename={this.state.formName} hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
              {Boolean(this.state.pageFormData) &&
                this.state.pageFormData.pageFormInputCompanyList.map(item => {
                  return (
                    <ExcelColumn
                      key={item.inputName}
                      label={item.label}
                      value={Boolean(item.tableColumnValue) ? item.tableColumnValue : item.inputName}
                    />
                  );
                })}
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col md={21}>
            <span className="h3">
              <FormattedMessage
                id={this.state.controllerName + ".pageTitle"}
                defaultMessage={Boolean(this.state.pageFormData) ? this.state.pageFormData.pageForm.title : ""}
              />
            </span>
          </Col>
          <Col md={1}>{excelExport}</Col>
          <Col md={1}>
            <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
          </Col>
          <Col md={1}>
            <AntButton
              style={{ border: "0px" }}
              hidden={!this.state.hideInputs}
              onClick={this.onClickCreateNew}
              icon={<CreateIcon color="secondary" fontSize="large" />}
            ></AntButton>
            <AntButton
              style={{ border: "0px" }}
              hidden={this.state.hideInputs}
              onClick={this.onClickCreateNew}
              icon={<RemoveCircle color="error" fontSize="large" />}
            ></AntButton>
          </Col>
        </Row>
        <div hidden={this.state.hideInputs}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
              <>
                {(Boolean(this.state.pageFormData.pageFormInputCompanyList) && this.state.pageFormData.pageFormInputCompanyList.length > 0) ?
                  this.state.pageFormData.pageFormInputCompanyList.map((field, i) =>
                    <>
                      {
                        this.state.type === "Update" && !(field.inputName === "plannedStartDate" || field.inputName === "plannedEndDate"
                          || field.inputName === "plannedTotalTrainingDays" || field.inputName === "plannedTotalTrainingHours") &&
                        <ElementComponent key={i} field={field} layout={layout} form={this.formRef} refresh={this.state.refreshForm} />
                      }
                      {
                        this.state.type !== "Update" && !(field.inputName === "attendanceSheetUrl" || field.inputName === "startDate" ||
                          field.inputName === "endDate" || field.inputName === "totalTrainingDays" || field.inputName === "totalTrainingHours") &&
                        <ElementComponent key={i} field={field} layout={layout} form={this.formRef} refresh={this.state.refreshForm} />
                      } </>
                  )
                  : null}
              </>
            ) : null}
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </Form>
        </div>
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showShareModal}
          onHide={() => {
            this.setState({
              showShareModal: false,
            });
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {" "}
              Share File
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Select
              allowClear
              showSearch
              style={{ width: "100%" }}
              mode="tags"
              id="selectedMailAddress"
              value={this.state.selectedMailAddress}
              onChange={(value) => {
                this.setState({ selectedMailAddress: value });
              }}
            >
              {this.state.userSelectItems.map((i) => (
                <Option value={i.key}>{i.value}</Option>
              ))}
            </Select>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  showShareModal: false,
                });
              }}
            >
              Close
            </Button>
            <Button variant="info" onClick={this.share}>
              {" "}
              Share{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TrainingSchedule);