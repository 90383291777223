import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableAnt } from "react-bootstrap";
import { FilterOutlined, CloseCircleOutlined, ArrowRightOutlined, UndoOutlined, PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import DeleteIcon from "@material-ui/icons/Delete";
import { EditOutlined } from "@material-ui/icons";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import "react-quill/dist/quill.snow.css";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import ZoomInOutlined from "@material-ui/icons/ZoomInOutlined";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import CreateIcon from "@material-ui/icons/AddCircle";
import "../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";
import CodingFormatDetailMethod from "./CodingFormatDetailMethod";
import { Table, Form, Input, Select, Divider, Badge, Tooltip, Button as AntButton, Modal as AntModal, Col, Row, TreeSelect } from "antd";
import { excelExport } from "../../ExcelHelper";

const { Option } = Select;
const { TextArea } = Input;

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class CodingFormat extends Component {
  constructor() {
    super();
    this.state = {
      formName: "CodingFormat",
      controllerName: "codingFormat",
      modalDeleteShow: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      exportClick: false,
      exportList: [],
      lastUpdateDate: [],
      id: null,
      selectedRow: null,
      tableList: [],
      filePath: "",
      fileList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      textFormattingDetailId: null,
      excelData: [],
      searchCriteriaModels: [],
      nodes: [],
      inputTypeSelectItems: null,
      layoutSelectItems: null,
      methodSelectItems: null,
      details: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    fillFilterOperations();
  };

  fillComboboxes = async () => {
    this.fillCode("CodingFormatComponentId");
    var responselayoutSelect = await handleRequest("GET", "/api/" + this.state.controllerName + "/CodingLayoutModes");
    if (responselayoutSelect.type === "ERROR") {
      error(responselayoutSelect);
    }
    var responseMethods = await handleRequest("GET", "/api/" + this.state.controllerName + "/methods");
    if (responseMethods.type === "ERROR") {
      error(responseMethods);
    }
    var responseInputTyp = await handleRequest("GET", "/api/" + this.state.controllerName + "/getInputTypes");
    if (responseInputTyp.type === "ERROR") {
      error(responseInputTyp);
    }
    this.setState({
      inputTypeSelectItems: Boolean(responseInputTyp.data) ? responseInputTyp.data : [],
      layoutSelectItems: Boolean(responselayoutSelect.data) ? responselayoutSelect.data : [],
      methodSelectItems: Boolean(responseMethods.data) ? responseMethods.data : []
    });
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showInputModal = row => {
    this.setState({
      modalInputShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  save = async values => {
    const newItem = { ...values, id: this.state.id };
    console.log("RRR", this.state.details);

    let details = [];
    if (Boolean(this.state.details) && this.state.details.length > 0) {
      this.state.details.forEach(element => {
        if (Boolean(element.details) && element.details.length > 0) {
          element.details.forEach(field => {
            let layouts = [];
            if (Boolean(element.layouts) && element.layouts.length > 0) {
              element.layouts.forEach(f => {
                if (Boolean(f)) {
                  layouts.push({ layout: f });
                }
              });
            }
            details.push({
              codes: field.codes,
              methodId: field.methodId,
              layouts: layouts,
              inputType: field.inputType,
              remarks: field.remarks,
              templateLocation: field.templateLocation
            });
          });
        }
      });
    }
    newItem.details = details;

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.fillComboboxes();
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  edit = row => {
    console.log("RRR", row);
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    var details = [];
    if (Boolean(row.details) && row.details) {
      row.details.forEach(detail => {
        var layouts = [];
        if (Boolean(detail.layouts) && detail.layouts.length > 0) {
          detail.layouts.forEach(element => {
            if (element.layout) {
              layouts.push(element.layout);
            }
          });
        }
        detail.layouts = layouts;
        details.push({
          details: [detail],
          methodId: detail.methodId,
          codes: detail.codes,
          layouts: layouts,
          inputType: detail.inputType,
          remarks: detail.remarks,
          templateLocation: detail.templateLocation
        });
      });
    }

    let allCodes = this.getCodes(details);

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      selectedRow: row,
      details: details,
      allCodes: allCodes
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.fillComboboxes();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data && response.data.data) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      selectedRow: null,
      allCodes: "",
      details: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  addToFormulaList = index => {
    const { details } = this.state;
    let field = details[index];

    if (Boolean(field.codes)) {
      let object = {
        methodId: field.methodId,
        codes: field.codes,
        layouts: field.layouts,
        inputType: field.inputType,
        remarks: field.remarks,
        templateLocation: field.templateLocation
      };
      if (!Boolean(field.details)) {
        field.details = [];
      }
      object.indexOrder = Boolean(field.details) ? field.details.length + 1 : 0;
      field.details.push(object);

      let allCodes = this.getCodes(details);
      this.setState({ details, allCodes: allCodes });
    } else {
      showError("Code is required");
    }
  };

  removeFromFormulaList = index => {
    const { details } = this.state;
    let field = details[index];
    if (field.details && field.details.length > 0) {
      field.details.pop();
    }
    let allCodes = this.getCodes(details);
    this.setState({ details, allCodes: allCodes });
  };

  addFeature = () => {
    const { details } = this.state;
    let key = 0;
    if (Boolean(details) && details.length > 0) {
      key = details[details.length - 1].key + 1;
    }
    if (key === 0) {
      details.push({ key: key });
    } else details.push({ key: key });

    this.setState({ details });
  };

  removeFeature = index => {
    const { details } = this.state;
    details.splice(index, 1);

    let allCodes = this.getCodes(details);
    this.setState({ details, allCodes: allCodes });
  };

  getCodes(details) {
    let allCodes = "";
    if (Boolean(details) && details) {
      details.forEach(element => {
        if (Boolean(element.details) && element.details) {
          element.details.forEach(code => {
            if (Boolean(code.codes)) {
              allCodes += code.codes + " ";
            }
          });
          allCodes += "\r\n\r\n";
        }
      });
    }
    return allCodes;
  }

  modalMethodClose() {
    this.setState({ modalMethodShow: false });
    this.fillComboboxes();
  }

  handleExportExcel = async () => {
    const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/export");
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(response.data) && response.data.length !== 0) {
        response.data.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          exportList: response.data,
          exportClick: true
        });
      } else {
        this.setState({
          exportList: [],
          exportClick: true
        });
      }
    }
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            componentName: element["Component"],
            layoutName: element["Layout"],
            methodName: element["Method"],
            templateLocation: Boolean(element["Template Location"]) ? element["Template Location"] : null,
            inputType: Boolean(element["Input Type"]) ? element["Input Type"] : null,
            codes: element["Code"],
            remarks: element["Remarks"]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 8 }
    };

    const layoutFormula = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      style: {
        marginBottom: 0
      }
    };

    const columnsExcel = [
      { header: "S/N", key: "index" },
      { header: "Component", key: "componentName" },
      { header: "Layout", key: "layoutName" },
      { header: "Method", key: "methodName" },
      { header: "Template Location", key: "templateLocation", value: col => (Boolean(col.templateLocation) ? col.templateLocation : "") },
      { header: "Input Type", key: "inputType", value: col => (Boolean(col.inputType) ? col.inputType : "") },
      { header: "Code", key: "codes" },
      { header: "Remarks", key: "remarks" }
    ];

    const treeData = [
      {
        value: "Backend",
        title: "Backend",
        children: [
          {
            value: "Backend_Domain",
            title: <b style={{ color: "#08c" }}>Domain</b>
          },
          {
            value: "Backend_Service",
            title: <b style={{ color: "#08c" }}>Service</b>
          },
          {
            value: "Backend_Rest",
            title: <b style={{ color: "#08c" }}>Rest</b>
          },
          {
            value: "Backend_Model",
            title: <b style={{ color: "#08c" }}>Model</b>,
            children: [
              {
                value: "Backend_Model_Enums",
                title: <b style={{ color: "#08c" }}>Enums</b>
              }
            ]
          }
        ]
      },
      {
        value: "Frontend",
        title: "Frontend",
        children: [
          {
            value: "Frontend_Import",
            title: <b style={{ color: "#08c" }}>Import</b>
          },
          {
            value: "Frontend_Cons",
            title: <b style={{ color: "#08c" }}>Cons</b>
          },
          {
            value: "Frontend_Var",
            title: <b style={{ color: "#08c" }}>Var</b>
          },
          {
            value: "Frontend_Chart",
            title: <b style={{ color: "#08c" }}>Chart</b>
          },
          {
            value: "Frontend_Export",
            title: <b style={{ color: "#08c" }}>Export</b>,
            children: [
              {
                value: "Frontend_Export_Constructor",
                title: <b style={{ color: "#08c" }}>Constructor</b>,
                children: [
                  {
                    value: "Frontend_Export_Constructor_ThisState",
                    title: <b style={{ color: "#08c" }}>This.State</b>
                  }
                ]
              },
              {
                value: "Frontend_Export_Methods",
                title: <b style={{ color: "#08c" }}>Methods</b>
              },
              {
                value: "Frontend_Export_Render",
                title: <b style={{ color: "#08c" }}>Render</b>,
                children: [
                  {
                    value: "Frontend_Export_Render_ExcelExport",
                    title: <b style={{ color: "#08c" }}>Excel Export</b>
                  },
                  {
                    value: "Frontend_Export_Render_Methods",
                    title: <b style={{ color: "#08c" }}>Methods</b>
                  },
                  {
                    value: "Frontend_Export_Render_FormItem",
                    title: <b style={{ color: "#08c" }}>Form.Item</b>
                  },
                  {
                    value: "Frontend_Export_Render_Return",
                    title: <b style={{ color: "#08c" }}>Return</b>,
                    children: [
                      {
                        value: "Frontend_Export_Render_Return_Table",
                        title: <b style={{ color: "#08c" }}>Table</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_Table_Filter",
                        title: <b style={{ color: "#08c" }}>Table Filter</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_Table_Parent_Child",
                        title: <b style={{ color: "#08c" }}>Table Parent Child</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_ExportButton",
                        title: <b style={{ color: "#08c" }}>Export Button</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_ImportButton",
                        title: <b style={{ color: "#08c" }}>Import Button</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_SettingsButton",
                        title: <b style={{ color: "#08c" }}>Settings Button</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_PdfButton",
                        title: <b style={{ color: "#08c" }}>Pdf Button</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_CreateButton",
                        title: <b style={{ color: "#08c" }}>Create Button</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_Drawer_Button",
                        title: <b style={{ color: "#08c" }}>Drawer Button</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_Drawer_Buttons",
                        title: <b style={{ color: "#08c" }}>Drawer Buttons</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_Save_Button",
                        title: <b style={{ color: "#08c" }}>Save Button</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_Update_Button",
                        title: <b style={{ color: "#08c" }}>Update Button</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_Modal",
                        title: <b style={{ color: "#08c" }}>Modal</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_Drawer_Settings",
                        title: <b style={{ color: "#08c" }}>Drawer Settings</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_Modal_Button",
                        title: <b style={{ color: "#08c" }}>Modal Button</b>
                      },
                      {
                        value: "Frontend_Export_Render_Return_Modal_Opening_Draggable",
                        title: <b style={{ color: "#08c" }}>Modal Opening Draggable</b>
                      }
                    ]
                  },
                  {
                    value: "Frontend_Export_Render_FormItem",
                    title: <b style={{ color: "#08c" }}>Form.Item</b>
                  }
                ]
              }
            ]
          }
        ]
      }
    ];

    const columnsDetail = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="CodingFormat.layout" defaultMessage="Layout" />,
        key: "id",
        render: record => {
          return record.layoutName;
        }
      },
      {
        title: <FormattedMessage id="CodingFormat.methodId" defaultMessage="Method" />,
        key: "methodId",
        render: record => {
          return record.methodName;
        },
        ...getColumnFilter({
          inputName: "methodId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["CodingFormatMethodIdSelectItems"]) ? this.state["CodingFormatMethodIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="CodingFormat.inputType" defaultMessage="Input Type" />,
        key: "inputType",
        render: record => {
          return record.inputType;
        },
        ...getColumnFilter({
          inputName: "inputType",
          inputType: "SELECT",
          selectItems: Boolean(this.state["inputTypeSelectItems"]) ? this.state["inputTypeSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="CodingFormat.templateLocation" defaultMessage="Template Location" />,
        key: "templateLocation",
        render: record => {
          return record.templateLocationName;
        },
        ...getColumnFilter({
          inputName: "templateLocation",
          inputType: "SELECT",
          selectItems: treeData,
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="CodingFormat.code" defaultMessage="Code" />,
        key: "codes",
        ellipsis: true,
        render: record => {
          return record.codes;
        },
        ...getColumnFilter({
          inputName: "codes",
          inputType: "TEXT",
          selectItems: Boolean(this.state["CodingFormatCodeIdSelectItems"]) ? this.state["CodingFormatCodeIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="CodingFormat.remarks" defaultMessage="Remarks" />,
        key: "remarks",
        render: record => {
          return record.remarks;
        },
        ...getColumnFilter({
          inputName: "remarks",
          inputType: "TEXT",
          selectItems: Boolean(this.state["CodingFormatRemarksIdSelectItems"]) ? this.state["CodingFormatRemarksIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: "Last Update",
        key: "lastUpdated",
        ellipsis: true,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        ...getColumnFilter({
          inputName: "lastUpdated",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      }
    ];

    const columns = [
      {
        key: "key",
        width: 100,
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="CodingFormat.componentId" defaultMessage="Component" />,
        key: "componentId",
        render: record => {
          return record.componentName;
        },
        ...getColumnFilter({
          inputName: "componentId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["CodingFormatComponentIdSelectItems"]) ? this.state["CodingFormatComponentIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: "Last Update",
        key: "lastUpdated",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        ...getColumnFilter({
          inputName: "lastUpdated",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];

      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: handleResize(index)
            })
          }))
        : Boolean(columns) &&
          columns.length > 0 &&
          columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: handleResize(index)
            })
          }));

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="CodingFormat.CodingFormatPage" defaultMessage="Coding Format" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "3rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={20}></Col>
              <Col md={1}>
                <Tooltip title="Methods">
                  <AntButton
                    style={{ border: "0px", color: "#1BC5BD" }}
                    onClick={() => {
                      this.setState({ modalMethodShow: true });
                    }}
                    icon={<ZoomInOutlined fontSize="large" />}
                  ></AntButton>
                </Tooltip>
              </Col>
              <Col md={1}>
                <Tooltip title="Export">
                  {
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={async () => {
                        await this.handleExportExcel();
                        excelExport(columnsExcel, this.state.exportList);
                      }}
                      icon={<ExportIcon color="action" fontSize="large" />}
                    />
                  }
                </Tooltip>
              </Col>
              <Col md={1}>
                <Tooltip title="Upload">
                  <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
                </Tooltip>
              </Col>
              <Col md={1}>
                <Tooltip title="Create">
                  <AntButton
                    style={{ border: "0px" }}
                    hidden={!this.state.hideInputs}
                    onClick={this.onClickCreateNew}
                    icon={<CreateIcon color="secondary" fontSize="large" />}
                  ></AntButton>
                  <AntButton
                    style={{ border: "0px" }}
                    hidden={this.state.hideInputs}
                    onClick={this.onClickCreateNew}
                    icon={<RemoveCircle color="error" fontSize="large" />}
                  ></AntButton>
                </Tooltip>
              </Col>
            </Row>

            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CodingFormat.componentId" defaultMessage="Component" />}
                  name="componentId"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("CodingFormatComponentId")}
                          >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["CodingFormatComponentIdSelectItems"]) &&
                      this.state["CodingFormatComponentIdSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }

              <Form.Item {...layoutFormula} label={<FormattedMessage id="CodingFormat.Codes" defaultMessage="Codes" />}>
                <>
                  <br />
                  <TableAnt borderless size="sm">
                    <thead>
                      {Boolean(this.state.details) && this.state.details.length > 0 && (
                        <tr>
                          <th>
                            <FormattedMessage id={"CodingFormat.Layout"} defaultMessage={"Layout"} />
                          </th>
                          <th>
                            <FormattedMessage id={"CodingFormat.Method"} defaultMessage={"Method"} />
                          </th>
                          <th>
                            <FormattedMessage id={"CodingFormat.InputType"} defaultMessage={"Input Type"} />
                          </th>
                          <th>
                            <FormattedMessage id={"CodingFormat.TemplateLocation"} defaultMessage={"Template Location"} />
                          </th>
                          <th>
                            <FormattedMessage id={"CodingFormat.Remarks"} defaultMessage={"Remarks"} />
                          </th>
                          <th>
                            <FormattedMessage id={"CodingFormat.Text"} defaultMessage={"Code"} />
                          </th>
                          <th>
                            <FormattedMessage id="GENERAL.Remove" defaultMessage="Remove" />
                          </th>
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {Boolean(this.state.details) &&
                        this.state.details.map((field, index) => {
                          return (
                            <tr>
                              <td>
                                <Select
                                  allowClear
                                  showSearch
                                  style={{ width: "100px" }}
                                  placeholder="Layout"
                                  value={field.layouts}
                                  mode="multiple"
                                  onChange={value => {
                                    const { details } = this.state;
                                    details[index].layouts = value;
                                    this.setState({ details });
                                  }}
                                >
                                  {Boolean(this.state["layoutSelectItems"]) &&
                                    this.state["layoutSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.key}
                                      </Option>
                                    ))}
                                </Select>
                              </td>
                              <td>
                                <Select
                                  allowClear
                                  showSearch
                                  style={{ width: "100px" }}
                                  placeholder="Method"
                                  value={field.methodId}
                                  onChange={value => {
                                    const { details } = this.state;
                                    details[index].methodId = value;
                                    this.setState({ details });
                                  }}
                                  dropdownRender={menu => (
                                    <div>
                                      {menu}
                                      <Divider style={{ margin: "4px 0" }} />
                                      <div
                                        style={{
                                          display: "flex",
                                          flexWrap: "nowrap",
                                          padding: 8
                                        }}
                                      >
                                        <AntButton
                                          type="link"
                                          icon={<PlusOutlined />}
                                          onClick={() => {
                                            this.setState({ modalMethodShow: true });
                                          }}
                                        >
                                          <FormattedMessage id={"CodingFormat.Methods"} defaultMessage={"Methods"} />
                                        </AntButton>
                                      </div>
                                    </div>
                                  )}
                                >
                                  {Boolean(this.state["methodSelectItems"]) &&
                                    this.state["methodSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </td>
                              <td>
                                <Select
                                  allowClear
                                  showSearch
                                  style={{ width: "100px" }}
                                  placeholder="Input Type"
                                  value={field.inputType}
                                  onChange={value => {
                                    const { details } = this.state;
                                    details[index].inputType = value;
                                    this.setState({ details });
                                  }}
                                >
                                  {Boolean(this.state["inputTypeSelectItems"]) &&
                                    this.state["inputTypeSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.key}
                                      </Option>
                                    ))}
                                </Select>
                              </td>
                              <td>
                                <TreeSelect
                                  allowClear
                                  showSearch
                                  style={{ width: "200px" }}
                                  value={field.templateLocation}
                                  onChange={value => {
                                    const { details } = this.state;
                                    details[index].templateLocation = value;
                                    this.setState({ details });
                                  }}
                                  treeData={treeData}
                                />
                              </td>
                              <td>
                                <Input
                                  style={{ width: "100px" }}
                                  value={field.remarks}
                                  onChange={e => {
                                    const { details } = this.state;
                                    details[index].remarks = e.target.value;
                                    this.setState({ details });
                                  }}
                                ></Input>
                              </td>
                              <td>
                                <Input
                                  style={{ width: "100px" }}
                                  value={field.codes}
                                  onChange={e => {
                                    const { details } = this.state;
                                    details[index].codes = e.target.value;
                                    this.setState({ details });
                                  }}
                                ></Input>
                              </td>
                              <td>
                                <ArrowRightOutlined onClick={() => this.addToFormulaList(index)} />
                              </td>
                              <td>
                                <UndoOutlined onClick={() => this.removeFromFormulaList(index)} />
                              </td>
                              <td>
                                <MinusCircleOutlined onClick={() => this.removeFeature(index)} />
                              </td>
                            </tr>
                          );
                        })}
                      <tr>
                        <td colSpan={8} className="justify-content-end">
                          <AntButton type="dashed" onClick={() => this.addFeature()} block icon={<PlusOutlined />}>
                            <FormattedMessage id={"CodingFormat.AddNewLine"} defaultMessage={"Add New Line"} />
                          </AntButton>
                        </td>
                      </tr>
                    </tbody>
                  </TableAnt>
                </>
              </Form.Item>
              <br />
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={4} lg={4} xl={4} xxl={4}></Col>
                <Col xs={24} sm={24} md={20} lg={20} xl={20} xxl={20}>
                  <TextArea style={{ width: "100%" }} rows={10} value={Boolean(this.state.allCodes) ? this.state.allCodes : ""} readOnly={true} />
                </Col>
              </Row>
              <br />
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
              <div className="alert alert-custom alert-light">
                <div className="alert-icon">
                  <FilterOutlined />
                </div>
                <div className="alert-text">
                  {this.state.searchCriteriaModels.map((val, i) => {
                    return (
                      <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                        <span className="mr-2">{val.label}</span>
                        <strong className="mr-2">{val.type}</strong>
                        <span className="mr-2">{val.operationValue}</span>
                        <span className="mr-2">{val.showValue}</span>
                        <span>
                          <Tooltip title="Remove">
                            <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                          </Tooltip>
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="alert-close">
                  <Tooltip title="Remove All">
                    <CloseCircleOutlined
                      style={{ color: "crimson" }}
                      onClick={() =>
                        this.setState({ searchCriteriaModels: [] }, () => {
                          this.filterChangeItem([]);
                        })
                      }
                    />
                  </Tooltip>
                </div>
              </div>
            )}

            <Table
              rowKey="id"
              bordered
              size="small"
              components={{
                header: {
                  cell: ResizableTitle
                }
              }}
              columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
              expandable={{
                expandedRowRender: record =>
                  Boolean(record) &&
                  Boolean(record.details) &&
                  record.details.length > 0 && (
                    <Table
                      rowKey="value"
                      bordered
                      size="small"
                      columns={columnsDetail}
                      scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                      dataSource={record.details}
                      pagination={false}
                    />
                  ),
                rowExpandable: record => (Boolean(record.details) && record.details.length > 0 ? true : false)
              }}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <AntModal
            closable="false"
            open={this.state.modalMethodShow}
            onCancel={() => {
              this.setState({ modalMethodShow: false });
              this.fillComboboxes();
            }}
            width={1400}
            centered
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    modalMethodShow: false
                  });
                  this.fillComboboxes();
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
          >
            <CodingFormatDetailMethod random={Math.random()} />
          </AntModal>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CodingFormat);
