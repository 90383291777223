import moment from "moment";
import React, { useEffect } from "react";
import { handleRequest } from "../../ApiConnector";
import { showError } from "../../MessageHelper";
import {Pie, measureTextWidth} from "@ant-design/plots";
import {DatePicker} from "antd";

const { RangePicker } = DatePicker;

function PieChart(props) {
  const [loading, setLoading] = React.useState(false);
  const [loadUrl, setLoadUrl] = React.useState(null);
  const [showHeader, setShowHeader] = React.useState(false);
  let [chartConfig, setChartConfig] = React.useState(null);
  const fillData = async (loadUrl, sdate, edate) => {
    let obj = {
      startDate: Boolean(sdate) ? moment(sdate).toDate() : moment()
        .subtract(1, "M")
        .toDate(),
      endDate: Boolean(edate) ? moment(edate).toDate() : moment().toDate()
    };
    const response = await handleRequest("POST", loadUrl, obj);
    if (response.type === "ERROR") {
      showError(response);
      setLoading(false);
    } else {
      setChartConfig(prev => ({
        ...prev,
        appendPadding: 10,
        data: Boolean(response.data) ? response.data.pieChartProduct : [],
        angleField: "quantity",
        colorField: "product",
        radius: 1,
        innerRadius: 0.6,
        label: {
          type: "inner",
          offset: "-50%",
          content: "{value}",
          style: {
            textAlign: "center",
            fontSize: 14
          }
        },
        interactions: [
          {
            type: 'element-selected',
          },
          {
            type: 'element-active',
          },
          {
            type: 'pie-statistic-active',
          },
        ],
        statistic: {
          title: false,
          content: {
            offsetY: 4,
            style: {
              fontSize: '32px',
            },
            customHtml: (container, view, datum, data) => {

              const { width } = container.getBoundingClientRect();
              const text = datum ? `${datum.quantity}` : `${data.reduce((r, d) => r + d.quantity, 0)}`;
              return renderStatistic(width, text, {
                fontSize: 32,
              });
            },
          }
        }
      }));
    }
  };

  const renderStatistic = (containerWidth, text, style) => {
    const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
  }


  useEffect(() => {
    // setLoadUrl(props.apiUrl);
    // setShowHeader(props.detailHeader)
    const getData = async () => {
      await fillData(props.apiUrl);
    };
    getData().catch(console.error);
  }, []);
  return (
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-header align-items-center border-0 mt-4">
            <h3 className="card-title align-items-start flex-column">
              <span className="font-weight-bolder text-dark">{props.title}</span>
              <span className="text-muted mt-3 font-weight-bold font-size-sm">
                <RangePicker
                    value={Boolean(chartConfig) ? chartConfig.data.lastUpdateDate : null}
                    format="DD-MM-YYYY"
                    onChange={async (dates, dateStrings) => {
                      console.log(dates, dateStrings);
                      const getData = async () => {
                        await fillData(props.apiUrl, dates[0], dates[1]);
                      };
                      getData().catch(console.error);
                    }}
                />
            </span>
            </h3>
          </div>
          <div className="card-body pt-4">
            <div style={{ width: "100%" }}>{Boolean(chartConfig) && <Pie {...chartConfig} />}</div>
          </div>
        </div>
      </div>
  );
}

export default PieChart;
