import { FilterOutlined } from "@ant-design/icons";
import { DatePicker, Input, Radio, Select, Table, Tag } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { handleRequest } from "../ApiConnector";
import { error } from "../MessageHelper";
import { DownloadIconsFromPaths } from "./ElementUtils";
import ImageGalleryFromUrls from "./helpers/ImageGalleryFromUrls";
const { Option } = Select;
const { RangePicker } = DatePicker;
let timerId;
const debounceFunction = (func, delay) => {
  // Cancels the setTimeout method execution
  clearTimeout(timerId);
  // Executes the func after delay time.
  timerId = setTimeout(func, delay);
};
export class DataTableComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      field: props.field,
      actions: props.actions,
      inputs: props.inputs,
      createWhereParams: props.createWhereParams,
      restartTable: props.restartTable,
      list: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    };
  }
  columnComponents = {
    Tag: Tag
  };
  componentDidMount() {
    //this.fillComboboxes();
    const { pagination, inputs } = this.state;
    inputs.forEach(input => {
      if (Boolean(input.loadUrl)) {
        this.fillFilterSelectsList(input);
      }
    });
    this.restartTable({ pagination });
  }
  componentDidUpdate(prevProps, prevState) {
    let res = Boolean(this.props) ? this.props.restartTable : null;
    if (res !== this.state.restartTable) {
      this.setState({ restartTable: res });
      const { pagination } = this.state;
      this.restartTable({ pagination });
    }
  }
  restartTable = async (params = {}) => {
    const { field } = this.state;
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = field.loadUrl; //"/api/countries/selectItems";
    var response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({ loading: false, list: [], pagination: { ...params.pagination, total: 0 }, sortField: "", sortOrder: "" });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        this.setState({
          list: list,
          pagination: { ...params.pagination, total: response.data.totalRecords },
          loading: false,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          list: [],
          pagination: { ...params.pagination, total: 0 },
          loading: false,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere = params => {
    const { pagination } = this.state;
    if (Boolean(params._pagination)) params.pagination = params._pagination;
    var current = pagination.current;
    var pageSize = pagination.pageSize;
    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";

    if (Boolean(params) && Boolean(params.pagination)) {
      current = params.pagination.current;
      pageSize = params.pagination.pageSize;
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }

      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    let otherParams = {};
    if (Boolean(this.state.createWhereParams)) {
      this.state.createWhereParams.forEach(param => {
        otherParams[param.key] = param.value;
      });
    }
    const newObj = {
      ...params.pagination,
      ...otherParams,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: current - 1, // (current - 1) * pageSize,
      maxResults: pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };
  getColumFilterInput = column => {
    return Boolean(this.ColumnFilters[column.inputType]) ? this.ColumnFilters[column.inputType](column) : null;
  };
  getColumnFilter = column => {
    const { pagination } = this.state;
    let c = {
      filterDropdown: () => <div style={{ padding: 8 }}>{this.getColumFilterInput(column)}</div>,
      filterIcon: filtered => (
        <FilterOutlined style={{ color: Boolean(pagination[column.inputName + "Color"]) ? pagination[column.inputName + "Color"] : "#bfbfbf" }} />
      ),

      sorter: true
    };
    return c;
  };
  fillFilterSelectsList = async column => {
    let url = column.loadUrl; //"/api/countries/selectItems";
    var response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        [column.inputName + "SelectItems"]: Boolean(response.data) ? response.data : []
      });
    }
  };
  ColumnFilterInput = column => {
    return (
      <Input
        style={{ flex: "auto" }}
        // value={Boolean(pagination[column.inputName]) ? pagination[column.inputName] : null}
        onChange={async e => {
          const { pagination, sortField, sortOrder } = this.state;
          let _pagination = {
            ...pagination,
            current: 1,
            [column.inputName]: e.target.value,
            [column.inputName + "color"]: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
          };
          this.setState({ pagination: _pagination });
          debounceFunction(() => {
            this.restartTable({ _pagination, sortField: sortField, sortOrder: sortOrder });
          }, 500);
        }}
      />
    );
  };
  ColumnFilterRadio = column => {
    return (
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        onChange={async e => {
          const { pagination, sortField, sortOrder } = this.state;
          let _pagination = {
            ...pagination,
            current: 1,
            [column.inputName]: e.target.value,
            [column.inputName + "Color"]: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
          };
          this.setState({ pagination: _pagination });
          debounceFunction(() => {
            this.restartTable({ _pagination, sortField: sortField, sortOrder: sortOrder });
          }, 500);
        }}
      >
        <Radio.Button>All</Radio.Button>
        {Boolean(this.state[column.inputName + "SelectItems"])
          ? this.state[column.inputName + "SelectItems"].map(i => (
              <Radio.Button value={Boolean(column.propKey) ? i[column.propKey] : i.key} key={Boolean(column.propKey) ? i[column.propKey] : i.key}>
                {Boolean(column.propValue) ? i[column.propValue] : i.value}
              </Radio.Button>
            ))
          : null}
      </Radio.Group>
    );
  };
  ColumnFilterCheckbox = column => {
    return (
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        onChange={async e => {
          const { pagination, sortField, sortOrder } = this.state;
          let _pagination = {
            ...pagination,
            current: 1,
            [column.inputName]: e.target.value,
            [column.inputName + "Color"]: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
          };
          this.setState({ pagination: _pagination });
          debounceFunction(() => {
            this.restartTable({ _pagination, sortField: sortField, sortOrder: sortOrder });
          }, 500);
        }}
      >
        <Radio.Button>All</Radio.Button>
        <Radio.Button value={1}>{column.label}</Radio.Button>
      </Radio.Group>
    );
  };
  ColumnFilterSelect = column => {
    return (
      <Select
        showSearch
        allowClear
        optionFilterProp="children"
        placeholder={column.label}
        style={{ width: "100%" }}
        onChange={async value => {
          const { pagination, sortField, sortOrder } = this.state;
          let _pagination = {
            ...pagination,
            current: 1,
            [column.inputName]: value,
            [column.inputName + "Color"]: Boolean(value) ? "#1890ff" : "#bfbfbf"
          };
          this.setState({ pagination: _pagination });
          debounceFunction(() => {
            this.restartTable({ _pagination, sortField: sortField, sortOrder: sortOrder });
          }, 500);
        }}
      >
        {Boolean(this.state[column.inputName + "SelectItems"])
          ? this.state[column.inputName + "SelectItems"].map(i => (
              <Option value={Boolean(column.propKey) ? i[column.propKey] : i.id} key={Boolean(column.propKey) ? i[column.propKey] : i.id}>
                {Boolean(column.propValue) ? i[column.propValue] : i.name}
              </Option>
            ))
          : null}
      </Select>
    );
  };
  ColumnFilterDate = column => {
    return (
      <DatePicker
        format="DD-MM-YYYY"
        style={{ width: "100%" }}
        onChange={value => {
          const { pagination, sortField, sortOrder } = this.state;
          let _pagination = {
            ...pagination,
            current: 1,
            [column.inputName]: value,
            [column.inputName + "Color"]: Boolean(value) ? "#1890ff" : "#bfbfbf"
          };
          this.setState({ pagination: _pagination });
          debounceFunction(() => {
            this.restartTable({ _pagination, sortField: sortField, sortOrder: sortOrder });
          }, 500);
        }}
      />
    );
  };
  ColumnFilters = {
    TEXT: this.ColumnFilterInput,
    TEXTAREA: this.ColumnFilterInput,
    RADIO: this.ColumnFilterRadio,
    CHECKBOX: this.ColumnFilterCheckbox,
    SELECT: this.ColumnFilterSelect,
    SELECT_ADDABLE: this.ColumnFilterSelect,
    SELECT_MULTIPLE: this.ColumnFilterSelect,
    SELECT_MULTIPLE_ADDABLE: this.ColumnFilterSelect,
    DATEPICKER: this.ColumnFilterDate,
    DATEPICKER_WITH_TIME: this.ColumnFilterDate
  };
  ColumnRenderers = {
    FILEUPLOAD_IMAGE: (input, row) => {
      return <ImageGalleryFromUrls paths={row[input.inputName]} />;
    },
    FILEUPLOAD: (input, row) => {
      return DownloadIconsFromPaths(row[input.inputName]);
    },
    DATEPICKER: (input, row) => {
      return Boolean(row[input.inputName]) ? moment(row[input.inputName]).format("DD-MM-YYYY") : "";
    },
    DATEPICKER_WITH_TIME: (input, row) => {
      return Boolean(row[input.inputName]) ? moment(row[input.inputName]).format("DD-MM-YYYY HH:mm:ss") : "";
    },
    CHECKBOX: (input, row) => {
      let elem = Boolean(this.columnComponents[input.columnElement]) ? this.columnComponents[input.columnElement] : "span";
      return Boolean(row[input.inputName])
        ? React.createElement(elem, { color: Boolean(input.trueColor) ? input.trueColor : "green" }, "Yes")
        : React.createElement(elem, { color: Boolean(input.falseColor) ? input.falseColor : "red" }, "No");
    }
  };
  render() {
    const { field, actions, pagination, inputs } = this.state;
    var cols = [
      {
        title: "Actions",
        key: "Actions",
        render: actions
      },
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + pagination.pageSize * (pagination.current - 1);
        }
      }
    ];
    inputs.map((val, i) => {
      let c = {
        title: (
          <>
            <FormattedMessage id={val.labelLanguageKey} defaultMessage={val.label} />
          </>
        ),
        dataIndex: val.inputName,
        key: val.inputName
      };
      if (Boolean(val.tableColumnValue)) {
        c = {
          ...c,
          render: (value, row, index) => {
            let v = val.tableColumnValue.split(".");
            let columnValue = row[v[0]];
            for (let i = 1; i < v.length; i++) {
              columnValue = Boolean(columnValue[v[i]]) ? columnValue[v[i]] : null;
            }
            return columnValue;
          },
          ...this.getColumnFilter(val)
        };
      } else {
        c = {
          ...c,
          render: (value, row, index) => {
            return Boolean(this.ColumnRenderers[val.inputType]) ? this.ColumnRenderers[val.inputType](val, row) : value;
          },
          ...this.getColumnFilter(val)
        };
      }
      cols.push(c);
      return cols;
    });
    if (field.showLastUpdateDate !== false) {
      cols.push({
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={async (dates, dateStrings) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  lastUpdateDate: dates,
                  lastUpdatedColor: Boolean(dates) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.lastUpdatedColor) ? this.state.lastUpdatedColor : "#bfbfbf" }} />,
        sorter: true
      });
    }
    return (
      <>
        <Table
          rowKey={record => record.id}
          bordered
          scroll={{ x: 900, scrollToFirstRowOnChange: true }}
          columns={cols}
          dataSource={this.state.list}
          loading={this.state.loading}
          onChange={this.handleTableChange}
          pagination={this.state.pagination}
        />
      </>
    );
  }
}

export default DataTableComponent;
