import { MinusCircleOutlined, FilterOutlined, PlusOutlined } from "@ant-design/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Button as AntButton, Modal as AntModal, Col, DatePicker, Form, Input, Row, Select, Table, InputNumber } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess, showWarningBasic } from "../../MessageHelper";
import ActionMenu from "../CustomMenu/ActionMenu";
import { ExcelReader } from "../excel/ExcelReader";
import ProductList from "./ProductList";
import DraggableModal from "../../Components/DraggableModal";

const { RangePicker } = DatePicker;
const { Option } = Select;

export class DiscountPolicy extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      userIdSelectItems: null,
      vendorIdSelectItems: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseuserId = await handleRequest("GET", "/api/discountPolicy/users");
    if (responseuserId.type === "ERROR") {
      error(responseuserId);
    } else {
      this.setState({
        userIdSelectItems: Boolean(responseuserId.data) ? responseuserId.data : []
      });
    }
    var responseVendor = await handleRequest("GET", "/api/discountPolicy/clients");
    if (responseVendor.type === "ERROR") {
      error(responseVendor);
    } else {
      this.setState({
        vendorIdSelectItems: Boolean(responseVendor.data) ? responseVendor.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    let vendors = [];
    if (row.discountPolicyClientList.length > 0) {
      vendors = row.discountPolicyClientList.map(item => item.vendorId);
    }

    this.formRef.current.setFieldsValue({
      ...row,
      discountPolicyClientList: vendors,
      productList: [{ productId: row.productId, productName: row.productName, listPrice: row.listPrice }]
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/discountPolicy/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/discountPolicy/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = { ...values, id: this.state.id };
    if (newItem.productList.length === 0) {
      showWarningBasic("Please select at least one product");
      return;
    }
    if (newItem.discountPolicyClientList.length > 0) {
      newItem.discountPolicyClientList = this.state.vendorIdSelectItems
        .filter(item => newItem.discountPolicyClientList.includes(item.key))
        .map(item => {
          return { vendorId: item.key, vendorName: item.value };
        });
    } else {
      newItem.discountPolicyClientList = undefined;
    }

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/discountPolicy", newItem);
    } else {
      response = await handleRequest("PUT", "/api/discountPolicy/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  addSelectedProducts = async selectedRows => {
    let data = selectedRows;
    let productList = [];
    data.forEach(element => {
      let obj = {};
      obj.productId = element.id;
      obj.productName = Boolean(element.producDetail) ? element.producDetail.name : element.materialName;
      obj.listPrice = Boolean(element.productDetail) ? element.productDetail.sellingPrice : element.sellingPrice;
      productList.push(obj);
    });
    this.formRef.current.setFieldsValue({
      productList: productList
    });
    this.setState({
      showProductListModal: false
    });
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/discountPolicy/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="DiscountPolicy.userId" defaultMessage="Sales Person" />,
        key: "userId",
        render: record => {
          return record.user ? record.user.fullNameWithEmail : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.userId) ? this.state.pagination.userId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  userId: e.target.value,
                  userIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.userIdColor) ? this.state.pagination.userIdColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="DiscountPolicy.productId" defaultMessage="Product" />,
        key: "productId",
        render: record => {
          return record.productName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.productId) ? this.state.pagination.productId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  productId: e.target.value,
                  productIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.productIdColor) ? this.state.pagination.productIdColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="DiscountPolicy.listPrice" defaultMessage="List Price" />,
        key: "listPrice",
        render: record => {
          return record.listPrice;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.listPrice) ? this.state.pagination.listPrice : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  listPrice: e.target.value,
                  listPriceColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.listPriceColor) ? this.state.pagination.listPriceColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="DiscountPolicy.discountPolicyClientList" defaultMessage="Clients" />,
        key: "discountPolicyClientList",
        render: record => {
          return "record.discountPolicyClientList";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.discountPolicyClientList) ? this.state.pagination.discountPolicyClientList : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  discountPolicyClientList: e.target.value,
                  discountPolicyClientListColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.discountPolicyClientListColor) ? this.state.pagination.discountPolicyClientListColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="DiscountPolicy.discountPolicyPaymentTermsList" defaultMessage="Terms" />,
        key: "discountPolicyPaymentTermsList",
        render: record => {
          return "record.discountPolicyPaymentTermsList";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.discountPolicyPaymentTermsList) ? this.state.pagination.discountPolicyPaymentTermsList : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  discountPolicyPaymentTermsList: e.target.value,
                  discountPolicyPaymentTermsListColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.discountPolicyPaymentTermsListColor)
                ? this.state.pagination.discountPolicyPaymentTermsListColor
                : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },

      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={21}></Col>
            <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            <div className="w-100" style={{ overflow: "auto hidden" }}>
              <table className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th style={{ maxWidth: "330px" }}>
                      <FormattedMessage id="DiscountPolicy.userId" defaultMessage="Sales Person" />
                    </th>
                    <th style={{ minWidth: "330px" }}>
                      <FormattedMessage id="DiscountPolicy.productId" defaultMessage="Product" />
                    </th>
                    <th style={{ minWidth: "200px" }}>
                      <FormattedMessage id="DiscountPolicy.discountPolicyClientList" defaultMessage="Clients" />
                    </th>
                    <th>
                      <FormattedMessage id="DiscountPolicy.discountPolicyPaymentTermsList" defaultMessage="Terms" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Item
                        noStyle
                        name="userId"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select allowClear showSearch style={{ width: "100%" }} placeholder="Sales Person">
                          {Boolean(this.state["userIdSelectItems"]) &&
                            this.state["userIdSelectItems"].map(i => (
                              <Option key={i.id} value={i.id}>
                                {i.fullNameWithEmail}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </td>
                    <td>
                      <AntButton
                        disabled={this.state.id}
                        onClick={() => {
                          this.setState({
                            showProductListModal: true,
                            random: Math.random(),
                          });
                        }}
                      >
                        <FormattedMessage id="DiscountPolicy.selectProducts" defaultMessage="Select Product" />
                      </AntButton>
                      <Form.List name="productList">
                        {(fields, { add, remove }) => {
                          if (fields.length === 0) {
                            return;
                          }
                          return (
                            <>
                              <table className="table-sm table-borderless">
                                <thead>
                                  <tr>
                                    <th>
                                      <FormattedMessage id="DiscountPolicy.productNo" defaultMessage="No" />
                                    </th>
                                    <th>
                                      <FormattedMessage id="DiscountPolicy.productName" defaultMessage="Name" />
                                    </th>
                                    <th>
                                      <FormattedMessage id="DiscountPolicy.listPrice" defaultMessage="List Price" />
                                    </th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map(field => {
                                    return (
                                      <tr key={field.key}>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "productId"]}>
                                            <Input bordered={false} readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "productName"]}>
                                            <Input bordered={false} readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "listPrice"]}>
                                            <Input bordered={false} readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <MinusCircleOutlined disabled={this.state.id} onClick={() => remove(field.name)} />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </>
                          );
                        }}
                      </Form.List>
                    </td>
                    <td>
                      <Form.Item
                        noStyle
                        name="discountPolicyClientList"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select allowClear showSearch mode="multiple" style={{ width: "100%" }} placeholder="Clients">
                          {Boolean(this.state["vendorIdSelectItems"]) &&
                            this.state["vendorIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </td>
                    <td>
                      <Form.List name="discountPolicyPaymentTermsList">
                        {(fields, { add, remove }) => {
                          return (
                            <>
                              <table className="table-sm table-borderless">
                                <thead>
                                  <tr>
                                    <td colSpan={2}>
                                      <AntButton type="dashed" size="small" onClick={() => add()} block icon={<PlusOutlined />}>
                                        <span>Add</span>
                                      </AntButton>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <FormattedMessage id="DiscountPolicy.quantity" defaultMessage="Quantity" />
                                    </th>
                                    <th>
                                      <FormattedMessage id="DiscountPolicy.paymentTerms" defaultMessage="Payment Terms" />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.reverse().map(field => {
                                    return (
                                      <tr key={field.key}>
                                        <td style={{ minWidth: "200px" }}>
                                          <Form.Item noStyle name={[field.name, "minQuantity"]}>
                                            <InputNumber placeholder="Min" />
                                          </Form.Item>
                                          <span>-</span>
                                          <Form.Item noStyle name={[field.name, "maxQuantity"]}>
                                            <InputNumber placeholder="Max" />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.List name={[field.name, "discountPolicyPeriodDiscountModelList"]}>
                                            {(terms, { add, remove }) => {
                                              return (
                                                <>
                                                  <table className="table-sm table-borderless">
                                                    <thead>
                                                      <tr>
                                                        <td colSpan={2}>
                                                          <AntButton type="dashed" size="small" onClick={() => add()} block icon={<PlusOutlined />}>
                                                            <span>Add</span>
                                                          </AntButton>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <th>
                                                          <FormattedMessage id="DiscountPolicy.quantity" defaultMessage="Payment Date" />
                                                        </th>
                                                        <th>
                                                          <FormattedMessage id="DiscountPolicy.productName" defaultMessage="Discount" />
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {terms.reverse().map(term => {
                                                        return (
                                                          <tr key={term.key}>
                                                            <td style={{ minWidth: "200px" }}>
                                                              <Form.Item noStyle name={[term.name, "minPaymentDays"]}>
                                                                <InputNumber placeholder="Min" />
                                                              </Form.Item>
                                                              <span>-</span>
                                                              <Form.Item noStyle name={[term.name, "maxPaymentDays"]}>
                                                                <InputNumber placeholder="Max" />
                                                              </Form.Item>
                                                              <span> days</span>
                                                            </td>
                                                            <td style={{ minWidth: "250px" }}>
                                                              <Form.Item noStyle name={[term.name, "discountRate"]}>
                                                                <InputNumber
                                                                  placeholder="Rate"
                                                                  prefix="%"
                                                                  onChange={val => {
                                                                    let productList = this.formRef.current.getFieldValue("productList");
                                                                    if (productList && productList.length === 1) {
                                                                      let listPrice = productList[0].listPrice;
                                                                      let discountPolicyPaymentTermsList = this.formRef.current.getFieldValue(
                                                                        "discountPolicyPaymentTermsList"
                                                                      );
                                                                      discountPolicyPaymentTermsList[field.key].discountPolicyPeriodDiscountModelList[
                                                                        term.key
                                                                      ].discountAmount = ((listPrice * val) / 100).toFixed(2);
                                                                      this.formRef.current.setFieldsValue({
                                                                        discountPolicyPaymentTermsList: discountPolicyPaymentTermsList
                                                                      });
                                                                    }
                                                                  }}
                                                                />
                                                              </Form.Item>
                                                              <span>-</span>
                                                              <Form.Item
                                                                noStyle
                                                                name={[term.name, "discountAmount"]}
                                                                onChange={val => {
                                                                  let productList = this.formRef.current.getFieldValue("productList");
                                                                  if (productList && productList.length === 1) {
                                                                    let listPrice = productList[0].listPrice;
                                                                    let discountPolicyPaymentTermsList = this.formRef.current.getFieldValue(
                                                                      "discountPolicyPaymentTermsList"
                                                                    );
                                                                    discountPolicyPaymentTermsList[field.key].discountPolicyPeriodDiscountModelList[
                                                                      term.key
                                                                    ].discountRate = ((val / listPrice) * 100).toFixed(2);
                                                                    this.formRef.current.setFieldsValue({
                                                                      discountPolicyPaymentTermsList: discountPolicyPaymentTermsList
                                                                    });
                                                                  }
                                                                }}
                                                              >
                                                                <InputNumber placeholder="Amount" />
                                                              </Form.Item>
                                                            </td>
                                                            <td>
                                                              <MinusCircleOutlined onClick={() => remove(term.name)} />
                                                            </td>
                                                          </tr>
                                                        );
                                                      })}
                                                    </tbody>
                                                  </table>
                                                </>
                                              );
                                            }}
                                          </Form.List>
                                        </td>
                                        <td>
                                          <MinusCircleOutlined disabled={this.state.id} onClick={() => remove(field.name)} />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </>
                          );
                        }}
                      </Form.List>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <DraggableModal
          title="Product List"
          style={{ top: "10%" }}
          closable={true}
          maskClosable={false}
          keyboard={false}
          centered
          visible={this.state.showProductListModal}
          onCancel={() => {
            this.setState({
              showProductListModal: false
            });
          }}
          okButtonProps={{ style: { display: "none" } }}
          width={1000}
          content={
            <ProductList random={this.state.random} addSelectedProducts={this.addSelectedProducts.bind(this)} refreshWhenChange={this.state.refreshProductListWhenChange} />
          }
        />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountPolicy);
