import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Select, InputNumber } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Modal } from "antd";
import { Input } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, SettingTwoTone, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import moment from "moment";

const { Option } = Select;

export class StormLine extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "StormLine",
      controllerName: "stormLine",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      frontCodes: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }


  formRef = React.createRef();
  formRefTotalNumbersOfEndConnectionOfStormLines = React.createRef();
  formRefDescriptionOfEndConnections = React.createRef();
  formRefTotalNumbersOfMainStormPipelines = React.createRef();
  formRefMainPipelineDescriptions = React.createRef();
  formRefTotalEndConnectionOfStormManholes = React.createRef();
  formRefManholeDescriptions = React.createRef();
  formRefManholeAndPipelines = React.createRef();
  formRefHouseConnections = React.createRef();
  formRefManholeCoordinates = React.createRef();
  formRefCatchBasinCoordinates = React.createRef();
  formRefCatchBasinConnections = React.createRef();
  formRefPipeJointCoordinates = React.createRef();

  fillComboboxes = async () => {/**
    fillFilterOperations();
    var responseComponent = await handleRequest("GET", "/api/" + this.state.controllerName + "/component");
    if (responseComponent.type === "ERROR") {
      error(responseComponent);
    }
    this.setState({
      componentSelectItems: Boolean(responseComponent.data) ? responseComponent.data : [],
    });
     */
  };

  fillEndPointNo = async () => {
    this.fillCode("StormLine.EndPoint");
    var responseEndPointId = await handleRequest("GET", "/api/" + this.state.controllerName + "/endPointId");
    if (responseEndPointId.type === "ERROR") {
      error(responseEndPointId);
    }

    this.setState({
      endPointIdSelectItems: Boolean(responseEndPointId.data) ? responseEndPointId.data : [],
    });
  };

  fillEndPointDescription = async () => {
    this.fillCode("StormLine.EndPointDescription");
    var responsedescriptionOfEnd = await handleRequest("GET", "/api/" + this.state.controllerName + "/descriptionOfEnd");
    if (responsedescriptionOfEnd.type === "ERROR") {
      error(responsedescriptionOfEnd);
    }

    this.setState({
      descriptionOfEndSelectItems: Boolean(responsedescriptionOfEnd.data) ? responsedescriptionOfEnd.data : [],
    });
  };

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };



    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);



    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  saveTotalNumbersOfEndConnection = async values => {
    if (Boolean(values) && Boolean(values.totalNoOfEndConnection) && values.totalNoOfEndConnection > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveTotalNumbersOfEndConnection/" + values.totalNoOfEndConnection);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        this.fillEndPointNo();
        this.fillEndPointDescription();
        this.setState({
          TotalNumbersOfEndConnection: false,
          TotalSanitaryPipelines: true
        });
      }
    } else {
      showWarning("Not found.");
    }
  };


  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };


  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };
    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="StormLine.endPoint" defaultMessage="End Point" />,
        key: "name",
        render: record => {
          return record.name;
        }, ...getColumnFilter({
          inputName: "name", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="StormLine.pipeline" defaultMessage="Pipeline" />,
        key: "pipeline",
        render: record => {
          return record.pipeline;
        }, ...getColumnFilter({
          inputName: "pipeline", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="StormLine.manhole" defaultMessage="Manhole" />,
        key: "manhole",
        render: record => {
          return record.manhole;
        }, ...getColumnFilter({
          inputName: "manhole", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="StormLine.houseConnection" defaultMessage="House connection" />,
        key: "houseConnection",
        render: record => {
          if (Boolean(record.houseConnection)) {
            return moment(record.houseConnection).format("DD-MM-YYYY HH:mm");
          }
        }, sorter: true
      },
      {
        title: <FormattedMessage id="StormLine.houseNo" defaultMessage="House No" />,
        key: "houseNo",
        render: record => {
          return record.houseNo;
        }, ...getColumnFilter({
          inputName: "houseNo", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
    ];

    const excelExport = (<>
      {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}         {this.state.exportClick ? (<ExcelFile filename="StormLine" hideElement={true}>             <ExcelSheet name="StormLine" data={this.state.tableList}>               <ExcelColumn label="Project" value="project" />
      <ExcelColumn label="Apartment No" value="itemGroup" />
      <ExcelColumn label="Description" value="item" />
      <ExcelColumn label="Apartment Type" value="requestDate" />
      <ExcelColumn label="Sections" value="quantity" />

    </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} 
    icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)}      */}
    </>);
    return (
      <Badge.Ribbon text={<FormattedMessage id="StormLine_Form.pageTitle" defaultMessage="Storm Line" />} placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>             <Col md={20}></Col><Col md={1}>{excelExport}</Col>  <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={() => this.setState({ TotalNumbersOfEndConnectionOfStormLines: true })} icon={<SettingTwoTone />}               ></AntButton>          <Col md={1}>               <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>             </Col>             <Col md={1}>               <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={this.onClickCreateNew} icon={<CreateIcon color="secondary" fontSize="large" />}               ></AntButton>               <AntButton style={{ border: "0px" }} hidden={this.state.hideInputs} onClick={this.onClickCreateNew} icon={<RemoveCircle color="error" fontSize="large" />}               ></AntButton>             </Col>           </Row>
            <div hidden={this.state.hideInputs}>





              <Modal
                title={<Badge.Ribbon text={<FormattedMessage id="StormLine.TotalNumbersOfEndConnectionOfStormLines" defaultMessage="Total number(s) of End connection of Storm Lines" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                keyboard={false}
                maskClosable={false}
                open={this.state.TotalNumbersOfEndConnectionOfStormLines}
                onCancel={() => {
                  this.setState({ TotalNumbersOfEndConnectionOfStormLines: false });
                }}
                okButtonProps={{ hidden: false }}
                okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
                cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                onOk={() => {
                  this.formRefTotalNumbersOfEndConnectionOfStormLines.current.submit();
                  this.setState({
                    TotalNumbersOfEndConnectionOfStormLines: false,
                    DescriptionOfEndConnections: true,
                  }, () => {
                    if (Boolean(this.formRefTotalNumbersOfEndConnectionOfStormLines) && Boolean(this.formRefTotalNumbersOfEndConnectionOfStormLines.current)) {
                      this.formRefTotalNumbersOfEndConnectionOfStormLines.current.resetFields();
                    }
                  });
                }}
                width={1000}
                centered
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveTotalNumbersOfEndConnectionOfStormLines} ref={this.formRefTotalNumbersOfEndConnectionOfStormLines}>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="StormLine.TotalNumberOfConnectionOfStormLines" defaultMessage="Total Number Of End Connection of Storm Lines" />}
                    name="TotalNumberOfConnectionOfStormLines"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <InputNumber min={1} style={{ width: "100%" }} />
                  </Form.Item>
                </Form>
              </Modal>


              <Modal
                title={<Badge.Ribbon text={<FormattedMessage id="StormLine.DescriptionOfEndConnections" defaultMessage="Total End Point StormLines" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                keyboard={false}
                maskClosable={false}
                open={this.state.DescriptionOfEndConnections}
                onCancel={() => {
                  this.setState({ DescriptionOfEndConnections: false });
                }}
                okButtonProps={{ hidden: false }}
                okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
                cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                onOk={() => {
                  this.formRefDescriptionOfEndConnections.current.submit();
                  this.setState({
                    DescriptionOfEndConnections: false,
                    TotalNumbersOfMainStormPipelines: true
                  }, () => {
                    if (Boolean(this.formRefDescriptionOfEndConnections) && Boolean(this.formRefDescriptionOfEndConnections.current)) {
                      this.formRefDescriptionOfEndConnections.current.resetFields();
                    }
                  });
                }}
                width={1000}
                centered
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveApartmentsAndTypes} ref={this.formRefDescriptionOfEndConnections}>
                  <Form.List name="apartmentsAndTypes">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"StormLine.EndPoint#"} defaultMessage={"End Point #"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"Apartment.Description"} defaultMessage={"Description"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"Apartment.ConnectionType"} defaultMessage={"Connection Type"} />
                                </th>
                                <th colSpan="1">
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "id"]}>
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                          {Boolean(this.state["apartmentIdSelectItems"]) && this.state["apartmentIdSelectItems"].length > 0 &&
                                            this.state["apartmentIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="description"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Input
                                          placeholder="Description"
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "apartmentTypeId"]}>
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                          {Boolean(this.state["Apartment.ApartmentTypeSelectItems"]) && this.state["Apartment.ApartmentTypeSelectItems"].length > 0 &&
                                            this.state["Apartment.ApartmentTypeSelectItems"].map(i => (
                                              <Option key={i.id} value={i.id}>
                                                {i.name}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"StormLine.AddNew"} defaultMessage={"Add New"} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </Form>
              </Modal>

              <Modal
                title={<Badge.Ribbon text={<FormattedMessage id="StormLine.TotalNumbersOfMainStormPipelines" defaultMessage="Total number(s) of main storm pipelines" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                keyboard={false}
                maskClosable={false}
                open={this.state.TotalNumbersOfMainStormPipelines}
                onCancel={() => {
                  this.setState({ TotalNumbersOfMainStormPipelines: false });
                }}
                okButtonProps={{ hidden: false }}
                okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
                cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                onOk={() => {
                  this.formRefTotalNumbersOfMainStormPipelines.current.submit();
                  this.setState({
                    TotalNumbersOfMainStormPipelines: false,
                    MainPipelineDescriptions: true,
                  }, () => {
                    if (Boolean(this.formRefTotalNumbersOfMainStormPipelines) && Boolean(this.formRefTotalNumbersOfMainStormPipelines.current)) {
                      this.formRefTotalNumbersOfMainStormPipelines.current.resetFields();
                    }
                  });
                }}
                width={1000}
                centered
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveTotalNumbersOfMainStormPipelines} ref={this.formRefTotalNumbersOfMainStormPipelines}>

                  <Form.Item

                    label={<FormattedMessage id="House Connections.descriptionOfEnd" defaultMessage="  " />}
                    style={{ marginBottom: "5px" }}
                  >
                    <Row gutter={24}>
                      <Col span={10}>
                        <Form.Item name="labelTrue">
                          <Select
                            allowClear
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Sanitary Pipelines"
                            onChange={value => {
                              this.setState({ answerType: value });
                            }}
                          >
                            {Boolean(this.state["answerTypeSelectItems"]) && this.state["answerTypeSelectItems"].length > 0 &&
                              this.state["answerTypeSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item name="labelTrue">
                          <InputNumber
                            placeholder="number"
                            style={{
                              width: "100%"
                            }}
                            min={1}
                            max={this.state.availableQuantity}
                          />
                        </Form.Item>
                      </Col>


                    </Row>
                  </Form.Item>
                </Form>
              </Modal>


              <Modal
                title={<Badge.Ribbon text={<FormattedMessage id="StormLine.Mai  PipelineDescriptions" defaultMessage="Main Pipeline Descriptions" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                keyboard={false}
                maskClosable={false}
                open={this.state.MainPipelineDescriptions}
                onCancel={() => {
                  this.setState({ MainPipelineDescriptions: false });
                }}
                okButtonProps={{ hidden: false }}
                okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
                cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                onOk={() => {
                  this.formRefMainPipelineDescriptions.current.submit();
                  this.setState({
                    MainPipelineDescriptions: false,
                    TotalEndConnectionOfStormManholes: true
                  }, () => {
                    if (Boolean(this.formRefMainPipelineDescriptions) && Boolean(this.formRefMainPipelineDescriptions.current)) {
                      this.formRefMainPipelineDescriptions.current.resetFields();
                    }
                  });
                }}
                width={1000}
                centered
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveMainPipelineDescriptions} ref={this.formRefMainPipelineDescriptions}>
                  <Form.List name="apartmentsAndTypes">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"StormLine.EndPointDescription"} defaultMessage={"End Point Description"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"Apartment.PipelineNo"} defaultMessage={"Pipeline #"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"Apartment.MainPipelineDescription"} defaultMessage={"Description"} />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "endPointDescription"]}>
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                          {Boolean(this.state["endPointDescriptionSelectItems"]) && this.state["endPointDescriptionSelectItems"].length > 0 &&
                                            this.state["endPointDescriptionSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="mainDescription"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Input
                                          placeholder="Description"
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "apartmentTypeId"]}>
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                          {Boolean(this.state["Apartment.ApartmentTypeSelectItems"]) && this.state["Apartment.ApartmentTypeSelectItems"].length > 0 &&
                                            this.state["Apartment.ApartmentTypeSelectItems"].map(i => (
                                              <Option key={i.id} value={i.id}>
                                                {i.name}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"StormLine.AddNew"} defaultMessage={"Add New"} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </Form>
              </Modal>


              <Modal
                title={<Badge.Ribbon text={<FormattedMessage id="StormLine.TotalEndConnectionOfStormManholes" defaultMessage="Total number(s) of End connection of Storm Lines" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                keyboard={false}
                maskClosable={false}
                open={this.state.TotalEndConnectionOfStormManholes}
                onCancel={() => {
                  this.setState({ TotalEndConnectionOfStormManholes: false });
                }}
                okButtonProps={{ hidden: false }}
                okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
                cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                onOk={() => {
                  this.formRefTotalEndConnectionOfStormManholes.current.submit();
                  this.setState({
                    TotalEndConnectionOfStormManholes: false,
                    ManholeDescriptions: true,
                  }, () => {
                    if (Boolean(this.formRefTotalEndConnectionOfStormManholes) && Boolean(this.formRefTotalEndConnectionOfStormManholes.current)) {
                      this.formRefTotalEndConnectionOfStormManholes.current.resetFields();
                    }
                  });
                }}
                width={1000}
                centered
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveTotalEndConnectionOfStormManholes} ref={this.formRefTotalEndConnectionOfStormManholes}>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="StormLine.TotalNumberOfConnectionOfStormLines" defaultMessage=" " />}
                    name="TotalNumberOfConnectionOfStormLines"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <InputNumber min={1} style={{ width: "100%" }} />
                  </Form.Item>
                </Form>
              </Modal>




              <Modal
                title={<Badge.Ribbon text={<FormattedMessage id="StormLine.ManholeDescriptions" defaultMessage="Total End Point StormLines" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                keyboard={false}
                maskClosable={false}
                open={this.state.ManholeDescriptions}
                onCancel={() => {
                  this.setState({ ManholeDescriptions: false });
                }}
                okButtonProps={{ hidden: false }}
                okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
                cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                onOk={() => {
                  this.formRefManholeDescriptions.current.submit();
                  this.setState({
                    ManholeDescriptions: false,
                    ManholeAndPipelines: true
                  }, () => {
                    if (Boolean(this.formRefManholeDescriptions) && Boolean(this.formRefManholeDescriptions.current)) {
                      this.formRefManholeDescriptions.current.resetFields();
                    }
                  });
                }}
                width={1000}
                centered
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveManholeDescriptions} ref={this.formRefManholeDescriptions}>
                  <Form.List name="apartmentsAndTypes">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"StormLine.MainStormPipeline"} defaultMessage={"Main Storm Pipeline"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"Apartment.ManholeNo"} defaultMessage={"Manhole #"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"Apartment.Description"} defaultMessage={"Description"} />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "id"]}>
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                          {Boolean(this.state["apartmentIdSelectItems"]) && this.state["apartmentIdSelectItems"].length > 0 &&
                                            this.state["apartmentIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "apartmentTypeId"]}>
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                          {Boolean(this.state["Apartment.ApartmentTypeSelectItems"]) && this.state["Apartment.ApartmentTypeSelectItems"].length > 0 &&
                                            this.state["Apartment.ApartmentTypeSelectItems"].map(i => (
                                              <Option key={i.id} value={i.id}>
                                                {i.name}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="description"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Input
                                          placeholder="Description"
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"StormLine.AddNew"} defaultMessage={"Add New"} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </Form>
              </Modal>



              <Modal
                title={<Badge.Ribbon text={<FormattedMessage id="StormLine.ManholeAndPipelines" defaultMessage="Manhole And Pipelines" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                keyboard={false}
                maskClosable={false}
                open={this.state.ManholeAndPipelines}
                onCancel={() => {
                  this.setState({ ManholeAndPipelines: false });
                }}
                okButtonProps={{ hidden: false }}
                okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
                cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                onOk={() => {
                  this.formRefManholeAndPipelines.current.submit();
                  this.setState({
                    ManholeAndPipelines: false,
                    HouseConnections: true
                  }, () => {
                    if (Boolean(this.formRefManholeAndPipelines) && Boolean(this.formRefManholeAndPipelines.current)) {
                      this.formRefManholeAndPipelines.current.resetFields();
                    }
                  });
                }}
                width={1000}
                centered
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveManholeAndPipelines} ref={this.formRefManholeAndPipelines}>
                  <Form.List name="ManholeAndPipelines">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"StormLine.MainStormPipeline"} defaultMessage={"Main Storm Pipeline"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"Apartment.ManholeNo"} defaultMessage={"Manhole #"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"Apartment.Description"} defaultMessage={"Description"} />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "id"]}>
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                          {Boolean(this.state["apartmentIdSelectItems"]) && this.state["apartmentIdSelectItems"].length > 0 &&
                                            this.state["apartmentIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "apartmentTypeId"]}>
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                          {Boolean(this.state["Apartment.ApartmentTypeSelectItems"]) && this.state["Apartment.ApartmentTypeSelectItems"].length > 0 &&
                                            this.state["Apartment.ApartmentTypeSelectItems"].map(i => (
                                              <Option key={i.id} value={i.id}>
                                                {i.name}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="description"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Input
                                          placeholder="Description"
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"StormLine.AddNew"} defaultMessage={"Add New"} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </Form>
              </Modal>


              <Modal
                title={<Badge.Ribbon text={<FormattedMessage id="StormLine.HouseConnections" defaultMessage="House Connections" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                keyboard={false}
                maskClosable={false}
                open={this.state.HouseConnections}
                onCancel={() => {
                  this.setState({ HouseConnections: false });
                }}
                okButtonProps={{ hidden: false }}
                okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
                cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                onOk={() => {
                  this.formRefHouseConnections.current.submit();
                  this.setState({
                    HouseConnections: false,
                    CatchBasinConnections: true
                  }, () => {
                    if (Boolean(this.formRefHouseConnections) && Boolean(this.formRefHouseConnections.current)) {
                      this.formRefHouseConnections.current.resetFields();
                    }
                  });
                }}
                width={1000}
                centered
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveHouseConnections} ref={this.formRefHouseConnections}>
                  <Form.List name="apartmentsAndTypes">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"StormLine.MainStormPipeline"} defaultMessage={"Main Storm Line "} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"StormLine.ManholeDescription"} defaultMessage={"Manhole Description"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"StormLine.PipelineNo"} defaultMessage={"Pipeline #"} />
                                </th>
                                <th colSpan="1">
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"Apartment.ManholeNo"} defaultMessage={"No of house connections"} />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "id"]}>
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                          {Boolean(this.state["apartmentIdSelectItems"]) && this.state["apartmentIdSelectItems"].length > 0 &&
                                            this.state["apartmentIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "id"]}>
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                          {Boolean(this.state["apartmentIdSelectItems"]) && this.state["apartmentIdSelectItems"].length > 0 &&
                                            this.state["apartmentIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "apartmentTypeId"]}>
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                          {Boolean(this.state["StormLine.noOfHouseConnection"]) && this.state["StormLine.noOfHouseConnection"].length > 0 &&
                                            this.state["StormLine.noOfHouseConnection"].map(i => (
                                              <Option key={i.id} value={i.id}>
                                                {i.name}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="no of house connection"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Input
                                          placeholder="Description"
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"StormLine.AddNew"} defaultMessage={"Add New"} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </Form>
              </Modal>


              <Modal
                title={<Badge.Ribbon text={<FormattedMessage id="StormLine.CatchBasinConnections" defaultMessage="Catch Basin Connections" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                keyboard={false}
                maskClosable={false}
                open={this.state.CatchBasinConnections}
                onCancel={() => {
                  this.setState({ CatchBasinConnections: false });
                }}
                okButtonProps={{ hidden: false }}
                okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
                cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                onOk={() => {
                  this.formRefCatchBasinConnections.current.submit();
                  this.setState({
                    CatchBasinConnections: false,
                    ManholeCoordinates: true
                  }, () => {
                    if (Boolean(this.formRefCatchBasinConnections) && Boolean(this.formRefCatchBasinConnections.current)) {
                      this.formRefCatchBasinConnections.current.resetFields();
                    }
                  });
                }}
                width={1000}
                centered
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveCatchBasinConnections} ref={this.formRefCatchBasinConnections}>
                  <Form.List name="apartmentsAndTypes">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"StormLine.MainStormPipeline"} defaultMessage={"Main Storm Pipeline"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"Apartment.ManholeDescription"} defaultMessage={"Manhole Description"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"Apartment.Description"} defaultMessage={"Description"} />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "id"]}>
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                          {Boolean(this.state["apartmentIdSelectItems"]) && this.state["apartmentIdSelectItems"].length > 0 &&
                                            this.state["apartmentIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "apartmentTypeId"]}>
                                        <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                          {Boolean(this.state["Apartment.ApartmentTypeSelectItems"]) && this.state["Apartment.ApartmentTypeSelectItems"].length > 0 &&
                                            this.state["Apartment.ApartmentTypeSelectItems"].map(i => (
                                              <Option key={i.id} value={i.id}>
                                                {i.name}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="description"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Input
                                          placeholder="Description"
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"StormLine.AddNew"} defaultMessage={"Add New"} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </Form>
              </Modal>

              <Modal
                title={<Badge.Ribbon text={<FormattedMessage id="StormLine.ManholeCoordinates" defaultMessage="Catch Basin Connections" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                keyboard={false}
                maskClosable={false}
                open={this.state.ManholeCoordinates}
                onCancel={() => {
                  this.setState({ ManholeCoordinates: false });
                }}
                okButtonProps={{ hidden: false }}
                okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
                cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                onOk={() => {
                  this.formRefManholeCoordinates.current.submit();
                  this.setState({
                    ManholeCoordinates: false,
                    CatchBasinCoordinates: true,
                  }, () => {
                    if (Boolean(this.formRefManholeCoordinates) && Boolean(this.formRefManholeCoordinates.current)) {
                      this.formRefManholeCoordinates.current.resetFields();
                    }
                  });
                }}
                width={1000}
                centered
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveManholeCoordinates} ref={this.formRefManholeCoordinates}>

                  <br>
                  </br>
                  <br></br>
                  <Form.Item
                    label={<FormattedMessage id="EldenLaborLaw.overtimeCriterias" defaultMessage="Manhole" />}
                    name="overtimeCriterias"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select style={{ width: "100%" }} mode="multiple" placeholder="Manhole Description">
                      {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                        this.state["overtimeCriteriasSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  <br />      <h4><FormattedMessage id={"APDetailsTypesOfHousesModal.SectionsOfApartments"} defaultMessage={"  "} /></h4>

                  <Form.List name="APDetailsTypesOfHouses">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Hole"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"N"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"E"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"El"} />
                                </th>
                                <th colSpan="1">
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Input
                                          style={{
                                            width: "100%"
                                          }}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ExecutionPlan.AddNewPersonnel"} defaultMessage={"Add New Coordinate "} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>

                  <br />      <h4><FormattedMessage id={"APDetailsTypesOfHousesModal.SectionsOfApartments"} defaultMessage={"  "} /></h4>

                  <Form.List name="APDetailsTypesOfHouses">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"S/N"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Manhole No"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Coordinates"} />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Input
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ExecutionPlan.AddNewPersonnel"} defaultMessage={"Add New Coordinate "} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </Form>
              </Modal>


              <Modal
                title={<Badge.Ribbon text={<FormattedMessage id="StormLine.CatchBasinCoordinates" defaultMessage="Catch Basin Coordinates" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                keyboard={false}
                maskClosable={false}
                open={this.state.CatchBasinCoordinates}
                onCancel={() => {
                  this.setState({ CatchBasinCoordinates: false });
                }}
                okButtonProps={{ hidden: false }}
                okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
                cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                onOk={() => {
                  this.formRefCatchBasinCoordinates.current.submit();
                  this.setState({
                    CatchBasinCoordinates: false,
                    PipeJointCoordinates: true,
                  }, () => {
                    if (Boolean(this.formRefCatchBasinCoordinates) && Boolean(this.formRefCatchBasinCoordinates.current)) {
                      this.formRefCatchBasinCoordinates.current.resetFields();
                    }
                  });
                }}
                width={1000}
                centered
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveCatchBasinCoordinates} ref={this.formRefCatchBasinCoordinates}>

                  <br>
                  </br>
                  <br></br>
                  <Form.Item
                    label={<FormattedMessage id="EldenLaborLaw.overtimeCriterias" defaultMessage="Manhole" />}
                    name="overtimeCriterias"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select style={{ width: "100%" }} mode="multiple" placeholder="Manhole Description">
                      {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                        this.state["overtimeCriteriasSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  <br />      <h4><FormattedMessage id={"APDetailsTypesOfHousesModal.SectionsOfApartments"} defaultMessage={"  "} /></h4>

                  <Form.List name="APDetailsTypesOfHouses">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"S/N"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"N"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"E"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"El"} />
                                </th>
                                <th colSpan="1">
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Input
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ExecutionPlan.AddNewPersonnel"} defaultMessage={"Add New Coordinate "} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>

                  <br />      <h4><FormattedMessage id={"APDetailsTypesOfHousesModal.SectionsOfApartments"} defaultMessage={"  "} /></h4>

                  <Form.List name="APDetailsTypesOfHouses">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"S/N"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Manhole No"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Coordinates"} />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Input
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ExecutionPlan.AddNewPersonnel"} defaultMessage={"Add New Coordinate "} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </Form>
              </Modal>


              <Modal
                title={<Badge.Ribbon text={<FormattedMessage id="StormLine.ManholeDescriptions" defaultMessage="Total End Point StormLines" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                keyboard={false}
                maskClosable={false}
                open={this.state.PipeJointCoordinates}
                onCancel={() => {
                  this.setState({ PipeJointCoordinates: false });
                }}
                okButtonProps={{ hidden: false }}
                okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
                cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                onOk={() => {
                  this.PipeJointCoordinates.current.submit();
                  this.setState({
                    PipeJointCoordinates: false,
                  }, () => {
                    if (Boolean(this.formRefPipeJointCoordinates) && Boolean(this.formRefPipeJointCoordinates.current)) {
                      this.formRefPipeJointCoordinates.current.resetFields();
                    }
                  });
                }}
                width={1000}
                centered
              >
                <Form initialValues={{ remember: false }} onFinish={this.savePipeJointCoordinates} ref={this.formRefPipeJointCoordinates}>


                  <br>
                  </br>
                  <br></br>
                  <Form.Item
                    label={<FormattedMessage id="" defaultMessage=" " />}
                    style={{ marginBottom: "5px" }}
                  >
                    <Row gutter={8}>
                      <Col span={10}>
                        <Form.Item
                          name=" "
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select style={{ width: "100%" }} mode="multiple" placeholder="  ">
                            {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                              this.state["overtimeCriteriasSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}{" "}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        To
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          name="  "
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select style={{ width: "100%" }} mode="multiple" placeholder="   ">
                            {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                              this.state["overtimeCriteriasSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}{" "}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <br />      <h4><FormattedMessage id={"APDetailsTypesOfHousesModal.SectionsOfApartments"} defaultMessage={"  "} /></h4>

                  <Form.List name="APDetailsTypesOfHouses">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Joint No"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"N"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"E"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"El"} />
                                </th>
                                <th colSpan="2" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Matl1"} />
                                </th>
                                <th colSpan="2" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"Matl2"} />
                                </th>
                                <th colSpan="1">
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Input
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "40%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "40%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}


                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ExecutionPlan.AddNewPersonnel"} defaultMessage={"Add New Coordinate "} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                  <br />      <h4><FormattedMessage id={"APDetailsTypesOfHousesModal.SectionsOfApartments"} defaultMessage={"  "} /></h4>

                  <Form.List name="APDetailsTypesOfHouses">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"S/N"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Line No"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Joint No"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"Coordinates"} />
                                </th>
                                <th colSpan="2" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Materials"} />
                                </th>
                                <th colSpan="1">
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Input
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "40%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "40%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}


                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ExecutionPlan.AddNewPersonnel"} defaultMessage={"Add New Coordinate "} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </Form>
              </Modal>


              {<Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Button id="StormLine.button" style={{ width: "100%" }} variant="secondary" onClick={this.buttonModal}>
                    <FormattedMessage id="StormLine.button" defaultMessage="Button" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>} 				<br />
              <div hidden={this.state.hideSave}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>                     <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">                       <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
              <div hidden={this.state.hideUpdate}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>                       <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />                     </Button>                   </Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">                       <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (<div className="alert alert-custom alert-light">                 <div className="alert-icon">                   <FilterOutlined />                 </div>                 <div className="alert-text">                   {this.state.searchCriteriaModels.map((val, i) => { return (<div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">                         <span className="mr-2">{val.label}</span>                         <strong className="mr-2">{val.type}</strong>                         <span className="mr-2">{val.operationValue}</span>                         <span className="mr-2">{val.showValue}</span>                         <span>                           <Tooltip title="Remove">                             <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />                           </Tooltip>                         </span>                       </div>); })}                 </div>                 <div className="alert-close">                   <Tooltip title="Remove All">                     <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.setState({ searchCriteriaModels: [] }, () => { this.filterChangeItem([]); })} />                   </Tooltip>                 </div>               </div>)}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">           <Table bordered scroll={{ x: 900, scrollToFirstRowOnChange: true }} columns={columns} dataSource={this.state.tableList} loading={this.state.loading} onChange={this.handleTableChange} pagination={this.state.pagination} />         </div>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(StormLine);
