import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Col as ColBoot, Row as RowBoot, Form as FormBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import XMLParser from "react-xml-parser";
import {
  Input,
  Select,
  InputNumber,
  Checkbox,
  Col,
  Row,
  Modal,
  Table,
  Typography,
  Cascader,
  Button as AntButton,
  Form,
  Space,
  Divider,
  DatePicker,
  Badge,
  Tooltip,
  Spin
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  RightCircleTwoTone,
  LeftCircleTwoTone,
  UploadOutlined,
  MinusOutlined,
  ZoomInOutlined,
  FilterOutlined,
  LoadingOutlined,
  EyeTwoTone,
  TagOutlined
} from "@ant-design/icons";
import { handleRequest } from "../../ApiConnector";
import moment from "moment";
import { InfoCircleTwoTone, PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { ReplyOutlined } from "@material-ui/icons";
import { Resizable } from "react-resizable";
import "../../assets/style/resizeTable.css";
import RfqReplyPaymentTerms from "./RfqReplyPaymentTerms";

const { Option } = Select;
const { Text } = Typography;

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate"
      ? form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? moment(record[dataIndex], "DD-MM-YYYY") : ""
      })
      : form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
      });
  };

  const toggleSave = async () => {
    try {
      if (dataIndex === "planedDeliveryDate") {
        const values = await form.validateFields();
        values.planedDeliveryDate = Boolean(values.planedDeliveryDate) ? moment(values.planedDeliveryDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else if (dataIndex === "paymentDate") {
        const values = await form.validateFields();
        values.paymentDate = Boolean(values.paymentDate) ? moment(values.paymentDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record }, { ...values });
      }
    } catch (errInfo) { }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <>
        {dataIndex === "deliveryPlace" ? (
          <>
            <Form.Item
              label={<FormattedMessage id="RfqVendorReply.town" defaultMessage="Town" />}
              name="town"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Form.Item
              label={<FormattedMessage id="RfqVendorReply.city" defaultMessage="City" />}
              name="city"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Button id="SaveButton" onClick={toggleSave} style={{ width: "100%" }} variant="success">
              <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
            </Button>
          </>
        ) : (
          <Form.Item
            style={{
              margin: 0
            }}
            name={dataIndex}
          >
            {dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate" ? (
              <DatePicker ref={inputRef} onBlur={toggleSave} format={"DD-MM-YYYY"} />
            ) : dataIndex === "currency" ? (
              <Select ref={inputRef} onChange={toggleSave} onBlur={toggleSave} showSearch optionFilterProp="children">
                {Boolean(record.currencySelectItems) &&
                  record.currencySelectItems.length > 0 &&
                  record.currencySelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            ) : dataIndex === "unit" ? (
              <Cascader style={{ width: "100%" }} options={record.unitSelectItems} ref={inputRef} onChange={toggleSave} onBlur={toggleSave} />
            ) : (
              <InputNumber
                parser={value => value.replace(",", ".")}
                formatter={value => value.replace(".", ",")}
                min={0}
                ref={inputRef}
                onPressEnter={toggleSave}
                onBlur={toggleSave}
              />
            )}
          </Form.Item>
        )}
      </>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion

export class RfqVendorReply extends Component {
  constructor({ requestForQuotation, vendor }) {
    super();
    this.state = {
      controllerName: "rfqVendorReply",
      hideSave: false,
      id: null,
      requestForQuotation: requestForQuotation,
      requestForQuotationId: requestForQuotation?.id,
      vendorId: vendor?.id,
      vendor: vendor,
      disableVendorSelect: false,
      disableRfqSelect: true,
      vendorsSelectItems: [],
      vendorReplyList: [],
      totalScore: 0,
      hasOurExpectedPrice: false,
      currencySelect: "TL",
      saveLoading: false,

      paginationDeliveryDate: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      }
    };
  }

  formPaymentTerms = React.createRef();
  formCurrency = React.createRef();
  formDiscount = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
    this.fillVendors(this.props.requestForQuotation.id);
    if (this.props.vendor) {
      this.fillReplies(this.props.requestForQuotation, this.props.vendor);
    }
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search and click enter`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
      </div>
    ),
    filterIcon: filtered => <FilterOutlined style={{ color: filtered ? "#1890ff" : "#bfbfbf" }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  handlePriceListUnitPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.unitPrice === value.unitPrice) return;
        var newUnitPrice = item.unitPrice - value.unitPrice;
        if (Boolean(newUnitPrice) && newUnitPrice > 0) {
          row.unitPrice = newUnitPrice;
          row.oldUnitPrice = 0;
        }

        item.discount = 0;
        item.discountPercent = 0;
        item.oldUnitPrice = item.unitPrice;
        item.unitPrice = value.unitPrice;
        item.price = item.quantity * item.unitPrice;
        this.setState({
          ourExpectedPrices: newData
        });
      }
    }
  };

  handleUnit = async (row, value) => {
    if (Boolean(value) && Boolean(value.unit) && value.unit.length > 0) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(item)) {
        let unitId = Boolean(value.unit) && value.unit.length > 0 ? value.unit[value.unit.length - 1] : null;
        let unitTypeId = Boolean(value.unit) && value.unit.length > 0 ? value.unit[0] : null;
        if (!isNaN(unitId) && !isNaN(unitTypeId)) {
          item.unitId = unitId;
          item.purchasingCriteriaUnitId = unitId;
          const unitType = row.unitSelectItems.find(p => p.value === unitTypeId);
          if (Boolean(unitType) && Boolean(unitType.children) && unitType.children.length > 0) {
            const unit = unitType.children.find(p => p.value === unitId);
            if (Boolean(unit)) {
              item.unit = unit.label;
            }
          }
          this.setState({
            ourExpectedPrices: newData
          });
        }
      }
    }
  };

  componentDidUpdate = async prevProps => {
    console.log("props", this.props);
    let id = Boolean(this.props) && Boolean(this.props.requestForQuotation) ? this.props.requestForQuotation.id : undefined;
    let vendorId = Boolean(this.props) && Boolean(this.props.vendor) ? this.props.vendor.id : undefined;

    let random = this.props.random;
    if (random !== prevProps.random) {
      this.setState(
        {
          requestForQuotation: this.props.requestForQuotation,
          requestForQuotationId: id,
          vendor: this.props.vendor,
          vendorId: vendorId,
          vendorReplyList: []
        },
        () => {
          this.fillComboboxes();
          this.fillVendors(id);
          if (vendorId) {
            this.fillReplies(this.props.requestForQuotation, this.props.vendor);
          }
        }
      );
    }
  };

  fillComboboxes = async () => {
    var responsePeriodType = await handleRequest("GET", "/api/" + this.state.controllerName + "/periodType");
    if (responsePeriodType.type === "ERROR") {
      error(responsePeriodType);
    }

    var responseCurrency = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responseCurrency.type === "ERROR") {
      error(responseCurrency);
    }

    var responseKur = await handleRequest("GET", "/api/" + this.state.controllerName + "/kur");
    if (responseKur.type === "ERROR") {
      error(responseKur);
    }

    var responseAllUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/unit");
    if (responseAllUnit.type === "ERROR") {
      error(responseAllUnit);
    }

    var kur = null;
    try {
      kur = new XMLParser().parseFromString(responseKur.data);
    } catch (error) { }

    this.setState({
      periodTypeSelectItems: Boolean(responsePeriodType.data) ? responsePeriodType.data : [],
      currencySelectItems: Boolean(responseCurrency.data) ? responseCurrency.data : [],
      kur: kur,
      allUnitSelectItems: Boolean(responseAllUnit.data) ? responseAllUnit.data : []
    });
  };

  fillReplies(rfq, vendor) {
    let newItem = {};
    if (vendor && vendor.id) {
      newItem.vendorId = vendor.id;
      newItem.disableVendorSelect = true;
      this.onChangeVendor(rfq.id, vendor.id);
    }
    this.setState({
      ...newItem
    });
  }

  fillVendors = async requestForQuotationId => {
    if (Boolean(requestForQuotationId) && requestForQuotationId > 0) {
      var response = null;
      if (this.props.responsePage && Boolean(this.props.vendor) && Boolean(this.props.vendor.id) && this.props.vendor.id > 0) {
        response = await handleRequest(
          "GET",
          "/api/" + this.state.controllerName + "/vendorResponsePage/" + requestForQuotationId + "/" + this.props.vendor.id
        );
      } else {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/vendor/" + requestForQuotationId);
      }
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          vendorsSelectItems: Boolean(response.data) && response.data.length > 0 ? response.data : []
        });
      }
    } else {
      this.setState({
        vendorsSelectItems: []
      });
    }
    this.getHasOurExpectedPrice();
  };

  getHasOurExpectedPrice = async () => {
    if (Boolean(this.state.vendorId) && this.state.vendorId > 0) {
      var responseOurExpected = await handleRequest(
        "GET",
        "/api/" + this.state.controllerName + "/hasOurExpectedPrice/" + this.state.requestForQuotationId + "/" + this.state.vendorId
      );
      if (responseOurExpected.type === "ERROR") {
        error(responseOurExpected);
      } else {
        this.setState({
          hasOurExpectedPrice: responseOurExpected.data
        });
      }
    } else {
      this.setState({
        hasOurExpectedPrice: false
      });
    }
  };

  cancel = () => {
    this.setState({
      hideSave: false
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.setState({
      id: null,

      vendorsSelectItems: [],
      vendorReplyList: [],
      vendorId: null,
      totalScore: 0
    });
  };

  save = async () => {
    this.setState({ saveLoading: true });
    if (
      Boolean(this.state.vendorReplyList) &&
      this.state.vendorReplyList.length > 0 &&
      Boolean(this.state.vendorId) &&
      this.state.vendorId > 0 &&
      Boolean(this.state.requestForQuotationId) &&
      this.state.requestForQuotationId > 0
    ) {
      var newItem = { ...this.state.vendorReplyList[0] };
      newItem.id = null;
      newItem.vendorId = this.state.vendorId;
      newItem.requestForQuotationId = this.state.requestForQuotationId;

      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
      }
      if (response.type === "ERROR") {
        error(response);
        this.setState({ saveLoading: false });
      } else {
        this.fillVendors(this.state.requestForQuotationId);
        this.cancel();
        showSuccess();
        this.setState({ saveLoading: false });
        if (Boolean(this.props.closeRfqVendorReply)) {
          this.props.closeRfqVendorReply();
        }
        this.setState({
          modalPriceShow: false
        });
      }
    } else {
      showError();
      this.setState({ saveLoading: false });
    }
  };

  onClickDeliveryDateModal = () => {
    this.setState({
      modalPricePaymentDeliveryShow: true
    });
    this.getPricesData();
  };

  onClickPricesModal = () => {
    this.setState({
      modalPricePaymentDeliveryShow: true
    });

    this.getPricesData();
  };

  getPricesData = async () => {
    if (Boolean(this.state.requestForQuotationId)) {
      var newObj = {
        id: this.state.requestForQuotationId
      };
      let responsePricecs = await handleRequest("POST", "/api/" + this.state.controllerName + "/ourExpectedPrices/" + this.state.vendorId, newObj);
      if (responsePricecs.type === "ERROR") {
        error(responsePricecs);
      } else {
        if (Boolean(responsePricecs.data) && responsePricecs.data.length > 0) {
          responsePricecs.data.forEach((element, index) => {
            if (Boolean(element.planedDeliveryDate)) {
              element.planedDeliveryDate = moment(element.planedDeliveryDate).format("DD-MM-YYYY");
            }
            element.price = element.quantity * element.unitPrice;
            element.key = index + 1;
            element.currencySelectItems = this.state.currencySelectItems;
            element.defaultUnit = element.unit;
            element.defaultUnitId = element.unitId;

            if (!Boolean(element.priceVat)) {
              element.priceVat = 20;
            }

            if (!Boolean(element.currency)) {
              element.currency = "TL";
            }

            this.getUnitStcokCard(element);
          });
        }
        this.setState({
          ourExpectedPrices: Boolean(responsePricecs.data) && responsePricecs.data.length > 0 ? responsePricecs.data : [],
          maxOurExpectedPrice: responsePricecs.data.length
        });
      }
    } else {
      this.setState({
        ourExpectedPrices: [],
        maxOurExpectedPrice: 0
      });
    }
  };

  handlePriceListUnitPriceOurExpectedPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(value.unitPrice) && Boolean(item)) {
        if (item.unitPrice === value.unitPrice) return;
        var newUnitPrice = item.unitPrice - value.unitPrice;
        if (Boolean(newUnitPrice) && newUnitPrice > 0) {
          row.unitPrice = newUnitPrice;
          row.oldUnitPrice = 0;
        }

        if (!Boolean(row.currency)) {
          row.currency = "TL";
        }

        item.oldUnitPrice = item.unitPrice;
        item.unitPrice = value.unitPrice;
        item.price = item.quantity * item.unitPrice;
        this.setState(
          {
            ourExpectedPrices: newData,
            modalCurrencyShow: true,
            selectedOurExpectedPriceId: item.key
          },
          () => {
            this.formCurrency.current.setFieldsValue({
              ...row
            });
          }
        );
      }
    }
  };

  handlePriceListQuantityOurExpectedPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.quantity === value.quantity) return;

        var newQuantity = item.quantity - value.quantity;
        if (Boolean(newQuantity) && newQuantity > 0) {
          row.quantity = newQuantity;
          row.oldQuantity = 0;
        }
        item.oldQuantity = item.quantity;
        item.quantity = value.quantity;
        item.price = item.quantity * item.unitPrice;
        item.unit = item.defaultUnit;
        item.unitId = item.defaultUnitId;

        this.setState({
          ourExpectedPrices: newData
        });
      }
    }
  };

  handlePriceListUnitOurExpectedPrice = async (row, value) => {
    if (Boolean(value)) {
      let obj = row.unitSelectItems.find(p => p.key === value.unit);
      if (Boolean(obj)) {
        const newData = [...this.state.ourExpectedPrices];
        const item = newData.find(item => row.key === item.key);
        if (Boolean(value) && Boolean(item)) {
          if (item.unit === obj.value) return;
          item.purchasingCriteriaUnitId = obj.key;
          item.unit = obj.value;
          this.setState({
            ourExpectedPrices: newData
          });
        }
      }
    }
  };

  handlePriceListDeliveryPlaceOurExpectedPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        item.town = value.town;
        item.city = value.city;
        this.setState({
          ourExpectedPrices: newData
        });
      }
    }
  };

  handlePriceListCurrencyOurExpectedPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.currency === value.currency) return;
        item.currency = value.currency;
        this.setState({
          ourExpectedPrices: newData
        });
      }
    }
  };

  handlePriceListPlannedDeliveryDate = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.planedDeliveryDate === value.planedDeliveryDate) return;
        item.planedDeliveryDate = value.planedDeliveryDate;
        this.setState({
          ourExpectedPrices: newData
        });
      }
    }
  };

  handlePriceListUnitOurExpectedPriceVat = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.ourExpectedPrices];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.priceVat === value.priceVat) return;
        item.priceVat = value.priceVat;
        this.setState({
          ourExpectedPrices: newData
        });
      }
    }
  };

  renderElement = (values, title) => {
    return values.map((item, index) => (
      <RowBoot key={item.description + index} style={{ marginBottom: "1rem" }}>
        <ColBoot xs={4}>
          <FormBoot.Label style={index === 0 ? { color: "black", marginTop: "0.5rem", fontWeight: "bold" } : { color: "black", marginTop: "0.5rem" }}>
            {item.description}
          </FormBoot.Label>
        </ColBoot>
        <ColBoot xs={2} className="text-center">
          {index !== 0 && (
            <Checkbox
              valuePropName="checked"
              checked={item.mandatory}
              onChange={e => {
                if (e.target.checked) {
                  item.mandatory = false;
                }
                item.mandatory = e.target.checked;
                this.setState({
                  vendorReplyList: this.state.vendorReplyList
                });
              }}
            />
          )}
        </ColBoot>

        {item.description === "Fix Date" ||
          item.description === "Your proposal of delivery date" ||
          item.description === "To be decided by mutual negotiations" ? (
          <>
            {this.state.hasOurExpectedPrice ? (
              <>
                <ColBoot xs={1}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={this.onClickDeliveryDateModal}
                    icon={<InfoCircleTwoTone style={{ fontSize: "25px", cursor: "pointer" }} />}
                  ></AntButton>
                </ColBoot>
                <ColBoot xs={2}>
                  {index !== 0 && (
                    <FormattedMessage id=" RfqVendorReply.reply" defaultMessage="Reply">
                      {msg => (
                        <Input
                          value={item.replyMessage}
                          placeholder={msg}
                          onChange={e => {
                            item.replyMessage = e.target.value;
                            this.setState({
                              vendorReplyList: this.state.vendorReplyList
                            });
                          }}
                          onClick={this.onClickDeliveryDateModal}
                        ></Input>
                      )}
                    </FormattedMessage>
                  )}
                </ColBoot>
              </>
            ) : (
              <ColBoot xs={3}>
                {index !== 0 && (
                  <FormattedMessage id=" RfqVendorReply.reply" defaultMessage="Reply">
                    {msg => (
                      <Input
                        value={item.replyMessage}
                        placeholder="Reply"
                        onChange={e => {
                          item.replyMessage = e.target.value;
                          this.setState({
                            vendorReplyList: this.state.vendorReplyList
                          });
                        }}
                        onClick={this.onClickDeliveryDateModal}
                      ></Input>
                    )}
                  </FormattedMessage>
                )}
              </ColBoot>
            )}
          </>
        ) : item.description === "Your offer for price" || item.description === "Our expected price" ? (
          <>
            {this.state.hasOurExpectedPrice ? (
              <>
                <ColBoot xs={1}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={this.onClickPricesModal}
                    icon={<InfoCircleTwoTone style={{ fontSize: "25px", cursor: "pointer" }} />}
                  ></AntButton>
                </ColBoot>
                <ColBoot xs={2}>
                  {index !== 0 && (
                    <FormattedMessage id=" RfqVendorReply.reply" defaultMessage="Reply">
                      {msg => (
                        <Input
                          value={item.replyMessage}
                          placeholder={msg}
                          onChange={e => {
                            item.replyMessage = e.target.value;
                            this.setState({
                              vendorReplyList: this.state.vendorReplyList
                            });
                          }}
                          onClick={this.onClickPricesModal}
                        ></Input>
                      )}
                    </FormattedMessage>
                  )}
                </ColBoot>
              </>
            ) : (
              <ColBoot xs={3}>
                {index !== 0 && (
                  <FormattedMessage id=" RfqVendorReply.reply" defaultMessage="Reply">
                    {msg => (
                      <Input
                        value={item.replyMessage}
                        placeholder={msg}
                        onChange={e => {
                          item.replyMessage = e.target.value;
                          this.setState({
                            vendorReplyList: this.state.vendorReplyList
                          });
                        }}
                        onClick={this.onClickPricesModal}
                      ></Input>
                    )}
                  </FormattedMessage>
                )}
              </ColBoot>
            )}
          </>
        ) : (
          <ColBoot xs={3}>
            {index !== 0 && (
              <FormattedMessage id=" RfqVendorReply.reply" defaultMessage="Reply">
                {msg => (
                  <Input
                    value={item.replyMessage}
                    placeholder={msg}
                    onChange={e => {
                      item.replyMessage = e.target.value;
                      this.setState({
                        vendorReplyList: this.state.vendorReplyList
                      });
                    }}
                  ></Input>
                )}
              </FormattedMessage>
            )}
          </ColBoot>
        )}
        <ColBoot xs={3}>
          {index !== 0 && (
            <FormattedMessage id=" RfqVendorReply.Remarks" defaultMessage="Remarks">
              {msg => (
                <Input
                  value={item.remarks}
                  placeholder={msg}
                  onChange={e => {
                    item.remarks = e.target.value;
                    this.setState({
                      vendorReplyList: this.state.vendorReplyList
                    });
                  }}
                ></Input>
              )}
            </FormattedMessage>
          )}
        </ColBoot>
      </RowBoot>
    ));
  };

  onChangeVendor = async (requestForQuotationId, vendorId) => {
    this.setState(
      {
        vendorId: vendorId
      },
      () => {
        this.fillVendorReplies(requestForQuotationId, vendorId);
        this.getHasOurExpectedPrice();
      }
    );
  };

  fillVendorReplies = async (requestForQuotationId, vendorId) => {
    if (Boolean(vendorId) && Boolean(requestForQuotationId)) {
      const response = await handleRequest(
        "GET",
        "/api/" + this.state.controllerName + "/vendorReplies/" + this.state.requestForQuotationId + "/" + this.state.vendorId
      );
      if (response.type === "ERROR") {
        error(response);
      } else if (Boolean(response.data)) {
        let reply = response.data;
        this.vendorRepliesCheckList(reply);

        this.setState(
          {
            vendorReplyList: [reply]
          },
          () => {
            if (Boolean(this.state.vendorReplyList) && this.state.vendorReplyList.length > 0) {
              this.getPricesData();
            }
          }
        );
      } else {
        this.setState(
          {
            vendorReplyList: [this.state.requestForQuotation]
          },
          () => {
            if (Boolean(this.state.vendorReplyList) && this.state.vendorReplyList.length > 0) {
              this.getPricesData();
            }
          }
        );
      }
    } else {
      this.setState({
        vendorReplyList: []
      });
    }
  };

  getRfqNo = record => {
    if (!Boolean(record) || !Boolean(record.id)) {
      return "";
    }
    if (record.id.toString().length === 2) {
      return "RFQ-0" + record.id;
    } else if (record.id.toString().length === 1) {
      return "RFQ-00" + record.id;
    } else {
      return "RFQ-" + record.id;
    }
  };

  savePrice = async () => {
    if (Boolean(this.state.ourExpectedPrices) && this.state.ourExpectedPrices.length > 0) {
      let paymentTermsErrorItem = [];
      this.state.ourExpectedPrices.forEach(element => {
        if (Boolean(element.planedDeliveryDate)) {
          element.planedDeliveryDate = moment(element.planedDeliveryDate, "DD-MM-YYYY");
        }
        if (!(Boolean(element.paymentTerms) && element.paymentTerms.length > 0)) {
          paymentTermsErrorItem.push(element.itemDescriptionRename + " (" + element.quantity + ")");
        }
      });

      if (Boolean(paymentTermsErrorItem) && paymentTermsErrorItem.length > 0) {
        this.setState({
          modalPaymentTermsErrorShow: true,
          paymentTermsErrorItem: paymentTermsErrorItem
        });
      } else {
        var response = await handleRequest(
          "POST",
          "/api/" + this.state.controllerName + "/savePrice/" + this.state.requestForQuotationId + "/" + this.state.vendorId,
          this.state.ourExpectedPrices
        );
        if (response.type === "ERROR") {
          error(response);
        } else {
          this.getHasOurExpectedPrice();
          showSuccess();
          this.setState({
            modalPriceShow: true
          });
        }
      }
    } else {
      this.showError("Not found");
    }
  };

  savePriceAgain = async () => {
    if (Boolean(this.state.ourExpectedPrices) && this.state.ourExpectedPrices.length > 0) {
      this.state.ourExpectedPrices.forEach(element => {
        if (Boolean(element.planedDeliveryDate)) {
          element.planedDeliveryDate = moment(element.planedDeliveryDate, "DD-MM-YYYY");
        }
      });
      var response = await handleRequest(
        "POST",
        "/api/" + this.state.controllerName + "/savePrice/" + this.state.requestForQuotationId + "/" + this.state.vendorId,
        this.state.ourExpectedPrices
      );
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.getHasOurExpectedPrice();
        showSuccess();
        this.setState({
          modalPriceShow: true,
          modalPaymentTermsErrorShow: false
        });
      }
    } else {
      this.showError("Not found");
    }
  };

  paymentTermsModal = async row => {
    this.setState({
      selectedOurExpectedPrice: row,
      selectedOurExpectedPriceId: row.key,
      modalPaymentTermsShow: true
    });
  };

  discountModal = async row => {
    let lastPrice = row.unitPrice;
    if (Boolean(row.discount)) {
      lastPrice = row.unitPrice - row.discount;
    }
    this.setState(
      {
        selectedOurExpectedPrice: row,
        selectedOurExpectedPriceId: row.key,
        modalDiscountShow: true,
        unitPrice: row.unitPrice
      },
      () => {
        this.formDiscount.current.setFieldsValue({
          ...row,
          lastPrice: lastPrice
        });
      }
    );
  };

  saveDiscount = values => {
    this.setState({ saveLoading: true });
    if (Boolean(this.state.selectedOurExpectedPriceId) && this.state.selectedOurExpectedPriceId > 0) {
      var ourExpectedPrices = this.state.ourExpectedPrices;
      var obj = ourExpectedPrices.find(p => p.key === this.state.selectedOurExpectedPriceId);
      if (Boolean(obj)) {
        obj.unitPrice = values.unitPrice;
        obj.discount = values.discount;
        obj.discountPercent = values.discountPercent;
        this.setState({
          ourExpectedPrices: ourExpectedPrices,
          modalDiscountShow: false
        });
        showSuccess();
        this.setState({ saveLoading: false });
      } else {
        showError("Not found");
        this.setState({ saveLoading: false });
      }
    } else {
      showError("Not found");
      this.setState({ saveLoading: false });
    }
  };

  saveCurrency = values => {
    this.setState({ saveLoading: true });
    if (Boolean(this.state.selectedOurExpectedPriceId) && this.state.selectedOurExpectedPriceId > 0) {
      var ourExpectedPrices = this.state.ourExpectedPrices;
      var obj = ourExpectedPrices.find(p => p.key === this.state.selectedOurExpectedPriceId);
      if (Boolean(obj)) {
        obj.currency = values.currency;
        this.setState({
          ourExpectedPrices: ourExpectedPrices,
          modalCurrencyShow: false
        });
        this.paymentTermsModal(obj);
        showSuccess();
        this.setState({ saveLoading: false });
      } else {
        showError("Not found");
        this.setState({ saveLoading: false });
      }
    } else {
      showError("Not found");
      this.setState({ saveLoading: false });
    }
  };

  async getUnitStcokCard(element) {
    if (Boolean(element.stockCardId) && element.stockCardId > 0) {
      let responsePurchaseCriteria = await handleRequest("GET", "/api/" + this.state.controllerName + "/purchasingcriterias/" + element.stockCardId);
      if (responsePurchaseCriteria.type === "ERROR") {
      } else {
        element.unitSelectItems =
          Boolean(responsePurchaseCriteria.data) && responsePurchaseCriteria.data.length > 0 ? responsePurchaseCriteria.data : [];
      }
    } else {
      element.unitSelectItems = [];
    }
  }

  addNewRow = row => {
    const maxOurExpectedPrice = this.state.maxOurExpectedPrice + 1;
    const newData = [...this.state.ourExpectedPrices];
    const item = newData.find(item => row.key === item.key);
    if (Boolean(item)) {
      let newObj = { row: row };
      newData.push({ ...newObj.row, key: maxOurExpectedPrice });
      this.setState({
        ourExpectedPrices: newData,
        maxOurExpectedPrice: maxOurExpectedPrice
      });
    }
  };

  removeRow = row => {
    if (Boolean(row.key)) {
      this.setState({
        ourExpectedPrices: this.state.ourExpectedPrices.filter(p => p.key !== row.key)
      });
    }
  };

  vendorRepliesCheckList(reply) {
    this.vendorReplyCheck(reply, "generalTerms");
    this.vendorReplyCheck(reply, "techRequirements");
    this.vendorReplyCheck(reply, "workingProperties");
    this.vendorReplyCheck(reply, "pricePaymentInvoicing");
    this.vendorReplyCheck(reply, "paymentTerms");
    this.vendorReplyCheck(reply, "deliveryPlaces");
    this.vendorReplyCheck(reply, "taxes");
    this.vendorReplyCheck(reply, "essenceDeliveryDates");
    this.vendorReplyCheck(reply, "warranties");
    this.vendorReplyCheck(reply, "languages");
    this.vendorReplyCheck(reply, "requestedDocuments");
    this.vendorReplyCheck(reply, "terminations");
    this.vendorReplyCheck(reply, "confidentialities");
    this.vendorReplyCheck(reply, "inspectionAndAcceptances");
    this.vendorReplyCheck(reply, "competentCourt");
    this.vendorReplyCheck(reply, "liabilityInsurances");
    this.vendorReplyCheck(reply, "intellectualProperties");
    this.vendorReplyCheck(reply, "contractRecords");
    this.vendorReplyCheck(reply, "informationPrivacies");
    this.vendorReplyCheck(reply, "miscellaneous");
  }

  vendorReplyCheck(reply, item) {
    if (Boolean(reply[item]) && reply[item].length > 0) {
      reply[item].forEach(element => {
        element.check = true;
      });
    }
  }

  setPaymentTerms = paymentTerms => {
    if (Boolean(this.state.selectedOurExpectedPriceId) && this.state.selectedOurExpectedPriceId > 0) {
      var ourExpectedPrices = this.state.ourExpectedPrices;
      var obj = ourExpectedPrices.find(p => p.key === this.state.selectedOurExpectedPriceId);
      if (Boolean(obj)) {
        obj.paymentTerms = paymentTerms;
        this.setState({
          ourExpectedPrices: ourExpectedPrices,
          modalPaymentTermsShow: false
        });
      } else {
        showError("Not found");
      }
    } else {
      showError("Not found");
    }
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      },
      header: {
        cell: ResizableTitle
      }
    };

    const columnsOurExpectedPrice = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.paginationDeliveryDate.pageSize * (this.state.paginationDeliveryDate.current - 1);
        }
      },
      {
        title: <FormattedMessage id="RfqVendorReply.Item" defaultMessage="Item" />,
        key: "description",
        width: 150,
        ellipsis: true,
        render: record => {
          return (
            <Tooltip
              color={"white"}
              title={
                <div className="w-100" style={{ color: "black" }}>
                  {record.description}
                </div>
              }
            >
              {record.description}
            </Tooltip>
          );
        }
      },
      {
        title: <FormattedMessage id="RfqVendorReply.Quantity" defaultMessage="Quantity" />,
        key: "quantity",
        width: 100,
        render: record => {
          let weight = "";
          if (record.unit && Boolean(record.weight) && Boolean(record.quantity)) {
            if (Boolean(this.state.allUnitSelectItems) && this.state.allUnitSelectItems.length > 0) {
              let unit = this.state.allUnitSelectItems.find(p => p.abbreviation === record.unit);
              if (Boolean(unit.abbreviation) && unit.abbreviation === "ad") {
                weight = record.quantity;
              } else {
                if (unit.unitTypeName === "Length") {
                  if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                    weight = (record.length * record.quantity) / unit.coefficent;
                  } else {
                    weight = record.length * record.quantity;
                  }
                } else {
                  if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                    weight = (record.quantity * record.weight) / unit.coefficent;
                  } else {
                    weight = record.weight * record.quantity;
                  }
                }
              }
            }
          }
          //let quantity = record.quantity;
          let quantity = weight.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");
          if (!Boolean(quantity)) {
            quantity = record.quantity;
          }

          return Boolean(record.oldQuantity) && record.oldQuantity > 0 ? (
            <Badge showZero offset={[25, -15]} overflowCount={record.oldQuantity + 1} count={record.oldQuantity}>
              {quantity}
            </Badge>
          ) : (
            quantity
          );
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "quantity",
          title: <FormattedMessage id="RfqVendorReply.quantity" defaultMessage="Quantity" />,
          handleSave: this.handlePriceListQuantityOurExpectedPrice
        })
      },
      {
        title: <FormattedMessage id="RfqVendorReply.Unit" defaultMessage="Unit" />,
        key: "unit",
        width: 100,
        render: record => {
          let weight = "";
          if (record.unit && Boolean(record.weight) && Boolean(record.quantity)) {
            if (Boolean(this.state.allUnitSelectItems) && this.state.allUnitSelectItems.length > 0) {
              let unit = this.state.allUnitSelectItems.find(p => p.abbreviation === record.unit);
              if (Boolean(unit.abbreviation) && unit.abbreviation === "ad") {
                weight = record.quantity;
              } else {
                if (unit.unitTypeName === "Length") {
                  if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                    weight = (record.length * record.quantity) / unit.coefficent;
                  } else {
                    weight = record.length * record.quantity;
                  }
                } else {
                  if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                    weight = (record.quantity * record.weight) / unit.coefficent;
                  } else {
                    weight = record.weight * record.quantity;
                  }
                }
              }
            }
          }
          // return ((weight.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ')) + " " + record.unit);
          return Boolean(record.unit) ? record.unit : "";
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "unit",
          title: <FormattedMessage id="RfqVendorReply.unit" defaultMessage="Unit" />,
          handleSave: this.handleUnit
        }),
        ...this.getColumnSearchProps("unit"),
        sorter: (a, b) => Boolean(a.unit) && Boolean(b.unit) && a.unit.localeCompare(b.unit)
      },
      {
        width: 100,
        children: [
          {
            title: <FormattedMessage id="RfqVendorReply.UnitPrice" defaultMessage="Unit Price" />,
            key: "unitPrice",
            width: 100,
            render: record => {
              let lastPrice = record.unitPrice;
              if (Boolean(record.discount) && record.discount > 0) {
                lastPrice = record.unitPrice - record.discount;
                return (
                  <Badge showZero offset={[25, -15]} overflowCount={record.unitPrice + 1} count={record.unitPrice}>
                    {lastPrice}
                  </Badge>
                );
              } else {
                return record.unitPrice;
              }
            },
            editable: true,
            onCell: record => ({
              record,
              editable: true,
              dataIndex: "unitPrice",
              title: <FormattedMessage id="RfqVendorReply.unitPrice" defaultMessage="Unit Price" />,
              handleSave: this.handlePriceListUnitPrice
            }),
            ...this.getColumnSearchProps("unitPrice"),
            sorter: (a, b) => Boolean(a.unitPrice) && Boolean(b.unitPrice) && (a.unitPrice + "").localeCompare(b.unitPrice + "")
          },
          {
            title: <FormattedMessage id="RfqVendorReply.Discount" defaultMessage="Discount" />,
            key: "discount",
            width: 100,
            render: record => {
              return (
                <span>
                  <AntButton type="link" onClick={() => this.discountModal(record)} style={{ color: "#1BC5BD" }} icon={<TagOutlined />} />
                </span>
              );
            }
          }
        ]
      },
      {
        title: <FormattedMessage id="RfqVendorReply.Currency" defaultMessage="Currency" />,
        key: "currency",
        width: 100,
        render: record => {
          return record.currency;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "currency",
          title: <FormattedMessage id="RfqVendorReply.currency" defaultMessage="Currency" />,
          handleSave: this.handlePriceListCurrencyOurExpectedPrice
        })
      },
      {
        title: <FormattedMessage id="RfqVendorReply.PaymentTerms" defaultMessage="Payment Terms" />,
        key: "id",
        width: 100,
        render: record => {
          return (
            <AntButton
              onClick={() => this.paymentTermsModal(record)}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<ReplyOutlined fontSize="large" />}
            ></AntButton>
          );
        }
      },
      {
        title: <FormattedMessage id="RfqVendorReply.Price" defaultMessage="Price" />,
        key: "price",
        width: 100,
        render: record => {
          return record.price.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");
        }
      },
      {
        title: <FormattedMessage id="RfqVendorReply.Vat" defaultMessage="Vat(%)" />,
        key: "priceVat",
        width: 100,
        render: record => {
          return record.priceVat;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "priceVat",
          title: <FormattedMessage id="RfqVendorReply.priceVat" defaultMessage="Vat(%)" />,
          handleSave: this.handlePriceListUnitOurExpectedPriceVat
        })
      },
      {
        title: <FormattedMessage id="RfqVendorReply.PlannedDeliveryDate" defaultMessage="Planned Delivery Date" />,
        key: "planedDeliveryDate",
        width: 100,
        render: record => {
          return record.planedDeliveryDate;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "planedDeliveryDate",
          title: <FormattedMessage id="RfqVendorReply.planedDeliveryDate" defaultMessage="Planned Delivery Date" />,
          handleSave: this.handlePriceListPlannedDeliveryDate
        })
      },
      {
        title: <FormattedMessage id="RfqVendorReply.DeliveryPlace" defaultMessage="Delivery Place" />,
        key: "id",
        width: 100,
        render: record => {
          return Boolean(record.town) && Boolean(record.town) && Boolean(record.city) && Boolean(record.city) && record.town + "/" + record.city;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "deliveryPlace",
          title: <FormattedMessage id="RfqVendorReply.DeliveryPlace" defaultMessage="Delivery Place" />,
          handleSave: this.handlePriceListDeliveryPlaceOurExpectedPrice
        })
      },
      {
        key: "id",
        width: 100,
        render: record => {
          return (
            <Space>
              <AntButton
                onClick={() => this.addNewRow(record)}
                style={{ border: "0px", color: "#007bff" }}
                icon={<PlusCircleOutlined fontSize="large" />}
              ></AntButton>

              <AntButton
                onClick={() => this.removeRow(record)}
                style={{ border: "0px", color: "red" }}
                icon={<MinusCircleOutlined fontSize="large" />}
              ></AntButton>
            </Space>
          );
        }
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumnsOurExpectedPrice = [...columnsOurExpectedPrice];

      newColumnsOurExpectedPrice[index] = {
        ...newColumnsOurExpectedPrice[index],
        width: size.width
      };
      this.setState({
        columnsOurExpectedPrice: newColumnsOurExpectedPrice
      });
    };

    const mergeColumnsOurExpectedPrice =
      Boolean(this.state.columnsOurExpectedPrice) && this.state.columnsOurExpectedPrice.length > 0
        ? this.state.columnsOurExpectedPrice.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columnsOurExpectedPrice) &&
        columnsOurExpectedPrice.length > 0 &&
        columnsOurExpectedPrice.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col md={24}>
            <span className="h4">{this.getRfqNo(this.state.requestForQuotation)}</span>
            {Boolean(this.props.vendor) && <span className="h4"> {this.props.vendor.name}</span>}
          </Col>
        </Row>
        {!this.state.disableVendorSelect && (
          <RowBoot style={{ marginTop: "2rem" }}>
            <ColBoot xs={1}>
              <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                <FormattedMessage id="RfqVendorReply.Vendor" defaultMessage="Vendor" />
              </FormBoot.Label>
            </ColBoot>
            <ColBoot xs={4}>
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                style={{ width: "100%" }}
                disabled={this.state.disableVendorSelect}
                value={this.state.vendorId}
                onChange={value => {
                  this.onChangeVendor(this.state.requestForQuotation.id, value);
                }}
              >
                {this.state.vendorsSelectItems.map(i => (
                  <Option key={i.id} value={i.id}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </ColBoot>
          </RowBoot>
        )}

        {Boolean(this.state.vendorReplyList) && this.state.vendorReplyList.length > 0 ? (
          <>
            <br />
            <Table
              bordered
              components={components}
              rowClassName={() => "editable-row"}
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={
                Boolean(mergeColumnsOurExpectedPrice) && mergeColumnsOurExpectedPrice.length > 0
                  ? mergeColumnsOurExpectedPrice
                  : columnsOurExpectedPrice
              }
              dataSource={this.state.ourExpectedPrices}
              loading={this.state.loading}
              pagination={false}
              summary={pageData => {
                let priceSubTotal = 0;
                let priceVatTotal = 0;
                pageData.forEach(({ price, priceVat, currency }) => {
                  if (Boolean(price) && price > 0 && Boolean(currency)) {
                    if (Boolean(price) && price > 0) {
                      priceSubTotal += this.getPriceWtihKur(currency, price);

                      if (Boolean(priceVat) && priceVat > 0) {
                        priceVatTotal += this.getVatWtihKur(currency, price, priceVat);
                      }
                    }
                  }
                });
                var priceTotal = priceSubTotal + priceVatTotal;
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <FormattedMessage id="GeneralButtonSubTotal" defaultMessage="Sub total" />
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text type="danger">
                          {priceSubTotal.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <FormattedMessage id="GeneralButtonVAT" defaultMessage="VAT" />
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <InputNumber
                          readOnly
                          min={0}
                          value={priceVatTotal.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
                        />
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <FormattedMessage id="GeneralButtonTotal" defaultMessage="Total" />
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text type="danger">
                          {priceTotal.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ")}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Select
                          showSearch
                          optionFilterProp="children"
                          style={{ width: "200px" }}
                          value={this.state.currencySelect}
                          onChange={value => {
                            this.setState({
                              currencySelect: value
                            });
                          }}
                        >
                          {Boolean(this.state.currencySelectItems) &&
                            this.state.currencySelectItems.length > 0 &&
                            this.state.currencySelectItems.map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
            <br />
            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Button id="SaveButton" onClick={this.savePrice} style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Col>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            </Row>
          </>
        ) : (
          ""
        )}

        <Modal
          title={
            <FormattedMessage
              id="RfqVendorReply.PricePaymentAndInvoicingDeliveryDates"
              defaultMessage="Price, payment and invoicing/Delivery dates"
            />
          }
          visible={this.state.modalPriceShow}
          onCancel={() => {
            this.setState({ modalPriceShow: false });
          }}
          okButtonProps={{ hidden: true }}
          width={1200}
          centered
        >
          <>
            {Boolean(this.state.vendorReplyList) && this.state.vendorReplyList.length > 0 ? (
              <>
                <RowBoot style={{ marginTop: "2rem" }}>
                  <ColBoot xs={4}>
                    <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                      <FormattedMessage id="RfqVendorReply.Item" defaultMessage="Item" />
                    </FormBoot.Label>
                  </ColBoot>
                  <ColBoot xs={2} className="text-center">
                    <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                      <FormattedMessage id="RfqVendorReply.Mandatory" defaultMessage="Mandatory" />
                    </FormBoot.Label>
                  </ColBoot>
                  <ColBoot xs={3}>
                    <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                      <FormattedMessage id="RfqVendorReply.Reply" defaultMessage="Reply" />
                    </FormBoot.Label>
                  </ColBoot>
                  <ColBoot xs={3}>
                    <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                      <FormattedMessage id="RfqVendorReply.Remarks" defaultMessage="Remarks" />
                    </FormBoot.Label>
                  </ColBoot>
                </RowBoot>
                <Divider style={{ "background-color": "black" }} />

                {Boolean(this.state.vendorReplyList[0].generalTerms) &&
                  this.state.vendorReplyList[0].generalTerms.length > 0 &&
                  this.state.vendorReplyList[0].generalTerms.filter(p => p.check === true).length > 0 &&
                  this.renderElement(
                    this.state.vendorReplyList[0].generalTerms.filter(p => p.check === true),
                    "General Terms and Conditions"
                  )}

                {Boolean(this.state.vendorReplyList[0].techRequirements) &&
                  this.state.vendorReplyList[0].techRequirements.length > 0 &&
                  this.state.vendorReplyList[0].techRequirements.filter(p => p.check === true).length > 0 &&
                  this.renderElement(
                    this.state.vendorReplyList[0].techRequirements.filter(p => p.check === true),
                    "Technical Requirements Checklist"
                  )}

                {Boolean(this.state.vendorReplyList[0].workingProperties) &&
                  this.state.vendorReplyList[0].workingProperties.length > 0 &&
                  this.state.vendorReplyList[0].workingProperties.filter(p => p.check === true).length > 0 &&
                  this.renderElement(
                    this.state.vendorReplyList[0].workingProperties.filter(p => p.check === true),
                    "Working on (company name) property"
                  )}

                {/* {Boolean(this.state.vendorReplyList[0].pricePaymentInvoicing) &&
              this.state.vendorReplyList[0].pricePaymentInvoicing.length > 0 &&
              this.state.vendorReplyList[0].pricePaymentInvoicing.filter(p => p.check === true).length > 0 &&
              this.renderElement(
                this.state.vendorReplyList[0].pricePaymentInvoicing.filter(p => p.check === true),
                "Price, payment and invoicing"
              )}

            {Boolean(this.state.vendorReplyList[0].paymentTerms) &&
              this.state.vendorReplyList[0].paymentTerms.length > 0 &&
              this.state.vendorReplyList[0].paymentTerms.filter(p => p.check === true).length > 0 &&
              this.renderElement(
                this.state.vendorReplyList[0].paymentTerms.filter(p => p.check === true),
                "Payment Terms"
              )}

            {Boolean(this.state.vendorReplyList[0].deliveryPlaces) &&
              this.state.vendorReplyList[0].deliveryPlaces.length > 0 &&
              this.state.vendorReplyList[0].deliveryPlaces.filter(p => p.check === true).length > 0 &&
              this.renderElement(
                this.state.vendorReplyList[0].deliveryPlaces.filter(p => p.check === true),
                "Delivery Place"
              )} */}

                {Boolean(this.state.vendorReplyList[0].taxes) &&
                  this.state.vendorReplyList[0].taxes.length > 0 &&
                  this.state.vendorReplyList[0].taxes.filter(p => p.check === true).length > 0 &&
                  this.renderElement(
                    this.state.vendorReplyList[0].taxes.filter(p => p.check === true),
                    "Taxes"
                  )}

                {Boolean(this.state.vendorReplyList[0].essenceDeliveryDates) &&
                  this.state.vendorReplyList[0].essenceDeliveryDates.length > 0 &&
                  this.state.vendorReplyList[0].essenceDeliveryDates.filter(p => p.check === true).length > 0 &&
                  this.renderElement(
                    this.state.vendorReplyList[0].essenceDeliveryDates.filter(p => p.check === true),
                    "Time of essence/Delivery dates"
                  )}

                {Boolean(this.state.vendorReplyList[0].warranties) &&
                  this.state.vendorReplyList[0].warranties.length > 0 &&
                  this.state.vendorReplyList[0].warranties.filter(p => p.check === true).length > 0 &&
                  this.renderElement(
                    this.state.vendorReplyList[0].warranties.filter(p => p.check === true),
                    "Warranty"
                  )}

                {Boolean(this.state.vendorReplyList[0].languages) &&
                  this.state.vendorReplyList[0].languages.length > 0 &&
                  this.state.vendorReplyList[0].languages.filter(p => p.check === true).length > 0 &&
                  this.renderElement(
                    this.state.vendorReplyList[0].languages.filter(p => p.check === true),
                    "Language"
                  )}

                {Boolean(this.state.vendorReplyList[0].requestedDocuments) &&
                  this.state.vendorReplyList[0].requestedDocuments.length > 0 &&
                  this.state.vendorReplyList[0].requestedDocuments.filter(p => p.check === true).length > 0 &&
                  this.renderElement(
                    this.state.vendorReplyList[0].requestedDocuments.filter(p => p.check === true),
                    "Requested documents"
                  )}

                {Boolean(this.state.vendorReplyList[0].terminations) &&
                  this.state.vendorReplyList[0].terminations.length > 0 &&
                  this.state.vendorReplyList[0].terminations.filter(p => p.check === true).length > 0 &&
                  this.renderElement(
                    this.state.vendorReplyList[0].terminations.filter(p => p.check === true),
                    "Termination"
                  )}

                {Boolean(this.state.vendorReplyList[0].confidentialities) &&
                  this.state.vendorReplyList[0].confidentialities.length > 0 &&
                  this.state.vendorReplyList[0].confidentialities.filter(p => p.check === true).length > 0 &&
                  this.renderElement(
                    this.state.vendorReplyList[0].confidentialities.filter(p => p.check === true),
                    "Confidentiality, privacy and publicity"
                  )}

                {Boolean(this.state.vendorReplyList[0].inspectionAndAcceptances) &&
                  this.state.vendorReplyList[0].inspectionAndAcceptances.length > 0 &&
                  this.state.vendorReplyList[0].inspectionAndAcceptances.filter(p => p.check === true).length > 0 &&
                  this.renderElement(
                    this.state.vendorReplyList[0].inspectionAndAcceptances.filter(p => p.check === true),
                    "Inspection and acceptance"
                  )}

                {Boolean(this.state.vendorReplyList[0].competentCourt) &&
                  this.state.vendorReplyList[0].competentCourt.length > 0 &&
                  this.state.vendorReplyList[0].competentCourt.filter(p => p.check === true).length > 0 &&
                  this.renderElement(
                    this.state.vendorReplyList[0].competentCourt.filter(p => p.check === true),
                    "Governing law and competent court"
                  )}

                {Boolean(this.state.vendorReplyList[0].liabilityInsurances) &&
                  this.state.vendorReplyList[0].liabilityInsurances.length > 0 &&
                  this.state.vendorReplyList[0].liabilityInsurances.filter(p => p.check === true).length > 0 &&
                  this.renderElement(
                    this.state.vendorReplyList[0].liabilityInsurances.filter(p => p.check === true),
                    "Liability and insurance"
                  )}

                {Boolean(this.state.vendorReplyList[0].intellectualProperties) &&
                  this.state.vendorReplyList[0].intellectualProperties.length > 0 &&
                  this.state.vendorReplyList[0].intellectualProperties.filter(p => p.check === true).length > 0 &&
                  this.renderElement(
                    this.state.vendorReplyList[0].intellectualProperties.filter(p => p.check === true),
                    "Intellectual property"
                  )}

                {Boolean(this.state.vendorReplyList[0].contractRecords) &&
                  this.state.vendorReplyList[0].contractRecords.length > 0 &&
                  this.state.vendorReplyList[0].contractRecords.filter(p => p.check === true).length > 0 &&
                  this.renderElement(
                    this.state.vendorReplyList[0].contractRecords.filter(p => p.check === true),
                    "Contract records"
                  )}

                {Boolean(this.state.vendorReplyList[0].informationPrivacies) &&
                  this.state.vendorReplyList[0].informationPrivacies.length > 0 &&
                  this.state.vendorReplyList[0].informationPrivacies.filter(p => p.check === true).length > 0 &&
                  this.renderElement(
                    this.state.vendorReplyList[0].informationPrivacies.filter(p => p.check === true),
                    "Freedom of information and privacy"
                  )}

                {Boolean(this.state.vendorReplyList[0].miscellaneous) &&
                  this.state.vendorReplyList[0].miscellaneous.length > 0 &&
                  this.state.vendorReplyList[0].miscellaneous.filter(p => p.check === true).length > 0 &&
                  this.renderElement(
                    this.state.vendorReplyList[0].miscellaneous.filter(p => p.check === true),
                    "Miscellaneous"
                  )}
                {Boolean(this.state.vendorReplyList[0].additionsList) &&
                  this.state.vendorReplyList[0].additionsList.length > 0 &&
                  this.state.vendorReplyList[0].additionsList.map(p => {
                    return this.renderElement(
                      p.rfqModelList.filter(p => p.check === true),
                      p.description
                    );
                  })}
                <br />
                <div hidden={this.state.hideSave}>
                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <Spin spinning={this.state.saveLoading}>
                          <Button id="SaveButton" onClick={this.save} style={{ width: "100%" }} variant="success">
                            <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                          </Button>
                        </Spin>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </div>
              </>
            ) : (
              ""
            )}
          </>
        </Modal>

        <Modal
          open={this.state.modalPaymentTermsShow}
          width={1800}
          centered
          keyboard={false}
          maskClosable={false}
          closable={false}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
        >
          <RfqReplyPaymentTerms
            selectedOurExpectedPrice={this.state.selectedOurExpectedPrice}
            requestForQuotationId={this.state.requestForQuotationId}
            random={Math.random()}
            setPaymentTerms={this.setPaymentTerms}
          ></RfqReplyPaymentTerms>
        </Modal>

        <Modal
          open={this.state.modalCurrencyShow}
          onCancel={() => {
            this.setState({ modalCurrencyShow: false });
          }}
          width={1000}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalCurrencyShow: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
        >
          <Badge.Ribbon
            text={<FormattedMessage id="RfqVendorReply.Currency" defaultMessage="Currency" />}
            placement="start"
            color="purple"
            style={{ fontSize: 12 }}
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveCurrency} ref={this.formCurrency}>
              <br />
              <Form.Item {...layout} label={<FormattedMessage id="RfqVendorReply.currency" defaultMessage="Currency" />} name="currency">
                <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                  {Boolean(this.state.currencySelectItems) &&
                    this.state.currencySelectItems.length > 0 &&
                    this.state.currencySelectItems.map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <br />
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Spin spinning={this.state.saveLoading}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Spin>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            </Form>
          </Badge.Ribbon>
        </Modal>

        <Modal
          title={
            <FormattedMessage
              id="RfqVendorReply.PricePaymentAndInvoicingDeliveryDates"
              defaultMessage="Price, payment and invoicing/Delivery dates"
            />
          }
          open={this.state.modalPricePaymentDeliveryShow}
          onCancel={() => {
            this.setState({ modalPricePaymentDeliveryShow: false });
          }}
          width={1200}
          centered
        >
          <Table
            bordered
            components={components}
            rowClassName={() => "editable-row"}
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={
              Boolean(mergeColumnsOurExpectedPrice) && mergeColumnsOurExpectedPrice.length > 0
                ? mergeColumnsOurExpectedPrice
                : columnsOurExpectedPrice
            }
            dataSource={this.state.ourExpectedPrices}
            loading={this.state.loading}
            pagination={false}
            summary={pageData => {
              let priceSubTotal = 0;
              let priceVatTotal = 0;
              pageData.forEach(({ price, priceVat, currency }) => {
                if (Boolean(price) && price > 0 && Boolean(currency)) {
                  if (Boolean(price) && price > 0) {
                    priceSubTotal += this.getPriceWtihKur(currency, price);

                    if (Boolean(priceVat) && priceVat > 0) {
                      priceVatTotal += this.getVatWtihKur(currency, price, priceVat);
                    }
                  }
                }
              });
              var priceTotal = priceSubTotal + priceVatTotal;
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <FormattedMessage id="GeneralButtonSubTotal" defaultMessage="Sub total" />
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="danger">{priceSubTotal.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <FormattedMessage id="GeneralButtonVAT" defaultMessage="VAT" />
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <InputNumber readOnly min={0} value={priceVatTotal.toFixed(2)} />
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <FormattedMessage id="GeneralButtonTotal" defaultMessage="Total" />
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="danger">{priceTotal.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        style={{ width: "200px" }}
                        value={this.state.currencySelect}
                        onChange={value => {
                          this.setState({
                            currencySelect: value
                          });
                        }}
                      >
                        {Boolean(this.state.currencySelectItems) &&
                          this.state.currencySelectItems.length > 0 &&
                          this.state.currencySelectItems.map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Modal>

        <Modal
          open={this.state.modalDiscountShow}
          onCancel={() => {
            this.setState({ modalDiscountShow: false });
          }}
          width={800}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalDiscountShow: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
        >
          <Badge.Ribbon
            text={<FormattedMessage id="RfqVendorReply.Discount" defaultMessage="Discount" />}
            placement="start"
            color="purple"
            style={{ fontSize: 12 }}
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveDiscount} ref={this.formDiscount}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="RfqVendorReply.unitPrice" defaultMessage="Unit Price" />}
                name="unitPrice"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber
                  parser={value => value.replace(",", ".")}
                  formatter={value => value.replace(".", ",")}
                  onChange={value => {
                    this.setState(
                      {
                        unitPrice: value,
                        modalCurrencyShow: true
                      },
                      () => {
                        this.formCurrency.current.setFieldsValue({
                          ...this.state.selectedOurExpectedPrice
                        });
                      }
                    );
                  }}
                ></InputNumber>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="RfqVendorReply.discount" defaultMessage="Discount" />}
                name="discount"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber
                  parser={value => value.replace(",", ".")}
                  formatter={value => value.replace(".", ",")}
                  max={this.state.unitPrice}
                  onChange={value => {
                    this.setState(
                      {
                        discount: value
                      },
                      () => {
                        const result = (parseFloat(value) / parseFloat(this.state.unitPrice)) * 100;
                        this.formDiscount.current.setFieldsValue({
                          discountPercent: result.toFixed(2),
                          lastPrice: this.state.unitPrice - value
                        });
                      }
                    );
                  }}
                ></InputNumber>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="RfqVendorReply.discountPercent" defaultMessage="Discount(%)" />}
                name="discountPercent"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber
                  parser={value => value.replace(",", ".")}
                  formatter={value => value.replace(".", ",")}
                  min={0}
                  max={100}
                  onChange={value => {
                    this.setState(
                      {
                        discountPercent: value
                      },
                      () => {
                        const result = (parseFloat(value) * parseFloat(this.state.unitPrice)) / 100;
                        this.formDiscount.current.setFieldsValue({
                          discount: result.toFixed(2),
                          lastPrice: this.state.unitPrice - result
                        });
                      }
                    );
                  }}
                ></InputNumber>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="RfqVendorReply.lastPrice" defaultMessage="Last Price" />}
                name="lastPrice"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber readOnly></InputNumber>
              </Form.Item>
              <br />
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Spin spinning={this.state.saveLoading}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Spin>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            </Form>
          </Badge.Ribbon>
        </Modal>

        <Modal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="RfqVendorReply.PaymentTermsInformationIsBlank" defaultMessage="Payment Terms information is blank !" />
              </span>
            </span>
          }
          centered
          open={this.state.modalPaymentTermsErrorShow}
          onOk={() => this.savePriceAgain()}
          onCancel={() => this.setState({ modalPaymentTermsErrorShow: false })}
          okText={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />}
          cancelText={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />}
        >
          {Boolean(this.state.paymentTermsErrorItem) && this.state.paymentTermsErrorItem.length > 0
            ? this.state.paymentTermsErrorItem.map((item, index) => <div key={index}>{item}</div>)
            : ""}
          <br />
          <br />
          <FormattedMessage id="RfqVendorReply.MessageDoYouWantToContinue?" defaultMessage="Do you want to continue ?" />
        </Modal>
      </div>
    );
  }

  getPriceWtihKur(currency, price) {
    let { dollarKur, euroKur, poundKur, srKur } = this.getKurValue();
    let priceSubTotal = 0;
    if (currency === "DOLLAR") {
      if (this.state.currencySelect === "DOLLAR") {
        priceSubTotal += price;
      } else if (this.state.currencySelect === "EURO") {
        priceSubTotal += price * (dollarKur / euroKur);
      } else if (this.state.currencySelect === "POUND") {
        priceSubTotal += price * (dollarKur / poundKur);
      } else if (this.state.currencySelect === "SR") {
        priceSubTotal += price * (dollarKur / srKur);
      } else if (this.state.currencySelect === "TL") {
        priceSubTotal += price * dollarKur;
      }
    } else if (currency === "EURO") {
      if (this.state.currencySelect === "EURO") {
        priceSubTotal += price;
      } else if (this.state.currencySelect === "DOLLAR") {
        priceSubTotal += price * (euroKur / dollarKur);
      } else if (this.state.currencySelect === "POUND") {
        priceSubTotal += price * (euroKur / poundKur);
      } else if (this.state.currencySelect === "SR") {
        priceSubTotal += price * (euroKur / srKur);
      } else if (this.state.currencySelect === "TL") {
        priceSubTotal += price * euroKur;
      }
    } else if (currency === "POUND") {
      if (this.state.currencySelect === "POUND") {
        priceSubTotal += price;
      } else if (this.state.currencySelect === "EURO") {
        priceSubTotal += price * (poundKur / euroKur);
      } else if (this.state.currencySelect === "DOLLAR") {
        priceSubTotal += price * (poundKur / dollarKur);
      } else if (this.state.currencySelect === "SR") {
        priceSubTotal += price * (poundKur / srKur);
      } else if (this.state.currencySelect === "TL") {
        priceSubTotal += price * poundKur;
      }
    } else if (currency === "SR") {
      if (this.state.currencySelect === "SR") {
        priceSubTotal += price;
      } else if (this.state.currencySelect === "POUND") {
        priceSubTotal += price * (srKur / poundKur);
      } else if (this.state.currencySelect === "EURO") {
        priceSubTotal += price * (srKur / euroKur);
      } else if (this.state.currencySelect === "DOLLAR") {
        priceSubTotal += price * (srKur / dollarKur);
      } else if (this.state.currencySelect === "TL") {
        priceSubTotal += price * srKur;
      }
    } else if (currency === "TL") {
      if (this.state.currencySelect === "TL") {
        priceSubTotal += price;
      } else if (this.state.currencySelect === "SR") {
        priceSubTotal += price * (1 / srKur);
      } else if (this.state.currencySelect === "POUND") {
        priceSubTotal += price * (1 / poundKur);
      } else if (this.state.currencySelect === "EURO") {
        priceSubTotal += price * (1 / euroKur);
      } else if (this.state.currencySelect === "DOLLAR") {
        priceSubTotal += price * (1 / dollarKur);
      }
    } else {
      return 0;
    }
    return priceSubTotal;
  }

  getVatWtihKur(currency, price, priceVat) {
    let { dollarKur, euroKur, poundKur, srKur } = this.getKurValue();
    let priceVatTotal = 0;
    if (currency === "DOLLAR") {
      if (this.state.currencySelect === "DOLLAR") {
        priceVatTotal += (priceVat * price) / 100;
      } else if (this.state.currencySelect === "EURO") {
        priceVatTotal += (priceVat * (price * (dollarKur / euroKur))) / 100;
      } else if (this.state.currencySelect === "POUND") {
        priceVatTotal += (priceVat * (price * (dollarKur / poundKur))) / 100;
      } else if (this.state.currencySelect === "SR") {
        priceVatTotal += (priceVat * (price * (dollarKur / srKur))) / 100;
      } else if (this.state.currencySelect === "TL") {
        priceVatTotal += (priceVat * (price * dollarKur)) / 100;
      }
    } else if (currency === "EURO") {
      if (this.state.currencySelect === "EURO") {
        priceVatTotal += (priceVat * price) / 100;
      } else if (this.state.currencySelect === "DOLLAR") {
        priceVatTotal += (priceVat * (price * (euroKur / dollarKur))) / 100;
      } else if (this.state.currencySelect === "POUND") {
        priceVatTotal += (priceVat * (price * (euroKur / poundKur))) / 100;
      } else if (this.state.currencySelect === "SR") {
        priceVatTotal += (priceVat * (price * (euroKur / srKur))) / 100;
      } else if (this.state.currencySelect === "TL") {
        priceVatTotal += (priceVat * (price * euroKur)) / 100;
      }
    } else if (currency === "POUND") {
      if (this.state.currencySelect === "POUND") {
        priceVatTotal += (priceVat * price) / 100;
      } else if (this.state.currencySelect === "EURO") {
        priceVatTotal += (priceVat * (price * (poundKur / euroKur))) / 100;
      } else if (this.state.currencySelect === "DOLLAR") {
        priceVatTotal += (priceVat * (price * (poundKur / dollarKur))) / 100;
      } else if (this.state.currencySelect === "SR") {
        priceVatTotal += (priceVat * (price * (poundKur / srKur))) / 100;
      } else if (this.state.currencySelect === "TL") {
        priceVatTotal += (priceVat * (price * poundKur)) / 100;
      }
    } else if (currency === "SR") {
      if (this.state.currencySelect === "SR") {
        priceVatTotal += (priceVat * price) / 100;
      } else if (this.state.currencySelect === "POUND") {
        priceVatTotal += (priceVat * (price * (srKur / poundKur))) / 100;
      } else if (this.state.currencySelect === "EURO") {
        priceVatTotal += (priceVat * (price * (srKur / euroKur))) / 100;
      } else if (this.state.currencySelect === "DOLLAR") {
        priceVatTotal += (priceVat * (price * (srKur / dollarKur))) / 100;
      } else if (this.state.currencySelect === "TL") {
        priceVatTotal += (priceVat * (price * srKur)) / 100;
      }
    } else if (currency === "TL") {
      if (this.state.currencySelect === "TL") {
        priceVatTotal += (priceVat * price) / 100;
      } else if (this.state.currencySelect === "SR") {
        priceVatTotal += (priceVat * (price * (1 / srKur))) / 100;
      } else if (this.state.currencySelect === "POUND") {
        priceVatTotal += (priceVat * (price * (1 / poundKur))) / 100;
      } else if (this.state.currencySelect === "EURO") {
        priceVatTotal += (priceVat * (price * (1 / euroKur))) / 100;
      } else if (this.state.currencySelect === "DOLLAR") {
        priceVatTotal += (priceVat * (price * (1 / dollarKur))) / 100;
      }
    } else {
      return 0;
    }
    return priceVatTotal;
  }

  getKurValue() {
    let dollarKur = 0,
      euroKur = 0,
      poundKur = 0,
      srKur = 0;
    if (Boolean(this.state.kur) && Boolean(this.state.kur.children) && this.state.kur.children.length > 0) {
      this.state.kur.children.forEach(element => {
        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "USD") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            dollarKur = obj.value;
          }
        }

        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "EUR") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            euroKur = obj.value;
          }
        }

        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "GBP") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            poundKur = obj.value;
          }
        }

        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "SAR") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            srKur = obj.value;
          }
        }
      });
    }
    return { dollarKur, euroKur, poundKur, srKur };
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RfqVendorReply);
