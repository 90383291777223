import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import { showError, error, showInfo } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Select, Space, Table, Typography, Breadcrumb, InputNumber, Tree } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FcFolder, FcEditImage, FcFile } from "react-icons/fc";
import { FaFileAudio, FaRegFile } from "react-icons/fa";
import { Menu, Item, useContextMenu } from "react-contexify";
import { FcTreeStructure, FcEmptyBattery, FcLibrary } from "react-icons/fc";
import ProjectsTreeCompanyRoomQuantity from "./ProjectsTreeCompanyRoomQuantity";
import { fillFilterOperations } from "../../../Components/DataTableFilterComponent";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import DraggableModal from "../../../Components/DraggableModal";
import ProjectsTreeCompanyWindowQuantity from "./ProjectsTreeCompanyWindowQuantity";

const { Text } = Typography;

const Menu_Room_Quantity = "Menu_Room_Quantity";
const { show } = useContextMenu();
const { Option } = Select;

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

export class ProjectsTreeCompanyQuantity extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ProjectsTreeCompanyQuantity",
      controllerName: "apartment",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      houseRoomList: [],
      quantityDetailList: [],
      loadingButtonSubmit: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      currentRow: 0,
      currentWallRow: 0,
      currentContainRow: 0,
      treeVisibleModal: true,
      wallSelectItems: [],
      roomTypeName: "",
      sameLayoutList: [],
    };
  }

  formRefLayout = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    this.fillTree();
    fillFilterOperations();
  };

  fillComboboxes = async () => {
    this.fillApartmentTypes("Apartment.ApartmentType");
    this.fillTypeOfHouses("Apartment.TypeOfHouses");

    var responseUnitId = await handleRequest("GET", "/api/" + this.state.controllerName + "/unitId");
    if (responseUnitId.type === "ERROR") {
      error(responseUnitId);
    }

    var responseMainStructure = await handleRequest("GET", "/api/" + this.state.controllerName + "/mainStructure");
    if (responseMainStructure.type === "ERROR") {
      error(responseMainStructure);
    }

    var responseCover = await handleRequest("GET", "/api/" + this.state.controllerName + "/cover");
    if (responseCover.type === "ERROR") {
      error(responseCover);
    }

    var responseFinishing = await handleRequest("GET", "/api/" + this.state.controllerName + "/finishing");
    if (responseFinishing.type === "ERROR") {
      error(responseFinishing);
    }

    this.setState({
      unitIdSelectItems: Boolean(responseUnitId.data) ? responseUnitId.data : [],
      mainStructureSelectItems: Boolean(responseMainStructure.data) ? responseMainStructure.data : [],
      coverSelectItems: Boolean(responseCover.data) ? responseCover.data : [],
      finishingSelectItems: Boolean(responseFinishing.data) ? responseFinishing.data : [],
    });
  };

  fillTypeOfHouses = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      }, () => {
        this.fillApartmentsWithHouseNotNull();
      });
    }
  };

  fillApartmentsWithHouseNotNull = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/apartmentsWithHouseNotNull");
    if (response.type === "ERROR") {
      error(response);
    }

    this.setState({
      apartmentsWithHouseNotNull: Boolean(response.data) ? response.data : [],
    });
  };

  fillItemId = async (mainStructure) => {
    if (Boolean(mainStructure)) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemId/" + mainStructure);
      if (response.type === "ERROR") {
        error(response);
      }

      this.setState({
        itemIdSelectItems: Boolean(response.data) ? response.data : [],
      });
    } else {
      this.setState({
        itemIdSelectItems: [],
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/apartmentsWithQuantity";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: "",
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder,
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: "",
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  resetInputs = () => {

  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillApartmentTypes = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data,
        currentApartmentTypeSelectItems: response.data,
      });
    }
  };

  roomQuantityModalClose = () => {
    this.setState({
      treeVisibleModal: true,
      roomQuantityModal: false,
    });
  };

  onRightClick = (event, node) => {
    let parentNode = this.getParentNode(node);
    this.setState(
      {
        selectedNodeKey: node.key,
        selectedNode: node,
        parentNode: parentNode
      },
      this.displayMenu(event, Boolean(node) && Boolean(node.type) ? node.type : null)
    );
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  displayMenu = (e, type) => {
    console.log("type", type);
    if (type === "Contain") {
      show(e, { id: Menu_Room_Quantity });
    }
  };

  handleItemClick = ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;
    console.log("selectedNode", selectedNode);
    console.log("parentNode", parentNode);
    switch (event.currentTarget.id) {
      case "create_room_quantity":
        this.setState({
          roomQuantityModal: true,
          treeVisibleModal: false,
          roomTypeId: selectedNode.roomTypeId,
          houseTypeId: selectedNode.houseTypeId,
          roomQuantityId: selectedNode.id,
          roomUnit: parentNode.title,
          contain: selectedNode.title,
          random: Math.random(),
        });
        break;
      default:
    }
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true,
        houseTypeSelectItems: []
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        let houseTypeSelectItems = [];
        response.data.forEach(element => {
          element.id = element.key;
          element.key = Math.random();
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "ProjectName";
          }

          let houseType = houseTypeSelectItems.find(p => p.houseTypeId === element.id);
          if (!Boolean(houseType)) {
            houseTypeSelectItems.push({ "houseTypeName": element.title, "houseTypeId": element.id, });
          }
        });

        this.setState({
          // nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true,
          houseTypeSelectItems: houseTypeSelectItems,
        });
      } else {
        this.setState({
          refreshTree: true,
          houseTypeSelectItems: []
        });
      }
    }
  };

  fillTreeByHouseTypeId = async (houseTypeId) => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillTreeByHouseTypeId/" + houseTypeId);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true,
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.key = Math.random();
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "ProjectName";
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true,
        });
      }
    }
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "ProjectName",
      houseTypeId: Boolean(treenode.houseTypeId) ? treenode.houseTypeId : 0,
    };

    var response = null;
    if (Boolean(treenode.key) && obj.type !== "Contain") {
      response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsLayout/" + obj.id + "/" + obj.type + "/" + obj.houseTypeId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
          const data = response.data;
          data.forEach(element => {
            if (element.projectName === "Apartment") {
              element.type = "ApartmentRoot";
            }
            element.icon =
              Boolean(element.type) && element.type === "ProjectName" ? (
                <FcFile />
              ) : element.type === "Project" ? (
                <FaRegFile />
              ) : element.type === "ProjectChild" ? (
                <FaFileAudio />
              ) : element.type === "ApartmentRoot" ? (
                <FcLibrary />
              ) : element.type === "Contain" && element.hasRoomQuantity ? (
                < FcTreeStructure />
              ) : element.type === "Contain" ? (
                <FcEmptyBattery />
              ) : (
                <FcFolder />
              );
            element.title = element.projectName;
            element.key = Math.random();
          });

          var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
          this.setState({
            nodes: res,
            refreshTree: true
          });
        }
      }
    }
  };

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  restartNewHouseType = async () => {
    const { currentRow, houseTypeSelectItems } = this.state;

    if (Boolean(houseTypeSelectItems) && houseTypeSelectItems.length > 0 && houseTypeSelectItems.length > currentRow) {
      let row = houseTypeSelectItems[currentRow];
      this.setState({
        houseTypeId: row.houseTypeId,
        houseTypeName: row.houseTypeName,
      }, () => {
        this.fillWallSelection();
      });

      // this.formRefApartmentLayout.current.resetFields();
      // let houseTypeId = row.houseTypeId;
      // this.fillApartmentLayout(houseTypeId);
      // var responseDivision = await handleRequest("GET", "/api/" + this.state.controllerName + "/getRoomByHouseType/" + houseTypeId);
      // if (responseDivision.type === "ERROR") {
      //   error(responseDivision);
      // } else {
      //   this.setState({
      //     divisionSelectItems: Boolean(responseDivision.data) ? responseDivision.data : [],
      //     houseTypeId: houseTypeId,
      //     houseTypeName: row.houseTypeName,
      //   });
      // }
    } else {
      // this.props.nextTab();
      // this.formRefApartmentLayout.current.resetFields();
    }
  }

  restartNewWall = async () => {
    const { currentWallRow, wallSelectItems } = this.state;

    if (Boolean(wallSelectItems) && wallSelectItems.length > 0 && wallSelectItems.length > currentWallRow) {
      let row = wallSelectItems[currentWallRow];
      this.setState({
        wall: row.wall,
        roomTypeName: row.roomTypeName,
      });

      // this.formRefApartmentLayout.current.resetFields();
      // let houseTypeId = row.houseTypeId;
      // this.fillApartmentLayout(houseTypeId);
      // var responseDivision = await handleRequest("GET", "/api/" + this.state.controllerName + "/getRoomByHouseType/" + houseTypeId);
      // if (responseDivision.type === "ERROR") {
      //   error(responseDivision);
      // } else {
      //   this.setState({
      //     divisionSelectItems: Boolean(responseDivision.data) ? responseDivision.data : [],
      //     houseTypeId: houseTypeId,
      //     houseTypeName: row.houseTypeName,
      //   });
      // }
    } else {
      // this.props.nextTab();
      // this.formRefApartmentLayout.current.resetFields();
    }
  }

  fillDiscipline = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/discipline");
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        disciplineIdSelectItems: Boolean(response.data) ? response.data : []
      });
    }
  };

  fillWallSelection = async () => {
    const { houseTypeId, houseTypeSelectItems, currentRow } = this.state;
    let row = houseTypeSelectItems[currentRow];

    if (Boolean(houseTypeId) && houseTypeId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillWall/" + houseTypeId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        row.wallSelectItems = Boolean(response.data) ? response.data : []
      }
    } else {
      row.wallSelectItems = [];
    }

    this.setState({
      houseTypeSelectItems: houseTypeSelectItems
    });

  };

  fillWallContain = async () => {
    const { periferyWallId } = this.state;

    if (Boolean(periferyWallId) && periferyWallId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillContains/" + periferyWallId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          containSelectItems: Boolean(response.data) ? response.data : []
        });
      }
    } else {
      this.setState({
        containSelectItems: []
      });
    }
  };

  restartNewContain = async () => {
    const { currentContainRow, containSelectItems } = this.state;

    if (Boolean(containSelectItems) && containSelectItems.length > 0 && containSelectItems.length > currentContainRow) {
      let row = containSelectItems[currentContainRow];
      this.setState({
        containName: row.value,
        apartmentPeriferyWallContainId: row.key,
      }, async () => {
        const { apartmentPeriferyWallContainId } = this.state;

        if (Boolean(apartmentPeriferyWallContainId) && apartmentPeriferyWallContainId > 0) {
          var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getLayout/" + apartmentPeriferyWallContainId);
          if (response.type === "ERROR") {
            error(response);
          } else {
            if (Boolean(response.data)) {
              let data = response.data;

              await this.fillItemId(data.mainStructure);

              let quantityOutside = [{
                "name": "Main Structure",
                "mainStructure": data.mainStructure,
                "mainStructureItemId": Boolean(data.mainStructureItemId) && data.mainStructureItemId > 0 ? parseInt(data.mainStructureItemId, 10) : null,
              }];

              quantityOutside.push({
                "name": "Cover",
                "coverThickness": data.coverThickness,
                "coverOutSide": data.coverOutSide,
                "coverUnitId": data.coverUnitId,
                "coverRoom": data.coverRoom,
                "coverThicknessRoom": data.coverThicknessRoom,
                "coverUnitIdRoom": data.coverUnitIdRoom,
              });

              quantityOutside.push({
                "name": "Finishing",
                "finishingThickness": data.finishingThickness,
                "finishingUnitId": data.finishingUnitId,
                "finishingOutSide": data.finishingOutSide,
                "finishingRoom": data.finishingRoom,
                "finishingThicknessRoom": data.finishingThicknessRoom,
                "finishingUnitIdRoom": data.finishingUnitIdRoom,
              });
              this.formRefLayout.current.setFieldsValue({
                discipline: data.discipline,
                quantityOutside: quantityOutside,
              });
            } else {
              this.fillItemId("");

              let quantityOutside = [{
                "name": "Main Structure",
              }];

              quantityOutside.push({
                "name": "Cover",
              });

              quantityOutside.push({
                "name": "Finishing",
              });
              this.formRefLayout.current.setFieldsValue({
                discipline: null,
                quantityOutside: quantityOutside,
              });
            }
          }
        } else {
          this.formRefLayout.current.resetFields();
        }
      });
    } else {
      // this.props.nextTab();
    }
  }

  saveLayout = async values => {
    const { apartmentPeriferyWallContainId, currentContainRow } = this.state;

    if (currentContainRow === 0) {
      this.setState({
        modalCoversShow: true,
        saveLayoutValues: values,
      });
    } else {
      if (Boolean(apartmentPeriferyWallContainId) && apartmentPeriferyWallContainId > 0) {
        let newObj = {
          apartmentPeriferyWallContainId: apartmentPeriferyWallContainId,
          discipline: values.discipline,
          mainStructure: values.quantityOutside[0].mainStructure,
          mainStructureItemId: values.quantityOutside[0].mainStructureItemId,

          coverOutSide: values.quantityOutside[1].coverOutSide,
          coverRoom: values.quantityOutside[1].coverRoom,
          coverThickness: values.quantityOutside[1].coverThickness,
          coverThicknessRoom: values.quantityOutside[1].coverThicknessRoom,
          coverUnitId: values.quantityOutside[1].coverUnitId,
          coverUnitIdRoom: values.quantityOutside[1].coverUnitIdRoom,

          finishingOutSide: values.quantityOutside[2].finishingOutSide,
          finishingRoom: values.quantityOutside[2].finishingRoom,
          finishingThickness: values.quantityOutside[2].finishingThickness,
          finishingThicknessRoom: values.quantityOutside[2].finishingThicknessRoom,
          finishingUnitId: values.quantityOutside[2].finishingUnitId,
          finishingUnitIdRoom: values.quantityOutside[2].finishingUnitIdRoom,
        }

        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveLayout", newObj);
        if (response.type === "ERROR") {
          error(response);
        }
        else {
          this.setState({
            currentContainRow: currentContainRow + 1,
          }, () => {
            this.restartNewContain();
          });
        }
      } else {
        showInfo("Apartment Perifery Wall Contain not found.");
      }
    }
  }

  fillSameLayoutList = async () => {
    const { houseTypeId } = this.state;
    let sameLayoutList = [];
    if (Boolean(houseTypeId) && houseTypeId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillSameLayoutList/" + houseTypeId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        sameLayoutList = Boolean(response.data) ? response.data : [];
      }
    }

    this.setState({
      sameLayoutList: sameLayoutList,
    });
  }

  saveAllCoversOutside = async () => {
    const { apartmentPeriferyWallContainId, saveLayoutValues, houseTypeId } = this.state;

    if (Boolean(houseTypeId) && houseTypeId > 0) {
      if (Boolean(apartmentPeriferyWallContainId) && apartmentPeriferyWallContainId > 0) {
        let newObj = {
          houseTypeId: houseTypeId,
          apartmentPeriferyWallContainId: apartmentPeriferyWallContainId,
          discipline: saveLayoutValues.discipline,

          mainStructure: saveLayoutValues.quantityOutside[0].mainStructure,
          mainStructureItemId: saveLayoutValues.quantityOutside[0].mainStructureItemId,

          coverOutSide: saveLayoutValues.quantityOutside[1].coverOutSide,
          coverRoom: saveLayoutValues.quantityOutside[1].coverRoom,
          coverThickness: saveLayoutValues.quantityOutside[1].coverThickness,
          coverThicknessRoom: saveLayoutValues.quantityOutside[1].coverThicknessRoom,
          coverUnitId: saveLayoutValues.quantityOutside[1].coverUnitId,
          coverUnitIdRoom: saveLayoutValues.quantityOutside[1].coverUnitIdRoom,

        }

        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAllCoversOutside", newObj);
        if (response.type === "ERROR") {
          error(response);
        }
        else {
          this.setState({
            modalCoversShow: false,
            modalFinishingsShow: true,
          });
        }
      } else {
        showInfo("Apartment Perifery Wall Contain not found.");
      }
    } else {
      showInfo("House type not found.");
    }
  }

  saveSelectedCoversOutside = async () => {
    const { apartmentPeriferyWallContainIds, saveLayoutValues } = this.state;

    if (Boolean(apartmentPeriferyWallContainIds) && apartmentPeriferyWallContainIds.length > 0) {
      let newObj = {
        apartmentPeriferyWallContainIds: apartmentPeriferyWallContainIds,
        discipline: saveLayoutValues.discipline,

        mainStructure: saveLayoutValues.quantityOutside[0].mainStructure,
        mainStructureItemId: saveLayoutValues.quantityOutside[0].mainStructureItemId,

        coverOutSide: saveLayoutValues.quantityOutside[1].coverOutSide,
        coverRoom: saveLayoutValues.quantityOutside[1].coverRoom,
        coverThickness: saveLayoutValues.quantityOutside[1].coverThickness,
        coverThicknessRoom: saveLayoutValues.quantityOutside[1].coverThicknessRoom,
        coverUnitId: saveLayoutValues.quantityOutside[1].coverUnitId,
        coverUnitIdRoom: saveLayoutValues.quantityOutside[1].coverUnitIdRoom,
      }

      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveSelectedCoversOutside", newObj);
      if (response.type === "ERROR") {
        error(response);
      }
      else {
        this.setState({
          modalCoversShow: false,
          modalSameCoversShow: false,
          modalFinishingsShow: true,
          apartmentPeriferyWallContainIds: [],
        });
      }
    } else {
      showInfo("Please select item.");
    }
  }

  onSelectChangePeriferyWallContain = apartmentPeriferyWallContainIds => {
    this.setState({
      apartmentPeriferyWallContainIds: apartmentPeriferyWallContainIds
    });
  };

  saveAllFinishingsOutside = async () => {
    const { apartmentPeriferyWallContainId, saveLayoutValues, houseTypeId } = this.state;
    if (Boolean(houseTypeId) && houseTypeId > 0) {
      if (Boolean(apartmentPeriferyWallContainId) && apartmentPeriferyWallContainId > 0) {
        let newObj = {
          apartmentPeriferyWallContainId: apartmentPeriferyWallContainId,
          discipline: saveLayoutValues.discipline,
          houseTypeId: houseTypeId,

          mainStructure: saveLayoutValues.quantityOutside[0].mainStructure,
          mainStructureItemId: saveLayoutValues.quantityOutside[0].mainStructureItemId,

          finishingOutSide: saveLayoutValues.quantityOutside[2].finishingOutSide,
          finishingRoom: saveLayoutValues.quantityOutside[2].finishingRoom,
          finishingThickness: saveLayoutValues.quantityOutside[2].finishingThickness,
          finishingThicknessRoom: saveLayoutValues.quantityOutside[2].finishingThicknessRoom,
          finishingUnitId: saveLayoutValues.quantityOutside[2].finishingUnitId,
          finishingUnitIdRoom: saveLayoutValues.quantityOutside[2].finishingUnitIdRoom,
        }

        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAllFinishingsOutside", newObj);
        if (response.type === "ERROR") {
          error(response);
        }
        else {
          this.setState({
            modalFinishingsShow: false,
          });
        }
      } else {
        showInfo("Apartment Perifery Wall Contain not found.");
      }
    } else {
      showInfo("House type not found.");
    }
  }

  saveSelectedFinishingsOutside = async () => {
    const { apartmentPeriferyWallContainIds, saveLayoutValues } = this.state;
    if (Boolean(apartmentPeriferyWallContainIds) && apartmentPeriferyWallContainIds.length > 0) {
      let newObj = {
        apartmentPeriferyWallContainIds: apartmentPeriferyWallContainIds,
        discipline: saveLayoutValues.discipline,

        mainStructure: saveLayoutValues.quantityOutside[0].mainStructure,
        mainStructureItemId: saveLayoutValues.quantityOutside[0].mainStructureItemId,

        finishingOutSide: saveLayoutValues.quantityOutside[2].finishingOutSide,
        finishingRoom: saveLayoutValues.quantityOutside[2].finishingRoom,
        finishingThickness: saveLayoutValues.quantityOutside[2].finishingThickness,
        finishingThicknessRoom: saveLayoutValues.quantityOutside[2].finishingThicknessRoom,
        finishingUnitId: saveLayoutValues.quantityOutside[2].finishingUnitId,
        finishingUnitIdRoom: saveLayoutValues.quantityOutside[2].finishingUnitIdRoom,
      }

      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveSelectedFinishingsOutside", newObj);
      if (response.type === "ERROR") {
        error(response);
      }
      else {
        this.setState({
          modalFinishingsShow: false,
          modalSameFinishingsShow: false,
          apartmentPeriferyWallContainIds: [],
        });
      }
    } else {
      showInfo("Please select item.");
    }
  }

  getQuantityDetails = async (row) => {
    console.log("row", row);
    let newObj = {
      eldenStockCardId: row.eldenStockCardId,
      itemId: row.itemId
    };

    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/quantityDetails", newObj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        quantityDetailList: Boolean(response.data) && response.data.length > 0 ? response.data : []
      });
    }
  }

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const { apartmentPeriferyWallContainIds } = this.state;
    const rowSelectionWall = {
      selectedRowKeys: apartmentPeriferyWallContainIds,
      onChange: this.onSelectChangePeriferyWallContain
    };

    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="ProjectsTreeCompanyQuantity.Group" defaultMessage="Group" />,
        key: "groupName",
        render: record => {
          return record.groupName;
        }
      },
      {
        title: <FormattedMessage id="ProjectsTreeCompanyQuantity.Item" defaultMessage="Item" />,
        key: "itemName",
        render: record => {
          return record.itemName;
        }
      },
      {
        title: <FormattedMessage id="ProjectsTreeCompanyQuantity.Dimension" defaultMessage="Dimension" />,
        key: "dimensionName",
        render: record => {
          return record.dimensionName;
        },
      },
      {
        title: <FormattedMessage id="ProjectsTreeCompanyQuantity.Quantity" defaultMessage="Quantity" />,
        key: "quantityArea",
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline"
                }}
                onClick={() =>
                  this.setState({
                    modalQuantityDetailShow: true,
                  }, () => {
                    this.getQuantityDetails(record)
                  })
                }>
                {record.quantityArea}
              </div>
            </React.Fragment >
          );
        },
      },
      {
        title: <FormattedMessage id="ProjectsTreeCompanyQuantity.Unit" defaultMessage="Unit" />,
        key: "quantityUnitName",
        render: record => {
          return record.quantityUnitName;
        },
      }
    ];

    const columnsWall = [
      {
        title: <FormattedMessage id="ProjectsTreeCompanyQuantity.Wall" defaultMessage="Wall" />,
        key: "wall",
        width: 100,
        render: record => {
          return record.wall;
        }
      },
      {
        title: <FormattedMessage id="ProjectsTreeCompanyQuantity.Division" defaultMessage="Division" />,
        key: "roomTypeName",
        width: 100,
        render: record => {
          return record.roomTypeName;
        }
      },
      {
        title: <FormattedMessage id="ProjectsTreeCompanyQuantity.Contain" defaultMessage="Contain" />,
        key: "contain",
        width: 100,
        render: record => {
          return record.contain;
        }
      }
    ];

    const columnsQuantityDetail = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="ProjectsTreeCompanyQuantity.Group" defaultMessage="Group" />,
        key: "groupName",
        render: record => {
          return record.groupName;
        }
      },
      {
        title: <FormattedMessage id="ProjectsTreeCompanyQuantity.Item" defaultMessage="Item" />,
        key: "itemName",
        render: record => {
          return record.itemName;
        }
      },
      {
        title: <FormattedMessage id="ProjectsTreeCompanyQuantity.Dimension" defaultMessage="Dimension" />,
        key: "dimensionName",
        render: record => {
          return record.dimensionName;
        },
      },
      {
        title: <FormattedMessage id="ProjectsTreeCompanyQuantity.Quantity" defaultMessage="Quantity" />,
        key: "quantityArea",
        render: record => {
          return record.quantityArea;
        },
      },
      {
        title: <FormattedMessage id="ProjectsTreeCompanyQuantity.Unit" defaultMessage="Unit" />,
        key: "quantityUnitName",
        render: record => {
          return record.quantityUnitName;
        },
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <div className="card card-custom card-stretch">
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <Breadcrumb separator=">">
                <Breadcrumb.Item>
                  <a href="#"
                    className={this.state.treeVisibleModal ? 'customBreadcrumbItem' : ''}
                    onClick={() => {
                      this.setState({
                        treeVisibleModal: true,

                        roomQuantityModal: false,
                        roomLayoutModal: false,
                        currentRow: 0,
                        currentWallRow: 0,
                        currentContainRow: 0
                      });
                    }}>
                    <Space>
                      <SVG style={{ width: '20px', height: '20px' }} src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>
                      <FormattedMessage id="ProjectsTreeCompanyApartment.Quantity" defaultMessage="Quantity" />
                    </Space>
                  </a>
                </Breadcrumb.Item>

                {Boolean(this.state.houseTypeSelectItems) && this.state.houseTypeSelectItems.length > 0 &&
                  this.state.houseTypeSelectItems.map((val, i) => (
                    <Breadcrumb.Item key={i}>
                      <a href="#"
                        onClick={() => {
                          this.setState({
                            roomLayoutModal: true,

                            treeVisibleModal: false,
                            roomQuantityModal: false,

                            currentRow: i,
                            currentWallRow: 0,
                            currentContainRow: 0,
                            containSelectItems: [],
                          }, () => {
                            this.fillDiscipline();
                            this.restartNewHouseType();
                            this.fillTreeByHouseTypeId(val.houseTypeId);
                          });
                        }}>
                        {val.houseTypeName}
                      </a>
                    </Breadcrumb.Item>
                  ))
                }
              </Breadcrumb>
            </div>
            <div className="card-toolbar"></div>
          </div>

          <br />
          <h3 className="card-label font-weight-bolder text-dark">
            <FormattedMessage id="ProjectsTreeCompanyQuantity.Quantity" defaultMessage="Quantity" />
          </h3>
        </div>
        <br />

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table bordered size="small"
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination} />
        </div>

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                {this.state.houseTypeName}
              </span>
            </span>
          }
          centered
          open={this.state.roomLayoutModal}
          onCancel={() => this.setState({ roomLayoutModal: false })}
          okText={<FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          onOk={() => this.saveAllCoversOutside()}
          cancelButtonProps={{ hidden: true }}
          width={1000}
          content={
            <>
              {this.state.refreshTree && (
                <Tree
                  style={{ marginTop: "10px", lineHeight: "30px" }}
                  className="draggable-tree"
                  showLine={{ showLeafIcon: false }}
                  showIcon
                  checkStrictly={true}
                  loadedKeys={this.state.loadedKeys}
                  onLoad={this.onLoadData}
                  loadData={this.loadData}
                  onRightClick={({ event, node }) => this.onRightClick(event, node)}
                  onExpand={this.onExpand}
                  expandedKeys={this.state.expandedKeys}
                  autoExpandParent={false}
                  treeData={this.state.nodes}
                  blockNode={true}
                  checkedKeys={this.state.checkedKeys}
                  halfCheckedKeys={this.state.halfCheckedKeys}
                />
              )}

              <Menu id={Menu_Room_Quantity}>
                <Item id="create_room_quantity" onClick={this.handleItemClick}>
                  <Space>
                    <FcEditImage />
                    <span>
                      <FormattedMessage id="ProjectsTreeCompanyQuantity.RoomQuantity" defaultMessage="Room Quantity" />
                    </span>
                  </Space>
                </Item>
              </Menu>
            </>
          }
        />

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                {this.state.houseTypeName}
              </span>
            </span>
          }
          centered
          open={this.state.roomLayoutModalFormItem}
          onCancel={() => this.setState({ roomLayoutModalFormItem: false })}
          okText={<FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          onOk={() => this.saveAllCoversOutside()}
          cancelButtonProps={{ hidden: true }}
          width={1000}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveLayout} onFinishFailed={onFinishFailed} ref={this.formRefLayout}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProjectPlanning.Discipline" defaultMessage="Discipline" />}
                    name="discipline"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      style={{ width: "100%" }}
                      onChange={value => {
                        this.setState({
                          discipline: value
                        });
                      }}
                    >
                      {Boolean(this.state["disciplineIdSelectItems"]) && this.state["disciplineIdSelectItems"].length > 0 &&
                        this.state["disciplineIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }

                <div style={{ overflowX: 'auto', maxWidth: '600px' }}>
                  <Form.List name="quantityOutside">
                    {(fields, { add, remove }) => {
                      return (
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              <th colSpan="3" style={{ textAlign: "center" }}>
                                <FormattedMessage id="ProjectsTreeCompanyQuantity.Outside" defaultMessage="Outside" />
                              </th>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                {this.state.roomTypeName}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item style={{ width: "100px" }} name={[field.name, "name"]}>
                                      <Text type="secondary"
                                        style={{ width: "100px" }} >
                                        {
                                          Boolean(this.formRefLayout) && this.formRefLayout.current && this.formRefLayout.current.getFieldValue("quantityOutside") &&
                                          this.formRefLayout.current.getFieldValue("quantityOutside").length > 0 &&
                                          this.formRefLayout.current.getFieldValue("quantityOutside")[field.name].name
                                        }
                                      </Text>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    {Boolean(this.formRefLayout) && this.formRefLayout.current &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside") &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside").length > 0 &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside")[field.name].name === "Main Structure" && (
                                        <Form.Item noStyle name={[field.name, "mainStructure"]}>
                                          <Select
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            style={{ width: "100px" }}
                                            onChange={(value) => {
                                              this.fillItemId(value);
                                            }}
                                          >
                                            {Boolean(this.state["mainStructureSelectItems"]) && this.state["mainStructureSelectItems"].length > 0 &&
                                              this.state["mainStructureSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                  {i.value}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      )}

                                    {Boolean(this.formRefLayout) && this.formRefLayout.current &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside") &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside").length > 0 &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside")[field.name].name === "Cover" && (
                                        <Form.Item noStyle name={[field.name, "coverOutSide"]}>
                                          <Select
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            style={{ width: "100px" }}
                                          >
                                            {Boolean(this.state["coverSelectItems"]) && this.state["coverSelectItems"].length > 0 &&
                                              this.state["coverSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                  {i.value}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      )}

                                    {Boolean(this.formRefLayout) && this.formRefLayout.current &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside") &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside").length > 0 &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside")[field.name].name === "Finishing" && (
                                        <Form.Item noStyle name={[field.name, "finishingOutSide"]}>
                                          <Select
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            style={{ width: "100px" }}
                                          >
                                            {Boolean(this.state["finishingSelectItems"]) && this.state["finishingSelectItems"].length > 0 &&
                                              this.state["finishingSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                  {i.value}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      )}
                                  </td>

                                  <td>
                                    {Boolean(this.formRefLayout) && this.formRefLayout.current &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside") &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside").length > 0 &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside")[field.name].name === "Main Structure" && (
                                        <Form.Item noStyle name={[field.name, "mainStructureItemId"]}>
                                          <Select
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            style={{ width: "100px" }}
                                          >
                                            {Boolean(this.state["itemIdSelectItems"]) && this.state["itemIdSelectItems"].length > 0 &&
                                              this.state["itemIdSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                  {i.value}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      )}

                                    {Boolean(this.formRefLayout) && this.formRefLayout.current &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside") &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside").length > 0 &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside")[field.name].name === "Cover" && (
                                        <Space>
                                          <Form.Item noStyle name={[field.name, "coverThickness"]}>
                                            <InputNumber></InputNumber>
                                          </Form.Item>
                                          <Form.Item noStyle name={[field.name, "coverUnitId"]}>
                                            <Select
                                              showSearch
                                              allowClear
                                              optionFilterProp="children"
                                              style={{ width: "100px" }}
                                            >
                                              {Boolean(this.state["unitIdSelectItems"]) && this.state["unitIdSelectItems"].length > 0 &&
                                                this.state["unitIdSelectItems"].map(i => (
                                                  <Option key={i.id} value={i.id}>
                                                    {i.abbreviation}
                                                  </Option>
                                                ))}
                                            </Select>
                                          </Form.Item>
                                        </Space>
                                      )}

                                    {Boolean(this.formRefLayout) && this.formRefLayout.current &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside") &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside").length > 0 &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside")[field.name].name === "Finishing" && (
                                        <Space>
                                          <Form.Item noStyle name={[field.name, "finishingThickness"]}>
                                            <InputNumber></InputNumber>
                                          </Form.Item>
                                          <Form.Item noStyle name={[field.name, "finishingUnitId"]}>
                                            <Select
                                              showSearch
                                              allowClear
                                              optionFilterProp="children"
                                              style={{ width: "100px" }}
                                            >
                                              {Boolean(this.state["unitIdSelectItems"]) && this.state["unitIdSelectItems"].length > 0 &&
                                                this.state["unitIdSelectItems"].map(i => (
                                                  <Option key={i.id} value={i.id}>
                                                    {i.abbreviation}
                                                  </Option>
                                                ))}
                                            </Select>
                                          </Form.Item>
                                        </Space>
                                      )}
                                  </td>

                                  <td>
                                    {Boolean(this.formRefLayout) && this.formRefLayout.current &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside") &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside").length > 0 &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside")[field.name].name === "Main Structure" && (
                                        <Form.Item noStyle name={[field.name, "mainStructure"]}>
                                          <Select
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            style={{ width: "100px" }}
                                          >
                                            {Boolean(this.state["mainStructureSelectItems"]) && this.state["mainStructureSelectItems"].length > 0 &&
                                              this.state["mainStructureSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                  {i.value}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      )}


                                    {Boolean(this.formRefLayout) && this.formRefLayout.current &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside") &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside").length > 0 &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside")[field.name].name === "Cover" && (
                                        <Form.Item noStyle name={[field.name, "coverRoom"]}>
                                          <Select
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            style={{ width: "100px" }}
                                          >
                                            {Boolean(this.state["coverSelectItems"]) && this.state["coverSelectItems"].length > 0 &&
                                              this.state["coverSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                  {i.value}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      )}

                                    {Boolean(this.formRefLayout) && this.formRefLayout.current &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside") &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside").length > 0 &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside")[field.name].name === "Finishing" && (
                                        <Form.Item noStyle name={[field.name, "finishingRoom"]}>
                                          <Select
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            style={{ width: "100px" }}
                                          >
                                            {Boolean(this.state["finishingSelectItems"]) && this.state["finishingSelectItems"].length > 0 &&
                                              this.state["finishingSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                  {i.value}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      )}

                                  </td>
                                  <td>
                                    {Boolean(this.formRefLayout) && this.formRefLayout.current &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside") &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside").length > 0 &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside")[field.name].name === "Main Structure" && (
                                        <Form.Item noStyle name={[field.name, "mainStructureItemId"]}>
                                          <Select
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            style={{ width: "100px" }}
                                          >
                                            {Boolean(this.state["itemIdSelectItems"]) && this.state["itemIdSelectItems"].length > 0 &&
                                              this.state["itemIdSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                  {i.value}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      )}

                                    {Boolean(this.formRefLayout) && this.formRefLayout.current &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside") &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside").length > 0 &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside")[field.name].name === "Cover" && (
                                        <Space>
                                          <Form.Item noStyle name={[field.name, "coverThicknessRoom"]}>
                                            <InputNumber></InputNumber>
                                          </Form.Item>
                                          <Form.Item noStyle name={[field.name, "coverUnitIdRoom"]}>
                                            <Select
                                              showSearch
                                              allowClear
                                              optionFilterProp="children"
                                              style={{ width: "100px" }}
                                            >
                                              {Boolean(this.state["unitIdSelectItems"]) && this.state["unitIdSelectItems"].length > 0 &&
                                                this.state["unitIdSelectItems"].map(i => (
                                                  <Option key={i.id} value={i.id}>
                                                    {i.abbreviation}
                                                  </Option>
                                                ))}
                                            </Select>
                                          </Form.Item>
                                        </Space>
                                      )}


                                    {Boolean(this.formRefLayout) && this.formRefLayout.current &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside") &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside").length > 0 &&
                                      this.formRefLayout.current.getFieldValue("quantityOutside")[field.name].name === "Finishing" && (
                                        <Space>
                                          <Form.Item noStyle name={[field.name, "finishingThicknessRoom"]}>
                                            <InputNumber></InputNumber>
                                          </Form.Item>
                                          <Form.Item noStyle name={[field.name, "finishingUnitIdRoom"]}>
                                            <Select
                                              showSearch
                                              allowClear
                                              optionFilterProp="children"
                                              style={{ width: "100px" }}
                                            >
                                              {Boolean(this.state["unitIdSelectItems"]) && this.state["unitIdSelectItems"].length > 0 &&
                                                this.state["unitIdSelectItems"].map(i => (
                                                  <Option key={i.id} value={i.id}>
                                                    {i.abbreviation}
                                                  </Option>
                                                ))}
                                            </Select>
                                          </Form.Item>
                                        </Space>
                                      )}

                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </TableBoot>
                      );
                    }}
                  </Form.List>
                </div>
              </Form>
            </>
          }
        />

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="ProjectsTreeCompanyQuantity.Covers" defaultMessage="Covers" />
              </span>
            </span>
          }
          centered
          open={this.state.modalCoversShow}
          maskClosable={false}
          onCancel={() => this.setState({ modalCoversShow: false })}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() =>
                  this.setState({
                    modalCoversShow: false,
                    modalSameCoversShow: true,
                  }, () => {
                    this.fillSameLayoutList()
                  })}
              >
                <FormattedMessage id="GeneralButtonNo" defaultMessage="No" />
              </Button>
              <Button variant="primary" onClick={() => this.saveAllCoversOutside()}>
                <FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />
              </Button>
            </Space>
          ]}
          content={
            <FormattedMessage id="ProjectsTreeCompanyQuantity.DoAllExteriorWallsCoversHaveTheSameFeaturesAndDimensions" defaultMessage="Do all exterior wall's covers have the same features and dimensions ?" />
          }
        />

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="ProjectsTreeCompanyQuantity.PleaseSelectSameFeaturesAndDimensions" defaultMessage="Please select same features and dimensions" />
              </span>
            </span>
          }
          centered
          width={800}
          closable={false}
          maskClosable={false}
          open={this.state.modalSameCoversShow}
          onOk={() => this.saveSelectedCoversOutside()}
          onCancel={() => this.setState({ modalSameCoversShow: false, modalCoversShow: true, })}
          okText={<FormattedMessage id="GeneralButtonOk" defaultMessage="Ok" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Table
              bordered
              rowKey={record => record.apartmentPeriferyWallContainId}
              rowSelection={rowSelectionWall}
              columns={columnsWall}
              dataSource={this.state.sameLayoutList}
              pagination={false}
            />
          }
        />

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="ProjectsTreeCompanyQuantity.Finishings" defaultMessage="Finishings" />
              </span>
            </span>
          }
          centered
          open={this.state.modalFinishingsShow}
          maskClosable={false}
          onCancel={() => this.setState({ modalFinishingsShow: false })}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() =>
                  this.setState({
                    modalFinishingsShow: false,
                    modalSameFinishingsShow: true,
                  }, () => {
                    this.fillSameLayoutList()
                  })}
              >
                <FormattedMessage id="GeneralButtonNo" defaultMessage="No" />
              </Button>
              <Button variant="primary" onClick={() => this.saveAllFinishingsOutside()}>
                <FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />
              </Button>
            </Space>
          ]}
          content={
            <FormattedMessage id="ProjectsTreeCompanyQuantity.DoAllExteriorWallsFinishingsHaveTheSameFeaturesAndDimensions" defaultMessage="Do all exterior wall's finishings have the same features and dimensions ?" />
          }
        />

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="ProjectsTreeCompanyQuantity.PleaseSelectSameFeaturesAndDimensions" defaultMessage="Please select same features and dimensions" />
              </span>
            </span>
          }
          centered
          width={800}
          closable={false}
          maskClosable={false}
          open={this.state.modalSameFinishingsShow}
          onOk={() => this.saveSelectedFinishingsOutside()}
          onCancel={() => this.setState({ modalSameFinishingsShow: false, modalFinishingsShow: true, })}
          okText={<FormattedMessage id="GeneralButtonOk" defaultMessage="Ok" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Table
              bordered
              rowKey={record => record.apartmentPeriferyWallContainId}
              rowSelection={rowSelectionWall}
              columns={columnsWall}
              dataSource={this.state.sameLayoutList}
              pagination={false}
            />
          }
        />

        <DraggableModal
          centered
          width={1200}
          title={<FormattedMessage id="ProjectsTreeCompanyQuantity.Quantity" defaultMessage="Quantity" />}
          open={this.state.modalQuantityDetailShow}
          onCancel={() => {
            this.setState({ modalQuantityDetailShow: false });
          }}
          okButtonProps={{ hidden: true }}
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Table
                bordered
                size="small"
                columns={columnsQuantityDetail}
                dataSource={this.state.quantityDetailList}
                loading={this.state.loading}
                pagination={false}
              />
            </div>
          }
        />

        <DraggableModal
          centered
          width={1300}
          title={<FormattedMessage id="ProjectsTreeCompanyQuantity.Quantity" defaultMessage="Quantity" />}
          open={this.state.roomQuantityModal}
          onCancel={() => {
            this.setState({ roomQuantityModal: false });
          }}
          okButtonProps={{ hidden: true }}
          content={
            Boolean(this.state.contain) && <>
              {(this.state.contain === "Masonry"
                || this.state.contain === "Column" || this.state.contain === "Beam") ?
                <ProjectsTreeCompanyRoomQuantity
                  random={this.state.random}
                  id={this.state.roomQuantityId}
                  houseTypeId={this.state.houseTypeId}
                  roomTypeId={this.state.roomTypeId}
                  roomUnit={this.state.roomUnit}
                  roomQuantityModalClose={this.roomQuantityModalClose}
                  companyProjectId={this.props.companyProjectId}
                />
                :
                (this.state.contain === "Door"
                  || this.state.contain === "Window" || this.state.contain === "Opening") &&
                <ProjectsTreeCompanyWindowQuantity
                  random={this.state.random}
                  id={this.state.roomQuantityId}
                  houseTypeId={this.state.houseTypeId}
                  roomTypeId={this.state.roomTypeId}
                  roomUnit={this.state.roomUnit}
                  roomQuantityModalClose={this.roomQuantityModalClose}
                  companyProjectId={this.props.companyProjectId}
                />}
            </>
          }
        />

      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsTreeCompanyQuantity);