import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { Table, Input, Badge, DatePicker, Modal as AntModal, Tag, Typography } from "antd";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import PaymentLog from "./PaymentLog";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import RevisionIcon from "@material-ui/icons/ViewList";
import { ReplyOutlined } from "@material-ui/icons";
import { EditOutlined } from "@material-ui/icons";

const { Text } = Typography;

const { RangePicker } = DatePicker;

export class PaymentPlanSchedule extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "Payment Log",
      controllerName: "paymentLog",
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      showScheduleButtons: true,
      showPaymentModal: false,

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      paymentGroupIdSelectItems: null,
      reasonForPaymentIdSelectItems: null,
      paymentTypeSelectItems: null,
      paymentModeSelectItems: null,
      scheduleTypeSelectItems: null,
      scheduleAmountTypeSelectItems: null,
      creditorSelectItems: null,
      periodSelectItems: null,
      bankAccountsSelectItems: [],
      cashLogSelectItems: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responsepaymentGroupId = await handleRequest("GET", "/api/codes/type/paymentGroupId");
    if (responsepaymentGroupId.type === "ERROR") {
      error(responsepaymentGroupId);
    } else {
      this.setState({
        paymentGroupIdSelectItems: Boolean(responsepaymentGroupId.data) ? responsepaymentGroupId.data : []
      });
    }
    var responsereasonForPaymentId = await handleRequest("GET", "/api/codes/type/reasonForPaymentId");
    if (responsereasonForPaymentId.type === "ERROR") {
      error(responsereasonForPaymentId);
    } else {
      this.setState({
        reasonForPaymentIdSelectItems: Boolean(responsereasonForPaymentId.data) ? responsereasonForPaymentId.data : []
      });
    }
    var responsepaymentType = await handleRequest("GET", "/api/paymentPlan/paymentTypes");
    if (responsepaymentType.type === "ERROR") {
      error(responsepaymentType);
    } else {
      this.setState({
        paymentTypeSelectItems: Boolean(responsepaymentType.data) ? responsepaymentType.data : []
      });
    }
    var responsepaymentMode = await handleRequest("GET", "/api/paymentPlan/paymentModes");
    if (responsepaymentMode.type === "ERROR") {
      error(responsepaymentMode);
    } else {
      this.setState({
        paymentModeSelectItems: Boolean(responsepaymentMode.data) ? responsepaymentMode.data : []
      });
    }
    var responsescheduleType = await handleRequest("GET", "/api/paymentPlan/scheduleTypes");
    if (responsescheduleType.type === "ERROR") {
      error(responsescheduleType);
    } else {
      this.setState({
        scheduleTypeSelectItems: Boolean(responsescheduleType.data) ? responsescheduleType.data : []
      });
    }
    var responsescheduleAmountType = await handleRequest("GET", "/api/paymentPlan/scheduleAmountTypes");
    if (responsescheduleAmountType.type === "ERROR") {
      error(responsescheduleAmountType);
    } else {
      this.setState({
        scheduleAmountTypeSelectItems: Boolean(responsescheduleAmountType.data) ? responsescheduleAmountType.data : []
      });
    }

    var responsePeriod = await handleRequest("GET", "/api/paymentPlan/periodTypes");
    if (responsePeriod.type === "ERROR") {
      error(responsePeriod);
    } else {
      this.setState({
        periodSelectItems: Boolean(responsePeriod.data) ? responsePeriod.data : []
      });
    }

    var responseDeptor = await handleRequest("GET", "/api/paymentPlan/creditors");
    if (responseDeptor.type === "ERROR") {
      error(responseDeptor);
    } else {
      this.setState({
        creditorSelectItems: Boolean(responseDeptor.data) ? responseDeptor.data : []
      });
    }

    var responseBankAccounts = await handleRequest("GET", "/api/paymentPlan/bankAccounts");
    if (responseBankAccounts.type === "ERROR") {
      error(responseBankAccounts);
    } else {
      this.setState({
        bankAccountsSelectItems: Boolean(responseBankAccounts.data) ? responseBankAccounts.data : []
      });
    }

    var responseCashLog = await handleRequest("GET", "/api/paymentPlan/cashLogs");
    if (responseCashLog.type === "ERROR") {
      error(responseCashLog);
    } else {
      this.setState({
        cashLogSelectItems: Boolean(responseCashLog.data) ? responseCashLog.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = [];
    let scheduleList = [];
    if (Boolean(row.scheduleList)) {
      scheduleList = row.scheduleList.map((item, index) => {
        return {
          ...item,
          key: index,
          id: null,
          date: item.date ? moment(item.date) : null
        };
      });
    }

    console.log("scheduleList", scheduleList);

    this.formRef.current.setFieldsValue({
      ...row,
      paymentDate: Boolean(row.paymentDate) ? moment(row.paymentDate) : null,
      firstDateForPeriod: Boolean(row.firstDateForPeriod) ? moment(row.firstDateForPeriod) : null,
      scheduleList: scheduleList
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      paymentType: row.paymentType,
      scheduleType: row.scheduleType,
      scheduleAmountType: row.scheduleAmountType,
      amount: row.amount,
      fixedAmount: row.fixedAmount,
      period: row.period,
      firstDateForPeriod: Boolean(row.firstDateForPeriod) ? moment(row.firstDateForPeriod) : null,
      paymentMode: row.paymentMode
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  paymentModal = row => {
    this.setState({
      showPaymentModal: true,
      selectedPaymentPlan: row
    });
  };

  evaluationModal = row => {
    this.setState({
      showEvaluationModal: true,
      selectedPaymentPlan: row
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/paymentPlan/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/paymentPlanSchedule/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let tempList = response.data.data;
        let list = [];
        tempList.forEach((element, index) => {
          element.index = index + 1;
          list.push({
            index: index + 1,
            ...element.paymentPlan,
            id: element.id,
            paymentPlanId: element.paymentPlan.id,
            amount: element.amount,
            date: element.date,
            paymentLogs: element.paymentLogs
          });
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/paymentPlan/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    // if (this.getStatusAsString(record.paymentLogs, record.amount) === "Paid")
    //   return (
    //     <React.Fragment>
    //       <ActionMenu row={record} onEditRow={this.paymentModal.bind(this)} />
    //     </React.Fragment>
    //   );
    // else
    //   return (
    //     <React.Fragment>
    //       <ActionMenu row={record} onPayment={this.paymentModal.bind(this)} />
    //     </React.Fragment>
    //   );

    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.paymentModal.bind(this)
    };
    let payAction = {
      name: "Pay",
      icon: <RevisionIcon fontSize="small" color="error" />,
      actionClick: this.paymentModal.bind(this)
    };

    let evaluationAction = {
      name: "Evaluation",
      icon: <ReplyOutlined fontSize="small" color="secondary" />,
      actionClick: this.evaluationModal.bind(this)
    };

    let actionList = [];
    if (record.requestStatus === "RFQ_REQUESTED") {
      actionList.push(evaluationAction);
    } else {
      if (this.getStatusAsString(record.paymentLogs, record.amount) === "Paid") {
        actionList.push(editAction);
      } else {
        actionList.push(payAction);
      }
    }

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  createElementWithPeriodAndAmount = (period, firstDateForPeriod, index, fixedAmount) => {
    let element = { key: index, date: null, amount: fixedAmount };

    if (Boolean(period) && Boolean(firstDateForPeriod)) {
      let firstDate = firstDateForPeriod.toDate();
      switch (period) {
        case "DAILY":
          element.date = moment(firstDate).add(index, "days");

          break;
        case "WEEKLY":
          element.date = moment(firstDate).add(index, "weeks");
          break;
        case "MONTHLY":
          element.date = moment(firstDate).add(index, "months");
          break;
        case "YEARLY":
          element.date = moment(firstDate).add(index, "years");
          break;

        default:
          break;
      }
    }

    return element;
  };

  calculateScheduleList = async () => {
    const { scheduleType, scheduleAmountType, amount, fixedAmount, period, firstDateForPeriod } = this.state;

    if (!Boolean(scheduleType)) {
      showWarning("Please select schedule type");
      return;
    }

    if (!Boolean(scheduleAmountType)) {
      showWarning("Please select schedule amount type");
      return;
    }

    if (scheduleAmountType === "FIXED") {
      if (!Boolean(amount)) {
        showWarning("Please enter amount");
        return;
      }

      if (!Boolean(fixedAmount)) {
        showWarning("Please enter fixed amount");
        return;
      }
    }

    if (scheduleAmountType === "VARIABLE") {
      if (!Boolean(amount)) {
        showWarning("Please enter amount");
        return;
      }
    }

    if (scheduleType === "PERIODIC") {
      if (!Boolean(period)) {
        showWarning("Please select period");
        return;
      }

      if (!Boolean(firstDateForPeriod)) {
        showWarning("Please select first date for period");
        return;
      }
    }

    switch (scheduleType) {
      case "VARIABLEDATES":
        if (Boolean(scheduleAmountType) && scheduleAmountType === "FIXED") {
          if (Boolean(amount) && Boolean(fixedAmount)) {
            let k = amount % fixedAmount;
            let schduleListCount = Math.ceil(amount / fixedAmount);
            let schduleList = [];
            if (k > 0) schduleListCount = schduleListCount - 1;
            for (let index = 0; index < schduleListCount; index++) {
              const element = { key: index, date: null, amount: fixedAmount };
              schduleList.push(element);
            }

            if (k > 0) {
              const element = { key: schduleListCount, date: null, amount: k };
              schduleList.push(element);
            }

            this.formRef.current.setFieldsValue({
              scheduleList: schduleList
            });
          }
          this.setState({
            showScheduleButtons: false
          });
        }

        if (Boolean(scheduleAmountType) && scheduleAmountType === "VARIABLE") {
          this.setState({
            showScheduleButtons: true
          });
        }

        break;

      case "PERIODIC":
        if (Boolean(scheduleAmountType) && scheduleAmountType === "FIXED") {
          if (Boolean(amount) && Boolean(fixedAmount)) {
            let k = amount % fixedAmount;
            let schduleListCount = Math.ceil(amount / fixedAmount);
            let schduleList = [];
            if (k > 0) schduleListCount = schduleListCount - 1;
            for (let index = 0; index < schduleListCount; index++) {
              const element = this.createElementWithPeriodAndAmount(period, firstDateForPeriod, index, fixedAmount);
              schduleList.push(element);
            }

            if (k > 0) {
              const element = this.createElementWithPeriodAndAmount(period, firstDateForPeriod, schduleListCount, k);
              schduleList.push(element);
            }

            this.formRef.current.setFieldsValue({
              scheduleList: schduleList
            });
          }
          this.setState({
            showScheduleButtons: false
          });
        }

        if (Boolean(scheduleAmountType) && scheduleAmountType === "VARIABLE") {
          this.setState({
            showScheduleButtons: true
          });
        }

        break;

      default:
        break;
    }
  };

  addNewSchedule = async () => {
    const { scheduleType, scheduleAmountType, amount, fixedAmount, period, firstDateForPeriod } = this.state;

    if (!Boolean(scheduleType)) {
      showWarning("Please select schedule type");
      return;
    }

    if (!Boolean(scheduleAmountType)) {
      showWarning("Please select schedule amount type");
      return;
    }

    if (scheduleAmountType === "FIXED") {
      if (!Boolean(amount)) {
        showWarning("Please enter amount");
        return;
      }

      if (!Boolean(fixedAmount)) {
        showWarning("Please enter fixed amount");
        return;
      }
    }

    if (scheduleAmountType === "VARIABLE") {
      if (!Boolean(amount)) {
        showWarning("Please enter amount");
        return;
      }
    }

    if (scheduleType === "PERIODIC") {
      if (!Boolean(period)) {
        showWarning("Please select period");
        return;
      }

      if (!Boolean(firstDateForPeriod)) {
        showWarning("Please select first date for period");
        return;
      }
    }
    let schduleList = Boolean(this.formRef.current.getFieldValue("scheduleList")) ? this.formRef.current.getFieldValue("scheduleList") : [];

    if (scheduleType === "VARIABLEDATES") {
      const element = { key: schduleList.length, date: null, amount: null };
      schduleList.push(element);

      this.formRef.current.setFieldsValue({
        scheduleList: schduleList
      });
    }

    if (scheduleType === "PERIODIC") {
      const element = this.createElementWithPeriodAndAmount(
        period,
        firstDateForPeriod,
        Boolean(schduleList) && schduleList.length > 0 ? schduleList.length : 0,
        null
      );
      schduleList.push(element);

      this.formRef.current.setFieldsValue({
        scheduleList: schduleList
      });
    }
  };

  getStatus = (list, amount) => {
    if (Boolean(amount)) {
      if (Boolean(list) && list.length > 0) {
        let total = 0;
        list.map(item => {
          total = total + item.amount;
        });
        if (total === amount) {
          return (
            <Tag color="green">
              {" "}
              <FormattedMessage id="PaymentPlanSchedule.paid" defaultMessage="Paid" />{" "}
            </Tag>
          );
        } else {
          return (
            <Tag color="yellow">
              {" "}
              <FormattedMessage id="PaymentPlanSchedule.partiallyPaid" defaultMessage="Partially Paid" />{" "}
            </Tag>
          );
        }
      } else
        return (
          <Tag color="red">
            {" "}
            <FormattedMessage id="PaymentPlanSchedule.notPaid" defaultMessage="Not Paid" />{" "}
          </Tag>
        );
    }
  };

  getStatusAsString = (list, amount) => {
    if (Boolean(amount)) {
      if (Boolean(list) && list.length > 0) {
        let total = 0;
        list.map(item => {
          total = total + item.amount;
        });
        if (total === amount) {
          return "Paid";
        } else {
          return "Partially Paid";
        }
      } else return "Not Paid";
    }
  };

  render() {

    const columns = [
      {
        key: "key",
        width: 70,
        render: this.action,
        onCell: (record, rowIndex) => ({
          style: {
            backgroundColor: record.requestStatus === "RFQ_REQUESTED" ? "#d3d3d3" : null
          }
        }),
      },
      {
        title: "S/N",
        key: "id",
        width: 70,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        },
        onCell: (record, rowIndex) => ({
          style: {
            backgroundColor: record.requestStatus === "RFQ_REQUESTED" ? "#d3d3d3" : null
          }
        }),
      },

      {
        title: <FormattedMessage id="PaymentPlanSchedule.paymentGroupId" defaultMessage="Payment Group" />,
        key: "paymentGroupId",
        ellipsis: true,
        render: record => {
          return record.paymentGroupName;
        },
        onCell: (record, rowIndex) => ({
          style: {
            backgroundColor: record.requestStatus === "RFQ_REQUESTED" ? "#d3d3d3" : null
          }
        }),
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.paymentGroupId) ? this.state.pagination.paymentGroupId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  paymentGroupId: e.target.value,
                  paymentGroupIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.paymentGroupIdColor) ? this.state.pagination.paymentGroupIdColor : "#bfbfbf" }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="PaymentPlanSchedule.paymentDescription" defaultMessage="Payment Description" />,
        key: "paymentDescription",
        ellipsis: true,
        render: record => {
          return record.paymentDescription;
        },
        onCell: (record, rowIndex) => ({
          style: {
            backgroundColor: record.requestStatus === "RFQ_REQUESTED" ? "#d3d3d3" : null
          }
        }),
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.paymentDescription) ? this.state.pagination.paymentDescription : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  paymentDescription: e.target.value,
                  paymentDescriptionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.paymentDescriptionColor) ? this.state.pagination.paymentDescriptionColor : "#bfbfbf" }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="PaymentPlanSchedule.reasonForPaymentId" defaultMessage="Reason For Payment" />,
        key: "reasonForPaymentId",
        ellipsis: true,
        render: record => {
          return record.reasonForPaymentName;
        },
        onCell: (record, rowIndex) => ({
          style: {
            backgroundColor: record.requestStatus === "RFQ_REQUESTED" ? "#d3d3d3" : null
          }
        }),
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.reasonForPaymentId) ? this.state.pagination.reasonForPaymentId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  reasonForPaymentId: e.target.value,
                  reasonForPaymentIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.reasonForPaymentIdColor) ? this.state.pagination.reasonForPaymentIdColor : "#bfbfbf" }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="PaymentPlanSchedule.creditorId" defaultMessage="Creditor" />,
        key: "creditorId",
        ellipsis: true,
        render: record => {
          return record.creditorName;
        },
        onCell: (record, rowIndex) => ({
          style: {
            backgroundColor: record.requestStatus === "RFQ_REQUESTED" ? "#d3d3d3" : null
          }
        }),
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.creditorId) ? this.state.pagination.creditorId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  creditorId: e.target.value,
                  creditorIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.creditorIdColor) ? this.state.pagination.creditorIdColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="PaymentPlanSchedule.paymentDate" defaultMessage="Date" />,
        key: "date",
        render: record => {
          if (Boolean(record.date)) return moment(record.date).format("DD-MM-YYYY");
        },
        onCell: (record, rowIndex) => ({
          style: {
            backgroundColor: record.requestStatus === "RFQ_REQUESTED" ? "#d3d3d3" : null
          }
        }),
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.paymentDate) ? this.state.pagination.paymentDate : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  paymentDate: e.target.value,
                  paymentDateColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.paymentDateColor) ? this.state.pagination.paymentDateColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="PaymentPlanSchedule.amount" defaultMessage="Total Amount" />,
        key: "amount",
        render: record => {
          if (Boolean(record.amount)) {
            let amount = record.amount.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ');
            if (record.currency) {
              return amount + " " + record.currency;
            }
            return amount;
          }
        },
        onCell: (record, rowIndex) => ({
          style: {
            backgroundColor: record.requestStatus === "RFQ_REQUESTED" ? "#d3d3d3" : null
          }
        }),
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.amount) ? this.state.pagination.amount : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  amount: e.target.value,
                  amountColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.amountColor) ? this.state.pagination.amountColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="PaymentPlanSchedule.status" defaultMessage="Status" />,
        key: "status",
        width: 120,
        render: record => {
          if (record.requestStatus === "RFQ_REQUESTED") {
            return <Tag color="yellow" style={{ borderColor: "black", borderWidth: "1px", borderStyle: "solid" }}>
              <Text>
                <FormattedMessage id="PaymentPlanSchedule.WaitingFor" defaultMessage="Waiting for" />
              </Text>
              <Text>{<br />}</Text>
              <Text>
                <FormattedMessage id="PaymentPlanSchedule.Approval" defaultMessage="approval" />
              </Text>
            </Tag>
          } else {
            return this.getStatus(record.paymentLogs, record.amount);
          }
        },
        onCell: (record, rowIndex) => ({
          style: {
            backgroundColor: record.requestStatus === "RFQ_REQUESTED" ? "#d3d3d3" : null
          }
        }),
      },
      {
        title: "Last Update",
        key: "lastUpdateDate",
        ellipsis: true,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        onCell: (record, rowIndex) => ({
          style: {
            backgroundColor: record.requestStatus === "RFQ_REQUESTED" ? "#d3d3d3" : null
          }
        }),
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    return (
      <Badge.Ribbon text={<FormattedMessage id="paymentPlanSchedule.pageTitle" defaultMessage="Payment Log" />} placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          {this.state.showPaymentModal && (
            <AntModal
              centered
              width={1500}
              title="Payment Detail"
              open={this.state.showPaymentModal}
              onCancel={() => {
                this.setState({ showPaymentModal: false });
                const { pagination } = this.state;
                this.restartTable({ pagination });
              }}
              footer={[
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      showPaymentModal: false
                    });
                    const { pagination } = this.state;
                    this.restartTable({ pagination });
                  }}
                >
                  Close
                </Button>
              ]}
            >
              {this.state.showPaymentModal && (
                <PaymentLog
                  paymentPlanId={this.state.selectedPaymentPlan.paymentPlanId}
                  paymentPlanScheduleId={this.state.selectedPaymentPlan.id}
                  totalAmount={this.state.selectedPaymentPlan.amount}
                ></PaymentLog>
              )}
            </AntModal>
          )}

          <AntModal
            centered
            width={1000}
            title="Evaluation"
            open={this.state.showEvaluationModal}
            onCancel={() => {
              this.setState({ showEvaluationModal: false });
              const { pagination } = this.state;
              this.restartTable({ pagination });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showEvaluationModal: false
                  });
                  const { pagination } = this.state;
                  this.restartTable({ pagination });
                }}
              >
                Close
              </Button>
            ]}
          >

          </AntModal>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPlanSchedule);
