import { FileSearchOutlined } from "@ant-design/icons";
import Viewer, { defaultLayout, Worker } from "@phuocng/react-pdf-viewer";
import { Button, Descriptions, PageHeader } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { handleRequest } from "../../ApiConnector";
import { error } from "../../MessageHelper";
import NotFoundError from "../error/NotFoundError";

export class ReportVerify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportData: null,
      qrCode: this.props.match.params.qrcode,
      modalPdfViewer: false,
      pdfFile: ""
    };
  }
  fillData = async () => {
    const response = await handleRequest("GET", "/api/reportQrLog/" + this.state.qrCode);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        reportData: response.data
      });
      // showSuccess();
    }
  };
  showReport = path => {
    if (Boolean(path))
      try {
        const fileRealUrl = path;
        this.setState({
          pdfFile: fileRealUrl,
          modalPdfViewer: true
        });
      } catch (error) {
        alert("File cant found.");
      }
    else error("Invoice path could not find !");
  };
  componentDidMount = async () => {
    this.fillData();
  };
  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer
    });
  };

  render() {
    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };
    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.printButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };
    return (
      <>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <PageHeader className="site-page-header" title={"QrCode Verify Report"} />
          {this.state.reportData ? (
            <Descriptions bordered column={{ sm: 1, xs: 1 }}>
              <Descriptions.Item label={<FormattedMessage id="ReportVerify.reportType" defaultMessage="Report Type" />}>
                {this.state.reportData.reportType}
              </Descriptions.Item>
              <Descriptions.Item label={<FormattedMessage id="ReportVerify.qr" defaultMessage="QR Code" />}>
                {this.state.reportData.qrCode}
              </Descriptions.Item>
              <Descriptions.Item label={<FormattedMessage id="ReportVerify.name" defaultMessage="Name" />}>
                {this.state.reportData.reportName}
              </Descriptions.Item>
              <Descriptions.Item label={<FormattedMessage id="ReportVerify.reportNo" defaultMessage="Report No" />}>
                {this.state.reportData.reportNo}
              </Descriptions.Item>
              <Descriptions.Item label={<FormattedMessage id="ReportVerify.file" defaultMessage="File" />}>
                <Button
                  style={{
                    marginLeft: "2px",
                    marginTop: "-2px",
                    alignContent: "center"
                  }}
                  type="primary"
                  shape="round"
                  icon={<FileSearchOutlined />}
                  onClick={() => this.showReport(this.state.reportData.reportFile)}
                >
                  Preview File
                </Button>
              </Descriptions.Item>
              <Descriptions.Item label={<FormattedMessage id="ReportVerify.totalPage" defaultMessage="Total Page" />}>
                {this.state.reportData.totalPage}
              </Descriptions.Item>
              <Descriptions.Item label={<FormattedMessage id="ReportVerify.purposeForPrint" defaultMessage="Purpose For Print" />}>
                {this.state.reportData.purposeForPrint}
              </Descriptions.Item>
              <Descriptions.Item label={<FormattedMessage id="ReportVerify.issueDate" defaultMessage="Issue Date" />}>
                {Boolean(this.state.reportData.issueDate) ? moment(this.state.reportData.issueDate).format("DD-MM-YYYY HH:mm") : ""}
              </Descriptions.Item>
              <Descriptions.Item label={<FormattedMessage id="ReportVerify.generatedDate" defaultMessage="Generated Date" />}>
                {Boolean(this.state.reportData.generateDate) ? moment(this.state.reportData.generateDate).format("DD-MM-YYYY HH:mm") : ""}
              </Descriptions.Item>
            </Descriptions>
          ) : (
            <NotFoundError />
          )}
        </div>
        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfViewer}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ReportVerify;
