import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Col as ColBoot, Form as FormBoot } from "react-bootstrap";
import { EditOutlined } from "@material-ui/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Form, Table, DatePicker, Badge, Button as AntButton, Col, Row, InputNumber, Space, Radio, Switch, Checkbox, Upload } from "antd";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import { handleRequest } from "../../ApiConnector";
import CreateIcon from "@material-ui/icons/AddCircle";
import { Select } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { getColumnFilter, fillFilterOperations } from "../../Components/DataTableFilterComponent";
import ExcelReader from "../excel/ExcelReader";
import DraggableModal from "../../Components/DraggableModal";
import { UploadOutlined } from "@ant-design/icons";
import { RadioGroup } from "@material-ui/core";

const { Option } = Select;

export class DailyCheck extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "dailyCheck",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      filePath: "",
      nodes: [],
      itemIndex: 0,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    fillFilterOperations();
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: ""
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    this.equipmentSearch(row.equipmentLogName);
    this.formRef.current.setFieldsValue({
      ...row,
      date: Boolean(row.date) ? moment(row.date) : null
    });

    console.log(row);
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
          element.itemIndex = index;
          element.answerType = "Radiogroup";
          element.options = [
            { codeId: null, codeName: "Yes" },
            { codeId: null, codeName: "No" }
          ];
          element.question =
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters";
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        modalChecklistShow: true,
        itemIndex: 0
      });
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          if (Array.isArray(element))
            element.forEach(element2 => {
              let obj = { columns: element2 };
              list.push(obj);
            });
          else {
            let obj = { columns: element };
            list.push(obj);
          }
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/importDynamicaly", list);
          if (response.type === "ERROR") {
            error(response);
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          error("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };
    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);
    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  showReport = async rowData => {
    try {
      if (rowData.photoPath !== null && rowData.photoPath !== undefined && rowData.photoPath !== "") {
        const fileRealUrl = rowData.photoPath;
        window.location.href = fileRealUrl;
      } else {
        this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
      }
    } catch (error) {
      this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
    }
  };

  equipmentSearch = async item => {
    console.log("item", item);
    this.setState({ loadingSearch: true });
    if (Boolean(item) && item.length > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/equipmentSearch/" + item);
      console.log("response", response.data);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          equipmentLogIdSelectItems: [],
          loadingSearch: false
        });
      } else {
        if (Boolean(response.data)) {
          this.setState({
            equipmentLogIdSelectItems: response.data,
            loadingSearch: false
          });
        } else {
          this.setState({
            equipmentLogIdSelectItems: [],
            loadingSearch: false
          });
        }
      }
    } else {
      this.setState({
        equipmentLogIdSelectItems: [],
        loadingSearch: false
      });
    }
  };

  renderElementSingle = child => {
    console.log("child", child);
    console.log("itemIndex", this.state.itemIndex);
    return (
      child.itemIndex === this.state.itemIndex && (
        <>
          <div style={{ backgroundColor: "white", padding: "10px", border: "1px solid #1ab394" }}>
            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
              <FormBoot.Label style={{ color: "black" }} column sm="9">
                {child.question}
              </FormBoot.Label>
            </FormBoot.Group>
            {Boolean(child.answerType) && child.answerType === "Comment" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot xs="12">
                  <FormBoot.Control as="textarea" rows="3" />
                </ColBoot>
              </FormBoot.Group>
            )}

            {Boolean(child.answerType) && child.answerType === "Radiogroup" && (
              <>
                {Boolean(child.options) &&
                  child.options.length > 0 &&
                  child.options.map((opt, j) => (
                    <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                      <ColBoot xs="11">
                        <Radio checked={false}>{opt.codeName}</Radio>
                      </ColBoot>
                    </FormBoot.Group>
                  ))}
              </>
            )}

            {Boolean(child.answerType) && child.answerType === "Imagepicker" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot xs="12">
                  {Boolean(child.imageOptions) &&
                    child.imageOptions.length > 0 &&
                    child.imageOptions.map((opt, j) => (
                      <>
                        <RadioGroup>
                          <Radio checked={false}>
                            <img alt="option" style={{ width: "120px", height: "120px" }} src={opt.imageUrl} />
                          </Radio>
                        </RadioGroup>
                      </>
                    ))}
                </ColBoot>
              </FormBoot.Group>
            )}

            {Boolean(child.answerType) && child.answerType === "Rating" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot xs="12">
                  <Radio.Group buttonStyle="solid">
                    {this.getRatingList(child.rateMax).map((element, index) => (
                      <Radio.Button value={element}>{element}</Radio.Button>
                    ))}
                  </Radio.Group>
                </ColBoot>
              </FormBoot.Group>
            )}

            {Boolean(child.answerType) && child.answerType === "Boolean" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot xs="12">
                  <Switch checkedChildren={child.labelTrue} unCheckedChildren={child.labelFalse} defaultChecked />
                </ColBoot>
              </FormBoot.Group>
            )}

            {Boolean(child.answerType) && child.answerType === "Checkbox" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                {Boolean(child.options) &&
                  child.options.length > 0 &&
                  child.options.map((opt, j) => (
                    <>
                      <ColBoot style={{ marginBottom: "1rem" }} xs="11">
                        <Checkbox> {opt.codeName}</Checkbox>
                      </ColBoot>
                      <br />
                    </>
                  ))}
              </FormBoot.Group>
            )}

            {Boolean(child.answerType) && child.answerType === "Dropdown" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot xs="4">
                  <FormBoot.Control name="type" as="select">
                    <option value="Select">Select Option</option>
                    {Boolean(child.options) &&
                      child.options.length > 0 &&
                      child.options.map((opt, j) => <option value={opt.codeName}>{opt.codeName}</option>)}
                  </FormBoot.Control>
                </ColBoot>
              </FormBoot.Group>
            )}

            {Boolean(child.answerType) && child.answerType === "File" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot xs="4">
                  <Upload>
                    <AntButton>
                      <UploadOutlined /> Click to Upload
                    </AntButton>
                  </Upload>
                </ColBoot>
              </FormBoot.Group>
            )}

            {Boolean(child.answerType) && child.answerType === "Matrix_Single" && Boolean(child.columns) && child.columns.length > 0 && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot> </ColBoot>
                {child.columns.map((col, j) => (
                  <ColBoot>{col.codeName}</ColBoot>
                ))}
              </FormBoot.Group>
            )}

            {Boolean(child.answerType) && child.answerType === "Matrix_Multiple" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot> </ColBoot>
                {child.columns.map((col, j) => (
                  <ColBoot>{col.codeName}</ColBoot>
                ))}
              </FormBoot.Group>
            )}

            {Boolean(child.answerType) &&
              child.answerType === "Matrix_Single" &&
              Boolean(child.columns) &&
              child.columns.length > 0 &&
              Boolean(child.matrixValues) &&
              child.matrixValues.length > 0 &&
              child.matrixValues.map((row, k) => (
                <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <ColBoot>{row.title}</ColBoot>
                  {child.columns.map((col, j) => (
                    <ColBoot>
                      <Radio checked={false}></Radio>
                    </ColBoot>
                  ))}
                </FormBoot.Group>
              ))}

            {Boolean(child.answerType) &&
              child.answerType === "Matrix_Multiple" &&
              Boolean(child.matrixValues) &&
              child.matrixValues &&
              child.matrixValues.map((row, k) => (
                <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <ColBoot> {row.title} </ColBoot>
                  {child.columns.map((col, j) => (
                    <ColBoot>
                      <FormBoot.Control name="type" as="select">
                        <option value="Select">Select Option</option>
                        {Boolean(child.options) &&
                          child.options.length > 0 &&
                          child.options.map((opt, j) => <option value={opt.codeId}>{opt.codeName}</option>)}
                      </FormBoot.Control>
                    </ColBoot>
                  ))}
                </FormBoot.Group>
              ))}
          </div>
          <br />
        </>
      )
    );
  };

  getRatingList = max => {
    const numbers = [];
    for (let index = 1; index <= max; index++) {
      numbers.push(index);
    }
    return numbers;
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="DailyCheck.Equipment" defaultMessage="Equipment" />,
        key: "equipmentLogId",
        render: record => {
          return record.equipmentLogName;
        },
        ...getColumnFilter({
          inputName: "equipmentLogId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["equipmentLogIdSelectItems"]) ? this.state["equipmentLogIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="DailyCheck.Km" defaultMessage="Km" />,
        key: "km",
        render: record => {
          return record.km;
        },
        ...getColumnFilter({
          inputName: "km",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="DailyCheck.Hour" defaultMessage="Hour" />,
        key: "hour",
        render: record => {
          return record.hour;
        },
        ...getColumnFilter({
          inputName: "hour",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="DailyCheck.Date" defaultMessage="Date" />,
        key: "date",
        render: record => {
          return record.date ? moment(record.date).format("DD-MM-YYYY HH:mm") : "";
        },
        sorter: true
      },
      {
        title: "Last Update",
        key: "lastUpdated",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
              <ExcelColumn label="Name" value="name" />
              <ExcelColumn label="Arabic Name" value="arabic_name" />
              <ExcelColumn label="Customer" value="customer" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <Badge.Ribbon text="Daily Check" placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <br />
            <Row gutter={[16, 16]}>
              <Col md={21}> </Col>
              <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>

            <div hidden={this.state.hideInputs}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="DailyCheck.Equipment" defaultMessage="Equipment" />}
                name="equipmentLogId"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={this.equipmentSearch}
                  style={{ marginBottom: 0, width: "100%" }}
                  loading={this.state.loadingSearch}
                  onChange={value => {
                    this.setState({
                      equipmentLogId: value
                    });
                  }}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {Boolean(this.state["equipmentLogIdSelectItems"]) &&
                    this.state["equipmentLogIdSelectItems"].length > 0 &&
                    this.state["equipmentLogIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="DailyCheck.Km" defaultMessage="Km" />}
                name="km"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="DailyCheck.Hour" defaultMessage="Hour" />}
                name="hour"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="DailyCheck.Date" defaultMessage="Date" />}
                name="date"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime readOnly />
              </Form.Item>

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              rowKey="id"
              size="small"
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          <DraggableModal
            title={<FormattedMessage id="GapAnalysis.Checklist" defaultMessage="Checklist" />}
            centered
            width={1200}
            open={this.state.modalChecklistShow}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState({ modalChecklistShow: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    const itemIndex = this.state.itemIndex;
                    if (itemIndex > 0) {
                      this.setState({
                        itemIndex: itemIndex - 1
                      });
                    }
                  }}
                >
                  Previous
                </Button>
                <Button
                  variant="success"
                  onClick={() => {
                    const { itemIndex } = this.state;
                    this.setState({
                      itemIndex: itemIndex + 1
                    });
                  }}
                >
                  Next
                </Button>
              </Space>
            ]}
            content={
              Boolean(this.state.tableList) && this.state.tableList.length > 0 ? (
                <>
                  {this.state.tableList.map((item, index) => (
                    <>{this.renderElementSingle(item)}</>
                  ))}
                </>
              ) : (
                ""
              )
            }
          />
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DailyCheck);
