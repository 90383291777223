import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Modal as AntModal, InputNumber } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../../excel/ExcelReader";
import { Input } from "antd";
import { Select } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ActivityLogWorkFlow from "./ActivityLogWorkFlow";
import { excelExport } from "../../../ExcelHelper";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import DraggableModal from "../../../Components/DraggableModal";

const { Option } = Select;

export class ActivityLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ActivityLog",
      controllerName: "activityLog",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      isSafetyHovered: false,
      isSurveyingHovered: false,
      isConstructionHovered: false,
      isQualityHovered: false
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseDisciplineId = await handleRequest("GET", "/api/" + this.state.controllerName + "/disciplineId");
    if (responseDisciplineId.type === "ERROR") {
      error(responseDisciplineId);
    }
    var responsePhaseId = await handleRequest("GET", "/api/" + this.state.controllerName + "/phaseId");
    if (responsePhaseId.type === "ERROR") {
      error(responsePhaseId);
    }
    this.setState({
      disciplineIdSelectItems: Boolean(responseDisciplineId.data) ? responseDisciplineId.data : [],
      phaseIdSelectItems: Boolean(responsePhaseId.data) ? responsePhaseId.data : []
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  workFlowModal = record => {
    this.setState({
      random: Math.random(),
      showWorkFlowModal: true,
      selectedActivityLogWorkFlow: record
    });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };
    let workFlowAction = {
      name: "Work Flow",
      icon: <CreateIcon fontSize="small" color="primary" />,
      actionClick: this.workFlowModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(workFlowAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/typeAll/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columnsExcel = [
      { header: "S/N", key: "index" },
      { header: "Discipline", key: "disciplineName" },
      { header: "Phase", key: "phaseName" },
      { header: "Activity", key: "activity" }
    ];

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ActivityLog.Phase" defaultMessage="Phase" />,
        key: "phaseId",
        render: record => {
          return record.phaseName;
        },
        ...getColumnFilter({
          inputName: "phaseId",
          inputType: "SELECT",
          selectItems: Boolean(this.state.phaseIdSelectItems) && this.state.phaseIdSelectItems.length > 0 ? this.state.phaseIdSelectItems : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ActivityLog.disciplineId" defaultMessage="Discipline" />,
        key: "disciplineId",
        render: record => {
          return record.disciplineName;
        },
        ...getColumnFilter({
          inputName: "disciplineId",
          inputType: "SELECT",
          selectItems:
            Boolean(this.state.disciplineIdSelectItems) && this.state.disciplineIdSelectItems.length > 0 ? this.state.disciplineIdSelectItems : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ActivityLog.activity" defaultMessage="Activity" />,
        key: "activity",
        render: record => {
          return record.activity;
        },
        ...getColumnFilter({
          inputName: "activity",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
    ];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="ActivityLog.pageTitle" defaultMessage="Activity Log" />}
        placement="start"
        color="purple"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={21}></Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => {
                    excelExport(columnsExcel, this.state.tableList);
                  }}
                  icon={<ExportIcon color="action" fontSize="large" />}
                />
              </Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>
            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ActivityLog.phaseId" defaultMessage="Phase" />}
                  name="phaseId"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["phaseIdSelectItems"]) &&
                      this.state["phaseIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ActivityLog.disciplineId" defaultMessage="Discipline" />}
                  name="disciplineId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["disciplineIdSelectItems"]) &&
                      this.state["disciplineIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ActivityLog.Activity" defaultMessage="Activity" />}
                  name="activity"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>

              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>
          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          {/* WORKFLOW */}
          <DraggableModal
            centered
            width={1500}
            title="Work Flow"
            open={this.state.showWorkFlowModal}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ showWorkFlowModal: false })}
            content={
              <ActivityLogWorkFlow
                selectedActivityLogWorkFlow={this.state.selectedActivityLogWorkFlow}
                random={Math.random()}
                close={() => this.setState({ showWorkFlowModal: false })}
              />
            }
          />

          {/* SAFETY */}
          {
            <AntModal
              centered
              width={1300}
              title="Safety"
              open={this.state.showSafetyModal}
              okButtonProps={{ hidden: false }}
              onCancel={() => this.setState({ showSafetyModal: false })}
              onOk={() => this.formRefSafety.current.submit()}
            >
              <Form initialValues={{ remember: false }} onFinish={this.saveMaterial} onFinishFailed={onFinishFailed} ref={this.formRefCreateMaterial}>
                {
                  <Form.List name="safetyList">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered responsive="xl">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Action"} defaultMessage={"Action"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Person"} defaultMessage={"Person"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Items"} defaultMessage={"Items"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.SupportingItems"} defaultMessage={"Supporting Items"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Tools"} defaultMessage={"Tools"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Equipment"} defaultMessage={"Equipment"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Device"} defaultMessage={"Device"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Control"} defaultMessage={"Control"} />
                                </th>
                                <th colSpan="2" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Duration"} defaultMessage={"Duration"} />
                                </th>
                                <th colSpan="1"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item style={{ width: "100%" }} name={[field.name, "action"]}>
                                        <Input noStyle />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "personId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["personIdSelectItems"]) &&
                                            this.state["personIdSelectItems"].length > 0 &&
                                            this.state["personIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "itemsId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["itemsIdSelectItems"]) &&
                                            this.state["itemsIdSelectItems"].length > 0 &&
                                            this.state["itemsIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "supportingItemsId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["supportingItemsIdSelectItems"]) &&
                                            this.state["supportingItemsIdSelectItems"].length > 0 &&
                                            this.state["supportingItemsIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "toolsId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["toolsIdSelectItems"]) &&
                                            this.state["toolsIdSelectItems"].length > 0 &&
                                            this.state["toolsIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "equipmentId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["equipmentIdSelectItems"]) &&
                                            this.state["equipmentIdSelectItems"].length > 0 &&
                                            this.state["equipmentIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "deviceId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["deviceIdSelectItems"]) &&
                                            this.state["deviceIdSelectItems"].length > 0 &&
                                            this.state["deviceIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "controlId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["controlIdSelectItems"]) &&
                                            this.state["controlIdSelectItems"].length > 0 &&
                                            this.state["controlIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "duration"]}>
                                        <InputNumber
                                          // style={{ width: "100%" }}
                                          noStyle
                                          min={0}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "durationId"]}>
                                        <Select style={{ width: "100%" }} optionFilterProp="children">
                                          {Boolean(this.state["durationIdSelectItems"]) &&
                                            this.state["durationIdSelectItems"].length > 0 &&
                                            this.state["durationIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ActivityLog.AddNewMaterial"} defaultMessage={"Add New"} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                }
              </Form>
            </AntModal>
          }

          {/* SURVEYING */}
          {
            <AntModal
              centered
              width={1300}
              title="Safety"
              open={this.state.showSurveyingModal}
              okButtonProps={{ hidden: false }}
              onCancel={() => this.setState({ showSurveyingModal: false })}
              onOk={() => this.formRefSurveying.current.submit()}
            >
              <Form initialValues={{ remember: false }} onFinish={this.saveMaterial} onFinishFailed={onFinishFailed} ref={this.formRefSurveying}>
                {
                  <Form.List name="surveyingList">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered responsive="xl">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Action"} defaultMessage={"Action"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Person"} defaultMessage={"Person"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Items"} defaultMessage={"Items"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.SupportingItems"} defaultMessage={"Supporting Items"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Tools"} defaultMessage={"Tools"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Equipment"} defaultMessage={"Equipment"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Device"} defaultMessage={"Device"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Control"} defaultMessage={"Control"} />
                                </th>
                                <th colSpan="2" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Duration"} defaultMessage={"Duration"} />
                                </th>
                                <th colSpan="1"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item style={{ width: "100%" }} name={[field.name, "action"]}>
                                        <Input noStyle />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "personId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["personIdSelectItems"]) &&
                                            this.state["personIdSelectItems"].length > 0 &&
                                            this.state["personIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "itemsId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["itemsIdSelectItems"]) &&
                                            this.state["itemsIdSelectItems"].length > 0 &&
                                            this.state["itemsIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "supportingItemsId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["supportingItemsIdSelectItems"]) &&
                                            this.state["supportingItemsIdSelectItems"].length > 0 &&
                                            this.state["supportingItemsIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "toolsId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["toolsIdSelectItems"]) &&
                                            this.state["toolsIdSelectItems"].length > 0 &&
                                            this.state["toolsIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "equipmentId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["equipmentIdSelectItems"]) &&
                                            this.state["equipmentIdSelectItems"].length > 0 &&
                                            this.state["equipmentIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "deviceId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["deviceIdSelectItems"]) &&
                                            this.state["deviceIdSelectItems"].length > 0 &&
                                            this.state["deviceIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "controlId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["controlIdSelectItems"]) &&
                                            this.state["controlIdSelectItems"].length > 0 &&
                                            this.state["controlIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "duration"]}>
                                        <InputNumber
                                          // style={{ width: "100%" }}
                                          noStyle
                                          min={0}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "durationId"]}>
                                        <Select style={{ width: "100%" }} optionFilterProp="children">
                                          {Boolean(this.state["durationIdSelectItems"]) &&
                                            this.state["durationIdSelectItems"].length > 0 &&
                                            this.state["durationIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ActivityLog.AddNewMaterial"} defaultMessage={"Add New"} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                }
              </Form>
            </AntModal>
          }

          {/* CONSTRUCTION */}
          {
            <AntModal
              centered
              width={1300}
              title="Safety"
              open={this.state.showConstructionModal}
              okButtonProps={{ hidden: false }}
              onCancel={() => this.setState({ showConstructionModal: false })}
              onOk={() => this.formRefConstruction.current.submit()}
            >
              <Form initialValues={{ remember: false }} onFinish={this.saveMaterial} onFinishFailed={onFinishFailed} ref={this.formRefConstruction}>
                {
                  <Form.List name="constructionList">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered responsive="xl">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Action"} defaultMessage={"Action"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Person"} defaultMessage={"Person"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Items"} defaultMessage={"Items"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.SupportingItems"} defaultMessage={"Supporting Items"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Tools"} defaultMessage={"Tools"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Equipment"} defaultMessage={"Equipment"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.ItemSettlement"} defaultMessage={"Item Settlement"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.MeasuringDevice"} defaultMessage={"Measuring Device"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Control"} defaultMessage={"Control"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.ControlDevice"} defaultMessage={"Control Device"} />
                                </th>
                                <th colSpan="2" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Duration"} defaultMessage={"Duration"} />
                                </th>
                                <th colSpan="1"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item style={{ width: "100%" }} name={[field.name, "action"]}>
                                        <Input noStyle />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "personId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["personIdSelectItems"]) &&
                                            this.state["personIdSelectItems"].length > 0 &&
                                            this.state["personIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "itemsId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["itemsIdSelectItems"]) &&
                                            this.state["itemsIdSelectItems"].length > 0 &&
                                            this.state["itemsIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "supportingItemsId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["supportingItemsIdSelectItems"]) &&
                                            this.state["supportingItemsIdSelectItems"].length > 0 &&
                                            this.state["supportingItemsIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "toolsId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["toolsIdSelectItems"]) &&
                                            this.state["toolsIdSelectItems"].length > 0 &&
                                            this.state["toolsIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "equipmentId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["equipmentIdSelectItems"]) &&
                                            this.state["equipmentIdSelectItems"].length > 0 &&
                                            this.state["equipmentIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "itemSettlementId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["itemSettlementIdSelectItems"]) &&
                                            this.state["itemSettlementIdSelectItems"].length > 0 &&
                                            this.state["itemSettlementIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "measuringDeviceId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["measuringDeviceIdSelectItems"]) &&
                                            this.state["measuringDeviceIdSelectItems"].length > 0 &&
                                            this.state["measuringDeviceIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "controlId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["controlIdSelectItems"]) &&
                                            this.state["controlIdSelectItems"].length > 0 &&
                                            this.state["controlIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "controlDeviceId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["controlDeviceIdSelectItems"]) &&
                                            this.state["controlDeviceIdSelectItems"].length > 0 &&
                                            this.state["controlDeviceIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "duration"]}>
                                        <InputNumber
                                          // style={{ width: "100%" }}
                                          noStyle
                                          min={0}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "durationId"]}>
                                        <Select style={{ width: "100%" }} optionFilterProp="children">
                                          {Boolean(this.state["durationIdSelectItems"]) &&
                                            this.state["durationIdSelectItems"].length > 0 &&
                                            this.state["durationIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ActivityLog.AddNewMaterial"} defaultMessage={"Add New"} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                }
              </Form>
            </AntModal>
          }

          {/* QUALITY */}
          {
            <AntModal
              centered
              width={1300}
              title="Safety"
              open={this.state.showQualityModal}
              okButtonProps={{ hidden: false }}
              onCancel={() => this.setState({ showQualityModal: false })}
              onOk={() => this.formRefQuality.current.submit()}
            >
              <Form initialValues={{ remember: false }} onFinish={this.saveMaterial} onFinishFailed={onFinishFailed} ref={this.formRefQuality}>
                {
                  <Form.List name="constructionList">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered responsive="xl">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Action"} defaultMessage={"Action"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Person"} defaultMessage={"Person"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.InspectionActivity"} defaultMessage={"Inspection Activity"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.MeasuringDevice"} defaultMessage={"Measuring Device"} />
                                </th>
                                <th colSpan="2" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ActivityLog.Duration"} defaultMessage={"Duration"} />
                                </th>
                                <th colSpan="1"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item style={{ width: "100%" }} name={[field.name, "action"]}>
                                        <Input noStyle />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "personId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["personIdSelectItems"]) &&
                                            this.state["personIdSelectItems"].length > 0 &&
                                            this.state["personIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "inspectionActivityId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["inspectionActivityIdSelectItems"]) &&
                                            this.state["inspectionActivityIdSelectItems"].length > 0 &&
                                            this.state["inspectionActivityIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "measuringDeviceId"]}>
                                        <Select style={{ width: "100%" }} mode="multiple">
                                          {Boolean(this.state["measuringDeviceIdSelectItems"]) &&
                                            this.state["measuringDeviceIdSelectItems"].length > 0 &&
                                            this.state["measuringDeviceIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "duration"]}>
                                        <InputNumber
                                          // style={{ width: "100%" }}
                                          noStyle
                                          min={0}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "durationId"]}>
                                        <Select style={{ width: "100%" }} optionFilterProp="children">
                                          {Boolean(this.state["durationIdSelectItems"]) &&
                                            this.state["durationIdSelectItems"].length > 0 &&
                                            this.state["durationIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ActivityLog.AddNewMaterial"} defaultMessage={"Add New"} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                }
              </Form>
            </AntModal>
          }
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ActivityLog);
