import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { Form, Select, InputNumber, Col, Row } from "antd";
import { handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";

const { Option } = Select;

export class UnitConverter extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      unitGroupList: [],
      unitList: [],
      unitList1: [],
      unitList2: [],
      unitId1: null,
      unitId2: null,
      value1: null,
      value2: null, // for input number.

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    let url = "/api/unitType";
    const responseUnitType = await handleRequest("GET", url);

    if (responseUnitType.status === 200) {
      this.setState({ unitGroupList: responseUnitType.data });
    }

    let url2 = "/api/unit";
    const responseUnit = await handleRequest("GET", url2);

    if (responseUnit.status === 200) {
      this.setState({ unitList: responseUnit.data });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/service/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/service/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = { ...values, id: this.state.id };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/service", newItem);
    } else {
      response = await handleRequest("PUT", "/api/service/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/service/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          {
            <Row gutter={[16, 16]}>
              <Col md={6}></Col>
              <Col md={12}>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Unit Group"
                  className="mb-4"
                  value={this.state.unitGroupId}
                  onChange={value => {
                    this.setState({ unitGroupId: value });

                    let fUnitList = this.state.unitList.filter(unit => unit.unitTypeId === value);

                    this.setState({ unitList1: fUnitList, unitList2: fUnitList, unitId1: null, unitId2: null, value1: null, value2: null });
                  }}
                >
                  {this.state.unitGroupList.map(i => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          }
          {
            <Row gutter={[16, 16]}>
              <Col md={6}></Col>
              <Col md={6}>
                <Select
                  style={{ width: "100%" }}
                  className="mb-4"
                  placeholder="Unit"
                  value={this.state.unitId1}
                  onChange={value => {
                    this.setState({ unitId1: value });

                    this.setState({
                      value1: null,
                      value2: null
                    });
                  }}
                >
                  {this.state.unitList1.map(i => (
                    <Option key={i.id} value={i.id}>
                      {i.description}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col md={6}>
                <Select
                  style={{ width: "100%" }}
                  className="mb-4"
                  placeholder="Unit"
                  value={this.state.unitId2}
                  onChange={value => {
                    this.setState({ unitId2: value });

                    this.setState({
                      value1: null,
                      value2: null
                    });
                  }}
                >
                  {this.state.unitList2.map(i => (
                    <Option key={i.id} value={i.id}>
                      {i.description}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          }
          {
            <Row gutter={[16, 16]}>
              <Col span={6}></Col>
              <Col md={6}>
                <InputNumber
                  placeholder="Quantity"
                  style={{ width: "100%" }}
                  value={this.state.value1}
                  onChange={value => {
                    this.setState({ value1: value });
                    const { unitId1, unitId2 } = this.state;
                    if (unitId1 && unitId2) {
                      let value2 =
                        (value * this.state.unitList1.find(unit => unit.id === unitId1).coefficent) /
                        this.state.unitList2.find(unit => unit.id === unitId2).coefficent;
                      this.setState({ value2: value2 });
                    }
                  }}
                />
              </Col>
              <Col md={6}>
                <InputNumber
                  placeholder="Quantity"
                  style={{ width: "100%" }}
                  value={this.state.value2}
                  onChange={value => {
                    this.setState({ value2: value });
                    const { unitId1, unitId2 } = this.state;
                    if (unitId1 && unitId2) {
                      let value1 =
                        (value * this.state.unitList2.find(unit => unit.id === unitId2).coefficent) /
                        this.state.unitList1.find(unit => unit.id === unitId1).coefficent;
                      this.setState({ value1: value1 });
                    }
                  }}
                />
              </Col>
            </Row>
          }
        </Form>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UnitConverter);
