import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Select, Space, InputNumber, Checkbox } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Modal as AntModal } from "antd";
import { Input } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, SettingTwoTone, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import moment from "moment";

const { Option } = Select;

export class SanitarySewerLine extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "SanitarySewerLine",
      controllerName: "sanitarySewerLine",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      frontCodes: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();
  formRefTotalNumbersOfEndConnection = React.createRef();


  fillComboboxes = async () => {/**
    fillFilterOperations();
    var responseComponent = await handleRequest("GET", "/api/" + this.state.controllerName + "/component");
    if (responseComponent.type === "ERROR") {
      error(responseComponent);
    }
    this.setState({
      componentSelectItems: Boolean(responseComponent.data) ? responseComponent.data : [],
    });
     */
  };

  fillEndPointNo = async () => {
    this.fillCode("SanitarySewerLine.EndPoint");
    var responseEndPointId = await handleRequest("GET", "/api/" + this.state.controllerName + "/endPointId");
    if (responseEndPointId.type === "ERROR") {
      error(responseEndPointId);
    }

    this.setState({
      endPointIdSelectItems: Boolean(responseEndPointId.data) ? responseEndPointId.data : [],
    });
  };

  fillEndPointDescription = async () => {
    this.fillCode("SanitarySewerLine.EndPointDescription");
    var responsedescriptionOfEnd = await handleRequest("GET", "/api/" + this.state.controllerName + "/descriptionOfEnd");
    if (responsedescriptionOfEnd.type === "ERROR") {
      error(responsedescriptionOfEnd);
    }

    this.setState({
      descriptionOfEndSelectItems: Boolean(responsedescriptionOfEnd.data) ? responsedescriptionOfEnd.data : [],
    });
  };

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };



    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);



    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  saveTotalNumbersOfEndConnection = async values => {
    if (Boolean(values) && Boolean(values.totalNoOfEndConnection) && values.totalNoOfEndConnection > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveTotalNumbersOfEndConnection/" + values.totalNoOfEndConnection);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        this.fillEndPointNo();
        this.fillEndPointDescription();
        this.setState({
          TotalNumbersOfEndConnection: false,
          TotalSanitaryPipelines: true
        });
      }
    } else {
      showWarning("Not found.");
    }
  };


  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };


  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="SanitarySewerLine.endPoint" defaultMessage="End Point" />,
        key: "name",
        render: record => {
          return record.name;
        }, ...getColumnFilter({
          inputName: "name", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="SanitarySewerLine.pipeline" defaultMessage="Pipeline" />,
        key: "pipeline",
        render: record => {
          return record.pipeline;
        }, ...getColumnFilter({
          inputName: "pipeline", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="SanitarySewerLine.manhole" defaultMessage="Manhole" />,
        key: "manhole",
        render: record => {
          return record.manhole;
        }, ...getColumnFilter({
          inputName: "manhole", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="SanitarySewerLine.houseConnection" defaultMessage="House connection" />,
        key: "houseConnection",
        render: record => {
          if (Boolean(record.houseConnection)) {
            return moment(record.houseConnection).format("DD-MM-YYYY HH:mm");
          }
        }, sorter: true
      },
      {
        title: <FormattedMessage id="SanitarySewerLine.houseNo" defaultMessage="House No" />,
        key: "houseNo",
        render: record => {
          return record.houseNo;
        }, ...getColumnFilter({
          inputName: "houseNo", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
    ];

    const excelExport = (<>
      {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}         {this.state.exportClick ? (<ExcelFile filename="SanitarySewerLine" hideElement={true}>             <ExcelSheet name="SanitarySewerLine" data={this.state.tableList}>               <ExcelColumn label="Project" value="project" />
      <ExcelColumn label="Apartment No" value="itemGroup" />
      <ExcelColumn label="Description" value="item" />
      <ExcelColumn label="Apartment Type" value="requestDate" />
      <ExcelColumn label="Sections" value="quantity" />

    </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} 
    icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)}  */}
    </>);
    return (
      <Badge.Ribbon text={<FormattedMessage id="SanitarySewerLine_Form.pageTitle" defaultMessage="Sanitary Sewer Line" />} placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>             <Col md={20}></Col><Col md={1}>{excelExport}</Col>  <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={() => this.setState({ TotalNumbersOfEndConnection: true })} icon={<SettingTwoTone />}               ></AntButton>          <Col md={1}>               <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>             </Col>             <Col md={1}>               <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={this.onClickCreateNew} icon={<CreateIcon color="secondary" fontSize="large" />}               ></AntButton>               <AntButton style={{ border: "0px" }} hidden={this.state.hideInputs} onClick={this.onClickCreateNew} icon={<RemoveCircle color="error" fontSize="large" />}               ></AntButton>             </Col>           </Row>
            <div hidden={this.state.hideInputs}>


              <AntModal

                /**                title={<Badge.Ribbon text={<FormattedMessage id="TotalNumbersOfEndConnection.pageTitle" defaultMessage="Settings" />} 
                                   placement="start" 
                                   color="volcano" 
                                   style={{ fontSize: 12,marginTop: -25,height: 15 }}></Badge.Ribbon>
                                 }*/
                title={<FormattedMessage id="TotalNumbersOfEndConnection.pageTitle" defaultMessage="Settings" />}

                keyboard={false}
                maskClosable={false}
                open={this.state.TotalNumbersOfEndConnection}
                onCancel={() => {
                  this.setState({ TotalNumbersOfEndConnection: false });
                }}
                okButtonProps={{ hidden: false }}
                okText={<FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />}
                cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                onOk={() => {
                  this.setState({
                    TotalNumbersOfEndConnection: false,
                    TotalSanitaryPipelines: true
                  }, () => {
                    if (Boolean(this.formRefTotalSanitaryPipelines) && Boolean(this.formRefTotalSanitaryPipelines.current)) {
                      this.formRefTotalSanitaryPipelines.current.resetFields();
                    }
                    this.fillEndPointDescription();
                  });
                }}

                width={800}
                centered
              ><Form initialValues={{ remember: false }} onFinish={this.saveTotalNumbersOfEndConnection} ref={this.formRefTotalNumbersOfEndConnection}>
                  <br></br>
                  <Form.Item
                    label={<FormattedMessage id="SanitarySewerLine.totalNoOfEndConnection" defaultMessage="Total # of End Connection Lines" />}
                    name="totalNoOfEndConnection"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <InputNumber placeholder="number" style={{ width: "100%" }} />
                  </Form.Item>
                  <br />      <h4><FormattedMessage id={"SanitarySewerLine.totalNoOfEndConnection"} defaultMessage={"  "} /></h4>

                  <Form.List name="totalNoOfEndConnection">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"SanitarySewerLine.endPointNo"} defaultMessage={"End Point #"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"SanitarySewerLine.description"} defaultMessage={"Description"} />
                                </th>
                                <th colSpan="1">
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        name="endPointNo"
                                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="End Point #" /> }]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="End Point #">
                                          {Boolean(this.state["endPointNoSelectItems"]) &&
                                            this.state["endPointNoSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Input
                                          placeholder="Description"
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ExecutionPlan.AddNewPersonnel"} defaultMessage={"Add New Coordinate "} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </Form>
              </AntModal>


              <AntModal
                title={<Badge.Ribbon text={<FormattedMessage id="TotalSanitaryPipelines.pageTitle" defaultMessage="Total number(s) sanitary pipelines" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                closable="false"
                open={this.state.TotalSanitaryPipelines}
                onCancel={() => {
                  this.setState({ TotalSanitaryPipelines: false });
                }}
                width={800}
                centered
                footer={[
                  <Space>
                    <Button variant="secondary" onClick={() => { this.setState({ TotalSanitaryPipelines: false }); }}>

                      <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                    </Button>
                    <Button style={{ width: "100%" }} variant="success" onClick={() => {
                      this.setState({ TotalSanitaryPipelines: false });
                      this.setState({ SanitaryPipelines: true })
                    }}  >
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Space>
                ]}
              ><Form initialValues={{ remember: false }} onFinish={this.saveTotalSanitaryPipelines} ref={this.formRefTotalSanitaryPipelines}>
                  <Form.Item
                    label={<FormattedMessage id="TotalSanitaryPipelines.descriptionOfEnd" defaultMessage="End point description" />}
                    style={{ marginBottom: "5px" }}
                  >
                    <Row gutter={15}>
                      <Col span={10}>
                        <Form.Item name="labelTrue">
                          <Select
                            allowClear
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="End Point #"
                            onChange={value => {
                              this.setState({ answerType: value });
                            }}
                          >
                            {Boolean(this.state["endPointDescriptionSelectItems"]) && this.state["endPointDescriptionSelectItems"].length > 0 &&
                              this.state["endPointDescriptionSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={5}>
                        <Form.Item
                          label={<FormattedMessage id="TotalSanitaryPipelines.labelLanguageKey" defaultMessage="   " />}
                          name="descripiton"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input placeholder="number" style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item></Form>
              </AntModal>


              <AntModal
                title={<Badge.Ribbon text={<FormattedMessage id="SanitaryPipelines.pageTitle" defaultMessage="Sanitary Pipelines" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                closable="false"
                open={this.state.SanitaryPipelines}
                onCancel={() => {
                  this.setState({ SanitaryPipelines: false });
                }}
                width={800}
                centered
                footer={[
                  <Space>
                    <Button variant="secondary" onClick={() => { this.setState({ SanitaryPipelines: false }); }}>

                      <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                    </Button>
                    <Button style={{ width: "100%" }} variant="success" onClick={() => {
                      this.setState({ SanitaryPipelines: false });
                      this.setState({ NumberOfSanitaryManholes: true })
                    }}  >
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Space>
                ]}
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveSanitaryPipelines} ref={this.formRefSanitaryPipelines}>

                  <Form.Item
                    label={<FormattedMessage id="SanitaryPipelines.descriptionOfEnd" defaultMessage="Description of End Point" />}
                    style={{ marginBottom: "5px" }}
                  >
                    <Row gutter={15}>
                      <Col span={10}>
                        <Form.Item name="labelTrue">
                          <Select
                            allowClear
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="End Point #"
                            onChange={value => {
                              this.setState({ answerType: value });
                            }}
                          >
                            {Boolean(this.state["answerTypeSelectItems"]) && this.state["answerTypeSelectItems"].length > 0 &&
                              this.state["answerTypeSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={5}>
                        <Form.Item
                          label={<FormattedMessage id="SanitaryPipelines.labelLanguageKey" defaultMessage="   " />}
                          name="descripiton"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input placeholder="Descripiton" style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </AntModal>


              <AntModal
                title={<Badge.Ribbon text={<FormattedMessage id="SanitaryPipelines.pageTitle" defaultMessage="Numbers Of Sanitary Manholes" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                closable="false"
                open={this.state.NumberOfSanitaryManholes}
                onCancel={() => {
                  this.setState({ NumberOfSanitaryManholes: false });
                }}
                width={800}
                centered
                footer={[
                  <Space>
                    <Button variant="secondary" onClick={() => { this.setState({ NumberOfSanitaryManholes: false }); }}>

                      <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                    </Button>
                    <Button style={{ width: "100%" }} variant="success" onClick={() => {
                      this.setState({ NumberOfSanitaryManholes: false });
                      this.setState({ ManholeDescriptionModal: true })
                    }}  >
                      <FormattedMessage id="GeneralButtonSave" default32Message="Save" />
                    </Button>
                  </Space>
                ]}
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveNumberOfSanitaryManholes} ref={this.formRefNumberOfSanitaryManholes}>
                  <br />      <h4><FormattedMessage id={"ExecutionPlan.Personnel"} defaultMessage={"  "} /></h4>

                  <Form.List name="executionPlanPersonnel">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Personnel"} defaultMessage={"Sanitary Pipeline #"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Personnel"} defaultMessage={"  "} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Personnel"} defaultMessage={"  "} />
                                </th>
                                <th colSpan="1">
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "designationId"]}>
                                        <Select allowClear
                                          style={{ width: "100%" }}
                                          optionFilterProp="children"
                                        >
                                          {Boolean(this.state["designationIdSelectItems"]) && this.state["designationIdSelectItems"].length > 0 &&
                                            this.state["designationIdSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "responsibility"]}>
                                        <Input min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Checkbox
                                        onChange={e => {
                                          this.setState({
                                            includeVat: e.target.checked
                                          });
                                          this.formRef.current.setFieldsValue({
                                            taxRate: null
                                          });
                                        }}
                                      >
                                        {" "}
                                        <FormattedMessage id="paymentContractCustomer.includeVat" defaultMessage="Connection Manhole excluded" />{" "}
                                      </Checkbox>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ExecutionPlan.AddNewPersonnel"} defaultMessage={"Add New  "} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </Form>
              </AntModal>



              <AntModal
                title={<Badge.Ribbon text={<FormattedMessage id="SanitaryPipelines.pageTitle" defaultMessage="Manhole Description" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                closable="false"
                open={this.state.ManholeDescriptionModal}
                onCancel={() => {
                  this.setState({ ManholeDescriptionModal: false });
                }}
                width={800}
                centered
                footer={[
                  <Space>
                    <Button variant="secondary" onClick={() => { this.setState({ ManholeDescriptionModal: false }); }}>

                      <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                    </Button>
                    <Button style={{ width: "100%" }} variant="success" onClick={() => {
                      this.setState({ ManholeDescriptionModal: false });
                      this.setState({ ManholeAndHouseConnectionModal: true })
                    }}  >
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Space>
                ]}
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveManholeDescriptionModal} ref={this.formRefManholeDescriptionModal}>
                  <br></br>
                  <Form.Item
                    label={<FormattedMessage id="SanitaryPipelines.descriptionOfEnd" defaultMessage="Description of End Point" />}
                    style={{ marginBottom: "5px" }}
                  >
                    <Row gutter={16}>
                      <Col span={5}>
                        <Form.Item name="labelTrue">
                          <Select
                            allowClear
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="End Point #"
                            onChange={value => {
                              this.setState({ answerType: value });
                            }}
                          >
                            {Boolean(this.state["answerTypeSelectItems"]) && this.state["answerTypeSelectItems"].length > 0 &&
                              this.state["answerTypeSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item name="labelTrue">
                          <Select
                            allowClear
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Manhole #"
                            onChange={value => {
                              this.setState({ answerType: value });
                            }}
                          >
                            {Boolean(this.state["answerTypeSelectItems"]) && this.state["answerTypeSelectItems"].length > 0 &&
                              this.state["answerTypeSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}></Col>
                      <Col span={5}>
                        <Form.Item
                          label={<FormattedMessage id="SanitaryPipelines.labelLanguageKey" defaultMessage="  " />}
                          name="descripiton"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input placeholder="number" style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </AntModal>



              <AntModal
                title={<Badge.Ribbon text={<FormattedMessage id="SanitaryPipelines.pageTitle" defaultMessage="Manhole And House Connection" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                closable="false"
                open={this.state.ManholeAndHouseConnectionModal}
                onCancel={() => {
                  this.setState({ ManholeAndHouseConnectionModal: false });
                }}
                width={800}
                centered
                footer={[
                  <Space>
                    <Button variant="secondary" onClick={() => { this.setState({ ManholeAndHouseConnectionModal: false }); }}>

                      <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                    </Button>
                    <Button style={{ width: "100%" }} variant="success" onClick={() => {
                      this.setState({ ManholeAndHouseConnectionModal: false });
                      this.setState({ HouseConnectionDescripitonModal: true })
                    }}  >
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Space>
                ]}
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveManholeAndHouseConnectionModal} ref={this.formRefManholeAndHouseConnectionModal}>
                  <Form.Item

                    label={<FormattedMessage id="SanitaryPipelines.descriptionOfEnd" defaultMessage="Description of End Point" />}
                    style={{ marginBottom: "5px" }}
                  >
                    <Row gutter={17}>
                      <Col span={5}>
                        <Form.Item name="labelTrue">
                          <Select
                            allowClear
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Sanitary Pipelines"
                            onChange={value => {
                              this.setState({ answerType: value });
                            }}
                          >
                            {Boolean(this.state["answerTypeSelectItems"]) && this.state["answerTypeSelectItems"].length > 0 &&
                              this.state["answerTypeSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item name="labelTrue">
                          <Select
                            allowClear
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Manhole Decription"
                            onChange={value => {
                              this.setState({ answerType: value });
                            }}
                          >
                            {Boolean(this.state["answerTypeSelectItems"]) && this.state["answerTypeSelectItems"].length > 0 &&
                              this.state["answerTypeSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}></Col>
                      <Col span={5}>
                        <Form.Item
                          label={<FormattedMessage id="SanitaryPipelines.labelLanguageKey" defaultMessage="  " />}
                          name="descripiton"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input placeholder="number" style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item name="labelTrue">
                          <Select
                            allowClear
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="House #"
                            onChange={value => {
                              this.setState({ answerType: value });
                            }}
                          >
                            {Boolean(this.state["answerTypeSelectItems"]) && this.state["answerTypeSelectItems"].length > 0 &&
                              this.state["answerTypeSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </AntModal>


              <AntModal
                title={<Badge.Ribbon text={<FormattedMessage id="SanitaryPipelines.pageTitle" defaultMessage="House Connection Lines Descriptions" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                closable="false"
                open={this.state.HouseConnectionDescripitonModal}
                onCancel={() => {
                  this.setState({ HouseConnectionDescripitonModal: false });
                }}
                width={800}
                centered
                footer={[
                  <Space>
                    <Button variant="secondary" onClick={() => { this.setState({ HouseConnectionDescripitonModal: false }); }}>

                      <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                    </Button>
                    <Button style={{ width: "100%" }} variant="success" onClick={() => {
                      this.setState({ HouseConnectionDescripitonModal: false });
                      this.setState({ HouseConnectionsModal: true })
                    }}  >
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Space>
                ]}
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveHouseConnectionDescripitonModal} ref={this.formRefHouseConnectionDescripitonModal}>
                  <Form.Item

                    label={<FormattedMessage id="SanitaryPipelines.descriptionOfEnd" defaultMessage="House Connection Lines Description" />}
                    style={{ marginBottom: "5px" }}
                  >
                    <Row gutter={17}>
                      <Col span={5}>
                        <Form.Item name="labelTrue">
                          <Select
                            allowClear
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Sanitary Pipelines"
                            onChange={value => {
                              this.setState({ answerType: value });
                            }}
                          >
                            {Boolean(this.state["answerTypeSelectItems"]) && this.state["answerTypeSelectItems"].length > 0 &&
                              this.state["answerTypeSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item name="labelTrue">
                          <Select
                            allowClear
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Manhole Decription"
                            onChange={value => {
                              this.setState({ answerType: value });
                            }}
                          >
                            {Boolean(this.state["answerTypeSelectItems"]) && this.state["answerTypeSelectItems"].length > 0 &&
                              this.state["answerTypeSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}></Col>
                      <Col span={5}>
                        <Form.Item
                          label={<FormattedMessage id="SanitaryPipelines.labelLanguageKey" defaultMessage="  " />}
                          name="descripiton"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input placeholder="number" style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item></Form>
              </AntModal>


              <AntModal
                title={<Badge.Ribbon text={<FormattedMessage id="SanitaryPipelines.pageTitle" defaultMessage="House Connections" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                closable="false"
                open={this.state.HouseConnectionsModal}
                onCancel={() => {
                  this.setState({ HouseConnectionsModal: false });
                }}
                width={800}
                centered
                footer={[
                  <Space>
                    <Button variant="secondary" onClick={() => { this.setState({ HouseConnectionsModal: false }); }}>

                      <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                    </Button>
                    <Button style={{ width: "100%" }} variant="success" onClick={() => {
                      this.setState({ HouseConnectionsModal: false });
                      this.setState({ ManholeCoordinatesModal: true })
                    }}  >
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Space>
                ]}
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveHouseConnectionsModal} ref={this.formRefHouseConnectionsModal}>
                  <Form.Item

                    label={<FormattedMessage id="House Connections.descriptionOfEnd" defaultMessage="House Connections" />}
                    style={{ marginBottom: "5px" }}
                  >
                    <Row gutter={24}>
                      <Col span={5}>
                        <Form.Item name="labelTrue">
                          <Select
                            allowClear
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Sanitary Pipelines"
                            onChange={value => {
                              this.setState({ answerType: value });
                            }}
                          >
                            {Boolean(this.state["answerTypeSelectItems"]) && this.state["answerTypeSelectItems"].length > 0 &&
                              this.state["answerTypeSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item name="labelTrue">
                          <Select
                            allowClear
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Manhole Decription"
                            onChange={value => {
                              this.setState({ answerType: value });
                            }}
                          >
                            {Boolean(this.state["answerTypeSelectItems"]) && this.state["answerTypeSelectItems"].length > 0 &&
                              this.state["answerTypeSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}></Col>
                      <Col span={5}>
                        <Form.Item name="labelTrue">
                          <Select
                            allowClear
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Sanitary Pipelines"
                            onChange={value => {
                              this.setState({ answerType: value });
                            }}
                          >
                            {Boolean(this.state["answerTypeSelectItems"]) && this.state["answerTypeSelectItems"].length > 0 &&
                              this.state["answerTypeSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item name="labelTrue">
                          <Select
                            allowClear
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Manhole Decription"
                            onChange={value => {
                              this.setState({ answerType: value });
                            }}
                          >
                            {Boolean(this.state["answerTypeSelectItems"]) && this.state["answerTypeSelectItems"].length > 0 &&
                              this.state["answerTypeSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </AntModal>

              <AntModal
                title={<Badge.Ribbon text={<FormattedMessage id="ManholeCoordinatesModal.pageTitle" defaultMessage="Manhole Coordinates" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                closable="false"
                open={this.state.ManholeCoordinatesModal}
                onCancel={() => {
                  this.setState({ ManholeCoordinatesModal: false });
                }}
                width={800}
                centered
                footer={[
                  <Space>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        this.setState({ ManholeCoordinatesModal: false });
                      }}
                    >
                      <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                    </Button>
                    <Button style={{ width: "100%" }} variant="success" onClick={() => {
                      this.setState({ ManholeCoordinatesModal: false });
                      this.setState({ PipeJointCoordinates: true })
                    }}  >
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Space>
                ]}
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveManholeCoordinatesModal} ref={this.formRefManholeCoordinatesModal}>

                  <br>
                  </br>
                  <br></br>
                  <Form.Item
                    label={<FormattedMessage id="EldenLaborLaw.overtimeCriterias" defaultMessage="Manhole" />}
                    name="overtimeCriterias"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select style={{ width: "100%" }} mode="multiple" placeholder="Manhole Description">
                      {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                        this.state["overtimeCriteriasSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  <br />      <h4><FormattedMessage id={"APDetailsTypesOfHousesModal.SectionsOfApartments"} defaultMessage={"  "} /></h4>

                  <Form.List name="APDetailsTypesOfHouses">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Hole"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"N"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"E"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"El"} />
                                </th>
                                <th colSpan="1">
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Input
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ExecutionPlan.AddNewPersonnel"} defaultMessage={"Add New Coordinate "} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </Form>
              </AntModal>



              <AntModal
                title={<Badge.Ribbon text={<FormattedMessage id="PipeJointCoordinates.pageTitle" defaultMessage="Manhole Coordinates" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                closable="false"
                open={this.state.PipeJointCoordinates}
                onCancel={() => {
                  this.setState({ PipeJointCoordinates: false });
                }}
                width={800}
                centered
                footer={[
                  <Space>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        this.setState({ PipeJointCoordinates: false });
                      }}
                    >
                      <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                    </Button>
                    <Button style={{ width: "100%" }} variant="success" onClick={() => {
                      this.setState({ PipeJointCoordinates: false });
                      this.setState({ PipeJointCoordinates: true })
                    }}  >
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Space>
                ]}
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveManholeCoordinatesModal} ref={this.formRefManholeCoordinatesModal}>


                  <br>
                  </br>
                  <br></br>
                  <Form.Item
                    label={<FormattedMessage id="" defaultMessage=" " />}
                    style={{ marginBottom: "5px" }}
                  >
                    <Row gutter={8}>
                      <Col span={10}>
                        <Form.Item
                          name=" "
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select style={{ width: "100%" }} mode="multiple" placeholder="  ">
                            {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                              this.state["overtimeCriteriasSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}{" "}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        To
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          name="  "
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select style={{ width: "100%" }} mode="multiple" placeholder="   ">
                            {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                              this.state["overtimeCriteriasSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}{" "}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <br />      <h4><FormattedMessage id={"APDetailsTypesOfHousesModal.SectionsOfApartments"} defaultMessage={"  "} /></h4>

                  <Form.List name="APDetailsTypesOfHouses">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Hole"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"N"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"E"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"El"} />
                                </th>
                                <th colSpan="2" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Matl1"} />
                                </th>
                                <th colSpan="2" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"Matl2"} />
                                </th>
                                <th colSpan="1">
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Input
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "40%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "40%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name="overtimeCriterias"
                                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Overtime Criterias">
                                          {Boolean(this.state["overtimeCriteriasSelectItems"]) &&
                                            this.state["overtimeCriteriasSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="reservedQuantity"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber
                                          style={{
                                            width: "100%"
                                          }}
                                          min={1}
                                          max={this.state.availableQuantity}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}


                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ExecutionPlan.AddNewPersonnel"} defaultMessage={"Add New Coordinate "} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </Form>
              </AntModal>





              {<Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Button id="SanitarySewerLine.button" style={{ width: "100%" }} variant="secondary" onClick={this.buttonModal}>
                    <FormattedMessage id="SanitarySewerLine.button" defaultMessage="Button" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>} 				<br />
              <div hidden={this.state.hideSave}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>                     <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">                       <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
              <div hidden={this.state.hideUpdate}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>                       <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />                     </Button>                   </Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">                       <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (<div className="alert alert-custom alert-light">                 <div className="alert-icon">                   <FilterOutlined />                 </div>                 <div className="alert-text">                   {this.state.searchCriteriaModels.map((val, i) => { return (<div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">                         <span className="mr-2">{val.label}</span>                         <strong className="mr-2">{val.type}</strong>                         <span className="mr-2">{val.operationValue}</span>                         <span className="mr-2">{val.showValue}</span>                         <span>                           <Tooltip title="Remove">                             <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />                           </Tooltip>                         </span>                       </div>); })}                 </div>                 <div className="alert-close">                   <Tooltip title="Remove All">                     <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.setState({ searchCriteriaModels: [] }, () => { this.filterChangeItem([]); })} />                   </Tooltip>                 </div>               </div>)}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">           <Table bordered scroll={{ x: 900, scrollToFirstRowOnChange: true }} columns={columns} dataSource={this.state.tableList} loading={this.state.loading} onChange={this.handleTableChange} pagination={this.state.pagination} />         </div>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SanitarySewerLine);
