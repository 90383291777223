import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import {
  FileTextOutlined,
  AppstoreAddOutlined,
  FormOutlined,
} from '@ant-design/icons';

import { CandidatePortalHiringRequests } from '../humanresources/CandidatePortalHiringRequests';
import JobOffer from '../humanresources/JobOffer';

const { Sider, Content } = Layout;

const CandidatePortal = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedTab, setSelectedTab] = useState("hiringRequest");

  const handleMenuItemClick = (tab) => {
    setSelectedTab(tab);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout style={{ minHeight: '200vh' }}>
      <Sider
        width={200}
        theme="light"
        collapsible
        collapsed={collapsed}
        onCollapse={toggleCollapsed}
      >
        <Menu
          mode="vertical"
          theme="light"
          selectedKeys={[selectedTab]}
          onClick={(e) => handleMenuItemClick(e.key)}
        >
          <Menu.Item key="myCv" icon={<FileTextOutlined />}>
            My CV
          </Menu.Item>
          <Menu.Item key="myApplications" icon={<AppstoreAddOutlined />}>
            My Applications
          </Menu.Item>
          <Menu.Item key="hiringRequest" icon={<FormOutlined />}>
            Hiring Request
          </Menu.Item>
        </Menu>
      </Sider>

      <Layout>
        <Content style={{ padding: '8px', minHeight: 280 }}>
          {/* {collapsed ? (
            <MenuUnfoldOutlined onClick={toggleCollapsed} style={{ fontSize: '18px', marginBottom: '16px' }} />
          ) : (
            <MenuFoldOutlined onClick={toggleCollapsed} style={{ fontSize: '18px', marginBottom: '16px' }} />
          )} */}

          {selectedTab === "myCv" && (
            <JobOffer />
          )}

          {selectedTab === "myApplications" && (
            <div>
              MY Applications Content
            </div>
          )}

          {selectedTab === "hiringRequest" && (
            <div>
              <CandidatePortalHiringRequests />
            </div>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default CandidatePortal;