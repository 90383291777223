import { Column } from "@ant-design/charts";
import moment from "moment";
import React, { useEffect } from "react";
import { handleRequest } from "../../ApiConnector";
import { showError } from "../../MessageHelper";
import {DatePicker} from "antd";

const { RangePicker } = DatePicker;

function BarChart(props) {
  const [loading, setLoading] = React.useState(false);
  const [loadUrl, setLoadUrl] = React.useState(null);
  const [showHeader, setShowHeader] = React.useState(false);
  const [chartConfig, setChartConfig] = React.useState(null);
  const fillData = async (loadUrl, sdate, edate) => {
    let obj = {
      startDate: Boolean(sdate) ? moment(sdate).toDate() : moment()
          .subtract(1, "M")
          .toDate(),
      endDate: Boolean(edate) ? moment(edate).toDate() : moment().toDate()
    };
    const response = await handleRequest("POST", loadUrl, obj);
    if (response.type === "ERROR") {
      showError(response);
      setLoading(false);
    } else {
      setChartConfig(prev => ({
        ...prev,
        data: Boolean(response.data) ? response.data.lineChartProduct : [],
        isGroup: true,
        xField: "dateStr",
        yField: "quantity",
        seriesField: "product",
        legend: { position: "top-left" },
        label: {
          position: "middle",
          layout: [{ type: "interval-adjust-position" }, { type: "interval-hide-overlap" }, { type: "adjust-color" }]
        }
      }));
    }
  };

  useEffect(() => {
    const getData = async () => {
      await fillData(props.apiUrl);
    };
    getData().catch(console.error);
  }, []);
  return (
    <div className="col-lg-6">
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header align-items-center border-0 mt-4">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">Product Sales</span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">
                <RangePicker
                    value={Boolean(chartConfig) ? chartConfig.data.lastUpdateDate : null}
                    format="DD-MM-YYYY"
                    onChange={async (dates, dateStrings) => {
                      console.log(dates, dateStrings);
                      const getData = async () => {
                        await fillData(props.apiUrl, dates[0], dates[1]);
                      };
                      getData().catch(console.error);
                    }}
                />
            </span>
          </h3>
        </div>
        <div className="card-body pt-4">
          <div style={{ width: "100%" }}>{Boolean(chartConfig) && <Column {...chartConfig} />}</div>
        </div>
      </div>
    </div>
  );
}

export default BarChart;
