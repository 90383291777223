import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, InputNumber, } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Select } from "antd";
import { Modal as AntModal } from "antd";
import { Input } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, MinusCircleOutlined, PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const { Option } = Select;

export class SamplingDetail extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      modalSampling: false,
      formName: "SamplingDetail",
      controllerName: "samplingDetail",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      modalnewSamplingShowList: [],
      showCashInModal: false,
      selectedRowKeysCashIn: [],

      paginationCashIn: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  formRefNewSamplingModal = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };
  fillComboboxes = async () => {
    var responseinspectionCategory = await handleRequest("GET", "/api/" + this.state.controllerName + "/inspectionCategory");
    if (responseinspectionCategory.type === "ERROR") {
      error(responseinspectionCategory);
    }
    this.setState({
      inspectionCategorySelectItems: Boolean(responseinspectionCategory.data) ? responseinspectionCategory.data : []
    });
    var responsesampleSizeType = await handleRequest("GET", "/api/" + this.state.controllerName + "/sampleSizeType");
    if (responsesampleSizeType.type === "ERROR") {
      error(responsesampleSizeType);
    }
    this.setState({
      sampleSizeTypeSelectItems: Boolean(responsesampleSizeType.data) ? responsesampleSizeType.data : []
    });
    var responsemaxNonConformingType = await handleRequest("GET", "/api/" + this.state.controllerName + "/maxNonConformingType");
    if (responsemaxNonConformingType.type === "ERROR") {
      error(responsemaxNonConformingType);
    }
    this.setState({
      maxNonConformingTypeSelectItems: Boolean(responsemaxNonConformingType.data) ? responsemaxNonConformingType.data : []
    });
    var responsefutureAction = await handleRequest("GET", "/api/" + this.state.controllerName + "/futureAction");
    if (responsefutureAction.type === "ERROR") {
      error(responsefutureAction);
    }
    this.setState({
      futureActionSelectItems: Boolean(responsefutureAction.data) ? responsefutureAction.data : []
    });
  };
  saveCashIn = () => {
    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.resetFields();
    }
    this.setState(
      {
        showCashInModal: false,

        cashId: null,
        id: null,
        selectedRow: null
      },
      () => {
        const { paginationCashIn } = this.state;
        this.restartTableCashIn({ paginationCashIn });
      }
    );
  };
  cashInSelect = row => {
    if (Boolean(this.state.cashInType)) {
      this.setState({
        showCashInModal: true,
        selectedRow: null
      });
    } else {
      showError("Please select type");
    }
  };
  cashInModal = async () => {
    this.setState({
      showCashInModal: true
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      samplingId: this.props.selectedSamplingDetail.id,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let samplingDetailList = [];

    if (Boolean(row.sampleSizeType)) {
      if (row.sampleSizeType === "Length") {
        if (Boolean(row.sampleSize) && Boolean(row.lotSize) && row.lotSize > 0) {
          let result = (row.sampleSize * 100) / row.lotSize;

          let maxNonConformingLotSize = Math.ceil(result);

          row.maxNonConformingLotSize = Boolean(maxNonConformingLotSize) ? maxNonConformingLotSize : 0;
          row.maxNonConformingAmount = row.sampleSize;
        }
      } else if (row.sampleSizeType === "Percent") {
        if (Boolean(row.sampleSize) && Boolean(row.lotSize) && row.lotSize > 0) {
          let result = (row.sampleSize * 100) / row.lotSize;

          let maxNonConformingAmount = Math.ceil(result);

          row.maxNonConformingAmount = Boolean(maxNonConformingAmount) ? maxNonConformingAmount : 0;
          row.maxNonConformingLotSize = row.sampleSize;
        }
      }
    }

    samplingDetailList.push(row);

    this.formRef.current.setFieldsValue({
      ...row,
      samplingDetailList: samplingDetailList,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      futureAction: row.futureAction
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  newSamplingModal = async row => {
    this.setState({
      modalnewSamplingShow: true,
      modalnewSamplingList: row.samplingNewSampling
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      futureAction: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    if (Boolean(this.props.selectedSamplingDetail.id) && this.props.selectedSamplingDetail.id > 0) {
      var response = await handleRequest(
        "POST",
        "/api/" + this.state.controllerName + "/saveAllList/" + this.props.selectedSamplingDetail.id,
        values.samplingDetailList
      );

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showWarning("Sampling not found.");
    }
  };

  saveNewSamplingDetailList = async () => {
    const samplingDetailList = this.formRef.current.getFieldValue("samplingDetailList");
    const newSamplingDetailList = this.formRefNewSamplingModal.current.getFieldValue("newSamplingDetailList");
    let samplingDetailListIndex = samplingDetailList[this.state.fieldIndex];
    if (Boolean(newSamplingDetailList) && newSamplingDetailList.length > 0) {
      samplingDetailListIndex.newSamplingDetailList = this.formRefNewSamplingModal.current.getFieldValue("newSamplingDetailList");

      this.formRef.current.setFieldsValue({ samplingDetailList: samplingDetailList });
    }
    this.setState({
      newSamplingModal: false
    });
  };

  messageErrorr = async (value, fieldIndex) => {
    let newSamplingModal = false,
      acceptedConditionallyModal = false;
    if (Boolean(value) && value === "NewSampling") {
      const samplingDetailList = this.formRef.current.getFieldValue("samplingDetailList");
      let fieldIndexValue = samplingDetailList[fieldIndex];
      console.log("fieldIndex.lotSize", fieldIndex.lotSize);
      console.log("value.lotSize", value.lotSize);
      console.log("fieldIndexValue.lotSize", fieldIndexValue.lotSize);
      if (Boolean(fieldIndexValue.lotSize)) {
        newSamplingModal = true;
      } else {
        newSamplingModal = false;
        showWarning("Lot Size is Null");
      }
    } else if (Boolean(value) && value === "AcceptedConditionally") {
      acceptedConditionallyModal = true;
    }
    this.setState(
      {
        futureAction: value,
        fieldIndex: fieldIndex,
        newSamplingModal: newSamplingModal,
        acceptedConditionallyModal: acceptedConditionallyModal
      },
      () => {
        if (newSamplingModal) {
          let newSamplingDetailList = [];
          const samplingDetailList = this.formRef.current.getFieldValue("samplingDetailList");
          let fieldIndexValue = samplingDetailList[fieldIndex];
          newSamplingDetailList.push({ sizeLotSize: fieldIndexValue.size + ' ' + fieldIndexValue.lotSize });

          this.formRefNewSamplingModal.current.setFieldsValue({
            newSamplingDetailList: newSamplingDetailList
          });
        }
      }
    );
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  personnelModal = async row => {
    this.setState({
      modalPersonnelShow: true,
      modalPersonnelList: row.activityPlanningPersonnel
    });
  };

  calcLotSize = async () => {
    const samplingDetailList = this.formRef.current.getFieldValue("samplingDetailList");
    console.log("samplingDetailList", samplingDetailList);

    if (Boolean(samplingDetailList) && samplingDetailList.length > 0) {
      samplingDetailList.forEach((element, index) => {
        console.log("index", index);
        if (index > 0) {
          console.log("element", element);
          let element1 = samplingDetailList[index - 1];
          if (Boolean(element)) {
            element.size = element1.lotSize;
          } else {
            samplingDetailList[index] = { size: element1.lotSize };
          }
        } else {
          if (!Boolean(element)) {
            samplingDetailList[index] = { size: 1 };
          }
        }
      });
    }
    console.log("samplingDetailList", samplingDetailList);

    this.formRef.current.setFieldsValue({
      samplingDetailList: samplingDetailList
    });
    this.setState({
      samplingDetailList: samplingDetailList
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const layout2 = {
      labelCol: { span: 2 },
      wrapperCol: { span: 22 }
    };
    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="SamplingDetail.lotSize" defaultMessage="Lot Size" />,
        key: "lotSize",
        render: record => {
          return record.lotSize;
        },
        ...getColumnFilter({
          inputName: "lotSize",
          inputType: "Number  ",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SamplingDetail.sampleSize" defaultMessage="Sample Size" />,
        key: "sampleSize",
        render: record => {
          return record.sampleSize;
        },
        ...getColumnFilter({
          inputName: "sampleSize",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SamplingDetail.maxNonConformingItems" defaultMessage="Max NonConforming Items" />,
        key: "maxNonConforming",
        render: record => {
          return record.maxNonConforming;
        },
        ...getColumnFilter({
          inputName: "maxNonConforming",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SamplingDetail.sampling" defaultMessage="Sampling" />,
        key: "sampling",
        render: record => {
          return record.sampling;
        },
        ...getColumnFilter({
          inputName: "sampling",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="SamplingDetail.acceptedConditionally" defaultMessage="Accepted Conditionally" />,
        key: "acceptedConditionally",
        render: record => {
          return record.acceptedConditionally;
        },
        ...getColumnFilter({
          inputName: "acceptedConditionally",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
    ];
    const columnsPersonnelList = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="ActivityPlanningTableChild.Designation" defaultMessage="Designation" />,
        key: "id",
        render: record => {
          return record.designationName;
        }
      },
      {
        title: <FormattedMessage id="ActivityPlanningTableChild.Responsibility" defaultMessage="Responsibility" />,
        key: "id",
        render: record => {
          return record.responsibility;
        }
      },
      {
        title: <FormattedMessage id="ActivityPlanningTableChild.NumberOfPersonnel" defaultMessage="Number Of Personnel" />,
        key: "id",
        render: record => {
          return record.numberOfPersonnel;
        }
      },
      {
        title: <FormattedMessage id="ActivityPlanningTableChild.WorkingTime" defaultMessage="Working Time" />,
        key: "id",
        render: record => {
          return record.workingTime;
        }
      }
    ];
    const excelExport = (
      <>

        {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="SamplingDetail" hideElement={true}>

            <ExcelSheet name="SamplingDetail" data={this.state.tableList}>

              <ExcelColumn label="Lot Size" value="lotSize" />
              <ExcelColumn label="Sample Size" value="sampleSize" />
              <ExcelColumn label="Max NonConforming Items" value="maxNonConformingItems" />
              <ExcelColumn label="Sampling" value="sampling" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    let details =
      Boolean(this.formRef) &&
        Boolean(this.formRef.current) &&
        Boolean(this.formRef.current.getFieldValue("samplingDetailList")) &&
        this.formRef.current.getFieldValue("samplingDetailList").length > 0
        ? this.formRef.current.getFieldValue("samplingDetailList")
        : [];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="newSampling.pageTitle" defaultMessage="Sampling" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>

              <Col md={21}></Col> <Col md={1}>{excelExport}</Col>
              <Col md={1}>

                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>

                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>
            <div hidden={this.state.hideInputs}>
              <br />
              <h4>
                <FormattedMessage id={"SamplingChild.newSampling"} defaultMessage={"New Sampling"} />
              </h4>
              {console.log("details", details)}
              <Form.List name="samplingDetailList">
                {(fields, { add, remove }) => {
                  const handleAddClick = () => {
                    const samplingDetailList = this.formRef.current.getFieldValue("samplingDetailList");
                    if (Boolean(samplingDetailList) && samplingDetailList.length > 0) {
                      const isValid = samplingDetailList.every(element => Boolean(element) && Boolean(element.lotSize));
                      if (!isValid) {
                        showWarning("Lot size type is required.");
                      } else {
                        add();
                        this.calcLotSize();
                      }
                    } else {
                      add();
                      this.calcLotSize();
                    }
                  };

                  const handleRemoveClick = fieldKey => {
                    remove(fieldKey);
                    this.calcLotSize();
                  };
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="2" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"SamplingChild.lotSize"} defaultMessage={"Lot Size"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"SamplingChild.inspectionCategory"} defaultMessage={"Inspection Category"} />
                            </th>
                            <th colSpan="2" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"SamplingChild.sampleSize"} defaultMessage={"Sample Size"} />
                            </th>
                            <th colSpan="2" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"SamplingChild.maxNonConformingItems"} defaultMessage={"Max. Non-Conforming Items"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"SamplingChild.futureActions"} defaultMessage={"Future Actions"} />
                            </th>
                            <th colSpan="1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle name={[field.name, "size"]}>
                                    <InputNumber
                                      noStyle
                                      readOnly
                                      min={0}
                                      parser={value => value.replace(",", ".")}
                                      formatter={value => value.replace(".", ",")}
                                      addonBefore=">"
                                    />
                                  </Form.Item>
                                </td>

                                <td>
                                  <Form.Item noStyle name={[field.name, "lotSize"]}>
                                    <InputNumber
                                      onChange={value => {
                                        const updatedDetails = { ...details };
                                        updatedDetails[field.name].lotSize = value;

                                        this.formRef.current.setFieldsValue({
                                          samplingDetailList: updatedDetails,
                                        });
                                        this.setState({
                                          details: updatedDetails
                                        });
                                      }}
                                      noStyle
                                      min={
                                        Boolean(details) && details.length > 0 && Boolean(details[field.name]) && Boolean(details[field.name].size)
                                          ? details[field.name].size + 1
                                          : 0
                                      }
                                      parser={value => value.replace(",", ".")}
                                      formatter={value => value.replace(".", ",")}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "inspectionCategory"]}>
                                    <Select showSearch allowClear style={{ width: "100%" }} optionFilterProp="children"
                                      onChange={value => {
                                        const updatedDetails = { ...details };
                                        this.setState({
                                          details: updatedDetails
                                        });
                                      }}>
                                      {Boolean(this.state["inspectionCategorySelectItems"]) &&
                                        this.state["inspectionCategorySelectItems"].length > 0 &&
                                        this.state["inspectionCategorySelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td style={{ width: "10%" }}>
                                  <Form.Item noStyle name={[field.name, "sampleSizeType"]}>
                                    <Select showSearch allowClear style={{ width: "100%" }} optionFilterProp="children"
                                      onChange={value => {
                                        const updatedDetails = { ...details };

                                        updatedDetails[field.name].sampleSizeType = value;
                                        updatedDetails[field.name].sampleSize = value === "Percent" ? 1 : 0;

                                        console.log("updatedDetails", updatedDetails);

                                        this.formRef.current.setFieldsValue({
                                          samplingDetailList: updatedDetails,
                                        });
                                        this.setState({
                                          details: updatedDetails
                                        });
                                      }}>
                                      {Boolean(this.state["sampleSizeTypeSelectItems"]) &&
                                        this.state["sampleSizeTypeSelectItems"].length > 0 &&
                                        this.state["sampleSizeTypeSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  {details[field.name].sampleSizeType === "Length" ?
                                    <>
                                      <Form.Item noStyle name={[field.name, "sampleSize"]}>
                                        <InputNumber
                                          onChange={value => {
                                            console.log("element", details[field.name]);

                                            let result = (value * 100) / details[field.name].lotSize;
                                            console.log("result", result);
                                            const updatedDetails = { ...details };

                                            let maxNonConformingLotSize = Math.ceil(result);

                                            updatedDetails[field.name].maxNonConformingLotSize = Boolean(maxNonConformingLotSize) ? maxNonConformingLotSize : 0;
                                            updatedDetails[field.name].maxNonConformingAmount = value;
                                            updatedDetails[field.name].sampleSize = value;

                                            console.log("updatedDetails", updatedDetails);

                                            this.formRef.current.setFieldsValue({
                                              samplingDetailList: updatedDetails,
                                            });
                                            this.setState({
                                              details: updatedDetails
                                            });
                                          }}
                                          noStyle
                                          min={0}
                                          max={details[field.name].lotSize}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                        />
                                      </Form.Item>
                                    </>
                                    :
                                    details[field.name].sampleSizeType === "Percent" ?
                                      <Form.Item noStyle name={[field.name, "sampleSize"]}>
                                        <InputNumber
                                          onChange={value => {
                                            let result = (value * 100) / details[field.name].lotSize;
                                            const updatedDetails = { ...details };

                                            let maxNonConformingAmount = Math.ceil(result);

                                            updatedDetails[field.name].maxNonConformingAmount = Boolean(maxNonConformingAmount) ? maxNonConformingAmount : 0;
                                            updatedDetails[field.name].maxNonConformingLotSize = value;
                                            updatedDetails[field.name].sampleSize = value;

                                            console.log("updatedDetails", updatedDetails);

                                            this.formRef.current.setFieldsValue({
                                              samplingDetailList: updatedDetails,
                                            });
                                            this.setState({
                                              details: updatedDetails
                                            });
                                          }}
                                          noStyle
                                          min={1}
                                          max={100}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                        />
                                      </Form.Item>
                                      :
                                      <Form.Item noStyle name={[field.name, "sampleSize"]}>
                                        <InputNumber
                                          noStyle
                                          min={0}
                                          max={0}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                          onChange={value => {
                                            const updatedDetails = { ...details };
                                            this.setState({
                                              details: updatedDetails
                                            });
                                          }}
                                        />
                                      </Form.Item>
                                  }
                                </td>
                                <td style={{ width: "15%" }}>
                                  <Form.Item noStyle name={[field.name, "maxNonConformingType"]}>
                                    <Select showSearch allowClear style={{ width: "100%" }} optionFilterProp="children"
                                      onChange={value => {
                                        const updatedDetails = { ...details };

                                        updatedDetails[field.name].maxNonConformingType = value;
                                        updatedDetails[field.name].maxNonConforming = (value === "LotSize" || value === "SampleSize") ? 1 : 0;

                                        console.log("updatedDetails", updatedDetails);

                                        this.formRef.current.setFieldsValue({
                                          samplingDetailList: updatedDetails,
                                        });
                                        this.setState({
                                          details: updatedDetails
                                        });
                                      }}>
                                      {Boolean(this.state["maxNonConformingTypeSelectItems"]) &&
                                        this.state["maxNonConformingTypeSelectItems"].length > 0 &&
                                        this.state["maxNonConformingTypeSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  {details[field.name].maxNonConformingType === "Amount" ?
                                    <Form.Item noStyle name={[field.name, "maxNonConforming"]}>
                                      <InputNumber
                                        noStyle
                                        min={0}
                                        max={details[field.name].maxNonConformingAmount}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                        onChange={value => {
                                          const updatedDetails = { ...details };
                                          this.setState({
                                            details: updatedDetails
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                    :
                                    details[field.name].maxNonConformingType === "LotSize" ?
                                      <Form.Item noStyle name={[field.name, "maxNonConforming"]}>
                                        <InputNumber
                                          onChange={value => {
                                            console.log("maxNonConformingLotSize", details[field.name].maxNonConformingLotSize);
                                            this.setState({
                                              details: details,
                                            })
                                          }}
                                          noStyle
                                          min={1}
                                          max={details[field.name].maxNonConformingLotSize}
                                          parser={value => value.replace(",", ".")}
                                          formatter={value => value.replace(".", ",")}
                                        />
                                      </Form.Item>
                                      :
                                      details[field.name].maxNonConformingType === "SampleSize" ?
                                        <Form.Item noStyle name={[field.name, "maxNonConforming"]}>
                                          <InputNumber
                                            noStyle
                                            min={1}
                                            max={100}
                                            parser={value => value.replace(",", ".")}
                                            formatter={value => value.replace(".", ",")}
                                            onChange={value => {
                                              const updatedDetails = { ...details };
                                              this.setState({
                                                details: updatedDetails
                                              });
                                            }}
                                          />
                                        </Form.Item>
                                        :
                                        <Form.Item noStyle name={[field.name, "maxNonConforming"]}>
                                          <InputNumber
                                            noStyle
                                            min={0}
                                            max={0}
                                            parser={value => value.replace(",", ".")}
                                            formatter={value => value.replace(".", ",")}
                                            onChange={value => {
                                              const updatedDetails = { ...details };
                                              this.setState({
                                                details: updatedDetails
                                              });
                                            }}
                                          />
                                        </Form.Item>
                                  }
                                </td>
                                <td style={{ width: "15%" }}>
                                  <Form.Item noStyle name={[field.name, "futureAction"]}>
                                    <Select
                                      showSearch
                                      allowClear
                                      style={{ width: "100%" }}
                                      optionFilterProp="children"
                                      onChange={value => {
                                        this.messageErrorr(value, field.name);
                                      }}
                                    >
                                      {Boolean(this.state["futureActionSelectItems"]) &&
                                        this.state["futureActionSelectItems"].length > 0 &&
                                        this.state["futureActionSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>{field.name > 0 && <MinusCircleOutlined onClick={() => handleRemoveClick(field.name)} />}</td>
                              </tr>
                            );
                          })}

                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"SamplingChild.AddNewSampling"} defaultMessage={"Add New Sampling "} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot >
                    </>
                  );
                }}
              </Form.List>
              <AntModal>
                <Form.Item
                  name={"percent"}
                  extra={<FormattedMessage id="NewSampling.newSampleSizeType" defaultMessage="Percent" />}
                  style={{
                    display: "inline-block",
                    width: "calc(20%)",
                    marginBottom: "5px",
                    marginLeft: "5px"
                  }}
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select showSearch allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["percentSelectItems"]) &&
                      this.state["percentSelectItems"].length > 0 &&
                      this.state["percentSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="NewSampling.newSampleSize" defaultMessage="newSampleSize" />}
                  name="newSampleSize"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>

                <div className="card card-custom" style={{ minHeight: "100%" }}></div>
              </AntModal>
              {
                <AntModal
                  title={<Badge.Ribbon text={"New Sampling"} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                  keyboard={false}
                  maskClosable={false}
                  open={this.state.newSamplingModal}
                  onCancel={() => {
                    this.setState({ newSamplingModal: false });
                  }}
                  okButtonProps={{ hidden: false }}
                  okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
                  cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                  onOk={() => {
                    this.formRefNewSamplingModal.current.submit();
                  }}
                  width={800}
                  centered
                >
                  <Form
                    initialValues={{ remember: false }}
                    /** onFinish={this.saveInnerCoordinates}?*/
                    ref={this.formRefNewSamplingModal}
                    onFinish={this.saveNewSamplingDetailList}
                  >
                    <Form.List name="newSamplingDetailList">
                      {fields => {
                        return (
                          <>
                            <TableBoot bordered size="sm">
                              <thead>
                                <tr>
                                  {/* <th colSpan="1" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"SamplingChild.lotSizeMin"} defaultMessage={"  "} />
                                  </th> */}
                                  <th colSpan="1" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"SamplingChild.lotSize"} defaultMessage={"Lot Size"} />
                                  </th>
                                  <th colSpan="1" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"SamplingChild.inspectionCategory"} defaultMessage={"Inspection Category"} />
                                  </th>
                                  <th colSpan="2" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"SamplingChild.sampleSize"} defaultMessage={"Sample Size"} />
                                  </th>
                                  <th colSpan="2" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"SamplingChild.maxNonConformingItems"} defaultMessage={"Max. Non-Conforming Items"} />
                                  </th>
                                  <th colSpan="1" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"SamplingChild.futureActions"} defaultMessage={"Future Actions"} />
                                  </th>
                                  <th colSpan="1"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {fields.map(field => {
                                  return (
                                    <tr key={field.key}>
                                      <Form.Item hidden name={[field.name, "newSamplingDetailList"]}></Form.Item>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "sizeLotSize"]}>
                                          <Input readOnly noStyle />
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "inspectionCategory"]}>
                                          <Select showSearch allowClear style={{ width: "100%" }} optionFilterProp="children">
                                            {Boolean(this.state["inspectionCategorySelectItems"]) &&
                                              this.state["inspectionCategorySelectItems"].length > 0 &&
                                              this.state["inspectionCategorySelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                  {i.value}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      </td>
                                      <td style={{ width: "10%" }}>
                                        <Form.Item noStyle name={[field.name, "sampleSizeType"]}>
                                          <Select showSearch allowClear style={{ width: "100%" }} optionFilterProp="children">
                                            {Boolean(this.state["sampleSizeTypeSelectItems"]) &&
                                              this.state["sampleSizeTypeSelectItems"].length > 0 &&
                                              this.state["sampleSizeTypeSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                  {i.value}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "sampleSize"]}>
                                          <InputNumber
                                            noStyle
                                            min={0}
                                            parser={value => value.replace(",", ".")}
                                            formatter={value => value.replace(".", ",")}
                                          />
                                        </Form.Item>
                                      </td>
                                      <td style={{ width: "15%" }}>
                                        <Form.Item noStyle name={[field.name, "maxNonConformingType"]}>
                                          <Select showSearch allowClear style={{ width: "100%" }} optionFilterProp="children">
                                            {Boolean(this.state["maxNonConformingTypeSelectItems"]) &&
                                              this.state["maxNonConformingTypeSelectItems"].length > 0 &&
                                              this.state["maxNonConformingTypeSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                  {i.value}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "maxNonConforming"]}>
                                          <InputNumber
                                            noStyle
                                            min={0}
                                            parser={value => value.replace(",", ".")}
                                            formatter={value => value.replace(".", ",")}
                                          />
                                        </Form.Item>
                                      </td>
                                      <td style={{ width: "15%" }}>
                                        <Form.Item noStyle name={[field.name, "futureAction"]}>
                                          <Select
                                            allowClear
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            onChange={value => {
                                              let newSamplingModal = false,
                                                acceptedConditionallyModal = false;
                                              if (Boolean(value) && value === "NewSampling") {
                                                newSamplingModal = true;
                                              } else if (Boolean(value) && value === "AcceptedConditionally") {
                                                acceptedConditionallyModal = true;
                                              }
                                              this.setState({
                                                futureAction: value,
                                                acceptedConditionallyModal: acceptedConditionallyModal
                                              });

                                              if (newSamplingModal) {
                                                let newSamplingDetailList = this.formRefNewSamplingModal.current.getFieldValue(
                                                  "newSamplingDetailList"
                                                );
                                                const samplingDetailList = this.formRef.current.getFieldValue("samplingDetailList");
                                                let fieldIndexValue = samplingDetailList[this.state.fieldIndex];
                                                newSamplingDetailList.push({ sizeLotSize: fieldIndexValue.size + ' ' + fieldIndexValue.lotSize });

                                                this.formRefNewSamplingModal.current.setFieldsValue({
                                                  newSamplingDetailList: newSamplingDetailList
                                                });
                                              }
                                            }}
                                          >
                                            {Boolean(this.state["futureActionSelectItems"]) &&
                                              this.state["futureActionSelectItems"].length > 0 &&
                                              this.state["futureActionSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                  {i.value}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </TableBoot>
                          </>
                        );
                      }}
                    </Form.List>
                  </Form>
                </AntModal>
              }
              {
                <AntModal
                  title={<Badge.Ribbon text={"New Sampling"} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
                  keyboard={false}
                  maskClosable={false}
                  open={this.state.acceptedConditionallyModal}
                  onCancel={() => {
                    this.setState({ acceptedConditionallyModal: false });
                  }}
                  okButtonProps={{ hidden: false }}
                  okText={<FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />}
                  cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                  onOk={() => {
                    this.formRefAcceptedConditionallyModal.current.submit();
                  }}
                  width={800}
                  centered
                >
                  <Form
                    initialValues={{ remember: false }}
                    /** onFinish={this.saveformRefAcceptedConditionallyModal}?*/ ref={this.formRefAcceptedConditionallyModal}
                  >
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="NewSampling.newSampleSize" defaultMessage="newSampleSize" />}
                      name="newSampleSize"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  </Form>
                </AntModal>
              }
              <div hidden={this.state.hideSave}>

                {
                  <Row gutter={[16, 16]}>

                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>

                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">

                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>

                {
                  <Row gutter={[16, 16]}>

                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>

                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>

                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>

                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">

                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>
          {
            Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
              <div className="alert alert-custom alert-light">

                <div className="alert-icon">

                  <FilterOutlined />
                </div>
                <div className="alert-text">

                  {this.state.searchCriteriaModels.map((val, i) => {
                    return (
                      <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">

                        <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                        <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                        <span>

                          <Tooltip title="Remove">

                            <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                          </Tooltip>
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="alert-close">

                  <Tooltip title="Remove All">

                    <CloseCircleOutlined
                      style={{ color: "crimson" }}
                      onClick={() =>
                        this.setState({ searchCriteriaModels: [] }, () => {
                          this.filterChangeItem([]);
                        })
                      }
                    />
                  </Tooltip>
                </div>
              </div>
            )
          }
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">

            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>
        </div >
      </Badge.Ribbon >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SamplingDetail);