import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import UploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button as AntButton, Col, Row, Tooltip, DatePicker } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import DataTableComponent2 from "../../Components/DataTableComponent2";
import { GetDynamicFormItems } from "../../Components/ElementUtils";
import { error, showError, showSuccess } from "../../MessageHelper";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { CloseOutlined } from "@ant-design/icons";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";

export class ComListing extends Component {
    constructor() {
        super();
        this.state = {
            formName: "ComListing",
            controllerName: "comListing",
            modalDeleteShow: false,
            modalExportShow: false,
            exportList: [],
            exportClick: false,
            hideUpdate: true,
            hideSave: false,
            hideInputs: true,
            lastUpdateDate: [],
            id: null,
            tableList: [],
            restartTable: false,
            pageFormData: null,
            excelData: [],
            createWhereParams: null,
            refreshForm: false
        };
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        let data = await GetDynamicFormItems(this.state.formName);
        this.setState({
            pageFormData: data
        });

        this.fillComboboxes();
    };
    componentDidUpdate = async nextProps => { };
    fillComboboxes = async () => { };

    setData = data => {
        this.setState({
            tableList: data
        });
    };

    deleteModal = row => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    };

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };

    delete = async () => {
        const deletedItem = {
            id: this.state.id
        };
        var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

        if (response.type === "ERROR") {
            error(response);
        } else {
            this.restartTable();
            showSuccess();
            this.setState({
                id: null,
                modalDeleteShow: false
            });
        }
    };

    exportModal = (type) => {
        this.setState({
            date: null,
            modalExportShow: true,
            type: type
        });
    };

    showOrHideExportModal = () => {
        this.setState({
            modalExportShow: !this.state.modalExportShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };

    restartTable = async () => {
        this.setState({ restartTable: !this.state.restartTable });
    };

    handleExportExcel = async () => {
        var newObj = {
            dateStr: moment(this.state.date).format("DD-MM-YYYY")
        }
        const response = await handleRequest("POST", "/api/" + this.state.controllerName + "/export", newObj);
        if (response.type === "ERROR") {
            showError("");
        }
        else {
            if (Boolean(response.data) && response.data.length !== 0) {
                response.data.forEach((element, index) => {
                    element.index = index + 1;
                });
                this.setState({
                    exportList: response.data,
                    exportClick: true,
                });
            } else {
                this.setState({
                    exportList: [],
                    exportClick: true,
                });
            }
        }
    }

    handleUploadExcel = async excelJson => {
        if (Boolean(this.state.date)) {
            try {
                var list = [];
                if (excelJson === null) showError("Select an excel file");
                else {
                    excelJson.forEach(element => {
                        const obj = {};
                        this.state.pageFormData.pageFormInputCompanyList.forEach(input => {
                            Boolean(input.tableColumnValue) ? obj[input.tableColumnValue] = element[input.label] : obj[input.inputName] = element[input.label];
                        });
                        obj.date = this.state.date;
                        list.push(obj);
                    });
                    if (Boolean(list) && list.length > 0) {
                        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
                        if (response.type === "ERROR") {
                            showError("No records to be added");
                        } else {
                            this.restartTable();
                            showSuccess();
                        }
                    } else {
                        showError("No records to be added");
                    }
                }
            } catch (error) {
                showError("");
            }
            this.setState({
                modalExportShow: false,
            });
        } else {
            showError("Please select date");
        }
    };

    action = record => {
        return (
            <React.Fragment>
                <DynamicActionMenu
                    row={record}
                    actionList={[
                        {
                            name: "Delete",
                            icon: <DeleteIcon fontSize="small" color="error" />,
                            actionClick: this.deleteModal.bind(this)
                        }
                    ]}
                />
            </React.Fragment>
        );
    };

    render() {
        const excelExport = (
            <>
                {/* {this.state.exportClick
                    ? this.setState({
                        exportClick: false,
                        modalExportShow: false
                    })
                    : ""}
                {this.state.exportClick ? (
                    <ExcelFile filename={this.state.formName} hideElement={true}>
                        <ExcelSheet name="ComListing" data={this.state.exportList}>
                            <ExcelColumn label="S/N" value="index" />
                            {Boolean(this.state.pageFormData) &&
                                this.state.pageFormData.pageFormInputCompanyList.map(item => {
                                    return (
                                        <ExcelColumn
                                            key={item.inputName}
                                            label={item.label}
                                            value={Boolean(item.tableColumnValue) ? item.tableColumnValue : item.inputName}
                                        />
                                    );
                                })}
                        </ExcelSheet>
                    </ExcelFile>
                ) : (
                        <Tooltip title="Export Data">
                            <AntButton style={{ border: "0px" }} onClick={() => this.exportModal("Export")} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
                        </Tooltip>
                    )
                } */}
            </>
        );

        return (
            <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
                <Row gutter={[16, 16]}>
                    <Col md={22}>
                        <span className="h3">
                            <FormattedMessage
                                id={this.state.controllerName + ".pageTitle"}
                                defaultMessage={Boolean(this.state.pageFormData) ? this.state.pageFormData.pageForm.title : ""}
                            />
                        </span>
                    </Col>
                    <Col md={1}>
                        {excelExport}
                    </Col>
                    <Col md={1}>
                        <Tooltip title="Import From Excel">
                            <AntButton style={{ border: "0px" }} onClick={() => this.exportModal("Import")} icon={<UploadIcon color="primary" fontSize="large" />}></AntButton>
                        </Tooltip>
                    </Col>
                </Row>
                <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                    {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
                        <DataTableComponent2
                            key="dataTable"
                            field={this.state.pageFormData.pageForm}
                            inputs={this.state.pageFormData.pageFormInputCompanyList}
                            actions={this.action}
                            restartTable={this.state.restartTable}
                            createWhereParams={this.state.createWhereParams}
                            setData={this.setData.bind(this)}
                        />
                    ) : null}
                </div>
                <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                            Cancel  </Button>
                        <Button variant="danger" onClick={this.delete}>
                            Delete   </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.modalExportShow} onHide={this.showOrHideExportModal} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        {this.state.type === "Export" && "Export Data !"}
                        {this.state.type === "Import" && "Import Data !"}
                        <AntButton
                            style={{ marginLeft: "auto" }}
                            onClick={this.showOrHideExportModal}
                            icon={<CloseOutlined color="secondary" />}
                        ></AntButton>
                    </Modal.Header>
                    <Modal.Body>
                        <DatePicker
                            id="date"
                            format="DD-MM-YYYY"
                            value={this.state.date}
                            onChange={(value) => {
                                var date = null;
                                if (Boolean(value)) {
                                    var strDate = moment(value).format("DD/MM/YYYY");
                                    var date = moment(
                                        strDate + " 00:00",
                                        "DD/MM/YYYY HH:mm"
                                    );
                                }
                                this.setState({
                                    date: date,
                                });
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.type === "Export" && <Button disabled={Boolean(this.state.date) ? false : true} variant="danger" onClick={this.handleExportExcel}>
                            Export Data  </Button>}

                        {this.state.type === "Import" &&
                            <Tooltip title="Import">
                                <ExcelReader disabled={Boolean(this.state.date) ? false : true} onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
                            </Tooltip>
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ComListing);