import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import {
  Form,
  Table,
  Input,
  Select,
  DatePicker,
  Image,
  Button as AntButton,
  Switch,
  Col,
  Row,
  PageHeader,
  InputNumber,
  Badge,
  Tooltip,
  Drawer
} from "antd";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import { Resizable } from "react-resizable";
import { Settings } from "@material-ui/icons";
import { PageSettings } from "../administrator/pageSettings/PageSettings";
import store from "../../../redux/store";

const { RangePicker } = DatePicker;
const { Option } = Select;

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class InventoryPacking extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "InventoryPacking",
      controllerName: "inventory",
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideProductSave: false,
      hideProductUpdate: true,
      hideInputs: true,
      hideProductForm: true,
      lastUpdateDate: [],
      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      inventorySelectItems: [],
      hideChangeProductMessage: true,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      user: store.getState().auth.user
    };
  }
  currencyCodeSelectItems = [
    { value: "DOLLAR", label: "$" },
    { value: "EURO", label: "€" },
    { value: "POUND", label: "£" },
    { value: "SR", label: "SR" }
  ];
  formRef = React.createRef();
  formProductRef = React.createRef();

  componentDidMount = async () => {
    this.initPage();
  };
  initPage = async params => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    this.setState({
      hideProductForm: true,
      hideChangeProductMessage: true
    });
  };

  fillComboboxes = async () => {
    var responseInventoryList = await handleRequest("GET", "/api/inventory/notproductlist");
    if (responseInventoryList.type === "ERROR") {
      error(responseInventoryList);
    } else {
      this.setState({
        inventorySelectItems: Boolean(responseInventoryList.data) ? responseInventoryList.data : []
      });
    }
    var responseNameId = await handleRequest("GET", "/api/" + this.state.controllerName + "/name");
    if (responseNameId.type === "ERROR") {
      error(responseNameId);
    }
    this.setState({
      nameSelectItems: Boolean(responseNameId.data) ? responseNameId.data : []
    });
    var responseArabicNameId = await handleRequest("GET", "/api/" + this.state.controllerName + "/arabicName");
    if (responseArabicNameId.type === "ERROR") {
      error(responseArabicNameId);
    }
    this.setState({
      arabicNameSelectItems: Boolean(responseArabicNameId.data) ? responseArabicNameId.data : []
    });
  };
  getInventoryById = async id => {
    var responseInventory = await handleRequest("GET", "/api/inventory/" + id);
    if (responseInventory.type === "ERROR") {
      error(responseInventory);
    } else {
      this.formProductRef.current.resetFields();
      let newItem = {
        ...(Boolean(responseInventory.data.productDetail) ? responseInventory.data.productDetail : responseInventory.data)
      };
      if (!Boolean(responseInventory.data.productDetail)) {
        newItem.name = responseInventory.data.materialName;
        newItem.arabicName = responseInventory.data.arabicMaterialName;
        newItem.productCode = responseInventory.data.materialCode;
        newItem.description = responseInventory.data.remarks;
      }
      this.formProductRef.current.setFieldsValue({
        ...newItem,
        inventoryId: id
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideProductSave: false,
      hideProductUpdate: true,
      hideInputs: true,
      hideProductForm: true
    });
    this.resetInputs();
    const { pagination } = this.state;
    this.restartTable({ pagination });
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.formProductRef.current.resetFields();
    this.setState({
      id: null,
      inventoryProductId: null,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };
  setSelectedFiles = field => {
    let fileList = [];
    if (Boolean(field) && field.length > 0) {
      let files = field.split(",");
      for (let i = 0; i < files.length; i++) {
        fileList.push({
          uid: i,
          name: files[i],
          url: files[i],
          thumbUrl: files[i]
        });
      }
      return fileList;
    }
    return [];
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = [];

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      fileList: fileList
    });
  };
  editProduct = row => {
    document.getElementById("kt_scrolltop").click();

    this.formProductRef.current.resetFields();
    let newItem = {
      ...(Boolean(row.productDetail) ? row.productDetail : row)
    };
    if (!Boolean(row.productDetail)) {
      newItem.name = row.materialName;
      newItem.arabicName = row.arabicMaterialName;
      newItem.productCode = row.materialCode;
      newItem.description = row.remarks;
    }
    this.formProductRef.current.setFieldsValue({
      ...newItem,
      inventoryId: row.id
    });

    this.setState({
      hideInputs: true,
      hideSave: true,
      hideUpdate: false,
      hideProductSave: Boolean(row.productDetail) ? true : false,
      hideProductUpdate: Boolean(row.productDetail) ? false : true,
      id: row.id,
      hideProductForm: false,
      inventoryProductId: Boolean(row.productDetail) ? row.productDetail.id : null
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("POST", "/api/inventory/product/" + deletedItem.id + "/delete");

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.initPage();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/inventory/product/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideProductSave: false,
        hideProductUpdate: true,
        hideUpdate: true,
        hideProductForm: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideProductSave: true,
        hideProductUpdate: false,
        hideSave: true,
        hideUpdate: false,
        hideProductForm: true
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values
    };
    var response = await handleRequest("POST", "/api/inventory/product/" + newItem.inventoryId + "/create", {});

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        hideChangeProductMessage: false,
        id: newItem.inventoryId,
        hideInputs: true
      });
    }
  };
  handleChangeProduct = async () => {
    this.getInventoryById(this.state.id);
    this.setState({
      hideProductForm: false,
      hideChangeProductMessage: true
    });
  };
  handleChangeProductCancel = async () => {
    this.cancel();
    this.initPage();
    showSuccess();
  };
  saveProduct = async values => {
    const newItem = {
      ...values,
      id: this.state.inventoryProductId,
      inventoryId: this.state.id
    };
    if (!Boolean(this.state.inventoryProductId)) {
      var response = await handleRequest("POST", "/api/inventoryProduct", newItem);
    } else {
      response = await handleRequest("PUT", "/api/inventoryProduct/" + this.state.inventoryProductId, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      this.initPage();
      showSuccess();
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/inventoryPacking/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.editProduct.bind(this)} />
      </React.Fragment>
    );
  };
  getImageGalleryFromUrls = (paths, galleryName) => {
    if (Boolean(paths) && paths.length > 0) {
      let pathList = paths.split(",");
      // for (let i = 0; i < pathList.length; i++) {
      //   images.push(<Image key={i} width={80} src={pathList[i].trim()} />);
      // }

      return (
        <>
          {Boolean(paths) && paths.length > 0 ? (
            <Image
              width={80}
              preview={{
                visible: false
              }}
              src={pathList[0].trim()}
              onClick={() => this.setState({ [galleryName]: true })}
            />
          ) : null}
          {Boolean(pathList) && pathList.length > 0 ? (
            <div style={{ display: "none" }}>
              <Image.PreviewGroup
                preview={{
                  visible: this.state[galleryName],
                  onVisibleChange: vis => this.setState({ [galleryName]: vis })
                }}
              >
                {pathList.map((field, i) => (
                  <Image key={i} src={field} />
                ))}
              </Image.PreviewGroup>
            </div>
          ) : null}
        </>
      );
    }
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: { marginBottom: 0 }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        width: 100,
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="InventoryProduct.category" defaultMessage="Category" />,
        key: "category",
        width: 100,
        render: record => {
          return record.item.itemCategory.name;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Category"
              id="itemCategoryId"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  itemCategoryId: value,
                  itemCategoryIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["companyProjectIdSelectItems"]) &&
                this.state["companyProjectIdSelectItems"].length > 0 &&
                this.state["companyProjectIdSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}{" "}
            </Select>
          </div>
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryProduct.materialCode" defaultMessage="Code" />,
        key: "materialCode",
        width: 100,
        render: record => {
          return Boolean(record.productDetail) ? record.productDetail.productCode : record.materialCode;
        }
      },
      {
        title: <FormattedMessage id="InventoryProduct.name" defaultMessage="Name" />,
        key: "name",
        width: 100,
        render: record => {
          return Boolean(record.productDetail) ? record.productDetail.name : record.materialName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Name"
              id="materialName"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  materialName: value,
                  materialNameColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["nameSelectItems"]) &&
                this.state["nameSelectItems"].length > 0 &&
                this.state["nameSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}{" "}
            </Select>
          </div>
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryProduct.arabicName" defaultMessage="Arabic Name" />,
        key: "arabicName",
        width: 100,
        render: record => {
          return Boolean(record.productDetail) ? record.productDetail.arabicName : record.arabicMaterialName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Arabic Name"
              id="arabicNameId"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  arabicNameId: value,
                  arabicNameIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["arabicNameSelectItems"]) &&
                this.state["arabicNameSelectItems"].length > 0 &&
                this.state["arabicNameSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}{" "}
            </Select>
          </div>
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Inventory.materialPhotoPaths" defaultMessage="Product Photos" />,
        key: "materialPhotoPaths",
        width: 100,
        render: record => {
          return this.getImageGalleryFromUrls(record.materialPhotoPaths, "gallery" + record.id);
        }
      },
      {
        title: <FormattedMessage id="InventoryProduct.description" defaultMessage="Description" />,
        key: "remarks",
        width: 100,
        render: record => {
          return Boolean(record.productDetail) ? record.productDetail.description : record.remarks;
        }
      },
      {
        title: <FormattedMessage id="InventoryProduct.unit" defaultMessage="Unit" />,
        key: "unit",
        width: 100,
        render: record => {
          return record.unit;
        }
      },
      {
        title: <FormattedMessage id="InventoryProduct.quantity" defaultMessage="Quantity" />,
        key: "quantity",
        width: 100,
        render: record => {
          return record.quantity;
        }
      },
      {
        title: <FormattedMessage id="InventoryProduct.reservedQuantity" defaultMessage="Reserved Quantity" />,
        key: "reservedQuantity",
        width: 100,
        render: record => {
          return record.reservedQuantity;
        }
      },
      {
        title: <FormattedMessage id="InventoryProduct.buyingPrice" defaultMessage="Buying Price" />,
        key: "buyingPrice",
        width: 100,
        render: record => {
          return Boolean(record.productDetail) ? record.productDetail.buyingPrice : record.buyingPrice;
        }
      },
      {
        title: <FormattedMessage id="InventoryProduct.sellingPrice" defaultMessage="Selling Price" />,
        key: "sellingPrice",
        width: 100,
        render: record => {
          return Boolean(record.productDetail) ? record.productDetail.sellingPrice : record.sellingPrice;
        }
      },
      {
        title: <FormattedMessage id="InventoryProduct.discount" defaultMessage="Discount" />,
        key: "discount",
        width: 100,
        render: record => {
          return record.discount;
        }
      },
      {
        title: <FormattedMessage id="InventoryProduct.active" defaultMessage="Status" />,
        key: "active",
        width: 100,
        render: record => {
          return record.active;
        }
      },
      {
        title: "Last Update",
        key: "lastUpdated",
        width: 100,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];

      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: handleResize(index)
            })
          }))
        : Boolean(columns) &&
          columns.length > 0 &&
          columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: handleResize(index)
            })
          }));

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
              exportClick: false
            })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Badge.Ribbon
          text={<FormattedMessage id="productLog.pageTitle" defaultMessage="Product Log" />}
          placement="start"
          color="volcano"
          style={{ fontSize: 12 }}
        >
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={20}></Col>
              <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
              <Col md={1}>
                {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                  <Tooltip title={<FormattedMessage id="RequestForQuotation.Settings" defaultMessage="Settings" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => {
                        this.setState({
                          drawerSettingsShow: true
                        });
                      }}
                      icon={<Settings color="error" fontSize="large" />}
                    ></AntButton>
                  </Tooltip>
                )}
              </Col>
            </Row>

            <div hidden={this.state.hideInputs}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InventoryProduct.Description" defaultMessage="Description" />}
                name="inventoryId"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select style={{ width: "100%" }} placeholder="Description" allowClear>
                  {Boolean(this.state.inventorySelectItems) &&
                    this.state.inventorySelectItems.map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.description}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InventoryProduct.code" defaultMessage="Code" />}
                name="inventoryId"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select style={{ width: "100%" }} placeholder="Description" allowClear>
                  {Boolean(this.state.inventorySelectItems) &&
                    this.state.inventorySelectItems.map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>
          <div style={{ textAlign: "center" }} hidden={this.state.hideChangeProductMessage}>
            <h3>Do you want to update product information?</h3>
            <Button variant="info" onClick={this.handleChangeProduct}>
              Yes
            </Button>
            <Button variant="secondary" onClick={this.handleChangeProductCancel}>
              No
            </Button>
          </div>
          <Form
            initialValues={{ remember: false }}
            onFinish={this.saveProduct}
            onFinishFailed={onFinishFailed}
            ref={this.formProductRef}
            hidden={this.state.hideProductForm}
          >
            <PageHeader className="site-page-header" title={"Product Details"} />
            <div>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InventoryProduct.name" defaultMessage="Name" />}
                name="name"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input placeholder="Name" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InventoryProduct.arabicName" defaultMessage="Arabic Name" />}
                name="arabicName"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input placeholder="الاسم العربي" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InventoryProduct.description" defaultMessage="Description" />}
                name="description"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input placeholder="Description" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InventoryProduct.productCode" defaultMessage="Code" />}
                name="productCode"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input placeholder="Code" style={{ width: "100%" }} />
              </Form.Item>
              {
                <Form.Item {...layout} label={<FormattedMessage id="InventoryProduct.buyingPrice" defaultMessage="Buying Price" />}>
                  <Row gutter={8}>
                    <Col span={8}>
                      <Form.Item name="buyingPrice">
                        <InputNumber min={0} placeholder="Buying Price" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        label={<FormattedMessage id="InventoryProduct.buyingPriceIncludeVat" defaultMessage="Include Vat?" />}
                        name="buyingPriceIncludeVat"
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              }{" "}
              {
                <Form.Item {...layout} label={<FormattedMessage id="InventoryProduct.sellingPrice" defaultMessage="Selling Price" />}>
                  <Row gutter={8}>
                    <Col span={8}>
                      {" "}
                      <Form.Item name="sellingPrice">
                        <InputNumber min={0} placeholder="Selling Price" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        label={<FormattedMessage id="InventoryProduct.sellingPriceIncludeVat" defaultMessage="Include Vat?" />}
                        name="sellingPriceIncludeVat"
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              }{" "}
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InventoryProduct.currencyCode" defaultMessage="Currency" />}
                name="currencyCode"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select allowClear>
                  {Boolean(this.currencyCodeSelectItems) &&
                    this.currencyCodeSelectItems.map(i => (
                      <Option key={i.value} value={i.value}>
                        {i.label}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              <div hidden={this.state.hideProductSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Don't Change" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideProductUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              rowKey="id"
              scroll={{ x: 900, y: 400, scrollToFirstRowOnChange: true }}
              components={{
                header: {
                  cell: ResizableTitle
                }
              }}
              columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          <Drawer
            title={<FormattedMessage id="Product.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ drawerSettingsShow: false });
            }}
            open={this.state.drawerSettingsShow}
          >
            <PageSettings pageName={"Products"} />
          </Drawer>
        </Badge.Ribbon>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryPacking);
