import React from "react";
import IconButton from "@material-ui/core/IconButton/IconButton";
import { FormattedMessage } from "react-intl";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@material-ui/core/Divider/Divider";
import Typography from "@material-ui/core/Typography/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import RevisionIcon from "@material-ui/icons/ViewList";
import SendIcon from "@material-ui/icons/Send";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import EmailIcon from "@material-ui/icons/Email";
import AttachFileIcon from "@material-ui/icons/AttachFile";
export default ({
  row,
  onDeleteRow,
  onEditRow,
  onRevisionRow,
  revisionVisible,
  onPreviewRow,
  previewVisible,
  onEnterPaymentRow,
  onShowDetail,
  onReviewRow,
  onApprovalRow,
  onAddBreakdown,
  onAddQuantity,
  onFeatures,
  onAddAnalysis,
  onPayment,
  onReceive,
  onPdfToReport,
  onCloseAndSend,
  onSendAll,
  onSendInvitation,
  onAddFile,
  onMeetingStart,
  onMeetingMinutes,
  award
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteRow = () => {
    if (onDeleteRow) {
      onDeleteRow(row);
      setAnchorEl(null);
    }
  };

  const editRow = () => {
    if (onEditRow) {
      onEditRow(row);
      setAnchorEl(null);
    }
  };

  const revisionRow = () => {
    if (onRevisionRow) {
      onRevisionRow(row);
      setAnchorEl(null);
    }
  };

  const enterPaymentRow = () => {
    if (onEnterPaymentRow) {
      onEnterPaymentRow(row);
      setAnchorEl(null);
    }
  };

  const previewRow = () => {
    if (onPreviewRow) {
      onPreviewRow(row);
      setAnchorEl(null);
    }
  };

  const showDetail = () => {
    if (onShowDetail) {
      onShowDetail(row);
      setAnchorEl(null);
    }
  };

  const reviewRow = () => {
    if (onReviewRow) {
      onReviewRow(row);
      setAnchorEl(null);
    }
  };

  const approvalRow = () => {
    if (onApprovalRow) {
      onApprovalRow(row);
      setAnchorEl(null);
    }
  };

  const addBreakdown = () => {
    if (onAddBreakdown) {
      onAddBreakdown(row);
      setAnchorEl(null);
    }
  };

  const addQuantity = () => {
    if (onAddQuantity) {
      onAddQuantity(row);
      setAnchorEl(null);
    }
  };

  const addAnalysis = () => {
    if (onAddAnalysis) {
      onAddAnalysis(row);
      setAnchorEl(null);
    }
  };

  const addPayment = () => {
    if (onPayment) {
      onPayment(row);
      setAnchorEl(null);
    }
  };

  const addReceive = () => {
    if (onReceive) {
      onReceive(row);
      setAnchorEl(null);
    }
  };

  const closeAndSend = () => {
    if (onCloseAndSend) {
      onCloseAndSend(row);
      setAnchorEl(null);
    }
  };

  const sendAll = () => {
    if (onSendAll) {
      onSendAll(row);
      setAnchorEl(null);
    }
  };

  const Features = () => {
    if (onFeatures) {
      onFeatures(row);
      setAnchorEl(null);
    }
  };
  const PdfToReport = () => {
    if (onPdfToReport) {
      onPdfToReport(row);
      setAnchorEl(null);
    }
  };
  const sendInvitation = () => {
    if (onSendInvitation) {
      onSendInvitation(row);
      setAnchorEl(null);
    }
  };

  const addFile = () => {
    if (onAddFile) {
      onAddFile(row);
      setAnchorEl(null);
    }
  };

  const meetingStart = () => {
    if (onMeetingStart) {
      onMeetingStart(row);
      setAnchorEl(null);
    }
  };
  const meetingMinutes = () => {
    if (onMeetingMinutes) {
      onMeetingMinutes(row);
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu" // flaticon-layer
        aria-haspopup="true"
        onClick={handleClick}
      >
        {/* <i className={`kt-menu__link-icon  flaticon-layer`} /> */}
        <MenuIcon color="primary" />
      </IconButton>
      <Menu
        id="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Boolean(onEditRow) && (
          <MenuItem onClick={editRow}>
            <ListItemIcon>
              <EditIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <Typography variant="inherit"><FormattedMessage id="ActionMenu.Edit" defaultMessage="Edit" /></Typography>
          </MenuItem>
        )}

        {Boolean(onEnterPaymentRow) && (
          <>
            <Divider />
            <MenuItem onClick={enterPaymentRow}>
              <ListItemIcon>
                <RevisionIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.EnterPayment" defaultMessage="Enter Payment" /></Typography>
            </MenuItem>
          </>
        )}

        {Boolean(onDeleteRow) && (
          <>
            <Divider />

            <MenuItem onClick={deleteRow}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" color="error" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.Delete" defaultMessage="Delete" /></Typography>
            </MenuItem>
          </>
        )}

        {Boolean(revisionVisible) && revisionVisible && (
          <>
            <Divider />
            <MenuItem onClick={revisionRow}>
              <ListItemIcon>
                <RevisionIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.Revision" defaultMessage="Revision" /></Typography>
            </MenuItem>
          </>
        )}

        {Boolean(previewVisible) && previewVisible && (
          <>
            <Divider />
            <MenuItem onClick={previewRow}>
              <ListItemIcon>
                <RevisionIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.Preview" defaultMessage="Preview" /></Typography>
            </MenuItem>
          </>
        )}

        {Boolean(onShowDetail) && (
          <>
            <Divider />
            <MenuItem onClick={showDetail}>
              <ListItemIcon>
                <RevisionIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.ShowDetail" defaultMessage="Show Detail" /></Typography>
            </MenuItem>
          </>
        )}

        {Boolean(onReviewRow) && (
          <>
            <Divider />
            <MenuItem onClick={reviewRow}>
              <ListItemIcon>
                <RevisionIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.Review" defaultMessage="Review" /></Typography>
            </MenuItem>
          </>
        )}

        {Boolean(onApprovalRow) && (
          <>
            <Divider />
            <MenuItem onClick={approvalRow}>
              <ListItemIcon>
                <RevisionIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.Approval" defaultMessage="Approval" /></Typography>
            </MenuItem>
          </>
        )}
        {Boolean(onAddBreakdown) && (
          <>
            <Divider />
            <MenuItem onClick={addBreakdown}>
              <ListItemIcon>
                <RevisionIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.AddBreakdown" defaultMessage="Add Breakdown" /></Typography>
            </MenuItem>
          </>
        )}
        {Boolean(onAddQuantity) && (
          <>
            <Divider />
            <MenuItem onClick={addQuantity}>
              <ListItemIcon>
                <RevisionIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.AddQuantity" defaultMessage="Add Quantity" /></Typography>
            </MenuItem>
          </>
        )}
        {Boolean(onAddAnalysis) && (
          <>
            <Divider />
            <MenuItem onClick={addAnalysis}>
              <ListItemIcon>
                <RevisionIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.AddAnalysis" defaultMessage="Add Analysis" /></Typography>
            </MenuItem>
          </>
        )}

        {Boolean(onPayment) && (
          <>
            <Divider />
            <MenuItem onClick={addPayment}>
              <ListItemIcon>
                <RevisionIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.Pay" defaultMessage="Pay" /></Typography>
            </MenuItem>
          </>
        )}

        {Boolean(onReceive) && (
          <>
            <Divider />
            <MenuItem onClick={addReceive}>
              <ListItemIcon>
                <RevisionIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.Receive" defaultMessage="Receive" /></Typography>
            </MenuItem>
          </>
        )}

        {Boolean(onFeatures) && (
          <>
            <Divider />
            <MenuItem onClick={Features}>
              <ListItemIcon>
                <RevisionIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.Features" defaultMessage="Features" /></Typography>
            </MenuItem>
          </>
        )}

        {Boolean(onPdfToReport) && (
          <>
            <Divider />
            <MenuItem onClick={PdfToReport}>
              <ListItemIcon>
                <PictureAsPdfIcon style={{ color: "red" }} fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.PdfReport" defaultMessage="Pdf Report" /></Typography>
            </MenuItem>
          </>
        )}

        {Boolean(onCloseAndSend) && (
          <>
            <Divider />
            <MenuItem onClick={closeAndSend}>
              <ListItemIcon>
                <SendIcon fontSize="small" color="action" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.CloseAndSend" defaultMessage="Close And Send" /></Typography>
            </MenuItem>
          </>
        )}

        {Boolean(onSendAll) && (
          <>
            <Divider />
            <MenuItem onClick={sendAll}>
              <ListItemIcon>
                <SendIcon fontSize="small" color="action" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.SendAll" defaultMessage="Send All" /></Typography>
            </MenuItem>
          </>
        )}

        {Boolean(onSendInvitation) && (
          <>
            <Divider />
            <MenuItem onClick={sendInvitation}>
              <ListItemIcon>
                <EmailIcon fontSize="small" style={{ color: "#1890ff" }} />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.SendInvitation" defaultMessage="Send Invitation" /></Typography>
            </MenuItem>
          </>
        )}
        {Boolean(onAddFile) && (
          <>
            <Divider />
            <MenuItem onClick={addFile}>
              <ListItemIcon>
                <AttachFileIcon fontSize="small" color="Warning" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.AddFile" defaultMessage="Add File" /></Typography>
            </MenuItem>
          </>
        )}

        {Boolean(onMeetingStart) && (
          <>
            <Divider />
            <MenuItem onClick={meetingStart}>
              <ListItemIcon>
                <EmailIcon fontSize="small" color="Warning" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.StartTheMeeting" defaultMessage="Start The Meeting" /></Typography>
            </MenuItem>
          </>
        )}

        {Boolean(onMeetingMinutes) && (
          <>
            <Divider />
            <MenuItem onClick={meetingMinutes}>
              <ListItemIcon>
                <EmailIcon fontSize="small" color="Warning" />
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="ActionMenu.MinutesOfMeeting" defaultMessage="Minutes Of Meeting" /></Typography>
            </MenuItem>
          </>
        )}
      </Menu>
    </div>
  );
};
