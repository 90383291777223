import { FilterOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Button as AntButton, Badge, Cascader, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Table, Divider } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess, showWarning } from "../../MessageHelper";
import ActionMenu from "../CustomMenu/ActionMenu";
import { ExcelReader } from "../excel/ExcelReader";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Viewer, { defaultLayout, Worker } from "@phuocng/react-pdf-viewer";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

const generateItemList = data => {
  let itemList = [];
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    itemList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children && node.children.length > 0) {
      itemList.push(...generateItemList(node.children));
    }
  }
  return itemList;
};
export class PurchaseAuthorization extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "purchaseAuthorization",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      authorityTypes: [],
      currencyTypes: [],
      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      modalPdfViewer: false,
      pdfFile: "",
      designationIdSelectItems: null,
      itemCategoryIdSelectItems: null,
      disabledPriceAuthorityTypes: [],
      disabledQuantityAuthorityTypes: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "designationId",
      sortOrder: "ascend",
      designationId: null,
      itemCategoryId: null,
      maxPriceSelectItems: null,

      excelData: []
    };
  }
  authoritySortList = ["Request", "Review", "Approval"];
  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination, sortField, sortOrder } = this.state;
    this.restartTable({ pagination, sortField, sortOrder });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.fillCode("StockCardItemClassId");

    var responseAuthority = await handleRequest("GET", "/api/purchaseAuthorization/authorityTypes");
    if (responseAuthority.type === "ERROR") {
      error(responseAuthority);
    } else {
      this.setState({
        authorityTypes: Boolean(responseAuthority.data) ? responseAuthority.data : []
      });
    }
    var responseCurrency = await handleRequest("GET", "/api/purchaseAuthorization/currencyTypes");
    if (responseCurrency.type === "ERROR") {
      error(responseCurrency);
    } else {
      this.setState({
        currencyTypes: Boolean(responseCurrency.data) ? responseCurrency.data : []
      });
    }
    var responsedesignationId = await handleRequest("GET", "/api/purchaseAuthorization/designationIdSelectItems");
    if (responsedesignationId.type === "ERROR") {
      error(responsedesignationId);
    } else {
      this.setState({
        designationIdSelectItems: Boolean(responsedesignationId.data) ? responsedesignationId.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      itemClassId: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "designationId",
      sortOrder: "ascend",
      filePath: "",
      fileList: [],
      disabledPriceAuthorityTypes: [],
      disabledQuantityAuthorityTypes: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let disabledPriceAuthorityTypes = [];
    let disabledQuantityAuthorityTypes = [];
    if (Boolean(row.purchaseAuthorizationPriceList)) {
      row.purchaseAuthorizationPriceList.forEach(element => {
        disabledPriceAuthorityTypes.push(element.procurementPurchasingAuthority);
        element.authorityList = [element.procurementPurchasingAuthority];
      });
    }
    if (Boolean(row.purchaseAuthorizationQuantityList)) {
      row.purchaseAuthorizationQuantityList.forEach(element => {
        disabledQuantityAuthorityTypes.push(element.procurementPurchasingAuthority);
        element.authorityList = [element.procurementPurchasingAuthority];
      });
    }
    let itemClassIds = [];
    if (Boolean(row.itemClassId)) {
      itemClassIds.push(row.itemClassId);
    }
    this.formRef.current.setFieldsValue({
      ...row,
      itemClassIds: itemClassIds
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      disabledPriceAuthorityTypes: disabledPriceAuthorityTypes,
      disabledQuantityAuthorityTypes: disabledQuantityAuthorityTypes,
      id: row.id,
      itemClassId: row.itemClassId
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/purchaseAuthorization/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination, sortField, sortOrder } = this.state;
      this.restartTable({ pagination, sortField, sortOrder });
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/purchaseAuthorization/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "designationId",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/purchaseAuthorization", newItem);
    } else {
      newItem.itemClassId = this.state.itemClassId;
      response = await handleRequest("PUT", "/api/purchaseAuthorization/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination, sortField, sortOrder } = this.state;
      this.restartTable({ pagination, sortField, sortOrder });
      showSuccess();
      this.setState({
        disabledPriceAuthorityTypes: [],
        disabledQuantityAuthorityTypes: []
      });
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/purchaseAuthorization/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination, sortField, sortOrder } = this.state;
            this.restartTable({ pagination, sortField, sortOrder });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };
  restartTableByDesignation = async value => {
    const { pagination } = this.state;
    let _pagination = {
      ...pagination,
      designationId: value,
      designationIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
    };
    this.setState({
      pagination: _pagination
    });

    this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
  };

  getMaxPrice = async item => {
    const response = await handleRequest(
      "GET",
      "/api/purchaseAuthorization/" + this.state.pagination.designationId + "/" + this.state.itemClassId + "/" + item.target.value
    );
    if (Boolean(response.data)) {
      console.log(response.data);
      this.setState({
        maxPriceSelectItems: response.data
      });
    }
  };

  generateReport = async () => {
    let url = "/api/" + this.state.controllerName + "/getReport";
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false
      });
      showError("There is no created report for selected item");
    }
  };

  generateReport1 = async row => {
    let url = "/api/" + this.state.controllerName + "/getReport1/" + row.id;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false
      });
      showError("There is no created report for selected item");
    }
  };

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showReport = async url => {
    try {
      const fileRealUrl = url;

      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true
      });
    } catch (error) {
      alert("File cant found.");
    }
  };

  render() {
    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.printButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: { marginBottom: 0 }
    };
    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 12 },
      style: { marginBottom: 0 }
    };
    const innerFormItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      style: { marginBottom: 0 }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 8,
          offset: 8
        }
      },
      style: { marginBottom: 0 }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        fixed: "left",
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        fixed: "left",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="PurchaseAuthorization.designationId" defaultMessage="Designation" />,
        key: "designationId",
        fixed: "left",
        render: record => {
          return Boolean(record.designation) ? record.designation.designation : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              allowClear
              showSearch
              style={{ width: "100%" }}
              placeholder="Designation"
              value={Boolean(this.state.pagination.designationId) ? this.state.pagination.designationId : null}
              onChange={async value => {
                await this.restartTableByDesignation(value);
              }}
            >
              {Boolean(this.state["designationIdSelectItems"]) &&
                this.state["designationIdSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.designationIdColor) ? this.state.pagination.designationIdColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PurchaseAuthorization.itemClassId" defaultMessage="Item Class" />,
        key: "itemClassId",
        fixed: "left",
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: record.status === "Deleted" ? "#d3d3d3" : "blue",
                  textDecoration: "underline"
                }}
                onClick={e => this.generateReport1(record)}
              >
                {Boolean(record.itemClass) ? record.itemClass.name : ""}
              </div>
            </React.Fragment>
          );
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              allowClear
              showSearch
              style={{ width: "100%" }}
              placeholder="Item Class"
              value={Boolean(this.state.pagination.itemClassId) ? this.state.pagination.itemClassId : null}
              onChange={async value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  itemClassId: Boolean(value) ? value : null,
                  itemClassIdValue: value,
                  itemClassIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            >
              {Boolean(this.state["StockCardItemClassIdSelectItems"]) &&
                this.state["StockCardItemClassIdSelectItems"].map(i => (
                  <Option key={i.id} value={i.id}>
                    {i.name}
                  </Option>
                ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.itemClassId) ? this.state.pagination.itemClassIdColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PurchaseAuthorization.priceAuthority" defaultMessage="Price Authorization List" />,
        key: "purchaseAuthorizationPriceList",
        render: record => {
          if (!Boolean(record.purchaseAuthorizationPriceList) || record.purchaseAuthorizationPriceList.length === 0) return "";
          return (
            <table className="table table-bordered table-sm">
              <thead>
                <tr>
                  <th>Authority</th>
                  <th>Price</th>
                  <th>Currency</th>
                  <th>Period</th>
                </tr>
              </thead>
              <tbody>
                {record.purchaseAuthorizationPriceList
                  .sort((a, b) => {
                    return (
                      this.authoritySortList.indexOf(a.procurementPurchasingAuthority) -
                      this.authoritySortList.indexOf(b.procurementPurchasingAuthority)
                    );
                  })
                  .map((item, index) => {
                    let c = Boolean(item.unlimited) ? "Unlimited" : Boolean(item.notApplicable) ? "N/A" : item.priceMin + " - " + item.priceMax;
                    return (
                      <tr key={index}>
                        <td>{item.procurementPurchasingAuthority}</td>
                        <td className="text-nowrap">{c}</td>
                        <td>{item.priceCurrency}</td>
                        <td className="text-nowrap">{item.periodValue + " " + item.periodUnit}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          );
        }
      },
      {
        title: <FormattedMessage id="PurchaseAuthorization.priceAuthority" defaultMessage="Quantity Authorization List" />,
        key: "purchaseAuthorizationQuantityList",
        render: record => {
          if (!Boolean(record.purchaseAuthorizationQuantityList) || record.purchaseAuthorizationQuantityList.length === 0) return "";
          return (
            <table className="table table-bordered table-sm">
              <thead>
                <tr>
                  <th>Authority</th>
                  <th>Quantity</th>
                  <th>Period</th>
                </tr>
              </thead>
              <tbody>
                {record.purchaseAuthorizationQuantityList
                  .sort((a, b) => {
                    return (
                      this.authoritySortList.indexOf(a.procurementPurchasingAuthority) -
                      this.authoritySortList.indexOf(b.procurementPurchasingAuthority)
                    );
                  })
                  .map((item, index) => {
                    let c = Boolean(item.unlimited) ? "Unlimited" : Boolean(item.notApplicable) ? "N/A" : item.quantity;
                    return (
                      <tr key={index}>
                        <td>{item.procurementPurchasingAuthority}</td>
                        <td>{c}</td>
                        <td className="text-nowrap">{item.periodValue + " " + item.periodUnit}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          );
        }
      },
      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
              exportClick: false
            })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );
    let addPriceList, addQuantityList;
    return (
      <Badge.Ribbon text="Purchasing Authorization" placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={22}></Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={this.generateReport}
                  icon={<PictureAsPdfIcon style={{ color: "red" }} fontSize="large" />}
                ></AntButton>
              </Col>
              {/* <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col> */}
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>

            <div hidden={this.state.hideInputs}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PurchaseAuthorization.designationId" defaultMessage="Designation" />}
                name="designationId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  allowClear
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Designation"
                  disabled={Boolean(this.state.id)}
                  onChange={value => {
                    this.restartTableByDesignation(value);
                  }}
                >
                  {Boolean(this.state["designationIdSelectItems"]) &&
                    this.state["designationIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PurchaseAuthorization.itemClassId" defaultMessage="Item Class" />}
                  name="itemClassIds"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    disabled={Boolean(this.state.id)}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    mode="multiple"
                    style={{ width: "100%" }}
                    onChange={value => {
                      if (Boolean(value) && value.length > 0) {
                        this.setState({ itemClassId: value[value.length - 1] });
                      } else {
                        this.setState({ itemClassId: null });
                      }
                    }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("StockCardItemClassId")}
                          >
                            <PlusOutlined /> Add item
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["StockCardItemClassIdSelectItems"]) &&
                      this.state["StockCardItemClassIdSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              <Form.Item {...layout2} label={<FormattedMessage id="PurchaseAuthorization.priceAuthority" defaultMessage="Price Authority" />}>
                <Form.Item noStyle name="priceAuthority">
                  <Checkbox.Group className="w-75" disabled={Boolean(this.state.id)}>
                    {this.state.authorityTypes.map(i => {
                      return (
                        <Checkbox
                          key={i.key}
                          value={i.key}
                          disabled={this.state.disabledPriceAuthorityTypes.includes(i.key)}
                          onChange={this.getMaxPrice}
                        >
                          {i.value}
                        </Checkbox>
                      );
                    })}
                  </Checkbox.Group>
                </Form.Item>
                <AntButton
                  type="dashed"
                  hidden={Boolean(this.state.id)}
                  onClick={() => {
                    let v = this.formRef.current.getFieldValue("priceAuthority");
                    if (v === undefined || v.length === 0 || !v.some(i => !this.state.disabledPriceAuthorityTypes.includes(i))) {
                      showWarning("Please select authority first");
                      return;
                    }
                    let aa = [];
                    v.forEach(i => {
                      if (!this.state.disabledPriceAuthorityTypes.includes(i)) aa.push(i);
                    });
                    addPriceList({
                      authorityList: aa,
                      priceMin: 0,
                      priceMax: Boolean(this.state.maxPriceSelectItems) ? this.state.maxPriceSelectItems[0].priceMax : 0,
                      priceCurrency: Boolean(this.state.maxPriceSelectItems)
                        ? this.state.maxPriceSelectItems[0].priceCurrency
                        : this.state.currencyTypes[0].key,
                      periodUnit: Boolean(this.state.maxPriceSelectItems) ? this.state.maxPriceSelectItems[0].priceCurrency : "",
                      periodValue: Boolean(this.state.maxPriceSelectItems) ? this.state.maxPriceSelectItems[0].periodValue : ""
                    });
                    this.setState({
                      disabledPriceAuthorityTypes: v,
                      selectedPriceAuthority: aa
                    });
                  }}
                >
                  Add
                </AntButton>
              </Form.Item>
              <Form.List
                name={"purchaseAuthorizationPriceList"}
                label={<FormattedMessage id="PurchaseAuthorization.priceList" defaultMessage="Price List" />}
              >
                {(fields, { add, remove }) => {
                  addPriceList = add;
                  return (
                    <>
                      {fields.map(field => {
                        return (
                          <div key={field.key} className="border rounded-sm p-1 m-1">
                            <Form.Item
                              {...innerFormItemLayout}
                              label={<FormattedMessage id="PurchaseAuthorization.procurementPurchasingAuthority" defaultMessage="Authority" />}
                            >
                              <Form.Item noStyle name={[field.name, "authorityList"]}>
                                <Input disabled={true} className="w-25" />
                              </Form.Item>
                              <MinusCircleOutlined
                                className="ml-2"
                                style={{ color: "crimson" }}
                                hidden={Boolean(this.state.id)}
                                onClick={() => {
                                  remove(field.name);
                                  let v = this.formRef.current.getFieldValue("purchaseAuthorizationPriceList");
                                  //get authorityList as uniq array from v
                                  let aa = [];
                                  v.forEach(i => {
                                    aa.push(...i.authorityList);
                                  });
                                  this.setState({
                                    disabledPriceAuthorityTypes: aa
                                  });
                                }}
                              />
                            </Form.Item>
                            <Form.Item {...tailFormItemLayout}>
                              <Form.Item noStyle name={[field.name, "notApplicable"]} valuePropName="checked">
                                <Checkbox> Not Applicable</Checkbox>
                              </Form.Item>
                              <Form.Item noStyle name={[field.name, "unlimited"]} valuePropName="checked">
                                <Checkbox> Unlimited</Checkbox>
                              </Form.Item>
                            </Form.Item>
                            <Form.Item {...innerFormItemLayout} label={<FormattedMessage id="PurchaseAuthorization.price" defaultMessage="Price" />}>
                              <Form.Item
                                noStyle
                                name={[field.name, "priceMin"]}
                                rules={[
                                  { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                              >
                                <InputNumber placeholder="Min" min={0} className="mr-2" />
                              </Form.Item>
                              <Form.Item
                                noStyle
                                name={[field.name, "priceMax"]}
                                rules={[
                                  { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                              >
                                <InputNumber
                                  placeholder="Max"
                                  min={Boolean(this.state.maxPriceSelectItems) ? this.state.maxPriceSelectItems[0].priceMax : 0}
                                  className="mr-2"
                                />
                              </Form.Item>
                              <Form.Item
                                noStyle
                                name={[field.name, "priceCurrency"]}
                                rules={[
                                  {
                                    required: true,
                                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This currency field is required." />
                                  }
                                ]}
                              >
                                <Select placeholder="Currency" className="mr-2" style={{ width: "20%" }}>
                                  {this.state.currencyTypes.map(i => (
                                    <Option key={i.key} value={i.key}>
                                      {i.value}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Form.Item>
                            <Form.Item
                              {...innerFormItemLayout}
                              label={<FormattedMessage id="PurchaseAuthorization.period" defaultMessage="Period" />}
                            >
                              <Form.Item
                                noStyle
                                name={[field.name, "periodValue"]}
                                rules={[
                                  { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                              >
                                <InputNumber className="mr-2" placeholder="Period" min={0} />
                              </Form.Item>
                              <Form.Item
                                noStyle
                                name={[field.name, "periodUnit"]}
                                rules={[
                                  { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                              >
                                <Select placeholder="Period" className="mr-2" style={{ width: "20%" }}>
                                  <Option value="Day">Day</Option>
                                  <Option value="Month">Month</Option>
                                  <Option value="Year">Year</Option>
                                </Select>
                              </Form.Item>
                            </Form.Item>
                          </div>
                        );
                      })}
                      <Form.Item {...tailFormItemLayout}>
                        <AntButton
                          type="dashed"
                          className="mb-3"
                          hidden={Boolean(this.state.id) || !this.state.selectedPriceAuthority}
                          block
                          icon={<PlusOutlined />}
                          onClick={() => {
                            const { selectedPriceAuthority } = this.state;
                            if (selectedPriceAuthority === undefined || selectedPriceAuthority.length === 0) {
                              showWarning("Please select authority first");
                              return;
                            }
                            let max = 0;
                            let periodUnit, periodValue, priceCurrency;
                            this.formRef.current.getFieldValue("purchaseAuthorizationPriceList").forEach(pApItem => {
                              if (pApItem.authorityList === selectedPriceAuthority && pApItem.priceMax > max) {
                                max = pApItem.priceMax;
                                periodUnit = pApItem.periodUnit;
                                periodValue = pApItem.periodValue;
                                priceCurrency = pApItem.priceCurrency;
                              }
                            });
                            add({
                              authorityList: selectedPriceAuthority,
                              priceMin: max + 1,
                              periodUnit,
                              periodValue,
                              priceCurrency
                            });
                          }}
                        >
                          Add Price
                        </AntButton>
                      </Form.Item>
                    </>
                  );
                }}
              </Form.List>
              <Form.Item {...layout2} label={<FormattedMessage id="PurchaseAuthorization.quantityAuthority" defaultMessage="Quantity Authority" />}>
                <Form.Item noStyle name="quantityAuthority">
                  <Checkbox.Group className="w-75" disabled={Boolean(this.state.id)}>
                    {this.state.authorityTypes.map(i => {
                      return (
                        <Checkbox key={i.key} value={i.key} disabled={this.state.disabledQuantityAuthorityTypes.includes(i.key)}>
                          {i.value}
                        </Checkbox>
                      );
                    })}
                  </Checkbox.Group>
                </Form.Item>
                <AntButton
                  type="dashed"
                  hidden={Boolean(this.state.id)}
                  onClick={() => {
                    let v = this.formRef.current.getFieldValue("quantityAuthority");
                    if (v === undefined || v.length === 0 || !v.some(i => !this.state.disabledQuantityAuthorityTypes.includes(i))) {
                      showWarning("Please select authority first");
                      return;
                    }
                    let aa = [];
                    v.forEach(i => {
                      if (!this.state.disabledQuantityAuthorityTypes.includes(i)) aa.push(i);
                    });
                    addQuantityList({
                      authorityList: aa,
                      quantity: 0
                    });
                    this.setState({
                      disabledQuantityAuthorityTypes: v,
                      selectedQuantityAuthority: aa
                    });
                  }}
                >
                  Add
                </AntButton>
              </Form.Item>
              <Form.List name={"purchaseAuthorizationQuantityList"}>
                {(fields, { add, remove }) => {
                  addQuantityList = add;
                  return (
                    <>
                      {fields.map(field => {
                        return (
                          <div key={field.key} className="border rounded-sm p-1 m-1">
                            <Form.Item
                              {...innerFormItemLayout}
                              label={<FormattedMessage id="PurchaseAuthorization.procurementPurchasingAuthority" defaultMessage="Authority" />}
                            >
                              <Form.Item noStyle name={[field.name, "authorityList"]}>
                                <Input disabled={true} className="w-25" />
                              </Form.Item>
                              <MinusCircleOutlined
                                className="ml-2"
                                style={{ color: "crimson" }}
                                hidden={Boolean(this.state.id)}
                                onClick={() => {
                                  remove(field.name);
                                  let v = this.formRef.current.getFieldValue("purchaseAuthorizationQuantityList");
                                  //get authorityList as uniq array from v
                                  let aa = [];
                                  v.forEach(i => {
                                    aa.push(...i.authorityList);
                                  });
                                  this.setState({
                                    disabledQuantityAuthorityTypes: aa
                                  });
                                }}
                              />
                            </Form.Item>
                            <Form.Item {...tailFormItemLayout}>
                              <Form.Item noStyle name={[field.name, "notApplicable"]} valuePropName="checked">
                                <Checkbox> Not Applicable</Checkbox>
                              </Form.Item>
                              <Form.Item noStyle name={[field.name, "unlimited"]} valuePropName="checked">
                                <Checkbox> Unlimited</Checkbox>
                              </Form.Item>
                            </Form.Item>
                            <Form.Item
                              {...innerFormItemLayout}
                              label={<FormattedMessage id="PurchaseAuthorization.quantity" defaultMessage="Quantity" />}
                              rules={[
                                { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                              ]}
                            >
                              <Form.Item noStyle name={[field.name, "quantity"]}>
                                <InputNumber className="mr-2" placeholder="Quantity" min={0} />
                              </Form.Item>
                            </Form.Item>
                            <Form.Item
                              {...innerFormItemLayout}
                              label={<FormattedMessage id="PurchaseAuthorization.period" defaultMessage="Period" />}
                            >
                              <Form.Item
                                noStyle
                                name={[field.name, "periodValue"]}
                                rules={[
                                  { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                              >
                                <InputNumber className="mr-2" placeholder="Period" min={0} />
                              </Form.Item>
                              <Form.Item
                                noStyle
                                name={[field.name, "periodUnit"]}
                                rules={[
                                  { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                              >
                                <Select style={{ width: "20%" }} placeholder="Period">
                                  <Option value="Day">Day</Option>
                                  <Option value="Month">Month</Option>
                                  <Option value="Year">Year</Option>
                                </Select>
                              </Form.Item>
                            </Form.Item>
                          </div>
                        );
                      })}
                      <Form.Item {...tailFormItemLayout}>
                        <AntButton
                          type="dashed"
                          className="mb-3"
                          hidden={Boolean(this.state.id) || !this.state.selectedQuantityAuthority}
                          block
                          icon={<PlusOutlined />}
                          onClick={() => {
                            const { selectedQuantityAuthority } = this.state;
                            if (selectedQuantityAuthority === undefined || selectedQuantityAuthority.length === 0) {
                              showWarning("Please select authority first");
                              return;
                            }
                            add({
                              authorityList: selectedQuantityAuthority,
                              quantity: 0
                            });
                          }}
                        >
                          Add Quantity
                        </AntButton>
                      </Form.Item>
                    </>
                  );
                }}
              </Form.List>
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              rowKey={record => record.id}
              size="small"
              scroll={{ x: 1000, y: 1200, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal
            size="xl"
            show={this.state.modalPdfViewer}
            onHide={this.showOrHideModalPdfViewer}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Badge.Ribbon text="PDF Report Viewer" placement="start" color="purple" style={{ fontSize: 12 }}>
              <Modal.Body>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                  <div style={{ marginTop: "2rem" }}>
                    <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                  </div>
                </Worker>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.showOrHideModalPdfViewer}>
                  Close
                </Button>
              </Modal.Footer>
            </Badge.Ribbon>
          </Modal>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseAuthorization);
