import { EditOutlined, Print } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import DescriptionIcon from "@material-ui/icons/Description";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Button as AntButton, Col, Form, Modal as AntModal, Row, Table, Input, Select, InputNumber, Checkbox, Divider, Cascader, DatePicker, Typography } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import DataTableComponent2 from "../../Components/DataTableComponent2";
import ElementsProvider from "../../Components/ElementsProvider";
import { GetDynamicFormItems, PrepareRowForEdit, PrepareRowForSave } from "../../Components/ElementUtils";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { ExcelReader } from "../excel/ExcelReader";
import EmployeeRequestScoring from "./EmployeeRequestScoring";
import RoleDescriptionListModal from "./RoleDescriptionListModal";
import Viewer, { defaultLayout, Worker } from "@phuocng/react-pdf-viewer";
import { PlusOutlined, CloseOutlined, CheckOutlined, MinusOutlined, MinusCircleOutlined, ManOutlined, WomanOutlined } from "@ant-design/icons";
import moment from "moment";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

function CheckIcon() {
  return (
    <span role="img" aria-label="checkmark">✅</span>
  );
}

function DeclineIcon() {
  return (
    <span role="img" aria-label="decline" style={{ color: 'red' }}>❌</span>
  );
}

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (Boolean(node)) {
      var lineage = [];
      if (Boolean(node.lineage) && node.lineage.length > 0) {
        node.lineage.forEach(element => {
          if (!lineage.includes(element)) {
            lineage.push(element);
          }
        });
      }

      dataList.push({
        key: node.value,
        lineage: node.lineage,
        title: node.value
      });
    }
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class EmployeeRequest extends Component {
  constructor() {
    super();
    this.state = {
      formName: "EmployeeRequest",
      controllerName: "employeeRequest",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      tableList: [],
      restartTable: false,
      pageFormData: null,
      excelData: [],
      createWhereParams: null,
      refreshForm: false,
      employeeRequestData: {},
      modalPdfViewer: false,
      pdfFile: "",
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      sortField: "",
      sortOrder: "",
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {

    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/tree");
    if (responseTree.data.length !== 0) {
      generateList(responseTree.data);
      this.setState({
        departmentIdSelectItems: responseTree.data
      });
    }

    var responseDesignations = await handleRequest("GET", "/api/" + this.state.controllerName + "/designations");
    if (responseDesignations.type === "ERROR") {
      error(responseDesignations);
    }
    this.setState({
      designationIdSelectItems: Boolean(responseDesignations.data) ? responseDesignations.data : []
    });

    var responseFixedAndUndefineTermContractType = await handleRequest("GET", "/api/" + this.state.controllerName + "/fixedAndUndefineTermContractType");
    if (responseFixedAndUndefineTermContractType.type === "ERROR") {
      error(responseFixedAndUndefineTermContractType);
    }
    this.setState({
      workingPeriodId01SelectItems: Boolean(responseFixedAndUndefineTermContractType.data) ? responseFixedAndUndefineTermContractType.data : []
    });

    var responseMonthYearType = await handleRequest("GET", "/api/" + this.state.controllerName + "/monthYearType");
    if (responseMonthYearType.type === "ERROR") {
      error(responseMonthYearType);
    }
    this.setState({
      workingPeriodId02SelectItems: Boolean(responseMonthYearType.data) ? responseMonthYearType.data : [],
      probationPeriodIdSelectItems: Boolean(responseMonthYearType.data) ? responseMonthYearType.data : [],
      minimumTotalExperinceIdSelectItems: Boolean(responseMonthYearType.data) ? responseMonthYearType.data : [],
      minWorkDurationIdSelectItems: Boolean(responseMonthYearType.data) ? responseMonthYearType.data : [],
      minimumIdSelectItems: Boolean(responseMonthYearType.data) ? responseMonthYearType.data : []
    });

    var responseGreaterOrLessType = await handleRequest("GET", "/api/" + this.state.controllerName + "/greaterOrLessType");
    if (responseGreaterOrLessType.type === "ERROR") {
      error(responseGreaterOrLessType);
    }
    this.setState({
      ageId01SelectItems: Boolean(responseGreaterOrLessType.data) ? responseGreaterOrLessType.data : [],
      ageId02SelectItems: Boolean(responseGreaterOrLessType.data) ? responseGreaterOrLessType.data : []
    });

    var responseEmployementStatusType = await handleRequest("GET", "/api/" + this.state.controllerName + "/employementStatusType");
    if (responseEmployementStatusType.type === "ERROR") {
      error(responseEmployementStatusType);
    }
    this.setState({
      employementStatusIdSelectItems: Boolean(responseEmployementStatusType.data) ? responseEmployementStatusType.data : []
    });

    var responseHealthType = await handleRequest("GET", "/api/" + this.state.controllerName + "/healthType");
    if (responseHealthType.type === "ERROR") {
      error(responseHealthType);
    }
    this.setState({
      healthIdSelectItems: Boolean(responseHealthType.data) ? responseHealthType.data : []
    });

    var responseLicenceClassType = await handleRequest("GET", "/api/" + this.state.controllerName + "/licenceClassType");
    if (responseLicenceClassType.type === "ERROR") {
      error(responseLicenceClassType);
    }
    this.setState({
      licenceClassIdSelectItems: Boolean(responseLicenceClassType.data) ? responseLicenceClassType.data : []
    });

    var responseMilitaryObligationType = await handleRequest("GET", "/api/" + this.state.controllerName + "/militaryObligationType");
    if (responseMilitaryObligationType.type === "ERROR") {
      error(responseMilitaryObligationType);
    }
    this.setState({
      militaryObligationIdSelectItems: Boolean(responseMilitaryObligationType.data) ? responseMilitaryObligationType.data : []
    });

    var responseGraduationType = await handleRequest("GET", "/api/" + this.state.controllerName + "/graduationType");
    if (responseGraduationType.type === "ERROR") {
      error(responseGraduationType);
    }
    this.setState({
      graduationIdSelectItems: Boolean(responseGraduationType.data) ? responseGraduationType.data : []
    });

    var responseCertificateType = await handleRequest("GET", "/api/" + this.state.controllerName + "/certificateType");
    if (responseCertificateType.type === "ERROR") {
      error(responseCertificateType);
    }
    this.setState({
      certficateTypeIdSelectItems: Boolean(responseCertificateType.data) ? responseCertificateType.data : []
    });

    var responseMinimumDateDurationType = await handleRequest("GET", "/api/" + this.state.controllerName + "/minimumDateDurationType");
    if (responseMinimumDateDurationType.type === "ERROR") {
      error(responseMinimumDateDurationType);
    }
    this.setState({
      certificateValidityIdSelectItems: Boolean(responseMinimumDateDurationType.data) ? responseMinimumDateDurationType.data : []
    });

    var responseLanguageType = await handleRequest("GET", "/api/" + this.state.controllerName + "/languageType");
    if (responseLanguageType.type === "ERROR") {
      error(responseLanguageType);
    }
    this.setState({
      languageIdSelectItems: Boolean(responseLanguageType.data) ? responseLanguageType.data : [],
      certificateIdSelectItems: Boolean(responseLanguageType.data) ? responseLanguageType.data : []
    });

    var responseGoodModeratePoorType = await handleRequest("GET", "/api/" + this.state.controllerName + "/goodModeratePoorType");
    if (responseGoodModeratePoorType.type === "ERROR") {
      error(responseGoodModeratePoorType);
    }
    this.setState({
      writtenIdSelectItems: Boolean(responseGoodModeratePoorType.data) ? responseGoodModeratePoorType.data : [],
      readingIdSelectItems: Boolean(responseGoodModeratePoorType.data) ? responseGoodModeratePoorType.data : [],
      speakingIdSelectItems: Boolean(responseGoodModeratePoorType.data) ? responseGoodModeratePoorType.data : []
    });

  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    let militaryObligations = [];
    if (Boolean(row.militaryObligationIds) && row.militaryObligationIds.length > 0) {
      row.militaryObligationIds.forEach(militaryObligationsId => {
        if (militaryObligationsId.militaryObligationId != null) {
          militaryObligations.push(militaryObligationsId.militaryObligationId);
        }
      });
    }


    this.formRef.current.setFieldsValue({
      ...row,
      graduationAndExperiencesList: row.graduationAndExperiencesList,
      trainingsList: row.trainingsList,
      languageList: row.languageList,
      militaryObligationIds: militaryObligations,
      expectedJoiningDate: moment(row.expectedJoiningDate),
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    let militaryObligations = [];
    if (Boolean(newItem.militaryObligationIds) && newItem.militaryObligationIds.length > 0) {
      newItem.militaryObligationIds.forEach(militaryObligationId => {
        militaryObligations.push({ militaryObligationId: militaryObligationId });
      });
    }
    newItem.militaryObligationIds = militaryObligations

    newItem.departmentId =
      Boolean(values.departmentId) && values.departmentId.length > 0 ? values.departmentId[values.departmentId.length - 1] : null;


    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  completeSave = async newItem => {
    if (!Boolean(newItem.id)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      showError(response);
    } else {
      this.cancel();
      this.restartTable();
      showSuccess();
      this.setState({
        showScoringModal: false
      });
    }
  };

  fillCriterias = async data => {
    console.log(data);
    this.setState({
      showRoleDescriptionModal: false
    });
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          this.state.pageFormData.pageFormInputCompanyList.forEach(input => {
            Boolean(input.tableColumnValue) ? (obj[input.tableColumnValue] = element[input.label]) : (obj[input.inputName] = element[input.label]);
          });
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            this.restartTable();
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let sendAction = {
      name: "Send",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let revisionAction = {
      name: "Revision",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let withdrawn = {
      name: "Withdrawn",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let addQualificationsAction = {
      name: "Add Qualifications",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };
    let printAction = {
      name: "Print",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.generateReport.bind(this)
    };


    let actionList = [];
    if (Boolean(record.status)) {
      if (record.status === "Send") {
        actionList.push(revisionAction);
        actionList.push(withdrawn);
      } else if (record.status === "Revision") {
        actionList.push(editAction);
        actionList.push(sendAction);
        actionList.push(withdrawn);
      }
      else {
        actionList.push(editAction);
        actionList.push(sendAction);
        actionList.push(deleteAction);
      }
    } else {
      actionList.push(editAction);
      actionList.push(sendAction);
      actionList.push(addQualificationsAction);
      actionList.push(deleteAction);
      actionList.push(printAction);
      

    }
    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  generateReport = async row => {
    let url = "/api/employeeRequest/getReport/" + row.id;

    const response = await handleRequest("GET", url);

    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false
      });

      showError("There is no created report for selected item");
    }
  };

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showReport = async url => {
    try {
      const fileRealUrl = url;

      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true
      });
    } catch (error) {
      alert("File cant found.");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="EmployeeRequest.RequestNo" defaultMessage="Request No" />,
        key: "requestNo",
        render: record => {
          return record.requestNo;
        }, ...getColumnFilter({
          inputName: "requestNo", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="EmployeeRequest.RevNo" defaultMessage="Rev.No" />,
        key: "revNo",
        render: record => {
          return record.revNo;
        }, ...getColumnFilter({
          inputName: "revNo", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="EmployeeRequest.Status" defaultMessage="Status" />,
        key: "status",
        render: record => {
          return record.status;
        },
        ...getColumnFilter({
          inputName: "status",
          inputType: "SELECT",
          selectItems: Boolean(this.state["statusSelectItems"]) && this.state["statusSelectItems"].length > 0 ? this.state["statusSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EmployeeRequest.RequestDate" defaultMessage="Request Date" />,
        key: "requestDate",
        render: record => {
          return record.requestDate;
        }, ...getColumnFilter({
          inputName: "requestDate", inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="EmployeeRequest.Department" defaultMessage="Department" />,
        key: "departmentId",
        render: record => {
          return record.departmentName;
        },
        ...getColumnFilter({
          inputName: "departmentName",
          inputType: "SELECT",
          selectItems: Boolean(this.state["departmentSelectItems"]) && this.state["departmentSelectItems"].length > 0 ? this.state["departmentSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EmployeeRequest.Designation" defaultMessage="Designation" />,
        key: "designationId",
        render: record => {
          return record.designationName;
        },
        ...getColumnFilter({
          inputName: "designationName",
          inputType: "SELECT",
          selectItems: Boolean(this.state["designationIdSelectItems"]) && this.state["designationIdSelectItems"].length > 0 ? this.state["designationIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EmployeeRequest.RevNo" defaultMessage="Number" />,
        key: "numberOfRequestedEmployee",
        render: record => {
          return record.numberOfRequestedEmployee;
        }, ...getColumnFilter({
          inputName: "numberOfRequestedEmployee", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="EmployeeRequest.ExpectedJoiningDate" defaultMessage="Expected Joining Date" />,
        key: "expectedJoiningDate",
        render: record => {
          return record.expectedJoiningDate;
        }, ...getColumnFilter({
          inputName: "expectedJoiningDate", inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="EmployeeRequest.WorkingPeriod" defaultMessage="Working Period" />,
        key: "workingPeriod",
        render: record => {
          return record.workingPeriodName;
        }, ...getColumnFilter({
          inputName: "workingPeriodName", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="EmployeeRequest.Gender" defaultMessage="Gender" />,
        key: "gender",
        render: record => (
          <div>
          <div>
            <ManOutlined /> {record.isMale ? <CheckIcon /> : <DeclineIcon />}
          </div>
          <div>
            <WomanOutlined /> {record.isFemale ? <CheckIcon /> : <DeclineIcon />}
          </div>
        </div>
        ), ...getColumnFilter({
          inputName: "gender", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="EmployeeRequest.Age" defaultMessage="Age" />,
        key: "age",
        render: record => {
          return record.ageName;
        }, ...getColumnFilter({
          inputName: "ageName", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="EmployeeRequest.EmployementStatus" defaultMessage="Employement Status" />,
        key: "employementStatusId",
        render: record => {
          return record.employementStatusName;
        },
        ...getColumnFilter({
          inputName: "employementStatusName",
          inputType: "SELECT",
          selectItems: Boolean(this.state["employementStatusIdSelectItems"]) && this.state["employementStatusIdSelectItems"].length > 0 ? this.state["employementStatusIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
    ];

    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    }

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename={this.state.formName} hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
              {Boolean(this.state.pageFormData) &&
                this.state.pageFormData.pageFormInputCompanyList.map(item => {
                  return (
                    <ExcelColumn
                      key={item.inputName}
                      label={item.label}
                      value={Boolean(item.tableColumnValue) ? item.tableColumnValue : item.inputName}
                    />
                  );
                })}
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col md={21}>
            <span className="h3">
              <FormattedMessage
                id={this.state.controllerName + ".pageTitle"}
                defaultMessage={Boolean(this.state.pageFormData) ? this.state.pageFormData.pageForm.title : ""}
              />
            </span>
          </Col>
          <Col md={1}>{excelExport}</Col>
          <Col md={1}>
            <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
          </Col>
          <Col md={1}>
            <AntButton
              style={{ border: "0px" }}
              hidden={!this.state.hideInputs}
              onClick={this.onClickCreateNew}
              icon={<CreateIcon color="secondary" fontSize="large" />}
            ></AntButton>
            <AntButton
              style={{ border: "0px" }}
              hidden={this.state.hideInputs}
              onClick={this.onClickCreateNew}
              icon={<RemoveCircle color="error" fontSize="large" />}
            ></AntButton>
          </Col>
        </Row>
        <div hidden={this.state.hideInputs}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EmployeeRequest.Project" defaultMessage="Project" />}
                name="projectId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["projectIdSelectItems"]) &&
                    this.state["projectIdSelectItems"].length > 0 &&
                    this.state["projectIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EmployeeRequest.Department" defaultMessage="Department" />}
                name="departmentId"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Cascader changeOnSelect style={{ width: "100%" }} options={this.state.departmentIdSelectItems} />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EmployeeRequest.Designation" defaultMessage="Designation" />}
                name="designationId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["designationIdSelectItems"]) &&
                    this.state["designationIdSelectItems"].length > 0 &&
                    this.state["designationIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EmployeeRequest.NumberofRequestedEmployee" defaultMessage="Number of Requested Employee" />}
                name="numberOfRequestedEmployee"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="TaxSchedule.ExpectedJoiningDate" defaultMessage="Expected Joining Date" />}
                name="expectedJoiningDate"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={
                  <FormattedMessage
                    id="EmployeeRequest.WorkingPeriod"
                    defaultMessage="Working Period"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  />
                }
              >
                <Row>
                  <Col span={8}>
                    <Form.Item
                      name="workingPeriodId01"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["workingPeriodId01SelectItems"]) &&
                          this.state["workingPeriodId01SelectItems"].length > 0 &&
                          this.state["workingPeriodId01SelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={7}>
                    <Form.Item
                      name="workingPeriod"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      style={{ marginRight: "10px" }}
                    >
                      <InputNumber
                        style={{ width: "100px" }}
                        parser={value => value.replace(",", ".")}
                        formatter={value => value.replace(".", ",")}
                        className="w-100"
                        placeholder="    "
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={7}>
                    <Form.Item
                      name="workingPeriodId02"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      style={{ marginLeft: "10px" }}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["workingPeriodId02SelectItems"]) &&
                          this.state["workingPeriodId02SelectItems"].length > 0 &&
                          this.state["workingPeriodId02SelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            }

            {/* {
              <Form.Item
                {...layout}
                label={
                  <FormattedMessage
                    id="EmployeeRequest.ProbationPeriod"
                    defaultMessage="Probation Period"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  />
                }
              >
                <Row>
                  <Col span={11}>
                    <Form.Item
                      name="probationPeriod"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber
                        style={{ width: "100px" }}
                        parser={value => value.replace(",", ".")}
                        formatter={value => value.replace(".", ",")}
                        className="w-100"
                        placeholder="    "
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Form.Item
                      name="probationPeriodId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["probationPeriodIdSelectItems"]) &&
                          this.state["probationPeriodIdSelectItems"].length > 0 &&
                          this.state["probationPeriodIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            } */}

            {
              <Form.Item
                {...layout}
                label={
                  <FormattedMessage
                    id="EmployeeRequest.Gender"
                    defaultMessage="Gender"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  />
                }
              >
                <Row>
                  <Col span={11}>
                    <Form.Item
                      {...layout}
                      name="isFemale"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      valuePropName="checked"
                    >
                      <Checkbox>Female</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Form.Item
                      {...layout}
                      name="isMale"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      valuePropName="checked"
                    >
                      <Checkbox>Male</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={
                  <FormattedMessage
                    id="EmployeeRequest.Age"
                    defaultMessage="Age"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  />
                }
              >
                <Row>
                  <Col span={6}>
                    <Form.Item
                      name="age01"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      style={{ marginRight: "10px" }}
                    >
                      <InputNumber
                        style={{ width: "100px" }}
                        parser={value => value.replace(",", ".")}
                        formatter={value => value.replace(".", ",")}
                        className="w-100"
                        placeholder="    "
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="ageId01"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      style={{ marginRight: "10px" }}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["ageId01SelectItems"]) &&
                          this.state["ageId01SelectItems"].length > 0 &&
                          this.state["ageId01SelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="age02"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      style={{ marginRight: "10px" }}
                    >
                      <InputNumber
                        style={{ width: "100px" }}
                        parser={value => value.replace(",", ".")}
                        formatter={value => value.replace(".", ",")}
                        className="w-100"
                        placeholder="    "
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="ageId02"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}

                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["ageId02SelectItems"]) &&
                          this.state["ageId02SelectItems"].length > 0 &&
                          this.state["ageId02SelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EmployeeRequest.EmployementStatus" defaultMessage="Employement Status" />}
                name="employementStatusId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["employementStatusIdSelectItems"]) &&
                    this.state["employementStatusIdSelectItems"].length > 0 &&
                    this.state["employementStatusIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            }


            {
              <Row gutter={[16, 16]}>
                <Col md={3}></Col>
                <Col md={5}>
                  <>
                    <AntButton
                      style={{ width: "1000px", textAlign: "left", }}
                      className="mb-3"
                      type="dashed"
                      hidden={Boolean(this.state.graduationAndExperiencesButton) && this.state.graduationAndExperiencesButton}
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          graduationAndExperiencesButton: true
                        });
                      }}
                    >
                      {<FormattedMessage id="EmployeeRequest.GraduationandExperiences" defaultMessage="Graduation and Experiences" />}
                    </AntButton>
                    <AntButton
                      style={{ width: "1000px", textAlign: "left" }}
                      className="mb-3"
                      type="dashed"
                      hidden={!(Boolean(this.state.graduationAndExperiencesButton) && this.state.graduationAndExperiencesButton)}
                      icon={<MinusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          graduationAndExperiencesButton: false
                        });
                      }}
                    >
                      {<FormattedMessage id="EmployeeRequest.GraduationandExperiences" defaultMessage="Graduation and Experiences" />}
                    </AntButton>
                  </>
                </Col>
              </Row>
            }
            {
              <div hidden={!(Boolean(this.state.graduationAndExperiencesButton) && this.state.graduationAndExperiencesButton)}>
                <Form.List name="graduationAndExperiencesList">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>

                            <tr>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EmployeeRequest.Graduation"} defaultMessage={"Graduation"} />
                              </th>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EmployeeRequest.MinimumTotalExperince"} defaultMessage={"Minimum Total Experince"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EmployeeRequest.Workedas"} defaultMessage={"Worked as"} />
                              </th>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EmployeeRequest.MinWorkDuration"} defaultMessage={"Min Work Duration"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EmployeeRequest.EmployementLetter"} defaultMessage={"Employement Letter"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EmployeeRequest.ClientApproval"} defaultMessage={"Client Approval"} />
                              </th>
                              <th colSpan="1">
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "isGraduationMinimum"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                      valuePropName="checked"
                                    >
                                      <Checkbox>Minimum</Checkbox>
                                    </Form.Item>

                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "graduationId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        style={{ width: "100px" }}
                                      >
                                        {Boolean(this.state["graduationIdSelectItems"]) && this.state["graduationIdSelectItems"].length > 0 &&
                                          this.state["graduationIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "minimumTotalExperince"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} className="w-100" placeholder="    " min={0} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "minimumTotalExperinceId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        style={{ width: "100px" }}
                                      >
                                        {Boolean(this.state["minimumTotalExperinceIdSelectItems"]) && this.state["minimumTotalExperinceIdSelectItems"].length > 0 &&
                                          this.state["minimumTotalExperinceIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "workedAs"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <Input style={{ width: "100px" }} />
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "minWorkDuration"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} className="w-100" placeholder="    " min={0} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "minWorkDurationId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        style={{ width: "100px" }}
                                      >
                                        {Boolean(this.state["minWorkDurationIdSelectItems"]) && this.state["minWorkDurationIdSelectItems"].length > 0 &&
                                          this.state["minWorkDurationIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "isEmployementLetter"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                      valuePropName="checked"
                                    >
                                      <Checkbox>Required</Checkbox>
                                    </Form.Item>

                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "isClientApproval"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                      valuePropName="checked"
                                    >
                                      <Checkbox>Required</Checkbox>
                                    </Form.Item>

                                  </td>

                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan={10}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot >
                      </>
                    );
                  }}
                </Form.List>
              </div>
            }
            {
              <Row gutter={[16, 16]}>
                <Col md={3}></Col>
                <Col md={5}>
                  <>
                    <AntButton
                      style={{ width: "1000px", textAlign: "left" }}
                      className="mb-3"
                      type="dashed"
                      hidden={Boolean(this.state.trainingsButton) && this.state.trainingsButton}
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          trainingsButton: true
                        });
                      }}
                    >
                      {<FormattedMessage id="EmployeeRequest.Trainings" defaultMessage="Trainings" />}
                    </AntButton>
                    <AntButton
                      style={{ width: "1000px", textAlign: "left" }}
                      className="mb-3"
                      type="dashed"
                      hidden={!(Boolean(this.state.trainingsButton) && this.state.trainingsButton)}
                      icon={<MinusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          trainingsButton: false
                        });
                      }}
                    >
                      {<FormattedMessage id="EmployeeRequest.Trainings" defaultMessage="Trainings" />}
                    </AntButton>
                  </>
                </Col>
              </Row>
            }
            {
              <div hidden={!(Boolean(this.state.trainingsButton) && this.state.trainingsButton)}>
                <Form.List name="trainingsList">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>

                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EmployeeRequest.Graduation"} defaultMessage={"Training"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EmployeeRequest.MinimumTotalExperince"} defaultMessage={"Certificate Type"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EmployeeRequest.Workedas"} defaultMessage={"Min Days of Training"} />
                              </th>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EmployeeRequest.MinWorkDuration"} defaultMessage={"Certificate Validity"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EmployeeRequest.EmployementLetter"} defaultMessage={"Requirements"} />
                              </th>
                              <th colSpan="1">
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "training"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <Input style={{ width: "100px" }} />
                                    </Form.Item>

                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "certificateTypeId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        style={{ width: "100px" }}
                                      >
                                        {Boolean(this.state["certficateTypeIdSelectItems"]) && this.state["certficateTypeIdSelectItems"].length > 0 &&
                                          this.state["certficateTypeIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "minDaysOfTraining"]}
                                      style={{
                                        display: "inline-block",

                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} className="w-100" placeholder="    " min={0} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "certificateValidityId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        style={{ width: "100px" }}
                                      >
                                        {Boolean(this.state["certificateValidityIdSelectItems"]) && this.state["certificateValidityIdSelectItems"].length > 0 &&
                                          this.state["certificateValidityIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "certificateValidity"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} className="w-100" placeholder="    " min={0} />
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "requirements"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <Input style={{ width: "100px" }} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan={6}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot >
                      </>
                    );
                  }}
                </Form.List>
              </div>
            }
            {
              <Row gutter={[16, 16]}>
                <Col md={3}></Col>
                <Col md={5}>
                  <>
                    <AntButton
                      style={{ width: "1000px", textAlign: "left" }}
                      className="mb-3"
                      type="dashed"
                      hidden={Boolean(this.state.languageButton) && this.state.languageButton}
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          languageButton: true
                        });
                      }}
                    >
                      {<FormattedMessage id="EmployeeRequest.Language" defaultMessage="Language" />}
                    </AntButton>
                    <AntButton
                      style={{ width: "1000px", textAlign: "left" }}
                      className="mb-3"
                      type="dashed"
                      hidden={!(Boolean(this.state.languageButton) && this.state.languageButton)}
                      icon={<MinusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          languageButton: false
                        });
                      }}
                    >
                      {<FormattedMessage id="EmployeeRequest.Language" defaultMessage="Language" />}
                    </AntButton>
                  </>
                </Col>
              </Row>
            }
            {
              <div hidden={!(Boolean(this.state.languageButton) && this.state.languageButton)}>
                <Form.List name="languageList">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>

                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EmployeeRequest.Language"} defaultMessage={"Language"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EmployeeRequest.Written"} defaultMessage={"Written"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EmployeeRequest.Reading"} defaultMessage={"Reading"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EmployeeRequest.Speaking"} defaultMessage={"Speaking"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EmployeeRequest.EmployementLetter"} defaultMessage={"Certificate"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EmployeeRequest.MinScore"} defaultMessage={"Min Score"} />
                              </th>
                              <th colSpan="1">
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "languageId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        style={{ width: "100px" }}
                                      >
                                        {Boolean(this.state["languageIdSelectItems"]) && this.state["languageIdSelectItems"].length > 0 &&
                                          this.state["languageIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "writtenId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        style={{ width: "100px" }}
                                      >
                                        {Boolean(this.state["writtenIdSelectItems"]) && this.state["writtenIdSelectItems"].length > 0 &&
                                          this.state["writtenIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "readingId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        style={{ width: "100px" }}
                                      >
                                        {Boolean(this.state["readingIdSelectItems"]) && this.state["readingIdSelectItems"].length > 0 &&
                                          this.state["readingIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "speakingId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        style={{ width: "100px" }}
                                      >
                                        {Boolean(this.state["speakingIdSelectItems"]) && this.state["speakingIdSelectItems"].length > 0 &&
                                          this.state["speakingIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "certificateId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        style={{ width: "100px" }}
                                      >
                                        {Boolean(this.state["certificateIdSelectItems"]) && this.state["certificateIdSelectItems"].length > 0 &&
                                          this.state["certificateIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "minScore"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} className="w-100" placeholder="    " min={0} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan={6}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot >
                      </>
                    );
                  }}
                </Form.List>
              </div>
            }
            {
              <Row gutter={[16, 16]}>
                <Col md={3}></Col>
                <Col md={5}>
                  <>
                    <AntButton
                      style={{ width: "1000px", textAlign: "left" }}
                      className="mb-3"
                      type="dashed"
                      hidden={Boolean(this.state.healthButton) && this.state.healthButton}
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          healthButton: true
                        });
                      }}
                    >
                      {<FormattedMessage id="EmployeeRequest.Health" defaultMessage="Health" />}
                    </AntButton>
                    <AntButton
                      style={{ width: "1000px", textAlign: "left" }}
                      className="mb-3"
                      type="dashed"
                      hidden={!(Boolean(this.state.healthButton) && this.state.healthButton)}
                      icon={<MinusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          healthButton: false
                        });
                      }}
                    >
                      {<FormattedMessage id="EmployeeRequest.Health" defaultMessage="Health" />}
                    </AntButton>
                  </>
                </Col>
              </Row>
            }
            {
              <div hidden={!(Boolean(this.state.healthButton) && this.state.healthButton)}>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EmployeeRequest.MilitaryObligation" defaultMessage="Health" />}
                  name="healthId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  style={{ marginLeft: "10px" }}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["healthIdSelectItems"]) &&
                      this.state["healthIdSelectItems"].length > 0 &&
                      this.state["healthIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            }
            {
              <Row gutter={[16, 16]}>
                <Col md={3}></Col>
                <Col md={5}>
                  <>
                    <AntButton
                      style={{ width: "1000px", textAlign: "left" }}
                      className="mb-3"
                      type="dashed"
                      hidden={Boolean(this.state.drivingLicenceButton) && this.state.drivingLicenceButton}
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          drivingLicenceButton: true
                        });
                      }}
                    >
                      {<FormattedMessage id="EmployeeRequest.DrivingLicence" defaultMessage="Driving Licence" />}
                    </AntButton>
                    <AntButton
                      style={{ width: "1000px", textAlign: "left" }}
                      className="mb-3"
                      type="dashed"
                      hidden={!(Boolean(this.state.drivingLicenceButton) && this.state.drivingLicenceButton)}
                      icon={<MinusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          drivingLicenceButton: false
                        });
                      }}
                    >
                      {<FormattedMessage id="EmployeeRequest.DrivingLicence" defaultMessage="Driving Licence" />}
                    </AntButton>
                  </>
                </Col>
              </Row>
            }
            {
              <div hidden={!(Boolean(this.state.drivingLicenceButton) && this.state.drivingLicenceButton)}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeRequest.DrivingLicence" defaultMessage="Required" />}
                    name="isDrivingLicence"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox></Checkbox>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeRequest.DrivingLicence" defaultMessage="Licence Class" />}
                    name="licenceClassId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                      {Boolean(this.state["licenceClassIdSelectItems"]) &&
                        this.state["licenceClassIdSelectItems"].length > 0 &&
                        this.state["licenceClassIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }
              </div>
            }
            {
              <Row gutter={[16, 16]}>
                <Col md={3}></Col>
                <Col md={5}>
                  <>
                    <AntButton
                      style={{ width: "1000px", textAlign: "left" }}
                      className="mb-3"
                      type="dashed"
                      hidden={Boolean(this.state.militaryObligationButton) && this.state.militaryObligationButton}
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          militaryObligationButton: true
                        });
                      }}
                    >
                      {<FormattedMessage id="EmployeeRequest.MilitaryObligation" defaultMessage="Military Obligation" />}
                    </AntButton>
                    <AntButton
                      style={{ width: "1000px", textAlign: "left" }}
                      className="mb-3"
                      type="dashed"
                      hidden={!(Boolean(this.state.militaryObligationButton) && this.state.militaryObligationButton)}
                      icon={<MinusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          militaryObligationButton: false
                        });
                      }}
                    >
                      {<FormattedMessage id="EmployeeRequest.MilitaryObligation" defaultMessage="Military Obligation" />}
                    </AntButton>
                  </>
                </Col>
              </Row>
            }
            {
              <div hidden={!(Boolean(this.state.militaryObligationButton) && this.state.militaryObligationButton)}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeRequest.MilitaryObligation" defaultMessage="Military Obligation" />}
                    name="militaryObligationIds"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear mode="multiple" style={{ width: "100%" }} optionFilterProp="children">
                      {Boolean(this.state["militaryObligationIdSelectItems"]) &&
                        this.state["militaryObligationIdSelectItems"].length > 0 &&
                        this.state["militaryObligationIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={
                      <FormattedMessage
                        id="EmployeeRequest.Minimum"
                        defaultMessage="Minimum"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      />
                    }
                  >
                    <Row>
                      <Col span={11}>
                        <Form.Item
                          name="minimum"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          style={{ marginRight: "10px" }}
                        >
                          <InputNumber
                            style={{ width: "100px" }}
                            parser={value => value.replace(",", ".")}
                            formatter={value => value.replace(".", ",")}
                            className="w-100"
                            placeholder="    "
                            min={0}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={11}>
                        <Form.Item
                          {...layout}
                          name="minimumId"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                            {Boolean(this.state["minimumIdSelectItems"]) &&
                              this.state["minimumIdSelectItems"].length > 0 &&
                              this.state["minimumIdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                }
              </div>
            }
            {
              <Row gutter={[16, 16]}>
                <Col md={3}></Col>
                <Col md={5}>
                  <>
                    <AntButton
                      style={{ width: "1000px", textAlign: "left" }}
                      className="mb-3"
                      type="dashed"
                      hidden={Boolean(this.state.referencesButton) && this.state.referencesButton}
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          referencesButton: true
                        });
                      }}
                    >
                      {<FormattedMessage id="EmployeeRequest.References" defaultMessage="References" />}
                    </AntButton>
                    <AntButton
                      style={{ width: "1000px", textAlign: "left" }}
                      className="mb-3"
                      type="dashed"
                      hidden={!(Boolean(this.state.referencesButton) && this.state.referencesButton)}
                      icon={<MinusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          referencesButton: false
                        });
                      }}
                    >
                      {<FormattedMessage id="EmployeeRequest.References" defaultMessage="References" />}
                    </AntButton>
                  </>
                </Col>
              </Row>
            }
            {
              <div hidden={!(Boolean(this.state.referencesButton) && this.state.referencesButton)}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EmployeeRequest.NumberofReference(s)" defaultMessage="Number of Reference(s)" />}
                    name="numberOfReferences"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    style={{ marginRight: "10px" }}
                  >
                    <InputNumber
                      style={{ width: "100px" }}
                      parser={value => value.replace(",", ".")}
                      formatter={value => value.replace(".", ",")}
                      className="w-100"
                      placeholder="    "
                      min={0}
                    />
                  </Form.Item>
                }
                {
                  <Row gutter={0} style={{ justifyContent: "flex-start" }}>
                    <Col span={3} offset={6}>
                      <Form.Item
                        label={<FormattedMessage id="EmployeeRequest.Name" defaultMessage="Name" />}
                        name="isName"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />,
                          },
                        ]}
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                    <Col span={3} >
                      <Form.Item
                        label={<FormattedMessage id="EmployeeRequest.Company" defaultMessage="Company" />}
                        name="isCompany"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />,
                          },
                        ]}
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                    <Col span={3} >
                      <Form.Item
                        label={<FormattedMessage id="EmployeeRequest.Contact" defaultMessage="Contact" />}
                        name="isContact"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />,
                          },
                        ]}
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                    <Col span={3} >
                      <Form.Item
                        label={<FormattedMessage id="EmployeeRequest.Letter" defaultMessage="Letter" />}
                        name="isLetter"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />,
                          },
                        ]}
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                  </Row>
                }
              </div>
            }
            {
              <Row gutter={[16, 16]} >
                <Col md={3}></Col>
                <Col md={5}>
                  <>
                    <AntButton
                      style={{ width: "1000px", textAlign: "left" }}
                      className="mb-3"
                      type="dashed"
                      hidden={Boolean(this.state.preferencesButton) && this.state.preferencesButton}
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          preferencesButton: true
                        }, () => {
                          let preferencesList = [];

                          preferencesList.push({ "preferences": " " });

                          this.formRef.current.setFieldsValue({
                            preferencesList: preferencesList
                          });
                        });
                      }}
                    >
                      {<FormattedMessage id="EmployeeRequest.Preferences" defaultMessage="Preferences" />}
                    </AntButton>
                    <AntButton
                      style={{ width: "1000px", textAlign: "left" }}
                      className="mb-3"
                      type="dashed"
                      hidden={!(Boolean(this.state.preferencesButton) && this.state.preferencesButton)}
                      icon={<MinusOutlined />}
                      size="large"
                      onClick={() => {
                        this.setState({
                          preferencesButton: false
                        });
                      }}
                    >
                      {<FormattedMessage id="EmployeeRequest.Preferences" defaultMessage="Preferences" />}
                    </AntButton>
                  </>
                </Col>
              </Row>
            }
            {
              <div hidden={!(Boolean(this.state.preferencesButton) && this.state.preferencesButton)}>

                <Form.List name="preferencesList">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>

                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EmployeeRequest.Work"} defaultMessage={"Preferences"} />
                              </th>
                              <th colSpan="1">
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>

                                  <td>
                                    {field.name > 0 ?
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "preferences"]}
                                        style={{
                                          display: "inline-block",
                                          marginBottom: "5px",
                                          marginLeft: "5px"
                                        }}
                                        rules={[
                                          { required: false, message: 'This field is required.' }
                                        ]}
                                      >
                                        <Checkbox>
                                          <Input
                                            name="preferences"
                                          />
                                        </Checkbox>
                                      </Form.Item>
                                      :
                                      <Form.Item
                                        name={[field.name, "preferences"]}
                                      >
                                        <Checkbox.Group options={['Non-smoker', 'Smoker', 'Male', 'Female']} />
                                      </Form.Item>
                                    }
                                  </td>

                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot >
                      </>
                    );
                  }}
                </Form.List>

              </div>
            }



            <Row gutter={[16, 16]} className="mb-2">
              <Col md={24} style={{ display: "flex", justifyContent: "flex-end" }}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => {
                    this.setState({
                      showRoleDescriptionModal: true
                    });
                  }}
                  icon={<DescriptionIcon color="secondary" fontSize="large" />}
                >
                  Fill Criterias From Role Description
                </AntButton>
              </Col>
            </Row>
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </Form>
        </div>
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
        <Table 
          bordered 
          scroll={{ x: 900, scrollToFirstRowOnChange: true }} 
          columns={columns} 
          dataSource={this.state.tableList} 
          loading={this.state.loading} 
          onChange={this.handleTableChange} 
          pagination={this.state.pagination} 
          />  
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <AntModal
          title="Scoring"
          style={{ top: 20 }}
          visible={this.state.showScoringModal}
          onCancel={() => {
            this.setState({ showScoringModal: false });
          }}
          okButtonProps={{ style: { display: "none" } }}
          onOk={() => {
            this.setState({ showScoringModal: false });
          }}
          maskClosable={false}
          width={1000}
        >
          {this.state.employeeRequestData && (
            <EmployeeRequestScoring employeeRequestData={this.state.employeeRequestData} save={this.completeSave.bind(this)} />
          )}
        </AntModal>
        <AntModal
          title="Scoring"
          style={{ top: 20 }}
          visible={this.state.showRoleDescriptionModal}
          onCancel={() => {
            this.setState({ showRoleDescriptionModal: false });
          }}
          okButtonProps={{ style: { display: "none" } }}
          onOk={() => {
            this.setState({ showRoleDescriptionModal: false });
          }}
          maskClosable={false}
          width={1000}
        >
          <RoleDescriptionListModal fillData={this.fillCriterias.bind(this)} />
        </AntModal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfViewer}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeRequest);
