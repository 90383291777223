import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess, showWarningBasic } from "../../MessageHelper";
import ActionMenu from "../CustomMenu/ActionMenu";
import moment from "moment";
import { IApplicationContribution } from "@antv/xflow-core";
import {
  message,
  Col,
  Row,
  Table,
  Space,
  Select,
  Button as AntButton,
  Modal as AntModal,
  Spin,
  Input,
  DatePicker,
  Progress,
  Badge,
  Steps,
  Button,
  Form
} from "antd";
import { FormattedMessage } from "react-intl";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
const { Option } = Select;
const { Step } = Steps;

const ProcurementProgressWidget = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [progressType, setProgressType] = useState("line");
  const [procurementProgress, setProcurementProgress] = useState(null);
  const [showModalProcurementProgress, setShowModalProcurementProgress] = useState(false);
  const [projectId, setProjectId] = useState([]);

  useEffect(() => {
    asyncFetch();
    fillProject();
  }, [page, limit, keyword]);

  const asyncFetch = async () => {
    let pagination = {
      page: page,
      limit: limit,
      keyword: keyword
    };
    setLoading(true);
    var response = await handleRequest("POST", "/api/dashboard/procurementProgress", pagination);
    setLoading(false);
    if (response.type === "ERROR") {
      error(response);
    } else {
      let data = Boolean(response.data) ? response.data : null;
      if (Boolean(data.details) && data.details.length > 0) {
        let index = data.details.findIndex(p => p.status === "Not Started");
        if (index !== -1) data.details[index].status = "In Progress";
      }
      setData(data);
    }
  };

  const fillProject = async () => {
    const response = await handleRequest("GET", "/api/procurementProgress/companyProjectId");
    if (Boolean(response.data)) {
      setProjectId(response.data);
    }
  };

  const showDetail = async row => {
    var response = await handleRequest("GET", "/api/dashboard/procurementProgress/" + row.projectTakeOffQuantityId);
    if (response.type === "ERROR") {
      error(response);
    } else {
      let data = Boolean(response.data) ? response.data : null;

      setProcurementProgress(data);
      setShowModalProcurementProgress(true);
    }
  };

  let columns = [
    {
      key: "itemNameCode",
      title: <FormattedMessage id="ProcurementProgressWidget.ProductCode" defaultMessage="Product > Code" />,
      render: (value, row, index) => (
        <AntButton
          type="link"
          onClick={() => {
            showDetail(row);
          }}
        >
          {row.itemNameCode}{" "}
        </AntButton>
      )
    },
    {
      key: "Progress",
      title: "Progress",
      render: (value, row, index) => {
        return <Progress type={progressType} percent={row.percentCompleted} status="active" strokeColor={{ from: "#108ee9", to: "#87d068" }} />;
      }
    }
  ];

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={6}>
          <Input
            value={keyword}
            allowClear
            placeholder="Search Product"
            prefix={<SearchOutlined />}
            onChange={e => {
              setKeyword(e.target.value);
              setLimit(5);
            }}
          />
        </Col>
        <Col lg={12}>
          <Form
          // initialValues={{ remember: false }}
          // onFinish={this.save}
          //  ref={this.formRef}
          >
            <Form.Item
              label={<FormattedMessage id="ProcurementProgressWidget.Project" defaultMessage="Project" />}
              name="projectId"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                {Boolean(projectId) &&
                  projectId.length > 0 &&
                  projectId.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Form>
        </Col>
        <Col lg={6}>
          <Select
            placeholder="Progress Type"
            defaultActiveFirstOption
            allowClear
            value={progressType}
            className="w-100"
            onChange={value => {
              setProgressType(value);
              setLimit(5);
            }}
          >
            <Option key={"line"} value={"line"}>
              <FormattedMessage id="ProcurementProgressWidget.line" defaultMessage="Line" />
            </Option>
            <Option key={"circle"} value={"circle"}>
              <FormattedMessage id="ProcurementProgressWidget.Circle" defaultMessage="Circle" />
            </Option>
          </Select>
        </Col>
        {/* <Col lg={4}>
          <AntButton
            type="dashed"
            icon={<SearchOutlined />}
            onClick={() => {
              asyncFetch();
            }}
          >
            <FormattedMessage id="GeneralButtonSearch" defaultMessage="Search" />
          </AntButton>
        </Col> */}
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={24}>
          <div>
            <Table columns={columns} dataSource={data} pagination={false} />
          </div>
          <Button
            onClick={() => setLimit(limit + 5)}
            type="dashed"
            loading={loading}
            disabled={loading}
            style={{ marginRight: "auto", marginLeft: "auto", marginTop: "8px" }}
          >
            Load More
          </Button>
        </Col>
      </Row>

      <AntModal
        visible={showModalProcurementProgress}
        okButtonProps={{ hidden: true }}
        onCancel={() => setShowModalProcurementProgress(false)}
        width={700}
        centered
      >
        <Badge.Ribbon text="Procurement Progress" placement="start" color="green" style={{ fontSize: 12 }}>
          <div className="card card-custom" style={{ padding: "0.1rem", minHeight: "100%" }}>
            <div className="card-body pt-4 custom-timeline pr-5 pl-5 mr-5 mt-10 align-content-center">
              <Steps direction="vertical" className="pl-5-custom anticon-custom">
                {Boolean(procurementProgress) &&
                  Boolean(procurementProgress.details) &&
                  procurementProgress.details.length > 0 &&
                  procurementProgress.details.map((item, index) => {
                    return (
                      <Step
                        title={item.procurementProgressStepName}
                        status={
                          item.status === "Completed"
                            ? "finish"
                            : item.status === "In Progress"
                            ? "process"
                            : item.status === "Not Started"
                            ? "wait"
                            : "wait"
                        }
                        description={"( " + item.weight + " % )"}
                        icon={item.status === "In Progress" && <LoadingOutlined />}
                      />
                    );
                  })}
              </Steps>
            </div>
          </div>
        </Badge.Ribbon>
      </AntModal>
    </>
  );
};

export default ProcurementProgressWidget;
