import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Input, Tree, Form, Modal as AntModal, Space, InputNumber, Row, Col } from "antd";
import { FcFolder, FcFile } from "react-icons/fc";
import { FaFileAudio, FaRegFile } from "react-icons/fa";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../../ApiConnector";
import { error, showError, showSuccess } from "../../../MessageHelper";
import { Button } from "react-bootstrap";

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

export class PresentationContentPageProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "PresentationContentPageProject",
      controllerName: "presentationContentPageProject",
      id: null,
      projectName: "",
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      nodes: [],
      loadedKeys: [],
      searchValue: "",
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      refreshTree: true
    };
  }

  inputRef = React.createRef(null);
  formRef = React.createRef();
  formRefOnClickName = React.createRef();
  formRefCreateProject = React.createRef();

  componentDidMount = async () => {
    this.fillTree();
    this.getProjects();
  };

  getProjects = async () => {
    var responseProjects = await handleRequest("GET", "/api/" + this.state.controllerName + "/getProjects/" + this.props.presentationContentPageTitleId);
    if (responseProjects.type === "ERROR") {
      error(responseProjects);
    }
    let row = responseProjects.data;
    let checkedKeys = { checked: [] };
    if (Array.isArray(row.projectsIdentificationsIds) && row.projectsIdentificationsIds.length > 0) {
      checkedKeys.checked = row.projectsIdentificationsIds;
    }
    this.setState({
      checkedKeys: checkedKeys
    });
  };

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "ProjectName"
    };

    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitems/" + obj.id);
    }
    if (response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.icon =
          Boolean(element.type) && element.type === "ProjectName" ? (
            <FcFile />
          ) : element.type === "Project" ? (
            <FaRegFile />
          ) : element.type === "Structure" ? (
            <FaFileAudio />
          ) : element.type === "Area" ? (
            <FaFileAudio />
          ) : element.type === "Division" ? (
            <FaFileAudio />
          ) : (
            <FcFolder />
          );
        element.title = element.projectName;
        element.key = element.id;
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      this.setState({
        nodes: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  onCheck = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    if (Boolean(this.props) && Boolean(this.props.onTargetKeysCheck)) {
      console.log("checkedKeys", e.checkedNodes);
      console.log("checkedKeys", e.halfCheckedKeys);
      this.props.onTargetKeysCheck(checkedKeys.checked);
    }
    this.setState({
      checkedKeys: checkedKeys,
      halfCheckedKeys: e.halfCheckedKeys,
      checkedNodes: e.checkedNodes
    });
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "ProjectName";
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  handleItemClick = ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;

    console.log("AAA", event.currentTarget.id);
    console.log(selectedNode);
    console.log(parentNode);
    console.log(key);

    // eslint-disable-next-line default-case
    switch (event.currentTarget.id) {
      case "create_projectName":
        this.setState(
          {
            showCreateProjectModal: true,
            parentId: selectedNode.id,
            projectName: "",
            type: "ProjectName"
          },
          () => {
            if (Boolean(this.formRefCreateProject) && Boolean(this.formRefCreateProject.current)) {
              this.formRefCreateProject.current.resetFields();
            }
          }
        );
        break;

      case "create_project":
        this.setState(
          {
            showCreateProjectModal: true,
            parentId: selectedNode.id,
            projectName: "",
            type: "Project"
          },
          () => {
            if (Boolean(this.formRefCreateProject) && Boolean(this.formRefCreateProject.current)) {
              this.formRefCreateProject.current.resetFields();
            }
          }
        );
        break;

      case "create_Structure":
        this.setState(
          {
            showCreateProjectModal: true,
            parentId: selectedNode.id,
            projectName: "",
            type: "Structure"
          },
          () => {
            if (Boolean(this.formRefCreateProject) && Boolean(this.formRefCreateProject.current)) {
              this.formRefCreateProject.current.resetFields();
            }
          }
        );
        break;

      case "create_Area":
        this.setState(
          {
            showCreateProjectModal: true,
            parentId: selectedNode.id,
            projectName: "",
            type: "Area"
          },
          () => {
            if (Boolean(this.formRefCreateProject) && Boolean(this.formRefCreateProject.current)) {
              this.formRefCreateProject.current.resetFields();
            }
          }
        );
        break;

      case "create_Division":
        this.setState(
          {
            showCreateProjectModal: true,
            parentId: selectedNode.id,
            projectName: "",
            type: "Division"
          },
          () => {
            if (Boolean(this.formRefCreateProject) && Boolean(this.formRefCreateProject.current)) {
              this.formRefCreateProject.current.resetFields();
            }
          }
        );
        break;

      case "rename":
        this.setState({
          showCreateProjectModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        });
        break;

      case "delete":
        this.setState({
          showDeleteModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;
      default:
    }
  };

  getCheckedKeys = async id => {
    if (Boolean(id) && id > 0) {
      var res = await handleRequest("GET", "/api/" + this.state.controllerName + "/samplingDivision/" + id);
      if (res.type === "ERROR") {
        error(res);
      } else {
        let list = Boolean(res.data) ? res.data : [];
        let keyList = [];
        list.forEach(element => {
          keyList.push("CODE~" + element.stockCardId);
        });
        console.log(keyList);
        if (Boolean(this.props) && Boolean(this.props.onTargetKeysCheck)) {
          this.props.onTargetKeysCheck(keyList);
        }
        this.setState({
          checkedKeys: keyList
        });
      }
    }
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id,
      type: this.state.type
    };

    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
      else this.fillTree();

      showSuccess();
      this.setState({
        id: null,
        showDeleteModal: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      presentationContentPageTitleId: this.props.presentationContentPageTitleId
    };
    console.log("TTT", this.state.checkedKeys);
    newItem.projectsIdentificationsIds = Array.isArray(this.state.checkedKeys?.checked) ? this.state.checkedKeys.checked.map(element => element) : [];

    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/save", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
      this.getProjects();
    }
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      description: "",
      projectName: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      process: null,
      showOrderModal: false,
      showPasteModal: false
    });
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          {this.state.refreshTree && (
            <Tree
              style={{ marginTop: "10px", lineHeight: "30px" }}
              className="draggable-tree"
              showLine={{ showLeafIcon: false }}
              showIcon
              checkStrictly={true}
              loadedKeys={this.state.loadedKeys}
              onLoad={this.onLoadData}
              loadData={this.loadData}
              onExpand={this.onExpand}
              expandedKeys={this.state.expandedKeys}
              autoExpandParent={false}
              treeData={this.state.nodes}
              blockNode={true}
              draggable
              checkable
              onCheck={this.onCheck}
              checkedKeys={this.state.checkedKeys}
              halfCheckedKeys={this.state.halfCheckedKeys}
            />
          )}

          <br />
          <div hidden={false}>
            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            }
          </div>
        </Form>

        <AntModal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={500}
          okButtonProps={{ hidden: true }}
          open={this.state.showDeleteModal}
          onCancel={() => {
            this.setState({ showDeleteModal: false });
          }}
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ showDeleteModal: false });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Space>
          ]}
        >
          <p>
            <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
          </p>
        </AntModal>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PresentationContentPageProject);
