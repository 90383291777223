import React, { useState, useEffect } from "react";
import { handleRequest } from "../../ApiConnector";
import { error } from "../../MessageHelper";
import { Table, Col, Select, Row, DatePicker } from "antd";
import { Pie } from "@ant-design/plots";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { Line } from "@ant-design/plots";
import { Column, Radar } from "@ant-design/charts";
const { Option } = Select;
const CountyNumberOfProjects = () => {
  const [tableList, setTableList] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(6, "M"));
  const [endDate, setEndDate] = useState(moment());
  const [progressType, setProgressType] = useState("table");
  const [radarVisible, setRadarVisible] = useState(false);
  const [tableVisible, setTableVisible] = useState(true);
  const [pieVisible, setPieVisible] = useState(false);
  const [lineVisible, setLineVisible] = useState(false);
  const [columnVisible, setColumnVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    showSizeChanger: true,
    pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
    total: 0
  });
  const barConfig = {
    data: tableList,
    isGroup: true,
    xField: "value",
    yField: "key",
    seriesField: "value",
    yAxis: {
      label: {
        formatter: v => `${v}`,
        layout: [{ type: "interval-adjust-position" }, { type: "interval-hide-overlap" }, { type: "adjust-color" }]
      }
    },
    color: ["#1979C9", "#D62A0D", "#FAA219", "#FF99C3"]
  };
  const lineConfig = {
    data: tableList,
    xField: "value",
    yField: "key",
    seriesField: "value",
    yAxis: {
      label: {
        formatter: v => `${v}`
      }
    },
    color: ["#1979C9", "#D62A0D", "#FAA219", "#FF99C3"]
  };
  var pieConfig = {
    appendPadding: 10,
    data: tableList,
    angleField: "key",
    colorField: "value",
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 14
      }
    },
    interactions: [
      {
        type: "element-selected"
      },
      {
        type: "element-active"
      },
      {
        type: "pie-statistic-active"
      }
    ]
  };
  const handleSelectChange = value => {
    console.log("value", value);
    switch (value) {
      case "table":
        setTableVisible(true);
        setRadarVisible(false);
        setPieVisible(false);
        setColumnVisible(false);
        setLineVisible(false);
        break;
      case "line":
        setLineVisible(true);
        setRadarVisible(false);
        setPieVisible(false);
        setColumnVisible(false);
        setTableVisible(false);
        break;
      case "progress":
        setTableVisible(true);
        setRadarVisible(false);
        setPieVisible(false);
        setColumnVisible(false);
        setLineVisible(false);
        break;

      case "circle":
        setTableVisible(true);
        setRadarVisible(false);
        setPieVisible(false);
        setColumnVisible(false);
        setLineVisible(false);
        break;
      case "radar":
        setRadarVisible(true);
        setPieVisible(false);
        setColumnVisible(false);
        setLineVisible(false);
        setTableVisible(false);
        break;
      case "pie":
        setPieVisible(true);
        setLineVisible(false);
        setColumnVisible(false);
        setTableVisible(false);
        setRadarVisible(false);
        break;
      case "column":
        setColumnVisible(true);
        setLineVisible(false);
        setTableVisible(false);
        setRadarVisible(false);
        setPieVisible(false);
        break;
    }
  };
  useEffect(() => {
    asyncFetch();
  }, [startDate, endDate]);

  const asyncFetch = async () => {
    restartTable({ pagination }, progressType);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters }, progressType);
  };
  const restartTable = async (params = {}, progressType) => {
    const search = {
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize
    };
    console.log(search);
    var response = await handleRequest("POST", "/api/dashboard/countyNumberOfProjectss/" + progressType, search);
    if (response.type === "ERROR") {
      error(response);
    } else {
      setTableList(Boolean(response.data) ? response.data : null);
      setPagination({
        ...params.pagination,
        total: response.data.totalRecords
      });
    }
  };
  const columns = [
    {
      title: <FormattedMessage id="CountyNumberOfProjects.County" defaultMessage="County" />,
      key: "value",
      render: record => record.value
    },
    {
      title: <FormattedMessage id="CountyNumberOfProjects.NumberOfProjects" defaultMessage="Number of Projects" />,
      key: "key",
      render: record => record.key,
      align: "center"
    }
  ];

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={8}>
          <DatePicker
            placeholder="Start Date"
            style={{ width: "100%" }}
            value={startDate}
            onChange={date => {
              setStartDate(date);
            }}
          />
        </Col>
        <Col lg={8}>
          <DatePicker
            placeholder="End Date"
            style={{ width: "100%" }}
            value={endDate}
            onChange={date => {
              setEndDate(date);
            }}
          />
        </Col>
        <Col lg={8}>
          <Select
            defaultActiveFirstOption
            allowClear
            value={progressType}
            className="w-100"
            onChange={value => {
              setProgressType(value);
              restartTable({ pagination }, value);
              handleSelectChange(value);
            }}
          >
            <Option key={"circle"} value={"circle"}>
              <FormattedMessage id="General.Circle" defaultMessage="Circle" />
            </Option>
            <Option key={"table"} value={"table"}>
              <FormattedMessage id="General.Table" defaultMessage="Table" />
            </Option>
            <Option key={"pie"} value={"pie"}>
              <FormattedMessage id="General.Pie" defaultMessage="Pie" />
            </Option>
            <Option key={"column"} value={"column"}>
              <FormattedMessage id="General.Column" defaultMessage="Column" />
            </Option>
            <Option key={"radar"} value={"radar"}>
              <FormattedMessage id="General.Radar" defaultMessage="Radar" />
            </Option>
            <Option key={"line"} value={"line"}>
              <FormattedMessage id="General.Line" defaultMessage="Line" />
            </Option>
          </Select>
        </Col>
      </Row>
      <br />
      <Row gutter={[16, 16]}>
        <Col lg={24}>
          {tableVisible && <Table bordered columns={columns} dataSource={tableList} onChange={handleTableChange} pagination={false} />}
        </Col>

        <Col lg={24}>
          {Boolean(tableList) && tableList.length > 0 && pieVisible && <Pie {...pieConfig} />}

          {Boolean(tableList) && tableList.length > 0 && lineVisible && <Line {...lineConfig} />}

          {Boolean(tableList) && tableList.length > 0 && columnVisible && <Column {...barConfig} />}

          {Boolean(tableList) && tableList.length > 0 && radarVisible && <Radar {...barConfig} />}
        </Col>
      </Row>
    </>
  );
};
export default CountyNumberOfProjects;
