import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Input, Modal as AntModal } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import AddIcon from '@material-ui/icons/Add';
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Select } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import PageAutoDesignChildChild from "./PageAutoDesignChildChild";
import PageAutoDesignTable from "./PageAutoDesignTable";
import PageAutoDesignForm from "./PageAutoDesignForm";

const { Option } = Select;

export class PageAutoDesignChild extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "PageAutoDesignChild",
      controllerName: "pageAutoDesign",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = (prevProps) => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {

  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/pagingChild";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      parentId: this.props.parentId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let childForm = {
      name: "Form",
      icon: <AddIcon fontSize="small" color="primary" />,
      actionClick: this.addFormModal.bind(this)
    };

    let childTable = {
      name: "Table",
      icon: <AddIcon fontSize="small" color="error" />,
      actionClick: this.addTableModal.bind(this)
    };

    let childAction = {
      name: "Add Child",
      icon: <AddIcon fontSize="small" color="secondary" />,
      actionClick: this.addChildModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);
    actionList.push(childForm);
    actionList.push(childTable);
    actionList.push(childAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  addFormModal = record => {
    this.setState({
      random: Math.random(),
      showFormModal: true,
      selectedRecord: record,
      pageAutoDesignId: record.id,
    });
  };

  addTableModal = record => {
    this.setState({
      random: Math.random(),
      showTableModal: true,
      selectedRecord: record,
      pageAutoDesignId: record.id,
    });
  };

  addChildModal = record => {
    this.setState({
      random: Math.random(),
      showChildModal: true,
      selectedRecord: record,
      pageAutoDesignId: record.id,
    });
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    if (Boolean(this.props.parentId) && this.props.parentId > 0) {
      const newItem = {
        ...values,
        id: this.state.id,
        parentId: this.props.parentId,
      };

      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createParentChild", newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateParentChild/" + newItem.id, newItem);
      }

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showError("Page Auto Design not found");
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };
  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  closeFormModal = () => {
    this.setState({
      showFormModal: false,
    });
  };

  closeTableModal = () => {
    this.setState({
      showTableModal: false,
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="PageAutoDesignChild.ChildPage" defaultMessage="Child Page" />,
        key: "description",
        render: record => {
          return record.description;
        }, ...getColumnFilter({
          inputName: "description", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
    ];
    const excelExport = (<>
      {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
      {this.state.exportClick ? (<ExcelFile filename="PageAutoDesignChild" hideElement={true}>
        <ExcelSheet name="PageAutoDesignChild" data={this.state.tableList}>
        </ExcelSheet>
      </ExcelFile>) :
        (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel}
          icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)} */}
    </>);
    return (
      <Badge.Ribbon text={<FormattedMessage id="pageAutoDesign.pageTitle" defaultMessage="Page Auto Design Child" />} placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={21}></Col>
              <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={this.onClickCreateNew} icon={<CreateIcon color="secondary" fontSize="large" />}  ></AntButton>
                <AntButton style={{ border: "0px" }} hidden={this.state.hideInputs} onClick={this.onClickCreateNew} icon={<RemoveCircle color="error" fontSize="large" />} ></AntButton>
              </Col>
            </Row>
            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PageAutoDesignChild.ChildPage" defaultMessage="Child Page" />}
                  extra={<FormattedMessage id="PageAutoDesignChild.PleaseWriteWithoutSpaces" defaultMessage="Please write without spaces" />}
                  name="description"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }

              <div hidden={this.state.hideSave}>
                {<Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {<Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>}
              </div>
            </div>
          </Form>

          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (<div className="alert alert-custom alert-light">
            <div className="alert-icon"><FilterOutlined /></div>
            <div className="alert-text">
              {this.state.searchCriteriaModels.map((val, i) => {
                return (<div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                  <span className="mr-2">{val.label}</span>
                  <strong className="mr-2">{val.type}</strong>
                  <span className="mr-2">{val.operationValue}</span>
                  <span className="mr-2">{val.showValue}</span>
                  <span>
                    <Tooltip title="Remove">
                      <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                    </Tooltip>
                  </span>
                </div>);
              })}
            </div>
            <div className="alert-close">
              <Tooltip title="Remove All">
                <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.setState({ searchCriteriaModels: [] }, () => { this.filterChangeItem([]); })} />
              </Tooltip>
            </div>
          </div>)}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              rowKey="index"
              columns={columns} dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
              expandable={{
                expandedRowRender: record => Boolean(record) && <PageAutoDesignChildChild parentId={record.id} random={this.state.random} />,
                rowExpandable: () => true,
                onExpand: (expanded, record) => {
                  this.setState({ random: Math.random() });
                },
              }}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <AntModal
            centered
            width={1100}
            title=""
            open={this.state.showFormModal}
            onCancel={() => {
              this.setState({ showFormModal: false });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showFormModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
          >
            {<PageAutoDesignForm pageAutoDesignId={this.state.pageAutoDesignId} random={this.state.random} closeFormModal={this.closeFormModal}></PageAutoDesignForm>}
          </AntModal>

          <AntModal
            centered
            width={1100}
            title=""
            open={this.state.showTableModal}
            onCancel={() => {
              this.setState({ showTableModal: false });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showTableModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
          >
            {<PageAutoDesignTable pageAutoDesignId={this.state.pageAutoDesignId} random={this.state.random} closeTableModal={this.closeTableModal} child={true}></PageAutoDesignTable>}
          </AntModal>

          <AntModal
            centered
            width={1100}
            title=""
            open={this.state.showChildModal}
            onCancel={() => {
              this.setState({ showChildModal: false });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showChildModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
          >
            {<PageAutoDesignChildChild parentId={this.state.pageAutoDesignId} random={this.state.random}></PageAutoDesignChildChild>}
          </AntModal>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PageAutoDesignChild);